import React from 'react';
import I18n from 'i18n-js';
import { isEmpty, isNull, isUndefined, omitBy, toPlainObject } from 'lodash';
import classNames from 'classnames';

import { gql } from '@reverbdotcom/commons/src/gql';
import { useMutation } from '@reverbdotcom/commons/src/useMutation';
import { RCButton, RCIconButton, RCPopover, RCTextWithIcon, useMediaQuery } from '@reverbdotcom/cadence/components';
import { DownloadIcon } from '@reverbdotcom/cadence/icons/react';
import { Listing, core_apimessages_BulkExportListingsRequest_ListingState } from '@reverbdotcom/commons/src/gql/graphql';
import { useViewTracking } from '@reverbdotcom/commons/src/use_tracking';
import { MParticleEventName } from '@reverbdotcom/commons/src/elog/mparticle_types';
import { trackEvent } from '@reverbdotcom/commons/src/elog/mparticle_tracker';

import {
  DRAFT,
  ENDED,
  LIVE,
  ORDERED,
  SOLD_OUT,
  SELLER_UNAVAILABLE,
  queryParamState,
  SUSPENDED,
} from '../toolbarHelpers';
import SellerListingsCollectionContext from '../SellerListingsCollectionContext';

export const COMPONENT_NAME = 'ExportListingsButton';

export const STATE_MAP = {
  [LIVE]: core_apimessages_BulkExportListingsRequest_ListingState.LIVE,
  [DRAFT]: core_apimessages_BulkExportListingsRequest_ListingState.DRAFT,
  [ENDED]: core_apimessages_BulkExportListingsRequest_ListingState.ENDED,
  [ORDERED]: core_apimessages_BulkExportListingsRequest_ListingState.ORDERED,
  [SOLD_OUT]: core_apimessages_BulkExportListingsRequest_ListingState.SOLD_OUT,
  [SELLER_UNAVAILABLE]: core_apimessages_BulkExportListingsRequest_ListingState.VACATION,
  [SUSPENDED]: core_apimessages_BulkExportListingsRequest_ListingState.SUSPENDED,
};
interface IExternalProps {
  selectedListings: Listing[];
  onSuccessCallback: (errorMessage: string) => void;
  onErrorCallback: (errorMessage: string) => void;
  fullSizeButton?: boolean;
}

const EXPORT_LISTINGS_MUTATION = gql(`
  mutation Core_MyListings_ExportListings($input: Input_core_apimessages_BulkExportListingsRequest) {
    bulkExportListings(input: $input) {
      enqueued
      message
    }
  }
`);

export default function ExportListingsButton({
  selectedListings,
  onSuccessCallback,
  onErrorCallback,
  fullSizeButton = false,
}: IExternalProps) {
  const {
    listingsCollectionState,
  } = React.useContext(SellerListingsCollectionContext);

  const {
    hasAllMatchesSelected,
    totalMatches,
    serializedDashboardSearchParams,
    originatingUserId,
  } = listingsCollectionState;

  const isMobile = useMediaQuery('mobile');
  const selectedState = queryParamState(window);
  const exportState = STATE_MAP[queryParamState(window)];

  const [bulkExportListings, {
    data = {},
    loading,
    errors,
  }] = useMutation(EXPORT_LISTINGS_MUTATION);

  const { message: successMessage } = data.bulkExportListings ?? {};

  const hasErrors = !!errors.length;

  const [isPopoverOpen, setIsPopoverOpen] = React.useState(false);
  const hasSelectedListings = !!selectedListings.length;
  const hasSelections = hasAllMatchesSelected || hasSelectedListings;

  React.useEffect(() => {
    if (loading) return;

    setIsPopoverOpen(false);

    if (hasErrors) return onErrorCallback(
      I18n.t('discovery.listingsManagement.genericError'),
    );

    if (isEmpty(data)) return;

    onSuccessCallback(successMessage);
  }, [loading]);

  function trackDropdownItemClick({
    state = null,
    listingIds = null,
    exportingAllMatchesSelected = false,
    listingsCount = null,
  }) {
    const eventAttributes = toPlainObject(omitBy({
      eventName: MParticleEventName.ExportingListings,
      componentName: COMPONENT_NAME,
      listingState: state,
      listingIds,
      listingsCount,
      exportingAllMatchesSelected,
    }, isNull || isUndefined));

    trackEvent(eventAttributes);
  }

  useViewTracking({
    componentName: COMPONENT_NAME,
    eventName: MParticleEventName.ComponentView,
  }, true);

  return (
    <RCPopover
      isOpen={isPopoverOpen}
      onDismiss={() => setIsPopoverOpen(false)}
      trapFocus
      position="bottom-end"
      anchor={fullSizeButton ? (
        <RCButton
          fullWidth
          onClick={() => setIsPopoverOpen(true)}
          variant="outlined"
          size={isMobile ? 'medium' : 'mini'}
        >
          <RCTextWithIcon
            svgComponent={DownloadIcon}
            placement="right"
          >
            {I18n.t('discovery.listingsManagement.toolbar.bulkActions.exportListings')}
          </RCTextWithIcon>
        </RCButton>
      ) : (
        <RCIconButton
          onClick={() => {
            trackEvent({
              eventName: MParticleEventName.ClickedComponent,
              componentName: COMPONENT_NAME,
            });

            setIsPopoverOpen(true);
          }}
          label={I18n.t('discovery.listingsManagement.toolbar.bulkActions.exportListings')}
          svgComponent={DownloadIcon}
          variant="outlined"
          size="mini"
        />
      )}
    >
      <div className="d-flex fx-dir-col fx-align-start weight-bold">
        <div className="pb-3 width-100">
          <button
            type="button"
            className="listings-management-export-buttons"
            onClick={() => {
              trackDropdownItemClick({
                state: core_apimessages_BulkExportListingsRequest_ListingState.ALL,
              });

              bulkExportListings({
                variables: {
                  input: {
                    state: core_apimessages_BulkExportListingsRequest_ListingState.ALL,
                    recipientId: originatingUserId,
                  },
                },
              });
            }}
          >
            <RCTextWithIcon svgComponent={DownloadIcon}>
              {I18n.t('discovery.listingsManagement.toolbar.bulkActions.exports.exportAll')}
            </RCTextWithIcon>
          </button>
        </div>

        <div className={classNames('pt-3 bdt-1 bd-color-gray-300 width-100', {
          'pb-3': hasSelectedListings,
        })}>
          <button
            type="button"
            className="listings-management-export-buttons"
            onClick={() => {
              trackDropdownItemClick({
                state: exportState,
              });

              bulkExportListings({
                variables: {
                  input: {
                    state: exportState,
                    recipientId: originatingUserId,
                  },
                },
              });
            }}
          >
            <RCTextWithIcon svgComponent={DownloadIcon}>
              {I18n.t(`discovery.listingsManagement.toolbar.bulkActions.exports.states.${selectedState}`)}
            </RCTextWithIcon>
          </button>
        </div>

        {hasSelections && (
          <div className="pt-3 bdt-1 bd-color-gray-300 width-100">
            <button
              type="button"
              className="listings-management-export-buttons"
              onClick={() => {
                if (hasAllMatchesSelected) {
                  trackDropdownItemClick({
                    listingsCount: totalMatches,
                    exportingAllMatchesSelected: true,
                  });

                  bulkExportListings({
                    variables: {
                      input: {
                        serializedDashboardSearchParams,
                        recipientId: originatingUserId,
                      },
                    },
                  });
                } else {
                  const listingIds = selectedListings.map(listing => listing.id);

                  trackDropdownItemClick({
                    listingIds,
                    listingsCount: listingIds.length,
                  });

                  bulkExportListings({
                    variables: {
                      input: {
                        listingIds,
                        recipientId: originatingUserId,
                      },
                    },
                  });
                }
              }}
            >
              <RCTextWithIcon svgComponent={DownloadIcon}>
                {I18n.t('discovery.listingsManagement.toolbar.bulkActions.exports.exportSelected')}
              </RCTextWithIcon>
            </button>
          </div>
        )}
      </div>
    </RCPopover>
  );
}
