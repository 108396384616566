import React from 'react';
import { trackEvent, trackPageView } from './elog/mparticle_tracker';
import { MParticleEvent, PageViewEvent } from './elog/mparticle_types';

export function useViewTracking(
  viewEvent: MParticleEvent,
  shouldTrack: boolean,
) {
  const [hasViewed, setView] = React.useState(false);

  React.useEffect(() => {
    if (!shouldTrack || hasViewed) return;

    trackEvent(viewEvent);

    setView(true);
  }, [hasViewed, shouldTrack, viewEvent]);

  return [hasViewed, setView];
}

export function usePageViewTracking(mParticlePageView: PageViewEvent, triggerEffectProps) {
  React.useEffect(() => {
    if (!mParticlePageView) { return; }

    trackPageView(mParticlePageView);
  }, [mParticlePageView, triggerEffectProps]);
}

export function useIntersectionTracking(
  viewEvent: MParticleEvent,
  shouldTrack: boolean,
  intersectionObserverOptions = { threshold: 0.5, rootMargin: '0px' },
) {
  const notSupported = !('IntersectionObserver' in window) ||
    !('IntersectionObserverEntry' in window) ||
    !('isIntersecting' in window.IntersectionObserverEntry.prototype);
  const [hasViewed, setView] = React.useState(false);
  const [ref, setRef] = React.useState(null);
  const observerRef = React.useRef(null);
  const trackIntersectionView = React.useCallback(
    () => {
      if (!shouldTrack) return;

      trackEvent(viewEvent);
      setView(true);
    },
    [viewEvent, shouldTrack],
  );

  React.useEffect(
    () => {
      if (notSupported) return;

      const observer = new window.IntersectionObserver(
        entries => {
          const entry = entries[0];
          if (entry.isIntersecting) {
            trackIntersectionView();
          }
        },
        intersectionObserverOptions,
      );
      observerRef.current = observer;
    },
    [], // left empty to prevent runs on re-render
  );

  React.useEffect(
    () => {
      if (notSupported) return null;

      const observer = observerRef.current;
      if (ref) {
        if (hasViewed) {
        // if we have already tracked the view,
        // unobserve the component so we don't log another track event
          observer.unobserve(ref);
        } else {
          observer.observe(ref);
        }
      }

      return () => {
        if (ref) {
        // clean up after the ref and unobserve it
          observer.unobserve(ref);
        }
      };
    },
    [hasViewed, ref, notSupported],
  );

  return setRef;
}
