import React from 'react';
import classNames from 'classnames';
import ReactDOM from 'react-dom';
import bind from '../bind';

const ESCAPE_KEY = 27;
const MOUSEOUT_BUFFER_MS = 600;
// this is a buffered dropdown that won't immediately disappear on mouseout.

interface IProps {
  trigger?: string | JSX.Element;
  coreLinkTrigger?: JSX.Element;
  contents: React.ReactNode;

  // this is effectively a callback allowing a controlling parent component
  // to set this dropdown as the activeDropdownKey and give that value
  // to other dropdowns
  onOpen: Function;

  // this is an arbitrary unique key
  dropdownKey: string;

  // if this value exists and doesn't match `dropdownKey`, the dropdown
  // will close immediately
  activeDropdownKey: string;

  // When this is set, the contents will be hidden even when open for mobile devices
  hideDropdownOnMobile?: boolean;

  disableDropdown?: boolean;

  onClose?: Function;
}

interface IState {
  isOpen: boolean;
}

export default class HeaderDropdown extends React.Component<IProps, IState> {
  state = {
    isOpen: false,
  };

  timeoutId: number;

  @bind
  didClickPage(evt) {
    if (ReactDOM.findDOMNode(this).contains(evt.target)) return;
    this.closeDropdown();
  }

  @bind
  handleKeyDown(evt) {
    if (evt.keyCode === ESCAPE_KEY) {
      this.closeDropdown();
    }
  }

  componentDidUpdate(prevProps) {
    if (!this.props.activeDropdownKey) return null;
    if (prevProps.activeDropdownKey === this.props.activeDropdownKey) return null;
    if (this.props.dropdownKey === this.props.activeDropdownKey) return null;

    this.closeDropdown();
  }

  @bind
  openDropdown() {
    if (this.props.disableDropdown) return;

    this.setState({ isOpen: true });
    this.bindPageClickEvent();
    this.props.onOpen(this.props.dropdownKey);
  }

  @bind
  closeDropdown() {
    this.unbindPageClickEvent();
    this.setState({ isOpen: false });
    if (this.props.onClose) {
      this.props.onClose();
    }
  }

  @bind
  bufferedClose() {
    window.clearTimeout(this.timeoutId);
    this.timeoutId = window.setTimeout(
      () => {
        if (this.shouldClose()) this.closeDropdown();
      },
      MOUSEOUT_BUFFER_MS,
    );
  }

  @bind
  toggleDropdown(evt) {
    evt.preventDefault();
    if (!this.state.isOpen) this.openDropdown();
    if (this.state.isOpen) this.closeDropdown();
  }

  @bind
  handleTriggerClick(e) {
    // possible for touch devices to keep the trigger focused,
    // so we check state.isOpen to make sure the dropdown is open
    // before they visit the link.
    // also, if they tap after scrolling (which doesn't remove focus),
    // we reopen the modal
    if (this.props.disableDropdown) return;

    e.preventDefault();
    if (!this.state.isOpen) this.openDropdown();
  }

  @bind
  handleBlur() {
    const ref = ReactDOM.findDOMNode(this) as any;

    // when the trigger loses focus, we want the dropdown to stay open if the user is focused within the dropdown
    // if the focus is not within this component, the dropdown should close
    if (!ref.querySelector(':focus')) {
      this.closeDropdown();
    }
  }

  shouldClose() {
    const ref = ReactDOM.findDOMNode(this) as any;
    return !ref.querySelector(':hover');
  }

  unbindPageClickEvent() {
    document.removeEventListener('touchstart', this.didClickPage);
    document.removeEventListener('keydown', this.handleKeyDown);
  }

  bindPageClickEvent() {
    document.addEventListener('touchstart', this.didClickPage);
    document.addEventListener('keydown', this.handleKeyDown);
  }

  triggerId() {
    return `header-dropdown-${this.props.dropdownKey}`;
  }

  renderTrigger() {
    const { coreLinkTrigger } = this.props;

    if (coreLinkTrigger) {
      return React.cloneElement(coreLinkTrigger, {
        className: `header-dropdown__trigger ${coreLinkTrigger.props.className ?? ''}`,
        onFocus: this.openDropdown,
        onClick: this.handleTriggerClick,
        'aria-expanded': this.state.isOpen,
        id: this.triggerId(),
      });
    }
    return (
      <button
        className="header-dropdown__trigger"
        onFocus={this.openDropdown}
        onClick={this.handleTriggerClick}
        type="button"
        disabled={this.props.disableDropdown}
        aria-expanded={this.state.isOpen}
        id={this.triggerId()}
      >
        {this.props.trigger}
      </button>
    );
  }

  render() {
    const classes = classNames(
      'header-dropdown',
      { 'header-dropdown--open': this.state.isOpen },
    );

    const contentsClasses = classNames(
      'header-dropdown__contents',
      { 'header-dropdown__contents--mobile-hide': this.props.hideDropdownOnMobile },
    );

    return (
      <div
        className={classes}
        onMouseOver={this.openDropdown}
        onFocus={this.openDropdown}
        onMouseOut={this.bufferedClose}
        onBlur={this.handleBlur}
      >
        {this.renderTrigger()}
        <div
          className={contentsClasses}
          role="region"
          aria-labelledby={this.triggerId()}
          hidden={!this.state.isOpen}
        >
          {this.props.contents}
        </div>
      </div>
    );
  }
}
