import React from 'react';
import Currency from '../currency';
import { RCTextInput, TextInputProps } from '@reverbdotcom/cadence/components';

interface Props extends TextInputProps {
  /** Currency for value */
  currencyCode: string;
}

const NUMERIC_PATTERN = '[0-9.,\\s]*';

export function MoneyInput({ currencyCode, ...props }: Props) {
  const symbol = Currency.get(currencyCode)?.symbol || '';

  return (
    <RCTextInput
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      prefixText={symbol}
      type="text"
      pattern={NUMERIC_PATTERN}
      inputMode="decimal"
    />
  );
}
