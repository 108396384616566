import * as I18n from 'i18n-js';
import React from 'react';

import { RCTextWithIcon } from '@reverbdotcom/cadence/components';
import CouponIcon from '@reverbdotcom/cadence/images/nudge/coupon.svg?component';
import CoreLink from '@reverbdotcom/commons/src/components/core_link';

import URLHelpers from '../../shared/url_helpers';

export function buildFinePrint(
  restrictionsSummary: string,
  excludedBrandsNames: string[] = [],
  excludedCategoriesNames: string[] = [],
): string {
  const finePrintSentences = [restrictionsSummary];

  if (excludedBrandsNames.length > 0) {
    finePrintSentences.push(
      I18n.t(
        'discovery.dashboard.selling.shopCampaigns.couponPreview.brandsFinePrint',
        { brands: excludedBrandsNames.join(', ') },
      ),
    );
  }

  if (excludedCategoriesNames.length > 0) {
    finePrintSentences.push(
      I18n.t(
        'discovery.dashboard.selling.shopCampaigns.couponPreview.categoriesFinePrint',
        { categories: excludedCategoriesNames.join(', ') },
      ),
    );
  }

  return finePrintSentences.join(' ');
}

interface IExternalProps {
  headerText: string;
  children: React.ReactNode;
  headerAction?: React.ReactNode;
  hideTermsLink?: boolean;
}

export function ShopCampaignCouponPreview({ headerText, children, headerAction = undefined, hideTermsLink = false }: IExternalProps) {
  return (
    <div className="shop-campaign-coupon-preview">
      <div className="shop-campaign-coupon-preview__header">
        <div>
          <RCTextWithIcon svgComponent={CouponIcon} placement="left">
            {headerText}
          </RCTextWithIcon>
        </div>
        {headerAction && (
          <div>
            {headerAction}
          </div>
        )}
      </div>
      <div className="shop-campaign-coupon-preview__body">
        {children}

        {!hideTermsLink && (
          <div className="shop-campaign-coupon-terms__link">
            <small>
              <CoreLink
                to={URLHelpers.legalPagePath('promotional-terms-and-conditions')}
                target="_blank"
              >
                {I18n.t('discovery.dashboard.selling.shopCampaigns.couponPreview.seeTerms')}
              </CoreLink>
            </small>
          </div>
        )}
      </div>
    </div>
  );
}

export default ShopCampaignCouponPreview;
