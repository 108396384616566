import PropTypes from 'prop-types';
import React from 'react';
import { RCTooltip } from '@reverbdotcom/cadence/components';

function LabelWithTooltip({ tooltip, label }) {
  if (!tooltip) return label;

  return (
    <RCTooltip text={tooltip}>
      {label}
    </RCTooltip>
  );
}

function SubStat({ label, datum, tooltip }) {
  return (
    <div className="sub-stat">
      <div className="stat-title">
        <LabelWithTooltip tooltip={tooltip} label={label} />
      </div>
      <div
        className="stat-data"
        dangerouslySetInnerHTML={{ __html: datum }}
      />
    </div>
  );
}

SubStat.propTypes = {
  label: PropTypes.string.isRequired,
  datum: PropTypes.string,
  tooltip: PropTypes.string,
};

export default SubStat;
