import React from 'react';
import I18n from 'i18n-js';
import {
  core_apimessages_TaxIdentification_TaxType as TaxType,
} from '@reverbdotcom/commons/src/gql/graphql';
import FormGroupWithInput from '@reverbdotcom/commons/src/components/form_group_with_input';
import FormGroupWithSelect, { ISelectOption } from '@reverbdotcom/commons/src/components/form_group_with_select';
import ModalDialog from '@reverbdotcom/commons/src/components/modal_dialog';
import ShopSettingsVatIdSaveError from './shop_settings_vat_id_save_error';
import vatIdHelpText from './shop_settings_vat_id_help_text';
import { CreateMutationProps, withCreateTaxIdentification } from './shop_settings_tax_identification_mutations';
import { connect } from '@reverbdotcom/commons/src/connect';

interface ExternalProps {
  countryOptions: ISelectOption[];
  isOpen: boolean;
  closeForm: () => void;
}

type Props = ExternalProps & CreateMutationProps;

export function ShopSettingsCreateVatIdForm(props: Props) {
  const {
    countryOptions,
    isOpen,
    closeForm,
    createTaxIdentification,
  } = props;

  const [countryCode, setCountryCode] = React.useState('');
  const [taxId, setTaxId] = React.useState('');
  const [error, setError] = React.useState('');

  async function create() {
    try {
      const response = await createTaxIdentification({
        variables: {
          input: {
            taxId,
            countryCode,
            taxType: TaxType.VAT,
          },
        },
      });

      if (response) {
        clearAndCloseForm();
      }
    } catch (e) {
      setError(I18n.t('discovery.shopSettings.vat.saveFailed'));
    }
  }

  function clearAndCloseForm() {
    setTaxId('');
    setCountryCode('');
    closeForm();
  }

  return (
    <ModalDialog
      isOpen={isOpen}
      onRequestClose={closeForm}
      headerTitle={I18n.t('discovery.shopSettings.vat.addVat')}
      saveButtonText={I18n.t('discovery.shopSettings.vat.save')}
      onSubmit={create}
    >
      <ShopSettingsVatIdSaveError
        countryCode={countryCode}
        error={error}
      />
      <FormGroupWithSelect
        inputName="countryCode"
        fieldName="countryCode"
        label={I18n.t('discovery.shopSettings.vat.countryCode')}
        value={countryCode}
        updateField={(field) => setCountryCode(field.countryCode)}
        options={countryOptions}
        required
        addEmptyOption
        helpText={I18n.t('discovery.shopSettings.vat.countryAlreadyUsed')}
      />
      <FormGroupWithInput
        inputName="taxId"
        fieldName="taxId"
        inputType="text"
        label={I18n.t('discovery.shopSettings.vat.vatId')}
        value={taxId}
        updateField={(field) => setTaxId(field.taxId)}
        pattern="[a-zA-Z0-9]+"
        helpText={vatIdHelpText(countryCode, countryOptions)}
        required
      />
    </ModalDialog>
  );
}

export default connect<ExternalProps>([
  withCreateTaxIdentification,
])(ShopSettingsCreateVatIdForm);
