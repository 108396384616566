import React, { useState } from 'react';
import { useQuery } from '@reverbdotcom/commons/src/useQuery';
import I18n from 'i18n-js';
import { RCTextWithIcon } from '@reverbdotcom/cadence/components';
import CouponIcon from '@reverbdotcom/cadence/images/nudge/coupon.svg?component';
import ShopCampaignCouponPreview, { buildFinePrint } from '../../selling/shop_campaigns/ShopCampaignCouponPreview';
import { core_apimessages_ShopCampaignCoupon_Status } from '@reverbdotcom/commons/src/gql/graphql';
import { ModalDialog } from '@reverbdotcom/commons';
import { gql } from '@reverbdotcom/commons/src/gql/gql';

const cartCouponsQuery = gql(`
  query Core_Cart_MyCouponList {
    me {
      _id
      uuid
      buyerCoupons(input: { context: CART }) {
        shopCampaignCoupons {
          _id
          code
          descriptionSummary
          restrictionsSummary
          discountValue
          couponHeader
          status
          excludedBrands {
            _id
            name
          }
          excludedCategories {
            _id
            name
          }
        }
      }
    }
}`);

function CouponAccordion({ coupon }) {
  const [showDetails, setShowDetails] = useState(false);

  return (
    <ShopCampaignCouponPreview
      headerText={coupon.couponHeader}
      hideTermsLink={!showDetails}
    >
      <div>
        <span className="mr-2">
          {coupon.descriptionSummary}
        </span>
        <button
          type="button"
          className="button-as-link td-underline color-secondary"
          onClick={() => setShowDetails(!showDetails)}>
          {showDetails ? I18n.t('discovery.shopCoupons.hideDetails') : I18n.t('discovery.shopCoupons.showDetails')}
        </button>
      </div>

      {showDetails && (
        <div className="mt-3">
          <p className="size-90">
            {
              buildFinePrint(
                coupon.restrictionsSummary,
                coupon.excludedBrands.map(({ name }) => name),
                coupon.excludedCategories.map(({ name }) => name),
              )
            }
          </p>
        </div>
      )}
    </ShopCampaignCouponPreview>
  );
}

export function CartCoupons() {
  const {
    loading,
    data,
  } = useQuery(cartCouponsQuery);
  const [showCouponsModal, setShowCouponsModal] = useState(false);

  if (!data || loading) {
    return null;
  }
  const coupons = data.me?.buyerCoupons?.shopCampaignCoupons ?? [];
  const eligibleCoupons = coupons.filter((coupon) => coupon.status === core_apimessages_ShopCampaignCoupon_Status.ELIGIBLE);

  if (eligibleCoupons.length < 1) {
    return null;
  }

  return (
    <>
      <div className="cart-coupons__callout">
        <span className="cart-coupons__callout-text">
          <RCTextWithIcon svgComponent={CouponIcon} placement="left">
            <span className="cart-coupons__coupons-available">
              {I18n.t('discovery.shopCoupons.couponsAvailable')}
            </span>
            <span className="cart-coupons__apply-coupons">
              {I18n.t('discovery.shopCoupons.applyCoupons')}
            </span>
          </RCTextWithIcon>
        </span>

        <button type="button" className="cart-coupons__view-coupons" onClick={() => setShowCouponsModal(true)}>
          {I18n.t('discovery.shopCoupons.viewCoupons')}
        </button>
      </div>
      <ModalDialog
        isOpen={showCouponsModal}
        headerTitle={I18n.t('discovery.shopCoupons.eligibleCoupons')}
        onSubmit={() => setShowCouponsModal(false)}
        onRequestClose={() => setShowCouponsModal(false)}
        saveButtonText={I18n.t('discovery.shopCoupons.gotIt')}
        hideFooterDismissButton
      >
        {eligibleCoupons.map((coupon) => <CouponAccordion key={coupon._id} coupon={coupon} />)}
      </ModalDialog>
    </>
  );
}
