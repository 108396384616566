import React from 'react';
import classNames from 'classnames';
import {
  LineChart,
  Interpolation,
  easings,
  SeriesObject,
  LineChartData,
  LineChartOptions,
} from 'chartist';

/**
* A generic line chart
* usage: price_history_graph_container.tsx
* options examples: https://github.com/chartist-js/chartist
* chartData example:
*  {
*    labels: ['W1', 'W2', 'W3', 'W4', 'W5'],
*    series: [[1, 2, 3, 4, 5]]
*  }
* tooltipCallback: expects an html string
* labelTiltDegrees: tilts the x-axis labels 30, 45, or 90 degrees
* ariaLabel & ariaDescription: strongly encouraged to use
*/

type LabelTiltDegrees = 30 | 45 | 90;

interface IProps {
  chartData: LineChartData;
  chartOptions?: LineChartOptions;
  tooltipCallback?: (meta: SeriesObject) => string;
  animate?: boolean;
  labelTiltDegrees?: LabelTiltDegrees;
  ariaLabel: string;
  ariaDescription?: string;
}

export function buildTooltip(data, tooltipCallback) {
  if (data.type === 'point') {
    const { meta } = data;
    const tooltipElements = Array.from(document.getElementsByClassName('line-graph--tooltip') as HTMLCollectionOf<HTMLElement>);
    const [tooltip] = tooltipElements;

    const openEvents = ['mouseenter', 'focus'];
    const closeEvents = ['mouseleave', 'blur'];

    openEvents.forEach(openEvent => {
      data.element._node.addEventListener(openEvent, () => {
        const chartWidth = data.chartRect.x2 - data.chartRect.x1;

        tooltip.classList.remove('line-graph--tooltip__hidden');
        tooltip.style.top = data.y - 50 + 'px';
        tooltip.style.left = data.x < chartWidth / 2 ? data.x + 'px' : data.x - tooltip.offsetWidth - 30 + 'px';
        tooltip.innerHTML = tooltipCallback(meta);
      });
    });

    closeEvents.forEach(closeEvent => {
      data.element._node.addEventListener(closeEvent, () => {
        tooltip.classList.add('line-graph--tooltip__hidden');
      });
    });
  }
}

export function svgPathAnimation(data) {
  if (data.type === 'line' || data.type === 'area') {
    data.element.animate({
      d: {
        begin: 2000 * data.index,
        dur: 2000,
        from: data.path
          .clone()
          .scale(1, 0)
          .translate(0, data.chartRect.height())
          .stringify(),
        to: data.path.clone().stringify(),
        easing: easings.easeOutQuint,
      },
    });
  }
}

export function buildLineGraph({
  chartData,
  chartOptions,
  graphRef,
  tooltipCallback,
  animate = false,
}) {

  const defaultChartOptions = {
    fullWidth: true,
    lineSmooth: Interpolation.cardinal({
      fillHoles: true,
    }),
    axisX: {
      showGrid: true,
      showLabel: true,
    },
    axisY: {
      showGrid: true,
      showLabel: true,
    },
    classNames: {
      label: 'line-graph__label',
      vertical: 'line-graph__vertical',
      horizontal: 'line-graph__horizontal',
    },
    ...chartOptions,
  };

  return new LineChart(graphRef.current, chartData, defaultChartOptions).on('draw', (data) => {
    if (animate) svgPathAnimation(data);
    if (tooltipCallback) buildTooltip(data, tooltipCallback);
  });
}

export default function LineGraph({
  chartData,
  chartOptions,
  tooltipCallback,
  labelTiltDegrees,
  animate,
  ariaLabel,
  ariaDescription,
}: IProps) {

  const graphRef = React.useRef();
  const [graph, setGraph] = React.useState(null);

  React.useEffect(() => {
    if (graph) {
      graph.update(chartData);
    } else {
      setGraph(buildLineGraph({
        chartData,
        chartOptions,
        graphRef,
        tooltipCallback,
        animate,
      }));
    }
  }, [chartData]);

  const classes = classNames(
    'line-graph',
    { 'line-graph__label--30': labelTiltDegrees === 30 },
    { 'line-graph__label--45': labelTiltDegrees === 45 },
    { 'line-graph__label--90': labelTiltDegrees === 90 },
  );

  return (
    <div className="line-graph-parent">
      {tooltipCallback &&
        <div className="line-graph--tooltip line-graph--tooltip__hidden" />
      }
      {/* eslint-disable-next-line */}
      <div ref={graphRef} className={classes} aria-label={ariaLabel} aria-description={ariaDescription} />
      {/* aria-description is a valid aria - lint fixed in react 18 */}
    </div>
  );
}
