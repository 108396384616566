import MobileDetection from '../../reverb-js-common/mobile_detection';
import { get } from 'lodash';
import { Input_core_apimessages_BumpKey } from '@reverbdotcom/commons/src/gql/graphql';

import BLANK_MEDIUM_IMAGE from '../../../images/products/blank_medium.jpg';

const DIGITAL_LISTING_UUID = 'e71b6e43-7217-4746-b4e3-64bf708a2664';

interface Price {
  amount: string;
  amount_cents: number;
  currency: string;
  symbol: string;
  display: string;
}

interface ListingState {
  slug: string;
  description: string;
}

interface Ribbon {
  display: string;
  reason: string;
}

export interface APIListing {
  id: string;
  title: string;
  description: string;
  price_drop?: { original_price: Price };
  state: ListingState;
  auction: boolean;
  _links: any;
  images: any[];
  photos: any[];
  bumped: boolean;
  admin: {
    bumped?: boolean;
    curated_set_id?: string;
    featured_in_set?: boolean;
  };
  condition: {
    display_name: string;
    slug: string;
    uuid: string;
  };
  sale_ribbon: {
    display: string;
    value: string;
  };
  price: Price;
  sale_price?: Price;
  buyer_price: Price;
  original_price?: Price;
  ribbon?: Ribbon;
  saved_search_title?: string;
  curated_set_notes: string;
  feed_source?: string;
  category_uuids: string[];
  watching?: boolean;
  free_expedited_shipping: boolean;
}

export default class Listing implements APIListing {
  id: string;

  title: string;

  description: string;

  price_drop?: { original_price: Price };

  state: ListingState;

  auction: boolean;

  _links: any;

  images: any[];

  photos: any[];

  bumped: boolean;

  admin: {
    bumped?: boolean;
    curated_set_id?: string;
    featured_in_set?: boolean;
  };

  bump_key?: Input_core_apimessages_BumpKey;

  condition: {
    display_name: string;
    slug: string;
    uuid: string;
  };

  sale_ribbon: { display: string; value: string };

  price: Price;

  buyer_price: Price;

  original_price: Price;

  ribbon: Ribbon;

  saved_search_title?: string;

  curated_set_notes: string;

  feed_source?: string;

  category_uuids: string[];

  watching?: boolean;

  free_expedited_shipping: boolean;

  constructor(listingJSON: APIListing) {
    Object.assign(this, listingJSON);
  }

  link(name: string): string {
    return get(this._links, `${name}.href`);
  }

  get largeImage() {
    if (this.photos.length === 0) {
      return BLANK_MEDIUM_IMAGE;
    }

    if (MobileDetection.isMobileBrowser()) {
      return this.photos[0]._links.mobile_small.href;
    }

    return this.photos[0]._links.medium_square.href;
  }

  get smallCropImage() {
    if (this.photos.length === 0) {
      return BLANK_MEDIUM_IMAGE;
    }

    return this.photos[0]._links.small_crop.href;
  }

  get bumpClickTrackingHref() {
    if (!this.bumped || !this._links.track_click) {
      return;
    }

    return this._links.track_click.href;
  }

  get closed() {
    return this.state.slug !== 'live';
  }

  get isDigitalListing() {
    return this.category_uuids.includes(DIGITAL_LISTING_UUID);
  }
}
