import React from 'react';
import I18n from 'i18n-js';
import { core_apimessages_Country as ICountry } from '../gql/graphql';
import { SUBREGION_CODES, subregionName } from './shipping_region';
import { RCSelect } from '@reverbdotcom/cadence/components';

interface IProps {
  countries: ICountry[];
  onChange: React.ChangeEventHandler<HTMLSelectElement>;
  value: string;
}

function countryIsNotUS(country: ICountry) {
  return country.countryCode !== 'US';
}

export function ShippingRegionSelector({ countries, onChange, value }: IProps) {
  const filteredCountries = countries.filter(countryIsNotUS);

  return (
    <RCSelect
      id="offers-shippingRegionCode"
      name="shippingRegionCode"
      label={I18n.t('commons.offers.location.shippingRegionLabel')}
      onChange={onChange}
      value={value}
      required
      addEmptyOption
    >
      <RCSelect.OptionGroup>
        {SUBREGION_CODES.map((code) => (
          <RCSelect.Option
            key={code}
            label={subregionName(code)}
            value={code}
          />
        ))}
      </RCSelect.OptionGroup>
      <RCSelect.OptionGroup>
        {filteredCountries.map(({ countryCode, name }) => (
          <RCSelect.Option
            key={countryCode}
            label={name}
            value={countryCode}
          />
        ))}
      </RCSelect.OptionGroup>
    </RCSelect>
  );
}
