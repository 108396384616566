import { core_apimessages_ShippingLabelPackage } from '@reverbdotcom/commons/src/gql/graphql';

import { CarrierCalculatedPackageValidatorResponse } from './carrierCalculatedPackageValidator';

export const UPS_HEAVY_PACKAGE_STICKER_REQUIRED = 70; // pounds. Over 70 pounds requires a Heavy Package sticker.
export const UPS_LONGEST_SIDE_INCHES = 96;
const LENGTH_PLUS_GIRTH_INCHES = 130;

function sortSides(packageEntry: core_apimessages_ShippingLabelPackage): number[] {
  const { length, width, height } = packageEntry;
  const lengthInches = Number(length?.value);
  const widthInches = Number(width?.value);
  const heightInches = Number(height?.value);

  return [
    Math.ceil(lengthInches),
    Math.ceil(widthInches),
    Math.ceil(heightInches),
  ].sort((a, b) => a - b);
}

function lengthPlusGirth(packageEntry: core_apimessages_ShippingLabelPackage): number {
  const [shortSide1, shortSide2, longestSide] = sortSides(packageEntry);

  return longestSide + (2 * shortSide1) + (2 * shortSide2);
}


// For domestic shipments, a package is considered a “Large Package” when its length (longest side of the package) plus girth [(2 x width) + (2 x height)] combined exceeds 130 inches or its length exceeds 96 inches.
// Large Packages are subject to a minimum billable weight of 90 pounds. An Additional Handling charge will not be assessed when a Large Package Surcharge is applied.
function isLargePackageDimensional(packageEntry: core_apimessages_ShippingLabelPackage): boolean {
  return lengthPlusGirth(packageEntry) > LENGTH_PLUS_GIRTH_INCHES;
}

function isLargePackageLength(packageEntry: core_apimessages_ShippingLabelPackage): boolean {
  const longestSide = sortSides(packageEntry)[2];
  return longestSide > UPS_LONGEST_SIDE_INCHES;
}

function requiresHeavyPackageSticker(packageEntry: core_apimessages_ShippingLabelPackage): boolean {
  const { weight } = packageEntry;
  const weightPounds = Number(weight?.value);

  return Math.ceil(weightPounds) > UPS_HEAVY_PACKAGE_STICKER_REQUIRED;
}

export function carrierCalculatedUpsPackageValidator(packageEntry: core_apimessages_ShippingLabelPackage): CarrierCalculatedPackageValidatorResponse {

  let errorKey;
  if (requiresHeavyPackageSticker(packageEntry)) {
    errorKey = 'exceedsActualWeightLimit';
  } else if (isLargePackageLength(packageEntry)) {
    errorKey = 'exceedsLengthLimit';
  } else if (isLargePackageDimensional(packageEntry)) {
    errorKey = 'exceedsLargePackageLimitUps';
  }

  return {
    errorTranslationKey: errorKey ? `discovery.sellForm.shippingSection.domesticShippingCard.carrierCalculatedWarnings.${errorKey}` : null,
    carrier: 'UPS',
  };
}
