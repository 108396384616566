import React from 'react';

import { Brand } from '../gql/graphql';
import CoreLink from './core_link';
import { ArrowRightIcon } from '@reverbdotcom/cadence/icons/react';

interface IProps {
  brand?: Brand;
  position?: number;
  linkOverride?: string;
  inMobileApp?: boolean;
}

export default class BrandCard extends React.Component<IProps, null> {
  getLink(): string {
    return this.props.linkOverride || this.props.brand.webLink?.href;
  }

  render() {
    if (!this.props.brand) {
      return (
        <div className="brand-card brand-card--placeholder">
          <div className="brand-card__link" />
        </div>
      );
    }

    return (
      <div className="brand-card">
        <CoreLink
          to={this.getLink()}
          position={this.props.position}
          entityId={this.props.brand.id}
          className="brand-card__link"
        >
          <h4 className="brand-card__title">
            {this.props.brand.name}
          </h4>
          <span className="brand-card__icon">
            <ArrowRightIcon />
          </span>
        </CoreLink>
      </div>
    );
  }
}
