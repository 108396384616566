import PropTypes from 'prop-types';
import React from 'react';
import APIRequest from '../../../components/api_request';
import UrlHelpers from '../../../shared/url_helpers';
import I18n from 'i18n-js';

class Form extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      body: '',
      isSubmitting: false,
    };
  }

  handleSubmit = (event) => {
    event.preventDefault();
    if (this.isSubmitDisabled) { return; }

    this.setState({ isSubmitting: true });

    APIRequest.post(
      UrlHelpers.createOrderNotePath,
      JSON.stringify({
        order_id: this.props.orderId.toString(),
        body: this.state.body,
      }),
      { contentType: 'application/json' },
      '4.0',
    ).then((response) => {
      this.setState({
        isSubmitting: false,
        body: '',
      });
      this.props.onNoteAdded(response.order_note);
    });
  };

  handleChangeBody = (event) => {
    this.setState({ body: event.target.value });
  };

  get isSubmitDisabled() {
    return this.state.body.length === 0 || this.state.isSubmitting;
  }

  render() {
    return (
      <form className="order-notes__form" onSubmit={this.handleSubmit}>
        <textarea
          className="order-notes__textarea"
          value={this.state.body}
          onChange={this.handleChangeBody}
        />

        <button
          className="order-notes__submit"
          type="submit"
          disabled={this.isSubmitDisabled}
        >
          {I18n.t('js.orderNotes.submit')}
        </button>
        <div className="order-notes__hint">
          {I18n.t('js.orderNotes.hint')}
        </div>
      </form>
    );
  }
}

Form.propTypes = {
  orderId: PropTypes.string.isRequired,
  onNoteAdded: PropTypes.func.isRequired,
};

export default Form;
