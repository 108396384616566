import React, { useState } from 'react';
import { Paths } from '../shared/url_helpers';
import CsrfForm from '@reverbdotcom/commons/src/csrf_form';
import { RCAccordion, RCAlertBox, RCButton, RCRadioGroup } from '@reverbdotcom/cadence/components';
import RefundAccommodationFormFields from '../../refund_requests/refund_accommodation_form_fields';
import { IRefundState } from '../../refund_requests/refund_request';
import { IAccommodationDataFields } from '@reverbdotcom/commons/src/components/admin_accommodation_data_fields';
import { MoneyInput } from '@reverbdotcom/commons/src/components/money_input';

export const FULL = 'full';
export const PARTIAL = 'partial';
type RefundType = 'full' | 'partial';

interface IProps {
  orderId: number;
  presentmentCurrency: string;
  paymentMethod: string;
  accommodationAuthorized: boolean;
  isAdyenShopWithPayoutsDisabled: boolean;
  categoryToReasonMap: object;
  categoryToPolicyContextMap: object;
  payoutable: boolean;
}

interface IManualRefundFormData extends IAccommodationDataFields {
  refundType: RefundType;
  refundAmount: string;
  isReverbAccommodated: boolean;
}

export function AdminCreateManualRefundForm({
  orderId,
  presentmentCurrency,
  paymentMethod,
  accommodationAuthorized,
  isAdyenShopWithPayoutsDisabled,
  categoryToReasonMap,
  categoryToPolicyContextMap,
  payoutable,
}: IProps) {
  const [formData, setFormData] = useState<IManualRefundFormData>({
    refundType: FULL,
    refundAmount: '',
    isReverbAccommodated: false,
    selectedCategory: '',
    selectedReason: '',
    additionalContext: '',
    selectedPolicyContexts: {},
  });

  const setFormField = (fieldName, value) => {
    setFormData((state) => ({
      ...state,
      [fieldName]: value,
    }));
  };

  const handleRefundTypeChange = (event) => {
    setFormField('refundType', event.target.value);
  };

  const handleRefundAmountChange = (event) => {
    setFormField('refundAmount', event.target.value);
  };

  const toggleReverbAccommodated = (event) => {
    setFormField('isReverbAccommodated', event.target.checked);
  };

  const handleSellerVerb = formData.isReverbAccommodated ? 'accommodate' : 'bill';

  return (
    <RCAccordion
      heading={<span className="size-80 text-link">Record manual {paymentMethod} refund{ payoutable && (` and ${handleSellerVerb} seller`) }</span>}
      id="create-manual-refund"
    >
      <div className="mb-4 bg-color-gray-100 padding-4 bd-2 bd-color-gray-300 bd-radius-sm">
        <CsrfForm
          action={Paths.adminCreateManualOrderRefund.expand({ orderId })}
          method="POST"
        >
          <div className="mb-4">
            <RCAlertBox
              type="warning"
            >
              <p>This form should be used <strong>after the buyer has already been refunded manually</strong> (through the PayPal or Affirm website, etc.).</p>

              <p>Submitting this form will:</p>

              <div className="cms-text">
                <ul>
                  <li>record the refund on Reverb's side</li>
                  { payoutable ? (
                    <li>{handleSellerVerb} the seller</li>
                  ) : (
                    <li>refund fees to the seller</li>
                  )}
                  <li>for full refunds only: mark the order refunded</li>
                </ul>
              </div>
            </RCAlertBox>
          </div>

          <RCRadioGroup
            required
            id="refund_type"
            label="Refund Type"
            value={formData.refundType}
            onChange={handleRefundTypeChange}
            name="refund_type"
          >
            <RCRadioGroup.Option
              label="Full"
              value={FULL}
            />
            <RCRadioGroup.Option
              label="Partial"
              value={PARTIAL}
            />
          </RCRadioGroup>
          { formData.refundType === PARTIAL && (
            <MoneyInput
              helpText="Full amount of payment sent to buyer"
              id="refund_amount"
              name="refund_amount"
              label="Refund Amount"
              onChange={handleRefundAmountChange}
              required
              currencyCode={presentmentCurrency}
              suffixText={presentmentCurrency}
              value={formData.refundAmount}
            />
          )}
          {accommodationAuthorized && (
            <RefundAccommodationFormFields
              accommodationAuthorized={accommodationAuthorized}
              isAdyenShopWithPayoutsDisabled={isAdyenShopWithPayoutsDisabled}
              isReverbAccommodated={formData.isReverbAccommodated}
              toggleReverbAccommodated={toggleReverbAccommodated}
              refundState={IRefundState.APPROVED}
              categoryToReasonMap={categoryToReasonMap}
              categoryToPolicyContextMap={categoryToPolicyContextMap}
              formData={formData}
              setFormField={setFormField}
            />
          )}
          <RCButton
            isSubmit
            variant="filled"
          >
            Record refund{ payoutable && ` and ${handleSellerVerb} seller` }
          </RCButton>
        </CsrfForm>
      </div>
    </RCAccordion>

  );
}

export default AdminCreateManualRefundForm;
