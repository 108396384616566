import React from 'react';

interface Props {
  name: string;
}

export default function USRoutingNumberField(props: Props) {
  return (
    <div>
      <input
        className="string required wide user-success"
        maxLength={9}
        type="text"
        name={props.name}
      />
    </div>
  );
}
