import React from 'react';
import I18n from 'i18n-js';
import PacklinkModal from './packlink_modal';
import window from '@reverbdotcom/commons/src/window_wrapper';
import Location from '../lib/wrapped_location';
import URLHelpers from './shared/url_helpers';
import qs from 'qs';

export const COMPONENT_NAME = 'PacklinkLinkAccountButton';
export const SUCCESS_URL = `${URLHelpers.shippingRatesPath}?success=true`;

export default function PacklinkLinkAccountButton() {
  const query = qs.parse(window.location.search, { ignoreQueryPrefix: true });
  const defaultIsOpen = !!query.open_packlink_modal;
  const [isOpen, setIsOpen] = React.useState(defaultIsOpen);

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  function closeModalAndFlashSuccess() {
    closeModal();
    Location.assign(SUCCESS_URL);
  }

  return (
    <>
      <button className="button-as-link" type="button" onClick={openModal}>
        {I18n.t('discovery.packlink.linkAccount')}
      </button>
      <PacklinkModal
        isOpen={isOpen}
        onRequestClose={closeModal}
        onAuthSuccess={closeModalAndFlashSuccess}
      />
    </>
  );
}
