export default {
  USD: { currencyFullName: 'United States Dollar', currencyShortName: 'USD' },
  CAD: { currencyFullName: 'Canadian Dollar', currencyShortName: 'CAD' },
  EUR: { currencyFullName: 'Euro', currencyShortName: 'EUR' },
  GBP: { currencyFullName: 'British Pound', currencyShortName: 'GBP' },
  AUD: { currencyFullName: 'Australian Dollar', currencyShortName: 'AUD' },
  JPY: { currencyFullName: 'Japanese Yen', currencyShortName: 'JPY' },
  NZD: { currencyFullName: 'New Zealand Dollar', currencyShortName: 'NZD' },
  MXN: { currencyFullName: 'Mexican Peso', currencyShortName: 'MXN' },
};
