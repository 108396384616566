/* eslint-disable indent, @typescript-eslint/indent */
import React from 'react';

// TODO: mapAjaxToProps doesn't refetch on prop updates. We should
// implement that, giving consumers a way to determine if an update requires a refetch

const mapAJAXToProps = <ExternalProps extends {}, InjectedProps extends {} = {}>(
  fetcher: (props: ExternalProps) => Promise<InjectedProps>,
) => (Component: React.ComponentType<Partial<ExternalProps> & InjectedProps>, name?: string) => {
  const result = class Connected extends React.Component<ExternalProps, InjectedProps> {
    static displayName = `AJAX${name}`;

    static WrappedComponent = Component;

    componentDidMount() {
      const req = fetcher(this.props);

      req.then((res) => {
        this.setState(res);
      });
    }

    render() {
      return <Component {...this.props} {...this.state} />;
    }
  };

  return result;
};

export default mapAJAXToProps;
