import alt, { AltStoreModel } from '../components/alt';
import AppConfigActions from './app_config_actions';

class AppConfigStore extends AltStoreModel {
  constructor() {
    super();
    this.state = {};

    this.bindListeners({
      handleConfigChange: AppConfigActions.SET_CONFIG,
    });
  }

  handleConfigChange(config) {
    this.setState(config);
  }
}

export default alt.createStore(AppConfigStore, 'AppConfigStore');
