import React from 'react';
import PacklinkAuthorizeAccountOrCreateDraft from './packlink_authorize_account_or_create_draft';
import Modal from '@reverbdotcom/commons/src/components/modal';

interface Props {
  isOpen: boolean;
  onRequestClose(): void;
  onAuthSuccess?(): void;
  onDraftSuccess?(): void;
  orderId?: string;
}

export default function PacklinkModal(props: Props) {
  const {
    isOpen,
    onRequestClose,
    onAuthSuccess,
    onDraftSuccess,
    orderId,
  } = props;

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      paddedBody
    >
      <PacklinkAuthorizeAccountOrCreateDraft
        onAuthSuccess={onAuthSuccess}
        onDraftSuccess={onDraftSuccess}
        orderId={orderId}
      />
    </Modal>
  );
}
