import React from 'react';
import I18n from 'i18n-js';
import OverflowingRow from '../../components/overflowing_row';
import CoreLink from '../../components/core_link';
import { VideoPlayerSidebar } from './video_player/sidebar';
import { imageUploadURL, VIMEO_VIDEO, YOUTUBE_VIDEO } from '../../url_helpers';
import classNames from 'classnames';
import { IDynamicComponentProps } from '../dynamic_component_props';
import SanitizedRender from '../../components/sanitized_render';

const VIMEO = 'vimeo';

export interface IProps extends IDynamicComponentProps {
  slug: string;
  caption?: string;
  captionHtml?: string;
  description?: string;
  descriptionHtml?: string;
  narrow?: boolean;
  withBackground?: boolean;
  platform?: string;
  sidebarVideos?: any[];
  titleOverride?: string;
}

function videoUrl(slug: string, platform: string): string {
  if (platform === VIMEO) {
    return VIMEO_VIDEO.expand({ slug });
  }

  return YOUTUBE_VIDEO.expand({ slug });
}

export function VideoPlayer(props: IProps) {
  const url = videoUrl(props.slug, props.platform);

  const classes = classNames(
    'video-embed',
    { 'video-embed--narrow': props.narrow },
    { 'video-embed--with-description': !!props.descriptionHtml },
    { 'video-embed--with-background': props.withBackground },
  );

  return (
    <div className={classes}>
      <div className="video-embed__wrapper">
        <div className="video-embed__overhang">
          <div className="video-embed__columns">
            <div className="video-embed__column">
              <div className="video-embed__inner" itemProp="videoObject" itemScope itemType="http://schema.org/VideoObject">
                <meta itemProp="embedUrl" content={url} />
                <iframe
                  title={props.caption || props.captionHtml}
                  src={url}
                  frameBorder="0"
                  allowFullScreen
                />
              </div>
            </div>
            <div className="video-embed__column">
              <SanitizedRender
                className="video-embed__caption"
                html={props.captionHtml || props.caption}
                htmlTag="div"
              />
              <SanitizedRender
                className="video-embed__description"
                html={props.descriptionHtml || props.description}
                htmlTag="div"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function getSelectedVideo(slugs: string[], slug: string): string | null {
  return slugs.find(v => v === slug);
}

function findSidebarVideo(slug: string, sidebarVideos: any[]) {
  return sidebarVideos.find(i => i.sidebarSlug === slug);
}

export function VideoPlayerWithSidebar(props: IProps) {
  const [videoDataResponse, setVideoDataResponse] = React.useState([]);
  const videoSlugs = [props.slug].concat(props.sidebarVideos.map(v => v.sidebarSlug));
  const [selectedVideo, setSelected] = React.useState(videoSlugs[0]);

  React.useEffect(() => {
    const fetchVideoData = async () => {
      const sidebarVideoData = videoSlugs.map(slug => {
        const videoSlug = findSidebarVideo(slug, props.sidebarVideos)?.imageOverride;
        const thumbnail = videoSlug ? imageUploadURL(videoSlug) : null;
        const title = slug === props.slug ?
          props?.titleOverride : findSidebarVideo(slug, props.sidebarVideos)?.titleOverride;

        return ({
          id: slug,
          thumbnail,
          title,
        });
      });

      setVideoDataResponse(sidebarVideoData);
    };

    fetchVideoData();
  }, []);

  const reverbYouTubeLink = 'https://youtube.com/c/Reverb';
  const reverbYouTubeLinkText = I18n.t('cms.videoPlayer.viewMore');

  return (
    <OverflowingRow
      title={props.caption}
      action={
        <CoreLink
          to={reverbYouTubeLink}
          target="_blank"
        >
          {reverbYouTubeLinkText}
        </CoreLink>
      }
    >
      <div className="video-player__wrapper">
        <div className="video-player">
          <VideoPlayer
            slug={selectedVideo}
          />
        </div>
        {videoSlugs.length > 1 && videoDataResponse.length > 1 &&
          <VideoPlayerSidebar
            videoSlugs={videoSlugs}
            videoData={videoDataResponse}
            selectedVideoSlug={selectedVideo}
            onClick={(slug) => setSelected(getSelectedVideo(videoSlugs, slug))}
          />
        }
      </div>
    </OverflowingRow>
  );
}

export default function VideoEmbed(props: IProps) {
  if (!props.slug) {
    return null;
  }

  const sidebarVideos = props.sidebarVideos || [];

  if (!sidebarVideos.length) {
    return <VideoPlayer {...props} />;
  }

  return <VideoPlayerWithSidebar {...props} sidebarVideos={sidebarVideos} />;
}
