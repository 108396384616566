import React, { useContext, useEffect } from 'react';
import I18n from 'i18n-js';
// TODO update this to import from commons
// import { gql } from '@reverbdotcom/commons/src/gql';
// eslint-disable-next-line no-restricted-imports
import { gql, useApolloClient } from '@apollo/client';
import { RCButton, RCIconButton, RCTextWithIcon } from '@reverbdotcom/cadence/components';
import { TrashIcon } from '@reverbdotcom/cadence/icons/react';
import { useMutation } from '@reverbdotcom/commons/src/useMutation';
import { CoreListingDeleteDraft, Listing } from '@reverbdotcom/commons/src/gql/graphql';
import { MParticleEventName, trackEvent } from '@reverbdotcom/commons/src/elog/mparticle_tracker';
import SellerListingsCollectionContext from '../SellerListingsCollectionContext';
import { ActionType } from '../sellerListingsCollectionReducer';

interface IDeleteButtonProps {
  setDraftDeleteErrors: React.Dispatch<React.SetStateAction<Array<string>>>
  listing: Listing;
}

export const COMPONENT_NAME = 'DeleteButton';

function DeleteButton({ listing, setDraftDeleteErrors }: IDeleteButtonProps) {
  const apolloClient = useApolloClient();
  const [
    deleteDraftListing,
    {
      data: mutationData,
      loading: mutationDataLoading,
      errors: mutationErrors,
    },
  ] =
    useMutation<
      CoreListingDeleteDraft.Mutation,
      CoreListingDeleteDraft.Variables
    >(DELETE_DRAFT_MUTATION);

  const {
    dispatch: selectionDispatch,
  } = useContext(SellerListingsCollectionContext);


  useEffect(() => {
    const { isFinishedLoading, hasData, hasErrors } = checkResponse(
      mutationDataLoading,
      mutationData,
      mutationErrors,
    );

    const hasAPIResponse = isFinishedLoading && (hasData || hasErrors);

    if (!hasAPIResponse) return;

    if (hasErrors) {
      selectionDispatch({
        type: ActionType.RESET_LOADING,
        payload: {
          listingIds: [listing.id],
        },
      });

      handleErrors();
    } else {
      selectionDispatch({
        type: ActionType.REMOVE_LISTINGS,
        payload: {
          listingIds: [listing.id],
        },
      });

      evictFromCache();
    }
  }, [mutationDataLoading]);

  async function startDelete() {
    const confirmMessage = I18n.t(
      'discovery.listingsManagement.sellerListingCard.deleteDraftConfirm',
      {
        title: listing.title,
      },
    );

    if (!confirm(confirmMessage)) return;

    selectionDispatch({
      type: ActionType.LOAD_LISTINGS,
      payload: {
        listingIds: [listing.id],
      },
    });

    await deleteDraftListing({
      variables: {
        input: {
          listingId: listing.id,
        },
      },
    });
  }

  function checkResponse(
    dataLoading: boolean,
    data: unknown,
    errors: string | any[],
  ) {
    return {
      isFinishedLoading: !dataLoading,
      hasData: !!data,
      hasErrors: !!errors?.length,
    };
  }

  function handleErrors() {
    const [rawErrors] = mutationErrors;
    const parsedErrors = JSON.parse(rawErrors.message);
    setDraftDeleteErrors(parsedErrors);
  }

  function evictFromCache() {
    apolloClient.cache.evict({
      id: apolloClient.cache.identify({
        __typename: 'Listing',
        id: listing.id,
      }),
    });
    apolloClient.cache.gc();
  }

  function handleClick() {
    startDelete();
    trackEvent({
      eventName: MParticleEventName.ClickedComponent,
      componentName: COMPONENT_NAME,
      listingId: listing.id,
    });
  }

  return (
    <>
      <div className="tablet-d-none">
        <RCButton onClick={handleClick} variant="muted" fullWidth>
          <RCTextWithIcon svgComponent={TrashIcon} placement="right">
            {I18n.t('discovery.listingsManagement.sellerListingCard.deleteDraft')}
          </RCTextWithIcon>
        </RCButton>
      </div>
      <div className="seller-listing-card-footer__tablet-button-container">
        <RCIconButton
          onClick={handleClick}
          label={I18n.t('discovery.listingsManagement.sellerListingCard.deleteDraft')}
          svgComponent={TrashIcon}
          variant="muted"
        />
      </div>
    </>
  );
}

const DELETE_DRAFT_MUTATION = gql`
  mutation Core_Listing_DeleteDraft(
    $input: Input_core_apimessages_DraftListingDeleteRequest
  ) {
    deleteDraftListing(input: $input) {
      listingId
    }
  }
`;

export default DeleteButton;
