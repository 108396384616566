import { unionBy } from 'lodash';
import { convertMoney } from '@reverbdotcom/commons/src/convert_currency';
import { UniversalPromoCampaign, Core_Universal_Promo_CampaignQuery, reverb_config_cache_ExchangeRate } from '@reverbdotcom/commons/src/gql/graphql';

export interface DisplayCurrency {
  currency: string;
  amount: string;
  minPurchaseAmount: string;
}

interface CategoryFromResponse {
  name: string;
  uuid: string;
  root_uuid: string;
}

interface DisplayCategory {
  name: string;
  uuid: string;
  checked: boolean;
}

interface CategoryForRequest {
  id: string;
}

const INITIAL_CURRENCY_VALUES: DisplayCurrency[] = [
  { currency: 'USD', amount: '', minPurchaseAmount: '' },
  { currency: 'GBP', amount: '', minPurchaseAmount: '' },
  { currency: 'EUR', amount: '', minPurchaseAmount: '' },
  { currency: 'CAD', amount: '', minPurchaseAmount: '' },
  { currency: 'AUD', amount: '', minPurchaseAmount: '' },
  { currency: 'JPY', amount: '', minPurchaseAmount: '' },
];

const NON_PUBLIC_CATEGORIES_TO_SHOW = [
  {
    root_uuid: 'e71b6e43-7217-4746-b4e3-64bf708a2664',
    uuid: 'e71b6e43-7217-4746-b4e3-64bf708a2664',
    name: 'Software',
  },
];

export const getDisplayCategories = (categories: CategoryFromResponse[], campaign: UniversalPromoCampaign): DisplayCategory[] => {
  const campaignCategoriesUuids = campaign ? campaign.categories.map(cat => cat.id) : [];

  return [...categories, ...NON_PUBLIC_CATEGORIES_TO_SHOW]
    .filter(cat => cat.root_uuid === cat.uuid)
    .map(cat => ({
      name: cat.name,
      uuid: cat.uuid,
      checked: campaignCategoriesUuids.includes(cat.uuid) || false,
    }));
};

export const getDisplayCurrencyValues = (campaignCurrencyValues: Core_Universal_Promo_CampaignQuery['universalpromocampaign']['universalPromoCurrencies']): DisplayCurrency[] =>
  unionBy(fillCurrencyValues(campaignCurrencyValues), INITIAL_CURRENCY_VALUES, 'currency');

export const fillCurrencyValues = (campaignValues = []) => campaignValues.map(campaignValue => ({
  currency: campaignValue.amount.currency,
  amount: campaignValue.amount.amount.toString(),
  minPurchaseAmount: campaignValue.minPurchaseAmount.amount.toString(),
}));

export const getCurrenciesForSubmit = (currencyValues: DisplayCurrency[]): Core_Universal_Promo_CampaignQuery['universalpromocampaign']['universalPromoCurrencies'] =>
  currencyValues
    .filter(currencyVal => !!currencyVal.amount)
    .map(currencyVal => ({
      amount: {
        currency: currencyVal.currency,
        amount: currencyVal.amount,
      },
      minPurchaseAmount: {
        currency: currencyVal.currency,
        amount: currencyVal.minPurchaseAmount,
      },
    }));

export const getCategoriesForSubmit = (categories: DisplayCategory[]): CategoryForRequest[] =>
  categories.filter(cat => cat.checked).map(cat => ({ id: cat.uuid }));

export const convertCurrencyValues = (
  currencyValues: DisplayCurrency[],
  exchangeRates: reverb_config_cache_ExchangeRate[],
  primaryCurrencyValues: DisplayCurrency,
): DisplayCurrency[] => {
  const { currency, amount, minPurchaseAmount } = primaryCurrencyValues;

  return currencyValues.map(val => {
    const convertedAmount = convertMoney({ amountCents: Number(amount) * 100, currency }, val.currency, exchangeRates);
    const convertedMinPurchaseAmount = convertMoney({ amountCents: Number(minPurchaseAmount) * 100, currency }, val.currency, exchangeRates);

    return {
      ...val,
      amount: Math.round(Number(convertedAmount.amount)).toString(),
      minPurchaseAmount: Math.round(Number(convertedMinPurchaseAmount.amount)).toString(),
    };
  });
};
