import { ChildProps } from '@apollo/client/react/hoc';
import React from 'react';

import { MParticleEventName } from '@reverbdotcom/commons/src/elog/mparticle_types';
import { useViewTracking } from '@reverbdotcom/commons/src/use_tracking';

import { CoreBumpRecommendationListing } from '@reverbdotcom/commons/src/gql/graphql';
import { ProductPageBumpV2 } from './product_page_bump_v2';
import { BUMPED_LISTING_RECOMMENDATION_QUERY } from './bump_listing_query';
import { formatBid } from './bump_helpers';
import { withGraphql } from '@reverbdotcom/commons/src/with_graphql';

interface ExternalProps {
  listingId: string;
  listingSoldWithBump: boolean;
  bid: number;
  impressions?: number;
  salesCount?: number;
  salesSpent?: string;
  salesRevenue?: string;
}

type Props = ChildProps<ExternalProps, CoreBumpRecommendationListing.Query>;

const COMPONENT_NAME = 'ProductPageBump';

export function BumpDisplay(props: Props) {
  const listing = props.data?.listing;
  const isLive = listing?.state === 'live';
  const isDraft = listing?.state === 'draft';

  const shouldRender = (isLive || isDraft) && !props.listingSoldWithBump;

  function shouldShowOpenModal() {
    if (listing?.bumpEligible) { return false; }

    return isDraft;
  }

  const mParticleViewData = {
    componentName: COMPONENT_NAME,
    eventName: MParticleEventName.ViewedBumpDisplay,
    listing,
    startingBumpRatePercent: formatBid(props.bid),
  };

  useViewTracking(mParticleViewData, shouldRender);

  if (!shouldRender) return null;

  return (
    <ProductPageBumpV2
      bid={props.bid}
      listing={listing}
      showModal={shouldShowOpenModal()}
      impressions={props.impressions}
      salesCount={props.salesCount}
      salesSpent={props.salesSpent}
      salesRevenue={props.salesRevenue}
      refetch={() => {}}
    />
  );
}

const withQuery = withGraphql<ExternalProps, CoreBumpRecommendationListing.Query>(
  BUMPED_LISTING_RECOMMENDATION_QUERY,
  {
    options: (ownProps) => {
      return {
        errorPolicy: 'all',
        ssr: false, // not prerendered in core
        variables: {
          id: ownProps.listingId,
        },
      };
    },
  },
);

export default withQuery(BumpDisplay);
