import React from 'react';
import I18n from 'i18n-js';
import { RCGuidePopover } from '@reverbdotcom/cadence/components';
import { useCreateUserAction, CoreUserActionsCreateMutationFn } from '../user_actions/create_action_mutation';
import { availableOfferButtonAction } from './create_offer_button';
import {
  core_apimessages_UserActionName,
  Item2AvailableActionsData,
  CreateUserActionMeData,
  ListingCreateOfferButtonData,
} from '../gql/graphql';

interface IProps {
  anchor: JSX.Element;
  availableActions?: Item2AvailableActionsData.Fragment[];
  createUserAction?: CoreUserActionsCreateMutationFn;
  listing: ListingCreateOfferButtonData.Fragment;
  currentView?: string;
  me?: CreateUserActionMeData.Fragment;
}

export function SellerOpenToOffersPopover({
  anchor,
  listing,
  availableActions,
  createUserAction,
  currentView,
  me,
}: IProps) {
  const isListingPage = currentView === 'listing';
  const loggedInUserActionAvailable = me && availableOfferButtonAction(availableActions);

  const createUserActionState = useCreateUserAction(
    core_apimessages_UserActionName.SELLER_OPEN_TO_OFFERS_CALLOUT,
    me,
    {
      listingId: listing.id,
    },
    createUserAction,
  );

  const [isOpen, setIsOpen] = React.useState(!!loggedInUserActionAvailable && isListingPage);

  function onDismiss() {
    createUserActionState.createUserAction();
    setIsOpen(false);
  }

  return (
    <RCGuidePopover
      title={I18n.t('commons.offers.sellerOpenToOffersPopover.title')}
      content={I18n.t('commons.offers.sellerOpenToOffersPopover.content')}
      isOpen={isOpen}
      onDismiss={onDismiss}
      anchor={anchor}
      preventAutoFocus
    />
  );
}
