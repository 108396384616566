import Window from '@reverbdotcom/commons/src/window_wrapper';

function windowNavigator(): Pick<Navigator, 'userAgent' | 'language' | 'javaEnabled'> {
  if (!(Window && Window.navigator)) {
    return {
      userAgent: '',
      language: '',
      javaEnabled: () => false,
    };
  }
  return Window.navigator;
}

function windowScreen(): Pick<Screen, 'colorDepth' | 'width' | 'height'> {
  if (!(Window && Window.screen)) {
    return {
      colorDepth: null,
      width: null,
      height: null,
    };
  }
  return Window.screen;
}

function browserInfo() {
  const navigator = windowNavigator();
  const screen = windowScreen();

  return {
    screen_width: screen.width,
    screen_height: screen.height,
    color_depth: screen.colorDepth,
    user_agent: navigator.userAgent,
    language: navigator.language,
    java_enabled: navigator.javaEnabled(),
    time_zone_offset: new Date().getTimezoneOffset(),
  };
}

export { browserInfo };
