import React from 'react';
import I18n from 'i18n-js';
import KlarnaPinkLogo from '@reverbdotcom/cadence/images/vendor/klarna-pink-logo.svg?component';

import { RCIcon } from '@reverbdotcom/cadence/components';
import { SanitizedRender } from '@reverbdotcom/commons';

interface Props {
  paymentPlan: string;
  learnMore: React.ReactNode;
  textOnly: boolean;
}

export function KlarnaCallout(props: Props) {
  return (
    <div className="klarna-callout">
      {!props.textOnly &&
        <span className="klarna-callout__icon">
          <RCIcon
            svgComponent={KlarnaPinkLogo}
            title={translate('logoText')}
          />
        </span>
      }
      <div className="klarna-callout__payment-plan">
        <span
          data-klarna-callout-payment-plan
          className="mr-space"
        >
          <SanitizedRender html={props.paymentPlan} />
        </span>
        {props.learnMore}
      </div>
    </div>
  );
}

function translate(key: string, args = {}) {
  return I18n.t(`discovery.klarnaCallout.${key}`, args);
}
