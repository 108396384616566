import { createContext, useState, useContext } from 'react';

export interface CreditCardForm {
  /**
   * HTML Form ID
   */
  id: string;
  isLoading: boolean;
  onSelect: (creditCardId: string) => void;
  setLoading: (loading: boolean) => void;
}

export const CreditCardFormContext = createContext<CreditCardForm>({
  id: null,
  isLoading: false,
  setLoading: () => {},
  onSelect: null,
});

export function useCreditCardForm() {
  return useContext(CreditCardFormContext);
}

type DefaultFormState = Pick<CreditCardForm, 'id' | 'onSelect'>;

export function useCreditCardFormState(defaults: DefaultFormState): CreditCardForm {
  const [isLoading, setLoading] = useState(false);

  return {
    id: defaults.id,
    isLoading,
    setLoading,
    onSelect: defaults.onSelect,
  };
}
