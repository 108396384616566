import { PaymentAction } from '@adyen/adyen-web/dist/types/types';
import React from 'react';
import { SCAState, SCAType } from './adyen_sca_hook';
import SCAChallengeShopper from './sca_challenge_shopper';
import SCARedirectShopper from './sca_redirect_shopper';
import * as elog from '@reverbdotcom/commons/src/elog';

interface Props {
  action: PaymentAction;
  onPaymentDetailResult(threeds2Result: string): void;
}

export default function SCAInteraction(props: Props) {
  function onAdditionalDetails(state: SCAState) {
    elog.info('use_adyen_sca_on_addl_details', { state });

    props.onPaymentDetailResult(state.data.details.threeDSResult);
  }

  if (props.action.type === SCAType.Redirect) {
    return (<SCARedirectShopper action={props.action} />);
  }

  return (
    <SCAChallengeShopper
      action={props.action}
      onAdditionalDetails={onAdditionalDetails}
    />
  );
}
