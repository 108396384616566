import React from 'react';
import I18n from 'i18n-js';
import { RCAlertBox, RCCheckbox } from '@reverbdotcom/cadence/components';
import { IRefundState } from './refund_request';

interface IProps {
  isReverbAccommodated: boolean;
  toggleReverbAccommodated: (event: any) => void;
  refundState: IRefundState;
}

export default function ReverbAccommodationCheckbox(props: IProps) {
  const {
    isReverbAccommodated,
    refundState,
    toggleReverbAccommodated,
  } = props;

  const approved = refundState === IRefundState.APPROVED;

  return (
    <RCCheckbox
      label={I18n.t('discovery.dashboard.refundRequests.admin.formFields.isReverbAccommodated')}
      name="order_refund_request[reverb_accommodated]"
      id="order_refund_request_reverb_accommodated"
      checked={isReverbAccommodated && approved}
      disabled={!approved}
      onChange={toggleReverbAccommodated}
    />
  );
}

export function AdyenPayoutsBlockedWarning() {

  return (
    <div className="scaling-mt-2" id="adyen_payouts_blocked_warning">
      <RCAlertBox type="error">
        <div className="weight-bold size-90 lh-120">
          {I18n.t('discovery.dashboard.refundRequests.admin.formFields.adyenPayoutsBlockedWarning')}
        </div>
      </RCAlertBox>
    </div>
  );
}
