import I18n from 'i18n-js';

declare const Reverb: any;

export function flashSuccess(msg: string) {
  if (globalNotSet()) return;
  if (Reverb?.UI) Reverb.UI.flashSuccess(msg);
}

export function flashError(msg: string) {
  if (globalNotSet()) return;
  if (Reverb?.UI) Reverb.UI.flashError(msg);
}

export function flashExpireAuthError() {
  flashError(I18n.t('commons.flash.error.expiredAuthError'));
}

function globalNotSet() {
  return typeof Reverb === 'undefined';
}
