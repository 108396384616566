import I18n from 'i18n-js';
import { compact } from 'lodash';
import React, { useContext } from 'react';

import { RCAlertBox, RCSelect } from '@reverbdotcom/cadence/components';
import { FormCards } from '@reverbdotcom/commons/src/components/FormCards';

import { SellFormContext } from '../../SellFormContext';
import ShippingSummary from '../../../ShippingSummary';
import { Field, ActionType } from '../../sellFormReducerTypes';

function ShippingProfileCard() {
  const { listingEntry, dispatch, shopConfig, loading, errorMap } = useContext(SellFormContext);

  const { shippingProfiles = [] } = shopConfig;
  const selectedShippingProfile = shippingProfiles.find((sp) => sp.id === listingEntry[Field.SHIPPING_PROFILE_ID]);
  const shippingProfileOptions = [
    { key: 0, value: '', label: I18n.t('discovery.sellForm.shippingSection.profileCard.selectNone') },
    ...shippingProfiles.map((sp) => ({ key: sp.id, value: sp.id, label: sp.name })),
  ];

  const listingCategoryUuids = compact([listingEntry[Field.ROOT_CATEGORY_UUID], ...listingEntry[Field.SUBCATEGORY_LEAF_UUIDS]]);
  const suggestedProfiles = shippingProfiles.filter((sp) => {
    return sp.categoryUuids.some((profileCategory) => listingCategoryUuids.includes(profileCategory));
  });

  return (
    <FormCards.Card>
      <FormCards.FormSection title={I18n.t('discovery.sellForm.shippingSection.profileCard.header')}>
        {!selectedShippingProfile && !!suggestedProfiles.length && (
          <div className="mb-4">
            <RCAlertBox type="info" small>
              <p className="mb-1">
                {I18n.t('discovery.sellForm.shippingSection.profileCard.suggestedText')}
              </p>
              <ul className="price-breakdown_bulleted-list">
                {suggestedProfiles.map((sp) => <li key={sp.id}>{sp.name}</li>)}
              </ul>
            </RCAlertBox>
          </div>
        )}

        <RCSelect
          id="shippingProfileId"
          name="shippingProfileId"
          label={I18n.t('discovery.sellForm.shippingSection.profileCard.select')}
          onChange={(evt) => {
            if (evt.target.value) {
              dispatch({ type: ActionType.SET_SHIPPING_PROFILE_ID, value: evt.target.value });
            } else {
              dispatch({ type: ActionType.SET_NO_SHIPPING });
            }
          }}
          value={listingEntry[Field.SHIPPING_PROFILE_ID]}
          disabled={loading}
          errorText={errorMap[Field.SHIPPING_PROFILE_ID]}
        >
          {shippingProfileOptions.map(({ key, value, label }) => <RCSelect.Option key={key} value={value} label={label}/>)}
        </RCSelect>

        {selectedShippingProfile && (
          <ShippingSummary
            offersShipping={selectedShippingProfile.profileRates.length > 0}
            offersLocalPickup={selectedShippingProfile.localPickup}
            shippingRates={selectedShippingProfile.profileRates}
          />
        )}
      </FormCards.FormSection>
    </FormCards.Card>
  );
}

export default ShippingProfileCard;
