import { ApolloProvider } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { UserContextProvider, IUser } from '@reverbdotcom/commons/src/components/user_context_provider';
import { URLContext, IUrl } from '@reverbdotcom/commons/src/url_context';
import { RoutingResult, RoutingResultContext } from '@reverbdotcom/commons/src/routing_result';
import { buildClient } from './lib/apollo_client';
import routerHistory, { initializeRouterHistory } from '@reverbdotcom/commons/src/cms/lib/router_history';
import { IEppoClient } from '@reverbdotcom/commons/src/eppo/eppoClientContext';
import EppoClientContextProvider from '@reverbdotcom/commons/src/components/eppo/EppoClientContextProvider';
import { ThemeContextProvider } from '@reverbdotcom/commons/src/components/theme_context_provider';
import { Theme } from '@reverbdotcom/cadence/components';

export interface IProps {
  user: IUser;
  client: ReturnType<typeof buildClient>;
  url: string;
  routingResult?: RoutingResult;
  referer: string;
  children?: React.ReactNode;
  eppoClient?: IEppoClient;
  theme?: Theme;
}

export default function AppProvider(props: IProps) {
  const urlFromCore = props.url;
  const pathname = new URL(urlFromCore).pathname;
  const [urlContextObject, setUrlContextObject] = useState<IUrl>(
    initializeRouterHistory(pathname).getCurrentLocation(),
  );

  useEffect(() => {
    routerHistory.listen(location => {
      setUrlContextObject(location);
    });
  }, []);

  return (
    <URLContext.Provider value={{ ...urlContextObject, referer: props.referer }}>
      <RoutingResultContext.Provider value={props.routingResult || {}}>
        <UserContextProvider user={props.user}>
          <EppoClientContextProvider eppoClient={props.eppoClient}>
            <ApolloProvider client={props.client}>
              <ThemeContextProvider theme={props.theme}>
                {props.children}
              </ThemeContextProvider>
            </ApolloProvider>
          </EppoClientContextProvider>
        </UserContextProvider>
      </RoutingResultContext.Provider>
    </URLContext.Provider>
  );
}
