import React from 'react';
import { Paths } from './shared/url_helpers';
import { RCButton } from '@reverbdotcom/cadence/components';
import { Modal } from '@reverbdotcom/commons';
import { trackEvent, MParticleEventName } from '@reverbdotcom/commons/src/elog/mparticle_tracker';
import { useUser } from '@reverbdotcom/commons/src/user_hooks';
import { useViewTracking } from '@reverbdotcom/commons/src/use_tracking';
import LocalePickerModal from './locale_picker/locale_picker_modal';
import { withPrefix } from '@reverbdotcom/commons/src/i18n_helpers';
import I18n from 'i18n-js';
import Currency from '@reverbdotcom/commons/src/currency';
import { shippingRegionName } from '../lib/geo';
import windowWrapper from '@reverbdotcom/commons/src/window_wrapper';

interface Props {
  nagId: string;
}

const t = withPrefix('discovery.acceptLocaleSettings');

export function AcceptLocaleSettingsModal(props: Props) {
  const { shippingRegionCode, currency } = useUser();
  const [isAlertOpen, setAlertOpen] = React.useState(true);
  const [isPickerOpen, setPickerOpen] = React.useState(false);

  useViewTracking({
    eventName: MParticleEventName.ViewedInternationalSettingsConfirmation,
    shippingRegionCode,
    currency,
  }, true);

  function trackRevert() {
    trackEvent({
      eventName: MParticleEventName.InternationalSettingsReverted,
      shippingRegionCode,
      currency,
    });
  }

  function onUpdate() {
    trackRevert();
    hideSettings(props.nagId);
    setPickerOpen(true);
  }

  function onAccept() {
    hideSettings(props.nagId);
    setAlertOpen(false);
  }

  return (
    <div>
      <Modal
        isOpen={isAlertOpen}
        size="wide"
        position="bottom"
        hideOverlay
        allowBackgroundScroll
        paddedBody
      >
        <div className="size-110 weight-bold">
          {t('title')}
        </div>
        <p className="mtb-4 d-flex mobile-fx-dir-col gap-2">
          <span>
            {t('shippingRegionLabel')}
            <span className="ml-space weight-bold">{shippingRegionName(shippingRegionCode)}</span>
          </span>
          <span>
            {t('currencyLabel')}
            <span className="ml-space weight-bold">
              {displayCurrency(currency)}
            </span>
          </span>
        </p>
        <div className="d-flex fx-justify-end fx-wrap gap-2">
          <RCButton
            variant="muted"
            onClick={onUpdate}
          >
            {t('updateButtonText')}
          </RCButton>
          <RCButton
            variant="filled"
            onClick={onAccept}
          >
            {t('acceptButtonText')}
          </RCButton>
        </div>
      </Modal>
      <LocalePickerModal
        isOpen={isPickerOpen}
        onClose={() => setPickerOpen(false)}
      />
    </div>
  );
}

function displayCurrency(currencyCode: string) {
  const { symbol } = Currency.get(currencyCode);

  return I18n.t(`discovery.currencies.${currencyCode}`, { symbol });
}

// Uses jQuery to retain the user context embedded in the DOM
// since the nag alert endpoint is a Rails controller and can't
// communicate via the Authorization header
function hideSettings(nagId: string) {
  windowWrapper.jQuery.post(Paths.closeNagAlert.expand({ nagId }));
}
