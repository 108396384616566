// TODO update this to import from commons/src/gql
// eslint-disable-next-line no-restricted-imports
import { gql } from '@apollo/client';
import React from 'react';
import classNames from 'classnames';

import { kebabCase, difference } from 'lodash';
import SelectFacet from './select_facet';
import TextInputFacet from './text_input_facet';
import { NestedSelectFacet } from './nested_select_facet';
import RangeFacet from './range_facet';
import RegionSelectFilter from './region_select_filter';
import { Filter, FilterProps, WidgetType, isFacetOpenByDefault } from './search_filters';
import { MParticleEventName } from '../elog/mparticle_types';
import { trackEvent } from '../elog/mparticle_tracker';

interface IProps {
  filters?: Filter[];
  loading?: boolean;
  sidebarContent?: React.ReactNode;
  trackingQuery?: string;
  inRCSiteWrapper?: boolean;
  isFilterOpenByDefault?: (filter: Filter) => boolean;
}

interface IState {
  hasLoaded: boolean;
}

interface FacetMapping {
  [widgetType: string]: React.ComponentType<FilterProps>;
}

export const nestedFilterFragment = gql`
  fragment NestedFilter on reverb_search_Filter {
    name
    key
    aggregationName
    widgetType
    options {
      count {
        value
      }
      name
      selected
      autoselected
      paramName
      setValues
      unsetValues
      all
      optionValue
      trackingValue
      subFilter {
        widgetType
        options {
          count {
            value
          }
          name
          selected
          autoselected
          paramName
          setValues
          unsetValues
          all
          optionValue
          trackingValue
          subFilter {
            widgetType
            options {
              count {
                value
              }
              name
              selected
              autoselected
              paramName
              setValues
              unsetValues
              all
              optionValue
              trackingValue
              subFilter {
                widgetType
                options {
                  count {
                    value
                  }
                  name
                  selected
                  autoselected
                  paramName
                  setValues
                  unsetValues
                  all
                  optionValue
                  trackingValue
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const flatFilterFragment = gql`
  fragment FlatFilter on reverb_search_Filter {
    name
    key
    aggregationName
    widgetType
    options {
      count {
        value
      }
      name
      selected
      paramName
      setValues
      unsetValues
      all
      optionValue
    }
  }
`;

const FACET_MAPPING: FacetMapping = {
  [WidgetType.TEXT]: TextInputFacet,
  [WidgetType.NESTED_SELECT]: NestedSelectFacet,
  [WidgetType.CHECKBOX]: SelectFacet,
  [WidgetType.RADIO]: SelectFacet,
  [WidgetType.RANGE]: RangeFacet,
  [WidgetType.REGION_SELECT]: RegionSelectFilter,
  [WidgetType.NONE]: null,
  [WidgetType.SORT]: SelectFacet,
};

const onToggleCallback = (filter: Filter, closed: boolean) => {
  const eventName = closed
    ? MParticleEventName.ClosedCollapsedFilterSection
    : MParticleEventName.OpenCollapsedFilterSection;

  trackEvent({
    eventName,
    filter: filter.name,
  });
};

export default class RQLFacets extends React.Component<IProps, IState> {
  state = {
    hasLoaded: false,
  };

  componentDidUpdate(_prevProps, prevState) {
    if (prevState.hasLoaded) return;

    this.setState({
      hasLoaded: true,
    });
  }

  hasValidFacets() {
    return !!this.filterValid(this.props.filters)?.length;
  }

  filterValid(facets) {
    if (!facets) return null;
    return facets.filter(f => f.options.length);
  }

  getCategoryFacet() {
    const firstFacet = this.props.filters && this.props.filters[0];

    if (firstFacet && firstFacet.widgetType === WidgetType.NESTED_SELECT) {
      return firstFacet;
    }
  }

  getSortByFacet() {
    const sortFilter = this.props.filters && this.props.filters.find(({ key }) => key === 'SORT_BY');

    if (sortFilter && sortFilter.widgetType === WidgetType.SORT) {
      return sortFilter;
    }
  }

  regionFilterKey(facet) {
    const selectedVal = facet.options.filter(o => o.selected)[0]?.optionValue;
    return kebabCase(`${facet.name}-${selectedVal || ''}`);
  }

  renderFacet(facet, trackingQuery) {
    const FacetComponent = FACET_MAPPING[facet.widgetType];
    if (facet.options.length === 0 || !FacetComponent) return null;

    const defaultOpenFunction = this.props.isFilterOpenByDefault ?? isFacetOpenByDefault;

    if (facet.widgetType === WidgetType.REGION_SELECT) {
      return (
        <RegionSelectFilter
          pageSection="sidebar"
          filter={facet}
          key={this.regionFilterKey(facet)}
          trackingQuery={trackingQuery}
          collapsible
          onToggleCallback={(closed) => onToggleCallback(facet, closed)}
          defaultOpen={defaultOpenFunction(facet)}
        />
      );
    }

    return (
      <FacetComponent
        pageSection="sidebar"
        filter={facet}
        key={kebabCase(facet.name)}
        trackingQuery={trackingQuery}
        collapsible
        defaultOpen={defaultOpenFunction(facet)}
        onToggleCallback={(closed) => onToggleCallback(facet, closed)}
      />
    );
  }

  renderNoMarginSpecificFacet(facet : Filter) {
    if (!facet || !facet.options.length) return null;

    if (this.props.inRCSiteWrapper) {
      return this.renderFacet(facet, this.props.trackingQuery);
    }

    return (
      <div className="facet-container facet-container--no-margin">
        {this.renderFacet(facet, this.props.trackingQuery)}
      </div>
    );
  }

  renderRemainingFacets() {
    const result = difference(this.props.filters, [this.getCategoryFacet(), this.getSortByFacet()]);
    const validFacets = this.filterValid(result);

    if (!validFacets || validFacets.length === 0) { return null; }

    if (this.props.inRCSiteWrapper) {
      return validFacets.map((facet) => this.renderFacet(facet, this.props.trackingQuery));
    }

    return (
      <div className="facet-container">
        {validFacets.map((facet) => this.renderFacet(facet, this.props.trackingQuery))}
      </div>
    );
  }

  renderPlaceholder() {
    // TODO this should be different in RCListingGrid
    return (
      <div className="facet-sidebar facet-sidebar--placeholder">
        <div className="facet-container" />
        <div className="facet-container" />
      </div>
    );
  }

  render() {
    if (this.props.loading && !this.state.hasLoaded) {
      return this.renderPlaceholder();
    }

    if (!this.hasValidFacets()) return null;

    const classes = classNames(
      'facet-sidebar',
      { 'facet-sidebar--loading': this.props.loading },
    );

    const categoryFacets = this.getCategoryFacet();
    const sortFacets = this.getSortByFacet();

    if (this.props.inRCSiteWrapper) {
      return (
        <>
          <div className="tablet-d-block d-none">{this.renderNoMarginSpecificFacet(sortFacets)}</div>
          {this.renderNoMarginSpecificFacet(categoryFacets)}
          {this.renderRemainingFacets()}
          {this.props.sidebarContent}
        </>
      );
    }

    return (
      <div className={classes}>
        <div className="facet-sidebar__contents">
          {this.renderNoMarginSpecificFacet(categoryFacets)}
          {this.renderRemainingFacets()}
          {this.props.sidebarContent}
        </div>
      </div>
    );
  }
}
