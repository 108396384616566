import React from 'react';

import bind from '../bind';
import { parse, stringify } from 'qs';

import SearchInputGroup from '../cms/search_input_group';
import { IClientInjectedProps } from '../cms/lib/client_injected_props';

interface IProps extends IClientInjectedProps {
  placeholder?: string;
  defaultValue?: string;
  actionUrl: string;
  searchOverride?: Function;
  largeInput?: boolean;
  trackSearchFn?: (query: string) => void;
  rounded?: boolean;
}

interface IState {
  query: string;
}
export default class CMSSearchInputGroup extends React.Component<IProps, IState> {
  static defaultProps = {
    pushPath: (path: string) => window.location.assign(path),
  };

  componentDidMount() {
    this.setState({
      query: parse(window.location.search.replace(/^\?/, '')).query as string || '',
    });
  }

  state = {
    query: this.props.defaultValue || '',
  };

  onQueryChange = (evt: React.ChangeEvent<any>) => {
    this.setState({ query: evt.target.value });
  };

  search = (evt: React.FormEvent<any>) => {
    evt.preventDefault();
    const query = { query: this.state.query };

    const parts = this.props.actionUrl.split('?');
    const path = parts[0];
    const existingQuery = parse(parts[1]);
    const mergedQuery = stringify({ ...query, ...existingQuery });
    const url = `${path}?${mergedQuery}`;

    this.props.pushPath(url);
  };

  @bind
  handleSearch(evt: React.FormEvent<any>) {
    if (this.props.trackSearchFn) {
      this.props.trackSearchFn(this.state.query);
    }

    if (this.props.searchOverride) {
      this.props.searchOverride(evt, this.state.query);
      return;
    }

    this.search(evt);
  }

  render() {
    return (
      <SearchInputGroup
        onSubmit={this.handleSearch}
        placeholder={this.props.placeholder}
        onChange={this.onQueryChange}
        value={this.state.query}
        large={this.props.largeInput}
        rounded={this.props.rounded}
      />
    );
  }
}
