import React from 'react';
import I18n from 'i18n-js';

import { RCAlertBox, RCSelect } from '@reverbdotcom/cadence/components';
import { core_apimessages_UserShopPermission_PermissionLevel } from '@reverbdotcom/commons/src/gql/graphql';
import { PermissionFormState, permissionLevelToLabel } from './shop_settings_user_management';

interface Props {
  formState: PermissionFormState;
  dispatch: (obj: any) => void;
  errorText?: string;
}

export default function ShopSettingsUserManagementPermissionLevelSelect({ formState, dispatch, errorText = '' }: Props) {
  return (
    <>
      <RCSelect
        id="permissionLevel"
        label={I18n.t('discovery.shopSettings.userManagement.userAccess.form.accessLevel.title')}
        value={formState.permissionLevel}
        onChange={(e) => { dispatch({ type: 'setPermissionLevel', value: e.target.value }); }}
        errorText={errorText}
      >
        {[core_apimessages_UserShopPermission_PermissionLevel.ADMINISTRATOR,
          core_apimessages_UserShopPermission_PermissionLevel.MANAGER].map((level) => {
          return (
            <RCSelect.Option
              label={permissionLevelToLabel(level)}
              value={level}
              key={level}
            />
          );
        })}
      </RCSelect>

      <RCAlertBox type="info">
        <h4 className="weight-bold mb-2">
          {I18n.t('discovery.shopSettings.userManagement.userAccess.form.accessLevel.alertHeader', { permissionLevel: permissionLevelToLabel(formState.permissionLevel) })}
        </h4>

        <ul className="list-type-disc ml-2">
          {formState.permissionLevel == core_apimessages_UserShopPermission_PermissionLevel.MANAGER && (
            <li>{I18n.t('discovery.shopSettings.userManagement.userAccess.form.accessLevel.manageListings')}</li>
          )}

          {formState.permissionLevel == core_apimessages_UserShopPermission_PermissionLevel.ADMINISTRATOR && (
            <>
              <li>{I18n.t('discovery.shopSettings.userManagement.userAccess.form.accessLevel.manageShopPolicies')}</li>
              <li>{I18n.t('discovery.shopSettings.userManagement.userAccess.form.accessLevel.manageListings')}</li>
            </>
          )}
        </ul>
      </RCAlertBox>
    </>
  );
}
