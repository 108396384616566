import React from 'react';
import I18n from 'i18n-js';

import greatValueIcon from '@reverbdotcom/cadence/images/nudge/gem.svg';
import { core_apimessages_Money, reverb_pricing_PriceRecommendation } from '@reverbdotcom/commons/src/gql/graphql';
import classNames from 'classnames';

export const COMPONENT_NAME = 'SellFormGreatValueCallout';
const MINIMUM_PRICE_CENTS = 100;

interface IExternalProps {
  sellerPrice: core_apimessages_Money;
  priceRecommendation: reverb_pricing_PriceRecommendation;
}

export function isGreatValueVisible({
  sellerPrice,
  priceRecommendation,
}) {
  const hasPriceRecommendation = !!priceRecommendation.priceMiddle;
  const isSellerPriceGreaterThanMinimumPrice = sellerPrice.amountCents > MINIMUM_PRICE_CENTS;
  const hasPreconditionsMet = hasPriceRecommendation && isSellerPriceGreaterThanMinimumPrice;

  return hasPreconditionsMet && priceRecommendation.priceMiddle.amountCents >= sellerPrice.amountCents;
}

export default function SellFormGreatValueCallout({
  sellerPrice,
  priceRecommendation,
}: IExternalProps) {

  const showGreatValue = isGreatValueVisible({
    sellerPrice,
    priceRecommendation,
  });

  const calloutClasses = classNames(
    'sell-form-great-value-callout', {
      'sell-form-great-value-callout--visible': showGreatValue,
    },
  );

  return (
    <div className={calloutClasses}>
      <div className="sell-form-great-value-callout__content">
        <div className="sell-form-great-value-callout__title">
          {I18n.t('discovery.sellForm.pricingSection.greatValue.title')}
        </div>
        <div className="sell-form-great-value-callout__body">
          <div className="sell-form-great-value-callout__body__icon">
            <img
              alt={I18n.t('discovery.sellForm.pricingSection.greatValue.greatValueIconAlt')}
              src={greatValueIcon}
            />
          </div>
          <div className="sell-form-great-value-callout__body-content">
            <div className="sell-form-great-value-callout__body__title">
              {I18n.t('discovery.sellForm.pricingSection.greatValue.greatValueText')}
            </div>
            <div className="sell-form-great-value-callout__body__text">
              {I18n.t('discovery.sellForm.pricingSection.greatValue.belowAverageText')}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
