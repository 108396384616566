import React from 'react';
import I18n from 'i18n-js';
import { parseAmountCents } from '@reverbdotcom/commons/src/money';

import { core_apimessages_Money } from '@reverbdotcom/commons/src/gql/graphql';
import { KlarnaLearnMoreButton } from './KlarnaLearnMoreButton';
import { KlarnaCallout } from './KlarnaCallout';

type Money = Pick<core_apimessages_Money, 'amountCents' | 'currency'>;

const installmentCount = 4;

interface Props {
  amount: Money;
  textOnly?: boolean;
}

export function CanadianKlarnaCallout({
  amount,
  textOnly = false,
}: Props) {
  const installmentAmount = calculateInstallmentAmount(amount);

  return (
    <KlarnaCallout
      paymentPlan={
        translate('paymentPlan', {
          installmentCount,
          tagOpen: '<strong>',
          installmentAmount,
          tagClose: '</strong>',
        })}
      learnMore={
        <KlarnaLearnMoreButton
          description={buildButtonDescription(installmentAmount)}
          additionalSteps={[
            additionalStep('enterInformation'),
            additionalStep('explainPaymentPlan'),
          ]}
        />
      }
      textOnly={textOnly}
    />
  );
}

function additionalStep(key: string) {
  return {
    key,
    value: translate(`learnMoreButton.additionalSteps.${key}`),
  };
}

function calculateInstallmentAmount(amount: Money) {
  return parseAmountCents(amount.amountCents / installmentCount, amount.currency).display;
}

function buildButtonDescription(installmentAmount: string) {
  return {
    title: translate('learnMoreButton.title', {
      installmentCount,
      installmentAmount,
    }),
    terms: translate('learnMoreButton.terms', {
      linkOpen: '<a href="https://www.klarna.com/ca/legal" target="_blank">',
      linkClose: '</a>',
    }),
  };
}

function translate(key: string, args = {}) {
  return I18n.t(`discovery.klarnaCallout.klarnaAccount.${key}`, args);
}
