import I18n from 'i18n-js';
import React from 'react';
import BankDepositConfirmCheckbox from './payout_method_currency_warnings';
import PayoutMethodCountryCodeInput from './payout_method_country_code_input';
import RoutingNumberField from './us_routing_number_field';
import { PaymentProvider } from './payment_provider';
import Currency from '@reverbdotcom/commons/src/currency';

export interface Props {
  countryCodes: string[];
  inputNamePrefix: string;
  onCountryChange(countryCode: string): void;
  shopCurrency: string;
}

export default function USBankFields(props: Props) {
  function inputName(methodName) {
    return `${props.inputNamePrefix}[${methodName}]`;
  }

  return (
    <div>
      <div className="g-container">
        <div className="g-col-6 g-col-mobile-12">
          <label>
            {I18n.t('discovery.payoutMethod.directCheckoutProfile.bankAccountFields.nameOnAccount')}
          </label>
          <input
            className="string required wide mb-0"
            autoFocus
            type="text"
            name={inputName('name_on_account')}
          />
        </div>
        <div className="g-col-6 g-col-mobile-12 mobile-mt-2">
          <label>
            {I18n.t('discovery.payoutMethod.directCheckoutProfile.bankAccountFields.accountType')}
          </label>
          <ul className="inline-list horizontal-padded-list scaling-pt-1">
            <li>
              <label className="inline">
                <input
                  required
                  type="radio"
                  value="checking"
                  defaultChecked
                  name={inputName('bank_account_type')}
                />
                <span>
                  {I18n.t('discovery.payoutMethod.directCheckoutProfile.bankAccountFields.checkingRadio')}
                </span>
              </label>
            </li>
            <li>
              <label className="inline">
                <input
                  required
                  type="radio"
                  value="savings"
                  name={inputName('bank_account_type')}
                />
                <span>
                  {I18n.t('discovery.payoutMethod.directCheckoutProfile.bankAccountFields.savingsRadio')}
                </span>
              </label>
            </li>
          </ul>
        </div>
      </div>

      <div className="mb-2">
        <div className="g-container">
          <PayoutMethodCountryCodeInput
            countryCode="US"
            countryCodes={props.countryCodes}
            inputName={inputName('country_code')}
            onCountryChange={props.onCountryChange}
          />
          <div className="g-col-6">
            <label className="string required">
              {I18n.t('discovery.payoutMethod.directCheckoutProfile.bankAccountFields.currencyInput.label')}
            </label>
            <input
              type="hidden"
              name={inputName('currency')}
              value="USD"
            />
            <div>
              {I18n.t(`discovery.currencies.${'USD'}`, { symbol: Currency.get('USD').symbol })}
            </div>
          </div>
        </div>

        <BankDepositConfirmCheckbox
          inputName={inputName('bank_deposits_confirmed_at')}
          payoutCurrencyCode="USD"
          shopCountryCode="US"
          shopCurrencyCode={props.shopCurrency}
          paymentProvider={PaymentProvider.ReverbPayments}
        />
      </div>

      <div className="g-container">
        <div className="g-col-6 g-col-mobile-12">
          <label className="string required">
            {I18n.t('discovery.payoutMethod.directCheckoutProfile.bankAccountFields.routingNumber')}
          </label>
          <RoutingNumberField
            name={inputName('routing_number')}
          />
        </div>

        <div className="g-col-6 g-col-mobile-12">
          <label className="string required">
            {I18n.t('discovery.payoutMethod.directCheckoutProfile.bankAccountFields.accountNumber')}
          </label>
          <input
            className="string required wide"
            maxLength={17}
            type="text"
            name={inputName('bank_account_number')}
          />
        </div>
      </div>
    </div>
  );
}
