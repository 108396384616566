import React from 'react';
import I18n from 'i18n-js';

import { I18N } from '@reverbdotcom/commons/src/components/translate';
import { RCAlertBox } from '@reverbdotcom/cadence/components';
import { core_apimessages_ShippingLabelPackage } from '@reverbdotcom/commons/src/gql/graphql';

import CustomPackageInputs from '../../../../discovery/shipping_labels/custom_package_inputs';
import { MeasurementSystem } from '../../../../discovery/shipping_labels/shipping_label_package';
import { carrierCalculatedPackageValidator } from './carrierCalculatedPackageValidator';
import { UPS_LONGEST_SIDE_INCHES, UPS_HEAVY_PACKAGE_STICKER_REQUIRED } from './carrierCalculatedUpsPackageValidator';

interface IProps {
  packageEntry?: core_apimessages_ShippingLabelPackage;
  setIsInCarrierCalculatedMode: (boolean) => void;
  setPackageEntry: (core_apimessages_ShippingLabelPackage) => void;
  disabled?: boolean;
}

export default function CarrierCalculatedPackageForm({
  packageEntry,
  setPackageEntry,
  setIsInCarrierCalculatedMode,
  disabled = false,
}: IProps) {
  const carrierCalculatedPackageValidatorResponse = carrierCalculatedPackageValidator(packageEntry);
  const maxLengthInches = String(UPS_LONGEST_SIDE_INCHES);
  const maxWeightPounds = String(UPS_HEAVY_PACKAGE_STICKER_REQUIRED);
  const maxCubicInches = String(null);

  return (
    <div className="pb-4">
      <CustomPackageInputs
        shippingPackage={packageEntry}
        measurementSystem={MeasurementSystem.IMPERIAL}
        updatePackageDimensions={setPackageEntry}
        disabled={disabled}
        alert={carrierCalculatedPackageValidatorResponse?.errorTranslationKey && (
          <RCAlertBox data-package-warning type="error">
            <I18N
              html
              text={carrierCalculatedPackageValidatorResponse.errorTranslationKey}
              args={{
                maxLengthInches,
                maxWeightPounds,
                maxCubicInches,
              }}
            >
              <button
                className="button-as-link"
                type="button"
                onClick={() => setIsInCarrierCalculatedMode(false)}
              >
                {I18n.t('discovery.sellForm.shippingSection.domesticShippingCard.carrierCalculatedWarnings.setCustomRate')}
              </button>
            </I18N>
          </RCAlertBox>
        )}
        forCarrierCalculated
        hideMeasurementSystemControls
      />
    </div>
  );
}
