import { ChildProps } from '@apollo/client/react/hoc';
// TODO update this to import from commons/src/gql
// eslint-disable-next-line no-restricted-imports
import { gql } from '@apollo/client';
import React from 'react';
import { parse, stringify } from 'qs';
import { CommonsCmsBrandCollection, Brand } from '../../gql/graphql';

import SanitizedRender from '../../components/sanitized_render';

import BrandCard from '../../components/brand_card';
import LinkList from './link_list';
import OverflowingRow from '../../components/overflowing_row';
import Tiles from '../../components/tiles';
import { BRAND_ROUTE } from '../../url_helpers';
import { compact } from 'lodash';

import { IDynamicComponentProps } from '../dynamic_component_props';
import { withGraphql } from '../../with_graphql';

export interface IProps extends IDynamicComponentProps {
  title?: string;
  titleHtml?: string;
  curatedSetId?: string;
  onSale?: boolean;
  saleCuratedSetId?: string;
  query?: string;
  brandId?: string[];
  condition?: string;
  maxCount?: number;
  priceMax?: string;
  priceMin?: string;
  yearMax?: string;
  yearMin?: string;
  linkOverride?: string;
  jumplinkSlug?: string;
  rootCategorySlug?: string;
  leafCategorySlug?: string;
  subtitleHtml?: string;
  ledeHtml?: string;
}

interface IQueryParams {
  product_type?: string;
  category?: string;
}

const SIDEBAR_SECTION = 'sidebar';

export class BrandCollection extends React.Component<ChildProps<IProps, CommonsCmsBrandCollection.Query>, null> {
  getBrandLink(brand: Brand): string {
    if (this.props.linkOverride) {
      const parts = this.props.linkOverride.split('?');
      const path = parts[0];
      const overrideQuery = parse(parts[1]);

      const query = stringify({
        make: brand.slug,
        ...overrideQuery,
      });
      return `${path}?${query}`;
    }

    if (!this.props.linkOverride && this.props.rootCategorySlug) {
      const query: IQueryParams = {
        product_type: this.props.rootCategorySlug,
      };

      if (this.props.leafCategorySlug) {
        query.category = this.props.leafCategorySlug;
      }

      const routeContext = { ...query, brand_slug: brand.slug };

      return BRAND_ROUTE.expand(routeContext);
    }

    return brand.webLink && brand.webLink.href;
  }

  brands() {
    return this.props.data.listingsSearch?.topCollections?.brands || [];
  }

  renderTitle() {
    return (
      <SanitizedRender
        html={this.props.titleHtml || this.props.title}
      />
    );
  }

  renderForSidebar() {
    const brandLinks = this.brands().map((brand, _) => {
      return {
        text: brand.name,
        url: this.getBrandLink(brand),
      };
    });

    return (
      <LinkList
        links={brandLinks}
        title={this.renderTitle()}
      />
    );
  }

  render() {
    if (!this.props.data.loading && !this.brands().length) {
      return null;
    }

    if (this.props.layoutSection === SIDEBAR_SECTION) {
      return this.renderForSidebar();
    }

    return (
      <OverflowingRow
        title={this.renderTitle()}
        subtitleHtml={this.props.subtitleHtml}
        ledeHtml={this.props.ledeHtml}
        collectionCount={this.brands().length}
        id={this.props.jumplinkSlug}
      >
        <Tiles
          singleRow
          placeholder={<BrandCard />}
          loading={this.props.data.loading}
          expectedCount={this.props.maxCount}
          largeTiles
        >
          {this.brands().map((brand, idx) => (
            <BrandCard
              key={brand.id}
              brand={brand}
              linkOverride={this.getBrandLink(brand)}
              position={idx}
              inMobileApp={this.props.inMobileApp}
            />
          ))}
        </Tiles>
      </OverflowingRow>
    );
  }
}

const connect = withGraphql<IProps, CommonsCmsBrandCollection.Query, CommonsCmsBrandCollection.Variables>(
  gql`
  query Commons_Cms_BrandCollection(
    $maxCount: Int
    $curatedSetId: String
    $onSale: Boolean
    $saleCuratedSetId: String
    $priceMax: String
    $priceMin: String
    $yearMax: String
    $yearMin: String
    $categorySlugs: [String]
    $query: String
  ) {
      listingsSearch(input: {
        aggregationsOnly: false,
        withTopCollections: { brandUuids: $maxCount },
        curatedSetId: $curatedSetId,
        onSale: $onSale
        saleCuratedSetId: $saleCuratedSetId
        priceMax: $priceMax,
        priceMin: $priceMin,
        yearMax: $yearMax,
        yearMin: $yearMin,
        categorySlugs: $categorySlugs
        query: $query
      }) {
        topCollections {
          brands {
            _id
            id
            name
            slug
            webLink {
              href
            }
            images(input: {
              scope: "full_bleed_brand",
              transform: "card_wide",
              type: "Brand"
            }) {
              _id
              source
          }
        }
      }
    }
  }
  `,
  {
    options: ownProps => ({
      ssr: ownProps.ssr,
      variables: {
        query: ownProps.query,
        maxCount: ownProps.maxCount ? ownProps.maxCount : 12,
        curatedSetId: ownProps.curatedSetId,
        onSale: ownProps.onSale,
        saleCuratedSetId: ownProps.saleCuratedSetId,
        priceMax: ownProps.priceMax,
        priceMin: ownProps.priceMin,
        yearMax: ownProps.yearMax,
        yearMin: ownProps.yearMin,
        categorySlugs: compact([ownProps.rootCategorySlug, ownProps.leafCategorySlug]),
      },
    }),
  },
);

export default connect(BrandCollection);
