import { InMemoryCacheConfig, ApolloLink, Operation } from '@apollo/client';
import { getMainDefinition } from '@apollo/client/utilities';
import { GQL_GATEWAY_BASE_URL } from '@reverbdotcom/env';
import { IUser } from './components/user_context_provider';

const NON_CACHEABLE_TYPENAMES = Object.freeze([
  'OrderCollection',
  'ListingCollection',
  'WatchCollection',
]);

function nonCacheable(typename: string): boolean {
  return NON_CACHEABLE_TYPENAMES.indexOf(typename) > -1;
}

/**
 * Use this for response field types that fit this criteria:
 * 1. Do not have an `id`, `uuid`, or `_id` field.
 * 2. Are nested within other objects that do have an `id`, `uuid`, or `_id` field.
 */
const MERGE_EXISTING_AND_INCOMING_FIELDS = { merge: true };

/**
 * Shared settings for apollo's InMemoryCache for use in client and server JS.
 */
export const cacheOptions: InMemoryCacheConfig = {
  possibleTypes: {
    FeedEntry: ['Article', 'Listing'],
  },
  typePolicies: {
    core_apimessages_CheckoutPaymentMethod: MERGE_EXISTING_AND_INCOMING_FIELDS,
    core_apimessages_Condition: MERGE_EXISTING_AND_INCOMING_FIELDS,
    core_apimessages_CSPInventory: MERGE_EXISTING_AND_INCOMING_FIELDS,
    core_apimessages_CSPReviews: MERGE_EXISTING_AND_INCOMING_FIELDS,
    core_apimessages_FeedbacksResponse: MERGE_EXISTING_AND_INCOMING_FIELDS,
    core_apimessages_Feedback: MERGE_EXISTING_AND_INCOMING_FIELDS,
    core_apimessages_EstimatedMonthlyPayment: MERGE_EXISTING_AND_INCOMING_FIELDS,
    core_apimessages_Link: MERGE_EXISTING_AND_INCOMING_FIELDS,
    core_apimessages_ListingPricing: MERGE_EXISTING_AND_INCOMING_FIELDS,
    core_apimessages_Money: MERGE_EXISTING_AND_INCOMING_FIELDS,
    core_apimessages_PreorderInfo: MERGE_EXISTING_AND_INCOMING_FIELDS,
    core_apimessages_ReturnPolicy: MERGE_EXISTING_AND_INCOMING_FIELDS,
    core_apimessages_Ribbon: MERGE_EXISTING_AND_INCOMING_FIELDS,
    core_apimessages_ShippingPrices: MERGE_EXISTING_AND_INCOMING_FIELDS,
    core_apimessages_ListingCertifiedPreOwned: MERGE_EXISTING_AND_INCOMING_FIELDS,
    mparticle_ProfileResponse: MERGE_EXISTING_AND_INCOMING_FIELDS,
    reverb_pricing_Money: MERGE_EXISTING_AND_INCOMING_FIELDS,
    reverb_pricing_PriceRecommendation: MERGE_EXISTING_AND_INCOMING_FIELDS,
    core_apimessages_CheckoutOrder: { keyFields: ['listingId'] },
    rql_MyCart: MERGE_EXISTING_AND_INCOMING_FIELDS,
    rql_Me: {
      fields: {
        discover: {
          // Don't cache separate results based on
          // any of this field's arguments.
          keyArgs: false,
        },
      },
    },
    rql_DiscoverResponse: {
      fields: {
        entries: {
          // Concatenate the incoming list items with
          // the existing list items.
          merge(existing = [], incoming = []) {
            return [...existing, ...incoming];
          },
        },
      },
    },
  },
  dataIdFromObject: (result: any) => {
    if (result.__typename) {
      if (nonCacheable(result.__typename)) {
        return null;
      }

      // Check to see if we're selecting a Node type via the naming convention
      // established in RQL. Types like Listing are Nodes, but types like api_messages_Listing
      // are not.
      //
      // Having conflicting or missing IDs for objects can result in failures to write to the apollo
      // cache. Node should _always_ have an _id field.
      if (/^[A-Z]/.test(result.__typename) && result.__typename != 'Mutation') {
        if (!result._id) {
          console.error(`A query returned a ${result.__typename} without an _id field. This can cause Apollo to fail to write this object to the cache`);
        }
      }

      if (result._id !== undefined) {
        return `${result.__typename}:${result._id}`;
      }

      if (result.uuid !== undefined) {
        return `${result.__typename}:uuid:${result.uuid}`;
      }

      if (result.id !== undefined) {
        return `${result.__typename}:id:${result.id}`;
      }
    }

    return null;
  },
};

function coercePrimitive(val, kind) {
  let newVal;

  switch (kind) {
    case 'String':
      newVal = String(val);
      break;
    case 'Boolean':
      newVal = (String(val) === 'true');
      break;
    case 'Int':
      newVal = parseInt(val, 10);
      break;
    case 'Float':
      newVal = parseFloat(val);
      break;
    default:
      newVal = val;
  }

  return newVal;
}

// cms-api does not validate the input type, however GraphQL 14
// started validating input primitives. This normally would
// be caught via TS, but these values are not known until
// runtimes and cms-api provides no contracts for these values.
export const apolloInputCoercionLink = new ApolloLink((operation, forward) => {
  const coercedVals = {};
  const q = getMainDefinition(operation.query);
  q.variableDefinitions?.forEach((def) => {
    const name = def.variable.name.value;
    const val = operation.variables[name];
    if (!val) { return; }

    // coerce just top level primitive values
    // def.type.kind is ListType for arrays, but
    // have more complicated parsing rules that
    // we hopefully do not need
    if (def.type.kind === 'NamedType') {
      const kind = def.type.name.value;
      coercedVals[name] = coercePrimitive(val, kind);
    }
  });

  operation.variables = {
    ...operation.variables,
    ...coercedVals,
  };

  return forward(operation);
});

// This function is being called by react-rendering-engine, so we have to make the arguments optional to avoid breaking changes.
export function apolloClientUri(_operation: Operation = null, _user: IUser = null): string {
  return GQL_GATEWAY_BASE_URL;
}
