import React from 'react';
import PropTypes from 'prop-types';
import isValidPassword from './password_policy';

class PasswordInput extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      password: '',
    };
  }

  passwordValidationClassName() {
    if (isValidPassword(this.state.password)) {
      return ('password-validation__flag active');
    }

    return ('password-validation__flag');
  }

  render() {
    return (
      <div className="form-group">
        <div className="form-group__header">
          <label htmlFor={this.props.fieldName}>
            {I18n.t('js.passwordInput.label')}
          </label>
          <span className="form-group__help-text mtb-0 ml-1">
            {I18n.t('js.passwordInput.lengthRequirement')}
          </span>
        </div>
        <div className="form-group__fields">
          <div className="password-validation">
            <input
              type="password"
              required
              name={this.props.fieldName}
              id={this.props.fieldName}
              value={this.state.password}
              onChange={event => this.setState({ password: event.target.value })}
              autoComplete="new-password"
            />
            <div className={this.passwordValidationClassName()} />
          </div>
        </div>
      </div>
    );
  }
}

PasswordInput.propTypes = {
  fieldName: PropTypes.string.isRequired,
};

export default PasswordInput;
