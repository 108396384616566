import React from 'react';

import { gql } from '@reverbdotcom/commons/src/gql';
import {
  Listing,
  reverb_search_SearchResponse,
} from '@reverbdotcom/commons/src/gql/graphql';

import { useQuery } from '@reverbdotcom/commons/src/useQuery';
import { useLazySellerListingsQuery } from './useLazySellerListingsQuery';
import { useViewTracking } from '@reverbdotcom/commons/src/use_tracking';
import { MParticleEventName } from '@reverbdotcom/commons/src/elog/mparticle_types';
import RouterHistory from '@reverbdotcom/commons/src/cms/lib/router_history';

import LoadingOverlay from '../../shared/loading_overlay';
import PlaceholderSellerListingCard from './PlaceholderSellerListingCard';
import { EVERYWHERE_CODE, US } from '@reverbdotcom/commons/src/constants';
import SellerListingCardContainer from './SellerListingCardContainer';
import SellerListingCardsToolbar from './SellerListingCardsToolbar';
import SellerListingsCollectionContext from './SellerListingsCollectionContext';
import sellerListingsCollectionReducer, { ActionType } from './sellerListingsCollectionReducer';
import EmptyListingsState from './EmptyListingsState';
import URLHelpers from '../shared/url_helpers';
import { useSeller } from '@reverbdotcom/commons/src/user_hooks';

export const SHIPPING_REGION_CODES = [
  EVERYWHERE_CODE,
];

export const COMPONENT_NAME = 'SellerListingCardsCollection';

interface IExternalProps {
  listingIds: string[] | number[];
  emptyStateMessage?: string;
  totalMatches?: number;
  totalMatchesEligibleForDirectOffers?: number;
  totalMatchesEnabledForDirectOffers?: number;
  totalMatchesEnabledForBump?: number;
  totalMatchesEligibleForBump?: number;
  totalMatchesEligibleForAffirm?: number;
  totalMatchesEnabledForAffirm?: number;
  serializedDashboardSearchParams?: string;
  originatingUserId?: string;
}

export const SellerListingPriceRecommendationFragment = gql(`
  fragment SellerListingPriceRecommendation on Listing {
    _id
    priceRecommendation(input: {
      currency: $shopCurrency,
      countryCode: $shopCountryCode
    }) {
      priceLow {
        amount
        amountCents
        display
        currency
      }
      priceMiddle {
        amount
        amountCents
        display
        currency
      }
      priceHigh {
        amount
        amountCents
        display
        currency
      }
    }
  }
`);

export const SellerListingCardFragment = gql(`
  fragment SellerListingCard on Listing {
    _id
    id
    sellerUuid
    title
    slug
    sku
    state
    inventory
    condition {
      conditionSlug
      displayName
    }
    bumpRate {
      rate
    }
    sellerPrice {
      amount
      amountCents
      symbol
      currency
      display
    }
    images(input: { transform: "card_square", count: 1 }) {
      _id
      source
    }
    counts {
      views
      offers
      watchers
      inBuyerCarts
    }
    orderStats {
      totalOrders
    }
    publishedAt {
      seconds
    }
    hasInventory
    isAutoOfferEligible
    affirmZeroPercentFinancingConfiguration {
      isEligible
      isEnabled
    }
    autoOffers {
      id
    }
    shop {
      _id
      currency
      canCurateSales
    }
    exclusiveChannel
    relistable
    vatIncluded
    vatIncludedHint
    taxExempt
    publishValidation {
      isValid
      errors {
        message
      }
    }
    shipmentPackage {
      _id
      length {
        value
        unit
      }
      width {
        value
        unit
      }
      height {
        value
        unit
      }
      weight {
        value
        unit
      }
    }
  }
`);

export const SELLER_LISTINGS_QUERY = gql(`
query Core_MyListings_SellerListingCards(
  $listingIds: [String],
  $shippingRegionCodes: [String],
  $itemRegion: String,
  $shopCurrency: String,
  $shopCountryCode: String,
  $skipPriceRecommendation: Boolean!
) {
  me {
    _id
    uuid
    availableActions {
      id
      name
    }
    shop {
      _id
      acceptsAffirm
      canCurateSales
      currency
      listings(input: {
        ids: $listingIds,
        shippingRegionCodes: $shippingRegionCodes,
        itemRegion: $itemRegion
      }) {
        listings {
          _id
          ...SellerListingCard
          ...SellerListingPriceRecommendation @skip(if: $skipPriceRecommendation)
        }
      }
    }
  }
}
`);

export function handlePaginationRedirect(listings: Listing[]) {
  const searchParams = new URLSearchParams(window.location.search);
  const page = searchParams.get('page') ?? 1;

  if (listings.length) return;
  if (page == 1) return;

  const newSearchParams = {
    ...Object.fromEntries(searchParams),
    ...{ page: Number(page) - 1 },
  };

  const redirectUrl = URLHelpers.listingsManagerPath(newSearchParams);
  RouterHistory.replace(redirectUrl);
}

export function SellerListingCardsCollection({
  listingIds,
  emptyStateMessage = '',
  totalMatches,
  totalMatchesEligibleForDirectOffers,
  totalMatchesEnabledForDirectOffers,
  totalMatchesEnabledForBump,
  totalMatchesEligibleForBump,
  totalMatchesEligibleForAffirm,
  totalMatchesEnabledForAffirm,
  serializedDashboardSearchParams,
  originatingUserId,
}: IExternalProps) {
  const seller = useSeller();

  const { data = {}, loading } = useQuery(SELLER_LISTINGS_QUERY, {
    ssr: false,
    variables: {
      listingIds: listingIds.map(id => id.toString()),
      shippingRegionCodes: SHIPPING_REGION_CODES,
      itemRegion: EVERYWHERE_CODE,
      shopCurrency: seller.shopCurrency,
      shopCountryCode: seller.shopCountryCode,
      skipPriceRecommendation: seller.shopCountryCode != US,
    },
    errorPolicy: 'all',
    fetchPolicy: 'network-only',
  },
  );

  const { me = {} } = data;
  const { shop = {} } = me;

  const {
    listings: listingsResponse,
  } = shop;
  const {
    listings = [],
  }: reverb_search_SearchResponse = listingsResponse ?? {};
  const placeholderListings = Array.from(Array(listingIds.length).keys());
  const shouldRenderPlaceholderListings = loading;

  const sanitizedAndSortedListings = listingIds.map(id => listings.find(x => x.id === id))
    .filter(listing => listing !== undefined)
    .map(listing => {
      return { ...listing, ...{ loading: false } };
    });

  const initialListingsCollectionState = {
    selectedListings: [],
    listings: [],
    showEmptyState: false,
    hasAllMatchesSelected: false,
    totalMatches,
    totalMatchesEligibleForDirectOffers,
    totalMatchesEnabledForDirectOffers,
    totalMatchesEnabledForBump,
    totalMatchesEligibleForBump,
    totalMatchesEligibleForAffirm,
    totalMatchesEnabledForAffirm,
    serializedDashboardSearchParams,
    originatingUserId,
  };

  const [listingsCollectionState, dispatch] = React.useReducer(
    sellerListingsCollectionReducer,
    initialListingsCollectionState,
  );

  const { listings: sellerListings, showEmptyState } = listingsCollectionState;

  React.useEffect(() => {
    if (loading) return;

    dispatch({
      type: ActionType.ADD_INITIAL_LISTINGS,
      payload: {
        listings: sanitizedAndSortedListings,
      },
    });

    handlePaginationRedirect(sanitizedAndSortedListings);
  }, [loading]);

  useViewTracking({
    componentName: COMPONENT_NAME,
    eventName: MParticleEventName.ComponentView,
  }, !loading);

  return (
    <LoadingOverlay showOverlay={loading}>
      <SellerListingsCollectionContext.Provider
        value={{
          listingsCollectionState,
          dispatch,
          useLazySellerListingsQuery,
        }}
      >
        <div className="seller-listing-cards-collection__listings">
          <SellerListingCardsToolbar
            me={me}
          />

          {shouldRenderPlaceholderListings ? placeholderListings.map(element => (
            <div key={`placeholderListing-${element}`}>
              <PlaceholderSellerListingCard />
            </div>
          )) : sellerListings.map(listing => (
            <div key={listing.id}>
              <SellerListingCardContainer
                listing={listing}
              />
            </div>
          ))}
        </div>
        {showEmptyState && (
          <EmptyListingsState message={emptyStateMessage} />
        )}
      </SellerListingsCollectionContext.Provider>
    </LoadingOverlay>
  );
}

export default SellerListingCardsCollection;
