import React from 'react';
import I18n from 'i18n-js';
import TogglableDropdown from '../shared/togglable_dropdown';

interface Props {
  currencyCode: string;
  onCurrencyChange(currencyCode: string): void;
  inputName: string;
  currencyCodes: string[];
}

function selectOptions(currencyCodes: string[]) {
  return currencyCodes.map((currencyCode) => ({ name: currencyCode, value: currencyCode }));
}

export default function PayoutMethodCurrencyInput(props: Props) {
  return (
    <div className="g-col-6" data-change-currency>
      <TogglableDropdown
        defaultValue={props.currencyCode}
        selectOptions={selectOptions(props.currencyCodes)}
        inputName={props.inputName}
        onChange={props.onCurrencyChange}
        labelTitle={I18n.t('discovery.payoutMethod.directCheckoutProfile.bankAccountFields.currencyInput.label')}
        changeLinkText={I18n.t('discovery.payoutMethod.directCheckoutProfile.bankAccountFields.currencyInput.change')}
        autoComplete="currency-code"
      />
    </div>
  );
}
