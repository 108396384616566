import { ChildProps } from '@apollo/client/react/hoc';
import { MutationFunction } from '@reverbdotcom/commons/src/useHOCMutation';
import React from 'react';
import { withUserContext, IUserContext } from '@reverbdotcom/commons/src/components/user_context_provider';
import {
  GetBillingMethod,
  UpdateMyShopBillingMethod,
  Input_core_apimessages_UpdateBillingMethodRequest as UpdateBillingMethodRequest,
  core_apimessages_AdyenPaymentResult_PaymentStatus as PaymentStatus,
  core_apimessages_AdyenPaymentResult as AdyenPaymentResult,
  core_apimessages_Error,
  core_apimessages_MyShopOnboardingResponse_BillingMethodStatus as BillingMethodStatus,
  core_apimessages_UpdateBillingMethodResponse as UpdateBillingMethodResponse,
} from '@reverbdotcom/commons/src/gql/graphql';
import BillingMethodForm from '../billing/billing_method_form';
import { connectGetBillingMethod, connectUpdateMyShopBillingMethod } from './billing_method_operations';
import bind from '@reverbdotcom/commons/src/bind';
import { parseMutationErrors } from '@reverbdotcom/commons/src/parse_graphql_errors';
import BillingMethodPlaceholder from '../billing/billing_method_placeholder';
import PrimaryBillingMethod from '../billing/primary_billing_method';
import BillingMethodSCAInteraction from './billing_method_sca_interaction';
import BillingMethodFormError from '../billing/billing_method_form_error';
import BillingMethodCompleteShopFirst from './billing_method_complete_shop_first';
import { connect } from '@reverbdotcom/commons/src/connect';
import { get, flatten } from 'lodash';

interface MutationProps {
  updateMyShopBillingMethod: MutationFunction<UpdateMyShopBillingMethod.Mutation, UpdateMyShopBillingMethod.Variables>;
}

interface ExternalProps {
  onRedirectShopperRequired?(paymentResult: AdyenPaymentResult): void;
  onUpdateBillingMethodSuccess?(): void;
  defaultAdyenPaymentResult?: AdyenPaymentResult;
  returnURL: string;
  description?: string;
  showEditURL?: boolean;
  title?: string;
}
type Props = ExternalProps & IUserContext & MutationProps;

interface State {
  billingMethodResponse: UpdateBillingMethodResponse;
  isSaving: boolean;
  errors: core_apimessages_Error[];
  showBillingMethodForm: boolean;
}

/**
 * A billing method result has been successfully set if the response
 * contains a non-null credit card ID. This indicates the card has successfully been
 * updated to that ID.
 */
function isBillingMethodResponseSuccessful(billingMethodResponse?: UpdateBillingMethodResponse) {
  return !!billingMethodResponse?.creditCard?.id;
}

export const SCA_PAYMENT_STATUSES = [
  PaymentStatus.REDIRECT_SHOPPER_REQUIRED,
  PaymentStatus.IDENTIFY_SHOPPER_REQUIRED,
  PaymentStatus.CHALLENGE_SHOPPER_REQUIRED,
];

export class BillingMethod extends React.Component<ChildProps<Props, GetBillingMethod.Query>, State> {
  state = {
    billingMethodResponse: {
      adyenPaymentResult: this.props.defaultAdyenPaymentResult,
      creditCard: null,
      billingMethodUuid: null,
    },
    isSaving: false,
    errors: [],
    showBillingMethodForm: false,
  };

  get primaryBillingCard() {
    return this.props.data.me.creditCards.find((creditCard) => creditCard.primaryBilling);
  }

  get is3DSRequired() {
    return SCA_PAYMENT_STATUSES.includes(this.state.billingMethodResponse?.adyenPaymentResult?.paymentStatus);
  }

  get showPrimaryBillingMethod() {
    const { billingMethodStatus } = this.props.data.me.shop;
    const { showBillingMethodForm } = this.state;

    return !showBillingMethodForm &&
      billingMethodStatus === BillingMethodStatus.COMPLETED &&
      this.primaryBillingCard &&
      this.state.errors.length === 0 &&
      !this.state.isSaving;
  }

  @bind
  async updateMyShopBillingMethod(input: UpdateBillingMethodRequest) {
    this.setState({ isSaving: true, errors: [] });
    try {
      const result = await this.props.updateMyShopBillingMethod({ variables: { input } });
      if (result) {
        this.setState({
          isSaving: false,
          showBillingMethodForm: false,
          billingMethodResponse: result.data.updateMyShopBillingMethod,
          errors: this.parseValidationErrors(result.errors || []),
        }, this.onUpdateBillingMethodComplete);
      }
    } catch (error) {
      this.setState({ isSaving: false, errors: parseMutationErrors(error) });
    }
  }

  parseValidationErrors = (errors) => {
    if (errors.length === 0) {
      return [];
    }

    return flatten(errors.map(e => get(e, 'extensions.errors')));
  };

  @bind
  onUpdateBillingMethodComplete() {
    this.handleRedirectShopperRequired();
    this.handleUpdateBillingMethodSuccess();
  }

  @bind
  handleUpdateBillingMethodSuccess() {
    if (this.props.onUpdateBillingMethodSuccess && isBillingMethodResponseSuccessful(this.state.billingMethodResponse)) {
      this.props.onUpdateBillingMethodSuccess();
    }
  }

  @bind
  openBillingMethodForm() {
    this.setState({ showBillingMethodForm: true });
  }

  @bind
  onThreeDS2Errors(errors: core_apimessages_Error[]) {
    this.setState({
      errors,
      billingMethodResponse: {},
      isSaving: false,
    });
  }

  handleRedirectShopperRequired() {
    if (this.props.onRedirectShopperRequired &&
      this.state.billingMethodResponse?.adyenPaymentResult?.paymentStatus === PaymentStatus.REDIRECT_SHOPPER_REQUIRED) {
      this.props.onRedirectShopperRequired(this.state.billingMethodResponse.adyenPaymentResult);
    }
  }

  @bind
  onThreeDS2Result(billingMethodResponse) {
    this.setState({ billingMethodResponse }, this.handleUpdateBillingMethodSuccess);
  }

  get hasErrorsAndShopSetUp() {
    return this.props.data.error && !!this.props.data.me.shop.id;
  }

  get hasErrorsAndShopNotSetUp() {
    return this.props.data.error && !this.props.data.me.shop.id;
  }

  render() {
    if (this.props.data.loading) {
      return (<BillingMethodPlaceholder title={this.props.title} />);
    }

    if (this.hasErrorsAndShopNotSetUp) {
      return <BillingMethodCompleteShopFirst />;
    }

    if (this.hasErrorsAndShopSetUp) {
      return <BillingMethodFormError />;
    }

    if (this.is3DSRequired) {
      return (
        <BillingMethodSCAInteraction
          title={this.props.title}
          billingMethodResponse={this.state.billingMethodResponse}
          onBillingMethodResponse={this.onThreeDS2Result}
          onError={this.onThreeDS2Errors}
        />
      );
    }

    if (this.showPrimaryBillingMethod) {
      return (
        <PrimaryBillingMethod
          creditCard={this.primaryBillingCard}
          onChangeButtonClick={this.openBillingMethodForm}
        />
      );
    }

    return (
      <BillingMethodForm
        billingMethodStatus={this.props.data.me.shop.billingMethodStatus}
        countries={this.props.data.countries.countries}
        creditCards={this.primaryBillingCard ? [this.primaryBillingCard] : []}
        defaultOriginCountryCode={this.props.data.me.shop.originCountryCode}
        description={this.props.description}
        isSaving={this.state.isSaving}
        validationErrors={this.state.errors}
        updateMyShopBillingMethod={this.updateMyShopBillingMethod}
        user={this.props.user}
        title={this.props.title}
        showEditURL={this.props.showEditURL}
        returnURL={this.props.returnURL}
      />
    );
  }
}

export default connect<ExternalProps>([
  withUserContext,
  connectUpdateMyShopBillingMethod,
  connectGetBillingMethod,
])(BillingMethod);
