import PropTypes from 'prop-types';
import React from 'react';

class RelatedArticle extends React.Component {
  get categoryName() {
    return this.props.article.categories
      && this.props.article.categories[0]
      && this.props.article.categories[0].name;
  }

  render() {
    return (
      <article className="article-condensed-card">
        <a
          href={this.props.article._links.web.href}
          style={{ backgroundImage: `url(${this.props.article.photo})` }}
        >
          <div className="article-condensed-card__title">
            {this.props.article.title}
          </div>
          <div className="article-condensed-card__category">
            {this.categoryName}
          </div>
        </a>
      </article>
    );
  }
}

RelatedArticle.propTypes = {
  article: PropTypes.object.isRequired,
};

export default RelatedArticle;
