import React from 'react';
import { CollectionHeaderStore } from '@reverbdotcom/commons/src/components/stores/collection_header_store';

import SeoBlurb from '@reverbdotcom/commons/src/cms/components/seo_blurb';

class CollectionSEOContent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      collectionHeader: CollectionHeaderStore.getState().collectionHeader || {},
    };

    this.onCollectionHeaderStoreUpdated = this.onCollectionHeaderStoreUpdated.bind(this);
  }

  componentDidMount() {
    CollectionHeaderStore.listen(this.onCollectionHeaderStoreUpdated);
  }

  componentWillUnmount() {
    CollectionHeaderStore.unlisten(this.onCollectionHeaderStoreUpdated);
  }

  onCollectionHeaderStoreUpdated() {
    this.setState({
      collectionHeader: CollectionHeaderStore.getState().collectionHeader,
    });
  }

  render() {
    if (!this.state.collectionHeader) { return null; }

    const descriptionHTML = this.state.collectionHeader.description;
    if (!descriptionHTML) { return null; }

    return (
      <SeoBlurb
        contentHtml={descriptionHTML}
        withWrapper
      />
    );
  }
}

export default CollectionSEOContent;
