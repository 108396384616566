import React from 'react';
import { Route } from 'react-router';
import URLHelpers from '../components/shared/url_helpers';

const OnboardingRoute = (
  <Route
    key={URLHelpers.onboardingPath}
    path={URLHelpers.onboardingPath}
    getComponent={(_location, cb) => {
      import(
        /* webpackChunkName: 'onboarding' */
        '../onboarding/progressive_onboarding_container'
      ).then(m => cb(null, m.default));
    }}
  />
);

const OnboardingCompleteRoute = (
  <Route
    key={URLHelpers.onboardingCompleteShopDetailsPath}
    path={URLHelpers.onboardingCompleteShopDetailsPath}
    getComponent={(_location, cb) => {
      import(
        /* webpackChunkName: 'onboarding' */
        '../onboarding/progressive_onboarding_container'
      ).then(m => cb(null, m.default));
    }}
  />
);

const OnboardingBegin3DS1Route = (
  <Route
    key={URLHelpers.onboardingBegin3DS1}
    path={URLHelpers.onboardingBegin3DS1}
    getComponent={(_location, cb) => {
      import(
        /* webpackChunkName: 'onboarding' */
        '../onboarding/progressive_onboarding_container'
      ).then(m => cb(null, m.default));
    }}
  />
);

const OnboardingRoutes = [
  OnboardingRoute,
  OnboardingCompleteRoute,
  OnboardingBegin3DS1Route,
];

export default OnboardingRoutes;
