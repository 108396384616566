import React, { useState } from 'react';
import I18n from 'i18n-js';
import {
  core_apimessages_ProtectionPlan,
} from '@reverbdotcom/commons/src/gql/graphql';
import {
  RCModal,
  RCText,
  RCIcon,
} from '@reverbdotcom/cadence/components';
import MulberryShieldLogo from '@reverbdotcom/cadence/images/vendor/mulberry-shield-logo.svg';
import CoreLink from '@reverbdotcom/commons/src/components/core_link';
import { MParticleEventName, trackEvent } from '@reverbdotcom/commons/src/elog/mparticle_tracker';
import { useHelpCenterArticleUrl } from '@reverbdotcom/commons/src/url_helpers';
import { PROTECTION_PLANS_ARTICLE_ID } from '@reverbdotcom/commons/src/constants';
import { CheckCircleIcon } from '@reverbdotcom/cadence/icons/react';

interface IProps {
  isOpen: boolean;
  onToggle: (boolean) => void;
  protectionPlan: core_apimessages_ProtectionPlan;
  availableProtectionPlans: core_apimessages_ProtectionPlan[];
  listingId: string;
  trackEventFn: (componentName, protectionPlan) => void;
  modalTrigger?: () => JSX.Element;
  onUpdate: (selectedPlan) => void;
  onClose?: () => void;
}

interface OptionLabelProps {
  protectionPlanOption: core_apimessages_ProtectionPlan;
}

const COMPONENT_NAME = 'ProtectionPlanModal';

function OptionLabel({ protectionPlanOption }: OptionLabelProps) {
  return (
    <>
      <div className="mb-1">
        <RCText.Utility size="400">
          {I18n.t('commons.protectionPlanModal.planOptionDuration', { durationYears: (Number(protectionPlanOption.durationMonths) / 12) })}
        </RCText.Utility>
      </div>
      <RCText.Utility weight="bold" size="600">
        {protectionPlanOption.amount.display}
      </RCText.Utility>
    </>
  );
}

function ProtectionPlanModal(
  {
    isOpen,
    onToggle,
    availableProtectionPlans,
    listingId,
    protectionPlan,
    trackEventFn,
    modalTrigger = () => { return null; },
    onUpdate,
    onClose = () => { },
  }: IProps,
) {
  const [selectedProtectionPlan, setSelectedProtectionPlan] = useState(protectionPlan);
  const [clickedSubmit, setClickedSubmit] = useState(false);

  async function onClick(selectedPlan: core_apimessages_ProtectionPlan) {
    trackEventFn(
      COMPONENT_NAME,
      selectedPlan,
    );

    await setClickedSubmit(true);
    onUpdate(selectedPlan);
  }

  function handleOnChange(plan: core_apimessages_ProtectionPlan) {
    const selectedPlan = availableProtectionPlans.find(pp => pp.planId === plan.planId);
    setSelectedProtectionPlan(selectedPlan);
  }

  return (
    <RCModal
      isOpen={isOpen}
      title={I18n.t('commons.protectionPlanModal.title')}
      onOpenChange={(state) => {
        if (state === true) {
          trackEvent({
            componentName: COMPONENT_NAME,
            eventName: MParticleEventName.OpenedProtectionPlanModal,
            listingId: listingId,
          });
        }

        onToggle(state);

        if (!clickedSubmit) {
          onClose();
        }
      }}
      actions={{
        primary: {
          onClick: () => onClick(selectedProtectionPlan),
          buttonText: I18n.t('commons.protectionPlanModal.addPlan'),
        },
        secondary: {
          onClick: () => onClose(),
          buttonText: I18n.t('commons.protectionPlanModal.noThankYou'),
        },
      }}
      trigger={modalTrigger()}
    >
      <div className="protection-plan-modal">
        <div className="protection-plan-modal__content">
          <div className="protection-plan-modal__plan-length">
            <div className="d-flex fx-dir-row">
              <div className="protection-plan-modal__logo">
                <img src={MulberryShieldLogo} alt="" />
              </div>
              <div>
                <div className="protection-plan-modal__plan-length-title">
                  <RCText.Utility weight="bold" size="550">
                    {I18n.t('commons.protectionPlanModal.playOn')}
                  </RCText.Utility>
                </div>
                <div className="protection-plan-modal__plan-length-subtitle">
                  <RCText>{I18n.t('commons.protectionPlanModal.poweredBy')}</RCText>
                </div>
              </div>
            </div>
            <RCText>{I18n.t('commons.protectionPlanModal.selectorLabel')}</RCText>
            <div className="protection-plan-modal__selector">
              {availableProtectionPlans.map((plan) =>
                <button
                  key={`${plan.planId}-${listingId}`}
                  onClick={() => handleOnChange(plan)}
                  aria-pressed={selectedProtectionPlan.planId === plan.planId}
                >
                  <OptionLabel protectionPlanOption={plan} />
                </button>,
              )}
            </div>
          </div>
          <div className="protection-plan-modal__plan-highlights">
            <div className="protection-plan-modal__plan-copy">
              <span className="fx-align-self-stretch">
                <RCText.Utility size="350" weight="bold">
                  {I18n.t('commons.protectionPlanModal.addPlanDescription')}
                </RCText.Utility>
              </span>
              <div className="protection-plan-modal__highlight-points">
                <div className="protection-plan-modal__highlight-point">
                  <RCIcon svgComponent={CheckCircleIcon} />
                  <RCText>
                    {I18n.t('commons.protectionPlanModal.listItem0')}
                  </RCText>
                </div>
                <div className="protection-plan-modal__highlight-point">
                  <RCIcon svgComponent={CheckCircleIcon} />
                  <RCText>
                    {I18n.t('commons.protectionPlanModal.listItem1')}
                  </RCText>
                </div>
                <div className="protection-plan-modal__highlight-point">
                  <RCIcon svgComponent={CheckCircleIcon} />
                  <RCText>
                    {I18n.t('commons.protectionPlanModal.listItem2')}
                  </RCText>
                </div>
              </div>
            </div>
            <CoreLink to={useHelpCenterArticleUrl(PROTECTION_PLANS_ARTICLE_ID)} className="protection-plan-modal__learn-more" target="_blank">
              {I18n.t('commons.protectionPlanModal.learnMore')}
            </CoreLink>
          </div>
        </div>
      </div>
    </RCModal>
  );
}

export default ProtectionPlanModal;
