import React from 'react';
import { isEmpty } from 'lodash';

import {
  reverb_search_SearchResponse,
} from '@reverbdotcom/commons/src/gql/graphql';
import { useLazyQuery } from '@reverbdotcom/commons/src/useLazyQuery';
import * as elog from '@reverbdotcom/commons/src/elog';

import { SELLER_LISTINGS_QUERY, SHIPPING_REGION_CODES } from './SellerListingCardsCollection';
import SellerListingsCollectionContext from './SellerListingsCollectionContext';
import { ActionType } from './sellerListingsCollectionReducer';
import { EVERYWHERE_CODE, US } from '@reverbdotcom/commons/src/constants';
import { useSeller } from '@reverbdotcom/commons/src/user_hooks';

export const REFETCH_ERROR = 'seller_listing_cards.refetch_error';

export const useLazySellerListingsQuery = () => {
  const seller = useSeller();
  const {
    shopCountryCode,
    shopCurrency,
  } = seller;

  const {
    dispatch,
  } = React.useContext(SellerListingsCollectionContext);

  const [findSellerListings, {
    data = {},
    loading,
    errors = [],
  }] = useLazyQuery(SELLER_LISTINGS_QUERY);

  const hasErrors = !!errors.length;

  const { me = {} } = data;
  const { shop = {} } = me;
  const { listings: listingsResponse } = shop;
  const { listings = [] }: reverb_search_SearchResponse = listingsResponse ?? {};

  React.useEffect(() => {
    if (loading) return;
    if (hasErrors) {
      elog.error(
        REFETCH_ERROR,
        { errorMessages: errors.map(e => e.message).join(',') },
        null,
      );

      return dispatch({
        type: ActionType.RESET_LOADING,
      });
    }

    if (isEmpty(data)) return;

    dispatch({
      type: ActionType.UPDATE_LISTINGS,
      payload: {
        listings,
      },
    });
  }, [loading]);

  const refetchSellerListings = (listingIds: string[]) => {
    dispatch({
      type: ActionType.LOAD_LISTINGS,
      payload: {
        listingIds,
      },
    });

    findSellerListings({
      variables: {
        listingIds,
        shippingRegionCodes: SHIPPING_REGION_CODES,
        itemRegion: EVERYWHERE_CODE,
        shopCurrency,
        shopCountryCode,
        skipPriceRecommendation: shopCountryCode != US,
      },
      fetchPolicy: 'network-only',
    });
  };

  return { refetchSellerListings, data, loading, errors };
};
