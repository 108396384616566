import React from 'react';
import I18n from 'i18n-js';
import SalesCards from './SalesCards';
import { core_apimessages_ListingSaleMembership } from '@reverbdotcom/commons/src/gql/graphql';
import SalesMembershipsContext from './SalesMembershipsContext';
import salesMembershipsReducer from './salesMembershipsReducer';
import { SaleTypes } from './SaleCard';
import SalesMembershipsTabs from './SalesMembershipsTabs';

interface IExternalProps {
  defaultReverbSalesMemberships: core_apimessages_ListingSaleMembership[];
  defaultSellerSalesMemberships: core_apimessages_ListingSaleMembership[];
}

export default function SalesMemberships({
  defaultReverbSalesMemberships,
  defaultSellerSalesMemberships,
}: IExternalProps) {
  const salesMemberships = {
    reverbSalesMemberships: defaultReverbSalesMemberships,
    sellerSalesMemberships: defaultSellerSalesMemberships,
  };

  const [
    salesMembershipsState,
    dispatch,
  ] = React.useReducer(salesMembershipsReducer, salesMemberships);

  const {
    reverbSalesMemberships,
    sellerSalesMemberships,
  } = salesMembershipsState;

  const hasReverbSales = !!reverbSalesMemberships.filter(saleMembership => saleMembership.isListingEligible || saleMembership.isListingInSale).length;
  const hasSellerSales = !!sellerSalesMemberships.length;
  const sellerSalesDescriptionKey = hasSellerSales ? 'description' : 'noSalesDescription';

  const [showReverbSales, setShowReverbSales] = React.useState(hasReverbSales);

  return (
    <SalesMembershipsContext.Provider
      value={{
        salesMembershipsState,
        dispatch,
      }}
    >
      {hasReverbSales && (
        <SalesMembershipsTabs
          showReverbSales={showReverbSales}
          selectReverbSales={(boolean) => setShowReverbSales(boolean)}
        />
      )}

      <div>
        {showReverbSales && (
          <SalesCards
            salesType={SaleTypes.REVERB}
            salesMemberships={reverbSalesMemberships}
            description={I18n.t('discovery.listingsManagement.sellerListingCard.sales.modal.reverbSales.description')}
          />
        )}

        {!showReverbSales && (
          <SalesCards
            salesType={SaleTypes.SELLER}
            salesMemberships={sellerSalesMemberships}
            description={I18n.t(`discovery.listingsManagement.sellerListingCard.sales.modal.sellerSales.${sellerSalesDescriptionKey}`)}
            showCreateSaleButton={!hasSellerSales}
          />
        )}
      </div>
    </SalesMembershipsContext.Provider>
  );
}
