import I18n from 'i18n-js';
import React from 'react';
import classNames from 'classnames';

interface Props {
  display: string;
  showFree?: boolean;
  amountCents?: number;
}

export function PriceDisplay({
  display,
  amountCents = null,
  showFree = false,
}: Props) {
  function canShowFree() {
    return showFree && amountCents === 0;
  }

  function showMessage() {
    return !!showFree;
  }

  function text() {
    if (canShowFree()) {
      return I18n.t('commons.free');
    }

    return display;
  }

  if (!display) {
    return null;
  }

  const classes = classNames(
    'price-display',
    { 'price-display--message': showMessage() },
  );

  return (
    <span className={classes}>
      {text()}
    </span>
  );
}
