import React from 'react';

import { InputMode } from '@reverbdotcom/cadence/components';

import bind from '../bind';
import { NUMBER_OR_FLOAT_KEYS_REGEX } from '../constants';

interface IProps {
  autoCapitalize?: string;
  autoComplete?: string;
  autoCorrect?: string;
  autoFocus?: boolean;
  currencyPrefix?: string;
  currencySuffix?: string;
  disabled?: boolean;
  id?: string;
  inputName: string;
  inputType?: string;
  minLength?: number;
  maxLength?: number;
  numberOnly?: boolean;
  pattern?: string;
  placeholder?: string;
  required?: boolean;
  spellCheck?: boolean;
  className?: string;
  onChange?: any;
  inputTitleValue?: string; // This is the title attribute for the input, not a label
  inputMode?: InputMode;
}

// Does this keyboard event print a character to the input?
function isPrinting(e: React.KeyboardEvent<HTMLInputElement>) {
  return e.key.length === 1 && !e.altKey && !e.ctrlKey && !e.metaKey;
}

export default class UncontrolledInput extends React.Component<IProps, null> {
  @bind
  onKeyDown(event): void {
    if (this.props.numberOnly && isPrinting(event) && !event.key.match(NUMBER_OR_FLOAT_KEYS_REGEX)) {
      event.preventDefault();
    }
  }

  get inputType() {
    return this.props.inputType || 'text';
  }

  shouldRenderInputGroup() {
    return (!!this.props.currencyPrefix || !!this.props.currencySuffix);
  }

  inputGroupClass() {
    if (this.shouldRenderInputGroup()) return 'input-group__input';
    return this.props.className;
  }

  renderInputOrGroup() {
    if (this.shouldRenderInputGroup()) {
      return (
        <div className="input-group">
          {!!this.props.currencyPrefix &&
            <div className="input-group__text">
              {this.props.currencyPrefix}
            </div>
          }
          {this.renderInput()}
          {!!this.props.currencySuffix &&
            <div className="input-group__text">
              {this.props.currencySuffix}
            </div>
          }
        </div>
      );
    }

    return this.renderInput();
  }

  renderInput() {
    return (
      <input
        autoCapitalize={this.props.autoCapitalize}
        autoComplete={this.props.autoComplete}
        autoCorrect={this.props.autoCorrect}
        autoFocus={this.props.autoFocus}
        disabled={this.props.disabled}
        id={this.props.id || this.props.inputName}
        minLength={this.props.minLength}
        maxLength={this.props.maxLength}
        name={this.props.inputName}
        onChange={this.props.onChange}
        onKeyDown={this.onKeyDown}
        pattern={this.props.pattern}
        placeholder={this.props.placeholder}
        required={this.props.required}
        spellCheck={this.props.spellCheck}
        type={this.inputType}
        className={this.inputGroupClass()}
        title={this.props.inputTitleValue}
        inputMode={this.props.inputMode}
      />
    );
  }

  render() {
    if (this.props.inputType === 'textarea') {
      return (
        <textarea
          autoCapitalize={this.props.autoCapitalize}
          autoComplete={this.props.autoComplete}
          autoCorrect={this.props.autoCorrect}
          autoFocus={this.props.autoFocus}
          disabled={this.props.disabled}
          id={this.props.id || this.props.inputName}
          minLength={this.props.minLength}
          maxLength={this.props.maxLength}
          name={this.props.inputName}
          onChange={this.props.onChange}
          onKeyDown={this.onKeyDown}
          placeholder={this.props.placeholder}
          required={this.props.required}
          spellCheck={this.props.spellCheck}
          className={this.props.className}
          title={this.props.inputTitleValue}
        />
      );
    }

    return this.renderInputOrGroup();
  }
}
