/* eslint max-len: "off" */
export default {
  buyerDetails: {
    title: 'Who are you sending this to?',
    emailOffer: 'Email Offer',
    emailOfferWithBuyerName: 'Email Offer For %{buyerName}',
    firstName: 'Customer First Name',
    lastName: 'Customer Last Name',
    email: 'Customer Email Address',
    acceptsEmailMarketing: 'Customer Accepts Email Marketing',
  },
  productSearchForm: {
    addListings: 'Add Listings',
  },
  writeAMessage: 'Write a Message',
  sendOffer: 'Send Offer',
  explanation: {
    title: 'How do email offers work?',
    summary: 'Email offers send an email with your offer to the recipient and gives them 48 hours to respond.',
  },
  totals: {
    priceSubtotal: 'Subtotal',
    shippingSubtotal: 'Shipping',
    total: 'Total',
  },
  offerItem: {
    price: 'Offer Price',
    priceWithTaxHint: 'Offer Price ({{hint}})',
    shippingPrice: 'Shipping Price',
  },
  offerCreated: 'Your offer was sent!',
  offerCreationFailed: 'Sorry, something went wrong.',
};
