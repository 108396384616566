import I18n from 'i18n-js';
import React from 'react';
import BankAccountInformation from './bank_account_information';
import {
  PendingPlaidView,
} from './shared/plaid_helpers';

const PayoutBankAccountForm = ({
  accountRepEmail = null,
  accountRepName = null,
  bankChangesLocked = false,
  countryCodeOptions = null,
  dcProfileComplete,
  formPrefix,
  hideBankAccountEditForm,
  isGetPaid = false,
  isPlaidDcp,
  isPlaidEnabled,
  onCountryChange,
  onCurrencyChange,
  pendingPlaidAccountId,
  plaidAccountStatus,
  profileAttributes,
  selectedPayoutCurrency,
  shopCurrency,
  showCollapsedAchSummary,
  showPlaidSummary,
  summaryConfig,
  toggleEditing,
  shopCountryCode,
  payoutCurrencies = null,
}) => {
  const nameForField = React.useCallback(
    (fieldName) => (
      `${formPrefix}[${fieldName}]`
    ),
    [formPrefix],
  );
  const pendingAutomaticVerification = plaidAccountStatus?.includes('pending_automatic');
  const pendingManualVerification = plaidAccountStatus?.includes('pending_manual');

  return (
    <>
      {isPlaidEnabled && (pendingAutomaticVerification || pendingManualVerification) && (
        <PendingPlaidView plaidAccountStatus={plaidAccountStatus}  />
      )}

      {hideBankAccountEditForm && dcProfileComplete && !showCollapsedAchSummary && (
        <p className="mb-2">
          {/* disabling inline here so we can enable this rule globally, but this should be fixed the next time this file is touched */}
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a data-nevermind-link onClick={toggleEditing}>
            {I18n.t('discovery.payoutMethod.directCheckoutProfile.nevermind')}
          </a>
        </p>
      )}

      <p className="strong mb-0">
        {I18n.t('discovery.payoutMethod.directCheckoutProfile.bankInfo')}
        <span className="ml-space size-90 opacity-60">
          <span className="fa fa-lock mr-space" />
          {I18n.t('discovery.payoutMethod.directCheckoutProfile.storedSecurelyAndEncrypted')}
        </span>
      </p>

      {(showCollapsedAchSummary || showPlaidSummary) && (
        <div className="mt-0 mb-2" data-readonly-profile-fields>
          <div>
            <p className="mb-0">{profileAttributes.nameOnAccount}</p>
            <p className="mb-0">{profileAttributes.maskedNameSummary}</p>
            {profileAttributes.bankName && (
              <p className="mb-0">{profileAttributes.bankName}</p>
            )}
          </div>
        </div>
      )}

      {!(isPlaidEnabled && pendingAutomaticVerification) && <BankAccountInformation
        accountRepEmail={accountRepEmail || 'sellerengagement@reverb.com'}
        accountRepName={accountRepName || 'Reverb'}
        bankChangesLocked={bankChangesLocked}
        countryCodeOptions={countryCodeOptions}
        formPrefix={formPrefix}
        isGetPaid={isGetPaid}
        isPlaidDcp={isPlaidDcp}
        isPlaidEnabled={isPlaidEnabled}
        nameForField={nameForField}
        onCountryChange={onCountryChange}
        onCurrencyChange={onCurrencyChange}
        payoutCurrencies={payoutCurrencies}
        pendingPlaidAccountId={pendingManualVerification ? pendingPlaidAccountId : null}
        profileAttributes={profileAttributes}
        selectedPayoutCurrency={selectedPayoutCurrency}
        shopCountryCode={shopCountryCode}
        shopCurrency={shopCurrency}
        showCollapsedAchSummary={showCollapsedAchSummary}
        showPlaidSummary={showPlaidSummary}
        summaryConfig={summaryConfig}
        toggleEditing={toggleEditing}
      />}
    </>
  );
};

export default PayoutBankAccountForm;
