// TODO update this to import from commons
// import { gql } from '@reverbdotcom/commons/src/gql';
// eslint-disable-next-line no-restricted-imports
import { gql } from '@apollo/client';
import React from 'react';
import {
  CheckoutActionHookFields,
  core_apimessages_CheckoutAction_Type as CheckoutActionType,
  core_apimessages_CheckoutPaymentMethod_Type as CheckoutPaymentMethodType,
} from '@reverbdotcom/commons/src/gql/graphql';
import { IUrl as Url, useURL } from '@reverbdotcom/commons/src/url_context';
import Location from '../lib/wrapped_location';
import { parseURL } from '@reverbdotcom/commons/src/link_parser';
import { buildCheckoutPaths } from '../lib/checkoutPaths';

export type CheckoutAction = CheckoutActionHookFields.Fragment;

export interface Checkout {
  id?: string;
  action?: CheckoutAction;
  paymentMethod?: {
    type?: CheckoutPaymentMethodType;
  }
}

export function useCheckoutLayoutAction(action: CheckoutAction) {
  const url = useURL();
  const shouldRedirect = hasProcessingRedirectUrl(url, action);
  const redirectUrl = checkoutActionURL(action);

  React.useEffect(() => {
    if (shouldRedirect) {
      Location.assign(redirectUrl);
    }
  }, [shouldRedirect, redirectUrl]);

  return { hasRedirect: shouldRedirect };
}

export function useCheckoutProcessingAction(checkout: Checkout, onRedirect: () => void) {
  const { hasRedirect: shouldRedirect, redirectUrl } = paymentProcessingAction(
    useURL(),
    checkout,
  );

  React.useEffect(() => {
    if (shouldRedirect) {
      onRedirect();
      Location.replace(redirectUrl);
    }
  }, [shouldRedirect, redirectUrl, onRedirect]);

  return { hasRedirect: shouldRedirect };
}

function paymentProcessingAction(url: Url, checkout: Checkout) {
  const action = checkout?.action;

  if (isAwaitingPayment(action)) {
    const checkoutPaths = buildCheckoutPaths(checkout.id);

    return {
      hasRedirect: true,
      redirectUrl: checkoutPaths.show(),
    };
  }

  if (isRedirectUrlDifferent(url, action)) {
    return {
      hasRedirect: true,
      redirectUrl: checkout.action.redirectUrl,
    };
  }

  return {
    hasRedirect: false,
    redirectUrl: null,
  };
}

function hasProcessingRedirectUrl(url: Url, action: CheckoutAction) {
  return !hasPaymentFailure(action) && isRedirectUrlDifferent(url, action);
}

function isRedirectUrlDifferent(url: Url, action: CheckoutAction) {
  const redirectUrl = checkoutActionURL(action);
  const parsedRedirectUrl = parseURL(redirectUrl);

  if (!parsedRedirectUrl.pathname) { return false; }

  return url.pathname !== parsedRedirectUrl.pathname;
}

function isAwaitingPayment(action: CheckoutAction) {
  return action?.type === CheckoutActionType.AWAITING_PAYMENT;
}

function hasPaymentFailure(action: CheckoutAction) {
  return action?.type === CheckoutActionType.PAYMENT_FAILURE;
}

export function withReverbAppInput(url: Url): { reverbApp: string } {
  return { reverbApp: reverbAppQueryValue(url) };
}

export function reverbAppQueryValue(url: Url) {
  return url?.query?._reverb_app || '';
}

function checkoutActionURL(action: CheckoutAction) {
  if (!action) { return null; }

  return action.redirectUrl;
}

export const CheckoutActionHookFragment = gql`
  fragment CheckoutActionHookFields on CheckoutAction {
    _id
    type
    redirectUrl
  }
`;
