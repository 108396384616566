import React from 'react';
import LocalePickerModal from './locale_picker_modal';

interface IProps {
  className: string;
  children: React.ReactNode;
}

export default function LocalePickerButton(props: IProps) {
  const [isModalOpen, setModalOpen] = React.useState(false);

  const closeModal = React.useCallback(() => {
    setModalOpen(false);
  }, []);

  const openModal = React.useCallback(() => {
    setModalOpen(true);
  }, []);

  return (
    <>
      {isModalOpen && <LocalePickerModal isOpen onClose={closeModal} />}
      <button
        type="button"
        className={props.className}
        onClick={openModal}
      >
        {props.children}
      </button>
    </>
  );
}
