import { ChildProps } from '@apollo/client/react/hoc';
// TODO update this to import from commons
// import { gql } from '@reverbdotcom/commons/src/gql';
// eslint-disable-next-line no-restricted-imports
import { gql } from '@apollo/client';
import React from 'react';
import { CoreSiteBannerHero } from '@reverbdotcom/commons/src/gql/graphql';
import SiteBannerHero from '@reverbdotcom/commons/src/cms/components/site_banner_hero';
import { withGraphql } from '@reverbdotcom/commons/src/with_graphql';

export function SiteBannerHeroContainer({ data }: ChildProps<{}, CoreSiteBannerHero.Query>) {
  if (data?.loading) {
    return null;
  }

  function siteBannerProps() {
    if (data.siteBanner) {
      const urlHref = data.siteBanner.url?.href;
      const sponsorshipType = data.siteBanner.contentSponsorship?.sponsorshipType;
      const partnershipName = data.siteBanner.contentSponsorship?.partnershipName;

      return {
        ...data.siteBanner,
        url: urlHref,
        sponsoredContentType: sponsorshipType,
        sponsoredContentPartner: partnershipName,
      };
    }

    return null;
  }

  return (
    <SiteBannerHero {...siteBannerProps()} />
  );
}

const withQuery = withGraphql<CoreSiteBannerHero.Query>(
  gql`
    query Core_SiteBannerHero {
      siteBanner {
        id
        heading
        subheading
        callToAction
        url {
          href
        }
        hasPhotos
        imageUrl
        contentSponsorship {
          sponsorshipType
          partnershipName
        }
      }
    }
  `,
  {
    options: {
      ssr: true,
    },
  },
);

export default withQuery(SiteBannerHeroContainer);
