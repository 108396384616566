import { core_apimessages_AddressEntry as AddressEntry } from '@reverbdotcom/commons/src/gql/graphql';

export const emptyAddressEntry: AddressEntry = {
  countryCode: '',
  name: '',
  streetAddress: '',
  extendedAddress: '',
  locality: '',
  region: '',
  postalCode: '',
  phone: '',
};
