import React from 'react';
import I18n from 'i18n-js';
import Modal from '@reverbdotcom/commons/src/components/modal';
import SanitizedRender from '@reverbdotcom/commons/src/components/sanitized_render';
import { Paths } from './shared/url_helpers';
import { RCCheckbox, RCTag, RCTooltip, RCButton } from '@reverbdotcom/cadence/components';

interface IProps {
  isOpen: boolean;
  onClose?: () => void;
  isAdDataChecked: boolean;
  isAdStorageChecked: boolean;
  isAdPersonalizationChecked: boolean;
  isAnalyticsStorageChecked: boolean;
  updatePrivacySettings: (e) => void;
  onSubmit: () => void;
  allowBackgroundScroll?: boolean;
}

function UserPrivacySettingModal(
  {
    isOpen,
    onClose = null,
    onSubmit,
    isAdDataChecked,
    isAdStorageChecked,
    isAdPersonalizationChecked,
    isAnalyticsStorageChecked,
    updatePrivacySettings,
    allowBackgroundScroll = false,
  }: IProps,
) {

  const privacyAttributes = [
    {
      name: 'adData',
      checked: isAdDataChecked,
    },
    {
      name: 'adStorage',
      checked: isAdStorageChecked,
    },
    {
      name: 'adPersonalization',
      checked: isAdPersonalizationChecked,
    },
    {
      name: 'analyticsStorage',
      checked: isAnalyticsStorageChecked,
    },
  ];

  const checkBoxLabels = privacyAttributes.map((attribute) => {
    return (<div key={attribute.name} className="label-with-checkbox mb-1">
      <RCCheckbox
        label={
          <RCTooltip
            text={
              <div className="size-90">
                {I18n.t(`discovery.privacySettingsModal.thirdPartyData.tooltip.${attribute.name}`)}
              </div>
            }
            placement="right"
          >
            {I18n.t(`discovery.privacySettingsModal.thirdPartyData.label.${attribute.name}`)}
          </RCTooltip>
        }
        id={attribute.name}
        name={attribute.name}
        onChange={updatePrivacySettings}
        checked={attribute.checked}
      />
    </div>);
  });

  return (
    <Modal
      isOpen={isOpen}
      headerTitle={I18n.t('discovery.privacySettingsModal.privacySettings.title')}
      onRequestClose={onClose}
      allowBackgroundScroll={allowBackgroundScroll}
    >
      <div className="reverb-modal__dialog-body">
        <div className="cms-text size-80">
          <p className="size-100 mb-1">
            {I18n.t('discovery.privacySettingsModal.privacySettings.listIntro')}
          </p>
          <ul className="size-100">
            <li>{I18n.t('discovery.privacySettingsModal.privacySettings.listItem0')}</li>
            <li>{I18n.t('discovery.privacySettingsModal.privacySettings.listItem1')}</li>
            <li>{I18n.t('discovery.privacySettingsModal.privacySettings.listItem2')}</li>
            <li>{I18n.t('discovery.privacySettingsModal.privacySettings.listItem3')}</li>
            <li>{I18n.t('discovery.privacySettingsModal.privacySettings.listItem4')}</li>
            <li>{I18n.t('discovery.privacySettingsModal.privacySettings.listItem5')}</li>
            <li>{I18n.t('discovery.privacySettingsModal.privacySettings.listItem6')}</li>
            <li>{I18n.t('discovery.privacySettingsModal.privacySettings.listItem7')}</li>
          </ul>
          <p className="size-90">
            <SanitizedRender
              html={I18n.t('discovery.privacySettingsModal.privacySettings.listOutro', {
                cookieLinkOpen: `<a href="${Paths.cookiePolicy.expand({})}" target="_blank">`,
                cookieLinkClose: '</a>',
                privacyLinkOpen: `<a href="${Paths.privacyPolicy.expand({})}" target="_blank">`,
                privacyLinkClose: '</a>',
              })}
            />
          </p>
          <h4>
            {I18n.t('discovery.privacySettingsModal.requiredCookies.title')}
            <span className="ml-2 size-90">
              <RCTag variant="success">
                {I18n.t('discovery.privacySettingsModal.requiredCookies.alwaysOn')}
              </RCTag>
            </span>
          </h4>
          <p className="size-100">
            {I18n.t('discovery.privacySettingsModal.requiredCookies.text')}
          </p>
          <h4>
            {I18n.t('discovery.privacySettingsModal.thirdPartyData.title')}
          </h4>
          {checkBoxLabels}
        </div>
      </div>
      <div className="reverb-modal__actions">
        <RCButton
          variant="filled"
          onClick={onSubmit}
        >
          {I18n.t('commons.modalDialog.save')}
        </RCButton>
      </div>
    </Modal>
  );
}

export default UserPrivacySettingModal;
