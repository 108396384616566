import { ChildProps } from '@apollo/client/react/hoc';
// TODO update this to import from commons
// import { gql } from '@reverbdotcom/commons/src/gql';
// eslint-disable-next-line no-restricted-imports
import { gql } from '@apollo/client';
import React from 'react';
import I18n from 'i18n-js';

import { withGraphql } from '@reverbdotcom/commons/src/with_graphql';

import {
  MParticleEventName,
  trackEvent,
} from '@reverbdotcom/commons/src/elog/mparticle_tracker';

import { RCButton, RCLoadingBars, RCTextWithIcon } from '@reverbdotcom/cadence/components';

import { ChevronRightIcon, ChevronLeftIcon } from '@reverbdotcom/cadence/icons/react';

import TransactionTable from './transaction_table';
import { I18N } from '@reverbdotcom/commons/src/components/translate';

import {
  SELLER_COUNTRY_CODES,
  PAID_TRANSACTION_STATUSES,
} from '../../show/price_guide_tool_transaction_graph_container';

import {
  CSP,
  SearchPriceGuideToolTransactionTable,
} from '@reverbdotcom/commons/src/gql/graphql';

export const DEFAULT_PAGE_SIZE = 10;
export const FIRST_PAGE = 1;
export const DEFAULT_OFFSET = 0;

interface IQueryVariableArgs {
  canonicalProductIds: string[];
  conditionSlugs: string[];
  offset?: number;
  pageSize?: number;
}

export function queryVariables({
  canonicalProductIds,
  conditionSlugs,
  offset = DEFAULT_OFFSET,
  pageSize,
}: IQueryVariableArgs) {
  return {
    canonicalProductIds,
    conditionSlugs,
    sellerCountries: SELLER_COUNTRY_CODES,
    actionableStatuses: PAID_TRANSACTION_STATUSES,
    limit: pageSize || DEFAULT_PAGE_SIZE,
    offset,
  };
}

function canonicalProductIdsForTable({ canonicalProductIds, csp }) {
  return canonicalProductIds ?? csp.canonicalProducts.map(({ id }) => id);
}
interface IExternalProps {
  csp: CSP;
  conditionSlugs: string[];
  ssr: boolean;
  canonicalProductIds?: string[];
  pageSize?: number;
}

export type IProps = ChildProps<IExternalProps, SearchPriceGuideToolTransactionTable.Query>;

export function CSPTransactionTableContainer({
  canonicalProductIds,
  csp,
  conditionSlugs,
  data,
  pageSize,
}: IProps) {
  const cpIds = canonicalProductIdsForTable({ canonicalProductIds, csp });

  const {
    loading,
    priceRecordsSearch = {},
  } = data;

  const {
    priceRecords = [],
    total = 0,
    offset = 0,
  } = priceRecordsSearch;

  const filteredPriceRecords = priceRecords
    .filter(priceRecord => !!priceRecord.createdAt)
    .map(priceRecord => ({
      timestamp: priceRecord.createdAt,
      conditionDisplayName: priceRecord.condition.displayName,
      priceDisplay: priceRecord.amountProduct.display,
      id: priceRecord._id,
    }));

  const queryPageSize = pageSize || DEFAULT_PAGE_SIZE;

  const currentPage = offset === DEFAULT_OFFSET ? FIRST_PAGE : (offset / queryPageSize) + FIRST_PAGE;
  const totalPages = Math.ceil(total / queryPageSize);
  const isFirstPage = currentPage === FIRST_PAGE;
  const isLastPage = currentPage === totalPages;

  const previousPage = () => {
    const newPage = currentPage - 1;
    const newOffset = (newPage - 1) * queryPageSize;

    trackEvent({
      eventName: MParticleEventName.ClickedPreviousOnCSPPriceGuideTransactionTable,
      componentName: 'CSPTransactionTableContainer',
      cspId: csp.id,
      cspSlug: csp.slug,
    });

    data.refetch({
      conditionSlugs,
      canonicalProductIds: cpIds,
      offset: newOffset,
    });
  };

  const nextPage = () => {
    const newPage = currentPage + 1;
    const newOffset = (newPage - 1) * queryPageSize;

    trackEvent({
      eventName: MParticleEventName.ClickedNextOnCSPPriceGuideTransactionTable,
      componentName: 'CSPTransactionTableContainer',
      cspId: csp.id,
      cspSlug: csp.slug,
    });

    data.refetch({
      conditionSlugs,
      canonicalProductIds: cpIds,
      offset: newOffset,
    });
  };

  return (
    <div className="csp-transaction-table-container">
      <div className="csp-transaction-table-container__header">
        <h3>
          {
            I18n.t('discovery.priceGuideTool.cspModule.transactionTable.title')
          }
        </h3>

        {loading ? (
          <div className="csp-transaction-table-container__header__loader">
            <RCLoadingBars size="large" />
          </div>
        ) : (
          <TransactionTable
            priceRecords={filteredPriceRecords}
          />
        )}
      </div>

      <div className="csp-transaction-table-container__footer">
        <I18N
          className="csp-transaction-table-container__footer__disclaimer"
          tag="p"
          html
          text="discovery.priceGuideTool.transactionTable.disclaimer"
          args={{ supOpen: '<sup>', supClose: '</sup>' }}
        />
        <nav className="csp-transaction-table-container__footer__pagination-buttons">
          <RCButton
            size="mini"
            onClick={previousPage}
            disabled={isFirstPage}
          >
            <RCTextWithIcon
              svgComponent={ChevronLeftIcon}
              children=""
              title={I18n.t('discovery.priceGuideTool.transactionTable.previousButton')}
            />
          </RCButton>

          <div>
            <RCButton
              size="mini"
              onClick={nextPage}
              disabled={isLastPage}
            >
              <RCTextWithIcon
                svgComponent={ChevronRightIcon}
                children=""
                title={I18n.t('discovery.priceGuideTool.transactionTable.nextButton')}
              />
            </RCButton>
          </div>
        </nav>
      </div>
    </div>
  );
}

export const TransactionTablePriceRecordsDataFragment = gql`
fragment TransactionTablePriceRecordsData on PublicPriceRecord {
	_id
	condition {
		displayName
	}
	createdAt {
		seconds
	}
	amountProduct {
		display
	}
}
`;

export const transactionsTableQuery = gql`
  query Search_PriceGuideTool_TransactionTable(
    $canonicalProductIds: [String],
    $sellerCountries: [String],
    $conditionSlugs: [String],
    $createdAfterDate: String,
    $actionableStatuses: [String],
    $limit: Int,
    $offset: Int
  ) {
    priceRecordsSearch(input: {
      canonicalProductIds: $canonicalProductIds,
      sellerCountries: $sellerCountries,
      listingConditionSlugs: $conditionSlugs,
      createdAfterDate: $createdAfterDate,
      actionableStatuses: $actionableStatuses,
      limit: $limit,
      offset: $offset
    }) {
      priceRecords {
        _id
        ...TransactionTablePriceRecordsData
      }
      total
      offset
    }
  }
  ${TransactionTablePriceRecordsDataFragment}
`;

const withTransactionsTableQuery = withGraphql<IProps, SearchPriceGuideToolTransactionTable.Query>(
  transactionsTableQuery,
  {
    options: ({
      canonicalProductIds,
      conditionSlugs,
      csp,
      pageSize,
      ssr,
    }) => {

      return {
        ssr: ssr,
        errorPolicy: 'ignore',
        variables: queryVariables({
          canonicalProductIds: canonicalProductIdsForTable({ canonicalProductIds, csp }),
          conditionSlugs,
          pageSize,
        }),
      };
    },
  },
);

export default withTransactionsTableQuery(props => <CSPTransactionTableContainer {...props} />);
