import React from 'react';
import I18n from 'i18n-js';
import PacklinkLoginAccountForm from './packlink_login_account_form';
import { PacklinkLoginPrompt, PacklinkRegisterPrompt } from './packlink_account_prompt';
import PacklinkAccountStatus from './packlink_account_status';
import { PacklinkAuthAction as AuthAction } from './packlink_auth_action';
import PacklinkRegisterAccountForm from './packlink_register_account_form';
import { RCAlertBox }  from '@reverbdotcom/cadence/components';
import PacklinkInfoAlert from './packlink_info_alert';
import { core_apimessages_PacklinkAccount_Status as AccountStatus } from '@reverbdotcom/commons/src/gql/graphql';

interface Props {
  onAuthSuccess(): void;
}

export enum Display {
  Status = 'STATUS',
  Form = 'FORM',
  Error = 'ERROR',
}

export default function PacklinkAuthForm(props: Props) {
  const [display, setDisplay] = React.useState<Display>(Display.Form);
  const [authAction, setAuthAction] = React.useState(AuthAction.Register);

  function onFormSubmit() {
    setDisplay(Display.Status);
  }

  function onStatusUpdate(status: AccountStatus) {
    if (status === AccountStatus.FAILED) {
      setDisplay(Display.Error);
    } else if (status === AccountStatus.COMPLETED) {
      setDisplay(Display.Form);
      props.onAuthSuccess && props.onAuthSuccess(); // eslint-disable-line
    }
  }

  function alternativePrompt() {
    if (authAction === AuthAction.Login) {
      return <PacklinkRegisterPrompt data-register-authAction switchPrompt={setAuthAction} />;
    }

    return <PacklinkLoginPrompt data-login-authAction switchPrompt={setAuthAction} />;
  }

  function displayContent() {
    if (display === Display.Status) {
      return statusContent();
    }

    const authActionFailed = authAction === AuthAction.Register ? 'registrationFailed' : 'loginFailed';

    return (
      <>
        {display === Display.Error &&
          <div className="mb-4">
            <RCAlertBox type="error">
              {I18n.t(`discovery.packlink.account.${authActionFailed}`)}
            </RCAlertBox>
          </div>
        }
        {formContent()}
      </>
    );
  }

  function statusContent() {
    return (
      <div className="d-flex fx-align-center fx-justify-center height-vh-10">
        <div className="d-flex">
          <PacklinkAccountStatus data-account-status onStatusUpdate={onStatusUpdate} />
        </div>
      </div>
    );
  }

  function formContent() {
    if (authAction === AuthAction.Login) {
      return <PacklinkLoginAccountForm data-login-account onComplete={onFormSubmit} />;
    }

    return <PacklinkRegisterAccountForm data-register-account onComplete={onFormSubmit} />;
  }

  return (
    <div>
      <div className="cms-text">
        <h2 className="align-center">{I18n.t(`discovery.packlink.${authAction.toLowerCase()}.header`)}</h2>
      </div>
      <div className="scaling-mb-4">
        <PacklinkInfoAlert display={display} />
      </div>
      <div className="mb-4 pb-4 bdb-1 bd-primary">
        {displayContent()}
      </div>
      <div className="mb-4">
        {alternativePrompt()}
      </div>
    </div>
  );
}
