import React from 'react';
import { useUser } from '@reverbdotcom/commons/src/user_hooks';
import { useKlarnaMessagingEmbed } from './klarnaMessagingEmbed';
import classNames from 'classnames';

interface Props {
  amountCents: number;
  textOnly: boolean;
}

export function KlarnaCreditPlacement({ amountCents, textOnly }: Props) {
  const user = useUser();

  useKlarnaMessagingEmbed();

  const classes = classNames(
    'klarna-credit-placement',
    { 'klarna-credit-placement--text-only': textOnly },
  );

  function key() {
    if (textOnly) {
      return 'credit-promotion-auto-size';
    }
    return 'credit-promotion-badge';
  }

  return (
    <div className={classes}>
      <klarna-placement
        data-key={key()}
        data-locale={user.fiveDigitLocale}
        data-purchase-amount={amountCents}
      />
    </div>
  );
}
