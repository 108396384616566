// TODO update this to import from commons/src/gql
// eslint-disable-next-line no-restricted-imports
import { gql } from '@apollo/client';
import React from 'react';
import { isEqual } from 'lodash';

import { core_apimessages_Ad, CSP } from '../gql/graphql';
import CSPSquareCard from './csp_square_card';
import CSPWideCard from './csp_wide_card';
import { ConditionFilter } from '../cms/lib/metadata_helper';
import OverflowingRow from './overflowing_row';
import Tiles from './tiles';
import { withUserContext, IUser, IUserContext } from './user_context_provider';
import { trackEvent, trackCSPView } from '../elog/mparticle_tracker';
import { AdCard } from './ads/ad_card';

export interface IProps {
  title?: JSX.Element | React.ReactNode | string;
  subtitleHtml?: string;
  ledeHtml?: string;
  csps: CSP[];
  conditionFilter?: ConditionFilter;
  loading: boolean;
  action?: React.ReactElement;
  expectedCount?: number;
  displayAsGrid?: boolean;
  hideMetadata?: boolean;
  includeItemListMetadata?: boolean;
  id?: string;
  user?: IUser;
  shouldLinkToSellForm?: boolean;
  trackingComponentName?: string;
  shouldTrack?: boolean;
  trackingQuery?: string;
  ad?: core_apimessages_Ad;
  hidePrices?: boolean;
  shouldDisplayAsLanding?: boolean;
  withSidebar?: boolean;
}

export const CSPAdditionalTrackingFieldsFragment = gql`
  fragment CSPAdditionalTrackingFields on CSP {
    _id
    id
    slug
    brand {
      _id
      slug
    }
    categories {
      _id
      slug
      rootSlug
      parentCategories {
        _id
        slug
      }
    }
    inventory {
      usedTotal
      newTotal
    }
  }
`;

export const CSPCardFragment = gql`
  fragment CSPCard on CSP {
    _id
    id
    title
    slug
    averageReviewRating
    reviewsCount
    webLink {
      path
      href
    }
    image(input: {transform: "card_square"}) {
      _id
      source
    }
    brand {
      _id
      slug
      name
    }
    categories {
      _id
      slug
      rootSlug
      name
      parentCategories {
        _id
        name
      }
    }
    inventory {
      newLowPrice {
        display
        amount
        amountCents
        currency
      }
      usedLowPrice {
        display
        amount
        amountCents
        currency
      }
      usedTotal
      newTotal
    }
    finishes
    ... CSPAdditionalTrackingFields
  }
  ${CSPAdditionalTrackingFieldsFragment}
  `;

export class CSPRow extends React.Component<IProps & IUserContext, null> {
  componentName() {
    return this.props.trackingComponentName || 'CSPRow';
  }

  displayStyle() {
    return this.getCount() > 1 ? 'compareText' : null;
  }

  componentDidMount() {
    const csps = this.props?.csps as CSP[];
    if (!csps.length || !this.props.shouldTrack) { return; }

    trackEvent(
      trackCSPView(
        csps,
        this.componentName(),
        this.displayStyle(),
      ),
    );
  }

  componentDidUpdate(oldProps) {
    const newCSPs = this.props?.csps as CSP[];
    const oldCSPs = oldProps?.csps as CSP[];

    if (!newCSPs.length || !this.props.shouldTrack) { return; }

    if (!isEqual(newCSPs, oldCSPs)) {
      trackEvent(
        trackCSPView(
          newCSPs,
          this.componentName(),
          this.displayStyle(),
        ),
      );
    }
  }

  getCount() {
    if (this.props.loading) return this.props.expectedCount;

    return this.props.csps.length;
  }

  shouldBeOneWide() {
    return this.getCount() === 1 && !this.props.ad;
  }

  shouldGrow() {
    if (!this.props.displayAsGrid) return true;
    if (this.props.displayAsGrid && this.getCount() <= 3) return true;
  }

  shouldRenderAsHorizontal(): boolean {
    return this.getCount() < 4;
  }

  renderPlaceholder() {
    if (this.props.expectedCount === 1) {
      return <CSPWideCard />;
    }

    return <CSPSquareCard />;
  }

  renderCards() {
    const { csps } = this.props;

    if (this.shouldBeOneWide()) {
      return this.renderWideCard(csps[0]);
    }

    return csps.map((page, idx) => (
      this.renderSquareCard(page, idx)
    ));
  }

  renderWideCard(csp: CSP) {
    return (
      <CSPWideCard
        conditionFilter={this.props.conditionFilter}
        csp={csp}
        hideMetadata={this.props.hideMetadata}
        includeItemListMetadata={this.props.includeItemListMetadata}
        shouldLinkToSellForm={this.props.shouldLinkToSellForm}
        trackingQuery={this.props.trackingQuery}
        hidePrices={this.props.hidePrices}
      />
    );
  }

  renderSquareCard(csp: CSP, index) {
    return (
      <CSPSquareCard
        conditionFilter={this.props.conditionFilter}
        csp={csp}
        key={csp.id}
        position={index}
        hideMetadata={this.props.hideMetadata}
        includeItemListMetadata={this.props.includeItemListMetadata}
        renderAsHorizontal={this.shouldRenderAsHorizontal()}
        shouldLinkToSellForm={this.props.shouldLinkToSellForm}
        trackingQuery={this.props.trackingQuery}
        hideCallout={this.props.hidePrices}
      />
    );
  }

  renderAd() {
    if (!this.props.ad) return false;

    return (
      <AdCard
        ad={this.props.ad}
        key={this.props.ad.uuid}
        componentName={this.componentName()}
      />
    );
  }

  render() {
    if (!this.props.loading && !this.props.csps.length) {
      return null;
    }

    return (
      <OverflowingRow
        title={this.props.title}
        subtitleHtml={this.props.subtitleHtml}
        ledeHtml={this.props.ledeHtml}
        action={this.props.action}
        collectionCount={this.getCount()}
        id={this.props.id}
        shouldDisplayAsLanding={this.props.shouldDisplayAsLanding}
      >
        <div
          itemScope={this.props.includeItemListMetadata}
          itemType={this.props.includeItemListMetadata ? 'http://schema.org/ItemList' : null}
        >
          <Tiles
            placeholder={this.renderPlaceholder()}
            loading={this.props.loading}
            expectedCount={this.props.expectedCount}
            singleRow={!this.props.displayAsGrid}
            oneWide={this.shouldBeOneWide()}
            largeTiles={!this.shouldBeOneWide()}
            threeWide={this.shouldRenderAsHorizontal()}
            grow={this.shouldGrow()}
          >
            {this.renderAd()}
            {this.renderCards()}
          </Tiles>
        </div>
      </OverflowingRow>
    );
  }
}

export default withUserContext(CSPRow);
