import { get, set, remove } from 'js-cookie';

export default {
  get(cookieName) {
    return get(cookieName);
  },

  set(cookieName, value, expiresDays) {
    const opts = {};
    if (expiresDays) {
      opts.expires = expiresDays;
    }

    return set(cookieName, value, opts);
  },

  remove(cookieName) {
    return remove(cookieName);
  },
};
