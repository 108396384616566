export function getOffsetFromPage(page: string, limit: number): number {
  return (parseInt(page || '1', 10) - 1) * limit;
}

export function getPage(offset, limit) {
  const page = Math.ceil(offset / limit) + 1;

  if (page < 1) {
    return 1;
  }

  return page;
}

export function getTotalPages(total, limit) {
  return Math.ceil(total / limit);
}
