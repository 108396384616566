/* eslint-disable new-cap */
import React, { useContext } from 'react';
import I18n from 'i18n-js';

import { Redirect } from '@reverbdotcom/commons/src/routing_result';
import { RCButton, RCTextWithIcon } from '@reverbdotcom/cadence/components';
import {
  core_apimessages_ListingStateUpdate,
} from '@reverbdotcom/commons/src/gql/graphql';
import { CheckCircleIcon } from '@reverbdotcom/cadence/icons/react';
import { MParticleEventName, trackEvent } from '@reverbdotcom/commons/src/elog/mparticle_tracker';

import SellerListingCardContext from '../SellerListingCardContext';
import { ActionType } from '../sellerListingCardReducer';
import { Paths } from '../../shared/url_helpers';
import SellerListingsCollectionContext from '../SellerListingsCollectionContext';
import { ActionType as CollectionActionType, SellerListing } from '../sellerListingsCollectionReducer';
import { useUpdateListingStateMutation } from './useUpdateListingStateMutation';
import { publishedListingSprigEvent } from '@reverbdotcom/commons/src/elog/sprig_events';
import { useExpEnabled } from '@reverbdotcom/commons/src/user_hooks';
import experiments from '@reverbdotcom/commons/src/experiments';

export const COMPONENT_NAME = 'PublishButton';
interface IExternalProps {
  listing: SellerListing;
}

function PublishButton({ listing }: IExternalProps) {
  const { dispatch: cardDispatch } = useContext(
    SellerListingCardContext,
  );

  const [updateListingState, {
    data,
    loading,
    errors,
  }] = useUpdateListingStateMutation();

  const {
    dispatch: collectionDispatch,
    useLazySellerListingsQuery,
  } = useContext(SellerListingsCollectionContext);

  const { refetchSellerListings } = useLazySellerListingsQuery();

  const shouldSendSprigEvents = useExpEnabled(experiments.WEB_CSAT);

  React.useEffect(() => {
    if (loading) return;
    if (errors.length) return handleErrors();
    if (data) handleSuccess();
  }, [loading]);

  function handleErrors() {
    const redirectUrl = Paths.listingSubmit.expand({
      query: {
        id: listing.id,
        react_sell_form_publish: true,
      },
    });

    Redirect({
      url: redirectUrl,
      fullPageReload: true,
    });
  }

  function handleSuccess() {
    refetchSellerListings([listing.id]);

    if (shouldSendSprigEvents) {
      publishedListingSprigEvent(listing);
    }

    cardDispatch({
      type: ActionType.SHOW_TOAST,
      payload: {
        isSuccess: true,
        message: I18n.t(
          'discovery.listingsManagement.sellerListingCard.publishSuccess',
        ),
      },
    });
  }

  function startPublish() {
    collectionDispatch({
      type: CollectionActionType.LOAD_LISTINGS,
      payload: {
        listingIds: [listing.id],
      },
    });

    updateListingState({
      variables: {
        input: {
          productId: listing.id,
          newState: core_apimessages_ListingStateUpdate.LIVE,
        },
      },
    });
  }

  function handleClick() {
    trackEvent({
      eventName: MParticleEventName.ClickedComponent,
      componentName: COMPONENT_NAME,
      listingId: listing.id,
    });

    startPublish();
  }

  return (
    <>
      <div className="tablet-d-none">
        <RCButton
          onClick={handleClick}
          variant="filled"
          disabled={!listing.publishValidation?.isValid || loading}
          fullWidth
        >
          <RCTextWithIcon svgComponent={CheckCircleIcon} placement="right">
            {I18n.t('discovery.listingsManagement.sellerListingCard.publishListing')}
          </RCTextWithIcon>
        </RCButton>
      </div>

      <div className="seller-listing-card-footer__tablet-button-container">
        <RCButton
          onClick={handleClick}
          variant="filled"
          disabled={!listing.publishValidation?.isValid || loading}
          fullWidth
        >
          <RCTextWithIcon svgComponent={CheckCircleIcon} placement="right">
            {I18n.t('discovery.listingsManagement.sellerListingCard.publish')}
          </RCTextWithIcon>
        </RCButton>
      </div>
    </>
  );
}

export default PublishButton;
