import React from 'react';
import bind from '../bind';

interface Props {
  verticalOffset: number;
  onlyScrollUp?: boolean;
}

class ScrollTarget extends React.Component<Props, null> {
  scrollTarget = null;

  static defaultProps = {
    verticalOffset: 0,
  };

  constructor(props) {
    super(props);
    this.scrollToTop = this.scrollToTop.bind(this);
  }

  @bind
  createRef(element) {
    this.scrollTarget = element;
  }

  scrollToTop() {
    if (this.shouldScroll()) {
      window.scrollTo({ left: 0, top: this.clientTop + window.scrollY, behavior: 'smooth' });
    }
  }

  shouldScroll() {
    if (this.props.onlyScrollUp) {
      return this.clientTop < 0;
    }
    return true;
  }

  get clientTop() {
    const _scrollTarget = this.scrollTarget as any;
    return _scrollTarget.getBoundingClientRect().top + this.props.verticalOffset;
  }

  render() {
    return (
      <div className="offset-anchor-wrapper">
        <div className="offset-anchor scrollTarget" ref={this.createRef}></div>
      </div>
    );
  }
}

export default ScrollTarget;
