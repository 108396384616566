import PropTypes from 'prop-types';
import React from 'react';
import moment from 'moment';
import { RCStarRating, UserStore, APIRequest } from '../index';
import ReviewForm from './form';
import I18n from 'i18n-js';

const TITLE_LENGTH = 80;

class Review extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      formError: null,
      showUpdateForm: false,
      reviewBlocked: false,
    };
    this.handleFormClose = this.handleFormClose.bind(this);
    this.handleFormOpen = this.handleFormOpen.bind(this);
    this.updateSuccess = this.updateSuccess.bind(this);
    this.updateFail = this.updateFail.bind(this);
    this.handleBlockingReview = this.handleBlockingReview.bind(this);
  }

  updateSuccess() {
    this.setState({ showUpdateForm: false });
    this.props.updateSuccess();
  }

  updateFail(response) {
    const error = JSON.parse(response.responseText);
    this.setState({ formError: error.message });
  }

  handleFormOpen() {
    this.setState({ showUpdateForm: true });
  }

  handleFormClose() {
    this.setState({ showUpdateForm: false });
  }

  updateButton() {
    if (!this.props.review.is_my_review) { return false; }

    return (
      <button
        onClick={this.handleFormOpen}
        className="product-review-edit-btn"
        aria-label={I18n.t('js.productReviews.edit')}
      >
        {I18n.t('js.productReviews.edit')}
      </button>
    );
  }

  handleBlockingReview() {
    const url = `/admin/product_reviews/${this.props.review.id}/block`;

    APIRequest.put(url).then(() => {
      this.setState({ reviewBlocked: true });
    });
  }

  renderTitle() {
    if (this.props.review.title) {
      return this.props.review.title;
    }

    return this.props.review.body.substring(0, TITLE_LENGTH);
  }

  renderVerified() {
    if (!this.props.review.verified) { return false; }

    return I18n.t('js.productReviews.verified');
  }

  renderReviewForm() {
    return (
      <li>
        <ReviewForm
          isOpen
          onClose={this.handleFormClose}
          submitSuccess={this.updateSuccess}
          submitFail={this.updateFail}
          saveLink={this.props.review._links.self.href}
          errorMessage={this.state.formError}
          saveMethod="PUT"
          rating={this.props.review.rating}
          body={this.props.review.body}
          title={this.props.review.title}
          submitText={I18n.t('js.productReviews.reviewUpdate')}
        />
      </li>
    );
  }

  renderUserAvatar() {
    return (
      <div className="avatar avatar-large" title={this.props.review.reviewer.shortname}>
        <img
          alt="Reviewer Avatar"
          src={this.props.review.reviewer._links.avatar.href}
        />
      </div>
    );
  }

  renderUserName() {
    return (
      <p className="small strong mb-0" itemProp="author">
        {this.props.review.reviewer.shortname}
      </p>
    );
  }

  renderUser() {
    if (this.props.review.reviewer._links.self) {
      return (
        <a
          href={this.props.review.reviewer._links.self.href}
          className="g-col-2 align-center show-only-on-desktop"
        >
          {this.renderUserAvatar()}
          {this.renderUserName()}
        </a>
      );
    }

    return (
      <div className="g-col-2 align-center show-only-on-desktop">
        {this.renderUserAvatar()}
        {this.renderUserName()}
      </div>
    );
  }

  renderAdminActions() {
    if (!UserStore.getState().isAdmin) { return null; }

    if (this.state.reviewBlocked) { return null; }

    return (
      <div className="align-right">
        <button
          className="button-as-link size-70 color-red"
          onClick={this.handleBlockingReview}
        >
          Block Review
        </button>
      </div>
    );
  }

  renderReviewBlocked() {
    if (!this.state.reviewBlocked) { return null; }

    return (
      <div className="alert-box alert-box--red mtb-2">
        This review has been blocked.
      </div>
    );
  }

  renderReviewContent() {
    return (
      <li itemProp="review" itemScope itemType="http://schema.org/Review">
        <meta
          content={moment(this.props.review.created_at).format('YYYY-MM-DD')}
          itemProp="datePublished"
        />
        {this.renderReviewBlocked()}
        <div className="g-container">
          {this.renderUser()}
          <div className="g-col-10 g-col-mobile-12">
            <div className="g-container">
              <div className="g-col-9" itemProp="reviewRating" itemScope itemType="http://schema.org/Rating">
                <meta content={this.props.review.rating} itemProp="ratingValue" />
                <meta content="5" itemProp="bestRating" />
                <meta content="1" itemProp="worstRating" />
                <h4 className="product-review-title" itemProp="name">
                  {this.renderTitle()}
                </h4>
                <span className="product-review-star-group">
                  <RCStarRating rating={this.props.review.rating / 5} hideTooltip />
                </span>
                <p className="d-inline-block size-90 strong color-orange mb-0 ml-space">{this.renderVerified()}</p>
              </div>
              <div className="g-col-3 align-right">
                <p className="fine-print muted mb-0">
                  {moment(this.props.review.created_at).format('ll')}
                </p>
                {this.updateButton()}
                {this.renderAdminActions()}
              </div>
            </div>
            <p className="product-review-description" itemProp="reviewBody">
              {this.props.review.body}
            </p>
            <p className="small muted strong show-only-on-mobile mt-1 mb-0" itemProp="author">
              {this.props.review.reviewer.shortname}
            </p>
          </div>
        </div>
      </li>
    );
  }

  render() {
    if (this.state.showUpdateForm) {
      return this.renderReviewForm();
    }

    return this.renderReviewContent();
  }
}

Review.propTypes = {
  review: PropTypes.object.isRequired,
  updateSuccess: PropTypes.func.isRequired,
};

export default Review;
