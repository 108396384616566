import PropTypes from 'prop-types';
import React from 'react';
import { omit } from 'lodash';
import StreetAddressFormatHint from './street_address_format_hint';

const fieldName = 'street_address';

class StreetAddressInput extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      value: this.props.initialValue,
      streetAddressForHint: '',
    };
  }

  inputName() {
    if (this.props.objectName) {
      return `${this.props.objectName}[${fieldName}]`;
    }
    return fieldName;
  }

  displayHint() {
    return (
      <StreetAddressFormatHint
        streetAddress={this.state.streetAddressForHint}
      />
    );
  }

  get inputProps() {
    return omit(
      { ...this.props },
      'classes', 'objectName', 'initialValue',
    );
  }

  render() {
    return (
      <div>
        <input
          ref="streetAddressField"
          type="text"
          name={this.inputName()}
          placeholder={I18n.t('js.bundledCheckout.streetAddressFormatHint.address')}
          value={this.state.value}
          className={this.props.classes}
          autoComplete="street-address"
          onBlur={event => this.setState({ streetAddressForHint: event.target.value })}
          onChange={event => this.setState({ value: event.target.value })}
          {...this.inputProps}
        />
        {this.displayHint()}
      </div>
    );
  }
}

StreetAddressInput.propTypes = {
  classes: PropTypes.string,
  objectName: PropTypes.string,
  initialValue: PropTypes.string,
};

export default StreetAddressInput;
