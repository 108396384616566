import PropTypes from 'prop-types';
import React from 'react';
import { ListingRow, APIRequest } from '../index';
import I18n from 'i18n-js';

const PER_PAGE = 6;

class SimilarListings extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      listings: [],
    };
  }

  componentDidMount() {
    this.fetchListings();
  }

  fetchListings() {
    APIRequest.V3.get(
      `/api/listings/${this.props.listingId}/similar_listings`,
      { include_grid_images: true, per_page: PER_PAGE },
    ).then((response) => {
      this.setState({
        listings: response.listings,
        category: response.similar_category,
      });
    });
  }

  empty() {
    return !this.listings().length;
  }

  listings() {
    if (!this.state || !this.state.listings) { return []; }

    return this.state.listings;
  }

  render() {
    if (this.empty()) { return null; }

    return (
      <ListingRow
        listings={this.listings()}
        title={I18n.t('productShowPage.seeSimilar', { category: this.state.category })}
        minListings={3}
        trackName="SimilarListings"
      />
    );
  }
}

SimilarListings.propTypes = {
  listingId: PropTypes.string.isRequired,
};

export default SimilarListings;
