import React from 'react';
import CannotAddNewCardWarning from './CannotAddNewCardWarning';
import { CREDIT_CARD_LIMIT } from '../lib/creditCards';

interface Props {
  creditCardCount: number;
  button: React.ReactNode;
}

export default function CreditCardAddButton(props: Props) {
  if (props.creditCardCount < CREDIT_CARD_LIMIT) {
    return (
      <>
        {props.button}
      </>
    );
  }

  return (
    <CannotAddNewCardWarning />
  );
}
