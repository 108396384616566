import React from 'react';
import classNames from 'classnames';
import I18n from 'i18n-js';
import bind from '../bind';
import { MParticleEventName, trackEvent } from '../elog/mparticle_tracker';
import { RCAlertBox } from '@reverbdotcom/cadence/components';

import Modal from './modal';
import SigninForm from './signin_form';
import SignupForm from './signup_form';

interface IProps {
  redirectTo?: string;
  message?: string;
  messageComponent?: JSX.Element;
  active?: boolean;
  onRequestClose: Function;
  trackSource: string;
  onlyShowMessageOnTab?: 'signin' | 'signup';
  showSignInOnOpen?: boolean;
  variant?: 'default' | 'continue';
  prioritizeOauth?: boolean;
}

interface State {
  showSignup: boolean;
}

export default class SignupSigninModal extends React.Component<IProps, State> {
  static defaultProps: Partial<IProps> = {
    redirectTo: window.location.href,
    variant: 'default',
  };

  constructor(props) {
    super(props);

    this.state = {
      showSignup: !props.showSignInOnOpen,
    };
  }

  @bind
  switchToSignup() {
    this.setState({ showSignup: true });
  }

  @bind
  switchToLogin() {
    this.setState({ showSignup: false });
  }

  componentDidMount() {
    if (this.props.active) {
      this.trackView();
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.active && !prevProps.active) {
      this.trackView();
      this.setState({ showSignup: !this.props.showSignInOnOpen });
    }
  }

  trackView() {
    const view = this.state.showSignup ? 'signup' : 'signin';

    trackEvent({
      view,
      source: this.props.trackSource,
      eventName: MParticleEventName.ViewedSignupSignin,
    });
  }

  renderForm() {
    const { redirectTo } = this.props;

    if (this.state.showSignup) {
      return (
        <SignupForm
          redirectTo={redirectTo}
          shouldRenderHeader={this.shouldRenderHeader()}
          variant={this.props.variant}
          prioritizeOauth={this.props.prioritizeOauth}
          switchToSignin={this.switchToLogin}
        />
      );
    }

    return (
      <SigninForm
        redirectTo={redirectTo}
        shouldRenderHeader={this.shouldRenderHeader()}
        variant={this.props.variant}
        prioritizeOauth={this.props.prioritizeOauth}
        switchToSignup={this.switchToSignup}
      />
    );
  }

  shouldRenderHeader() {
    return (!this.props.messageComponent && !this.props.message) || this.props.prioritizeOauth;
  }

  renderMessage() {
    if ((this.props.onlyShowMessageOnTab == 'signin' && this.state.showSignup) || (this.props.onlyShowMessageOnTab == 'signup' && !this.state.showSignup)) {
      return null;
    }

    if (this.props.messageComponent) {
      return this.props.messageComponent;
    }

    if (this.props.message) {
      return (
        <div className="scaling-mb-4">
          <RCAlertBox type="info" small>
            {this.props.message}
          </RCAlertBox>
        </div>
      );
    }

    return null;
  }

  render() {
    return (
      <Modal
        isOpen={this.props.active}
        onRequestClose={this.props.onRequestClose}
        subClass="signup-signin"
        headerMarkup={(
          <div className="signup-signin__tabs">
            <button
              className={
                classNames('signup-signin__tab', {
                  'signup-signin__tab--active': this.state.showSignup,
                })
              }
              onClick={this.switchToSignup}
              type="button"
            >
              {I18n.t('commons.signupForm.signUp')}
            </button>
            <button
              className={
                classNames('signup-signin__tab', {
                  'signup-signin__tab--active': !this.state.showSignup,
                })
              }
              onClick={this.switchToLogin}
              type="button"
            >
              {I18n.t('commons.signupForm.logIn')}
            </button>
          </div>
        )}
      >
        <div className="signup-signin">
          <div className="signup-signin__form">
            {this.renderMessage()}
            {this.renderForm()}
          </div>
        </div>
      </Modal>
    );
  }
}
