import PropTypes from 'prop-types';
import React from 'react';
import { PriceDisplay } from '@reverbdotcom/commons';
import ReactDOMServer from 'react-dom/server';

import { SanitizedRender } from '@reverbdotcom/discovery-ui';

// Similar to PriceDisplay, used to show 'Free Shipping' or '$10 Shipping'.
// Does not render a currency code since it's meant to be used next to the
// main price (which can have a currency code).
// Expects a Entities::Money object
class ShippingPrice extends React.Component {
  isFree() {
    return this.price.amount_cents === 0;
  }

  get price() {
    return this.props.shippingRate;
  }

  render() {
    if (!this.props.shippingRate) { return false; }
    if (this.isFree()) { return <span>{I18n.t('js.listing.shipping.free')}</span>; }

    const price = <PriceDisplay display={this.price.display} />;
    const translation = I18n.t('js.listing.shipping.price', {
      price: ReactDOMServer.renderToString(price),
    });
    return (
      <span className="ws-nowrap">
        <SanitizedRender
          html={translation}
        />
      </span>
    );
  }
}

ShippingPrice.propTypes = {
  shippingRate: PropTypes.object,
};

export default ShippingPrice;
