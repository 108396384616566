import React from 'react';
import bind from '../bind';

export interface IProps {
  checked: boolean;
  disabled?: boolean;
  fieldName: string;
  inputName: string;
  required?: boolean;
  updateField: Function;
  defaultChecked?: boolean;
  id?: string;
  inputTitleValue?: string;
}

export default class ControlledCheckbox extends React.Component<IProps, null> {
  @bind
  updateField(event) {
    const attributes = {};
    attributes[this.props.fieldName] = event.target.checked;
    this.props.updateField(attributes);
  }

  render() {
    return (
      <input
        name={this.props.inputName}
        type="checkbox"
        id={this.props.id || this.props.inputName}
        checked={this.props.checked}
        onChange={this.updateField}
        required={this.props.required}
        disabled={this.props.disabled}
        defaultChecked={this.props.defaultChecked}
        title={this.props.inputTitleValue}
      />
    );
  }
}
