import SiteModule from '@reverbdotcom/commons/src/components/site_module';
import React from 'react';

interface Props {
  title?: string;
}

export default function BillingMethodPlaceholder({ title }: Props) {
  return (
    <div className="form-section-wrapper">
      {title &&
        <div className="form-section-wrapper__header">
          <h2 className="form-section-wrapper__title">
            {title}
          </h2>
        </div>
      }
      <SiteModule data-placeholder-rows>
        <ul className="actionable-rows">
          <li className="actionable-row actionable-row--placeholder">
            <div className="actionable-row__content"></div>
          </li>
        </ul>
      </SiteModule>
    </div>
  );
}
