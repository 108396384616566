import React from 'react';
import { RCButton, RCFlex } from '@reverbdotcom/cadence/components';
import I18n from 'i18n-js';

interface IProps {
  limit: number;
  offset: number;
  total: number;
  loadMoreHandler: () => void;
  title?: string;
}

export default function RQLLoadMoreButton({
  limit,
  offset,
  total,
  loadMoreHandler,
  title = null,
}: IProps) {

  const currentPage = Math.max(Math.ceil(offset / limit) + 1, 1);
  const totalPages = Math.ceil(total / limit);
  if (currentPage === totalPages) return null;
  if (total <= 0) return null;

  return (
    <RCFlex justify="center">
      <RCButton
        variant="muted"
        onClick={loadMoreHandler}
      >
        {title || I18n.t('discovery.sellFlow.search.loadMore')}
      </RCButton>
    </RCFlex>
  );
}
