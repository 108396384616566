import { sha256 } from 'js-sha256';
import window from './window_wrapper';

export const GRANTED = 'granted';
export const DENIED = 'denied';
export const GOOGLE_TAG_ID = 'AW-879846789';

export interface IPrivacyConsentSettings {
  adData: boolean
  adStorage: boolean
  adPersonalization: boolean
  analyticsStorage: boolean
}

export interface ILeadFormConversionInfo {
  email: string
}

export function updateGoogleConsent(privacyConsentSettings: IPrivacyConsentSettings) {
  const {
    adData,
    adStorage,
    adPersonalization,
    analyticsStorage,
  } = privacyConsentSettings;

  if (window.gtag !== undefined) {
    window.gtag('consent', 'update', {
      'ad_user_data': adData ? GRANTED : DENIED,
      'ad_storage': adStorage ? GRANTED : DENIED,
      'ad_personalization': adPersonalization ? GRANTED : DENIED,
      'analytics_storage': analyticsStorage ? GRANTED : DENIED,
    });
  }
}

export function sendLeadFormConversion(leadFormConversionInfo: ILeadFormConversionInfo) {
  const { email } = leadFormConversionInfo;

  const hashedEmail = sha256(email);

  if (window.gtag !== undefined) {
    window.gtag('set', 'user_data', {
      'sha256_email_address': hashedEmail,
    });

    window.gtag('event', 'form_submit', {
      'send_to': GOOGLE_TAG_ID,
    });
  }
}
