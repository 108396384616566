export default {
  orderSummary: 'Order Summary',
  subtotal: 'Subtotal: ',
  howYoullPay: "How You'll Pay",
  protection: {
    buyWithConfidence: 'Buy with confidence -',
    protectionIncluded: 'Report any issues within 7 days, we\'ll help you get a refund',
  },
  debitOrCreditCard: 'Debit or Credit Card',
  icons: {
    visa: 'Visa Icon',
    mastercard: 'Mastercard Icon',
    discover: 'Discover Icon',
    amex: 'American Express Icon',
    reverbCredits: 'Reverb Credits Icon',
    affirm: 'Affirm Icon',
    paypal: 'PayPal Icon',
    sofort: 'Sofort Icon',
    klarna: 'Klarna Icon',
  },
  plusReverbCredits: '+ %{display} %{currency} Reverb Credits',
  creditsAppliedToEligibleItems: 'Your Reverb Credits will be applied at checkout to eligible items in your cart.',
  payOverTime: 'Pay Over Time',
  yourPaypalAccount: 'Your PayPal Account',
  buyWithSofort: 'Buy with Sofort',
  buyWithKlarnaAccount: 'Buy with Klarna',
  buyWithKlarnaPayLater: 'Pay Later with Klarna',
  choosePaymentMethod: 'Choose Payment Method',
  buyItNow: 'Buy it now',
  continueToCheckout: 'Continue to checkout',
  expressPayment: 'Express payment',
  standardPayment: 'Standard payment',
};
