import { gql } from '@reverbdotcom/commons/src/gql';
import React from 'react';
import {
  CheckoutDisplayCheckoutFields,
  CheckoutDisplayMeFields,
  core_apimessages_CheckoutPaymentMethod_Type as CheckoutPaymentMethodType,
} from '@reverbdotcom/commons/src/gql/graphql';
import { CheckoutStep, SHIPPING_STEP } from '../lib/checkout_step';
import { useCheckoutContinueButtonState } from './checkout/useCheckoutContinueButtonState';
import { CardPaymentForm, useCheckoutCardPaymentForm } from './checkout/useCheckoutCardPaymentForm';
import { AffirmPaymentForm, useCheckoutAffirmPaymentForm } from './checkout/useCheckoutAffirmPaymentForm';
import { elog } from '@reverbdotcom/commons/src/elog';
import { CheckoutShippingForm, useCheckoutShippingForm } from './checkout/useCheckoutShippingForm';
import { useCheckoutDisplayStep } from './checkout/useCheckoutDisplayStep';
import { InjectedRouter } from 'react-router';
import { PaypalOrderRedirectForm, usePaypalOrderRedirectForm } from './checkout/usePaypalOrderRedirectForm';

type Checkout = CheckoutDisplayCheckoutFields.Fragment;
type Me = CheckoutDisplayMeFields.Fragment;

export type AuthVariant = 'sign-in' | 'sign-up';

interface AuthModalState {
  isOpen: boolean;
  variant: AuthVariant;
  open: (variant: AuthVariant) => void;
  close: () => void;
}

type NullPaymentForm = Record<string, never>;
type ShippingForm = CheckoutShippingForm;
type PaymentForm = CardPaymentForm | AffirmPaymentForm | NullPaymentForm;

export interface CheckoutDisplay {
  step: CheckoutStep;
  setStep: (checkoutStep: CheckoutStep) => void;
  isLoading: boolean;
  setLoading: (loading: boolean) => void;
  layoutQueryName: string;
  shippingForm: ShippingForm,
  paymentForm: PaymentForm;
  paypalOrderRedirectForm: PaypalOrderRedirectForm;
  continueButton: {
    disabled: boolean;
  },
  authModal: AuthModalState;
}

export const CheckoutDisplayContext = React.createContext<CheckoutDisplay>({
  step: SHIPPING_STEP,
  isLoading: false,
  setLoading: (_) => { },
  setStep: (_) => { },
  layoutQueryName: null,
  shippingForm: {
    loading: false,
    submit: (_) => null,
    errors: [],
  },
  paymentForm: {},
  continueButton: {
    disabled: false,
  },
  paypalOrderRedirectForm: {
    submit: () => null,
    loading: false,
    errors: [],
  },
  authModal: {
    isOpen: false,
    variant: null,
    open: () => { },
    close: () => { },
  },
});

/**
 * Use to access the checkout layout's state
 */
export function useCheckoutDisplay() {
  return React.useContext(CheckoutDisplayContext);
}

export const CHECKOUT_LAYOUT_QUERY_NAME = 'Core_Checkout_Layout';

/**
 * Use to hydrate a layout with a loading state.
 *
  * Only necessary once per top-level checkout layout
 */
export function useCheckoutDisplayProvider({ checkout, me, router }: {
  checkout: Checkout,
  me: Me,
  router: InjectedRouter,
}): CheckoutDisplay {
  const { step, setStep } = useCheckoutDisplayStep({ checkout, router });
  const shippingForm = useCheckoutShippingForm(checkout?.id);
  const paymentForm = usePaymentForm(checkout, me);
  const [isLoading, setLoading] = React.useState(false);
  const continueButton = useCheckoutContinueButtonState({ checkout, isOrderSummaryLoading: isLoading });
  const [authVariant, setAuthModalVariant] = React.useState<AuthVariant>();
  const paypalOrderRedirectForm = usePaypalOrderRedirectForm({
    checkoutId: checkout?.id,
    setLoading,
  });

  function openModal(variant: AuthVariant) {
    setAuthModalVariant(variant);
  }

  function closeModal() {
    setAuthModalVariant(null);
  }

  return {
    step,
    isLoading,
    setStep,
    setLoading,
    layoutQueryName: CHECKOUT_LAYOUT_QUERY_NAME,
    shippingForm,
    paymentForm,
    continueButton,
    paypalOrderRedirectForm,
    authModal: {
      isOpen: !!authVariant,
      variant: authVariant,
      open: openModal,
      close: closeModal,
    },
  };
}

function usePaymentForm(checkout: Checkout, me: Me): PaymentForm {
  const paymentMethodType = checkout?.paymentMethod?.type;
  const cardPaymentForm = useCheckoutCardPaymentForm({ checkout, me });
  const affirmPaymentForm = useCheckoutAffirmPaymentForm({ checkout });

  if (paymentMethodType === CheckoutPaymentMethodType.DIRECT_CHECKOUT) {
    return cardPaymentForm;
  }

  if (paymentMethodType === CheckoutPaymentMethodType.AFFIRM) {
    return affirmPaymentForm;
  }

  return {};
}

/**
 * Performs [type narrowing](https://www.typescriptlang.org/docs/handbook/2/narrowing.html)
 * on the current checkout payment form to ensure that it's the card payment form.
 */
export function narrowCardPaymentForm(paymentForm: PaymentForm): CardPaymentForm {
  if (paymentForm.type !== CheckoutPaymentMethodType.DIRECT_CHECKOUT) {
    elog.error('checkout_display_context.incorrect_payment_form', { payment_form_type: paymentForm.type });
    return null;
  }

  return paymentForm;
}

export const fragments = {
  checkout: gql(`
    fragment CheckoutDisplayCheckoutFields on Checkout {
      _id
      ...CheckoutContinueButtonStateFields
      ...CheckoutCardPaymentHookCheckoutFields
      ...CheckoutAffirmPaymentFormHookFields
      ...CheckoutDisplayStepHookFields
    }
  `),
  me: gql(`
    fragment CheckoutDisplayMeFields on rql_Me {
      _id
      uuid
      ...CheckoutCardPaymentHookMeFields
    }
  `),
};
