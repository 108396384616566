import React from 'react';
import I18n from 'i18n-js';
import { renderToString } from 'react-dom/server';
import { flatten } from 'lodash';

import { RCAlertBox, RCButton, RCCheckbox, RCGuidePopover, useMediaQuery } from '@reverbdotcom/cadence/components';
import {
  core_apimessages_UserActionName,
  rql_Me,
} from '@reverbdotcom/commons/src/gql/graphql';
import { useExpEnabled } from '@reverbdotcom/commons/src/user_hooks';
import experiments from '@reverbdotcom/commons/src/experiments';
import { createUserActionMutation, useCreateUserAction } from '@reverbdotcom/commons/src/user_actions/create_action_mutation';
import { useMutation } from '@reverbdotcom/commons/src/useMutation';
import { useViewTracking } from '@reverbdotcom/commons/src/use_tracking';
import {
  SanitizedRender,
} from '@reverbdotcom/commons';

import { ActionType } from './sellerListingsCollectionReducer';
import SellerListingsCollectionContext from './SellerListingsCollectionContext';
import {
  isPageEligibleForBulkDraftActions,
  isPageEligibleForBulkDirectOffers,
  isPageEligibleForBulkBump,
  isPageEligibleForBulkAffirm,
  isPageEligibleForBulkSales,
} from './toolbarHelpers';
import BulkPublishButton from './bulk_actions/BulkPublishButton';
import BulkDeleteButton from './bulk_actions/BulkDeleteButton';
import PublishAllDraftsButton from './bulk_actions/PublishAllDraftsButton';
import ExportListingsButton from './bulk_actions/ExportListingsButton';
import BulkActionErrors from './bulk_actions/BulkActionErrors';
import { MParticleEventName } from '@reverbdotcom/commons/src/elog/mparticle_types';
import BulkBumpButton from './bulk_actions/bump/BulkBumpButton';
import BulkDirectOffersButton from './bulk_actions/direct_offers/BulkDirectOffersButton';
import BulkAffirmFinancingButton from './bulk_actions/affirm/BulkAffirmFinancingButton';
import BulkSalesButton from './bulk_actions/sales/BulkSalesButton';
import SellerListingCardsToolbarContext from './SellerListingCardsToolbarContext';
import BulkSaleModalButton from '../selling/sales/bulk_sale_modal_button';

export const COMPONENT_NAME = 'SellerListingCardsToolbar';
const USD = 'USD';
export const SELECT_ALL_MATCHES_LIMIT = 10000;
interface IExternalProps {
  me?: rql_Me;
}

export default function SellerListingCardsToolbar({
  me = {},
}: IExternalProps) {
  const {
    shop = {},
    availableActions = [],
  } = me;

  const { canCurateSales, currency, acceptsAffirm } = shop;
  const isUSDSeller = currency == USD;

  const canUserManageAffirm = useExpEnabled(experiments.SELLER_AFFIRM_PROMOTION);

  const isMobile = useMediaQuery('mobile');
  const isTablet = useMediaQuery('tablet');

  const [createMyAction] = useMutation(createUserActionMutation);

  const { createUserAction } = useCreateUserAction(
    core_apimessages_UserActionName.SELLER_BULK_ACTIONS_CALLOUT,
    {
      uuid: me.uuid,
    },
    {
      listingId: null,
    },
    createMyAction,
  );

  const [isBulkActionPopoverHidden, setIsBulkActionPopoverHidden] = React.useState<boolean>(false);
  const [successMessage, setSuccessMessage] = React.useState<string>('');
  const [errorMessage, setErrorMessage] = React.useState<string>('');
  const hasSuccessMessage = !!successMessage.length;
  const hasErrorMessages = !!errorMessage.length;
  const shouldRenderBulkActionResponses = hasSuccessMessage || hasErrorMessages;

  const isAffirmEligibleShop = canUserManageAffirm && isUSDSeller && acceptsAffirm;

  const showBumpButton = isPageEligibleForBulkBump(window);
  const showDirectOffersButton = isPageEligibleForBulkDirectOffers(window);
  const showAffirmButton = isAffirmEligibleShop && isPageEligibleForBulkAffirm(window);
  const showSalesButton = canCurateSales && isPageEligibleForBulkSales(window);

  const {
    listingsCollectionState,
    dispatch,
    useLazySellerListingsQuery,
  } = React.useContext(SellerListingsCollectionContext);

  const { refetchSellerListings } = useLazySellerListingsQuery();
  const {
    listings,
    selectedListings,
    hasAllMatchesSelected,
    totalMatches,
  } = listingsCollectionState;

  const hasListings = !!listings.length;
  const isAllSelected = hasListings && listings.length == selectedListings.length;
  const isSomeSelected = !!selectedListings.length && !isAllSelected;

  const I18nSelectKey = (isAllSelected || isSomeSelected) ? 'selectedListings' : 'selectListings';

  const showAlertForSelectAllMatches = isAllSelected && totalMatches > listings.length;
  const isEligibleForBulkActionsOnAllMatches = SELECT_ALL_MATCHES_LIMIT >= totalMatches;

  const bulkActionCallout = availableActions.find(action => action.name == core_apimessages_UserActionName.SELLER_BULK_ACTIONS_CALLOUT);
  const showBulkActionPopover = !!bulkActionCallout && !isBulkActionPopoverHidden && hasListings;

  const searchParams = new URLSearchParams(window.location.search);

  function resetSuccessAndErrorMessages() {
    setSuccessMessage('');
    setErrorMessage('');
  }

  function handleBulkResponses({
    responses,
    I18nSuccessMessageKey,
    I18nErrorMessageKey,
    successArgs = {},
  }) {
    resetSuccessAndErrorMessages();

    handleBulkSuccessResponses({
      responses,
      I18nMessageKey: I18nSuccessMessageKey,
      args: successArgs,
    });

    handleBulkErrorResponses({
      responses,
      I18nMessageKey: I18nErrorMessageKey,
    });
  }

  function handleAsyncBulkResponse({
    I18nKey,
    expectedTotal,
    I18nArgs = {},
  }) {
    resetSuccessAndErrorMessages();

    const message = I18n.t(I18nKey, {
      count: expectedTotal,
      formattedCount: I18n.toNumber(expectedTotal, {
        precision: 0,
      }),
      ...I18nArgs,
    });

    setSuccessMessage(message);
  }

  function handleBulkSuccessResponses({
    responses,
    I18nMessageKey,
    args = {},
  }) {
    const successResponses = responses.filter(response => response.success);
    const listingIds = successResponses.map(response => response.listingId);
    const successCount = listingIds.length;

    if (successCount) {
      const message = I18n.t(I18nMessageKey, {
        count: successCount,
        formattedCount: I18n.toNumber(successCount, {
          precision: 0,
        }),
        ...args,
      });

      setSuccessMessage(message);
      refetchSellerListings(listingIds);
    }
  }

  function handleBulkErrorResponses({
    responses,
    I18nMessageKey,
  }) {
    const errorResponses = responses.filter(response => !response.success);
    const listingIds = errorResponses.map(response => response.listingId);
    const errorCount = listingIds.length;

    if (errorCount) {
      const errors = flatten(errorResponses.map(listing => listing.errors));

      const message = renderToString(
        <BulkActionErrors
          totalListingsWithErrors={errorCount}
          errors={errors}
          titleI18nKey={I18nMessageKey}
        />,
      );

      setErrorMessage(message);
    }
  }

  function handleGenericError(I18nMessageKey = 'discovery.listingsManagement.genericError') {
    resetSuccessAndErrorMessages();

    setErrorMessage(I18n.t(I18nMessageKey));
  }

  function showSelectionButtons() {
    if (!isMobile && !isTablet) return true;

    return isAllSelected || isSomeSelected;
  }

  function updateSelections() {
    if (listings.length == selectedListings.length) {
      dispatch({
        type: ActionType.DESELECT_ALL_LISTINGS,
      });

      dispatch({
        type: ActionType.DESELECT_ALL_MATCHES,
      });

    } else {
      dispatch({
        type: ActionType.SELECT_ALL_LISTINGS,
      });
    }
  }

  function checkedState() {
    if (isAllSelected) return true;
    if (isSomeSelected) return 'mixed';

    return false;
  }

  useViewTracking({
    componentName: COMPONENT_NAME,
    eventName: MParticleEventName.ComponentView,
  }, true);

  React.useEffect(() => {
    return () => {
      setIsBulkActionPopoverHidden(true);
    };
  }, [searchParams.toString()]);

  return (
    <SellerListingCardsToolbarContext.Provider value={{
      handleBulkResponses,
      handleGenericError,
      handleAsyncBulkResponse,
    }}>
      <div className="seller-listing-cards-toolbar">
        {shouldRenderBulkActionResponses && (
          <div
            className="seller-listing-cards-toolbar__alerts"
          >
            {hasSuccessMessage && (
              <RCAlertBox
                type="success"
                onDismiss={() => setSuccessMessage('')}
              >
                <SanitizedRender
                  html={successMessage}
                />
              </RCAlertBox>
            )}

            {hasErrorMessages && (
              <RCAlertBox
                type="error"
                onDismiss={() => setErrorMessage('')}
              >
                <SanitizedRender
                  html={errorMessage}
                />
              </RCAlertBox>
            )}
          </div>
        )}

        <div className="seller-listing-cards-toolbar__actions">
          <div className="ws-nowrap ml-4">
            <div className="d-flex fx-dir-col">
              <RCCheckbox
                disabled={!hasListings}
                checked={checkedState()}
                triState={isSomeSelected}
                label={
                  <div className="weight-bold">
                    {I18n.t(`discovery.listingsManagement.toolbar.${I18nSelectKey}`, {
                      count: selectedListings.length,
                    })}
                  </div>
                }
                onClick={() => updateSelections()}
                onChange={() => updateSelections()}
              />

              <RCGuidePopover
                title={I18n.t('discovery.listingsManagement.toolbar.popover.title')}
                content={I18n.t('discovery.listingsManagement.toolbar.popover.content')}
                isOpen={showBulkActionPopover}
                preventAutoFocus
                onDismiss={() => {
                  setIsBulkActionPopoverHidden(true);
                  createUserAction();
                }}
                anchor={
                  <div className="width-100 tablet-width-20 mobile-width-30">
                    {/*
                      Wrapping the popover around the RCCheckbox leads to layout issues at tablet breakpoint and below. Using this div to provide
                      an anchor
                    */}
                  </div>
                }
              />
            </div>
          </div>

          <div className="d-flex fx-justify-between tablet-gap-3 fx-align-center tablet-fx-dir-col-reverse width-100">
            {showSelectionButtons() && (
              <div className="d-flex gap-3 fx-wrap tablet-width-100">
                {isPageEligibleForBulkDraftActions(window) && (
                  <>
                    <div className="tablet-width-100">
                      <BulkDeleteButton
                        selectedListings={selectedListings}
                        onSuccessCallback={responses => {
                          handleBulkResponses({
                            responses,
                            I18nSuccessMessageKey: 'discovery.listingsManagement.toolbar.bulkActions.bulkDelete.deleteSuccessful',
                            I18nErrorMessageKey: 'discovery.listingsManagement.toolbar.bulkActions.bulkDelete.deleteErrors.title',
                          });

                          const listingIdsToRemove = responses.filter(response => response.success)
                            .map(response => response.listingId);
                          dispatch({
                            type: ActionType.REMOVE_LISTINGS,
                            payload: {
                              listingIds: listingIdsToRemove,
                            },
                          });
                        }}
                      />
                    </div>

                    <div className="tablet-width-100">
                      <BulkPublishButton
                        selectedListings={selectedListings}
                      />
                    </div>
                  </>
                )}

                {showBumpButton && (
                  <div className="tablet-width-100">
                    <BulkBumpButton
                      selectedListings={selectedListings}
                    />
                  </div>
                )}

                {showDirectOffersButton && (
                  <div className="tablet-width-100">
                    <BulkDirectOffersButton
                      selectedListings={selectedListings}
                    />
                  </div>
                )}

                {showAffirmButton && (
                  <div className="tablet-width-100">
                    <BulkAffirmFinancingButton
                      selectedListings={selectedListings}
                    />
                  </div>
                )}

                {showSalesButton && (
                  <div className="tablet-width-100">
                    {hasAllMatchesSelected ? (
                      <BulkSaleModalButton
                        listingsCount={totalMatches}
                      />
                    ) : (
                      <BulkSalesButton
                        selectedListings={selectedListings}
                      />
                    )}
                  </div>
                )}
              </div>
            )}

            <div className="d-flex gap-3 fx-wrap tablet-width-100">
              {isPageEligibleForBulkDraftActions(window) && (
                <div className="tablet-width-100">
                  <PublishAllDraftsButton
                    onSuccessCallback={message => {
                      resetSuccessAndErrorMessages();
                      setSuccessMessage(message);
                    }}
                    onErrorCallback={message => {
                      resetSuccessAndErrorMessages();
                      setErrorMessage(message);
                    }}
                  />
                </div>
              )}

              <div className="tablet-width-100">
                <div className="tablet-d-none">
                  <ExportListingsButton
                    selectedListings={selectedListings}
                    onSuccessCallback={message => {
                      resetSuccessAndErrorMessages();
                      setSuccessMessage(message);
                    }}
                    onErrorCallback={message => {
                      resetSuccessAndErrorMessages();
                      setErrorMessage(message);
                    }}
                  />
                </div>

                <div className="tablet-d-block d-none">
                  <ExportListingsButton
                    selectedListings={selectedListings}
                    onSuccessCallback={message =>{
                      resetSuccessAndErrorMessages();
                      setSuccessMessage(message);
                    }}
                    onErrorCallback={message => {
                      resetSuccessAndErrorMessages();
                      setErrorMessage(message);
                    }}
                    fullSizeButton
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        {showAlertForSelectAllMatches && (
          <RCAlertBox type="info">
            <div className="d-flex fx-justify-center gap-3 fx-align-center fx-wrap">
              {!hasAllMatchesSelected && (
                <span>
                  {I18n.t('discovery.listingsManagement.toolbar.allListingsSelectedCallout', {
                    count: selectedListings.length,
                    formattedCount: I18n.toNumber(selectedListings.length, {
                      precision: 0,
                    }),
                  })}
                </span>
              )}

              {hasAllMatchesSelected && (
                <span>
                  {I18n.t('discovery.listingsManagement.toolbar.allMatchesSelectedCallout', {
                    count: totalMatches,
                    formattedCount: I18n.toNumber(totalMatches, {
                      precision: 0,
                    }),
                  })}
                </span>
              )}

              <RCButton
                size="mini"
                variant="filled"
                inverted
                disabled={!isEligibleForBulkActionsOnAllMatches}
                onClick={() => {
                  if (hasAllMatchesSelected) {
                    dispatch({ type: ActionType.DESELECT_ALL_MATCHES });
                  } else {
                    dispatch({ type: ActionType.SELECT_ALL_MATCHES });
                  }
                }}
              >
                {!hasAllMatchesSelected && (
                  I18n.t('discovery.listingsManagement.toolbar.selectAllMatches', {
                    count: totalMatches,
                    formattedCount: I18n.toNumber(totalMatches, {
                      precision: 0,
                    }),
                  })
                )}

                {hasAllMatchesSelected && (
                  I18n.t('discovery.listingsManagement.toolbar.clearSelectionOfMatches')
                )}
              </RCButton>

              {!isEligibleForBulkActionsOnAllMatches && (
                <SanitizedRender
                  html={I18n.t('discovery.listingsManagement.toolbar.tooManyMatches', {
                    belowAmount: I18n.toNumber(SELECT_ALL_MATCHES_LIMIT + 1, {
                      precision: 0,
                    }),
                    max: I18n.toNumber(SELECT_ALL_MATCHES_LIMIT, {
                      precision: 0,
                    }),
                  })}
                />
              )}
            </div>
          </RCAlertBox>
        )}
      </div>
    </SellerListingCardsToolbarContext.Provider>
  );
}
