import React from 'react';
import I18n from 'i18n-js';
import { withRouter } from 'react-router';
import { Location } from 'history';

import * as elog from '../elog';
import { buildCSPClick } from '../elog/mparticle_tracker';
import SanitizedRender from './sanitized_render';
import { RCStarRating } from '@reverbdotcom/cadence/components';
import { Paths, prefilledListingFromCSP } from '../url_helpers';
import { IUserContext, withUserContext } from './user_context_provider';
import experiments from '../experiments';
import { formatExperimentsForEventAttributes } from '../elog/mparticle';
import { SquareCard } from './square_card';
import { CSP } from '../gql/graphql';
import { ConditionFilter, PriceMetaData } from '../cms/lib/metadata_helper';
import { connect } from '../connect';

interface RouterProps {
  location?: Location;
}

interface ExternalProps {
  csp?: CSP;
  position?: number;
  conditionFilter?: ConditionFilter;
  hideMetadata?: boolean;
  includeItemListMetadata?: boolean;
  renderAsHorizontal?: boolean;
  shouldLinkToSellForm?: boolean;
  trackingQuery?: string;
  hideCallout?: boolean;
  filterParams?: Object;
}

type IProps = IUserContext & RouterProps & ExternalProps;

const COMPONENT_NAME = 'CspSquareCard';

function PlaceholderCard() {
  return (
    <div className="csp-square-card csp-square-card--placeholder">
      <div
        className="csp-square-card__inner"
      >
        <div className="csp-square-card__main">
          <div className="image-box" />
        </div>
        <div className="csp-square-card__details" />
      </div>
    </div>
  );
}

export function CspSquareCard(props: IProps) {

  const destinationPath = () => {
    if (props.shouldLinkToSellForm) {
      return prefilledListingFromCSP(props.csp.id);
    }

    if (props.filterParams) {
      const cspSlug = props.csp.slug;
      return Paths.filteredCsp.expand({ cspSlug, ...props.filterParams });
    }

    return props.csp.webLink.path;
  };

  const getDetailsMetadata = () => {
    if (props.hideMetadata) return;

    return {
      itemScope: true,
      itemProp: 'offers',
      itemType: 'http://schema.org/AggregateOffer',
    };
  };

  const calloutLanguage = (props) => {
    if (props.hideCallout || !props.csp) { return null; }

    const { price, count } = priceAndCount();

    if (!price && count === 0) { return null; }

    if (!price) {
      elog.error(
        'CSP price is null',
        {
          inventory: JSON.stringify(props.csp.inventory),
          conditionFilter: props.conditionFilter,
        },
        null,
      );
      return null;
    }

    const t = (key) => {
      return I18n.t(`cms.comparisonShoppingPages.${key}`, { count, price: price.display, tagOpen: '<b>', tagClose: '</b>' });
    };

    if (price.amountCents === 0) {
      return t('comparePriceForFreeCount');
    }

    return t('comparePriceFromCount');
  };

  const clickEvent = () => {
    return {
      experiments: formatExperimentsForEventAttributes(
        props.user,
        [
          experiments.DUMMY,
        ],
      ),
      ...buildCSPClick(props.csp),
      displayStyle: COMPONENT_NAME,
      position: props.position,
      query: props.trackingQuery,
    };
  };

  const priceAndCount = () => {
    const { conditionFilter, csp } = props;
    const { inventory } = csp;

    if (!inventory) return {};

    if (conditionFilter === 'new') {
      const { newLowPrice, newTotal } = inventory;
      return { count: newTotal, price: newLowPrice };
    }

    if (conditionFilter === 'used') {
      const { usedLowPrice, usedTotal } = inventory;
      return { count: usedTotal, price: usedLowPrice };
    }

    const count = inventory.usedTotal + inventory.newTotal;

    if (!inventory.usedLowPrice) {
      return { count, price: inventory.newLowPrice };
    }

    if (!inventory.newLowPrice) {
      return { count, price: inventory.usedLowPrice };
    }

    if (inventory.newLowPrice.amountCents < inventory.usedLowPrice.amountCents) {
      return { count, price: inventory.newLowPrice };
    }

    return { count, price: inventory.usedLowPrice };
  };

  if (!props.csp) return <PlaceholderCard />;

  return (
    <SquareCard
      csp={props.csp}
      position={props.position}
      hideMetadata={props.hideMetadata}
      renderAsHorizontal={props.renderAsHorizontal}
      location={props.location}
      destinationPath={destinationPath()}
      clickEvent={clickEvent()}
      includeItemListMetadata={props.includeItemListMetadata}
    >
      <div
        className="csp-square-card__details__price"
        {...getDetailsMetadata()}
      >
        {props.csp.reviewsCount !== 0 && (
          <div className="mb-1">
            <RCStarRating
              rating={props.csp.averageReviewRating / 5.0}
              count={props.csp.reviewsCount}
            />
          </div>
        )}
        {!props.hideMetadata && (
          <div className="mb-1">
            <PriceMetaData
              csp={props.csp}
              conditionFilter={props.conditionFilter}
            />
          </div>
        )}
        <SanitizedRender html={calloutLanguage(props)} />
      </div>
    </SquareCard>
  );
}

export default connect<ExternalProps>([
  withUserContext,
  withRouter,
])(CspSquareCard);
