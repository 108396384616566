import {
  core_apimessages_CheckoutPaymentMethod_Type as CheckoutPaymentMethodType,
} from '@reverbdotcom/commons/src/gql/graphql';

/**
 * @param paymentMethodType
 * @returns Boolean, true if the payment method is processed via PayPal.
 */
export function isViaPayPal(paymentMethodType: CheckoutPaymentMethodType) {
  return paymentMethodType === CheckoutPaymentMethodType.PAYPAL ||
    paymentMethodType === CheckoutPaymentMethodType.DC_VIA_PAYPAL ||
    paymentMethodType === CheckoutPaymentMethodType.PAYPAL_PAY_LATER;
}
