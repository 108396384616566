import React from 'react';
import I18n from 'i18n-js';
import SignupSigninModal from '@reverbdotcom/commons/src/components/signup_signin_modal';

import UrlHelpers from '../../shared/url_helpers';

export default function LogIn() {
  const [showLogin, setShowLogin] = React.useState(false);
  const [showSignUp, setShowSignUp] = React.useState(false);

  function handleModalClose() {
    setShowLogin(false);
    setShowSignUp(false);
  }

  function handleSignUp(event) {
    setShowSignUp(true);
    event.stopPropagation();
    event.preventDefault();
  }

  function handleLogIn(event) {
    setShowLogin(true);
    event.stopPropagation();
    event.preventDefault();
  }

  return (
    <>
      <li>
        <a
          href={UrlHelpers.signupPath()}
          className="reverb-header__nav__link"
          onClick={handleSignUp}
        >
          {I18n.t('discovery.reverbHeader.signUp')}
        </a>
      </li>
      <li>
        <a
          href={UrlHelpers.signinPath()}
          className="reverb-header__nav__link"
          onClick={handleLogIn}
        >
          {I18n.t('discovery.reverbHeader.logIn')}
        </a>
      </li>
      <SignupSigninModal
        onRequestClose={handleModalClose}
        active={showLogin || showSignUp}
        showSignInOnOpen={showLogin}
      />
    </>
  );
}
