import { ChildProps } from '@apollo/client/react/hoc';
// TODO update this to import from commons/src/gql
// eslint-disable-next-line no-restricted-imports
import { gql } from '@apollo/client';

import React from 'react';
import I18n from 'i18n-js';
import { get } from 'lodash';

import { CommonsCmsArticlesCollection } from '../../gql/graphql';

import { OverflowingRowAction } from '../../components/overflowing_row_action';
import { ArticleCollectionView } from '../../components/article_collection_view';

import { IDynamicComponentProps } from '../dynamic_component_props';
import { withGraphql } from '../../with_graphql';

export interface IProps extends IDynamicComponentProps {
  title?: string;
  titleHtml?: string;
  subtitleHtml?: string;
  ledeHtml?: string;
  articleIds?: string[];
  ctaText?: string;
  ctaTargetHref?: string;
  jumplinkSlug?: string;
  cardStyle?: string;
}

const SIDEBAR_SECTION = 'sidebar';

export class ArticleCollection extends React.Component<ChildProps<IProps, CommonsCmsArticlesCollection.Query>, null> {
  getBackgroundImageStyle(article) {
    const src = get(article.images[0], 'source');
    if (!src) return null;

    return { backgroundImage: `url(${article.images[0].source})` };
  }

  articles() {
    return this.props.data.articles || [];
  }

  renderAction() {
    const { ctaTargetHref, ctaText } = this.props;
    if (!ctaTargetHref) return null;

    return (
      <OverflowingRowAction
        href={ctaTargetHref}
        text={ctaText ? ctaText : I18n.t('cms.articleCollection.readMore')}
      />
    );
  }

  render() {
    if (!this.props.data.loading && !this.articles().length) return null;

    return (
      <ArticleCollectionView
        title={this.props.title}
        titleHtml={this.props.titleHtml}
        subtitleHtml={this.props.subtitleHtml}
        ledeHtml={this.props.ledeHtml}
        action={this.renderAction()}
        articles={this.articles()}
        id={this.props.jumplinkSlug}
        loading={this.props.data.loading}
        expectedCount={get(this.props, 'articleIds.length')}
        renderForSidebar={this.props.layoutSection === SIDEBAR_SECTION}
        cardStyle={this.props.cardStyle}
      />
    );
  }
}

const connect = withGraphql<IProps, CommonsCmsArticlesCollection.Query, CommonsCmsArticlesCollection.Variables>(
  gql`
  query Commons_Cms_ArticlesCollection(
    $ids: [String]
  ) {
    articles(input: { ids: $ids }) {
      _id
      id
      title
      articleCategories {
        name
      }
      webLink {
        href
      }
      images(input:{
        type: "Article",
        scope: "full_bleed_image",
        transform: "card_wide"
      }) {
        _id
        source
      }
    }
  }
  `,
  {
    options: ownProps => ({
      ssr: ownProps.ssr,
      variables: {
        ids: ownProps.articleIds,
      },
    }),
  },
);

export default connect(ArticleCollection);
