import React from 'react';
import FormSection from '@reverbdotcom/commons/src/components/form_section';
import { I18N as Translate } from '@reverbdotcom/commons/src/components/translate';
import { RCAlertBox, RCTextWithIcon } from '@reverbdotcom/cadence/components';
import { InfoCircleIcon } from '@reverbdotcom/cadence/icons/react';
import CoreLink from '@reverbdotcom/commons/src/components/core_link';
import { Paths } from '../shared/url_helpers';

interface IProps {
  children: React.ReactNode;
  helpCenterArticleId?: string;
}

export function InternationalTaxProfileMainFormSection({
  children,
  helpCenterArticleId,
}: IProps) {
  return (
    <FormSection
      title={<Translate text="discovery.internationalTaxProfile.taxProfileTitle" />}
      description={
        <>
          <Translate text="discovery.internationalTaxProfile.taxProfileDescription" />
          {helpCenterArticleId && (
            <div className="scaling-mt-2">
              <RCAlertBox
                type="info"
              >
                <RCTextWithIcon
                  placement="left"
                  svgComponent={InfoCircleIcon}
                >
                  <CoreLink
                    className="text-link"
                    to={Paths.helpCenterArticlesUrl.expand({ articleId: helpCenterArticleId })}
                    target="_blank"
                  >
                    <Translate text="discovery.internationalTaxProfile.taxInfoHelpCenterLinkLabel" />
                  </CoreLink>
                </RCTextWithIcon>
              </RCAlertBox>
            </div>
          )}
        </>}
    >
      {children}
    </FormSection>
  );
}

export default InternationalTaxProfileMainFormSection;
