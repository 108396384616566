import React from 'react';
import { I18N as Translate } from '@reverbdotcom/commons/src/components/translate';
import CoreLink from '@reverbdotcom/commons/src/components/core_link';
import {
  core_apimessages_TaxIdentification,
  core_apimessages_TaxIdentification_TaxType,
} from '@reverbdotcom/commons/src/gql/graphql';

interface TaxIdProps {
  taxIdentification: core_apimessages_TaxIdentification;
}

export function TaxIdRow({ taxIdentification }: TaxIdProps) {
  return (
    taxIdentification.taxType === core_apimessages_TaxIdentification_TaxType.VAT ?
      <div className="mb-0">{taxIdentification.taxId} ({taxIdentification.countryCode})</div> :
      <div className="mb-0">{taxIdentification.taxType}: {taxIdentification.taxId}</div>
  );
}

interface IProps {
  taxIdentifications?: core_apimessages_TaxIdentification[];
  taxIdentificationType: core_apimessages_TaxIdentification_TaxType;
}

export function TaxIdentificationsSection({
  taxIdentifications,
  taxIdentificationType,
}: IProps) {
  const hasTaxIdentifications = taxIdentifications?.length > 0;

  return (
    <>
      {hasTaxIdentifications ? (
        taxIdentifications.map((taxIdentification) => (
          <TaxIdRow
            key={taxIdentification.taxId}
            taxIdentification={taxIdentification}
          />
        ))
      ) : (
        <Translate text={`discovery.internationalTaxProfile.taxIdentifications.noTaxIdentification.${taxIdentificationType}`} />
      )}
      <Translate
        tag="p"
        id="tax-identification-help-text"
        text={hasTaxIdentifications ? `discovery.internationalTaxProfile.taxIdentifications.hasTaxIdPrompt.${taxIdentificationType}` : `discovery.internationalTaxProfile.taxIdentifications.noTaxIdPrompt.${taxIdentificationType}`}
      >
        <CoreLink
          to="/my/selling/tax_policy"
          target="_blank"
        >
          <Translate text="discovery.internationalTaxProfile.taxIdentifications.pageLinkText" />
        </CoreLink>
      </Translate>
    </>
  );
}

export default TaxIdentificationsSection;
