import {
  core_apimessages_UpdateBillingMethodRequest, core_apimessages_BillingCreditCardEntry,
  core_apimessages_AddressEntry as IAddressEntry,
} from '@reverbdotcom/commons/src/gql/graphql';
import { browserInfo } from '../credit_cards/browser_info';
import { ITokenizedCreditCardData } from '../credit_cards/card_data';
import Location from '../lib/wrapped_location';

interface BillingMethodMutationInput {
  cardholderName: string;
  tokenizedCreditCardData: ITokenizedCreditCardData;
  billingCardAddress: IAddressEntry;
  selectedCreditCardId: string;
  editingCard: boolean;
  useExistingCreditCard: boolean;
  returnUrl: string;
  partialPayment?: boolean;
}

interface CreditCardInput {
  cardholderName: string;
  tokenizedCreditCardData: ITokenizedCreditCardData;
  billingCardAddress: IAddressEntry;
  selectedCreditCardId: string;
  editingCard: boolean;
  useExistingCreditCard: boolean;
}

export function buildCreditCardEntry(creditCardEntry: CreditCardInput): core_apimessages_BillingCreditCardEntry {
  const {
    useExistingCreditCard,
    editingCard,
    selectedCreditCardId,
    billingCardAddress,
    tokenizedCreditCardData,
    cardholderName,
  } = creditCardEntry;

  if (useExistingCreditCard) {
    return {
      creditCardId: selectedCreditCardId,
    };
  }

  const creditCardId = editingCard ? selectedCreditCardId : '';

  return {
    creditCardId,
    address: {
      countryCode: billingCardAddress.countryCode,
      postalCode: billingCardAddress.postalCode,
      region: billingCardAddress.region,
      locality: billingCardAddress.locality,
      name: billingCardAddress.name,
      streetAddress: billingCardAddress.streetAddress,
      extendedAddress: billingCardAddress.extendedAddress,
      phone: billingCardAddress.phone,
    },
    adyenCreditCard: {
      cardholderName,
      tokenizedFields: JSON.stringify(tokenizedCreditCardData),
    },
  };
}

export function buildUpdateBillingMethodMutationInput(
  billingMethodMutationInput: BillingMethodMutationInput,
): core_apimessages_UpdateBillingMethodRequest {
  const {
    cardholderName,
    tokenizedCreditCardData,
    billingCardAddress,
    editingCard,
    selectedCreditCardId,
    useExistingCreditCard,
    returnUrl,
    partialPayment,
  } = billingMethodMutationInput;

  return {
    returnUrl,
    origin: Location.origin,
    browserInfo: JSON.stringify(browserInfo()),
    creditCard: buildCreditCardEntry({
      cardholderName,
      tokenizedCreditCardData,
      billingCardAddress,
      editingCard,
      selectedCreditCardId,
      useExistingCreditCard,
    }),
    partialPayment,
  };
}
