export default {
  articleCollection: {
    readMore: 'Read More',
  },
  buyingGuideLabel: 'Buying Guide',
  comparisonShoppingPages: {
    priceFromWithHighlightedCount: {
      one: '{{count}} available from {{tagOpen}}{{price}}{{tagClose}}',
      other: '{{count}} available from {{tagOpen}}{{price}}{{tagClose}}',
    },
    priceForFreeHighlightedCount: {
      one: '{{count}} available for {{tagOpen}}{{price}}{{tagClose}}',
      other: '{{count}} available for {{tagOpen}}{{price}}{{tagClose}}',
    },
    comparePriceFromCount: {
      one: '{{count}} available for {{tagOpen}}{{price}}{{tagClose}}',
      other: 'Compare {{count}} from {{tagOpen}}{{price}}{{tagClose}}',
    },
    comparePriceForFreeCount: {
      one: '{{count}} available for {{tagOpen}}{{price}}{{tagClose}}',
      other: 'Compare {{count}} for {{tagOpen}}{{price}}{{tagClose}}',
    },
    priceFrom: 'from {{tagOpen}}{{price}}{{tagClose}}',
    priceForFree: '{{tagOpen}}{{price}}{{tagClose}}',
    seeMore: 'See More',
  },
  countdownTimer: {
    condensed: {
      timeRemainingDays: '{{days}}d {{hours}}h left',
      timeRemainingHours: '{{hours}}h {{minutes}}m left',
    },
    timeRemainingDays: '{{days}}d {{hours}}h {{minutes}}m {{seconds}}s left',
    timeRemainingHours: '{{hours}}h {{minutes}}m {{seconds}}s left',
    timeRemainingMinutes: '{{minutes}}m {{seconds}}s left',
    timeDateOnly: {
      condensed: {
        timeRemainingDays: '{{days}}d {{hours}}h',
        timeRemainingHours: '{{hours}}h {{minutes}}m',
      },
      timeRemainingDays: '{{days}}d {{hours}}h {{minutes}}m {{seconds}}s',
      timeRemainingHours: '{{hours}}h {{minutes}}m {{seconds}}s',
      timeRemainingMinutes: '{{minutes}}m {{seconds}}s',
    },
    ended: 'Ended',
  },
  categorySearch: {
    searchInCategory: 'Search in {{categoryName}}',
  },
  cspFeature: {
    priceWithInventory: {
      one: 'One available for {{tagOpen}}{{price}}{{tagClose}}',
      other: '{{count}} available from {{tagOpen}}{{price}}{{tagClose}}',
    },
    pricing: {
      buying: {
        priceFrom: 'Starting at {{price}}',
        priceFromWithTags: 'Starting at {{tagOpen}}{{price}}{{tagClose}}',
        usedPriceFrom: 'Used from {{price}}',
        newPriceFrom: 'New from {{price}}',
        usedPriceFromWithTags: 'Used from {{tagOpen}}{{price}}{{tagClose}}',
        newPriceFromWithTags: 'New from {{tagOpen}}{{price}}{{tagClose}}',
      },
      selling: {
        priceFrom: 'Selling from {{price}}',
        priceFromWithTags: 'Selling from {{tagOpen}}{{price}}{{tagClose}}',
        usedPriceFrom: 'Selling used from {{price}}',
        newPriceFrom: 'Selling new from {{price}}',
        usedPriceFromWithTags: 'Selling used from {{tagOpen}}{{price}}{{tagClose}}',
        newPriceFromWithTags: 'Selling new from {{tagOpen}}{{price}}{{tagClose}}',
      },
    },
    shopNow: 'Shop Now',
    seeMore: 'See More',
    sellYours: 'Sell Yours',
  },
  head: {
    defaultTitle: 'Buy & Sell New, Used, and Rare Music Gear',
    defaultDescription: 'Reverb is a marketplace bringing together a wide-spanning community to buy, sell, and discuss all things music gear.',
    fullTitle: '{{title}} | {{postfix}}',
    postfix: 'Reverb',
    titleWithPage: '{{title}} - Page {{page}}',
  },
  invalidDate: 'Unspecified Year',
  linkList: {
    ctaText: 'Explore New Category',
  },
  listingsCollection: {
    seeMore: 'See More',
  },
  marketingCallout: {
    seeMore: 'See More',
    buyingGuideSubtitle: 'Buying Guide',
    bestSellers: 'Best Sellers',
  },
  freeExpeditedShipping: 'Free 2-Day Shipping',
  imageFeature: {
    sponsoredContentPartner: 'In Partnership With {{partner}}',
  },
  traitCollection: {
    seeMore: 'See More',
  },
  videoPlayer: {
    views: '{{viewCount}} Views',
    viewMore: 'Visit Reverb on YouTube',
  },
};
