import React from 'react';
import I18n from 'i18n-js';

import { SanitizedRender } from '@reverbdotcom/commons';
import { core_apimessages_Money } from '@reverbdotcom/commons/src/gql/graphql';
import { parseAmount } from '@reverbdotcom/commons/src/money';
import { useExpEnabled } from '@reverbdotcom/commons/src/user_hooks';
import experiments from '@reverbdotcom/commons/src/experiments';

const MIN_PRICE_DROP_PERCENT_FOR_BUYER_NOTIFICATION = 5;

interface IExternalProps {
  originalPrice: core_apimessages_Money;
  currentPriceAmount: string;
  isLiveListing: boolean;
}

export function calculatePercentOff(originalPrice: core_apimessages_Money, currentPriceAmount: string) {
  const currentPrice = parseAmount(currentPriceAmount, originalPrice.currency);
  const isPriceDropped = originalPrice.amountCents > currentPrice.amountCents;

  if (!isPriceDropped) return;

  const percent = (currentPrice.amountCents / originalPrice.amountCents) * 100;
  const percentOff = Math.round(100 - percent);

  return percentOff;
}

export default function QuickPriceEditHelpText({ originalPrice, currentPriceAmount, isLiveListing }: IExternalProps) {
  const isV2QuickPriceEdit = useExpEnabled(experiments.LISTINGS_MANAGEMENT_QUICK_PRICE_EDIT_UPDATES);

  const currentPrice = parseAmount(currentPriceAmount, originalPrice.currency);

  if (originalPrice.amountCents == currentPrice.amountCents) return null;

  const percentOff = calculatePercentOff(originalPrice, currentPriceAmount);
  const percentOffText = I18n.t('discovery.listingsManagement.quickPriceEdit.modal.moneyInput.priceDropPrompt', {
    percentOff,
  });
  const dropMoreText = isLiveListing && !isV2QuickPriceEdit ? I18n.t('discovery.listingsManagement.quickPriceEdit.modal.moneyInput.dropMorePrompt') : '';

  const priceDropText = percentOff >= MIN_PRICE_DROP_PERCENT_FOR_BUYER_NOTIFICATION ? percentOffText : dropMoreText;

  const priceDropClass = isV2QuickPriceEdit ? 'text-color-secondary' : 'text-color-sentiment-negative-dark';
  const priceDropHTML = `<span class='${priceDropClass}'>${priceDropText}</span>`;
  const originalPriceWithPriceDropHTML = `<span>${I18n.t('discovery.listingsManagement.quickPriceEdit.modal.moneyInput.originalPriceWithPriceDropHelpText', {
    originalPrice: originalPrice.display,
    priceDropHTML: priceDropHTML,
  })}</span>`;

  const showPriceDropText = originalPrice.amountCents > currentPrice.amountCents && isLiveListing;

  return (
    <>
      {showPriceDropText ? (
        <SanitizedRender
          html={originalPriceWithPriceDropHTML}
        />
      ) : (
        <span>
          {I18n.t('discovery.listingsManagement.quickPriceEdit.modal.moneyInput.originalPriceHelpText', {
            originalPrice: originalPrice.display,
          })}
        </span>
      )}
    </>
  );
}
