// TODO update this to import from commons/src/gql
// eslint-disable-next-line no-restricted-imports
import { gql } from '@apollo/client';
import { AddToCartButtonFragment } from './add_to_cart_button';
import { ListingGreatValueFragment } from '../listing_great_value';
import { ListingCreateOfferButtonFragment } from '../offers/create_offer_button';
import { WatchBadgeFragment } from './watch_badge';
import { ListingCardCPOFragment } from './listing_card_cpo_signal';

// This fragment should only be used if
// intending to show Bump UI. Analytics events
// will check this field to determine if
// a listing is shown as bumped.
const ListingsBumpKeyFieldsFragment = gql`
  fragment BumpKey on Listing {
    _id
    bumpKey {
      key
    }
  }
`;

const ListingsFinancingFieldsFragment = gql`
  fragment FinancingFields on Listing {
    _id
    estimatedMonthlyPaymentPlan {
      estimatedMonthlyPayment {
        display
      }
    }
  }
`;

const ListingImagesFragment = gql`
  fragment ListingImagesFields on Listing{
    _id
    images(input: {
      transform: "card_square",
      count: 3,
      scope: "photos",
      type: "Product"
    }) {
      _id
      source
    }
    largeSquareImages: images(input: {
      transform: "large_square",
      count: 3,
      scope: "photos",
      type: "Product"
    }) {
      _id
      source
    }
  }
`;

const ListingsShopFieldsFragment = gql`
  fragment ShopFields on Listing {
    _id
    shop {
      _id
      address {
        _id
        locality
        countryCode
        region
      }
    }
  }
`;

const ListingsCollectionFragment = gql`
    fragment ListingsCollection on Listing {
      _id
      id
      usOutlet
      bumped
      categoryUuids
      slug
      title
      description
      listingType
      condition {
        displayName
        conditionSlug
      }
      price {
        amountCents
        display
      }
      pricing {
        buyerPrice {
          display
          currency
          amountCents
        }
        originalPrice {
          display
        }
        ribbon {
          display
          reason
        }
      }
      watching
      state
      stateDescription
      shipping {
        shippingPrices {
          _id
          shippingMethod
          carrierCalculated
          destinationPostalCodeNeeded
          rate {
            amount
            amountCents
            currency
            display
          }
        }
        freeExpeditedShipping
        localPickupOnly
      },
      shop {
        _id
        address {
          _id
          country {
            _id
            countryCode
            name
          }
        }
      }
      ...AddToCartButtonFields
      ...WatchBadgeData
      ...ListingGreatValueData
      ...ListingCreateOfferButtonData
      ...ListingImagesFields
      ...ListingCardCPOData
    }
    ${ListingImagesFragment}
    ${WatchBadgeFragment}
    ${AddToCartButtonFragment}
    ${ListingGreatValueFragment}
    ${ListingCreateOfferButtonFragment}
    ${ListingCardCPOFragment}
  `;

export const ListingsCollectionFragments = {
  bumpKey: ListingsBumpKeyFieldsFragment,
  financingFields: ListingsFinancingFieldsFragment,
  listing: ListingsCollectionFragment,
  shopFields: ListingsShopFieldsFragment,
};
