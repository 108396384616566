/* eslint-disable new-cap */
import React from 'react';
import I18n from 'i18n-js';
import { RCPopover, RCButton, RCToast } from '@reverbdotcom/cadence/components';
import AffirmZeroPercentPopoverContent from './AffirmZeroPercentPopoverContent';
import { trackEvent, MParticleEventName } from '@reverbdotcom/commons/src/elog/mparticle_tracker';
import SellerListingsCollectionContext from '../../SellerListingsCollectionContext';

export const NOT_ELIGIBLE = 'not_eligible';
export const ALREADY_ENABLED = 'already_enabled';
export const FAILED_TO_CREATE = 'failed_to_create';
export const NOT_IN_ZERO_PERCENT = 'not_in_zero_percent';
export const FAILED_TO_DELETE = 'failed_to_delete';

interface IExternalProps {
  listingId: string;
  isAffirmEnabled: boolean;
}

function toastErrorMessage(errorType) {
  switch (errorType) {
    case NOT_ELIGIBLE:
      return I18n.t('discovery.listingsManagement.toast.message.enable.notEligible');
    case ALREADY_ENABLED:
      return I18n.t('discovery.listingsManagement.toast.message.enable.alreadyEnabled');
    case FAILED_TO_CREATE:
      return I18n.t('discovery.listingsManagement.toast.message.enable.failedToEnable');
    case NOT_IN_ZERO_PERCENT:
      return I18n.t('discovery.listingsManagement.toast.message.disable.notInZeroPercent');
    case FAILED_TO_DELETE:
      return I18n.t('discovery.listingsManagement.toast.message.disable.failedToDisable');
    default:
      return I18n.t('discovery.listingsManagement.genericError');
  }
}

export default function AffirmZeroPercentButton({
  listingId,
  isAffirmEnabled,
}: IExternalProps) {
  const { useLazySellerListingsQuery } = React.useContext(SellerListingsCollectionContext);
  const { refetchSellerListings } = useLazySellerListingsQuery();

  const [showToast, setShowToast] = React.useState(false);
  const [isSuccess, setIsSuccess] = React.useState(false);
  const [toastMessage, setToastMessage] = React.useState('');
  const [isPopoverOpen, setIsPopoverOpen] = React.useState(false);

  const buttonText = isAffirmEnabled ? I18n.t('discovery.listingsManagement.sellerListingCard.affirmZeroPercent.disableButtonText') : I18n.t('discovery.listingsManagement.sellerListingCard.affirmZeroPercent.enableButtonText');

  return (
    <>
      <RCPopover
        isOpen={isPopoverOpen}
        onDismiss={() => setIsPopoverOpen(false)}
        position="bottom-end"
        trapFocus
        anchor={
          <RCButton
            variant="muted"
            onClick={() => {
              setIsPopoverOpen(true);
              trackEvent({
                componentName: 'AffirmZeroPercentButton',
                eventName: MParticleEventName.ClickedAffirmZeroPercentFinancingButton,
                listingId: listingId,
              });
            }
            }
            fullWidth>
            {buttonText}
          </RCButton>
        }
      >
        <AffirmZeroPercentPopoverContent
          isAffirmEnabled={isAffirmEnabled}
          listingId={listingId}
          onSuccessCallback={() => {
            refetchSellerListings([listingId]);
            setIsPopoverOpen(false);
            setShowToast(true);
            setIsSuccess(true);
            setToastMessage(I18n.t('discovery.listingsManagement.toast.message.success'));
          }}
          onFailureCallback={(errorType) => {
            refetchSellerListings([listingId]);
            setToastMessage(toastErrorMessage(errorType));
            setIsSuccess(false);
            setIsPopoverOpen(false);
            setShowToast(true);
          }}
        />
      </RCPopover>

      <RCToast
        delayInterval="short"
        onClose={() => {
          setShowToast(false);
        }}
        text={toastMessage}
        theme={isSuccess ? 'success' : 'error'}
        isOpen={showToast}
      />
    </>
  );
}
