import React from 'react';
import classNames from 'classnames';
import CoreLink from '@reverbdotcom/commons/src/components/core_link';

interface IStep {
  name: string
  label: string
  link?: string
}

interface IProps {
  activeStep: string
  steps: IStep[]
}

function Path({ index, activeIndex, total }) {
  if (index === total - 1) return null;

  const pathClasses = classNames(
    'step-progress-bar__path',
    { 'step-progress-bar__path--finished': (index <= activeIndex - 1) },
    { 'step-progress-bar__path--active': (index === activeIndex - 1) },

  );

  return (
    <li className={pathClasses} />
  );
}

function Label({ index, activeIndex, lastIndex, label, link }) {
  if (link && index < activeIndex && activeIndex !== lastIndex) {
    return (
      <CoreLink className="step-progress-bar__step__label" to={link}>
        {label}
      </CoreLink>
    );
  }

  return (
    <div className="step-progress-bar__step__label">
      {label}
    </div>
  );
}

function Step({ index, activeIndex, lastIndex, label, link }) {
  const stepClasses = classNames(
    'step-progress-bar__step',
    { 'step-progress-bar__step--finished': (index < activeIndex || activeIndex === lastIndex) },
    { 'step-progress-bar__step--current': (index === activeIndex) },

  );
  return (
    <li className={stepClasses}>
      <Label index={index} activeIndex={activeIndex} lastIndex={lastIndex} label={label} link={link} />
    </li>
  );
}

export default function StepProgressBar(props: IProps) {
  const {
    activeStep,
    steps,
  } = props;

  const activeIndex = steps.findIndex(step => step.name === activeStep);

  return (
    <ul className="step-progress-bar">
      {steps.map((step, index) => {
        return (
          <React.Fragment key={step.name}>
            <Step label={step.label} link={step.link} index={index} activeIndex={activeIndex} lastIndex={steps.length - 1} />
            <Path index={index} activeIndex={activeIndex} total={steps.length} />
          </React.Fragment>
        );
      })}
    </ul>
  );
}
