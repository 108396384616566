import { isDefaultedSellerLocationFilter } from '../marketplace_filter_row';
import { Filter, FilterOption } from '../search_filters';

export function getFiltersTotal(filters : Filter[], excludedFilterKeys: string[], userShippingRegionCode: string) {
  const selectedFilterCounts = filters.map(filter => {
    const key = filter.aggregationName || filter.key;

    if (excludedFilterKeys.includes(key)) return 0;

    if (isDefaultedSellerLocationFilter(key, userShippingRegionCode)) return 0;

    return countSelectedOptionsForFilter(filter.options, excludedFilterKeys, userShippingRegionCode, key);
  });

  return selectedFilterCounts.length > 0 && selectedFilterCounts.reduce((total, filter) => {
    return filter > 0 ? total + filter : total;
  });
}

export function countSelectedOptionsForFilter(options: FilterOption[], excludedFilterKeys: string[], userShippingRegionCode, key: string) {
  return options.reduce((acc, option) => {
    if (option.all) return acc;

    // Checking Shipping Region Code
    if (key === 'SHIPS_TO' && option.optionValue === userShippingRegionCode) return acc;

    // Checking normal cases
    if (option.selected) return acc + 1;

    // Checking Subfilters
    if (option.subFilter) {
      return acc + countSelectedOptionsForFilter(option.subFilter.options, excludedFilterKeys, userShippingRegionCode, key);
    }

    return acc;
  }, 0);
}

export function getExcludedFilterKeys(pathname, pinTextQuery = false) {
  const excluded = ['SORT_BY'];
  if (pathname?.includes('/marketplace') || pinTextQuery) {
    excluded.push('TEXT_QUERY');
  }

  if (pathname?.includes('/shop')) {
    excluded.push('SELLER_LOCATION');
  }

  return excluded;
}
