export const AD_IMAGE_FEATURE = 'ad_image_feature';
export const ALBUM_COMPARISON_EMBED = 'album_sample_comparison_embed';
export const ALBUM_EMBED = 'album_embed';
export const ALBUM_FEATURE_EMBED = 'album_feature_embed';
export const ALBUM_GRID = 'album_grid';
export const ALBUM_ROW_EMBED = 'album_row_embed';
export const ALBUM_WITH_QUOTE_EMBED = 'album_with_quote_embed';
export const APP_CALLOUT = 'app_callout';
export const ARTICLE_SECTION = 'article_section';
export const ARTIST_ROW = 'artist_row';
export const BRAND_COLLECTION = 'brand_collection';
export const CALL_TO_ACTION = 'call_to_action';
export const CATEGORY_COLLECTION = 'category_collection';
export const CATEGORY_SEARCH = 'category_search';
export const CMS_PAGE_CARDS = 'cms_page_cards';
export const COLLECTION_LISTINGS_ROW_EMBED = 'collection_listings_row_embed';
export const CSP_COLLECTION = 'csp_collection';
export const CSP_FEATURE = 'csp_feature';
export const CSP_TILES = 'csp_tiles';
export const CURATED_SET_COLLECTION = 'curated_set_collection';
export const EMAIL_SIGNUP_FORM = 'email_signup_form';
export const FAQ_SECTION = 'faq_section';
export const FEED_ROW = 'feed_row';
export const FULL_BLEED_HEADING = 'full_bleed_heading';
export const GENRE_ROW = 'genre_row';
export const HANDPICKED_LISTINGS_ROW_EMBED = 'handpicked_listings_row_embed';
export const HANDPICKED_PAGE_ROWS = 'handpicked_page_rows';
export const HEADING = 'heading';
export const HOLIDAY_GRID = 'holiday_grid';
export const IMAGE_EMBED = 'image_embed';
export const IMAGE_FEATURE = 'image_feature';
export const IMAGE_GALLERY = 'image_gallery';
export const LINK_LIST = 'link_list';
export const LISTINGS_COLLECTION = 'listings_collection';
export const PERSONALIZED_LISTINGS_COLLECTION = 'personalized_listings_collection';
export const JUMPLINK_LOCATION = 'jumplink_location';
export const MARKDOWN_SECTION = 'markdown_section';
export const MARKDOWN_TO_HTML_SECTION = 'markdown_to_html_section';
export const MARKETING_CALLOUT = 'marketing_callout';
export const MOSAIC = 'mosaic';
export const PULLQUOTE = 'pullquote';
export const RECENTLY_VIEWED_LISTINGS = 'recently_viewed_listings';
export const RELEASE_EMBED = 'release_embed';
export const REVIEW_LIST = 'review_list';
export const SEO_BLURB = 'seo_blurb';
export const SITE_BANNER_HERO = 'site_banner_hero';
export const SHOP_LISTINGS_ROW_EMBED = 'shop_listings_row_embed';
export const SHOP_ROW_EMBED = 'shop_row_embed';
export const SUBCATEGORY_NAV = 'subcategory_nav';
export const TOGGLED_SITE_BANNER = 'toggled_site_banner';
export const TRAIT_COLLECTION = 'trait_collection';
export const VIDEO_EMBED = 'video_embed';

const CORE_COMPONENTS = [
  AD_IMAGE_FEATURE,
  ARTICLE_SECTION,
  BRAND_COLLECTION,
  CALL_TO_ACTION,
  CATEGORY_COLLECTION,
  CATEGORY_SEARCH,
  CMS_PAGE_CARDS,
  CSP_FEATURE,
  CSP_TILES,
  CSP_COLLECTION,
  CURATED_SET_COLLECTION,
  EMAIL_SIGNUP_FORM,
  FAQ_SECTION,
  FULL_BLEED_HEADING,
  HEADING,
  IMAGE_EMBED,
  IMAGE_FEATURE,
  IMAGE_GALLERY,
  LINK_LIST,
  LISTINGS_COLLECTION,
  PERSONALIZED_LISTINGS_COLLECTION,
  JUMPLINK_LOCATION,
  MARKETING_CALLOUT,
  MARKDOWN_TO_HTML_SECTION,
  PULLQUOTE,
  RECENTLY_VIEWED_LISTINGS,
  REVIEW_LIST,
  SEO_BLURB,
  SITE_BANNER_HERO,
  SUBCATEGORY_NAV,
  TOGGLED_SITE_BANNER,
  TRAIT_COLLECTION,
  VIDEO_EMBED,
];

const LP_COMPONENTS = [
  ALBUM_COMPARISON_EMBED,
  ALBUM_EMBED,
  ALBUM_FEATURE_EMBED,
  ALBUM_GRID,
  ALBUM_ROW_EMBED,
  ALBUM_WITH_QUOTE_EMBED,
  APP_CALLOUT,
  ARTICLE_SECTION,
  ARTIST_ROW,
  CALL_TO_ACTION,
  CATEGORY_SEARCH,
  CMS_PAGE_CARDS,
  COLLECTION_LISTINGS_ROW_EMBED,
  CSP_COLLECTION,
  CSP_FEATURE,
  CURATED_SET_COLLECTION,
  EMAIL_SIGNUP_FORM,
  FEED_ROW,
  FULL_BLEED_HEADING,
  GENRE_ROW,
  HANDPICKED_LISTINGS_ROW_EMBED,
  HANDPICKED_PAGE_ROWS,
  HEADING,
  IMAGE_EMBED,
  IMAGE_FEATURE,
  IMAGE_GALLERY,
  LINK_LIST,
  LISTINGS_COLLECTION,
  MARKDOWN_TO_HTML_SECTION,
  MARKETING_CALLOUT,
  MOSAIC,
  PULLQUOTE,
  RELEASE_EMBED,
  SEO_BLURB,
  SHOP_LISTINGS_ROW_EMBED,
  SHOP_ROW_EMBED,
  VIDEO_EMBED,
];

export function getComponentsForChannel(channels) {
  if (channels.indexOf('lp') !== -1) return LP_COMPONENTS;

  return CORE_COMPONENTS;
}
