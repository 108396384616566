import { core_apimessages_AccertifyDeviceInfo } from './gql/graphql';
import window from './window_wrapper';

/** Interface matching the shape of Accertify-provided window._bcn JS */
export interface AccertifyBcn {
  flush: () => string;
  getToken: () => string;
  getEvents: () => string;
  getPageId: () => number;
  getUbaSessionId: () => string;
  dvc: {
    getTID: () => string,
  }
}

export function buildDeviceInfoPayload(): core_apimessages_AccertifyDeviceInfo {
  if (!window._bcn) {
    return {};
  } else {
    return {
      transactionId: window._bcn.dvc?.getTID() || '',
      ubaId: window._bcn.getToken(),
      ubaEvents: window._bcn.getEvents(),
      pageId: String(window._bcn.getPageId()),
      ubaSessionId: window._bcn.getUbaSessionId(),
    };
  }
}
