import React from 'react';
import { core_apimessages_Address } from '@reverbdotcom/commons/src/gql/graphql';
import bind from '@reverbdotcom/commons/src/bind';
import I18n from 'i18n-js';
import AddressSummary from '../../AddressSummary';
import { AddressBookClickInteraction } from '@reverbdotcom/commons/src/elog/mparticle_types';
import { RCTag } from '@reverbdotcom/cadence/components';

interface IProps {
  isSelected: boolean;
  onSelect(uuid: string): void;
  address: core_apimessages_Address;
  onEdit(event: any): void;
  makeAddressPrimary: boolean;
  onMakePrimary(): void;
  trackEvent(interaction: AddressBookClickInteraction): void;
}

export default class AddressRow extends React.Component<IProps, null> {
  @bind
  handleEdit(e) {
    e.preventDefault();
    this.props.trackEvent(AddressBookClickInteraction.EditAddress);
    this.props.onEdit(this.props.address);
  }

  renderPrimary() {
    if (!this.props.address.primary) { return null; }

    return (
      <RCTag>
        {I18n.t('discovery.addressBook.row.defaultLabel')}
      </RCTag>
    );
  }

  renderEdit() {
    if (!this.props.isSelected) { return null; }

    return (
      <li className="actionable-row__action">
        <button
          className="button-as-link icon-l-pencil"
          onClick={this.handleEdit}
          type="button"
        >
          {I18n.t('discovery.addressBook.row.actions.edit')}
        </button>
      </li>
    );
  }

  @bind
  handleSelect(event) {
    this.props.onSelect(event.target.value);
  }

  renderMakePrimaryCheckbox() {
    if (this.props.address.primary || !this.props.isSelected) { return null; }
    return (
      <div className="pl-6 mt-2">
        <div className="label-with-checkbox">
          <input
            name="address_primary"
            type="checkbox"
            id="address_primary"
            checked={this.props.makeAddressPrimary}
            onChange={this.props.onMakePrimary}
          />
          <label htmlFor="address_primary">
            {I18n.t('discovery.addressBook.form.makeDefault')}
          </label>
        </div>
      </div>
    );
  }

  render() {
    const { address } = this.props;
    return (
      <li className="actionable-row">
        <div className="actionable-row__content">
          <div className="label-with-radio">
            <input
              type="radio"
              name="selected_address"
              checked={this.props.isSelected}
              onChange={this.handleSelect}
              value={this.props.address.uuid}
              id={this.props.address.uuid}
            />
            <label htmlFor={this.props.address.uuid}>
              <div>
                <AddressSummary address={address} />
              </div>
            </label>
            {this.renderMakePrimaryCheckbox()}
          </div>
        </div>
        <ul className="actionable-row__actions">
          <li className="actionable-row__action">{this.renderPrimary()}</li>
          {this.renderEdit()}
        </ul>
      </li>
    );
  }
}
