import React from 'react';
import InternationalTaxProfileContent from './international_tax_profile_content';
import MarketplaceTaxReports from './marketplace_tax_reports';

interface Props {
  availableReportYears: number[];
}

export function InternationalTaxProfileContainer({ availableReportYears }: Props) {
  return (
    <>
      <MarketplaceTaxReports
        availableReportYears={availableReportYears}
      />
      <InternationalTaxProfileContent />
    </>
  );
}

export default InternationalTaxProfileContainer;
