import { gql } from '@reverbdotcom/commons/src/gql';
import React from 'react';
import { withGraphql } from '@reverbdotcom/commons/src/with_graphql';
import { isMarketplacePage } from '@reverbdotcom/commons/src/url_helpers';
import { withUrlContext } from '@reverbdotcom/commons/src/url_context';
import { withUserContext } from '@reverbdotcom/commons/src/components/user_context_provider';

function shouldSkipQuerySuggestions(ownProps) {
  if (!ownProps.url.query?.query) { return true; }
  if (!isMarketplacePage(ownProps.url)) { return true; }
  return false;
}

export function searchQueries(ownProps) {
  if (ownProps?.url.query) {
    return [
      {
        query: ownProps.url.query?.query,
      },
    ];
  }
  return [];
}

export const QUERY_SUGGESTIONS_QUERY = gql(`query MarketplaceQuerySuggestions(
  $searchQueries: [Input_reverb_personalization_SearchQuery]
) {
  querySuggestions(input: {
    queries: $searchQueries
  }) {
    queries {
      suggestions {
        query
        listings {
          _id
          id
          images(input: {
            type: "Product",
            scope: "photos",
            transform: "card_square"
            count: 1
          }) {
            _id
            source
          }
        }
      }
    }
  }
}`);

export default function withQuerySuggestions<IProps>(
  Component: React.ComponentType<IProps>,
): React.ComponentType<IProps> {
  const requestConfig = {
    skip: ownProps => shouldSkipQuerySuggestions(ownProps),
    options: (ownProps) => {
      return {
        ssr: false,
        variables: {
          searchQueries: searchQueries(ownProps),
        },
      };
    },
    props({ data, ownProps }) {
      const { querySuggestions, loading } = data;
      const suggestions = querySuggestions?.queries ? querySuggestions.queries[0].suggestions : [];
      const props = {
        ...ownProps,
        querySuggestions: suggestions,
        suggestionsLoading: loading,
      };
      return props;
    },
  };
  return withUserContext(withUrlContext(withGraphql(QUERY_SUGGESTIONS_QUERY, requestConfig)(Component))) as React.ComponentType<IProps>;
}
