import PropTypes from 'prop-types';
import React from 'react';
import UrlHelpers from '../shared/url_helpers';
import EmbeddedListing from './embedded_listing';
import { APIRequest } from '@reverbdotcom/discovery-ui';

class Embeds extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      embeds: {},
    };
  }

  componentDidMount() {
    this.fetchEmbeds();
  }

  fetchEmbeds() {
    APIRequest.V3.get(UrlHelpers.messageEmbedsPath(this.props.messageId)).done((response) => {
      this.setState({ embeds: response });
    });
  }

  get listings() {
    return this.state.embeds.listings;
  }

  renderListingEmbeds() {
    return this.listings.map(listing =>
      <EmbeddedListing listing={listing} key={`listing-${listing.id}`} />);
  }

  render() {
    if (!this.listings) { return null; }

    return (
      <div>
        {this.renderListingEmbeds()}
      </div>
    );
  }
}

Embeds.propTypes = {
  messageId: PropTypes.number.isRequired,
};

export default Embeds;
