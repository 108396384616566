export default {
  title: {
    affirm: 'Pay Over Time',
    android_pay: 'Google Pay',
    apple_pay: 'Apple Pay',
    dc_via_paypal: 'Your PayPal Account',
    direct_checkout: 'Debit or Credit Card',
    klarna_account: 'Buy with Klarna',
    klarna_pay_later: 'Pay Later with Klarna',
    other: 'Other',
    paypal_manual: 'Your PayPal Account',
    sofort: 'Buy with Sofort',
    paypal: 'Debit or Credit Card',
  },
  icon: {
    affirm: 'Affirm Icon',
    amex: 'American Express Icon',
    android_pay: 'Google Pay Icon',
    apple_pay: 'Apple Pay Icon',
    discover: 'Discover Icon',
    klarna_account: 'Klarna Icon',
    klarna_pay_later: 'Klarna Icon',
    mastercard: 'Mastercard Icon',
    other: 'Other Icon',
    paypal: 'PayPal Icon',
    reverb_bucks: 'Reverb Credits Icon',
    sofort: 'Sofort Icon',
    visa: 'Visa Icon',
  },
};
