import { MutationFunction } from '@reverbdotcom/commons/src/useHOCMutation';
// TODO update this to import from commons
// import { gql } from '@reverbdotcom/commons/src/gql';
// eslint-disable-next-line no-restricted-imports
import { gql } from '@apollo/client';
import React from 'react';
import I18n from 'i18n-js';
import { withGraphql } from '@reverbdotcom/commons/src/with_graphql';
import {
  usePlaidLink,
  PlaidLinkOnSuccess,
  PlaidLinkOnEvent,
  PlaidLinkOnExit,
} from 'react-plaid-link';
import { trackEvent, MParticleEventName } from '@reverbdotcom/commons/src/elog/mparticle_tracker';
import * as elog from '@reverbdotcom/commons/src/elog';
import { RCAlertBox } from '@reverbdotcom/cadence/components';
import { IUserContext, withUserContext } from '@reverbdotcom/commons';
import { inMobileApp } from '@reverbdotcom/commons/src/user_context_helpers';
import { connect } from '@reverbdotcom/commons/src/connect';
import { CA, US } from '@reverbdotcom/commons/src/constants';

const PLAID_EVENT_TYPES_FOR_TRACKING = ['OPEN', 'EXIT', 'HANDOFF'];
const PLAID_ERROR_EVENT_TYPE = 'ERROR';
const DEFAULT_COUNTRY_CODE = US;
const PLAID_ALLOWED_COUNTRIES = [US, CA];

interface MutationProps {
  createMyPlaidLinkToken?: MutationFunction<any, any>;
  savePlaidAccountMutationFn?: MutationFunction<any, any>;
  cancelPlaidAccountMutation?: MutationFunction<any, any>;
}

interface ExternalProps {
  summaryConfig: {
    setPlaidModalOpenInSummaryView: (callback: () => void) => void;
    refetchDcp?: () => void;
    setSummaryLoadingState?: (boolean) => void;
    setPendingPlaidAccountId: (string) => void;
    setPlaidAccountStatus: (string) => void;
  },
  openHandlerOnly?: boolean;
  pendingPlaidAccountId: string;
  shopCountryCode: string;
}

type Props = MutationProps & ExternalProps & IUserContext;

export function PlaidLinkContainer({
  createMyPlaidLinkToken,
  openHandlerOnly,
  pendingPlaidAccountId,
  summaryConfig,
  savePlaidAccountMutationFn,
  cancelPlaidAccountMutation,
  shopCountryCode,
  user,
}: Props) {
  const [loadingLinkToken, setLoadingLinkToken] = React.useState(false);
  const plaidCountryCode = PLAID_ALLOWED_COUNTRIES.includes(shopCountryCode) ? shopCountryCode : DEFAULT_COUNTRY_CODE;

  const [plaidLinkToken, setPlaidLinkToken] = React.useState(null);
  const urlParams = React.useMemo(
    () => new URLSearchParams(window.location.search),
    [],
  );
  const [oauthStateId, setOAuthStateId] = React.useState(
    urlParams.get('oauth_state_id'),
  );
  const tokenInLocalStorage = React.useMemo(
    () => localStorage.getItem('plaid-link-token'),
    [],
  );
  const [fetchNewToken, setFetchNewToken] = React.useState(null);
  const [hasError, setHasError] = React.useState(null);

  const handleExit = React.useCallback((refreshDCP: boolean | undefined) => {
    if (refreshDCP && summaryConfig.refetchDcp) {
      summaryConfig.refetchDcp();
    } else {
      // eslint-disable-next-line no-restricted-globals
      if (!history.pushState) {
        return;
      }
      setOAuthStateId(null);
      const newUrl = `${window.location.protocol}//${window.location.host}${window.location.pathname}`;
      window.history.pushState({ path: newUrl }, '', newUrl);
    }
  }, [summaryConfig]);

  async function savePlaidAccount(publicToken, metadata) {
    const accountId = metadata?.accounts[0]?.id;
    const verificationStatus = metadata?.accounts[0]?.verification_status;
    setHasError(false);
    try {
      summaryConfig.setSummaryLoadingState(true);
      const result: any | { data } = await savePlaidAccountMutationFn({
        variables: { input: { accountId, publicToken, verificationStatus, countryCode: plaidCountryCode } },
      });
      const plaidAccountId =
        result.data.saveMyPlaidAccount.plaidAccountId;
      summaryConfig.setPlaidAccountStatus(verificationStatus);

      if (plaidAccountId) {
        const newPlaidAccountIsPending = verificationStatus?.includes('pending');
        summaryConfig.setPendingPlaidAccountId(newPlaidAccountIsPending ? plaidAccountId : null);
        summaryConfig.setSummaryLoadingState(false);
        handleExit(!newPlaidAccountIsPending);
      }
    } catch (error) {
      elog.error(
        'plaid.frontend.savePlaidAccount.error',
        {
          metadata,
          errorMessage: error?.message,
          plaidAccountId: accountId,
        },
        error,
      );
      setHasError(true);
      summaryConfig.setSummaryLoadingState(false);
    }
  }

  async function cancelPlaidAccount() {
    try {
      summaryConfig.setSummaryLoadingState(true);
      await cancelPlaidAccountMutation({ variables: { input: {} } });
      summaryConfig.setPendingPlaidAccountId(null);
      summaryConfig.setPlaidAccountStatus(null);
      summaryConfig.setSummaryLoadingState(false);
    } catch (error) {
      elog.error(
        'plaid.frontend.cancelPlaidAccount.error',
        {
          errorMessage: error?.message,
          pendingPlaidAccountId,
        },
        error,
      );
      setHasError(true);
      summaryConfig.setSummaryLoadingState(false);
    }
  }

  React.useEffect(() => {
    async function createLinkToken(mutationFn) {
      setHasError(false);
      if (oauthStateId && tokenInLocalStorage && !fetchNewToken) {
        setPlaidLinkToken(tokenInLocalStorage);
        return;
      }
      try {
        setLoadingLinkToken(true);
        const isInMobileApp = inMobileApp(user);
        const result = await mutationFn({ variables: { input: { countryCode: plaidCountryCode, plaidAccountId: pendingPlaidAccountId, requiresMobileRedirect: isInMobileApp } } });
        const linkToken = result?.data?.createMyPlaidLinkToken.linkToken;
        setPlaidLinkToken(linkToken);
        localStorage.setItem('plaid-link-token', linkToken);
        setLoadingLinkToken(false);
      } catch (error) {
        elog.error(
          'plaid.frontend.createLinkToken.error',
          {
            errorMessage: error?.message,
          },
          error,
        );
        setHasError(true);
        setLoadingLinkToken(false);
      }
    }
    createLinkToken(createMyPlaidLinkToken);
  }, [
    createMyPlaidLinkToken,
    tokenInLocalStorage,
    oauthStateId,
    fetchNewToken,
    pendingPlaidAccountId,
    user,
  ]);

  const handlers = {
    savePlaidAccount,
    handleExit,
    setFetchNewToken,
    cancelPlaidAccount,
  };
  const { setPlaidModalOpenInSummaryView } = summaryConfig;

  return <>
    {hasError && <RCAlertBox type="error">
      {I18n.t('discovery.plaid.errorMsg')}
    </RCAlertBox>}
    {plaidLinkToken && <PlaidLink
      displayPendingButton={!!pendingPlaidAccountId}
      handlers={handlers}
      isOauth={oauthStateId}
      linkToken={plaidLinkToken}
      openHandlerOnly={openHandlerOnly && plaidCountryCode === US}
      setPlaidModalOpenInSummaryView={setPlaidModalOpenInSummaryView}
    />}
    {loadingLinkToken && !plaidLinkToken && !openHandlerOnly && <button className="button button--primary mt-2" type="button" disabled>
      {I18n.t('discovery.plaid.loading')}
    </button>
    }
    <p className="hint">{I18n.t('discovery.plaid.disclaimer')}</p>
  </>;
}

function PlaidLink({
  displayPendingButton,
  linkToken,
  isOauth,
  handlers,
  setPlaidModalOpenInSummaryView,
  openHandlerOnly,
}) {

  const onSuccess = React.useCallback<PlaidLinkOnSuccess>(
    (public_token, metadata) => {
      handlers.savePlaidAccount(public_token, metadata);
    },
    [handlers],
  );

  const cancelPendingPlaidAccount = () => {
    handlers.cancelPlaidAccount();
  };

  const onExit = React.useCallback<PlaidLinkOnExit>(
    (err, metadata) => {
      if (err) {
        elog.error(
          'plaid.frontend.plaidModal.error',
          {
            plaidErrorCode: err.error_code,
            plaidErrorType: err.error_type,
            plaidErrorMessage: err.error_message,
            institutionId: metadata.institution,
            plaidRequestId: metadata.request_id,
          },
          `plaid.frontend.plaidModal.error.${err.error_code}`,
        );
      }
      if (err && err.error_code === 'INVALID_LINK_TOKEN') {
        handlers.setFetchNewToken(true);
      } else if (err && err.error_code === 'TOO_MANY_VERIFICATION_ATTEMPTS') {
        cancelPendingPlaidAccount();
      }
      handlers.handleExit();
    },
    [handlers],
  );

  const onEvent = React.useCallback<PlaidLinkOnEvent>(
    (eventName, metadata) => {
      if (PLAID_EVENT_TYPES_FOR_TRACKING.includes(eventName)) {
        trackEvent({
          eventName: MParticleEventName.PlaidLink,
          plaidLinkSessionId: metadata.link_session_id,
          plaidExitStatus: metadata.exit_status,
          plaidErrorMessage: metadata.error_message,
          plaidEventName: eventName,
        });
      }

      if (eventName === PLAID_ERROR_EVENT_TYPE) {
        elog.error(
          'plaid.frontend.plaidModal.error',
          {
            plaidErrorType: metadata.error_type,
            plaidErrorMessage: metadata.error_message,
            institutionId: metadata.institution_id,
            plaidRequestId: metadata.request_id,
          },
          `plaid.frontend.plaidModal.error.${metadata.error_code}`,
        );
      } else {
        elog.info(
          `plaid.frontend.plaidModal.event.${eventName}`,
          metadata,
        );
      }
    },
    [],
  );

  const config: any = {
    token: linkToken,
    onSuccess,
    onExit,
    onEvent,
    receivedRedirectUri: isOauth ? document.location.href : null,
  };
  const { ready, open } = usePlaidLink(config);

  React.useEffect(() => {
    if (open && open.name === 'open') {
      setPlaidModalOpenInSummaryView(open);
    }
    if (isOauth && ready) {
      open();
    }
  }, [open, ready, setPlaidModalOpenInSummaryView, isOauth]);

  const handleClick = React.useCallback(
    (event) => {
      event.preventDefault();
      if (ready) {
        open();
      }
    },
    [open, ready],
  );

  if (displayPendingButton) {
    return (
      <>
        <button
          className="button button--primary mtb-2 mr-2"
          type="button"
          onClick={handleClick}
          disabled={!ready}
        >
          {I18n.t('discovery.plaid.verifyBankDeposits')}
        </button>

        <button
          className="button button--red mtb-2"
          type="button"
          onClick={cancelPendingPlaidAccount}
          disabled={!ready}
        >
          {I18n.t('discovery.plaid.depositsNotFound')}
        </button>
      </>);
  }

  return (
    <>
      {openHandlerOnly ? (
        <button
          data-change-link
          className="button-as-link mtb-2"
          dangerouslySetInnerHTML={{
            __html: I18n.t(
              'discovery.plaid.connectADifferentAccountWithPlaidHtml',
              { icon: '<span class="fa fa-pencil"></span>' },
            ),
          }}
          type="button"
          onClick={handleClick}
          disabled={!ready}
        />
      ) :
        (<button
          className="button button--primary mtb-2"
          type="button"
          onClick={handleClick}
          disabled={!ready}
        >
          {I18n.t('discovery.plaid.connect')}
        </button>)}
    </>
  );
}

export const CREATE_LINK_TOKEN_MUTATION = gql`
  mutation Core_Payouts_CreateMyPlaidLinkToken(
    $input: Input_core_apimessages_CreateMyPlaidLinkTokenRequest
  ) {
    createMyPlaidLinkToken(input: $input) {
      linkToken
    }
  }
`;

export const SAVE_PLAID_ACCOUNT_MUTATION = gql`
  mutation Core_Payouts_SaveMyPlaidAccount(
    $input: Input_core_apimessages_SaveMyPlaidAccountRequest
  ) {
    saveMyPlaidAccount(input: $input) {
      plaidAccountId
    }
  }
`;

export const CANCEL_PLAID_ACCOUNT_MUTATION = gql`
  mutation Core_Payouts_CancelPlaidAccount(
    $input: Input_core_apimessages_CancelPlaidAccountRequest
  ) {
    cancelPlaidAccount(input: $input) {
      shopId
    }
  }
`;

const withMutation = withGraphql<any, any, any>(CREATE_LINK_TOKEN_MUTATION, {
  name: 'createMyPlaidLinkToken',
  options: {
    errorPolicy: 'all',
  },
});

const withSaveMutation = withGraphql<any, any, any>(SAVE_PLAID_ACCOUNT_MUTATION, {
  name: 'savePlaidAccountMutationFn',
  options: {
    errorPolicy: 'all',
  },
});

const withCancelMutation = withGraphql<any, any, any>(CANCEL_PLAID_ACCOUNT_MUTATION, {
  name: 'cancelPlaidAccountMutation',
  options: {
    errorPolicy: 'all',
  },
});

export default connect<ExternalProps>([withUserContext, withMutation, withSaveMutation, withCancelMutation])(PlaidLinkContainer);
