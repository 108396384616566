import Purify from '../universal_purify';
import I18n from 'i18n-js';
import React from 'react';
import { Link } from 'react-router';
import bind from '../bind';

import { difference } from 'lodash';
import classNames from 'classnames';

interface IProps {
  html?: string;
  className?: string;
  allowedTags?: string[];
  allowedAttributes?: string[];
  blocklistedTags?: string[];
  breakLongURLs?: boolean;
  convertNewlines?: boolean;
  removeNonBreakingSpaces?: boolean;
  htmlTag?: string;

  // TODO: truncation functions should be extracted to another component
  // This component had some functionality tacked on that isn't a part
  // of the need for sanitization. We should actively discourage the use
  // of this component unless it's for sanitizing user controlled input
  truncationLimit?: number;
  truncationWiggle?: number;
  truncationHeight?: number;
  readMoreUrl?: string;
  id?: string;

  // if this is true, bypasses allowedTags prop
  // and uses a predefined list of inline tags
  inlineTagsOnly?: boolean;
  withoutFade?: boolean;
}

interface IState {
  shouldTruncateByCharacter: boolean;
  shouldTruncateByHeight: boolean;
}

export const ALLOWED_TAGS = [
  'h3', 'h4', 'h5', 'h6', 'blockquote', 'p', 'a', 'ul', 'ol',
  'nl', 'li', 'b', 'i', 'strong', 'em', 'strike', 'code', 'hr', 'br', 'div',
  'table', 'thead', 'caption', 'tbody', 'tr', 'th', 'td', 'pre', 'span', 'br',
];

export const ALLOWED_ATTRIBUTES = [
  'class', 'itemprop', 'itemscope', 'itemtype',
  'href', 'name', 'target',
  'src',
];

const INLINE_LEVEL_TAGS = [
  'a', 'b', 'i', 'strong', 'em', 'strike', 'span',
];

/**
 * SanitizedRender will clean user input and make it safe to render into
 * the DOM as HTML. This allows users some basic tags for use in things like
 * descriptions.
 *
 * Do not use this for content that is known to be safe (e.g. a hardcoded translation)
 * as santization is expensive and subject to regexp DDOS attacks. So always prefer
 * sanitizing user input on the backend or using the <I18N> component
 * for known safe input.
 */
export default class SanitizedRender extends React.Component<IProps, IState> {
  elementRef = React.createRef<HTMLDivElement>();

  componentDidMount() {
    if (!this.props.truncationHeight || !this.elementRef.current) return;

    this.setState({
      shouldTruncateByHeight: this.elementRef.current.getBoundingClientRect().height > this.props.truncationHeight,
    });
  }

  static defaultProps: Partial<IProps> = {
    allowedTags: ALLOWED_TAGS,
    allowedAttributes: ALLOWED_ATTRIBUTES,
    blocklistedTags: [],
    truncationWiggle: 0.25,
  };

  state = {
    shouldTruncateByCharacter: !!this.props.truncationLimit,
    shouldTruncateByHeight: !!this.props.truncationHeight,
  };

  breakLongURLs(clean) {
    if (!this.props.breakLongURLs) { return clean; }
    return clean.replace(/(\.|\/)/g, '<wbr>$1');
  }

  removeNonBreakingSpaces(clean) {
    if (!this.props.removeNonBreakingSpaces) { return clean; }
    return clean.replace(/\s?&nbsp;\s?/g, '').trim();
  }

  convertNewlines(clean) {
    if (!this.props.convertNewlines) { return clean; }
    return clean.replace(/\n/g, '<br>');
  }

  shouldTruncateByCharacter() {
    return this.couldTruncateByCharacter() && this.state.shouldTruncateByCharacter;
  }

  couldTruncateByCharacter() {
    const cleanedLength = this.getCleanText().length;
    return (
      cleanedLength > this.props.truncationLimit
      && !(cleanedLength <= this.props.truncationLimit * (1 + this.props.truncationWiggle))
    );
  }

  @bind
  toggleTruncationByCharacter() {
    this.setState({ shouldTruncateByCharacter: !this.state.shouldTruncateByCharacter });
  }

  @bind
  toggleTruncateByHeight(e) {
    this.setState({ shouldTruncateByHeight: !this.state.shouldTruncateByHeight });
    e.stopPropagation();
    e.preventDefault();
    return false;
  }

  allowedAttributes() {
    return this.props.allowedAttributes;
  }

  allowedTags() {
    if (this.props.inlineTagsOnly) {
      return difference(INLINE_LEVEL_TAGS, this.props.blocklistedTags);
    }

    return difference(this.props.allowedTags, this.props.blocklistedTags);
  }

  truncateByHeightStyle() {
    if (!this.state.shouldTruncateByHeight) return;

    return {
      maxHeight: `${this.props.truncationHeight}px`,
    };
  }

  truncateByHeightAttr() {
    if (!this.state.shouldTruncateByHeight) return;

    return {
      'data-truncate-with-fade': this.state.shouldTruncateByHeight && !this.props.withoutFade,
      'data-truncate-without-fade': this.state.shouldTruncateByHeight && this.props.withoutFade,
    };
  }

  getCleanText() {
    const clean = Purify.sanitize(
      this.props.html,
      {
        ALLOWED_ATTR: this.allowedAttributes(),
        ALLOWED_TAGS: this.allowedTags(),
        ALLOW_DATA_ATTR: false,
      },
    );
    return this.convertNewlines(
      this.breakLongURLs(
        this.removeNonBreakingSpaces(clean),
      ),
    );
  }

  getTruncatedText() {
    if (!this.state.shouldTruncateByCharacter || !this.couldTruncateByCharacter()) return this.getCleanText();
    const truncated = this.getCleanText().slice(0, this.props.truncationLimit);
    return Purify.sanitize(truncated).concat('…');
  }

  renderTruncationToggle() {
    if (!this.couldTruncateByCharacter()) return;

    if (this.state.shouldTruncateByCharacter && this.props.readMoreUrl) {
      return (
        <Link
          className="ml-space size-80"
          to={this.props.readMoreUrl}
        >
          {I18n.t('commons.sanitizedRender.expand')}
        </Link>
      );
    }

    return (
      <button
        className="button-as-link ml-space size-80"
        onClick={this.toggleTruncationByCharacter}
      >
        {I18n.t(`commons.sanitizedRender.${this.state.shouldTruncateByCharacter ? 'expand' : 'collapse'}`)}
      </button>
    );
  }

  renderTruncateByHeightToggle() {
    if (!this.state.shouldTruncateByHeight) return;

    if (this.props.readMoreUrl === '#') {
      return (
        <button
          type="button"
          className="ml-space size-80 button-as-link"
        >
          {I18n.t('commons.sanitizedRender.expandEllipsis')}
        </button>
      );
    }

    if (this.props.readMoreUrl) {
      return (
        <Link
          className="ml-space size-80"
          to={this.props.readMoreUrl}
        >
          {I18n.t('commons.sanitizedRender.expandEllipsis')}
        </Link>
      );
    }

    const classes = classNames(
      'button-as-link',
      { 'mt-2': !this.props.withoutFade },
      { 'text-color-gray-700 td-underline': this.props.withoutFade },
    );

    return (
      <button
        className={classes}
        onClick={this.toggleTruncateByHeight}
      >
        {I18n.t(`commons.sanitizedRender.${this.props.withoutFade ? 'expandCaps' : 'expandEllipsis'}`)}
      </button>
    );
  }

  render() {
    const { html } = this.props;
    if (!html) return null;

    const Tag = (this.props.htmlTag || 'span') as any;

    if (this.props.truncationLimit) {
      return (
        <Tag
          className={this.props.className}
          id={this.props.id}
        >
          <span
            dangerouslySetInnerHTML={{ __html: this.getTruncatedText() }}
          />
          {this.renderTruncationToggle()}
        </Tag>
      );
    }

    if (this.state.shouldTruncateByHeight) {
      return (
        <div
          className={this.props.className}
          id={this.props.id}
          ref={this.elementRef}
        >
          <div
            dangerouslySetInnerHTML={{ __html: this.getCleanText() }}
            {...this.truncateByHeightAttr()}
            style={this.truncateByHeightStyle()}
          />
          {this.renderTruncateByHeightToggle()}
        </div>
      );
    }

    return (
      <Tag
        className={this.props.className}
        dangerouslySetInnerHTML={{ __html: this.getCleanText() }}
        id={this.props.id}
      />
    );
  }
}
