// TODO update this to import from commons
// import { gql } from '@reverbdotcom/commons/src/gql';
// eslint-disable-next-line no-restricted-imports
import { gql } from '@apollo/client';
import React from 'react';
import { useHOCMutation, MutationFunction } from '@reverbdotcom/commons/src/useHOCMutation';
import { withGraphql } from '@reverbdotcom/commons/src/with_graphql';
import { DeleteMyFavorite, DeleteMyFavoriteMutation, DeleteMyFavoriteMutationVariables } from '@reverbdotcom/commons/src/gql/graphql';

export type DeleteMyFavoriteMutationFn = MutationFunction<DeleteMyFavorite.Mutation, DeleteMyFavorite.Variables>;

const mutationFnName = 'deleteMyFavorite';
type EmptyObject = Record<string, never>;

export const DELETE_FAVORITE_MUTATION = gql`
  mutation DeleteMyFavorite($input: Input_core_apimessages_DeleteMyFavoriteRequest) {
    deleteMyFavorite(input: $input) {
      favorite {
        id
        favorited
      }
    }
  }
`;

export const withDeleteMyFavoriteMutation =
  withGraphql<EmptyObject, DeleteMyFavoriteMutation, DeleteMyFavoriteMutationVariables>(
    DELETE_FAVORITE_MUTATION,
    {
      name: mutationFnName,
    },
  );

export interface DeleteMyFavoriteState {
  deleteMyFavorite: () => any;
}

export function useDeleteMyFavorite(
  deleteMyFavorite: DeleteMyFavoriteMutationFn,
  id: string,
  queryToBeRefetchedName?: string,
): DeleteMyFavoriteState {
  const [mutate] = useHOCMutation(deleteMyFavorite);

  const deleteMyFavoriteCallback = React.useCallback(async function () {
    return mutate({
      variables: {
        input: {
          id,
        },
      },
      optimisticResponse: {
        __typename: 'Mutation',
        deleteMyFavorite: {
          __typename: 'core_apimessages_DeleteMyFavoriteResponse',
          favorite: {
            __typename: 'core_apimessages_Favorite',
            id,
            favorited: false,
          },
        },
      },
      refetchQueries: queryToBeRefetchedName ? [queryToBeRefetchedName] : null,
      awaitRefetchQueries: !!queryToBeRefetchedName,
    });
  }, [id, queryToBeRefetchedName, mutate]);

  return { deleteMyFavorite: deleteMyFavoriteCallback };
}
