import React from 'react';
import classNames from 'classnames';

import NewsLetterSignup from '@reverbdotcom/commons/src/components/newsletter_signup';

interface IProps {
  callout: string;
  reason: string;
  hideBg?: boolean;
}

export default function EmailSignUpEmbed(props: IProps) {
  const { callout, reason, hideBg } = props;

  return (
    <section
      className={classNames('align-center d-flex scaling-padding-4', {
        'article-email-signup': !hideBg,
      })}
    >
      <div className="mlr-auto width-rem-60">
        <NewsLetterSignup
          signupReason={reason}
          title={callout}
        />
      </div>
    </section>
  );
}
