import I18n from 'i18n-js';
import React, { useContext } from 'react';

import { RCCheckbox, RCSwitch } from '@reverbdotcom/cadence/components';
import { MParticleEventName } from '@reverbdotcom/commons/src/elog/mparticle_types';
import { useViewTracking } from '@reverbdotcom/commons/src/use_tracking';
import { US } from '@reverbdotcom/commons/src/constants';
import { MoneyInput } from '@reverbdotcom/commons/src/components/money_input';
import { BRAND_NEW } from '@reverbdotcom/commons/src/condition_helpers';
import { FormCards } from '@reverbdotcom/commons/src/components/FormCards';
import { trackEvent } from '@reverbdotcom/commons/src/elog/mparticle_tracker';
import { parseAmount } from '@reverbdotcom/commons/src/money';
import { useUser } from '@reverbdotcom/commons/src/user_hooks';
import { isExperimentEnabled } from '@reverbdotcom/commons/src/user_context_helpers';
import experiments from '@reverbdotcom/commons/src/experiments';

import PriceBreakdown from '../PriceBreakdown';
import SellFormIncVatPricingHint from '../SellFormIncVatPricingHint';
import { Field, ActionType } from '../../sellFormReducerTypes';
import { SellFormContext, IShopConfig } from '../../SellFormContext';
import SellFormPricingGuidance from '../pricing_guidance/SellFormPricingGuidance';
import { SellFormScheduledPriceDrop } from '../SellFormScheduledPriceDrop';
import lightningBolt from '../../../../../../images/icons/lightning-bolt.svg';
import SellFormGreatValueCallout, {
  COMPONENT_NAME as GREAT_VALUE_COMPONENT_NAME, isGreatValueVisible,
} from '../SellFormGreatValueCallout';
import SellFormPriceSignalCallout from '../SellFormPriceSignalCallout';
import { reverb_pricing_PriceRecommendation } from '@reverbdotcom/commons/src/gql/graphql';
import { formatExperimentsForEventAttributes } from '@reverbdotcom/commons/src/elog/mparticle';


export const COMPONENT_NAME = 'PricingSection';

export function isShopInUSA(shopConfig: IShopConfig) {
  return shopConfig.address.countryCode === US;
}

function offersEnabledCheckboxSublabel(autoRejectLowOffers, autoAcceptHighOffers) {
  let text = I18n.t(`discovery.sellForm.fields.${Field.OFFERS_ENABLED}.checkboxSublabel`);

  if (autoAcceptHighOffers && autoRejectLowOffers) {
    text += I18n.t(`discovery.sellForm.fields.${Field.OFFERS_ENABLED}.lowballAndAutoAcceptText`);
  } else if (autoAcceptHighOffers) {
    text += I18n.t(`discovery.sellForm.fields.${Field.OFFERS_ENABLED}.autoAcceptText`);
  } else if (autoRejectLowOffers) {
    text += I18n.t(`discovery.sellForm.fields.${Field.OFFERS_ENABLED}.lowballText`);
  }

  return text;
}

function trackGreatValueBadgeVisible() {
  trackEvent({
    eventName: MParticleEventName.ComponentView,
    componentName: GREAT_VALUE_COMPONENT_NAME,
  });
}

export function PricingSection() {
  const user = useUser();
  const [priceRecommendation, setPriceRecommendation] = React.useState<reverb_pricing_PriceRecommendation>({});

  const {
    listingEntry,
    dispatch,
    loading,
    errorMap,
    shopConfig,
    listingCreateFlow,
    showSellingFeeWaived,
    currentCanonicalProductId,
  } = useContext(SellFormContext);
  const {
    currencySetting: currency,
    hasAccountRep,
    offerPolicy,
    preferredSeller,
    taxPolicies = [],
    inclusiveVatPricingEnabled,
  } = shopConfig;
  const { autoRejectLowOffers, autoAcceptHighOffers } = offerPolicy ?? {};
  const showVatTaxExemptField = taxPolicies.length > 0 && taxPolicies.some(({ vatPolicy }) => !!vatPolicy);
  const defaultLocaleContent = listingEntry[Field.LOCALIZED_CONTENTS].find((lc) => lc.defaultForShop);
  const title = [defaultLocaleContent[Field.MAKE], defaultLocaleContent[Field.MODEL], defaultLocaleContent[Field.FINISH]].filter(Boolean).join(' ');
  const isBrandNew = listingEntry[Field.CONDITION_SLUG] === BRAND_NEW;
  const adminOrAccountRep = hasAccountRep || user.isAdmin;
  const showMapField = isBrandNew && adminOrAccountRep;

  const sellerAmount = listingEntry[Field.PRICE]?.amount ?? '';
  const sellerPrice = parseAmount(sellerAmount, currency);
  const showOverpricedSignalCallouts = isShopInUSA(shopConfig) && isExperimentEnabled(user, experiments.SELL_FORM_OVERPRICED_SIGNAL);
  const showGreatValue = isGreatValueVisible({ sellerPrice, priceRecommendation });

  const showIncVatPricingHint = inclusiveVatPricingEnabled && isBrandNew && isExperimentEnabled(user, experiments.INC_VAT_LISTING_PRICE);

  useViewTracking({
    componentName: COMPONENT_NAME,
    eventName: MParticleEventName.ComponentView,
    experiments: formatExperimentsForEventAttributes(user, [experiments.SELL_FORM_OVERPRICED_SIGNAL]),
    listingCreateFlow,
  }, true);

  return (
    <>
      <FormCards.Card>
        <SellFormPricingGuidance
          canonicalProduct={{ title, id: currentCanonicalProductId }}
          conditionSlug={listingEntry[Field.CONDITION_SLUG]}
          shopConfig={shopConfig}
          updatePriceRecommendation={updatedPriceRecommendation => setPriceRecommendation(updatedPriceRecommendation)}
        />

        <FormCards.FormSection fullWidthForm>
          <div className="d-flex fx-justify-between gap-8 width-100">
            <div className="d-flex fx-dir-col width-60 mobile-width-100">
              <div className="mb-1">
                <MoneyInput
                  id={Field.PRICE}
                  name={Field.PRICE}
                  label={I18n.t(`discovery.sellForm.fields.${Field.PRICE}.label`)}
                  value={sellerAmount}
                  onChange={(event) => {
                    dispatch({
                      type: ActionType.UPDATE_FIELD,
                      fieldKey: Field.PRICE,
                      value: { amount: event.target.value },
                    });
                  }}
                  tag="required"
                  required
                  disabled={loading}
                  errorText={errorMap[Field.PRICE]}
                  currencyCode={currency}
                  onBlur={() => {
                    if (showGreatValue && !showOverpricedSignalCallouts) {
                      trackGreatValueBadgeVisible();
                    }
                  }}
                />
              </div>

              <div className="d-none mobile-d-flex">
                {showOverpricedSignalCallouts &&
                  <SellFormPriceSignalCallout
                    calloutPrice={sellerPrice}
                    conditionSlug={listingEntry[Field.CONDITION_SLUG]}
                    canonicalProductId={currentCanonicalProductId} />}
                {!showOverpricedSignalCallouts &&
                  <SellFormGreatValueCallout sellerPrice={sellerPrice} priceRecommendation={priceRecommendation} />}
              </div>

              {showIncVatPricingHint &&
                <SellFormIncVatPricingHint />
              }

              <PriceBreakdown
                price={sellerAmount}
                currency={currency}
                preferredSeller={preferredSeller}
                sellingFeeWaived={showSellingFeeWaived}
                shopConfig={shopConfig}
                showIncVatPricingHint={showIncVatPricingHint}
              />

              {showVatTaxExemptField && (
                <>
                  <div className="weight-bold size-90 mt-6 mb-2">
                    {I18n.t(`discovery.sellForm.fields.${Field.TAX_EXEMPT}.checkboxTitle`)}
                  </div>
                  <RCCheckbox
                    name={Field.TAX_EXEMPT}
                    id={Field.TAX_EXEMPT}
                    label={I18n.t(`discovery.sellForm.fields.${Field.TAX_EXEMPT}.checkboxLabel`)}
                    checked={listingEntry[Field.TAX_EXEMPT]}
                    onChange={() => dispatch({
                      type: ActionType.UPDATE_FIELD,
                      fieldKey: Field.TAX_EXEMPT,
                      value: !listingEntry[Field.TAX_EXEMPT],
                    })}
                    disabled={loading}
                  />
                </>
              )}

              {showMapField && (
                <div className="mt-4">
                  <MoneyInput
                    id={Field.SELLER_REPORTED_MAP}
                    name={Field.SELLER_REPORTED_MAP}
                    label={I18n.t('discovery.sellForm.fields.sellerReportedMap.label')}
                    value={listingEntry[Field.SELLER_REPORTED_MAP]?.amount || ''}
                    onChange={(event) => dispatch({
                      type: ActionType.UPDATE_FIELD,
                      fieldKey: Field.SELLER_REPORTED_MAP,
                      value: { amount: event.target.value },
                    })}
                    disabled={loading}
                    errorText={errorMap[Field.SELLER_REPORTED_MAP]}
                    currencyCode={currency}
                    helpText={I18n.t('discovery.sellForm.fields.sellerReportedMap.helpText')}
                  />
                </div>
              )}
            </div>
            <div className="width-40 mobile-width-100 mobile-d-none">
              {showOverpricedSignalCallouts &&
                <SellFormPriceSignalCallout
                  calloutPrice={sellerPrice}
                  conditionSlug={listingEntry[Field.CONDITION_SLUG]}
                  trackEvents
                  canonicalProductId={currentCanonicalProductId} />}
              {!showOverpricedSignalCallouts &&
                <SellFormGreatValueCallout sellerPrice={sellerPrice} priceRecommendation={priceRecommendation} />}
            </div>
          </div>
        </FormCards.FormSection>
        <FormCards.ToggledSection
          id="seller-cost-collapse"
          title={I18n.t(`discovery.sellForm.fields.${Field.SELLER_COST}.descriptionHeading`)}
          defaultOpen={!!listingEntry[Field.SELLER_COST]?.amount}
        >
          <div className="pr-20 mobile-pr-0">
            <p>{I18n.t(`discovery.sellForm.fields.${Field.SELLER_COST}.descriptionContent`)}</p>
          </div>
          <MoneyInput
            id={Field.SELLER_COST}
            name={Field.SELLER_COST}
            label={I18n.t(`discovery.sellForm.fields.${Field.SELLER_COST}.label`)}
            value={listingEntry[Field.SELLER_COST]?.amount || ''}
            onChange={(event) => dispatch({
              type: ActionType.UPDATE_FIELD,
              fieldKey: Field.SELLER_COST,
              value: { amount: event.target.value },
            })}
            disabled={loading}
            errorText={errorMap[Field.SELLER_COST]}
            currencyCode={currency}
            helpText={I18n.t(`discovery.sellForm.fields.${Field.SELLER_COST}.helpText`)}
          />
        </FormCards.ToggledSection>
      </FormCards.Card>

      <FormCards.Card>
        <FormCards.FormSection>
          <div className="width-rem-50 tablet-max-width-70 mobile-max-width-100">
            <div className="mb-4 d-flex fx-align-center">
              <img
                src={lightningBolt}
                alt={I18n.t('discovery.sellForm.fields.sellYourGearFaster.heading')}
                className="mr-2"
              />
              <span className="size-110 weight-bold">
                {I18n.t('discovery.sellForm.fields.sellYourGearFaster.heading')}
              </span>
            </div>
            <p>
              {I18n.t('discovery.sellForm.fields.sellYourGearFaster.increaseChancesOfSelling')}
            </p>

            <SellFormScheduledPriceDrop/>

            <div className="mt-4">
              <RCSwitch
                name={Field.OFFERS_ENABLED}
                id={Field.OFFERS_ENABLED}
                label={I18n.t(`discovery.sellForm.fields.${Field.OFFERS_ENABLED}.checkboxLabelForScheduledPriceDropExperiment`)}
                sublabel={offersEnabledCheckboxSublabel(autoRejectLowOffers, autoAcceptHighOffers)}
                checked={listingEntry[Field.OFFERS_ENABLED]}
                onChange={() => dispatch({
                  type: ActionType.UPDATE_FIELD,
                  fieldKey: Field.OFFERS_ENABLED,
                  value: !listingEntry[Field.OFFERS_ENABLED],
                })}
                disabled={loading}
              />
            </div>
          </div>
        </FormCards.FormSection>
      </FormCards.Card>
    </>
  );
}
