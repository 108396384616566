import React from 'react';
import I18n from 'i18n-js';
import classNames from 'classnames';

import SanitizedRender from '@reverbdotcom/commons/src/components/sanitized_render';
import useDismissibleAlert from '@reverbdotcom/commons/src/components/use_dismissible_alert';

import URLHelpers from '../../shared/url_helpers';

interface IProps {
  dismissibleWithKey?: string;
  texturedBackground?: boolean;
  withBorder?: boolean;
  verticalStack?: boolean;
}

interface IValuePropSectionProps {
  imgSrc: string;
  title: string;
  body: React.ReactNode;
}

ReverbShippingLabelsValueProp.defaultProps = {
  dismissibleWithKey: undefined,
  texturedBackground: false,
  withBorder: false,
  verticalStack: false,
};

function ValuePropSection({
  imgSrc,
  title,
  body,
}: IValuePropSectionProps) {
  return (
    <div className="rsl-value-prop">
      <div className="rsl-value-prop__image">
        <img src={imgSrc} alt={title} />
      </div>
      <div className="rsl-value-prop__content">
        <h4 className="rsl-value-prop__title">
          {title}
        </h4>
        <div className="rsl-value-prop__subtitle">
          {body}
        </div>
      </div>
    </div>
  );
}

export default function ReverbShippingLabelsValueProp({
  dismissibleWithKey,
  texturedBackground,
  withBorder,
  verticalStack,
}: IProps) {
  const [isDismissed, dismissAlertBox] = useDismissibleAlert(dismissibleWithKey);
  if (dismissibleWithKey && isDismissed) { return null; }

  return (
    <div
      className={classNames(
        'rsl-value-props',
        {
          'rsl-value-props--border': withBorder,
          'rsl-value-props--vertical': verticalStack,
          'rsl-value-props--textured': texturedBackground,
        },
      )}
    >

      <div className="rsl-value-props__header">
        <div className="rsl-value-props__title-block">
          <h3 className="rsl-value-props__title">
            <SanitizedRender
              htmlTag="div"
              html={I18n.t('discovery.dashboard.selling.shippingLabels.reverbShippingLabelsValueProp.title',
                {
                  linkOpen: `<a href=${URLHelpers.sellingShippingLabelsPath} target="_blank" class="text-link">`,
                  linkClose: '</a>',
                },
              )}
            />
          </h3>

          {!!dismissibleWithKey && (
            <div className="pl-4">
              <button
                type="button"
                onClick={dismissAlertBox}
                className="button-as-link color-primary size-120"
              >
                <span className="fa fa-close" />
              </button>
            </div>
          )}
        </div>
      </div>

      <div className="rsl-value-props__content">
        <ValuePropSection
          imgSrc="https://res.cloudinary.com/reverb-marketing/image/upload/v1622133378/20191230_illustration_v4_100x100px_color_protection.svg"
          title={I18n.t('discovery.dashboard.selling.shippingLabels.reverbShippingLabelsValueProp.worryFree')}
          body={(
            <SanitizedRender
              htmlTag="div"
              html={I18n.t('discovery.dashboard.selling.shippingLabels.reverbShippingLabelsValueProp.worryFreeBody',
                {
                  tagOpen: `<strong><a href=${URLHelpers.sellingSafeShippingInfoPath} target="_blank">`,
                  tagClose: '</a></strong>',
                },
              )}
            />
          )}
        />

        <ValuePropSection
          imgSrc="https://res.cloudinary.com/reverb-marketing/image/upload/v1622135038/20191230_illustration_v7_100x100px_color_clock.svg"
          title={I18n.t('discovery.dashboard.selling.shippingLabels.reverbShippingLabelsValueProp.saveMoneyTime')}
          body={I18n.t('discovery.dashboard.selling.shippingLabels.reverbShippingLabelsValueProp.saveMoneyTimeBody')}
        />
      </div>
    </div>
  );
}
