import React from 'react';
import I18n from 'i18n-js';
import { PaymentProvider } from './payment_provider';

interface Props {
  shopCurrency: string;
  payoutCurrency: string;
  paymentProvider: PaymentProvider;
}

export default function CurrencyExchangeFeeWarning(props: Props) {
  if (props.paymentProvider !== PaymentProvider.ReverbPayments || props.shopCurrency === props.payoutCurrency) {
    return null;
  }

  return (
    <p className="small">
      {I18n.t(
        'discovery.payoutMethod.directCheckoutProfile.bankAccountFields.dashboardCurrencyExchangeFeeWarning',
        {
          shopCurrencyCode: props.shopCurrency,
        },
      )}
    </p>
  );
}
