import { isObject, isArray } from 'lodash';

/**
 * Fixes a bug with Apollo 3 where server side cache keys may be generated
 * differently than client side cache keys. This causes a cache hydration
 * mismatch on the client, which causes flicker / re-render while the
 * client refetches data that wasn't provided by the server.
 *
 * The bug happens with queries that use SSR and an Input type (nested object)
 * as a variable.
 *
 * Instead of avoiding usage of nested input variables, or having to define
 * nested input variables in a consistent way (alphabetically sorted) in
 * every query component, normalize all variables here before calling Apollo,
 * which ensures consistent cache keys are generated on the client and server.
 *
 * Example query and variables:
 *
 *    query Core_Marketplace_MarketplaceListingsCount(
 *      $inputListings: Input_reverb_search_ListingsSearchRequest
 *    ) {
 *      listingsSearch(input: $inputListings) {
 *        total
 *        __typename
 *      }
 *    }
 *
 *    variables: {
 *      inputListings: {
 *        query: 'akai',
 *        priceMax: '100',
 *        currency: 'USD'
 *      }
 *    }
 *
 * Apollo cache keys are expected to be generated in alphabetical order.
 * With nested object variables, this happens on the client, but does not
 * happen on server-side in the React Rendering Engine (this is the bug).
 *
 * The client cache key for the variable above uses alphabetically sorted
 * properties and looks something like this:
 *
 *  {
 *    'ROOT_QUERY': {
 *      'listingsSearch({"input":{"currency":"USD","priceMax":"100","query":"akai"}})': [...cache values...]
 *    }
 *  }
 *
 * The server cache key for the same variable uses properties sorted by definition order:
 *
 *  {
 *    'ROOT_QUERY': {
 *      'listingsSearch({"input":{"query":"akai","priceMax":"100","currency":"USD"}})': [...cache values...]
 *    }
 *  }
 *
 * By redefining variables here using alphabetically sorted properties,
 * the client and server cache keys are generated the same way.
 */
export function normalizeVariables<T>(variables: T): T {
  const normalized = {};

  Object.keys(variables).sort().forEach((key) => {
    normalized[key] = normalizeValue(variables[key]);
  });

  return normalized as T;
}

function normalizeValue(value) {
  if (isArray(value)) {
    return value.map(v => normalizeValue(v));
  }

  if (!isObject(value)) {
    return value;
  }

  const normalizedObj = {};
  const sortedKeys = Object.keys(value).sort();
  sortedKeys.forEach((key) => {
    normalizedObj[key] = normalizeValue(value[key]);
  });

  return normalizedObj;
}
