import { ChildProps } from '@apollo/client/react/hoc';
// TODO update this to import from commons
// import { gql } from '@reverbdotcom/commons/src/gql';
// eslint-disable-next-line no-restricted-imports
import { gql } from '@apollo/client';
/* eslint-disable @typescript-eslint/indent */
import I18n from 'i18n-js';
import React from 'react';
import classNames from 'classnames';
import { withGraphql } from '@reverbdotcom/commons/src/with_graphql';
import { withUserContext, IUserContext } from '@reverbdotcom/commons/src/components/user_context_provider';
import ListingsCollection, { ListingsCollection as ListingsCollectionClass } from '@reverbdotcom/commons/src/components/listings_collection';
import { OverflowingRowAction } from '@reverbdotcom/commons/src/components/overflowing_row_action';

import {
  RegionalListingsRowQuery,
  reverb_search_ListingsSearchRequest_Sort,
} from '@reverbdotcom/commons/src/gql/graphql';
import { USED_CONDITION, EVERYWHERE_CODE } from '../shared/constants';

const NON_PARTICIPATING_COUNTRIES = ['US', EVERYWHERE_CODE];
const NUM_LISTINGS = 6;
const COUNTRIES_THAT_REQUIRE_THE = ['GB'];

interface ExternalProps {}

function countryOptedOut(countryCode: string): boolean {
  // "Everywhere" region (XX) does not have country code
  if (!countryCode) { return true; }
  return !!(NON_PARTICIPATING_COUNTRIES.indexOf(countryCode) + 1);
}

function countryRequiresThe(countryCode) {
  return COUNTRIES_THAT_REQUIRE_THE.includes(countryCode);
}

type IProps = ChildProps<ExternalProps & IUserContext, RegionalListingsRowQuery.Query, RegionalListingsRowQuery.Variables>;

function Header({ countryCode }: { countryCode: string }) {
  const classes = classNames(
    'flag-icon-header__icon',
    `flag-icon flag-icon-${countryCode.toLowerCase()}`,
  );

    const country = I18n.t(`countries.${countryCode}`);
    const titleKey = countryRequiresThe(countryCode) ?
      'discovery.regionalListings.titleWithThe' :
      'discovery.regionalListings.title';

    const title = I18n.t(titleKey, { country });

    return (
      <div className="flag-icon-header">
        <span className={classes}></span>
        {title}
      </div>
    );
}

export function RegionalListings({ user, data }: IProps) {
  const marketplaceHref = `/marketplace?item_region=${user.countryCode}`;

  if (data?.error) { return null; }
  if (countryOptedOut(user.countryCode)) { return null; }

  const limit = NUM_LISTINGS;
  const listings = (data?.listingsSearch?.listings || []).slice(0, limit);
    return (
      <ListingsCollection
        callToAction={
          <OverflowingRowAction
            href={marketplaceHref}
            text={I18n.t('discovery.regionalListings.viewMore')}
          />
        }
        componentName="regionalListings"
        loading={data.loading}
        maxCount={limit}
        listings={listings}
        title={<Header countryCode={user.countryCode} />}
        showFlags
        largeTiles={false}
      />
    );
}

const withQuery = withGraphql<IProps, RegionalListingsRowQuery.Query, RegionalListingsRowQuery.Variables>(
  gql`
    query Regional_Listings_Row_Query(
      $itemRegion: String
      $conditionSlugs: [String]
      $sort: reverb_search_ListingsSearchRequest_Sort
      $limit: Int
    ) {
      listingsSearch(
        input: {
          itemRegion: $itemRegion
          conditionSlugs: $conditionSlugs
          sort: $sort,
          limit: $limit,
        }
      ) {
        listings {
          _id
          ... ListingsCollection
        }
      }
    }
    ${ListingsCollectionClass.fragments.listing}
  `,
  {
    options: (ownProps) => {
      return {
        ssr: false,
        skip: !!ownProps.user?.loggedOut,
        variables: {
          itemRegion: ownProps.user?.countryCode,
          conditionSlugs: [USED_CONDITION],
          sort: reverb_search_ListingsSearchRequest_Sort.NONE,
          limit: NUM_LISTINGS,
        },
      };
    },
  },
);

export default withUserContext(withQuery(RegionalListings));
