import React from 'react';

interface handleBulkResponsesArgs {
  responses: any;
  I18nSuccessMessageKey: string;
  I18nErrorMessageKey: string;
  successArgs?: object;
}

interface handleAsyncBulkResponseArgs {
  I18nKey: string;
  expectedTotal: number;
  I18nArgs?: object;
}
interface ISellerListingsToolbarContext {
  handleBulkResponses: (args: handleBulkResponsesArgs) => void;
  handleGenericError: (errorKey?: string) => void;
  handleAsyncBulkResponse: (arg: handleAsyncBulkResponseArgs) => void;
}

export default React.createContext<ISellerListingsToolbarContext>({
  handleBulkResponses: (_args) => {},
  handleGenericError: (_errorKey) => {},
  handleAsyncBulkResponse: (_args) => {},
});
