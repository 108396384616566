import React from 'react';
import I18n from 'i18n-js';
import moment from 'moment';
import CalendarInput, { YEAR_MONTH_DAY_FORMAT } from './shared/calendar_input';
import bind from '@reverbdotcom/commons/src/bind';

const MINIMUM_SEND_DATE = moment().add(1, 'day');

interface IProps {
  sendAt: string;
}

interface IState {
  sendAt?: moment.Moment;
  sendNow: boolean;
}

export default class GiftCardSendDatePicker extends React.Component<IProps, IState> {
  constructor(props) {
    super(props);
    const { sendAt } = this.props;
    this.state = {
      sendAt: (sendAt && moment(sendAt)) || null,
      sendNow: !this.props.sendAt,
    };
  }

  @bind
  onChange(sendAt: moment.Moment) {
    this.setState({ sendAt });
  }

  @bind
  toggleSendNow() {
    const sendNow = !this.state.sendNow;

    if (sendNow) {
      this.setState({
        sendNow,
        sendAt: null,
      });
    } else {
      this.setState({
        sendNow,
        sendAt: MINIMUM_SEND_DATE,
      });
    }
  }

  @bind
  formatHiddenSendAt() {
    if (this.state.sendAt) {
      return this.state.sendAt.format(YEAR_MONTH_DAY_FORMAT);
    }
    return '';
  }

  render() {
    return (
      <div className="form-group mb-0">
        <div className="form-group__header">
          <label>{I18n.t('discovery.giftCards.deliveryDate.title')}</label>
        </div>
        <div className="form-group__fields">
          <div className="label-with-radio">
            <input
              name="sendTiming"
              id="sendNow"
              type="radio"
              onChange={this.toggleSendNow}
              checked={this.state.sendNow}
            />
            <label htmlFor="sendNow">{I18n.t('discovery.giftCards.deliveryDate.sendNow')}</label>
          </div>
          <div className="label-with-radio">
            <input
              name="sendTiming"
              id="sendLater"
              type="radio"
              onChange={this.toggleSendNow}
              checked={!this.state.sendNow}
            />
            <label htmlFor="sendLater">{I18n.t('discovery.giftCards.deliveryDate.chooseDate')}</label>
          </div>
          {!this.state.sendNow && (
            <div className="width-40 mobile-width-100 mt-1">
              <CalendarInput
                minDate={MINIMUM_SEND_DATE}
                currentDate={this.state.sendAt}
                onChange={this.onChange}
              />
            </div>
          )}
          <input type="hidden" value={this.formatHiddenSendAt()} name="gift_card_form[send_at]" />
        </div>
      </div>
    );
  }
}
