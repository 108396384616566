import { IUser } from '@reverbdotcom/commons/src/components/user_context_provider';
import { canViewZeroPercentFinancingPromo } from '@reverbdotcom/commons/src/user_context_helpers';
import { browsePagePaths } from './shared/browsePagePaths';
import { dealsAndStealsPromoURLPath, theWarehousePromoURLPath } from './promo_url_path_for_user';

export interface CategoryPanelData {
  key: string;
  i18nPrefix: string;
  hrefPrefix?: string;
  headingKey?: string;
  headingHref?: string;
  columns: CategoryColumnData[][];
  curated?: CuratedData;
}

export interface CuratedData {
  imageHref: string;
  children: LinkData[];
}

export interface CategoryColumnData {
  headingKey: string;
  viewAllHref?: string;
  children?: LinkData[];
  slugs?: string[];
}

export interface LinkData {
  textKey: string;
  href: string;
  usOnly?: boolean;
  enLocaleOnly?: boolean;
}

function financingPromoLink(user: Partial<IUser>) {
  if (!canViewZeroPercentFinancingPromo(user)) { return []; }

  return (
    [
      {
        textKey: 'zeroPercentFinancing',
        href: '/promo/financing',
      },
    ]
  );
}

export function buildFindADealLinks(user: Partial<IUser>): LinkData[] {
  return [
    {
      textKey: 'deals',
      href: dealsAndStealsPromoURLPath(user),
    },
    {
      textKey: 'warehouse',
      href: theWarehousePromoURLPath(user),
    },
  ];
}

export function buildExploreLinks(user: Partial<IUser>, hideDealsAndSteals = false): LinkData[] {
  return [
    ...(financingPromoLink(user)),
    {
      textKey: 'twoDay',
      href: '/promo/free-2-day-shipping',
      usOnly: true,
    },
    {
      textKey: 'buyingGuides',
      href: '/guide',
      enLocaleOnly: true,
    },
    ...(hideDealsAndSteals ? [] : [{
      textKey: 'deals',
      href: dealsAndStealsPromoURLPath(user),
    }]),
    {
      textKey: 'exclusives',
      href: '/promo/reverb-exclusives-and-makers',
      usOnly: true,
    },
    {
      textKey: 'priceDrops',
      href: '/promo/price-drops',
    },
    {
      textKey: 'priceGuide',
      href: '/price-guide',
    },
    {
      textKey: 'sellerHub',
      href: '/selling',
    },
    {
      textKey: 'topSellers',
      href: '/promo/top-sellers-recent-releases',
      usOnly: true,
    },
  ];
}

export const brandData: CategoryPanelData = {
  key: 'brands',
  i18nPrefix: 'discovery.categoryNavBar.brands',
  headingHref: '/brands',
  hrefPrefix: '/brand/',
  headingKey: 'title',
  columns: [
    [
      {
        headingKey: 'guitars',
        slugs: [
          'fender',
          'gibson',
          'epiphone',
          'prs',
          'ibanez',
          'martin',
          'squier',
          'taylor-guitars',
          'gretsch',
          'esp',
        ],
      },
    ],
    [
      {
        headingKey: 'effects',
        slugs: [
          'boss',
          'electro-harmonix',
          'tc-electronic',
          'earthquaker-devices',
          'jhs',
          'keeley',
          'fender',
          'marshall',
          'orange',
          'vox',
        ],
      },
    ],
    [
      {
        headingKey: 'keyboards',
        slugs: [
          'korg',
          'roland',
          'yamaha',
          'arturia',
          'make-noise',
          'moog',
          'mutable-instruments',
          'akai',
          'novation',
          'teenage-engineering',
        ],
      },
    ],
    [
      {
        headingKey: 'recording',
        slugs: [
          'focusrite',
          'shure',
          'behringer',
          'sennheiser',
          'universal-audio',
          'presonus',
          'dbx',
          'yamaha',
          'tascam',
          'radial',
        ],
      },
    ],
    [
      {
        headingKey: 'drums',
        slugs: [
          'zildjian',
          'sabian',
          'paiste',
          'ludwig',
          'dw',
          'meinl',
          'yamaha',
          'roland',
          'pearl',
          'tama',
        ],
      },
    ],
    [
      {
        headingKey: 'dj',
        slugs: [
          'roland',
          'akai',
          'boss',
          'elektron',
          'korg',
          'arturia',
          'alesis',
          'novation',
          'native-instruments',
          'pioneer',
        ],
      },
    ],
  ],
};

export function buildCategoryData(user: Partial<IUser>): CategoryPanelData[] {
  const dealsAndStealsPath = dealsAndStealsPromoURLPath(user);

  return [
    {
      key: 'guitars',
      i18nPrefix: 'discovery.categoryNavBar.guitars',
      headingKey: 'title',
      columns: [
        [
          {
            headingKey: 'electric',
            viewAllHref: browsePagePaths.electricGuitars,
            children: [
              {
                textKey: 'solidBody',
                href: browsePagePaths.electricGuitarsSolidBody,
              },
              {
                textKey: 'leftHanded',
                href: browsePagePaths.electricGuitarsLeftHanded,
              },
              {
                textKey: 'semiHollow',
                href: browsePagePaths.electricGuitarsSemiHollow,
              },
              {
                textKey: 'hollowBody',
                href: browsePagePaths.electricGuitarsHollowBody,
              },
              {
                textKey: 'twelveString',
                href: browsePagePaths.electricGuitars12String,
              },
              {
                textKey: 'archtop',
                href: browsePagePaths.electricGuitarsArchtop,
              },
              {
                textKey: 'baritone',
                href: browsePagePaths.electricGuitarsBaritone,
              },
              {
                textKey: 'pedalSteel',
                href: browsePagePaths.electricGuitarsPedalSteel,
              },
              {
                textKey: 'tenor',
                href: browsePagePaths.electricGuitarsTenor,
              },
              {
                textKey: 'lapSteel',
                href: browsePagePaths.electricGuitarsLapSteel,
              },
              {
                textKey: 'mini',
                href: browsePagePaths.electricGuitarsTravelMini,
              },
              {
                textKey: 'vintageElectric',
                href: '/marketplace?product_type=electric-guitars&year_max=1980',
              },
            ],
          },
          {
            headingKey: 'bassGuitars',
            viewAllHref: browsePagePaths.bassGuitars,
            children: [
              {
                textKey: 'fourString',
                href: browsePagePaths.bassGuitars4String,
              },
              {
                textKey: 'shortScale',
                href: browsePagePaths.bassGuitarsShortScale,
              },
              {
                textKey: 'leftHanded',
                href: browsePagePaths.bassGuitarsLeftHanded,
              },
              {
                textKey: 'fretless',
                href: browsePagePaths.bassGuitarsFretless,
              },
              {
                textKey: 'acousticBass',
                href: browsePagePaths.bassGuitarsAcousticBass,
              },
              {
                textKey: 'vintageBass',
                href: '/marketplace?product_type=bass-guitars&year_max=1980',
              },
            ],
          },
        ],
        [
          {
            headingKey: 'acousticGuitars',
            viewAllHref: browsePagePaths.acousticGuitars,
            children: [
              {
                textKey: 'dreadnought',
                href: browsePagePaths.acousticGuitarsDreadnought,
              },
              {
                textKey: 'classical',
                href: browsePagePaths.acousticGuitarsClassical,
              },
              {
                textKey: 'parlor',
                href: browsePagePaths.acousticGuitarsParlor,
              },
              {
                textKey: 'twelveString',
                href: browsePagePaths.acousticGuitars12String,
              },
              {
                textKey: 'leftHanded',
                href: browsePagePaths.acousticGuitarsLeftHanded,
              },
              {
                textKey: 'resonator',
                href: browsePagePaths.acousticGuitarsResonator,
              },
              {
                textKey: 'archtop',
                href: browsePagePaths.acousticGuitarsArchtop,
              },
              {
                textKey: 'jumbo',
                href: browsePagePaths.acousticGuitarsJumbo,
              },
              {
                textKey: 'concert',
                href: browsePagePaths.acousticGuitarsConcert,
              },
              {
                textKey: 'tenor',
                href: browsePagePaths.acousticGuitarsTenor,
              },
              {
                textKey: 'baritone',
                href: browsePagePaths.acousticGuitarsBaritone,
              },
              {
                textKey: 'mini',
                href: browsePagePaths.acousticGuitarsTravelMini,
              },
              {
                textKey: 'vintageAcoustic',
                href: '/marketplace?product_type=acoustic-guitars&year_max=1980',
              },
            ],
          },
        ],
        [
          {
            headingKey: 'guitarParts',
            viewAllHref: '/c/parts/guitar-parts',
            children: [
              {
                textKey: 'accessories',
                href: '/c/accessories',
              },
              {
                textKey: 'guitarPickups',
                href: '/c/parts/guitar-pickups',
              },
              {
                textKey: 'guitarBodies',
                href: '/c/parts/guitar-bodies',
              },
              {
                textKey: 'necks',
                href: '/c/parts/necks',
              },
              {
                textKey: 'bridges',
                href: '/c/parts/bridges',
              },
              {
                textKey: 'pickguards',
                href: '/c/parts/pickguards',
              },
              {
                textKey: 'tuningHeads',
                href: '/c/parts/tuning-heads',
              },
              {
                textKey: 'knobs',
                href: '/c/parts/knobs',
              },
              {
                textKey: 'bassGuitarParts',
                href: '/c/parts/bass-guitar-parts',
              },
              {
                textKey: 'bassPickups',
                href: '/c/parts/bass-pickups',
              },
              {
                textKey: 'acousticPickups',
                href: '/c/parts/acoustic-pickups',
              },
              {
                textKey: 'bridgePins',
                href: '/c/parts/bridge-pins',
              },
              {
                textKey: 'strings',
                href: '/c/accessories/strings',
              },
            ],
          },
        ],
        [
          {
            headingKey: 'popularProducts',
            children: [
              {
                textKey: 'fenderStratocaster',
                href: browsePagePaths.fenderStratocaster,
              },
              {
                textKey: 'fenderTelecaster',
                href: browsePagePaths.fenderTelecaster,
              },
              {
                textKey: 'gibsonLesPaul',
                href: browsePagePaths.gibsonLesPaul,
              },
              {
                textKey: 'epiphoneLesPaul',
                href: browsePagePaths.epiphoneLesPaul,
              },
              {
                textKey: 'gibsonSg',
                href: browsePagePaths.gibsonSg,
              },
              {
                textKey: 'fenderMustang',
                href: browsePagePaths.fenderMustang,
              },
              {
                textKey: 'fenderJaguar',
                href: browsePagePaths.fenderJaguar,
              },
              {
                textKey: 'fenderJazzmaster',
                href: browsePagePaths.fenderJazzmaster,
              },
            ],
          },
        ],
      ],
      curated: {
        imageHref: 'https://rvb-img.reverb.com/image/upload/v1590188040/1-Guitars---Player-Grade-Vintage_lwco1o.jpg',
        children: [
          {
            textKey: 'dealsAndSteals',
            href: dealsAndStealsPath,
          },
          {
            textKey: 'priceDrops',
            href: '/promo/price-drops',
          },
          {
            textKey: 'playerGradeVintage',
            href: '/handpicked/vintage-guitars-player-grade',
          },
          {
            textKey: 'fenderBassGuitars',
            href: '/brand/fender?product_type=bass-guitars',
          },
          {
            textKey: 'artisanalAcoustics',
            href: '/handpicked/artisanal-acoustics',
          },
          {
            textKey: 'collectorGradeVintage',
            href: '/handpicked/classic-vintage-guitars',
          },
          {
            textKey: 'selling',
            href: '/selling',
          },
        ],
      },
    },
    {
      key: 'pedalsAmps',
      i18nPrefix: 'discovery.categoryNavBar.pedalsAmps',
      columns: [
        [
          {
            headingKey: 'effectsAndPedals',
            viewAllHref: '/c/effects-and-pedals/',
            children: [
              {
                textKey: 'overdriveAndBoost',
                href: '/c/effects-and-pedals/overdrive-and-boost',
              },
              {
                textKey: 'fuzz',
                href: '/c/effects-and-pedals/fuzz',
              },
              {
                textKey: 'delay',
                href: '/c/effects-and-pedals/delay',
              },
              {
                textKey: 'reverb',
                href: '/c/effects-and-pedals/reverb',
              },
              {
                textKey: 'multiEffectUnit',
                href: '/c/effects-and-pedals/multi-effect-unit',
              },
              {
                textKey: 'distortion',
                href: '/c/effects-and-pedals/distortion',
              },
              {
                textKey: 'loopPedalsAndSamplers',
                href: '/c/effects-and-pedals/loop-pedals-and-samplers',
              },
              {
                textKey: 'chorusAndVibrato',
                href: '/c/effects-and-pedals/chorus-and-vibrato',
              },
              {
                textKey: 'compressionAndSustain',
                href: '/c/effects-and-pedals/compression-and-sustain',
              },
              {
                textKey: 'octaveAndPitch',
                href: '/c/effects-and-pedals/octave-and-pitch',
              },
              {
                textKey: 'tremolo',
                href: '/c/effects-and-pedals/tremolo',
              },
              {
                textKey: 'flanger',
                href: '/c/effects-and-pedals/flanger',
              },
              {
                textKey: 'phaseShifters',
                href: '/c/effects-and-pedals/phase-shifters',
              },
              {
                textKey: 'wahsAndFilters',
                href: '/c/effects-and-pedals/wahs-and-filters',
              },
            ],
          },
        ],
        [
          {
            headingKey: 'pedalUtilities',
            children: [
              {
                textKey: 'pedalboardPowerSupplies',
                href: '/c/effects-and-pedals/pedalboards-and-power-supplies',
              },
              {
                textKey: 'tuningPedals',
                href: '/c/effects-and-pedals/tuning-pedals',
              },
              {
                textKey: 'eq',
                href: '/c/effects-and-pedals/eq',
              },
              {
                textKey: 'controllersVolumeAndExpression',
                href: '/c/effects-and-pedals/controllers-volume-and-expression',
              },
              {
                textKey: 'ampModeling',
                href: '/c/effects-and-pedals/amp-modeling',
              },
              {
                textKey: 'cabSims',
                href: '/c/effects-and-pedals/cab-sims',
              },
            ],
          },
        ],
        [
          {
            headingKey: 'amps',
            viewAllHref: '/c/amps/',
            children: [
              {
                textKey: 'guitarCombos',
                href: '/c/amps/guitar-combos',
              },
              {
                textKey: 'guitarHeads',
                href: '/c/amps/guitar-heads',
              },
              {
                textKey: 'modelingAmps',
                href: '/c/amps/modeling-amps',
              },
              {
                textKey: 'smallAmps',
                href: '/c/amps/small-amps',
              },
              {
                textKey: 'bassHeads',
                href: '/c/amps/bass-heads',
              },
              {
                textKey: 'guitarCabinets',
                href: '/c/amps/guitar-cabinets',
              },
              {
                textKey: 'bassCombos',
                href: '/c/amps/bass-combos',
              },
              {
                textKey: 'attenuators',
                href: '/c/amps/attenuators',
              },
              {
                textKey: 'bassCabinets',
                href: '/c/amps/bass-cabinets',
              },
              {
                textKey: 'acousticAmps',
                href: '/c/amps/acoustic-amps',
              },
              {
                textKey: 'vintageAmps',
                href: '/marketplace?product_type=amps&year_max=1980',
              },
            ],
          },
        ],
        [
          {
            headingKey: 'popularProducts',
            children: [
              {
                textKey: 'ehxBigmuff',
                href: '/f/electro-harmonix/big-muff',
              },
              {
                textKey: 'ibanezTubescreamer',
                href: '/f/ibanez/tube-screamer',
              },
              {
                textKey: 'jhsMorningGlory',
                href: '/f/jhs/morning-glory',
              },
              {
                textKey: 'dittoLooper',
                href: '/f/tc-electronic/ditto-looper',
              },
            ],
          },
        ],
      ],
      curated: {
        imageHref: 'https://rvb-img.reverb.com/image/upload/v1590188040/2-Amps-Player-Grade-Vintage-Amps_dzdgvw.jpg',
        children: [
          {
            textKey: 'pedalMovie',
            href: '/page/the-pedal-movie',
          },
          {
            textKey: 'dealsAndSteals',
            href: dealsAndStealsPath,
          },
          {
            textKey: 'priceDrops',
            href: '/promo/price-drops',
          },
          {
            textKey: 'playerGradeVintage',
            href: '/handpicked/vintage-workhorse-amps',
          },
          {
            textKey: 'tubeScreamers',
            href: '/collection/the-hall-of-tube-screamers',
          },
          {
            textKey: 'selling',
            href: '/selling',
          },
        ],
      },
    },
    {
      key: 'synths',
      i18nPrefix: 'discovery.categoryNavBar.synths',
      columns: [
        [
          {
            headingKey: 'keyboards',
            viewAllHref: '/c/keyboards-and-synths/',
            children: [
              {
                textKey: 'controllers',
                href: '/c/keyboards-and-synths/midi-controllers',
              },
              {
                textKey: 'digitalPianos',
                href: '/c/keyboards-and-synths/digital-pianos',
              },
              {
                textKey: 'workstations',
                href: '/c/keyboards-and-synths/workstation-keyboards',
              },
              {
                textKey: 'acousticPianos',
                href: '/c/keyboards-and-synths/acoustic-pianos',
              },
              {
                textKey: 'organs',
                href: '/c/keyboards-and-synths/organs',
              },
              {
                textKey: 'electricPianos',
                href: '/c/keyboards-and-synths/electric-pianos',
              },
            ],
          },
        ],
        [
          {
            headingKey: 'synths',
            viewAllHref: '/c/keyboards-and-synths/',
            children: [
              {
                textKey: 'analog',
                href: '/c/keyboards-and-synths/analog-synths',
              },
              {
                textKey: 'digital',
                href: '/c/keyboards-and-synths/digital-synths',
              },
              {
                textKey: 'eurorack',
                href: '/c/keyboards-and-synths/eurorack',
              },
              {
                textKey: 'modular',
                href: '/c/keyboards-and-synths/modular-synths',
              },
              {
                textKey: 'rackmount',
                href: '/c/keyboards-and-synths/rackmount-synths',
              },
              {
                textKey: 'vintageSynths',
                href: '/marketplace?category=synths&product_type=keyboards-and-synths&year_max=1989',
              },
              {
                textKey: 'miniSynths',
                href: '/collection/mini-synths-1',
              },
              {
                textKey: 'sequencers',
                href: '/c/keyboards-and-synths/sequencers',
              },
              {
                textKey: 'desktopSynths',
                href: '/c/keyboards-and-synths/desktop-synths',
              },
              {
                textKey: 'keyboardSynths',
                href: '/c/keyboards-and-synths/keyboard-synths',
              },
            ],
          },
        ],
        [
          {
            headingKey: 'beatProduction',
            children: [
              {
                textKey: 'drumMachines',
                href: '/c/keyboards-and-synths/drum-machines',
              },
              {
                textKey: 'samplers',
                href: '/c/keyboards-and-synths/samplers',
              },
              {
                textKey: 'padControllers',
                href: '/c/drums-and-percussion/pad-controllers',
              },
              {
                textKey: 'software',
                href: '/c/software/',
              },
              {
                textKey: 'grooveboxes',
                href: '/c/keyboards-and-synths/grooveboxes',
              },
            ],
          },
        ],
        [
          {
            headingKey: 'popularProducts',
            children: [
              {
                textKey: 'akaiMpc',
                href: '/f/akai/mpc',
              },
              {
                textKey: 'rolandJuno',
                href: '/f/roland/juno',
              },
            ],
          },
        ],
      ],
      curated: {
        imageHref: 'https://rvb-img.reverb.com/image/upload/v1593121734/3-Keys-Modern-Electronic-Production-Gear-v2_uotg4r.jpg',
        children: [
          {
            textKey: 'dealsAndSteals',
            href: dealsAndStealsPath,
          },
          {
            textKey: 'priceDrops',
            href: '/promo/price-drops',
          },
          {
            textKey: 'modernProductionGear',
            href: '/collection/hybrid-theory-modern-electronic-production-gear-1',
          },
          {
            textKey: 'selling',
            href: '/selling',
          },
        ],
      },
    },
    {
      key: 'recording',
      i18nPrefix: 'discovery.categoryNavBar.recording',
      columns: [
        [
          {
            headingKey: 'proAudio',
            viewAllHref: '/c/pro-audio/',
            children: [
              {
                textKey: 'microphones',
                href: '/c/pro-audio/microphones',
              },
              {
                textKey: 'interfaces',
                href: '/c/pro-audio/interfaces',
              },
              {
                textKey: '500Series',
                href: '/c/pro-audio/500-series',
              },
              {
                textKey: 'boxedSoftware',
                href: '/c/pro-audio/software',
              },
              {
                textKey: 'recordingEquipment',
                href: '/c/pro-audio/recording',
              },
              {
                textKey: 'mixers',
                href: '/c/pro-audio/mixers',
              },
              {
                textKey: 'portableRecorders',
                href: '/c/pro-audio/portable-recorders',
              },
              {
                textKey: 'diBoxes',
                href: '/c/pro-audio/di-boxes',
              },
              {
                textKey: 'studioFurniture',
                href: '/c/pro-audio/studio-furniture',
              },
              {
                textKey: 'patchbays',
                href: '/c/pro-audio/patchbays',
              },
              {
                textKey: 'software',
                href: '/c/software/',
              },
            ],
          },
        ],
        [
          {
            headingKey: 'outboardGear',
            viewAllHref: '/c/pro-audio/outboard-gear',
            children: [
              {
                textKey: 'compressorsAndLimiters',
                href: '/c/pro-audio/compressors-and-limiters',
              },
              {
                textKey: 'microphonePreamps',
                href: '/c/pro-audio/microphone-preamps',
              },
              {
                textKey: 'equalizers',
                href: '/c/pro-audio/equalizers',
              },
              {
                textKey: 'multiEffect',
                href: '/c/pro-audio/multi-effect',
              },
              {
                textKey: 'channelStrips',
                href: '/c/pro-audio/channel-strips',
              },
              {
                textKey: 'adDaConverters',
                href: '/c/pro-audio/ad-da-converters',
              },
              {
                textKey: 'gatesAndExpanders',
                href: '/c/pro-audio/gates-and-expanders',
              },
              {
                textKey: 'reverbUnits',
                href: '/c/pro-audio/reverb',
              },
              {
                textKey: 'summing',
                href: '/c/pro-audio/summing',
              },
              {
                textKey: 'delay',
                href: '/c/pro-audio/delay',
              },
              {
                textKey: 'utility',
                href: '/c/pro-audio/utility',
              },
            ],
          },
        ],
        [
          {
            headingKey: 'speakers',
            viewAllHref: '/c/pro-audio/speakers',
            children: [
              {
                textKey: 'studioMonitors',
                href: '/c/pro-audio/studio-monitors',
              },
              {
                textKey: 'poweredSpeakers',
                href: '/c/pro-audio/powered-speakers',
              },
              {
                textKey: 'passiveSpeakers',
                href: '/c/pro-audio/passive-speakers',
              },
            ],
          },
        ],
      ],
      curated: {
        imageHref: 'https://res.cloudinary.com/reverb-marketing/image/upload/v1654713841/Recording-Gear-2.jpg',
        children: [
          {
            textKey: 'dealsAndSteals',
            href: dealsAndStealsPath,
          },
          {
            textKey: 'priceDrops',
            href: '/promo/price-drops',
          },
          {
            textKey: 'studioGradeGear',
            href: '/handpicked/studio-grade-gear',
          },
          {
            textKey: 'topShelfStudioMicrophones',
            href: '/handpicked/top-shelf-studio-microphones',
          },
          {
            textKey: 'selling',
            href: '/selling',
          },
        ],
      },
    },
    {
      key: 'drums',
      i18nPrefix: 'discovery.categoryNavBar.drums',
      headingHref: '/c/drums-and-percussion/',
      columns: [
        [
          {
            headingKey: 'acousticDrums',
            viewAllHref: '/c/drums-and-percussion/acoustic-drums',
            children: [
              {
                textKey: 'fullAcousticKits',
                href: '/c/drums-and-percussion/full-acoustic-kits',
              },
              {
                textKey: 'snares',
                href: '/c/drums-and-percussion/snare',
              },
              {
                textKey: 'bassDrums',
                href: '/c/drums-and-percussion/bass-drum',
              },
              {
                textKey: 'toms',
                href: '/c/drums-and-percussion/tom',
              },
              {
                textKey: 'vintageDrums',
                href: '/handpicked/vintage-drums-classic-kits',
              },
            ],
          },
          {
            headingKey: 'electronicDrums',
            viewAllHref: '/c/drums-and-percussion/electronic-drums',
            children: [
              {
                textKey: 'fullElectronicKits',
                href: '/c/drums-and-percussion/full-electronic-kits',
              },
              {
                textKey: 'modules',
                href: '/c/drums-and-percussion/modules',
              },
            ],
          },
        ],
        [
          {
            headingKey: 'cymbals',
            viewAllHref: '/c/drums-and-percussion/cymbals',
            children: [
              {
                textKey: 'crash',
                href: '/c/drums-and-percussion/crash',
              },
              {
                textKey: 'ride',
                href: '/c/drums-and-percussion/ride',
              },
              {
                textKey: 'hiHats',
                href: '/c/drums-and-percussion/hi-hats',
              },
              {
                textKey: 'splashChinaEffects',
                href: '/c/drums-and-percussion/other-splash-china-etc',
              },
              {
                textKey: 'cymbalPacks',
                href: '/c/drums-and-percussion/cymbal-packs',
              },
              {
                textKey: 'vintageCymbals',
                href: '/handpicked/old-soul-cymbals',
              },
            ],
          },
          {
            headingKey: 'auxiliaryPercussion',
            viewAllHref: '/c/drums-and-percussion/auxiliary-percussion',
            children: [
              {
                textKey: 'handDrums',
                href: '/c/drums-and-percussion/hand-drums',
              },
            ],
          },
        ],
        [
          {
            headingKey: 'partsAndAccessories',
            viewAllHref: '/c/drums-and-percussion/parts-and-accessories',
            children: [
              {
                textKey: 'heads',
                href: '/c/drums-and-percussion/heads',
              },
              {
                textKey: 'drumSticksAndMallets',
                href: '/c/drums-and-percussion/drum-sticks-and-mallets',
              },
              {
                textKey: 'stands',
                href: '/c/drums-and-percussion/stands',
              },
              {
                textKey: 'pedals',
                href: '/c/drums-and-percussion/pedals',
              },
              {
                textKey: 'casesAndBags',
                href: '/c/drums-and-percussion/cases-and-bags',
              },
              {
                textKey: 'thrones',
                href: '/c/drums-and-percussion/thrones',
              },
              {
                textKey: 'drumParts',
                href: '/c/drums-and-percussion/drum-parts',
              },
              {
                textKey: 'mounts',
                href: '/c/drums-and-percussion/mounts',
              },
              {
                textKey: 'drumKeysAndTuners',
                href: '/c/drums-and-percussion/drum-keys-and-tuners',
              },
              {
                textKey: 'practicePads',
                href: '/c/drums-and-percussion/practice-pads',
              },
            ],
          },
        ],
        [
          {
            headingKey: 'concertPercussion',
            viewAllHref: '/c/drums-and-percussion/concert-percussion',
            children: [
              {
                textKey: 'concertCymbals',
                href: '/c/drums-and-percussion/concert-cymbals',
              },
              {
                textKey: 'gongs',
                href: '/c/drums-and-percussion/gongs',
              },
              {
                textKey: 'concertSnareDrums',
                href: '/c/drums-and-percussion/concert-snare-drums',
              },
              {
                textKey: 'timpani',
                href: '/c/drums-and-percussion/timpani',
              },
              {
                textKey: 'concertBassDrums',
                href: '/c/drums-and-percussion/concert-bass-drums',
              },
            ],
          },
          {
            headingKey: 'malletPercussion',
            viewAllHref: '/c/drums-and-percussion/mallet-percussion',
            children: [
              {
                textKey: 'bellsAndGlockenspiels',
                href: '/c/drums-and-percussion/bells-and-glockenspiels',
              },
              {
                textKey: 'xylophones',
                href: '/c/drums-and-percussion/xylophones',
              },
              {
                textKey: 'marimbas',
                href: '/c/drums-and-percussion/marimbas',
              },
              {
                textKey: 'marchingBassDrums',
                href: '/c/drums-and-percussion/marching-bass-drums',
              },
              {
                textKey: 'marchingTomsAndTenors',
                href: '/c/drums-and-percussion/marching-toms-and-tenors',
              },
              {
                textKey: 'marchingCymbals',
                href: '/c/drums-and-percussion/marching-cymbals',
              },
            ],
          },
        ],
      ],
      curated: {
        imageHref: 'https://rvb-img.reverb.com/image/upload/v1590188040/5-Drums-Player-Grade-Vintage-Drums_oswbgc.jpg',
        children: [
          {
            textKey: 'dealsAndSteals',
            href: dealsAndStealsPath,
          },
          {
            textKey: 'priceDrops',
            href: '/promo/price-drops',
          },
          {
            textKey: 'playerGradeVintage',
            href: '/handpicked/player-grade-vintage-drums',
          },
          {
            textKey: 'selling',
            href: '/selling',
          },
        ],
      },
    },
    {
      key: 'dj',
      i18nPrefix: 'discovery.categoryNavBar.dj',
      columns: [
        [
          {
            headingKey: 'beatProduction',
            children: [
              {
                textKey: 'drumMachines',
                href: '/c/keyboards-and-synths/drum-machines',
              },
              {
                textKey: 'samplers',
                href: '/c/keyboards-and-synths/samplers',
              },
              {
                textKey: 'padControllers',
                href: '/c/drums-and-percussion/pad-controllers',
              },
            ],
          },
        ],
        [
          {
            headingKey: 'djEquipment',
            viewAllHref: '/c/dj-and-lighting-gear/',
            children: [
              {
                textKey: 'lighting',
                href: '/c/dj-and-lighting-gear/lighting',
              },
              {
                textKey: 'djControllers',
                href: '/c/dj-and-lighting-gear/dj-controllers',
              },
              {
                textKey: 'mixers',
                href: '/c/dj-and-lighting-gear/mixers',
              },
              {
                textKey: 'turntables',
                href: '/c/dj-and-lighting-gear/turntables',
              },
              {
                textKey: 'allInOneDJSystems',
                href: '/c/dj-and-lighting-gear/all-in-one-dj-systems',
              },
              {
                textKey: 'portablePA',
                href: '/c/pro-audio/portable-pa-systems',
              },
            ],
          },
        ],
        [
          {
            headingKey: 'homeAudio',
            viewAllHref: '/c/home-audio/',
            children: [
              {
                textKey: 'speakers',
                href: '/c/home-audio/speakers',
              },
              {
                textKey: 'wirelessSpeakers',
                href: '/c/home-audio/wireless-speakers',
              },
              {
                textKey: 'headphones',
                href: '/c/home-audio/headphones',
              },
              {
                textKey: 'noiseCancelingHeadphones',
                href: '/c/home-audio/noise-canceling-headphones',
              },
              {
                textKey: 'amplifiers',
                href: '/c/home-audio/amplifiers',
              },
              {
                textKey: 'preamps',
                href: '/c/home-audio/preamps',
              },
              {
                textKey: 'turntables',
                href: '/c/home-audio/turntables',
              },
            ],
          },
        ],
      ],
      curated: {
        imageHref: 'https://rvb-img.reverb.com/image/upload/v1593121730/6-DJ-Modern-Production-Gear-v2_ccsz5e.jpg',
        children: [
          {
            textKey: 'dealsAndSteals',
            href: dealsAndStealsPath,
          },
          {
            textKey: 'priceDrops',
            href: '/promo/price-drops',
          },
          {
            textKey: 'modernProduction',
            href: '/collection/hybrid-theory-modern-electronic-production-gear-1',
          },
          {
            textKey: 'selling',
            href: '/selling',
          },
        ],
      },
    },
    {
      key: 'more',
      i18nPrefix: 'discovery.categoryNavBar.moreCategories',
      columns: [
        [
          {
            headingKey: 'bandAndOrchestra',
            viewAllHref: '/c/band-and-orchestra/',
            children: [
              {
                textKey: 'woodwind',
                href: '/c/band-and-orchestra/woodwind',
              },
              {
                textKey: 'saxophones',
                href: '/c/band-and-orchestra/saxophones',
              },
              {
                textKey: 'flutes',
                href: '/c/band-and-orchestra/flutes',
              },
              {
                textKey: 'brass',
                href: '/c/band-and-orchestra/brass',
              },
              {
                textKey: 'trumpets',
                href: '/c/band-and-orchestra/trumpets',
              },
              {
                textKey: 'trombones',
                href: '/c/band-and-orchestra/trombones',
              },
              {
                textKey: 'string',
                href: '/c/band-and-orchestra/string',
              },
              {
                textKey: 'violins',
                href: '/c/band-and-orchestra/violins',
              },
              {
                textKey: 'uprightBass',
                href: '/c/band-and-orchestra/upright-bass',
              },
            ],
          },
        ],
        [
          {
            headingKey: 'folkInstruments',
            viewAllHref: '/c/folk-instruments/',
            children: [
              {
                textKey: 'ukuleles',
                href: '/c/folk-instruments/ukuleles',
              },
              {
                textKey: 'harmonicas',
                href: '/c/folk-instruments/harmonicas',
              },
              {
                textKey: 'banjos',
                href: '/c/folk-instruments/banjos',
              },
              {
                textKey: 'mandolins',
                href: '/c/folk-instruments/mandolins',
              },
              {
                textKey: 'accordions',
                href: '/c/folk-instruments/accordions',
              },
              {
                textKey: 'sitars',
                href: '/c/folk-instruments/sitars',
              },
              {
                textKey: 'ouds',
                href: '/c/folk-instruments/ouds',
              },
            ],
          },
        ],
        [
          {
            headingKey: 'software',
            viewAllHref: '/c/software/',
            children: [
              {
                textKey: 'daws',
                href: '/c/software/digital-audio-workstations',
              },
              {
                textKey: 'samplesAndLoops',
                href: '/c/software/samples-and-loops',
              },
              {
                textKey: 'reverbDrumMachines',
                href: '/software/samples-and-loops/reverb/3514-reverb-drum-machines-the-complete-collection',
              },
              {
                textKey: 'virtualInstruments',
                href: '/c/software/instruments',
              },
            ],
          },
        ],
      ],
      curated: {
        imageHref: 'https://rvb-img.reverb.com/image/upload/v1590188267/7-Folk-Artisanal_evz5ua.jpg',
        children: [
          {
            textKey: 'dealsAndSteals',
            href: dealsAndStealsPath,
          },
          {
            textKey: 'priceDrops',
            href: '/promo/price-drops',
          },
          {
            textKey: 'artisanalAcoustics',
            href: '/handpicked/artisanal-acoustics',
          },
          {
            textKey: 'hifiHeyday',
            href: '/handpicked/hi-fi-heyday-vintage-turntables-receivers-and-stereo-speakers',
          },
          {
            textKey: 'selling',
            href: '/selling',
          },
        ],
      },
    },
  ];
}
