/**
 * Payment Service Provider selected for the shop
 */
export enum PaymentProvider {
  ReverbPayments = 'reverb_payments',
  PayPal = 'paypal',
}

/**
 * Reverb Payments destinations
 *
 * ACH applies to any non-Reverb Bank shops on Reverb Payments
 */
export enum ReverbPaymentsPayoutMethod {
  ACH = 'ach',
  ReverbBank = 'reverb',
}
