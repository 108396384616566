import React from 'react';
import I18n from 'i18n-js';
import { isEmpty } from 'lodash';
import {
  core_apimessages_Country as ICountry,
  core_apimessages_Address as Address,
  core_apimessages_Error as IError,
} from '@reverbdotcom/commons/src/gql/graphql';
import AddressRow from './address_row';
import ErrorDisplay from '../../discovery/error_display';
import { RCButton } from '@reverbdotcom/cadence/components';
import {
  IAddressFormData,
  buildBlankAddressFormData,
  buildAddressFormDataFromAddress,
} from '../address_form_data';
import AddressForm from '../address_form';
import withAddressBookApollo, {
  IAddressResponse,
} from '../with_address_book_apollo';

import ModalDialog from '@reverbdotcom/commons/src/components/modal_dialog';
import bind from '@reverbdotcom/commons/src/bind';

import { withUserContext, IUser } from '@reverbdotcom/commons/src/components/user_context_provider';
import { AddressBookClickInteraction } from '@reverbdotcom/commons/src/elog/mparticle_types';
import AddressSummary from '../../AddressSummary';
import { connect } from '@reverbdotcom/commons/src/connect';

const SELECTED_ADDRESS_VIEW = 'selectedAddressView';
const ADDRESS_INDEX = 'addressIndex';
const ADDRESS_FORM = 'addressForm';
type UiState = 'selectedAddressView' | 'addressIndex' | 'addressForm';

const ADDRESS_INPUT_NAME = 'checkout[selected_shipping_address_id]';

interface ExternalProps {
  selectedAddress: Address;
  autoOpen?: boolean;
  hideTitle?: boolean;
  onAddressSaved?: (addressUuid: string) => void;
  onOpen?: () => void;
  trackEvent?: (interaction: AddressBookClickInteraction) => void;
}

interface AddressBookApolloProps {
  loading: boolean;
  error: boolean;
  addresses: Address[];
  countries: ICountry[];
  submitErrorMessage?: string;
  onCreate(address: Address): Promise<IAddressResponse>;
  onMakePrimary(address: Address): Promise<any>;
  onUpdate(address: Address): Promise<IAddressResponse>;
  user: IUser;
}

export type Props = ExternalProps & AddressBookApolloProps;

interface State {
  selectedAddress: Address;
  newSelectedShippingAddressUuid: string;
  makeAddressPrimary: boolean;
  addressFormData: IAddressFormData;
  saving: boolean;
  shownAddressCount: number;
  addressErrors: IError[];
  uiState: UiState;
}

const PAGE_SIZE = 5;

export class ShippingAddressSelector extends React.Component<Props, State> {
  static defaultProps = {
    onAddressSaved: () => { },
    trackEvent: () => { },
    onOpen: () => { },
    autoOpen: false,
    hideTitle: false,
  };

  constructor(props) {
    super(props);

    this.state = {
      selectedAddress: props.selectedAddress,
      newSelectedShippingAddressUuid: props.selectedShippingAddressUuid,
      addressFormData: buildBlankAddressFormData(this.props.user),
      saving: false,
      shownAddressCount: PAGE_SIZE,
      addressErrors: [],
      makeAddressPrimary: false,
      uiState: props.autoOpen ? ADDRESS_INDEX : SELECTED_ADDRESS_VIEW,
    };
  }

  addressFormRef = null;

  componentDidMount() {
    // The initial address could have errors, we want to open the modal if that's the case
    this.onLoaded();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.loading && !this.props.loading) {
      this.onLoaded();
    }
  }

  onLoaded() {
    // Once we've loaded the addresses, if checkout redirected back with a submitErrorMessage (eg phone is missing),
    // open the edit form to that address with the error message.
    const { submitErrorMessage, addresses = [] } = this.props;
    if (submitErrorMessage && addresses.length) {
      this.setState({
        addressErrors: [{ message: submitErrorMessage }],
        uiState: ADDRESS_FORM,
        addressFormData: buildAddressFormDataFromAddress(
          this.selectedAddress,
        ),
      });
    } else if (this.hasSelectedAddressChanged()) {
      this.setState({
        selectedAddress: this.props.selectedAddress,
      });
    }
  }

  hasSelectedAddressChanged() {
    return this.selectedAddress?.uuid && this.props.selectedAddress?.uuid !== this.selectedAddress.uuid;
  }

  get isEditingExistingAddress() {
    return (
      this.state.uiState === ADDRESS_FORM &&
      this.state.addressFormData.uuid.length > 0 &&
      !!this.addressCurrentlyEditing
    );
    /* all these checks are to ensure that we account for any mismatch between
      the state of this component and props passed from Apollo (Apollo cache versus server response)
      eg. deleting an address could get us into a state where we have a nil id for the address
      but we are still trying to render the form */
  }

  get editedAddressIsCurrentPrimary() {
    return (
      this.isEditingExistingAddress && this.addressCurrentlyEditing.primary
    );
  }

  get addressCurrentlyEditing() {
    return this.props.addresses.find(
      address => address.uuid === this.state.addressFormData.uuid,
    );
  }

  get selectedAddress() {
    if (isEmpty(this.state.selectedAddress)) {
      return this.props.addresses[0];
    }

    return this.state.selectedAddress;
  }

  get newSelectedShippingAddress() {
    return this.props.addresses.find(
      address => address.uuid === this.state.newSelectedShippingAddressUuid,
    ) || this.props.addresses[0];
  }

  @bind
  handleAddNewAddress() {
    this.props.trackEvent(AddressBookClickInteraction.AddAddress);
    this.setState({
      addressFormData: buildBlankAddressFormData(this.props.user),
      uiState: ADDRESS_FORM,
    });
  }

  @bind
  closeModal() {
    this.props.trackEvent(AddressBookClickInteraction.CloseModal);
    this.setState({
      addressErrors: [],
      uiState: SELECTED_ADDRESS_VIEW,
    });
  }

  @bind
  openModal() {
    this.props.onOpen();
    this.props.trackEvent(AddressBookClickInteraction.OpenModal);
    this.setState({
      uiState: ADDRESS_INDEX,
      newSelectedShippingAddressUuid: this.selectedAddress.uuid,
      makeAddressPrimary: false,
    });
  }

  @bind
  handleUpdateField(addressAttributes: Partial<IAddressFormData>) {
    this.setState(prevState => ({
      addressFormData: {
        ...prevState.addressFormData,
        ...addressAttributes,
      },
    }));
  }

  @bind
  handleUpdatePrimary() {
    this.setState(prevState => ({
      makeAddressPrimary: !prevState.makeAddressPrimary,
      addressFormData: {
        ...prevState.addressFormData,
        primary: !prevState.makeAddressPrimary,
      },
    }));
  }

  @bind
  handleAddressEdit(address: Address) {
    const addressFormData = buildAddressFormDataFromAddress(address);
    if (!address.primary && this.state.makeAddressPrimary) {
      addressFormData.primary = true;
    }

    this.setState({
      addressFormData,
      uiState: ADDRESS_FORM,
    });
  }

  @bind
  handleSubmit() {
    if (this.props.loading || this.state.saving) {
      return;
    }

    const form = this.addressFormRef as HTMLFormElement;
    if (form && form.reportValidity && !form.reportValidity()) {
      return;
    }

    if (this.state.uiState === ADDRESS_FORM) {
      this.createOrUpdateAddress();
    }

    this.useSelectedAddress();
  }

  @bind
  handleShowMore(e) {
    e.preventDefault();
    this.setState(prevState => ({
      shownAddressCount: prevState.shownAddressCount + PAGE_SIZE,
    }));
  }

  useSelectedAddress() {
    if (this.state.makeAddressPrimary) {
      this.setState({ saving: true });

      this.props.onMakePrimary(this.newSelectedShippingAddress);
      this.saveSuccess(this.newSelectedShippingAddress);
    } else {
      this.saveSuccess(this.newSelectedShippingAddress);
    }
  }

  async createOrUpdateAddress() {
    if (this.isEditingExistingAddress) {
      const { address, errors } = await this.props.onUpdate(
        this.state.addressFormData,
      );
      this.handleResponse({ address, errors });
    } else {
      const { address, errors } = await this.props.onCreate(
        this.state.addressFormData,
      );
      this.handleResponse({ address, errors });
    }
  }

  handleResponse({ address, errors }: IAddressResponse) {
    if (address) {
      this.saveSuccess(address);
    } else {
      this.setState({
        addressErrors: errors,
        saving: false,
      });
    }
  }

  @bind
  saveSuccess(address: Address) {
    if (this.isEditingExistingAddress) {
      this.setState(prevState => ({ shownAddressCount: prevState.shownAddressCount + 1 }));
    }
    this.props.trackEvent(AddressBookClickInteraction.SaveAddress);
    this.props.onAddressSaved(address.uuid);
    this.setState({
      saving: false,
      uiState: SELECTED_ADDRESS_VIEW,
      selectedAddress: address,
      addressErrors: [],
    });
  }

  formHeaderTitle() {
    if (this.isEditingExistingAddress) {
      return I18n.t('discovery.addressBook.form.title.edit');
    }
    return I18n.t('discovery.addressBook.form.title.create');
  }

  @bind
  handleChangeSelectedAddress(selectedAddressUuid) {
    this.props.trackEvent(AddressBookClickInteraction.SelectAddress);
    this.setState({
      makeAddressPrimary: false,
      newSelectedShippingAddressUuid: selectedAddressUuid,
    });
  }

  @bind
  toggleMakePrimary() {
    this.props.trackEvent(AddressBookClickInteraction.SelectDefaultAddress);
    this.setState(prevState => ({ makeAddressPrimary: !prevState.makeAddressPrimary }));
  }

  @bind
  setAddressFormRef(ref) {
    this.addressFormRef = ref;
  }

  get modalProps() {
    if (this.state.uiState === ADDRESS_INDEX) {
      return {
        headerTitle: I18n.t('discovery.checkout.addressBook.listView.header'),
        saveButtonText: I18n.t(
          'discovery.checkout.addressBook.listView.submit',
        ),
        onNavigateBack: null,
      };
    }
    if (this.isEditingExistingAddress) {
      return {
        headerTitle: I18n.t('discovery.checkout.addressBook.editView.header'),
        saveButtonText: I18n.t(
          'discovery.checkout.addressBook.editView.submit',
        ),
        onNavigateBack: this.handleModalBack,
      };
    }
    // create address
    return {
      headerTitle: I18n.t('discovery.checkout.addressBook.createView.header'),
      saveButtonText: I18n.t(
        'discovery.checkout.addressBook.createView.submit',
      ),
      onNavigateBack: this.handleModalBack,
    };
  }

  get shownAddresses(): Address[] {
    const otherAddresses = this.props.addresses.filter(
      a => a.uuid !== this.selectedAddress.uuid,
    );
    return [this.selectedAddress].concat(
      otherAddresses.slice(0, this.state.shownAddressCount - 1),
    );
  }

  renderAddresses() {
    const addressList = this.shownAddresses.map((address) => {
      const selected = address.uuid === this.newSelectedShippingAddress.uuid;
      return (
        <AddressRow
          key={address.uuid}
          isSelected={selected}
          address={address}
          onSelect={this.handleChangeSelectedAddress}
          onMakePrimary={this.toggleMakePrimary}
          makeAddressPrimary={this.state.makeAddressPrimary}
          onEdit={this.handleAddressEdit}
          trackEvent={this.props.trackEvent}
        />
      );
    });

    return addressList;
  }

  renderAddressList() {
    if (this.props.loading) {
      return this.renderPlaceholderContent();
    }

    return (
      <div>
        <ul className="actionable-rows">
          {this.renderAddresses()}
          {this.renderPagination()}
        </ul>

        <div className="mt-4">
          <button
            type="button"
            data-add-new-address onClick={this.handleAddNewAddress}
            className="button"
          >
            {I18n.t('discovery.addressBook.addNewBtn')}
          </button>
        </div>
      </div>
    );
  }

  renderForm() {
    return (
      <AddressForm
        addressFormData={this.state.addressFormData}
        countries={this.props.countries}
        onUpdateField={this.handleUpdateField}
        addressIsCurrentPrimary={this.editedAddressIsCurrentPrimary}
        errors={this.state.addressErrors}
        formRef={this.setAddressFormRef}
      />
    );
  }

  renderPagination() {
    if (this.state.shownAddressCount >= this.props.addresses.length) {
      return null;
    }

    return (
      <li className="actionable-row">
        <div className="actionable-row__content">
          <button
            type="button"
            data-load-more
            className="button-as-link"
            onClick={this.handleShowMore}
          >
            {I18n.t('discovery.addressBook.loadMore')}
          </button>
        </div>
      </li>
    );
  }

  @bind
  handleModalBack() {
    this.props.trackEvent(AddressBookClickInteraction.CancelEditAddress);
    this.setState({
      uiState: ADDRESS_INDEX,
      addressErrors: [],
    });
  }

  renderPlaceholderRow() {
    return (
      <li className="actionable-row actionable-row--placeholder">
        <div className="actionable-row__content" />
        <ul className="actionable-row__actions">
          <li className="actionable-row__action" />
        </ul>
      </li>
    );
  }

  renderPlaceholderContent() {
    return (
      <div>
        <ul className="actionable-rows">
          {this.renderPlaceholderRow()}
          {this.renderPlaceholderRow()}
          {this.renderPlaceholderRow()}
        </ul>
      </div>
    );
  }

  renderHiddenInput() {
    if (this.props.loading) {
      return null;
    }

    return (
      <input
        type="hidden"
        name={ADDRESS_INPUT_NAME}
        value={this.selectedAddress.legacyId}
      />
    );
  }

  renderTitleAndAddress() {
    if (this.props.hideTitle) {
      return (
        <>
          <div className="d-flex fx-dir-row mb-2 fx-align-start fx-justify-between">
            <div className="fx-grow">
              <AddressSummary address={this.selectedAddress} />
            </div>
            <RCButton
              variant="muted"
              onClick={this.openModal}
            >
              {I18n.t('discovery.checkout.addressBook.row.actions.change')}
            </RCButton>
          </div>
        </>
      );
    } else {
      return (
        <>
          <div className="d-flex fx-justify-between mb-2">
            <h3 className="size-120 weight-bold">
              {I18n.t('discovery.checkout.shippingAddress')}
            </h3>
            <button
              className="button-as-link icon-l-pencil"
              onClick={this.openModal}
              type="button"
            >
              {I18n.t('discovery.checkout.addressBook.row.actions.change')}
            </button>
          </div>
          <AddressSummary address={this.selectedAddress} />
        </>
      );
    }
  }

  render() {
    if (this.props.error) {
      return <ErrorDisplay />;
    }

    return (
      <div>
        {this.renderTitleAndAddress()}
        {this.renderHiddenInput()}

        <ModalDialog
          {...this.modalProps}
          onRequestClose={this.closeModal}
          isOpen={this.state.uiState !== SELECTED_ADDRESS_VIEW}
          onSubmit={this.handleSubmit}
          isSaving={this.state.saving}
          backButtonText={I18n.t('discovery.checkout.addressBook.cancel')}
          containsFormElement
        >
          <div className="shipping-address-selector">
            {this.state.uiState === ADDRESS_FORM && this.renderForm()}
            {this.state.uiState === ADDRESS_INDEX && this.renderAddressList()}
          </div>
        </ModalDialog>
      </div>
    );
  }
}

export const ShippingAddressSelectorWithRQL = connect<ExternalProps>([
  withUserContext,
  withAddressBookApollo,
])(ShippingAddressSelector);
