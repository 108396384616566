import { ChildProps } from '@apollo/client/react/hoc';
// TODO update this to import from commons
// import { gql } from '@reverbdotcom/commons/src/gql';
// eslint-disable-next-line no-restricted-imports
import { gql } from '@apollo/client';
import React, { useMemo } from 'react';
import PayStatementForm from './pay_statement_form';
import { StatementProps } from './statement_props';
import { withGraphql } from '@reverbdotcom/commons/src/with_graphql';
import {
  CreditCardListQuery,
} from '@reverbdotcom/commons/src/gql/graphql';

interface ExternalProps extends StatementProps {
  closeModal(): void;
  newCardId: string;
  setPaymentSuccessful: (val: boolean) => void;
  toggleDisplayNewCardForm(): void;
}

type Props = ExternalProps & ChildProps<{}, CreditCardListQuery.Query>;

export const PayStatementCreditCardListFragment = gql`
  fragment PayStatementCreditCardListFields on rql_Me {
    _id
    creditCards {
      id
      cardType
      needsReverification
      primaryBilling
      display {
        summary
        expirationDate
      }
      brand {
        type
        name
        __typename
      }
    }
  }
`;

export const CREDIT_CARD_LIST_QUERY = gql`
query CreditCardListQuery {
  me {
    _id
    uuid
    ...PayStatementCreditCardListFields
  }
}
${PayStatementCreditCardListFragment}
`;

export const connectCreditCardListQuery = withGraphql<ExternalProps, CreditCardListQuery.Query>(
  CREDIT_CARD_LIST_QUERY,
  {
    options: {
      // The parent component is rendered by a Rails/HAML view, is not routed, and does not use SSR:
      // https://github.com/reverbdotcom/reverb/blob/master/app/views/dashboard/selling/statements/index.haml
      ssr: false,
    },
  },
);


export function PayStatementContainer({
  balanceCents,
  closeModal,
  currencyCode,
  data,
  newCardId,
  setPaymentSuccessful,
  statementId,
  totalOwedCents,
  totalPaidCents,
  toggleDisplayNewCardForm,
}: Props) {
  const creditCards = data?.me?.creditCards || [];

  const primaryBillingCardId = useMemo(() => {
    return creditCards.find(card => card.primaryBilling)?.id;
  }, [ data ]);

  return (
    <PayStatementForm
      areCardsLoading={data?.loading}
      balanceCents={balanceCents}
      closeModal={closeModal}
      creditCards={creditCards}
      currencyCode={currencyCode}
      newCardId={newCardId}
      primaryBillingCardId={primaryBillingCardId}
      setPaymentSuccessful={setPaymentSuccessful}
      statementId={statementId}
      totalOwedCents={totalOwedCents}
      totalPaidCents={totalPaidCents}
      toggleDisplayNewCardForm={toggleDisplayNewCardForm}
    />
  );
}

export default connectCreditCardListQuery(PayStatementContainer);
