import React from 'react';
import bind from '@reverbdotcom/commons/src/bind';
import CreateOfferModal from '@reverbdotcom/commons/src/offers/create_offer_modal';
import { IPhotoAttrs } from '../photos/photo';
import {
  core_apimessages_CreateNegotiationRequest,
} from '@reverbdotcom/commons/src/gql/graphql';
import { ControlledBodyInput } from './body_input';
import I18n from 'i18n-js';

export interface IProps {
  recipientUuid: string;
  listingId?: string;
  isSeller: boolean;
  canIncludeOffer: boolean;
  photoUploadOptions?: Record<string, any>;
  hideListingSearch?: boolean;
}

export interface IInput {
  message: string;
  price?: string;
  shippingPrice?: string;
  quantity?: number;
}

export interface IState {
  isOfferModalOpen: boolean;
  message: string;
  photos: IPhotoAttrs[];
}

// Wraps the BodyInput and CreateOfferButton components, and syncs the state between them,
// so if you start typing a message and then decide to include an offer, your message
// (and photos) persists to the offer modal.
export default class MessageForm extends React.Component<IProps, IState> {
  state: IState = {
    isOfferModalOpen: false,
    message: '',
    photos: [],
  };

  @bind handleMessageChange(message) {
    this.setState({
      message: message,
    });
  }

  @bind handlePhotosChange(photos) {
    this.setState({ photos });
  }

  @bind
  toggleOfferModal() {
    this.setState({
      isOfferModalOpen: !this.state.isOfferModalOpen,
    });
  }

  @bind
  handleCloseOfferModal() {
    this.setState({ isOfferModalOpen: false });
  }

  @bind
  handleSuccess() {
    this.setState({ isOfferModalOpen: false });
    window.location.reload();
  }

  get additionalMutationParams(): Partial<core_apimessages_CreateNegotiationRequest> {
    return {
      conversation: {
        listingId: String(this.props.listingId),
        photos: this.photoData,
      },
    };
  }

  get photoData() {
    return this.state.photos.map(photo => ({
      publicId: photo.publicId,
      version: photo.version && String(photo.version),
      width: photo.width,
      height: photo.height,
      format: photo.format,
      resourceType: photo.resourceType,
      originalFilename: photo.originalFilename,
      transformation: photo.transformation,
    }));
  }

  get sharedBodyInputProps() {
    const props = {
      onChange: this.handleMessageChange,
      photos: this.state.photos,
      onPhotosChange: this.handlePhotosChange,
      productId: this.props.listingId,
      isSeller: this.props.isSeller,
      photoUploadOptions: this.props.photoUploadOptions,
      hideListingSearch: this.props.hideListingSearch,
    };

    return {
      ...props,
      message: this.state.message,
    };
  }

  renderOfferMessageInput() {
    return (
      <ControlledBodyInput
        {...this.sharedBodyInputProps}
        isRequired={false}
        inputNamespace=""
        data-offer-body-input
      />
    );
  }

  renderIncludeOffer() {
    if (!this.props.canIncludeOffer) { return null; }

    return (
      <div>
        <button
          className="button button--primary width-100"
          onClick={this.toggleOfferModal}
          type="button"
          data-include-offer-button
        >
          {I18n.t('discovery.messages.messageForm.includeOffer')}
        </button>

        <CreateOfferModal
          listingId={this.props.listingId}
          isSeller={this.props.isSeller}
          onCancel={this.handleCloseOfferModal}
          isDisplayed={this.state.isOfferModalOpen}
          additionalMutationParams={this.additionalMutationParams}
          headerTitle={I18n.t('discovery.messages.messageForm.offerModalHeader')}
          recipientUuid={this.props.recipientUuid}
          messageInput={this.renderOfferMessageInput()}
          message={this.state.message}
          onSuccess={this.handleSuccess}
          bumpKey={null} // null here because we are already tracking the send message interaction
        />
      </div>
    );
  }

  render() {
    return (
      <div className="g-container">
        <div className="g-col-8 g-col-mobile-12">
          <ControlledBodyInput
            {...this.sharedBodyInputProps}
            isRequired
            inputNamespace="message"
            data-message-body-input
          />
        </div>
        <div className="g-col-4 g-col-mobile-12 mb-2 mobile-mt-2">
          {this.renderIncludeOffer()}
        </div>
      </div>
    );
  }
}
