import { MutationFunction } from '@reverbdotcom/commons/src/useHOCMutation';
// TODO update this to import from commons
// import { gql } from '@reverbdotcom/commons/src/gql';
// eslint-disable-next-line no-restricted-imports
import { gql } from '@apollo/client';
import { withGraphql } from '@reverbdotcom/commons/src/with_graphql';
import {
  CoreShopSettingsCreateTaxIdentification,
  CoreShopSettingsUpdateTaxIdentification,
  CoreShopSettingsDeactivateTaxIdentification,
} from '@reverbdotcom/commons/src/gql/graphql';

export interface CreateMutationProps {
  createTaxIdentification: MutationFunction<
    CoreShopSettingsCreateTaxIdentification.Mutation,
    CoreShopSettingsCreateTaxIdentification.Variables
  >;
}

export interface MutationsProps {
  updateTaxIdentification: MutationFunction<
    CoreShopSettingsUpdateTaxIdentification.Mutation,
    CoreShopSettingsUpdateTaxIdentification.Variables
  >;
  deactivateTaxIdentification: MutationFunction<
    CoreShopSettingsDeactivateTaxIdentification.Mutation,
    CoreShopSettingsDeactivateTaxIdentification.Variables
  >;
}

const CREATE_TAX_IDENTIFICATION_MUTATION = gql`
  mutation Core_ShopSettings_CreateTaxIdentification(
    $input: Input_core_apimessages_CreateTaxIdentificationRequest
  ) {
    createTaxIdentification(input: $input) {
      shop {
        _id
        taxIdentifications {
          id
          taxId
          taxType
          status
          countryCode
        }
      }
    }
  },
`;

const UPDATE_TAX_IDENTIFICATION_MUTATION = gql`
  mutation Core_ShopSettings_UpdateTaxIdentification(
    $input: Input_core_apimessages_UpdateTaxIdentificationRequest
  ) {
    updateTaxIdentification(input: $input) {
      shop {
        _id
        taxIdentifications {
          id
          taxId
          status
          countryCode
        }
      }
    }
  }
`;

const DEACTIVATE_TAX_IDENTIFICATION_MUTATION = gql`
  mutation Core_ShopSettings_DeactivateTaxIdentification(
    $input: Input_core_apimessages_DeactivateTaxIdentificationRequest
  ) {
    deactivateTaxIdentification(input: $input) {
      shop {
        _id
        taxIdentifications {
          id
          taxId
          status
          countryCode
          active
        }
      }
    }
  }
`;

export const withUpdateTaxIdentification = withGraphql<
  {},
  CoreShopSettingsUpdateTaxIdentification.Mutation,
  CoreShopSettingsUpdateTaxIdentification.Variables
>(
  UPDATE_TAX_IDENTIFICATION_MUTATION,
  { name: 'updateTaxIdentification' },
);

export const withDeactivateTaxIdentification = withGraphql<
  {},
  CoreShopSettingsDeactivateTaxIdentification.Mutation,
  CoreShopSettingsDeactivateTaxIdentification.Variables
>(
  DEACTIVATE_TAX_IDENTIFICATION_MUTATION,
  { name: 'deactivateTaxIdentification' },
);

export const withCreateTaxIdentification = withGraphql<
  {},
  CoreShopSettingsCreateTaxIdentification.Mutation,
  CoreShopSettingsCreateTaxIdentification.Variables
>(
  CREATE_TAX_IDENTIFICATION_MUTATION,
  { name: 'createTaxIdentification' },
);
