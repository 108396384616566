/* eslint-disable new-cap */
import React, { useContext, useState } from 'react';
import I18n from 'i18n-js';
import { RCButton, RCRadioGroup } from '@reverbdotcom/cadence/components';
import {
  core_apimessages_ListingStateUpdate,
} from '@reverbdotcom/commons/src/gql/graphql';
import { MParticleEventName, trackEvent } from '@reverbdotcom/commons/src/elog/mparticle_tracker';
import SellerListingCardContext from '../SellerListingCardContext';
import ModalDialog from '@reverbdotcom/commons/src/components/modal_dialog';
import { ActionType } from '../sellerListingCardReducer';
import { useUser } from '@reverbdotcom/commons/src/user_hooks';
import { parseAmountCents } from '@reverbdotcom/commons/src/money';
import SellerListingsCollectionContext from '../SellerListingsCollectionContext';
import { SellerListing } from '../sellerListingsCollectionReducer';
import { useUpdateListingStateMutation } from './useUpdateListingStateMutation';

const DEFAULT_SELL_FEE_PERCENT = 0.05;
const ADMIN_SELL_FEE_PERCENT = 0;
const NO_LONGER_AVAILABLE = 'no_longer_available';
const SOLD_VIA_MESSAGES = 'sold_via_messages';

export const COMPONENT_NAME = 'EndButton';

interface IExternalProps {
  listing: SellerListing;
}

function EndButton({ listing }: IExternalProps) {
  const { dispatch: cardDispatch } = useContext(
    SellerListingCardContext,
  );

  const { useLazySellerListingsQuery } = useContext(SellerListingsCollectionContext);
  const [updateListingState, { data, loading, errors }] = useUpdateListingStateMutation();
  const { refetchSellerListings } = useLazySellerListingsQuery();

  const [isModalOpen, setModalOpen] = useState(false);
  const [endListingReason, setEndListingReason] = useState(NO_LONGER_AVAILABLE);

  const user = useUser();
  const sellFeePercent = user.isAdmin ? ADMIN_SELL_FEE_PERCENT : DEFAULT_SELL_FEE_PERCENT;
  const feePercent = sellFeePercent * 100;
  const { sellerPrice } = listing;
  const feeAmountDisplay = parseAmountCents(sellFeePercent * sellerPrice.amountCents, sellerPrice.currency).display;

  React.useEffect(() => {
    if (loading) return;
    if (errors.length) return handleErrors();
    if (data) handleSuccess();
  }, [loading]);

  function handleErrors() {
    setModalOpen(false);

    cardDispatch({
      type: ActionType.SHOW_TOAST,
      payload: {
        isSuccess: false,
        message: I18n.t('discovery.listingsManagement.sellerListingCard.endError'),
      },
    });
  }

  function handleSuccess() {
    setModalOpen(false);

    cardDispatch({
      type: ActionType.SHOW_TOAST,
      payload: {
        isSuccess: true,
        message: I18n.t(
          'discovery.listingsManagement.sellerListingCard.endSuccess',
        ),
      },
    });

    refetchSellerListings([listing.id]);
  }

  function startEndListing() {
    updateListingState({
      variables: {
        input: {
          productId: listing.id,
          newState: core_apimessages_ListingStateUpdate.ENDED,
          endingReason: endListingReason,
        },
      },
    });
  }

  return (
    <>
      <RCButton
        onClick={() => {
          trackEvent({
            eventName: MParticleEventName.ClickedComponent,
            componentName: COMPONENT_NAME,
            listingId: listing.id,
          });
          setModalOpen(true);
        }
        }
        variant="outlined"
        fullWidth
      >
        {I18n.t('discovery.listingsManagement.sellerListingCard.end')}
      </RCButton>

      <ModalDialog
        headerTitle={I18n.t('discovery.listingsManagement.sellerListingCard.endListingModal.modalHeaderTitle')}
        isOpen={isModalOpen}
        onRequestClose={() => setModalOpen(false)}
        saveButtonText={I18n.t('discovery.listingsManagement.sellerListingCard.endListingModal.modalEndButton')}
        onSubmit={startEndListing}
        isSaving={loading}
      >
        <div className="g-container">
          <div className="size-n">
            {I18n.t('discovery.listingsManagement.sellerListingCard.endListingModal.modalTitle')}
          </div>
          <div>
            <RCRadioGroup
              id="endListingReasonGroup"
              label=""
              name="endListingReasonGroup"
              onChange={event => setEndListingReason(event.target.value)}
              value={endListingReason}
            >
              <RCRadioGroup.Option
                label={I18n.t('discovery.listingsManagement.sellerListingCard.endListingModal.soldOption')}
                value={NO_LONGER_AVAILABLE}
              />
              <RCRadioGroup.Option
                label={I18n.t('discovery.listingsManagement.sellerListingCard.endListingModal.foundOption', {
                  feePercent,
                  feeAmountDisplay,
                })}
                value={SOLD_VIA_MESSAGES}
              />
            </RCRadioGroup>
          </div>
        </div>
      </ModalDialog>
    </>
  );
}

export default EndButton;
