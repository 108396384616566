import React from 'react';
import I18n from 'i18n-js';

import { Listing } from '@reverbdotcom/commons/src/gql/graphql';
import { RCChip, RCMenu, useMediaQuery } from '@reverbdotcom/cadence/components';
import BulkCreateDirectOffersModal from './BulkCreateDirectOffersModal';
import BulkDeleteDirectOffersModal from './BulkDeleteDirectOffersModal';
import { useViewTracking } from '@reverbdotcom/commons/src/use_tracking';
import { MParticleEventName } from '@reverbdotcom/commons/src/elog/mparticle_types';

export const COMPONENT_NAME = 'BulkDirectOffersButton';

interface IExternalProps {
  selectedListings: Listing[];
}

export enum DirectOfferModals {
  CREATE = 'create',
  DELETE = 'delete',
  NONE = 'none',
}

export default function BulkDirectOffersButton({
  selectedListings,
}: IExternalProps) {
  const hasSelectedListings = !!selectedListings.length;
  const isMobile = useMediaQuery('mobile');
  const chipSize = isMobile ? 'small' : 'mini';

  const [
    openModal,
    setOpenModal,
  ] = React.useState<DirectOfferModals>(DirectOfferModals.NONE);

  useViewTracking({
    componentName: COMPONENT_NAME,
    eventName: MParticleEventName.ComponentView,
  }, true);

  return (
    <>
      <RCMenu
        anchor={
          <RCChip
            size={chipSize}
          >
            {I18n.t('discovery.listingsManagement.toolbar.bulkActions.directOffers.chipText')}
          </RCChip>
        }
      >
        <RCMenu.Item
          key={I18n.t('discovery.listingsManagement.toolbar.bulkActions.directOffers.enableDirectOffers')}
          disabled={!hasSelectedListings}
          onClick={() => setOpenModal(DirectOfferModals.CREATE)}
        >
          {I18n.t('discovery.listingsManagement.toolbar.bulkActions.directOffers.enableDirectOffers')}
        </RCMenu.Item>

        <RCMenu.Item
          key={I18n.t('discovery.listingsManagement.toolbar.bulkActions.directOffers.disableDirectOffers')}
          disabled={!hasSelectedListings}
          onClick={() => setOpenModal(DirectOfferModals.DELETE)}
        >
          {I18n.t('discovery.listingsManagement.toolbar.bulkActions.directOffers.disableDirectOffers')}
        </RCMenu.Item>
      </RCMenu>
      {openModal == DirectOfferModals.CREATE && (
        <BulkCreateDirectOffersModal
          closeModal={() => setOpenModal(DirectOfferModals.NONE)}
          selectedListings={selectedListings}
        />
      )}

      {openModal == DirectOfferModals.DELETE && (
        <BulkDeleteDirectOffersModal
          closeModal={() => setOpenModal(DirectOfferModals.NONE)}
          selectedListings={selectedListings}
        />
      )}
    </>
  );
}
