import PropTypes from 'prop-types';
import React from 'react';
import Stat from './stat';
import ShopData from './shop_data';

const SalesStats = ({ shopData }) =>
  (<div className="g-container mb-2">
    <Stat shopData={shopData} dataKey="sales" additionalClasses="mt-2" />
    <div className="g-col-8 g-col-mobile-12 mt-2">
      <div className="g-container">
        <Stat shopData={shopData} dataKey="orders" />
        <Stat shopData={shopData} dataKey="offers" />
        <Stat shopData={shopData} dataKey="watchers" />
      </div>
    </div>
  </div>);

SalesStats.propTypes = {
  shopData: PropTypes.instanceOf(ShopData).isRequired,
};

export default SalesStats;
