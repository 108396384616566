import React from 'react';
import FreeShippingProgressBar, { IFreeShippingStatus } from './free_shipping_progress_bar';
import classNames from 'classnames';

export interface IProps {
  statuses: IFreeShippingStatus[];
}

export function FreeShippingProgressBarContainer({ statuses }: IProps) {
  function ProgressBars() {
    return (
      <>
        {statuses.map((status, index) => (
          <div
            key={index}
            className={classes(statuses, index)}
          >
            <FreeShippingProgressBar
              thresholdMet={status.thresholdMet}
              percentProgress={status.percentProgress}
              amountRemainingDisplay={status.amountRemainingDisplay}
              shopName={status.shopName}
              shopSlug={status.shopSlug}
            />
          </div>
        ))}
      </>
    );
  }

  if (!statuses.length) { return null; }

  return (
    <div className="scaling-mtb-6">
      <section className="site-module">
        <div className="site-module__content">
          <ProgressBars />
        </div>
      </section>
    </div>
  );
}

function classes(statuses: IFreeShippingStatus[], index: number) {
  const isBottomRow = index === statuses.length - 1;

  return classNames({ 'mb-4 pb-4 bdb-1 bd-primary': !isBottomRow });
}

export default FreeShippingProgressBarContainer;
