// TODO update this to import from commons/src/gql
// eslint-disable-next-line no-restricted-imports
import { gql } from '@apollo/client';

export const ModalSidebarListingFragment = gql`
  fragment ModalSidebarListingFragment on Listing {
    _id
    id
    title
    slug
    condition {
      displayName
    }
    images(input: { transform: "card_square" }) {
      source
    }
  }
`;
