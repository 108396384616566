import React from 'react';
import classnames from 'classnames';

interface IRowProps {
  label: string;
  children: React.ReactNode;
  singleColumn?: boolean;
}

function Row({ label, children, singleColumn = false }: IRowProps) {
  return (
    <>
      <dt className={classnames(
        'sell-form-key-value-list__term',
        { 'sell-form-key-value-list__term--single-column': singleColumn },
      )}>
        {label}
      </dt>
      <dd className={classnames(
        'sell-form-key-value-list__definition',
        { 'sell-form-key-value-list__definition--single-column': singleColumn },
      )}>
        {children}
      </dd>
    </>
  );
}

interface IProps {
  /* Should be one or more SellFormKeyValueList.Row components */
  children: React.ReactNode;
  fullHeightContainer?: boolean;
}

export function SellFormKeyValueList({ children, fullHeightContainer }: IProps) {
  const classes = classnames('sell-form-key-value-list', { 'sell-form-key-value-list--margin-top': !fullHeightContainer });

  return (
    <dl className={classes}>
      {children}
    </dl>
  );
}

SellFormKeyValueList.Row = Row;
