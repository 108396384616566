class SelectedOrders {
  constructor() {
    this.orders = {};
    this.primaryOrder = null;
  }

  toggleOrderSelected(order) {
    if (this.findOrder(order.id)) {
      this.removeOrder(order);
    } else {
      this.addOrder(order);
    }
  }

  setPrimaryOrder(order) {
    this.primaryOrder = order;
    this.addOrder(order);
  }

  updateOrderDeclaredValue(params) {
    const orderId = params.orderId;
    const price = params.price;
    this.findOrder(orderId).setDeclaredAmountUSDCents(price);
  }

  findOrder(id) {
    return this.orders[id];
  }

  get totalDeclaredAmountUSDCents() {
    let total = 0;
    this.allSelectedOrders.forEach((order) => {
      total += order.declaredAmountUSDCents;
    });
    return total;
  }

  get mostExpensiveOrderProductTypeUUID() {
    return this.selectedOrdersMostExpensiveFirst[0].productTypeUUID;
  }

  get selectedOrdersMostExpensiveFirst() {
    return this.allSelectedOrders.sort((a, b) => a.insurableAmountUSDCents < b.insurableAmountUSDCents);
  }

  get ordersPrimaryFirst() {
    return [this.primaryOrder].concat(this.allExceptPrimaryOrder);
  }

  get allExceptPrimaryOrder() {
    return this.allSelectedOrders.filter(order => order.id !== this.primaryOrder.id);
  }

  get allSelectedOrders() {
    return Object.keys(this.orders).map(key => this.orders[key]);
  }

  addOrder(order) {
    this.orders[order.id] = order;
  }

  isPrimaryOrder(order) {
    return order.id === this.primaryOrder.id;
  }

  removeOrder(order) {
    if (!this.isPrimaryOrder(order)) {
      delete this.orders[order.id];
    }
  }
}

export default SelectedOrders;
