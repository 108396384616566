// TODO update this to import from commons
// import { gql } from '@reverbdotcom/commons/src/gql';
// eslint-disable-next-line no-restricted-imports
import { gql } from '@apollo/client';
import React from 'react';
import I18n from 'i18n-js';
import HeaderDropdown from '@reverbdotcom/commons/src/components/header_dropdown';
import CoreLink from '@reverbdotcom/commons/src/components/core_link';
import { RCIcon } from '@reverbdotcom/cadence/components';
import { CartIcon } from '@reverbdotcom/cadence/icons/react';

import { ReverbHeaderCartItem, ReverbHeaderCartItemFragment } from './reverb_header_cart_item';
import { PriceDisplay } from '@reverbdotcom/commons/src/components/PriceDisplay';
import UrlHelpers from '../../shared/url_helpers';
import {
  ReverbHeaderCartBadgeData,
  core_apimessages_Money as Money,
} from '@reverbdotcom/commons/src/gql/graphql';

const MAXIMUM_VISIBLE_CART_ITEMS = 3;

interface Props {
  me: ReverbHeaderCartBadgeData.Fragment;
  activeDropdownKey: string;
  setActiveDropdownKey: (key: string) => void;
}

function ReverbHeaderCartBadge({ activeDropdownKey, setActiveDropdownKey, me }: Props) {
  const cartItems = me?.cart?.cartItems || [];
  const count = cartItems.length;

  const reverbCredits = me?.reverbCredits;

  const hasCartItems = count > 0;
  const countForDataTag = hasCartItems ? count : null;

  function handleDropdownOpen(dropdownKey: string) {
    if (!hasCartItems) {
      return;
    }

    setActiveDropdownKey(dropdownKey);
  }

  return (
    <HeaderDropdown
      coreLinkTrigger={<CartBadgeDropdownTrigger countForDataTag={countForDataTag} />}
      disableDropdown={!hasCartItems}
      dropdownKey="cart"
      onOpen={handleDropdownOpen}
      activeDropdownKey={activeDropdownKey}
      hideDropdownOnMobile
      contents={(
        <ul className="reverb-header__menu__cart">
          <ReverbCreditsCallout reverbCredits={reverbCredits} />
          {cartItems.slice(0, MAXIMUM_VISIBLE_CART_ITEMS).map(item => (
            <ReverbHeaderCartItem key={item.uuid} cartItem={item} />
          ))}
          <li className="reverb-header__menu__footer">
            <CoreLink
              className="button button--primary width-100"
              to={UrlHelpers.cartPath}
            >
              {I18n.t('discovery.reverbHeader.cart.viewCart')}
            </CoreLink>
          </li>
        </ul>
      )}
    />
  );
}

function ReverbCreditsCallout({ reverbCredits }: { reverbCredits: Money[] }) {
  if (!reverbCredits?.length) { return null; }

  return (
    <>
      {reverbCredits.map(({ currency, display }) => (
        <li className="bdb-1 bd-primary" key={currency}>
          <div className="reverb-header__cart__credits-balance">
            <span className="mr-space">
              {I18n.t('discovery.reverbHeader.cart.credits')}
            </span>
            <div className="weight-bold color-green">
              <PriceDisplay display={display} />
            </div>
          </div>
        </li>
      ))}
    </>
  );
}

function CartBadgeDropdownTrigger({ countForDataTag }) {
  return (
    <CoreLink to={UrlHelpers.cartPath}>
      <div
        className="reverb-header__nav__link"
      >
        <div
          className="reverb-header__nav__link__icon"
          data-notification-count={countForDataTag}
        >
          <RCIcon svgComponent={CartIcon} />
        </div>
        <div className="reverb-header__nav__link__label">
          {I18n.t('discovery.reverbHeader.cart.badge')}
        </div>
        <span className="reverb-header__count-badge">
          {countForDataTag && I18n.t('discovery.reverbHeader.cart.badgeCount', { count: countForDataTag })}
        </span>
      </div>
    </CoreLink>
  );
}

export const ReverbHeaderCartBadgeFragment = gql`
  fragment ReverbHeaderCartBadgeData on rql_Me {
    _id
    uuid
    reverbCredits {
      display
      currency
    }
    cart {
      cartItems {
        _id
        uuid
      }
    }
    ...ReverbHeaderCartItemFields
  }
  ${ReverbHeaderCartItemFragment}
`;

export default ReverbHeaderCartBadge;
