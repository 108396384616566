import BaseClient from './base_client';
import ReverbCookie from '../components/reverb_cookie';
import UserStore from '../reverb-js-common/user_store';
import I18n from 'i18n-js';
import { pickBy, identity } from 'lodash';

import { currentSession } from '@reverbdotcom/commons/src/elog';
import { authHeader } from '@reverbdotcom/commons/src/http_auth_header';
import { reverbDeviceInfoHeader } from '@reverbdotcom/commons/src/headers/reverbDeviceInfoHeader';
import { reverbUserInfoHeader } from '@reverbdotcom/commons/src/headers/reverbUserInfoHeader';

class CoreClient extends BaseClient {
  beforeRequest() {
    Object.assign(
      this.defaultHeaders,
      pickBy(
        {
          'X-Item-Region': ReverbCookie.get('itemRegionOverride'),
          'X-Display-Currency': UserStore.getState().currency,
          'X-Shipping-Region': UserStore.getState().shippingRegionCode,
          'Accept-Language': I18n.locale,
          'X-Context-Id': UserStore.getState().cookieId,
          'X-Session-Id': currentSession().ID(),
          'X-Reverb-App': 'REVERB',
          'X-Secondary-User-Enabled': (!!UserStore.getState().secondaryUserModeIsActive).toString(),
          'X-Postal-Code': UserStore.getState().postalCode,
          ...reverbDeviceInfoHeader(UserStore.getState()),
          ...reverbUserInfoHeader(UserStore.getState()),
          ...authHeader(),
        },
        identity,
      ),
    );
  }
}

const client = new CoreClient();
client.setDefaultHeaders({
  'Content-Type': 'application/json',
  'Accept-Version': '3.0',
});

export default client;

const V4CoreClient = new CoreClient();
V4CoreClient.setDefaultHeaders({
  'Content-Type': 'application/json',
  'Accept-Version': '4.0',
});

export { V4CoreClient };
