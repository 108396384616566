import React from 'react';
import I18n from 'i18n-js';
import striptags from 'striptags';
import Helmet from 'react-helmet';
import { truncate } from 'lodash';
import { CORE_DEFAULT_OG_IMAGE_URL } from '../constants';
import { isRegionalURL, getRegionFromUrl } from '@reverbdotcom/commons/src/url_helpers';
import { useURL, IUrl } from '@reverbdotcom/commons/src/url_context';
import { CORE_WEB_ORIGIN } from '@reverbdotcom/env';

export const MAX_DESC_LEN = 300;

export interface IProps {
  // title and titlePostfix are joined for the final page title: `{{title}} | {{titlePostfix}}`
  title?: string;
  description?: string;
  ogDescription?: string;
  metaImage?: string;
  image?: string;
  // No Index tag should only be be set for cms pages. For other pages set metadata in the route controller in core
  noIndex?: boolean;
  includeOgUrl?: boolean;
}

function formatTitle(title, postfix) {
  return I18n.t('cms.head.fullTitle', { title, postfix });
}

function formatDescription(description) {
  return truncate(stripHtml(description), { length: MAX_DESC_LEN });
}

function calculateTitle(title, postfix) {
  const stripped = stripHtml(title);

  if (!stripped) {
    return formatTitle(I18n.t('cms.head.defaultTitle'), postfix);
  }

  return formatTitle(stripped, postfix);
}

function stripHtml(text) {
  if (!text) return null;

  return striptags(text);
}

function regionalPostfix(url: Partial<IUrl>): string {
  const region = getRegionFromUrl(url);

  if (isRegionalURL(url)) {
    return I18n.t(`discovery.metadata.titlePostfix.${region}`);
  }

  return I18n.t('cms.head.postfix');
}

// Other tags, like canonical links and hreflang tags, should be set within Rails. Before adding
// new tags to this component, verify that it isn't already set within Rails.
export default function Metadata(props: IProps) {
  const image = props.metaImage || props.image || CORE_DEFAULT_OG_IMAGE_URL;
  const url = useURL();
  const postfix = regionalPostfix(url);

  const title = calculateTitle(props.title, postfix);

  const defaultDescription = I18n.t('cms.head.defaultDescription');
  const description = formatDescription(props.description || defaultDescription);
  const ogDescription = formatDescription(props.ogDescription || props.description || defaultDescription);

  return (
    <Helmet>
      <meta property="fb:app_id" content="405002109547804" />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@reverb" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={ogDescription} />
      <meta name="twitter:image" content={image} />
      <meta property="og:title" content={title} />
      <meta property="og:type" content="website" />
      <meta property="og:description" content={ogDescription} />
      <meta property="og:image" content={image} />
      <meta property="og:site_name" content="reverb.com" />
      {props.includeOgUrl && <meta property="og:url" content={`${CORE_WEB_ORIGIN}${url.pathname}`} />}
      <meta name="description" content={description} />

      <title>{title}</title>

      {props.noIndex && <meta name="robots" content="noindex" />}
    </Helmet>
  );
}
