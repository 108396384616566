import I18n from 'i18n-js';
import React, { useContext } from 'react';

import { isZeroAmount } from '@reverbdotcom/commons/src/money';
import { RCCheckbox } from '@reverbdotcom/cadence/components';
import { US } from '@reverbdotcom/commons/src/constants';

import { upsertShippingRate, deleteShippingRate, BLANK_RATE_ATTRS, FREE_RATE_ATTRS } from './ShippingForm';
import { SellFormContext } from '../../SellFormContext';
import { Field, ActionType, IShippingRateObjectState } from '../../sellFormReducerTypes';
import EditShippingRateCardManualPrices from '../../../../shared/edit_shipping_rate_card_manual_prices';

function InternationalShippingPrice({ shippingRate }: { shippingRate: IShippingRateObjectState }) {
  const { listingEntry, dispatch, shopConfig, loading } = useContext(SellFormContext);
  const [isFreeModeSelected, setIsFreeModeSelected] = React.useState(
    isZeroAmount(shippingRate?.rate?.amount) &&
      !shippingRate?.incrementalRate?.amount &&
      !shippingRate?.expeditedRate?.amount,
  );

  return (
    <div className="international-shipping-price">
      <div className="international-shipping-price__header">
        <div>
          <strong>
            {I18n.t(`discovery.shippingRegions.${shippingRate.regionCode}`)}
          </strong>
        </div>
        <div>
          <button
            data-remove-rate="true"
            className="international-shipping-price__remove"
            type="button"
            onClick={() => {
              dispatch({
                type: ActionType.UPDATE_FIELD,
                fieldKey: Field.SHIPPING_RATES,
                value: deleteShippingRate(listingEntry[Field.SHIPPING_RATES], shippingRate.regionCode),
              });
            }}
            disabled={loading}
            aria-label={
              I18n.t('discovery.dashboard.selling.shippingRates.rateFields.removeButtonLabel', {
                regionName: I18n.t(`discovery.shippingRegions.${shippingRate.regionCode}`),
              })
            }
          >
            {I18n.t('discovery.dashboard.selling.shippingRates.rateFields.removeRate')}
          </button>
        </div>
      </div>

      <RCCheckbox
        id={`${shippingRate.regionCode}_free`}
        name={`${shippingRate.regionCode}_free`}
        label={I18n.t('discovery.sellForm.shippingSection.internationalShippingCard.offerFree')}
        checked={isFreeModeSelected}
        onChange={() => {
          if (isFreeModeSelected) {
            setIsFreeModeSelected(false);
            dispatch({
              type: ActionType.UPDATE_FIELD,
              fieldKey: Field.SHIPPING_RATES,
              value: upsertShippingRate(listingEntry[Field.SHIPPING_RATES], { ...shippingRate, ...BLANK_RATE_ATTRS }),
            });
          } else {
            setIsFreeModeSelected(true);
            dispatch({
              type: ActionType.UPDATE_FIELD,
              fieldKey: Field.SHIPPING_RATES,
              value: upsertShippingRate(listingEntry[Field.SHIPPING_RATES], { ...shippingRate, ...FREE_RATE_ATTRS }),
            });
          }
        }}
        disabled={loading}
      />

      <hr />

      <EditShippingRateCardManualPrices
        shippingRate={shippingRate}
        handleEditShippingRate={(updated) => dispatch({
          type: ActionType.UPDATE_FIELD,
          fieldKey: Field.SHIPPING_RATES,
          value: upsertShippingRate(listingEntry[Field.SHIPPING_RATES], updated),
        })}
        currency={shopConfig.currencySetting}
        allowedToShipExpedited={false}
        disabled={loading || isFreeModeSelected}
        displayShippingEstimator={shopConfig.address.countryCode === US}
      />
    </div>
  );
}

export default InternationalShippingPrice;
