import * as React from 'react';
import classNames from 'classnames';

import CoreLink from '@reverbdotcom/commons/src/components/core_link';
import CreateOfferButton from '@reverbdotcom/commons/src/offers/create_offer_button';
import { SHOP_PATH, listingItemPagePath } from '@reverbdotcom/commons/src/url_helpers';
import AddToCartButton from '@reverbdotcom/commons/src/components/add_to_cart_button';
import { DisplayStyle, WatchBadge } from '@reverbdotcom/commons/src/components/watch_badge';
import { WatchParentComponentNames } from '@reverbdotcom/commons/src/event_tracking/watchlist';
import { shouldShowBumpTag } from '@reverbdotcom/commons/src/components/bump_tag';
import { useUser } from '@reverbdotcom/commons/src/user_hooks';
import { LISTING_STATES, WEB } from '@reverbdotcom/commons/src/constants';
import { buildListingClickEvent } from '@reverbdotcom/commons/src/elog/mparticle_tracker';
import { withRouter, WithRouterProps } from 'react-router';
import { RCListingRowCard } from '@reverbdotcom/cadence/components/RCListingRowCard/RCListingRowCard';
import ImageCarousel from '@reverbdotcom/commons/src/components/image_carousel';
import {
  buildListingForBuyer,
  getEyebrow,
  buildPrice,
  getRCListingCardNudges,
} from '@reverbdotcom/commons/src/listing_helpers';
import { withUserContext } from './user_context_provider';
import { connect } from '@reverbdotcom/commons/src/connect';

import AffirmEstimateListingCardCTA from './affirm_estimate_listing_card_cta';
import ListingCardTag from './listing_card_tag';
import { ListingCardMetadata } from './listing_card_metadata';
import { ListingTitleHtmlTags } from '@reverbdotcom/cadence/components/types';
import { ListingCardReviews } from './reviews_metadata';

interface IExternalProps {
  listing: any;
  loading: boolean;
  position?: number;
  trackingQuery?: string;
  trackingFilter?: string;
  trackingSort?: string;
  trackingPage?: number;
  showingSold?: boolean;
  handleCreateWatchResult?: () => void;
  hideMetadataAsOffer?: boolean;
  listingsCount?: number;
  showEstimatedMonthlyPayment?: boolean;
  componentName?: string;
  cspFeaturedListingId?: string;
  displayGreatValue?: boolean;
  reviews?: ListingCardReviews;
  includeItemListMetadata?: boolean;
  titleHtmlTag?: keyof typeof ListingTitleHtmlTags;
  cmsComponentId?: string;
}

type IProps = IExternalProps & WithRouterProps;

export function ListingRowCard({ listing, ...props }: IProps) {
  const user = useUser();

  if (props.loading) {
    return <RCListingRowCard.Skeleton />;
  }

  const clickEvent = buildListingClickEvent({
    listing,
    isFeaturedInCSPCollection: props.cspFeaturedListingId === listing.id,
    position: props.position,
    listingsCount: props.listingsCount,
    componentName: props.componentName,
    cmsComponentId: props.cmsComponentId,
  });

  const receivedCount = listing.seller?.feedbackSummary?.receivedCount;
  const rollingRatingPercentage = listing.seller?.feedbackSummary?.rollingRatingPercentage;
  const rating = rollingRatingPercentage / 100;
  const openNewTab = user && user.deviceName === WEB;

  const isBumped = shouldShowBumpTag(listing);
  const images = listing.images.map(i => (i.source));
  const listingPath = `${listingItemPagePath(listing)}`;
  const combinedShippingDiscount = false; // In the previous component showCombinedRate=false
  const showShippingDisplay = true; //In the previous component it was passed but the logic ends in false
  const decoratedListing = buildListingForBuyer(
    listing,
    { combinedDiscountActive: combinedShippingDiscount },
  );
  const nudges = getRCListingCardNudges({
    listing,
    decoratedListing,
    shop: listing.shop,
    displayGreatValue: props.displayGreatValue,
    user,
    showFlag: false, // showFlag is set to false as it is not display in a row card
    location: props?.location,
  });

  function getShopInfo() {
    return {
      location: listing.shop.address.displayLocation,
      name: listing.shop.name,
      rating: {
        count: receivedCount,
        rating: rating,
      },
      linkElement: (children) => (
        <CoreLink
          to={SHOP_PATH.expand({ slug: listing.shop.slug })}
          target={openNewTab ? '_blank' : undefined}
        >
          {children}
        </CoreLink>
      ),
    };
  }

  return (
    <div className={classNames(
      {
        'list-view-row-card--loading': props.loading,
      },
    )}>
      <ListingCardMetadata
        listing={listing}
        reviews={props.reviews}
        hideMetadataAsOffer={props.hideMetadataAsOffer}
        includeItemListMetadata={props.includeItemListMetadata}
      />
      <RCListingRowCard
        thumbnailElement={(<ImageCarousel images={images} />)}
        title={listing.title}
        titleHtmlTag={props.titleHtmlTag}
        conditionLabel={decoratedListing.conditionLabel}
        price={buildPrice(decoratedListing, combinedShippingDiscount, showShippingDisplay)}
        estimatedMonthlyPaymentPlan={props.showEstimatedMonthlyPayment &&
          <div className="mtb-1">
            <AffirmEstimateListingCardCTA listing={listing} />
          </div>
        }
        nudges={nudges}
        eyebrow={getEyebrow(listing, isBumped)}
        violator={<ListingCardTag listing={listing} />}
        linkElement={(children) => (
          <CoreLink
            to={listingPath}
            target={openNewTab ? '_blank' : undefined}
            clickEvent={clickEvent}
          >
            {children}
          </CoreLink>
        )}
        shopInfo={getShopInfo()}
        primaryAction={listing.state === LISTING_STATES.LIVE && (
          <AddToCartButton
            listing={listing}
            parentComponentName={props.componentName}
            listingClickEvent={clickEvent}
            position={props.position}
          />
        )}
        secondaryAction={<CreateOfferButton
          className="list-view-row-card__make-offer"
          listing={listing}
          parentComponentName={props.componentName}
          position={props.position}
        />}
        tertiaryAction={<WatchBadge
          listing={listing}
          parentComponentName={WatchParentComponentNames.MarketplaceListViewAction}
          useToast
          position={props.position}
        />}
        favoriteButtonElement={(
          <WatchBadge
            listing={listing}
            displayStyle={DisplayStyle.RC_FAVORITE_BUTTON}
            parentComponentName={WatchParentComponentNames.MarketplaceListViewIcon}
            handleCreateWatchResult={props.handleCreateWatchResult}
            useToast
            position={props.position}
          />
        )}
      />
    </div>
  );
}

export function PlaceholderListingRowCard() {
  return <RCListingRowCard.Skeleton />;
}


export default connect<IExternalProps>([
  withUserContext,
  withRouter,
])(ListingRowCard);
