import React from 'react';
import classNames from 'classnames';

interface Props {
  children?: any;
  active?: boolean;
  childActive?: boolean;
  disabled?: boolean;
  paramName?: string;
}

export default class FacetOption extends React.Component<Props, null> {
  render() {
    return (
      <div
        className={classNames(`facet__option facet__option--${this.props.paramName}`, {
          'facet__option--disabled': this.props.disabled,
          'facet__option--active': this.props.active,
          'facet__option--child-active': this.props.childActive,
        })}
      >
        {this.props.children}
      </div>
    );
  }
}
