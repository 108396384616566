import React from 'react';
import { isEqual } from 'lodash';
import { Route } from 'react-router';
import isNode from '@reverbdotcom/commons/src/is-node';
import CSPGridDataManager from '../data_managers/csp_grid_data_manager';
import { CollectionHeaderStore, CollectionHeaderActions } from '@reverbdotcom/commons/src/components/stores/collection_header_store';
import CuratedSetRowActions from '../components/discovery/actions/curated_set_row_actions';

function alreadyLoadedHeader(newParams) {
  const storeParams = CollectionHeaderStore.getState().params;
  return storeParams && storeParams.curated_set_slug === newParams.curated_set_slug;
}

function curatedSetRowActions(excludedSlug) {
  CuratedSetRowActions.fetch({
    sort: 'random',
    csp_type: true,
    excluded_slug: excludedSlug,
  });
}

const CollectionRoute = (
  <Route
    key="collection/:curated_set_slug"
    path="collection/:curated_set_slug"
    props={{ clientSide: true }}
    getComponent={(_location, cb) => {
      import(
        /* webpackChunkName: 'csp' */
        '../components/discovery/csp/csp_curated_set_grid'
      ).then(m => cb(null, m.default));
    }}
    onChange={(oldState, newState) => {
      if (!isEqual(oldState.params, newState.params)) {
        CollectionHeaderActions.fetchOrClear(newState.params);
      }

      curatedSetRowActions(newState.params.curated_set_slug);
      CSPGridDataManager.fetchCSPs(newState, oldState);
    }}
    onEnter={(newState) => {
      if (isNode) { return; }

      curatedSetRowActions(newState.params.curated_set_slug);
      if (!alreadyLoadedHeader(newState.params)) {
        CollectionHeaderActions.fetchOrClear(newState.params);
      }
      CSPGridDataManager.fetchCSPs(newState);
    }}
  />
);

export default CollectionRoute;
