import React from 'react';
import classNames from 'classnames';
import { RCLoadingBars } from '@reverbdotcom/cadence/components';

interface Props {
  showOverlay?: boolean;
  children?: React.ReactNode;
}

function LoadingOverlay({
  showOverlay = false,
  children = null,
}: Props) {
  const classes = classNames('loading-overlay', {
    'loading-overlay--active': showOverlay,
  });

  return (
    <div className={classes}>
      <div className="loading-overlay__content">
        {children}
      </div>
      {showOverlay &&
        <div className="loading-overlay__bars">
          <RCLoadingBars />
        </div>
      }
    </div>
  );
}

export default LoadingOverlay;
