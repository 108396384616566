export default {
  payNowButtonText: 'Pay Now',
  payNowModalTitle: 'Pay My Bill Now',
  paymentForm: {
    totalOwed: 'Total Owed',
    totalPaid: 'Previous Total Paid',
    balance: 'Balance',
    addNewCard: 'Add New Card',
    paymentAmount: 'Payment Amount',
    cancel: 'Cancel',
    confirmPayment: 'Confirm Payment',
    confirmPaymentWithAmount: 'Confirm Payment of {{inputAmount}}',
    invalidAmount: 'Please enter a valid amount',
    paymentThanks: 'Thanks for your payment of',
    paymentOnDate: 'Payment on {{date}}',
  },
  addNewCardModalTitle: 'Add New Card',
  addNewCardForm: {
    nameOnCardLabel: 'Name on Card',
    cardNumberLabel: 'Card Number',
    expirationDateLabel: 'Expiration',
    securityCodeLabel: 'Security Code',
    billingAddressHeader: 'Billing Address',
    cancel: 'Cancel',
    submitCard: 'Save and Use Card',
    cardSaving: 'Saving card...',
  },
  statementPaymentNeededBanner: {
    speakerAlt: 'Speaker Icon',
    headline: 'Payment Needed',
    message: 'You have an unpaid balance. Please pay it now to continue using your Reverb account.',
  },
  paymentSuccessBanner: {
    headerAllStatementsPaid: 'Your monthly statements are paid!',
    messageAllStatementsPaid: 'Nothing additional is needed at this time.',
    headerStatementFullyPaid: 'Thank you for paying your statement!',
    messageStatementFullyPaid: 'Looks like there are additional statements to be paid. To make another payment, select “Pay Now” below.',
    headerStatementPartiallyPaid: 'Thanks for your payment!',
    messageStatementPartiallyPaid: 'To pay the rest of your statement balance, select the "Pay Now" button below.',
  },
};
