import React from 'react';
import I18n from 'i18n-js';

import { useQuery } from '@reverbdotcom/commons/src/useQuery';
import { RCCheckbox, RCLoadingBars, RCModal, RCTextWithIcon } from '@reverbdotcom/cadence/components';
import { useExpEnabled, useSeller } from '@reverbdotcom/commons/src/user_hooks';
import {
  formatNumber,
  parseAmount,
} from '@reverbdotcom/commons/src/money';
import { MParticleEventName } from '@reverbdotcom/commons/src/elog/mparticle_types';
import { trackEvent } from '@reverbdotcom/commons/src/elog/mparticle_tracker';
import { LISTING_STATES, US } from '@reverbdotcom/commons/src/constants';
import { useMutation } from '@reverbdotcom/commons/src/useMutation';

import { buildListingEntryStateFromPersistedListing } from '../../sell/form/sellFormReducer';
import { Field } from '../../sell/form/sellFormReducerTypes';
import { listingSellerPrice } from './quick_price_edit_button';
import { buildShopConfig, listingPriceRecommendation, QUICK_PRICE_EDIT_LISTING_QUERY, QUICK_PRICE_EDIT_MUTATION, showQuickPriceEditFilterChips } from './quick_price_edit_modal';
import QuickPriceEditPercentOffFilterChips, { calculateDiscountedPrice, PERCENTS_OFF } from './quick_price_edit_percent_off_filter_chips';
import experiments from '@reverbdotcom/commons/src/experiments';
import QuickPriceEditPriceRecommendation from './quick_price_edit_price_recommendation';
import { InfoCircleIcon } from '@reverbdotcom/cadence/icons/react';
import SellFormIncVatPricingHint from '../../sell/form/components/SellFormIncVatPricingHint';
import SellFormPriceSignalCallout from '../../sell/form/components/SellFormPriceSignalCallout';
import PriceBreakdown from '../../sell/form/components/PriceBreakdown';
import QuickPriceEditHelpText, { calculatePercentOff } from './quick_price_edit_help_text';
import { MoneyInput } from '@reverbdotcom/commons/src/components/money_input';
import { core_apimessages_Money, reverb_pricing_PriceRecommendation } from '@reverbdotcom/commons/src/gql/graphql';

interface IExternalProps {
  listingId: string;
  isOpen: boolean;
  requestClose: React.Dispatch<void>;
  onSuccessCallback: () => void;
}

export const COMPONENT_NAME = 'QuickPriceEditModalV2';
const DEFAULT_CURRENCY = 'USD';

export function defaultSellerPrice(originalSellerPrice: core_apimessages_Money, priceRecommendation: reverb_pricing_PriceRecommendation) {
  const { priceMiddle } = priceRecommendation;
  const hasPriceRecommendation = !!priceMiddle;

  if (!hasPriceRecommendation) return calculateDiscountedPrice(originalSellerPrice, PERCENTS_OFF[0]);

  if (priceMiddle.amountCents < originalSellerPrice.amountCents) {
    return priceMiddle;
  } else {
    return originalSellerPrice;
  }
}

export default function QuickPriceEditModalV2({
  listingId,
  isOpen,
  requestClose,
  onSuccessCallback,
}: IExternalProps) {
  const seller = useSeller();
  const isV2QuickPriceEdit = useExpEnabled(experiments.LISTINGS_MANAGEMENT_QUICK_PRICE_EDIT_UPDATES);

  const {
    sellerId,
    locale,
    shopCountryCode,
    shopCurrency,
  } = seller;

  const {
    data: listingData,
    loading: listingLoading,
  } = useQuery(QUICK_PRICE_EDIT_LISTING_QUERY, {
    skip: !isOpen,
    ssr: false,
    fetchPolicy: 'network-only',
    variables: {
      listingId,
      skipPriceRecommendation: shopCountryCode != US,
      shopCountryCode,
      shopCurrency,
    },
    errorPolicy: 'all',
  });

  const [updateListingPrice, {
    loading: isUpdatingListingPrice,
    data: updateListingPriceData,
    errors: updateListingPriceErrors,
  }] = useMutation(QUICK_PRICE_EDIT_MUTATION);

  const [priceAmount, setPriceAmount] = React.useState('');
  const [taxExempt, setTaxExempt] = React.useState(false);
  const [errors, setErrors] = React.useState([]);

  const { listing = {} } = listingData ?? {};
  const shopConfig = listingLoading ? null : buildShopConfig(listingData);

  const originalSellerPrice = listingSellerPrice(listing, shopCurrency || DEFAULT_CURRENCY);
  const { currency: listingCurrency } = originalSellerPrice;

  const priceRecommendation = listingPriceRecommendation(listing);
  const { priceMiddle: competitivePrice } = priceRecommendation;
  const hasPriceRecommendation = !!competitivePrice;

  const isLiveListing = listing.state == LISTING_STATES.LIVE;

  const preferredSeller = shopConfig?.preferredSeller ?? false;
  const taxPolicies = shopConfig?.taxPolicies ?? [];

  const showVatTaxExemptField = taxPolicies.length > 0 && taxPolicies.some(({ vatPolicy }) => !!vatPolicy);

  const excludeFilterChipsFromV2Experiment = isV2QuickPriceEdit && hasPriceRecommendation;

  const showFilterChips = showQuickPriceEditFilterChips({
    priceMiddle: priceRecommendation.priceMiddle,
    sellerPrice: originalSellerPrice,
  }) && !excludeFilterChipsFromV2Experiment;

  const isEligibleForWatcherPriceDropAlerts = isLiveListing && originalSellerPrice.amountCents > 0;
  const forceMoneySuffixForUserInUS = locale === 'en' && shopCountryCode === 'US' && shopCurrency === 'USD';

  React.useEffect(() => {
    const shouldInitialize = isOpen && !listingLoading && !!listingData;

    if (shouldInitialize) {
      trackEvent({
        eventName: MParticleEventName.ComponentView,
        componentName: COMPONENT_NAME,
        listingId,
        sellerId,
      });

      const defaultNewPrice = defaultSellerPrice(originalSellerPrice, priceRecommendation);

      setPriceAmount(
        formatNumber(
          Number(defaultNewPrice.amount),
        ),
      );

      setTaxExempt(!!listing.taxExempt);
    }
  }, [listingLoading]);

  React.useEffect(() => {
    const isFinishedLoading = !isUpdatingListingPrice;
    const hasData = !!updateListingPriceData;
    const hasErrors = !!updateListingPriceErrors?.length;
    const hasAPIResponse = isFinishedLoading && (hasData || hasErrors);

    if (!hasAPIResponse) return;

    if (hasErrors) {
      handleErrors(updateListingPriceErrors);
    } else {
      const updatedPrice = updateListingPriceData.crupdateListing.sellerPrice;

      const priceDroppedAttribute = updatedPrice.amountCents != originalSellerPrice.amountCents ? {
        priceDropped: updatedPrice.amountCents < originalSellerPrice.amountCents,
      } : {};

      const discountPercent = calculatePercentOff(originalSellerPrice, updatedPrice.amount);
      const discountPercentAttribute = discountPercent ? { discountPercent } : {};

      const wellPricedAttribute = competitivePrice ? {
        wellPriced: competitivePrice.amountCents >= updatedPrice.amountCents,
      } : {};

      trackEvent({
        eventName: MParticleEventName.UpdatedListingPrice,
        componentName: COMPONENT_NAME,
        listingId,
        sellerId,
        ...priceDroppedAttribute,
        ...discountPercentAttribute,
        ...wellPricedAttribute,
      });

      setErrors([]);
      onSuccessCallback();
    }
  }, [isUpdatingListingPrice]);

  function handleCloseModal() {
    setErrors([]);
    requestClose();
  }

  function handleErrors(errors) {
    setErrors(errors.map(error => error.message));
  }

  function formatErrors() {
    return errors.join(' ');
  }

  function updatePricing({
    priceAmount,
    taxExempt,
  }) {
    const formattedListing = buildListingEntryStateFromPersistedListing(listing);

    const listingEntry = {
      ...formattedListing,
      ...{
        [Field.PRICE]: {
          amount: `${priceAmount}`,
        },
        [Field.TAX_EXEMPT]: taxExempt,
      },
    };

    updateListingPrice({
      variables: {
        input: {
          listingEntry,
        },
      },
    });
  }

  if (!isOpen) return null;

  return (
    <RCModal
      isOpen={isOpen}
      title={I18n.t('discovery.listingsManagement.quickPriceEdit.modal.title')}
      onOpenChange={handleCloseModal}
      actions={{
        primary: {
          onClick: () => updatePricing({
            priceAmount,
            taxExempt,
          }),
          preventCloseOnClick: true,
          disabled: listingLoading || isUpdatingListingPrice,
        },
        secondary: true,
      }}
    >
      {listingLoading ? (
        <div className="d-flex fx-justify-center">
          <RCLoadingBars />
        </div>
      ) : (
        <div className="quick-price-edit-modal-v2__content">
          <div className="quick-price-edit-modal-v2__content-header">
            {I18n.t('discovery.listingsManagement.quickPriceEdit.modal.currentPrice', {
              displayAmount: originalSellerPrice.display,
            })}
          </div>

          <QuickPriceEditPriceRecommendation
            listingId={listing.id}
            priceRecommendation={priceRecommendation}
          />

          {showFilterChips && (
            <QuickPriceEditPercentOffFilterChips
              listingId={listing.id}
              originalPrice={originalSellerPrice}
              currentPrice={parseAmount(priceAmount, listingCurrency)}
              updatePrice={priceAmount => setPriceAmount(priceAmount)}
            />
          )}

          {isEligibleForWatcherPriceDropAlerts && (
            <div className="weight-semibold">
              <RCTextWithIcon
                placement="left"
                svgComponent={InfoCircleIcon}
              >
                {I18n.t('discovery.listingsManagement.quickPriceEdit.modal.notifyWatchers')}
              </RCTextWithIcon>
            </div>
          )}

          <div className="quick-price-edit-modal-v2__content-body">
            <MoneyInput
              id={Field.PRICE}
              name={Field.PRICE}
              label={I18n.t('discovery.listingsManagement.quickPriceEdit.modal.moneyInput.newPrice')}
              value={priceAmount || ''}
              onChange={event => setPriceAmount(event.target.value)}
              required
              helpText={<QuickPriceEditHelpText
                originalPrice={originalSellerPrice}
                currentPriceAmount={priceAmount}
                isLiveListing={isLiveListing} />
              }
              errorText={formatErrors()}
              currencyCode={listingCurrency}
              suffixText={forceMoneySuffixForUserInUS ? listingCurrency : null}
            />

            {hasPriceRecommendation && (
              <SellFormPriceSignalCallout
                calloutPrice={parseAmount(priceAmount, listingCurrency)}
                conditionSlug={listing.condition.conditionSlug}
                canonicalProductId={listing.canonicalProductId}
              />
            )}

            {listing.vatIncluded &&
            <SellFormIncVatPricingHint />
            }

            <PriceBreakdown
              price={priceAmount}
              currency={listingCurrency}
              preferredSeller={preferredSeller}
              sellingFeeWaived={false}
              shopConfig={shopConfig}
              showIncVatPricingHint={listing.vatIncluded}
            />

            {showVatTaxExemptField && (
              <div>
                <div className="weight-bold size-90 mb-2">
                  {I18n.t(`discovery.sellForm.fields.${Field.TAX_EXEMPT}.checkboxTitle`)}
                </div>
                <RCCheckbox
                  name={Field.TAX_EXEMPT}
                  id={Field.TAX_EXEMPT}
                  label={I18n.t(`discovery.sellForm.fields.${Field.TAX_EXEMPT}.checkboxLabel`)}
                  checked={taxExempt}
                  onChange={() => setTaxExempt(!taxExempt)}
                />
              </div>
            )}
          </div>
        </div>
      )}
    </RCModal>
  );
}
