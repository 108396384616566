import * as elog from '@reverbdotcom/commons/src/elog';
import * as Sentry from '@sentry/browser';
import { parseMeta } from './parse_meta';
import { initMParticleSDK } from '@reverbdotcom/commons/src/elog/mparticle_tracker';
import { IS_TEST } from '@reverbdotcom/env';
const ELOG_CONFIG_KEY = 'elog-config';
const CURRENT_USER_KEY = 'current-user';

window.Sentry = Sentry;

function initializeMParticle() {
  const userConfig = parseMeta(CURRENT_USER_KEY);
  const appConfig = parseMeta('app-config-meta');
  if (userConfig && appConfig) {
    initMParticleSDK();
  }
}

function init() {
  const elogConfig = parseMeta(ELOG_CONFIG_KEY);

  if (elogConfig) {
    const config: elog.Config = {
      ...elogConfig.elog,
      sentryClient: Sentry,
    };

    elog.configure(config);
    elog.identify(config.userContext);
    elog.pageView();
    initializeMParticle();
  }
}

window.addEventListener('load', () => {
  if (IS_TEST) {
    return;
  }

  init();
});

export default {
  init, // for testing only
};
