import { ChildProps } from '@apollo/client/react/hoc';
// TODO update this to import from commons/src/gql
// eslint-disable-next-line no-restricted-imports
import { gql } from '@apollo/client';
import React from 'react';
import PhotoTiles, { PhotoTileFragment } from './photo_tiles';
import { DiscoRecentlyViewedListingPhotosRow } from '@reverbdotcom/commons/src/gql/graphql';
import OverflowingRow from '@reverbdotcom/commons/src/components/overflowing_row';

import { useViewTracking } from '@reverbdotcom/commons/src/use_tracking';
import { MParticleEventName } from '@reverbdotcom/commons/src/elog/mparticle_tracker';
import { withGraphql } from '@reverbdotcom/commons/src/with_graphql';

interface IProps {
  title?: string | JSX.Element;
}

const COMPONENT_NAME = 'RecentlyViewedListingPhotosRow';

export function RecentlyViewedListingPhotosRow(props: ChildProps<IProps, DiscoRecentlyViewedListingPhotosRow.Query>) {
  const listings = props.data?.recentlyViewedListings;
  const isLoading = props.data?.loading;
  const shouldRender = listings && listings.length !== 0;

  const mParticleViewData = {
    componentName: COMPONENT_NAME,
    eventName: MParticleEventName.ComponentView,
    listingsCount: listings?.length,
  };

  useViewTracking(mParticleViewData, shouldRender && !isLoading);

  if (!shouldRender && !isLoading) return null;

  return (
    <OverflowingRow
      title={props.title}
      collectionCount={listings?.length || 12}
      condensed
    >
      <PhotoTiles
        items={listings}
        loading={isLoading}
        trackingName={COMPONENT_NAME}
      />
    </OverflowingRow>
  );
}

const connect = withGraphql<IProps, DiscoRecentlyViewedListingPhotosRow.Query>(
  gql`
    query Disco_RecentlyViewedListingPhotosRow {
      recentlyViewedListings(input: {
        limit: 12
      }) {
        _id
        ...PhotoTileFields
      }
    }
    ${PhotoTileFragment}
  `,
  {
    options: {
      ssr: false, // only rendered client side after a user interacts with the site search component, no need for ssr
    },
  },
);

export default connect(RecentlyViewedListingPhotosRow);
