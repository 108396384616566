import React from 'react';
import { Route } from '@reverbdotcom/commons/src/route_settings';

export const BrowsePageRoutes = [
  <Route
    key="cat/*"
    path="cat/*"
    props={{ clientSide: true }}
    getComponent={(_location, cb) => {
      import(
        /* webpackChunkName: 'browse' */
        '../components/browse/browse_layout'
      ).then(m => cb(null, m.default));
    }}
  />,
  <Route
    key="b/*"
    path="b/*"
    props={{ clientSide: true }}
    getComponent={(_location, cb) => {
      import(
        /* webpackChunkName: 'browse' */
        '../components/browse/browse_layout'
      ).then(m => cb(null, m.default));
    }}
  />,
  <Route
    key="m/*"
    path="m/*"
    props={{ clientSide: true }}
    getComponent={(_location, cb) => {
      import(
      /* webpackChunkName: 'browse' */
        '../components/browse/browse_layout'
      ).then(m => cb(null, m.default));
    }}
  />,
];
