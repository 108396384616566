import { CURRENCY_CODES, PAYOUT_METHODS } from '../../../shared/constants';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import UrlHelpers from '../../../shared/url_helpers';
import ErrorMessage from '../../../shared/error_message';
import I18n from 'i18n-js';
import {
  withUserContext,
} from '@reverbdotcom/commons/src/components/user_context_provider';
import bind from '@reverbdotcom/commons/src/bind';
import {
  refetchDirectCheckoutProfile,
  setPlaidModalOpenInSummaryView,
  setDirectCheckoutProfileSummaryLoadingState,
  setPlaidAccountStatus,
  withDirectCheckoutProfileQuery,
  isPlaid,
  setPendingPlaidAccountId,
} from '../../../components/shared/plaid_helpers';
import {
  trackEvent,
  MParticleEventName,
  MParticlePageName,
} from '@reverbdotcom/commons/src/elog/mparticle_tracker';
import { RCLoadingBars } from '@reverbdotcom/cadence/components';
import PayoutBankAccountForm from '../../../components/payout_bank_account_form';
import { connect } from '@reverbdotcom/commons/src/connect';

export class PayoutMethodOptions extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isEditing:
        this.hasDirectCheckoutProfileErrors ||
        !this.props.hideBankAccountEditForm,
      selectedPayoutMethod: this.payoutMethodInitiallySelected,
      selectedPayoutCurrency: this.props.profileAttributes.currency,
      // eslint-disable-next-line react/no-unused-state
      openPlaidModal: null,
      pendingPlaidAccountId: this.props.plaidProps?.pendingPlaidAccountId,
      plaidAccountStatus: this.props.plaidProps?.plaidAccountStatus,

      showPlaidSummary: false,
      // eslint-disable-next-line react/no-unused-state
      loadingDcp: false,
    };

    this.hasTrackedGetPaidView = false;
  }

  onlyACHPayoutMethod() {
    return (
      <div className="mb-2">
        <p className="weight-bold mb-0">
          {I18n.t(
            'js.dashboard.selling.shopPolicies.directCheckoutProfile.achPayoutDescription.setUpForAch',
          )}
        </p>
        <p
          dangerouslySetInnerHTML={{ __html: this.achPayoutDescriptionText }}
        />
      </div>
    );
  }

  get payoutMethodInitiallySelected() {
    if (this.props.hideBankAccountEditForm) {
      return this.props.profileAttributes.payoutMethod;
    }

    return PAYOUT_METHODS.ACH;
  }

  nameForField(fieldName) {
    return `${this.props.formPrefix}[${fieldName}]`;
  }

  idForField(fieldName) {
    return `${this.props.formPrefix}-${fieldName}`;
  }

  formatPayoutOptionId() {
    return this.achPayoutDescriptionText
      ?.split(' ')
      .join('-')
      .split('.')[0];
  }

  achPayoutOption() {
    return (
      /* disabling inline here so we can enable this rule globally, but this should be fixed the next time this file is touched */
      /* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */
      (<li onClick={this.setSelectedPayoutMethod}>
        <input
          className="boxed-list__toggle"
          type="radio"
          value={PAYOUT_METHODS.ACH}
          defaultChecked={this.isAch()}
          name={this.nameForField('payout_method')}
          id={this.idForField(PAYOUT_METHODS.ACH)}
        />
        <label
          className="boxed-list__item boxed-list__item--selectable"
          htmlFor={this.idForField(PAYOUT_METHODS.ACH)}
          data-slide-down-target=".pay-out-to-bank-tips"
          data-slide-up-target=".pay-out-to-bucks-tips"
        >
          <p className="weight-bold mb-0">
            {I18n.t(
              'js.dashboard.selling.shopPolicies.directCheckoutProfile.payOutToBankAccount',
            )}
          </p>
          <ul
            className={classNames('normal small pay-out-to-bank-tips', {
              hidden: this.isReverbBank(),
            })}
          >
            <li
              id={this.formatPayoutOptionId()}
              dangerouslySetInnerHTML={{
                __html: this.achPayoutDescriptionText,
              }}
            />
          </ul>
        </label>
      </li>)
    );
  }

  remindMeLaterOption() {
    if (
      this.state.selectedPayoutMethod === PAYOUT_METHODS.ACH &&
      this.props.dcProfileComplete
    ) {
      return null;
    }
    return (
      /* disabling inline here so we can enable this rule globally, but this should be fixed the next time this file is touched */
      /* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */
      (<li onClick={this.setSelectedPayoutMethod}>
        <input
          className="boxed-list__toggle"
          type="radio"
          value={PAYOUT_METHODS.REVERB_BANK}
          defaultChecked={!this.isAch()}
          name={this.nameForField('payout_method')}
          id={this.idForField('reverb_bank')}
        />
        <label
          className="boxed-list__item boxed-list__item--selectable"
          htmlFor={this.idForField('reverb_bank')}
          data-slide-down-target=".pay-out-to-bucks-tips"
          data-slide-up-target=".pay-out-to-bank-tips"
        >
          <p className="weight-bold mb-0">{this.remindMeLaterTitle}</p>
          <ul
            className={classNames('normal small pay-out-to-bucks-tips', {
              hidden: this.isAch(),
            })}
          >
            <li
              dangerouslySetInnerHTML={{
                __html: this.remindMeLaterDescription,
              }}
            />
            <li>
              <div className="label-with-checkbox">
                <input
                  type="checkbox"
                  value="1"
                  defaultChecked={
                    this.props.profileAttributes.hasAcceptedReverbBankTerms
                  }
                  name={this.nameForField('accepted_terms_at')}
                  id="reverb-bucks-conversion-terms-checkbox"
                />
                <label htmlFor="reverb-bucks-conversion-terms-checkbox">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: this.acceptReverbBankTerms,
                    }}
                  />
                </label>
              </div>
            </li>
          </ul>
        </label>
      </li>)
    );
  }

  reverbBankandACHPayoutMethodOptions() {
    return (
      <div className="form-group">
        <div className="form-group__header">
          <label>
            {I18n.t(
              'js.dashboard.selling.shopPolicies.directCheckoutProfile.whereShouldYourEarningsGo',
            )}
          </label>
        </div>
        <div className="form-group__fields">
          <ul className="payout-form">
            {this.achPayoutOption()}
            {this.remindMeLaterOption()}
          </ul>
        </div>
      </div>
    );
  }

  canPayoutToReverbBank() {
    return (
      this.props.shopCurrency === CURRENCY_CODES.USD &&
      this.props.shopLocatedInUs
    );
  }

  payoutMethodsSection() {
    if (this.canPayoutToReverbBank()) {
      return this.reverbBankandACHPayoutMethodOptions();
    }
    return this.onlyACHPayoutMethod();
  }

  get achPayoutDescriptionText() {
    if (this.props.preferredSeller) {
      return I18n.t(
        'js.dashboard.selling.shopPolicies.directCheckoutProfile.achPayoutDescription.preferredSellerPayOutTimingMessage',
      );
    }
    if (this.props.hasCompletedPayouts) {
      return I18n.t(
        'js.dashboard.selling.shopPolicies.directCheckoutProfile.achPayoutDescription.payOutTimingMessageHtml',
        {
          linkOpen: `<a href='${
            UrlHelpers.reverbPaymentsFaqPath
          }' rel='noreferrer noopener' target='_blank'>`,
          linkClose: '</a>',
        },
      );
    }
    return I18n.t(
      'js.dashboard.selling.shopPolicies.directCheckoutProfile.achPayoutDescription.firstSalePayOutTimingMessageHtml',
      {
        linkOpen: `<a href='${
          UrlHelpers.reverbPaymentsFaqPath
        }' rel='noreferrer noopener' target='_blank'>`,
        linkClose: '</a>',
      },
    );
  }

  get remindMeLaterTitle() {
    return I18n.t(
      'js.dashboard.selling.shopPolicies.directCheckoutProfile.remindMeLater',
    );
  }

  get remindMeLaterDescription() {
    return I18n.t(
      'js.dashboard.selling.shopPolicies.directCheckoutProfile.remindMeLaterDescriptionHtml',
    );
  }

  get acceptReverbBankTerms() {
    return I18n.t(
      'js.dashboard.selling.shopPolicies.directCheckoutProfile.agreeToRemindMeLater',
    );
  }

  updateSelectedPayoutCurrency = (selectedPayoutCurrency) => {
    this.setState({ selectedPayoutCurrency });
    this.props.onCurrencyChange(selectedPayoutCurrency);
  };

  @bind
  async refetchDcp() {
    return refetchDirectCheckoutProfile(
      this.setState.bind(this),
      this.props.data?.refetch,
    );
  }

  @bind
  setPlaidModalOpen(callback) {
    return setPlaidModalOpenInSummaryView(this.setState.bind(this), callback);
  }

  @bind
  async setSummaryLoadingState(boolean) {
    return setDirectCheckoutProfileSummaryLoadingState(
      this.setState.bind(this),
      boolean,
    );
  }

  setSelectedPayoutMethod = (event) => {
    if (event.target.type === 'radio') {
      const selectedPayoutMethod = event.target.value;
      this.setState({ selectedPayoutMethod });
      trackEvent({
        eventName: MParticleEventName.SelectedPayoutMethod,
        payoutMethod: selectedPayoutMethod,
      });

      if (selectedPayoutMethod === PAYOUT_METHODS.ACH && !this.hasTrackedGetPaidView) {
        trackEvent({
          eventName: MParticleEventName.ViewedPaymentSettings,
          isPlaid: this.props.plaidProps?.plaidEnabled,
          pageName: MParticlePageName.GetPaid,
        });
        this.hasTrackedGetPaidView = true;
      }
    }
  };

  toggleEditing() {
    this.setState({
      isEditing: !this.state.isEditing,
    });
  }

  get hasDirectCheckoutProfileErrors() {
    return this.props.profileAttributes.profileErrors.length > 0;
  }

  get joinedDirectCheckoutProfileErrors() {
    return this.props.profileAttributes.profileErrors.join(' ');
  }

  isAch() {
    return this.state.selectedPayoutMethod === PAYOUT_METHODS.ACH;
  }

  isReverbBank() {
    return !this.isAch();
  }

  renderPaymentMethodErrors() {
    if (!this.hasDirectCheckoutProfileErrors) {
      return null;
    }

    if (this.props.plaidProps?.plaidEnabled) {
      return (
        <ErrorMessage
          alertStyle="alert-error alert-small"
          message={I18n.t('js.dashboard.selling.shopPolicies.directCheckoutProfile.connectWithPlaidErrorMessage')}
        />
      );
    }

    return (
      <ErrorMessage
        alertStyle="alert-error alert-small"
        message={this.joinedDirectCheckoutProfileErrors}
      />
    );
  }

  get showCollapsedAchSummary() {
    return !this.state.isEditing && this.props.dcProfileComplete;
  }

  get reverbBankSelected() {
    return !this.isAch() && this.canPayoutToReverbBank();
  }

  get isSummaryLoading() {
    return (this.props.data?.loading || this.state.loadingDcp) && (this.showCollapsedAchSummary || this.state.showPlaidSummary);
  }

  @bind
  setPlaidAccountStatus(plaidAccountStatus) {
    return setPlaidAccountStatus(this.setState.bind(this), plaidAccountStatus);
  }

  @bind
  setPendingPlaidAccountId(pendingPlaidAccountId) {
    return setPendingPlaidAccountId(this.setState.bind(this), pendingPlaidAccountId);
  }

  render() {
    const plaidAccountStatus  = this.state.plaidAccountStatus;
    const pendingPlaidAccountId  = this.state.pendingPlaidAccountId;
    const isPlaidDcp = isPlaid(this.props);

    const summaryConfig = {
      setSummaryLoadingState: this.setSummaryLoadingState,
      refetchDcp: this.refetchDcp,
      setPendingPlaidAccountId: this.setPendingPlaidAccountId,
      setPlaidModalOpenInSummaryView: this.setPlaidModalOpen,
      setPlaidAccountStatus: this.setPlaidAccountStatus,
    };

    return (
      <div>
        {this.renderPaymentMethodErrors()}
        <div className={this.props.modularFormContainerClass}>
          {this.payoutMethodsSection()}
        </div>
        {!this.reverbBankSelected && (
          (this.isSummaryLoading ? (
            <div className="d-flex fx-align-center fx-justify-center padding-4">
              <RCLoadingBars />
            </div>
          ) : (
            <PayoutBankAccountForm
              countryCodeOptions={this.props.countryCodeOptions}
              dcProfileComplete={this.props.dcProfileComplete}
              formPrefix={this.props.formPrefix}
              hideBankAccountEditForm={this.props.hideBankAccountEditForm}
              isGetPaid
              isPlaidDcp={isPlaidDcp}
              isPlaidEnabled={this.props.plaidProps?.plaidEnabled}
              onCountryChange={this.props.onCountryChange}
              onCurrencyChange={this.updateSelectedPayoutCurrency}
              pendingPlaidAccountId={pendingPlaidAccountId}
              plaidAccountStatus={plaidAccountStatus}
              profileAttributes={this.props.profileAttributes}
              selectedPayoutCurrency={this.state.selectedPayoutCurrency}
              shopCountryCode={this.props.shopCountryCode}
              shopCurrency={this.props.shopCurrency}
              showCollapsedAchSummary={this.showCollapsedAchSummary}
              showPlaidSummary={this.state.showPlaidSummary}
              summaryConfig={summaryConfig}
              toggleEditing={this.toggleEditing}
              payoutCurrencies={this.props.payoutCurrencies}
            />
          ))
        )}
      </div>
    );
  }
}

PayoutMethodOptions.propTypes = {
  profileAttributes: PropTypes.object.isRequired,
  payoutCurrencies: PropTypes.object.isRequired,
  hideBankAccountEditForm: PropTypes.bool,
  shopLocatedInUs: PropTypes.bool,
  dcProfileComplete: PropTypes.bool,
  preferredSeller: PropTypes.bool,
  hasCompletedPayouts: PropTypes.bool,
  countryCodeOptions: PropTypes.array.isRequired,
  formPrefix: PropTypes.string.isRequired,
  reverbBankCurrencies: PropTypes.array,
  selected: PropTypes.bool,
  shopCurrency: PropTypes.string.isRequired,
  onCountryChange: PropTypes.func,
  onCurrencyChange: PropTypes.func,
  modularFormContainerClass: PropTypes.string,
  modularBankAccountFormClass: PropTypes.string,
  user: PropTypes.object,
  plaidProps: PropTypes.object,
  shopCountryCode: PropTypes.string.isRequired,
};

PayoutMethodOptions.defaultProps = {
  onCountryChange: () => null,
  onCurrencyChange: () => null,
};

export default connect([withDirectCheckoutProfileQuery, withUserContext])(
  PayoutMethodOptions,
);
