import React from 'react';
import I18n from 'i18n-js';
import { ISuggestionGroup } from './suggestion_collection';
import Suggestion from './suggestion';

import { IUserContext, withUserContext } from '@reverbdotcom/commons/src/components/user_context_provider';
import RecentlyViewedListingPhotosRow from '../recently_viewed_listing_photos_row';

interface DropdownProps extends IUserContext {
  suggestions: ISuggestionGroup[];
  lastTypedValue: string;
  selectSuggestion: Function;
  selectedSuggestion: Suggestion;
  renderRecentlyViewed?: boolean;
  handleInputChange: Function;
}

export function Dropdown({
  suggestions,
  lastTypedValue,
  selectSuggestion,
  selectedSuggestion,
  renderRecentlyViewed = false,
  handleInputChange,
}: DropdownProps) {

  function renderSection(section, sectionIdx) {
    return (
      <div
        key={sectionIdx}
        className="site-search__dropdown__section"
      >
        {renderSectionHeading(section)}
        <div className="recent-searches__container">
          {section.results.map((r, idx) => renderSectionItem(r, idx))}
        </div>
      </div>
    );
  }

  function renderSectionHeading(section) {
    if (!section.heading) { return false; }
    if (!section.results.length) { return false; }

    return (
      <div className="site-search__dropdown__section__heading">
        {section.heading}
        {section.headingAction && (
          <button
            type="button"
            className="site-search__dropdown__section__heading__action"
            onClick={section.headingAction.action}
          >
            {section.headingAction.label}
          </button>
        )}
      </div>
    );
  }

  function renderSectionItem(suggestion, idx) {
    const isActive = suggestion.positionEqual(selectedSuggestion);

    return (
      <suggestion.component
        key={idx}
        suggestion={suggestion}
        active={isActive}
        lastTypedValue={lastTypedValue}
        selectSuggestion={selectSuggestion}
        handleInputChange={handleInputChange}
      />
    );
  }

  return (
    <div className="site-search__dropdown">
      {suggestions.map((s, idx) => renderSection(s, idx))}
      {renderRecentlyViewed && (
        <div className="site-search__dropdown__section">
          <div className="scaling-mb-2">
            <RecentlyViewedListingPhotosRow
              title={
                <div className="site-search__dropdown__section__heading">
                  {I18n.t('commons.recentlyViewed.title')}
                </div>
              }
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default withUserContext(Dropdown);
