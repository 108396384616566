import { ChildProps } from '@apollo/client/react/hoc';
import { gql } from '@reverbdotcom/commons/src/gql';

import React from 'react';
import I18n from 'i18n-js';
import classNames from 'classnames';
import { get, uniq, chain } from 'lodash';
import template from '@reverbdotcom/url-template';
import { RCCloseButton } from '@reverbdotcom/cadence/components';
import { withGraphql } from '@reverbdotcom/commons/src/with_graphql';

import {
  CoreSearchCompletionsQuery,
  reverb_search_CompletionType as CompletionType,
  reverb_search_SuggestOptionScope_Type as SuggestOptionScopeType,
} from '@reverbdotcom/commons/src/gql/graphql';

import { withUserContext, IUserContext, IUser } from '@reverbdotcom/commons/src/components/user_context_provider';
import { Paths, cspPath } from '../../url_helpers';
import
SuggestionCollection,
{ ISuggestionGroup }
  from './suggestion_collection';
import Suggestion, { CompletionOption } from './suggestion';
import Input from './input';
import Dropdown from './dropdown';
import CspCompletionCard from './csp_completion_card';
import bind from '@reverbdotcom/commons/src/bind';
import { ISiteBanner } from './reverb_site_search';
import { addCurrentPageSuggestion, buildRecentSearches } from './reverb_site_search_suggestions';
import SiteSearchSuggestion from './site_search_suggestion';
import { setItem, getItem } from '@reverbdotcom/commons/src/local_storage_utils';
import { trackEvent } from '@reverbdotcom/commons/src/elog/mparticle_tracker';
import { MParticleEventName } from '@reverbdotcom/commons/src/elog/mparticle_types';
import { IUrl } from '../../url_context';

export const SALE_SUGGESTION_TYPE = 'SALE';
export const RECENT_SEARCH = 'recentSearch';

const MINIMUM_INPUT_LENGTH = 2;
const MAX_AUTOCOMPLETE_TOTAL = 5;
const NEW_COMPLETION_CONFIG = [
  { completionType: CompletionType.PRODUCT_FAMILY, min: 1, max: 2 },
  { completionType: CompletionType.PRODUCT_TYPE, min: 1, max: 3 },
  { completionType: CompletionType.SUB_CATEGORY, min: 1, max: 3 },
  { completionType: CompletionType.BRAND, min: 1, max: 3 },
  { completionType: CompletionType.BRAND_MODEL, min: 1, max: 3 },
  { completionType: CompletionType.SHOP, min: 1, max: 1 },
];

interface ExternalProps {
  defaultValue: string;
  selectSuggestion: (user: IUser, suggestion: Suggestion, suggestions: Suggestion[], lastTypedValue: string) => void;
  submitQuery: (user: IUser, query: string, suggestions: Suggestion[], lastTypedValue: string) => void;
  fetchSuggestions: Function;
  placeholderText: string;
  siteBanner?: ISiteBanner;
  rounded?: boolean;
  url?: IUrl;
}

type ExternalAndUserProps = ExternalProps & IUserContext;
type IProps = ExternalAndUserProps;

interface IState {
  inputValue?: string;
  lastTypedValue?: string;
  open?: boolean;
  selectedSuggestion?: Suggestion;
  showDefault?: boolean;
  suggestionCollection?: SuggestionCollection;
  recentSearches?: SuggestionCollection;
}

export class SiteSearch extends React.Component<
  ChildProps<IProps, CoreSearchCompletionsQuery.Query>,
  IState
> {
  private wrapperRef: HTMLElement;

  private textInput: HTMLInputElement;

  constructor(props) {
    super(props);

    this.state = {
      inputValue: '',
      lastTypedValue: '',
      open: false,
      selectedSuggestion: null,
      showDefault: true,
      suggestionCollection: new SuggestionCollection([]),
      recentSearches: new SuggestionCollection([]),
    };
  }

  hydrateRecentSearches() {
    const recentSearches = buildRecentSearches(getItem('RECENT_SEARCHES') || []);
    this.setState({ recentSearches });
  }

  updateRecentSearches(query: string) {
    if (query.trim().length === 0) { return; }
    const existingRecentSearches = this.suggestionValues(this.state.recentSearches);
    const allSearches = [query, ...existingRecentSearches];
    const filteredSearches = uniq(allSearches).slice(0, 10);

    this.updateRecentSearchesInLocalStorage(filteredSearches);
    this.setState({ recentSearches: buildRecentSearches(filteredSearches) });
  }

  updateRecentSearchesInLocalStorage(queries) {
    setItem('RECENT_SEARCHES', queries);
  }

  hasMinimumLength(query) {
    return query.length >= MINIMUM_INPUT_LENGTH;
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
    this.hydrateRecentSearches();
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  componentDidUpdate(prevProps) {
    if (prevProps?.url?.query?.query != this.props?.url?.query?.query) {
      this.setState({
        showDefault: true,
        inputValue: '',
        lastTypedValue: '',
        selectedSuggestion: null,
      });
    }
  }

  // v3 suggestions, will slowly be strangled.
  fetchSuggestions(query): void {
    if (!this.hasMinimumLength(query)) {
      this.setState({
        suggestionCollection: new SuggestionCollection([]),
      });

      return;
    }

    this.props.fetchSuggestions(query).then((suggestionCollection) => {
      const groups = get(suggestionCollection, 'suggestions', []);

      if (groups.length === 0) {
        this.setState({
          suggestionCollection: new SuggestionCollection([this.suggestionGroup([], true)]),
        });

        return;
      }

      const suggestions = get(groups, '[0].results', []);

      this.addSaleSuggestion(suggestions);
      this.setState({ suggestionCollection });
    });
  }

  // rql completions: csp
  fetchCompletions(query: string): void {
    if (!this.hasMinimumLength(query)) return;

    this.props.data.refetch({ query, skip: false });
  }

  @bind
  handleClickOutside(event): void {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.toggleDropdown(false);
    }
  }

  @bind
  toggleDropdown(dir: boolean, inputValues: Partial<IState> = {}): void {
    this.setState({ open: dir, ...inputValues });
  }

  @bind
  suggestionCollection(): SuggestionCollection {
    if (this.shouldShowRecentSearches()) return new SuggestionCollection(this.state.recentSearches.suggestions);
    if (!this.hasMinimumLength(this.state.inputValue)) return new SuggestionCollection([]);

    const groups = [
      this.mainSuggestions(),
      this.cspSuggestions(),
    ]
      .reduce((acc, suggestions) => acc.concat(suggestions), [])
      .filter(group => group.results.length > 0);

    const collection = new SuggestionCollection(groups);

    collection.flatSuggestions.forEach((suggestion, idx) => suggestion.position = idx);

    return collection;
  }

  @bind
  handleInputChange(value): void {
    this.fetchCompletions(value);

    this.setState(
      {
        open: true,
        inputValue: value,
        lastTypedValue: value,
        selectedSuggestion: null,
        showDefault: false,
      },
      () => this.fetchSuggestions(value),
    );
  }

  @bind
  clearInputValue(): void {
    trackEvent({
      eventName: MParticleEventName.ClickedClearSiteSearch,
      query: this.state.inputValue,
    });
    this.textInput.focus();
    this.setState({
      inputValue: '',
      lastTypedValue: '',
      selectedSuggestion: null,
      showDefault: false,
    });
  }

  @bind
  selectSuggestion(selectedSuggestion: Suggestion): void {
    this.setState({
      inputValue: selectedSuggestion.value,
    });

    this.updateRecentSearches(selectedSuggestion.value);
    this.closeSuggestions();
    this.props.selectSuggestion(this.props.user, selectedSuggestion, this.suggestionCollection().flatSuggestions, this.state.lastTypedValue);
  }

  @bind
  handleSubmitClick(evt): void {
    evt.preventDefault();
    this.submitRawQuery(this.state.inputValue);
  }

  @bind
  submitRawQuery(query): void {
    if (!query) { return; }

    this.closeSuggestions();

    this.updateRecentSearches(query);

    this.setState({ showDefault: true });

    this.props.submitQuery(
      this.props.user, query,
      this.suggestionCollection().flatSuggestions,
      this.state.lastTypedValue,
    );
  }

  closeSuggestions(): void {
    this.toggleDropdown(false, {
      lastTypedValue: '',
      selectedSuggestion: null,
    });

    this.textInput.blur();
  }

  @bind
  setSelectedSuggestion(selectedSuggestion): void {
    this.setState({
      selectedSuggestion,
      inputValue: get(selectedSuggestion, 'value', ''),
    });
  }

  @bind
  bindInputRef(node) {
    if (!node) { return; }
    this.textInput = node.textInput;
  }

  @bind
  bindWrapperRef(node) {
    if (!node) { return; }
    this.wrapperRef = node;
  }

  shouldShowRecentSearches(): boolean {
    const { inputValue, selectedSuggestion } = this.state;

    if (inputValue.length === 0) {
      return true;
    }

    if (selectedSuggestion && selectedSuggestion.type === RECENT_SEARCH) {
      return true;
    }

    return false;
  }

  shouldShowRecentlyViewedListingPhotos(): boolean {
    return this.shouldShowRecentSearches() && this.state.open;
  }

  @bind
  mainSuggestions(): ISuggestionGroup[] {
    const coreSuggestions = get(this.state.suggestionCollection, 'suggestions', [this.suggestionGroup()]);

    const newCspSuggestions = this.newCspSuggestions();
    if (newCspSuggestions[0].results.length >= 1) {
      return newCspSuggestions;
    }
    return coreSuggestions;
  }

  addSaleSuggestion(suggestions: Suggestion[]) {
    if (!this.props.siteBanner) return;

    const { saleUrl, heading } = this.props.siteBanner;
    if (!saleUrl || !heading) return;

    const query = this.state.lastTypedValue.trim();

    suggestions.unshift(
      new Suggestion(
        {
          url: template.parse(`${saleUrl}{?query*}`).expand({ query: { query } }),
          filter_value: heading,
          type: SALE_SUGGESTION_TYPE,
          value: query,
        },
        SiteSearchSuggestion,
      ),
    );
  }

  @bind
  cspSuggestions(): ISuggestionGroup[] {
    if (!this.props.data.completions) return [this.suggestionGroup()];

    const cspCompletion = this.props.data.completions.completions.find(c => c.type === 'CSP');
    if (!cspCompletion) return [];

    // Ensure we're only showing CSPs that have inventory
    const cspsWithListings = cspCompletion.options
      .filter(c => c.completionPayload.inventoryNew.listingCount + c.completionPayload.inventoryUsed.listingCount > 0)
      .slice(0, 5);

    const suggestions = cspsWithListings.map((o) => {
      return new Suggestion(
        {
          type: CompletionType.CSP,
          completion: o,
          value: o.output,
          url: cspPath(o.slug),
        },
        CspCompletionCard,
      );
    });

    return [this.suggestionGroup(suggestions)];
  }

  suggestionGroup(suggestions = [], withSale = false): ISuggestionGroup {
    if (withSale) this.addSaleSuggestion(suggestions);

    return { heading: null, results: suggestions };
  }

  suggestionValues(suggestions: SuggestionCollection): string[] {
    return suggestions.flatSuggestions.map((suggestion) => suggestion.value);
  }

  suggestions(): ISuggestionGroup[] {
    if (this.shouldShowRecentSearches()) {
      return get(this.state.recentSearches, 'suggestions', []);
    }

    return get(this.suggestionCollection(), 'suggestions', []);
  }

  flatSuggestions(): Suggestion[] {
    if (this.shouldShowRecentSearches()) {
      return this.state.recentSearches.flatSuggestions;
    }

    return get(this.suggestionCollection(), 'flatSuggestions', []);
  }

  value(): string {
    let value;

    if (this.state.showDefault) {
      value = (this.state.inputValue || this.props.defaultValue);
    } else {
      value = this.state.inputValue;
    }

    return value || '';
  }

  renderDropdown() {
    const suggestions = this.suggestions();
    if (suggestions.length === 0) return null;

    return (
      <Dropdown
        suggestions={suggestions}
        lastTypedValue={this.state.lastTypedValue}
        selectedSuggestion={this.state.selectedSuggestion}
        selectSuggestion={this.selectSuggestion}
        handleInputChange={this.handleInputChange}
        renderRecentlyViewed={this.shouldShowRecentlyViewedListingPhotos()}
      />
    );
  }

  renderClearButton() {
    if (!this.state.inputValue && !this.state.showDefault) return;
    if (!this.props.defaultValue && this.state.showDefault) return;

    return (
      <span className="site-search__controls__clear">
        <RCCloseButton
          onClick={this.clearInputValue}
        />
      </span>
    );
  }

  @bind
  newCspSuggestions(): ISuggestionGroup[] {
    if (!this.props.data.completions) return [this.suggestionGroup([], true)];

    let total = 0;

    const buildSuggestions = ({ completionType, min, max }) => {
      const suggestions = this.suggestionsBy(completionType, max);
      total += suggestions.length;

      return { suggestions, min };
    };

    const resize = (suggestions, min) => {

      const excess = total - MAX_AUTOCOMPLETE_TOTAL;

      if (excess > 0 && suggestions.length > min) {
        const size = suggestions.length;
        const offset = size - min;
        const hasExcess = excess >= offset;

        total -= hasExcess ? offset : size - (size - excess);
        suggestions = suggestions.slice(0, hasExcess ? min : size - excess);
      }

      return suggestions;
    };

    const sizedSuggestionsGroups = NEW_COMPLETION_CONFIG
      .map(buildSuggestions)
      .reduceRight((acc, { suggestions, min }) => [resize(suggestions, min)].concat(acc), []);

    const suggestionsGroups = chain(this.orderMainSuggestions(sizedSuggestionsGroups))
      .flatten()
      .uniqBy(s => s.value + s.context)
      .value();

    addCurrentPageSuggestion(suggestionsGroups, this.state.lastTypedValue.trim());
    this.addSaleSuggestion(suggestionsGroups);

    return [this.suggestionGroup(suggestionsGroups)];
  }

  orderMainSuggestions(groups: Suggestion[][]): Suggestion[][] {
    const inputs = this.state.lastTypedValue.trim().split(' ');

    return groups
      .map(group => {
        const score = inputs.reduce((score, input) => {
          const word = input.toLowerCase();
          const inc = group.some(sugg => sugg.normalizeValue.includes(word)) ? 1 : 0;

          return score + inc;
        }, 0);

        return { score, group };
      })
      .sort((a, b) => b.score - a.score)
      .map(({ group }) => group);
  }

  suggestionsBy(completionType: CompletionType, limit: number): Suggestion[] {
    const completion = this.props.data.completions.completions.find(c => c.type === completionType);
    if (!completion) return [];

    let completions;

    if (completionType === CompletionType.SHOP) {
      completions = completion.options.slice(0, limit);
    } else {
      completions = completion.options.slice(0, limit).filter(f => {
        const productTypeScope = this.scopesBy(SuggestOptionScopeType.PRODUCT_TYPE, f);
        return productTypeScope && productTypeScope.output !== 'Parts';
      });
    }

    const mainSuggestions = completions.map((o) => {
      return this.buildMainSuggestion(o, completionType, this.suggestionUrl(o, completionType));
    });

    if (mainSuggestions.length <= 1 && ![CompletionType.PRODUCT_FAMILY, CompletionType.SHOP].includes(completionType)) return [];

    return mainSuggestions;
  }

  scopesBy(scopeType: SuggestOptionScopeType, option: CompletionOption) {
    return option.scopes.filter(s => s.type === scopeType)[0];
  }

  suggestionUrl(option: CompletionOption, completionType: CompletionType): string {
    const make = (params) => Paths.marketplace.expand(params);
    const productTypeScope = this.scopesBy(SuggestOptionScopeType.PRODUCT_TYPE, option);
    const productTypeSlug = productTypeScope?.slug;

    let url;
    let brandScope;

    switch (completionType) {
      case CompletionType.FREE_TEXT:
      case CompletionType.PRODUCT_FAMILY:
        brandScope = this.scopesBy(SuggestOptionScopeType.BRAND, option);
        url = make({ query: option.output, product_type: productTypeSlug, make: brandScope.slug });
        break;
      case CompletionType.BRAND_MODEL:
        url = make({ query: option.output, product_type: productTypeSlug });
        break;
      case CompletionType.BRAND:
        url = make({ make: option.slug, product_type: productTypeSlug });
        break;
      case CompletionType.PRODUCT_TYPE:
        url = make({ product_type: option.slug });
        break;
      case CompletionType.SUB_CATEGORY:
        url = make({ product_type: productTypeSlug, category: option.slug });
        break;
      case CompletionType.SHOP:
        url = Paths.shop.expand({ shopSlug: option.slug });
        break;
      default:
        url = make({ query: option.output });
    }

    return url;
  }

  buildMainSuggestion(option, completionType, url) {
    let filterValue;

    if (completionType === CompletionType.SHOP) {
      filterValue = I18n.t('commons.searchBar.context.shops');
    } else {
      const productTypeScope = this.scopesBy(SuggestOptionScopeType.PRODUCT_TYPE, option);
      filterValue = productTypeScope?.output;
    }

    return new Suggestion(
      {
        url,
        filter_value: filterValue,
        type: completionType,
        completion: option,
        value: option.output,
      },
      SiteSearchSuggestion,
    );
  }

  render() {
    const flatSuggestions = this.flatSuggestions();
    const classnames = classNames(
      'site-search',
      { 'site-search--active': this.state.open }, // TODO we might be able to use :focus-within here now
      { 'site-search--rounded': this.props.rounded },
    );

    const underlayClassnames = classNames(
      'site-search__underlay',
      // show when input is focused and there's either recent searches or typed chars length is > 1
      {
        'site-search__underlay--active': this.state.open &&
          (flatSuggestions.length > 0 || this.state.inputValue.length > 0),
      },
    );

    return (
      <div
        className={underlayClassnames}
        onClick={() => { }} // necessary to ensure mobile safari registers a tap and dismisses search input focus
      >
        <div className={classnames} ref={this.bindWrapperRef}>
          <div className="site-search__controls">
            <Input
              ref={this.bindInputRef}
              value={this.value()}
              handleInputChange={this.handleInputChange}
              toggleDropdown={this.toggleDropdown}
              suggestions={flatSuggestions}
              setSelectedSuggestion={this.setSelectedSuggestion}
              submit={this.submitRawQuery}
              selectSuggestion={this.selectSuggestion}
              selectedSuggestion={this.state.selectedSuggestion}
              placeholderText={this.props.placeholderText}
              aria-label={I18n.t('commons.siteSearch.placeholder')}
              user={this.props.user}
            />
            {this.renderClearButton()}
            {/* disabling inline here so we can enable this rule globally, but this should be fixed the next time this file is touched */}
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a
              className="site-search__controls__submit"
              rel="submit-form"
              onClick={this.handleSubmitClick}
            >
            </a>
          </div>
          {this.renderDropdown()}
        </div>
      </div>
    );
  }
}


export const SITE_SEARCH_COMPLETIONS_QUERY = gql(`query Core_Search_CompletionsQuery(
  $query: String
  $skip: Boolean!
) {
  completions(
    input: {
      query: $query
    }
  ) @skip(if: $skip) {
    completions {
      text
      type
      options {
        score
        slug
        id
        text
        output
        scopes {
          output
          slug
          type
        }
        completionPayload {
          thumbnailUrl
          inventoryNew {
            listingCount
            listingMinPrice {
              cents
              currency
            }
          }
          inventoryUsed {
            listingCount
            listingMinPrice {
              cents
              currency
            }
          }
        }
      }
    }
  }
}`);


const connect = withGraphql<ExternalAndUserProps, CoreSearchCompletionsQuery.Query>(
  SITE_SEARCH_COMPLETIONS_QUERY,
  {
    options: ({ defaultValue }) => {
      return {
        ssr: false, // not run on page load
        variables: {
          query: defaultValue,
          skip: true,
        },
      };
    },
  },
);

export default withUserContext<ExternalProps>(connect(SiteSearch));
