import { ChildProps } from '@apollo/client/react/hoc';
// TODO update this to import from commons/src/gql
// eslint-disable-next-line no-restricted-imports
import { gql } from '@apollo/client';
import React from 'react';
import { compact, times } from 'lodash';
import classNames from 'classnames';

import { withGraphql } from '../../with_graphql';
import { IDynamicComponentProps } from '../dynamic_component_props';
import { MParticleEvent, MParticleEventName } from '../../elog/mparticle_types';
import { trackCSPView } from '../../elog/mparticle_tracker';
import { useViewTracking } from '../../use_tracking';
import {
  Commons_Cms_CspTilesQuery,
  reverb_search_CSPSearchRequest_Sort,
  core_apimessages_ContentSponsorship_SponsorshipType,
} from '../../gql/graphql';
import { CSPCardFragment } from '../../components/csp_row';
import SanitizedRender from '../../components/sanitized_render';
import CoreLink from '../../components/core_link';
import MarketingCalloutCard from './marketing_callout_card';
import { imageUploadURL } from '../../url_helpers';
import CSPSquareCard from '../../components/csp_square_card';

const COMPONENT_NAME = 'CMSCspTiles';

const TOP_ROW_CSP_COUNT = 4;
const TOP_ROW_WITH_CALLOUT_CSP_COUNT = 2;
const BOTTOM_ROW_CSP_COUNT = 6;

const CSP_SORT_DEFAULT = reverb_search_CSPSearchRequest_Sort.TRENDING_DESC;

function categoryUuidsFromProps(props: IProps): string[] {
  return props.categoryId ? [props.categoryId] : [];
}

type QueryCsps = Commons_Cms_CspTilesQuery['cspSearch']['csps'][0];

interface FeaturedCalloutProps {
  title: string;
  titleHtml?: string;
  subtitle?: string;
  subtitleHtml?: string;
  image: string;
  targetHref: string;
  sponsoredContentType?: core_apimessages_ContentSponsorship_SponsorshipType;
  sponsoredContentPartner?: string;
  targetCountries?: string[];
}

interface CSPRowProps {
  loading: boolean;
  csps: QueryCsps[],
  startIdx: number,
  endIdx: number,
  hideOnMobile: boolean,
  trackingQuery: string,
  filterParams: Object;
}

export interface IProps extends IDynamicComponentProps {
  title?: string;
  titleHtml?: string;
  subtitle?: string;
  subtitleHtml?: string;
  ctaText?: string;
  ctaTargetHref?: string;
  query?: string;
  make?: string[];
  categoryId?: string;
  condition?: ConditionFilter;
  countryOfOrigin?: string;
  sort?: reverb_search_CSPSearchRequest_Sort;
  traits?: string[];
  cspIds?: string[];
  trackingName?: string;
  yearMin?: number;
  yearMax?: number;
  dealsAndSteals?: boolean;
  onSale?: boolean;
  freeShipping?: boolean;
  conditionNew?: boolean;
  conditionUsed?: boolean;
  zeroPercentFinancing?: boolean;
  featuredCallouts?: FeaturedCalloutProps[];
}

type ConditionFilter = 'new' | 'used';

const CSP_LISTING_FILTERS = {
  dealsAndSteals: 'deals_and_steals=true',
  onSale: 'on_sale=true',
  freeShipping: 'free_shipping=true',
  conditionNew: 'condition=new',
  conditionUsed: 'condition=used',
  zeroPercentFinancing: 'zero_percent_financing=true',
};

function trackingName({ trackingName }: IProps): string {
  return trackingName ? trackingName : COMPONENT_NAME;
}

function getFeaturedCallout({ featuredCallouts }: IProps): FeaturedCalloutProps {
  return featuredCallouts && featuredCallouts[0];
}

function csps(ownProps): QueryCsps[] {
  return ownProps.data?.cspSearch?.csps || [];
}

function filterParams(props: IProps): Object {
  const urlParams = Object.keys(CSP_LISTING_FILTERS).reduce((all, filter) => {
    if (props[filter]) all.push(CSP_LISTING_FILTERS[filter].split('='));
    return all;
  }, []);

  if (!urlParams.length) return null;

  return Object.fromEntries(urlParams);
}

function buildCtaClickEvent(props: IProps): MParticleEvent {
  return {
    eventName: MParticleEventName.ClickedCspTilesCta,
    componentName: trackingName(props),
    entityId: props.ctaTargetHref,
  };
}

function CtaTile(props: IProps) {
  return (
    <div className="g-col-4 g-col-tablet-12 g-col-mobile-12 mb-gutter d-flex">
      <div className="csp-tiles__cta">
        <div>
          {(props.subtitleHtml || props.subtitle) &&
            <span className="csp-tiles__cta-subtitle">
              <SanitizedRender
                html={props.subtitleHtml || props.subtitle}
              />
            </span>
          }
          <h2 className="csp-tiles__cta-title">
            <SanitizedRender
              html={props.titleHtml || props.title}
            />
          </h2>

          <CoreLink
            className="csp-tiles__cta-link"
            to={props.ctaTargetHref}
            clickEvent={buildCtaClickEvent(props)}
          >
            {props.ctaText}
          </CoreLink>
        </div>
      </div>
    </div>
  );
}

function MobileCtaLink(props: IProps) {
  return (
    <div className="csp-tiles__mobile-cta">
      <CoreLink
        className="csp-tiles__mobile-cta-link"
        to={props.ctaTargetHref}
        clickEvent={buildCtaClickEvent(props)}
      >
        {props.ctaText}
      </CoreLink>
    </div>
  );
}

function FeaturedCallout(props: IProps) {
  const callout = getFeaturedCallout(props);
  if (!callout) return null;

  return (
    <div className="g-col-4 g-col-tablet-6 g-col-mobile-12 mb-gutter d-flex">
      <MarketingCalloutCard
        key={callout.title}
        imageURL={imageUploadURL(callout.image)}
        subtitle={callout.subtitleHtml || callout.subtitle}
        targetHref={callout.targetHref}
        title={callout.titleHtml || callout.title}
        trackingComponentName={trackingName(props)}
        sponsoredContentType={callout.sponsoredContentType}
        sponsoredContentPartner={callout.sponsoredContentPartner}
      />
    </div>
  );
}

function CspTileRow({ startIdx, endIdx, hideOnMobile, loading, csps, trackingQuery, filterParams }: CSPRowProps) {
  const expectedCount = endIdx - startIdx;
  const className = classNames(
    'csp-tile-row',
    'g-col-2 g-col-tablet-3 g-col-mobile-6 mb-gutter d-flex',
    {
      'mobile-d-none': hideOnMobile,
      'tablet-d-none': hideOnMobile,
    },
  );

  if (loading || !csps.length) {
    return (
      <>
        {times(expectedCount, (idx) => {
          return (
            <div key={startIdx + idx} className={className}>
              <CSPSquareCard />
            </div>
          );
        })}
      </>
    );
  }

  return (
    <>
      {csps.slice(startIdx, endIdx).map(csp => (
        <div key={csp.id} className={className}>
          <CSPSquareCard
            csp={csp}
            trackingQuery={trackingQuery}
            filterParams={filterParams}
            hideCallout={!!filterParams}
          />
        </div>
      ))}
    </>
  );
}

export function CspTiles(props: ChildProps<IProps, Commons_Cms_CspTilesQuery>) {
  const topRowEndIdx = getFeaturedCallout(props) ? TOP_ROW_WITH_CALLOUT_CSP_COUNT : TOP_ROW_CSP_COUNT;
  const bottomRowStartIdx = topRowEndIdx;
  const bottomRowEndIdx = bottomRowStartIdx + BOTTOM_ROW_CSP_COUNT;
  const cspListingFilterParams = filterParams(props);

  const cspResults = csps(props);

  useViewTracking(
    trackCSPView(cspResults, trackingName(props)),
    !props.data.loading && !!cspResults.length,
  );

  return (
    <div className="site-wrapper">
      <div className="csp-tiles">
        <div className="g-container g-container--flex fx-wrap">
          <CtaTile {...props} />
          <CspTileRow
            loading={props.data.loading}
            csps={cspResults}
            startIdx={0}
            endIdx={topRowEndIdx}
            hideOnMobile={false}
            trackingQuery={trackingName(props)}
            filterParams={cspListingFilterParams}
          />
          <MobileCtaLink {...props} />
          <FeaturedCallout {...props} />

          <CspTileRow
            loading={props.data.loading}
            csps={cspResults}
            startIdx={bottomRowStartIdx}
            endIdx={bottomRowEndIdx}
            hideOnMobile={true}
            trackingQuery={trackingName(props)}
            filterParams={cspListingFilterParams}
          />
        </div>
      </div>
    </div>
  );
}

export const withQuery = withGraphql<IProps, Commons_Cms_CspTilesQuery>(
  gql`
    query Commons_Cms_CspTiles(
      $ids: [String]
      $fullTextQuery: String
      $brandNames: [String]
      $condition: String
      $countryOfOrigin: [String]
      $categoryUuids: [String]
      $sort: reverb_search_CSPSearchRequest_Sort
      $traitSlugs: [String]
      $yearMin: Int
      $yearMax: Int
    )
    {
      cspSearch(input: {
        ids: $ids
        fullTextQuery: $fullTextQuery
        brandNames: $brandNames
        condition: $condition
        countryOfOrigin: $countryOfOrigin
        categoryUuids: $categoryUuids
        sort: $sort
        traitSlugs: $traitSlugs
        yearMin: $yearMin
        yearMax: $yearMax
        withInventory: true
      }) {
        csps {
          _id
          ...CSPCard
          metaDescription
          summary
          brand {
            _id
            name
          }
        }
      }
    }
    ${CSPCardFragment}
  `,
  {
    options: (ownProps) => {
      return {
        ssr: ownProps.ssr,
        variables: {
          ids: ownProps.cspIds || [],
          fullTextQuery: ownProps.query,
          brandNames: ownProps.make,
          condition: ownProps.condition,
          countryOfOrigin: compact([ownProps.countryOfOrigin]),
          categoryUuids: categoryUuidsFromProps(ownProps),
          sort: ownProps.sort || CSP_SORT_DEFAULT,
          traitSlugs: ownProps.traits,
          yearMin: ownProps.yearMin,
          yearMax: ownProps.yearMax,
        },
      };
    },
  },
);

export default withQuery(CspTiles);
