import React from 'react';

const UNSUPPORTED_METHODS = ['PUT', 'DELETE', 'PATCH'];

interface IState {
  token?: string;
}

export default class CsrfForm extends React.Component<React.HTMLProps<HTMLFormElement>, IState> {
  state: IState = { token: '' };

  componentDidMount() {
    this.setState({
      token: this.csrfToken(),
    });
  }

  csrfToken() {
    const csrfMetaTag = document.querySelector('meta[name=csrf-token]');

    if (csrfMetaTag) {
      return (csrfMetaTag as HTMLMetaElement).content;
    }

    return '';
  }

  unsupportedMethod() {
    return UNSUPPORTED_METHODS.indexOf(this.props.method.toUpperCase()) > -1;
  }

  methodInput() {
    if (this.unsupportedMethod()) {
      return <input type="hidden" name="_method" value={this.props.method} />;
    }
  }

  method() {
    if (this.unsupportedMethod()) {
      return 'POST';
    }

    return this.props.method;
  }

  render() {
    return (
      <form {...this.props} method={this.method()}>
        <input type="hidden" name="authenticity_token" value={this.state.token} />
        {this.methodInput()}
        {this.props.children}
      </form>
    );
  }
}
