import React, { useContext } from 'react';
import classNames from 'classnames';
import I18n from 'i18n-js';

import { Filter, WidgetType } from './search_filters';
import MarketplaceFilterRow from './marketplace_filter_row';
import { RCChip, RCPopover, RCText } from '@reverbdotcom/cadence/components';
import SelectFacet from './select_facet';
import { I18N } from './translate';
import { Location } from 'history';
import ListViewToggle from './list_view_toggle';
import TextInputFacet from './text_input_facet';
import SanitizedRender from './sanitized_render';
import GridRowWithInputChips, { InputChipFilterOption } from './grid_row_with_input_chips';
import { getFiltersForSidebar } from '../grid_helpers';
import CouponContext from '@reverbdotcom/commons/src/coupon_context';
import ShopCouponSearchToggle from '../components/ShopCouponSearchToggle';

interface ISearchOverviewListViewToggleProps {
  loading: boolean,
  filters: Filter[],
  setListViewState: (val: boolean) => void
}

interface IProps {
  filters: Filter[];
  hideFilters?: boolean;
  followButton?: React.ReactNode;
  tipText?: string | React.ReactNode;
  totalText?: string;
  titleOverride?: string;
  loading?: boolean;
  resultsTotal?: number;
  trackingQuery?: string;
  location: Location;
  setListViewState?: (val: boolean) => void;
  showListViewToggle?: boolean;
  hideClearFiltersButton?: boolean;
  useH1Title?: boolean;
  pinTextQuery?: boolean; // ensure search bar always visible above grid, instead of buried within filters
  query?: string;
  hideGridTitle?: boolean;
  toggleExposedSidebarOpen: () => void;
  showNewMobileSidebar: boolean;
  isFilterOpenByDefault?: (filter: Filter) => boolean;
  modifyRemovableFilterChips?: (filterChips: InputChipFilterOption[]) => InputChipFilterOption[];
  clearFiltersUrl?: string;
  invertFiltersAndCountOrder?: boolean;
}

export function LoadingTitle() {
  return (
    <div>
      <div className="search-overview__loading-text" />
      <div className="size-70 mt-1">
        <div className="search-overview__loading-text" />
      </div>
    </div>
  );
}

// Rewrite of existing Overview component for use in marketplace_filter_row experiment
export default function RQLSearchOverview({
  filters = [],
  hideFilters = false,
  followButton = null,
  totalText = '',
  tipText = '',
  loading = false,
  resultsTotal = null,
  trackingQuery = '',
  titleOverride = null,
  location = null,
  setListViewState = undefined,
  showListViewToggle = false,
  hideClearFiltersButton = false,
  useH1Title = false,
  pinTextQuery = false,
  query = '',
  hideGridTitle = false,
  toggleExposedSidebarOpen = undefined,
  showNewMobileSidebar = false,
  isFilterOpenByDefault = undefined,
  modifyRemovableFilterChips = undefined,
  clearFiltersUrl = undefined,
  invertFiltersAndCountOrder = false,
}: IProps) {
  const sort = filters.find(filter => filter.widgetType === WidgetType.SORT);
  const classes = classNames(
    'search-overview',
    'search-overview--new',
    { 'search-overview--loading': loading },
  );

  const canRenderQueryTitle = !!query && resultsTotal !== null;
  const hasSufficientTitlePropsForSSR = !!titleOverride || canRenderQueryTitle;
  const showLoadingState = loading && filters.length === 0 && !hasSufficientTitlePropsForSSR;
  const textQueryFilter = filters.find((f) => f.key === 'TEXT_QUERY');
  const showTextQueryBoxOverGrid = pinTextQuery && textQueryFilter;
  const filterInputChips = getFiltersForSidebar(filters);
  const { coupon } = useContext(CouponContext);

  return (
    <nav className={classes}>
      <section className="search-overview__pinned-query-coupon-switch-container">
        {coupon &&
          <div className="search-overview__coupon-switch-container">
            <ShopCouponSearchToggle coupon={coupon} />
          </div>
        }
        {showTextQueryBoxOverGrid && (
          <div className="search-overview__pinned-query-container">
            <div className="search-overview__pinned-query">
              <TextInputFacet
                pageSection="marketplace-pinned-query"
                filter={textQueryFilter}
                trackingQuery={trackingQuery}
                placeholder={I18n.t(`commons.marketplaceFilters.pinnedTextQuery.${location?.pathname?.includes('/shop') ? 'shop' : 'other'}`)}
                hideLabel
              />
            </div>
          </div>
        )}
      </section>
      <div className={classNames({ 'search-overview__info-container-inverted': invertFiltersAndCountOrder })}>
        <section className={classNames({
          'mt-12': invertFiltersAndCountOrder,
          'mb-4': !invertFiltersAndCountOrder,
          'mobile-d-none': hideGridTitle,
        })}>
          <div className="d-flex fx-justify-between fx-align-start gap-2 tablet-mb-4 tablet-pb-4">
            <div>
              {!hideGridTitle && showLoadingState && (
                <LoadingTitle />
              )}
              {!hideGridTitle && !showLoadingState && (
                <>
                  <RCText.Title
                    size="700"
                    weight={query ? 'regular' : 'bold'}
                    htmlTag={useH1Title ? 'h1' : 'h2'}
                  >
                    <Title
                      filters={filters}
                      resultsTotal={resultsTotal}
                      totalText={totalText}
                      titleOverride={titleOverride}
                      query={query}
                    />
                  </RCText.Title>
                  <span className="color-secondary mt-2 d-inline-block">{tipText}</span>
                  {followButton}
                </>
              )}
            </div>
            <div className="d-flex fx-align-end gap-2">
              {showListViewToggle && (
                <SearchOverviewListViewToggle
                  loading={loading}
                  setListViewState={setListViewState}
                  filters={filters}
                />
              )}
              <div className="search-overview__feed">
                <SortChip
                  loading={loading}
                  sort={sort}
                  trackingQuery={trackingQuery}
                />
              </div>
            </div>
          </div>
        </section>
        {!hideFilters &&
        <div className="d-flex fx-dir-col gap-2 mobile-mb-4 mobile-pb-4 mobile-bdb-1 bd-primary">
          <div className="d-flex fx-justify-between fx-align-start gap-2">
            <MarketplaceFilterRow
              filters={filters}
              loading={loading}
              trackingQuery={trackingQuery}
              displayCount
              location={location}
              resultsTotal={resultsTotal}
              hideOnMobile
              dynamicTitle
              setListViewState={setListViewState}
              showListViewToggle={showListViewToggle}
              hideClearFiltersButton={hideClearFiltersButton}
              pinTextQuery={pinTextQuery}
              toggleExposedSidebarOpen={toggleExposedSidebarOpen}
              showNewMobileSidebar={showNewMobileSidebar}
              isFilterOpenByDefault={isFilterOpenByDefault}
              clearFiltersUrl={clearFiltersUrl}
            />
          </div>
          <div className="d-none mobile-d-block">
            <GridRowWithInputChips filters={filterInputChips}
              modifyRemovableFilterChips={modifyRemovableFilterChips} />
          </div>
        </div>
        }
      </div>

    </nav>
  );
}

export function Title({ filters, resultsTotal, totalText, titleOverride, query }) {
  if (titleOverride) {
    return titleOverride;
  }

  const queryFilter = filters.filter(f => {
    return f.key === 'TEXT_QUERY';
  });

  const queryStringFromFilter = queryFilter && queryFilter.length > 0
    ? queryFilter[0].options[0].optionValue
    : null;

  /* On server side renders we will default to using the query string while client side renders
  will pull from the filter query to ensure the title is properly updated */
  const queryString = queryStringFromFilter || query;

  if (queryString && resultsTotal) {
    return (
      <SanitizedRender
        html={
          I18n.t('commons.marketplaceFilters.resultsCountForStyled', {
            count: I18n.toNumber(resultsTotal, { precision: 0 }),
            queryString,
            tagOpen: '<strong>',
            tagClose: '</strong>',
          })
        }
      />
    );
  }

  if (resultsTotal) {
    return (
      <I18N
        text="commons.marketplaceFilters.resultsCount"
        args={{ count: I18n.toNumber(resultsTotal, { precision: 0 }) }}
      />
    );
  }

  return totalText;
}

export function SortChip({ loading, sort, trackingQuery }) {
  const [isPopoverOpen, openPopover] = React.useState(false);

  if (loading && !sort) {
    return (
      <div className="mobile-d-none">
        <RCChip
          key="sort"
          loading
        >
          <div className="filter-chip__loading-placeholder" />
        </RCChip>
      </div>
    );
  }
  
  if (!sort) return null;

  const selected = sort?.options.find(opt => opt.selected);
  return (
    <div className="mobile-d-none">
      <RCPopover
        isOpen={isPopoverOpen}
        onDismiss={() => openPopover(false)}
        loading={loading}
        trapFocus
        anchor={
          <RCChip
            loading={loading}
            onClick={() => openPopover(!isPopoverOpen)}
          >
            <span>
              <span className="weight-normal">{`${sort.name}: `}</span>
              {selected?.name || ''}
            </span>
          </RCChip>
        }
      >
        <SelectFacet
          pageSection="search-overview"
          filter={sort}
          trackingQuery={trackingQuery}
        />
      </RCPopover>
    </div>
  );
}

function SearchOverviewListViewToggle({ loading, filters, setListViewState }: ISearchOverviewListViewToggleProps) {
  return (
    <div className="search-overview__list-view-toggle">
      {(loading && filters.length === 0) ? (
        <div className="search-overview__list-view-toggle-loading-placeholder" />
      ) :
        (
          <ListViewToggle
            setListViewState={setListViewState}
            loading={loading}
          />
        )}
    </div>
  );
}
