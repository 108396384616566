export const TIN_FORMATTING_HELP_CENTER_ARTICLE_ID: string = '23084478133139';
export const EU_SELLER_TAX_INFO_HELP_CENTER_ARTICLE_ID: string = '23177144554771';
export const INDIVIDUAL_TYPE: string = 'individual';
export const BUSINESS_TYPE: string = 'business';
export const INFORM_ACT_HELP_CENTER_ARTICLE_ID: string = '22671226454675';
export const UK_SELLER_TAX_INFO_HELP_CENTER_ARTICLE_ID: string = '32315335372691';
export const CA_SELLER_TAX_INFO_HELP_CENTER_ARTICLE_ID: string = '34509141120147';

export const CANADA = 'CA';
export const UNITED_KINGDOM = 'GB';
export const EU_MARKETPLACE_TAX_COUNTRY_CODES = [
  'AT',
  'BE',
  'BG',
  'CY',
  'CZ',
  'DE',
  'DK',
  'EE',
  'GR',
  'ES',
  'FI',
  'FR',
  'HR',
  'HU',
  'IE',
  'IT',
  'LT',
  'LU',
  'LV',
  'MT',
  'NL',
  'PL',
  'PT',
  'RO',
  'SE',
  'SI',
  'SK',
];
