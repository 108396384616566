export default {
  recommends: 'Reverb Recommends',
  topPick: 'Top Pick',
  reverbNews: 'Reverb News',
  treatYourself: 'Treat Yourself',
  seeSimilar: 'See Similar {{category}}',
  seeMore: 'See More',
  addToCart: 'Add to Cart',
  listingSellerLockedModeAlert: {
    lockedModeTitle: 'Locked Mode',
    lockedModeDescription: 'We need a few more details to verify your business. Reverb Support will be in contact with you via email to unlock your account. {{children}}',
    lockedModeHelpLinkText: 'Visit our help center for more info.',
  },
};
