import React from 'react';
import ConditionDisplay from '@reverbdotcom/commons/src/components/condition_display';
import { CoreBumpRecommendationListingCard } from '@reverbdotcom/commons/src/gql/graphql';

interface Props {
  listing: CoreBumpRecommendationListingCard.Fragment;
}

export function ListingPreview(props: Props) {
  return (
    <div className="d-flex fx-align-center">
      {
        props.listing.images[0] && <img
          className="d-block width-20"
          alt={props.listing.title}
          src={props.listing.images[0]?.source}
        />
      }
      <div className="ml-2 fx-grow">
        <h3 className="size-110 weight-bold mb-2">
          {props.listing.title}
        </h3>
        {/* TODO wrap this in what classes necessary to get it left aligned and please leave a comment about it...
        Design team is working on a prop or better default for this to not necessitate that anymore */}
        <ConditionDisplay
          slug={props.listing.condition.conditionSlug}
          displayName={props.listing.condition?.displayName}
        />
      </div>
    </div>
  );
}
