import React from 'react';
import I18n from 'i18n-js';

import CoreLink from '../../components/core_link';
import SanitizedRender from '../../components/sanitized_render';

import { imageUploadURL } from '../../url_helpers';

export interface IProps {
  headingHtml?: string;
  subheading?: string;
  bodyHtml?: string;
  ctaText: string;
  ctaTargetHref: string;
  imageKey?: string;
  jumplinkSlug?: string;
  sponsoredContentPartner?: string;
}

export default class ImageFeature extends React.Component<IProps, null> {
  imageURI() {
    return imageUploadURL(this.props.imageKey);
  }

  getBackgroundImageStyle() {
    if (!this.props.imageKey) return null;

    return {
      style: { backgroundImage: `url('${this.imageURI()}')` },
    };
  }

  renderSponsoredContent() {
    if (!this.props.sponsoredContentPartner) return null;

    return (
      <div
        className="size-90 style-italic mtb-2 weight-bold"
      >
        {I18n.t('cms.imageFeature.sponsoredContentPartner', { partner: this.props.sponsoredContentPartner })}
      </div>
    );
  }

  renderLinkOrImage() {
    if (this.props.ctaTargetHref) {
      return (
        <CoreLink
          className="image-feature__image"
          to={this.props.ctaTargetHref}
          {... this.getBackgroundImageStyle()}
        />
      );
    }

    return (
      <div
        className="image-feature__image"
        {... this.getBackgroundImageStyle()}
      />
    );
  }

  render() {
    return (
      <div
        className="image-feature"
        id={this.props.jumplinkSlug}
      >
        <div className="image-feature__inner">
          {this.renderLinkOrImage()}
          <div className="image-feature__text">
            <div className="image-feature__text__inner">
              {this.props.subheading &&
                <h4
                  className="image-feature__subheading"
                >
                  {this.props.subheading}
                </h4>
              }
              <SanitizedRender
                htmlTag="h3"
                className="image-feature__heading"
                html={this.props.headingHtml}
                inlineTagsOnly
                blocklistedTags={['a']}
              />
              {this.renderSponsoredContent()}
              <SanitizedRender
                htmlTag="p"
                className="image-feature__body"
                html={this.props.bodyHtml}
                inlineTagsOnly
              />
              {this.props.ctaTargetHref && this.props.ctaText &&
                <CoreLink
                  className="button button--primary"
                  to={this.props.ctaTargetHref}
                >
                  {this.props.ctaText}
                </CoreLink>
              }
            </div>
          </div>
        </div>
      </div>
    );
  }
}
