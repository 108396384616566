import { ComponentClass, StatelessComponent } from 'react';

import { Core_Search_CompletionsQueryQuery } from '@reverbdotcom/commons/src/gql/graphql';

interface IFilter {
  params: Record<string, any>;
  url: string;
  value: string;
}

export type CompletionOption = Core_Search_CompletionsQueryQuery['completions']['completions'][0]['options'][0];

export interface ISuggestionData {
  filter_value: string;
  filters: IFilter[];
  params: Record<string, any>;
  type: string;
  url: string;
  value: string;
  position?: number;
  completion?: CompletionOption;
}

export interface IComponentProps {
  active: boolean;
  lastTypedValue?: string;
  suggestion: Suggestion;
  selectSuggestion: Function;
  handleInputChange: Function;
}

type Component = ComponentClass<any> | StatelessComponent<any>;

export default class Suggestion {
  private data: Partial<ISuggestionData> = {
    value: '',
    filters: [],
    filter_value: '',
    type: '',
    url: '',
    params: {},
  };

  private _position: number;

  public component: Component;

  constructor(
    data: Partial<ISuggestionData>,
    component: Component,
  ) {
    this.data = Object.assign(this.data, data);
    this.component = component;
    this._position = this.data.position === undefined ? null : this.data.position;
  }

  get params(): Record<string, any> {
    return this.data.params;
  }

  get context(): string {
    return this.data.filter_value;
  }

  get url(): string {
    return this.data.url;
  }

  get value(): string {
    return this.data.value;
  }

  get normalizeValue(): string {
    return `${this.value} ${this.context}`.toLowerCase();
  }

  get type(): string {
    return this.data.type;
  }

  get position(): number {
    return this._position;
  }

  set position(position) {
    this._position = position;
  }

  get completion(): CompletionOption {
    return this.data.completion;
  }

  // returns an array that includes this suggestion and
  // all of its sub-suggestions
  get filters(): Suggestion[] {
    const allFilters = [];
    allFilters.push(this);

    this.data.filters.forEach((filter) => {
      const suggestion = new Suggestion(
        ({

          ...filter,
          type: this.type,
          value: this.value,
          filter_value: filter.value,
        }),
        this.component,
      );

      allFilters.push(suggestion);
    });

    return allFilters;
  }

  positionEqual(other: Suggestion): boolean {
    if (!other) return false;

    return this.position === other.position;
  }
}
