import I18n from 'i18n-js';

export type Variant =
  | 'sign-in'
  | 'sign-up'
  | 'continue';

type Provider =
  | 'apple'
  | 'facebook'
  | 'google';

export function titleText({ provider, variant }: { provider: Provider, variant: Variant }) {
  switch (variant) {
    case 'sign-up':
      return I18n.t(`commons.thirdPartyAuth.${provider}.signUp`);
    case 'sign-in':
      return I18n.t(`commons.thirdPartyAuth.${provider}.signIn`);
    default:
      return I18n.t(`commons.thirdPartyAuth.${provider}.continue`);
  }
}
