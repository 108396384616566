import React from 'react';
import I18n from 'i18n-js';
import classNames from 'classnames';
import { Location } from 'history';

import CoreLink from './core_link';
import { getCategory, getSubcategory } from '../csp_category_helper';
import { CSP } from '../gql/graphql';
import { RCImage } from '@reverbdotcom/cadence/components';
import { MParticleEvent } from '../elog/mparticle_types';
import {
  BrandMetaData,
  NameMetaData,
  DescriptionMetaData,
  ImageMetaData,
  UrlMetaData,
} from '../cms/lib/metadata_helper';
import { isItemPage } from '../url_helpers';
import { inWeb } from '../user_context_helpers';
import { useUser } from '../user_hooks';
import { ReviewMetadata } from './reviews_metadata';

interface IProps {
  csp?: CSP;
  position?: number;
  hideMetadata?: boolean;
  includeItemListMetadata?: boolean;
  renderAsHorizontal?: boolean;
  location?: Location;
  destinationPath?: string;
  clickEvent?: MParticleEvent;
  children?: JSX.Element;
  hideFinishes?: boolean;
  header?: string | JSX.Element | React.ReactNode;
}

function Category({ csp, location }) {
  if (!csp.categories) { return null; }

  const isFilteringCategory = !!location?.query?.product_type;
  const isFilteringSubcategory = !!location?.query?.category;
  const category = getCategory(csp);
  const subCategory = getSubcategory(csp);

  return (
    <div className="csp-square-card__category">
      {!isFilteringCategory && category?.name &&
        <span>
          {category.name}
        </span>
      }
      {!isFilteringSubcategory && subCategory?.name &&
        <span>
          {subCategory.name}
        </span>
      }
    </div>
  );
}

function Title({ csp, location, showMetadata }) {
  return (
    <div
      className="csp-square-card__titleblock"
    >
      <div className="csp-square-card__eyebrow">
        {csp.brand?.name}
      </div>
      <h3
        itemProp="model"
        className="csp-square-card__title"
      >
        {csp.title.replace(csp.brand?.name, '')}
      </h3>
      <Category csp={csp} location={location} />
      {showMetadata &&
        <div>
          <BrandMetaData csp={csp} />
          <NameMetaData csp={csp} />
          <DescriptionMetaData csp={csp} />
          <ImageMetaData csp={csp} />
          <ReviewMetadata totalReviews={csp?.reviewsCount} averageRating={csp?.averageReviewRating} />
          <UrlMetaData csp={csp} />
        </div>
      }
    </div>
  );
}

interface ImageAndFinishIndicatorProps {
  csp: CSP;
  hideFinishes?: boolean;
}

export function ImageAndFinishIndicator({ csp, hideFinishes }: ImageAndFinishIndicatorProps) {
  return (
    <>
      <RCImage
        src={csp.image?.source}
        overlay="offwhiteWithHover"
        borderRadius="md"
      />
      {!hideFinishes && csp.finishes?.length > 1 &&
        <div className="csp-square-card__finish-indicator">
          {I18n.t('discovery.cardNudge.multipleFinishes')}
        </div>
      }
    </>
  );
}

function SquareCardInner(props: IProps) {
  return (
    <>
      { props.header && !props.renderAsHorizontal &&
        <div className="csp-square-card__header">
          {props.header}
        </div>
      }
      <div className="csp-square-card__main">
        <ImageAndFinishIndicator csp={props.csp} hideFinishes={props.hideFinishes} />
        {!props.renderAsHorizontal &&
          <Title
            csp={props.csp}
            location={props.location}
            showMetadata={!props.hideMetadata}
          />
        }
      </div>
      <div
        className="csp-square-card__details"
      >
        {props.renderAsHorizontal &&
          <Title
            csp={props.csp}
            location={props.location}
            showMetadata={!props.hideMetadata}
          />
        }
        {props.children}
      </div>
    </>
  );
}

export function SquareCard(props: IProps) {
  const getItemMetadata = () => {
    if (props.hideMetadata) return;

    return {
      itemProp: props.includeItemListMetadata ? 'itemListElement' : null,
      itemScope: true,
      itemType: 'http://schema.org/Product',
    };
  };

  const classes = classNames(
    'csp-square-card height-100',
    { 'csp-square-card--horizontal': props.renderAsHorizontal },
  );

  const user = useUser();
  const openNewTab = isItemPage(props.location) && inWeb(user);

  return (
    <article
      className={classes}
      {...getItemMetadata()}
      data-rc-image-overlay-trigger
    >
      {props.destinationPath ?
        <CoreLink
          className="csp-square-card__inner"
          to={props.destinationPath}
          clickEvent={props.clickEvent}
          entityId={props.csp.id}
          position={props.position}
          target={openNewTab ? '_blank' : undefined}
        >
          <SquareCardInner {...props} />
        </CoreLink>
        :
        <div className="csp-square-card__inner">
          <SquareCardInner {...props} />
        </div>
      }
    </article>
  );
}
