import React from 'react';
import classNames from 'classnames';
import CoreLink from '@reverbdotcom/commons/src/components/core_link';
import { RCIcon, RCButton, RCTextWithIcon } from '@reverbdotcom/cadence/components';
import PercentageBar from '@reverbdotcom/commons/src/components/percentage_bar';
import { I18N } from '@reverbdotcom/commons/src/components/translate';
import {
  QuickShipperIcon,
  QuickResponderIcon,
  PreferredSellerIcon,
  AngleDownIcon,
  AngleUpIcon,
} from '@reverbdotcom/cadence/icons/react';

interface BadgeStep {
  label: string;
  labelLink?: string;
  complete?: boolean;
  note?: string;
}

interface BadgeProps {
  icon: 'quick_shipper' | 'quick_responder' | 'preferred_seller';
  title: string;
  steps: BadgeStep[];
  extraWide?: boolean;
}

interface BadgesProps {
  badges: BadgeProps[];
}

const icons = {
  quick_shipper: QuickShipperIcon,
  quick_responder: QuickResponderIcon,
  preferred_seller: PreferredSellerIcon,
};

function ShopBadgeStatus({ icon, title, steps, extraWide }: BadgeProps) {
  const totalSteps = steps.length;
  const completeSteps = steps.filter(step => step.complete).length;
  const [stepsOpen, toggleSteps] = React.useState(false);

  return (
    <div className={classNames(
      'shop-badge-status', {
        'shop-badge-status--complete': completeSteps === totalSteps,
        'shop-badge-status--step-open': stepsOpen,
        'shop-badge-status--extra-wide': extraWide,
      },
    )}>
      <div className="shop-badge-status__inner">
        <h3 className="shop-badge-status__title">
          <RCIcon svgComponent={icons[icon]} />
          {title}
        </h3>
        <div className="shop-badge-status__percentage">
          <PercentageBar
            percentage={(completeSteps / totalSteps) * 100}
            color="green"
          />
          <p className="shop-badge-status__percentage-label">
            <I18N
              text="commons.sellerBadgeStatus.completed"
              args={{
                completed: completeSteps.toString(),
                total: totalSteps.toString(),
              }}
            />
          </p>
        </div>
        <div className="shop-badge-status__toggle">
          <RCButton
            variant="muted"
            size="mini"
            onClick={() => toggleSteps(!stepsOpen)}
          >
            <RCTextWithIcon svgComponent={stepsOpen ? AngleUpIcon : AngleDownIcon}>
              {stepsOpen ? (
                <I18N text="commons.sellerBadgeStatus.hideRequirements" />
              ) : (
                <I18N text="commons.sellerBadgeStatus.showRequirements" />
              )}
            </RCTextWithIcon>
          </RCButton>
        </div>
        <ol className="shop-badge-status__steps">
          {steps.map(({ label, labelLink, complete, note }, index) => (
            <li
              key={label}
              className={classNames(
                'shop-badge-status__step', {
                  'shop-badge-status__step--complete': complete,
                },
              )}
            >
              <span className="shop-badge-status__step-label">
                {complete &&
                  <span className="visually-hidden">
                    <I18N
                      text="commons.sellerBadgeStatus.stepNumberCompleted"
                      args={{ number: (index + 1).toString() }}
                    />
                  </span>
                }
                {labelLink ? (
                  <CoreLink
                    className="shop-badge-status__step-link"
                    to={labelLink}
                  >
                    {label}
                  </CoreLink>
                ) : (
                  <>
                    {label}
                  </>
                )}
              </span>
              {note && (
                <span className="shop-badge-status__step-note">
                  {note}
                </span>
              )}
            </li>
          ))}
        </ol>
      </div>
    </div>
  );
}

export function ShopBadgeStatuses({ badges }: BadgesProps) {
  return (
    <div className="shop-badge-statuses">
      {badges.map(badge => (
        <ShopBadgeStatus
          key={badge.title}
          {...badge}
          extraWide={badges.length === 2}
        />
      ))}
    </div>
  );
}
