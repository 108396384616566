import { ChildProps } from '@apollo/client/react/hoc';
import { MutationFunction } from '@reverbdotcom/commons/src/useHOCMutation';
// TODO update this to import from commons
// import { gql } from '@reverbdotcom/commons/src/gql';
// eslint-disable-next-line no-restricted-imports
import { gql } from '@apollo/client';
import React from 'react';
import I18n from 'i18n-js';
import { get } from 'lodash';
import { RCLoadingBars, RCAlertBox } from '@reverbdotcom/cadence/components';
import { flashSuccess, flashError } from '@reverbdotcom/commons/src/flash';
import FormGroupWithCheckbox from '@reverbdotcom/commons/src/components/form_group_with_checkbox';
import {
  CoreUserSubscriptions,
  UpdateEmailSubscription,
  rql_EmailSubscription,
  rql_SubscribeState,
  SetEmailGlobalSubscribe,
  rql_EmailSubscription_TranslationKey,
  TriggerOptInEmail,
} from '@reverbdotcom/commons/src/gql/graphql';
import { usePageViewTracking } from '@reverbdotcom/commons/src/use_tracking';
import {
  MParticlePageName,
  MParticleEventName,
  trackEvent,
} from '@reverbdotcom/commons/src/elog/mparticle_tracker';
import { withGraphql } from '@reverbdotcom/commons/src/with_graphql';
import { connect, EmptyExternalProps } from '@reverbdotcom/commons/src/connect';

interface ApolloProps {
  updateEmailSubscription: MutationFunction<UpdateEmailSubscription.Mutation, UpdateEmailSubscription.Variables>;
  updateGlobalSubscription: MutationFunction<SetEmailGlobalSubscribe.Mutation, SetEmailGlobalSubscribe.Variables>;
  triggerOptInEmail: MutationFunction<TriggerOptInEmail.Mutation, TriggerOptInEmail.Variables>;
}

type IProps = ChildProps<CoreUserSubscriptions.Query> & ApolloProps;

const {
  NEWSLETTER,
  SELLER_NEWSLETTER,
  FEED,
  WATCH,
  OFFERS_AND_PROMOTIONS,
} = rql_EmailSubscription_TranslationKey;

const reverbUpdatesSubscriptionGroups = [NEWSLETTER, SELLER_NEWSLETTER, OFFERS_AND_PROMOTIONS];
const yourGearSubscriptionGroups = [FEED, WATCH];
const OPTED_OUT_CALLOUT_KEY = 'optedOutCallout';
const OPT_IN_BUTTON_KEY = 'globalOptIn';
const UNSUBSCRIBED_CALLOUT_KEY = 'unsubscribedCallout';
const SUBSCRIBE_BUTTON_KEY = 'globalSubscribe';
const UNSUBSCRIBE_BUTTON_KEY = 'globalUnsubscribe';
const TRIGGER_OPT_IN_EMAIL_TIMEOUT = 5000;

export function Subscriptions({ data, updateEmailSubscription, updateGlobalSubscription, triggerOptInEmail }: IProps) {
  const userEmailSubscriptions = get(data, 'me.emailSubscriptions', []) || [];
  const userGlobalEmailSubscribe = get(data, 'me.emailGlobalSubscribe', '');
  const userRequiresOptIn = get(data, 'me.requireOptIn', false);
  const userGloballySubscribed = userGlobalEmailSubscribe === rql_SubscribeState.SUBSCRIBED;
  const userGloballyUnsubscribed = userGlobalEmailSubscribe === rql_SubscribeState.UNSUBSCRIBED;
  const userGloballyOptedIn = userGlobalEmailSubscribe === rql_SubscribeState.OPTED_IN;
  const disableSubscriptionToggles = data.loading || !!data.error || userGloballyUnsubscribed;

  const reverbUpdatesCategory = {
    categoryName: 'reverbUpdates',
    subscriptionGroups: userEmailSubscriptions.filter(subscription => reverbUpdatesSubscriptionGroups.includes(subscription.translationKey)),
  };
  const yourGearCategory = {
    categoryName: 'yourGear',
    subscriptionGroups: userEmailSubscriptions.filter(subscription => yourGearSubscriptionGroups.includes(subscription.translationKey)),
  };

  const onChangeSubscriptionGroup = React.useCallback(async (subscription: rql_EmailSubscription) => {
    const updatedSubscribedState = !subscription.subscribed;
    const input = {
      id: subscription.id,
      subscribed: updatedSubscribedState,
      translationKey: subscription.translationKey,
    };

    const response = await updateEmailSubscription({
      variables: { input },
    });

    if (response.errors) {
      flashError(I18n.t('discovery.subscriptions.errorOnUpdate'));
    } else {
      flashSuccess(I18n.t('discovery.subscriptions.success'));
      trackEvent({
        eventName: MParticleEventName.ChangedSubscriptionGroupStatus,
        subscriptionGroup: subscription.translationKey,
        subscriptionGroupState: updatedSubscribedState,
      });
    }
  }, [updateEmailSubscription]);

  const onChangeGlobalStatus = React.useCallback(async () => {
    const state = userGloballySubscribed || userGloballyOptedIn ? rql_SubscribeState.UNSUBSCRIBED : rql_SubscribeState.SUBSCRIBED;
    const triggerOptIn = userRequiresOptIn && state === rql_SubscribeState.SUBSCRIBED;

    const response = await updateGlobalSubscription({
      variables: { input: { state } },
    });

    if (response.errors) {
      flashError(I18n.t('discovery.subscriptions.errorOnUpdate'));
    } else {
      flashSuccess(I18n.t('discovery.subscriptions.success'));
      trackEvent({
        eventName: MParticleEventName.ChangedGlobalSubscriptionStatus,
        globalSubscriptionState: state,
      });

      if (triggerOptIn) {
        setTimeout(
          () => {
            triggerOptInEmail({
              variables: { input: {} },
            });
          },
          TRIGGER_OPT_IN_EMAIL_TIMEOUT,
        );
      }
    }
  }, [userGloballySubscribed, userGloballyOptedIn, userRequiresOptIn, updateGlobalSubscription, triggerOptInEmail]);

  const onTriggerOptInEmail = React.useCallback(async () => {
    const response = await triggerOptInEmail({
      variables: { input: {} },
    });

    if (response.errors) {
      flashError(I18n.t('discovery.subscriptions.errorOnOptInEmailTrigger'));
    } else {
      flashSuccess(I18n.t('discovery.subscriptions.optInEmailTriggerSuccess'));
      trackEvent({
        eventName: MParticleEventName.TriggeredOptInEmail,
      });
    }
  }, [triggerOptInEmail]);

  const renderCalloutAndActionButton = (callout: string, buttonCta: string, onClick: () => void) => (
    <div className="bdt-1 bd-primary">
      {!!callout && <h3 className="cms-h3">{I18n.t(`discovery.subscriptions.${callout}`)}</h3>}
      <div className="scaling-mb-8 mt-4">
        <button
          className="button button--primary"
          type="button"
          onClick={onClick}
        >
          {I18n.t(`discovery.subscriptions.${buttonCta}`)}
        </button>
      </div>
    </div>
  );

  usePageViewTracking({
    pageName: MParticlePageName.EmailPreferences,
  }, null);

  return (
    <div className="max-width-50 tablet-max-width-70 mobile-max-width-100">
      <h2 className="cms-h1">
        {I18n.t('discovery.subscriptions.title')}
      </h2>
      <p className="form-section__description">
        {I18n.t('discovery.subscriptions.generalDescription')}
      </p>
      {!!data.error &&
        <RCAlertBox type="error" small>
          {I18n.t('discovery.subscriptions.errorOnLoad')}
        </RCAlertBox>
      }
      {data.loading ? (
        <div className="ptb-8">
          <RCLoadingBars />
        </div>
      ) : (
        <>
          {userGloballySubscribed && userRequiresOptIn && renderCalloutAndActionButton(OPTED_OUT_CALLOUT_KEY, OPT_IN_BUTTON_KEY, onTriggerOptInEmail)}
          {userGloballyUnsubscribed && renderCalloutAndActionButton(UNSUBSCRIBED_CALLOUT_KEY, SUBSCRIBE_BUTTON_KEY, onChangeGlobalStatus)}
          {
            [reverbUpdatesCategory, yourGearCategory].map(category => (
              <div key={category.categoryName} className="bdt-1 bd-primary">
                <h3 className="cms-h2">{I18n.t(`discovery.subscriptions.categories.${category.categoryName}`)}</h3>

                {category.subscriptionGroups.map(subscription => (
                  <div className="scaling-mb-8" key={subscription.id}>
                    <FormGroupWithCheckbox
                      display="indentedSwitch"
                      inputName={subscription.translationKey}
                      disabled={disableSubscriptionToggles}
                      checked={subscription.subscribed}
                      updateField={() => onChangeSubscriptionGroup(subscription)}
                      label={I18n.t(`discovery.subscriptions.emails.${subscription.translationKey}`)}
                      helpText={I18n.t(`discovery.subscriptions.descriptions.${subscription.translationKey}`)}
                    />
                  </div>
                ))}
              </div>
            ))
          }
          {!userGloballyUnsubscribed && renderCalloutAndActionButton('', UNSUBSCRIBE_BUTTON_KEY, onChangeGlobalStatus)}
        </>
      )}
    </div>
  );
}

const subscriptionsQuery = withGraphql<IProps, CoreUserSubscriptions.Query>(
  gql`
  query Core_User_Subscriptions {
    me {
      _id
      uuid
      requireOptIn
      emailSubscriptions {
        id
        subscribed
        translationKey
      }
      emailGlobalSubscribe
    }
  }
`,
  {
    options: {
      // This used to be ssr: true implicitly in this component, and it was rendered
      // by a parent Rails/HAML component that sets prerender: true (equivalent to ssr: true).
      // It was changed to ssr: false because the query makes direct calls to the external Braze API,
      // which result in ssr timeouts more frequently than internal API calls.
      ssr: false,
    },
  },
);

const updateEmailSubscription =
  withGraphql<IProps, UpdateEmailSubscription.Mutation, UpdateEmailSubscription.Variables>(
    gql`
      mutation Update_Email_Subscription($input: Input_rql_EmailSubscription) {
        setEmailSubscription(input: $input) {
          id
          subscribed
          translationKey
        }
      }
    `,
    {
      name: 'updateEmailSubscription',
    },
  );

const updateGlobalSubscription =
  withGraphql<IProps, SetEmailGlobalSubscribe.Mutation, SetEmailGlobalSubscribe.Variables>(
    gql`
      mutation Set_Email_Global_Subscribe($input: Input_rql_SetGlobalSubscribeRequest) {
        setEmailGlobalSubscribe(input: $input) {
          _id
          uuid
          emailGlobalSubscribe
        }
      }
    `,
    {
      name: 'updateGlobalSubscription',
    },
  );

const triggerOptInEmail =
  withGraphql<IProps, TriggerOptInEmail.Mutation, TriggerOptInEmail.Variables>(
    gql`
      mutation Trigger_Opt_In_Email($input: Input_rql_Blank) {
        triggerOptInEmail(input: $input) {
          dispatchId
        }
      }
    `,
    {
      name: 'triggerOptInEmail',
    },
  );

export default connect<EmptyExternalProps, IProps>([
  subscriptionsQuery,
  updateEmailSubscription,
  updateGlobalSubscription,
  triggerOptInEmail,
])(Subscriptions);
