import React from 'react';
import classNames from 'classnames';
import { snakeCase, orderBy } from 'lodash';
import { withRouter, WithRouterProps } from 'react-router';

import CollapsingList from './collapsing_list';
import { SelectFacetOption } from './select_facet_option';
import { FilterProps, filterID, WidgetType } from './search_filters';
import { RCAccordion } from '@reverbdotcom/cadence/components';

const COLLAPSE_THRESHOLD = 7;


export function SelectFacet({
  filter,
  pageSection,
  trackingQuery,
  collapsible = false,
  defaultOpen = false,
  location,
  onToggleCallback,
}: FilterProps & WithRouterProps) {
  const labelId = `${pageSection}--${filterID(filter)}-lbl`;

  const isTraitFilter = filter.aggregationName === 'TRAITS';
  const parentNameOverride = isTraitFilter ? snakeCase(filter.name) : undefined;

  let filterOptionsOrderedBySelected = filter.options;
  if (filter.widgetType === WidgetType.CHECKBOX) {
    filterOptionsOrderedBySelected = orderBy(filter.options, ['selected'], ['desc']);
  }

  const filterOptions = (
    <CollapsingList
      countThreshold={isTraitFilter ? 3 : COLLAPSE_THRESHOLD}
      renderInFilterList
    >
      {filterOptionsOrderedBySelected.map((option) => (
        <SelectFacetOption
          key={option.name}
          option={option}
          checkbox={filter.widgetType === WidgetType.CHECKBOX}
          trackingQuery={trackingQuery}
          parentNameOverride={parentNameOverride}
          location={location}
          enableZeroCountOption={option.paramName === 'condition'}
        />
      ))}
    </CollapsingList>
  );

  const classes = classNames('facet', { 'facet--collapsible': collapsible });
  const anySelected = filterOptionsOrderedBySelected.some(opt => (opt.selected && !opt.all));

  return (
    <fieldset className={classes}>
      <RCAccordion
        id={labelId}
        heading={filter.name}
        defaultOpen={!collapsible || defaultOpen || anySelected}
        onToggle={onToggleCallback}
        locked={!collapsible}
        zeroPadding
      >
        {filterOptions}
      </RCAccordion>
    </fieldset>
  );
}

export default withRouter(SelectFacet);
