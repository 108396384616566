import { ChildProps } from '@apollo/client/react/hoc';
// TODO update this to import from commons
// import { gql } from '@reverbdotcom/commons/src/gql';
// eslint-disable-next-line no-restricted-imports
import { gql } from '@apollo/client';
import React from 'react';
import { CorePacklinkAccountStatus, core_apimessages_PacklinkAccount_Status as AccountStatus } from '@reverbdotcom/commons/src/gql/graphql';
import { RCLoadingBars } from '@reverbdotcom/cadence/components';
import { withGraphql } from '@reverbdotcom/commons/src/with_graphql';

interface ExternalProps {
  onStatusUpdate(status: AccountStatus): void;
}

type Props = ChildProps<ExternalProps, CorePacklinkAccountStatus.Query>;

export function PacklinkAccountStatus(props: Props) {
  const status = props.data?.me?.shop?.packlink?.account?.status;

  useStatusUpdate(status, props.onStatusUpdate);

  return <RCLoadingBars />;
}

export function useStatusUpdate(status: AccountStatus, updateStatus?: (status: AccountStatus) => void) {
  React.useEffect(() => {
    updateStatus(status);
  }, [status, updateStatus]);
}

const withQuery = withGraphql<ExternalProps, {}>(
  gql`
  query Core_Packlink_AccountStatus {
    me {
      _id
      uuid
      shop {
        _id
        packlink {
          account {
            email
            status
          }
        }
      }
    }
  }`,
  {
    options: {
      fetchPolicy: 'network-only',
      pollInterval: 500,
      ssr: false, // only rendered for logged in users after clicking a link to open a modal
    },
  },
);

export default withQuery(PacklinkAccountStatus);
