import React from 'react';
import I18n from 'i18n-js';
import { debounce } from 'lodash';

import { PriceDisplay } from '@reverbdotcom/commons/src/components/PriceDisplay';
import { RCTooltip } from '@reverbdotcom/commons';
import { parseAmount, parseAmountCents } from '@reverbdotcom/commons/src/money';
import { CA } from '@reverbdotcom/commons/src/constants';
import { useHelpCenterArticleUrl } from '@reverbdotcom/commons/src/url_helpers';
import { I18N } from '@reverbdotcom/commons/src/components/translate';
import { RCAlertBox } from '@reverbdotcom/cadence/components';
import { MParticleEventName, trackEvent, EventType } from '@reverbdotcom/commons/src/elog/mparticle_tracker';

import { IShopConfig } from '../SellFormContext';
import { filterVatCountries } from '../../../shop_settings_vat_layout';

interface IProps {
  price?: string;
  currency?: string;
  preferredSeller?: boolean;
  sellingFeeWaived?: boolean;
  shopConfig?: IShopConfig;
  showIncVatPricingHint?: boolean;
}

enum priceKeys {
  SELLING_FEE = 'sellingFee',
  PROCESSING_FEE = 'processingFee',
  ESTIMATED_EARNINGS = 'estimatedEarnings',
}

// Selling Fee is static for estimation purposes
const SELLING_FEE_PERCENT = 0.05;
const PAYMENT_PROCESSING_FEE_PERCENT = 0.0319;
const PAYMENT_PROCESSING_FEE_PREFERRED_SELLER_PERCENT = 0.0299;
const PAYMENT_PROCESSING_FEE_AMOUNT_CENTS = 49;

// Source of truth: https://github.com/reverbdotcom/reverb/blob/master/app/reverb/billing/fee_calculator.rb
const MINIMUM_SELLING_FEE_CENTS = 50;
const CURRENCY_TO_MAXIMUM_FEE_CENTS = {
  AUD: 71500,
  CAD: 68000,
  EUR: 50000,
  GBP: 50000,
  JPY: 50000,
  MXN: 1100000,
  NZD: 50000,
  USD: 50000,
  DKK: 325000,
  SEK: 450000,
  CHF: 50000,
};
const MINIMUM_PRICE_CENTS = 99;
const TOOLTIP_EVENT_DELAY = 3000;
const VAT_ID_HC_ARTICLE = '15478546545171';
const COMPONENT_NAME = 'PriceBreakdown';

function getSellingFee(priceAmountCents: number, currency: string): number {
  const sellingFeeCents = priceAmountCents * SELLING_FEE_PERCENT;
  const minimumBoundedFee = Math.max(sellingFeeCents, MINIMUM_SELLING_FEE_CENTS);
  const maximumFeeInCurrency = CURRENCY_TO_MAXIMUM_FEE_CENTS[currency];

  return maximumFeeInCurrency ? Math.min(minimumBoundedFee, maximumFeeInCurrency) : minimumBoundedFee;
}

function formatEstimatedEarnings(priceAmountCents: number, feesAmountCents: number[]) {
  return feesAmountCents.reduce((acc, currentFee) => {
    return acc - currentFee;
  }, priceAmountCents);
}

function paymentProcessingFeePercent(preferredSeller = false) {
  return preferredSeller ? PAYMENT_PROCESSING_FEE_PREFERRED_SELLER_PERCENT : PAYMENT_PROCESSING_FEE_PERCENT;
}

function isVatCountry(shopConfig) {
  const address = shopConfig?.address ?? {};

  return !!filterVatCountries([{
    countryCode: address.countryCode,
  }]).length || address.countryCode === CA;
}

function getTooltip(field, preferredSeller, currency) {
  switch (field) {
    case priceKeys.SELLING_FEE:
      return (
        <>
          <p className="mb-1">
            {I18n.t('discovery.sellForm.pricingSection.priceBreakdown.sellingFee.tooltip.youGet', {
              feePercent: SELLING_FEE_PERCENT * 100,
            })}
          </p>
          <ul className="price-breakdown_bulleted-list">
            <li>
              {I18n.t('discovery.sellForm.pricingSection.priceBreakdown.sellingFee.tooltip.access')}
            </li>
            <li>
              {I18n.t('discovery.sellForm.pricingSection.priceBreakdown.sellingFee.tooltip.expertSupport')}
            </li>
            <li>
              {I18n.t('discovery.sellForm.pricingSection.priceBreakdown.sellingFee.tooltip.marketing')}
            </li>
            <li>
              {I18n.t('discovery.sellForm.pricingSection.priceBreakdown.sellingFee.tooltip.pricing')}
            </li>
            <li>
              {I18n.t('discovery.sellForm.pricingSection.priceBreakdown.sellingFee.tooltip.waiver')}
            </li>
          </ul>
        </>
      );

    case priceKeys.PROCESSING_FEE:
      return (
        <>
          <p className="mb-1">
            {I18n.t('discovery.sellForm.pricingSection.priceBreakdown.processingFee.tooltip.header', {
              feePercent: (paymentProcessingFeePercent(preferredSeller) * 100).toFixed(2),
              processingFlatFeeDisplayAmount: parseAmountCents(PAYMENT_PROCESSING_FEE_AMOUNT_CENTS, currency).display,
            })}
          </p>
          <p>
            {I18n.t('discovery.sellForm.pricingSection.priceBreakdown.processingFee.tooltip.explainer')}
          </p>
        </>
      );

    case priceKeys.ESTIMATED_EARNINGS:
      return I18n.t('discovery.sellForm.pricingSection.priceBreakdown.estimatedEarnings.tooltip');

    default:
      return null;
  }
}

function toolTipOpenEvent(open: boolean, tooltipName: string) {
  if (!open) return;

  trackEvent({
    eventName: MParticleEventName.TooltipOpened,
    eventType: EventType.Other,
    componentName: COMPONENT_NAME,
    source: tooltipName,
  });
}

export function PriceBreakdown({
  price,
  currency,
  preferredSeller,
  sellingFeeWaived,
  shopConfig,
  showIncVatPricingHint,
}: IProps) {
  const vatIdHcArticleUrl = useHelpCenterArticleUrl(VAT_ID_HC_ARTICLE);
  const debouncedTooltipOpenEvent = React.useCallback(debounce((open, tooltipName) => {
    toolTipOpenEvent(open, tooltipName);
  }, TOOLTIP_EVENT_DELAY), []);

  if (!price || !currency) { return null; }

  const priceFormatted = parseAmount(price, currency);
  const priceAmountCents = priceFormatted.amountCents;

  if (priceAmountCents <= MINIMUM_PRICE_CENTS) {
    return null;
  }

  const processingFeeAdditional = parseAmountCents(PAYMENT_PROCESSING_FEE_AMOUNT_CENTS, currency);
  const processingFeeAmountCents = (priceAmountCents * paymentProcessingFeePercent(preferredSeller)) + processingFeeAdditional.amountCents;
  const sellingFeeAmountCents = sellingFeeWaived ? 0 : getSellingFee(priceAmountCents, currency);
  const estimatedEarningsAmountCents = formatEstimatedEarnings(priceAmountCents, [sellingFeeAmountCents, processingFeeAmountCents]);

  const taxIncludedHint = showIncVatPricingHint ? I18n.t('discovery.sellForm.pricingSection.priceBreakdown.estimatedEarnings.specifier') : '';
  const showVatIdHcArticle = isVatCountry(shopConfig) && !shopConfig?.vatId;

  if (estimatedEarningsAmountCents <= 0) { return null; }

  const priceRows: { [key in priceKeys]: { amountCents: number; specifier: string; } } = {
    [priceKeys.SELLING_FEE]: {
      amountCents: sellingFeeAmountCents,
      specifier: '',
    },
    [priceKeys.PROCESSING_FEE]: {
      amountCents: processingFeeAmountCents,
      specifier: '',
    },
    [priceKeys.ESTIMATED_EARNINGS]: {
      amountCents: estimatedEarningsAmountCents,
      specifier: taxIncludedHint,
    },
  };

  return (
    <>
      {sellingFeeWaived && (
        <div className="price-breakdown__selling-fee-alert">
          <RCAlertBox type="success" small>
            {I18n.t('discovery.sellForm.pricingSection.priceBreakdown.sellingFee.feeWaived')}
          </RCAlertBox>
        </div>
      )}
      <table className="price-breakdown__table">
        <tbody>
          {Object.entries(priceRows).map(([field, { amountCents, specifier }]) => (
            <tr key={field}>
              <td>
                <RCTooltip
                  text={getTooltip(field, preferredSeller, currency)}
                  onOpenChange={(open) => debouncedTooltipOpenEvent(open, field)}
                >
                  {I18n.t(`discovery.sellForm.pricingSection.priceBreakdown.${field}.label`, { specifier: specifier })}
                </RCTooltip>
              </td>
              <td className="price-breakdown__value">
                <PriceDisplay
                  display={parseAmountCents(amountCents, currency).display}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {showVatIdHcArticle &&
        <div className="price-breakdown__vat-id-alert">
          <RCAlertBox type="warning" small>
            <I18N
              html
              text="discovery.sellForm.pricingSection.priceBreakdown.vatIdAlert"
              args={{ linkOpen: `<br /><a href="${vatIdHcArticleUrl}">`, linkClose: '</a>' }}
            />
          </RCAlertBox>
        </div>
      }
    </>
  );
}

export default PriceBreakdown;
