/* eslint-disable @typescript-eslint/no-namespace */
import React, { useState, useEffect } from 'react';
import I18n from 'i18n-js';
import { RCButton, RCPopover, RCAlertBox, RCToast } from '@reverbdotcom/cadence/components';
import { useHOCMutation, MutationFunction } from '@reverbdotcom/commons/src/useHOCMutation';
import { core_apimessages_Error, CoreZeroFinancingEnable, CoreZeroFinancingDisable } from '@reverbdotcom/commons/src/gql/graphql';
import { connect } from '@reverbdotcom/commons/src/connect';
import { withGraphql } from '@reverbdotcom/commons/src/with_graphql';
import { trackEvent } from '@reverbdotcom/commons/src/elog/mparticle_tracker';
import { MParticleEventName } from '@reverbdotcom/commons/src/elog/mparticle_types';
import { isNull } from 'lodash';
import {
  ENABLE_ZERO_PERCENT_FINANCING_MUTATION, DISABLE_ZERO_PERCENT_FINANCING_MUTATION,
} from '../../listings_management/footer_buttons/affirm_zero_percent_financing/AffirmZeroPercentPopoverContent';

export const COMPONENT_NAME = 'ZeroFinancingPopoverButton';
interface IExternalProps {
  showZeroFinancingPopover: boolean;
  showSuccessToast: boolean;
  showFailedToast: boolean;
  zeroFinancingSuccess: boolean;
  zeroFinancingActive: boolean;
  listingId: number;
  isProductEligible?: boolean;
  productInvalidToast: boolean;
}
interface IApolloProps {
  enableZeroFinancingMutation: MutationFunction<CoreZeroFinancingEnable.Mutation, CoreZeroFinancingEnable.Variables>;
  disableZeroFinancingMutation: MutationFunction<CoreZeroFinancingDisable.Mutation, CoreZeroFinancingDisable.Variables>;
}

type IProps = IApolloProps & IExternalProps;

export function ZeroFinancingPopoverButton({
  showZeroFinancingPopover,
  showSuccessToast,
  showFailedToast,
  zeroFinancingSuccess,
  enableZeroFinancingMutation,
  disableZeroFinancingMutation,
  zeroFinancingActive,
  listingId,
  isProductEligible,
  productInvalidToast,
}: IProps) {

  const [isPopoverOpen, setPopoverState] = useState(showZeroFinancingPopover);
  const [isSuccessToastOpen, setSuccessToastState] = useState(showSuccessToast);
  const [isFailedToastOpen, setFailedToastState] = useState(showFailedToast);
  const [isProductInvalidToastOpen, setProductInvalidToastState] = useState(productInvalidToast);
  const [isZeroFinancingSuccess, setZeroFinancingSuccess] = useState(zeroFinancingSuccess);
  const [isZeroFinancingActive, setZeroFinancingActive] = useState(zeroFinancingActive);
  const [, setErrors] = useState([]);
  const [isZeroFinancingHidden, setZeroFinancingHidden] = useState(false);

  const [
    enableZeroFinancing,
    {
      loading: enableFinancingDataLoading,
      data: enableFinancingData,
      errors: enableFinancingErrors,
    },
  ] = useHOCMutation(enableZeroFinancingMutation);

  const [
    disableZeroFinancing,
    {
      loading: disableFinancingDataLoading,
      data: disableFinancingData,
      errors: disableFinancingErrors,
    },
  ] = useHOCMutation(disableZeroFinancingMutation);


  useEffect(() => {
    handleResponse(enableFinancingDataLoading, enableFinancingData, enableFinancingErrors, MParticleEventName.EnabledZeroPercentFinancing);
  }, [enableFinancingDataLoading]);

  useEffect(() => {
    handleResponse(disableFinancingDataLoading, disableFinancingData, disableFinancingErrors, MParticleEventName.DisabledZeroPercentFinancing);
  }, [disableFinancingDataLoading]);

  function handleResponse(
    mutationDataLoading: boolean,
    mutationData: unknown,
    mutationErrors: core_apimessages_Error[],
    eventName: MParticleEventName) {
    const { isFinishedLoading, hasData, hasErrors } = checkResponse(mutationDataLoading, mutationData, mutationErrors);
    const hasAPIResponse = isFinishedLoading && (hasData || hasErrors);

    if (!hasAPIResponse) return;

    if (hasErrors) {
      handleErrors(mutationErrors);

      setZeroFinancingSuccess(false);
      setFailedToastState(true);
    } else {
      trackEvent({
        eventName,
        componentName: COMPONENT_NAME,
        listingId,
      });

      setErrors([]);
      validateIfProductIsEligible();
      setZeroFinancingActive(!isZeroFinancingActive);
      setSuccessToastState(true);
    }
  }

  function validateIfProductIsEligible() {
    if (isNull(isProductEligible)) return;

    if (!isProductEligible) {
      setProductInvalidToastState(true);
    } else {
      setZeroFinancingSuccess(true);
    }
  }

  function checkResponse(mutationDataLoading: boolean, mutationData: unknown, mutationErrors: string | any[]) {
    return {
      isFinishedLoading: !mutationDataLoading,
      hasData: !!mutationData,
      hasErrors: !!mutationErrors?.length,
    };
  }

  function handleErrors(resErrors: core_apimessages_Error[]): void {
    setErrors(resErrors.map(error => error.message));
  }

  function handleClickZeroFinancing() {
    const payload = {
      variables: {
        input: {
          productId: String(listingId),
        },
      },
    };

    if (!isZeroFinancingActive) {
      enableZeroFinancing(payload);
    } else {
      disableZeroFinancing(payload);
    }
    setPopoverState(false);
  }

  if (isZeroFinancingHidden) return null;
  return (
    <div className="listing-row__action">
      <RCPopover
        isOpen={isPopoverOpen}
        anchor={<button
          className="listing-row__action__trigger"
          type="button"
          onClick={() => setPopoverState(!isPopoverOpen)}
        >
          {isZeroFinancingActive && I18n.t('discovery.listingsManagement.sellerListingCard.affirmZeroPercent.disableButtonText')}
          {!isZeroFinancingActive && I18n.t('discovery.listingsManagement.sellerListingCard.affirmZeroPercent.enableButtonText')}
        </button>}
        onDismiss={() => setPopoverState(false)}
        position="bottom-end"
        trapFocus
      >
        <div className="mb-2">
          <RCAlertBox
            type="plain"
          >
            {isZeroFinancingActive && (
              <div>
                <div>
                  <p className="bail-card__link">
                    {I18n.t('discovery.listingsManagement.sellerListingCard.affirmZeroPercent.affirmIsEnabled')}
                  </p>
                  <p>
                    {I18n.t('discovery.listingsManagement.sellerListingCard.affirmZeroPercent.content')}
                  </p>
                </div>
                <div className="pt-0">
                  <p className="togglable-dropdown__button">
                    <a href="https://help.reverb.com/hc/en-us/articles/360009991994-How-does-0-interest-financing-through-Affirm-work-as-a-seller-"
                      className="text-link">
                      {I18n.t('discovery.listingsManagement.sellerListingCard.affirmZeroPercent.learnMore')}
                    </a>
                  </p>
                </div>
              </div>
            )}
            {!isZeroFinancingActive && (
              <div>
                <div>
                  <p>
                    {I18n.t('discovery.listingsManagement.sellerListingCard.affirmZeroPercent.content')}
                  </p>
                </div>
                <div className="pt-0">
                  <p className="togglable-dropdown__button">
                    <a href="https://help.reverb.com/hc/en-us/articles/360009991994-How-does-0-interest-financing-through-Affirm-work-as-a-seller-"
                      className="text-link">
                      {I18n.t('discovery.listingsManagement.sellerListingCard.affirmZeroPercent.learnMore')}
                    </a>
                  </p>
                </div>
              </div>
            )}
          </RCAlertBox>
          {isZeroFinancingActive && (
            <div className="pt-8">
              <RCButton
                fullWidth
                onClick={handleClickZeroFinancing}
                size="medium"
                theme="main"
                variant="filled"
              >
                {I18n.t('discovery.listingsManagement.sellerListingCard.affirmZeroPercent.disableButtonText')}
              </RCButton>
            </div>
          )}
          {!isZeroFinancingActive && (
            <div className="pt-8">
              <RCButton
                fullWidth
                onClick={handleClickZeroFinancing}
                size="medium"
                theme="main"
                variant="filled"
              >
                {I18n.t('discovery.listingsManagement.sellerListingCard.affirmZeroPercent.enableButtonText')}
              </RCButton>
            </div>
          )}
        </div>
      </RCPopover>
      {isZeroFinancingSuccess && (
        <RCToast
          delayInterval="short"
          onClose={() => {
            setSuccessToastState(false);
          }}
          text={I18n.t('discovery.listingsManagement.toast.message.success')}
          theme="success"
          isOpen={isSuccessToastOpen}
        />
      )}
      {!isZeroFinancingSuccess && (
        <RCToast
          delayInterval="short"
          onClose={() => {
            setFailedToastState(false);
          }}
          text={I18n.t('discovery.listingsManagement.genericError')}
          theme="error"
          isOpen={isFailedToastOpen}
        />
      )}
      {!isProductEligible && (
        <RCToast
          delayInterval="medium"
          onClose={() => {
            setProductInvalidToastState(false);
            setZeroFinancingHidden(true);
          }}
          text={ I18n.t('discovery.zeroPercentFinancing.productIsNoLongerEligible') }
          theme="plain"
          isOpen={isProductInvalidToastOpen}
        />
      )}
    </div>
  );
}

const withEnableZeroFinancingMutation = withGraphql<IExternalProps, CoreZeroFinancingEnable.Mutation, CoreZeroFinancingEnable.Variables>(
  ENABLE_ZERO_PERCENT_FINANCING_MUTATION,
  {
    name: 'enableZeroFinancingMutation',
  },
);

const withDisableZeroFinancingMutation = withGraphql<IExternalProps, CoreZeroFinancingDisable.Mutation, CoreZeroFinancingDisable.Variables>(
  DISABLE_ZERO_PERCENT_FINANCING_MUTATION,
  {
    name: 'disableZeroFinancingMutation',
  },
);

export default connect<IExternalProps>([
  withEnableZeroFinancingMutation,
  withDisableZeroFinancingMutation,
])(ZeroFinancingPopoverButton);
