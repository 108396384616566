import React from 'react';
import SanitizedRender from '@reverbdotcom/commons/src/components/sanitized_render';
import { RecommendedRadio } from './recommended_radio';
import { CoreBumpRecommendationListingCard } from '@reverbdotcom/commons/src/gql/graphql';
import { BumpDescription } from './bump_description';
import { ListingPreview } from './listing_preview';
import { MParticleEventName } from '@reverbdotcom/commons/src/elog/mparticle_types';
import I18n from 'i18n-js';
import { useViewTracking } from '@reverbdotcom/commons/src/use_tracking';
import { formatBid } from './bump_helpers';
import { RCAlertBox } from '@reverbdotcom/cadence/components';

const COMPONENT_NAME = 'BumpModalContainer';

interface Props {
  bid: number;
  handleBidChange: (bid: number) => void;
  listing: CoreBumpRecommendationListingCard.Fragment;
  recommendationAmount: number;
  selectedBid: number;
  successMessage: string;
  failMessage: string;
  updateBid: (
    bid: number,
    bumpRecommendationAmount: number,
    listing: CoreBumpRecommendationListingCard.Fragment,
    refetch: (newBid: number | null) => void,
  ) => void;
  refetch: (newBid: number | null) => void;
  onRequestClose?: Function;
  hideSubmit?: boolean;
  onSubmit: () => void;
  setShowCustomWarning: React.Dispatch<React.SetStateAction<boolean>>;
  showCustomWarning: boolean;
}

export function BumpModalBody(props: Props) {
  const mParticleViewData = {
    componentName: COMPONENT_NAME,
    eventName: MParticleEventName.ViewedBumpSlider,
    listing: props.listing,
    startingBumpRatePercent: formatBid(props.bid),
    bumpRecommendationPercent: props.recommendationAmount,
  };

  useViewTracking(mParticleViewData, true);

  function Messages() {
    return (
      <>
        {props.failMessage &&
          <div className="scaling-mb-4">
            <RCAlertBox type="error">
              <SanitizedRender html={props.failMessage} />
            </RCAlertBox>
          </div>
        }
        {props.successMessage &&
          <div className="scaling-mb-4">
            <RCAlertBox type="success">
              {props.successMessage}
            </RCAlertBox>
          </div>
        }
      </>
    );
  }

  return (
    <>
      <Messages />
      <div className="scaling-mb-4">
        <ListingPreview
          listing={props.listing}
        />
      </div>
      <div className="scaling-mb-4">
        <BumpDescription />
      </div>
      <>
        <div className="scaling-mt-6">
          <p className="weight-bold size-90">
            {I18n.t('discovery.bumpModalV2.copyV2.setYourBumpRate')}
          </p>
          <RecommendedRadio
            handleBidChange={props.handleBidChange}
            recommendationAmount={props.recommendationAmount}
            selectedBid={props.selectedBid}
            showCustomWarning={props.showCustomWarning}
            setShowCustomWarning={props.setShowCustomWarning}
          />
        </div>

        <div className="scaling-mt-2 color-secondary size-90">
          {I18n.t('discovery.bumpModalV2.copyV2.footnote')}
        </div>

        {!props.hideSubmit && (
          <div className="align-right scaling-mt-8">
            <button
              type="button"
              className="button button--primary size-90 weight-bold"
              disabled={props.showCustomWarning}
              onClick={props.onSubmit}
            >
              {I18n.t('discovery.bumpModalV2.copyV2.save')}
            </button>
          </div>
        )}
      </>
    </>
  );
}
