// TODO update this to import from commons/src/gql
// eslint-disable-next-line no-restricted-imports
import { gql } from '@apollo/client';
import React from 'react';
import { ListingCardSignalsData } from '../gql/graphql';

export interface ListingWithSignals {
  signals?: ListingCardSignalsData.Fragment[]
}

interface IProps {
  listing: ListingWithSignals
}

export default function ListingCardSignals(props: IProps) {
  const { signals } = props.listing;
  if (!signals?.length) return null;

  return (
    <>
      {signals.map(s => {
        return (
          <div className="listing-card-nudge" key={s.name}>
            <img src={s.icon} alt="" />
            <span>{s.title}</span>
          </div>
        );
      })}
    </>
  );
}

/* unfortunately, the linter complains about input variables in fragments.
so instead, root queries must wrap this fragment like so:

signals (input: { groups: $signalGroups }) {
  ... ListingCardSignalsData
}
*/
export const ListingCardSignalsFragment = gql`
  fragment ListingCardSignalsData on reverb_signals_Signal {
    name
    title
    icon
  }
`;
