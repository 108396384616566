import React from 'react';
import I18n from 'i18n-js';

import {
  core_apimessages_Country as ICountry,
  core_apimessages_Error as IError,
  core_apimessages_MyAddressType,
} from '@reverbdotcom/commons/src/gql/graphql';
import { IAddressFormData } from './address_form_data';
import AddressFormFields from '../address_form_fields';

import ControlledCheckbox from '@reverbdotcom/commons/src/components/controlled_checkbox';
import { RCAlertBox, RCTag } from '@reverbdotcom/cadence/components';
import FormGroupWithInput from '@reverbdotcom/commons/src/components/form_group_with_input';
import { mapAddressToEntry } from '../../lib/addressEntryMapping';

interface IProps {
  addressFormData: IAddressFormData;
  countries: ICountry[];
  onUpdateField: (attrs) => void;
  addressIsCurrentPrimary: boolean;
  errors: IError[];
  formRef(instance: HTMLFormElement): any;
  addressType?: core_apimessages_MyAddressType;
}

const COMPONENT_NAME = 'AddressForm';

export class AddressForm extends React.Component<IProps, null> {
  get isEuOdrAddress(): boolean {
    return this.props.addressType === core_apimessages_MyAddressType.EU_ODR_PLATFORM;
  }

  renderEmailInput() {
    if (!this.isEuOdrAddress) {
      return null;
    }

    return (
      <div className="scaling-mt-4">
        <FormGroupWithInput
          label={I18n.t('discovery.addressBook.form.email')}
          id="address-email"
          fieldName="email"
          inputName="email"
          inputType="text"
          autoComplete="email"
          value={this.props.addressFormData.email}
          updateField={this.props.onUpdateField}
          required
        />
      </div>
    );
  }

  renderDefaultCheckbox() {
    if (this.isEuOdrAddress) {
      return null;
    }

    if (this.props.addressIsCurrentPrimary) {
      return (
        <p className="scaling-mt-4 mb-0">
          <RCTag>
            <span className="icon-l-star">
              {I18n.t('discovery.addressBook.currentlyDefault')}
            </span>
          </RCTag>
        </p>
      );
    }

    return (
      <div className="label-with-checkbox">
        <ControlledCheckbox
          fieldName="primary"
          inputName="address_primary"
          checked={this.props.addressFormData.primary}
          updateField={this.props.onUpdateField}
        />
        <label data-make-primary htmlFor="address_primary">
          <div>
            {I18n.t('discovery.addressBook.form.makeDefault')}
            <p className="form-group__help-text mb-0">
              {I18n.t('discovery.addressBook.form.makeDefaultHint')}
            </p>
          </div>
        </label>
      </div>
    );
  }

  renderError(error: IError) {
    return (
      <li key={error.field + error.message}>
        {error.message}
      </li>
    );
  }

  renderErrors() {
    if (this.props.errors.length === 0) { return null; }

    return (
      <div className="mb-2">
        <RCAlertBox type="error">
          {this.props.errors.map(e => this.renderError(e))}
        </RCAlertBox>
      </div>
    );
  }

  render() {
    return (
      <form
        className="address-form"
        ref={this.props.formRef}
      >
        {this.renderErrors()}

        <AddressFormFields
          address={mapAddressToEntry(this.props.addressFormData)}
          countries={this.props.countries}
          updateField={this.props.onUpdateField}
          requiredFieldsOverrides={['phone']}
          useCamelizedFieldsForRQL
          trackingComponentName={COMPONENT_NAME}
          autocompleteEnabled
        />

        {this.renderEmailInput()}

        <div className="scaling-mt-4">
          {this.renderDefaultCheckbox()}
        </div>
      </form>
    );
  }
}

export default AddressForm;
