import React from 'react';
import { Route } from '@reverbdotcom/commons/src/route_settings';
import { SALE_ROUTE_PATH } from '../components/shared/constants';

const SaleRoute = (
  <Route
    key={SALE_ROUTE_PATH}
    path={SALE_ROUTE_PATH}
    props={{
      clientSide: true,
    }}
    getComponent={(_location, cb) => {
      import(
        /* webpackChunkName: 'marketplace' */
        '../components/discovery/sales/sale_grid'
      ).then(m => cb(null, m.default));
    }}
  />
);

export default SaleRoute;
