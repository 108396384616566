import React from 'react';
import I18n from 'i18n-js';

import { RCSwitch } from '@reverbdotcom/cadence/components';

export interface IProps {
  automateSellingFeedback: boolean;
}

export function FeedbackPreferences(props: IProps) {
  const [automateSellingFeedback, setAutomateSellingFeedback] = React.useState(props.automateSellingFeedback);

  return (
    <div className="max-width-50 tablet-max-width-70 mobile-max-width-100">
      <h2 className="cms-h1">
        {I18n.t('discovery.feedbackPreferences.title')}
      </h2>
      <div className="bdt-1 bd-primary">
        <div className="scaling-mb-4 scaling-mt-2">
          <RCSwitch
            checked={automateSellingFeedback}
            label={I18n.t('discovery.feedbackPreferences.automateFeedbackLabel')}
            sublabel={I18n.t('discovery.feedbackPreferences.automateFeedbackSublabel')}
            onChange={() => setAutomateSellingFeedback(!automateSellingFeedback)}
          />
        </div>
        <input
          name="feedback_preferences[automate_selling_feedback]"
          type="hidden"
          value={automateSellingFeedback ? '1' : '0'}
        />
      </div>
    </div>
  );
}

export default FeedbackPreferences;
