import React from 'react';
import I18n from 'i18n-js';

import { RCAlertBox, RCButton, RCToast } from '@reverbdotcom/cadence/components';
import {
  Core_SecondaryUsers_ExportActivitiesMutation,
  Core_SecondaryUsers_ExportActivitiesMutationVariables,
} from '@reverbdotcom/commons/src/gql/graphql';
import { useMutation } from '@reverbdotcom/commons/src/useMutation';
import { gql } from '@reverbdotcom/commons/src/gql';

interface Props {
  activityDataAvailable: boolean;
}

const exportSecondaryUserActivitiesMutation = gql(`
  mutation Core_SecondaryUsers_ExportActivities(
    $input: Input_core_apimessages_ExportSecondaryUserActivitiesRequest
  ) {
    exportSecondaryUserActivities(input: $input) {
      enqueued
    }
  }
`);

export default function ShopSettingsUserManagementActivityExport({ activityDataAvailable }: Props) {
  const [showExportStartedToast, setShowExportStartedToast] = React.useState(false);

  const [exportActivities, { data, loading, errors }] = useMutation<Core_SecondaryUsers_ExportActivitiesMutation, Core_SecondaryUsers_ExportActivitiesMutationVariables>(
    exportSecondaryUserActivitiesMutation,
  );

  const exportSucceeded = data?.exportSecondaryUserActivities.enqueued && !loading;
  const exportFailed = errors.length > 0 || (!data?.exportSecondaryUserActivities.enqueued && !loading);

  return (
    <div className="mb-10">
      <h4 className="weight-bold size-140 pt-8 mb-4">
        {I18n.t('discovery.shopSettings.userManagement.activityLogs.title')}
      </h4>
      <p className="mb-1">
        {I18n.t('discovery.shopSettings.userManagement.activityLogs.description')}
      </p>

      {activityDataAvailable && (
        <div className="mt-6 pb-8">
          <RCButton
            onClick={async () => {
              setShowExportStartedToast(true);
              await exportActivities();
            }}
            disabled={loading}
            variant="filled"
          >
            {I18n.t('discovery.shopSettings.userManagement.activityLogs.exportButton')}
          </RCButton>
        </div>
      )}

      {!activityDataAvailable && (
        <RCAlertBox
          type="warning"
        >
          {I18n.t('discovery.shopSettings.userManagement.activityLogs.noDataAvailable')}
        </RCAlertBox>
      )}

      {exportSucceeded && (
        <RCToast
          isOpen={showExportStartedToast}
          onClose={() => setShowExportStartedToast(false)}
          text={I18n.t('discovery.shopSettings.userManagement.activityLogs.exportStarted')}
          theme="success"
        />
      )}

      {exportFailed && (
        <RCToast
          isOpen={showExportStartedToast}
          onClose={() => setShowExportStartedToast(false)}
          text={I18n.t('discovery.shopSettings.userManagement.activityLogs.exportFailed')}
          theme="error"
        />
      )}
    </div>
  );
}
