import { RCBanner, BannerType } from '@reverbdotcom/cadence/components';
import CoreLink from '@reverbdotcom/commons/src/components/core_link';
import React from 'react';
import DocumentBrandIcon from '@reverbdotcom/cadence/images/brand/document-orange.svg';

export interface Props {
  message: string;
  headline: string;
  buttonLinkPath?: string;
  buttonText?: string;
  hideIcon?: boolean;
  bannerType?: BannerType;
}

export function TaxInformationBanner({
  message,
  headline,
  buttonLinkPath = '/my/selling/tax_id',
  buttonText = undefined,
  hideIcon = false,
  bannerType = 'info',
}: Props) {

  return (
    <RCBanner
      title={headline}
      content={message}
      image={hideIcon ? null : DocumentBrandIcon}
      theme={bannerType}
      cta={buttonText ? <CoreLink to={buttonLinkPath}>{buttonText}</CoreLink> : null}
    />
  );
}

export default TaxInformationBanner;
