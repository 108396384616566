import * as elog from './elog';
import isNode from './is-node';

export function setItem(key: string, value: any): boolean {
  try {
    const serializedValue = JSON.stringify(value);
    localStorage.setItem(key, serializedValue);
    return true;
  } catch (error) {
    elog.error('Local Storage set error', {}, error);
    return false;
  }
}

export function getItem(key: string, fallbackValue: any = null): any {
  if (isNode) { return fallbackValue; }

  try {
    const storedValue = localStorage.getItem(key);
    return JSON.parse(storedValue) ?? fallbackValue;
  } catch (error) {
    elog.error('Local Storage get error', {}, error);
    localStorage.removeItem(key);
    return fallbackValue;
  }
}
