import React from 'react';
import I18n from 'i18n-js';
import { get } from 'lodash';
import SanitizedRender from '@reverbdotcom/commons/src/components/sanitized_render';
import { RCAlertBox } from '@reverbdotcom/cadence/components';
import {
  Negotiation,
  core_apimessages_NegotiationParty as NegotiationParty,
  core_apimessages_NegotiationAction as NegotiationAction,
} from '@reverbdotcom/commons/src/gql/graphql';
import { IOfferPrices } from '@reverbdotcom/commons/src/offers/negotiation_types';
import {
  lastOffer,
  offerQuantity,
} from './negotiation_getters';

interface IProps {
  prices: IOfferPrices;
  originalPrices: IOfferPrices;
  negotiation: Negotiation;
  party: NegotiationParty;
  action: NegotiationAction;
  localPickupOnly?: boolean;
}

function CurrencyConvertedNote({ prices, originalPrices }) {
  if (originalPrices && prices.price.currency !== originalPrices.price.currency) {
    const price = get(originalPrices, 'price.display');
    const priceText = `${price} <span class="size-80 weight-normal">${get(originalPrices, 'price.currency')}</span>`;
    const text = I18n.t('discovery.offers.convertedFrom', {
      price: priceText,
      shipping: get(originalPrices, 'shippingPrice.display'),
    });

    return (
      <p className="offers-form__negotiation-summary__sub-price">
        <SanitizedRender html={text} />
      </p>
    );
  } else {
    return null;
  }
}

export default function NegotiationSummary({ prices, originalPrices, negotiation, party, action, localPickupOnly = false }: IProps) {
  const isSeller = party === NegotiationParty.SELLER;
  const shippingPrice = get(prices, 'shippingPrice.display', '');
  const offerAmount = get(prices, 'price.display', '');
  const shippingLocation = negotiation.shippingLocation?.displayLocation;
  const quantity = offerQuantity(lastOffer(negotiation));

  return (
    <div className="offers-form__negotiation-summary">
      {isSeller && shippingLocation && !localPickupOnly && (
        <SanitizedRender
          html={I18n.t('discovery.offers.summary.ofBuyerOffer', {
            openTag: '<span class="offers-form__negotiation-summary__price-label">',
            closeTag: '</span>',
            offerAmount: offerAmount,
            shippingPrice: shippingPrice,
            shippingLocation: shippingLocation,
          })}
        />
      )}

      {isSeller && !shippingLocation && !localPickupOnly && (
        <SanitizedRender
          html={I18n.t('discovery.offers.summary.ofBuyerOfferWithoutShippingLocation', {
            openTag: '<span class="offers-form__negotiation-summary__price-label">',
            closeTag: '</span>',
            offerAmount: offerAmount,
            shippingPrice: shippingPrice,
          })}
        />
      )}

      {isSeller && localPickupOnly && (
        <SanitizedRender
          html={I18n.t('discovery.offers.summary.ofBuyerOfferLocalPickupOnly', {
            openTag: '<span class="offers-form__negotiation-summary__price-label">',
            closeTag: '</span>',
            offerAmount: offerAmount,
          })}
        />
      )}

      {!isSeller && shippingLocation && !localPickupOnly && (
        <SanitizedRender
          html={I18n.t('discovery.offers.summary.ofSellerOffer', {
            openTag: '<span class="offers-form__negotiation-summary__price-label">',
            closeTag: '</span>',
            offerAmount: offerAmount,
            shippingPrice: shippingPrice,
            shippingLocation: shippingLocation,
          })}
        />
      )}

      {!isSeller && !shippingLocation && !localPickupOnly && (
        <SanitizedRender
          html={I18n.t('discovery.offers.summary.ofSellerOfferWithoutShippingLocation', {
            openTag: '<span class="offers-form__negotiation-summary__price-label">',
            closeTag: '</span>',
            offerAmount: offerAmount,
            shippingPrice: shippingPrice,
          })}
        />
      )}

      {!isSeller && localPickupOnly && (
        <SanitizedRender
          html={I18n.t('discovery.offers.summary.ofSellerOfferLocalPickupOnly', {
            openTag: '<span class="offers-form__negotiation-summary__price-label">',
            closeTag: '</span>',
            offerAmount: offerAmount,
          })}
        />
      )}

      {negotiation.taxIncluded &&
        <span className="offers-form__negotiation-summary__tax-hint">
          ({negotiation.taxIncludedHint})
        </span>
      }

      {quantity > 1 && (
        <p className="offers-form__negotiation-summary__quantity">
          {I18n.t('discovery.offers.summaryWithQuantity', { quantity })}
        </p>
      )}

      <CurrencyConvertedNote
        prices={prices}
        originalPrices={originalPrices}
      />

      {isSeller && negotiation.buyerWillPayImmediately && action === NegotiationAction.ACCEPT && (
        <div className="mtb-4">
          <RCAlertBox
            type="info"
            small
          >
            <SanitizedRender
              html={I18n.t('discovery.offers.buyerWillPayImmediately')}
            />
          </RCAlertBox>
        </div>
      )}
    </div>
  );
}
