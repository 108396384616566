import React from 'react';
import I18n from 'i18n-js';

import { RCToast } from '@reverbdotcom/cadence/components';
import { ModalDialog } from '@reverbdotcom/commons';
import { gql } from '@reverbdotcom/commons/src/gql';
import { useMutation } from '@reverbdotcom/commons/src/useMutation';
import {
  Core_SecondaryUsers_UpdateUserShopPermissionMutation,
  Core_SecondaryUsers_UpdateUserShopPermissionMutationVariables,
  Core_SecondaryUsers_MySecondaryUsersQuery,
} from '@reverbdotcom/commons/src/gql/graphql';
import { I18N as Translate } from '@reverbdotcom/commons/src/components/translate';

import ShopSettingsUserManagementPermissionLevelSelect from './shop_settings_user_management_permission_level_select';
import { PermissionFormState } from './shop_settings_user_management';

interface Props {
  isOpen: boolean;
  onClose: () => any;
  selectedPermission: Core_SecondaryUsers_MySecondaryUsersQuery['me']['shop']['userShopPermissions'][0];
  formState: PermissionFormState;
  dispatch: (obj: any) => void;
}

const updateUserShopPermissionMutation = gql(`
  mutation Core_SecondaryUsers_UpdateUserShopPermission(
    $input: Input_core_apimessages_UpdateUserShopPermissionRequest
  ) {
    updateUserShopPermission(input: $input) {
      userShopPermission {
        id
        email
        permissionLevel
      }
    }
  }
`);

export default function ShopSettingsUserManagementUpdateModal({ isOpen, onClose, selectedPermission, formState, dispatch }: Props) {
  const [toastIsOpen, setToastIsOpen] = React.useState(false);
  const [toastText, setToastText] = React.useState('');

  const [updateUserShopPermission, { loading, errors, reset }] = useMutation<Core_SecondaryUsers_UpdateUserShopPermissionMutation, Core_SecondaryUsers_UpdateUserShopPermissionMutationVariables>(
    updateUserShopPermissionMutation,
  );

  return (
    <>
      <ModalDialog
        headerTitle={I18n.t('discovery.shopSettings.userManagement.userAccess.update.modalTitle')}
        isOpen={isOpen}
        onRequestClose={() => {
          reset();
          onClose();
        }}
        onSubmit={async () => {
          const result = await updateUserShopPermission({
            variables: {
              input: {
                userId: selectedPermission.user.id,
                permissionLevel: formState.permissionLevel,
              },
            },
            refetchQueries: ['Core_SecondaryUsers_MySecondaryUsers'],
            awaitRefetchQueries: true,
          });

          if (result.data?.updateUserShopPermission) {
            reset();
            onClose();
            setToastText(I18n.t('discovery.shopSettings.userManagement.userAccess.update.success', { email: selectedPermission.email }));
            setToastIsOpen(true);
          }
        }}
        isSaving={loading}
      >
        <p>
          <Translate
            html
            text="discovery.shopSettings.userManagement.userAccess.update.modalDescription"
            args={{ email: selectedPermission?.email }}
          />
        </p>

        <ShopSettingsUserManagementPermissionLevelSelect
          formState={formState}
          dispatch={dispatch}
          errorText={errors.map((error) => error.message).join(' ')}
        />
      </ModalDialog>

      <RCToast
        isOpen={toastIsOpen}
        onClose={() => { setToastIsOpen(false); }}
        text={toastText}
        theme="success"
      />
    </>
  );
}
