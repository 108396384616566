import PropTypes from 'prop-types';
import React from 'react';
import SiteModule from '@reverbdotcom/commons/src/components/site_module';
import DirectCheckoutWrapper from './direct_checkout_wrapper';
import DirectCheckoutProfileSetup from './direct_checkout_profile_setup';
import DirectCheckoutOnlyPaymentMethod from './direct_checkout_only_payment_method';
import PaypalRadioButton from './paypal_radio_button';
import PaypalProfile from './paypal_profile';
import ErrorMessage from '../shared/error_message';
import I18n from 'i18n-js';
import { trackEvent, MParticleEventName, MParticlePageName } from '@reverbdotcom/commons/src/elog/mparticle_tracker';

const PAYPAL = 'paypal';
const DIRECT_CHECKOUT = 'direct_checkout';

// This is shown to sellers after they create a listing, before they have set up
// an accepted payment methods profile (Listings::ShopPoliciesController#new).
// It uses some of the same child components as the payment method selection
// component used on the shop policies dashboard.
class ShopPaymentMethodSelection extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedPaymentMethod: props.initiallySelectedPaymentMethod,
      directCheckoutProfileAttributes: props.directCheckoutProfileProps.profileAttributes,
    };
  }

  componentDidMount() {
    trackEvent({
      eventName: MParticleEventName.ViewedPaymentSettings,
      isPlaid: this.props.plaidProps?.plaidEnabled,
      pageName: MParticlePageName.GetPaid,
    });
  }

  get directCheckoutSelected() {
    return this.state.selectedPaymentMethod === DIRECT_CHECKOUT;
  }

  get paypalSelected() {
    return this.state.selectedPaymentMethod === PAYPAL;
  }

  nameForField(fieldName) {
    return `${this.props.formPrefix}[${fieldName}]`;
  }

  directCheckoutRadio() {
    if (!this.props.allowDirectCheckout) {
      return false;
    }

    return (
      <DirectCheckoutWrapper
        directCheckoutFeePercent={this.props.directCheckoutFeePercent}
        directCheckoutFeeFlatAmount={this.props.directCheckoutFeeFlatAmount}
        directCheckoutIntlFeePercent={this.props.directCheckoutIntlFeePercent}
        shopCurrency={this.props.shopCurrency}
        bankCountryCode={this.state.directCheckoutProfileAttributes.countryCode}
      >
        <input
          type="radio"
          value="direct_checkout"
          checked={this.directCheckoutSelected}
          name={this.nameForField('payment_method')}
          id="shop-settings-dc-radio"
          onChange={event => this.handleChangePaymentMethod(event)}
        />
      </DirectCheckoutWrapper>
    );
  }

  handleChangePaymentMethod(event) {
    this.setState({
      selectedPaymentMethod: event.target.value,
    });
  }

  updateDirectCheckoutProfileCountryCode(countryCode) {
    const directCheckoutProfileAttributes = {

      ...this.state.directCheckoutProfileAttributes,
      countryCode,
    };

    this.setState({ directCheckoutProfileAttributes });
  }

  updateDirectCheckoutProfileCurrency(currency) {
    const directCheckoutProfileAttributes = {

      ...this.state.directCheckoutProfileAttributes,
      currency,
    };

    this.setState({ directCheckoutProfileAttributes });
  }

  directCheckoutProfileProps() {
    return {
      ...this.props.directCheckoutProfileProps,
      profileAttributes: this.state.directCheckoutProfileAttributes,
      selected: this.directCheckoutSelected,
      shopCurrency: this.props.shopCurrency,
      onCountryChange: countryCode => this.updateDirectCheckoutProfileCountryCode(countryCode),
      onCurrencyChange: currency => this.updateDirectCheckoutProfileCurrency(currency),
      plaidProps: this.props.plaidProps,
      shopCountryCode: this.props.shopCountryCode,
    };
  }

  directCheckoutProfile() {
    if (!this.props.allowDirectCheckout) {
      return false;
    }

    return (
      <DirectCheckoutProfileSetup {...this.directCheckoutProfileProps()} />
    );
  }

  shopPolicyFormErrors() {
    if (this.props.shopPolicyFormErrors.length === 0) {
      return false;
    }

    return (
      <ErrorMessage
        message={this.props.shopPolicyFormErrors.join('. ')}
        alertStyle="alert-error"
      />
    );
  }

  paymentMethodRadios() {
    return (
      <>
        {this.directCheckoutRadio()}
        <PaypalRadioButton
          inputName={this.nameForField('payment_method')}
          checked={this.paypalSelected}
          handleChangePaymentMethod={event => this.handleChangePaymentMethod(event)}
          shopCurrency={this.props.shopCurrency}
        />
      </>
    );
  }

  directCheckoutOnly() {
    return (
      <DirectCheckoutOnlyPaymentMethod
        directCheckoutFeePercent={this.props.directCheckoutFeePercent}
        directCheckoutFeeFlatAmount={this.props.directCheckoutFeeFlatAmount}
        directCheckoutIntlFeePercent={this.props.directCheckoutIntlFeePercent}
        shopCurrency={this.props.shopCurrency}
        fieldName={this.nameForField('payment_method')}
      />
    );
  }

  paymentMethodFields() {
    if (this.props.allowDirectCheckoutOnly) {
      return this.directCheckoutOnly();
    }
    return this.paymentMethodRadios();
  }

  sectionHeader() {
    if (this.props.allowDirectCheckoutOnly) {
      return I18n.t('js.dashboard.selling.shopPolicies.yourPaymentSettings');
    }
    return I18n.t('js.dashboard.selling.shopPolicies.howCanBuyersPayYou');
  }

  render() {
    return (
      <SiteModule title={this.sectionHeader()}>
        {this.shopPolicyFormErrors()}
        <div className="g-container">
          {this.paymentMethodFields()}
        </div>

        <div className="shop-settings-payment-setup">
          {this.directCheckoutProfile()}
        </div>

        <PaypalProfile
          classNames="scaling-mt-4"
          selected={this.paypalSelected}
          {...this.props.paypalProfileProps}
        />
      </SiteModule>
    );
  }
}

ShopPaymentMethodSelection.propTypes = {
  allowDirectCheckout: PropTypes.bool.isRequired,
  directCheckoutFeePercent: PropTypes.number.isRequired,
  directCheckoutFeeFlatAmount: PropTypes.string.isRequired,
  directCheckoutIntlFeePercent: PropTypes.number.isRequired,
  directCheckoutProfileProps: PropTypes.shape({
    profileAttributes: PropTypes.object.isRequired,
    payoutCurrencies: PropTypes.object.isRequired,
    countryCodeOptions: PropTypes.array.isRequired,
    formPrefix: PropTypes.string.isRequired,
    selected: PropTypes.bool,
    shopCurrency: PropTypes.string,
  }),
  paypalProfileProps: PropTypes.object.isRequired,
  shopPolicyFormErrors: PropTypes.array.isRequired,
  currencyErrors: PropTypes.array,
  formPrefix: PropTypes.string.isRequired,
  initiallySelectedPaymentMethod: PropTypes.string,
  shopCurrency: PropTypes.string.isRequired,
  allowDirectCheckoutOnly: PropTypes.bool.isRequired,
};

export default ShopPaymentMethodSelection;
