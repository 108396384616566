import React, { useState } from 'react';
import classNames from 'classnames';
import LightboxImageItem from './lightbox_image_item';

interface Props {
  slug?: string;
  narrow?: boolean;
  caption?: string;
  jumplinkSlug?: string;
  thumbnailSlugs?: string[];
}

function getVideoUrl(slug) {
  return `https://www.youtube.com/embed/${slug}`;
}

export default function VideoEmbed({
  slug = '',
  narrow = false,
  caption = '',
  jumplinkSlug = '',
  thumbnailSlugs = [],
}: Props) {
  const [activeThumbSlug, setActiveThumbSlug] = useState(thumbnailSlugs[0]);

  if (!slug) return null;
  const videoUrl = getVideoUrl(slug || activeThumbSlug);

  const classes = classNames(
    'video-embed',
    { 'video-embed--narrow': narrow },
  );
  const getThumbClass = (thumbSlug: string) => classNames(
    'video-embed__thumb',
    { 'video-embed__thumb--inactive': thumbSlug !== activeThumbSlug },
  );

  return (
    <div
      className={classes}
      id={jumplinkSlug || null}
    >
      <div className="video-embed__inner" itemProp="videoObject" itemScope itemType="http://schema.org/VideoObject">
        <meta itemProp="embedUrl" content={videoUrl} />
        <iframe
          src={videoUrl}
          title={caption}
          frameBorder="0"
          allowFullScreen
        />
      </div>
      {caption &&
        <div className="video-embed__caption">
          {caption}
        </div>
      }
      {!!thumbnailSlugs.length && (
        <div className="video-embed__thumbs">
          <div className="video-embed__thumbs__inner">
            {thumbnailSlugs.map((slug, index) => (
              <div onClick={() => setActiveThumbSlug(slug)} className={getThumbClass(slug)} key={slug}>
                <LightboxImageItem
                  urlOrSlug={slug}
                  index={index}
                />
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}
