import React from 'react';
import { isEmpty } from 'lodash';
import classNames from 'classnames';

import CoreLink from '@reverbdotcom/commons/src/components/core_link';
import AdTag from '@reverbdotcom/commons/src/components/ads/ad_tag';
import { MParticleEventName, MParticleEvent } from '@reverbdotcom/commons/src/elog/mparticle_types';
import { useUser } from '@reverbdotcom/commons/src/user_hooks';
import { RCIcon, RCTextWithIcon } from '@reverbdotcom/cadence/components';
import { AngleRightIcon, ChevronRightIcon } from '@reverbdotcom/cadence/icons/react';
import { useViewTracking } from '@reverbdotcom/commons/src/use_tracking';
import { preferLightTheme } from '@reverbdotcom/cadence/components/helpers/themeOverrides';

const COMPONENT_NAME = 'PromotionalBannerTop';

export interface ISiteBanner {
  id: string;
  heading: string;
  subheading: string;
  callToAction: string;
  downtime: boolean;
  url: {
    href: string;
  };
  mobileWebUrl: {
    href: string;
  };
  contentSponsorship: boolean;
}

interface PromotionalBannerTopProps {
  siteBanner: ISiteBanner;
}

function buildClickEvent(siteBanner: ISiteBanner): MParticleEvent {
  return {
    eventName: MParticleEventName.SiteBanner,
    componentName: COMPONENT_NAME,
    entityId: siteBanner.id,
    isAd: !!siteBanner.contentSponsorship,
  };
}

export function PromotionalBannerTop(props: PromotionalBannerTopProps) {
  const user = useUser();
  const { siteBanner } = props;
  const hasBanner = siteBanner && !isEmpty(siteBanner);

  let displayStyle = 'default';
  if (siteBanner?.contentSponsorship) { displayStyle = 'ad'; }
  if (siteBanner?.downtime) { displayStyle = 'downtime'; }

  useViewTracking({
    eventName: MParticleEventName.ComponentView,
    componentName: 'SiteBanner',
    entityId: siteBanner?.id,
    displayStyle,
  }, hasBanner);

  if (!hasBanner) return null;

  const href = user?.deviceName === 'mobile-browser' ? siteBanner.mobileWebUrl.href : siteBanner.url.href;
  const classes = classNames(
    'promotional-banner-top',
    { 'promotional-banner-top--ad': !!siteBanner.contentSponsorship },
    { 'promotional-banner-top--downtime': siteBanner.downtime },
  );

  return (
    <div className={classes} {...preferLightTheme()}>
      <CoreLink
        to={href}
        clickEvent={buildClickEvent(siteBanner)}
      >
        <aside className="promotional-banner-top__inner">
          <p className="promotional-banner-top__content">
            <span className="promotional-banner-top__subheading">
              <RCTextWithIcon svgComponent={ChevronRightIcon}>
                {siteBanner.subheading}
              </RCTextWithIcon>
            </span>
            <span className="promotional-banner-top__heading">
              {siteBanner.heading}
            </span>
            <span className="promotional-banner-top__action">
              {siteBanner.callToAction}
            </span>
          </p>
          {siteBanner.contentSponsorship &&
            <div className="promotional-banner-top__ad">
              <AdTag small />
            </div>
          }
          <div className="promotional-banner-top__icon">
            <RCIcon svgComponent={AngleRightIcon} />
          </div>
        </aside>
      </CoreLink>
    </div>
  );
}

export default PromotionalBannerTop;
