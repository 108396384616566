import React from 'react';
import I18n from 'i18n-js';
import CoreLink from '../components/core_link';
import { MParticleEvent } from '../elog/mparticle_types';
import { CTA } from '../elog/mparticle_tracker';

interface Props {
  href: string;
  text?: string;
  clickEvent?: MParticleEvent;
  'aria-describedby'?: string;
}

export function OverflowingRowAction(props: Props) {
  return (
    <CoreLink
      className="weight-bold icon-r-arrow-right color-primary"
      to={props.href}
      clickEvent={props.clickEvent}
      interactionType={CTA}
      aria-describedby={props['aria-describedby']}
    >
      {props.text ? props.text : I18n.t('commons.seeMore')}
    </CoreLink>
  );
}
