import React from 'react';
import I18n from 'i18n-js';
import Modal from '@reverbdotcom/commons/src/components/modal';
import BumpV2ModalContainer, { BumpV2ModalContainerProps } from './bump_v2_modal_container';
import { bumpRecommendationsController, BumpControllerProps } from './bump_controller';
import { formatBid } from './bump_helpers';

const COMPONENT_NAME = 'ListingRowBumpButton';

interface SuccessCalloutProps {
  successMessage: string;
  bid: number;
}

function SuccessCallout(props: SuccessCalloutProps) {
  if (!props.successMessage) {
    return null;
  }

  const message = props.bid ? I18n.t('discovery.bump.successListingRow', { percent: formatBid(props.bid) }) : I18n.t('discovery.bump.successBidDelete');

  return (
    <div
      className="mini-bump-meter mini-bump-meter--new-bump scaling-mt-1"
    >
      {message}
    </div>
  );
}

export function ListingRowBumpButton(props: BumpV2ModalContainerProps) {
  const [showModal, setShowModal] = React.useState(false);

  return (
    <div className="align-center">
      {/* disabling inline here so we can enable this rule globally, but this should be fixed the next time this file is touched */}
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <a
        className="listing-row__action__trigger"
        onClick={() => setShowModal(true)}
      >
        {I18n.t('discovery.bumpModalV2.buttonListingRow')}
      </a>
      <SuccessCallout successMessage={props.successMessage} bid={props.bid} />
      <Modal
        isOpen={showModal}
        headerTitle={I18n.t('discovery.bumpModal.title')}
        onRequestClose={
          () => {
            setShowModal(false);
          }
        }
        paddedBody
        shouldCloseOnOverlayClick
      >
        <BumpV2ModalContainer
          bid={props.bid}
          listingId={props.listingId}
          selectedBid={props.selectedBid}
          handleBidChange={props.handleBidChange}
          updateBid={props.updateBid}
          successMessage={props.successMessage}
          failMessage={props.failMessage}
          refetch={() => {}}
          onRequestClose={
            () => {
              setShowModal(false);
            }
          }
        />
      </Modal>
    </div>
  );
}

const ListingRowBumpButtonContainer = bumpRecommendationsController(ListingRowBumpButton);

export default (props: BumpControllerProps) => {
  return (
    <ListingRowBumpButtonContainer
      componentName={COMPONENT_NAME}
      listingId={props.listingId}
      bid={props.bid}
      refetch={() => {}}
    />
  );
};
