import { MParticlePageName } from '@reverbdotcom/commons/src/elog/mparticle_types';
import React from 'react';
import { Route } from 'react-router';

export const EndedWatchedProductsRoute = (
  <Route
    key="watched_products/ended"
    path="watched_products/ended"
    props={{
      clientSide: true,
      pageViewEvent: {
        pageName: MParticlePageName.EndedWatchList,
      },
    }}
    getComponent={(_location, cb) => {
      import(
        '../components/watchlist/watchlist_container'
      ).then(m => cb(null, m.default));
    }}
  />
);

export const PublicEndedWatchedProductsRoute = (
  <Route
    key="watched_products/shared/:profile_slug/ended"
    path="watched_products/shared/:profile_slug/ended"
    props={{
      clientSide: true,
      pageViewEvent: {
        pageName: MParticlePageName.PublicEndedWatchList,
      },
    }}
    getComponent={(_location, cb) => {
      import(
        '../components/watchlist/public_watchlist_layout'
      ).then(m => cb(null, m.default));
    }}
  />
);
