import I18n from 'i18n-js';

export function buildElement(tag, text, options = {}) {
  const element = document.createElement(tag);
  const textNode = document.createTextNode(text);
  element.appendChild(textNode);

  Object.keys(options)
    .forEach(key => element.setAttribute(key, options[key]));

  return element.outerHTML;
}

export function translateElement(tag, translationKey, options = {}) {
  const text = I18n.t(translationKey);
  return buildElement(tag, text, options);
}
