import { ChildProps } from '@apollo/client/react/hoc';
// TODO update this to import from commons
// import { gql } from '@reverbdotcom/commons/src/gql';
// eslint-disable-next-line no-restricted-imports
import { gql } from '@apollo/client';
import I18n from 'i18n-js';
import React from 'react';
import { times } from 'lodash';

import { ListingsCollection } from '@reverbdotcom/commons/src/components/listings_collection';
import OverflowingRow from '@reverbdotcom/commons/src/components/overflowing_row';
import ListingCard from '@reverbdotcom/commons/src/components/listing_card';
import { MParticleEventName } from '@reverbdotcom/commons/src/elog/mparticle_tracker';
import { withGraphql } from '@reverbdotcom/commons/src/with_graphql';
import { useViewTracking } from '@reverbdotcom/commons/src/use_tracking';
import { useExpEnabled, useUser } from '@reverbdotcom/commons/src/user_hooks';
import experiments from '@reverbdotcom/commons/src/experiments';
import { formatExperimentsForEventAttributes } from '@reverbdotcom/commons/src/elog/mparticle';

import { SHOP_PAGE } from '../shared/url_helpers';
import { CoreListingCombineAndSaveRow, core_apimessages_ShippingMethod } from '@reverbdotcom/commons/src/gql/graphql';
import { RCListingGrid } from '@reverbdotcom/cadence/components';
import { OverflowingRowAction } from '@reverbdotcom/commons/src/components/overflowing_row_action';

const COMPONENT_NAME = 'CombineAndSaveRow';

interface IExternalProps {
  categoryRootUuid?: string;
  excludedListingIds: string[];
  maxStandardRateDisplayCents?: number;
  shopName: string;
  shopId: string;
  shopSlug: string;
  trackingEntityId?: string;
  displayAddToCart?: boolean;
  freeCombinedShippingParent?: boolean;
}

export type IProps = ChildProps<
IExternalProps,
CoreListingCombineAndSaveRow.Query,
CoreListingCombineAndSaveRow.Variables
>;

const filterListings = ({
  data,
  excludedListingIds,
  maxStandardRateDisplayCents,
}) => {
  const { loading, error, listingsSearch } = data;
  if (error || loading || !listingsSearch?.listings) { return []; }

  const excludedListingIdsSet = new Set(excludedListingIds);

  return listingsSearch.listings.filter((listing) => {
    if (excludedListingIdsSet.has(listing.id)) { return false; }

    const shippingPrices = listing.shipping?.shippingPrices || [];

    const standardShippingRate = shippingPrices.find(({ shippingMethod }) => shippingMethod === core_apimessages_ShippingMethod.SHIPPED);
    const combinedShippingRate = shippingPrices.find(({ shippingMethod }) => shippingMethod === core_apimessages_ShippingMethod.COMBINED_SHIPPING);

    if (!standardShippingRate?.rate || !combinedShippingRate?.rate) { return false; }

    const noMaxSpecified = [null, undefined, false].includes(maxStandardRateDisplayCents);
    return noMaxSpecified || standardShippingRate?.rate?.amountCents <= maxStandardRateDisplayCents;
  });
};

function ViewAllCTA(props) {
  const shopPageUrl = SHOP_PAGE.expand({
    slug: props.shopSlug,
    query: { combined_shipping: true },
  });

  const clickEvent = {
    eventName: MParticleEventName.ClickedCombineAndSaveViewAll,
    shopSlug: props.shopSlug,
    listingId: props.listingId,
  };

  return (
    <OverflowingRowAction
      href={shopPageUrl}
      clickEvent={clickEvent}
      text={I18n.t('discovery.listingsRow.viewAll')}
    />
  );
}

export function CombineAndSaveRow({
  data,
  excludedListingIds = [],
  maxStandardRateDisplayCents,
  shopName,
  shopSlug,
  trackingEntityId,
  displayAddToCart,
  freeCombinedShippingParent = false,
}: IProps) {
  const filteredListings = filterListings({ data, excludedListingIds, maxStandardRateDisplayCents });
  const resultsAvailable = filteredListings.length > 0;
  const user = useUser();
  const inExperiment = useExpEnabled(experiments.COMBINED_SHIPPING_EXPANSION);

  useViewTracking({
    componentName: COMPONENT_NAME,
    eventName: MParticleEventName.ComponentView,
    freeCombinedShippingParent,
    experiments: formatExperimentsForEventAttributes(user, [experiments.COMBINED_SHIPPING_EXPANSION]),
  }, resultsAvailable);

  const shouldDisplay = freeCombinedShippingParent ? (resultsAvailable && inExperiment) : resultsAvailable;

  if (!shouldDisplay) { return null; }

  return (
    <OverflowingRow
      title={I18n.t('discovery.listingsRow.combineAndSaveTitle')}
      ledeHtml={I18n.t('discovery.listingsRow.combineAndSaveLedeHtml', {
        shopName,
        tagOpen: '<div class="mb-2">',
        tagClose: '</div>',
      })}
      action={<ViewAllCTA shopSlug={shopSlug} listingId={trackingEntityId} />}
    >
      <RCListingGrid
        loading={data.loading}
        singleRow
      >
        {data.loading && filteredListings.length === 0 ? (
          times(6, index => (
            <RCListingGrid.Item key={index}>
              <ListingCard listing={null} />
            </RCListingGrid.Item>
          ))
        ) : (
          filteredListings.map((listing, idx) => (
            <RCListingGrid.Item key={listing.id}>
              <ListingCard
                listing={listing}
                position={idx}
                combinedShippingDiscount
                displayAddToCart={displayAddToCart}
                trackingName={COMPONENT_NAME}
              />
            </RCListingGrid.Item>
          ))
        )}
      </RCListingGrid>
    </OverflowingRow>
  );
}

export const COMBINE_AND_SAVE_ROW_QUERY = gql`
    query Core_Listing_CombineAndSaveRow(
      $combinedShipping: Boolean
      $boostCombinedShippingCategories: [String]
      $shopId: String
    ) {
      listingsSearch(
        input: {
          combinedShipping: $combinedShipping
          boostCombinedShippingCategories: $boostCombinedShippingCategories
          shopId: $shopId
        }
      ) {
        listings {
          _id
          ...ListingsCollection
        }
      }
    }
    ${ListingsCollection.fragments.listing}
  `;

const withSearch = withGraphql<
IExternalProps,
CoreListingCombineAndSaveRow.Query,
CoreListingCombineAndSaveRow.Variables
>(
  COMBINE_AND_SAVE_ROW_QUERY,
  {
    options: ({ categoryRootUuid, shopId }) => ({
      ssr: false,
      variables: {
        combinedShipping: true,
        boostCombinedShippingCategories: categoryRootUuid ? [categoryRootUuid] : [],
        shopId,
      },
    }),
  },
);

export default withSearch(CombineAndSaveRow);
