import React from 'react';
import I18n from 'i18n-js';
import { RCModalConfirmation } from '@reverbdotcom/cadence/components';
import { TrashOIcon } from '@reverbdotcom/cadence/icons/react';

interface ModalConfirmationProps {
  title: string;
  content: React.ReactNode;
  confirmButtonText?: string;
  isOpen: boolean;
  onConfirm: () => void;
  onRequestClose: () => void;
  theme?: 'danger' | 'main';
  icon?: (props: React.SVGProps<SVGElement>) => React.ReactElement;
}

/** Deprecated. Use RCModalConfirmation. */
export default function ModalConfirmation({
  title,
  content,
  confirmButtonText = I18n.t('commons.modalConfirmation.confirm'),
  isOpen,
  onConfirm,
  onRequestClose,
  theme = 'danger',
  icon = TrashOIcon,
}: ModalConfirmationProps) {


  return (
    <RCModalConfirmation
      isOpen={isOpen}
      onOpenChange={(state) => {
        if (state === false) onRequestClose();
      }}
      title={title}
      svgComponent={icon}
      actions={{
        primary: {
          buttonText: confirmButtonText,
          theme: theme,
          onClick: onConfirm,
          preventCloseOnClick: true,
        },
      }}
    >
      {content}
    </RCModalConfirmation>
  );
}
