import experiments from '@reverbdotcom/commons/src/experiments';
import { IUser } from '@reverbdotcom/commons/src/components/user_context_provider';

export const ALWAYS_TRACKED_EXPERIMENTS = [
  experiments.LISTING_CARD_LOCATION,
  experiments.DUMMY,
  experiments.MV_DUMMY_COOKIE_BUCKETING,
  experiments.MARKETPLACE_GRID_ADS,
  experiments.AA_MARKETPLACE_SESSION_BUCKETING,
  experiments.SRP_EXPOSED_FILTERS_V2_DESKTOP,
];

interface PageViewTrackedExperimentsParams {
  user?: Partial<IUser>;
}

export function pageViewTrackedExperiments({
  user,
}: PageViewTrackedExperimentsParams): string[] {
  const searchRelevanceExperiments = (user?.experiments?.filter(exp => exp.use_in_marketplace_listings_search) || []).map(exp => exp.name);

  return searchRelevanceExperiments.concat(ALWAYS_TRACKED_EXPERIMENTS);
}
