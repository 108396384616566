import I18n from 'i18n-js';
import React, { useReducer, useRef, useState } from 'react';

import { RCTextInput, RCTextWithIcon, RCSelect, RCButton, RCFormGroup, RCLoadingBars } from '@reverbdotcom/cadence/components';
import { US } from '@reverbdotcom/commons/src/constants';
import SharedTabs from '@reverbdotcom/commons/src/components/shared_tabs';
import SharedTab from '@reverbdotcom/commons/src/components/shared_tab';
import {
  ElectricIcon,
  EffectsIcon,
  DrumsIcon,
  MicrophoneIcon,
  KeyboardsIcon,
  AmpsIcon,
} from '@reverbdotcom/cadence/icons/react';

import { SellFormKeyValueList } from '../sell/form/components/SellFormKeyValueList';
import { V3 } from '../api_request';
import URLHelpers from '../shared/url_helpers';
import { ShippingLabelOutageBanner } from './ShippingLabelOutageBanner';

export interface IEstimationResult {
  description: string;
  rates: {
    display_name: string;
    price: {
      amount: string;
      amount_cents: number;
      currency: string;
      symbol: string;
      display: string;
    },
  }[];
}

interface IFormState {
  postalCode: string;
  destinationRegionCode: string;
  weightPounds: string;
  lengthInches: string;
  widthInches: string;
  heightInches: string;
}

interface IAction {
  type: 'setField' | 'setExample';
  value: string;
  field?: string;
}

const GUITAR = 'guitar';
const PEDAL = 'pedal';
const SNARE = 'snare';
const CABLE = 'cable';
const KEYBOARD = 'keyboard';
const AMP = 'amp';

const PRESETS = {
  [GUITAR]: {
    icon: ElectricIcon,
    dimensions: {
      weightPounds: '32',
      lengthInches: '50',
      widthInches: '20',
      heightInches: '8',
    },
  },
  [PEDAL]: {
    icon: EffectsIcon,
    dimensions: {
      weightPounds: '1.5',
      lengthInches: '8',
      widthInches: '5',
      heightInches: '4',
    },
  },
  [SNARE]: {
    icon: DrumsIcon,
    dimensions: {
      weightPounds: '12',
      lengthInches: '18',
      widthInches: '18',
      heightInches: '10',
    },
  },
  [CABLE]: {
    icon: MicrophoneIcon,
    dimensions: {
      weightPounds: '0.75',
      lengthInches: '8.5',
      widthInches: '11',
      heightInches: '1',
    },
  },
  [KEYBOARD]: {
    icon: KeyboardsIcon,
    dimensions: {
      weightPounds: '40',
      lengthInches: '50',
      widthInches: '20',
      heightInches: '8',
    },
  },
  [AMP]: {
    icon: AmpsIcon,
    dimensions: {
      weightPounds: '50',
      lengthInches: '30',
      widthInches: '14',
      heightInches: '22',
    },
  },
};

// Our label estimator only provides estimates for countries.
// If the user selects an non-country region,
// we just estimate cost for a country in that region
// (e.g. estimate to Germany if user selects EU).
const EXAMPLE_REGION_TO_COUNTRY = {
  EUR_EU: 'DE',
  EUR_NON_EU: 'NO',
  NORTH_AMERICA: 'US',
  SOUTH_AMERICA: 'BR',
  OCEANIA: 'AU',
  ASIA: 'JP',
  AFRICA: 'ZA',
};

const TOP_COUNTRY_CODES = ['US', 'CA', 'GB', 'AU', 'FR', 'DE', 'JP', 'RU'];
const EXAMPLE_REGION_CODES = Object.keys(EXAMPLE_REGION_TO_COUNTRY);
const OTHER_COUNTRY_CODES = [
  'AR',
  'AT',
  'BE',
  'BG',
  'BR',
  'CH',
  'CN',
  'CZ',
  'DK',
  'EE',
  'ES',
  'FI',
  'GR',
  'HR',
  'IE',
  'IL',
  'IN',
  'IS',
  'IT',
  'KR',
  'LT',
  'LU',
  'LV',
  'MT',
  'MX',
  'NL',
  'NO',
  'NZ',
  'PL',
  'PT',
  'SE',
  'SI',
  'SK',
  'VN',
  'ZA',
];

const INITIAL_STATE = {
  postalCode: '',
  destinationRegionCode: US,
  weightPounds: '',
  lengthInches: '',
  widthInches: '',
  heightInches: '',
};

export function formReducer(state: IFormState, action: IAction): IFormState {
  if (action.type === 'setField') {
    return {
      ...state,
      [action.field]: action.value,
    };
  }

  if (action.type === 'setExample') {
    return {
      ...state,
      ...PRESETS[action.value].dimensions,
    };
  }

  return state;
}

function buildFetchUrl(formState: IFormState): string {
  let to_countrycode;
  if (EXAMPLE_REGION_CODES.includes(formState.destinationRegionCode)) {
    to_countrycode = EXAMPLE_REGION_TO_COUNTRY[formState.destinationRegionCode];
  } else {
    to_countrycode = formState.destinationRegionCode;
  }

  return URLHelpers.apiShippingEstimate({
    to_countrycode,
    from_postal: formState.postalCode,
    weight: formState.weightPounds,
    length: formState.lengthInches,
    width: formState.widthInches,
    height: formState.heightInches,
    metric: false,
    from_countrycode: US,
  });
}

interface IEstimationResultProps {
  loading: boolean;
  estimationResult: IEstimationResult;
  hasError: boolean;
}

export function EstimationResult({ loading, estimationResult, hasError }: IEstimationResultProps) {
  if (!loading && !estimationResult && !hasError) { return null; }

  return (
    <div className="shipping-cost-estimator__results">
      <hr />

      {loading && (
        <div className="scaling-padding-4 d-flex fx-justify-center">
          <RCLoadingBars/>
        </div>
      )}

      {!loading && (
        <>
          <p className="mt-4">
            {hasError && I18n.t('discovery.shippingEstimator.error')}
            {!hasError && I18n.t('discovery.shippingEstimator.resultsDisclaimer')}
          </p>

          {estimationResult && (
            <>
              <strong>
                {estimationResult.description}
              </strong>

              <SellFormKeyValueList>
                {estimationResult.rates.map((r) => (
                  <SellFormKeyValueList.Row label={r.display_name} key={r.display_name}>
                    {r.price.display}
                  </SellFormKeyValueList.Row>
                ))}
              </SellFormKeyValueList>

              <p className="shipping-cost-estimator__rsl__text">
                {I18n.t('discovery.shippingEstimator.buyReverb')}
              </p>

              <ShippingLabelOutageBanner />
            </>
          )}
        </>
      )}
    </div>
  );
}

export function ShippingCostEstimator() {
  const [formState, dispatch] = useReducer(formReducer, INITIAL_STATE);
  const [showInternational, setShowInternational] = useState(false);
  const [estimationResult, setEstimationResult] = useState<IEstimationResult>(null);
  const [loading, setLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const scrollRef = useRef<HTMLInputElement>();

  function fetchEstimates() {
    setEstimationResult(null);
    setHasError(false);
    setLoading(true);

    const fetchUrl = buildFetchUrl(formState);
    V3.get(fetchUrl)
      .then((response) => {
        if (!response?.estimated_shipping?.rates || response.estimated_shipping.rates.length < 1) {
          setHasError(true);
        } else {
          setEstimationResult(response.estimated_shipping);
        }

        setLoading(false);
        if (scrollRef?.current?.scrollIntoView) { scrollRef.current.scrollIntoView(); }
      })
      .fail(() => {
        setHasError(true);
        setLoading(false);
      });
  }

  return (
    <>
      <div className="shipping-cost-estimator">
        <div className="shipping-cost-estimator__form">
          <div className="shipping-cost-estimator__tabs">
            <SharedTabs smallTabs>
              <SharedTab
                activeOverride={!showInternational}
                text={I18n.t('discovery.shippingEstimator.domestic')}
                onClick={(e) => {
                  e.preventDefault();
                  dispatch({ type: 'setField', field: 'destinationRegionCode', value: US });
                  setShowInternational(false);
                }}
              />
              <SharedTab
                activeOverride={showInternational}
                text={I18n.t('discovery.shippingEstimator.international')}
                onClick={(e) => { e.preventDefault(); setShowInternational(true); }}
              />
            </SharedTabs>
          </div>

          <form onSubmit={(e) => {
            e.preventDefault();
            e.stopPropagation();
            fetchEstimates();
          }}>
            <RCTextInput
              id="postalCode"
              name="postalCode"
              label={I18n.t('discovery.shippingEstimator.formLabel.postalCode')}
              value={formState.postalCode}
              onChange={(event) => dispatch({ type: 'setField', field: 'postalCode', value: event.target.value })}
              disabled={loading}
              inputMode="decimal"
              tag="required"
              required
            />

            {showInternational && (
              <RCSelect
                id="destinationRegionCode"
                name="destinationRegionCode"
                label={I18n.t('discovery.shippingEstimator.formLabel.toCountryCode')}
                onChange={(event) => dispatch({ type: 'setField', field: 'destinationRegionCode', value: event.target.value })}
                value={formState.destinationRegionCode}
                required
                addEmptyOption
              >
                <RCSelect.OptionGroup>
                  {TOP_COUNTRY_CODES.map((code) => (
                    <RCSelect.Option
                      key={code}
                      label={I18n.t(`discovery.shippingRegions.${code}`)}
                      value={code}
                    />
                  ))}
                </RCSelect.OptionGroup>

                <RCSelect.OptionGroup>
                  {EXAMPLE_REGION_CODES.map((code) => (
                    <RCSelect.Option
                      key={code}
                      label={I18n.t(`discovery.shippingRegions.${code}`)}
                      value={code}
                    />
                  ))}
                </RCSelect.OptionGroup>

                <RCSelect.OptionGroup>
                  {OTHER_COUNTRY_CODES.map((code) => (
                    <RCSelect.Option
                      key={code}
                      label={I18n.t(`discovery.shippingRegions.${code}`)}
                      value={code}
                    />
                  ))}
                </RCSelect.OptionGroup>
              </RCSelect>
            )}

            <RCTextInput
              id="weightPounds"
              name="weightPounds"
              label={I18n.t('discovery.shippingEstimator.formLabel.weightPounds')}
              value={formState.weightPounds}
              onChange={(event) => dispatch({ type: 'setField', field: 'weightPounds', value: event.target.value })}
              disabled={loading}
              suffixText={I18n.t('discovery.shippingEstimator.formLabel.poundsSuffix')}
              inputMode="decimal"
              tag="required"
              required
            />

            <RCFormGroup
              inputId="lengthInches"
              label={I18n.t('discovery.shippingEstimator.formLabel.packageDimensions')}
            >
              <div className="shipping-cost-estimator__package__row">
                <div className="shipping-cost-estimator__package__input">
                  <RCTextInput
                    id="lengthInches"
                    name="lengthInches"
                    label={I18n.t('discovery.dashboard.selling.shippingLabels.package.length')}
                    value={formState.lengthInches}
                    onChange={(event) => dispatch({ type: 'setField', field: 'lengthInches', value: event.target.value })}
                    disabled={loading}
                    suffixText={I18n.t('discovery.shippingEstimator.formLabel.inchesSuffix')}
                    inputMode="decimal"
                    tag="required"
                    required
                  />
                </div>
                <div className="shipping-cost-estimator__package__input">
                  <RCTextInput
                    id="widthInches"
                    name="widthInches"
                    label={I18n.t('discovery.dashboard.selling.shippingLabels.package.width')}
                    value={formState.widthInches}
                    onChange={(event) => dispatch({ type: 'setField', field: 'widthInches', value: event.target.value })}
                    disabled={loading}
                    suffixText={I18n.t('discovery.shippingEstimator.formLabel.inchesSuffix')}
                    inputMode="decimal"
                    tag="required"
                    required
                  />
                </div>
                <div className="shipping-cost-estimator__package__input">
                  <RCTextInput
                    id="heightInches"
                    name="heightInches"
                    label={I18n.t('discovery.dashboard.selling.shippingLabels.package.height')}
                    value={formState.heightInches}
                    onChange={(event) => dispatch({ type: 'setField', field: 'heightInches', value: event.target.value })}
                    disabled={loading}
                    suffixText={I18n.t('discovery.shippingEstimator.formLabel.inchesSuffix')}
                    inputMode="decimal"
                    tag="required"
                    required
                  />
                </div>
              </div>
            </RCFormGroup>

            <RCButton
              disabled={loading}
              variant="filled"
              isSubmit
            >
              {I18n.t('discovery.shippingEstimator.getEstimate')}
            </RCButton>
          </form>

        </div>

        <div className="shipping-cost-estimator__example__container">
          <div className="shipping-cost-estimator__example__box shipping-cost-estimator__example__box--centered">
            <div>
              <strong>
                {I18n.t('discovery.shippingEstimator.examples.header')}
              </strong>
            </div>
          </div>

          {Object.keys(PRESETS).map((exampleKey) => (
            <div key={exampleKey} className="shipping-cost-estimator__example__box">
              <div>
                <div>
                  <RCTextWithIcon
                    svgComponent={PRESETS[exampleKey].icon}
                    title={I18n.t(`discovery.shippingEstimator.examples.name.${exampleKey}`)}
                    size="large"
                    placement="left"
                  >
                    {I18n.t('discovery.shippingEstimator.examples.measurementSummary', {
                      weight: PRESETS[exampleKey].dimensions.weightPounds,
                      length: PRESETS[exampleKey].dimensions.lengthInches,
                      width: PRESETS[exampleKey].dimensions.widthInches,
                      height: PRESETS[exampleKey].dimensions.heightInches,
                    })}
                  </RCTextWithIcon>
                </div>
                <div>
                  <RCButton
                    onClick={() => dispatch({ type: 'setExample', value: exampleKey })}
                    size="small"
                  >
                    {I18n.t('discovery.shippingEstimator.examples.useMeasurements')}
                  </RCButton>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div ref={scrollRef} />
      <EstimationResult estimationResult={estimationResult} loading={loading} hasError={hasError}/>
    </>
  );
}

export default ShippingCostEstimator;
