import window from './window_wrapper';

const TRACK = 'track';
export const VIEW_CONTENT_EVENT = 'ViewContent';
export const LEAD_EVENT = 'Lead';
export const ADD_TO_CART_EVENT = 'AddToCart';
export const PRODUCT_CONTENT_TYPE = 'Product';
export const MAILING_LIST_CONTENT_NAME = 'mailing_list';
export const INITIATE_CHECKOUT = 'InitiateCheckout';

// Note that the facebook pixel is initialized from core (https://github.com/reverbdotcom/reverb/blob/ec981aa1ea1de8fe7cca8c95e0059cde6337355f/app/views/shared/external/_facebook_pixel.erb)
// and handles the logic of it the user has opted out of third party ad data consent because the fbq function will not be added to the window

export function trackFacebookEvent(eventName, eventData = {}, eventOptions = {}) {
  if (window.fbq !== undefined) {
    window.fbq(
      TRACK,
      eventName,
      eventData,
      eventOptions,
    );
  }
}
