import React from 'react';
import I18n from 'i18n-js';

import { RCAccordion, RCTextWithIcon, RCTag } from '@reverbdotcom/cadence/components';
import { CheckCircleIcon, CommentsIcon, TimesCircleIcon } from '@reverbdotcom/cadence/icons/react';

import { I18N as Translate } from '@reverbdotcom/commons/src/components/translate';

import UrlHelpers from '../shared/url_helpers';
import { HOURS_TO_CHECK_OUT_WHEN_CONDITIONALLY_ACCEPTED } from './update_offer_modal';


interface Props {
  isSeller: boolean;
}

export default function OfferActionsExplanation({ isSeller }: Props) {
  if (!isSeller) {
    return null;
  }

  return (
    <div className="mb-4">
      <RCAccordion
        id="offer-actions-explanation"
        heading={I18n.t('discovery.offers.actionsExplanation.headline')}
        defaultOpen={true}
      >
        <div className="d-flex mobile-fx-dir-col">
          <div className="width-25 mobile-width-100 mr-6">
            <RCTextWithIcon
              svgComponent={CheckCircleIcon}
              placement="left"
            >
              <strong>{I18n.t('discovery.offers.actionsExplanation.seller.accept.title')}</strong>
            </RCTextWithIcon>

            <p className="mt-1">
              {I18n.t('discovery.offers.actionsExplanation.seller.accept.body')}
            </p>
          </div>
          <div className="width-25 mobile-width-100 mr-6">
            <RCTextWithIcon
              svgComponent={CommentsIcon}
              placement="left"
            >
              <strong>{I18n.t('discovery.offers.actionsExplanation.seller.counter.title')}</strong>
            </RCTextWithIcon>

            <p className="mt-1 mb-2">
              {I18n.t('discovery.offers.actionsExplanation.seller.counter.body')}
            </p>

            <RCTag variant="highlight">
              {I18n.t('discovery.offers.actionsExplanation.seller.counter.conditionalAcceptance.getPaidFaster')}
            </RCTag>

            <p>
              {I18n.t('discovery.offers.actionsExplanation.seller.counter.conditionalAcceptance.body', { count: HOURS_TO_CHECK_OUT_WHEN_CONDITIONALLY_ACCEPTED })}
            </p>
          </div>
          <div className="width-25 mobile-width-100">
            <RCTextWithIcon
              svgComponent={TimesCircleIcon}
              placement="left"
            >
              <strong>{I18n.t('discovery.offers.actionsExplanation.seller.decline.title')}</strong>
            </RCTextWithIcon>

            <p className="mt-1">
              {I18n.t('discovery.offers.actionsExplanation.seller.decline.body')}
            </p>
          </div>
        </div>

        <div>
          <Translate
            html
            text="discovery.offers.actionsExplanation.seller.setupOfferAutomation"
            args={{
              linkOpen: `<a href="${UrlHelpers.shopPoliciesEditPath}" target="_blank" class="text-link">`,
              linkClose: '</a>',
            }}
          />
        </div>
      </RCAccordion>
    </div>
  );
}
