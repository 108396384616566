import * as elog from '@reverbdotcom/commons/src/elog';
import { IUser } from '@reverbdotcom/commons/src/components/user_context_provider';
import { MParticleEventName, trackEvent } from '@reverbdotcom/commons/src/elog/mparticle_tracker';
import { LOAD_CHAT_WINDOW } from './shared/constants';
import { DEPLOY_ENV } from '@reverbdotcom/env';

const handle = DEPLOY_ENV === 'production' ? 'reverb' : 'demo-sandbox-reverb';

export function loadAdaChat(token, user: Partial<IUser>) {
  loadAdaEmbedJS().then(() => {
    startAdaChatWIthConfig(token, user);
  }).catch((error) => {
    elog.error(
      'adaChat.load.error',
      { error: error.message },
      error,
    );
  });
}

function loadAdaEmbedJS() {
  const el = document.createElement('script');
  el.id = '__ada';
  el.type = 'text/javascript';
  el.async = true;
  el.src = 'https://static.ada.support/embed2.js';
  el.dataset.handle = handle;
  el.dataset.lazy = '';
  document.body.appendChild(el);

  return new Promise((res, rej) => {
    el.onload = res;
    el.onerror = rej;
  });
}

function startAdaChatWIthConfig(token, user: Partial<IUser>) {
  (window as any).adaEmbed.start({
    handle,
    metaFields: {
      metaEmail: user.email,
      metaName: user.name,
      metaAuthToken: token,
      meta_platform: 'web',
    },
    crossWindowPersistence: {
      enabled: true,
    },
    eventCallbacks: {
      'log_event': (event) => {
        // we can log individual answers served in realtime using this callback.
        // to opt-in an answer for logging, add a 'fire event' block to the end of the 'answer content'
        // the event name for the new fire event block should be: log_event
        trackAdaChatAnswer(event);
      },
    },
    adaReadyCallback: () => {
      (window as any).adaEmbed.toggle().then(() => {
        trackEvent({
          eventName: MParticleEventName.AdaChatOpenSuccess,
        });
      });
    },
    conversationEndCallback: (event) => {
      // ada chat manually ended by user
      trackAdaChatEnd(event);
    },
  });
}

export function onVerificationPage() {
  const searchParams = new URLSearchParams(window.location.search);
  return !!searchParams.has(LOAD_CHAT_WINDOW);
}

export function trackAdaChatAnswer(event) {
  const {
    initialurl,
    language,
    last_answer_id,
    last_question_asked,
  } = event?.user_data?.all_data ?? {};

  trackEvent({
    eventName: MParticleEventName.AdaChatAnswerServed,
    initialUrl: initialurl,
    language,
    lastAnswerId: last_answer_id,
    lastQuestionAsked: last_question_asked,
  });
}

export function trackAdaChatEnd(event) {
  const {
    initialurl,
    language,
    last_answer_id,
    last_question_asked,
  } = event?.event_data?.user_data?.all_data ?? {};

  trackEvent({
    eventName: MParticleEventName.AdaChatConversationManuallyEnded,
    initialUrl: initialurl,
    language,
    lastAnswerId: last_answer_id,
    lastQuestionAsked: last_question_asked,
  });
}
