import qs from 'qs';
import { ApolloClient, MutationOptions, FetchResult, InMemoryCache, ApolloLink, HttpLink, Operation } from '@apollo/client';
import { RetryLink } from '@apollo/client/link/retry';
import { IUser } from '@reverbdotcom/commons/src/components/user_context_provider';
import { getMainDefinition } from '@apollo/client/utilities';
import { removeTypenameFromVariables } from '@apollo/client/link/remove-typename';
import { pickBy, join } from 'lodash';
import { onError } from '@apollo/client/link/error';
import { core_apimessages_Channel } from '@reverbdotcom/commons/src/gql/graphql';
import { cacheOptions, apolloInputCoercionLink, apolloClientUri } from '@reverbdotcom/commons/src/apollo_client';
import * as elog from '@reverbdotcom/commons/src/elog';
import ReverbCookie from '../components/reverb_cookie';
import { isExperimentEnabled } from '@reverbdotcom/commons/src/user_context_helpers';
import experiments from '@reverbdotcom/commons/src/experiments';
import location from './wrapped_location';
import { flashExpireAuthError } from '@reverbdotcom/commons/src/flash';
import Window from '@reverbdotcom/commons/src/window_wrapper';
import { GraphQLErrors } from '@apollo/client/errors';
import { reverbDeviceInfoHeader } from '@reverbdotcom/commons/src/headers/reverbDeviceInfoHeader';
import { reverbUserInfoHeader } from '@reverbdotcom/commons/src/headers/reverbUserInfoHeader';

const inMemoryCache = new InMemoryCache(cacheOptions);

const APOLLO_HEADER_EXPERIMENTS = [
  experiments.PROXIMITY_FEATURES,
];

if (window.__APOLLO_STATE__) {
  inMemoryCache.restore(window.__APOLLO_STATE__);
}

// Datadog RUM replaces window.fetch with a proxy so it can inject trace headers.
// Passing this wrapper to Apollo means window.fetch gets resolved at the time of use,
// so we don't run into init ordering issues between Apollo and Datadog.
const fetchWrapper: typeof Window.fetch = (input, init) => Window.fetch(input, init);

// Apollo's typing can also return void (in case of onCompleted/onError callbacks):
// https://github.com/apollographql/react-apollo/issues/2095
// This definition allows us to not check for a void result.
export type MutationFunction<TResult, TVariables> =
  (options?: MutationOptions<TResult, TVariables>) => Promise<FetchResult<TResult>>;

const EXPIRED_TOKEN_ERROR = 'EXPIRED_TOKEN_ERROR';
const NON_REPORTABLE_ERRORS = ['CLIENT_ERROR', 'NOT_FOUND_ERROR'];

export function buildClient(user: IUser) {
  const uri = (operation: Operation) => apolloClientUri(operation, user);

  return new ApolloClient({
    cache: inMemoryCache,
    connectToDevTools: true,
    link: ApolloLink.from([
      removeTypenameFromVariables(),
      apolloInputCoercionLink,
      onError(({ graphQLErrors, networkError, operation }) => {
        onNetworkErrors(networkError, operation);
        onGraphqlErrors(graphQLErrors, operation);
      }),
      new ApolloLink((operation, forward) => {
        operation.setContext(({ headers }) => ({
          headers: pickBy({
            authorization: bearer(user),
            ...headers,
          }),
        }));

        return forward(operation);
      }),
      new RetryLink({
        delay: {
          initial: 300,
          max: 10000,
          jitter: true,
        },
        attempts: {
          max: 1,
          retryIf: (error, graphqlOperation) => {
            // This will ONLY get triggered on a network error, not a GraphQL error
            const definition = getMainDefinition(graphqlOperation.query);
            return !!error && definition.kind === 'OperationDefinition' && definition.operation === 'query';
          },
        },
      }),
      new HttpLink({
        fetch: fetchWrapper,
        uri,
        headers: pickBy({
          'X-Item-Region': user.itemRegionOverride,
          'X-Display-Currency': user.currency,
          'X-Shipping-Region': user.shippingRegionCode,
          'Accept-Language': user.fiveDigitLocale,
          'X-Context-Id': user.cookieId,
          'X-Request-Id': user.requestId,
          'X-Session-Id': user.sessionId,
          'X-Reverb-App': core_apimessages_Channel.REVERB,
          'X-Experiments': experimentsHeader(user),
          'X-Postal-Code': user.postalCode,
          'X-Secondary-User-Enabled': (!!user.secondaryUserModeIsActive).toString(),
          ...reverbDeviceInfoHeader(user),
          ...reverbUserInfoHeader(user),
        }),
      }),
    ]),
  });
}

function guestToken() {
  const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true });
  return queryParams?.gt;
}

function tryGuestBearer() {
  const gt = guestToken();

  if (!gt) return;

  return `Bearer ${gt}`;
}

export function experimentsHeader(user, experiments = APOLLO_HEADER_EXPERIMENTS) {
  const enabledExperiments = experiments.filter(experiment => isExperimentEnabled(user, experiment));
  if (!enabledExperiments.length) { return; }
  return enabledExperiments.join(',');
}

export function bearer(user) {
  if (user.loggedOut) {
    return tryGuestBearer();
  }

  const tokenv1 = ReverbCookie.get('reverb_user_oauth_token');
  const tokenv2 = ReverbCookie.get('reverb_user_oauth_token_v2');

  if (!tokenv1 && !tokenv2) {
    // at the end of guest checkout, the user will not be "loggedOut", but they
    // also will not have an oauth token
    const guestBearer = tryGuestBearer();

    if (!guestBearer) {
      elog.error(
        'auth.user_token_mismatch',
        {
          user_id: user?.id,
        },
      );
    }

    return guestBearer;
  }

  if (tokenv2) return `BearerV2 ${tokenv2}`;

  return `Bearer ${tokenv1}`;
}

function onNetworkErrors(networkError, operation) {
  if (networkError) {
    const errorName = `Apollo Network Error - ${operation.operationName}`;
    const err = new Error(`${errorName} - ${networkError.message}`);

    elog.error(
      errorName,
      {
        message: networkError.message,
        operation: operation.operationName,
      },
      err,
      false, // do not report to sentry
    );
  }
}

function onGraphqlErrors(graphQLErrors: GraphQLErrors, operation: Operation) {
  // this is sometimes undefined
  // https://sentry.io/organizations/reverb-llc/issues/1485389339/?query=is%3Aunresolved
  if (graphQLErrors) {
    onUnauthorizedErrors(graphQLErrors);

    // Don't report client errors to Sentry
    const errors = graphQLErrors.filter(({ extensions }) =>
      extensions && !NON_REPORTABLE_ERRORS.includes(extensions.code as string),
    );

    if (errors.length) {
      const errorName = `GraphQL Error - ${operation.operationName}`;
      const err = new Error(`${errorName} - ${errors[0].message}`);

      elog.error(
        errorName,
        {
          operation: operation.operationName,
          graphQLErrors: graphQLErrors && graphQLErrors.map(({
            message,
            path,
            extensions,
            locations,
          }) => ({
            locations,
            message,
            extensions,
            path: join(path || [], '.'),
          })),
        },
        err,
      );
    }
  }
}

export function onUnauthorizedErrors(graphQLErrors: GraphQLErrors) {
  const unauthorized = graphQLErrors.find(({ extensions }) => extensions && extensions.code === EXPIRED_TOKEN_ERROR);
  if (unauthorized) flashExpireAuthError();
}
