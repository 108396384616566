import React from 'react';
import template from '@reverbdotcom/url-template';
import classNames from 'classnames';
import { get } from 'lodash';

import { Commons_Cms_CuratedSetCollectionQuery } from '../../gql/graphql';
import CoreLink from '../../components/core_link';
import { imageUploadURL } from '../../url_helpers';

const curatedSetLinkParams = template.parse('{?product_type}{&category}');

export const BASIC_TILE = 'basicTile';
export const FEATURE_TILE = 'featureTile';
export const TWO_IMAGE_TILE = 'twoImageTile';

export const CURATED_SET_TYPES = [
  BASIC_TILE,
  FEATURE_TILE,
  TWO_IMAGE_TILE,
];

type Commons_Cms_CuratedSetCollectionQuery_CuratedSets = Commons_Cms_CuratedSetCollectionQuery['curatedSets'][0];

interface IProps {
  curatedSet?: Commons_Cms_CuratedSetCollectionQuery_CuratedSets;
  position?: number;
  rootCategorySlug?: string;
  leafCategorySlug?: string;
  inMobileApp?: boolean;

  bannerOverride?: string;
  squareImage?: string;
  titleOverride?: string;

  // maybe one day these can be consts
  // https://github.com/Microsoft/TypeScript/issues/3964
  displayAs?: 'featureTile' | 'twoImageTile' | 'basicTile';
}

export default class CuratedSetCard extends React.Component<IProps, null> {
  getHref() {
    const params = {
      product_type: this.props.rootCategorySlug,
      category: this.props.leafCategorySlug,
    };
    return this.props.curatedSet.webLink.href + curatedSetLinkParams.expand(params);
  }

  getBackgroundImageStyle() {
    const src = this.props.bannerOverride ?
      imageUploadURL(this.props.bannerOverride) :
      get(this.props.curatedSet.images[0], 'source');

    if (!src) return null;
    return { backgroundImage: `url(${src})` };
  }

  getClasses() {
    return classNames(
      'curated-set-card',
      { 'curated-set-card--placeholder': !this.props.curatedSet },
      { 'curated-set-card--basic': !this.props.displayAs || this.props.displayAs === BASIC_TILE },
      { 'curated-set-card--two-image': this.props.displayAs === TWO_IMAGE_TILE },
      { 'curated-set-card--feature': this.props.displayAs === FEATURE_TILE },
    );
  }

  renderSquareImage() {
    if (this.props.displayAs !== TWO_IMAGE_TILE) return null;

    return (
      <div className="curated-set-card__square-image">
        <div
          className="curated-set-card__square-image__inner"
          style={{ backgroundImage: `url(${imageUploadURL(this.props.squareImage)})` }}
        />
      </div>
    );
  }

  renderPlaceholderCard() {
    return (
      <div className={this.getClasses()}>
        <div className="curated-set-card__link">
          <div className="curated-set-card__image">
            <div className="curated-set-card__image__inner" />
          </div>
          <div className="curated-set-card__text">
            <div className="curated-set-card__text__inner">
              {this.renderSquareImage()}
              <div className="curated-set-card__text__title" />
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    if (!this.props.curatedSet) { return this.renderPlaceholderCard(); }

    return (
      <div className={this.getClasses()}>
        <CoreLink
          className="curated-set-card__link"
          to={this.getHref()}
          entityId={this.props.curatedSet.id}
          position={this.props.position}
        >
          <div className="curated-set-card__image">
            <div className="curated-set-card__image__inner"
              style={this.getBackgroundImageStyle()}
            />
          </div>
          <div className="curated-set-card__text">
            <div className="curated-set-card__text__inner">
              {this.renderSquareImage()}
              <div className="curated-set-card__title">
                {this.props.titleOverride || this.props.curatedSet.title}
              </div>
            </div>
          </div>
        </CoreLink>
      </div>
    );
  }
}
