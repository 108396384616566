import React from 'react';
import I18n from 'i18n-js';
import {
  IUserType,
  IRefundState,
  IRefundRequestProps,
  ViewContext,
} from './refund_request';
import { core_apimessages_Money } from '@reverbdotcom/commons/src/gql/graphql';

interface IProps {
  userType: IUserType;
  refundState: IRefundState;
  onRefundStateChange: (event: any) => void;
  exchangedAmount?: core_apimessages_Money;
  refundRequest: IRefundRequestProps;
  viewContext: ViewContext;
}

interface IRefundRequestStateOptionProps {
  checked: boolean;
  refundState: IRefundState;
  onRefundStateChange: (event: any) => void;
  showOnClickSelector?: string;
  hideOnClickSelector: string;
}

const RefundRequestStateOption: React.StatelessComponent<IRefundRequestStateOptionProps> = (props) => {
  return (
    <div className="label-with-radio">
      <input
        type="radio"
        id={`order_refund_request_state_${props.refundState}`}
        name="state"
        value={props.refundState}
        checked={props.checked}
        onChange={props.onRefundStateChange}
        data-show-on-click={props.showOnClickSelector}
        data-hide-element-on-click={props.hideOnClickSelector}
      />
      <label htmlFor={`order_refund_request_state_${props.refundState}`}>
        {I18n.t(`discovery.dashboard.refundRequests.refundState.${props.refundState}`)}
      </label>
    </div>
  );
};

const UpdateRefundRequestStateOptions: React.StatelessComponent<IProps> = (props) => {
  if (props.refundRequest.canConditionallyApprove) {
    return (
      <div className="form-group">
        <div className="form-group__header">
          <label>
            {I18n.t('discovery.dashboard.refundRequests.refundState.response')}
          </label>
        </div>
        <div className="form-group__fields">
          <RefundRequestStateOption
            refundState={IRefundState.CONDITIONALLY_APPROVED}
            checked={props.refundState === IRefundState.CONDITIONALLY_APPROVED}
            onRefundStateChange={props.onRefundStateChange}
            showOnClickSelector=".conditionally-approve-refund-request"
            hideOnClickSelector=".approve-refund-request, .deny-refund-request"
          />
          <RefundRequestStateOption
            refundState={IRefundState.APPROVED}
            checked={props.refundState === IRefundState.APPROVED}
            onRefundStateChange={props.onRefundStateChange}
            showOnClickSelector=".approve-refund-request"
            hideOnClickSelector=".conditionally-approve-refund-request, .deny-refund-request"
          />
          <RefundRequestStateOption
            refundState={IRefundState.DENIED}
            checked={props.refundState === IRefundState.DENIED}
            onRefundStateChange={props.onRefundStateChange}
            showOnClickSelector=".deny-refund-request"
            hideOnClickSelector=".approve-refund-request, .conditionally-approve-refund-request"
          />
        </div>
      </div>
    );
  }

  return (
    <div className="form-group">
      <div className="form-group__header">
        <label>
          {I18n.t('discovery.dashboard.refundRequests.refundState.response')}
        </label>
      </div>
      <div className="form-group__fields">
        <RefundRequestStateOption
          refundState={IRefundState.APPROVED}
          checked={props.refundState === IRefundState.APPROVED}
          onRefundStateChange={props.onRefundStateChange}
          showOnClickSelector=".approve-refund-request"
          hideOnClickSelector=".deny-refund-request"
        />
        <RefundRequestStateOption
          refundState={IRefundState.DENIED}
          checked={props.refundState === IRefundState.DENIED}
          onRefundStateChange={props.onRefundStateChange}
          showOnClickSelector=".deny-refund-request"
          hideOnClickSelector=".approve-refund-request"
        />
      </div>
    </div>
  );
};

const RefundRequestState: React.StatelessComponent<IProps> = (props) => {
  if (props.userType === IUserType.BUYER) {
    return null;
  }

  if (props.refundRequest.isPersisted && props.viewContext !== ViewContext.CONFIRM) {
    return <UpdateRefundRequestStateOptions {...props} />;
  }

  if (!props.refundRequest.canConditionallyApprove || props.viewContext === ViewContext.CONFIRM) {
    return (
      <input
        type="hidden"
        name="state"
        id="order_refund_request_state"
        value={IRefundState.APPROVED}
      />
    );
  }

  return (
    <div className="form-group">
      <div className="form-group__header">
        <label>
          {I18n.t('discovery.dashboard.refundRequests.refundState.title')}
        </label>
      </div>
      <div className="form-group__fields">
        <div className="label-with-radio">
          <input
            type="radio"
            id={`order_refund_request_state_${IRefundState.CONDITIONALLY_APPROVED}`}
            name="state"
            value={IRefundState.CONDITIONALLY_APPROVED}
            checked={props.refundState === IRefundState.CONDITIONALLY_APPROVED}
            onChange={props.onRefundStateChange}
            data-show-on-click=".conditionally-approve-refund-request"
            data-hide-element-on-click=".approve-refund-request"
          />
          <label htmlFor={`order_refund_request_state_${IRefundState.CONDITIONALLY_APPROVED}`}>
            {I18n.t(`discovery.dashboard.refundRequests.refundState.${IRefundState.CONDITIONALLY_APPROVED}`)}
          </label>
        </div>
        <div className="label-with-radio">
          <input
            type="radio"
            id={`order_refund_request_state_${IRefundState.APPROVED}`}
            name="state"
            value={IRefundState.APPROVED}
            checked={props.refundState === IRefundState.APPROVED}
            onChange={props.onRefundStateChange}
            data-show-on-click=".approve-refund-request"
            data-hide-element-on-click=".conditionally-approve-refund-request"
          />
          <label htmlFor={`order_refund_request_state_${IRefundState.APPROVED}`}>
            {I18n.t(`discovery.dashboard.refundRequests.refundState.${IRefundState.APPROVED}`)}
          </label>
        </div>
      </div>
    </div>
  );
};

export default RefundRequestState;
