import { omit, isEmpty, includes, isNumber, pickBy, isArray } from 'lodash';
import { Core_FindFavoriteQuery } from '../gql/graphql';

type Core_FindFavoriteQuery_Favorite = Core_FindFavoriteQuery['findFavorite']['favorite'];

/**
 * Entity type is a concept specific to Reverb's web frontend used for translation keys and applying CSS classes.
 * Do not use for event tracking.
 */
export const EntityType = {
  Search: 'search',
  Category: 'category',
  Product: 'product',
  Shop: 'shop',
  Collection: 'collection',
  Brand: 'brand',
} as const;
export type EntityTypeName = typeof EntityType[keyof typeof EntityType];

const categoryParams = ['product_type_uuid', 'category_uuid'];
const productParams = ['cp_ids'];
const collectionParams = ['curated_set_id', 'curated_search_id'];
const shopParams = ['shop_id'];
const brandParams = ['make'];

function hasOnly(typeParamNames: string[], nonEmptyQueryParamNames: string[]): boolean {
  if (nonEmptyQueryParamNames.length === 0) {
    return false;
  }

  return nonEmptyQueryParamNames.every(k => includes(typeParamNames, k)) && nonEmptyQueryParamNames.length <= typeParamNames.length;
}

function isNotEmpty(val: FavoriteQueryParamValue): boolean {
  if (isArray(val)) {
    return !isEmpty(val);
  }

  if (isNumber(val)) {
    return true;
  }

  return !!val;
}

const ignoredQueryParams = ['ships_to'];

export function getFavoriteQueryParams(favorite: Core_FindFavoriteQuery_Favorite): FavoriteQueryParams {
  let queryParams: FavoriteQueryParams = {};
  try {
    queryParams = omit(JSON.parse(favorite.queryParams), ignoredQueryParams);
  } catch (_) { // tslint:disable-next-line no-empty
  }

  return queryParams;
}

/**
 * USE ONLY FOR TRANSLATION KEYS, DO NOT USE FOR EVENT TRACKING.
 *
 * This is a rewrite of legacy logic for `getTranslationKey` in the `FollowButtonContainer` component,
 * which infers the type of follow based on non-empty query params.
 *
 * Ideally this would be returned from an API response, and there is a similar field
 * `searchableType` available in API responses, with some behavior differences.
 *
 * For example, any set of query params with only brand + category is considered searchable type Brand,
 * but considered a 'search' entity type. Same for CSP + category, shop + category, collection + category.
 */
export function mapFavoriteToEntityType(favorite: Core_FindFavoriteQuery_Favorite): EntityTypeName {
  const queryParams = getFavoriteQueryParams(favorite);
  const nonEmptyQueryParams = pickBy(queryParams, isNotEmpty);
  const nonEmptyQueryParamNames = Object.keys(nonEmptyQueryParams);

  if (hasOnly(categoryParams, nonEmptyQueryParamNames)) {
    return EntityType.Category;
  }

  if (hasOnly(productParams, nonEmptyQueryParamNames)) {
    return EntityType.Product;
  }

  if (hasOnly(shopParams, nonEmptyQueryParamNames)) {
    return EntityType.Shop;
  }

  if (hasOnly(collectionParams, nonEmptyQueryParamNames)) {
    return EntityType.Collection;
  }

  if (hasOnly(brandParams, nonEmptyQueryParamNames)) {
    return EntityType.Brand;
  }

  return EntityType.Search;
}

/**
 * The subset of [FollowQueryParams](https://github.com/reverbdotcom/reverb/blob/f908bcfabc328b622a2c5c78631b098e52a88f91/schemas/follows.proto#L47)
 * that are used for `mapFavoriteToEntityType`.
 */
export interface FavoriteQueryParams {
  category_uuid?: string;
  condition?: string[];
  country_of_origin?: string;
  cp_ids?: number[];
  curated_set_id?: number;
  curated_search_id?: number;
  currency?: string;
  decade?: string;
  finish?: string;
  handmade?: boolean;
  item_city?: string;
  item_region?: string;
  item_country?: string;
  item_state?: string;
  make?: string[];
  model?: string;
  price_max?: string;
  price_min?: string;
  product_type_uuid?: string;
  query?: string;
  year_max?: string;
  year_min?: string;
  shop_id?: number;
  accepts_gift_cards?: boolean;
  preferred_seller?: boolean;
  listing_type?: string;
  ships_to?: string;
  accepts_payment_plans?: boolean;
  free_shipping?: boolean;
  personal_profile?: string;
  free_expedited_shipping?: boolean;
  zero_percent_financing?: boolean;
  decades?: string[];
}
type FavoriteQueryParamValue = FavoriteQueryParams[keyof FavoriteQueryParams];
