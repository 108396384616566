import React from 'react';
import I18n from 'i18n-js';
import { get } from 'lodash';
import classNames from 'classnames';
import {
  core_apimessages_NegotiationParty,
  core_apimessages_NegotiationAction as NegotiationAction,
  Listing,
} from '../gql/graphql';
import { UserInputOffer, Taxable } from './negotiation_types';
import OfferQuantitySelect from './offer_quantity_select';
import { IUserContext, withUserContext } from '../components/user_context_provider';
import { MoneyInput } from '../components/money_input';
import { parseAmount } from '../money';

export interface IProps extends IUserContext {
  offer: UserInputOffer;
  listing: Listing;
  taxable: Taxable;
  currency: string;
  action: NegotiationAction;
  party: core_apimessages_NegotiationParty;
  onOfferChange: (any) => void;
}

function taxHint(taxable: Taxable) {
  if (taxable && taxable.taxIncluded) {
    return ` (${taxable.taxIncludedHint})`;
  }

  return '';
}

function priceLabel(action: NegotiationAction, taxable: Taxable) {
  if (action === NegotiationAction.COUNTER) {
    return `${I18n.t('commons.offers.label.counterOfferPrice')}${taxHint(taxable)}`;
  }

  return `${I18n.t('commons.offers.label.price')}${taxHint(taxable)}`;
}

function shippingPriceForBuyer(props: IProps) {
  const shippingPrice = get(props, 'offer.shippingPrice', '');
  return I18n.t('commons.offers.shippingPrice', { shippingPrice });
}

function moreThanOneInventoriedItem(listing: Listing) {
  return listing.hasInventory && listing.inventory > 1;
}

function userShouldSeeQuantitySelect(
  party: core_apimessages_NegotiationParty,
  lastOfferedQuantity: number) {
  return lastOfferedQuantity > 1 || party === core_apimessages_NegotiationParty.SELLER;
}

function displayQuantitySelect(props: Partial<IProps>) {
  const { action, listing, offer, party } = props;
  return (action === NegotiationAction.COUNTER || action === NegotiationAction.CREATE) &&
    moreThanOneInventoriedItem(listing) &&
    userShouldSeeQuantitySelect(party, offer.quantity);
}

function OfferAmountInputs(props: IProps) {
  const shippingPrice = shippingPriceForBuyer(props);
  const parsedOfferPrice = props.offer.price && props.currency && parseAmount(props.offer.price, props.currency);
  const offerIsAboveListingPrice = props.listing?.price?.currency == parsedOfferPrice?.currency &&
      !!props.listing?.price?.amountCents &&
      !!parsedOfferPrice?.amountCents &&
      parsedOfferPrice.amountCents > props.listing.price.amountCents;

  return (
    <div className={classNames({
      'offers-form__price-row': props.action !== NegotiationAction.COUNTER,
      'offers-form__counter-price-row': props.action === NegotiationAction.COUNTER,
    })}>
      <div className="offers-form__price">
        <MoneyInput
          label={priceLabel(props.action, props.taxable)}
          suffixText={!props.listing.shipping.localPickupOnly && shippingPrice}
          name="offer-price"
          id="offer-price"
          value={props.offer.price}
          currencyCode={props.currency}
          onChange={(evt) => {
            props.onOfferChange({ type: 'setPrice', value: evt.target.value });
          }}
          required
          errorText={offerIsAboveListingPrice && I18n.t('commons.offers.errors.aboveListingPrice')}
        />
      </div>
      {displayQuantitySelect(props) &&
        <div className="offers-form__quantity">
          <OfferQuantitySelect
            inventory={props.listing.inventory}
            party={props.party}
            quantity={props.offer.quantity}
            onQuantityChange={props.onOfferChange}
          />
        </div>
      }
    </div>
  );
}

export default withUserContext(OfferAmountInputs);
