import PropTypes from 'prop-types';
import React from 'react';
import CanadianBankFields from './canadian_bank_fields';
import USBankFields from './us_bank_fields';

class BankAccountFieldsSelector extends React.Component {
  constructor(props) {
    super(props);

    this.usBankFields = this.usBankFields.bind(this);
    this.canadianBankFields = this.canadianBankFields.bind(this);
  }

  canadianBankFields() {
    return (
      <CanadianBankFields
        data-country-form-ca
        nameForField={this.props.nameForField}
        onCountryChange={this.props.onCountryChange}
        onCurrencyChange={this.props.onCurrencyChange}
        countryCodeOptions={this.props.countryCodeOptions}
        currencies={this.props.currencies}
        selectedPayoutCurrency={this.props.selectedPayoutCurrency}
        shopCurrency={this.props.shopCurrency}
        dashboardView={this.props.dashboardView}
      />
    );
  }

  usBankFields() {
    return (
      <USBankFields
        data-country-form-us
        nameForField={this.props.nameForField}
        onCountryChange={this.props.onCountryChange}
        countryCodeOptions={this.props.countryCodeOptions}
        selectedPayoutCurrency={this.props.selectedPayoutCurrency}
        shopCurrency={this.props.shopCurrency}
        dashboardView={this.props.dashboardView}
        currencies={this.props.currencies}
      />
    );
  }

  render() {
    const bankFields = {
      US: this.usBankFields,
      CA: this.canadianBankFields,
    };

    return bankFields[this.props.selectedBankCountry]();
  }
}

BankAccountFieldsSelector.propTypes = {
  selectedBankCountry: PropTypes.string.isRequired,
  nameForField: PropTypes.func,
  onCountryChange: PropTypes.func,
  onCurrencyChange: PropTypes.func,
  countryCodeOptions: PropTypes.array,
  currencies: PropTypes.array,
  selectedPayoutCurrency: PropTypes.string,
  shopCurrency: PropTypes.string,
  dashboardView: PropTypes.bool,
};

export default BankAccountFieldsSelector;
