import {
  core_apimessages_Address as Address,
  core_apimessages_AddressEntry as AddressEntry,
} from '@reverbdotcom/commons/src/gql/graphql';

export function mapAddressToEntry(address: Address): AddressEntry {
  const safeAddress = address || {};

  return {
    name: safeAddress.name,
    streetAddress: safeAddress.streetAddress,
    extendedAddress: safeAddress.extendedAddress,
    postalCode: safeAddress.postalCode,
    phone: safeAddress.phone,
    region: safeAddress.region,
    locality: safeAddress.locality,
    countryCode: safeAddress.countryCode,
    email: safeAddress.email,
  };
}
