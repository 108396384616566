import React, { useState } from 'react';
import {
  core_apimessages_TaxProfile,
  core_apimessages_Address,
  InputTaxProfileUpsertRequest,
} from '@reverbdotcom/commons/src/gql/graphql';
import { I18N as Translate } from '@reverbdotcom/commons/src/components/translate';
import {
  RCAlertBox,
  RCButton,
  RCRadioGroup,
  RCTextInput,
  RCTextWithIcon,
} from '@reverbdotcom/cadence/components';
import { LockIcon } from '@reverbdotcom/cadence/icons/react';
import AddressFormFields from '../address_form_fields';
import { useCountries } from '@reverbdotcom/commons/src/useCountries';
import { BUSINESS, INDIVIDUAL } from './tax_profile_constants';

interface IFormProps {
  taxProfile?: core_apimessages_TaxProfile;
  shopAddress?: core_apimessages_Address;
  cancelEdit: () => void;
  submitTaxProfile: (InputTaxProfileUpsertRequest) => void;
  isSubmitting: boolean;
}

const COMPONENT_NAME = 'USTaxProfileForm';

export default function USTaxProfileForm({
  taxProfile,
  shopAddress,
  cancelEdit,
  submitTaxProfile,
  isSubmitting,
}: IFormProps) {
  const { countries } = useCountries();

  const [ taxProfileData, setTaxProfileData ] = useState({
    tinType: taxProfile?.tinType || INDIVIDUAL,
    tin: '', // always reset this to empty when editing an existing profile
    firstName: taxProfile?.firstName || '',
    lastName: taxProfile?.lastName || '',
    name: taxProfile?.name || '',
    addressAttributes: {
      streetAddress: taxProfile?.address?.streetAddress || shopAddress?.streetAddress || '',
      extendedAddress: taxProfile?.address?.extendedAddress || shopAddress?.extendedAddress || '',
      postalCode: taxProfile?.address?.postalCode || shopAddress?.postalCode || '',
      phone: taxProfile?.address?.phone || shopAddress?.phone || '',
      region: taxProfile?.address?.region || shopAddress?.region || '',
      locality: taxProfile?.address?.locality || shopAddress?.locality || '',
      countryCode: taxProfile?.address?.country?.countryCode || shopAddress?.country?.countryCode || '',
    },
  });

  const isIndividual = taxProfileData.tinType === INDIVIDUAL;

  const setFormField = (fieldName, value) => {
    setTaxProfileData((tpd) => ({
      ...tpd,
      [fieldName]: value,
      // reset TIN & name fields when switching type
      ...(fieldName === 'tinType' && {
        firstName: '',
        lastName: '',
        name: '',
        tin: '',
      }),
    }));
  };

  const saveTaxProfile = () => {
    submitTaxProfile(taxProfileData as InputTaxProfileUpsertRequest);
  };

  return (
    <>
      <RCRadioGroup
        id="tinType"
        name="tinType"
        label={<Translate text="discovery.taxProfile.usTaxProfile.individualOrBusiness" />}
        value={taxProfileData.tinType}
        onChange={(event) => setFormField('tinType', event.target.value)}
      >
        <RCRadioGroup.Option
          label={<Translate text="discovery.taxProfile.usTaxProfile.individual" />}
          key={INDIVIDUAL}
          value={INDIVIDUAL}
        />
        <RCRadioGroup.Option
          label={<Translate text="discovery.taxProfile.usTaxProfile.business" />}
          key={BUSINESS}
          value={BUSINESS}
        />
      </RCRadioGroup>
      <RCTextInput
        id="tin"
        name="tin"
        label={
          <Translate text={`discovery.taxProfile.usTaxProfile.${isIndividual ? 'ssn' : 'ein'}Label`}>
            <Translate className="hint" text="discovery.taxProfile.usTaxProfile.numberHint" />
          </Translate>
        }
        helpText={
          <RCTextWithIcon
            placement="left"
            svgComponent={LockIcon}
          >
            <Translate text="discovery.taxProfile.usTaxProfile.privacyAndEncryption" />
          </RCTextWithIcon>
        }
        onChange={(event) => setFormField('tin', event.target.value)}
        value={taxProfileData.tin}
      />
      {isIndividual ? (
        <div className="mb-4">
          <div className="d-flex gap-4">
            <div className="width-100">
              <RCTextInput
                id="firstName"
                name="firstName"
                label={<Translate text="discovery.taxProfile.usTaxProfile.firstName" />}
                onChange={(event) => setFormField('firstName', event.target.value)}
                value={taxProfileData.firstName}
              />
            </div>
            <div className="width-100">
              <RCTextInput
                id="lastName"
                name="lastName"
                label={<Translate text="discovery.taxProfile.usTaxProfile.lastName" />}
                onChange={(event) => setFormField('lastName', event.target.value)}
                value={taxProfileData.lastName}
              />
            </div>
          </div>
          <Translate className="hint" text="discovery.taxProfile.usTaxProfile.nameInstructionsIndividual" />
        </div>
      ) : (
        <RCTextInput
          id="name"
          name="name"
          label={<Translate text="discovery.taxProfile.usTaxProfile.businessName" />}
          helpText={<Translate text="discovery.taxProfile.usTaxProfile.nameInstructionsBusiness" />}
          onChange={(event) => setFormField('name', event.target.value)}
          value={taxProfileData.name}
        />
      )}
      <AddressFormFields
        data-address-form-fields
        address={taxProfileData.addressAttributes}
        countries={countries}
        updateField={(entry) => setFormField('addressAttributes', entry)}
        requiredFieldsOverrides={['phone']}
        useCamelizedFieldsForRQL
        trackingComponentName={COMPONENT_NAME}
        autocompleteEnabled
        hiddenFieldsOverrides={['name']}
      />
      <div className="mtb-4">
        <RCAlertBox type="plain">
          <Translate text="discovery.taxProfile.usTaxProfile.pleaseConfirm" />
        </RCAlertBox>
      </div>
      <div className="d-flex mt-4 gap-2 fx-align-center fx-justify-center">
        <RCButton
          variant="filled"
          onClick={saveTaxProfile}
          disabled={isSubmitting}
        >
          <RCTextWithIcon
            placement="left"
            svgComponent={LockIcon}
          >
            <Translate text="discovery.taxProfile.usTaxProfile.submitPrompt" />
          </RCTextWithIcon>
        </RCButton>
        {!!taxProfile?.id && (
          <RCButton
            variant="transparent"
            onClick={cancelEdit}
            disabled={isSubmitting}
          >
            <Translate text="discovery.taxProfile.usTaxProfile.cancel" />
          </RCButton>
        )}
      </div>
    </>
  );
}
