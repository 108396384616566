export default {
  quickLinks: {
    'bass-big-muffs': 'Bass Big Muffs',
    'free-2-day-shipping': 'Free 2-Day Shipping',
    'black-russian-big-muffs': 'Black Russian Big Muffs',
    'civil-war-big-muffs': 'Civil War Big Muffs',
    'deluxe-big-muffs': 'Deluxe Big Muffs',
    'green-russian-big-muffs': 'Green Russian Big Muffs',
    'metal-muffs': 'Metal Muffs',
    'nano-big-muffs': 'Nano Big Muffs',
    'op-amp-big-muffs': 'Op-Amp Big Muffs',
    'ram-s-head-big-muffs': "Ram's Head Big Muffs",
    used: 'Used',
    vintage: 'Vintage',
    'elitist-les-pauls': 'Elitist Les Pauls',
    'les-paul-customs': 'Les Paul Customs',
    'les-paul-juniors': 'Les Paul Juniors',
    'les-paul-specials': 'Les Paul Specials',
    'les-paul-standards': 'Les Paul Standards',
    'les-paul-studios': 'Les Paul Studios',
    'under-500': 'Under $500',
    'under-800': 'Under $800',
    'mint-condition': 'Mint Condition',
    'plus-top-les-pauls': 'Plus Top Les Pauls',
    '66-sgs': "'66 SGs",
    'g-400s': 'G-400s',
    'sg-specials': 'SG Specials',
    'sg-standards': 'SG Standards',
    'under-300': 'Under $300',
    '65-reissue-deluxes': "'65 Reissue Deluxes",
    '0-financing': '0% Financing',
    'free-shipping': 'Free Shipping',
    'deluxe-reverbs': 'Deluxe Reverbs',
    'fsr-limited-edition-deluxes': 'FSR & Limited-Edition Deluxes',
    'pre-cbs-deluxes': 'Pre-CBS Deluxes',
    'silverface-deluxes': 'Silverface Deluxes',
    'american-vintage-series-jaguars': 'American Vintage Series Jaguars',
    'b-stock-and-mint-condition': 'B-Stock and Mint Condition',
    'custom-shop': 'Custom Shop',
    'under-1-000': 'Under $1,000',
    'under-1-500': 'Under $1,500',
    'kurt-cobain-jaguars': 'Kurt Cobain Jaguars',
    'vintage-made-in-usa-jaguars': 'Vintage Made-in-USA Jaguars',
    'vintage-made-in-japan-jaguars': 'Vintage Made-in-Japan Jaguars',
    '5-strings': '5-Strings',
    'artist-signature-jazz-basses': 'Artist Signature Jazz Basses',
    'under-1-200': 'Under $1,200',
    'under-600': 'Under $600',
    'active-electronics': 'Active Electronics',
    'pre-cbs-jazz-basses': 'Pre-CBS Jazz Basses',
    'vintage-jazz-basses': 'Vintage Jazz Basses',
    'vintage-made-in-japan-jazz-basses': 'Vintage Made-in-Japan Jazz Basses',
    'american-professional-jazzmasters': 'American Professional Jazzmasters',
    'vintage-made-in-usa-jazzmasters': 'Vintage Made-in-USA Jazzmasters',
    'vintage-made-in-japan-jazzmasters': 'Vintage Made-in-Japan Jazzmasters',
    'vintage-jazzmasters': 'Vintage Jazzmasters',
    'b-stock-and-mint-condition-mustangs': 'B-Stock and Mint Condition Mustangs',
    'competition-mustangs': 'Competition Mustangs',
    'vintage-made-in-usa-mustangs': 'Vintage Made-in-USA Mustangs',
    'vintage-made-in-japan-mustangs': 'Vintage Made-in-Japan Mustangs',
    'vintage-mustangs': 'Vintage Mustangs',
    'artist-signature-precision-basses': 'Artist Signature Precision Basses',
    'pre-cbs-precision-basses': 'Pre-CBS Precision Basses',
    'b-stock-stratocasters': 'B-Stock Stratocasters',
    'cbs-era-stratocasters': 'CBS Era Stratocasters',
    'eric-clapton-stratocasters': 'Eric Clapton Stratocasters',
    'eric-johnson-stratocasters': 'Eric Johnson Stratocasters',
    'hardtail-stratocasters': 'Hardtail Stratocasters',
    'jimi-hendrix-stratocasters': 'Jimi Hendrix Stratocasters',
    'pre-cbs-stratocasters': 'Pre-CBS Stratocasters',
    'vintage-made-in-japan-stratocasters': 'Vintage Made-in-Japan Stratocasters',
    'vintage-stratocasters': 'Vintage Stratocasters',
    'cbs-era-telecasters': 'CBS-Era Telecasters',
    'deluxe-telecasters': 'Deluxe Telecasters',
    'nashville-telecasters': 'Nashville Telecasters',
    'pre-cbs-telecasters': 'Pre-CBS Telecasters',
    'telecaster-customs': 'Telecaster Customs',
    'thinline-telecasters': 'Thinline Telecasters',
    'vintage-made-in-japan-telecasters': 'Vintage Made-in-Japan Telecasters',
    'vintage-telecasters': 'Vintage Telecasters',
    '65-reissue-twins': "'65 Reissue Twins",
    'pre-cbs-twins': 'Pre-CBS Twins',
    'silverface-twins': 'Silverface Twins',
    'tweed-twins': 'Tweed Twins',
    'twin-reverbs': 'Twin Reverbs',
    'block-inlay-es-335s': 'Block Inlay ES-335s',
    'dot-inlay-es-335s': 'Dot Inlay ES-335s',
    'es-335-studios': 'ES-335 Studios',
    'under-2-500': 'Under $2,500',
    'figured-es-335s': 'Figured ES-335s',
    'norlin-era-es-335s': 'Norlin-Era ES-335s',
    'cmt-explorers': 'CMT Explorers',
    'e2-explorers': 'E2 Explorers',
    'gibson-usa': 'Gibson USA',
    'korina-explorers': 'Korina Explorers',
    'firebird-i': 'Firebird I',
    'firebird-iii': 'Firebird III',
    'firebird-v': 'Firebird V',
    'firebird-vii': 'Firebird VII',
    'non-reverse-firebirds': 'Non-Reverse Firebirds',
    'vintage-reverse-firebirds': 'Vintage Reverse Firebirds',
    'vintage-firebirds': 'Vintage Firebirds',
    'designer-flying-vs': 'Designer Flying Vs',
    'faded-flying-vs': 'Faded Flying Vs',
    'korina-flying-vs': 'Korina Flying Vs',
    'v-ii-flying-vs': 'V-II Flying Vs',
    'es-les-pauls': 'ES-Les Pauls',
    'goldtop-les-pauls': 'Goldtop Les Pauls',
    'under-2-000': 'Under $2,000',
    'murphy-lab-les-pauls': 'Murphy Lab Les Pauls',
    'norlin-era-les-pauls': 'Norlin-Era Les Pauls',
    '61-sgs': "'61 SGs",
    'faded-worn-sgs': 'Faded/Worn SGs',
    'les-paul-sgs': 'Les Paul SGs',
    'sg-juniors': 'SG Juniors',
    '10-top-custom-22s': '10-Top Custom 22s',
    'piezo-custom-22s': 'Piezo Custom 22s',
    'private-stock-custom-22s': 'Private Stock Custom 22s',
    's2-custom-22s': 'S2 Custom 22s',
    'se-custom-22s': 'SE Custom 22s',
    'semi-hollow-custom-22s': 'Semi-Hollow Custom 22s',
    'tremolo-custom-22s': 'Tremolo Custom 22s',
    'wood-library-custom-22s': 'Wood Library Custom 22s',
    '10-top-custom-24s': '10-Top Custom 24s',
    '25th-anniversary-custom-24s': '25th Anniversary Custom 24s',
    '30th-anniversary-custom-24s': '30th Anniversary Custom 24s',
    'floyd-rose-custom-24s': 'Floyd Rose Custom 24s',
    'piezo-custom-24s': 'Piezo Custom 24s',
    'private-stock-custom-24s': 'Private Stock Custom 24s',
    's2-custom-24s': 'S2 Custom 24s',
    'se-custom-24s': 'SE Custom 24s',
    'wood-library-custom-24s': 'Wood Library Custom 24s',
    'big-baby-taylors': 'Big Baby Taylors',
    'koa-baby-taylors': 'Koa Baby Taylors',
    'left-handed': 'Left-Handed',
    'mahogany-baby-taylors': 'Mahogany Baby Taylors',
    'taylor-swift-baby-taylors': 'Taylor Swift Baby Taylors',
    'koa-gs-minis': 'Koa GS Minis',
    'mahogany-gs-minis': 'Mahogany GS Minis',
    'jmi-era-ac10s': 'JMI-Era AC10s',
    'korg-era-ac10s': 'Korg-Era AC10s',
    'hand-wired-ac15s': 'Hand-Wired AC15s',
    'jmi-era-ac15s': 'JMI-Era AC15s',
    'korg-era-ac15s': 'Korg-Era AC15s',
    'ac30-combos': 'AC30 Combos',
    'ac30-heads': 'AC30 Heads',
    'hand-wired-ac30s': 'Hand-Wired AC30s',
    'jmi-era-ac30s': 'JMI-Era AC30s',
    'korg-era-ac30s': 'Korg-Era AC30s',
    'top-boost': 'Top Boost',
  },
};
