import { sampleTimings } from '@reverbdotcom/commons/src/performance_tracker';
import UserStore from './user_store';
import isNode from '@reverbdotcom/commons/src/is-node';

const PERFORMANCE_SAMPLE = 0.1;

const PAGE_TYPE_HOME = 'home';
const PAGE_TYPE_FEED = 'feed';
const PAGE_TYPE_DASHBOARD = 'dashboard';
const PAGE_TYPE_OTHER = 'other';

const PAGE_PREFIXES = [
  'c',
  'item',
  'marketplace',
  'news',
  'p',
];

const PAGE_PATHS = [
  { pageName: 'edit_my_selling_listings', path: /^\/my\/selling\/listings\/\d+\/edit/ },
];

function normalizedPrefix(prefix) {
  if (!prefix) {
    return PAGE_TYPE_HOME;
  }

  if (PAGE_PREFIXES.includes(prefix)) {
    return prefix;
  }

  return PAGE_TYPE_OTHER;
}

function getPagePrefix(pathname) {
  const [, first, second] = pathname.split('/', 3);
  const pageMatch = PAGE_PATHS.find(({ path }) => path.test(pathname));

  if (pageMatch) {
    return pageMatch.pageName;
  }

  if (first === 'my') {
    return (second === PAGE_TYPE_FEED) ? PAGE_TYPE_FEED : PAGE_TYPE_DASHBOARD;
  }

  if (first && (first.length === 2 || /^\w\w-\w\w$/.test(first))) {
    // locale path prefix
    return normalizedPrefix(second);
  }

  return normalizedPrefix(first);
}

export function trackTimings(pathname = window.location.pathname) {
  const { isBot, deviceName } = UserStore.getState();
  if (isNode || isBot) { return; }

  const page = getPagePrefix(pathname);

  if (page === PAGE_TYPE_OTHER) { return; }

  // we want tracking for all matching pathnames and a smaller sample for everything else
  const trackingPagePath = PAGE_PATHS.some(({ pageName }) => pageName === page);
  const sample = trackingPagePath ? 1 : PERFORMANCE_SAMPLE;
  const tags = {
    deviceName,
    page,
    app: 'reverb',
  };

  sampleTimings(tags, sample);
}
