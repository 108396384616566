import { ChildProps } from '@apollo/client/react/hoc';
// TODO update this to import from commons/src/gql
// eslint-disable-next-line no-restricted-imports
import { gql } from '@apollo/client';
import React from 'react';
import { isEqual, get } from 'lodash';
import I18n from 'i18n-js';

import bind from '../../bind';
import CspFeatureCard from './csp_feature_card';
import CSPRow, { CSPCardFragment } from '../../components/csp_row';
import CspVerticalFeature from './csp_vertical_feature';
import CspFeatureListItem from './csp_feature_list_item';
import OverflowingRow from '../../components/overflowing_row';
import Tiles from '../../components/tiles';
import { withGraphql } from '../../with_graphql';
import { CommonsCmsCspFeature } from '../../gql/graphql';
import { IDynamicComponentProps } from '../dynamic_component_props';
import CoreLink from '../../components/core_link';
import SanitizedRender from '../../components/sanitized_render';
import { trackEvent, trackCSPView } from '../../elog/mparticle_tracker';
import { IUserContext, withUserContext } from '../../components/user_context_provider';
import { showLocalizedListingContent } from '../../localize_listing_rows_utils';

const COMPONENT_NAME = 'CMSCspFeature';
export const FEATURE_TYPES = [
  'cspRow',
  'squareRow',
  'growRow',
  'squareGrid',
  'growGrid',
  'vertical',
  'verticalWide',
  'orderedList',
];

interface FeatureData {
  id: string;
  description?: string;
  descriptionHtml?: string;
  imageOverride?: string;
}

export interface IProps extends IDynamicComponentProps, IUserContext {
  title?: string;
  titleHtml?: string;
  callouts?: FeatureData[];
  subtitleHtml?: string;
  ledeHtml?: string;
  ctaText?: string;
  ctaTargetHref?: string;
  splitPrices?: boolean;
  hidePrices?: boolean;
  jumplinkSlug?: string;
  useCspSummary?: boolean;
  shouldLinkToSellForm?: boolean;
  trackingName?: string;

  // see FEATURE_TYPES
  displayAs?: string;
}

export class CspFeature extends React.Component<ChildProps<IProps, CommonsCmsCspFeature.Query>, null> {
  static defaultProps = {
    callouts: [],
  };

  componentDidMount() {
    if (!this.props.data.loading && this.csps().length && !this.displayAs('cspRow')) {
      trackEvent(trackCSPView(this.csps(), this.trackingName()));
    }
  }

  componentDidUpdate(oldProps) {
    if (!this.props.data.loading
      && this.csps().length
      && !isEqual(this.csps(), oldProps.data.csps)
      && !this.displayAs('cspRow')) {
      trackEvent(trackCSPView(this.csps(), this.trackingName()));
    }
  }

  trackingName() {
    return this.props.trackingName ?? COMPONENT_NAME;
  }

  displayAs(style) {
    return this.props.displayAs === style;
  }

  squareImages() {
    return this.displayAs('squareRow') || this.displayAs('squareGrid');
  }

  displayAsGrid() {
    return this.displayAs('growGrid') || this.displayAs('squareGrid');
  }

  csps() {
    return this.props.data.csps || [];
  }

  findMatchingCsp(calloutId) {
    return this.csps().find(csp => csp.id === calloutId);
  }

  visibleCallouts() {
    // If the CSP callouts point to the sell form,
    // we show all of them (including 0-inventory CSPs).
    if (this.props.shouldLinkToSellForm) {
      return this.props.callouts;
    }

    // If the CSP callouts point to the CSP landing,
    // we filter out any 0-inventory CSPs.
    return this.props.callouts.filter((callout) => {
      const csp = this.findMatchingCsp(callout.id);

      return !!get(csp, 'inventory.newLowPrice') || !!get(csp, 'inventory.usedLowPrice');
    });
  }

  shouldGrow() {
    // we don't want huge square tiles
    if (this.squareImages()) return;

    return this.props.callouts.length < 2;
  }

  shouldAppendAction() {
    return this.displayAsGrid() || this.displayAs('vertical') || this.displayAs('verticalWide');
  }

  @bind
  renderCallout(callout, idx) {
    const csp = this.findMatchingCsp(callout.id);

    if (this.displayAs('orderedList')) {
      return (
        <CspFeatureListItem
          csp={csp}
          key={idx}
          description={callout.descriptionHtml || callout.description}
          splitPrices={this.props.splitPrices}
          hidePrices={this.props.hidePrices}
          listPosition={idx + 1}
          imageOverride={callout.imageOverride}
          useCspSummary={this.props.useCspSummary}
          position={idx}
          shouldLinkToSellForm={this.props.shouldLinkToSellForm}
          trackingName={this.trackingName()}
        />
      );
    }

    if (this.displayAs('vertical') || this.displayAs('verticalWide')) {
      return (
        <CspVerticalFeature
          csp={csp}
          key={idx}
          description={callout.descriptionHtml || callout.description}
          splitPrices={this.props.splitPrices}
          hidePrices={this.props.hidePrices}
          imageOverride={callout.imageOverride}
          wide={this.displayAs('verticalWide')}
          useCspSummary={this.props.useCspSummary}
          position={idx}
          shouldLinkToSellForm={this.props.shouldLinkToSellForm}
          trackingName={this.trackingName()}
        />
      );
    }

    return (
      <CspFeatureCard
        csp={csp}
        key={idx}
        description={callout.descriptionHtml || callout.description}
        splitPrices={this.props.splitPrices}
        hidePrices={this.props.hidePrices}
        squareImage={this.squareImages()}
        imageOverride={callout.imageOverride}
        useCspSummary={this.props.useCspSummary}
        position={idx}
        shouldLinkToSellForm={this.props.shouldLinkToSellForm}
        trackingName={this.trackingName()}
      />
    );
  }

  renderCallouts() {
    if (this.props.data.loading) { return null; }

    return this.visibleCallouts().map(this.renderCallout);
  }

  renderList() {
    return (
      <Tiles
        renderAsOrderedList
        oneWide
        loading={this.props.data.loading}
        placeholder={<CspFeatureListItem />}
        expectedCount={this.props.callouts.length}
      >
        {this.visibleCallouts().map(this.renderCallout)}
      </Tiles>
    );
  }

  renderAction() {
    if (!this.props.ctaTargetHref) {
      return null;
    }

    return (
      <CoreLink
        className={this.shouldAppendAction() && 'button button--primary'}
        to={this.props.ctaTargetHref}
      >
        {this.props.ctaText
          ? this.props.ctaText
          : I18n.t('cms.marketingCallout.seeMore')}
      </CoreLink>
    );
  }

  renderTitle() {
    return (
      <SanitizedRender
        html={this.props.titleHtml || this.props.title}
      />
    );
  }

  renderTiles() {
    if (this.displayAs('vertical') || this.displayAs('verticalWide')) {
      return (
        <Tiles
          oneWide
          expectedCount={this.props.callouts.length}
          placeholder={<CspVerticalFeature />}
          loading={this.props.data.loading}
        >
          {this.renderCallouts()}
        </Tiles>
      );
    }

    return (
      <Tiles
        singleRow={!this.displayAsGrid()}
        threeWide={!this.squareImages()}
        largeTiles={this.squareImages()}
        grow={this.shouldGrow()}
        expectedCount={this.props.callouts.length}
        placeholder={<CspFeatureCard squareImage={this.squareImages()} />}
        loading={this.props.data.loading}
      >
        {this.renderCallouts()}
      </Tiles>
    );
  }

  render() {
    if (!this.props.data.loading && !this.csps().length) return null;

    if (this.displayAs('orderedList')) {
      return (
        <div className="csp-feature-list">
          <h2 className="csp-feature-list__heading">
            {this.renderTitle()}
          </h2>
          {this.renderList()}
        </div>
      );
    }

    if (this.displayAs('cspRow')) {
      const cspsWithInventory = this.visibleCallouts().map(callout => this.findMatchingCsp(callout.id));

      return (
        <CSPRow
          title={this.renderTitle()}
          subtitleHtml={this.props.subtitleHtml}
          ledeHtml={this.props.ledeHtml}
          csps={cspsWithInventory}
          loading={this.props.data.loading}
          action={this.renderAction()}
          id={this.props.jumplinkSlug}
          shouldLinkToSellForm={this.props.shouldLinkToSellForm}
          trackingComponentName={this.trackingName()}
          hidePrices={showLocalizedListingContent(this.props.user)}
          shouldTrack
        />
      );
    }

    return (
      <OverflowingRow
        title={this.renderTitle()}
        subtitleHtml={this.props.subtitleHtml}
        ledeHtml={this.props.ledeHtml}
        action={this.renderAction()}
        appendAction={this.shouldAppendAction()}
        centeredTitle={this.displayAs('vertical') || this.displayAs('verticalWide')}
        id={this.props.jumplinkSlug}
        collectionCount={this.visibleCallouts().length}
      >
        {this.renderTiles()}
      </OverflowingRow>
    );
  }
}

const connect = withGraphql<IProps, CommonsCmsCspFeature.Query>(
  gql`
    query Commons_Cms_CspFeature(
      $ids: [String]
    ){
      csps(input: {
        ids: $ids
      }) {
        _id
        ...CSPCard
        summary
      }
    }
    ${CSPCardFragment}
  `,
  {
    options: (ownProps) => {
      const ids = (get(ownProps, 'callouts', []) as FeatureData[]).map(callout => callout.id);
      return {
        ssr: ownProps.ssr,
        errorPolicy: 'all',
        variables: {
          ids,
        },
      };
    },
  },
);

export default connect(withUserContext(CspFeature));
