import React from 'react';
import { get } from 'lodash';

import {
  CSP,
  core_apimessages_Money,
} from '../../gql/graphql';

export type ConditionFilter = 'new' | 'used';

export function findLowestPrice(csp: CSP) {
  const { inventory } = csp;
  if (!inventory) return null;

  if (!inventory.usedLowPrice) {
    return inventory.newLowPrice;
  }

  if (!inventory.newLowPrice) {
    return inventory.usedLowPrice;
  }

  if (inventory.newLowPrice.amountCents < inventory.usedLowPrice.amountCents) {
    return inventory.newLowPrice;
  }

  return inventory.usedLowPrice;
}

function renderMeta(count: number, price: core_apimessages_Money) {
  if (!price) {
    return null;
  }

  return (
    <span className="d-none">
      <meta itemProp="offerCount" content={String(count)} />
      <meta itemProp="priceCurrency" content={price.currency} />
      <meta itemProp="lowPrice" content={price.amount} />
    </span>
  );
}

interface IBrandMetaDataProps {
  csp: CSP;
}

export function BrandMetaData(props: IBrandMetaDataProps) {
  const brand = get(props.csp, 'brand.name');
  if (!brand) return null;

  return (
    <meta
      content={brand}
      itemType="http://schema.org/Brand"
      itemProp="brand"
    />
  );
}

interface IPriceMetaDataProps {
  csp: CSP;
  conditionFilter?: ConditionFilter;
}

export function PriceMetaData(props: IPriceMetaDataProps) {
  const { csp, conditionFilter } = props;
  const { inventory } = csp;

  if (!inventory) {
    return null;
  }

  if (conditionFilter === 'new') {
    return renderMeta(inventory.newTotal, inventory.newLowPrice);
  }

  if (conditionFilter === 'used') {
    return renderMeta(inventory.usedTotal, inventory.usedLowPrice);
  }

  const total = inventory.usedTotal + inventory.newTotal;
  const lowestPrice = findLowestPrice(csp);

  return renderMeta(total, lowestPrice);
}

interface ICSPMetadataProps {
  csp: CSP;
}

export function NameMetaData(props: ICSPMetadataProps) {
  const name = get(props.csp, 'title');
  if (!name) return null;

  return (
    <meta
      content={name}
      itemProp="name"
    />
  );
}

export function DescriptionMetaData(props: ICSPMetadataProps) {
  const description = get(props.csp, 'metaDescription');
  if (!description) return null;

  return (
    <meta
      content={description}
      itemProp="description"
    />
  );
}

export function ImageMetaData(props: ICSPMetadataProps) {
  const image = get(props.csp, 'image.source');
  if (!image) return null;

  return (
    <meta
      content={image}
      itemProp="image"
    />
  );
}

export function UrlMetaData(props: ICSPMetadataProps) {
  const url = get(props.csp, 'webLink.href');
  if (!url) return null;

  return (
    <meta
      content={url}
      itemProp="url"
    />
  );
}
