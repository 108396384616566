import React from 'react';
import I18n from 'i18n-js';
import { IUser } from './user_context_provider';
import listingIcon from '@reverbdotcom/cadence/images/brand/listing-orange.svg';
import electricGuitarIcon from '@reverbdotcom/commons/images/homepage/electric-guitar.svg';
import synthIcon from '@reverbdotcom/commons/images/homepage/synthesizer.svg';
import drumIcon from '@reverbdotcom/commons/images/homepage/drums.svg';
import ampIcon from '@reverbdotcom/commons/images/homepage/amplifier.svg';
import microphoneIcon from '@reverbdotcom/commons/images/homepage/proaudio-mic.svg';
import { HeartFilledIcon } from '@reverbdotcom/cadence/icons/react';
import { RCIcon } from '@reverbdotcom/cadence/components/RCIcon/RCIcon';
import SanitizedRender from '@reverbdotcom/commons/src/components/sanitized_render';

interface IProps {
  user: Partial<IUser>;
  mainCallToAction?: React.ReactElement;
  secondaryCallToAction?: React.ReactElement;
  innerSubtitleOverride?: string;
}

function PlaceholderCards() {
  const icons = [electricGuitarIcon, synthIcon, drumIcon, ampIcon, microphoneIcon];
  return <>{
    icons.map((icon, idx) => {
      return (
        <li className="tiles__tile" key={idx}>
          <div className="placeholder-card">
            <div className="placeholder-card__inner">
              <div className="placeholder-card__main">
                <div className="placeholder-card__image" style={{ backgroundImage: `url(${icon})` }}>
                  <div className="placeholder-watch-icon">
                    <RCIcon svgComponent={HeartFilledIcon} nativeSize />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </li>
      );
    })
  }</>;
}

export default function EmptyStateWatchedListings(props: IProps) {
  const innerSubtitle = props.innerSubtitleOverride || I18n.t(`commons.watchRow.${props.user.loggedOut ? 'loggedOut' : 'loggedIn'}.emptyState.subtitle`);
  let innerTitle = I18n.t('commons.watchRow.loggedOut.emptyState.title', { tagOpen: '<strong>', tagClose: '</strong>' });
  if (!props.user.loggedOut) {
    innerTitle = I18n.t('commons.watchRow.loggedIn.emptyState.title', { tagOpen: '<strong>', tagClose: '</strong>' });
  }

  return (
    <div className="site-wrapper">
      <div className="empty-watchlist-row">
        <div className="empty-watchlist-row__inner">
          <h2 className="empty-watchlist-row__heading">
            {I18n.t(`commons.watchRow.${props.user.loggedOut ? 'loggedOut' : 'loggedIn'}.title`)}
          </h2>
          {props.mainCallToAction &&
            <div className="empty-watchlist-row__action">
              {React.cloneElement(props.mainCallToAction)}
            </div>
          }
          <div className="empty-watchlist-row__items">
            <div className="empty-watchlist-row__items__inner">
              <div className="row-main-content">
                <div className="row-main-content__container">
                  <div className="mobile-icon" style={{ backgroundImage: `url(${listingIcon})` }}/>
                  <div>
                    <span className="inner-subtitle">
                      <RCIcon svgComponent={HeartFilledIcon} inline/>
                      <SanitizedRender
                        className="inner-subtitle__text"
                        html={innerSubtitle}
                      />
                    </span>
                    <SanitizedRender
                      htmlTag="div"
                      className="inner-title"
                      html={innerTitle}
                    />
                  </div>
                </div>
                {props.secondaryCallToAction &&
                  <div className="inner-call-to-action">
                    {props.secondaryCallToAction}
                  </div>
                }
              </div>
              <ul className="tiles tiles--single-row">
                <PlaceholderCards />
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
