import React from 'react';
import VisaCard from '@reverbdotcom/cadence/images/payment-card/visa-payment-card.svg?component';
import MastercardCard from '@reverbdotcom/cadence/images/payment-card/mastercard-payment-card.svg?component';
import DiscoverCard from '@reverbdotcom/cadence/images/payment-card/discover-payment-card.svg?component';
import AmexCard from '@reverbdotcom/cadence/images/payment-card/amex-payment-card.svg?component';
import JCBCard from '@reverbdotcom/cadence/images/payment-card/jcb-payment-card.svg?component';
import UnknownCard from '@reverbdotcom/cadence/images/payment-card/error-payment-card.svg?component';
import { RCIcon } from '@reverbdotcom/cadence/components';
import {
  core_apimessages_CreditCard_Brand as CreditCardBrand,
  core_apimessages_CreditCard_Brand_Type as CreditCardBrandType,

} from '../gql/graphql';

interface Props {
  brand: CreditCardBrand;
}

export default function CreditCardBrandIcon({ brand }: Props) {
  const type = brand.type.toLowerCase();
  const icon = creditCardIcons[type];

  return (
    <RCIcon
      title={brand.name}
      svgComponent={icon}
    />
  );
}

const creditCardIcons: Record<Lowercase<CreditCardBrandType>, any> = {
  unknown: UnknownCard,
  visa: VisaCard,
  mastercard: MastercardCard,
  discover: DiscoverCard,
  amex: AmexCard,
  jcb: JCBCard,
};
