import React from 'react';
import I18n from 'i18n-js';
// TODO update this to import from commons
// import { gql } from '@reverbdotcom/commons/src/gql';
// eslint-disable-next-line no-restricted-imports
import { gql } from '@apollo/client';

import ModalDialog from '@reverbdotcom/commons/src/components/modal_dialog';
import { CoreMyListingAvailableSalesMemberships } from '@reverbdotcom/commons/src/gql/graphql';
import { RCLoadingBars } from '@reverbdotcom/cadence/components';
import { useQuery } from '@reverbdotcom/commons/src/useQuery';
import SalesMemberships from './SalesMemberships';

export const AvailableSalesMembershipsFragment = gql`
  fragment AvailableSalesMemberships on Listing {
    _id
    availableSalesMemberships {
      reverbSalesMemberships {
        listingId
        sale {
          _id
          id
          name
          percent
          startsAt {
            seconds
          }
          endsAt {
            seconds
          }
          links {
            self {
              href
            }
          }
        }
        totalLiveListings
        isListingInSale
        isListingEligible
      }
      sellerSalesMemberships {
        listingId
        sale {
          _id
          id
          name
          percent
          startsAt {
            seconds
          }
          endsAt {
            seconds
          }
          links {
            self {
              href
            }
          }
        }
        totalLiveListings
        isListingInSale
        isListingEligible
      }
    }
  }
`;

const AVAILABLE_SALES_MEMBERSHIPS_QUERY = gql`
  query Core_MyListing_AvailableSalesMemberships($listingId: String) {
    listing(input: { id: $listingId }) {
      _id
      id
      ...AvailableSalesMemberships
    }
  },
  ${AvailableSalesMembershipsFragment}
`;
interface IExternalProps {
  listingId: string;
  isModalOpen: boolean;
  onModalClose: () => void;
}

export function SalesModal({
  listingId,
  isModalOpen,
  onModalClose,
}: IExternalProps) {
  const {
    data,
    loading,
  } = useQuery<CoreMyListingAvailableSalesMemberships.Query>(AVAILABLE_SALES_MEMBERSHIPS_QUERY, {
    ssr: false,
    fetchPolicy: 'network-only',
    variables: {
      listingId,
    },
    errorPolicy: 'all',
    skip: !isModalOpen,
  });

  const { listing = {} } = data ?? {};
  const { availableSalesMemberships = {} } = listing ?? {} ;
  const { reverbSalesMemberships = [], sellerSalesMemberships = [] } = availableSalesMemberships;

  return (
    <ModalDialog
      isOpen={isModalOpen}
      headerTitle={I18n.t('discovery.listingsManagement.sellerListingCard.sales.modal.title')}
      onSubmit={() => onModalClose()}
      onRequestClose={() => onModalClose()}
      saveButtonText={I18n.t('discovery.listingsManagement.sellerListingCard.sales.modal.closeButtonText')}
      hideFooterDismissButton
    >
      {loading && (
        <div className="d-flex fx-justify-center">
          <RCLoadingBars size="large" />
        </div>
      )}

      {!loading && (
        <SalesMemberships
          defaultReverbSalesMemberships={reverbSalesMemberships}
          defaultSellerSalesMemberships={sellerSalesMemberships}
        />
      )}
    </ModalDialog>
  );
}

export default SalesModal;
