export default class EmailValidationHelper {
  static isEmailValid(emailAddress) {
    /**
     * Verify that we've got some character(s), then an @ symbol, then some character(s), then a '.', then at least two characters.
     *
     * Ideally we would use input.checkValidity(),
     *   but then our frontend validations would be misaligned with the backend validations,
     *   and we might allow some emails that the backend would reject,
     *   which would result in a poor user experience.
     */
    return /^([^@\s]+)@((?:[-a-z0-9]+\.)+[a-z]{2,})$/.test(emailAddress);
  }
}
