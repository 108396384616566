import I18n from 'i18n-js';
import { flatten, compact } from 'lodash';
import qs from 'qs';
import React from 'react';
import { withRouter, WithRouterProps } from 'react-router';

import { RCSwitch } from '@reverbdotcom/cadence/components';

import { B_STOCK, NEW_GROUP, USED_GROUP } from '../condition_helpers';
import { Coupon } from '../coupon_context';

interface ExternalProps {
  coupon: Coupon;
}

type IProps = ExternalProps & WithRouterProps;

const IS_COUPON_SEARCH_PARAM = 'is_coupon_search';
const EXCLUDE_BRAND_SLUGS_PARAM = 'exclude_brand_slugs';
const EXCLUDE_CATEGORY_UUIDS_PARAM = 'exclude_category_uuids';
const INCLUDE_CONDITION_SLUG_PARAM = 'condition';

// Checks the coupon restrictions against the currently active 'condition[]=' URL query.
// Our condition filters are OR filters (e.g. you can opt in 'b-stock' items, even if your current filter set is scoped to 'new' only).
// For a coupon that does not support 'b-stock' items, a buyer could technically:
//
// 1. activate the coupon search (which sets proper filters for the coupon in URL)
// 2. click to opt in 'b-stock' items via the condition filter chip
//
// In these scenarios, we check for conflicts, and just mark the coupon toggle as off.
function hasConditionParamConflict(coupon, location) {
  // coupons that apply to all conditions can never have conflicting query params
  if (coupon.applyToUsedConditions && coupon.applyToNewConditions && coupon.applyToBStockConditions) { return false; }

  const activeConditionFilters = compact(flatten([location.query?.condition]));
  if (!activeConditionFilters.length) { return true; } // coupons that apply to some conditions should always have at least 1 condition param present

  if (!coupon.applyToNewConditions && activeConditionFilters.includes(NEW_GROUP)) { return true; }
  if (!coupon.applyToBStockConditions && activeConditionFilters.includes(B_STOCK)) { return true; }
  if (!coupon.applyToUsedConditions) {
    return !activeConditionFilters.every((slug) => [NEW_GROUP, B_STOCK].includes(slug));
  }

  return false;
}

export function ShopCouponSearchToggle({ coupon, location, router }: IProps) {
  const isToggleActive = location.query[IS_COUPON_SEARCH_PARAM]?.toLowerCase() === 'true' && !hasConditionParamConflict(coupon, location);

  function onToggle() {
    const updatedQueryParams = {};

    if (isToggleActive) {
      updatedQueryParams[IS_COUPON_SEARCH_PARAM] = false;
    } else {
      updatedQueryParams[IS_COUPON_SEARCH_PARAM] = true;

      if (coupon.excludedBrands.length) {
        updatedQueryParams[EXCLUDE_BRAND_SLUGS_PARAM] = coupon.excludedBrands.map(({ slug }) => slug);
      }

      if (coupon.excludedCategories.length) {
        updatedQueryParams[EXCLUDE_CATEGORY_UUIDS_PARAM] = coupon.excludedCategories.map(({ id }) => id);
      }

      if (!(coupon.applyToUsedConditions && coupon.applyToNewConditions && coupon.applyToBStockConditions)) {
        updatedQueryParams[INCLUDE_CONDITION_SLUG_PARAM] = compact([
          coupon.applyToUsedConditions && USED_GROUP,
          coupon.applyToNewConditions && NEW_GROUP,
          coupon.applyToBStockConditions && B_STOCK,
        ]);
      }
    }

    const updatedQueryString = qs.stringify(updatedQueryParams, { encode: false, arrayFormat: 'brackets' });

    router.push(`${location.pathname}?${updatedQueryString}`);
  }

  return (
    <RCSwitch checked={isToggleActive} label={I18n.t('discovery.shopCoupons.onlyEligibleItems')} onChange={onToggle}/>
  );
}

export default withRouter(ShopCouponSearchToggle);
