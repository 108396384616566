import React from 'react';
import I18n from 'i18n-js';
import {
  IRefundState,
} from './refund_request';
import { RCFormGroup } from '@reverbdotcom/cadence/components';
import ReverbAccommodationCheckbox, { AdyenPayoutsBlockedWarning } from './reverb_accommodation_checkbox';
import AdminAccommodationDataFields, { IAccommodationDataFields } from '@reverbdotcom/commons/src/components/admin_accommodation_data_fields';

interface IProps {
  accommodationAuthorized?: boolean;
  isAdyenShopWithPayoutsDisabled?: boolean;
  isReverbAccommodated: boolean;
  toggleReverbAccommodated: (event: any) => void;
  refundState: IRefundState;
  categoryToReasonMap: object;
  categoryToPolicyContextMap: object;
  formData: IAccommodationDataFields;
  setFormField: (fieldName: string, value: any) => void;
}

export default function RefundAccommodationFormFields(props: IProps) {

  return (
    <div className="scaling-mtb-4">
      <RCFormGroup
        label={I18n.t('discovery.dashboard.refundRequests.admin.formFields.title')}
        inputId="refund-accommodation-form-fields"
      >
        {renderReverbAccommodationCheckbox(props)}
        {renderAdminAccommodationFormFields(props)}
      </RCFormGroup>
    </div>
  );
}

const renderReverbAccommodationCheckbox = ({
  accommodationAuthorized,
  isAdyenShopWithPayoutsDisabled,
  isReverbAccommodated,
  refundState,
  toggleReverbAccommodated,
}: IProps) => {

  if (accommodationAuthorized && !isAdyenShopWithPayoutsDisabled) {
    return (
      <ReverbAccommodationCheckbox
        isReverbAccommodated={isReverbAccommodated}
        refundState={refundState}
        toggleReverbAccommodated={toggleReverbAccommodated}
      />
    );
  }

  if (accommodationAuthorized && isAdyenShopWithPayoutsDisabled) {
    return  <AdyenPayoutsBlockedWarning />;
  }

  return null;
};

const renderAdminAccommodationFormFields = (props: IProps) => {
  const {
    refundState,
    isReverbAccommodated,
    categoryToReasonMap,
    categoryToPolicyContextMap,
    formData,
    setFormField,
  } = props;

  const approved = refundState === IRefundState.APPROVED;

  if (isReverbAccommodated && approved) {
    return (
      <div className="mt-2">
        <AdminAccommodationDataFields
          categoryToReasonMap={categoryToReasonMap}
          categoryToPolicyContextMap={categoryToPolicyContextMap}
          formData={formData}
          setFormField={setFormField}
        />
      </div>
    );
  }

  return null;
};
