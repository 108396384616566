import { ChildProps } from '@apollo/client/react/hoc';
// TODO update this to import from commons
// import { gql } from '@reverbdotcom/commons/src/gql';
// eslint-disable-next-line no-restricted-imports
import { gql } from '@apollo/client';
import React from 'react';
import I18n from 'i18n-js';
import moment from 'moment';
import { LineChartData } from 'chartist';

import { withGraphql } from '@reverbdotcom/commons/src/with_graphql';
import { IUserContext, withUserContext } from '@reverbdotcom/commons/src/components/user_context_provider';
import { conditionSlugForUuid } from '@reverbdotcom/commons/src/condition_helpers';
import { MParticleEventName, trackEvent } from '@reverbdotcom/commons/src/elog/mparticle_tracker';
import { useViewTracking } from '@reverbdotcom/commons/src/use_tracking';
import { parseAmount } from '@reverbdotcom/commons/src/money';

import SanitizedRender from '@reverbdotcom/commons/src/components/sanitized_render';
import LineGraph from '../graph/line_graph';
import { RCSegmentedControl } from '@reverbdotcom/cadence/components';
import { buildChartData, buildChartDescription, DATE_FORMAT } from './price_guide_tool_transaction_graph_helpers';
import searchEmptyStateImage from '../../../../images/icons/empty-states/not-found.svg';
import {
  SearchPriceGuideToolTransactionGraph,
  CSP,
} from '@reverbdotcom/commons/src/gql/graphql';
import { connect } from '@reverbdotcom/commons/src/connect';

interface IExternalProps extends IUserContext {
  canonicalProductIds: string[];
  conditionUuids?: string[];
  csp: CSP;
}

type IProps = ChildProps<IExternalProps, SearchPriceGuideToolTransactionGraph.Query>;

export const COMPONENT_NAME = 'PriceGuideToolTransactionGraphContainer';
export const SIX_MONTH_DATE_RANGE_KEY = 'months_6';
export const ONE_YEAR_DATE_RANGE_KEY = 'years_1';
export const TWO_YEAR_DATE_RANGE_KEY = 'years_2';

export const DATE_RANGE_SELECTORS = [
  SIX_MONTH_DATE_RANGE_KEY,
  ONE_YEAR_DATE_RANGE_KEY,
  TWO_YEAR_DATE_RANGE_KEY,
];

export const DURATION_IN_MONTHS = {
  [SIX_MONTH_DATE_RANGE_KEY]: 6,
  [ONE_YEAR_DATE_RANGE_KEY]: 12,
  [TWO_YEAR_DATE_RANGE_KEY]: 24,
};

export const LABEL_TILT_DEGREES = {
  [SIX_MONTH_DATE_RANGE_KEY]: 0,
  [ONE_YEAR_DATE_RANGE_KEY]: 30,
  [TWO_YEAR_DATE_RANGE_KEY]: 45,
};

export const SELLER_COUNTRY_CODES = ['US'];
export const PAID_TRANSACTION_STATUSES = [
  'shipped',
  'picked_up',
  'received',
];

export const QUERY = gql`
query Search_PriceGuideToolTransactionGraph(
  $canonicalProductIds: [String],
  $sellerCountries: [String],
  $conditionUuids: [String],
  $createdAfterDate: String,
  $actionableStatuses: [String]
) {
  priceRecordsSearch(input: {
    canonicalProductIds: $canonicalProductIds,
    sellerCountries: $sellerCountries,
    listingConditionUuids: $conditionUuids,
    createdAfterDate: $createdAfterDate,
    actionableStatuses: $actionableStatuses,
    withAverageMonthlyProductPricesAggregations: true
  }) {
    averageMonthlyProductPrices {
      date
      docCount
      averageProductPrice {
        amount
        amountCents
        currency
        display
      }
    }
  }
}
`;

export function PriceGuideToolTransactionGraphEmptyState() {
  return (
    <div className="d-flex fx-dir-col tablet-mt-4">
      <div className="d-flex mobile-fx-dir-col fx-align-center mb-8 mobile-mb-4">
        <div className="fx-width-60 mobile-fx-width-0">
          {I18n.t('discovery.priceGuideTool.show.form.results.transactionGraph.nullState.needMoreTransactions')}
        </div>
        <div className="fx-width-40 mobile-width-100">
          <img
            src={searchEmptyStateImage}
            alt={I18n.t('discovery.priceGuideTool.show.form.results.transactionGraph.nullState.imageAlt')}
            className="plr-8"
          />
        </div>
      </div>
      <div className="d-flex fx-dir-col">
        <div className="weight-bold size-90 mb-2">
          {I18n.t('discovery.priceGuideTool.show.form.results.transactionGraph.nullState.whyIsThis')}
        </div>
        <div className="size-80">
          {I18n.t('discovery.priceGuideTool.show.form.results.transactionGraph.nullState.aGraphMayBeAvailable')}
        </div>
      </div>
    </div>
  );
}

export function createdAfterTwoYearsAgoDate() {
  return moment().startOf('month').subtract(DURATION_IN_MONTHS[TWO_YEAR_DATE_RANGE_KEY] - 1, 'months').format(DATE_FORMAT);
}

export function createdAfterSixMonthsAgoDate() {
  return moment().startOf('month').subtract(DURATION_IN_MONTHS[SIX_MONTH_DATE_RANGE_KEY] - 1, 'months').format(DATE_FORMAT);
}

function chartOptions(user) {
  return {
    axisY: {
      showGrid: true,
      showLabel: true,
      labelInterpolationFnc: (val) => parseAmount(val, user?.currency || 'USD', { precision: 0 }).display,
    },
  };
}

export function PriceGuideToolTransactionGraphContainer({
  canonicalProductIds,
  conditionUuids,
  csp,
  data,
  user,
}: IProps) {
  const { loading, priceRecordsSearch = {} } = data;
  const { averageMonthlyProductPrices: productPrices = [] } = priceRecordsSearch;

  const canonicalProductId = canonicalProductIds[0] || '';
  const conditionSlug = conditionSlugForUuid(conditionUuids[0]);
  const totalOrders = productPrices.reduce((acc, field) => acc + field.docCount, 0);
  const shouldRenderEmptyState = !totalOrders;

  const [dateRange, setDateRange] = React.useState(DATE_RANGE_SELECTORS[0]);
  const currentMonth = moment().startOf('month');
  // we subtract one from the duration, since the current month is included in the date range
  const monthsSubtractedFromCurrentMonth = DURATION_IN_MONTHS[dateRange] - 1;
  const createdAfterDate = currentMonth.subtract(monthsSubtractedFromCurrentMonth, 'months').format(DATE_FORMAT);
  const labelTiltDegrees = LABEL_TILT_DEGREES[dateRange];
  const chartData = totalOrders ? buildChartData({ productPrices, createdAfterDate }) : {} as LineChartData;

  React.useEffect(() => {
    trackEvent({
      eventName: MParticleEventName.ClickedPriceGuideToolTransactionGraphDateRangeButton,
      componentName: COMPONENT_NAME,
      cspId: csp.id,
      cspSlug: csp.slug,
      conditionSlug,
      dateRange,
    });
  }, [dateRange]);

  useViewTracking({
    eventName: MParticleEventName.ComponentView,
    componentName: 'PriceGuideToolTransactionGraph',
    cspId: csp.id,
    cspSlug: csp.slug,
    cpId: canonicalProductId,
    conditionSlug,
    totalOrders,
  }, !loading);

  const conditionText = I18n.t(`discovery.conditions.${conditionSlug}`).toLowerCase();

  function tooltipCallback(meta) {
    const titleText = I18n.t('discovery.priceGuideTool.show.form.results.transactionGraph.tooltip.productsSold', { count: meta.docCount });
    const priceValueText = I18n.t('discovery.priceGuideTool.show.form.results.transactionGraph.tooltip.amountAverage', {
      formattedAmount: meta.averageProductPrice.display,
      dateString: moment(meta.date).format('MMM YYYY'),
    });
    const conditionSection = I18n.t('discovery.priceGuideTool.show.form.results.transactionGraph.tooltip.condition', {
      conditionText,
    });

    return `
      <div class="price-guide-tool-transaction-graph__tooltip">
        <h3 class="price-guide-tool-transaction-graph__tooltip__title">
          ${titleText}
        </h3>
        <span class="price-guide-tool-transaction-graph__tooltip__price-value">
          ${priceValueText}
        </span>
        <span class="price-guide-tool-transaction-graph__tooltip__condition">
          ${conditionSection}
        </span>
      </div>
    `;
  }

  if (loading) return null;

  return (
    <div className="price-guide-tool-transaction-graph-container">
      <h3 className="size-100 weight-bold">
        {I18n.t('discovery.priceGuideTool.show.form.results.transactionGraph.priceHistory')}
      </h3>

      {shouldRenderEmptyState ? (
        <PriceGuideToolTransactionGraphEmptyState />
      ) : (
        <>
          <p className="mb-0 size-80">
            {I18n.t('discovery.priceGuideTool.show.form.results.transactionGraph.tooltip.basedOn', { count: totalOrders })}
          </p>
          <LineGraph
            chartData={chartData}
            chartOptions={chartOptions(user)}
            tooltipCallback={tooltipCallback}
            labelTiltDegrees={labelTiltDegrees}
            ariaLabel={I18n.t('discovery.priceGuideTool.show.form.results.transactionGraph.ariaLabel')}
            ariaDescription={buildChartDescription(chartData)}
          />
          <div className="fx-align-self-end mobile-width-100">
            <RCSegmentedControl
              aria-label={I18n.t('discovery.priceGuideTool.show.form.results.transactionGraph.buttonGroup.label')}
              loading={loading}
              fullWidth
              size="small"
            >
              {DATE_RANGE_SELECTORS.map((range, index) => (
                <RCSegmentedControl.Button
                  key={range}
                  onClick={() => setDateRange(DATE_RANGE_SELECTORS[index])}
                  selected={dateRange === DATE_RANGE_SELECTORS[index]}
                >
                  {I18n.t(`discovery.priceGuideTool.show.form.results.transactionGraph.buttonGroup.${range}`)}
                </RCSegmentedControl.Button>
              ))}
            </RCSegmentedControl>
          </div>
          <SanitizedRender
            className="size-80 opacity-70 mb-0 mt-4 lh-120 align-right"
            htmlTag="p"
            html= {
              I18n.t('discovery.priceGuideTool.show.form.results.transactionGraph.pricesReflect',
                {
                  condition: conditionText,
                  supOpen: '<sup>',
                  supClose: '</sup>',
                })
            }
          />
        </>
      )}
    </div>
  );
}

const withQuery = withGraphql<IProps, SearchPriceGuideToolTransactionGraph.Query>(
  QUERY,
  {
    options: ({ conditionUuids, canonicalProductIds }) => {
      return {
        ssr: false,
        fetchPolicy: 'network-only',
        variables: {
          canonicalProductIds,
          conditionUuids: conditionUuids || [],
          sellerCountries: SELLER_COUNTRY_CODES,
          createdAfterDate: createdAfterTwoYearsAgoDate(),
          actionableStatuses: PAID_TRANSACTION_STATUSES,
        },
      };
    },
  },
);

export default connect<IExternalProps>([
  withUserContext,
  withQuery,
])(props => {
  return <PriceGuideToolTransactionGraphContainer {...props} />;
});
