import React from 'react';
import I18n from 'i18n-js';
import ShopSettingsCreateVatIdForm from './shop_settings_create_vat_id_form';
import { filterUsedCountries } from './shop_settings_vat_layout';
import { ISelectOption } from '@reverbdotcom/commons/src/components/form_group_with_select';
import {
  core_apimessages_Country as Country,
  core_apimessages_TaxIdentification as TaxIdentification,
} from '@reverbdotcom/commons/src/gql/graphql';

interface Props {
  taxIdentifications: TaxIdentification[];
  countries: Country[];
}

export default function ShopSettingsVatAddVatIdButton({ taxIdentifications, countries }: Props) {
  const [isOpen, setIsOpen] = React.useState(false);

  const usedCountries = filterUsedCountries(taxIdentifications);
  const countryOptions = createCountryOptions(countries, usedCountries);

  function openForm() {
    setIsOpen(true);
  }

  function closeForm() {
    setIsOpen(false);
  }

  return (
    <>
      <button
        className="button button--primary"
        type="button"
        onClick={openForm}
      >
        {I18n.t('discovery.shopSettings.vat.addVat')}
      </button>
      <ShopSettingsCreateVatIdForm
        countryOptions={countryOptions}
        isOpen={isOpen}
        closeForm={closeForm}
      />
    </>
  );
}

export function createCountryOptions(countries: Country[], usedCountries: string[]): ISelectOption[] {
  return countries.map(country => {
    const value = country.countryCode;
    const text = country.name;
    const disabled = usedCountries.includes(country.countryCode);

    return ({
      value,
      text,
      disabled,
    });
  });
}
