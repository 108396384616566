import { createContext, Dispatch } from 'react';
import { ICardState } from './sellerListingCardReducer';

export interface ISellerListingCardContext {
  cardState: ICardState;
  dispatch?: Dispatch<any>;
}

const SellerListingCardContext = createContext<ISellerListingCardContext>({
  cardState: {
    toast: {
      isOpen: false,
      message: '',
      isSuccess: true,
    },
  },
  dispatch: () => {},
});


export default SellerListingCardContext;
