import React from 'react';
import * as storage from '../storage_wrapper';

const DISMISSED_ALERTS = 'dismissed_alerts';

export default function useDismissibleAlert(name: string): [boolean, () => void] {
  const [value, setValue] = React.useState(true);

  React.useEffect(() => {
    try {
      const data = storage.getItem(DISMISSED_ALERTS);
      const all = data ? JSON.parse(data) : {};
      setValue(!!all[name]);
    } catch (error) {
      console.error('dismissible alertbox localStorage get error', error);
      setValue(false);
      storage.removeItem(DISMISSED_ALERTS);
    }
  }, [name, storage]);

  function dismiss() {
    try {
      const data = storage.getItem(DISMISSED_ALERTS);
      const all = data ? JSON?.parse(data) : {};
      all[name] = true;
      storage.setItem(DISMISSED_ALERTS, JSON.stringify(all));

      setValue(true);
    } catch (error) {
      console.error('dismissible alertbox localStorage set error', error);
    }
  }

  return [value, dismiss];
}
