import { Listing, reverb_search_SearchResponse } from '../gql/graphql';

export function itemSoldSprigEvent(): void {
  sprigEvent('web_item_sold', {});
}

export function publishedListingSprigEvent(listing: Listing): void {
  sprigEvent('web_item_listed', {
    'Brand': listing.brandSlug,
  });
}

export function searchResultsSprigEvent(searchResponse: reverb_search_SearchResponse): void {
  sprigEvent('web_search_results', {
    'Total Results': searchResponse.total || 0,
    'Listings': (searchResponse.listings || []).length,
    'Fallback Listings': (searchResponse.fallbackListings || []).length,
    'Suggested Queries': (searchResponse.suggestedQueries || []).length,
    'Suggestions': (searchResponse.suggestions || []).length,
  });
}

/*
 * Report events to Sprig using externally declared Sprig snippet
 */
export function sprigEvent(eventName: string, properties: any): void {
  if (window.Sprig) {
    // eslint-disable-next-line new-cap
    window.Sprig('identifyAndTrack', { eventName, properties });
  }
}
