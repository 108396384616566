import React from 'react';
import I18n from 'i18n-js';
// TODO update this to import from commons
// import { gql } from '@reverbdotcom/commons/src/gql';
// eslint-disable-next-line no-restricted-imports
import { gql } from '@apollo/client';

import { useMutation } from '@reverbdotcom/commons/src/useMutation';
import { RCButton, RCTextWithIcon, useMediaQuery } from '@reverbdotcom/cadence/components';
import { CheckCircleIcon } from '@reverbdotcom/cadence/icons/react';
import { isEmpty } from 'lodash';
import { MParticleEventName } from '@reverbdotcom/commons/src/elog/mparticle_types';
import { trackEvent } from '@reverbdotcom/commons/src/elog/mparticle_tracker';
import { CoreMyListingsPublishAllListings } from '@reverbdotcom/commons/src/gql/graphql';
import { useViewTracking } from '@reverbdotcom/commons/src/use_tracking';

export const COMPONENT_NAME = 'PublishAllDraftsButton';

interface IExternalProps {
  onSuccessCallback: (errorMessage: string) => void;
  onErrorCallback: (errorMessage: string) => void;
}

const PUBLISH_ALL_DRAFTS_MUTATION = gql`
  mutation Core_MyListings_PublishAllListings {
    publishAllListings {
      enqueued
      message
      prepublishStepRequired {
        step
      }
    }
  }
`;

export default function PublishAllDraftsButton({
  onSuccessCallback,
  onErrorCallback,
}: IExternalProps) {
  const isMobile = useMediaQuery('mobile');

  const [publishAllListings, {
    data = {},
    loading,
    errors,
  }] = useMutation<CoreMyListingsPublishAllListings.Mutation>(PUBLISH_ALL_DRAFTS_MUTATION);

  const { enqueued, message } = data.publishAllListings ?? {};
  const hasErrors = !!errors.length;

  React.useEffect(() => {
    if (loading) return;
    if (hasErrors) return onErrorCallback(
      I18n.t('discovery.listingsManagement.genericError'),
    );

    handleResponse();
  }, [loading]);

  function handleEnqueued() {
    if (enqueued) {
      onSuccessCallback(message);
    } else {
      onErrorCallback(message);
    }
  }

  function handleResponse() {
    if (isEmpty(data)) return;

    handleEnqueued();
  }

  function handleClick() {
    trackEvent({
      eventName: MParticleEventName.ClickedComponent,
      componentName: COMPONENT_NAME,
    });

    publishAllListings();
  }

  useViewTracking({
    componentName: COMPONENT_NAME,
    eventName: MParticleEventName.ComponentView,
  }, true);

  return (
    <>
      <RCButton
        fullWidth
        variant="filled"
        size={isMobile ? 'medium' : 'mini'}
        onClick={handleClick}
        disabled={loading}
      >
        <RCTextWithIcon
          svgComponent={CheckCircleIcon}
          placement="right"
        >
          {I18n.t('discovery.listingsManagement.toolbar.bulkActions.publishAll')}
        </RCTextWithIcon>
      </RCButton>
    </>
  );
}
