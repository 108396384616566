import {
  compact,
  some,
  sumBy,
  uniq,
} from 'lodash';
import {
  MParticlePageName,
  PageViewEvent,
} from '@reverbdotcom/commons/src/elog/mparticle_tracker';
import { Paths } from './url_helpers';
import CoreClient from '../../lib/core_client';

export interface CartItem {
  payment_methods: Record<string, any>;
  product_id: string | number;
  item_price: { amount: string, currency: string };
  quantity?: number;
  seller_id: string;
}

export async function fetchCartItems(): Promise<CartItem[]> {
  const headers = {
    'X-Display-Currency': 'USD',
  };

  const response = await CoreClient.get<{ cart_items: CartItem[] }>(Paths.apiCart.expand({}), { headers });
  return response.cart_items;
}

interface CheckoutPageViewParams {
  cartItems: CartItem[];
}

export function buildCartPageView({ cartItems }: CheckoutPageViewParams): PageViewEvent {
  const itemTotalPrice = getTotal(cartItems, 'item_price');
  const shippingTotalPrice = getTotal(cartItems, 'shipping_price');
  const negotiatedOrder = some(cartItems, { type: 'offer' });
  const sellerCount = getSellerCount(cartItems);
  const currencyCount = getCurrencyCount(cartItems);

  return {
    pageName: MParticlePageName.Cart,
    itemCount: cartItems.length,
    sellerCount,
    totalPrice: itemTotalPrice.toFixed(2),
    totalShippingRate: shippingTotalPrice.toFixed(2),
    'negotiatedOrder?': negotiatedOrder,
    currencyCount,
  };
}

function getTotal(cartItems: CartItem[], key: string) {
  return sumBy(
    cartItems,
    (item) => {
      return (item[key] && parseFloat(item[key].amount)) || 0;
    },
  );
}

function getSellerCount(cartItems: CartItem[]) {
  const sellerLinks = uniq(compact(cartItems.map(cartItem => cartItem.seller_id)));
  return sellerLinks.length;
}

function getCurrencyCount(cartItems: CartItem[]) {
  const uniqueCurrencies = uniq(compact(cartItems.map(item => item.item_price.currency)));
  return uniqueCurrencies.length;
}
