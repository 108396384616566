import window from '@reverbdotcom/commons/src/window_wrapper';

/**
 * Includes only the fields from
 * the Adyen Apple Pay object
 */
interface ApplePay {
  props: {
    version?: number;
    configuration: {
      merchantId?: string;
    };
  }
}

/**
 * Based on an experiment in 2022, Apple Pay is always shown as
 * an option when available to users in the following regions.
 *
 * If a user is outside one of these regions, then Apple
 * Pay will only be shown if a user has an active card in
 * their Apple ID Wallet.
 */
const ALWAYS_AVAILABLE_REGIONS = ['US', 'GB', 'CA'];

export async function isApplePayAvailable({ applePay, userCountryCode }: { applePay: ApplePay, userCountryCode: string }) {
  const { version, configuration: { merchantId } } = applePay.props;

  const isSupportedVersion = window.ApplePaySession && ApplePaySession.supportsVersion(version);

  return isSupportedVersion &&
    (ALWAYS_AVAILABLE_REGIONS.includes(userCountryCode) ||
      ApplePaySession.canMakePaymentsWithActiveCard(merchantId));
}
