import React from 'react';
import I18n from 'i18n-js';
import APIRequest from '../api_request';

interface Props {
  productId: number;
  bid?: number;
}

interface State {
  bid: number;
  error: string;
}

class BumpBid extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      bid: props.bid,
      error: null,
    };
  }

  componentDidMount() {
    if (this.state.bid) { return; }

    APIRequest.V3.get(`/bump/bids/${this.props.productId}`)
      .then(response => this.setState({ bid: response.bid }))
      .fail(response => this.setState({ error: this.extractError(response) }));
  }

  extractError(response) {
    if (response && response.responseJSON) {
      return response.responseJSON.error;
    }
    return I18n.t('discovery.bump.viewError');
  }

  renderBid() {
    if (!this.state.bid) { return null; }

    const displayBid = (this.state.bid * 100).toFixed(1);

    return (
      <p className="align-left strong extratight-line-height mb-0">
        {displayBid}%
      </p>
    );
  }

  renderError() {
    if (!this.state.error) { return null; }

    return <p className="color-red size-80 align-left mb-0">{this.state.error}</p>;
  }

  render() {
    return (
      <div className="mini-bump-meter mini-bump-meter--new-bump scaling-mt-1">
        <div className="bump-title">
          {I18n.t('discovery.bump.bumped')}
        </div>
        {this.renderBid()}
        {this.renderError()}
      </div>
    );
  }
}

export default BumpBid;
