import React from 'react';
import { Router } from 'react-router';
import routerHistory from '@reverbdotcom/commons/src/cms/lib/router_history';

import ErrorBoundary from '@reverbdotcom/commons/src/components/error_boundary';
import ErrorDisplay from '../components/discovery/error_display';

const OFFSET = -60;

function afterRender(cb) {
  setTimeout(() => {
    // Opera Mini :( -- how does anything work for them?
    if (!window.requestAnimationFrame) {
      cb();
      return;
    }
    // Wait two animation frames to help ensure that React
    // has flushed some of the elements to the DOM
    requestAnimationFrame(() => {
      requestAnimationFrame(cb);
    });
  }, 0);
}

// Smooth client side routing for anchor tags, find the specified element
// and scroll the window to the element with a offset after the route
// has been navigated
function scrollToAnchor() {
  afterRender(() => {
    const { hash } = window.location;
    if (hash !== '') {
      const id = hash.replace('#', '');
      const element = document.getElementById(id);

      if (element) {
        const top = element.getBoundingClientRect().top + window.pageYOffset + OFFSET;
        window.scrollTo({
          top,
          behavior: 'smooth',
        });
      }
    }
  });
}

export default (routes, { onUpdate = () => {} } = {}) => (
  (routerProps = {}) => (
    <ErrorBoundary
      errorName="fatal-fallback-error-boundary"
      fallback={<ErrorDisplay />}
    >
      <Router
        onUpdate={() => {
          onUpdate();
          scrollToAnchor();
        }}
        history={routerHistory}
        {...routerProps}
      >
        {routes}
      </Router>
    </ErrorBoundary>
  )
);
