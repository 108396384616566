import { gql } from '@reverbdotcom/commons/src/gql';
import { useUser } from '@reverbdotcom/commons/src/user_hooks';
import { CheckoutStep, PAYMENT_STEP, SHIPPING_STEP, isValidStep } from '../../lib/checkout_step';
import { IUser } from '@reverbdotcom/commons/src/components/user_context_provider';
import { isCheckoutLocalPickupOnly, isCheckoutShipped, isCheckoutDigitalDeliveryOnly } from '../../lib/checkoutShipping';
import { IUrl, useURL } from '@reverbdotcom/commons/src/url_context';
import { CheckoutDisplayStepHookFields } from '@reverbdotcom/commons/src/gql/graphql';
import { InjectedRouter } from 'react-router';
import { omit } from 'lodash';
import { CHECKOUT_ERROR_KEY } from '../../lib/checkoutPaths';

type Checkout = CheckoutDisplayStepHookFields.Fragment;

export function useCheckoutDisplayStep({ checkout, router }: { checkout: Checkout, router: InjectedRouter }) {
  const user = useUser();
  const url = useURL();
  const step = findCheckoutStep(checkout, user, url);

  function setStep(checkoutStep: CheckoutStep) {
    const nextQuery = {
      ...omit(url.query, CHECKOUT_ERROR_KEY),
      step: checkoutStep,
    };

    router.push({
      query: nextQuery,
      pathname: url.pathname,
    });
  }

  return {
    setStep,
    step,
  };
}

function findCheckoutStep(checkout: Checkout, user: Partial<IUser>, url: IUrl): CheckoutStep {
  if (isValidStep(url.query.step)) {
    return url.query.step;
  }

  if (isPaymentStep(checkout, user)) {
    return PAYMENT_STEP;
  }

  return SHIPPING_STEP;
}

function isPaymentStep(checkout: Checkout, user: Partial<IUser>) {
  if (!checkout) {
    return false;
  }

  const isUserInfoComplete = (!user.loggedOut || checkout.guest?.isComplete);
  const isShippingInfoComplete = checkout.shippingAddress?.isComplete;

  const isVisibleForShippedCheckout = isCheckoutShipped(checkout) && isShippingInfoComplete;
  const isVisibleForLocalPickupOnlyCheckout = isCheckoutLocalPickupOnly(checkout) && isUserInfoComplete;

  const isVisibleForDigitalOnlyCheckout = isCheckoutDigitalDeliveryOnly(checkout) &&
    (checkout.paymentMethod?.isComplete || (isShippingInfoComplete && isUserInfoComplete));

  return isVisibleForShippedCheckout || isVisibleForLocalPickupOnlyCheckout || isVisibleForDigitalOnlyCheckout;
}

export const CheckoutDisplayStepHookFragment = gql(`
  fragment CheckoutDisplayStepHookFields on Checkout {
    _id
    paymentMethod {
      type
      isComplete
    }
    orders {
      shippingMethod {
        type
      }
    }
    guest {
      isComplete
    }
    shippingAddress {
      _id
      isComplete
    }
    billingAddress {
      _id
      isComplete
    }
  }
`);
