import { ChildProps } from '@apollo/client/react/hoc';
// TODO update this to import from commons
// import { gql } from '@reverbdotcom/commons/src/gql';
// eslint-disable-next-line no-restricted-imports
import { gql } from '@apollo/client';
import React from 'react';
import I18n from 'i18n-js';
import CoreLink from '@reverbdotcom/commons/src/components/core_link';
import { RCIcon } from '@reverbdotcom/cadence/components';
import { ReverbLogoIcon } from '@reverbdotcom/cadence/icons/react';
import URLHelpers from './shared/url_helpers';
import UrlHelpers from '../shared/url_helpers';
import { SecureCheckoutMessage } from './checkout/SecureCheckoutMessage';
import { CoreCheckoutHeader } from '@reverbdotcom/commons/src/gql/graphql';
import { CheckoutClickInteraction } from '@reverbdotcom/commons/src/elog/mparticle_types';
import { checkoutClickEvent, CheckoutTrackingFragment } from './checkout/checkoutTracking';
import { withGraphql } from '@reverbdotcom/commons/src/with_graphql';
import { withUrlContext, WithUrlProps } from '@reverbdotcom/commons/src/url_context';
import { connect } from '@reverbdotcom/commons/src/connect';
import { AccertifyDeviceScript } from './AccertifyDeviceScript';

interface ExternalProps {
  id: string;
}

type Props = ChildProps<ExternalProps & WithUrlProps, CoreCheckoutHeader.Query>;

export function CheckoutHeaderWithTracking(props: Props) {
  function clickData(interaction: CheckoutClickInteraction) {
    if (!props.data || props.data.loading) {
      return null;
    }

    const data = {
      checkoutClickInteraction: interaction,
      checkoutId: props.data.checkout.id,
      paymentMethod: props.data.checkout.paymentMethod.type,
    };

    return checkoutClickEvent(data);
  }

  return (
    <>
      <header className="checkout-header">
        <div className="checkout-header__inner">
          <div className="checkout-header__content">
            <CoreLink
              to={UrlHelpers.rootPath}
              className="checkout-header__logo"
              aria-label={I18n.t('discovery.checkoutHeader.reverb')}
              clickEvent={clickData(CheckoutClickInteraction.HeaderBackToHomepage)}
            >
              <RCIcon svgComponent={ReverbLogoIcon} />
            </CoreLink>
            <SecureCheckoutMessage />
            <CoreLink
              to={URLHelpers.cartPath}
              className="checkout-header__cart-link"
              clickEvent={clickData(CheckoutClickInteraction.HeaderBackToCart)}
            >
              {I18n.t('discovery.checkoutHeader.viewCart')}
            </CoreLink>
          </div>
        </div>
      </header>

      <AccertifyDeviceScript />
    </>
  );
}

const withQuery = withGraphql<Props, CoreCheckoutHeader.Query>(
  gql`
    query Core_Checkout_Header(
      $id: String,
    ) {
      checkout(input: {id: $id}) {
        _id
        ...CheckoutTrackingFields
      }
    }
    ${CheckoutTrackingFragment}
    `,
  {
    options: (ownProps) => {
      return {
        variables: {
          id: ownProps.id,
        },
        errorPolicy: 'ignore',
      };
    },
  },
);

export default connect<ExternalProps>([
  withUrlContext,
  withQuery,
])(CheckoutHeaderWithTracking);
