import React from 'react';
import { trackCriteoCartView } from '@reverbdotcom/commons/src/criteo';
import { useUser } from '@reverbdotcom/commons/src/user_hooks';
import { trackPageView } from '@reverbdotcom/commons/src/elog/mparticle_tracker';
import { buildCartPageView, fetchCartItems } from '../shared/checkout_events';
import { IUser } from '@reverbdotcom/commons/src/components/user_context_provider';

export function useTrackCartView() {
  const [hasViewed, setViewed] = React.useState(false);
  const user = useUser();

  React.useEffect(() => {
    if (!hasViewed) {
      trackCartView(user);
      setViewed(true);
    }
  }, [user, hasViewed]);
}

async function trackCartView(user: Partial<IUser>) {
  const cartItems = await fetchCartItems();

  const cartViewEvent = buildCartPageView({
    cartItems,
  });

  trackPageView(cartViewEvent);

  const items = cartItems.map(item => ({
    id: item.product_id.toString(),
    price: item.item_price.amount,
    quantity: item.quantity || 1,
  }));
  trackCriteoCartView(items, user);
}

export default function TrackCartView() {
  useTrackCartView();

  return null;
}
