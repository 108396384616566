import React from 'react';
import classNames from 'classnames';
import { IndexLink, Link } from 'react-router';

import { RCStarRating } from '@reverbdotcom/cadence/components';
import ScrollLink from './scroll_link';

export interface IProps {
  text: string;
  url?: string;
  count?: number | string;
  scrollTo?: string;
  /** Styles the link as active, overriding the router's 'active' determination */
  activeOverride?: boolean;
  onClick?: (evt: any) => void;
  /** Renders a star rating in the tab (0.0-1.0) */
  starRating?: number;
  /** Use the react-router IndexLink component instead of Link */
  indexLink?: boolean;
}

/** Only to be used as a child of SharedTabs */
export class SharedTab extends React.Component<IProps, null> {
  getScrollProp() {
    if (this.props.scrollTo) {
      return {
        scrollTo: this.props.scrollTo,
      };
    }
  }

  getActiveClassName() {
    if (this.props.url === '#') return;

    return 'shared-tabs__tab--active';
  }

  getLinkComponent(): any {
    if (this.props.indexLink) return IndexLink;

    return this.props.scrollTo === undefined ? Link : ScrollLink;
  }

  render() {
    const LinkComponent = this.getLinkComponent();

    // optionally hardcodes active class
    const classes = classNames(
      'shared-tabs__tab',
      { 'shared-tabs__tab--active': this.props.activeOverride },
    );

    return (
      <LinkComponent
        className={classes}
        activeClassName={this.getActiveClassName()}
        to={this.props.url}
        onClick={this.props.onClick}
        {...this.getScrollProp()}
      >
        <div className="shared-tabs__text">
          {this.props.text}
        </div>
        {this.props.starRating !== undefined &&
          <div className="shared-tabs__rating">
            <RCStarRating
              rating={this.props.starRating}
            />
          </div>
        }
        {this.props.count !== undefined &&
          <div className="shared-tabs__count">
            {this.props.count}
          </div>
        }
      </LinkComponent>
    );
  }
}

export default SharedTab;
