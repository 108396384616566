import React from 'react';
import FormGroupWithSelect from '@reverbdotcom/commons/src/components/form_group_with_select';
import FormGroupWithInput from '@reverbdotcom/commons/src/components/form_group_with_input';
import FormGroup from '@reverbdotcom/commons/src/components/form_group';
import { useShopDisbursements, Disbursement } from './admin_shop_disbursement_hook';

interface Props {
  shopID: string;
}

export default function AdminBouncedPayoutAmountFormFields(props: Props) {
  const [inputAmount, setInputAmount] = React.useState('');
  const [currencyCode, setCurrencyCode] = React.useState('USD');
  const [disbursementID, setDisbursementID] = React.useState();

  const disbursements = useShopDisbursements({ shopID: props.shopID, inputAmount, currencyCode });

  function updateAmountField(field) {
    setInputAmount(field.amount);
  }

  function updateCurrencyCode(field) {
    setCurrencyCode(field.currency);
  }

  function updateDisbursement(field) {
    setDisbursementID(field['bounced_payout[disbursement_id]']);
  }

  const disbursementOptions = disbursements.map((disbursement: Disbursement) => ({
    text: `#${disbursement.id}: ${disbursement.amount.display} on ${disbursement.created_at}`,
    value: String(disbursement.id),
  }));

  const currencyCodeOptions = ['USD', 'CAD'].map((code) => ({
    text: code,
    value: code,
  }));

  return (
    <div>
      <FormGroup label="Search disbursements by amount">
        <div className="d-inline-block width-rem-40">
          <FormGroupWithInput
            data-amount-input
            label=""
            placeholder="Amount"
            inputName="amount"
            value={inputAmount}
            updateField={updateAmountField}
          />
        </div>
        <div className="d-inline-block width-rem-10">
          <FormGroupWithSelect
            data-currency-select
            label=""
            inputName="currency"
            value={currencyCode}
            updateField={updateCurrencyCode}
            options={currencyCodeOptions}
          />
        </div>
      </FormGroup>
      <FormGroupWithSelect
        data-disbursement-select
        label="Disbursement"
        inputName="bounced_payout[disbursement_id]"
        value={disbursementID}
        updateField={updateDisbursement}
        disabled={disbursements.length === 0}
        options={disbursementOptions}
        tagRequired
      />
    </div>
  );
}
