import React from 'react';
import { core_apimessages_CreditCard } from '@reverbdotcom/commons/src/gql/graphql';
import { CheckoutVerifyCardCVV } from './checkout_verify_card_cvv';
import CreditCardBrandIcon from '@reverbdotcom/commons/src/components/credit_card_brand_icon';

type CreditCard = Pick<core_apimessages_CreditCard,
  | 'needsReverification'
  | 'cardType'
  | 'brand'
  | 'display'
>;

interface Props {
  creditCard: CreditCard;
  trackReverifyEvent?: () => void;
}

export function CreditCardSummary({
  creditCard,
  trackReverifyEvent = null,
}: Props) {
  return (
    <div>
      <div className="d-flex fx-align-center">
        <div className="width-rem-5">
          <CreditCardBrandIcon
            brand={creditCard.brand}
          />
        </div>
        <div className="pl-2">
          <div className="weight-bold">
            {creditCard.display.summary}
          </div>
          <div className="size-80">
            {creditCard.display.expirationDate}
          </div>
        </div>
      </div>
      <div>
        {creditCard.needsReverification &&
          <div className="mt-4">
            <CheckoutVerifyCardCVV
              creditCard={creditCard}
              trackEvent={trackReverifyEvent}
            />
          </div>
        }
      </div>
    </div>
  );
}
