import React from 'react';
import { RCIcon } from '../RCIcon/RCIcon';
import { ChevronRightIcon } from '../../icons/react';
import classNames from 'classnames';

export interface RCAutocompleteItemProps {
  /** Text to be displayed for the item. */
  title?: string;

  /** Item id. Can be any string, but must be unique. */
  id: string;

  /** Optional type that can be referenced in onClick handler. */
  type?: string;

  /** Optional callback to be fired on item click / select. */
  onClick?: () => void;

  /** Optional style variants. */
  variant?: 'default' | 'link' | 'disabled' | 'parent';

  // Optionally pass in your own JSX element. Ignores 'title' and 'variant' props.
  children?: JSX.Element;
}

export const RCAutocompleteItem = React.forwardRef<
  HTMLLIElement, RCAutocompleteItemProps
>(({
  title,
  id,
  variant = 'default',
  children = null,
  ...passthroughProps
}, ref) => {

  const ariaTags = children ? {} : {
    role: 'option',
    'aria-selected': false,
    'aria-disabled': variant === 'disabled',
  };

  return (
    <li
      className={classNames(
        'rc-autocomplete-item',
        { 'rc-autocomplete-item--link': variant === 'link' },
      )}
      ref={ref}
      id={id}
      {...ariaTags}
      {...passthroughProps}
    >
      {children ? (
        children
      ) : (
        <>
          {title}
          {variant === 'parent' &&
            <span className="rc-autocomplete-item__icon">
              <RCIcon svgComponent={ChevronRightIcon} />
            </span>
          }
        </>
      )}

    </li>
  );
});

RCAutocompleteItem.displayName = 'RCAutocompleteItem';
