import React from 'react';
import I18n from 'i18n-js';
import {
  IUserType,
  IRefundableOrderProps,
  IRefundRequestProps,
} from './refund_request';

interface IProps {
  userType: IUserType;
  order: IRefundableOrderProps;
  refundRequest: IRefundRequestProps;
}

const RefundRequestDetails: React.StatelessComponent<IProps> = (props) => {
  if (props.userType === IUserType.BUYER || !props.refundRequest.isPersisted) {
    return null;
  }

  let returnShippingLabelCost;
  if (props.order.returnShippingLabelAmount) {
    returnShippingLabelCost = (
      <div className="form-group">
        <div className="form-group__header">
          <label>
            {I18n.t('discovery.dashboard.refundRequests.returnShippingLabelCost')}
          </label>
        </div>
        <div className="form-group__fields">
          <p className="mb-0">
            {props.order.returnShippingLabelAmount.display}
          </p>
        </div>
      </div>
    );
  }

  let noteToSeller;
  if (props.refundRequest.noteToSeller) {
    noteToSeller = (
      <div className="form-group">
        <div className="form-group__header">
          <label>
            {I18n.t('discovery.dashboard.refundRequests.noteFromBuyer')}
          </label>
        </div>
        <div className="form-group__fields">
          <p className="mb-0">
            {props.refundRequest.noteToSeller}
          </p>
        </div>
      </div>
    );
  }

  return (
    <div className="scaling-mb-4">
      <div className="form-group">
        <div className="form-group__header">
          <label>
            {I18n.t('discovery.dashboard.refundRequests.seller.reason.label')}
          </label>
        </div>
        <div className="form-group__fields">
          <p className="mb-0">
            {I18n.t(`discovery.dashboard.refundRequests.seller.reasons.${props.refundRequest.reason}`)}
          </p>
        </div>
      </div>
      {returnShippingLabelCost}
      {noteToSeller}
    </div>
  );
};

export default RefundRequestDetails;
