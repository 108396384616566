/* eslint-disable no-restricted-imports */
import {
  useQuery as useApolloQuery,
  DocumentNode,
  OperationVariables,
  QueryHookOptions,
  QueryResult,
  TypedDocumentNode,
  ApolloError,
} from '@apollo/client';
/* eslint-enable no-restricted-imports */
import { cloneDeep, omit } from 'lodash';
import { normalizeVariables } from './apollo_client/safeQueryOptions';
import { parseQueryErrors } from './parse_graphql_errors';
import { core_apimessages_Error } from './gql/graphql';

/**
 * A wrapped version of Apollo Client's `QueryResult`.
 */
type RQLQueryResult<TData, TVariables> = Omit<QueryResult<TData, TVariables>, 'error'> & {
  /**
   * The original error from Apollo Client.
   **/
  originalError?: ApolloError;

  /**
   * Parsed errors built from an `ApolloError`. The original error can be accessed as `originalError`.
   */
  errors?: core_apimessages_Error[];
};

/**
 * Reverb's `useQuery` is a wrapper around the `useQuery` function provided by `@apollo/client`,
 * which is the primary way to execute graphql queries.
 *
 * See the [Apollo Client usage guide](https://reverb.atlassian.net/wiki/spaces/ENG/pages/1877377087/Apollo+Client+3+Usage+Guide) for more details and examples.
 *
 * Both functions provide the [same interface](https://www.apollographql.com/docs/react/data/queries#executing-a-query)
 * for executing queries, and Reverb's `useQuery` also sets safe default options across all queries:
 *
 * - Reverb's `useQuery` sets `ssr: false` by default, while Apollo's `useQuery` sets `ssr: true` by default.
 *   See this [doc](https://reverb.atlassian.net/wiki/spaces/SEO/pages/1329233951/Make+SSR+Opt-In+Instead+of+Opt-Out)
 *   for details on why `ssr: false` is the preferred default.
 * - Reverb's `useQuery` normalizes operation variables to prevent issues with mismatched cache data between
 *   server side rendering and client side rendering. See [this PR](https://github.com/reverbdotcom/frontend/pull/9901) for details.
 *
 * Reverb's `useQuery` also has a slightly different return type from Apollo:
 *
 * - An `errors` property is added with parsed messages, to reduce boilerplate error handling code.
 * - Apollo's `error` property is renamed to `originalError`.
 *
 * The generic types are otherwise copied directly from `@apollo/client`.
 */
export function useQuery<
  TData = any,
  TVariables extends OperationVariables = OperationVariables,
>(
  query: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options?: QueryHookOptions<TData, TVariables>,
): RQLQueryResult<TData, TVariables> {
  const safeOptions = cloneDeep(options) || {};

  if (!Object.prototype.hasOwnProperty.call(safeOptions, 'ssr')) {
    safeOptions.ssr = false;
  }

  const shouldNormalizeVariables = safeOptions.ssr && safeOptions.variables;
  if (shouldNormalizeVariables) {
    safeOptions.variables = normalizeVariables<TVariables>(safeOptions.variables);
  }

  const result = useApolloQuery<TData, TVariables>(query, safeOptions);

  return {
    ...omit(result, 'error'),
    errors: result.error && parseQueryErrors(result.error),
    originalError: result.error,
  };
}
