// TODO update this to import from commons
// import { gql } from '@reverbdotcom/commons/src/gql';
// eslint-disable-next-line no-restricted-imports
import { gql } from '@apollo/client';
import { compact, castArray, invert, size, isEmpty, flatten, uniq } from 'lodash';

import {
  reverb_search_ListingsSearchRequest_Aggregation as Aggregation,
  core_apimessages_CollectionHeader_CollectionType,
  CoreMarketplaceHeader,
  reverb_search_CSPSearchRequest_Sort,
  reverb_search_ListingsSearchRequest,
  reverb_search_ListingsSearchRequest_Sort,
  CoreMarketplaceCombinedMarketplaceSearch,
  reverb_search_Autodirects,
  reverb_search_Context,
  CoreMarketplaceCspRow,
} from '@reverbdotcom/commons/src/gql/graphql';
import { LEGACY_OFFERS_LISTING_TYPE } from '../shared/constants';
import { getOffsetFromPage } from '../../pagination_helpers';
import toBool from '../../reverb-js-common/to_boolean';
import { buildMultiClientExperimentGroups } from '@reverbdotcom/commons/src/components/multi_client_experiment_groups';
import { MParticlePageName } from '@reverbdotcom/commons/src/elog/mparticle_tracker';
import { likelihoodToSellExperimentGroup, shouldApplyLikelihoodToSellBoosts } from './experiment_groups';
import { MarketplaceSuggestionsFragment } from '../no_results_alert';
import { conditionFilter } from '../discovery/csp/condition_filter';
import { MarketplacePathContext } from '../shared/url_helpers';

import { updateParams } from '@reverbdotcom/commons/src/update_params';
import { nestedFilterFragment } from '@reverbdotcom/commons/src/components/rql_facets';
import { ListingsCollection } from '@reverbdotcom/commons/src/components/listings_collection';
import { InOtherCartsCardFragment } from '@reverbdotcom/commons/src/components/in_other_carts_card_nudge';
import { WatchBadgeFragment } from '@reverbdotcom/commons/src/components/watch_badge';
import { IUser } from '@reverbdotcom/commons/src/components/user_context_provider';
import { ListingCardFragment } from '@reverbdotcom/commons/src/components/listing_card';
import { ListViewRowCardFragment } from '../list_view_row_card';
import experiments from '@reverbdotcom/commons/src/experiments';
import { isExperimentEnabled } from '@reverbdotcom/commons/src/user_context_helpers';
import { UpdateUrlArgs } from '@reverbdotcom/commons/src/components/with_update_url';
import { ListingCardSignalsFragment } from '@reverbdotcom/commons/src/components/listing_card_signals';
import { Location } from 'history';
import { isCSP } from '@reverbdotcom/commons/src/url_helpers';
import { applyProximityBoost } from '@reverbdotcom/commons/src/seller_location_experiments';

// These are marketplace search params that the CSP search can handle. Please add any new params here
// if CSP searches should be affected by these params. Also be sure to update the mapUrlParamsForCSP
// function to handle this new param.
export interface MarketplaceCSPParams {
  query?: string;
  make?: string[] | string;
  condition?: string[] | string;
  year_min?: string;
  year_max?: string;
  price_min?: string;
  price_max?: string;
  country_of_origin?: string[] | string;
  decades?: string[];
  category?: string;
  trait_values?: string[] | string;
  product_type?: string;
  ships_to?: string;
}

// !!!!!! STOP !!!!!!
// Before you add a new param to this interface, consider whether or not the marketplace CSP row
// should also be filtered by this param. If so, please add the param to the MarketplaceCSPParams
// interface. If the param should only affect listing searches, it can be added to the
// MarketplaceQueryParams interface.
export interface MarketplaceQueryParams extends MarketplaceCSPParams {
  sort?: string;
  on_sale?: boolean;
  holiday_sale?: boolean;
  handmade?: boolean;
  free_shipping?: boolean;
  free_expedited_shipping?: boolean;
  listing_type?: 'offers'; // deprecated
  accepts_offers?: boolean;
  accepts_gift_cards?: boolean;
  preferred_seller?: boolean;
  zero_percent_financing?: boolean;
  item_region?: string;
  shop?: string; // path only?
  curated_set_slug?: string; // path only
  region?: string; // locale path prefix
  page?: string;
  decade?: string; // used?
  featured_id?: string;
  currency?: string;
  show_only_sold?: boolean;
  showsold?: boolean;
  item_city?: string;
  item_state?: string;
  csp_slug?: string;
  combined_shipping?: boolean;
  accepts_affirm?: boolean;
  proximity?: boolean;
  distance?: string;
  postal_code?: string;
  local_pickup?: boolean;
  min_sale_discount_percent?: string;
  exclude_local_pickup_only?: boolean;
  deals_and_steals?: boolean;
}

export interface IPathParams {
  product_type?: string;
  category?: string;
  region?: string;
  brand_slug?: string;
  shop?: string;
  curated_set_slug?: string;
  sale_slug?: string;
  slug?: string; // CSP slug path param (on CSP page)
}

export interface PageSettings {
  collectionSlug?: string;
  collectionType?: core_apimessages_CollectionHeader_CollectionType;
  showSold?: boolean; // show sold listings even without query param
  holidaySale?: boolean;
  useExperimentalRecall?: boolean;
  pageName?: MParticlePageName;
  renderCollectionHeaderDescription?: boolean;
  skipHeader?: boolean;
  hideFollowButtons?: boolean;
  pinTextQuery?: boolean; // ensure search bar always visible above grid, instead of buried within filters
}

export function usingSignalSystemMarketplace() {
  return false;
}

export function onlyQuery(query) {
  return !!query && size(query) === 1 && !isEmpty(query.query);
}

export function updateCategoryAsParams({ location, newParams, autodirects = false }: UpdateUrlArgs) {
  const { category_slugs, ...queryParams } = newParams;

  const slugs = [].concat(category_slugs).filter(s => typeof s !== 'undefined');

  if (typeof category_slugs !== 'undefined') {
    queryParams.product_type = slugs[0];
    queryParams.category = slugs[1];
  }

  const query = updateParams(location.query, queryParams);
  if (autodirects && onlyQuery(query)) {
    // when user manually removes all filters,
    // add `skip_autodirect=true` so search isn't tagged as INITIAL_QUERY,
    // skipping autodirects and preserving user's choice
    // ex. { query: 'fender', make: 'fender' } => { query: 'fender' }
    query.skip_autodirects = 'true';
  } else {
    delete query.skip_autodirects;
  }

  return {
    pathname: location.pathname,
    query,
  };
}

const URL_TO_RQL_PARAM = {
  sort: 'sort_slug',
  make: 'brand_slugs',
  condition: 'condition_slugs',
  zero_percent_financing: 'accepts_payment_plans',
  ships_to: 'shipping_region_codes',
};

const RQL_TO_URL_PARAM = invert(URL_TO_RQL_PARAM);

export function renameRqlParam(paramName: string): string {
  const newParam = RQL_TO_URL_PARAM[paramName] || paramName;

  return newParam;
}

export function includePromotedRows(pathParams: IPathParams, queryParams: MarketplaceQueryParams) {
  return !queryParams.show_only_sold && !pathParams.shop;
}

function getProximityParams(queryParams) {
  const proximity = toBool(queryParams.proximity);

  return {
    proximity,
    distance: queryParams.distance,
    postalCode: queryParams.postal_code,
  };
}

const DOMESTIC_BUMPED_ONLY_COUNTRIES = [
  'GB', // United Kingdom
  'DE', // Germany
  'ES', // Spain
  'FR', // France
  'IT', // Italy
  'CA', // Canada
  'AU', // Australia
];

const ELECTRIC_GUITARS_CATEGORY_SLUG = 'electric-guitars';

function itemRegionForBumpedSearch(user: Partial<IUser>) {
  if (DOMESTIC_BUMPED_ONLY_COUNTRIES.includes(user.countryCode)) {
    return user.countryCode;
  }

  return undefined;
}

const LIMIT = 45;

function listingsLimit(adTilesCount = 0): number {
  return LIMIT - adTilesCount;
}

export function shouldShowTraitFilters(user: Partial<IUser>, queryParams: MarketplaceQueryParams) {
  // Show traits for every product type on all pages for users in this group
  if (isExperimentEnabled(user, experiments.TRAIT_FACETS)) {
    return true;
  }

  // Show Electric Guitars traits on all page types
  return queryParams?.product_type === ELECTRIC_GUITARS_CATEGORY_SLUG;
}

function getBoolParamValue(queryParamValue: string): boolean | undefined {
  if (queryParamValue?.toLowerCase?.() === 'true') return true;
  if (queryParamValue?.toLowerCase?.() === 'false') return false;

  return undefined;
}

interface IMapParamsArgs {
  pathParams?: IPathParams,
  location: Location,
  user: Partial<IUser>,
  pageSettings?: PageSettings,
  adTilesCount?: number;
  listingsLimitOverride?: number;
  queryOverride?: string;
  boostByBumpRateOverride?: boolean;
  additionalQueryParams?: object;
  showTraits?: boolean;
  isOutlet?: boolean;
  showMostWatchedListingsSort?: boolean;
}

export function mapParams({
  pathParams = {},
  location,
  user,
  pageSettings = {},
  adTilesCount = 0,
  listingsLimitOverride = undefined,
  queryOverride = '',
  boostByBumpRateOverride = true,
  additionalQueryParams = {},
  showTraits = false,
  isOutlet = false,
  showMostWatchedListingsSort = false,
}: IMapParamsArgs): reverb_search_ListingsSearchRequest {
  let aggs = [
    Aggregation.CATEGORY_SLUGS,
    Aggregation.BRAND_SLUGS,
    Aggregation.CONDITION_SLUGS,
    Aggregation.DECADES,
    Aggregation.CURATED_SETS,
    Aggregation.COUNTRY_OF_ORIGIN,
  ];

  const queryParams = {
    ...additionalQueryParams,
    ...(location?.query || {}),
  };

  if (showTraits || shouldShowTraitFilters(user, queryParams)) {
    aggs.push(Aggregation.TRAITS);
  }

  if (isExperimentEnabled(user, experiments.FIND_A_DEAL_AGGREGATIONS)) {
    aggs.push(Aggregation.FIND_A_DEAL);
  }

  if (pathParams.brand_slug) {
    aggs = aggs.filter(f => f !== Aggregation.BRAND_SLUGS);
  }
  const brandSlugs = pathParams.brand_slug ? [pathParams.brand_slug] : compact(castArray(queryParams.make));

  const shouldApplyLtsBoosts = shouldApplyLikelihoodToSellBoosts(user);
  const multiClientExperiments = buildMultiClientExperimentGroups(user, location, { shouldApplyLikelihoodToSellBoosts: shouldApplyLtsBoosts });
  const limit = listingsLimitOverride || listingsLimit(adTilesCount);

  return {
    acceptsAffirm: getBoolParamValue(queryParams.accepts_affirm),
    acceptsGiftCards: getBoolParamValue(queryParams.accepts_gift_cards),
    acceptsOffers:
      queryParams.listing_type === LEGACY_OFFERS_LISTING_TYPE ||
      getBoolParamValue(queryParams.accepts_offers),
    acceptsPaymentPlans: getBoolParamValue(queryParams.zero_percent_financing),
    applyProximityBoost: applyProximityBoost(user) && isExperimentEnabled(user, experiments.BROWSE_PAGE_OPTIMIZATION),
    autodirects: reverb_search_Autodirects.IMPROVED_DATA,
    boostByBumpRate: boostByBumpRateOverride && !queryParams.sort,
    boostedItemRegionCode: user.countryCode || '',
    brandSlugs,
    canonicalFinishes: compact(castArray(queryParams.canonicalFinish)), // CSP page filter
    categorySlugs: compact([
      queryParams.product_type,
      queryParams.category,
    ]),
    combinedShipping: getBoolParamValue(queryParams.combined_shipping),
    conditionSlugs: compact(castArray(queryParams.condition)),
    contexts: onlyQuery(location.query) ? [reverb_search_Context.INITIAL_QUERY] : [],
    countryOfOrigin: compact(castArray(queryParams.country_of_origin)),
    cspSlug: queryParams.csp_slug || (isCSP(location) ? pathParams.slug : undefined),
    curatedSetSlugs: compact(castArray(pathParams.curated_set_slug)),
    currency: queryParams.currency,
    dealsAndSteals: getBoolParamValue(queryParams.deals_and_steals),
    decades: queryParams.decades,
    excludeLocalPickupOnly: getBoolParamValue(queryParams.exclude_local_pickup_only),
    fallbackToOr: true,
    freeExpeditedShipping: getBoolParamValue(queryParams.free_expedited_shipping),
    freeShipping: getBoolParamValue(queryParams.free_shipping),
    handmade: getBoolParamValue(queryParams.handmade),
    itemCity: compact(castArray(queryParams.item_city)),
    itemRegion: !pathParams.shop ? queryParams.item_region : undefined,
    itemState: compact(castArray(queryParams.item_state)),
    holidaySale: pageSettings.holidaySale, //
    likelihoodToSellExperimentGroup: likelihoodToSellExperimentGroup(user),
    limit,
    localPickup: getBoolParamValue(queryParams.local_pickup),
    minSaleDiscountPercent: queryParams.min_sale_discount_percent,
    multiClientExperiments: multiClientExperiments,
    offset: getOffsetFromPage(queryParams.page, limit),
    onSale: getBoolParamValue(queryParams.on_sale),
    preferredSeller: getBoolParamValue(queryParams.preferred_seller),
    priceMax: queryParams.price_max,
    priceMin: queryParams.price_min,
    query: queryOverride || queryParams.query,
    saleSlugs: compact(castArray(pathParams.sale_slug)),
    shippingRegionCodes: compact(castArray(queryParams.ships_to)),
    shopSlug: pathParams.shop, //
    showOnlySold: getBoolParamValue(queryParams.show_only_sold),
    showSold: getBoolParamValue(queryParams.showsold) || pageSettings.showSold,
    sortSlug: queryParams.sort,
    terminateEarly: getBoolParamValue(queryParams.show_only_sold) && pathParams.shop === undefined,
    traitValues: compact(castArray(queryParams.trait_values)),
    excludeBrandSlugs: compact(castArray(queryParams.exclude_brand_slugs)),
    excludeCategoryUuids: compact(castArray(queryParams.exclude_category_uuids)),
    useExperimentalRecall: pageSettings.useExperimentalRecall,
    withAggregations: aggs,
    withProximityFilter: getProximityParams(queryParams),
    yearMax: queryParams.year_max,
    yearMin: queryParams.year_min,
    outlet: isOutlet || getBoolParamValue(queryParams.outlet),
    showMostWatchedListingsSort: showMostWatchedListingsSort,
  };
}

export function mapUrlParamsForHeader(
  pathParams: IPathParams,
  queryParams: MarketplaceQueryParams,
  pageSettings: PageSettings,
): CoreMarketplaceHeader.Variables {
  const brandSlugs = pathParams.brand_slug ? [pathParams.brand_slug] : compact(castArray(queryParams.make));
  const { collectionSlug, collectionType } = pageSettings;

  return {
    collectionType,
    collectionSlug,
    skipCollectionHeader: !collectionSlug,
    brandSlugs,
    categorySlugs: compact([
      queryParams.product_type,
      queryParams.category,
    ]),
    query: queryParams.query,
    yearMin: queryParams.year_min,
    yearMax: queryParams.year_max,
    conditionSlugs: compact(castArray(queryParams.condition)),
    shopSlug: pathParams.shop,
    curatedSetSlug: pathParams.curated_set_slug || pathParams.sale_slug,
  };
}

export function mapUrlParamsForCSP(
  pathParams: MarketplacePathContext,
  queryParams: MarketplaceCSPParams,
  user: Partial<IUser>,
): CoreMarketplaceCspRow.Variables {

  return (
    {
      brands: compact(flatten([queryParams.make, pathParams.brand_slug])),
      categories: uniq(compact([
        queryParams.product_type,
        queryParams.category,
      ])),
      condition: conditionFilter(queryParams.condition),
      countryOfOrigin: compact(castArray(queryParams.country_of_origin)),
      currency: user.currency,
      decades: compact(flatten([queryParams.decades])),
      fullTextQuery: queryParams.query,
      listingsThatShipTo: queryParams.ships_to,
      priceMin: queryParams.price_min,
      priceMax: queryParams.price_max,
      sort: reverb_search_CSPSearchRequest_Sort.TRENDING_DESC,
      traitValues: compact(castArray(queryParams.trait_values)),
      yearMax: parseInt(queryParams.year_max) || undefined,
      yearMin: parseInt(queryParams.year_min) || undefined,
    }
  );
}

const DEFAULT_BUMP_LIMIT = 15;

export function getCombinedMarketplaceInputs(
  mapped: reverb_search_ListingsSearchRequest,
  user: IUser,
  queryParams: MarketplaceQueryParams,
  bumpLimit?: number,
): Partial<CoreMarketplaceCombinedMarketplaceSearch.Variables> {

  // Add variables here that will be shared between all the searches (listings, bumped, aggs)
  const common = {
    query: mapped.query,
    currency: mapped.currency,
    priceMax: mapped.priceMax,
    priceMin: mapped.priceMin,
    decades: mapped.decades,
    yearMax: mapped.yearMax,
    yearMin: mapped.yearMin,
    sortSlug: mapped.sortSlug,
    categorySlugs: mapped.categorySlugs,
    brandSlugs: mapped.brandSlugs,
    conditionSlugs: mapped.conditionSlugs,
    onSale: mapped.onSale,
    handmade: mapped.handmade,
    freeShipping: mapped.freeShipping,
    freeExpeditedShipping: mapped.freeExpeditedShipping,
    acceptsOffers: mapped.acceptsOffers,
    acceptsGiftCards: mapped.acceptsGiftCards,
    preferredSeller: mapped.preferredSeller,
    acceptsPaymentPlans: mapped.acceptsPaymentPlans,
    shippingRegionCodes: mapped.shippingRegionCodes,
    showOnlySold: mapped.showOnlySold,
    showSold: mapped.showSold,
    itemState: mapped.itemState,
    itemCity: mapped.itemCity,
    shopSlug: mapped.shopSlug,
    curatedSetSlugs: mapped.curatedSetSlugs,
    saleSlugs: mapped.saleSlugs,
    cspSlug: mapped.cspSlug,
    combinedShipping: mapped.combinedShipping,
    acceptsAffirm: mapped.acceptsAffirm,
    withProximityFilter: mapped.withProximityFilter,
    localPickup: mapped.localPickup,
    minSaleDiscountPercent: mapped.minSaleDiscountPercent,
    boostedItemRegionCode: mapped.boostedItemRegionCode,
    useExperimentalRecall: mapped.useExperimentalRecall,
    traitValues: mapped.traitValues,
    excludeCategoryUuids: mapped.excludeCategoryUuids,
    excludeBrandSlugs: mapped.excludeBrandSlugs,
    likelihoodToSellExperimentGroup: mapped.likelihoodToSellExperimentGroup,
    countryOfOrigin: mapped.countryOfOrigin,
    contexts: mapped.contexts,
    autodirects: mapped.autodirects,
    excludeLocalPickupOnly: mapped.excludeLocalPickupOnly,
    dealsAndSteals: mapped.dealsAndSteals,
    multiClientExperiments: mapped.multiClientExperiments,
    canonicalFinishes: mapped.canonicalFinishes,
    outlet: mapped.outlet,
  };

  const inputListings = {
    ...common,
    limit: mapped.limit,
    itemRegion: mapped.itemRegion,
    offset: mapped.offset,
    terminateEarly: mapped.terminateEarly,
    holidaySale: mapped.holidaySale,
    fallbackToOr: mapped.fallbackToOr,
    sort: reverb_search_ListingsSearchRequest_Sort.NONE,
  };

  const bumpLimitValue = Number.isInteger(bumpLimit) ? bumpLimit : DEFAULT_BUMP_LIMIT;
  const bumpedItemRegion = mapped.itemRegion || itemRegionForBumpedSearch(user);
  const inputBumped = {
    ...common,
    limit: bumpLimitValue,
    itemRegion: bumpedItemRegion,
    offset: getOffsetFromPage(queryParams.page, bumpLimitValue),
    boostByBumpRate: mapped.boostByBumpRate,
    bumpedOnly: true,
    sort: reverb_search_ListingsSearchRequest_Sort.NONE,
  };

  const inputAggs = {
    ...common,
    limit: 0,
    itemRegion: mapped.itemRegion,
    withAggregations: mapped.withAggregations,
    terminateEarly: mapped.terminateEarly,
    holidaySale: mapped.holidaySale,
    fallbackToOr: mapped.fallbackToOr,
  };

  return {
    inputListings,
    inputBumped,
    inputAggs,
  };
}

export const combinedMarketplaceSearchQuery = gql`
  query Core_Marketplace_CombinedMarketplaceSearch(
    $inputListings: Input_reverb_search_ListingsSearchRequest
    $inputBumped: Input_reverb_search_ListingsSearchRequest
    $inputAggs: Input_reverb_search_ListingsSearchRequest
    $shouldntLoadBumps: Boolean!
    $shouldntLoadSuggestions: Boolean!
    $usingListView: Boolean!
    $signalGroups: [reverb_signals_Signal_Group]
    $useSignalSystem: Boolean!
  ) {
    bumpedSearch: listingsSearch(input: $inputBumped) @skip(if: $shouldntLoadBumps) {
      listings {
        _id
        ...ListingCardFields
        ...WatchBadgeData
        ...BumpKey
        ...ShopFields
        ...ListViewListings @include(if: $usingListView)
        signals (input: { groups: $signalGroups }) @include(if: $useSignalSystem) {
          ...ListingCardSignalsData
        }
      }
    }
    aggsSearch: listingsSearch(input: $inputAggs) {
      filters {
        ...NestedFilter
      }
    }
    listingsSearch(input: $inputListings) {
      total
      offset
      limit
      suggestedQueries
      eligibleAutodirects
      listings {
        _id
        esScore
        ...ListingCardFields
        ...WatchBadgeData
        ...InOtherCartsCardData @skip(if: $useSignalSystem)
        ...ShopFields
        ...ListViewListings @include(if: $usingListView)
        signals (input: { groups: $signalGroups }) @include(if: $useSignalSystem) {
          ...ListingCardSignalsData
        }
      }
      fallbackListings {
        _id
        ...ListingCardFields
        ...InOtherCartsCardData @skip(if: $useSignalSystem)
        ...WatchBadgeData
        signals (input: { groups: $signalGroups }) @include(if: $useSignalSystem) {
          ...ListingCardSignalsData
        }
      }
      suggestions @skip(if: $shouldntLoadSuggestions) {
        ...MarketplaceSuggestions
      }
    }
  }
  ${ListingCardFragment}
  ${WatchBadgeFragment}
  ${ListingsCollection.fragments.bumpKey}
  ${ListingsCollection.fragments.shopFields}
  ${InOtherCartsCardFragment}
  ${nestedFilterFragment}
  ${MarketplaceSuggestionsFragment}
  ${ListViewRowCardFragment}
  ${ListingCardSignalsFragment}
`;

export const listingsCountQuery = gql`
  query Core_Marketplace_MarketplaceListingsCount(
    $inputListings: Input_reverb_search_ListingsSearchRequest
  ) {
    listingsSearch(input: $inputListings) {
      total
      __typename
    }
  }
`;
