import React from 'react';
import I18n from 'i18n-js';
import { debounce } from 'lodash';

import {
  core_apimessages_NegotiationParty as NegotiationParty,
  core_apimessages_NegotiationAction as NegotiationAction,
  Listing,
  Negotiation,
  reverb_pricing_PriceRecommendation as PriceRecommendation,
} from '@reverbdotcom/commons/src/gql/graphql';
import { mapPriceRecommendationToMoney } from '@reverbdotcom/commons/src/priceRecommendationMoney';
import FormGroup from '@reverbdotcom/commons/src/components/form_group';
import { UserInputOffer } from '@reverbdotcom/commons/src/offers/negotiation_types';
import OfferAmountInputs from '@reverbdotcom/commons/src/offers/offer_amount_inputs';
import EstimatedValueAlert from '@reverbdotcom/commons/src/offers/estimated_value_alert';
import { ControlledBodyInput } from '../messages/body_input';
import { RCAlertBox, RCCheckbox, RCTag } from '@reverbdotcom/cadence/components';
import { MoneyInput } from '@reverbdotcom/commons/src/components/money_input';
import { parseAmount } from '@reverbdotcom/commons/src/money';
import { MParticleEventName, trackEvent } from '@reverbdotcom/commons/src/elog/mparticle_tracker';
import { offerIsBelowLowballThreshold } from '@reverbdotcom/commons/src/offers/offer_create_form_fields';

export interface Props {
  error?: string;
  listing: Listing;
  negotiation: Negotiation;
  currency: string;
  onOfferChange: (any) => void;
  offer: UserInputOffer;
  existingOffer?: UserInputOffer;
  party: NegotiationParty;
  action: NegotiationAction;
  priceRecommendation?: PriceRecommendation;
  preventLowballOffers?: boolean;
  conditionalAcceptanceSelected?: boolean;
}

function CounterFields({ offer, listing, party, currency, negotiation, onOfferChange }) {
  const taxHint = negotiation.taxIncluded ? ` (${negotiation.taxIncludedHint})` : '';
  const shippingPriceLabel = `${I18n.t('discovery.offers.label.adjustCounterOfferShippingPrice')}${taxHint}`;
  const isSeller = party === NegotiationParty.SELLER;

  return (
    <>
      <OfferAmountInputs
        offer={offer}
        listing={listing}
        party={party}
        action={NegotiationAction.COUNTER}
        currency={currency}
        taxable={negotiation}
        onOfferChange={onOfferChange}
      />

      {isSeller && !listing.shipping.localPickupOnly && (
        <>
          <div className="offers-form__shipping-price-row">
            <MoneyInput
              label={shippingPriceLabel}
              id="offer-shipping-price"
              name="offer-shipping-price"
              currencyCode={currency}
              value={offer.shippingPrice}
              onChange={(change) => {
                onOfferChange({ type: 'setShippingPrice', value: change.target.value });
              }}
              tag="optional"
              helpText={negotiation.sellerShippingWarning}
            />
          </div>
        </>
      )}
    </>
  );
}

export function UpdateOfferFormFields({
  listing,
  negotiation,
  currency,
  onOfferChange,
  offer,
  action,
  party,
  error = '',
  priceRecommendation = undefined,
  existingOffer = undefined,
  preventLowballOffers = false,
  conditionalAcceptanceSelected = false,
}: Props) {
  const [photos, setPhotos] = React.useState([]);
  const [showMessage, setShowMessage] = React.useState(false);
  const [debouncedOfferPrice, setDebouncedOfferPrice] = React.useState('');

  const debounceOfferPrice = React.useCallback(debounce((newOfferPrice) => {
    setDebouncedOfferPrice(newOfferPrice);
  }, 200), []);

  React.useEffect(() => {
    debounceOfferPrice(offer.price);
  }, [offer.price, debounceOfferPrice]);

  const parsedOfferPrice = offer.price && parseAmount(offer.price, currency);
  const parsedExistingOfferPrice = existingOffer.price && parseAmount(existingOffer.price, currency);
  const parsedDebouncedOfferPrice = debouncedOfferPrice && parseAmount(debouncedOfferPrice, currency);
  const isSeller = party === NegotiationParty.SELLER;
  const isCounteringOffer = action === NegotiationAction.COUNTER;
  const isRejectingOffer = action === NegotiationAction.REJECT;
  const showEstimatedValueAlert = priceRecommendation && (isCounteringOffer || isRejectingOffer);
  const belowLowballThreshold = preventLowballOffers &&
    offerIsBelowLowballThreshold(listing.offerBotRule?.autoRejectPercentage, listing.price, parsedDebouncedOfferPrice);

  React.useEffect(() => {
    // If there's no price guide data available or we're not in an offer context that would show it (i.e. when
    // accepting), then we'll only emit a single InteractedWithOfferFlow event on render, since there won't be any
    // negotiation guidance. If there is price guide data available we don't do the track event here, since the
    // EstimatedValueAlert component will handle it.
    let negotiationGuidance;
    if (priceRecommendation && action === NegotiationAction.ACCEPT) {
      // if we're accepting an offer, price guide data isn't shown even if it's available, so make this field blank
      negotiationGuidance = '';
    } else {
      negotiationGuidance = 'No Price Guide';
    }

    if (!showEstimatedValueAlert) {
      trackEvent({
        eventName: MParticleEventName.InteractedWithOfferFlow,
        role: party.toLowerCase(),
        negotiationAction: action.toLowerCase(),
        negotiationGuidance: negotiationGuidance,
        offerStatus: negotiation.state.toLowerCase(),
      });
    }
  }, []);

  let messageLabel;
  if (isCounteringOffer) {
    messageLabel = I18n.t('discovery.offers.messageLabel');
  } else {
    if (isSeller) {
      messageLabel = I18n.t('discovery.offers.messageToBuyerCheckboxLabel');
    } else {
      messageLabel = I18n.t('discovery.offers.messageToSellerCheckboxLabel');
    }
  }

  return (
    <div className="offers-form__body">
      {error && (
        <p className="offers-form__error">{error}</p>
      )}

      <div className="offers-form__fields">
        {showEstimatedValueAlert && (
          <EstimatedValueAlert
            // initialize with existing offer price, then switch to the one that they put in
            offerPrice={parsedDebouncedOfferPrice || parsedExistingOfferPrice}
            recommendedLowPrice={mapPriceRecommendationToMoney(priceRecommendation.priceLow)}
            recommendedHighPrice={mapPriceRecommendationToMoney(priceRecommendation.priceHigh)}
            party={party}
            negotiationAction={action}
            negotiationState={negotiation.state}
            belowLowballThreshold={belowLowballThreshold}
          />
        )}

        {isCounteringOffer && !isSeller && !showEstimatedValueAlert && belowLowballThreshold && (
          <RCAlertBox
            type="error"
          >
            <p>
              {I18n.t('discovery.offers.belowLowballThreshold')}
            </p>
          </RCAlertBox>
        )}

        {isCounteringOffer && !conditionalAcceptanceSelected && (
          <CounterFields
            offer={offer}
            listing={listing}
            party={party}
            currency={currency}
            negotiation={negotiation}
            onOfferChange={onOfferChange}
          />
        )}

        {!isSeller && parsedOfferPrice && currency != listing.shop.currency && (
          <section className="offers-form__currency-difference-alert">
            <p>
              {I18n.t('discovery.offers.currencyMayVary')}
            </p>
          </section>
        )}
      </div>

      {isCounteringOffer && (
        <div className="d-flex fx-align-start mtb-4">
          <RCCheckbox
            id="show-message"
            name="show-message"
            checked={showMessage}
            label={isSeller ? I18n.t('discovery.offers.messageToBuyerCheckboxLabel') : I18n.t('discovery.offers.messageToSellerCheckboxLabel')}
            onChange={() => { setShowMessage(!showMessage); }}
          />
          <div className="ml-1">
            <RCTag>
              {I18n.t('discovery.offers.optional')}
            </RCTag>
          </div>
        </div>
      )}

      {(showMessage || !isCounteringOffer) && (
        <FormGroup
          label={messageLabel}
          tagOptional={!isCounteringOffer}
        >
          <ControlledBodyInput
            message={offer.message}
            onChange={(message) => {
              onOfferChange({ type: 'setMessage', 'value': message });
            }}
            photos={photos}
            onPhotosChange={setPhotos}
            inputNamespace=""
            bodyInputName="message"
            productId={listing.id}
            isSeller={isSeller}
            allowPhotos={false}
            isRequired={false}
          />
        </FormGroup>
      )}
    </div>
  );
}

export default UpdateOfferFormFields;
