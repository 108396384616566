import PropTypes from 'prop-types';
import React from 'react';
import I18n from 'i18n-js';

const INSTITUTION_NUMBER_LENGTH = 3;
const TRANSIT_NUMBER_LENGTH = 5;

class CanadianRoutingNumberFields extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      transitNumber: '',
      institutionNumber: '',
    };
  }

  setTransitNumber(event) {
    const transitNum = event.target.value;
    if (this.isValidTransitNumber(transitNum)) {
      this.setState({ transitNumber: transitNum }, this.verifyBankNumbers);
    }
  }

  setInstitutionNumber(event) {
    const institutionNum = event.target.value;
    if (this.isValidInstitutionNumber(institutionNum)) {
      this.setState({ institutionNumber: institutionNum }, this.verifyBankNumbers);
    }
  }

  isValidTransitNumber(numberString) {
    return (numberString.length === TRANSIT_NUMBER_LENGTH);
  }

  isValidInstitutionNumber(numberString) {
    return (numberString.length === INSTITUTION_NUMBER_LENGTH);
  }

  render() {
    return (
      <div className="g-container">
        <div className="g-col-6 g-col-mobile-12">
          <label
            className="string required control-label"
            dangerouslySetInnerHTML={{
              __html: I18n.t('js.dashboard.selling.shopPolicies.directCheckoutProfile.' +
                             'bankAccountFields.canada.transitNumber', {
                hint_open: '<span class="hint">',
                hint_close: '</span>',
              }),
            }}
          />
          <input
            className="string required wide user-success"
            maxLength={TRANSIT_NUMBER_LENGTH}
            type="text"
            name={this.props.nameForField('transit_number')}
            onBlur={event => this.setTransitNumber(event)}
          />
        </div>
        <div className="g-col-6 g-col-mobile-12">
          <label
            className="string required control-label"
            dangerouslySetInnerHTML={{
              __html: I18n.t('js.dashboard.selling.shopPolicies.directCheckoutProfile.' +
                             'bankAccountFields.canada.institutionNumber', {
                hint_open: '<span class="hint">',
                hint_close: '</span>',
              }),
            }}
          />
          <input
            className="string required wide user-success"
            maxLength={INSTITUTION_NUMBER_LENGTH}
            type="text"
            name={this.props.nameForField('institution_number')}
            onBlur={event => this.setInstitutionNumber(event)}
          />
        </div>
      </div>
    );
  }
}

CanadianRoutingNumberFields.propTypes = {
  nameForField: PropTypes.func.isRequired,
};

export default CanadianRoutingNumberFields;
