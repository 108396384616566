import I18n from 'i18n-js';

export function isUSA(countryCode: string): boolean {
  return (countryCode === 'US' || countryCode === 'PR');
}

export const EUROPEAN_UNION_COUNTRY_CODES = [
  'AT',
  'BE',
  'BG',
  'CY',
  'CZ',
  'DE',
  'DK',
  'EE',
  'ES',
  'FI',
  'FR',
  'GR',
  'HR',
  'HU',
  'IE',
  'IT',
  'LT',
  'LU',
  'LV',
  'MT',
  'NL',
  'PL',
  'PT',
  'RO',
  'SE',
  'SI',
  'SK',
];

export function isEU(countryCode: string) {
  return EUROPEAN_UNION_COUNTRY_CODES.includes(countryCode);
}

const EUROPEAN_ECONOMIC_AREA_COUNTRY_CODES = EUROPEAN_UNION_COUNTRY_CODES.concat([
  'NO',
  'LI',
  'IS',
]);

export function isEEA(countryCode: string) {
  return EUROPEAN_ECONOMIC_AREA_COUNTRY_CODES.includes(countryCode);
}

const US_SUBREGION_CODES = [
  'US_CON',
  'US_AK',
  'US_HI',
  'US_PR',
  'US_OTHER',
];

export function shippingRegionName(regionCode: string): string {
  const code = regionCode.toUpperCase();
  const name = US_SUBREGION_CODES.includes(code) ? `subRegions.${code}` : `countries.${code}`;

  return I18n.t(name);
}
