import React from 'react';
import I18n from 'i18n-js';

import { RCEmailInput, RCToast } from '@reverbdotcom/cadence/components';
import { ModalDialog } from '@reverbdotcom/commons';
import { gql } from '@reverbdotcom/commons/src/gql';
import { useMutation } from '@reverbdotcom/commons/src/useMutation';
import {
  Core_SecondaryUsers_CreateUserShopPermissionMutation,
  Core_SecondaryUsers_CreateUserShopPermissionMutationVariables,
} from '@reverbdotcom/commons/src/gql/graphql';
import EmailValidationHelper from '@reverbdotcom/commons/src/components/helpers/email_validation_helper';

import ShopSettingsUserManagementPermissionLevelSelect from './shop_settings_user_management_permission_level_select';
import { PermissionFormState } from './shop_settings_user_management';

interface Props {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => any;
  formState: PermissionFormState;
  dispatch: (obj: any) => void;
}

const createUserShopPermissionMutation = gql(`
  mutation Core_SecondaryUsers_CreateUserShopPermission(
    $input: Input_core_apimessages_CreateUserShopPermissionRequest
  ) {
    createUserShopPermission(input: $input) {
      userShopPermission {
        id
        email
        permissionLevel
      }
    }
  }
`);

export default function ShopSettingsUserManagementCreateModal({ isOpen, setIsOpen, formState, dispatch }: Props) {
  const [toastIsOpen, setToastIsOpen] = React.useState(false);
  const [toastText, setToastText] = React.useState('');

  const [createUserShopPermission, { loading, errors, reset }] = useMutation<Core_SecondaryUsers_CreateUserShopPermissionMutation, Core_SecondaryUsers_CreateUserShopPermissionMutationVariables>(
    createUserShopPermissionMutation,
  );

  return (
    <>
      <ModalDialog
        headerTitle={I18n.t('discovery.shopSettings.userManagement.userAccess.create.modalTitle')}
        isOpen={isOpen}
        onRequestClose={() => {
          reset();
          setIsOpen(false);
        }}
        onSubmit={async () => {
          const result = await createUserShopPermission({
            variables: {
              input: {
                email: formState.email,
                permissionLevel: formState.permissionLevel,
              },
            },
            refetchQueries: ['Core_SecondaryUsers_MySecondaryUsers'],
            awaitRefetchQueries: true,
          });

          if (result.data?.createUserShopPermission) {
            reset();
            setIsOpen(false);
            setToastText(I18n.t('discovery.shopSettings.userManagement.userAccess.create.success', { email: formState.email }));
            setToastIsOpen(true);
          }
        }}
        isSaving={loading}
        isDisabled={!EmailValidationHelper.isEmailValid(formState.email)}
      >
        <p>{I18n.t('discovery.shopSettings.userManagement.userAccess.create.modalDescription')}</p>

        <RCEmailInput
          label={I18n.t('discovery.shopSettings.userManagement.userAccess.form.email')}
          value={formState.email}
          onChange={(e) => {
            dispatch({ type: 'setEmail', value: e.target.value });
          }}
          errorText={errors.map((error) => error.message).join(' ')}
        />

        <ShopSettingsUserManagementPermissionLevelSelect
          formState={formState}
          dispatch={dispatch}
        />
      </ModalDialog>

      <RCToast
        isOpen={toastIsOpen}
        onClose={() => { setToastIsOpen(false); }}
        text={toastText}
        theme="success"
      />
    </>
  );
}
