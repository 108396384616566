import React from 'react';
import I18n from 'i18n-js';
import Modal from '@reverbdotcom/commons/src/components/modal';
import { SanitizedRender } from '@reverbdotcom/commons';
import { RCIcon } from '@reverbdotcom/cadence/components';
import KlarnaPaymentCard from '@reverbdotcom/cadence/images/payment-card/klarna-payment-card.svg?component';

interface AdditionalStep {
  key: string;
  value: string;
}

interface Props {
  description: {
    title: string;
    terms?: string;
  }
  additionalSteps: AdditionalStep[];
}

export function KlarnaLearnMoreButton({ description, additionalSteps }: Props) {
  const [openModal, setModalOpen] = React.useState(false);

  function toggleModal(e: any) {
    e.preventDefault();
    e.stopPropagation();

    setModalOpen(value => !value);
  }

  function closeModal() {
    setModalOpen(false);
  }

  return (
    <>
      <button
        onClick={toggleModal}
        type="button"
        className="text-link button-as-link"
      >
        {translate('learnMore')}
      </button>
      <Modal
        isOpen={openModal}
        onRequestClose={closeModal}
        shouldCloseOnOverlayClick
        paddedBody
      >
        <h4 className="weight-bold size-130 mb-4 mobile-mt-4">
          {description.title}
        </h4>
        <ol className="cms-ol">
          <li key="addItemToCart">{translate('steps.addItemToCart')}</li>
          <li key="chooseKlarna" className="lh-100 d-flex fx-align-center">
            <span>{translate('steps.chooseKlarna')}</span>
            <span className="ml-space size-200">
              <RCIcon
                svgComponent={KlarnaPaymentCard}
                title={translate('klarnaLogoTitle')}
                inline
              />
            </span>
          </li>
          {additionalSteps.map(step => (
            <li key={step.key}>{step.value}</li>
          ))}
        </ol>
        {description.terms &&
          <div className="mt-2 pt-4 bdt-1 bd-color-primary size-80">
            <SanitizedRender html={description.terms} />
          </div>
        }
      </Modal>
    </>
  );
}

function translate(key: string, args = {}) {
  return I18n.t(`discovery.klarnaCallout.learnMoreButton.${key}`, args);
}
