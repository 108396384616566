import React from 'react';
import I18n from 'i18n-js';
import classNames from 'classnames';

import { Listing } from '@reverbdotcom/commons/src/gql/graphql';
import CoreLink from '@reverbdotcom/commons/src/components/core_link';
import { listingOrdersPath, listingOffersPath } from '@reverbdotcom/commons/src/url_helpers';

const DRAFT = 'draft';

interface IExternalProps {
  listing: Listing;
  asRow?: boolean;
}

export default function SellerListingStats({
  listing,
  asRow,
}: IExternalProps) {
  if (listing.state == DRAFT) return null;

  const ordersCount = listing.orderStats?.totalOrders ?? 0;
  const offersCount = listing.counts.offers;
  const inBuyerCartsCount = listing.counts.inBuyerCarts;

  const statsLayout = {
    'fx-dir-col': !asRow,
    'fx-justify-end': asRow,
    'gap-4': asRow,
    'pr-4': asRow,
  };

  return (
    <ul
      className={classNames('d-flex fx-align-end tablet-fx-align-start weight-semibold', {
        ...statsLayout,
      })}
      style={{ fontSize: 'var(--rc-font-size-350)' }}
    >
      <li>
        {I18n.t('discovery.listingsManagement.sellerListingCard.views', {
          count: listing.counts.views,
        })}
      </li>

      <li>
        {I18n.t('discovery.listingsManagement.sellerListingCard.watchers', {
          count: listing.counts.watchers,
        })}
      </li>

      {offersCount > 0 && (
        <li>
          <CoreLink
            to={listingOffersPath(listing)}
            className="td-underline color-primary"
          >
            {I18n.t('discovery.listingsManagement.sellerListingCard.offers', {
              count: offersCount,
            })}
          </CoreLink>
        </li>
      )}

      {inBuyerCartsCount > 0 && (
        <li>
          {I18n.t('discovery.listingsManagement.sellerListingCard.inBuyerCarts', {
            count: listing.counts.inBuyerCarts,
          })}
        </li>
      )}

      {ordersCount > 0 && (
        <li>
          <CoreLink
            to={listingOrdersPath(listing)}
            className="td-underline color-primary"
          >
            {I18n.t('discovery.listingsManagement.sellerListingCard.orders', {
              count: ordersCount,
            })}
          </CoreLink>
        </li>
      )}

      {!!listing.bumpRate?.rate && (
        <li>
          {I18n.t('discovery.listingsManagement.sellerListingCard.bumped', {
            percent: listing.bumpRate.rate,
          })}
        </li>
      )}
    </ul>
  );
}
