import { BaseMutationOptions } from '@apollo/client/react/types/types';
import React, { useCallback } from 'react';
import { core_apimessages_Error as Error } from './gql/graphql';
import { parseMutationErrors } from './parse_graphql_errors';
import { ExecutionResult } from 'graphql';

export interface MutationResult<MutationData> {
  data: MutationData;
  errors: Error[];
  loading: boolean;
}

export type MutationFunction<MutationData, Variables> =
  (options?: BaseMutationOptions<MutationData, Variables>) => Promise<ExecutionResult<MutationData>>;

type MutationTuple<MutationData, Variables> = [
  MutationFunction<MutationData, Variables>,
  MutationResult<MutationData>,
];

/**
 * @deprecated Use `import { useMutation } from '@reverbdotcom/commons/src/useMutation';` instead.
 */
export function useHOCMutation<MutationData, Variables>(
  mutation: MutationFunction<MutationData, Variables>,
): MutationTuple<MutationData, Variables> {
  const [result, setResult] = React.useState<MutationResult<MutationData>>({
    data: null,
    errors: [],
    loading: false,
  });

  const execute = useCallback(async (variables: BaseMutationOptions<MutationData, Variables>) => {
    setResult({
      data: null,
      errors: [],
      loading: true,
    });

    try {
      const fetchResult = await mutation(variables);
      if (fetchResult) {
        const successResult = {
          data: fetchResult.data,
          errors: [],
          loading: false,
        };
        setResult(successResult);

        return successResult;
      }

      const nonFatalResult = {
        data: null,
        errors: [],
        loading: false,
      };
      setResult(nonFatalResult);

      return nonFatalResult;
    } catch (e) {
      const errors = parseMutationErrors(e);
      const errorResult = {
        data: null,
        errors,
        loading: false,
      };
      setResult(errorResult);

      return errorResult;
    }
  }, [mutation]);

  return [execute, result];
}
