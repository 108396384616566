import React from 'react';
import I18n from 'i18n-js';

import { RCButton, RCIcon } from '@reverbdotcom/cadence/components';
import { ChevronRightIcon, ChevronLeftIcon } from '@reverbdotcom/cadence/icons/react';

import TransactionTable, {
  IPriceRecord,
} from '../../../../price_guide_tool/csp_module/shared/transaction_table';

export const MAX_PAGES = 10;
interface IExternalProps {
  currentPage: number;
  onPageChange: (pageChange: number) => any;
  priceRecords: IPriceRecord[];
  totalPages: number;
  dateHeader?: string;
  priceHeader?: string;
}

export default function PriceRecordsTable({
  priceRecords,
  currentPage,
  onPageChange,
  totalPages,
  dateHeader,
  priceHeader,
}: IExternalProps) {
  const isPreviousButtonDisabled = currentPage === 0;
  const isNextButtonDisabled = (currentPage + 1) >= Math.min(totalPages, MAX_PAGES);

  return (
    <>
      <div className="mt-4">
        <TransactionTable
          priceRecords={priceRecords}
          dateHeader={dateHeader}
          priceHeader={priceHeader}
        />
      </div>

      {totalPages > 1 && (
        <nav
          aria-label={I18n.t('discovery.sellForm.pricingSection.pricingTables.nav')}
          className="d-flex fx-dir-row fx-justify-end mt-2"
        >
          <RCButton
            size="small"
            onClick={onPageChange(-1)}
            title={I18n.t('discovery.sellForm.pricingSection.pricingTables.previous')}
            disabled={isPreviousButtonDisabled}
          >
            <RCIcon
              svgComponent={ChevronLeftIcon}
              inline
            />
          </RCButton>

          <div className="ml-1">
            <RCButton
              size="small"
              onClick={onPageChange(1)}
              title={I18n.t('discovery.sellForm.pricingSection.pricingTables.next')}
              disabled={isNextButtonDisabled}
            >
              <RCIcon
                svgComponent={ChevronRightIcon}
                inline
              />
            </RCButton>
          </div>
        </nav>
      )}
    </>
  );
}
