import React from 'react';
import I18n from 'i18n-js';
import { MParticleEventName } from '../elog/mparticle_types';
import { trackEvent } from '../elog/mparticle_tracker';
import { getItem } from '../storage_wrapper';
import { RCSegmentedControl, RCIcon } from '@reverbdotcom/cadence/components';
import { ListViewIcon, GridViewIcon } from '@reverbdotcom/cadence/icons/react';

interface IProps {
  setListViewState?: (val: boolean) => void;
  loading?: boolean;
}
export const LIST_VIEW_STATE = 'LIST_VIEW_STATE';

export default function ListViewToggle({
  setListViewState,
  loading = false,
}: IProps) {
  const listViewState = getItem('LIST_VIEW_STATE') || 'false';

  const onClick = (enabled) => {
    if (listViewState === enabled.toString()) { return; }
    setListViewState(enabled);

    const clickTrackingData = {
      componentName: 'ListViewToggle',
      eventName: MParticleEventName.ClickedListViewToggle,
      usingListView: enabled,
    };

    trackEvent(clickTrackingData);
  };

  if (loading) {
    return <RCSegmentedControl.Skeleton/>;
  }

  return (
    <RCSegmentedControl
      aria-label={I18n.t('commons.marketplaceViewStates.ariaLabel')}
      loading={loading}
      size="small"
    >
      <RCSegmentedControl.Button
        selected={listViewState === 'false'}
        onClick={() => onClick(false)}
        aria-label={I18n.t('commons.marketplaceViewStates.grid')}
        iconOnly
      >
        <RCIcon svgComponent={GridViewIcon} />
      </RCSegmentedControl.Button>
      <RCSegmentedControl.Button
        selected={listViewState === 'true'}
        onClick={() => onClick(true)}
        aria-label={I18n.t('commons.marketplaceViewStates.list')}
        iconOnly
      >
        <RCIcon svgComponent={ListViewIcon} />
      </RCSegmentedControl.Button>
    </RCSegmentedControl>
  );
}
