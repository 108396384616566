/* eslint-disable */

import qs from 'qs';

export var API_KEY = 'AIzaSyCmGXpw0KmMcQg1mf0pDjFvcMipguYPntI';
var NAMESPACE = 'youtube-api';
var CHANNEL_ID = 'UCHP-KQAJgXu4Wo2Xq4-eNow';
var YOUTUBE_API_URL = 'https://www.googleapis.com/youtube/v3/';
var VIDEO_PLAYER_ID = 'ytplayer';
var PLAY_CLASS = 'playing';
var TRUNCATE_TITLE_GROW = 80;
var TRUNCATE_DESCRIPTION_GROW = 535;

var _title;
var _description;
var _video;
var $module;
var $player;
var $header;
var $videoTitle;
var $videoDescription;
var $lists;
var $card;
var $playlists;
var $searchResultsEmptyMessage;

var params = {
  apiKey: API_KEY,
  channelId: CHANNEL_ID,
  autoplay: {
    yes: 1,
    no: 0,
  },
  maxResults: {
    default: 50,
    min: 8,
    max: 12,
  },
};

var _playlistCache = {};

function buildURL(path) {
  return YOUTUBE_API_URL + path;
}

function buildPlaylist(data, pid) {
  setupPlaylistTitle(pid);
  buildList(data, pid);
}

function buildHubList(data, pid) {
  buildList(data, pid);
}

function buildList(data, pid) {
  data.items.forEach(function (item) {
    buildItem(item, pid);
  });

  setupLoadMore(data, pid, loadMore);
}

function buildToList(data, pid) {
  data.items.forEach(function (item) {
    buildItem(item, pid);
  });

  setupLoadMore(data, pid, loadMore);
}

function buildSearchList(data, pid) {
  data.items.forEach(function (item) {
    buildItem(item, pid);
  });

  setupLoadMore(data, pid, loadMoreSearchResults);
}

function buildItem(item, pid) {
  var $list = $('[data-playlist-id=' + pid + '] [data-' + NAMESPACE + '=list]'),
    $clone = setupCard(item),
    $li = $('<li />').addClass('video-item').append($clone),
    videoId = (item.snippet.resourceId) ? item.snippet.resourceId.videoId : item.id.videoId;

  $li.on('click.youtubeAPI.playVideo', function () {
    $('html, body').animate({ scrollTop: 0 }, 400);
    playVideo($li, params.autoplay.yes);
  }).data('snippet', item.snippet).data('videoId', videoId).appendTo($list);

  return $li;
}

function setupLoadMore(data, pid, callBack) {
  var $container = $('[data-playlist-id=' + pid + ']'),
    $loadMore = $container.find('[data-' + NAMESPACE + '=load-more]'),
    totalItems = $container.find('li.video-item').length;

  $loadMore.off('click.youtubeAPI.loadMore').on('click.youtubeAPI.loadMore', function () {
    callBack(pid, data.nextPageToken);
  });

  if (data.pageInfo.totalResults == totalItems) {
    $loadMore.hide();
  } else {
    $loadMore.show();
  }
}

function setupPlaylistTitle(pid) {
  var $title = $('[data-playlist-id=' + pid + '] [data-' + NAMESPACE + '=title]'),
    playlistTitle = getPlaylistProperty(pid, 'title');

  $title.text(playlistTitle);
}

function setupCard(item) {
  var $clone = $card.clone(),
    $img = $clone.find('[data-' + NAMESPACE + '=video-card-img]'),
    $title = $clone.find('[data-' + NAMESPACE + '=video-card-title]'),
    title = truncate(item.snippet.title, 58),
    thumbnail = getThumbnail(item.snippet.thumbnails);

  $img.attr('src', thumbnail);
  $title.text(title);

  return $clone;
}

function storeCard() {
  if (!$card) {
    $card = $lists.first().find('[data-' + NAMESPACE + '=video-card]');
    $card.detach();

    $lists.empty();
  }
}

function getPlaylists(nextPageToken = null, attempt = 0) {
  return $.get(buildURL('playlists'), {
    part: 'snippet',
    maxResults: params.maxResults.default,
    channelId: params.channelId,
    key: params.apiKey,
    pageToken: nextPageToken || null,
  }, function (data) {
    cachePlaylistsData(data);

    if (data.nextPageToken && attempt < 3) {
      getPlaylists(data.nextPageToken, attempt++);
    }
  });
}

function getPlaylistItems(pid, callBack, nextPageToken) {
  return $.get(buildURL('playlistItems'), {
    part: 'snippet',
    maxResults: params.maxResults.max,
    channelId: params.channelId,
    playlistId: pid,
    key: params.apiKey,
    pageToken: nextPageToken || null,
  }, function (data) {
    callBack(data, pid);
  }).done(function () {
    loadFirstVideo();
  });
}

function getVideosWithQuery(loc) {
  const p =  {
    part: 'snippet',
    maxResults: params.maxResults.max,
    channelId: params.channelId,
    q: searchQuery(loc),
    key: params.apiKey,
    type: 'video',
  };

  return $.get(buildURL('search'), p, function (data) {
    setupSearchResults(data);
  }).done(function () {
    loadFirstVideo();
  });
}

function loadMore(pid, nextPageToken) {
  return $.get(buildURL('playlistItems'), {
    part: 'snippet',
    maxResults: params.maxResults.max,
    playlistId: pid,
    key: params.apiKey,
    pageToken: nextPageToken,
  }, function (data) {
    buildToList(data, pid);
  });
}

function loadMoreSearchResults(pid, nextPageToken) {
  return $.get(buildURL('search'), {
    part: 'snippet',
    maxResults: params.maxResults.max,
    channelId: params.channelId,
    q: searchQuery(),
    key: params.apiKey,
    pageToken: nextPageToken,
  }, function (data) {
    buildSearchList(data, pid);
  });
}

function loadFirstVideo() {
  if (!_video) {
    var $li = $lists.first().children().first();
    playVideo($li, params.autoplay.no);
  }
}

function cachePlaylistsData(data) {
  data.items.forEach(function (playlist) {
    _playlistCache[playlist.id] = playlist.snippet;
  });
}

function getPlaylistProperty(pid, prop) {
  return _playlistCache[pid] && _playlistCache[pid][prop];
}

function getThumbnail(thumbnails) {
  var thumbnail;
  if (!thumbnails) {
    return;
  }

  if (thumbnails.medium) {
    thumbnail = thumbnails.medium.url;
  } else if (thumbnails.high) {
    thumbnail = thumbnails.high.url;
  } else if (thumbnails.standard) {
    thumbnail = thumbnails.standard.url;
  } else if (thumbnails.default) {
    thumbnail = thumbnails.default.url;
  }

  return thumbnail;
}

function playVideo($li, autoplay) {
  // getPlaylistItems.done is called before the
  // lists are created. This is an unfortunate
  // result of the javascript not aware of which
  // playlist will be returned first, but insures
  // that the first video will always be loaded.
  if (!$li || $li.length === 0 || !$li.data('snippet')) {
    return;
  }

  var $dummy = $('<div />').attr('id', VIDEO_PLAYER_ID),
    snippet = $li.data('snippet'),
    videoId = $li.data('videoId');

  _title = snippet.title;
  _description = snippet.description;

  truncateTitle(TRUNCATE_TITLE_GROW);
  truncateDescription(TRUNCATE_DESCRIPTION_GROW);

  $module.find('.' + PLAY_CLASS).removeClass(PLAY_CLASS);
  $li.addClass(PLAY_CLASS);

  $player.empty();
  $player.append($dummy);

  setTimeout(function () {
    if (typeof YT !== 'undefined') {
      _video = new YT.Player(VIDEO_PLAYER_ID, {
        videoId: videoId,
        width: '100%',
        height: '100%',
        playerVars: {
          autoplay: autoplay,
          controls: 2,
          modestbranding: 1,
          rel: 0,
          showInfo: 0,
        },
      });
    }
  }, 250);
}

function setupPlaylists(results) {
  results.forEach(function (pid) {
    getPlaylistItems(pid, buildPlaylist);
  });
}

function setupHub(results) {
  results.forEach(function (pid) {
    getPlaylistItems(pid, buildHubList);
  });
}

function setupSearchResults(data) {
  if (data.items.length > 0) {
    buildSearchList(data, 'search-results');
  } else {
    searchResultsEmptyMessage();
  }
}

function handleError() {
  var errorMessage = 'Sorry, something went wrong. Please try again later.';
  $('[data-' + NAMESPACE + '=playlists]').addClass('error').text(errorMessage);
}

function searchResultsEmptyMessage() {
  $searchResultsEmptyMessage.show();

  $playlists.hide();

  $module.addClass('empty-search-results');

  $(window).off('scroll.youtubeAPI.scroll');
}

function load(type, result) {
  switch (type) {
    case 'hub':
      setupHub(result);
      break;
    case 'playlists':
      setupPlaylists(result);
      break;
  }
}

function truncate(string, tolerance) {
  var text = (string.length >= tolerance) ? string.substring(0, tolerance) + '...' : string;
  return text;
}

function truncateTitle(tolerance) {
  if (_title) {
    var title = truncate(_title, tolerance);
    $videoTitle.text(title);
  }
}

function truncateDescription(tolerance) {
  if (_description) {
    var description = truncate(_description, tolerance);
    $videoDescription.text(description);
  }
}

function setup() {
  $module = $('[data-module=' + NAMESPACE + ']');
  $header = $module.find('[data-' + NAMESPACE + '=video-header]');
  $videoTitle = $module.find('[data-' + NAMESPACE + '=video-title]');
  $videoDescription = $module.find('[data-' + NAMESPACE + '=video-description]');
  $lists = $('[data-' + NAMESPACE + '=list]');
  $player = $module.find('[data-' + NAMESPACE + '=video-player]');
  $playlists = $module.find('[data-' + NAMESPACE + '=playlists]');
  $searchResultsEmptyMessage = $module.find('[data-' + NAMESPACE + '=search-results-empty-message]');

  storeCard();
}

function searchQuery(loc) {
  const parsed = qs.parse(loc.search, { ignoreQueryPrefix: true });
  return parsed.query;
}

function init(type, playlists, loc = location) {
  setup();

  var req;
  if (type === 'search') {
    req = getVideosWithQuery(loc);
  } else {
    req = getPlaylists().success(function () {
      load(type, playlists);
    });
  }

  req.fail(function (response) {
    handleError(response);
  });
}

export default {
  init,
};
