import PropTypes from 'prop-types';
import React from 'react';
import RoutingNumberField from '../routing_number_field';
import CurrencyWarnings from '../currency_warnings';
import CountryCodeInput from './country_code_input';
import EXAMPLE_CHECK_IMAGE from '../../../../images/example-check.png';
import { CURRENCY_CODES } from '../../../shared/constants';
import I18n from 'i18n-js';

const UNITED_STATES_COUNTRY_CODE = 'US';

const USBankFields = (props) => {
  function formattedNameForCurrency() {
    return props.currencies[0].name;
  }

  return (
    <div>
      <div className="g-container">
        <div className="g-col-6 g-col-mobile-12">
          <label>
            {I18n.t('js.dashboard.selling.shopPolicies.directCheckoutProfile.bankAccountFields.nameOnAccount')}
          </label>
          <input
            className="string required wide mb-0"
            autoFocus
            type="text"
            name={props.nameForField('name_on_account')}
          />
        </div>
        <div className="g-col-6 g-col-mobile-12 mobile-mt-2">
          <label>
            {I18n.t('js.dashboard.selling.shopPolicies.directCheckoutProfile.bankAccountFields.accountType')}
          </label>
          <ul className="inline-list horizontal-padded-list scaling-pt-1">
            <li>
              <label className="inline">
                <input
                  required
                  type="radio"
                  value="checking"
                  defaultChecked
                  name={props.nameForField('bank_account_type')}
                />
                <span>
                  {I18n.t('js.dashboard.selling.shopPolicies.directCheckoutProfile.bankAccountFields.checkingRadio')}
                </span>
              </label>
            </li>
            <li>
              <label className="inline">
                <input
                  required
                  type="radio"
                  value="savings"
                  name={props.nameForField('bank_account_type')}
                />
                <span>
                  {I18n.t('js.dashboard.selling.shopPolicies.directCheckoutProfile.bankAccountFields.savingsRadio')}
                </span>
              </label>
            </li>
          </ul>
        </div>
      </div>

      <div className="mb-2">
        <div className="g-container">
          <CountryCodeInput
            countryCode={UNITED_STATES_COUNTRY_CODE}
            countryCodeOptions={props.countryCodeOptions}
            nameForField={fieldName => props.nameForField(fieldName)}
            onCountryChange={countryCode => props.onCountryChange(countryCode)}
          />
          <div className="g-col-6">
            <label className="string required">
              {I18n.t('js.dashboard.selling.shopPolicies.directCheckoutProfile.bankAccountFields.currencyInput.label')}
            </label>
            <input
              type="hidden"
              name={props.nameForField('currency')}
              value={CURRENCY_CODES.USD}
            />
            <div>{formattedNameForCurrency()}</div>
          </div>
        </div>

        <CurrencyWarnings
          payoutCurrency={CURRENCY_CODES.USD}
          defaultCurrencyForBankCountry={CURRENCY_CODES.USD}
          shopCurrency={props.shopCurrency}
          dashboardView={props.dashboardView}
          nameForField={props.nameForField}
        />
      </div>

      <div className="g-container">
        <div className="g-col-6 g-col-mobile-12">
          <label className="string required">
            {I18n.t('js.dashboard.selling.shopPolicies.directCheckoutProfile.bankAccountFields.routingNumber')}
          </label>
          <RoutingNumberField
            name={props.nameForField('routing_number')}
          />
        </div>

        <div className="g-col-6 g-col-mobile-12">
          <label className="string required">
            {I18n.t('js.dashboard.selling.shopPolicies.directCheckoutProfile.bankAccountFields.accountNumber')}
          </label>
          <input
            className="string required wide"
            maxLength={17}
            type="text"
            name={props.nameForField('bank_account_number')}
          />
        </div>
      </div>
      <div className="toggler mb-0">
        {/* disabling inline here so we can enable this rule globally, but this should be fixed the next time this file is touched */}
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a className="small" href="#">
          {I18n.t('js.dashboard.selling.shopPolicies.directCheckoutProfile.bankAccountFields.findBankNumbers')}
        </a>
      </div>
      <div className="toggleable ptb-2" id="example-check">
        <img
          src={EXAMPLE_CHECK_IMAGE}
          className="example-check-img"
          alt={I18n.t('js.dashboard.selling.shopPolicies.directCheckoutProfile.bankAccountFields.exampleCheckAlt')}
        />
      </div>
    </div>
  );
};

USBankFields.propTypes = {
  nameForField: PropTypes.func.isRequired,
  onCountryChange: PropTypes.func.isRequired,
  countryCodeOptions: PropTypes.array.isRequired,
  shopCurrency: PropTypes.string.isRequired,
  currencies: PropTypes.array.isRequired,
  dashboardView: PropTypes.bool.isRequired,
};

export default USBankFields;
