import React from 'react';
import classNames from 'classnames';
import { AdyenCardType } from '@reverbdotcom/commons/src/adyen';

export enum NonAdyenCardType {
  Affirm = 'affirm',
  ApplePay = 'apple-pay',
  Error = 'error',
  GooglePay = 'google-pay',
  Paypal = 'paypal',
  Placeholder = 'placeholder',
  ReverbBucks = 'reverb-bucks',
  ReverbCredits = 'reverb-credits',
}

export type PaymentIconName = NonAdyenCardType | AdyenCardType;

interface IPaymentIconProps {
  name?: PaymentIconName | string; // for when Adyen returns 'mc'
  size?: 'default' | 'fullwidth'; // 'default' is assumed when no modifiers present
}

// This is a UI wrapper for the 'payment-icon' classes found at
// packages/cadence/styles/classes-ui/payment-icon.scss

// There are other payment icons labeled with plurally i.e.
// 'payment-icons', those are deprecated and should be considered legacy.
export default function PaymentIcon({ name, size }: IPaymentIconProps) {
  return (
    <span className={classNames(
      'payment-icon',
      { 'payment-icon--fullwidth': size === 'fullwidth' },
      { [`payment-icon--${name}`]: !!name },
    )} />
  );
}
