import { first, get, isNull } from 'lodash';
import React from 'react';
import I18n from 'i18n-js';

import ModalDialog from '@reverbdotcom/commons/src/components/modal_dialog';
import {
  offerFormReducer,
  emptyOfferFormState,
  SubmittedOffer,
  filterValidShippingAddresses,
  filterValidCreditCards,
  calculateOfferDiscountPercent,
  offerWouldBeAutoRejected,
} from '@reverbdotcom/commons/src/offers/create_offer_modal';
import ImmediatePaymentReview from '@reverbdotcom/commons/src/offers/immediate_payment_review';
import PayImmediatelyRadioGroup from '@reverbdotcom/commons/src/offers/pay_immediately_radio_group';
import { OfferModalHeaderContent } from '@reverbdotcom/commons/src/offers/offer_modal_header_content';
import { priceRecommendationCanBeShown } from '@reverbdotcom/commons/src/offers/estimated_value_alert';
import { EVERYWHERE_CODE, US } from '@reverbdotcom/commons/src/constants';
import { convertCurrency } from '@reverbdotcom/commons/src/convert_currency';
import {
  editOfferFormQuery,
  updateNegotiationMutation,
} from '@reverbdotcom/commons/src/offers/offer_operations';
import * as elog from '@reverbdotcom/commons/src/elog';
import { useSeller } from '@reverbdotcom/commons/src/user_hooks';
import { RCAlertBox, RCRadioGroup } from '@reverbdotcom/cadence/components';
import { parseAmount } from '@reverbdotcom/commons/src/money';
import { MParticleEventName, trackEvent } from '@reverbdotcom/commons/src/elog/mparticle_tracker';
import { canViewListingPriceRecommendations, isExperimentEnabled } from '@reverbdotcom/commons/src/user_context_helpers';
import { useQuery } from '@reverbdotcom/commons/src/useQuery';
import { useMutation } from '@reverbdotcom/commons/src/useMutation';
import experiments from '@reverbdotcom/commons/src/experiments';
import { SanitizedRender } from '@reverbdotcom/commons';
import { formatExperimentsForEventAttributes } from '@reverbdotcom/commons/src/elog/mparticle';

import {
  Commons_Offers_UpdateOfferFormMutation,
  Commons_Offers_UpdateOfferFormMutationVariables,
  Input_core_apimessages_UpdateNegotiationRequest,
  core_apimessages_NegotiationParty as NegotiationParty,
  core_apimessages_NegotiationAction as NegotiationAction,
} from '@reverbdotcom/commons/src/gql/graphql';

import NegotiationSummary from './negotiation_summary';
import UpdateOfferFormFields from './update_offer_form_fields';
import {
  lastOffer as getLastOffer,
  lastOfferPrices as getLastOfferPrices,
  lastOfferPricesFor,
  activeNegotiation,
  offerShippingPriceAmount,
  offerQuantity,
  userCurrency as getUserCurrency,
  originalCurrency,
} from './negotiation_getters';
import { UpdateOfferConfirmation } from './update_offer_confirmation';

interface Props {
  listingId: string;
  isSeller: boolean;
  negotiationId: string;
  onSuccess: (requestingParty: NegotiationParty) => void;
  isDialogOpen?: boolean;
  onRequestDialogClose?: () => void;
  negotiationAction: NegotiationAction;
}

const TRACKING_COMPONENT = 'UpdateOfferModal';
export const HOURS_TO_CHECK_OUT_WHEN_CONDITIONALLY_ACCEPTED = 3;

interface BaseOffer {
  price: string;
  shippingPrice: string;
  quantity: number;
  message: string;
}

function ErrorDisplay() {
  return (
    <RCAlertBox type="error">
      <p className="mb-0">
        {I18n.t('discovery.offers.error')}
      </p>
    </RCAlertBox>
  );
}

function LoadingDisplay() {
  return (
    <div className="offers-form offers-form--loading">
      <div className="offers-form-placeholder" />
      <div className="offers-form-placeholder" />
      <div className="offers-form-placeholder" />
    </div>
  );
}

export function UpdateOfferModal({
  isDialogOpen,
  onRequestDialogClose,
  negotiationAction,
  isSeller,
  onSuccess,
  listingId,
  negotiationId,
}: Props) {
  const user = useSeller(); // this is not necessarily the seller in the negotiation, but we may need access to seller-specific fields if they are
  const [formState, dispatch] = React.useReducer(offerFormReducer, emptyOfferFormState);
  const [errorMessage, setErrorMessage] = React.useState('');
  const [acceptedOrCounteredOffer, setAcceptedOrCounteredOffer] = React.useState<SubmittedOffer>({ price: '', shippingPrice: '', totalPrice: '' });
  const [willPayImmediately, setWillPayImmediately] = React.useState(false);
  const [confirmedWillPayImmediately, setConfirmedWillPayImmediately] = React.useState(false);
  const [conditionalAcceptanceSelected, setConditionalAcceptanceSelected] = React.useState(null);

  const { loading, errors, data, refetch } = useQuery(
    editOfferFormQuery,
    {
      skip: !isDialogOpen,
      errorPolicy: 'all',
      variables: {
        listingId: listingId,
        negotiationId: negotiationId,
        isSeller: !!isSeller,
        shippingRegionCode: user?.shippingRegionCode || EVERYWHERE_CODE,
      },
    },
  );

  const [
    updateNegotiation,
    {
      loading: mutationDataLoading,
      errors: mutationErrors,
    },
  ] = useMutation<Commons_Offers_UpdateOfferFormMutation, Commons_Offers_UpdateOfferFormMutationVariables>(
    updateNegotiationMutation,
  );

  const listing = data?.listing;
  const negotiation = activeNegotiation(data?.me);
  const shop = listing?.shop;
  const lastOffer = getLastOffer(negotiation);
  const userCurrency = getUserCurrency(data, user);
  const shopCurrency = shop?.currency;
  const preventLowballOffers = !!listing?.offerBotRule?.autoRejectPercentage;
  const offerCurrency = isSeller ? shopCurrency : userCurrency;
  const exchangeRates = data?.exchangeRates?.rates || [];
  const party = isSeller ? NegotiationParty.SELLER : NegotiationParty.BUYER;
  const lastOfferPrices = getLastOfferPrices(data?.me);
  const originalLastOfferPrices = lastOfferPricesFor(NegotiationParty.SELLER, negotiation);
  const isCounterOffer = negotiationAction === NegotiationAction.COUNTER;
  const closeDialog = () => {
    if (onRequestDialogClose) {
      onRequestDialogClose();
    }
  };

  const missingExchangeRates = !exchangeRates.length &&
    !isSeller &&
    (userCurrency !== originalCurrency(data));
  const showError = errors || (!negotiation && !loading) || missingExchangeRates;

  const userCanViewPriceRecommendation = user && shopCurrency && canViewListingPriceRecommendations(user, shopCurrency);
  const showPriceRecommendation = !conditionalAcceptanceSelected && priceRecommendationCanBeShown(user, shopCurrency, listing);
  const parsedOfferPrice = formState.price && offerCurrency && parseAmount(formState.price, offerCurrency);
  const parsedOfferShippingPrice = formState.shippingPrice && shopCurrency && parseAmount(formState.shippingPrice, shopCurrency);
  const inRecommendedPriceRange = userCanViewPriceRecommendation &&
    parsedOfferPrice?.amountCents &&
    listing.priceRecommendation?.priceLow?.amountCents &&
    listing.priceRecommendation?.priceHigh?.amountCents &&
    parsedOfferPrice.amountCents >= listing.priceRecommendation.priceLow.amountCents &&
    parsedOfferPrice.amountCents <= listing.priceRecommendation.priceHigh.amountCents;
  const hasAcceptedOrCounteredOffer = !!acceptedOrCounteredOffer?.totalPrice;

  const shippingAddresses = filterValidShippingAddresses(data?.me?.shippingAddresses);
  const creditCards = filterValidCreditCards(data?.me?.creditCards);
  const selectedCreditCard = formState.creditCardId && creditCards.find((cc) => cc.id.toString() == formState.creditCardId);
  const showPayImmediately = isCounterOffer && !isSeller &&
    shop?.address?.countryCode == US && shippingAddresses.length > 0 && creditCards.length > 0;
  const immediatePaymentFormStateIsInvalid = !formState.shipTo?.id || !selectedCreditCard || selectedCreditCard?.needsReverification;

  const wouldBeAutoRejected = !isSeller && isCounterOffer && preventLowballOffers && offerWouldBeAutoRejected(parsedOfferPrice, offerCurrency, listing);

  const canConditionallyAccept = isSeller && isCounterOffer && isExperimentEnabled(user, experiments.ALLOW_CONDITIONAL_OFFER_ACCEPTANCE);

  React.useEffect(() => {
    if (loading || !negotiation) { return null; }

    const initialShippingPrice = offerShippingPriceAmount(
      formState.shippingPrice || undefined, // the default shipping price behavior kicks in when the value is undefined, not the empty string
      data?.me,
    );
    dispatch({ type: 'setShippingPrice', value: initialShippingPrice });

    const initialQuantity = offerQuantity(lastOffer, formState.quantity);
    dispatch({ type: 'setQuantity', value: initialQuantity });

    if (showPayImmediately) {
      dispatch({ type: 'setShipTo', value: shippingAddresses.find((addr) => addr.primary) || shippingAddresses[0] });
      dispatch({ type: 'setCreditCardId', value: (creditCards.find((card) => card.primaryForCheckout) || creditCards[0]).id });
    }

    if (isSeller && isCounterOffer) {
      trackEvent({
        eventName: MParticleEventName.ConditionalOfferAcceptanceQualifyingEvent,
        sellerId: user.sellerId,
        shopId: user.shopId,
        secondaryUserModeIsActive: user.secondaryUserModeIsActive,
        secondaryUserModeIsAvailable: user.secondaryUserModeIsAvailable,
        experiments: formatExperimentsForEventAttributes(user, [experiments.ALLOW_CONDITIONAL_OFFER_ACCEPTANCE]),
      });
    }
  }, [negotiation, loading]);

  React.useEffect(() => {
    if (mutationErrors.length > 0) {
      const { message: firstErrorMessage } = first(mutationErrors);

      setErrorMessage(firstErrorMessage);

      elog.error(
        TRACKING_COMPONENT,
        {
          ...trackingContext(),
          errors: mutationErrors,
        },
        firstErrorMessage,
      );
    }
  }, [mutationErrors]);

  function modalHeaderTitle() {
    if (hasAcceptedOrCounteredOffer) {
      if (negotiationAction == NegotiationAction.ACCEPT) {
        if (isSeller && negotiation?.buyerWillPayImmediately) {
          return I18n.t('discovery.offers.confirmation.modalHeader.acceptedWithImmediatePayment');
        } else {
          return I18n.t('discovery.offers.confirmation.modalHeader.accepted');
        }
      } else if (isCounterOffer) {
        return I18n.t('discovery.offers.confirmation.modalHeader.countered');
      }
    } else if (confirmedWillPayImmediately) {
      return I18n.t('discovery.offers.headerTitle.review');
    }
    return I18n.t(`discovery.offers.headerTitle.${negotiationAction.toLocaleLowerCase()}`);
  }

  function saveButtonText() {
    const immediatePaymentReviewPending = showPayImmediately && willPayImmediately && !confirmedWillPayImmediately;

    if (hasAcceptedOrCounteredOffer) {
      return I18n.t('discovery.offers.confirmation.buttonText');
    } else if (immediatePaymentReviewPending) {
      return I18n.t('discovery.offers.submitButton.review');
    } else {
      switch (negotiationAction) {
        case NegotiationAction.ACCEPT:
          return I18n.t('discovery.offers.headerTitle.accept');
        case NegotiationAction.REJECT:
          return I18n.t('discovery.offers.headerTitle.reject');
        case NegotiationAction.COUNTER:
        default:
          return I18n.t('discovery.offers.submitButton.counter');
      }
    }
  }

  function getExistingOffer(): BaseOffer {
    return {
      price: lastOffer.prices.price.display.amount,
      shippingPrice: lastOffer.prices.shippingPrice.display.amount,
      quantity: first(lastOffer.offerItems).quantity,
      message: formState.message,
    };
  }

  function offerToSubmitOnUpdate(): Input_core_apimessages_UpdateNegotiationRequest {
    const existingOffer = getExistingOffer();
    const baseOffer = isCounterOffer ? { ...formState, price: conditionalAcceptanceSelected ? existingOffer.price : formState.price } : existingOffer;

    const shopCurrencyOfferPrice = convertCurrency(
      userCurrency,
      originalCurrency(data),
      exchangeRates,
      baseOffer.price,
    );
    const shopCurrencyOfferShippingPrice = convertCurrency(
      userCurrency,
      originalCurrency(data),
      exchangeRates,
      baseOffer.shippingPrice,
    );

    let optionalParams = {};

    if (confirmedWillPayImmediately) {
      optionalParams = {
        ...optionalParams,
        shippingAddressUuid: formState.shipTo.id,
        creditCardId: formState.creditCardId,
      };
    }

    if (!isNull(conditionalAcceptanceSelected)) {
      optionalParams = {
        ...optionalParams,
        conditionallyAcceptedBySeller: conditionalAcceptanceSelected,
      };
    }

    return {
      action: negotiationAction,
      id: negotiation.id,
      message: formState.message,
      offerItems: [
        {
          listingId: data.listing.id,
          quantity: baseOffer.quantity,
          price: shopCurrencyOfferPrice,
          shippingPrice: shopCurrencyOfferShippingPrice,
        },
      ],
      ...optionalParams,
    };
  }

  function trackingContext() {
    return {
      listingId: listingId,
      negotiationId: negotiationId,
      shopCurrency: shopCurrency,
      userCurrency: userCurrency,
    };
  }

  async function doNegotiationUpdate(): Promise<Commons_Offers_UpdateOfferFormMutation['updateNegotiation']['negotiation']> {
    const input = offerToSubmitOnUpdate();
    const { data: mutationResult } = await updateNegotiation({ variables: { input } });
    return get(mutationResult, 'updateNegotiation.negotiation');
  }

  async function handleSubmit() {
    if (hasAcceptedOrCounteredOffer) {
      onSuccess(party);
      closeDialog();
    } else if (showPayImmediately && willPayImmediately && !confirmedWillPayImmediately) {
      setConfirmedWillPayImmediately(true);
    } else {
      setErrorMessage('');

      const response = await doNegotiationUpdate();

      if (response?.lastOffer) {
        if (isCounterOffer) {
          let comparedToRecommendedMiddlePrice;
          if (!userCanViewPriceRecommendation || !parsedOfferPrice?.amountCents || !listing.priceRecommendation?.priceMiddle?.amountCents) {
            comparedToRecommendedMiddlePrice = '';
          } else {
            if (parsedOfferPrice.amountCents < listing.priceRecommendation.priceMiddle.amountCents) {
              comparedToRecommendedMiddlePrice = 'lower';
            } else if (parsedOfferPrice.amountCents > listing.priceRecommendation.priceMiddle.amountCents) {
              comparedToRecommendedMiddlePrice = 'higher';
            } else {
              comparedToRecommendedMiddlePrice = 'equal';
            }
          }

          trackEvent({
            eventName: MParticleEventName.SubmittedOffer,
            listingId: listingId,
            discountPercent: calculateOfferDiscountPercent(conditionalAcceptanceSelected ? response.lastOffer.prices.price.display : parsedOfferPrice, listing.price, exchangeRates),
            eligibleForPriceRecommendation: !!showPriceRecommendation,
            inRecommendedPriceRange: !!inRecommendedPriceRange,
            comparedToRecommendedMiddlePrice: comparedToRecommendedMiddlePrice,
            role: isSeller ? 'seller' : 'buyer',
            isCounterOffer: true,
            optedIntoImmediatePayment: confirmedWillPayImmediately,
            isConditionalAcceptance: !!conditionalAcceptanceSelected,
          });
        }

        if (negotiationAction !== NegotiationAction.REJECT) {
          const { prices } = response.lastOffer;
          setAcceptedOrCounteredOffer({
            price: prices.price.display.display,
            shippingPrice: prices.shippingPrice.display.display,
            totalPrice: prices.totalPrice.display.display,
          });
        } else {
          onSuccess(party);
          closeDialog();
        }
      }
    }
  }

  function handleClose() {
    if (hasAcceptedOrCounteredOffer) {
      onSuccess(party);
    }
    closeDialog();
  }

  return (
    <ModalDialog
      isOpen={isDialogOpen}
      onRequestClose={handleClose}
      headerTitle={modalHeaderTitle()}
      onSubmit={handleSubmit}
      saveButtonText={saveButtonText()}
      backButtonText={confirmedWillPayImmediately ? I18n.t('discovery.offers.cancelButton.back') : I18n.t('discovery.offers.cancelButton.cancel')}
      isDisabled={loading ||
        mutationDataLoading ||
        (isCounterOffer && ((canConditionallyAccept && isNull(conditionalAcceptanceSelected)) || (!conditionalAcceptanceSelected && !formState.price))) ||
        wouldBeAutoRejected ||
        (confirmedWillPayImmediately && immediatePaymentFormStateIsInvalid)
      }
      hideFooterDismissButton={hasAcceptedOrCounteredOffer}
      onNavigateBack={confirmedWillPayImmediately ? () => {
        setConfirmedWillPayImmediately(false);
      } : undefined}
    >
      {showError && <ErrorDisplay />}

      {loading && !listing && <LoadingDisplay />}

      {!loading && !errors && listing && negotiation && (
        <>
          <OfferModalHeaderContent
            listing={listing}
            isSeller={isSeller}
            refetch={refetch}
            updateMode={true}
            submittedOffer={hasAcceptedOrCounteredOffer && acceptedOrCounteredOffer}
            hideListingInfo={confirmedWillPayImmediately && !hasAcceptedOrCounteredOffer}
            condensed={(confirmedWillPayImmediately && !hasAcceptedOrCounteredOffer) || canConditionallyAccept}
          />

          {!hasAcceptedOrCounteredOffer && !confirmedWillPayImmediately && (
            <>
              <div>
                <NegotiationSummary
                  prices={lastOfferPrices}
                  originalPrices={originalLastOfferPrices}
                  negotiation={negotiation}
                  party={party}
                  action={negotiationAction}
                  localPickupOnly={listing.shipping.localPickupOnly}
                />
              </div>

              {canConditionallyAccept && (
                <RCRadioGroup
                  id="counter-options"
                  name="counter-options"
                  label={I18n.t('discovery.offers.counterOptions.title')}
                  value={!isNull(conditionalAcceptanceSelected) ? conditionalAcceptanceSelected.toString() : ''}
                  onChange={(e) => { setConditionalAcceptanceSelected(e.target.value == 'true'); }}
                >
                  <RCRadioGroup.Option
                    label={<SanitizedRender
                      html={I18n.t('discovery.offers.counterOptions.conditional.label', { price: lastOfferPrices.price.display, hours: HOURS_TO_CHECK_OUT_WHEN_CONDITIONALLY_ACCEPTED })}
                    />}
                    value="true"
                  />
                  <RCRadioGroup.Option
                    label={I18n.t('discovery.offers.counterOptions.standard.label')}
                    value="false"
                  />
                </RCRadioGroup>
              )}

              {(!canConditionallyAccept || !isNull(conditionalAcceptanceSelected)) && (
                <UpdateOfferFormFields
                  error={errorMessage}
                  listing={listing}
                  negotiation={negotiation}
                  currency={userCurrency}
                  offer={formState}
                  existingOffer={getExistingOffer()}
                  onOfferChange={dispatch}
                  action={negotiationAction}
                  party={party}
                  priceRecommendation={showPriceRecommendation && listing.priceRecommendation}
                  preventLowballOffers={preventLowballOffers}
                  conditionalAcceptanceSelected={conditionalAcceptanceSelected}
                />
              )}

              {showPayImmediately && (
                <div className="mt-4">
                  <PayImmediatelyRadioGroup
                    willPayImmediately={willPayImmediately}
                    setWillPayImmediately={setWillPayImmediately}
                  />
                </div>
              )}
            </>
          )}

          {!hasAcceptedOrCounteredOffer && confirmedWillPayImmediately && (
            <ImmediatePaymentReview
              offerPrice={parsedOfferPrice}
              shippingPrice={parsedOfferShippingPrice}
              currency={offerCurrency}
              selectedAddress={formState.shipTo}
              allAddresses={shippingAddresses}
              selectedCreditCard={selectedCreditCard}
              allCreditCards={creditCards}
              dispatch={dispatch}
              localPickupOnly={listing.shipping.localPickupOnly}
              listingId={listingId}
            />
          )}

          {hasAcceptedOrCounteredOffer && isSeller && (
            <UpdateOfferConfirmation
              negotiationAction={negotiationAction}
              isSeller={isSeller}
              buyerWillPayImmediately={negotiation.buyerWillPayImmediately}
              localPickupOnly={listing.shipping.localPickupOnly}
              hasConditionallyAccepted={conditionalAcceptanceSelected}
            />
          )}

          {hasAcceptedOrCounteredOffer && !isSeller && (
            <UpdateOfferConfirmation
              negotiationAction={negotiationAction}
              isSeller={isSeller}
              buyerWillPayImmediately={confirmedWillPayImmediately}
            />
          )}
        </>
      )}
    </ModalDialog>
  );
}

export default UpdateOfferModal;
