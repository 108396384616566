import React from 'react';
import I18n from 'i18n-js';
import { RCFlex, RCIcon, RCSegmentedControl, RCText, Theme, useTheme } from '@reverbdotcom/cadence/components';
import { SunIcon, MoonIcon } from '@reverbdotcom/cadence/icons/react';

/**
 * README
 *
 * This component is intended to be used for debug purposes.
 * Please reach out to #cadence if you have any questions or concerns.
 */
export function WebThemeControl() {
  const { theme, updateTheme } = useTheme();
  const LIGHT_THEME: Theme = 'light';
  const DARK_THEME: Theme = 'dark';

  function handleClick(newTheme) {
    if (newTheme === theme) return;
    updateTheme(newTheme);
  }

  return (
    <div className="web-theme-control">
      <RCSegmentedControl
        aria-label={I18n.t('commons.webThemeControl.theme')}
        size="small"
        fullWidth
      >
        <RCSegmentedControl.Button
          selected={theme === LIGHT_THEME}
          onClick={() => handleClick(LIGHT_THEME)}
        >
          <RCFlex gap="2" align="center">
            <RCIcon size="rc-space-4" svgComponent={() => <SunIcon />} />
            <RCText.Utility size="350" weight="semibold">{I18n.t('commons.webThemeControl.light')}</RCText.Utility>
          </RCFlex>
        </RCSegmentedControl.Button>
        <RCSegmentedControl.Button
          selected={theme === DARK_THEME}
          onClick={() => handleClick(DARK_THEME)}
        >
          <RCFlex gap="2" align="center">
            <RCIcon size="rc-space-4" svgComponent={() => <MoonIcon />} />
            <RCText.Utility size="350" weight="semibold">{I18n.t('commons.webThemeControl.dark')}</RCText.Utility>
          </RCFlex>
        </RCSegmentedControl.Button>
      </RCSegmentedControl>
    </div>
  );
}
