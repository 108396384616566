import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import AboutDirectCheckoutBullets from './about_direct_checkout_bullets';

export const US_RP_LABEL_IMAGE_CLASS = 'payment-setup__provider-logo--rp-us';
export const CA_RP_LABEL_IMAGE_CLASS = 'payment-setup__provider-logo--rp-ca';
export const EU_RP_LABEL_IMAGE_CLASS = 'payment-setup__provider-logo--rp-eu';
export const EUR_EU_RP_LABEL_IMAGE_CLASS = 'payment-setup__provider-logo--rp-eu-eur';

function DirectCheckoutWrapper(props) {
  function labelImageClass() {
    // show Sofort as an option
    if (props.shopCurrency === 'EUR') {
      return EUR_EU_RP_LABEL_IMAGE_CLASS;
    }

    // hide Sofort
    if (props.shopCurrency === 'GBP') {
      return EU_RP_LABEL_IMAGE_CLASS;
    }

    // show Discover and Affirm
    if (props.shopCurrency === 'USD' && props.bankCountryCode === 'US') {
      return US_RP_LABEL_IMAGE_CLASS;
    }

    // show Discover; hide Affirm
    return CA_RP_LABEL_IMAGE_CLASS;
  }

  return (
    <div className="payment-setup">
      <div className={classNames('payment-setup__label payment-setup__label--dc scaling-mt-4', props.className)}>
        {props.children}
        <label htmlFor="shop-settings-dc-radio">
          <div className={classNames('payment-setup__provider-logo', labelImageClass())} />
        </label>
      </div>
      <div className="payment-setup__info">
        <AboutDirectCheckoutBullets
          directCheckoutFeePercent={props.directCheckoutFeePercent}
          directCheckoutFeeFlatAmount={props.directCheckoutFeeFlatAmount}
          directCheckoutIntlFeePercent={props.directCheckoutIntlFeePercent}
          shopCurrency={props.shopCurrency}
        />
      </div>
    </div>
  );
}

DirectCheckoutWrapper.defaultProps = {
  children: null,
  className: '',
};

DirectCheckoutWrapper.propTypes = {
  directCheckoutFeePercent: PropTypes.number.isRequired,
  directCheckoutFeeFlatAmount: PropTypes.string.isRequired,
  directCheckoutIntlFeePercent: PropTypes.number.isRequired,
  shopCurrency: PropTypes.string.isRequired,
  bankCountryCode: PropTypes.string.isRequired,
  children: PropTypes.element,
  className: PropTypes.string,
};

export default DirectCheckoutWrapper;
