import React from 'react';
import I18n from 'i18n-js';
import SanitizedRender from './sanitized_render';

export default function GoogleRecaptchaTerms() {
  const translatedTerms = I18n.t(
    'commons.signupForm.googleRecaptchaTermsOfService',
    {
      termsLinkOpen: '<a href=\'https://policies.google.com/terms\' target=\'_blank\'>',
      privacyLinkOpen: '<a href=\'https://policies.google.com/privacy\' target=\'_blank\'>',
      linkClose: '</a>',
    },
  );

  return (
    <div className="color-secondary mt-2 size-80">
      <SanitizedRender html={translatedTerms} />
    </div>
  );
}
