import React, { useState } from 'react';
import {
  core_apimessages_Tax1099kReportingThresholdSearchResult,
  Input_core_apimessages_AdminTax1099kReportingThresholdUpdateRequest,
  Input_core_apimessages_AdminTax1099kReportingThresholdCreateRequest,
} from '@reverbdotcom/commons/src/gql/graphql';
import { RCButton, RCTextInput, RCTextWithIcon } from '@reverbdotcom/cadence/components';
import { CheckIcon, CloseIcon, PlusIcon } from '@reverbdotcom/cadence/icons/react';

type ThresholdInput = Input_core_apimessages_AdminTax1099kReportingThresholdCreateRequest | Input_core_apimessages_AdminTax1099kReportingThresholdUpdateRequest;

interface EditRowProps {
  cancelEdit?: () => void;
  threshold?: core_apimessages_Tax1099kReportingThresholdSearchResult;
  saveThreshold: (threshold: ThresholdInput) => void
}

export default function Tax1099kReportingThresholdEditRow({
  cancelEdit,
  saveThreshold,
  threshold,
}: EditRowProps) {
  const [ year, setYear ] = useState(threshold?.year || '');
  const [ region, setRegion ] = useState(threshold?.region || '');
  const [ salesAmountCents, setSalesAmountCents ] = useState(threshold?.salesAmountCents || '');
  const [ transactionCount, setTransactionCount ] = useState(threshold?.transactionCount || '');

  const save = () => {
    saveThreshold({
      ...(threshold?.id && { id: threshold?.id }),
      year,
      region,
      salesAmountCents,
      transactionCount,
    });

    if (cancelEdit) {
      cancelEdit();
    }
  };

  return (
    <tr>
      <td>
        <div className="mt-4">
          <RCTextInput
            id={`year${threshold?.id || 'New'}`}
            label=""
            name="year"
            value={year}
            onChange={(e) => setYear(e.target.value)}
            type="number"
          />
        </div>
      </td>
      <td>
        Leave blank for federal threshold
        <RCTextInput
          id={`region${threshold?.id || 'New'}`}
          label=""
          name="region"
          value={region}
          onChange={(e) => setRegion(e.target.value)}
        />
      </td>
      <td>
        Enter as cents: 60000 for $600
        <RCTextInput
          id={`salesAmountCents${threshold?.id || 'New'}`}
          label=""
          name="salesAmountCents"
          value={salesAmountCents}
          onChange={(e) => setSalesAmountCents(e.target.value)}
          type="number"
        />
      </td>
      <td>
        <div className="mt-4">
          <RCTextInput
            id={`transactionCount${threshold?.id || 'New'}`}
            label=""
            name="transactionCount"
            value={transactionCount}
            onChange={(e) => setTransactionCount(e.target.value)}
            type="number"
          />
        </div>
      </td>
      <td className="align-right">
        <div className="d-flex fx-justify-end mt-4">
          <div className="mr-2">
            <RCButton
              id={`save${threshold?.id || 'New'}`}
              size="mini"
              onClick={save}
              variant="filled"
            >
              <RCTextWithIcon
                svgComponent={cancelEdit ? CheckIcon : PlusIcon}
                children={cancelEdit ? 'Save' : 'Add new threshold'}
                title={cancelEdit ? 'Save' : 'Add new threshold'}
                placement="left"
              />
            </RCButton>
          </div>
          {!!cancelEdit && (
            <RCButton
              id={`cancel${threshold?.id}`}
              size="mini"
              onClick={cancelEdit}
            >
              <RCTextWithIcon
                svgComponent={CloseIcon}
                children="Cancel"
                title="Cancel"
                placement="left"
              />
            </RCButton>
          )}
        </div>
      </td>
    </tr>
  );
}
