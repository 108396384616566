import PropTypes from 'prop-types';
import React from 'react';
import { RCStarRatingSelector } from '../index';
import ProductReview from './model';
import classNames from 'classnames';
import { debounce } from 'lodash';
import I18n from 'i18n-js';
import { RCButton, RCTextInput, RCTextArea } from '@reverbdotcom/cadence/components';

const SELLER_REVIEW_HINTS = [
  'seller',
  'sold',
  'shipped',
  'ship',
  'shipment',
  'shipping',
  'arrived',
  'transaction',
  'delivery',
  'refund',
  'damaged',
  'returned',
  'condition',
  'deal',
  'money back',
  'offer',
  'do business',
  'doing business',
  'fraud',
  'fraudulent',
  'scam',
  'as described',
  'fast shipping',
  'thank you',
  'description',
];

class ReviewForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      rating: props.rating,
      body: props.body,
      title: props.title,
      ratingError: props.errorMessage,
      displayRating: props.rating,
    };

    this.updateRating = this.updateRating.bind(this);

    if (this.props.disableDebounce) {
      this.checkSellerReviewWarning = this.checkSellerReviewWarning;
    } else {
      this.checkSellerReviewWarning = debounce(this.checkSellerReviewWarning, 500);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({ ratingError: nextProps.errorMessage });
  }

  updateRating(newRating) {
    this.setState({ rating: newRating, displayRating: newRating });
  }

  updateBody(event) {
    this.setState({ body: event.target.value });

    this.checkSellerReviewWarning(event.target.value);
  }

  updateTitle(event) {
    this.setState({ title: event.target.value });
  }

  resetDisplayRating() {
    this.setState({ displayRating: this.state.rating });
  }

  review() {
    return new ProductReview({
      rating: this.state.rating,
      title: this.state.title,
      body: this.state.body,
      link: this.props.saveLink,
      saveMethod: this.props.saveMethod,
    });
  }

  valid() {
    const review = this.review();
    const valid = review.valid();
    this.setState({ bodyError: review.errors.body, ratingError: review.errors.rating });
    return valid;
  }

  errorMessage() {
    return this.state.ratingError || this.state.bodyError;
  }

  submitForm(e) {
    e.preventDefault();

    if (this.valid()) {
      const save = this.review().save();
      save.done(response => this.props.submitSuccess(response));
      save.fail(response => this.props.submitFail(response));
    }
  }

  checkSellerReviewWarning(body) {
    if (body && this.props.showSellerReviewWarning) {
      let didMatch = false;
      SELLER_REVIEW_HINTS.forEach((word) => {
        const regex = new RegExp(/\b/.source + word + /\b/.source, 'i');
        const matches = body.match(regex);

        didMatch = didMatch || !!matches;
      });

      this.setState({ showSellerReviewWarning: didMatch });
    }
  }

  renderSellerReviewWarning() {
    if (!this.state.showSellerReviewWarning) { return false; }

    return (
      <p className="alert alert-warn">
        {I18n.t('js.productReviews.sellerReviewWarning')}
      </p>
    );
  }

  render() {
    const wrapperClass = classNames(
      'write-a-review-form',
      { 'write-a-review-form--open': this.props.isOpen },
    );

    return (
      <div className={wrapperClass}>
        <div className="write-a-review">
          <div className="g-container">
            <div className="g-col-8 g-col-mobile-12 mobile-mb-2">
              <form onSubmit={e => this.submitForm(e)}>
                <RCStarRatingSelector
                  rating={this.state.displayRating}
                  onSelect={this.updateRating}
                  inputName="product-review-star-rating"
                  inputId="star-rating-write-a-review-form"
                  label={I18n.t('js.productReviews.yourRating')}
                  errorText={this.state.ratingError}
                />
                <RCTextInput
                  label={I18n.t('js.productReviews.reviewTitle')}
                  onChange={e => this.updateTitle(e)}
                  maxLength={90}
                  value={this.state.title}
                />
                <RCTextArea
                  label={I18n.t('js.productReviews.reviewBody')}
                  value={this.state.body}
                  errorText={this.state.bodyError}
                  onChange={e => this.updateBody(e)}
                />
                {this.renderSellerReviewWarning()}
                <div className="d-flex fx-align-center mobile-d-block">
                  <RCButton
                    isSubmit
                    variant="filled"
                    size="small"
                  >
                    {this.props.submitText}
                  </RCButton>
                  <div className="ml-2 mobile-ml-0 mobile-mt-2">
                    <RCButton
                      onClick={() => this.props.onClose()}
                      variant="transparent"
                      size="small"
                    >
                      {I18n.t('js.productReviews.cancel')}
                    </RCButton>
                  </div>
                </div>

              </form>
            </div>
            <div className="g-col-4 g-col-mobile-12">
              <p className="strong mb-1">
                {I18n.t('js.productReviews.reviewTips')}
                <span className="small normal">
                  <a
                    className="ml-1"
                    href="/page/product-review-guidelines"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {I18n.t('js.productReviews.reviewGuidelines')}
                  </a>
                </span>
              </p>
              <ul className="size-80 fa-ul">
                <li>
                  <span className="fa fa-check fa-li color-green" />
                  <strong className="mr-1">{I18n.t('js.productReviews.DoLabel')}</strong>
                  {I18n.t('js.productReviews.DoMessage')}
                </li>
                <li>
                  <span className="fa fa-close fa-li color-red" />
                  <strong className="mr-1">{I18n.t('js.productReviews.DontLabel')}</strong>
                  {I18n.t('js.productReviews.DontMessage')}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

ReviewForm.propTypes = {
  rating: PropTypes.number,
  body: PropTypes.string,
  title: PropTypes.string,
  errorMessage: PropTypes.string,
  saveLink: PropTypes.string,
  submitSuccess: PropTypes.func.isRequired,
  submitFail: PropTypes.func.isRequired,
  submitText: PropTypes.string,
  saveMessage: PropTypes.string,
  showSellerReviewWarning: PropTypes.bool,
  saveMethod: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

ReviewForm.defaultProps = {
  rating: 0,
};

export default ReviewForm;
