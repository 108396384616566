import React from 'react';

interface IProps {
  classes: string;
  buttonText: string;
  onClick: (event) => void;
}

export default class CreditCardFormToggleButton extends React.Component<IProps, null> {
  render() {
    return (
      // eslint-disable-next-line jsx-a11y/anchor-is-valid
      (<a
        className={this.props.classes}
        onClick={this.props.onClick}
      >
        {this.props.buttonText}
      </a>)
    );
  }
}
