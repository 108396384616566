import React from 'react';
import { ListingsCollectionFragment } from '../gql/graphql';
import { RCText, RCListingCard, RCPriceBlock, useMediaQuery } from '@reverbdotcom/cadence/components';
import { PlaceholderListingRowCard } from './listing_row_card';
import { listingItemPagePath } from '../url_helpers';
import classNames from 'classnames';
import { WatchBadge, DisplayStyle } from './watch_badge';
import {
  buildListingForBuyer,
  getA11yPriceDescription,
} from '../listing_helpers';
import { trackEvent, buildListingClickEvent } from '@reverbdotcom/commons/src/elog/mparticle_tracker';
import ImageCarousel from '@reverbdotcom/commons/src/components/image_carousel';

const DEFAULT_MAX_COUNT = 8;
const MIN_COUNT = 5;
const DEFAULT_COLUMN_COUNT = 4;
export const PATTERNS = {
  '9-tile-center-focus': ['small', 'small', 'large', 'small', 'small', 'small', 'small', 'small', 'small'],
  '8-tile-grid': ['small', 'small', 'small', 'small', 'small', 'small', 'small', 'small'],
  '5-tile-left-focus': ['large', 'small', 'small', 'small', 'small'],
  '5-tile-center-focus': ['small', 'small', 'large', 'small', 'small'],
};

export interface MosaicPatternProps {
  desktop?: ('5-tile-left-focus' | '5-tile-center-focus' | '8-tile-grid' | '9-tile-center-focus');
  mobile?: ('5-tile-left-focus' | '5-tile-center-focus' | '8-tile-grid' | '9-tile-center-focus');
}

export interface ColSpanProps {
  desktop?: number;
  tablet?: number;
  mobile?: number;
}

type Listing = ListingsCollectionFragment;

interface Props {
  listings?: Listing[];
  loading?: boolean;
  handleCreateWatchResult?: () => void;
  parentComponentName?: string;
  displayMobileMosaic?: boolean;
  maxCountOverride?: number;
  mosaicPattern?: MosaicPatternProps;
  colCount?: ColSpanProps;
  parentComponentId?: string;
}

const DEFAULT_MOSAIC_PATTERN = '5-tile-left-focus';
const DEFAULT_MOSAIC_PATTERN_MWEB = '8-tile-grid';

export default function MosaicListingsCollection(props: Props) {
  const listings = props.listings.slice(0, props.maxCountOverride || DEFAULT_MAX_COUNT);
  const isMobile = useMediaQuery('mobile');

  if (listings.length < MIN_COUNT) { return null; }

  const gridClasses = classNames(
    'mosaic-listings-collection__grid',
    `mosaic-listings-collection__grid--col-d-${props.colCount?.desktop || DEFAULT_COLUMN_COUNT}`,
    props.colCount?.tablet && `mosaic-listings-collection__grid--col-t-${props.colCount.tablet || DEFAULT_COLUMN_COUNT}`,
    props.colCount?.mobile && `mosaic-listings-collection__grid--col-m-${props.colCount.mobile || DEFAULT_COLUMN_COUNT}`,
    {
      'mosaic-listings-collection__adjust-grid': props.listings.length <= 6 && !props.displayMobileMosaic,
      'mosaic-listings-collection__adjust-grid-mobile-mosaic': props.listings.length <= 6 && props.displayMobileMosaic,
    },
  );

  function displayPattern() {
    if (isMobile) {
      const mobilePattern = props.mosaicPattern?.mobile || DEFAULT_MOSAIC_PATTERN_MWEB;
      return PATTERNS[mobilePattern];
    } else {
      const desktopPattern = props.mosaicPattern?.desktop || DEFAULT_MOSAIC_PATTERN;
      return PATTERNS[desktopPattern];
    }
  }

  function renderGrid() {
    const mosaicPattern = displayPattern();
    return (
      <ul className={gridClasses}>
        {listings.map(function (listing, index) {
          const isSmall = mosaicPattern[index] === 'small';
          const isLarge = mosaicPattern[index] === 'large';
          const isDisplayNone = !mosaicPattern[index];
          const itemClasses = classNames(
            {
              'mosaic-listings-collection__item': !props.displayMobileMosaic,
              'grid-col-span-2 grid-row-span-2 mobile-grid-col-span-1 mobile-grid-row-span-1': isLarge && !props.displayMobileMosaic,
              'grid-col-span-1 grid-row-span-1': isSmall && !props.displayMobileMosaic,
              'd-none mobile-d-block': isDisplayNone && !props.displayMobileMosaic,
            },
            {
              'mosaic-listings-collection__item-mobile-mosaic': props.displayMobileMosaic,
              'grid-col-span-2 grid-row-span-2 mobile-grid-col-span-2 mobile-grid-row-span-2': isLarge && props.displayMobileMosaic,
              'grid-col-span-1 grid-row-span-1 mobile-grid-col-span-1': isSmall && props.displayMobileMosaic,
              'd-none': isDisplayNone && props.displayMobileMosaic,
            },
          );
          return (
            <li
              className={itemClasses}
              key={listing.id}
            >
              {renderCard(listing, index)}
            </li>
          );
        })}
      </ul>
    );
  }

  function trackOnClick(listing: Listing, index: number) {
    trackEvent(
      buildListingClickEvent({
        listing: listing,
        componentName: props.parentComponentName,
        cmsComponentId: props.parentComponentId,
        displayStyle: 'mosaic',
        position: index,
        countryCode: listing.shop.address?.country?.countryCode,
      }),
    );
  }

  function renderCard(listing: Listing, index: number) {
    if (props.loading) {
      return (<PlaceholderListingRowCard />);
    }

    const decoratedListing = buildListingForBuyer(listing);
    const listingImages = listing.largeSquareImages.map(i => i.source);
    const useUpdatedPriceDesign = props.parentComponentName === 'LoggedOutHomepageMostWatched' && !isMobile;

    const violator = (
      <div
        className="bd-radius-md padding-1 overflow-hidden"
        style={{ backgroundColor: 'var(--rc-color-highlight-800)' }}
      >
        <RCPriceBlock
          priceDisplay={decoratedListing.primaryPriceDisplay}
          discountPriceDisplay={decoratedListing.discountedPriceDisplay}
          a11yPriceDescription={getA11yPriceDescription(decoratedListing)}
          inlineDiscountText={useUpdatedPriceDesign}
          highlightDiscountedPrice
          smallText
        />
        {useUpdatedPriceDesign &&
          <RCText color="rc-color-text-primary" maxLines={1}>{listing.title}</RCText>
        }
      </div>
    );

    return (
      <RCListingCard
        label={listing.title}
        violator={violator}
        linkElement={(children) => (
          <a
            href={listingItemPagePath(listing)}
            target="_blank"
            rel="noreferrer"
            onClick={() => trackOnClick(listing, index)}
          >
            {children}
          </a>
        )}
        thumbnailElement={
          <ImageCarousel
            images={listingImages}
            imgAlt={listing.title}
          />
        }
        favoriteButtonElement={
          <WatchBadge
            listing={listing}
            displayStyle={DisplayStyle.RC_FAVORITE_BUTTON}
            parentComponentName={props.parentComponentName}
            handleCreateWatchResult={props.handleCreateWatchResult}
            useToast
          />
        }
      />
    );
  }

  return (
    renderGrid()
  );
}
