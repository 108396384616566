import React from 'react';
import URLHelpers from '../../shared/url_helpers';
import CoreLink from '@reverbdotcom/commons/src/components/core_link';
import { RCButton } from '@reverbdotcom/cadence/components';
import I18n from 'i18n-js';
import { Listing } from '@reverbdotcom/commons/src/gql/graphql';
import { MParticleEventName, trackEvent } from '@reverbdotcom/commons/src/elog/mparticle_tracker';

interface IListingStateProps {
  listing: Listing;
}

export const COMPONENT_NAME = 'RelistButton';

function RelistButton({ listing }: IListingStateProps) {
  return (
    <CoreLink
      to={URLHelpers.sellFormEditListingPath(listing.id, {
        section: 'review',
      })}
      onClick={() => {
        trackEvent({
          eventName: MParticleEventName.ClickedComponent,
          componentName: COMPONENT_NAME,
          listingId: listing.id,
        });
      }}
    >
      <RCButton variant="outlined" fullWidth>
        {I18n.t('discovery.listingsManagement.sellerListingCard.relist')}
      </RCButton>
    </CoreLink>
  );
}

export default RelistButton;
