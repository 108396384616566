import { ChildProps } from '@apollo/client/react/hoc';
import { MutationFunction } from '@reverbdotcom/commons/src/useHOCMutation';
// TODO update this to import from commons
// import { gql } from '@reverbdotcom/commons/src/gql';
// eslint-disable-next-line no-restricted-imports
import { gql } from '@apollo/client';
import React from 'react';
import I18n from 'i18n-js';
import { withGraphql } from '@reverbdotcom/commons/src/with_graphql';
import {
  CoreDashboardEuOdrAddress,
  CoreDashboardUpdateShopEuAddress,
  core_apimessages_MyAddressType,
  Input_core_apimessages_UpdateAddressRequest,
  core_apimessages_Error,
  DeleteEuAddress,
} from '@reverbdotcom/commons/src/gql/graphql';
import ModalDialog from '@reverbdotcom/commons/src/components/modal_dialog';
import AddressRow from '../../addresses/address_row';
import AddressForm from '../../addresses/address_form';
import { IAddressFormData } from '../../addresses/address_form_data';
import { parseMutationErrors } from '@reverbdotcom/commons/src/parse_graphql_errors';
import { connect } from '@reverbdotcom/commons/src/connect';
import { mapAddressToEntry } from '../../../lib/addressEntryMapping';

interface ExternalProps {
  shopSlug: string;
}

interface MutationProps {
  upsertEuAddressMutation?: MutationFunction<CoreDashboardUpdateShopEuAddress.Mutation, CoreDashboardUpdateShopEuAddress.Variables>;
  deleteEuAddressMutation?: MutationFunction<DeleteEuAddress.Mutation, DeleteEuAddress.Variables>;
}

type Props = ChildProps<ExternalProps & MutationProps, CoreDashboardEuOdrAddress.Query, CoreDashboardUpdateShopEuAddress.Mutation>;

async function deleteEuAddress(props: Props, handleDeleteSuccess: Function, uuid: string) {
  const updateData: Input_core_apimessages_UpdateAddressRequest = {
    addressType: core_apimessages_MyAddressType.EU_ODR_PLATFORM,
    uuid,
  };

  await props.deleteEuAddressMutation({
    variables: { input: updateData },
    refetchQueries(_mutationResult) {
      return [{
        query: euOdrAddressGQL,
        variables: {
          slug: props.shopSlug,
        },
      }];
    },
    awaitRefetchQueries: true,
  });

  handleDeleteSuccess();
}

async function upsertEuAddress(props: Props, addressFormData: IAddressFormData, handleSuccess: Function, handleErrors: Function) {
  const updateData: Input_core_apimessages_UpdateAddressRequest = {
    addressType: core_apimessages_MyAddressType.EU_ODR_PLATFORM,
    address: mapAddressToEntry(addressFormData),
  };

  try {
    await props.upsertEuAddressMutation({
      variables: { input: updateData },
      refetchQueries(_mutationResult) {
        return [{
          query: euOdrAddressGQL,
          variables: {
            slug: props.shopSlug,
          },
        }];
      },
      awaitRefetchQueries: true,
    });

    handleSuccess();

  } catch (e) {
    handleErrors(e);
  }
}

export function EuOdrDetailsDashboardContainer(props: Props) {
  const { loading, shop, countries } = props.data;

  const [modalOpen, setModalOpen] = React.useState(false);
  const [isSaving, setIsSaving] = React.useState(false);
  const [addressFormData, setAddressFormData] = React.useState<IAddressFormData>({} as IAddressFormData);
  const [errors, setErrors] = React.useState<core_apimessages_Error[]>([]);

  let addressForm = null;

  React.useEffect(() => {
    if (!shop || !shop.euOdrAddress) {
      return;
    }

    const { euOdrAddress } = shop;

    setAddressFormData({
      name: euOdrAddress.name,
      streetAddress: euOdrAddress.streetAddress,
      postalCode: euOdrAddress.postalCode,
      phone: euOdrAddress.phone,
      region: euOdrAddress.region,
      locality: euOdrAddress.locality,
      countryCode: euOdrAddress.countryCode,
      email: euOdrAddress.email,
    } as IAddressFormData);
  }, [shop]);

  function toggleOpen(): void {
    setModalOpen(!modalOpen);
  }

  function handleSuccess(): void {
    toggleOpen();
    setIsSaving(false);
    setErrors([]);
  }

  function handleError(e): void {
    setErrors(parseMutationErrors(e));
    setIsSaving(false);
  }

  function handleSubmit(): void {
    if (isSaving) {
      return;
    }

    const form = addressForm as HTMLFormElement;
    if (form && form.reportValidity && !form.reportValidity()) { return; }

    setIsSaving(true);

    upsertEuAddress(
      props,
      addressFormData,
      handleSuccess,
      handleError,
    );
  }

  function handleDelete(): void {
    if (isSaving) {
      return;
    }

    setIsSaving(true);

    deleteEuAddress(
      props,
      handleSuccess,
      shop.euOdrAddress?.uuid,
    );
  }

  function deleteCallback() {
    if (shop?.euOdrAddress) {
      return handleDelete;
    }
    return null;
  }

  function handleUpdateField(addressAttributes: Partial<IAddressFormData>) {
    setAddressFormData({ ...addressFormData, ...addressAttributes });
  }

  function handleAddNewAddress(e): void {
    e.preventDefault();
    toggleOpen();
  }

  function setAddressFormRef(ref) {
    addressForm = ref;
  }

  if (loading || !shop) {
    return null;
  }

  if (!shop.euOdrAddress && !modalOpen) {
    return (
      <button data-add-address-button
        onClick={(e) => handleAddNewAddress(e)}
        className="button"
        type="button"
      >
        {I18n.t('discovery.addressBook.addNewEuOdrAddressBtn')}
      </button>
    );
  }

  return (
    <>
      <ul className="actionable-rows">
        {shop.euOdrAddress && <AddressRow
          address={shop.euOdrAddress}
          onEdit={toggleOpen}
          addressType={core_apimessages_MyAddressType.EU_ODR_PLATFORM}
        />}
      </ul>
      <ModalDialog
        headerTitle={I18n.t('discovery.addressBook.form.title.euOdrSellerDetails')}
        isOpen={modalOpen}
        onRequestClose={toggleOpen}
        onDelete={deleteCallback()}
        onSubmit={handleSubmit}
        isSaving={isSaving}
        backButtonText={I18n.t('discovery.checkout.addressBook.cancel')}
        containsFormElement
      >
        <AddressForm
          addressFormData={addressFormData}
          countries={countries.countries}
          addressType={core_apimessages_MyAddressType.EU_ODR_PLATFORM}
          onUpdateField={handleUpdateField}
          addressIsCurrentPrimary={false}
          errors={errors}
          formRef={setAddressFormRef}
        />
      </ModalDialog>
    </>
  );
}

const upsertEuAddressMutation =
  withGraphql<{}, CoreDashboardUpdateShopEuAddress.Mutation, CoreDashboardUpdateShopEuAddress.Variables>(
    gql`
      mutation Core_Dashboard_UpdateShopEuAddress($input: Input_core_apimessages_UpdateAddressRequest) {
        updateMyAddress(input: $input) {
          address {
            _id
            uuid
          }
        }
      }
    `,
    {
      name: 'upsertEuAddressMutation',
    },
  );

const deleteEuAddressMutation =
  withGraphql<{}, DeleteEuAddress.Mutation, DeleteEuAddress.Variables>(
    gql`
      mutation DeleteEuAddress($input: Input_core_apimessages_AddressDeleteRequest) {
        deleteMyAddress(input: $input) {
          uuid
        }
      }
    `,
    {
      name: 'deleteEuAddressMutation',
    },
  );

const euOdrAddressGQL = gql`
query Core_Dashboard_EuOdrAddress(
  $slug: String
) {
  countries {
    countries {
      _id
      name
      countryCode
      subregions {
        _id
        name
        code
      }
    }
  }
  shop(input: {slug: $slug}) {
    _id
    euOdrAddress {
      _id
      name
      streetAddress
      displayLocation
      postalCode
      email
      phone
      countryCode
      country {
        _id
        countryCode
        name
      }
      region
      locality
      uuid
    }
  }
}
`;

const euOdrAddressQuery = withGraphql<ExternalProps, CoreDashboardEuOdrAddress.Query>(
  euOdrAddressGQL,
  {
    options: (ownProps) => {
      return {
        errorPolicy: 'all',
        ssr: false, // not important for SEO or initial page rendering
        variables: {
          slug: ownProps.shopSlug,
        },
      };
    },
  },
);

export default connect<ExternalProps>([
  upsertEuAddressMutation,
  deleteEuAddressMutation,
  euOdrAddressQuery,
])(EuOdrDetailsDashboardContainer);
