// TODO update this to import from commons/src/gql
// eslint-disable-next-line no-restricted-imports
import { gql } from '@apollo/client';
import React from 'react';

import experiments from '../../experiments';
import ListingsCollection from '../../components/listings_collection';
import { MParticleEventName } from '../../elog/mparticle_tracker';
import { isExperimentEnabled } from '../../user_context_helpers';
import SanitizedRender from '../../components/sanitized_render';
import { IDynamicComponentProps } from '../dynamic_component_props';
import {
  Commons_Cms_PersonalizedListingsCollectionQuery,
  Commons_Cms_PersonalizedListingsCollectionQueryVariables,
  reverb_search_ListingsSearchRequest_Sort,
  reverb_search_ListingsSearchRequest as ListingsSearchRequest,
  reverb_search_ListingsSearchRequest_Collapsible,
} from '../../gql/graphql';
import { IUser } from '../../components/user_context_provider';
import { useViewTracking } from '../../use_tracking';
import { mParticleListingFields } from '../../components/with_mparticle_listings';
import { userContextIsTrackable } from '../../elog/mparticle';
import {
  localizeListingsRowsFilters,
  showLocalizedListingContent,
} from '../../localize_listing_rows_utils';
import { ListingsCollectionFragments } from '../../components/listings_collection_fragments';
import { useQuery } from '../../useQuery';
import { useUser } from '../../user_hooks';
import { B_STOCK, BRAND_NEW, EXCELLENT, GOOD, MINT, VERY_GOOD } from '../../condition_helpers';

const COMPONENT_NAME = 'PersonalizedListingsCollection';

const CP_RECS_LIMIT = 100;
const DEFAULT_MIN_COUNT = 1;
const DEFAULT_MAX_COUNT = 12;

export interface IProps extends IDynamicComponentProps {
  holidaySale?: boolean;
  maxCount?: number;
  minCount?: number;
  title?: string;
  titleHtml?: string;
  trackingName?: string;
}

function getItemRegionFilters(user): ListingsSearchRequest {
  return { itemRegion: user.countryCode, ...localizeListingsRowsFilters(user) };
}

function getMaxCount({ maxCount }: IProps): number {
  return maxCount || DEFAULT_MAX_COUNT;
}

export function PersonalizedListingsCollection(props: IProps) {
  const user = useUser();

  const {
    loading,
    data,
  } = useQuery<Commons_Cms_PersonalizedListingsCollectionQuery, Commons_Cms_PersonalizedListingsCollectionQueryVariables>(
    personalizedListingsCollectionQuery,
    buildPersonalizedListingsCollectionVars(user, props),
  );

  const minCount = props.minCount || DEFAULT_MIN_COUNT;
  const componentName = props.trackingName || COMPONENT_NAME;
  const listings = data?.cpToListingRecs?.searchResponse?.listings || [];

  const shouldNotRender = !loading && listings.length < minCount;

  useViewTracking({
    eventName: MParticleEventName.ComponentView,
    componentName: componentName,
    cmsComponentId: props.componentId,
    listingsCount: listings.length,
    title: props.title,
  }, !(loading || shouldNotRender));

  if (shouldNotRender) {
    return null;
  }

  return (
    <ListingsCollection
      componentName={componentName}
      cmsComponentId={props.componentId}
      listings={listings}
      loading={loading}
      maxCount={getMaxCount(props)}
      title={
        <SanitizedRender
          html={props.titleHtml || props.title}
        />
      }
      titleString={props.title}
      showFlags={showLocalizedListingContent(user)}
    />
  );
}

export const personalizedListingsCollectionQuery = gql`
  query Commons_Cms_PersonalizedListingsCollection(
    $collapsible: reverb_search_ListingsSearchRequest_Collapsible
    $conditionSlugs: [String]
    $holidaySale: Boolean
    $itemRegion: String
    $itemRegionRelation: reverb_search_ListingItemRegionRelation
    $listingsLimit: Int
    $recsLimit: Int
    $shippingRegionCodes: [String]
    $shouldSkipTracking: Boolean!
    $showListingLocation: Boolean!
    $sort: reverb_search_ListingsSearchRequest_Sort
  ) {
    cpToListingRecs(input: {
      limit: $recsLimit
      listingsSearchRequest: {
        collapsible: $collapsible
        conditionSlugs: $conditionSlugs
        holidaySale: $holidaySale
        itemRegion: $itemRegion
        itemRegionRelation: $itemRegionRelation
        limit: $listingsLimit
        shippingRegionCodes: $shippingRegionCodes
        sort: $sort
      }
    }) {
      searchResponse {
        limit
        total
        listings {
          _id
          ... ListingsCollection
          ... mParticleListingFields @skip(if: $shouldSkipTracking)
          ... ShopFields @include(if: $showListingLocation)
        }
      }
    }
  }
  ${mParticleListingFields}
  ${ListingsCollectionFragments.listing}
  ${ListingsCollectionFragments.shopFields}
`;

export function buildPersonalizedListingsCollectionVars(user: Partial<IUser>, ownProps: IProps) {
  const listingsLimit = getMaxCount(ownProps);

  return {
    ssr: ownProps.ssr,
    variables: {
      collapsible: reverb_search_ListingsSearchRequest_Collapsible.CANONICAL_PRODUCT_ID,
      conditionSlugs: [BRAND_NEW, B_STOCK, MINT, EXCELLENT, VERY_GOOD, GOOD],
      holidaySale: ownProps.holidaySale,
      ...getItemRegionFilters(user),
      listingsLimit,
      recsLimit: CP_RECS_LIMIT,
      shippingRegionCodes: [user?.shippingRegionCode],
      shouldSkipTracking: !userContextIsTrackable(user),
      showListingLocation: isExperimentEnabled(user, experiments.LISTING_CARD_LOCATION),
      sort: reverb_search_ListingsSearchRequest_Sort.LIKELIHOOD_TO_SELL,
    },
  };
}

export default PersonalizedListingsCollection;
