import React from 'react';
import I18n from 'i18n-js';
import classNames from 'classnames';

import ControlledInput from './controlled_input';

interface IProps {
  inputName: string;
  inputTitleValue?: string; // This is the title attribute for the input, not a label
  label: string | JSX.Element | React.ReactNode;
  value: string;

  checked?: boolean;
  updateField?: Function;
  fieldName?: string;

  id?: string;
  required?: boolean;
  disabled?: boolean;
  tagOptional?: boolean;
  tagRecommended?: boolean;
  tagRequired?: boolean;
  errorText?: string;
  helpText?: string | JSX.Element | React.ReactNode;
}

export default class FormGroupWithRadio extends React.Component<IProps, null> {
  shouldBeControlled() {
    return !!this.props.updateField;
  }

  getTagText() {
    if (this.props.tagOptional) {
      return I18n.t('commons.formGroup.optional');
    }

    if (this.props.tagRecommended) {
      return I18n.t('commons.formGroup.recommended');
    }

    if (this.props.tagRequired) {
      return I18n.t('commons.formGroup.required');
    }
  }

  renderTag() {
    if (!this.getTagText()) return;

    return (
      <div className="d-inline-block">
        <div className="form-group__tag">
          {this.getTagText()}
        </div>
      </div>
    );
  }

  renderError() {
    if (!this.props.errorText) return;

    return (
      <div className="form-group__error">
        {this.props.errorText}
      </div>
    );
  }

  renderUncontrolledRadio() {
    return (
      <input
        type="radio"
        name={this.props.inputName}
        id={this.props.id || this.props.inputName}
        required={this.props.required}
        disabled={this.props.disabled}
        value={this.props.value}
        title={this.props.inputTitleValue}
      />
    );
  }

  renderControlledRadio() {
    return (
      <ControlledInput
        inputType="radio"
        inputName={this.props.inputName}
        inputValue={this.props.value}
        checked={this.props.checked}
        updateField={this.props.updateField}
        fieldName={this.props.fieldName || this.props.inputName}
        required={this.props.required}
        disabled={this.props.disabled}
        id={this.props.id}
        inputTitleValue={this.props.inputTitleValue}
      />
    );
  }

  render() {
    const classes = classNames(
      'form-group',
      'form-group--with-radio',
      { 'form-group--with-error': !!this.props.errorText },
    );

    return (
      <div className={classes}>
        <div className="label-with-radio">
          {this.shouldBeControlled() && this.renderControlledRadio()}
          {!this.shouldBeControlled() && this.renderUncontrolledRadio()}
          <label
            htmlFor={this.props.id || this.props.inputName}
          >
            <div>
              <div className="form-group__label-text">
                {this.props.label}
              </div>
              {this.renderTag()}
              {this.renderError()}
            </div>
          </label>
        </div>
        {this.props.children}
        {!!this.props.helpText &&
          <div className="form-group__help-text">
            {this.props.helpText}
          </div>
        }
      </div>
    );
  }
}
