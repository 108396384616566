/* eslint max-len: ["error", 200] */

import React from 'react';

import { Route } from '@reverbdotcom/commons/src/route_settings';
import { MParticlePageName } from '@reverbdotcom/commons/src/elog/mparticle_tracker';
import MarketplaceRoute from './routes/marketplace';
import { BrandRoute } from './routes/brand';
import CSPIndexRoute from './routes/csp_index';
import SaleRoute from './routes/sale';
import {
  ItemShowRoute,
  ItemPreviewRoute,
  ItemPreviewConfirmRoute,
} from './routes/item_show';
import HandpickedRoute from './routes/handpicked';
import CollectionRoute from './routes/collection';
import { EndedWatchedProductsRoute, PublicEndedWatchedProductsRoute } from './routes/ended_watched_products';
import RecentlyViewedRoute from './routes/recently_viewed';
import { WatchedProductsRoute, PublicWatchedProductsRoute } from './routes/watched_products';
import FeedCustomizationRoute from './routes/feed_customization';
import HomepageRoute from './routes/homepage';
import OnboardingRoutes from './routes/progressive_onboarding';
import { CmsRoutes } from './routes/cms_route';
import {
  CoreIdentityVerificationRoute,
} from './routes/identity_verification';
import HolidaySaleRoute from './routes/holiday_sale';
import { ShopRoutes } from './routes/shop';
import { PRICE_GUIDE_ROUTE } from './components/shared/url_helpers';
import { CheckoutAnalyticsRoutes, CheckoutRoutes } from './routes/checkout';
import TrackArticle from '@reverbdotcom/commons/src/components/track_article_page_view';
import {
  MyFavoritesLandingRoute,
  MyFavoritesWatchListRoute,
  MyFavoritesWatchListEndedRoute,
  MyFavoritesSearchesRoute,
  MyFavoritesShopsRoute,
  MyFavoritesFeedRoute,
  MyFavoritesDiscoverRoute,
} from './routes/my_favorites';
import { BrowsePageRoutes } from './routes/browse_page_routes';

/*
 * All routes for the ReverbRouter are defined here. These are exported
 * separately so they can be shared between the client and server side
 * Router.
 *
 * Note: All routes here are defined with a relative route to support international
 * prefix routing, which is defined below in the actual export
 *
 * Please add a key to allow React to do some fancy DOM diffing (and to prevent an error in console)
 */
const Routes = [
  <Route
    key="admin/_frontend_error"
    path="admin/_frontend_error"
    props={{
      clientSide: true,
    }}
    getComponent={(_location, cb) => {
      import(
        /* webpackChunkName: 'marketplace' */
        './components/frontend_error'
      ).then(m => cb(null, m.default));
    }}
  />,
  <Route
    key="my/selling/bump-listing/:listingId"
    path="my/selling/bump-listing/:listingId"
    props={{
      clientSide: false,
      pageViewEvent: {
        pageName: MParticlePageName.MobileBump,
      },
    }}
    getComponent={(_location, cb) => {
      import(
        /* webpackChunkName: 'mobile-bump-listing' */
        './components/bump/mobile_bump_container'
      ).then(m => cb(null, m.default));
    }}
  />,
  <Route
    key="my/selling/advertising"
    path="my/selling/advertising"
    props={{
      clientSide: false,
      pageViewEvent: {
        pageName: MParticlePageName.SellerDashboard,
      },
    }}
    getComponent={(_location, cb) => {
      import(
        /* webpackChunkName: 'seller-advertising-dashboard' */
        './components/seller_dashboard/seller_dashboard_container'
      ).then(m => cb(null, m.default));
    }}
  />,
  <Route
    key="my/collection"
    path="my/collection"
    getComponent={(_location, cb) => {
      import(
        /* webpackChunkName: 'gear_collections' */
        './components/gear_collections/gear_collection_index_layout'
      ).then(m => cb(null, m.default));
    }}
  />,
  <Route
    key="my/collection/new"
    path="my/collection/new"
    getComponent={(_location, cb) => {
      import(
        /* webpackChunkName: 'gear_collections' */
        './components/gear_collections/form/create_collection_item_form_wrapper'
      ).then(m => cb(null, m.default));
    }}
  />,
  <Route
    key="my/collection/search"
    path="my/collection/search"
    getComponent={(_location, cb) => {
      import(
        /* webpackChunkName: 'gear_collections' */
        './components/gear_collections/csp_search/GearCollectionCspSearch'
      ).then(m => cb(null, m.default));
    }}
  />,
  <Route
    key="my/collection/:gearCollectionItemId"
    path="my/collection/:gearCollectionItemId"
    getComponent={(_location, cb) => {
      import(
        /* webpackChunkName: 'gear_collections' */
        './components/gear_collections/show/gear_collection_item_show_layout'
      ).then(m => cb(null, m.default));
    }}
  />,
  <Route
    key="my/collection/:gearCollectionItemId/edit"
    path="my/collection/:gearCollectionItemId/edit"
    getComponent={(_location, cb) => {
      import(
        /* webpackChunkName: 'gear_collections' */
        './components/gear_collections/edit/GearCollectionItemEditLayout'
      ).then(m => cb(null, m.default));
    }}
  />,
  <Route
    key="trade-in"
    path="trade-in"
    props={{ clientSide: true }}
    getComponent={(_location, cb) => {
      import(
        /* webpackChunkName: 'express_sale' */
        './components/express_sale/index/express_sale_index'
      ).then(m => cb(null, m.default));
    }}
  />,
  <Route
    key="checkout/:checkoutId/trade-in"
    path="checkout/:checkoutId/trade-in"
    props={{ clientSide: true }}
    getComponent={(_location, cb) => {
      import(
        /* webpackChunkName: 'express_sale' */
        './components/express_sale/checkout/express_sale_checkout'
      ).then(m => cb(null, m.default));
    }}
  />,
  <Route
    key="feed"
    path="my/feed"
    props={{ clientSide: true }}
    getComponent={(_location, cb) => {
      import(
        /* webpackChunkName: 'feed' */
        './feed/feed_grid'
      ).then(m => cb(null, m.default));
    }}
  />,
  <Route
    key="my/recommendations"
    path="my/recommendations"
    props={{
      clientSide: true,
      pageViewEvent: {
        pageName: MParticlePageName.RecommendedListings,
      },
    }}
    getComponent={(_location, cb) => {
      import(
        /* webpackChunkName: 'recommendations' */
        './components/recommended_listings/recommended_listings_container'
      ).then(m => cb(null, m.default));
    }}
  />,
  <Route
    key="sell"
    path="sell"
    props={{
      pageViewEvent: ({ location }) => ({
        pageName: MParticlePageName.SellForm,
        promotionalToken: location.query.promotional_token as string,
      }),
    }}
  />,
  <Route
    key="sell/search"
    path="sell/search"
    props={{
      clientSide: true,
    }}
    getComponent={(_location, cb) => {
      import(
        /* webpackChunkName: 'sell' */
        './components/sell/sell_search'
      ).then(m => cb(null, m.default));
    }}
  />,
  <Route
    key="my/selling/listings/:listingId/edit"
    path="my/selling/listings/:listingId/edit"
    props={{
      clientSide: true,
    }}
    getComponent={(_location, cb) => {
      import(
        /* webpackChunkName: 'sell' */
        './components/sell/form/SellFormEditListingLayout'
      ).then(m => cb(null, m.default));
    }}
  />,
  <Route
    key="my/selling/listings/new"
    path="my/selling/listings/new"
    props={{
      clientSide: true,
      pageViewEvent: ({ location }) => ({
        pageName: MParticlePageName.SellForm,
        cspId: location.query.seed_id as string,
        promotionalToken: location.query.promotional_token as string,
      }),
    }}
    getComponent={(_location, cb) => {
      import(
        /* webpackChunkName: 'sell' */
        './components/sell/form/SellFormNewListingLayout'
      ).then(m => cb(null, m.default));
    }}
  />,
  <Route
    key="my/selling/listings/:listingId/cross_platform_start/v1"
    path="my/selling/listings/:listingId/cross_platform_start/v1"
    props={{ clientSide: true }}
    getComponent={(_location, cb) => {
      import(
        /* webpackChunkName: 'sell' */
        './components/sell/CrossPlatformMobilePhotoUploaderLayout'
      ).then(m => cb(null, m.default));
    }}
  />,
  <Route
    key="sell-form-setup"
    path="sell-form-setup"
    props={{
      clientSide: true,
    }}
    getComponent={(_location, cb) => {
      import(
        /* webpackChunkName: 'sell' */
        './components/sell/SellFormSetupPage'
      ).then(m => cb(null, m.default));
    }}
  />,
  <Route
    key="seller_verification/onboarding"
    path="seller_verification/onboarding"
    getComponent={(_location, cb) => {
      import(
        './onboarding/seller_verification/shop_setup_wrapper'
      ).then(m => cb(null, m.default));
    }}
  />,
  <Route
    key="item/:slug/similar"
    path="item/:slug/similar"
    props={{
      clientSide: true,
    }}
    getComponent={(_location, cb) => {
      import(
        /* webpackChunkName: 'similar-listings' */
        './components/discovery/similar_listings_grid'
      ).then(m => cb(null, m.default));
    }}
  />,
  <Route
    key="similar/:id"
    path="similar/:id"
    props={{
      clientSide: true,
    }}
    getComponent={(_location, cb) => {
      import(
        /* webpackChunkName: 'similar-listings' */
        './components/discovery/similar_listings_grid'
      ).then(m => cb(null, m.default));
    }}
  />,
  <Route
    key="my/selling/shipping_labels/new/:orderId"
    path="my/selling/shipping_labels/new/:orderId"
    getComponent={(_, cb) => {
      import(
        /* webpackChunkName: 'shipping-labels' */
        './components/discovery/shipping_labels/shipping_labels_form_container'
      ).then(m => cb(null, m.default));
    }}
  />,
  <Route
    key="my/selling/shipping_labels/:shipmentId"
    path="my/selling/shipping_labels/:shipmentId"
    getComponent={(_, cb) => {
      import(
        /* webpackChunkName: 'shipping-labels' */
        './components/discovery/shipping_labels/shipping_rates_form'
      ).then(m => cb(null, m.default));
    }}
  />,
  <Route
    key="my/selling/shipping_labels/:shipmentId/edit"
    path="my/selling/shipping_labels/:shipmentId/edit"
    getComponent={(_, cb) => {
      import(
        /* webpackChunkName: 'shipping-labels' */
        './components/discovery/shipping_labels/edit_shipment_container'
      ).then(m => cb(null, m.default));
    }}
  />,
  <Route
    key="price-guide"
    path={PRICE_GUIDE_ROUTE}
    getComponent={(_location, cb) => {
      import(
        /* webpackChunkName: 'price-guide' */
        './components/price_guides/price_guide_page'
      ).then(m => cb(null, m.default));
    }}
  />,
  <Route
    key="p/:slug/"
    props={{
      clientSide: true,
    }}
    path="p/:slug(/:condition)"
    getComponent={(_location, cb) => {
      import(
        /* webpackChunkName: 'csp' */
        './components/csp/csp_layout'
      ).then(m => cb(null, m.default));
    }}
  />,
  <Route
    key="page/:slug"
    path="page/:slug"
    props={{
      pageViewEvent: ({ params }) => ({
        pageName: MParticlePageName.Page,
        pageSlug: params.slug,
      }),
    }}
  />,
  <Route
    key="news"
    path="news"
    props={{
      pageViewEvent: ({ location }) => ({
        pageName: MParticlePageName.NewsHomepage,
        articleQuery: location.query.query,
      }),
    }}
  />,
  // /news/the-forgotten-guitars-of-jimi-hendrix/locale/ja
  // We have the optional /locale portion here because we
  // have a number of links in the wild that use this
  // routing patern.
  //
  // Our Rails router handles this case without a 404,
  // and renders the content in the specified "locale"
  //
  // This ensures that the React Router is at parity
  // https://sentry.io/organizations/reverb-llc/issues/1770671512/?project=1769470
  <Route
    key="news/:slug"
    path="news/:slug(/locale/:locale)"
    getComponent={(_location, cb) => {
      cb(null, TrackArticle);
    }}
  />,
  <Route
    key="my/selling/packlink_shipments/:orderId"
    path="my/selling/packlink_shipments/:orderId"
    getComponent={(_location, cb) => {
      import(
        /* webpackChunkName: 'mobile-packlink-layout' */
        './components/packlink_mobile_layout'
      ).then(m => cb(null, m.default));
    }}
  />,
  <Route
    key="my/selling/shipping_profiles/new"
    path="my/selling/shipping_profiles/new"
    props={{
      pageViewEvent: {
        pageName: MParticlePageName.CreateShippingProfileForm,
      },
    }}
  />,
  <Route
    key="my/selling/shop_campaign"
    path="my/selling/shop_campaign"
    getComponent={(_location, cb) => {
      import(
        /* webpackChunkName: 'shop_campaigns' */
        './components/selling/shop_campaigns/ShopCampaignSellerIndexLayout'
      ).then(m => cb(null, m.default));
    }}
  />,
  <Route
    key="my/selling/shop_campaign/new"
    path="my/selling/shop_campaign/new"
    getComponent={(_location, cb) => {
      import(
        /* webpackChunkName: 'shop_campaigns' */
        './components/selling/shop_campaigns/ShopCampaignSellerNewLayout'
      ).then(m => cb(null, m.default));
    }}
  />,
  <Route
    key="top/:slug/:child"
    path="top/:slug(/:child)"
    props={{ clientSide: true }}
    getComponent={(_location, cb) => {
      import(
        /* webpackChunkName: 'category_top_products' */
        './components/category_top_products'
      ).then(m => cb(null, m.default));
    }}
  />,
  <Route
    key="software(/:categorySlug)"
    path="software(/:categorySlug)"
    getComponent={(_location, cb) => {
      import(
        /* webpackChunkName: 'software' */
        './digital_listings/index_page'
      ).then(m => cb(null, m.default));
    }}
  />,
  <Route
    key="software/:categorySlug/:brandSlug/:digitalListingId"
    path="software/:categorySlug/:brandSlug/:digitalListingId"
    getComponent={(_location, cb) => {
      import(
        /* webpackChunkName: 'software' */
        './digital_listings/show_page'
      ).then(m => cb(null, m.default));
    }}
  />,
  <Route
    key="my/selling/orders"
    path="my/selling/orders"
    getComponent={(_location, cb) => {
      import(
        /* webpackChunkName: 'dashboard' */
        './dashboard/selling/orders/selling_orders_manager'
      ).then(m => cb(null, m.default));
    }}
  />,
  <Route
    key="my/buying/orders"
    path="my/buying/orders"
    getComponent={(_location, cb) => {
      import(
        /* webpackChunkName: 'dashboard' */
        './dashboard/buying/orders/buying_orders_manager'
      ).then(m => cb(null, m.default));
    }}
  />,
  <Route
    key="my/selling/listings"
    path="my/selling/listings"
    getComponent={(_location, cb) => {
      import(
        /* webpackChunkName: 'dashboard' */
        './dashboard/selling/listings_manager/ListingsManager'
      ).then(m => cb(null, m.default));
    }}
  />,
  <Route
    key="my/account"
    path="my/account"
    getComponent={(_location, cb) => {
      import(
        /* webpackChunkName: 'dashboard' */
        './dashboard/seller_hub/SellerHub'
      ).then(m => cb(null, m.default));
    }}
  />,
  <Route
    key="redeem"
    path="redeem" // Rails view, just used for pageViewEvent
    props={{
      clientSide: false,
      pageViewEvent: (routerState) => ({
        pageName: MParticlePageName.RedeemGiftCard,
        prefilled: !!routerState.location.query.token,
      }),
    }}
  />,
  <Route
    key="f/:brand/:product_family"
    path="f/:brand/:product_family"
    props={{ clientSide: false }}
    getComponent={(_location, cb) => {
      import(
        /* webpackChunkName: 'product-family' */
        './components/product_family/product_family_page'
      ).then(m => cb(null, m.default));
    }}
  />,
  <Route
    key="my/selling/tax_id"
    path="my/selling/tax_id" // Rails view, just used for pageViewEvent
    props={{
      clientSide: false,
      pageViewEvent: () => ({
        pageName: MParticlePageName.ShopTaxId,
      }),
    }}
  />,
  <Route
    key="outlet"
    path="outlet"
    props={{
      clientSide: true,
    }}
    getComponent={(_location, cb) => {
      import(
        /* webpackChunkName: 'outlet' */
        './components/outlet/outlet_layout'
      ).then(m => cb(null, m.default));
    }}
  />,
  <Route
    key="my/buying/trade_ins"
    path="my/buying/trade_ins"
    getComponent={(_location, cb) => {
      import(
        /* webpackChunkName: 'dashboard' */
        './dashboard/buying/trade_ins/trade_ins_manager'
      ).then(m => cb(null, m.default));
    }}
  />,
  <Route
    key="my/buying/trade_ins/:tradeInId"
    path="my/buying/trade_ins/:tradeInId"
    getComponent={(_location, cb) => {
      import(
        /* webpackChunkName: 'dashboard' */
        './dashboard/buying/trade_ins/trade_in_show'
      ).then(m => cb(null, m.default));
    }}
  />,
  <Route
    key="sell/listing-options"
    path="sell/listing-options"
    getComponent={(_location, cb) => {
      import(
        /* webpackChunkName: 'listing-options' */
        './components/sell/SellOptionsSelector'
      ).then(m => cb(null, m.default));
    }}
  />,
];

Routes.push(
  ShopRoutes,
  CSPIndexRoute,
  HomepageRoute,
  SaleRoute,
  ItemShowRoute,
  ItemPreviewRoute,
  ItemPreviewConfirmRoute,
  HandpickedRoute,
  BrandRoute,
  CollectionRoute,
  ...MarketplaceRoute,
  EndedWatchedProductsRoute,
  PublicEndedWatchedProductsRoute,
  FeedCustomizationRoute,
  MyFavoritesLandingRoute,
  MyFavoritesWatchListRoute,
  MyFavoritesWatchListEndedRoute,
  MyFavoritesSearchesRoute,
  MyFavoritesShopsRoute,
  MyFavoritesFeedRoute,
  MyFavoritesDiscoverRoute,
  RecentlyViewedRoute,
  WatchedProductsRoute,
  PublicWatchedProductsRoute,
  HolidaySaleRoute,
  CoreIdentityVerificationRoute,
  ...CmsRoutes,
  ...CheckoutRoutes,
  ...CheckoutAnalyticsRoutes,
  ...BrowsePageRoutes,
);
Routes.push(...OnboardingRoutes);

export default Routes;
