import React from 'react';
import { IconDisplay } from '../lib/payment_method_icons';
import classNames from 'classnames';

interface Props {
  icons: IconDisplay[];
  variant?: 'small' | 'default';
}

export function PaymentMethodIcons({
  icons,
  variant = 'default',
}: Props) {
  const classes = classNames(
    'payment-method__icons',
    { 'payment-method__icons--small': variant === 'small' },
  );

  return (
    <div className={classes}>
      {icons.map(icon => (
        <img className="payment-method__icon"
          src={icon.url}
          key={icon.slug}
          alt=""
        />
      ))}
    </div>
  );
}
