import React from 'react';
import I18n from 'i18n-js';
import classNames from 'classnames';
import { RCIcon, RCTooltip } from '@reverbdotcom/cadence/components';
import { PreferredSellerIcon, QuickShipperIcon, QuickResponderIcon } from '@reverbdotcom/cadence/icons/react';

interface ShopBadgeInterface {
  /** The icon for the badge, should come from RCIcon */
  icon: React.ReactNode;

  /** The i18n label key for the badge, i.e. "Preferred Seller" */
  labelKey: string;

  /** The i18n tooltip text key for the badge, i.e. "This shop is a preferred seller" */
  descriptionKey: string;
}

interface IShopBadgesProps {
  /** Shows the Preferred Seller badge */
  preferredSeller?: boolean;

  /** Shows the Quick Responder badge */
  quickResponder?: boolean;

  /** Shows the Quick Shipper badge */
  quickShipper?: boolean;

  /**
   * Uses CSS to visually hides labels on all badges
   * (labels still accessible for assistive technologies)
   */
  hideLabels?: boolean;

  /** Toggles icons between default and small sizes */
  smallBadges?: boolean;

  /** Renders list of badges vertically */
  stacked?: boolean;
}

export const PREFERRED_SELLER_BADGE: ShopBadgeInterface = {
  icon: <RCIcon svgComponent={PreferredSellerIcon} />,
  labelKey: 'commons.shopBadges.preferredSeller',
  descriptionKey: 'commons.shopBadges.preferredSellerDescription',
};

export const QUICK_RESPONDER_BADGE: ShopBadgeInterface = {
  icon: <RCIcon svgComponent={QuickResponderIcon} />,
  labelKey: 'commons.shopBadges.quickResponder',
  descriptionKey: 'commons.shopBadges.quickResponderDescription',
};

export const QUICK_SHIPPER_BADGE: ShopBadgeInterface = {
  icon: <RCIcon svgComponent={QuickShipperIcon} />,
  labelKey: 'commons.shopBadges.quickShipper',
  descriptionKey: 'commons.shopBadges.quickShipperDescription',
};

export function ShopBadges({
  preferredSeller = false,
  quickResponder = false,
  quickShipper = false,
  hideLabels = false,
  smallBadges = false,
  stacked = false,
}: IShopBadgesProps) {
  const badgeTiersToRender = [];

  if (preferredSeller) {
    badgeTiersToRender.push(PREFERRED_SELLER_BADGE);
  }

  if (quickResponder) {
    badgeTiersToRender.push(QUICK_RESPONDER_BADGE);
  }

  if (quickShipper) {
    badgeTiersToRender.push(QUICK_SHIPPER_BADGE);
  }

  const wrapperClasses = classNames(
    'shared-shop-badges',
    { 'shared-shop-badges--stacked': stacked },
  );

  const badgeClasses = classNames(
    'shared-shop-badge',
    { 'shared-shop-badge--small': smallBadges },
    { 'shared-shop-badge--hide-label': hideLabels },
  );

  if (badgeTiersToRender.length === 0) {
    return null;
  }

  return (
    <div className={wrapperClasses}>
      {badgeTiersToRender.map((badge) => (
        <RCTooltip
          text={I18n.t(badge.descriptionKey)}
          key={I18n.t(badge.labelKey)}
          type="plain"
        >
          <button
            type="button"
            className={badgeClasses}
          >
            <span className="shared-shop-badge__icon">
              {badge.icon}
            </span>
            <span className="shared-shop-badge__label">
              {I18n.t(badge.labelKey)}
            </span>
          </button>
        </RCTooltip>
      ))}
    </div>
  );
}
