import React from 'react';
import classNames from 'classnames';

import MarkdownOutput from './markdown_output';

import { imageUploadURL } from '../../url_helpers';

export interface IProps {
  image_key: string;
  text: string;
  textHtml?: string;
  slug?: string;
  subtitle?: string;
  subtitleHtml?: string;
  cdn?: string;
  ssr: boolean;
  inMobileApp: boolean;
  markdownParser?: any;
  jumplinkSlug?: string;
}

export default class FullBleedHeading extends React.Component<IProps, null> {
  get imageURI() {
    return imageUploadURL(this.props.image_key, this.props.cdn);
  }

  getBackgroundImageStyle() {
    if (!this.imageURI) return null;

    return { backgroundImage: `url(${this.imageURI})` };
  }

  render() {
    const classes = classNames(
      'full-bleed-heading',
      { 'full-bleed-heading--with-album': !!this.props.slug },
    );

    return (
      <div
        className={classes}
        style={this.getBackgroundImageStyle()}
        id={this.props.jumplinkSlug}
      >
        <div className="full-bleed-heading__inner">
          <div className="full-bleed-heading__text">
            <MarkdownOutput
              htmlTag="h3"
              className="full-bleed-heading__title"
              text={this.props.text}
              textHtml={this.props.textHtml}
              markdownParser={this.props.markdownParser}
            />
            <MarkdownOutput
              htmlTag="p"
              className="full-bleed-heading__subtitle"
              text={this.props.subtitle}
              textHtml={this.props.subtitleHtml}
              markdownParser={this.props.markdownParser}
            />
          </div>
        </div>
      </div>
    );
  }
}
