import React from 'react';
import CurationStore from '../stores/curation_store';
import CuratedSetToggle from './curated_set_toggle';
import { filter, uniqBy } from 'lodash';
import Tiles from '@reverbdotcom/commons/src/components/tiles';

class CurationAdmin extends React.Component {
  constructor(props) {
    super(props);
    this.state = CurationStore.getState();
    this.onCurationStoreUpdated = this.onCurationStoreUpdated.bind(this);
  }

  componentDidMount() {
    CurationStore.listen(this.onCurationStoreUpdated);
  }

  componentWillUnmount() {
    CurationStore.unlisten(this.onCurationStoreUpdated);
  }

  onCurationStoreUpdated() {
    this.setState(CurationStore.getState());
  }

  get listingSets() {
    return this.state.listing_curated_sets;
  }

  get hasListingSets() {
    return this.listingSets.length > 0;
  }

  render() {
    const sales = filter(this.listingSets, { has_sale: true });
    const sets = filter(this.listingSets, { has_sale: false });

    return (
      <div>
        {this.state.error}
        <h3 className="align-center cms-h2 mt-0">Add to a Handpicked Collection</h3>
        <Tiles>
          {uniqBy(sets, 'curated_set_id').map((set) => <CuratedSetToggle set={set} />)}
        </Tiles>
        <h3 className="align-center cms-h2 bdt-1 bd-primary pt-4">Add to a Sale</h3>
        <Tiles>
          {uniqBy(sales, 'curated_set_id').map((set) => <CuratedSetToggle set={set} />)}
        </Tiles>
      </div>
    );
  }
}

export default CurationAdmin;
