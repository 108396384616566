import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import APIRequest from '../../../components/api_request';
import UrlHelpers from '../../../shared/url_helpers';
import OrderNoteForm from './form';
import OrderNote from './order_note';
import I18n from 'i18n-js';

class OrderNotes extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      fetchRequested: false,
      loaded: false,
      notes: [],
      notesCount: this.props.notesCount || 0,
    };
  }

  fetchOrderNotes() {
    this.setState({ fetchRequested: true });

    APIRequest.get(UrlHelpers.orderNotesPath(this.props.orderId), null, {}, '4.0').then((response) => {
      this.setState({
        loaded: true,
        notes: response.order_notes,
      });
    });
  }

  handleToggle = (e) => {
    e.preventDefault();
    this.setState({ isOpen: !this.state.isOpen });

    if (this.hasNotesToLoad && !this.state.fetchRequested) {
      this.fetchOrderNotes();
    }
  };

  handleNoteAdded = (orderNote) => {
    this.setState({
      notes: [...this.state.notes, orderNote],
      notesCount: this.state.notesCount + 1,
    });
  };

  handleNoteDeleted = (orderNote) => {
    const newNotes = this.state.notes.filter(note => note.id !== orderNote.id);
    this.setState({
      notes: newNotes,
      notesCount: this.state.notesCount - 1,
    });
  };

  get hasNotesToLoad() {
    return this.props.notesCount > 0;
  }

  renderLoading() {
    return <div className="order-notes__loader">{I18n.t('js.orderNotes.loading')}</div>;
  }

  renderForm() {
    return (
      <OrderNoteForm
        onNoteAdded={this.handleNoteAdded}
        orderId={this.props.orderId}
      />
    );
  }

  renderNotes() {
    return this.state.notes.map(note => (
      <OrderNote
        orderNote={note}
        key={`order-note-${note.id}`}
        onDeleted={this.handleNoteDeleted}
      />
    ));
  }

  renderSlideout() {
    if (!this.state.isOpen) { return null; }
    if (this.hasNotesToLoad && !this.state.loaded) { return this.renderLoading(); }

    return (
      <div className="order-notes__container">
        <div className="order-notes__list">
          {this.renderNotes()}
        </div>
        {this.renderForm()}
      </div>
    );
  }

  renderLinkText() {
    return I18n.t('js.orderNotes.label', { count: this.state.notesCount });
  }

  render() {
    return (
      <div className={classNames('order-notes', { 'order-notes--open': this.state.isOpen })}>
        <button
          className="order-notes__label-button"
          type="button"
          onClick={this.handleToggle}
        >
          {this.renderLinkText()}
        </button>
        {this.renderSlideout()}
      </div>
    );
  }
}

OrderNotes.propTypes = {
  orderId: PropTypes.string.isRequired,
  notesCount: PropTypes.number,
};

export default OrderNotes;
