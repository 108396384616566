import { gql } from '@reverbdotcom/commons/src/gql';

export const trackBumpInteractionMutation = gql(`
  mutation Core_Track_Bump_Interaction(
    $input: Input_core_apimessages_TrackBumpInteractionRequest
  ) {
    trackBumpInteraction(input: $input) {
      bumpKey {
        key
      }
    }
  }
`);
