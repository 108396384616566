import React from 'react';
import { Route } from '@reverbdotcom/commons/src/route_settings';
import { CmsPageTypeRoutes } from '@reverbdotcom/commons/src/url_helpers';
import { MParticlePageName } from '@reverbdotcom/commons/src/elog/mparticle_types';
import { CmsPageType } from '@reverbdotcom/commons/src/constants';

function requireDynamicPage(_location, cb) {
  import(
    /* webpackChunkName: 'cms' */
    '@reverbdotcom/commons/src/components/dynamic_page'
  ).then(m => cb(null, m.default));
}

function buildRoute(prefix, cmsPageType) {
  const suffix = cmsPageType === CmsPageType.SEO_LANDING_PAGE ? '/*' : '/:slug(/:childSlug)';
  const path = `${prefix}${suffix}`;

  return (
    <Route
      path={path}
      key={path}
      props={{
        cmsPageType,
        clientSide: true,
      }}
      getComponent={requireDynamicPage}
    />
  );
}

function buildRootRoute(prefix) {
  if (prefix === CmsPageTypeRoutes.promo) {
    // /promo is not actually a CMS route, just using it for tracking
    return (
      <Route
        path={prefix}
        key={prefix}
        props={{
          clientSide: false,
          pageViewEvent: (routerState) => ({
            pageName: MParticlePageName.RedeemPromoCode,
            prefilled: !!routerState.location.query.token,
          }),
        }}
      />
    );
  }

  return (
    <Route
      path={prefix}
      key={prefix}
      props={{
        cmsPageType: 'root',
        slugOverride: prefix,
        clientSide: true,
      }}
      getComponent={requireDynamicPage}
    />
  );
}

function buildAll() {
  const routes = [];

  Object.keys(CmsPageTypeRoutes).forEach((pageType) => {
    const prefix = CmsPageTypeRoutes[pageType];

    routes.push(
      buildRoute(prefix, pageType),
      buildRootRoute(prefix),
    );
  });

  return routes;
}

export const CmsRoutes = buildAll();
