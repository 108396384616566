import APIRequest from '../../api_request';
import alt from '../../alt';
import { cspShippingRegionSupported } from '../user_helper';
import constants from '../../shared/constants';
import URLHelpers from '../../shared/url_helpers';
import UserStore from '../../../reverb-js-common/user_store';

const PAGE_SIZE = 12;

class ComparisonShoppingPageActions {
  errored() {
    return null;
  }

  preloadClear() {
    return null;
  }

  loaded(response) {
    return response;
  }

  clear() {
    return null;
  }

  fetchForSupportedRegions({ query, params }) {
    if (!cspShippingRegionSupported(UserStore.getState().shippingRegionCode)) {
      return null;
    }

    const fetchParams = { ...query,
      ...params,
      page: 1,
      per_page: PAGE_SIZE };

    this.fetch(fetchParams);

    return null;
  }

  loading() {
    return true;
  }

  fetch(params) {
    const requestParams = { ...params };

    this.loading();

    if (!cspShippingRegionSupported(UserStore.getState().shippingRegionCode)) {
      // returns global pricing and counts instead of region-specific ones
      requestParams.ships_to = constants.EVERYWHERE_CODE;
    }

    const req = APIRequest.V3.get(URLHelpers.cspsPath(), requestParams);

    req.then((response) => {
      this.loaded(response);
    });

    req.fail((_xhr, status, err) => {
      this.errored();
      throw new Error(`could not fetch CSPs - status: ${status}, err: ${err}`);
    });

    return null;
  }
}

export default alt.createActions(ComparisonShoppingPageActions);
