import React from 'react';
import { RCAlertBox } from '@reverbdotcom/cadence/components';

interface Props {
  errorMessage: string;
}

/**
 * For use in Rails react_component scenarios where
 * the React Router is not in context.
 *
 * Use the {@link CheckoutStepErrorMessage} default export
 * for all other React-routed use cases
 */
export default function CartCheckoutRedirectErrors(props: Props) {
  const checkout = {
    paymentErrorMessage: props.errorMessage,
  };

  if (!checkout.paymentErrorMessage) {
    return null;
  }

  return (
    <div className="site-wrapper site-wrapper--narrow pt-4">
      <RCAlertBox type="error">
        {checkout.paymentErrorMessage}
      </RCAlertBox>
    </div>
  );
}
