import * as elog from '@reverbdotcom/commons/src/elog';
import I18n from 'i18n-js';
import qs from 'qs';
import { filter } from 'lodash';
import locationUtil from '../../cms/lib/location_util';

import { V3 } from '../../http_client';
import Suggestion, { ISuggestionData } from './suggestion';
import SuggestionCollection, { ISuggestionHeadingAction } from './suggestion_collection';
import { CollectionHeaderStore } from '../stores/collection_header_store';
import { RECENT_SEARCH } from './site_search';
import { autocompletePath } from '../../url_helpers';
import SiteSearchSuggestion from './site_search_suggestion';

const MAX_MAIN_RESULTS = 5;
const MAX_SUB_RESULTS = 2;
const CURATED_SET_TYPE = 'curated_set';

type SiteSearchSuggestionComponent = typeof SiteSearchSuggestion;

export function buildRecentSearches(
  searches: string[],
  component: SiteSearchSuggestionComponent = SiteSearchSuggestion,
  headingAction: ISuggestionHeadingAction = undefined,
): SuggestionCollection {
  if (searches.length === 0) {
    return new SuggestionCollection([]);
  }

  const results = searches.map((value, idx) => {
    return new Suggestion({ value, type: RECENT_SEARCH, position: idx }, component);
  });

  return new SuggestionCollection([{
    heading: I18n.t('commons.autocomplete.recentSearches'),
    headingAction,
    results,
  }]);
}

export function addCurrentPageSuggestion(suggestions: Suggestion[], query: string): void {
  const { collectionHeader } = CollectionHeaderStore.getState();

  if (
    !collectionHeader ||
    (!locationUtil.isOnHandpickedPage() && !locationUtil.isOnSalePage())
  ) { return; }

  const { pathname, search, origin } = window.location;
  const currentQueryParams = qs.parse(search.slice(1));
  const params = qs.stringify({ ...currentQueryParams, query });
  const url = `${origin}${pathname}?${params}`;

  suggestions.unshift(
    new Suggestion(
      {
        url,
        filter_value: collectionHeader.title,
        filters: [],
        params: {},
        type: 'currentPage',
        value: query,
      },
      SiteSearchSuggestion,
    ),
  );
}

function setPositions(suggestions: Suggestion[], offset: number): void {
  suggestions.forEach((s, idx) => { s.position = idx + offset; });
}

export function buildSuggestions(
  suggestions: Partial<ISuggestionData[]>,
  query: string,
): SuggestionCollection {
  const allSuggestions = suggestions.reduce(
    (all, suggestion) => all.concat(new Suggestion(suggestion, SiteSearchSuggestion).filters),
    [],
  );

  const mainSuggestions = filter(allSuggestions, suggestion => (
    (suggestion.type !== CURATED_SET_TYPE)
  )).slice(0, MAX_MAIN_RESULTS);

  addCurrentPageSuggestion(mainSuggestions, query);

  const subSuggestions = filter(allSuggestions, s => (s.type === CURATED_SET_TYPE))
    .slice(0, MAX_SUB_RESULTS);

  setPositions(mainSuggestions, 0);
  setPositions(subSuggestions, mainSuggestions.length);

  const suggestionGroups = [
    {
      heading: null,
      results: mainSuggestions,
    },
    {
      heading: I18n.t('commons.searchBar.context.handpickedCollections'),
      results: subSuggestions,
    },
  ].filter(group => group.results.length);

  return new SuggestionCollection(suggestionGroups);
}

export default class ReverbSiteSearchSuggestions {
  static fetch(query: string) {
    return V3.get(autocompletePath(query))
      .then((resp) => {
        if (resp.bodyUsed) { return []; }

        return resp.json();
      })
      .then((suggestions: ISuggestionData[]) => {
        return buildSuggestions(suggestions, query);
      }).catch((error) => {
        elog.error(
          'siteSearchSuggestions.fetch.error',
          { error: error.message },
          error,
        );
      });
  }
}
