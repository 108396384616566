import React from 'react';
import I18n from 'i18n-js';
import SanitizedRender from '@reverbdotcom/commons/src/components/sanitized_render';
import CoreLink from '@reverbdotcom/commons/src/components/core_link';
import {
  Core_Feedback_OrderInfoQuery,
} from '@reverbdotcom/commons/src/gql/graphql';
import { get } from 'lodash';
import URLHelpers from '../shared/url_helpers';
import { formatInternationalizedSeconds } from '../../lib/date_utils';

const LP_MERCHANDISING_TYPE = 'lp';
const DIGITAL_MERCHANDISING_TYPE = 'digital';
const EXPRESS_SALE_MERCHANDISING_TYPE = 'express_sale';

interface IProps {
  order: Core_Feedback_OrderInfoQuery['me']['orders'][0];
  isBuyer: boolean;
}

export default class FeedbackOrderInfo extends React.Component<IProps, null> {
  get order() {
    return this.props.order;
  }

  get listing() {
    return this.props.order.listing;
  }

  get legacyOrderId() {
    return this.order.legacyOrderId;
  }

  boughtOrSoldText() {
    if (this.props.isBuyer) {
      return I18n.t('discovery.feedbackOrderInfo.youBought');
    }

    return I18n.t('discovery.feedbackOrderInfo.youSold');
  }

  orderPath() {
    if (this.props.isBuyer) {
      return URLHelpers.purchasePath({ orderId: this.legacyOrderId });
    }

    return URLHelpers.orderPath({ orderId: this.legacyOrderId });
  }

  orderNumberText() {
    return (
      <SanitizedRender
        html={
          I18n.t('discovery.feedbackOrderInfo.orderNumberHtml', {
            orderNumber: this.legacyOrderId,
            linkOpen: `<a href="${this.orderPath()}">`,
            linkClose: '</a>',
          })
        }
      />
    );
  }

  hasShippedAt() {
    // order will always haved proto shippedAt, which defaults to 0
    // which is equivalent to an order having nil shipped_at
    return !this.order.shippedAt || this.order.shippedAt.seconds > 0;
  }

  statusText() {
    const status = this.order.status.toString();

    const date = this.hasShippedAt() ? formatInternationalizedSeconds(this.order.shippedAt.seconds) : '';

    if (status === 'UNPAID' || status === 'PAYMENT_PENDING' || status === 'PARTIALLY_PAID') {
      return I18n.t('discovery.feedbackOrderInfo.status.waitingForPayment');
    }

    if (status === 'PRESUMED_RECEIVED') {
      return I18n.t('discovery.feedbackOrderInfo.status.presumedReceived');
    }

    if (this.hasShippedAt() && status === 'SHIPPED') {
      return I18n.t('discovery.feedbackOrderInfo.status.shipped', {
        date,
      });
    }

    if (this.hasShippedAt() || status === 'PICKED_UP') {
      return I18n.t('discovery.feedbackOrderInfo.status.pickedUp', {
        date,
      });
    }

    if (this.listing.merchandisingType !== DIGITAL_MERCHANDISING_TYPE) {
      return I18n.t('discovery.feedbackOrderInfo.status.awaitingShipment');
    }
  }

  listingUrl() {
    if (this.listing.merchandisingType === LP_MERCHANDISING_TYPE) {
      return '';
    }

    return URLHelpers.listingPagePath(this.listing.id, this.listing.slug);
  }

  renderListingTitle() {
    if (this.listing.merchandisingType === EXPRESS_SALE_MERCHANDISING_TYPE) {
      return this.listing.title;
    }
    return (
      <CoreLink to={this.listingUrl()}>
        {this.listing.title}
      </CoreLink>
    );

  }

  renderListingImage(imageSource) {
    if (this.listing.merchandisingType === EXPRESS_SALE_MERCHANDISING_TYPE) {
      return (
        <img
          className="img-wide"
          src={imageSource}
          alt={this.listing.title}
        />
      );
    }
    return (
      <CoreLink to={this.listingUrl()}>
        <img
          className="img-wide img-hoverable"
          src={imageSource}
          alt={this.listing.title}
        />
      </CoreLink>
    );

  }

  render() {
    const imageSource = get(this.order, 'listing.images[0].source');

    return (
      <div>
        <p className="mb-0 strong">
          {this.boughtOrSoldText()}
        </p>
        <div className="g-container">
          <div className="g-col-4 mobile-mb-2 bd-1 bd-primary">
            {this.renderListingImage(imageSource)}
          </div>
          <div className="g-col-8">
            <p className="mb-0 extratight-line-height strong">
              {this.renderListingTitle()}
            </p>
            <p className="mb-0 strong">
              {this.order.amountTotal.display}
            </p>
            <p className="mb-0">
              {this.orderNumberText()}
            </p>
            <p className="mb-0">
              {I18n.t('discovery.feedbackOrderInfo.status.orderedAt', {
                date: formatInternationalizedSeconds(this.order.createdAt.seconds),
              })}
            </p>
            <p className="mb-0">
              {this.statusText()}
            </p>
          </div>
        </div>
      </div>
    );
  }
}
