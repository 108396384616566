import React from 'react';
import { AffirmScript } from './AffirmScript';
import windowWrapper from '@reverbdotcom/commons/src/window_wrapper';
import { useTheme } from '@reverbdotcom/cadence/components';

/**
 * Page type is defined by Affirm in their [HTML reference](https://docs.affirm.com/payments/docs/html-reference>).
 *
 * More page types are available based on the docs such as homepage or category. Modify them here as they change.
 */
type AffirmPageType =
  | 'product'
  | 'cart'
  | 'payment';

interface Props {
  amountCents: number;
  financingProgramSlug: string;
  pageType?: AffirmPageType;
}

const DEFAULT_PAGE_TYPE = 'product';

export function AffirmCallout({
  amountCents,
  financingProgramSlug,
  pageType = DEFAULT_PAGE_TYPE,
}: Props) {
  const promoId = `promo_set_${financingProgramSlug}`;
  const { theme } = useTheme();

  const affirmColor = theme === 'light' ? undefined : 'white';

  React.useEffect(() => {
    const refresh = windowWrapper.affirm?.ui?.refresh;

    if (refresh) {
      refresh();
    }
  }, [amountCents, financingProgramSlug, theme]);

  return (
    <>
      <AffirmScript />
      <div
        className="affirm-callout affirm-as-low-as"
        data-page-type={pageType}
        data-amount={amountCents}
        data-promo-id={promoId}
        data-affirm-color={affirmColor}
      />
    </>
  );
}
