import { ChildProps } from '@apollo/client/react/hoc';
// TODO update this to import from commons/src/gql
// eslint-disable-next-line no-restricted-imports
import { gql } from '@apollo/client';
import React from 'react';
import { get } from 'lodash';
import {
  Commons_Cms_CuratedSetCollectionQuery,
  Commons_Cms_CuratedSetCollectionQueryVariables,
} from '../../gql/graphql';

import { withGraphql } from '../../with_graphql';
import OverflowingRow from '../../components/overflowing_row';
import Tiles from '../../components/tiles';
import { IDynamicComponentProps } from '../dynamic_component_props';

import CuratedSetCard, {
  BASIC_TILE,
  FEATURE_TILE,
} from './curated_set_card';

type Commons_Cms_CuratedSetCollectionQuery_CuratedSets = Commons_Cms_CuratedSetCollectionQuery['curatedSets'][0];

interface CmsCuratedSetOverides {
  id: string;
  bannerOverride?: string;
  squareImage?: string;
  titleOverride?: string;
}

export interface IProps extends IDynamicComponentProps {
  curatedSets: CmsCuratedSetOverides[];
  title?: string;
  titleHtml?: string;
  subtitleHtml?: string;
  ledeHtml?: string;
  rootCategorySlug?: string;
  leafCategorySlug?: string;
  ssr: boolean;
  inMobileApp: boolean;
  displayAsGrid?: boolean;
  jumplinkSlug?: string;

  // maybe one day these can be consts
  // https://github.com/Microsoft/TypeScript/issues/3964
  displayAs?: 'featureTile' | 'twoImageTile' | 'basicTile';
}

export class CuratedSetCollection extends React.Component<
ChildProps<IProps, Commons_Cms_CuratedSetCollectionQuery>,
null
> {
  displayAs(style) {
    return this.props.displayAs === style;
  }

  curatedSets(): Commons_Cms_CuratedSetCollectionQuery_CuratedSets[] {
    return get(this.props.data, 'curatedSets', []) || [];
  }

  renderTiles() {
    return this.curatedSets().map((curatedSet, idx) => {
      const formData = this.props.curatedSets.find(c => c.id === curatedSet.id);
      if (!formData) return null;

      return (
        <CuratedSetCard
          key={curatedSet.id}
          curatedSet={curatedSet}
          position={idx}
          rootCategorySlug={this.props.rootCategorySlug}
          leafCategorySlug={this.props.leafCategorySlug}
          inMobileApp={this.props.inMobileApp}
          displayAs={this.props.displayAs}
          bannerOverride={formData.bannerOverride}
          squareImage={formData.squareImage}
          titleOverride={formData.titleOverride}
        />
      );
    });
  }

  render() {
    if (!this.props.data.loading && !this.curatedSets().length) {
      return null;
    }

    return (
      <OverflowingRow
        title={this.props.title}
        titleHtml={this.props.titleHtml}
        subtitleHtml={this.props.subtitleHtml}
        ledeHtml={this.props.ledeHtml}
        collectionCount={this.curatedSets().length}
        id={this.props.jumplinkSlug}
      >
        <Tiles
          singleRow={!this.props.displayAsGrid}
          placeholder={<CuratedSetCard displayAs={this.props.displayAs} />}
          loading={this.props.data.loading}
          expectedCount={this.props.curatedSets.length}
          grow={this.displayAs(BASIC_TILE) || !this.props.displayAs}
          largeTiles={!this.displayAs(FEATURE_TILE)}
          twoWide={this.displayAs(FEATURE_TILE)}
        >
          {this.renderTiles()}
        </Tiles>
      </OverflowingRow>
    );
  }
}

const connect = withGraphql<IProps, Commons_Cms_CuratedSetCollectionQuery, Commons_Cms_CuratedSetCollectionQueryVariables>(
  gql`
    query Commons_Cms_CuratedSetCollection(
      $ids: [String]
    )
    {
      curatedSets(input: {
        ids: $ids
      }) {
        _id
        id
        title
        webLink {
          href
        }
        images(input: {
          transform: "banner_wide",
          scope: "full_bleed_image",
          type: "CuratedSet"
        }) {
          _id
          source
        }
      }
    }
  `,
  {
    options: ownProps => ({
      ssr: ownProps.ssr,
      variables: {
        ids: ownProps.curatedSets.map(c => c.id),
      },
    }),
  },
);

export default connect(CuratedSetCollection);
