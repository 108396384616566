import { reverb_search_Money as Money } from '../../gql/graphql';
import { CompletionOption } from '../site_search/suggestion';


function cspOptionListingTotal(option: CompletionOption) {
  return option.completionPayload.inventoryUsed.listingCount + option.completionPayload.inventoryNew.listingCount;
}

function cspOptionMinPrice(option: CompletionOption): Money {
  const hasOnlyNewListings = !option.completionPayload.inventoryUsed.listingCount;
  const hasOnlyUsedListings = !option.completionPayload.inventoryNew.listingCount;
  const minPriceUsed = option.completionPayload.inventoryUsed.listingMinPrice;
  const minPriceNew = option.completionPayload.inventoryNew.listingMinPrice;

  if (hasOnlyNewListings) return minPriceNew;
  if (hasOnlyUsedListings) return minPriceUsed;

  if (minPriceUsed.cents < minPriceNew.cents) return minPriceUsed;

  return minPriceNew;
}

export { cspOptionListingTotal, cspOptionMinPrice };