import { ChildProps } from '@apollo/client/react/hoc';
// TODO update this to import from commons
// import { gql } from '@reverbdotcom/commons/src/gql';
// eslint-disable-next-line no-restricted-imports
import { gql } from '@apollo/client';
import React from 'react';
import {
  CoreShopSettingsAuGstLayout,
  core_apimessages_TaxIdentification_TaxType as TaxType,
} from '@reverbdotcom/commons/src/gql/graphql';
import FormSection from '@reverbdotcom/commons/src/components/form_section';
import { RCLoadingBars, RCAlertBox, RCTextWithIcon } from '@reverbdotcom/cadence/components';
import { InfoCircleIcon } from '@reverbdotcom/cadence/icons/react';
import { I18N as Translate } from '@reverbdotcom/commons/src/components/translate';
import I18n from 'i18n-js';
import CoreLink from '@reverbdotcom/commons/src/components/core_link';
import { useHelpCenterArticleUrl } from '@reverbdotcom/commons/src/url_helpers';
import { withGraphql } from '@reverbdotcom/commons/src/with_graphql';
import AustraliaGstForm, { AUSTRALIA_COUNTRY_CODE } from './australia_gst_form';

const VAT_HELP_ARTICLE_ID = '15478546545171';

type Props = ChildProps<any, CoreShopSettingsAuGstLayout.Query>;

export function ShopSettingsAuGstContainer({ data }: Props) {
  const vatHelpCenterUrl = useHelpCenterArticleUrl(VAT_HELP_ARTICLE_ID);

  const australiaGst = (data?.me?.shop?.taxIdentifications || []).find(taxIdentification => (
    taxIdentification.taxType === TaxType.GST && taxIdentification.countryCode == AUSTRALIA_COUNTRY_CODE
  ));

  return (
    <FormSection
      title={I18n.t('discovery.shopSettings.australianGst.title')}
      description={
        <>
          <RCAlertBox
            type="info"
          >
            <RCTextWithIcon
              placement="left"
              svgComponent={InfoCircleIcon}
            >
              <span className="weight-bold">
                <Translate text="discovery.shopSettings.australianGst.auGstOnFeesDescription" />
              </span>
            </RCTextWithIcon>
            <Translate
              text="discovery.shopSettings.australianGst.addGstIdPrompt"
              tag="div"
            >
              <CoreLink to={vatHelpCenterUrl}><Translate text="discovery.shopSettings.australianGst.learnMore" /></CoreLink>
            </Translate>
          </RCAlertBox>
        </>
      }
    >
      {data?.loading ? (
        <div className="d-flex fx-align-center fx-justify-center">
          <RCLoadingBars
            size="default"
          />
        </div>
      ) : (
        <AustraliaGstForm
          taxIdentification={australiaGst}
        />
      )}
    </FormSection>
  );
}

const LAYOUT_QUERY = gql`
  query Core_ShopSettings_AuGstLayout {
    me {
      _id
      uuid
      shop {
        _id
        taxIdentifications {
          id
          taxId
          taxType
          status
          countryCode
        }
      }
    }
  }
`;

const withQuery = withGraphql<any, CoreShopSettingsAuGstLayout.Query>(
  LAYOUT_QUERY,
);

export default withQuery(ShopSettingsAuGstContainer);
