import PropTypes from 'prop-types';
import React from 'react';
import CanadianRoutingNumberFields from '../canadian_routing_number_fields';
import CountryCodeInput from './country_code_input';
import CurrencyInput from './currency_input';
import CurrencyWarnings from '../currency_warnings';
import EXAMPLE_CHECK_IMAGE from '../../../../images/canadian-example-check.png';
import Constants from '../../../shared/constants';
import I18n from 'i18n-js';

const ACCOUNT_INPUT_LENGTH = 17;
const CANADIAN_DOLLAR = Constants.CURRENCY_CODES.CAD;
const CANADA = 'CA';

// TODO: I think these are duplicated
const CanadianBankFields = (props) => {
  function hasValidSelectedPayoutCurrency() {
    return !!props.currencies.find(currency => currency.code === props.selectedPayoutCurrency);
  }

  function currencyForInput() {
    if (hasValidSelectedPayoutCurrency()) {
      return props.selectedPayoutCurrency;
    }

    return Constants.CURRENCY_CODES.CAD;
  }

  function bankLocationDetailsInput() {
    return (
      <div className="mb-2">
        <div className="g-container">
          <CountryCodeInput
            countryCode={CANADA}
            countryCodeOptions={props.countryCodeOptions}
            nameForField={fieldName => props.nameForField(fieldName)}
            onCountryChange={countryCode => props.onCountryChange(countryCode)}
          />
          <CurrencyInput
            selectedPayoutCurrency={currencyForInput()}
            currencies={props.currencies}
            nameForField={fieldName => props.nameForField(fieldName)}
            onCurrencyChange={currencyCode => props.onCurrencyChange(currencyCode)}
          />
        </div>

        <CurrencyWarnings
          payoutCurrency={currencyForInput()}
          defaultCurrencyForBankCountry={CANADIAN_DOLLAR}
          shopCurrency={props.shopCurrency}
          dashboardView={props.dashboardView}
          nameForField={props.nameForField}
        />
      </div>
    );
  }

  return (
    <div>
      <div className="g-container mb-2">
        <div className="g-col-6 g-col-mobile-12">
          <label>
            {I18n.t('js.dashboard.selling.shopPolicies.directCheckoutProfile.bankAccountFields.nameOnAccount')}
          </label>
          <input
            className="string required wide mb-0"
            autoFocus
            type="text"
            name={props.nameForField('name_on_account')}
          />
        </div>
      </div>

      {bankLocationDetailsInput()}

      <div className="g-container">
        <div className="g-col-8 g-col-mobile-12">
          <CanadianRoutingNumberFields
            nameForField={fieldName => props.nameForField(fieldName)}
          />
        </div>

        <div className="g-col-4 g-col-mobile-12">
          <label
            className="string required control-label"
            dangerouslySetInnerHTML={{
              __html: I18n.t('js.dashboard.selling.shopPolicies.directCheckoutProfile.bankAccountFields.canada.accountNumber', {
                hint_open: '<span class="hint">',
                hint_close: '</span>',
              }),
            }}
          />
          <input
            className="string required wide"
            maxLength={ACCOUNT_INPUT_LENGTH}
            type="text"
            name={props.nameForField('bank_account_number')}
          />
        </div>
      </div>
      <div className="toggler mb-0">
        {/* disabling inline here so we can enable this rule globally, but this should be fixed the next time this file is touched */}
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a className="small" href="#">
          {I18n.t('js.dashboard.selling.shopPolicies.directCheckoutProfile.bankAccountFields.canada.findBankNumbers')}
        </a>
      </div>
      <div className="toggleable ptb-2" id="example-check">
        <img
          src={EXAMPLE_CHECK_IMAGE}
          className="example-check-img"
          alt={I18n.t('js.dashboard.selling.shopPolicies.directCheckoutProfile.bankAccountFields.exampleCheckAlt')}
        />
      </div>
    </div>
  );
};

CanadianBankFields.propTypes = {
  countryCodeOptions: PropTypes.array.isRequired,
  nameForField: PropTypes.func.isRequired,
  onCountryChange: PropTypes.func.isRequired,
  onCurrencyChange: PropTypes.func.isRequired,
  currencies: PropTypes.array.isRequired,
  selectedPayoutCurrency: PropTypes.string.isRequired,
  shopCurrency: PropTypes.string.isRequired,
  dashboardView: PropTypes.bool.isRequired,
};

export default CanadianBankFields;
