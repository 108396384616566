import { useMutation } from '@reverbdotcom/commons/src/useMutation';

// TODO update this to import from commons
// import { gql } from '@reverbdotcom/commons/src/gql';
// eslint-disable-next-line no-restricted-imports
import { gql } from '@apollo/client';
import { CoreListingUpdateState } from '@reverbdotcom/commons/src/gql/graphql';

const updateListingStateMutation = gql`
  mutation Core_Listing_UpdateState(
    $input: Input_core_apimessages_UpdateListingStateRequest
  ) {
    updateListingState(input: $input) {
      newState
    }
  }
`;

export function useUpdateListingStateMutation() {
  return useMutation<CoreListingUpdateState.Mutation, CoreListingUpdateState.Variables>(updateListingStateMutation);
}
