import React from 'react';
import I18n from 'i18n-js';
import {
  IOfferLocation,
} from './negotiation_types';
import { ShippingRegionSelector } from './shipping_region_selector';
import { core_apimessages_Country as ICountry } from '../gql/graphql';
import { shippingRegionName } from './shipping_region';
import { RCTextInput } from '@reverbdotcom/cadence/components';

interface IProps {
  isEditing: boolean;
  countries: ICountry[];
  location: IOfferLocation;
  onLocationChange: (location: Partial<IOfferLocation>) => void;
  onEditLocation: (any) => void;
}

function ClosedForm(props: IProps) {
  const label = I18n.t('commons.offers.location.shippingTo', {
    regionName: shippingRegionName(props.location.shippingRegionCode, props.countries),
    postalCode: props.location.postalCode,
  });

  return (
    <p className="offers-form__location-text">
      {label}
      <button
        type="button"
        className="offers-form__location__edit"
        onClick={props.onEditLocation}
      >
        {I18n.t('commons.offers.location.change')}
      </button>
    </p>
  );
}

function OpenForm(props: IProps) {
  return (
    <div className="offers-form__location__form">
      <div className="offers-form__location__input">
        <ShippingRegionSelector
          countries={props.countries}
          onChange={(evt) => {
            props.onLocationChange({ shippingRegionCode: evt.target.value });
          }}
          value={props.location.shippingRegionCode}
        />
      </div>
      <div className="offers-form__location__input">
        <RCTextInput
          id="offermodal-location-postalcode"
          label={I18n.t('commons.offers.location.postalCodeLabel')}
          name="postalCode"
          value={props.location.postalCode}
          onChange={(evt) => {
            props.onLocationChange({ postalCode: evt.target.value });
          }}
          required
        />
      </div>
    </div>
  );
}

export default function LocationForm(props: IProps) {
  if (props.isEditing) {
    return <OpenForm {...props} />;
  }

  return <ClosedForm {...props} />;
}
