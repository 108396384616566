import React from 'react';
import { Listing, core_apimessages_ShippingMethod as IShippingMethod } from '../gql/graphql';
import { ListingOfferMetadata } from './listing_offer_metadata';
import { shippingPriceDataForMethod, getReturnPolicyDays } from '@reverbdotcom/commons/src/listing_helpers';
import { listingItemPageMetaDataUrl } from './helpers/metadata_helper';
import { ReviewMetadata, ListingCardReviews } from './reviews_metadata';
interface Props {
  listing: Listing;
  reviews?: ListingCardReviews;
  hideMetadataAsOffer?: boolean;
  includeItemListMetadata?: boolean;
}

export function ItemListMetadata({ listing } : { listing: Listing }) {
  return (
    <>
      <meta itemProp="name" content={listing.title || ''} />
      <meta itemProp="image" content={(listing?.images?.length && listing?.images[0]?.source) || ''} />
      <meta itemProp="url" content={listingItemPageMetaDataUrl(listing)} />
    </>
  );
}

export function ListingCardMetadata({
  listing,
  reviews = undefined,
  hideMetadataAsOffer = false,
  includeItemListMetadata = false,
}: Props) {

  return (
    <>
      {includeItemListMetadata && <ItemListMetadata listing={listing} />}
      {reviews && (
        <ReviewMetadata
          totalReviews={reviews.totalReviews}
          averageRating={reviews.averageRating}
          bestRating={reviews.bestRating}
          worstRating={reviews.worstRating}
        />
      )}
      {!hideMetadataAsOffer && (
        <ListingOfferMetadata
          listing={listing}
          shippingData={shippingPriceDataForMethod(listing, IShippingMethod.SHIPPED)}
          returnPolicyDays={getReturnPolicyDays(listing)}
        />
      )}
    </>
  );
}
