import React from 'react';
import I18n from 'i18n-js';

import { RCButton, useMediaQuery } from '@reverbdotcom/cadence/components';
import BulkSalesModal from './BulkSalesModal';
import { Listing } from '@reverbdotcom/commons/src/gql/graphql';
import { MParticleEventName } from '@reverbdotcom/commons/src/elog/mparticle_types';
import { useViewTracking } from '@reverbdotcom/commons/src/use_tracking';

export const COMPONENT_NAME = 'BulkSalesButton';
interface IExternalProps {
  selectedListings: Listing[];
}

export default function BulkSalesButton({
  selectedListings,
}: IExternalProps) {
  const isMobile = useMediaQuery('mobile');

  const [isModalOpen, setIsModalOpen] = React.useState(false);

  useViewTracking({
    componentName: COMPONENT_NAME,
    eventName: MParticleEventName.ComponentView,
  }, true);

  return (
    <>
      <RCButton
        fullWidth
        size={isMobile ? 'medium' : 'mini'}
        onClick={() => setIsModalOpen(true)}
        disabled={!selectedListings.length}
      >
        {I18n.t('discovery.listingsManagement.toolbar.bulkActions.sales.addToSales')}
      </RCButton>
      <BulkSalesModal
        isOpen={isModalOpen}
        selectedListings={selectedListings}
        onClose={() => setIsModalOpen(false)}
      />
    </>
  );
}
