import PropTypes from 'prop-types';
import React from 'react';
import PaypalDescriptionBullets from './paypal_description_bullets';

const PAYPAL = 'paypal';

function PaypalRadioButton(props) {
  return (
    <div className="payment-setup">
      <div className="payment-setup__label payment-setup__label--paypal">
        <input
          name={props.inputName}
          checked={props.checked}
          type="radio"
          value={PAYPAL}
          onChange={event => props.handleChangePaymentMethod(event)}
          id="shop-settings-paypal-radio"
        />
        <label htmlFor="shop-settings-paypal-radio">
          <div className="payment-setup__provider-logo payment-setup__provider-logo--paypal" />
        </label>
      </div>
      <div className="payment-setup__info">
        <PaypalDescriptionBullets
          shopCurrency={props.shopCurrency}
        />
      </div>
    </div>
  );
}

PaypalRadioButton.propTypes = {
  checked: PropTypes.bool.isRequired,
  handleChangePaymentMethod: PropTypes.func.isRequired,
  inputName: PropTypes.string.isRequired,
  shopCurrency: PropTypes.string.isRequired,
};

export default PaypalRadioButton;
