// This class takes an element inside a list that scrolls and that list's
// element. It them scrolls the list to insure the passed in item is fully
// visible. It only scrolls if necessary.
class ScrollToItem {
  constructor(itemElement, listElement) {
    this.item = itemElement;
    this.list = listElement;
  }

  scrollToItem() {
    if (!this.item) { return false; }

    if (this.itemTooHigh()) {
      this.scrollUpToItem();
    } else if (this.itemTooLow()) {
      this.scrollDownToItem();
    }
    return true;
  }

  itemTooHigh() {
    return this.listTop > this.itemTop;
  }

  itemTooLow() {
    return this.itemBottom > this.listBottom;
  }

  scrollUpToItem() {
    this.list.scrollTop = this.itemTop;
  }

  scrollDownToItem() {
    this.list.scrollTop = this.itemBottom - this.listHeight;
  }

  get itemTop() {
    return this.item.offsetTop;
  }

  get itemBottom() {
    return this.itemTop + this.item.offsetHeight;
  }

  get listBottom() {
    return this.listTop + this.listHeight;
  }

  get listTop() {
    return this.list.scrollTop;
  }

  get listHeight() {
    return this.list.offsetHeight;
  }
}

export default ScrollToItem;
