import { ChildProps } from '@apollo/client/react/hoc';
// TODO update this to import from commons
// import { gql } from '@reverbdotcom/commons/src/gql';
// eslint-disable-next-line no-restricted-imports
import { gql } from '@apollo/client';
import React from 'react';
import I18n from 'i18n-js';
import { camelizeKeys, decamelizeKeys } from 'humps';

import { CountrySelector } from './discovery/country_selector';
import {
  core_apimessages_AddressEntry as AddressEntry,
  core_apimessages_Country as Country,
  CoreAddressFormFieldsConfigsQuery,
} from '@reverbdotcom/commons/src/gql/graphql';
import AddressFormField from './address_form_field';
import { buildInputName, configFields, mergeAutocompleteAddress } from '../lib/address_form_fields_helpers';
import { isAddressEmpty } from '../lib/addressComparator';
import {
  AddressInputField,
  LabelOverride,
  Field,
} from '../lib/address_form_fields_types';

import FormGroup from '@reverbdotcom/commons/src/components/form_group';
import { useUser } from '@reverbdotcom/commons/src/user_hooks';
import { withGraphql } from '@reverbdotcom/commons/src/with_graphql';
import { connect } from '@reverbdotcom/commons/src/connect';
import { AddressAutocomplete } from './AddressAutocomplete';
import { MParticleEventName, trackEvent } from '@reverbdotcom/commons/src/elog/mparticle_tracker';
import { useViewTracking } from '@reverbdotcom/commons/src/use_tracking';

export interface ExternalProps {
  address: AddressEntry;
  countries: Country[];
  updateField: (attributes) => void;
  fieldPrefix?: string;
  /**
   * fields that are optional in the config but should be required
   */
  requiredFieldsOverrides?: AddressInputField[];
  /**
   * fields that are in the config but should not render
   */
  hiddenFieldsOverrides?: AddressInputField[];
  /**
   * fields that should be visible but not editable
   */
  disabledFieldsOverrides?: AddressInputField[];
  labelOverrides?: LabelOverride;
  phoneLabelTooltip?: JSX.Element | React.ReactNode;
  /**
   * Pivots between use of camel and snake case since this
   * form is used in both frontend and Core.
   *
   * Address data submitted to RQL needs to be in camel case,
   * while the data being sent to Core should be in snake case.
   */
  useCamelizedFieldsForRQL?: boolean;
  /**
   * should the entire form be disabled (e.g. during submit operations)
   */
  disabled?: boolean;

  /**
   * should a set of frequently-used countries be elevated above other options in the country dropdown
   */
  prioritizeTopCountries?: boolean;
  trackingComponentName: string;

  /**
   * Determines if the autocomplete functionality should be used
   */
  autocompleteEnabled?: boolean;

  /**
   * Determines if the autocomplete functionality is required
   */
  autocompleteRequired?: boolean;
}

type Props = ChildProps<ExternalProps, CoreAddressFormFieldsConfigsQuery.Query>;

/**
 * This component should be used exclusively in frontend in components making RQL queries
 * modifying address data. When dealing with address forms talking directly
 * to our database in core, use the `<AddressFormFieldsContainer />` with relevant field
 * prefixes instead.
 */
export function AddressFormFields(props: ChildProps<Props, CoreAddressFormFieldsConfigsQuery.Query>) {
  const {
    updateField,
    address,
    countries,
    useCamelizedFieldsForRQL,
    data,
    disabled,
    trackingComponentName,
    phoneLabelTooltip,
    autocompleteEnabled = false,
    prioritizeTopCountries = false,
    autocompleteRequired = false,
  } = props;
  const { loading, intlAddressFormConfig } = data;
  const user = useUser();
  const [isAutocompleteVisible, showAutocomplete] = React.useState(isAddressEmpty(address));
  const showAutocompleteInput = isAutocompleteVisible && autocompleteEnabled;
  const showAddressInputs = !isAutocompleteVisible || !autocompleteEnabled;

  useViewTracking({
    componentName: 'AddressFormFields',
    eventName: MParticleEventName.ComponentView,
    displayStyle: trackingComponentName,
  }, !loading);

  if (loading || !intlAddressFormConfig) return null;

  const fieldPrefix = props.fieldPrefix || '';
  const requiredFieldsOverrides = props.requiredFieldsOverrides || [];
  const hiddenFieldsOverrides = props.hiddenFieldsOverrides || [];
  const disabledFieldsOverrides = props.disabledFieldsOverrides || [];
  const labelOverrides = props.labelOverrides || {};

  const configs = intlAddressFormConfig?.configs;
  const addressFields = configFields(address.countryCode, configs, requiredFieldsOverrides);
  const nameField = addressFields.find(field => field.fieldName === 'name');
  const phoneField = addressFields.find(field => field.fieldName === 'phone');
  const displayableAddressFields = addressFields.filter(field => field.fieldName !== 'name' && field.fieldName !== 'phone');

  function onAutocompleteSuggestion(suggestedAddress: AddressEntry) {
    const mergedAddress = mergeAutocompleteAddress({
      address,
      suggestion: suggestedAddress,
    });

    trackEvent({
      eventName: MParticleEventName.SelectedAutocompleteAddress,
      componentName: props.trackingComponentName,
    });
    showAutocomplete(false);
    updateField(mergedAddress);
  }

  function onChooseManualEntry() {
    trackEvent({
      eventName: MParticleEventName.ClickedManualAddressEntry,
      componentName: props.trackingComponentName,
    });
    showAutocomplete(false);
  }

  function renderAddressInput(field: Field) {
    if (hiddenFieldsOverrides.includes(field.fieldName)) return null;

    return (
      <AddressFormField
        key={field.fieldName}
        field={field}
        user={user}
        address={address}
        countries={countries}
        useCamelizedFieldsForRQL={useCamelizedFieldsForRQL}
        updateField={updateField}
        requiredFieldsOverrides={requiredFieldsOverrides}
        labelOverrides={labelOverrides}
        fieldPrefix={fieldPrefix}
        phoneLabelTooltip={phoneLabelTooltip}
        disabledFieldsOverrides={disabledFieldsOverrides}
        disabled={disabled}
        trackingComponentName={trackingComponentName}
      />
    );
  }

  return (
    <div data-address-fields>
      {renderAddressInput(nameField)}

      {showAutocompleteInput &&
        <AddressAutocomplete
          onAddressSelect={onAutocompleteSuggestion}
          onChooseManualEntry={onChooseManualEntry}
          required={autocompleteRequired}
        />
      }

      {showAddressInputs &&
        <>
          <FormGroup label={I18n.t('discovery.addressFormFields.country')}>
            <CountrySelector
              inputName={buildInputName(fieldPrefix, 'country_code')}
              value={address.countryCode}
              onChange={e => handleCountryChange(e, addressFields, address, useCamelizedFieldsForRQL, updateField, configs, requiredFieldsOverrides)}
              countries={decamelizeKeys(countries)}
              disabled={disabled || disabledFieldsOverrides.includes('country_code')}
              prioritizeTopCountries={prioritizeTopCountries}
            />
          </FormGroup>

          {displayableAddressFields.map((field) => (
            renderAddressInput(field)
          ))}
        </>
      }

      {renderAddressInput(phoneField)}
    </div>
  );
}

function handleCountryChange(
  evt,
  currentFields,
  address,
  useCamelizedFieldsForRQL,
  updateField,
  configs,
  requiredFieldsOverrides,
) {
  const newAddressState = buildDiffedAddressFieldsForCountryCode(address, currentFields, configs, evt, requiredFieldsOverrides);

  if (useCamelizedFieldsForRQL) {
    updateField(newAddressState);
  } else {
    updateField(decamelizeKeys(newAddressState));
  }
}

function buildDiffedAddressFieldsForCountryCode(
  address,
  currentFields: Field[],
  configs,
  evt,
  requiredFieldsOverrides,
): AddressEntry {
  const fieldsFromConfig = configFields(evt, configs, requiredFieldsOverrides);
  const newFields = fieldsFromConfig.map((field) => field.fieldName);
  const oldFields = currentFields.map((field) => field.fieldName);
  const fieldsDifference = oldFields.filter((oldField) => !newFields.includes(oldField));
  const blankFields = fieldsDifference.reduce((prev, fieldName) => ({ ...prev, [fieldName]: '' }), {});

  return { ...address, ...camelizeKeys(blankFields), countryCode: evt };
}

export const ADDRESS_FORM_FIELDS_CONFIGS_QUERY = gql`
  query Core_Address_Form_Fields_Configs_Query {
    intlAddressFormConfig {
      configs {
        countryCode
        fields {
          fieldName
          displayNames {
            locale
            displayName
          }
          required
        }
      }
    }
  }
`;

const withQuery = withGraphql<ExternalProps, CoreAddressFormFieldsConfigsQuery.Query>(
  ADDRESS_FORM_FIELDS_CONFIGS_QUERY,
  {
    options: {
      ssr: true,
    },
  },
);

export default connect<ExternalProps>([withQuery])(AddressFormFields);
