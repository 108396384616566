import React from 'react';
import I18n from 'i18n-js';
import classnames from 'classnames';
import { core_apimessages_ShippingLabelPackage } from '@reverbdotcom/commons/src/gql/graphql';
import ControlledInput from '@reverbdotcom/commons/src/components/controlled_input';

import { MeasurementSystem } from './shipping_label_package';
import PackageInput from './package_input';
import HelpCard from '../help_card';
import DimensionIllustration from '../../../../images/shipping/box-dimension-illustration.svg';

export enum PackageAttribute {
  HEIGHT = 'height',
  LENGTH = 'length',
  WIDTH = 'width',
  WEIGHT = 'weight',
}

interface IProps {
  shippingPackage: core_apimessages_ShippingLabelPackage;
  measurementSystem: MeasurementSystem;
  updatePackageDimensions: (attributes: core_apimessages_ShippingLabelPackage) => void;
  updateMeasurementSystem?: (measurementSystem: MeasurementSystem) => void;
  hideMeasurementSystemControls?: boolean;
  forCarrierCalculated?: boolean;
  disabled?: boolean;
  alert?: React.ReactNode;
}

export default function CustomPackageInputs({
  updateMeasurementSystem,
  updatePackageDimensions,
  measurementSystem,
  forCarrierCalculated,
  shippingPackage,
  hideMeasurementSystemControls,
  disabled,
  alert,
}: IProps) {

  const usingImperial = measurementSystem === MeasurementSystem.IMPERIAL;
  const usingMetric = measurementSystem === MeasurementSystem.METRIC;
  return (
    <div
      className="g-container g-container--cycle g-container--flex fx-wrap mobile-fx-dir-col">
      <div className="g-col-mobile-12 g-col-7">
        {forCarrierCalculated && (
          <>
            <strong>
              {I18n.t('discovery.dashboard.selling.shippingLabels.package.dimensionsRequest')}
            </strong>
            <p>
              {I18n.t('discovery.dashboard.selling.shippingLabels.package.dimensionsRequestReason')}
            </p>
          </>
        )}

        {!hideMeasurementSystemControls &&
          <>
            <div className="label-with-radio scaling-mb-2">
              <ControlledInput
                inputType="radio"
                inputName="measurementSystemImperial"
                inputValue={MeasurementSystem.IMPERIAL}
                checked={usingImperial}
                updateField={(attributes) => updateMeasurementSystem(attributes.measurementSystem)}
                fieldName="measurementSystem"
                disabled={disabled}
              />
              <label htmlFor="measurementSystemImperial">
                {I18n.t('discovery.dashboard.selling.shippingLabels.package.useImperial')}
              </label>
            </div>
            <div className="label-with-radio">
              <ControlledInput
                inputType="radio"
                inputName="measurementSystemMetric"
                inputValue={MeasurementSystem.METRIC}
                checked={usingMetric}
                updateField={(attributes) => updateMeasurementSystem(attributes.measurementSystem)}
                fieldName="measurementSystem"
                disabled={disabled}
              />
              <label htmlFor="measurementSystemMetric">
                {I18n.t('discovery.dashboard.selling.shippingLabels.package.useMetric')}
              </label>
            </div>
          </>
        }
        <div className="mb-4">
          <PackageInput
            dimensionKey={PackageAttribute.LENGTH}
            value={shippingPackage.length.value}
            shippingPackage={shippingPackage}
            measurementUnit={shippingPackage.length.unit}
            onUpdate={updatePackageDimensions}
            disabled={disabled}
            roundUpUserInput
          />
        </div>
        <div className="mb-4">
          <PackageInput
            dimensionKey={PackageAttribute.WIDTH}
            value={shippingPackage.width.value}
            shippingPackage={shippingPackage}
            measurementUnit={shippingPackage.width.unit}
            onUpdate={updatePackageDimensions}
            disabled={disabled}
            roundUpUserInput
          />
        </div>
        <div className="mb-4">
          <PackageInput
            dimensionKey={PackageAttribute.HEIGHT}
            value={shippingPackage.height.value}
            shippingPackage={shippingPackage}
            measurementUnit={shippingPackage.height.unit}
            onUpdate={updatePackageDimensions}
            disabled={disabled}
            roundUpUserInput
          />
        </div>
        <PackageInput
          dimensionKey={PackageAttribute.WEIGHT}
          value={shippingPackage.weight.value}
          shippingPackage={shippingPackage}
          measurementUnit={shippingPackage.weight.unit}
          onUpdate={updatePackageDimensions}
          disabled={disabled}
        />
      </div>
      <div className="g-col-mobile-12 g-col-5 d-flex mobile-fx-order-3">
        <div className={classnames(
          'mobile-fx-width-100 mobile-mb-0 mobile-mt-6',
          {
            'fx-width-70': !forCarrierCalculated,
          },
        )}>
          <HelpCard
            variant="secondary"
            iconAlt={I18n.t('discovery.dashboard.selling.shippingLabels.package.helpCard.shippingBoxImgAlt')}
          >
            <div className="text-fine-300">
              <p className="weight-bold text-fine--bold text-default-350 mb-2">
                {I18n.t('discovery.dashboard.selling.shippingLabels.package.helpCard.makeSure')}
              </p>
              <ul className="rsl-selector-module__list-group">
                <li className="rsl-selector-module__list-item">
                  {usingImperial ?
                    I18n.t('discovery.dashboard.selling.shippingLabels.package.helpCard.roundUpImperial') :
                    I18n.t('discovery.dashboard.selling.shippingLabels.package.helpCard.roundUpMetric')
                  }
                </li>
                <li className="rsl-selector-module__list-item">
                  {usingImperial ?
                    I18n.t('discovery.dashboard.selling.shippingLabels.package.helpCard.addImperial') :
                    I18n.t('discovery.dashboard.selling.shippingLabels.package.helpCard.addMetric')
                  }
                </li>
              </ul>
              <div className="d-flex fx-justify-center ptb-2">
                <img
                  src={DimensionIllustration}
                  alt={I18n.t('discovery.dashboard.selling.shippingLabels.package.helpCard.boxIllustrationImgAlt')}
                  className="width-80"
                />
              </div>
              <div>
                <p className="mb-2">
                  {I18n.t('discovery.dashboard.selling.shippingLabels.package.inaccurateDimensionsWarning')}
                </p>
                <a
                  href="https://help.reverb.com/hc/articles/360013150694-Why-Did-I-Receive-a-Shipping-Label-Adjustment-#Avoid"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-fine--bold td-underline color-black"
                  tabIndex={-1}
                >
                  {I18n.t('discovery.dashboard.selling.shippingLabels.package.inaccurateDimensionsWarningLink')}
                </a>
              </div>
            </div>
          </HelpCard>
        </div>
      </div>
      {!!alert && (
        <div data-testid="alert" className="g-col-12">
          {alert}
        </div>
      )}
    </div>
  );
}
