import { RCButton, RCIcon } from '@reverbdotcom/cadence/components';
import { ArrowRightIcon } from '@reverbdotcom/cadence/icons/react';
import { withPrefix } from '@reverbdotcom/commons/src/i18n_helpers';
import React from 'react';
import { useTrackCheckoutClickEvent } from './checkout/checkoutTracking';
import { core_apimessages_CheckoutPaymentMethod_Type as CheckoutPaymentMethodType } from '@reverbdotcom/commons/src/gql/graphql';
import { CheckoutClickInteraction } from '@reverbdotcom/commons/src/elog/mparticle_types';

interface Props {
  checkoutId: string;
}

export function LegacyCheckoutShippingContinueButton(props: Props) {
  const translate = withPrefix('discovery.checkout.newShippingAddressForm');

  const trackClickEvent = useTrackCheckoutClickEvent({
    id: props.checkoutId,
    paymentMethod: {
      type: CheckoutPaymentMethodType.DIRECT_CHECKOUT,
    },
  });

  function trackShippingEvent() {
    trackClickEvent(CheckoutClickInteraction.ContinueWithShipping);
  }

  return (
    <RCButton
      variant="filled"
      isSubmit
      fullWidth
      onClick={trackShippingEvent}
      data-disable-with={translate('saveButton.saving')}
    >
      {translate('saveButton.default')}
      <span className="ml-space">
        <RCIcon
          inline
          svgComponent={ArrowRightIcon}
        />
      </span>
    </RCButton>
  );
}
