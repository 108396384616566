/* eslint-disable no-mixed-operators, no-bitwise */

function getKeyInteger(key: string): number {
  return key.split('').map(char => char.charCodeAt(0) || 0)
    .reduce(byteArrayReducer);
}

function byteArrayReducer(all, next) {
  return all + next;
}

// given an integer seed
// returns a random number generator that will return a number between 0 and 1
// given the same seed, the generator will return the same number every time
export function rand(seed) {
  return () => {
    let t = seed + 0x6D2B79F5;
    t = Math.imul(t ^ t >>> 15, t | 1);
    t ^= t + Math.imul(t ^ t >>> 7, t | 61);
    return ((t ^ t >>> 14) >>> 0) / 4294967296;
  };
}

// given a seed and an array
// returns a random element from the array
// given the same seed, the same element will be returned every time
// Source from: https://stackoverflow.com/a/47593316
export function randSample(seed: string, arr) {
  const index = randInt(seed, arr.length);
  return arr[index];
}

// given a seed and an int
// returns a random number between 0 and n - 1
// given the same seed, the same number will be returned every time
export function randInt(seed: string, n: number) {
  const intSeed = getKeyInteger(seed);
  const generator = rand(intSeed);
  const num = generator();
  const bigNum = num * 1000000;

  return Math.floor(bigNum % n);
}
