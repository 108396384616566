import React from 'react';
import I18n from 'i18n-js';
import classNames from 'classnames';
import { kebabCase, last } from 'lodash';

import SelectFacet from './select_facet';
import TextInputFacet from './text_input_facet';
import { NestedSelectFacet } from './nested_select_facet';
import RangeFacet from './range_facet';
import RegionSelectFilter from './region_select_filter';
import { Filter, FilterProps, WidgetType } from './search_filters';
import { RCChip, RCPopover } from '@reverbdotcom/cadence/components';
import { selectedPath, isSomethingSelected } from '../facet_utils';
import { MParticleEventName, trackEvent } from '../elog/mparticle_tracker';

interface FacetMapping {
  [widgetType: string]: React.ComponentType<FilterProps>;
}

const FACET_MAPPING: FacetMapping = {
  [WidgetType.TEXT]: TextInputFacet,
  [WidgetType.NESTED_SELECT]: NestedSelectFacet,
  [WidgetType.CHECKBOX]: SelectFacet,
  [WidgetType.RADIO]: SelectFacet,
  [WidgetType.RANGE]: RangeFacet,
  [WidgetType.REGION_SELECT]: RegionSelectFilter,
  [WidgetType.NONE]: null,
  [WidgetType.SORT]: null,
};

const FACETS_WITH_ALL_FILTERS = [
  'CONDITION_SLUGS',
  'CATEGORY_SLUGS',
  'BRAND_SLUGS',
  'DECADES',
];

interface IProps {
  filter?: Filter;
  filters?: Filter[];
  loading?: boolean;
  trackingQuery?: string;
  displayCount?: boolean;
  dynamicTitle?: boolean;
  hideOnMobile?: boolean;
}

export default function MarketplaceFilter({
  filter = null,
  loading = false,
  trackingQuery = null,
  displayCount = false,
  dynamicTitle = false,
  hideOnMobile = false,
}: IProps) {
  const [popoverIsOpen, openPopover] = React.useState(false);

  if (!filter || filter.options.length === 0) return null;

  const selectedFilters = filter.options?.filter(f => {
    return f.selected;
  });

  const isFilterSelected = (aggregationName) => {
    return filter.aggregationName === aggregationName && filter.options?.[0].selected === true;
  };

  const getSellerLocationFilterText = () => {
    const sellerLocationOption = filter.options.find((option) => option.selected);
    return (
      <span>
        <span className="weight-bold">{`${I18n.t('commons.sellerLocationSearchFilter.buttonText')}: `}</span>
        {sellerLocationOption.name}
      </span>
    );
  };

  function isUnspecifiedTraitFilterSelected() {
    return filter.aggregationName === 'TRAITS' &&
      selectedFilters.length === 1 &&
      selectedFilters[0]?.optionValue.includes('unspecified');

  }

  const getButtonText = () => {
    if (!dynamicTitle) return filter.name;
    if (filter.aggregationName === 'CATEGORY_SLUGS') {
      if (filter.options?.[0].selected === true) return filter.name;

      const category = selectedPath(filter);

      return last(category).name;
    }
    if (selectedFilters?.length === 0) return filter.name;

    if (isFilterSelected('CONDITION_SLUGS')) {
      return I18n.t('commons.marketplaceFilters.allConditions');
    }

    if (isFilterSelected('BRAND_SLUGS')) {
      return I18n.t('commons.marketplaceFilters.allBrands');
    }

    if (filter.key === 'SELLER_LOCATION') {
      return getSellerLocationFilterText();
    }

    // Index 2 on a RANGE filter displays the combination of min & max values
    if (filter.widgetType === 'RANGE' && selectedFilters.length > 1) {
      return filter.options?.[2].name;
    }

    if (selectedFilters?.length > 1) return filter.name;

    if (isUnspecifiedTraitFilterSelected()) {
      // Showing just the 'Unspecified' selected value for the filter chip doesn't give any helpful context for what the filter does
      return filter.name;
    }

    return selectedFilters[0].name;
  };

  const getSelectedFilterCount = () => {
    // hides the count for range filters
    if (filter.widgetType === 'RANGE' && selectedFilters.length > 1) {
      return 1;
    }
    if (filter.aggregationName === 'CATEGORY_SLUGS') {
      if (isSomethingSelected(filter, true)) return 1;
    }
    if (selectedFilters?.length !== 1) return selectedFilters.length;

    const hasAllFilter = FACETS_WITH_ALL_FILTERS.some(name => filter.aggregationName === name);
    if (hasAllFilter && filter.options?.[0].selected === true) {
      return 0;
    }
    return selectedFilters.length;
  };

  const FacetComponent = FACET_MAPPING[filter.widgetType];
  if (filter.options.length === 0 || !FacetComponent) return null;
  if (!getButtonText()) return null;

  const classes = classNames(
    'marketplace-filter',
    { 'marketplace-filter--mobile-hidden': hideOnMobile },
  );

  const trackFilterChipClick = (opened) => {
    trackEvent({
      componentName: 'FilterChip',
      eventName: MParticleEventName.ClickedSearchFilterChip,
      displayStyle: opened ? 'opened' : 'closed',
      parent: filter.key.length !== 0 ? filter.key : filter.aggregationName,
      query: trackingQuery,
    });
  };

  function handleClick() {
    openPopover(!popoverIsOpen);
    trackFilterChipClick(popoverIsOpen);
  }

  return (
    <div className={classes}>
      <RCPopover
        isOpen={popoverIsOpen}
        onDismiss={() => openPopover(false)}
        position="bottom-start"
        loading={loading}
        anchor={
          <RCChip
            selected={getSelectedFilterCount() > 0}
            count={displayCount && getSelectedFilterCount() > 1 ? getSelectedFilterCount() : null}
            onClick={() => handleClick()}
            loading={loading}
          >
            {getButtonText()}
          </RCChip>
        }
        trapFocus
      >
        <FacetComponent
          pageSection="filter-row"
          filter={filter}
          key={kebabCase(filter.name)}
          trackingQuery={trackingQuery}
        />
      </RCPopover>
    </div>
  );
}
