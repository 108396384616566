// TODO update this to import from commons
// import { gql } from '@reverbdotcom/commons/src/gql';
// eslint-disable-next-line no-restricted-imports
import { gql } from '@apollo/client';
import React, { useCallback } from 'react';
import { withGraphql } from '@reverbdotcom/commons/src/with_graphql';
import {
  CoreCreditCardWalletCreateMyCreditCard,
  Input_core_apimessages_CreateMyCreditCardRequest as CreateMyCreditCardRequest,
  core_apimessages_Country as Country,
  CreditCardWalletCreateFormFieldsFragment,
} from '@reverbdotcom/commons/src/gql/graphql';
import { useHOCMutation, MutationFunction } from '@reverbdotcom/commons/src/useHOCMutation';
import { CreditCardForm } from './credit_card_form_context';
import { withRouter, WithRouterProps } from 'react-router';
import CreditCardCreateForm, { CreateCreditCardFormInput } from './credit_card_create_form';
import { connect } from '@reverbdotcom/commons/src/connect';

type CreateMyCreditCardMutationFn = MutationFunction<
  CoreCreditCardWalletCreateMyCreditCard.Mutation,
  CoreCreditCardWalletCreateMyCreditCard.Variables
>;
type Address = CreditCardWalletCreateFormFieldsFragment['shippingAddresses'][0];

interface MutationProps {
  createMyCreditCard: CreateMyCreditCardMutationFn;
}

interface ExternalProps {
  form: CreditCardForm;
  defaultBillingAddress: Address;
  countries: Country[];
  onCardValidChange?: (valid: boolean) => void;
}

type Props = ExternalProps & MutationProps & WithRouterProps;

export function CreditCardWalletCreateForm(props: Props) {
  const { form } = props;

  const { createMyCreditCard, errors } = useCreateMyCreditCard(props.createMyCreditCard);

  async function onSubmit(input: CreateCreditCardFormInput) {
    form.setLoading(true);

    const result = await createMyCreditCard({
      creditCard: {
        cardholderName: input.creditCard.cardholderName,
        tokenizedFields: input.creditCard.tokenizedFields,
      },
      billingAddress: input.billingAddress,
    });

    const newCreditCardId = result?.data?.createMyCreditCard?.creditCard?.id;

    if (newCreditCardId) {
      form.onSelect(newCreditCardId);
    } else {
      form.setLoading(false);
    }
  }

  return (
    <CreditCardCreateForm
      formId={form.id}
      defaultBillingAddress={props.defaultBillingAddress}
      countries={props.countries}
      errors={errors}
      onSubmit={onSubmit}
      onCardValidChange={props.onCardValidChange}
    />
  );
}

export const CreditCardWalletCreateFormFragment = gql`
  fragment CreditCardWalletCreateFormFields on rql_Me {
    _id
    shippingAddresses {
      _id
      id
      uuid
      name
      streetAddress
      extendedAddress
      postalCode
      region
      regionName
      locality
      phone
      primary
      isComplete
      countryCode
      country {
        _id
        name
      }
    }
  }
`;

export function useCreateMyCreditCard(
  createMyCreditCard: CreateMyCreditCardMutationFn,
) {
  const [mutate, { loading, errors }] = useHOCMutation(createMyCreditCard);

  const createMyCreditCardCallback = useCallback(async (input: CreateMyCreditCardRequest) => {
    return mutate({
      variables: {
        input: {
          ...input,
        },
      },
    });
  }, [mutate]);

  return { createMyCreditCard: createMyCreditCardCallback, loading, errors };
}

const withMutation = withGraphql<Props, CoreCreditCardWalletCreateMyCreditCard.Mutation, CoreCreditCardWalletCreateMyCreditCard.Variables>(
  gql`
    mutation Core_CreditCardWallet_CreateMyCreditCard(
      $input: Input_core_apimessages_CreateMyCreditCardRequest
    ) {
      createMyCreditCard(input: $input) {
        creditCard {
          id
        }
      }
    }
  `,
  {
    name: 'createMyCreditCard',
    options: {
      refetchQueries: ['Core_CreditCardWallet_Index'],
      awaitRefetchQueries: true,
    },
  },
);

const connected = connect<ExternalProps>([
  withRouter,
  withMutation,
])(CreditCardWalletCreateForm);

export default connected;
