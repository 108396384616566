import { ChildProps } from '@apollo/client/react/hoc';
// TODO update this to import from commons
// import { gql } from '@reverbdotcom/commons/src/gql';
// eslint-disable-next-line no-restricted-imports
import { gql } from '@apollo/client';
import React from 'react';
import I18n from 'i18n-js';

import CoreLink from '@reverbdotcom/commons/src/components/core_link';
import CategoryFlyoutHeader from '../../new_header/category_flyout_header';
import { useURL } from '@reverbdotcom/commons/src/url_context';
import { withUserContext, IUserContext } from '@reverbdotcom/commons/src/components/user_context_provider';
import { RCIcon } from '@reverbdotcom/cadence/components';
import { HeartEmptyIcon, ReverbLogoIcon } from '@reverbdotcom/cadence/icons/react';
import { MParticleEventName } from '@reverbdotcom/commons/src/elog/mparticle_types';
import { withGraphql } from '@reverbdotcom/commons/src/with_graphql';
import { ADMINISTRATOR_PERMISSION_LEVEL } from '@reverbdotcom/commons/src/constants';

import componentUrlHelpers, { Paths } from '../../shared/url_helpers';

import SiteSearch from '@reverbdotcom/commons/src/components/site_search/reverb_site_search';
import LogIn from './login';
import ReverbHeaderUserBadges from './reverb_header_user_badges';
import ReverbHeaderCartBadge, { ReverbHeaderCartBadgeFragment } from './reverb_header_cart_badge';
import ReverbHeaderDesktopMenu from './reverb_header_desktop_menu';
import ReverbHeaderMobileMenu from './reverb_header_mobile_menu';
import { CoreReverbHeader } from '@reverbdotcom/commons/src/gql/graphql';
import classNames from 'classnames';

interface IProps extends IUserContext {
  loginComponent?: React.ReactNode; // used to pass in an oauth login component from sandbox layout.tsx
  shouldHideSiteSearch?: boolean;
}

export function ReverbHeader({ loginComponent, data, user, shouldHideSiteSearch }: ChildProps<IProps, CoreReverbHeader.Query>) {
  const COMPONENT_NAME = 'ReverbHeader';
  const [activeDropdownKey, setActiveDropdownKey] = React.useState<string>();
  const { loggedOut } = user;
  const me = data?.me;
  const url = useURL();

  return (
    <>
      <div className="reverb-header__main">
        <div className="reverb-header__inner">
          <nav aria-label={I18n.t('discovery.reverbHeader.navigationRole')}>
            <ul className="reverb-header__nav__links">
              <ReverbHeaderMobileMenu me={me} />
              <li className="reverb-header__show-on-mobile">
                <CoreLink
                  to="/"
                  className="reverb-header__logo"
                  aria-label={I18n.t('discovery.reverbHeader.reverbLogo')}
                >
                  <ReverbLogoIcon />
                </CoreLink>
              </li>
            </ul>
            <div className={classNames(
              'reverb-header__nav',
              'reverb-header__nav--main',
            )}>
              <div className="reverb-header__nav__search">
                {!shouldHideSiteSearch && <SiteSearch defaultValue={url.query.query} url={url} />}
              </div>
            </div>
            <div className="reverb-header__skip-link">
              <CoreLink
                to={{
                  anchor: 'main-content',
                }}
                className="reverb-header__skip-link__link"
              >
                {I18n.t('discovery.reverbHeader.skipLink')}
              </CoreLink>
            </div>
            <nav
              aria-label={I18n.t('discovery.reverbHeader.myReverb')}
              role="navigation"
              className="reverb-header__nav reverb-header__nav--account"
            >
              <ul className="reverb-header__nav__links">
                <li className="reverb-header__show-on-mobile">
                  <div className="mobile-d-none tablet-d-none">
                    <CoreLink
                      className="reverb-header__nav__link reverb-header__nav__link--sell-button"
                      to={componentUrlHelpers.sellFormSearchPath({})}
                      clickEvent={{
                        componentName: COMPONENT_NAME,
                        entityId: componentUrlHelpers.sellFormSearchPath({}),
                        entityType: 'url',
                        eventName: MParticleEventName.ClickedComponent,
                      }}
                    >
                      {I18n.t('discovery.reverbHeader.sellCTAFull')}
                    </CoreLink>
                  </div>
                  <div className="mobile-d-block tablet-d-block d-none">
                    <CoreLink
                      className="reverb-header__nav__link reverb-header__nav__link--sell-button"
                      to={componentUrlHelpers.sellFormSearchPath({})}
                      clickEvent={{
                        componentName: COMPONENT_NAME,
                        entityId: componentUrlHelpers.sellFormSearchPath({}),
                        entityType: 'url',
                        eventName: MParticleEventName.ClickedComponent,
                      }}
                    >
                      {I18n.t('discovery.reverbHeader.sellCTAMobile')}
                    </CoreLink>
                  </div>
                </li>
                <li className="reverb-header__badges-show-on-mobile reverb-header__favorites-heart">
                  <CoreLink
                    className="reverb-header__nav__link"
                    to={Paths.myFavorites.expand({})}
                    title={I18n.t('discovery.reverbHeader.favorites')}
                  >
                    <div className="reverb-header__nav__link__icon">
                      <RCIcon svgComponent={HeartEmptyIcon} />
                    </div>
                    <div className="reverb-header__nav__link__label">
                      {I18n.t('discovery.reverbHeader.favorites')}
                    </div>
                  </CoreLink>
                </li>
                <li className={classNames(
                  'reverb-header__nav__link__auto-width',
                  'reverb-header__badges-show-on-mobile',
                )}>
                  <ReverbHeaderCartBadge
                    activeDropdownKey={activeDropdownKey}
                    setActiveDropdownKey={setActiveDropdownKey}
                    me={me}
                  />
                </li>
                {!loggedOut && (
                  <li className={classNames(
                    'reverb-header__nav__link__auto-width',
                    'reverb-header__badges-show-on-mobile',
                  )}>
                    <ReverbHeaderUserBadges
                      activeDropdownKey={activeDropdownKey}
                      setActiveDropdownKey={setActiveDropdownKey}
                    />
                  </li>
                )}

                {loggedOut && !loginComponent && <LogIn />}
                {loggedOut && loginComponent}
                {!loggedOut && (
                  <li className="reverb-header__nav__link__auto-width">
                    <ReverbHeaderDesktopMenu
                      activeDropdownKey={activeDropdownKey}
                      setActiveDropdownKey={setActiveDropdownKey}
                      me={me}
                    />
                  </li>
                )}
              </ul>
            </nav>
          </nav>
        </div>
      </div>
      <div className="reverb-header__links">
        <CategoryFlyoutHeader />
      </div>
    </>
  );
}

const withQuery = withGraphql<IProps, CoreReverbHeader.Query>(
  gql`
    query Core_ReverbHeader($isShopAdmin: Boolean!) {
      me {
        _id
        uuid
        shop {
          _id
          slug
          paymentMethod
        }
        reverbCredits {
          display
          currency
        }
        payoutBalances @include(if: $isShopAdmin) {
          display
          currency
        }
        ...ReverbHeaderCartBadgeData
      }
    }
    ${ReverbHeaderCartBadgeFragment}
  `,
  {
    skip: (ownProps) => (ownProps.user.loggedOut),
    options: (ownProps) => {
      return {
        ssr: false,
        variables: {
          isShopAdmin: !ownProps.user.secondaryUserModeIsActive || ownProps.user.managedShopPermission == ADMINISTRATOR_PERMISSION_LEVEL,
        },
      };
    },
  },
);

export default withUserContext(withQuery(ReverbHeader));
