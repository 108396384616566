import { groupBy } from 'lodash';

import { formatNumber } from '@reverbdotcom/commons/src/money';
import Purify from '@reverbdotcom/commons/src/universal_purify';
import {
  core_apimessages_Money,
  core_apimessages_ImageEntry,
  core_apimessages_Listing_SeedType,
  HydrateSellForm,
  core_apimessages_Listing_ListingType,
  Core_SellForm_NewListingLayoutQuery,
  core_apimessages_ShippingMethod as IShippingMethod,
  core_apimessages_PreorderInfoEntry,
  core_apimessages_PreorderInfoEntry_TimeType,
  core_apimessages_PreorderInfoEntry_LeadTimeUnit,
  core_apimessages_ScheduledPriceDropEntry,
  core_apimessages_Listing_SeedType as SeedType,
} from '@reverbdotcom/commons/src/gql/graphql';

import { formatInternationalizedSeconds } from '../../../lib/date_utils';
import { MeasurementSystem, buildPackageEntry } from '../../discovery/shipping_labels/shipping_label_package';
import { CARRIER_CALC_RATE_TYPE } from './components/shipping/ShippingForm';
import {
  ISellFormState,
  IListingEntryState,
  IAction,
  ActionType,
  Field,
  EMPTY_PREORDER_INFO,
  IMoneyFieldState,
  IShippingRateObjectState,
} from './sellFormReducerTypes';

export const DEFAULT_SEED_TYPE = SeedType.ComparisonShoppingPage;
const DONT_MARK_DIRTY_ACTIONS = [ActionType.HANDLE_SUBMIT_SUCCESS];

export function sellFormReducer(state: ISellFormState, action: IAction): ISellFormState {
  const dirty = DONT_MARK_DIRTY_ACTIONS.includes(action.type) ? false : true;

  return {
    ...state,
    dirty,
    listingEntry: listingEntryReducer(state.listingEntry, action),
  };
}

export function listingEntryReducer(state: IListingEntryState, action: IAction): IListingEntryState {
  switch (action.type) {
    case ActionType.UPDATE_FIELD:
      return {
        ...state,
        [action.fieldKey]: action.value,
      };

    case ActionType.UPDATE_LOCALIZED_FIELD:
      return {
        ...state,
        [Field.LOCALIZED_CONTENTS]: state[Field.LOCALIZED_CONTENTS].map((lc) => ({
          ...lc,
          ...(action.forLocale === lc.locale && {
            [action.fieldKey]: action.value,
          }),
        })),
      };

    case ActionType.HANDLE_SUBMIT_SUCCESS:
      return buildListingEntryStateFromPersistedListing(action.backendListing);

    case ActionType.SET_TRAIT: {
      // Remove existing trait if it matches the uuid of the updated trait
      const newTraits = [...state[Field.TRAITS]].filter((existingTrait) => existingTrait.keyId !== action.value.uuid);
      // Add new trait uuid and value to the array
      newTraits.push({ keyId: action.value.uuid, valueId: action.value.traitValue });
      return { ...state, [Field.TRAITS]: newTraits };
    }

    case ActionType.CLEAR_TRAITS:
      return { ...state, [Field.TRAITS]: [] };

    case ActionType.UPDATE_SELECTED_VARIANT:
      return {
        ...state,
        [Field.SEED_TYPE]: action.updateSeed ? core_apimessages_Listing_SeedType.CanonicalProduct : state[Field.SEED_TYPE],
        [Field.SEED_ID]: action.updateSeed ? action.value?.id : state[Field.SEED_ID],
        [Field.YEAR]: action.value?.year,
        [Field.COUNTRY]: action.value?.countryOfOrigin,
        [Field.LOCALIZED_CONTENTS]: state[Field.LOCALIZED_CONTENTS].map((lc) => {
          return {
            ...lc,
            ...(action.forLocale === lc.locale && {
              [Field.MAKE]: action.csp?.brand?.name,
              [Field.MODEL]: action.value?.model,
              [Field.FINISH]: action.value?.finish,
              [Field.TITLE]: generateListingTitle({
                make: action.csp?.brand?.name,
                model: action.value?.model,
                year: action.value?.year,
                finish: action.value?.finish,
              }),
            }),
          };
        }),
      };

    case ActionType.SET_DEFAULT_SHIPPING:
      return {
        ...state,
        [Field.SHIPPING_RATES]: action.shippingRates,
        [Field.SHIPPING_PROFILE_ID]: '',
        [Field.PREFERS_REVERB_SHIPPING_LABEL]: true,
        [Field.CARRIER_CALCULATED_PACKAGE]: null,
      };

    case ActionType.SET_NO_SHIPPING:
      return {
        ...state,
        [Field.SHIPPING_RATES]: [],
        [Field.SHIPPING_PROFILE_ID]: '',
        [Field.PREFERS_REVERB_SHIPPING_LABEL]: false,
        [Field.CARRIER_CALCULATED_PACKAGE]: null,
      };

    case ActionType.SET_SHIPPING_PROFILE_ID:
      return {
        ...state,
        [Field.SHIPPING_RATES]: [],
        [Field.SHIPPING_PROFILE_ID]: action.value,
        [Field.PREFERS_REVERB_SHIPPING_LABEL]: false,
        [Field.OFFERS_LOCAL_PICKUP]: false,
        [Field.CARRIER_CALCULATED_PACKAGE]: null,
      };

    case ActionType.ACTIVATE_CARRIER_CALCULATED_MODE:
      return {
        ...state,
        [Field.SHIPPING_RATES]: action.shippingRates,
        [Field.PREFERS_REVERB_SHIPPING_LABEL]: false,
        [Field.CARRIER_CALCULATED_PACKAGE]: buildPackageEntry({}, MeasurementSystem.IMPERIAL),
      };

    case ActionType.DEACTIVATE_CARRIER_CALCULATED_MODE:
      return {
        ...state,
        [Field.SHIPPING_RATES]: action.shippingRates,
        [Field.PREFERS_REVERB_SHIPPING_LABEL]: true,
        [Field.CARRIER_CALCULATED_PACKAGE]: null,
      };

    case ActionType.SET_HAS_INVENTORY:
      return {
        ...state,
        [Field.HAS_INVENTORY]: action.value,
        [Field.INVENTORY]: action.value ? state.inventory : null,
      };

    case ActionType.SET_CONDITION_SLUG:
      return {
        ...state,
        [Field.CONDITION_SLUG]: action.value,
        [Field.HAS_INVENTORY]: false,
        [Field.INVENTORY]: null,
        [Field.UPC_DOES_NOT_APPLY]: true,
        [Field.UPC]: '',
      };

    case ActionType.SET_UPC_DOES_NOT_APPLY:
      return {
        ...state,
        [Field.UPC_DOES_NOT_APPLY]: action.value,
        [Field.UPC]: action.value ? '' : state.upc,
      };
    case ActionType.SET_PREORDER_TIME_TYPE:
      return ({
        ...state,
        [Field.PREORDER_INFO]: {
          ...EMPTY_PREORDER_INFO,
          [Field.PREORDER_INFO_TIME_TYPE]: action.value,
        },
      });
    case ActionType.SET_PREORDER_INFO_FIELD:
      return ({
        ...state,
        [Field.PREORDER_INFO]: {
          ...state[Field.PREORDER_INFO],
          [action.fieldKey]: action.value,
        },
      });
    case ActionType.TOGGLE_SUPPORTED_LOCALE_CONTENT:
      if (action.value) { // Add locale content
        return {
          ...state,
          [Field.LOCALIZED_CONTENTS]: [
            ...state[Field.LOCALIZED_CONTENTS],
            {
              // Copy default locale data to prefill for the new locale
              ...state[Field.LOCALIZED_CONTENTS].find((lc) => lc.defaultForShop),
              [Field.LOCALE]: action.forLocale,
              [Field.DESCRIPTION]: '',
              [Field.PROP_65_WARNING]: '',
              [Field.DEFAULT_FOR_SHOP]: false,
            },
          ],
        };
      }
      return { // Remove locale content
        ...state,
        [Field.LOCALIZED_CONTENTS]: [
          ...state[Field.LOCALIZED_CONTENTS].filter((lc) => lc.locale !== action.forLocale),
        ],
      };
    case ActionType.SET_SCHEDULED_PRICE_DROP_ACTIVE:
      return {
        ...state,
        [Field.SCHEDULED_PRICE_DROPS]: [
          {
            ...state[Field.SCHEDULED_PRICE_DROPS][0],
            [Field.SCHEDULED_PRICE_DROP_ACTIVE]: action.value,
          },
        ],
      };
    case ActionType.SET_SCHEDULED_PRICE_DROP_NEW_PRICE:
      return {
        ...state,
        [Field.SCHEDULED_PRICE_DROPS]: [
          {
            ...state[Field.SCHEDULED_PRICE_DROPS][0],
            [Field.SCHEDULED_PRICE_DROP_NEW_PRICE]: action.value,
          },
        ],
      };

    default:
      return { ...state };
  }
}

export function initializeListingEntryState(
  seedId: string,
  seedType: SeedType,
  seedResult: Core_SellForm_NewListingLayoutQuery['seedListing']['listing'],
  defaultLocale: string,
): IListingEntryState {
  const seedTypeOrDefault = seedType || DEFAULT_SEED_TYPE;
  const validSeedTypes = Object.keys(SeedType);
  const hasValidSeed = !!seedId && validSeedTypes.includes(seedTypeOrDefault) && !!seedResult;
  const seedListingData = seedResult || {};
  const isCSPSeedType = seedTypeOrDefault === SeedType.ComparisonShoppingPage;
  const initialModel = !isCSPSeedType ? seedListingData?.model : '';

  return {
    [Field.ID]: '',
    [Field.SKU]: '',
    [Field.YEAR]: seedListingData.year || '',
    [Field.COUNTRY]: seedListingData.countryOfOrigin || '',
    [Field.SEED_ID]: hasValidSeed ? seedId : '',
    [Field.SEED_TYPE]: hasValidSeed ? seedTypeOrDefault : null,
    [Field.ROOT_CATEGORY_UUID]: seedListingData.categoryRootUuid || '',
    [Field.SUBCATEGORY_LEAF_UUIDS]: seedListingData.subcategoryLeafUuids || [],
    [Field.LOCALIZED_CONTENTS]: [
      {
        [Field.LOCALE]: defaultLocale,
        [Field.TITLE]: seedListingData.title || generateListingTitle(seedListingData),
        [Field.MAKE]: seedListingData.make || '',
        [Field.MODEL]: initialModel,
        [Field.FINISH]: seedListingData.finish || '',
        [Field.DESCRIPTION]: seedListingData.description || '',
        [Field.PROP_65_WARNING]: '',
        [Field.DEFAULT_FOR_SHOP]: true,
      },
    ],
    [Field.HANDMADE]: seedListingData.handmade || false,
    [Field.TRAITS]: [],
    [Field.CONDITION_SLUG]: seedListingData.conditionSlug || '',
    [Field.HAS_INVENTORY]: false,
    [Field.INVENTORY]: null,
    [Field.UPC]: '',
    [Field.UPC_DOES_NOT_APPLY]: true,
    [Field.VIDEO_LINK_URL]: '',
    [Field.PHOTOS]: [],
    [Field.SHIPPING_PROFILE_ID]: seedListingData.shippingProfileId || '',
    [Field.OFFERS_LOCAL_PICKUP]: false,
    [Field.SHIPPING_RATES]: [],
    [Field.CARRIER_CALCULATED_PACKAGE]: null,
    [Field.PREFERS_REVERB_SHIPPING_LABEL]: false,
    [Field.PRICE]: buildMoneyEntry(seedListingData.sellerPrice),
    [Field.SELLER_COST]: buildMoneyEntry(seedListingData.sellerCost),
    [Field.SELLER_REPORTED_MAP]: null,
    [Field.SOLD_AS_IS]: seedListingData.soldAsIs || false,
    [Field.OFFERS_ENABLED]: true,
    [Field.PREORDER_INFO]: { ...EMPTY_PREORDER_INFO },
    [Field.TAX_EXEMPT]: false,
    [Field.SCHEDULED_PRICE_DROPS]: [],
  };
}

export function generateListingTitle(seedListingData: Core_SellForm_NewListingLayoutQuery['seedListing']['listing']): string {
  return [
    seedListingData.make,
    seedListingData.model,
    seedListingData.year,
    seedListingData.finish ? `- ${seedListingData.finish}` : '',
  ].filter((v) => (v)).join(' ');
}

// All money fields in the sell form are localized number strings.
// These values will always be processed in the shop currency on the backend.
// When hydrating, we format incoming amounts according to the user's locale setting.
export function buildMoneyEntry(moneyObject?: core_apimessages_Money): IMoneyFieldState {
  if (!moneyObject) { return null; }

  return {
    amount: formatNumber(Number(moneyObject.amount)),
  };
}

export function buildListingEntryStateFromPersistedListing(existingListing: HydrateSellForm.Fragment): IListingEntryState {
  return {
    [Field.ID]: existingListing[Field.ID],
    [Field.SKU]: existingListing[Field.SKU],
    [Field.YEAR]: existingListing[Field.YEAR],
    [Field.COUNTRY]: existingListing[Field.COUNTRY],
    [Field.SEED_ID]: existingListing[Field.SEED_ID],
    [Field.SEED_TYPE]: existingListing[Field.SEED_TYPE],
    [Field.ROOT_CATEGORY_UUID]: existingListing[Field.ROOT_CATEGORY_UUID],
    [Field.SUBCATEGORY_LEAF_UUIDS]: existingListing.subcategoryUuids,
    [Field.LOCALIZED_CONTENTS]: existingListing[Field.LOCALIZED_CONTENTS].map((lc) => ({
      [Field.LOCALE]: lc[Field.LOCALE],
      [Field.MAKE]: lc[Field.MAKE],
      [Field.MODEL]: lc[Field.MODEL],
      [Field.FINISH]: lc[Field.FINISH],
      [Field.TITLE]: lc[Field.TITLE],
      [Field.DESCRIPTION]: Purify.sanitize(lc[Field.DESCRIPTION]),
      [Field.PROP_65_WARNING]: lc[Field.PROP_65_WARNING],
      [Field.DEFAULT_FOR_SHOP]: lc[Field.DEFAULT_FOR_SHOP],
    })),
    [Field.HANDMADE]: existingListing[Field.HANDMADE],
    [Field.TRAITS]: existingListing[Field.TRAITS].map((t) => ({
      [Field.TRAIT_UUID]: t[Field.TRAIT_UUID],
      [Field.TRAIT_VALUE_UUID]: t[Field.TRAIT_VALUE_UUID],
    })),
    [Field.CONDITION_SLUG]: existingListing.condition?.conditionSlug || '',
    [Field.HAS_INVENTORY]: existingListing[Field.HAS_INVENTORY],
    [Field.INVENTORY]: existingListing[Field.INVENTORY],
    [Field.UPC]: existingListing[Field.UPC],
    [Field.UPC_DOES_NOT_APPLY]: existingListing[Field.UPC_DOES_NOT_APPLY],
    [Field.VIDEO_LINK_URL]: existingListing.video?.link || '',
    [Field.PHOTOS]: buildPhotosStateFromPersistedListing(existingListing),
    [Field.SHIPPING_PROFILE_ID]: existingListing[Field.SHIPPING_PROFILE_ID],
    [Field.OFFERS_LOCAL_PICKUP]: existingListing.allShippingPrices.localPickup,
    [Field.SHIPPING_RATES]: buildShippingRatesStateFromPersistedListing(existingListing),
    [Field.CARRIER_CALCULATED_PACKAGE]: existingListing.shipmentPackage ? buildPackageEntry(existingListing.shipmentPackage) : null,
    [Field.PREFERS_REVERB_SHIPPING_LABEL]: existingListing[Field.PREFERS_REVERB_SHIPPING_LABEL],
    [Field.PRICE]: buildMoneyEntry(existingListing.sellerPrice),
    [Field.SELLER_COST]: buildMoneyEntry(existingListing[Field.SELLER_COST]),
    [Field.SELLER_REPORTED_MAP]: buildMoneyEntry(existingListing[Field.SELLER_REPORTED_MAP]),
    [Field.SOLD_AS_IS]: existingListing[Field.SOLD_AS_IS],
    [Field.OFFERS_ENABLED]: existingListing.listingType === core_apimessages_Listing_ListingType.MAKE_AN_OFFER,
    [Field.PREORDER_INFO]: buildPreorderInfoStateFromPersistedListing(existingListing),
    [Field.TAX_EXEMPT]: existingListing[Field.TAX_EXEMPT],
    [Field.SCHEDULED_PRICE_DROPS]: buildScheduledPriceDropStateFromPersistedListing(existingListing),
  };
}

function buildPhotosStateFromPersistedListing(existingListing: HydrateSellForm.Fragment): core_apimessages_ImageEntry[] {
  const { images = [] } = existingListing;
  return images.map((existingPhoto) => {
    const {
      id,
      publicId,
      version,
      transformation: transformationResponse,
      height,
      width,
    } = existingPhoto;

    return {
      id,
      publicId,
      version,
      height,
      width,
      transformation: transformationResponse ? {
        width: transformationResponse.width,
        height: transformationResponse.height,
        x: transformationResponse.x,
        y: transformationResponse.y,
        crop: transformationResponse.crop,
        angle: transformationResponse.angle,
      } : null,
    };
  });
}

function buildShippingRatesStateFromPersistedListing(existingListing: HydrateSellForm.Fragment): IShippingRateObjectState[] {
  if (existingListing.shippingProfileId) { return []; }
  if (existingListing.allShippingPrices.shippingPrices.length < 1) { return []; }

  const methodsByRegion = groupBy(existingListing.allShippingPrices.shippingPrices, 'shippingRegionCode');

  return Object.keys(methodsByRegion).map((regionCode) => {
    const standard = methodsByRegion[regionCode].find((m) => m.shippingMethod === IShippingMethod.SHIPPED);
    const combined = methodsByRegion[regionCode].find((m) => m.shippingMethod === IShippingMethod.COMBINED_SHIPPING);
    const expedited = methodsByRegion[regionCode].find((m) => m.shippingMethod === IShippingMethod.EXPEDITED_SHIPPING);

    return {
      regionCode,
      rate: standard.carrierCalculated ? null : buildMoneyEntry(standard?.originalRateVatExcluded),
      incrementalRate: buildMoneyEntry(combined?.originalRateVatExcluded),
      expeditedRate: buildMoneyEntry(expedited?.originalRateVatExcluded),
      rateType: standard.carrierCalculated ? CARRIER_CALC_RATE_TYPE : null,
    };
  });
}

function buildPreorderInfoStateFromPersistedListing(existingListing: HydrateSellForm.Fragment): core_apimessages_PreorderInfoEntry {
  const { preorderInfo } = existingListing;

  if (preorderInfo?.shipDate?.seconds) {
    return ({
      [Field.PREORDER_INFO_TIME_TYPE]: core_apimessages_PreorderInfoEntry_TimeType.SHIP_DATE,
      [Field.PREORDER_INFO_SHIP_DATE]: formatInternationalizedSeconds(preorderInfo.shipDate.seconds, 'YYYY-MM-DD'),
      [Field.PREORDER_INFO_LEAD_TIME_DAYS]: null,
      [Field.PREORDER_INFO_LEAD_TIME_UNIT]: core_apimessages_PreorderInfoEntry_LeadTimeUnit.DAYS,
    });
  }

  if (preorderInfo?.leadTimeDays) {
    return ({
      [Field.PREORDER_INFO_TIME_TYPE]: core_apimessages_PreorderInfoEntry_TimeType.LEAD_TIME,
      [Field.PREORDER_INFO_SHIP_DATE]: null,
      [Field.PREORDER_INFO_LEAD_TIME_DAYS]: preorderInfo.leadTimeDays,
      [Field.PREORDER_INFO_LEAD_TIME_UNIT]: core_apimessages_PreorderInfoEntry_LeadTimeUnit.DAYS,
    });
  }

  return { ...EMPTY_PREORDER_INFO };
}

function buildScheduledPriceDropStateFromPersistedListing(existingListing: HydrateSellForm.Fragment): core_apimessages_ScheduledPriceDropEntry[] {
  const { scheduledPriceDrops } = existingListing;

  return scheduledPriceDrops.map((drop) => {
    return {
      [Field.SCHEDULED_PRICE_DROP_ACTIVE]: drop.active,
      [Field.SCHEDULED_PRICE_DROP_NEW_PRICE]: buildMoneyEntry(drop.newPrice),
    };
  });
}
