import React from 'react';
import I18n from 'i18n-js';
import classNames from 'classnames';

import { useUser, useExpEnabled } from '@reverbdotcom/commons/src/user_hooks';
import CsrfForm from '@reverbdotcom/commons/src/csrf_form';
import { ADMINISTRATOR_PERMISSION_LEVEL } from '@reverbdotcom/commons/src/constants';
import experiments from '@reverbdotcom/commons/src/experiments';
import { WebThemeControl } from '@reverbdotcom/commons/src/components/web_theme_control';

import {
  buildSellingSection,
  buildBuyingSection,
  buildFinancesSection,
  buildMyAccountSection,
  buildExploreSection,
  buildJoinReverbSection,
  ILinkProps,
} from './reverb_header_menu_data';
import ReverbHeaderMenuSection from './reverb_header_menu_section';
import { Core_ReverbHeaderQuery } from '@reverbdotcom/commons/src/gql/graphql';
import { RCButton, RCIcon } from '@reverbdotcom/cadence/components';
import { BarsAltIcon } from '@reverbdotcom/cadence/icons/react';
import baseUrlHelpers from '../../../shared/url_helpers';


interface IProps {
  me: Core_ReverbHeaderQuery['me'];
}

const DEFAULT_AVATAR = 'https://res.cloudinary.com/reverb-preprod-cld/image/upload/s--t2fk8vm_--/v1699306207/hfgk738xixwp3yqkxjjw.jpg';

interface IMobileMenuSectionProps {
  data: {
    key: string;
    title: string;
    links: ILinkProps[]
  }
  onClick: React.MouseEventHandler<HTMLButtonElement>;
}

function renderSecondaryUserMenu(secondaryUserModeIsActive: boolean, managedShopName: string) {
  return (
    <>
      <div className="reverb-header__menu__user-menu__header">
        {I18n.t('discovery.reverbHeader.userMenu.secondaryUser.header')}
      </div>
      <div className="reverb-header__menu__user-menu__mobile_secondary_user_container">
        <span>
          {managedShopName}
        </span>

        <CsrfForm action={baseUrlHelpers.toggleSecondaryUserModePath} method="POST">
          <RCButton
            size="mini"
            variant="transparent"
            isSubmit={true}
          >
            {secondaryUserModeIsActive ? I18n.t('discovery.reverbHeader.userMenu.secondaryUser.logOut') :
              I18n.t('discovery.reverbHeader.userMenu.secondaryUser.logIn')}
          </RCButton>
        </CsrfForm>
      </div>
    </>
  );
}

export function MobileMenuSection({ data, onClick }: IMobileMenuSectionProps) {
  const { key, title, links } = data;

  return (
    <ReverbHeaderMenuSection key={key} title={title} links={links} onClick={onClick}/>
  );
}

function ReverbHeaderMobileMenu({ me }: IProps) {
  const user = useUser();
  const [open, setOpen] = React.useState(false);
  const classes = classNames(
    'reverb-header__mobile-menu',
    { 'reverb-header__mobile-menu--active': open },
  );

  const isShopAdmin = !user.secondaryUserModeIsActive || user.managedShopPermission == ADMINISTRATOR_PERMISSION_LEVEL;
  const shownAvatarUrl = (user.secondaryUserModeIsActive && user.managedShopUserAvatarUrl) || user.avatarUrl || DEFAULT_AVATAR;
  const { slug: shopSlug, paymentMethod: shopPaymentMethod } = me?.shop || {};

  const signedIn = !user.loggedOut;
  const themeExpEnabled = useExpEnabled(experiments.WEB_THEME_CONTROL);

  return (
    <>
      <li className="reverb-header__show-only-on-mobile">
        <button
          type="button"
          className="reverb-header__mobile-menu-toggle"
          onClick={() => setOpen(true)}
        >
          <RCIcon svgComponent={BarsAltIcon} />
        </button>
      </li>
      <div className={classes}>
        <button
          type="button"
          className="reverb-header__mobile-menu__close"
          onClick={() => setOpen(false)}
        >
          {I18n.t('discovery.reverbHeader.mobileMenu.close')}
        </button>
        <div className="reverb-header__mobile-menu__header reverb-header__mobile-menu__header--centered">
          {signedIn && (
            <>
              <div className="reverb-header__avatar" title={user.name}>
                <img width="64" height="64" src={shownAvatarUrl} alt={I18n.t('discovery.reverbHeader.userMenu.userAvatarAlt')} />
              </div>
              <div className="reverb-header__name reverb-header__name--mobile" title={user.name}>
                {user.name}
              </div>
            </>
          )}
        </div>
        {!!user.managedShopName && renderSecondaryUserMenu(user.secondaryUserModeIsActive, user.managedShopName)}
        <div className="g-container">
          {themeExpEnabled &&
            <div className="g-col-12 mb-1 pb-4 bdb-1 bd-color-primary">
              <div className="reverb-header__menu__user-menu__header">
                <span className="icon-r-lock">{I18n.t('discovery.reverbHeader.userMenu.theme')}</span>
              </div>
              <WebThemeControl />
            </div>
          }
          <div className="g-col-6">
            <MobileMenuSection data={buildExploreSection(user)} onClick={() => setOpen(false)} />
            {signedIn && (
              <>
                <MobileMenuSection data={buildBuyingSection(user)} onClick={() => setOpen(false)} />
                <MobileMenuSection data={buildMyAccountSection({ locale: user.locale })} onClick={() => setOpen(false)} />
              </>
            )}
          </div>
          <div className="g-col-6">
            {user.loggedOut && <MobileMenuSection data={buildJoinReverbSection(user.locale)} onClick={() => setOpen(false)} />}
            {signedIn && <MobileMenuSection data={buildSellingSection(shopSlug, isShopAdmin)} onClick={() => setOpen(false)} />}
            {isShopAdmin && shopPaymentMethod && <MobileMenuSection data={buildFinancesSection(shopPaymentMethod)} onClick={() => setOpen(false)} />}
          </div>
        </div>
      </div>
    </>
  );
}

export default ReverbHeaderMobileMenu;
