import I18n from 'i18n-js';
import React from 'react';
import Suggestion from './suggestion';
import bind from '@reverbdotcom/commons/src/bind';
import ScrollTarget from '@reverbdotcom/commons/src/components/scroll_target';
import { trackEvent } from '@reverbdotcom/commons/src/elog/mparticle_tracker';
import { MParticleEventName } from '@reverbdotcom/commons/src/elog/mparticle_types';
import { IUser } from '@reverbdotcom/commons/src/components/user_context_provider';
import { formatExperimentsForEventAttributes } from '@reverbdotcom/commons/src/elog/mparticle';

interface IProps {
  value: string;
  handleInputChange: Function;
  toggleDropdown: Function;
  suggestions: Suggestion[];
  selectedSuggestion: Suggestion;
  selectSuggestion: Function;
  setSelectedSuggestion: Function;
  submit: Function;
  placeholderText: string;
  user: IUser;
}

const UP_ARROW = 38;
const DOWN_ARROW = 40;
const ESC = 27;
const ENTER = 13;
const TAB = 9;
const MOBILE_WEB_BREAKPOINT = 640;

export default class Input extends React.Component<IProps, null> {
  textInput: HTMLInputElement;

  scrollTarget: ScrollTarget;

  move(increaser) {
    const lastItemIdx = this.props.suggestions.length - 1;

    const selected = this.props.suggestions.find(suggestion =>
      suggestion.positionEqual(this.props.selectedSuggestion),
    );

    let newIdx = this.props.suggestions.indexOf(selected);
    newIdx += increaser;

    if (newIdx < 0) { newIdx = lastItemIdx; }
    if (newIdx > lastItemIdx) { newIdx = 0; }

    const suggestion = this.props.suggestions[newIdx];
    this.props.setSelectedSuggestion(suggestion);
  }

  @bind
  handleKeyDown(evt) {
    if (evt.ctrlKey || evt.metaKey) {
      // don't do anything if modifier keys are used
      return;
    }
    if (evt.keyCode === UP_ARROW) {
      evt.preventDefault();
      this.move(-1);
      return;
    }
    if (evt.keyCode === DOWN_ARROW) {
      evt.preventDefault();
      this.move(1);
      return;
    }
    if (evt.keyCode === ESC || evt.keyCode === TAB) {
      this.props.toggleDropdown(false);
      return;
    }
    if (evt.keyCode === ENTER) {
      evt.preventDefault();
      if (this.props.selectedSuggestion) {
        this.props.selectSuggestion(this.props.selectedSuggestion);
      } else {
        this.props.submit(this.props.value);
      }
    }
  }

  @bind
  handleFocus() {
    const trackingData = {
      eventName: MParticleEventName.ClickedSearchInput,
      experiments: formatExperimentsForEventAttributes(this.props.user, []),
    };
    trackEvent(trackingData);

    const shouldScroll = window && window.innerWidth < MOBILE_WEB_BREAKPOINT && this.scrollTarget;

    if (shouldScroll) this.scrollTarget.scrollToTop();
    this.props.toggleDropdown(true);
  }

  @bind
  handleChange(evt) {
    this.props.handleInputChange(evt.target.value);
  }

  @bind
  bindRef(node) {
    if (!node) { return; }
    this.textInput = node;
  }

  render() {
    return (
      <>
        <ScrollTarget verticalOffset={-10} ref={r => this.scrollTarget = r} />
        <input
          type="text"
          ref={this.bindRef}
          className="site-search__controls__input"
          title={I18n.t('commons.searchBar.inputTitle')}
          autoCorrect="off"
          autoCapitalize="off"
          tabIndex={0}
          value={this.props.value}
          placeholder={this.props.placeholderText}
          onChange={this.handleChange}
          onKeyDown={this.handleKeyDown}
          onFocus={this.handleFocus}
        />
      </>
    );
  }
}
