import React from 'react';
import { LinkElement, LinkElementFunction } from '../helpers/linkElement';

import {
  RCSkeleton,
  RCNudge, RCNudgeProps,
  RCPriceBlock, RCPriceBlockProps,
  RCShopInfo, RCShopInfoProps,
  RCText,
} from '..';
import { preferLightTheme } from '../helpers/themeOverrides';
import { EyebrowTextProps } from '../RCText/RCText';
import { ListingTitleHtmlTags } from '../types';

interface RowCardEyebrow {
  text: string;
  color?: EyebrowTextProps['color'];
}

export interface RCListingRowCardProps {
  /**
   * `linkElement` is Cadence's way to support CoreLink.
   * Pass in a linkable element and in component's internals will determine how to render the `children` prop passed.
   *
   * Example:
   *
   * ```tsx
   * <RCListingRowCard
   *  title="Foo"
   *  linkElement={(children) => (
   *    <CoreLink
   *      to="/where/ever/you/want/"
   *      target="_blank"
   *    >
   *      {children}
   *    </CoreLink>
   *  )}
   *  ...
   * />
   * ```
   */
  linkElement?: LinkElementFunction;

  /**
   * Supports `<ImageCarousel>` only for now. Passing in anything else will likely "work" but may not look/function correctly.
   */
  thumbnailElement: React.ReactElement;

  /** The title of the listing */
  title?: string;

  /** The HTML tag of the title element. Defaults to 'div'. */
  titleHtmlTag?: keyof typeof ListingTitleHtmlTags;

  /** The condition of the listing. E.g. `Condition: Brand New`. This component is not responsible for appending `Condition: ` strings */
  conditionLabel?: React.ReactElement | string;

  /** Eyebrow element above the title. */
  eyebrow?: RowCardEyebrow;

  /** Eyebrow element above the price */
  secondaryEyebrow?: RowCardEyebrow;

  /** A human-readable string that shows the listing price */
  price?: RCPriceBlockProps;

  /** The watch element that gets rendered on top of the thumbnail in the upper-right corner of the card */
  favoriteButtonElement?: React.ReactElement;

  /** A `primary` action should be a `primary` RCButton variant. Actions passed in here should be RCButtons, or CoreLinks using RCButton styling. */
  primaryAction?: React.ReactElement;

  /** a `secondary` action should be a `secondary` RCButton. */
  secondaryAction?: React.ReactElement;

  /** a `tertiary` action should be a `tertiary` RCButton. */
  tertiaryAction?: React.ReactElement;

  /** hide action buttons on mobile screen width */
  hideActionsOnMobile?: boolean;

  /** Structured interface - See RCNudge for details */
  nudges?: RCNudgeProps[];

  /** Pass in non-UI-rendering HTML content here to help with SEO. Will be injected as the first DOM item of the card */
  metaElement?: React.ReactElement;

  /** When present, renders information about the shop */
  shopInfo?: RCShopInfoProps;

  /** When present, renders a violator on the lower left corner of the image.
   * Note: thumbnailElement must be present for `violator` to also appear. */
  violator?: React.ReactElement;

  /** Displays information about estimated monthly payment */
  estimatedMonthlyPaymentPlan?: JSX.Element | null;
}
export function RCListingRowCard({
  linkElement = undefined,
  thumbnailElement,
  eyebrow = undefined,
  secondaryEyebrow = undefined,
  title = undefined,
  titleHtmlTag = 'div',
  conditionLabel = undefined,
  price = undefined,
  favoriteButtonElement = undefined,
  primaryAction = undefined,
  secondaryAction = undefined,
  tertiaryAction = undefined,
  nudges = undefined,
  metaElement = undefined,
  shopInfo = undefined,
  violator = undefined,
  estimatedMonthlyPaymentPlan = null,
}: RCListingRowCardProps) {

  const TitleTag = titleHtmlTag as React.ElementType;

  return (
    <div className="rc-listing-row-card">
      {metaElement && (
        <div className="rc-listing-row-card__meta">
          {metaElement}
        </div>
      )}
      <div className="rc-listing-row-card__inner">
        <div className="rc-listing-row-card__thumbnail" {...preferLightTheme()}>
          {thumbnailElement}
          {violator && (
            <div className="rc-listing-row-card__violator">
              {violator}
            </div>
          )}
          {favoriteButtonElement && (
            <div className="rc-listing-row-card__favorite-button">
              {favoriteButtonElement}
            </div>
          )}
        </div>
        <div className="rc-listing-row-card__subcontent">
          <div className="rc_listing-row-card__title-container">
            {eyebrow && (
              <div className="rc-listing-row-card__eyebrow">
                <RCText.Eyebrow color={eyebrow.color}>{eyebrow.text}</RCText.Eyebrow>
              </div>
            )}
            {title &&
              <LinkElement
                styleName="rc-listing-row-card__title-link"
                linkElement={linkElement}
              >
                <TitleTag className="rc-listing-row-card__title">
                  {title}
                </TitleTag>
              </LinkElement>
            }
            {conditionLabel && (
              <div className="rc-listing-row-card__condition">
                {conditionLabel}
              </div>
            )}
            {shopInfo && (
              <div className="rc-listing-row-card__shop-info">
                <RCShopInfo {...shopInfo} />
              </div>
            )}
          </div>
          <div className="rc-listing-row-card__price-area">
            {secondaryEyebrow &&
              <div className="rc-listing-row-card__eyebrow">
                <RCText.Eyebrow color={secondaryEyebrow.color}>{secondaryEyebrow.text}</RCText.Eyebrow>
              </div>
            }
            {price && <RCPriceBlock {...price}/>}
            {estimatedMonthlyPaymentPlan}
            {!!nudges &&
              <div className="rc-listing-row-card__nudge-block">
                {nudges?.map(nudge => (
                  <div
                    key={nudge.label}
                    className="rc-listing-row-card__nudge">
                    <RCNudge {...nudge} />
                  </div>
                ))}
              </div>
            }
          </div>
          <div className="rc-listing-row-card__actions-area">
            {primaryAction}
            {secondaryAction}
            {tertiaryAction}
          </div>
        </div>
      </div>
    </div>
  );
}

function Skeleton() {
  return (
    <div className="rc-listing-row-card">
      <div className="rc-listing-row-card__inner">
        <div className="rc-listing-row-card__thumbnail">
          <RCSkeleton
            shape="square"
            display="block"
          />
        </div>
        <div className="rc-listing-row-card__title-area">
          <div className="rc-listing-row-card__header">
            <RCSkeleton shape="text" display="block" />
          </div>
        </div>
        <div className="rc-listing-row-card__subcontent">
          <div className="rc-listing-row-card__price-area">
            <RCSkeleton width="100%" />
          </div>
          <div className="rc-listing-row-card__actions-area">
            <RCSkeleton width="100%" />
          </div>
        </div>
      </div>
    </div>
  );
}

RCListingRowCard.Skeleton = Skeleton;
