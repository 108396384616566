import PropTypes from 'prop-types';
import React from 'react';
import { MobileDetection } from '@reverbdotcom/discovery-ui';

const BEGINNING = 'beginning';
const END = 'end';

class Fotorama extends React.Component {
  componentDidMount() {
    $(this.refs.fotorama).fotorama(this.props);
  }

  images() {
    return this.props.images.map((link, i) => (
      <a
        href={link.href}
        data-full={link.full}
        key={i}
        data-url={link.href}
      >
        <img itemProp="image" alt={link.title} src={link.thumb} />;
      </a>
    ));
  }

  videos() {
    return this.props.videos.map((link, i) => (
      <a
        href={link.href}
        key={i}
        data-url={link.href}
      >
        {link.title}
      </a>
    ));
  }

  render() {
    if (this.props.videoPosition === BEGINNING) {
      return (
        <div className="fotorama" ref="fotorama">
          {this.videos()}
          {this.images()}
        </div>
      );
    }

    return (
      <div className="fotorama" ref="fotorama">
        {this.images()}
        {this.videos()}
      </div>
    );
  }
}

Fotorama.propTypes = {
  nav: PropTypes.oneOf(['dots', 'thumbs', false]),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  click: PropTypes.bool,
  thumbheight: PropTypes.number,
  thumbwidth: PropTypes.number,
  thumbmargin: PropTypes.number,
  thumbborderwidth: PropTypes.number,
  keyboard: PropTypes.bool,
  allowfullscreen: PropTypes.bool,
  transitionduration: PropTypes.number,
  shadows: PropTypes.bool,
  swipe: PropTypes.bool,
  images: PropTypes.array,
  videos: PropTypes.array,
  videoPosition: PropTypes.oneOf([BEGINNING, END]),
};

Fotorama.defaultProps = {
  keyboard: true,
  width: '100%',
  height: '1/1',
  click: false,
  allowfullscreen: true,
  swipe: MobileDetection.isMobileBrowser(),
  transitionduration: 300,
  thumbheight: 64,
  thumbwidth: 64,
  thumbmargin: 4,
  thumbborderwidth: 0,
  shadows: true,
  loop: true,
  images: [],
  videos: [],
  videoPosition: END,
};

export default Fotorama;
