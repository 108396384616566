import React from 'react';
import I18n from 'i18n-js';

import { ErrorAlertBox } from '@reverbdotcom/commons/src/ErrorAlertBox';
import { useUser } from '@reverbdotcom/commons/src/user_hooks';
import { inMobileBrowser } from '@reverbdotcom/commons/src/user_context_helpers';

import { ExpressPayPalButtons } from '../checkout/ExpressPayPalButtons';
import URLHelpers from '../shared/url_helpers';
import { ExpressPaySeparator } from '../ExpressPaySeparator';
import CartApplePayButton from '../CartApplePayButton';
import { ApplePayConfig } from '../useCartApplePay';
import { core_apimessages_Money as Money } from '@reverbdotcom/commons/src/gql/graphql';
import CondensedOrderSummary from '../condensed_order_summary';

interface Props {
  checkoutBundlingId: string;
  amountTotal: Money;
  currency: string;
  showApplePayButton: boolean;
  applePayConfig: ApplePayConfig;
  showPayPalButton: boolean;
  showPaypalPayLaterButton: boolean;
}

export function CartHoistedExpressPayOptions({
  checkoutBundlingId,
  amountTotal,
  currency,
  showApplePayButton,
  applePayConfig,
  showPayPalButton,
  showPaypalPayLaterButton,
}: Props) {
  const user = useUser();
  const [errors, setErrors] = React.useState([]);

  if (!inMobileBrowser(user) || (!showApplePayButton && !showPayPalButton)) {
    return null;
  }

  return (
    <div className="cart-hoisted-express-pay-options">
      <CondensedOrderSummary
        itemPlusShippingSubtotal={amountTotal.display}
      />
      <div className="mtb-6">
        <p className="cart-payment-methods__protection">
          {I18n.t('discovery.paymentMethodSelection.protection.buyWithConfidence')}
          <span className="ml-space">
            <a
              href={URLHelpers.reverbProtectionPath}
              target="_blank"
              rel="noreferrer"
              className="text-link"
            >
              {I18n.t('discovery.paymentMethodSelection.protection.protectionIncluded')}
            </a>
          </span>
        </p>
      </div>
      {errors.length > 0 &&
        <div className="mb-4">
          <ErrorAlertBox errors={errors} />
        </div>
      }
      <h2 className="weight-bold size-140 mb-4">
        {I18n.t('discovery.checkout.expressCheckout.title')}
      </h2>
      <div className="mb-2">
        {showPayPalButton &&
          <div className="d-flex fx-dir-col gap-2">
            <ExpressPayPalButtons
              currency={currency}
              checkoutBundlingId={checkoutBundlingId}
              onError={setErrors}
              parentComponentName="CartHoistedExpressPayWrapper"
              amountCents={amountTotal.amountCents}
              showPaypalPayLaterButton={showPaypalPayLaterButton}
            />
          </div>
        }
      </div>
      {showApplePayButton &&
        <CartApplePayButton
          variant="black-express"
          checkoutBundlingId={checkoutBundlingId}
          amountTotal={amountTotal}
          applePayConfig={applePayConfig}
          tracking={{
            displayStyle: 'hoisted',
          }}
        />
      }
      <div className="mtb-4">
        <ExpressPaySeparator />
      </div>
    </div>
  );
}
