export default {
  genericError: 'Unexpected error occurred. Please try again.',
  toast: {
    theme: {
      success: 'success',
      error: 'error',
    },
    message: {
      success: 'Changes Saved!',
      failure: 'Unexpected error occurred. Please try again.',
      enable: {
        notEligible: 'Listing is not eligible for Affirm Zero Percent Financing.',
        alreadyEnabled: 'Listing is already in Affirm Zero Percent Financing.',
        failedToEnable: 'Affirm could not be enabled for listing.',
      },
      disable: {
        notInZeroPercent: 'Listing is not in Affirm Zero Percent Financing.',
        failedToDisable: 'Affirm could not be disabled for listing',
      },
    },
  },
  quickPriceEdit: {
    button: {
      addPrice: 'ADD PRICE',
      mostLikelyToSellPrice: 'Most likely to sell at {{displayPrice}} or less',
    },
    popover: {
      title: 'Save time editing prices',
      content: 'Now it\'s easier to keep your listing prices competitive.',
    },
    modal: {
      title: 'Edit Price',
      currentPrice: 'Current listing price: {{displayAmount}}',
      notifyWatchers: 'We notify your watchers when you drop the price by 5% or more.',
      moneyInput: {
        label: 'Your Price',
        newPrice: 'New Price',
        originalPriceHelpText: 'Was {{originalPrice}}',
        priceDropPrompt: '{{percentOff}}% price drop',
        originalPriceWithPriceDropHelpText: 'Was {{originalPrice}}. {{priceDropHTML}}',
        dropMorePrompt: 'Buyers see indications of price drops greater than 4%',
      },
      percentOffFilterChips: {
        label: 'Drop the price by',
        percentOff: '{{percentOff}}% Off',
      },
      priceRecommendation: {
        estimatedPriceRange: 'Estimated price range:',
      },
    },
  },
  quickInventoryEdit: {
    input: {
      label: 'Amount in stock',
    },
    button: {
      save: 'Save',
      saveAndRelist: 'Save and Relist',
    },
    toasts: {
      success: 'Amount in stock updated',
      relisted: 'Your listing has been relisted with new inventory',
      error: 'Sorry, something went wrong.',
    },
  },
  sellerListingCard: {
    sku: 'SKU: {{sku}}',
    inStock: '{{inventory}} in stock',
    editInventory: 'Edit Inventory',
    condition: '{{condition}} Condition',
    edit: 'Edit',
    relist: 'Relist',
    end: 'End',
    pricedAboveAverage: 'Priced above average',
    views: {
      one: '{{count}} view',
      other: '{{count}} views',
    },
    watchers: {
      one: '{{count}} watcher',
      other: '{{count}} watchers',
    },
    inBuyerCarts: {
      one: 'in {{count}} cart',
      other: 'in {{count}} carts',
    },
    bumped: 'Bumped at {{percent}}%',
    offers: {
      one: '{{count}} Offer',
      other: '{{count}} Offers',
      autoOffers: {
        autoOffersManage: 'Manage Direct Offers',
      },
    },
    orders: {
      one: '{{count}} Order',
      other: '{{count}} Orders',
    },
    daysAgo: {
      lessThanOne: 'Less than 1 day old',
      one: '{{count}} day old',
      other: '{{count}} days old',
    },
    monthsAgo: {
      one: '{{count}} month old',
      other: '{{count}} months old',
    },
    yearsAgo: {
      one: '{{count}} year old',
      other: '{{count}} years old',
    },
    states: {
      draft: 'Draft',
      ended: 'Ended',
      expired: 'Expired',
      live: 'Live',
      ordered: 'Ordered',
      sold: 'Sold',
      soldOut: 'Sold Out',
      suspended: 'Suspended',
      upcoming: 'Upcoming',
      sellerUnavailable: 'On Vacation',
    },
    draftErrors: 'You must address these issues in order to publish:',
    publishListing: 'Publish Listing',
    publish: 'Publish',
    publishSuccess: 'Listing Published',
    endSuccess: 'Listing Ended',
    endError: 'Unable to end listing.',
    listingFetchFail: 'An error occurred while updating this listing. Please refresh the page.',
    deleteDraft: 'Delete Draft',
    deleteDraftConfirm: 'Are you sure you want to delete this draft listing: {{title}}?',
    deleteDraftError: 'This listing cannot be deleted:',
    sales: {
      salesButtonText: 'Sales',
      modal: {
        title: 'Add Listings to Sales',
        reverbSales: {
          tabTitle: 'Reverb Sales',
          description: 'Eligible themed sales for this listing that Reverb runs and promotes.',
        },
        sellerSales: {
          tabTitle: 'Your Sales',
          description: 'Sales that you created for your shop.',
          noSalesDescription: 'You don’t currently have any sales for items in your shop.',
        },
        saleCard: {
          saleDateRange: '{{startsAt}} - {{endsAt}}',
          salePercent: '{{percent}}% Off',
          totalLiveListings: {
            one: '{{count}} listing added',
            other: '{{count}} listings added',
          },
          viewSale: 'View',
          apply: 'Apply',
          applied: 'Applied',
          createNewSalePrompt: 'Want to create a new sale?',
          createSale: 'Create Sale',
        },
        closeButtonText: 'Close',
      },
    },
    endListingModal: {
      modalHeaderTitle: 'End Listing',
      modalTitle: 'Need to end your listing? No problem! Please let us know why:',
      modalEndButton: 'End Listing',
      soldOption: 'I sold it elsewhere or am no longer selling',
      foundOption: 'I found my buyer on Reverb -- charge me {{feePercent}}% ({{feeAmountDisplay}})',
    },
    affirmZeroPercent: {
      enableButtonText: 'Enable 0% Financing',
      disableButtonText: 'Disable 0% Financing',
      affirmIsEnabled: '0% financing is enabled.',
      content: '0% interest financing through Affirm gives buyers more ways to pay.',
      learnMore: 'Learn More',
      productIsNoLongerEligible: 'Listing is no longer eligible for 0% financing',
      fee: '{{supOpen}}*{{supClose}}A 6% co-op fee is deducted from the payout on any order sold via this promotion. Eligible items are sold at listed price and cannot be combined with Offers or other discounts.',
    },
  },
  toolbar: {
    createNewListing: 'Create New Listing',
    selectListings: 'Select listings',
    selectedListings: '{{count}} selected',
    selectedListingsCallouts: {
      one: 'You have selected {{count}} listing on the page.',
      other: 'You have selected {{count}} listings on the page.',
    },
    allListingsSelectedCallout: {
      one: 'You have selected the only listing on the page.',
      other: 'You have selected all {{formattedCount}} listings on the page.',
    },
    allMatchesSelectedCallout: {
      one: "You've selected {{formattedCount}} listing. Some bulk actions may take a few minutes",
      other: "You've selected {{formattedCount}} listings. Some bulk actions may take a few minutes",
    },
    clearSelectionOfMatches: 'Clear Selection',
    tooManyMatches: '<span class="weight-bold">You can select up to {{max}} listings. Filter listings to below {{belowAmount}}.</span>',
    selectAllMatches: {
      one: 'Select {{count}} Listing',
      other: 'Select All {{formattedCount}} Listings',
    },
    popover: {
      title: 'Manage multiple listings',
      content: 'Select multiple listings to easily manage them in bulk.',
    },
    bulkActions: {
      bulkPublish: {
        publishSelected: 'Publish Selected',
        publishSuccessful: {
          one: '<span class="weight-bold">{{formattedCount}} listing was published.</span> It may take a few minutes to see it go live.',
          other: '<span class="weight-bold">{{formattedCount}} listings were published.</span> It may take a few minutes to see them go live.',
        },
        asyncResponses: {
          successes: {
            one: '<span class="weight-bold">{{formattedCount}} listing has been submitted to be published.</span>  Changes may take a few minutes and won\'t apply if listing is ineligible.',
            other: '<span class="weight-bold">{{formattedCount}} listings have been submitted to be published.</span> Changes may take a few minutes and won\'t apply to ineligible listings.',
          },
        },
        publishErrors: {
          title: {
            one: '{{formattedCount}} listing could not be published:',
            other: '{{formattedCount}} listings could not be published:',
          },
        },
        publishErrorModal: {
          publish: 'Publish',
          close: 'Close',
          headerTitle: {
            one: 'Publish {{count}} Listing',
            other: 'Publish {{count}} Listings',
          },
          publishMessage: {
            one: "You're about to Publish {{count}} listing.",
            other: "You're about to Publish {{count}} listings.",
          },
          errorMessage: {
            one: '{{count}} selected listing cannot be published.',
            other: '{{count}} selected listings cannot be published.',
          },
        },
        prepublishStepRequired: "Please finish setting up your <a class='text-link weight-bold' href='/my/selling/shop_policies/edit' target='_blank'> shop and billing info</a>.",
      },
      bulkDelete: {
        deleteSelected: 'Delete Selected',
        deleteSuccessful: {
          one: '<span class="weight-bold">{{formattedCount}} listing was deleted.</span>',
          other: '<span class="weight-bold">{{formattedCount}} listings were deleted.</span>',
        },
        deleteErrors: {
          title: {
            one: '{{formattedCount}} listing could not be deleted:',
            other: '{{formattedCount}} listings could not be deleted:',
          },
        },
        asyncResponses: {
          successes: {
            one: '<span class="weight-bold">{{formattedCount}} listing has been submitted to be deleted.</span> Changes may take a few minutes and won\'t apply if listing is ineligible.',
            other: '<span class="weight-bold">{{formattedCount}} listings have been submitted to be deleted.</span>  Changes may take a few minutes and won\'t apply to ineligible listings.',
          },
        },
        deleteErrorModal: {
          delete: 'Delete',
          close: 'Close',
          headerTitle: {
            one: 'Delete {{count}} Listing',
            other: 'Delete {{count}} Listings',
          },
          deleteMessage: {
            one: "You're about to Delete {{count}} listing.",
            other: "You're about to Delete {{count}} listing.",
          },
          errorMessage: {
            one: '{{count}} selected listing cannot be deleted.',
            other: '{{count}} selected listings cannot be deleted.',
          },
        },
      },
      bump: {
        chipText: 'Bump',
        bumpListings: 'Bump Listings',
        removeBump: 'Remove Bump from Listings',
        createModal: {
          headerTitle: 'Bump Listings',
          buyerText: '<span class="weight-bold">Only pay if it sells.</span> Bump rate is a percentage of each listing\'s price, on top of the 5% selling fee.',
          selectingBumpWill: 'Selecting a Bump rate and Bumping listings will:',
          noSelectedListingsEligible: '<span class="weight-bold">0<span> selected listings eligible for Bump.',
          enableText: {
            one: 'Bump <span class="weight-bold">{{formattedCount}}</span> selected listing with a rate you select.',
            other: 'Bump <span class="weight-bold">{{formattedCount}}</span> selected listings with a rate you select.',
          },
          adjustText: {
            one: 'Adjust the Bump rate for <span class="weight-bold">{{formattedCount}}</span> selected listing.',
            other: 'Adjust the Bump rate for <span class="weight-bold">{{formattedCount}}</span> selected listings.',
          },
          rateDisplay: '{{percent}}%',
          input: {
            label: 'Select Bump Rate',
          },
          submit: 'Bump Listings',
          successes: {
            one: '<span class="weight-bold">{{formattedCount}} listing was Bumped at {{bumpRate}}.</span>',
            other: '<span class="weight-bold">{{formattedCount}} listings were Bumped at {{bumpRate}}.</span>',
          },
          errors: {
            title: {
              one: '{{formattedCount}} listing could not be Bumped:',
              other: '{{formattedCount}} listings could not be Bumped:',
            },
          },
          asyncResponses: {
            successes: {
              one: "<span class='weight-bold'>{{formattedCount}} listing has been submitted for Bump at {{bumpRate}}.</span> Changes may take a few minutes and won't apply to ineligible listings.",
              other: "<span class='weight-bold'>{{formattedCount}} listings have been submitted for Bump at {{bumpRate}}.</span> Changes may take a few minutes and won't apply to ineligible listings.",
            },
          },
        },
        deleteModal: {
          headerTitle: 'Remove Bump',
          buyerText: '<span class="weight-bold">Only pay if it sells.</span> Bump rate is a percentage of each listing\'s price, on top of the 5% selling fee.',
          disableText: {
            zero: '<span class="weight-bold">{{formattedCount}}</span> selected listings have Bump enabled.',
            one: '<span class="weight-bold">{{formattedCount}}</span> selected listing has Bump enabled. <span class="weight-bold">Want to remove Bump for this listing?</span>',
            other: '<span class="weight-bold">{{formattedCount}}</span> selected listings have Bump enabled. <span class="weight-bold">Want to remove Bump for these listings?</span>',
          },
          submit: 'Remove Bump',
          successes: {
            one: '<span class="weight-bold">{{formattedCount}} listing was removed from Bump.</span>',
            other: '<span class="weight-bold">{{formattedCount}} listings were removed from Bump.</span>',
          },
          errors: {
            title: {
              one: '{{formattedCount}} listing could not be removed from Bump.:',
              other: '{{formattedCount}} listings could not be removed from Bump.:',
            },
          },
          asyncResponses: {
            successes: {
              one: '<span class="weight-bold">{{formattedCount}} listing has been submitted for removal of Bump.</span> Changes may take a few minutes.',
              other: '<span class="weight-bold">{{formattedCount}} listings have been submitted for removal of Bump.</span> Changes may take a few minutes.',
            },
          },
        },
      },
      directOffers: {
        chipText: 'Direct Offers',
        enableDirectOffers: 'Enable Direct Offers',
        disableDirectOffers: 'Disable Direct Offers',
        createModal: {
          buyerText: {
            line1: 'Buyers get targeted deals with Direct Offers when they watch items or leave items in their carts.',
            line2: {
              one: 'Enter a percent off amount and enable or adjust Direct Offers for <span class="weight-bold">{{formattedCount}}</span> eligible selected listing.',
              other: 'Enter a percent off amount and enable or adjust Direct Offers for <span class="weight-bold">{{formattedCount}}</span> eligible selected listings.',
            },
          },
          noSelectedListingsEligible: '<span class="weight-bold">0</span> selected listings eligible for Direct Offers.',
          ineligibleListingsText: 'Direct Offers won\'t be applied in bulk for ineligible listings you may have selected. You can enable Direct Offers individually for listings with carrier calculated shipping. Local pickup listings aren\'t eligible for Direct Offers.',
          input: {
            label: 'Percent Off',
            helpText: 'Must be 5% or greater.',
            placeholder: 'Example: 5',
          },
          successes: {
            one: '<span class="weight-bold">{{formattedCount}} listing was enabled for Direct Offers at {{percentOff}}% off.</span>',
            other: '<span class="weight-bold">{{formattedCount}} listings were enabled for Direct Offers at {{percentOff}}% off.</span>',
          },
          errors: {
            title: {
              one: '{{formattedCount}} listing was not enabled for Direct Offers:',
              other: '{{formattedCount}} listings were not enabled for Direct Offers:',
            },
          },
          asyncResponses: {
            successes: {
              one: '<span class="weight-bold">{{formattedCount}} listing has been submitted for Direct Offers at {{percentOff}}% off.</span> Changes may take a few minutes and won\'t apply to ineligible listings.',
              other: '<span class="weight-bold">{{formattedCount}} listings have been submitted for Direct Offers at {{percentOff}}% off.</span> Changes may take a few minutes and won\'t apply to ineligible listings.',
            },
          },
        },
        deleteModal: {
          buyerText: 'Buyers get Direct Offers when they Watch the listing or add it to their cart without checking out.',
          disableText: {
            zero: '<span class="weight-bold">{{formattedCount}}</span> selected listings have Direct Offers enabled.',
            one: '<span class="weight-bold">{{formattedCount}}</span> selected listing has Direct Offers enabled. <span class="weight-bold">Want to disable Direct Offers for this listing?</span>',
            other: '<span class="weight-bold">{{formattedCount}}</span> selected listings have Direct Offers enabled. <span class="weight-bold">Want to disable Direct Offers for these listings?</span>',
          },
          successes: {
            one: '<span class="weight-bold">{{formattedCount}} listing was disabled for Direct Offers.</span>',
            other: '<span class="weight-bold">{{formattedCount}} listings were disabled for Direct Offers.</span>',
          },
          errors: {
            title: {
              one: '{{formattedCount}} listing was not disabled for Direct Offers:',
              other: '{{formattedCount}} listings were not disabled for Direct Offers:',
            },
          },
          asyncResponses: {
            successes: {
              one: '<span class="weight-bold">{{formattedCount}} listing has been submitted for removal of Direct Offers.</span> Changes may take a few minutes.',
              other: '<span class="weight-bold">{{formattedCount}} listings have been submitted for removal of Direct Offers.</span> Changes may take a few minutes.',
            },
          },
        },
      },
      affirm: {
        chipText: '0% Financing',
        enableAffirm: 'Enable 0% Financing',
        disableAffirm: 'Disable 0% Financing',
        createModal: {
          headerTitle: 'Enable 0% Financing',
          submit: 'Enable 0% Financing',
          description: '0% interest rate financing through Affirm gives buyers more ways to pay.',
          actions: {
            zero: "You have <span class='weight-bold'>{{formattedCount}}</span> selected listings eligible for 0% Financing.",
            one: "You have <span class='weight-bold'>{{formattedCount}}</span> selected listing that is eligible for 0% Financing. <span class='weight-bold'>Want to enable  0% Financing for this listing?</span>",
            other: "You have <span class='weight-bold'>{{formattedCount}}</span> selected listings that are eligible for 0% Financing. <span class='weight-bold'>Want to enable  0% Financing for these listings?</span>",
          },
          note: '*Note: Bulk operation excludes preorder, local pickup only, and on sale listings. Only valid for listings priced between $50 and $25,000, excluding shipping.',
          successes: {
            one: '<span class="weight-bold">{{formattedCount}} listing was enabled for 0% Financing.</span>',
            other: '<span class="weight-bold">{{formattedCount}} listings were enabled for 0% Financing.</span>',
          },
          errors: {
            title: {
              one: '{{formattedCount}} listing was not enabled for 0% Financing:',
              other: '{{formattedCount}} listings were not enabled for 0% Financing:',
            },
          },
          asyncResponses: {
            successes: {
              one: '<span class="weight-bold">{{formattedCount}} listing has been submitted for 0% Affirm Financing.</span> Changes may take a few minutes and won\'t apply to ineligible listings.',
              other: '<span class="weight-bold">{{formattedCount}} listings have been submitted for 0% Affirm Financing.</span> Changes may take a few minutes and won\'t apply to ineligible listings.',
            },
          },
        },
        deleteModal: {
          headerTitle: 'Disable 0% Financing',
          submit: 'Disable 0% Financing',
          description: '0% interest rate financing through Affirm gives buyers more ways to pay.',
          actions: {
            zero: "You have <span class='weight-bold'>{{formattedCount}}</span> selected listings enabled for 0% Financing.",
            one: "You have <span class='weight-bold'>{{formattedCount}}</span> selected listing that is enabled for 0% Financing. <span class='weight-bold'>Want to disable  0% Financing for this listing?</span>",
            other: "You have <span class='weight-bold'>{{formattedCount}}</span> selected listings that are enabled for 0% Financing. <span class='weight-bold'>Want to disable  0% Financing for these listings?</span>",
          },
          successes: {
            one: '<span class="weight-bold">{{formattedCount}} listing was disabled for 0% Financing.</span>',
            other: '<span class="weight-bold">{{formattedCount}} listings were disabled for 0% Financing.</span>',
          },
          errors: {
            title: {
              one: '{{formattedCount}} listing was not disabled for 0% Financing:',
              other: '{{formattedCount}} listings were not disabled for 0% Financing:',
            },
          },
          asyncResponses: {
            successes: {
              one: '<span class="weight-bold">{{formattedCount}} listing has been submitted for removal of 0% Affirm Financing.</span> Changes may take a few minutes.',
              other: '<span class="weight-bold">{{formattedCount}} listings have been submitted for removal of 0% Affirm Financing.</span> Changes may take a few minutes.',
            },
          },
        },
      },
      sales: {
        addToSales: 'Add to Sales',
        modal: {
          headerTitle: 'Bulk Add to Sale',
          close: 'Done',
          selectedListings: {
            one: 'You have {{formattedCount}} selected listing.',
            other: 'You have {{formattedCount}} selected listings.',
          },
          reverbPromotedSales: 'Reverb Promoted Sales',
          mySales: 'My Sales',
          saleType: 'Sale Type',
          saleStatus: 'Sale Status',
          sortBy: 'Sort By',
          searchSales: 'Search Sales',
          searchPlaceholder: 'Example: Sale name',
          states: {
            all: 'All Sales',
            live: 'Active',
            upcoming: 'Upcoming',
          },
          sorts: {
            alphabetical: 'A-Z',
            reverseAlphabetical: 'Z-A',
            endingSoonest: 'Ending Soonest',
          },
          noSales: 'No sales for selected filters.',
          createSale: 'Create a new sale',
          saleCard: {
            reverbPromotedSale: 'Reverb Promoted Sale',
            datesActive: 'Dates Active',
            discount: 'Discount',
            status: 'Status',
            statuses: {
              live: 'Active',
              upcoming: 'Upcoming',
              ended: 'Ended',
            },
            viewSalePage: '(view sale page)',
            saleRunning: '{{startDate}} - {{endDate}}',
            adds: {
              one: 'Add {{formattedCount}} Eligible Listing',
              other: 'Add {{formattedCount}} Eligible Listings',
            },
            removes: {
              one: '{{formattedCount}} listing added',
              other: '{{formattedCount}} listings added',
            },
            removeLink: '(remove)',
            noEligibleListings: 'No eligible selected listings for this sale.',
            messages: {
              adding: {
                successes: {
                  one: '{{formattedCount}} listing successfully added to sale.',
                  other: '{{formattedCount}} listings successfully added to sale.',
                },
                errors: {
                  title: {
                    one: '{{formattedCount}} listing could not be added to sale:',
                    other: '{{formattedCount}} listings could not be added to sale:',
                  },
                },
              },
              removing: {
                successes: {
                  one: '{{count}} listing successfully removed from sale.',
                  other: '{{count}} listings successfully removed from sale.',
                },
              },
            },
          },
        },
      },
      publishAll: 'Publish All Drafts',
      exportListings: 'Export Listings',
      exports: {
        states: {
          live: 'Export All Live',
          draft: 'Export All Drafts',
          ended: 'Export All Ended',
          ordered: 'Export All Ordered',
          suspended: 'Export All Suspended',
          sellerUnavailable: 'Export All on Vacation',
          soldOut: 'Export All Out of Stock',
        },
        exportAll: 'Export All Listings',
        exportSelected: 'Export Selected',
      },
      listingErrorCount: {
        one: '({{formattedCount}} listing)',
        other: '({{formattedCount}} listings)',
      },
    },
  },
  sorting: {
    sortBy: 'Sort by: <strong>{{sortByOption}}</strong>',
    pricedHighToLow: 'Priced High to Low',
    pricedLowToHigh: 'Priced Low to High',
    newestToOldest: 'Newest to Oldest',
    oldestToNewest: 'Oldest to Newest',
  },
  sidebar: {
    collapseAll: 'Collapse All',
    facets: {
      keywordSearch: 'Keyword Search',
      sortBy: 'Sort by',
      status: 'Status',
      insights: 'Insights',
      pricingInsights: 'Pricing insights',
      engagementInsights: 'Engagement insights',
      price: 'Price',
      category: 'Category',
      brand: 'Brand',
      condition: 'Condition',
      listingAge: 'Listing Age',
      promotionsAndSales: 'Promotions and sales',
      offersAndSales: 'Offers and sales',
      shippingAndPickup: 'Shipping and Pickup',
      directOffers: 'Direct Offers',
      buyerOffers: 'Buyer Offers',
      zeroFinancing: 'Affirm 0% Financing',
      sales: 'Sales',
      searchForASale: 'Search for a sale',
      reverbBump: 'Reverb Bump',
      sublabels: {
        pricedAboveAverage: {
          listingsSellFaster: 'Lower your prices to sell faster',
        },
      },
    },
    searchForm: {
      queryPlaceholder: 'Search your listings',
    },
  },
  emptyState: {
    noListingsMatching: 'No listings matching your search.',
    noListingsOnPage: 'No listings on this page. Go to another page.',
    noListings: "You don't have any listings.",
    createNewListing: 'Create a new listing',
    clearFilters: 'Clear filters',
  },
  recommendationsSummary: {
    title: 'Tips to help you sell',
    items: {
      one: '1 item',
      other: '{{count}} items',
    },
    priceRecommendation: {
      one: 'is priced above average. Review your price.',
      other: 'are priced above average. Review your prices.',
    },
    directOffersRecommendation: {
      one: 'has watchers you can keep engaged by sending Direct Offers.',
      other: 'have watchers you can keep engaged by sending Direct Offers.',
    },
  },
};
