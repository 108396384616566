import { FetchResult } from '@apollo/client';
import { RCAlertBox, RCButton, RCLoadingBars, RCTextWithIcon } from '@reverbdotcom/cadence/components';
import React from 'react';
import { I18N as Translate } from '@reverbdotcom/commons/src/components/translate';
import I18n from 'i18n-js';
import {
  FetchTaxFormDownloadMutation,
  core_apimessages_TaxFormDownload_Status as TaxFormDownloadStatus,

} from '@reverbdotcom/commons/src/gql/graphql';
import { DownloadIcon, LockIcon } from '@reverbdotcom/cadence/icons/react';
import { NoTaxFormsToDisplay } from './no_tax_forms_to_display';
import FormSection from '@reverbdotcom/commons/src/components/form_section';
import { ITaxForm, MutationProps } from '../tax_profile_helpers';

interface ExternalProps {
  taxForms: ITaxForm[];
}

export type Props = ExternalProps & MutationProps;

interface TaxFormRowExternalProps {
  taxForm: ITaxForm;
  requestButtonsInactive: boolean;
  onTaxFormLoadingChanged: (isLoading: boolean) => void;
}

type TaxFormRowProps = TaxFormRowExternalProps & MutationProps;

interface TaxFormSectionProps {
  children: React.ReactNode;
}

const TaxFormSection = ({ children }: TaxFormSectionProps) => {
  const currentDate = new Date();
  // we only show the "here is how to request a paper form" message from October to December
  const shouldShowPaperFormMessage = currentDate.getMonth() >= 9;

  return (
    <FormSection
      title={
        <Translate
          id="tax-forms"
          text="discovery.taxProfile.taxForms.title"
        />
      }
      description={
        shouldShowPaperFormMessage && (
          <Translate
            tag="p"
            text="discovery.taxProfile.taxForms.description"
          >
            <a href="mailto:1099taxreporting@reverb.com" className="text-link">
              <Translate text="discovery.taxProfile.taxForms.emailLinkText" />
            </a>
          </Translate>
        )
      }
    >
      {children}
    </FormSection>
  );
};

function TaxFormRow({ taxForm, requestButtonsInactive, onTaxFormLoadingChanged, requestTaxFormDownload, fetchTaxFormDownload }: TaxFormRowProps) {
  const [taxFormDownload, setTaxFormDownload] = React.useState(taxForm.taxFormDownload);
  const [showError, setShowError] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const setTaxFormLoading = (isLoading) => {
    setLoading(isLoading);
    onTaxFormLoadingChanged(isLoading);
  };

  React.useEffect(() => {
    if (taxFormDownload?.status === TaxFormDownloadStatus.PENDING) {
      requestForm(`${taxFormDownload.id}`);
    }
  }, []);

  const requestForm = async (taxFormDownloadId: string = null) => {
    setShowError(false);
    setTaxFormLoading(true);
    let id = taxFormDownloadId;
    try {
      if (!id) {
        const response = await makeFormRequest();
        ({ id } = response.data.requestTaxFormDownload.taxFormDownload);
      }
      const downloadFormData: FetchResult<FetchTaxFormDownloadMutation, Record<string, any>> = await pollForFormDownload(id);
      const { status } = downloadFormData.data.taxFormDownload.taxFormDownload;

      if (status === TaxFormDownloadStatus.COMPLETE) {
        setTaxFormDownload(downloadFormData.data.taxFormDownload.taxFormDownload);
      } else {
        setShowError(true);
      }
    } catch {
      setShowError(true);
    } finally {
      setTaxFormLoading(false);
    }
  };

  const makeFormRequest = async () => {
    const response = await requestTaxFormDownload({ variables: {
      input: {
        taxFormId: `${taxForm.id}`,
      },
    } });
    if (!response || response.data?.requestTaxFormDownload.error) {
      throw new Error('error');
    }
    return response;
  };

  const pollForFormDownload = async (taxFormDownloadId: string) => {
    let downloadFormData: void | FetchResult<FetchTaxFormDownloadMutation, Record<string, any>>;
    let status: TaxFormDownloadStatus;
    do {
      await new Promise(r => setTimeout(r, 5000));

      downloadFormData = await fetchTaxFormDownload({ variables: { input: { id: taxFormDownloadId } } });
      if (!downloadFormData) {
        throw new Error('error');
      }
      ({ status } = downloadFormData.data.taxFormDownload.taxFormDownload);
    }
    while (status === TaxFormDownloadStatus.PENDING);
    return downloadFormData;
  };

  const shouldShowRequestDownload = () => {
    return taxForm &&
    !loading &&
    !shouldShowDownloadButton();
  };

  const shouldShowDownloadButton = () => {
    return taxFormDownload?.status === TaxFormDownloadStatus.COMPLETE &&
      !loading;
  };

  return (
    <>
      <tr>
        <td><strong>{taxForm.taxYear}</strong></td>
        <td>
          <div className="g-container d-flex mobile-d-block mobile-ml-4">
            <div className="d-flex fx-dir-col fx-justify-center g-col-mobile-12">
              {
                taxForm.lastRequestedAt ?
                  I18n.t('discovery.taxProfile.taxForms.downloadRequestedStatus', { date: taxForm.lastRequestedAt }) :
                  I18n.t('discovery.taxProfile.taxForms.downloadNotYetRequested')
              }
            </div>
            <div className="fx-grow g-col-mobile-12 mobile-mt-2">
              { showError && (
                <div className="mb-4">
                  <RCAlertBox
                    type="error"
                  >
                    <Translate
                      text="discovery.taxProfile.taxForms.failure"
                    />
                  </RCAlertBox>
                </div>
              )}
              <div className="f-right mobile-f-left">
                <div className="d-flex fx-dir-col fx-align-center">
                  { loading && (
                    <>
                      <RCButton
                        variant="muted"
                        size="small"
                      >
                        <div className="d-flex fx-align-center">
                          <div className="mlr-2">
                            <RCLoadingBars
                              size="small"
                            />
                          </div>
                          <Translate
                            text="discovery.taxProfile.taxForms.loading"
                          />
                        </div>
                      </RCButton>
                    </>
                  )}
                  { shouldShowRequestDownload() && (
                    <RCButton
                      disabled={requestButtonsInactive}
                      variant="muted"
                      size="small"
                      onClick={() => requestForm()}
                    >
                      <Translate text="discovery.taxProfile.taxForms.requestDownload" />
                    </RCButton>
                  )}
                  { shouldShowDownloadButton() && (
                    <>
                      <div>
                        <a
                          href={taxFormDownload.url}
                          className="rc-button rc-button--filled rc-button--small"
                          target="_blank" rel="noreferrer"
                        >
                          <RCTextWithIcon
                            placement="left"
                            svgComponent={DownloadIcon}
                          >
                            <Translate
                              text="discovery.taxProfile.taxForms.downloadTaxForm"
                            />
                          </RCTextWithIcon>
                        </a>
                      </div>
                      <div className="mt-4 color-secondary">
                        <RCTextWithIcon
                          placement="left"
                          svgComponent={LockIcon}
                        >
                          <Translate
                            text="discovery.taxProfile.taxForms.secureDownload"
                          />
                        </RCTextWithIcon>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </td>
      </tr>
    </>
  );
}


export function MultipleTaxForms({ taxForms, requestTaxFormDownload, fetchTaxFormDownload }: Props) {
  const [taxFormLoadingStates, setTaxFormLoadingStates] = React.useState({});

  React.useEffect(() => {
    setTaxFormLoadingStates(() => {
      return (taxForms || []).reduce((loadingStates, taxForm) => {
        loadingStates[taxForm.id] = taxForm?.taxFormDownload?.status === TaxFormDownloadStatus.PENDING;
        return loadingStates;
      }, {});
    });
  }, []);

  const handleTaxFormLoading = (isLoading, taxFormId) => {
    setTaxFormLoadingStates((states) => { return { ...states, [taxFormId]: isLoading }; });
  };

  const areTaxFormsLoading = () => {
    return Object.values(taxFormLoadingStates).some(v => v);
  };

  if (!taxForms || taxForms.length === 0) {
    return (
      <TaxFormSection>
        <NoTaxFormsToDisplay/>
      </TaxFormSection>
    );
  }

  return (
    <TaxFormSection>
      { areTaxFormsLoading() && (
        <div className="mb-4">
          <RCAlertBox
            type="success"
          >
            <Translate
              text="discovery.taxProfile.taxForms.requested"
            />
          </RCAlertBox>
        </div>
      )}
      <table className="table statement-table">
        <thead>
          <tr>
            <th className="ws-nowrap">
              <Translate text="discovery.taxProfile.taxForms.taxYear" />
            </th>
            <th>
              <div className="mobile-ml-4">
                <Translate text="discovery.taxProfile.taxForms.taxYearStatus" />
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          {
            taxForms.map((taxForm) => (
              <TaxFormRow
                key={taxForm.id}
                taxForm={taxForm}
                requestButtonsInactive={areTaxFormsLoading()}
                fetchTaxFormDownload={fetchTaxFormDownload}
                requestTaxFormDownload={requestTaxFormDownload}
                onTaxFormLoadingChanged={(isLoading) => { handleTaxFormLoading(isLoading, taxForm.id); }}
              />
            ))
          }
        </tbody>
      </table>
    </TaxFormSection>
  );
}

export default MultipleTaxForms;
