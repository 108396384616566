import { gql } from '@reverbdotcom/commons/src/gql';
import React from 'react';

import { useHOCMutation, MutationFunction } from '../useHOCMutation';
import {
  CoreUserActionsCreate,
  core_apimessages_UserActionName,
  core_apimessages_Error,
  CreateUserActionMeData,
  Input_core_apimessages_MyAvailableActionsIndexRequest,
  core_apimessages_UserActionStatus,
} from '../gql/graphql';
import { withGraphql } from '../with_graphql';

export type CoreUserActionsCreateMutationFn = MutationFunction<CoreUserActionsCreate.Mutation, CoreUserActionsCreate.Variables>;

const mutationFnName = 'createUserAction';

export const CreateUserActionMeFragment = gql(`
  fragment CreateUserActionMeData on rql_Me {
    _id
    uuid
  }
`);

export const ActionCacheFragment = gql(`
  fragment ActionCacheFragmentData on core_apimessages_AvailableAction {
    id
    name
    status
  }
`);

type EmptyObject = Record<string, never>;

export const createUserActionMutation = gql(`
  mutation Core_UserActions_Create(
    $input: Input_core_apimessages_CreateMyActionRequest
  ) {
    createMyAction(input: $input) {
      errors {
        message
      }
      action {
        id
        name
        status
      }
    }
  }
`);

export const withCreateUserActionMutation = withGraphql<EmptyObject, CoreUserActionsCreate.Mutation, CoreUserActionsCreate.Variables>(
  createUserActionMutation,
  {
    name: mutationFnName,
  },
);

export interface CreateUserActionState {
  errors: core_apimessages_Error[];
  createUserAction: () => any;
}

export function useCreateUserAction(
  actionName: core_apimessages_UserActionName,
  me: CreateUserActionMeData.Fragment,
  availableActionsInput: Input_core_apimessages_MyAvailableActionsIndexRequest,
  createUserAction: CoreUserActionsCreateMutationFn,
): CreateUserActionState {
  const [mutate, { errors }] = useHOCMutation(createUserAction);

  const createUserActionCallback = React.useCallback(async function () {
    return mutate({
      variables: {
        input: { name: actionName },
      },
      optimisticResponse: {
        __typename: 'Mutation',
        createMyAction: {
          __typename: 'core_apimessages_CreateMyActionResponse',
          errors: [],
          action: {
            __typename: 'core_apimessages_AvailableAction',
            id: actionName,
            name: actionName,
            status: core_apimessages_UserActionStatus.UNAVAILABLE,
          },
        },
      },
      update: function (cache, mutationResult) {
        const action = mutationResult.data?.createMyAction?.action;

        if (action) {
          cache.writeFragment({
            fragment: ActionCacheFragment,
            data: action,
            id: cache.identify(action),
          });
        }

        return true;
      },
    });
  }, [actionName, mutate, me, availableActionsInput]);

  return { createUserAction: createUserActionCallback, errors };
}
