import React from 'react';
import classNames from 'classnames';
import I18n from 'i18n-js';

import bind from '../../bind';
import CoreLink from '../../components/core_link';
import { MParticleEventName } from '../../elog/mparticle_tracker';
import { IDynamicComponentProps } from '../dynamic_component_props';

export interface LinkData {
  text: string;
  url: string;
  isParent?: boolean;
}

export interface IProps extends IDynamicComponentProps {
  links: LinkData[];
  title?: string | JSX.Element;
  backLinkText?: string;
  backLinkUrl?: string;
  jumplinkSlug?: string;
  displayStyle?: string;
  mobileDropdown?: boolean;
  ctaText?: string;
}

export interface IState {
  selectedLink: LinkData;
}

const COMPONENT_NAME = 'LinkList';

export default class LinkList extends React.Component<IProps, IState> {
  state = {
    selectedLink: this.props.links[0],
  };

  get links() {
    return this.props.links;
  }

  clickEvent(link) {
    return {
      componentName: COMPONENT_NAME,
      eventName: MParticleEventName.ClickedLinkList,
      displayStyle: this.props.displayStyle || '',
      targetUrl: link.url,
    };
  }

  ctaText() {
    if (this.props.ctaText) {
      return this.props.ctaText;
    }

    return I18n.t('cms.linkList.ctaText');
  }

  @bind
  handleUpdate(evt) {
    const selectedLink = this.links.filter(link => link.text === evt.target.value)[0];

    this.setState({ selectedLink });
  }

  @bind
  renderLink(link, idx) {
    const classes = classNames(
      'link-list__item',
      { 'link-list__item--parent': link.isParent },
    );

    return (
      <li
        key={`${idx}`}
        className={classes}
      >
        <CoreLink
          to={link.url}
          clickEvent={this.clickEvent(link)}
        >
          {link.text}
        </CoreLink>
      </li>
    );
  }

  renderBackLink() {
    if (!this.props.backLinkText || !this.props.backLinkUrl) return null;

    return (
      <div
        className="link-list__item link-list__item--back"
        id={this.props.jumplinkSlug || null}
      >
        <CoreLink
          to={this.props.backLinkUrl}
        >
          {this.props.backLinkText}
        </CoreLink>
      </div>
    );
  }

  renderLinks() {
    return (
      <ul>
        {this.links.map(this.renderLink)}
      </ul>
    );
  }

  renderMobileDropdown() {
    return (
      <>
        <div className="mobile-d-block d-none">
          {this.props.title &&
            <div className="link-list__title--mobile">
              {this.props.title}
            </div>
          }
          <div className="styled-dropdown">
            <select
              id="linkListSelection"
              name="linkListSelection"
              value={this.state.selectedLink.text}
              onChange={this.handleUpdate}
            >
              {this.links.map((link) => {
                const { text } = link;
                return (
                  <option value={text} key={text}>
                    {text}
                  </option>
                );
              })}
            </select>
          </div>
          <div>
            <CoreLink
              to={this.state.selectedLink.url}
              clickEvent={this.clickEvent(this.state.selectedLink)}
              className="link-list--nav-button"
            >
              {this.ctaText()}
            </CoreLink>
          </div>
        </div>
      </>
    );
  }

  getAriaLabel() {
    const { title } = this.props;

    if (!title) { return ''; }

    return typeof title === 'string' ? title : title.props.html;
  }

  render() {
    if (!this.links) return null;

    const classes = classNames(
      'link-lists',
    );

    const childrenLinks = this.renderLinks();

    return (
      <>
        <nav
          className={classes}
          aria-label={this.getAriaLabel()}
        >
          {this.props.title &&
            <h2 className="link-list__title">
              {this.props.title}
            </h2>
          }
          <hr className="mtb-2" />
          {this.renderBackLink()}
          {childrenLinks}
        </nav>
        {this.props.mobileDropdown && this.renderMobileDropdown()}
      </>
    );
  }
}
