import React from 'react';
import I18n from 'i18n-js';
import classNames from 'classnames';

import { IDynamicComponentProps } from '../dynamic_component_props';
import CoreLink from '../../components/core_link';
import { RCCard, buttonClassName } from '@reverbdotcom/cadence/components';
import { imageUploadURL } from '../../url_helpers';
import { MParticleEventName } from '../../elog/mparticle_types';
import { preferLightTheme } from '@reverbdotcom/cadence/components/helpers/themeOverrides';

interface CalloutData {
  title: string;
  titleHtml?: string;
  subtitle?: string;
  subtitleHtml?: string;
  image: string;
  targetHref: string;
  channel?: string;
  pageType?: string;
}

export interface IProps extends IDynamicComponentProps {
  callouts: CalloutData[];
  title?: string;
  subtitle?: string;
  ctaText?: string;
  ctaTargetHref?: string;
  bannerImage?: string;
}

const TWO_WIDE = 2;
const THREE_WIDE = 3;

export function MarketingCalloutBanner({
  callouts = [],
  title,
  subtitle,
  ctaText,
  ctaTargetHref,
  bannerImage,
}: IProps) {
  const bannerImageURL = imageUploadURL(bannerImage);

  function renderAction() {
    if (!ctaTargetHref) return null;

    const buttonClasses = buttonClassName({
      variant: 'filled',
    });

    return (
      <CoreLink
        className={buttonClasses}
        to={ctaTargetHref}
      >
        {ctaText
          ? ctaText
          : I18n.t('cms.marketingCallout.seeMore')}
      </CoreLink>
    );
  }

  function CalloutCard(calloutData) {
    const { callout } = calloutData;
    const calloutClickEvent = {
      eventName: MParticleEventName.ClickedHomepageMarketingCallout,
      marketingCalloutTitle: callout.title,
    };

    return (
      <RCCard
        key={callout.title}
        bgColorToken="rc-color-palette-bw-white"
        bgColorHoverToken="rc-color-palette-gray-100"
        fullHeight
      >
        <RCCard.LinkWrapper>
          <CoreLink
            to={callout.targetHref}
            className="d-flex fx-dir-col color-primary"
            clickEvent={calloutClickEvent}
          >
            <div className="image-box">
              <img
                src={imageUploadURL(callout.image)}
                alt=""
                style={{
                  borderTopLeftRadius: 'var(--rc-border-radius-primary)',
                  borderTopRightRadius: 'var(--rc-border-radius-primary)',
                }}
              />
            </div>

            <div className="marketing-callout-banner__callout-text">
              {callout.title}
            </div>
          </CoreLink>
        </RCCard.LinkWrapper>
      </RCCard>
    );
  }

  function renderCallouts() {
    return callouts.map((callout, index) => {
      return (
        <div key={index} className={classNames(
          'marketing-callout-banner__callout-card',
          { 'marketing-callout-banner__callout-card--two-wide': callouts.length === TWO_WIDE },
          { 'marketing-callout-banner__callout-card--three-wide': callouts.length === THREE_WIDE },
        )}>
          <CalloutCard
            key={index}
            callout={callout}
          />
        </div>
      );
    });
  }

  return (
    <div
      className="marketing-callout-banner"
      style={{ backgroundImage: `url(${bannerImageURL})`, backgroundSize: 'cover' }}
      {...preferLightTheme()}
    >
      <div className="marketing-callout-banner__inner">
        <div className="marketing-callout-banner__copy">
          <h1 className="marketing-callout-banner__main-heading">
            {title}
          </h1>
          <h2 className="marketing-callout-banner__secondary-heading">
            {subtitle}
          </h2>
          {renderAction()}
        </div>

        <div className={classNames(
          'marketing-callout-banner__callouts',
          { 'marketing-callout-banner__callouts--two-wide': callouts.length === TWO_WIDE },
        )}>
          {renderCallouts()}
        </div>
      </div>
    </div>
  );
}
