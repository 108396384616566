import { RCBanner } from '@reverbdotcom/cadence/components';
import React from 'react';
import I18n from 'i18n-js';
import SpeakerIcon from '@reverbdotcom/cadence/images/brand/report-orange.svg';

export function StatementPaymentNeededBanner() {
  return (
    <RCBanner
      content={I18n.t('discovery.payStatement.statementPaymentNeededBanner.message')}
      image={SpeakerIcon}
      theme="error"
      title={I18n.t('discovery.payStatement.statementPaymentNeededBanner.headline')}
    />
  );
}

export default StatementPaymentNeededBanner;
