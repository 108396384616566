import React from 'react';
import qs from 'qs';
import { RCChip } from '@reverbdotcom/cadence/components';
import { Filter, FilterOption, getRangeValue } from './search_filters';
import { withRouter, WithRouterProps } from 'react-router';
import { useUpdateUrl } from './with_update_url';
import { MParticleEventName, trackEvent } from '../elog/mparticle_tracker';
import { getAllSelectedOptions } from '../grid_helpers';
import { changeStickyItemRegion } from '../change_sticky_item_region';

type IProps = {
  filters: Filter[];
  modifyRemovableFilterChips?: (filterChips: InputChipFilterOption[]) => InputChipFilterOption[];
} & WithRouterProps;

export interface InputChipFilterOption extends FilterOption {
  filter: Filter;
}

export function GridRowWithInputChips({
  filters = [],
  modifyRemovableFilterChips = undefined,
  location,
  router,
}:IProps) {
  const { updateUrl } = useUpdateUrl();

  const onCloseChip = (inputChip) => {
    trackEvent({
      eventName: MParticleEventName.RemovedInputChipFromExposedSidebarGridView,
      filterOption: inputChip.name,
    });
  };

  const closeChip = (inputChip: InputChipFilterOption) => {
    let newParams;

    if (inputChip.filter.widgetType === 'RANGE') {
      const minParam = getRangeValue(inputChip.filter, 'min');
      const maxParam = getRangeValue(inputChip.filter, 'max');

      newParams = {
        [minParam.key]: '',
        [maxParam.key]: '',
      };
    } else {
      newParams = { [inputChip.paramName]: inputChip.unsetValues };
    }

    const newUrl = updateUrl({
      location,
      newParams,
    });
    const newUrlHref = `${newUrl.pathname}?${qs.stringify(newUrl.query)}`;

    onCloseChip(inputChip);

    if (inputChip.filter.key === 'SELLER_LOCATION') {
      changeStickyItemRegion('', newUrlHref);
    } else {
      router.push(inputChip.directLinkOverride || newUrl);
    }
  };

  let filterChips = getAllSelectedOptions(filters);
  if (modifyRemovableFilterChips) {
    filterChips = modifyRemovableFilterChips(filterChips);
  }

  return (
    <div className="scroll-x-on-tablet scroll-x-on-tablet--full-bleed">
      <RCChip.Collection nowrap="tablet">
        {filterChips.map((chip, idx) => (
          <RCChip
            selected
            onClick={() => closeChip(chip)}
            key={`${chip.name}-${idx}`}
          >
            {chip.name}
          </RCChip>
        ))}
      </RCChip.Collection>
    </div>
  );
}

export default withRouter(GridRowWithInputChips);
