import PropTypes from 'prop-types';
import React from 'react';
import { PAYOUT_METHODS } from '../shared/constants';
import { GetPaidLaterInfo } from '../shared/get_paid_later_info';

function GetPaidLater(props) {

  return (
    <>
      <GetPaidLaterInfo />
      <input
        type="hidden"
        value={PAYOUT_METHODS.REVERB_BANK}
        name={`${props.formPrefix}[direct_checkout_profile][payout_method]`}
        id={`${props.formPrefix}-reverb_bank`}
      />
      <input
        type="hidden"
        value="1"
        name={`${props.formPrefix}[direct_checkout_profile][accepted_terms_at]`}
        id={`${props.formPrefix}-accepted-terms-at`}
      />
      <input
        type="hidden"
        value="direct_checkout"
        name={`${props.formPrefix}[payment_method]`}
        id={`${props.formPrefix}-payment-method`}
      />
    </>
  );
}

GetPaidLater.propTypes = {
  formPrefix: PropTypes.string.isRequired,
};

export default GetPaidLater;
