import React from 'react';
import I18n from 'i18n-js';

import { core_apimessages_ContentSponsorship_SponsorshipType } from '../../gql/graphql';

import CoreLink from '../../components/core_link';
import { MParticleEventName } from '../../elog/mparticle_types';
import AdTag from '../../components/ads/ad_tag';
import { imageUploadURL } from '../../url_helpers';
import { useViewTracking } from '@reverbdotcom/commons/src/use_tracking';
import { preferLightTheme } from '@reverbdotcom/cadence/components/helpers/themeOverrides';

// This component can be rendered with props from either:
// - a CMS component (cms-ui/src/components/site_banner_hero_builder.tsx)
// - a graphql query (discovery-ui/src/components/discovery/site_banner_hero_container.tsx)

const COMPONENT_NAME = 'SiteBannerHero';
export const DEFAULT_SEARCH_HEADER = 'SearchHeader';

interface SiteBannerProps {
  id?: string;
  heading?: string;
  subheading?: string;
  callToAction?: string;
  url?: string;
  hasPhotos?: boolean;
  imageUrl?: string;
  cmsBannerImage?: string;
  sponsoredContentType?: core_apimessages_ContentSponsorship_SponsorshipType;
  sponsoredContentPartner?: string;
}

interface ContentSponsorshipProps {
  contentSponsorship: {
    sponsorshipType?: core_apimessages_ContentSponsorship_SponsorshipType;
    partnershipName?: string;
  };
}

function buildClickEvent(siteBanner: SiteBannerProps) {
  return {
    eventName: MParticleEventName.SiteBanner,
    componentName: COMPONENT_NAME,
    entityId: siteBanner.heading,
    isAd: !!siteBanner.sponsoredContentType,
    isDefault: false,
  };
}

function PartnershipSponsorshipContent({ contentSponsorship }: ContentSponsorshipProps) {
  if (
    contentSponsorship?.sponsorshipType === core_apimessages_ContentSponsorship_SponsorshipType.PARTNERSHIP &&
      contentSponsorship?.partnershipName) {
    return (
      <p className="size-80 mb-2 style-italic">
        {I18n.t('commons.contentSponsorship.partnership', { partner: contentSponsorship.partnershipName })}
      </p>
    );
  }

  return null;
}

function AdSponsorshipContent({ contentSponsorship }: ContentSponsorshipProps) {
  if (contentSponsorship?.sponsorshipType === core_apimessages_ContentSponsorship_SponsorshipType.AD) {
    return (
      <div className="site-banner-hero__ad">
        <AdTag inline small />
      </div>
    );
  }

  return null;
}

function getBackgroundImageStyle(siteBanner: SiteBannerProps) {
  if (siteBanner.cmsBannerImage) {
    return { backgroundImage: `url(${imageUploadURL(siteBanner.cmsBannerImage)})` };
  }

  return { backgroundImage: `url(${siteBanner.imageUrl})` };
}

export default function SiteBannerHero(siteBanner: SiteBannerProps) {
  const renderSiteBanner = siteBanner?.hasPhotos || siteBanner?.cmsBannerImage;

  useViewTracking({
    eventName: MParticleEventName.ComponentView,
    componentName: COMPONENT_NAME,
    entityId: siteBanner?.id,
  }, !!renderSiteBanner);

  if (!renderSiteBanner) return null;

  const contentSponsorship = {
    sponsorshipType: siteBanner.sponsoredContentType,
    partnershipName: siteBanner.sponsoredContentPartner,
  };

  return (
    <div className="site-banner-hero" {...preferLightTheme()}>
      <CoreLink
        to={siteBanner.url}
        className="site-banner-hero__inner"
        style={getBackgroundImageStyle(siteBanner)}
        clickEvent={buildClickEvent(siteBanner)}
      >
        <div className="site-banner-hero__content">
          <PartnershipSponsorshipContent contentSponsorship={contentSponsorship} />
          <p className="site-banner-hero__subtext">
            {siteBanner.subheading}
          </p>
          <h2 className="site-banner-hero__headline">
            {siteBanner.heading}
          </h2>
          <span className="site-banner-hero__cta">
            {siteBanner.callToAction}
          </span>
        </div>
        <AdSponsorshipContent contentSponsorship={contentSponsorship} />
      </CoreLink>
    </div>
  );
}
