import React from 'react';
import I18n from 'i18n-js';
import classNames from 'classnames';
import { ITEM_WITH_SLUG_ROUTE } from '../url_helpers';
import { ModalSidebarListingFragment } from '../gql/graphql';
import CoreLink from './core_link';

export interface IListingDetail {
  term: string;
  definition: string | number;
}

interface IProps {
  details?: IListingDetail[];
  listing: ModalSidebarListingFragment.Fragment;
  loading: boolean;
}

export default function ModalSidebarListing(props: IProps) {

  const [detailsVisible, toggleDetails] = React.useState(false);

  const renderThumbnail = () => {
    if (!props.listing.images) { return null; }

    return (
      <div className="modal-sidebar-listing__thumbnail">
        <img
          src={props.listing.images[0].source}
          alt={props.listing.title}
        />
      </div>
    );
  };

  if (props.loading || !props.listing) {
    return (
      <div className="modal-sidebar-listing modal-sidebar-listing--loading">
        <div className="modal-sidebar-listing__thumbnail" />
        <div className="modal-sidebar-listing__title" />
        <div className="modal-sidebar-listing__details-label" />
        <div className="modal-sidebar-listing__details" />
      </div>
    );
  }

  return (
    <div
      className={classNames(
        'modal-sidebar-listing',
        { 'modal-sidebar-listing--expanded': detailsVisible },
      )}
    >
      <div className="modal-sidebar-listing__primary">
        {renderThumbnail()}
        <h2 className="modal-sidebar-listing__title">{props.listing.title}</h2>
        <button
          className="modal-sidebar-listing__details-toggle"
          onClick={() => toggleDetails(!detailsVisible)}
        />
      </div>
      <div className="modal-sidebar-listing__secondary">
        {props.details && (
          <dl className="modal-sidebar-listing__details">
            {props.details.map(detail => (
              <div key={detail.term} className="modal-sidebar-listing__detail">
                <dt>{detail.term}</dt>
                <dd>{detail.definition}</dd>
              </div>
            ))}
          </dl>
        )}
        <CoreLink
          to={ITEM_WITH_SLUG_ROUTE.expand({
            slug: props.listing.slug,
            id: props.listing.id,
          })}
          className="modal-sidebar-listing__link"
          target="_blank"
        >
          {I18n.t('commons.offers.sidebar.viewListing')}
        </CoreLink>
      </div>
    </div>
  );
}
