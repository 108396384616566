import React from 'react';
import { groupBy, compact, last } from 'lodash';
import fetch from 'isomorphic-fetch';

// eslint-disable-next-line max-len
const JOBS_URL = 'https://api.smartrecruiters.com/v1/companies/Etsy2/postings?custom_field.5dfa5643852dfd7a67ca1713=92fcffd1-2a5e-4ab5-9704-6792731b7b3b';
const LISTING_URL = 'https://jobs.smartrecruiters.com/Etsy2';
const JOB_FAMILY_GROUP_ID = '5e178a74d4c568605193dbe2';
const JOB_FAMILY_ID = '5e178adfd4c568605193dbe3';
const JOB_LEVEL_ID = '5e1789c5d8c40d69c0c5f67d';

interface ICustomField {
  fieldId: string;
  valueLabel: string;
}

interface IJob {
  id: string;
  name: string;
  location: {
    city: string;
    region: string;
    country: string;
    remote: boolean;
  };
  customField: ICustomField[];
}

interface IResp {
  content: IJob[];
}

export function useJobs() {
  const [jobs, setJobs] = React.useState<IJob[]>([]);

  React.useEffect(() => {
    async function fetchResp() {
      try {
        const resp = await fetch(JOBS_URL);
        const body: IResp = await resp.json();
        setJobs(body.content);
      } catch (e) {
        throw new Error(`could not fetch job - ${e}`);
      }
    }

    fetchResp();
  }, []);

  return jobs;
}

function Location(props: { location: IJob['location'] }) {
  const { city, region, country, remote } = props.location;
  let desc = '';
  desc = compact([city, region]).join(', ');

  if (country.toUpperCase() !== 'US') {
    desc = `${desc}, ${country.toLocaleUpperCase()}`;
  }

  if (remote) {
    if (desc.length === 0) {
      desc = 'Remote';
    } else {
      desc = desc.concat(' or Remote');
    }
  }

  return (
    <div className="srjobs__location">
      {desc}
    </div>
  );
}

function getJobLevel(job: IJob) {
  return last(job.customField.find(el => el.fieldId === JOB_LEVEL_ID).valueLabel.split(' ')).trim();
}

// We want the higher position jobs at the front. E.g. M3 > IC3 > IC1
function sortedJobs(jobs: IJob[]) {
  return jobs.sort((a, b) => {
    const levelA = getJobLevel(a);
    const levelB = getJobLevel(b);
    if (levelA > levelB) {
      return -1;
    }
    if (levelA < levelB) {
      return 1;
    }
    return 0;
  });
}

export function Department(props: { jobs: IJob[] }) {
  return (
    <ul>
      {sortedJobs(props.jobs).map((job) => (
        <li key={job.id}>
          <a
            href={`${LISTING_URL}/${job.id}`}
            className="srjobs__job d-flex fx-justify-between fx-dir-row"
            rel="noopener noreferrer"
            target="_blank"
          >
            <div className="srjobs__name">{job.name}</div>
            <Location location={job.location} />
          </a>
        </li>
      ))}
    </ul>
  );
}

function DepartmentHeader(props: { dept: string }) {
  // Many of the jobs have (R) to denote reverb or put a "Group" at the end.
  // We want to remove that from display
  const dept = props.dept.replace(/\(R\)|Group/ig, '');
  return (
    <h3 className="srjobs__department">{dept}</h3>
  );

}

// Mostly we want to show the job family group, but for large groups like Product and Engineering
// we want to shot the job family which is a subset of the group
function groupJobs(job :IJob) {
  const jobFamilyGroupField = job.customField.find(el => el.fieldId === JOB_FAMILY_GROUP_ID);
  if (jobFamilyGroupField === undefined || jobFamilyGroupField.valueLabel === '(R) Development Group') {
    return job.customField.find(el => el.fieldId === JOB_FAMILY_ID).valueLabel;
  }
  return jobFamilyGroupField.valueLabel;
}

export default function SmartRecruiterJobs() {
  const jobs = useJobs();
  if (jobs.length === 0) { return null; }
  const grouped = groupBy(jobs, groupJobs);

  return (
    <div className="srjobs">
      {Object.keys(grouped).map((dept) => (
        <React.Fragment key={dept}>
          <DepartmentHeader dept={dept} />
          <Department jobs={grouped[dept]} />
        </React.Fragment>
      ))}
    </div>
  );
}
