import React, { useState } from 'react';
import {
  RCAlertBox,
  RCButton,
  RCCheckbox,
  RCTextWithIcon,
  RCLoadingBars,
} from '@reverbdotcom/cadence/components';
import { ChevronRightIcon, ChevronLeftIcon } from '@reverbdotcom/cadence/icons/react';
import { gql } from '@reverbdotcom/commons/src/gql';
import { useQuery } from '@reverbdotcom/commons/src/useQuery';
import { getPage, getTotalPages } from '../../pagination_helpers';
import AdyenBalanceNotificationRow from './adyen_balance_notification_row';

const FIRST_PAGE = 1;
const DEFAULT_OFFSET = '0';
export const DEFAULT_LIMIT = '10';

export const DEFAULT_QUERY_PARAMS = {
  offset: DEFAULT_OFFSET,
  limit: DEFAULT_LIMIT,
};

export const LIST_BALANCE_PLATFORM_NOTIFICATIONS_QUERY = gql(`
  query shopAdyenBalancePlatformNotifications($input: Input_core_apimessages_ShopAdyenBalancePlatformNotificationsIndexRequest) {
    shopAdyenBalancePlatformNotifications(input: $input) {
      notifications {
        id
        createdAt
        rawBody
      }
      limit
      offset
      totalResults
    }
  }
`);

interface Props {
  shopId: string;
  eventTypes: string[];
}

export default function AdyenBalanceNotifications({
  shopId,
  eventTypes,
}: Props) {
  const defaultQueryParams = { ...DEFAULT_QUERY_PARAMS, shopId, eventTypes: eventTypes };

  const [ queryParams, setQueryParams ] = useState(defaultQueryParams);

  const { data: balanceNotificationsResponse, loading: loadingNotifications, errors: fetchErrors } = useQuery(LIST_BALANCE_PLATFORM_NOTIFICATIONS_QUERY, { variables: { input: queryParams } });

  const notifications = balanceNotificationsResponse?.shopAdyenBalancePlatformNotifications?.notifications;

  const currentOffset = parseInt(balanceNotificationsResponse?.shopAdyenBalancePlatformNotifications?.offset, 10);
  const currentLimit = parseInt(balanceNotificationsResponse?.shopAdyenBalancePlatformNotifications?.limit, 10);
  const totalNotifications = parseInt(balanceNotificationsResponse?.shopAdyenBalancePlatformNotifications?.totalResults || '0', 10);

  const previousPage = () => {
    setQueryParams((params) => ({
      ...params,
      offset: (currentOffset - currentLimit).toString(),
    }));
  };

  const nextPage = () => {
    setQueryParams((params) => ({
      ...params,
      offset: (currentOffset + currentLimit).toString(),
    }));
  };

  const toggleEventType = (eventType, checked) => {
    setQueryParams((params) => {
      let newEventTypes;
      if (checked) {
        newEventTypes = params.eventTypes.concat(eventType);
      } else {
        newEventTypes = params.eventTypes.filter(e => e !== eventType);
      }
      return {
        ...params,
        eventTypes: newEventTypes,
      };
    });
  };

  const currentPage = getPage(currentOffset, currentLimit);
  const totalPages = getTotalPages(totalNotifications, currentLimit);

  const isFirstPage = currentPage === FIRST_PAGE;
  const isLastPage = currentPage === totalPages;


  return (
    <>
      <h3 className="mb-4">Adyen Balance Notifications {!loadingNotifications && (<>({totalNotifications} total)</>)}</h3>
      {fetchErrors?.length > 0 && (
        <div className="mb-4">
          <RCAlertBox type="error">
            Unable to load notifications.
          </RCAlertBox>
        </div>
      )}
      <div className="bd-1 bd-color-primary bd-radius-sm padding-2 mb-4">
        <div className="d-flex fx-align-center gap-3">
          {eventTypes.map((eventType, index) => (
            <RCCheckbox
              id={`eventType${index}`}
              key={eventType}
              label={eventType}
              onChange={(e) => toggleEventType(eventType, e.target.checked)}
              checked={queryParams.eventTypes.includes(eventType)}
            />
          ))}
        </div>
      </div>
      <div className="table table-bordered table-striped width-100">
        <table className="width-100">
          <thead>
            <th>Notification ID</th>
            <th>Notification Time</th>
            <th>Body</th>
          </thead>
          <tbody>
            {loadingNotifications && (
              <tr>
                <td colSpan={3}>
                  <div className="d-flex fx-justify-center">
                    <RCLoadingBars />
                  </div>
                </td>
              </tr>
            )}
            {!loadingNotifications && (
              notifications?.length > 0 ? (
                notifications.map((notification) => (
                  <AdyenBalanceNotificationRow
                    key={notification.id}
                    notification={notification}
                  />
                ))
              ) : (
                <tr>
                  <td colSpan={3} className="width-100">
                    <div className="d-flex fx-justify-center">
                      No balance notifications
                    </div>
                  </td>
                </tr>
              )
            )}
          </tbody>
        </table>
      </div>
      {(!loadingNotifications && totalNotifications > 0) && (
        <div className="d-flex margin-2 fx-justify-center">
          <RCButton
            id="previousPage"
            size="mini"
            onClick={previousPage}
            disabled={isFirstPage}
          >
            <RCTextWithIcon
              svgComponent={ChevronLeftIcon}
              children=""
              title="Previous Page"
            />
          </RCButton>
          <div className="ml-2 mr-2 d-flex fx-align-center">
            Page {currentPage} of {totalPages}
          </div>
          <RCButton
            id="nextPage"
            size="mini"
            onClick={nextPage}
            disabled={isLastPage}
          >
            <RCTextWithIcon
              svgComponent={ChevronRightIcon}
              children=""
              title="Next Page"
            />
          </RCButton>
        </div>
      )}
    </>
  );
}
