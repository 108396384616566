import PropTypes from 'prop-types';
import React from 'react';
import { CURRENCY_CODES, CURRENCY_SYMBOLS } from '../shared/constants';
import CurrencyExchangeFeeWarning from './currency_exchange_fee_warning';
import { includes } from 'lodash';
import I18n from 'i18n-js';

class ShopCurrencySelect extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showCurrencyChangeNote: false,
      selectedCurrency: props.originalCurrencyCode,
    };

    this.onCurrencyChange = this.onCurrencyChange.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.currencies.length !== nextProps.currencies.length) {
      const newCurrencyCodes = nextProps.currencies.map(currency => currency.code);

      if (!includes(newCurrencyCodes, this.state.selectedCurrency)) {
        const newCurrency = newCurrencyCodes[0];

        this.setState({
          showCurrencyChangeNote: this.showCurrencyChange(newCurrency),
          selectedCurrency: newCurrency,
        });
      }
    } else if (this.props.originalCurrencyCode !== nextProps.originalCurrencyCode) {
      this.setState({
        showCurrencyChangeNote: this.showCurrencyChange(nextProps.originalCurrencyCode),
        selectedCurrency: nextProps.originalCurrencyCode,
      });
    }
  }

  onCurrencyChange(e) {
    const selectedCurrency = e.target.value;
    const showCurrencyChangeNote = this.showCurrencyChange(selectedCurrency);
    this.setState({
      showCurrencyChangeNote,
      selectedCurrency,
    });
    this.props.onCurrencyChange(selectedCurrency);
  }

  showCurrencyChange(currency) {
    return (
      !!currency &&
        !!this.props.originalCurrencyCode &&
        currency !== this.props.originalCurrencyCode
    );
  }

  get hasErrors() {
    return this.props.currencyErrors && this.props.currencyErrors.length > 0;
  }

  displayCurrencyErrors() {
    if (this.hasErrors) {
      return (
        <div className="alert alert-error">
          <ul>{this.errorListItems()}</ul>
        </div>
      );
    }
    return null;
  }

  optionsForSelect() {
    return (
      this.props.currencies.map(currency =>
        (<option
          key={currency.code}
          value={currency.code}
        >
          {currency.name}
        </option>))
    );
  }

  errorListItems() {
    return (
      this.props.currencyErrors.map((error, index) =>
        (<li key={index}>
          {error}
        </li>))
    );
  }

  selectTag() {
    const selectProps = {
      ref: 'selectDropdown',
      name: 'shop_policies_form[shop][currency]',
      onChange: this.onCurrencyChange,
    };

    if (this.state.selectedCurrency) {
      Object.assign(selectProps, { value: this.state.selectedCurrency });
    }

    if (this.props.disabled) {
      selectProps.disabled = 'disabled';
    }

    return (
      <div className="styled-dropdown mb-0">
        <select
          className="required"
          required
          defaultValue={this.props.originalCurrencyCode}
          {...selectProps}
        >
          <option
            value=""
          >
            {I18n.t('js.shopPolicies.shopCurrency.selectAnOption')}
          </option>
          {this.optionsForSelect()}
        </select>
      </div>
    );
  }

  htmlParagraph(text) {
    return (
      <p
        className="small mb-0"
        dangerouslySetInnerHTML={{ __html: text }}
      />
    );
  }

  currencyChangeNote() {
    if (this.state.showCurrencyChangeNote) {
      return (
        <div className="alert-info ptb-2 plr-2 bd-radius-primary mtb-2">
          {this.htmlParagraph(I18n.t('js.shopPolicies.shopCurrency.currencyChange.listingsWillUpdateHtml', {
            strongTagOpen: '<strong>', strongTagClose: '</strong>', oldCurrencyCode: this.props.originalCurrencyCode, newCurrencyCode: this.state.selectedCurrency,
          }))}
          <br />
          {this.htmlParagraph(I18n.t('js.shopPolicies.shopCurrency.currencyChange.offersWillRemainHtml', {
            strongTagOpen: '<strong>', strongTagClose: '</strong>', oldCurrencyCode: this.props.originalCurrencyCode, newCurrencyCode: this.state.selectedCurrency,
          }))}

          <CurrencyExchangeFeeWarning
            payoutCurrency={this.props.selectedPayoutCurrency}
            shopCurrency={this.state.selectedCurrency}
            className="small mt-1"
            dashboardView
            directCheckoutSelected={this.props.directCheckoutSelected}
            currencyAttributeName="shopCurrency"
          />
        </div>
      );
    }
    return null;
  }

  currencySymbol(currencyCode) {
    return CURRENCY_SYMBOLS[currencyCode.toLowerCase()];
  }

  sellingFeesDescription() {
    if (!this.state.selectedCurrency) {
      return null;
    }

    let translationKey = 'js.shopPolicies.shopCurrency.sellingFeesDescription';

    if (this.state.selectedCurrency !== CURRENCY_CODES.USD) {
      translationKey = 'js.shopPolicies.shopCurrency.sellingFeesDescriptionWithCurrencyConversion';
    }

    const descriptionText = I18n.t(translationKey, {
      currencySymbol: this.currencySymbol(this.state.selectedCurrency),
      currencyCode: this.state.selectedCurrency,
    });

    return (
      <p
        className="form-group__help-text"
        data-currency-select-description
        dangerouslySetInnerHTML={{ __html: descriptionText }}
      />
    );
  }

  render() {
    return (
      <div>
        <div className="offset-anchor-wrapper">
          <div id="shop-currency-selector" className="offset-anchor" />
        </div>
        <div className="form-group">
          <div className="form-group__header">
            <label htmlFor="shop_policies_form_shop_currency">
              {I18n.t('js.shopPolicies.shopCurrency.currencyLabel')}
            </label>
          </div>
          <div className="form-group__fields">
            {this.displayCurrencyErrors()}
            <div className="width-50 mobile-width-100">
              {this.selectTag()}
            </div>
            {this.currencyChangeNote()}
            {this.sellingFeesDescription()}
          </div>
        </div>
      </div>
    );
  }
}

ShopCurrencySelect.propTypes = {
  originalCurrencyCode: PropTypes.string.isRequired,
  currencies: PropTypes.array.isRequired,
  currencyErrors: PropTypes.array,
  selectedPayoutCurrency: PropTypes.string,
  directCheckoutSelected: PropTypes.bool,
  disabled: PropTypes.bool,
  onCurrencyChange: PropTypes.func,
};

// Not passed in when rendered as a top-level component on the legacy payment methods form
ShopCurrencySelect.defaultProps = {
  onCurrencyChange: shopCurrency => shopCurrency,
};

export default ShopCurrencySelect;
