import { ChildProps } from '@apollo/client/react/hoc';
// TODO update this to import from commons
// import { gql } from '@reverbdotcom/commons/src/gql';
// eslint-disable-next-line no-restricted-imports
import { gql } from '@apollo/client';
import { withGraphql } from '@reverbdotcom/commons/src/with_graphql';
import { CoreWithSuggestionCheckListingsSearch, MarketplaceSuggestions } from '@reverbdotcom/commons/src/gql/graphql';
import { connect } from '@reverbdotcom/commons/src/connect';

interface ISuggestion {
  suggestion: MarketplaceSuggestions.Fragment;
}

function withSuggestionCheck<IProps extends ISuggestion>(componentClass: React.FunctionComponent<IProps>) {
  return connect<IProps>([
    withGraphql<IProps, CoreWithSuggestionCheckListingsSearch.Query>(
      gql`
        query Core_WithSuggestionCheck_ListingsSearch($query: String) {
          listingsSearch(input: {
            query: $query
            limit: 1
          }) {
            total
          }
        }
      `,
      {
        skip: (props: IProps) => {
          return !props.suggestion;
        },
        options: (props: IProps) => {
          return {
            ssr: false,
            variables: {
              query: props.suggestion.text,
            },
          };
        },
      },
    ),
  ])(componentClass);
}

export function isSuggestionValid(props: ChildProps<ISuggestion, CoreWithSuggestionCheckListingsSearch.Query>) {
  return props.suggestion &&
    !props.data?.loading &&
    props.data?.listingsSearch?.total !== 0;
}

export default withSuggestionCheck;
