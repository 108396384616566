import I18n from 'i18n-js';
import React from 'react';
import Currency from '@reverbdotcom/commons/src/currency';
import SanitizedRender from '@reverbdotcom/commons/src/components/sanitized_render';

interface Props {
  currencyCode: string;
  countryDefaultCurrencyCode: string;
  inputName: string;
}

function currencySymbol(currencyCode: string) {
  return Currency.get(currencyCode).symbol;
}

function formatHtml({ currencyCode, countryDefaultCurrencyCode }: { currencyCode: string; countryDefaultCurrencyCode: string }) {
  return I18n.t(
    'discovery.payoutMethod.directCheckoutProfile.bankAccountFields.bankTransferWarning',
    {
      currencyCode,
      currencySymbol: currencySymbol(currencyCode),
      defaultCurrencyCode: countryDefaultCurrencyCode,
      defaultCurrencySymbol: currencySymbol(countryDefaultCurrencyCode),
      strongOpen: '<strong>',
      strongClose: '</strong>',
    },
  );
}

function BankTransferWarning({ currencyCode, countryDefaultCurrencyCode, inputName }: Props) {
  if (currencyCode === countryDefaultCurrencyCode) {
    return null;
  }

  return (
    <label htmlFor={inputName} className="custom-checkbox__label normal small">
      <input
        type="checkbox"
        name={inputName}
        required
        defaultChecked={false}
      />
      <span className="small">
        <SanitizedRender html={formatHtml({ currencyCode, countryDefaultCurrencyCode })} htmlTag="strong" />
      </span>
    </label>
  );
}

export default BankTransferWarning;
