import React from 'react';
import I18n from 'i18n-js';

import { NudgeExploreIcon, PencilIcon } from '@reverbdotcom/cadence/icons/react';
import { RCButton, RCGuidePopover, RCTextWithIcon, RCToast } from '@reverbdotcom/cadence/components';
import QuickPriceEditModalV1, { listingPriceRecommendation } from './quick_price_edit_modal';
import { useCreateUserAction, createUserActionMutation } from '@reverbdotcom/commons/src/user_actions/create_action_mutation';
import { core_apimessages_UserActionName, Listing, core_apimessages_Money, reverb_pricing_PriceRecommendation } from '@reverbdotcom/commons/src/gql/graphql';
import { MParticleEventName } from '@reverbdotcom/commons/src/elog/mparticle_types';
import { trackEvent } from '@reverbdotcom/commons/src/elog/mparticle_tracker';
import { PriceDisplay } from '@reverbdotcom/commons';
import SellerListingsCollectionContext from '../../listings_management/SellerListingsCollectionContext';
import { parseAmountCents } from '@reverbdotcom/commons/src/money';
import { useMutation } from '@reverbdotcom/commons/src/useMutation';
import { useExpEnabled } from '@reverbdotcom/commons/src/user_hooks';
import experiments from '@reverbdotcom/commons/src/experiments';
import QuickPriceEditModalV2 from './QuickPriceEditModalV2';

const LIVE = 'live';
const DRAFT = 'draft';
const ENDED = 'ended';

export const VALID_LISTING_STATES_FOR_QUICK_PRICE_EDIT = [
  LIVE,
  DRAFT,
  ENDED,
];
interface IExternalProps {
  listing: Listing;
  showQuickPriceEditPopover?: boolean;
  isEditable?: boolean;
}

export const COMPONENT_NAME = 'QuickPriceEditButton';

function VatIncludedHint({ vatIncludedHint }) {
  return (
    <div className="color-secondary size-50 weight-normal align-right mb-2">
      {vatIncludedHint}
    </div>
  );
}

interface IPricedAboveAverageArgs {
  sellerPrice: core_apimessages_Money;
  conditionSlug?: string;
  priceRecommendation?: reverb_pricing_PriceRecommendation;
}

function isPricedAboveAverage({
  sellerPrice,
  priceRecommendation,
}: IPricedAboveAverageArgs) {
  const { priceMiddle } = priceRecommendation ?? {};
  if (!priceMiddle) return false;
  return priceMiddle.amountCents < sellerPrice.amountCents;
}

export function listingSellerPrice(listing: Listing, defaultCurrency?: string) {
  const {
    shop = {},
    sellerPrice,
  } = listing ?? {};

  const shopCurrency = shop.currency ?? defaultCurrency;
  return sellerPrice ?? parseAmountCents(0, shopCurrency, { precision: 0 });
}

export default function QuickPriceEditButton({
  listing,
  showQuickPriceEditPopover,
  isEditable = true,
}: IExternalProps) {
  const isV2QuickPriceEdit = useExpEnabled(experiments.LISTINGS_MANAGEMENT_QUICK_PRICE_EDIT_UPDATES);
  const sellerPrice = listingSellerPrice(listing);

  const {
    useLazySellerListingsQuery,
  } = React.useContext(SellerListingsCollectionContext);

  const { refetchSellerListings } = useLazySellerListingsQuery();

  const [showToast, setShowToast] = React.useState(false);
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [showPopover, setShowPopover] = React.useState(showQuickPriceEditPopover);

  const priceRecommendation = listingPriceRecommendation(listing);
  const { priceMiddle } = priceRecommendation;

  const showPricedAboveAverageCallout = isEditable && isPricedAboveAverage({
    sellerPrice,
    priceRecommendation,
  });

  const QuickPriceEditModal = isV2QuickPriceEdit ? QuickPriceEditModalV2 : QuickPriceEditModalV1;

  const [createMyAction] = useMutation(createUserActionMutation);
  const { createUserAction } = useCreateUserAction(
    core_apimessages_UserActionName.QUICK_PRICE_EDIT_CALLOUT,
    {
      uuid: listing.sellerUuid,
    },
    {
      listingId: listing.id,
    },
    createMyAction,
  );

  const searchParams = new URLSearchParams(window.location.search);

  React.useEffect(() => {
    trackEvent({
      eventName: MParticleEventName.ComponentView,
      componentName: COMPONENT_NAME,
      listingId: listing.id,
    });
  }, []);

  React.useEffect(() => {
    return () => {
      setShowPopover(false);
    };
  }, [searchParams.toString()]);

  return (
    <div className="quick-price-edit-button">
      <RCGuidePopover
        title={I18n.t('discovery.listingsManagement.quickPriceEdit.popover.title')}
        content={I18n.t('discovery.listingsManagement.quickPriceEdit.popover.content')}
        isOpen={isEditable && showPopover}
        onDismiss={() => {
          setShowPopover(false);
          createUserAction();
        }}
        preventAutoFocus
        anchor={<div className="size-100 weight-bold">
          {isEditable ? (
            <RCButton
              variant="muted"
              size="mini"
              onClick={() => setIsModalOpen(true)}
            >
              <RCTextWithIcon
                svgComponent={PencilIcon}
                placement="right"
              >
                {sellerPrice.amountCents === 0
                  ? I18n.t(
                    'discovery.listingsManagement.quickPriceEdit.button.addPrice',
                  )
                  : sellerPrice.display}
              </RCTextWithIcon>
            </RCButton>
          ) : (
            <PriceDisplay
              amountCents={sellerPrice.amountCents}
              display={sellerPrice.display}
            />
          )}
        </div>}
      />

      {showPricedAboveAverageCallout && (
        <div className="quick-price-edit-button__priced-above-average">
          <RCTextWithIcon
            size="large"
            svgComponent={NudgeExploreIcon}
            placement="left"
          >
            {I18n.t('discovery.listingsManagement.quickPriceEdit.button.mostLikelyToSellPrice', {
              displayPrice: parseAmountCents(
                priceMiddle.amountCents,
                priceMiddle.currency,
                { precision: 0 },
              ).display,
            })}
          </RCTextWithIcon>
        </div>
      )}

      <QuickPriceEditModal
        isOpen={isModalOpen}
        listingId={listing.id}
        requestClose={() => setIsModalOpen(false)}
        onSuccessCallback={() => {
          setIsModalOpen(false);
          setShowToast(true);
          refetchSellerListings([listing.id]);
        }}
      />

      <RCToast
        isOpen={showToast}
        onClose={() => setShowToast(false)}
        text={I18n.t('discovery.listingsManagement.toast.message.success')}
        theme="success"
        delayInterval="short"
      />

      {listing.vatIncluded &&
        <VatIncludedHint vatIncludedHint={listing.vatIncludedHint} />
      }
    </div>
  );
}
