import { sha256 } from 'js-sha256';
import window from './window_wrapper';
import { IUser } from './components/user_context_provider';
import { TABLET, IOS, WEB, ANDROID, MOBILE_PHONE } from './constants';
import { Commons_MParticle_OrderBundlesQuery } from './gql/graphql';
import { ENABLE_CRITEO } from '@reverbdotcom/env';

const SCRIPT_ID = 'criteo-js';

const CRITEO_MOBILE = 'm';
const CRITEO_DESKTOP = 'd';
const CRITEO_TABLET = 't';

const AMERICAS_ACCOUNT_ID = 25390;
const EMEA_ACCOUNT_ID = 45786;

const DEVICE_MAPPING = {
  [WEB]: CRITEO_DESKTOP,
  [IOS]: CRITEO_MOBILE,
  [ANDROID]: CRITEO_MOBILE,
  [MOBILE_PHONE]: CRITEO_MOBILE,
  [TABLET]: CRITEO_TABLET,
};

export function initCriteo(user: Partial<IUser>) {
  const { document } = window;

  if (!ENABLE_CRITEO || document.getElementById(SCRIPT_ID)) return;

  const account = accountForUser(user);
  if (!account) return;

  const el = document.createElement('script');
  el.id = SCRIPT_ID;
  el.type = 'text/javascript';
  el.async = true;
  el.src = `https://dynamic.criteo.com/js/ld/ld.js?a=${account}`;
  document.body.appendChild(el);

  window.criteo_q = window.criteo_q || [];

  window.criteo_q.push(
    { event: 'setAccount', account },
    { event: 'setCustomerId', id: user.id },
    { event: 'setRetailerVisitorId', id: user.cookieId },
    { event: 'setEmail', email: sha256(user.email || ''), hash_method: 'sha256' },
    { event: 'setSiteType', type: DEVICE_MAPPING[user.deviceName] },
  );
}

function accountForUser(user: Partial<IUser>) {
  if (!user.thirdPartyAdDataAllowed) {
    return null;
  }

  switch (user.countryCode) {
    case 'US':
      return AMERICAS_ACCOUNT_ID;
    case 'GB':
      return EMEA_ACCOUNT_ID;
    default:
      return null;
  }
}

function pushEvent(evt, user) {
  initCriteo(user);

  if (typeof window.criteo_q?.push === 'function') {
    window.criteo_q.push(evt);
  }
}

export function trackCriteoProductView(listingId, user: Partial<IUser>) {
  pushEvent({
    event: 'viewItem',
    item: `${listingId}`,
  }, user);
}

interface ProductListParams {
  listingIds: (string | number)[];
  keywords?: string;
  page_number?: number;
}
const MAX_PRODUCT_IDS = 3;

export function trackCriteoProductList(params: ProductListParams, user: Partial<IUser>) {
  const { listingIds, ...evtParams } = params;
  if (listingIds.length === 0) { return; }

  const ids = listingIds.slice(0, MAX_PRODUCT_IDS).map(id => `${id}`);

  pushEvent({
    event: 'viewList',
    ...evtParams,
    item: ids,
  }, user);
}

export function trackCriteoTransaction(orderBundle: Commons_MParticle_OrderBundlesQuery['orderBundle'], user: Partial<IUser>) {
  const items = orderBundle.orders.nodes.map((o) => (
    {
      id: o.listing.id,
      price: o.listing.pricing.buyerPrice.amount,
      quantity: o.quantity,
    }
  ));

  pushEvent({
    event: 'trackTransaction',
    id: orderBundle.orders.nodes[0].id, // Use first order ID as bundle identifier
    item: items,
  }, user);
}

export function trackCriteoHomepageView(user: Partial<IUser>) {
  pushEvent({ event: 'viewHome' }, user);
}

interface CriteoItem {
  id: string,
  price: string,
  quantity: number,
}

export function trackCriteoAddToCart(item: CriteoItem, user: Partial<IUser>) {
  pushEvent({
    event: 'addToCart',
    item: [item],
  }, user);
}

export function trackCriteoCartView(items: CriteoItem[], user: Partial<IUser>) {
  pushEvent({
    event: 'viewBasket',
    item: items,
  }, user);
}
