import React from 'react';
import update from 'immutability-helper';
import { arrayMove } from 'react-sortable-hoc';
import Modal from '@reverbdotcom/commons/src/components/modal';
import { RCAlertBox } from '@reverbdotcom/cadence/components';
import Photo, { IPhoto, IPhotoAttrs } from './photo';
import Editor from './editor';
import ThumbList from './thumb_list';
import Uploader from './uploader';
import { camelizeKeys, decamelizeKeys } from 'humps';
import I18n from 'i18n-js';

const minimumDimension = 200; // (px) We will prevent images less than this on either isde.
const minimumWarnDimension = 620; // (px) We will warn them if less than this on either side.

interface ISharedProps {
  inputName: string;
  uploadOptions: any;
  initialPhotos?: any[];
  requireMinimumDimensions?: boolean;
  allowEdit?: boolean;
  renderTip?: boolean;
  categories?: any[];
  imageDetectionPath?: string;
  shouldDetectImages?: boolean;
  categoryId?: number;
  dropZoneSelector?: string;
  disallowMultiplePhotos?: boolean;
  labels?: {
    thumbMessage?: string;
    tipText?: string;
    dragOverText?: string;
  }
}

interface IUncontrolledProps extends ISharedProps {
  initialPhotos?: IPhotoAttrs[];
}

interface IControlledProps extends ISharedProps {
  photos: IPhotoAttrs[];
  onPhotosChange: (imageEntries: IPhotoAttrs[]) => void;
}

interface IControlledState {
  editingPhoto: IPhoto;
  isDroppingFile: boolean;
  warnedPrimaryImageId?: string;
  warningTypeNames: string[];
  showPrimaryPhotoWarning: boolean;
  showTooSmallWarning: boolean;
  showTooSmallError: boolean;
  showUploadFailureError: boolean;
}

interface IUncontrolledState {
  photos: IPhotoAttrs[];
}

class UncontrolledPhotos extends React.Component<IUncontrolledProps, IUncontrolledState> {
  constructor(props: IUncontrolledProps) {
    super(props);

    this.state = {
      photos: (props.initialPhotos || []).map(photo => camelizeKeys(photo)),
    };

    this.handlePhotosChange = this.handlePhotosChange.bind(this);
  }

  handlePhotosChange(photos) {
    this.setState({ photos });
  }

  render() {
    return (
      <ControlledPhotos
        onPhotosChange={this.handlePhotosChange}
        photos={this.state.photos}
        {...this.props}
      />
    );
  }
}

class ControlledPhotos extends React.Component<IControlledProps, IControlledState> {
  static defaultProps: Partial<IControlledProps> = {
    photos: [],
    requireMinimumDimensions: true,
    allowEdit: true,
    renderTip: true,
    shouldDetectImages: false,
  };

  constructor(props) {
    super(props);

    this.state = {
      editingPhoto: null,
      isDroppingFile: false,
      warningTypeNames: [],
      showPrimaryPhotoWarning: false,
      showTooSmallWarning: false,
      showTooSmallError: false,
      showUploadFailureError: false,
    };
    this.handleCloseEditor = this.handleCloseEditor.bind(this);
    this.handleUpdatePhoto = this.handleUpdatePhoto.bind(this);
    this.handlePhotoAdded = this.handlePhotoAdded.bind(this);
    this.handleUploadFailure = this.handleUploadFailure.bind(this);
    this.handleFileDropStart = this.handleFileDropStart.bind(this);
    this.handleFileDropEnd = this.handleFileDropEnd.bind(this);
    this.handleEditPhoto = this.handleEditPhoto.bind(this);
    this.handleRemovePhoto = this.handleRemovePhoto.bind(this);
    this.handleSortEnd = this.handleSortEnd.bind(this);
    this.shouldCancelDrag = this.shouldCancelDrag.bind(this);
  }

  newSmallPhoto(photo) {
    return this.props.requireMinimumDimensions && !photo.meetsMinimumDimension(minimumWarnDimension) && !photo.id;
  }

  get photos(): IPhoto[] {
    return this.props.photos.map((photoAttrs) => {
      const photo = new Photo(photoAttrs);

      if (this.newSmallPhoto(photo)) {
        photo.showTooSmallWarning = true;
      }

      return photo;
    });
  }

  get isEditingPhoto() {
    return !!this.state.editingPhoto;
  }

  get allowUpload() {
    return !this.props.disallowMultiplePhotos || !this.photos.length;
  }

  handleEditPhoto(photo: IPhoto) {
    this.setState({ editingPhoto: photo });
  }

  handleCloseEditor() {
    this.setState({ editingPhoto: null });
  }

  handleUpdatePhoto(updatedPhoto: IPhoto) {
    this.updatePhoto(updatedPhoto);
    this.setState({ editingPhoto: null });
  }

  handleRemovePhoto(photo: IPhoto) {
    this.removePhoto(photo);

    const anyRemainingPhotosTooSmall = this.photos.some(p => p.publicId !== photo.publicId && p.showTooSmallWarning);
    if (!anyRemainingPhotosTooSmall) {
      this.closeErrorMessage('showTooSmallWarning');
    }
  }

  removePhoto(photoToRemove: IPhoto) {
    const photoIndex = this.photos.findIndex(photo =>
      photo.publicId === photoToRemove.publicId);

    const newPhotos = update(this.props.photos, {
      $splice: [[photoIndex, 1]],
    });

    this.updatePhotos(newPhotos);
  }

  handlePhotoAdded(photo: IPhoto) {
    if (!this.props.requireMinimumDimensions || photo.meetsMinimumDimension(minimumWarnDimension)) {
      this.addPhoto(photo);
    } else if (photo.meetsMinimumDimension(minimumDimension)) {
      this.setState({ showTooSmallWarning: true });
      this.addPhoto(photo);
    } else {
      this.setState({ showTooSmallError: true });
    }
  }

  handleUploadFailure() {
    this.setState({ showUploadFailureError: true });
  }

  updatePhoto(updatedPhoto: IPhoto) {
    const photoIndex = this.photos.findIndex(photo =>
      photo.publicId === updatedPhoto.publicId);

    const { photos } = this.props;
    photos[photoIndex] = updatedPhoto.attrsWithUpdates;

    this.updatePhotos(photos);
  }

  updatePhotos(newPhotos: IPhotoAttrs[]) {
    this.props.onPhotosChange(newPhotos);
  }

  addPhoto(photo: IPhoto) {
    if (!this.allowUpload) {
      return;
    }

    const newPhotos = update(this.props.photos, {
      $push: [photo.attrsWithUpdates],
    });

    this.updatePhotos(newPhotos);
  }

  handleSortEnd({ oldIndex, newIndex }) {
    this.updatePhotos(arrayMove(this.props.photos, oldIndex, newIndex));
  }

  shouldCancelDrag(e) {
    if (e.target.tagName.toLowerCase() === 'a') {
      return true;
    }
    return false;
  }

  handleFileDropStart() {
    if (this.allowUpload) {
      this.setState({ isDroppingFile: true });
    }
  }

  handleFileDropEnd() {
    if (this.allowUpload) {
      this.setState({ isDroppingFile: false });
    }
  }

  closeErrorMessages() {
    this.setState({
      showTooSmallWarning: false,
      showTooSmallError: false,
      showPrimaryPhotoWarning: false,
      showUploadFailureError: false,
    });
  }

  closeErrorMessage(stateKey) {
    const newState = {};
    newState[stateKey] = false;
    this.setState(newState);
  }

  tipText() {
    if (this.props.photos.length === 0) {
      return this.props.labels?.tipText || I18n.t('discovery.photos.tip.uploadOrDrop');
    }

    return I18n.t('discovery.photos.tip.reorder');
  }

  renderEditor() {
    if (!this.isEditingPhoto) { return false; }

    return (
      <Editor
        photo={this.state.editingPhoto}
        onCloseEditor={this.handleCloseEditor}
        onUpdatePhoto={this.handleUpdatePhoto}
      />
    );
  }

  renderEditModal() {
    return (
      <Modal
        isOpen={this.isEditingPhoto}
      >
        {this.renderEditor()}
      </Modal>
    );
  }

  renderUploader() {
    return (
      <Uploader
        uploadOptions={this.props.uploadOptions}
        onFileAdded={this.handlePhotoAdded}
        onDragStart={this.handleFileDropStart}
        onDragEnd={this.handleFileDropEnd}
        onUploadFailure={this.handleUploadFailure}
        dropZoneSelector={this.props.dropZoneSelector}
        thumbMessage={this.props.labels?.thumbMessage}
      />
    );
  }

  renderAlert(stateKey, heading, message, type, warningName = null) {
    return (
      <div className="mb-2" key={warningName}>
        <RCAlertBox type={type} small>
          <div className="lh-140">
            <button
              className="img-uploader__message__close"
              onClick={() => this.closeErrorMessage(stateKey)}
            />
            <span className="weight-bold">
              {heading}
            </span>
            <div className="size-90 pt-1">
              {message}
            </div>
          </div>
        </RCAlertBox>
      </div>
    );
  }

  renderUploadFailureError() {
    if (this.state.showUploadFailureError) {
      return this.renderAlert(
        'showUploadFailureError',
        I18n.t('discovery.photos.uploadFailureHeading'),
        I18n.t('discovery.photos.uploadFailure'),
        'error',
      );
    }
    return null;
  }

  renderTooSmallWarning() {
    if (this.state.showTooSmallWarning) {
      return this.renderAlert(
        'showTooSmallWarning',
        I18n.t('discovery.photos.smallImageWarningHeading'),
        I18n.t('discovery.photos.smallImageWarning'),
        'info',
      );
    }
    return null;
  }

  renderTooSmallError() {
    if (this.state.showTooSmallError) {
      return this.renderAlert(
        'showTooSmallError',
        I18n.t('discovery.photos.smallImageErrorHeading'),
        I18n.t('discovery.photos.smallImageError'),
        'error',
      );
    }
    return null;
  }

  renderPrimaryPhotoWarning() {
    if (this.state.showPrimaryPhotoWarning) {
      return this.state.warningTypeNames.map(warningName =>
        this.renderAlert(
          'showPrimaryPhotoWarning',
          I18n.t(`discovery.photos.${warningName}WarningHeading`),
          I18n.t(`discovery.photos.${warningName}Warning`),
          'info',
          warningName,
        ));
    }
    return null;
  }

  renderThumbs() {
    return (
      <ThumbList
        photos={this.photos}
        onEditPhoto={this.handleEditPhoto}
        onRemovePhoto={this.handleRemovePhoto}
        axis="xy"
        onSortEnd={this.handleSortEnd}
        shouldCancelStart={this.shouldCancelDrag}
        allowEdit={this.props.allowEdit}
      >
        {this.allowUpload && this.renderUploader()}
      </ThumbList>
    );
  }

  renderDropFileOverlay() {
    if (this.state.isDroppingFile) {
      return (
        <div className="img-uploader__drop-file-overlay">
          {this.props.labels?.dragOverText || I18n.t('discovery.photos.drop_files')}
        </div>
      );
    }
    return null;
  }

  renderPhotosField() {
    const value = JSON.stringify(this.photos.map(photo => decamelizeKeys(photo.attrsWithUpdates)));
    return (
      <input
        type="hidden"
        name={`${this.props.inputName}[]`}
        value={value}
        className="img-uploader__photos-input"
      />
    );
  }

  renderPhotoOrderFields() {
    return this.photos.map((photo, index) => {
      const inputName = `photo_order[${photo.publicId}]`;
      return (
        <input
          type="hidden"
          name={inputName}
          value={index}
          key={photo.publicId}
          className="img-uploader__photo-order-input"
        />
      );
    });
  }

  renderImageDetectionFeedbackField() {
    if (!this.state.warnedPrimaryImageId) { return null; }
    const inputName = 'photo_detection_feedback';
    return (
      <input
        type="hidden"
        name={inputName}
        value={this.state.warnedPrimaryImageId}
      />
    );
  }

  renderTip() {
    if (!this.props.renderTip || !this.allowUpload) { return null; }

    return (
      <div className="img-uploader__tip">
        {this.tipText()}
      </div>
    );
  }

  render() {
    return (
      <div ref="container" className="img-uploader">
        {this.renderTip()}
        {this.renderUploadFailureError()}
        {this.renderTooSmallError()}
        {this.renderTooSmallWarning()}
        {this.renderPrimaryPhotoWarning()}
        {this.renderThumbs()}
        {this.renderEditModal()}
        {this.renderDropFileOverlay()}
        {this.renderPhotosField()}
        {this.renderPhotoOrderFields()}
        {this.renderImageDetectionFeedbackField()}
      </div>
    );
  }
}

export { UncontrolledPhotos, ControlledPhotos };
