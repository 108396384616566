import I18n from 'i18n-js';

import { core_apimessages_Measurement, core_apimessages_ShippingLabelPackage, ShipmentPackage } from '@reverbdotcom/commons/src/gql/graphql';

import { isUSA } from '../../../lib/geo';

export enum MeasurementSystem {
  METRIC = 'metric',
  IMPERIAL = 'imperial',
}

export enum PackageType {
  CUSTOM = 'custom',
  FLAT_RATE = 'flatRate',
}

interface IExistingPackageData {
  length?: core_apimessages_Measurement;
  width?: core_apimessages_Measurement;
  height?: core_apimessages_Measurement;
  weight?: core_apimessages_Measurement;
  predefinedPackage?: string;
  allowedCarriers?: string[];
  packageSizeSuggestionId?: string;
}

export const OZ_TO_LB = 16;

export function mapCountryCodeToDefaultMeasurementSystem(
  countryCode: string,
): MeasurementSystem {
  if (isUSA(countryCode)) {
    return MeasurementSystem.IMPERIAL;
  }

  return MeasurementSystem.METRIC;
}

export function convertPoundsToPoundsAndOunces(weightInPounds) {
  const pounds = Math.floor(weightInPounds);
  const ounces = Math.ceil((weightInPounds - pounds) * OZ_TO_LB);
  return { pounds, ounces };
}

export function convertPoundsAndOuncesToPounds(pounds: number, ounces: number) {
  return pounds + (ounces / 16);
}

export function convertPackageUnits(
  shippingLabelPackage: core_apimessages_ShippingLabelPackage,
  measurementSystem: MeasurementSystem,
): core_apimessages_ShippingLabelPackage {
  return buildPackageEntry(shippingLabelPackage, measurementSystem);
}

export function getMeasurementUnitFromPackage(
  shippingPackage: core_apimessages_ShippingLabelPackage | ShipmentPackage,
): MeasurementSystem {
  if (shippingPackage.length.unit === LengthUnit.INCHES) {
    return MeasurementSystem.IMPERIAL;
  }
  return MeasurementSystem.METRIC;
}

export function displayWeight(weight: core_apimessages_Measurement) {
  if (weight.unit === WeightUnit.KILOGRAMS) {
    return I18n.t('discovery.dashboard.selling.shippingLabels.shippingRates.displayMeasurement', {
      value: weight.value,
      unit: weight.unit,
    });
  } else {
    const { pounds, ounces } = convertPoundsToPoundsAndOunces(weight.value);
    const displayPounds = I18n.t('discovery.dashboard.selling.shippingLabels.shippingRates.displayMeasurement', {
      value: pounds,
      unit: WeightUnit.POUNDS,
    });

    const displayOunces = I18n.t('discovery.dashboard.selling.shippingLabels.shippingRates.displayMeasurement', {
      value: ounces,
      unit: WeightUnit.OUNCES,
    });

    return `${displayPounds} ${displayOunces}`;
  }
}

export function lengthWidthHeightSummary(shippingLabelPackage: core_apimessages_ShippingLabelPackage): string {
  if (!shippingLabelPackage) { return ''; }
  if (['length', 'width', 'height'].some((key) => !shippingLabelPackage[key]?.value || !shippingLabelPackage[key]?.unit)) { return ''; }

  return I18n.t('discovery.dashboard.selling.shippingLabels.shippingRates.lengthWidthHeightSummary', {
    lengthSummary: I18n.t('discovery.dashboard.selling.shippingLabels.shippingRates.displayMeasurement', {
      value: shippingLabelPackage.length.value,
      unit: shippingLabelPackage.length.unit,
    }),
    widthSummary: I18n.t('discovery.dashboard.selling.shippingLabels.shippingRates.displayMeasurement', {
      value: shippingLabelPackage.width.value,
      unit: shippingLabelPackage.width.unit,
    }),
    heightSummary: I18n.t('discovery.dashboard.selling.shippingLabels.shippingRates.displayMeasurement', {
      value: shippingLabelPackage.height.value,
      unit: shippingLabelPackage.height.unit,
    }),
  });
}

export function buildPackageEntry(
  existingPackageData: IExistingPackageData,
  measurementSystem?: MeasurementSystem,
  packageSizeSuggestionId = '',
): core_apimessages_ShippingLabelPackage {
  const lengthUnit = measurementSystem ? getLengthUnit(measurementSystem) : (existingPackageData?.length?.unit || LengthUnit.INCHES);
  const weightUnit = measurementSystem ? getWeightUnit(measurementSystem) : (existingPackageData?.weight?.unit || WeightUnit.POUNDS);

  return {
    length: {
      value: existingPackageData?.length?.value || '',
      unit: lengthUnit,
    },
    width: {
      value: existingPackageData?.width?.value || '',
      unit: lengthUnit,
    },
    height: {
      value: existingPackageData?.height?.value || '',
      unit: lengthUnit,
    },
    weight: {
      value: existingPackageData?.weight?.value || '',
      unit: weightUnit,
    },
    predefinedPackage: existingPackageData?.predefinedPackage || '',
    allowedCarriers: existingPackageData?.allowedCarriers,
    packageSizeSuggestionId: existingPackageData?.packageSizeSuggestionId || packageSizeSuggestionId,
  };
}

export enum LengthUnit {
  CENTIMETERS = 'cm',
  INCHES = 'in',
}

export enum WeightUnit {
  KILOGRAMS = 'kg',
  POUNDS = 'lb',
  OUNCES = 'oz',
}

function getLengthUnit(measurementSystem: MeasurementSystem) {
  if (measurementSystem === MeasurementSystem.METRIC) {
    return LengthUnit.CENTIMETERS;
  }

  return LengthUnit.INCHES;
}

function getWeightUnit(measurementSystem: MeasurementSystem) {
  if (measurementSystem === MeasurementSystem.METRIC) {
    return WeightUnit.KILOGRAMS;
  }

  return WeightUnit.POUNDS;
}
