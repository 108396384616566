import React from 'react';
import I18n from 'i18n-js';
import BumpV2ModalContainer, { BumpV2ModalContainerProps } from './bump_v2_modal_container';
import SanitizedRender from '@reverbdotcom/commons/src/components/sanitized_render';
import { formatBid } from './bump_helpers';
import { BumpControllerProps, bumpRecommendationsController } from './bump_controller';

const COMPONENT_NAME = 'ProductPageBump';

interface ExternalProps {
  showModal?: boolean;
  hidePercentCallout?: boolean;
  buttonText?: string | React.ReactNode | JSX.Element;
  buttonClassName?: string;
  refetch?: (newBid: number | null) => void;
  onModalClose?: () => void;
  disabled?: boolean;
}

interface PercentCalloutProps {
  bid: number;
}

function PercentCallout(props: PercentCalloutProps) {
  if (!props.bid) {
    return null;
  }

  return (
    <SanitizedRender
      htmlTag="div"
      className="size-90 opacity-70 mb-2"
      html={I18n.t('discovery.bumpModalV2.bumpAmount',
        {
          tagOpen: '<b>',
          tagClose: '</b>',
          amount: `${formatBid(props.bid)}%`,
        },
      )}
    />
  );
}

export function BumpButton(props: ExternalProps & BumpV2ModalContainerProps) {
  const [showModal, setShowModal] = React.useState(props.showModal ?? false);
  React.useEffect(() => {
    setShowModal(props.showModal);
  }, [props.showModal]);

  const buttonText = props.buttonText || I18n.t(`discovery.bumpModalV2.${props.bid ? 'buttonEdit' : 'buttonBump'}`);
  const buttonClassName = props.buttonClassName || 'button button--primary';

  function onRequestClose() {
    setShowModal(false);
    if (props.onModalClose) {
      props.onModalClose();
    }
  }

  return (
    <div className="align-center">
      {!props.hidePercentCallout && <PercentCallout bid={props.bid} />}
      <button
        className={buttonClassName}
        onClick={() => setShowModal(true)}
        disabled={props.disabled}
        type="button"
        aria-label={I18n.t('discovery.bumpModalV2.buttonAriaLabel')}
      >
        {buttonText}
      </button>
      <BumpV2ModalContainer
        bid={props.bid}
        listingId={props.listingId}
        selectedBid={props.selectedBid}
        handleBidChange={props.handleBidChange}
        updateBid={props.updateBid}
        successMessage={props.successMessage}
        failMessage={props.failMessage}
        refetch={props.refetch ?? (() => {})}
        onRequestClose={onRequestClose}
        modalIsOpen={showModal}
        renderWithModalDialogWrapper
      />
    </div>
  );
}

const BumpButtonContainer = bumpRecommendationsController(BumpButton);

export default function BumpButtonWrapper(props: ExternalProps & BumpControllerProps) {
  return (
    <BumpButtonContainer
      componentName={COMPONENT_NAME}
      listingId={props.listingId}
      bid={props.bid}
      showModal={props.showModal}
      hidePercentCallout={props.hidePercentCallout}
      buttonText={props.buttonText}
      buttonClassName={props.buttonClassName}
      refetch={props.refetch}
      onModalClose={props.onModalClose}
      disabled={props.disabled}
    />
  );
}
