import { error } from '@reverbdotcom/commons/src/elog';

const cache = {};

/*
 * parseMeta is useful for grabbing data out of the head
 * passed up from the server.
 *
 * parseMeta will _first_ check to see if the data is cached and
 * return that value first.
 *
 * while it may be tempting to store base64 tags into the head,
 * there are some serious issues with parsing utf8 strings.
 */
export function parseMeta(tagName) {
  if (cache[tagName]) {
    return cache[tagName];
  }

  const tag = document.querySelector<HTMLMetaElement>(`meta[name=${tagName}]`);

  try {
    if (!tag || !tag.content) {
      return null;
    }

    const parsed = JSON.parse(tag.content);
    cache[tagName] = parsed;

    return parsed;
  } catch (e) {
    error('parse_meta.error', {
      tagName,
      tagContent: tag.content,
      error: e,
    });
  }

  return null;
}

/**
 * This "frontendCurrentUser" is divergent from the user-context
 * schema used to identify elog/analytics. Because of the size
 * of the experiments payload, we are sharing that value.
 *
 * Aligning these two concepts in the future would be prudent. They
 * differ mostly in key casing (camel vs snake) and the frontendUserConfig,
 * lacking any schema or definition has some additional keys.
 */
export function frontendCurrentUser() {
  const current = parseMeta('current-user');
  const elogConfig = parseMeta('elog-config');

  if (!elogConfig || !current) {
    return null;
  }

  return {
    user: {
      ...current.user,
      experiments: elogConfig.elog.userContext.experiments,
    },
  };
}
