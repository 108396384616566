import React, { ReactElement } from 'react';
import classNames from 'classnames';
import { IUserContext } from '@reverbdotcom/commons/src/components/user_context_provider';

interface IProps extends IUserContext {
  title: string | JSX.Element;
  subtitle?: string | JSX.Element;
  linkUrl?: string;
  linkText?: string;
  linkInline?: boolean;
  subclass?: string;
  inline?: boolean;
  icon?: ReactElement;
}

export default function ListingNudge(props: IProps) {

  const classes = classNames(
    'listing-nudge',
    { 'listing-nudge--inline': props.inline },
    props.subclass,
  );

  const linkClasses = classNames(
    'size-90',
    { 'weight-bold': !props.linkInline },
    { 'td-underline color-black': props.linkInline },
    { 'ml-1': props.linkInline && props.subtitle },
  );

  const showPassedInIcon = !!props.icon;

  return (
    <div className={classes}>
      <div className="listing-nudge__icon">
        {showPassedInIcon && props.icon}
      </div>
      <div className="listing-nudge__inner">
        <p className="listing-nudge__title" data-nudge-title>
          {props.title}
        </p>
        { props.linkInline ?
          <>
            <p className="listing-nudge__subtitle" data-nudge-subtitle>
              {props.subtitle}
              {!!props.linkUrl && !!props.linkText &&
                <a
                  className={linkClasses}
                  href={props.linkUrl}
                  data-nudge-link
                >
                  {props.linkText}
                </a>
              }
            </p>
          </>
          :
          <>
            {!!props.subtitle &&
              <p className="listing-nudge__subtitle" data-nudge-subtitle>
                {props.subtitle}
              </p>
            }
            {!!props.linkUrl && !!props.linkText &&
              <a
                className={linkClasses}
                href={props.linkUrl}
                data-nudge-link
              >
                {props.linkText}
              </a>
            }
          </>
        }
      </div>
    </div>
  );
}
