import PropTypes from 'prop-types';
import React from 'react';
import { PriceDisplay } from '@reverbdotcom/commons';
import ShippingPrice from '../listings/shipping_price';
import { ConditionDisplay } from '@reverbdotcom/discovery-ui';

class EmbeddedListing extends React.Component {
  get listing() {
    return this.props.listing;
  }

  render() {
    if (!this.listing) { return null; }

    return (
      <div className="product-row-card product-row-card--embedded">
        <a href={this.listing._links.web.href}>
          <div className="product-row-card__image">
            <img src={this.listing.photos[0]._links.thumbnail.href} role="presentation" />
          </div>
          <div className="product-row-card__title">
            <div className="product-row-card__title__text">{this.listing.title}</div>
            <div
              className="product-row-card__title__description"
              dangerouslySetInnerHTML={{ __html: this.listing.description }}
            />
            <ConditionDisplay condition={this.listing.condition} />
          </div>
          <div className="product-row-card__price">
            <div className="product-row-card__price__base">
              <PriceDisplay display={this.listing.price.display} />
            </div>
            <div className="product-row-card__price__shipping">
              + <ShippingPrice shippingRate={this.listing.shipping.user_region_rate.rate} />
            </div>
          </div>
        </a>
      </div>
    );
  }
}

EmbeddedListing.propTypes = {
  listing: PropTypes.object.isRequired,
};

export default EmbeddedListing;
