import User from '../user';
import '@reverbdotcom/discovery-ui/src/vendor/jquery-fancybox';

export const UI = (function () {
  const Dialogs = (function () {
    function bindLinksToLightboxes() {
      $(document).on('click', 'a[data-lightbox]', function (e) {
        e.preventDefault();
        const lightbox_id = $(this).data('lightbox');
        $.fancybox(`#${lightbox_id}`);

        if ($(this).data('tab')) {
          window.location = `#${$(this).data('tab')}`;
        }
      });
    }

    function bindDialogSubmit() {
      $(document).on('click', '[rel=dialog][data-autosubmit] a[rel=submit]', function (e) {
        e.preventDefault();
        $(this).parents('[rel=dialog]').find('form').submit();
      });
    }

    function bindSubmitByLink() {
      $(document).on('click', 'a[rel=submit-form]', function (e) {
        e.preventDefault();
        $(this).parents('form').submit();
      });
    }

    return {
      init() {
        bindLinksToLightboxes();
        bindDialogSubmit();
        bindSubmitByLink();
      },
    };
  }());

  function init() {
    setupFancyboxDefaults();
    requireRegistration();
    handleDisabledButtons();

    Dialogs.init();

    displayFlash();
    formPowertips();
    handleLinksToButtons();
    toggleSection();
  }

  function setupFancyboxDefaults() {
    if (jQuery.fancybox) {
      jQuery.extend(jQuery.fancybox.defaults, {
        padding: 0,
        scrolling: 'no',
        helpers: {
          overlay: {
            locked: false,
          },
        },
      });

      $(document).on('click', 'a.close', (e) => {
        e.preventDefault();
        $.fancybox.close();
      });
    }
  }

  function spinWithMessage(selector, message) {
    const elem = $(selector);
    const origHeight = elem.height();

    elem.addClass('alert alert-success').html(`<h3 class='center margined-top'>${message}</h3>`);
    elem.height(origHeight);
    elem.spin();
  }

  function updateAndHighlight(dom_id, content) {
    const elem = $(`#${dom_id}`);
    const createdEl = elem.removeClass('alert alert-success').replaceWith(content).highlight();
    $(document).trigger('reverb:ui:element_added', createdEl);
  }

  function displayMessageThenContent(dom_id, message, content, messageDisplayDelay) {
    if (!messageDisplayDelay) { messageDisplayDelay = 500; }

    spinWithMessage(`#${dom_id}`, message);
    setTimeout(() => {
      updateAndHighlight(dom_id, content);
      $(`#${dom_id}`).height('auto');
    }, messageDisplayDelay);
  }

  function handleLinksToButtons() {
    $('[data-btn-ref]').each(function () {
      const link = $(this);
      link.click((e) => {
        $(link.data('btn-ref')).click();
        e.preventDefault();
      });
    });
  }

  function formPowertips() {
    $('input.powertip').powerTip({ placement: 'e' });
  }

  function popupRegistration(e, source_element) {
    e.preventDefault();
    e.stopImmediatePropagation();
    User.showInlineRegistration(e, source_element);
  }

  function popupLogin(e, source_element) {
    e.preventDefault();
    e.stopImmediatePropagation();
    User.showInlineLogin(e, source_element);
  }

  function popupRegistrationOrLogin(e) {
    if (!$('body').data('signed-in')) {
      User.showInlineRegistrationOrLogin(e, this);
    }
  }

  function isLoggedIn() {
    return $('body').data('signed-in');
  }

  function isLoggedOut() {
    return !isLoggedIn();
  }

  function requireRegistration() {
    // bind to document.body instead of document as a way to ensure other callbacks bound to these
    // elements at the document level get fired after this. Eg the make an offer button has a
    // data-slideout but  we don't want that to fire if you are logged out.
    $(document.body).on('click', 'input[data-registration=required],a[data-registration=required]', popupRegistrationOrLogin);
    $(document.body).on('submit', 'form[data-registration=required]', popupRegistrationOrLogin);

    $(document.body).on('click', '[data-registration=force-login]', function (e) {
      if (isLoggedOut()) {
        User.showInlineLogin(e, this);
      }
    });

    $(document.body).on('click', '[data-registration=force-registration]', function (e) {
      if (isLoggedOut()) {
        e.preventDefault();
        User.showInlineRegistration(e, this);
      }
    });
  }

  function displayFlash() {
    const message = $('body').data('flash');
    if (message) {
      show_flash_message(message.message, message.message_class);
    }
  }

  function hideFlashMessages() {
    $('.flash-messages--active').removeClass('flash-messages--active');
  }

  /* Render a flash message */
  /* @param message [String] message text */
  /* @param type [String] message type: info, alert, etc */
  function show_flash_message(message, type) {
    const container = $('.flash-messages');
    container.attr('data-flash-message-type', type);
    container.addClass('flash-messages--active');
    container.find('.flash-message').html(message);

    setTimeout(hideFlashMessages, 5000);
    container.find('.flash-messages__close')
      .click(hideFlashMessages);
  }

  function showFlashError(message) {
    show_flash_message(message, 'error');
  }

  function showFlashNotice(message) {
    show_flash_message(message, 'notice');
  }

  function showFlashSuccess(message) {
    show_flash_message(message, 'success');
  }

  function handleDisabledButtons() {
    $(document).on('click', 'a.btn-disabled', (e) => {
      e.preventDefault();
    });
  }

  function toggleSection() {
    $(document).on('click', '.toggler', function (e) {
      e.preventDefault();
      $(this).toggleClass('open');
      const sectionId = `#${$(this).attr('rel')}`;
      $(sectionId).stop().slideToggle();
    });
  }

  // Given a promise and a target to put the spinner, it spins
  function spin(promise, target) {
    target.spin();
    target.css({ opacity: 0.8 });
    promise.done(() => {
      $(target).stopSpinner();
      target.css({ opacity: 1.0 });
    });
    return promise;
  }

  // toggles slideout the on the elemToShow
  // optionally focuses on elemToAutofocus
  function slideOut(elemToShow, elemToAutofocus) {
    elemToAutofocus = typeof elemToAutofocus !== 'undefined' ? elemToAutofocus : null;
    $(elemToShow).slideToggle(300, () => {
      if (elemToAutofocus) {
        $(elemToAutofocus).focus();
      }
    }).toggleClass('slideout-active');
  }

  return {
    init,
    show_flash_message,
    flashError: showFlashError,
    flashNotice: showFlashNotice,
    flashSuccess: showFlashSuccess,
    displayMessageThenContent,
    spinWithMessage,
    updateAndHighlight,
    isLoggedIn,
    isLoggedOut,
    spin,
    slideOut,
  };
}());

UI.Ajax = (function () {
  function lightbox(href, callback, lightboxOptions) {
    $.get(href).done((data) => {
      const defaultOptions = {
        centerOnScroll: true,
        afterShow() {
          if (callback) { callback.call(this); }
          Reverb.Renderer.render(window, this.inner.get(0));
        },
      };

      $.extend(defaultOptions, lightboxOptions);

      $.fancybox(data, defaultOptions);
    });
  }

  function bindRemoteLightboxes() {
    $(document).on('click', '[data-remote-lightbox]', function (e) {
      if ($(this).data('mobile-degrade') && window.Reverb.MobileDetection.isMobileBrowser()) {
        // no-op. Let it fall through to the regular url.
      } else {
        const element = $(this);
        const lightboxOptions = element.data('lightbox-options');

        const lightboxCallback = function () {
          // https://sentry.io/reverb-llc/discovery-ui/issues/670392391/?query=is:unresolved
          // This is blowing up for certain versions of IE 11 on windows 7. Given that the
          // focus is a nice to have, let's swallow this error.
          try {
            if (this.inner) {
              this.inner.focusFirstInput();
            }
          } catch (e) {
            console.error(e);
          }

          element.trigger('reverb:remote-lightbox:opened');
        };

        const href = element.data('lightbox-href') || element.prop('href');
        UI.Ajax.lightbox(href, lightboxCallback, lightboxOptions);
        e.preventDefault();
        e.stopPropagation();
      }
    });
  }

  return {
    lightbox,
    init: bindRemoteLightboxes,
  };
}());

// Duplication: Superseded by plugin - app/assets/javascripts/plugins/jquery.check-all.js
UI.CheckAll = (function () {
  function bindMasterCheckbox(masterCheckboxSelector, slaveCheckboxSelector) {
    $(document).on('change', masterCheckboxSelector, (e) => {
      const $enabledCheckboxes = $(slaveCheckboxSelector).not(':disabled');


      const checked = $(e.currentTarget).is(':checked');

      $enabledCheckboxes.prop('checked', checked);
    });
  }

  function autoBind() {
    bindMasterCheckbox('[data-checkbox-master]', '[data-checkbox-slave]');
  }

  return {
    init: bindMasterCheckbox,
    autoBind,
  };
}());

function initAll() {
  UI.init();
  UI.Ajax.init();
  UI.CheckAll.autoBind();
}

$(initAll);

export default {
  UI,
};
