import { ChildProps } from '@apollo/client/react/hoc';
// TODO update this to import from commons/src/gql
// eslint-disable-next-line no-restricted-imports
import { gql } from '@apollo/client';
import React from 'react';
import { withGraphql } from '@reverbdotcom/commons/src/with_graphql';
import { connect } from '@reverbdotcom/commons/src/connect';
import I18n from 'i18n-js';
import { ListingsCollection as ListingsCollectionQuery, CoreHomepageRecentlyViewedSimilarListingsSearch } from '../gql/graphql';
import ListingsCollection, { ListingsCollection as ListingsCollectionClass } from '@reverbdotcom/commons/src/components/listings_collection';
import { useViewTracking } from '@reverbdotcom/commons/src/use_tracking';
import { MParticleEventName } from '@reverbdotcom/commons/src/elog/mparticle_types';
import { mParticleListingFields } from '@reverbdotcom/commons/src/components/with_mparticle_listings';

interface IExternalProps {
  recentlyViewedListings: ListingsCollectionQuery.Fragment[];
  largeTiles?: boolean;
  maxCount?: number;
  displayAsGrid?: boolean;
  jumplinkSlug?: string;
  displayAddToCart?: boolean;
  showFlags?: boolean;
  showShippingDisplay?: boolean;
  cmsComponentId?: string;
}

const COMPONENT_NAME = 'RecentlyViewedAndMore';
const MIN_LISTINGS = 6;

export type IProps = ChildProps<IExternalProps, CoreHomepageRecentlyViewedSimilarListingsSearch.Query>;

export function RecentlyViewedAndMoreListings(props: IProps) {
  const {
    recentlyViewedListings,
    displayAsGrid,
    largeTiles,
    maxCount,
    jumplinkSlug,
    displayAddToCart,
    showFlags,
    showShippingDisplay,
    cmsComponentId,
  } = props;

  const loading = props.data?.loading;
  const totalListings = recentlyViewedListings.concat(
    props.data?.similarListings0?.listings || [],
    props.data?.similarListings1?.listings || [],
  );

  useViewTracking({
    eventName: MParticleEventName.ComponentView,
    componentName: 'HomepageRecentlyViewedAndMore',
    cmsComponentId: cmsComponentId,
  }, !loading && totalListings.length >= MIN_LISTINGS);

  if (totalListings.length < MIN_LISTINGS) { return null; }

  return (
    <ListingsCollection
      componentName={COMPONENT_NAME}
      cmsComponentId={cmsComponentId}
      displayAsGrid={displayAsGrid}
      largeTiles={largeTiles}
      listings={totalListings.slice(0, MIN_LISTINGS)}
      loading={loading}
      maxCount={maxCount}
      title={I18n.t('discovery.homepage.recentlyViewedAndMore')}
      jumplinkSlug={jumplinkSlug}
      displayAddToCart={displayAddToCart}
      showFlags={showFlags}
      showShippingDisplay={showShippingDisplay}
    />
  );
}

const withListingsSearchQuery = withGraphql<
  IProps,
  CoreHomepageRecentlyViewedSimilarListingsSearch.Query,
  CoreHomepageRecentlyViewedSimilarListingsSearch.Variables
>(
  gql`query Core_Homepage_RecentlyViewedSimilarListingsSearch(
    $limit: Int
    $recentSearchId0: String
    $recentSearchId1: String
    $skipQuery1: Boolean!
  ) {
    similarListings0: similarListingsSearch(input: {
      limit: $limit
      listingId: $recentSearchId0
      includeForLiveListings: true
    }) {
      listings {
        _id
        ... ListingsCollection
        ... mParticleListingFields
      }
    }

    similarListings1: similarListingsSearch(input: {
      limit: $limit
      listingId: $recentSearchId1
      includeForLiveListings: true
    }) @skip(if: $skipQuery1) {
      listings {
        _id
        ... ListingsCollection
        ... mParticleListingFields
      }
    }
  }
  ${ListingsCollectionClass.fragments.listing}
  ${mParticleListingFields}
  `,
  {
    options: (props: IProps) => ({
      skip: !props.recentlyViewedListings.length,
      ssr: false,
      variables: {
        limit: props.recentlyViewedListings.length === 1 ? 5 : 2, // we want a total of 6 listings
        recentSearchId0: props.recentlyViewedListings[0]?.id,
        recentSearchId1: props.recentlyViewedListings[1]?.id,
        skipQuery1: !props.recentlyViewedListings[1],
      },
    }),
  },
);

export default connect<IExternalProps>([
  withListingsSearchQuery,
])(RecentlyViewedAndMoreListings);
