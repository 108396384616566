import React from 'react';
import classNames from 'classnames';

import { IClientInjectedProps } from '../lib/client_injected_props';
import CoreLink from '../../components/core_link';
import CMSSearchInputGroup from '../../components/cms_search_input_group';
import SanitizedRender from '../../components/sanitized_render';

import SocialLinks, { ISocialLink } from '../../components/social_links';

const SUBTITLE_THRESHOLD = 150;

export interface ICMSPageHeadProps extends IClientInjectedProps {
  title: string;
  subtitle?: string;
  fineprint?: string | JSX.Element;
  imageUrl?: string;
  actionUrl?: string;
  actionText?: string;
  searchPlaceholder?: string;
  socialLinks?: ISocialLink[];
  pageType?: string;
  searchOverride?: Function;
}

export default class CMSCenteredPageHead extends React.Component<ICMSPageHeadProps, {}> {
  getBackgroundImageStyle() {
    if (!this.props.imageUrl) { return null; }

    return {
      style: { backgroundImage: `url('${this.props.imageUrl}')` },
    };
  }

  renderHeadings() {
    if (!this.props.title) return null;

    return (
      <header className="cms-centered-page-head__text">
        <SanitizedRender
          htmlTag="h1"
          className="cms-centered-page-head__title"
          html={this.props.title}
          inlineTagsOnly
        />
        {this.props.subtitle &&
          <SanitizedRender
            htmlTag="h2"
            className="cms-centered-page-head__subtitle"
            html={this.props.subtitle}
            inlineTagsOnly
          />
        }
        {this.props.fineprint &&
          <div className="cms-centered-page-head__fineprint">
            {this.props.fineprint}
          </div>
        }
      </header>
    );
  }

  renderActions() {
    if (!this.props.actionUrl) return null;

    return (
      <div className="cms-centered-page-head__actions">
        <CMSSearchInputGroup
          actionUrl={this.props.actionUrl}
          placeholder={this.props.searchPlaceholder}
          searchOverride={this.props.searchOverride}
        />
        {this.props.actionText &&
          <CoreLink
            className="d-block weight-bold icon-r-arrow-right mt-2"
            to={this.props.actionUrl}
          >
            {this.props.actionText}
          </CoreLink>
        }
        {this.renderSocialLinks()}
      </div>
    );
  }

  renderSocialLinks() {
    if (!this.props.socialLinks) return null;

    return (
      <div className="mt-2 align-center">
        <SocialLinks links={this.props.socialLinks} />
      </div>
    );
  }

  render() {
    const classes = classNames(
      'cms-centered-page-head',
      { 'cms-centered-page-head--no-actions': !this.props.actionUrl },
      { 'cms-centered-page-head--long-description': this.props.subtitle?.length > SUBTITLE_THRESHOLD },
    );

    return (
      <div className={classes}>
        <div
          className="cms-centered-page-head__image"
          {...this.getBackgroundImageStyle()}
        />
        <div className="cms-centered-page-head__wrapper">
          <div className="cms-centered-page-head__content">
            {this.renderHeadings()}
            {this.renderActions()}
          </div>
        </div>
      </div>
    );
  }
}
