// TODO update this to import from commons
// import { gql } from '@reverbdotcom/commons/src/gql';
// eslint-disable-next-line no-restricted-imports
import { gql } from '@apollo/client';
import { withGraphql } from '@reverbdotcom/commons/src/with_graphql';
import {
  UpdateMyShopBillingMethod,
  GetBillingMethod,
} from '@reverbdotcom/commons/src/gql/graphql';

export const myShopBillingMethods = gql`
  fragment myShopBillingMethods on rql_Me {
    _id
    uuid
    shop {
      _id
      id
      currency
      originCountryCode
      shopType
      billingMethodStatus
      paypalSetupStatus
      paypalProfile {
        email
      }
    }
    creditCards {
      id
      last4
      cardType
      expirationYear
      expirationMonth
      cardholderName
      primaryBilling
      address {
        _id
        postalCode
        countryCode
      }
    }
  }
`;

export const GET_BILLING_METHOD = gql`
  query GetBillingMethod {
    countries {
      countries {
        _id
        name
        countryCode
        subregions {
          _id
          name
          code
        }
      }
    }
    me {
      _id
      uuid
      ...myShopBillingMethods
    }
  }
  ${myShopBillingMethods}
`;

export const connectGetBillingMethod = withGraphql<GetBillingMethod.Variables, GetBillingMethod.Query>(
  GET_BILLING_METHOD,
  {
    options: {
      errorPolicy: 'all',
    },
  },
);

export const UPDATE_MY_SHOP_BILLING_METHOD = gql`
  mutation UpdateMyShopBillingMethod($input: Input_core_apimessages_UpdateBillingMethodRequest) {
    updateMyShopBillingMethod(input: $input) {
      errors {
        message
      }
      creditCard {
        id
      }
      adyenPaymentResult {
        action
        paymentStatus
        fingerprintToken
        challengeToken
        paymentData
        threeDsOneData {
          paReq
          md
          termUrl
          redirectUrl
        }
      }
      billingMethodUuid
      me {
        _id
        ...myShopBillingMethods
      }
    }
  }
  ${myShopBillingMethods}
`;

export const connectUpdateMyShopBillingMethod =
  withGraphql<UpdateMyShopBillingMethod.Variables, UpdateMyShopBillingMethod.Mutation>(
    UPDATE_MY_SHOP_BILLING_METHOD,
    {
      name: 'updateMyShopBillingMethod',
      options: {
        ssr: true,
        errorPolicy: 'all',
      },
    },
  );
