import { get } from '../api_request';
import URLHelpers from '../shared/url_helpers';
import { core_apimessages_NegotiationParty } from '@reverbdotcom/commons/src/gql/graphql';

declare const Reverb: any;

interface TemplateResponse {
  dom_id: string;
  template: string;
}

function domIDToReplace(negotiationId: string) {
  return `#negotiation_${negotiationId}`;
}

function decrementDashboardOfferBadges(party: core_apimessages_NegotiationParty) {
  Reverb.Badges.decrementBadge('total_active_offers_badge');
  if (party === core_apimessages_NegotiationParty.BUYER) {
    Reverb.Badges.decrementBadge('buyer_active_offers_badge');
  } else {
    Reverb.Badges.decrementBadge('seller_active_offers_badge');
  }
}

/** Legacy compatibility for existing Rails offer dashboard partial reload */
export async function refreshDashboard(
  negotiationID: string,
  refreshMessage: string,
  party: core_apimessages_NegotiationParty,
) {
  decrementDashboardOfferBadges(party);
  Reverb.UI.spinWithMessage(domIDToReplace(negotiationID), refreshMessage);

  const response: TemplateResponse = await get(URLHelpers.myOffersOfferCard(negotiationID), {}, {}, '');

  Reverb.UI.updateAndHighlight(response.dom_id, response.template);
}
