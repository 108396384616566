import React from 'react';
import I18n from 'i18n-js';
import qs from 'qs';

import SanitizedRender from './sanitized_render';
import { TERMS_OF_USE_PATH, PRIVACY_POLICY_PATH, GDPR_POLICY_PATH } from '../url_helpers';
import CsrfForm from '../csrf_form';
import FormGroupWithCheckbox from './form_group_with_checkbox';
import FormGroupWithInput from './form_group_with_input';
import ThirdPartyAuthButtons from './third_party_auth_buttons';
import GoogleRecaptchaTerms from './google_recaptcha_terms';
import { PasswordInput } from './PasswordInput';
import { GOOGLE_RECAPTCHA_ACTIONS } from '../constants';
import { useExpEnabled, useUser } from '../user_hooks';
import { AuthWrapper } from './AuthWrapper';
import { RecaptchaInput } from './RecaptchaInput';
import { sendLeadFormConversion } from '../google';
import { userHasGivenFullPrivacyConsent } from '../user_privacy_consent_helper';
import experiments from '../experiments';

interface Props {
  redirectTo?: string;
  shouldRenderHeader?: boolean;
  variant: 'default' | 'continue';
  prioritizeOauth?: boolean;
  switchToSignin?: () => void;
}

function GdprExplanation() {
  const translation = I18n.t('commons.signupForm.gdprReverbProcessesData', {
    gdprPolicyLinkOpen: `<a target='_blank' href='${GDPR_POLICY_PATH}'>`,
    linkClose: '</a>',
  });

  return (
    <div className="signup-signin__form__gdpr__explanation">

      <hr className="mtb-2" />

      <h4 className="signup-signin__form__gdpr__explanation__title">
        {I18n.t('commons.signupForm.gdprHowReverbUsesData')}
      </h4>
      <p className="signup-signin__form__gdpr__explanation__subtext">
        <SanitizedRender html={translation} />
      </p>
    </div>
  );
}

function Header() {
  return (
    <h4 className="signup-signin__header">
      {I18n.t('commons.signupForm.signupHeader')}
    </h4>
  );
}

function GdprTerms(props: TermsCheckboxesProps) {
  return (
    <div className="signup-signin__form__gdpr">
      <TermsCheckboxes
        gdpr={props.gdpr}
        agreedToMarketingTerms={props.agreedToMarketingTerms}
        setAgreedToMarketingTerms={props.setAgreedToMarketingTerms}
      />
      <input type="hidden" name="user[terms_type]" value="gdpr" />
    </div>
  );
}

function AcceptTermsLabel(props: { gdpr: boolean }) {
  if (props.gdpr) {
    const gdprTranslation = I18n.t('commons.signupForm.gdprAcceptTermsLabel', {
      termsLinkOpen: `<a href='${TERMS_OF_USE_PATH}'>`,
      privacyLinkOpen: `<a href='${PRIVACY_POLICY_PATH}'>`,
      linkClose: '</a>',
    });
    return <SanitizedRender html={gdprTranslation} />;
  }

  const translation = I18n.t('commons.signupForm.acceptTermsLabel', {
    termsLinkOpen: `<a href='${TERMS_OF_USE_PATH}'>`,
    privacyLinkOpen: `<a href='${PRIVACY_POLICY_PATH}'>`,
    linkClose: '</a>',
  });
  return <SanitizedRender html={translation} />;
}


interface TermsCheckboxesProps {
  gdpr: boolean,
  agreedToMarketingTerms: boolean,
  setAgreedToMarketingTerms: (value) => void,
}

/**
   * Because Enzyme doesn't propagate the onSubmit callback testing
   * that I don't muck with these inputs is tough. According to mdn's
   * docs https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/checkbox
   *
   * A checkbox will send a value of 'on' or undefined. We don't, nor should we
   * coerce these into truthy values. On the Rails side we'll make sure that 'on'
   * and nil represent the falsy and truthy values that they should.
   *
   * So this will come across as `user[accept_terms]=on` in the FormParams
   * or `user[accept_terms]=` if it isn't set (or the value won't be sent at all)
   *
   * Here is the corresponding Rails PR:
   */
function TermsCheckboxes(props: TermsCheckboxesProps) {
  return (
    <div>
      {!props.agreedToMarketingTerms
        && <input type="hidden" name="user[weekly_newsletter]" value="false" className="hidden_weekly_newsletter" />}

      <FormGroupWithCheckbox
        label={I18n.t('commons.signupForm.newsletterLabel')}
        inputName="user[weekly_newsletter]"
        updateField={() => props.setAgreedToMarketingTerms(!props.agreedToMarketingTerms)}
        fieldName="agreedToMarketingTerms"
        checked={props.agreedToMarketingTerms}
      />

      <FormGroupWithCheckbox
        label={<AcceptTermsLabel gdpr={props.gdpr} />}
        inputName="user[accept_terms]"
        required
        tagRequired
      />
    </div>
  );
}

function formAction(redirectTo: string): string {
  const authUrlParams = qs.stringify({
    redirect_to: redirectTo,
  });
  return `/signup?${authUrlParams}`;
}

function emailsMatch(emailValue: string, emailConfirmValue: string) {
  // if the user hasn't entered values in the email fields
  // we don't want to annoy them with errors yet
  if (!emailValue || !emailConfirmValue) return true;
  return emailValue === emailConfirmValue;
}

export function SignupForm(props: Props) {
  const { gdpr } = useUser();

  const [user] = React.useState(useUser());
  const [emailValue, setEmailValue] = React.useState('');
  const [emailConfirmValue, setEmailConfirmValue] = React.useState('');
  const [agreedToMarketingTerms, setAgreedToMarketingTerms] = React.useState(!gdpr);
  const [hasBlurredEmailConfirm, setHasBlurredEmailConfirm] = React.useState(false);

  // only show "emails must match" error on blur of the email confirm field
  // we don't want errors popping up until they've at least attempted to write values
  const showEmailMismatchError = hasBlurredEmailConfirm && !emailsMatch(emailValue, emailConfirmValue);
  const emailsMatchErrorText = showEmailMismatchError ? I18n.t('commons.signupForm.emailMatchError') : null;

  const isLeadFormRolloutOn = useExpEnabled(experiments.GOOGLE_ENHANCED_CONVERSIONS_LEADS_FORMS);

  const onSubmit = () => {
    if (userHasGivenFullPrivacyConsent(user) && isLeadFormRolloutOn) {
      sendLeadFormConversion({ email: emailValue });
    }
  };


  return (
    <AuthWrapper
      header={props.shouldRenderHeader && <Header />}
      oauthButtons={
        <ThirdPartyAuthButtons
          redirectTo={props.redirectTo}
          variant={props.variant === 'continue' ? 'continue' : 'sign-up'}
        />
      }
      authForm={
        <>
          <CsrfForm
            id="reverb-signup-form"
            action={formAction(props.redirectTo)}
            method="POST"
            onSubmit={onSubmit}
            data-disable-auto-validation="true" // block forms.js handling
          >
            <div className="g-container scaling-mb-4">
              <div className="g-col-6">
                <FormGroupWithInput
                  label={I18n.t('commons.signupForm.firstNameLabel')}
                  inputName="user[first_name]"
                  autoComplete="given-name"
                  autoFocus
                  required
                />
              </div>
              <div className="g-col-6">
                <FormGroupWithInput
                  label={I18n.t('commons.signupForm.lastNameLabel')}
                  inputName="user[last_name]"
                  autoComplete="family-name"
                  required
                />
              </div>
            </div>

            <FormGroupWithInput
              label={I18n.t('commons.signupForm.emailLabel')}
              inputType="email"
              inputName="user[email]"
              fieldName="emailValue"
              autoComplete="email"
              value={emailValue}
              updateField={(fieldUpdate) => setEmailValue(fieldUpdate.emailValue)}
              required
            />

            <FormGroupWithInput
              label={I18n.t('commons.signupForm.emailConfirmLabel')}
              inputType="email"
              inputName="emailConfirmValue"
              autoComplete="email"
              errorText={emailsMatchErrorText}
              value={emailConfirmValue}
              updateField={(fieldUpdate) => setEmailConfirmValue(fieldUpdate.emailConfirmValue)}
              onBlur={() => { setHasBlurredEmailConfirm(true); }}
              required
            />

            <PasswordInput
              autoComplete="new-password"
              helpText={I18n.t('commons.signupForm.passwordTip')}
              id="signup--password"
              label={I18n.t('commons.signupForm.passwordLabel')}
              name="user[password]"
              required
            />

            {gdpr &&
              <GdprTerms
                gdpr={gdpr}
                agreedToMarketingTerms={agreedToMarketingTerms}
                setAgreedToMarketingTerms={setAgreedToMarketingTerms}
              />
            }

            <div className="scaling-mtb-4">
              <input
                type="submit"
                value={I18n.t('commons.signupForm.signUp')}
                className="button button--primary width-100"
                name="submit-signup"
                disabled={!emailsMatch(emailValue, emailConfirmValue)}
              />
              <GoogleRecaptchaTerms />
            </div>

            {!gdpr &&
              <div className="mtb-4">
                <TermsCheckboxes
                  gdpr={gdpr}
                  agreedToMarketingTerms={agreedToMarketingTerms}
                  setAgreedToMarketingTerms={setAgreedToMarketingTerms}
                />
              </div>
            }

            <RecaptchaInput
              formId="reverb-signup-form"
              action={GOOGLE_RECAPTCHA_ACTIONS.SIGNUP}
            />

          </CsrfForm>
          {gdpr &&
            <div className="mb-4">
              <GdprExplanation />
            </div>
          }
          {props.prioritizeOauth &&
            <p className="size-100 mt-4 align-center">
              {I18n.t('commons.signupForm.alreadyHaveAccount')}
              {' '}
              <button
                onClick={props.switchToSignin}
                className="button-as-link"
                type="button"
              >
                {I18n.t('commons.signupForm.signIn')}
              </button>
            </p>
          }
        </>
      }
      prioritizeOauth={props.prioritizeOauth}
    />
  );
}

export default SignupForm;
