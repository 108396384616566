import React from 'react';
import I18n from 'i18n-js';
import FormPrimaryActionButton from './form_primary_action_button';

export default function OnboardingFormSaveButton({ isSaving, isDisabled, ...props }: any) {
  const disabled = isSaving || isDisabled;

  const buttonText = isSaving ? // show 'save' when disabled
    I18n.t('discovery.coreOnboarding.processing') :
    I18n.t('discovery.coreOnboarding.save');

  return (
    <FormPrimaryActionButton
      disabled={disabled}
      buttonText={buttonText}
      {...props}
    />
  );
}
