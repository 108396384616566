import { ChildProps } from '@apollo/client/react/hoc';
// TODO update this to import from commons/src/gql
// eslint-disable-next-line no-restricted-imports
import { gql } from '@apollo/client';
import React from 'react';
import { ImageAdCreativeFragment, AdFragment } from './ad_fragments';
import { CoreAdImageFeature, core_apimessages_AdFormat } from '../../gql/graphql';
import { AdImageFeature } from './ad_image_feature';
import { withGraphql } from '../../with_graphql';

interface ExternalProps {
  categoryUuids: string[];
}
type Props = ChildProps<ExternalProps, CoreAdImageFeature.Query>;

const AD_ZONE = 'category-feature';

const query = gql`
  query Core_Ad_Image_Feature(
    $adServeInput: Input_core_apimessages_AdServeRequest
  ) {
    adServe(input: $adServeInput) {
      placements {
        _id
        adZones {
          slug
        }
        ad {
          ...AdData
          imageAdCreative {
            ...ImageAdCreativeData
          }
        }
      }
    }
  }
  ${AdFragment}
  ${ImageAdCreativeFragment}
`;

const bannerQuery = withGraphql<Props, CoreAdImageFeature.Query, CoreAdImageFeature.Variables>(query,
  {
    options: (ownProps) => {
      return {
        ssr: true,
        variables: {
          adServeInput: {
            targeting: {
              categories: ownProps.categoryUuids,
            },
            placements: [{ adZones: [{ slug: AD_ZONE }], adTypes: [{ format: core_apimessages_AdFormat.IMAGE_AD }] }],
          },
        },
      };
    },
  },
);

export function AdFeatureContainer(props: Props) {
  const hasData = (!props.data?.loading && !!props.data?.adServe?.placements[0]?.ad?.imageAdCreative);

  if (!hasData) {
    return null;
  }

  return (
    <AdImageFeature
      ad={props.data?.adServe?.placements[0]?.ad}
      adZones={props.data?.adServe?.placements[0]?.adZones}
    />
  );
}

export default (bannerQuery(AdFeatureContainer));
