import qs from 'qs';
import UrlParse from 'url-parse';
import HashUtil from './hash_util';

const QueryParamsUtil = {
  queryParamsForUrl(hash): string {
    const updatedHash = HashUtil.rejectBlankValues(hash);
    return `?${qs.stringify(updatedHash)}`;
  },

  getParamValue(key, url?): string {
    const href = url || window.location.href;
    const queryString = new UrlParse(href).query.replace(/^\?/, '');
    return (qs.parse(queryString)[key] as string) || null;
  },
};

export default QueryParamsUtil;
