import React from 'react';
import I18n from 'i18n-js';

import Tiles from '../../components/tiles';
import CoreLink from '../../components/core_link';
import OverflowingRow from '../../components/overflowing_row';
import { IDynamicComponentProps } from '../dynamic_component_props';
import { MParticleEventName } from '../../elog/mparticle_types';
import { imageUploadURL } from '../../url_helpers';
import { OverflowingRowAction } from '../../components/overflowing_row_action';

function clickEvent(trait) {
  return {
    componentName: 'TraitCollection',
    eventName: MParticleEventName.ClickedTrait,
    trait: trait.traitName,
    targetUrl: trait.linkOverride,
  };
}

function TraitTile(trait, idx) {
  const tileClass = 'trait-collection__item';

  return (
    <div className="trait-collection__item--outer" key={idx}>
      <CoreLink
        to={trait.linkOverride}
        className={tileClass}
        style={{ backgroundImage: `url(${imageUploadURL(trait.imageOverride)})` }}
        key={trait.traitName}
        clickEvent={clickEvent(trait)}
      />
      <div>
        <CoreLink
          to={trait.linkOverride}
          className="trait-collection__item-text"
          key={trait.traitName}
          clickEvent={clickEvent(trait)}
        >
          {trait.traitName}
        </CoreLink>
      </div>
    </div>
  );
}


interface IProps extends IDynamicComponentProps {
  title?: string;
  ctaText?: string;
  ctaTargetHref?: string;
  ledeHtml?: string;
  traits?: any[];
}

export function TraitCollection(props: IProps) {
  function renderAction() {
    if (!props.ctaTargetHref) return null;

    return (
      <OverflowingRowAction
        href={props.ctaTargetHref}
        text={props.ctaText || I18n.t('cms.listingsCollection.seeMore')}
      />
    );
  }

  return (
    <OverflowingRow
      title={props.title}
      ledeHtml={props.ledeHtml}
      collectionCount={props.traits.length}
      action={renderAction()}
    >
      <Tiles
        largeTiles
        singleRow
      >
        {props.traits.map((trait, idx) => TraitTile(trait, idx))}
      </Tiles>
    </OverflowingRow>
  );
}
