import React from 'react';
import I18n from 'i18n-js';
import { includes } from 'lodash';
import SanitizedRender from '@reverbdotcom/commons/src/components/sanitized_render';
import { RCTooltip } from '@reverbdotcom/cadence/components';
import { core_apimessages_Money } from '@reverbdotcom/commons/src/gql/graphql';
import { IRefundType, IRefundableOrderProps, IRefundRequestProps } from './refund_request';
import { initTips } from '../legacy/init/powertip';

const UNSUPPORTED_FA_ICONS = ['AUD', 'CAD'];

interface IProps {
  refundType: IRefundType;
  order: IRefundableOrderProps;
  amount: string;
  onAmountChange: (event: any) => void;
  exchangedAmount?: core_apimessages_Money;
  refundRequest: IRefundRequestProps;
}

const PartialRefundAmount: React.StatelessComponent<IProps> = (props) => {
  function renderCurrencySymbol(currency: string) {
    const currencyForIcon = includes(UNSUPPORTED_FA_ICONS, currency) ? 'usd' : currency;
    const iconClasses = `fa fa-${currencyForIcon.toLowerCase()}`;

    return (
      <span
        className="input-group__text"
        data-price-with-currency-symbol
      >
        <span className={iconClasses} />
      </span>
    );
  }

  function renderExchangedAmount() {
    if (!props.exchangedAmount) {
      return null;
    }

    return (
      <div className="currency-exchange-approximation mb-2">
        <div className="panel strong">
          <SanitizedRender
            className="mb-0"
            htmlTag="p"
            html={
              I18n.t('discovery.dashboard.refundRequests.exchangedAmount', {
                amount: props.exchangedAmount.display,
                settlementCurrency: props.order.settlementCurrency,
                spanOpen: '<span class="price-currency">',
                spanClose: '</span>',
              })
            }
          />
          <p
            className="mb-0 normal tight-line-height"
            ref={node => initTips(node)}
          >
            {
              I18n.t('discovery.dashboard.refundRequests.exchangedAmountHint', {
                displayCurrency: props.order.displayCurrency,
              })
            }
            <RCTooltip
              text={
                I18n.t('discovery.dashboard.refundRequests.exchangedAmountTooltip', {
                  settlementCurrency: props.order.settlementCurrency,
                  displayCurrency: props.order.displayCurrency,
                  lineBreak: '<br/>',
                })
              }
            />
          </p>
        </div>
      </div>
    );
  }

  if (props.refundType === IRefundType.FULL && !props.refundRequest.isPersisted) {
    return null;
  }

  return (
    <div>
      <div className="g-container scaling-mb-4">
        <div className="g-col-4 g-col-mobile-12">
          <div className="form-group">
            <div className="form-group__header">
              <div className="form-group__label-text">
                <label htmlFor="order_refund_request_amount">
                  {I18n.t('discovery.dashboard.refundRequests.amount')}
                </label>
              </div>
              <div className="form-group__tag">
                {I18n.t('discovery.dashboard.refundRequests.required')}
              </div>
            </div>
            <div className="form-group__fields">
              <div className="input-group">
                {renderCurrencySymbol(props.order.displayCurrency)}
                <input
                  type="text"
                  pattern="[0-9.,\s]*"
                  onChange={props.onAmountChange}
                  name="order_refund_request[amount]"
                  id="order_refund_request_amount"
                  value={props.amount}
                  required
                  className="input-group__input"
                />
                <span className="input-group__text">
                  {props.order.displayCurrency}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      {renderExchangedAmount()}
    </div>
  );
};

export default PartialRefundAmount;
