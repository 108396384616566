import React from 'react';
import {
  core_apimessages_Money as Money,
  core_apimessages_CheckoutPaymentMethod_Type as CheckoutPaymentMethodType,
} from '@reverbdotcom/commons/src/gql/graphql';
import { useApplePayAvailabilityByConfig } from './useApplePayAvailability';
import { CartHoistedExpressPayOptions } from './cart/CartHoistedExpressPayOptions';
import { findMerchantId, merchantName } from './checkoutApplePay';
import { ApplePayConfig } from './useCartApplePay';
import { PaypalCheckoutEligibilityAttributes, useExpressPayPalAvailability, usePayPalPayLaterButtonAvailability } from './useExpressPayPal';

interface Props {
  paymentMethodType: string;
  checkoutBundlingId: string;
  amountTotal: Money;
  adyenCheckoutPaymentMethodsConfig: string;
  expressPaypalCurrency: string;
  shopCountryCode: string;
  paypalCheckoutEligibilityAttributes: PaypalCheckoutEligibilityAttributes;
}

export default function CartHoistedExpressPayWrapper({
  paymentMethodType,
  checkoutBundlingId,
  amountTotal,
  adyenCheckoutPaymentMethodsConfig,
  expressPaypalCurrency,
  paypalCheckoutEligibilityAttributes,
  shopCountryCode,
}: Props) {
  const [isApplePayAvailable] = useApplePayAvailabilityByConfig(adyenCheckoutPaymentMethodsConfig);
  const showApplePayButton = isApplePayAvailable && paymentMethodType == CheckoutPaymentMethodType.DIRECT_CHECKOUT;

  const showPayPalButton = useExpressPayPalAvailability({ paypalCheckoutEligibilityAttributes });
  const isPaypalPayLaterAvailable = usePayPalPayLaterButtonAvailability({
    currency: amountTotal.currency,
    amountCents: amountTotal.amountCents,
    shopCountryCode,
  });

  const applePayConfig: ApplePayConfig = {
    merchantId: findMerchantId(JSON.parse(adyenCheckoutPaymentMethodsConfig)),
    merchantName: merchantName(),
  };

  return (
    <CartHoistedExpressPayOptions
      checkoutBundlingId={checkoutBundlingId}
      amountTotal={amountTotal}
      currency={expressPaypalCurrency}
      showApplePayButton={showApplePayButton}
      applePayConfig={applePayConfig}
      showPayPalButton={showPayPalButton}
      showPaypalPayLaterButton={isPaypalPayLaterAvailable}
    />
  );
}
