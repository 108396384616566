import React from 'react';
import classNames from 'classnames';
import I18n from 'i18n-js';
import { get } from 'lodash';

import CoreLink from '../../components/core_link';

import { CSP } from '../../gql/graphql';
import SanitizedRender from '../../components/sanitized_render';
import { imageUploadURL, prefilledListingFromCSP } from '../../url_helpers';
import { buildCSPClick } from '../../elog/mparticle_tracker';

import {
  findLowestPrice,
  BrandMetaData,
  NameMetaData,
  PriceMetaData,
  DescriptionMetaData,
  ImageMetaData,
} from '../lib/metadata_helper';
import { IUserContext, withUserContext } from '../../components/user_context_provider';
import { preferLightTheme } from '@reverbdotcom/cadence/components/helpers/themeOverrides';

const COMPONENT_NAME = 'CspFeatureCard';

export interface IProps extends IUserContext {
  csp?: CSP;
  description?: string;
  splitPrices?: boolean;
  hidePrices?: boolean;
  squareImage?: boolean;
  imageOverride?: string;
  useCspSummary?: boolean;
  position?: number;
  shouldLinkToSellForm?: boolean;
  trackingName?: string;
}

export class CspFeatureCard extends React.Component<IProps, null> {
  getBackgroundStyle() {
    const img = this.props.imageOverride ?
      imageUploadURL(this.props.imageOverride) :
      this.props.csp.image.source;

    return { backgroundImage: `url(${img})` };
  }

  getLowestPrice() {
    return findLowestPrice(this.props.csp)?.display;
  }

  getUsedPrice() {
    return get(this.props.csp, 'inventory.usedLowPrice.display', null);
  }

  getNewPrice() {
    return get(this.props.csp, 'inventory.newLowPrice.display', null);
  }

  trackingName() {
    return this.props.trackingName ?? COMPONENT_NAME;
  }

  destinationPath() {
    if (!this.props.csp) { return null; }

    if (this.props.shouldLinkToSellForm) {
      return prefilledListingFromCSP(this.props.csp.id);
    }

    return this.props.csp.webLink.path;
  }

  buttonText() {
    if (this.props.shouldLinkToSellForm) {
      return I18n.t('cms.cspFeature.sellYours');
    }

    return I18n.t('cms.cspFeature.shopNow');
  }

  priceTranslationKey(format) {
    if (this.props.shouldLinkToSellForm) {
      return `cms.cspFeature.pricing.selling.${format}`;
    }

    return `cms.cspFeature.pricing.buying.${format}`;
  }

  renderPlaceholder() {
    const classes = classNames(
      'csp-feature-card',
      'csp-feature-card--placeholder',
      { 'csp-feature-card--square-image': this.props.squareImage },
    );

    return (
      <article
        className={classes}
      >
        <div
          className="csp-feature-card__image-area"
        >
          <div className="csp-feature-card__price">
            <div className="csp-feature-card__price__inner" />
          </div>
        </div>
        <div className="csp-feature-card__text" />
      </article>
    );

  }

  renderPrice() {
    if (this.props.hidePrices) { return null; }
    if (!this.getNewPrice() && !this.getUsedPrice()) { return null; }

    const tags = {
      tagOpen: '<b>',
      tagClose: '</b>',
    };

    if (!this.props.splitPrices) {
      return (
        <div className="csp-feature-card__price">
          <SanitizedRender
            className="csp-feature-card__price__inner"
            html={I18n.t(
              this.priceTranslationKey('priceFromWithTags'),
              { price: this.getLowestPrice(), ...tags },
            )}
          />
        </div>
      );
    }

    return (
      <div className="csp-feature-card__price">
        <div className="csp-feature-card__price__inner">
          {this.getNewPrice() &&
            <SanitizedRender
              className="csp-feature-card__split-price"
              htmlTag="div"
              html={I18n.t(
                this.priceTranslationKey('newPriceFromWithTags'),
                { price: this.getNewPrice(), ...tags },
              )}
            />
          }
          {this.getUsedPrice() &&
            <SanitizedRender
              className="csp-feature-card__split-price"
              htmlTag="div"
              html={I18n.t(
                this.priceTranslationKey('usedPriceFromWithTags'),
                { price: this.getUsedPrice(), ...tags },
              )}
            />
          }
        </div>
      </div>
    );
  }

  renderCSPSummary(description) {
    const summaryFallback = this.props.useCspSummary && this.props.csp.summary;
    const summary = description || summaryFallback;
    if (!summary) return;

    return (
      <SanitizedRender
        htmlTag="div"
        className="csp-feature-card__description"
        html={summary}
      />
    );
  }

  clickEvent() {
    return {
      ...buildCSPClick(this.props.csp),
      displayStyle: this.trackingName(),
      position: this.props.position,
    };
  }

  render() {
    const { csp, description } = this.props;
    const classes = classNames(
      'csp-feature-card',
      { 'csp-feature-card--square-image': this.props.squareImage },
    );

    if (!csp) return this.renderPlaceholder();

    return (
      <article
        className={classes}
      >
        <CoreLink
          className="csp-feature-card__image-area"
          to={this.destinationPath()}
          style={this.getBackgroundStyle()}
          clickEvent={this.clickEvent()}
          {...preferLightTheme()}
        >
          {this.renderPrice()}
          <BrandMetaData csp={this.props.csp} />
          <NameMetaData csp={this.props.csp} />
          <DescriptionMetaData csp={this.props.csp} />
          <ImageMetaData csp={this.props.csp} />
          <PriceMetaData
            csp={this.props.csp}
          />
        </CoreLink>
        <div className="csp-feature-card__text">
          <h3 className="csp-feature-card__title">
            <CoreLink
              to={this.destinationPath()}
              clickEvent={this.clickEvent()}
            >
              {csp.title}
            </CoreLink>
          </h3>
          {this.renderCSPSummary(description)}
          <CoreLink
            className="csp-feature-card__shop-link"
            to={this.destinationPath()}
            clickEvent={this.clickEvent()}
          >
            {this.buttonText()}
          </CoreLink>
        </div>
      </article>
    );
  }
}

export default withUserContext(CspFeatureCard);
