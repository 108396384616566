import I18n from 'i18n-js';
import React from 'react';

import SiteSearch from './site_search';
import ReverbSiteSearchSuggestions from './reverb_site_search_suggestions';
import ReverbSiteSearchController from './reverb_site_search_controller';
import { IUrl } from '../../url_context';

export interface ISiteBanner {
  saleUrl: string;
  heading: string;
}

interface IProps {
  defaultValue: string;
  siteBanner?: ISiteBanner;
  placeholder?: string;
  rounded?: boolean;
  url?: IUrl;
}

export default class ReverbSiteSearch extends React.Component<IProps> {
  render() {
    return (
      <SiteSearch
        fetchSuggestions={ReverbSiteSearchSuggestions.fetch}
        submitQuery={ReverbSiteSearchController.submitQuery}
        selectSuggestion={ReverbSiteSearchController.selectSuggestion}
        defaultValue={this.props.defaultValue}
        siteBanner={this.props.siteBanner}
        placeholderText={this.props.placeholder || I18n.t('commons.siteSearch.placeholder')}
        rounded={this.props.rounded}
        url={this.props.url}
      />
    );
  }
}
