import React from 'react';
import { RCCheckbox, RCSelect, RCTextInput } from '@reverbdotcom/cadence/components';
import I18n from 'i18n-js';

interface IProps {
  categoryToReasonMap: object;
  categoryToPolicyContextMap?: object;
  categoryToCreditMethodMap?: object;
  formData: IAccommodationDataFields;
  setFormField: (fieldName: string, value: any) => void;
  categoryLabel?: string;
}

export interface IAccommodationDataFields {
  selectedCategory: string;
  selectedReason: string;
  additionalContext: string;
  selectedPolicyContexts?: object;
  selectedCreditMethod?: string;
}

export function AdminAccommodationDataFields({
  categoryToReasonMap,
  categoryToPolicyContextMap,
  categoryToCreditMethodMap = null,
  formData,
  setFormField,
  categoryLabel = 'Category',
}: IProps) {

  const handleCategoryChange = (event) => {
    setFormField('selectedCategory', event.target.value);
    setFormField('selectedReason', '');
    setFormField('additionalContext', '');
    setFormField('selectedPolicyContexts', {});
    setFormField('selectedCreditMethod', '');
  };

  const handleReasonChange = (event) => {
    setFormField('selectedReason', event.target.value);
  };

  const handleAdditionalContextChange = (event) => {
    setFormField('additionalContext', event.target.value);
  };

  const handlePolicyContextChange = (event) => {
    setFormField(
      'selectedPolicyContexts',
      {
        ...formData.selectedPolicyContexts,
        [event.target.id]: !formData.selectedPolicyContexts[event.target.id],
      },
    );
  };

  const handleCreditMethodChange = (event) => {
    setFormField('selectedCreditMethod', event.target.value);
  };

  const isOfficialAccommodation = () => {
    return categoryToReasonMap[formData.selectedCategory]?.length > 0;
  };

  return (
    <>
      <RCSelect
        addEmptyOption
        id="category"
        label={categoryLabel}
        name="category"
        onChange={handleCategoryChange}
        required
        tag="required"
        value={formData.selectedCategory}
      >
        {Object.keys(categoryToReasonMap).map(option => (
          <RCSelect.Option
            key={option}
            label={I18n.t(`commons.accommodations.categories.${option}`)}
            value={option}
          />
        ))}
      </RCSelect>
      {isOfficialAccommodation() && (
        <RCSelect
          addEmptyOption
          emptyOptionLabel={formData.selectedCategory ? 'Select one...' : 'Select a category first...'}
          id="accommodation_reason"
          label="Accommodation Reason"
          name="accommodation_reason"
          onChange={handleReasonChange}
          required
          tag="required"
          value={formData.selectedReason}
        >
          {categoryToReasonMap[formData.selectedCategory].map(option => (
            <RCSelect.Option
              key={option}
              label={I18n.t(`commons.accommodations.reasons.${option}`)}
              value={option}
            />
          ))}
        </RCSelect>
      )}
      {categoryToPolicyContextMap?.[formData.selectedCategory]?.length > 0 && (
        <div className="mb-5">
          <div className="weight-bold size-fixed-90">Policy Contexts</div>
          <div className="size-fixed-90 color-secondary mb-2">Please select all that apply, if any.</div>
          {categoryToPolicyContextMap[formData.selectedCategory].map(policyContext => (
            <RCCheckbox
              key={policyContext}
              label={I18n.t(`commons.accommodations.policyContexts.${policyContext}`)}
              id={policyContext}
              name={`policy_contexts[${policyContext}]`}
              checked={formData.selectedPolicyContexts[policyContext] || false}
              onChange={handlePolicyContextChange}
            />
          ))}
        </div>
      )}
      {isOfficialAccommodation() && (
        <RCTextInput
          id="additional_context"
          label="Additional Context"
          name="additional_context"
          onChange={handleAdditionalContextChange}
          required
          tag="required"
          value={formData.additionalContext}
        />
      )}
      {categoryToCreditMethodMap && (
        <RCSelect
          addEmptyOption
          emptyOptionLabel={formData.selectedCategory ? 'Select one...' : 'Select a category first...'}
          id="credit_method"
          label="Credit Method"
          name="credit_method"
          onChange={handleCreditMethodChange}
          tag="required"
          required
          value={formData.selectedCreditMethod}
        >
          {(categoryToCreditMethodMap[formData.selectedCategory] || []).map(option => (
            <RCSelect.Option
              key={option}
              label={option}
              value={option}
            />
          ))}
        </RCSelect>
      )}
    </>
  );
}

export default AdminAccommodationDataFields;
