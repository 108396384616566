export default {
  form: {
    cancel: 'Cancel',
    label: 'To ensure accuracy, consider the changes below:',
    recommended: 'Recommended',
    save: 'Continue with this address',
    whatYouEntered: 'What you entered',
  },
  modal: {
    header: 'We found a better address',
  },
};
