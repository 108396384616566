import React from 'react';
import I18n from 'i18n-js';
import classNames from 'classnames';

import ControlledCheckbox from './controlled_checkbox';

interface IProps {
  inputName: string;
  inputTitleValue?: string; // This is the title attribute for the input, not a label
  label: string | JSX.Element | React.ReactNode;

  checked?: boolean;
  updateField?: Function;
  fieldName?: string;

  id?: string;
  required?: boolean;
  disabled?: boolean;
  tagOptional?: boolean;
  tagRecommended?: boolean;
  tagRequired?: boolean;
  errorText?: string;
  helpText?: string | JSX.Element | React.ReactNode;

  defaultChecked?: boolean;
  display?: 'default' | 'switch' | 'indentedSwitch';
}

export default class FormGroupWithCheckbox extends React.Component<IProps, null> {
  shouldBeControlled() {
    return !!this.props.updateField;
  }

  getTagText() {
    if (this.props.tagOptional) {
      return I18n.t('commons.formGroup.optional');
    }

    if (this.props.tagRecommended) {
      return I18n.t('commons.formGroup.recommended');
    }

    if (this.props.tagRequired) {
      return I18n.t('commons.formGroup.required');
    }
  }

  renderTag() {
    if (!this.getTagText()) return;

    return (
      <div className="d-inline-block">
        <div className="form-group__tag">
          {this.getTagText()}
        </div>
      </div>
    );
  }

  renderError() {
    if (!this.props.errorText) return;

    if (this.props.display === 'switch') {
      return (
        <div className="switch__error">
          {this.props.errorText}
        </div>
      );
    }

    return (
      <div className="form-group__error">
        {this.props.errorText}
      </div>
    );
  }

  renderUncontrolledCheckbox() {
    return (
      <input
        type="checkbox"
        name={this.props.inputName}
        id={this.props.id || this.props.inputName}
        required={this.props.required}
        disabled={this.props.disabled}
        defaultChecked={this.props.defaultChecked}
        title={this.props.inputTitleValue}
      />
    );
  }

  renderControlledCheckbox() {
    return (
      <ControlledCheckbox
        inputName={this.props.inputName}
        checked={this.props.checked}
        updateField={this.props.updateField}
        fieldName={this.props.fieldName || this.props.inputName}
        required={this.props.required}
        disabled={this.props.disabled}
        defaultChecked={this.props.defaultChecked}
        id={this.props.id}
        inputTitleValue={this.props.inputTitleValue}
      />
    );
  }

  renderSwitch() {
    const classes = classNames(
      'switch',
      { 'switch--indented': this.props.display === 'indentedSwitch' },
    );

    return (
      <div className={classes}>
        { this.shouldBeControlled() && this.renderControlledCheckbox() }
        { !this.shouldBeControlled() && this.renderUncontrolledCheckbox() }
        <label
          htmlFor={this.props.id || this.props.inputName}
        >
          {this.props.label}
          {this.renderTag()}
          {this.renderError()}
        </label>
        {!!this.props.helpText &&
          <div className="switch__help-text">
            {this.props.helpText}
          </div>
        }
      </div>
    );
  }

  render() {
    const classes = classNames(
      'form-group',
      'form-group--with-checkbox',
      { 'form-group--with-error': !!this.props.errorText },
    );

    if (
      this.props.display === 'switch' ||
      this.props.display === 'indentedSwitch'
    ) return this.renderSwitch();

    return (
      <div className={classes}>
        <div className="label-with-checkbox">
          { this.shouldBeControlled() && this.renderControlledCheckbox() }
          { !this.shouldBeControlled() && this.renderUncontrolledCheckbox() }
          <label
            htmlFor={this.props.id || this.props.inputName}
          >
            <div>
              <div className="form-group__label-text">
                {this.props.label}
              </div>
              {this.renderTag()}
              {this.renderError()}
            </div>
          </label>
        </div>
        {this.props.children}
        {!!this.props.helpText &&
          <div className="form-group__help-text">
            {this.props.helpText}
          </div>
        }
      </div>
    );
  }
}
