import React, { Dispatch, useReducer } from 'react';
import SellerListingCardContext from './SellerListingCardContext';
import SellerListingCard from './SellerListingCard';
import { Listing } from '@reverbdotcom/commons/src/gql/graphql';
import { RCToast } from '@reverbdotcom/cadence/components';
import listingCardReducer, { ICardState } from './sellerListingCardReducer';

interface IExternalProps {
  listing: Listing;
}
interface IListingUpdatedToastProps {
  cardState: ICardState;
  dispatch: Dispatch<any>;
}

function ListingUpdatedToast({ cardState, dispatch }: IListingUpdatedToastProps) {
  return <RCToast
    delayInterval="short"
    onClose={() => {
      dispatch({
        type: 'hideToast',
      });
    }}
    text={cardState.toast?.message}
    theme={cardState.toast?.isSuccess ? 'success' : 'error'}
    isOpen={cardState.toast?.isOpen}
  />;
}

function SellerListingCardContainer({
  listing,
}: IExternalProps) {
  const initialCardState = {
    toast: {
      isOpen: false,
      message: '',
      isSuccess: true,
    },
  };

  const [cardState, dispatch] = useReducer(
    listingCardReducer,
    initialCardState,
  );

  return (
    <>
      <SellerListingCardContext.Provider
        value={{
          cardState,
          dispatch,
        }}
      >
        <SellerListingCard
          listing={listing}
        />
      </SellerListingCardContext.Provider>
      <ListingUpdatedToast
        cardState={cardState}
        dispatch={dispatch}
      />
    </>
  );
}

export default SellerListingCardContainer;
