/* eslint-disable */
export default {
  home: "Home",
  flash: {
    error: {
      expiredAuthError: "Your session has expired, <a href='javascript:window.location.reload();'>reload?</a>",
    },
  },
  addToCart: 'Add to Cart',
  addToCartButton: {
    addToCart: 'Add to Cart',
    preorder: 'Preorder',
    preorderEstimatedShipDate: 'Estimated ship date: {{date}}'
  },
  accommodations: {
    categories: {
      bump: 'Bump',
      bump_credit: 'Bump Credit',
      bump_for_new: 'Bump For New',
      bump_for_new_credit: 'Bump For New Credit',
      sale_fee: 'Sale Fee',
      sale_fee_credit: 'Sale Fee Credit',
      shipping_label: 'Shipping Label',
      shipping_label_credit: 'Shipping Label Credit',
      app_store: 'App Store',
      app_store_credit: 'App Store Credit',
      payout_write_off_credit: 'Payout Write Off Credit',
      zero_financing_eligibility: 'Zero Financing Eligibility',
      zero_financing_eligibility_credit: 'Zero Financing Eligibility Credit',
      cme: 'CME',
      cme_credit: 'CME Credit',
      excessive_seller_refund_fee: 'Excessive Seller Refund Fee',
      excessive_seller_refund_fee_credit: 'Excessive Seller Refund Fee Credit',
      seller_remitted_tax_credit: 'Seller Remitted Tax Credit',
      payment_credit: 'Payment Credit',
      accommodation_credit: 'Customer Service Accommodation',
      accommodation_credit_removal: 'Customer Service Accommodation Removal',
      safe_shipping_claim_credit: 'Safe Shipping Claim',
      safe_shipping_claim_credit_removal: 'Safe Shipping Claim Removal',
      dispute_accommodation_credit: 'Dispute Accommodation',
      dispute_accommodation_credit_removal: 'Dispute Accomodation Removal',
      warehouse_service_expense: 'Warehouse Service Expense',
      warehouse_service_expense_credit: 'Warehouse Service Expense Credit',
      customer_service_accommodation: 'Customer Service Accommodation',
      customer_service_accommodation_removal: 'Customer Service Accommodation Removal',
      dispute_accommodation: 'Dispute Accommodation',
      dispute_accommodation_removal: 'Dispute Accomodation Removal',
      safe_shipping_claim: 'Safe Shipping Claim',
      safe_shipping_claim_removal: 'Safe Shipping Claim Removal',
      financial_adjustment: 'Financial Adjustment',
      financial_adjustment_removal: 'Financial Adjustment Removal',
      promotional_gift: 'Promotional Gift',
      promotional_gift_removal: 'Promotional Gift Removal',
      reverb_gives: 'Reverb Gives',
      reverb_gives_removal: 'Reverb Gives Removal',
    },
    reasons: {
      bump_accommodation: 'Bump Accommodation',
      currency_conversion: 'Currency Conversion',
      damage_return_to_seller: 'Damage Return To Seller',
      damaged_in_transit: 'Damaged in Transit',
      delivered_not_received: 'Delivered Not Received',
      general_comped_label: 'General Comped Label',
      import_duties: 'Import Duties',
      lost_and_found_or_return_to_sender: 'Lost and Found or Return to Sender',
      lost_in_transit: 'Lost in Transit',
      manual_refund: 'Manual Refund',
      marketing_promo_accommodation: 'Marketing Promo Accommodation',
      marketplace_integrity_team_accommodation: 'Marketplace Integrity Team Accommodation',
      not_as_described: 'Not as Described',
      outreach_team_accommodation: 'Outreach Team Accommodation',
      payout_accommodation: 'Payout Accommodation',
      payout_currency_swap: 'Payout Currency Swap',
      paypal_to_paypal_fraud_accommodation: 'Paypal to Paypal Fraud Accommodation',
      poor_experience_caused_by_reverb: 'Poor Experience Caused by Reverb',
      poor_experience_caused_by_user: 'Poor Experience Caused by User',
      preference_return_to_seller: 'Preference Return to Seller',
      return_to_reverb_restock: 'Return to Reverb Restock',
      returned_payout: 'Returned Payout',
      reverb_payments_fraud_accommodation: 'Reverb Payments Fraud Accommodation',
      reverb_warehouse_reship: 'Reverb Warehouse Reship',
      shipping_costs: 'Shipping Costs',
      shipping_overage_accommodation: 'Shipping Overage Accommodation',
    },
    policyContexts: {
      buyer_ineligible_for_reverb_protection: 'Buyer not eligible for Reverb Protection',
      buyer_safe_shipping_noncompliance: 'Buyer unwilling to follow Safe Shipping process',
      policy_gray_area: 'Policy Gray Area',
      poor_buying_practices: 'Poor Buying Practices',
      poor_packaging: 'Poor Packaging',
      poor_selling_practices: 'Poor Selling Practices',
      seller_safe_shipping_noncompliance: 'Seller unwilling to follow Safe Shipping process',
    },
  },
  alertBox: {
    dismiss: 'Dismiss'
  },
  RCGuidePopover: {
    gotIt: 'Got It',
  },
  close: 'Close',
  listingRow: {
    similarListings: {
      au: 'Similar Gear from Sellers Shipping to Australia',
      br: 'Similar Gear from Sellers in Brazil',
      ca: 'Similar Gear from Sellers in Canada',
      eu: 'Similar Gear from Sellers in the EU',
      no: 'Similar Gear from Sellers in Norway',
      uk: 'Similar Gear from UK Sellers',
      default: 'Similar Listings',
    }
  },
  bump: {
    dsaCompliancePopover: {
      title: 'including Reverb Bump',
      description: 'Sellers looking to grow their business can promote their listings using Reverb Bump. These listings are shown to you based on a number of factors like relevancy and the rate sellers set. %{linkOpen}Learn more%{linkClose}.',
      learnMore: 'Learn More',
    },
  },
  shipping: 'Shipping',
  shippingTo: 'Shipping to',
  expeditedShippingTo: '2-Day Shipping to',
  freeShipping: 'Free Shipping',
  instantDownload: 'Instant Download',
  acceptsOffers: 'Accepts Offers',
  localPickupOnly: 'Local Pickup Only',
  plusShipping: '+ Shipping',
  plusShippingPrice: '+ {{displayPrice}} Shipping',
  plusFreeExpedited: '+ Free 2-Day Shipping',
  estimatedMonthlyPaymentCallout: 'As low as {{displayPrice}}/mo',
  expeditedShippingAvailable: '2-Day Shipping available',
  freeExpeditedShipping: 'Free 2-Day Shipping',
  freeExpeditedShippingInfo: "Ships within one business day via a 2-day delivery service. Not available with offers or 0% financing",
  whenYouCombineShipping: 'shipping when combined',
  whenYouCombineShippingLabel: '{{displayPrice}} shipping when combined',
  howToCombinedShippingTooltip: 'Discount applied when purchased with another item from this shop that has a greater shipping cost (or if you buy multiple)',
  freeBecauseThreshold: 'Free because you met this shop’s free shipping threshold!',
  calculateShipping: 'Calculate shipping',
  calculateShippingChange: 'Change',
  unavailableTo: 'Shipping unavailable to',
  collapsingFacets: {
    collapse: 'Collapse',
    showMore: 'Show More',
  },
  collapsingList: {
    collapse: 'Collapse',
    showMore: 'Show More',
  },
  free: 'Free',
  priceDisplayRangeSeparator: ' - ',
  ribbonView: {
    bidNow: 'Bid Now',
  },
  formGroup: {
    optional: 'Optional',
    recommended: 'Recommended',
    required: 'Required',
    requiredError: 'This field is required'
  },
  lightboxImage: {
    closeButton: 'Close',
  },
  next: 'Next',
  nextImage: 'Next image',
  paginationGap: 'Pagination Gap',
  previous: 'Previous',
  previousImage: 'Previous image',
  sanitizedRender: {
    collapse: 'collapse',
    expand: 'read more',
    expandEllipsis: 'read more…',
    expandCaps: 'Read more',
  },
  selectFormGroup: {
    selectOne: 'Select one…',
  },
  sellYoursButton: 'Sell Yours',
  shopBadges: {
    preferredSeller: 'Preferred Seller',
    preferredSellerDescription: 'This seller provides outstanding service',
    quickResponder: 'Quick Responder',
    quickResponderDescription: 'This seller responds quickly to messages',
    quickShipper: 'Quick Shipper',
    quickShipperDescription: 'On average, this seller ships quickly',
  },
  watchBadge: {
    watch: 'Watch',
    unwatch: 'Watching',
    watchTooltip: 'Add to Watch List',
    unwatchTooltip: 'Remove from Watch List',
    watchToast: 'Added to Watch List',
    unwatchToast: 'Removed from Watch List',
    ownListingTooltip: 'You can\'t Watch your own listing',
    watchers: {
      one: '{{count}} Watcher',
      other: '{{count}} Watchers',
    },
    benefits: {
      signUp: 'Join Reverb to Watch this gear',
      priceDrop: 'Get price drop notifications, so you never miss a deal',
      offers: 'Get real-time updates when someone else makes an offer',
      save: 'Save to your Watch List to easily find again later',
    }
  },
  watchRow: {
    loggedIn: {
      title: 'From Your Watch List',
      cta: 'View Watch List',
      emptyState: {
        subtitle: 'Start building your Watch List',
        title: 'Watch items to get updated on {{tagOpen}}price drops, save gear for later,{{tagClose}} and {{tagOpen}}more.{{tagClose}}',
        cta: 'Search for gear to add to your Watch List',
        condensedCta: 'Search for gear to Watch',
        intermediarySubtitle: 'Keep building your Watch List',
      },
    },
    loggedOut: {
      title: 'From Your Watch List',
      emptyState: {
        subtitle: 'Start building your Watch List',
        title: 'Create an account or log in to {{tagOpen}}Watch for deals.{{tagClose}}',
        cta: 'Sign Up or Log In',
      },
    }
  },
  currency: {
    unsupportedCurrencyConversion:
      'Conversion between from currency {{fromCurrency}} and to currency {{toCurrency}} is not supported',
  },
  auctionForm: {
    bidNow: 'Bid Now',
    ended: 'Ended',
    increaseBid: 'Increase Bid',
    inputPlaceholder: 'Bid amount ...',
    viewAuction: 'View Auction',
    outbid: "You've been outbid",
  },
  auctionStatus: {
    LOSING: "You're being outbid!",
    WINNING: "You're winning!",
    WON: "You've won!",
  },
  auctionHelpers: {
    bidSummary: {
      amount: 'Bid at least {{amount}}',
      currentBid: 'Your current max bid is {{display}}',
    },
    errors: {
      bidMustBeHigherThanMinimum:
        'Your bid must be higher than the current minimum bid of {{formattedBidMinimum}}.',
      bidMustBeHigherThanYourCurrentMax:
        'Your new bid must be higher than your current max bid of {{currentMax}}.',
    },
    priceSummary: {
      startingBid: 'Starting Bid',
      currentBid: 'Current Bid',
    },
    timeSummary: {
      endsAt: 'Ends {{date}}',
      endedAt: 'Auction ended {{date}}',
      startsAt: 'Bidding begins {{date}}',
    },
  },
  modalDialog: {
    goBack: 'Go back',
    cancel: 'Cancel',
    save: 'Save Changes',
    delete: 'Delete',
    wait: 'Please wait...',
  },
  addFollowToFeed: {
    addToGearAlerts: 'Also add this to my Daily Feed email',
    removeFromGearAlerts: 'Remove from my Daily Feed email',
    followSearch: 'View all matching listings',
    remove: {
      removeFromFeed: 'Remove from feed',
      areYouSure: 'Are you sure?',
      confirm: 'Yes',
      cancel: 'No',
      removeHint: "Matching items will be removed from your feed and we won't add any new ones.",
    },
  },
  bumpsView: {
    header: 'Reverb Bump',
  },
  regionFilterOptions: {
    loadPlaceholder: 'Change',
  },
  searchControls: {
    used: 'Used',
    new: 'Brand New',
  },
  modal: {
    backAction: 'Go back',
  },
  modalConfirmation: {
    confirm: 'Confirm',
    cancel: 'Cancel'
  },
  gridCardRow: {
    sellerRating: 'Seller Rating',
    moreInfo: 'More Info',
    viewListing: 'View Listing',
  },
  offers: {
    action: {
      create: 'Make an Offer',
      counter: 'Counter',
      accept: 'Accept',
      reject: 'Decline',
    },
    submitOfferButton: 'Submit Offer',
    offerCount: {
      one: '{{count}} Offer',
      other: '{{count}} Offers',
    },
    percentOff: '{{percent}}% off',
    amountWithPercentOff: '{{displayAmount}} ({{percent}}% Off)',
    offerQuantity: 'Quantity',
    shippingPrice: '+ {{shippingPrice}} Shipping',
    label: {
      counterOfferPrice: 'Counter offer',
      price: 'Your Offer',
      shipping: 'Shipping Price',
    },
    sellerMessageLabel: 'Message to Buyer',
    buyerShareAMessageLabel: 'Share a message with the seller',
    buyerShareAMessageHelpText: 'Tips: Share why you\'re interested or how you\'ll use it.',
    location: {
      shippingTo: 'Shipping to {{regionName}}, {{postalCode}}',
      change: 'Change',
      shippingRegionLabel: 'Shipping Region',
      postalCodeLabel: 'ZIP/Postal Code',
    },
    cancel: 'Cancel',
    error: "We're sorry, something went wrong. Please try again.",
    sidebar: {
      condition: 'Condition',
      shipping: 'Shipping',
      viewListing: 'View Listing',
      shopDisplayLocation: 'Ships From',
      sellerCost: 'Your Cost',
      askingPrice: 'Asking Price',
      listPrice: 'List Price',
      priceWithTaxIncludedHint: '{{displayPrice}} ({{taxIncludedHint}})',
      watches: 'Watches',
    },
    benefits: {
      title: 'Sign Up to Make an Offer',
      saveMoney: 'Save 10-15% on average',
      quickResponse: 'Most sellers respond within 4 hours',
    },
    autoOffers: 'Direct Offers',
    sellerOpenToOffersPopover: {
      title: 'Name your price',
      content: 'This seller\'s open to offers. See if they\'ll give you a deal.',
    },
    headerContent: {
      priceWithShipping: '{{openTag}}{{price}}{{closeTag}} + {{shippingPrice}} Shipping',
      priceWithUnknownShipping: '{{openTag}}{{price}}{{closeTag}} + Shipping',
      toPostalCode: 'to <strong>{{postalCode}}</strong>',
      shipsFrom: 'Ships from {{shopLocation}}',
      localPickupOnly: 'Local Pickup only',
      inCarts: {
        one: 'In {{count}} other cart',
        other: 'In {{count}} other carts',
      },
      changeButton: 'Change',
      applyButton: 'Apply',
      condition: '{{condition}} condition',
      currentPrice: 'Current price: {{currentPrice}}',
      listingLink: 'View listing',
      priceWithLabel: 'Price: {{displayPrice}}',
    },
    estimatedValueAlert: {
      label: 'Reverb estimated value:',
      belowLowballThreshold: 'The seller doesn\'t accept offers this low.',
      outOfRange: 'Many sellers won\'t accept an offer below this range.',
      sellerOutOfRange: 'This offer is below the estimated value of your item.',
    },
    confirmation: {
      modalHeader: 'Your Offer was submitted',
      header: 'What happens next',
      buyer: {
        line1: 'The seller has <strong>24 hours</strong> to respond. We\'ll email you with updates.',
        line2: 'If your Offer is accepted, we\'ll add it to your cart and you\'ll have <strong>3 days</strong> to check out.',
        line2WillPayImmediately: 'If your Offer is accepted, the payment method you selected will be charged and we\'ll send you an order confirmation.',
      },
      seller: {
        line1: 'The buyer has <strong>24 hours</strong> to respond. We\'ll email you with updates.',
        line2: 'If the buyer accepts the offer, they\'ll have <strong>3 days</strong> to check out. You\'ll receive an order confirmation when they complete the purchase.',
      },
      totalOfferPrice: '{{total}} total',
      buyerCheckoutCaveat: 'Taxes and fees applied at checkout',
      sellerCheckoutCaveat: 'Selling and processing fees apply',
    },
    currencyMayVary: 'What you pay may vary based on exchange rates, import duties, and taxes.',
    errors: {
      aboveListingPrice: 'Offer is above listing price',
    },
    modalHeader: {
      create: 'Make an Offer',
      review: 'Review',
      confirmed: 'Your Offer was submitted',
    },
    submitButton: {
      submit: 'Submit Offer',
      review: 'Review Details',
      confirm: 'Got it',
    },
    cancelButton: {
      cancel: 'Cancel',
      back: 'Back',
    },
    immediatePayment: {
      heading: 'Choose when you\'ll pay',
      options: {
        immediately: {
          label: 'Charge my account automatically if the offer gets accepted.',
          sublabel: 'Makes your offer more appealing because the seller gets paid faster.',
        },
        standard: {
          label: 'I\'ll pay within 3 days if the offer gets accepted.',
        },
      },
      totals: {
        yourOfferPrice: 'Your offer price',
        shipping: 'Shipping',
        tax: 'Estimated tax',
        total: 'Estimated total',
        totalWithoutTax: 'Subtotal before tax',
        ifAccepted: 'If the seller accepts',
        taxNote: 'Taxes will be calculated when the sale is processed',
      },
      shipTo: {
        label: 'Ship to:',
        helpText: '{{accountLinkOpen}}Visit your account{{accountLinkClose}} to update your address.',
      },
      payWith: {
        label: 'Pay with:',
        helpText: 'Offers with automatic payment must be paid via credit card.',
        helpTextLine2: '{{accountLinkOpen}}Visit your account{{accountLinkClose}} to update your payment options.',
        helpTextLine3: 'Reverb Credits cannot be used at this time.',
      },
      delivery: {
        label: 'Delivery:',
        standard: 'Standard Shipping',
        localPickup: 'Local Pickup',
      },
      contact: {
        label: 'Contact:',
      },
      terms: 'By clicking <strong>Submit Offer</strong>, you agree to {{termsLinkOpen}}Reverb\'s Terms{{termsLinkClose}}. Your payment method will be automatically charged if the seller accepts your offer.',
    },
  },
  shippingRegionSelector: {
    everywhereElse: 'Rest of the World',
    label: 'Select a region or country',
    emptyOptionText: 'None selected',
  },
  subRegions: {
    US_CON: 'Continental U.S.',
    US_AK: 'Alaska',
    US_HI: 'Hawaii',
    US_PR: 'Puerto Rico',
    US_OTHER: 'Other U.S. Territories',
  },
  signinForm: {
    passwordLabel: 'Password',
  },
  signupForm: {
    signupHeader: 'Create a Reverb Account',
    signinHeader: 'Log in to Reverb',
    firstNameLabel: 'First name',
    lastNameLabel: 'Last name',
    emailLabel: 'Email',
    emailValidationError: 'Please enter a valid email address.',
    emailConfirmLabel: 'Email Confirmation',
    emailMatchError: 'Emails must match',
    passwordLabel: 'Password',
    passwordTip: '(at least 12 characters, with at least one uppercase letter, one lowercase letter, one number, and one special character)',
    showPassword: 'Show Password',
    hidePassword: 'Hide Password',
    newsletterLabel: 'Yes, send me exclusive offers, promotions, news, reviews, and personalized tips for buying and selling on Reverb.',
    acceptTermsLabel:
      'By clicking Sign Up, I expressly agree to accept Reverb’s {{termsLinkOpen}}Terms of Use{{linkClose}} and {{privacyLinkOpen}}Privacy Policy{{linkClose}',
    gdprAcceptTermsLabel:
      'By clicking Sign Up, I expressly agree to accept Reverb’s {{termsLinkOpen}}Terms of Use{{linkClose}} and {{privacyLinkOpen}}Privacy Policy{{linkClose}}, including the processing of my personal data for access to the Reverb Marketplace and for Reverb ads.',
    gdprHowReverbUsesData: 'How Reverb Uses Data',
    gdprReverbProcessesData:
      'Reverb processes your data to provide you with access to its marketplace as a buyer, seller, or both, or as a developer utilizing the API or creating Applications. To learn about changing your data processing settings, visit our {{gdprPolicyLinkOpen}}policy page{{linkClose}}.',
    signUp: 'Sign Up',
    googleRecaptchaTermsOfService: 'This site is protected by reCAPTCHA Enterprise and the Google {{privacyLinkOpen}}Privacy Policy{{linkClose}} and {{termsLinkOpen}}Terms of Service{{linkClose}} apply.',
    logIn: 'Log In',
    continue: 'Continue',
    rememberMe: 'Stay signed in',
    forgotPassword: 'Forgot your Password?',
    passwordReset: 'Reset it',
    alreadyHaveAccount: 'Already have an account?',
    dontHaveAccount: "Don't have an account yet?",
    separatorText: 'or',
    createAccount: 'Want to create an account?',
    signUpLowercase: 'Sign up',
    signIn: 'Sign in',
  },
  taxonomy: {
    '032c74d0-b0e2-4442-877f-e1a22438a7fa': 'Band and Orchestra',
    '09055aa7-ed49-459d-9452-aa959f288dc2': 'Amps',
    '1f99c852-9d20-4fd3-a903-91da9c805a5e': 'Parts',
    '37f5ad77-15d0-4c2c-b4d3-1438c0805f13': 'Other',
    '3ca3eb03-7eac-477d-b253-15ce603d2550': 'Acoustic Guitars',
    '40e8bfd0-3021-43f7-b104-9d7b19af5c2b': 'Home Audio',
    '53a9c7d7-d73d-4e7f-905c-553503e50a90': 'Bass Guitars',
    '58d889f7-0aa1-4689-a9d3-da16dd225e8d': 'DJ and Lighting Gear',
    '62835d2e-ac92-41fc-9b8d-4aba8c1c25d5': 'Accessories',
    'b021203f-1ed8-476c-a8fc-32d4e3b0ef9e': 'Pro Audio',
    'b3cb9f8e-4cb6-4325-8215-1efcd9999daf': 'Drums and Percussion',
    'd002db05-ab63-4c79-999c-d49bbe8d7739': 'Keyboards and Synths',
    'dfd39027-d134-4353-b9e4-57dc6be791b9': 'Electric Guitars',
    'fa10f97c-dd98-4a8f-933b-8cb55eb653dd': 'Effects and Pedals',
    'fb60628c-be4b-4be2-9c0f-bc5d31e3996c': 'Folk Instruments',
    'e71b6e43-7217-4746-b4e3-64bf708a2664': 'Software',
  },
  thirdPartyAuth: {
    apple: {
      signUp: 'Sign Up with Apple',
      signIn: 'Log In with Apple',
      continue: 'Continue with Apple',
    },
    google: {
      signUp: 'Sign Up with Google',
      signIn: 'Log In with Google',
      continue: 'Continue with Google',
    },
    facebook: {
      signUp: 'Sign Up with Facebook',
      signIn: 'Log In with Facebook',
      continue: 'Continue with Facebook',
    },
  },
  dynamicPage: {
    pageEditLink: 'Edit This Page',
    footerByline: 'Editorial content by {{author}}',
  },
  dynamicPageHead: {
    searchPlaceholder: 'Search',
    actionText: 'View All Listings',
  },
  notFound: {
    pageTitle: 'Not Found',
    title: "Sorry, this page isn't available.",
    description: 'The link you followed may be broken or the page may have been deleted.',
    returnHome: 'Return Home',
  },
  homepage: {
    free2DayShipping: 'Free 2-Day Shipping',
    bestSellers: 'Best Sellers',
  },
  propertySwitcher: {
    core: 'Reverb',
    lp: 'Reverb LP',
  },
  errorDisplay: {
    header: 'Oops',
    message: 'Something went wrong. Please try again.',
  },
  cspModule: {
    estimatedValue: 'Price Guide Estimated Value',
    usedFrom: '{{count}} used from {{price}}',
    newFrom: '{{count}} new from {{price}}',
    buyNewFrom: 'Buy New from {{price}}',
    seePriceGuide: 'See chart & recent transactions',
    fromPriceGuide: 'From the Price Guide',
    findMore: 'Find More on Reverb',
    fairMarketValue: 'Fair Market Value',
    lowToHighPrice: '{{low}} - {{high}}'
  },
  quantitySelector: {
    inStock: '{{count}} in stock',
    atMaxBuyerQuantity: ' (limit {{maxBuyerQuantity}} per order)',
    availableForPreorder: '{{count}} available for preorder',
  },
  specList: {
    condition: 'Condition',
    conditionNew: '{{name}} (New)',
    conditionUsed: '{{name}} (Used)',
    learnMore: 'Learn more',
    learnMoreLink: ' %{linkOpen}Learn more%{linkClose}',
    conditions: {
      'brand-new': 'Brand New items are sold by an authorized dealer or original builder and include all original packaging.',
      'b-stock': 'B-Stock items are sold by an authorized dealer or original builder, but have been opened, blemished, or used in some manner.',
      'like-new': 'Like New items are in essentially new original condition but have been opened or played.',
      mint: 'Mint items are in essentially new original condition but have been opened or played.',
      'mint-inventory': 'Mint items are in essentially new original condition but have been opened or played.',
      excellent: 'Excellent items are almost entirely free from blemishes and other visual defects and have been played or used with the utmost care.',
      'very-good': 'Very Good items may show a few slight marks or scratches but are fully functional and in overall great shape.',
      good: 'Good condition items function properly but may exhibit some wear and tear.',
      fair: 'Fair condition gear should function but will show noticeable cosmetic damage or other issues.',
      poor: 'Poor condition gear may not work properly but can still perform most functions.',
      'non-functioning': 'Non-functioning items do not work as they should. All known issues should be described in the listing description.',
    },
    listed: 'Listed',
  },
  newsletterSignup: {
    heading: "Join the Musician's Marketplace",
    cta: 'Sign Up',
    headingNewsletter: 'Yes! Send me exclusive offers & promotions, news & reviews, and personalized tips for buying and selling on Reverb.',
    ctaNewsletter: 'Subscribe',
    placeholder: 'Your Email',
    gdprTerms: 'By clicking %{buttonText}, I agree to receive exclusive offers & promotions, news & reviews, and personalized tips for buying and selling on Reverb.',
    terms: 'By clicking %{buttonText}, I agree to receive exclusive offers & promotions, news & reviews, and personalized tips for buying and selling on Reverb.',
    successTitle: 'Thanks for signing up!',
    successMessage: 'Stay tuned for the latest from Reverb.',
    errorMessage: 'Sorry, something went wrong. Try again in a minute.',
    invalidEmailMessage: 'Please enter a valid email address.',
    emailTooLongMessage: 'That email address is too long. Please enter a valid email address.',
  },
  emailPopUp: {
    title: 'Never miss a beat',
    subtitle: 'Get updates on new releases, limited-time deals, and music gear we think you’ll love.',
  },
  searchHeader: {
    pickUp: 'Pick up where you left off',
    needInspiration: 'Need inspiration? Try one of these',
    suggestion: 'Try {{tagOpen}}"{{suggestion}}"{{tagClose}}',
    placeholder: 'Search the marketplace…',
    title: 'Find Your Dream Gear',
    subtitle: 'Join the millions of music lovers who use Reverb to find everything they need to make music',
    loggedInSubtitle: 'New music gear from trusted brands, small businesses, and music makers just like you added daily.',
    suggestions: {
      fenderStratocaster: '1964 Fender Stratocaster',
      elektronDigitakt: 'Elektron Digitakt',
      fenderPrecisionBass: 'Fender Precision Bass',
      lesPaulCustom: 'Gibson Les Paul Custom',
      tubeScreamer: 'Ibanez Tube Screamer',
      ludwigBlackBeauty: 'Ludwig Black Beauty Snare',
      ludwigJazzFestival: 'Ludwig Jazz Festival Snare',
      MarshallJcm800: 'Marshall JCM800',
      moogModelD: 'Moog Model D Synthesizer',
      neumannU87: 'Neumann U 87',
      procoRat: 'ProCo Rat',
      rolandJuno106: 'Roland Juno 106',
      sureSm7b: 'Shure SM7B Microphone',
      strymonTimeline: 'Strymon TimeLine',
      taylorGsMini: 'Taylor GS Mini',
      teenageEngineeringOp1: 'Teenage Engineering OP-1',
      apolloTwin: 'Universal Audio Apollo Twin',
      voxAc30: 'Vox AC30',
      yamahaDx7: 'Yamaha DX7',
      zildjianNewBeat: 'Zildjian New Beat Hi-Hats',
    },
  },
  messageSeller: 'Message Seller',
  listingNudge: {
    inOtherCarts: {
      one: 'In {{count}} Other Cart',
      other: 'In {{count}} Other Carts',
    },
  },
  conditionDisplay: {
    used: `Used – {{condition}}`,
  },
  listingCard: {
    location: {
      sameCountry: '{{city}}',
      sameCountryUs: '{{city}}, {{state}}',
      otherCountry: '{{city}}, {{country}}',
    },
    viewed: 'Viewed',
    outlet: 'Outlet',
  },
  listingCardNudge: {
    digitalDownload: 'Digital Download',
    freeExpeditedShipping: 'Free 2-Day Shipping',
    freeShipping: 'Free Shipping',
    greatValue: 'Great Value',
    inOtherCarts: {
      one: 'In {{count}} Other Cart',
      other: 'In {{count}} Other Carts',
    },
    localPickup: 'Local Pickup',
    localPickupAvailable: 'Local Pickup Available',
    newListing: 'Recently Listed',
    returnPolicy: '{{returnPolicyDays}}-Day Return Policy',
  },
  subwayNavigation: {
    dropdownTitle: 'Step {{currentStepNumber}} of {{totalSteps}}',
    step: {
      currentStep: 'Current Step',
      currentStepWithErrors: 'Current Step - Has Errors',
      completedStep: 'Completed Step',
      stepWithErrors: 'Step with errors',
      futureStep: 'Future Step',
    },
  },
  richTextEditor: {
    styleButton: {
      bold: 'Toggle Bold',
      italic: 'Toggle Italic',
      underline: 'Toggle Underline',
      bulletedList: 'Toggle Bulleted List',
    },
    adminOnlyButton: 'HTML (admin only)',
    errorBoundary: 'Something went wrong.',
  },
  truncationContainer: {
    showMore: 'Show More',
  },
  proximityFilter: {
    filterName: 'Within {{distance}} Miles',
    filterNameWithZip: 'Within {{distance}} Miles of {{postalCode}}',
    modalButton: 'Change',
    modalDistanceMiles: '{{distance}} Miles',
    adjustLocation: 'Adjust Location',
    zipCode: 'Zip Code',
    postalCode: 'Postal Code',
    radius: 'Radius',
    editZipCode: 'Edit Zip Code',
    update: 'Update',
  },
  photoUploader: {
    thumb: {
      edit: 'Edit',
      alt: 'Uploaded Image {{index}}',
      remove: 'Remove',
      tooSmallWarning: 'Image is too small',
      sortableAlt: 'Toggle reordering for this image',
      primaryLabel: 'Primary',
    },
    thumbList: {
      photos: 'Photos',
      reorder: 'Reorder',
      done: 'Done',
    },
    editor: {
      rotate: 'Rotate',
      crop: 'Crop',
      cancel: 'Cancel',
      done: 'Done',
      save: 'Save',
      imageAlt: 'Image Preview',
      header: 'Edit Photo',
      removePhoto: 'Remove Photo',
    },
    tip: {
      header: 'Upload or drag and drop photos of your gear.',
      reorder: 'You can drag your images to reorder them - the upper left image will be the primary photo.',
      details: 'Use original photos for used gear. Screenshots of pictures from other sites, sellers, or manufacturers are not allowed. Square photos are best, and they should be at least 620 by 620 pixels.',
    },
    fileUploader: {
      uploadPhoto: 'Upload Photo',
      dragAndDrop: {
        noLimit: 'Or drag and drop your files here',
        withLimit: 'Or drag and drop up to {{count}}',
      },
    },
    alert: {
      uploadFailure: {
        message: 'There was a problem uploading your image. Please make sure your image is a .jpg, .png or .heic format and try again.'
      },
      smallImageError: {
        message: 'An image was removed because it was too small. We recommend using images that are at least 620 pixels on each side. Please upload a larger image.',
      },
      smallImageWarning: {
        message: 'Some of your images are a bit small. We recommend using images that are at least 620 pixels on each side.',
      },
      photoLimitReached: {
        message: "You've reached the limit of {{count}} photos."
      },
      photoLimitExceeded: {
        message: "Some photos weren't uploaded because you've reached the maximum allowed limit."
      },
    }
  },
  marketplaceFilters: {
    allFilters: 'All Filters',
    filterAndSort: 'Filter & Sort',
    filterYourSearch: 'Filter Your Search',
    viewResults: 'View Results',
    resultsCount: '{{count}} Results',
    resultsCountFor: '{{count}} Results for {{children}}',
    resultsCountForStyled: '{{tagOpen}}{{count}} Results{{tagClose}} for "{{queryString}}"',
    showResultsCount: {
      one: 'Show {{count}} Result',
      other: 'Show {{count}} Results',
    },
    allConditions: 'New & Used',
    allBrands: 'Brand',
    allDecades: 'Decade',
    clearFilters: 'Clear Filters',
    allFinishes: 'All Finishes',
    allFretboardMaterials: 'All Fretboard Materials',
    pinnedTextQuery: {
      shop: 'Search this shop',
      other: 'Search term',
    },
  },
  marketplaceViewStates: {
    list: 'List View',
    grid: 'Grid View',
    ariaLabel: 'Listing View',
  },
  adCallout: 'Ad',
  sponsoredContentPartner: 'In Partnership With {{partner}}',
  sellerLocationSearchFilter: {
    buttonText: 'Seller Location',
  },
  forms: {
    clearLabel: 'Clear',
    submitLabel: 'Submit',
  },
  appStoreBadge: {
    storeBadgeAltApple: 'Download on the Apple App Store',
    storeBadgeAltGoogle: 'Download on the Google Play Store',
  },
  mobileAppCallout: 'Download the Reverb App',
  mobileAppAd: {
    appIllustrationAlt: 'Sketch of Reverb Mobile App',
  },
  shopLocal: {
    title: 'Shop Local',
    tooltip: 'Find deals in your neighborhood and save on shipping with local pickup.',
    learnMore: 'Learn more',
  },
  pill: {
    add: "Add",
    remove: "Remove",
  },
  loadingBars: {
    label: 'Loading',
  },
  sellerBadgeStatus: {
    completed: '{{completed}}/{{total}} Completed',
    showRequirements: 'Show Requirements',
    hideRequirements: 'Hide Requirements',
    stepNumberCompleted: 'Step {{number}} Completed.',
  },
  contentSponsorship: {
    ad: 'Ad',
    partnership: 'In Partnership With {{partner}}',
  },
  priceWithShipping: {
    newPrice: 'New Price',
    originalPrice: 'Original Price',
    price: 'Price',
    currentPrice: 'Current Price',
    typicalNewPriceTooltipNew: 'This item is priced lower than the typical new price on Reverb. {{linkOpen}}Learn more{{linkClose}}',
    typicalNewPriceTooltipUsed: 'This is what you’d likely pay when buying new on Reverb. Get the same gear for less when buying used. {{linkOpen}}Learn more{{linkClose}}',
    typicalNewPriceTooltipBStock: 'This is what you’d likely pay when buying new on Reverb. Get the same gear for less when buying b-stock. {{linkOpen}}Learn more{{linkClose}}',
    saveWithUsed: 'Save with used',
    saveWithBStock: 'Save with b-stock',
    typicalNewPrice: 'Typical new price',
    typicalNewPriceWithAmount: 'Typical new price is {{price}}',
  },
  seeMore: 'See more',
  listingCardEyebrows: {
    bump: 'Reverb Bump',
    priceDrop: 'Price Drop',
    onSale: 'On Sale',
  },
  a11yPriceDescription: {
    withoutRibbon: 'Originally %{originalPrice}, now %{buyerPrice}',
    withRibbon: 'Originally %{originalPrice}, now %{buyerPrice} (%{ribbon})',
  },
  favoriteShoppability: {
    noResults: 'No results right now',
    checkLater: 'Check back later to see if anything turns up.',
    viewAll: 'View All',
  },
  creditCardForm: {
    pleaseReverifyCvv: 'Please re-enter the CVV on this card',
    cvvLocation: 'This is the 3 or 4 digit security code usually found on the back of the card.',
    verifyCvvButton: 'Verify CVV',
  },
  webThemeControl: {
    theme: 'Site theme',
    light: 'Light',
    dark: 'Dark',
  },
  toggledSiteBanner: {
    searchInputPlaceholder: 'Shop for used & new music gear...',
  },
  searchBar: {
    placeholders: {
      listings: 'Shop for used & new music gear...',
      news: 'Search for articles...',
      priceGuides: 'Search for pricing info...',
      shops: 'Search shop names...',
    },
    context: {
      listings: 'Gear',
      news: 'Articles',
      priceGuides: 'Price Guide',
      shops: 'Shops',
      handpickedCollections: 'Handpicked Collections',
    },
    in: 'in',
    inputTitle: 'Search Reverb for used & new music gear',
    replaceSearchTerm: 'Replace search term with "{{term}}"',
  },
  autocomplete: {
    favoriteSearches: 'Saved Searches',
    recentSearches: 'Recent Searches',
    clearRecentSearches: 'Clear all',
    trendingSearches: 'Trending Searches',
  },
  recentlyViewed: {
    title: 'Recently Viewed Listings',
  },
  siteSearch: {
    placeholder: 'Shop for used & new music gear...',
    ariaLabel: 'Search',
    cspCompletionCard: {
      availableFrom: '{{count}} available from ',
    },
  },
  trustpilot: {
    fallbackText: 'Trustpilot reviews for Reverb',
  },
  paginationControls: {
    next: 'Next',
    previous: 'Previous',
  },
  protectionPlanModal: {
    title: 'Add a protection plan',
    playOn: 'Play on with peace of mind',
    poweredBy: 'Add a Mulberry protection plan',
    addPlanDescription: 'Add a plan to keep your gear protected long after it arrives at your door. Coverage includes:',
    listItem0: 'Electronics (such as keyboards, outboard gear, synths, amps) are covered for issues ranging from typical repairs to electrical failures and more.',
    listItem1: 'Guitars, drums, and other non-electronics are covered for defects, accidental damage, and more—from neck resets to headstock repairs and beyond.',
    listItem2: 'Coverage extends to new and used gear.',
    learnMore: 'See terms and conditions',
    selectorLabel: 'Select a plan length:',
    planOptionDuration: '{{durationYears}}-year plan',
    addPlan: 'Add plan',
    noThankYou: 'No, thank you',
  },
};
