import { includes } from 'lodash';

const OTHERS_SLUG = 'other';

function alphaSortCategories(categories) {
  return categories.sort((a, b) => {
    if (a.slug === OTHERS_SLUG) {
      return 1;
    } if (b.slug === OTHERS_SLUG) {
      return -1;
    }

    return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
  });
}

class Category {
  constructor(attributes = {}, parent = null) {
    this.attributes = attributes;
    this.parent = parent;
  }

  get id() {
    return String(this.attributes.id);
  }

  get name() {
    return this.attributes.name;
  }

  get slug() {
    return this.attributes.slug;
  }

  get uuid() {
    return this.attributes.uuid;
  }

  get isSelectableCategory() {
    return this.isRoot || !this.hasChildren;
  }

  get displayName() {
    const names = [this.name];
    this.ancestors.forEach((cat) => {
      names.push(cat.name);
    });
    return names.reverse().join(' > ');
  }

  get root() {
    if (this.isRoot) {
      return this;
    }

    return this.ancestors.reverse()[0];
  }

  get isRoot() {
    return this.ancestors.length === 0;
  }

  get hasChildren() {
    return this.children.length !== 0;
  }

  get inSameBranch() {
    return this.root.flattenedChildren();
  }

  isInSameBranch(category) {
    return this.root.id === category.root.id;
  }

  flattenedChildren(flattened = [], currentCategories = this.children) {
    currentCategories.forEach((cat) => {
      flattened.push(cat);
      this.flattenedChildren(flattened, cat.children);
    });
    return flattened;
  }

  get ancestors() {
    const ancestors = [];

    // eslint-disable-next-line @typescript-eslint/no-this-alias
    let currentNode = this;
    while (currentNode.parent) {
      ancestors.push(currentNode.parent);
      currentNode = currentNode.parent;
    }
    return ancestors;
  }

  get children() {
    return alphaSortCategories(this.attributes.children.map(cat => new Category(cat, this)));
  }
}

class CategoryTree {
  constructor(attributesArray) {
    this.categories = alphaSortCategories(attributesArray.map(atts => new Category(atts)));
  }

  flattened(arr = [], currentCategories = this.categories) {
    currentCategories.forEach((cat) => {
      if (cat.isSelectableCategory) {
        arr.push(cat);
      }
      this.flattened(arr, cat.children);
    });
    return arr;
  }

  findById(id) {
    if (id) {
      return this.flattened().find(cat => String(cat.id) === String(id));
    }

    return null;
  }

  findByIds(ids) {
    if (ids) {
      const stringIds = ids.map(id => String(id));
      return this.flattened().filter(cat => includes(stringIds, cat.id));
    }

    return [];
  }
}

export default CategoryTree;
