import React from 'react';
import StandardFormFields from './standard_form_fields';
import {
  IProps,
  IRefundType,
  getInitialRefundState,
  mapRefundTypeToRefundState,
} from './refund_request';

const { useState } = React;

export default function StandardRefundRequestFormFields(props: IProps) {
  const {
    userType,
    reasons,
    order,
    refundRequest,
    viewContext,
    hideRefundExplanation,
    protectionPlanAmount,
  } = props;
  const { canConditionallyApprove } = refundRequest;

  const [refundType, setRefundType] = useState(IRefundType.FULL);
  const onRefundTypeChange = event => {
    const refundTypeValue = event.target.value;
    setRefundType(refundTypeValue);
    setRefundState(
      mapRefundTypeToRefundState(
        refundTypeValue,
        canConditionallyApprove,
      ),
    );
  };

  const initialRefundState = getInitialRefundState(canConditionallyApprove);
  const [refundState, setRefundState] = useState(initialRefundState);
  const onRefundStateChange = event => {
    const refundStateValue = event.target.value;
    setRefundState(refundStateValue);
  };

  return (
    <StandardFormFields
      userType={userType}
      reasons={reasons}
      order={order}
      refundRequest={refundRequest}
      viewContext={viewContext}
      hideRefundExplanation={hideRefundExplanation}
      refundType={refundType}
      onRefundTypeChange={onRefundTypeChange}
      refundState={refundState}
      onRefundStateChange={onRefundStateChange}
      protectionPlanAmount={protectionPlanAmount}
    />
  );
}
