import PropTypes from 'prop-types';
import React from 'react';
import OfferItems from './offer_items';
import I18n from 'i18n-js';

class NegotiationSubtotal extends React.Component {
  render() {
    if (this.props.offerItems.isEmpty) {
      return false;
    }

    return (
      <table>
        <tbody>
          <tr>
            <td>{I18n.t('emailOffers.totals.priceSubtotal')}</td>
            <td>{this.props.offerItems.priceSubtotal.toString()}</td>
          </tr>
          <tr>
            <td>{I18n.t('emailOffers.totals.shippingSubtotal')}</td>
            <td>{this.props.offerItems.shippingSubtotal.toString()}</td>
          </tr>
          <tr>
            <td>
              <span className="weight-bold">{I18n.t('emailOffers.totals.total')}</span>
            </td>
            <td>
              <span className="weight-bold">{this.props.offerItems.subtotal.toString()}</span>
            </td>
          </tr>
        </tbody>
      </table>
    );
  }
}

NegotiationSubtotal.propTypes = {
  offerItems: PropTypes.instanceOf(OfferItems),
};

export default NegotiationSubtotal;
