import React from 'react';
import { AppleIcon, WindowsIcon, LinuxIcon, AndroidIcon } from '@reverbdotcom/cadence/icons/react';
import { RCIcon } from '@reverbdotcom/cadence/components';
import { core_apimessages_ListingDigitalVariant } from '@reverbdotcom/commons/src/gql/graphql';
import OperatingSystem from '../reverb-js-common/operating_system';
import traits from './traits';

// Digital listings can have either one variant with multiple operating systems,
// OR multiple variants with one operating system each
// If we have multiple operating systems we want to hide the OS selection dropdown
// and remove other OS specific items
export function hasUniversalOperatingSystemSupport(variants): boolean {
  const multipleOSVariant = variants.find(variant => {
    return (variant?.operatingSystems || variant?.operatingSystemTraits || []).length > 1;
  });
  return !!multipleOSVariant;
}

export function operatingSystemLabel(variant): string {
  const operatingSystems = variant.operatingSystems || [];
  return operatingSystems[0];
}

export function DownloadButtonIcon({ variant, variants }) {
  if (hasUniversalOperatingSystemSupport(variants)) { return null; }
  if (variant.downloadText) { return null; }

  const osLabel = operatingSystemLabel(variant);
  return <OperatingSystemIcon operatingSystem={osLabel} />;
}

export function OperatingSystemIcon({ operatingSystem }) {
  const iconMapping = {
    'macOS': AppleIcon,
    'Windows': WindowsIcon,
    'Linux': LinuxIcon,
    'iOS': AppleIcon,
    'Android': AndroidIcon,
  };
  const icon = iconMapping[operatingSystem];
  if (!icon) { return null; }

  return (
    <RCIcon svgComponent={icon} size="inline" />
  );
}

export function variantForOperatingSystem(operatingSystem, variants): core_apimessages_ListingDigitalVariant {
  return variants?.find(variant => {
    return variant.operatingSystems?.includes(operatingSystem.name);
  });
}

export function variantMatchingOperatingSystem(variants): core_apimessages_ListingDigitalVariant {
  if (!variants.length) { return null; }
  if (OperatingSystem.isWindows()) {
    return variantForOperatingSystem(traits.operatingSystem.windows, variants);
  } if (OperatingSystem.isMac()) {
    return variantForOperatingSystem(traits.operatingSystem.osx, variants);
  } if (OperatingSystem.isLinux()) {
    return variantForOperatingSystem(traits.operatingSystem.linux, variants);
  }
  return null;
}
