import React, { FocusEvent } from 'react';
import classNames from 'classnames';
import bind from '../bind';
import SanitizedRender from './sanitized_render';
import { useId } from '@reach/auto-id';
import { AngleLeftIcon, AngleRightIcon } from '@reverbdotcom/cadence/icons/react';

export interface IProps {
  title?: JSX.Element | React.ReactNode | string;
  titleHtml?: string;
  subtitle?: string | React.ReactNode;
  subtitleHtml?: string;
  ledeHtml?: string;
  action?: React.ReactElement;
  centeredTitle?: boolean;
  narrow?: boolean;
  appendAction?: boolean;
  hideActionArrow?: boolean;
  id?: string;
  condensed?: boolean;
  children?: React.ReactNode;
  shouldDisplayAsLanding?: boolean;

  // often this component will render without children yet
  // or with an empty <Tiles> component or some such;
  // passing this count allows it to detect when it now has
  // children and needs to recalculate the visibility
  // of controls
  collectionCount?: number;
}

export interface IState {
  leftControlEnabled: boolean;
  rightControlEnabled: boolean;
  controlsVisible: boolean;
}

// created this functional component wrapper as a quick work around
// to generate a unique id that is consistant server and client side for
// accessibility purposes
export default function OverflowingRow(props: IProps) {
  const id = useId(props.id);

  return <InnerOverflowingRow {...props} id={id} />;
}

class InnerOverflowingRow extends React.Component<IProps, IState> {
  state = {
    leftControlEnabled: false,
    rightControlEnabled: false,
    controlsVisible: false,
  };

  get headingId() {
    if (!this.props.id) {
      return null;
    }

    return `overflowing-row_heading-${this.props.id}`;
  }

  private innerDiv: any;

  private outerDiv: any;

  componentDidMount() {
    this.setControlVisibility();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.collectionCount !== this.props.collectionCount) {
      this.setControlVisibility();
    }
  }

  @bind
  setControlVisibility() {
    // collectionCount is not always used by implementations, so an explicit 0 here
    // is necessary for OverflowingRow to know not to render controls
    if (!this.innerDiv || !this.outerDiv || (this.props.collectionCount === 0)) { return false; }

    this.setState({
      controlsVisible: this.shouldDisplayControls(),
      leftControlEnabled: this.shouldEnableLeftControl(),
      rightControlEnabled: this.shouldEnableRightControl(),
    });
  }

  @bind
  scrollLeft() {
    this.innerDiv.scrollLeft -= this.getScrollDistance();
  }

  @bind
  scrollRight() {
    this.innerDiv.scrollLeft += this.getScrollDistance();
  }

  @bind
  handleFocus(event: FocusEvent<HTMLElement>) {
    const innerDivDimensions = this.innerDiv.getBoundingClientRect();
    const focusDimensions = event.target.getBoundingClientRect();
    if (focusDimensions.right > innerDivDimensions.right || focusDimensions.left < innerDivDimensions.left) {
      event.target.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'center',
      });
    }
  }

  getScrollDistance() {
    return this.innerDiv.offsetWidth * 0.85;
  }

  shouldDisplayControls() {
    return this.innerDiv.offsetWidth < this.innerDiv.scrollWidth;
  }

  shouldEnableLeftControl() {
    return this.innerDiv.scrollLeft > 0;
  }

  shouldEnableRightControl() {
    return (this.innerDiv.offsetWidth + this.innerDiv.scrollLeft) < this.innerDiv.scrollWidth - 120;
  }

  // used clone element to pass the aria-described by prop to the link within the action prop
  renderAction() {
    if (!this.props.action) { return null; }

    return (
      <div className="overflowing-row__action">
        {React.cloneElement(this.props.action, { 'aria-describedby': this.headingId })}
      </div>
    );
  }

  renderSubtitle() {
    if (!this.props.subtitle && !this.props.subtitleHtml) return;

    if (this.props.subtitleHtml) {
      return (
        <small className="d-block">
          <SanitizedRender
            html={this.props.subtitleHtml}
          />
        </small>
      );
    }

    return (
      <small className="d-block">
        {this.props.subtitle}
      </small>
    );
  }

  renderHeading() {
    if (!this.props.titleHtml && !this.props.title) { return null; }

    return (
      <h2
        className="overflowing-row__heading"
        id={this.headingId}
      >
        {this.renderTitle()}
        {this.renderSubtitle()}
      </h2>
    );
  }

  renderTitle() {
    return this.props.titleHtml ? (
      <SanitizedRender
        html={this.props.titleHtml}
      />
    ) : (
      this.props.title
    );
  }

  renderLede() {
    if (!this.props.ledeHtml) return;

    return (
      <SanitizedRender
        className="overflowing-row__lede"
        htmlTag="div"
        html={this.props.ledeHtml}
      />
    );
  }

  render() {
    const classes = classNames(
      'overflowing-row',
      { 'overflowing-row--centered': this.props.centeredTitle },
      { 'overflowing-row--landing': this.props.shouldDisplayAsLanding },
      { 'overflowing-row--append-action': this.props.appendAction },
      { 'overflowing-row--condensed': this.props.condensed },
      { 'overflowing-row--narrow': this.props.narrow },
      { 'overflowing-row--hide-controls': !this.state.controlsVisible },
      { 'overflowing-row--disable-left-control': !this.state.leftControlEnabled },
      { 'overflowing-row--disable-right-control': !this.state.rightControlEnabled },
      { 'overflowing-row--hide-action-arrow': this.props.hideActionArrow },
    );

    return (
      <div className={classes} id={this.props.id || null}>
        <div className="overflowing-row__inner">
          {this.renderHeading()}
          {this.renderAction()}
          {this.renderLede()}
          <div
            className="overflowing-row__items"
            ref={div => this.outerDiv = div}
          >
            <div
              className="overflowing-row__items__inner"
              ref={div => this.innerDiv = div}
              onScroll={this.setControlVisibility}
              onFocus={this.handleFocus}
            >
              {this.props.children}
            </div>
            <div
              className="overflowing-row__control overflowing-row__control--left"
              onClick={this.scrollLeft}
            >
              <AngleLeftIcon />
            </div>
            <div
              className="overflowing-row__control overflowing-row__control--right"
              onClick={this.scrollRight}
            >
              <AngleRightIcon />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
