import React from 'react';
import { times } from 'lodash';
import I18n from 'i18n-js';
import OverflowingRow from '@reverbdotcom/commons/src/components/overflowing_row';
import { RCButton, RCGrid, RCImage, RCIcon, useMediaQuery } from '@reverbdotcom/cadence/components';
import Tiles from '@reverbdotcom/commons/src/components/tiles';
import { SearchIcon } from '@reverbdotcom/cadence/icons/react';
import CoreLink from '@reverbdotcom/commons/src/components/core_link';
import { Paths } from '@reverbdotcom/commons/src/url_helpers';
import { MParticleEventName } from '@reverbdotcom/commons/src/elog/mparticle_types';
import classNames from 'classnames';
import { reverb_personalization_SearchQuery } from '@reverbdotcom/commons/src/gql/graphql';

interface IProps {
  querySuggestions?: reverb_personalization_SearchQuery[];
  loading?: boolean;
  displayPills?: boolean;
  relatedSearchLocation: 'top' | 'bottom';
}

interface QuerySuggestionProps {
  querySuggestions?: reverb_personalization_SearchQuery[];
  loading?: boolean;
  relatedSearchLocation: 'top' | 'bottom';
}

const COMPONENT_NAME = 'MarketplaceRelatedSearches';
const MAX_SEARCHES = 7;

function backgroundImageStyle(suggestion) {
  let imageUrl;
  if (suggestion.listings) {
    imageUrl = suggestion.listings[0].images[0]?.source;
  }
  return imageUrl;
}

function buildClickEvent(querySuggestion, position, displayStyle, relatedSearchLocation) {
  return {
    componentName: COMPONENT_NAME,
    eventName: MParticleEventName.ClickedRelatedSearchSuggestion,
    query: querySuggestion.query,
    relatedSearchLocation,
    displayStyle,
    position,
  };
}

function renderPlaceholder(displayPills, idx = null) {
  const classes = classNames(
    {
      'related-search-pill': displayPills,
      'related-search-pill__placeholder': displayPills,
      'related-search-image-card': !displayPills,
      'related-search-image-card__placeholder': !displayPills,
    },
  );

  if (displayPills) {
    return (
      <RCButton size="mini" variant="muted" key={`related-search-pill__placeholder-button-${idx}`}>
        <div className="related-search-pill__placeholder" key={`related-search-pill__placeholder-${idx}`}>
          <div className="related-search-pill__text" />
        </div>
      </RCButton>
    );
  }

  return (
    <div className={classes}>
      <div className="related-search-image-card__image-container" >
        <div className="related-search-image-card__image"/>
      </div>
    </div>
  );
}

function QuerySuggestion({ suggestion, idx, displayPills, relatedSearchLocation }) {
  const urlParams = {
    query: suggestion.query,
    referer: 'related_search',
  };

  Object.keys(urlParams).forEach(k => !urlParams[k] && delete urlParams[k]);
  const path = Paths.marketplace.expand(urlParams);

  if (displayPills) {
    return (
      <CoreLink
        clickEvent={buildClickEvent(suggestion, idx, 'pill', relatedSearchLocation)}
        className="related-search-pill"
        to={path}
        onClick={() => window.scrollTo(0, 0)}
      >
        <RCButton size="mini" variant="muted" >
          <div className="related-search-pill__icon">
            <RCIcon svgComponent={SearchIcon} inline />
          </div>
          <div className="related-search-pill__text">
            {suggestion?.query}
          </div>
        </RCButton>
      </CoreLink>
    );
  }

  return (
    <CoreLink
      className="related-search-image-card"
      clickEvent={buildClickEvent(suggestion, idx, 'image', relatedSearchLocation)}
      to={path}
      onClick={() => window.scrollTo(0, 0)}
    >
      <div
        className="related-search-image-card__image-container"
        key={`related-search-image-card-photo-${idx}`} >
        <RCImage
          src={backgroundImageStyle(suggestion)}
          overlay="offwhite"
          borderRadius="full"
        />
      </div>
      <div className="related-search-image-card__text">
        {suggestion?.query}
      </div>
    </CoreLink>
  );
}

export function RelatedSearchImageCard(props: QuerySuggestionProps) {
  const { loading, querySuggestions, relatedSearchLocation } = props;
  return (
    <OverflowingRow
      title={
        <div className="related-search-image-card__title">
          {I18n.t('discovery.marketplace.querySuggestions.relatedSearches')}
        </div>
      }
      collectionCount={querySuggestions?.length}
      centeredTitle={!useMediaQuery('mobile')}
    >
      <Tiles
        loading={loading}
        placeholder={renderPlaceholder(false)}
        centered
        singleRow
      >
        { !!querySuggestions?.length &&
          querySuggestions?.map((qs, idx) => {
            return (
              <QuerySuggestion
                key={`marketplace-related-search-image-card-${idx}`}
                suggestion={qs}
                idx={idx}
                displayPills={false}
                relatedSearchLocation={relatedSearchLocation}
              />
            );
          })}
      </Tiles>
    </OverflowingRow>
  );
}

export function RelatedSearchPills(props: QuerySuggestionProps) {
  const { loading, querySuggestions, relatedSearchLocation } = props;
  const isMobile = useMediaQuery('mobile');
  const classes = classNames(
    'related-searches-pills-row',
    { 'related-searches-pills-row-stack': !isMobile },
  );

  return (
    <div className="ptb-6">
      <RCGrid singleRowOn="mobile">
        <RCGrid.Item colSpan={{ desktop: 2 }} hideOn="mobile">
          <div className="related-search-pill__title">
            {I18n.t('discovery.marketplace.querySuggestions.relatedSearches')}:
          </div>
        </RCGrid.Item>
        <RCGrid.Item colSpan={{ desktop: 10, tablet: 12, mobile: 12 }} >
          <OverflowingRow
            collectionCount={querySuggestions?.length}
            title={isMobile ? I18n.t('discovery.marketplace.querySuggestions.relatedSearches') : null }
            condensed
          >
            <div className={classes}>
              { !loading && querySuggestions?.map((qs, idx) => {
                return (
                  <QuerySuggestion
                    key={`marketplace-related-search-pill-${idx}`}
                    suggestion={qs}
                    idx={idx}
                    relatedSearchLocation={relatedSearchLocation}
                    displayPills
                  />
                );
              }) }
              { loading && times(MAX_SEARCHES, (idx) => { return renderPlaceholder(true, idx); }) }
            </div>
          </OverflowingRow>
        </RCGrid.Item>
      </RCGrid>
    </div>
  );
}

export function MarketplaceRelatedSearches(props: IProps) {
  const { displayPills, loading, querySuggestions } = props;
  if (!loading && !querySuggestions?.length) { return null; }

  const classes = classNames(
    'related-searches-row',
    {
      'related-searches-pills': displayPills,
      'related-searches-image-cards': !displayPills,
    },
  );

  return (
    <div className={classes}>
      { displayPills ?
        <RelatedSearchPills { ...props } />
        :
        <RelatedSearchImageCard { ...props } />
      }
    </div>
  );
}

export default MarketplaceRelatedSearches;
