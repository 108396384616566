import React from 'react';
import classNames from 'classnames';
import I18n from 'i18n-js';
import bind from '../bind';

export interface IProps {
  count?: number;
  classPrefix?: string;
  countThreshold?: number;
}

export interface IState {
  open: boolean;
}

export default class CollapsingFacet extends React.Component<IProps, IState> {
  static defaultProps = {
    classPrefix: 'collapsing-facet',
    // this value must match the constant in the
    // `nth-child` selector in `.${classPrefix}__option`
    countThreshold: 6,
  };

  state = {
    open: false,
  };

  shouldCollapse() {
    return this.props.count > this.props.countThreshold;
  }

  @bind
  toggleFacet(event) {
    if (event) event.preventDefault();

    this.setState({ open: !this.state.open });
  }

  linkText() {
    return this.state.open ? 'collapse' : 'showMore';
  }

  renderToggleLink() {
    if (!this.shouldCollapse()) {
      return null;
    }

    return (
      <button
        className={`${this.props.classPrefix}__toggle`}
        onClick={this.toggleFacet}
      >
        {I18n.t(`commons.collapsingFacets.${this.linkText()}`)}
      </button>
    );
  }

  render() {
    const classes = classNames(this.props.classPrefix, {
      [`${this.props.classPrefix}--collapsed`]:
        this.shouldCollapse() && !this.state.open,
    });

    return (
      <div className={classes}>
        {this.props.children}
        {this.renderToggleLink()}
      </div>
    );
  }
}
