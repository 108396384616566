import React from 'react';

const ESC = 27;

/**
 * React hook to add a global listener for the esc key
 */
export function useEscToClose(visible: boolean, handleClose: (evt: Event) => void) {
  React.useEffect(() => {
    function keyListener(e: KeyboardEvent) {
      if (e.keyCode === ESC && visible) {
        handleClose(e);
      }
    }

    if (visible) {
      document.addEventListener('keydown', keyListener);
    }

    return () => document.removeEventListener('keydown', keyListener);
  }, [visible, handleClose]);
}
