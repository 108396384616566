import React from 'react';
import PropTypes from 'prop-types';
import { EppoClientContext, IEppoClient } from '../../eppo/eppoClientContext';

const eppoClientPropTypes = PropTypes.shape({
  getStringAssignment: PropTypes.func,
});

export interface IEppoClientContext {
  eppoClient?: IEppoClient;
}

/**
 * HOC: withEppoClientContext adds the Eppo client to your class components' props.
 */
export function withEppoClientContext<TProps extends {}>(
  Component: React.ComponentType<TProps & IEppoClientContext>,
) {
  type IProps = TProps & IEppoClientContext;

  const result = class Connected extends React.Component<IProps, null> {
    static displayName = `withEppoClientContext(${Component.displayName || Component.name})`;

    static WrappedComponent = Component;

    static contextTypes = {
      eppoClient: eppoClientPropTypes,
    };

    render() {
      return <Component eppoClient={this.context.eppoClient} {...this.props} />;
    }
  };

  return result;
}


export default class EppoClientContextProvider extends React.Component<IEppoClientContext, {}>
  implements React.ChildContextProvider<IEppoClientContext> {

  static childContextTypes = { eppoClient: eppoClientPropTypes };

  getChildContext() {
    return { eppoClient: this.props.eppoClient };
  }

  render() {
    return (
      <EppoClientContext.Provider value={this.props.eppoClient}>
        {this.props.children}
      </EppoClientContext.Provider>
    );
  }
}
