import React from 'react';
import I18n from 'i18n-js';
import { I18N } from '@reverbdotcom/commons/src/components/translate';
import ListingGrid from '../listing_grid';
import { Listing } from '@reverbdotcom/commons/src/gql/graphql';
import { ListingsCollection } from '@reverbdotcom/commons';

interface IProps {
  fallbackListings: Listing[];
  query: string;
  hasPrimaryListings: boolean;
  showingSold?: boolean;
  displayAsRow?: boolean;
}

const COMPONENT_NAME = 'MarketplaceFallbackListings';

export default function MarketplaceFallbackListings(props: IProps) {
  const {
    fallbackListings,
    query,
    hasPrimaryListings,
    showingSold,
    displayAsRow,
  } = props;

  const TRANSLATION_ROOT = 'discovery.marketplace.searchResults';

  if (fallbackListings.length === 0) return null;

  return (
    <>
      {!hasPrimaryListings && (
        displayAsRow ? (
          <div className="size-140 mobile-size-120 mb-4">
            {I18n.t(`${TRANSLATION_ROOT}.youMayAlsoBeInterested`)}
          </div>
        ) : (
          <div className="pt-4 pb-6">
            <h3 className="size-130">
              <I18N
                className="mr-space"
                html
                text={`${TRANSLATION_ROOT}.sorryFormatted`}
              >
                <span className="weight-bold">{query}</span>
              </I18N>
            </h3>
            <p className="mb-0 pt-1">
              {I18n.t(`${TRANSLATION_ROOT}.tryAnother`)}
            </p>
          </div>
        )
      )
      }
      <div className="mt-6">
        {hasPrimaryListings && (
          displayAsRow ? (
            <div className="size-140 mobile-size-120 mb-4">
              {I18n.t(`${TRANSLATION_ROOT}.youMayAlsoBeInterested`)}
            </div>
          ) : (
            <div className="size-140 mobile-size-120 mb-4">
              {I18n.t(`${TRANSLATION_ROOT}.relatedItems`)}
            </div>
          )
        )}
        {displayAsRow ? (
          <ListingsCollection
            componentName={COMPONENT_NAME}
            listings={fallbackListings}
          />
        ) : (
          <ListingGrid
            loading={false}
            error={false}
            listings={fallbackListings}
            showingSold={showingSold}
            trackingName={COMPONENT_NAME}
          />
        )}
      </div>
    </>
  );
}
