import React from 'react';
import I18n from 'i18n-js';
import { RCButton, RCIcon } from '@reverbdotcom/cadence/components';
import { FlagIcon } from '@reverbdotcom/cadence/icons/react';
import {
  core_apimessages_ConversationMessage as ConversationMessage,
  core_apimessages_ConversationMessageFlag as ConversationMessageFlag,
} from '@reverbdotcom/commons/src/gql/graphql';
import { Modal } from '@reverbdotcom/commons';
import ConversationFlagMessageForm from './ConversationFlagMessageForm';
import Location from '../lib/wrapped_location';

interface Props {
  isModalOpen?: boolean;
  message: ConversationMessage;
  messageFlag: ConversationMessageFlag;
}

export function ConversationFlagMessageButton(props: Props) {
  const buttonState = useButtonState(props.messageFlag, props.isModalOpen || false);

  return (
    <>
      <RCButton
        variant="transparent"
        size="mini"
        disabled={buttonState.disabled}
        onClick={buttonState.onClick}
      >
        <RCIcon svgComponent={FlagIcon} inline />
        <span className="ml-space">
          {buttonState.text}
        </span>
      </RCButton>
      <Modal
        data-flag-message-modal
        isOpen={buttonState.isModalOpen}
        onRequestClose={buttonState.closeModal}
        headerTitle={translate('modalTitle')}
      >
        <div className="padding-4">
          <ConversationFlagMessageForm
            message={props.message}
            onFlagCreated={buttonState.onFlagCreated}
          />
        </div>
      </Modal>
    </>
  );
}

function useButtonState(messageFlag: ConversationMessageFlag, modalOpenOnLoad: boolean) {
  const isMessageFlagged = !!messageFlag?.id;
  const [isModalOpen, showModal] = React.useState(modalOpenOnLoad);

  function buttonText() {
    if (isMessageFlagged) {
      return translate('completedText');
    }

    return translate('defaultText');
  }

  function closeModal() {
    showModal(false);
  }

  function onFlagCreated() {
    refreshLayout();
  }

  function refreshLayout() {
    // refresh, omitting any query params
    Location.assign(`${Location.origin}${Location.pathname}`);
  }

  function onClick() {
    showModal(true);
  }

  return {
    text: buttonText(),
    disabled: isMessageFlagged,
    isModalOpen,
    closeModal,
    onClick,
    onFlagCreated,
  };
}

function translate(key: string): string {
  return I18n.t(`discovery.conversations.flagMessage.button.${key}`);
}
