import React from 'react';
import I18n from 'i18n-js';
import { RCAlertBox } from '@reverbdotcom/cadence/components';

interface ContinueToPacklinkProps {
  onDraftSuccess(): void;
}

export default function ContinueToPacklink({ onDraftSuccess }: ContinueToPacklinkProps) {
  return (
    <div className="bg-white mlr-4 align-center">
      <div className="cms-text">
        <h2 className="align-center">{I18n.t('discovery.packlink.continueToPacklink')}</h2>
        <div className="mb-4">
          <RCAlertBox type="success">
            <ul className="size-80">
              <li className="d-flex">
                <span className="icon-l-check color-green" />
                <span>
                  {I18n.t('discovery.packlink.draftSuccess')}
                </span>
              </li>
            </ul>
          </RCAlertBox>
        </div>
        <button
          type="button"
          onClick={onDraftSuccess}
          className="button button--primary"
        >
          {I18n.t('discovery.packlink.viewOrEditLabel')}
        </button>
      </div>
    </div>
  );
}
