import { elog } from '@reverbdotcom/commons/src/elog';
import React from 'react';

interface LoggingEventProps {
  /** Unique event name. Prefer snake_case event names for searchability in DataDog. */
  eventName: string,
  /** Information to surface in DataDog under the 'data' event attribute. */
  data?: Record<string, any>,
  /**
   * Controls whether the log should be recorded.
   *
   * Can be set to false if a component is still loading for example.
   */
  shouldLog: boolean,
}

/**
 * Monitor React mount or update events for functional components.
 *
 * @example
 * ```typescript
 * useLoggingEvent({
 *   eventName: 'listing.financing_callout.mounted',
 *   shouldLog: !props.data.loading,
 * });
 * ```
 * For click handlers or network events, use {@link elog} directly
 *
 */
export function useLoggingEvent({
  eventName,
  data = {},
  shouldLog,
}: LoggingEventProps) {
  const [isLogged, setLogged] = React.useState(false);

  React.useEffect(() => {
    if (!shouldLog || isLogged) {
      return;
    }

    elog.info(eventName, data);
    setLogged(true);
  }, [eventName, data, shouldLog, isLogged, setLogged]);
}
