// TODO update this to import from commons/src/gql
// eslint-disable-next-line no-restricted-imports
import { gql } from '@apollo/client';
import React from 'react';
import { userContextIsTrackable } from '../elog/mparticle';
import { Commons_MParticle_ListingQuery } from '../gql/graphql';
import { withGraphql } from '../with_graphql';
import { IUserContext, withUserContext } from './user_context_provider';

export enum MParticleEventName {
  Purchase = 'PURCHASE',
}

export interface IMParticleTrackingProps extends IUserContext {
  listingIds: string[];
  mParticleListings?: Commons_MParticle_ListingQuery['listings'];
  loading: boolean;
}

export const mParticleListingFields = gql`
  fragment mParticleListingFields on Listing {
    id
    _id
    title
    brandSlug
    categoryRootUuid
    make
    categoryUuids
    state
    listingType
    bumpEligible
    shopId
    inventory
    soldAsIs
    acceptedPaymentMethods
    currency
    usOutlet

    condition {
      conditionUuid
      conditionSlug
    }

    categories {
      _id
      slug
      rootSlug
    }

    csp {
      _id
      id
      slug
      brand {
        _id
        slug
      }
    }

    pricing {
      buyerPrice {
        amount
        currency
        amountCents
      }
    }

    publishedAt {
      seconds
    }

    sale {
      _id
      id
      code
      buyerIneligibilityReason
    }

    shipping {
      shippingPrices {
        _id
        shippingMethod
        carrierCalculated
        destinationPostalCodeNeeded
        rate {
          amount
          amountCents
          currency
          display
        }
      }
      freeExpeditedShipping
      localPickupOnly
      localPickup
    }

    certifiedPreOwned {
      title
      badgeIconUrl
    }

    shop {
      _id
      address {
        _id
        countryCode
      }
      returnPolicy {
        _id
        newReturnWindowDays
        usedReturnWindowDays
      }
    }
  }
`;

export const LISTINGS_QUERY = gql`
  query Commons_MParticle_Listing(
    $ids: [String]
    $shouldSkipTracking: Boolean!
  )
  {
    listings(input: { ids: $ids }) {
      _id
      ...mParticleListingFields @skip(if: $shouldSkipTracking)
    }
  }
  ${mParticleListingFields}
`;

export default function withMParticleListings<TProps>(ssr = true) {
  return (
    Component: React.ComponentType<TProps>,
  ): React.ComponentType<TProps> => {
    const requestConfig = {
      options: (props: IMParticleTrackingProps) => {
        return {
          ssr,
          variables: {
            ids: props.listingIds.map(id => String(id)),
            shouldSkipTracking: !userContextIsTrackable(props.user),
          },
        };
      },
      props({ data, ownProps }) {
        const { listings, loading } = data;

        const props: IMParticleTrackingProps = {
          ...ownProps,
          mParticleListings: listings || [],
          loading,
        };

        return props;
      },
    };

    return withUserContext(withGraphql(LISTINGS_QUERY, requestConfig)(Component)) as React.ComponentType<TProps>;
  };
}
