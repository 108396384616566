import React from 'react';
import { Route } from '@reverbdotcom/commons/src/route_settings';
import { MParticlePageName } from '@reverbdotcom/commons/src/elog/mparticle_tracker';

import { MAKE_ROUTE_PATH } from '../components/shared/constants';

const BrandRoute = (
  <Route
    key="brand/:brand_slug"
    path={MAKE_ROUTE_PATH}
    props={{
      clientSide: false,
      pageName: MParticlePageName.Brand,
    }}
    getComponent={(_, cb) => {
      import(
        /* webpackChunkName: 'marketplace' */
        '../components/marketplace/marketplace_page_wrapper'
      ).then(m => cb(null, m.default));
    }}
  />
);

export { BrandRoute };
