import React from 'react';

import { SellerListing } from './sellerListingsCollectionReducer';

export interface ISellerListingsCollectionState {
  selectedListings: SellerListing[];
  listings: SellerListing[];
  showEmptyState: boolean;
  hasAllMatchesSelected?: boolean;
  totalMatches?: number;
  totalMatchesEligibleForDirectOffers?: number;
  totalMatchesEnabledForDirectOffers?: number;
  totalMatchesEnabledForBump?: number;
  totalMatchesEligibleForBump?: number;
  totalMatchesEligibleForAffirm?: number;
  totalMatchesEnabledForAffirm?: number;
  serializedDashboardSearchParams?: string;
  originatingUserId?: string;
}
interface ISellerListingsCollectionContext {
  listingsCollectionState: ISellerListingsCollectionState;
  dispatch: React.Dispatch<any>;
  useLazySellerListingsQuery: () => any;
}

export default React.createContext<ISellerListingsCollectionContext>({
  listingsCollectionState: null,
  dispatch: () => {},
  useLazySellerListingsQuery: () => {},
});
