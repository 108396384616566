import React from 'react';
// TODO update this to import from commons
// import { gql } from '@reverbdotcom/commons/src/gql';
// eslint-disable-next-line no-restricted-imports
import { gql } from '@apollo/client';
import { useQuery } from '@reverbdotcom/commons/src/useQuery';
import { CoreInformActVerificationStatus } from '@reverbdotcom/commons/src/gql/graphql';
import FormSection from '@reverbdotcom/commons/src/components/form_section';
import I18n from 'i18n-js';
import { I18N as Translate } from '@reverbdotcom/commons/src/components/translate';
import CoreLink from '@reverbdotcom/commons/src/components/core_link';
import UrlHelpers, { Paths } from '../components/shared/url_helpers';
import InformActVerificationForType from './inform_act_verification_for_type';

export const HELP_CENTER_ARTICLE_ID = '22671226454675';
export const US_TAX_PROFILE_PATH = '/my/selling/tax_id';
export const INTERNATIONAL_TAX_PROFILE_PATH = '/my/selling/international_tax_profile';

function InformActVerifications() {
  const BANK_ACCOUNT_TYPE = 'bankAccount';
  const TAX_ID_TYPE = 'taxId';

  const { data, loading } = useQuery<CoreInformActVerificationStatus.Query>(
    FETCH_INFORM_ACT_STATUS_THRESHOLD_DATA,
  );

  const informActThresholdStatus = data?.me?.shop?.informActThresholdStatus;
  const taxProfile = data?.me?.shop?.taxProfile;
  const internationalTaxProfile = data?.me?.shop?.internationalTaxProfile;

  const hasValidTaxId:boolean = informActThresholdStatus?.hasValidTaxId;
  const taxIdUpdatedDate:string = taxProfile?.updatedAt || internationalTaxProfile?.updatedAt;
  const taxProfilePageLink:string = data?.me?.shop?.address?.countryCode === 'US' ? US_TAX_PROFILE_PATH : INTERNATIONAL_TAX_PROFILE_PATH;

  const hasValidBankAccount:boolean = informActThresholdStatus?.hasValidBankAccount;
  const bankAccountVerifiedDate:string = informActThresholdStatus?.bankAccountVerifiedDate;

  const isPayPalOnlySeller:boolean = informActThresholdStatus?.isPaypalOnly;

  if (loading || !informActThresholdStatus) return null;

  return (
    <FormSection
      description={
        <Translate text="discovery.informActVerifications.description">
          <CoreLink
            className="text-link"
            target="_blank"
            to={Paths.helpCenterArticlesUrl.expand({ articleId: HELP_CENTER_ARTICLE_ID })}
          >
            <Translate text="discovery.informActVerifications.learnMore" />
          </CoreLink>
        </Translate>
      }
      title={I18n.t('discovery.informActVerifications.title')}
    >
      <div className="verification-task-list">
        <div className="verification-task-list__items">
          <InformActVerificationForType
            verificationType={TAX_ID_TYPE}
            isVerified={hasValidTaxId}
            updatedDate={taxIdUpdatedDate}
            buttonLink={taxProfilePageLink}
          />
          {!isPayPalOnlySeller && (
            <InformActVerificationForType
              verificationType={BANK_ACCOUNT_TYPE}
              isVerified={hasValidBankAccount}
              updatedDate={bankAccountVerifiedDate}
              buttonLink={UrlHelpers.shopPoliciesEditPath}
            />
          )}
        </div>
      </div>
    </FormSection>
  );
}

export const FETCH_INFORM_ACT_STATUS_THRESHOLD_DATA = gql`
  query Core_InformActVerification_Status {
    me {
      _id
      id
      shop {
        _id
        address {
          _id
          countryCode
        }
        informActThresholdStatus {
          id
          isCompliant
          hasValidTaxId
          hasValidBankAccount
          hasCompletedSellerVerification
          bankAccountVerifiedDate
          isPaypalOnly
        }
        taxProfile {
          updatedAt
        }
        internationalTaxProfile {
          updatedAt
        }
      }
    }
  }
`;

export default InformActVerifications;
