import React from 'react';
import I18n from 'i18n-js';
import {
  core_apimessages_TaxIdentification as TaxIdentification,
} from '@reverbdotcom/commons/src/gql/graphql';
import FormGroupWithInput from '@reverbdotcom/commons/src/components/form_group_with_input';
import FormGroupWithSelect, { ISelectOption } from '@reverbdotcom/commons/src/components/form_group_with_select';
import ModalDialog from '@reverbdotcom/commons/src/components/modal_dialog';
import ShopSettingsVatIdSaveError from './shop_settings_vat_id_save_error';
import vatIdHelpText from './shop_settings_vat_id_help_text';
import { connect } from '@reverbdotcom/commons/src/connect';
import {
  MutationsProps,
  withUpdateTaxIdentification,
  withDeactivateTaxIdentification,
} from './shop_settings_tax_identification_mutations';

interface ExternalProps {
  taxIdentification: TaxIdentification;
  countryOptions: ISelectOption[];
  isOpen: boolean;
  closeForm: () => void;
}

type Props = ExternalProps & MutationsProps;

export function ShopSettingsUpdateVatIdForm(props: Props) {
  const {
    taxIdentification,
    countryOptions,
    isOpen,
    closeForm,
    updateTaxIdentification,
    deactivateTaxIdentification,
  } = props;

  const { id } = taxIdentification;
  const [countryCode, setCountryCode] = React.useState(taxIdentification.countryCode);
  const [taxId, setTaxId] = React.useState(taxIdentification.taxId);
  const [error, setError] = React.useState('');

  async function update() {
    try {
      const response = await updateTaxIdentification({
        variables: {
          input: {
            taxIdentification: {
              id,
              countryCode,
              taxId,
            },
          },
        },
      });

      if (response) {
        clearAndCloseForm();
      }
    } catch (e) {
      setError(I18n.t('discovery.shopSettings.vat.saveFailed'));
    }
  }

  async function deactivate() {
    try {
      const response = await deactivateTaxIdentification({
        variables: {
          input: {
            id,
          },
        },
      });

      if (response) {
        clearAndCloseForm();
      }
    } catch (e) {
      setError(I18n.t('discovery.shopSettings.vat.deleteFailed'));
    }
  }

  function clearAndCloseForm() {
    setTaxId('');
    setCountryCode('');
    closeForm();
  }

  return (
    <ModalDialog
      isOpen={isOpen}
      onRequestClose={closeForm}
      headerTitle={I18n.t('discovery.shopSettings.vat.updateVat')}
      saveButtonText={I18n.t('discovery.shopSettings.vat.save')}
      onSubmit={update}
      onDelete={deactivate}
    >
      <ShopSettingsVatIdSaveError
        countryCode={countryCode}
        error={error}
      />
      <FormGroupWithSelect
        inputName="countryCode"
        fieldName="countryCode"
        label={I18n.t('discovery.shopSettings.vat.countryCode')}
        value={countryCode}
        updateField={(field) => setCountryCode(field.countryCode)}
        options={countryOptions}
        required
        addEmptyOption
        helpText={I18n.t('discovery.shopSettings.vat.countryAlreadyUsed')}
      />
      <FormGroupWithInput
        inputName="taxId"
        fieldName="taxId"
        inputType="text"
        label={I18n.t('discovery.shopSettings.vat.vatId')}
        value={taxId}
        updateField={(field) => setTaxId(field.taxId)}
        pattern="[a-zA-Z0-9]+"
        helpText={vatIdHelpText(countryCode, countryOptions)}
        required
      />
    </ModalDialog>
  );
}

export default connect<ExternalProps>([
  withUpdateTaxIdentification,
  withDeactivateTaxIdentification,
])(ShopSettingsUpdateVatIdForm);
