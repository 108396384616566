import { APIRequest } from '../index';
import I18n from 'i18n-js';

class ProductReview {
  constructor(props) {
    props = props || {};
    this.errors = {};
    this.title = props.title;
    this.body = props.body;
    this.rating = props.rating || 0;
    this.link = props.link;
    this.saveMethod = props.saveMethod;
  }

  valid() {
    this._setErrors();
    return Object.keys(this.errors).length === 0;
  }

  save() {
    return APIRequest[this.saveMethod.toLowerCase()](this.link, {
      rating: this.rating,
      body: this.body,
      title: this.title,
    });
  }

  _setErrors() {
    this.errors = {};
    if (this.rating < 1) {
      this.errors.rating = I18n.t('js.productReview.ratingError');
    }
    if (!this.body || !this.body.length) {
      this.errors.body = I18n.t('js.productReview.bodyError');
    }
  }
}

export default ProductReview;
