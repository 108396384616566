import React from 'react';
import I18n from 'i18n-js';

import { Filter, WidgetType } from './search_filters';
import { RCChip, RCPopover, RCText } from '@reverbdotcom/cadence/components';
import SelectFacet from './select_facet';
import FilterChip from './filter_chip';
import { I18N } from './translate';
import { Location } from 'history';
import ListViewToggle from './list_view_toggle';
import TextInputFacet from './text_input_facet';
import GridRowWithInputChips from './grid_row_with_input_chips';
import SanitizedRender from './sanitized_render';
import { getFiltersForSidebar } from '../grid_helpers';
import { getExcludedFilterKeys, getFiltersTotal } from './helpers/selected_filters_helper';
import { useUser } from '../user_hooks';

interface ISearchOverviewListViewToggleProps {
  loading: boolean,
  filters: Filter[],
  setListViewState: (val: boolean) => void
}

interface IProps {
  filters: Filter[];
  followButton?: React.ReactNode;
  tipText?: string | React.ReactNode;
  totalText?: string;
  titleOverride?: string;
  loading?: boolean;
  resultsTotal?: number;
  trackingQuery?: string;
  location: Location;
  setListViewState?: (val: boolean) => void;
  showListViewToggle?: boolean;
  useH1Title?: boolean;
  pinTextQuery?: boolean; // ensure search bar always visible above grid, instead of buried within filters
  query?: string;
  toggleExposedSidebarOpen: () => void;
}

function LoadingTitle() {
  return (
    <div>
      <div className="search-overview__loading-text" />
      <div className="size-70 mt-1">
        <div className="search-overview__loading-text" />
      </div>
    </div>
  );
}

// Component for `srp_exposed_filters` experiment. Most of the code here should be duplicated in RQLSearch Grid (with the
// exception of any sidebar related code) until experiment is complete and code is cleaned up.
export default function RQLSearchOverviewWithExposedSidebar({
  filters = [],
  followButton = null,
  totalText = '',
  tipText = '',
  loading = false,
  resultsTotal = null,
  trackingQuery = '',
  titleOverride = null,
  location = null,
  setListViewState,
  showListViewToggle = false,
  useH1Title = false,
  pinTextQuery = false,
  query = '',
  toggleExposedSidebarOpen,
}: IProps) {
  const filterInputChips = getFiltersForSidebar(filters);
  const sort = filters.find(filter => filter.widgetType === WidgetType.SORT);

  const canRenderQueryTitle = !!query && resultsTotal !== null;
  const hasSufficientTitlePropsForSSR = !!titleOverride || canRenderQueryTitle;
  const showLoadingTitleState = loading && filters.length === 0 && !hasSufficientTitlePropsForSSR;
  const textQueryFilter = filters.find((f) => f.key === 'TEXT_QUERY');
  const showTextQueryBoxOverGrid = pinTextQuery && textQueryFilter;

  const user = useUser();
  const pathname = location?.pathname;
  const excludedFilterKeys = getExcludedFilterKeys(pathname, pinTextQuery);
  const selectedFiltersTotal = getFiltersTotal(filters, excludedFilterKeys, user.shippingRegionCode);

  return (
    <nav data-exposed-sidebar className="scaling-mtb-6">
      <section className="d-flex fx-justify-between mb-4">
        {showTextQueryBoxOverGrid && (
          <div className="width-30">
            <TextInputFacet
              pageSection="marketplace-pinned-query"
              filter={textQueryFilter}
              trackingQuery={trackingQuery}
              placeholder={I18n.t(`commons.marketplaceFilters.pinnedTextQuery.${location?.pathname?.includes('/shop') ? 'shop' : 'other'}`)}
              hideLabel
            />
          </div>
        )}
        <div>
          {showLoadingTitleState
            ? <LoadingTitle />
            : (
              <>
                <RCText.Title
                  size="700"
                  weight={query ? 'regular' : 'bold'}
                  htmlTag={useH1Title ? 'h1' : 'h2'}
                >
                  <Title
                    filters={filters}
                    resultsTotal={resultsTotal}
                    totalText={totalText}
                    titleOverride={titleOverride}
                    query={query}
                  />
                </RCText.Title>
                {tipText}
                {followButton}
              </>
            )
          }
        </div>
      </section>
      <section className="d-flex fx-justify-between fx-dir-reverse tablet-fx-dir-row gap-4 tablet-d-block">
        <div className="d-flex fx-justify-between fx-align-start gap-2 tablet-mb-4 tablet-pb-4 tablet-bdb-1 bd--offwhite">
          <RCChip
            onClick={toggleExposedSidebarOpen}
            count={selectedFiltersTotal}
          >
            {I18n.t('commons.marketplaceFilters.filterAndSort')}
          </RCChip>
          {showListViewToggle &&
            <SearchOverviewListViewToggle
              loading={loading}
              filters={filters}
              setListViewState={setListViewState}
            />
          }
          <div className="tablet-d-none">
            <SortChip
              loading={loading}
              sort={sort}
              trackingQuery={trackingQuery}
            />
          </div>
        </div>
        <GridRowWithInputChips
          filters={filterInputChips}
        />
      </section>
    </nav>
  );
}

export function Title({ filters, resultsTotal, totalText, titleOverride, query }) {
  if (titleOverride) {
    return titleOverride;
  }

  const queryFilter = filters.filter(f => {
    return f.key === 'TEXT_QUERY';
  });

  const queryStringFromFilter = queryFilter && queryFilter.length > 0
    ? queryFilter[0].options[0].optionValue
    : null;

  /* On server side renders we will default to using the query string while client side renders
  will pull from the filter query to ensure the title is properly updated */
  const queryString = queryStringFromFilter || query;

  if (queryString && resultsTotal) {
    return (
      <SanitizedRender
        html={
          I18n.t('commons.marketplaceFilters.resultsCountForStyled', {
            count: I18n.toNumber(resultsTotal, { precision: 0 }),
            queryString,
            tagOpen: '<strong>',
            tagClose: '</strong>',
          })
        }
      />
    );

  }

  if (resultsTotal) {
    return (
      <I18N
        text="commons.marketplaceFilters.resultsCount"
        args={{ count: I18n.toNumber(resultsTotal, { precision: 0 }) }}
      />
    );
  }

  return totalText;
}

export function SortChip({ loading, sort, trackingQuery }) {
  const [isPopoverOpen, openPopover] = React.useState(false);

  if (loading && !sort) {
    return (
      <FilterChip
        key="sort"
        loading
      >
        <div className="filter-chip__loading-placeholder" />
      </FilterChip>
    );
  }
  if (!sort) return null;

  const selected = sort?.options.find(opt => opt.selected);
  return (
    <RCPopover
      isOpen={isPopoverOpen}
      onDismiss={() => openPopover(false)}
      loading={loading}
      trapFocus
      anchor={
        <FilterChip
          withDropdown
          loading={loading}
          onClick={() => openPopover(!isPopoverOpen)}
        >
          <span>
            <span className="weight-normal">{`${sort.name}: `}</span>
            {selected?.name || ''}
          </span>
        </FilterChip>
      }
    >
      <SelectFacet
        pageSection="search-overview"
        filter={sort}
        trackingQuery={trackingQuery}
      />
    </RCPopover>
  );
}

function SearchOverviewListViewToggle({ loading, filters, setListViewState }:ISearchOverviewListViewToggleProps) {
  if (loading && filters.length === 0) {
    return (
      <div className="search-overview__list-view-toggle-loading-placeholder" />
    );
  }

  return (
    <ListViewToggle
      setListViewState={setListViewState}
      loading={loading}
    />
  );
}
