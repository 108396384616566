import React from 'react';
import I18n from 'i18n-js';
import { IUserType, IRefundRequestProps } from './refund_request';

interface IProps {
  reasons: string[];
  refundReason: string;
  onRefundReasonChange: (event: any) => void;
  userType: IUserType;
  refundRequest: IRefundRequestProps;
}

const RefundRequestReason: React.StatelessComponent<IProps> = (props) => {
  if (props.userType !== IUserType.BUYER && props.refundRequest.isPersisted) {
    return null;
  }

  function renderOptions() {
    const options = [<option value="" key="prompt">{I18n.t('discovery.dashboard.refundRequests.selectOne')}</option>];

    props.reasons.forEach((reason) => {
      const text = I18n.t(`discovery.dashboard.refundRequests.${props.userType}.reasons.${reason}`);
      options.push(<option value={reason} key={reason}>{text}</option>);
    });

    return options;
  }

  return (
    <div className="form-group">
      <div className="form-group__header">
        <label className="form-group__label-text" htmlFor="order_refund_request_reason">
          {I18n.t('discovery.dashboard.refundRequests.reason')}
        </label>
        <div className="form-group__tag">
          {I18n.t('discovery.dashboard.refundRequests.required')}
        </div>
      </div>
      <div className="form-group__fields">
        <div className="styled-dropdown">
          <select
            id="order_refund_request_reason"
            name="order_refund_request[reason]"
            onChange={props.onRefundReasonChange}
            required
            className="nochosen"
            data-refund-reason-select
          >
            {renderOptions()}
          </select>
        </div>
      </div>
    </div>
  );
};

export default RefundRequestReason;
