import React from 'react';
import { RCAlertBox } from '@reverbdotcom/cadence/components';
import { I18N } from '@reverbdotcom/commons/src/components/translate';
import URLHelpers from './url_helpers';

export function GetPaidLaterInfo() {
  return (
    <RCAlertBox type="info">
      <I18N
        html
        text="js.shopPolicies.directCheckoutSetup.deferBankSetupText"
        args={{
          linkOpen: `<a href="${URLHelpers.shopPoliciesEditPath}">`,
          linkClose: '</a>',
        }}
      />
    </RCAlertBox>
  );
}
