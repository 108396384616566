import React from 'react';
import SiteFooter from './site_footer';
import SiteSubFooter from './site_subfooter';

export default function () {
  return (
    <>
      <SiteFooter />
      <SiteSubFooter />
    </>
  );
}
