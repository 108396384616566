import React from 'react';
import { elog } from '@reverbdotcom/commons/src/elog';
import { useUser } from '@reverbdotcom/commons/src/user_hooks';
import { findKlarnaClientID } from '../lib/klarnaOnSiteMessaging';
import wrappedWindow from '@reverbdotcom/commons/src/window_wrapper';
import { loadScript } from '@reverbdotcom/commons/src/loadScript';

export function useKlarnaMessagingEmbed() {
  const { countryCode } = useUser();

  React.useEffect(() => {
    try {
      loadKlarnaEmbed({ countryCode });
    } catch (e) {
      elog.error('klarna_callout.fetch_error', {}, e);
    }
  }, [countryCode]);

  React.useLayoutEffect(() => {
    window.Klarna?.OnsiteMessaging?.refresh();
  });
}

export async function loadKlarnaEmbed({ countryCode, document = wrappedWindow.document }: {
  countryCode: string,
  document?: Document,
}) {
  const clientId = findKlarnaClientID(countryCode);
  if (!clientId) {
    return;
  }

  loadScript(
    {
      id: '__klarna-osm',
      src: 'https://osm.klarnaservices.com/lib.js',
      dataset: {
        environment: 'production',
        clientId,
      },
    },
    document,
  );
}
