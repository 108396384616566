import { ChildProps } from '@apollo/client/react/hoc';
import { MutationFunction } from '@reverbdotcom/commons/src/useHOCMutation';
// TODO update this to import from commons
// import { gql } from '@reverbdotcom/commons/src/gql';
// eslint-disable-next-line no-restricted-imports
import { gql } from '@apollo/client';
import React from 'react';
import { withGraphql } from '@reverbdotcom/commons/src/with_graphql';
import {
  CorePacklinkCreateDraft,
  OrderPacklinkShipmentData,
  core_apimessages_PacklinkShipment_Status as PacklinkShipmentStatus,
} from '@reverbdotcom/commons/src/gql/graphql';
import ConnectingToPacklink from './packlink_connecting_to_packlink';

interface ExternalProps {
  onDraftSuccess(): void;
  order: OrderPacklinkShipmentData.Fragment;
  startPolling(): void;
  stopPolling(): void;
}

interface MutationProps {
  mutate: MutationFunction<CorePacklinkCreateDraft.Mutation, CorePacklinkCreateDraft.Variables>;
}

export type Props = ChildProps<ExternalProps> & MutationProps;

export function PacklinkCreateDraft(props: Props) {
  const {
    order,
    onDraftSuccess,
    startPolling,
    stopPolling,
    mutate,
  } = props;

  const currentStatus = order?.packlinkShipment?.status;

  const terminalStatuses = [PacklinkShipmentStatus.SUCCESS, PacklinkShipmentStatus.FAILED];
  const hasTerminalStatus = currentStatus && terminalStatuses.includes(currentStatus);

  React.useEffect(() => {
    async function createDraft() {
      if (hasTerminalStatus) {
        return;
      }
      const response = await mutate({ variables: { input: { orderId: order.legacyOrderId } } });

      if (response) {
        startPolling();
      }
    }

    createDraft();
  }, [currentStatus, mutate, order.legacyOrderId, startPolling, hasTerminalStatus]);

  React.useEffect(() => {
    function stopPollingOnSuccess() {
      if (currentStatus === PacklinkShipmentStatus.SUCCESS) {
        stopPolling();
      }
    }

    stopPollingOnSuccess();
  }, [currentStatus, order, onDraftSuccess, stopPolling]);

  return (
    <ConnectingToPacklink />
  );
}

const withMutation = withGraphql<ExternalProps, CorePacklinkCreateDraft.Mutation, CorePacklinkCreateDraft.Variables>(gql`
mutation Core_Packlink_CreateDraft($input: Input_core_apimessages_CreatePacklinkDraftRequest) {
  createPacklinkDraft(input: $input) {
    shipment {
      id
    }
  }
}`,
);

export default withMutation(PacklinkCreateDraft);
