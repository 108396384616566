export default {
  rateFields: {
    shippingToSectionTitle: 'Shipping To',
    shippingToRequired: 'Shipping to %{regionName} is required',
    required: '(Required)',
    locationColumnHeader: 'Location',
    notApplicableAcronym: 'N/A',
    costHeader: 'Rates',
    removeRate: 'Remove',
    standardRate: 'Standard Rate*',
    incrementalRate: 'Combined Rate',
    expeditedRate: '2-Day Rate*',
    freeShippingThreshold: 'Free Shipping on Orders Over',
    continentalUnitedStatesLabel: 'Continental United States',
  },
  shippingRegionPlaceholder: 'Select a region...',
  addShippingLocations: 'Add Shipping Locations',
  addRate: 'Add',
  everywhereElse: 'Everywhere Else',
  everywhereElseTip: 'The rate for any location outside of the locations you’ve specified in this list.',
  expeditedShipping: {
    title: 'Offer 2-Day Shipping',
    yes: 'Yes',
    no: 'No',
    finePrint: 'By offering 2-Day Shipping, you agree to the %{link_open}requirements%{link_close}.',
    qualifyExpeditedShipping: '%{open_tag}Learn how you can offer 2-Day Shipping.%{close_tag}',
  },
};
