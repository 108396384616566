import { ChildProps } from '@apollo/client/react/hoc';
// TODO update this to import from commons
// import { gql } from '@reverbdotcom/commons/src/gql';
// eslint-disable-next-line no-restricted-imports
import { gql } from '@apollo/client';
import React from 'react';
import FavoriteShopCTA, { FavoriteShopCTAFragment } from './favorite_shop_cta';
import { withGraphql } from '@reverbdotcom/commons/src/with_graphql';
import { ShopHeaderFragment } from '../discovery/shops/shop_header';
import { RCBanner } from '@reverbdotcom/cadence/components';
import { get } from 'lodash';
import I18n from 'i18n-js';
import { CorePostPurchaseShopNudge } from '@reverbdotcom/commons/src/gql/graphql';
import { RCAvatar } from '@reverbdotcom/cadence/components/RCAvatar/RCAvatar';

interface ExternalProps {
  shopId: string;
  orderBundleId: string;
  isProtectionPlan?: boolean;
}

type Props = ChildProps<ExternalProps, CorePostPurchaseShopNudge.Query, CorePostPurchaseShopNudge.Variables>;

export function PostPurchaseShopNudge(props: Props) {
  const eligible = !props.isProtectionPlan && !props.data?.loading && !props.data.findFavorite.isFavorite;

  if (!eligible) {
    return null;
  }

  return (
    <div className="post_purchase_shop_nudge--wrapper mb-4">
      <RCBanner
        title={I18n.t('discovery.checkout.postPurchaseShopNudge.title', { shop: props.data.shop.name })}
        content={I18n.t('discovery.checkout.postPurchaseShopNudge.subtitle')}
        image={
          <RCAvatar
            thumbnailURL={get(props.data.shop, 'user.avatar.source', null)}
            thumbnailFallback="shop"
            userName={props.data.shop.name}
          />
        }
        disableTexture= {true}
        theme="plain"
        mobileLayout="card"
        cta={<FavoriteShopCTA
          shop={props.data.shop}
          initialFavoriteId={null}
          theme="filled"
          parentComponentName= "PostPurchaseShopNudge"/>}
      />
    </div>
  );
}

const connect = withGraphql<Props>(
  gql`
    query Core_Post_Purchase_Shop_Nudge(
      $shop: String
    ) {
      shop(input: { id: $shop }) {
        _id
        ... ShopHeaderFragment
        ... FavoriteShopCTAData
      }
      findFavorite(
        input: {
          shopId: $shop
        }
      ) {
        isFavorite
        canFavorite
      }
    }
    ${ShopHeaderFragment}
    ${FavoriteShopCTAFragment}
  `,
  {
    options: (props) => ({
      ssr: true,
      variables: {
        shop: props.shopId,
      },
    }),
  },
);

export default connect(PostPurchaseShopNudge);
