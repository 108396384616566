import { ChildProps } from '@apollo/client/react/hoc';
import React, { useState, useEffect } from 'react';
import I18n from 'i18n-js';
import { CoreBumpRecommendationListing, Core_Bump_Recommendation_ListingQuery } from '@reverbdotcom/commons/src/gql/graphql';
import { fixPrecision } from './bump_helpers';
import { BumpModalBody } from './bump_modal_body';
import { BumpModalBody as ExperimentalBumpModalBody } from './BumpModalBody';
import { BUMPED_LISTING_RECOMMENDATION_QUERY, CoreBumpListingCardQueryProps } from './bump_listing_query';
import { withGraphql } from '@reverbdotcom/commons/src/with_graphql';
import ModalDialog from '@reverbdotcom/commons/src/components/modal_dialog';
import eppoFlags from '@reverbdotcom/commons/src/eppoFlags';
import { useEppoExpEnabled, useUser } from '@reverbdotcom/commons/src/user_hooks';
import { EppoSubjectTypes } from '@reverbdotcom/commons/src/eppo/eppoExperimentHelpers';
import { inMobileApp } from '@reverbdotcom/commons/src/user_context_helpers';

export interface BumpV2ModalContainerProps {
  listingId: string;
  bid: number;
  selectedBid: number;
  successMessage: string;
  failMessage: string;
  handleBidChange: (bid: number) => void;
  updateBid: (
    bid: number,
    bumpRecommendationAmount: number,
    listing: Core_Bump_Recommendation_ListingQuery['listing'],
    refetch: (newBid: number | null) => void,
  ) => void;
  refetch: (newBid: number | null) => void;
  onRequestClose?: () => void;
  /* Despite this file having Modal in its name, it is not always meant to be rendered in a modal */
  renderWithModalDialogWrapper?: boolean;
  modalIsOpen?: boolean;
  disabled?: boolean;
}

type Props = ChildProps<BumpV2ModalContainerProps &
CoreBumpListingCardQueryProps,
CoreBumpRecommendationListing.Query
>;

export function recommendationAmount(
  listing: Core_Bump_Recommendation_ListingQuery['listing'],
): number {
  return fixPrecision(listing.bumpRecommendations.bumpRecommendationAmount);
}

export function BumpV2ModalContainer(props: Props) {
  const [bidSubmitted, setBidSubmitted] = useState(false);
  const [showCustomWarning, setShowCustomWarning] = useState(false);
  const {
    successMessage,
    onRequestClose,
  } = props;
  // close the modal immediately after the bid is successfully updated
  useEffect(() => {
    if (successMessage && bidSubmitted && onRequestClose) {
      onRequestClose();
      setBidSubmitted(false);
    }
  }, [successMessage, bidSubmitted, onRequestClose]);

  const loading = props.data?.loading;
  const listing = props.data?.listing;

  if (loading || !listing) {
    return null;
  }

  const bumpRecommendationAmount = recommendationAmount(listing);

  function handleSubmit() {
    props.updateBid(
      props.selectedBid,
      bumpRecommendationAmount,
      listing,
      props.refetch,
    );

    setBidSubmitted(true);
  }

  if (!props.renderWithModalDialogWrapper) {
    return <ModalBody
      bumpRecommendationAmount={bumpRecommendationAmount}
      listing={listing}
      bid={props.bid}
      selectedBid={props.selectedBid}
      handleBidChange={props.handleBidChange}
      updateBid={props.updateBid}
      successMessage={!props.renderWithModalDialogWrapper ? props.successMessage : ''}
      failMessage={props.failMessage}
      refetch={props.refetch}
      onRequestClose={props.onRequestClose}
      renderWithModalDialogWrapper={props.renderWithModalDialogWrapper}
      handleSubmit={handleSubmit}
      showCustomWarning={showCustomWarning}
      setShowCustomWarning={setShowCustomWarning}
    />;
  }

  return (
    <ModalDialog
      isOpen={props.modalIsOpen}
      headerTitle={I18n.t('discovery.bumpModal.title')}
      onRequestClose={props.onRequestClose}
      onSubmit={handleSubmit}
      saveButtonText={I18n.t('discovery.bumpModal.save')}
      isDisabled={showCustomWarning}
      backButtonText={I18n.t('discovery.bumpModal.cancel')}
    >
      <ModalBody
        bumpRecommendationAmount={bumpRecommendationAmount}
        listing={listing}
        bid={props.bid}
        selectedBid={props.selectedBid}
        handleBidChange={props.handleBidChange}
        updateBid={props.updateBid}
        successMessage={!props.renderWithModalDialogWrapper ? props.successMessage : ''}
        failMessage={props.failMessage}
        refetch={props.refetch}
        onRequestClose={props.onRequestClose}
        renderWithModalDialogWrapper={props.renderWithModalDialogWrapper}
        handleSubmit={handleSubmit}
        showCustomWarning={showCustomWarning}
        setShowCustomWarning={setShowCustomWarning}
      />
    </ModalDialog>
  );
}

function ModalBody({
  bumpRecommendationAmount,
  listing,
  bid,
  selectedBid,
  handleBidChange,
  updateBid,
  renderWithModalDialogWrapper,
  successMessage,
  failMessage,
  refetch,
  onRequestClose,
  handleSubmit,
  showCustomWarning,
  setShowCustomWarning,
}) {
  const user = useUser();
  const expEnabled = useEppoExpEnabled({
    flagKey: eppoFlags.BUMP_MODAL_REDESIGN_WEB,
    subjectKey: user.id,
    subjectType: EppoSubjectTypes.USER_ID,
  });

  const redesignEnabled = inMobileApp(user) || expEnabled;

  if (redesignEnabled) {
    return (
      <ExperimentalBumpModalBody
        recommendationAmount={bumpRecommendationAmount}
        listing={listing}
        bid={bid}
        selectedBid={selectedBid}
        handleBidChange={handleBidChange}
        updateBid={updateBid}
        successMessage={successMessage}
        failMessage={failMessage}
        refetch={refetch}
        onRequestClose={onRequestClose}
        hideSubmit={renderWithModalDialogWrapper}
        onSubmit={handleSubmit}
        showCustomWarning={showCustomWarning}
        setShowCustomWarning={setShowCustomWarning}
      />
    );
  } else {
    return (
      <BumpModalBody
        recommendationAmount={bumpRecommendationAmount}
        listing={listing}
        bid={bid}
        selectedBid={selectedBid}
        handleBidChange={handleBidChange}
        updateBid={updateBid}
        successMessage={successMessage}
        failMessage={failMessage}
        refetch={refetch}
        onRequestClose={onRequestClose}
        hideSubmit={renderWithModalDialogWrapper}
        onSubmit={handleSubmit}
        showCustomWarning={showCustomWarning}
        setShowCustomWarning={setShowCustomWarning}
      />
    );
  }
}

const coreBumpRecommendationListingCardQuery = withGraphql<
BumpV2ModalContainerProps,
CoreBumpRecommendationListing.Query
>(
  BUMPED_LISTING_RECOMMENDATION_QUERY,
  {
    options: (ownProps) => {
      return {
        variables: {
          id: ownProps.listingId,
        },
      };
    },
  },
);

export default coreBumpRecommendationListingCardQuery(BumpV2ModalContainer);
