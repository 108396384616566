import React from 'react';

import SanitizedRender from '../../components/sanitized_render';
import LightboxImage from '../../components/lightbox_image';
import { IDynamicComponentProps } from '../dynamic_component_props';
import { imageUploadURL } from '../../url_helpers';

interface ImageData {
  caption?: string;
  image: string;
}

export interface IProps extends IDynamicComponentProps {
  images: ImageData[];
  displayAs?: string;
  jumplinkSlug?: string;
  title?: string;
  titleHtml?: string;
}

export default class ImageGallery extends React.Component<IProps, null> {
  getImageURLs() {
    return this.props.images.map(i => imageUploadURL(i.image));
  }

  getImageAlts() {
    return this.props.images.map(i => i.caption);
  }

  render() {
    return (
      <section
        className="image-gallery"
        id={this.props.jumplinkSlug}
      >
        {(!!this.props.titleHtml || !!this.props.title) &&
          <SanitizedRender
            html={this.props.titleHtml || this.props.title}
            className="image-gallery__title"
            htmlTag="h3"
          />
        }
        <div className="image-gallery__inner">
          <LightboxImage
            thumbURLs={this.getImageURLs()}
            fullsizeURLs={this.getImageURLs()}
            imageURLs={this.getImageURLs()}
            imageAlts={this.getImageAlts()}
            showAltsAsCaptions
            withControls
          />
        </div>
      </section>
    );
  }
}
