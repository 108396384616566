import React from 'react';
// TODO update this to import from commons
// import { gql } from '@reverbdotcom/commons/src/gql';
// eslint-disable-next-line no-restricted-imports
import { FetchResult, gql } from '@apollo/client';
import { useUser } from '@reverbdotcom/commons/src/user_hooks';
import { useMutation } from '@reverbdotcom/commons/src/useMutation';
import {
  CoreCheckoutProcessApprovedPaypalOrder,
  core_apimessages_Error as Error,
} from '@reverbdotcom/commons/src/gql/graphql';


interface Props {
  checkoutId: string;
  setLoading: (loading: boolean) => void;
}

export interface PaypalOrderRedirectForm {
  submit: () => Promise<FetchResult<CoreCheckoutProcessApprovedPaypalOrder.Mutation>>;
  loading: boolean;
  errors: Error[];
}

export const emptyForm = {
  submit: () => null,
  loading: false,
  errors: [],
};

export function usePaypalOrderRedirectForm({
  checkoutId,
  setLoading,
}: Props): PaypalOrderRedirectForm {
  const { loggedOut } = useUser();

  const [mutate, { loading, errors }] = useMutation<
    CoreCheckoutProcessApprovedPaypalOrder.Mutation,
    CoreCheckoutProcessApprovedPaypalOrder.Variables
  >(
    PROCESS_PAYPAL_ORDER,
  );

  const submit = React.useCallback(async () => {
    function queries() {
      if (loggedOut) {
        return ['Core_Checkout_Layout'];
      }

      return ['Core_Checkout_Layout', 'AddressBook'];
    }

    setLoading(true);

    const result = mutate({
      variables: {
        input: {
          id: checkoutId,
        },
      },
      refetchQueries: queries(),
      awaitRefetchQueries: true,
    });

    setLoading(false);

    return result;
  }, [checkoutId, loggedOut, mutate, setLoading]);

  return {
    submit,
    loading,
    errors,
  };
}

const PROCESS_PAYPAL_ORDER = gql`
  mutation Core_Checkout_ProcessApprovedPaypalOrder(
    $input: Input_core_apimessages_ProcessApprovedPaypalOrderRequest
  ) {
    processApprovedPaypalOrder(input: $input) {
      checkout {
        _id
      }
    }
  }
`;
