import React from 'react';
import {
  BarChart,
  easings,
  SeriesObject,
  BarChartData,
  BarChartOptions,
} from 'chartist';

/**
* A generic bar chart
* for usage: stats_graph.tsx
* for option examples: https://github.com/chartist-js/chartist
* chartData example:
*  {
*    labels: ['W1', 'W2', 'W3', 'W4', 'W5'],
*    series: [[1, 2, 3, 4, 5]]
*  }
* tooltipCallback expects an html string
*/

export interface IProps {
  chartData: BarChartData;
  chartOptions?: BarChartOptions;
  tooltipCallback?: (meta: SeriesObject) => string;
  animate?: boolean;
  ariaLabel: string;
}

export function buildTooltip(data, tooltipCallback) {
  if (data.type === 'bar') {
    const tooltipElements = Array.from(document.getElementsByClassName('bar-graph--tooltip') as HTMLCollectionOf<HTMLElement>);
    const [tooltip] = tooltipElements;

    const openEvents = ['mouseenter', 'focus'];
    const closeEvents = ['mouseleave', 'blur'];

    openEvents.forEach(openEvent => {
      data.element._node.addEventListener(openEvent, () => {
        const chartWidth = data.chartRect.x2 - data.chartRect.x1;

        tooltip.classList.remove('bar-graph--tooltip__hidden');
        tooltip.style.top = data.y - 50 + 'px';
        tooltip.style.left = data.x < chartWidth / 2 ? data.x + 'px' : data.x - tooltip.offsetWidth - 30 + 'px';
        tooltip.innerHTML = tooltipCallback(data);
      });
    });

    data.element._node.addEventListener('mousemove', (event) => {
      // sets the top and left position of the tooltip based on the y and x of the mouse
      tooltip.style.top = (event.originalEvent?.layerY || event.offsetY) + 40 + 'px';
      tooltip.style.left = (event.originalEvent?.layerX || event.offsetX) - 20 + 'px';
    });

    closeEvents.forEach(closeEvent => {
      data.element._node.addEventListener(closeEvent, () => {
        tooltip.classList.add('bar-graph--tooltip__hidden');
      });
    });
  }
}

function setBarWidth(data) {
  if (data.type === 'bar') {
    const widthPercentageForPadding = 0.80;
    const chartWidth = data.chartRect.x2 - data.chartRect.x1;
    const barWidth = chartWidth / data.series.length * widthPercentageForPadding;
    const maxWidth = 45;
    const width = (barWidth > maxWidth) ? maxWidth : barWidth;

    data.element.attr({
      style: `stroke-width:${width}`,
    });
  }
}

export function svgPathAnimation(data) {
  if (data.type === 'bar') {
    data.element.animate({
      y2: {
        begin: 0,
        dur: 2000,
        from: data.y1,
        to: data.y2,
        easing: easings.easeOutQuint,
      },
    });
  }
}

export function buildBarGraph({
  chartData,
  chartOptions,
  graphRef,
  tooltipCallback,
  animate = false,
}) {

  const defaultChartOptions = {
    fullWidth: true,
    axisX: {
      showGrid: true,
      showLabel: true,
    },
    axisY: {
      showGrid: true,
      showLabel: true,
    },
    classNames: {
      label: 'bar-graph__label',
      vertical: 'bar-graph__vertical',
      horizontal: 'bar-graph__horizontal',
    },
    ...chartOptions,
  };

  return new BarChart(graphRef.current, chartData, defaultChartOptions).on('draw', (data) => {
    setBarWidth(data);
    if (animate) svgPathAnimation(data);
    if (tooltipCallback) buildTooltip(data, tooltipCallback);
  });
}

export default function BarGraph({
  chartData,
  chartOptions,
  tooltipCallback,
  animate,
  ariaLabel,
}: IProps) {

  const graphRef = React.useRef();
  const [graph, setGraph] = React.useState(null);

  React.useEffect(() => {
    if (graph) {
      graph.update(chartData);
    } else {
      setGraph(buildBarGraph({
        chartData,
        chartOptions,
        graphRef,
        tooltipCallback,
        animate,
      }));
    }
  }, [chartData]);

  return (
    <div className="bar-graph-parent">
      {tooltipCallback &&
        <div className="bar-graph--tooltip bar-graph--tooltip__hidden" />
      }
      <div ref={graphRef} className="bar-graph" aria-label={ariaLabel} />
    </div>
  );
}
