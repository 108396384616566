import React from 'react';
import { withRouter, LinkProps, WithRouterProps } from 'react-router';
import track from 'react-tracking';
import { omit } from 'lodash';
import bind from '../bind';
import RouterHistory from '../cms/lib/router_history';
import PropTypes from 'prop-types';
import { debug } from '../debug';
import wrapped from '../window_wrapper';
import { inMobileApp } from '../user_context_helpers';
import { IUserContext, withUserContext, IUser } from './user_context_provider';
import classNames from 'classnames';
import { getRouteSettings } from '../route_settings';
import { ToTarget, isActive, serializeURL, parseURL, HistoryURL } from '../link_parser';
import { regionalizePath } from '../url_helpers';
import { MParticleEvent } from '../elog/mparticle_types';
import { trackEvent } from '../elog/mparticle_tracker';
import { Location } from 'history';

let alwaysClientSide = false;

/**
 * For full SPA applications, we don't need to check if
 * a given route is client side enabled, they all are.
 */
export function alwaysClientSideTransition(should: boolean) {
  alwaysClientSide = should;
}

interface TrackingProps {
  tracking?: {
    getTrackingData: () => PlainObject;
  };
}

interface ExternalProps extends LinkProps {
  to: ToTarget;
  interactionType?: string;
  entityId?: string;
  position?: number;
  trackName?: string;

  // Replace the current entry in history, instead of adding
  // use this when you want to navigate, but also want to make
  // sure we don't pollute our user's history.
  //
  // e.g. We have inset pagination of reviews on the CSP page. We
  // want to allow users to paginate or link to a specific page,
  // but we also want to make sure that when they hit back, they leave
  // the CSP page and go back to the grid.
  //
  // This option is native in later version of React Router's link, but
  // is backported here until a migration happens.
  replace?: boolean;
  clickEvent?: MParticleEvent;
}

type IProps = ExternalProps & TrackingProps & IUserContext & WithRouterProps;

/**
 * These functions will take the given route and the current routes set on the window
 * at `Reverb.Routes`, and pass them through React Router's match function. This will
 * resolve the route given the path. We can take the current window's location, check
 * to see that its route is clientSide and then compare the destination with the same
 * check.
 *
 * <Route
 *   key="feed"
 *   path="my/feed"
 *   props={{ clientSide: true }}
 *   ...
 *
 * This function returns a promise that will resolve to an array of booleans,
 * if both are true, you can issue a history.push() because your going from and
 * to a client side route. If not, just do a location.assign().
 */
export function shouldClientSideResolve(nextLocation: HistoryURL, currentLocation: Location) {
  if (nextLocation.origin) {
    // HistoryURL with an origin means it's offsite
    return false;
  }

  const toClientSide = isClientSide(nextLocation);
  const fromClientSide = isClientSide(currentLocation);

  return Promise.all([fromClientSide, toClientSide]).then(([fromC, toC]) => {
    return fromC && toC;
  });
}

async function isClientSide(location): Promise<boolean> {
  if (alwaysClientSide) {
    return true;
  }

  const settings = await getRouteSettings(location);
  if (settings.length === 0) {
    return false;
  }

  // Nested routes are returned from general to specific, check the most specific one
  return settings.pop().clientSide;
}

const NON_PASSTHROUGH_PROPS = [
  // direct props
  'to',
  'interactionType',
  'entityId',
  'position',
  'trackName',
  'replace',
  'clickEvent',

  // from context wrappers
  'tracking',
  'user',
  'router',
  'params',
  'location',
  'routes',

  // <Link> or <a> props that we may modify
  'rel',
  'className',
  'activeClassName',
  'children',
  'onClick',
];

function passThroughProps(props) {
  return omit(props, NON_PASSTHROUGH_PROPS);
}

/**
 * <CoreLink />
 *
 * Component for ALL client and server side links, this will provide tracking and
 * smart navigation from and to server or client routes. The routes must be configured
 * correctly to have client side behavior. If not they will fallback.
 *
 * ## Feature Set
 * * Is route aware, so if you tag a route with `props={{ clientSide: true }}`,
 *   CoreLink will perform a client side transition if you're on and going to a `clientSide` route
 *
 * * All clicks will be tracked using react-tracking. You can pass in overrides for the tracking if needed.
 *
 * * CoreLink will fallback to a normal link if you're in a WebView.
 *   This allows native apps to intercept native click events, and re-route to deep links if needed.
 *
 * * CoreLink accepts a `to` param, that can either be a full url, a relative url, or
 *   any more complex options that the `<Link>` component would normally
 *   accept (i.e. `{ pathname: '/foo', query: { foo: 'bar' } }`)
 *
 * * CoreLink knows when it is "active" even if its rendered outside the current router
 *   context, no need to track active state.
 *
 * * With CoreLink you should _almost_ never have to use the history object directly, just build the appropriate link.
 *
 * * There is no need to use CoreLink in LP or Sites, just use Link.  CoreLink will correctly work in full
 *   SPA applications, but it is not needed.
 */
export class InternalCoreLink extends React.Component<IProps, null> {
  static displayName = 'CoreLink';

  // Inject the router, and see if we can use it.
  static contextTypes: React.ValidationMap<any> = {
    router: PropTypes.object,
  };

  @bind
  async handleClick(evt: React.MouseEvent<HTMLAnchorElement>, url: HistoryURL, useNativeEvent: boolean) {
    const { clickEvent, onClick, tracking } = this.props;

    if (clickEvent) {
      const trackingContext = tracking.getTrackingData() || {};
      const componentName = clickEvent.componentName || trackingContext.componentName;

      trackEvent({
        ...clickEvent,
        componentName,
      });
    }

    if (onClick) { onClick(evt); }

    // If a modifier key is hit, just let the browser do its thing
    // this will allow for "normal" behavior.
    if (useNativeEvent || (evt.metaKey || evt.altKey || evt.ctrlKey)) {
      return;
    }
    evt.preventDefault();

    try {
      const { ...routerURL } = url;

      if (!routerURL.pathname && this.props.location) {
        // react-router v3 treats blank pathname as '/' :-(
        routerURL.pathname = this.props.location.pathname;
      }

      const clientSide = await shouldClientSideResolve(routerURL, this.props.location);

      if (clientSide) {

        debug(`client side transition - ${JSON.stringify(url)}`);

        if (this.props.replace) {
          RouterHistory.replace(routerURL);
        } else {
          RouterHistory.push(routerURL);
        }
      } else {
        debug(`server side transition - ${JSON.stringify(url)}`);
        wrapped.location.assign(serializeURL(url));
      }
    } catch (e) {
      debug(`could not transition - ${e}`);
      window.location.assign(serializeURL(url));
    }
  }

  stop: () => void;

  componentDidMount() {
    // If there is no router context, listen for route changes and schedule an update
    // This is mostly for category nav links, which are rendered outside of the router.
    if (!this.context.router) {
      this.stop = RouterHistory.listen(() => {
        setTimeout(() => this.forceUpdate(), 0);
      });
    }
  }

  componentWillUnmount() {
    if (this.stop) {
      this.stop();
    }
  }

  render() {
    const url = parseURL(this.props.to);
    const user: Partial<IUser> = this.props.user || {};

    const isOnsiteWebLink = (
      url &&
      !inMobileApp(user) && // Do not render client side links in Webviews, they don't trigger transitions
      !url.origin
    );

    const useNativeEvent = !!this.props.target || !isOnsiteWebLink;

    if (isOnsiteWebLink && url.pathname) {
      url.pathname = regionalizePath(url.pathname, user.urlRegion);
    }

    const active = isActive(url, this.props.location);

    const rel = classNames(
      this.props.rel,
      { noopener: this.props.target === '_blank' && !isOnsiteWebLink },
    );

    const classes = classNames(
      this.props.className,
      { [this.props.activeClassName]: this.props.activeClassName && active },
    );

    const href = serializeURL(url);

    return (
      <a
        {...passThroughProps(this.props)}
        className={classes || undefined}
        rel={rel || undefined}
        href={href}
        onClick={(evt) => { this.handleClick(evt, url, useNativeEvent); }}
      >
        {this.props.children}
      </a>
    );
  }
}

const WithHOCs = withUserContext<ExternalProps>(track({})(withRouter(InternalCoreLink)));
// Compat with tests that use string selectors. These should be replaced with `import CoreLink; find(CoreLink)`
WithHOCs.displayName = 'withUserContext(WithTracking(CoreLink))';

export default WithHOCs;
