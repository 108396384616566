import React from 'react';

import VerifyCardCVV from '@reverbdotcom/commons/src/components/verify_card_cvv';

import {
  core_apimessages_CreditCard as CreditCard,
} from '@reverbdotcom/commons/src/gql/graphql';
import { useCheckoutDisplay } from './checkoutDisplayContext';

interface Props {
  creditCard: CreditCard;
  trackEvent?: () => void;
}

export function CheckoutVerifyCardCVV({
  creditCard,
  trackEvent = null,
}: Props) {
  const checkoutDisplay = useCheckoutDisplay();

  return (
    <VerifyCardCVV
      creditCard={creditCard}
      onSubmit={() => {
        trackEvent?.();
        checkoutDisplay.setLoading(true);
      }}
      onComplete={() => checkoutDisplay.setLoading(false)}
      refetchQueryName={checkoutDisplay.layoutQueryName}
    />
  );
}
