// TODO update this to import from commons/src/gql
// eslint-disable-next-line no-restricted-imports
import { gql } from '@apollo/client';
import React from 'react';
import I18n from 'i18n-js';
import { InOtherCartsCardData } from '../gql/graphql';
import cartNudgeIcon from '@reverbdotcom/cadence/images/nudge/cart-nudge.svg';

interface IProps {
  listing: InOtherCartsCardData.Fragment;
}

export const InOtherCartsCardFragment = gql`
  fragment InOtherCartsCardData on Listing {
    _id
    id
    otherBuyersWithListingInCartCounts
  }
`;

export function InOtherCartsCardNudge(props: IProps) {
  const { otherBuyersWithListingInCartCounts } = props.listing;
  const eligible = !!otherBuyersWithListingInCartCounts &&
    otherBuyersWithListingInCartCounts > 0;

  if (!eligible) { return null; }

  return (
    <div className="listing-card-nudge">
      <img src={cartNudgeIcon} alt="InOtherCartsNudge" />
      {I18n.t(
        'commons.listingNudge.inOtherCarts',
        { count: otherBuyersWithListingInCartCounts },
      )}
    </div>
  );
}
