import React from 'react';
import I18n from 'i18n-js';
import { imageUploadURL, Paths } from '../../url_helpers';
import { RCSegmentedControl, RCTextWithIcon, useMediaQuery } from '@reverbdotcom/cadence/components';
import CMSSearchInputGroup from '../../components/cms_search_input_group';
import SanitizedRender from '../../components/sanitized_render';
import TrendingSvg from '@reverbdotcom/cadence/icons/svgs/trending-up-arrow.svg?component';
import CoreLink from '../../components/core_link';
import { MParticleEventName } from '../../elog/mparticle_types';
import { trackEvent } from '../../elog/mparticle_tracker';
import { popularSearchSampling } from './popular_searches_data';
import ReverbSiteSearch from '../../components/site_search/reverb_site_search';

interface SiteBannerData {
  toggleName?: string;
  heading: string;
  subheading?: string;
  image: string;
  backgroundImage: string;
  secondaryImage: string;
  inputPlaceholderText?: string;
  displayDynamicContent?: boolean;
  pillOne?: string;
  pillOneHtml?: string;
  pillTwo?: string;
  pillTwoHtml?: string;
  pillThree?: string;
  pillThreeHtml?: string;
}

export interface IProps {
  siteBanners: SiteBannerData[];
}

interface ToggledSiteBannerControlProps {
  siteBanners: SiteBannerData[];
  onClick: (string) => void;
  enabledSiteBannerToggleName: string;
}

interface ToggledSiteBannerPillsProps {
  siteBanner: SiteBannerData;
}

function ToggledSiteBannerControl(props: ToggledSiteBannerControlProps) {
  const isMobile = useMediaQuery('mobile');
  if (props?.siteBanners?.length <= 1) { return null; }

  function clickEvent(siteBanner) {
    props.onClick(siteBanner.toggleName);
    trackEvent({
      componentName: 'ToggledSiteBannerControl',
      eventName: MParticleEventName.ClickedSiteBannerControl,
      title: siteBanner.toggleName,
    });
  }

  return (
    <RCSegmentedControl highContrast size={isMobile ? 'small' : 'medium'}>
      { props.siteBanners.map(siteBanner => {
        return (
          <RCSegmentedControl.Button
            key={siteBanner.toggleName}
            selected={siteBanner.toggleName === props.enabledSiteBannerToggleName}
            onClick={() => clickEvent(siteBanner)}
          >
            {siteBanner.toggleName}
          </RCSegmentedControl.Button>
        );
      })}
    </RCSegmentedControl>
  );
}

function ToggledSiteBannerPills(props: ToggledSiteBannerPillsProps) {
  const pills = [
    props.siteBanner.pillOneHtml || props.siteBanner.pillOne,
    props.siteBanner.pillTwoHtml || props.siteBanner.pillTwo,
    props.siteBanner.pillThreeHtml || props.siteBanner.pillThree,
  ];

  const clickEvent = (search) => {
    return {
      componentName: 'ToggledSiteBannerPopularSearch',
      eventName: MParticleEventName.ClickedTrendingSearchPill,
      query: search,
    };
  };

  return (
    <div className="toggled-site-banner__pills">
      { props.siteBanner.displayDynamicContent ?
        <>
          { popularSearchSampling(3).map((search) => {
            return (
              <CoreLink
                key={`toggled-site-banner-dynamic-${search}`}
                className="toggled-site-banner__pill-dynamic"
                to={Paths.marketplace.expand({
                  query: search,
                  referer: 'trending_search',
                })}
                clickEvent={clickEvent(search)}
              >
                <RCTextWithIcon
                  svgComponent={TrendingSvg}
                  placement="left"
                >
                  <span className="toggled-site-banner__pill-label">{search}</span>
                </RCTextWithIcon>
              </CoreLink>
            );
          })}
        </>
        :
        <>
          { pills.map((pill, index) => {
            return (
              <SanitizedRender
                key={`toggled-site-banner-pill-${index}`}
                htmlTag="span"
                className="toggled-site-banner__pill-text"
                html={pill}
                inlineTagsOnly
              />
            );
          })}
        </>
      }
    </div>
  );
}

function BannerSiteSearch({ enabledSiteBanner, position }) {
  if (position === 0) {
    return (
      <ReverbSiteSearch
        defaultValue=""
        placeholder={enabledSiteBanner.inputPlaceholderText || I18n.t('commons.toggledSiteBanner.searchInputPlaceholder')}
        rounded
      />
    );
  } else {
    return (
      <CMSSearchInputGroup
        actionUrl={Paths.sellGear.expand({})}
        placeholder={enabledSiteBanner.inputPlaceholderText || I18n.t('commons.toggledSiteBanner.searchInputPlaceholder')}
        trackSearchFn={(query) => trackEvent({
          componentName: 'ToggledSiteBannerSearchInput',
          eventName: MParticleEventName.SiteSearch,
          query: query,
          searchUrl: Paths.sellGear.expand({}),
        })}
        rounded
      />
    );
  }
}

function ToggledSiteBanner(props: IProps) {
  const [enabledSiteBanner, setEnableSiteBanner] = React.useState(props?.siteBanners[0]);
  if (!props?.siteBanners?.length) { return null; }

  const toggleControlOnClick = (toggleName) => {
    if (!toggleName) { return; }
    const enabledBanner = props.siteBanners.find(banner => banner.toggleName === toggleName);
    if (enabledBanner === enabledSiteBanner) { return; }
    setEnableSiteBanner(enabledBanner);
  };

  const index = props.siteBanners.findIndex(banner => banner.toggleName === enabledSiteBanner.toggleName);
  return (
    <div className="toggled-site-banner"
      style={{ backgroundImage: `url(${imageUploadURL(enabledSiteBanner.backgroundImage)})` }}
    >
      <div className="toggled-site-banner__inner" >
        <div className="toggled-site-banner__mobile-image" >
          <div className="image-box">
            <img
              src={imageUploadURL(enabledSiteBanner.secondaryImage)} alt=""
            />
          </div>
        </div>
        <div className="toggled-site-banner__content">
          <ToggledSiteBannerControl
            siteBanners={props.siteBanners}
            enabledSiteBannerToggleName={enabledSiteBanner.toggleName}
            onClick={toggleControlOnClick}
          />
          <p className="toggled-site-banner__subheading">
            { enabledSiteBanner.subheading }
          </p>
          <h2 className="toggled-site-banner__heading">
            { enabledSiteBanner.heading }
          </h2>
          <div className="toggled-site-banner__search-input">
            <BannerSiteSearch position={index} enabledSiteBanner={enabledSiteBanner} />
          </div>
          <ToggledSiteBannerPills
            siteBanner={enabledSiteBanner}
          />
        </div>
        <div className="toggled-site-banner__image-container">
          <div className="image-box">
            <img src={imageUploadURL(enabledSiteBanner.image)} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ToggledSiteBanner;
