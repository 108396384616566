import '../../../vendor/jquery.hoverIntent';

window.Reverb = window.Reverb || {};

Reverb.HoverIntent = (function () {
  const OPEN_BUFFER_TIME = 100;
  const CLOSE_BUFFER_TIME = 400;

  function openDropdown() {
    Reverb.NavDropdownStore.closeAll();
    $(this).attr('data-dropdown-active', 'true');
  }

  function closeDropdown() {
    $(this).attr('data-dropdown-active', 'false');
  }

  function triggerDropdownOpen() {
    const dropdownTrigger = this;

    setTimeout(() => {
      if (dropdownTrigger.querySelector(':hover')) {
        $(dropdownTrigger).find('[data-dropdown]').trigger('open.dropdown');
      }
    }, OPEN_BUFFER_TIME);
  }

  function triggerDropdownClose() {
    const dropdownTrigger = this;

    setTimeout(() => {
      if (!dropdownTrigger.querySelector(':hover')) {
        $(dropdownTrigger).find('[data-dropdown]').trigger('close.dropdown');
      }
    }, CLOSE_BUFFER_TIME);
  }

  function clickIsInsideDropdown($target) {
    // don't close dropdown if click was inside dropdown but not a link
    // with one exception for elements explicitly set to
    // toggle dropdown closed on click

    return (
      $target.closest('[data-dropdown-trigger]').length &&
      !$target.is('a') &&
      !$target.is('[data-dropdown-close-trigger]')
    );
  }

  function handleDropdownClick(e) {
    const $target = $(e.target);
    const $dropdown = $target.closest('[data-dropdown-trigger]').find('[data-dropdown]');
    const isOpen = $dropdown.attr('data-dropdown-active') === 'true';

    if (clickIsInsideDropdown($target)) { return; }

    $dropdown.attr('data-dropdown-active', !isOpen);
  }

  function init() {
    $(document.body)

      .off('close.dropdown open.dropdown mouseenter.dropdown mouseleave.dropdown click.dropdown')

      .on('close.dropdown', '[data-dropdown]', closeDropdown)
      .on('open.dropdown', '[data-dropdown]', openDropdown)

      .on('mouseenter.dropdown', '[data-dropdown-trigger]', triggerDropdownOpen)
      .on('mouseleave.dropdown', '[data-dropdown-trigger]', triggerDropdownClose)
      .on('click.dropdown', handleDropdownClick);

    // legacy dropdown handler
    $('.dropdown:not([data-no-hover])').hoverIntent({
      over() { $(this).find('.js-dropdown-menu').show(); },
      out() { $(this).find('.js-dropdown-menu').fadeOut(150); },
      timeout: 150,
      interval: 50,
    });

    // toggle admin dropdowns on mobile
    // hack due to mobile safari's unwillingness to render anything
    // outside of an overflow:x area.
    // moves dropdown element into an element outside of the scrolling container.
    // if a dropdown is open, copies it back
    $('.admin-panel a[data-toggle="dropdown"]').off('click.dropdown').on('click.dropdown', function (e) {
      const $dropdown = $(this).closest('.dropdown').find('.dropdown-menu');
      const $menu = $('.mobile-admin-menu');

      if ($('.admin-panel .inline-list').css('overflow-y') !== 'hidden') { return; }
      e.preventDefault();

      function returnDropdownToParent() {
        $('.admin-panel')
          .find('.dropdown-toggle--open')
          .removeClass('dropdown-toggle--open')
          .after($menu.children().detach());
      }

      if ($menu.html()) {
        returnDropdownToParent();
      } else {
        $(this).addClass('dropdown-toggle--open');
        $('.mobile-admin-menu').html($dropdown.detach());
      }
    });
  }

  return {
    init,
  };
}());

$(document).ready(Reverb.HoverIntent.init);
