export default {
  profile: {
    welcomeBack: 'Welcome back, {{name}}',
    viewYourShop: 'View your shop',
    customizeYourProfile: 'Customize your profile',
  },
  earnings: {
    youveEarned: 'You\'ve earned ',
    onReverb: ' on Reverb',
    fromAccommodations: 'from {{children}} or Reverb Safe Shipping',
    reverbProtections: 'Reverb Protections',
    tooltipText: 'Your total earnings may change when Reverb finalizes new payouts.',
  },
  timePeriods: {
    this_month: 'this month',
    last_month: 'last month',
    days_7: 'in the last 7 days',
    months_3: 'in the last 3 months',
    months_6: 'in the last 6 months',
    months_12: 'in the last year',
  },
  shopStats: {
    shopEngagement: 'Shop Engagement',
    comparedToPrevious: '{{startDate}} - {{endDate}} compared to the previous period',
    orders: 'Orders',
    offers: 'Offers',
    messages: 'Messages',
    watchers: 'Watchers',
    noChange: 'no change',
  },
  feedbackAndBadges: {
    title: 'Feedback and badges',
    sellerRating: {
      reviewsFromBuyers: '{{strongOpen}}{{total}}{{strongClose}} reviews from buyers',
      percentPositive: '{{ratingPercent}}% positive',
    },
    badges: {
      preferredSeller: 'Reverb Preferred Seller',
      quickResponder: 'Quick Responder',
      quickShipper: 'Quick Shipper',
      badgeIconTitle: '{{badgeType}} Badge',
      earnBadge: 'Earn the {{badgeType}} Badge',
      completed: '{{completed}}/{{total}} Completed',
      preferredSellerRequirement: 'Preferred Sellers provide outstanding service.',
    },
  },
  bumpStats: {
    title: 'Bumped Listings',
    usedTitle: 'Used Listings',
    brandNewTitle: 'New Listings',
    spent: 'Spent',
    sales: 'Sales',
    percentageSpent: 'Bump Spend %',
    views: 'Bump Views',
  },
  respondToPeople: 'Respond to people who show interest',
  recentOrders: {
    mostRecentOrders: 'Most recent orders',
    viewAllOrders: 'View All Orders',
    addTracking: 'Add Tracking',
    orderButton: {
      reviewOrder: 'Review Order',
      getALabel: 'Get a Label',
      addTracking: 'Add Tracking',
    },
  },
  recentOffers: {
    mostRecentOffers: 'Most recent offers',
    viewAllOffers: 'View All Offers',
    offer: 'Offer: ',
    counterOffer: 'Counter offer: ',
    from: 'from {{name}}',
    in: 'in {{address}}',
    expired: 'Expired',
    expires: 'Expires {{time}}',
    respond: 'Respond',
  },
  recentMessages: {
    mostRecentMessages: 'Most recent messages',
    viewAllMessages: 'View All Messages',
    unread: 'unread',
    respond: 'Respond',
  },
  recommendedPriceDrops: {
    priceYourGear: 'Price your gear to sell',
    theseListings: 'These listings are priced above average. Competitively priced gear is more likely to sell.',
    explorePrices: 'Explore prices with the %{linkOpen}Reverb Price Guide%{linkClose}.',
    viewAll: 'View all listings priced above average',
    priceDropItem: {
      mostLikelyToSell: 'Most likely to sell for {{price}} or less',
      editPrice: 'Edit Price',
      success: 'Price successfully updated',
    },
  },
  recommendedDirectOffers: {
    sendDealsWith: 'Send targeted deals with direct offers',
    engageWatchers: 'Engage watchers and other interested buyers by setting up automated %{linkOpen}direct offers%{linkClose}.',
    viewAll: 'View all listings with direct offers tips',
    directOfferItem: {
      buyersWatching: '{{watcherCount}} buyers watching ',
      enableDirectOffers: 'Enable Direct Offers',
      manageDirectOffers: 'Manage Direct Offers',
      success: 'Direct offers enabled',
    },
  },
  draftListings: {
    readyToSell: 'Ready to sell again?',
  },
  newSellerPrompt: {
    onceYourGearSells: 'Once your gear sells, finalize it in four easy steps:',
    getReady: 'Get ready',
    shipIt: 'Ship it',
    getPaid: 'Get paid',
    leaveFeedback: 'Leave feedback',
    makeSure: 'Make sure you have {{children}}',
    aBankAccount: 'a bank account on file.',
    shipItem: 'Ship the item and add a tracking number to the order. For US sellers, {{children}}',
    youCanPurchase: 'you can purchase a shipping label from Reverb and opt for Safe Shipping.',
    earningsForFirstTime: 'Earnings for first-time sellers are processed within 5 days of the {{children}}. After your first sale, you should see your earnings in your bank within 1-5 days after your item is in transit.',
    itemsDelivery: "item's delivery",
    onceSaleComplete: 'Once the sale is complete, {{children}} describing your experience with them, which helps the Reverb community.',
    leaveFeedbackfor: 'leave feedback for your buyer',
  },
};
