import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import UrlHelpers from '../shared/url_helpers';
import Order from './order';
import SelectedOrders from './selected_orders';

const Context = {
  ShippingLabelForm: 'ShippingLabelForm',
  ManualShippingInfoForm: 'ManualShippingInfoForm',
};

class MultiOrderSelect extends React.Component {
  constructor(props) {
    super(props);
    this.toggleAllOrders = this.toggleAllOrders.bind(this);
  }

  get buyerName() {
    return this.props.relatedOrders[0].buyerName;
  }

  inputName() {
    if (this.props.context === Context.ManualShippingInfoForm) {
      return 'related_orders[]';
    }

    return 'shipping_label_form[additional_orders][]';
  }

  isSelected(order) {
    return !!this.props.selectedOrders.findOrder(order.id);
  }

  relatedOrder(order) {
    return (
      <li key={`order_${order.id}`}>
        <input
          checked={this.isSelected(order)}
          onChange={() => this.props.toggleOrderSelected(order)}
          className="boxed-list__toggle boxed-list__toggle--show-checkbox boxed-list__toggle--show-checkbox--multi-order"
          id={`order_${order.id}`}
          type="checkbox"
          value={order.id}
          name={this.inputName()}
        />
        <label
          className="boxed-list__item boxed-list__item--toggleable"
          htmlFor={`order_${order.id}`}
        >
          <div className="g-container">
            <div className="g-col-6 g-col-mobile-12">
              <h4 className="heading-4" id={`listing_${order.productId}`}>
                {order.title}
              </h4>
              <p className="mb-0">
                {I18n.t('js.dashboard.selling.shippingLabels.multiOrderSelect.buyerName', { name: order.buyerName })}
              </p>
              <p className="small color-green mb-0">
                <span className="fa fa-check mr-1" />
                {I18n.t('js.dashboard.selling.shippingLabels.multiOrderSelect.clearedForShipment')}
              </p>
            </div>
            <div className="g-col-6 g-col-mobile-12 mobile-mt-1">
              <h4>
                <a
                  id={`order_link_${order.id}`}
                  href={UrlHelpers.orderPath(order.id)}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {I18n.t('js.dashboard.selling.shippingLabels.multiOrderSelect.orderLink', { orderId: order.id })}
                </a>
              </h4>
              <p className="small mb-0">
                {I18n.t('js.dashboard.selling.shippingLabels.multiOrderSelect.orderedOnDate', { date: order.dateOrdered })}
              </p>
            </div>
          </div>
        </label>
      </li>
    );
  }

  gridClasses() {
    return classNames('g-col-mobile-12', {
      'g-col-6': this.props.context === Context.ShippingLabelForm,
      'g-col-12': this.props.context === Context.ManualShippingInfoForm,
    });
  }

  areAllOrdersSelected() {
    return this.props.relatedOrders.every(o => this.isSelected(o));
  }

  toggleAllOrders() {
    if (this.areAllOrdersSelected()) {
      this.props.removeAllRelatedOrders();
    } else {
      this.props.addAllRelatedOrders();
    }
  }

  selectAllCheckboxText() {
    if (this.areAllOrdersSelected()) {
      return I18n.t('js.dashboard.selling.shippingLabels.multiOrderSelect.deselectAll');
    }

    return I18n.t('js.dashboard.selling.shippingLabels.multiOrderSelect.selectAll');
  }

  selectAllCheckbox() {
    return (
      <div className="label-with-checkbox mt-2">
        <input
          onChange={this.toggleAllOrders}
          id="select-all-orders"
          type="checkbox"
          checked={this.areAllOrdersSelected()}
        />
        <label htmlFor="select-all-orders">
          {this.selectAllCheckboxText()}
        </label>
      </div>
    );
  }

  render() {
    if (this.props.relatedOrders.length === 0) return false;

    return (
      <div>
        <h3 className="heading-3">
          {I18n.t('js.dashboard.selling.shippingLabels.multiOrderSelect.selectOrdersHeading', { buyerName: this.buyerName })}
        </h3>
        <p className="mb-0">
          {I18n.t('js.dashboard.selling.shippingLabels.multiOrderSelect.weEmailBuyerAboutPackage')}
        </p>
        <div className="g-container">
          <div className={this.gridClasses()}>
            {this.selectAllCheckbox()}
            <ul className="mt-1">
              {this.props.relatedOrders.map(order => this.relatedOrder(order))}
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

MultiOrderSelect.Context = Context;

MultiOrderSelect.propTypes = {
  relatedOrders: PropTypes.arrayOf(PropTypes.instanceOf(Order)),
  selectedOrders: PropTypes.instanceOf(SelectedOrders),
  toggleOrderSelected: PropTypes.func,
  addAllRelatedOrders: PropTypes.func,
  removeAllRelatedOrders: PropTypes.func,
  context: PropTypes.string,
};

MultiOrderSelect.defaultProps = {
  relatedOrders: [],
  selectedOrders: new SelectedOrders(),
  toggleOrderSelected: () => true,
  addAllRelatedOrders: () => true,
  removeAllRelatedOrders: () => true,
  context: MultiOrderSelect.Context.ShippingLabelForm,
};

export default MultiOrderSelect;
