import React, { useState } from 'react';
import {
  RCAlertBox,
  RCButton,
  RCCheckbox,
  RCTextInput,
  RCTextWithIcon,
  RCLoadingBars,
  RCSelect,
  RCTextArea,
} from '@reverbdotcom/cadence/components';
import { ErrorAlertBox } from '@reverbdotcom/commons/src/ErrorAlertBox';
import { ChevronRightIcon, ChevronLeftIcon } from '@reverbdotcom/cadence/icons/react';
import AdminNoteRow from './admin_note_row';
import { gql } from '@reverbdotcom/commons/src/gql';
import { useMutation } from '@reverbdotcom/commons/src/useMutation';
import { useQuery } from '@reverbdotcom/commons/src/useQuery';
import { getPage, getTotalPages } from '../../pagination_helpers';

const FIRST_PAGE = 1;
const DEFAULT_OFFSET = '0';
export const DEFAULT_LIMIT = '5';
const DEFAULT_SORT_ORDER = 'desc';
const ORDER_LINKABLE_TYPE = 'Order';

export const DEFAULT_SEARCH_PARAMS = {
  bodyText: '',
  offset: DEFAULT_OFFSET,
  limit: DEFAULT_LIMIT,
  sortOrder: DEFAULT_SORT_ORDER,
  linkableType: ORDER_LINKABLE_TYPE,
  linkableId: '',
  ignorePinned: false,
};

export const CREATE_ADMIN_NOTE_MUTATION = gql(`
  mutation Core_CreateAdminNote($input: Input_core_apimessages_AdminNoteCreateRequest) {
    createAdminNote(input: $input) {
      id
      linkHtml
      createdAt
      createdByName
      lastEditedByName
      updatedAt
      userCanEdit
      body
      isPinned
      versions {
        id
        editedAt
        editedByName
        body
      }
    }
  }
`);

export const SEARCH_ADMIN_NOTES_QUERY = gql(`
  query adminNotesSearch($input: Input_core_apimessages_AdminNotesSearchRequest) {
    adminNotesSearch(input: $input) {
      adminNotes {
        id
        linkHtml
        createdAt
        createdByName
        lastEditedByName
        updatedAt
        userCanEdit
        body
        isPinned
        versions {
          id
          editedAt
          editedByName
          body
        }
      }
      limit
      offset
      totalResults
    }
  }
`);

interface Props {
  notableType: string;
  notableId: string;
  pageSize?: string;
}

export default function AdminNotes({
  notableType,
  notableId,
  pageSize,
}: Props) {
  const defaultSearchParams = { ...DEFAULT_SEARCH_PARAMS, notableType, notableId, limit: pageSize || DEFAULT_LIMIT };

  const [ searchText, setSearchText ] = useState('');
  const [ linkableId, setLinkableId ] = useState('');
  const [ searchSortOrder, setSearchSortOrder ] = useState(DEFAULT_SORT_ORDER);
  const [ searchParams, setSearchParams ] = useState(defaultSearchParams);
  const [ noteBody, setNoteBody ] = useState('');
  const [ isPinned, setIsPinned ] = useState(false);
  const [ ignorePinned, setIgnorePinned ] = useState(false);

  const { data: adminNotesSearchResponse, loading: loadingNotes, errors: fetchErrors } = useQuery(SEARCH_ADMIN_NOTES_QUERY, { variables: { input: searchParams } });

  const adminNotes = adminNotesSearchResponse?.adminNotesSearch?.adminNotes;

  const currentOffset = parseInt(adminNotesSearchResponse?.adminNotesSearch?.offset, 10);
  const currentLimit = parseInt(adminNotesSearchResponse?.adminNotesSearch?.limit, 10);
  const totalNotes = parseInt(adminNotesSearchResponse?.adminNotesSearch?.totalResults || '0', 10);

  const previousPage = () => {
    setSearchParams((params) => ({
      ...params,
      offset: (currentOffset - currentLimit).toString(),
    }));
  };

  const nextPage = () => {
    setSearchParams((params) => ({
      ...params,
      offset: (currentOffset + currentLimit).toString(),
    }));
  };

  const currentPage = getPage(currentOffset, currentLimit);
  const totalPages = getTotalPages(totalNotes, currentLimit);

  const isFirstPage = currentPage === FIRST_PAGE;
  const isLastPage = currentPage === totalPages;

  const setFilters = () => {
    setSearchParams({
      ...defaultSearchParams,
      bodyText: searchText,
      linkableId,
      sortOrder: searchSortOrder,
      ignorePinned,
    });
  };

  const clearFilters = () => {
    setSearchText('');
    setLinkableId('');
    setSearchSortOrder(DEFAULT_SORT_ORDER);
    setIgnorePinned(false);
    setSearchParams(defaultSearchParams);
  };

  const [ createNoteMutation, { loading: createLoading, errors }] = useMutation(CREATE_ADMIN_NOTE_MUTATION);

  async function createNote() {
    const result = await createNoteMutation({
      variables: {
        input: {
          notableType,
          notableId,
          body: noteBody,
          pinned: isPinned,
        },
      },
      refetchQueries: ['adminNotesSearch'],
      awaitRefetchQueries: true,
    });

    if (result?.data?.createAdminNote?.id) {
      setNoteBody('');
      setIsPinned(false);
      clearFilters();
    }
  }

  return (
    <>
      <h3 className="mb-4">Notes {!loadingNotes && (<>({totalNotes} total)</>)}</h3>
      {fetchErrors?.length > 0 && (
        <div className="mb-4">
          <RCAlertBox type="error">
            Unable to load notes.
          </RCAlertBox>
        </div>
      )}
      <div className="bd-1 bd-color-primary bd-radius-sm padding-2 mb-4">
        <RCTextArea
          id="noteBody"
          label=""
          name="noteBody"
          value={noteBody}
          onChange={(e) => setNoteBody(e.target.value)}
        />
        <div className="d-flex fx-align-center">
          <div className="mr-2">
            <RCButton
              size="mini"
              onClick={createNote}
              id="createNote"
              disabled={createLoading}
            >
              {createLoading ? <RCLoadingBars /> : 'Add Note'}
            </RCButton>
          </div>
          <RCCheckbox
            checked={isPinned}
            onChange={(e) => setIsPinned(e.target.checked)}
            label="Pinned"
            id="pinned"
            name="pinned"
          />
          {
            errors.length > 0 && (
              <div className="ml-2">
                <ErrorAlertBox errors={errors} />
              </div>
            )
          }
        </div>
      </div>
      <div className="bd-1 bd-color-primary bd-radius-sm padding-2 mb-4">
        <div className="d-flex fx-justify-between fx-align-center">
          <div className="d-flex fx-align-center">
            <div className="mr-8">
              <RCTextInput
                id="searchText"
                label="Search Notes"
                name="searchText"
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
              />
            </div>
            <div className="mr-8">
              <RCTextInput
                id="orderLinkableText"
                label="Order ID"
                name="orderLinkableText"
                value={linkableId}
                onChange={(e) => setLinkableId(e.target.value)}
              />
            </div>
            <div className="mr-8">
              <RCSelect
                id="sortId"
                label="Sort by"
                name="sortId"
                onChange={(e) => setSearchSortOrder(e.target.value)}
                value={searchSortOrder}
              >
                <RCSelect.Option
                  label="Newest"
                  value="desc"
                />
                <RCSelect.Option
                  label="Oldest"
                  value="asc"
                />
              </RCSelect>
            </div>
            <RCCheckbox
              checked={ignorePinned}
              onChange={(e) => setIgnorePinned(e.target.checked)}
              label="Ignore Pinned"
              id="ignorePinned"
              name="ignorePinned"
            />
          </div>
          <div className="d-flex">
            <div className="d-flex fx-align-center mr-2">
              <RCButton
                id="searchNotes"
                onClick={setFilters}
                variant="filled"
                size="small"
              >
                Search
              </RCButton>
            </div>
            <div className="d-flex fx-align-center">
              <RCButton
                id="clearFilters"
                onClick={clearFilters}
                size="small"
              >
                Clear
              </RCButton>
            </div>
          </div>
        </div>
      </div>
      <div className="table table-bordered table-striped width-100">
        <table className="width-100">
          <tbody>
            {loadingNotes && (
              <tr>
                <td colSpan={4}>
                  <div className="d-flex fx-justify-center">
                    <RCLoadingBars />
                  </div>
                </td>
              </tr>
            )}
            {!loadingNotes && (
              adminNotes?.length > 0 ? (
                adminNotes.map((note) => (
                  <AdminNoteRow
                    key={note.id}
                    note={note}
                  />
                ))
              ) : (
                <tr>
                  <td colSpan={4} className="width-100">
                    <div className="d-flex fx-justify-center">
                      No notes
                    </div>
                  </td>
                </tr>
              )
            )}
          </tbody>
        </table>
      </div>
      {(!loadingNotes && totalNotes > 0) && (
        <div className="d-flex margin-2 fx-justify-center">
          <RCButton
            id="previousPage"
            size="mini"
            onClick={previousPage}
            disabled={isFirstPage}
          >
            <RCTextWithIcon
              svgComponent={ChevronLeftIcon}
              children=""
              title="Previous Page"
            />
          </RCButton>
          <div className="ml-2 mr-2 d-flex fx-align-center">
            Page {currentPage} of {totalPages}
          </div>
          <RCButton
            id="nextPage"
            size="mini"
            onClick={nextPage}
            disabled={isLastPage}
          >
            <RCTextWithIcon
              svgComponent={ChevronRightIcon}
              children=""
              title="Next Page"
            />
          </RCButton>
        </div>
      )}
    </>
  );
}
