import React from 'react';
import CoreLink from '../core_link';
import { core_apimessages_Ad, core_apimessages_AdZone } from '../../gql/graphql';
import { useIntersectionTracking, useViewTracking } from '../../use_tracking';
import { buildAdObservedData, buildAdImpressionData, buildAdClickData } from './ad_mparticle_tracking_helpers';
import AdTag from './ad_tag';

const COMPONENT_NAME = 'AdImageFeature';

interface Props {
  ad: core_apimessages_Ad;
  adZones?: core_apimessages_AdZone[];
}

export function hasAdFeatureRequiredFields(ad: core_apimessages_Ad): boolean {
  return (
    !!(
      ad.imageAdCreative?.title &&
      ad.imageAdCreative?.ctaText &&
      ad.imageAdCreative?.ctaHref &&
      ad.imageAdCreative?.overlayImage &&
      ad.imageAdCreative?.description
    )
  );
}

// AdImageFeature VS amp-feature namespacing differences are to help circumvent ad blockers
export function AdImageFeature(props: Props) {
  const intersectionTrackingRef = useIntersectionTracking(
    buildAdObservedData(
      props.ad,
      COMPONENT_NAME,
      props.adZones,
    ),
    hasAdFeatureRequiredFields(props.ad),
  );

  useViewTracking(
    buildAdImpressionData(
      props.ad,
      COMPONENT_NAME,
      props.adZones,
    ),
    hasAdFeatureRequiredFields(props.ad),
  );

  if (!hasAdFeatureRequiredFields(props.ad)) {
    return null;
  }

  const { imageAdCreative } = props.ad;
  const clickEventData = buildAdClickData(
    props.ad,
    COMPONENT_NAME,
    props.adZones,
  );

  return (
    <div
      className="amp-feature"
      data-ad-image-feature
      ref={intersectionTrackingRef}
    >
      <div className="amp-feature__inner">
        <CoreLink
          className="amp-feature__image"
          to={imageAdCreative.ctaHref}
          style={
            {
              backgroundImage: `url('${imageAdCreative.overlayImage}')`,
              backgroundColor: imageAdCreative.backgroundColor,
            }
          }
          clickEvent={clickEventData}
        >
          {
            !imageAdCreative.hideAdCallout &&
            <div className="amp-feature__tag-callout">
              <AdTag />
            </div>
          }
        </CoreLink>
        <div className="amp-feature__text">
          <div className="amp-feature__text__inner">
            <div
              className="amp-feature__heading"
            >
              {imageAdCreative.title}
            </div>
            {imageAdCreative.subtitle && <div
              className="amp-feature__subtitle"
            >
              {imageAdCreative.subtitle}
            </div>}
            <p
              className="amp-feature__body"
            >
              {imageAdCreative.description}
            </p>
            <CoreLink
              className="button button--primary"
              to={imageAdCreative.ctaHref}
              clickEvent={clickEventData}
            >
              {imageAdCreative.ctaText}
            </CoreLink>
          </div>
        </div>
      </div>
    </div>
  );
}
