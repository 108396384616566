import React from 'react';
import StepProgressBar from '@reverbdotcom/commons/src/components/step_progress_bar';
import { core_apimessages_CheckoutPaymentMethod_Type as CheckoutPaymentMethodType } from '@reverbdotcom/commons/src/gql/graphql';
import { CONFIRMATION_STEP, CheckoutStep, PAYMENT_STEP, REVIEW_STEP, SHIPPING_STEP } from '../../lib/checkout_step';
import { withPrefix } from '@reverbdotcom/commons/src/i18n_helpers';
import { isViaPayPal } from './isViaPaypal';
import { useStandalonePayPalInlineForm } from './useStandalonePayPalInlineForm';

interface Props {
  step: CheckoutStep;
  paymentMethodType: CheckoutPaymentMethodType;
  isDigital: boolean;
}

const translate = withPrefix('discovery.checkout.checkoutProgressBar');

export function CheckoutProgressBar({ step, paymentMethodType, isDigital }: Props) {
  return (
    <StepProgressBar
      activeStep={step}
      steps={useCheckoutSteps(paymentMethodType, step, isDigital)}
    />
  );
}

function getStandalonePayPalSteps(step: CheckoutStep) {
  if (step === REVIEW_STEP) {
    return getPayPalSteps();
  } else {
    return [
      {
        name: SHIPPING_STEP,
        label: translate('shipping'),
      },
      {
        name: PAYMENT_STEP,
        label: translate('authorizePayment'),
      },
      {
        name: CONFIRMATION_STEP,
        label: translate('confirmation'),
      },
    ];
  }
}

function getPayPalSteps() {
  return [
    {
      name: SHIPPING_STEP,
      label: translate('shipping'),
    },
    {
      name: PAYMENT_STEP,
      label: translate('paypalPayment'),
    },
    {
      name: REVIEW_STEP,
      label: translate('review'),
    },
    {
      name: CONFIRMATION_STEP,
      label: translate('confirmation'),
    },
  ];
}

function useCheckoutSteps(paymentMethodType: CheckoutPaymentMethodType, step: CheckoutStep, isDigital: boolean) {
  if (useStandalonePayPalInlineForm(paymentMethodType)) {
    return getStandalonePayPalSteps(step);
  }

  if (isViaPayPal(paymentMethodType)) {
    return getPayPalSteps();
  }

  if (isDigital) {
    return [
      {
        name: SHIPPING_STEP,
        label: translate('contactInfo'),
      },
      {
        name: PAYMENT_STEP,
        label: translate('payment'),
      },
      {
        name: CONFIRMATION_STEP,
        label: translate('confirmation'),
      },
    ];
  }

  return [
    {
      name: SHIPPING_STEP,
      label: translate('shipping'),
    },
    {
      name: PAYMENT_STEP,
      label: translate('payment'),
    },
    {
      name: CONFIRMATION_STEP,
      label: translate('confirmation'),
    },
  ];
}
