import React from 'react';
import Helmet from 'react-helmet';

interface Props {
  title: string;
}

export default function PageTitle({ title }: Props) {
  if (!title) {
    return null;
  }

  return <Helmet title={`${title} | Reverb`} />;
}
