import qs from 'qs';
import { CORE_WEB_ORIGIN } from '@reverbdotcom/env';
import wrapped from './window_wrapper';

interface CoreLinkLocation {
  // The ID of the dom element we will scroll to
  anchor?: string;

  // Query params for the request
  query?: object;

  // Pathname with or without ORIGIN
  pathname?: string;
}

export type ToTarget = string | CoreLinkLocation;

/**
 * Internal representation of a CoreLink's target URL. It can be any of:
 * 1. Absolute (offsite) URL, where the origin is different from the current page (based on the CORE_WEB_ORIGIN global)
 * 2. Relative URL, with blank origin
 * 3. Anchor-only URL, with blank origin, pathname, and query. Used for Jumplinks that scroll within the same page
 */
export interface HistoryURL {
  origin?: string;
  pathname?: string;
  query: Record<string, any>;
  hash: string;
}

export function isActive(to: HistoryURL, current: Partial<Location>) {
  return (
    current && // We know what page we're on
    (!to.pathname || to.pathname === current.pathname) && // the 'to' has a matching pathname (or is anchor-only)
    to.hash === current.hash
  );
}

export function serializeURL(url: HistoryURL): string {
  if (!url) {
    return '';
  }

  const { origin, pathname, query, hash } = url;

  let search = '';

  if (query && Object.keys(query).length) {
    search = `?${qs.stringify(query)}`;
  }

  const href = `${origin || ''}${pathname || ''}${search}${hash || ''}`;

  return href;
}

// Yes this is set to the string, null in production while in RRE.
// FIXME: expose a `currentOrigin` from @reverbdotcom/env that takes into account the current app.
// This assumes it's always going to be core/reverb-ui
const currentOrigin = (wrapped.location.origin && wrapped.location.origin !== 'null') ?
  wrapped.location.origin :
  CORE_WEB_ORIGIN;

export function parseURL(to: ToTarget): HistoryURL {
  if (!to) {
    return {
      query: {},
      hash: '',
    };
  }

  if (typeof to === 'string') {
    const u = new URL(to, currentOrigin);

    const parsed: HistoryURL = {
      pathname: u.pathname,
      query: qs.parse(u.search, { ignoreQueryPrefix: true }),
      hash: u.hash,
    };

    if (u.origin !== currentOrigin) {
      parsed.origin = u.origin;
    }

    return parsed;
  }

  let hash;
  if (to.anchor) {
    hash = `#${to.anchor}`;
  }

  return {
    pathname: to.pathname,
    hash,
    query: to.query,
  };
}
