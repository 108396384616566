import React from 'react';
import { I18N as Translate } from '@reverbdotcom/commons/src/components/translate';
import { formatAmountCents } from '@reverbdotcom/commons/src/money';
import { CheckCircleIcon } from '@reverbdotcom/cadence/icons/react';
import { RCIcon } from '@reverbdotcom/cadence/components';
import { StatementProps } from './statement_props';
import StatementDetails from './statement_details';

function PaymentSuccess({
  balanceCents,
  currencyCode,
  amountPaidCents,
  totalOwedCents,
  totalPaidCents,
}: StatementProps) {

  const formattedAmountPaidCents = formatAmountCents({ amountCents: amountPaidCents, currency: currencyCode });

  return (
    <div className="scaling-padding-6 weight-bold">
      <div className="d-flex fx-justify-center">
        <div className="width-rem-5 color-green">
          <RCIcon svgComponent={CheckCircleIcon} />
        </div>
      </div>

      <div className="size-130 align-center">
        <Translate text="discovery.payStatement.paymentForm.paymentThanks" />
        <p> { formattedAmountPaidCents } </p>
      </div>

      <StatementDetails
        balanceCents={balanceCents}
        currencyCode={currencyCode}
        amountPaidCents={amountPaidCents}
        totalOwedCents={totalOwedCents}
        totalPaidCents={totalPaidCents}
      />
    </div>
  );
}

export default PaymentSuccess;
