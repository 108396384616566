import '../../../vendor/owl.carousel.min';

$(() => {
  const owlCarousel = $('[data-bind="carousel"]');


  const owlOptions = owlCarousel.data();


  const owlSettings = {
    singleItem: true,
  };

  $.extend(owlSettings, owlOptions);

  owlCarousel.owlCarousel(owlSettings);
});
