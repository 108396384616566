import PropTypes from 'prop-types';
import React from 'react';

import {
  SanitizedRender,
} from '@reverbdotcom/discovery-ui';

import { RCTooltip } from '@reverbdotcom/cadence/components';

function TooltipWrapper(props) {
  const {
    triggerHtml,
    text,
    placement,
    ariaLabel,
    removeButtonWrapper,
  } = props;

  let children = <SanitizedRender html={triggerHtml} />;

  if (!removeButtonWrapper) {
    children = (
      <button type="button" className="rc-tooltip__trigger" aria-label={ariaLabel}>
        {children}
      </button>
    );
  }

  return (
    <RCTooltip
      type="plain"
      text={text}
      placement={placement}
    >
      {children}
    </RCTooltip>
  );
}

TooltipWrapper.propTypes = {
  placement: PropTypes.oneOf(['top', 'bottom', 'left', 'right']),
  triggerHtml: PropTypes.string.isRequired, // children should be passed in as an html string
  text: PropTypes.string.isRequired,
  ariaLabel: PropTypes.string,
  removeButtonWrapper: PropTypes.bool,
};

TooltipWrapper.defaultProps = {
  removeButtonWrapper: false,
  ariaLabel: undefined,
  placement: 'top',
};

export default TooltipWrapper;
