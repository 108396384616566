import { ChildProps } from '@apollo/client/react/hoc';
// TODO update this to import from commons
// import { gql } from '@reverbdotcom/commons/src/gql';
// eslint-disable-next-line no-restricted-imports
import { gql } from '@apollo/client';
import React from 'react';
import { withGraphql } from '@reverbdotcom/commons/src/with_graphql';
import {
  CoreCreditCardWalletEditFormLayout,
} from '@reverbdotcom/commons/src/gql/graphql';
import { WithRouterProps } from 'react-router';

import { RCLoadingBars } from '@reverbdotcom/cadence/components';
import CreditCardEditForm, { CreditCardEditFormFragment } from './credit_card_edit_form';
import CreditCardDeleteButton, { CreditCardDeleteButtonFragment } from './CreditCardDeleteButton';

type Props = ChildProps<unknown, CoreCreditCardWalletEditFormLayout.Query> & WithRouterProps;

export function CreditCardWalletEditFormLayout(props: Props) {
  if (!props.data || props.data.loading) {
    return (
      <div className="d-flex fx-justify-center mtb-2">
        <RCLoadingBars />
      </div>
    );
  }

  const creditCards = props.data.me?.creditCards;
  const selectedCard = creditCards.find(card => card.id === props.params.creditCardId);
  if (!selectedCard) { return null; }

  return (
    <>
      <CreditCardEditForm
        creditCard={selectedCard}
        countries={props.data.countries.countries}
      />
      {!selectedCard.primaryBilling &&
        <CreditCardDeleteButton
          creditCard={selectedCard}
        />
      }
    </>
  );
}

const withQuery = withGraphql<Props>(
  gql`
    query Core_CreditCardWallet_EditFormLayout {
      countries {
        countries {
          _id
          countryCode
          name
          subregionRequired
          subregions {
            _id
            code
            name
          }
        }
      }
      me {
        _id
        uuid
        creditCards {
          id
          primaryBilling
        }
        ...CreditCardEditFormFields
        ...CreditCardDeleteButtonFields
      }
    }
    ${CreditCardEditFormFragment}
    ${CreditCardDeleteButtonFragment}
  `,
);

export default withQuery(CreditCardWalletEditFormLayout);
