import alt, { AltStoreModel } from '../components/alt';
import LocalesActions from './locales_actions';
import I18n from 'i18n-js';

const AVAILABLE_LOCALES = ['en', 'de', 'es', 'fr', 'nl', 'ja', 'it'];
const DEFAULT_LOCALE = I18n.defaultLocale;

class LocalesStore extends AltStoreModel {
  constructor() {
    super();
    this.resetState();

    this.bindListeners({
      handleCurrentLocaleChange: LocalesActions.SET_CURRENT_LOCALE,
    });
  }

  handleCurrentLocaleChange(locale) {
    this.currentLocale = locale;
  }

  resetState() {
    this.locales = AVAILABLE_LOCALES;
    this.currentLocale = DEFAULT_LOCALE;
  }
}

export default alt.createStore(LocalesStore, 'LocalesStore');
