import { RCBanner } from '@reverbdotcom/cadence/components';
import CoreLink from '@reverbdotcom/commons/src/components/core_link';
import React from 'react';
import DocumentBrandIcon from '@reverbdotcom/cadence/images/brand/document-orange.svg';
import I18n from 'i18n-js';
import { I18N as Translate } from '@reverbdotcom/commons/src/components/translate';
import { COUNTRY_CODES } from '../shared/constants';
import { Paths } from './shared/url_helpers';

const CA_HELP_CENTER_ARTICLE_ID = '7023355975955';
const CA_HELP_CENTER_ANCHOR = 'h_7';

const COUNTRY_CODE_TO_TITLE_MAPPING = {
  [COUNTRY_CODES.AU]: 'auTaxTitle',
  [COUNTRY_CODES.CA]: 'caTaxTitle',
  [COUNTRY_CODES.UK]: 'ukTaxTitle',
};

const COUNTRY_CODE_TO_CONTENT_MAPPING = {
  [COUNTRY_CODES.AU]: 'auAndUkContent',
  [COUNTRY_CODES.UK]: 'auAndUkContent',
};

const COUNTRY_CODE_TO_BUTTON_TEXT_MAPPING = {
  [COUNTRY_CODES.CA]: 'learnMore',
};

interface Props {
  taxType: string;
  countryCode: string;
}

export const BannerContent = ({ countryCode, taxType }) => {
  if (countryCode == COUNTRY_CODES.CA) {
    return <Translate text="discovery.internationalTaxIds.banner.caTaxContent" />;
  }

  const contentKey = COUNTRY_CODE_TO_CONTENT_MAPPING[countryCode] || 'euAddTaxId';

  return (
    <Translate text={`discovery.internationalTaxIds.banner.${contentKey}`}>
      <Translate text={`discovery.internationalTaxIds.banner.${taxType}`} />
    </Translate>
  );
};

export function InternationalTaxIdBanner({ taxType, countryCode }: Props) {
  const titleKey = COUNTRY_CODE_TO_TITLE_MAPPING[countryCode];

  const buttonTextKey = COUNTRY_CODE_TO_BUTTON_TEXT_MAPPING[countryCode] || 'buttonText';

  const link = countryCode === COUNTRY_CODES.CA ? Paths.helpCenterArticlesUrl.expand({ articleId: CA_HELP_CENTER_ARTICLE_ID, anchor: CA_HELP_CENTER_ANCHOR }) : '/my/selling/tax_policy';

  return (
    <RCBanner
      title={titleKey ? I18n.t(`discovery.internationalTaxIds.banner.${titleKey}`) : ''}
      content={
        <BannerContent
          countryCode={countryCode}
          taxType={taxType}
        />
      }
      image={DocumentBrandIcon}
      cta={<CoreLink to={link}><Translate text={`discovery.internationalTaxIds.banner.${buttonTextKey}`} /></CoreLink>}
    />
  );
}

export default InternationalTaxIdBanner;
