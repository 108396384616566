export default {
  howCanBuyersPayYou: 'How can buyers pay you?',
  yourPaymentSettings: 'Your Payment Settings',
  acceptedPaymentMethodsForm: {
    directCheckout: {
      paidToBank: 'Your earnings will be automatically sent to your bank account.',
      firstSalePayOutTimingMessageHtml: 'For your first sale, Reverb initiates a payout following confirmation your item has been delivered. For a more detailed payout timeline, please see the %{linkOpen}Reverb Payments FAQ%{linkClose}.',
    },
  },
  defaultErrorMessage: 'An error occurred. Please check again later.',
  updateYourAccount: 'Update Your Account',
  switchToDirectCheckout: 'Switch to Reverb Payments',
  whyUpgrade: 'Reach more buyers by accepting all forms of payment, receive your funds directly in your bank account, and get lower processing fees than PayPal.',
  dcPaymentMethodsHtml: '%{icon} Accept all forms of payment available to Reverb buyers.',
  dcPayoutsHtml: '%{icon} Regardless of how buyers pay, you get paid via direct deposit.',
  dcFeesHtml: '%{icon} One payment processing fee of %{fee_percent}% + %{flatAmount} for all orders. (%{intl_fee_percent}% for intl. payments)',
  dcCoveredByProtectionHtml: '%{icon} All orders covered by %{linkOpen}Reverb Protection%{linkClose}.',
  paypalFeesEURHtml: '%{icon} PayPal payment processing fee of up to 3.49% + 0.49€ (up to 4.99% for intl. payments).',
  paypalFeesGBPHtml: '%{icon} PayPal payment processing fee of up to 3.49% + 49p (up to 4.99% for intl. payments).',
  paypalFeesHtml: '%{icon} PayPal payment processing fee of 3.49% + 49¢ (up to 4.99% for intl. payments).',
  paypalFeesMayVaryHtml: '*PayPal fees can vary by location. See %{linkOpen}PayPal\'s fees page%{linkClose}',
  paypalPoliciesApplyHtml: '%{icon} PayPal\'s return window and protection policies apply.',
  paypalEmailLabel: 'PayPal Email*',
  getDirectCheckout: 'Get Reverb Payments',
  editBankAccount: 'Edit Bank Account Details',
  contactReverb: 'In order to set up Reverb Payments, you will need to contact %{linkOpen}sellerengagement@reverb.com%{linkClose}.',
  changeCurrencyHelpLink: 'Looking to change your currency? %{linkOpen}Read more in our Help Center%{linkClose}.',
  completeSetupCta: {
    title: 'Complete Reverb Payments Setup',
    completeDetails: 'Reverb Payments are active, but you need to complete your Payout Details in order to get earnings transferred to your bank account.',
    buttonText: 'Complete Payment Details',
  },
  bankTransferAmountForm: {
    submitButton: 'Submit Request',
    totalTransferableBalance: 'Total Transferable Balance (%{balance})',
    otherAmount: 'Other Amount',
  },
  directCheckoutProfile: {
    changeAccount: 'Change Reverb Payments Account',
    pleaseDoubleCheck: "Please double-check that you've entered your bank details correctly to ensure we can pay you on time.",
    save: 'Save',
    bankInfo: 'Bank Information',
    storedSecurelyAndEncryptedHtml: '%{icon} Stored securely & encrypted',
    storedSecurelyAndEncrypted: 'Stored securely & encrypted',
    useADifferentAccountHtml: '%{icon} Use a different account',
    bankChangesLockedHtml: '%{icon} To use a different account, please contact %{linkOpen} %{name} %{linkClose} to unlock this form.',
    nevermind: 'Nevermind, use the account on file',
    weNeedYourBankInfo: 'We\'ll need your bank account info to deposit your earnings. This only allows Reverb to deposit funds into your account, not withdraw them. Please double-check that your info is correct to ensure we can pay you on time.',
    bucksPayoutDescriptionHtml: 'Access your funds sooner than waiting for a bank transfer, withdraw them at any time, and get a %{strongOpen}1% discount%{strongClose} on purchases using Reverb Bucks. %{linkOpen}Learn More%{linkClose}.',
    remindMeLaterDescriptionHtml: "If you're not ready to complete this step right now, we'll send you a reminder once your first listing sells. Please note, you will not be able to withdraw any earnings until a Bank Account is linked.",
    whereShouldYourEarningsGo: 'Where should your earnings go?',
    youCanChangeThisSettingHint: '(you can change this anytime)',
    pleaseAcceptTerms: 'Please accept the terms & conditions.',
    payOutToBankAccount: 'Automatically send to Bank Account',
    payOutToReverbBucks: 'Convert to Reverb Bucks for immediate use',
    remindMeLater: 'Remind me later',
    iAcceptTheTermsAndConditionsHtml: 'I accept the %{linkOpen}terms & conditions%{linkClose}.',
    agreeToRemindMeLater: 'I understand',
    achPayoutDescription: {
      preferredSellerPayOutTimingMessage: 'Reverb initiates payouts for shipped orders each business day.',
      payOutTimingMessageHtml: 'Reverb initiates your payout after the tracking number indicates the item is in-transit. For a more detailed payout timeline, please see the %{linkOpen}Reverb Payments FAQ%{linkClose}.',
      firstSalePayOutTimingMessageHtml: 'For your first sale, Reverb initiates a payout following confirmation your item has been delivered. For a more detailed payout timeline, please see the %{linkOpen}Reverb Payments FAQ%{linkClose}.',
      setUpForAch: 'Your earnings will be automatically sent to your bank account.',
    },
    bankAccountFields: {
      nameOnAccount: 'Name on account*',
      accountType: 'Account Type*',
      checkingRadio: 'Checking',
      savingsRadio: 'Savings',
      routingNumber: 'Routing number*',
      accountNumber: 'Account number*',
      findBankNumbers: 'See where to find your bank routing & account numbers',
      exampleCheckAlt: 'Example check',
      canada: {
        transitNumber: 'Transit number* {{hint_open}}(5 digits){{hint_close}}',
        institutionNumber: 'Institution number* {{hint_open}}(3 digits){{hint_close}}',
        accountNumber: 'Account number* {{hint_open}}(up to 12){{hint_close}}',
        findBankNumbers: 'See where to find your bank transit, institution, & account numbers',
      },
      countryCodeInput: {
        bankLocation: 'Bank Location',
        change: 'Change',
      },
      currencyInput: {
        label: 'Bank Currency',
        change: 'Change',
      },
      bankTransferWarning: 'I have confirmed my bank accepts direct deposits in %{currencyCode}. If your bank does not accept %{currencySymbol}%{currencyCode}, change your %{strongOpen}Bank Currency%{strongClose} to %{defaultCurrencySymbol}%{defaultCurrencyCode}.',
      dashboardCurrencyExchangeFeeWarning: 'Your shop currency is %{shopCurrencyCode}. Using a bank currency that is different from your shop currency will result in an additional 2.5% currency conversion fee charged on the payout amount.',
    },
  },
  shopCurrency: {
    heading: 'Your shop currency is %{currencySymbol}%{currencyCode}',
    sellingFeesDescription: 'The currency you list items in.',
    sellingFeesDescriptionWithCurrencyConversion: 'The currency you list items in. Your fees will be based on the %{currencySymbol}%{currencyCode} item price converted to $USD at the time of sale.',
    currencyLabel: 'Shop Currency',
    selectAnOption: 'Select an option',
    currencyChange: {
      listingsWillUpdateHtml: 'If you save this change, we will update your listings and shipping profiles from %{strongTagOpen}%{oldCurrencyCode}%{strongTagClose} to %{strongTagOpen}%{newCurrencyCode}%{strongTagClose}. It may take up to 60 minutes for these changes to become visible on the site.',
      offersWillRemainHtml: 'All existing offers and counter-offers will remain in %{strongTagOpen}%{oldCurrencyCode}%{strongTagClose}. New offers made after the changes are complete will be in %{strongTagOpen}%{newCurrencyCode}%{strongTagClose}.',
      payoutConversionFee: 'Listing in a currency different from your bank currency will result in an additional 2.5% currency conversion fee charged on payouts.',

    },
  },
  directCheckoutSetup: {
    title: 'Reverb Payments Setup',
    subheading: 'We\'ll need your bank account information to direct deposit your earnings.',
    firstOrderPayoutHtml: '%{strongOpen}Note:%{strongClose} %{lineBreak} For your first sale, Reverb initiates your payout following delivery confirmation of the item. For a more detailed payout timeline, please see the %{linkOpen}Reverb Payments FAQ%{linkClose}.',
  },
};
