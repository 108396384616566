import React from 'react';
import I18n from 'i18n-js';
import SanitizedRender from '@reverbdotcom/commons/src/components/sanitized_render';
import { Paths } from './shared/url_helpers';
import { RCNotice } from '@reverbdotcom/cadence/components';


interface IProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: () => void;
}

export default function UserPrivacyConsentPopup({ isOpen, onClose, onSubmit }: IProps) {
  return (
    <>
      <RCNotice
        isOpen={isOpen}
        onOpenChange={(state) => {
          if (state === false) onClose();
        }}
        title={I18n.t('discovery.userPrivacyConsentModal.title')}
        actions={{
          primary: {
            buttonText: I18n.t('discovery.userPrivacyConsentModal.accept'),
            onClick: onSubmit,
            preventCloseOnClick: true,
            initialFocus: true,
          },
          secondary: {
            buttonText: I18n.t('discovery.userPrivacyConsentModal.updateSettings'),
            onClick: onClose,
            preventCloseOnClick: true,
            variant: 'transparent',
          },
        }}
      >
        <p className="mb-4">
          <SanitizedRender
            html={I18n.t(
              'discovery.userPrivacyConsentModal.consentBlurb',
              {
                cookiePolicyLinkOpen: `<a href="${Paths.cookiePolicy.expand({})}" class="text-link weight-bold">`,
                linkClose: '</a>',
              },
            )}
          />
        </p>
      </RCNotice>
    </>
  );
}
