import { ChildProps } from '@apollo/client/react/hoc';
// TODO update this to import from commons/src/gql
// eslint-disable-next-line no-restricted-imports
import { gql } from '@apollo/client';
import React from 'react';
import I18n from 'i18n-js';
import track from 'react-tracking';
import { withUserContext, IUserContext } from './user_context_provider';
import { isExperimentEnabled } from '../user_context_helpers';
import experiments from '../experiments';
import { userIsTrackable } from '../elog/mparticle';
import ListingsCollection from './listings_collection';
import { mParticleListingFields } from './with_mparticle_listings';
import { CommonsWatchedListings } from '../gql/graphql';
import { withGraphql } from '../with_graphql';
import { connect } from '../connect';
import { WatchedListingsFallback } from './watched_listings_fallback';
import { ListingsCollectionFragments } from './listings_collection_fragments';

const COMPONENT_NAME = 'WatchRow';
const MIN_COUNT = 5;
const MAX_COUNT = 8;

export interface ExternalProps {
  mainCallToAction?: React.ReactElement;
  secondaryCallToAction?: React.ReactElement;
  showFlags?: boolean;
}

type IProps = ChildProps<IUserContext & ExternalProps, CommonsWatchedListings.Query>;

export function WatchedListings(props: IProps) {
  const { data: { me, loading } } = props;
  const limit = MAX_COUNT;
  const listings = (me?.watches?.nodes ?? []).map(n => n.listing).slice(0, limit);
  const isEmptyWatchlist = !loading && !listings.length;
  const title = I18n.t('commons.watchRow.loggedIn.title');
  const minCount = MIN_COUNT;

  if (listings.length < minCount && !loading) {
    const topProductTypeUuids = me?.profile?.topProductTypeUuids || [];
    const topProductTypes = me?.profile?.topProductTypes || [];

    return (
      <WatchedListingsFallback
        emptyStateMainCallToAction={props.mainCallToAction}
        emptyStateSecondaryCallToAction={props.secondaryCallToAction}
        hasWatchedListings={!isEmptyWatchlist}
        topProductTypeUuid={topProductTypeUuids[0]}
        topProductTypeName={topProductTypes[0]}
      />
    );
  }

  return (
    <ListingsCollection
      listings={listings}
      loading={loading}
      callToAction={props.mainCallToAction}
      componentName={COMPONENT_NAME}
      title={title}
      indentedMosaic
      largeTiles={false}
      showFlags={props.showFlags}
    />
  );
}

const watchedListingsQuery = gql`
    query Commons_WatchedListings(
      $limit: Int
      $showListingLocation: Boolean!
      $shouldSkipTracking: Boolean!
    ) {
      me {
        _id
        uuid
        profile {
          topProductTypeUuids
          topProductTypes
        }
        watches(input: {
          limit: $limit
          listingsState: LIVE
        }) {
          nodes {
            _id
            listing {
              _id
              ...ListingsCollection
              ...mParticleListingFields @skip(if: $shouldSkipTracking)
              ...ShopFields @include(if: $showListingLocation)
            }
          }
        }
      }
    }
    ${ListingsCollectionFragments.listing}
    ${ListingsCollectionFragments.shopFields}
    ${mParticleListingFields}
  `;

function buildWatchedListingsVars(ownProps: IProps) {
  return {
    ssr: true,
    skip: !!ownProps.user?.loggedOut,
    variables: {
      limit: MAX_COUNT,
      showListingLocation: isExperimentEnabled(ownProps.user, experiments.LISTING_CARD_LOCATION),
      shouldSkipTracking: !userIsTrackable(),
    },
  };
}

const queryLarge = withGraphql<IProps, CommonsWatchedListings.Query, CommonsWatchedListings.Variables>(
  watchedListingsQuery,
  {
    options: buildWatchedListingsVars,
  },
);

const WatchedListingsLarge = connect<ExternalProps>([
  withUserContext,
  queryLarge,
])(track({ componentName: COMPONENT_NAME })(WatchedListings));

export default (props: IProps) => {
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <WatchedListingsLarge {...props} />;
};
