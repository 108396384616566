import React from 'react';
import CoreLink from '@reverbdotcom/commons/src/components/core_link';
import { listingItemPagePath } from '@reverbdotcom/commons/src/url_helpers';
import { useUser } from '@reverbdotcom/commons/src/user_hooks';
import { WEB } from '@reverbdotcom/commons/src/constants';

import QuickPriceEditButton, {
  VALID_LISTING_STATES_FOR_QUICK_PRICE_EDIT,
} from '../listings/quick_price_edit/quick_price_edit_button';
import SellerListingCardDetails from './SellerListingCardDetails';
import SellerListingCardFooter from './SellerListingCardFooter';
import BLANK_MEDIUM_IMAGE from '../../../images/products/blank_medium.jpg';
import PublishErrors from './PublishErrors';
import LoadingOverlay from '../../shared/loading_overlay';
import { RCCheckbox, RCImage } from '@reverbdotcom/cadence/components';
import SellerListingsCollectionContext from './SellerListingsCollectionContext';
import { SellerListing, ActionType } from './sellerListingsCollectionReducer';
import { MParticleEventName } from '@reverbdotcom/commons/src/elog/mparticle_types';
import { useViewTracking } from '@reverbdotcom/commons/src/use_tracking';
import SellerListingStats from './SellerListingStats';

interface IExternalProps {
  listing: SellerListing;
  showQuickPriceEditPopover?: boolean;
}

export const COMPONENT_NAME = 'SellerListingCard';

export default function SellerListingCard({
  listing,
  showQuickPriceEditPopover = false,
}: IExternalProps) {

  const user = useUser();
  const openNewTab = user && user.deviceName === WEB;

  const {
    listingsCollectionState,
    dispatch: selectionDispatch,
  } = React.useContext(SellerListingsCollectionContext);

  const isSelected = !!listingsCollectionState.selectedListings.find(selectedListing => selectedListing.id == listing.id);

  useViewTracking({
    eventName: MParticleEventName.ComponentView,
    componentName: COMPONENT_NAME,
    listingId: listing.id,
  }, true);

  function handleSelection() {
    if (isSelected) {
      selectionDispatch({
        type: ActionType.DESELECT_LISTING,
        payload: {
          listing,
        },
      });
    } else {
      selectionDispatch({
        type: ActionType.SELECT_LISTING,
        payload: {
          listing,
        },
      });
    }
  }

  return (
    <div className="seller-listing-card">
      <LoadingOverlay showOverlay={listing.loading}>
        <div className="seller-listing-card__content">
          <RCCheckbox
            label=""
            checked={isSelected}
            onChange={() => handleSelection()}
          />

          <div className="seller-listing-card__content__image">
            <CoreLink
              to={listingItemPagePath(listing)}
              target={openNewTab ? '_blank' : undefined}
            >
              <RCImage
                src={listing.images[0]?.source || BLANK_MEDIUM_IMAGE}
                imageBox="fullWidth"
              />
            </CoreLink>
          </div>

          <div className="d-flex fx-justify-between tablet-fx-dir-col tablet-gap-3 width-100">
            <div className="d-flex fx-dir-col gap-1 width-70 tablet-width-100">
              <CoreLink
                className="text-link weight-bold"
                to={listingItemPagePath(listing)}
                target={openNewTab ? '_blank' : undefined}
              >
                <span style={{ fontSize: 'var(--rc-font-size-400)' }}>{listing.title}</span>
              </CoreLink>

              <div className="tablet-d-none">
                <SellerListingCardDetails listing={listing} />
                <PublishErrors listing={listing} />
              </div>
            </div>

            <div className="d-flex fx-dir-col gap-1 tablet-fx-align-self-end">
              <QuickPriceEditButton
                listing={listing}
                showQuickPriceEditPopover={showQuickPriceEditPopover}
                isEditable={VALID_LISTING_STATES_FOR_QUICK_PRICE_EDIT.includes(listing.state)}
              />
            </div>
          </div>
        </div>

        <div className="tablet-pl-4 tablet-pb-4">
          <div className="d-none tablet-d-inline">
            <SellerListingCardDetails listing={listing} />
            <PublishErrors listing={listing} />
          </div>

          <div className="tablet-d-none">
            <SellerListingStats
              listing={listing}
              asRow
            />
          </div>

          <div className="d-none tablet-d-inline">
            <SellerListingStats
              listing={listing}
            />
          </div>
        </div>
        <SellerListingCardFooter listing={listing}/>
      </LoadingOverlay>
    </div>
  );
}
