import React from 'react';
import { isEqual, times } from 'lodash';
import track from 'react-tracking';
import { withRouter, WithRouterProps } from 'react-router';

import bind from '../bind';
import ListingCard from './listing_card';
import ListingRowCard, { PlaceholderListingRowCard } from './listing_row_card';
import OverflowingRow from './overflowing_row';
import {
  Core_Ad_Banner_WrapperQuery,
  ListingsCollectionFragment,
  ListingsCollection as ListingsCollectionQuery,
} from '../gql/graphql';
import { trackEvent } from '../elog/mparticle_tracker';
import { ListingsCollectionFragments } from './listings_collection_fragments';
import { MParticleEvent, MParticleEventName } from '../elog/mparticle_types';
import { IUserContext, withUserContext } from './user_context_provider';
import { AdCard } from './ads/ad_card';
import { isHomePage, listingItemPagePath, isCSP } from '../url_helpers';
import { connect } from '../connect';
import { RCListingGrid } from '@reverbdotcom/cadence/components';
import IndentedRow from '@reverbdotcom/commons/src/cms/components/indented_row';
import CoreLink from '@reverbdotcom/commons/src/components/core_link';
import MosaicListingsCollection, { MosaicPatternProps, ColSpanProps } from '@reverbdotcom/commons/src/components/mosaic_listings_collection';

function componentNameForAd(
  componentName: string,
  listingsCollectionTitle: string,
): string {
  return `${componentName}-${listingsCollectionTitle}`;
}

export interface ExternalProps {
  callToAction?: JSX.Element;
  componentName?: string;
  largeTiles?: boolean;
  listings?: ListingsCollectionFragment[];
  sponsoredListings?: ListingsCollectionFragment[];
  loading?: boolean;
  maxCount?: number;
  title?: JSX.Element | string;
  titleTooltip?: React.ReactNode;
  subtitle?: string | React.ReactNode;
  subtitleHtml?: string;
  ledeHtml?: string;
  emptyState?: JSX.Element;
  jumplinkSlug?: string;
  displayAsGrid?: boolean;
  displayAsRowCards?: boolean;
  hideMetadataAsOffer?: boolean;
  displayAddToCart?: boolean;
  displayMakeAnOffer?: boolean;
  listingsCount?: number;
  mParticleView?: MParticleEvent;
  showEstimatedMonthlyPayment?: boolean;
  ad?: Core_Ad_Banner_WrapperQuery['adServe']['ad'];
  titleString?: string;
  showFlags?: boolean;
  shouldDisplayAsLanding?: boolean;
  cspFeaturedListingId?: string;
  displayGreatValue?: boolean;
  withSidebar?: boolean;
  handleCreateWatchResult?: () => void;
  hideListingCardTitle?: boolean;
  minimalNudges?: boolean;
  showShippingDisplay?: boolean;
  indentedMosaic?: boolean;
  hideActionsOnMobile?: boolean;
  thumbnailTitleContent?: ListingsCollectionFragment[];
  displayAsGridOnMobile?: boolean;
  displayMobileMosaic?: boolean;
  mosaicListingRow?: boolean;
  mosaicPattern?: MosaicPatternProps;
  colCount?: ColSpanProps;
  includeItemListMetadata?: boolean;
  cmsComponentId?: string;
}

export type IProps = ExternalProps & IUserContext & WithRouterProps;

export class ListingsCollection extends React.Component<IProps, null> {
  static defaultProps: Partial<IProps> = {
    maxCount: 12,
    displayAsGrid: false,
    largeTiles: false,
    displayGreatValue: false,
    includeItemListMetadata: false,
  };

  static fragments = ListingsCollectionFragments;

  componentDidMount() {
    if (!this.props.loading && this.listings?.length) {
      this.trackView();
      this.trackSponsoredImpressions();
    }
  }

  componentDidUpdate(oldProps) {
    if (this.propsDidChange(oldProps)) {
      this.trackView();
      this.trackSponsoredImpressions();
    }
  }

  trackView() {
    const { mParticleView } = this.props;
    if (!mParticleView) { return; }

    trackEvent(mParticleView);
  }

  sponsoredListings() {
    return this.props.sponsoredListings ?? [];
  }

  thumbnailTitleContent() {
    return this.props.thumbnailTitleContent ?? [];
  }

  trackSponsoredImpressions() {
    const sponsoredListings = this.sponsoredListings();
    const bumpedListings = sponsoredListings.filter(l => l.bumped);

    if (!sponsoredListings.length) { return; }

    trackEvent({
      eventName: MParticleEventName.ListingImpression,
      listingIds: sponsoredListings.map(l => l.id),
      bumpedListingIds: bumpedListings.map(l => l.id),
      listings: sponsoredListings,
      componentName: this.props.componentName,
    });
  }

  propsDidChange(oldProps) {
    return !this.props.loading
      && this.props.listings?.length
      && !isEqual(this.props.listings, oldProps.listings);
  }

  get listings() {
    return (this.props.sponsoredListings || []).concat(this.props.listings || []);
  }

  shouldBeSingleRow() {
    if (this.props.displayAsRowCards) return false;
    if (this.props.displayAsGridOnMobile) return 'tabletAndDesktop';
    return !this.props.displayAsGrid;
  }

  isEmpty() {
    return !this.props.loading && !this.listings?.length;
  }

  showGreatValueForHomePage() {
    if (!this.props.location) return false;

    return isHomePage(this.props.location);
  }

  showGreatValueForCSPPage() {
    if (!this.props.location) return false;

    return this.props.displayGreatValue && isCSP(this.props.location);
  }

  @bind
  renderListingCard(listing: ListingsCollectionQuery.Fragment, idx) {
    // For tracking purposes, adjust sponsored rows positions start at 0.
    const sponsored = this.props.sponsoredListings ?? [];
    const isSponsored = sponsored.find(sponsoredListing => sponsoredListing.id == listing.id);
    const adjustedIdx = isSponsored ? idx : idx - sponsored.length;

    if (this.props.displayAsRowCards) {
      return (
        <ListingRowCard
          key={listing.id}
          listing={listing}
          position={adjustedIdx}
          loading={this.props.loading}
          hideMetadataAsOffer={this.props.hideMetadataAsOffer}
          listingsCount={this.props.listingsCount}
          showEstimatedMonthlyPayment={this.props.showEstimatedMonthlyPayment}
          componentName={this.props.componentName}
          cmsComponentId={this.props.cmsComponentId}
          cspFeaturedListingId={this.props.cspFeaturedListingId}
          displayGreatValue={this.props.displayGreatValue}
          includeItemListMetadata={this.props.includeItemListMetadata}
          titleHtmlTag={'h2'}
        />
      );
    }

    return (
      <ListingCard
        key={listing.id}
        listing={listing}
        position={idx}
        hideMetadataAsOffer={this.props.hideMetadataAsOffer}
        displayAddToCart={this.props.displayAddToCart}
        displayMakeAnOffer={this.props.displayMakeAnOffer}
        listingsCount={this.props.listingsCount}
        trackingName={this.props.componentName}
        cmsComponentId={this.props.cmsComponentId}
        showFlag={this.props.showFlags}
        watchBadgeParentComponentName={this.props.componentName}
        displayGreatValue={this.showGreatValueForHomePage() || this.showGreatValueForCSPPage()}
        handleCreateWatchResult={this.props.handleCreateWatchResult}
        minimalNudges={this.props.minimalNudges}
        showShippingDisplay={this.props.showShippingDisplay}
        hideActionsOnMobile={this.props.hideActionsOnMobile}
        includeItemListMetadata={this.props.includeItemListMetadata}
        titleHtmlTag={'h2'}
      />
    );
  }

  renderPlaceholder() {
    if (this.props.displayAsRowCards) {
      return <PlaceholderListingRowCard />;
    }
    return <ListingCard />;
  }

  renderAd() {
    if (!this.props.ad) return false;
    if (this.props.displayAsRowCards) return false;
    return (
      <AdCard
        ad={this.props.ad}
        key={this.props.ad.uuid}
        componentName={
          componentNameForAd(
            this.props.componentName,
            this.props.titleString,
          )
        }
      />
    );
  }

  gridColSpan() {
    if (this.props.displayAsRowCards) {
      return { desktop: 12 };
    }
    return { desktop: 2, tablet: 3, mobile: 5 };
  }

  renderGrid() {
    return (
      <div
        itemScope={this.props.includeItemListMetadata}
        itemType={this.props.includeItemListMetadata ? 'http://schema.org/ItemList' : null}
      >
        <RCListingGrid
          loading={this.props.loading}
          singleRow={this.shouldBeSingleRow()}
        >
          {this.renderGridItems()}
        </RCListingGrid>
      </div>
    );
  }

  renderMosaicGrid() {
    return (
      <MosaicListingsCollection
        listings={this.props.listings}
        loading={this.props.loading}
        handleCreateWatchResult={this.props.handleCreateWatchResult}
        parentComponentName={this.props.componentName}
        parentComponentId={this.props.cmsComponentId}
        displayMobileMosaic={this.props.displayMobileMosaic}
        mosaicPattern={this.props.mosaicPattern}
        colCount={this.props.colCount}
        maxCountOverride={this.props.maxCount}
      />
    );
  }

  renderEmptyGridState() {
    return (
      <RCListingGrid.Item colSpan="all">
        {this.props.emptyState}
      </RCListingGrid.Item>
    );
  }

  renderGridItems() {
    if (this.props.loading && !this.listings?.length) {
      return this.gridPlaceholders();
    }
    if (!this.listings?.length && this.props.emptyState) {
      return this.renderEmptyGridState();
    }

    return (
      <>
        {this.renderAd() &&
          <RCListingGrid.Item key={this.props.ad.uuid} colSpan={this.props.displayAsRowCards ? 'all' : 'one'}>
            {this.renderAd()}
          </RCListingGrid.Item>
        }
        {this.listings.map((listing, index) => (
          <RCListingGrid.Item
            key={listing.id}
            colSpan={this.props.displayAsRowCards ? 'all' : 'one'}
          >
            {this.renderListingCard(listing, index)}
          </RCListingGrid.Item>
        ))}
      </>
    );
  }

  gridPlaceholders() {
    return times(this.props.maxCount, index => (
      <RCListingGrid.Item
        key={index}
        colSpan={this.props.displayAsRowCards ? 'all' : 'one'}
      >
        {this.props.displayAsRowCards
          ? <PlaceholderListingRowCard />
          : <ListingCard />
        }
      </RCListingGrid.Item>
    ));
  }


  renderIndentedRow() {
    return (
      <IndentedRow
        collection={this.renderRow(this.renderMosaicGrid())}
        title={this.props.title}
        callToAction={this.props.callToAction}
      />
    );
  }

  imageSource(listing: ListingsCollectionFragment) {
    if (this.props.largeTiles || this.props.indentedMosaic) {
      return listing.largeSquareImages[0].source;
    } else {
      return listing.images[0].source;
    }
  }

  renderThumbnailTitleContent() {
    if (!this.thumbnailTitleContent().length) { return null; }

    return (
      <div className="height-rem-5 pb-2 overflow-hidden">
        {this.thumbnailTitleContent().map(listing => {
          return (
            <CoreLink
              key={listing.id}
              to={`${listingItemPagePath(listing)}`}
              target="_blank"
              clickEvent={{
                componentName: this.props.componentName,
                eventName: MParticleEventName.ClickedListingsCollectionThumbnail,
                listingId: listing.id,
              }}
            >
              <img
                src={this.imageSource(listing)}
                alt={listing.title}
                className="height-rem-5 width-rem-5 margin-1 bd-radius-md"
              />
            </CoreLink>
          );
        })}
      </div>
    );
  }

  renderTitle() {
    return (
      <>
        {this.props.title}
        {this.props.titleTooltip}
        {this.renderThumbnailTitleContent()}
      </>
    );
  }

  renderRow(grid?: JSX.Element) {
    return (
      <OverflowingRow
        title={this.renderTitle()}
        subtitle={this.props.subtitle}
        subtitleHtml={this.props.subtitleHtml}
        ledeHtml={this.props.ledeHtml}
        action={(!this.isEmpty() || this.props.emptyState) && this.props.callToAction}
        // collectionCount is not always used by implementations, so an explicit 0 here
        // is needed for OverflowingRow to know not to render controls
        collectionCount={this.listings?.length || 0}
        appendAction={this.props.displayAsGrid || this.props.displayAsRowCards}
        id={this.props.jumplinkSlug}
        shouldDisplayAsLanding={this.props.shouldDisplayAsLanding}
      >
        {grid || this.renderGrid()}
      </OverflowingRow>
    );
  }

  render() {
    if (this.isEmpty() && !this.props.emptyState) {
      return null;
    }

    if (this.props.mosaicListingRow) {
      return (this.renderRow(this.renderMosaicGrid()));
    }

    if (this.props.indentedMosaic) {
      return (this.renderIndentedRow());
    }

    return (
      this.renderRow()
    );
  }
}

// TODO - set componentName in tracking context more directly where we actually need it
const withTrackingListingsCollection = track((props: IProps) => ({ componentName: props.componentName }))(ListingsCollection);

export default connect<ExternalProps>([
  withUserContext,
  withRouter,
])(withTrackingListingsCollection);
