import React from 'react';
import {
  core_apimessages_Error,
} from '@reverbdotcom/commons/src/gql/graphql';
import { I18N as Translate } from '@reverbdotcom/commons/src/components/translate';
import BillingMethodNewCardFormFields, { BillingMethodNewCardFormProps } from '../billing_method_new_card_form_fields';
import { RCAlertBox, RCButton, RCLoadingBars } from '@reverbdotcom/cadence/components';

interface IProps extends BillingMethodNewCardFormProps {
  errors: core_apimessages_Error[];
  isDataLoading: boolean;
  isSaving: boolean;
  isSubmitDisabled: boolean;
  onSubmit(): void;
  toggleDisplayNewCardForm(): void;
}

export default function AddNewCardForm({
  billingCardAddress,
  cardholderName,
  countries,
  errors,
  isDataLoading,
  isSaving,
  isSubmitDisabled,
  onSubmit,
  setBillingCardAddress,
  setCardholderName,
  setCardState,
  toggleDisplayNewCardForm,
}: IProps) {

  return (
    <>
      <div className="scaling-padding-6">
        {isDataLoading ? (
          <div className="d-flex fx-justify-center">
            <RCLoadingBars />
          </div>
        ) : (
          <>
            { errors.length > 0 && (
              <div className="mb-4">
                <RCAlertBox
                  type="error"
                >
                  { errors.map((error => (
                    <p key={error.message}>{error.message}</p>
                  )))}
                </RCAlertBox>
              </div>
            )}
            <BillingMethodNewCardFormFields
              setCardState={setCardState}
              setCardholderName={setCardholderName}
              setBillingCardAddress={setBillingCardAddress}
              cardholderName={cardholderName}
              billingCardAddress={billingCardAddress}
              countries={countries}
              adyenCardFieldsName="pay-statement-card-fields"
            />
          </>
        )}
      </div>
      <div className="scaling-padding-6 bdt-2 bd--offwhite d-flex fx-justify-end">
        <RCButton
          onClick={toggleDisplayNewCardForm}
          variant="transparent"
        >
          <Translate text="discovery.payStatement.addNewCardForm.cancel" />
        </RCButton>
        <div className="pl-6">
          <RCButton
            variant="filled"
            disabled={isSubmitDisabled || isSaving}
            onClick={onSubmit}
          >
            {isSaving ?
              <Translate text="discovery.payStatement.addNewCardForm.cardSaving" /> :
              <Translate text="discovery.payStatement.addNewCardForm.submitCard" />
            }
          </RCButton>
        </div>
      </div>
    </>
  );
}
