import { IUser } from '@reverbdotcom/commons';
import { useUser } from '@reverbdotcom/commons/src/user_hooks';
import { AFFIRM_GLOBAL_PUBLIC_API_KEY, AFFIRM_PUBLIC_API_KEY, AFFIRM_SCRIPT_URL } from '@reverbdotcom/env';

const PUBLIC_API_KEY = {
  'US': AFFIRM_PUBLIC_API_KEY,
  'CA': AFFIRM_GLOBAL_PUBLIC_API_KEY,
};

export const AFFIRM_COUNTRY_CODES = {
  US: 'USA',
  CA: 'CAN',
};

const DEFAULT_COUNTRY_CODE = 'US';

const DEFAULT_LOCALE = 'en_US';

export interface AffirmEnvironment {
  countryCode: string;
  locale: string;
  publicAPIKey: string;
  scriptURL: string;
}

export function useAffirmEnvironment() {
  return buildAffirmEnvironment(useUser());
}

export function buildAffirmEnvironment(user: Partial<IUser>): AffirmEnvironment {
  const countryCode = user.countryCode || DEFAULT_COUNTRY_CODE;
  const apiKey = PUBLIC_API_KEY[countryCode];
  const locale = safeLocale(user);

  return {
    countryCode,
    locale,
    publicAPIKey: apiKey,
    scriptURL: AFFIRM_SCRIPT_URL,
  };
}

// Affirm only supports en_US, en_CA, and fr_CA locales
// https://docs.affirm.com/developers/v1.0-Global-Integration/docs/global-affirm-integration-guide
function safeLocale(user: Partial<IUser>) {
  if (['en-US', 'en-CA', 'fr-CA'].includes(user.fiveDigitLocale)) {
    return user.fiveDigitLocale.replace('-', '_');
  } else {
    return DEFAULT_LOCALE;
  }
}
