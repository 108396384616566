import { flattenDeep, isEmpty } from 'lodash';
import { Filter, FilterOption } from './components/search_filters';

const optionIsSelected = (option: FilterOption) => option.selected && !option.all;

const getSelectedOptionsForFilter = (filter: Filter, acc = []) => {
  if (filter.key === 'PRICE' || filter.key === 'YEAR') {
    const readableOption = filter.options.filter(option => !option.paramName && option.selected);
    if (readableOption.length) {
      acc.push({ ...readableOption[0], filter });
    }
  } else {
    filter.options.forEach(option => {
      if (optionIsSelected(option)) {
        acc.push({ ...option, filter });
      }

      if (option.subFilter && !isEmpty(option.subFilter)) {
        getSelectedOptionsForFilter(option.subFilter, acc);
      } else {
        return acc;
      }
    });
  }

  return acc;
};

export const getAllSelectedOptions = (filters: Filter[]) => {
  return flattenDeep(filters.map(f => getSelectedOptionsForFilter(f, [])));
};

export const getFiltersForSidebar = (filters: Filter[]) => {
  const excludedFilterKeys = ['SHIPS_TO', 'SORT_BY', 'TEXT_QUERY'];

  return filters.filter(f => !excludedFilterKeys.includes(f.key));
};
