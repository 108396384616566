import PropTypes from 'prop-types';
import React from 'react';
import { SortableContainer } from 'react-sortable-hoc';
import Thumb from './thumb';

class ThumbList extends React.Component {
  renderThumbs() {
    return this.props.photos.map((photo, i) => (
      <Thumb
        key={i}
        index={i}
        photo={photo}
        onEdit={() => this.props.onEditPhoto(photo)}
        onRemove={() => this.props.onRemovePhoto(photo)}
        allowEdit={this.props.allowEdit}
      />
    ));
  }

  render() {
    return (
      <div className="img-uploader__thumbs">
        {this.renderThumbs()}
        {this.props.children}
      </div>
    );
  }
}

ThumbList.propTypes = {
  children: PropTypes.node,
  photos: PropTypes.array.isRequired,
  onEditPhoto: PropTypes.func.isRequired,
  onRemovePhoto: PropTypes.func.isRequired,
  allowEdit: PropTypes.bool.isRequired,
};

export default SortableContainer(ThumbList); // eslint-disable-line new-cap
