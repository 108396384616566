import React from 'react';
import classNames from 'classnames';
import I18n from 'i18n-js';
import bind from '../bind';
import { withUserContext, IUserContext } from './user_context_provider';
import { mparticleEmailFields } from '../mparticle_email_fields_helper';
import { newsletterSignupPath } from '../url_helpers';
import { trackFacebookEvent, LEAD_EVENT, MAILING_LIST_CONTENT_NAME } from '../facebook';

import { post } from '../http_client';
import uuid from '../uuid';
import { GOOGLE_RECAPTCHA_ACTIONS } from '../constants';
import { generateRecaptchaToken } from '../generate_recaptcha_token';

interface ISubscribeRequest {
  email: string;
  consented_to_gdpr: boolean;
  signup_reason: string;
  mparticle_id: string;
  mparticle_device_id: string;
  signup_uuid: string;
  google_recaptcha_token: string;
}

interface IProps extends IUserContext {
  signupReason: string;
  listId?: string;
  title?: string;
  subtitle?: string;
  terms?: React.ReactElement;
  actionColor?: string;
  successTitle?: string;
  successMessage?: string;
}

interface IState {
  submitted: boolean;
  disableSubmit: boolean;
  error: boolean;
  invalidEmail: boolean;
  emailTooLong: boolean;
  email: string;
}

export class NewsletterSignup extends React.Component<IProps, IState> {
  static signupUuid = uuid();

  state = {
    submitted: false,
    disableSubmit: false,
    error: false,
    invalidEmail: false,
    emailTooLong: false,
    email: '',
  };

  get gdpr() {
    return this.props.user.gdpr;
  }

  get email() {
    return this.state.email;
  }

  @bind
  emailTooLong() {
    this.setState({
      submitted: false,
      disableSubmit: false,
      error: true,
      invalidEmail: false,
      emailTooLong: true,
    });
  }

  @bind
  async onSubmit(e) {
    e.preventDefault();
    if (!this.email) { return; }
    if (this.email.length > 255) {
      this.emailTooLong();
      return;
    }
    this.setState({ disableSubmit: true });

    const url = newsletterSignupPath();
    const user = this.props.user;
    const handleSuccess = () => {
      trackFacebookEvent(
        LEAD_EVENT,
        { content_name: MAILING_LIST_CONTENT_NAME },
        { eventID: NewsletterSignup.signupUuid },
      );

      this.setState({ submitted: true, disableSubmit: false, error: false });
    };

    const handleInvalidEmail = () => {
      this.setState({
        submitted: false,
        disableSubmit: false,
        error: true,
        invalidEmail: true,
        emailTooLong: false,
      });
    };

    const handleFailure = () => {
      this.setState({ submitted: false, disableSubmit: false, error: true });
    };

    const params = {
      email: this.email,
      signup_reason: this.props.signupReason,
      signup_uuid: NewsletterSignup.signupUuid,
      ...mparticleEmailFields(),
    } as ISubscribeRequest;

    if (this.gdpr) { params.consented_to_gdpr = true; }

    const token = await generateRecaptchaToken(GOOGLE_RECAPTCHA_ACTIONS.SIGNUP);

    post(url, user, {
      body: JSON.stringify(params),
      headers: {
        'content-type': 'application/json',
        'X-Google-Recaptcha-Token': token,
      },
    }).then((res) => {
      if (res.ok) {
        handleSuccess();
      } else {
        handleInvalidEmail();
      }
    }).catch(() => {
      handleFailure();
    });
  }

  @bind
  onInputChange(e) {
    this.setState({ email: e.target.value });
  }

  renderSuccess() {
    return (
      <div data-success className="padding-2 bg-white color-gray">
        <h3 className="size-120">
          {this.props.successTitle || I18n.t('commons.newsletterSignup.successTitle')}
        </h3>
        <p className="mb-0">
          {this.props.successMessage || I18n.t('commons.newsletterSignup.successMessage')}
        </p>
      </div>
    );
  }

  renderErrorMessage() {
    if (!this.state.error) { return; }

    let message;

    if (this.state.emailTooLong) {
      message = 'emailTooLongMessage';
    } else if (this.state.invalidEmail) {
      message = 'invalidEmailMessage';
    } else {
      message = 'errorMessage';
    }

    return (
      <div data-error className="weight-bold mtb-1 size-80">
        {I18n.t(`commons.newsletterSignup.${message}`)}
      </div>
    );
  }

  renderTerms() {
    return (
      <div className="newsletter-signup__fineprint">
        {this.props.terms}
        {!this.props.terms &&
          I18n.t(
            this.gdpr ? 'commons.newsletterSignup.gdprTerms' : 'commons.newsletterSignup.terms',
            { buttonText: I18n.t('commons.newsletterSignup.ctaNewsletter') },
          )
        }
      </div>
    );
  }

  renderForm() {
    if (this.state.submitted) {
      return this.renderSuccess();
    }

    const buttonClasses = classNames(
      'input-group__button',
      { 'button--primary': this.props.actionColor === 'orange' },
    );

    return (
      <form onSubmit={this.onSubmit}>
        <h3 className="newsletter-signup__heading">
          {this.props.title || I18n.t('commons.newsletterSignup.headingNewsletter')}
        </h3>
        {this.props.subtitle &&
          <p className="newsletter-signup__subheading">
            {this.props.subtitle}
          </p>
        }
        {this.renderErrorMessage()}
        <div className="input-group">
          <input
            type="email"
            name="email"
            className="input-group__input"
            placeholder={I18n.t('commons.newsletterSignup.placeholder')}
            onChange={this.onInputChange}
          />

          {/* nasty class stacking here, temporary until we enhance the sass-core styles */}
          <button
            type="submit"
            disabled={this.state.disableSubmit}
            className={buttonClasses}
          >
            {I18n.t('commons.newsletterSignup.ctaNewsletter')}
          </button>
        </div>
        {this.renderTerms()}
      </form>
    );
  }

  render() {
    return (
      <div className="newsletter-signup">
        {this.renderForm()}
      </div>
    );
  }
}

export default withUserContext(NewsletterSignup);
