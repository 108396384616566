import { PROD_MODE } from '@reverbdotcom/env';

/**
 * Useful for marking certain components or functions as deprecated. This will show up
 * in dev logs, but not in production.
 */
export function deprecate(message: string): void {
  if (!PROD_MODE) {
    console.warn(`DEPRECATED: ${message}`);
  }
}

/**
 * Adds a @deprecated('your message here') decorator that you can add
 * to class bound functions.
 */
export function deprecated(message: string) {
  return function withDeprecationWarning(_target, propertyKey, descriptor): any {
    const original = descriptor.value;
    // eslint-disable-next-line
    descriptor.value = function(...args) {
      deprecate(`${propertyKey} - ${message}`);
      return original.apply(this, args);
    };
  };
}
