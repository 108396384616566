import React from 'react';
import I18n from 'i18n-js';

import { RCRadioGroup } from '@reverbdotcom/cadence/components';

interface Props {
  willPayImmediately: boolean;
  setWillPayImmediately: (any) => void;
  defaultToImmediatePayment?: boolean;
}

export function PayImmediatelyRadioGroup({ willPayImmediately, setWillPayImmediately, defaultToImmediatePayment = false }: Props) {
  const payImmediatelyOption = (
    <RCRadioGroup.Option
      label={I18n.t('commons.offers.immediatePayment.options.immediately.label')}
      sublabel={I18n.t('commons.offers.immediatePayment.options.immediately.sublabel')}
      key="true"
      value="true"
    />
  );
  const standardOption = (
    <RCRadioGroup.Option
      label={I18n.t('commons.offers.immediatePayment.options.standard.label')}
      key="false"
      value="false"
    />
  );

  let options;
  if (defaultToImmediatePayment) {
    options = [payImmediatelyOption, standardOption];
  } else {
    options = [standardOption, payImmediatelyOption];
  }

  return (
    <RCRadioGroup
      id="payImmediately"
      name="payImmediately"
      label={I18n.t('commons.offers.immediatePayment.heading')}
      value={willPayImmediately.toString()}
      onChange={() => { setWillPayImmediately(!willPayImmediately); }}
    >
      {options}
    </RCRadioGroup>
  );
}

export default PayImmediatelyRadioGroup;
