import CSPActions from '../components/discovery/csp/csp_actions';
import CategoriesStore from '../components/discovery/stores/categories_store';
import CategoriesActions from '../components/discovery/actions/categories_actions';
import { get, isEqual } from 'lodash';

function buildParams(state) {
  return {

    ...(get(state, 'location.query') || {}),
    ...(get(state, 'params') || {}),
  };
}

export default {
  fetchCSPs(newState, oldState = {}) {
    const oldParams = buildParams(oldState);
    const newParams = buildParams(newState);

    if (isEqual(oldParams, newParams)) {
      return;
    }

    const requestParams = {

      ...newParams,
      page: newParams.page || 1,
      per_page: 30,
      sort: 'title',
      featured: true,
    };
    CSPActions.fetch(requestParams);
  },

  fetchCategories() {
    const currentState = CategoriesStore.getState();

    if (!currentState.categories.length) {
      CategoriesActions.fetch();
    }
  },
};
