import React from 'react';
import classNames from 'classnames';
import I18n from 'i18n-js';
import bind from '../bind';

export interface IProps {
  countThreshold?: number;
  // if this is true, make sure to render this component
  // inside a <table> and also that its children are all
  // <tr> elements.
  renderAsTableBody?: boolean;
  mobileOnly?: boolean;
  renderInFilterList?: boolean;
}

export interface IState {
  open: boolean;
}

export default class CollapsingList extends React.Component<IProps, IState> {
  static defaultProps = {
    countThreshold: 6,
  };

  state = {
    open: false,
  };

  shouldCollapse() {
    return React.Children.toArray(this.props.children).length > this.props.countThreshold;
  }

  shouldShowItem(idx) {
    return this.state.open || idx < this.props.countThreshold;
  }

  @bind
  toggleList(event) {
    if (event) event.preventDefault();

    this.setState({ open: !this.state.open });
  }

  linkText() {
    return this.state.open ? 'collapse' : 'showMore';
  }

  renderToggleButton() {
    return (
      <button
        className="collapsing-list__toggle"
        onClick={this.toggleList}
      >
        {I18n.t(`commons.collapsingList.${this.linkText()}`)}
      </button>
    );
  }

  renderToggleItem() {
    if (!this.shouldCollapse()) {
      return null;
    }

    if (this.props.renderAsTableBody) {
      return (
        <tr className="collapsing-list__item">
          <td colSpan={6}>
            {this.renderToggleButton()}
          </td>
        </tr>
      );
    }

    return (
      <li className="collapsing-list__item">
        {this.renderToggleButton()}
      </li>
    );
  }

  renderItems() {
    return React.Children.toArray(this.props.children).map((child, idx) => {
      const classes = classNames(
        'collapsing-list__item',
        { 'collapsing-list__item--should-collapse': !this.shouldShowItem(idx) },
      );

      if (this.props.renderAsTableBody) {
        return (
          <tr className={classes} key={idx}>
            {child}
          </tr>
        );
      }

      return (
        <li className={classes} key={idx}>
          {child}
        </li>
      );
    });
  }

  render() {
    const classes = classNames(
      'collapsing-list',
      { 'collapsing-list--collapsed': !this.state.open },
      { 'collapsing-list--mobile-only': this.props.mobileOnly },
      { 'collapsing-list--in-filter-list': this.props.renderInFilterList },
    );

    if (this.props.renderAsTableBody) {
      return (
        <tbody className={classes}>
          {this.renderItems()}
          {this.renderToggleItem()}
        </tbody>
      );
    }

    return (
      <ul className={classes} role="group">
        {this.renderItems()}
        {this.renderToggleItem()}
      </ul>
    );
  }
}
