import React from 'react';
import { Link } from 'react-router';
import { Location } from 'history';

import { withUpdateURL, IUpdateURLProps } from './with_update_url';
import { changeStickyItemRegion } from '../change_sticky_item_region';
import { trackEvent } from '../elog/mparticle_tracker';
import { MParticleEvent } from '../elog/mparticle_types';
import { ToTarget } from '../link_parser';

interface IProps extends React.LinkHTMLAttributes<Link> {
  params?: PlainObject;
  activeClassName?: string;
  onlyActiveOnIndex?: boolean;
  trackInteraction?: Function;
  handleClickCallback?: Function;
  anchor?: string;
  clickEvent?: MParticleEvent;
  directLinkOverride?: ToTarget
}

/**
 * DEPRECATED
 * Please use CoreLink instead of FilterLink
 */
export function FilterLink(props: IProps & IUpdateURLProps) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { anchor, params, updateUrl, onClick, clickEvent, trackInteraction, handleClickCallback, directLinkOverride, ...linkProps } = props;

  function handleClick(e) {
    if (onClick) onClick(e);

    if (handleClickCallback) {
      props.handleClickCallback(e, params.page);
    }

    if (trackInteraction) {
      props.trackInteraction(params.page);
    }

    if (clickEvent) {
      const { componentName } = clickEvent;

      trackEvent({
        ...clickEvent,
        componentName,
      });
    }

    if (params.item_region) {
      changeStickyItemRegion(params.item_region, e.target.href);
    }
  }

  function linkLocation(location: Location) {
    return updateUrl({ location, newParams: params, anchor });
  }

  return (
    <Link onClick={handleClick} {...linkProps} to={directLinkOverride || linkLocation} />
  );
}

export default withUpdateURL(FilterLink);
