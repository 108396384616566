import moment from 'moment';
import I18n from 'i18n-js';

const THIS_MONTH = 'this_month';
const LAST_MONTH = 'last_month';
const DAYS_7 = 'days_7';
export const MONTHS_3 = 'months_3';
const MONTHS_6 = 'months_6';
const MONTHS_12 = 'months_12';

const VALID_TIME_PERIODS = [
  DAYS_7,
  THIS_MONTH,
  LAST_MONTH,
  MONTHS_3,
  MONTHS_6,
  MONTHS_12,
];

const DEFAULT_PERIOD = DAYS_7;

const DATE_FORMAT = 'MMMM';

// A frontend reflection of CORE TimePeriod class.
// In: app/view_models/dashboard/selling/shop_stats/time_period.rb
export function formatDateSelection(timePeriodString) {
  let startDate = moment();
  let endDate = moment();

  switch (timePeriodString) {
    case DAYS_7:
      startDate = startDate.subtract(6, 'days').startOf('day');
      break;
    case THIS_MONTH:
      startDate = startDate.startOf('month');
      break;
    case LAST_MONTH:
      startDate = startDate.subtract(1, 'months').startOf('month');
      endDate = endDate.subtract(1, 'months').endOf('month');
      break;
    case MONTHS_3:
      startDate = startDate.subtract(2, 'months').startOf('month');
      break;
    case MONTHS_6:
      startDate = startDate.subtract(5, 'months').startOf('month');
      break;
    case MONTHS_12:
      startDate = startDate.subtract(11, 'months').startOf('month');
      break;
    default:
      startDate = startDate.subtract(6, 'days').startOf('day');
      break;
  }

  return { startDate, endDate };
}

function currentMonthName() {
  return moment().format(DATE_FORMAT);
}

function previousMonthName() {
  return moment().subtract(1, 'months').format(DATE_FORMAT);
}

class TimePeriods {
  constructor(timePeriodString) {
    this.rawTimePeriodString = timePeriodString;
  }

  get timePeriodString() {
    if (VALID_TIME_PERIODS.indexOf(this.rawTimePeriodString) === -1) {
      return DEFAULT_PERIOD;
    }

    return this.rawTimePeriodString;
  }

  get isThisMonth() {
    return this.timePeriodString === THIS_MONTH;
  }

  get isLastMonth() {
    return this.timePeriodString === LAST_MONTH;
  }

  get isLast12Months() {
    return this.timePeriodString === MONTHS_12;
  }

  get title() {
    if (this.isThisMonth) {
      return currentMonthName();
    } if (this.isLastMonth) {
      return previousMonthName();
    }

    return I18n.t(`shopStats.timePeriods.${this.timePeriodString}`);
  }

  get previousDataLabel() {
    if (this.isThisMonth) {
      return I18n.t('shopStats.timePeriods.lastMonthTotal');
    }

    if (this.isLastMonth) {
      return I18n.t('shopStats.timePeriods.previousMonth');
    }
    return I18n.t('shopStats.timePeriods.previousPeriod');
  }

  get options() {
    return VALID_TIME_PERIODS.map(period =>
      ({ display: I18n.t(`shopStats.timePeriods.${period}`), value: period }));
  }
}

export default TimePeriods;
