import { ChildProps } from '@apollo/client/react/hoc';
// TODO update this to import from commons
// import { gql } from '@reverbdotcom/commons/src/gql';
// eslint-disable-next-line no-restricted-imports
import { gql } from '@apollo/client';
import React from 'react';
import { withGraphql } from '@reverbdotcom/commons/src/with_graphql';
import {
  Core_CreditCardWallet_CreateFormLayoutQuery,
} from '@reverbdotcom/commons/src/gql/graphql';
import CreditCardWalletCreateForm, { CreditCardWalletCreateFormFragment } from './credit_card_wallet_create_form';
import { RCLoadingBars } from '@reverbdotcom/cadence/components';
import { useCreditCardForm } from './credit_card_form_context';

type Me = Core_CreditCardWallet_CreateFormLayoutQuery['me'];

type Props = ChildProps<unknown, Core_CreditCardWallet_CreateFormLayoutQuery>;

export function CreditCardWalletCreateFormLayout(props: Props) {
  const form = useCreditCardForm();

  if (!props.data || props.data.loading) {
    return (
      <div className="d-flex fx-justify-center mtb-2">
        <RCLoadingBars />
      </div>
    );
  }

  const defaultBillingAddress = findPrimaryAddress(
    props.data.me,
  );

  return (
    <CreditCardWalletCreateForm
      form={form}
      defaultBillingAddress={defaultBillingAddress}
      countries={props.data.countries.countries}
    />
  );
}

function findPrimaryAddress(me: Me) {
  const shippingAddresses = me?.shippingAddresses || [];
  const shippingAddress = shippingAddresses.find(address => address.primary);

  return shippingAddress || {};
}

const withQuery = withGraphql<Props>(
  gql`
    query Core_CreditCardWallet_CreateFormLayout {
      countries {
        countries {
          _id
          countryCode
          name
          subregionRequired
          subregions {
            _id
            code
            name
          }
        }
      }
      me {
        _id
        uuid
        shippingAddresses {
          _id
          primary
        }
        ...CreditCardWalletCreateFormFields
      }
    }
    ${CreditCardWalletCreateFormFragment}
  `,
);

export default withQuery(CreditCardWalletCreateFormLayout);
