// TODO update this to import from commons
// import { gql } from '@reverbdotcom/commons/src/gql';
// eslint-disable-next-line no-restricted-imports
import { gql } from '@apollo/client';
import React from 'react';
import I18n from 'i18n-js';
import * as elog from '@reverbdotcom/commons/src/elog';
import { connect } from '@reverbdotcom/commons/src/connect';
import { withGraphql } from '@reverbdotcom/commons/src/with_graphql';
import Modal from '@reverbdotcom/commons/src/components/modal';
import { useHOCMutation, MutationFunction } from '@reverbdotcom/commons/src/useHOCMutation';
import { ExecutionResult } from 'graphql';
import { UpsertMyFavorite, CoreFindFavoriteProviderUpsertMyFavorite, DeleteMyFavorite } from '@reverbdotcom/commons/src/gql/graphql';
import { withRouter, WithRouterProps } from 'react-router';
import classNames from 'classnames';
import { RCButton, RCSwitch, RCTextWithIcon } from '@reverbdotcom/cadence/components';
import { DeleteMyFavoriteMutationFn, useDeleteMyFavorite, withDeleteMyFavoriteMutation } from './delete_my_favorite_mutation';
import { MParticleEventName } from '@reverbdotcom/commons/src/elog/mparticle_types';
import { useViewTracking } from '@reverbdotcom/commons/src/use_tracking';
import { ExperimentGroupValue } from '@reverbdotcom/commons/src/components/experiments/experiment_group';
import { HeartFilledIcon } from '@reverbdotcom/cadence/icons/react';

export type FavoriteToggleTypes = 'emailEnabled' | 'feedEnabled' | 'favorited';
export type FavoriteModalToggleHandler = (key: FavoriteToggleTypes, value: boolean, isExperimentalModal: boolean) => void;
interface ExternalProps {
  isOpen: boolean;
  handleClose: Function;
  handleUpdate: FavoriteModalToggleHandler;
  modalTitle: string;
  searchTitle: string;
  feedEnabled: boolean;
  emailEnabled: boolean;
  queryParams: string;
  subtitle?: string;
  handleMutationResult?: (result: ExecutionResult<UpsertMyFavorite.Mutation>) => void;
  withRemove?: boolean;
  handleRemove?: (result: ExecutionResult<DeleteMyFavorite.Mutation>) => void;
  favoriteId?: string;
  refetchQueryName?: string;
  expGroup: ExperimentGroupValue;
  expDesc: string;
}

interface ApolloProps {
  upsertMyFavorite: MutationFunction<UpsertMyFavorite.Mutation, UpsertMyFavorite.Variables> | MutationFunction<CoreFindFavoriteProviderUpsertMyFavorite.Mutation, CoreFindFavoriteProviderUpsertMyFavorite.Variables>;
  deleteMyFavorite: DeleteMyFavoriteMutationFn;
}

type IProps = ExternalProps & ApolloProps & WithRouterProps;

export function FavoriteModal(props: IProps) {
  const [upsertFavorite] = useHOCMutation(props.upsertMyFavorite);
  const { deleteMyFavorite } = useDeleteMyFavorite(props.deleteMyFavorite, props.favoriteId, props.refetchQueryName);
  const { expGroup, expDesc } = props;
  const experimentalModal = expGroup > 0;
  const controlModal = !experimentalModal;
  const i18nKey = controlModal ? 'discovery.favorites.confirmationModal' : 'discovery.favorites.confirmationModalExp';

  useViewTracking({
    eventName: MParticleEventName.ComponentView,
    componentName: 'FavoriteModal',
    experiments: expDesc,
  }, expGroup >= 0);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const result = await upsertFavorite({
        variables: {
          input: {
            queryParams: props.queryParams,
            feedEnabled: props.feedEnabled,
            emailEnabled: props.emailEnabled,
          },
        },
      });

      if (props.handleMutationResult) {
        props.handleMutationResult(result);
      }

      props.handleClose();
    } catch (e) {
      elog.error(
        e.message,
        { componentName: 'FavoriteSearchesModal' },
        e,
      );
    }
  };

  const handleDelete = async (e) => {
    e.preventDefault();
    try {
      const result = await deleteMyFavorite();
      props.handleClose();
      props.handleRemove(result);
    } catch (e) {
      elog.error(
        e.message,
        { componentName: 'FavoriteModal' },
        e,
      );
    }
  };

  const handleClose = (e) => {
    if (e) { e.preventDefault(); }
    props.handleClose();
  };

  const renderSubtitle = (subtitleClass: string, isExperimentalModal: boolean) => {
    const searchTitle = isExperimentalModal ? props.searchTitle?.replace(/"/g, '') : props.searchTitle;
    return (
      searchTitle &&
        <div className={subtitleClass}>
          {I18n.t(`${i18nKey}.firstToKnowSubtitle`, { title: searchTitle })}
        </div>
    );
  };

  const headerClass = classNames(
    'favorite-modal__shoppability-header',
    {
      'favorite-modal__shoppability-header__experimental': experimentalModal,
    },
  );
  const actionsClass = classNames(
    'favorite-modal__actions',
    {
      'favorite-modal__actions__experimental': experimentalModal,
    },
  );
  const subtitleClass = classNames(
    'favorite-modal__header__shoppability-follow-title',
    {
      'favorite-modal__header__shoppability-follow-title__experimental': experimentalModal,
    },
  );

  const displayFilters = controlModal || !!props.subtitle;
  const filtersLabel = props.subtitle ? I18n.t(`${i18nKey}.filters`) : I18n.t(`${i18nKey}.noFilters`);

  const displayEmailEnabledToggle = experimentalModal || props.feedEnabled;

  const displayNewlyListed = !props.emailEnabled;

  return (
    <Modal
      isOpen={props.isOpen}
      subClass="feed"
      onRequestClose={handleClose}
    >
      <div className={headerClass}>
        <h1>{props.modalTitle}</h1>
        {renderSubtitle(subtitleClass, experimentalModal)}
      </div>

      {displayFilters &&
        <div className="favorite-modal__shoppability-subsection">
          <h1>{filtersLabel}</h1>
          <div className={controlModal ? 'favorite-modal__header__shoppability-text' : subtitleClass}>
            {props.subtitle}
          </div>
        </div>
      }

      <div className="favorite-modal__shoppability-subsection favorite-modal__shoppability-subsection-options">
        <h1>{I18n.t(`${i18nKey}.savedSearchFeed`)}</h1>
        {experimentalModal &&
          <div className={subtitleClass}>
            {I18n.t(`${i18nKey}.savedSearchFeedSubtitle`)}
          </div>
        }

        <div className="favorite-modal__option">
          <RCSwitch
            checked={props.feedEnabled}
            id="add-to-feed-alert-toggle"
            label={I18n.t(`${i18nKey}.addMatchingItemsToFeed`)}
            onChange={(e) => props.handleUpdate('feedEnabled', e.target.checked, experimentalModal)}
          />
          {displayEmailEnabledToggle &&
            <RCSwitch
              checked={props.emailEnabled}
              id="add-to-email-alert-toggle"
              label={I18n.t(`${i18nKey}.addToYourDailyEmail`)}
              onChange={e => props.handleUpdate('emailEnabled', e.target.checked, experimentalModal)}
            />
          }
        </div>
        {controlModal &&
          <div className="favorite-modal__shoppability-subsection-divider" />
        }
      </div>

      {experimentalModal &&
        <div className="favorite-modal__shoppability-subsection favorite-modal__shoppability-subsection-callouts">
          {displayNewlyListed &&
            <div className="favorite-modal__header__shoppability-follow-callout">
              <div className="favorite-modal__header__shoppability-follow-callout-title">
                <RCTextWithIcon svgComponent={HeartFilledIcon} placement="left">
                  {I18n.t(`${i18nKey}.newlyListedTitle`)}
                </RCTextWithIcon>
              </div>
              <div className="favorite-modal__header__shoppability-follow-callout-subtitle">{I18n.t(`${i18nKey}.newlyListedSubtitle`)}</div>
            </div>
          }

          <div className="favorite-modal__header__shoppability-follow-disclaimer">
            {I18n.t(`${i18nKey}.disclaimer`)}
          </div>
          <div className="favorite-modal__shoppability-subsection-divider" />
        </div>
      }

      <div className={actionsClass}>
        <div className="favorite-modal__save-and-cancel-buttons">
          <RCButton
            id="submit-favorite"
            variant="filled"
            onClick={e => handleSubmit(e)}
          >
            {I18n.t(`${i18nKey}.save`)}
          </RCButton>

          {controlModal &&
            <div className="mobile-d-none">
              <RCButton
                variant="muted"
                onClick={() => props.handleClose()}
              >
                {I18n.t(`${i18nKey}.cancel`)}
              </RCButton>
            </div>
          }
        </div>

        {props.withRemove &&
          <div className="favorite-modal__remove-button">
            <RCButton
              variant="transparent"
              theme="danger"
              onClick={e => handleDelete(e)}
            >
              {I18n.t(`${i18nKey}.remove`)}
            </RCButton>
          </div>
        }
      </div>
    </Modal>
  );
}

/**
 * One mutation and connected component for the favorites hub (modifying settings for existing favorites)
 */
export const FAVORITES_HUB_UPSERT_FAVORITE_MUTATION = gql`
  mutation UpsertMyFavorite($input: Input_core_apimessages_UpsertMyFavoriteRequest) {
    upsertMyFavorite(input: $input) {
      favorite {
        id
        favorited
        emailEnabled
        feedEnabled
        searchableId
        searchableType
        queryParams
      }
    }
  }
`;

const withFavoritesHubUpsertFavoriteMutation =
  withGraphql<ExternalProps, UpsertMyFavorite.Mutation, UpsertMyFavorite.Variables>(
    FAVORITES_HUB_UPSERT_FAVORITE_MUTATION,
    {
      name: 'upsertMyFavorite',
    },
  );

export const FavoritesHubUpsertFavoriteModal = connect<ExternalProps>([
  withRouter,
  withFavoritesHubUpsertFavoriteMutation,
  withDeleteMyFavoriteMutation,
])(FavoriteModal);

/**
 * One mutation and connected component for the FindFavoriteProvider (saving a new favorite from marketplace pages or CSPs)
 */
export const FIND_FAVORITE_PROVIDER_UPSERT_FAVORITE_MUTATION = gql`
  mutation Core_FindFavoriteProvider_UpsertMyFavorite($input: Input_core_apimessages_UpsertMyFavoriteRequest) {
    upsertMyFavorite(input: $input) {
      favorite {
        id
        favorited
        emailEnabled
        feedEnabled
        searchableId
        searchableType
        queryParams
      }
    }
  }
`;

const withFindFavoriteProviderUpsertFavoriteMutation =
  withGraphql<ExternalProps, CoreFindFavoriteProviderUpsertMyFavorite.Mutation, CoreFindFavoriteProviderUpsertMyFavorite.Variables>(
    FIND_FAVORITE_PROVIDER_UPSERT_FAVORITE_MUTATION,
    {
      name: 'upsertMyFavorite',
    },
  );

export const FindFavoriteProviderUpsertFavoriteModal = connect<ExternalProps>([
  withRouter,
  withFindFavoriteProviderUpsertFavoriteMutation,
])(FavoriteModal);
