// These paths are all static production paths and will work with a disco-ui-prod build but
// may not work with a full local core build if your local browse_page database state differs from production
//

export const browsePagePaths = {
  acousticGuitars: '/cat/acoustic-guitars--10525',
  acousticGuitars12String: '/cat/acoustic-guitars/12-string--10593',
  acousticGuitarsArchtop: '/cat/acoustic-guitars/archtop--10542',
  acousticGuitarsBaritone: '/cat/acoustic-guitars/baritone--10666',
  acousticGuitarsClassical: '/cat/acoustic-guitars/classical--10544',
  acousticGuitarsConcert: '/cat/acoustic-guitars/concert--10548',
  acousticGuitarsDreadnought: '/cat/acoustic-guitars/dreadnought--10550',
  acousticGuitarsJumbo: '/cat/acoustic-guitars/jumbo--10555',
  acousticGuitarsLeftHanded: '/cat/acoustic-guitars/left-handed--10594',
  acousticGuitarsParlor: '/cat/acoustic-guitars/parlor--10557',
  acousticGuitarsResonator: '/cat/acoustic-guitars/resonator--12719',
  acousticGuitarsTenor: '/cat/acoustic-guitars/tenor--13379',
  acousticGuitarsTravelMini: '/cat/acoustic-guitars/mini-travel--11033',
  bassGuitars: '/cat/bass-guitars--19200',
  bassGuitars4String: '/cat/bass-guitars/4-string--19229',
  bassGuitarsShortScale: '/cat/bass-guitars/short-scale--19250',
  bassGuitarsLeftHanded: '/cat/bass-guitars/left-handed--19227',
  bassGuitarsFretless: '/cat/bass-guitars/fretless--19226',
  bassGuitarsAcousticBass: '/cat/bass-guitars/acoustic-bass-guitars--19233',
  electricGuitars: '/cat/electric-guitars--1',
  electricGuitars12String: '/cat/electric-guitars/12-string--8',
  electricGuitarsArchtop: '/cat/electric-guitars/archtop--2',
  electricGuitarsBaritone: '/cat/electric-guitars/baritone--3',
  electricGuitarsHollowBody: '/cat/electric-guitars/hollow-body--10',
  electricGuitarsLapSteel: '/cat/electric-guitars/lap-steel--4',
  electricGuitarsLeftHanded: '/cat/electric-guitars/left-handed--12',
  electricGuitarsPedalSteel: '/cat/electric-guitars/pedal-steel--5',
  electricGuitarsSemiHollow: '/cat/electric-guitars/semi-hollow--9',
  electricGuitarsSolidBody: '/cat/electric-guitars/solid-body--11',
  electricGuitarsTenor: '/cat/electric-guitars/tenor--6',
  electricGuitarsTravelMini: '/cat/electric-guitars/travel-mini--7',
  esp: '/b/esp--160',
  epiphone: '/b/epiphone--169',
  epiphoneLesPaul: '/m/epiphone/les-paul--2512',
  fender: '/b/fender--164',
  fenderMustang: '/m/fender/mustang--2574',
  fenderJaguar: '/m/fender/jaguar--2562',
  fenderJazzmaster: '/m/fender/jazzmaster--2566',
  fenderStratocaster: '/m/fender/stratocaster--2593',
  fenderTelecaster: '/m/fender/telecaster--2590',
  gibson: '/b/gibson--194',
  gibsonLesPaul: '/m/gibson/les-paul--2676',
  gibsonSg: '/m/gibson/sg--2694',
  gretsch: '/b/gretsch--203',
  ibanez: '/b/ibanez--234',
  martin: '/b/martin--306',
  prs: '/b/prs--365',
  squier: '/b/squier--426',
  'taylor-guitars': '/b/taylor-guitars--448',
};

export const categorySlugToBrowsePageUrlMap = {
  'electric-guitars': browsePagePaths.electricGuitars,
  'acoustic-guitars': browsePagePaths.acousticGuitars,
  'bass-guitars': browsePagePaths.bassGuitars,
};
