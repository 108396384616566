import React from 'react';
import I18n from 'i18n-js';

import CurationActions from '../actions/curation_actions';
import CurationStore from '../stores/curation_store';
import CurationAdmin from './admin';
import ModalDialog from '@reverbdotcom/commons/src/components/modal_dialog';

class CurationModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = CurationStore.getState();
    this.onCurationStoreUpdated = this.onCurationStoreUpdated.bind(this);
  }

  componentDidMount() {
    CurationStore.listen(this.onCurationStoreUpdated);
  }

  componentWillUnmount() {
    CurationStore.unlisten(this.onCurationStoreUpdated);
  }

  onCurationStoreUpdated() {
    this.setState(CurationStore.getState());
  }

  render() {
    return (
      <ModalDialog
        isOpen={this.state.isCurating}
        size="wide"
        onSubmit={CurationActions.stopCurating}
        saveButtonText={I18n.t('discovery.curation.doneCurating')}
        headerTitle={I18n.t('discovery.curation.addToSet')}
        onRequestClose={CurationActions.stopCurating}
      >
        <CurationAdmin />
      </ModalDialog>
    );
  }
}

export default CurationModal;
