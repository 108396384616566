import { mParticleListingFields } from '@reverbdotcom/commons/src/gql/graphql';
import Listing from '../../components/discovery/listing';

export function apiListingToMparticleListing(listing: Listing): mParticleListingFields.Fragment {
  return {
    id: listing.id,
    title: listing.title,
    bumpEligible: listing.bumped,
    pricing: {
      buyerPrice: listing.buyer_price,
    },
    condition: {
      conditionUuid: listing.condition.uuid,
      conditionSlug: listing.condition.slug,
    },
    categoryUuids: listing.category_uuids,
  };
}
