import { gql } from '@reverbdotcom/commons/src/gql';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useQuery } from '@reverbdotcom/commons/src/useQuery';
import {
  core_apimessages_TaxIdentification_TaxType as TaxType,
} from '@reverbdotcom/commons/src/gql/graphql';
import { RCAlertBox, RCLoadingBars } from '@reverbdotcom/cadence/components';
import InternationalTaxProfile from './international_tax_profile';
import InternationalTaxProfileForm from './international_tax_profile_form';
import InternationalTaxProfileMainFormSection from './international_tax_profile_main_form_section';
import ErrorDisplay from '../discovery/error_display';
import { I18N as Translate } from '@reverbdotcom/commons/src/components/translate';
import {
  EU_SELLER_TAX_INFO_HELP_CENTER_ARTICLE_ID,
  INFORM_ACT_HELP_CENTER_ARTICLE_ID,
  EU_MARKETPLACE_TAX_COUNTRY_CODES,
  CANADA,
  UNITED_KINGDOM,
  CA_SELLER_TAX_INFO_HELP_CENTER_ARTICLE_ID,
} from './international_tax_profile_constants';

const INTERNATIONAL_TAX_PROFILE_QUERY =  gql(`
  query Core_ShopSettings_InternationalTaxProfile {
    me {
      _id
      uuid
      shop {
        _id
        internationalTaxProfile {
          id
          tinMask
          tinType
          name
          dateOfBirth
          address {
            _id
            name
            streetAddress
            extendedAddress
            locality
            region
            postalCode
            countryCode
            country {
              _id
              name
            }
          }
        }
        businessRegistrationNumber {
          id
          registrationNumber
          countryCode
        }
        taxIdentifications {
          id
          taxId
          taxType
          countryCode
        }
        address {
          _id
          countryCode
        }
        shopType
        marketplaceTaxReportingCountryCode
      }
    }
    countries {
      countries {
        _id
        countryCode
        name
        subregionRequired
        subregions {
          _id
          code
          name
        }
      }
    }
  }
`);

export function InternationalTaxProfileContent() {
  const { data, loading, errors } = useQuery(INTERNATIONAL_TAX_PROFILE_QUERY);
  const businessRegistrationNumber = data?.me?.shop?.businessRegistrationNumber;
  const internationalTaxProfile = data?.me?.shop?.internationalTaxProfile;
  const taxIdentifications = (data?.me?.shop?.taxIdentifications || []).filter(taxIdentification => (
    [TaxType.GST, TaxType.QST, TaxType.VAT].includes(taxIdentification.taxType) &&
      taxIdentification.countryCode === data?.me?.shop?.address?.countryCode
  )).sort((a, b) => {
    if (a.taxType < b.taxType) {
      return -1;
    }

    if (a.taxType > b.taxType) {
      return 1;
    }

    return 0;
  });
  const taxIdentificationType = data?.me?.shop?.marketplaceTaxReportingCountryCode === CANADA ? TaxType.GST : TaxType.VAT;

  const [isEditing, setIsEditing] = useState(!internationalTaxProfile?.name);
  const [isSuccess, setIsSuccess] = useState(false);

  const helpCenterArticleId = useMemo(() => {
    if (!data?.me?.shop) {
      return;
    }

    if (EU_MARKETPLACE_TAX_COUNTRY_CODES.includes(data?.me?.shop?.marketplaceTaxReportingCountryCode)) {
      return EU_SELLER_TAX_INFO_HELP_CENTER_ARTICLE_ID;
    } else if (data?.me?.shop?.marketplaceTaxReportingCountryCode === CANADA) {
      return CA_SELLER_TAX_INFO_HELP_CENTER_ARTICLE_ID;
    }

    return INFORM_ACT_HELP_CENTER_ARTICLE_ID;
  }, [ data ]);

  const switchToEditMode = useCallback(() => {
    setIsSuccess(false);
    setIsEditing(true);
  }, [setIsEditing, setIsSuccess]);

  useEffect(() => {
    if (!loading && internationalTaxProfile?.name) {
      setIsEditing(false);
    }
  }, [loading]);

  if (loading) {
    return (
      <InternationalTaxProfileMainFormSection>
        <div className="d-flex fx-align-center fx-justify-center padding-8">
          <RCLoadingBars />
        </div>
      </InternationalTaxProfileMainFormSection>
    );
  }

  if (errors) {
    return (
      <InternationalTaxProfileMainFormSection>
        <ErrorDisplay />
      </InternationalTaxProfileMainFormSection>
    );
  }

  // if shop is in EU, UK, or Canada, we want to display the BRN and tax identification sections
  const displayAdditionalNumbers = EU_MARKETPLACE_TAX_COUNTRY_CODES.concat([ CANADA, UNITED_KINGDOM ]).includes(data?.me?.shop?.marketplaceTaxReportingCountryCode);

  return (
    isEditing ? (
      <InternationalTaxProfileForm
        businessRegistrationNumber={businessRegistrationNumber}
        countries={data?.countries?.countries}
        displayAdditionalNumbers={displayAdditionalNumbers}
        internationalTaxProfile={internationalTaxProfile}
        setIsEditing={setIsEditing}
        setIsSuccess={setIsSuccess}
        shopType={data?.me?.shop?.shopType}
        helpCenterArticleId={helpCenterArticleId}
        taxIdentifications={taxIdentifications}
        taxIdentificationType={taxIdentificationType}
      />
    ) : (
      <>
        {isSuccess && (
          <div className="scaling-mt-3">
            <RCAlertBox
              type="success"
            >
              <Translate text="discovery.internationalTaxProfile.form.success" />
            </RCAlertBox>
          </div>
        )}
        <InternationalTaxProfile
          businessRegistrationNumber={businessRegistrationNumber}
          displayAdditionalNumbers={displayAdditionalNumbers}
          internationalTaxProfile={internationalTaxProfile}
          switchToEditMode={switchToEditMode}
          helpCenterArticleId={helpCenterArticleId}
          taxIdentifications={taxIdentifications}
          taxIdentificationType={taxIdentificationType}
        />
      </>
    )
  );
}

export default InternationalTaxProfileContent;
