import React from 'react';

import { RCTextWithIcon, RCButton } from '@reverbdotcom/cadence/components';
import { AppleIcon } from '@reverbdotcom/cadence/icons/react';

import CsrfForm from '../csrf_form';
import { APPLE_AUTH_PATH } from '../url_helpers';
import { MParticleEventName, trackEvent } from '../elog/mparticle_tracker';
import { titleText, Variant } from './thirdPartyAuth';

interface Props {
  redirectTo: string;
  variant: Variant;
}

const formId = 'apple-signup-signin';

export default function AppleSigninButton({ redirectTo, variant }: Props) {
  const title = titleText({ provider: 'apple', variant });

  return (
    <CsrfForm
      id={formId}
      method="post"
      action={APPLE_AUTH_PATH.expand({ origin: redirectTo })}
    >
      <RCButton
        isSubmit
        fullWidth
        form={formId}
        variant="muted"
        onClick={trackAppleSigninButtonClick}
      >
        <RCTextWithIcon
          svgComponent={AppleIcon}
          placement="left"
          size="large"
        >
          {title}
        </RCTextWithIcon>
      </RCButton>
    </CsrfForm>
  );
}

function trackAppleSigninButtonClick() {
  trackEvent({
    componentName: 'AppleSigninButton',
    eventName: MParticleEventName.ClickedAppleSigninButton,
  });
}
