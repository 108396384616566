import React from 'react';
import I18n from 'i18n-js';

import bind from '../../bind';

import Tiles from '../../components/tiles';
import { IDynamicComponentProps } from '../dynamic_component_props';
import { RCStarRating } from '@reverbdotcom/cadence/components';
import SanitizedRender from '../../components/sanitized_render';

import { imageUploadURL } from '../../url_helpers';

interface IReviewData {
  avatar: string;
  content: string;
  name: string;
  rating?: number;
  title: string;
  verified: boolean;
}

export interface IProps extends IDynamicComponentProps {
  reviews: IReviewData[];
  titleHtml?: string;
}

export default class ReviewList extends React.Component<IProps, null> {
  getBackgroundImageSrc(reviewData: IReviewData) {
    if (!reviewData.avatar) return null;

    return imageUploadURL(reviewData.avatar);
  }

  @bind
  renderReview(reviewData: IReviewData) {
    return (
      <div
        className="review-item"
      >
        <div className="review-item__user">
          <img
            className="review-item__avatar"
            src={this.getBackgroundImageSrc(reviewData)}
            alt="user avatar"
            loading="lazy"
          />
          {reviewData.name}
        </div>
        <div className="review-item__main">
          <h4 className="cms-h4 mtb-0">
            {reviewData.title}
          </h4>
          <div className="mb-1">
            <div className="d-inline-block mr-1">
              <RCStarRating
                rating={reviewData.rating ? +reviewData.rating / 5 : 1}
                hideTooltip
              />
            </div>
            {reviewData.verified &&
              <span className="size-90 valign-bottom weight-bold color-orange" data-review-verified>
                {I18n.t('discovery.productReviews.card.verifiedPurchase')}
              </span>
            }
          </div>
          <p
            className="review-item__body"
          >
            {reviewData.content}
          </p>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className="cms-reviews">
        <div className="cms-reviews__inner">
          {this.props.titleHtml &&
            <SanitizedRender
              htmlTag="div"
              className="cms-reviews__title"
              html={this.props.titleHtml}
              blocklistedTags={['a']}
            />
          }
          <Tiles
            oneWide
          >
            {this.props.reviews.map(this.renderReview)}
          </Tiles>
        </div>
      </div>
    );
  }
}
