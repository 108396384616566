import React, { useCallback, useState } from 'react';
import {
  core_apimessages_TaxIdentification as TaxIdentification,
  core_apimessages_TaxIdentification_TaxType as TaxType,
} from '@reverbdotcom/commons/src/gql/graphql';
import FormGroup from '@reverbdotcom/commons/src/components/form_group';
import FormGroupWithInput from '@reverbdotcom/commons/src/components/form_group_with_input';
import { I18N as Translate } from '@reverbdotcom/commons/src/components/translate';
import I18n from 'i18n-js';
import { RCAlertBox, RCButton, RCIcon, RCIconButton } from '@reverbdotcom/cadence/components';
import { PencilIcon, TrashIcon } from '@reverbdotcom/cadence/icons/react';
import {
  CreateMutationProps,
  MutationsProps,
  withCreateTaxIdentification,
  withUpdateTaxIdentification,
  withDeactivateTaxIdentification,
} from './shop_settings_tax_identification_mutations';
import { connect } from '@reverbdotcom/commons/src/connect';

export const AUSTRALIA_COUNTRY_CODE = 'AU';

interface ExternalProps {
  taxIdentification?: TaxIdentification;
}

type Props = ExternalProps & CreateMutationProps & MutationsProps;

export function AustraliaGstForm({ taxIdentification, createTaxIdentification, updateTaxIdentification, deactivateTaxIdentification }: Props) {

  const [isEditing, setIsEditing] = useState(!taxIdentification);
  const [taxId, setTaxId] = useState(taxIdentification?.taxId || '');
  const [error, setError] = React.useState('');
  const [successMessage, setSuccessMessage] = React.useState('');

  const setEditingMode = useCallback(() => {
    setError('');
    setSuccessMessage('');
    setTaxId('');
    setIsEditing(true);
  }, [
    setError,
    setSuccessMessage,
    setTaxId,
  ]);

  async function create() {
    try {
      const response = await createTaxIdentification({
        variables: {
          input: {
            taxId,
            countryCode: AUSTRALIA_COUNTRY_CODE,
            taxType: TaxType.GST,
          },
        },
      });

      if (response) {
        clearAndCloseForm(true);
      }
    } catch (e) {
      setSuccessMessage('');
      setError(I18n.t('discovery.shopSettings.australianGst.saveFailed'));
    }
  }

  async function update() {
    try {
      const response = await updateTaxIdentification({
        variables: {
          input: {
            taxIdentification: {
              id: taxIdentification?.id,
              countryCode: AUSTRALIA_COUNTRY_CODE,
              taxId,
            },
          },
        },
      });

      if (response) {
        clearAndCloseForm(true);
      }
    } catch (e) {
      setError(I18n.t('discovery.shopSettings.australianGst.saveFailed'));
    }
  }

  async function deactivate() {
    try {
      const response = await deactivateTaxIdentification({
        variables: {
          input: {
            id: taxIdentification?.id,
          },
        },
      });

      if (response) {
        setTaxId('');
        setError('');
        setIsEditing(true);
        setSuccessMessage(I18n.t('discovery.shopSettings.australianGst.deleteSucceeded'));
      }
    } catch (e) {
      setError(I18n.t('discovery.shopSettings.australianGst.deleteFailed'));
    }
  }

  function clearAndCloseForm(isSuccess) {
    setError('');
    setSuccessMessage(isSuccess ? I18n.t('discovery.shopSettings.australianGst.saveSucceeded') : '');
    setTaxId(taxIdentification?.taxId || '');
    setIsEditing(false);
  }

  return (
    <>
      {successMessage && (
        <div className="mb-4">
          <RCAlertBox type="success">
            {successMessage}
          </RCAlertBox>
        </div>
      )}
      {isEditing ? (
        <>
          {error && (
            <div className="mb-4">
              <RCAlertBox type="error">
                {error}
              </RCAlertBox>
            </div>
          )}
          <div className="d-flex fx-align-center fx-justify-between mobile-d-block">
            <div className="fx-grow mr-4">
              <FormGroupWithInput
                inputName="taxId"
                fieldName="taxId"
                inputType="text"
                label={I18n.t('discovery.shopSettings.australianGst.registrationNumber')}
                value={taxId}
                updateField={(field) => setTaxId(field.taxId)}
                pattern="[0-9]+"
                helpText={I18n.t('discovery.shopSettings.australianGst.registrationNumberHelpText')}
                required
              />
            </div>
            <div className="d-flex fx-align-center fx-justify-end">
              {!!taxIdentification && (
                <div className="mr-4">
                  <RCButton
                    variant="transparent"
                    onClick={() => clearAndCloseForm(false)}
                  >
                    <Translate text="discovery.shopSettings.australianGst.cancel" />
                  </RCButton>
                </div>
              )}
              <RCButton
                isSubmit
                variant="filled"
                onClick={taxIdentification ? update : create}
              >
                <Translate text="discovery.shopSettings.australianGst.submit" />
              </RCButton>
            </div>
          </div>
        </>
      ) : (
        <div className="d-flex fx-align-center fx-justify-between mobile-d-block">
          <div>
            <FormGroup
              label={I18n.t('discovery.shopSettings.australianGst.registrationNumber')}>
              <code className="code-snippet">
                {taxIdentification?.taxId}
              </code>
            </FormGroup>
          </div>
          <div className="mobile-mt-4">
            <FormGroup label="Status">
              <Translate text={`discovery.shopSettings.vat.statuses.${taxIdentification?.status.toLocaleLowerCase()}`} />
            </FormGroup>
          </div>
          <div className="mobile-mt-4 d-flex fx-justify-end fx-align-center">
            <div className="mr-4">
              <RCButton
                variant="transparent"
                onClick={setEditingMode}
              >
                <RCIcon
                  inline
                  svgComponent={PencilIcon}
                />
                <span className="ml-2">
                  <Translate text="discovery.shopSettings.vat.edit" />
                </span>
              </RCButton>
            </div>
            <RCIconButton
              label={I18n.t('discovery.shopSettings.australianGst.delete')}
              variant="transparent"
              svgComponent={TrashIcon}
              onClick={deactivate}
            />
          </div>
        </div>
      )}
    </>
  );
}

export default connect<ExternalProps>([
  withCreateTaxIdentification,
  withUpdateTaxIdentification,
  withDeactivateTaxIdentification,
])(AustraliaGstForm);
