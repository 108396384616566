// Bridge to old `data-dropdown`s controlled by hoverIntent.js
function closeLegacyDropdowns() {
  $('[data-dropdown]').trigger('close.dropdown');
}

class DropdownStore {
  callbacks = [];

  listen(cb) {
    if (this.callbacks.indexOf(cb) === -1) {
      this.callbacks.push(cb);
    }
  }

  unlisten(cb) {
    const i = this.callbacks.indexOf(cb);
    if (i !== -1) {
      this.callbacks = this.callbacks.splice(i, 1);
    }
  }

  closeAll() {
    closeLegacyDropdowns();
    this.callbacks.forEach(cb => cb());
  }
}

export default new DropdownStore();
