import { Location } from 'history';
import React from 'react';

// The location type from history doesn't include
// the hash or referer. Referer is needed in a few places,
// TODO - we should remove referer and get it from somewhere else.
export interface IUrl extends Location {
  hash?: string;
  referer?: string;
}

export interface WithUrlProps {
  url: IUrl;
}

export const URLContext = React.createContext<IUrl>({
  pathname: '',
  search: '',
  query: '',
  state: '',
  action: 'PUSH',
  key: '',
});

export function useURL(): IUrl {
  return React.useContext(URLContext);
}

export function withUrlContext<TProps>(Wrapped: React.ComponentType<TProps>): React.FunctionComponent<TProps> {
  const Component = (props: TProps) => (
    <URLContext.Consumer>
      {(urlContextObject) => (
        // eslint-disable-next-line react/jsx-props-no-spreading
        (<Wrapped url={urlContextObject} {...props} />)
      )}
    </URLContext.Consumer>
  );

  Component.displayName = `withUrlContext(${Wrapped.displayName || Wrapped.name})`;

  return Component;
}
