import React from 'react';
import I18n from 'i18n-js';
import {
  core_apimessages_NegotiationAction as NegotiationAction,
  core_apimessages_NegotiationParty,
} from '@reverbdotcom/commons/src/gql/graphql';
import UpdateOfferModal from './update_offer_modal';
import classNames from 'classnames';
import { refreshDashboard } from './refresh_dashboard';
import { RCToast } from '@reverbdotcom/cadence/components';
import { MParticleEventName, trackEvent } from '@reverbdotcom/commons/src/elog/mparticle_tracker';

interface Props {
  listingId: string;
  isSeller: boolean;
  negotiationId: string;
  negotiationAction: NegotiationAction;
  disabled?: boolean;
}

export function handleSuccess(requestingParty: core_apimessages_NegotiationParty, negotiationId: string) {
  const updateOfferMessage = I18n.t('discovery.offers.updateSubmissionSuccess');
  refreshDashboard(negotiationId, updateOfferMessage, requestingParty);
}

export function UpdateOfferButton({ listingId, isSeller, negotiationId, negotiationAction, disabled = false }: Props) {
  const [openDialog, setOpenDialog] = React.useState(false);
  const [confirmationToastText, setConfirmationToastText] = React.useState('');

  React.useEffect(() => {
    if (openDialog) {
      trackEvent({
        eventName: MParticleEventName.ClickedUpdateOfferButton,
        listingId: listingId,
        negotiationAction: negotiationAction.toLowerCase(),
        role: isSeller ? 'seller' : 'buyer',
      });
    }
  }, [openDialog]);

  return (
    <div>
      <button
        className={classNames(
          'offer-action', {
            'offer-action--accept': negotiationAction === NegotiationAction.ACCEPT,
            'offer-action--reject': negotiationAction === NegotiationAction.REJECT,
            'offer-action--counter': negotiationAction === NegotiationAction.COUNTER,
          })}
        onClick={() => {
          setOpenDialog(!openDialog);
        }}
        type="button"
        disabled={!!disabled}
      >
        {I18n.t(`discovery.offers.action.${negotiationAction.toLocaleLowerCase()}`)}
      </button>

      <>
        <UpdateOfferModal
          onRequestDialogClose={() => { setOpenDialog(false); }}
          isDialogOpen={openDialog}
          isSeller={isSeller}
          onSuccess={(party) => {
            handleSuccess(party, negotiationId);

            if (negotiationAction === NegotiationAction.REJECT) {
              setConfirmationToastText(I18n.t('discovery.offers.toast.rejected'));
            }
          }}
          listingId={listingId}
          negotiationId={negotiationId}
          negotiationAction={negotiationAction}
        />
        <RCToast
          isOpen={!!confirmationToastText}
          onClose={() => { setConfirmationToastText(''); }}
          text={confirmationToastText}
          theme="success"
        />
      </>
    </div>
  );
}

export default UpdateOfferButton;
