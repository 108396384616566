import { CoreFetchCountries } from './gql/graphql';
import { useQuery } from './useQuery';
// TODO update this to import from commons/src/gql
// eslint-disable-next-line no-restricted-imports
import { gql } from '@apollo/client';

type Country = CoreFetchCountries.Query['countries']['countries'][0];

export interface CountriesResult {
  countries: Country[];
}

export function useCountries(): CountriesResult {
  const { data } = useQuery<CoreFetchCountries.Query, CoreFetchCountries.Variables>(FETCH_COUNTRIES);

  const countries = data?.countries?.countries || [];

  return {
    countries,
  };
}

export const FETCH_COUNTRIES = gql`
  query Core_FetchCountries {
    countries {
      countries {
        _id
        name
        countryCode
        subregionRequired
        subregions {
          _id
          name
          code
        }
      }
    }
  }
`;
