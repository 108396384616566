import React from 'react';
import I18n from 'i18n-js';
import classNames from 'classnames';

interface IAdTagProps {
  small?: boolean;
  inline?: boolean;
}

// AdTag VS amp-tag namespacing differences are to help circumvent ad blockers
export default function AdTag({ small, inline }: IAdTagProps) {
  return (
    <span className={classNames(
      'amp-tag',
      { 'amp-tag--small': small },
      { 'amp-tag--inline': inline },
    )}>
      {I18n.t('commons.adCallout')}
    </span>
  );
}
