import React from 'react';
import { Route } from 'react-router';
import RootRoute from './root_route';
import { SUPPORTED_URL_REGIONS } from '@reverbdotcom/commons/src/url_helpers';

export default (routes) => ([
  <Route key="root" path="/" component={RootRoute}>{routes}</Route>,
  ...SUPPORTED_URL_REGIONS.map(region => (
    <Route key={`root-${region}`} path={`/${region}`} component={RootRoute}>{routes}</Route>
  )),
]);
