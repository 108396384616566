import React from 'react';
import I18n from 'i18n-js';
import className from 'classnames';
import { Commons_MParticle_ListingQuery } from '@reverbdotcom/commons/src/gql/graphql';
import withMParticleListings from '@reverbdotcom/commons/src/components/with_mparticle_listings';
import { destroy, post } from './api_request';
import Location from '../lib/wrapped_location';
import { trackMoveToWatchListClick, trackRemoveFromCart, trackRemoveFromCartClick } from './cart/cartTracking';

interface IProps {
  listingIds: string[];
  cartItemId: string;
  canMoveToWatchList?: boolean;
  canRemoveFromCart?: boolean;
  mParticleListings?: Commons_MParticle_ListingQuery['listings'];
}

export function CartItemActions(props: IProps) {
  const {
    listingIds,
    cartItemId,
    canMoveToWatchList,
    canRemoveFromCart,
    mParticleListings,
  } = props;

  const listingId = listingIds[0];
  const listings = mParticleListings || [];
  const listing = listings[0];

  function moveToWatchList() {
    post(`/cart/${cartItemId}/move_to_watchlist`, {}, {}, '3.0').then(() => {
      trackMoveToWatchListClick(listing);
      Location.reload();
    });
  }

  function removeFromCart() {
    destroy(`/cart/${listingId}`, {}, {}, '3.0').then(() => {
      trackRemoveFromCart(listing);
      Location.reload();
    });
  }

  function handleRemoveFromCartClick() {
    trackRemoveFromCartClick(listing);
    removeFromCart();
  }

  const watchButtonClasses = className(
    'mr-space',
    'fa',
    'fa-heart',
  );

  return (
    <ul className="inline-list mt-1 small">
      {canMoveToWatchList && (
        <li className="margined-right hidden-mobile">
          <button
            className="color-gray button-as-link"
            data-move-to-watchlist
            onClick={moveToWatchList}
            type="button"
          >
            <span className={watchButtonClasses} />
            {I18n.t('discovery.removeFromCart.moveToWatchList')}
          </button>
        </li>
      )}
      {canRemoveFromCart && (
        <li>
          <button
            className="color-gray button-as-link"
            data-toggle-confirmation
            onClick={() => handleRemoveFromCartClick()}
          >
            <span className="fa fa-times mr-space" />
            {I18n.t('discovery.removeFromCart.remove')}
          </button>
        </li>
      )}
    </ul>
  );
}

export default withMParticleListings<IProps>()(CartItemActions);
