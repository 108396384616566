import { IUser } from '@reverbdotcom/commons/src/components/user_context_provider';
import { dealsAndStealsPromoURLPath } from './promo_url_path_for_user';
import userHelpCenterUrl from './shared/user_help_center_url';
import { Paths } from './shared/url_helpers';

export interface SiteFooterData {
  titleKey: string;
  links: SiteFooterLinkData[];
}

export interface SiteFooterLinkData {
  textKey: string;
  href: string;
  usOnly?: boolean;
  enLocaleOnly?: boolean;
}

function buildSiteFooterData(user: Partial<IUser>): SiteFooterData[] {
  return [
    {
      titleKey: 'shopGear',
      links: [
        {
          textKey: 'categories',
          href: '/gear-categories',
        },
        {
          textKey: 'brands',
          href: '/brands',
        },
        {
          textKey: 'shops',
          href: '/shops',
          usOnly: true,
        },
        {
          textKey: 'deals',
          href: dealsAndStealsPromoURLPath(user),
        },
        {
          textKey: 'priceDrops',
          href: '/promo/price-drops',
        },
        {
          textKey: 'financing',
          href: '/promo/financing',
          usOnly: true,
        },
        {
          textKey: 'new',
          href: '/promo/top-sellers-recent-releases',
        },
      ],
    },
    {
      titleKey: 'resources',
      links: [
        {
          textKey: 'news',
          href: '/news',
        },
        {
          textKey: 'priceGuide',
          href: '/price-guide',
        },
        {
          textKey: 'buyingGuide',
          href: '/guide',
          enLocaleOnly: true,
        },
        {
          textKey: 'giftCards',
          href: '/gift-cards ',
          usOnly: true,
        },
      ],
    },
    {
      titleKey: 'sell',
      links: [
        {
          textKey: 'sellerHub',
          href: '/selling',
        },
        {
          textKey: 'reverbPayments',
          href: '/selling/reverb-payment-faq',
        },
      ],
    },
    {
      titleKey: 'company',
      links: [
        {
          textKey: 'about',
          href: '/page/about',
        },
        {
          textKey: 'careers',
          href: '/page/jobs',
        },
        {
          textKey: 'press',
          href: '/page/press',
        },
        {
          textKey: 'reverbGives',
          href: '/page/reverb-gives',
        },
        {
          textKey: 'trust',
          href: '/trust',
        },
      ],
    },
    {
      titleKey: 'help',
      links: [
        {
          textKey: 'helpCenter',
          href: `${userHelpCenterUrl(user.locale)}?utm_source=Reverb&utm_medium=web&utm_campaign=footer`,
        },
        {
          textKey: 'contactSupport',
          href: `${userHelpCenterUrl(user.locale)}/articles/115015709067?utm_source=Reverb&utm_medium=web&utm_campaign=footer`,
        },
        {
          textKey: 'reverbProtection',
          href: '/page/reverb-protection',
        },
        {
          textKey: 'mobileApps',
          href: Paths.mobileAppsLanding.expand({}),
        },
        {
          textKey: 'integrations',
          href: '/page/integrations',
        },
        {
          textKey: 'affiliate',
          href: '/selling/how-to-join-the-reverb-affiliate-program',
        },
      ],
    },
  ];
}

export default buildSiteFooterData;
