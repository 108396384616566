import PropTypes from 'prop-types';
import React from 'react';
import { isEqual } from 'lodash';

import CollectionHeaderComponent from './collection_header_component';
import { CollectionHeaderActions } from '@reverbdotcom/commons/src/components/stores/collection_header_store';

class CollectionHeaderManager extends React.Component {
  componentDidMount() {
    CollectionHeaderActions.fetchOrClear(this.props.params);
  }

  componentDidUpdate(prevProps) {
    const notChanged = isEqual(
      prevProps.params,
      this.props.params,
    );

    if (notChanged) { return; }

    CollectionHeaderActions.fetchOrClear(this.props.params);
  }

  render() {
    return (
      <div>
        <CollectionHeaderComponent
          hideBanner={this.props.hideBanner}
        />
      </div>
    );
  }
}

CollectionHeaderManager.propTypes = {
  params: PropTypes.object.isRequired,
  hideBanner: PropTypes.bool,
};

CollectionHeaderManager.defaultProps = {
  hideBanner: false,
};

export default CollectionHeaderManager;
