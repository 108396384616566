import React from 'react';
import AdImageFeatureContainer from '../../components/ads/ad_image_feature_container';

export interface IProps {
  categoryUuids: string[]
}

export default function CmsAdImageFeature(props: IProps) {

  return (
    <AdImageFeatureContainer
      categoryUuids={props.categoryUuids}
    />
  );
}
