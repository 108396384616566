import {
  core_apimessages_AddressEntry as IAddressEntry,
} from '@reverbdotcom/commons/src/gql/graphql';

export interface ITokenizedCreditCardData {
  encryptedSecurityCode: string;
  encryptedExpiryMonth: string;
  encryptedExpiryYear: string;
  encryptedCardNumber: string;
}

export function buildBillingCardAddress(): IAddressEntry {
  return {
    countryCode: '',
    postalCode: '',
  };
}
