/* eslint-disable new-cap */
import React from 'react';
import I18n from 'i18n-js';
// TODO update this to import from commons
// import { gql } from '@reverbdotcom/commons/src/gql';
// eslint-disable-next-line no-restricted-imports
import { gql } from '@apollo/client';
import { useMutation } from '@reverbdotcom/commons/src/useMutation';
import { RCButton, RCAlertBox } from '@reverbdotcom/cadence/components';
import CoreLink from '@reverbdotcom/commons/src/components/core_link';
import URLHelpers from '../../../shared/url_helpers';
import { SanitizedRender } from '@reverbdotcom/commons';
import { core_apimessages_Error } from '@reverbdotcom/commons/src/gql/graphql';
import { trackEvent, MParticleEventName } from '@reverbdotcom/commons/src/elog/mparticle_tracker';

export const HELP_CENTER_ARTICLE_ID = '360009991994';
const COMPONENT_NAME = 'AffirmZeroPercentPopoverContent';

interface IExternalProps {
  listingId: string;
  isAffirmEnabled: boolean;
  onSuccessCallback: () => void;
  onFailureCallback: (errorType) => void;
}
interface core_apimessages_ExtendedError extends core_apimessages_Error {
  error_type: string;
}

export const ENABLE_ZERO_PERCENT_FINANCING_MUTATION = gql`
  mutation Core_ZeroFinancing_Enable(
    $input: Input_core_apimessages_MyAffirmCuratedSetMembershipRequest
  ) {
    addListingToMyAffirmCuratedSet(input: $input) {
      curatedSetMembership {
        id
        curatedSetId
        productId
      }
    }
  }
`;

export const DISABLE_ZERO_PERCENT_FINANCING_MUTATION = gql`
  mutation Core_ZeroFinancing_Disable(
    $input: Input_core_apimessages_MyAffirmCuratedSetMembershipRequest
  ) {
    removeListingFromMyAffirmCuratedSet(input: $input) {
      curatedSetMembership {
        id
        curatedSetId
        productId
      }
    }
  }
`;

function PopoverText({ isAffirmEnabled }) {
  return (
    <>
      <div className="pt-2">
        {isAffirmEnabled && I18n.t('discovery.listingsManagement.sellerListingCard.affirmZeroPercent.affirmIsEnabled')}
      </div>
      <div className="pt-2">
        {I18n.t(
          'discovery.listingsManagement.sellerListingCard.affirmZeroPercent.content',
        )}
      </div>
      <div className="pt-2">
        <CoreLink
          to={URLHelpers.helpCenterArticlePath(HELP_CENTER_ARTICLE_ID)}
          target="_blank"
        >
          {I18n.t(
            'discovery.listingsManagement.sellerListingCard.affirmZeroPercent.learnMore',
          )}
        </CoreLink>
      </div>
    </>
  );
}

function DisplayFeeDisclaimer() {
  return (
    <div>
      <SanitizedRender
        className="padding-3 size-80 text-color-gray-700"
        htmlTag="p"
        html={I18n.t(
          'discovery.listingsManagement.sellerListingCard.affirmZeroPercent.fee',
          {
            supOpen: '<sup>',
            supClose: '</sup>',
          },
        )}
      />
    </div>
  );
}

export default function AffirmZeroPercentPopoverContent({
  isAffirmEnabled,
  listingId,
  onSuccessCallback,
  onFailureCallback,
}: IExternalProps) {
  const text = PopoverText({ isAffirmEnabled });
  const buttonText = isAffirmEnabled ? I18n.t('discovery.listingsManagement.sellerListingCard.affirmZeroPercent.disableButtonText') : I18n.t('discovery.listingsManagement.sellerListingCard.affirmZeroPercent.enableButtonText');

  const [
    enableAffirmZeroPercent,
    { data: enableData, loading: enableLoading, errors: enableErrors },
  ] = useMutation(ENABLE_ZERO_PERCENT_FINANCING_MUTATION);

  const [
    disableAffirmZeroPercent,
    { data: disableData, loading: disableLoading, errors: disableErrors },
  ] = useMutation(DISABLE_ZERO_PERCENT_FINANCING_MUTATION);

  React.useEffect(() => {
    if (enableLoading) return;

    if (enableErrors.length) {
      const { error_type: errorType } = enableErrors[0] as core_apimessages_ExtendedError;
      onFailureCallback(errorType);
    }
    const results = enableData && enableData.addListingToMyAffirmCuratedSet;

    if (results) {
      onSuccessCallback();
      trackEvent({
        componentName: COMPONENT_NAME,
        eventName: MParticleEventName.EnabledZeroPercentFinancing,
        listingId: listingId,
      });
    }
  }, [enableLoading]);

  React.useEffect(() => {
    if (disableLoading) return;

    if (disableErrors.length) {
      const { error_type: errorType } = disableErrors[0] as core_apimessages_ExtendedError;
      onFailureCallback(errorType);
    }

    const results =
      disableData && disableData.removeListingFromMyAffirmCuratedSet;
    if (results) {
      onSuccessCallback();
      trackEvent({
        componentName: COMPONENT_NAME,
        eventName: MParticleEventName.DisabledZeroPercentFinancing,
        listingId: listingId,
      });
    }
  }, [disableLoading]);

  function updateZeroPercentAffirm() {
    const payload = {
      variables: {
        input: {
          productId: listingId,
        },
      },
    };

    if (isAffirmEnabled) {
      disableAffirmZeroPercent(payload);
    } else {
      enableAffirmZeroPercent(payload);
    }
  }

  return (
    <div>
      <div className="mb-2">
        <RCAlertBox type="plain">{text}</RCAlertBox>
        <div className="pt-4">
          <div>
            <RCButton
              fullWidth
              onClick={updateZeroPercentAffirm}
              size="medium"
              theme="main"
              variant="filled"
            >
              {buttonText}
            </RCButton>
          </div>
          {!isAffirmEnabled && <DisplayFeeDisclaimer/>}
        </div>
      </div>
    </div>
  );
}
