import React from 'react';
import {
  core_apimessages_CreditCard,
  core_apimessages_Country,
} from '@reverbdotcom/commons/src/gql/graphql';
import CreditCardBox from './CreditCardBox';
import MyAccountCreditCardForm from './MyAccountCreditCardForm';

interface IProps {
  creditCard: core_apimessages_CreditCard;
  countriesForSelect: core_apimessages_Country[];
  isEditing: Boolean;
  setEditableCardId: React.Dispatch<React.SetStateAction<any>>;
}

export default function EditableCreditCard({
  creditCard,
  countriesForSelect,
  isEditing,
  setEditableCardId,
}: IProps) {
  return (
    <div className="boxed-list__item">
      {isEditing ? (
        <MyAccountCreditCardForm
          creditCard={creditCard}
          countries={countriesForSelect}
          cancelEdit={() => setEditableCardId(null)}
        />
      ) : (
        <CreditCardBox
          creditCard={creditCard}
          setIsEditing={() => setEditableCardId(creditCard.id)}
        />
      )}
    </div>
  );
}
