import { ChildProps } from '@apollo/client/react/hoc';
// TODO update this to import from commons
// import { gql } from '@reverbdotcom/commons/src/gql';
// eslint-disable-next-line no-restricted-imports
import { gql } from '@apollo/client';
import { get } from 'lodash';
import React from 'react';
import I18n from 'i18n-js';
import RegionSelect from '../addresses/region_select';
import {
  Countries,
  MyShopTaxPolicyData,
} from '@reverbdotcom/commons/src/gql/graphql';

import FormGroupWithSelect from '@reverbdotcom/commons/src/components/form_group_with_select';
import FormGroupWithInput from '@reverbdotcom/commons/src/components/form_group_with_input';
import FormGroupWithCheckbox from '@reverbdotcom/commons/src/components/form_group_with_checkbox';
import { withGraphql } from '@reverbdotcom/commons/src/with_graphql';
import { connect, EmptyExternalProps } from '@reverbdotcom/commons/src/connect';

type Props = ChildProps<MyShopTaxPolicyData.Query, Countries.Query>;

const DEFAULT_COUNTRY_CODE = 'US';
const CALCULATION_TYPES = {
  auto: 'auto',
  custom: 'custom',
};

const myShopTaxPolicyData = withGraphql<{}, MyShopTaxPolicyData.Query, null>(
  gql`
    query MyShopTaxPolicyData
    {
      me {
        _id
        uuid
        shop {
          _id
          address {
            _id
            countryCode
          }
        }
      }
      countries {
        countries {
          _id
          name
          countryCode
          subregions {
            _id
            name
            code
          }
        }
      }
    }
  `,
  {
    options: {
      ssr: false, // not important for SEO or initial page rendering
    },
  },
);

function countries(props) {
  return get(props, 'data.countries.countries', []);
}

function myShopCountryCode(props): string {
  return get(props, 'data.me.shop.address.countryCode', DEFAULT_COUNTRY_CODE);
}

interface TaxRegionSelectProps {
  taxPolicyProps: Props;
  region: string;
  setRegion: React.Dispatch<React.SetStateAction<string>>;
}

function TaxRegionSelect(props: TaxRegionSelectProps) {
  return (
    <div className="g-col-5 g-col-mobile-12">
      <div className="form-group">
        <div className="form-group__header">
          <label htmlFor="tax_policy[region]">
            {I18n.t('discovery.taxPoliciesForm.state')}
          </label>
        </div>
        <div className="form-group__fields">
          <RegionSelect
            name="tax_policy[region]"
            countryCode={myShopCountryCode(props.taxPolicyProps)}
            countries={countries(props.taxPolicyProps)}
            value={props.region}
            onChange={(event) => props.setRegion(event.target.value)}
            required
          />
        </div>
      </div>
    </div>
  );
}

interface CalculationTypeSelectProps {
  setCalculationType: React.Dispatch<React.SetStateAction<string>>;
}

function calculationTypeOptions() {
  return Object.keys(CALCULATION_TYPES).map((key) => {
    return {
      text: I18n.t(`discovery.taxPoliciesForm.calculationTypes.${CALCULATION_TYPES[key]}`),
      value: CALCULATION_TYPES[key],
    };
  });
}

function CalculationTypeSelect(props: CalculationTypeSelectProps) {
  const inputName = 'tax_policy[calculation_type]';

  return (
    <div className="g-col-3 g-col-mobile-12">
      <FormGroupWithSelect
        label={I18n.t('discovery.taxPoliciesForm.taxRate')}
        inputName={inputName}
        options={calculationTypeOptions()}
        updateField={(field) => { props.setCalculationType(field[inputName]); }}
      />
    </div>
  );
}

function RateInput() {
  return (
    <div className="g-col-4 g-col-mobile-12">
      <FormGroupWithInput
        inputName="tax_policy[rate]"
        label={I18n.t('discovery.taxPoliciesForm.rate')}
        currencySuffix="%"
        numberOnly
      />
    </div>
  );
}

function ApplyTaxCheckbox() {
  return (
    <div className="g-col-12">
      <FormGroupWithCheckbox
        inputName="tax_policy[include_shipping]"
        label={I18n.t('discovery.taxPoliciesForm.includeShipping')}
      />
    </div>
  );
}

function Placeholder() {
  return (
    <div className="mb-4">
      <div className="tax-policy-form tax-policy-form--placeholder"></div>
    </div>
  );
}

export function TaxPolicyUS(props: Props) {
  const [region, setRegion] = React.useState('');
  const [calculationType, setCalculationType] = React.useState(CALCULATION_TYPES.auto);

  if (props.data.loading) { return <Placeholder />; }

  return (
    <div className="g-container mb-2">
      <TaxRegionSelect
        taxPolicyProps={props}
        region={region}
        setRegion={setRegion}
      />
      {region.length > 0 && <CalculationTypeSelect setCalculationType={setCalculationType} />}
      {calculationType === CALCULATION_TYPES.custom && <RateInput />}
      {calculationType === CALCULATION_TYPES.custom && <ApplyTaxCheckbox />}
      <input
        type="hidden"
        name="tax_policy[greater_region_code]"
        value={myShopCountryCode(props)}
      />
    </div>
  );
}

export default connect<EmptyExternalProps, Props>([
  myShopTaxPolicyData,
])(TaxPolicyUS);
