import React from 'react';

interface IProps {
  cardType: string;
}

const cardToIconMapping = {
  'American Express': 'cc-american-express',
  Visa: 'cc-visa',
  JCB: 'cc-jcb',
  Discover: 'cc-discover',
  MasterCard: 'cc-mastercard',
};

export default class CreditCardIcon extends React.Component<IProps, null> {
  get cardIconClass() {
    return cardToIconMapping[this.props.cardType];
  }

  render() {
    if (!this.props.cardType) { return null; }

    return (
      <div className={this.cardIconClass}></div>
    );
  }
}
