import React from 'react';
import CoreLink from '../core_link';
import { AdZone, Core_Ad_Banner_WrapperQuery } from '../../gql/graphql';
import { useIntersectionTracking, useViewTracking } from '../../use_tracking';
import { buildAdImpressionData, buildAdClickData, buildAdObservedData } from './ad_mparticle_tracking_helpers';
import AdTag from './ad_tag';

type Ad = Core_Ad_Banner_WrapperQuery['adServe']['ad'];

interface Props {
  ad: Ad;
  componentName: string;
  adZones?: AdZone[],
}

interface IContentBlockProps {
  title: string;
  subtitle: string;
  textColor: string;
}

function ContentBlock(props: IContentBlockProps) {
  return (
    <div
      className="amp-card__titleblock"
      style={{
        color: props.textColor,
      }}
    >
      <div
        className="amp-card__title"
        style={{ color: props.textColor }}
      >
        {props.title}
      </div>
      <div
        className="amp-card__subtitle"
        style={{ color: props.textColor }}
      >
        {props.subtitle}
      </div>
    </div>
  );
}

interface ICTAProps {
  ctaText: string;
  ctaButtonColor: string;
  ctaTextColor: string;
}

function CallToAction(props: ICTAProps) {
  return (
    <div className="amp-card__cta-wrapper">
      <span
        className="amp-card__cta"
        style={{
          backgroundColor: props.ctaButtonColor,
          color: props.ctaTextColor,
        }}
      >
        {props.ctaText}
      </span>
    </div>
  );
}

export function hasAdCardRequiredFields(ad: Ad): boolean {
  const { imageAdCreative } = ad;
  return !!(imageAdCreative.title &&
    imageAdCreative.ctaText &&
    imageAdCreative.overlayImage);
}

// AdCard VS amp-card namespacing differences are to help circumvent ad blockers
export function AdCard(props: Props) {
  const intersectionTrackingRef = useIntersectionTracking(
    buildAdObservedData(
      props.ad,
      props.componentName,
      props.adZones,
    ),
    hasAdCardRequiredFields(props.ad),
  );

  useViewTracking(
    buildAdImpressionData(
      props.ad,
      props.componentName,
      props.adZones,
    ),
    hasAdCardRequiredFields(props.ad),
  );

  if (!hasAdCardRequiredFields(props.ad)) {
    return null;
  }

  const { imageAdCreative } = props.ad;

  return (
    <CoreLink
      className="amp-card"
      to={imageAdCreative.ctaHref}
      style={{ backgroundColor: imageAdCreative.backgroundColor }}
      data-ad-card
      clickEvent={
        buildAdClickData(
          props.ad,
          props.componentName,
          props.adZones,
        )
      }
    >
      <ContentBlock
        title={imageAdCreative.title}
        subtitle={imageAdCreative.subtitle}
        textColor={imageAdCreative.titleColor}
      />
      <div
        className="amp-card__image-wrapper"
        ref={intersectionTrackingRef}
      >
        <img
          src={imageAdCreative.overlayImage}
          alt={imageAdCreative.title}
          className="amp-card__image"
        />
      </div>
      <CallToAction
        ctaText={imageAdCreative.ctaText}
        ctaButtonColor={imageAdCreative.ctaButtonColor}
        ctaTextColor={imageAdCreative.ctaTextColor}
      />
      {
        !imageAdCreative.hideAdCallout &&
        <div className="amp-card__amp-callout">
          <AdTag small />
        </div>
      }
    </CoreLink>
  );
}
