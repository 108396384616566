import PropTypes from 'prop-types';
import React from 'react';
import ScrollToItem from '../shared/scroll_to_item';
import classNames from 'classnames';

const DOWN_ARROW = 40;
const UP_ARROW = 38;
const ENTER = 13;

class ScrollableList extends React.Component {
  constructor(props) {
    super(props);

    this.onChange = this.onChange.bind(this);
    this.handleArrows = this.handleArrows.bind(this);

    this.state = {
      focused: null,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.items !== nextProps.items) {
      this.setState({ focused: null });
    }
  }

  componentDidUpdate() {
    const focusedItem = this.refs[`item-${this.state.focused}`];
    const { list } = this.refs;
    new ScrollToItem(focusedItem, list).scrollToItem();
  }

  onChange(e) {
    if (this.props.changeEvent) {
      this.props.changeEvent(e);
    }
  }

  handleArrows(e) {
    if (e.keyCode === DOWN_ARROW) {
      this.focusNext();
    } else if (e.keyCode === UP_ARROW) {
      this.focusPrevious();
    } else if (e.keyCode === ENTER && this.props.onEnter) {
      this.props.onEnter(e);
    }
  }

  // used by ProductSearchForm in handleSubmit()
  focusedItem() {
    return this.props.items[this.currentFocus()];
  }

  currentFocus() {
    let currentFocus = this.state.focused;
    if (currentFocus === null) {
      currentFocus = -1;
    }
    return currentFocus;
  }

  focusNext() {
    const currentFocus = this.currentFocus();
    if (currentFocus < this.props.items.length - 1) {
      this.setState({ focused: currentFocus + 1 });
    }
  }

  focusPrevious() {
    const currentFocus = this.currentFocus();
    if (currentFocus > 0) {
      this.setState({ focused: currentFocus - 1 });
    }
  }

  items() {
    return this.props.items.map((result, index) => (
      <li
        key={result.id}
        ref={`item-${index}`}
        className={classNames('product-search-form__row', {
          'product-search-form__item--active': this.state.focused === index,
        })}
      >
        {this.props.renderItem(result)}
      </li>
    ));
  }

  get shouldShowItems() {
    return this.props.items.length && !this.props.hideItems;
  }

  renderItems() {
    if (!this.shouldShowItems) {
      return null;
    }

    return (
      <ul className="product-search-form__rows" ref="list">
        {this.items()}
      </ul>
    );
  }

  render() {
    const inputClasses = classNames(
      'product-search-form__input',
      { 'product-search-form__input--with-results': this.shouldShowItems },
    );

    return (
      <div
        className="product-search-form"
        data-product-search-form
      >

        <input
          className={inputClasses}
          autoComplete={this.props.autoComplete}
          autoFocus={this.props.autoFocus}
          name={this.props.name}
          placeholder={this.props.placeholder}
          type={this.props.type}
          ref="input"
          onChange={this.onChange}
          onKeyDown={this.handleArrows}
          value={this.props.inputValue}
        />

        {this.renderItems()}
      </div>
    );
  }
}

ScrollableList.propTypes = {
  renderItem: PropTypes.func.isRequired,
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  hideItems: PropTypes.bool,
  changeEvent: PropTypes.func,
  onEnter: PropTypes.func,
  listClass: PropTypes.string,
  autoComplete: PropTypes.string,
  autoFocus: PropTypes.string,
  className: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  inputValue: PropTypes.string,
};

ScrollableList.defaultProps = {
  hideItems: false,
};

export default ScrollableList;
