import React from 'react';
import { core_apimessages_TaxProfile_TaxProfileVerificationStatus as VerificationStatus } from '@reverbdotcom/commons/src/gql/graphql';
import { I18N as Translate } from '@reverbdotcom/commons/src/components/translate';
import { useHelpCenterArticleUrl } from '@reverbdotcom/commons/src/url_helpers';
import CoreLink from '@reverbdotcom/commons/src/components/core_link';

const HC_1099K_ARTICLE_ID = '35233186653459';

const SUBMITTED_STATUSES = [
  VerificationStatus.PENDING_VERIFICATION,
  VerificationStatus.VERIFIED,
];

export default function USTaxProfileContainerDescription({ taxProfile, isEditing }) {
  const helpCenterArticleUrl = useHelpCenterArticleUrl(HC_1099K_ARTICLE_ID);

  if (isEditing || !SUBMITTED_STATUSES.includes(taxProfile?.verificationStatus)) {
    return (
      <>
        <Translate tag="p" text="discovery.taxProfile.usTaxProfile.explainer" />
        <Translate tag="p" text="discovery.taxProfile.usTaxProfile.formEligibilityVerification" />
        <CoreLink
          to={helpCenterArticleUrl}
          target="_blank"
          className="text-link"
        >
          <Translate tag="p" text="discovery.taxProfile.usTaxProfile.helpCenterLinkText" />
        </CoreLink>
      </>
    );
  }

  if (taxProfile?.verificationStatus === VerificationStatus.PENDING_VERIFICATION) {
    return (
      <Translate text="discovery.taxProfile.usTaxProfile.taxInfoPendingVerification" />
    );
  }

  if (taxProfile?.verificationStatus === VerificationStatus.VERIFIED) {
    return (
      <Translate text="discovery.taxProfile.usTaxProfile.completedTaxProfile" />
    );
  }
}
