import {
  RCButton,
  RCTextWithIcon,
  RCCheckbox,
  RCTextArea,
  RCLoadingBars,
} from '@reverbdotcom/cadence/components';
import { ErrorAlertBox } from '@reverbdotcom/commons/src/ErrorAlertBox';
import { StarEmptyIcon, StarFilledIcon, PencilIcon, CloseIcon, ChevronUpIcon, ChevronDownIcon } from '@reverbdotcom/cadence/icons/react';
import React, { useState }  from 'react';
import { gql } from '@reverbdotcom/commons/src/gql';
import { useMutation } from '@reverbdotcom/commons/src/useMutation';
import { formatInternationalizedDate } from '../../lib/date_utils';
interface RowProps {
  note: any;
}

export const UPDATE_ADMIN_NOTE_MUTATION = gql(`
  mutation Core_UpdateAdminNote($input: Input_core_apimessages_AdminNoteUpdateRequest) {
    updateAdminNote(input: $input) {
      id
      linkHtml
      createdAt
      createdByName
      lastEditedByName
      updatedAt
      userCanEdit
      body
      isPinned
      versions {
        id
        editedAt
        editedByName
        body
      }
    }
  }
`);


export default function AdminNoteRow({
  note,
}: RowProps) {

  const [isEditing, setIsEditing] = useState(false);
  const [ noteBody, setNoteBody ] = useState(note.body);
  const [ isPinned, setIsPinned ] = useState(note.isPinned);
  const [ collapsed, setCollapsed ] = useState(true);

  const [updateNoteMutation, { loading, errors }] = useMutation(UPDATE_ADMIN_NOTE_MUTATION);

  async function updateNote() {
    const result = await updateNoteMutation({
      variables: {
        input: {
          id: note.id,
          body: noteBody,
          pinned: isPinned,
        },
      },
      refetchQueries: ['adminNotesSearch'],
      awaitRefetchQueries: true,
    });

    if (result?.data?.updateAdminNote?.id) {
      setIsEditing(false);
    }
  }

  async function togglePin() {
    updateNoteMutation({
      variables: {
        input: {
          id: note.id,
          body: note.body,
          pinned: !note.isPinned,
        },
      },
      refetchQueries: ['adminNotesSearch'],
      awaitRefetchQueries: true,
    });
  }

  const versionCount = note.versions.length;
  const hasEdits = versionCount > 0;

  return isEditing ? (
    <tr>
      <td className="width-70">
        <RCTextArea
          id={`noteBody-${note.id}`}
          label=""
          name={`noteBody-${note.id}`}
          value={noteBody}
          onChange={(e) => setNoteBody(e.target.value)}
        />
        <div className="d-flex fx-align-center">
          <RCCheckbox
            checked={isPinned}
            onChange={(e) => setIsPinned(e.target.checked)}
            label="Pinned"
            id={`pinned-${note.id}`}
            name={`pinned-${note.id}`}
          />
        </div>
        <div className="mt-2">
          <em dangerouslySetInnerHTML={{ __html: note.linkHtml }}></em>
        </div>

        {
          errors.length > 0 && (
            <div className="ml-2 mt-2">
              <ErrorAlertBox errors={errors} />
            </div>
          )
        }
      </td>
      <td className="width-20">
        {note.createdByName}
        <div className="mt-2">
          {formatInternationalizedDate(note.createdAt, 'L h:mma')}
        </div>
      </td>
      <td className="width-10">
        <div className="d-flex fx-wrap">
          <div className="mr-2 mb-2">
            <RCButton
              size="mini"
              variant="filled"
              onClick={updateNote}
              id={`updateNote-${note.id}`}
              disabled={loading}
            >
              {loading ? <RCLoadingBars /> : 'Update Note'}
            </RCButton>
          </div>
          <RCButton
            id={`cancelEdit-${note.id}`}
            variant="muted"
            size="mini"
            onClick={() => setIsEditing(!isEditing)}
            disabled={loading}
          >
            <RCTextWithIcon
              svgComponent={CloseIcon}
              title="Cancel"
            >
              Cancel
            </RCTextWithIcon>
          </RCButton>
        </div>
      </td>
    </tr>
  ) : (
    <tr>
      <td className="width-70">
        <div dangerouslySetInnerHTML={{ __html: note.body.replaceAll('\n', '<br>') }}></div>
        {note.linkHtml && (
          <div className="mt-2">
            <em dangerouslySetInnerHTML={{ __html: note.linkHtml }} />
          </div>
        )}
        {hasEdits && (
          <>
            <div className="mt-2 align-right">
              <div>&mdash; {note.lastEditedByName} at { formatInternationalizedDate(note.updatedAt, 'L h:mma') }</div>
              <button
                type="button"
                onClick={() => setCollapsed(value => !value)}
                className="button-as-link"
              >
                <RCTextWithIcon
                  svgComponent={collapsed ? ChevronDownIcon : ChevronUpIcon}
                  title="Collapsed"
                >
                  { collapsed ? 'See edit history' : 'Hide edit history' }
                </RCTextWithIcon>
              </button>
            </div>
            {!collapsed && (
              <div>
                <hr />
                <div className="pl-8 pr-8">
                  {note.versions.map((version, idx) => (
                    <div key={version.versionId} className="bdb-1 pb-1 pt-2">
                      <div>{version.body}</div>
                      <div className="align-right">
                        <em>&mdash; {version.editedByName} at { formatInternationalizedDate(version.editedAt, 'L h:mma') }</em>
                      </div>
                      {idx != versionCount - 1 ? <hr /> : null}
                    </div>
                  ))}
                </div>
              </div>
            )}
          </>
        )}
      </td>
      <td className="width-20">
        {note.createdByName}
        <div>
          { formatInternationalizedDate(note.createdAt, 'L h:mma') }
        </div>
      </td>
      <td className="width-10">
        <div className="d-flex fx-wrap">
          <div className="mr-2 mb-2">
            <RCButton
              id={`pinNote-${note.id}`}
              variant="muted"
              size="mini"
              onClick={togglePin}
            >
              <RCTextWithIcon
                svgComponent={note.isPinned ? StarFilledIcon : StarEmptyIcon}
                title="Pinned"
              >
                {loading ? <RCLoadingBars /> : (
                  note.isPinned ? 'Unpin' : 'Pin'
                )}
              </RCTextWithIcon>
            </RCButton>
          </div>
          {note.userCanEdit && (
            <RCButton
              id={`editNote-${note.id}`}
              variant="muted"
              size="mini"
              onClick={() => setIsEditing(!isEditing)}
            >
              <RCTextWithIcon
                svgComponent={PencilIcon}
                title="Edit"
              >
                Edit
              </RCTextWithIcon>
            </RCButton>
          )}
        </div>
      </td>
    </tr>
  );
}
