import I18n from 'i18n-js';
import React from 'react';
import SignupSigninModal from '@reverbdotcom/commons/src/components/signup_signin_modal';
import LocationUtil from '../shared/location_util';
import { FOLLOW_PROMPT_PARAM } from '../shared/constants';
import { HeartEmptyIcon } from '@reverbdotcom/cadence/icons/react';
import { RCIcon } from '@reverbdotcom/cadence/components/RCIcon/RCIcon';
import classNames from 'classnames';
import { useFindFavorite, FavoriteModal } from './find_favorite_context';
import { EntityType, mapFavoriteToEntityType } from '@reverbdotcom/commons/src/favorites/entity_type';

interface Props {
  renderWithBanner: boolean;
  hideLoggedOutModal?: boolean;
  buttonTypeOverride?: 'link' | 'secondary';
}

export const LoggedOutFavoriteButton = function (props: Props) {
  const { favorite, canFavorite, displayedModal, setDisplayedModal } = useFindFavorite();
  const entityType = mapFavoriteToEntityType(favorite);
  const modalOpen = displayedModal === FavoriteModal.Signup && !props.hideLoggedOutModal;

  const handleClick = function (evt) {
    evt.preventDefault();

    if (!canFavorite) {
      return;
    }

    setDisplayedModal(FavoriteModal.Signup);
  };

  const handleModalClose = function () {
    setDisplayedModal(FavoriteModal.None);
  };

  const buttonType = props.buttonTypeOverride || (entityType === EntityType.Product || props.renderWithBanner ? 'secondary' : 'link');
  const classes = classNames(
    'favorite-button__button',
    {
      'favorite-button__button--link': buttonType === 'link',
      'favorite-button__button--secondary': buttonType === 'secondary',
      'favorite-button__button--disabled': !canFavorite,
    },
  );

  return (
    <div className="favorite-button">
      <button
        type="button"
        className={classes}
        onClick={handleClick}
        data-disabled={!canFavorite || null}
      >
        <RCIcon svgComponent={HeartEmptyIcon} inline />
        {I18n.t(`discovery.favorites.favoriteButton.save.${entityType}`)}
      </button>
      <SignupSigninModal
        onRequestClose={handleModalClose}
        active={modalOpen}
        message={I18n.t('discovery.searchSignup.fullLoginMessage')}
        redirectTo={LocationUtil.currentPageWithParams({ [FOLLOW_PROMPT_PARAM]: true })}
        trackSource="FavoriteButton"
      />
    </div>
  );
};
