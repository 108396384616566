class OfferItem {
  constructor(json) {
    // We preferentially use listing.price because it will include VAT if relevant. However, if the seller is
    // browsing in something other than their shop currency, we go by the seller price (which won't include VAT, but has accurate currency).
    const userCurrencyMatchesShopCurrency = json.listing.price.currency === json.listing.listing_currency;

    this.json = json;
    this.listing = json.listing;
    this.price = userCurrencyMatchesShopCurrency ? json.listing.price.amount : json.listing.seller_price.amount;
    this.shippingPrice = json.listing.shipping.initial_offer_rate.rate.original.amount;
    this.currency = json.listing.listing_currency;
    this.listingId = json.listing.id;
  }
}

export default OfferItem;
