import React from 'react';
import I18n from 'i18n-js';
import { YOUTUBE_IMAGE } from '../../../url_helpers';

interface IProps {
  videoSlugs: string[];
  selectedVideoSlug: string;
  videoData: Record<string, string>[];
  onClick: (slug: string) => void;
}

export function VideoPlayerSidebar({
  videoSlugs = [],
  selectedVideoSlug = '',
  videoData = [],
  onClick,
}: IProps) {

  if (videoSlugs.length === 0) {
    return null;
  }

  return (
    <div className="video-player-sidebar">
      {videoSlugs.map(slug => {
        if (slug === selectedVideoSlug) {
          return null;
        }

        const backgroundImage = YOUTUBE_IMAGE.expand({ slug });
        const { title, viewCount, thumbnail } = videoData.find(i => i.id === slug);

        return (
          <div className="video-player-sidebar__video-wrapper" key={slug}>
            <button
              type="button"
              className="video-player-sidebar__video"
              onClick={() => onClick(slug)}
              key={slug}
            >
              <div className="video-player-sidebar__image-wrapper">
                <div
                  className="video-player-sidebar__image"
                  style={{ backgroundImage: `url("${thumbnail || backgroundImage}"` }}
                />
              </div>

              <div className="video-player-sidebar__video-info">
                <div className="video-player-sidebar__video-title">{title}</div>
                {viewCount &&
                  <div className="video-player-sidebar__video-views">{`${I18n.t('cms.videoPlayer.views', { viewCount })}`}</div>
                }
              </div>
            </button>
          </div>
        );
      })}
    </div>
  );
}
