import alt from '../../alt';
import APIRequest from '../../api_request';
import URLHelpers from '../../shared/url_helpers';

class CategoriesActions {
  loaded(response) {
    return response;
  }

  fetch() {
    return () => {
      APIRequest.V3.get(URLHelpers.categoriesFlatPath()).then((response) => {
        this.loaded(response);
      }).fail((err) => {
        throw (err);
      });
    };
  }
}

export default alt.createActions(CategoriesActions);
