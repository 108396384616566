import { ChildProps } from '@apollo/client/react/hoc';
// TODO update this to import from commons
// import { gql } from '@reverbdotcom/commons/src/gql';
// eslint-disable-next-line no-restricted-imports
import { gql } from '@apollo/client';
import { withGraphql } from '@reverbdotcom/commons/src/with_graphql';
import React from 'react';
import FeedbackMessages from './feedback_messages';
import { RCLoadingBars } from '@reverbdotcom/cadence/components';
import I18n from 'i18n-js';
import { MyFeedbackMessages } from '@reverbdotcom/commons/src/gql/graphql';

type IProps = ChildProps<unknown, MyFeedbackMessages.Query>;

export function MyFeedbackMessagesContainer(props: IProps) {
  const messages = props.data?.myFeedbackMessages?.feedbackMessages || [];
  return (
    <div className="g-container d-flex mt-4">
      <div className="g-col-6">
        <header className="d-flex fx-dir-col fx-gap-3">
          <h3 className="heading-3">
            {I18n.t('discovery.feedbackMessages.title')}
          </h3>
          <p>
            {I18n.t('discovery.feedbackMessages.description')}
          </p>
        </header>
        {props.data.loading &&
          <div className="d-flex fx-align-center fx-justify-center">
            <RCLoadingBars/>
          </div>
        }
        {!props.data.loading && <FeedbackMessages messages={messages}/>}
      </div>
    </div>
  );
}

const MyFeedbackMessagesQuery = gql`
  query MyFeedbackMessages {
    myFeedbackMessages {
      feedbackMessages {
        id
        message
      }
    }
  }
`;

const withQuery = withGraphql<IProps, MyFeedbackMessages.Query>(MyFeedbackMessagesQuery);

export default withQuery(MyFeedbackMessagesContainer);

