import React from 'react';
import I18n from 'i18n-js';
import moment from 'moment';

import { RCAlertBox, RCLoadingBars, RCIcon } from '@reverbdotcom/cadence/components';
import HighFive from '@reverbdotcom/cadence/images/brand/high-five-orange.svg?component';
import { DateRange, DATE_RANGE_INPUTS } from './shop_refund_stats';

export interface IProps {
  dateRange?: DateRange;
  loading: boolean;
  error: boolean;
  count?: number;
}

export default function ShopRefundStatsResults({
  dateRange,
  loading,
  error,
  count,
}: IProps) {
  if (loading) {
    return (
      <div className="shop-refund-stats__results shop-refund-stats__results__loading">
        <RCLoadingBars />
      </div>
    );
  }

  if (error || typeof count === 'undefined') {
    return (
      <div className="shop-refund-stats__results shop-refund-stats__error">
        <RCAlertBox type="error">{I18n.t('discovery.dashboard.refundStats.error')}</RCAlertBox>
      </div>
    );
  }

  if (count === 0) {
    return (
      <div
        className="shop-refund-stats__results shop-refund-stats__no-refunds"
        id="no-refunds"
      >
        <div>
          {I18n.t('discovery.dashboard.refundStats.noRefunds')}
        </div>
        <div className="shop-refund-stats__icon">
          <RCIcon svgComponent={HighFive} />
        </div>
      </div>
    );
  }

  const dateDisplayFormat = 'LL';
  const startDate = moment.utc(DATE_RANGE_INPUTS[dateRange].startDate).format(dateDisplayFormat);
  const endDate = DATE_RANGE_INPUTS[dateRange].endDate ?
    moment.utc(DATE_RANGE_INPUTS[dateRange].endDate).format(dateDisplayFormat) : undefined;

  const dateDisplay = endDate ?
    I18n.t('discovery.dashboard.refundStats.dateDisplay.dateRange', { startDate, endDate }) :
    I18n.t('discovery.dashboard.refundStats.dateDisplay.sinceStartDate', { startDate });

  return (
    <div className="shop-refund-stats__results">
      <p className="shop-refund-stats__results__count-label">
        {I18n.t('discovery.dashboard.refundStats.refundsCountLabel')}
      </p>
      <div>
        <span className="shop-refund-stats__results__count" id="count">
          {I18n.t('discovery.dashboard.refundStats.count', { count })}
        </span>
        <span className="shop-refund-stats__results__subtext" id="date-display">
          {dateDisplay}
        </span>
      </div>
    </div>
  );
}
