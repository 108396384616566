import { core_apimessages_ShippingMethod as ShippingMethod } from '@reverbdotcom/commons/src/gql/graphql';

interface CheckoutOrder {
  shippingMethod?: {
    type?: string;
  }
}

interface Checkout {
  orders?: CheckoutOrder[];
}

export function isCheckoutLocalPickupOnly(checkout: Checkout) {
  return checkout.orders.every((order) => order.shippingMethod?.type === ShippingMethod.LOCAL);
}

export function isCheckoutDigitalDeliveryOnly(checkout: Checkout) {
  return checkout.orders.every((order) => order.shippingMethod?.type === ShippingMethod.DIGITAL_DELIVERY);
}

export function isCheckoutShipped(checkout: Checkout) {
  const shippingMethods = checkout.orders.map((order) => order.shippingMethod?.type);

  return shippingMethods.some((shippingMethodType) =>
    !(shippingMethodType === ShippingMethod.LOCAL || shippingMethodType === ShippingMethod.DIGITAL_DELIVERY),
  );
}
