import React from 'react';
import { RCGrid } from '@reverbdotcom/cadence/components';

interface ExternalProps {
  title: JSX.Element | string;
  collection: JSX.Element;
  callToAction?: JSX.Element;
}

export default function IndentedRow(props: ExternalProps) {
  function indentedTitle() {
    return (
      <div className="indented-row-titles">
        <div className="indented-row__title">
          {props.title}
        </div>
        {
          props.callToAction &&
          <div className="indented-row__action">
            {props.callToAction}
          </div>
        }
      </div>
    );
  }

  return (
    <div className="indented-row-content">
      <RCGrid singleRowOn="all">
        <RCGrid.Item
          key={0}
          colSpan={{
            desktop: 4,
          }}
          hideOn="tabletAndMobile"
        >
          {indentedTitle()}
        </RCGrid.Item>
        <RCGrid.Item
          key={1}
          colSpan={{
            desktop: 8,
            tablet: 12,
            mobile: 12,
          }}
        >
          <div className="indented-row-collection">
            {props.collection}
          </div>
        </RCGrid.Item>
      </RCGrid>
    </div>
  );
}
