export const AVAILABLE_LOCALES = ['en', 'de', 'es', 'fr', 'nl', 'it'];
export const CURATED_SET_ROW_LENGTH = 4;
export const CURRENCY_SYMBOLS = {
  usd: '&#36;',
  eur: '&#8364;',
  gbp: '&#163;',
  jpy: '&#165;',
  aud: '&#36;',
  cad: '&#36;',
};

export const SIDEBAR_TRACK_NAME = 'GridFacetsSidebar';

export const FOLLOWABLE_CATEGORIES = [
  '62835d2e-ac92-41fc-9b8d-4aba8c1c25d5',
  '3ca3eb03-7eac-477d-b253-15ce603d2550',
  '09055aa7-ed49-459d-9452-aa959f288dc2',
  '032c74d0-b0e2-4442-877f-e1a22438a7fa',
  '53a9c7d7-d73d-4e7f-905c-553503e50a90',
  '58d889f7-0aa1-4689-a9d3-da16dd225e8d',
  'b3cb9f8e-4cb6-4325-8215-1efcd9999daf',
  'fa10f97c-dd98-4a8f-933b-8cb55eb653dd',
  'dfd39027-d134-4353-b9e4-57dc6be791b9',
  'fb60628c-be4b-4be2-9c0f-bc5d31e3996c',
  '40e8bfd0-3021-43f7-b104-9d7b19af5c2b',
  'd002db05-ab63-4c79-999c-d49bbe8d7739',
  '1f99c852-9d20-4fd3-a903-91da9c805a5e',
  'b021203f-1ed8-476c-a8fc-32d4e3b0ef9e',
  'e71b6e43-7217-4746-b4e3-64bf708a2664',
];

export const PRICE_ASC_SORT = 'price|asc';
export const DEFAULT_CONDITION = 'all';
export const DEFAULT_EMPTY_GRID_ROWS = 2;
export const DEFAULT_PAGE = 1;
export const EVERYWHERE_CODE = 'XX';
export const US_CON_CODE = 'US_CON';
export const CANADA_REGION_CODE = 'CA';
export const PUERTO_RICO_REGION_CODE = 'PR';
export const NEW_CONDITION = 'new';
export const USED_CONDITION = 'used';
export const MY_FAVORITES_LANDING_ROUTE_PATH = 'my/favorites';
export const MY_FAVORITES_WATCH_LIST_ROUTE_PATH = 'my/favorites/watch-list';
export const MY_FAVORITES_WATCH_LIST_ENDED_ROUTE_PATH = 'my/favorites/ended';
export const MY_FAVORITES_SEARCHES_ROUTE_PATH = 'my/favorites/searches';
export const MY_FAVORITES_SHOPS_ROUTE_PATH = 'my/favorites/shops';
export const MY_FAVORITES_FEED_ROUTE_PATH = 'my/favorites/feed';
export const MY_FAVORITES_DISCOVER_ROUTE_PATH = 'my/favorites/discover';
export const MAKE_ROUTE_PATH = 'brand/:brand_slug';
export const CSP_INDEX_ROUTE = 'popular-products';
export const SALE_ROUTE_PATH = 'sales/:sale_slug';
export const ITEM_SHOW_ROUTE_PATH = 'item/:id';
export const ITEM_PREVIEW_PATH = 'preview/:id';
export const ITEM_PREVIEW_CONFIRM_PATH = 'preview/:id/confirm';
export const HANDPICKED_ROUTE_PATH = 'handpicked/:curated_set_slug';
export const PERSONAL_HANDPICKED_ROUTE_PATH = 'profile/:profile_slug/handpicked/:curated_set_slug';
export const PROFILE_FOLLOWERS_ROUTE_PATH = 'profile/:profile_slug/followers';
export const MARKETPLACE_PATH = 'marketplace';
export const PRODUCT_TYPE_PARAM = 'product_type';
export const CATEGORY_UUID_PARAM = 'category_uuid';
export const CATEGORY_PARAM = 'category';
export const LP_NATIVE = 'lp_native';
export const IOS = 'ios';
export const ANDROID = 'android';
export const SELLER_LOCATION_FILTER = 'seller_location';
export const BRAND_NEW_CONDITION = 'brand-new';
export const SHIPS_TO_FILTER_NAME = 'ships_to';

export const ENDED_LISTING_STATES = {
  ENDED: 'ended',
  ORDERED: 'ordered',
  SOLD_OUT: 'sold_out',
};

export const pills = {
  CATEGORIES_NAME: 'categories',
  DECADES_NAME: 'decades',
  US_CON_REGION: 'US_CON',
  DEFAULT_REGION: 'XX',
  MAKES_NAME: 'makes',
  MULTI_SELECT_TYPE: 'multi_select',
  RANGE_TYPE: 'range',
  OPEN_INPUT_TYPE: 'open_input',
  PLACEHOLDER_TYPE: 'placeholder',
};

export const cloudinaryTransforms = {
  H300_W600: 'a_exif,c_fill,f_auto,fl_progressive,g_north,h_300,q_auto:eco,w_600',
  H226_W226: 'a_exif,c_thumb,fl_progressive,g_south,h_226,q_auto:eco,w_226',
};

export const RECENT_SEARCH = 'recentSearch';

export const FOLLOW_PROMPT_PARAM = 'follow_prompt';
export const WATCH_PROMPT_PARAM = 'watch_prompt';

export const LEGACY_OFFERS_LISTING_TYPE = 'offers';

export const SALE_BUYER_INELIGIBILITY_REASONS = {
  NOT_STARTED: 'sale_not_started',
  ENDED: 'ended',
  MUST_BE_FIRST_PURCHASE: 'must_be_first_purchase',
  MUST_BE_ON_MOBILE: 'must_be_on_mobile',
  MUST_BE_ACTIVATED: 'must_be_activated',
  MUST_BE_STUDENT: 'must_be_student',
  NOT_FIRST_SALE_PURCHASE: 'not_first_sale_purchase',
  TIME_EXPIRED: 'time_expired',
  LOGIN_REQUIRED: 'login_required',
  LOGIN_REQUIRED_SHOW_INVENTORY: 'login_required_show_inventory',
  GENERIC: 'not_eligible',
};

export const EXPERIMENT_VALUES = {
  CTL: 'ctl',
  EXP1: 'exp1',
  EXP2: 'exp2',
  EXP3: 'exp3',
};

export const PAYMENT_PROCESSING_FEES = {
  STANDARD: 3.19,
  PREFERRED_SELLER: 2.99,
};

export const ARTICLE_PAGE_TYPE = 'article';
export const CRATE_PAGE_TYPE = 'crate';
// eslint-disable-next-line max-len
export const CORE_DEFAULT_OG_IMAGE_URL = 'https://static.reverb-assets.com/assets/homepage/open-graph-2a0cbbc49a26d873db03aae487defc6d.jpg';
export const LISTING_GUIDELINES_URL = 'https://help.reverb.com/hc/articles/360013231013';

export const LOAD_CHAT_WINDOW = 'load_chat_window';

export const LP_CATEGORY_UUID = 'b891b5df-051c-4ee1-ac91-c9a38f62ef90';

export const FLAG_LISTING_REASONS = {
  FRAUDULENT: 'fraudulent',
  INCORRECT_PRODUCT_INFO: 'incorrect_product_information',
  TRADEMARK_INFRINGEMENT: 'trademark_infringement',
  VIOLATES_GUIDELINES: 'guidelines_violation_html',
  INCORRECT_CANONICAL_PRODUCT: 'incorrect_canonical_product',
};

export const MIN_BUMP_AMOUNT_PERCENT = 0.5;
export const MAX_BUMP_AMOUNT_PERCENT = 30;
export const BUMP_INCREMENT_AMOUNT_PERCENT = 0.5;

// Languages for locale picker
export const LANGUAGES = {
  en: 'English',
  fr: 'Français',
  ja: '日本語',
  es: 'Español',
  de: 'Deutsch',
  it: 'Italiano',
  eo: 'Esperanto',
};

export const BESTSELLER_SUBCATEGORIES_TO_EXCLUDE = [
  'pedal-steel',
  'software',
  'samples-and-loops',
  'digital-audio-workstations',
  'instruments',
  'acoustic-pianos',
  'grand-pianos',
  'upright-pianos',
  'bajo-quintos',
  'bajo-sextos',
  'guitarrones',
  'harmoniums',
  'ouds',
  'shrutis',
  'sitars',
  'vihuelas',
  'cables',
  'complete-stereo-systems',
  'equalizers',
  'tape-decks',
  'tuners-and-radios',
  'case-candy',
];

export default {
  SALE_BUYER_INELIGIBILITY_REASONS,
  AVAILABLE_LOCALES,
  CORE_DEFAULT_OG_IMAGE_URL,
  CURATED_SET_ROW_LENGTH,
  CURRENCY_SYMBOLS,
  DEFAULT_CONDITION,
  DEFAULT_EMPTY_GRID_ROWS,
  DEFAULT_PAGE,
  EVERYWHERE_CODE,
  US_CON_CODE,
  NEW_CONDITION,
  USED_CONDITION,
  MAKE_ROUTE_PATH,
  CSP_INDEX_ROUTE,
  SALE_ROUTE_PATH,
  ITEM_SHOW_ROUTE_PATH,
  ITEM_PREVIEW_PATH,
  ITEM_PREVIEW_CONFIRM_PATH,
  MARKETPLACE_PATH,
  PRODUCT_TYPE_PARAM,
  CATEGORY_UUID_PARAM,
  CATEGORY_PARAM,
  pills,
  cloudinaryTransforms,
  RECENT_SEARCH,
  EXPERIMENT_VALUES,
  PAYMENT_PROCESSING_FEES,
  ARTICLE_PAGE_TYPE,
  CRATE_PAGE_TYPE,
  LANGUAGES,
};
