import React from 'react';
import CoreClient from '../lib/core_client';
import { Paths } from './shared/url_helpers';
import { debounce } from 'lodash';

export function useShopDisbursements({ shopID, inputAmount, currencyCode }): Disbursement[] {
  const [disbursements, setDisbursements] = React.useState([]);

  const debounceFetchDisbursements = React.useCallback(
    debounce(async (inputAmount, currencyCode) => {
      if (!(inputAmount && currencyCode)) {
        setDisbursements([]);
        return;
      }

      const response = await CoreClient.get<GetShopDisbursementsResponse>(
        Paths.apiAdminShopDisbursements.expand({ shopID, amount: inputAmount, currency: currencyCode }),
      );
      setDisbursements(response.disbursements);
    }, 500),
    [],
  );

  React.useEffect(() => {
    function fetchDisbursements() {
      debounceFetchDisbursements(inputAmount, currencyCode);
    }

    fetchDisbursements();
  }, [shopID, inputAmount, currencyCode]);

  return disbursements;
}

export interface Disbursement {
  id: number;
  shop_id: number;
  created_at: string;
  amount: {
    amount: string;
    amount_cents: number;
    currency: string;
    symbol: string;
    display: string;
  }
}

interface GetShopDisbursementsResponse {
  disbursements: Disbursement[];
}
