import { get as cookieGet } from 'js-cookie';
import { pickBy, identity } from 'lodash';
import I18n from 'i18n-js';
import { currentSession } from './elog';
import { IUser } from './components/user_context_provider';
import { authHeader } from './http_auth_header';
import { flashExpireAuthError } from './flash';

/**
 * Generic http client wrapper for `fetch` for easier mocking.
 *
 * This _will_ make authenticated requests against _any_ API via
 * the reverb_user_oauth_token.
 *
 * This was used to avoid CoreClient, which assumes V4 API endpoints,
 * and APIRequest.js which still uses jQuery to make requests.
 *
 * Use this where you would use `fetch`.
 *
 * See: commons/src/components/newsletter_signup.tsx for an example usage
 * See: commons/__test__/components/newsletter_signup_test.tsx for an example
 *      on how to mock these requests using jest.mock
 */
const V3_VERSION = '3.0';
const EXPIRED_TOKEN_ERROR = 'expired_token_error';

export function get(url: string, user?: Partial<IUser>, input?: RequestInit): Promise<Response> {
  return request(url, 'GET', user, input);
}

export function post(url: string, user: Partial<IUser>, input?: RequestInit): Promise<Response> {
  return request(url, 'POST', user, input);
}

export function put(url: string, user: Partial<IUser>, input?: RequestInit): Promise<Response> {
  return request(url, 'PUT', user, input);
}

export function destroy(url: string, user: Partial<IUser>, input?: RequestInit): Promise<Response> {
  return request(url, 'DELETE', user, input);
}

export function request(url: string, method: string, _user?: Partial<IUser>, input?: RequestInit): Promise<Response> {
  const csrf = cookieGet('csrf_token');

  const headers = {
    'X-CSRF-Token': csrf,
    ...authHeader(),
    ...input.headers,
  };
  const options = { method, ...input, headers: { ...headers } };

  const resp = fetch(url, options);
  resp.then(r => { onUnauthorizedErrors(r); });

  return resp;
}

const v3Headers = pickBy(
  {
    'Accept-Version': V3_VERSION,
    'X-Item-Region': cookieGet('itemRegionOverride'),
    'Accept-Language': I18n.locale,
    'X-Session-Id': currentSession().ID(),
    'X-Requested-With': 'XMLHttpRequest',
    'X-Reverb-App': 'REVERB',
  },
  identity,
);

export const V3 = {
  get(url: string, user?: Partial<IUser>, input?: RequestInit): Promise<Response> {
    return get(url, user, { headers: { ...input?.headers, ...v3Headers } });
  },
};

export async function onUnauthorizedErrors(resp: Response) {
  if (!resp || resp.ok) return;

  try {
    const data = await resp.json();
    if (data?.error_type === EXPIRED_TOKEN_ERROR) flashExpireAuthError();
  } catch (e) {
    // ignore
  }
}
