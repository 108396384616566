import I18n from 'i18n-js';
import React from 'react';

import PercentageBar from '@reverbdotcom/commons/src/components/percentage_bar';
import SanitizedRender from '@reverbdotcom/commons/src/components/sanitized_render';
import { SHOP_PAGE } from '../shared/url_helpers';

export interface IFreeShippingStatus {
  thresholdMet: boolean;
  percentProgress: number;
  amountRemainingDisplay: string;
  shopName: string;
  shopSlug: string;
}

export function FreeShippingProgressBar({
  thresholdMet,
  percentProgress,
  amountRemainingDisplay,
  shopName,
  shopSlug,
}: IFreeShippingStatus) {
  return (
    <>
      <div className="bg-offwhite mb-2 bd-radius-primary">
        <PercentageBar animate percentage={percentProgress} color="green" />
      </div>
      {thresholdMet ? (
        <p className="weight-bold mb-0 lh-120">
          {I18n.t('discovery.cart.freeShippingThresholds.thresholdMet', { shopName })}
        </p>
      ) : (
        <SanitizedRender
          html={I18n.t(
            'discovery.cart.freeShippingThresholds.amountRemaining',
            {
              amountRemainingDisplay,
              shopName,
              tagOpen: `<a href="${SHOP_PAGE.expand({ slug: shopSlug })}" target="_blank">`,
              tagClose: '</a>',
            },
          )}
        />
      )}
    </>
  );
}

export default FreeShippingProgressBar;
