import { ChildProps } from '@apollo/client/react/hoc';
// TODO update this to import from commons
// import { gql } from '@reverbdotcom/commons/src/gql';
// eslint-disable-next-line no-restricted-imports
import { gql } from '@apollo/client';
import React from 'react';
import {
  CoreShopSettingsVatLayout,
  core_apimessages_Country as Country,
  core_apimessages_TaxIdentification as TaxIdentification,
  core_apimessages_TaxIdentification_TaxType as TaxType,
} from '@reverbdotcom/commons/src/gql/graphql';
import { ISelectOption } from '@reverbdotcom/commons/src/components/form_group_with_select';
import FormSection from '@reverbdotcom/commons/src/components/form_section';
import { I18N as Translate } from '@reverbdotcom/commons/src/components/translate';
import I18n from 'i18n-js';
import ShopSettingsVatAddVatIdButton from './shop_settings_add_vat_id_button';
import ShopSettingsVatIds from './shop_settings_vat_ids';
import { COUNTRY_CODES } from './shop_settings_vat_supported_countries_and_formats';
import { withGraphql } from '@reverbdotcom/commons/src/with_graphql';
import { RCAlertBox, RCTextWithIcon } from '@reverbdotcom/cadence/components';
import { InfoCircleIcon } from '@reverbdotcom/cadence/icons/react';
import { UNITED_KINGDOM } from './international_tax_profiles/international_tax_profile_constants';

type Props = ChildProps<any, CoreShopSettingsVatLayout.Query>;

interface VatIdHelpTextProps {
  countryCode: string;
}

export const VatIdHelpText = ({ countryCode }: VatIdHelpTextProps) => {
  const vatTextKey = countryCode === UNITED_KINGDOM ? 'ukVatOnFeesDescription' : 'euVatOnFeesDescription';

  return (
    <RCAlertBox
      type="info"
    >
      <RCTextWithIcon
        placement="left"
        svgComponent={InfoCircleIcon}
      >
        <span className="weight-bold"><Translate text={`discovery.shopSettings.vat.${vatTextKey}`} /></span>
      </RCTextWithIcon>
      <Translate
        tag="div"
        text="discovery.shopSettings.vat.addVatIdPrompt"
      />
    </RCAlertBox>
  );
};

export function ShopSettingsVatLayout({ data }: Props) {
  if (data?.loading) {
    return null;
  }

  const taxIdentifications = (data?.me?.shop?.taxIdentifications || []).filter(taxIdentification => taxIdentification.taxType === TaxType.VAT);

  const countries = data?.countries.countries;

  const vatCountries = filterVatCountries(countries);

  return (
    <FormSection
      title={I18n.t('discovery.shopSettings.vat.title')}
      description={<VatIdHelpText countryCode={data?.me?.shop?.address?.country?.countryCode} />}
    >
      { taxIdentifications.length > 0 ? (
        <ShopSettingsVatIds
          taxIdentifications={taxIdentifications}
          countries={vatCountries}
        />
      ) :
        <VatEmptyStateMessage />
      }
      <ShopSettingsVatAddVatIdButton
        taxIdentifications={taxIdentifications}
        countries={vatCountries}
      />
    </FormSection>
  );
}

export function filterVatCountries(countries: Country[]): Country[] {
  return countries.filter(country => COUNTRY_CODES.includes(country.countryCode));
}

export function filterUsedCountries(taxIdentifications: TaxIdentification[]): string[] {
  return taxIdentifications.map(taxIdentification => taxIdentification.countryCode);
}

export function createCountryOptions(countries: Country[], usedCountries: string[]): ISelectOption[] {
  return countries.map(country => {
    const value = country.countryCode;
    const text = country.name;
    const disabled = usedCountries.includes(country.countryCode);

    return ({
      value,
      text,
      disabled,
    });
  });
}

function VatEmptyStateMessage() {
  return (
    <p className="scaling-mb-4">
      <Translate text="discovery.shopSettings.vat.vatEmptyStateMessage" />
    </p>
  );
}

const LAYOUT_QUERY = gql`
  query Core_ShopSettings_VatLayout {
    me {
      _id
      uuid
      shop {
        _id
        taxIdentifications {
          id
          taxId
          taxType
          status
          countryCode
        }
        address {
          _id
          country {
            _id
            countryCode
          }
        }
      }
    }
    countries {
      countries {
        _id
        countryCode
        name
      }
    }
  }
`;

const withQuery = withGraphql<any, CoreShopSettingsVatLayout.Query>(
  LAYOUT_QUERY,
  {
    options: {
      ssr: false, // only rendered for logged in users after clicking a link to open a modal
    },
  },
);

export default withQuery(ShopSettingsVatLayout);
