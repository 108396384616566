import {
  reverb_search_Filter,
} from '@reverbdotcom/commons/src/gql/graphql';
import { renameParams } from '@reverbdotcom/commons/src/facet_utils';
import { isBrandPage, isMarketplacePage, isShopPage, isSimilarItemPage } from '@reverbdotcom/commons/src/url_helpers';
import { Location } from 'history';
import { renameRqlParam } from './shared/rql_param_helper';
import experiments from '@reverbdotcom/commons/src/experiments';
import { Filter } from '@reverbdotcom/commons/src/components/search_filters';
import { IUser } from '@reverbdotcom/commons';
import { isExperimentEnabled } from '@reverbdotcom/commons/src/user_context_helpers';

// Given an array of all listing filters from a graphql query,
// returns only relevant filters (and filter options) to display,
// based on the current user (experiment groups) and URL (brand page, shop page, marketplace, CSP).
export function listingsFilters(
  filters: reverb_search_Filter[],
  location: Location,
  user: Partial<IUser> = undefined,
): reverb_search_Filter[] {
  // Removes the 'combined_shipping' option from the
  // `Show Only` || `Find a Deal` filter if not viewing a shop page
  if (!isShopPage(location)) {
    filters = filters.map((filter) => {
      if (filter.key !== 'SHOW_ONLY' && filter.key !== 'FIND_A_DEAL') {
        return filter;
      }

      return {
        ...filter,
        options: filter.options.filter((o) => o.paramName !== 'combined_shipping'),
      };
    });
  }

  // Removes the sidebar keyword search when viewing a brand or similar item page
  if (isBrandPage(location) || isSimilarItemPage(location)) {
    filters = filters.filter(filter => filter.key !== 'TEXT_QUERY');
  }

  if (isMarketplacePage(location) && isExperimentEnabled(user, experiments.SRP_EXPOSED_FILTERS_V2_DESKTOP)) {
    if (location.query.product_type === 'electric-guitars') {
      filters.sort(electricGuitarsFilterSort);
    } else {
      filters.sort(filterSort);
    }
  }

  return renameParams(filters, renameRqlParam);
}
export function selectedFilters(
  filters: reverb_search_Filter[],
): string {
  const filterValues = [];
  filters.map((filter) => {
    filter.options.filter((o) => {
      if (o.selected) {
        filterValues.push(o.paramName + ':' + o.name);

      }
      if (o.subFilter != null) {
        o.subFilter.options.forEach(s => {
          if (s.selected) {
            filterValues.push(s.paramName + ':' + s.name);
          }
        });
      }
    });
  });
  if (filterValues.length > 0) {
    return filterValues.join('|');
  } else {
    return '';
  }
}

// A high value ensures all other filters will get sorted after all filters with a defined value above
const DEFAULT_SORT_VALUE = 1000;

function filterKey(filter: Filter): string {
  return filter.key || filter.aggregationName;
}

function filterSort(a: Filter, b: Filter): number {
  return filterSortValue(a) - filterSortValue(b);
}

function filterSortValue(filter: Filter) {
  return FILTER_SORT_VALUES[filterKey(filter)] || DEFAULT_SORT_VALUE;
}

function electricGuitarsFilterSort(a: Filter, b: Filter): number {
  return electricGuitarsFilterSortValue(a) - electricGuitarsFilterSortValue(b);
}

function electricGuitarsFilterSortValue(filter: Filter) {
  return ELECTRIC_GUITARS_FILTER_SORT_VALUES[filterKey(filter)] || DEFAULT_SORT_VALUE;
}

const FILTER_SORT_VALUES = {
  'CONDITION_SLUGS': 1,
  'TEXT_QUERY': 2,
  'CATEGORY_SLUGS': 3,
  'SELLER_LOCATION': 4,
  'PRICE': 5,
  'FIND_A_DEAL': 6,
  'LOCATION': 7,
  'BRAND_SLUGS': 8,
  'SHOW_ONLY': 9,
  'DECADES': 10,
  'YEAR': 11,
  'SHIPS_TO': 12,
  'COUNTRY_OF_ORIGIN': 13,
  'BUYING_OPTIONS': 14,
};

const ELECTRIC_GUITARS_FILTER_SORT_VALUES = {
  'CONDITION_SLUGS': 1,
  'TEXT_QUERY': 2,
  'CATEGORY_SLUGS': 3,
  'SELLER_LOCATION': 4,
  'PRICE': 5,
  'FIND_A_DEAL': 6,
  'LOCATION': 7,
  'BRAND_SLUGS': 8,
  'bb810a6d-d699-4fa1-a7fe-ebbca42a1a5f': 9, // Model family trait
  '68d13ff3-9a2c-4e97-884e-6865c2806ad1': 10, // Model subfamily trait
  '9a68cc32-9301-4a47-ac3c-23d989c0b0e5': 11, // Series trait
  'c78557ac-fc14-46bc-abce-5c746680e7b2': 12, // Right/Left-handed trait
  'SHOW_ONLY': 13,
  'DECADES': 14,
  'YEAR': 15,
  'SHIPS_TO': 16,
  'COUNTRY_OF_ORIGIN': 17,
  '610bfe57-58fd-4a7f-a966-c22064f637f3': 18, // Color family trait
  '64dbf67b-2fa8-48f7-9762-c6b445ac6a46': 19, // Number of strings trait
  'd54c23dd-8e8a-408a-86e8-45557054aba8': 20, // String Type trait
  'ca5f02bf-4127-4a5c-be41-8603cd904ffb': 21, // Pickup configuration trait
  '9a355c89-c810-446e-be9f-0def8ef9dad5': 22, // Body shape trait
  'b05df9b7-a6c9-4230-8c67-b32f530b77b4': 23, // Body type trait
  '7bd31a32-a731-46cf-8526-1e44cc081e87': 24, // Body material trait
  '782ee63b-1741-422b-950b-0004506711ef': 25, // Bridge/tailpiece type trait
  '6a24e2dc-800d-4f06-ac67-acef9569a40f': 26, // Fretboard material trait
  '0c3dfa03-e905-4883-afce-f15ffb9d8975': 27, // Fretboard radius trait
  '8efe834a-c390-4adf-a59c-46fed3b84891': 28, // Frets trait
  '00579c3a-c5f2-49c4-8498-1f2ff993ccf3': 29, // Scale length trait
  '4480838c-03d7-425a-9fd0-386dddc38fbf': 30, // Neck material trait
  'f1967c7b-1287-4742-ac07-7557ffa3efc0': 31, // Neck construction trait
  '44660c14-2b74-453b-95f1-acff5a10bf6f': 32, // Finish pattern trait
  '024c6bde-82c6-4c53-8cf2-93a270cdde2b': 33, // Finish style trait
  '5328712b-4b10-434e-9f79-34c9984ffac3': 34, // Finish features trait
  'c0b03e40-f463-433c-838e-e1954c730d82': 35, // Wood type style trait
  'cd0caecf-798d-4315-a1dd-691e2550d3d1': 36, // Top material trait
  'BUYING_OPTIONS': 37,
  '2057958f-5985-4e43-bcb8-8cbd4e88c322': 38, // Offset body trait
  'ab675417-8777-4ca9-bfc7-2e02f8dc67d5': 39, // Artist trait
  '971f638c-e6e1-4363-b2d6-b9a06838777e': 40, // Nut width trait
};
