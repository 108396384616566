import alt, { AltStoreModel } from '../../alt';
import CurationActions from '../actions/curation_actions';
import CurationSource from '../sources/curation_source';

const CURATE_ERROR = 'Error adding to curated set.';
const DECURATE_ERROR = 'Error removing from curated set.';
const FETCH_ERROR = 'Error fetching curated sets.';

class CurationStore extends AltStoreModel {
  constructor() {
    super();
    this.state = this.initialState();
    // eslint-disable-next-line
    // @ts-ignore
    this.registerAsync(CurationSource);

    this.bindListeners({
      handleFetchIndex: CurationActions.FETCH_INDEX,
      handleSetIndex: CurationActions.SET_INDEX,
      handleStopCurating: CurationActions.STOP_CURATING,
      handleCurate: CurationActions.CURATE,
      handleCurateSuccess: CurationActions.CURATE_SUCCESS,
      handleCurateError: CurationActions.CURATE_ERROR,
      handleDecurate: CurationActions.DECURATE,
      handleDecurateSuccess: CurationActions.DECURATE_SUCCESS,
      handleDecurateError: CurationActions.DECURATE_ERROR,
    });
  }

  handleFetchIndex(params) {
    this.setState(
      Object.assign(this.initialState(), params, { isCurating: true }),
    );

    // eslint-disable-next-line
    // @ts-ignore
    this.getInstance().fetchIndex();
  }

  handleFetchError() {
    this.setState({ error: FETCH_ERROR });
  }

  handleSetIndex(response) {
    this.state.listing_curated_sets = response.listing_curated_sets;
  }

  handleCurate(curatedSetId) {
    this.setState({ curated_set_id: curatedSetId });
    // eslint-disable-next-line
    // @ts-ignore
    this.getInstance().curate();
  }

  findSelectedCuratedSet() {
    const findBy = (set) => set.curated_set_id === this.state.curated_set_id;

    return this.state.listing_curated_sets.find(findBy);
  }

  handleCurateSuccess() {
    this.findSelectedCuratedSet().curated = true;
  }

  handleCurateError() {
    this.setState({ error: CURATE_ERROR });
  }

  handleDecurateSuccess() {
    this.findSelectedCuratedSet().curated = false;
  }

  handleDecurateError() {
    this.setState({ error: DECURATE_ERROR });
  }

  handleDecurate(curatedSetId) {
    this.setState({ curated_set_id: curatedSetId });
    // eslint-disable-next-line
    // @ts-ignore
    this.getInstance().decurate();
  }

  handleStopCurating() {
    this.setState(this.initialState());
  }

  initialState() {
    return {
      comparison_shopping_page_id: null,
      curated_set_id: null,
      error: null,
      isCurating: false,
      listing_curated_sets: [],
      product_id: null,
    };
  }
}

export default alt.createStore(CurationStore, 'CurationStore');
