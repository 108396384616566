import PropTypes from 'prop-types';
import React from 'react';
import FormGroup from '@reverbdotcom/commons/src/components/form_group';
import I18n from 'i18n-js';

class BuyerDetails extends React.Component {
  constructor(props) {
    super(props);

    this.setFirstName = this.setFirstName.bind(this);
    this.setLastName = this.setLastName.bind(this);
    this.setEmail = this.setEmail.bind(this);
    this.setAcceptsEmailMarketing = this.setAcceptsEmailMarketing.bind(this);
  }

  setFirstName(e) {
    this.props.onChange({ firstName: e.target.value });
  }

  setLastName(e) {
    this.props.onChange({ lastName: e.target.value });
  }

  setEmail(e) {
    this.props.onChange({ email: e.target.value });
  }

  setAcceptsEmailMarketing(e) {
    this.props.onChange({ acceptsEmailMarketing: e.target.checked });
  }

  render() {
    return (
      <div className="g-container">
        <div className="g-col-6 g-col-mobile-12 scaling-mb-4">
          <FormGroup
            label={I18n.t('emailOffers.buyerDetails.firstName')}
            inputName="buyer_first_name"
          >
            <input
              type="text"
              defaultValue={this.props.buyer.firstName}
              onChange={this.setFirstName}
              name="buyer_first_name"
              id="buyer_first_name"
              required
            />
          </FormGroup>
        </div>
        <div className="g-col-6 g-col-mobile-12 scaling-mb-4">
          <FormGroup
            label={I18n.t('emailOffers.buyerDetails.lastName')}
            inputName="buyer_last_name"
          >
            <input
              type="text"
              defaultValue={this.props.buyer.lastName}
              onChange={this.setLastName}
              name="buyer_last_name"
              id="buyer_last_name"
              required
            />
          </FormGroup>
        </div>
        <div className="g-col-12 scaling-mb-4">
          <FormGroup
            label={I18n.t('emailOffers.buyerDetails.email')}
            inputName="buyer_email"
          >
            <input
              type="text"
              defaultValue={this.props.buyer.email}
              onChange={this.setEmail}
              name="buyer_email"
              id="buyer_email"
              required
            />
          </FormGroup>
        </div>
        <div className="g-col-12 scaling-mb-4">
          <FormGroup label="">
            <div className="label-with-checkbox">
              <input
                id="accepts_email_marketing_checkbox"
                name="accepts_email_marketing_checkbox"
                type="checkbox"
                defaultChecked={this.props.buyer.acceptsEmailMarketing}
                onChange={this.setAcceptsEmailMarketing}
              />
              <label htmlFor="accepts_email_marketing_checkbox">
                {I18n.t('emailOffers.buyerDetails.acceptsEmailMarketing')}
              </label>
            </div>
          </FormGroup>
        </div>
      </div>
    );
  }
}

BuyerDetails.propTypes = {
  buyer: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
    acceptsEmailMarketing: PropTypes.bool,
  }),
  onChange: PropTypes.func.isRequired,
};

export default BuyerDetails;
