import React from 'react';
import I18n from 'i18n-js';
import classNames from 'classnames';

import CoreLink from './core_link';
import { Filter } from './search_filters';
import FilterChip from './filter_chip';
import { useUser } from '../user_hooks';
import MarketplaceFilter from './marketplace_filter';
import { Location } from 'history';
import { isSomethingSelected } from '../facet_utils';
import { RCPopover, RCChip, RCText, RCTextWithIcon } from '@reverbdotcom/cadence/components';
import { SlidersIcon } from '@reverbdotcom/cadence/icons/react';
import SelectFacet from './select_facet';
import { reverb_search_Filter_WidgetType as WidgetType } from '../gql/graphql';
import ListViewToggle from './list_view_toggle';
import MarketplaceFilterSidebar from './marketplace_filter_sidebar';
import { getExcludedFilterKeys, getFiltersTotal } from './helpers/selected_filters_helper';
import { applyProximityBoost } from '../seller_location_experiments';

export interface CSP2FiltersOption {
  name: string;
  total: number;
  selected: boolean;
  optionValue?: string;
  setValues?: any;
  unsetValues?: any;
  paramName?: string;
}
interface IProps {
  filters?: Filter[];
  loading?: boolean;
  trackingQuery?: string;
  displayCount?: boolean;
  dynamicTitle?: boolean;
  hideOnMobile?: boolean;
  location: Location;
  resultsTotal?: number;
  finishOptions?: CSP2FiltersOption[];
  fretboardMaterialOptions?: CSP2FiltersOption[];
  setListViewState?: (val: boolean) => void;
  showListViewToggle?: boolean;
  showTraitFilters?: boolean;
  hideClearFiltersButton?: boolean;
  pinTextQuery?: boolean; // when true, RQLSearchOverview is responsible for rendering TEXT_QUERY filter, and it can be omitted here.
  toggleExposedSidebarOpen?: () => void;
  showNewMobileSidebar?: boolean;
  isFilterOpenByDefault?: (filter: Filter) => boolean;
  clearFiltersUrl?: string;
}

export const FEATURED = [
  'CONDITION_SLUGS',
  'CATEGORY_SLUGS',
  'BRAND_SLUGS',
  'PRICE',
  'FIND_A_DEAL',
  'LOCATION',
];

export const MODEL_FAMILY_FEATURED = [
  'CONDITION_SLUGS',
  'PRICE',
  'FIND_A_DEAL',
];

const CSP_FEATURED = [
  'CONDITION_SLUGS',
  'PRICE',
  'FIND_A_DEAL',
  '6a24e2dc-800d-4f06-ac67-acef9569a40f', // fretboard material filter
];

const ALWAYS_SHOW_SELLER_LOCATION_FILTER_REGIONS = [
  'GB',
  'AU',
];

export function isDefaultedSellerLocationFilter(filterKey, shippingRegionCode) {
  return filterKey === 'SELLER_LOCATION' && ALWAYS_SHOW_SELLER_LOCATION_FILTER_REGIONS.includes(shippingRegionCode);
}

export default function MarketplaceFilterRow({
  filters = [],
  loading = false,
  trackingQuery = '',
  displayCount = false,
  dynamicTitle = false,
  hideOnMobile = false,
  location = null,
  resultsTotal = null,
  finishOptions = [],
  fretboardMaterialOptions = [],
  setListViewState = undefined,
  showListViewToggle = false,
  showTraitFilters = false, // show the Finish and Fretboard Material Filters
  hideClearFiltersButton = false,
  pinTextQuery = false,
  showNewMobileSidebar = false,
  toggleExposedSidebarOpen = undefined,
  isFilterOpenByDefault = undefined,
  clearFiltersUrl = undefined,
}: IProps) {
  const user = useUser();
  const pathname = location?.pathname;
  const excludedFilterKeys = getExcludedFilterKeys(pathname, pinTextQuery);

  if (loading && filters.length === 0) {
    return (
      <div className="search-overview__filters">
        <Placeholder />
      </div>
    );
  }

  const includedFilters = filters.filter(f => {
    const key = getFilterKey(f);
    return !excludedFilterKeys.includes(key);
  });

  const defaultFilters = getFilters(includedFilters, user, pathname);

  const defaultFilterKeys = defaultFilters.map(f => {
    return f.aggregationName ? f.aggregationName : f.key;
  });

  const otherSelectedFilters = includedFilters.filter(f => {
    const key = getFilterKey(f);
    if (defaultFilterKeys.includes(key)) return false;
    return f.options && f.options.some(o => {
      if (o.all) return false;
      // Note - we hide the SHIPS_TO filter only if you've selected your default region. It's additional noise at that point
      if (key === 'SHIPS_TO' && o.optionValue === user.shippingRegionCode) return false;
      return o.selected;
    });
  });

  const allSelectedFilters = includedFilters.filter(f => {
    const key = f.aggregationName || f.key;

    // Note - we hide the SHIPS_TO filter only if you've selected your default region. It's additional noise at that point
    if (key === 'SHIPS_TO') {
      if (f.options.some(o => {
        return o.optionValue === user.shippingRegionCode;
      })) return false;
      return true;
    }
    return isSomethingSelected(f, true);
  });

  const finishFilter = buildFinishFilter(finishOptions);
  const fretboardMaterialFilter = buildFretboardMaterialFilter(fretboardMaterialOptions);
  const filtersForSidebar = showTraitFilters ? [finishFilter, fretboardMaterialFilter, ...filters] : filters;
  const selectedFiltersTotal = getFiltersTotal(filters, excludedFilterKeys, user.shippingRegionCode);

  if (isSomethingSelected(finishFilter, true)) {
    allSelectedFilters.push(finishFilter);
  }

  return (
    <>
      <div className="search-overview__filters search-overview__filters--mobile">
        <MarketplaceFilterSidebar
          filters={filtersForSidebar}
          loading={loading}
          resultsTotal={resultsTotal}
          trackingQuery={trackingQuery}
          location={location}
          isFilterOpenByDefault={isFilterOpenByDefault}
          toggleExposedSidebarOpen={toggleExposedSidebarOpen}
          hideSidebarOnMobile={showNewMobileSidebar}
          clearFiltersUrl={clearFiltersUrl}
          hideClearFiltersButton={hideClearFiltersButton}

        />
        {defaultFilters.map(f => {
          return (
            <MarketplaceFilter
              filter={f}
              filters={filters}
              loading={loading}
              key={f.name}
              trackingQuery={trackingQuery}
              dynamicTitle={dynamicTitle}
              displayCount={displayCount}
              hideOnMobile={hideOnMobile}
            />
          );
        })}
        {dynamicTitle && otherSelectedFilters.map(f => {
          return (
            <MarketplaceFilter
              filter={f}
              filters={filters}
              loading={loading}
              key={f.name}
              trackingQuery={trackingQuery}
              dynamicTitle={dynamicTitle}
              displayCount={displayCount}
              hideOnMobile={hideOnMobile}
            />
          );
        })}
        {showTraitFilters && (
          <>
            <TraitFilter
              loading={loading}
              filter={finishFilter}
              trackingQuery={trackingQuery}
              dynamicTitle
            />
            <TraitFilter
              loading={loading}
              filter={fretboardMaterialFilter}
              trackingQuery={trackingQuery}
              dynamicTitle
            />
          </>
        )}
        {selectedFiltersTotal > 0 && !hideClearFiltersButton &&
          <ClearFiltersButton
            clearFilterUrl={clearFiltersUrl || pathname}
            hideOnMobile
          />
        }
        {showNewMobileSidebar &&
          <div className="mobile-d-inline d-none">
            <RCChip
              loading={loading}
              count={selectedFiltersTotal}
              onClick={toggleExposedSidebarOpen}>
              <RCTextWithIcon
                svgComponent={SlidersIcon}
                placement="left"
                size="large"
              >
                {I18n.t('commons.marketplaceFilters.filterAndSort')}
              </RCTextWithIcon>
            </RCChip>
          </div>
        }
        {showListViewToggle &&
          <FilterRowListViewToggle
            loading={loading}
            setListViewState={setListViewState}
            filters={filters}
          />
        }
      </div>
    </>
  );
}

function Placeholder() {
  return (
    <div className="marketplace-filter">
      <FilterChip loading>
        <div className="filter-chip__loading-placeholder" />
      </FilterChip>
    </div>
  );
}

function getFilterKey(f: Filter) {
  return f.key || f.aggregationName;
}

function getFeaturedFilterKeys(pathname) {
  if (pathname?.includes('/f/')) return MODEL_FAMILY_FEATURED;
  if (pathname?.includes('/p/')) return CSP_FEATURED;
  return FEATURED;
}

function getFilters(filters, user, pathname) {
  const featuredFilterKeys = getFeaturedFilterKeys(pathname);
  const featuredFilters = filters.filter(f => {
    const key = getFilterKey(f);
    if (key === 'LOCATION' && user.shippingRegionCode !== 'US_CON') return false;
    if (key === 'SELLER_LOCATION' && pathname?.includes('/p/') && applyProximityBoost(user)) return true;
    return featuredFilterKeys.includes(key) || isDefaultedSellerLocationFilter(key, user.shippingRegionCode);
  });

  if (featuredFilters.length === 0) {
    return filters.slice(0, 5);
  }

  return featuredFilters.sort((a: Filter, b: Filter) => {
    const keyA = getFilterKey(a);
    const keyB = getFilterKey(b);
    const indexOfA = featuredFilterKeys.indexOf(keyA);
    const indexOfB = featuredFilterKeys.indexOf(keyB);
    if (indexOfA < indexOfB) { return -1; }
    if (indexOfA > indexOfB) { return 1; }
    return 0;
  });
}

function buildFinishFilter(finishOptions: CSP2FiltersOption[]): Filter {
  const finishFilter = {
    name: 'Finish',
    widgetType: WidgetType.RADIO,
    aggregationName: 'finish',
    key: 'finishOptions',
    options: [],
  };

  if (!finishOptions || finishOptions.length <= 1) return finishFilter;

  const selected = finishOptions.find(opt => opt.selected);

  const allFinishesOption = {
    name: I18n.t('commons.marketplaceFilters.allFinishes'),
    selected: !selected,
    optionValue: '',
    setValues: [],
    unsetValues: [],
    paramName: 'canonicalFinish',
    all: true,
  };

  return {
    ...finishFilter,
    options: [
      allFinishesOption,
      ...finishOptions.sort((a, b) => {
        return b.total - a.total;
      }).map(option => {
        return {
          ...option,
          count: {
            value: option.total,
          },
        };
      }),
    ],
  };
}

function buildFretboardMaterialFilter(fretboardMaterialOptions: CSP2FiltersOption[]): Filter {
  const fretboardMaterialFilter = {
    name: 'Fretboard Material',
    widgetType: WidgetType.RADIO,
    aggregationName: 'fretboardMaterial',
    key: 'fretboardMaterialOptions',
    options: [],
  };

  if (!fretboardMaterialOptions || fretboardMaterialOptions.length <= 1) return fretboardMaterialFilter;

  return {
    ...fretboardMaterialFilter,
    options: fretboardMaterialOptions,
  };
}

function FilterRowListViewToggle({ loading, filters, setListViewState }) {
  return (
    <div className="marketplace-filter__list-view-toggle">
      {(loading && filters.length === 0) ? (
        <div className="search-overview__list-view-toggle-loading-placeholder" />
      ) :
        (
          <ListViewToggle
            setListViewState={setListViewState}
            loading={loading}
          />
        )}
    </div>
  );
}

function TraitFilter({ loading, filter, trackingQuery, dynamicTitle }) {
  const [popoverIsOpen, openPopover] = React.useState(false);
  if (!filter) return null;
  if (filter.options?.length <= 1) return null;

  const selected = filter.options.find(opt => opt.selected && !opt.all);

  return (
    <div className="marketplace-filter marketplace-filter--mobile-hidden">
      <RCPopover
        isOpen={popoverIsOpen}
        onDismiss={() => openPopover(false)}
        position="bottom-start"
        loading={loading}
        anchor={
          <RCChip
            loading={loading}
            selected={selected}
            onClick={() => openPopover(!popoverIsOpen)}
          >
            {(dynamicTitle && selected) && selected.name}
            {(!dynamicTitle || !selected) && filter.name}
          </RCChip>
        }
      >
        <SelectFacet
          pageSection="filter-row"
          filter={filter}
          trackingQuery={trackingQuery}
        />
      </RCPopover>
    </div>
  );
}

export function ClearFiltersButton({ clearFilterUrl, hideOnMobile = false, hideOnDesktop = false }) {
  const className = classNames(
    'marketplace-filter',
    {
      'mobile-d-none': hideOnMobile,
      'd-none mobile-d-inline': hideOnDesktop,
    },
  );

  return (
    <div className={className}>
      <CoreLink
        to={clearFilterUrl}
        className="text-link plr-2 d-flex height-100 fx-align-center"
      >
        <RCText.Utility size="350" weight="semibold" htmlTag="span">
          {I18n.t('commons.marketplaceFilters.clearFilters')}
        </RCText.Utility>
      </CoreLink>
    </div>
  );
}
