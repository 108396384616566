import React, { RefObject, useEffect, useRef } from 'react';
import { camelize, camelizeKeys } from 'humps';
import {
  core_apimessages_AddressEntry as AddressEntry,
  core_apimessages_Country as Country,
} from '@reverbdotcom/commons/src/gql/graphql';

import RegionSelect from './addresses/region_select';
import {
  AddressInputField,
  LabelOverride,
  Field,
} from '../lib/address_form_fields_types';
import { buildInputName } from '../lib/address_form_fields_helpers';

import { IUser } from '@reverbdotcom/commons/src/components/user_context_provider';
import FormGroupWithInput from '@reverbdotcom/commons/src/components/form_group_with_input';
import FormGroup from '@reverbdotcom/commons/src/components/form_group';
import { MParticleEventName, trackEvent } from '@reverbdotcom/commons/src/elog/mparticle_tracker';

type PhoneLabelTooltip = JSX.Element | React.ReactNode;
type UpdateFieldCallback = (addressFields: any) => any;

export interface Props {
  field: Field;
  fieldPrefix: string;
  user: Partial<IUser>;
  address: AddressEntry;
  countries: Country[];
  updateField: UpdateFieldCallback;
  requiredFieldsOverrides?: AddressInputField[];
  disabledFieldsOverrides?: AddressInputField[];
  labelOverrides: LabelOverride;
  phoneLabelTooltip: PhoneLabelTooltip;
  useCamelizedFieldsForRQL?: boolean;
  disabled?: boolean;
  inputRef?: RefObject<HTMLInputElement>;
  trackingComponentName: string;
}

const MAX_POSTAL_CODE_LENGTH = 11;
// For reference: https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/autocomplete
const AUTOCOMPLETE_ATTRIBUTES = {
  name: 'name',
  streetAddress: 'street-address',
  extendedAddress: 'address-line2',
  postalCode: 'postal-code',
  phone: 'tel',
  countryCode: 'country',
};

function setMaxLength(fieldName) {
  return fieldName === 'postal_code' ? MAX_POSTAL_CODE_LENGTH : null;
}

function camelizedCountries(countries): Country[] {
  return countries.map(c => camelizeKeys(c));
}

function handleInputChange(
  event: any,
  address: any,
  updateField: UpdateFieldCallback,
  useCamelizedFieldsForRQL: boolean,
) {
  if (useCamelizedFieldsForRQL) {
    updateField({ ...address, ...camelizeKeys(event) });
  } else {
    const name = Object.keys(event)[0];

    updateField({ ...address, [name]: event[name] });
  }
}

function handleRegionChange(
  region: string,
  address: AddressEntry,
  updateField,
) {
  updateField({ ...address, region });
}

function getFieldLabel(
  { fieldName, display }: Field,
  user: Partial<IUser>,
  labelOverrides: LabelOverride,
  phoneLabelTooltip: PhoneLabelTooltip,
) {
  if (fieldName === 'phone' && !!phoneLabelTooltip) {
    return phoneLabelTooltip;
  }

  return buildLabel(fieldName, display[user.locale], labelOverrides);
}

function buildLabel(fieldName: string, fieldLabel: string, labelOverrides: LabelOverride) {
  return labelOverrides[fieldName] || fieldLabel;
}

function setAutoCompleteAttribute(fieldName: string): string {
  return AUTOCOMPLETE_ATTRIBUTES[fieldName];
}

function useAutofillDetection(element: RefObject<HTMLInputElement>, onAutofill: (event: any) => any) {
  const savedHandler = useRef(onAutofill);

  useEffect(() => {
    savedHandler.current = onAutofill;
  }, [onAutofill]);

  useEffect(() => {
    const targetElement = element?.current;

    if (!(targetElement && targetElement.addEventListener)) return;

    const listener: typeof onAutofill = event => savedHandler.current(event);

    targetElement.addEventListener('input', listener);

    return () => {
      targetElement.removeEventListener('input', listener);
    };
  }, [element]);
}

export function AddressFormField({
  field,
  fieldPrefix,
  user,
  countries,
  address,
  useCamelizedFieldsForRQL,
  updateField,
  labelOverrides,
  phoneLabelTooltip,
  requiredFieldsOverrides = [],
  disabledFieldsOverrides = [],
  disabled,
  trackingComponentName,
}: Props) {
  function onAutofill(event: any) {
    if (!('data' in event) || event.inputType === 'insertReplacementText') {
      trackEvent({
        eventName: MParticleEventName.BrowserAutofill,
        componentName: trackingComponentName,
        displayStyle: event.target.id,
      });
    }
  }

  const ref = useRef(null);
  useAutofillDetection(ref, onAutofill);

  if (field.fieldName === 'region') {
    return (
      <FormGroup
        key={field.fieldName}
        label={field.display[user.locale]}
        tagOptional={!field.required}
      >
        <RegionSelect
          name={buildInputName(fieldPrefix, 'region')}
          countries={camelizedCountries(countries)}
          value={address.region}
          countryCode={address.countryCode}
          onChange={(event) => handleRegionChange(event.target.value, address, updateField)}
          disabled={disabled || disabledFieldsOverrides.includes('region')}
          required={field.required}
        />
      </FormGroup>
    );
  }

  return (
    <FormGroupWithInput
      key={field.fieldName}
      required={field.required || requiredFieldsOverrides.includes(field.fieldName)}
      label={getFieldLabel(field, user, labelOverrides, phoneLabelTooltip)}
      inputName={buildInputName(fieldPrefix, field.fieldName)}
      fieldName={field.fieldName}
      value={address[camelize(field.fieldName)]}
      updateField={event => handleInputChange(event, address, updateField, useCamelizedFieldsForRQL)}
      tagOptional={!field.required && !requiredFieldsOverrides.includes(field.fieldName)}
      disabled={disabled || disabledFieldsOverrides.includes(field.fieldName)}
      maxLength={setMaxLength(field.fieldName)}
      autoComplete={setAutoCompleteAttribute(field.fieldName)}
      inputRef={ref}
    />
  );
}

export default AddressFormField;
