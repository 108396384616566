import { RCAlertBox } from '@reverbdotcom/cadence/components';
import React from 'react';
import { I18N as Translate } from '@reverbdotcom/commons/src/components/translate';

export function TaxProfileVerificationFailed() {
  return (
    <RCAlertBox
      type="error"
    >
      <Translate
        className="weight-bold"
        tag="div"
        text="discovery.taxProfile.taxProfileForm.tinFailedVerificationHeadline"
      />
      <Translate text="discovery.taxProfile.taxProfileForm.tinFailedVerificationMessage" />
    </RCAlertBox>
  );
}

export default TaxProfileVerificationFailed;
