import React from 'react';

import {
  core_apimessages_ListingSaleMembership,
} from '@reverbdotcom/commons/src/gql/graphql';

export interface ISalesMembershipsState {
  reverbSalesMemberships: core_apimessages_ListingSaleMembership[];
  sellerSalesMemberships: core_apimessages_ListingSaleMembership[];
}

interface ISalesMembershipsContext {
  salesMembershipsState: ISalesMembershipsState;
  dispatch: React.Dispatch<any>;
}

export default React.createContext<ISalesMembershipsContext>({
  salesMembershipsState: null,
  dispatch: () => {},
});
