import { useCallback } from 'react';
import { inAndroidApp, inIOSApp } from '../user_context_helpers';
import { useUser } from '../user_hooks';

interface iMessageParams {
  eventName: string;
  data?: object;
}

type sendNativeMessageType = (params: iMessageParams) => void;

export function useSendNativeMessage(): sendNativeMessageType {
  const user = useUser();

  const sendNativeMessage = useCallback(({ eventName, data }: iMessageParams) => {
    if (inIOSApp(user)) {
      if (!window.webkit) {
        console.error('no window.webkit object found');
        return;
      }
      if (!window.webkit?.messageHandlers?.iOS?.postMessage) {
        console.error('no window.webkit.messageHandlers.iOS.postMessage method found');
        return;
      }

      window.webkit.messageHandlers.iOS.postMessage({
        name: eventName,
        data,
      });
    }

    if (inAndroidApp(user)) {
      if (!window.ReverbAndroidEventBus) {
        console.error('no window.ReverbAndroidEventBus object found');
        return;
      }

      window.ReverbAndroidEventBus?.postMessage(JSON.stringify({
        name: eventName,
        data,
      }));
    }
  }, [user]);

  return sendNativeMessage;
}
