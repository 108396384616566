// TODO update this to import from commons
// import { gql } from '@reverbdotcom/commons/src/gql';
// eslint-disable-next-line no-restricted-imports
import { gql } from '@apollo/client';
import React from 'react';
import I18n from 'i18n-js';
import { RCButton, RCSelect } from '@reverbdotcom/cadence/components';
import {
  CoreConversationsFlagMessage,
  core_apimessages_ConversationMessage as ConversationMessage,
  core_apimessages_ConversationMessageFlag_Reason_Type as MessageFlagReasonType,
} from '@reverbdotcom/commons/src/gql/graphql';
import { withGraphql } from '@reverbdotcom/commons/src/with_graphql';
import { useHOCMutation, MutationFunction } from '@reverbdotcom/commons/src/useHOCMutation';
import { MParticleEventName, trackEvent } from '@reverbdotcom/commons/src/elog/mparticle_tracker';

type FlagMessageMutationFn = MutationFunction<
  CoreConversationsFlagMessage.Mutation,
  CoreConversationsFlagMessage.Variables
>;

interface MutationProps {
  flagMessage: FlagMessageMutationFn;
}

interface ExternalProps {
  message: ConversationMessage;
  onFlagCreated: () => void;
}

type Props = ExternalProps & MutationProps;

export function ConversationFlagMessageForm(props: Props) {
  const formState = useFormState({
    message: props.message,
    flagMessage: props.flagMessage,
    onFlagCreated: props.onFlagCreated,
  });

  return (
    <form onSubmit={formState.onSubmit}>
      <RCSelect
        id="flag-message-reason-type-select"
        name="flag-message-reason-type-select"
        value={formState.selectedValue}
        label={translate('reasonSelect.label')}
        onChange={formState.onSelect}
      >
        {formState.reasonSelectOptions.map((option) => (
          <RCSelect.Option
            key={option.value}
            label={option.label}
            value={option.value}
          />
        ))}
      </RCSelect>
      <RCButton
        isSubmit
        variant="filled"
        disabled={formState.isButtonDisabled}
      >
        {formState.buttonText}
      </RCButton>
    </form>
  );
}

function useFormState({ message, flagMessage, onFlagCreated }: {
  message: ConversationMessage,
  flagMessage: FlagMessageMutationFn,
  onFlagCreated: () => void;
}) {
  const reasonTypes = Object.keys(MessageFlagReasonType) as MessageFlagReasonType[];
  const [reasonType, setReasonType] = React.useState<MessageFlagReasonType>(reasonTypes[0]);
  const [mutate, { loading }] = useHOCMutation(flagMessage);

  const reasonSelectOptions = reasonTypes.map(type => ({
    label: translate(`reasonSelect.option.${type.toLowerCase()}`),
    value: type,
  }));

  function buttonText() {
    if (loading) {
      return translate('button.loadingText');
    }

    return translate('button.defaultText');
  }

  function onSelect(e: React.ChangeEvent<HTMLSelectElement>) {
    setReasonType(e.target.value as MessageFlagReasonType);
  }

  async function onSubmit(e: React.ChangeEvent<HTMLFormElement>) {
    e.preventDefault();

    await mutate({
      variables: {
        input: {
          messageId: message.id,
          reasonType,
        },
      },
    });

    trackEvent({
      eventName: MParticleEventName.ConversationFlagMessageSubmit,
      messageId: message.id,
      conversationId: message.conversationId,
      flagReasonType: reasonType,
    });

    onFlagCreated();
  }

  return {
    buttonText: buttonText(),
    isButtonDisabled: loading,
    selectedValue: reasonType,
    reasonSelectOptions,
    onSubmit,
    onSelect,
  };
}

function translate(key: string) {
  return I18n.t(`discovery.conversations.flagMessage.form.${key}`);
}

const withMutation = withGraphql<
  ExternalProps,
  CoreConversationsFlagMessage.Mutation,
  CoreConversationsFlagMessage.Variables
>(
  gql`
    mutation Core_Conversations_FlagMessage(
      $input: Input_core_apimessages_ConversationFlagMessageRequest
    ) {
      flagMessage(input: $input) {
        id
      }
    }
  `,
  {
    name: 'flagMessage',
  },
);

export default withMutation(ConversationFlagMessageForm);
