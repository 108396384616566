import { camelCase, lowerCase } from 'lodash';

export const DRAFT = 'draft';
export const LIVE = 'live';
export const ENDED = 'ended';
export const ORDERED = 'ordered';
export const SUSPENDED = 'suspended';
export const SOLD_OUT = 'soldOut';
export const SELLER_UNAVAILABLE = 'sellerUnavailable';

export const VALID_DIRECT_OFFER_STATES = [
  LIVE,
  ENDED,
];

export function queryParamState(window) {
  const urlParams = new URLSearchParams(window.location.search);
  return camelCase(lowerCase(urlParams.get('state')));
}

export function isLiveURLStateParam(window) {
  return LIVE === queryParamState(window);
}

export function isPageEligibleForBulkAffirm(window) {
  return isLiveURLStateParam(window);
}

export function isPageEligibleForBulkSales(window) {
  return isLiveURLStateParam(window);
}

export function isPageEligibleForBulkBump(window) {
  return isLiveURLStateParam(window);
}

export function isPageEligibleForBulkDirectOffers(window) {
  return VALID_DIRECT_OFFER_STATES.includes(queryParamState(window));
}

export function isPageEligibleForBulkDraftActions(window) {
  return DRAFT === queryParamState(window);
}
