import React from 'react';
import classNames from 'classnames';
import CoreLink from '@reverbdotcom/commons/src/components/core_link';
export interface IAction {
  key: string;
  node: React.ReactElement;
}
export interface IDashboardPageHeaderProps {
  title: string | React.ReactNode;
  subtitle?: string | React.ReactNode;
  breadcrumbs?: {
    title: string;
    to?: string;
    current?: boolean;
  }[];
  actions?: IAction[];
  // useful when you're inserting into a non-typical layout
  noTopPadding?: boolean;
  noBorder?: boolean;
  titleSize?: 'large'; // not a boolean so we can expand into other sizes more easily down the road
}

function DashboardPageHeader({
  title,
  subtitle,
  breadcrumbs,
  actions,
  noTopPadding,
  noBorder,
  titleSize,
}: IDashboardPageHeaderProps) {
  const classes = classNames(
    'dashboard-page-header',
    { 'dashboard-page-header--no-top-padding': noTopPadding },
    { 'dashboard-page-header--no-border': noBorder },
    { 'dashboard-page-header--title-large': titleSize === 'large' },
  );

  return (
    <div className={classes}>
      {breadcrumbs?.length > 0 &&
        <nav
          aria-label="breadcrumbs"
          className="dashboard-page-header__breadcrumbs"
        >
          <ol>
            {breadcrumbs.map(link => (
              <li
                key={link.title}
                className="dashboard-page-header__breadcrumb"
                aria-current={link.current ? 'page' : null}
              >
                {link.to ? (
                  <CoreLink to={link.to}>
                    {link.title}
                  </CoreLink>
                ) : (
                  <span>
                    {link.title}
                  </span>
                )}
              </li>
            ))}
          </ol>
        </nav>
      }
      <div className="dashboard-page-header__main">
        <div className="dashboard-page-header__titleblock">
          <h1 className="dashboard-page-header__title">
            {title}
          </h1>
          {subtitle &&
            <p className="dashboard-page-header__subtitle">
              {subtitle}
            </p>
          }
        </div>
        {actions?.length > 0 &&
          <div className="dashboard-page-header__actions">
            {actions.map(action => (
              <div
                key={action.key}
                className="dashboard-page-header__action-wrapper"
              >
                {action.node}
              </div>
            ))}
          </div>
        }
      </div>
    </div>
  );
}

export default DashboardPageHeader;
