import I18n from 'i18n-js';
import { IUser } from './components/user_context_provider';
import {
  reverb_search_ListingItemRegionRelation as ItemRegionRelation,
  reverb_search_SimilarListingsSearchRequest as SimilarListingsSearchRequest,
  reverb_search_ListingsSearchRequest as ListingsSearchRequest,
} from './gql/graphql';
import { isNonUSUser } from './user_context_helpers';

export function showItemPageListingFlags(user: Partial<IUser>) {
  return !!user.countryCode && !['US', 'GB'].includes(user.countryCode);
}

export function showLocalizedListingContent(user: Partial<IUser>) {
  return isNonUSUser(user);
}

export function localizeSimilarListingsFilters(user: Partial<IUser>): SimilarListingsSearchRequest {
  if (showLocalizedListingContent(user)) {
    return {
      ...withItemRegionRelation(user),
      itemRegion: user.countryCode,
    };
  }

  return {};
}

export function localizedSimilarListingsTitle(user: Partial<IUser>) {
  if (user.countryCode === 'GB') {
    return I18n.t('commons.listingRow.similarListings.uk');
  }

  return I18n.t('commons.listingRow.similarListings.default');
}

function withItemRegionRelation(user: Partial<IUser>) {
  const showItemRegionOnly = !user.countryCode || user.countryCode == 'GB';

  if (showItemRegionOnly) { return {}; }

  return {
    itemRegionRelation: ItemRegionRelation.INCLUDE_TRADE_REGIONS,
  };
}

export function localizeListingsRowsFilters(user: Partial<IUser>): ListingsSearchRequest {
  if (showLocalizedListingContent(user)) {
    return {
      itemRegion: user.countryCode,
      itemRegionRelation: ItemRegionRelation.INCLUDE_TRADE_REGIONS,
    };
  }

  return {};
}
