export default Object.freeze({
  operatingSystem: {
    uuid: 'c6639f61-8884-45e2-9f78-9f1de059700b',
    osx: {
      uuid: '0504f1bc-5727-4c11-943b-21ae32d664fb',
      name: 'macOS',
    },
    windows: {
      uuid: 'dfeffbaa-53a1-41e6-b8cf-e145b8dcd772',
      name: 'Windows',
    },
    linux: {
      uuid: '45d3ae2e-ddfe-411b-9119-ac4a0f8ac823',
      name: 'Linux',
    },
    android: {
      uuid: 'cf42490e-2c88-42f0-9832-09ed1f67949c',
      name: 'Android',
    },
    ios: {
      uuid: 'a10f539f-21a0-4151-9674-a7eed02840ad',
      name: 'iOS',
    },
  },
  fileType: {
    uuid: '2ae386f1-7144-4d0c-b8aa-6caba14faa6f',
  },
});
