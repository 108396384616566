import React from 'react';
import { Location } from 'history';
import { defaultUpdateLocation } from '../update_params';

export interface LocationWithHash extends Partial<Location> {
  hash?: string;
}

export interface UpdateUrlArgs {
  location: Location,
  newParams: PlainObject,
  anchor?: string,
  autodirects?: boolean,
}

export type UpdateUrlType = (args: UpdateUrlArgs) => Partial<LocationWithHash>;

export interface UpdateUrlContextType {
  updateUrl: UpdateUrlType;
}

export const UpdateUrlContext = React.createContext<UpdateUrlContextType>({
  updateUrl: defaultUpdateLocation,
});

interface IUpdateURLProviderProps {
  updateUrl?: UpdateUrlType;
  children: React.ReactNode;
}

export function UpdateURLProvider({ updateUrl = defaultUpdateLocation, children }: IUpdateURLProviderProps) {
  return (
    <UpdateUrlContext.Provider value={{ updateUrl }}>
      {children}
    </UpdateUrlContext.Provider>
  );
}

export function useUpdateUrl(): UpdateUrlContextType {
  return React.useContext(UpdateUrlContext);
}

export interface IUpdateURLProps {
  updateUrl: UpdateUrlType;
}

export function withUpdateURL<TProps extends {}>(
  Component: React.ComponentType<TProps & IUpdateURLProps>,
): React.ComponentType<TProps> {

  const result = class Connected extends React.Component<TProps, null> {
    static displayName = `withUpdateURL(${Component.displayName || Component.name})`;

    static WrappedComponent = Component;

    static contextType = UpdateUrlContext;

    render() {
      const updateUrl = this.context.updateUrl || defaultUpdateLocation;

      return (<Component updateUrl={updateUrl} {...this.props} />);
    }
  };

  return result;
}
