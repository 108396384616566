import React from 'react';
import {
  CoreCheckoutStartApplePayCheckout,
  CoreCheckoutUpdateApplePayShippingEstimate,
  core_apimessages_Money as Money,
} from '@reverbdotcom/commons/src/gql/graphql';
import { withGraphql } from '@reverbdotcom/commons/src/with_graphql';
import {
  finalizePaymentMutation,
  FinalizePaymentMutationFn,
} from './checkoutApplePay';
import {
  useCartApplePay,
  StartCheckoutMutationFn,
  START_APPLE_PAY_CHECKOUT,
  UPDATE_APPLE_PAY_SHIPPING_ESTIMATE,
  UpdateShippingEstimateMutationFn,
  ApplePayConfig,
} from './useCartApplePay';
import {
  ApplePayButton,
  ApplePayButtonVariant,
  TrackingDisplayStyle,
} from './ApplePayButton';
import { connect } from '@reverbdotcom/commons/src/connect';
import { AccertifyDeviceScript } from './AccertifyDeviceScript';

interface MutationProps {
  startApplePayCheckout: StartCheckoutMutationFn;
  updateCheckoutShippingEstimate: UpdateShippingEstimateMutationFn;
  finalizeApplePayPayment: FinalizePaymentMutationFn;
}

interface ExternalProps {
  checkoutBundlingId: string;
  amountTotal: Pick<Money, 'amountCents' | 'currency'>;
  applePayConfig: ApplePayConfig
  variant: ApplePayButtonVariant;
  tracking: {
    displayStyle: TrackingDisplayStyle,
  };
}

type Props = ExternalProps & MutationProps;

export function CartApplePayButton(props: Props) {
  const { applePay, isLoading } = useCartApplePay({
    checkoutBundlingId: props.checkoutBundlingId,
    amountTotal: props.amountTotal,
    applePayConfig: props.applePayConfig,
    mutations: {
      startApplePayCheckout: props.startApplePayCheckout,
      updateCheckoutShippingEstimate: props.updateCheckoutShippingEstimate,
      finalizeApplePayPayment: props.finalizeApplePayPayment,
    },
  });

  function onPaymentSheetOpen() {
    applePay.submit();
  }

  return (
    <>
      <AccertifyDeviceScript />
      <ApplePayButton
        variant={props.variant}
        shouldTrack={!isLoading}
        hidden={isLoading || !applePay}
        onClick={onPaymentSheetOpen}
        tracking={
          {
            applePayAvailableForDevice: !!applePay,
            displayStyle: props.tracking.displayStyle,
          }
        }
      />
    </>
  );
}

export const withStartCheckoutMutation = withGraphql<
  ExternalProps,
  CoreCheckoutStartApplePayCheckout.Mutation,
  CoreCheckoutStartApplePayCheckout.Variables
>(
  START_APPLE_PAY_CHECKOUT,
  {
    name: 'startApplePayCheckout',
  },
);

export const withShippingEstimateMutation = withGraphql<
  ExternalProps,
  CoreCheckoutUpdateApplePayShippingEstimate.Mutation,
  CoreCheckoutUpdateApplePayShippingEstimate.Variables
>(
  UPDATE_APPLE_PAY_SHIPPING_ESTIMATE,
  {
    name: 'updateCheckoutShippingEstimate',
  },
);

export const withFinalizePaymentMutation = finalizePaymentMutation<ExternalProps>();

export default connect<ExternalProps>([
  withStartCheckoutMutation,
  withShippingEstimateMutation,
  withFinalizePaymentMutation,
])(CartApplePayButton);
