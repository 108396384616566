import I18n from 'i18n-js';
import React, { useContext } from 'react';

import { RCCheckbox, RCTag } from '@reverbdotcom/cadence/components';
import { FormCards } from '@reverbdotcom/commons/src/components/FormCards';

import { SellFormContext } from '../../SellFormContext';
import { Field, ActionType } from '../../sellFormReducerTypes';
import ShippingBox from '../../../../../../images/shipping/shipping-box.svg';
import SellFormCallout from '../SellFormCallout';

function PrefersReverbShippingLabelCard() {
  const { listingEntry, dispatch, loading } = useContext(SellFormContext);

  return (
    <FormCards.Card>
      <FormCards.FormSection
        title={I18n.t('discovery.sellForm.shippingSection.prefersReverbShippingLabelCard.header')}
        subtitle={I18n.t('discovery.sellForm.shippingSection.prefersReverbShippingLabelCard.subheader')}
      >
        <SellFormCallout
          imageSrc={ShippingBox}
          imageAlt={I18n.t('discovery.sellForm.shippingSection.prefersReverbShippingLabelCard.valueProp.iconAlt')}
        >
          <div className="size-90">
            <ul className="cms-ul mb-0 ">
              <li>
                {I18n.t('discovery.sellForm.shippingSection.prefersReverbShippingLabelCard.valueProp.save')}
              </li>
              <li>
                {I18n.t('discovery.sellForm.shippingSection.prefersReverbShippingLabelCard.valueProp.safe')}
              </li>
              <li>
                {I18n.t('discovery.sellForm.shippingSection.prefersReverbShippingLabelCard.valueProp.qr')}
              </li>
            </ul>
          </div>
        </SellFormCallout>
        <RCCheckbox
          id={Field.PREFERS_REVERB_SHIPPING_LABEL}
          name={Field.PREFERS_REVERB_SHIPPING_LABEL}
          label={
            <>
              <span className="mr-2">{I18n.t('discovery.sellForm.shippingSection.prefersReverbShippingLabelCard.checkboxLabel')}</span>
              <span>
                <RCTag variant="highlight">
                  {I18n.t('discovery.sellForm.shippingSection.prefersReverbShippingLabelCard.checkboxTag')}
                </RCTag>
              </span>
            </>
          }
          checked={listingEntry[Field.PREFERS_REVERB_SHIPPING_LABEL]}
          onChange={() => dispatch({
            type: ActionType.UPDATE_FIELD,
            fieldKey: Field.PREFERS_REVERB_SHIPPING_LABEL,
            value: !listingEntry[Field.PREFERS_REVERB_SHIPPING_LABEL],
          })}
          disabled={loading}
        />
      </FormCards.FormSection>
    </FormCards.Card>
  );
}

export default PrefersReverbShippingLabelCard;
