import PropTypes from 'prop-types';
import React from 'react';
import Money from '@reverbdotcom/discovery-ui/src/shared/money';
import classNames from 'classnames';

class GiftCardOptions extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      cardValue: props.selectedAmount || props.giftCardValues[0],
      cardDesignId: props.selectedDesignId || props.cardDesigns[0].id,
    };

    this.handleGiftCardValueChange = this.handleGiftCardValueChange.bind(this);
    this.handleGiftCardThumbnailChange = this.handleGiftCardThumbnailChange.bind(this);
  }

  get cardDesigns() {
    return this.props.cardDesigns;
  }

  handleGiftCardValueChange(event) {
    this.setState({ cardValue: event.target.value });
  }

  handleGiftCardThumbnailChange(event) {
    this.setState({ cardDesignId: event.target.value });
  }

  formatMoney(cents) {
    const money = new Money(cents, 'USD');
    return money.toStringNoCents();
  }

  cardValueSelected(value) {
    return String(this.state.cardValue) === String(value);
  }

  cardDesignSelected(id) {
    return String(this.state.cardDesignId) === String(id);
  }

  get selectedDesign() {
    return this.cardDesigns.find(design =>
      String(design.id) === String(this.state.cardDesignId));
  }

  renderGiftCardValues() {
    return (
      this.props.giftCardValues.map(giftCardValue =>
        this.renderGiftCardValue(giftCardValue))
    );
  }

  renderGiftCardValue(giftCardValue) {
    return (
      <div
        key={giftCardValue}
        className="gift-card-module__value"
      >
        <input
          required="required"
          data-gift-card-amount="true"
          type="radio"
          value={giftCardValue}
          name="gift_card_form[amount_cents]"
          id={`gift_card_form_amount_cents_${giftCardValue}`}
          onChange={this.handleGiftCardValueChange}
          checked={this.cardValueSelected(giftCardValue)}
        />
        <label
          className="gift-card-module__value-label"
          htmlFor={`gift_card_form_amount_cents_${giftCardValue}`}
          data-amount-option-display={giftCardValue}
        >
          {this.formatMoney(giftCardValue)}
        </label>
      </div>
    );
  }

  renderCardThumbnail(cardDesign) {
    const checked = this.cardDesignSelected(cardDesign.id);
    return (
      <div
        key={cardDesign.id}
        className={classNames('gift-card-module__card-thumb', { 'gift-card-module__card-thumb--selected': checked })}
      >
        <input
          type="radio"
          id={cardDesign.id}
          name="gift_card_form[design_id]"
          checked={checked}
          onChange={this.handleGiftCardThumbnailChange}
          value={cardDesign.id}
        />
        <label
          htmlFor={cardDesign.id}
          style={{ backgroundImage: `url('${cardDesign.imageURL}')` }}
        />
      </div>
    );
  }

  renderCardThumbnails() {
    return (
      this.cardDesigns.map(cardDesign => this.renderCardThumbnail(cardDesign))
    );
  }

  renderSelectedCardDesign() {
    return (
      <div
        className="gift-card__image"
        style={{ backgroundImage: `url('${this.selectedDesign.imageURL}')` }}
      />
    );
  }

  render() {
    return (
      <>
        <div className="site-module">
          <div className="site-module__header">
            <h3 className="site-module__title">
              {I18n.t('js.giftCardOptions.valueLabel')}
            </h3>
          </div>
          <div className="site-module__content">
            <div
              className="gift-card-module__values"
              data-gift-card-values
            >
              <div className="gift-card-module__values__inner">
                {this.renderGiftCardValues()}
              </div>
            </div>
          </div>
        </div>

        <div className="site-module">
          <div className="site-module__header">
            <h3 className="site-module__title">
              {I18n.t('js.giftCardOptions.designLabel')}
            </h3>
          </div>
          <div className="site-module__content">
            <div className="gift-card">
              {this.renderSelectedCardDesign()}
              <div className="gift-card__details">
                <div className="gift-card__info gift-card__info--value">
                  <div
                    className="gift-card__value gift-card__value--filled"
                    data-selected-amount-display
                  >
                    {this.formatMoney(this.state.cardValue)}
                  </div>
                </div>
              </div>
            </div>
            <div className="gift-card-module__card-thumbs">
              <div className="gift-card-module__card-thumbs__inner">
                {this.renderCardThumbnails()}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

GiftCardOptions.propTypes = {
  selectedDesignId: PropTypes.number,
  selectedAmount: PropTypes.number,
  giftCardValues: PropTypes.arrayOf(PropTypes.number).isRequired,
  cardDesigns: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    imageURL: PropTypes.string,
  })).isRequired,
};

export default GiftCardOptions;
