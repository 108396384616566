import React from 'react';
import {
  core_apimessages_Address,
  core_apimessages_MyAddressType,
} from '@reverbdotcom/commons/src/gql/graphql';
import bind from '@reverbdotcom/commons/src/bind';
import I18n from 'i18n-js';
import AddressSummary from '../AddressSummary';

interface IProps {
  address: core_apimessages_Address;
  onEdit(event: any): void;
  onMakePrimary?(event: any): void;
  addressType?: core_apimessages_MyAddressType;
}

export default class AddressRow extends React.Component<IProps, null> {
  @bind
  handleMakePrimary(e) {
    e.preventDefault();
    this.props.onMakePrimary(this.props.address);
  }

  @bind
  handleEdit(e) {
    e.preventDefault();
    this.props.onEdit(this.props.address);
  }

  get isEuOdrAddress(): boolean {
    return this.props.addressType === core_apimessages_MyAddressType.EU_ODR_PLATFORM;
  }

  renderPrimary() {
    if (this.isEuOdrAddress) {
      return null;
    }

    const { address } = this.props;

    if (address.primary) {
      return (
        <div className="label-tag">
          {I18n.t('discovery.addressBook.row.defaultLabel')}
        </div>
      );
    }

    return (
      <button
        data-mark-primary
        className="button-as-link"
        type="button"
        onClick={this.handleMakePrimary}
      >
        {I18n.t('discovery.addressBook.row.actions.makeDefault')}
      </button>
    );
  }

  renderEuAddressDetails() {
    return (
      <div className="mt-2">
        <p className="formatted-address">
          {this.props.address.phone}
          <br />
          {this.props.address.email}
        </p>
      </div>
    );
  }

  render() {
    const { address } = this.props;

    return (
      <li className="actionable-row">
        <div className="actionable-row__content">
          <AddressSummary address={address} />
          {this.isEuOdrAddress && this.renderEuAddressDetails()}
        </div>
        <ul className="actionable-row__actions">
          <li className="actionable-row__action">{this.renderPrimary()}</li>
          <li className="actionable-row__action">
            <button
              data-edit-address
              className="button-as-link icon-l-pencil"
              onClick={this.handleEdit}
              type="button"
            >
              {I18n.t('discovery.addressBook.row.actions.edit')}
            </button>
          </li>
        </ul>
      </li>
    );
  }
}
