import React from 'react';
import I18n from 'i18n-js';
import { times } from 'lodash';
import { core_apimessages_NegotiationParty } from '../gql/graphql';
import { RCSelect } from '@reverbdotcom/cadence/components';

interface IProps {
  inventory: number;
  party: core_apimessages_NegotiationParty;
  quantity: number;
  onQuantityChange: (any) => void;
}

function inventoryOption(offset: number) {
  const displayQuantity = `${offset + 1}`;
  return { text: displayQuantity, value: displayQuantity };
}

export function OfferQuantitySelect(props: IProps) {
  const inventoryOptions = times(props.inventory, inventoryOption);

  function onSelect(inputOffer: { quantity: string }) {
    props.onQuantityChange({ type: 'setQuantity', value: inputOffer.quantity });
  }

  return (
    <RCSelect
      id="offer-quantity"
      value={`${props.quantity}`}
      label={I18n.t('commons.offers.offerQuantity')}
      onChange={(e) => {
        onSelect({ quantity: e.target.value });
      }}
      disabled={props.party === core_apimessages_NegotiationParty.BUYER}
    >
      {inventoryOptions.map((option) => {
        return (
          <RCSelect.Option
            key={option.value}
            label={option.text}
            value={option.value}
          />
        );
      })}
    </RCSelect>
  );
}

export default OfferQuantitySelect;
