import AltFactory from 'alt';

const alt = new AltFactory();
export default alt;

export class AltStoreModel<S> {
  bindActions: (...actions: Record<string, any>[]) => void;

  bindAction: (...args: any[]) => void;

  bindListeners: (obj: any) => void;

  exportPublicMethods: (config: { [key: string]: (...args: any[]) => any }) => any;

  exportAsync: (source: any) => void;

  waitFor: any;

  exportConfig: any;

  getState: () => S;

  setState: (S) => S;
}

export class AltActions {
  actions: any;

  dispatch: (...payload: any[]) => void;

  generateActions: (...actions: string[]) => void;
}
