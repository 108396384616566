// TODO update this to import from commons
// import { gql } from '@reverbdotcom/commons/src/gql';
// eslint-disable-next-line no-restricted-imports
import { gql } from '@apollo/client';
import React from 'react';
import { withGraphql } from '@reverbdotcom/commons/src/with_graphql';
import {
  RequestTaxFormDownloadMutation,
  RequestTaxFormDownloadMutationVariables,
  FetchTaxFormDownloadMutation,
  FetchTaxFormDownloadMutationVariables,
} from '@reverbdotcom/commons/src/gql/graphql';
import { ITaxForm, MutationProps } from '../tax_profile_helpers';
import MultipleTaxForms from './multiple_tax_forms';
import { connect } from '@reverbdotcom/commons/src/connect';

interface ExternalProps {
  taxForm: ITaxForm;
  taxForms: ITaxForm[];
  taxFormDownload: FetchTaxFormDownloadMutation['taxFormDownload'];
  lastRequestedAt: string;
}


type Props = ExternalProps & MutationProps;

export function TaxForms({ requestTaxFormDownload, fetchTaxFormDownload, taxForms }: Props) {
  return <MultipleTaxForms
    taxForms={taxForms}
    requestTaxFormDownload={requestTaxFormDownload}
    fetchTaxFormDownload={fetchTaxFormDownload}
  />;
}

const REQUEST_TAX_FORM_DOWNLOAD = gql`
  mutation RequestTaxFormDownload(
    $input: Input_core_apimessages_RequestTaxFormDownloadRequest
  ) {
    requestTaxFormDownload(input: $input) {
      taxFormDownload {
        id
        status
      }
      error
    }
  }
`;

const FETCH_TAX_FORM_DOWNLOAD = gql`
  mutation FetchTaxFormDownload(
    $input: Input_core_apimessages_TaxFormDownloadRequest
  ) {
    taxFormDownload(input: $input) {
      taxFormDownload {
        id
        status
        url
      }
    }
  }
`;

const withRequestTaxFormDownload = withGraphql<ExternalProps, RequestTaxFormDownloadMutation, RequestTaxFormDownloadMutationVariables>(
  REQUEST_TAX_FORM_DOWNLOAD, {
    name: 'requestTaxFormDownload',
    options: {
      errorPolicy: 'all',
    },
  });

const withTaxFormDownload = withGraphql<ExternalProps, FetchTaxFormDownloadMutation, FetchTaxFormDownloadMutationVariables>(FETCH_TAX_FORM_DOWNLOAD, {
  name: 'fetchTaxFormDownload',
  options: {
    errorPolicy: 'all',
  },
});

export default connect<ExternalProps>([withRequestTaxFormDownload, withTaxFormDownload])(TaxForms);
