import React, { useCallback, useState } from 'react';
import AdminAccommodationDataFields, { IAccommodationDataFields } from '@reverbdotcom/commons/src/components/admin_accommodation_data_fields';
import { RCRadioGroup } from '@reverbdotcom/cadence/components';

interface IProps {
  categoryToReasonMap: object;
}

interface IShippingLabelBillingMethodData extends IAccommodationDataFields {
  feeProcessingMethod: string;
}

export default function AdminShippingLabelBillingMethodFields({
  categoryToReasonMap,
}: IProps) {
  const [formData, setFormData] = useState<IShippingLabelBillingMethodData>({
    feeProcessingMethod: '',
    selectedCategory: '',
    selectedReason: '',
    additionalContext: '',
  });

  const setFormField = useCallback((fieldName, value) => {
    setFormData((state) => ({
      ...state,
      [fieldName]: value,
    }));
  }, [ setFormData ]);

  const handleFeeProcessingMethodChange = (event) => {
    setFormField('feeProcessingMethod', event.target.value);
  };

  const isAccommodation = formData.feeProcessingMethod == 'no_fee';

  return (
    <>
      <RCRadioGroup
        id="fee_processing_method"
        label=""
        name="fee_processing_method"
        onChange={handleFeeProcessingMethodChange}
        required
        value={formData.feeProcessingMethod}
      >
        <RCRadioGroup.Option
          label="No Fee"
          sublabel="Comped label - Reverb pays base price"
          value="no_fee"
        />
        <RCRadioGroup.Option
          label="Add to Seller Statement"
          sublabel="Seller will pay retail price in their statement currency"
          value="add_to_seller_statement"
        />
        <RCRadioGroup.Option
          label="Add to Buyer Statement"
          sublabel="Buyer will pay retail price in their statement currency"
          value="add_to_buyer_statement"
        />
      </RCRadioGroup>

      {isAccommodation && (
        <AdminAccommodationDataFields
          categoryToReasonMap={categoryToReasonMap}
          formData={formData}
          setFormField={setFormField}
        />
      )}
    </>
  );
}
