import React from 'react';
import I18n from 'i18n-js';
import SanitizedRender from '@reverbdotcom/commons/src/components/sanitized_render';
import URLHelpers from '../../shared/url_helpers';
import DashboardPageHeader from '../../dashboard_page_header';

interface IShipOrderHeaderProps {
  pageTitle: string;
  orderId: string;
}

export default function ShipOrderHeader({ pageTitle, orderId }: IShipOrderHeaderProps) {
  return (
    <DashboardPageHeader
      breadcrumbs={[
        {
          title: I18n.t('discovery.dashboard.selling.shipOrderViews.header.selling'),
          to: URLHelpers.listingsManagerPath(),
        },
        {
          title: I18n.t('discovery.dashboard.selling.shipOrderViews.header.allOrders'),
          to: URLHelpers.ordersManagerPath(),
        },
        {
          title: I18n.t('discovery.dashboard.selling.shipOrderViews.header.orderNumber', { orderId }),
          to: URLHelpers.orderPath({ orderId }),
          current: true,
        },
      ]}
      title={pageTitle}
      actions={[
        {
          key: 'shipping-tips-link',
          node: (
            <SanitizedRender
              html={
                I18n.t(
                  'discovery.dashboard.selling.shipOrderViews.header.visitShippingGuide',
                  { linkOpen: `<a href=${URLHelpers.shippingGuidePath}>`, linkClose: '</a>' },
                )
              }
            />
          ),
        },
      ]}
    />
  );
}
