import { ChildProps } from '@apollo/client/react/hoc';
// TODO update this to import from commons
// import { gql } from '@reverbdotcom/commons/src/gql';
// eslint-disable-next-line no-restricted-imports
import { gql } from '@apollo/client';
import React from 'react';
import { RCAlertBox, RCTextWithIcon } from '@reverbdotcom/cadence/components';
import CoreLink from '@reverbdotcom/commons/src/components/core_link';
import { I18N } from '@reverbdotcom/commons/src/components/translate';
import { Paths } from '@reverbdotcom/commons/src/url_helpers';
import { SearchAltIcon } from '@reverbdotcom/cadence/icons/react';
import { MParticleEventName } from '@reverbdotcom/commons/src/elog/mparticle_tracker';
import { useViewTracking } from '@reverbdotcom/commons/src/use_tracking';
import withSuggestionCheck, { isSuggestionValid } from './with_suggestion_check';
import { CoreWithSuggestionCheckListingsSearch, MarketplaceSuggestions } from '@reverbdotcom/commons/src/gql/graphql';

interface IExternalProps {
  query: string;
  suggestion: MarketplaceSuggestions.Fragment;
}

type IProps = ChildProps<IExternalProps, CoreWithSuggestionCheckListingsSearch.Query>;

const COMPONENT_NAME = 'NoResultsAlert';

export const MarketplaceSuggestionsFragment = gql`
  fragment MarketplaceSuggestions on reverb_search_SearchResponse_Suggestion {
    text
  }
`;

export function NoResultsAlert(props: IProps) {
  const viewTrackingData = {
    componentName: COMPONENT_NAME,
    eventName: MParticleEventName.ComponentView,
    suggestion: props.suggestion?.text,
  };

  useViewTracking(viewTrackingData, !!props.query);

  if (!props.query) return null;

  const clickTrackingData = {
    componentName: 'NoResultsAlert',
    eventName: MParticleEventName.ClickedSearchSuggestion,
    suggestion: props.suggestion?.text,
  };

  const suggestionValid = isSuggestionValid(props);

  return (
    <div className="mb-6">
      <RCAlertBox
        type="error"
        headerText={suggestionValid && <NoResultsFor query={props.query} />}
      >
        {suggestionValid ? (
          <div className="d-flex fx-align-center fx-wrap">
            <I18N html text="discovery.noResults.didYouMean" />
            <CoreLink
              className="button mlr-space mobile-mlr-0 mobile-d-block mobile-mt-2 ws-normal"
              to={Paths.marketplace.expand({ query: props.suggestion.text })}
              clickEvent={clickTrackingData}
            >
              <RCTextWithIcon svgComponent={SearchAltIcon}>
                <span>{props.suggestion.text}</span>
              </RCTextWithIcon>
            </CoreLink>
          </div>
        ) : (
          <NoResultsFor query={props.query} />
        )}
      </RCAlertBox>
    </div>
  );
}

function NoResultsFor({ query }) {
  return (
    <I18N
      html
      text="discovery.noResults.showingNoResultsFor"
    >
      <span className="weight-bold"><q>{query}</q></span>
    </I18N>
  );
}

export default withSuggestionCheck<IProps>(NoResultsAlert);
