import React from 'react';

const PATTERN = '[0-9.,\s]*';

class NumericInput extends React.Component {
  render() {
    const valueProps = {};
    if (this.props.defaultValue) {
      valueProps.defaultValue = this.props.defaultValue;
    } else if (this.props.value) {
      valueProps.value = this.props.value;
    }

    return (
      <input
        ref="input"
        type="text"
        pattern={this.props.pattern || PATTERN}
        className={this.props.className}
        required={this.props.required}
        placeholder={this.props.placeholder}
        disabled={this.props.disabled}
        name={this.props.name}
        {...valueProps}
        value={this.props.value}
        onChange={this.props.onChange}
      />
    );
  }
}

export default NumericInput;
