export default {
  banner: {
    documentAlt: 'Document Icon',
    stepsToPrepare: 'Two easy steps to prepare for tax season',
    submitYourInfo: 'Submit your tax information',
    request1099: 'Request Form 1099-K',
    step1Alt: 'Step 1',
    step2Alt: 'Step 2',
    explanationHtml: 'If you sell more than $600 in a calendar year, your tax info is needed so we can issue you a Form 1099-K. {{children}}',
  },
  footer: {
    speakerAlt: 'Speaker Icon',
    shieldAlt: 'Shield Icon',
    doINeedToProvideInfoHeader: 'Do I need to provide my tax info?',
    doINeedToProvideInfoLine1: 'For sales made in 2024, Reverb is required to collect tax identification information from US sellers who exceed the federal threshold of $5,000 in sales  or live in a state with a lower reporting threshold. ',
    doINeedToProvideInfoLearnMore1: 'You can learn more about 2024 US tax reporting requirements here.',
    doINeedToProvideInfoParagraph2: 'This tax information is needed to issue you a Form 1099-K. Once you\'ve provided your tax information, we\'ll reach out to you on any next steps—including when your Form 1099-K is ready to download. Note that reporting your sales doesn\'t necessarily mean you\'ll need to pay income tax on your sales.',
  },
  tinVerification: {
    incomplete: 'Incomplete',
    complete: 'Complete',
    pending: 'Pending',
  },
  taxForms: {
    comingSoon: 'Coming soon: if you exceeded federal or state tax reporting thresholds in {{prevYear}}, your Form 1099-K will be available here at the end of January {{currYear}}.',
    noFormsAvailable: 'No tax forms available. You didn\'t meet the 1099-K reporting threshold for {{prevYear}}.',
    downloadTaxForm: 'Download',
    failure: 'Something went wrong downloading your tax form.  Please try again.',
    loading: 'Fetching Form...',
    requestDownload: 'Request Form',
    requested: 'Form 1099-K successfully requested! A download link to your Form 1099-K will be available below shortly.',
    secureDownload: 'For your security, this link will expire after 24 hours.',
    title: 'Request Form 1099-K',
    description: 'If you prefer a paper copy of your Form 1099-K mailed to you, please email {{children}} no later than December 31.',
    emailLinkText: '1099taxreporting@reverb.com',
    downloadIncompleteStatus: 'Incomplete',
    downloadRequestedStatus: 'Last requested on {{date}}',
    downloadNotYetRequested: 'This form has not been requested yet.',
    taxYear: 'Tax Year',
    taxYearStatus: 'Status',
  },
  helpCenterLinkText: 'Learn more in our Help Center.',
  taxProfileForm: {
    tinFailedVerificationHeadline: 'Important: Updated tax info needed',
    tinFailedVerificationMessage: 'Your TIN was not able to be verified. Please double check that the information you provided matches what you used to file your taxes.',
  },
  usTaxProfile: {
    title: 'Provide your tax information here',
    explainer: 'Enter your US federal Social Security Number (SSN), Individual Taxpayer Identification Number (ITIN), or Employer Identification Number (EIN).',
    formEligibilityVerification: 'We’ll verify your info and issue you a Form 1099-K if you’re eligible.',
    taxInfoPendingVerification: 'Your tax information has been submitted and is pending verification. We’ll reach out to you on any next steps, including when your Form 1099-K is ready to download.',
    completedTaxProfile: 'We’ll reach out to you on any next steps, including when your Form 1099-K is ready to download.',
    helpCenterLinkText: 'Learn more about US tax reporting requirements in our Help Center.',
    ssn: 'Social Security Number (SSN)',
    ein: 'Employer Identification Number (EIN)',
    ssnLabel: 'Social Security Number (SSN) {{children}}',
    einLabel: 'Employer Identification Number (EIN) {{children}}',
    businessName: 'Business Name',
    legalName: 'Legal Name',
    address: 'Address',
    editTaxProfile: 'Edit Tax Profile',
    delete: 'Delete',
    deleteTaxProfile: 'Delete Tax Profile?',
    cancel: 'Cancel',
    submitPrompt: 'Submit Tax Info Securely',
    individualOrBusiness: 'Are you operating as an individual or a business on Reverb?',
    individual: 'Individual',
    business: 'Business',
    numberHint: '(9 digits)',
    privacyAndEncryption: 'Don’t worry—your information is sent privately. We use encryption to store it.',
    firstName: 'First Name',
    lastName: 'Last Name',
    nameInstructionsBusiness: 'Please enter the name used to register your tax ID. Only the characters A-Z, a-z, 0-9, spaces, hyphens, and ampersands are permitted.',
    nameInstructionsIndividual: 'Please enter the name used when filing your taxes. Only the characters A-Z, a-z, 0-9, spaces, hyphens, and ampersands are permitted.',
    pleaseConfirm: 'Please check that the Tax ID information you entered above is correct and matches the information you use for your taxes. Once complete, we’ll verify your information and reach out with any next steps.',
    confirmDeleteTitle: 'Are you sure you want to delete your tax information?',
  },
};
