import classNames from 'classnames';
import I18n from 'i18n-js';
import React from 'react';
import { decamelize } from 'humps';

import CoreLink from '@reverbdotcom/commons/src/components/core_link';
import Currency from '@reverbdotcom/commons/src/currency';
import FormGroupWithCheckbox from '@reverbdotcom/commons/src/components/form_group_with_checkbox';
import Modal from '@reverbdotcom/commons/src/components/modal';
import { RCTextInput, RCTextWithIcon, RCTooltip } from '@reverbdotcom/cadence/components';
import { AngleRightIcon } from '@reverbdotcom/cadence/icons/react';
import { unformatNumber } from '@reverbdotcom/commons/src/money';

import ShippingCostEstimator from './ShippingCostEstimator';
import URLHelpers from './url_helpers';
import { IRegionalRate } from './edit_shipping_rate_card_regional_prices';
import { IMoneyFieldState } from '../sell/form/sellFormReducerTypes';

// This component expects an explicit currency code for each shipping rate object.
// It also expects to receive all money state fields (rate|expeditedRate|incrementalRate|freeShippingThreshold)
// to contain localized `amount` strings based on the user's locale setting.
//
// E.g. a USD/en-locale seller:
// shippingRate={{
//   rate: { amount: '5000' }  // ('5,000.00' or `5000.00` will also be handled correctly)
//   incrementalRate: { amount: '4000' }  // ('4,000.00' or `4000.00` will also be handled correctly)
// }}
//
// E.g. a EUR/de-locale seller:
// shippingRate={{
//   rate: { amount: '5.000,12' }  // ('5000,12' will also be handled correctly)
//   incrementalRate: { amount: '4.000,12' }  // ('4000,12' will also be handled correctly)
// }}
export interface IShippingRate {
  isRequired?: boolean;
  regionType?: string;
  regionCode?: string;
  regionName?: string;
  rate?: IMoneyFieldState;
  expeditedRate?: IMoneyFieldState;
  incrementalRate?: IMoneyFieldState;
  freeShippingThreshold?: IMoneyFieldState;
  standardRegionalRates?: IRegionalRate[];
  expeditedRegionalRates?: IRegionalRate[];
}

// The names of the individual money fields on a ShippingRate object, as returned by our backend.
export enum PriceField {
  STANDARD = 'rate',
  COMBINED = 'incrementalRate',
  EXPEDITED = 'expeditedRate',
}

export interface IProps {
  shippingRate?: IShippingRate;
  handleEditShippingRate?: (IShippingRate) => void;
  currency: string;
  allowedToShipExpedited: boolean;
  disabled?: boolean;
  displayShippingEstimator?: boolean;
  forShippingProfile?: boolean;
}

export function invalidCombinedRate(shippingRate: IShippingRate): boolean {
  const standardAmount = shippingRate[PriceField.STANDARD]?.amount || '';
  const combinedAmount = shippingRate[PriceField.COMBINED]?.amount || '';

  return standardAmount && combinedAmount && unformatNumber(combinedAmount) >= unformatNumber(standardAmount);
}

export function invalidExpeditedRate(shippingRate: IShippingRate): boolean {
  const standardAmount = shippingRate[PriceField.STANDARD]?.amount || '';
  const expeditedAmount = shippingRate[PriceField.EXPEDITED]?.amount || '';

  return standardAmount && expeditedAmount && unformatNumber(expeditedAmount) < unformatNumber(standardAmount);
}

export function priceInputErrorMessage(shippingRate: IShippingRate, forField: PriceField, inRegionalShipping = false): string {
  const standardAmount = shippingRate[PriceField.STANDARD]?.amount || '';

  switch (forField) {
    case PriceField.STANDARD:
      if (!standardAmount) {
        return I18n.t('discovery.dashboard.selling.shippingRates.rateFields.validationErrors.standardRequired');
      }

      break;
    case PriceField.COMBINED:
      if (invalidCombinedRate(shippingRate)) {
        return I18n.t('discovery.dashboard.selling.shippingRates.rateFields.validationErrors.combinedGreaterThanStandard');
      }

      break;
    case PriceField.EXPEDITED:
      if (invalidExpeditedRate(shippingRate)) {
        if (inRegionalShipping) {
          return I18n.t('discovery.dashboard.selling.shippingRates.rateFields.validationErrors.regionalExpeditedLessThanStandard');
        } else {
          return I18n.t('discovery.dashboard.selling.shippingRates.rateFields.validationErrors.expeditedLessThanStandard');
        }
      }

      break;
    default:
      return '';
  }

  return '';
}

export function ManualPriceEntry({
  rateType,
  value,
  onUpdate,
  currency,
  error,
  tooltipText = '',
  label,
  helpText,
  indented = false,
  required = false,
  disabled = false,
  optional = false,
  forShippingProfile = false,
}: {
  rateType: PriceField;
  value: string;
  onUpdate: (string) => void;
  currency: string;
  error: string;
  tooltipText?: string;
  indented?: boolean;
  required?: boolean;
  disabled?: boolean;
  optional?: boolean;
  label?: string;
  helpText?: string;
  forShippingProfile?: boolean;
}) {
  const [hasBeenTouched, setHasBeenTouched] = React.useState(Boolean(value));

  React.useEffect(() => {
    if (value) {
      setHasBeenTouched(true);
    }
  }, [value]);

  // In shipping profile context, need to use input names expected by HAML form/controller.
  const inputName = forShippingProfile ? `shipping_profile[shipping][rates][][${decamelize(rateType)}]` : rateType;

  const className = classNames(
    'shipping-rate-card__manual__price__entry',
    { 'shipping-rate-card__manual__price__entry--indented': indented },
  );

  let tagType;
  if (required) { tagType = 'required'; }
  if (optional) { tagType = 'optional'; }

  const labelText = label || I18n.t(`discovery.dashboard.selling.shippingRates.rateFields.${rateType}`);

  return (
    <div className={className}>
      <div className="shipping-rate-card__manual__price__entry__input">
        <RCTextInput
          label={tooltipText ? (
            <RCTooltip text={tooltipText}>
              {labelText}
            </RCTooltip>
          ) : labelText}
          value={value}
          prefixText={Currency.get(currency).symbol}
          onChange={(e) => onUpdate(e.target.value)}
          helpText={helpText}
          errorText={hasBeenTouched && error}
          disabled={disabled}
          required={required}
          tag={tagType}
          type="number"
          name={inputName}
        />
      </div>
    </div>
  );
}

export function EditShippingRateCardManualPrices({
  shippingRate,
  handleEditShippingRate,
  currency,
  allowedToShipExpedited,
  disabled = false,
  displayShippingEstimator = false,
  forShippingProfile = false,
}: IProps) {
  const [isExpeditedShippingEnabled, setIsExpeditedShippingEnabled] = React.useState(!!shippingRate[PriceField.EXPEDITED]?.amount);
  const [isCombinedShippingEnabled, setIsCombinedShippingEnabled] = React.useState(!!shippingRate[PriceField.COMBINED]?.amount);
  const [isEstimatorModalOpen, setIsEstimatorModalOpen] = React.useState(false);

  const forDomesticRegion = shippingRate.isRequired;
  const showExpeditedControls = forDomesticRegion && forShippingProfile;

  return (
    <div className="shipping-rate-card__manual__prices">
      <ManualPriceEntry
        forShippingProfile={forShippingProfile}
        rateType={PriceField.STANDARD}
        value={shippingRate[PriceField.STANDARD]?.amount || ''}
        onUpdate={(v) => handleEditShippingRate({
          ...shippingRate,
          [PriceField.STANDARD]: v ? { amount: v } : null,
        })}
        currency={currency}
        error={priceInputErrorMessage(shippingRate, PriceField.STANDARD)}
        tooltipText={isCombinedShippingEnabled && I18n.t('discovery.dashboard.selling.shippingRates.rateFields.standardShippingTooltip')}
        required={!forShippingProfile}
        disabled={disabled}
      />

      {displayShippingEstimator && (
        <button
          type="button"
          className="shipping-rate-card__estimator__button"
          onClick={() => setIsEstimatorModalOpen(!isEstimatorModalOpen)}
        >
          <RCTextWithIcon svgComponent={AngleRightIcon}>
            {I18n.t('discovery.dashboard.selling.shippingRates.shippingCostEstimator')}
          </RCTextWithIcon>
        </button>
      )}

      <div className="shipping-rate-card__enable__checkbox">
        <FormGroupWithCheckbox
          data-combined-toggle
          inputName={`enable_combined_${shippingRate.regionCode}`}
          label={I18n.t('discovery.dashboard.selling.shippingRates.rateFields.enableCombined')}
          helpText={I18n.t('discovery.dashboard.selling.shippingRates.rateFields.enableCombinedHelp')}
          checked={isCombinedShippingEnabled && !disabled}
          updateField={() => {
            if (isCombinedShippingEnabled) {
              handleEditShippingRate({ ...shippingRate, [PriceField.COMBINED]: null });
            }
            setIsCombinedShippingEnabled(!isCombinedShippingEnabled);
          }}
          disabled={disabled}
        />
      </div>

      {isCombinedShippingEnabled && (
        <ManualPriceEntry
          forShippingProfile={forShippingProfile}
          rateType={PriceField.COMBINED}
          value={shippingRate[PriceField.COMBINED]?.amount || ''}
          onUpdate={(v) => handleEditShippingRate({
            ...shippingRate,
            [PriceField.COMBINED]: v ? { amount: v } : null,
          })}
          currency={currency}
          tooltipText={I18n.t('discovery.dashboard.selling.shippingRates.rateFields.combinedShippingTooltip')}
          error={priceInputErrorMessage(shippingRate, PriceField.COMBINED)}
          disabled={disabled}
          indented
        />
      )}

      {showExpeditedControls && (
        <>
          <div className="shipping-rate-card__enable__checkbox">
            <FormGroupWithCheckbox
              data-expedited-toggle
              disabled={!allowedToShipExpedited}
              inputName={`enable_expedited_${shippingRate.regionCode}`}
              label={(
                <>
                  {I18n.t(`discovery.dashboard.selling.shippingRates.rateFields.expeditedLabel.${allowedToShipExpedited ? 'enabled' : 'disabled'}`)}
                  <CoreLink
                    to={URLHelpers.expeditedShippingHelpPath}
                    target="_blank"
                    className="ml-space"
                  >
                    {I18n.t(`discovery.dashboard.selling.shippingRates.rateFields.expeditedLearn.${allowedToShipExpedited ? 'enabled' : 'disabled'}`)}
                  </CoreLink>
                </>
              )}
              updateField={() => {
                if (isExpeditedShippingEnabled) {
                  handleEditShippingRate({ ...shippingRate, [PriceField.EXPEDITED]: null });
                }
                setIsExpeditedShippingEnabled(!isExpeditedShippingEnabled);
              }}
              checked={isExpeditedShippingEnabled}
            />
          </div>

          {isExpeditedShippingEnabled && (
            <ManualPriceEntry
              forShippingProfile={forShippingProfile}
              rateType={PriceField.EXPEDITED}
              value={shippingRate[PriceField.EXPEDITED]?.amount || ''}
              onUpdate={(v) => handleEditShippingRate({
                ...shippingRate,
                [PriceField.EXPEDITED]: v ? { amount: v } : null,
              })}
              currency={currency}
              error={priceInputErrorMessage(shippingRate, PriceField.EXPEDITED)}
              disabled={disabled}
              indented
            />
          )}
        </>
      )}

      <Modal
        isOpen={displayShippingEstimator && isEstimatorModalOpen}
        onRequestClose={() => setIsEstimatorModalOpen(false)}
        headerTitle={I18n.t('discovery.dashboard.selling.shippingRates.shippingCostEstimator')}
        size="wide"
        shouldCloseOnOverlayClick
        paddedBody
      >
        <div>
          <ShippingCostEstimator />
        </div>
      </Modal>
    </div>
  );
}

export default EditShippingRateCardManualPrices;
