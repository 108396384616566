import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import { MobileDetection } from '@reverbdotcom/discovery-ui';

/**
 * Creates a component that displays a series of steps and provides an animation to
 * highlight the current step. Used to show the progress of an item shipment.
 * This ShippingProgressBar can be displayed in mini mode with (min-width: 7em) which
 * displays the current status over the bar. In normal (non-mini) mode
 * the bar displays each step as a label.
 * You will need to supply an i18n prefix for the translation of those labels.
 */
class ShippingProgressBar extends React.Component {
  label(step) {
    if (this.props.mini) { return false; }
    return (
      <div className="label">
        {I18n.t(`${this.props.i18nPrefix}.${step}`)}
      </div>
    );
  }

  path(index, activeIndex, total) {
    if (index === total - 1) { return false; }

    const pathClasses = classNames(
      'path',
      { finished: (index <= activeIndex - 1) },
      { active: (index === activeIndex - 1) },
      { warning: (activeIndex === -1) },
    );

    return (
      <li className={pathClasses} />
    );
  }

  isDelivered() {
    return this.props.activeStep === 'delivered';
  }

  showCurrentStatus() {
    if (!this.props.mini && !this.isDelivered() && this.props.estimatedDeliveryDateMessage) {
      return this.renderCurrentStatus(this.props.estimatedDeliveryDateMessage);
    }

    if (!this.props.mini && !MobileDetection.isMobileBrowser() && !this.isUnknownStatus()) { return false; }

    return this.renderCurrentStatus(I18n.t(`${this.props.i18nPrefix}.${this.props.activeStep}`));
  }

  isUnknownStatus() {
    return this.props.steps.indexOf(this.props.activeStep) === -1;
  }

  renderCurrentStatus(text) {
    return (
      <h3 className="progress-bar-header">
        {text}
      </h3>
    );
  }

  render() {
    const classes = classNames(
      'progress-bar',
      this.props.className,
      { mini: this.props.mini },
    );
    const activeIndex = this.props.steps.indexOf(this.props.activeStep);

    return (
      <div className={classes}>
        {this.showCurrentStatus()}
        <ul>
          {this.props.steps.map((step, index) => {
            const stepClasses = classNames(
              'step',
              { finished: (index <= activeIndex) },
              { current: (index === activeIndex) },
              { warning: (activeIndex === -1) },
            );

            return (
              <span key={step}>
                <li className={stepClasses}>{this.label(step)}</li>
                {this.path(index, activeIndex, this.props.steps.length)}
              </span>
            );
          })}
        </ul>
      </div>
    );
  }
}

ShippingProgressBar.propTypes = {
  // steps will be displayed in the order they are provided.
  steps: PropTypes.arrayOf(PropTypes.string).isRequired,
  // The active step should be present in the available steps above
  activeStep: PropTypes.string.isRequired,

  i18nPrefix: PropTypes.string,

  /**
  * Use the smaller version of the graph which omits the labels for
  * each step and only shows the current step.
  */
  mini: PropTypes.bool,
  // Estimated delivery date is not always available from the shipping provider. If so pass empty string.
  estimatedDeliveryDateMessage: PropTypes.string.isRequired,
};

ShippingProgressBar.defaultProps = {
  mini: false,
};

export default ShippingProgressBar;
