import { RCAlertBox } from '@reverbdotcom/cadence/components';
import React from 'react';
import I18n from 'i18n-js';
import { I18N as Translate } from '@reverbdotcom/commons/src/components/translate';

interface ExternalProps {
  allStatementsPaid: boolean;
  statementFullyPaid: boolean;
}

function StatementPaymentSuccessBanner({
  allStatementsPaid,
  statementFullyPaid,
}: ExternalProps) {

  function getText(textType) {
    let text = textType;

    if (allStatementsPaid) {
      text += 'AllStatementsPaid';
    } else if (statementFullyPaid) {
      text += 'StatementFullyPaid';
    } else {
      text += 'StatementPartiallyPaid';
    }

    return `discovery.payStatement.paymentSuccessBanner.${text}`;
  }

  return (
    <div className="scaling-mb-4">
      <RCAlertBox
        small
        headerText={I18n.t(getText('header'))}
        type="success"
      >
        <Translate
          tag="p"
          text={getText('message')}
        />
      </RCAlertBox>
    </div>
  );
}

export default StatementPaymentSuccessBanner;
