import React from 'react';
import I18n from 'i18n-js';
import classNames from 'classnames';

import { randInt } from '../random';
import { useUser } from '../user_hooks';
import { Paths } from '../url_helpers';
import { getItem } from '../local_storage_utils';
import { compact, reject, trim } from 'lodash';
import CoreLink from './core_link';
import { MParticleEventName } from '../elog/mparticle_tracker';
import { useViewTracking } from '../use_tracking';
import { ArrowRightIcon } from '@reverbdotcom/cadence/icons/react';
import { RCTextWithIcon } from '@reverbdotcom/cadence/components';

export const SUGGESTION_LIMIT = 5;
const COMPONENT_NAME = 'RecentSearches';

function hydrate(setRecentSearches) {
  let recentSearches: string[] = compact(getItem('RECENT_SEARCHES') || []);

  recentSearches = reject(
    recentSearches.map(s => trim(s)),
    s => s === '',
  );

  setRecentSearches(recentSearches);
}

function getLabel(hasRecentSearches, recentSearches) {
  return !hasRecentSearches ?
    'suggestions:df' :
    `suggestions:rs:${recentSearches.length}`;
}

function getDefaultSuggestions(user) {
  const index = randInt(user.requestId, defaultSuggestions().length);

  if (index + SUGGESTION_LIMIT <= defaultSuggestions().length) {
    return defaultSuggestions().slice(index, index + SUGGESTION_LIMIT);
  }

  return defaultSuggestions().slice(index - SUGGESTION_LIMIT, index);
}

export function defaultSuggestions() {
  return [
    I18n.t('commons.searchHeader.suggestions.fenderStratocaster'),
    I18n.t('commons.searchHeader.suggestions.elektronDigitakt'),
    I18n.t('commons.searchHeader.suggestions.fenderPrecisionBass'),
    I18n.t('commons.searchHeader.suggestions.lesPaulCustom'),
    I18n.t('commons.searchHeader.suggestions.tubeScreamer'),
    I18n.t('commons.searchHeader.suggestions.ludwigBlackBeauty'),
    I18n.t('commons.searchHeader.suggestions.ludwigJazzFestival'),
    I18n.t('commons.searchHeader.suggestions.MarshallJcm800'),
    I18n.t('commons.searchHeader.suggestions.moogModelD'),
    I18n.t('commons.searchHeader.suggestions.neumannU87'),
    I18n.t('commons.searchHeader.suggestions.procoRat'),
    I18n.t('commons.searchHeader.suggestions.rolandJuno106'),
    I18n.t('commons.searchHeader.suggestions.sureSm7b'),
    I18n.t('commons.searchHeader.suggestions.strymonTimeline'),
    I18n.t('commons.searchHeader.suggestions.taylorGsMini'),
    I18n.t('commons.searchHeader.suggestions.teenageEngineeringOp1'),
    I18n.t('commons.searchHeader.suggestions.apolloTwin'),
    I18n.t('commons.searchHeader.suggestions.voxAc30'),
    I18n.t('commons.searchHeader.suggestions.yamahaDx7'),
    I18n.t('commons.searchHeader.suggestions.zildjianNewBeat'),
  ];
}

export default function RecentSearches() {
  const user = useUser();
  const [recentSearches, setRecentSearches] = React.useState<[] | null>(null);
  const loading = recentSearches === null;
  const hasRecentSearches = !loading && recentSearches.length > 0;

  const suggestions = hasRecentSearches ?
    recentSearches.slice(0, SUGGESTION_LIMIT) :
    getDefaultSuggestions(user);

  React.useEffect(() => hydrate(setRecentSearches), []);

  const mParticleViewData = {
    componentName: 'RecentSearchesRow',
    eventName: MParticleEventName.ComponentView,
    displayStyle: getLabel(hasRecentSearches, recentSearches),
  };

  useViewTracking(mParticleViewData, !loading);

  const classes = classNames(
    'search-header__suggestions',
    { 'search-header__suggestions--hidden': loading },
  );

  const clickEvent = {
    componentName: COMPONENT_NAME,
    eventName: MParticleEventName.ClickedSearchSuggestion,
  };

  return (
    <div className={classes}>
      <p className="search-header__suggestions-title">
        {hasRecentSearches ?
          I18n.t('commons.searchHeader.pickUp') :
          I18n.t('commons.searchHeader.needInspiration')}
      </p>
      {suggestions.map((suggestion, index) => {
        return (
          <CoreLink
            className="search-header__suggestion"
            to={Paths.marketplace.expand({ query: suggestion })}
            key={index}
            clickEvent={{ ...clickEvent, position: index }}
          >
            <RCTextWithIcon svgComponent={ArrowRightIcon}>
              {suggestion}
            </RCTextWithIcon>
          </CoreLink>
        );
      })}
    </div>
  );
}
