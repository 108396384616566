import { MutationFunction } from '@reverbdotcom/commons/src/useHOCMutation';
// TODO update this to import from commons
// import { gql } from '@reverbdotcom/commons/src/gql';
// eslint-disable-next-line no-restricted-imports
import { gql } from '@apollo/client';
import React from 'react';
import I18n from 'i18n-js';
import { withGraphql } from '@reverbdotcom/commons/src/with_graphql';
import { core_apimessages_PacklinkPolicies as PacklinkPolicies, CorePacklinkRegisterAccount } from '@reverbdotcom/commons/src/gql/graphql';
import FormGroupWithCheckbox from '@reverbdotcom/commons/src/components/form_group_with_checkbox';
import FormGroupWithInput from '@reverbdotcom/commons/src/components/form_group_with_input';
import { useUser } from '@reverbdotcom/commons/src/user_hooks';
import SanizitedRender from '@reverbdotcom/commons/src/components/sanitized_render';

interface MutationProps {
  mutate: MutationFunction<CorePacklinkRegisterAccount.Mutation, CorePacklinkRegisterAccount.Variables>;
}

interface ExternalProps {
  onComplete: Function;
}

type Props = ExternalProps & MutationProps;

const MINIMUM_PASSWORD_LENGTH = 6;

const USER_LOCALE_TO_PACKLINK_LOCALE = {
  de: 'de',
  en: 'en-gb',
  es: 'es-es',
  fr: 'fr-fr',
  it: 'it',
};
const DEFAULT_PACKLINK_LOCALE = 'en-gb';

export function PacklinkRegisterAccountForm(props: Props) {
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [policies, setPolicies] = React.useState<PacklinkPolicies>({
    dataProcessing: false,
    termsAndConditions: false,
  });

  const user = useUser();
  const packlinkLocale = USER_LOCALE_TO_PACKLINK_LOCALE[user.locale] || DEFAULT_PACKLINK_LOCALE;
  const termsUrl = `https://support-pro.packlink.com/hc/${packlinkLocale}/articles/360010011480`;
  const privacyUrl = `https://support-pro.packlink.com/hc/${packlinkLocale}/articles/360010011560`;
  const termsAndConditionsText = I18n.t('discovery.packlink.register.termsAndConditions', {
    termsLinkOpen: `<a href="${termsUrl}">`,
    termsLinkClose: '</a>',
    privacyLinkOpen: `<a href="${privacyUrl}">`,
    privacyLinkClose: '</a>',
  });

  async function registerAccount(e) {
    e.preventDefault();
    const response = await props.mutate({
      variables: {
        input: {
          email,
          password,
          policies,
        },
      },
    });

    if (response) {
      props.onComplete();
    }
  }

  function updatePolicy(policy: Partial<PacklinkPolicies>) {
    setPolicies({ ...policies, ...policy });
  }

  return (
    <form onSubmit={registerAccount}>
      <FormGroupWithInput
        inputName="email"
        inputType="email"
        fieldName="email"
        label={I18n.t('discovery.packlink.register.email')}
        updateField={(field) => setEmail(field.email)}
        required
        tagRequired
      />
      <FormGroupWithInput
        inputName="password"
        inputType="password"
        fieldName="password"
        minLength={MINIMUM_PASSWORD_LENGTH}
        label={I18n.t('discovery.packlink.register.password')}
        updateField={(field) => setPassword(field.password)}
        helpText={I18n.t('discovery.packlink.register.passwordTip', { minLength: MINIMUM_PASSWORD_LENGTH })}
        required
        tagRequired
      />
      <FormGroupWithCheckbox
        inputName="dataProcessing"
        label={I18n.t('discovery.packlink.register.dataProcessing')}
        checked={policies.dataProcessing}
        fieldName="dataProcessing"
        updateField={updatePolicy}
        required
        tagRequired
      />
      <FormGroupWithCheckbox
        inputName="termsAndConditions"
        label={<SanizitedRender html={termsAndConditionsText} />}
        checked={policies.termsAndConditions}
        fieldName="termsAndConditions"
        updateField={updatePolicy}
        required
        tagRequired
      />
      <button className="button button--primary" type="submit">{I18n.t('discovery.packlink.register.submit')}</button>
    </form>
  );
}

const withMutation = withGraphql<ExternalProps, CorePacklinkRegisterAccount.Mutation, CorePacklinkRegisterAccount.Variables>(gql`
mutation Core_Packlink_RegisterAccount($input: Input_core_apimessages_RegisterMyPacklinkAccountRequest) {
  registerMyPacklinkAccount(input: $input) {
    shopId
  }
}`,
);

export default withMutation(PacklinkRegisterAccountForm);
