import React from 'react';
import classNames from 'classnames';
import { uniqueId } from 'lodash';

import { core_apimessages_Error as Error } from './gql/graphql';

interface Props {
  errors: Error[];
}

export function ErrorList({ errors }: Props) {
  const classes = classNames({ 'list-type-disc': errors.length > 1 });

  return (
    <ul className={classes}>
      {errors.map((e) => <li key={uniqueId(e.field)}>{e.message}</li>)}
    </ul>
  );
}
