import React from 'react';
import I18n from 'i18n-js';
import { RCAlertBox } from '@reverbdotcom/cadence/components';
import { Display } from './packlink_auth_form';

interface Props {
  display: Display;
}

export default function PacklinkInfoAlert({ display }: Props) {
  if (display !== Display.Form) return null;

  return (
    <RCAlertBox type="info">
      <div className="d-flex fx-align-center">
        <ul className="size-80">
          <li className="d-flex">
            <span className="icon-l-check color-green" />
            <span>
              {I18n.t('discovery.packlink.infoAlert.saveMoney')}
            </span>
          </li>
          <li className="d-flex">
            <span className="icon-l-check color-green" />
            {I18n.t('discovery.packlink.infoAlert.saveTime')}
          </li>
          <li className="d-flex">
            <span className="icon-l-check color-green" />
            {I18n.t('discovery.packlink.infoAlert.protectShipment')}
          </li>
        </ul>
      </div>
    </RCAlertBox>
  );
}
