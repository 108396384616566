import { V3 } from '../../api_request';
import alt, { AltActions } from '../../alt';
import { CURATED_SET_ROW_LENGTH } from '../../shared/constants';
import URLHelpers from '../../shared/url_helpers';

interface Actions {
  setRow(response: any);
  fetch(params: any): any;
  fetchHomepage();
  clear();
}

const IMAGE_CROP = 'search_filter_preset';
const DEFAULT_PARAMS = {
  per_page: CURATED_SET_ROW_LENGTH,
  thumb_size: IMAGE_CROP,
};

class CuratedSetRowActions extends AltActions implements Actions {
  buildParams(routeParams) {
    const params = {

      ...DEFAULT_PARAMS,
      ...routeParams,
    };

    delete params.page;
    return params;
  }

  setRow(curated_sets: any[]): any[] {
    return curated_sets;
  }

  fetchHomepage() {
    V3.get(URLHelpers.curatedSetsHomepagePath(), { thumb_size: IMAGE_CROP })
      .then((response) => { this.setRow(response.curated_sets); })
      .fail(() => this.clear());

    return null;
  }

  fetch(params) {
    V3.get(URLHelpers.curatedSetsPath(), this.buildParams(params))
      .then((response) => { this.setRow(response.curated_sets); })
      .fail(() => this.clear());

    return null;
  }

  clear() {
    this.setRow([]);

    // if you return undefined your actions won't dispatch
    return null;
  }
}

export default alt.createActions(CuratedSetRowActions);
