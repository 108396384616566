import React, { useContext, useState } from 'react';

import { core_apimessages_ShippingLabelPackage } from '@reverbdotcom/commons/src/gql/graphql';
import { useQuery } from '@reverbdotcom/commons/src/useQuery';
import { gql } from '@reverbdotcom/commons/src/gql';
import { RCLoadingBars } from '@reverbdotcom/cadence/components';

import { SellFormContext } from '../../SellFormContext';
import { buildPackageEntry, displayWeight, lengthWidthHeightSummary } from '../../../../discovery/shipping_labels/shipping_label_package';
import CarrierCalculatedPackageForm from './CarrierCalculatedPackageForm';
import { carrierCalculatedPackageValidator } from './carrierCalculatedPackageValidator';
import { SuggestedPackageToggle } from './SuggestedPackageToggle';
import { SelectedCarrier } from './SelectedCarrier';

interface IProps {
  packageEntry: core_apimessages_ShippingLabelPackage;
  setPackageEntry: (core_apimessages_ShippingLabelPackage) => void;
  setIsInCarrierCalculatedMode: (boolean) => void;
  disabled?: boolean;
}

export default function CarrierCalculatedPackageFormContainer({
  packageEntry,
  setPackageEntry,
  setIsInCarrierCalculatedMode,
  disabled = false,
}: IProps) {
  const { currentCanonicalProductId, listingState } = useContext(SellFormContext);
  const currentPackageEntryCameFromReverbSuggestion = !!packageEntry.packageSizeSuggestionId;
  const [isReverbSuggestedToggleActive, setIsReverbSuggestedToggleActive] = useState(currentPackageEntryCameFromReverbSuggestion);
  // Whenever backend PackageSizeSuggestion data is available, we want to keep around a copy of form state built from that suggestion.
  // This is true regardless of any subsequent changes that the user makes to the *actual* sell form state.
  // This way, we can instantly apply the suggestion whenever the user flips on the "use reverb suggestion" toggle.
  const [suggestedPackageEntryMemo, setSuggestedPackageEntryMemo] = useState(currentPackageEntryCameFromReverbSuggestion ? packageEntry : null);

  function onPackageSuggestionResponse(respData) {
    const suggestionData = respData?.canonicalproduct?.packageSizeSuggestion;
    const validSuggestionData = !!suggestionData && !carrierCalculatedPackageValidator(suggestionData)?.errorTranslationKey;
    const currentPackageEntryIsBlank = ['length', 'width', 'height', 'weight'].every((dimensionKey) => !packageEntry[dimensionKey].value);
    const suggestionDiffersFromCurrentEntry = validSuggestionData && ['length', 'width', 'height', 'weight'].some((dimensionKey) => {
      return Number(suggestionData[dimensionKey]?.value) !== Number(packageEntry[dimensionKey]?.value) ||
        suggestionData[dimensionKey]?.unit !== packageEntry[dimensionKey]?.unit;
    });

    if (!validSuggestionData) { return; }

    const packageEntryFromSuggestionData = buildPackageEntry(suggestionData, undefined, suggestionData.id);

    const shouldActivateSuggestion = (currentPackageEntryIsBlank || currentPackageEntryCameFromReverbSuggestion) && suggestionDiffersFromCurrentEntry;

    if (shouldActivateSuggestion) {
      setPackageEntry(packageEntryFromSuggestionData);
      setIsReverbSuggestedToggleActive(true);
    }

    setSuggestedPackageEntryMemo(packageEntryFromSuggestionData);
  }

  const { loading } = useQuery(
    FETCH_PACKAGE_SIZE_SUGGESTION,
    {
      skip: !currentCanonicalProductId || listingState !== 'draft',
      variables: { canonicalProductId: currentCanonicalProductId },
      onCompleted: onPackageSuggestionResponse,
    },
  );

  if (loading) {
    return (
      <div className="d-flex fx-align-center fx-justify-center mtb-2 mobile-mt-4">
        <RCLoadingBars />
      </div>
    );
  }

  return (
    <div className="mt-2">
      {!!suggestedPackageEntryMemo && (
        <SuggestedPackageToggle
          displayWeight={displayWeight(suggestedPackageEntryMemo.weight)}
          lengthWidthHeightSummary={lengthWidthHeightSummary(suggestedPackageEntryMemo)}
          checked={isReverbSuggestedToggleActive}
          onChange={() => {
            setIsReverbSuggestedToggleActive(!isReverbSuggestedToggleActive);

            if (!isReverbSuggestedToggleActive) {

              setPackageEntry(suggestedPackageEntryMemo);
            }
          }}
        />
      )}

      {!isReverbSuggestedToggleActive && (
        <CarrierCalculatedPackageForm
          packageEntry={packageEntry}
          setPackageEntry={setPackageEntry}
          setIsInCarrierCalculatedMode={setIsInCarrierCalculatedMode}
          disabled={disabled}
        />
      )}

      {!!packageEntry.allowedCarriers?.length && (
        <div className="mtb-3">
          <SelectedCarrier carrier={packageEntry.allowedCarriers[0]} />
        </div>
      )}
    </div>
  );
}

export const FETCH_PACKAGE_SIZE_SUGGESTION = gql(`
  query Core_SellForm_FetchPackageSizeSuggestion($canonicalProductId: String!) {
    canonicalproduct(input: { id: $canonicalProductId }) {
      _id
      packageSizeSuggestion {
        _id
        id
        length {
          unit
          value
        }
        width {
          unit
          value
        }
        height {
          unit
          value
        }
        weight {
          unit
          value
        }
      }
    }
  },
`);
