import React from 'react';
import { Location } from 'history';

import { RCCheckbox } from '@reverbdotcom/cadence/components';
import CoreLink from './core_link';
import { FilterOption } from './search_filters';
import { useUpdateUrl } from './with_update_url';
import { SelectFacetOptionCount, linkParams } from './select_facet_option';
import { inMobileApp } from '../user_context_helpers';
import { withRouter, WithRouterProps } from 'react-router';
import { useUser } from '../user_hooks';

interface IProps {
  option: FilterOption;
  trackInteraction: () => void;
  location?: Location;
  enableZeroCountOption?: boolean;
}

export function SelectFacetCheckboxComponent({
  option,
  location = undefined,
  enableZeroCountOption = false,
  trackInteraction,
  router,
}: IProps & WithRouterProps) {
  const user = useUser();
  const { updateUrl } = useUpdateUrl();

  const isInMobileApp = inMobileApp(user);
  const zeroCount = option.count?.value === 0;
  const nofollow = !option.directLinkOverride;
  const disabled = enableZeroCountOption ? false : zeroCount;

  const newUrl = option.directLinkOverride || (location && updateUrl({
    location: location,
    newParams: linkParams(option, true),
  }));

  // I have no idea why this was happening, but mobile apps (specifically iOS)
  // were not working correct with any variation of anchor tag here when the marketplace page
  // was rendered within a webview, but they work fine with a `router.push` event in the `onChange` callback
  const linkElement = isInMobileApp ? undefined : (
    (children: React.ReactNode) => (
      <CoreLink to={newUrl} onClick={trackInteraction} rel={nofollow ? 'nofollow' : undefined}>
        {children}
      </CoreLink>
    )
  );

  function mobileAppOnChange() {
    trackInteraction();

    router.push(newUrl);
  }

  const id = `${option.paramName}-${option.optionValue}`;
  return (
    <RCCheckbox
      id={id}
      key={`${id}--option${option.selected}`} // force a re-render of the checkbox when the selected state changes
      checked={option.selected}
      disabled={disabled}
      paddedHover
      onChange={isInMobileApp ? mobileAppOnChange : undefined}
      linkElement={linkElement}
      label={(
        <>
          <span className="mr-space">{option.name}</span>
          <SelectFacetOptionCount option={option} />
        </>
      )}
    />
  );
}

export const SelectFacetCheckbox = withRouter(SelectFacetCheckboxComponent);
