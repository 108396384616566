import { MutationFunction } from '@reverbdotcom/commons/src/useHOCMutation';
import {
  RequestTaxFormDownloadMutation,
  RequestTaxFormDownloadMutationVariables,
  FetchTaxFormDownloadMutation,
  FetchTaxFormDownloadMutationVariables,
  core_apimessages_TaxFormDownload,
} from '@reverbdotcom/commons/src/gql/graphql';

export const TAXES_HELP_CENTER_ARTICLE_ID = '4411577966227';
export const YEAR_2024_TAX_REPORTING_REQUIREMENTS_ARTICLE_ID = '35233186653459';

export interface ITaxForm {
  id: string;
  taxFormType: string;
  taxYear: number;
  taxFormDownload: core_apimessages_TaxFormDownload;
  lastRequestedAt: string;
}

export interface MutationProps {
  requestTaxFormDownload: MutationFunction<RequestTaxFormDownloadMutation, RequestTaxFormDownloadMutationVariables>;
  fetchTaxFormDownload: MutationFunction<FetchTaxFormDownloadMutation, FetchTaxFormDownloadMutationVariables>;
}
