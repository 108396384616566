import { core_apimessages_ShippingLabelPackage } from '@reverbdotcom/commons/src/gql/graphql';

import { carrierCalculatedUspsPackageValidator } from './carrierCalculatedUspsPackageValidator';
import { carrierCalculatedUpsPackageValidator } from './carrierCalculatedUpsPackageValidator';

export interface CarrierCalculatedPackageValidatorResponse {
  errorTranslationKey: string;
  carrier: string;
}

export function carrierCalculatedPackageValidator(packageEntry: core_apimessages_ShippingLabelPackage): CarrierCalculatedPackageValidatorResponse {
  const { length, width, height, weight } = packageEntry;
  if (!length || !width || !height || !weight) { return null; }
  const allInches = [length, width, height].every((dim) => dim.unit === 'in');
  const weightIsInPounds = !!weight && weight.unit === 'lb';
  if (!allInches || !weightIsInPounds) { return null; }

  const lengthInches = Number(length?.value);
  const widthInches = Number(width?.value);
  const heightInches = Number(height?.value);
  const weightPounds = Number(weight?.value);

  const allDimensionsEntered = [lengthInches, widthInches, heightInches, weightPounds].every((v) => v > 0);

  if (!allDimensionsEntered) { return null; }

  const uspsResponse = carrierCalculatedUspsPackageValidator(packageEntry);

  // USPS is the prefered option for smaller packages
  if (!uspsResponse.errorTranslationKey) {
    return uspsResponse;
  }

  // If the package can't fit in USPS, then check to see if it is valid for UPS
  return carrierCalculatedUpsPackageValidator(packageEntry);
}
