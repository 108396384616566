import { IUser } from '@reverbdotcom/commons/src/components/user_context_provider';
import { isEU } from '../lib/geo';
import { Paths } from './shared/url_helpers';

export function dealsAndStealsPromoURLPath({ countryCode }: Partial<IUser>) {
  if (['GB', 'IE'].includes(countryCode)) {
    return Paths.promoDealsAndStealsUK.expand({});
  }
  if (isEU(countryCode)) {
    return Paths.promoDealsAndStealsEU.expand({});
  }

  return Paths.promoDealsAndStealsDefault.expand({});
}

export function theWarehousePromoURLPath({ countryCode }: Partial<IUser>) {
  if (['GB', 'IE'].includes(countryCode)) {
    return Paths.promoTheWarehouseUK.expand({});
  }
  if (isEU(countryCode)) {
    return Paths.promoTheWarehouseEU.expand({});
  }

  return Paths.promoTheWarehouseDefault.expand({});
}
