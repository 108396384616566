import React from 'react';
import I18n from 'i18n-js';

import {
  RCSiteWrapper,
  RCButton,
  RCAlertBox,
  RCIconButton,
  RCLoadingBars,
  RCTextWithIcon,
  RCToast,
} from '@reverbdotcom/cadence/components';
import { TrashIcon, PencilIcon, PlusIcon, InfoCircleIcon } from '@reverbdotcom/cadence/icons/react';
import ModalConfirmation from '@reverbdotcom/commons/src/components/modal_confirmation';
import { gql } from '@reverbdotcom/commons/src/gql';
import { useQuery } from '@reverbdotcom/commons/src/useQuery';
import { useMutation } from '@reverbdotcom/commons/src/useMutation';
import {
  Core_SecondaryUsers_DeleteUserShopPermissionMutation,
  Core_SecondaryUsers_DeleteUserShopPermissionMutationVariables,
  core_apimessages_UserShopPermission,
  core_apimessages_UserShopPermission_PermissionLevel,
} from '@reverbdotcom/commons/src/gql/graphql';
import PaginationControls from '@reverbdotcom/commons/src/components/pagination_controls';
import { I18N as Translate } from '@reverbdotcom/commons/src/components/translate';

import { getOffsetFromPage } from '../pagination_helpers';
import ShopSettingsUserManagementActivityExport from './shop_settings_user_management_activity_export';
import ShopSettingsUserManagementCreateModal from './shop_settings_user_management_create_modal';
import ShopSettingsUserManagementUpdateModal from './shop_settings_user_management_update_modal';

const PAGE_LENGTH = 10;

interface Props {
  activityDataAvailable: boolean;
  ownerEmail: string;
}

export interface PermissionFormState {
  permissionLevel: core_apimessages_UserShopPermission_PermissionLevel;
  email: string;
}

function permissionFormReducer(state, action): PermissionFormState {
  switch (action.type) {
    case 'setPermissionLevel':
      return { ...state, permissionLevel: action.value };
    case 'setEmail':
      return { ...state, email: action.value };
    default:
      return { ...state };
  }
}

const emptyFormState = {
  permissionLevel: core_apimessages_UserShopPermission_PermissionLevel.MANAGER,
  email: '',
};

export const mySecondaryUsersQuery = gql(`
  query Core_SecondaryUsers_MySecondaryUsers {
    me {
      _id
      name
      shop {
        _id
        userShopPermissions {
          _id
          id
          email
          permissionLevel
          user {
            _id
            id
            name
          }
        }
      }
    }
  }
`);

export const deleteUserShopPermissionMutation = gql(`
  mutation Core_SecondaryUsers_DeleteUserShopPermission(
    $input: Input_core_apimessages_DeleteUserShopPermissionRequest
  ) {
    deleteUserShopPermission(input: $input) {
      id
    }
  }
`);

export function permissionLevelToLabel(permissionLevel: core_apimessages_UserShopPermission_PermissionLevel) {
  if (permissionLevel == core_apimessages_UserShopPermission_PermissionLevel.ADMINISTRATOR) {
    return I18n.t('discovery.shopSettings.userManagement.userAccess.permissionLevels.administrators.label');
  }
  return I18n.t('discovery.shopSettings.userManagement.userAccess.permissionLevels.managers.label');
}

function permissionLevelToNumber(permissionLevel: core_apimessages_UserShopPermission_PermissionLevel) {
  if (permissionLevel == core_apimessages_UserShopPermission_PermissionLevel.ADMINISTRATOR) {
    return 1;
  }
  return 0;
}

function comparePermissionLevels(permissionOne: core_apimessages_UserShopPermission, permissionTwo: core_apimessages_UserShopPermission) {
  if (permissionOne.permissionLevel == permissionTwo.permissionLevel) {
    return permissionOne.email.localeCompare(permissionTwo.email);
  } else if (permissionLevelToNumber(permissionOne.permissionLevel) > permissionLevelToNumber(permissionTwo.permissionLevel)) {
    return -1;
  }
  return 1;
}

function UserManagementInfoAlertBox() {
  return (
    <div className="mb-4">
      <RCAlertBox type="info">
        <div className="d-flex fx-justify-between mobile-fx-dir-col">
          <div className="width-30 mobile-width-100 mobile-mb-6">
            <h4 className="weight-bold">{I18n.t('discovery.shopSettings.userManagement.userAccess.permissionLevels.shopOwner.label')}</h4>

            <p className="mt-2">{I18n.t('discovery.shopSettings.userManagement.userAccess.permissionLevels.shopOwner.description')}</p>
          </div>

          <div className="width-30 mobile-width-100 mobile-mb-6">
            <h4 className="weight-bold">{I18n.t('discovery.shopSettings.userManagement.userAccess.permissionLevels.administrators.labelUsers')}</h4>

            <ul className="list-type-disc ml-2 mt-2">
              <li>{I18n.t('discovery.shopSettings.userManagement.userAccess.form.accessLevel.manageShopPolicies')}</li>
              <li>{I18n.t('discovery.shopSettings.userManagement.userAccess.form.accessLevel.manageListings')}</li>
            </ul>
          </div>

          <div className="width-30 mobile-width-100">
            <h4 className="weight-bold">{I18n.t('discovery.shopSettings.userManagement.userAccess.permissionLevels.managers.labelUsers')}</h4>

            <ul className="list-type-disc ml-2 mt-2">
              <li>{I18n.t('discovery.shopSettings.userManagement.userAccess.form.accessLevel.manageListings')}</li>
            </ul>
          </div>
        </div>
      </RCAlertBox>
    </div>
  );
}

function UserManagementTable({ me, ownerEmail, permissions, deleteUserShopPermission, deleteErrors, loading }) {
  const [createModalOpen, setCreateModalOpen] = React.useState(false);
  const [updateModalOpen, setUpdateModalOpen] = React.useState(false);
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = React.useState(false);
  const [deleteToastOpen, setDeleteToastOpen] = React.useState(false);
  const [deleteToastText, setDeleteToastText] = React.useState('');
  const [deleteSuccessful, setDeleteSuccessful] = React.useState(false);
  const [selectedPermission, setSelectedPermission] = React.useState(null);
  const [currentPage, setCurrentPage] = React.useState(1);

  const [formState, dispatch] = React.useReducer(permissionFormReducer, emptyFormState);

  React.useEffect(() => {
    if ((deleteErrors || []).length > 0 && !deleteToastOpen) {
      setDeleteToastOpen(true);
    }
  }, [deleteErrors]);

  if (loading) {
    return (
      <RCLoadingBars />
    );
  }

  const sortedPermissions = [...permissions].sort((a, b) => comparePermissionLevels(a, b));
  const allPermissions = [
    {
      id: '0',
      permissionLevel: I18n.t('discovery.shopSettings.userManagement.userAccess.permissionLevels.shopOwner.label'),
      email: ownerEmail,
      user: { name: me?.name },
    },
    ...sortedPermissions,
  ];
  const offset = getOffsetFromPage(currentPage.toString(), PAGE_LENGTH);
  const total = allPermissions.length;

  return (
    <>
      <div className="shop_settings-user-management__table">
        <div className="styled-table mb-6">
          <table>
            <thead>
              <tr>
                <th>{I18n.t('discovery.shopSettings.userManagement.userAccess.columnHeaders.level')}</th>
                <th>{I18n.t('discovery.shopSettings.userManagement.userAccess.columnHeaders.email')}</th>
                <th>{I18n.t('discovery.shopSettings.userManagement.userAccess.columnHeaders.name')}</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {allPermissions.slice(offset, offset + PAGE_LENGTH).map((permission) => {
                return (
                  <tr key={permission.id}>
                    <td>{permission.id !== '0' ? permissionLevelToLabel(permission.permissionLevel) : permission.permissionLevel}</td>
                    <td>{permission.email}</td>
                    <td>{permission.user.name}</td>
                    <td>
                      <div className="d-flex fx-align-center fx-justify-end">
                        {permission.id !== '0' && (
                          <div className="mr-4">
                            <RCIconButton
                              onClick={() => {
                                setSelectedPermission(permission);
                                dispatch({ type: 'setPermissionLevel', value: permission.permissionLevel });
                                setUpdateModalOpen(true);
                              }}
                              label={I18n.t('discovery.shopSettings.userManagement.userAccess.update.buttonTitle')}
                              svgComponent={PencilIcon}
                              variant="muted"
                            />
                          </div>
                        )}
                        <div className={permission.id === '0' ? 'shop_settings-user-management__table__hidden-button' : ''}>
                          <RCIconButton
                            onClick={() => {
                              setSelectedPermission(permission);
                              setDeleteConfirmationOpen(true);
                            }}
                            label={I18n.t('discovery.shopSettings.userManagement.userAccess.delete.buttonTitle')}
                            svgComponent={TrashIcon}
                            variant="muted"
                            disabled={permission.id === '0'}
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>

      {total == 1 && (
        <div className="mb-4">
          <RCTextWithIcon
            svgComponent={InfoCircleIcon}
            title={I18n.t('discovery.shopSettings.userManagement.userAccess.noUsers')}
            placement="left"
          >
            {I18n.t('discovery.shopSettings.userManagement.userAccess.noUsers')}
          </RCTextWithIcon>
        </div>
      )}

      <div className="shop-settings-user-management__table__footer">
        <RCButton
          onClick={async () => {
            dispatch({ type: 'setPermissionLevel', value: emptyFormState.permissionLevel });
            dispatch({ type: 'setEmail', value: '' });
            setCreateModalOpen(true);
          }}
        >
          <RCTextWithIcon
            svgComponent={PlusIcon}
            title={I18n.t('discovery.shopSettings.userManagement.userAccess.create.buttonTitle')}
          >
            {I18n.t('discovery.shopSettings.userManagement.userAccess.create.buttonTitle')}
          </RCTextWithIcon>
        </RCButton>

        <PaginationControls
          limit={PAGE_LENGTH}
          offset={offset}
          total={total}
          onPreviousClick={() => { setCurrentPage(currentPage - 1); }}
          onNextClick={() => { setCurrentPage(currentPage + 1); }}
          onPageNumberClick={(pageNum) => { setCurrentPage(pageNum); }}
        />
      </div>

      <ShopSettingsUserManagementCreateModal
        isOpen={createModalOpen}
        setIsOpen={setCreateModalOpen}
        formState={formState}
        dispatch={dispatch}
      />

      <ShopSettingsUserManagementUpdateModal
        isOpen={updateModalOpen}
        onClose={() => {
          setSelectedPermission(null);
          setUpdateModalOpen(false);
        }}
        selectedPermission={selectedPermission}
        formState={formState}
        dispatch={dispatch}
      />

      <ModalConfirmation
        title={I18n.t('discovery.shopSettings.userManagement.userAccess.delete.modalTitle')}
        content={<Translate
          html
          text="discovery.shopSettings.userManagement.userAccess.delete.modalContent"
          args={{ email: selectedPermission?.email }}
        />}
        isOpen={deleteConfirmationOpen}
        onConfirm={async () => {
          const result = await deleteUserShopPermission({
            variables: {
              input: {
                userId: selectedPermission.user.id.toString(),
              },
            },
            refetchQueries: ['Core_SecondaryUsers_MySecondaryUsers'],
            awaitRefetchQueries: true,
          });

          if (result.data?.deleteUserShopPermission?.id) {
            setDeleteToastText(I18n.t('discovery.shopSettings.userManagement.userAccess.delete.success', { email: selectedPermission.email }));
            setDeleteSuccessful(true);
          }

          setSelectedPermission(null);
          setDeleteConfirmationOpen(false);
          setDeleteToastOpen(true);
        }}
        onRequestClose={() => {
          setSelectedPermission(null);
          setDeleteConfirmationOpen(false);
        }}
        confirmButtonText={I18n.t('discovery.shopSettings.userManagement.userAccess.delete.modalTitle')}
      />

      <RCToast
        isOpen={deleteToastOpen}
        onClose={() => { setDeleteToastOpen(false); }}
        text={deleteSuccessful ? deleteToastText : I18n.t('discovery.shopSettings.userManagement.userAccess.delete.error')}
        theme={deleteSuccessful ? 'success' : 'error'}
      />
    </>
  );
}

export function ShopSettingsUserManagement(props: Props) {
  const { data, loading } = useQuery(mySecondaryUsersQuery, {
    fetchPolicy: 'network-only',
  });

  const [deleteUserShopPermission, { loading: deleteLoading, errors: deleteErrors }] = useMutation<Core_SecondaryUsers_DeleteUserShopPermissionMutation, Core_SecondaryUsers_DeleteUserShopPermissionMutationVariables>(
    deleteUserShopPermissionMutation,
  );

  const anythingLoading = loading || deleteLoading;
  const permissions = data?.me.shop.userShopPermissions;

  return (
    <RCSiteWrapper>
      <div className="width-60 tablet-width-100 mobile-width-100">
        <h1 className="weight-bold size-180 mt-0 mb-4">
          {I18n.t('discovery.shopSettings.userManagement.title')}
        </h1>

        <p className="mb-8">
          {I18n.t('discovery.shopSettings.userManagement.description')}
        </p>

        <ShopSettingsUserManagementActivityExport
          activityDataAvailable={props.activityDataAvailable}
        />

        <h4 className="weight-bold size-140 mt-8 mb-4">
          {I18n.t('discovery.shopSettings.userManagement.userAccess.title')}
        </h4>

        <UserManagementInfoAlertBox/>

        <p className="mt-6 mb-8">
          {I18n.t('discovery.shopSettings.userManagement.userAccess.description')}
        </p>

        <UserManagementTable
          me={data?.me}
          ownerEmail={props.ownerEmail}
          permissions={permissions}
          deleteUserShopPermission={deleteUserShopPermission}
          deleteErrors={deleteErrors}
          loading={anythingLoading}
        />
      </div>
    </RCSiteWrapper>
  );
}

export default ShopSettingsUserManagement;
