import alt from '../../alt';

class CurationActions {
  fetchIndex(params) { return params; }

  setIndex(response) { return response; }

  setIndexError() { return true; }

  stopCurating() { return true; }

  curate(curatedSetId) { return curatedSetId; }

  curateSuccess() { return true; }

  curateError() { return true; }

  decurate(curatedSetId) { return curatedSetId; }

  decurateSuccess() { return true; }

  decurateError() { return true; }
}

export default alt.createActions(CurationActions);
