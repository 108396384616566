import React from 'react';
import I18n from 'i18n-js';

import { PriceDisplay } from '@reverbdotcom/commons';
import PriceDisplayRange from '@reverbdotcom/commons/src/components/price_display_range';
import { reverb_pricing_PriceRecommendation } from '@reverbdotcom/commons/src/gql/graphql';

import SmallPriceGuideGraphIcon from '../../../../images/price-guide-tool/small-colored-price-guide-graph.svg';
import { useViewTracking } from '@reverbdotcom/commons/src/use_tracking';
import { MParticleEventName } from '@reverbdotcom/commons/src/elog/mparticle_types';
import { parseAmountCents } from '@reverbdotcom/commons/src/money';
import classNames from 'classnames';
import { useExpEnabled } from '@reverbdotcom/commons/src/user_hooks';
import experiments from '@reverbdotcom/commons/src/experiments';

const MINIMUM_PRICE_LOW = 0;
export const COMPONENT_NAME = 'QuickPriceEditPriceRecommendation';
interface IExternalProps {
  listingId: string;
  priceRecommendation?: reverb_pricing_PriceRecommendation;
}

export default function QuickPriceEditPriceRecommendation({
  listingId,
  priceRecommendation,
}: IExternalProps) {
  const isV2QuickPriceEdit = useExpEnabled(experiments.LISTINGS_MANAGEMENT_QUICK_PRICE_EDIT_UPDATES);
  const { priceLow, priceMiddle, priceHigh } = priceRecommendation ?? {};
  const hasDataServicesPriceEstimate = !!priceLow && !!priceMiddle && !!priceHigh;

  useViewTracking({
    eventName: MParticleEventName.ComponentView,
    componentName: COMPONENT_NAME,
    listingId: listingId,
  }, hasDataServicesPriceEstimate);

  if (!hasDataServicesPriceEstimate) return null;

  const showEstimatedPriceRange = priceLow.amountCents > MINIMUM_PRICE_LOW;

  return (
    <div className="quick-price-edit-price-recommendation">
      <div className="quick-price-edit-price-recommendation__price-guide">
        <img
          src={SmallPriceGuideGraphIcon}
          alt={I18n.t('discovery.sellForm.pricingSection.pricingGuidance.graphIcon')}
        />

        <div className="quick-price-edit-price-recommendation__price-guide__competitive-price">
          <div>
            {I18n.t('discovery.sellForm.pricingSection.pricingGuidance.competitivePrice')}
          </div>

          <PriceDisplay
            amountCents={priceMiddle.amountCents}
            display={parseAmountCents(
              priceMiddle.amountCents,
              priceMiddle.currency,
              { precision: 0 },
            ).display}
          />
        </div>
      </div>

      {showEstimatedPriceRange && (
        <div className={classNames('quick-price-edit-price-recommendation__estimated-range', {
          'quick-price-edit-price-recommendation__estimated-range--semibold': !isV2QuickPriceEdit,
        })}>
          <div>
            {I18n.t('discovery.listingsManagement.quickPriceEdit.modal.priceRecommendation.estimatedPriceRange')}
          </div>

          <PriceDisplayRange
            moneyLow={priceLow}
            moneyHigh={priceHigh}
            displayConfig={{ precision: 0 }}
          />
        </div>
      )}
    </div>
  );
}
