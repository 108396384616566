import React from 'react';
import classNames from 'classnames';

import CoreLink from '../../components/core_link';
import MarkdownOutput from './markdown_output';

import { imageUploadURL } from '../../url_helpers';
import { buttonClassName } from '@reverbdotcom/cadence/components';

export interface IProps {
  heading?: string;
  headingHtml?: string;
  subheading?: string;
  subheadingHtml?: string;
  ctaText: string;
  ctaTargetHref: string;
  backgroundColor: string;
  backgroundImageKey?: string;
  jumplinkSlug?: string;
  cdn?: string;
}

export default class CallToAction extends React.Component<IProps, null> {
  imageURI() {
    return imageUploadURL(this.props.backgroundImageKey, this.props.cdn);
  }

  getBackgroundImageStyle() {
    if (!this.props.backgroundImageKey) return null;

    return {
      style: { backgroundImage: `url('${this.imageURI()}')` },
    };
  }

  render() {
    const classes = classNames(
      'call-to-action',
      { 'call-to-action--white': this.props.backgroundColor === 'white' },
      { 'call-to-action--offwhite': this.props.backgroundColor === 'offwhite' },
      { 'call-to-action--blue': this.props.backgroundColor === 'blue' },
      { 'call-to-action--blue-lp': this.props.backgroundColor === 'blue-lp' },
      { 'call-to-action--orange': this.props.backgroundColor === 'orange' },
    );

    const buttonClasses = buttonClassName({
      variant: 'filled',
      inverted: this.props.backgroundColor === 'orange' || this.props.backgroundColor === 'blue',
    });

    return (
      <div
        className={classes}
        id={this.props.jumplinkSlug}
        {... this.getBackgroundImageStyle()}
      >
        <div className="call-to-action__inner">
          <MarkdownOutput
            htmlTag="h3"
            className="call-to-action__heading"
            text={this.props.heading}
            textHtml={this.props.headingHtml}
            inlineTagsOnly
            blocklistedTags={['a']}
          />
          <MarkdownOutput
            htmlTag="h4"
            className="call-to-action_subheading"
            text={this.props.subheading}
            textHtml={this.props.subheadingHtml}
            inlineTagsOnly
            blocklistedTags={['a']}
          />
          <div className="call-to-action__link-container">
            <CoreLink
              className={buttonClasses}
              to={this.props.ctaTargetHref}
            >
              {this.props.ctaText}
            </CoreLink>
          </div>
        </div>
      </div>
    );
  }
}
