// TODO update this to import from commons
// import { gql } from '@reverbdotcom/commons/src/gql';
// eslint-disable-next-line no-restricted-imports
import { gql } from '@apollo/client';
import React from 'react';
import I18n from 'i18n-js';
import bind from '@reverbdotcom/commons/src/bind';
import { MutationFunction } from '@reverbdotcom/commons/src/useHOCMutation';
import { withGraphql } from '@reverbdotcom/commons/src/with_graphql';
import SanitizedRender from '@reverbdotcom/commons/src/components/sanitized_render';
import { UpdateFeedback } from '@reverbdotcom/commons/src/gql/graphql';
import FormGroupWithInput from '@reverbdotcom/commons/src/components/form_group_with_input';
import { RCStarRatingSelector, RCAlertBox } from '@reverbdotcom/cadence/components';
import { parseMutationErrors } from '@reverbdotcom/commons/src/parse_graphql_errors';
import URLHelpers from '../shared/url_helpers';
import toBool from '../../reverb-js-common/to_boolean';
import { IUserContext, withUserContext } from '@reverbdotcom/commons/src/components/user_context_provider';
import { translateElement } from '@reverbdotcom/commons/src/html_translation';
import { connect } from '@reverbdotcom/commons/src/connect';

const updateFeedbackGQL = gql`
  mutation UpdateFeedback($input: Input_core_apimessages_UpdateFeedbackRequest) {
    updateFeedbackRequest(input: $input) {
      feedback {
        rating
        shippingSatisfactory {
          value
        }
        conditionSatisfactory {
          value
        }
        message
      }
    }
  }
`;

const updateFeedbackMutation = withGraphql<
  {},
  UpdateFeedback.Mutation,
  UpdateFeedback.Variables
>(updateFeedbackGQL, {
  name: 'updateFeedbackMutation',
});

interface MutationProps {
  updateFeedbackMutation: MutationFunction<UpdateFeedback.Mutation, UpdateFeedback.Variables>;
}

interface ExternalProps {
  orderUuid: string;
  legacyOrderId: string;
  onSuccess: Function;
  onError: Function;
  isBuyer: boolean;
}

type IProps = MutationProps & IUserContext & ExternalProps;

interface IState {
  rating: number;
  message: string;
  shippingSatisfactory: boolean;
  conditionSatisfactory: boolean;
  submitDisabled: boolean;
}

export class FeedbackForm extends React.Component<IProps, IState> {
  state = {
    message: '',
    rating: null,
    shippingSatisfactory: null,
    conditionSatisfactory: null,
    submitDisabled: false,
  };

  shippingSatisfactoryValue() {
    if (this.state.shippingSatisfactory === null) { return null; }
    return {
      value: this.state.shippingSatisfactory,
    };
  }

  conditionSatisfactoryValue() {
    if (this.state.conditionSatisfactory === null) { return null; }
    return {
      value: this.state.conditionSatisfactory,
    };
  }

  @bind
  createFeedback() {
    this.setState({ submitDisabled: true });

    return this.props.updateFeedbackMutation({
      variables: {
        input: {
          orderUuid: this.props.orderUuid,
          rating: this.state.rating,
          message: this.state.message,
          shippingSatisfactory: this.shippingSatisfactoryValue(),
          conditionSatisfactory: this.conditionSatisfactoryValue(),
        },
      },
    }).then(this.onSuccess, this.onError);
  }

  @bind
  onSuccess() {
    this.props.onSuccess();
  }

  @bind
  onError(errorResponse) {
    this.setState({ submitDisabled: false });
    this.props.onError(
      parseMutationErrors(errorResponse),
    );
  }

  @bind
  onValueChange(obj) {
    this.setState(obj);
  }

  @bind
  starRatingSelected(starsSelected) {
    this.setState({ rating: starsSelected });
  }

  @bind
  updateShipping(obj) {
    this.setState({ shippingSatisfactory: toBool(obj.shippingSatisfactory) });
  }

  @bind
  updateCondition(obj) {
    this.setState({ conditionSatisfactory: toBool(obj.conditionSatisfactory) });
  }

  @bind
  formValid() {
    if (this.state.rating === null) { return false; }

    return true;
  }

  showStartAConversation() {
    return this.state.rating && this.state.rating > 0 && this.state.rating < 4;
  }

  buyerSuggestion() {
    const msgUrl = URLHelpers.purchasePath({
      orderId: this.props.legacyOrderId,
    });
    const refundUrl = URLHelpers.purchasePath({
      orderId: this.props.legacyOrderId,
      actions_expanded: true,
    });

    return (
      <div>
        <SanitizedRender
          html={translateElement('b', 'discovery.feedbackForm.problemsWithYourOrder')}
        />
        <br />
        <SanitizedRender
          html={
            I18n.t('discovery.feedbackForm.buyerSuggestion', {
              msgLinkOpen: `<a href="${msgUrl}">`,
              msgLinkClose: '</a>',
              refundLinkOpen: `<a href="${refundUrl}">`,
              refundLinkClose: '</a>',
            })
          }
        />
      </div>
    );
  }

  sellerSuggestion() {
    const url = URLHelpers.orderPath({
      orderId: this.props.legacyOrderId,
    });

    return (
      <div>
        <SanitizedRender
          html={translateElement('b', 'discovery.feedbackForm.problemsWithYourOrder')}
        />
        <br />
        <SanitizedRender
          html={
            I18n.t('discovery.feedbackForm.sellerSuggestion', {
              linkOpen: `<a href="${url}">`,
              linkClose: '</a>',
            })
          }
        />
      </div>
    );
  }

  renderStartAConversation() {
    return (
      <div className="mb-2" data-start-conversation>
        <RCAlertBox type="info" small>
          <div className="mb-0">
            {this.props.isBuyer && this.buyerSuggestion()}
            {!this.props.isBuyer && this.sellerSuggestion()}
          </div>
        </RCAlertBox>
      </div>
    );
  }

  render() {
    return (
      <div>
        <RCStarRatingSelector
          label={I18n.t('discovery.feedbackForm.rateTransaction')}
          onSelect={this.starRatingSelected}
          rating={this.state.rating}
          inputName={`feedback-star-rating-${this.props.orderUuid}`}
          inputId={`feedback-star-rating-${this.props.orderUuid}`}
        />
        {this.showStartAConversation() && this.renderStartAConversation()}
        <FormGroupWithInput
          inputName="message"
          id={`message-${this.props.orderUuid}`}
          inputType="textarea"
          label={I18n.t('discovery.feedbackForm.feedbackLabel')}
          updateField={this.onValueChange}
          value={this.state.message}
          maxLength={500}
          displayCharacterCount
          helpText={
            this.state.message ?
              I18n.t('discovery.feedbackForm.feedbackPublicHint') : ' '
          }
        />
        <button
          className="button"
          onClick={this.createFeedback}
          disabled={this.state.submitDisabled || !this.formValid()}
        >
          {I18n.t('discovery.feedbackForm.sendFeedback')}
        </button>
      </div>
    );
  }
}

export default connect<ExternalProps>([
  updateFeedbackMutation,
])(withUserContext(FeedbackForm));
