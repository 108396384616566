/* eslint-disable no-restricted-imports */
import {
  useLazyQuery as useApolloLazyQuery,
  LazyQueryResult,
  LazyQueryHookOptions,
  DocumentNode,
  OperationVariables,
  TypedDocumentNode,
  LazyQueryExecFunction,
} from '@apollo/client';
import { core_apimessages_Error } from './gql/graphql';
import { omit } from 'lodash';
import { parseQueryErrors } from './parse_graphql_errors';
/* eslint-enable no-restricted-imports */

export type RQLLazyQueryHookOptions<TData = any, TVariables = OperationVariables> = Omit<LazyQueryHookOptions<TData, TVariables>, 'skip' | 'ssr'>;

type RQLLazyQueryResult<TData, TVariables> = Omit<LazyQueryResult<TData, TVariables>, 'error'> & {
  /** Formatted errors returned via RQL */
  errors?: core_apimessages_Error[];
};

type RQLLazyQueryResultTuple<TData, TVariables extends OperationVariables> = [
  LazyQueryExecFunction<TData, TVariables>,
  RQLLazyQueryResult<TData, TVariables>,
];

/**
 * Ensures that both data and error.graphQLErrors are populated,
 * enabling the mutation to retrieve both partial results and error information.
 */
const DEFAULT_ERROR_POLICY = 'all' as const;

/**
 * Wraps the [Apollo useLazyQuery](https://www.apollographql.com/docs/react/api/react/hooks/#uselazyquery) hook.
 */
export function useLazyQuery<
  TData = any,
  TVariables extends OperationVariables = OperationVariables,
>(
  query: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options?: RQLLazyQueryHookOptions<TData, TVariables>,
): RQLLazyQueryResultTuple<TData, TVariables> {
  const mergedOptions = {
    errorPolicy: DEFAULT_ERROR_POLICY,
    ...options,
  };

  const [execute, result] = useApolloLazyQuery<TData, TVariables>(query, mergedOptions);

  const decoratedResult: RQLLazyQueryResult<TData, TVariables> = {
    ...omit(result, 'error'),
    errors: result.error && parseQueryErrors(result.error),
  };

  return [execute, decoratedResult];
}
