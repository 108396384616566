import React from 'react';

import { IListingEntryState } from './sellFormReducerTypes';
import { CompletionStatus, ISellFormViewData } from './SellFormView';
import {
  Input_core_apimessages_Condition as ICondition,
  core_apimessages_ShopConfig_OfferPolicy as IOfferPolicy,
  core_apimessages_ReturnPolicy as IReturnPolicy,
  core_apimessages_ShopTaxPolicy as ITaxPolicy,
  core_apimessages_ShippingRegionSetting as IRegionSetting,
  core_apimessages_ShippingRates as IShippingRate,
} from '@reverbdotcom/commons/src/gql/graphql';

// In order to build our IShopConfig object, these are RQL data fields that get unpacked from either:
// - Core_SellForm_NewListingLayout (creating new listing)
// - HydrateSellFormFragment (editing persisted listing)
export const SHOP_CONFIG_FIELDS = [
  'currencySetting',
  'defaultLocale',
  'shopSupportedLocaleCodes',
  'preferredSeller',
  'hasAccountRep',
  'listableConditions',
  'offerPolicy',
  'returnPolicy',
  'taxPolicies',
  'inclusiveVatPricingEnabled',
  'sameDayShippingConfigured',
  'shippingRegionSettings',
  'firstTimeSeller',
  'address',
];
// When building IShopConfig, we also manually build up a
// `profileRates` attribute, to account for the fact that
// me->shop->shippingProfiles and listing->shop->shippingProfiles
// RQL queries return slightly different types.
export const SHIPPING_PROFILE_CONFIG_FIELDS = [
  'id',
  'name',
  'localPickup',
  'categoryUuids',
];

export interface IShopConfig {
  currencySetting: string;
  defaultLocale: string;
  shopSupportedLocaleCodes: string[];
  preferredSeller: boolean;
  hasAccountRep: boolean;
  listableConditions: ICondition[];
  offerPolicy: IOfferPolicy;
  returnPolicy: IReturnPolicy;
  taxPolicies: ITaxPolicy[];
  vatId: string;
  inclusiveVatPricingEnabled: boolean;
  sameDayShippingConfigured: boolean,
  shippingRegionSettings: IRegionSetting[];
  firstTimeSeller: boolean;
  address: { region: string; postalCode: string; countryCode: string; };
  shippingProfiles: {
    id: string;
    name: string;
    localPickup: boolean;
    categoryUuids: string[];
    profileRates: IShippingRate[];
  }[];
}

export interface ISellFormContext {
  dirty: boolean;
  listingEntry: IListingEntryState;
  dispatch: React.Dispatch<any>;
  sellFormViewData: ISellFormViewData;
  shopConfig: IShopConfig;
  loading: boolean;
  completionStatusMap: { string?: CompletionStatus };
  errorMap: Record<string, string>;
  setIsSubmitting: React.Dispatch<React.SetStateAction<boolean>>;
  listingState: string;
  listingCreateFlow: boolean;
  showBumpModal: boolean;
  setShowBumpModal: React.Dispatch<React.SetStateAction<boolean>>;
  showRequiredFieldError: boolean;
  setShowRequiredFieldError: React.Dispatch<React.SetStateAction<boolean>>;
  showSellingFeeWaived: boolean;
  setShowSellingFeeWaived: React.Dispatch<React.SetStateAction<boolean>>;
  hideUnknownBrandWarning: boolean;
  setHideUnknownBrandWarning: React.Dispatch<React.SetStateAction<boolean>>;
  singlePageActive: boolean;
  areTraitsRequired: boolean;
  showTraitsSelector: boolean;
  activateSinglePage: () => void;
  deactivateSinglePage: () => void;
  currentCanonicalProductId: string;
}

export const SellFormContext = React.createContext<ISellFormContext>({
  dirty: false,
  listingEntry: null,
  dispatch: () => {},
  sellFormViewData: undefined,
  shopConfig: undefined,
  loading: false,
  completionStatusMap: {},
  errorMap: {},
  setIsSubmitting: () => {},
  listingState: 'draft',
  listingCreateFlow: true,
  showBumpModal: false,
  setShowBumpModal: () => {},
  showRequiredFieldError: false,
  setShowRequiredFieldError: () => {},
  showSellingFeeWaived: false,
  setShowSellingFeeWaived: () => {},
  hideUnknownBrandWarning: false,
  setHideUnknownBrandWarning: () => {},
  singlePageActive: false,
  areTraitsRequired: false,
  showTraitsSelector: false,
  activateSinglePage: () => {},
  deactivateSinglePage: () => {},
  currentCanonicalProductId: '',
});
