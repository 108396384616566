// TODO update this to import from commons
// import { gql } from '@reverbdotcom/commons/src/gql';
// eslint-disable-next-line no-restricted-imports
import { gql } from '@apollo/client';
import { RCAutocomplete, RCFormGroup } from '@reverbdotcom/cadence/components';
import I18n from 'i18n-js';
import { RCAutocompleteItem } from '@reverbdotcom/cadence/components/RCAutocomplete/RCAutocompleteItem';
import React from 'react';
import { useLazyQuery } from '@reverbdotcom/commons/src/useLazyQuery';
import {
  CoreAddressAutocompleteFindAutocompleteSuggestions,
  CoreAddressAutocompleteSelectAutocompleteSuggestion,
  core_apimessages_AddressEntry as AddressEntry,
  core_apimessages_FindAddressAutocompleteSuggestionsResponse_AutocompleteSuggestion as AutocompleteSuggestion,
  core_apimessages_FindAddressAutocompleteSuggestionsResponse_SuggestionType as AutocompleteSuggestionType,
} from '@reverbdotcom/commons/src/gql/graphql';
import { mapAddressToEntry } from '../lib/addressEntryMapping';
import { useMutation } from '@reverbdotcom/commons/src/useMutation';

interface Props {
  onAddressSelect(address: AddressEntry): void;
  onChooseManualEntry(): void;
  required?: boolean;
}

export function AddressAutocomplete(props: Props) {
  const [isOpen, setOpen] = React.useState<boolean>(false);
  const [inputValue, setInputValue] = React.useState('');

  const [selectSuggestion] = useMutation<
    CoreAddressAutocompleteSelectAutocompleteSuggestion.Mutation,
    CoreAddressAutocompleteSelectAutocompleteSuggestion.Variables
  >(
    SELECT_SUGGESTION_MUTATION,
  );

  const [findSuggestions, suggestionsResult] = useLazyQuery<
    CoreAddressAutocompleteFindAutocompleteSuggestions.Query,
    CoreAddressAutocompleteFindAutocompleteSuggestions.Variables
  >(
    FIND_SUGGESTIONS_QUERY,
  );

  const showLoading = suggestionsResult.called && suggestionsResult.loading;
  const suggestions = suggestionsResult.data?.findAddressAutocompleteSuggestions?.suggestions || [];
  const showManualEntry = inputValue.length > 2 || suggestions.length > 1;

  function onQueryChange(value: string) {
    setInputValue(value);
    findSuggestions({ variables: { query: value } });
  }

  async function onSuggestionSelect(suggestion: AutocompleteSuggestion) {
    if (suggestion.type === AutocompleteSuggestionType.CONTAINER) {
      findSuggestions({ variables: { containerId: suggestion.id } });
    } else if (suggestion.type === AutocompleteSuggestionType.ADDRESS) {
      const result = await selectSuggestion({ variables: { id: suggestion.id } });
      const address = mapAddressToEntry(result.data?.selectAddressAutocompleteSuggestion?.suggestion);

      if (address) {
        setOpen(false);
        props.onAddressSelect(address);
      }
    }
  }

  return (
    <RCFormGroup
      inputId="address-autocomplete-input"
      label={I18n.t('discovery.addressAutocomplete.label')}
    >
      <RCAutocomplete
        inputValue={inputValue}
        inputId="address-autocomplete-input"
        onInputChange={onQueryChange}
        loading={showLoading}
        isOpen={isOpen}
        onOpenChange={setOpen}
        placeholder={I18n.t('discovery.addressAutocomplete.placeholder')}
        required={props.required}
      >
        {inputValue.length !== 0 &&
          <RCAutocompleteItem
            key="keep-typing"
            id="keep-typing"
            title={I18n.t('discovery.addressAutocomplete.continueTyping')}
            variant="disabled"
          />
        }
        {suggestions.length > 0 &&
          suggestions.map((suggestion) => (
            <RCAutocompleteItem
              key={suggestion.id}
              id={suggestion.id}
              title={suggestion.text}
              type={suggestion.type}
              onClick={() => onSuggestionSelect(suggestion)}
              variant={suggestion.type === AutocompleteSuggestionType.CONTAINER ? 'parent' : undefined}
            />
          ))
        }
        {showManualEntry &&
          <RCAutocompleteItem
            key="manual-entry"
            id="manual-entry"
            title={I18n.t('discovery.addressAutocomplete.manualEntry')}
            variant="link"
            onClick={() => props.onChooseManualEntry()}
          />
        }
      </RCAutocomplete>
    </RCFormGroup>
  );
}

export const FIND_SUGGESTIONS_QUERY = gql`
  query Core_AddressAutocomplete_FindAutocompleteSuggestions(
    $query: String,
    $containerId: String,
  ) {
    findAddressAutocompleteSuggestions(input: { text: $query, container: $containerId }) {
      suggestions {
        id
        type
        text
      }
    }
  }
`;

const SELECT_SUGGESTION_MUTATION = gql`
  mutation Core_AddressAutocomplete_SelectAutocompleteSuggestion($id: String) {
    selectAddressAutocompleteSuggestion(input: { id: $id }) {
      suggestion {
        _id
        streetAddress
        extendedAddress
        locality
        region
        postalCode
        countryCode
      }
    }
  }
`;
