import { ChildProps } from '@apollo/client/react/hoc';
// TODO update this to import from commons/src/gql
// eslint-disable-next-line no-restricted-imports
import { gql } from '@apollo/client';
import React from 'react';
import bind from '../../bind';
import { isEmpty } from 'lodash';

import { withGraphql } from '../../with_graphql';
import { CoreCmsPageCards } from '../../gql/graphql';
import { imageUploadURL, cmsPagePath } from '../../url_helpers';

import MarketingCalloutCard from './marketing_callout_card';
import OverflowingRow from '../../components/overflowing_row';
import SanitizedRender from '../../components/sanitized_render';
import CoreLink from '../../components/core_link';
import { IDynamicComponentProps } from '../dynamic_component_props';
import { IUserContext, withUserContext } from '../../components/user_context_provider';
import { RCGrid } from '@reverbdotcom/cadence/components';

const SIDEBAR_SECTION = 'sidebar';

const CARDS = 'cards';
const TEXT_ONLY = 'textOnly';
const ARTICLE = 'cms1';
export const CMS2_PAGE = 'cms2';

export const DISPLAY_AS = [
  CARDS,
  TEXT_ONLY,
];

export interface CmsPageFields {
  slug: string;
  pageSource: string;
  imageOverride?: string;
}

export interface Props extends IDynamicComponentProps {
  pageFields: CmsPageFields[];
  title?: string;
  titleHtml?: string;
  subtitleHtml?: string;
  ledeHtml?: string;
  displayAsGrid?: boolean;
  jumplinkSlug?: string;
  displayAs?: string;
  action?: React.ReactElement;
  withSidebar?: boolean;
}

type IProps = Props & IUserContext;

interface Card {
  title: string;
  slug: string;
  link: string;
  imageURL: string;
  pageType?: string;
  channels?: string[];
}

export class CmsPageCards extends React.Component<ChildProps<IProps, CoreCmsPageCards.Query>, null> {
  static defaultProps: Partial<IProps> = { pageFields: [] };

  getCards(): Card[] {
    const { data, pageFields } = this.props;

    const { articles, cmsPages } = data;

    return pageFields.reduce((cards, page) => {
      if (page.pageSource === ARTICLE) {
        const foundArticle = articles.find(article => article.slug === page.slug);

        if (foundArticle && !!foundArticle.slug) {
          cards.push({
            title: foundArticle.title,
            slug: foundArticle.slug,
            link: foundArticle.webLink?.href,
            imageURL: this.getImageNameForArticle(foundArticle),
          });
        }
      }

      if (page.pageSource === CMS2_PAGE) {
        const foundCmsPage = cmsPages.find(cmsPage => cmsPage.slug === page.slug);

        if (foundCmsPage && !foundCmsPage.pageNotFound) {
          cards.push({
            title: foundCmsPage.title,
            slug: foundCmsPage.slug,
            link: cmsPagePath(foundCmsPage),
            imageURL: this.getImageNameForCMSPage(foundCmsPage),
            pageType: foundCmsPage.pageType,
            channels: foundCmsPage.channels,
          });
        }
      }

      return cards;
    }, []);
  }

  getImageOverride(page) {
    const idx = this.props.pageFields.findIndex(p => p.slug === page.slug);
    return this.props.pageFields[idx] && this.props.pageFields[idx].imageOverride;
  }

  getImageNameForArticle(article) {
    const override = this.getImageOverride(article);
    if (override) return imageUploadURL(override);
    if (isEmpty(article.images)) return null;
    return article.images[0].source;
  }

  getImageNameForCMSPage(page) {
    const override = this.getImageOverride(page);
    if (override) return imageUploadURL(override);
    if (!page.image || !page.image.imageUrl) return null;
    return imageUploadURL(page.image.imageUrl.split('/upload/')[1]);
  }

  isInSidebar() {
    return (this.props.layoutSection === SIDEBAR_SECTION);
  }

  @bind
  renderCard(card: Card, idx) {
    if (this.props.displayAs === TEXT_ONLY) {
      const styles = `color-primary width-100 height-100 shadow-on-hover
        d-flex fx-align-center fx-justify-between size-110 bg-color-module bd-radius-md`;

      return (
        <CoreLink
          className={styles}
          to={card.link}
          key={idx}
        >
          <div className="weight-bold scaling-padding-4">
            {card.title}
          </div>
          <span className="icon-r-arrow-right scaling-padding-4 pl-0" />
        </CoreLink>
      );
    }

    return (
      <MarketingCalloutCard
        title={card.title}
        imageURL={card.imageURL}
        targetHref={card.link}
        pageType={card.pageType}
        channel={card.channels?.[0] ?? 'core'}
        key={idx}
      />
    );
  }

  renderTitle() {
    return (
      <SanitizedRender
        html={this.props.titleHtml || this.props.title}
      />
    );
  }

  render() {
    if (!this.props.data || this.props.data.loading || this.props.data.error) return null;

    const cardsToShow = this.getCards();

    if (!cardsToShow.length) return null;

    return (
      <OverflowingRow
        title={this.renderTitle()}
        subtitleHtml={this.props.subtitleHtml}
        ledeHtml={this.props.ledeHtml}
        id={this.props.jumplinkSlug}
        action={this.props.action}
      >
        <RCGrid singleRowOn={(this.props.displayAsGrid || this.isInSidebar()) ? null : 'all'}>
          {cardsToShow.map((card, idx) => (
            <RCGrid.Item
              key={card.slug}
              colSpan={{
                desktop: this.isInSidebar() ? 12 : 3,
                tablet: this.isInSidebar() ? 12 : 4,
                mobile: this.isInSidebar() ? 12 : 8,
              }}
            >
              {this.renderCard(card, idx)}
            </RCGrid.Item>
          ))}
        </RCGrid>
      </OverflowingRow>
    );
  }
}

const connect = withGraphql<IProps, CoreCmsPageCards.Query>(
  gql`
    query Core_Cms_PageCards(
      $cms1Slugs: [String],
      $cms2Slugs: [String]
    )
    {
      cmsPages: cmsPagesFetch(input: {
        slugs: $cms2Slugs
      }) {
        _id
        slug
        title
        pageType
        channels
        image {
          imageUrl
        }
        pageNotFound
      },
      articles(input: { slugs: $cms1Slugs }) {
        _id
        slug
        title
        webLink {
          href
        }
        images(input:{
          type: "Article",
          scope: "full_bleed_image",
          transform: "card_wide"
        }) {
          _id
          source
        }
      }
    }
  `,
  {
    options: ownProps => ({
      ssr: ownProps.ssr,
      errorPolicy: 'all',
      variables: {
        cms1Slugs: ownProps.pageFields.filter(pf => pf.pageSource === ARTICLE).map(s => s.slug),
        cms2Slugs: ownProps.pageFields.filter(pf => pf.pageSource === CMS2_PAGE).map(s => s.slug),
      },
    }),
  },
);

export default withUserContext(connect(CmsPageCards));
