import React from 'react';
import I18n from 'i18n-js';
import { pick } from 'lodash';

import { gql } from '@reverbdotcom/commons/src/gql';
import { useQuery } from '@reverbdotcom/commons/src/useQuery';
import { RCCheckbox, RCLoadingBars } from '@reverbdotcom/cadence/components';
import ModalDialog from '@reverbdotcom/commons/src/components/modal_dialog';
import { MoneyInput } from '@reverbdotcom/commons/src/components/money_input';
import { useSeller } from '@reverbdotcom/commons/src/user_hooks';
import {
  parseAmount,
  formatNumber,
} from '@reverbdotcom/commons/src/money';
import { MParticleEventName } from '@reverbdotcom/commons/src/elog/mparticle_types';
import { trackEvent } from '@reverbdotcom/commons/src/elog/mparticle_tracker';
import { LISTING_STATES, US } from '@reverbdotcom/commons/src/constants';
import {
  Core_QuickPriceEdit_ListingQuery,
  Listing,
} from '@reverbdotcom/commons/src/gql/graphql';
import { useMutation } from '@reverbdotcom/commons/src/useMutation';

import { buildListingEntryStateFromPersistedListing } from '../../sell/form/sellFormReducer';
import { Field } from '../../sell/form/sellFormReducerTypes';
import SellFormIncVatPricingHint from '../../sell/form/components/SellFormIncVatPricingHint';
import QuickPriceEditPriceRecommendation from './quick_price_edit_price_recommendation';
import QuickPriceEditPercentOffFilterChips from './quick_price_edit_percent_off_filter_chips';
import PriceBreakdown from '../../sell/form/components/PriceBreakdown';
import QuickPriceEditHelpText, { calculatePercentOff } from './quick_price_edit_help_text';
import { IShopConfig, SHIPPING_PROFILE_CONFIG_FIELDS, SHOP_CONFIG_FIELDS } from '../../sell/form/SellFormContext';
import { listingSellerPrice } from './quick_price_edit_button';
import { isConditionIneligibleForEstimates } from '../../sell/form/components/pricing_guidance/SellFormPricingGuidance';

export const COMPONENT_NAME = 'QuickPriceEditModal';
const DEFAULT_CURRENCY = 'USD';

export const QUICK_PRICE_EDIT_LISTING_QUERY = gql(`
  query Core_QuickPriceEdit_Listing(
    $listingId: String,
    $shopCurrency: String,
    $shopCountryCode: String,
    $skipPriceRecommendation: Boolean!,
  ) {
    listing(input: { id: $listingId }) {
      _id
      ...HydrateSellForm
      ...SellerListingPriceRecommendation @skip(if: $skipPriceRecommendation)
    }
  }
`);

export const QUICK_PRICE_EDIT_MUTATION = gql(`
  mutation Core_QuickPriceEdit_PriceUpdate($input: Input_core_apimessages_UpdateListingRequest) {
    crupdateListing(input: $input) {
      _id
      sellerPrice {
        amountCents
        currency
        amount
        display
        symbol
      }
      taxExempt
    }
  }
`);

interface IExternalProps {
  listingId: string;
  isOpen: boolean;
  requestClose: React.Dispatch<void>;
  onSuccessCallback: () => void;
}

export function buildShopConfig(data: Core_QuickPriceEdit_ListingQuery): IShopConfig {
  return {
    ...pick(data?.listing?.shop ?? {}, SHOP_CONFIG_FIELDS),
    shippingProfiles: (data?.listing?.shop?.shippingProfiles ?? []).map((sp) => ({
      ...pick(sp, SHIPPING_PROFILE_CONFIG_FIELDS),
      profileRates: sp.allShippingRates,
    })),
  } as IShopConfig;
}

export function listingPriceRecommendation(listing: Listing) {
  if (isConditionIneligibleForEstimates(listing?.condition?.conditionSlug)) return {};
  return listing?.priceRecommendation ?? {};
}

interface IShowFilterChipsArgs {
  priceMiddle: { amountCents?: number },
  sellerPrice: { amountCents?: number };
}

export function showQuickPriceEditFilterChips({ priceMiddle, sellerPrice }: IShowFilterChipsArgs) {
  if (!sellerPrice.amountCents) return false;
  if (!priceMiddle) return true;

  return sellerPrice.amountCents > priceMiddle.amountCents;
}

export default function QuickPriceEditModal({
  listingId,
  isOpen,
  requestClose,
  onSuccessCallback,
}: IExternalProps) {
  const seller = useSeller();
  const { locale, shopCountryCode, shopCurrency, sellerId } = seller;

  const {
    data: listingEntryData,
    loading: listingEntryLoading,
  } = useQuery(QUICK_PRICE_EDIT_LISTING_QUERY, {
    skip: !isOpen,
    ssr: false,
    fetchPolicy: 'network-only',
    variables: {
      listingId: listingId,
      skipPriceRecommendation: shopCountryCode != US,
      shopCountryCode,
      shopCurrency,
    },
    errorPolicy: 'all',
  });

  const [updateListingPrice, {
    loading: updatingListingPrice,
    data: updateListingPriceData,
    errors: updateListingPriceErrors,
  }] = useMutation(QUICK_PRICE_EDIT_MUTATION);

  const { listing: listingEntry = {} } = listingEntryData ?? {};
  const sellerPrice = listingSellerPrice(listingEntry, shopCurrency || DEFAULT_CURRENCY);
  const { currency } = sellerPrice;
  const isLiveListing = listingEntry.state == LISTING_STATES.LIVE;

  const isMissingListingData = !listingEntryData;
  const shopConfig = listingEntryLoading || isMissingListingData ? null : buildShopConfig(listingEntryData);
  const preferredSeller = shopConfig?.preferredSeller ?? false;
  const taxPolicies = shopConfig?.taxPolicies ?? [];

  const [priceAmount, setPriceAmount] = React.useState(formatNumber(Number(sellerPrice.amount)));
  const [taxExemptState, setTaxExemptState] = React.useState(false);
  const [errors, setErrors] = React.useState([]);

  const priceRecommendation = listingPriceRecommendation(listingEntry);
  const { priceMiddle: competitivePrice } = priceRecommendation;

  const showVatTaxExemptField = taxPolicies.length > 0 && taxPolicies.some(({ vatPolicy }) => !!vatPolicy);
  const showFilterChips = showQuickPriceEditFilterChips({
    priceMiddle: priceRecommendation.priceMiddle,
    sellerPrice,
  });

  const forceMoneySuffixForUserInUS = locale === 'en' && shopCountryCode === 'US' && shopCurrency === 'USD';

  React.useEffect(() => {
    const shouldInitialize = isOpen && !listingEntryLoading && !!listingEntryData;

    if (shouldInitialize) {
      trackEvent({
        eventName: MParticleEventName.ComponentView,
        componentName: COMPONENT_NAME,
        listingId,
        sellerId,
      });

      setPriceAmount(sellerPrice.amount);
      setTaxExemptState(listingEntry.taxExempt);
    }
  }, [listingEntryLoading]);

  React.useEffect(() => {
    const isFinishedLoading = !updatingListingPrice;
    const hasData = !!updateListingPriceData;
    const hasErrors = !!updateListingPriceErrors?.length;
    const hasAPIResponse = isFinishedLoading && (hasData || hasErrors);

    if (!hasAPIResponse) return;

    if (hasErrors) {
      handleErrors(updateListingPriceErrors);
    } else {
      const updatedPrice = updateListingPriceData.crupdateListing.sellerPrice;
      const updatedTaxExempt = updateListingPriceData.crupdateListing.taxExempt;

      const priceDroppedAttribute = updatedPrice.amountCents != sellerPrice.amountCents ? {
        priceDropped: updatedPrice.amountCents < sellerPrice.amountCents,
      } : {};

      const discountPercent = calculatePercentOff(sellerPrice, updatedPrice.amount);
      const discountPercentAttribute = discountPercent ? { discountPercent } : {};

      const wellPricedAttribute = competitivePrice ? {
        wellPriced: competitivePrice.amountCents >= updatedPrice.amountCents,
      } : {};

      trackEvent({
        eventName: MParticleEventName.UpdatedListingPrice,
        componentName: COMPONENT_NAME,
        listingId,
        sellerId,
        ...priceDroppedAttribute,
        ...discountPercentAttribute,
        ...wellPricedAttribute,
      });

      setErrors([]);
      setPriceAmount(updatedPrice.amount);
      setTaxExemptState(updatedTaxExempt);
      onSuccessCallback();
    }
  }, [updatingListingPrice]);

  function handleCloseModal() {
    setErrors([]);
    setPriceAmount(sellerPrice.amount);
    setTaxExemptState(listingEntry.taxExempt);
    requestClose();
  }

  function handleErrors(errors) {
    setErrors(errors.map(error => error.message));
  }

  function formatErrors() {
    return errors.join(' ');
  }

  function updatePrice() {
    const formattedListing = buildListingEntryStateFromPersistedListing(listingEntry);

    const updatedListingEntry = {
      ...formattedListing,
      ...{
        [Field.PRICE]: {
          amount: `${priceAmount}`,
        },
        [Field.TAX_EXEMPT]: taxExemptState,
      },
    };

    updateListingPrice({
      variables: {
        input: {
          listingEntry: updatedListingEntry,
        },
      },
    });
  }

  if (!isOpen) return null;

  return (
    <ModalDialog
      isOpen={isOpen}
      headerTitle={I18n.t('discovery.listingsManagement.quickPriceEdit.modal.title')}
      onRequestClose={handleCloseModal}
      hideFooterDismissButton
      onSubmit={updatePrice}
      isSaving={updatingListingPrice}
      isDisabled={listingEntryLoading}
    >
      {listingEntryLoading ? (
        <div className="d-flex fx-justify-center">
          <RCLoadingBars />
        </div>
      ) : (
        <div className="quick-price-edit-modal">
          {showFilterChips && (
            <QuickPriceEditPercentOffFilterChips
              listingId={listingId}
              originalPrice={sellerPrice}
              currentPrice={parseAmount(priceAmount, currency)}
              updatePrice={priceAmount => setPriceAmount(priceAmount)}
            />
          )}

          <MoneyInput
            id={Field.PRICE}
            name={Field.PRICE}
            label={I18n.t('discovery.listingsManagement.quickPriceEdit.modal.moneyInput.label')}
            value={priceAmount || ''}
            onChange={event => setPriceAmount(event.target.value)}
            required
            helpText={<QuickPriceEditHelpText
              originalPrice={sellerPrice}
              currentPriceAmount={priceAmount}
              isLiveListing={isLiveListing} />
            }
            errorText={formatErrors()}
            currencyCode={currency}
            suffixText={forceMoneySuffixForUserInUS ? currency : null}
          />

          {listingEntry.vatIncluded &&
            <SellFormIncVatPricingHint />
          }

          <PriceBreakdown
            price={priceAmount}
            currency={currency}
            preferredSeller={preferredSeller}
            sellingFeeWaived={false}
            shopConfig={shopConfig}
            showIncVatPricingHint={listingEntry.vatIncluded}
          />

          <QuickPriceEditPriceRecommendation
            listingId={listingId}
            priceRecommendation={priceRecommendation}
          />

          {showVatTaxExemptField && (
            <div>
              <div className="weight-bold size-90 mb-2">
                {I18n.t(`discovery.sellForm.fields.${Field.TAX_EXEMPT}.checkboxTitle`)}
              </div>
              <RCCheckbox
                name={Field.TAX_EXEMPT}
                id={Field.TAX_EXEMPT}
                label={I18n.t(`discovery.sellForm.fields.${Field.TAX_EXEMPT}.checkboxLabel`)}
                checked={taxExemptState}
                onChange={() => setTaxExemptState(!taxExemptState)}
              />
            </div>
          )}
        </div>
      )}
    </ModalDialog>
  );
}
