import I18n from 'i18n-js';

const ORDER_MINIMUM_FOR_GRAPH_VIEW = 5;
const ORDER_MINIMUM_FOR_STATS_VIEW = 1;

class Stats {
  constructor(passedData) {
    const data = passedData || {};
    this.sales = data.sales;
    this.orders = data.orders;
    this.offers = data.offers;
    this.watchers = data.watchers;
  }
}

class ShopData {
  constructor(timePeriods, data) {
    this.timePeriods = timePeriods;
    this.orderCount = data.order_count || 0;
    this.salesInMoreThanOneCurrency = data.sales_in_more_than_one_currency;
    this.currency = data.currency;
    this.ordersChange = data.orders_change;
    this.salesChange = data.sales_change;
    this.offersChange = data.offers_change;
    this.watchersChange = data.watchers_change;
    this.currentStats = new Stats(data.current_stats);
    this.previousStats = new Stats(data.previous_stats);
    this.lastMonthApproximations = new Stats(data.last_month_approximations);
  }

  get shouldShowStats() {
    return this.orderCount >= ORDER_MINIMUM_FOR_STATS_VIEW;
  }

  get shouldShowGraph() {
    return this.orderCount >= ORDER_MINIMUM_FOR_GRAPH_VIEW;
  }

  get showPreviousStats() {
    return !this.timePeriods.isLast12Months;
  }

  get isThisMonth() {
    return this.timePeriods.isThisMonth;
  }

  showPercentChange(percent) {
    return !!(this.showPreviousStats && percent);
  }

  showPercentChangeFor(key) {
    return this.showPercentChange(this.changeFor(key));
  }

  changeFor(key) {
    return this[`${key}Change`];
  }

  titleFor(key) {
    return I18n.t(`shopStats.salesStats.${key}`);
  }

  currentStatsFor(key) {
    return this.currentStats[key];
  }

  previousStatsFor(key) {
    return this.previousStats[key];
  }

  lastMonthApproximationsFor(key) {
    return this.lastMonthApproximations[key];
  }
}

export default ShopData;
