import PropTypes from 'prop-types';
import React from 'react';
import { APIRequest } from '@reverbdotcom/discovery-ui';
import RelatedArticlesView from './related_articles_view';

class RelatedArticles extends React.Component {
  componentDidMount() {
    this.fetchRelatedArticles();
  }

  fetchRelatedArticles() {
    APIRequest.V3.get(`/api/articles/${this.props.articleId}/related`, { table: this.props.table })
      .then((response) => {
        const { articles } = response;

        this.setState({ articles });
      });
  }

  render() {
    if (!(this.state && this.state.articles)) { return null; }

    return (
      <RelatedArticlesView
        articles={this.state.articles}
      />
    );
  }
}

RelatedArticles.propTypes = {
  articleId: PropTypes.number.isRequired,
  table: PropTypes.string,
};

export default RelatedArticles;
