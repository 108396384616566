// This file has a disco-ui equivalent, but has elements needed in commons so here we are.
// If a better solution is found, feel free to refactor.

export const EUROPEAN_UNION_COUNTRY_CODES = [
  'AT',
  'BE',
  'BG',
  'CY',
  'CZ',
  'DE',
  'DK',
  'EE',
  'ES',
  'FI',
  'FR',
  'GR',
  'HR',
  'HU',
  'IE',
  'IT',
  'LT',
  'LU',
  'LV',
  'MT',
  'NL',
  'PL',
  'PT',
  'RO',
  'SE',
  'SI',
  'SK',
];

export const EUR_EU = 'EUR_EU';

export function isEU(countryCode: string) {
  return EUROPEAN_UNION_COUNTRY_CODES.includes(countryCode);
}
