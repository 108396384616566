import React from 'react';
import PropTypes from 'prop-types';
import withMParticleListings from '@reverbdotcom/commons/src/components/with_mparticle_listings';
import {
  MParticleEventName,
  ProductActionType,
} from '@reverbdotcom/commons/src/elog/mparticle_types';
import { trackEvent } from '@reverbdotcom/commons/src/elog/mparticle_tracker';
import { APIRequest, UserStore, HashUtil } from '@reverbdotcom/discovery-ui';
import { trackFacebookEvent, ADD_TO_CART_EVENT, PRODUCT_CONTENT_TYPE } from '@reverbdotcom/commons/src/facebook';

import UrlHelpers from '../../shared/url_helpers';

// Like AjaxAddToCartButton but adds multiple products to your cart.
export class AjaxMultiAddToCartButton extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isAdding: false,
    };

    this.handleClick = this.handleClick.bind(this);
    this.handleSuccess = this.handleSuccess.bind(this);
    this.handleError = this.handleError.bind(this);
  }

  componentDidMount() {
    this.$quantitySelector = $('.jq-checkout-quantity');
  }

  quantity() {
    if (this.$quantitySelector.length === 0) { return null; }
    return this.$quantitySelector.val();
  }

  showSellerBlock() {
    Reverb.BlockSellerAction.showMessage(I18n.t('js.addToCart.sellerBlockMessage'));
  }

  handleClick() {
    if (this.props.isOwner) {
      this.showSellerBlock();
      return;
    }

    this.setState({ isAdding: true });

    const downgradeTracking = !!this.props.loading;
    this.addToCart({ downgradeTracking });
  }

  addToCart(args) {
    const { downgradeTracking } = args;

    APIRequest.V3.post(
      UrlHelpers.addMultipleToCartPath,
      JSON.stringify(this.params()),
      {
        contentType: 'application/json',
      },
    )
      .then(this.handleSuccess)
      .fail(this.handleError);

    const event = {
      eventName: MParticleEventName.ProductAction,
      productActionType: ProductActionType.AddToCart,
      quantity: (this.quantity() || 1),
    };

    if (downgradeTracking) {
      event.listing = { id: this.props.listingIds[0].toString() };
    } else {
      event.listing = this.props.mParticleListings[0];
    }

    trackEvent(event);
  }

  updateFacebook() {
    trackFacebookEvent(
      ADD_TO_CART_EVENT,
      {
        content_type: PRODUCT_CONTENT_TYPE,
        content_ids: this.props.listingIds,
      },
    );
  }

  handleSuccess() {
    this.updateFacebook();
    this.props.location.assign('/cart');
  }

  handleError(response) {
    this.setState({ isAdding: false });

    this.props.onError(response.responseJSON.message);
  }

  params() {
    return {
      products: this.productParams(),
      source: this.props.source,
      action_source_attributes: {
        device: UserStore.getState().deviceType,
      },
    };
  }

  productParams() {
    return this.props.listingIds.map(productId => HashUtil.rejectBlankValues({
      id: productId,
      quantity: this.quantity(),
    }));
  }

  renderButtonText() {
    if (this.state.isAdding) {
      return I18n.t('js.addToCart.button.adding');
    } if (this.props.onPreorder) {
      return I18n.t('js.addToCart.button.preorder');
    }

    return this.props.text || I18n.t('js.addToCart.button.add');
  }

  render() {
    const buttonText = this.renderButtonText();

    return (
      <div>
        <button
          onClick={this.handleClick}
          className="add-to-cart-button"
          aria-label={buttonText}
          disabled={this.state.isAdding || null}
        >
          {buttonText}
        </button>
      </div>
    );
  }
}

AjaxMultiAddToCartButton.propTypes = {
  isOwner: PropTypes.bool,
  mParticleListings: PropTypes.arrayOf(PropTypes.any),
  listingIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  onError: PropTypes.func,
  onPreorder: PropTypes.bool,
  source: PropTypes.string,
  text: PropTypes.string,
  location: PropTypes.any,
  loading: PropTypes.bool,
};

AjaxMultiAddToCartButton.defaultProps = {
  isOwner: false,
  onError: () => {},
  onPreorder: false,
  mParticleListings: [],
  source: '',
  text: '',
  location: window.location,
  loading: true,
};

export default withMParticleListings(false)(AjaxMultiAddToCartButton);
