import React from 'react';
import I18n from 'i18n-js';
import classNames from 'classnames';
import {
  withUserContext,
  IUserContext,
} from '@reverbdotcom/commons/src/components/user_context_provider';
import ConditionDisplay from '@reverbdotcom/commons/src/components/condition_display';
import { PriceDisplay } from '@reverbdotcom/commons/src/components/PriceDisplay';
import ImageCarousel from '@reverbdotcom/commons/src/components/image_carousel';
import RibbonView from '@reverbdotcom/commons/src/components/ribbon_view';

import TileCurationView from './curation/tile_curation_view';
import bind from '@reverbdotcom/commons/src/bind';
import Listing from './listing';
import WatchBadge from './watch_badge';
import LocationUtil from '../shared/location_util';

import { apiListingToMparticleListing } from '../../lib/adapters/api_listing_to_mparticle_fragment';
import { buildListingClickEvent, trackEvent } from '@reverbdotcom/commons/src/elog/mparticle_tracker';
import { WEB } from '@reverbdotcom/commons/src/constants';
import CoreLink from '@reverbdotcom/commons/src/components/core_link';
import BumpTag, { shouldShowBumpTag } from '@reverbdotcom/commons/src/components/bump_tag';

interface IProps extends IUserContext {
  listing: Listing;
  listingClicked?: (listing: Listing) => void;
  condensed?: boolean;
  position?: number;
  page?: number;
  perPage?: number;
  trackName?: string;
  trackContext?: object;
}

function PlaceholderCard() {
  return (
    <div className="grid-card grid-card--placeholder">
      <div className="grid-card__inner">
        <div className="grid-card__main">
          <div className="grid-card__image" />
          <div className="grid-card__main__text">
            <div className="grid-card__title" />
          </div>
        </div>

        <div className="grid-card__footer">
          <div className="grid-card__footer__pricing">
            <div className="grid-card__price" />
          </div>
        </div>
      </div>
    </div>
  );
}

export class ListingCard extends React.Component<IProps, null> {
  static defaultProps: Partial<IProps> = {
    condensed: false,
    listingClicked: () => { },
    trackName: 'ListingCard',
  };

  trackClick() {
    trackEvent(buildListingClickEvent({
      listing: apiListingToMparticleListing(this.props.listing),
      componentName: this.props.trackName,
      position: this.props.position,
      displayStyle: null,
    }));
  }

  @bind
  listingClicked() {
    this.trackClick();
    this.props.listingClicked(this.props.listing);
  }

  get user() {
    return this.props.user || { experiments: [] };
  }

  hasDiscount() {
    return !!this.props.listing.original_price;
  }

  ribbonText() {
    return this.props.listing.ribbon ?
      this.props.listing.ribbon.display :
      null;
  }

  listingUrl() {
    const url = new URL(this.props.listing.link('web'));

    if (LocationUtil.filteringShowOnlySold()) {
      url.search += 'show_sold=true';
    }

    return url.pathname + url.search;
  }

  renderCuration() {
    if (!this.props.user?.isAdmin) { return null; }

    return (
      <TileCurationView
        listingId={this.props.listing.id}
        bumped={this.props.listing.bumped}
      />
    );
  }

  renderConditionDisplay() {
    return (
      <div className="grid-card__condition">
        <ConditionDisplay
          slug={this.props.listing.condition.slug}
          displayName={this.props.listing.condition.display_name}
          isDigitalListing={this.props.listing.isDigitalListing}
        />
      </div>
    );
  }

  renderWatchBadge() {
    if (typeof this.props.listing.watching === 'undefined') { return null; }

    if (this.props.user?.loggedOut) {
      return null;
    }

    return (
      <div className="grid-card__watch">
        <WatchBadge listing={this.props.listing} />
      </div>
    );
  }

  renderFreeExpeditedShipping() {
    if (!this.props.listing.free_expedited_shipping) {
      return null;
    }

    return (
      <div
        className="grid-card__free-2-day"
      >
        {I18n.t('discovery.freeExpeditedShipping')}
      </div>
    );
  }

  renderOriginalPrice() {
    if (!this.hasDiscount()) {
      return null;
    }

    // the empty span here is intentional to support the redesigned listing card
    return (
      <div className="grid-card__original-price">
        <span>
          {this.props.listing.original_price.display}
        </span>
      </div>
    );
  }

  renderPrice() {
    return (
      <PriceDisplay
        amountCents={this.props.listing.buyer_price.amount_cents}
        display={this.props.listing.buyer_price.display}
      />
    );
  }

  render() {
    if (!this.props.listing) {
      return <PlaceholderCard />;
    }

    const classes = classNames(
      'grid-card',
      { 'grid-card--software': this.props.listing.isDigitalListing },
      { 'grid-card--discounted': this.hasDiscount() },
    );

    const { user } = this.props;
    const openNewTab = user && user.deviceName === WEB;

    const images = this.props.listing.images.map(i => (i._links.small.href));
    return (
      <div className={classes}>
        <CoreLink
          className="grid-card__inner"
          onClick={this.listingClicked}
          to={this.listingUrl()}
          target={openNewTab ? '_blank' : undefined}
        >
          <div
            className="grid-card__main"
          >
            <div className="grid-card__image">
              <ImageCarousel
                images={images}
                imgAlt={this.props.listing.title}
              />
              <RibbonView
                auction={this.props.listing.auction}
                listingStateDescription={this.props.listing.state.description}
                listingStateSlug={this.props.listing.state.slug}
                discountDisplay={this.ribbonText()}
              />
            </div>
            <div className="grid-card__main__text">
              {shouldShowBumpTag({ bumpKey: this.props.listing.bump_key }) &&
                <div className="grid-card__bump-tag">
                  <BumpTag />
                </div>
              }
              <h4 className="grid-card__title">
                {this.props.listing.title}
              </h4>
            </div>
          </div>
          <div className="grid-card__footer">
            {this.renderFreeExpeditedShipping()}
            {this.renderOriginalPrice()}
            <div
              className="grid-card__footer__pricing"
            >
              <div className="grid-card__price">
                {this.renderPrice()}
              </div>
              {this.renderConditionDisplay()}
            </div>
            {this.renderCuration()}
          </div>
        </CoreLink>
        {this.renderWatchBadge()}
      </div>
    );
  }
}

export default withUserContext(ListingCard);
