import I18n from 'i18n-js';
import React from 'react';
import { isEmpty, union, xor } from 'lodash';
import classNames from 'classnames';

import { MoneyInput } from '@reverbdotcom/commons/src/components/money_input';
import { ModalDialog, SanitizedRender } from '@reverbdotcom/commons';
import { RCCheckbox, RCFormGroup, RCAccordion, RCAlertBox } from '@reverbdotcom/cadence/components';
import { isZeroAmount, parseAmount } from '@reverbdotcom/commons/src/money';
import { IShippingRate } from './edit_shipping_rate_card_manual_prices';
import { IRegionalRate, RegionalRateType } from './edit_shipping_rate_card_regional_prices';
import { US_CON_MIDWEST, US_CON_WEST, US_CON_SOUTH, US_CON_NORTHEAST, US_CON, CANADA_PROVINCIAL_CODES, REGIONAL_AREA_TYPES } from '@reverbdotcom/commons/src/constants';

export interface IRegionalShippingModal {
  modalType: RegionalRateType | '';
  handleModalClose: () => void;
  handleSubmit: () => void;
  rateAmount: string;
  currencyCode: string;
  setRateAmount: (rate) => void;
  modalEditMode: boolean;
  shippingRate: IShippingRate;
  selectedAreas: string[];
  setSelectedAreas: (areas) => void;
  handleAreaSelect: (any, string) => void;
  disabledAreas: string[];
  existingRatesInCents: number[];
  regionalRatesOfOtherType: IRegionalRate[];
  inFreeMode: boolean;
}

export interface IAreaGroup {
  name: string;
  areas: string[];
}

export interface IAreaContent {
  regions: IAreaGroup[] | IAreaGroup;
  shippingRate: IShippingRate;
  selectedAreas: string[];
  setSelectedAreas: (areas) => void;
  disabledAreas: string[];
  handleAreaSelect: (any, string) => void;
  setErrorList: (any: any) => void;
  rateAmount: string;
  regionalRatesOfOtherType: IRegionalRate[];
  rateType: RegionalRateType | '';
  currencyCode: string;
}

export interface IAreaCheckboxes {
  region: IAreaGroup;
  shippingRate: IShippingRate;
  selectedAreas: string[];
  setSelectedAreas: (areas) => void;
  disabledAreas: string[];
  handleAreaSelect: (any, string) => void;
}

const modalTranslationKey = 'discovery.dashboard.selling.shippingRates.shippingModeRadios.regional.modal';

function SelectAllAreasCheckbox({ region, selectedAreas, setSelectedAreas, disabledAreas }) {
  function isDisabled() {
    // the checkbox is disabled if all of this region's states are present in the list of currently disabled states
    return region.areas.every((areaCode) => disabledAreas.includes(areaCode));
  }

  return (
    <div className="shipping-rate-card__regional__modal__area-checkbox">
      <RCCheckbox
        id={`all-${region.name.toLowerCase()}-checkbox`}
        name={`all-${region.name.toLowerCase()}`}
        label={I18n.t(`${modalTranslationKey}.selectAllInRegion`)}
        checked={!isDisabled() && region.areas.every((areaCode) => selectedAreas.includes(areaCode) || disabledAreas.includes(areaCode))}
        onChange={(event) => {
          // if checked, union all of this region's non-disabled states with the currently selected states; if unchecked, remove them all
          return event.target.checked ?
            setSelectedAreas(union(selectedAreas, region.areas.filter((areaCode) => !disabledAreas.includes(areaCode)))) :
            setSelectedAreas(xor(selectedAreas, region.areas.filter((areaCode) => !disabledAreas.includes(areaCode))));
        }}
        disabled={isDisabled()}
      />
    </div>
  );
}

function AreaCheckboxes({
  region,
  selectedAreas,
  disabledAreas,
  handleAreaSelect,
  shippingRate,
}: IAreaCheckboxes) {
  return (
    <>
      {region.areas.map((areaCode) => (
        <div
          className="shipping-rate-card__regional__modal__area-checkbox"
          key={areaCode}
        >
          <RCCheckbox
            id={`${areaCode}-checkbox`}
            name={areaCode}
            label={I18n.t(`discovery.regionalShippingAreas.${shippingRate.regionCode}.${areaCode}`)}
            checked={selectedAreas.includes(areaCode)}
            onChange={(event) => handleAreaSelect(event, areaCode)}
            disabled={disabledAreas.includes(areaCode)}
          />
        </div>
      ))}
    </>
  );
}

function AreaContent({
  regions,
  shippingRate,
  selectedAreas,
  setSelectedAreas,
  disabledAreas,
  handleAreaSelect,
  setErrorList,
  rateAmount,
  regionalRatesOfOtherType,
  rateType,
  currencyCode,
} : IAreaContent) {
  const rateAmountCents = parseAmount(rateAmount, currencyCode).amountCents;

  React.useEffect(() => {
    setErrorList(selectedAreas.reduce((errorList, areaCode) => {
      const text = errorText(areaCode);
      return text ? [...errorList, text] : errorList;
    }, []).sort());
  }, [rateAmount, selectedAreas]);

  function errorText(areaCode) {
    const conflictingRate = regionalRatesOfOtherType.find((regionalRateOfOtherType) => {
      if (!selectedAreas.includes(areaCode) || !rateAmount || !regionalRateOfOtherType.areas.includes(areaCode)) {
        return false;
      }

      if (rateType === RegionalRateType.EXPEDITED) {
        return rateAmountCents < regionalRateOfOtherType.rate.amountCents;
      }

      return rateAmountCents > regionalRateOfOtherType.rate.amountCents;
    });

    return !!conflictingRate &&
      `${I18n.t(`discovery.regionalShippingAreas.${shippingRate.regionCode}.${areaCode}`)}: ${conflictingRate.rate.display || parseAmount(conflictingRate.rate.amount, currencyCode).display}`;
  }

  if (!Array.isArray(regions)) {
    return (
      <div className="shipping-rate-card__regional__modal__area-checkboxes__container">
        <AreaCheckboxes
          region={regions}
          handleAreaSelect={handleAreaSelect}
          disabledAreas={disabledAreas}
          selectedAreas={selectedAreas}
          setSelectedAreas={setSelectedAreas}
          shippingRate={shippingRate}
        />
      </div>
    );
  }

  return (
    <>
      {regions.map((region, index) => (
        <div className="shipping-rate-card__regional__modal__accordion" key={region.name}>
          {index !== 0 && <hr className="shipping-rate-card__regional__modal__separator" />}
          <RCAccordion
            heading={region.name}
            id={`area_group-${region.name.toLowerCase()}`}
            defaultOpen={!isEmpty(region.areas.filter((areaCode) => selectedAreas.includes(areaCode))) || (isEmpty(selectedAreas) && index === 0)}
            size="small"
          >
            <SelectAllAreasCheckbox
              region={region}
              selectedAreas={selectedAreas}
              setSelectedAreas={setSelectedAreas}
              disabledAreas={disabledAreas}
            />
            <AreaCheckboxes
              region={region}
              handleAreaSelect={handleAreaSelect}
              disabledAreas={disabledAreas}
              selectedAreas={selectedAreas}
              setSelectedAreas={setSelectedAreas}
              shippingRate={shippingRate}
            />
          </RCAccordion>
        </div>
      ))}
    </>
  );
}

function EditShippingRateCardRegionalModal({
  modalType,
  handleModalClose,
  handleSubmit,
  rateAmount,
  currencyCode,
  setRateAmount,
  modalEditMode,
  shippingRate,
  selectedAreas,
  setSelectedAreas,
  handleAreaSelect,
  disabledAreas,
  existingRatesInCents,
  regionalRatesOfOtherType,
  inFreeMode,
}: IRegionalShippingModal) {
  const usConRegions: IAreaGroup[] = [{ name: I18n.t('discovery.regionalShippingAreas.US_CON_MIDWEST'), areas: US_CON_MIDWEST },
    { name: I18n.t('discovery.regionalShippingAreas.US_CON_NORTHEAST'), areas: US_CON_NORTHEAST },
    { name: I18n.t('discovery.regionalShippingAreas.US_CON_SOUTH'), areas: US_CON_SOUTH },
    { name: I18n.t('discovery.regionalShippingAreas.US_CON_WEST'), areas: US_CON_WEST }];
  const canadaRegion: IAreaGroup = { name: I18n.t('discovery.shippingRegions.CA'), areas: CANADA_PROVINCIAL_CODES };
  const rateAmountCents = parseAmount(rateAmount, currencyCode).amountCents;
  const inputPriceExists = !!rateAmount && existingRatesInCents.includes(rateAmountCents);
  const [errorList, setErrorList] = React.useState([]);

  const noAreasSelected = !selectedAreas.length;
  const zeroEnteredInPaidMode = isZeroAmount(rateAmount) && !inFreeMode;
  const saveButtonDisabled = noAreasSelected || errorList.length > 0 || inputPriceExists || zeroEnteredInPaidMode || (!Number(rateAmount) && !isZeroAmount(rateAmount));
  const regionalAreaType = REGIONAL_AREA_TYPES[shippingRate.regionCode];
  const localizedAreaType = I18n.t(`discovery.dashboard.selling.shippingRates.shippingModeRadios.regional.${regionalAreaType}s`).toLowerCase();
  const areaTypeTranslationKey = `${regionalAreaType}${errorList.length > 1 ? 's' : ''}`;

  function moneyInputErrorText() {
    if (!!rateAmount && (zeroEnteredInPaidMode || !Number(rateAmount))) {
      return I18n.t(`${modalTranslationKey}.error.price.invalid`);
    } else if (inputPriceExists) {
      return I18n.t(`${modalTranslationKey}.error.price.duplicate`);
    }
  }

  return (
    <ModalDialog
      isOpen={!!modalType}
      onRequestClose={handleModalClose}
      onSubmit={handleSubmit}
      isDisabled={saveButtonDisabled}
      headerTitle={I18n.t(`${modalTranslationKey}.header.${modalType == RegionalRateType.EXPEDITED ? 'expedited' : 'standard'}.${inFreeMode ? 'free' : 'paid'}`)}
      saveButtonText={I18n.t(`${modalTranslationKey}.save`)}
      backButtonText={I18n.t(`${modalTranslationKey}.cancel`)}
    >
      <div className="shipping-rate-card__regional__modal">
        <p className="shipping-rate-card__regional__modal__body">
          {inFreeMode ?
            I18n.t(`${modalTranslationKey}.body.${modalType == RegionalRateType.EXPEDITED ? 'expedited' : 'standard'}.free`, { country: shippingRate.regionName }) : (
              <>
                {I18n.t(`${modalTranslationKey}.body.${modalType == RegionalRateType.EXPEDITED ? 'expedited' : 'standard'}.paid`, { areaType: localizedAreaType })}
                <br />
                {I18n.t(`${modalTranslationKey}.body.${modalType == RegionalRateType.EXPEDITED ? 'expedited' : 'standard'}.paidHelp`)}
              </>
            )
          }
        </p>
        <RCFormGroup label="" inputId="regional">
          {!inFreeMode && (
            <>
              <div className={classNames('shipping-rate-card__manual__price__entry', {
                'shipping-rate-card__manual__price__entry--regional': errorList.length < 1,
              })}>
                <div className="shipping-rate-card__manual__price__entry__input">
                  <MoneyInput
                    id={`${modalType}-money-input`}
                    name={modalType}
                    label={I18n.t(`${modalTranslationKey}.priceLabel.${modalType == RegionalRateType.EXPEDITED ? 'expedited' : 'standard'}`)}
                    value={rateAmount}
                    onChange={(e) => setRateAmount(e.target.value)}
                    currencyCode={currencyCode}
                    required={modalType == RegionalRateType.STANDARD}
                    disabled={modalEditMode}
                    tag={modalType == RegionalRateType.STANDARD ? 'required' : null}
                    errorText={moneyInputErrorText()}
                  />
                </div>
              </div>
            </>
          )}
          {errorList.length > 0 && (
            <RCAlertBox type="error" small>
              <SanitizedRender
                htmlTag="span"
                html={I18n.t(`${modalTranslationKey}.error.alert.${errorList.length > 1 ? 'plural' : 'singular'}.${modalType}`, {
                  errorCount: errorList.length,
                  areaType: I18n.t(`discovery.dashboard.selling.shippingRates.shippingModeRadios.regional.${areaTypeTranslationKey}`).toLocaleLowerCase(),
                  tagOpen: '<strong>',
                  tagClose: '</strong>',
                })}
              />
              <ul className="shipping-rate-card__regional__modal__error-list">
                {errorList.map((errorText) => (
                  <li key={errorText}>{errorText}</li>
                ))}
              </ul>
            </RCAlertBox>
          )}
          {!inFreeMode && (
            <p className="shipping-rate-card__regional__modal__body">
              {I18n.t(`${modalTranslationKey}.secondaryBody.${modalType == RegionalRateType.EXPEDITED ? 'expedited' : 'standard'}`)}
            </p>
          )}

          <AreaContent
            regions={shippingRate.regionCode === US_CON ? usConRegions : canadaRegion}
            shippingRate={shippingRate}
            selectedAreas={selectedAreas}
            setSelectedAreas={setSelectedAreas}
            disabledAreas={disabledAreas}
            handleAreaSelect={handleAreaSelect}
            setErrorList={setErrorList}
            rateAmount={rateAmount}
            regionalRatesOfOtherType={regionalRatesOfOtherType}
            rateType={modalType}
            currencyCode={currencyCode}
          />
        </RCFormGroup>
      </div>
    </ModalDialog>
  );
}

export default EditShippingRateCardRegionalModal;
