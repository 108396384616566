import I18n from 'i18n-js';
import React from 'react';

import { mapFavoriteToEntityType } from '@reverbdotcom/commons/src/favorites/entity_type';

import { Core_FindFavoriteQuery } from '@reverbdotcom/commons/src/gql/graphql';
import { useUser } from '@reverbdotcom/commons/src/user_hooks';
import { FavoriteButton } from './favorite_button';
import { ExternalProps, FavoriteModal, useFindFavorite } from './find_favorite_context';
import { LoggedOutFavoriteButton } from './logged_out_favorite_button';

type Core_FindFavoriteQuery_Favorite = Core_FindFavoriteQuery['findFavorite']['favorite'];

interface FavoriteButtonWrapperProps {
  favorite: Core_FindFavoriteQuery_Favorite;
  displayStyle?: 'banner' | 'button' | 'link';
  children: JSX.Element;
  header?: string;
  subtext?: string;
}

function FavoriteButtonWrapper(props: FavoriteButtonWrapperProps) {
  if (props.displayStyle !== 'banner') {
    return props.children;
  }

  const bannerHeaderContent = props.favorite.favorited ?
    I18n.t(`discovery.favorites.banner.saved.${mapFavoriteToEntityType(props.favorite)}`) :
    (props.header || I18n.t('discovery.favorites.banner.unsaved'));

  return (
    <div className="feed-button__banner">
      <h4 className="size-150 mobile-size-130 weight-bold pb-1">
        {bannerHeaderContent}
      </h4>
      <p className="size-90">
        {props.subtext || I18n.t('discovery.favorites.saveSearchCallout')}
      </p>
      {props.children}
    </div>
  );
}

export function FavoriteButtonContainer(props: ExternalProps) {
  const {
    setDisplayedModal,
    setFavoriteIntentComponent,
    favorite,
    canFavorite,
  } = useFindFavorite();
  const user = useUser();

  const handleOpenModal = function () {
    if (props.componentName?.length > 0) {
      setFavoriteIntentComponent(props.componentName);
    }
    setDisplayedModal(FavoriteModal.Save);
  };

  if (!canFavorite) return null;
  const buttonTypeOverride = (props.displayStyle === 'link') ? props.displayStyle : undefined;

  return (
    <div className="favorite-button">
      <FavoriteButtonWrapper
        favorite={favorite}
        displayStyle={props.displayStyle}
        header={props.header}
        subtext={props.subtext}
      >
        {
          user.loggedOut ?
            <LoggedOutFavoriteButton
              renderWithBanner={props.displayStyle === 'banner'}
              hideLoggedOutModal={props.hideLoggedOutModal}
              buttonTypeOverride={buttonTypeOverride}
            />
            :
            <FavoriteButton
              renderWithBanner={props.displayStyle === 'banner'}
              openModal={handleOpenModal}
              showCallout={props.showCallout}
              buttonTypeOverride={buttonTypeOverride}
            />
        }
      </FavoriteButtonWrapper>
    </div>
  );
}
