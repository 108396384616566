const MOBILE_SCREEN_WIDTH = 721;
const REGEX = /reverb.*ios|android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i;
const BUILD_PHRASE_REGEX = /\bbuild (?<version>\d+)/i;

export default {
  userAgent(): string {
    return navigator.userAgent;
  },

  isMobileBrowser(): boolean {
    return REGEX.test(this.userAgent());
  },

  getClickEvent(): string {
    return this.isMobileBrowser() ? 'touchend' : 'click';
  },

  isMobileScreenWidth(): boolean {
    return window.screen.width < MOBILE_SCREEN_WIDTH;
  },

  buildNumber(): number {
    const number = Number(this.userAgent().match(BUILD_PHRASE_REGEX)?.groups?.version);

    return isNaN(number) ? 0 : number;
  },
};
