import React, { RefObject } from 'react';
import Textarea from 'react-textarea-autosize';

import { InputMode } from '@reverbdotcom/cadence/components';

import bind from '../bind';
import { NUMBER_OR_FLOAT_KEYS_REGEX } from '../constants';
import isMobile from '../is-mobile';
import { hasPeriodDecimalSeparator } from '../money';

/** Valid HTML input types for ControlledInput  */
/** Do not use datetime-local in user-facing components */
export type InputType =
  | 'checkbox'
  | 'datetime-local'
  | 'email'
  | 'image'
  | 'number'
  | 'password'
  | 'radio'
  | 'select'
  | 'tags'
  | 'tel'
  | 'text'
  | 'textarea';

interface IProps {
  autoCapitalize?: string;
  autoComplete?: string;
  autoCorrect?: string;
  autoFocus?: boolean;
  checked?: boolean;
  className?: string;
  currencyPrefix?: string;
  currencySuffix?: string;
  fieldName: string;
  id?: string;
  inputMode?: InputMode;
  inputName: string;
  inputValue: string;
  inputTitleValue?: string; // This is the title attribute for the input, not a label
  minLength?: number;
  maxLength?: number;
  numberOnly?: boolean;
  pattern?: string;
  patternDescription?: string;
  disabled?: boolean;
  inputType?: InputType;
  placeholder?: string;
  required?: boolean;
  rows?: number;
  spellCheck?: boolean;
  updateField: Function;
  onBlur?: (event: FocusEvent, attributes: PlainObject) => void;
  step?: string;
  inputRef?: RefObject<HTMLInputElement>;
}

// Does this keyboard event print a character to the input?
function isPrinting(e: React.KeyboardEvent<HTMLInputElement>) {
  return e.key.length === 1 && !e.altKey && !e.ctrlKey && !e.metaKey;
}

/**
 * [Controlled component](https://reactjs.org/docs/forms.html#controlled-components)
 * to wrap HTML inputs or textarea based on inputType prop
 *
 * Change events are passed to the updateField prop
 *
 * For labels and input error handling, use with FormGroupWithInput
 */
export default class ControlledInput extends React.Component<IProps, null> {
  static defaultProps = {
    rows: 5,
  };

  @bind
  onKeyDown(event: React.KeyboardEvent<HTMLInputElement>) {
    if (this.props.numberOnly && isPrinting(event) && !event.key.match(NUMBER_OR_FLOAT_KEYS_REGEX)) {
      event.preventDefault();
    }
  }

  @bind
  updateField(event) {
    const attributes = {};
    attributes[this.props.fieldName] = event.target.value;
    this.props.updateField(attributes);
  }

  @bind
  onBlur(event) {
    if (!this.props.onBlur) return;

    const attributes = {};
    attributes[this.props.fieldName] = event.target.value;
    this.props.onBlur(event, attributes);
  }

  get inputType() {
    // if a numeric input, mobile, AND english, we can safely use the 'number' type
    if (this.props.numberOnly && isMobile && hasPeriodDecimalSeparator()) {
      return 'number';
    }

    return this.props.inputType || 'text';
  }

  get placeholder() {
    return this.props.placeholder;
  }

  getStep() {
    if (this.inputType === 'number') {
      return { step: this.props.step || '0.01' };
    }
  }

  shouldRenderInputGroup() {
    return (!!this.props.currencyPrefix || !!this.props.currencySuffix);
  }

  inputGroupClass() {
    if (this.shouldRenderInputGroup()) return 'input-group__input';
    return this.props.className;
  }

  renderInputOrGroup() {
    if (this.shouldRenderInputGroup()) {
      return (
        <div className="input-group">
          {!!this.props.currencyPrefix &&
            <div className="input-group__text">
              {this.props.currencyPrefix}
            </div>
          }
          {this.renderInput()}
          {!!this.props.currencySuffix &&
            <div className="input-group__text">
              {this.props.currencySuffix}
            </div>
          }
        </div>
      );
    }

    return this.renderInput();
  }

  renderInput() {
    return (
      <input
        autoCapitalize={this.props.autoCapitalize}
        autoComplete={this.props.autoComplete}
        autoCorrect={this.props.autoCorrect}
        autoFocus={this.props.autoFocus}
        checked={this.props.checked}
        className={this.inputGroupClass()}
        disabled={this.props.disabled}
        id={this.props.id || this.props.inputName}
        minLength={this.props.minLength}
        maxLength={this.props.maxLength}
        name={this.props.inputName}
        onChange={this.updateField}
        onKeyDown={this.onKeyDown}
        onBlur={this.onBlur}
        pattern={this.props.pattern}
        placeholder={this.props.placeholder}
        required={this.props.required}
        spellCheck={this.props.spellCheck}
        type={this.inputType}
        value={this.props.inputValue}
        title={this.props.inputTitleValue}
        inputMode={this.props.inputMode}
        ref={this.props.inputRef}
        {...this.getStep()}
      />
    );
  }

  render() {
    const { inputType } = this.props;

    if (inputType === 'textarea') {
      return (
        <Textarea
          autoCapitalize={this.props.autoCapitalize}
          autoCorrect={this.props.autoCorrect}
          autoFocus={this.props.autoFocus}
          className={this.props.className}
          disabled={this.props.disabled}
          id={this.props.id || this.props.inputName}
          minLength={this.props.minLength}
          maxLength={this.props.maxLength}
          name={this.props.inputName}
          onChange={this.updateField}
          placeholder={this.props.placeholder}
          required={this.props.required}
          rows={this.props.rows}
          spellCheck={this.props.spellCheck}
          value={this.props.inputValue}
          maxRows={30}
          title={this.props.inputTitleValue}
        />
      );
    }

    return this.renderInputOrGroup();
  }
}
