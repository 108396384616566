// TODO update this to import from commons/src/gql
// eslint-disable-next-line no-restricted-imports
import { gql } from '@apollo/client';
import { ChildProps } from '@apollo/client/react/hoc';
import { get, isEqual } from 'lodash';
import React from 'react';

import ListingsCollection, { ListingsCollection as ListingsCollectionClass } from '../../components/listings_collection';
import SanitizedRender from '../../components/sanitized_render';
import { withGraphql } from '../../with_graphql';

import {
  Commons_Cms_RecentlyViewedListingsQuery,
  Commons_Cms_RecentlyViewedListingsQueryVariables,
  core_apimessages_Ribbon_Reason as IRibbonReason,
} from '../../gql/graphql';

import { formatExperimentsForEventAttributes, userContextIsTrackable } from '../../elog/mparticle';
import { MParticleEventName, trackEvent } from '../../elog/mparticle_tracker';

import { OverflowingRowAction } from '../../components/overflowing_row_action';
import RecentlyViewedAndMoreListings from '../../components/recently_viewed_and_more_listings';
import { IUserContext, withUserContext } from '../../components/user_context_provider';
import { mParticleListingFields } from '../../components/with_mparticle_listings';
import experiments from '../../experiments';
import { isExperimentEnabled } from '../../user_context_helpers';
import { IDynamicComponentProps } from '../dynamic_component_props';

interface ExternalProps extends IDynamicComponentProps {
  onSale?: boolean;
  ctaText?: string;
  ctaTargetHref?: string;
  largeTiles?: boolean;
  title?: string;
  titleHtml?: string;
  displayAsGrid?: boolean;
  jumplinkSlug?: string;
  loggedOutExperiment?: boolean;
  onVisible?: () => void;
  displayAddToCart?: boolean;
  trackName?: string;
  experimentName?: string;
  showFlags?: boolean;
  showShippingDisplay?: boolean;
}

export type IProps = ExternalProps & IUserContext;

type Commons_Cms_RecentlyViewedListingsQuery_RecentlyViewedListings = Commons_Cms_RecentlyViewedListingsQuery['recentlyViewedListings'][0];

const COMPONENT_NAME = 'RecentlyViewedListings';
const DEFAULT_COUNT = 6;
const MIN_COUNT = 6;

export class RecentlyViewedListings extends React.Component<
ChildProps<IProps, Commons_Cms_RecentlyViewedListingsQuery, Commons_Cms_RecentlyViewedListingsQueryVariables>,
null
> {

  static defaultProps: Partial<IProps> = {
    loggedOutExperiment: false,
  };

  componentDidMount() {
    this.logView();

    const { onVisible } = this.props;
    if (this.showListings() && onVisible) {
      onVisible();
    }
  }

  componentDidUpdate(oldProps) {
    const newListings = get(this.props, 'data.recentlyViewedListings', []);
    const oldListings = get(oldProps, 'data.recentlyViewedListings', []);
    if (!isEqual(newListings, oldListings)) {
      this.logView();
    }

    const { onVisible } = this.props;
    if (!isEqual(newListings, oldListings) && this.showListings() && onVisible) {
      onVisible();
    }
  }

  logView() {
    if (!this.hasEnoughListings()) return;

    const experimentTracking = !this.props.experimentName ? {} : {
      experiments: formatExperimentsForEventAttributes(this.props.user, [this.props.experimentName]),
    };

    const viewData = {
      componentName: this.props.trackName || COMPONENT_NAME,
      cmsComponentId: this.props.componentId,
      eventName: MParticleEventName.ComponentView,
      ...experimentTracking,
    };

    trackEvent(viewData);
  }

  minListingCount = () => MIN_COUNT;

  maxListingCount = () => DEFAULT_COUNT;

  hasEnoughListings() {
    return !this.loading() && this.listings().length >= this.minListingCount();
  }

  renderCTA() {
    if (!this.props.ctaTargetHref) {
      return null;
    }

    return (
      <OverflowingRowAction
        href={this.props.ctaTargetHref}
        text={this.props.ctaText}
      />
    );
  }

  saleListings() {
    const listings = get(this.props, 'data.recentlyViewedListings');

    const salesListings = listings.filter((listing) => {
      const reason = get(listing, 'pricing.ribbon.reason', '');
      return reason === IRibbonReason.SALE;
    });

    return salesListings.slice(0, this.maxListingCount());
  }

  loading() {
    return get(this.props, 'data.loading');
  }

  listings(): Commons_Cms_RecentlyViewedListingsQuery_RecentlyViewedListings[] {
    const listings = get(this.props, 'data.recentlyViewedListings');

    if (this.props.onSale && listings) {
      return this.saleListings();
    }

    return (listings || []).slice(0, this.maxListingCount());
  }

  renderTitle() {
    if (!this.props.title) return null;

    return (
      <SanitizedRender
        html={this.props.titleHtml || this.props.title}
      />
    );
  }

  showListings() {
    return this.hasEnoughListings();
  }

  render() {
    if (!this.showListings()) {
      if (!this.loading() && this.listings().length) {
        return (
          <RecentlyViewedAndMoreListings
            recentlyViewedListings={this.listings()}
            largeTiles={this.props.largeTiles}
            maxCount={this.maxListingCount()}
            displayAsGrid={this.props.displayAsGrid}
            jumplinkSlug={this.props.jumplinkSlug}
            displayAddToCart={this.props.displayAddToCart}
            showFlags={this.props.showFlags}
            showShippingDisplay={this.props.showShippingDisplay}
            cmsComponentId={this.props.componentId}
          />
        );
      } else {
        return null;
      }
    }

    if (this.props.experimentName && !isExperimentEnabled(this.props.user, this.props.experimentName)) {
      return null;
    }

    return (
      <ListingsCollection
        callToAction={this.renderCTA()}
        componentName={this.props.trackName || COMPONENT_NAME}
        cmsComponentId={this.props.componentId}
        displayAsGrid={this.props.displayAsGrid}
        largeTiles={this.props.largeTiles}
        listings={this.listings()}
        loading={this.loading()}
        maxCount={this.maxListingCount()}
        title={this.renderTitle()}
        jumplinkSlug={this.props.jumplinkSlug}
        displayAddToCart={this.props.displayAddToCart}
        showFlags={this.props.showFlags}
        showShippingDisplay={this.props.showShippingDisplay}
      />
    );
  }
}

export const RECENTLY_VIEWED_LISTINGS_QUERY = gql`
  query Commons_Cms_RecentlyViewedListings(
    $limit: Int
    $showListingLocation: Boolean!
    $shouldSkipTracking: Boolean!
  ) {
    recentlyViewedListings(input: {
      limit: $limit
    }) {
      _id
      ... ListingsCollection
      ... mParticleListingFields @skip(if: $shouldSkipTracking)
      ... ShopFields @include(if: $showListingLocation)
    }
  }
  ${ListingsCollectionClass.fragments.listing}
  ${mParticleListingFields}
  ${ListingsCollectionClass.fragments.shopFields}
`;

const connect = withGraphql<IProps, Commons_Cms_RecentlyViewedListingsQuery, Commons_Cms_RecentlyViewedListingsQueryVariables>(
  RECENTLY_VIEWED_LISTINGS_QUERY,
  {
    options: ownProps => ({
      ssr: ownProps.ssr,
      variables: {
        limit: ownProps.onSale ? 20 : DEFAULT_COUNT,
        showListingLocation: isExperimentEnabled(ownProps.user, experiments.LISTING_CARD_LOCATION),
        shouldSkipTracking: !userContextIsTrackable(ownProps.user),
      },
    }),
  },
);

export default withUserContext<IProps>(connect(RecentlyViewedListings));
