import PropTypes from 'prop-types';
import React from 'react';
import { ListingRow, APIRequest } from '../index';
import I18n from 'i18n-js';

class BumpRetargeting extends React.Component {
  componentDidMount() {
    this.fetchListings();
  }

  fetchListings() {
    APIRequest.V3.get(
      `/api/listings/${this.props.listingId}/retargeting_history`,
      { include_grid_images: true },
    ).then((response) => {
      this.setState({ listings: response.listings });
    });
  }

  empty() {
    return !this.listings().length;
  }

  listings() {
    if (!this.state || !this.state.listings) { return []; }

    return this.state.listings;
  }

  render() {
    if (this.empty()) { return null; }

    return (
      <ListingRow
        listings={this.listings()}
        title={I18n.t('productShowPage.treatYourself')}
        trackName="RetargetListings"
        minListings={3}
      />
    );
  }
}

BumpRetargeting.propTypes = {
  listingId: PropTypes.string.isRequired,
};

export default BumpRetargeting;
