import accounting from 'accounting';

class Order {
  constructor(json) {
    this.json = json;
    this.insurableAmountUSD = json.insurable_amount_usd;
    this.declaredAmountUSD = json.insurable_amount_usd;
    this.id = json.order_number;
    this.title = json.title;
    this.buyerName = json.buyer_name;
    this.dateOrdered = json.date_ordered_mdy;
    this.productId = json.product_id;
    this.includedInShipment = json.included_in_shipment || false;
    this.defaultCountryOfOrigin = json.default_country_of_origin;
    this.customsDescription = json.customs_description;
    this.tariffNumber = json.tariff_number;
    this.categoryName = json.category_name;
    this.quantity = json.quantity;
    this.productTypeUUID = json.product_type_uuid;
    this.createdAt = json.created_at;
  }

  // eslint-disable-next-line
  get createdAtInUnixSeconds() {
    if (this.createdAtDate !== 'Invalid Date') {
      return this.createdAtDate.getTime() / 1000;
    }
  }

  get createdAtDate() {
    return new Date(this.createdAt);
  }

  setDeclaredAmountUSDCents(amountCents) {
    this.declaredAmountUSD.amount_cents = amountCents;
  }

  get insurableAmountUSDCents() {
    return this.insurableAmountUSD.amount_cents;
  }

  get declaredAmountUSDCents() {
    return this.declaredAmountUSD.amount_cents;
  }

  get insurableAmountUSDDollars() {
    return accounting.formatMoney(
      this.insurableAmountUSDCents / 100,
      {
        symbol: '',
        precision: 2,
      },
    );
  }
}

export default Order;
