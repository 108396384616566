import { ChildProps } from '@apollo/client/react/hoc';
// TODO update this to import from commons
// import { gql } from '@reverbdotcom/commons/src/gql';
// eslint-disable-next-line no-restricted-imports
import { gql } from '@apollo/client';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  PayStatementCountries,
  UpdateMyShopBillingMethod,
  core_apimessages_AddressEntry as IAddressEntry,
  core_apimessages_UpdateBillingMethodResponse,
} from '@reverbdotcom/commons/src/gql/graphql';
import { IUserContext } from '@reverbdotcom/commons/src/components/user_context_provider';
import { CardState } from '@reverbdotcom/commons/src/adyen_card_hook';
import { buildBillingCardAddress } from '../../credit_cards/card_data';
import { withGraphql } from '@reverbdotcom/commons/src/with_graphql';
import { buildUpdateBillingMethodMutationInput } from '../../billing/billing_method_operation_inputs';
import { connectUpdateMyShopBillingMethod } from '../billing_method_operations';
import { useHOCMutation, MutationFunction } from '@reverbdotcom/commons/src/useHOCMutation';
import { SCA_PAYMENT_STATUSES } from '../billing_method';
import BillingMethodSCAInteraction from '../billing_method_sca_interaction';
import AddNewCardForm from './add_new_card_form';
import { connect } from '@reverbdotcom/commons/src/connect';

export const myShopCountry = gql`
  fragment myShopCountry on rql_Me {
    _id
    uuid
    shop {
      _id
      originCountryCode
    }
  }
`;

const COUNTRIES_QUERY = gql`
  query PayStatementCountries {
    me {
      _id
      uuid
      ...myShopCountry
    }
    countries {
      countries {
        _id
        countryCode
        name
      }
    }
  }
  ${myShopCountry}
`;

const defaultBillingMethodResponse = (): core_apimessages_UpdateBillingMethodResponse => (
  { adyenPaymentResult: null, creditCard: null, billingMethodUuid: null }
);

interface MutationProps {
  updateMyShopBillingMethod: MutationFunction<UpdateMyShopBillingMethod.Mutation, UpdateMyShopBillingMethod.Variables>;
}

interface ExternalProps extends IUserContext {
  returnUrl: string;
  selectNewCreditCard(creditCardId: string): void;
  toggleDisplayNewCardForm(): void;
}

type IProps = MutationProps & ExternalProps;

type Props = ChildProps<IProps, PayStatementCountries.Query>;

export function AddNewCardContainer({
  data,
  returnUrl,
  selectNewCreditCard,
  toggleDisplayNewCardForm,
  updateMyShopBillingMethod,
}: Props) {
  const [is3DSRequired, setIs3DSRequired] = useState(false);

  const [saveBillingCard, { loading: isSaving, errors }] = useHOCMutation(updateMyShopBillingMethod);

  const [threeDSErrors, setThreeDSErrors] = useState([]);

  const [billingMethodResponse, setBillingMethodResponse] = useState(defaultBillingMethodResponse());

  const [tokenizedCreditCardData, setTokenizedCreditCardData] = useState({
    encryptedSecurityCode: '',
    encryptedExpiryMonth: '',
    encryptedExpiryYear: '',
    encryptedCardNumber: '',
  });

  const [cardholderName, setCardholderName] = useState('');

  const [billingCardAddress, setBillingCardAddress] = useState(buildBillingCardAddress());

  const onTokenizedFieldUpdate = useCallback((cardState: CardState) => {
    const { paymentMethod } = cardState.data;
    setTokenizedCreditCardData(tokenizedCreditCardData => ({
      ...tokenizedCreditCardData,
      ...paymentMethod,
    }));
  }, [setTokenizedCreditCardData]);

  const onBillingCardAddressUpdate = useCallback((updatedBillingCardAddress: IAddressEntry) => {
    setBillingCardAddress(billingCardAddress => ({
      ...billingCardAddress,
      ...updatedBillingCardAddress,
    }));
  }, [setBillingCardAddress]);

  // initialize billing address country to shop country when data first loads
  useEffect(() => {
    if (!data?.loading) {
      setBillingCardAddress({ ...buildBillingCardAddress(), countryCode: data.me.shop.originCountryCode });
    }
  }, [data]);

  const countries = useMemo(() => {
    return data?.loading ? [] : data.countries.countries;
  }, [data]);

  const isSubmitDisabled = useMemo(() => {
    return !(
      cardholderName &&
      billingCardAddress.countryCode &&
      billingCardAddress.postalCode &&
      billingCardAddress.region &&
      billingCardAddress.locality &&
      billingCardAddress.name &&
      billingCardAddress.streetAddress &&
      tokenizedCreditCardData.encryptedSecurityCode &&
      tokenizedCreditCardData.encryptedExpiryMonth &&
      tokenizedCreditCardData.encryptedExpiryYear &&
      tokenizedCreditCardData.encryptedCardNumber
    );
  }, [cardholderName, billingCardAddress, tokenizedCreditCardData]);

  const handle3DS2Success = useCallback((billingMethodResponse) => {
    selectNewCreditCard(billingMethodResponse.creditCard.id);
  }, [selectNewCreditCard]);

  const handleThreeDSErrors = useCallback((errors) => {
    setThreeDSErrors(errors);
    setIs3DSRequired(false);
  }, [setThreeDSErrors]);

  const displayErrors = useMemo(() => {
    return [...errors, ...threeDSErrors];
  }, [errors, threeDSErrors]);

  async function onSubmit() {
    const input = buildUpdateBillingMethodMutationInput({
      cardholderName,
      tokenizedCreditCardData,
      billingCardAddress,
      editingCard: false,
      selectedCreditCardId: null,
      useExistingCreditCard: false,
      returnUrl,
      partialPayment: true,
    });

    const result = await saveBillingCard({ variables: { input } });

    if (SCA_PAYMENT_STATUSES.includes(result.data?.updateMyShopBillingMethod?.adyenPaymentResult?.paymentStatus)) {
      setBillingMethodResponse(result.data.updateMyShopBillingMethod);
      setIs3DSRequired(true);
    } else if (result) {
      selectNewCreditCard(result.data.updateMyShopBillingMethod.creditCard.id);
    }
  }

  return (
    is3DSRequired ? (
      <BillingMethodSCAInteraction
        billingMethodResponse={billingMethodResponse}
        loggerTag="adyen.threeds2_pay_statement_now"
        onBillingMethodResponse={handle3DS2Success}
        onError={handleThreeDSErrors}
        partialPayment
      />
    ) : (
      <AddNewCardForm
        billingCardAddress={billingCardAddress}
        cardholderName={cardholderName}
        countries={countries}
        errors={displayErrors}
        isDataLoading={data?.loading}
        isSaving={isSaving}
        isSubmitDisabled={isSubmitDisabled}
        onSubmit={onSubmit}
        setBillingCardAddress={onBillingCardAddressUpdate}
        setCardholderName={setCardholderName}
        setCardState={onTokenizedFieldUpdate}
        toggleDisplayNewCardForm={toggleDisplayNewCardForm}
      />
    )
  );
}

const withQuery = withGraphql<any, PayStatementCountries.Query>(
  COUNTRIES_QUERY,
  {
    options: {
      ssr: false, // only rendered for logged in users after clicking a link to open a modal
    },
  },
);

export default connect<ExternalProps>([withQuery, connectUpdateMyShopBillingMethod])(AddNewCardContainer);
