import React from 'react';
import { Route } from 'react-router';

const path = 'my/feed/customize';

const FeedCustomizationRoute = (
  <Route
    key={path}
    path={path}
    getComponent={(_location, cb) => {
      import(
        /* webpackChunkName: 'feed' */
        '../components/discovery/feed/customization'
      ).then(m => cb(null, m.default));
    }}
  />
);

export default FeedCustomizationRoute;
