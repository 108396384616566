import React from 'react';
import I18n from 'i18n-js';

import { RCChip } from '@reverbdotcom/cadence/components';
import { parseAmountCents, formatNumber, MoneyInput } from '@reverbdotcom/commons/src/money';
import { core_apimessages_Money } from '@reverbdotcom/commons/src/gql/graphql';
import { MParticleEventName, trackEvent } from '@reverbdotcom/commons/src/elog/mparticle_tracker';
import { useViewTracking } from '@reverbdotcom/commons/src/use_tracking';

export const COMPONENT_NAME = 'QuickPriceEditPercentOffFilterChips';

export const PERCENTS_OFF = [
  5,
  10,
  15,
];
interface IExternalProps {
  listingId: string | number;
  originalPrice: core_apimessages_Money;
  currentPrice: core_apimessages_Money;
  updatePrice: React.Dispatch<any>;
}

export function calculateDiscountedPrice(price: MoneyInput, percent_off: number) {
  const percentage = (100 - percent_off) / 100.00;
  const discountedPriceCents = Math.round(percentage * price.amountCents);
  return parseAmountCents(discountedPriceCents, price.currency);
}

export default function QuickPriceEditPercentOffFilterChips({
  listingId,
  originalPrice,
  currentPrice,
  updatePrice,
}: IExternalProps) {

  useViewTracking({
    eventName: MParticleEventName.ComponentView,
    componentName: COMPONENT_NAME,
    listingId,
  }, true);

  return (
    <div className="quick-price-edit-percent-off-filter-chips">
      <div className="quick-price-edit-percent-off-filter-chips__label">
        {I18n.t('discovery.listingsManagement.quickPriceEdit.modal.percentOffFilterChips.label')}
      </div>
      <div className="quick-price-edit-percent-off-filter-chips__buttons">
        {PERCENTS_OFF.map((percentOff, idx) => {
          const calculatedDiscountedPrice = calculateDiscountedPrice(originalPrice, percentOff);
          const isSelected = currentPrice.amountCents === calculatedDiscountedPrice.amountCents;
          const discountedPriceAmount = formatNumber(Number(calculatedDiscountedPrice.amount));

          return (
            <RCChip
              key={idx}
              size="small"
              selected={isSelected}
              onClick={() => {
                if (isSelected) {
                  updatePrice(originalPrice.amount);
                } else {
                  trackEvent({
                    eventName: MParticleEventName.PercentOffFilterChipClicked,
                    componentName: COMPONENT_NAME,
                    listingId,
                    discountPercent: percentOff,
                  });

                  updatePrice(discountedPriceAmount);
                }
              }}
            >
              {I18n.t('discovery.listingsManagement.quickPriceEdit.modal.percentOffFilterChips.percentOff', { percentOff })}
            </RCChip>
          );
        })}
      </div>
    </div>
  );
}
