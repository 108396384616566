export const SHIPPING_STEP = 'shipping';
export const PAYMENT_STEP = 'payment';
export const REVIEW_STEP = 'review';
export const CONFIRMATION_STEP = 'confirmation';

export type CheckoutStep = 'shipping' | 'payment' | 'review' | 'confirmation';

const ALL_STEPS = [
  SHIPPING_STEP,
  PAYMENT_STEP,
  REVIEW_STEP,
  CONFIRMATION_STEP,
];

export function isShippingStep(step: CheckoutStep) {
  return step === SHIPPING_STEP;
}

export function isReviewStep(step: CheckoutStep) {
  return step === REVIEW_STEP;
}

export function isPaymentStep(step: CheckoutStep) {
  return step === PAYMENT_STEP;
}

export function isValidStep(value: string) {
  return ALL_STEPS.includes(value);
}
