import { ChildProps } from '@apollo/client/react/hoc';
// TODO update this to import from commons/src/gql
// eslint-disable-next-line no-restricted-imports
import { gql } from '@apollo/client';
import React from 'react';
import { CommonsShippingRegionFilter } from '../gql/graphql';
import qs from 'qs';
import { withRouter, WithRouterProps } from 'react-router';
import classNames from 'classnames';
import I18n from 'i18n-js';
import bind from '../bind';
import { Filter, FilterOption } from './search_filters';
import { changeStickyItemRegion } from '../change_sticky_item_region';
import { withUpdateURL, IUpdateURLProps } from './with_update_url';
import { MParticleEventName } from '../elog/mparticle_types';
import { trackEvent } from '../elog/mparticle_tracker';
import { withUserContext, IUserContext } from './user_context_provider';
import { withGraphql } from '../with_graphql';

function filterParamName(filter: Filter) {
  return filter.options[0].paramName;
}

function selectedOptions(filter: Filter): FilterOption[] {
  return filter.options.filter(opt => opt.selected);
}

function setDefaultState(filter: Filter) {
  const selected = selectedOptions(filter);
  if (selected) {
    return selected[0].optionValue;
  }

  return '';
}

interface IPlaceholderProps {
  onClick?: () => void;
  loading: boolean;
  name: string;
}

export function PlaceholderSelect(props: IPlaceholderProps) {
  return (
    <div>
      <span className={classNames(
        'facet__placeholder__value',
        { 'facet__placeholder__value--loading': props.loading },
      )}>
        {props.name}
      </span>
      <button
        className="facet__placeholder__button"
        onClick={props.loading ? null : props.onClick}
      >
        {I18n.t('commons.regionFilterOptions.loadPlaceholder')}
      </button>
    </div>
  );
}

interface IExternalProps {
  id: string;
  placeholderFilter: Filter;
  trackingQuery?: string;
}

interface IState {
  value: string;
}

type IProps =
  ChildProps<IExternalProps, CommonsShippingRegionFilter.Query> &
  WithRouterProps &
  IUpdateURLProps &
  IUserContext;

const ITEM_REGION_PARAM = 'item_region';

export class RegionFilterOptions extends React.Component<IProps, IState> {
  state = {
    value: setDefaultState(this.props.placeholderFilter),
  };

  @bind
  handleChange(evt) {
    this.setState({ value: evt.target.value }, () => {
      const paramName = filterParamName(this.props.placeholderFilter);

      const newLocation = this.props.updateUrl({
        location: this.props.location,
        newParams: { [paramName]: this.state.value },
      });

      const newUrl = `${newLocation.pathname}${qs.stringify(newLocation.query, { addQueryPrefix: true })}`;

      // This will only fire for ships_to and not item_region
      trackEvent({
        componentName: 'RegionFilterOptions',
        eventName: MParticleEventName.ClickedSearchFilter,
        displayStyle: this.state.value,
        selected: true,
        parent: paramName,
        query: this.props.trackingQuery,
      });

      if (paramName === ITEM_REGION_PARAM) {
        changeStickyItemRegion(this.state.value, newUrl);
      }

      this.props.router.push(newUrl);
    });
  }

  select(options: FilterOption[]) {
    return (
      <select
        className="facet__select"
        onChange={this.handleChange}
        value={this.state.value}
        id={this.props.id}
      >
        {
          options.map(option => (
            <option
              value={option.optionValue}
              key={option.optionValue}
            >
              {option.name}
            </option>
          ))
        }
      </select>
    );
  }

  render() {
    const filter: Filter = this.props.data?.shippingRegionFilter?.filter || this.props.placeholderFilter;

    return (
      <div className="styled-dropdown mb-0">
        {this.select(filter.options)}
      </div>
    );
  }
}

const connect = withGraphql<
Partial<IProps>,
CommonsShippingRegionFilter.Query,
CommonsShippingRegionFilter.Variables
>(
  gql`
    query Commons_ShippingRegionFilter(
      $paramName: String
      $selectedValues: [String]
    ) {
      shippingRegionFilter(
        input: {
          paramName: $paramName
          selectedValues: $selectedValues
        }
      ) {
        filter {
          name
          aggregationName
          widgetType
          options {
            count {
              value
            }
            name
            selected
            paramName
            setValues
            unsetValues
            all
            optionValue
          }
        }
      }
    }
  `, // Copying FlatFilter fragment to avoid circular import
  {
    options: ({ placeholderFilter }) => {
      const paramName = filterParamName(placeholderFilter);
      const selectedValues = selectedOptions(placeholderFilter)
        .map(opt => opt.optionValue);

      return {
        ssr: false,
        variables: { paramName, selectedValues },
      };
    },
  },
);

export default connect(withUserContext(withRouter(withUpdateURL(RegionFilterOptions))));
