export default {
  couponAvailable: 'You have a %{discountValue} off coupon.',
  couponDetails: 'Coupon Details',
  expiresAt: 'Coupon expires',
  seeDetails: 'See details',
  coupon: 'coupon',
  couponsAvailable: 'You have coupons available for this purchase.',
  applyCoupons: 'Apply coupons at checkout to get a discount.',
  viewCoupons: 'View coupons',
  eligibleCoupons: 'Eligible coupons',
  showDetails: 'See details',
  hideDetails: 'Hide details',
  gotIt: 'Got it',
  onlyEligibleItems: 'Only show coupon-eligible items',
};

