// TODO update this to import from commons
// import { gql } from '@reverbdotcom/commons/src/gql';
// eslint-disable-next-line no-restricted-imports
import { gql } from '@apollo/client';
import React, { useCallback } from 'react';
import I18n from 'i18n-js';
import { withRouter, WithRouterProps } from 'react-router';

import { withGraphql } from '@reverbdotcom/commons/src/with_graphql';
import { useHOCMutation, MutationFunction } from '@reverbdotcom/commons/src/useHOCMutation';
import { useHelpCenterArticleUrl } from '@reverbdotcom/commons/src/url_helpers';
import { ErrorList } from '@reverbdotcom/commons/src/ErrorList';
import { RCTextInput, RCCheckbox, RCSelect, RCAlertBox } from '@reverbdotcom/cadence/components';
import { connect } from '@reverbdotcom/commons/src/connect';

import { useCreditCardForm } from './credit_card_form_context';
import { CreditCardSummary } from './CreditCardSummary';
import AddressFormFields from './address_form_fields';
import { generateDropdownYears, MONTHS } from './../credit_cards/credit_card_helpers';

import {
  CoreCreditCardWalletUpdateMyCreditCard,
  Input_core_apimessages_UpdateMyCreditCardRequest as UpdateMyCreditCardRequest,
  core_apimessages_AddressEntry as AddressEntry,
  core_apimessages_Country as Country,
  Core_CreditCardWallet_UpdateMyCreditCardMutation,
  Core_CreditCardWallet_UpdateMyCreditCardMutationVariables,
  CreditCardEditFormFieldsFragment,
} from '@reverbdotcom/commons/src/gql/graphql';

type CreditCard = CreditCardEditFormFieldsFragment['creditCards'][0];

type UpdateMyCreditCardMutationFn = MutationFunction<
  Core_CreditCardWallet_UpdateMyCreditCardMutation,
  Core_CreditCardWallet_UpdateMyCreditCardMutationVariables
>;

interface MutationProps {
  updateMyCreditCard: UpdateMyCreditCardMutationFn;
}

interface ExternalProps {
  countries: Country[];
  creditCard: CreditCard;
}

type Props = ExternalProps & MutationProps & WithRouterProps;

const CREDIT_CARD_TROUBLESHOOTING_TIPS_ARTICLE_ID = '1500005385482';
const COMPONENT_NAME = 'CreditCardEditForm';

export function CreditCardEditForm(props: Props) {
  const form = useCreditCardForm();
  const selectedCard = props.creditCard;
  const cardAddress = selectedCard.address;

  const [saveAsDefault, setSaveAsDefault] = React.useState(selectedCard.primaryForCheckout);

  const [creditCard, setCreditCard] = React.useState({
    cardholderName: selectedCard.cardholderName,
    expirationMonth: selectedCard.expirationMonth,
    expirationYear: selectedCard.expirationYear,
    primaryForCheckout: saveAsDefault,
  });

  const [billingAddress, setBillingAddress] = React.useState<AddressEntry>({
    countryCode: cardAddress?.countryCode,
    name: cardAddress?.name,
    streetAddress: cardAddress?.streetAddress,
    extendedAddress: cardAddress?.extendedAddress,
    locality: cardAddress?.locality,
    region: cardAddress?.region,
    postalCode: cardAddress?.postalCode,
    phone: cardAddress?.phone,
  });

  const { updateMyCreditCard, errors } = useUpdateMyCreditCard(
    props.updateMyCreditCard,
  );

  async function onSubmit(e) {
    e.preventDefault();
    form.setLoading(true);

    const input = {
      id: selectedCard.id,
      creditCard: {
        ...creditCard,
        primaryForCheckout: saveAsDefault,
      },
      billingAddress,
    };

    const result = await updateMyCreditCard(input);

    if (result.data) {
      form.onSelect(selectedCard.id);
    } else {
      form.setLoading(false);
    }
  }

  function onChange(e) {
    setCreditCard({
      ...creditCard,
      [e.target.name]: e.target.value,
    });
  }

  const troubleshootingUrl = useHelpCenterArticleUrl(CREDIT_CARD_TROUBLESHOOTING_TIPS_ARTICLE_ID);
  const years = generateDropdownYears();

  return (
    <form id={form.id} onSubmit={onSubmit}>
      {errors.length > 0 && (
        <div className="mb-4">
          <RCAlertBox type="error">
            <ErrorList errors={errors} />
            <div className="mt-2">
              <a href={troubleshootingUrl}>
                {I18n.t('discovery.creditCardWallet.cardTroubleshooting')}
              </a>
            </div>
          </RCAlertBox>
        </div>
      )}
      <CreditCardSummary creditCard={props.creditCard} />
      <div className="g-container d-flex fx-dir-col mtb-4">
        <div className="d-flex fx-justify-between mb-2">
          <div className="fx-grow mr-4">
            <RCSelect
              label={I18n.t('discovery.creditCardWallet.editForm.expirationMonth')}
              name="expirationMonth"
              id="expirationMonth"
              value={creditCard.expirationMonth}
              onChange={onChange}
            >
              {MONTHS.map((month) => (
                <RCSelect.Option
                  key={month}
                  value={month}
                  label={month}
                />
              ))}
            </RCSelect>
          </div>
          <div className="fx-grow">
            <RCSelect
              label={I18n.t('discovery.creditCardWallet.editForm.expirationYear')}
              name="expirationYear"
              id="expirationYear"
              value={creditCard.expirationYear}
              onChange={onChange}
            >
              {years.map((year) => (
                <RCSelect.Option
                  key={year}
                  value={year}
                  label={year}
                />
              ))}
            </RCSelect>
          </div>
        </div>
        <RCTextInput
          label={I18n.t('discovery.creditCardWallet.editForm.cardholderName')}
          name="cardholderName"
          value={creditCard.cardholderName}
          id="cardholderName"
          type="text"
          placeholder={I18n.t('discovery.creditCardWallet.editForm.cardholderName')}
          onChange={onChange}
        />
      </div>
      <RCCheckbox
        id="credit-card-edit-billing"
        name="credit-card-edit-billing"
        checked={saveAsDefault}
        onChange={() => setSaveAsDefault(prev => !prev)}
        label={I18n.t('discovery.creditCardWallet.editForm.saveDefault')}
      />
      <>
        <div className="bdt-1 bd-color-primary mtb-4" />
        <AddressFormFields
          address={billingAddress}
          countries={props.countries}
          updateField={setBillingAddress}
          useCamelizedFieldsForRQL
          trackingComponentName={COMPONENT_NAME}
        />
      </>
    </form>
  );
}

export const CreditCardEditFormFragment = gql`
  fragment CreditCardEditFormFields on rql_Me {
    _id
    creditCards {
      id
      brand {
        type
        name
      }
      display {
        summary
        expirationDate
      }
      address {
        _id
        countryCode
        name
        streetAddress
        extendedAddress
        locality
        region
        postalCode
        phone
      }
      expirationMonth
      expirationYear
      cardholderName
      primaryForCheckout
    }
  }
`;

export function useUpdateMyCreditCard(
  updateMyCreditCard: UpdateMyCreditCardMutationFn,
) {
  const [mutate, { loading, errors }] = useHOCMutation(updateMyCreditCard);

  const updateMyCreditCardCallback = useCallback(async (input: UpdateMyCreditCardRequest) => {
    return mutate({
      variables: {
        input: {
          ...input,
        },
      },
    });
  }, [mutate]);

  return { updateMyCreditCard: updateMyCreditCardCallback, loading, errors };
}

export const UPDATE_MY_CREDIT_CARD_MUTATION = gql`
  mutation Core_CreditCardWallet_UpdateMyCreditCard(
      $input: Input_core_apimessages_UpdateMyCreditCardRequest
    ) {
      updateMyCreditCard(input: $input) {
        creditCard {
          id
          cardholderName
          primaryForCheckout
          display {
            summary
            expirationDate
          }
          address {
            _id
            name
            streetAddress
            extendedAddress
            locality
            region
            regionName
            postalCode
            phone
            countryCode
            country {
              _id
              countryCode
            }
          }
        }
      }
    }
`;

const withMutation = withGraphql<Props, CoreCreditCardWalletUpdateMyCreditCard.Mutation, CoreCreditCardWalletUpdateMyCreditCard.Variables>(
  UPDATE_MY_CREDIT_CARD_MUTATION,
  {
    name: 'updateMyCreditCard',
  },
);

export default connect<ExternalProps>([
  withRouter,
  withMutation,
])(CreditCardEditForm);
