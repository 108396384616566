import React from 'react';
import I18n from 'i18n-js';

import { core_apimessages_ShippingLabelPackage } from '@reverbdotcom/commons/src/gql/graphql';
import { RCTextInput } from '@reverbdotcom/cadence/components';

import { PackageAttribute } from './custom_package_inputs';
import {
  convertPoundsAndOuncesToPounds,
  convertPoundsToPoundsAndOunces,
  OZ_TO_LB,
  WeightUnit,
} from './shipping_label_package';

interface IProps {
  dimensionKey: 'length' | 'width' | 'height' | 'weight';
  measurementUnit: string;
  value: string;

  onUpdate(attributes: core_apimessages_ShippingLabelPackage);

  shippingPackage: core_apimessages_ShippingLabelPackage;
  roundUpUserInput?: boolean;
  disabled?: boolean;
}

// On the backend, we store weight data as a single float value (either in lb or kg). For imperial packages, this
// component lets users interact with lb + oz inputs, while continuing to do all bookkeeping as a single weight value.
function PoundsOuncesInputPair({ weightInPounds = 0, onUpdate, disabled }) {
  const { pounds: currentPounds = 0, ounces: currentOunces = 0 } = convertPoundsToPoundsAndOunces(weightInPounds);

  function onPoundsUpdate(incoming) {
    const newPoundsValue = Number(incoming);
    if (newPoundsValue >= 0) {
      onUpdate({ weight: convertPoundsAndOuncesToPounds(newPoundsValue, currentOunces).toString() });
    }
  }

  function onOuncesUpdate(incoming) {
    const newOuncesValue = Number(incoming);
    if (newOuncesValue >= 0 && newOuncesValue < OZ_TO_LB) {
      onUpdate({ weight: convertPoundsAndOuncesToPounds(currentPounds, newOuncesValue).toString() });
    }
  }

  return (
    <div className="d-flex fx-justify-between gap-4 fx-align-end">
      <div className="width-50">
        <RCTextInput
          value={String(currentPounds || '')}
          name={`${PackageAttribute.WEIGHT}_pounds`}
          onChange={(event) => onPoundsUpdate(event.target.value)}
          label={I18n.t('discovery.dashboard.selling.shippingLabels.package.weight')}
          suffixText={WeightUnit.POUNDS}
          disabled={disabled}
          type="number"
          inputMode="numeric"
          tag="required"
        />
      </div>
      <div className="width-50">
        <RCTextInput
          value={String(currentOunces || '')}
          name={`${PackageAttribute.WEIGHT}_ounces`}
          onChange={(event) => onOuncesUpdate(event.target.value)}
          label=""
          suffixText={WeightUnit.OUNCES}
          disabled={disabled}
          type="number"
          inputMode="numeric"
          required={currentPounds === 0 && currentOunces === 0}
        />
      </div>
    </div>
  );
}

export default function PackageInput({
  dimensionKey,
  measurementUnit,
  shippingPackage,
  value,
  onUpdate,
  roundUpUserInput = false,
  disabled = false,
}: IProps) {
  function buildPackageDimensionAttributes(inputAttributes): Partial<core_apimessages_ShippingLabelPackage> {
    const dimensionAttributes = {
      value: inputAttributes[dimensionKey],
      unit: measurementUnit,
    };
    const packageAttributes = {};
    packageAttributes[dimensionKey] = dimensionAttributes;
    return packageAttributes;
  }

  function updateShippingPackageDimensions(attributes) {
    const packageAttributes = buildPackageDimensionAttributes(attributes);
    onUpdate(
      {
        ...shippingPackage,
        ...packageAttributes,
        packageSizeSuggestionId: '',
      },
    );
  }

  function roundValue(event) {
    const targetValue = event.target.value;
    const attributes = { [dimensionKey]: Math.ceil(targetValue).toString() };

    updateShippingPackageDimensions(attributes);
  }

  if (dimensionKey === PackageAttribute.WEIGHT && measurementUnit === WeightUnit.POUNDS) {
    return (
      <PoundsOuncesInputPair
        weightInPounds={Number(value)}
        onUpdate={updateShippingPackageDimensions}
        disabled={disabled}
      />
    );
  }

  return (
    <RCTextInput
      value={value}
      name={dimensionKey}
      onChange={(event) => updateShippingPackageDimensions({ [dimensionKey]: event.target.value })}
      onBlur={roundUpUserInput ? roundValue : null}
      disabled={disabled}
      suffixText={measurementUnit}
      label={I18n.t(`discovery.dashboard.selling.shippingLabels.package.${dimensionKey}`)}
      type="number"
      inputMode="numeric"
      tag="required"
      required
    />
  );
}
