import { gql } from '@reverbdotcom/commons/src/gql';
import {
  CheckoutContinueButtonStateFields,
} from '@reverbdotcom/commons/src/gql/graphql';
import { useUser } from '@reverbdotcom/commons/src/user_hooks';
import { isCheckoutShipped } from '../../lib/checkoutShipping';

type Checkout = CheckoutContinueButtonStateFields.Fragment;

interface ContinueButtonState {
  disabled: boolean;
}

/**
 * Provides the UI button state for checkouts that require on-site information
 * before completing a Direct Checkout or Klarna payment. Those requirements include
 *
 * - Shipping address is complete
 * - Orders can be shipped
 * - Payment method is entered in the case of a credit card
 *
 * These requirements may be not directly applicable to digital wallets where user information
 * is added in a vendor-supplied component that lacks the need for this state control.
 */
export function useCheckoutContinueButtonState({ checkout, isOrderSummaryLoading }: {
  checkout: Checkout,
  isOrderSummaryLoading: boolean,
}): ContinueButtonState {
  const user = useUser();

  if (!checkout) {
    return {
      disabled: false,
    };
  }

  const isShippingAddressComplete = checkout.shippingAddress?.isComplete;
  const cannotBeShipped = checkout.orders.some(order => order.cannotBeShippedToAddress);
  const isIncompleteGuestUser = user.loggedOut && !checkout.guest?.isComplete;

  const canUserPlaceShippedOrder = isShippingAddressComplete;
  const canUserPlaceNonShippedOrder = !user.loggedOut || !isIncompleteGuestUser;

  const canUserPlaceOrder = isCheckoutShipped(checkout)
    ? canUserPlaceShippedOrder
    : canUserPlaceNonShippedOrder;

  const disabled = isOrderSummaryLoading || cannotBeShipped || isIncompleteGuestUser || !canUserPlaceOrder;

  return {
    disabled: !!disabled,
  };
}

export const CheckoutContinueButtonStateFragment = gql(`
  fragment CheckoutContinueButtonStateFields on Checkout {
    _id
    id
    shippingAddress {
      _id
      isComplete
    }
    paymentMethod {
      isComplete
    }
    orders {
      cannotBeShippedToAddress
      shippingMethod {
        type
      }
    }
    guest {
      isComplete
    }
  }
`);
