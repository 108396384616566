import { Core_Ad_Banner_WrapperQuery } from '../../gql/graphql';
import { AdClick, AdImpression, AdObserved, MParticleEventName } from '../../elog/mparticle_types';

type Ad = Core_Ad_Banner_WrapperQuery['adServe']['ad'];

interface AdZone {
  slug?: string | null;
}

export function adBannerMparticleData(
  ad: Ad,
  adZones?: AdZone[],
) {
  return {
    adCampaignUuid: ad?.adCampaignUuid,
    adGroupUuid: ad?.adGroupUuid,
    adGroupName: ad?.adGroupName,
    advertiserUuid: ad?.advertiserUuid,
    adId: ad?.uuid,
    adUuid: ad?.uuid,
    format: ad?.format,
    title: ad?.imageAdCreative?.title,
    adZoneSlugs: adZones?.map(adZone => adZone.slug).sort().join('|'), // alphabetized to provide consistent analytics
  };
}

// fired when a user "views" an Ad according to the IntersectionObserver API
export function buildAdObservedData(
  ad: Ad,
  componentName: string,
  adZones?: AdZone[],
): AdObserved {
  return ({
    ...adBannerMparticleData(ad, adZones),
    componentName,
    eventName: MParticleEventName.AdObserved,
  });
}

export function buildAdImpressionData(
  ad: Ad,
  componentName: string,
  adZones?: AdZone[],
): AdImpression {
  return ({
    ...adBannerMparticleData(ad, adZones),
    componentName,
    eventName: MParticleEventName.AdImpression,
  });
}

export function buildAdClickData(
  ad: Ad,
  componentName: string,
  adZones?: AdZone[],
): AdClick {
  return ({
    ...adBannerMparticleData(ad, adZones),
    componentName,
    eventName: MParticleEventName.AdClick,
  });
}
