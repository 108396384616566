import React from 'react';
import I18n from 'i18n-js';

import { RCButton, RCTextInput, RCFlex, RCToast } from '@reverbdotcom/cadence/components';
import { gql } from '@reverbdotcom/commons/src/gql';
import { useMutation } from '@reverbdotcom/commons/src/useMutation';
import { core_apimessages_UpdateListingRequest_UpdateType } from '@reverbdotcom/commons/src/gql/graphql';
import { LISTING_STATES } from '@reverbdotcom/commons/src/constants';
import { useViewTracking } from '@reverbdotcom/commons/src/use_tracking';
import { MParticleEventName } from '@reverbdotcom/commons/src/elog/mparticle_types';

import { SellerListing } from '../listings_management/sellerListingsCollectionReducer';
import SellerListingsCollectionContext from '../listings_management/SellerListingsCollectionContext';

interface Props {
  listing: SellerListing;
  inEditMode: boolean;
  onSuccess: () => void;
}

export const QUICK_INVENTORY_EDIT_MUTATION = gql(`
  mutation Core_QuickInventoryEdit_Listing($input: Input_core_apimessages_UpdateListingRequest) {
    crupdateListing(input: $input) {
      _id
      state
    }
  }
`);

export default function QuickInventoryEditInput({ listing, inEditMode, onSuccess }: Props) {
  const {
    useLazySellerListingsQuery,
  } = React.useContext(SellerListingsCollectionContext);

  const { refetchSellerListings, loading } = useLazySellerListingsQuery();
  const [updateListingInventory, { loading: mutationLoading }] = useMutation(QUICK_INVENTORY_EDIT_MUTATION, { fetchPolicy: 'no-cache' } );

  const [inventoryValue, setInventoryValue] = React.useState(listing.inventory);
  const [edited, setEdited] = React.useState(false);
  const [showSuccessToast, setShowSuccessToast] = React.useState(false);
  const [successToastCopy, setSuccessToastCopy] = React.useState('');
  const [showErrorToast, setShowErrorToast] = React.useState(false);

  useViewTracking({
    componentName: 'QuickInventoryEdit',
    eventName: MParticleEventName.ComponentView,
    listingId: listing.id,
  }, inEditMode);

  return (
    <div className="mt-half">
      {inEditMode && (
        <RCFlex align="center">
          <RCTextInput
            label={I18n.t('discovery.listingsManagement.quickInventoryEdit.input.label')}
            value={inventoryValue.toString()}
            onChange={(e) => {
              setInventoryValue(parseInt(e.target.value));
              setEdited(true);
            }}
            type="number"
          />

          <RCButton
            variant="transparent"
            onClick={async () => {
              if (edited) {
                const listingEntry = {
                  id: listing.id,
                  hasInventory: true,
                  inventory: inventoryValue,
                };

                const response = await updateListingInventory({
                  variables: {
                    input: {
                      updateType: core_apimessages_UpdateListingRequest_UpdateType.SPECIFIC_FIELDS,
                      fields: Object.keys(listingEntry),
                      listingEntry: listingEntry,
                    },
                  },
                });

                if (response?.data?.crupdateListing?._id) {
                  if (listing.state == LISTING_STATES.SOLD_OUT && response.data.crupdateListing.state == LISTING_STATES.LIVE) {
                    setSuccessToastCopy(I18n.t('discovery.listingsManagement.quickInventoryEdit.toasts.relisted'));
                  } else {
                    setSuccessToastCopy(I18n.t('discovery.listingsManagement.quickInventoryEdit.toasts.success'));
                  }

                  refetchSellerListings([listing.id]);
                  setShowSuccessToast(true);
                  setEdited(false);
                  onSuccess();
                } else {
                  setShowErrorToast(true);
                }
              } else {
                onSuccess();
              }
            }}
            disabled={loading || mutationLoading}
          >
            {listing.state == LISTING_STATES.SOLD_OUT && I18n.t('discovery.listingsManagement.quickInventoryEdit.button.saveAndRelist')}
            {listing.state != LISTING_STATES.SOLD_OUT && I18n.t('discovery.listingsManagement.quickInventoryEdit.button.save')}
          </RCButton>
        </RCFlex>
      )}

      <RCToast
        isOpen={showSuccessToast}
        onClose={() => { setShowSuccessToast(false); }}
        text={successToastCopy}
        theme="success"
      />

      <RCToast
        isOpen={showErrorToast}
        onClose={() => { setShowErrorToast(false); }}
        text={I18n.t('discovery.listingsManagement.quickInventoryEdit.toasts.error')}
        theme="error"
      />
    </div>
  );
}
