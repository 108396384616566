import React from 'react';

import { AffirmCallout } from '../AffirmCallout';

interface IProps {
  estimatedMonthlyPayment: string;
  financingProgramSlug: string;
  amountCents: number;
}

function AffirmEstimateCTA({
  estimatedMonthlyPayment,
  amountCents,
  financingProgramSlug,
}: IProps) {

  if (!estimatedMonthlyPayment) { return null; }

  return (
    <div className="affirm-estimate-cta">
      <AffirmCallout
        amountCents={amountCents}
        financingProgramSlug={financingProgramSlug}
        pageType="cart"
      />
    </div>
  );
}

export default AffirmEstimateCTA;
