import React from 'react';
import I18n from 'i18n-js';

interface Props {
  itemPlusShippingSubtotal: string;
}

function CondensedOrderSummary(props: Props) {
  return (
    <div className="condensed-order-summary__titleblock">
      <h2 className="condensed-order-summary__title">
        {I18n.t('discovery.paymentMethodSelection.orderSummary')}
      </h2>
      <div className="condensed-order-summary__subtotal-wrapper">
        <span>{I18n.t('discovery.paymentMethodSelection.subtotal')}</span>
        <span className="condensed-order-summary__subtotal-amount">{props.itemPlusShippingSubtotal}</span>
      </div>
    </div>
  );
}

export default CondensedOrderSummary;
