import { IAssignmentLogger } from '@eppo/js-client-sdk';
import { elog } from '@reverbdotcom/commons/src/elog';
import { mParticleReady } from '@reverbdotcom/commons/src/elog/mparticle';
import { EventType } from '@reverbdotcom/commons/src/elog/mparticle_types';
import windowWrapper from '@reverbdotcom/commons/src/window_wrapper';

export enum MPARTICLE_CUSTOM_EVENT_ATTRIBUTES {
  EXPERIMENT = 'Experiment',
  SUBJECT = 'Subject',
  VARIATION = 'Variation',
  TIMESTAMP = 'Timestamp',
  FEATURE_FLAG = 'Feature Flag',
  ALLOCATION = 'Allocation',
  SUBJECT_TYPE = 'Subject Type',
  SHIPPING_REGION = 'Shipping Region',
  PLATFORM = 'Platform',
  PLATFORM_VERSION = 'Platform Version',
  APP_VERSION = 'App Version',
  MPID = 'Mpid',
  SESSION_ID = 'Session Id',
  DEVICE_ID = 'Device Id',
  REVERB_ADMIN = 'Reverb Admin',
  EMAIL = 'Email',
  COOKIE_ID = 'Cookie Id',
  REVERB_SESSION_ID = 'Reverb Session Id',
}

// Define logAssignment so that it logs events to mParticle
export const assignmentLogger: IAssignmentLogger = {
  logAssignment(assignment) {
    try {
      mParticleReady(
        () => {
          const eventAttributes = mParticleCustomEventAttributes(assignment);

          windowWrapper.mParticle.logEvent(
            'Eppo Randomization Event',
            EventType.Other,
            eventAttributes,
          );
        },
      );
    } catch (error) {
      elog.error('Error logging assignment to mParticle', {
        error: error.message,
        assignment,
      });
    }
  },
};

export function mParticleCustomEventAttributes(assignment) {
  return {
    [MPARTICLE_CUSTOM_EVENT_ATTRIBUTES.EXPERIMENT]: assignment.experiment,
    [MPARTICLE_CUSTOM_EVENT_ATTRIBUTES.SUBJECT]: assignment.subject,
    [MPARTICLE_CUSTOM_EVENT_ATTRIBUTES.VARIATION]: assignment.variation,
    [MPARTICLE_CUSTOM_EVENT_ATTRIBUTES.TIMESTAMP]: assignment.timestamp,
    [MPARTICLE_CUSTOM_EVENT_ATTRIBUTES.FEATURE_FLAG]: assignment.featureFlag,
    [MPARTICLE_CUSTOM_EVENT_ATTRIBUTES.ALLOCATION]: assignment.allocation,
    [MPARTICLE_CUSTOM_EVENT_ATTRIBUTES.SUBJECT_TYPE]: assignment.subjectAttributes.subject_type,
    [MPARTICLE_CUSTOM_EVENT_ATTRIBUTES.SHIPPING_REGION]: assignment.subjectAttributes.shipping_region,
    [MPARTICLE_CUSTOM_EVENT_ATTRIBUTES.PLATFORM]: assignment.subjectAttributes.platform,
    [MPARTICLE_CUSTOM_EVENT_ATTRIBUTES.PLATFORM_VERSION]: assignment.subjectAttributes.platform_version,
    [MPARTICLE_CUSTOM_EVENT_ATTRIBUTES.APP_VERSION]: assignment.subjectAttributes.app_version,
    [MPARTICLE_CUSTOM_EVENT_ATTRIBUTES.MPID]: assignment.subjectAttributes.mpid,
    [MPARTICLE_CUSTOM_EVENT_ATTRIBUTES.SESSION_ID]: assignment.subjectAttributes.session_id,
    [MPARTICLE_CUSTOM_EVENT_ATTRIBUTES.DEVICE_ID]: assignment.subjectAttributes.device_id,
    [MPARTICLE_CUSTOM_EVENT_ATTRIBUTES.REVERB_ADMIN]: assignment.subjectAttributes.ra,
    [MPARTICLE_CUSTOM_EVENT_ATTRIBUTES.EMAIL]: assignment.subjectAttributes.email,
    [MPARTICLE_CUSTOM_EVENT_ATTRIBUTES.COOKIE_ID]: assignment.subjectAttributes.cookie_id,
    [MPARTICLE_CUSTOM_EVENT_ATTRIBUTES.REVERB_SESSION_ID]: assignment.subjectAttributes.session_id,
  };
}
