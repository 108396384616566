import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import PayoutMethodOptions from '../dashboard/selling/shop_policies/payout_method_options';
import UrlHelpers from '../shared/url_helpers';
import I18n from 'i18n-js';

function DirectCheckoutProfileSetup(props) {
  function profileContainerClass() {
    return classNames(
      'dc_form',
      { 'global-slidedown--open': props.selected },
      { 'global-slidedown--closed': !props.selected },
    );
  }

  function firstOrderPayoutHtml() {
    return (
      I18n.t('js.shopPolicies.directCheckoutSetup.firstOrderPayoutHtml', {
        strongOpen: '<strong>',
        strongClose: '</strong>',
        lineBreak: '<br/>',
        linkOpen: `<a href="${UrlHelpers.reverbPaymentsFaqPath}" target="blank" class="muted underlined" tabindex="-1">`,
        linkClose: '</a>',
      })
    );
  }

  return (
    <div
      className={profileContainerClass()}
      data-direct-checkout-profile-form
    >
      <PayoutMethodOptions
        {...props}
        modularFormContainerClass="plr-0 pb-0"
      />
      <div className="plr-0 pb-0">
        <p
          className="fine-print tight-line-height mb-0"
          dangerouslySetInnerHTML={{ __html: firstOrderPayoutHtml() }}
        />
      </div>
    </div>
  );
}

DirectCheckoutProfileSetup.propTypes = {
  profileAttributes: PropTypes.object.isRequired,
  payoutCurrencies: PropTypes.object.isRequired,
  countryCodeOptions: PropTypes.array.isRequired,
  formPrefix: PropTypes.string.isRequired,
  selected: PropTypes.bool,
  shopCurrency: PropTypes.string.isRequired,
  onCountryChange: PropTypes.func.isRequired,
  onCurrencyChange: PropTypes.func.isRequired,
  plaidProps: PropTypes.object,
  shopCountryCode: PropTypes.string.isRequired,
};

export default DirectCheckoutProfileSetup;
