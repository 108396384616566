import React from 'react';
import { Route } from '@reverbdotcom/commons/src/route_settings';
import { MParticlePageName } from '@reverbdotcom/commons/src/elog/mparticle_tracker';

const RecentlyViewedRoute = (
  <Route
    key="browsing_history"
    path="browsing_history"
    props={{
      clientSide: true,
      pageViewEvent: {
        pageName: MParticlePageName.RecentlyViewed,
      },
    }}
    getComponent={(_location, cb) => {
      import(
        '../components/recently_viewed/recently_viewed_container'
      ).then(m => cb(null, m.default));
    }}
  />
);

export default RecentlyViewedRoute;
