import React from 'react';
import {
  core_apimessages_Country as ICountry,
} from '@reverbdotcom/commons/src/gql/graphql';
import I18n from 'i18n-js';

interface IProps {
  id?: string;
  name?: string;
  countries: ICountry[];
  value: string;
  countryCode: string;
  onChange: Function;
  disabled?: boolean;
  required: boolean;
}

const regionsForSelectedCountryCode = (props) => {
  if (props.countries.length === 0 || !props.countryCode) {
    return [];
  }

  const selectedCountry = props.countries.find((country) => {
    return country.countryCode === props.countryCode;
  });

  if (!selectedCountry) {
    return [];
  }

  return selectedCountry.subregions;
};

function mapPropsToRegionCode(props: IProps): string {
  const regionNameOrCode = props.value;

  const country = props.countries.find(c => c.countryCode === props.countryCode);

  const region = country.subregions.find(r => r.code === regionNameOrCode || r.name === regionNameOrCode);

  return region?.code || '';
}

const renderRegionTextInput = (props) => {
  return (
    <input
      type="text"
      name={props.name}
      value={props.value}
      onChange={props.onChange}
      disabled={props.disabled}
      required
    />
  );
};

const renderEmptyOption = () => {
  return (
    <option value="" key="empty">
      {I18n.t('discovery.onboardingAddressForm.select')}
    </option>
  );
};

const renderRegionSelect = (props) => {
  const options = regionsForSelectedCountryCode(props).map((region) => {
    return (
      <option
        value={region.code}
        key={region.code}
      >
        {region.name}
      </option>
    );
  });

  options.unshift(renderEmptyOption());

  return (
    <div className="styled-dropdown">
      <select
        id={props.id}
        name={props.name}
        onChange={props.onChange}
        value={mapPropsToRegionCode(props)}
        disabled={props.disabled}
        required={props.required}
      >
        {options}
      </select>
    </div>
  );
};

const RegionSelect: React.StatelessComponent<IProps> = (props) => {
  if (regionsForSelectedCountryCode(props).length === 0) {
    return renderRegionTextInput(props);
  }

  return renderRegionSelect(props);
};

RegionSelect.defaultProps = {
  name: 'region',
};

export default RegionSelect;
