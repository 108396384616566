import { ChildProps } from '@apollo/client/react/hoc';
// TODO update this to import from commons
// import { gql } from '@reverbdotcom/commons/src/gql';
// eslint-disable-next-line no-restricted-imports
import { gql } from '@apollo/client';
/* eslint-disable no-restricted-globals */
import React from 'react';
import {
  core_apimessages_PacklinkAccount_Status as PacklinkAccountStatus,
  CorePacklinkShippingLabelButton,
} from '@reverbdotcom/commons/src/gql/graphql';
import PacklinkCreateDraft from './packlink_create_draft';
import PacklinkAuthForm from './packlink_auth_form';
import ContinueToPacklink from './packlink_continue_to_packlink';
import { RCLoadingBars } from '@reverbdotcom/cadence/components';
import { withGraphql } from '@reverbdotcom/commons/src/with_graphql';

interface ExternalProps {
  orderId?: string;
  onAuthSuccess?(): void;
  onDraftSuccess?(): void;
}

type Props = ChildProps<ExternalProps, CorePacklinkShippingLabelButton.Query>;

export function PacklinkAuthorizeAccountOrCreateDraft(props: Props) {
  const { data } = props;
  const shop = data?.me?.shop;
  const order = data?.order;

  const hasActiveAccount = shop?.packlink?.account?.status === PacklinkAccountStatus.COMPLETED;

  function onAuthSuccess() {
    props.data.refetch();
    props.onAuthSuccess();
  }

  function onDraftSuccess() {
    props.data.refetch();
    props.onDraftSuccess();
  }

  if (!data || data.loading) {
    return <LoadingData />;
  }

  if (order?.packlinkShipment?.reference) {
    return <ContinueToPacklink onDraftSuccess={onDraftSuccess} />;
  }

  if (hasActiveAccount && order?.legacyOrderId) {
    return (
      <PacklinkCreateDraft
        order={order}
        onDraftSuccess={onDraftSuccess}
        startPolling={() => props.data?.startPolling(500)}
        stopPolling={props.data?.stopPolling}
      />
    );
  }
  return <PacklinkAuthForm onAuthSuccess={onAuthSuccess} />;
}

export const OrderPacklinkShipmentFragment = gql`
  fragment OrderPacklinkShipmentData on Order {
    _id
    legacyOrderId
    packlinkShipment {
      reference
      status
    }
  }
`;

export const MyShopPacklinkAccountFragment = gql`
  fragment MyShopPacklinkAccountData on rql_Me {
    _id
    uuid
    shop {
      _id
      packlink {
        account {
          status
        }
      }
    }
  }
`;

const withQuery = withGraphql<ExternalProps, {}>(
  gql`
  query Core_Packlink_ShippingLabelButton($orderId: String, $authOnly: Boolean!) {
    order(input: { id: $orderId }) @skip(if: $authOnly) {
      _id
      ...OrderPacklinkShipmentData
    }
    me {
      _id
      ...MyShopPacklinkAccountData
    }
  }
  ${OrderPacklinkShipmentFragment}
  ${MyShopPacklinkAccountFragment}
  `,
  {
    options: (ownProps) => {
      return {
        errorPolicy: 'all',
        ssr: false, // only rendered for logged in users after clicking a link to open a modal
        variables: {
          orderId: ownProps.orderId,
          authOnly: !ownProps.orderId,
        },
      };
    },
  },
);

function LoadingData() {
  return (
    <div className="bg-white mlr-4">
      <div className="cms-text">
        <div className="d-flex fx-align-center fx-justify-center height-vh-10 ptb-8">
          <RCLoadingBars />
        </div>
      </div>
    </div>
  );
}

export default withQuery(PacklinkAuthorizeAccountOrCreateDraft);
