import { invert } from 'lodash';

const URL_TO_RQL_PARAM = {
  sort: 'sort_slug',
  make: 'brand_slugs',
  condition: 'condition_slugs',
  zero_percent_financing: 'accepts_payment_plans',
  ships_to: 'shipping_region_codes',
};

const RQL_TO_URL_PARAM = invert(URL_TO_RQL_PARAM);

export function renameRqlParam(paramName: string): string {
  const newParam = RQL_TO_URL_PARAM[paramName] || paramName;

  return newParam;
}
