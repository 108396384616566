import React from 'react';
import { Route } from 'react-router';
import ErrorBoundary from '@reverbdotcom/commons/src/components/error_boundary';

import {
  ITEM_SHOW_ROUTE_PATH,
  ITEM_PREVIEW_PATH,
  ITEM_PREVIEW_CONFIRM_PATH,
} from '../components/shared/constants';

function removeSlugFromId(props) {
  const cleaned = {
    ...props.routeParams,
    id: props.routeParams.id.split('-')[0],
  };

  return { ...props, routeParams: cleaned };
}

function getItemShow(_location, cb) {
  import(
    /* webpackChunkName: 'item-show' */
    '../components/item2_layout'
  ).then((m) => {
    const Item2Layout = m.default;

    cb(null, (props) => (
      <ErrorBoundary>
        <Item2Layout {...removeSlugFromId(props)} />
      </ErrorBoundary>
    ));
  });
}

export const ItemShowRoute = (
  <Route
    key={ITEM_SHOW_ROUTE_PATH}
    path={ITEM_SHOW_ROUTE_PATH}
    props={{ clientSide: false }}
    getComponent={getItemShow}
  />
);

export const ItemPreviewRoute = (
  <Route
    key={ITEM_PREVIEW_PATH}
    path={ITEM_PREVIEW_PATH}
    getComponent={getItemShow}
  />
);

export const ItemPreviewConfirmRoute = (
  <Route
    key={ITEM_PREVIEW_CONFIRM_PATH}
    path={ITEM_PREVIEW_CONFIRM_PATH}
    getComponent={getItemShow}
  />
);
