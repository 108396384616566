import {
  core_apimessages_ImageEntry,
  core_apimessages_Listing_SeedType,
  HydrateSellForm,
  SellFormCanonicalProductCardData,
  core_apimessages_PreorderInfoEntry,
  core_apimessages_PreorderInfoEntry_TimeType,
  core_apimessages_PreorderInfoEntry_LeadTimeUnit,
  core_apimessages_ScheduledPriceDropEntry,
  core_apimessages_ShippingLabelPackage,
} from '@reverbdotcom/commons/src/gql/graphql';

export interface ISellFormState {
  dirty: boolean;
  listingEntry: IListingEntryState;
}

export interface IListingEntryState {
  [Field.ID]: string;
  [Field.SKU]: string;
  [Field.YEAR]: string,
  [Field.COUNTRY]: string;
  [Field.SEED_ID]: string;
  [Field.SEED_TYPE]: core_apimessages_Listing_SeedType;
  [Field.ROOT_CATEGORY_UUID]: string;
  [Field.SUBCATEGORY_LEAF_UUIDS]: string[];
  [Field.LOCALIZED_CONTENTS]: ILocalizedContentsState[];
  [Field.HANDMADE]: boolean;
  [Field.TRAITS]: {
    [Field.TRAIT_UUID]: string;
    [Field.TRAIT_VALUE_UUID]: string;
  }[];
  [Field.CONDITION_SLUG]: string;
  [Field.HAS_INVENTORY]: boolean;
  [Field.INVENTORY]: number;
  [Field.UPC]: string;
  [Field.UPC_DOES_NOT_APPLY]: boolean;
  [Field.VIDEO_LINK_URL]: string;
  [Field.PHOTOS]: core_apimessages_ImageEntry[];
  [Field.SHIPPING_PROFILE_ID]: string;
  [Field.OFFERS_LOCAL_PICKUP]: boolean;
  [Field.SHIPPING_RATES]: IShippingRateObjectState[];
  [Field.CARRIER_CALCULATED_PACKAGE]: core_apimessages_ShippingLabelPackage;
  [Field.PREFERS_REVERB_SHIPPING_LABEL]: boolean;
  [Field.PRICE]?: IMoneyFieldState;
  [Field.SELLER_COST]?: IMoneyFieldState;
  [Field.SELLER_REPORTED_MAP]?: IMoneyFieldState;
  [Field.SOLD_AS_IS]: boolean;
  [Field.OFFERS_ENABLED]: boolean;
  [Field.PREORDER_INFO]?: core_apimessages_PreorderInfoEntry;
  [Field.TAX_EXEMPT]?: boolean;
  [Field.SCHEDULED_PRICE_DROPS]: core_apimessages_ScheduledPriceDropEntry[];
}

export interface IAction {
  type: ActionType;
  fieldKey?: string;
  value?: any;
  forLocale?: string;
  backendListing?: HydrateSellForm.Fragment;
  shippingRates?: IShippingRateObjectState[];
  csp?: SellFormCanonicalProductCardData.Fragment;
  updateSeed?: boolean;
}

export enum ActionType {
  UPDATE_FIELD = 'updateField',
  UPDATE_LOCALIZED_FIELD = 'updateLocalizedContentField',
  HANDLE_SUBMIT_SUCCESS = 'handleSubmit',
  SET_TRAIT = 'setTrait',
  CLEAR_TRAITS = 'clearTraits',
  UPDATE_SELECTED_VARIANT = 'updateSelectedVariant',
  SET_DEFAULT_SHIPPING = 'setDefaultShipping',
  SET_NO_SHIPPING = 'setNoShipping',
  SET_SHIPPING_PROFILE_ID = 'setShippingProfileId',
  ACTIVATE_CARRIER_CALCULATED_MODE = 'activateCarrierCalculatedMode',
  DEACTIVATE_CARRIER_CALCULATED_MODE = 'deactivateCarrierCalculatedMode',
  SET_HAS_INVENTORY = 'setHasInventory',
  SET_CONDITION_SLUG = 'setConditionSlug',
  SET_UPC_DOES_NOT_APPLY = 'setUpcDoesNotApply',
  SET_PREORDER_INFO_FIELD = 'setPreorderInfoField',
  SET_PREORDER_TIME_TYPE = 'setPreorderTimeType',
  TOGGLE_SUPPORTED_LOCALE_CONTENT = 'toggleSupportedLocaleContent',
  SET_SCHEDULED_PRICE_DROP_ACTIVE = 'setScheduledPriceDropActive',
  SET_SCHEDULED_PRICE_DROP_NEW_PRICE = 'setScheduledPriceDropNewPrice',
}

export enum Field {
  // Listing attributes
  ID = 'id',
  SKU = 'sku',
  YEAR = 'year',
  COUNTRY = 'countryOfOrigin',
  SEED_ID = 'seedId',
  SEED_TYPE = 'seedType',
  ROOT_CATEGORY_UUID = 'categoryRootUuid',
  SUBCATEGORY_LEAF_UUIDS = 'categoryUuids',
  LOCALIZED_CONTENTS = 'localizedContents',
  HANDMADE = 'handmade',
  TRAITS = 'traits',
  CONDITION_SLUG = 'conditionSlug',
  HAS_INVENTORY = 'hasInventory',
  INVENTORY = 'inventory',
  UPC = 'upc',
  UPC_DOES_NOT_APPLY = 'upcDoesNotApply',
  VIDEO_LINK_URL = 'videoLinkUrl',
  PHOTOS = 'photos',
  SHIPPING_PROFILE_ID = 'shippingProfileId',
  OFFERS_LOCAL_PICKUP = 'offersLocalPickup',
  SHIPPING_RATES = 'shippingRates',
  CARRIER_CALCULATED_PACKAGE = 'carrierCalculatedPackage',
  PREFERS_REVERB_SHIPPING_LABEL = 'prefersReverbShippingLabel',
  PRICE = 'price',
  SELLER_COST = 'sellerCost',
  SELLER_REPORTED_MAP = 'sellerReportedMap',
  SOLD_AS_IS = 'soldAsIs',
  OFFERS_ENABLED = 'offersEnabled',
  TAX_EXEMPT = 'taxExempt',

  // Localized content attributes
  LOCALE = 'locale',
  TITLE = 'title',
  MAKE = 'make',
  MODEL = 'model',
  FINISH = 'finish',
  DESCRIPTION = 'description',
  PROP_65_WARNING = 'prop65Warning',
  DEFAULT_FOR_SHOP = 'defaultForShop',

  // Preorder Info
  PREORDER_INFO = 'preorderInfo',
  PREORDER_INFO_TIME_TYPE = 'timeType',
  PREORDER_INFO_LEAD_TIME_UNIT = 'leadTimeUnit',
  PREORDER_INFO_SHIP_DATE = 'shipDate',
  PREORDER_INFO_LEAD_TIME_DAYS = 'leadTimeDays',

  // Trait attributes
  TRAIT_UUID = 'keyId',
  TRAIT_VALUE_UUID = 'valueId',

  // Scheduled price drop attributes
  SCHEDULED_PRICE_DROPS = 'scheduledPriceDrops',
  SCHEDULED_PRICE_DROP_ACTIVE = 'active',
  SCHEDULED_PRICE_DROP_NEW_PRICE = 'newPrice',
}

export const EMPTY_PREORDER_INFO: core_apimessages_PreorderInfoEntry = {
  [Field.PREORDER_INFO_LEAD_TIME_DAYS]: 0,
  [Field.PREORDER_INFO_LEAD_TIME_UNIT]: core_apimessages_PreorderInfoEntry_LeadTimeUnit.DAYS,
  [Field.PREORDER_INFO_TIME_TYPE]: core_apimessages_PreorderInfoEntry_TimeType.SHIP_DATE,
  [Field.PREORDER_INFO_SHIP_DATE]: '',
};

export interface ILocalizedContentsState {
  [Field.LOCALE]: string;
  [Field.TITLE]: string;
  [Field.MAKE]: string;
  [Field.MODEL]: string;
  [Field.FINISH]: string,
  [Field.DESCRIPTION]: string;
  [Field.PROP_65_WARNING]: string;
  [Field.DEFAULT_FOR_SHOP]: boolean;
}

export interface IMoneyFieldState {
  amount: string;
}

export interface IShippingRateObjectState {
  regionCode: string;
  rate: IMoneyFieldState;
  incrementalRate: IMoneyFieldState;
  expeditedRate: IMoneyFieldState;
  rateType: string;
}
