import React from 'react';
import I18n from 'i18n-js';
import URLHelpers from '../shared/url_helpers';
import bind from '@reverbdotcom/commons/src/bind';
import Listing from './listing';
import { V3 } from '../api_request';
import classNames from 'classnames';

interface Props {
  listing: Listing;
}

interface State {
  watching: boolean;
}

export default class WatchBadge extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      watching: props.listing.watching,
    };
  }

  watch() {
    V3.post(URLHelpers.watchProductPath(this.props.listing.id))
      .fail(() => this.setState({ watching: false }));

    this.setState({ watching: true });
  }

  unwatch() {
    V3.destroy(URLHelpers.unwatchProductPath(this.props.listing.id))
      .fail(() => this.setState({ watching: true }));

    this.setState({ watching: false });
  }

  @bind
  handleClick() {
    if (this.state.watching) {
      this.unwatch();
    } else {
      this.watch();
    }
  }

  render() {
    const classes = classNames(
      'watch-badge',
      { 'watch-badge--watching': this.state.watching },
    );

    const title = this.state.watching ?
      I18n.t('discovery.watchBadge.unwatch') : I18n.t('discovery.watchBadge.watch');

    return (
      <div
        className={classes}
      >
        <button
          onClick={this.handleClick}
          className="watch-badge__button"
          title={title}
          type="button"
        >
          <span className="watch-badge__icon" />
        </button>
      </div>
    );
  }
}
