import React from 'react';
import qs from 'qs';

import { FacebookIcon } from '@reverbdotcom/cadence/icons/react';
import { RCTextWithIcon, RCButton } from '@reverbdotcom/cadence/components';

import CsrfForm from '../csrf_form';
import { FACEBOOK_AUTH_PATH } from '../url_helpers';
import { titleText, Variant } from './thirdPartyAuth';
import { preferLightTheme } from '@reverbdotcom/cadence/components/helpers/themeOverrides';

interface Props {
  redirectTo: string;
  variant: Variant;
}

const formId = 'facebook-signup-signin';

export default function FacebookSigninButton({ redirectTo, variant }: Props) {
  const query = qs.stringify({ redirect_to: redirectTo });
  const title = titleText({ provider: 'facebook', variant });

  return (
    <CsrfForm
      id={formId}
      method="post"
      action={`${FACEBOOK_AUTH_PATH}?${query}`}
    >
      <div className="signup-signin__form__facebook__button" {...preferLightTheme()}>
        <RCButton
          isSubmit
          form={formId}
          variant="filled"
          fullWidth
        >
          <RCTextWithIcon
            svgComponent={FacebookIcon}
            placement="left"
            size="large"
          >
            {title}
          </RCTextWithIcon>
        </RCButton>
      </div>
    </CsrfForm>
  );
}
