import React from 'react';
import Helmet from 'react-helmet';
import { useExpEnabled } from '@reverbdotcom/commons/src/user_hooks';
import experiments from '@reverbdotcom/commons/src/experiments';
import { ACCERTIFY_DEVICE_JS_URL } from '@reverbdotcom/env';

export function AccertifyDeviceScript() {
  const inAccertifyDeviceJsRollout = useExpEnabled(experiments.LOAD_ACCERTIFY_DEVICE_JS);

  if (!inAccertifyDeviceJsRollout || !ACCERTIFY_DEVICE_JS_URL) {
    return null;
  }

  return (
    <Helmet>
      {/* satisfy accertify requirement to include dvc= attribute */}
      {/* @ts-ignore */}
      <script type="text/javascript" src={ACCERTIFY_DEVICE_JS_URL} id="bcn" dvc="a" async={true} />
    </Helmet>
  );
}
