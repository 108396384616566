import PropTypes from 'prop-types';
import React from 'react';
import ShopData from './shop_data';
import TimePeriods from './time_periods';
import ErrorMessage from '../../shared/error_message';
import I18n from 'i18n-js';

function multiCurrencyHint(statsData) {
  if (!statsData.salesInMoreThanOneCurrency) { return null; }

  return (
    <p className="hint">
      {I18n.t('shopStats.currencyChangeNote', { currency: statsData.currency })}
    </p>
  );
}

const StatsDisplay = ({
  sellerGraph,
  timePeriods,
  timePeriodChanged,
  statsData,
  salesStats,
  error,
}) => {
  if (error) { return <ErrorMessage />; }

  return (
    <section className="site-module">
      <div className="site-module__header">
        <div className="d-flex fx-align-center fx-justify-between">
          <h3 className="site-module__title">
            {I18n.t('shopStats.mySales')}
            <span className="muted hidden-mobile ml-1">- {timePeriods.title}</span>
          </h3>
          <div className="styled-dropdown mb-0">
            <select
              onChange={e => timePeriodChanged(e.target.value)}
              value={timePeriods.timePeriodString}
            >
              {timePeriods.options.map(option =>
                <option key={option.value} value={option.value}>{option.display}</option>)}
            </select>
          </div>
        </div>
      </div>
      <div className="site-module__content">
        {sellerGraph}
        {salesStats}
        {multiCurrencyHint(statsData)}
      </div>
    </section>
  );
};

StatsDisplay.propTypes = {
  sellerGraph: PropTypes.element,
  timePeriods: PropTypes.instanceOf(TimePeriods),
  timePeriodChanged: PropTypes.func.isRequired,
  salesStats: PropTypes.element,
  statsData: PropTypes.instanceOf(ShopData).isRequired,
  error: PropTypes.bool,
};

export default StatsDisplay;
