import React from 'react';
import I18n from 'i18n-js';
import SignupSigninModal from './signup_signin_modal';
import { RCButton, RCAlertBox } from '@reverbdotcom/cadence/components';
import { Paths } from '../url_helpers';

interface Props {
  email: string;
  orderBundleUuid: string;
}

export default function ClaimOrderBanner(props: Props) {
  const [modalOpen, setModalOpen] = React.useState(false);

  return (
    <RCAlertBox type="info">
      <div className="d-flex fx-dir-row fx-justify-between fx-align-center mobile-fx-dir-col mobile-fx-align-start">
        <div className="mobile-mb-2">
          {I18n.t('discovery.checkout.confirmationPage.claimOrderBannerText')}
          <div className="size-fixed-90">
            {props.email}
          </div>
        </div>
        <RCButton
          variant="filled"
          onClick={() => setModalOpen(true)}
        >
          {I18n.t('discovery.checkout.confirmationPage.claimOrderButtonText')}
        </RCButton>
        <SignupSigninModal
          active={modalOpen}
          onRequestClose={() => setModalOpen(false)}
          redirectTo={Paths.orderBundlesClaim.expand({ orderBundleUuid: props.orderBundleUuid })}
          trackSource="ClaimOrderBanner"
          showSignInOnOpen
        />
      </div>
    </RCAlertBox>
  );
}
