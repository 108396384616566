// TODO update this to import from commons
// import { gql } from '@reverbdotcom/commons/src/gql';
// eslint-disable-next-line no-restricted-imports
import { gql } from '@apollo/client';
import * as React from 'react';
import classNames from 'classnames';

import CoreLink from '@reverbdotcom/commons/src/components/core_link';
import CreateOfferButton, { ListingCreateOfferButtonFragment } from '@reverbdotcom/commons/src/offers/create_offer_button';
import { BumpKeyFragment, ListViewListings } from '@reverbdotcom/commons/src/gql/graphql';
import { SHOP_PATH, isOutletHub, listingItemPagePath } from '@reverbdotcom/commons/src/url_helpers';
import { isEndedWatchesPath } from './shared/url_helpers';
import AddToCartButton, { AddToCartButtonFragment } from '@reverbdotcom/commons/src/components/add_to_cart_button';
import { DisplayStyle, WatchBadge } from '@reverbdotcom/commons/src/components/watch_badge';
import { WatchParentComponentNames } from '@reverbdotcom/commons/src/event_tracking/watchlist';
import { InOtherCartsCardFragment } from '@reverbdotcom/commons/src/components/in_other_carts_card_nudge';
import { shouldShowBumpTag } from '@reverbdotcom/commons/src/components/bump_tag';
import { useUser } from '@reverbdotcom/commons/src/user_hooks';
import { WEB } from '@reverbdotcom/commons/src/constants';
import { buildListingClickEvent } from '@reverbdotcom/commons/src/elog/mparticle_tracker';
import { ListingCardFragment } from '@reverbdotcom/commons/src/components/listing_card';
import { ListingWithSignals } from '@reverbdotcom/commons/src/components/listing_card_signals';
import { withRouter, WithRouterProps } from 'react-router';
import { RCListingRowCard } from '@reverbdotcom/cadence/components/RCListingRowCard/RCListingRowCard';
import ImageCarousel from '@reverbdotcom/commons/src/components/image_carousel';
import {
  buildListingForBuyer,
  getEyebrow,
  buildPrice,
  getRCListingCardNudges,
  isListingDomesticToBuyer,
} from '@reverbdotcom/commons/src/listing_helpers';
import ListingCardTag from '@reverbdotcom/commons/src/components/listing_card_tag';
import { ListingCardMetadata } from '@reverbdotcom/commons/src/components/listing_card_metadata';
import { ListingTitleHtmlTags } from '@reverbdotcom/cadence/components/types';
import { ListingCardReviews } from '@reverbdotcom/commons/src/components/reviews_metadata';

const COMPONENT_NAME = 'ListViewRowCard';
const LIVE = 'live';
const DISPLAY_STYLE = 'Row';

interface ExternalProps {
  listing: ListViewListings.Fragment & ListingWithSignals & BumpKeyFragment;
  loading: boolean;
  position?: number;
  trackingQuery?: string;
  trackingFilter?: string;
  trackingSort?: string;
  trackingPage?: number;
  showingSold?: boolean;
  handleCreateWatchResult?: () => void;
  showFlag?: boolean;
  hideMetadataAsOffer?: boolean;
  includeItemListMetadata?: boolean;
  titleHtmlTag?: keyof typeof ListingTitleHtmlTags;
  reviews?: ListingCardReviews;
}

type IProps = ExternalProps & WithRouterProps;

export function ListViewRowCard({ listing, ...props }: IProps) {
  const user = useUser();

  if (props.loading) {
    return <RCListingRowCard.Skeleton />;
  }

  const listingClickEvent = () => {
    const sellerCountryCode = listing.shop.address?.country.countryCode;

    return {
      query: props.trackingQuery,
      ...buildListingClickEvent({
        listing: listing,
        componentName: COMPONENT_NAME,
        displayStyle: DISPLAY_STYLE,
        position: props.position,
        city: listing.shop.address?.locality,
        region: listing.shop.address?.region,
        countryCode: sellerCountryCode,
        isListingDomesticToBuyer: isListingDomesticToBuyer(sellerCountryCode, user?.countryCode),
      }),
    };
  };

  const receivedCount = listing.seller?.feedbackSummary?.receivedCount;
  const rollingRatingPercentage = listing.seller?.feedbackSummary?.rollingRatingPercentage;
  const rating = rollingRatingPercentage / 100;
  const openNewTab = user && user.deviceName === WEB;
  const isBumped = shouldShowBumpTag(listing);
  const images = listing.images.map(i => (i.source));
  const listingPath = `${listingItemPagePath(listing)}${isEndedWatchesPath(props?.location) ? '?show_sold=true' : ''}`;
  const combinedShippingDiscount = false;
  const showShippingDisplay = true;
  const decoratedListing = buildListingForBuyer(
    listing,
    { combinedDiscountActive: combinedShippingDiscount },
  );
  const nudges = getRCListingCardNudges({
    listing,
    decoratedListing,
    shop: listing.shop,
    displayGreatValue: false, // displayGreatValue is set to false as it is not display in a row card
    user,
    showFlag: props?.showFlag,
    location: props?.location,
  });

  if (props.loading) {
    return <RCListingRowCard.Skeleton />;
  }

  return (
    <div
      className={classNames(
        {
          'list-view-row-card--loading': props.loading,
        },
      )}
      itemProp={props.includeItemListMetadata ? 'itemListElement' : null}
      itemScope={props.includeItemListMetadata}
      itemType={props.includeItemListMetadata && 'http://schema.org/Product'}
    >
      {props.includeItemListMetadata &&
        <ListingCardMetadata
          listing={listing}
          hideMetadataAsOffer={props.hideMetadataAsOffer}
          includeItemListMetadata={props.includeItemListMetadata}
          reviews={props.reviews}
        />
      }
      <RCListingRowCard
        thumbnailElement={(<ImageCarousel images={images} />)}
        title={listing.title}
        titleHtmlTag={props.titleHtmlTag}
        conditionLabel={decoratedListing.conditionLabel}
        price={buildPrice(decoratedListing, combinedShippingDiscount, showShippingDisplay)}
        nudges={nudges}
        eyebrow={getEyebrow(listing, isBumped)}
        violator={!isOutletHub(props?.location) && <ListingCardTag listing={listing} />}
        linkElement={(children) => (
          <CoreLink
            to={listingPath}
            target={openNewTab ? '_blank' : undefined}
            clickEvent={listingClickEvent()}
          >
            {children}
          </CoreLink>
        )}
        shopInfo={{
          location: listing.shop.address.displayLocation,
          name: listing.shop.name,
          rating: {
            count: receivedCount,
            rating: rating,
          },
          linkElement: (children) => (
            <CoreLink
              to={SHOP_PATH.expand({ slug: listing.shop.slug })}
              target={openNewTab ? '_blank' : undefined}
            >
              {children}
            </CoreLink>
          ),
        }}
        primaryAction={listing.state === LIVE && (
          <AddToCartButton
            listing={listing}
            parentComponentName={COMPONENT_NAME}
            listingClickEvent={listingClickEvent()}
          />
        )}
        secondaryAction={<CreateOfferButton
          className="list-view-row-card__make-offer"
          listing={listing}
          parentComponentName={COMPONENT_NAME}
        />}
        tertiaryAction={<WatchBadge
          listing={listing}
          parentComponentName={WatchParentComponentNames.MarketplaceListViewAction}
          useToast
        />}
        favoriteButtonElement={(
          <WatchBadge
            listing={listing}
            displayStyle={DisplayStyle.RC_FAVORITE_BUTTON}
            parentComponentName={WatchParentComponentNames.MarketplaceListViewIcon}
            handleCreateWatchResult={props.handleCreateWatchResult}
            useToast
          />
        )}
      />
    </div>
  );
}

export const ListViewRowCardFragment = gql`
  fragment ListViewListings on Listing {
    _id
    id
    categoryUuids
    state
    shop {
      _id
      name
      slug
      preferredSeller
      quickShipper
      quickResponder
      address {
        _id
        locality
        region
        displayLocation
        country {
          _id
          countryCode
          name
        }
      }
    }
    seller {
      _id
      feedbackSummary {
        receivedCount
        rollingRatingPercentage
      }
    }
    csp {
      _id
      webLink {
        href
      }
    }
    ... AddToCartButtonFields
    ... ListingCardFields
    ... ListingCreateOfferButtonData
    ... InOtherCartsCardData
  }
  ${ListingCardFragment}
  ${AddToCartButtonFragment}
  ${ListingCreateOfferButtonFragment}
  ${InOtherCartsCardFragment}
`;

export default withRouter(ListViewRowCard);
