import PropTypes from 'prop-types';
import React from 'react';
import RelatedArticleView from './related_article_view';

const RelatedArticlesView = ({ articles }) =>
  (
    <div className="mb-6">
      <h4 className="strong mb-1">{I18n.t('js.articles.relatedArticles.title')}</h4>
      <div className="g-container">
        {articles.map(article =>
          <RelatedArticleView key={article.id} article={article} />)}
      </div>
    </div>
  );

RelatedArticlesView.propTypes = {
  articles: PropTypes.array.isRequired,
};

export default RelatedArticlesView;
