import { ChildProps } from '@apollo/client/react/hoc';
// TODO update this to import from commons
// import { gql } from '@reverbdotcom/commons/src/gql';
// eslint-disable-next-line no-restricted-imports
import { gql } from '@apollo/client';
import React from 'react';
import ListingNudge from './discovery/listing_nudge';
import { CartSignals as CartSignalsQuery } from '@reverbdotcom/commons/src/gql/graphql';

import { IUserContext, withUserContext } from '@reverbdotcom/commons';
import { withGraphql } from '@reverbdotcom/commons/src/with_graphql';

interface ExternalProps {
  listingId: string;
  skipLoadingSignals?: boolean; // loading signals in this way uses n API calls where n is the number of products in the user's cart. Until that's fixed, we skip loading signals when the user has too many items in cart (see constant in backend for actual number)
}

interface Signal {
  name?: string,
  title?: string,
  icon?: string
}

type IProps = ChildProps<ExternalProps & IUserContext, CartSignalsQuery.Query, Location>;

export function CartSignals(
  props: IProps,
) {

  const signals = props.data?.listing?.signals;
  if (!signals?.length) { return null; }

  return (
    <>
      {signals.map(signal => {
        return RenderSignal(signal);
      })}
    </>
  );
}

function RenderSignal(signal: Signal) {
  if (!signal.title || !signal.icon) {
    return null;
  }

  const getIconSvg = function () {
    const iconHeight = 20;
    return (
      <svg height={iconHeight}>
        <image href={signal.icon} height={iconHeight} />
      </svg>
    );
  };

  return (
    <ListingNudge
      title={signal.title}
      inline
      icon={getIconSvg()}
      key={signal.title}
    />
  );
}

const withQuery = withGraphql<IProps, CartSignalsQuery.Query>(
  gql`
    query Cart_Signals(
      $id: String
      $skipLoadingSignals: Boolean!
    ){
      listing(
        input: {id: $id}
      ) {
        _id
        id
        signals (input: { groups: [ CART_PAGE_WHY_TO_BUY ] }) @skip(if: $skipLoadingSignals) {
          name
          title
          icon
        }
      }
    }
  `,
  {
    options: (ownProps) => {
      return {
        errorPolicy: 'all',
        ssr: true,
        variables: {
          id: ownProps.listingId,
          skipLoadingSignals: ownProps.skipLoadingSignals || false,
        },
      };
    },
  },
);

export default withUserContext(withQuery(CartSignals));
