// TODO update this to import from commons
// import { gql } from '@reverbdotcom/commons/src/gql';
// eslint-disable-next-line no-restricted-imports
import { gql } from '@apollo/client';
import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router';
import { get } from 'lodash';
import { CollectionHeader } from '@reverbdotcom/commons/src/gql/graphql';

interface IProps {
  collectionHeader: CollectionHeader;
  headerLinkTarget?: string;
  withDescription?: boolean;
}

export default class CollectionHeaderDisplay extends React.Component<IProps, null> {
  imageStyle() {
    const imageUrl = get(this.props, 'collectionHeader.fullBleedImage.source');

    if (!imageUrl) { return null; }
    return { style: { backgroundImage: `url('${imageUrl}')` } };
  }

  renderHeader() {
    if (this.props.headerLinkTarget) {
      return (
        <h1 className="collection-header__title__text">
          <Link
            to={this.props.headerLinkTarget}
            className="collection-header__title__link"
          >
            {this.props.collectionHeader.title}
          </Link>
        </h1>
      );
    }

    return (
      <h1 className="collection-header__title__text">
        {this.props.collectionHeader.title}
      </h1>
    );
  }

  render() {
    const imageStyle = this.imageStyle();

    const classes = classNames(
      'collection-header',
      { 'collection-header--with-image': !!imageStyle },
      { 'collection-header--with-description': this.props.withDescription },
    );

    return (
      <div className={classes}>
        <header
          className="collection-header__title"
          {...imageStyle}
        >
          {this.renderHeader()}
        </header>
      </div>
    );
  }
}

export const collectionInfoFragment = gql`
  fragment CollectionInfo on CollectionHeader {
    _id
    title
    metaTitle
    collectionId
    collectionType
    description
    fullBleedImage {
      _id
      source
    }
  }
`;
