import React from 'react';
import OverflowingRow from '../../components/overflowing_row';
import { IDynamicComponentProps } from '../dynamic_component_props';

interface IQuestionAnswerPair {
  question: string;
  answer: string;
  answerHtml: string;
}

function QuestionAnswerPairRow(pair: IQuestionAnswerPair, idx: number) {
  return (
    <div itemScope itemProp="mainEntity" itemType="https://schema.org/Question" className="markdown-section"  key={idx}>
      <h3 itemProp="name"><strong>{pair.question}</strong></h3>
      <div itemScope itemProp="acceptedAnswer" itemType="https://schema.org/Answer">
        <div itemProp="text" className="answer-text markdown-section" dangerouslySetInnerHTML={{ __html: pair.answerHtml || pair.answer }}>
        </div>
      </div>
    </div>
  );
}

interface IProps extends IDynamicComponentProps {
  questionAnswerPairs?: IQuestionAnswerPair[];
}

export function FaqSection(props: IProps) {
  return (
    <OverflowingRow>
      <div itemScope itemType="https://schema.org/FAQPage">
        {props.questionAnswerPairs.map((questionAnswerPair, idx) => QuestionAnswerPairRow(questionAnswerPair, idx))}
      </div>
    </OverflowingRow>
  );
}
