import React, { useState } from 'react';
import classNames from 'classnames';
import { gql } from '@reverbdotcom/commons/src/gql';
import {
  Core_DeleteAdminTax1099kReportingThresholdMutation,
  Core_DeleteAdminTax1099kReportingThresholdMutationVariables,
  Core_UpdateAdminTax1099kReportingThresholdMutation,
  Core_UpdateAdminTax1099kReportingThresholdMutationVariables,
  Input_core_apimessages_AdminTax1099kReportingThresholdUpdateRequest,
  Core_CreateAdminTax1099kReportingThresholdMutation,
  Core_CreateAdminTax1099kReportingThresholdMutationVariables,
  Input_core_apimessages_AdminTax1099kReportingThresholdCreateRequest,
} from '@reverbdotcom/commons/src/gql/graphql';
import { useQuery } from '@reverbdotcom/commons/src/useQuery';
import { useMutation } from '@reverbdotcom/commons/src/useMutation';
import { RCButton, RCLoadingBars, RCTextInput, RCTextWithIcon } from '@reverbdotcom/cadence/components';
import { ChevronRightIcon, ChevronLeftIcon } from '@reverbdotcom/cadence/icons/react';
import { getPage, getTotalPages } from '../../pagination_helpers';
import { ErrorAlertBox } from '@reverbdotcom/commons/src/ErrorAlertBox';
import Tax1099kReportingThresholdTableRow from './tax_1099k_reporting_threshold_table_row';
import Tax1099kReportingThresholdEditRow from './tax_1099k_reporting_threshold_edit_row';

const STARTING_OFFSET = '0';
export const THRESHOLDS_PER_PAGE_DEFAULT = '10';
const FIRST_PAGE = 1;

const DEFAULT_SEARCH_PARAMS = {
  offset: STARTING_OFFSET,
  limit: THRESHOLDS_PER_PAGE_DEFAULT,
  year: '',
  region: '',
};

export const SEARCH_TAX_THRESHOLDS_QUERY = gql(`
  query Core_SearchAdminTax1099kReportingThresholds($input: Input_core_apimessages_AdminTax1099kReportingThresholdSearchRequest) {
    taxReportingThresholdSearch(input: $input) {
      totalResults
      offset
      limit
      taxReportingThresholds {
        id
        year
        region
        salesAmountCents
        transactionCount
      }
    }
  }
`);

export const DELETE_TAX_THRESHOLD_MUTATION = gql(`
  mutation Core_DeleteAdminTax1099kReportingThreshold($input: Input_core_apimessages_AdminTax1099kReportingThresholdDeleteRequest) {
    deleteAdminTax1099kReportingThreshold(input: $input) {
      id
    }
  }
`);

export const UPDATE_TAX_THRESHOLD_MUTATION = gql(`
  mutation Core_UpdateAdminTax1099kReportingThreshold($input: Input_core_apimessages_AdminTax1099kReportingThresholdUpdateRequest) {
    updateAdminTax1099kReportingThreshold(input: $input) {
      id
      year
      region
      salesAmountCents
      transactionCount
    }
  }
`);

export const CREATE_TAX_THRESHOLD_MUTATION = gql(`
  mutation Core_CreateAdminTax1099kReportingThreshold($input: Input_core_apimessages_AdminTax1099kReportingThresholdCreateRequest) {
    createAdminTax1099kReportingThreshold(input: $input) {
      id
      year
      region
      salesAmountCents
      transactionCount
    }
  }
`);

interface TableColumn {
  id: string;
  displayName: string;
  alignRight?: boolean;
}

const EDIT_COLUMN: TableColumn = {
  id: 'edit',
  displayName: '',
  alignRight: true,
};

const DEFAULT_COLUMN_NAMES: TableColumn[] = [
  {
    id: 'year',
    displayName: 'Year',
  },
  {
    id: 'region',
    displayName: 'State',
  },
  {
    id: 'salesAmountCents',
    displayName: 'Sales Threshold',
    alignRight: true,
  },
  {
    id: 'transactionCount',
    displayName: 'Transaction Count Threshold',
    alignRight: true,
  },
];

interface IProps {
  canEdit: Boolean;
}

export default function Tax1099kReportingThresholds({ canEdit }: IProps) {
  const [ searchParams, setSearchParams ] = useState(DEFAULT_SEARCH_PARAMS);

  const [ filterYear, setFilterYear ] = useState('');
  const [ filterRegion, setFilterRegion ] = useState('');

  const { data: thresholdsSearchResponse, loading: loadingThresholds } = useQuery(SEARCH_TAX_THRESHOLDS_QUERY, { variables: { input: searchParams } });

  const taxReportingThresholds = thresholdsSearchResponse?.taxReportingThresholdSearch?.taxReportingThresholds;

  const currentOffset = parseInt(thresholdsSearchResponse?.taxReportingThresholdSearch?.offset, 10);
  const currentLimit = parseInt(thresholdsSearchResponse?.taxReportingThresholdSearch?.limit, 10);
  const totalThresholds = parseInt(thresholdsSearchResponse?.taxReportingThresholdSearch?.totalResults, 10);

  const currentPage = getPage(currentOffset, currentLimit);
  const totalPages = getTotalPages(totalThresholds, currentLimit);

  const isFirstPage = currentPage === FIRST_PAGE;
  const isLastPage = currentPage === totalPages;

  const [ deleteThresholdMutation, { errors: deleteErrors }] =
    useMutation<
      Core_DeleteAdminTax1099kReportingThresholdMutation,
      Core_DeleteAdminTax1099kReportingThresholdMutationVariables
    >(DELETE_TAX_THRESHOLD_MUTATION);

  const [ updateThresholdMutation, { errors: updateErrors }] =
    useMutation<
      Core_UpdateAdminTax1099kReportingThresholdMutation,
      Core_UpdateAdminTax1099kReportingThresholdMutationVariables
    >(UPDATE_TAX_THRESHOLD_MUTATION);

  const [ createThresholdMutation, { errors: createErrors }] =
    useMutation<
      Core_CreateAdminTax1099kReportingThresholdMutation,
      Core_CreateAdminTax1099kReportingThresholdMutationVariables
    >(CREATE_TAX_THRESHOLD_MUTATION);

  const deleteThreshold = (thresholdId: string) => {
    deleteThresholdMutation({
      variables: {
        input: {
          id: thresholdId,
        },
      },
      refetchQueries: ['Core_SearchAdminTax1099kReportingThresholds'],
      awaitRefetchQueries: true,
    });
  };

  const updateThreshold = (threshold: Input_core_apimessages_AdminTax1099kReportingThresholdUpdateRequest) => {
    updateThresholdMutation({
      variables: {
        input: threshold,
      },
      refetchQueries: ['Core_SearchAdminTax1099kReportingThresholds'],
      awaitRefetchQueries: true,
    });
  };

  const createThreshold = (threshold: Input_core_apimessages_AdminTax1099kReportingThresholdCreateRequest) => {
    createThresholdMutation({
      variables: {
        input: threshold,
      },
      refetchQueries: ['Core_SearchAdminTax1099kReportingThresholds'],
      awaitRefetchQueries: true,
    });
  };

  const setFilters = () => {
    setSearchParams({
      offset: STARTING_OFFSET,
      limit: THRESHOLDS_PER_PAGE_DEFAULT,
      year: filterYear,
      region: filterRegion,
    });
  };

  const clearFilters = () => {
    setFilterYear('');
    setFilterRegion('');
    setSearchParams(DEFAULT_SEARCH_PARAMS);
  };


  const previousPage = () => {
    setSearchParams((params) => ({
      ...params,
      offset: (currentOffset - currentLimit).toString(),
    }));
  };

  const nextPage = () => {
    setSearchParams((params) => ({
      ...params,
      offset: (currentOffset + currentLimit).toString(),
    }));
  };

  const columnNames = canEdit ? DEFAULT_COLUMN_NAMES.concat(EDIT_COLUMN) : DEFAULT_COLUMN_NAMES;

  return (
    <div className="well">
      <h3 className="mb-4">{canEdit && 'Manage '}1099K Reporting Thresholds</h3>
      <div className="bd-1 bd-color-primary bd-radius-sm padding-2 mb-4">
        <div className="d-flex fx-justify-between">
          <div className="d-flex">
            <div className="mr-2">
              <RCTextInput
                id="filterYear"
                label="Year"
                name="filterYear"
                value={filterYear}
                onChange={(e) => setFilterYear(e.target.value)}
                type="number"
              />
            </div>
            <RCTextInput
              id="filterRegion"
              label="Region"
              name="filterRegion"
              value={filterRegion}
              onChange={(e) => setFilterRegion(e.target.value)}
            />
          </div>
          <div className="d-flex">
            <div className="d-flex fx-align-center mr-2">
              <RCButton
                id="searchThresholds"
                onClick={setFilters}
                variant="filled"
              >
                Search
              </RCButton>
            </div>
            <div className="d-flex fx-align-center">
              <RCButton
                id="clearFilters"
                onClick={clearFilters}
              >
                Clear
              </RCButton>
            </div>
          </div>
        </div>
      </div>
      <div className="styled-table styled-table--striped bd-1 pt-2 bd-radius-md bd-color-primary">
        {deleteErrors.length > 0 && (
          <div className="mb-2 ml-2 mr-2">
            <ErrorAlertBox errors={deleteErrors} />
          </div>
        )}
        {updateErrors.length > 0 && (
          <div className="margin-2">
            <ErrorAlertBox errors={updateErrors} />
          </div>
        )}
        {createErrors.length > 0 && (
          <div className="margin-2">
            <ErrorAlertBox errors={createErrors} />
          </div>
        )}
        <table>
          <thead>
            <tr>
              {columnNames.map((column) => (
                <th
                  key={column.id}
                  className={classNames({ 'align-right': column.alignRight })}
                >
                  {column.displayName}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {canEdit && (
              <Tax1099kReportingThresholdEditRow
                saveThreshold={createThreshold}
              />
            )}
            {loadingThresholds && (
              <tr>
                <td colSpan={4}>
                  <div className="d-flex fx-justify-center">
                    <RCLoadingBars />
                  </div>
                </td>
              </tr>
            )}
            {!loadingThresholds && (
              taxReportingThresholds?.length > 0 ? (
                taxReportingThresholds.map((threshold) => (
                  <Tax1099kReportingThresholdTableRow
                    key={`${threshold.year}-${threshold.region}`}
                    threshold={threshold}
                    deleteThreshold={deleteThreshold}
                    updateThreshold={updateThreshold}
                    canEdit={canEdit}
                  />
                ))
              ) : (
                <tr>
                  <td colSpan={columnNames.length}>
                    <div className="d-flex fx-justify-center">
                      No results
                    </div>
                  </td>
                </tr>
              )
            )}
          </tbody>
        </table>
        {(!loadingThresholds && totalThresholds > 0) && (
          <div className="d-flex margin-2 fx-justify-center">
            <RCButton
              id="previousPage"
              size="mini"
              onClick={previousPage}
              disabled={isFirstPage}
            >
              <RCTextWithIcon
                svgComponent={ChevronLeftIcon}
                children=""
                title="Previous Page"
              />
            </RCButton>
            <div className="ml-2 mr-2 d-flex fx-align-center">
              Page {currentPage} of {totalPages}
            </div>
            <RCButton
              id="nextPage"
              size="mini"
              onClick={nextPage}
              disabled={isLastPage}
            >
              <RCTextWithIcon
                svgComponent={ChevronRightIcon}
                children=""
                title="Next Page"
              />
            </RCButton>
          </div>
        )}
      </div>
    </div>
  );
}
