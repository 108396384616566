import React from 'react';
import { I18N as Translate } from '@reverbdotcom/commons/src/components/translate';
import { formatAmountCents } from '@reverbdotcom/commons/src/money';
import { StatementProps } from './statement_props';
import { formatInternationalizedDate } from '@reverbdotcom/commons/src/date_utils';

function StatementDetails({
  balanceCents,
  currencyCode,
  amountPaidCents,
  totalOwedCents,
  totalPaidCents,
}: StatementProps) {
  const totalOwedAmount = formatAmountCents({ amountCents: totalOwedCents, currency: currencyCode });
  const totalPaidAmount = formatAmountCents({ amountCents: -1 * totalPaidCents, currency: currencyCode });
  const amountPaid = formatAmountCents({ amountCents: amountPaidCents, currency: currencyCode });
  const balanceAmount = formatAmountCents({ amountCents: balanceCents, currency: currencyCode });
  const date = formatInternationalizedDate(new Date());

  return (
    <div className="styled-table styled-table--justified styled-table--compact">
      <table>
        <tbody>
          <tr>
            <td><Translate text="discovery.payStatement.paymentForm.totalOwed"/></td>
            <td>{totalOwedAmount}</td>
          </tr>
          <tr>
            <td><Translate text="discovery.payStatement.paymentForm.totalPaid"/></td>
            <td>{`-${totalPaidAmount}`}</td>
          </tr>

          { (amountPaidCents) && (
            <tr>
              <td><Translate
                text="discovery.payStatement.paymentForm.paymentOnDate"
                args={{ date: date }}
              /></td>
              <td>{`-${amountPaid}`}</td>
            </tr>
          )}

        </tbody>
        <tfoot>
          <tr>
            <td><Translate
              text="discovery.payStatement.paymentForm.balance"
              className="tt-uppercase"
            /></td>
            <td>{balanceAmount}</td>
          </tr>
        </tfoot>
      </table>
    </div>
  );
}

export default StatementDetails;
