import React from 'react';
import I18n from 'i18n-js';
import { countBy, uniq } from 'lodash';

import {
  core_apimessages_Error,
} from '@reverbdotcom/commons/src/gql/graphql';
import { useViewTracking } from '@reverbdotcom/commons/src/use_tracking';
import { MParticleEventName } from '@reverbdotcom/commons/src/elog/mparticle_types';

export const COMPONENT_NAME = 'BulkActionErrors';
interface IExternalProps {
  totalListingsWithErrors: number;
  errors: core_apimessages_Error[];
  titleI18nKey: string;
}

export default function BulkActionErrors({
  totalListingsWithErrors,
  errors,
  titleI18nKey,
}: IExternalProps) {
  const errorMessages = errors.map(error => error.message);

  const errorFrequencies = countBy(errorMessages);
  const uniqueErrorMessages = uniq(errorMessages);

  const hasErrorMessages = !!uniqueErrorMessages.length;

  useViewTracking({
    componentName: COMPONENT_NAME,
    eventName: MParticleEventName.ComponentView,
  }, hasErrorMessages);

  if (!hasErrorMessages) return null;

  return (
    <>
      <div className="weight-bold">
        {I18n.t(titleI18nKey, {
          count: totalListingsWithErrors,
          formattedCount: I18n.toNumber(totalListingsWithErrors, {
            precision: 0,
          }),
        })}
      </div>
      <div className="ml-8">
        <ul className="bulk-publish-errors__list-style">
          {uniqueErrorMessages.map(message => (
            <li key={message} className="width-90">
              <span className="mr-1">
                {message}
              </span>

              <span className="weight-semibold">
                {I18n.t('discovery.listingsManagement.toolbar.bulkActions.listingErrorCount', {
                  count: errorFrequencies[`${message}`],
                  formattedCount: I18n.toNumber(errorFrequencies[`${message}`], {
                    precision: 0,
                  }),
                })}
              </span>
            </li>
          ))}
        </ul>
      </div>
    </>
  );
}
