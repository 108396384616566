import React from 'react';
import I18n from 'i18n-js';

class Progress extends React.Component {
  renderPercentage = (progress) => {
    if (!progress.processing) {
      return false;
    }

    return (
      <div className="polling-progress-bar">
        <div className="progress-bar-total">
          <div className="progress-bar-percentage" style={{ width: progress.percentage }} />
        </div>
      </div>
    );
  };

  renderProgressStatus = (progress) => {
    if (!progress.processing) {
      return <span className="polling-progress-pending">{I18n.t('js.csvImports.pending')}</span>;
    }

    return <span className="polling-progress-completed">{progress.completed}/{progress.total}</span>;
  };

  render() {
    const { progress } = this.props;

    return (
      <li className="polling-progress">
        {this.renderPercentage(progress)}
        <dl className="polling-progress-info">
          <dd className="polling-progress-definition">
            <i className="fa fa-refresh fa-spin" />
            {this.renderProgressStatus(progress)}
          </dd>
          <dt className="polling-progress-title">{progress.name}</dt>
        </dl>
      </li>
    );
  }
}

export default Progress;
