// TODO update this to import from commons/src/gql
// eslint-disable-next-line no-restricted-imports
import { gql } from '@apollo/client';
import React from 'react';

import CoreLink from '@reverbdotcom/commons/src/components/core_link';
import { ITEM_WITH_SLUG_ROUTE } from '../url_helpers';
import { PhotoTileFieldsFragment } from '@reverbdotcom/commons/src/gql/graphql';
import { MParticleEventName } from '@reverbdotcom/commons/src/elog/mparticle_types';
import { RCImage } from '@reverbdotcom/cadence/components';

interface PhotoTileProps {
  title: string;
  image: string;
  url: string;
  loading?: boolean;
  clickEventData: {};
}

interface IProps {
  items: PhotoTileFieldsFragment[];
  loading?: boolean;
  trackingName?: string;
}

const COMPONENT_NAME = 'PhotoTile';

export const PhotoTileFragment = gql`
  fragment PhotoTileFields on Listing {
    _id
    id
    slug
    title
    images(input: {
      transform: "card_square",
      count: 1,
      scope: "photos",
      type: "Product"
    }) {
      _id
      source
    }
  }
`;

export function PhotoTiles(props: IProps) {
  if (props.loading) {
    const placeholderTiles = Array.from(Array(12).keys());
    return (
      <div className="photo-tiles">
        {placeholderTiles.map((id) => {
          return <PlaceholderTile key={id} />;
        })};
      </div>
    );
  }

  if (props.items?.length === 0) return null;

  return (
    <div className="photo-tiles">
      {props.items.map((item, index) => {
        return (
          <PhotoTile
            image={item.images[0].source}
            title={item.title}
            url={ITEM_WITH_SLUG_ROUTE.expand({ id: item.id, slug: item.slug })}
            key={index}
            clickEventData={{
              entityId: item.id,
              entityType: 'Listing',
              position: index,
              componentName: props.trackingName || COMPONENT_NAME,
            }}
          />
        );
      })}
    </div>
  );
}

function PhotoTile(props: PhotoTileProps) {
  const { url, image, title, clickEventData } = props;
  return (
    <CoreLink
      to={url}
      className="photo-tile"
      clickEvent={{
        eventName: MParticleEventName.ClickedComponent,
        ...clickEventData,
      }}
    >
      <div className="photo-tile__image">
        <RCImage
          src={image}
          alt={title}
          title={title}
          imageBox="auto"
          overlay="offwhite"
        />
      </div>
    </CoreLink>
  );
}

function PlaceholderTile() {
  return (
    <div className="photo-tile">
      <div className="photo-tile__placeholder" />
    </div>
  );
}

export default PhotoTiles;
