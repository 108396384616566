import React from 'react';
import classNames from 'classnames';

// The children of this component should always be SharedTab components.
// We'll be migrating tabs into this component piecemeal, which may result
// in new subclasses / props; talk to Cameron or Sam before adding any
// new modifications

interface IProps {
  /** Changes tabs to grow to fill their container */
  grow?: boolean;
  id?: string;
  /** Centers tabs (unnecessary to use alongside `grow`) */
  centered?: boolean;
  /**
   * Includes default max-width, centering, and L-R padding directly in the tabs.
   * Use this in situations where the tabs aren't already rendered inside a wrapper.
   */
  withWrapper?: boolean;
  smallTabs?: boolean;
  /** Bolder, darker styles */
  highContrast?: boolean;
  /** Children must be SharedTab */
  children: React.ReactNode;
}

export class SharedTabs extends React.Component<IProps, null> {
  renderPlaceholder() {
    return (
      <div className="shared-tabs shared-tabs--placeholder">
        <div className="shared-tabs__tab" />
        <div className="shared-tabs__tab" />
      </div>
    );
  }

  render() {
    if (!this.props.children) return this.renderPlaceholder();

    const classes = classNames(
      'shared-tabs',
      { 'shared-tabs--grow': this.props.grow },
      { 'shared-tabs--centered': this.props.centered },
      { 'shared-tabs--small': this.props.smallTabs },
      { 'shared-tabs--with-wrapper': this.props.withWrapper },
      { 'shared-tabs--high-contrast': this.props.highContrast },
    );

    return (
      <nav id={this.props.id} className={classes}>
        {this.props.children}
      </nav>
    );
  }
}

export default SharedTabs;
