import PropTypes from 'prop-types';
import React from 'react';
import PercentageChange from './percentage_change';
import SubStat from './sub_stat';
import ShopData from './shop_data';
import I18n from 'i18n-js';

const SALES = 'sales';

function renderThisMonthStats(shopData, datum, dataKey) {
  if (!(shopData.showPreviousStats && shopData.isThisMonth)) { return null; }

  if (dataKey === SALES) {
    return (
      <SubStat
        label={I18n.t('shopStats.timePeriods.lastMonthToDate')}
        datum={datum}
        tooltip={I18n.t('shopStats.thisMonthTooltip')}
      />
    );
  }

  return <SubStat label={I18n.t('shopStats.timePeriods.lastMonthToDate')} datum={datum} />;
}

function renderPreviousStats(shopData, datum) {
  if (!shopData.showPreviousStats) { return null; }

  return <SubStat label={shopData.timePeriods.previousDataLabel} datum={datum} />;
}

const Stat = ({ shopData, dataKey, additionalClasses }) =>
  (<div className={`g-col-4 stat-col ${additionalClasses}`}>
    <div className="stat-title">
      {shopData.titleFor(dataKey)}
      <PercentageChange
        percent={shopData.changeFor(dataKey)}
        showPercentChange={shopData.showPercentChangeFor(dataKey)}
      />
    </div>
    <div
      className="stat-data"
      dangerouslySetInnerHTML={{ __html: shopData.currentStatsFor(dataKey) }}
    />
    {renderThisMonthStats(shopData, shopData.lastMonthApproximationsFor(dataKey), dataKey)}
    {renderPreviousStats(shopData, shopData.previousStatsFor(dataKey))}
  </div>);

Stat.propTypes = {
  shopData: PropTypes.instanceOf(ShopData).isRequired,
  dataKey: PropTypes.string.isRequired,
  additionalClasses: PropTypes.string,
};

export default Stat;
