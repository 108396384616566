import React from 'react';
import * as elog from '@reverbdotcom/commons/src/elog';
import { useFiveDigitLocale } from '@reverbdotcom/commons/src/user_five_digit_locale_hook';
import { PaymentAction } from '@adyen/adyen-web/dist/types/types';
import { adyenCheckout } from '@reverbdotcom/commons/src/adyen_checkout';
import { useAdyenEnvironment } from '@reverbdotcom/commons/src/adyen_environment_hook';

// In compliance with PSD2 onward,
// 3DS is one of many implementations of
// Strong Customer Authentication (SCA)
export enum SCAType {
  Fingerprint = 'threeDS2Fingerprint',
  Challenge = 'threeDS2Challenge',
  Redirect = 'redirect',
}

// In Checkout API v67 onward, the 3DS2 subtypes
// are "challenge" and "fingerprint". These both
// have a parent action.type == "threeDS2"
export enum SCASubType {
  Fingerprint = 'fingerprint',
  Challenge = 'challenge',
}

// In Checkout API v67 onward, the state.data.details we get
// from Adyen will be "threeDSResult"
interface ThreeDS2ResultData {
  details: { 'threeDSResult': string };
  paymentData: string;
}

export interface SCAState {
  data: ThreeDS2ResultData;
}

// For 3DS2 configuration see https://docs.adyen.com/online-payments/3d-secure/native-3ds2/web-component
// For 3DS1 redirect configuration see https://docs.adyen.com/online-payments/3d-secure/redirect-3ds2-3ds1/web-component
export function useAdyenSCA({ selector, action, onAdditionalDetails }:
{ selector: string; action: PaymentAction, onAdditionalDetails(state:SCAState): void },
) {
  const locale = useFiveDigitLocale();
  const { environment, clientKey } = useAdyenEnvironment();

  React.useEffect(() => {
    if (environment === null || clientKey === null) {
      return;
    }

    elog.info('use_adyen_sca_action', { action });

    function onError(error) {
      elog.error('use_adyen_sca_error', { actionType: action.type, ...error }, error.errorCode);
    }

    function options() {
      if (action.type === SCAType.Challenge) {
        return { size: '05' };
        // @ts-ignore Unnecessary 'else' after 'return'
      } if (action.subtype === SCASubType.Challenge) { // in checkout api v67 & adyen components 4.9.0
        return { challengeWindowSize: '05' };
      }
      return {};
    }

    const configuration = {
      clientKey,
      environment,
      locale,
      onAdditionalDetails,
      onError,
    };

    const checkout = adyenCheckout({ configuration });

    checkout.createFromAction(action, options()).mount(selector);
  }, [locale, selector, environment, clientKey, action, onAdditionalDetails]);
}
