import React from 'react';
import I18n from 'i18n-js';
import classNames from 'classnames';

interface Props {
  condensed?: boolean;
}

export function ExpressPaySeparator({ condensed = false }: Props) {
  const classes = classNames(
    'express-pay-separator',
    { 'express-pay-separator--condensed': condensed },
  );

  return (
    <div className={classes}>
      {I18n.t('discovery.checkout.expressCheckout.separatorText')}
    </div>
  );
}
