export default {
  logoText: 'Klarna',
  klarnaAccount: {
    paymentPlan: '{{installmentCount}} payments of {{tagOpen}}{{installmentAmount}}{{tagOpen}}.',
    learnMoreButton: {
      title: '{{installmentCount}} payments of {{installmentAmount}}.',
      additionalSteps: {
        enterInformation: 'Enter your debit or credit card information',
        explainPaymentPlan: 'The first payment is taken when the order is processed and the next 4 payments are automatically charged every 2 weeks.',
      },
      terms: 'Canadian residents only, 18+. See Canadian payment {{linkOpen}}terms{{linkClose}}. A higher initial payment may be required for some consumers.',
    },
  },
  learnMoreButton: {
    learnMore: 'Learn more',
    klarnaLogoTitle: 'Klarna',
    steps: {
      addItemToCart: 'Add items(s) to your cart',
      chooseKlarna: 'Go to checkout and choose',
    },
  },
};
