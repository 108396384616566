import React from 'react';
import I18n from 'i18n-js';
import CoreLink from './core_link';
import PageTitle from './page_title';
import { NotFoundStatusCode } from '../routing_result';

export default class NotFound extends React.PureComponent<{}, null> {
  render() {
    return (
      <div className="scaling-ptb-20 align-center cms-text size-130 site-wrapper">
        <NotFoundStatusCode />
        <PageTitle
          title={I18n.t('commons.notFound.pageTitle')}
        />
        <h1>
          {I18n.t('commons.notFound.title')}
        </h1>
        <p>
          {I18n.t('commons.notFound.description')}
        </p>
        <p>
          <CoreLink to="/" className="button button--primary icon-l-arrow-left">
            {I18n.t('commons.notFound.returnHome')}
          </CoreLink>
        </p>
      </div>
    );
  }
}
