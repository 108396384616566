import React from 'react';

import googleIcon from '@reverbdotcom/cadence/images/vendor/google-color-logo.svg?component';

import CsrfForm from '../csrf_form';
import { RCTextWithIcon, RCButton } from '@reverbdotcom/cadence/components';

import { GOOGLE_AUTH_PATH } from '../url_helpers';
import { MParticleEventName, trackEvent } from '../elog/mparticle_tracker';
import { titleText, Variant } from './thirdPartyAuth';

interface Props {
  redirectTo: string;
  variant: Variant;
}

const formId = 'google-signup-signin';

export default function GoogleSigninButton({ variant, redirectTo }: Props) {
  const title = titleText({ provider: 'google', variant });

  return (
    <CsrfForm
      id={formId}
      method="post"
      action={GOOGLE_AUTH_PATH.expand({ origin: redirectTo })}
    >
      <RCButton
        isSubmit
        fullWidth
        form={formId}
        variant="muted"
        onClick={trackGoogleSigninButtonClick}
      >
        <RCTextWithIcon
          svgComponent={googleIcon}
          placement="left"
          size="large"
        >
          {title}
        </RCTextWithIcon>
      </RCButton>
    </CsrfForm>
  );
}

function trackGoogleSigninButtonClick() {
  trackEvent({
    eventName: MParticleEventName.ClickedGoogleSigninButton,
  });
}
