import {
  reverb_pricing_Money,
  core_apimessages_Money as Money,
} from './gql/graphql';

export function mapPriceRecommendationToMoney(pricingMoney: reverb_pricing_Money): Money {
  return {
    amount: pricingMoney.amount,
    amountCents: pricingMoney.amountCents,
    currency: pricingMoney.currency,
    display: pricingMoney.display,
    symbol: pricingMoney.symbol,
  };
}
