import I18n from 'i18n-js';
import React from 'react';

import { RCTooltip } from '@reverbdotcom/cadence/components';

interface IProps {
  recommendedAmountDisplay: string;
  listingsCount: number;
}

export default function ListingShippingRateRecommendation({ recommendedAmountDisplay, listingsCount }: IProps) {
  const listingsCountDisplay = listingsCount < 501 ? listingsCount : '500+';

  return (
    <RCTooltip
      text={I18n.t('discovery.listingShippingRateRecommendation.basedOn', { listingsCountDisplay, recommendedAmountDisplay })}
    >
      {I18n.t('discovery.listingShippingRateRecommendation.recommendText', { recommendedAmountDisplay })}
    </RCTooltip>
  );
}
