const locationUtil = {
  isOnSalePage(loc: Location = window.location) {
    return !!loc.pathname.match('/sales/');
  },

  isOnHandpickedPage(loc: Location = window.location) {
    return !!loc.pathname.match('/handpicked/');
  },

  isOnMarketplacePage(loc: Location = window.location) {
    return !!loc.pathname.match('/marketplace');
  },
};

export default locationUtil;
