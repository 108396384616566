import {
  CoreCheckoutUpdateShippingInfo,
  Input_core_apimessages_CheckoutUpdateShippingAddressRequest as CheckoutUpdateShippingAddressRequest,
  core_apimessages_Error as Error,
} from '@reverbdotcom/commons/src/gql/graphql';
import { useMutation } from '@reverbdotcom/commons/src/useMutation';
import { ExecutionResult } from 'graphql';
import { useCallback } from 'react';
import { mapAddressToEntry } from '../../lib/addressEntryMapping';
import { useUser } from '@reverbdotcom/commons/src/user_hooks';
import { gql } from '@reverbdotcom/commons/src/gql';

type ShippingInfoInput = Partial<Pick<CheckoutUpdateShippingAddressRequest, 'shippingAddress' | 'guest'>>;

export interface CheckoutShippingForm {
  submit: (input: ShippingInfoInput) => Promise<ExecutionResult<CoreCheckoutUpdateShippingInfo.Mutation>>;
  errors: Error[];
  loading: boolean;
}

export function useCheckoutShippingForm(checkoutId: string) {
  const [mutate, { loading, errors }] = useMutation(UPDATE_CHECKOUT_SHIPPING_INFO);

  const user = useUser();

  const submit = useCallback(async (input: ShippingInfoInput) => {
    const { shippingAddress, guest } = input;

    const result = await mutate({
      variables: {
        input: {
          id: checkoutId,
          shippingAddress: mapAddressToEntry(shippingAddress),
          guest: {
            firstName: guest.firstName,
            lastName: guest.lastName,
            email: guest.email,
            consentToAdData: user.thirdPartyAdDataAllowed,
            consentToAdStorage: user.thirdPartyAdStorageAllowed,
            consentToAdPersonalization: user.thirdPartyAdPersonalizationAllowed,
            consentToAnalyticsStorage: user.thirdPartyAnalyticsStorageAllowed,
          },
        },
      },
      refetchQueries: ['Core_Checkout_Layout'],
      awaitRefetchQueries: true,
    });

    return result;
  }, [
    checkoutId,
    mutate,
    user.thirdPartyAdDataAllowed,
    user.thirdPartyAdStorageAllowed,
    user.thirdPartyAdPersonalizationAllowed,
    user.thirdPartyAnalyticsStorageAllowed,
  ]);

  return {
    submit,
    loading,
    errors,
  };
}

const UPDATE_CHECKOUT_SHIPPING_INFO = gql(`
  mutation Core_Checkout_UpdateShippingInfo(
    $input: Input_core_apimessages_CheckoutUpdateShippingAddressRequest
  ) {
    updateCheckoutShippingAddress(input: $input) {
      checkout {
        _id
      }
    }
  }
`);
