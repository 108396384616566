import React from 'react';
import I18n from 'i18n-js';
import * as elog from '@reverbdotcom/commons/src/elog';
import { formatBid, DELETE_BID_AMOUNT } from './bump_helpers';
import { V3 } from '../api_request';
import { Paths } from '../shared/url_helpers';
import { MParticleEventName } from '@reverbdotcom/commons/src/elog/mparticle_types';
import { trackEvent } from '@reverbdotcom/commons/src/elog/mparticle_tracker';
import { CoreBumpRecommendationListingCard } from '@reverbdotcom/commons/src/gql/graphql';

const COMPONENT_NAME = 'BumpController';

export interface BumpControllerProps {
  listingId: string;
  bid: number;
  componentName?: string;
  showModal?: boolean;
  hidePercentCallout?: boolean;
  buttonText?: string | React.ReactNode | JSX.Element;
  buttonClassName?: string;
  refetch: (newBid: number | null) => void;
  onModalClose?: () => void;
  disabled?: boolean;
}

export function trackAdjustBumpRate(
  newBid: number,
  startingBid: number,
  bumpRecommendationAmount: number,
  listing: CoreBumpRecommendationListingCard.Fragment,
  componentName: string,
) {
  trackEvent({
    componentName,
    eventName: MParticleEventName.AdjustBumpRate,
    listing,
    startingBumpRatePercent: formatBid(startingBid),
    newBumpRatePercent: formatBid(newBid),
    bumpRecommendationPercent: bumpRecommendationAmount,
  });
}

export function bumpRecommendationsController(WrappedComponent) {
  return function BumpController(props: BumpControllerProps) {
    const [bid, setBid] = React.useState(props.bid);
    const [selectedBid, setSelectedBid] = React.useState(props.bid || 0);

    const [successMessage, setSuccessMessage] = React.useState('');
    const [failMessage, setFailMessage] = React.useState('');

    const { listingId } = props;

    React.useEffect(() => {
      setBid(props.bid);
      setSelectedBid(props.bid || 0);
    }, [props.bid]);

    const coreSetBid = (
      newBid: number,
      bumpRecommendationAmount: number,
      listing: CoreBumpRecommendationListingCard.Fragment,
      refetch: (newBid: number | null) => void,
    ): void => {
      V3.post(Paths.bumpBidsCreate.expand({}), {
        bump_bids: {
          product_id: listingId,
          bid: newBid,
        },
      })
        .then(() => {
          trackAdjustBumpRate(
            newBid,
            bid,
            bumpRecommendationAmount,
            listing,
            props.componentName || COMPONENT_NAME,
          );

          setBid(newBid);
          setSelectedBid(newBid);
          setSuccessMessage(I18n.t('discovery.bump.successBid', { percent: formatBid(newBid) }));
          setFailMessage('');
          refetch(newBid);
        })
        .fail((error) => {
          elog.error('Bump Set Bid Error', { bid: newBid }, error?.statusText);
          setSelectedBid(bid);
          setFailMessage(I18n.t('discovery.bump.errorBid'));
          setSuccessMessage('');
        });
    };

    const deleteBid = (
      bumpRecommendationAmount: number,
      listing: CoreBumpRecommendationListingCard.Fragment,
      refetch: (newBid: number | null) => void,
    ): void => {
      const deleteBidUrl = Paths.bumpBidsDelete.expand({
        listingId,
      });
      V3.destroy(deleteBidUrl)
        .then(() => {
          trackAdjustBumpRate(
            DELETE_BID_AMOUNT,
            bid,
            bumpRecommendationAmount,
            listing,
            props.componentName || COMPONENT_NAME,
          );

          setBid(null);
          setSelectedBid(null);
          setSuccessMessage(I18n.t('discovery.bump.successBidDelete'));
          setFailMessage('');
          refetch(null);
        })
        .fail((error) => {
          elog.error('Bump Delete Bid Error', {}, error?.statusText);
          setSelectedBid(bid);
          setFailMessage(I18n.t('discovery.bump.errorBid'));
          setSuccessMessage('');
        });
    };

    const updateBid = (
      newBid: number,
      bumpRecommendationAmount: number,
      listing: CoreBumpRecommendationListingCard.Fragment,
      refetch: (newBid: number | null) => void,
    ): void => {
      if (newBid === DELETE_BID_AMOUNT) {
        deleteBid(
          bumpRecommendationAmount,
          listing,
          refetch,
        );
      } else {
        coreSetBid(
          newBid,
          bumpRecommendationAmount,
          listing,
          refetch,
        );
      }
    };

    return (
      <WrappedComponent
        listingId={listingId}
        bid={bid}
        selectedBid={selectedBid}
        successMessage={successMessage}
        failMessage={failMessage}
        handleBidChange={setSelectedBid}
        updateBid={updateBid}
        showModal={props.showModal}
        hidePercentCallout={props.hidePercentCallout}
        buttonText={props.buttonText}
        buttonClassName={props.buttonClassName}
        refetch={props.refetch}
        onModalClose={props.onModalClose}
        disabled={props.disabled}
      />
    );
  };
}
