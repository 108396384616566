// TODO update this to import from commons/src/gql
// eslint-disable-next-line no-restricted-imports
import { gql } from '@apollo/client';

import {
  CoreAutoOffersCreateAutoOfferMutation,
  CoreAutoOffersDeleteAutoOfferMutation,
} from '../gql/graphql';
import { withGraphql } from '../with_graphql';

const SellerOfferListingFragment = gql`
  fragment SellerOfferListingFragment on Listing {
    _id
    sellerPrice {
      amount
      amountCents
      currency
      symbol
      display
    }
    sellerCost {
      display
    }
  }
`;

const OfferListingFragment = gql`
  fragment OfferListingFragment on Listing {
    _id
    id
    slug
    title
    hasInventory
    inventory
    images(input: {transform: "card_square" }) {
      _id
      source
    }
    condition {
      displayName
      conditionSlug
    }
    price {
      amount
      amountCents
      currency
      symbol
      display
    }
    taxIncluded
    taxIncludedHint
    shop {
      _id
      name
      currency
      slug
      address {
        _id
        displayLocation
        locality
        region
        postalCode
        countryCode
      }
      lowballOfferPercentage
    }
    sale {
      _id
      id
    }
    canonicalProductId
  }
`;

export const editOfferFormQuery = gql`query Commons_Offers_EditOfferForm(
  $listingId: String,
  $negotiationId: String,
  $isSeller: Boolean!,
  $shippingRegionCode: String,
) {
  exchangeRates(input: { inverseReverseRates: true }) {
    rates {
      from
      to
      rate
    }
  }
  listing(input: { id: $listingId }) {
    _id
    usOutlet
    priceRecommendation {
      priceLow {
        amountCents
        currency
        display
      }
      priceMiddle {
        amountCents
        currency
      }
      priceHigh {
        amountCents
        currency
        display
      }
    }
    shipping(input: {
      shippingRegionCode: $shippingRegionCode
    }) {
      localPickupOnly
    }
    offerBotRule {
      autoRejectPercentage
    }
    ...OfferListingFragment
    ...SellerOfferListingFragment @include(if: $isSeller)
  }
  me {
    _id
    uuid
    activeNegotiation: myNegotiations(input: { ids: [$negotiationId] }) {
      negotiations {
        _id
        id
        buyerUuid
        sellerUuid
        taxIncluded
        taxIncludedHint
        sellerShippingWarning
        state
        buyerWillPayImmediately
        buyer {
          _id
          name
          shop {
            name
            slug
          }
        }
        shippingLocation {
          _id
          displayLocation
        }
        lastOffer {
          status
          offerItems {
            quantity
          }
          prices {
            price {
              display {
                amount
                amountCents
                currency
                symbol
                display
              }
              original {
                amount
                amountCents
                currency
                symbol
                display
              }
            }
            shippingPrice {
              display {
                amount
                amountCents
                currency
                symbol
                display
              }
              original {
                amount
                amountCents
                currency
                symbol
                display
              }
            }
            totalPrice {
              display {
                amount
                amountCents
                currency
                symbol
                display
              }
              original {
                amount
                amountCents
                currency
                symbol
                display
              }
            }
          }
        }
      }
    }
    shippingAddresses {
      _id
      id
      name
      displayLocation
      streetAddress
      isComplete
      countryCode
      primary
    }
    creditCards {
      id
      display {
        summary
        expirationDate
      }
      primaryForCheckout
      expirationYear
      expirationMonth
      needsReverification
      address {
        _id
        isComplete
      }
      cardType
      brand {
        type
        name
      }
    }
  }
}
${OfferListingFragment}
${SellerOfferListingFragment}
`;

// shippingRegionCode gets set instead of usePrimaryShippingAddress if the user changes shipping info
export const newOfferFormQuery = gql`query Commons_Offers_NewOfferForm(
  $listingId: String,
  $shippingRegionCode: String,
  $usePrimaryShippingAddress: Boolean,
  $isSeller: Boolean!,
  $postalCode: String,
) {
  countries {
    countries {
      _id
      name
      countryCode
    }
  }
  exchangeRates(input: { inverseReverseRates: true }) {
    rates {
      from
      to
      rate
    }
  }
  listing(input: { id: $listingId }) {
    _id
    usOutlet
    ...OfferListingFragment
    ...SellerOfferListingFragment @include(if: $isSeller)
    shipping(input: {
      shippingRegionCode: $shippingRegionCode,
      usePrimaryShippingAddress: $usePrimaryShippingAddress,
      postalCode: $postalCode,
    }) {
      localPickupOnly
      shippingRegionCode
      shippingAddress {
        _id
        postalCode
      }
      shippingPrices {
        _id
        rate {
          amount
          amountCents
          currency
          symbol
          display
        }
        originalRate {
          amount
          amountCents
          currency
          symbol
          display
        }
        shippingMethod
        carrierCalculated
        destinationPostalCodeNeeded
        postalCode
      }
    }
    offerBotRule {
      autoRejectPercentage
    }
    priceRecommendation {
      priceLow {
        amountCents
        currency
        display
      }
      priceMiddle {
        amountCents
        currency
      }
      priceHigh {
        amountCents
        currency
        display
      }
    }
    otherBuyersWithListingInCartCounts
  }
  me {
    _id
    uuid
    offerCount: myNegotiations(
      input: { state: ACCEPTED, as: BUYER, limit: 1 }
    ) {
      total
    }
    shippingAddresses {
      _id
      id
      name
      displayLocation
      streetAddress
      isComplete
      countryCode
      primary
    }
    creditCards {
      id
      display {
        summary
        expirationDate
      }
      primaryForCheckout
      expirationYear
      expirationMonth
      needsReverification
      address {
        _id
        isComplete
      }
      cardType
      brand {
        type
        name
      }
    }
  }
}
${OfferListingFragment}
${SellerOfferListingFragment}
`;

export const createInitialOfferMutation = gql`mutation Commons_Offers_CreateOfferForm($input: Input_core_apimessages_CreateNegotiationRequest) {
  createNegotiation(input: $input) {
    negotiations {
      id
      lastOffer {
        offerItems {
          listingId
          quantity
        }
        initiatingParty
        prices {
          price {
            display {
              amount
              amountCents
              currency
              symbol
              display
            }
            original {
              amount
              amountCents
              currency
              symbol
              display
            }
          }
          shippingPrice {
            display {
              amount
              amountCents
              currency
              symbol
              display
            }
            original {
              amount
              amountCents
              currency
              symbol
              display
            }
          }
          totalPrice {
            display {
              amount
              amountCents
              currency
              symbol
              display
            }
            original {
              amount
              amountCents
              currency
              symbol
              display
            }
          }
        }
      }
    }
  }
}`;

export const updateNegotiationMutation = gql`mutation Commons_Offers_UpdateOfferForm($input: Input_core_apimessages_UpdateNegotiationRequest){
  updateNegotiation(input: $input) {
    negotiation {
      id
      state
      lastOffer {
        prices {
          price {
            display {
              display
              amountCents
              currency
            }
          }
          shippingPrice {
            display {
              display
            }
          }
          totalPrice {
            display {
              display
            }
          }
        }
      }
    }
  }
}`;

// Auto Offers
export const AutoOfferListingFragment = gql`
  fragment AutoOfferListingFragment on Listing {
    _id
    id
    title
    price {
      amount
      amountCents
      currency
      symbol
      display
    }
    condition {
      displayName
    }
    sellerCost {
      display
    }
    taxIncluded
    taxIncludedHint
    sellerPrice {
      amountCents
      display
      currency
    }
    counts {
      watchers
    }
    autoOffers {
      id
      listingId
      message
      price {
        amount
        currency
      }
      percentage
      shippingPriceOverride {
        amount
        amountCents
        currency
        symbol
        display
      }
    }
    shipmentPackage {
      _id
    }
  }
`;

export const createAutoOfferMutation = withGraphql<
{},
CoreAutoOffersCreateAutoOfferMutation.Mutation,
CoreAutoOffersCreateAutoOfferMutation.Variables
>(
  gql`
    mutation Core_AutoOffers_CreateAutoOfferMutation(
      $input: Input_core_apimessages_CreateAutoOfferRequest
    ) {
      createAutoOffer(input: $input) {
        autoOffer {
          id
          listingId
          message
          price {
            amount
            amountCents
            currency
            symbol
            display
          }
          percentage
          shippingPriceOverride {
            amount
            amountCents
            currency
            symbol
            display
          }
        }
      }
    }
  `,
  {
    name: 'createAutoOffer',
  },
);

export const deleteAutoOfferMutation = withGraphql<
{},
CoreAutoOffersDeleteAutoOfferMutation.Mutation,
CoreAutoOffersDeleteAutoOfferMutation.Variables
>(
  gql`
    mutation Core_AutoOffers_DeleteAutoOfferMutation(
      $input: Input_core_apimessages_DeleteAutoOfferRequest
    ) {
      deleteAutoOffer(input: $input) {
        autoOffer {
          id
          listingId
          message
          price {
            amount
            amountCents
            currency
            symbol
            display
          }
          percentage
          shippingPriceOverride {
            amount
            amountCents
            currency
            symbol
            display
          }
        }
      }
    }
  `,
  {
    name: 'deleteAutoOffer',
  },
);
