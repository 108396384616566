import { Listing } from '@reverbdotcom/commons/src/gql/graphql';
import { ISellerListingsCollectionState } from './SellerListingsCollectionContext';

export enum ActionType {
  ADD_INITIAL_LISTINGS = 'addInitialListings',
  REMOVE_LISTINGS = 'removeListings',
  SELECT_ALL_LISTINGS = 'selectAllListings',
  DESELECT_ALL_LISTINGS = 'deselectAllListings',
  SELECT_LISTING = 'selectListing',
  DESELECT_LISTING = 'deselectListing',
  UPDATE_LISTINGS = 'updateListings',
  LOAD_LISTINGS = 'loadListings',
  RESET_LOADING = 'resetListing',
  SELECT_ALL_MATCHES = 'selectAllMatches',
  DESELECT_ALL_MATCHES = 'deselectAllMatches',
}

export interface SellerListing extends Listing {
  loading?: boolean;
}

interface IPayload {
  listingIds?: string[];
  listing?: SellerListing;
  listings?: SellerListing[];
}

interface IAction {
  type: ActionType;
  payload?: IPayload;
}

export default function sellerListingsCollectionReducer(state: ISellerListingsCollectionState, action: IAction): ISellerListingsCollectionState {
  switch (action.type) {
    case ActionType.ADD_INITIAL_LISTINGS: {
      const { listings } = action.payload;

      return {
        ...state,
        ...{ listings, showEmptyState: !listings.length },
      };
    }

    case ActionType.REMOVE_LISTINGS: {
      const { listings, selectedListings } = state;
      const { listingIds: payloadListingIds } = action.payload;

      const updatedListings = listings.filter(listing => !payloadListingIds.includes(listing.id));
      const updatedSelectedListings = selectedListings.filter(listing => !payloadListingIds.includes(listing.id));

      return {
        ...state,
        ...{
          listings: updatedListings,
          selectedListings: updatedSelectedListings,
          showEmptyState: !updatedListings.length,
        },
      };
    }

    case ActionType.SELECT_ALL_MATCHES: {
      return {
        ...state,
        ...{ hasAllMatchesSelected: true },
      };
    }

    case ActionType.DESELECT_ALL_MATCHES: {
      return {
        ...state,
        ...{ hasAllMatchesSelected: false },
      };
    }

    case ActionType.SELECT_ALL_LISTINGS: {
      return {
        ...state,
        ...{ selectedListings: state.listings },
      };
    }

    case ActionType.DESELECT_ALL_LISTINGS: {
      return {
        ...state,
        ...{ selectedListings: [] },
      };
    }

    case ActionType.SELECT_LISTING: {
      const updatedListings = [...state.selectedListings, ...[action.payload.listing]];

      return {
        ...state,
        ...{ selectedListings: updatedListings },
      };
    }

    case ActionType.DESELECT_LISTING: {
      const updatedListings = state.selectedListings.filter(listing => action.payload.listing.id != listing.id);

      return {
        ...state,
        ...{ selectedListings: updatedListings, hasAllMatchesSelected: false },
      };
    }

    case ActionType.UPDATE_LISTINGS: {
      const loadingParams = { loading: false };

      const updatedListings = state.listings.map(listing => {
        const payloadListing = action.payload.listings.find(payloadListing => payloadListing.id == listing.id);

        if (payloadListing) {
          return { ...payloadListing, ...loadingParams };
        } else {
          return listing;
        }
      });

      const updatedSelectedListings = state.selectedListings.map(listing => {
        const payloadListing = action.payload.listings.find(payloadListing => payloadListing.id == listing.id);

        if (payloadListing) {
          return { ...payloadListing, ...loadingParams };
        } else {
          return listing;
        }
      });

      return {
        ...state,
        ...{
          listings: updatedListings,
          selectedListings: updatedSelectedListings,
        },
      };
    }

    case ActionType.LOAD_LISTINGS: {
      const loadingParams = { loading: true };

      const updatedListings = state.listings.map(listing => {
        const payloadListingId = action.payload.listingIds.find(listingId => listingId == listing.id);

        if (payloadListingId) {
          return { ...listing, ...loadingParams };
        } else {
          return listing;
        }
      });

      const updatedSelectedListings = state.selectedListings.map(listing => {
        const payloadListingId = action.payload.listingIds.find(listingId => listingId == listing.id);

        if (payloadListingId) {
          return { ...listing, ...loadingParams };
        } else {
          return listing;
        }
      });

      return {
        ...state,
        ...{
          listings: updatedListings,
          selectedListings: updatedSelectedListings,
        },
      };
    }

    case ActionType.RESET_LOADING: {
      const loadingParams = { loading: false };

      const updatedListings = state.listings.map(listing => {
        return {
          ...listing,
          ...loadingParams,
        };
      });

      const updatedSelectedListings = state.selectedListings.map(listing => {
        return {
          ...listing,
          ...loadingParams,
        };
      });

      return {
        ...state,
        ...{
          listings: updatedListings,
          selectedListings: updatedSelectedListings,
        },
      };
    }

    default: {
      return { ...state };
    }
  }
}
