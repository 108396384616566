import React from 'react';
import I18n from 'i18n-js';
import { RCAlertBox } from '@reverbdotcom/cadence/components';

export default function CannotAddNewCardWarning() {
  return (
    <div className="mt-4">
      <RCAlertBox type="info" small>
        {I18n.t('discovery.selectableCreditCardList.cannotAddNewCard')}
      </RCAlertBox>
    </div>
  );
}
