// TODO update this to import from commons
// import { gql } from '@reverbdotcom/commons/src/gql';
// eslint-disable-next-line no-restricted-imports
import { gql } from '@apollo/client';
import { trackEvent } from '@reverbdotcom/commons/src/elog/mparticle_tracker';
import {
  AddressBookClickInteraction,
  CartClickInteraction,
  CheckoutClickEvent,
  CheckoutClickInteraction,
  CreditCardWalletClickInteraction,
  MParticleEventName,
} from '@reverbdotcom/commons/src/elog/mparticle_types';
import {
  CheckoutTrackingFields,
  core_apimessages_CheckoutPaymentMethod_Type as PaymentMethodType,
} from '@reverbdotcom/commons/src/gql/graphql';
import { formatExperimentsForEventAttributes } from '@reverbdotcom/commons/src/elog/mparticle';
import { useUser } from '@reverbdotcom/commons/src/user_hooks';
import React from 'react';

type Checkout = CheckoutTrackingFields.Fragment;
type ClickInteraction =
  | AddressBookClickInteraction
  | CartClickInteraction
  | CheckoutClickInteraction
  | CreditCardWalletClickInteraction;

export interface CheckoutTracker {
  trackClickEvent: (clickInteraction: ClickInteraction, attributes?: Record<string, string>) => void;
}

export const CheckoutTrackerContext = React.createContext<CheckoutTracker>({
  trackClickEvent: (_) => { },
});

export function useCheckoutTrackerProvider(checkout: Checkout) {
  const trackClickEvent = useTrackCheckoutClickEvent(checkout || emptyCheckout);

  return {
    trackClickEvent,
  };
}

export function useCheckoutTracker() {
  return React.useContext(CheckoutTrackerContext);
}

export function checkoutClickEvent({ checkoutClickInteraction, checkoutId, paymentMethod }: {
  checkoutClickInteraction: ClickInteraction,
  checkoutId: string,
  paymentMethod: PaymentMethodType,
}): CheckoutClickEvent {
  return {
    eventName: MParticleEventName.CheckoutClickEvent,
    checkoutClickInteraction,
    checkoutId,
    paymentMethod,
  };
}

function buildCheckoutClickEvent(checkout: Checkout, experiments = '') {
  function trackCheckoutClickEvent(
    interaction: ClickInteraction,
    attributes: Record<string, string> = {},
  ) {
    trackEvent({
      eventName: MParticleEventName.CheckoutClickEvent,
      checkoutClickInteraction: interaction,
      checkoutId: checkout.id,
      paymentMethod: checkout.paymentMethod.type,
      experiments,
      ...attributes,
    });
  }

  return trackCheckoutClickEvent;
}

export function useTrackCheckoutClickEvent(checkout: Checkout, experiments: string[] = []) {
  return buildCheckoutClickEvent(
    checkout,
    formatExperimentsForEventAttributes(useUser(), experiments),
  );
}

const emptyCheckout: Checkout = {
  id: '',
  paymentMethod: {
    type: null,
  },
};

export const CheckoutTrackingFragment = gql`
  fragment CheckoutTrackingFields on Checkout {
    _id
    id
    source
    paymentMethod {
      type
    }
  }
`;
