import React from 'react';
import * as elog from '../elog';

interface IProps {
  fallback?: React.ReactNode;
  errorName?: string;
  errorData?: PlainObject;
}

interface IState {
  hasError: boolean;
}

// This component can be used to wrap a component leveraging React 16's
// error boundaries:https://reactjs.org/docs/error-boundaries.html
//
// When using this component, it will render `fallback` if any of its children (or its childrens' children, etc.)
// throw an Error. If no `fallback` is set, it renders null.
//
// ErrorBoundary does not catch errors for SSR: https://reactjs.org/docs/error-boundaries.html#introducing-error-boundaries
//  The use case for this is to handle catch all scenario where we want to hard break on uncaught errors: e.g. a broken guitar.
//  It should not be used to gracefully fallback for downstream components.
export default class ErrorBoundary extends React.Component<IProps, IState> {
  state = { hasError: false };

  static defaultProps: IProps = {
    errorName: 'runtime-fallback-error-boundary',
    errorData: {},
  };

  static getDerivedStateFromError() {
  // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    elog.error(
      this.props.errorName,
      {
        url: window?.location?.href,
        ...info,
        ...this.props.errorData,
        ...error,
      },
      error,
    );
  }

  render() {
    if (this.state.hasError) {
      const { fallback } = this.props;

      return fallback || null;
    }

    return this.props.children;
  }
}
