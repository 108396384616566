import React from 'react';
import {
  core_apimessages_TaxProfile,
  core_apimessages_TaxProfile_TaxProfileVerificationStatus as VerificationStatus,
} from '@reverbdotcom/commons/src/gql/graphql';
import I18n from 'i18n-js';
import { I18N as Translate } from '@reverbdotcom/commons/src/components/translate';
import { RCButton, RCModalConfirmation } from '@reverbdotcom/cadence/components';
import AddressSummary from '../AddressSummary';
import { BUSINESS } from './tax_profile_constants';
import { TrashIcon } from '@reverbdotcom/cadence/icons/react';


interface ITaxProfileProps {
  handleDelete: () => void;
  handleEditClick: () => void;
  taxProfile: core_apimessages_TaxProfile,
}

export default function USTaxProfile({
  handleDelete,
  handleEditClick,
  taxProfile,
}: ITaxProfileProps) {
  const maskedTin = `${taxProfile.tinType === BUSINESS ? 'XX-XXX' : 'XXX-XX-'}${taxProfile.mask}`;

  return (
    <>
      <div className="scaling-padding-4 bd-1 bd--offwhite padding-2 mb-6">
        {taxProfile.tinType === BUSINESS ? (
          <Translate className="weight-bold" text="discovery.taxProfile.usTaxProfile.ein" />
        ) : (
          <Translate className="weight-bold" text="discovery.taxProfile.usTaxProfile.ssn" />
        )}
        <p>{maskedTin}</p>

        {taxProfile.tinType === BUSINESS ? (
          <Translate className="weight-bold" text="discovery.taxProfile.usTaxProfile.businessName" />
        ) : (
          <Translate className="weight-bold" text="discovery.taxProfile.usTaxProfile.legalName" />
        )}
        <p>{taxProfile.name}</p>

        <Translate className="weight-bold" text="discovery.taxProfile.usTaxProfile.address" />
        <AddressSummary address={taxProfile.address} />
        {taxProfile.address?.phone}
      </div>
      {taxProfile.verificationStatus !== VerificationStatus.PENDING_VERIFICATION && (
        <div className="d-flex gap-2 fx-align-center fx-justify-center">
          <RCButton
            onClick={handleEditClick}
          >
            <Translate text="discovery.taxProfile.usTaxProfile.editTaxProfile" />
          </RCButton>

          <RCModalConfirmation
            title={I18n.t('discovery.taxProfile.usTaxProfile.deleteTaxProfile')}
            svgComponent={TrashIcon}
            actions={{
              primary: {
                initialFocus: false,
                buttonText: I18n.t('discovery.taxProfile.usTaxProfile.delete'),
                variant: 'filled',
                theme: 'danger',
                onClick: handleDelete,
              },
              secondary: {
                initialFocus: true,
                buttonText: I18n.t('discovery.taxProfile.usTaxProfile.cancel'),
                variant: 'muted',
              },
            }}
            trigger={
              <RCButton
                variant="transparent"
              >
                <Translate text="discovery.taxProfile.usTaxProfile.delete" />
              </RCButton>
            }
          >
            <Translate text="discovery.taxProfile.usTaxProfile.confirmDeleteTitle" />
          </RCModalConfirmation>
        </div>
      )}
    </>
  );
}
