import {
  MParticleEventName,
  ProductActionType,
  trackEvent,
} from '@reverbdotcom/commons/src/elog/mparticle_tracker';
import { Commons_MParticle_ListingQuery } from '@reverbdotcom/commons/src/gql/graphql';
import { WatchParentComponentNames } from '@reverbdotcom/commons/src/event_tracking/watchlist';

type Listing = Commons_MParticle_ListingQuery['listings'][0];

export function trackRemoveFromCartClick(listing: Listing) {
  if (!listing) return;

  trackEvent({
    eventName: MParticleEventName.ClickedRemoveFromCart,
    listing,
  });
}

export function trackQuantitySelect(listing: Listing) {
  if (!listing) return;

  trackEvent({
    eventName: MParticleEventName.SelectedCartItemQuantity,
    listing,
  });
}

export function trackRemoveFromCart(listing: Listing) {
  if (!listing) return;

  trackEvent({
    eventName: MParticleEventName.RemoveFromCart,
    listing,
    productActionType: ProductActionType.RemoveFromCart,
    source: 'removeFromCart',
  });
}

export function trackMoveToWatchListClick(listing: Listing) {
  if (!listing) return;

  trackEvent({
    eventName: MParticleEventName.AddToWishlist,
    listing,
    productActionType: ProductActionType.AddToWishlist,
    source: 'addToWishlist',
    componentName: WatchParentComponentNames.Cart,
  });

  trackRemoveFromCart(listing);
}
