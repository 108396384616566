import { ChildProps } from '@apollo/client/react/hoc';
// TODO update this to import from commons
// import { gql } from '@reverbdotcom/commons/src/gql';
// eslint-disable-next-line no-restricted-imports
import { gql } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { RCAlertBox, RCButton, RCIconButton, RCTextArea } from '@reverbdotcom/cadence/components';
import RemoveIcon from '@reverbdotcom/cadence/icons/svgs/remove.svg?component';
import { withGraphql } from '@reverbdotcom/commons/src/with_graphql';
import {
  CreateMyFeedbackMessage,
  DeleteMyFeedbackMessage,
  MyFeedbackMessagesQuery,
} from '@reverbdotcom/commons/src/gql/graphql';
import { useHOCMutation, MutationFunction } from '@reverbdotcom/commons/src/useHOCMutation';
import { connect } from '@reverbdotcom/commons/src/connect';
import I18n from 'i18n-js';

type CreateMyFeedbackMessageMutation = MutationFunction<
  CreateMyFeedbackMessage.Mutation,
  CreateMyFeedbackMessage.Variables
>;

type DeleteMyFeedbackMessageMutation = MutationFunction<
  DeleteMyFeedbackMessage.Mutation,
  DeleteMyFeedbackMessage.Variables
>;

interface MutationProps {
  createMyFeedbackMessage: CreateMyFeedbackMessageMutation;
  deleteMyFeedbackMessage: DeleteMyFeedbackMessageMutation;
}

interface ExternalProps {
  messages: MyFeedbackMessagesQuery['myFeedbackMessages']['feedbackMessages'];
}

type IProps = ChildProps<ExternalProps & MutationProps>;

export function FeedbackMessages(props: IProps) {
  const [messages, setMessages] = useState(props.messages);
  const [newMessageBody, setNewMessageBody] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [showForm, setShowForm] = useState(messages.length == 0);
  const [createFeedbackMessage] = useHOCMutation(props.createMyFeedbackMessage);
  const [deleteFeedbackMessage] = useHOCMutation(props.deleteMyFeedbackMessage);

  useEffect(() => {
    if (messages.length == 0) {
      setShowForm(true);
    }
    setErrorMessage('');
  }, [messages.length]);

  async function removeMessage(id) {
    const result = await deleteFeedbackMessage({
      variables: {
        input: {
          id: id,
        },
      },
    });
    if (result.errors.length) {
      setErrorMessage(result.errors[0].message);
    } else {
      setMessages(prevMessages => prevMessages.filter(message => message.id !== id));
    }
  }

  async function handleSubmit(e) {
    e.preventDefault();

    const result = await createFeedbackMessage({
      variables: {
        input: {
          message: newMessageBody,
        },
      },
    });

    if (result.errors.length) {
      setErrorMessage(result.errors[0].message);
    } else {
      setMessages([
        ...messages,
        { id: result.data.createMyFeedbackMessage.id, message: newMessageBody },
      ]);
      setShowForm(false);
      setNewMessageBody('');
    }
  }

  return (
    <div className="d-flex fx-dir-col fx-gap-3">
      {errorMessage != '' &&
        <RCAlertBox type="error" onDismiss={() => {
          setErrorMessage('');
        }}>
          <p>
            {errorMessage}
          </p>
        </RCAlertBox>
      }
      {messages.length > 0 &&
        <table id="messagesTable" className="table">
          <thead className="bg-offwhite">
            <tr>
              <th>{I18n.t('discovery.feedbackMessages.table.header')}</th>
            </tr>
          </thead>
          <tbody>
            {messages.map((message) => (
              <tr key={message.id}>
                <td className="d-flex fx-justify-between fx-align-center">
                  <span>{message.message}</span>
                  <RCIconButton
                    svgComponent={RemoveIcon}
                    label={I18n.t('discovery.feedbackMessages.actions.removeMessage')}
                    variant="muted"
                    onClick={() => removeMessage(message.id)}></RCIconButton>
                </td>
              </tr>))}
          </tbody>
        </table>
      }
      <div>
        <br />
        {!showForm &&
          <RCButton
            size="small"
            theme="main"
            variant="filled"
            id="addNewMessage"
            onClick={() => setShowForm(true)}
          >
            {I18n.t('discovery.feedbackMessages.addAnother')}
          </RCButton>
        }
        {showForm &&
          (<form id="addMessageForm" onSubmit={handleSubmit}>
            <RCTextArea
              errorText=""
              helpText=""
              label={I18n.t('discovery.feedbackMessages.form.addNew')}
              maxRows={30}
              minRows={3}
              id="newMessageBody"
              name="newMessageBody"
              onChange={(e) => setNewMessageBody(e.target.value)}
              placeholder={I18n.t('discovery.feedbackMessages.form.placeholder')}
              tag="required"
              value={newMessageBody}
            />
            <div className="d-flex fx-justify-end fx-gap-1">
              {messages.length > 0 &&
                <RCButton
                  size="small"
                  theme="main"
                  variant="transparent"
                  id="cancel"
                  onClick={() => {
                    setShowForm(false);
                  }}
                >
                  {I18n.t('discovery.feedbackMessages.form.cancel')}
                </RCButton>
              }
              <RCButton
                isSubmit
                size="small"
                theme="main"
                variant="filled"
                id="addMessage"
                disabled={newMessageBody.length == 0}
              >
                {I18n.t('discovery.feedbackMessages.form.add')}
              </RCButton>
            </div>
          </form>)
        }
      </div>
    </div>
  );
}

export const CreateFeedbackMessageMutation = gql`
  mutation CreateMyFeedbackMessage($input: Input_core_apimessages_CreateMyFeedbackMessageRequest) {
    createMyFeedbackMessage(input: $input) {
      id
    }
  }
`;

export const DeleteFeedbackMessageMutation = gql`
  mutation DeleteMyFeedbackMessage($input: Input_core_apimessages_DeleteMyFeedbackMessageRequest) {
    deleteMyFeedbackMessage(input: $input){
      id
    }
  }
`;

const withCreateMessageMutation = withGraphql<IProps, CreateMyFeedbackMessage.Mutation, CreateMyFeedbackMessage.Variables>(
  CreateFeedbackMessageMutation,
  {
    name: 'createMyFeedbackMessage',
  },
);

const withDeleteMessageMutation = withGraphql<IProps, DeleteMyFeedbackMessage.Mutation, DeleteMyFeedbackMessage.Variables>(
  DeleteFeedbackMessageMutation,
  {
    name: 'deleteMyFeedbackMessage',
  },
);

export default connect<ExternalProps>([
  withCreateMessageMutation,
  withDeleteMessageMutation,
])(FeedbackMessages);
