import React from 'react';
import I18n from 'i18n-js';

import { US_CON_MIDWEST, US_CON_WEST, US_CON_SOUTH, US_CON_NORTHEAST, US_CON, CANADA_PROVINCIAL_CODES, REGIONAL_AREA_TYPES } from '@reverbdotcom/commons/src/constants';
import { RCAlertBox, RCTag, RCCheckbox } from '@reverbdotcom/cadence/components';
import { parseAmount, isZeroAmount } from '@reverbdotcom/commons/src/money';
import CoreLink from '@reverbdotcom/commons/src/components/core_link';

import { core_apimessages_Money as IMoney } from '@reverbdotcom/commons/src/gql/graphql';
import {
  ManualPriceEntry,
  PriceField,
  IProps,
  priceInputErrorMessage,
} from './edit_shipping_rate_card_manual_prices';
import URLHelpers from './url_helpers';
import RegionalRateGroup from './edit_shipping_rate_card_regional_rate_group';
import RegionalShippingModal from './edit_shipping_rate_card_regional_modal';
import { EditShippingRateCardExpeditedInfo } from './edit_shipping_rate_card_expedited';
import { getLocalizedRegionName } from '../shipping_profiles/shipping_profile_rate_card';

export enum RegionalRateType {
  STANDARD = 'standard',
  EXPEDITED = 'expedited',
}

export interface IRegionalRate {
  rate: IMoney;
  areas: string[];
}

function RateTypeHeader({ rateType, localizedAreaType }: { rateType: RegionalRateType, localizedAreaType: string }) {
  return (
    <div className="shipping-rate-card__header">
      <div className="shipping-rate-card__titleblock">
        <h4 className="shipping-rate-card__title">
          {I18n.t(`discovery.dashboard.selling.shippingRates.shippingModeRadios.regional.header.${rateType}.title`)}
          {rateType === RegionalRateType.EXPEDITED && (
            <span className="shipping-rate-card__title__tag">
              <RCTag>
                {I18n.t(`discovery.dashboard.selling.shippingRates.shippingModeRadios.regional.header.${rateType}.badgeText`)}
              </RCTag>
            </span>
          )}
        </h4>
        <div className="shipping-rate-card__header__subheading">
          {rateType === RegionalRateType.STANDARD &&
              I18n.t(`discovery.dashboard.selling.shippingRates.shippingModeRadios.regional.header.${rateType}.helpText`)
          }
          {rateType === RegionalRateType.EXPEDITED && (
            <>
              <div>
                {I18n.t(`discovery.dashboard.selling.shippingRates.shippingModeRadios.regional.header.${rateType}.recommended`)}
                <CoreLink
                  to={URLHelpers.expeditedShippingHelpPath}
                  target="_blank"
                  className="shipping-rate-card__inline-link"
                >
                  {I18n.t(`discovery.dashboard.selling.shippingRates.shippingModeRadios.regional.header.${rateType}.learn`)}
                </CoreLink>
              </div>
              <div>
                {I18n.t(`discovery.dashboard.selling.shippingRates.shippingModeRadios.regional.header.${rateType}.default`, {
                  areaType: localizedAreaType,
                })}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export function EditShippingRateCardRegionalPrices({
  shippingRate,
  handleEditShippingRate,
  currency,
  allowedToShipExpedited,
}: IProps) {
  const [expeditedRegionalRates, setExpeditedRegionalRates] = React.useState(shippingRate.expeditedRegionalRates || []);
  const [standardRegionalRates, setStandardRegionalRates] = React.useState(shippingRate.standardRegionalRates || []);
  const [isCombinedShippingEnabled, setIsCombinedShippingEnabled] = React.useState(!!shippingRate[PriceField.COMBINED]?.amount);

  // Modal State
  const [modalType, setModalType] = React.useState<RegionalRateType | ''>('');
  const [modalEditMode, setModalEditMode] = React.useState(false);
  const [currentRate, setCurrentRate] = React.useState('');
  const [currentAreas, setCurrentAreas] = React.useState<string[]>([]);
  const [disabledAreas, setDisabledAreas] = React.useState<string[]>([]);
  const [inFreeMode, setInFreeMode] = React.useState(false);
  const localizedAreaType = I18n.t(`discovery.dashboard.selling.shippingRates.shippingModeRadios.regional.${REGIONAL_AREA_TYPES[shippingRate.regionCode]}s`).toLowerCase();

  function expeditedRateErrorMessage() {
    const expeditedRateZeroErrorMessage = isZeroAmount(shippingRate[PriceField.EXPEDITED]?.amount) ? I18n.t('discovery.dashboard.selling.shippingRates.shippingModeRadios.regional.freeNotAllowedInPaidRegionalExpedited', { zeroAmount: parseAmount('0', currency).display }) : '';
    return priceInputErrorMessage(shippingRate, PriceField.EXPEDITED, true) || expeditedRateZeroErrorMessage;
  }

  const hasRegionalRates = standardRegionalRates.length > 0 || expeditedRegionalRates.length > 0;
  const expeditedRateError = expeditedRateErrorMessage();

  React.useEffect(() => {
    const regionalRates = modalType === RegionalRateType.EXPEDITED ? expeditedRegionalRates : standardRegionalRates;

    setDisabledAreas(findDisabledAreas(regionalRates));
  }, [expeditedRegionalRates, standardRegionalRates, modalType]);

  function handleAreaSelect(event: React.ChangeEvent<HTMLInputElement>, areaCode: string) {
    const updatedAreas = event.target.checked ? [...currentAreas, areaCode] : currentAreas.filter((r) => r !== areaCode);
    setCurrentAreas(updatedAreas);
  }

  function findDisabledAreas(regionalRates) {
    return regionalRates.reduce((accumulator, rate) => {
      const rateAreas = rate.areas.filter((area) => !currentAreas.includes(area));

      return [...accumulator, ...rateAreas];
    }, []);
  }

  function findExistingRatesInCents(): number[] {
    const regionalRates = modalType === RegionalRateType.EXPEDITED ? expeditedRegionalRates : standardRegionalRates;

    return regionalRates.reduce((existingRateAmountCents, regionalRate) => {
      const currentRateAmountCents = regionalRate.rate.amountCents;
      if (modalEditMode && currentRateAmountCents === parseAmount(currentRate, currency).amountCents) {
        return existingRateAmountCents;
      }

      return [...existingRateAmountCents, currentRateAmountCents];
    }, []);
  }

  function handleModalClose() {
    setCurrentRate('');
    setCurrentAreas([]);
    setModalType('');
    setModalEditMode(false);
  }

  function handleModalOpen(rateType: RegionalRateType, initialRate: string, freeMode: boolean) {
    setModalType(rateType);
    setCurrentRate(initialRate);
    setInFreeMode(freeMode);

    if (freeMode) {
      const regionalRates = rateType === RegionalRateType.EXPEDITED ? expeditedRegionalRates : standardRegionalRates;
      const existingRate = regionalRates.find((rr) => rr.rate.amountCents === 0);

      setCurrentAreas(existingRate?.areas || []);
    }
  }

  function newRegionalRate(): IRegionalRate {
    return {
      areas: currentAreas,
      rate: parseAmount(currentRate, currency),
    };
  }

  function handleEditRateGroup(rateType: RegionalRateType, regionalRate: IRegionalRate) {
    setModalEditMode(true);
    setCurrentAreas(regionalRate.areas);
    handleModalOpen(rateType, regionalRate.rate.amount, isZeroAmount(regionalRate.rate.amount));
  }

  function handleDeleteRateGroup(rateType: RegionalRateType, regionalRate: IRegionalRate) {
    if (rateType === RegionalRateType.EXPEDITED) {
      setExpeditedRegionalRates(expeditedRegionalRates.filter((rr) => rr.rate.amountCents !== regionalRate.rate.amountCents));
    } else {
      setStandardRegionalRates(standardRegionalRates.filter((rr) => rr.rate.amountCents !== regionalRate.rate.amountCents));
    }
  }

  function handleSetRegionalRates(regionalRates: IRegionalRate[], setRegionalRates: (rates) => void) {
    const newRate = newRegionalRate();
    const existingRates = regionalRates.map((r) => r.rate.amountCents);

    if (existingRates.includes(newRate.rate.amountCents)) {
      setRegionalRates(regionalRates.map((r) =>
        r.rate.amountCents === newRate.rate.amountCents ?
          { ...r, areas: newRate.areas } : r,
      ));
    } else {
      setRegionalRates([...regionalRates, newRate]);
    }
  }

  function handleSubmit() {
    if (modalType === RegionalRateType.EXPEDITED) {
      handleSetRegionalRates(expeditedRegionalRates, setExpeditedRegionalRates);
    } else {
      handleSetRegionalRates(standardRegionalRates, setStandardRegionalRates);
    }

    handleModalClose();
  }

  function regionAreasCount(regionCode) {
    if (regionCode === US_CON) {
      return [US_CON_MIDWEST, US_CON_WEST, US_CON_SOUTH, US_CON_NORTHEAST].flat().length;
    }

    return CANADA_PROVINCIAL_CODES.length;
  }

  return (
    <div className="shipping-rate-card__option-container">
      {hasRegionalRates &&
        <input
          type="hidden"
          value="regional"
          id="shipping_profile[shipping][rates][][rate_type]"
          name="shipping_profile[shipping][rates][][rate_type]"
        />
      }

      {/* Standard Shipping */}
      <RateTypeHeader rateType={RegionalRateType.STANDARD} localizedAreaType={localizedAreaType} />
      <ManualPriceEntry
        rateType={PriceField.STANDARD}
        value={shippingRate[PriceField.STANDARD]?.amount || ''}
        onUpdate={(v) => handleEditShippingRate({
          ...shippingRate,
          [PriceField.STANDARD]: v ? { amount: v } : null,
        })}
        currency={currency}
        error={priceInputErrorMessage(shippingRate, PriceField.STANDARD)}
        label={I18n.t('discovery.dashboard.selling.shippingRates.shippingModeRadios.regional.defaultInputLabel', {
          rateType: I18n.t(`discovery.dashboard.selling.shippingRates.shippingModeRadios.regional.options.rateType.${RegionalRateType.STANDARD}`).toLowerCase(),
        })}
        helpText={I18n.t('discovery.dashboard.selling.shippingRates.shippingModeRadios.regional.defaultInputContinentalUsHelpText', {
          country: getLocalizedRegionName(shippingRate),
          areaType: localizedAreaType,
        })}
        forShippingProfile
        required
      />

      <div className="shipping-rate-card__enable__checkbox">
        <RCCheckbox
          id={`enable_combined_${shippingRate.regionCode}`}
          name={`enable_combined_${shippingRate.regionCode}`}
          label={I18n.t('discovery.dashboard.selling.shippingRates.rateFields.enableCombined')}
          checked={isCombinedShippingEnabled}
          onChange={() => {
            if (isCombinedShippingEnabled) {
              handleEditShippingRate({ ...shippingRate, [PriceField.COMBINED]: null });
            }
            setIsCombinedShippingEnabled(!isCombinedShippingEnabled);
          }}
          disabled={hasRegionalRates}
        />
      </div>

      {hasRegionalRates && (
        <div className="shipping-rate-card__regional__alert__info">
          <RCAlertBox type="info" small>
            {I18n.t('discovery.dashboard.selling.shippingRates.shippingModeRadios.regional.combinedNotAllowed')}
          </RCAlertBox>
        </div>
      )}

      {isCombinedShippingEnabled && (
        <>
          <ManualPriceEntry
            rateType={PriceField.COMBINED}
            value={shippingRate[PriceField.COMBINED]?.amount || ''}
            onUpdate={(v) => handleEditShippingRate({
              ...shippingRate,
              [PriceField.COMBINED]: v ? { amount: v } : null,
            })}
            currency={currency}
            tooltipText={I18n.t('discovery.dashboard.selling.shippingRates.rateFields.combinedShippingTooltip')}
            error={priceInputErrorMessage(shippingRate, PriceField.COMBINED)}
            forShippingProfile
          />
          <div className="shipping-rate-card__regional__alert__info">
            <RCAlertBox type="info" small>
              {I18n.t('discovery.dashboard.selling.shippingRates.shippingModeRadios.regional.regionalNotAllowed')}
            </RCAlertBox>
          </div>
        </>
      )}

      {!isCombinedShippingEnabled && (
        <>
          <div className="shipping-rate-card__title shipping-rate-card__regional-title">
            {I18n.t('discovery.dashboard.selling.shippingRates.shippingModeRadios.regional.options.title', {
              rateType: I18n.t(`discovery.dashboard.selling.shippingRates.shippingModeRadios.regional.options.rateType.${RegionalRateType.STANDARD}`),
            })}
            <span className="shipping-rate-card__title__tag">
              <RCTag>
                {I18n.t('discovery.dashboard.selling.shippingRates.shippingModeRadios.regional.options.badgeText')}
              </RCTag>
            </span>
          </div>

          {/* Free Standard Shipping Group */}
          <RegionalRateGroup
            handleModalOpen={() => handleModalOpen(RegionalRateType.STANDARD, '0.00', true)}
            regionalRates={standardRegionalRates.filter((sr) => sr.rate.amountCents === 0)}
            rateType={RegionalRateType.STANDARD}
            countryCode={shippingRate.regionCode}
            handleDeleteRateGroup={handleDeleteRateGroup}
            handleEditRateGroup={handleEditRateGroup}
            isFree
            disabled={isZeroAmount(shippingRate[PriceField.STANDARD]?.amount)}
          />

          {/* Standard Shipping Group (non-free) */}
          <RegionalRateGroup
            handleModalOpen={() => handleModalOpen(RegionalRateType.STANDARD, '', false)}
            regionalRates={standardRegionalRates.filter((sr) => sr.rate.amountCents !== 0)}
            rateType={RegionalRateType.STANDARD}
            countryCode={shippingRate.regionCode}
            handleDeleteRateGroup={handleDeleteRateGroup}
            handleEditRateGroup={handleEditRateGroup}
            allowCreateButton={findDisabledAreas(standardRegionalRates).length < regionAreasCount(shippingRate.regionCode)}
          />
        </>
      )}

      <hr className="shipping-rate-card__regional__separator" />

      {/* Expedited Shipping */}
      {allowedToShipExpedited &&
        <>
          <RateTypeHeader rateType={RegionalRateType.EXPEDITED} localizedAreaType={localizedAreaType} />
          <ManualPriceEntry
            rateType={PriceField.EXPEDITED}
            value={shippingRate[PriceField.EXPEDITED]?.amount || ''}
            onUpdate={(v) => handleEditShippingRate({
              ...shippingRate,
              [PriceField.EXPEDITED]: v ? { amount: v } : null,
            })}
            currency={currency}
            error={expeditedRateError}
            label={I18n.t('discovery.dashboard.selling.shippingRates.shippingModeRadios.regional.defaultInputLabel', {
              rateType: I18n.t('discovery.dashboard.selling.shippingRates.shippingModeRadios.regional.options.rateType.expedited'),
            })}
            helpText={I18n.t('discovery.dashboard.selling.shippingRates.shippingModeRadios.regional.defaultInputContinentalUsHelpText', {
              country: getLocalizedRegionName(shippingRate),
              areaType: localizedAreaType,
            })}
            forShippingProfile
            optional
          />

          {!isCombinedShippingEnabled && (
            <>
              <div className="shipping-rate-card__title shipping-rate-card__regional-title">
                {I18n.t('discovery.dashboard.selling.shippingRates.shippingModeRadios.regional.options.title', {
                  rateType: I18n.t('discovery.dashboard.selling.shippingRates.shippingModeRadios.regional.options.rateType.expedited'),
                })}
                <span className="shipping-rate-card__title__tag">
                  <RCTag>
                    {I18n.t('discovery.dashboard.selling.shippingRates.shippingModeRadios.regional.options.badgeText')}
                  </RCTag>
                </span>
              </div>

              {/* Free Expedited Shipping Group */}
              <RegionalRateGroup
                handleModalOpen={() => handleModalOpen(RegionalRateType.EXPEDITED, '0.00', true)}
                regionalRates={expeditedRegionalRates.filter((rr) => rr.rate.amountCents === 0)}
                countryCode={shippingRate.regionCode}
                rateType={RegionalRateType.EXPEDITED}
                handleDeleteRateGroup={handleDeleteRateGroup}
                handleEditRateGroup={handleEditRateGroup}
                isFree
                disabled={!!expeditedRateError}
              />

              {/* Expedited Shipping Group (non-free) */}
              <RegionalRateGroup
                handleModalOpen={() => handleModalOpen(RegionalRateType.EXPEDITED, '', false)}
                regionalRates={expeditedRegionalRates.filter((rr) => rr.rate.amountCents !== 0)}
                countryCode={shippingRate.regionCode}
                rateType={RegionalRateType.EXPEDITED}
                handleDeleteRateGroup={handleDeleteRateGroup}
                handleEditRateGroup={handleEditRateGroup}
                allowCreateButton={findDisabledAreas(expeditedRegionalRates).length < regionAreasCount(shippingRate.regionCode)}
                disabled={!!expeditedRateError}
              />
            </>
          )}
        </>
      }
      {!allowedToShipExpedited &&
        <EditShippingRateCardExpeditedInfo />
      }

      <RegionalShippingModal
        modalType={modalType}
        handleModalClose={handleModalClose}
        handleSubmit={handleSubmit}
        rateAmount={currentRate}
        currencyCode={currency}
        setRateAmount={setCurrentRate}
        modalEditMode={modalEditMode}
        shippingRate={shippingRate}
        selectedAreas={currentAreas}
        setSelectedAreas={setCurrentAreas}
        handleAreaSelect={handleAreaSelect}
        disabledAreas={disabledAreas}
        existingRatesInCents={findExistingRatesInCents()}
        regionalRatesOfOtherType={modalType === RegionalRateType.EXPEDITED ? standardRegionalRates : expeditedRegionalRates}
        inFreeMode={inFreeMode}
      />
    </div>
  );
}

export default EditShippingRateCardRegionalPrices;
