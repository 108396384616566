import PropTypes from 'prop-types';
import React from 'react';
import { CURRENCY_SYMBOLS } from '../shared/constants';
import I18n from 'i18n-js';

const BankTransferWarning = function (props) {
  if (props.payoutCurrency === props.defaultCurrencyForBankCountry) {
    return null;
  }

  const currencySymbol = currencyCode =>
    CURRENCY_SYMBOLS[currencyCode.toLowerCase()];

  const inputName = props.nameForField('bank_deposits_confirmed_at');

  const warningHtml = I18n.t(
    'js.dashboard.selling.shopPolicies.directCheckoutProfile.bankAccountFields.bankTransferWarning',
    {
      currencyCode: props.payoutCurrency,
      currencySymbol: currencySymbol(props.payoutCurrency),
      defaultCurrencyCode: props.defaultCurrencyForBankCountry,
      defaultCurrencySymbol: currencySymbol(props.defaultCurrencyForBankCountry),
      strongOpen: '<strong>',
      strongClose: '</strong>',
    },
  );

  return (
    <div
      className={props.className}
      data-bank-transfer-warning
    >
      <label className="custom-checkbox__label normal small">
        <input
          type="checkbox"
          name={inputName}
          required
          defaultChecked={false}
        />
        <span dangerouslySetInnerHTML={{ __html: warningHtml }} />
      </label>
    </div>
  );
};

BankTransferWarning.propTypes = {
  payoutCurrency: PropTypes.string.isRequired,
  defaultCurrencyForBankCountry: PropTypes.string.isRequired,
  nameForField: PropTypes.func.isRequired,
  className: PropTypes.string,
};

export default BankTransferWarning;
