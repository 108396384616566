import React from 'react';
import classNames from 'classnames';

interface ISiteModuleProps {
  title?: JSX.Element | React.ReactNode | string;
  subtitle?: JSX.Element | React.ReactNode | string;
  footerContent?: JSX.Element | React.ReactNode | string;
  loading?: boolean;
  children?: React.ReactNode;
}

export default function SiteModule({
  title,
  subtitle,
  footerContent,
  loading,
  children,
}: ISiteModuleProps) {
  const classes = classNames(
    'site-module',
    { 'site-module--loading': loading },
  );

  return (
    <div className={classes}>
      {(title || subtitle) &&
        <div className="site-module__header">
          {title &&
            <h3 className="site-module__title">
              {title}
            </h3>
          }
          {subtitle &&
            <p className="site-module__subtitle">
              {subtitle}
            </p>
          }
        </div>
      }
      {children &&
        <div className="site-module__content">
          {children}
        </div>
      }
      {footerContent &&
        <div className="site-module__footer">
          {footerContent}
        </div>
      }
    </div>
  );
}
