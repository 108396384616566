import React from 'react';
import I18n from 'i18n-js';

import { RCAlertBox } from '@reverbdotcom/cadence/components';

import {
  core_apimessages_Money as IMoney,
  core_apimessages_NegotiationParty as NegotiationParty,
  core_apimessages_NegotiationAction as NegotiationAction,
  core_apimessages_NegotiationState as NegotiationState,
} from '../gql/graphql';

import { canViewListingPriceRecommendations } from '../user_context_helpers';
import { B_STOCK, BRAND_NEW, NON_FUNCTIONING } from '../condition_helpers';
import { MParticleEventName, trackEvent } from '../elog/mparticle_tracker';


interface IProps {
  offerPrice?: IMoney;
  recommendedLowPrice: IMoney;
  recommendedHighPrice: IMoney;
  belowLowballThreshold?: boolean;
  party: NegotiationParty;
  negotiationAction: NegotiationAction;
  negotiationState?: NegotiationState;
}

export function priceRecommendationCanBeShown(user, shopCurrency, listing) {
  const canViewPriceRecommendation = user && shopCurrency && canViewListingPriceRecommendations(user, shopCurrency);
  if (!canViewPriceRecommendation) {
    return false;
  }

  const isExcludedCondition = [BRAND_NEW, NON_FUNCTIONING, B_STOCK].includes(listing?.condition?.conditionSlug);
  return listing?.price?.amountCents &&
    listing.canonicalProductId &&
    !isExcludedCondition &&
    listing.priceRecommendation?.priceHigh?.amountCents &&
    (listing.priceRecommendation.priceLow?.amountCents || 0) > 0 &&
    listing.price.amountCents <= listing.priceRecommendation.priceHigh.amountCents;
}

export function EstimatedValueAlert({
  recommendedLowPrice,
  recommendedHighPrice,
  party,
  negotiationAction,
  offerPrice = undefined,
  belowLowballThreshold = false,
  negotiationState = undefined,
}: IProps) {
  let alertType, alertMessage, negotiationGuidance;

  const isSeller = party === NegotiationParty.SELLER;
  const outOfRange = offerPrice && recommendedLowPrice?.amountCents && (offerPrice.amountCents < recommendedLowPrice.amountCents);

  if (belowLowballThreshold && !isSeller) {
    alertType = 'error';
    alertMessage = I18n.t('commons.offers.estimatedValueAlert.belowLowballThreshold');
    negotiationGuidance = 'Lowball';
  } else if (outOfRange) {
    alertType = 'warning';
    negotiationGuidance = 'Below Estimated Value';
    if (isSeller) {
      alertMessage = I18n.t('commons.offers.estimatedValueAlert.sellerOutOfRange');
    } else {
      alertMessage = I18n.t('commons.offers.estimatedValueAlert.outOfRange');
    }
  } else {
    alertType = 'info';
    negotiationGuidance = 'Price Guide';
  }

  React.useEffect(() => {
    // We want to know any time price guidance appears or changes, so this event tracking is hooked up to the
    // negotiationGuidance var, which changes based on the offer price the user has entered
    trackEvent({
      eventName: MParticleEventName.InteractedWithOfferFlow,
      role: party.toLowerCase(),
      negotiationAction: negotiationAction.toLowerCase(),
      negotiationGuidance: negotiationGuidance,
      offerStatus: negotiationState ? negotiationState.toLowerCase() : 'new',
    });
  }, [negotiationGuidance]);

  if (!recommendedLowPrice?.amountCents || !recommendedHighPrice?.amountCents) { return null; }

  return (
    <RCAlertBox
      type={alertType}
    >
      <strong>{I18n.t('commons.offers.estimatedValueAlert.label')}</strong> {recommendedLowPrice.display} - {recommendedHighPrice.display}
      {alertMessage && (
        <p>
          {alertMessage}
        </p>
      )}
    </RCAlertBox>
  );
}

export default EstimatedValueAlert;
