import React from 'react';
import I18n from 'i18n-js';
import { ExecutionResult } from 'graphql';

import { ToastCloseType } from '@reverbdotcom/cadence/components/types';
import { trackEvent } from '@reverbdotcom/commons/src/elog/mparticle_tracker';
import { buildClickedAddFavoriteSearch, buildClickedToastMessage } from '@reverbdotcom/commons/src/event_tracking/favorites';
import { useURL } from '@reverbdotcom/commons/src/url_context';
import {
  Core_FindFavoriteQuery,
  UndoDeleteMyFavoriteMutation,
  UpsertMyFavoriteMutation,
  core_apimessages_FavoriteType,
} from '@reverbdotcom/commons/src/gql/graphql';

import { useFindFavorite, FavoriteModal } from './find_favorite_context';
import { FavoriteModalToggleHandler, FavoriteToggleTypes, FindFavoriteProviderUpsertFavoriteModal } from './favorite_modal';
import FavoriteToast from '@reverbdotcom/commons/src/components/favorite_toast';

import { formatExperimentsForEventAttributes } from '@reverbdotcom/commons/src/elog/mparticle';
import { experimentInt } from '@reverbdotcom/commons/src/user_context_helpers';
import { ExperimentGroupValue } from '@reverbdotcom/commons/src/components/experiments/experiment_group';
import experiments from '@reverbdotcom/commons/src/experiments';
import { useUser } from '@reverbdotcom/commons/src/user_hooks';

type Core_FindFavoriteQuery_Favorite = Core_FindFavoriteQuery['findFavorite']['favorite'];

export function FindFavoriteLayout() {
  const { favorite, setFavorite, displayedModal, setDisplayedModal, isToastOpen, setIsToastOpen, favoriteIntentComponent } = useFindFavorite();
  const [hasError, setHasError] = React.useState<boolean>(false);
  const [errorMessage, setErrorMessage] = React.useState<string>();
  const url = useURL();

  const user = useUser();
  const expGroup = experimentInt(user, experiments.FEED_MODAL_V2) as ExperimentGroupValue;
  const expDesc = formatExperimentsForEventAttributes(user, [experiments.FEED_MODAL_V2]);

  const updateFavorite: FavoriteModalToggleHandler = function (key: FavoriteToggleTypes, value: boolean, isExperimentalModal: boolean) {
    let newFavorite = { ...favorite, [key]: value };

    if (key === 'feedEnabled' && !value) { newFavorite = { ...newFavorite, emailEnabled: false }; }
    if (isExperimentalModal && key === 'emailEnabled' && value && !newFavorite.feedEnabled) {
      newFavorite = { ...newFavorite, feedEnabled: true };
    }

    setFavorite(newFavorite);
  };

  const handleUpsert = function (result: ExecutionResult<UpsertMyFavoriteMutation>) {
    if (!result.errors.length) {
      const newFavorite: Core_FindFavoriteQuery_Favorite = {
        ...favorite,
        id: result.data.upsertMyFavorite?.favorite?.id,
        favorited: result.data.upsertMyFavorite?.favorite?.favorited,
      };
      trackEvent(buildClickedAddFavoriteSearch(newFavorite, url, newFavorite.feedEnabled, newFavorite.emailEnabled, favoriteIntentComponent));
      setFavorite(newFavorite);
      setIsToastOpen(true);
    } else {
      setHasError(true);
      setErrorMessage(result.errors[0].message);
      setIsToastOpen(true);
    }
  };

  const handleToastClose = function (closeType: ToastCloseType) {
    setIsToastOpen(false);

    if (closeType === 'dismiss' || closeType === 'swipe') {
      trackEvent(buildClickedToastMessage(url));
    }
  };

  // For some reason, after hitting "Undo" on the "Removed from Favorites" toast,
  // this component re-renders with isToastOpen: false, so the only way I could figure out
  // how to display the toast was by manually setting it here. I think it may be due to having to setIsToastOpen(false)
  // in #useFindFavoriteProvider, which was necessary to fix another bug.
  const handleUndoFavoriteRemove = (result: ExecutionResult<UndoDeleteMyFavoriteMutation>) => {
    if (!result.errors.length) {
      setIsToastOpen(true);
      const newFavorite: Core_FindFavoriteQuery_Favorite = {
        ...favorite,
        favorited: result.data.undoDeleteMyFavorite?.favorite?.favorited,
      };
      setFavorite(newFavorite);
    } else {
      setHasError(true);
      setIsToastOpen(true);
    }
  };

  const modalTitle = I18n.t('discovery.favorites.confirmationModal.saveTitle');

  return (
    <>
      <FindFavoriteProviderUpsertFavoriteModal
        isOpen={displayedModal === FavoriteModal.Save}
        handleClose={() => { setDisplayedModal(FavoriteModal.None); }}
        handleUpdate={updateFavorite}
        modalTitle={modalTitle}
        searchTitle={favorite.title}
        feedEnabled={favorite.feedEnabled}
        emailEnabled={favorite.emailEnabled}
        queryParams={favorite.queryParams}
        handleMutationResult={handleUpsert}
        subtitle={favorite.subtitle}
        expGroup={expGroup}
        expDesc={expDesc}
      />
      <FavoriteToast
        favoriteId={favorite.id}
        isFavorited={favorite.favorited}
        favoriteType={core_apimessages_FavoriteType.SAVED_SEARCH}
        isOpen={isToastOpen}
        handleToastClose={handleToastClose}
        handleMutationResult={handleUndoFavoriteRemove}
        hasError={hasError}
        errorMessage={errorMessage}
        setHasError={setHasError}
      />
    </>
  );
}
