export const COUNTRY_CODES = [
  'AT',
  'BE',
  'DE',
  'ES',
  'FR',
  'GB',
  'IE',
  'IT',
  'NL',
  'PT',
  'UN',
];
