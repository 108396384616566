import I18n from 'i18n-js';

import {
  DiscoTranslations,
  CoreTranslations,
} from '@reverbdotcom/discovery-ui';

import {
  CMSTranslations,
  CommonsTranslations,
  CadenceTranslations,
} from '@reverbdotcom/commons';

// Global config
I18n.fallbacks = true;
I18n.locales.eo = []; // Translator's Locale

I18n.translations.en = CoreTranslations;
I18n.translations.en.discovery = DiscoTranslations;
I18n.translations.en.cms = CMSTranslations;
I18n.translations.en.commons = CommonsTranslations;
I18n.translations.en.cadence = CadenceTranslations;
