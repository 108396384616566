import React from 'react';
import I18n from 'i18n-js';

import MarketingCalloutCard from './marketing_callout_card';
import OverflowingRow from '../../components/overflowing_row';
import Tiles from '../../components/tiles';
import SanitizedRender from '../../components/sanitized_render';

import { IDynamicComponentProps } from '../dynamic_component_props';
import CoreLink from '../../components/core_link';

import { imageUploadURL } from '../../url_helpers';
import { RCGrid } from '@reverbdotcom/cadence/components';
import { MarketingCalloutBanner } from './marketing_callout_banner';

const SIDEBAR_SECTION = 'sidebar';
const COMPONENT_NAME = 'MarketingCallout';

interface CalloutData {
  title: string;
  titleHtml?: string;
  subtitle?: string;
  subtitleHtml?: string;
  image: string;
  targetHref: string;
  channel?: string;
  pageType?: string;
}

export interface IProps extends IDynamicComponentProps {
  callouts: CalloutData[];
  displayAsGrid?: boolean;
  title?: string;
  titleHtml?: string;
  subtitle?: string;
  subtitleHtml?: string;
  ledeHtml?: string;
  ctaText?: string;
  ctaTargetHref?: string;
  tileSize?: string;
  jumplinkSlug?: string;
  trackingName?: string;
  withSidebar?: string;
  bannerFormat?: boolean;
  bannerImage?: string;
}

export function MarketingCallout({
  callouts = [],
  displayAsGrid,
  title,
  titleHtml,
  subtitle,
  subtitleHtml,
  ledeHtml,
  ctaText,
  ctaTargetHref,
  tileSize = 'threeWide',
  jumplinkSlug,
  trackingName = COMPONENT_NAME,
  layoutSection,
  withSidebar,
  bannerFormat,
  bannerImage,
}: IProps) {
  const useRCGrid = !withSidebar;

  const shouldBeTwoWide = tileSize === 'twoWide';
  const shouldBeThreeWide = tileSize === 'threeWide';
  const shouldBeSquare = tileSize === 'squareImages';
  const shouldBeLargeTiles = tileSize === 'largeTiles';
  const shouldBeSmallTiles = tileSize === 'smallTiles';
  const shouldRenderForSidebar = layoutSection === SIDEBAR_SECTION;

  const shouldGrow = shouldBeSquare ? false :
    (shouldBeThreeWide || shouldBeTwoWide || callouts.length === 1);

  // this allows content managers to be able to count on large tiles in this context
  // to be a multiple of 4 (default large tile behavior can result in 5-wide grids)
  const shouldBeFourWideMax = displayAsGrid ? shouldBeLargeTiles : false;

  // we can't center flex items that are expected to overflow
  // and anything singleRow could potentially overflow
  const shouldCenter = displayAsGrid ? (shouldBeThreeWide || shouldBeSquare) : false;

  function renderCallout(calloutData, idx) {
    return (
      <MarketingCalloutCard
        key={calloutData.title}
        channel={calloutData.channel}
        imageURL={imageUploadURL(calloutData.image)}
        pageType={calloutData.pageType}
        subtitle={calloutData.subtitleHtml || calloutData.subtitle}
        targetHref={calloutData.targetHref}
        title={calloutData.titleHtml || calloutData.title}
        wideCard={shouldBeTwoWide && !shouldRenderForSidebar}
        squareImage={shouldBeSquare}
        position={idx}
        smallTile={shouldBeSmallTiles}
        trackingComponentName={trackingName}
        sponsoredContentType={calloutData.sponsoredContentType}
        sponsoredContentPartner={calloutData.sponsoredContentPartner}
      />
    );
  }

  function gridColSpan() {
    if (callouts.length === 1) {
      return { desktop: 12 };
    }
    if (shouldBeTwoWide || callouts.length === 2) {
      return { desktop: 6, mobile: 8 };
    }
    if (shouldBeThreeWide || callouts.length === 3) {
      return { desktop: 4, tablet: 5, mobile: 8 };
    }
    if (shouldBeSmallTiles) {
      return { desktop: 2, tablet: 3, mobile: 5 };
    }
    return { desktop: 3, tablet: 5, mobile: 8 };
  }

  function renderCallouts() {
    return callouts.map((callout, index) => {
      if (useRCGrid && !shouldRenderForSidebar) {
        return (
          <RCGrid.Item
            colSpan={gridColSpan()}
            key={callout.title}
          >
            {renderCallout(callout, index)}
          </RCGrid.Item>
        );
      }
      return renderCallout(callout, index);
    });
  }

  function renderForSidebar() {
    return (
      <div className="marketing-callout-sidebar-container">
        {renderCallouts()}
      </div>
    );
  }

  function renderAction() {
    if (!ctaTargetHref) return null;

    return (
      <CoreLink
        className={displayAsGrid && 'button button--primary'}
        to={ctaTargetHref}
      >
        {ctaText
          ? ctaText
          : I18n.t('cms.marketingCallout.seeMore')}
      </CoreLink>
    );
  }

  function renderTitle() {
    return (
      <SanitizedRender
        html={titleHtml || title}
      />
    );
  }

  if (callouts.length === 0) return null;

  if (shouldRenderForSidebar) {
    return renderForSidebar();
  }

  if (bannerFormat) {
    return (
      <MarketingCalloutBanner
        callouts={callouts}
        title={title}
        subtitle={subtitle}
        ctaText={ctaText}
        ctaTargetHref={ctaTargetHref}
        bannerImage={bannerImage}
      />
    );
  }

  return (
    <OverflowingRow
      title={renderTitle()}
      titleHtml={titleHtml}
      subtitleHtml={shouldBeSquare ? null : subtitleHtml}
      ledeHtml={ledeHtml}
      action={renderAction()}
      appendAction={displayAsGrid}
      id={jumplinkSlug}
    >
      {useRCGrid ? (
        <RCGrid
          singleRowOn={displayAsGrid ? null : 'all'}
          flexboxItems
        >
          {renderCallouts()}
        </RCGrid>
      ) : (
        <Tiles
          grow={shouldGrow}
          twoWide={shouldBeTwoWide}
          threeWide={shouldBeThreeWide || shouldBeSquare}
          largeTiles={shouldBeLargeTiles}
          fourWideMax={shouldBeFourWideMax}
          singleRow={!displayAsGrid}
          expectedCount={callouts.length}
          placeholder={<MarketingCalloutCard />}
          centered={shouldCenter}
        >
          {renderCallouts()}
        </Tiles>
      )}

    </OverflowingRow>
  );
}
