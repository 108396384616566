import { formatExperimentsForEventAttributes } from '@reverbdotcom/commons/src/elog/mparticle';
import { useUser } from '@reverbdotcom/commons/src/user_hooks';
import { trackEvent, MParticleEventName } from '@reverbdotcom/commons/src/elog/mparticle_tracker';

export function useEventTracker({ listingCurrency, parentComponentName, currency, quantity = null, amountCents = null }: {
  listingCurrency: string,
  parentComponentName: string,
  currency: string,
  quantity?: number,
  amountCents?: number,
}) {
  const user = useUser();
  const tracked = [];

  const customTrackedValues = {
    parentComponentName,
    buyerCurrency: user.currency,
    listingCurrency,
    currency,
    quantity,
    amountCents,
  };
  function trackPaypalEvent(eventName: MParticleEventName) {
    trackEvent({
      eventName,
      experiments: formatExperimentsForEventAttributes(user, tracked),
      ...customTrackedValues,
    });
  }

  return trackPaypalEvent;
}
