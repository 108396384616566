// TODO update this to import from commons
// import { gql } from '@reverbdotcom/commons/src/gql';
// eslint-disable-next-line no-restricted-imports
import { gql } from '@apollo/client';
import React from 'react';
import { CreditCardWalletRowFieldsFragment } from '@reverbdotcom/commons/src/gql/graphql';
import CreditCardWalletRowSummary, { CreditCardWalletRowSummaryFragment } from './credit_card_wallet_row_summary';

type CreditCard = CreditCardWalletRowFieldsFragment['creditCards'][0];

interface Props {
  creditCard: CreditCard;
}

export default function CreditCardWalletRow(props: Props) {
  const { creditCard } = props;

  return (
    <CreditCardWalletRowSummary creditCard={creditCard} />
  );
}

export const CreditCardWalletRowFragment = gql`
  fragment CreditCardWalletRowFields on rql_Me {
    _id
    uuid
    creditCards {
      id
    }
    ...CreditCardWalletRowSummaryFields
  }
  ${CreditCardWalletRowSummaryFragment}
`;
