import React from 'react';
import classNames from 'classnames';

import I18n from 'i18n-js';

import SanitizedRender from '../../components/sanitized_render';
import CoreLink from '../../components/core_link';
import { MParticleEventName } from '../../elog/mparticle_types';
import { core_apimessages_ContentSponsorship_SponsorshipType } from '../../gql/graphql';
import AdTag from '../../components/ads/ad_tag';

export const BUYING_GUIDE = 'buying_guide';
export const BEST_SELLERS = 'best_sellers';

const COMPONENT_NAME = 'MarketingCallout';

interface IProps {
  channel?: string;
  imageURL?: string;
  pageType?: string;
  subtitle?: string;
  targetHref?: string;
  title?: string;
  wideCard?: boolean;
  squareImage?: boolean;
  position?: number;
  trackingComponentName?: string;
  smallTile?: boolean;
  sponsoredContentType?: core_apimessages_ContentSponsorship_SponsorshipType;
  sponsoredContentPartner?: string;
}

export default class MarketingCalloutCard extends React.Component<IProps, null> {
  hasTag() {
    return (
      this.props.pageType === BUYING_GUIDE ||
      this.props.pageType === BEST_SELLERS
    );
  }

  getBackgroundImageStyle() {
    if (!this.props.imageURL) return null;

    return { backgroundImage: `url(${this.props.imageURL})` };
  }

  renderTag() {
    const p = this.props.pageType;

    return (
      <p className="marketing-callout__tag">
        {p === BUYING_GUIDE && I18n.t('cms.marketingCallout.buyingGuideSubtitle')}
        {p === BEST_SELLERS && I18n.t('cms.marketingCallout.bestSellers')}
      </p>
    );
  }

  renderSubtitle() {
    if (!this.props.subtitle) return null;

    return (
      <SanitizedRender
        htmlTag="h4"
        className="marketing-callout__subtitle"
        html={this.props.subtitle}
        inlineTagsOnly
        blocklistedTags={['a']}
      />
    );
  }

  renderPlaceholder() {
    const classes = classNames(
      'marketing-callout',
      'marketing-callout--placeholder',
      { 'marketing-callout--wide': this.props.wideCard },
    );

    return (
      <div className={classes}>
        <div className="marketing-callout__text-area">
          <div className="marketing-callout__text">
            <div className="marketing-callout__title" />
          </div>
        </div>
      </div>
    );
  }

  renderPartnershipSponsorshipContent(sponsorshipType, partnershipName) {
    if (
      sponsorshipType === core_apimessages_ContentSponsorship_SponsorshipType.PARTNERSHIP &&
        partnershipName) {
      return (
        <p className="size-80 mb-2 style-italic">
          {I18n.t('commons.contentSponsorship.partnership', { partner: partnershipName })}
        </p>
      );
    }

    return null;
  }

  renderAdSponsorshipContent(sponsorshipType) {
    if (sponsorshipType === core_apimessages_ContentSponsorship_SponsorshipType.AD) {
      return (
        <div className="marketing-callout__ad">
          <AdTag inline small />
        </div>
      );
    }

    return null;
  }

  render() {
    const classes = classNames(
      'marketing-callout',
      { 'marketing-callout--with-tag': this.hasTag() },
      { 'marketing-callout--wide': this.props.wideCard },
      { 'marketing-callout--square': this.props.squareImage },
      { 'marketing-callout--core': this.props.channel === 'core' },
      { 'marketing-callout--small': this.props.smallTile },
    );

    if (!this.props.targetHref) return null;

    const clickEvent = {
      eventName: MParticleEventName.ClickedMarketingCallout,
      componentName: this.props.trackingComponentName || COMPONENT_NAME,
      entityId: this.props.targetHref,
      position: this.props.position,
    };

    return (

      <CoreLink
        className={classes}
        to={this.props.targetHref}
        clickEvent={clickEvent}
        // square image has tweed orange branding; don't flip it in dark mode
        data-theme={this.props.squareImage && 'light'}
      >
        <div className="marketing-callout__inner">
          <div
            className="marketing-callout__image-area"
          >
            <div
              className="marketing-callout__image"
              style={this.getBackgroundImageStyle()}
            >
              {this.renderAdSponsorshipContent(this.props.sponsoredContentType)}
            </div>
          </div>
          <div
            className="marketing-callout__text-area"
          >
            <div
              className="marketing-callout__text"
            >
              {this.hasTag() ? this.renderTag() : this.renderSubtitle()}
              <SanitizedRender
                htmlTag="h4"
                className="marketing-callout__title"
                html={this.props.title}
                inlineTagsOnly
                blocklistedTags={['a']}
              />
            </div>
            {this.renderPartnershipSponsorshipContent(this.props.sponsoredContentType, this.props.sponsoredContentPartner)}
          </div>
        </div>
      </CoreLink>
    );
  }
}
