import React from 'react';
import I18n from 'i18n-js';
import { PacklinkAuthAction } from './packlink_auth_action';

interface Props {
  onClick(): void;
  promptText: string;
  buttonText: string;
}

function PacklinkAccountPrompt({ promptText, onClick, buttonText }: Props) {
  return (
    <div>
      <span>{promptText}</span>
      <button type="button" className="ml-space button-as-link" onClick={onClick}>{buttonText}</button>
    </div>
  );
}

interface ExternalProps {
  switchPrompt(action: PacklinkAuthAction): void;
}

export function PacklinkLoginPrompt({ switchPrompt }: ExternalProps) {
  return (
    <PacklinkAccountPrompt
      onClick={() => switchPrompt(PacklinkAuthAction.Login)}
      promptText={I18n.t('discovery.packlink.haveAccount')}
      buttonText={I18n.t('discovery.packlink.signIn')}
    />
  );
}

export function PacklinkRegisterPrompt({ switchPrompt }: ExternalProps) {
  return (
    <PacklinkAccountPrompt
      onClick={() => switchPrompt(PacklinkAuthAction.Register)}
      promptText={I18n.t('discovery.packlink.needAccount')}
      buttonText={I18n.t('discovery.packlink.signUp')}
    />
  );
}
