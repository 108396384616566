import React from 'react';
import I18N from 'i18n-js';
import classNames from 'classnames';

import { gql } from '@reverbdotcom/commons/src/gql';
import { useQuery } from '@reverbdotcom/commons/src/useQuery';
import { I18N as Translate } from '@reverbdotcom/commons/src/components/translate';
import { parseAmount } from '@reverbdotcom/commons/src/money';
import { useUser } from '@reverbdotcom/commons/src/user_hooks';
import { NudgeTrustBadgeIcon } from '@reverbdotcom/cadence/icons/react';
import { RCIcon, RCChip, RCPopover, RCSkeleton, RCTooltip } from '@reverbdotcom/cadence/components';
import { SellerHub_EarningsQuery, SellerHub_EarningsQueryVariables } from '@reverbdotcom/commons/src/gql/graphql';
import { trackEvent } from '@reverbdotcom/commons/src/elog/mparticle_tracker';
import { MParticleEventName } from '@reverbdotcom/commons/src/elog/mparticle_types';
import CoreLink from '@reverbdotcom/commons/src/components/core_link';

import URLHelpers from '../../components/shared/url_helpers';
import TimePeriods from '../shop_stats/time_periods';

interface ITimePeriodSelect {
  loading: boolean;
  timePeriod?: string;
  setTimePeriod?: (timePeriod: string) => void;
}

const TIME_PERIOD_SELECT_COMPONENT_NAME = 'TimePeriodSelect';
const COMPONENT_NAME = 'Earnings';

export function TimePeriodSelect({ timePeriod, setTimePeriod, loading }: ITimePeriodSelect) {
  const [isOpen, setIsOpen] = React.useState(false);
  const timePeriods = React.useMemo(() => new TimePeriods(timePeriod), [timePeriod]);

  const clickedTimePeriodOption = (optionValue) => {
    setTimePeriod(optionValue);
    setIsOpen(false);
    trackEvent({
      eventName: MParticleEventName.ClickedSellerHub,
      componentName: TIME_PERIOD_SELECT_COMPONENT_NAME,
      clickableName: optionValue,
      clickableType: 'select',
    });
  };

  if (loading) {
    return (
      <div>
        <RCChip
          loading
        >
          <div className="filter-chip__loading-placeholder" />
        </RCChip>
      </div>
    );
  }

  return (
    <RCPopover
      isOpen={isOpen}
      onDismiss={() => setIsOpen(false)}
      loading={loading}
      anchor={
        <RCChip
          loading={loading}
          size="small"
          onClick={() => setIsOpen(!isOpen)}
        >
          {I18N.t(`sellerHub.timePeriods.${timePeriod}`)}
        </RCChip>
      }
    >
      {timePeriods.options.map(option => {
        const selectOptionClasses = classNames('seller-hub__shop-stats__time-period__select-option', {
          'seller-hub__shop-stats__time-period__select-option--selected': option.value === timePeriod,
        });

        return (
          <div
            className={selectOptionClasses}
            key={option.value}
            onClick={() => clickedTimePeriodOption(option.value)}
            onKeyPress={(event) => event.key === 'Enter' && clickedTimePeriodOption(option.value)}
            role="button"
            tabIndex={0}
          >
            {option.display}
          </div>
        );
      })}
    </RCPopover>
  );
}
interface IProps {
  timePeriod?: string;
  setTimePeriod?: (timePeriod: string) => void;
}

export default function Earnings({ timePeriod, setTimePeriod }: IProps) {
  const user = useUser();
  const { data, loading } = useQuery<SellerHub_EarningsQuery, SellerHub_EarningsQueryVariables>(SELLER_HUB_EARNINGS_QUERY, {
    variables: { timePeriod },
  });

  const { earnings } = data?.me?.shop || {};
  const defaultAmount = parseAmount('0', user.currency);
  const earningsDefault = { ordersTotal: defaultAmount, accommodationsTotal: defaultAmount };
  const { ordersTotal, accommodationsTotal } = earnings || earningsDefault;
  const showAccommodations = accommodationsTotal.amountCents > 0;

  if (loading) {
    return (
      <>
        <div className="d-flex mb-3 fx-align-center mobile-fx-wrap">
          <div className="seller-hub__earnings">
            <RCSkeleton width="40rem" height="2em" />
          </div>
          <TimePeriodSelect loading={true} />
        </div>
        <div className="d-flex fx-align-center mb-5">
          <RCSkeleton width="50rem" />
        </div>
      </>
    );
  }

  return (
    <div>
      <div className="d-flex mb-3 fx-align-center mobile-fx-wrap">
        <div className="seller-hub__earnings">
          <Translate text="sellerHub.earnings.youveEarned" />
          <RCTooltip
            text={<Translate text="sellerHub.earnings.tooltipText" />}
            type="underline"
          >
            <span className="seller-hub__earnings-amount">{ordersTotal.display}</span>
          </RCTooltip>
          <Translate text="sellerHub.earnings.onReverb" />
        </div>
        <TimePeriodSelect loading={loading} timePeriod={timePeriod} setTimePeriod={setTimePeriod} />
      </div>
      <div className="d-flex fx-align-start mb-5">
        {showAccommodations &&
          <>
            <RCIcon svgComponent={NudgeTrustBadgeIcon} size="inline" />
            <span className="weight-bold mlr-1">
              {accommodationsTotal.display}
            </span>
            <Translate
              tag="div"
              className="seller-hub__earnings--accommodations"
              text="sellerHub.earnings.fromAccommodations"
              html
            >
              <CoreLink
                to={URLHelpers.sellerProtectionPath}
                clickEvent={{
                  eventName: MParticleEventName.ClickedSellerHub,
                  componentName: COMPONENT_NAME,
                  clickableName: 'Reverb Protections',
                  clickableType: 'link',
                  clickableValue: URLHelpers.sellerProtectionPath,
                }}
              >
                <Translate text="sellerHub.earnings.reverbProtections" />
              </CoreLink>
            </Translate>
          </>
        }
      </div>
    </div>
  );
}

export const SELLER_HUB_EARNINGS_QUERY = gql(`
  query SellerHub_Earnings(
    $timePeriod: String
  ) {
    me {
      _id
      shop {
        _id
        earnings(input: {
          timePeriod: $timePeriod
        }) {
          ordersTotal {
            display
          }
          accommodationsTotal {
            amountCents
            display
          }
        }
      }
    }
  }
`);
