import { ChildProps } from '@apollo/client/react/hoc';
// TODO update this to import from commons
// import { gql } from '@reverbdotcom/commons/src/gql';
// eslint-disable-next-line no-restricted-imports
import { gql } from '@apollo/client';
import React from 'react';
import { isEmpty } from 'lodash';
import I18n from 'i18n-js';

import Metadata from '@reverbdotcom/commons/src/components/metadata';
import CMSCenteredPageHead from '@reverbdotcom/commons/src/cms/headers/cms_centered_page_head';
import { CoreMarketplaceHeader } from '@reverbdotcom/commons/src/gql/graphql';
import { PageSettings, MarketplaceQueryParams, mapUrlParamsForHeader } from './map_params';
import { collectionInfoFragment } from './collection_header_display';
import { Paths } from '@reverbdotcom/commons/src/url_helpers';
import { withGraphql } from '@reverbdotcom/commons/src/with_graphql';

interface ExternalProps {
  params: PlainObject,
  query: MarketplaceQueryParams,
  pageSettings: PageSettings,
  renderCollectionHeaderDescription?: boolean;
  isBrandPage?: boolean;
}

type IProps = ChildProps<ExternalProps, CoreMarketplaceHeader.Query, CoreMarketplaceHeader.Variables>;

function getSubtitle(renderCollectionHeaderDescription, data, query) {
  if (!isEmpty(query)) {
    return null;
  }

  return renderCollectionHeaderDescription ? data?.collectionHeader?.description : null;
}

export function HeaderWithMetadata({ data, renderCollectionHeaderDescription, params, isBrandPage, query }: IProps) {
  const title = data?.searchMetadata?.title;

  function renderHeader() {
    if (!data.collectionHeader) { return null; }

    return (
      <CMSCenteredPageHead
        title={title}
        subtitle={getSubtitle(renderCollectionHeaderDescription, data, query)}
        imageUrl={data?.collectionHeader?.fullBleedImage?.source}
        actionUrl={isBrandPage && Paths.marketplace.expand({
          make: params.brand_slug,
          ...query,
        })}
        searchPlaceholder={isBrandPage && I18n.t('discovery.brandPage.searchBoxPlaceholder')}
      />
    );
  }

  return (
    <>
      <Metadata title={title} />
      {renderHeader()}
    </>
  );
}

export const headerWithMetadataQuery = gql`
  query Core_Marketplace_Header(
    $collectionType: core_apimessages_CollectionHeader_CollectionType
    $collectionSlug: String
    $skipCollectionHeader: Boolean!
    $categorySlugs: [String]
    $brandSlugs: [String]
    $conditionSlugs: [String]
    $curatedSetSlug: String
    $shopSlug: String
    $yearMin: String
    $yearMax: String
    $query: String
  ) {
    collectionHeader(input: {
      collectionType: $collectionType
      collectionSlug: $collectionSlug
    }) @skip (if: $skipCollectionHeader) {
      _id
    ... CollectionInfo
    }
    searchMetadata(input: {
      categorySlugs: $categorySlugs
      brandSlugs: $brandSlugs
      conditionSlugs: $conditionSlugs
      curatedSetSlug: $curatedSetSlug
      shopSlug: $shopSlug
      yearMin: $yearMin
      yearMax: $yearMax
      query: $query
    }) {
      title
    }
  }
  ${collectionInfoFragment}
`;

const connect = withGraphql<
  IProps,
  CoreMarketplaceHeader.Query,
  CoreMarketplaceHeader.Variables
>(headerWithMetadataQuery, {
  options: (ownProps) => {
    return {
      ssr: true,
      variables: {
        ...mapUrlParamsForHeader(
          ownProps.params,
          ownProps.query,
          ownProps.pageSettings || {},
        ),
      },
    };
  },
});

export default connect(HeaderWithMetadata);
