import { pickBy, isEmpty, isNumber } from 'lodash';
import { UpdateUrlArgs } from './components/with_update_url';

export function defaultUpdateLocation({ location, newParams, anchor }: UpdateUrlArgs) {
  let hash;
  if (anchor) {
    hash = `#${anchor}`;
  }

  return {
    pathname: location.pathname,
    query: updateParams(location.query, newParams),
    hash,
  };
}

export function updateParams(
  currentParams: PlainObject,
  newParam: PlainObject,
): PlainObject {
  const newParams = { ...currentParams, ...newParam };

  if (!('page' in newParam) || parseInt(newParam.page, 10) <= 1) {
    delete newParams.page;
  }

  Object.keys(newParams).forEach((key) => {
    const values = newParams[key];

    // If a param is an array of length 1, unwrap and just return the value
    if (values && values.length === 1) {
      newParams[key] = values[0];
    }
  });

  return pickBy(newParams, val => isNumber(val) || !isEmpty(val));
}
