import React from 'react';
import classNames from 'classnames';

import CoreLink from '../../components/core_link';
import MarkdownOutput from './markdown_output';

import { imageUploadURL } from '../../url_helpers';
import { IDynamicComponentProps } from '../dynamic_component_props';

export interface IProps extends IDynamicComponentProps {
  alignment: string;
  caption?: string;
  captionHtml?: string;
  image_key: string;
  url?: string;
  cdn?: string;
  markdownParser?: any;
}

export default class ImageEmbed extends React.Component<IProps, null> {
  get imageURI() {
    return imageUploadURL(this.props.image_key, this.props.cdn);
  }

  renderImage() {
    return (
      <img
        src={this.imageURI}
        alt={this.props.caption}
      />
    );
  }

  renderImageLink() {
    if (!this.props.url) { return this.renderImage(); }

    if (this.props.url.startsWith('http')) {
      return (
        <a href={this.props.url} target="_blank" rel="noopener noreferrer nofollow">
          {this.renderImage()}
        </a>
      );
    }

    return (
      <CoreLink to={this.props.url}>
        {this.renderImage()}
      </CoreLink>
    );
  }

  render() {
    const classes = classNames(
      'image-embed',
      { 'image-embed--left': this.props.alignment === 'left' },
      { 'image-embed--right': this.props.alignment === 'right' },
      { 'image-embed--center': this.props.alignment === 'center' },
    );

    return (
      <div
        className={classes}
      >
        <div className="image-embed__inner">
          {this.renderImageLink()}
          <MarkdownOutput
            text={this.props.caption}
            textHtml={this.props.captionHtml}
            markdownParser={this.props.markdownParser}
            className="image-embed__caption"
          />
        </div>
      </div>
    );
  }
}
