import { get } from 'lodash';

import RouterHistory from '@reverbdotcom/commons/src/cms/lib/router_history';
import { RECENT_SEARCH, SALE_SUGGESTION_TYPE } from './site_search';
import { Paths } from '../../url_helpers';
import Suggestion from './suggestion';

import { MParticleEventName, trackEvent } from '@reverbdotcom/commons/src/elog/mparticle_tracker';
import { shouldClientSideResolve } from '@reverbdotcom/commons/src/components/core_link';
import { IUser } from '@reverbdotcom/commons/src/components/user_context_provider';

import { formatExperimentsForEventAttributes } from '@reverbdotcom/commons/src/elog/mparticle';
import experiments from '@reverbdotcom/commons/src/experiments';

import {
  reverb_search_CompletionType as CompletionType,
} from '@reverbdotcom/commons/src/gql/graphql';
import { parseURL } from '@reverbdotcom/commons/src/link_parser';

const SELECT_INTERACTION = 'select';
const SUBMIT_INTERACTION = 'submit';

interface ISearchInteractionEvent {
  user: IUser;
  position?: number;
  entityId?: number;
  interactionType?: string;
  context: {
    fullTextQuery?: string;
    type?: string;
    scope?: string;
    suggestions?: string[];
    query?: string;
  };
}

export default class ReverbSiteSearchController {
  static navigateWindow(href: string): void {
    window.location.href = href;
  }

  static async redirectTo(url: string, trackingParams: ISearchInteractionEvent) {
    const clientSide = await shouldClientSideResolve(parseURL(url), RouterHistory.getCurrentLocation());

    trackEvent({
      eventName: MParticleEventName.SiteSearch,
      position: trackingParams.position,
      suggestionId: String(trackingParams.entityId || ''),
      suggestionType: trackingParams.context.type,
      suggestionQuery: trackingParams.context.query,
      suggestionCount: get(trackingParams, 'context.suggestions.length', 0),
      interactionType: trackingParams.interactionType,
      targetUrl: url,
      experiments: formatExperimentsForEventAttributes(trackingParams.user, [
        experiments.DUMMY,
        experiments.MV_DUMMY_COOKIE_BUCKETING,
      ]),
    });

    if (clientSide) {
      RouterHistory.push(url);
    } else {
      ReverbSiteSearchController.navigateWindow(url);
    }
  }

  static selectSuggestion(user: IUser, suggestion: Suggestion, suggestions: Suggestion[], lastTypedValue = ''): void {
    if (suggestion.type === RECENT_SEARCH) {
      ReverbSiteSearchController.submitRecentQuery(user, suggestion, suggestions, lastTypedValue);
      return;
    }

    const trackingEvent: ISearchInteractionEvent = {
      user,
      interactionType: SELECT_INTERACTION,
      position: suggestion.position,
      entityId: get(suggestion, 'completion.id'),
      context: {
        fullTextQuery: suggestion.value,
        type: suggestion.type,
        query: lastTypedValue,
        scope: suggestion.context,
        suggestions: ReverbSiteSearchController.suggestionValues(suggestions),
      },
    };

    if (CompletionType[suggestion.type] || suggestion.type === SALE_SUGGESTION_TYPE) {
      ReverbSiteSearchController.redirectTo(suggestion.url, trackingEvent);
      return;
    }

    const uri = new URL(suggestion.url);
    const hostlessUrl = suggestion.url.replace(uri.origin, '');

    ReverbSiteSearchController.redirectTo(hostlessUrl, trackingEvent);
  }

  private static submitRecentQuery(user: IUser, suggestion: Suggestion, suggestions: Suggestion[], lastTypedValue = ''): void {
    const query = suggestion.value;
    const path = Paths.marketplace.expand({ query });

    const trackingEvent = {
      user,
      interactionType: SELECT_INTERACTION,
      position: suggestion.position,
      context: {
        fullTextQuery: query,
        type: RECENT_SEARCH,
        query: lastTypedValue,
        suggestions: ReverbSiteSearchController.suggestionValues(suggestions),
      },
    };

    ReverbSiteSearchController.redirectTo(path, trackingEvent);
  }

  static submitQuery(user: IUser, query: string, suggestions: Suggestion[], lastTypedValue = ''): void {
    const queryParams = { query: query };
    const path = Paths.marketplace.expand(queryParams);
    const trackingEvent = {
      user,
      interactionType: SUBMIT_INTERACTION,
      context: {
        fullTextQuery: query,
        query: lastTypedValue,
        suggestions: ReverbSiteSearchController.suggestionValues(suggestions),
      },
    };

    ReverbSiteSearchController.redirectTo(path, trackingEvent);
  }

  private static suggestionValues(suggestions: Suggestion[]): string[] {
    return suggestions ? suggestions.map(s => s.value) : [];
  }
}
