import React from 'react';
import { MyShopCampaignCouponFieldsFragment } from './gql/graphql';

export type Coupon = MyShopCampaignCouponFieldsFragment;

interface CouponContext {
  coupon: Coupon;
}

export default React.createContext<CouponContext>({ coupon: null });
