import PropTypes from 'prop-types';
import React from 'react';
import AjaxMultiAddToCartButton from './ajax_multi_add_to_cart_button';

// eslint-disable-next-line
import withMParticleListings from '@reverbdotcom/commons/src/components/with_mparticle_listings';

// Wrapper around AjaxMultiAddToCartButton
// * takes a single product id instead of multiple
// * instead of taking an onError callback, will render the
//   error in place.
class StandaloneAjaxAddToCartButton extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      errorMessage: null,
    };

    this.handleError = this.handleError.bind(this);
  }

  handleError(errorMessage) {
    this.setState({ errorMessage });
  }

  render() {
    if (this.state.errorMessage) {
      return <div className="alert alert-error mb-2">{this.state.errorMessage}</div>;
    }

    return (
      <AjaxMultiAddToCartButton
        listingIds={[this.props.productId]}
        onError={this.handleError}
        text={this.props.text}
        isOwner={this.props.isOwner}
        onPreorder={this.props.onPreorder}
        source={this.props.source}
        zeroPercentAffirmFinancing={this.props.zeroPercentAffirmFinancing}
      />
    );
  }
}

StandaloneAjaxAddToCartButton.propTypes = {
  productId: PropTypes.number.isRequired,
  text: PropTypes.string,
  isOwner: PropTypes.bool,
  onPreorder: PropTypes.bool,
  source: PropTypes.string,
  zeroPercentAffirmFinancing: PropTypes.bool,
};

StandaloneAjaxAddToCartButton.defaultProps = {
  isOwner: false,
  onPreorder: false,
  source: '',
  text: '',
  zeroPercentAffirmFinancing: false,
};

export default StandaloneAjaxAddToCartButton;
