import Suggestion from './suggestion';

export interface ISuggestionGroup {
  heading: string;
  headingAction?: ISuggestionHeadingAction;
  results: Suggestion[];
}

export interface ISuggestionHeadingAction {
  label: string;
  action: () => void;
}

export default class SuggestionCollection {
  public suggestions = [] as ISuggestionGroup[];

  constructor(suggestions: ISuggestionGroup[]) {
    this.suggestions = suggestions;
  }

  get flatSuggestions(): Suggestion[] {
    return this.suggestions.reduce((all, suggestion) => (all.concat(suggestion.results)), []);
  }
}
