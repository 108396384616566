import MultiOrderSelect from '../multi_order_select';
import { store as ShippingLabelStore, actions as ShippingLabelActions } from '../shipping_label_form_module';
import { mapStateToProps } from '@reverbdotcom/discovery-ui';

function mapper() {
  const {
    relatedOrders,
    selectedOrders,
  } = ShippingLabelStore.getState();

  return {
    relatedOrders,
    selectedOrders,
    toggleOrderSelected: ShippingLabelActions.toggleOrderSelected,
    addAllRelatedOrders: ShippingLabelActions.addAllRelatedOrders,
    removeAllRelatedOrders: ShippingLabelActions.removeAllRelatedOrders,
  };
}

export default mapStateToProps({
  mapper, stores: [ShippingLabelStore],
})(MultiOrderSelect, 'MultiOrderSelect');
