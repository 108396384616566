import { isEmpty, isEqual, omit, pick } from 'lodash';
import {
  core_apimessages_Address as Address,
  core_apimessages_AddressEntry as AddressEntry,
} from '@reverbdotcom/commons/src/gql/graphql';

export function isAddressEmpty(address: Address | AddressEntry) {
  const formattedAddress = omit(address, ['countryCode', 'primary', 'uuid']);

  return isEmpty(formattedAddress) || Object.values(formattedAddress).every(field => !field);
}

export function isEntryEmpty(entry: AddressEntry) {
  return isEmpty(pick(entry, userEnteredFields));
}

export function compare(firstAddress: Address, secondAddress: Address) {
  const firstAddressFields = pick(firstAddress, userEnteredFields);
  const secondAddressFields = pick(secondAddress, userEnteredFields);

  return isEqual(firstAddressFields, secondAddressFields);
}

const userEnteredFields = [
  'name',
  'streetAddress',
  'extendedAddress',
  'locality',
  'region',
  'postalCode',
  'countryCode',
];
