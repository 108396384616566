import { core_apimessages_Channel } from '@reverbdotcom/commons/src/gql/graphql';
import { flashExpireAuthError } from '@reverbdotcom/commons/src/flash';

const EXPIRED_TOKEN_ERROR = 'expired_token_error';

export default class BaseClient {
  defaultHeaders: any = {};

  setDefaultHeaders(headers) {
    this.defaultHeaders = {
      ...headers,
      'X-Reverb-App': core_apimessages_Channel.REVERB,
    };
  }

  beforeRequest() {
    // no-op by default -- use this to set up request time
    // headers
  }

  get<T>(url, options: RequestInit = {}): Promise<T> {
    return this.request('GET', url, options);
  }

  post<T>(url, body?, options: RequestInit = {}): Promise<T> {
    if (body) {
      options.body = JSON.stringify(body);
    }

    return this.request('POST', url, options);
  }

  put<T>(url, body?, options: RequestInit = {}): Promise<T> {
    if (body) {
      options.body = JSON.stringify(body);
    }

    return this.request('PUT', url, options);
  }

  destroy<T>(url, options: RequestInit = {}): Promise<T> {
    return this.request('DELETE', url, options);
  }

  request<T>(method: string, url: string, options: RequestInit = {}): Promise<T> {
    this.beforeRequest();

    const headers = {

      ...this.defaultHeaders,
      ...options.headers,
    };

    options.headers = headers;
    options.method = method;

    return new Promise((resolve, reject) => {
      fetch(url, options).then((r) => {

        if (r.ok) {
          resolve(r.json()); // TODO: return status
        } else {
          r.json().then((data) => {
            this.onUnauthorizedErrors(data);

            reject({
              data,
              status: r.status,
            });
          }).catch(() => {
            reject({ status: r.status });
          });
        }
      }).catch((e) => {
        reject(e);
      });
    });
  }

  onUnauthorizedErrors(data) {
    if (data?.error_type === EXPIRED_TOKEN_ERROR) flashExpireAuthError();
  }
}
