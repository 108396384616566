import PropTypes from 'prop-types';
import React from 'react';
import formatAmount from '../../../shared/format_amount';
import Money from '../../../shared/money';
import I18n from 'i18n-js';

class Product extends React.Component {
  get hasPriceDifference() {
    return this.offeredPrice && (this.offeredPrice.cents !== this.listingStartingPrice.amount_cents);
  }

  // the seller is browsing in a currency that does not match the shop currency
  get userCurrencyMatchesShopCurrency() {
    return this.props.product.price.currency === this.props.product.seller_price.currency;
  }

  get hasVat() {
    return this.props.product.buyer_price.tax_included;
  }

  get taxIncludedHint() {
    return this.props.product.buyer_price.tax_included_hint;
  }

  get listingStartingPrice() {
    if (this.hasVat && this.userCurrencyMatchesShopCurrency) {
      return this.props.product.price;
    }

    return this.props.product.seller_price;
  }

  get offeredPrice() {
    let price;

    try {
      price = Money.parse(this.props.initialOfferPrice, this.props.product.seller_price.currency);
    } catch (ex) {
      price = null;
    }

    return price;
  }

  renderCost() {
    if (!this.props.product.seller_cost) {
      return null;
    }

    return (
      <p className="color-gray mb-0">
        {
          I18n.t(
            'js.productDetails.sellerCost',
            { cost: formatAmount(this.props.product.seller_cost) },
          )
        }
      </p>
    );
  }

  renderSku() {
    if (!this.props.product.sku) {
      return null;
    }
    return (
      <div className="color-gray">
        {I18n.t('js.productDetails.sku', { sku: this.props.product.sku })}
      </div>
    );
  }

  renderPrices() {
    // If VAT is involved, and the seller is not browsing in shop currency, we render a static breakdown
    // of list price (browsing currency inc. VAT) and asking price (seller currency without VAT),
    // instead of displaying the realtime strikethrough UI.
    if (this.hasVat && !this.userCurrencyMatchesShopCurrency) {
      return (
        <div>
          <div>
            {I18n.t(
              'js.productDetails.listPriceWithTaxHint',
              { amount: formatAmount(this.props.product.price), currencyCode: this.props.product.price.currency, hint: this.taxIncludedHint },
            )}
          </div>
          <div>
            {I18n.t(
              'js.productDetails.askingPrice',
              { amount: formatAmount(this.props.product.seller_price) },
            )}
          </div>
        </div>
      );
    }

    if (!this.hasPriceDifference) {
      return (<h4 className="strong color-gray">{formatAmount(this.listingStartingPrice)}</h4>);
    }

    return (
      <div>
        <h4 className="color-gray line-through">{formatAmount(this.listingStartingPrice)}</h4>
        <h4 className="color-gray weight-bold">
          {formatAmount({
            amount: this.props.initialOfferPrice,
            symbol: this.props.product.seller_price.symbol,
          })}
        </h4>
      </div>
    );
  }

  render() {
    return (
      <div className="g-container">
        <div className="g-col-2">
          <img
            className="img-wide bd-1 bd-primary"
            alt={this.props.product.title}
            src={this.props.product._links.photo.href}
          />
        </div>
        <div className="g-col-6">
          <h4 className="strong color-gray">{this.props.product.title}</h4>
          {this.renderSku()}
          <div className="color-gray">
            {
              I18n.t(
                'js.productDetails.condition',
                {
                  condition: this.props.product.condition.display_name,
                },
              )
            }
          </div>
          {this.renderCost()}
        </div>
        <div className="g-col-4 align-right">
          {this.renderPrices()}
        </div>
      </div>
    );
  }
}

Product.propTypes = {
  product: PropTypes.object.isRequired,
  initialOfferPrice: PropTypes.string,
};

export default Product;
