import React from 'react';
import I18n from 'i18n-js';
import { core_apimessages_NegotiationAction as NegotiationAction } from '@reverbdotcom/commons/src/gql/graphql';
import SanitizedRender from '@reverbdotcom/commons/src/components/sanitized_render';
import URLHelpers from '../shared/url_helpers';
import { HOURS_TO_CHECK_OUT_WHEN_CONDITIONALLY_ACCEPTED } from './update_offer_modal';

interface Props {
  negotiationAction: NegotiationAction;
  isSeller: boolean;
  buyerWillPayImmediately: boolean;
  localPickupOnly?: boolean;
  hasConditionallyAccepted?: boolean;
}

export function UpdateOfferConfirmation({ negotiationAction, isSeller, buyerWillPayImmediately, localPickupOnly = false, hasConditionallyAccepted = false }: Props) {
  const accepted = negotiationAction === NegotiationAction.ACCEPT;
  const countered = negotiationAction === NegotiationAction.COUNTER;

  return (
    <div className="offers-form__confirmation">
      {isSeller && accepted && (
        <>
          <h4>
            {!buyerWillPayImmediately && I18n.t('discovery.offers.confirmation.accepted.standard.seller.header')}
            {buyerWillPayImmediately && I18n.t('discovery.offers.confirmation.accepted.immediatePayment.seller.header')}
          </h4>
          <ul>
            <li>
              {!buyerWillPayImmediately && (
                <SanitizedRender
                  html={I18n.t('discovery.offers.confirmation.accepted.standard.seller.line1')}
                />
              )}
              {buyerWillPayImmediately && I18n.t('discovery.offers.confirmation.accepted.immediatePayment.seller.line1')}
            </li>
            <li>
              {!buyerWillPayImmediately && I18n.t('discovery.offers.confirmation.accepted.standard.seller.line2')}
              {buyerWillPayImmediately && !localPickupOnly && (
                <SanitizedRender
                  html={I18n.t('discovery.offers.confirmation.accepted.immediatePayment.seller.line2', { linkOpen: `<a href="${URLHelpers.defaultShippingGuidePath}" target="_blank">`, linkClose: '</a>' })}
                />
              )}
            </li>
          </ul>
        </>
      )}

      {isSeller && countered && !hasConditionallyAccepted && (
        <>
          <h4>
            {I18n.t('discovery.offers.confirmation.countered.standard.seller.header')}
          </h4>
          <ul>
            <li>
              <SanitizedRender
                html={I18n.t('discovery.offers.confirmation.countered.standard.seller.line1')}
              />
            </li>
            <li>
              <SanitizedRender
                html={I18n.t('discovery.offers.confirmation.countered.standard.seller.line2')}
              />
            </li>
          </ul>
        </>
      )}

      {isSeller && countered && hasConditionallyAccepted && (
        <>
          <h4>
            {I18n.t('discovery.offers.confirmation.countered.conditionalAcceptance.seller.header')}
          </h4>
          <ul>
            <li>
              <SanitizedRender
                html={I18n.t('discovery.offers.confirmation.countered.conditionalAcceptance.seller.line1', { count: HOURS_TO_CHECK_OUT_WHEN_CONDITIONALLY_ACCEPTED })}
              />
            </li>
            <li>
              <SanitizedRender
                html={I18n.t('discovery.offers.confirmation.countered.conditionalAcceptance.seller.line2')}
              />
            </li>
          </ul>
        </>
      )}

      {!isSeller && accepted && (
        <>
          <h4>
            {I18n.t('discovery.offers.confirmation.accepted.standard.buyer.header')}
          </h4>
          <SanitizedRender
            html={I18n.t('discovery.offers.confirmation.accepted.standard.buyer.line1', { linkOpen: `<a href="${URLHelpers.cartPath}">`, linkClose: '</a>' })}
          />
        </>
      )}

      {!isSeller && countered && (
        <>
          <h4>
            {!buyerWillPayImmediately && I18n.t('discovery.offers.confirmation.countered.standard.buyer.header')}
            {buyerWillPayImmediately && I18n.t('discovery.offers.confirmation.countered.immediatePayment.buyer.header')}
          </h4>
          <ul>
            <li>
              {!buyerWillPayImmediately && (
                <SanitizedRender
                  html={I18n.t('discovery.offers.confirmation.countered.standard.buyer.line1')}
                />
              )}
              {buyerWillPayImmediately && (
                <SanitizedRender
                  html={I18n.t('discovery.offers.confirmation.countered.immediatePayment.buyer.line1')}
                />
              )}
            </li>
            <li>
              {!buyerWillPayImmediately && (
                <SanitizedRender
                  html={I18n.t('discovery.offers.confirmation.countered.standard.buyer.line2')}
                />
              )}
              {buyerWillPayImmediately && I18n.t('discovery.offers.confirmation.countered.immediatePayment.buyer.line2')}
            </li>
          </ul>
        </>
      )}
    </div>
  );
}
