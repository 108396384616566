import { ChildProps } from '@apollo/client/react/hoc';
// TODO update this to import from commons
// import { gql } from '@reverbdotcom/commons/src/gql';
// eslint-disable-next-line no-restricted-imports
import { gql } from '@apollo/client';
import React from 'react';
import I18n from 'i18n-js';
import bind from '@reverbdotcom/commons/src/bind';
import { RCAlertBox } from '@reverbdotcom/cadence/components';
import { withGraphql } from '@reverbdotcom/commons/src/with_graphql';
import FeedbackForm from './feedback_form';
import FeedbackOrderInfo from './feedback_order_info';
import {
  Core_Feedback_OrderInfoQuery,
  Core_Feedback_OrderInfoQueryVariables,
  core_apimessages_Error,
} from '@reverbdotcom/commons/src/gql/graphql';
import FeedbackSellerShopInfo from './feedback_seller_shop_info';
import FeedbackShopLink from './feedback_shop_link';
import DeclineFeedback from './decline_feedback';

const LP_MERCHANDISING_TYPE = 'lp';

interface IProps {
  orderUuid: string;
}

interface IState {
  successMessage: string;
  errors: core_apimessages_Error[];
}

const connect = withGraphql<IProps, Core_Feedback_OrderInfoQuery, Core_Feedback_OrderInfoQueryVariables>(
  gql`
    query Core_Feedback_OrderInfo(
      $orderUuid: String
    ){
      me {
        _id
        uuid
        orders(input: { uuids: [$orderUuid] }) {
          _id
          legacyOrderId
          buyerUuid
          buyer {
            _id
            name
            shop {
              name
              slug
            }
          }
          status
          amountTotal {
            amount
            amountCents
            currency
            symbol
            display
          }
          shippedAt {
            seconds
          }
          createdAt {
            seconds
          }
          listing {
            _id
            id
            slug
            title
            merchandisingType
            shopUuid
            images(input: { transform: "thumb", count: 1 }) {
              _id
              source
            }
          }
        }
      }
    }
  `,
  {
    options: (ownProps) => {
      return {
        ssr: true,
        variables: {
          orderUuid: ownProps.orderUuid,
        },
      };
    },
  },
);

export class FeedbackContainer extends React.Component<ChildProps<IProps, Core_Feedback_OrderInfoQuery>, IState> {
  state = {
    successMessage: null,
    errors: [],
  };

  @bind
  updateFeedbackSuccess() {
    this.setState({ successMessage: I18n.t('discovery.feedbackContainer.feedbackSuccess') });
  }

  @bind
  updateFeedbackError(errors: core_apimessages_Error[]) {
    this.setState({
      errors,
    });
  }

  @bind
  declineFeedbackSuccess() {
    this.setState({ successMessage: I18n.t('discovery.feedbackContainer.declineSuccess') });
  }

  @bind
  renderSuccessMessage() {
    return (
      <div className="bd-2 bd-primary bd--rounded scaling-padding-4 align-center">
        <RCAlertBox type="success">
          {this.state.successMessage}
        </RCAlertBox>
      </div>
    );
  }

  get order() {
    return this.props.data.me.orders[0];
  }

  get buyer() {
    return this.order.buyer;
  }

  get isBuyer() {
    return this.props.data.me.uuid === this.order.buyerUuid;
  }

  get isLp() {
    return this.order.listing.merchandisingType === LP_MERCHANDISING_TYPE;
  }

  get loading() {
    return this.props.data.loading !== false;
  }

  renderErrors() {
    if (this.state.errors.length === 0) {
      return null;
    }

    return (
      <div className="mb-4">
        <RCAlertBox type="error">
          {this.state.errors[0].message}
        </RCAlertBox>
      </div>
    );
  }

  renderSellerShopInfo() {
    return (
      <FeedbackSellerShopInfo
        listingId={this.order.listing.id}
      />
    );
  }

  renderBuyerShopInfo() {
    return (
      <FeedbackShopLink
        shop={this.buyer.shop}
        userName={this.buyer.name}
      />
    );
  }

  renderShopInfo() {
    if (this.isBuyer) {
      // if the author is the buyer, they are leaving feedback FOR the seller
      return this.renderSellerShopInfo();
    }

    return this.renderBuyerShopInfo();
  }

  renderPlaceholder() {
    return (
      <div className="bd-2 bd-primary bd--rounded scaling-padding-4">
        <div className="feedback-container feedback-container--placeholder"></div>
      </div>
    );
  }

  render() {
    if (this.loading) {
      return this.renderPlaceholder();
    }

    if (this.state.successMessage && this.state.successMessage.length > 0) {
      return this.renderSuccessMessage();
    }

    return (
      <div className="bd-2 bd-primary bd--rounded scaling-padding-4">
        {this.renderErrors()}
        <div className="g-container">
          <div className="g-col-5 g-col-mobile-12 mobile-mb-2 size-90">
            {this.renderShopInfo()}
            <FeedbackOrderInfo
              order={this.order}
              isBuyer={this.isBuyer}
            />
          </div>
          <div className="g-col-7 g-col-mobile-12">
            <div className="f-right mobile-d-none">
              <DeclineFeedback
                orderUuid={this.props.orderUuid}
                onSuccess={this.declineFeedbackSuccess}
              />
            </div>
            <FeedbackForm
              orderUuid={this.props.orderUuid}
              legacyOrderId={this.order && this.order.legacyOrderId}
              onSuccess={this.updateFeedbackSuccess}
              onError={this.updateFeedbackError}
              isBuyer={this.isBuyer}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default connect(FeedbackContainer);
