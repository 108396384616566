// TODO update this to import from commons
// import { gql } from '@reverbdotcom/commons/src/gql';
// eslint-disable-next-line no-restricted-imports
import { gql } from '@apollo/client';
import React from 'react';
import { PayPalScriptProvider, PayPalMessages } from '@paypal/react-paypal-js';
import { PAYPAL_CLIENT_ID } from '@reverbdotcom/env';
import { ListingPayPalCalloutFieldsFragment } from '@reverbdotcom/commons/src/gql/graphql';


type AmountTotal = ListingPayPalCalloutFieldsFragment['pricing']['buyerPrice'];

interface Props {
  amountTotal: AmountTotal;
}

export function ListingPayPalCallout({
  amountTotal,
}: Props) {
  const price = Number(amountTotal.amount);
  const options = {
    currency: amountTotal.currency,
    clientId: PAYPAL_CLIENT_ID,
    components: 'messages',
  };

  return (
    <PayPalScriptProvider options={options}>
      <PayPalMessages
        style={{ layout: 'text', logo: { type: 'inline' } }}
        className="listing-paypal-callout"
        amount={price}
      />
    </PayPalScriptProvider>
  );
}

export const ListingPayPalCalloutFragment = gql`
  fragment ListingPayPalCalloutFields on Listing {
    _id
    currency
    pricing {
      buyerPrice {
        amount
        currency
      }
    },
  }
`;
