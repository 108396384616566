import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import { APIRequest } from '@reverbdotcom/discovery-ui';
import UrlHelpers from '../shared/url_helpers';
import User from '../user';
import { UI } from '../legacy/ui';

const popupTimeout = 3000; // ms

class WatchButton extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isWatching: this.props.isWatching,
    };

    this.hideResponseCallback = null;
  }

  get isWatching() {
    return this.state.isWatching;
  }

  handleClick(e) {
    if (!UI.isLoggedIn()) {
      User.showInlineRegistrationOrLogin(e, e.target);
      return;
    }
    if (this.props.isOwner) {
      this.showSellerBlock();
      return;
    }

    if (this.isWatching) {
      this.removeWatch();
    } else {
      this.addWatch();
    }
  }

  showSellerBlock() {
    Reverb.BlockSellerAction.showMessage(I18n.t('js.watchButton.sellerBlockMessage'));
  }

  showResponse() {
    this.setState({ showResponse: true });
    window.clearTimeout(this.hideResponseCallback);
    this.hideResponseCallback = window.setTimeout(() => this.hideResponse(), popupTimeout);
  }

  hideResponse() {
    this.setState({ showResponse: false });
  }

  addWatch() {
    this.setState({ isWatching: true });
    this.showResponse();

    APIRequest.V3.post(UrlHelpers.watchProductPath(this.props.productId));
  }

  removeWatch() {
    this.setState({ isWatching: false });
    this.showResponse();

    APIRequest.V3.destroy(UrlHelpers.unwatchProductPath(this.props.productId));
  }

  renderSignupWatchBenefits() {
    if (UI.isLoggedIn()) { return null; }

    return (
      <div
        className="hidden"
        id="watch-benefits"
      >
        <div className="watch-benefits alert alert-info">
          <div className="d-flex fx-align-center mobile-mb-2">
            <div className="fx-width-20 mr-2 show-only-on-mobile">
              <img src={this.props.productImage} role="presentation" className="d-block width-100" />
            </div>
            <div className="fx-width-100 mobile-fx-width-80">
              <h4 className="heading-4 mb-2 align-center mobile-mb-0 mobile-align-left">
                {I18n.t('js.watchButton.benefits.signUp')}
              </h4>
            </div>
          </div>
          <div className="d-flex fx-align-center">
            <div className="fx-width-20 mr-2 show-only-on-desktop">
              <img src={this.props.productImage} role="presentation" className="d-block width-100" />
            </div>
            <div className="fx-width-80 mobile-fx-width-100">
              <ul className="fa-ul small">
                <li>
                  <span className="fa fa-check fa-li color-green" />
                  {I18n.t('js.watchButton.benefits.priceDrop')}
                </li>
                <li>
                  <span className="fa fa-check fa-li color-green" />
                  {I18n.t('js.watchButton.benefits.offers')}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderBtnText() {
    if (this.isWatching) {
      return I18n.t('js.watchButton.button.watching');
    }
    return I18n.t('js.watchButton.button.watch');
  }

  renderInnerResponse() {
    if (this.state.isWatching) {
      return I18n.t('js.watchList.itemAdded', {
        link_open: `<a href="${UrlHelpers.watchedProductsPath}">`,
        link_close: '</a>',
      });
    }

    return I18n.t('js.watchList.itemRemoved');
  }

  renderResponse() {
    if (!this.state.showResponse) { return ''; }

    return (
      <div
        className="tooltip-panel__content tooltip-panel__content--watch"
        dangerouslySetInnerHTML={{ __html: this.renderInnerResponse() }}
      />
    );
  }

  renderLoggedOutWatchBenefits() {
    if (UI.isLoggedIn()) return null;

    return (
      <div className="sidebar-callout sidebar-callout--watch">
        {I18n.t('js.watchButton.watchForPriceDrop')}
      </div>
    );
  }

  render() {
    const classes = classNames(
      'width-100 button-as-link',
      {
        'icon-l-star': this.state.isWatching,
        'icon-l-star-o': !this.state.isWatching,
      },
    );

    return (
      <div>
        <button
          className={classes}
          onClick={e => this.handleClick(e)}
          data-registration-url-source="product_watch"
          data-registration-explanation-content="#watch-benefits"
          data-registration-source-product-uuid={this.props.sourceProductUuid}
        >
          {this.renderBtnText()}
        </button>
        {this.renderResponse()}
        {this.renderLoggedOutWatchBenefits()}
        {this.renderSignupWatchBenefits()}
      </div>
    );
  }
}

WatchButton.propTypes = {
  productId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isWatching: PropTypes.bool.isRequired,
  productImage: PropTypes.string.isRequired,
  sourceProductUuid: PropTypes.string.isRequired,
  isOwner: PropTypes.bool,
};

WatchButton.defaultProps = {
  isOwner: false,
};

export default WatchButton;
