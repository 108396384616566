const OperatingSystem = {
  get userAgent() {
    return navigator.userAgent;
  },

  isWindows(userAgent = this.userAgent) {
    return /Windows/.test(userAgent);
  },

  isMac(userAgent = this.userAgent) {
    return /(Mac_PowerPC)|(Macintosh)/.test(userAgent);
  },

  isLinux(userAgent = this.userAgent) {
    return /(Linux)|(X11)/.test(userAgent);
  },
};

export default OperatingSystem;
