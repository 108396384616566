import React from 'react';
import I18n from 'i18n-js';
import PayoutMethodCountryCodeInput from './payout_method_country_code_input';
import CurrencyInput from './payout_method_currency_input';
import BankDepositConfirmCheckbox from './payout_method_currency_warnings';
import CanadianRoutingNumberFields from './canadian_routing_number_fields';
import { PaymentProvider } from './payment_provider';
import SanitizedRender from '@reverbdotcom/commons/src/components/sanitized_render';

const ACCOUNT_INPUT_LENGTH = 17;

const currencyCodes = ['CAD', 'USD'];

export interface Props {
  countryCodes: string[];
  inputNamePrefix: string;
  onCountryChange(countryCode: string): void;
  onCurrencyChange(currencyCode: string): void;
  payoutCurrency: string;
  shopCurrency: string;
}

export default function CanadianBankFields(props: Props) {
  function hasValidSelectedPayoutCurrency() {
    return !!currencyCodes.find(currencyCode => currencyCode === props.payoutCurrency);
  }

  function currencyForInput() {
    if (hasValidSelectedPayoutCurrency()) {
      return props.payoutCurrency;
    }

    return 'CAD';
  }

  function inputName(methodName) {
    return `${props.inputNamePrefix}[${methodName}]`;
  }

  return (
    <div>
      <div className="g-container mb-2">
        <div className="g-col-6 g-col-mobile-12">
          <label>
            {I18n.t('discovery.payoutMethod.directCheckoutProfile.bankAccountFields.nameOnAccount')}
          </label>
          <input
            className="string required wide mb-0"
            autoFocus
            type="text"
            name={inputName('name_on_account')}
          />
        </div>
      </div>

      <div className="mb-2">
        <div className="g-container">
          <PayoutMethodCountryCodeInput
            countryCode="CA"
            countryCodes={props.countryCodes}
            inputName={inputName('country_code')}
            onCountryChange={props.onCountryChange}
          />
          <CurrencyInput
            currencyCode={currencyForInput()}
            currencyCodes={currencyCodes}
            inputName={inputName('currency')}
            onCurrencyChange={currencyCode => props.onCurrencyChange(currencyCode)}
          />
        </div>

        <BankDepositConfirmCheckbox
          inputName={inputName('bank_deposits_confirmed_at')}
          paymentProvider={PaymentProvider.ReverbPayments}
          payoutCurrencyCode={currencyForInput()}
          shopCountryCode="CA"
          shopCurrencyCode={props.shopCurrency}
        />
      </div>
      <div className="g-container">
        <div className="g-col-8 g-col-mobile-12">
          <CanadianRoutingNumberFields
            inputNamePrefix={props.inputNamePrefix}
          />
        </div>

        <div className="g-col-4 g-col-mobile-12">
          <label className="string required control-label">
            <SanitizedRender
              allowedTags={['span']}
              html={I18n.t('discovery.payoutMethod.directCheckoutProfile.bankAccountFields.canada.accountNumber', {
                hint_open: '<span class="hint">',
                hint_close: '</span>',
              })}
            />
          </label>
          <input
            className="string required wide"
            maxLength={ACCOUNT_INPUT_LENGTH}
            type="text"
            name={inputName('bank_account_number')}
          />
        </div>
      </div>
    </div>
  );
}

