// TODO update this to import from commons
// import { gql } from '@reverbdotcom/commons/src/gql';
// eslint-disable-next-line no-restricted-imports
import { gql } from '@apollo/client';
import React from 'react';
import { CreditCardWalletRowSummaryFieldsFragment } from '@reverbdotcom/commons/src/gql/graphql';
import CreditCardBrandIcon from '@reverbdotcom/commons/src/components/credit_card_brand_icon';

interface Props {
  creditCard: CreditCardWalletRowSummaryFieldsFragment['creditCards'][0];
}

export default function CreditCardWalletRowSummary({ creditCard }: Props) {
  return (
    <div className="d-flex">
      <div className="width-rem-5">
        <CreditCardBrandIcon
          brand={creditCard.brand}
        />
      </div>
      <div className="pl-2">
        <div className="weight-bold">
          {creditCard.display.summary}
        </div>
        <div className="size-fixed-80 weight-normal">
          {creditCard.display.expirationDate}
        </div>
      </div>
    </div>
  );
}

export const CreditCardWalletRowSummaryFragment = gql`
  fragment CreditCardWalletRowSummaryFields on rql_Me {
    _id
    uuid
    creditCards {
      id
      primaryForCheckout
      display {
        summary
        expirationDate
      }
      brand {
        type
        name
      }
      address {
        _id
        name
        streetAddress
        extendedAddress
        locality
        regionName
        postalCode
        country {
          _id
          countryCode
        }
      }
    }
  }
`;
