import React from 'react';
import { RCAlertBox } from '@reverbdotcom/cadence/components';
import { FORMATS_FOR_COUNTRY_CODES } from './shop_settings_vat_supported_countries_and_formats';

interface Props {
  error?: string;
  countryCode?: string;
}

export default function ShopSettingsVatIdSaveError({ countryCode, error }: Props) {
  if (!error) return null;

  return (
    <div className="mb-4">
      <RCAlertBox type="error">
        <p className="mb-2">{error}</p>
        <ShopSettingsVatIdFormats countryCode={countryCode} />
      </RCAlertBox>
    </div>
  );
}

export function ShopSettingsVatIdFormats({ countryCode }) {
  const formats = FORMATS_FOR_COUNTRY_CODES[countryCode];

  return (
    <ul className="cms-ul mb-0">
      {
        formats.map(format => (
          <ShopSettingsVatIdFormat
            key={format}
            format={format}
          />
        ))
      }
    </ul>
  );
}

export function ShopSettingsVatIdFormat({ format }) {
  return <li>{format}</li>;
}
