export const FORMATS_FOR_COUNTRY_CODES = {
  AT: ['ATU99999999'], // Austria
  BE: ['BE0999999999', 'BE1999999999'], // Belgium
  BG: ['BG999999999', 'BG9999999999'], // Bulgaria
  CY: ['CY99999999L'], // Cyprus
  CZ: ['CZ99999999', 'CZ999999999', 'CZ9999999999'], // Czech Republic
  DE: ['DE999999999'], // Germany
  DK: ['DK99999999'], // Denmark
  EE: ['EE999999999'], // Estonia
  GR: ['EL999999999'], // Greece
  ES: ['ESX9999999X'], // Spain
  FI: ['FI99999999'], // Finland
  FR: ['FRXX999999999'], // France
  GB: ['GB999999999'], // United Kingdom
  HR: ['HR99999999999'], // Croatia
  HU: ['HU99999999'], // Hungary
  IE: ['IE9S99999L', 'IE9999999WI'], // Ireland
  IT: ['IT99999999999'], // Italy
  LT: ['LT999999999', 'LT999999999999'], // Lithuania
  LU: ['LU99999999'], // Luxembourg
  LV: ['LV99999999999'], // Latvia
  MT: ['MT99999999'], // Malta
  NL: ['NLSSSSSSSSSSSS'], // Netherlands
  PL: ['PL9999999999'], // Poland
  PT: ['PT999999999'], // Portugal
  RO: ['RO999999999'], // Romania
  SE: ['SE999999999999'], // Sweden
  SI: ['SI99999999'], // Slovenia
  SK: ['SK9999999999'], // Slovakia
};

export const COUNTRY_CODES = Object.keys(FORMATS_FOR_COUNTRY_CODES);
