import { MutationFunction } from '@reverbdotcom/commons/src/useHOCMutation';
import React from 'react';
import moment from 'moment';
import { parseMutationErrors } from '@reverbdotcom/commons/src/parse_graphql_errors';
import RouterHistory from '@reverbdotcom/commons/src/cms/lib/router_history';
import FormGroupWithInput from '@reverbdotcom/commons/src/components/form_group_with_input';
import FormGroupWithSelect from '@reverbdotcom/commons/src/components/form_group_with_select';
import FormGroupWithCheckbox from '@reverbdotcom/commons/src/components/form_group_with_checkbox';
import { V3 } from '../api_request';
import { Paths } from '../shared/url_helpers';
import {
  CoreUpdateUniversalPromoCampaign,
  Core_Universal_Promo_CampaignQuery,
  core_apimessages_Error,
  reverb_config_cache_ExchangeRate,
} from '@reverbdotcom/commons/src/gql/graphql';
import {
  getDisplayCategories,
  getDisplayCurrencyValues,
  getCurrenciesForSubmit,
  getCategoriesForSubmit,
  convertCurrencyValues,
  DisplayCurrency,
} from './form_helpers';
import CurrenciesInputs from './currencies_inputs';
import Categories from './categories';

interface IProps {
  campaign: Core_Universal_Promo_CampaignQuery['universalpromocampaign'];
  updateUniversalPromoCampaign: MutationFunction<CoreUpdateUniversalPromoCampaign.Mutation, CoreUpdateUniversalPromoCampaign.Variables>;
  exchangeRates: reverb_config_cache_ExchangeRate[];
  setWasSuccess: (success: boolean) => void;
  setErrors: (errors: core_apimessages_Error[]) => void;
}

const DATE_FORMAT = 'YYYY-MM-DDTHH:mm';
const MARKETING_CHANNELS = [
  'direct_mail',
  'email',
  'event',
  'facebook',
  'influencer',
  'partner',
  'podcast',
  'print',
];

function marketingChannelsSelectFields() {
  return MARKETING_CHANNELS.map(channel => ({
    text: channel,
    value: channel,
  }));
}

export function UniversalPromoCampaignForm({ campaign, updateUniversalPromoCampaign, exchangeRates, setErrors, setWasSuccess }: IProps) {
  const [name, setName] = React.useState(campaign?.name || '');
  const [code, setCode] = React.useState(campaign?.code || '');
  const [currencyValues, setCurrencyValues] = React.useState(getDisplayCurrencyValues(campaign?.universalPromoCurrencies));
  const [marketingChannel, setMarketingChannel] = React.useState(campaign?.marketingChannel || '');
  const [expiresAt, setExpiresAt] = React.useState(campaign?.expiresAt ? moment.utc(campaign.expiresAt).format(DATE_FORMAT) : '');
  const [redemptionLimit, setRedemptionLimit] = React.useState(campaign?.hasRedemptionLimit ? campaign.redemptionLimit.toString() : '');
  const [newBuyer, setNewBuyer] = React.useState(campaign?.newBuyer || false);
  const [categories, setCategories] = React.useState([]);
  const [isSaving, setIsSaving] = React.useState(false);
  const [lapsedBuyer, setLapsedBuyer] = React.useState(campaign?.lapsedBuyer || false);
  const [lapsedBuyerLastPurchaseDate, setLapsedBuyerLastPurchaseDate] = React.useState(
    campaign?.lapsedBuyerLastPurchaseDate ? moment.utc(campaign.lapsedBuyerLastPurchaseDate).format(DATE_FORMAT) : '',
  );

  React.useEffect(() => {
    V3.get(
      Paths.apiCategoriesFlat.expand({}),
    ).then((res) => {
      const displayCategories = getDisplayCategories(res.categories, campaign);
      setCategories(displayCategories);
    });
  }, []);

  const convertAndSetCurrencyValues = (primaryCurrencyValues: DisplayCurrency) => {
    const convertedCurrencyValues = convertCurrencyValues(currencyValues, exchangeRates, primaryCurrencyValues);

    setCurrencyValues(convertedCurrencyValues);
  };

  const handleSubmit = async (submit) => {
    submit.preventDefault();
    const categoriesForSubmit = getCategoriesForSubmit(categories);

    if (newBuyer && !categoriesForSubmit.length) {
      setErrors([{ message: 'At least one category needs to be selected when New Buyer Only is selected.' }]);
      window.scrollTo(0, 0);
      return;
    }

    if (lapsedBuyer && categoriesForSubmit.length) {
      setErrors([{ message: 'Categories can only be applied for the New Buyers Only option.' }]);
      window.scrollTo(0, 0);
      return;
    }

    setErrors([]);
    setWasSuccess(false);
    setIsSaving(true);

    const variables = {
      input: {
        universalPromoCampaign: {
          id: campaign ? campaign.id : null,
          name,
          code,
          marketingChannel,
          expiresAt: (expiresAt && moment.utc(expiresAt).format()) || null,
          newBuyer,
          universalPromoCurrencies: getCurrenciesForSubmit(currencyValues),
          categories: categoriesForSubmit,
          hasRedemptionLimit: !!redemptionLimit,
          redemptionLimit: redemptionLimit ? parseInt(redemptionLimit, 10) : null,
          lapsedBuyer,
          lapsedBuyerLastPurchaseDate: (lapsedBuyerLastPurchaseDate && moment.utc(lapsedBuyerLastPurchaseDate).format()) || null,
        },
      },
    };
    let response;
    try {
      response = await updateUniversalPromoCampaign({ variables });
      setWasSuccess(true);
      RouterHistory.push(Paths.adminEditUniversalPromoCampaign.expand(
        {
          id: response.data.updateUniversalPromoCampaign.universalPromoCampaign.id,
        },
      ));
    } catch (e) {
      setErrors(parseMutationErrors(e));
    } finally {
      setIsSaving(false);
      window.scrollTo(0, 0);
    }
  };

  const handleLapsedBuyer = (fieldUpdateValue) => {
    setLapsedBuyer(fieldUpdateValue);

    const updatedCategories = categories.map(category => {
      return { ...category, checked: false };
    });
    setCategories(updatedCategories);
  };

  return (
    <div className="width-75 mtb-6">
      <h2 className="mb-4">{`${campaign ? 'Edit' : 'New'} Promotional Campaign`}</h2>
      <form onSubmit={handleSubmit}>
        <FormGroupWithInput
          inputName="name"
          label="Campaign Name"
          value={name}
          required
          tagRequired
          updateField={(fieldUpdate) => setName(fieldUpdate.name)}
        />
        <FormGroupWithInput
          inputName="code"
          label="Promo Code"
          value={code}
          required
          tagRequired
          disabled={!!campaign}
          updateField={(fieldUpdate) => setCode(fieldUpdate.code)}
        />
        <CurrenciesInputs
          currencyValues={currencyValues}
          setCurrencyValues={setCurrencyValues}
          onConvert={convertAndSetCurrencyValues}
          isEdit={!!campaign}
        />
        <FormGroupWithSelect
          inputName="marketingChannel"
          label="Marketing Channel"
          options={marketingChannelsSelectFields()}
          value={marketingChannel}
          updateField={(fieldUpdate) => setMarketingChannel(fieldUpdate.marketingChannel)}
          addEmptyOption
          required
          tagRequired
        />
        <FormGroupWithInput
          inputName="expiresAt"
          label="Expires At"
          value={expiresAt}
          helpText="Time is UTC"
          tagOptional
          inputType="datetime-local"
          updateField={(fieldUpdate) => setExpiresAt(fieldUpdate.expiresAt)}
        />
        <FormGroupWithInput
          inputName="redemptionLimit"
          label="Redemption Limit"
          value={redemptionLimit}
          tagOptional
          updateField={(fieldUpdate) => setRedemptionLimit(fieldUpdate.redemptionLimit)}
        />
        <FormGroupWithCheckbox
          inputName="newBuyer"
          label="New Buyer Only"
          checked={newBuyer}
          disabled={lapsedBuyer}
          updateField={(fieldUpdate) => setNewBuyer(fieldUpdate.newBuyer)}
          helpText="At least one category needs to be selected when New Buyer Only is checked"
        />
        <FormGroupWithCheckbox
          inputName="lapsedBuyer"
          label="Lapsed Buyer Only"
          checked={lapsedBuyer}
          disabled={newBuyer}
          updateField={(fieldUpdate) => handleLapsedBuyer(fieldUpdate.lapsedBuyer)}
          helpText="No categories can be selected when Lapsed Buyer Only is checked"
        />
        {lapsedBuyer && <FormGroupWithInput
          inputName="lapsedBuyerLastPurchaseDate"
          label="Lapsed Buyer Last Purchase Date"
          value={lapsedBuyerLastPurchaseDate}
          helpText="A lapsed user's last paid purchase must be before this date to qualify for the discount. Time is UTC"
          inputType="datetime-local"
          updateField={(fieldUpdate) => setLapsedBuyerLastPurchaseDate(fieldUpdate.lapsedBuyerLastPurchaseDate)}
          required
          tagRequired
        />}
        <Categories
          categories={categories}
          setCategories={setCategories}
          disabled={lapsedBuyer}
        />
        <button
          className="button button--primary"
          type="submit"
          value="Submit"
          disabled={isSaving}
        >
          Submit
        </button>
      </form>
    </div>
  );
}

export default UniversalPromoCampaignForm;
