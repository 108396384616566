import React from 'react';
import Photo from './photo';
import classNames from 'classnames';
import I18n from 'i18n-js';
import { get } from 'lodash';
import bind from '@reverbdotcom/commons/src/bind';
import { camelizeKeys } from 'humps';

interface IProps {
  uploadOptions: any;
  onFileAdded: Function;
  onUploadFailure?: Function;
  onDragStart: Function;
  onDragEnd: Function;
  dropZoneSelector?: string;
  thumbMessage?: string;
}

interface IState {
  isUploading: boolean;
  isDragging: boolean;
  progress: number;
}

interface ICloudinaryResponse {
  public_id: string;
  version: number;
  width: number;
  height: number;
  format: string;
  resource_type: string;
}

const defaultDropZoneSelector = 'body';

class Uploader extends React.Component<IProps, IState> {
  static defaultProps: Partial<IProps> = {
    dropZoneSelector: defaultDropZoneSelector,
    onUploadFailure: () => {},
  };

  constructor(props) {
    super(props);

    this.state = {
      isUploading: false,
      isDragging: false,
      progress: 0,
    };
  }

  componentDidMount() {
    const { uploadOptions } = this.props;
    const $dropZone = $(this.props.dropZoneSelector || defaultDropZoneSelector);
    const options = {
      url: get(uploadOptions, 'html.data.url'),
      formData: get(uploadOptions, 'html.data.form_data'),
      dataType: 'json',
      paramName: 'file',
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
      },
      sequentialUploads: true,
      acceptFileTypes: /^image\/jpeg|image\/png|image\/heic|image\/heif|image\/webp|application\/pdf$/i,
      done: (_e, data) => this.handleUploadSuccess(data.result),
      fail: this.props.onUploadFailure,
      always: this.handleUploadDone,
      send: this.handleUploadStart,
      progressall: (_e, data) => this.handleProgress(data),
      dropZone: $dropZone,
      dragover: this.handleDragOver,
    };

    this.bindDragLeave();

    ($(this.refs.input) as any).fileupload(options);
  }

  componentWillUnmount() {
    $(document).off('dragleave');
  }

  bindDragLeave() {
    $(document).on('dragleave', this.props.dropZoneSelector, () => {
      // Set isDragging to false. If it is still false in 10ms, then we have actually
      // left the window. Otherwise this may be called when dragging into other elements
      // on the page, in which case isDragging will have been set back to true.
      this.setState({ isDragging: false });
      setTimeout(
        () => {
          if (!this.state.isDragging) {
            this.handleDragLeave();
          }
        },
        10,
      );
    });
  }

  @bind
  handleUploadStart() {
    this.setState({
      isUploading: true,
      progress: 0,
    });
    this.handleDragLeave();
  }

  @bind
  handleUploadSuccess(result: ICloudinaryResponse) {
    const camelized = camelizeKeys(result);
    this.props.onFileAdded(new Photo(camelized));
    this.handleUploadDone();
  }

  @bind
  handleUploadDone() {
    this.setState({ isUploading: false });
  }

  @bind
  handleProgress(data) {
    const progress = data.loaded / data.total * 100;
    this.setState({ progress });
  }

  @bind
  handleDragOver() {
    this.setState({ isDragging: true });
    this.props.onDragStart();
  }

  @bind
  handleDragLeave() {
    this.setState({ isDragging: false });
    this.props.onDragEnd();
  }

  renderLabel() {
    if (this.state.isUploading) {
      const percent = this.state.progress.toFixed(0);
      return I18n.t('discovery.photos.uploader.progress', { percent });
    }

    return this.props.thumbMessage || I18n.t('discovery.photos.uploader.uploadPhotos');
  }

  render() {
    const className = classNames(
      'img-uploader__thumb',
      'img-uploader__thumb--add-photo',
      { 'img-uploader__thumb--add-photo--active': !this.state.isUploading },
    );
    return (
      <div className={className}>
        <div className="img-uploader__thumb__message">
          <label>
            {this.renderLabel()}
          </label>
        </div>
        <input
          ref="input"
          type="file"
          accept="image/jpeg,image/png,image/heic,image/heif,image/webp,application/pdf"
          multiple={get(this.props.uploadOptions, 'html.multiple')}
        />
      </div>
    );
  }
}

export default Uploader;
