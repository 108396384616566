export default {
  '032c74d0-b0e2-4442-877f-e1a22438a7fa': 'Band and Orchestra',
  '09055aa7-ed49-459d-9452-aa959f288dc2': 'Amps',
  '1f99c852-9d20-4fd3-a903-91da9c805a5e': 'Parts',
  '37f5ad77-15d0-4c2c-b4d3-1438c0805f13': 'Other',
  '3ca3eb03-7eac-477d-b253-15ce603d2550': 'Acoustic Guitars',
  '40e8bfd0-3021-43f7-b104-9d7b19af5c2b': 'Home Audio',
  '53a9c7d7-d73d-4e7f-905c-553503e50a90': 'Bass Guitars',
  '58d889f7-0aa1-4689-a9d3-da16dd225e8d': 'DJ and Lighting Gear',
  '62835d2e-ac92-41fc-9b8d-4aba8c1c25d5': 'Accessories',
  'b021203f-1ed8-476c-a8fc-32d4e3b0ef9e': 'Pro Audio',
  'b3cb9f8e-4cb6-4325-8215-1efcd9999daf': 'Drums and Percussion',
  'd002db05-ab63-4c79-999c-d49bbe8d7739': 'Keyboards and Synths',
  'dfd39027-d134-4353-b9e4-57dc6be791b9': 'Electric Guitars',
  'fa10f97c-dd98-4a8f-933b-8cb55eb653dd': 'Effects and Pedals',
  'fb60628c-be4b-4be2-9c0f-bc5d31e3996c': 'Folk Instruments',
  'e71b6e43-7217-4746-b4e3-64bf708a2664': 'Software',
  all: {
    '032c74d0-b0e2-4442-877f-e1a22438a7fa': 'All Band and Orchestra',
    '09055aa7-ed49-459d-9452-aa959f288dc2': 'All Amps',
    '1f99c852-9d20-4fd3-a903-91da9c805a5e': 'All Parts',
    '37f5ad77-15d0-4c2c-b4d3-1438c0805f13': 'All Other',
    '3ca3eb03-7eac-477d-b253-15ce603d2550': 'All Acoustic Guitars',
    '40e8bfd0-3021-43f7-b104-9d7b19af5c2b': 'All Home Audio',
    '53a9c7d7-d73d-4e7f-905c-553503e50a90': 'All Bass Guitars',
    '58d889f7-0aa1-4689-a9d3-da16dd225e8d': 'All DJ and Lighting Gear',
    '62835d2e-ac92-41fc-9b8d-4aba8c1c25d5': 'All Accessories',
    'b021203f-1ed8-476c-a8fc-32d4e3b0ef9e': 'All Pro Audio',
    'b3cb9f8e-4cb6-4325-8215-1efcd9999daf': 'All Drums and Percussion',
    'd002db05-ab63-4c79-999c-d49bbe8d7739': 'All Keyboards and Synths',
    'dfd39027-d134-4353-b9e4-57dc6be791b9': 'All Electric Guitars',
    'fa10f97c-dd98-4a8f-933b-8cb55eb653dd': 'All Effects and Pedals',
    'fb60628c-be4b-4be2-9c0f-bc5d31e3996c': 'All Folk Instruments',
    'e71b6e43-7217-4746-b4e3-64bf708a2664': 'All Software',
  },
  allCategories: 'All Categories',
  categories: {
    '69edc7c9-a145-4e7d-bab2-26e9df982c57': 'Tenor',
    'ef1628c8-6af5-401a-9cc9-d52cff12cf81': 'Tenor',
    'd63135ba-55a8-4742-9891-a01d91538d96': 'Baritone',
    '8745626e-3273-4f9d-b7a1-ca5b202a8e6e': 'Noise Reduction and Gates',
    'dedbbe5c-dea0-452d-a8c7-56c46e549083': 'Buffer',
    '9b29cd66-cbb4-4feb-acd0-9b7c0088341c': 'Ouds',
    '5625b85a-7e06-447e-835a-5358b41d9a19': 'Shrutis',
    'ccaf6495-645f-4923-b2d2-38c7593996ae': 'Sitars',
    'd676f419-5955-4027-901f-352e0b0d113b': 'Harmoniums',
    'a4177e01-7aaa-45e4-8bda-4e7eaa2b72fa': 'Vihuelas',
    '2b949508-b68d-4456-9353-85cffc9238d7': 'Guitarrones',
    '8373cae2-8a0e-4ec8-9c19-d7519deb41b3': 'Bajo Quintos',
    'b05b0396-9ea9-4acc-a6d7-fd2f05736efa': 'Bajo Sextos',
    '58e4378e-545b-441b-844d-e171025101d5': 'Cable Adapters and Splitters',
    'd002db05-ab63-4c79-999c-d49bbe8d7739': 'Keyboards and Synths',
    '032c74d0-b0e2-4442-877f-e1a22438a7fa': 'Band and Orchestra',
    'fb60628c-be4b-4be2-9c0f-bc5d31e3996c': 'Folk Instruments',
    'fa10f97c-dd98-4a8f-933b-8cb55eb653dd': 'Effects and Pedals',
    '62835d2e-ac92-41fc-9b8d-4aba8c1c25d5': 'Accessories',
    'b3cb9f8e-4cb6-4325-8215-1efcd9999daf': 'Drums and Percussion',
    '58d889f7-0aa1-4689-a9d3-da16dd225e8d': 'DJ and Lighting Gear',
    '1f99c852-9d20-4fd3-a903-91da9c805a5e': 'Parts',
    'dfd39027-d134-4353-b9e4-57dc6be791b9': 'Electric Guitars',
    '40e8bfd0-3021-43f7-b104-9d7b19af5c2b': 'Home Audio',
    '3ca3eb03-7eac-477d-b253-15ce603d2550': 'Acoustic Guitars',
    'b021203f-1ed8-476c-a8fc-32d4e3b0ef9e': 'Pro Audio',
    '37f5ad77-15d0-4c2c-b4d3-1438c0805f13': 'Other',
    '53a9c7d7-d73d-4e7f-905c-553503e50a90': 'Bass Guitars',
    '09055aa7-ed49-459d-9452-aa959f288dc2': 'Amps',
    '1c7938ae-08d1-4337-b064-ef278869011b': 'Pedal Steel',
    '80d6ce96-487c-4ac1-ad10-d4bef6336fe6': '12-String',
    'c86f64cf-43df-4430-a2bf-b4126d81c5bd': 'Left-Handed',
    '0cb9b4ba-0b70-4b2e-978b-e5da9bd918d3': 'Body Type',
    '4caba01d-fef3-4f67-9c2c-a5116d1faf0a': 'Workstations',
    'fa8d98c5-3538-46d1-b74a-d48c5222f889': 'Controllers',
    'e8a9fc21-1e7b-475a-8928-5847fe1b5cca': 'Digital Pianos',
    '70bfe6dc-f5a4-4a38-a6fb-0957ef1d6324': 'Studio Furniture',
    '14d6cc96-ed7b-4521-bc21-7713c61e9dc5': '12-String',
    'f0589303-a790-44be-8c64-3dfb54485cd0': 'Acoustic Pianos',
    '47ebdc60-1ee5-4221-9b0b-fc45396fc003': '500 Series',
    '9bee8b39-c5f1-4fa7-90af-38740fc21a73': 'Overdrive and Boost',
    '36a0faca-93b7-4ad1-ab09-02629ec1e900': 'Recording',
    '8dab3e10-a7f8-444b-aa9d-ccdab4fe66c6': 'Octave and Pitch',
    '6bd92034-d59c-4d78-a6c1-1e8a3c31b31e': 'Controllers, Volume and Expression',
    'edd6e048-a378-4f6f-b2b5-dd46016c6118': 'Small Amps',
    '100a3e13-4d9c-4ee6-b093-d464b101f26e': 'Acoustic Amps',
    'e7b4aceb-7acd-41a1-b7d4-4ec889c0b237': 'Outboard Gear',
    'bfaedcbb-d128-4f5e-b708-d475c6c32277': 'Keyboard Amps',
    '88be2998-9f17-42ea-a8b9-a38d1f72e1a7': 'Modulation',
    '4069c185-c9a4-4354-b09f-31e83fbde2f1': 'Attenuators',
    '0bbe9f3e-7a6e-4654-862f-96dd6136c9b3': 'DI Boxes',
    'c6602a28-e2e7-4e70-abeb-0fa38b320be6': 'Bass Pedals',
    '76e7e290-b3de-4df0-89ac-548fb678fc45': 'Ring Modulators',
    '8a15a0e1-e341-44dd-9fd4-7093cf6daa64': 'Noise Generators',
    'af33fcd5-f10a-407d-a39d-92765a7d4796': 'Left-Handed',
    '09ea109a-5df1-4156-b00c-7456a3e5abf3': 'Built-in Electronics',
    '43f3b19b-c27a-423a-8713-f4dd202ac7d1': 'Body Type',
    'b753bcd4-2cc5-4ea1-8f01-c8b034012372': 'Flanger',
    '38f7f86b-5d7a-499a-9bdc-c3198395dfa6': 'Tremolo and Vibrato',
    '25a78085-c0cf-451c-b9c5-5898baa39431': 'Boutique',
    'ac571749-28c7-4eec-a1d9-09dca3cf3e5f': '4-String',
    '3178be7d-f1cd-4da5-a606-bf3c1b8e834d': '5-String or More',
    '0cd005a7-90ce-4f51-8e3c-b7acc4fa82f0': 'Left-Handed',
    'bd0f2bd8-714b-4d19-ad87-05c5695a3b02': 'Acoustic Bass Guitars',
    'ab53a2da-f594-42eb-88ad-bf1eb84aa151': 'Acoustic Drums',
    '3a410221-65c5-424f-b729-78ca1cbd66a8': 'Cymbals',
    '14aea629-7d78-4a57-8195-167e5713f3bc': 'Parts and Accessories',
    'a69d9614-7635-45f4-8fd5-4931ce756655': 'Active Electronics',
    '10335451-31e5-418a-8ed8-f48cd738f17d': 'Guitar Combos',
    '19d53222-297e-410c-ba4f-b48678e917f9': 'Guitar Heads',
    'f1b3d127-4158-43c3-934b-e402adc3d6ca': 'Guitar Cabinets',
    '052c7288-b2e0-42d2-8927-2c9176ef1699': 'Bass Combos',
    '892aa8b2-a209-49db-8ad2-eed758025a9d': 'Bass Heads',
    '6c664a95-048a-4795-b0ad-c7c03d9eee4c': 'Bass Cabinets',
    '732e30f0-21cf-4960-a3d4-bb90c68081db': 'Distortion',
    '305e09a1-f9cb-4171-8a70-6428ad1b55a8': 'Fuzz',
    '3b09f948-3462-4ac2-93b3-59dd66da787e': 'Delay',
    '1738a9ae-6485-46c2-8ead-0807bb2e20e9': 'Reverb',
    '2d6093b4-6b33-474e-b07c-25f6657d7956': 'Multi-Effect Unit',
    '0f2bbf76-3225-44d5-8a5b-c540cc1fd058': 'Microphones',
    '8260fe5a-bc98-4600-a64c-e1564bb8ee5b': 'Auxiliary Percussion',
    '0338462f-285a-4273-afde-47c4fc752e3f': 'Modeling Amps',
    '8f8e21ff-aa89-4f79-943c-dfaab20251f4': 'Pad Controllers',
    '271e38fa-a852-48a9-8785-3883023f9742': 'Keyboard Accessories',
    'fc775402-66a5-4248-8e71-fd9be6b2214a': 'Amp Modeling',
    'a7f470d1-266d-4495-b4d6-998cc84b7474': 'Classical',
    'd6b798a5-113c-4aa0-9cc5-ff58f4a14fc1': 'Synths',
    '15800d29-53a1-446e-8560-7a74a6d8d962': 'Chorus',
    '75e55b59-f57b-4e39-87b1-fcda4c1ed562': 'Phase Shifters',
    'a92165b2-2281-4dc2-850f-2789f513ec10': 'Wahs and Filters',
    '86d377ed-c038-4353-a391-f592ebd6d921': 'Compression and Sustain',
    'feb3c208-d3d5-4a57-bb7a-6e594b914dc0': 'Drum Machines and Samplers',
    'dfb72735-b7a3-4c8f-9b14-0d1dd904af1e': 'Interfaces',
    '760ffd05-b7e1-4dc9-a293-5e3f1da33483': 'Portable Recorders',
    '4ca4f473-567f-46b0-8b66-9936a1179cd6': 'Boutique Amps',
    '148977b8-b308-4364-89fc-95859d2b3bc3': 'Electric Pianos',
    'f4499585-f591-4401-9191-f7ba9fdeb02c': 'Organs',
    '66170426-1b4d-4361-8002-3282f4907217': 'Loop Pedals and Samplers',
    '69a7e38f-0ce8-42ea-a0f6-8a30b7f6886e': 'Tuning Pedals',
    '66fd5c3b-3227-4182-9337-d0e4893be9a2': 'Pedalboards and Power Supplies',
    'ec612b9c-6227-4249-9010-b85b6b0eb5b0': 'EQ',
    '07276aa7-6f18-4cae-a691-e6043b002fa4': 'Short Scale',
    'c63d7668-c0d1-421d-97ef-587959f7282c': 'Mixers',
    '391e9807-91a1-4960-96d7-7ea8939a9b37': 'Power Amps',
    '3d6a3a23-4c87-4306-abf6-63003374505e': 'Electronic Drums',
    'e1b634e7-1680-4f98-9801-d4b7a35f7ec3': 'Speakers',
    'bb2ad5f1-32a9-4f1e-8fa1-fdf77c7e6cfd': 'Boxed Software',
    '7dd339e2-d205-4d4b-a9a9-b37eb3c7d266': 'Hand Drums',
    'ed9714d2-2b98-4e1e-b85e-eb2f948a8985': 'Guitar Pickups',
    'f87eeadc-82f0-4e67-97df-8ac363f28e1a': 'Knobs',
    '5cb132e1-1a42-42f4-bcd1-cf17405e7aff': 'Case Candy',
    '3b1bc5f2-e783-4a53-b21c-61d54bff9837': 'Tuning Heads',
    '92aef906-a2fd-47be-85f5-8595cc61bedb': 'Guitar Bodies',
    'fb27a644-e2d6-4380-89be-eec471fe527e': 'Guitar Parts',
    '3852c31e-5019-4cd6-8c60-ba5fd397cf43': 'Bass Guitar Parts',
    'eb1827f3-c02c-46ff-aea9-7983e2aae1b4': 'Amp Parts',
    '96843abf-85ee-4d86-93e9-3140d698f1b7': 'Bass Pickups',
    'd6322534-edf5-43dd-b1c0-99f0c28e3053': 'Mandolins',
    '45ba2a33-add3-4f9d-a2d4-842fa663924f': 'Banjos',
    'e70f504b-eee7-4b71-89bc-eb925e802b3d': 'Ukuleles',
    '1b357626-300e-47dd-85b0-bf657bca1a96': 'Straps',
    'aff19d6a-ad5e-4b3b-b21c-8aa71ae834c6': 'Tuners',
    '0cb91848-05ae-4dda-a1f4-2f667e4955bb': 'Violins and Fiddles',
    '98a45e2d-2cc2-4b17-b695-a5d198c8f6d3': 'Picks',
    '65e5c829-1909-4310-a842-78ceef836dcc': 'Brass',
    '6306b5da-d0c0-40e0-8ffc-d2162cab0a95': 'Woodwind',
    '43f41799-c490-4520-ab8d-be500bd1eda9': 'String',
    '516cfd7e-e745-44cf-bb72-053b3edcddaf': 'Cables',
    'c355c3f3-87b8-4798-ab49-3068cd94bf6a': 'Strings',
    '0ab39944-c802-44e8-ac2d-842c78bb38d0': 'Cases and Gig Bags',
    'bb1ca93f-5dcc-48ec-adad-77d22f61b588': 'Stands',
    '6c29a999-cc23-47b6-ade9-68a14b8e3149': 'Capos',
    'f5b5d030-51db-0134-7b64-2cbc3295deb9': 'Slides',
    '2015ef45-0261-4fb5-a5ca-f33e76e5f8da': 'Pickguards',
    '41c7ac92-b6b4-443d-81d4-c06aa7f2002e': 'Harmonicas',
    '7681b711-435c-4923-bdc3-65076d15d78c': 'Books and DVDs',
    '797faee0-04fd-4998-954d-83c88c8e8527': 'Humidifiers',
    '22af0079-d5e7-48d1-9e5c-108105a2156c': 'Merchandise',
    '4ca6d5e9-f00f-468d-bcae-8c7497537281': 'Tools',
    '5004a624-03c4-436b-81bf-78a108eb595d': 'Headphones',
    'c021312c-60e1-4246-a2bd-088bfca99b00': 'Acoustic Pickups',
    '98a5fe5f-3399-45fc-95ec-a9f7fe9f41e8': 'Accordions',
    '0fa6ff05-bfb7-4b1c-913c-0b66f2897fbc': 'Metronome',
    '5737678f-8d15-4df2-b402-869d5bfc04bd': 'DJ Controllers',
    'f8c37b8b-a8c6-4967-a2a4-7d50717a52ab': 'Turntables',
    '4bf02b52-1389-4c07-af81-8e73f4eebaa0': 'DJ Interfaces',
    'd007c1a2-be03-4d5a-b2df-182a0da41271': 'Mixers',
    '1c65040a-6cca-4834-985e-44b2b29c14c1': 'All-in-one DJ Systems',
    '87581b0b-de93-49c2-9fe5-6ac5e62987f0': 'Lighting',
    'cdadd9b5-9d6d-4193-b0ee-b94d9ffd02ec': 'Amp Covers',
    '846e9dbd-366c-4532-acfd-3df0df550c7e': 'Speakers',
    '8bdd354f-1439-4d17-a556-6f331edddb73': 'Headphones',
    '1d814773-b05a-4f3e-a674-710783a78aaf': 'Digital Players',
    'ecd5b0f4-3393-4f3c-8ed8-3b8250e2b72d': 'Turntables',
    '21687a23-6d7c-45e4-a77f-05adafbf18b3': 'Cables',
    '69efce9d-d973-44b1-b3c9-bebe4797c744': 'Complete Stereo Systems',
    '4d45f512-4dd5-4dae-95b7-7eb400ce406b': 'Preamps',
    'dbe028e5-454b-462c-b51b-467e10ea8f3b': 'Preamps',
    'b7c14414-a2fb-4345-bd3d-887ce076769c': 'Amplifiers',
    '777d30e0-a38d-42dd-8ada-c49e7d01df25': 'Receivers',
    'afa4a4ae-efcf-4252-8dab-025a1c4ae5bc': 'Equalizers',
    '2cd13545-e7f2-4713-a207-1b501c4d2f0c': 'Power Distribution and Conditioning',
    '010855a4-d387-405f-929d-ec22667abadc': 'Tape Decks',
    '9614b33f-20dc-4381-9d16-aa1c086f0e7e': 'Pedal Parts',
    'b6895ed9-1d96-4f5e-8651-81bf5c3db748': 'Parts and Accessories',
    '2b92939c-5656-418e-b0c5-1bff2aa2ab86': 'Tuners and Radios',
    '5db35d7e-2b7e-4dcf-a73b-6a144c710956': 'Hollow Body',
    '8f8b1f88-83f5-449c-8c93-b57cf3ba1fd7': 'Baritone',
    'ddd7553e-68d5-4005-a356-3f94202682a8': 'Lap Steel',
    '60a89ea2-9604-46a8-a113-bfc065b48f28': 'Travel / Mini',
    'e57deb7a-382b-4e18-a008-67d4fbcb2879': 'Solid Body',
    '367e1d5d-1185-4a1e-b283-8ec860dc1d5f': 'Archtop',
    '6a63ac2e-f2a5-4064-b6ea-0393f42ee497': 'Semi-Hollow',
    'd3a11618-98ef-4488-9dc0-84410675aa44': 'Snare',
    '9fc2f928-2abf-466f-a7c9-74019e518251': 'Bass Drum',
    '5dac8308-df5f-4568-9439-fc0f55a87e8e': 'Shakers',
    '6c767197-4717-4c71-aada-d4173c85efbd': 'Djembes',
    '77995b11-8194-483c-8c1f-b176c8547e71': 'Stands',
    '9854a2b4-5db3-4dfd-85f1-dcb444d5d7f6': 'Rackmount Synths',
    'c577b406-a405-45ec-a8eb-56fbe628fa19': 'Analog Synths',
    'caa94b2b-d52b-499c-b4bb-412568747078': 'Modules',
    '66695cf7-b835-4792-9eb8-e6c22cf22ba9': 'Full Electronic Kits',
    'a04839b4-ef84-4568-9d38-e057509b1836': 'Cases and Bags',
    'a79c7ff7-059c-473d-99f3-0846201e9733': 'Thrones',
    '75b1e4a3-dbb4-46c5-8386-fa18546e097a': 'Digital Synths',
    '16fcc8f2-74d5-48d1-b738-1fc33a1660ae': 'Modular Synths',
    '18bdeae7-e834-42a8-aeee-0e8ae33f8709': 'Concert',
    '14351391-330c-4cc5-9094-037f7c88a745': 'Crash',
    'db34e833-b352-45b9-9976-4f674a7e6d8c': 'OM and Auditorium',
    'b905874b-ecdd-43f0-99e5-5c4d6857ff99': 'Full Acoustic Kits',
    '89111ae8-1ab9-4655-81aa-6da9d1cda2ab': 'Tom',
    'c58c6c12-4b50-4568-90c9-e071ec8e6a26': 'Jumbo',
    '800b0095-e12e-4304-b599-225c6e5bd61e': 'Drum Parts',
    'da296454-3c03-407b-9dca-3b09f9ceef3f': 'Ride',
    '93d86ecd-27e6-452c-9a43-16a0c5d547ab': 'Other (Splash, China, etc)',
    '70c36cd6-15b7-4787-80c2-ee939893d761': 'Cajons',
    'ad12f92f-a8d5-4b27-9676-7e9c8da9bcee': 'Drum Sticks and Mallets',
    '662e6fd4-2465-4699-9b75-32707eb3cfbb': 'Hi-Hats',
    '32488d42-5c28-4568-a929-046578604fda': 'Eurorack',
    'e632cb6c-bfc2-4140-ac63-c8edbe5db58a': 'Cymbal Packs',
    '15cedc86-0e56-4800-b3f0-0f99fab350cb': 'Parlor',
    '630dc140-45e2-4371-b569-19405de321cc': 'Dreadnought',
    'db8beca4-652b-4f36-8875-4001cd6b916b': 'Congas and Bongos',
    '4fe438c3-8312-4c52-b8d6-9f502174cbe7': 'Pedals',
    '8b531867-88ee-46c5-b6d1-40d2d6b9dc35': 'Resonator',
    'be24976f-ab6e-42e1-a29b-275e5fbca68f': 'Archtop',
    'b93ec8ea-a975-4ccb-a8c7-48bb75500905': 'Drum Keys and Tuners',
    '59d20746-12e0-45a8-b2ce-aa21cbdeec5f': 'Mounts',
    '7a28aae1-de39-4c8b-ae37-b621fc46a5e9': 'Heads',
    '090748e6-9ca8-4083-896b-d59e0aa42582': 'Mini/Travel',
    'c666d943-19fe-4ba6-be48-107691e8f3d2': 'Powered Speakers',
    '7d314252-3de4-494c-9e49-27edc5ef6482': 'Passive Speakers',
    'ae1822ce-4a55-42b0-a094-b9be6b27fc62': 'Bridges',
    '702abcab-7cef-4e39-bb43-7d7b2af36211': 'Benches',
    '206ee409-a7e6-4c15-8ef9-eee27139a5fc': 'Pedals',
    'c33e4de7-7ec0-4de9-912c-98a4dcb268c8': 'Necks',
    '7737a99b-ff87-4174-8d35-a96cb7dfc4ea': 'Cases',
    '64aa38c7-bbe6-4f9f-9df8-179107961d29': 'Stands',
    '33095a65-5662-414a-b86d-0e874911da16': 'Tailpieces',
    'f4104d52-a9ee-4256-8753-15bf5ff1b71d': 'Studio Monitors',
    '07d56816-232f-48e7-8606-7ed040beab16': 'Grand Pianos',
    'b387f2a1-0781-490a-a3f7-9d1797235cc4': 'Upright Pianos',
    '5d1a7016-8c02-4b22-8094-101c4d897bb4': 'Bassoons',
    'bacbeb52-d91c-4160-80ce-1f62a00072ca': 'Violas',
    'b73c1a62-1ba7-473e-80c0-b6b14fc65e77': 'Cellos',
    'a9013455-2233-4860-9f80-9d0606ef5405': 'Upright Bass',
    'ef040ff3-b7fc-4dc2-bcde-fb3d3f27d170': 'Harps',
    '970f9ee2-5e08-456d-a066-68e2d4a0217d': 'Streamers',
    'bd6ce3c9-b37a-4dd4-899b-9cf3488ebd4c': 'DACs',
    '99dd83ce-0fd7-4125-a650-5d9d68e394d1': 'In-wall Speakers',
    '2f636273-2b30-4b4e-8347-fcaabd34087a': 'Wireless Speakers',
    '8fc23224-7570-4ccb-a1b9-cf1a42b0503e': 'Outdoor Speakers',
    '7d6f824c-7f6a-4850-a4e6-33de8ce09c74': 'Portable Speaker Systems',
    '9357a4ce-1d80-40ed-8dbf-bfc89483acde': 'Closed-back Headphones',
    'ff66f312-2ea4-419d-a797-8a2ef3beea2c': 'Surround and Satellite Speakers',
    'd806bffe-8e7b-4be0-a80d-13dfb16db675': 'Bass Strings',
    'df6f1f19-e67e-42c7-b00b-608cb577edf4': 'Guitar Strings',
    '0aa66738-2512-4952-8626-4edec26c7a01': 'Woodwind Accessories',
    'dcd03dd7-e1bf-4fb9-84a5-0e5126a3fc64': 'Brass Accessories',
    'b1f4ce46-26e5-4f27-8b8a-66bd0f41a8eb': 'Guitar Cases',
    'bd397c15-0cf3-4c6f-8005-7b8309ced1c4': 'Bass Cases',
    'aefe60d2-6b9e-461c-9952-85d084f4b919': 'Baritone',
    '9f2edb74-fcd6-41a2-8d25-99222cc2577a': 'Guitar Gig Bags',
    '8dc53835-25c2-47a6-a51b-97a899d8ce2e': 'Other Strings',
    'a119407f-1192-42c9-93f1-d59e952f9917': 'Ukulele Strings',
    'ad1bc64f-ef22-4e54-a3fc-8db3cf038d35': 'Bass Gig Bags',
    '7e6c29f0-9536-439b-a190-7e4fe75a8a2c': 'Over-ear Headphones',
    '594f4bc6-8748-4533-9630-d76d99df4d36': 'Open-back Headphones',
    '73b6cf8b-aef7-4073-99a0-ed4088d27cea': 'Noise-canceling Headphones',
    '7f07764a-073d-4ffe-ac2d-04c1b5a71bdf': 'On-ear Headphones',
    'ad6abc16-7820-46fc-acbb-1845e4415b86': 'Bookshelf Speakers',
    'a6e7512a-fdb1-4f92-9787-c44c8af76124': 'In-Ear Headphones',
    '37bb4d03-3cc8-4963-b8c2-a394137696a8': 'Wireless Headphones',
    '7305478f-5865-4100-b8d7-91629e14d3a4': 'Subwoofers',
    '09d93d42-72f1-42c1-9352-e28ec34b0ebf': 'Floor Speakers',
    'c584855a-319b-46c8-9578-489325c9318c': 'Mandolin Strings',
    '3f6b77c2-996b-4e41-a6c2-2bd4bb6af4e3': 'Speaker Parts and Accessories',
    '96f2cafd-ada4-4864-8f5b-2c8739318892': 'Headphone Parts and Accessories',
    'ac90b7da-303f-4219-b6ea-3270d63d6a05': 'String Accessories',
    '47cb0d09-e0d2-41a6-bce6-5701e7a99eed': 'CD Players',
    '0e4e93fd-d460-41ec-a703-c04ab2fb2403': 'Portable Digital Players',
    '56a98546-eae0-481a-9cba-7697cbad6795': 'Media Servers',
    'd1c7768e-068d-4b0f-8e3d-b4e7be71a751': 'Trumpets',
    '4ca764aa-56d7-42a6-80cc-75d125ab4d97': 'Trombones',
    'bf70811f-7934-42ec-83b3-d75d51a265e1': 'French Horns',
    '7e3c8eb2-b638-47cc-a4c6-d7171a358305': 'Tubas',
    '5c89d4ef-7652-4f89-b766-bb257e746099': 'Saxophones',
    'fa60a987-584a-4731-9471-99d90c45a4d3': 'Clarinets',
    'bf495fa4-f89f-4b09-9c54-ede0d086a226': 'Flutes',
    'a256fd82-ab99-4c7e-9fed-9f3b912b52e3': 'Oboes',
    '0c9548aa-4c07-4ceb-9ebf-bc3bbb581e75': 'Violins',
    'af044b2e-88b9-4b3d-97f3-1d7b7c0831af': 'Banjo Strings',
    '4fc2f5ec-384c-49dd-adb9-4f437296ce0b': 'Turntable Parts and Accessories',
    '1f7ab3de-1751-4e3f-9c08-f3173179f465': 'Power Supplies',
    '008f4540-8b55-4136-ba64-ee05292ae586': 'Accessories',
    '58d52275-f213-4c21-919e-c38a7c9f35bc': 'Wireless Receivers',
    '7f43425f-6b9c-4ec7-865b-4517ca49c9f8': 'Wireless Transmitters',
    'f4009e8b-c421-4ba4-830e-ebdcb281dd4a': 'Wireless Instrument Systems',
    'e71b6e43-7217-4746-b4e3-64bf708a2664': 'Software',
    '0d1dd31d-50d4-45a2-a640-8e59e6262f26': 'DAWs',
    'bc2129f6-85c3-4908-af96-d74828a1e783': 'Instruments',
    'c4b5fba6-6526-489c-994e-462ba5be75dd': 'Effects',
    '88c22106-091a-45b6-a0b2-32e8a33b92ff': 'Utilities',
    '7ddd7fc0-59cc-42ca-b52d-181e1eea4294': 'Tubes',
    '6a00326e-3acc-4a53-be16-389d7b6a228c': 'Replacement Speakers',
    'ba6ed5ba-a92b-4fb7-b522-6314a221a21c': 'Presets',
    'ea74dc25-00a0-4a0c-a12b-24e107aaa640': 'Learning',
    'e6f8d815-68b8-405c-a96e-f633b56651f0': 'Mobile',
    'b7efe150-0629-463e-9fb5-7e1ccfed2c39': 'Portable PA Systems',
    'a509367a-6d89-4692-9e28-796357b009a7': 'Powered Mixers',
    '6491252a-5921-476b-8244-18295f83e809': 'Practice Pads',
    'af49e039-c41a-49e5-8da8-368b2bea96a7': 'Vibraphones',
    'a0d444bf-5545-4408-b5f3-ad2d45c1627f': 'Bells and Glockenspiels',
    'a9d7ea5c-16c4-44ac-ab18-79a6b4d9646e': 'Xylophones',
    'c6c3d9d6-e735-4c13-b1e0-3f593968f4f1': 'Marimbas',
    '67794239-e10f-454a-a485-6a6b6f49a154': 'Mallet Percussion',
    'ecb1bc0c-1f79-40a9-9429-0696defa7b19': 'Marching Cymbals',
    '281156ed-a044-44fe-af7e-229fd082f624': 'Marching Toms and Tenors',
    'fd5b4ede-019d-42c1-9fe9-325d296af2e4': 'Marching Snare Drums',
    '66bb7da7-9b89-485c-85ea-d3ef53ddd39f': 'Marching Bass Drums',
    'a0ad1b20-c5ea-43de-af9a-e98bf3eaf4a7': 'Marching Percussion',
    'a7c8bcd2-e9f1-4fd8-b3bf-f54865827965': 'Gongs',
    '6db94baf-d467-4cd1-8c2d-f7893448ff69': 'Concert Cymbals',
    'd3373fb5-c795-4214-aba2-6dfc73eed437': 'Timpani',
    'b1956192-43f1-49bd-ac43-83f677036194': 'Concert Toms',
    '059db6fa-cedf-40af-b36e-e08a98d51b87': 'Concert Snare Drums',
    '545b991b-6236-4e52-ae82-5c8a8b47d428': 'Concert Bass Drums',
    'ae5d9a1f-cb44-4dde-9bcc-b76c90c078e4': 'Concert Percussion',
    '1287ab0e-3696-408a-8c10-dbf4bf9a2fcc': 'Samples and Loops',
    '5b9e2d05-0797-4c53-a653-390516aef1e9': 'Fretless',
    'b891b5df-051c-4ee1-ac91-c9a38f62ef90': 'Recorded Music',
    '811382af-d7f8-4529-9c46-a8aa8d99403e': 'Vinyl',
    '0d80aa45-15e7-4693-94d8-c323627818f4': 'CD',
    '2b4aa540-3dd7-4eb2-812e-4e7920284d75': 'Cassette',
    '54927aba-b26f-4d7b-a78b-5e92f470bb75': 'Reel-to-Reel',
    'e5553727-8786-4932-8761-dab396640ff0': 'Vocal',
    '37552beb-79c3-4231-b9aa-a5d559f7e7d4': 'Channel Strips',
    'd3c6e84c-5bb2-41a3-9a60-0e8b88edc515': 'Microphone Preamps',
    'f8bc7750-ce54-48d7-b130-c8ec5efd7b13': 'AD/DA Converters',
    '1db56ead-c657-4a26-ac8b-2e441d5c6e76': 'Equalizers',
    '101acc1f-ae38-4e27-9fde-1948498618aa': 'Multi-Effect',
    '8865016e-edbb-4ee7-a704-6ea0652d6bf4': 'Compressors and Limiters',
    '10187eaa-7746-4978-9f44-7670e95a40da': 'Gates and Expanders',
    'af9cb76e-0fd3-43fe-9f2d-6f53a4e60371': 'Reverb',
    'd02b5dfa-38f1-4eec-9ebb-1eba6b108c53': 'Delay',
    '8203898c-9272-4439-bc6e-8b86ed463e11': 'Summing',
    '4a1f4a3a-c266-4771-b473-ec547b835e55': 'Utility',
    '2b91a345-aad9-424e-ac6c-13c7d2d0d373': 'Patchbays',
    'e36bdc32-abba-45e2-948b-ce60153cbdd9': 'Drum Machines',
    '5e3c7bdb-469a-4a22-bbb2-85ddf8bff3c9': 'Samplers',
  },
};