import React from 'react';
import I18n from 'i18n-js';
import moment from 'moment';

import {
  google_protobuf_Timestamp,
} from '@reverbdotcom/commons/src/gql/graphql';

export interface IPriceRecord {
  timestamp: google_protobuf_Timestamp;
  conditionDisplayName: string;
  priceDisplay: string;
  id: string;
}

interface IExternalProps {
  priceRecords: IPriceRecord[];
  dateHeader?: string;
  conditionHeader?: string;
  priceHeader?: string;
}

function tableRowBackground(index) {
  const isEvenRow = index % 2 === 0;

  if (isEvenRow) {
    return 'bg-color-page-secondary';
  } else {
    return '';
  }
}

export default function TransactionTable({
  priceRecords,
  dateHeader = I18n.t('discovery.priceGuideTool.transactionTable.columns.date'),
  conditionHeader = I18n.t('discovery.priceGuideTool.transactionTable.columns.condition'),
  priceHeader = I18n.t('discovery.priceGuideTool.transactionTable.columns.finalPrice'),
}: IExternalProps) {
  return (
    <table className="width-100">
      <thead>
        <tr>
          <th className="weight-bold scaling-ptb-4 scaling-plr-4">
            {dateHeader}
          </th>
          <th className="weight-bold scaling-ptb-4 scaling-plr-4 ">
            {conditionHeader}
          </th>
          <th className="weight-bold scaling-ptb-4 scaling-plr-4 ">
            {priceHeader}
          </th>
        </tr>
      </thead>

      <tbody>
        {priceRecords.map((priceRecord, index) => (
          <tr key={priceRecord.id} className={`${tableRowBackground(index)}`}>
            <td className="scaling-ptb-2 scaling-plr-4">
              {moment.unix(priceRecord.timestamp.seconds).format('LL')}
            </td>
            <td className="scaling-ptb-2 scaling-plr-4">
              {priceRecord.conditionDisplayName}
            </td>
            <td className="scaling-ptb-2 scaling-plr-4">
              {priceRecord.priceDisplay}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}
