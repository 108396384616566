import React from 'react';
import I18n from 'i18n-js';
import { get } from 'lodash';

import CoreLink from '../../components/core_link';
import SanitizedRender from '../../components/sanitized_render';
import { RCStarRating } from '@reverbdotcom/cadence/components';
import { buildCSPClick } from '../../elog/mparticle_tracker';

import { Commons_Cms_CspCollectionQuery } from '../../gql/graphql';
import { imageUploadURL, prefilledListingFromCSP } from '../../url_helpers';

import {
  findLowestPrice,
  NameMetaData,
  ImageMetaData,
} from '../lib/metadata_helper';
import { IUserContext, withUserContext } from '../../components/user_context_provider';

const COMPONENT_NAME = 'CspFeatureListItem';

export interface IProps extends IUserContext {
  csp?: Commons_Cms_CspCollectionQuery['cspSearch']['csps'][0];
  description?: string;
  splitPrices?: boolean;
  listPosition?: number;
  hidePrices?: boolean;
  imageOverride?: string;
  useCompareCTA?: boolean;
  useCspSummary?: boolean;
  position?: number;
  shouldLinkToSellForm?: boolean;
  trackingName?: string;
}

export class CspFeatureListItem extends React.Component<IProps, null> {
  getImageBackgroundStyle() {
    const img = this.props.imageOverride ?
      imageUploadURL(this.props.imageOverride) :
      this.props.csp.image.source;

    return { backgroundImage: `url(${img})` };
  }

  getLowestPrice() {
    return findLowestPrice(this.props.csp).display;
  }

  getUsedPrice() { return get(this.props.csp, 'inventory.usedLowPrice.display', null); }

  getNewPrice() {
    return get(this.props.csp, 'inventory.newLowPrice.display', null);
  }

  trackingName() {
    return this.props.trackingName ?? COMPONENT_NAME;
  }

  hasDescription() {
    const summaryFallback = this.props.useCspSummary && this.props.csp.summary;
    return !!this.props.description || !!summaryFallback;
  }

  destinationPath() {
    if (!this.props.csp) { return null; }

    if (this.props.shouldLinkToSellForm) {
      return prefilledListingFromCSP(this.props.csp.id);
    }

    return this.props.csp.webLink.path;
  }

  priceTranslationKey(format) {
    if (this.props.shouldLinkToSellForm) {
      return `cms.cspFeature.pricing.selling.${format}`;
    }

    return `cms.cspFeature.pricing.buying.${format}`;
  }

  renderPrice() {
    if (!this.getNewPrice() && !this.getUsedPrice()) return null;

    const tags = {
      tagOpen: '<b>',
      tagClose: '</b>',
    };

    if (this.props.useCompareCTA) {
      return (
        <div className="csp-feature-list__item__price">
          <SanitizedRender
            html={I18n.t(
              'cms.cspFeature.priceWithInventory',
              {
                count: this.props.csp.inventory.usedTotal + this.props.csp.inventory.newTotal,
                price: this.getLowestPrice(),
                ...tags,
              },
            )}
          />
        </div>
      );
    }

    if (!this.props.splitPrices) {
      return (
        <div className="csp-feature-list__item__price">
          <SanitizedRender
            html={I18n.t(
              this.priceTranslationKey('priceFromWithTags'),
              { price: this.getLowestPrice(), ...tags },
            )}
          />
        </div>
      );
    }

    return (
      <div className="csp-feature-list__item__price">
        {this.getNewPrice() &&
          <SanitizedRender
            htmlTag="div"
            html={I18n.t(
              this.priceTranslationKey('newPriceFromWithTags'),
              { price: this.getNewPrice(), ...tags },
            )}
          />
        }
        {this.getUsedPrice() &&
          <SanitizedRender
            htmlTag="div"
            className="mt-1"
            html={I18n.t(
              this.priceTranslationKey('usedPriceFromWithTags'),
              { price: this.getUsedPrice(), ...tags },
            )}
          />
        }
      </div>
    );
  }

  renderPlaceholder() {
    return (
      <div className="csp-feature-list__item csp-feature-list__item--placeholder">
        <div className="csp-feature-list__item__title-and-description">
          <div className="csp-feature-list__item__title-and-description__inner">
            <div className="csp-feature-list__item__title">
              <span className="csp-feature-list__item__number-group" />
            </div>
          </div>
        </div>
        <div className="csp-feature-list__item__details">
          <div className="csp-feature-list__item__price" />
          <div className="csp-feature-list__item__image">
            <div className="csp-feature-list__item__image__inner" />
          </div>
        </div>
      </div>
    );
  }

  renderCSPSummary(description) {
    const summaryFallback = this.props.useCspSummary && this.props.csp.summary;
    const summary = description || summaryFallback;
    if (!summary) return;

    return (
      <div className="mt-2">
        <SanitizedRender
          htmlTag="div"
          className="csp-feature-list__item__description"
          html={summary}
          truncationHeight={40}
        />
      </div>
    );
  }

  clickEvent() {
    return {
      ...buildCSPClick(this.props.csp),
      displayStyle: this.trackingName(),
      position: this.props.position,
    };
  }

  render() {
    const { csp, description, listPosition } = this.props;
    if (!csp) return this.renderPlaceholder();

    return (
      <CoreLink
        to={this.destinationPath()}
        className="csp-feature-list__item"
        itemScope
        itemType="http://schema.org/Product"
        clickEvent={this.clickEvent()}
      >
        <div className="csp-feature-list__item__image">
          <div
            className="csp-feature-list__item__image__inner"
            style={this.getImageBackgroundStyle()}
          />
        </div>
        <span className="csp-feature-list__item__number-group">
          <span className="csp-feature-list__item__number-group__text">
            {listPosition}
          </span>
        </span>
        <div className="csp-feature-list__item__title-and-description">
          <h3 className="csp-feature-list__item__title">
            <span>{csp.title}</span>
          </h3>
          {csp.reviewsCount > 0 &&
            <div className="csp-feature-list__item__rating">
              <RCStarRating
                rating={csp.averageReviewRating / 5}
                count={csp.reviewsCount}
              />
            </div>
          }
          {!this.props.hidePrices && this.renderPrice()}
          {this.renderCSPSummary(description)}
        </div>
        <NameMetaData csp={csp} />
        <ImageMetaData csp={csp} />
      </CoreLink>
    );
  }
}

export default withUserContext(CspFeatureListItem);
