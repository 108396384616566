import React from 'react';
import I18n from 'i18n-js';
import SanitizedRender from '@reverbdotcom/commons/src/components/sanitized_render';
import CoreLink from '@reverbdotcom/commons/src/components/core_link';
import { renderToString } from 'react-dom/server';

import {
  shippingGuideDetailsForCategoryUuids,
} from './item2_shipping_prompt_helpers';

interface Props {
  categoryUuids: [];
}

function renderLinkToShippingGuide(shippingPromptDetails) {
  const guideLink = shippingPromptDetails.guideLink;
  const itemKey = shippingPromptDetails.i18ItemKey;

  return renderToString(
    <CoreLink
      target="_blank"
      className="icon-r-chevron-right size-110"
      to={guideLink}
    >
      {I18n.t(`discovery.shipmentPackingHelpLink.packingGuides.${itemKey}`)}
    </CoreLink>,
  );
}

export function ShipmentPackingHelpLink({
  categoryUuids,
}: Props) {
  const shippingPromptDetails = shippingGuideDetailsForCategoryUuids(categoryUuids || []);
  if (!shippingPromptDetails) { return null; }

  return (
    <SanitizedRender
      html={renderLinkToShippingGuide(shippingPromptDetails)}
    />
  );
}

export default ShipmentPackingHelpLink;
