import React from 'react';
import I18n from 'i18n-js';

import { core_apimessages_ListingSaleMembership } from '@reverbdotcom/commons/src/gql/graphql';

import SaleCard, { SaleType } from './SaleCard';
import CoreLink from '@reverbdotcom/commons/src/components/core_link';
import { RCButton } from '@reverbdotcom/cadence/components';
import URLHelpers from '../../../shared/url_helpers';

interface IExternalProps {
  salesMemberships: core_apimessages_ListingSaleMembership[];
  description: string;
  salesType: SaleType;
  showCreateSaleButton?: boolean;
}

export default function SalesCards({
  salesType,
  salesMemberships,
  description,
  showCreateSaleButton = false,
}: IExternalProps) {
  const filteredSalesMemberships = salesMemberships.filter(saleMembership => saleMembership.isListingEligible || saleMembership.isListingInSale);

  return (
    <div>
      <div className="mtb-4">
        {description}
      </div>

      {showCreateSaleButton && (
        <div className="d-flex fx-dir-col gap-3 mb-3">
          <div className="weight-bold">
            {I18n.t('discovery.listingsManagement.sellerListingCard.sales.modal.saleCard.createNewSalePrompt')}
          </div>
          <CoreLink
            to={URLHelpers.mySalesPath}
            target="_blank"
          >
            <RCButton>
              {I18n.t('discovery.listingsManagement.sellerListingCard.sales.modal.saleCard.createSale')}
            </RCButton>
          </CoreLink>
        </div>
      )}

      <div className="d-flex fx-dir-col gap-3">
        {filteredSalesMemberships.map(saleMembership => (
          <div key={saleMembership.sale.id}>
            <SaleCard
              saleMembership={saleMembership}
              type={salesType}
            />
          </div>
        ))}
      </div>
    </div>
  );
}
