import React from 'react';
import Order from './order';
import Loading from '../shared/loading';
import formatAmount from '../shared/format_amount';

class SellerSummary extends React.Component {
  totalText = () => I18n.t('js.previousOrders.totalSellerText', { count: this.props.total });

  totalAmountBought = () => I18n.t('js.previousOrders.totalAmountBought', { amount: formatAmount(this.props.totalBought) });

  render() {
    return (
      <li className="previous-order-totals padded strong">
        <div className="g-container">
          <div className="g-col-6">{this.totalText()}</div>
          <div className="g-col-6 align-right">{this.totalAmountBought()}</div>
        </div>
      </li>
    );
  }
}

class OrderList extends React.Component {
  renderPreviousOrders = () => this.props.previousOrders.map((order, index) => (
    <Order order={order} key={index} negativeStates={this.props.negativeStates} neutralStates={this.props.neutralStates} />
  ));

  renderLoading = () => {
    if (!this.props.loading) { return false; }

    return <Loading />;
  };

  renderPrevious = () => {
    if (!this.props.prevLink || this.props.loading) { return false; }

    return (
      <li className="previous-order-pagination previous">
        <a className="btn btn-blue btn-tiny" onClick={this.props.followPagination(this.props.prevLink.href)}>
          <span className="fa fa-angle-left" /> {I18n.t('js.previous')}
        </a>
      </li>
    );
  };

  renderNext = () => {
    if (!this.props.nextLink || this.props.loading) { return false; }

    return (
      <li className="previous-order-pagination next">
        <a className="btn btn-blue btn-tiny" onClick={this.props.followPagination(this.props.nextLink.href)}>
          {I18n.t('js.next')} <span className="fa fa-angle-right" />
        </a>
      </li>
    );
  };

  renderSummary = () => {
    if (this.props.showSellerView) {
      return <SellerSummary total={this.props.total} totalBought={this.props.totalBought} />;
    }
  };

  render() {
    return (
      <ul className="dropdown-menu js-dropdown-menu pointer-body pointer-top pointer-left previous-order-list">
        {this.renderSummary()}
        {this.renderPreviousOrders()}
        {this.renderPrevious()}
        {this.renderNext()}
        {this.renderLoading()}
      </ul>
    );
  }
}

export default OrderList;
