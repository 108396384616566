import I18n from 'i18n-js';
import React from 'react';
import { debounce } from 'lodash';

import FormGroup from '../components/form_group';
import OfferAmountInputs from './offer_amount_inputs';
import { UserInputOffer } from './negotiation_types';
import {
  core_apimessages_NegotiationParty as NegotiationParty,
  core_apimessages_NegotiationAction as NegotiationAction,
  Commons_Offers_NewOfferFormQuery,
  reverb_pricing_PriceRecommendation as PriceRecommendation,
  core_apimessages_Money as Money,
} from '../gql/graphql';
import { RCAlertBox, RCTextArea, RCChip } from '@reverbdotcom/cadence/components';
import { MoneyInput } from '../components/money_input';
import { parseAmount, parseAmountCents, formatAmount } from '../money';
import EstimatedValueAlert from './estimated_value_alert';
import { MParticleEventName, trackEvent } from '../elog/mparticle_tracker';
import { mapPriceRecommendationToMoney } from '../priceRecommendationMoney';

const PERCENTAGE_BUTTON_CHOICES = [5, 10, 15];

export interface Props {
  listing: Commons_Offers_NewOfferFormQuery['listing'];
  currency: string;
  onOfferChange: (any) => void;
  offer: UserInputOffer;
  party: NegotiationParty;
  messageInput?: React.ReactNode;
  preventLowballOffers?: boolean;
  priceRecommendation?: PriceRecommendation;
}

export function offerIsBelowLowballThreshold(autoRejectPercentage: number, listingPrice: Money, offerPrice: Money) {
  if (!autoRejectPercentage || !listingPrice?.amountCents || !offerPrice?.amountCents) { return false; }

  return offerPrice.amountCents < Math.round((autoRejectPercentage / 100.0) * listingPrice.amountCents);
}

function OfferPercentageButtons({ listingPrice, offerPrice, party, dispatch, belowLowballThreshold }) {
  React.useEffect(() => {
    trackEvent({
      eventName: MParticleEventName.InteractedWithOfferFlow,
      role: party.toLowerCase(),
      negotiationAction: 'create',
      negotiationGuidance: belowLowballThreshold ? 'Lowball' : 'No Price Guide',
      offerStatus: 'new',
    });
  }, [belowLowballThreshold]);

  return (
    <>
      {!belowLowballThreshold && (
        <>
          {PERCENTAGE_BUTTON_CHOICES.map((percent) => {
            return (
              <OfferPercentageButton
                key={percent}
                percent={percent}
                listingPrice={listingPrice}
                offerPrice={offerPrice}
                dispatch={dispatch}
              />
            );
          })}
        </>
      )}

      {belowLowballThreshold && (
        <RCAlertBox type="error">
          {I18n.t('commons.offers.estimatedValueAlert.belowLowballThreshold')}
        </RCAlertBox>
      )}
    </>
  );
}

function OfferPercentageButton({ percent, listingPrice, offerPrice, dispatch }) {
  const percentageFloat = (100 - percent) / 100.0;
  const discountedPriceCents = Math.round(percentageFloat * listingPrice.amountCents);
  const discountedPrice = parseAmountCents(discountedPriceCents, listingPrice.currency);

  return (
    <RCChip
      selected={offerPrice.amountCents == discountedPriceCents}
      onClick={() => {
        dispatch({ type: 'setPrice', value: formatAmount({ ...discountedPrice, symbol: '' }).trim() });
        dispatch({ type: 'setSelectedPercentage', value: percent });
      }}
      size="mini"
    >
      {I18n.t('commons.offers.percentOff', { percent: percent })}
    </RCChip>
  );
}

export function OfferCreateFormFields(props: Props) {
  const [debouncedOfferPrice, setDebouncedOfferPrice] = React.useState('');

  const { shop, price: listingPrice } = props.listing;
  const { price: offerPrice } = props.offer;

  const isBuyer = props.party === NegotiationParty.BUYER;
  const parsedOfferPrice = offerPrice && parseAmount(offerPrice, props.currency);
  const parsedDebouncedOfferPrice = debouncedOfferPrice && parseAmount(debouncedOfferPrice, props.currency);
  const belowLowballThreshold = props.preventLowballOffers &&
    offerIsBelowLowballThreshold(props.listing.offerBotRule?.autoRejectPercentage, listingPrice, parsedDebouncedOfferPrice);

  // Price recommendation-based alerts are triggered by the offer amount input, and we don't want alerts to
  // immediately fire if the user is in the middle of typing a number, so we delay setting "debouncedOfferPrice"
  // by 200ms and attach the alert rendering to that piece of state rather than to the input state itself
  const debounceOfferPrice = React.useCallback(debounce((newOfferPrice) => {
    setDebouncedOfferPrice(newOfferPrice);
  }, 200), []);

  React.useEffect(() => {
    debounceOfferPrice(offerPrice);
  }, [offerPrice, debounceOfferPrice]);

  function messageLabel() {
    if (props.party === NegotiationParty.SELLER) {
      return I18n.t('commons.offers.sellerMessageLabel');
    }

    return I18n.t('commons.offers.buyerShareAMessageLabel');
  }

  return (
    <div className="offers-form__fields">
      <OfferAmountInputs
        offer={props.offer}
        listing={props.listing}
        party={props.party}
        action={NegotiationAction.CREATE}
        currency={props.currency}
        taxable={props.listing}
        onOfferChange={props.onOfferChange}
      />

      {isBuyer && (
        <div>
          {props.priceRecommendation && (
            <EstimatedValueAlert
              offerPrice={parsedDebouncedOfferPrice}
              recommendedLowPrice={mapPriceRecommendationToMoney(props.priceRecommendation.priceLow)}
              recommendedHighPrice={mapPriceRecommendationToMoney(props.priceRecommendation.priceHigh)}
              belowLowballThreshold={belowLowballThreshold}
              party={props.party}
              negotiationAction={NegotiationAction.CREATE}
            />
          )}

          {!props.priceRecommendation && (
            <div className="offers-form__discount-percentage-buttons">
              <OfferPercentageButtons
                listingPrice={listingPrice}
                offerPrice={parsedOfferPrice}
                dispatch={props.onOfferChange}
                belowLowballThreshold={belowLowballThreshold}
                party={props.party}
              />
            </div>
          )}

          {parsedOfferPrice && props.currency != shop.currency && (
            <section className="offers-form__currency-difference-alert">
              <p>
                {I18n.t('commons.offers.currencyMayVary')}
              </p>
            </section>
          )}
        </div>
      )}

      {props.party === NegotiationParty.SELLER && (
        <MoneyInput
          label={I18n.t('commons.offers.label.shipping')}
          onChange={(evt) => {
            props.onOfferChange({ type: 'setShippingPrice', value: evt.target.value });
          }}
          id="offer-shipping-price"
          name="offer-shipping-price"
          currencyCode={props.currency}
          value={props.offer.shippingPrice}
          required
        />
      )}

      {props.messageInput && (
        <div className="offers-form__message-row">
          <FormGroup label={messageLabel()} tagOptional>
            {props.messageInput}
          </FormGroup>
        </div>
      )}

      {!props.messageInput && (
        <div className="offers-form__message-row">
          <RCTextArea
            label={messageLabel()}
            onChange={(evt) => {
              props.onOfferChange({ type: 'setMessage', value: evt.target.value });
            }}
            name="message"
            id="offer-message"
            value={props.offer.message}
            helpText={I18n.t('commons.offers.buyerShareAMessageHelpText')}
            tag="optional"
          />
        </div>
      )}
    </div>
  );
}

export default OfferCreateFormFields;
