import React from 'react';
import { I18N as Translate } from '@reverbdotcom/commons/src/components/translate';
import I18n from 'i18n-js';
import ShieldIcon from '@reverbdotcom/cadence/images/brand/protection-orange.svg';
import HelpCard from '../discovery/help_card';
import { useHelpCenterArticleUrl } from '@reverbdotcom/commons/src/url_helpers';
import { YEAR_2024_TAX_REPORTING_REQUIREMENTS_ARTICLE_ID } from './tax_profile_helpers';

export function TaxProfileFooter() {
  const articleUrl = useHelpCenterArticleUrl(YEAR_2024_TAX_REPORTING_REQUIREMENTS_ARTICLE_ID);

  return (
    <div className="pt-10 mobile-mb-4">
      <HelpCard
        svgIcon={ShieldIcon}
        iconAlt={I18n.t('discovery.taxProfile.footer.shieldAlt')}
      >
        <p className="weight-bold">
          <Translate
            text="discovery.taxProfile.footer.doINeedToProvideInfoHeader"
          />
        </p>
        <p>
          <Translate
            text="discovery.taxProfile.footer.doINeedToProvideInfoLine1"
          />
          <a href={articleUrl} className="text-link">
            <Translate
              text="discovery.taxProfile.footer.doINeedToProvideInfoLearnMore1"
            />
          </a>
        </p>
        <p>
          <Translate
            text="discovery.taxProfile.footer.doINeedToProvideInfoParagraph2"
          />
        </p>
      </HelpCard>
    </div>
  );
}

export default TaxProfileFooter;
