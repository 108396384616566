import React from 'react';
import {
  core_apimessages_Money as Money,
} from '@reverbdotcom/commons/src/gql/graphql';
import CartApplePayButton from './CartApplePayButton';
import { findMerchantId, merchantName } from './checkoutApplePay';
import { ApplePayConfig } from './useCartApplePay';
import { ApplePayButtonVariant, TrackingDisplayStyle } from './ApplePayButton';

interface Props {
  variant: ApplePayButtonVariant;
  checkoutBundlingId: string;
  amountTotal: Money;
  adyenCheckoutPaymentMethodsConfig: string;
  tracking: {
    displayStyle: TrackingDisplayStyle,
  }
}

export function CartBundleExpressApplePayButton({
  variant,
  checkoutBundlingId,
  amountTotal,
  adyenCheckoutPaymentMethodsConfig,
  tracking,
}: Props) {
  const applePayConfig: ApplePayConfig = {
    merchantId: findMerchantId(JSON.parse(adyenCheckoutPaymentMethodsConfig)),
    merchantName: merchantName(),
  };

  return (
    <CartApplePayButton
      variant={variant}
      checkoutBundlingId={checkoutBundlingId}
      amountTotal={amountTotal}
      applePayConfig={applePayConfig}
      tracking={tracking}
    />
  );
}
