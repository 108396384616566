import React from 'react';
import PropTypes from 'prop-types';

function OtherBuyersWithListingInCart(props) {
  return (
    <p className="in-demand-tag">
      <span className="in-demand-tag__title">
        {I18n.t('js.listings.dontMissOut')}
      </span>
      <br />
      {I18n.t('js.listings.peopleHaveInCarts', { count: props.otherBuyersWithListingInCartCount })}
    </p>
  );
}

OtherBuyersWithListingInCart.propTypes = {
  otherBuyersWithListingInCartCount: PropTypes.string.isRequired,
};

export default OtherBuyersWithListingInCart;
