import React from 'react';
import FormGroupWithCheckbox from '@reverbdotcom/commons/src/components/form_group_with_checkbox';

export default function Categories({ categories, setCategories, disabled }) {
  const allCategoriesSelected = categories.filter(cat => cat.checked).length === categories.length;

  return (
    <div className="mb-10">
      <h2>Categories</h2>
      <div className="form-group__help-text">Restricts redemption to the categories selected below. For more details, including how New Buyer campaigns and categories interact, please <a target="_blank" rel="noreferrer" href="https://reverb.atlassian.net/wiki/spaces/MT/pages/1106247698/International+Buyer+Targeted+Promotions#New-buyers">see the docs.</a></div>
      <button
        className="button button--mini mb-4 mt-2"
        disabled={disabled}
        onClick={(e) => {
          e.preventDefault();
          const updatedCategories = categories.map(category => {
            return { ...category, checked: !allCategoriesSelected };
          });

          setCategories(updatedCategories);
        }}
      >
        {`${allCategoriesSelected ? 'Deselect' : 'Select'} All`}
      </button>
      {categories.map(cat => (
        <FormGroupWithCheckbox
          key={cat.uuid}
          inputName={`categories-${cat.name}`}
          label={cat.name}
          disabled={disabled}
          checked={cat.checked}
          updateField={() => {
            const updatedCategories = categories.map(category => {
              if (category.uuid === cat.uuid) {
                return { ...category, checked: !category.checked };
              }
              return category;
            });
            setCategories(updatedCategories);
          }}
        />
      ))}
    </div>
  );
}
