import I18n from 'i18n-js';
import React from 'react';

import CoreLink from '@reverbdotcom/commons/src/components/core_link';
import { buttonClassName, RCAlertBox } from '@reverbdotcom/cadence/components';

interface IProps {
  destinationUrl: string
  destinationHost: string
}

export function OnwardWarning({ destinationUrl, destinationHost }: IProps) {
  return (
    <div className="mt-2 mb-4">
      <RCAlertBox type="warning">
        <div className="pb-5">
          <p className="mb-4 weight-bold size-130">
            {I18n.t('discovery.onwardWarning.header')}
          </p>
          <div className="mb-4">
            <span className="mr-1">
              {I18n.t('discovery.onwardWarning.goingTo')}
            </span>
            <span>
              <strong>
                {destinationHost}
              </strong>
            </span>
          </div>
          <p className="mb-0">
            {I18n.t('discovery.onwardWarning.warningText')}
          </p>
        </div>
        <CoreLink
          interactionType="CTA"
          to={destinationUrl}
          className={buttonClassName({ variant: 'filled' })}
        >
          {I18n.t('discovery.onwardWarning.buttonText')}
        </CoreLink>
      </RCAlertBox>
    </div>
  );
}

export default OnwardWarning;
