import { gql } from '@reverbdotcom/commons/src/gql';
import React, { useCallback, useState, useMemo, useEffect } from 'react';
import { useMutation } from '@reverbdotcom/commons/src/useMutation';
import { I18N as Translate } from '@reverbdotcom/commons/src/components/translate';
import I18n from 'i18n-js';
import { core_apimessages_CreditCard } from '@reverbdotcom/commons/src/gql/graphql';
import { RCButton, RCAlertBox, RCRadioCardGroup } from '@reverbdotcom/cadence/components';
import { MoneyInput } from '@reverbdotcom/commons/src/components/money_input';
import { unformatNumber, parseAmount } from '@reverbdotcom/commons/src/money';
import { SiteModulePlaceholder } from '@reverbdotcom/commons/src/components/site_module_placeholder';
import CreditCardBrandIcon from '@reverbdotcom/commons/src/components/credit_card_brand_icon';
import { CheckoutVerifyCardCVV } from '../checkout_verify_card_cvv';
import { CREDIT_CARD_LIMIT } from '../../lib/creditCards';
import CannotAddNewCardWarning from '../CannotAddNewCardWarning';
import { StatementProps } from './statement_props';
import StatementDetails from './statement_details';
import PaymentSuccess from './payment_success';

interface IProps extends StatementProps {
  areCardsLoading: boolean;
  closeModal(): void;
  creditCards: core_apimessages_CreditCard[];
  newCardId: string;
  primaryBillingCardId: string;
  setPaymentSuccessful: (val: boolean) => void;
  toggleDisplayNewCardForm(): void;
}

const payMyStatementMutation = gql(`
  mutation PayMyStatement(
    $input: Input_core_apimessages_PayMyStatementRequest
  ) {
    payMyStatement(input: $input) {
      statementId,
      statementBalance,
      amountPaidCents
    }
  }
`);

function PayStatementForm({
  areCardsLoading,
  balanceCents,
  closeModal,
  creditCards,
  currencyCode,
  newCardId,
  primaryBillingCardId,
  setPaymentSuccessful,
  statementId,
  toggleDisplayNewCardForm,
  totalOwedCents,
  totalPaidCents,
}: IProps) {
  const [selectedCardId, setSelectedCardId] = useState(null);
  const [paymentAmount, setPaymentAmount] = useState(null);
  const [displaySuccess, setDisplaySuccess] = useState(false);
  const [newBalanceCents, setNewBalanceCents] = useState(null);
  const [amountPaidCents, setAmountPaidCents] = useState(null);

  const [mutate, { loading, errors }] = useMutation(payMyStatementMutation);

  const onSelectCard = useCallback((event) => {
    setSelectedCardId(event.target.value);
  }, [setSelectedCardId]);

  // set selected card after credit cards finish loading
  useEffect(() => {
    if (!areCardsLoading) {
      setSelectedCardId(newCardId || primaryBillingCardId);
    }
  }, [areCardsLoading, newCardId]);

  const unformattedPaymentAmount = unformatNumber(paymentAmount);

  const parsedPaymentAmount = useMemo(() => {
    return parseAmount(paymentAmount, currencyCode);
  }, [paymentAmount, currencyCode]);

  const invalidInputAmount = paymentAmount <= 0 || !/^[0-9,.]+$/.test(paymentAmount) || unformattedPaymentAmount > (balanceCents / 100);

  const submitButtonText = useMemo(() => {
    if (!paymentAmount || invalidInputAmount) {
      return <Translate text="discovery.payStatement.paymentForm.confirmPayment" />;
    }

    return (
      <Translate
        text="discovery.payStatement.paymentForm.confirmPaymentWithAmount"
        args={{ inputAmount: parsedPaymentAmount.display }}
      />
    );
  }, [paymentAmount, invalidInputAmount, parsedPaymentAmount]);

  function isSubmitDisabled() {
    const disableSubmitConditions = !selectedCardId || loading || !paymentAmount || invalidInputAmount;
    if (disableSubmitConditions) {
      return true;
    }
    return creditCards.find(cc => cc.id === selectedCardId)?.needsReverification;
  }

  function moneyInputErrorText() {
    if (!!paymentAmount && invalidInputAmount) {
      return I18n.t('discovery.payStatement.paymentForm.invalidAmount');
    }
    return null;
  }

  async function onClick() {
    const result = await mutate({
      variables: {
        input: {
          statementId: statementId.toString(),
          creditCardId: selectedCardId.toString(),
          payment: {
            amount: parsedPaymentAmount.amount,
            currency: currencyCode,
          },
        },
      },
    });

    if (result?.data?.payMyStatement) {
      setDisplaySuccess(true);
      setPaymentSuccessful(true);
      setAmountPaidCents(result?.data?.payMyStatement?.amountPaidCents);
      setNewBalanceCents(result?.data?.payMyStatement?.statementBalance);
    }
  }

  return (
    <>
      {(displaySuccess) ? (
        <div>
          <PaymentSuccess
            balanceCents={newBalanceCents}
            currencyCode={currencyCode}
            amountPaidCents={amountPaidCents}
            totalOwedCents={totalOwedCents}
            totalPaidCents={totalPaidCents}
          />
        </div>
      ) : (
        <>
          <div className="scaling-padding-6">
            <StatementDetails
              balanceCents={balanceCents}
              currencyCode={currencyCode}
              totalOwedCents={totalOwedCents}
              totalPaidCents={totalPaidCents}
            />

            <div className="pt-6 mb-4">
              {areCardsLoading ? (
                <div className="pb-4">
                  <SiteModulePlaceholder placeholderLineCount={2} />
                  <SiteModulePlaceholder placeholderLineCount={2} />
                </div>
              ) : (
                <RCRadioCardGroup
                  id="payStatementCardSelector"
                  label=""
                  name="pay-statement-card-selector"
                  onChange={onSelectCard}
                  value={selectedCardId}
                  required
                >
                  {creditCards.map((creditCard) => (
                    <RCRadioCardGroup.Option
                      key={`credit_card_${creditCard.id}`}
                      value={creditCard.id}
                      label={
                        <div className="d-flex fx-align-start">
                          <div className="pl-space width-rem-5">
                            <CreditCardBrandIcon brand={creditCard.brand} />
                          </div>
                          <div className="pl-2 weight-normal">
                            <div>{creditCard.display.summary}</div>
                            <div className="size-80">{creditCard.display.expirationDate}</div>
                          </div>
                        </div>
                      }
                      children={(selectedCardId == creditCard.id && creditCard.needsReverification) && (
                        <div className="pt-2">
                          <CheckoutVerifyCardCVV creditCard={creditCard} />
                        </div>
                      )}
                    />
                  ))}
                </RCRadioCardGroup>
              )}
            </div>

            {creditCards.length < CREDIT_CARD_LIMIT ? (
              <RCButton
                fullWidth
                onClick={toggleDisplayNewCardForm}
              >
                <Translate text="discovery.payStatement.paymentForm.addNewCard" />
              </RCButton>
            ) : (
              <CannotAddNewCardWarning />
            )}

            {(errors.length > 0) && (
              <div className="mt-4">
                <RCAlertBox type="error">
                  {errors.map((error) => (
                    error.message.includes('contact Reverb Support') ? (
                      <div key={`${error.message}`}>
                        {error.message.replace('contact Reverb Support.', '')}
                        <a href="https://reverb.com/?load_chat_window&force_web=true">
                          contact Reverb Support
                        </a>.
                      </div>
                    ) : (
                      <div key={`${error.message}`}>
                        {error.message}
                      </div>
                    )
                  ))}
                </RCAlertBox>
              </div>
            )}

            <div className="g-container pt-2 mt-4">
              <div className="g-col-5">
                <MoneyInput
                  type="text"
                  id="paymentAmount"
                  label={I18n.t('discovery.payStatement.paymentForm.paymentAmount')}
                  required
                  currencyCode={currencyCode}
                  onChange={(e) => setPaymentAmount(e.target.value)}
                  errorText={moneyInputErrorText()}
                />
              </div>
            </div>
          </div>
          <div className="scaling-padding-6 bdt-2 bd--offwhite d-flex fx-justify-end">
            <RCButton
              onClick={closeModal}
              variant="transparent"
            >
              <Translate text="discovery.payStatement.paymentForm.cancel" />
            </RCButton>
            <div className="pl-6">
              <RCButton
                variant="filled"
                disabled={isSubmitDisabled()}
                onClick={onClick}
                id="submitStatementPayment"
              >
                {submitButtonText}
              </RCButton>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default PayStatementForm;
