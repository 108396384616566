import React, { useContext } from 'react';
import I18n from 'i18n-js';

import { MParticleEventName, trackEvent } from '@reverbdotcom/commons/src/elog/mparticle_tracker';
import { RCButton } from '@reverbdotcom/cadence/components';
import AutoOfferModal from '../../listings/auto_offer_modal';
import SellerListingsCollectionContext from '../SellerListingsCollectionContext';

interface IExternalProps {
  listingId: string;
  offerActive: boolean;
}

const initialModalState = {
  open: false,
};

function reducer(state, action) {
  switch (action.type) {
    case 'toggleModalOpen':
      return { ...state, open: !state.open };
  }
}

export const COMPONENT_NAME = 'AutoOfferModalButtonV2';

export default function AutoOfferModalButtonV2({
  listingId,
  offerActive,
}: IExternalProps) {
  const [modalState, dispatch] = React.useReducer(reducer, initialModalState);

  const { useLazySellerListingsQuery } = useContext(SellerListingsCollectionContext);
  const { refetchSellerListings } = useLazySellerListingsQuery();

  function handleClick() {
    dispatch({ type: 'toggleModalOpen' });
    trackEvent({
      eventName: MParticleEventName.ClickedComponent,
      componentName: COMPONENT_NAME,
      listingId,
    });
  }

  return (
    <>
      <RCButton
        variant="muted"
        onClick={handleClick}
        fullWidth>
        {offerActive && (I18n.t('discovery.listingsManagement.sellerListingCard.offers.autoOffers.autoOffersManage'))}
        {!offerActive && (I18n.t('discovery.offers.autoOffers.autoOffersEnable'))}
      </RCButton>

      {modalState.open && (
        <AutoOfferModal
          modalIsOpen
          listingId={listingId}
          onModalClose={() => dispatch({ type: 'toggleModalOpen' })}
          refetchSellerListing={() => refetchSellerListings([listingId]) }
        />
      )}
    </>
  );
}
