import PropTypes from 'prop-types';
import React from 'react';
import Progress from './progress';
import APIRequest from '../../components/api_request';
import I18n from 'i18n-js';
import { elog } from '@reverbdotcom/commons/src/elog';

const POLLING_INTERVAL = 3000;

class ProgressBar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      hasError: false,
      progresses: [],
    };

    this.checkForPendingUploads = this.checkForPendingUploads.bind(this);
  }

  componentDidMount() {
    $('[data-bind=s3-upload-form]').on(
      'done',
      () => {
        setTimeout(this.checkForPendingUploads, POLLING_INTERVAL);
      },
    );

    this.checkForPendingUploads();
  }

  checkForPendingUploads() {
    const req = APIRequest.get(`/my/imports/in_progress?upload_type=${this.props.uploadType}`);

    req.then((resp) => {
      if (resp.progresses.length !== 0) {
        setTimeout(this.checkForPendingUploads, POLLING_INTERVAL);
      }

      this.setState({ progresses: resp.progresses });
    });

    req.fail((jqXHR) => {
      elog.error(
        'csv_imports.progress_bar.checkForPendingUploads',
        { status: jqXHR.status, responseText: jqXHR.responseText },
      );
      this.setState({ hasError: true });
    });
  }

  renderError() {
    return <div className="alert alert-error">{I18n.t('js.csvImports.error')}</div>;
  }

  renderProgresses() {
    if (this.state.hasError) {
      return this.renderError();
    }

    return this.state.progresses.map((progress, index) =>
      <Progress key={index} progress={progress} />);
  }

  render() {
    return (
      <div>
        <ul className="import-progresses">
          {this.renderProgresses()}
        </ul>
      </div>
    );
  }
}

ProgressBar.propTypes = {
  uploadType: PropTypes.string,
};

export default ProgressBar;
