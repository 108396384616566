import UserStore from '../reverb-js-common/user_store';
import I18n from 'i18n-js';
import { currentSession } from '@reverbdotcom/commons/src/elog';
import { authHeader } from '@reverbdotcom/commons/src/http_auth_header';

import ReverbCookie from './reverb_cookie';
import { pickBy, identity } from 'lodash';
import { flashExpireAuthError } from '@reverbdotcom/commons/src/flash';
import { reverbDeviceInfoHeader } from '@reverbdotcom/commons/src/headers/reverbDeviceInfoHeader';
import { reverbUserInfoHeader } from '@reverbdotcom/commons/src/headers/reverbUserInfoHeader';

/**
 * DEPRECATED
 *
 * Please consider using the CoreClient or RQL to fetch data from the API.
 * This AJAX client is jQuery based, which means it returns a Promise "like"
 * response that uses the functions `done()` and `fail()` to register success
 * and failure callbacks.
 *
 * WILL NOT WORK WITH async/await
 */

const csrf = ReverbCookie.get('csrf_token');
const EXPIRED_TOKEN_ERROR = 'expired_token_error';

export function headers(moreHeaders, version) {
  return pickBy(
    {
      'Accept-Version': version,
      'X-Item-Region': ReverbCookie.get('itemRegionOverride'),
      'X-Display-Currency': UserStore.getState().currency,
      'X-Shipping-Region': UserStore.getState().shippingRegionCode,
      'Accept-Language': I18n.locale,
      'X-CSRF-Token': csrf,
      'X-Context-Id': UserStore.getState().cookieId,
      'X-Session-Id': currentSession().ID(),
      'X-Requested-With': 'XMLHttpRequest',
      'X-Reverb-App': 'REVERB',
      'X-Postal-Code': UserStore.getState().postalCode,
      'X-Secondary-User-Enabled': (!!UserStore.getState().secondaryUserModeIsActive).toString(),
      ...reverbDeviceInfoHeader(UserStore.getState()),
      ...reverbUserInfoHeader(UserStore.getState()),
      ...authHeader(),
      ...moreHeaders,
    },
    identity,
  );
}

function request(url, data, options, version, type): JQueryXHR {
  const defaultOptions = {
    url,
    type,
    data,
  };
  const customHeaders = options?.headers || {};

  const h = headers(customHeaders, version);
  const ajaxOptions: JQueryAjaxSettings = { ...defaultOptions, ...options, headers: h };

  const resp = $.ajax(ajaxOptions);

  onUnauthorizedErrors(resp);

  return resp;
}

function onUnauthorizedErrors(resp) {
  resp.fail(xhr => {
    let data;

    try {
      data = JSON.parse(xhr?.responseText);
    } catch {
      return;
    }

    if (xhr.status === 401 && data.error_type === EXPIRED_TOKEN_ERROR) {
      flashExpireAuthError();
    }
  });
}

export function get(url, data?, options?, version?) {
  return request(url, data, options, version, 'GET');
}

export function post(url, data?, options?, version?) {
  return request(url, data, options, version, 'POST');
}

export function put(url, data?, options?, version?) {
  return request(url, data, options, version, 'PUT');
}

export function destroy(url, data, options, version) {
  return request(url, data, options, version, 'DELETE');
}

const V3_VERSION = '3.0';
export const V3 = {
  get(url, data = {}, options = {}) {
    return get(url, data, options, V3_VERSION);
  },
  post(url, data = {}, options = {}) {
    return post(url, data, options, V3_VERSION);
  },
  postJSON(url, data = {}, options = {}) {
    const postOptions = {
      contentType: 'application/json',
      ...options,
    };

    return post(url, JSON.stringify(data), postOptions, V3_VERSION);
  },
  put(url, data = {}, options = {}) {
    return put(url, data, options, V3_VERSION);
  },
  destroy(url, data = {}, options = {}) {
    return destroy(url, data, options, V3_VERSION);
  },
};

export default {
  get,
  post,
  put,
  destroy,
  V3,
};
