import React from 'react';
import BumpButton from './bump_button';
import { CoreBumpRecommendationListingCard } from '@reverbdotcom/commons/src/gql/graphql';
import { BumpStats } from './bump_stats';
import { BumpExplainer } from './bump_explainer';

interface Props {
  bid: number;
  listing: CoreBumpRecommendationListingCard.Fragment;
  showModal: boolean;
  // props for BumpStats
  impressions: number;
  salesCount: number;
  salesSpent: string;
  salesRevenue: string;
  refetch: (newBid: number | null) => void;
}

export function ProductPageBumpV2(props: Props) {
  return (
    <div className="g-container d-flex fx-align-center fx-wrap">
      <div className="g-col-12">
        {!props.bid && <BumpExplainer />}
        {!!props.bid && <BumpStats
          impressions={props.impressions}
          salesCount={props.salesCount}
          salesSpent={props.salesSpent}
          salesRevenue={props.salesRevenue}
          hasInventory={props.listing.hasInventory}
        />}
      </div>
      <div className="g-col align-center fx-grow ptb-4">
        <BumpButton
          listingId={props.listing.id}
          showModal={props.showModal}
          bid={props.bid}
          refetch={props.refetch}
          buttonClassName="button button--primary"
        />
      </div>
    </div>
  );
}
