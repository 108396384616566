import {
  type reverb_search_Filter,
  type reverb_search_FilterOption,
  reverb_search_Filter_WidgetType as WidgetType,
} from '../gql/graphql';
import { ToTarget } from '../link_parser';

export { WidgetType };

/** Common interface for all components that render a Filter */
interface ExtraFilterAttributes {
  directLinkOverride?: ToTarget;
}

export type FilterOption = reverb_search_FilterOption & ExtraFilterAttributes;
export interface Filter extends reverb_search_Filter {
  options?: (FilterOption | null)[] | null;
}
export interface FilterProps {
  filter: Filter;
  /**
   * The same filter can be rendered in multiple places on a page.
   * pass a different 'section' value for each part of the page so we can generate unique IDs
   */
  pageSection: string;
  trackingQuery?: string;
  collapsible?: boolean;
  defaultOpen?: boolean;
  onToggleCallback?: (closed: boolean) => void;
}

interface ITextFilterOptions {
  paramName: string;
  paramValue: string;
  name?: string;
  widgetType?: WidgetType;
}
export function textFilter(options: ITextFilterOptions): Filter {
  const { paramName, paramValue, name, widgetType } = options;

  const hasValue = !!paramValue;

  return {
    name,
    widgetType: widgetType || WidgetType.TEXT,
    options: [
      {
        paramName,
        name: `"${paramValue || ''}"`,
        selected: hasValue,
        setValues: [paramValue],
        all: false,
      },
    ],
  };
}

/**
 * Extracts an id that's guaranteed to be unique across all filters.
 */
export function filterID(filter: Filter) {
  // Some filters have options with different param names, but no two filters should cover the same paramName
  return filter.options[0].paramName;
}

export function getRangeValue(filter: Filter, side: 'min' | 'max') {
  const sideIdx = side === 'min' ? 0 : 1;
  const param = filter.options[sideIdx];

  return {
    key: param.paramName,
    value: param.setValues[0],
  };
}

export function getRangeUnsetParams(filter: Filter) {
  const minSide = filter.options[0];
  const maxSide = filter.options[1];

  return {
    [minSide.paramName]: minSide.unsetValues,
    [maxSide.paramName]: maxSide.unsetValues,
  };
}

const DEFAULT_OPEN_SIDEBAR_FACET_KEYS = [
  'CATEGORY_SLUGS',
  'TEXT_QUERY',
  'FIND_A_DEAL',
  'LOCATION',
  'PRICE',
  'BRAND_SLUGS',
  'CONDITION_SLUGS',
  'SELLER_LOCATION',
];

export function isFacetOpenByDefault(filter: Filter) {
  return DEFAULT_OPEN_SIDEBAR_FACET_KEYS.includes(filter.key || filter.aggregationName);
}
