import React from 'react';
import { WithRouterProps } from 'react-router';

interface ScrollProps {
  location: Partial<Location>;
}

// This component will enhance all the routes rendered with scroll to top behavior on landing. It is
// simply a hook that will scroll to the top of the page if the pathname changes, and lets the browser
// preserve page location if the user uses the back or forward button to navigate.
export function ScrollToTop(props: ScrollProps) {
  React.useEffect(() => {
    // If an anchor tag is present, do not scroll to the top
    if (!props.location.hash) {
      window.scrollTo(0, 0);
    }
  }, [props.location.pathname, props.location.hash]);

  return null;
}

interface RootRouteProps {
  children: React.ReactNode;
  routeParams: {
    region: string;
  }
}

class RootRoute extends React.Component<RootRouteProps & WithRouterProps, null> {
  childRoutes() {
    return React.Children.map(this.props.children, (child) =>

      // eslint-disable-next-line
      // @ts-ignore
      React.cloneElement(child, { region: this.props.routeParams.region }),
    );
  }

  render() {
    return (
      <div>
        <ScrollToTop location={this.props.location} />
        {this.childRoutes()}
      </div>
    );
  }
}

export default RootRoute;
