import React, { useContext, useState } from 'react';
import moment from 'moment/moment';
import I18n from 'i18n-js';

import { parseAmount, parseAmountCents } from '@reverbdotcom/commons/src/money';
import SanitizedRender from '@reverbdotcom/commons/src/components/sanitized_render';
import { MoneyInput } from '@reverbdotcom/commons/src/components/money_input';

import { dateFromToday, formatDate } from '../../../../lib/date_utils';
import { ActionType, Field } from '../sellFormReducerTypes';
import { RCSwitch } from '@reverbdotcom/cadence/components';
import { SellFormContext } from '../SellFormContext';
import { LISTING_STATES } from '@reverbdotcom/commons/src/constants';

export function getScheduledPriceDropDate(existingListing) {
  if (existingListing?.state == LISTING_STATES.LIVE && existingListing?.scheduledPriceDrops?.length) {
    return moment(existingListing.scheduledPriceDrops[0].dropAt);
  } else if (existingListing?.publishedAt) {
    const publishedAt = moment.unix(existingListing.publishedAt.seconds);
    return publishedAt.add(14, 'd');
  } else {
    return dateFromToday(14, 'future');
  }
}

function PriceDropPercentage({ priceDropPrice, listingPrice, currency }) {
  if (!priceDropPrice || !listingPrice) return null;

  const parsedPriceDropPrice = parseAmount(priceDropPrice, currency);
  const parsedListingPrice = parseAmount(listingPrice, currency);
  const percent = Math.round((1 - (parsedPriceDropPrice.amountCents / parsedListingPrice.amountCents)) * 100);

  if (percent > 0) {
    return (
      <>
        <strong>
          {I18n.t(`discovery.sellForm.fields.${Field.SCHEDULED_PRICE_DROPS}.percentDropText`, { percent: percent })}
        </strong>
        {percent < 10 && (
          <p>
            {I18n.t(`discovery.sellForm.fields.${Field.SCHEDULED_PRICE_DROPS}.dropTooLowText`)}
          </p>
        )}
      </>
    );
  }
  return null;
}

export function SellFormScheduledPriceDrop() {
  const {
    listingEntry,
    dispatch,
    loading,
    errorMap,
    listingState,
    sellFormViewData,
    shopConfig,
  } = useContext(SellFormContext);
  const {
    currencySetting: currency,
  } = shopConfig;
  const [priceDropToggleTouched, setPriceDropToggleTouched] = useState(false);

  const fetchedListing = sellFormViewData?.fetchedListing;
  const dropAt = getScheduledPriceDropDate(fetchedListing);
  const dropAtIsInThePast = dropAt < moment.utc();
  if (dropAtIsInThePast) {
    return null;
  }

  const [priceDropEntry] = listingEntry[Field.SCHEDULED_PRICE_DROPS];
  const active = priceDropEntry?.[Field.SCHEDULED_PRICE_DROP_ACTIVE] || false;
  const newPrice = priceDropEntry?.[Field.SCHEDULED_PRICE_DROP_NEW_PRICE]?.amount || '';

  function handlePriceDropToggle() {
    // If the seller enables scheduled price drops and there's a listing price present but no price drop
    // price present, default to 90% of the listing price. This behavior only occurs the first time they flip
    // the switch from off to on.
    if (!priceDropToggleTouched && !active && listingEntry[Field.PRICE]?.amount && !newPrice) {
      const parsedListingPrice = parseAmount(listingEntry[Field.PRICE].amount, currency);
      const defaultNewPrice = parseAmountCents(parsedListingPrice.amountCents * 0.9, currency);
      dispatch({
        type: ActionType.SET_SCHEDULED_PRICE_DROP_NEW_PRICE,
        fieldKey: Field.SCHEDULED_PRICE_DROP_NEW_PRICE,
        value: { amount: defaultNewPrice.amount },
      });
    }

    dispatch({
      type: ActionType.SET_SCHEDULED_PRICE_DROP_ACTIVE,
      fieldKey: Field.SCHEDULED_PRICE_DROP_ACTIVE,
      value: !active,
    });

    setPriceDropToggleTouched(true);
  }

  let switchLabel;
  let switchSublabel;
  if (listingState != 'live') {
    switchLabel = I18n.t(`discovery.sellForm.fields.${Field.SCHEDULED_PRICE_DROPS}.draftCheckboxLabel`);
    switchSublabel = I18n.t(`discovery.sellForm.fields.${Field.SCHEDULED_PRICE_DROPS}.draftCheckboxSublabel`);
  } else if (active) {
    switchLabel = I18n.t(`discovery.sellForm.fields.${Field.SCHEDULED_PRICE_DROPS}.liveCheckboxLabel`);
    switchSublabel = I18n.t(`discovery.sellForm.fields.${Field.SCHEDULED_PRICE_DROPS}.liveCheckboxSublabel`);
  } else {
    switchLabel = I18n.t(`discovery.sellForm.fields.${Field.SCHEDULED_PRICE_DROPS}.liveCheckboxDisabledLabel`);
    switchSublabel = I18n.t(`discovery.sellForm.fields.${Field.SCHEDULED_PRICE_DROPS}.liveCheckboxSublabel`);
  }

  return (
    <>
      <div className="mt-6">
        <RCSwitch
          name={`${Field.SCHEDULED_PRICE_DROPS}-${Field.SCHEDULED_PRICE_DROP_ACTIVE}`}
          id={`${Field.SCHEDULED_PRICE_DROPS}-${Field.SCHEDULED_PRICE_DROP_ACTIVE}`}
          label={switchLabel}
          sublabel={switchSublabel}
          checked={active}
          onChange={handlePriceDropToggle}
          disabled={loading}
        />
        {active && (
          <div className="sell-form-pricing-section__active-scheduled-price-drop-container">
            <p>
              <SanitizedRender html={I18n.t(`discovery.sellForm.fields.${Field.SCHEDULED_PRICE_DROPS}.dropDateText`, { date: formatDate(dropAt, 'LL') })}/>
            </p>
            <MoneyInput
              id={`${Field.SCHEDULED_PRICE_DROPS}-${Field.SCHEDULED_PRICE_DROP_NEW_PRICE}`}
              name={`${Field.SCHEDULED_PRICE_DROPS}-${Field.SCHEDULED_PRICE_DROP_NEW_PRICE}`}
              label={I18n.t(`discovery.sellForm.fields.${Field.SCHEDULED_PRICE_DROPS}.priceLabel`)}
              value={newPrice}
              onChange={(event) => dispatch({
                type: ActionType.SET_SCHEDULED_PRICE_DROP_NEW_PRICE,
                fieldKey: Field.SCHEDULED_PRICE_DROP_NEW_PRICE,
                value: { amount: event.target.value },
              })}
              disabled={loading || !active}
              currencyCode={currency}
              required={active}
              errorText={errorMap[Field.SCHEDULED_PRICE_DROPS]}
            />
            <PriceDropPercentage
              priceDropPrice={newPrice}
              listingPrice={listingEntry[Field.PRICE]?.amount}
              currency={currency}
            />
          </div>
        )}
      </div>
      <hr className="mt-4"/>
    </>
  );
}
