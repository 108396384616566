import { useEffect } from 'react';
import { CORE_WEB_ORIGIN } from '@reverbdotcom/env';

export function useNativeMessageListener(callback: (MessageEvent) => void) {
  useEffect(() => {
    function handleEvent(event) {
      // only allow messages originating from within the reverb.com origin (i.e. mobile webviews)
      if (!event.origin?.includes(CORE_WEB_ORIGIN)) { return; }

      callback(event);
    }

    window.addEventListener('message', handleEvent, false);

    return () => {
      window.removeEventListener('message', handleEvent);
    };
  }, [callback]);
}
