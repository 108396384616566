import React, { useState } from 'react';
import I18n from 'i18n-js';
import { times } from 'lodash';
import { MarketplacePathContext } from './shared/url_helpers';
import { RCAlertBox } from '@reverbdotcom/cadence/components';
import SubqueryRow from './subquery_row';
import { Location } from 'history';

interface RouterLocationProps {
  location: Location;
  params: MarketplacePathContext;
  listingsSearchCount: number;
}

interface IProps extends RouterLocationProps {
  query: string;
}

export function SubqueryRows(props: IProps) {
  const [anyDisplayedRows, setAnyDisplayedRows] = useState(false);

  if (!props.query) return null;

  const words = props.query.split(' ');
  if (words.length < 3) { return null; }

  const subqueries = [];

  if (words.length === 3) {
    words.pop();
    subqueries.push(words.join(' '));
  } else {
    times(2, (_idx) => {
      words.pop();
      subqueries.push(words.join(' '));
    });
  }

  return (
    <>
      {props.listingsSearchCount === 0 ? (
        anyDisplayedRows &&
          <div className="size-140 mobile-size-120 mb-6 mt-10">
            {I18n.t('discovery.noResults.tryOtherSearches')}
          </div>
      ) : (
        anyDisplayedRows &&
          <div className="mt-6 mb-6">
            <RCAlertBox type="info">
              {I18n.t('discovery.noResults.tryOtherSearches')}
            </RCAlertBox>
          </div>
      )}
      {subqueries.map(subquery => {
        return (
          <SubqueryRow
            key={subquery}
            subquery={subquery}
            location={props.location}
            params={props.params}
            setAnyDisplayedRows={setAnyDisplayedRows}
            listingsSearchCount={props.listingsSearchCount}
          />
        );
      })}
    </>
  );
}

export default SubqueryRows;
