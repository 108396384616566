/* eslint-disable no-restricted-imports */
import { graphql, DataProps, MutateProps, OperationOption } from '@apollo/client/react/hoc';
/* eslint-enable no-restricted-imports */
import { BaseQueryOptions, OperationVariables } from '@apollo/client';
import { DocumentNode } from 'graphql';
import { cloneDeep } from 'lodash';
import { normalizeVariables } from './apollo_client/safeQueryOptions';

/**
 * @deprecated Use `import { useQuery } from '@reverbdotcom/commons/src/useQuery';` instead.
 *
 * See also: [Apollo Client usage guide - migrating to hooks](https://reverb.atlassian.net/wiki/spaces/ENG/pages/1877377087/Apollo+Client+3+Usage+Guide#Migrating-to-Hooks).
 *
 * This HOC exists as a wrapper around the `graphql` HOC provided by react-apollo so that we can
 * set our own default options across all queries.
 * All these types look complicated, but they are the same generics specified for the `graphql` HOC
 * and they are just passed through this function.
 */
export function withGraphql<
  TProps extends TGraphQLVariables | {} = {},
  TData extends object = {},
  TGraphQLVariables extends OperationVariables = {},
  TChildProps extends object = Partial<DataProps<TData, TGraphQLVariables>> & Partial<MutateProps<TData, TGraphQLVariables>>,
>(
  document: DocumentNode,
  operationOptions: OperationOption<
    TProps,
    TData,
    TGraphQLVariables,
    TChildProps
  > = {},
): (
    WrappedComponent: React.ComponentType<TProps & TChildProps>,
  ) => React.ComponentClass<TProps> {
  // Creates a deep copy of operationOptions so we can avoid mutating the original object, preventing
  // infinite recursion.
  const optionsWithDefaults = cloneDeep(operationOptions) || {};

  // props isn't accessible in this context, so instead we set `options` to be a function that will
  // be evaluated later when Apollo does have access to props.
  optionsWithDefaults.options = (props: TProps) => {
    let safeOptions = {} as BaseQueryOptions<TGraphQLVariables>;

    if (typeof operationOptions !== 'undefined') {
      if (typeof operationOptions.options === 'object') {
        safeOptions = operationOptions.options;
      }

      if (typeof operationOptions.options === 'function') {
        safeOptions = operationOptions.options(props);
      }
    }

    if (!Object.prototype.hasOwnProperty.call(safeOptions, 'ssr')) {
      safeOptions.ssr = false;
    }

    const shouldNormalizeVariables = safeOptions.ssr && safeOptions.variables;
    if (shouldNormalizeVariables) {
      safeOptions.variables = normalizeVariables<TGraphQLVariables>(safeOptions.variables);
    }

    return safeOptions;
  };

  return graphql(document, optionsWithDefaults);
}
