import React from 'react';

import { I18N } from '@reverbdotcom/commons/src/components/translate';
import { useHelpCenterArticleUrl } from '@reverbdotcom/commons/src/url_helpers';

export const VAT_SETUP_HELP_CENTER_ID = '215774918';

export default function SellFormIncVatPricingHint() {

  const vatSetupHelpCenterUrl = useHelpCenterArticleUrl(VAT_SETUP_HELP_CENTER_ID);

  return (
    <div className="size-90 color-secondary mb-2">
      <I18N
        html
        text="discovery.sellForm.vatInclusiveListingPrice.helpTextHtml"
        args={{
          linkOpen: `<a href=${vatSetupHelpCenterUrl}>`,
          linkClose: '</a>',
        }}
      />
    </div>
  );
}
