import React from 'react';
import I18n from 'i18n-js';
import { buttonClassName, RCAlertBox } from '@reverbdotcom/cadence/components';
import SignupSigninModal from '@reverbdotcom/commons/src/components/signup_signin_modal';
import CoreLink from '@reverbdotcom/commons/src/components/core_link';

interface Props {
  active: boolean;
  onRequestClose: () => any;
  redirectTo: string;
  trackSource?: 'Cart' | 'BuyItNowButton'
}

export function CheckoutGuestAuthModal({
  active,
  onRequestClose,
  redirectTo,
  trackSource = null,
}: Props) {
  return (
    <SignupSigninModal
      messageComponent={
        <SigninHeader redirectTo={redirectTo} />
      }
      active={active}
      redirectTo={redirectTo}
      showSignInOnOpen
      trackSource={trackSource}
      onRequestClose={onRequestClose}
      variant="continue"
      prioritizeOauth
    />
  );
}

function SigninHeader({ redirectTo }: {
  redirectTo: string,
}) {
  return (
    <div className="mb-6">
      <RCAlertBox type="info">
        <div className="pb-4 weight-bold align-center size-130">
          {I18n.t('discovery.checkout.cart.guestUserAuthModalText')}
        </div>
        <CoreLink
          interactionType="CTA"
          to={redirectTo}
          className={buttonClassName({ variant: 'filled', fullWidth: true })}
        >
          {I18n.t('discovery.checkout.cart.guestUserAuthModalButtonText')}
        </CoreLink>
      </RCAlertBox>
    </div>
  );
}
