import React from 'react';

import { RCLoadingBars } from '@reverbdotcom/cadence/components';
import { ILegacyApiShippingRegion } from '@reverbdotcom/commons/src/shipping/shipping_region_selector';

import { V3 } from '../api_request';
import UrlHelpers from '../shared/url_helpers';
import ShippingProfileForm from './shipping_profile_form';
import { IShippingRate } from '../shared/edit_shipping_rate_card_manual_prices';

export interface IProps {
  currency: string;
  shippingRates: IShippingRate[];
  allowedToShipExpedited?: boolean;
  allowedToSetRegionalShipping?: boolean;
}

export function ShippingProfileFormContainer({
  currency,
  shippingRates,
  allowedToShipExpedited = false,
  allowedToSetRegionalShipping = false,
}: IProps) {
  const [areShippingRegionsLoaded, setAreShippingRegionsLoaded] = React.useState(false);
  const [shippingRegions, setShippingRegions] = React.useState<ILegacyApiShippingRegion[]>([]);

  React.useEffect(() => {
    V3.get(UrlHelpers.shippingRegionsPath).done((response) => {
      setShippingRegions(response.shipping_regions);
      setAreShippingRegionsLoaded(true);
    });
  }, [setShippingRegions]);

  if (!areShippingRegionsLoaded) {
    return (
      <div className="scaling-padding-4 d-flex fx-justify-center bg-white">
        <RCLoadingBars size="large" />
      </div>
    );
  }

  return (
    <ShippingProfileForm
      currency={currency}
      shippingRates={shippingRates}
      allowedToShipExpedited={allowedToShipExpedited}
      allowedToSetRegionalShipping={allowedToSetRegionalShipping}
      shippingRegionOptions={shippingRegions}
    />
  );
}

export default ShippingProfileFormContainer;
