/**
 *  DEPRECATED – Use [`useMediaQuery`](https://github.com/reverbdotcom/frontend/blob/main/packages/cadence/components/hooks/useMediaQuery.tsx) from Cadence
 *
 *  https://coderwall.com/p/i817wa/one-line-function-to-detect-mobile-devices-with-javascript
 */
const isMobile =
  (typeof window.orientation !== 'undefined') ||
  (navigator.userAgent.indexOf('IEMobile') !== -1);

export default isMobile;
