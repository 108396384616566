import '../../vendor/readmore.min';

window.Reverb = window.Reverb || {};

Reverb.ReadMore = (function () {
  function addReadMore(selector, height) {
    $(selector).readmore({
      speed: 300,
      moreLink: `<a href="#">${I18n.t('js.readMore.readMore')}</a>`,
      lessLink: `<a href="#">${I18n.t('js.readMore.readLess')}</a>`,
      maxHeight: height,
      expandedClass: 'active',
    });
  }

  function init() {
    addReadMore('.feedback-readmore', 180);
    addReadMore('.feedback-response-readmore', 115);
    addReadMore('.previous-listing-description', 60);
    addReadMore('.listing-description-readmore', 500);
    addReadMore('.listing-description-short-readmore', 100);
  }

  return {
    init,
    addReadMore,
  };
}());

$(Reverb.ReadMore.init);
