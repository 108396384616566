/* eslint-disable no-restricted-imports */
import {
  ApolloCache,
  DefaultContext,
  DocumentNode,
  FetchResult,
  MutationHookOptions,
  NoInfer,
  OperationVariables,
  TypedDocumentNode,
  useMutation as useApolloMutation,
  MutationResult as ApolloMutationResult,
  MutationOptions,
  ApolloClient,
  BaseMutationOptions,
  ApolloError,
  MutationFunctionOptions,
} from '@apollo/client';
/* eslint-enable no-restricted-imports */
import { core_apimessages_Error } from './gql/graphql';
import { parseMutationErrors } from './parse_graphql_errors';
import { omit } from 'lodash';

export interface RQLBaseMutationOptions<
  TData = any,
  TVariables = OperationVariables,
  TContext = DefaultContext,
  TCache extends ApolloCache<any> = ApolloCache<any>,
> extends Omit<MutationOptions<TData, TVariables, TContext, TCache>, 'mutation'> {
  client?: ApolloClient<object>;
  notifyOnNetworkStatusChange?: boolean;
  onCompleted?: (data: TData, clientOptions?: BaseMutationOptions) => void;
  onError?: (errors: core_apimessages_Error[], clientOptions?: BaseMutationOptions) => void;
  ignoreResults?: boolean;
}

export interface RQLMutationFunctionOptions<
  TData = any,
  TVariables = OperationVariables,
  TContext = DefaultContext,
  TCache extends ApolloCache<any> = ApolloCache<any>,
> extends RQLBaseMutationOptions<TData, TVariables, TContext, TCache> {
  mutation?: DocumentNode | TypedDocumentNode<TData, TVariables>;
}

export type RQLMutationTuple<TData, TVariables, TContext = DefaultContext, TCache extends ApolloCache<any> = ApolloCache<any>> = [
  (options?: MutationFunctionOptions<TData, TVariables, TContext, TCache>) => Promise<FetchResult<TData>>,
  RQLMutationResult<TData>,
];

type RQLMutationResult<TData = any> = Omit<ApolloMutationResult<TData>, 'error'> & {
  /** Formatted errors returned via RQL */
  errors: core_apimessages_Error[];
};

/**
 * Ensures that both data and error.graphQLErrors are populated,
 * enabling the mutation to retrieve both partial results and error information.
 */
const DEFAULT_ERROR_POLICY = 'all' as const;

/**
 * Wraps the [Apollo useMutation](https://www.apollographql.com/docs/react/data/mutations) hook.
 *
 * Takes a gql tag document node and provides the result with parsed Core Errors if any.
 *
 * @example
 *
 * const [mutate, { data, loading, errors }] = useMutation(ADD_TO_CART_MUTATION);
 *
 * async function addToCart(e) {
 *   e.preventDefault();
 *
 *   try {
 *     await mutate({
 *       variables: { listingId: '1234' },
 *     });
 *   } catch(e) {
 *     elog.error('discovery.add_to_cart.failure', {}, e);
 *   }
 * }
 *
 * <button onClick={addToCart}>Add to Cart</button>
 */
export function useMutation<
  TData = any,
  TVariables = OperationVariables,
  TContext = DefaultContext,
  TCache extends ApolloCache<any> = ApolloCache<any>,
>(
  mutation: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options?: RQLBaseMutationOptions<NoInfer<TData>, NoInfer<TVariables>, TContext, TCache>,
): RQLMutationTuple<TData, TVariables, TContext, TCache> {
  const [mutate, result] = useApolloMutation(mutation, apolloOptions(options));

  return [mutate, decoratedResult(result)];
}

function apolloOptions<
  TData = any,
  TVariables = OperationVariables,
  TContext = DefaultContext,
  TCache extends ApolloCache<any> = ApolloCache<any>,
>(
  options: RQLBaseMutationOptions<TData, TVariables, TContext, TCache>,
): MutationHookOptions<TData, TVariables, TContext, TCache> {
  return {
    errorPolicy: DEFAULT_ERROR_POLICY,
    ...omit(options, 'onError'),
    ...rqlErrorHandler(options?.onError),
  };
}

function rqlErrorHandler(onError?: (errors: core_apimessages_Error[]) => void) {
  if (!onError) {
    return {};
  }

  return {
    onError: (error: ApolloError) => onError(parseMutationErrors(error)),
  };
}

function decoratedResult<TData = any>(result: ApolloMutationResult<TData>): RQLMutationResult<TData> {
  const errors = (result.error && parseMutationErrors(result.error)) || [];

  return {
    ...omit(result, 'error'),
    errors,
  };
}
