import React from 'react';
import I18n from 'i18n-js';

import { gql } from '@reverbdotcom/commons/src/gql';
import { useMutation } from '@reverbdotcom/commons/src/useMutation';
import { ModalDialog } from '@reverbdotcom/commons';
import { I18N as Translate } from '@reverbdotcom/commons/src/components/translate';

import SellerListingCardsToolbarContext from '../../SellerListingCardsToolbarContext';
import { MParticleEventName, trackEvent } from '@reverbdotcom/commons/src/elog/mparticle_tracker';
import { useViewTracking } from '@reverbdotcom/commons/src/use_tracking';
import SellerListingsCollectionContext from '../../SellerListingsCollectionContext';

export const COMPONENT_NAME = 'BulkCreateAffirmModal';

const BULK_CREATE_AFFIRM_MUTATION = gql(`
  mutation Core_MyListings_BulkCreateAffirm(
    $input: Input_core_apimessages_BulkCreateZeroPercentAffirmRequest
  ) {
    bulkCreateZeroPercentAffirm(input: $input) {
      responses {
        listingId
        success
        errors {
          message
        }
      }
    }
  }
`);

const BULK_CREATE_AFFIRM_ASYNC_MUTATION = gql(`
  mutation Core_MyListings_BulkCreateAffirmAsync(
    $input: Input_core_apimessages_BulkCreateZeroPercentAffirmAsyncRequest
  ) {
    bulkCreateZeroPercentAffirmAsync(input: $input) {
      enqueued
      message
    }
  }
`);

function isEligibleForBulkAction(listing) {
  const { affirmZeroPercentFinancingConfiguration } = listing;

  const {
    isEnabled,
    isEligible,
  } = affirmZeroPercentFinancingConfiguration;

  return isEligible && !isEnabled;
}

export default function BulkCreateAffirmModal({
  closeModal,
  selectedListings,
}) {
  const {
    listingsCollectionState,
  } = React.useContext(SellerListingsCollectionContext);

  const {
    hasAllMatchesSelected,
    totalMatchesEligibleForAffirm,
    serializedDashboardSearchParams,
  } = listingsCollectionState;

  const {
    handleBulkResponses,
    handleGenericError,
    handleAsyncBulkResponse,
  } = React.useContext(SellerListingCardsToolbarContext);

  const [bulkCreateZeroPercentAffirm, {
    data = {},
    loading,
    errors,
  }] = useMutation(BULK_CREATE_AFFIRM_MUTATION);
  const hasErrors = !!errors.length;

  const [bulkCreateAffirmAsync, {
    data: asyncData = {},
    loading: asyncLoading,
    errors: asyncErrors,
  }] = useMutation(BULK_CREATE_AFFIRM_ASYNC_MUTATION);
  const hasAsyncErrors = !!asyncErrors.length;

  const eligibleListings = selectedListings.filter(listing => isEligibleForBulkAction(listing));

  const eligibleCount = hasAllMatchesSelected ? totalMatchesEligibleForAffirm : eligibleListings.length;

  const hasEligibleListings = !!eligibleCount;

  React.useEffect(() => {
    if (loading) return;
    if (hasErrors) return handleUnexpectedError();

    handleResponses();
  }, [loading]);

  React.useEffect(() => {
    if (asyncLoading) return;
    if (hasAsyncErrors) return handleUnexpectedError();

    handleAsyncResponse();
  }, [asyncLoading]);

  function handleResponses() {
    if (data?.bulkCreateZeroPercentAffirm) {
      const { responses } = data.bulkCreateZeroPercentAffirm;

      handleBulkResponses({
        responses,
        I18nSuccessMessageKey: 'discovery.listingsManagement.toolbar.bulkActions.affirm.createModal.successes',
        I18nErrorMessageKey: 'discovery.listingsManagement.toolbar.bulkActions.affirm.createModal.errors.title',
      });

      closeModal();
    }
  }

  function handleAsyncResponse() {
    if (asyncData?.bulkCreateZeroPercentAffirmAsync) {
      handleAsyncBulkResponse({
        I18nKey: 'discovery.listingsManagement.toolbar.bulkActions.affirm.createModal.asyncResponses.successes',
        expectedTotal: eligibleCount,
      });

      closeModal();
    }
  }

  function handleUnexpectedError() {
    handleGenericError();
    closeModal();
  }

  function submit() {
    trackEvent({
      componentName: COMPONENT_NAME,
      eventName: MParticleEventName.SellerBulkCreatingZeroPercentAffirm,
      listingsCount: eligibleCount,
      hasAllMatchesSelected,
    });

    if (hasAllMatchesSelected) {
      bulkCreateAffirmAsync({
        variables: {
          input: {
            serializedDashboardSearchParams,
            expectedTotal: eligibleCount,
          },
        },
      });
    } else {
      const listingIds = eligibleListings.map(listing => listing.id);

      bulkCreateZeroPercentAffirm({
        variables: {
          input: {
            listingIds,
          },
        },
      });
    }
  }

  useViewTracking({
    componentName: COMPONENT_NAME,
    eventName: MParticleEventName.ComponentView,
  }, true);

  return (
    <ModalDialog
      isOpen
      onRequestClose={closeModal}
      headerTitle={I18n.t('discovery.listingsManagement.toolbar.bulkActions.affirm.createModal.headerTitle')}
      saveButtonText={I18n.t('discovery.listingsManagement.toolbar.bulkActions.affirm.createModal.submit')}
      isDisabled={!hasEligibleListings}
      isSaving={loading || asyncLoading}
      onSubmit={submit}
    >
      <div className="d-flex fx-dir-col gap-4">
        <div>
          {I18n.t('discovery.listingsManagement.toolbar.bulkActions.affirm.createModal.description')}
        </div>

        <Translate
          html
          text="discovery.listingsManagement.toolbar.bulkActions.affirm.createModal.actions"
          args={{
            count: eligibleCount,
            formattedCount: I18n.toNumber(eligibleCount, {
              precision: 0,
            }),
          }}
        />

        <div>
          {I18n.t('discovery.listingsManagement.toolbar.bulkActions.affirm.createModal.note')}
        </div>
      </div>
    </ModalDialog>
  );
}
