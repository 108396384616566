import React from 'react';
import {
  core_apimessages_Country as Country,
  core_apimessages_TaxIdentification as TaxIdentification,
} from '@reverbdotcom/commons/src/gql/graphql';
import { I18N as Translate } from '@reverbdotcom/commons/src/components/translate';
import FormGroup from '@reverbdotcom/commons/src/components/form_group';
import { ISelectOption } from '@reverbdotcom/commons/src/components/form_group_with_select';
import ShopSettingsUpdateVatIdForm from './shop_settings_update_vat_id_form';
import { filterUsedCountries } from './shop_settings_vat_layout';

export interface Props {
  taxIdentifications: TaxIdentification[];
  countries: Country[];
}

export default function ShopSettingsVatIds({ taxIdentifications, countries }: Props) {
  const usedCountries = filterUsedCountries(taxIdentifications);

  return (
    <ul className="actionable-rows mb-2">
      {
        taxIdentifications.map(taxIdentification => (
          <ShopSettingsVatId
            taxIdentification={taxIdentification}
            countries={countries}
            usedCountries={usedCountries}
            key={taxIdentification.id}
          />
        ))
      }
    </ul>
  );
}

interface VatIdProps {
  taxIdentification: TaxIdentification;
  countries: Country[];
  usedCountries: string[];
}

export function ShopSettingsVatId({ taxIdentification, countries, usedCountries }: VatIdProps) {
  const { countryCode, taxId, status } = taxIdentification;

  const countryOptions = createCountryOptions(taxIdentification, countries, usedCountries);

  const countryName = countryOptions.find(country => country.value === countryCode).text;
  const statusKey = status.toLocaleLowerCase();

  const [isOpen, setIsOpen] = React.useState(false);

  function openForm() {
    setIsOpen(true);
  }

  function closeForm() {
    setIsOpen(false);
  }

  return (
    <li
      className="actionable-row"
      key={taxId}
      id={taxId}
    >
      <div className="actionable-row__content">
        <div className="g-container">
          <div className="g-col-4 g-col-mobile-12">
            <FormGroup
              label="VAT Identification Number">
              <code className="code-snippet">
                {taxId}
              </code>
            </FormGroup>
          </div>
          <div className="g-col-4 g-col-mobile-12 mobile-mt-4">
            <FormGroup label="Country">
              {countryName}
            </FormGroup>
          </div>
          <div className="g-col-4 g-col-mobile-12 mobile-mt-4">
            <FormGroup label="Status">
              <Translate text={`discovery.shopSettings.vat.statuses.${statusKey}`} />
            </FormGroup>
          </div>
        </div>
      </div>
      <ul className="actionable-row__actions">
        <button
          className="button-as-link icon-l-pencil"
          type="button"
          onClick={openForm}
        >
          <Translate text="discovery.shopSettings.vat.edit" />
          <ShopSettingsUpdateVatIdForm
            taxIdentification={taxIdentification}
            countryOptions={countryOptions}
            isOpen={isOpen}
            closeForm={closeForm}
          />
        </button>
      </ul>
    </li>
  );
}

export function createCountryOptions(taxIdentification: TaxIdentification, countries: Country[], usedCountries: string[]): ISelectOption[] {
  return countries.map(country => {
    const countryIsUsed = usedCountries.includes(country.countryCode);
    const notCurrentTaxIdentificationCountry = country.countryCode !== taxIdentification.countryCode;

    const value = country.countryCode;
    const text = country.name;
    const disabled = countryIsUsed && notCurrentTaxIdentificationCountry;

    return ({
      value,
      text,
      disabled,
    });
  });
}
