import React from 'react';
import I18n from 'i18n-js';
import bind from '../bind';
import Modal, { Size } from './modal';
import CsrfForm from '../csrf_form';
import classNames from 'classnames';

interface IProps {
  /** ID gets passed down to Modal */
  id?: string;

  /** Toggle the modal visibility via isOpen */
  isOpen: boolean;

  /** A brief string describing the contents or main user action of the modal */
  headerTitle: string;

  /** Fires when the modal's "Submit" button is pressed */
  onSubmit?: Function;

  /** Fires when the close button is pressed, or an outside click happens. Also is fired with the "cancel" button
   * is present, unless that button has been overridden via `onNavigateBack` being present.
   */
  onRequestClose?: Function;

  /** Deprecated, don't use - removing soon */
  headerSubtitle?: string;

  /** Customize the text of the primary footer action */
  saveButtonText?: string;

  /** Customize the text in the "cancel" button in the modal footer */
  backButtonText?: string;

  /** When present, the modal displays a red "delete" action in the footer */
  onDelete?: Function | undefined;

  /** Customize the delete button label */
  deleteButtonText?: string;

  /** When using a routed modal, this pivots the footer "cancel" action to be a "back" action */
  onNavigateBack?: Function;

  /** Puts the modal is a "loading" type state and pivots some button labels and UIs temporarily */
  isSaving?: boolean;

  /** Disables the submit button. Avoid using this unless you have good reason to do so as it
   * can cause confusion and result in poor UX.
   */
  isDisabled?: boolean;

  /** When `true`, the footer "cancel or go back" button will be hidden */
  hideFooterDismissButton?: boolean;

  /** When present this renders a black sidebar (on desktop) that can show secondary/related content */
  sidebarContent?: JSX.Element | React.ReactNode;

  /** Various size options for the modal. When in doubt, use the default modal size and only
   * change size to match content when necessary */
  size?: Size;

  /** This appends the form's ID to the submit button */
  formId?: string;

  /** Returns in interior `<CsrfForm>` if `true`, otherwise a native `<form>` element */
  useCsrfForm?: boolean;

  /** The main `action` used in both `<CsrfForm>` and `<form>` elements. Defaults to `#` when not present */
  formAction?: string;

  /** if the contents passed to ModalDialog already include a form element, set this prop to `true`
   * and ModalDialog won't build its own `<form>` */
  containsFormElement?: boolean;

  /** Adds top margin in mobile viewports. Avoid using for "tall" content-heavy modals. */
  mobileTopMargin?: boolean;
}

export default class ModalDialog extends React.Component<IProps, any> {
  @bind
  handleOnNavigateBack(e) {
    e.preventDefault();
    this.props.onNavigateBack();
  }

  @bind
  handleSubmit(e) {
    if (!this.props.onSubmit) return true;
    e.preventDefault();
    e.stopPropagation();
    this.props.onSubmit();
  }

  @bind
  handleClose(e) {
    e.preventDefault();
    this.props.onRequestClose();
  }

  @bind
  handleDelete(e) {
    e.preventDefault();
    this.props.onDelete();
  }

  @bind
  handleCloseOrBack(e) {
    if (this.props.onNavigateBack) {
      this.handleOnNavigateBack(e);
    } else {
      this.handleClose(e);
    }
  }

  get saveButtonText() {
    if (this.props.isSaving) {
      return I18n.t('commons.modalDialog.wait');
    }
    return this.props.saveButtonText || I18n.t('commons.modalDialog.save');
  }

  renderDelete() {
    if (!this.props.onDelete) return null;

    return (
      <button
        className="reverb-modal__actions__delete"
        onClick={this.handleDelete}
        type="button"
      >
        {this.props.deleteButtonText || I18n.t('commons.modalDialog.delete')}
      </button>
    );
  }

  get buttonFormIdProp() {
    if (!this.props.formId) { return {}; }

    return { form: this.props.formId };
  }

  get saveButtonProps() {
    const semanticHtmlProps = {
      ...this.buttonFormIdProp,
      className: 'reverb-modal__actions__save',
      type: 'submit',
      disabled: (this.props.isSaving || this.props.isDisabled),
    } as React.ButtonHTMLAttributes<HTMLButtonElement>;

    if (this.props.containsFormElement) {
      return { ...semanticHtmlProps, onClick: this.handleSubmit };
    }
    return semanticHtmlProps;
  }

  get backOrCancelButtonText() {
    if (this.props.backButtonText) return this.props.backButtonText;
    if (this.props.onNavigateBack) return I18n.t('commons.modalDialog.goBack');

    return I18n.t('commons.modalDialog.cancel');
  }

  renderActions() {
    return (
      <div className="reverb-modal__actions">
        <button {...this.saveButtonProps}>
          {this.saveButtonText}
        </button>
        {!this.props.hideFooterDismissButton &&
          <button
            className={classNames({
              'reverb-modal__actions__back': !this.handleOnNavigateBack,
              'reverb-modal__actions__close-or-back': this.handleOnNavigateBack,
            })}
            onClick={this.handleCloseOrBack}
            type="button"
          >
            {this.backOrCancelButtonText}
          </button>
        }
        {this.renderDelete()}
      </div>
    );
  }

  renderModalContents() {
    return (
      <>
        <div className="reverb-modal__dialog-body">
          {this.props.children}
        </div>
        {this.renderActions()}
      </>
    );
  }

  renderFormOrDiv() {
    if (this.props.containsFormElement) {
      return this.renderModalContents();
    }

    if (this.props.useCsrfForm) {
      return (
        <CsrfForm
          action={this.props.formAction || '#'}
          onSubmit={this.handleSubmit}
          method="post"
          className="reverb-modal__form"
        >
          {this.renderModalContents()}
        </CsrfForm>
      );
    }

    return (
      <form
        action={this.props.formAction || '#'}
        onSubmit={this.handleSubmit}
        className="reverb-modal__form"
      >
        {this.renderModalContents()}
      </form>
    );
  }

  render() {
    return (
      <Modal
        id={this.props.id}
        isOpen={this.props.isOpen}
        onRequestClose={this.props.onRequestClose}
        headerTitle={this.props.headerTitle}
        shouldCloseOnOverlayClick
        sidebarContent={this.props.sidebarContent}
        size={this.props.size}
        mobileTopMargin={this.props.mobileTopMargin}
        subClass="modal-dialog"
      >
        {this.renderFormOrDiv()}
      </Modal>
    );
  }
}
