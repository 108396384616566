import { core_apimessages_ShippingLabelPackage } from '@reverbdotcom/commons/src/gql/graphql';

import { CarrierCalculatedPackageValidatorResponse } from './carrierCalculatedPackageValidator';

const USPS_DIVISOR = 166;
const USPS_CUBIC_VOLUME_LOWER_LIMIT_INCHES = 1728; // the USPS-defined threshold where dimensional weight consideration begins
export const USPS_CARRIER_CALC_MAX_LENGTH_INCHES = 22;
export const USPS_CARRIER_CALC_MAX_WEIGHT_POUNDS = 10;
export const USPS_CARRIER_CALC_MAX_CUBIC_VOLUME_INCHES = 1660; // the reverb-defined limit for carrier-calc eligiblity (dimensional weight of 10lb)

export function carrierCalculatedUspsPackageValidator(
  packageEntry: core_apimessages_ShippingLabelPackage,
): CarrierCalculatedPackageValidatorResponse {
  const { length, width, height, weight } = packageEntry;
  const lengthInches = Number(length?.value);
  const widthInches = Number(width?.value);
  const heightInches = Number(height?.value);
  const weightPounds = Number(weight?.value);

  const volume_cubic_inches = lengthInches * widthInches * heightInches;

  let dimensionalWeightIfApplicable;
  if (volume_cubic_inches > USPS_CUBIC_VOLUME_LOWER_LIMIT_INCHES) {
    dimensionalWeightIfApplicable = Math.ceil(volume_cubic_inches / USPS_DIVISOR);
  }

  const exceedsActualWeightLimit = weightPounds > USPS_CARRIER_CALC_MAX_WEIGHT_POUNDS;
  const exceedsLengthLimit = [lengthInches, widthInches, heightInches].some((v) => v > USPS_CARRIER_CALC_MAX_LENGTH_INCHES);
  const exceedsDimensionalWeightLimit = !!dimensionalWeightIfApplicable && dimensionalWeightIfApplicable > USPS_CARRIER_CALC_MAX_WEIGHT_POUNDS;

  let errorKey;
  if (exceedsActualWeightLimit) {
    errorKey = 'exceedsActualWeightLimit';
  } else if (exceedsLengthLimit) {
    errorKey = 'exceedsLengthLimit';
  } else if (exceedsDimensionalWeightLimit) {
    errorKey = 'exceedsDimensionalWeightLimit';
  }

  return {
    errorTranslationKey: errorKey ? `discovery.sellForm.shippingSection.domesticShippingCard.carrierCalculatedWarnings.${errorKey}` : null,
    carrier: 'USPS',
  };
}
