import React from 'react';
import I18n from 'i18n-js';
import { trackEvent } from '@reverbdotcom/commons/src/elog/mparticle_tracker';
import CoreLink from '@reverbdotcom/commons/src/components/core_link';
import { MParticleEventName } from '@reverbdotcom/commons/src/elog/mparticle_types';
import URLHelpers from '../shared/url_helpers';
import { RCButton } from '@reverbdotcom/cadence/components';
import RouterHistory from '@reverbdotcom/commons/src/cms/lib/router_history';

export const NO_LISTINGS = 'noListings';
export const NO_LISTINGS_MATCHING = 'noListingsMatching';
export const NO_LISTINGS_ON_PAGE = 'noListingsOnPage';

const COMPONENT_NAME = 'EmptyListingsState';

interface IExternalProps {
  message?: string;
}

export function clearAllListingsManagementFilters({ hasResults }) {
  const url = new URL(window.location.href);
  const { searchParams } = url;
  const paramsSize = Array.from(searchParams).length;
  const state = searchParams.get('state');

  const hasMultipleParams = paramsSize > 1;
  const hasStateParam = !!state;

  const isValidForClientSideRouting = hasStateParam && (hasMultipleParams || hasResults);

  let clearFilterUrl;
  // In the UI filters, we always force a state now.
  // If there are results for a state and/or multiple params, we're fine
  // performing a client-side redirect. When state is not present,
  // in the url params or if there are no results for a state,
  // we server-side redirect, because the controller has
  // logic built in to assign a default state with results
  if (isValidForClientSideRouting) {
    clearFilterUrl = URLHelpers.listingsManagerPath({
      state,
    });
    RouterHistory.push(clearFilterUrl);
  } else {
    clearFilterUrl = URLHelpers.listingsManagerPath();
    window.location.assign(clearFilterUrl);
  }
}

export default function EmptyListingsState({ message = '' }: IExternalProps) {

  const messageKey = message || NO_LISTINGS_ON_PAGE;

  return (
    <div className="d-flex fx-dir-col fx-align-center ptb-6 mt-20 mobile-mt-0">
      <div className="weight-bold size-120">
        {I18n.t(`discovery.listingsManagement.emptyState.${messageKey}`)}
      </div>

      {messageKey === NO_LISTINGS && (
        <CoreLink
          className="seller-listing-cards-collection__empty__create-listing-link"
          to={URLHelpers.sellFormSearchPath()}
          target="_blank"
          clickEvent={{
            eventName: MParticleEventName.ClickedLinkToSellFormSearch,
            componentName: COMPONENT_NAME,
          }}
        >
          {I18n.t('discovery.listingsManagement.emptyState.createNewListing')}
        </CoreLink>
      )}

      {messageKey === NO_LISTINGS_MATCHING && (
        <div className="mt-5">
          <RCButton
            theme="main"
            variant="outlined"
            onClick={() => {
              trackEvent({
                eventName: MParticleEventName.ClickedClearFiltersButton,
                componentName: COMPONENT_NAME,
              });

              clearAllListingsManagementFilters({
                hasResults: false,
              });
            }}
          >
            {I18n.t('discovery.listingsManagement.emptyState.clearFilters')}
          </RCButton>
        </div>
      )}
    </div>
  );
}
