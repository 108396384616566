import { ChildProps } from '@apollo/client/react/hoc';
import React, { useEffect } from 'react';
import { withGraphql } from '@reverbdotcom/commons/src/with_graphql';
import { Location } from 'history';
import I18n from 'i18n-js';
import {
  CoreMarketplaceCombinedMarketplaceSearch,
} from '@reverbdotcom/commons/src/gql/graphql';
import { withUserContext, IUserContext } from '@reverbdotcom/commons/src/components/user_context_provider';
import ListingsCollection from '@reverbdotcom/commons/src/components/listings_collection';
import CoreLink from '@reverbdotcom/commons/src/components/core_link';
import { Paths } from '@reverbdotcom/commons/src/url_helpers';
import { MParticleEventName } from '@reverbdotcom/commons/src/elog/mparticle_tracker';
import { useViewTracking } from '@reverbdotcom/commons/src/use_tracking';
import { connect } from '@reverbdotcom/commons/src/connect';

import { combinedMarketplaceSearchQuery } from './marketplace/map_params';
import { mapVarsForMarketplaceQuery } from './marketplace/marketplace_page';
import { MarketplacePathContext } from './shared/url_helpers';

interface ExternalProps {
  location: Location;
  params: MarketplacePathContext;
  listingsSearchCount: number;
  subquery: string;
  setAnyDisplayedRows: (arg: boolean) => void;
}

type CombinedProps = ExternalProps & IUserContext;

type IProps = ChildProps<CombinedProps, CoreMarketplaceCombinedMarketplaceSearch.Query, CoreMarketplaceCombinedMarketplaceSearch.Variables>;

const LISTINGS_QUERY_LIMIT = 12;
const BUMP_LIMIT_OVERRIDE = 0;
const COMPONENT_NAME = 'SubqueryRow';

function CallToAction({ subquery }) {
  const clickTrackingData = {
    componentName: COMPONENT_NAME,
    eventName: MParticleEventName.ClickedSubqueryRowSeeMoreLink,
    subquery,
  };

  return (
    <CoreLink
      to={Paths.marketplace.expand({ query: subquery })}
      clickEvent={clickTrackingData}
    >
      {I18n.t('cms.listingsCollection.seeMore')}
    </CoreLink>
  );
}

export function SubqueryRow(props: IProps) {
  const { subquery, setAnyDisplayedRows, listingsSearchCount } = props;

  const listings = props.data.listingsSearch?.listings;
  const count = props.data.listingsSearch?.total;

  useEffect(() => {
    setAnyDisplayedRows(props.data.listingsSearch && count !== 0 && count !== listingsSearchCount);
  }, [props.data.listingsSearch, count, listingsSearchCount]);

  const viewTrackingData = {
    componentName: COMPONENT_NAME,
    eventName: MParticleEventName.ComponentView,
    subquery,
  };

  useViewTracking(
    viewTrackingData,
    (!!props.data.listingsSearch && count !== 0 && count !== listingsSearchCount),
  );

  if (count === 0 || count === listingsSearchCount) return null;

  return (
    <>
      <ListingsCollection
        componentName={COMPONENT_NAME}
        callToAction={<CallToAction subquery={subquery} />}
        listings={listings}
        title={I18n.t('discovery.marketplace.searchResults.resultsForQuery', { count, subquery })}
      />
    </>
  );
}

const withQuery = withGraphql<IProps, CoreMarketplaceCombinedMarketplaceSearch.Query, CoreMarketplaceCombinedMarketplaceSearch.Variables>(
  combinedMarketplaceSearchQuery,
  {
    options: (props) => {
      return {
        ssr: false,
        variables: mapVarsForMarketplaceQuery(
          props, 
          BUMP_LIMIT_OVERRIDE, 
          LISTINGS_QUERY_LIMIT, 
          props.subquery, 
          false,
        ),
      };
    },
  },
);

export default connect<ExternalProps>([
  withUserContext,
  withQuery,
])(SubqueryRow);
