
import CMSListingsCollection from '@reverbdotcom/commons/src/cms/components/cms_listings_collection';
import { MParticleEventName } from '@reverbdotcom/commons/src/elog/mparticle_types';
import { reverb_search_ListingsSearchRequest_Sort } from '@reverbdotcom/commons/src/gql/graphql';
import { Paths } from '@reverbdotcom/commons/src/url_helpers';
import { useViewTracking } from '@reverbdotcom/commons/src/use_tracking';
import I18n from 'i18n-js';
import React from 'react';
import EmptyStateWatchedListings from '@reverbdotcom/commons/src/components/empty_state_watched_listings';
import { useUser } from '../user_hooks';

export interface ExternalProps {
  handleCreateWatchResult?: () => void;
  hasWatchedListings?: boolean;
  emptyStateMainCallToAction?: React.ReactElement;
  emptyStateSecondaryCallToAction?: React.ReactElement;
  topProductTypeUuid?: string;
  topProductTypeName?: string;
}

const REVERB_MERCH_SHOP_ID = '21452';
const REVERB_DIGITAL_SHOP_ID = '237252';
const INAPPRPRIATE_LISTING_SHOP_ID = '4740057';
export const EXCLUDED_SHOP_IDS = [REVERB_MERCH_SHOP_ID, REVERB_DIGITAL_SHOP_ID, INAPPRPRIATE_LISTING_SHOP_ID];

export function WatchedListingsFallback(props: ExternalProps) {
  const { hasWatchedListings, topProductTypeName, topProductTypeUuid } = props;
  const user = useUser();
  useViewTracking({
    eventName: MParticleEventName.ComponentView,
    componentName: topProductTypeUuid ? 'HomepageMostWatched' : 'EmptyStateWatchedListings',
  }, true);

  if (!topProductTypeUuid) {
    // exp group users that have no affinities should see EmptyState with copy that changes depending on if they have watched listings
    return (
      <EmptyStateWatchedListings
        user={user}
        mainCallToAction={props.emptyStateMainCallToAction}
        secondaryCallToAction={props.emptyStateSecondaryCallToAction}
        innerSubtitleOverride={
          hasWatchedListings ?
            I18n.t('commons.watchRow.loggedIn.emptyState.intermediarySubtitle', { tagOpen: '<strong>', tagClose: '</strong>' })
            : null
        }
      />
    );
  }

  const categoryName = I18n.t(`discovery.taxonomy.${topProductTypeUuid}`);

  return (
    <CMSListingsCollection
      ctaTargetHref={Paths.marketplace.expand({ sort: 'recent_watchers_count|desc', product_type: topProductTypeName })}
      categoryUuids={[topProductTypeUuid]}
      sort={reverb_search_ListingsSearchRequest_Sort.RECENT_WATCHERS_COUNT_DESC}
      title={I18n.t('discovery.homepage.mostWatched', { categoryName: categoryName })}
      trackingName="HomepageMostWatched"
      userShippingRegion={user.shippingRegionCode}
      excludeShopIds={EXCLUDED_SHOP_IDS} // excluding these shop ids removes reverb shipping boxes from results
      maxCount={8}
      largeTiles
      ssr={false}
      handleCreateWatchResult={props.handleCreateWatchResult}
      indentedMosaic
    />
  );
}

export default WatchedListingsFallback;
