import { ChildProps } from '@apollo/client/react/hoc';
// TODO update this to import from commons
// import { gql } from '@reverbdotcom/commons/src/gql';
// eslint-disable-next-line no-restricted-imports
import { gql } from '@apollo/client';
import { get } from 'lodash';
import React from 'react';
import I18n from 'i18n-js';
import moment from 'moment';

import { RCSelect } from '@reverbdotcom/cadence/components';
import CoreLink from '@reverbdotcom/commons/src/components/core_link';
import SiteModule from '@reverbdotcom/commons/src/components/site_module';

import { core_apimessages_MyShopOrderRefundStatsRequest, MyShopOrderRefundStats } from '@reverbdotcom/commons/src/gql/graphql';
import URLHelpers from './shared/url_helpers';
import ShopRefundStatsResults from './shop_refund_stats_results';
import ShopRefundStatsAlert from './shop_refund_stats_alert';
import { MParticleEvent } from '@reverbdotcom/commons/src/elog/mparticle_types';
import { withGraphql } from '@reverbdotcom/commons/src/with_graphql';
import { trackEvent } from '@reverbdotcom/commons/src/elog/mparticle_tracker';

export const COMPONENT_NAME = 'ShopRefundStats';
export const MAX_SELLER_INITIATED_REFUND = 5;

export enum DateRange {
  currentMonth = 'currentMonth',
  lastMonth = 'lastMonth',
}

export interface IPropsStats {
  dateRange?: DateRange;
  learnMoreClickEvent?: MParticleEvent;
  dateSelectClickEvent?: MParticleEvent;
}

export type IProps = ChildProps<IPropsStats, MyShopOrderRefundStats.Query>;

export const withMyShopOrderRefundStats = withGraphql<IProps, MyShopOrderRefundStats.Query>(
  gql`
    query MyShopOrderRefundStats(
      $input: Input_core_apimessages_MyShopOrderRefundStatsRequest
    ) {
      me {
        _id
        uuid
        shop {
          _id
          uuid
          orderRefundStats(input: $input) {
            sellerInitiatedRefundCount
          }
        }
      }
    }
  `, {
    options: () => {
      return {
        // Content is only rendered client side after a user clicks to open a seller initiated refund modal
        ssr: false,
        variables: {
          input: DATE_RANGE_INPUTS.currentMonth,
        },
      };
    },
  },
);

type DateRangeInputsType = {
  [key in DateRange]: core_apimessages_MyShopOrderRefundStatsRequest;
};

const inputDateFormat = 'LLL';

export const DATE_RANGE_INPUTS: DateRangeInputsType = {
  currentMonth: {
    startDate: moment.utc(new Date().toISOString()).startOf('month').format(inputDateFormat),
    endDate: undefined,
  },
  lastMonth: {
    startDate: moment.utc(new Date().toISOString()).subtract(1, 'month').startOf('month').format(inputDateFormat),
    endDate: moment.utc(new Date().toISOString()).subtract(1, 'month').endOf('month').format(inputDateFormat),
  },
};

export function ShopRefundStats(props: IProps) {
  const [dateRange, setDateRange] = React.useState(DateRange.currentMonth);

  const onDateRangeChange = (value: string) => {
    const dateRangeKey = DateRange[value];
    if (dateRangeKey) {
      setDateRange(dateRangeKey);
      props.data.refetch({ input: DATE_RANGE_INPUTS[dateRangeKey] });

      if (props.dateSelectClickEvent) {
        trackEvent(props.dateSelectClickEvent);
      }
    }
  };

  return (
    <div className="shop-refund-stats">
      <SiteModule title={I18n.t('discovery.dashboard.refundStats.title')}>
        {(!props.data.loading && !props.data.error) &&
          <ShopRefundStatsAlert
            count={get(props, 'data.me.shop.orderRefundStats.sellerInitiatedRefundCount')}
            dateRange={dateRange}
          />
        }
        <div className="shop-refund-stats__row">
          <div className="shop-refund-stats__section">
            <p>
              {I18n.t('discovery.dashboard.refundStats.description')}
            </p>
            <CoreLink
              target="_blank" to={URLHelpers.sellerRefundFeePath}
              clickEvent={props.learnMoreClickEvent}
            >
              <strong>{I18n.t('discovery.dashboard.refundStats.learnMore')}</strong>
            </CoreLink>
          </div>
          <div className="shop-refund-stats__section">
            <RCSelect
              label={I18n.t('discovery.dashboard.refundStats.datePicker.title')}
              id="date-range-selector"
              name="date-ranges"
              onChange={(event) => onDateRangeChange(event.target.value)}
              value={dateRange}
              required
              addEmptyOption
              emptyOptionLabel={I18n.t('discovery.dashboard.refundStats.datePicker.placeholder')}
            >
              <RCSelect.Option
                label={I18n.t('discovery.dashboard.refundStats.datePicker.currentMonth')}
                value="currentMonth"
              />
              <RCSelect.Option
                label={I18n.t('discovery.dashboard.refundStats.datePicker.lastMonth')}
                value="lastMonth"
              />
            </RCSelect>
            <ShopRefundStatsResults
              dateRange={dateRange}
              loading={!!props.data?.loading}
              error={!!props.data?.error}
              count={get(props, 'data.me.shop.orderRefundStats.sellerInitiatedRefundCount')}
            />
          </div>
          <div className="shop-refund-stats__section">
            <div className="shop-refund-stats__icon">
              <img
                alt=""
                src="https://res.cloudinary.com/reverb-marketing/image/upload/v1622133378/20191230_illustration_v4_100x100px_color_protection.svg"
              />
            </div>
            <div className="shop-refund-stats__icon-label">
              <div>
                {I18n.t(
                  'discovery.dashboard.refundStats.recommended',
                  { max: MAX_SELLER_INITIATED_REFUND },
                )}
              </div>
            </div>
          </div>
        </div>
      </SiteModule>
    </div>
  );
}

export default withMyShopOrderRefundStats(ShopRefundStats);
