import I18n from 'i18n-js';
import React, { useContext } from 'react';

import { I18N as Translate } from '@reverbdotcom/commons/src/components/translate';
import { ShippingRegionSelector, ILegacyApiShippingRegion } from '@reverbdotcom/commons/src/shipping/shipping_region_selector';
import { RCLoadingBars } from '@reverbdotcom/cadence/components';
import { EVERYWHERE_CODE, US_CON, US } from '@reverbdotcom/commons/src/constants';
import TrendingSvg from '@reverbdotcom/cadence/images/brand/graph-equity-orange.svg';
import { trackEvent, MParticleEventName } from '@reverbdotcom/commons/src/elog/mparticle_tracker';
import { FormCards } from '@reverbdotcom/commons/src/components/FormCards';

import { buildBlankShippingRate, upsertShippingRate } from './ShippingForm';
import { SellFormContext } from '../../SellFormContext';
import { Field, ActionType } from '../../sellFormReducerTypes';
import { V3 } from '../../../../api_request';
import UrlHelpers from '../../../../shared/url_helpers';
import SellFormCallout from '../SellFormCallout';
import InternationalShippingPrice from './InternationalShippingPrice';

function InternationalShippingCard() {
  const { listingEntry, dispatch, shopConfig, loading, listingCreateFlow } = useContext(SellFormContext);
  const [areShippingRegionsLoaded, setAreShippingRegionsLoaded] = React.useState(false);
  const [shippingRegions, setShippingRegions] = React.useState<ILegacyApiShippingRegion[]>([]);

  const { shippingRegionSettings } = shopConfig;
  const domesticRegion = shippingRegionSettings.find(({ isDomestic }) => isDomestic);
  const nonDomesticShippingRates = listingEntry[Field.SHIPPING_RATES].filter((sr) => sr.regionCode !== domesticRegion.regionCode);
  const shouldDisableUsOption = domesticRegion.regionCode === US_CON; // Don't let US_CON shops set a US rate
  const disabledRegionCodes = listingEntry[Field.SHIPPING_RATES].map(({ regionCode }) => regionCode);
  if (shouldDisableUsOption) { disabledRegionCodes.push(US); }

  React.useEffect(() => {
    V3.get(UrlHelpers.shippingRegionsPath).done((response) => {
      setShippingRegions(response.shipping_regions);
      setAreShippingRegionsLoaded(true);
    });
  }, [setShippingRegions]);

  const suggestedShippingRegions = shippingRegionSettings.filter(({ isDomestic, regionCode }) => !isDomestic && regionCode !== EVERYWHERE_CODE);

  if (!areShippingRegionsLoaded) {
    return (
      <FormCards.Card>
        <div className="scaling-padding-4 d-flex fx-justify-center bg-white">
          <RCLoadingBars size="large" />
        </div>
      </FormCards.Card>
    );
  }

  return (
    <FormCards.Card>
      <FormCards.FormSection title={I18n.t('discovery.sellForm.shippingSection.internationalShippingCard.header')} >
        {suggestedShippingRegions.length > 0 && (
          <SellFormCallout
            imageSrc={TrendingSvg}
            imageAlt={I18n.t('discovery.sellForm.shippingSection.internationalShippingCard.iconAlt')}
          >
            <div className="size-90 ml-4">
              <Translate text="discovery.sellForm.shippingSection.internationalShippingCard.suggestedRegions">
                <div className="pl-4 mtb-1">
                  <ul className="cms-ul mb-0">
                    {suggestedShippingRegions.map(({ regionName }) => (
                      <li key={regionName}>
                        {regionName}
                      </li>
                    ))}
                  </ul>
                </div>
              </Translate>
            </div>
          </SellFormCallout>
        )}

        {nonDomesticShippingRates.map((sr) => <InternationalShippingPrice key={sr.regionCode} shippingRate={sr}/>)}

        <ShippingRegionSelector
          shippingRegions={shippingRegions}
          onSelect={(r) => {
            if (r.regionCode) {
              trackEvent({
                eventName: MParticleEventName.SellFormInteraction,
                interactionName: 'addedShippingRegion',
                listingCreateFlow,
              });

              dispatch({
                type: ActionType.UPDATE_FIELD,
                fieldKey: Field.SHIPPING_RATES,
                value: upsertShippingRate(listingEntry[Field.SHIPPING_RATES], buildBlankShippingRate(r.regionCode)),
              });
            }
          }}
          disabledShippingRegionCodes={disabledRegionCodes}
          value={null}
          disabled={loading}
        />

        <div className="scaling-mb-6">
          <p className="mt-2 size-90 lh-120 mb-0">
            {I18n.t('discovery.dashboard.selling.shippingRates.addShippingLocationExplainer')}
          </p>
        </div>
      </FormCards.FormSection>
    </FormCards.Card>
  );
}

export default InternationalShippingCard;
