import { HomepageComponentTrackingNames } from './homepage';

export const WatchParentComponentNames = {
  // These component names are the same in web and mobile app clients:
  MarketplaceGridWatch: 'Marketplace Grid Watch', // listing cards on marketplace grids

  // These component names are unique to web clients and are not used by mobile app clients:
  ItemPageActions: 'ItemPage', // existing value used for the item page watch button (item2_pricing.tsx)
  ItemPageImage: 'ItemPage - Image', // image carousel on item page
  MarketplaceListViewAction: 'ListViewRowCard',
  MarketplaceListViewIcon: 'Marketplace List View - Watch Icon',
  Feed: 'Feed', // my/feed grid, or homepage feed row

  Cart: 'Cart',
  ...HomepageComponentTrackingNames,
} as const;

export type WatchParentComponentName = typeof WatchParentComponentNames[keyof typeof WatchParentComponentNames];

export const WatchlistContainerComponentNames = {
  // Viewing your own watchlist - GET /watched_products or GET /watched_products/ended
  WatchlistContainer: 'WatchlistContainer',

  // Viewing another user's watchlist - GET /watched_products/shared/:profile_slug or GET /watched_products/shared/:profile_slug/ended
  PublicWatchlistContainer: 'PublicWatchlistContainer',
} as const;
