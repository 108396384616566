import { RCBanner } from '@reverbdotcom/cadence/components';
import CoreLink from '@reverbdotcom/commons/src/components/core_link';
import React from 'react';
import I18n from 'i18n-js';
import DocumentBrandIcon from '@reverbdotcom/cadence/images/brand/document-orange.svg';
import { I18N as Translate } from '@reverbdotcom/commons/src/components/translate';

export function InternationalTaxProfileBanner() {
  return (
    <RCBanner
      title={I18n.t('discovery.internationalTaxProfile.banner.bannerTitle')}
      content={
        <Translate text="discovery.internationalTaxProfile.banner.bannerContent" />
      }
      image={DocumentBrandIcon}
      cta={<CoreLink to="/my/selling/international_tax_profile"><Translate text="discovery.internationalTaxProfile.banner.bannerCta" /></CoreLink>}
    />
  );
}

export default InternationalTaxProfileBanner;
