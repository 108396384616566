export default {
  title: 'Feedback Messages',
  addAnother: 'Add another message',
  description: 'Create feedback messages to send automatically to buyers. Create as many messages as you’d like. Reverb will randomly select one to send each buyer on your behalf.',
  form: {
    placeholder: 'Write your message here',
    addNew: 'Add new message',
    cancel: 'Cancel',
    add: 'Add',
  },
  table: {
    header: 'Message',
  },
  actions: {
    removeMessage: 'Remove message',
  },
}; 
