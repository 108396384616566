import React, {
  useState,
  useCallback,
  useRef,
} from 'react';
import { getItem, setItem } from '../local_storage_utils';
import { useUser } from '../user_hooks';
import { inMobileApp } from '../user_context_helpers';
import { RCThemeContext, Theme, DEFAULT_THEME, isValidTheme } from '@reverbdotcom/cadence/components';

const THEME_DATA_ATTR = 'data-theme';
const THEME_KEY = 'reverb-theme';

interface ThemeContextProviderProps {
  theme: Theme;
}

/**
 * Top level Theme provider.
 * To override a theme lower in the stack use `RCThemeOverride`.
*/
export function ThemeContextProvider({
  theme: themeProp = null,
  children,
}: React.PropsWithChildren<ThemeContextProviderProps>) {
  const [providerTheme, setProviderTheme] = useState<Theme>(themeProp);
  const user = useUser();
  const isMobileApp = inMobileApp(user);

  const updateTheme = useCallback((newTheme: Theme) => {
    setProviderTheme(newTheme);
    setElementAttribute(newTheme);
    setLocalStorageKey(newTheme, isMobileApp);
  }, [isMobileApp]);

  useSetInitialTheme(providerTheme, updateTheme, isMobileApp);
  useThemeChangeObserver(updateTheme);

  return (
    <RCThemeContext.Provider value={{ theme: providerTheme, updateTheme }}>
      {children}
    </RCThemeContext.Provider>
  );
}

function setElementAttribute(newTheme: Theme) {
  const element = document?.documentElement;
  if (element?.getAttribute(THEME_DATA_ATTR) === newTheme) return;

  element?.setAttribute(THEME_DATA_ATTR, newTheme);
}

function setLocalStorageKey(newTheme: Theme, isMobileApp: boolean) {
  if (isMobileApp) return;
  if (getItem(THEME_KEY) === newTheme) return;
  setItem(THEME_KEY, newTheme);
}

function useSetInitialTheme(theme: Theme, callback: (newTheme: Theme) => void, isMobileApp: boolean) {
  React.useLayoutEffect(() => {
    if (theme) return;
    if (isMobileApp) {
      callback('system');
      return;
    }

    const localStorageKey = getItem(THEME_KEY);
    const initialTheme: Theme = isValidTheme(localStorageKey) ? localStorageKey : DEFAULT_THEME;

    callback(initialTheme);
  }, []);
}

// Uses a MutationObserver to check for changes to the `data-theme` attr.
// This allows ThemeContextProvider sibling components to stay in sync.
function useThemeChangeObserver(callback: (newTheme: Theme) => void) {
  const observer = useRef(null);
  const isObserverSupported = 'MutationObserver' in window;

  React.useEffect(() => {
    if (!isObserverSupported) return null;
    if (observer.current) observer.current.disconnect();

    observer.current = new window.MutationObserver(([mutation]) => {
      const targetElement = mutation.target as HTMLElement;
      const newTheme = targetElement.dataset?.theme as Theme;

      if (!isValidTheme(newTheme)) return;
      callback(newTheme);
    });

    observer.current.observe(
      document?.documentElement,
      { attributeFilter: [THEME_DATA_ATTR] },
    );

    return () => observer.current.disconnect();
  }, [isObserverSupported, callback]);
}
