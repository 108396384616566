import React, { useContext, useEffect, useState } from 'react';
import I18n from 'i18n-js';
import Modal from '@reverbdotcom/commons/src/components/modal';
import { RCButton } from '@reverbdotcom/cadence/components';
import { MParticleEventName, trackEvent } from '@reverbdotcom/commons/src/elog/mparticle_tracker';
import BumpV2ModalContainer, {
  BumpV2ModalContainerProps,
} from '../../bump/bump_v2_modal_container';
import {
  bumpRecommendationsController,
  BumpControllerProps,
} from '../../bump/bump_controller';
import { formatBid } from '../../bump/bump_helpers';
import SellerListingCardContext from '../SellerListingCardContext';
import { ActionType } from '../sellerListingCardReducer';

import SellerListingsCollectionContext from '../SellerListingsCollectionContext';

export const COMPONENT_NAME = 'BumpButton';

export function BumpButtonV2(props: BumpV2ModalContainerProps) {
  const [showModal, setShowModal] = useState(false);

  const { dispatch } = useContext(
    SellerListingCardContext,
  );

  const {
    useLazySellerListingsQuery,
  } = useContext(SellerListingsCollectionContext);

  const { refetchSellerListings } = useLazySellerListingsQuery();

  function handleClick() {
    setShowModal(true);
    trackEvent({
      eventName: MParticleEventName.ClickedComponent,
      componentName: COMPONENT_NAME,
      listingId: props.listingId,
    });
  }

  useEffect(() => {
    if (props.successMessage) {
      const message = props.bid
        ? I18n.t('discovery.bump.successListingRow', {
          percent: formatBid(props.bid),
        })
        : I18n.t('discovery.bump.successBidDelete');

      refetchSellerListings([props.listingId]);

      dispatch({
        type: ActionType.SHOW_TOAST,
        payload: {
          isSuccess: true,
          message,
        },
      });
    }
  }, [props.successMessage]);

  return (
    <>
      <RCButton variant="muted" onClick={handleClick} fullWidth>
        {I18n.t('discovery.bumpModalV2.buttonListingRow')}
      </RCButton>
      <Modal
        isOpen={showModal}
        headerTitle={I18n.t('discovery.bumpModal.title')}
        onRequestClose={() => {
          setShowModal(false);
        }}
        paddedBody
        shouldCloseOnOverlayClick
      >
        <BumpV2ModalContainer
          bid={props.bid}
          listingId={props.listingId}
          selectedBid={props.selectedBid}
          handleBidChange={props.handleBidChange}
          updateBid={props.updateBid}
          successMessage={props.successMessage}
          failMessage={props.failMessage}
          refetch={() => {}}
          onRequestClose={() => {
            setShowModal(false);
          }}
        />
      </Modal>
    </>
  );
}

const BumpButtonV2Container =
  bumpRecommendationsController(BumpButtonV2);

export default (props: BumpControllerProps) => {
  return (
    <BumpButtonV2Container
      componentName={COMPONENT_NAME}
      listingId={props.listingId}
      bid={props.bid}
      refetch={() => {}}
    />
  );
};
