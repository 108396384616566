import React from 'react';
import I18n from 'i18n-js';
import { buttonClassName, RCButton } from '@reverbdotcom/cadence/components';
import CoreLink from '@reverbdotcom/commons/src/components/core_link';
import { CartClickInteraction } from '@reverbdotcom/commons/src/elog/mparticle_types';
import {
  core_apimessages_CheckoutPaymentMethod_Type as CheckoutPaymentMethodType,
} from '@reverbdotcom/commons/src/gql/graphql';
import { useTrackCheckoutClickEvent } from './checkout/checkoutTracking';
import { PaymentMethodIcons } from './PaymentMethodIcons';
import { IconDisplay } from '../lib/payment_method_icons';
import { useUser } from '@reverbdotcom/commons/src/user_hooks';
import { CheckoutGuestAuthModal } from './CheckoutGuestAuthModal';

interface Props {
  paymentMethodType: CheckoutPaymentMethodType;
  paymentMethodIcons: IconDisplay[];
  checkoutPath: string;
}

export default function CartStartCheckoutButton(props: Props) {
  const user = useUser();
  const showGuestAuthModalButton = user.loggedOut;
  const [isModalOpen, setModalOpen] = React.useState(false);

  function openModal() {
    setModalOpen(true);
    trackEvent(CartClickInteraction.OpenAuthModal);
  }

  function closeModal() {
    setModalOpen(false);
    trackEvent(CartClickInteraction.CloseAuthModal);
  }

  const trackEvent = useTrackCheckoutClickEvent(
    {
      id: null,
      paymentMethod: {
        type: props.paymentMethodType,
      },
    },
  );

  function button() {
    if (showGuestAuthModalButton) {
      return (
        <>
          <RCButton
            variant="filled"
            onClick={openModal}
            fullWidth
          >
            {I18n.t('discovery.checkout.cart.startCheckout')}
            <span className="icon-r-arrow-right" />
          </RCButton>
          <CheckoutGuestAuthModal
            active={isModalOpen}
            redirectTo={props.checkoutPath}
            onRequestClose={closeModal}
            trackSource="Cart"
          />
        </>
      );
    }

    return (
      <div className="mobile-width-100">
        <CoreLink
          to={props.checkoutPath}
          className={buttonClassName({ variant: 'filled', fullWidth: true })}
          onClick={() => trackEvent(CartClickInteraction.StartCheckout)}
        >
          {I18n.t('discovery.checkout.cart.startCheckout')}
          <span className="icon-r-arrow-right" />
        </CoreLink>
      </div>
    );
  }

  return (
    <div className="d-flex fx-dir-col gap-1 fx-align-center">
      {button()}
      <PaymentMethodIcons
        icons={props.paymentMethodIcons}
        variant="small"
      />
    </div>
  );
}
