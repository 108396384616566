import React from 'react';
import Modal from '@reverbdotcom/commons/src/components/modal';
import { PaymentAction } from '@adyen/adyen-web/dist/types/types';
import { SCAState, useAdyenSCA } from './adyen_sca_hook';

interface Props {
  action: PaymentAction;
  onAdditionalDetails(state?: SCAState): void;
}

export default function SCAChallengeShopper(props: Props) {
  const [isOpen, setOpen] = React.useState(true);

  function onAdditionalDetails(state: SCAState) {
    setOpen(false);
    props.onAdditionalDetails(state);
  }

  // Adyen doesn't provide their own cancellation callback,
  // so if the user cancels the challenge flow (like closing the modal),
  // onComplete is triggered with a result that will cause the
  // 3DS submission process to fail gracefully.
  function onCancel() {
    setOpen(false);
    props.onAdditionalDetails(null);
  }

  return (
    <Modal
      isOpen={isOpen}
      paddedBody
      onRequestClose={onCancel}
    >
      <ChallengeShopperForm action={props.action} onAdditionalDetails={onAdditionalDetails} />
    </Modal>
  );
}

function ChallengeShopperForm({ action, onAdditionalDetails }) {
  useAdyenSCA({ selector: '[data-3ds-challenge-shopper]', action, onAdditionalDetails });
  return <div data-3ds-challenge-shopper />;
}
