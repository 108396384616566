import React from 'react';
import CoreLink from '@reverbdotcom/commons/src/components/core_link';
import { Paths } from './shared/url_helpers';
import { I18N } from '@reverbdotcom/commons/src/components/translate';

export default function BillingMethodCompleteShopFirst() {
  return (
    <div className="padding-4">
      <div className="mb-4">
        <I18N text="discovery.billingMethod.completeShopFirst.description" />
      </div>
      <CoreLink className="button button--primary" to={Paths.shopEditEssentialData.expand({ redirect_to: Paths.sellerStatements.expand({}) })}>
        <I18N text="discovery.billingMethod.completeShopFirst.button" />
      </CoreLink>
    </div>
  );
}
