import accounting from 'accounting';
import Currency from '@reverbdotcom/commons/src/currency';

class Money {
  cents: number;

  currency: string;

  static parse(amountString: string, currency: string) {
    const cents = parseFloat(amountString) * Currency.get(currency).subunitToUnit;
    return new Money(cents, currency);
  }

  constructor(cents: number, currency: string) {
    this.cents = cents;
    this.currency = currency || 'USD';
  }

  toString() {
    const currencyData = Currency.get(this.currency);

    return accounting.formatMoney(
      this.cents / currencyData.subunitToUnit,
      {
        symbol: currencyData.symbol,
        precision: currencyData.precision,
      },
    );
  }

  toStringNoCents() {
    const currencyData = Currency.get(this.currency);

    return accounting.formatMoney(
      this.cents / currencyData.subunitToUnit,
      {
        symbol: currencyData.symbol,
        precision: 0,
      },
    );
  }
}

export default Money;
