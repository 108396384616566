import React, { useState, useCallback } from 'react';
import StandardFormFields from './standard_form_fields';
import {
  IProps as GenericProps,
  IRefundType,
  getInitialRefundState,
  mapRefundTypeToRefundState,
  IRefundState,
} from './refund_request';
import { IAccommodationDataFields } from '@reverbdotcom/commons/src/components/admin_accommodation_data_fields';
import RefundAccommodationFormFields from './refund_accommodation_form_fields';

export interface IProps extends GenericProps {
  accommodationAuthorized?: boolean;
  isAdyenShopWithPayoutsDisabled?: boolean;
  adminAbleToAccommodate?: boolean;
  categoryToReasonMap: object;
  categoryToPolicyContextMap: object;
}

export default function AdminRefundRequestFormFields(props: IProps) {
  const {
    userType,
    reasons,
    order,
    refundRequest,
    viewContext,
    hideRefundExplanation,
    accommodationAuthorized,
    isAdyenShopWithPayoutsDisabled,
    categoryToReasonMap,
    categoryToPolicyContextMap,
  } = props;
  const { canConditionallyApprove } = refundRequest;

  const [refundType, setRefundType] = useState(IRefundType.FULL);
  const onRefundTypeChange = event => {
    const refundTypeValue = event.target.value;
    setRefundType(refundTypeValue);
    setRefundState(
      mapRefundTypeToRefundState(
        refundTypeValue,
        canConditionallyApprove,
      ),
    );
  };

  const initialRefundState = getInitialRefundState(canConditionallyApprove);
  const [refundState, setRefundState] = useState(initialRefundState);
  const onRefundStateChange = event => {
    const refundStateValue = event.target.value;
    const { APPROVED } = IRefundState;

    setRefundState(refundStateValue);
    if (refundStateValue !== APPROVED) {
      setIsReverbAccommodated(false);
    }
  };

  const [isReverbAccommodated, setIsReverbAccommodated] = useState(false);
  const toggleReverbAccommodated = () => {
    setIsReverbAccommodated(!isReverbAccommodated);
  };

  const [formData, setFormData] = useState<IAccommodationDataFields>({
    selectedCategory: '',
    selectedReason: '',
    additionalContext: '',
    selectedPolicyContexts: {},
  });

  const setFormField = useCallback((fieldName, value) => {
    setFormData((state) => ({
      ...state,
      [fieldName]: value,
    }));
  }, [ setFormData ]);

  const renderAdminFormFields = () => {
    if (accommodationAuthorized) {
      return (
        <RefundAccommodationFormFields
          accommodationAuthorized={accommodationAuthorized}
          isAdyenShopWithPayoutsDisabled={isAdyenShopWithPayoutsDisabled}
          isReverbAccommodated={isReverbAccommodated}
          toggleReverbAccommodated={toggleReverbAccommodated}
          refundState={refundState}
          categoryToReasonMap={categoryToReasonMap}
          categoryToPolicyContextMap={categoryToPolicyContextMap}
          formData={formData}
          setFormField={setFormField}
        />
      );
    }
  };

  return (
    <div>
      <StandardFormFields
        userType={userType}
        reasons={reasons}
        order={order}
        refundRequest={refundRequest}
        viewContext={viewContext}
        hideRefundExplanation={hideRefundExplanation}
        refundType={refundType}
        onRefundTypeChange={onRefundTypeChange}
        refundState={refundState}
        onRefundStateChange={onRefundStateChange}
      />
      {renderAdminFormFields()}
    </div>
  );
}
