import { ApolloError } from '@apollo/client';
import { core_apimessages_Error } from './gql/graphql';
import I18n from 'i18n-js';
import { get, flatten, compact } from 'lodash';

interface IGraphqlErrorWrapper {
  graphQLErrors?: IGraphqlErrorResponse[];
}

interface IGraphqlErrorResponse {
  extensions: IGraphqlErrorExtensions;
}

// Errors from each graphql mutation operation are stored in one of these.
interface IGraphqlErrorExtensions {
  errors?: (core_apimessages_Error | null)[] | null;
}

// Given an error caught from a graphql mutation,
// returns an array of parsed validation error messages.
// NOTE: This assumes that a single flat list of error messages
// is expected from the graphql operation, so it may not be
// suitable for a multi-mutation operation.
// See tests for examples.

export function parseMutationErrors(errorResponse: IGraphqlErrorWrapper | ApolloError): core_apimessages_Error[] {
  const graphqlErrors = (get(errorResponse, 'graphQLErrors') || []) as any;
  const parsedErrors = flatten(graphqlErrors.map(e => get(e, 'extensions.errors')));

  if (!compact(parsedErrors).length) {
    return defaultGraphqlErrors();
  }

  return parsedErrors;
}

export function defaultGraphqlErrors() {
  return (
    [
      {
        message: I18n.t('commons.errorDisplay.message'),
        field: '',
        context: '',
        errorType: '',
      },
    ]
  );
}

export function parseQueryErrors(error: ApolloError): core_apimessages_Error[] {
  const graphqlErrors = error.graphQLErrors || [];
  const parsedErrors = flatten(graphqlErrors.map(function (e) {
    return { message: e.message };
  }));
  if (!compact(parsedErrors).length) {
    return defaultGraphqlErrors();
  }
  return parsedErrors;
}
