import I18n from 'i18n-js';
import { core_apimessages_Money } from '@reverbdotcom/commons/src/gql/graphql';
import { IRefundableOrderProps, IRefundRequestProps, IUserType, IRefundType, IRefundState, isFullRefund } from './refund_request';

import URLHelpers from '../components/shared/url_helpers';

const strongOpen = '<strong>';
const strongClose = '</strong>';
const payoutsLinkOpen = `<a href="${URLHelpers.dashboardSellingPayoutsPath}" target="_blank">`;
const linkClose = '</a>';

export function getRefundExplanationLineItems(
  userType: IUserType,
  refundType: IRefundType,
  order: IRefundableOrderProps,
  refundState: IRefundState,
  refundRequest: IRefundRequestProps,
  refundAmount: string,
  refundReason: string,
  protectionPlanAmount?: core_apimessages_Money,
): string[] {
  if (userType === IUserType.BUYER) {
    return buyerExplanationItems(refundReason, protectionPlanAmount, refundType);
  }

  if (refundRequest.isPersisted) {
    return sellerUpdateRefundRequestLineItems(
      order,
      refundState,
      refundAmount,
    );
  }

  if (refundState === IRefundState.CONDITIONALLY_APPROVED) {
    return sellerConditionallyApprovedRefundItems();
  }

  if (refundType === IRefundType.FULL) {
    return sellerApprovedFullRefundItems(order);
  }

  return sellerApprovedPartialRefundItems(order);
}

export function buyerExplanationItems(refundReason: string, protectionPlanAmount: core_apimessages_Money, refundType: IRefundType): string[] {
  const items = [];

  if (refundReason) {
    items.push(
      I18n.t(`discovery.dashboard.refundRequests.buyer.explanation.reasons.${refundReason}`),
    );
  }

  items.push(
    I18n.t('discovery.dashboard.refundRequests.buyer.explanation.response'),
  );

  if (protectionPlanAmount && refundType === IRefundType.FULL) {
    items.push(
      I18n.t('discovery.dashboard.refundRequests.buyer.explanation.protectionPlanDetails', { amount: protectionPlanAmount.display }),
    );
  }

  return items;
}

export function sellerUpdateRefundRequestLineItems(
  order: IRefundableOrderProps,
  refundState: IRefundState,
  refundAmount: string,
): string[] {
  let items = [];

  if (refundState === IRefundState.CONDITIONALLY_APPROVED) {
    items = sellerConditionallyApprovedRefundItems();
  }

  if (order.isPaypal && refundState === IRefundState.APPROVED) {
    items.push(
      I18n.t('discovery.dashboard.refundRequests.seller.explanation.paypal'),
    );
  }

  if (refundState === IRefundState.APPROVED) {
    items.push(
      I18n.t('discovery.dashboard.refundRequests.seller.explanation.update.refundAmount'),
    );
  }

  if (order.sellingFeeAmount && isFullRefund(order, refundAmount)) {
    const sellingFeeKey =
      order.sellingFeesDeductedFromPayouts ? 'fullRefundToPayout' : 'fullRefund';

    items.push(
      I18n.t(`discovery.dashboard.refundRequests.seller.explanation.update.${sellingFeeKey}`, {
        strongOpen,
        strongClose,
        amount: order.sellingFeeAmount.display,
      }),
    );
  }

  if (order.sellingFeeAmount && !isFullRefund(order, refundAmount)) {
    items.push(
      I18n.t('discovery.dashboard.refundRequests.seller.explanation.update.partialRefund'),
    );
  }

  return items;
}

export function sellerConditionallyApprovedRefundItems(): string[] {
  return [
    I18n.t('discovery.dashboard.refundRequests.seller.explanation.conditionallyApproved.refundComplete'),
    I18n.t('discovery.dashboard.refundRequests.seller.explanation.conditionallyApproved.refundAdjustment'),
  ];
}

export function sellerApprovedPartialRefundItems(order: IRefundableOrderProps): string[] {
  const items = [
    I18n.t('discovery.dashboard.refundRequests.seller.explanation.partialRefund.amount'),
  ];

  const hasPayout = order.payoutAmountAvailableToRefund;
  if (hasPayout) {
    items.push(
      I18n.t('discovery.dashboard.refundRequests.seller.explanation.partialRefund.payout', {
        linkClose,
        linkOpen: payoutsLinkOpen,
        amount: order.payoutAmountAvailableToRefund.display,
      }),
    );
  }

  if (order.sellingFeeAmount) {
    const sellingFeeKey = order.sellingFeesDeductedFromPayouts ? 'sellingFees' : 'sellingFeesCreditedToPayout';

    items.push(
      I18n.t(`discovery.dashboard.refundRequests.seller.explanation.partialRefund.${sellingFeeKey}`, {
        strongOpen,
        strongClose,
        amount: order.sellingFeeAmount.display,
      }),
    );
  }

  return items;
}

export function sellerApprovedFullRefundItems(order: IRefundableOrderProps): string[] {
  const items = [
    I18n.t('discovery.dashboard.refundRequests.seller.explanation.fullRefund.refundAmount', {
      strongOpen,
      strongClose,
      amount: order.amountAvailableToRefund.display,
    }),
  ];

  if (order.reverbRemittedTaxAmountAvailableToRefund.amountCents !== 0) {
    items.push(
      I18n.t('discovery.dashboard.refundRequests.seller.explanation.fullRefund.taxRemittedByReverb', {
        strongOpen,
        strongClose,
        amount: order.reverbRemittedTaxAmountAvailableToRefund.display,
      }),
    );
  }

  if (order.sellingFeeAmount) {
    const sellingFeeKey = order.sellingFeesDeductedFromPayouts ? 'sellingFees' : 'sellingFeesCreditedToPayout';

    items.push(
      I18n.t(`discovery.dashboard.refundRequests.seller.explanation.fullRefund.${sellingFeeKey}`, {
        strongOpen,
        strongClose,
        amount: order.sellingFeeAmount.display,
      }),
    );
  }

  if (order.isPaypal) {
    items.push(
      I18n.t('discovery.dashboard.refundRequests.seller.explanation.paypal'),
    );
  }

  const hasCompletedPayout = order.payoutAmountAvailableToRefund && order.hasCompletedPayout;
  if (hasCompletedPayout) {
    items.push(
      I18n.t('discovery.dashboard.refundRequests.seller.explanation.fullRefund.completedPayout.original', {
        strongOpen,
        strongClose,
        amount: order.payoutAmountAvailableToRefund.display,
      }),
    );
    items.push(
      I18n.t('discovery.dashboard.refundRequests.seller.explanation.fullRefund.completedPayout.refund', {
        strongOpen,
        strongClose,
        linkClose,
        amount: order.payoutAmountAvailableToRefund.display,
        linkOpen: payoutsLinkOpen,
      }),
    );
  }

  const hasPendingPayout = order.payoutAmountAvailableToRefund && !order.hasCompletedPayout;
  if (hasPendingPayout) {
    items.push(
      I18n.t('discovery.dashboard.refundRequests.seller.explanation.fullRefund.pendingPayout.original', {
        strongOpen,
        strongClose,
        amount: order.payoutAmountAvailableToRefund.display,
      }),
    );
    items.push(
      I18n.t('discovery.dashboard.refundRequests.seller.explanation.fullRefund.pendingPayout.refund', {
        strongOpen,
        strongClose,
        linkClose,
        amount: order.payoutAmountAvailableToRefund.display,
        linkOpen: payoutsLinkOpen,
      }),
    );
  }

  return items;
}
