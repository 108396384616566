import I18n from 'i18n-js';
import React from 'react';

import CmsPageCards, { CmsPageFields, CMS2_PAGE } from '@reverbdotcom/commons/src/cms/components/cms_page_cards';
import CoreLink from '@reverbdotcom/commons/src/components/core_link';

const CTA_URL = '/page/handpicked-collections';

function renderAction() {
  return (
    <CoreLink
      to={CTA_URL}
    >
      {I18n.t('discovery.homepageCuratedSetRow.cta')}
    </CoreLink>
  );
}

function getPageFields(): CmsPageFields[] {
  return [
    {
      pageSource: CMS2_PAGE,
      slug: 'deals-and-steals',
    },
    {
      pageSource: CMS2_PAGE,
      slug: 'price-drops',
    },
    {
      pageSource: CMS2_PAGE,
      slug: 'reverb-exclusives-and-makers',
    },
  ];
}

export default function HomepageCuratedSetRow(props) {
  return (
    <CmsPageCards
      title={props.noTitle ? null : I18n.t('discovery.homepageCuratedSetRow.title')}
      action={renderAction()}
      pageFields={getPageFields()}
    />
  );
}
