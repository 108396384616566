// TODO update this to import from commons
// import { gql } from '@reverbdotcom/commons/src/gql';
// eslint-disable-next-line no-restricted-imports
import { gql } from '@apollo/client';
import React, { useState } from 'react';
import { RCButton, RCFavoriteButton, RCTextWithIcon, getStarsCount } from '@reverbdotcom/cadence/components';
import { useUser } from '@reverbdotcom/commons/src/user_hooks';
import { HeartEmptyIcon, HeartFilledIcon } from '@reverbdotcom/cadence/icons/react';
import { withGraphql } from '@reverbdotcom/commons/src/with_graphql';
import {
  DeleteMyFavoriteShop, FavoriteShopCTAData, UndoDeleteMyFavorite,
  UpsertMyFavoriteShop, core_apimessages_Favorite, core_apimessages_FavoriteType,
  core_apimessages_UserActionName } from '@reverbdotcom/commons/src/gql/graphql';
import I18n from 'i18n-js';
import * as elog from '@reverbdotcom/commons/src/elog';
import classNames from 'classnames';
import { useHOCMutation, MutationFunction } from '@reverbdotcom/commons/src/useHOCMutation';
import { connect } from '@reverbdotcom/commons/src/connect';
import { MParticleEventName } from '@reverbdotcom/commons/src/elog/mparticle_types';
import { trackEvent } from '@reverbdotcom/commons/src/elog/mparticle_tracker';
import { resolveCurrentView, resolveFavoriteType } from '@reverbdotcom/commons/src/event_tracking/favorites';
import { ToastCloseType } from '@reverbdotcom/cadence/components/types';
import { useURL } from '@reverbdotcom/commons/src/url_context';
import FavoriteToast from '@reverbdotcom/commons/src/components/favorite_toast';
import { ButtonVariant } from '@reverbdotcom/cadence/components/RCButton/styling';
import { FOLLOW_PROMPT_PARAM } from '../shared/constants';
import { hasNoFiltersApplied } from './find_favorite_context';
import { ExecutionResult } from 'graphql';
import UserActionCallout from '../user_action_callout';

interface ExternalProps {
  shop: FavoriteShopCTAData.Fragment;
  initialFavoriteId: string | null;
  theme?: ButtonVariant;
  parentComponentName?: string;
  condensed?: boolean;
  noToastLink?: boolean;
  handleUpsertMutationResult?: () => void;
  showCallout?: boolean;
}
interface ApolloProps {
  upsertMyFavoriteShop: MutationFunction<UpsertMyFavoriteShop.Mutation, UpsertMyFavoriteShop.Variables>;
  deleteMyFavoriteShop: MutationFunction<DeleteMyFavoriteShop.Mutation, DeleteMyFavoriteShop.Variables>;
}

type IProps = ExternalProps & ApolloProps;

export const FavoriteShopCTAFragment = gql`
  fragment FavoriteShopCTAData on Shop {
    _id
    id
    name
    address {
      _id
      countryCode
    }
    user {
      _id
      feedbackSummary {
        rollingRatingPercentage
        receivedCount
      }
    }
  }
`;

export function FavoriteShopCTA(props: IProps) {
  const [loading, setLoading] = useState<boolean>(false);
  const [toastOpen, setToastOpen] = useState<boolean>(false);
  const [hasApiError, setHasApiError] = useState<boolean>(false);
  const [upsertFavoriteShop] = useHOCMutation(props.upsertMyFavoriteShop);
  const [deleteFavoriteShop] = useHOCMutation(props.deleteMyFavoriteShop);

  const initialFavorite = {
    id: props.initialFavoriteId || '',
    title: '',
    subtitle: '',
    feedEnabled: false,
    emailEnabled: false,
    queryParams: '',
    favorited: !!props.initialFavoriteId,
  };
  const [favorite, setFavorite] = useState<core_apimessages_Favorite>(initialFavorite);

  const url = useURL();
  const showToast = () => setToastOpen(true);
  const hideToast = () => setToastOpen(false);
  const favoriteEventProperties = {
    eventName: MParticleEventName.ClickedToastMessage,
    currentView: resolveCurrentView(url),
    favoriteType: resolveFavoriteType(url),
  };

  const handleToastClose = (closeType: ToastCloseType) => {
    hideToast();

    if (closeType === 'dismiss' || closeType === 'swipe') {
      trackEvent(favoriteEventProperties);
    }
  };

  const user = useUser();

  if (user.loggedOut) { return null; }

  const buildShopEventProps = () => {
    const starRating = getStarsCount(props.shop.user.feedbackSummary.rollingRatingPercentage / 100);

    return {
      id: props.shop.id,
      name: props.shop.name,
      country: props.shop.address.countryCode,
      rating: starRating.displayRating,
      parentComponentName: props.parentComponentName,
    };
  };

  const handleUndoFavoriteRemove = (result: ExecutionResult<UndoDeleteMyFavorite.Mutation>) => {
    setFavorite(result.data.undoDeleteMyFavorite?.favorite);
  };

  const shouldAutoFavoriteShop = () => {
    if (!url.query[FOLLOW_PROMPT_PARAM]) { return false; }
    if (favorite.id) { return false; }
    if (loading) { return false; }

    return hasNoFiltersApplied(url.query);
  };

  if (shouldAutoFavoriteShop()) {
    handleFavorite();
  }

  async function handleFavorite() {
    setLoading(true);
    const trackingEventName = favorite.favorited ? MParticleEventName.ClickedRemoveFavoriteShop : MParticleEventName.ClickedAddFavoriteShop;

    try {
      if (favorite.favorited) {
        const result = await deleteFavoriteShop({
          variables: {
            input: {
              id: favorite.id,
            },
          },
        });
        setFavorite(result.data.deleteMyFavorite.favorite);
      } else {
        const result = await upsertFavoriteShop({
          variables: {
            input: {
              feedEnabled: false,
              emailEnabled: false,
              shopId: parseInt(props.shop.id),
            },
          },
        });
        setFavorite(result.data.upsertMyFavorite.favorite);
        props.handleUpsertMutationResult?.();
      }

      trackEvent({
        eventName: trackingEventName,
        ...buildShopEventProps(),
      });
      setHasApiError(false);
      setLoading(false);
      showToast();
    } catch (e) {
      setHasApiError(true);
      showToast();
      elog.error(e.message, { componentName: 'FavoriteShopCTA' }, e);
    }
  }

  const classes = classNames(
    'favorite-shop-cta',
    {
      'favorite-shop-cta--favorited': favorite.favorited,
    },
  );

  function renderButton() {
    return ( props.condensed ?
      <RCFavoriteButton
        favorited={favorite.favorited}
        onClick={() => handleFavorite()}
        disabled={loading}
      />
      :
      <RCButton
        variant={props.theme || 'muted'}
        onClick={() => handleFavorite()}
        disabled={loading}
        fullWidth
      >
        <RCTextWithIcon
          svgComponent={favorite.favorited ? HeartFilledIcon : HeartEmptyIcon}
          placement="left"
        >
          {favorite.favorited ? I18n.t('discovery.favorites.cta.savedShop') : I18n.t('discovery.favorites.cta.saveShop')}
        </RCTextWithIcon>
      </RCButton>
    );
  }

  return (
    <span className={classes}>
      { props.showCallout &&
        <UserActionCallout
          anchorComponent={
            renderButton()
          }
          parentComponentName={props.parentComponentName}
          userActionName={core_apimessages_UserActionName.SAVE_SHOP_EDUCATION_CALLOUT}
          showCallout={!favorite.favorited}
        />
      }
      { !props.showCallout && renderButton() }
      <FavoriteToast
        favoriteId={favorite.id}
        isFavorited={favorite.favorited}
        favoriteType={core_apimessages_FavoriteType.FAVORITE_SHOP}
        isOpen={toastOpen}
        handleToastClose={handleToastClose}
        handleMutationResult={handleUndoFavoriteRemove}
        hasError={hasApiError}
        setHasError={setHasApiError}
        noToastLink={props.noToastLink}
      />
    </span>
  );
}

const withUpsertFavoriteShopMutation =
  withGraphql<ExternalProps, UpsertMyFavoriteShop.Mutation, UpsertMyFavoriteShop.Variables>(
    gql`
      mutation UpsertMyFavoriteShop($input: Input_core_apimessages_UpsertMyFavoriteRequest) {
        upsertMyFavorite(input: $input) {
          favorite {
            id
            favorited
            searchableId
            searchableType
            queryParams
          }
        }
      }
    `,
    {
      name: 'upsertMyFavoriteShop',
    },
  );

const withDeleteFavoriteShopMutation =
  withGraphql<ExternalProps, DeleteMyFavoriteShop.Mutation, DeleteMyFavoriteShop.Variables>(
    gql`
      mutation DeleteMyFavoriteShop($input: Input_core_apimessages_DeleteMyFavoriteRequest) {
        deleteMyFavorite(input: $input) {
          favorite {
            id
            favorited
          }
        }
      }
    `,
    {
      name: 'deleteMyFavoriteShop',
    },
  );

export default connect<ExternalProps>([
  withUpsertFavoriteShopMutation,
  withDeleteFavoriteShopMutation,
])(FavoriteShopCTA);
