import React from 'react';
import I18n from 'i18n-js';

import { RCAlertBox } from '@reverbdotcom/cadence/components';

import { DateRange, MAX_SELLER_INITIATED_REFUND } from './shop_refund_stats';

export interface IProps {
  dateRange: DateRange;
  count?: number;
}

export default function ShopRefundStatsAlert({
  dateRange,
  count,
}: IProps) {
  const [showAlert, setShowAlert] = React.useState(
    dateRange === DateRange.currentMonth &&
    count &&
    count >= MAX_SELLER_INITIATED_REFUND,
  );

  const isAtRefundLimit = count === MAX_SELLER_INITIATED_REFUND;

  const alertText = () => {
    if (isAtRefundLimit) {
      return I18n.t('discovery.dashboard.refundStats.alert.atRefundLimit');
    }

    return I18n.t('discovery.dashboard.refundStats.alert.exceededRefundLimit', { max: MAX_SELLER_INITIATED_REFUND });
  };

  if (showAlert) {
    return (
      <div className="shop-refund-stats__alert">
        <RCAlertBox
          type="warning"
          onDismiss={isAtRefundLimit ? () => setShowAlert(false) : undefined}
          small
        >
          <span className="shop-refund-stats__alert__content">
            {alertText()}
          </span>
        </RCAlertBox>
      </div>
    );
  }

  return null;
}
