import React from 'react';
import { Route, IndexRoute } from '@reverbdotcom/commons/src/route_settings';

const ShopRoutes = (
  <Route
    key="shop/:shop"
    path="shop/:shop"
    props={{ clientSide: true }}
    getComponent={(_location, cb) => {
      import(
        /* webpackChunkName: 'shop' */
        '../components/discovery/shops/shop_container'
      ).then(m => cb(null, m.default));
    }}
  >
    <IndexRoute
      getComponent={(_location, cb) => {
        import(
          /* webpackChunkName: 'shop' */
          '../components/marketplace/shop_page'
        ).then(m => cb(null, m.default));
      }}
    />
    <Route
      key="feedback"
      path="feedback"
      getComponent={(_location, cb) => {
        import(
          /* webpackChunkName: 'shop' */
          '../components/discovery/shops/shop_feedback'
        ).then(m => cb(null, m.default));
      }}
    />
    <Route
      key="about"
      path="about"
      getComponent={(_location, cb) => {
        import(
          /* webpackChunkName: 'shop' */
          '../components/discovery/shops/shop_info'
        ).then(m => cb(null, m.default));
      }}
    />
  </Route>
);

export {
  ShopRoutes,
};
