/* eslint-disable max-len */
// TS max-length check is disabled for this file since the vendor code is a one-liner.
import React from 'react';
import Helmet from 'react-helmet';
import { useAffirmEnvironment } from './affirmEnvironment';

/**
 * Initializes Affirm JS
 *
 * See Affirm's docs <https://docs.affirm.com/payments/docs/checkout-web> for setup
 */
export function AffirmScript() {
  const env = useAffirmEnvironment();

  return (
    <Helmet>
      <script type="text/javascript">
        {`
          var _affirm_config = {
            public_api_key: "${env.publicAPIKey}",
            script: "${env.scriptURL}",
            locale: "${env.locale}",
            country_code: "${env.countryCode}"
          };

          (function(m,g,n,d,a,e,h,c){var b=m[n]||{},k=document.createElement(e),p=document.getElementsByTagName(e)[0],l=function(a,b,c){return function(){a[b]._.push([c,arguments])}};b[d]=l(b,d,"set");var f=b[d];b[a]={};b[a]._=[];f._=[];b._=[];b[a][h]=l(b,a,h);b[c]=function(){b._.push([h,arguments])};a=0;for(c="set add save post open empty reset on off trigger ready setProduct".split(" ");a<c.length;a++)f[c[a]]=l(b,d,c[a]);a=0;for(c=["get","token","url","items"];a<c.length;a++)f[c[a]]=function(){};k.async=
            !0;k.src=g[e];p.parentNode.insertBefore(k,p);delete g[e];f(g);m[n]=b})(window,_affirm_config,"affirm","checkout","ui","script","ready","jsReady");
        `}
      </script>
    </Helmet>
  );
}
