import { ChildProps } from '@apollo/client/react/hoc';
// TODO update this to import from commons
// import { gql } from '@reverbdotcom/commons/src/gql';
// eslint-disable-next-line no-restricted-imports
import { gql } from '@apollo/client';
import FormGroupWithInput from '@reverbdotcom/commons/src/components/form_group_with_input';
import { withGraphql } from '@reverbdotcom/commons/src/with_graphql';
import { debounce } from 'lodash';
import React from 'react';
import {
  SEARCH_USERSQuery,
} from '@reverbdotcom/commons/src/gql/graphql';

export const SEARCH_USERS_QUERY = gql`
  query SEARCH_USERS(
    $input: Input_core_apimessages_AdminUserSearchRequest
  ) {
    userSearch(input: $input) {
      users {
        id
        email
        shopId
        shopName
      }
    }
  }
`;

export interface IProps {
  type: 'included' | 'excluded';
}

type User = SEARCH_USERSQuery['userSearch']['users'][0];

interface IAdminDisbursementShopSearchResultsDisplayProps {
  searchText: string;
  users: SEARCH_USERSQuery['userSearch']['users'];
  userSelected: (user: any) => void;
  type: 'included' | 'excluded';
}

type AdminDisbursementShopSearchResultsDisplayProps = ChildProps<IAdminDisbursementShopSearchResultsDisplayProps, SEARCH_USERSQuery>;

const withSearchUsersQuery = withGraphql<IAdminDisbursementShopSearchResultsDisplayProps, SEARCH_USERSQuery>(SEARCH_USERS_QUERY, {
  options: (ownProps) => {
    return {
      errorPolicy: 'all',
      ssr: false, // admin only
      variables: {
        input: {
          search: ownProps.searchText,
        },
      },
    };
  },
});

export default function AdminDisbursementShopFormFields({ type }: IProps) {
  const [searchText, setSearchText] = React.useState('');
  const [users, setUsers] = React.useState<User[]>([]);
  const [debouncedSearchText, setDebouncedSearchText] = React.useState(searchText);

  const onUpdateSearchText = (field) => {
    setSearchText(field.search);
  };

  const debounceSearchText = React.useCallback(debounce((newSearchText) => {
    setDebouncedSearchText(newSearchText);
  }, 500), []);

  const onUserSelected = (user: User) => {
    if (!user.shopId) {
      return;
    }
    setUsers(users.concat(user));
  };

  const removeUserFromList = (user: User) => {
    setUsers(users.filter((u) => u.id !== user.id));
  };

  React.useEffect(() => {
    debounceSearchText(searchText);
  }, [searchText, debounceSearchText]);

  return (
    <>
      <div className="g-container">
        <div className="g-col-6">
          <FormGroupWithInput
            label=""
            placeholder=""
            inputName="search"
            value={searchText}
            updateField={onUpdateSearchText}
          />
        </div>
      </div>
      {
        debouncedSearchText &&
        <div className="ptb-8">
          <AdminDisbursementShopSearchResults
            searchText={debouncedSearchText}
            userSelected={onUserSelected}
            users={users}
            type={type}
          />
        </div>
      }
      { users.length > 0 &&
        <>
          <h3>Users { type }</h3>
          <div className="styled-table">
            <table>
              <tbody>
                {users.map((user) => {
                  return (
                    <tr key={user.id}>
                      <td>
                        <span>{user.email} - {user.shopName}</span>
                        <input type="hidden" name={`${type}_shops[]`} value={user.shopId}></input>
                      </td>
                      <td>
                        <button
                          type="button"
                          className="button-as-link"
                          onClick={() => removeUserFromList(user)}
                        >
                          Remove user from {type} list
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </>
      }
    </>
  );
}

function AdminDisbursementShopSearchResultsDisplay({ users, userSelected, data, type }: AdminDisbursementShopSearchResultsDisplayProps) {
  if (data.loading) {
    return (<p>Loading...</p>);
  }

  const usersToDisplay = (data.userSearch?.users || []).filter(u => users.every(eu => eu.id !== u.id));

  if (!usersToDisplay.length) {
    return <p>No shops found.  Please search again!</p>;
  }

  return (
    <div className="styled-table">
      <table>
        <tbody>
          {usersToDisplay.map((user) => {
            return (
              <tr key={user.id}>
                <td>
                  {user.email} - {user.shopName}
                </td>
                <td>
                  <button
                    type="button"
                    className="button-as-link"
                    onClick={() => userSelected(user)}
                  >
                    { type === 'excluded' ? 'Exclude' : 'Include' } User
                  </button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

const AdminDisbursementShopSearchResults = withSearchUsersQuery(AdminDisbursementShopSearchResultsDisplay);
