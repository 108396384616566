import React from 'react';

import { RCAlertBox } from '@reverbdotcom/cadence/components';

import { core_apimessages_Error as Error } from './gql/graphql';
import { ErrorList } from './ErrorList';

interface Props {
  errors: Error[];
  small?: boolean;
}

export function ErrorAlertBox({
  errors,
  small = false,
}: Props) {
  return (
    <RCAlertBox type="error" small={small}>
      <ErrorList errors={errors} />
    </RCAlertBox>
  );
}
