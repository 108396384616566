import React from 'react';
import I18n from 'i18n-js';
import bind from '../bind';

import FormGroup from './form_group';

export interface ISelectOption {
  text: string;
  value: string;
  disabled?: boolean;
}

export interface ISelectOptionGroup {
  label?: string;
  key: string;
  options: ISelectOption[];
}

interface IProps {
  inputName: string;
  inputTitleValue?: string; // This is the title attribute for the input, not a label
  fieldName?: string;
  label: string | JSX.Element | React.ReactNode;
  value?: string;

  id?: string;
  required?: boolean;
  disabled?: boolean;
  autoFocus?: boolean;
  tagOptional?: boolean;
  tagRecommended?: boolean;
  tagRequired?: boolean;
  errorText?: string;
  helpText?: string | JSX.Element | React.ReactNode;

  updateField?: any;
  options?: ISelectOption[];
  optionGroups?: ISelectOptionGroup[];
  addEmptyOption?: boolean;
  emptyOptionText?: string;
  large?: boolean;
}

export default class FormGroupWithSelect extends React.Component<IProps, null> {
  @bind
  updateField(event) {
    if (!this.props.updateField) return;
    const attributes = {};
    const fieldToUpdate = this.props.fieldName || this.props.inputName;
    attributes[fieldToUpdate] = event.target.value;
    this.props.updateField(attributes);
  }

  renderSelectOption(v, k) {
    return (
      <option
        key={k}
        value={v.value}
        disabled={v.disabled}
      >
        {v.text}
      </option>
    );
  }

  valueIfControlled() {
    if (this.props.updateField) return { value: this.props.value };
  }

  renderOptions() {
    if (this.props.optionGroups) {
      return this.props.optionGroups.map((group) => {
        return (
          <optgroup label={group.label} key={group.key}>
            {group.options.map(this.renderSelectOption)}
          </optgroup>
        );
      });
    }

    return this.props.options.map(this.renderSelectOption);
  }

  renderSelect() {
    return (
      <div className="styled-dropdown mb-0">
        <select
          onChange={this.updateField}
          required={this.props.required}
          id={this.props.id || this.props.inputName}
          name={this.props.inputName}
          disabled={this.props.disabled}
          autoFocus={this.props.autoFocus}
          title={this.props.inputTitleValue}
          {...this.valueIfControlled()}
        >
          {this.props.addEmptyOption &&
            <option key="empty-option" disabled value="">
              {this.props.emptyOptionText || I18n.t('commons.selectFormGroup.selectOne')}
            </option>
          }
          {this.renderOptions()}
        </select>
      </div>
    );
  }

  render() {
    return (
      <FormGroup
        label={this.props.label}
        inputName={this.props.inputName}
        errorText={this.props.errorText}
        helpText={this.props.helpText}
        tagOptional={this.props.tagOptional}
        tagRecommended={this.props.tagRecommended}
        tagRequired={this.props.tagRequired}
        large={this.props.large}
      >
        {this.renderSelect()}
      </FormGroup>
    );
  }
}
