import React from 'react';
import { MParticleEventName } from '../elog/mparticle_tracker';
import { useViewTracking } from '../use_tracking';
import AppleSigninButton from './apple_signin_button';
import FacebookSigninButton from './facebook_signin_button';
import GoogleSigninButton from './google_signin_button';
import SigninSeparator from './signin_separator';
import { Variant } from './thirdPartyAuth';

interface Props {
  redirectTo: string;
  variant?: Variant;
}

export default function ThirdPartyAuthButtons({
  redirectTo,
  variant = 'sign-in',
}: Props) {
  useViewTracking({
    eventName: MParticleEventName.ViewedThirdPartyAuthButtons,
  }, true);

  return (
    <>
      <SigninSeparator />
      <div className="mt-2">
        <GoogleSigninButton
          variant={variant}
          redirectTo={redirectTo}
        />
      </div>
      <div className="mt-2">
        <AppleSigninButton
          variant={variant}
          redirectTo={redirectTo}
        />
      </div>
      <div className="mt-2">
        <FacebookSigninButton
          variant={variant}
          redirectTo={redirectTo}
        />
      </div>
    </>
  );
}
