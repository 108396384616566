import React from 'react';
import { I18N as Translate } from '@reverbdotcom/commons/src/components/translate';
import URLHelpers from './shared/url_helpers';

export default function CheckoutTermsAndConditions() {
  return (
    <div className="mtb-4 size-90 align-center">
      <Translate
        html
        text="discovery.checkout.termsAndConditions"
        args={{
          termsLinkOpen: `<a href="${URLHelpers.termsOfUsePath}" target="_blank" class="text-link">`,
          termsLinkClose: '</a>',
          privacyPolicyLinkOpen: `<a href="${URLHelpers.privacyPolicyPath}" target="_blank" class="text-link">`,
          privacyPolicyLinkClose: '</a>',
        }}
      />
    </div>
  );
}
