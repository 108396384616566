import { ChildProps } from '@apollo/client/react/hoc';
import { gql } from '@reverbdotcom/commons/src/gql';
import React from 'react';
import I18n from 'i18n-js';

import { withGraphql } from '@reverbdotcom/commons/src/with_graphql';
import { SearchSellFormLiveListingsTable } from '@reverbdotcom/commons/src/gql/graphql';
import { PRICE_GUIDE_TOOL_CONDITIONS } from '@reverbdotcom/commons/src/condition_helpers';

import PriceRecordsTable from './PriceRecordsTable';
import PriceRecordsEmptyState from './PriceRecordsEmptyState';

interface IExternalProps {
  canonicalProductIds: string[];
}

type IProps = ChildProps<IExternalProps, SearchSellFormLiveListingsTable.Query>;

const DEFAULT_OFFSET = 0;
export const PAGE_SIZE = 10;
interface IQueryVariableArgs {
  canonicalProductIds: string[];
  offset?: number;
}

export function queryVariables({
  canonicalProductIds,
  offset = DEFAULT_OFFSET,
}: IQueryVariableArgs) {
  return {
    canonicalProductIds,
    conditionSlugs: PRICE_GUIDE_TOOL_CONDITIONS,
    statuses: ['live'],
    itemRegion: 'US',
    limit: PAGE_SIZE,
    offset,
  };
}

export function convertListingsToPriceRecords(listings) {
  return listings.filter(listing => !!listing.pricing?.buyerPrice?.display && !!listing.publishedAt)
    .map(listing => ({
      timestamp: listing.publishedAt,
      conditionDisplayName: listing.condition.displayName,
      priceDisplay: listing.pricing?.buyerPrice?.display,
      id: listing._id,
    }));
}

export function LiveListingsTableContainer({
  canonicalProductIds,
  data,
}: IProps) {
  const {
    loading,
    listingsSearch = {},
    refetch,
  } = data ?? {};

  const {
    listings = [],
    total = 0,
    offset = 0,
  } = listingsSearch;

  const filteredPriceRecords = convertListingsToPriceRecords(listings);

  const showEmptyState = !loading && total === 0;
  const currentPage = offset / PAGE_SIZE;
  const totalPages = Math.floor(total / PAGE_SIZE);

  function onPageChange(pageChange: number) {
    return () => {
      const newPage = currentPage + pageChange;
      const newOffset = newPage * PAGE_SIZE;

      refetch({
        canonicalProductIds,
        offset: newOffset,
      });
    };
  }

  if (showEmptyState) {
    return (
      <PriceRecordsEmptyState
        text={I18n.t('discovery.sellForm.pricingSection.pricingGuidance.noLiveListings')}
      />
    );
  }

  return (
    <PriceRecordsTable
      priceRecords={filteredPriceRecords}
      onPageChange={onPageChange}
      currentPage={currentPage}
      totalPages={totalPages}
      dateHeader={I18n.t('discovery.sellForm.pricingSection.pricingTables.liveListings.dateHeader')}
      priceHeader={I18n.t('discovery.sellForm.pricingSection.pricingTables.liveListings.priceHeader')}
    />
  );
}

export const LIVE_LISTINGS_QUERY_DEFINITION = gql(`
  query Search_SellForm_LiveListingsTable(
    $canonicalProductIds: [String],
    $conditionSlugs: [String],
    $itemRegion: String,
    $limit: Int,
    $offset: Int
  ) {
    listingsSearch(
      input: {
        canonicalProductIds: $canonicalProductIds,
        conditionSlugs: $conditionSlugs,
        itemRegion: $itemRegion,
        statuses: ["live"],
        limit: $limit,
        offset: $offset
      }
    ) {
      total
      offset
      listings {
        _id
        publishedAt {
          seconds
        }
        condition {
          displayName
        }
        pricing {
          buyerPrice {
            display
          }
        }
      }
    }
  }
`);

const withQuery = withGraphql<IProps, SearchSellFormLiveListingsTable.Query>(
  LIVE_LISTINGS_QUERY_DEFINITION,
  {
    skip: ({ canonicalProductIds }) => !canonicalProductIds?.length,
    options: ({
      canonicalProductIds,
    }) => {
      return {
        ssr: false,
        fetchPolicy: 'no-cache',
        errorPolicy: 'ignore',
        variables: queryVariables({
          canonicalProductIds,
        }),
      };
    },
  },
);
export default withQuery(LiveListingsTableContainer);
