import React from 'react';
import I18n from 'i18n-js';

import { AppStoreBadge } from './app_store_badge';
import ReverbAppIllustration from '../../images/mini-app-illustration.svg';

interface IMobileAppAdProps {
  title: string;
  subtitle: string;
  appleStoreLink: string;
  googleStoreLink: string;
  locale: string;
}

export function MobileAppAd(props: IMobileAppAdProps) {
  return (
    <div className="mobile-app-ad">
      <img
        src={ReverbAppIllustration}
        alt={I18n.t('commons.mobileAppAd.appIllustrationAlt')}
        className="mobile-app-ad__illustration"
      />
      <div className="mobile-app-ad__content">
        <div className="mobile-app-ad__titleblock">
          <h4 className="mobile-app-ad__title">
            {props.title}
          </h4>
          <p className="mobile-app-ad__subtitle">
            {props.subtitle}
          </p>
        </div>
        <div className="mobile-app-ad__store-links">
          <a
            href={props.appleStoreLink}
            target="_blank"
            rel="noopener noreferrer"
            className="mobile-app-ad__store-link mobile-app-ad__store-link--apple"
          >
            <AppStoreBadge locale={props.locale} brand="apple" />
          </a>
          <a
            href={props.googleStoreLink}
            target="_blank"
            rel="noopener noreferrer"
            className="mobile-app-ad__store-link mobile-app-ad__store-link--google"
          >
            <AppStoreBadge locale={props.locale} brand="google" />
          </a>
        </div>
      </div>
    </div>
  );
}
