export default {
  vat: {
    title: 'VAT Identification Number',
    status_label: 'VAT Status',
    statuses: {
      pending_verification: 'Pending Verification',
      failed_verification: 'Failed Verification',
      verified: 'Verified',
    },
    countryCode: 'Country',
    vatId: 'VAT Identification Number',
    edit: 'Edit',
    addVat: 'Add a VAT Number',
    updateVat: 'Update VAT Number',
    save: 'Save',
    countryAlreadyUsed: 'You may only have one VAT Number per country.',
    vatIdExamples: 'Valid format(s) for %{countryName}: %{formats}.',
    vatIdAlphanumeric: 'Only letters and numbers.',
    vatEmptyStateMessage: 'Your shop may be required to have a VAT number depending on your country.',
    learnMore: 'Learn more.',
    saveFailed: 'Sorry, your VAT Number should match the following format(s):',
    deleteFailed: 'Sorry, your VAT Number could not be deleted. Please try again.',
    vatInfoTitle: 'Questions about adding a VAT ID?',
    vatInfoDescription: '{{children}} for more information.',
    visitHelpCenter: 'Visit our Help Center',
    euVatOnFeesDescription: 'Reverb began assessing VAT on fees for sellers in the EU on 5 June',
    ukVatOnFeesDescription: 'Reverb began assessing VAT on fees for sellers in the UK on 6 September',
    addVatIdPrompt: "Add your VAT ID to help ensure you're not charged.",
  },
  australianGst: {
    title: 'Australian GST Registration Number',
    auGstOnFeesDescription: 'Reverb began assessing GST on fees for sellers in Australia on 6 September',
    addGstIdPrompt: "Add your GST ID to help ensure you're not charged. ",
    learnMore: 'Learn more.',
    registrationNumber: 'Registration Number',
    delete: 'Delete',
    registrationNumberHelpText: 'Valid format (ABN): 99999999999.',
    cancel: 'Cancel',
    submit: 'Submit',
    saveFailed: 'Unable to save your registration number. Please ensure it matches the accepted format and try again.',
    deleteFailed: 'Unable to delete your registration number. Please try again.',
    deleteSucceeded: 'Your registration number has been deleted.',
    saveSucceeded: 'Your registration number has been saved.',
  },
  onboardingBillingMethodLink: {
    completeBillingMethod: 'Complete Billing Method',
  },
  offerPolicy: {
    avoidLowballOffersLabel: 'Avoid receiving lowball offers',
    avoidLowballOffersSublabel: "Define what lowball means to you by entering a lowball percentage. Buyers won't be able to send you offers at or below this point.",
    lowballPercentageLabel: 'Lowball Percentage',
    lowballPercentageHelpText: 'Must be between %{minimum}% and %{maximum}%.',
    lowballPercentageError: 'Enter a percentage between %{minimum}% and %{maximum}%',
  },
  offerBotPolicy: {
    toggleTitle: 'Automatically accept or decline Offers from buyers',
    description: 'The Offer-Bot will only auto-respond to the fist offer recevied from a buyer. It will not respond if a buyer counters one of your offers or to offers that include a message.',
    conditions: {
      title: 'Which items do you want to have automatic Offer responses?',
      description: 'All listings with the selected conditions will apply.',
      brandNewOption: 'Brand New items',
      bStockOption: 'B-Stock items',
      usedOption: 'Items in all Used conditions',
    },
    priceBoundaries: {
      title: 'Set price boundaries for automatic Offer responses',
      description: 'Automatically accept or decline offers for listings within the following price boundaries.',
      max: {
        title: 'Highest price',
      },
      min: {
        title: 'Lowest price',
        conflictError: 'Lowest price must be less than highest price',
      },
    },
    autoAccept: {
      label: 'Auto-acceptance percentage',
      conflictError: 'Enter a percentage that is more than your auto-accept percentage',
      title: "What offer percentage of these listings' price are you willing to automatically accept?",
      example: "Example: if you auto-accept offers that are 90% of the listing's price, you'll auto-accept $90 for a $100 item.",
    },
    autoDecline: {
      label: 'Lowball percentage',
      conflictError: 'Enter a percentage that is more than your auto-accept percentage',
      title: 'Decline offers below a lowball threshold.',
      description: "Enter a lowball percentage. Buyers won't be able to send offers below this percentage of the listing's price.",
    },
    messages: {
      title: 'Messages',
      checkboxLabel: 'Include messages',
      optional: 'Optional',
      alertTitle: 'If you leave any messages blank, we\'ll send Reverb\'s default messages.',
      default: {
        accept: {
          label: 'Accept:',
          message: 'Thanks, you got it!',
        },
        counter: {
          label: 'Counter:',
          message: 'How about this?',
        },
        reject: {
          label: 'Reject:',
          message: 'The seller has chosen to decline offers below a certain threshold, and your offer was too low.',
        },
      },
      input: {
        accept: 'Acceptance message',
        counter: 'Counter message',
        reject: 'Rejection message',
      },
    },
    thresholdsError: 'Enter a percentage between %{minimum}% and %{maximum}%',
    counterAndLowballTitle: 'Counter Offers and Lowball Offers',
    haggleProtectionLabel: 'Avoid haggling. Accept a buyer\'s offer when it\'s within 2% of my own counter offer.',
    autoCounterCheckboxLabel: 'Automatically counter any new offer one time, using my auto-acceptance percentage.',
  },
  userManagement: {
    title: 'User Management',
    description: 'Do multiple people run your shop? Grant them access, manage access levels, and review their activity.',
    activityLogs: {
      title: 'Activity Logs',
      description: "An email with a link to the CSV file will be sent to the primary shop owner's email address.",
      noDataAvailable: 'No shop user activity available.',
      exportButton: 'Export Activity Logs CSV',
      exportStarted: "Your export is being processed. We'll email you when it's ready.",
      exportFailed: 'Sorry, there was an error.',
    },
    userAccess: {
      title: 'User Access',
      description: "Review, add, and remove your shop's users. Users must have an active Reverb account and the same email domain as the shop owner.",
      permissionLevels: {
        shopOwner: {
          label: 'Shop Owner',
          description: 'That\'s you! You can do any shop activity, and manage users.',
        },
        administrators: {
          label: 'Administrator',
          labelUsers: 'Administrator users',
        },
        managers: {
          label: 'Manager',
          labelUsers: 'Manager users',
        },
      },
      columnHeaders: {
        level: 'Level',
        email: 'Email',
        name: 'Name',
      },
      noUsers: 'No additional users have access to your shop.',
      form: {
        email: 'Email',
        accessLevel: {
          title: 'Access Level',
          alertHeader: '%{permissionLevel} users can',
          manageListings: 'Manage listings, offers, messages and orders',
          manageShopPolicies: 'Manage shop policies and financial information',
        },
      },
      create: {
        buttonTitle: 'Add User',
        modalTitle: 'Add a user',
        modalDescription: 'Grant an employee access to your shop.',
        success: '%{email} has access to your shop.',
      },
      update: {
        buttonTitle: 'Update',
        modalTitle: 'Update User Access Level',
        modalDescription: 'Change the level of access for <strong>%{email}</strong>.',
        success: '%{email}\'s access level has been updated.',
      },
      delete: {
        buttonTitle: 'Delete',
        modalTitle: 'Remove User',
        modalContent: 'If <strong>%{email}</strong> no longer needs access to your Reverb shop, remove them.',
        success: '%{email} no longer has access to your shop.',
        error: 'Sorry, there was an error.',
      },
    },
  },
};
