import React from 'react';
import ErrorDisplay from '../components/discovery/error_display';

const BillingMethodFormError: React.StatelessComponent<{}> = () => {
  return (
    <div className="site-wrapper site-wrapper--narrow ptb-4">
      <ErrorDisplay />
    </div>
  );
};

export default BillingMethodFormError;
