import React from 'react';

import SanitizedRender from '../../components/sanitized_render';

export interface IProps {
  text: string;
  textHtml?: string;
  markdownParser?: any;
  className?: string;
  htmlTag?: string;
  truncationHeight?: number;

  // passes through to SanitizedRender only
  blocklistedTags?: string[];
  inlineTagsOnly?: boolean;
}

// this class allows us to conditionally parse Markdown
// because some components are used by LP and have Markdown
// text stored on them. Once we migrate LP pages to the core
// CMS and backfill away the Markdown we can drop this class

export default class MarkdownOutput extends React.Component<IProps, null> {
  render() {
    const Parser = this.props.markdownParser;

    if (Parser) {
      if (!this.props.text) return null;
      return (
        <Parser
          className={this.props.className}
          text={this.props.text}
        />
      );
    }

    if (!this.props.text && !this.props.textHtml) return null;

    return (
      <SanitizedRender
        className={this.props.className}
        html={this.props.textHtml || this.props.text}
        htmlTag={this.props.htmlTag}
        truncationHeight={this.props.truncationHeight}
        inlineTagsOnly={this.props.inlineTagsOnly}
        blocklistedTags={this.props.blocklistedTags}
      />
    );
  }
}
