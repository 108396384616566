import React from 'react';

export interface ISocialLink {
  icon: string;
  url: string;
}

interface IProps {
  links: ISocialLink[];
}

export default class SocialLinks extends React.Component<IProps, null> {
  renderSocialLink(link, idx) {
    return (
      <li
        key={idx}
        className="social-links__link"
      >
        <a
          href={link.url}
          target="_blank" rel="noopener noreferrer"
        >
          <span className={`fa fa-${link.icon}`} />
        </a>
      </li>
    );
  }

  render() {
    return (
      <div className="social-links">
        {this.props.links.map(this.renderSocialLink)}
      </div>
    );
  }
}
