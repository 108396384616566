import React from 'react';
import I18n from 'i18n-js';

import { Listing } from '@reverbdotcom/commons/src/gql/graphql';
import { RCChip, RCMenu, useMediaQuery } from '@reverbdotcom/cadence/components';

import BulkCreateBumpModal from './BulkCreateBumpModal';
import BulkDeleteBumpModal from './BulkDeleteBumpModal';
import { useViewTracking } from '@reverbdotcom/commons/src/use_tracking';
import { MParticleEventName } from '@reverbdotcom/commons/src/elog/mparticle_types';

export const COMPONENT_NAME = 'BulkBumpButton';

interface IExternalProps {
  selectedListings: Listing[];
}

export enum BulkBumpModals {
  CREATE = 'create',
  DELETE = 'delete',
  NONE = 'none',
}

export default function BulkBumpButton({
  selectedListings,
}: IExternalProps) {
  const isMobile = useMediaQuery('mobile');
  const chipSize = isMobile ? 'small' : 'mini';

  const hasSelectedListings = !!selectedListings.length;

  const [
    openModal,
    setOpenModal,
  ] = React.useState<BulkBumpModals>(BulkBumpModals.NONE);

  useViewTracking({
    componentName: COMPONENT_NAME,
    eventName: MParticleEventName.ComponentView,
  }, true);

  return (
    <>
      <RCMenu
        anchor={
          <RCChip
            size={chipSize}
            onClick={() => {
            }}
          >
            {I18n.t('discovery.listingsManagement.toolbar.bulkActions.bump.chipText')}
          </RCChip>
        }
      >
        <RCMenu.Item
          key={I18n.t('discovery.listingsManagement.toolbar.bulkActions.bump.bumpListings')}
          disabled={!hasSelectedListings}
          onClick={() => setOpenModal(BulkBumpModals.CREATE)}
        >
          {I18n.t('discovery.listingsManagement.toolbar.bulkActions.bump.bumpListings')}
        </RCMenu.Item>

        <RCMenu.Item
          key={I18n.t('discovery.listingsManagement.toolbar.bulkActions.bump.removeBump')}
          disabled={!hasSelectedListings}
          onClick={() => setOpenModal(BulkBumpModals.DELETE)}
        >
          {I18n.t('discovery.listingsManagement.toolbar.bulkActions.bump.removeBump')}
        </RCMenu.Item>
      </RCMenu>

      {openModal == BulkBumpModals.CREATE && (
        <BulkCreateBumpModal
          closeModal={() => setOpenModal(BulkBumpModals.NONE)}
          selectedListings={selectedListings}
        />
      )}

      {openModal == BulkBumpModals.DELETE && (
        <BulkDeleteBumpModal
          closeModal={() => setOpenModal(BulkBumpModals.NONE)}
          selectedListings={selectedListings}
        />
      )}
    </>
  );
}
