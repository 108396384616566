import I18n from 'i18n-js';
import React from 'react';
import classNames from 'classnames';
import { withRouter, WithRouterProps } from 'react-router';
import track from 'react-tracking';

import CoreLink from './core_link';
import { MParticleEventName } from '../elog/mparticle_types';
import { RCTextWithIcon } from '@reverbdotcom/cadence/components';
import AngleRightIcon from '@reverbdotcom/cadence/icons/svgs/angle-right.svg?component';
import AngleLeftIcon from '@reverbdotcom/cadence/icons/svgs/angle-left.svg?component';
import PaginationControls from './pagination_controls';

type TrackingDisplayStyle =
  'Item2FeedbackList' |
  'Item2CspReviewsSection' |
  'ProductReviewsLayout';

interface IProps extends WithRouterProps {
  limit: number;
  offset: number;
  total: number;
  paginationWindow?: number;
  anchor?: string;
  pageParam?: string;
  replaceHistory?: boolean;
  additionalParam?: string;
  additionalParamValue?: string;
  trackingData?: { displayStyle: TrackingDisplayStyle }
}

const COMPONENT_NAME = 'RQLPagination';

@track({ componentName: COMPONENT_NAME })
export class RQLPagination extends React.Component<IProps, null> {
  static defaultProps = {
    limit: 0,
    offset: 0,
    total: 0,
    paginationWindow: 2,
    pageParam: 'page',
    replaceHistory: false,
  };

  get currentPage() {
    const page = Math.ceil(this.props.offset / this.props.limit) + 1;

    return page < 1 ? 1 : page;
  }

  get totalPages() {
    return Math.ceil(this.props.total / this.props.limit);
  }

  validPage(page) {
    return page !== this.currentPage;
  }

  getMParticleData(pageNum: number) {
    if (!this.props.trackingData) { return null; }

    return {
      componentName: COMPONENT_NAME,
      position: pageNum,
      displayStyle: this.props.trackingData.displayStyle,
      eventName: MParticleEventName.ClickedPagination,
    };
  }

  renderPageNumberLink(pageNum) {
    const classes = classNames(
      'pagination__button',
      'pagination__button--number',
      { 'pagination__button--active': pageNum === this.currentPage },
    );

    return (
      <CoreLink
        className={classes}
        key={pageNum}
        to={{
          query: {
            ...this.props?.location?.query,
            [this.props.pageParam]: pageNum,
            [this.props.additionalParam]: this.props.additionalParamValue,
          },
          anchor: this.props.anchor,
        }}
        clickEvent={this.getMParticleData(pageNum)}
        rel="nofollow"
        replace={this.props.replaceHistory}
      >
        {pageNum}
      </CoreLink>
    );
  }

  renderPreviousButton() {
    if (this.currentPage === 1) { return null; }

    const newPage = this.currentPage - 1;
    return (
      <CoreLink
        className="pagination__button"
        to={{
          query: {
            ...this.props?.location?.query,
            [this.props.pageParam]: newPage,
          },
          anchor: this.props.anchor,
        }}
        rel="nofollow"
        replace={this.props.replaceHistory}
        clickEvent={this.getMParticleData(newPage)}
      >
        <RCTextWithIcon
          svgComponent={AngleLeftIcon}
          placement="left"
        >
          {I18n.t('commons.previous')}
        </RCTextWithIcon>
      </CoreLink>
    );
  }

  renderNextButton() {
    if (this.currentPage === this.totalPages) { return null; }

    const newPage = this.currentPage + 1;
    return (
      <CoreLink
        className="pagination__button"
        to={{
          query: {
            ...this.props?.location?.query,
            [this.props.pageParam]: newPage,
          },
          anchor: this.props.anchor,
        }}
        replace={this.props.replaceHistory}
        rel="nofollow"
        clickEvent={this.getMParticleData(newPage)}
      >
        <RCTextWithIcon
          svgComponent={AngleRightIcon}
        >
          {I18n.t('commons.next')}
        </RCTextWithIcon>
      </CoreLink>
    );
  }

  render() {
    return (
      <PaginationControls
        limit={this.props.limit}
        offset={this.props.offset}
        total={this.props.total}
        previousButton={this.renderPreviousButton()}
        nextButton={this.renderNextButton()}
        renderPageNumberLink={(pageNum) => { return this.renderPageNumberLink(pageNum); }}
      />
    );
  }
}

export default withRouter(RQLPagination);
