import PropTypes from 'prop-types';
import React from 'react';
import RelatedArticles from '../shared/related_articles';
import BumpRetargeting from './bump_retargeting';
import SimilarListings from './similar_listings';
import UrlHelpers from '../shared/url_helpers';
import { APIRequest } from '../index';

class RelatedContent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      articles: [],
    };
  }

  componentDidMount() {
    this.fetchArticles();
  }

  fetchArticles() {
    const url = UrlHelpers.listingRelatedArticlesPath(this.props.listingId);
    APIRequest.V3.get(url).then((response) => {
      this.setState({ articles: response.articles });
    });
  }

  render() {
    return (
      <div>
        <RelatedArticles articles={this.state.articles} />
        <BumpRetargeting listingId={this.props.listingId} />
        <SimilarListings
          listingId={this.props.listingId}
        />
      </div>
    );
  }
}

RelatedContent.propTypes = {
  listingId: PropTypes.string.isRequired,
};

export default RelatedContent;
