import React, { useCallback } from 'react';
import { gql } from '@reverbdotcom/commons/src/gql';
import I18n from 'i18n-js';
import { core_apimessages_ListingDigitalVariant } from '@reverbdotcom/commons/src/gql/graphql';
import { RCButton, RCIconButton, RCMenu, RCToast } from '@reverbdotcom/cadence/components';
import { CaretDownIcon } from '@reverbdotcom/cadence/icons/react';
import { APIRequest } from '../index';
import { useUser } from '@reverbdotcom/commons/src/user_hooks';
import SignupSigninModal from '@reverbdotcom/commons/src/components/signup_signin_modal';
import LocationUtil from '../components/shared/location_util';
import {
  hasUniversalOperatingSystemSupport,
  DownloadButtonIcon,
  variantMatchingOperatingSystem,
  operatingSystemLabel,
} from './download_button_helpers';

export const DigitalDetailsFragment = gql(`
  fragment DigitalDetailsFields on Listing {
    _id
    id
    listingType
    digitalDetails {
      variants {
        id
        downloadText
        fileUrl
        fileSize
        operatingSystems
        fileTypes
      }
      supportedFormats {
        operatingSystem
        fileTypes
      }
    }
  }
`);

export interface IProps {
  variants: core_apimessages_ListingDigitalVariant[];
  isFree?: boolean;
}

export function DownloadUnavailableButton() {
  return (
    <RCButton
      variant="filled"
      theme="loud"
      size="large"
      disabled
      fullWidth
    >
      {I18n.t('digitalListing.noFilesFound')}
    </RCButton>
  );
}

export function DownloadLinkWithOptions({ variants, handleDownloadClick, disabled, selectedVariant, setSelectedVariant, isFree }) {
  return (
    <div className="download-button_menu">
      <div className="download-button_button">
        <DownloadLink
          variants={variants}
          onClick={handleDownloadClick}
          disabled={disabled}
          selectedVariant={selectedVariant}
          isFree={isFree}
        />
      </div>
      <div className="download-button_dropdown-content">
        <RCMenu
          anchor={
            <RCIconButton
              variant="filled"
              theme="loud"
              size="large"
              label={I18n.t('digitalListing.supportedFormats')}
              svgComponent={CaretDownIcon}
              disabled={disabled}
            />
          }
        >
          {variants.map((variant) => {
            return (
              <RCMenu.Item
                key={`${variant?.id}-${operatingSystemLabel(variant)}`}
                onClick={() => setSelectedVariant(variant)}
              >
                <div className="size-90 weight-bold">
                  <DownloadButtonIcon variant={variant} variants={variants} />
                  <span className="padding-1">{operatingSystemLabel(variant)}</span>
                  {variant.fileSize && <span>({variant.fileSize})</span>}
                </div>
                <div className="gray-500 size-80">
                  {variant.fileTypes?.join(I18n.t('js.listSeparator'))}
                </div>
              </RCMenu.Item>
            );
          })}
        </RCMenu>
      </div>
    </div>
  );
}

export function DownloadLink({ onClick, disabled, variants, selectedVariant, isFree }) {
  const downloadText = () => {
    if (hasUniversalOperatingSystemSupport(variants)) {
      return isFree ? I18n.t('digitalListing.freeDownloadAction') : I18n.t('digitalListing.paidDownloadAction');
    } else {
      return isFree ? I18n.t('digitalListing.freeDownloadActionFor', { operatingSystem: operatingSystemLabel(selectedVariant) })
        : I18n.t('digitalListing.paidDownloadActionFor', { operatingSystem: operatingSystemLabel(selectedVariant) });
    }
  };

  return (
    <RCButton
      variant="filled"
      theme="loud"
      size="large"
      onClick={onClick}
      disabled={disabled}
      fullWidth
    >
      <DownloadButtonIcon variant={selectedVariant} variants={variants} />
      <span className="download-button_text">
        {selectedVariant?.downloadText || downloadText()}
      </span>
    </RCButton>
  );
}

export default function DownloadButton(props: IProps) {
  const defaultVariant = variantMatchingOperatingSystem(props.variants) || props.variants[0];
  const [selectedVariant, setSelectedVariant] = React.useState(null);
  const [downloaded, setDownloaded] = React.useState(false);
  const [showSuccess, setShowSuccess] = React.useState(false);
  const [showError, setShowError] = React.useState(false);
  const [modalOpen, setModalOpen] = React.useState(false);
  const user = useUser();

  React.useEffect(() => {
    setSelectedVariant(defaultVariant);
  }, [defaultVariant]);

  const download = useCallback(() => {
    APIRequest.get(selectedVariant?.fileUrl).then((response) => {
      if (response.error) {
        setShowError(true);
      } else {
        window.open(response.url, '_blank');
        setShowSuccess(true);
        setDownloaded(true);
      }
    }).fail(() => {
      setShowSuccess(false);
      setShowError(true);
    });
  }, [selectedVariant?.fileUrl]);

  const handleDownloadClick = useCallback(() => {
    if (user.loggedOut) {
      setModalOpen(true);
    } else {
      download();
    }
  }, [user?.loggedOut, download]);


  if (!selectedVariant) { return <DownloadUnavailableButton />; }

  return (
    <>
      {hasUniversalOperatingSystemSupport(props.variants) ?
        <div className="d-flex">
          <DownloadLink
            variants={props.variants}
            onClick={handleDownloadClick}
            disabled={downloaded}
            selectedVariant={selectedVariant}
            isFree={props.isFree}
          />
        </div>
        :
        <DownloadLinkWithOptions
          variants={props.variants}
          handleDownloadClick={handleDownloadClick}
          disabled={downloaded}
          selectedVariant={selectedVariant}
          setSelectedVariant={setSelectedVariant}
          isFree={props.isFree}
        />
      }
      <SignupSigninModal
        onRequestClose={() => setModalOpen(false)}
        active={modalOpen}
        message={I18n.t('discovery.searchSignup.fullLoginMessage')}
        redirectTo={LocationUtil.currentPageWithParams({})}
        trackSource="DigitalDownload"
      />
      <RCToast isOpen={showSuccess} onClose={() => setShowSuccess(false)} text={I18n.t('digitalListing.downloadSuccess')} theme="success" />
      <RCToast isOpen={showError} onClose={() => setShowError(false)} text={I18n.t('digitalListing.downloadError')} theme="error" />
    </>
  );
}
