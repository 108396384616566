import React from 'react';
import ProductSearchForm from '../product_search_form/product_search_form';
import bind from '@reverbdotcom/commons/src/bind';

interface IProps {
  onProductSelected: (product: any) => any;
  currentListingIds: string[];
}

interface IState {
  results: string[];
  inputValue: string;
}

export default class ProductSuggestion extends React.Component<IProps, IState> {
  state = {
    results: [],
    inputValue: '',
  };

  @bind
  handleProductSelected(product) {
    this.props.onProductSelected(product);

    // hide the search results
    this.setState({
      results: [],
      inputValue: '',
    });
  }

  @bind
  handleChange(e) {
    this.setState({ inputValue: e.target.value });
  }

  render() {
    return (
      <ProductSearchForm
        productSelected={this.handleProductSelected}
        currentListingIds={this.props.currentListingIds}
        results={this.state.results}
        inputValue={this.state.inputValue}
        onChange={this.handleChange}
      />
    );
  }
}
