import React from 'react';
import I18n from 'i18n-js';
import { core_apimessages_CreditCard } from '@reverbdotcom/commons/src/gql/graphql';
import CreditCardIcon from '../credit_cards/legacy/credit_card_icon';
import bind from '@reverbdotcom/commons/src/bind';

interface IProps {
  creditCards: core_apimessages_CreditCard[];
  handleEditClick: Function;
  selectedCreditCardId: string;
  setSelectedCreditCardId: (id: string) => void;
  showEditURL?: boolean;
}

function renderCardSummary(creditCard) {
  const cardAbout = `${creditCard.cardType} ....${creditCard.last4}`;
  const expiryDate = `${creditCard.expirationMonth}/${creditCard.expirationYear}`;
  return (
    <div className="cc-details__card-info">
      <div className="cc-details__card-type">
        {cardAbout}
      </div>
      <div className="cc-details__expiration">
        {I18n.t('discovery.selectableCreditCardList.expiresOn', { date: expiryDate })}
      </div>
    </div>
  );
}

class SelectableCreditCardList extends React.Component<IProps, null> {
  get topCards() {
    return this.props.creditCards.slice(0, 5);
  }

  renderSelectableCards() {
    return this.topCards.map((creditCard, index) => {
      return (
        this.renderSelectableCard(creditCard, index)
      );
    });
  }

  isChecked(creditCard, index) {
    if (this.props.selectedCreditCardId) {
      return creditCard.id === this.props.selectedCreditCardId;
    }
    return index === 0;
  }

  @bind
  handleEditClick(cardId) {
    this.props.handleEditClick(cardId);
  }

  @bind
  updateSelectedCreditCardId(id) {
    this.props.setSelectedCreditCardId(id);
  }

  renderEditLink(cardId) {
    if (!this.props.showEditURL) {
      return null;
    }

    return (
      <div>
        <button
          className="weight-bold f-right button-as-link"
          type="button"
          onClick={() => { this.handleEditClick(cardId); }}
        >
          {I18n.t('discovery.selectableCreditCardList.edit')}
        </button>
      </div>
    );
  }

  renderSelectableCard(creditCard, index) {
    return (
      /* disabling inline here so we can enable this rule globally, but this should be fixed the next time this file is touched */
      /* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */
      (<li
        className="shop-onboarding__card"
        key={creditCard.id}
        id={`credit_card_${creditCard.id}`}
        onClick={() => this.updateSelectedCreditCardId(creditCard.id)}
      >
        <input
          className="boxed-list__toggle"
          type="radio"
          checked={this.isChecked(creditCard, index)}
          id={`credit_card_${creditCard.id}`}
          readOnly
        />
        <div
          className="boxed-list__item boxed-list__item--selectable"
        >
          <div className="cc-details">
            <div className="cc-details__card">
              <div className="cc-details__icon">
                <CreditCardIcon
                  cardType={creditCard.cardType}
                />
              </div>
              {renderCardSummary(creditCard)}
            </div>
            {this.renderEditLink(creditCard.id)}
          </div>
        </div>
      </li>)
    );
  }

  render() {
    return (
      <div>
        <ul className="shop-onboarding__cards">
          {this.renderSelectableCards()}
        </ul>
        {this.props.children}
      </div>
    );
  }
}

export default SelectableCreditCardList;
