import { ChildProps } from '@apollo/client/react/hoc';
import { MutationFunction } from '@reverbdotcom/commons/src/useHOCMutation';
// TODO update this to import from commons
// import { gql } from '@reverbdotcom/commons/src/gql';
// eslint-disable-next-line no-restricted-imports
import { gql } from '@apollo/client';
import React from 'react';
import { get } from 'lodash';
import { RCLoadingBars, RCAlertBox } from '@reverbdotcom/cadence/components';
import { RouteComponentProps } from 'react-router';
import NotFound from '@reverbdotcom/commons/src/components/not_found';
import UniversalPromoCampaignForm from './universal_promo_campaign_form';
import {
  CoreUpdateUniversalPromoCampaign,
  CoreUniversalPromoCampaign,
  CoreExchangeRates,
  core_apimessages_Error,
} from '@reverbdotcom/commons/src/gql/graphql';
import { withGraphql } from '@reverbdotcom/commons/src/with_graphql';
import { connect, EmptyExternalProps } from '@reverbdotcom/commons/src/connect';
import { EXCHANGE_RATES_QUERY } from '@reverbdotcom/commons/src/convert_currency';

interface MutationProps {
  updateUniversalPromoCampaign: MutationFunction<CoreUpdateUniversalPromoCampaign.Mutation, CoreUpdateUniversalPromoCampaign.Variables>;
}

type RouteProps = RouteComponentProps<{ campaignId: string }, {}>;

type IProps = ChildProps<CoreUniversalPromoCampaign.Query> & MutationProps & RouteProps;

export function UniversalPromoCampaignFormContainer({ data, updateUniversalPromoCampaign }: IProps) {
  const [wasSuccess, setWasSuccess] = React.useState(false);
  const [errors, setErrors] = React.useState<core_apimessages_Error[]>([]);

  const campaign = get(data, 'universalpromocampaign');
  const exchangeRates = get(data, 'exchangeRates');

  if (data?.loading) {
    return (
      <div className="d-flex fx-align-center fx-justify-center height-vh-50">
        <RCLoadingBars size="large" />
      </div>
    );
  }

  if (data?.error) {
    return <NotFound />;
  }

  return (
    <>
      <div className="mb-4">
        {!!errors.length && (
          <RCAlertBox type="error">
            {errors.map((error) => {
              return (
                <li key={error.field + error.message}>
                  {error.message}
                </li>
              );
            })}
          </RCAlertBox>
        )}
        {wasSuccess && (
          <RCAlertBox type="success">
            Saved!
          </RCAlertBox>
        )}
      </div>
      <UniversalPromoCampaignForm
        campaign={campaign}
        updateUniversalPromoCampaign={updateUniversalPromoCampaign}
        exchangeRates={exchangeRates?.rates}
        setWasSuccess={setWasSuccess}
        setErrors={setErrors}
      />
    </>
  );
}

const updateUniversalPromoCampaignMutation = withGraphql<any, CoreUpdateUniversalPromoCampaign.Mutation, CoreUpdateUniversalPromoCampaign.Variables>(
  gql`
    mutation Core_Update_Universal_Promo_Campaign(
      $input: Input_core_apimessages_UpdateUniversalPromoCampaignRequest
    )
    {
      updateUniversalPromoCampaign(input: $input) {
        universalPromoCampaign {
          id
        }
      }
    }
  `,
  {
    name: 'updateUniversalPromoCampaign',
  },
);

const universalPromoCampaignQuery = withGraphql<IProps, CoreUniversalPromoCampaign.Query>(
  gql`
    query Core_Universal_Promo_Campaign($campaignId: String) {
      universalpromocampaign(input: {id: $campaignId}) {
        _id
        id
        name
        code
        expiresAt
        marketingChannel
        newBuyer
        lapsedBuyer
        lapsedBuyerLastPurchaseDate
        categories {
          id
        }
        redemptionLimit
        hasRedemptionLimit
        universalPromoCurrencies {
          amount {
            currency
            amount
          }
          minPurchaseAmount {
            currency
            amount
          }
        }
      }
    }
`,
  {
    skip: (ownProps) => !ownProps.params.campaignId,
    options: (ownProps) => {
      return {
        variables: {
          campaignId: ownProps.params.campaignId,
        },
      };
    },
  });

const exchangeRatesQuery = withGraphql<IProps, CoreExchangeRates.Query>(EXCHANGE_RATES_QUERY);

export default connect<EmptyExternalProps, IProps>([
  exchangeRatesQuery,
  universalPromoCampaignQuery,
  updateUniversalPromoCampaignMutation,
])(UniversalPromoCampaignFormContainer);
