import { core_apimessages_Money as Money } from '@reverbdotcom/commons/src/gql/graphql';
import { Location } from 'history';
import RouterHistory from '@reverbdotcom/commons/src/cms/lib/router_history';
import { Paths } from '../components/shared/url_helpers';

export const CHECKOUT_ERROR_KEY = 'show_checkout_error';

export function buildCheckoutPaths(checkoutId: string, location: Location = RouterHistory.getCurrentLocation()) {
  const { _reverb_app } = location.query;

  function show({ showCheckoutError }: { showCheckoutError?: boolean } = {}) {
    return Paths.checkoutShow.expand({
      checkoutId,
      _reverb_app,
      [CHECKOUT_ERROR_KEY]: showCheckoutError,
    });
  }

  function review({ token }) {
    return Paths.checkoutShow.expand({
      checkoutId,
      _reverb_app,
      step: 'review',
      token,
    });
  }

  function paymentInProgress() {
    return Paths.checkoutPaymentInProgress.expand({ checkoutId, _reverb_app });
  }

  function redirectReturn({ displayedAmountOwed }: { displayedAmountOwed: Pick<Money, 'amountCents' | 'currency'> }) {
    return Paths.checkoutRedirectReturn.expand({
      checkoutId,
      _reverb_app,
      displayed_amount_owed_cents: displayedAmountOwed.amountCents,
      displayed_amount_owed_currency: displayedAmountOwed.currency,
    });
  }

  return {
    show,
    review,
    redirectReturn,
    paymentInProgress,
  };
}
