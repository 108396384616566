import React from 'react';
import { get, isEmpty } from 'lodash';
import classNames from 'classnames';
import { Link } from 'react-router';
import { CollectionHeaderStore } from '@reverbdotcom/commons/src/components/stores/collection_header_store';

interface IProps {
  hideBanner?: boolean;
  headerLinkTarget?: string;
}

interface IState {
  collectionHeader: {
    description?: string;
  }
}

/**
 * This is only used in two places: csp_grid and digital_listings/index_page. Can we get it down to zero??
 */
export default class CollectionHeaderComponent extends React.Component<IProps, IState> {
  constructor(props) {
    super(props);

    this.state = CollectionHeaderStore.getState();
    this.onCollectionHeaderStoreUpdated = this.onCollectionHeaderStoreUpdated.bind(this);
  }

  componentDidMount() {
    CollectionHeaderStore.listen(this.onCollectionHeaderStoreUpdated);
  }

  componentWillUnmount() {
    CollectionHeaderStore.unlisten(this.onCollectionHeaderStoreUpdated);
  }

  onCollectionHeaderStoreUpdated() {
    this.setState(CollectionHeaderStore.getState());
  }

  get fullBleedImage() {
    return get(
      this.state,
      'collectionHeader._links.full_bleed_header_image.href',
    );
  }

  get headerImage() {
    return get(
      this.state,
      'collectionHeader._links.header_image.href',
    );
  }

  get backgroundImage() {
    if (!this.fullBleedImage) return null;

    return { style: { backgroundImage: `url('${this.fullBleedImage}')` } };
  }

  get isLoaded() {
    return !isEmpty(this.state.collectionHeader);
  }

  get title() {
    return get(this.state, 'collectionHeader.title');
  }

  hasDescription() {
    return !!this.state.collectionHeader.description;
  }

  renderHeaderImage() {
    if (this.props.hideBanner || !this.headerImage) { return null; }

    return (
      <div className="collection-header__title__banner">
        <img src={this.headerImage} alt="" />
      </div>
    );
  }

  renderHeader() {
    if (this.props.headerLinkTarget) {
      return (
        <h1 className="collection-header__title__text">
          <Link
            to={this.props.headerLinkTarget}
            className="collection-header__title__link"
          >
            {this.title}
          </Link>
        </h1>
      );
    }

    return (
      <h1 className="collection-header__title__text">
        {this.title}
      </h1>
    );
  }

  render() {
    if (!this.isLoaded) { return null; }

    const classes = classNames(
      'collection-header',
      { 'collection-header--with-image': !!this.fullBleedImage },
      { 'collection-header--with-description': this.hasDescription() },
    );

    return (
      <div className={classes}>
        <header
          className="collection-header__title"
          {...this.backgroundImage}
        >
          {this.renderHeaderImage()}
          {this.renderHeader()}
        </header>
      </div>
    );
  }
}
