// TODO update this to import from commons/src/gql
// eslint-disable-next-line no-restricted-imports
import { gql } from '@apollo/client';

export const ImageAdCreativeFragment = gql`
  fragment ImageAdCreativeData on core_apimessages_ImageAdCreative {
    title
    titleColor
    subtitle
    subtitleColor
    ctaText
    ctaHref
    ctaTextColor
    ctaButtonColor
    backgroundImage
    mobileBackgroundImage
    backgroundColor
    backgroundColors
    overlayImage
    description
    partnershipText
    hideAdCallout
  }
`;

export const AdCampaignFragment = gql`
  fragment AdCampaignData on AdCampaign {
    _id
    uuid
    name
    status
    startsAt
    endsAt
    advertiserUuid
  }
`;

export const AdFragment = gql`
  fragment AdData on core_apimessages_Ad {
    uuid
    format
    adGroupUuid
    adGroupName
    adCampaignUuid
    advertiserUuid
  }
`;

export const AdCspRowQueryFragment = gql`
  fragment AdCspRowQueryData on core_apimessages_CspRowQuery {
    brandSlugs
    categoryUuids
    cspSlugs
    rowTitle
    sort
  }
`;

export const adBannerQuery = gql`
  query Core_Ad_Banner_Wrapper(
    $keywords: [String]
  ) {
    adServe(input: {
      targeting: {
        keywords: $keywords
      }
    }) {
      ad {
        ...AdData
        imageAdCreative {
          ...ImageAdCreativeData
        }
      }
    }
  }
  ${AdFragment}
  ${ImageAdCreativeFragment}
`;

export const adEndedListingPageQueryGql = gql`
  query Core_Ad_Ended_Listing_Page(
    $categories: [String]
  ) {
    adServe(input: {
      targeting: {
        categories: $categories
      },
    }) {
      ad {
        ...AdData
        imageAdCreative {
          ...ImageAdCreativeData
          cspRowQuery {
            ...AdCspRowQueryData
          }
        }
      }
    }
  }
  ${AdFragment}
  ${ImageAdCreativeFragment}
  ${AdCspRowQueryFragment}
`;

export const AdGroupWithoutKeywordsFragment = gql`
  fragment AdGroupWithoutKeywordsData on AdGroup {
    _id
    uuid
    name
    adCampaignUuid
    targetedCategoryUuids
    targetedCountryCodes
    targetedDevices
    targetedBrandSlugs
    targetedAdZones {
      adZone {
        slug
      }
      startsAt
      endsAt
    }
  }
`;

export const adPreviewsQueryGql = gql`
  query Core_Dev_Ad_Previews(
    $offset: Int
    $limit: Int
    $status: core_apimessages_AdCampaignsSearchRequest_Status
    $adZoneSlugs: [String]
    $withinDate: String
    $advertiserUuids: [String]
    $advertiserOffset: Int
    $advertiserLimit: Int
  ) {
    adCampaignsSearch(input: {
      offset: $offset,
      limit: $limit,
      status: $status,
      adZoneSlugs: $adZoneSlugs,
      withinDate: $withinDate
      advertiserUuids: $advertiserUuids
    }) {
      offset
      limit
      total
      adCampaigns {
        _id
        ...AdCampaignData
        adGroupCollection {
          _ids
          adGroups {
            _id
            ...AdGroupWithoutKeywordsData
            adCollection {
              _ids
              ads {
                _id
                uuid
                imageAdCreative {
                  ...ImageAdCreativeData
                  cspRowQuery {
                    ...AdCspRowQueryData
                  }
                }
              }
            }
          }
        }
      }
    }
    advertisersSearch(input: {
      offset: $advertiserOffset
      limit: $advertiserLimit
    }) {
      offset
      limit
      total
      advertisers {
        _id
        uuid
        name
      }
    }
    countries {
      countries {
        _id
        name
        countryCode
      }
    }
    adZonesSearch(input: {
      offset: 0
      limit: 50
    }) {
      adZones {
        _id
        slug
      }
    }
  }
  ${AdGroupWithoutKeywordsFragment}
  ${ImageAdCreativeFragment}
  ${AdCampaignFragment}
  ${AdCspRowQueryFragment}
`;
