import { WithApolloClient } from '@apollo/client/react/hoc';
import { Filter, FilterOption } from '@reverbdotcom/commons/src/components/search_filters';
import { renameParamsSingle, selectedPath } from '@reverbdotcom/commons/src/facet_utils';
import { reverb_search_Autodirects, reverb_search_Context, reverb_search_Filter_WidgetType as WidgetType } from '@reverbdotcom/commons/src/gql/graphql';
import { Location } from 'history';
import { combinedMarketplaceSearchQuery, onlyQuery, renameRqlParam, updateCategoryAsParams } from './map_params';
import { MPProps, mapVarsForMarketplaceQuery } from './marketplace_page';
import { UpdateUrlArgs } from '@reverbdotcom/commons/src/components/with_update_url';
import { isEqual, isMatch } from 'lodash';

interface HasLocation { location: Partial<Location> }
interface Autoselect { filter: Filter, selectedPath: FilterOption[] }
interface ReadCache {
  query,
  client: WithApolloClient<any>,
  props: HasLocation,
  autodirect: Partial<Location>,
  mapArgs: (props: HasLocation) => any,
}
interface WriteCache extends ReadCache { data: any }

export const updateMarketplaceUrl = (args: UpdateUrlArgs) => updateCategoryAsParams({ autodirects: true, ...args });
export const AUTODIRECTS_GROUP = reverb_search_Autodirects.IMPROVED_DATA;

export function autoselectedFilters(props: MPProps): Autoselect[] {
  return props.data?.aggsSearch?.filters
    ?.map(f => renameParamsSingle(f, renameRqlParam))
    .map(f => ({ filter: f, selectedPath: selectedPath(f).filter(o => o.autoselected) }))
    .filter(a => a.selectedPath.length > 0) || [];
}

export function autodirectUrl(props: MPProps) {
  if (props.data?.loading) { return; }

  const autoselected = autoselectedFilters(props);
  if (autoselected.length === 0) { return; }

  const { location } = props;
  const params = { ...location.query };

  autoselected.forEach(a => {
    if ([WidgetType.CHECKBOX, WidgetType.NESTED_SELECT].includes(a.filter.widgetType)) {
      a.selectedPath.forEach(o => params[o.paramName] = o.setValues);
    }
  });

  return updateMarketplaceUrl({ location, newParams: params });
}

function cacheKey({ props, autodirect, mapArgs, query }: ReadCache) {
  return {
    query,
    variables: mapArgs({
      ...props,
      location: { ...props.location, ...autodirect },
    }),
  };
}

export function isCached(args: ReadCache) {
  try {
    return !!args.client.cache.readQuery(cacheKey(args));
  } catch {
    return false;
  }
}

/*  When autodirected searches return, the frontend persists autodirect params to the url
    to reflect the autoapplied filters (ex. ?query=fender becomes ?query=fender&make=fender).
    Before the url updates, this function caches search results with the autodirect params to tell apollo:
    "hey! cache `{ query: "fender", make: "fender" }` with the same results as `{ query: "fender" }`".
    Otherwise, apollo will dumbly think it's a new query and fire an extra graphql request (which we don't want!).
*/
export function cacheAutodirect(args: WriteCache) {
  const { client, data } = args;
  client.cache.writeQuery({ ...cacheKey(args), data });
}

export function contexts(location: Partial<Location>) {
  return onlyQuery(location.query) ? [reverb_search_Context.INITIAL_QUERY] : [];
}

export function applyAutodirect({ prevProps, props } : { prevProps: MPProps, props: MPProps }) {
  const { data, client, router } = props;

  const sameData = isEqual(prevProps.data.listingsSearch, data.listingsSearch);
  const sameArgs = isEqual(prevProps.data.variables, data.variables);
  if (sameData && sameArgs) { return; }

  const autodirect = autodirectUrl(props);
  if (!autodirect) { return; }

  const applied = isMatch(props.location.query, autodirect.query);
  if (applied) { return; }

  const args = { client, data, props, autodirect, mapArgs: mapVarsForMarketplaceQuery, query: combinedMarketplaceSearchQuery };
  if (!isCached(args)) { cacheAutodirect(args); }

  router.replace(autodirect);
}
