import React from 'react';
import { Route } from 'react-router';
import { ADS_ADMIN_ADVERTISERS_ROOT, ADS_ADMIN_AD_ZONES_ROOT, AD_PREVIEWS_ROOT } from '../components/shared/url_helpers';

const advertiserEditPath = `${ADS_ADMIN_ADVERTISERS_ROOT}/:advertiserUuid/edit`;
const adZoneEditPath = `${ADS_ADMIN_AD_ZONES_ROOT}/:adZoneUuid/edit`;
const adCampaignsPath = `${ADS_ADMIN_ADVERTISERS_ROOT}/:advertiserUuid/ad_campaigns`;
const adCampaignEditPath = `${adCampaignsPath}/:adCampaignUuid/edit`;
const adGroupsPath = `${adCampaignsPath}/:adCampaignUuid/ad_groups`;
const adGroupEditPath = `${adGroupsPath}/:adGroupUuid/edit`;
const adsPath = `${adGroupsPath}/:adGroupUuid/ads`;
const adEditPath = `${adsPath}/:adUuid/edit`;

export const AdsAdminRoutes = [
  <Route
    key={ADS_ADMIN_AD_ZONES_ROOT}
    path={ADS_ADMIN_AD_ZONES_ROOT}
    props={{ clientSide: true }}
    getComponent={(_location, cb) => {
      import(
        /* webpackChunkName: 'ads_admin' */
        '../components/ad_admin/ad_zones_index_container'
      ).then(m => cb(null, m.default));
    }}
  />,
  <Route
    key={AD_PREVIEWS_ROOT}
    path={AD_PREVIEWS_ROOT}
    props={{ clientSide: true }}
    getComponent={(_location, cb) => {
      import(
        /* webpackChunkName: 'ads_admin' */
        '../components/ad_admin/ad_previews_index'
      ).then(m => cb(null, m.default));
    }}
  />,
  <Route
    key={adZoneEditPath}
    path={adZoneEditPath}
    props={{ clientSide: true }}
    getComponent={(_location, cb) => {
      import(
        /* webpackChunkName: 'ads_admin' */
        '../components/ad_admin/ad_zone_form_container'
      ).then((m) => {
        const AdZoneFormContainer = m.default;

        cb(null, (props) => (
          <AdZoneFormContainer
            adZoneUuid={props.params.adZoneUuid}
          />
        ));
      });
    }}
  />,
  <Route
    key={ADS_ADMIN_ADVERTISERS_ROOT}
    path={ADS_ADMIN_ADVERTISERS_ROOT}
    props={{ clientSide: true }}
    getComponent={(_location, cb) => {
      import(
        /* webpackChunkName: 'ads_admin' */
        '../components/ad_admin/advertisers_index_container'
      ).then(m => cb(null, m.default));
    }}
  />,
  <Route
    key={advertiserEditPath}
    path={advertiserEditPath}
    props={{ clientSide: true }}
    getComponent={(_location, cb) => {
      import(
        /* webpackChunkName: 'ads_admin' */
        '../components/ad_admin/advertiser_form_container'
      ).then((m) => {
        cb(null, (props) => {
          const AdvertiserFormContainer = m.default;

          return (
            <AdvertiserFormContainer
              advertiserUuid={props.params.advertiserUuid}
            />
          );
        });
      });
    }}
  />,
  <Route
    key={adCampaignsPath}
    path={adCampaignsPath}
    props={{ clientSide: true }}
    getComponent={(_location, cb) => {
      import(
        /* webpackChunkName: 'ads_admin' */
        '../components/ad_admin/ad_campaigns_index_container'
      ).then((m) => {
        cb(null, (props) => {
          const AdCampaignsIndexContainer = m.default;

          return (
            <AdCampaignsIndexContainer
              advertiserUuid={props.params.advertiserUuid}
            />
          );
        });
      });
    }}
  />,
  <Route
    key={adGroupsPath}
    path={adGroupsPath}
    props={{ clientSide: true }}
    getComponent={(_location, cb) => {
      import(
        /* webpackChunkName: 'ads_admin' */
        '../components/ad_admin/ad_groups_index_container'
      ).then((m) => {
        cb(null, (props) => {
          const AdGroupsIndexContainer = m.default;

          return (
            <AdGroupsIndexContainer
              adCampaignUuid={props.params.adCampaignUuid}
              advertiserUuid={props.params.advertiserUuid}
            />
          );
        });
      });
    }}
  />,
  <Route
    key={adGroupEditPath}
    path={adGroupEditPath}
    props={{ clientSide: true }}
    getComponent={(_location, cb) => {
      import(
        /* webpackChunkName: 'ads_admin' */
        '../components/ad_admin/ad_group_form_container'
      ).then((m) => {
        cb(null, (props) => {
          const AdGroupFormContainer = m.default;
          return (
            <AdGroupFormContainer
              advertiserUuid={props.params.advertiserUuid}
              adCampaignUuid={props.params.adCampaignUuid}
              adGroupUuid={props.params.adGroupUuid}
            />
          );
        });
      });
    }}
  />,
  <Route
    key={adsPath}
    path={adsPath}
    props={{ clientSide: true }}
    getComponent={(_location, cb) => {
      import(
        /* webpackChunkName: 'ads_admin' */
        '../components/ad_admin/ads_index_container'
      ).then((m) => {
        cb(null, (props) => {
          const AdsIndexContainer = m.default;
          return (
            <AdsIndexContainer
              advertiserUuid={props.params.advertiserUuid}
              adCampaignUuid={props.params.adCampaignUuid}
              adGroupUuid={props.params.adGroupUuid}
            />
          );
        });
      });
    }}
  />,
  <Route
    key={adCampaignEditPath}
    path={adCampaignEditPath}
    props={{ clientSide: true }}
    getComponent={(_location, cb) => {
      import(
        /* webpackChunkName: 'ads_admin' */
        '../components/ad_admin/ad_campaign_form_container'
      ).then((m) => {
        cb(null, (props) => {
          const AdCampaignFormContainer = m.default;
          return (
            <AdCampaignFormContainer
              advertiserUuid={props.params.advertiserUuid}
              adCampaignUuid={props.params.adCampaignUuid}
            />
          );
        });
      });
    }}
  />,
  <Route
    key={adEditPath}
    path={adEditPath}
    props={{ clientSide: true }}
    getComponent={(_location, cb) => {
      import(
        /* webpackChunkName: 'ads_admin' */
        '../components/ad_admin/ad_form_container'
      ).then((m) => {
        cb(null, (props) => {
          const AdFormContainer = m.default;
          return (
            <AdFormContainer
              advertiserUuid={props.params.advertiserUuid}
              adCampaignUuid={props.params.adCampaignUuid}
              adGroupUuid={props.params.adGroupUuid}
              adUuid={props.params.adUuid}
            />
          );
        });
      });
    }}
  />,
];
