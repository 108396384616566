import React from 'react';
import DownloadButton from './download_button';
import Loading from '../shared/loading';
import {
  Core_Listing_DigitalDetailsQuery,
  Core_Listing_DigitalDetailsQueryVariables,
} from '@reverbdotcom/commons/src/gql/graphql';
import { useQuery } from '@reverbdotcom/commons/src/useQuery';
import { gql } from '@reverbdotcom/commons/src/gql';

interface IProps {
  listingId: string;
}

export function StandaloneDownloadButton(props: IProps) {
  const digitalListingDetailsResults = useQuery<
    Core_Listing_DigitalDetailsQuery,
    Core_Listing_DigitalDetailsQueryVariables
  >(
    DIGITAL_DETAILS_QUERY,
    {
      ssr: false,
      variables: { id: props.listingId },
    },
  );
  const { data, loading } = digitalListingDetailsResults;
  const variants = data?.listing?.digitalDetails?.variants || [];

  return (
    <Loading loaded={!loading}>
      <DownloadButton variants={variants} />
    </Loading>
  );
}

const DIGITAL_DETAILS_QUERY = gql(`
  query Core_Listing_DigitalDetails(
    $id: String
  ) {
    listing(input: { id: $id }) {
      _id
      ...DigitalDetailsFields
    }
  }
`);

export default StandaloneDownloadButton;
