// TODO update this to import from commons
// import { gql } from '@reverbdotcom/commons/src/gql';
// eslint-disable-next-line no-restricted-imports
import { gql } from '@apollo/client';
/* eslint-disable jsx-quotes */
import React from 'react';
import { SanitizedRender } from '@reverbdotcom/commons';
import I18n from 'i18n-js';
import { withGraphql } from '@reverbdotcom/commons/src/with_graphql';
import { RCAlertBox } from '@reverbdotcom/cadence/components';

export const refetchDirectCheckoutProfile = async (setState, refetch) => {
  setState({ showPlaidSummary: true });
  setState({ loadingDcp: true });
  try {
    if (!refetch) return;
    await refetch();
  } catch (e) {
    setState({ loadingDcp: false });
  }
  setState({ loadingDcp: false });
};

export const setPlaidModalOpenInSummaryView = (setState, callback) => {
  return setState({ openPlaidModal: callback });
};

export const setDirectCheckoutProfileSummaryLoadingState = (
  setState,
  boolean,
) => {
  return setState({ loadingDcp: boolean });
};

export const setPlaidAccountStatus = (setState, plaidAccountStatus) => {
  return setState({ plaidAccountStatus });
};

export const setPendingPlaidAccountId = (setState, pendingPlaidAccountId) => {
  return setState({ pendingPlaidAccountId });
};

export const LockIcon = () => {
  return '<span class="fa fa-lock"></span>';
};

export const PendingPlaidView = ({ plaidAccountStatus }) => {
  return (
    <div className="mb-2">
      <RCAlertBox type="info">
        <SanitizedRender
          htmlTag='span'
          id='plaidBankChangeLocked'
          className='weight-bold'
          html={plaidAccountStatus?.includes('pending_manual') ?
            I18n.t('discovery.plaid.pendingManualVerificationHtml') :
            I18n.t('discovery.plaid.pendingAutomaticVerificationHtml', {
              icon: LockIcon(),
            })
          }
        />
      </RCAlertBox>
    </div>
  );
};

export const isPlaid = (props) => {
  return props.profileAttributes.plaidAccountId ||
    props.data?.myDirectCheckoutProfile?.plaidAccountId;
};

export const withDirectCheckoutProfileQuery = withGraphql<any, any>(
  gql`
    query Core_Payouts_MyDirectCheckoutProfile(
      $input: Input_core_apimessages_MyDirectCheckoutProfileRequest
    ) {
      myDirectCheckoutProfile(input: $input) {
        plaidAccountId
        nameOnAccount
        maskedNameSummary
        bankName
        countryCode
        payoutMethod
        hasAcceptedReverbBankTerms
        profileErrors
        currency
      }
    }
  `,
);
