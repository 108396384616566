// TODO update this to import from commons/src/gql
// eslint-disable-next-line no-restricted-imports
import { gql } from '@apollo/client';
import React from 'react';
import { AffirmCallout } from '../AffirmCallout';
import { Listing } from '../gql/graphql';

interface IProps {
  listing: Listing;
}

export const fullFinancingFieldsFragment = gql`
  fragment FullFinancingFields on Listing {
    _id
    estimatedMonthlyPaymentPlan {
      financingProgramSlug
    },
    pricing {
      buyerPrice {
        amountCents
      }
    },
  }
`;

function AffirmEstimateListingCardCTA({ listing: { estimatedMonthlyPaymentPlan, pricing } }: IProps) {
  if (!estimatedMonthlyPaymentPlan?.financingProgramSlug || !pricing?.buyerPrice?.amountCents) { return null; }

  return (
    <AffirmCallout
      amountCents={pricing.buyerPrice.amountCents}
      financingProgramSlug={estimatedMonthlyPaymentPlan.financingProgramSlug}
      pageType="product"
    />
  );
}

export default AffirmEstimateListingCardCTA;
