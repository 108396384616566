import React from 'react';
import { Route } from 'react-router';

export default (<Route
  key="holiday"
  path="holiday"
  props={{}}
  getComponent={(_location, cb) => {
    import(
      /* webpackChunkName: 'marketplace' */
      '../components/discovery/holiday_sale_grid'
    ).then(m => cb(null, m.HolidaySaleGrid));
  }}
/>);
