import I18n from 'i18n-js';
import React, { useState } from 'react';

import CoreLink from '@reverbdotcom/commons/src/components/core_link';
import * as elog from '@reverbdotcom/commons/src/elog';
import windowWrapper from '@reverbdotcom/commons/src/window_wrapper';
import { RCButton, RCInfoBox } from '@reverbdotcom/cadence/components';
import { ProtectionShieldIcon } from '@reverbdotcom/cadence/icons/react';

import { Paths } from './shared/url_helpers';

const SUSPICIOUS_ACTIVITY_HC_ARTICLE_ID = '5440807127443';

interface IProps {
  nagId: string
}

export function DismissibleTrustBanner({ nagId }: IProps) {
  const [isDismissed, setIsDismissed] = useState(false);

  if (isDismissed) { return null; }

  async function onDismiss() {
    try {
      await windowWrapper.jQuery.post(Paths.closeNagAlert.expand({ nagId }));
    } catch (e) {
      elog.error('discovery.trust_banner_dismiss.error', {}, e);
    }

    setIsDismissed(true);
  }

  return (
    <div className="mt-2 mb-4">
      <RCInfoBox
        variant="subtle-outlined"
        theme="vu-orange"
        svgComponent={ProtectionShieldIcon}
      >
        <div className="d-flex fx-justify-between">
          <div>
            <p className="mb-0">
              {I18n.t('discovery.dismissibleTrustBanner.message')}
            </p>
            <CoreLink
              to={Paths.helpCenterArticlesUrl.expand({ articleId: SUSPICIOUS_ACTIVITY_HC_ARTICLE_ID })}
              className="color-primary td-underline"
              target="_blank"
            >
              {I18n.t('discovery.dismissibleTrustBanner.learnMore')}
            </CoreLink>
          </div>
          <div>
            <RCButton onClick={onDismiss}>
              {I18n.t('discovery.dismissibleTrustBanner.buttonText')}
            </RCButton>
          </div>
        </div>
      </RCInfoBox>
    </div>
  );
}

export default DismissibleTrustBanner;
