import React from 'react';
import { withPrefix } from '@reverbdotcom/commons/src/i18n_helpers';
import {
  RCTag,
  RCTextWithIcon,
  RCButton,
} from '@reverbdotcom/cadence/components';
import { CheckCircleIcon } from '@reverbdotcom/cadence/icons/react';
import { formatInternationalizedDate } from '../lib/date_utils';
import Location from '../lib/wrapped_location';

interface Props {
  verificationType: string;
  isVerified: boolean;
  updatedDate: any;
  buttonLink: string;
}

interface StatusProps {
  title: string;
  button: string;
}

function InformActVerificationForType({
  verificationType,
  isVerified,
  updatedDate,
  buttonLink,
}: Props) {
  const t = withPrefix(`discovery.informActVerifications.${verificationType}`);

  const statusText: StatusProps = (isVerified ?
    {
      title: t('addedTitle'),
      button: t('updateButton'),
    } :
    {
      title: t('addTitle'),
      button: t('addButton'),
    }
  );

  function handleRedirect(): void {
    Location.assign(buttonLink);
  }

  const renderTitleWithStyling = () => (
    isVerified ? (
      <>
        <RCTextWithIcon
          placement="left"
          svgComponent={CheckCircleIcon}
        >
          <strong>
            {statusText.title}
          </strong>
        </RCTextWithIcon>
      </>
    ) : (
      <>
        <div className="mb-2">
          <RCTag variant="highlight">
            {t('needsAttention')}
          </RCTag>
        </div>
        <h3 className="verification-task-list__item__title">
          {statusText.title}
        </h3>
      </>
    )
  );

  return (
    <div className="verification-task-list__item">
      <div className="verification-task-list__item__inner">
        <div className="verification-task-list__item__info">
          {renderTitleWithStyling()}
          <p className="verification-task-list__item__description">
            {isVerified ? t('acceptedDescription') : t('needToProvideDescription') }
          </p>
          {updatedDate && (
            <p className="mb-0 mt-space color-secondary weight-bold">
              {t('lastUpdated', { date: formatInternationalizedDate(updatedDate) })}
            </p>
          )}
        </div>
        <div className="d-flex fx-shrink fx-align-center ml-4 mobile-ml-0 mobile-mt-2 mobile-mb-2">
          <RCButton onClick={handleRedirect} variant="filled">
            {statusText.button}
          </RCButton>
        </div>
      </div>
    </div>
  );
}

export default InformActVerificationForType;
