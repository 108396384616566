import { sortBy } from 'lodash';
import { EVERYWHERE_CODE, US, US_CON } from '@reverbdotcom/commons/src/constants';

export interface IShippingRegion {
  children: IShippingRegion[];
  code: string;
  name: string;
  region_type: string;
}

const TOP_REGION_CODES = Object.freeze([
  'US_CON',
  'CA',
  'GB',
  'AU',
  'FR',
  'DE',
  'JP',
  'ID',
]);

function sortTopRegions(topRegions: IShippingRegion[]): IShippingRegion[] {
  return TOP_REGION_CODES.map(topRegionCode => topRegions.find(country => country.code === topRegionCode));
}

export function getShippingRegionOptions(shippingRegions: IShippingRegion[]) {
  const partitionedRegionOptions = shippingRegions.reduce((options, region) => {
    if (region.code === EVERYWHERE_CODE) {
      options.everywhere.push(region);
    }

    region.children.forEach(regionChild => {
      if (regionChild.code === US) {
        regionChild.children.forEach(usRegion => {
          if (usRegion.code === US_CON) {
            options.topRegions.push(usRegion);
          } else {
            options.usRegions.push(usRegion);
          }
        });
      } else if (TOP_REGION_CODES.includes(regionChild.code)) {
        options.topRegions.push(regionChild);
      } else {
        options.remainingRegions.push(regionChild);
      }
    });

    return options;

  }, { everywhere: [], topRegions: [], usRegions: [], remainingRegions: [] });

  const sortedTopRegions = partitionedRegionOptions.topRegions.length ? sortTopRegions(partitionedRegionOptions.topRegions) : [];
  const sortedRemainingRegions = sortBy(partitionedRegionOptions.remainingRegions, 'name');

  return { ...partitionedRegionOptions, topRegions: sortedTopRegions, remainingRegions: sortedRemainingRegions };
}
