import React from 'react';
import classNames from 'classnames';
import { times } from 'lodash';

import OverflowingRow from './overflowing_row';
import Tiles from './tiles';
import ArticleCollectionCard from './article_collection_card';
import { Commons_Cms_ArticlesCollectionQuery } from '../gql/graphql';
import { RCGrid } from '@reverbdotcom/cadence/components';

type Commons_Cms_ArticlesCollectionQuery_Articles = Commons_Cms_ArticlesCollectionQuery['articles'][0];

interface IProps {
  title?: JSX.Element | React.ReactNode | string;
  titleHtml?: string;
  subtitle?: string;
  subtitleHtml?: string;
  ledeHtml?: string;
  action?: React.ReactElement;
  articles?: Commons_Cms_ArticlesCollectionQuery_Articles[];
  id?: string;
  loading?: boolean;
  expectedCount?: number;
  renderForSidebar?: boolean;
  cardStyle?: string;
}

export function ArticleCollectionView({
  title = undefined,
  titleHtml = undefined,
  subtitle = undefined,
  subtitleHtml = undefined,
  ledeHtml = undefined,
  action = undefined,
  articles = [],
  id = undefined,
  loading = false,
  expectedCount = 3,
  renderForSidebar = false,
  cardStyle = undefined,

}: IProps) {
  if (!loading && !articles.length) {
    return null;
  }

  function renderArticle(article) {
    return (
      <ArticleCollectionCard
        article={article}
        renderForSidebar={renderForSidebar}
        key={article?.id}
        cardStyle={cardStyle}
      />
    );
  }

  if (renderForSidebar) {
    const classes = classNames(
      'sidebar-articles',
      { 'sidebar-articles--loading': loading },
    );

    return (
      <div className={classes}>
        <h4 className="sidebar-articles__heading">
          {title}
        </h4>
        <Tiles
          oneWide
          placeholder={renderArticle(null)}
          expectedCount={expectedCount}
          loading={loading}
        >
          {articles.map(renderArticle)}
        </Tiles>
      </div>
    );
  }

  function gridArticlePlaceholders() {
    return times(expectedCount, index => (
      <RCGrid.Item
        key={index}
        colSpan={{ desktop: 4, tablet: 5, mobile: 8 }}
      >
        {renderArticle(null)}
      </RCGrid.Item>
    ));
  }

  return (
    <OverflowingRow
      title={title}
      titleHtml={titleHtml}
      subtitle={subtitle}
      subtitleHtml={subtitleHtml}
      ledeHtml={ledeHtml}
      collectionCount={articles.length}
      action={action}
      id={id}
    >
      <RCGrid
        disableAndPulse={loading}
        singleRowOn="all"
      >
        {loading ? (
          gridArticlePlaceholders()
        ) : (
          articles.map(article => (
            <RCGrid.Item
              colSpan={{ desktop: 4, tablet: 5, mobile: 8 }}
              key={article.id}
            >
              {renderArticle(article)}
            </RCGrid.Item>
          ))
        )}
      </RCGrid>
    </OverflowingRow>
  );
}
