import React from 'react';
import moment from 'moment';
import Datetime from 'react-datetime';
import bind from '@reverbdotcom/commons/src/bind';
import MobileDetection from '../../reverb-js-common/mobile_detection';

interface IProps {
  onChange: (moment: moment.Moment) => void;
  minDate: moment.Moment;
  currentDate: moment.Moment;
  className?: string;
}

export const YEAR_MONTH_DAY_FORMAT = 'YYYY-MM-DD';

export default class CalendarInput extends React.Component<IProps> {
  @bind
  validOnOrAfterMinDate(currentDay: moment.Moment) {
    return currentDay.isSameOrAfter(this.props.minDate, 'day');
  }

  @bind
  onMobileInputChange(event: React.ChangeEvent<HTMLInputElement>) {
    this.props.onChange(moment(event.target.value, YEAR_MONTH_DAY_FORMAT));
  }

  render() {
    if (MobileDetection.isMobileBrowser()) {
      return (
        <input
          className={this.props.className}
          type="date"
          value={this.props.currentDate.format(YEAR_MONTH_DAY_FORMAT)}
          min={this.props.minDate.format(YEAR_MONTH_DAY_FORMAT)}
          onChange={this.onMobileInputChange}
        />
      );
    }
    return (
      <Datetime
        className={this.props.className}
        value={this.props.currentDate}
        onChange={this.props.onChange}
        dateFormat="L"
        timeFormat={false}
        isValidDate={this.validOnOrAfterMinDate}
      />
    );
  }
}
