/**
 * This component acts as a wrapper around FormGroupWithInput in order to take an email address at checkout.
 * The reason that we need a wrapper component is: if the backend sends an error message to the frontend, we need to show that message to the user.
 * In general, we expect frontend and backend email validations to be aligned,
 *   but on the chance that backend validations become more stringent and frontend validations are forgotten in the update,
 *   then we would need to have this layer to present the backend's error message.
 */
import React from 'react';
import FormGroupWithInput from '@reverbdotcom/commons/src/components/form_group_with_input';

export default function CheckoutEmailInput({ initialEmailValue, emailFieldName, initialErrorMessage }) {
  const [emailValue, setEmailValue] = React.useState(initialEmailValue || '');
  const [errorText, setErrorText] = React.useState(initialErrorMessage || '');

  const onUpdateField = e => {
    const emailValueToSet = e[emailFieldName];
    setEmailValue(emailValueToSet);
    setErrorText('');
  };

  return (
    <FormGroupWithInput
      label={I18n.t('commons.signupForm.emailLabel')}
      inputName={emailFieldName}
      inputType="email"
      value={emailValue}
      autoComplete="email-address"
      required
      updateField={onUpdateField}
      errorText={errorText}
    />
  );
}
