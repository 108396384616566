import React from 'react';
import asyncComponent from '../async_component';
import watchUnwatch from './data/watch-unwatch.json';

export enum AnimationSegments {
  FirstHalf = 'First Half',
  SecondHalf = 'Second Half',
}

export interface AnimationProps {
  animationData: object;
  animationSegment: AnimationSegments;
}

// This module is loaded async because loading the lottie-web package
// cannot be loaded in server side JS:
//
// https://github.com/airbnb/lottie-web/issues/2739
//
// Its OK to load async because animations are executed in a
// useEffect hook which only runs client side anyway.
export const BaseAnimation = asyncComponent<AnimationProps>(() => { return import('./base_animation'); });

export interface AnimatedSwitchProps {
  isOn: boolean;
  animationData: object;
}

// This AnimatedSwitch component is based on the Reverb iOS app's usage of an
// AnimatedSwitch class provided by the lottie-ios SDK, but not the lottie-web package:
//
//    https://github.com/reverbdotcom/ios/blob/6b94b2952ad064e6f991b8a36531f7a39410eec2/Reverb/Shared/UI/Controls/Watch/WatchButton.swift
//    https://github.com/airbnb/lottie-ios/blob/3cf8ade4f14cb67fd06ee760e9b579eeb9ea31fb/Sources/Public/iOS/AnimatedSwitch.swift
//
// After rendering, it plays either the first half or second half of its animationData file.
export const AnimatedSwitch = function (props: AnimatedSwitchProps) {
  const animationSegment = props.isOn ? AnimationSegments.SecondHalf : AnimationSegments.FirstHalf;
  return (
    <BaseAnimation
      animationData={props.animationData}
      animationSegment={animationSegment}
    />
  );
};

export interface WatchUnwatchAnimationProps {
  watching: boolean;
}

export const WatchUnwatchAnimation = function (props: WatchUnwatchAnimationProps) {
  return (
    <AnimatedSwitch
      animationData={watchUnwatch}
      isOn={props.watching}
    />
  );
};
