// TODO update this to import from commons
// import { gql } from '@reverbdotcom/commons/src/gql';
// eslint-disable-next-line no-restricted-imports
import { gql } from '@apollo/client';
import React, { useCallback, useState } from 'react';
import { withGraphql } from '@reverbdotcom/commons/src/with_graphql';
import { useHOCMutation, MutationFunction } from '@reverbdotcom/commons/src/useHOCMutation';
import InternationalTaxProfileMainFormSection from './international_tax_profile_main_form_section';
import TaxIdentificationsSection from './tax_identifications_section';
import { I18N as Translate } from '@reverbdotcom/commons/src/components/translate';
import FormSection from '@reverbdotcom/commons/src/components/form_section';
import CoreLink from '@reverbdotcom/commons/src/components/core_link';
import AddressFormFields from '../address_form_fields';
import { Paths } from '../shared/url_helpers';
import I18n from 'i18n-js';
import {
  RCAlertBox,
  RCButton,
  RCFormGroupAlertText,
  RCIcon,
  RCRadioGroup,
  RCTextInput,
} from '@reverbdotcom/cadence/components';
import { LockIcon } from '@reverbdotcom/cadence/icons/react';
import {
  core_apimessages_BusinessRegistrationNumber,
  core_apimessages_Country,
  core_apimessages_InternationalTaxProfile,
  core_apimessages_Shop_ShopType,
  UpdateInternationalTaxProfile,
  core_apimessages_TaxIdentification,
  core_apimessages_TaxIdentification_TaxType,
} from '@reverbdotcom/commons/src/gql/graphql';
import {
  INDIVIDUAL_TYPE,
  BUSINESS_TYPE,
  TIN_FORMATTING_HELP_CENTER_ARTICLE_ID,
  EU_SELLER_TAX_INFO_HELP_CENTER_ARTICLE_ID,
} from './international_tax_profile_constants';

const TinHelpText = () => (
  <Translate
    text="discovery.internationalTaxProfile.form.tinHelpText"
  >
    <CoreLink
      to={Paths.helpCenterArticlesUrl.expand({ articleId: TIN_FORMATTING_HELP_CENTER_ARTICLE_ID })}
      target="_blank"
    >
      <Translate text="discovery.internationalTaxProfile.form.helpCenterLinkLabel" />
    </CoreLink>
  </Translate>
);

interface ExternalProps {
  businessRegistrationNumber: core_apimessages_BusinessRegistrationNumber;
  countries: core_apimessages_Country[];
  displayAdditionalNumbers: boolean;
  internationalTaxProfile: core_apimessages_InternationalTaxProfile;
  setIsEditing: React.Dispatch<React.SetStateAction<boolean>>;
  setIsSuccess: React.Dispatch<React.SetStateAction<boolean>>;
  shopType: core_apimessages_Shop_ShopType;
  helpCenterArticleId?: string;
  taxIdentifications: core_apimessages_TaxIdentification[];
  taxIdentificationType: core_apimessages_TaxIdentification_TaxType;
}

const withMutation = withGraphql<ExternalProps, UpdateInternationalTaxProfile.Mutation, UpdateInternationalTaxProfile.Variables>(
  gql`
    mutation UpdateInternationalTaxProfile(
      $input: Input_core_apimessages_UpdateInternationalTaxProfileRequest
    ) {
      updateInternationalTaxProfile(input: $input) {
        internationalTaxProfile {
          tinMask
          tinType
          name
          dateOfBirth
          address {
            _id
            name
            streetAddress
            extendedAddress
            locality
            region
            postalCode
            countryCode
            country {
              _id
              name
            }
          }
        }
        businessRegistrationNumber {
          registrationNumber
          countryCode
        }
      }
    }
  `,
  {
    name: 'updateInternationalTaxProfile',
    options: {
      refetchQueries: ['Core_ShopSettings_InternationalTaxProfile'],
      awaitRefetchQueries: true,
    },
  },
);

type UpdateInternationalTaxProfileMutationFn = MutationFunction<UpdateInternationalTaxProfile.Mutation, UpdateInternationalTaxProfile.Variables>;

interface MutationProps {
  updateInternationalTaxProfile: UpdateInternationalTaxProfileMutationFn;
}

type Props = ExternalProps & MutationProps;

export function InternationalTaxProfileForm({
  businessRegistrationNumber,
  countries,
  displayAdditionalNumbers,
  internationalTaxProfile,
  setIsEditing,
  setIsSuccess,
  shopType,
  updateInternationalTaxProfile,
  helpCenterArticleId,
  taxIdentifications,
  taxIdentificationType,
}: Props) {
  const cancelEdits = useCallback(() => {
    setIsEditing(false);
  }, [setIsEditing]);

  const [formState, setFormState] = useState({
    dateOfBirth: internationalTaxProfile?.dateOfBirth || '',
    name: internationalTaxProfile?.name || '',
    tin: '', // intentionally stripping existing TIN data from edit mode; users will need to re-enter
    tinType: internationalTaxProfile?.tinType || (shopType !== core_apimessages_Shop_ShopType.UNKNOWN ? shopType?.toLowerCase() : INDIVIDUAL_TYPE),
    businessRegistrationNumber: businessRegistrationNumber?.registrationNumber || '',
  });

  const [addressFormState, setAddressFormState] = useState({
    streetAddress: internationalTaxProfile?.address?.streetAddress || '',
    extendedAddress: internationalTaxProfile?.address?.extendedAddress || '',
    locality: internationalTaxProfile?.address?.locality || '',
    countryCode: internationalTaxProfile?.address?.countryCode || '',
    region: internationalTaxProfile?.address?.region || '',
    postalCode: internationalTaxProfile?.address?.postalCode || '',
  });

  const [mutate, { loading, errors }] = useHOCMutation(updateInternationalTaxProfile);

  const updateAddress = (addressAttributes) => {
    setAddressFormState((previousAddressFormState) => ({ ...previousAddressFormState, ...addressAttributes }));
  };

  const updateTaxProfile = (attributes) => {
    setFormState((previousFormState) => ({ ...previousFormState, ...attributes }));
  };

  async function submitTaxInformation() {
    const result = await mutate({
      variables: {
        input: {
          ...formState,
          addressAttributes: {
            ...addressFormState,
            name: formState.name,
          },
        },
      },
    });

    if (result.errors.length === 0) {
      setIsSuccess(true);
      setIsEditing(false);
    }
  }

  const getErrorForField = useCallback((fieldName) => {
    return errors.find(({ field }) => {
      return field === fieldName;
    })?.message;
  }, [errors]);

  return (
    <>
      {errors.length > 0 && (
        <div className="scaling-mt-3">
          <RCAlertBox
            type="error"
          >
            <Translate text="discovery.internationalTaxProfile.form.error">
              <CoreLink
                className="text-link"
                to={Paths.helpCenterArticlesUrl.expand({ articleId: EU_SELLER_TAX_INFO_HELP_CENTER_ARTICLE_ID })}
                target="_blank"
              >
                <Translate text="discovery.internationalTaxProfile.taxInfoHelpCenterLinkLabel" />
              </CoreLink>
            </Translate>
          </RCAlertBox>
        </div>
      )}
      <InternationalTaxProfileMainFormSection helpCenterArticleId={helpCenterArticleId}>
        <RCRadioGroup
          id="tinType"
          name="tinType"
          label={I18n.t('discovery.internationalTaxProfile.form.individualOrBusiness')}
          value={formState.tinType}
          onChange={(event) => {
            updateTaxProfile({ tinType: event.target.value });
          }}
          helpText={I18n.t('discovery.internationalTaxProfile.form.tinTypeHelpText')}
        >
          <RCRadioGroup.Option
            label={I18n.t('discovery.internationalTaxProfile.tinType.individual')}
            value={INDIVIDUAL_TYPE}
          />
          <RCRadioGroup.Option
            label={I18n.t('discovery.internationalTaxProfile.tinType.business')}
            value={BUSINESS_TYPE}
          />
        </RCRadioGroup>
        <RCTextInput
          id="tin"
          value={formState.tin}
          type="text"
          helpText={<TinHelpText />}
          label={I18n.t('discovery.internationalTaxProfile.labels.tin')}
          onChange={(event) => {
            updateTaxProfile({ tin: event.target.value });
          }}
          required
          tag="required"
          errorText={getErrorForField('tin')}
        />
      </InternationalTaxProfileMainFormSection>
      <FormSection
        title={<Translate text="discovery.internationalTaxProfile.form.contactInformation" />}
      >
        {getErrorForField('address') && <RCFormGroupAlertText errorText={getErrorForField('address')} />}
        <RCTextInput
          id="name"
          value={formState.name}
          type="text"
          helpText={I18n.t('discovery.internationalTaxProfile.form.tinNameHelpText')}
          label={I18n.t(`discovery.internationalTaxProfile.name.${formState.tinType}`)}
          onChange={(event) => {
            updateTaxProfile({ name: event.target.value });
          }}
          required
          tag="required"
        />
        <AddressFormFields
          address={addressFormState}
          countries={countries}
          hiddenFieldsOverrides={['name', 'phone']}
          trackingComponentName="InternationalTaxProfileForm"
          updateField={updateAddress}
          useCamelizedFieldsForRQL
        />
      </FormSection>
      {formState.tinType == INDIVIDUAL_TYPE && (
        <FormSection
          title={<Translate text="discovery.internationalTaxProfile.labels.dateOfBirth" />}
        >
          <RCTextInput
            id="dateOfBirth"
            value={formState.dateOfBirth}
            type="date"
            helpText={I18n.t('discovery.internationalTaxProfile.form.dateOfBirthHelpText')}
            label={I18n.t('discovery.internationalTaxProfile.labels.dateOfBirth')}
            onChange={(event) => {
              updateTaxProfile({ dateOfBirth: event.target.value });
            }}
            required
            tag="required"
            errorText={getErrorForField('date_of_birth')}
          />
        </FormSection>
      )}
      {formState.tinType == BUSINESS_TYPE && displayAdditionalNumbers && (
        <FormSection
          title={<Translate text="discovery.internationalTaxProfile.labels.businessRegistrationNumber" />}
        >
          <RCTextInput
            id="businessRegistrationNumber"
            value={formState.businessRegistrationNumber}
            type="text"
            helpText={I18n.t('discovery.internationalTaxProfile.form.businessRegistrationNumberHelpText')}
            label={I18n.t('discovery.internationalTaxProfile.labels.businessRegistrationNumber')}
            onChange={(event) => {
              updateTaxProfile({ businessRegistrationNumber: event.target.value });
            }}
            required
            tag="required"
            errorText={getErrorForField('business_registration_number')}
          />
        </FormSection>
      )}

      {displayAdditionalNumbers && (
        <FormSection
          title={<Translate text={`discovery.internationalTaxProfile.labels.taxIdentifications.${taxIdentificationType}`} />}
        >
          <TaxIdentificationsSection
            taxIdentifications={taxIdentifications}
            taxIdentificationType={taxIdentificationType}
          />
        </FormSection>
      )}

      <div className="scaling-mt-4 d-flex fx-align-center fx-justify-center">
        {!!internationalTaxProfile && (
          <div className="scaling-mr-4">
            <RCButton
              id="cancelEdits"
              onClick={cancelEdits}
            >
              <Translate text="discovery.internationalTaxProfile.form.cancelEditing" />
            </RCButton>
          </div>
        )}
        <RCButton
          disabled={loading}
          id="submitTaxInformation"
          onClick={submitTaxInformation}
          variant="filled"
        >
          <RCIcon svgComponent={LockIcon} inline />
          <Translate text="discovery.internationalTaxProfile.form.submitTaxInformation" />
        </RCButton>
      </div>
    </>
  );
}

export default withMutation(InternationalTaxProfileForm);
