import React from 'react';

interface Props extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
  buttonText: string[] | number | string;
}

/**
 * Primary Action Button for a form.
 * @param props component props to pass to <button/>
 *        Anything which <button/> accepts should be valid.
 * @param buttonText {string} button label /text
 */
export default function FormPrimaryActionButton({ buttonText, ...props }: Props) {
  return (
    <div className="form-section form-section--actions">
      <button
        type="submit"
        className="form-section__primary-action"
        {...props}
      >
        {buttonText}
      </button>
    </div>
  );
}
