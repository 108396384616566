import I18n from 'i18n-js';
import React from 'react';

import CoreLink from '@reverbdotcom/commons/src/components/core_link';
import SanitizedRender from '@reverbdotcom/commons/src/components/sanitized_render';
import { RCTag } from '@reverbdotcom/cadence/components';

import URLHelpers from '../../../../shared/url_helpers';
import { ShippingMode } from './DomesticShippingCard';
import { CarrierHighlights } from './CarrierHighlights';

function Sublabel({ shippingMode, isSelected, shopAddressSummary }: {
  shippingMode: ShippingMode;
  isSelected: boolean;
  shopAddressSummary: string;
}) {
  if (!isSelected && shippingMode !== ShippingMode.CARRIER_CALCULATED) { return null; }

  return (
    <div className="domestic-shipping-radio-label__sublabel">
      {shippingMode !== ShippingMode.CARRIER_CALCULATED && (
        I18n.t(`discovery.sellForm.shippingSection.domesticShippingCard.radios.subLabel.${shippingMode}`)
      )}

      {shippingMode === ShippingMode.CARRIER_CALCULATED && (
        <>
          <div>
            <span>
              <SanitizedRender
                html={I18n.t('discovery.sellForm.shippingSection.domesticShippingCard.radios.subLabel.carrierCalculated', { shopAddressSummary })}
              />
            </span>
            <span className="domestic-shipping-radio-label__sublabel__link">
              <CoreLink
                to={URLHelpers.legalPagePath('calculated-shipping-terms-and-conditions')}
                target="_blank"
              >
                {I18n.t('discovery.sellForm.shippingSection.domesticShippingCard.radios.carrierCalcTerms')}
              </CoreLink>
            </span>
          </div>

          {!isSelected && (<CarrierHighlights />)}
        </>
      )}
    </div>
  );
}

export function DomesticShippingRadioLabel({ shippingMode }: { shippingMode: ShippingMode }) {
  const showRecommendedTag = shippingMode === ShippingMode.CARRIER_CALCULATED;

  return (
    <>
      <span className="domestic-shipping-radio-label">
        {I18n.t(`discovery.sellForm.shippingSection.domesticShippingCard.radios.label.${shippingMode}`)}
      </span>

      {showRecommendedTag && (
        <span className="ml-2">
          <RCTag variant="highlight">
            {I18n.t('discovery.sellForm.shippingSection.domesticShippingCard.highlights.recommended')}
          </RCTag>
        </span>
      )}
    </>
  );
}

DomesticShippingRadioLabel.Sublabel = Sublabel;
