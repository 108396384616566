import React from 'react';
import I18n from 'i18n-js';
import classNames from 'classnames';

interface IExternalProps {
  showReverbSales: boolean;
  selectReverbSales: React.Dispatch<React.SetStateAction<boolean>>
}

export default function SalesMembershipsTabs({
  showReverbSales,
  selectReverbSales,
}: IExternalProps) {
  return (
    <nav className="sales-memberships-tabs">
      <div className={classNames({
        'sales-memberships-tabs--selected': showReverbSales,
      })}>
        <button
          type="button"
          onClick={(e) => {
            e.preventDefault();
            selectReverbSales(true);
          }}
        >
          {I18n.t('discovery.listingsManagement.sellerListingCard.sales.modal.reverbSales.tabTitle')}
        </button>
      </div>

      <div
        className={classNames({
          'sales-memberships-tabs--selected': !showReverbSales,
        })}
      >
        <button
          type="button"
          onClick={(e) => {
            e.preventDefault();
            selectReverbSales(false);
          }}
        >
          {I18n.t('discovery.listingsManagement.sellerListingCard.sales.modal.sellerSales.tabTitle')}
        </button>
      </div>
    </nav>
  );
}
