// TODO update this to import from commons/src/gql
// eslint-disable-next-line no-restricted-imports
import { gql } from '@apollo/client';
import classNames from 'classnames';
import I18n from 'i18n-js';
import React from 'react';

import { PriceDisplay } from './PriceDisplay';
import LocationBasedShippingDisplay from './location_based_shipping_display';
import { ListingForBuyerShippingFieldsFragment, isDigital } from '../listing_helpers';
import { RCTooltip } from '@reverbdotcom/cadence/components';
import { useUser } from '../user_hooks';
import {
  core_apimessages_ShippingMethod as IShippingMethod,
  core_apimessages_ShippingPrice as IShippingPrice,
  core_apimessages_Money as IMoney,
  ListingShippingDisplayData,
  ListingForBuyerShippingFieldsFragment as ListingForBuyerShippingFields,
} from '../gql/graphql';

export const ListingShippingDisplayFragment = gql`
  fragment ListingShippingDisplayData on Listing {
    _id
    shop {
      _id
      freeDomesticShippingRate {
        regionCode
        freeShippingThreshold {
          amountCents
          currency
        }
      }
    }
    ...ListingForBuyerShippingFields
  }
  ${ListingForBuyerShippingFieldsFragment}
`;

type IListing = ListingShippingDisplayData.Fragment;

interface IProps {
  listing: IListing;
  shopSubtotalForListingCents?: number;
  showCombinedRate?: boolean;
  useCombinedShippingStrikethrough?: boolean;
  showBadges?: boolean;
  onlyShowCost?: boolean;
}

interface IPlusShippingProps {
  priceObject: any;
}

export function shippingPriceDataForMethod(listing: IListing, shippingMethod: IShippingMethod): ListingForBuyerShippingFields['shipping']['shippingPrices'][0] {
  const shippingMethodConfigs = listing.shipping?.shippingPrices || [];

  return shippingMethodConfigs.find((price: IShippingPrice) => {
    return price.shippingMethod === shippingMethod;
  });
}

export function getStandardShippingPrice(listing: IListing): IMoney | undefined {
  return shippingPriceDataForMethod(listing, IShippingMethod.SHIPPED)?.rate;
}

export function getStandardShippingMethod(listing: IListing) {
  return shippingPriceDataForMethod(listing, IShippingMethod.SHIPPED);
}

export function getCombinedShippingPrice(listing: IListing): IMoney | undefined {
  return shippingPriceDataForMethod(listing, IShippingMethod.COMBINED_SHIPPING)?.rate;
}

export function getExpeditedShippingPrice(listing: IListing): IMoney | undefined {
  return shippingPriceDataForMethod(listing, IShippingMethod.EXPEDITED_SHIPPING)?.rate;
}

function PlusShippingGeneric() {
  return (
    <div className="listing-shipping-display">
      {I18n.t('commons.plusShipping')}
    </div>
  );
}

function PlusShipping({ priceObject }: IPlusShippingProps) {
  return (
    <span className="listing-shipping-display__original">
      {'+ '}
      <PriceDisplay
        amountCents={priceObject.amountCents}
        display={priceObject.display}
      />
      {` ${I18n.t('commons.shipping')}`}
    </span>
  );
}

export function FreeExpeditedShippingDisplay({ hideLabel = false }) {
  return (
    <RCTooltip text={I18n.t('commons.freeExpeditedShippingInfo')} type="plain">
      <span className="listing-shipping-display listing-shipping-display--free-expedited">
        {hideLabel ? null : I18n.t('commons.freeExpeditedShipping')}
      </span>
    </RCTooltip>
  );
}

function LocalPickupOnlyDisplay() {
  return (
    <div className="listing-shipping-display">
      {I18n.t('commons.localPickupOnly')}
    </div>
  );
}

function InstantDownloadDisplay() {
  return (
    <div className="listing-shipping-display">
      {I18n.t('commons.instantDownload')}
    </div>
  );
}

function FreeShippingDisplay() {
  return (
    <div className="listing-shipping-display listing-shipping-display--free">
      {I18n.t('commons.freeShipping')}
    </div>
  );
}

function FsoxActiveDisplay({ standardShippingPrice }) {
  return (
    <div className="listing-shipping-display listing-shipping-display--fsox">
      <span className="td-line-through mr-1">
        <PlusShipping priceObject={standardShippingPrice} />
      </span>
      <RCTooltip text={I18n.t('commons.freeBecauseThreshold')} type="underline">
        {I18n.t('commons.freeShipping')}
      </RCTooltip>
    </div>
  );
}

function StandardShippingDisplay({
  standardShippingPrice,
  combinedShippingPrice = null,
  showCombinedRate = false,
  useCombinedShippingStrikethrough = false,
}) {
  const combinedShippingPriceAvailable = !!combinedShippingPrice;
  const shouldRenderCombinedShippingCallout = showCombinedRate && combinedShippingPriceAvailable;
  const showCombinedShippingStrikethrough = useCombinedShippingStrikethrough && shouldRenderCombinedShippingCallout;
  const hasFreeCombinedShipping = combinedShippingPriceAvailable && combinedShippingPrice.amountCents === 0;

  return (
    <div
      className={classNames(
        'listing-shipping-display',
        {
          'listing-shipping-display--combined': showCombinedShippingStrikethrough,
        },
      )}
    >
      <PlusShipping priceObject={standardShippingPrice} />

      {shouldRenderCombinedShippingCallout && (
        (
          <div className="listing-shipping-display__combined">
            <RCTooltip
              text={I18n.t('commons.howToCombinedShippingTooltip')}
              type="underline"
            >
              <span className="listing-shipping-display__tooltip">
                <PriceDisplay
                  amountCents={combinedShippingPrice.amountCents}
                  display={combinedShippingPrice.display}
                  showFree={hasFreeCombinedShipping}
                />
                {` ${I18n.t('commons.whenYouCombineShipping')}`}
              </span>
            </RCTooltip>
          </div>
        )
      )}
    </div>
  );
}

export default function ListingShippingDisplay({
  listing,
  shopSubtotalForListingCents,
  showCombinedRate,
  useCombinedShippingStrikethrough,
  showBadges = true,
}: IProps) {
  const user = useUser();

  if (isDigital(listing)) { return (<InstantDownloadDisplay />); }

  const { freeDomesticShippingRate } = listing.shop || {};
  const standardShippingData = shippingPriceDataForMethod(listing, IShippingMethod.SHIPPED);
  const expeditedShippingData = shippingPriceDataForMethod(listing, IShippingMethod.EXPEDITED_SHIPPING);
  const standardShippingPrice = getStandardShippingPrice(listing);
  const expeditedShippingPrice = getExpeditedShippingPrice(listing);

  const fsoxPriceAvailable = !!standardShippingPrice && !!freeDomesticShippingRate?.freeShippingThreshold;
  const buyerInFreeShippingThresholdRegion = fsoxPriceAvailable && freeDomesticShippingRate.regionCode === user.shippingRegionCode;
  const listingExceedsThreshold = fsoxPriceAvailable && freeDomesticShippingRate.freeShippingThreshold.amountCents <= shopSubtotalForListingCents;
  const fsoxActive = buyerInFreeShippingThresholdRegion && listingExceedsThreshold && standardShippingPrice.amountCents !== 0;

  if (showBadges && listing.shipping?.freeExpeditedShipping) {
    if (expeditedShippingData?.regional) {
      return (
        <LocationBasedShippingDisplay
          expeditedShippingPrice={expeditedShippingPrice}
          postalCode={user.postalCode}
        />
      );
    }
    return (<FreeExpeditedShippingDisplay />);
  }

  if (listing.shipping?.localPickupOnly) { return (<LocalPickupOnlyDisplay />); }

  if (standardShippingData?.carrierCalculated || standardShippingData?.regional) {
    return (
      <LocationBasedShippingDisplay
        standardShippingPrice={standardShippingPrice}
        postalCode={standardShippingData.postalCode || user.postalCode}
      />
    );
  }

  if (!standardShippingPrice) { return (<PlusShippingGeneric />); }

  if (showBadges && standardShippingPrice?.amountCents === 0) {
    return (<FreeShippingDisplay />);
  }

  if (fsoxActive) {
    return (<FsoxActiveDisplay standardShippingPrice={standardShippingPrice} />);
  }

  return (
    <StandardShippingDisplay
      standardShippingPrice={standardShippingPrice}
      showCombinedRate={showCombinedRate}
      combinedShippingPrice={getCombinedShippingPrice(listing)}
      useCombinedShippingStrikethrough={useCombinedShippingStrikethrough}
    />
  );
}
