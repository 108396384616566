import I18n from 'i18n-js';
import React from 'react';
import errorImageSrc from '../../../images/top-level/500.svg';
import { useUser } from '@reverbdotcom/commons/src/user_hooks';
import * as Sentry from '@sentry/browser';
import { IUser } from '@reverbdotcom/commons';

interface Props {
  hide?: boolean;
}

const SENTRY_SEARCH = 'https://reverb-llc.sentry.io/issues/?query=';

export default function ErrorDisplay({ hide = false }: Props) {
  const user = useUser();

  if (hide) {
    return null;
  }

  const query = buildQuery(user);
  const sentryLink = `${SENTRY_SEARCH}${query}`;

  return (
    <div className="error scaling-mtb-10 align-center">
      <img src={errorImageSrc} alt={I18n.t('discovery.errorAltText')} />
      <div className="mb-2">
        <h3 className="heading-3 scaling-mb-1">
          {I18n.t('discovery.errorDisplay.message')}
        </h3>
        <h3 className="heading-3">
          {I18n.t('discovery.errorDisplay.looking')}
        </h3>
      </div>
      <a href="/" className="button button--primary mr-1 mobile-mb-1">
        {I18n.t('discovery.errorDisplay.homepage')}
      </a>
      <a href="/page/contact" className="button">
        {I18n.t('discovery.errorDisplay.contact')}
      </a>
      {user.isAdmin && <div className="mt-4">
        <a href={sentryLink} className="button-as-link color-red icon-l-search">
          Sentry Error Search
        </a>
      </div>}
      <div className="mt-6 d-flex fx-justify-center">
        <pre>
          <code className="code-snippet">
            {query}
          </code>
        </pre>
      </div>
    </div>
  );
}

function buildQuery(user: Partial<IUser>) {
  const eventId = Sentry.lastEventId();

  if (eventId) {
    return `id:${eventId}`;
  }

  return `request_id:${user.requestId}`;
}
