import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';

class ShareInput extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      active: false,
    };
  }

  componentDidUpdate() {
    if (this.state.active) {
      const input = this.shareInput;
      input.focus();
      input.setSelectionRange(0, input.value.length);
    }
  }

  toggleShareInput(event) {
    event.preventDefault();
    this.setState({ active: !this.state.active });
  }

  renderHintText() {
    if (this.props.customShortlinkTip.length > 0) {
      return (
        <p>{this.props.customShortlinkTip}</p>
      );
    }

    return (
      <p>{I18n.t('js.shared.shareInput.shortlinkTip')}</p>
    );
  }

  render() {
    return (
      <div className={classNames('shareinput', { 'shareinput--active': this.state.active })}>
        <button
          className="shareinput__trigger"
          onClick={event => this.toggleShareInput(event)}
          aria-label={I18n.t('js.shared.shareInput.copyURL')}
        >
          <i className="fa fa-share-alt" />
          {I18n.t('js.shared.shareInput.copyURL')}
        </button>
        <div className="shareinput__popover">
          {this.renderHintText()}
          <input type="text" ref={(shareInput) => { this.shareInput = shareInput; }} value={this.props.href} onChange={function () {}} />
        </div>
      </div>
    );
  }
}

ShareInput.propTypes = {
  href: PropTypes.string.isRequired,
  customShortlinkTip: PropTypes.string,
};

ShareInput.defaultProps = {
  customShortlinkTip: '',
};

export default ShareInput;
