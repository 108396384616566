import I18n from 'i18n-js';
import React from 'react';
import { V3 } from '../../api_request';
import CurationActions from '../actions/curation_actions';
import classNames from 'classnames';
import { PageSettings } from '../../marketplace/map_params';
import { Paths } from '../../shared/url_helpers';
import { V4CoreClient } from '../../../lib/core_client';
import { core_apimessages_CollectionHeader_CollectionType } from '@reverbdotcom/commons/src/gql/graphql';

interface IProps {
  listingId?: string;
  pageSettings?: PageSettings;
  host?: string; // used for testing
  bumped?: boolean;
}

interface IState {
  bumped: boolean;
  featuredInSet: boolean;
  featuredInSetLoading: boolean;
  bumpError: boolean;
  featureInSetError: boolean;
  curatedSetId: string;
}
export default class TileCurationView extends React.Component<IProps, IState> {
  static defaultProps: Partial<IProps> = {
    host: window?.location?.host,
  };

  state = {
    bumped: this.props.bumped || false,
    curatedSetId: '',
    featuredInSet: false,
    featuredInSetLoading: false,
    featureInSetError: false,
    bumpError: false,
  };

  get bumpHref() {
    return `/admin/bumps/${this.props.listingId}/bump`;
  }

  get curatedSetId() {
    return this.state.curatedSetId;
  }

  get featureInSetHref() {
    const { listingId } = this.props;
    return `/admin/curated_sets/${this.curatedSetId}/feature?product_id=${listingId}`;
  }

  componentDidMount() {
    if (this.curatedSetSlug()) {
      this.fetchCuratedSet();
    }
  }

  fetchCuratedSet = () => {
    this.setState({ featuredInSetLoading: true });

    V4CoreClient
      .get(Paths.apiListingsCuratedSets.expand({ ids: this.props.listingId, curated_set_slugs: this.curatedSetSlug() }))
      .then((res: any) => {
        const { curated_sets_for_listings } = res;
        const { curated_sets } = curated_sets_for_listings[0];
        this.setState({
          curatedSetId: curated_sets[0].id,
          featuredInSet: curated_sets[0].featured_in_set,
          featuredInSetLoading: false,
        });
      }).catch(() => {
        this.setState({ featureInSetError: true, featuredInSetLoading: false });
      });
  };

  curatedSetSlug() {
    if (this.props.pageSettings?.collectionType === core_apimessages_CollectionHeader_CollectionType.CuratedSet) {
      return this.props.pageSettings.collectionSlug;
    }
    return null;
  }

  bumpListing = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const req = V3.post(this.bumpHref).then(() => {
      this.setState({ bumped: true });
    });

    req.fail(() => {
      this.setState({ bumpError: true });
    });
  };

  toggleFeaturedInSet = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (this.state.featuredInSet) {
      this.unfeatureInSet();
    } else {
      this.featureInSet();
    }
  };

  featureInSet() {
    V3.post(this.featureInSetHref).done(() => {
      this.setState({ featuredInSet: true });
    }).fail(() => {
      this.setState({ featureInSetError: true });
    });
  }

  unfeatureInSet() {
    V3.destroy(this.featureInSetHref).done(() => {
      this.setState({ featuredInSet: false });
    }).fail(() => {
      this.setState({ featureInSetError: true });
    });
  }

  startCuration = (e) => {
    e.preventDefault();
    e.stopPropagation();

    CurationActions.fetchIndex({
      product_id: this.props.listingId,
    });
  };

  render() {
    if (this.props.host === 'reverb.com') return null;

    return (
      <>
        <button
          className="button button--mini mb-1"
          onClick={this.startCuration}
          type="button"
        >
          {I18n.t('discovery.curation.curateButton')}
        </button>
        <AdminBumpButton
          hasError={this.state.bumpError}
          onClick={this.bumpListing}
          isBumped={this.state.bumped}
        />
        <CuratedSetControls
          hasError={this.state.featureInSetError}
          isFeatured={this.state.featuredInSet}
          curatedSetID={this.curatedSetId}
          onClick={this.toggleFeaturedInSet}
          loading={this.state.featuredInSetLoading}
        />
      </>
    );
  }
}

function CuratedSetControls(props: {
  isFeatured: boolean;
  hasError: boolean;
  curatedSetID: string;
  onClick: (e) => void;
  loading: boolean;
}) {
  const { curatedSetID, onClick, isFeatured, hasError, loading } = props;

  if (loading) {
    return (
      <button
        className="button button--mini mb-1"
        type="button"
        disabled
      >
        Loading...
      </button>
    );
  }

  if (!curatedSetID) { return null; }

  if (hasError) {
    return (
      <div className="color-red">
        {I18n.t('discovery.curation.error')}
      </div>
    );
  }

  const text = isFeatured ? I18n.t('discovery.curation.featuredInSet') : I18n.t('discovery.curation.feature');

  return (
    <button
      className="button button--mini mb-1"
      onClick={onClick}
      data-featured-button
      type="button"
    >
      <span className={classNames({ 'icon-l-check': isFeatured })}>
        {text}
      </span>
    </button>
  );
}

function AdminBumpButton(props: {
  hasError: boolean;
  isBumped: boolean;
  onClick: (e) => void;
}) {
  const { hasError, isBumped, onClick } = props;

  if (hasError) {
    return I18n.t('discovery.curation.error');
  }

  if (isBumped) {
    return (
      <button
        className="button button--mini mb-1"
        data-disabled
        type="button"
      >
        {I18n.t('discovery.curation.bumped')}
      </button>
    );
  }

  return (
    <button
      onClick={onClick}
      className="button button--mini mb-1"
      type="button"
    >
      {I18n.t('discovery.curation.bumpButton')}
    </button>
  );
}
