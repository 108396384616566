import I18n from 'i18n-js';
import React from 'react';
import { SanitizedRender } from '@reverbdotcom/commons';
import { ReverbPaymentsPayoutMethod } from './payment_provider';
import PlaidLinkContainer from './plaid_link';
import BankAccountFieldsSelector from '../shop/direct_checkout_profile/bank_account_fields/bank_account_fields_selector';
import BankAccountFields from './bank_account_fields';

const BankAccountInformation = ({
  accountRepEmail,
  accountRepName,
  bankChangesLocked,
  countryCodeOptions,
  formPrefix,
  isGetPaid,
  isPlaidDcp,
  isPlaidEnabled,
  nameForField,
  onCountryChange,
  onCurrencyChange,
  payoutCurrencies,
  pendingPlaidAccountId,
  profileAttributes,
  selectedPayoutCurrency,
  shopCountryCode,
  shopCurrency,
  showCollapsedAchSummary,
  showPlaidSummary,
  summaryConfig,
  toggleEditing,
}) => {
  const [selectedCountryCode, setSelectedCountryCode] = React.useState(profileAttributes.countryCode);

  const handleChangeCountry = React.useCallback(
    (countryCode) => {
      setSelectedCountryCode(countryCode);
      onCountryChange(countryCode);
    },
    [onCountryChange],
  );

  // pendingPlaidAccountId is only present for manual verification cases
  // see payout_bank_account_form.tsx
  if (bankChangesLocked && !pendingPlaidAccountId) {
    return (
      <SanitizedRender
        htmlTag="span"
        id="bankChangeHelpText"
        className="weight-bold"
        html={I18n.t(
          'discovery.payoutMethod.directCheckoutProfile.bankChangesLockedHtml',
          {
            icon: '<span class="fa fa-lock"></span>',
            linkOpen: `<a href="mailto:${accountRepEmail}?subject=Account%20Unlock%20Request">`,
            name: accountRepName,
            linkClose: '</a>',
          },
        )}
      />
    );
  }

  if (isPlaidEnabled) {
    return (
      <PlaidLinkContainer
        summaryConfig={summaryConfig}
        openHandlerOnly={(showCollapsedAchSummary || showPlaidSummary) && isPlaidDcp}
        pendingPlaidAccountId={pendingPlaidAccountId}
        shopCountryCode={shopCountryCode}
      />
    );
  }

  return showCollapsedAchSummary ? (
    // disabling inline here so we can enable this rule globally, but this should be fixed the next time this file is touched
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    (<a
      data-change-link
      dangerouslySetInnerHTML={{ __html: I18n.t(
        'js.dashboard.selling.shopPolicies.directCheckoutProfile.useADifferentAccountHtml',
        { icon: '<span class="fa fa-pencil"></span>' },
      ) }}
      onClick={toggleEditing}
    />)
  ) : (
    <div>
      <p className="hint mb-2 mobile-mt-2 mobile-mb-4">
        {I18n.t('discovery.payoutMethod.directCheckoutProfile.weNeedYourBankInfo')}
      </p>
      <input
        value={ReverbPaymentsPayoutMethod.ACH}
        type="hidden"
        name={`${formPrefix}[payout_method]`}
      />
      {isGetPaid ? (
        <BankAccountFieldsSelector
          selectedBankCountry={selectedCountryCode}
          nameForField={nameForField}
          onCountryChange={handleChangeCountry}
          onCurrencyChange={onCurrencyChange}
          countryCodeOptions={countryCodeOptions}
          currencies={payoutCurrencies[selectedCountryCode].supported}
          selectedPayoutCurrency={selectedPayoutCurrency}
          shopCurrency={shopCurrency}
          dashboardView
        />
      ) : (
        <BankAccountFields
          inputNamePrefix={formPrefix}
          countryCode={profileAttributes.countryCode}
          onCountryChange={onCountryChange}
          selectedPayoutCurrency={selectedPayoutCurrency}
          onCurrencyChange={onCurrencyChange}
          shopCurrency={shopCurrency}
        />
      )}

    </div>
  );
};

export default BankAccountInformation;
