import I18n from 'i18n-js';
import React from 'react';

import { RCSwitch } from '@reverbdotcom/cadence/components';

interface IProps {
  checked: boolean;
  onChange: () => void;
  displayWeight: string;
  lengthWidthHeightSummary: string;
}

export function SuggestedPackageToggle({ checked, onChange, displayWeight, lengthWidthHeightSummary }: IProps) {
  return (
    <div className="suggested-package-toggle">
      <RCSwitch
        label={I18n.t('discovery.sellForm.shippingSection.domesticShippingCard.reverbPackageDataToggle.label')}
        sublabel={I18n.t('discovery.sellForm.shippingSection.domesticShippingCard.reverbPackageDataToggle.subLabel', {
          lengthWidthHeightSummary,
          displayWeight,
        })}
        checked={checked}
        onChange={onChange}
      />

      <p className="suggested-package-toggle__description">
        {I18n.t('discovery.sellForm.shippingSection.domesticShippingCard.reverbPackageDataToggle.description')}
      </p>
    </div>
  );
}
