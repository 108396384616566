import React from 'react';
import { Route } from '@reverbdotcom/commons/src/route_settings';
import { MParticlePageName } from '@reverbdotcom/commons/src/elog/mparticle_types';

export const WatchedProductsRoute = (
  <Route
    key="watched_products"
    path="watched_products"
    props={{
      clientSide: true,
      pageViewEvent: {
        pageName: MParticlePageName.WatchList,
      },
    }}
    getComponent={(_location, cb) => {
      import(
        '../components/watchlist/watchlist_container'
      ).then(m => cb(null, m.default));
    }}
  />
);

export const PublicWatchedProductsRoute = (
  <Route
    key="watched_products/shared/:profile_slug"
    path="watched_products/shared/:profile_slug"
    props={{
      clientSide: true,
      pageViewEvent: {
        pageName: MParticlePageName.PublicWatchList,
      },
    }}
    getComponent={(_location, cb) => {
      import(
        '../components/watchlist/public_watchlist_layout'
      ).then(m => cb(null, m.default));
    }}
  />
);
