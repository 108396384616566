import React from 'react';

import {
  core_apimessages_Country as ICountry,
  core_apimessages_Address as IAddress,
  core_apimessages_Error as IError,
} from '@reverbdotcom/commons/src/gql/graphql';
import AddressRow from './address_row';
import ModalDialog from '@reverbdotcom/commons/src/components/modal_dialog';
import bind from '@reverbdotcom/commons/src/bind';
import AddressForm from './address_form';
import ErrorDisplay from '../discovery/error_display';
import {
  IAddressFormData,
  buildBlankAddressFormData,
  buildAddressFormDataFromAddress,
} from './address_form_data';

import ScrollTarget from '@reverbdotcom/commons/src/components/scroll_target';
import I18n from 'i18n-js';
import EmptyStateImage from '../../../images/icons/empty-states/no-addresses.svg';
import withAddressBookApollo, { IAddressResponse } from './with_address_book_apollo';
import { withUserContext, IUser } from '@reverbdotcom/commons/src/components/user_context_provider';
import { AddressSuggestionModal } from './AddressSuggestionModal';

interface IProps {
  loading: boolean;
  error: boolean;
  addresses: IAddress[];
  countries: ICountry[];
  onCreate(IAddress): Promise<any>;
  onDelete(id: string): Promise<any>;
  onMakePrimary(address: IAddress): void;
  onUpdate(address: IAddress): Promise<any>;
  user: IUser;
}

interface IState {
  addressFormData: IAddressFormData;
  modalOpen: boolean;
  saving: boolean;
  shownAddressCount: number;
  addressErrors: IError[];
  addressId: string;
}

const PAGE_SIZE = 5;

class AddressBook extends React.Component<IProps, IState> {
  state = {
    addressFormData: buildBlankAddressFormData(this.props.user),
    modalOpen: false,
    saving: false,
    shownAddressCount: PAGE_SIZE,
    addressErrors: [],
    addressId: null,
  };

  addressForm = null;

  get noAddresses() {
    return this.props.addresses.length === 0;
  }

  get isEditingExistingAddress() {
    return this.state.modalOpen &&
      this.state.addressFormData.uuid.length > 0 &&
      !!this.addressCurrentlyEditing;
  }

  get editedAddressIsCurrentPrimary() {
    return this.noAddresses || (this.isEditingExistingAddress && this.addressCurrentlyEditing.primary);
  }

  get addressCurrentlyEditing() {
    return this.props.addresses.find(address => address.uuid === this.state.addressFormData.uuid);
  }

  @bind
  setAddressFormRef(ref) {
    this.addressForm = ref;
  }

  @bind
  handleAddNewAddress() {
    this.setState({
      addressFormData: buildBlankAddressFormData(this.props.user),
      modalOpen: true,
    });
  }

  @bind
  closeModal() {
    this.setState({
      modalOpen: false,
      addressErrors: [],
    });
  }

  @bind
  openModal() {
    this.setState({ modalOpen: true });
  }

  @bind
  handleUpdateField(addressAttributes: Partial<IAddressFormData>) {
    this.setState({
      addressFormData: { ...this.state.addressFormData, ...addressAttributes },
    });
  }

  @bind
  handleAddressEdit(address) {
    this.setState({
      addressFormData: buildAddressFormDataFromAddress(address),
      modalOpen: true,
    });
  }

  @bind
  showDeleteConfirmation() {
    return window.confirm(I18n.t('discovery.addressBook.form.deleteConfirmation'));
  }

  @bind
  async handleDelete() {
    if (this.state.saving) { return; }

    if (this.showDeleteConfirmation()) {
      if (this.isEditingExistingAddress) {
        this.setState({ saving: true });
        const success = await this.props.onDelete(this.state.addressFormData.uuid);
        if (success) {
          this.saveSuccess();
        } else {
          this.setState({ saving: false });
        }
      }
    }
  }

  @bind
  handleSubmit() {
    if (this.state.saving) { return; }

    const form = this.addressForm as HTMLFormElement;
    if (form && form.reportValidity && !form.reportValidity()) { return; }

    this.setState({ saving: true });

    this.createOrUpdateAddress()
      .catch(this.handleErrors);
  }

  @bind
  handleShowMore(e) {
    e.preventDefault();
    this.setState({ shownAddressCount: this.state.shownAddressCount + PAGE_SIZE });
  }

  async createOrUpdateAddress() {
    if (this.noAddresses) {
      this.state.addressFormData.primary = true;
    }

    if (this.isEditingExistingAddress) {
      const { address, errors } = await this.props.onUpdate(this.state.addressFormData);
      this.handleResponse({ address, errors });
    } else {
      const { address, errors } = await this.props.onCreate(this.state.addressFormData);
      this.handleResponse({ address, errors });
    }
  }

  handleResponse({ address, errors }: IAddressResponse) {
    if (address) {
      this.setState({ addressId: address.uuid });
      this.saveSuccess();
    } else {
      this.handleErrors(errors);
    }
  }

  @bind
  handleErrors(errors: IError[]) {
    this.setState({
      addressErrors: errors,
      saving: false,
    });
  }

  @bind
  saveSuccess() {
    if (!this.isEditingExistingAddress) {
      this.setState({ shownAddressCount: this.state.shownAddressCount + 1 });
    }
    this.setState({
      saving: false,
      modalOpen: false,
      addressErrors: [],
    });
  }

  @bind
  hideAddressSuggestionModal() {
    this.setState({ addressId: null });
  }

  scroll() {
    if (this.refs.scrollTarget) {
      const typedScrollTarget = this.refs.scrollTarget as ScrollTarget;
      typedScrollTarget.scrollToTop();
    }
  }

  formHeaderTitle() {
    if (this.isEditingExistingAddress) {
      return I18n.t('discovery.addressBook.form.title.edit');
    }
    return I18n.t('discovery.addressBook.form.title.create');
  }

  deleteCallback() {
    if (this.isEditingExistingAddress && !this.editedAddressIsCurrentPrimary) {
      return this.handleDelete;
    }
    return null;
  }

  renderAddresses() {
    const addresses = this.props.addresses.slice(0, this.state.shownAddressCount);
    return addresses.map((address) => {
      return (
        <AddressRow
          key={address.uuid}
          address={address}
          onMakePrimary={this.props.onMakePrimary}
          onEdit={this.handleAddressEdit}
        />
      );
    });
  }

  renderAddressList() {
    if (this.noAddresses) {
      return this.renderEmpty();
    }

    return (
      <ul className="actionable-rows">
        {this.renderAddresses()}
        {this.renderPagination()}
      </ul>
    );
  }

  renderPagination() {
    if (this.state.shownAddressCount >= this.props.addresses.length) {
      return null;
    }

    return (
      <li className="actionable-row">
        <div className="actionable-row__content">
          <button
            data-show-more-addresses
            className="button-as-link"
            onClick={this.handleShowMore}
          >
            {I18n.t('discovery.addressBook.loadMore')}
          </button>
        </div>
      </li>
    );
  }

  renderEmpty() {
    return (
      <div className="align-center" data-no-addresses-image>
        <img
          className="d-block width-50 mlr-auto"
          src={EmptyStateImage}
          alt="Placeholder"
        />
        <p className="lh-120 mtb-4">{I18n.t('discovery.addressBook.noAddresses')}</p>
      </div>
    );
  }

  renderPlaceholderRow() {
    return (
      <li className="actionable-row actionable-row--placeholder">
        <div className="actionable-row__content"></div>
        <ul className="actionable-row__actions">
          <li className="actionable-row__action"></li>
        </ul>
      </li>
    );
  }

  renderPlaceholderContent() {
    return (
      <div data-placeholder-rows>
        <ul className="actionable-rows">
          {this.renderPlaceholderRow()}
          {this.renderPlaceholderRow()}
          {this.renderPlaceholderRow()}
        </ul>
      </div>
    );
  }

  render() {
    if (this.props.error) { return <ErrorDisplay />; }

    if (this.props.loading) {
      return this.renderPlaceholderContent();
    }

    return (
      <div>
        <ScrollTarget onlyScrollUp ref="scrollTarget" />
        {!!this.state.addressId &&
          <AddressSuggestionModal
            id={this.state.addressId}
            onModalClose={this.hideAddressSuggestionModal}
            refetchQueryName="AddressBook"
          />
        }
        {this.renderAddressList()}
        <div className="mt-4 align-center">
          <button data-add-address-button
            onClick={this.handleAddNewAddress}
            className="button button--primary"
          >
            {I18n.t('discovery.addressBook.addNewBtn')}
          </button>
        </div>
        <ModalDialog
          headerTitle={this.formHeaderTitle()}
          isOpen={this.state.modalOpen}
          onRequestClose={this.closeModal}
          onDelete={this.deleteCallback()}
          onSubmit={this.handleSubmit}
          isSaving={this.state.saving}
          backButtonText={I18n.t('discovery.checkout.addressBook.cancel')}
          containsFormElement
        >
          <AddressForm
            addressFormData={this.state.addressFormData}
            countries={this.props.countries}
            onUpdateField={this.handleUpdateField}
            addressIsCurrentPrimary={this.editedAddressIsCurrentPrimary}
            errors={this.state.addressErrors}
            formRef={this.setAddressFormRef}
          />
        </ModalDialog>
      </div>
    );
  }
}

const AddressBookWithRQL = withUserContext(withAddressBookApollo(AddressBook));

export {
  AddressBook,
  AddressBookWithRQL,
};
