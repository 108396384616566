import React from 'react';
import I18n from 'i18n-js';
import { MINIMUM_AUTO_REJECT_THRESHOLD, MAXIMUM_AUTO_REJECT_THRESHOLD } from './selling_policies_offer_bot';

import { RCTextInput, RCCheckbox } from '@reverbdotcom/cadence/components';

export interface IProps {
  lowballThreshold: string;
}

export function ShopSettingsOfferPolicy(props: IProps) {
  const [enabled, setEnabled] = React.useState(!!props.lowballThreshold);
  const [lowballThreshold, setLowballThreshold] = React.useState(props.lowballThreshold || '');

  function lowballThresholdErrorMessage() {
    if (lowballThreshold) {
      const parsedThreshold = parseInt(lowballThreshold);
      if (parsedThreshold > MAXIMUM_AUTO_REJECT_THRESHOLD || parsedThreshold < MINIMUM_AUTO_REJECT_THRESHOLD) {
        return I18n.t('discovery.shopSettings.offerPolicy.lowballPercentageError', { maximum: MAXIMUM_AUTO_REJECT_THRESHOLD, minimum: MINIMUM_AUTO_REJECT_THRESHOLD });
      }
    }

    return null;
  }

  const lowballThresholdError = lowballThresholdErrorMessage();

  return (
    <>
      <div className="mb-2">
        <RCCheckbox
          checked={enabled}
          label={I18n.t('discovery.shopSettings.offerPolicy.avoidLowballOffersLabel')}
          sublabel={I18n.t('discovery.shopSettings.offerPolicy.avoidLowballOffersSublabel')}
          onChange={() => { setEnabled(!enabled); }}
        />
      </div>

      {enabled && (
        <RCTextInput
          name="shop_policies_form[shop][shop_preferences_attributes][auto_reject_low_offer_threshold]"
          id="shop_policies_form_shop_shop_preferences_attributes_auto_reject_low_offer_threshold"
          label={I18n.t('discovery.shopSettings.offerPolicy.lowballPercentageLabel')}
          helpText={I18n.t('discovery.shopSettings.offerPolicy.lowballPercentageHelpText', { maximum: MAXIMUM_AUTO_REJECT_THRESHOLD, minimum: MINIMUM_AUTO_REJECT_THRESHOLD })}
          value={lowballThreshold}
          onChange={(evt) => {
            setLowballThreshold(evt.target.value);
          }}
          inputMode="numeric"
          type="number"
          errorText={lowballThresholdError}
          maxLength={2}
        />
      )}

      {!enabled && (
        <input
          type="hidden"
          name="shop_policies_form[shop][shop_preferences_attributes][auto_reject_low_offer_threshold]"
          id="shop_policies_form_shop_shop_preferences_attributes_auto_reject_low_offer_threshold"
          value=""
        />
      )}

      <input
        type="hidden"
        name="shop_policies_form[shop][shop_preferences_attributes][auto_reject_low_offers]"
        id="shop_policies_form_shop_shop_preferences_attributes_auto_reject_low_offers"
        value={enabled ? '1' : '0'}
      />
    </>
  );
}

export default ShopSettingsOfferPolicy;
