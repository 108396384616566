import React from 'react';
import I18n from 'i18n-js';
import CoreLink from '@reverbdotcom/commons/src/components/core_link';
import { RCIcon } from '@reverbdotcom/cadence/components';
import { ReverbLogoIcon } from '@reverbdotcom/cadence/icons/react';
import URLHelpers from './shared/url_helpers';
import UrlHelpers from '../shared/url_helpers';
import { SecureCheckoutMessage } from './checkout/SecureCheckoutMessage';

export default function CheckoutHeader() {
  return (
    <>
      <header className="checkout-header">
        <div className="checkout-header__inner">
          <div className="checkout-header__content">
            <CoreLink
              to={UrlHelpers.rootPath}
              className="checkout-header__logo"
              aria-label={I18n.t('discovery.checkoutHeader.reverb')}
            >
              <RCIcon svgComponent={ReverbLogoIcon} />
            </CoreLink>
            <SecureCheckoutMessage />
            <CoreLink
              to={URLHelpers.cartPath}
              className="checkout-header__cart-link"
            >
              {I18n.t('discovery.checkoutHeader.viewCart')}
            </CoreLink>
          </div>
        </div>
      </header>
    </>
  );
}
