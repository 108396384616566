import { gql } from '@reverbdotcom/commons/src/gql';
import { IUser } from '@reverbdotcom/commons/src/components/user_context_provider';
import { isEmpty, omitBy } from 'lodash';
import {
  core_apimessages_Address as Address,
  core_apimessages_CheckoutLineItem_Type as CheckoutLineItemType,
  AffirmCheckoutBuilderFieldsFragment,
} from '@reverbdotcom/commons/src/gql/graphql';
import { Paths, withWebHost } from '../shared/url_helpers';
import { buildCheckoutPaths } from '../../lib/checkoutPaths';
import * as Affirm from '@reverbdotcom/commons/src/affirm';
import { CA } from '@reverbdotcom/commons/src/constants';
import { AFFIRM_COUNTRY_CODES, AffirmEnvironment, buildAffirmEnvironment } from '@reverbdotcom/commons/src/affirmEnvironment';

type Checkout = AffirmCheckoutBuilderFieldsFragment;
type Order = Checkout['orders'][0];

export function buildAffirmCheckout({ checkout, user }: {
  checkout: Checkout, user: Partial<IUser>,
}): Affirm.Checkout {
  const affirmEnvironment = buildAffirmEnvironment(user);
  const amounts = findAmounts(checkout);
  const guestEmail = checkout?.guest?.email;
  const buyerEmail = guestEmail ? guestEmail : user.email;

  return {
    merchant: buildMerchant(checkout, affirmEnvironment),
    financing_program: financingProgram(checkout),
    items: checkout.orders.map(orderToItem),
    shipping: mapToAffirmContactAddress({
      address: shippingAddressOrDefault(checkout),
      buyerEmail,
    }),
    billing: mapToAffirmContactAddress({
      address: checkout.billingAddress,
      buyerEmail,
    }),
    currency: amounts.currency,
    shipping_amount: amounts.shippingAmount,
    tax_amount: amounts.taxAmount,
    total: amounts.total,
  };
}

function financingProgram(checkout: Checkout) {
  return checkout.paymentMethod.affirm?.financingProgramSlug;
}

function buildMerchant(checkout: Checkout, env: AffirmEnvironment): Affirm.Merchant {
  const checkoutPaths = buildCheckoutPaths(checkout.id);
  const displayedAmountOwed = {
    amountCents: checkout.total.amount.amountCents,
    currency: checkout.total.amount.currency,
  };

  return {
    user_confirmation_url: withWebHost(checkoutPaths.redirectReturn({ displayedAmountOwed })),
    user_cancel_url: withWebHost(checkoutPaths.show()),
    user_confirmation_url_action: 'GET',
    public_api_key: env.publicAPIKey,
  };
}

function orderToItem(order: Order): Affirm.Item {
  return {
    display_name: order.listing.title,
    sku: order.listing.id,
    qty: order.quantity,
    unit_price: order.subtotal.listingPrice.amountCents,
    item_image_url: order.listing.affirmImages[0]?.source,
    item_url: withWebHost(Paths.item.expand({ id: order.listing.id })),
  };
}

function mapToAffirmContactAddress({ address, buyerEmail }: {
  address: Address,
  buyerEmail: string,
}): Affirm.ContactAddress {
  if (address.countryCode === CA) {
    return {
      name: {
        full: address.name,
      },
      address: {
        street1: address.streetAddress,
        street2: address.extendedAddress,
        city: address.locality,
        region1_code: address.region,
        postal_code: address.postalCode,
        country: AFFIRM_COUNTRY_CODES[address.countryCode],
      },
      phone_number: address.phone,
      email: buyerEmail,
    };
  } else {
    return {
      name: {
        full: address.name,
      },
      address: {
        line1: address.streetAddress,
        line2: address.extendedAddress,
        city: address.locality,
        state: address.region,
        zipcode: address.postalCode,
        country: address.countryCode,
      },
      phone_number: address.phone,
      email: buyerEmail,
    };
  }
}

/**
 * Shipping address will be blank for local pickup. This method defaults the
 * shipping address to the billing address in that case.
 */
function shippingAddressOrDefault(checkout: Checkout) {
  const isShippingAddressBlank = isEmpty(omitBy(checkout.shippingAddress, isEmpty));

  if (isShippingAddressBlank) {
    return checkout.billingAddress;
  }

  return checkout.shippingAddress;
}

function findAmounts(checkout: Checkout) {
  const shippingAmount = findLineItemAmount(checkout, CheckoutLineItemType.AMOUNT_SHIPPING);
  const taxAmount = findLineItemAmount(checkout, CheckoutLineItemType.AMOUNT_TAX);

  return {
    shippingAmount: shippingAmount.amountCents,
    taxAmount: taxAmount.amountCents,
    total: checkout.total.amount.amountCents,
    currency: checkout.total.amount.currency,
  };
}

function findLineItemAmount(checkout: Checkout, lineItemType: CheckoutLineItemType) {
  const lineItem = checkout.lineItems.find((item) => item.type === lineItemType);

  return lineItem?.amount || {};
}

export const AffirmCheckoutBuilderFragment = gql(`
  fragment AffirmCheckoutBuilderFields on Checkout {
    _id
    id
    total {
      amount {
        amountCents
        currency
      }
    }
    orders {
      quantity
      subtotal {
        listingPrice {
          amountCents
        }
      }
      listing {
        _id
        id
        title
        affirmImages: images(input: { transform: "card_square" }) {
          _id
          source
        }
      }
    }
    paymentMethod {
      affirm {
        financingProgramSlug
      }
    }
    shippingAddress {
      _id
      name
      streetAddress
      extendedAddress
      locality
      region
      postalCode
      countryCode
      phone
    }
    billingAddress {
      _id
      name
      streetAddress
      extendedAddress
      locality
      region
      postalCode
      countryCode
      phone
    }
    lineItems {
      type
      amount {
        amountCents
        currency
      }
    }
    guest {
      email
    }
  }
`);
