import {
  core_apimessages_CheckoutPaymentMethod_Type as CheckoutPaymentMethodType,
} from '@reverbdotcom/commons/src/gql/graphql';
import { useURL } from '@reverbdotcom/commons/src/url_context';
import { reverbAppQueryValue } from '../checkoutActionHooks';

/**
 * @param paymentMethodType
 * @returns Boolean, true if the payment method is standalone PayPal and user is not browsing via native app.
 */
export function useStandalonePayPalInlineForm(paymentMethodType: CheckoutPaymentMethodType = CheckoutPaymentMethodType.PAYPAL) {
  const url = useURL();

  return paymentMethodType === CheckoutPaymentMethodType.PAYPAL && !reverbAppQueryValue(url);
}
