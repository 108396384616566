import PropTypes from 'prop-types';
import React from 'react';
import ReactImageCrop from 'react-image-crop/dist/ReactCrop';
import classNames from 'classnames';
import I18n from 'i18n-js';

class Editor extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isCropping: false,
      photo: this.props.photo,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.photo !== this.props.photo) {
      this.state = {
        isCropping: false,
        photo: nextProps.photo,
      };
    }
  }

  handleCropChange(crop) {
    const newCrop = {
      ...crop,
      aspect: 1, // force a square aspect ratio
    };

    this.setState({ crop: newCrop });
  }

  handleImageDoneLoading() {
    this.setState({ isLoadingPhoto: false });
  }

  startCrop() {
    let initialCrop = this.state.photo.crop;
    if (!initialCrop.width) {
      initialCrop = { width: 100, aspect: 1 };
    }

    this.setState({
      isCropping: true,
      crop: initialCrop,
      initialCrop,
    });
  }

  cancelCrop() {
    this.setState({
      isCropping: false,
      crop: this.state.initialCrop,
    });
  }

  saveCrop(callback) {
    const newPhoto = this.state.photo.copy();
    newPhoto.crop = this.state.crop;

    this.setState({ isCropping: false, photo: newPhoto }, callback);
  }

  rotate() {
    const newPhoto = this.state.photo.copy();
    newPhoto.rotate();

    this.setState({ photo: newPhoto, isLoadingPhoto: true });
  }

  savePhoto() {
    if (this.state.isCropping) {
      this.saveCrop(() => {
        this.props.onUpdatePhoto(this.state.photo);
      });
    } else {
      this.props.onUpdatePhoto(this.state.photo);
    }
  }

  closeEditor() {
    this.props.onCloseEditor();
  }

  renderInitialState() {
    const imgBackgroundClassNames = classNames(
      'img-editor__img-background',
      { 'img-editor__img-background--loading': this.state.isLoadingPhoto },
    );

    return (
      <div>
        <div className={imgBackgroundClassNames}>
          <img
            src={this.state.photo.fullUrl}
            className="img-editor__preview-image"
            role="presentation"
            onLoad={() => this.handleImageDoneLoading()}
            alt={I18n.t('discovery.photos.editor.imageAlt')}
          />
        </div>
        <div className="img-editor__edit-actions">
          {/* disabling inline here so we can enable this rule globally, but this should be fixed the next time this file is touched */}
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a
            className="img-editor__edit-action img-editor__edit-action--rotate"
            onClick={() => this.rotate()}
          />
          {/* disabling inline here so we can enable this rule globally, but this should be fixed the next time this file is touched */}
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a
            className="img-editor__edit-action img-editor__edit-action--crop"
            onClick={() => this.startCrop()}
          >
            {I18n.t('discovery.photos.editor.cropToSquare')}
          </a>
        </div>
      </div>
    );
  }

  renderCroppingState() {
    return (
      <div>
        <div className="img-editor__img-background">
          <ReactImageCrop
            src={this.state.photo.fullUrlWithoutCrop}
            crop={this.state.crop}
            onChange={crop => this.handleCropChange(crop)}
            onImageLoaded={crop => this.handleCropChange(crop)}
            keepSelection
          />
        </div>
        <div className="img-editor__edit-actions">
          {/* disabling inline here so we can enable this rule globally, but this should be fixed the next time this file is touched */}
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a
            className="img-editor__edit-action img-editor__edit-action--cancel"
            onClick={() => this.cancelCrop()}
          >
            {I18n.t('discovery.photos.editor.cancel')}
          </a>
          {/* disabling inline here so we can enable this rule globally, but this should be fixed the next time this file is touched */}
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a
            className="img-editor__edit-action img-editor__edit-action--done"
            onClick={() => this.saveCrop()}
          >
            {I18n.t('discovery.photos.editor.done')}
          </a>
        </div>
      </div>
    );
  }

  renderControls() {
    if (this.state.isCropping) {
      return this.renderCroppingState();
    }
    return this.renderInitialState();
  }

  render() {
    return (
      <div className="img-editor">
        <div className="img-editor__editor-frame">
          {this.renderControls()}
        </div>
        <div className="img-editor__actions">
          {/* disabling inline here so we can enable this rule globally, but this should be fixed the next time this file is touched */}
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a
            className="img-editor__action img-editor__action--close"
            onClick={() => this.closeEditor()}
          >
            {I18n.t('discovery.photos.editor.cancel')}
          </a>
          {/* disabling inline here so we can enable this rule globally, but this should be fixed the next time this file is touched */}
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a
            className="img-editor__action img-editor__action--save"
            onClick={() => this.savePhoto()}
          >
            {I18n.t('discovery.photos.editor.save')}
          </a>
        </div>
      </div>
    );
  }
}

Editor.propTypes = {
  photo: PropTypes.object.isRequired,
  onUpdatePhoto: PropTypes.func.isRequired,
  onCloseEditor: PropTypes.func.isRequired,
};

export default Editor;
