// TODO update this to import from commons
// import { gql } from '@reverbdotcom/commons/src/gql';
// eslint-disable-next-line no-restricted-imports
import { gql } from '@apollo/client';
import { ChildProps, DataValue } from '@apollo/client/react/hoc';
import { omit } from 'lodash';
import React from 'react';
import { WithRouterProps, withRouter } from 'react-router';

import { IUser, IUserContext, withUserContext } from '@reverbdotcom/commons';
import RouterHistory from '@reverbdotcom/commons/src/cms/lib/router_history';
import { connect } from '@reverbdotcom/commons/src/connect';
import { IUrl, useURL } from '@reverbdotcom/commons/src/url_context';
import { isCSP, isShopPage } from '@reverbdotcom/commons/src/url_helpers';
import { withGraphql } from '@reverbdotcom/commons/src/with_graphql';

import { Core_FindFavoriteQuery, Core_FindFavoriteQueryVariables, reverb_feed_SearchableType } from '@reverbdotcom/commons/src/gql/graphql';
import { useUser } from '@reverbdotcom/commons/src/user_hooks';
import { mapVarsForMarketplaceQuery } from '../marketplace/marketplace_page';
import { FOLLOW_PROMPT_PARAM } from '../shared/constants';
import { FindFavoriteLayout } from './find_favorite_layout';
import experiments from '@reverbdotcom/commons/src/experiments';
import { experimentInt } from '@reverbdotcom/commons/src/user_context_helpers';
import { ExperimentGroupValue } from '@reverbdotcom/commons/src/components/experiments/experiment_group';

export const FavoriteModal = {
  Save: 'save',
  Signup: 'signup',
  None: 'none',
} as const;
export type FavoriteModalValue = typeof FavoriteModal[keyof typeof FavoriteModal];

type Core_FindFavoriteQuery_Favorite = Core_FindFavoriteQuery['findFavorite']['favorite'];

/**
 * Represents the state of components needed to find a favorite entity and save it.
 */
export interface FindFavoriteViewModel {
  /**
   * A modal with controls to save or unsave the entity (i.e. a search) as a favorite, or sign up as a logged out user
   */
  displayedModal: FavoriteModalValue;
  setDisplayedModal: (val: FavoriteModalValue) => void;

  /**
   * A toast message shown after saving a favorite
   */
  isToastOpen: boolean;
  setIsToastOpen: (val: boolean) => void;

  /**
   * The name of the component triggering the save search modal, indicating an intent to save
   */
  favoriteIntentComponent: string;
  setFavoriteIntentComponent: (val: string) => void;

  /**
   * The potentially saveable favorite entity (i.e. a marketplace search, a CSP, etc)
   * and the current user's notification preferences for it: feed enabled, emails enabled
   */
  favorite: Core_FindFavoriteQuery_Favorite;
  setFavorite: (val: Core_FindFavoriteQuery_Favorite) => void;

  /**
   * Has the current user already saved this favorite?
   */
  isFavorite: boolean;

  /**
   * Can this favorite entity be saved? (It may not be saveable if it has unsupported query params, too many results, etc)
   */
  canFavorite: boolean;
}

export const FindFavoriteContext = React.createContext<FindFavoriteViewModel>({
  displayedModal: FavoriteModal.None,
  setDisplayedModal: (_) => {},
  isToastOpen: false,
  setIsToastOpen: (_) => {},
  isFavorite: false,
  canFavorite: false,
  favorite: {},
  setFavorite: (_) => {},
  favoriteIntentComponent: '',
  setFavoriteIntentComponent: (_) => {},
});

/**
 * Use in any child component of a FindFavoriteContext.Provider
 * to access the current state of the find favorite result.
 */
export function useFindFavorite(): FindFavoriteViewModel {
  return React.useContext(FindFavoriteContext);
}

export const hasNoFiltersApplied = (url: IUrl) => {
  const queryParams = { ...url };
  delete queryParams[FOLLOW_PROMPT_PARAM];
  const utmParams = ['utm_campaign', 'utm_source', 'utm_medium', 'utm_term', 'utm_content'];
  utmParams.forEach(param => delete queryParams[param]);

  return !Object.keys(queryParams).length;
};

export function resolveDisplayedModal(
  data: DataValue<Core_FindFavoriteQuery>,
  user: Partial<IUser>,
  wasFollowPromptParamPresent: boolean,
  url: IUrl,
) {
  if (data.loading) {
    return FavoriteModal.None;
  }

  if (!data.findFavorite?.canFavorite) {
    return FavoriteModal.None;
  }

  const isFavorite = !!data.findFavorite?.favorite?.id;
  if (isFavorite) {
    return FavoriteModal.None;
  }

  if (!wasFollowPromptParamPresent) {
    return FavoriteModal.None;
  }
  if (data.findFavorite?.favorite?.searchableType === reverb_feed_SearchableType.FAVORITE_SHOP && !hasNoFiltersApplied(url)) {
    return FavoriteModal.None;
  }

  if (user.loggedOut) {
    return FavoriteModal.Signup;
  }

  return FavoriteModal.Save;
}

export function useFindFavoriteProvider(
  data: DataValue<Core_FindFavoriteQuery>,
  wasFollowPromptParamPresent: boolean,
): FindFavoriteViewModel {
  const user = useUser();
  const url = useURL();
  const [displayedModal, setDisplayedModal] = React.useState<FavoriteModalValue>(resolveDisplayedModal(data, user, wasFollowPromptParamPresent, url));
  const [isToastOpen, setIsToastOpen] = React.useState<boolean>(false);
  const [favorite, setFavorite] = React.useState<Core_FindFavoriteQuery_Favorite>(data?.findFavorite?.favorite || {});
  const [favoriteIntentComponent, setFavoriteIntentComponent] = React.useState<string>('');

  const expGroup = experimentInt(user, experiments.FEED_MODAL_V2) as ExperimentGroupValue;

  // Not sure why, but without this useEffect that depends on `[data]`, the `favorite` state gets stale.
  // The parent component that calls this function uses `withFindFavoriteQuery`, and apollo re-renders on query data changes as expected.
  // Each time the parent is rendered, it calls `useFindFavoriteProvider(props.data.findFavorite)`,
  // so this function should always be called with the latest `data` and set the initial state correctly.
  React.useEffect(
    () => {
      let dataFavorite = data.findFavorite?.favorite || {};

      // While in the FEED_MODAL_V2 experiment group, if the favorite is not yet saved, enable feed by default
      if (expGroup > 0 && dataFavorite.__typename && (dataFavorite.id?.length || 0) <= 0) {
        dataFavorite = { ...dataFavorite, feedEnabled: true };
      }
      setFavorite(dataFavorite);
    },
    [data],
  );

  React.useEffect(
    () => {
      // When query data changes, set the open/closed state of the modal
      // based on data and the initial presence of the follow_prompt=true param on page load
      setDisplayedModal(resolveDisplayedModal(data, user, wasFollowPromptParamPresent, url));

      // When query data changes, close the toast message if it is open. This fixes a display bug:
      // - The toast message is always synced to the current `favorite` state.
      // - If a user saves a favorite, an 'Added to Favorites' toast message is shown for a few seconds.
      // - If a user changes search filters while this toast is open, it triggers a new query,
      //   which calls `setFavorite` with new data.
      // - The current `favorite` is no longer the previous `favorite` that the toast message was shown for,
      //   and the message switches from 'Added to Favorites' to 'Removed from Favorites'.
      setIsToastOpen(false);

      // When query data has loaded, remove the follow_prompt=true param if present
      const isFollowPromptParamPresent = !!url.query[FOLLOW_PROMPT_PARAM];
      if (!data.loading && isFollowPromptParamPresent) {
        const queryWithoutFollowPrompt = omit(url.query, FOLLOW_PROMPT_PARAM);
        RouterHistory.replace({
          pathname: url.pathname,
          query: queryWithoutFollowPrompt,
        });
      }
    },
    [data],
  );

  React.useEffect(
    () => {
      if (displayedModal === FavoriteModal.None) {
        setFavoriteIntentComponent('');
      }
    }, [displayedModal],
  );

  return {
    displayedModal,
    setDisplayedModal,
    isToastOpen,
    setIsToastOpen,
    isFavorite: !!data.findFavorite?.isFavorite,
    canFavorite: !!data.findFavorite?.canFavorite,
    favorite,
    setFavorite,
    favoriteIntentComponent,
    setFavoriteIntentComponent,
  };
}

export const FIND_FAVORITE_QUERY = gql`
  query Core_FindFavorite(
    $listingsSearchRequest: Input_reverb_search_ListingsSearchRequest
    $shopSlug: String
  ) {
    findFavorite(
      input: {
        listingsSearchRequest: $listingsSearchRequest
        shopSlug: $shopSlug
      }
    ) {
      isFavorite
      canFavorite
      favorite {
        id
        favorited
        searchableId
        searchableType
        title
        emailEnabled
        feedEnabled
        queryParams
        subtitle
        link {
          href
        }
      }
    }
  }
`;

export type ExternalProps = React.PropsWithChildren<{
  displayStyle?: 'button' | 'banner' | 'link'
  ignoreFollowPrompt?: boolean;
  header?: string;
  subtext?: string;
  /** hideLoggedOutModal=true will prevent the SignupSigninModal to open upon rendering, but will still open if clicked */
  hideLoggedOutModal?: boolean;
  showCallout?: boolean;
  componentName?: string;
}>;

export type BaseProps = ExternalProps & IUserContext & WithRouterProps;
export type ConnectedProps = ChildProps<BaseProps, Core_FindFavoriteQuery, Core_FindFavoriteQueryVariables>;

const withFindFavoriteQuery =
  withGraphql<
    BaseProps,
    Core_FindFavoriteQuery,
    Core_FindFavoriteQueryVariables
  >(
    FIND_FAVORITE_QUERY,
    {
      options: (props: BaseProps) => {
        let listingsSearchRequest = mapVarsForMarketplaceQuery(props).inputListings;
        let shopSlug;

        if (isShopPage(props.location) && hasNoFiltersApplied(props.location.query)) {
          listingsSearchRequest = null;
          shopSlug = props.params.shop;
        }

        const shouldSsr = !isCSP(props.location);

        return {
          ssr: shouldSsr,
          variables: {
            listingsSearchRequest,
            shopSlug,
          },
          fetchPolicy: 'network-only',
        };
      },
    },
  );

export function InternalFindFavoriteProvider(props: ConnectedProps) {
  const url = useURL();
  // memoize whether `follow_prompt=true` was initially in the URL query params,
  // for the given `[url.pathname]`, like "/marketplace".
  // When `[url.pathname]` changes, such as from "/marketplace" to "/p/slug", this gets re-evaluated.
  const wasFollowPromptParamPresent = React.useMemo(
    function () {
      return !!url.query[FOLLOW_PROMPT_PARAM];
    },
    [
      url.pathname,
      sortAndStringifyParams(omit(url.query, FOLLOW_PROMPT_PARAM)),
    ],
  );
  const findFavoriteViewModel = useFindFavoriteProvider(
    props.data,
    wasFollowPromptParamPresent,
  );

  return (
    <FindFavoriteContext.Provider value={findFavoriteViewModel}>
      <FindFavoriteLayout />
      {props.children}
    </FindFavoriteContext.Provider>
  );
}

export function sortAndStringifyParams(params: Object) {
  const orderedParams = Object.keys(params).sort().reduce(
    (obj, key) => {
      obj[key] = params[key];
      return obj;
    },
    {},
  );

  return JSON.stringify(orderedParams);
}

/**
 * Use once in a layout component to keep track of a find favorite result,
 * which will stay up-to-date with the current URL and current user.
 * Consume the context within child components via `useFindFavorite();`
 */
export const FindFavoriteProvider = connect([
  withUserContext,
  withRouter,
  withFindFavoriteQuery,
])(InternalFindFavoriteProvider);
