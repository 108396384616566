import React from 'react';
import I18n from 'i18n-js';
import SanitizedRender from './sanitized_render';
import { RCPopover, RCIcon, useMediaQuery } from '@reverbdotcom/cadence/components';
import { InfoCircleIcon } from '@reverbdotcom/cadence/icons/react';
import { REVERB_BUMP } from '../url_helpers';

export function BumpDSACompliancePopover() {
  const position = useMediaQuery('mobile') ? 'bottom-start' : 'right';

  return (
    <div className="mb-2 mt-1">
      <RCPopover
        position={position}
        anchor={
          <button className="button-as-link ml-half d-flex gap-1">
            <span className="color-secondary size-fixed-90">
              {I18n.t('commons.bump.dsaCompliancePopover.title')}
            </span>
            <span className="color-secondary size-fixed-100">
              <RCIcon svgComponent={InfoCircleIcon} inline />
            </span>
            <span className="visually-hidden">{I18n.t('commons.bump.dsaCompliancePopover.learnMore')}</span>
          </button>
        }
      >
        <SanitizedRender
          htmlTag="span"
          html={I18n.t('commons.bump.dsaCompliancePopover.description', {
            linkOpen: `<a href="${REVERB_BUMP}" target=_blank class="text-link" rel="nofollow">`,
            linkClose: '</a>',
          })}
        />
      </RCPopover>
    </div>
  );
}
