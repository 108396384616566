import React from 'react';

export type ImportComponentFunction = () => Promise<{ default: React.ComponentType<any> }>;

// asyncComponent can be used to dynamically load a component in a client JS context,
// for a component that cannot be loaded normally in a server JS context, because
// loading the component would cause runtime errors that crash server side rendering.
//
// Usage:
//
//   const ClientOnlyComponent = asyncComponent<ClientOnlyComponentProps>(() => import('client_only_component'));
//
// asyncComponent can be replaced with React.lazy when frontend is upgraded to React 18,
// but using React.lazy in server JS with React 17 causes this error:
//
//  Error: ReactDOMServer does not yet support Suspense.
//    at ReactDOMServerRenderer.read (frontend/node_modules/react-dom/cjs/react-dom-server.node.development.js:3704:25)
//    at renderToStaticMarkup (frontend/node_modules/react-dom/cjs/react-dom-server.node.development.js:4314:27)
//    at process (frontend/node_modules/react-apollo/react-apollo.umd.js:118:24)
//    at processTicksAndRejections (node:internal/process/task_queues:96:5)
//
const asyncComponent = function<TProps>(importComponent: ImportComponentFunction) {
  type State = {
    component: React.ComponentType<TProps>,
  };
  return class extends React.Component<TProps, State> {
    state: State = {
      component: null,
    };

    componentDidMount() {
      importComponent()
        .then(c => {
          this.setState({ component: c.default });
        });
    }

    render() {
      const Component = this.state.component;

      if (!Component) {
        return null;
      }

      return <Component {...this.props} />;
    }
  };
};

export default asyncComponent;
