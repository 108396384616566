import React from 'react';
import I18n from 'i18n-js';
import classNames from 'classnames';
import { withRouter, WithRouterProps } from 'react-router';

import { RCCheckbox } from '@reverbdotcom/cadence/components';

import FacetOption from './facet_option';
import FilterLink from './filter_link';
import { withPrefix } from '../i18n_helpers';
import { FilterOption } from './search_filters';
import ProximityModal, { POSTAL_CODE_PARAM, DISTANCE_PARAM, MILES_100 } from './proximity_modal';
import { useUser } from '../user_hooks';
import { useUpdateUrl } from './with_update_url';
import { linkParams } from './select_facet_option';

interface IExternalProps {
  option: FilterOption;
  trackInteraction: () => void;
  inSortContext?: boolean;
}

type IProps = IExternalProps & WithRouterProps;

const t = withPrefix('commons.proximityFilter');

export const PROXIMITY_PARAM = 'proximity';
export const PROXIMITY_SORT_ASCENDING = 'proximity|asc';

function proximityParams(linkParams, postalCode, distance) {
  return {
    [POSTAL_CODE_PARAM]: postalCode,
    [DISTANCE_PARAM]: distance,
    ...linkParams,
  };
}

export function ProximityFacet({
  option,
  location,
  trackInteraction,
  inSortContext = false,
  router,
}: IProps) {
  const { updateUrl } = useUpdateUrl();
  const user = useUser();
  const [modal, openModal] = React.useState(false);
  const inFilterContext = !inSortContext;

  const currentPostalCode = location?.query?.postal_code || user.postalCode;
  const currentDistance = location?.query?.distance || MILES_100;

  const proximityFilterText = currentPostalCode ?
    I18n.t('commons.proximityFilter.filterNameWithZip', {
      distance: currentDistance,
      postalCode: currentPostalCode,
    }) :
    I18n.t('commons.proximityFilter.filterName', {
      distance: currentDistance,
    });

  function handleClick(event) {
    if (!currentPostalCode) {
      event.preventDefault();
      openModal(true);
      return;
    }

    const newUrl = updateUrl({
      location,
      newParams: proximityParams(
        linkParams(option, true),
        currentPostalCode,
        currentDistance,
      ),
    });

    trackInteraction();

    router.push(newUrl);
  }

  const editButton = (
    <button
      type="button"
      className={classNames(
        'button-as-link',
        { 'padding-half': inFilterContext },
      )}
      onClick={(e) => {
        e.preventDefault();
        openModal(true);
      }}
    >
      <span className="text-utility-350 text-utility--semibold">
        {t('modalButton')}
      </span>
    </button>
  );

  const linkClass = classNames({
    facet__link: true,
    'facet__link--active': option.selected,
  });

  return (
    <>
      {inFilterContext && (
        <div className="d-flex gap-1 fx-align-start">
          <RCCheckbox
            id={option.optionValue}
            checked={option.selected}
            onChange={handleClick}
            label={proximityFilterText}
            paddedHover
            // when there is no postal code, leverage the tri-state checkbox's onClick to open Proximity modal
            triState={!currentPostalCode}
            onClick={handleClick}
          />
          {editButton}
        </div>
      )}
      {inSortContext && (
        <FacetOption
          paramName="proximity"
          active={option.selected}
        >
          <span className="mr-2">
            <FilterLink
              className={linkClass}
              params={proximityParams(linkParams, currentPostalCode, currentDistance)}
              data-checked={option.selected || null}
              rel="nofollow"
              onClick={handleClick}
              trackInteraction={trackInteraction}
            >
              {proximityFilterText}
            </FilterLink>
          </span>
          {editButton}
        </FacetOption>
      )}
      <ProximityModal
        isModalOpen={modal}
        setModal={openModal}
        propsPostalCode={currentPostalCode}
        propsDistance={currentDistance}
        includeRadiusDropdown={inFilterContext}
        additionalQueryParams={inSortContext ? { sort: PROXIMITY_SORT_ASCENDING } : { [PROXIMITY_PARAM]: true }}
        shouldApplyDistanceQueryParam={inFilterContext}
        shouldApplyPostalCodeQueryParam
      />
    </>
  );
}

export default withRouter(ProximityFacet);
