export default {
  mySales: 'Sales',
  currencyChangeNote: 'Note: Sales from before your shop currency changed to {{currency}} are not represented.',
  thisMonthTooltip: 'Prorated portion of last month\'s total.',
  timePeriods: {
    days_7: 'Last 7 Days',
    months_3: 'Last 3 Months',
    months_6: 'Last 6 Months',
    months_12: 'Last 12 Months',
    this_month: 'This Month',
    last_month: 'Last Month',
    lastMonthToDate: 'This Point Last Month',
    lastMonthTotal: 'Last Month Total',
    previousMonth: 'Previous Month',
    previousPeriod: 'Previous Period',
  },
  salesStats: {
    sales: 'Sales',
    orders: 'Orders',
    offers: 'Offers',
    watchers: 'Watchers',
  },
  statsGraph: {
    ariaLabel: 'Seller Sales Graph',
  },
};
