import React from 'react';
import { I18N as Translate } from '@reverbdotcom/commons/src/components/translate';
import { RCAlertBox } from '@reverbdotcom/cadence/components';
import CoreLink from '@reverbdotcom/commons/src/components/core_link';
import { Paths } from './../components/shared/url_helpers';

const HELP_CENTER_ARTICLE_ID = '21894613284627';

const ListingSellerLockedModeAlert = () => {
  return (
    <div className="mb-4">
      <RCAlertBox
        type="error"
      >
        <Translate
          className="weight-bold"
          text="productShowPage.listingSellerLockedModeAlert.lockedModeTitle"
        />
        <Translate
          tag="p"
          text="productShowPage.listingSellerLockedModeAlert.lockedModeDescription"
        >
          <CoreLink
            target="_blank"
            to={Paths.helpCenterArticlesUrl.expand({ articleId: HELP_CENTER_ARTICLE_ID })}
          >
            <Translate text="productShowPage.listingSellerLockedModeAlert.lockedModeHelpLinkText" />
          </CoreLink>
        </Translate>
      </RCAlertBox>
    </div>
  );
};

export default ListingSellerLockedModeAlert;
