export default (callback: () => void): void => {
  document.addEventListener('mouseleave', handleMouseleave);
  let firedCallback = false;

  function handleMouseleave(e: MouseEvent) {
    if (e.clientY > 20) return;

    if (!firedCallback) {
      firedCallback = true;
      callback();
    }
  }
};
