import I18n from 'i18n-js';
import React from 'react';
import classNames from 'classnames';
import { IComponentProps } from './suggestion';
import lowlight from './lowlight';
import SanitizedRender from '@reverbdotcom/commons/src/components/sanitized_render';

import bind from '@reverbdotcom/commons/src/bind';

export default class SiteSearchSuggestion extends React.Component<IComponentProps, null> {
  public displayName = 'SiteSearchSuggestion';

  public static defaultProps: Partial<IComponentProps> = {
    lastTypedValue: '',
  };

  @bind
  submitSelection(e): void {
    e.preventDefault();

    this.props.selectSuggestion(this.props.suggestion);
  }

  renderContext() {
    if (!this.props.suggestion.context) { return null; }

    return (
      <small>{I18n.t('commons.searchBar.in')} {this.props.suggestion.context}</small>
    );
  }

  renderValue() {
    return lowlight({
      input: this.props.lastTypedValue,
      output: this.props.suggestion.value,
      emphasize: (idx, em) => `<span key=${`site-search-suggestions-${idx}`}>${em.pre}<b>${em.word}</b>${em.post}</span>`,
      noop: (idx, em) => `<span key=${`site-search-suggestions-noop-${idx}`}>${em.word}</span>`,
    }).join(' ');
  }

  render() {
    const classes = classNames(
      'site-search__dropdown__section__item',
      { 'site-search__dropdown__section__item--selected': this.props.active },
    );

    if (this.props.suggestion.value.trim().length === 0) { return null; }

    return (
      <div
        className={classes}
      >
        {/* disabling inline here so we can enable this rule globally, but this should be fixed the next time this file is touched */}
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a onClick={this.submitSelection}>
          <SanitizedRender html={this.renderValue()} />
          {this.renderContext()}
        </a>
        <button
          className="site-search__dropdown__section__tap-ahead"
          onClick={() => this.props.handleInputChange(this.props.suggestion.value)}
          title={I18n.t('commons.searchBar.replaceSearchTerm', { term: this.props.suggestion.value })}
          tabIndex={-1}
        />
      </div>
    );
  }
}
