// .forEach does not work on document.querySelectorAll
// The array returned is not a real array, but a node list
// This requires a custom for each loop

function customForEach(nodeListArray, callback, scope) {
  for (let i = 0; i < nodeListArray.length; i++) {
    callback.call(scope, i, nodeListArray[i]);
  }
}

const QuerySelectorAllForEach = {
  bySelector(selector, callback, scope) {
    const nodeListArray = document.querySelectorAll(selector);

    customForEach(nodeListArray, callback, scope);
  },

  byNodeList(nodeListArray, callback, scope) {
    customForEach(nodeListArray, callback, scope);
  },
};

export default QuerySelectorAllForEach;
