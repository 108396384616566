import React from 'react';
import classNames from 'classnames';

import NewsletterSignup from './newsletter_signup';

export interface IProps {
  list_id: string;
  signup_reason: string;
  title?: string;
  subtitle?: string;
  successTitle?: string;
  successMessage?: string;
  background?: 'white' | 'offwhite' | 'orange' | 'blue';
}

export default class Heading extends React.Component<IProps, null> {
  getActionColor() {
    const { background } = this.props;
    if (
      !background ||
      background === 'white' ||
      background === 'offwhite'
    ) return 'orange';
    return null;
  }

  render() {
    const classes = classNames(
      'newsletter-signup-module',
      { 'newsletter-signup-module--white': this.props.background === 'white' },
      { 'newsletter-signup-module--offwhite': this.props.background === 'offwhite' },
      { 'newsletter-signup-module--orange': this.props.background === 'orange' },
      { 'newsletter-signup-module--blue': this.props.background === 'blue' },
    );

    return (
      <div className={classes}>
        <NewsletterSignup
          signupReason={this.props.signup_reason}
          title={this.props.title}
          listId={this.props.list_id}
          subtitle={this.props.subtitle}
          actionColor={this.getActionColor()}
          successTitle={this.props.successTitle}
          successMessage={this.props.successMessage}
        />
      </div>
    );
  }
}
