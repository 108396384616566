// TODO update this to import from commons/src/gql
// eslint-disable-next-line no-restricted-imports
import { gql } from '@apollo/client';
import React, { useState } from 'react';
import I18n from 'i18n-js';
import classNames from 'classnames';
import { isEmpty } from 'lodash';

import CsrfForm from '../csrf_form';
import CoreLink from '@reverbdotcom/commons/src/components/core_link';
import { formatInternationalizedSeconds } from '../date_utils';
import { isOwner } from '../user_context_helpers';
import { isItemPage, listingItemPagePath } from '@reverbdotcom/commons/src/url_helpers';
import { MParticleEvent } from '@reverbdotcom/commons/src/elog/mparticle_types';
import { WEB } from '@reverbdotcom/commons/src/constants';

import {
  AddToCartButtonFields,
  BumpKeyFragment,
  core_apimessages_Listing_ListingType as ListingType,
  core_apimessages_ProtectionPlan,
} from '../gql/graphql';

import {
  MParticleEventName,
  ProductActionType,
  trackEvent,
} from '../elog/mparticle_tracker';
import { trackCriteoAddToCart } from '../criteo';
import { trackFacebookEvent, ADD_TO_CART_EVENT, PRODUCT_CONTENT_TYPE } from '@reverbdotcom/commons/src/facebook';
import { withRouter, WithRouterProps } from 'react-router';
import { Location } from 'history';
import { resolveCurrentView } from '../event_tracking/favorites';
import { useExpEnabled, useUser } from '@reverbdotcom/commons/src/user_hooks';
import { buttonClassName } from '@reverbdotcom/cadence/components';
import experiments from '../experiments';
import ProtectionPlanModal from '@reverbdotcom/commons/src/components/ProtectionPlanModal';
import { trackProtectionPlanEvent, formattedProtectionPlans } from '@reverbdotcom/commons/src/components/protectionPlanHelpers';

/**
 * Bump tracking is conditionally provided by the parent component
 * separately from the required listing fragment.
 *
 * Ideally this would be controlled by a separate flag prop but has
 * widespread enough use that the existing behavior should be kept.
 */
type Listing = AddToCartButtonFields.Fragment & BumpKeyFragment;

interface ExternalProps {
  listing: Listing;
  quantity?: number;
  disabled?: boolean;
  listingClickEvent?: MParticleEvent;
  position?: number;
  parentComponentName?: string;
  redirectPath?: string;
  protectionPlanId?: string;
  availableProtectionPlans?: core_apimessages_ProtectionPlan[];
  setProtectionPlanId?: (protectionPlanId) => void;
}

type Props = ExternalProps & WithRouterProps;

export function AddToCartButton({
  listing,
  quantity = 1,
  disabled = false,
  position = null,
  parentComponentName = null,
  redirectPath = null,
  location,
  listingClickEvent = null,
  protectionPlanId = null,
  availableProtectionPlans = [],
  setProtectionPlanId = null,
}: Props) {
  const view = useViewModel({
    listing,
    quantity,
    disabled,
    position,
    parentComponentName,
    location,
  });
  const [isProtectionPlanModalOpen, setProtectionPlanModalOpen] = useState(false);

  const applyLoudSecondaryCTA = useExpEnabled(experiments.ORANGE_SECONDARY_CTAS_ON_ITEM_PAGE) && isItemPage(location);

  const [ defaultFetchedProtectionPlan ] = availableProtectionPlans;
  const selectedProtectionPlan = availableProtectionPlans?.find(pp => pp.planId === protectionPlanId);
  const displayedProtectionPlan = selectedProtectionPlan || defaultFetchedProtectionPlan;

  const renderProtectionPlanModal = isItemPage(location) && !protectionPlanId && !isEmpty(availableProtectionPlans);

  const formSubmitId = `new_cart_item-${view.listingId}`;

  function handleClick(e: React.MouseEvent<HTMLButtonElement>) {
    e.stopPropagation();
    view.handleClick();

    setProtectionPlanModalOpen(true);
    trackProtectionPlanExp();
  }

  function trackProtectionPlanExp() {
    if (!protectionPlanId && !isEmpty(availableProtectionPlans)) {
      trackEvent({
        eventName: MParticleEventName.ProtectionPlanQualifyingEvent,
        listingId: listing.id,
        componentName: parentComponentName,
        protectionPlans: formattedProtectionPlans(availableProtectionPlans),
      });
    }
  }

  async function submitAddToCartForm() {
    const form = document.getElementById(formSubmitId) as HTMLFormElement;
    await form.submit();
  }

  async function onProtectionPlanModalSubmit(selectedPlan: core_apimessages_ProtectionPlan) {
    await setProtectionPlanId(selectedPlan.planId);
    submitAddToCartForm();
  }

  async function onProtectionPlanModalClose() {
    await setProtectionPlanId(null);
    submitAddToCartForm();
  }

  if (view.showDigitalListingLink) {
    return (
      <CoreLink
        className="add-to-cart-button"
        to={listingItemPagePath(listing)}
        target={view.freeListingAnchorTarget}
        clickEvent={listingClickEvent}
      >
        {I18n.t('commons.gridCardRow.viewListing')}
      </CoreLink>
    );
  }

  if (view.showDisabled) {
    return (
      <>
        <div
          className="add-to-cart-button"
          data-disabled
        >
          {view.title}
        </div>
        {view.showPreorderText &&
          view.preorderDateText
        }
      </>
    );
  }

  return (
    <CsrfForm
      noValidate
      id={formSubmitId}
      action="/cart"
      acceptCharset="UTF-8"
      method="post"
      key={view.listingId}
    >
      <input
        value="web"
        type="hidden"
        name="cart_item[action_source_attributes][device]"
        id={`cart_item_action_source_attributes_device-${view.listingId}`}
      />
      <input
        value={view.listingId}
        type="hidden"
        name="cart_item[product_id]"
        id={`cart_item_product_id-${view.listingId}`}
      />
      {listing?.bumpKey?.key &&
        <input
          type="hidden"
          name="cart_item[bump_key]"
          id={`cart_item_bump_key-${view.listingId}`}
          value={listing.bumpKey.key}
        />
      }
      <input
        type="hidden"
        name="cart_item[quantity]"
        id={`cart_item_for_product-${view.listingId}_quantity-${quantity}`}
        value={quantity || 1}
      />

      {redirectPath && (
        <input
          type="hidden"
          name="cart_item[redirect_path]"
          id={`cart_item_redirect_path-${view.listingId}`}
          value={redirectPath}
        />
      )}

      {protectionPlanId && (
        <input
          type="hidden"
          name="cart_item[protection_plan_id]"
          id={`cart_item_protection_plan_id-${view.listingId}`}
          value={protectionPlanId}
        />
      )}

      {renderProtectionPlanModal &&
        <ProtectionPlanModal
          onToggle={setProtectionPlanModalOpen}
          isOpen={isProtectionPlanModalOpen}
          availableProtectionPlans={availableProtectionPlans}
          listingId={listing.id}
          protectionPlan={displayedProtectionPlan}
          trackEventFn={(componentName, protectionPlan) => trackProtectionPlanEvent(componentName, MParticleEventName.ClickedAddedProtectionPlan, protectionPlan, listing)}
          onUpdate={onProtectionPlanModalSubmit}
          onClose={onProtectionPlanModalClose}
        />
      }

      <button
        type={renderProtectionPlanModal ? 'button' : 'submit'}
        className={applyLoudSecondaryCTA ? classNames(buttonClassName({ theme: 'loud', variant: 'muted' }), 'add-to-cart-button') : 'add-to-cart-button'}
        onClick={handleClick}
      >
        {view.title}
      </button>
      {view.showPreorderText &&
        <div className="add-to-cart-button__preorder-date">
          {view.preorderDateText}
        </div>
      }
    </CsrfForm>
  );
}

function useViewModel({
  quantity,
  listing,
  disabled,
  position,
  parentComponentName,
  location,
}: {
  listing: Listing,
  quantity: number,
  disabled: boolean,
  position: number,
  parentComponentName: string,
  location: Location,
}) {
  const user = useUser();

  const listingId = listing.id || '';
  const showPreorderText = !!listing.preorderInfo?.onPreorder;
  const title = showPreorderText ? t('preorder') : t('addToCart');
  const showDisabled = !!disabled || isOwner(user, listing);
  const showDigitalListingLink = isFree(listing) && isDigitalListing(listing);
  const digitalListingUrlTarget = user?.deviceName === WEB ? '_blank' : '';

  function handleClick() {
    trackEvent({
      eventName: MParticleEventName.AddToCart,
      productActionType: ProductActionType.AddToCart,
      listing: listing,
      position: position,
      componentName: parentComponentName,
      currentView: resolveCurrentView(location),
    });

    trackCriteoAddToCart({
      id: listing.id,
      price: listing.pricing.buyerPrice.amount,
      quantity,
    }, user);

    trackFacebookEvent(
      ADD_TO_CART_EVENT,
      {
        content_type: PRODUCT_CONTENT_TYPE,
        content_ids: [listingId],
      },
    );
  }

  return {
    title,
    showDisabled,
    showPreorderText,
    showDigitalListingLink,
    preorderDateText: preorderDateText(listing.preorderInfo),
    freeListingAnchorTarget: digitalListingUrlTarget,
    handleClick,
    listingId,
  };
}

const DATE_FORMAT = 'LL';
function preorderDateText(preorder: AddToCartButtonFields.Fragment['preorderInfo']) {
  if (!preorder?.onPreorder) {
    return null;
  }

  const { seconds } = preorder.estimatedShipDate;

  return t('preorderEstimatedShipDate', {
    date: formatInternationalizedSeconds(seconds, DATE_FORMAT),
  });
}

function t(key: string, opt = {}): string {
  return I18n.t(`commons.addToCartButton.${key}`, opt);
}

function isFree(listing: Listing) {
  return listing?.pricing?.buyerPrice?.amountCents === 0;
}

function isDigitalListing(listing: Listing): boolean {
  return listing?.listingType === ListingType.DIGITAL;
}

export const AddToCartButtonFragment = gql`
  fragment AddToCartButtonFields on Listing {
    _id
    id
    sellerId
    listingType
    pricing {
      buyerPrice {
        amount
        amountCents
      }
    }
    preorderInfo {
      onPreorder
      estimatedShipDate {
        seconds
      }
    }
  }
`;

export default withRouter(AddToCartButton);
