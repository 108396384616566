import React from 'react';
import { IClientInjectedProps } from '../lib/client_injected_props';
import I18n from 'i18n-js';
import { parse, stringify } from 'qs';

import SearchInputGroup from '../search_input_group';
import { imageUploadURL } from '../../url_helpers';
import { IDynamicComponentProps } from '../dynamic_component_props';

const SIDEBAR_SECTION = 'sidebar';

type OtherProps = IClientInjectedProps & IDynamicComponentProps;

export interface IProps extends OtherProps {
  linkOverride?: string;
  categoryName: string;
  labelOverride?: string;
  imageKey?: string;
  placeholder?: string;
  isLP?: boolean;
}

export interface IState {
  query: string;
}

export default class CategorySearch extends React.Component<IProps, IState> {
  static defaultProps = {
    pushPath: (path: string) => window.location.assign(path),
  };

  state = {
    query: '',
  };

  getQueryParam() {
    if (this.props.isLP) return 'sidebar_search';
    return 'query';
  }

  search = (evt: React.FormEvent<any>) => {
    evt.preventDefault();

    const query = {
      [this.getQueryParam()]: this.state.query,
    };

    let url;
    if (this.props.linkOverride) {
      const parts = this.props.linkOverride.split('?');
      const path = parts[0];
      const existingQuery = parse(parts[1]);
      const mergedQuery = stringify({ ...query, ...existingQuery });

      url = `${path}?${mergedQuery}`;
    } else {
      url = `/marketplace?${stringify(query)}`;
    }

    this.props.pushPath(url);
  };

  onQueryChange = (evt) => {
    this.setState({ query: evt.target.value });
  };

  getBackgroundImageStyle() {
    if (!this.props.imageKey) return null;

    return { backgroundImage: `url(${imageUploadURL(this.props.imageKey)})` };
  }

  getLabelText() {
    if (this.props.labelOverride) return this.props.labelOverride;

    return I18n.t('cms.categorySearch.searchInCategory', { categoryName: this.props.categoryName });
  }

  render() {
    return (
      <div
        className="cms-search"
        style={this.getBackgroundImageStyle()}
      >
        <div className="cms-search__inner">
          <label className="cms-search__label">
            {this.getLabelText()}
          </label>
          <div className="cms-search__form">
            <SearchInputGroup
              onSubmit={this.search}
              value={this.state.query}
              onChange={this.onQueryChange}
              large={this.props.layoutSection !== SIDEBAR_SECTION}
              placeholder={this.props.placeholder}
            />
          </div>
        </div>
      </div>
    );
  }
}
