import PropTypes from 'prop-types';
import React from 'react';
import CurationActions from '../actions/curation_actions';
import FormGroupWithCheckbox from '@reverbdotcom/commons/src/components/form_group_with_checkbox';

class CuratedSetToggle extends React.Component {
  constructor(props) {
    super(props);
    this.toggleCuration = this.toggleCuration.bind(this);
  }

  get id() {
    return this.props.set.curated_set_id;
  }

  get fieldId() {
    return `curated_set_${this.id}`;
  }

  toggleCuration(attrs) {
    if (attrs[this.fieldId]) {
      CurationActions.curate(this.id);
      return;
    }

    CurationActions.decurate(this.id);
  }

  render() {
    if (!this.fieldId) { return null; }

    return (
      <FormGroupWithCheckbox
        inputName={this.fieldId}
        label={this.props.set.curated_set_name}
        updateField={this.toggleCuration}
        checked={this.props.set.curated}
      />
    );
  }
}

CuratedSetToggle.defaultProps = {
  set: {
    curated: false,
  },
};

CuratedSetToggle.propTypes = {
  set: PropTypes.shape({
    curated_set_id: PropTypes.number.isRequired,
    has_sale: PropTypes.bool,
    curated: PropTypes.bool,
    curated_set_name: PropTypes.string,
  }),
};

export default CuratedSetToggle;
