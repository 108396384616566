import React, { useCallback, useState } from 'react';
import Modal from '@reverbdotcom/commons/src/components/modal';
import { RCButton } from '@reverbdotcom/cadence/components';
import { I18N as Translate } from '@reverbdotcom/commons/src/components/translate';
import I18n from 'i18n-js';
import Location from '../../lib/wrapped_location';
import URLHelpers from '../shared/url_helpers';
import PayStatementContainer from './pay_statement_container';
import { StatementProps } from './statement_props';
import AddNewCardContainer from './add_new_card_container';

interface ExternalProps {
  returnUrl: string;
}

type Props = ExternalProps & StatementProps;

function PayStatementModal({
  balanceCents,
  currencyCode,
  returnUrl,
  statementId,
  totalOwedCents,
  totalPaidCents,
}: Props) {
  const [isOpen, setIsOpen] = useState(false);
  const [shouldDisplayAddNewCardForm, setShouldDisplayAddNewCardForm] = useState(false);
  const [newCardId, setNewCardId] = useState(null);
  const [paymentSuccessful, setPaymentSuccessful] = useState(false);

  const closeModal = useCallback(() => {
    setShouldDisplayAddNewCardForm(false);
    setIsOpen(false);

    if (paymentSuccessful) {
      const params = { payment_success: true, statement_id: statementId };
      const url = URLHelpers.mySellingStatementsPath(params);
      Location.replace(url);
    }
  }, [ setIsOpen, setShouldDisplayAddNewCardForm, paymentSuccessful, statementId ]);

  const toggleDisplayNewCardForm = useCallback(() => {
    setShouldDisplayAddNewCardForm(!shouldDisplayAddNewCardForm);
  }, [ setShouldDisplayAddNewCardForm, shouldDisplayAddNewCardForm ]);

  const selectNewCreditCard = useCallback((creditCardId: string) => {
    toggleDisplayNewCardForm();
    setNewCardId(creditCardId);
  }, [ setNewCardId, toggleDisplayNewCardForm ]);

  return (
    <>
      <RCButton
        onClick={() => setIsOpen(!isOpen)}
        variant="filled"
        size="mini"
      >
        <Translate text="discovery.payStatement.payNowButtonText" />
      </RCButton>
      <Modal
        isOpen={isOpen}
        onRequestClose={closeModal}
        headerTitle={
          shouldDisplayAddNewCardForm ?
            I18n.t('discovery.payStatement.addNewCardModalTitle') :
            I18n.t('discovery.payStatement.payNowModalTitle')
        }
      >
        {shouldDisplayAddNewCardForm ? (
          <AddNewCardContainer
            toggleDisplayNewCardForm={toggleDisplayNewCardForm}
            returnUrl={returnUrl}
            selectNewCreditCard={selectNewCreditCard}
          />
        ) : (
          <PayStatementContainer
            balanceCents={balanceCents}
            closeModal={closeModal}
            currencyCode={currencyCode}
            newCardId={newCardId}
            setPaymentSuccessful={setPaymentSuccessful}
            statementId={statementId}
            totalOwedCents={totalOwedCents}
            totalPaidCents={totalPaidCents}
            toggleDisplayNewCardForm={toggleDisplayNewCardForm}
          />
        )}
      </Modal>
    </>
  );
}

export default PayStatementModal;
