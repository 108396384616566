import React from 'react';
import I18n from 'i18n-js';
import classNames from 'classnames';
import { CategoryPanelData, CategoryColumnData, LinkData, CuratedData } from './category_flyout_data';
import CoreLink from '@reverbdotcom/commons/src/components/core_link';
import { useEscToClose } from './shared/modal_effects';
import { useUser } from '@reverbdotcom/commons/src/user_hooks';
import { MParticleEventName } from '@reverbdotcom/commons/src/elog/mparticle_types';
import { RCCloseButton } from '@reverbdotcom/cadence/components';
import { browsePagePaths } from './shared/browsePagePaths';

interface IColumnSectionProps {
  i18nPrefix: string;
  hrefPrefix: string;
  data: CategoryColumnData;
  closeDialog: () => void;
}

interface IColumnSectionLinkProps {
  i18nPrefix: string;
  hrefPrefix?: string;
  linkData?: LinkData;
  slug?: string;
  closeDialog: () => void;
}

export const NAV_LINK_COMP_NAME = 'navLink';

function ColumnSectionLink({
  linkData = undefined,
  slug = undefined,
  hrefPrefix = undefined,
  i18nPrefix,
  closeDialog,
}: IColumnSectionLinkProps) {
  const { href } = linkData ?? {};

  const browsePagePathForSlug = slug && browsePagePaths[slug];
  const to = href || browsePagePathForSlug || `${hrefPrefix}${slug}`;

  return (
    <li key={slug}>
      <CoreLink
        to={to}
        className="category-flyout__link"
        onClick={closeDialog}
        clickEvent={{
          eventName: MParticleEventName.ClickedComponent,
          componentName: NAV_LINK_COMP_NAME,
          entityId: to,
          entityType: 'url',
          displayStyle: 'flyoutColumnLink',
        }}
      >
        {I18n.t(`${i18nPrefix}.${linkData?.textKey || slug}`)}
      </CoreLink>
    </li>
  );
}

function ColumnSection({ data, hrefPrefix, i18nPrefix, closeDialog }: IColumnSectionProps) {
  const headingText = I18n.t(`${i18nPrefix}.${data.headingKey}`);
  const href = data?.viewAllHref;

  const heading = href ?
    <CoreLink
      onClick={closeDialog}
      to={href}
      clickEvent={{
        eventName: MParticleEventName.ClickedComponent,
        componentName: NAV_LINK_COMP_NAME,
        entityId: href,
        entityType: 'url',
        displayStyle: 'flyoutColumnHeaderLink',
      }}
    >
      {headingText}
    </CoreLink> :
    headingText;

  return (
    <div className="category-flyout__column__section">
      <div className="category-flyout__subhead">
        {heading}
      </div>
      <ul>
        {data.children && data.children.map(linkData => (
          <ColumnSectionLink
            key={linkData.textKey}
            i18nPrefix={i18nPrefix}
            linkData={linkData}
            closeDialog={closeDialog}
          />
        ))}
        {data.slugs && data.slugs.map(slug => (
          <ColumnSectionLink
            key={slug}
            slug={slug}
            hrefPrefix={hrefPrefix}
            i18nPrefix={i18nPrefix}
            closeDialog={closeDialog}
          />
        ))}
      </ul>
    </div>
  );
}

interface ICuratedColumnProps {
  data: CuratedData;
  i18nPrefix: string;
  closeDialog: () => void;
}
export function CuratedColumn({ data, i18nPrefix, closeDialog }: ICuratedColumnProps) {
  return (
    <div className="category-flyout__secondary">
      <div className="category-flyout__secondary__inner">
        <div className="category-flyout__secondary__callout">
          <img src={data.imageHref} className="d-block width-100" alt="" />
        </div>
        <div>
          <div className="category-flyout__subhead">
            {I18n.t('discovery.categoryNavBar.curatedByReverb')}
          </div>
          <ul className="mt-2">
            {data.children.map(linkData => (
              <ColumnSectionLink
                key={linkData.textKey}
                i18nPrefix={i18nPrefix}
                linkData={linkData}
                closeDialog={closeDialog}
              />
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
}

interface IProps {
  data: CategoryPanelData;
  visible: boolean;
  closeDialog: (evt?: any) => void;
}

export default function CategoryFlyout({ data, visible, closeDialog }: IProps) {
  const [containerRef, setContainerRef] = React.useState(null);
  useEscToClose(visible, closeDialog);
  // Always render links in the DOM for bots, for SEO purposes
  const renderWhenHidden = useUser().isBot;

  React.useEffect(() => {
    if (visible) {
      containerRef?.focus();
    }
  }, [visible, containerRef]);

  if (!visible && !renderWhenHidden) {
    return null;
  }

  const classes = classNames(
    'category-flyout',
    { 'category-flyout--visible': visible },
  );

  const headingText = I18n.t(`${data.i18nPrefix}.title`);
  const heading = data.headingHref ? (
    <CoreLink
      onClick={closeDialog}
      to={data.headingHref}
      clickEvent={{
        eventName: MParticleEventName.ClickedComponent,
        componentName: NAV_LINK_COMP_NAME,
        entityId: data.headingHref,
        entityType: 'url',
        displayStyle: 'flyoutSectionHeaderLink',
      }}
    >
      {headingText}
    </CoreLink>
  ) :
    headingText;

  const headerId = `category-header-${data.key}`;

  return (
    <div className={classes}
      role="dialog"
      aria-labelledby={headerId}
      aria-hidden={!visible}
      ref={setContainerRef}
      tabIndex={-1}
    >
      <div className="category-flyout__content">
        <div className="category-flyout__primary">
          <div className="category-flyout__header">
            <div id={headerId} className="category-flyout__heading">
              {heading}
            </div>
            <div className="category-flyout__close">
              <RCCloseButton onClick={closeDialog} />
            </div>
          </div>
          <div className="category-flyout__columns">
            {data.columns.map((columnSections, idx) => (
              <div className="category-flyout__column" key={idx}>
                {columnSections.map((columnData) => (
                  <ColumnSection
                    key={columnData.headingKey}
                    hrefPrefix={data.hrefPrefix}
                    i18nPrefix={data.i18nPrefix}
                    data={columnData}
                    closeDialog={closeDialog}
                  />
                ))}
              </div>
            ))}
          </div>
        </div>
        {!!data.curated &&
          <CuratedColumn
            i18nPrefix={data.i18nPrefix}
            data={data.curated}
            closeDialog={closeDialog}
          />
        }
      </div>
    </div>
  );
}
