import { core_apimessages_Money } from '@reverbdotcom/commons/src/gql/graphql';
import Currency from '@reverbdotcom/commons/src/currency';
import { parseAmount, parseAmountCents } from '@reverbdotcom/commons/src/money';

export enum IUserType {
  BUYER = 'buyer',
  SELLER = 'seller',
}

export enum IRefundType {
  FULL = 'full',
  PARTIAL = 'partial',
}

export enum IRefundState {
  APPROVED = 'approved',
  CONDITIONALLY_APPROVED = 'conditionally_approved',
  DENIED = 'denied',
}

export enum ViewContext {
  // buyer or seller initiating a refund request,
  // or a seller editing a refund request
  DEFAULT = 'default',

  // seller updating a refund request which was already
  // already conditionally approved, and is now approving.
  // In this view there is no refund state selector, and
  // submitting the form always approves the refund request.
  CONFIRM = 'confirm',
}

export interface IRefundRequestProps {
  isPersisted: boolean;
  canConditionallyApprove: boolean;
  requestedAmount?: core_apimessages_Money;
  noteToSeller?: string;
  reason?: string;
}

export interface IRefundableOrderProps {
  hasPreviousRefund: boolean;
  amountAvailableToRefund: core_apimessages_Money;
  reverbRemittedTaxAmountAvailableToRefund: core_apimessages_Money;
  returnShippingLabelAmount?: core_apimessages_Money;
  settlementCurrency: string;
  displayCurrency: string;
  fromDisplayCurrencyToSettlementCurrencyExchangeRate: number;
  sellingFeeAmount: core_apimessages_Money;
  sellingFeesDeductedFromPayouts: boolean;
  isPaypal: boolean;
  payoutAmountAvailableToRefund?: core_apimessages_Money;
  hasCompletedPayout: boolean;
}

export interface IProps {
  userType: IUserType;
  reasons: string[];
  order: IRefundableOrderProps;
  refundRequest: IRefundRequestProps;
  viewContext?: ViewContext;
  hideRefundExplanation: boolean;
  protectionPlanAmount?: core_apimessages_Money;
}

export interface IState {
  refundReason: string;
  note: string;
  amount: string;
  exchangedAmount?: core_apimessages_Money;
}

export enum IAdminAccommodationReason {
  CUSTOMER_SERVICE_ACCOMODATION = 'customer_service_accommodation',
  DISPUTE_ACCOMMODATION = 'dispute_accommodation',
  INSURANCE_CLAIM = 'insurance_claim',
}

export const OTHER_REASON = 'other';

export function getInitialRefundState(canConditionallyApprove: boolean): IRefundState {
  if (canConditionallyApprove) {
    return IRefundState.CONDITIONALLY_APPROVED;
  }

  return IRefundState.APPROVED;
}

export function mapRefundTypeToRefundState(refundType: IRefundType, canConditionallyApprove: boolean): IRefundState {
  if (refundType === IRefundType.FULL && canConditionallyApprove) {
    return IRefundState.CONDITIONALLY_APPROVED;
  }

  return IRefundState.APPROVED;
}

export function needsCurrencyExchange(refundableOrder, userType: IUserType): boolean {
  if (userType !== IUserType.BUYER) {
    return false;
  }

  return refundableOrder.settlementCurrency !== refundableOrder.displayCurrency;
}

export function amountInSettlementCurrency(enteredAmount: string, refundableOrder): core_apimessages_Money | null {
  const {
    displayCurrency,
    settlementCurrency,
    fromDisplayCurrencyToSettlementCurrencyExchangeRate,
  } = refundableOrder;

  const fromCurrencyConfig = Currency.get(displayCurrency);
  const toCurrencyConfig = Currency.get(settlementCurrency);

  const amountCentsInDisplayCurrency = parseAmount(enteredAmount, displayCurrency).amountCents;

  if (amountCentsInDisplayCurrency === 0) {
    return null;
  }

  const fractionalAmount = amountCentsInDisplayCurrency /
    (fromCurrencyConfig.subunitToUnit / toCurrencyConfig.subunitToUnit);
  const toAmountCents = fractionalAmount * fromDisplayCurrencyToSettlementCurrencyExchangeRate;

  return parseAmountCents(toAmountCents, settlementCurrency);
}

export function getInitialAmount(refundRequest: IRefundRequestProps, userType: IUserType): string {
  if (
    userType !== IUserType.BUYER &&
    refundRequest.isPersisted &&
    refundRequest.requestedAmount
  ) {
    return refundRequest.requestedAmount.amount;
  }

  return '';
}

// When a seller updates a refund request there is no refund type selector, only an amount input.
// This is used to display different refund explanation line items based on the entered amount.
export function isFullRefund(order: IRefundableOrderProps, amountInCurrency: string): boolean {
  const amount = parseAmount(amountInCurrency, order.settlementCurrency);
  return amount.amountCents >= order.amountAvailableToRefund.amountCents;
}
