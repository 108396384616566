import { ChildProps } from '@apollo/client/react/hoc';
import { gql } from '@reverbdotcom/commons/src/gql';
import I18n from 'i18n-js';
import React, { useEffect } from 'react';

import { withGraphql } from '@reverbdotcom/commons/src/with_graphql';
import { I18N } from '@reverbdotcom/commons/src/components/translate';
import { DataServicesSellFormPricingRecommendations } from '@reverbdotcom/commons/src/gql/graphql';
import { trackEvent } from '@reverbdotcom/commons/src/elog/mparticle_tracker';
import { MParticleEventName } from '@reverbdotcom/commons/src/elog/mparticle_types';
import { formatAmount } from '@reverbdotcom/commons/src/money';
import { RCLoadingBars } from '@reverbdotcom/cadence/components';
import { withUserContext, IUserContext, IUser } from '@reverbdotcom/commons/src/components/user_context_provider';
import { convertMoney } from '@reverbdotcom/commons/src/convert_currency';
import { PriceDisplay } from '@reverbdotcom/commons/src/components/PriceDisplay';
import PriceDisplayRange from '@reverbdotcom/commons/src/components/price_display_range';
import { conditionUuidForSlug, NON_FUNCTIONING, BRAND_NEW, B_STOCK } from '@reverbdotcom/commons/src/condition_helpers';

import PriceGuideGraphIcon from '../../../../../../images/price-guide-tool/colored-price-guide-graph.svg';
import SellFormPricingGuidanceModal from './SellFormPricingGuidanceModal';
import { IShopConfig } from '../../SellFormContext';
import { isShopInUSA } from '../sections/PricingSection';
import { isExperimentEnabled } from '@reverbdotcom/commons/src/user_context_helpers';
import experiments from '@reverbdotcom/commons/src/experiments';
import { isEU } from '@reverbdotcom/commons/src/geo';
import { formatExperimentsForEventAttributes } from '@reverbdotcom/commons/src/elog/mparticle';

interface IProps extends IUserContext {
  shopConfig: IShopConfig;
  conditionSlug?: string;
  canonicalProduct?: {
    title: string;
    id: string;
  };
  updatePriceRecommendation: React.Dispatch<any>;
}

export function convertPriceRecommendation({
  priceRecommendation,
  currency,
  exchangeRates,
}) {
  const { priceLow, priceMiddle, priceHigh } = priceRecommendation ?? {};

  if (!priceLow || !priceMiddle || !priceHigh) return {};

  let exchangedLow;
  let exchangedMiddle;
  let exchangedHigh;

  try {
    exchangedLow = convertMoney(priceLow, currency, exchangeRates);
    exchangedMiddle = convertMoney(priceMiddle, currency, exchangeRates);
    exchangedHigh = convertMoney(priceHigh, currency, exchangeRates);
  } catch {
    return {};
  }

  const displayLow = formatAmount(exchangedLow, { precision: 0 });
  const displayMiddle = formatAmount(exchangedMiddle, { precision: 0 });
  const displayHigh = formatAmount(exchangedHigh, { precision: 0 });

  const formattedPriceLow = {
    amountCents: exchangedLow.amountCents,
    currency: exchangedLow.currency,
    display: displayLow,
  };

  const formattedPriceMiddle = {
    amountCents: exchangedMiddle.amountCents,
    currency: exchangedMiddle.currency,
    display: displayMiddle,
  };

  const formattedPriceHigh = {
    amountCents: exchangedHigh.amountCents,
    currency: exchangedHigh.currency,
    display: displayHigh,
  };

  return {
    priceLow: formattedPriceLow,
    priceMiddle: formattedPriceMiddle,
    priceHigh: formattedPriceHigh,
  };
}

export function isConditionIneligibleForEstimates(conditionSlug) {
  return !conditionSlug || [BRAND_NEW, NON_FUNCTIONING, B_STOCK].includes(conditionSlug);
}

function inEurope(countryCode: string): boolean {
  return isEU(countryCode) || countryCode === 'GB';
}

function invalidShopLocation(shopConfig: IShopConfig, user: Partial<IUser>): boolean {
  if (inEurope(shopConfig.address.countryCode)) return !isExperimentEnabled(user, experiments.PRICE_REC_MODULE_EU_UK);

  return !isShopInUSA(shopConfig);
}

function shouldSkipQuery({
  canonicalProduct,
  shopConfig,
  conditionSlug,
  user,
}) {
  return !canonicalProduct?.id || invalidShopLocation(shopConfig, user) || isConditionIneligibleForEstimates(conditionSlug);
}

export function SellFormPricingGuidance({
  data,
  canonicalProduct,
  conditionSlug,
  user,
  updatePriceRecommendation,
  shopConfig,
}: ChildProps<IProps, DataServicesSellFormPricingRecommendations.Query>) {

  const {
    me,
    exchangeRates,
    priceRecommendations,
    loading,
  } = data ?? {};

  const rates = exchangeRates?.rates ?? [];
  const recommendations = priceRecommendations?.priceRecommendations ?? [];
  const currency = me?.shop?.currency || user.currency;

  const priceRecommendation = convertPriceRecommendation({
    priceRecommendation: recommendations[0],
    exchangeRates: rates,
    currency,
  });

  const { priceLow, priceMiddle, priceHigh } = priceRecommendation;

  const hasNoEstimate = !priceLow || !priceMiddle || !priceHigh;
  const hasDataServicesPriceEstimate = !hasNoEstimate;
  const trackedExperiments = [];

  if (inEurope(shopConfig.address.countryCode)) {
    trackedExperiments.push(experiments.PRICE_REC_MODULE_EU_UK);
  }

  useEffect(() => {
    if (!loading) {
      trackEvent({
        eventName: MParticleEventName.ComponentView,
        componentName: 'SellFormPricingGuidance',
        hasDataServicesPriceEstimate,
        cpId: canonicalProduct?.id,
        conditionSlug,
        experiments: formatExperimentsForEventAttributes(user, trackedExperiments),
      });

      updatePriceRecommendation(priceRecommendation);
    }
  }, [loading]);

  if (loading) {
    return (
      <div className="d-flex fx-justify-center ptb-8">
        <RCLoadingBars />
      </div>
    );
  }

  return (
    <div className="sell-form-pricing-guidance">
      {hasNoEstimate ? (
        <div className="sell-form-pricing-guidance__no-estimate">
          <p>
            {I18n.t('discovery.sellForm.pricingSection.pricingGuidance.competitivePriceSellsFaster')}
          </p>
        </div>
      ) : (
        <div>
          <div className="sell-form-pricing-guidance__header">
            <h2>
              {I18n.t('discovery.sellForm.pricingSection.pricingGuidance.title')}
            </h2>
          </div>

          <div className="sell-form-pricing-guidance__body">
            <div className="sell-form-pricing-guidance__body__competitive-pricing">
              <div className="sell-form-pricing-guidance__body__competitive-pricing__price">
                <img
                  src={PriceGuideGraphIcon}
                  alt={I18n.t('discovery.sellForm.pricingSection.pricingGuidance.graphIcon')}
                />

                <div>
                  <h4>
                    {I18n.t('discovery.sellForm.pricingSection.pricingGuidance.competitivePrice')}
                  </h4>

                  <PriceDisplay
                    amountCents={priceMiddle.amountCents}
                    display={priceMiddle.display}
                  />
                </div>
              </div>

              <div className="sell-form-pricing-guidance__body__competitive-pricing__description">
                <I18N
                  html
                  text="discovery.sellForm.pricingSection.pricingGuidance.description"
                  args={{
                    modelName: canonicalProduct.title,
                    conditionName: I18n.t(`discovery.conditions.${conditionSlug}`),
                  }}
                />
              </div>
            </div>

            <div className="sell-form-pricing-guidance__body__estimated-price-range-section">
              <h4>
                {I18n.t('discovery.sellForm.pricingSection.pricingGuidance.estimatedPriceRange')}
              </h4>

              <div>
                <PriceDisplayRange
                  moneyLow={priceLow}
                  moneyHigh={priceHigh}
                  displayConfig={{ precision: 0 }}
                />
              </div>

              <div>
                <SellFormPricingGuidanceModal
                  canonicalProductIds={[canonicalProduct.id]}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

const withQuery = withGraphql<IProps, DataServicesSellFormPricingRecommendations.Query>(
  gql(`
    query DataServices_SellForm__PricingRecommendations(
      $priceRecommendationQueries: [Input_reverb_pricing_PriceRecommendationQuery]
      $loggedOut: Boolean!
    ) {
      priceRecommendations(input: {priceRecommendationQueries: $priceRecommendationQueries}) {
        priceRecommendations {
          priceLow {
            amountCents
            amount
            currency
          }
          priceMiddle {
            amountCents
            amount
            currency
          }
          priceHigh {
            amountCents
            amount
            currency
          }
        }
      }
      me @skip(if: $loggedOut) {
        _id
        uuid
        shop {
          _id
          currency
        }
      }
      exchangeRates(input: { inverseReverseRates: true }) {
        rates {
          from
          to
          rate
        }
      }
    }
  `),
  {
    skip: ({ shopConfig, canonicalProduct, conditionSlug, user }) => shouldSkipQuery({
      canonicalProduct,
      shopConfig,
      conditionSlug,
      user,
    }),
    options: ({ conditionSlug, canonicalProduct, user }) => {
      const canonicalProductId = canonicalProduct.id;
      const conditionUuids = [conditionUuidForSlug(conditionSlug)];

      const priceRecommendationQueries = conditionUuids.filter(Boolean).map(conditionUuid => ({
        canonicalProductId,
        conditionUuid,
      }));

      return {
        ssr: false,
        fetchPolicy: 'network-only',
        variables: {
          loggedOut: user.loggedOut,
          priceRecommendationQueries,
        },
      };
    },
  },
);

export default withUserContext(withQuery(SellFormPricingGuidance));
