import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import FormGroup from '@reverbdotcom/commons/src/components/form_group';
import ErrorMessage from '../shared/error_message';
import I18n from 'i18n-js';

class PaypalProfile extends React.Component {
  get profileContainerClass() {
    return classNames(
      { 'global-slidedown--open': this.props.selected },
      { 'global-slidedown--closed': !this.props.selected },
    );
  }

  completedProfile() {
    return (
      <div dangerouslySetInnerHTML={{ __html: this.props.paypalAccountLink }} />
    );
  }

  nameForField(fieldName) {
    return `${this.props.formPrefix}[${fieldName}]`;
  }

  profileForm() {
    return (
      <>
        {this.renderErrorMessage()}
        <FormGroup
          label={I18n.t('js.dashboard.selling.shopPolicies.paypalEmailLabel')}
          inputName={this.nameForField('email')}
        >
          <input
            value={this.props.userAttributes.firstName}
            type="hidden"
            name={this.nameForField('first_name')}
          />
          <input
            value={this.props.userAttributes.lastName}
            type="hidden"
            name={this.nameForField('last_name')}
          />
          <input
            required
            type="text"
            name={this.nameForField('email')}
            id={this.nameForField('email')}
          />
        </FormGroup>
      </>
    );
  }

  renderErrorMessage() {
    if (this.props.errorMessages.length === 0) {
      return false;
    }

    return (
      <ErrorMessage
        alertStyle="alert-error alert-small"
        message={this.props.errorMessages.join('. ')}
      />
    );
  }

  renderProfile() {
    if (this.props.hideBankAccountEditForm) {
      return this.completedProfile();
    }
    return this.profileForm();
  }

  render() {
    return (
      <div
        className={this.profileContainerClass}
        data-paypal-profile-form
      >
        <div className={this.props.classNames}>
          {this.renderProfile()}
        </div>
      </div>
    );
  }
}

PaypalProfile.propTypes = {
  hideBankAccountEditForm: PropTypes.bool.isRequired,
  paypalAccountLink: PropTypes.string,
  userAttributes: PropTypes.object.isRequired,
  formPrefix: PropTypes.string.isRequired,
  errorMessages: PropTypes.array.isRequired,
  classNames: PropTypes.string,
  selected: PropTypes.bool,
};

PaypalProfile.defaultProps = {
  classNames: 'margined-left mobile-mt-2 module panel',
};

export default PaypalProfile;
