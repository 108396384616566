import { trackEvent } from '@reverbdotcom/commons/src/elog/mparticle_tracker';
import {
  core_apimessages_ProtectionPlan,
} from '@reverbdotcom/commons/src/gql/graphql';
import { isEmpty } from 'lodash';

export function trackProtectionPlanEvent(componentName, eventName, protectionPlan, listing) {
  trackEvent({
    componentName: componentName,
    eventName: eventName,
    protectionPlanDurationMonths: protectionPlan.durationMonths,
    protectionPlanPrice: protectionPlan.amount.amount,
    conditionSlug: listing.condition.conditionSlug,
    listingId: listing.id,
  });
}

export function formattedProtectionPlans(protectionPlans: core_apimessages_ProtectionPlan[]): string {
  if (isEmpty(protectionPlans)) return null;
  const normalizedPlans = protectionPlans.map((plan) => `duration_months - ${plan.durationMonths}, price - ${plan.amount.display}`);
  return normalizedPlans.join('|');
}
