import PropTypes from 'prop-types';
import React from 'react';
import moment from 'moment';
import { APIRequest } from '../../../index';
import UrlHelpers from '../../../shared/url_helpers';
import I18n from 'i18n-js';

class OrderNote extends React.Component {
  renderCreatedAt() {
    const parsed = moment.unix(this.props.orderNote.created_at.seconds);
    return parsed.format('lll');
  }

  handleDeleteNote = (event) => {
    event.preventDefault();
    const confirmed = window.confirm(I18n.t('js.orderNotes.confirmDelete'));
    if (!confirmed) { return; }

    APIRequest.destroy(
      UrlHelpers.deleteOrderNotePath,
      JSON.stringify({
        order_note_ids: [this.props.orderNote.id],
      }),
      { contentType: 'application/json' },
      '4.0',
    );

    this.props.onDeleted(this.props.orderNote);
  };

  render() {
    return (
      <div className="order-note">
        <div className="order-note__header">
          <span className="order-note__timestamp">
            {this.renderCreatedAt()}
          </span>
          <button
            className="order-note__delete"
            type="button"
            onClick={this.handleDeleteNote}
          />
        </div>
        <div className="order-note__body">
          {this.props.orderNote.body}
        </div>
      </div>
    );
  }
}

OrderNote.propTypes = {
  orderNote: PropTypes.object.isRequired,
};

export default OrderNote;
