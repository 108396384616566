import { first } from 'lodash';
import React from 'react';
import I18n from 'i18n-js';
import classNames from 'classnames';
// TODO update this to import from commons/src/gql
// eslint-disable-next-line no-restricted-imports
import { gql } from '@apollo/client';

import { withGraphql } from '../with_graphql';
import {
  ReverifyCreditCardMutation,
  ReverifyCreditCardMutationVariables,
  core_apimessages_CreditCard as CreditCard,
} from '../gql/graphql';
import { CSEName, useAdyenCard } from '../adyen_card_hook';
import { reverbToAdyenCardType } from '../adyen';
import FormGroup from '../components/form_group';
import { MutationFunction, useHOCMutation } from '../useHOCMutation';

import { RCButton } from '@reverbdotcom/cadence/components';

interface ExternalProps {
  creditCard: CreditCard;
  onSubmit?: () => void;
  onComplete?: () => void;
  refetchQueryName?: string;
  hideHelpText?: boolean;
}

interface MutationProps {
  reverifyCreditCard: MutationFunction<ReverifyCreditCardMutation, ReverifyCreditCardMutationVariables>;
}

type Props = ExternalProps & MutationProps;

const cseName: CSEName = 'encryptedSecurityCode';

export function VerifyCardCVV({
  creditCard,
  reverifyCreditCard,
  onSubmit = null,
  onComplete = null,
  refetchQueryName = null,
  hideHelpText = false,
}: Props) {
  const [mutate, { loading, errors }] = useHOCMutation(reverifyCreditCard);
  const submissionError = first(errors) || {};
  const { focus, cardState, errors: formErrors } = useAdyenCard({
    selector: '[data-verify-cvv-field]',
    cardType: reverbToAdyenCardType(creditCard.cardType),
  });

  const isSubmitDisabled = loading || !cardState.isValid;
  const errorMessage = submissionError.message || formErrors[cseName]?.errorI18n;

  const cvvClassNames = classNames('adyen-field', {
    'adyen-field--focused': focus?.fieldType === cseName,
    'adyen-field--error': !!errorMessage,
  });

  function refetchOptions() {
    if (!refetchQueryName) { return {}; }

    return {
      refetchQueries: [refetchQueryName],
      awaitRefetchQueries: true,
    };
  }

  async function submitCardCVV(event: any) {
    event.preventDefault();

    if (onSubmit) {
      onSubmit();
    }

    await reverify();

    if (onComplete) {
      onComplete();
    }
  }

  async function reverify() {
    const tokenizedFieldsJson = JSON.stringify(cardState.data.paymentMethod);

    return mutate({
      variables: {
        input: {
          creditCardId: creditCard.id,
          tokenizedFields: tokenizedFieldsJson,
        },
      },
      ...refetchOptions(),
    });
  }

  return (
    <FormGroup
      label={I18n.t('commons.creditCardForm.pleaseReverifyCvv')}
      inputName="cardCVV"
      helpText={!hideHelpText && I18n.t('commons.creditCardForm.cvvLocation')}
      errorText={errorMessage}
    >
      <div className="d-flex fx-align-center">
        <div data-verify-cvv-field className="width-rem-10 mr-2">
          <div className={cvvClassNames} data-cse={cseName} />
        </div>
        <RCButton
          variant="filled"
          onClick={submitCardCVV}
          disabled={isSubmitDisabled}
          size="mini"
        >
          {I18n.t('commons.creditCardForm.verifyCvvButton')}
        </RCButton>
      </div>
    </FormGroup>
  );
}

export const withMutation = withGraphql<ExternalProps, ReverifyCreditCardMutation, ReverifyCreditCardMutationVariables>(
  gql`mutation ReverifyCreditCard($input: Input_core_apimessages_ReverifyCreditCardRequest) {
    reverifyCreditCard(input: $input) {
      creditCard {
        id
        last4
        cardType
        expirationYear
        expirationMonth
        cardholderName
        needsReverification
        primaryForCheckout
      }
    }
  }`,
  {
    name: 'reverifyCreditCard',
  });

export default withMutation(VerifyCardCVV);
