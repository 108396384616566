import React from 'react';
import I18n from 'i18n-js';

export function AffirmCartOptionText({ splitPay = false }) {
  return (
    <>
      {I18n.t('discovery.cart.buyNowPayOverTime')}
      <p className="mb-0 size-90 opacity-60">
        {I18n.t(splitPay ? 'discovery.cart.splitPay' : 'discovery.cart.makeMonthlyPayments')}
      </p>
    </>
  );
}
export default AffirmCartOptionText;
