import React from 'react';
import I18n from 'i18n-js';
import { head } from 'lodash';
import classNames from 'classnames';

import {
  Commons_Offers_NewOfferFormQuery,
  core_apimessages_Image as IImage,
  core_apimessages_ShippingPrice as IShippingPrice,
  core_apimessages_Country as ICountry,
} from '../gql/graphql';
import { RCTextWithIcon } from '@reverbdotcom/cadence/components';
import { NudgeCartIcon, ExternalLinkIcon } from '@reverbdotcom/cadence/icons/react';
import LocationForm from './location_form';
import { IOfferLocation } from './negotiation_types';
import SanitizedRender from '../components/sanitized_render';
import { SubmittedOffer } from './create_offer_modal';
import { listingItemPagePath } from '../url_helpers';
import CoreLink from '@reverbdotcom/commons/src/components/core_link';

interface Props {
  listing: Commons_Offers_NewOfferFormQuery['listing'];
  isSeller: boolean;
  countries?: ICountry[];
  isEditingLocation?: boolean;
  setIsEditingLocation?: (any) => void;
  location?: IOfferLocation;
  setLocation?: (any) => void;
  refetch: (any) => void;
  submittedOffer?: SubmittedOffer | undefined;
  updateMode?: boolean;
  hideListingInfo?: boolean;
  titleOnly?: boolean;
  condensed?: boolean;
}

function ListingInformation({ listing, isEditingLocation, setIsEditingLocation, isSeller, countries, location, setLocation, localPickupOnly, refetch, titleOnly = false }) {
  const shippingPrice = head(listing.shipping?.shippingPrices) as IShippingPrice;
  const shippingPriceAvailable = !!shippingPrice?.rate?.display;
  const postalCode = location?.postalCode;
  const shopLocation = listing.shop?.address?.displayLocation;
  const useOneLineListingTitle = isEditingLocation || listing.otherBuyersWithListingInCartCounts;
  const shouldDisplayListPriceWithTaxIncluded = isSeller && listing?.taxIncluded && listing?.taxIncludedHint;

  const title = (
    <div className={classNames({
      'offers-form__header-content__title': !useOneLineListingTitle,
      'offers-form__header-content__title__one-line': useOneLineListingTitle,
    })}>
      {listing.title}
    </div>
  );

  if (titleOnly) {
    return title;
  }

  function onApply() {
    refetch({ shippingRegionCode: location.shippingRegionCode, postalCode: location.postalCode, usePrimaryShippingAddress: false });
    setIsEditingLocation(false);
  }

  return (
    <>
      {title}

      <div className="offers-form__header-content__body">
        {!isEditingLocation && (
          <>
            {listing.otherBuyersWithListingInCartCounts && (
              <div className="offers-form__header-content__cart-nudge">
                <RCTextWithIcon
                  svgComponent={NudgeCartIcon}
                  placement="left"
                >
                  {I18n.t('commons.offers.headerContent.inCarts', { count: listing.otherBuyersWithListingInCartCounts })}
                </RCTextWithIcon>
              </div>
            )}

            {!isSeller && (
              <>
                {!localPickupOnly && (
                  <>
                    {shippingPriceAvailable && (
                      <SanitizedRender
                        html={I18n.t('commons.offers.headerContent.priceWithShipping', {
                          price: listing.price.display,
                          shippingPrice: shippingPrice.rate.display,
                          openTag: '<span class="offers-form__header-content__price">',
                          closeTag: '</span>',
                        })}
                      />
                    )}

                    {!shippingPriceAvailable && (
                      <SanitizedRender
                        html={I18n.t('commons.offers.headerContent.priceWithUnknownShipping', {
                          price: listing.price.display,
                          openTag: '<span class="offers-form__header-content__price">',
                          closeTag: '</span>',
                        })}
                      />
                    )} {postalCode && (
                      <SanitizedRender
                        html={I18n.t('commons.offers.headerContent.toPostalCode', { postalCode: postalCode })}
                      />
                    )}

                    <button
                      type="button"
                      className="offers-form__location__change"
                      onClick={() => { setIsEditingLocation(true); } }
                    >
                      {I18n.t('commons.offers.headerContent.changeButton')}
                    </button>
                  </>
                )}

                {localPickupOnly && (
                  <>
                    <>
                      <span className="offers-form__header-content__price">{listing.price.display}</span> {I18n.t('commons.offers.headerContent.localPickupOnly')}
                    </>
                    {shopLocation && (
                      <div className="offers-form__header-content__shop-location">
                        {shopLocation}
                      </div>
                    )}
                  </>
                )}

                {!localPickupOnly && shopLocation && (
                  <div className="offers-form__header-content__shop-location">
                    {I18n.t('commons.offers.headerContent.shipsFrom', { shopLocation: shopLocation })}
                  </div>
                )}
              </>
            )}

            {isSeller && (
              <>
                <span className="offers-form__header-content__price">
                  {
                    I18n.t('commons.offers.headerContent.priceWithLabel', {
                      displayPrice: listing.sellerPrice?.display || listing.price.display,
                    })
                  }
                </span> {shouldDisplayListPriceWithTaxIncluded && (
                  <span>
                    {listing.taxIncludedHint}
                  </span>
                )}
              </>
            )}
          </>
        )}

        {!isSeller && isEditingLocation && (
          <div className="d-flex">
            <LocationForm
              isEditing={isEditingLocation}
              countries={countries}
              location={location}
              onLocationChange={(change) => {
                setLocation({
                  ...location,
                  ...change,
                });
              }}
              onEditLocation={setIsEditingLocation}
            />

            <button
              data-apply-change-location-btn
              type="button"
              className="offers-form__location__apply"
              disabled={!postalCode}
              onClick={onApply}
            >
              {I18n.t('commons.offers.headerContent.applyButton')}
            </button>
          </div>
        )}
      </div>
    </>
  );
}

function ListingInformationForUpdate({ listing, hideListingInfo = false, hideCondition = false }) {
  return (
    <>
      <div className="offers-form__header-content__title">
        {listing.title}
      </div>

      {!hideListingInfo && (
        <div className="offers-form__header-content__body">
          {!hideCondition && (
            <div className="offers-form__header-content__condition">
              {I18n.t('commons.offers.headerContent.condition', { condition: listing.condition.displayName })}
            </div>
          )}
          <span className="offers-form__header-content__price">
            {I18n.t('commons.offers.headerContent.currentPrice', { currentPrice: listing.price.display })}
          </span>
          <span className="offers-form__header-content__listing-link">
            <CoreLink
              to={listingItemPagePath(listing)}
              target="_blank"
            >
              <RCTextWithIcon svgComponent={ExternalLinkIcon}>
                {I18n.t('commons.offers.headerContent.listingLink')}
              </RCTextWithIcon>
            </CoreLink>
          </span>
        </div>
      )}
    </>
  );
}

function SubmittedOfferInformation({ listing, isSeller, submittedOffer, localPickupOnly, hideTotal = false }) {
  return (
    <>
      <div className="offers-form__header-content__title__post-submit">
        {listing.title}
      </div>

      <div className="offers-form__header-content__body">
        {!localPickupOnly && submittedOffer.shippingPrice && (
          <SanitizedRender
            html={I18n.t('commons.offers.headerContent.priceWithShipping', {
              price: submittedOffer.price,
              shippingPrice: submittedOffer.shippingPrice,
              openTag: '<span class="offers-form__header-content__price__post-submit">',
              closeTag: '</span>',
            })}
          />
        )}

        {!localPickupOnly && !submittedOffer.shippingPrice && (
          <SanitizedRender
            html={I18n.t('commons.offers.headerContent.priceWithUnknownShipping', {
              price: submittedOffer.price,
              openTag: '<span class="offers-form__header-content__price__post-submit">',
              closeTag: '</span>',
            })}
          />
        )}

        {localPickupOnly && (
          <span className="offers-form__header-content__price__post-submit">
            {submittedOffer.price}
          </span>
        )}

        {!hideTotal && (
          <div className="offers-form__header-content__total__post-submit">
            <strong>
              {I18n.t('commons.offers.confirmation.totalOfferPrice', { total: submittedOffer.totalPrice })}
            </strong>
            <span>
              {!isSeller && I18n.t('commons.offers.confirmation.buyerCheckoutCaveat')}
              {isSeller && I18n.t('commons.offers.confirmation.sellerCheckoutCaveat')}
            </span>
          </div>
        )}
      </div>
    </>
  );
}

export function OfferModalHeaderContent({
  listing,
  isSeller,
  refetch,
  submittedOffer = undefined,
  countries = [],
  isEditingLocation = undefined,
  setIsEditingLocation = undefined,
  location = undefined,
  setLocation = undefined,
  updateMode = false,
  hideListingInfo = false,
  titleOnly = false,
  condensed = false,
}: Props) {
  const localPickupOnly = listing.shipping?.localPickupOnly;
  const image = head(listing.images) as IImage;

  return (
    <div className={classNames(
      'offers-form__header-content-container',
      { 'offers-form__header-content-container__condensed': condensed },
    )}>
      {image?.source && (
        <div className={classNames(
          'offers-form__header-image',
          { 'offers-form__header-image__condensed': condensed },
        )}>
          <img
            src={image.source}
            alt={listing.title}
          />
        </div>
      )}

      <div className="offers-form__header-content">
        {!submittedOffer && !updateMode && (
          <ListingInformation
            listing={listing}
            localPickupOnly={localPickupOnly}
            isSeller={isSeller}
            isEditingLocation={isEditingLocation}
            setIsEditingLocation={setIsEditingLocation}
            countries={countries}
            location={location}
            setLocation={setLocation}
            refetch={refetch}
            titleOnly={titleOnly}
          />
        )}

        {!submittedOffer && updateMode && (
          <ListingInformationForUpdate
            listing={listing}
            hideListingInfo={hideListingInfo}
            hideCondition={condensed}
          />
        )}

        {submittedOffer && (
          <SubmittedOfferInformation
            listing={listing}
            localPickupOnly={localPickupOnly}
            isSeller={isSeller}
            submittedOffer={submittedOffer}
            hideTotal={condensed}
          />
        )}
      </div>
    </div>
  );
}

export default OfferModalHeaderContent;
