export default {
  transactionTable: {
    title: 'Transaction History by Condition',
    previousButton: 'Previous transactions',
    nextButton: 'Next transactions',
    columns: {
      date: 'Date',
      condition: 'Condition',
      finalPrice: 'Final Price',
    },
    disclaimer: 'Prices reflect sales of items in used condition{{supOpen}}*{{supClose}}',
  },
  wrapper: {
    title: 'Reverb Price Guide',
    overview: 'Each time a used or vintage instrument is sold on Reverb, we record the sale price right here in our Reverb Price Guide. Whether you’re buying, selling, or just curious about a piece of gear, you can be confident that you’re getting a fair market range.',
    mobileHeaderAltText: 'Mobile Header Background',
    usage: {
      title: 'Three ways to use the Reverb Price Guide',
      sell: {
        title: 'Price your gear to sell',
        description: 'Use recent sales to ensure you’re getting a fair price and to find the right price for a buyer. Well-priced gear is likely to sell faster.',
        altText: 'Price tag',
      },
      buy: {
        title: 'Buy with confidence',
        description: 'See what other players have recently paid for the gear you want and trust that you’re getting the most gear for your money.',
        altText: 'Certification badge',
      },
      trends: {
        title: 'Follow the trends',
        description: 'Feed your curiosity by exploring how the value of the gear you love has changed over time.',
        altText: 'Trending graph',
      },
    },
    estimatedValueInfo: {
      title: 'How we get our estimated value',
      paragraphOne: 'We look at sold listings, which means that the value you see is based on how much gear in the same condition has sold for on Reverb recently. You can see its current estimated value as well as what players have paid over time for this gear.',
      paragraphTwo: 'Why share this data? We believe in fair prices for buyers and sellers. Price transparency helps everyone understand the value of what they’re buying and selling.',
      brandedIcon: 'Branded Icon',
    },
    articles: {
      learnMore: 'Learn more',
      dateGear: {
        title: 'How to date your gear',
        description: 'Not sure how old your gear is or where it came from? We’ve put together detailed guides on how to date gear from Fender, Martin, Ludwig, and other popular brands.',
      },
      gearChanges: {
        title: 'How your favorite gear changed over time',
        description: 'Dive into our gear timelines to learn how all-time greats like the Les Paul, Telecaster, MPC, Juno synths, and more have changed through the years.',
      },
      faq: {
        title: 'Vintage guitar FAQ',
        description: 'Looking to purchase your dream vintage guitar but not sure where to start? Our Vintage Guitar FAQ runs through helpful inf like pricing, condition, and value to help you make a savvy, informed purchase.',
      },
    },
  },
  index: {
    search: {
      title: "What's it worth?",
      subtitle: 'Discover and compare gear value',
      placeholder: 'Search by brand and model',
      marketplaceLinkText: 'Search Marketplace',
    },
    instructions: {
      title: 'How it works',
      step1Title: 'Search for gear',
      step1Description: 'Our database contains over 240,000 different products and counting.',
      step1AltText: 'Step 1',
      step2Title: 'Enter model and condition',
      step2Description: 'We catalog the millions of pieces of gear bought and sold on Reverb to evaluate specific products and conditions.',
      step2AltText: 'Step 2',
      step3Title: 'See how much it\'s worth',
      step3Description: 'Get an accurate estimate and learn how the value has changed over time.',
      step3AltText: 'Step 3',
    },
    card: {
      button: 'Get Price Estimate',
      multipleFinishes: 'Multiple Finishes',
    },
    bailCard: {
      text: "Can't find what you're looking for?",
      linkText: 'Search the marketplace for live listings',
      subtext: "(Live listings aren't indicative of price recommendations)",
    },
  },
  show: {
    form: {
      gearCollectionButton: {
        ownOne: 'Own one like this?',
        addingGear: 'Adding gear to your Collection allows you to track its value over time.',
        addToCollectionButtonText: 'Add to My Collection',
        viewCollectionButtonText: 'View in My Collection',
        loginMessage: 'Create a Reverb account or sign in to add gear to your Collection.',
        errors: {
          title: 'Error when adding to your Collection',
          message: 'We’re unable to add this product to your Collection right now. Please try again later or %{tagOpen}contact support%{tagClose}.',
        },
      },
      gearDetails: 'Gear Details',
      modelVersion: 'Model',
      selectModel: 'Select Model...',
      condition: 'Condition',
      selectCondition: 'Select Condition...',
      disclaimer: 'This information is not stored and is only used temporarily to provide a more accurate estimate of gear value.',
      conditionHelpText: 'Learn more about our conditions',
      conditionHelpTextSelected: 'Learn more',
      conditionExplanation: {
        nonFunctioning: '{{span_open}}Non Functioning{{span_close}} items do not work as they should. All known issues should be described in the listing{{no_break}}description.',
        poor: '{{span_open}}Poor{{span_close}} condition gear may not work properly but can still perform most{{no_break}}functions.',
        fair: '{{span_open}}Fair{{span_close}} condition gear should function but will show noticeable cosmetic damage or other{{no_break}}issues.',
        good: '{{span_open}}Good{{span_close}} condition items function properly but may exhibit some wear and{{no_break}}tear.',
        veryGood: '{{span_open}}Very Good{{span_close}} items may show a few slight marks or scratches but are fully functional and in overall great{{no_break}}shape.',
        excellent: '{{span_open}}Excellent{{span_close}} items are almost entirely free from blemishes and{{no_break}}other visual defects and have been played or used with the utmost care.',
        mint: '{{span_open}}Mint{{span_close}} items are in essentially new original condition but have been opened or{{no_break}}played.',
        mintInventory: '{{span_open}}Mint (with inventory){{span_close}} items are in essentially new original condition but have been opened or{{no_break}}played.',
        bStock: '{{span_open}}B-Stock{{span_close}} items are sold by an authorized dealer or original builder, but have been opened, blemished, or used in some{{no_break}}manner.',
        brandNew: 'Only list {{span_open}}Brand New{{span_close}} items if you are an authorized dealer or original builder.',
      },
      modelHelpText: 'There is only one model in the Reverb Price Guide matched to this product.',
      backButtonText: 'Back',
      priceGearButtonText: 'Price Gear',
      results: {
        reverbEstimatedValue: 'Reverb Estimated Value',
        basedOn: 'Based on orders for %{boldOpen}%{modelName}%{boldClose} in %{boldOpen}%{condition} Condition%{boldClose}',
        estimatedPriceRange: 'Estimated Price Range',
        condition: 'Condition',
        estimateUnavailable: 'Estimate from Reverb not available.',
        regionalEstimateCaveat: 'Note: Estimate based on US sales prices for similar items on Reverb. Non-US regional data coming soon!',
        pastThirtyDays: 'Past 30 Days',
        greatValue: 'Great Value',
        sellYoursButtonText: 'Sell Yours',
        viewLiveListingsText: 'View Live Listings',
        noEstimates: {
          title: "We'll need more transactions to display a value estimate for this product.",
          backButton: 'Back',
          searchMarketplace: 'Search Marketplace',
        },
        transactionGraph: {
          priceHistory: 'Price History',
          pricesReflect: 'Prices reflect monthly average for sales of item in %{condition} condition. Prices exclude shipping and tax/VAT/GST.{{supOpen}}*{{supClose}}',
          ariaLabel: 'A monthly average sales chart for item.',
          ariaDescription: '%{count} products sold at an average price of %{averagePrice} on %{dateString}. ',
          used: 'used',
          tooltip: {
            productsSold: {
              one: '%{count} Product Sold',
              other: '%{count} Products Sold',
            },
            amountAverage: '%{formattedAmount} average for %{dateString}',
            basedOn: {
              one: 'Based on %{count} order',
              other: 'Based on %{count} orders',
            },
            condition: 'Condition: %{conditionText}',
          },
          buttonGroup: {
            label: 'Time period',
            months_6: '6 months',
            years_1: '1 year',
            years_2: '2 years',
          },
          nullState: {
            needMoreTransactions: "We'll need more transactions to display the Price History for this product.",
            imageAlt: 'A pile of instruments.',
            whyIsThis: 'Why is this?',
            aGraphMayBeAvailable: 'A graph may be available in the future when more of this product is bought & sold on Reverb.',
          },
        },
      },
    },
  },
  cspModule: {
    header: {
      title: 'Reverb Price Guide',
      imageAlt: 'A keyboard, a guitar, and a pedal',
    },
    graph: {
      disclaimer: 'Prices reflect monthly average for sales of items in used condition{{supOpen}}*{{supClose}}',
    },
    disclaimer: '{{supOpen}}*{{supClose}}Excludes Brand New, B Stock, Fair, Poor, and Non-functioning. Prices exclude shipping and tax/VAT/GST.',
    viewPriceGuideButton: 'View Price Guide',
    nullState: {
      imageAlt: 'Price Guide graph icon',
      headerText: 'There aren’t enough recent transactions',
      explanationText: 'Reverb Price Guide data may become available as users buy and sell more of this item. In the meantime, you can review the prices of live listings.',
      disclaimer: 'Prices reflect monthly average for sales of items in used condition. Excludes Brand New, B Stock, Fair, Poor, and Non-functioning. Prices exclude shipping and tax/VAT/GST.',
    },
    filterHeader: {
      title: 'Estimated Value for {{cspTitle}} on Reverb',
      modelFilterLabel: 'Filter by model',
      modelHelpText: 'Includes material, year, finish',
      conditionFilterLabel: 'Filter by condition',
      defaultConditionDisplay: 'Good to Mint',
      defaultModelDisplay: 'All Models',
    },
    priceEstimates: {
      title: 'Estimated Price Range',
      allModels: 'All Models',
      subtitleAllConditions: 'Based on {{total}} orders for <b>{{model}}</b> in <b>Good to Mint condition</b>',
      subtitleOneCondition: 'Based on {{total}} orders for <b>{{model}}</b> in <b>{{condition}} condition</b>',
      subtitleWithOrderBelowThresholdAndAllConditions: 'Based on orders similar to <b>{{model}}</b> in <b>Good to Mint condition</b>',
      subtitleWithOrderBelowThresholdAndOneCondition: 'Based on orders similar to <b>{{model}}</b> in <b>{{condition}} condition</b>',
    },
    transactionTable: {
      title: 'Transaction History',
    },
    emptyState: {
      description: 'As people buy and sell more of this item on Reverb, we’ll be able to show recent transactions and price trends over time.',
    },
    onlyTable: {
      description: 'As people buy and sell more of this item on Reverb, we’ll show you how price trends change over time.',
      transactionButton: {
        show: 'View Transaction History',
        hide: 'Hide Transaction History',
      },
    },
  },
};
