/* tslint:disable:max-line-length */

import React from 'react';

import classNames from 'classnames';
import ProductSuggestion from './product_suggestion';
import ProductPreview from '../product_search_form/product_preview';
import { ControlledPhotos } from '../photos/photos';
import { IPhotoAttrs } from '../photos/photo';
import UrlHelpers from '../shared/url_helpers';
import { V3 } from '../api_request';
import { compact, uniq, isEqual } from 'lodash';
import bind from '@reverbdotcom/commons/src/bind';
import uuid from '../../lib/uuid';
import I18n from 'i18n-js';

const URLS_REGEX = /\b((?:[a-z][\w-]+:(?:\/{1,3}|[a-z0-9%])|www\d{0,3}[.]|[a-z0-9.\-]+[.][a-z]{2,4}\/)(?:[^\s()<>]+|\(([^\s()<>]+|(\([^\s()<>]+\)))*\))+(?:\(([^\s()<>]+|(\([^\s()<>]+\)))*\)|[^\s`!()\[\]{};:'".,<>?«»“”‘’]))/gi;

interface IUncontrolledProps {
  inputNamespace: string;
  productId: string;
  isSeller: boolean;
  bodyInputName?: string;
  photoUploadOptions?: Record<string, any>;
  allowPhotos?: boolean;
  isRequired?: boolean;
  hideListingSearch?: boolean;
}

interface IControlledProps extends IUncontrolledProps {
  message: string;
  onChange: Function;
  photos: IPhotoAttrs[];
  onPhotosChange: (photos: IPhotoAttrs[]) => void;
}

interface IControlledState {
  productSuggestionOpen: boolean;
  photosOpen: boolean;
  loadedProducts: any[];
  embeddedProductIds: string[];
}

interface IUncontrolledState {
  message: string;
  photos: IPhotoAttrs[];
}

class ControlledBodyInput extends React.Component<IControlledProps, IControlledState> {
  static defaultProps: Partial<IControlledProps> = {
    bodyInputName: 'body',
    allowPhotos: true,
    isRequired: true,
    photoUploadOptions: {},
    hideListingSearch: false,
  };

  uuid: string;

  constructor(props) {
    super(props);

    this.uuid = uuid();

    this.state = {
      productSuggestionOpen: false,
      photosOpen: false,
      loadedProducts: [],
      embeddedProductIds: [],
    };
  }

  get showProductSuggest() {
    return this.props.isSeller && !this.props.hideListingSearch;
  }

  get loadedProductIds() {
    return this.state.loadedProducts.map(product => product.id.toString());
  }

  get embeddedLoadedProducts() {
    // return in the same order as this.state.embeddedProductIds
    const products = this.state.embeddedProductIds.map(id =>
      this.loadedProductById(id));

    return compact(products);
  }

  get showPhotos(): boolean {
    return this.state.photosOpen || this.props.photos.length > 0;
  }

  @bind
  handleChange(e) {
    this.props.onChange(e.target.value);
    this.parseProductEmbeds(e.target.value);
  }

  @bind
  handleOpenPhotos(e) {
    e.preventDefault();
    this.setState({ photosOpen: true });
  }

  @bind
  handleToggleProductSuggestion(e) {
    e.preventDefault();
    this.setState({
      productSuggestionOpen: !this.state.productSuggestionOpen,
    });
  }

  @bind
  handleProductSelected(product) {
    const link = product._links.web.href;
    const updatedMessage = this.appendLink(link);

    this.props.onChange(updatedMessage);
    this.setState({
      productSuggestionOpen: false,
      loadedProducts: this.state.loadedProducts.concat([product]),
      embeddedProductIds: this.state.embeddedProductIds.concat([product.id.toString()]),
    });

    const textarea = this.refs.textarea as HTMLInputElement;
    if (textarea) { textarea.focus(); }
  }

  appendLink(link) {
    const { message } = this.props;

    // separate by 2 newlines, unless there is nothing typed yet
    if (message.length === 0) {
      return link;
    }
    if (/\n\n$/.test(message)) {
      return message + link;
    }
    if (/\n$/.test(message)) {
      return `${message}\n${link}`;
    }
    return `${message}\n\n${link}`;
  }

  parseProductEmbeds(message) {
    const links = message.match(URLS_REGEX) || [];
    const regex = new RegExp('\\/item\\/(\\d+)');
    let productIds = [];

    links.forEach((link) => {
      const match = link.match(regex);
      if (match) {
        productIds.push(match[1]);
      }
    });

    productIds = uniq(productIds);

    if (!isEqual(this.state.embeddedProductIds, productIds)) {
      this.setState({ embeddedProductIds: productIds });
    }
    this.loadProducts(productIds);
  }

  loadProducts(ids) {
    ids.forEach((id) => {
      if (!this.hasLoadedProduct(id)) {
        this.loadProduct(id);
      }
    });
  }

  loadProduct(id) {
    V3.get(UrlHelpers.listingPath(id)).then((response) => {
      this.setState({
        loadedProducts: this.state.loadedProducts.concat([response]),
      });
    });
  }

  hasLoadedProduct(id) {
    return this.loadedProductIds.includes(id.toString());
  }

  loadedProductById(id) {
    return this.state.loadedProducts.find(product =>
      product.id.toString() === id.toString());
  }

  inputName(field) {
    if (this.props.inputNamespace) {
      return `${this.props.inputNamespace}[${field}]`;
    }
    return field;
  }

  get domId() {
    return `message-box-${this.uuid}`;
  }

  renderProductSuggestionToggle() {
    if (!this.showProductSuggest) { return null; }
    const classes = classNames(
      'message-box__action',
      'message-box__action--listing', {
        'message-box__action--active': this.state.productSuggestionOpen,
      });

    return (
      <button
        className={classes}
        onClick={this.handleToggleProductSuggestion}
      >
        {I18n.t('discovery.messages.attach_listings')}
      </button>
    );
  }

  renderProductSuggestion() {
    if (!this.showProductSuggest || !this.state.productSuggestionOpen) { return null; }

    return (
      <div className="message-box__suggest-form">
        <ProductSuggestion
          currentListingIds={compact([this.props.productId])}
          onProductSelected={this.handleProductSelected}
        />
      </div>
    );
  }

  renderAttachImageLink() {
    if (!this.props.allowPhotos) { return null; }

    const classes = classNames(
      'message-box__action message-box__action--photos', {
        'message-box__action--active': this.showPhotos,
      });

    return (
      <button
        onClick={this.handleOpenPhotos}
        className={classes}
      >
        {I18n.t('discovery.messages.attach_images')}
      </button>
    );
  }

  renderTextarea() {
    return (
      <textarea
        name={this.inputName(this.props.bodyInputName)}
        value={this.props.message}
        onChange={this.handleChange}
        ref="textarea"
        placeholder={I18n.t('discovery.messages.body.placeholder')}
        data-detector="input"
        data-message-body
      />
    );
  }

  renderProductEmbeds() {
    return this.embeddedLoadedProducts.map(product => (
      <div
        className="message-form__listing-preview"
        key={product.id}
      >
        <ProductPreview product={product} />
      </div>
    ));
  }

  renderPhotoUpload() {
    if (!this.showPhotos) { return null; }

    return (
      <ControlledPhotos
        photos={this.props.photos}
        onPhotosChange={this.props.onPhotosChange}
        inputName={this.inputName('photos')}
        uploadOptions={this.props.photoUploadOptions}
        requireMinimumDimensions={false}
        allowEdit={false}
        renderTip={false}
        dropZoneSelector={`#${this.domId}`}
      />
    );
  }

  renderAttachments() {
    if (this.showPhotos || this.embeddedLoadedProducts.length > 0) {
      return (
        <div className="message-box__attachments">
          {this.renderPhotoUpload()}
          {this.renderProductEmbeds()}
        </div>
      );
    }
  }

  render() {
    return (
      <div className="message-box" id={this.domId}>
        <div className="message-box__text">
          {this.renderTextarea()}
        </div>
        <div className="message-box__content">
          {this.renderAttachments()}
          {this.renderProductSuggestion()}
        </div>
        {(this.props.allowPhotos || this.showProductSuggest) &&
          <div className="message-box__actions">
            {this.renderAttachImageLink()}
            {this.renderProductSuggestionToggle()}
          </div>
        }
      </div>
    );
  }
}

class UncontrolledBodyInput extends React.Component<IUncontrolledProps, IUncontrolledState> {
  state: IUncontrolledState = {
    message: '',
    photos: [],
  };

  @bind
  handleChange(newMessage) {
    this.setState({ message: newMessage });
  }

  @bind
  handlePhotosChange(photos) {
    this.setState({ photos });
  }

  render() {
    return (
      <ControlledBodyInput
        {...this.props}
        message={this.state.message}
        onChange={this.handleChange}
        photos={this.state.photos}
        onPhotosChange={this.handlePhotosChange}
      />
    );
  }
}

export {
  ControlledBodyInput,
  UncontrolledBodyInput,
};
