// TODO update this to import from commons
// import { gql } from '@reverbdotcom/commons/src/gql';
// eslint-disable-next-line no-restricted-imports
import { gql } from '@apollo/client';
import React from 'react';
import I18n from 'i18n-js';
import * as elog from '@reverbdotcom/commons/src/elog';
import { withGraphql } from '@reverbdotcom/commons/src/with_graphql';
import { RCTooltip } from '@reverbdotcom/cadence/components';
import bind from '@reverbdotcom/commons/src/bind';
import {
  DeclineFeedbackMutation,
} from '@reverbdotcom/commons/src/gql/graphql';
import { connect } from '@reverbdotcom/commons/src/connect';
import { MutationFunction } from '@reverbdotcom/commons/src/useHOCMutation';

const COMPONENT_NAME = 'FuDeclineFeedbackllBleedHeading';

const declineFeedbackGQL = gql`
  mutation DeclineFeedbackMutation(
    $input: Input_core_apimessages_DeclineFeedbackRequest
  ) {
    declineFeedbackRequest(input: $input) {
      orderUuid
    }
  }
`;

const declineFeedbackMutation = withGraphql<
  {},
  DeclineFeedbackMutation.Mutation,
  DeclineFeedbackMutation.Variables
>(declineFeedbackGQL, {
  name: 'declineFeedbackMutation',
});

interface MutationProps {
  declineFeedbackMutation: MutationFunction<DeclineFeedbackMutation.Mutation, DeclineFeedbackMutation.Variables>;
}

interface ExternalProps {
  orderUuid: string;
  onSuccess: Function;
}

type IProps = MutationProps & ExternalProps;

export class DeclineFeedback extends React.Component<IProps, null> {
  @bind
  onSuccess() {
    this.props.onSuccess();
  }

  @bind
  onError(e) {
    elog.error(`DeclineFeedbackError (${COMPONENT_NAME})`, {}, e);
  }

  @bind
  declineFeedback(event) {
    event.preventDefault();
    return this.props.declineFeedbackMutation({
      variables: {
        input: {
          orderUuid: this.props.orderUuid,
        },
      },
    }).then(this.onSuccess, this.onError);
  }

  render() {
    return (
      <div>
        <span data-decline-feedback>
          <RCTooltip text={I18n.t('discovery.declineFeedback.tooltip')} type="plain">
            <button className="button-as-link" onClick={this.declineFeedback} type="button" aria-label={I18n.t('discovery.declineFeedback.tooltip')}>
              <span className="fa fa-times"></span>
            </button>
          </RCTooltip>
        </span>
      </div>
    );
  }
}

export default connect<ExternalProps>([
  declineFeedbackMutation,
])(DeclineFeedback);
