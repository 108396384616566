import React from 'react';
import Review from './review';

const READ_MORE_SIZE = 70;

class ReviewList extends React.Component {
  componentDidUpdate() {
    window.Reverb.ReadMore.addReadMore('.product-review-description', READ_MORE_SIZE);
  }

  renderReviews = () => this.props.reviews.map((review, index) => <Review review={review} key={index} updateSuccess={this.props.updateSuccess} />);

  render() {
    return (
      <ul className="product-reviews-list">
        {this.renderReviews()}
      </ul>
    );
  }
}

export default ReviewList;
