import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import MultiOrderSelectContainer from './shipping_labels/containers/multi_order_select_container';
import MultiOrderSelect from './shipping_labels/multi_order_select';
import { actions as ShippingLabelActions } from './shipping_labels/shipping_label_form_module';
import Order from './shipping_labels/order';

const FEDEX = 'FedEx';
const FEDEX_BARCODE_LENGTH = 34;
const FEDEX_LAST_N_DIGITS_TRACKING = 14;

// This component plays a similar role to app/assets/javascripts/components/track_order_form.jsx
// Please ensure any tracking code form updates are consistent in both places.
class ManualShippingInfoForm extends React.Component {
  constructor(props) {
    super(props);

    this.setManualProvider = this.setManualProvider.bind(this);
    this.setCode = this.setCode.bind(this);
    this.setProvider = this.setProvider.bind(this);
    this.validateForm = this.validateForm.bind(this);
    this.submittedFedexBarcode = this.submittedFedexBarcode.bind(this);
    this.getCodeError = this.getCodeError.bind(this);

    this.state = {
      provider: this.setSelectedOption(),
      code: this.props.selectedCode,
      codeError: this.props.codeError,
      manualProvider: this.setDefaultManualProvider(),
    };
  }

  componentDidMount() {
    const primaryOrder = new Order(JSON.parse(this.props.primaryOrder));
    ShippingLabelActions.setupPrimaryOrder(primaryOrder);
  }

  setSelectedOption() {
    if (this.props.options.indexOf(this.props.selectedProvider) > -1) {
      return this.props.selectedProvider;
    }

    return this.props.otherOption;
  }

  setDefaultManualProvider() {
    if (this.props.options.indexOf(this.props.selectedProvider) > -1) {
      return null;
    }

    return this.props.selectedProvider;
  }

  setManualProvider(event) {
    const manualProvider = event.target.value.trim();
    this.setState({ manualProvider });
  }

  setProvider(event) {
    this.setState({
      provider: event.target.value,
      codeError: null,
      manualProviderError: null,
    });
  }

  setCode(event) {
    const code = event.target.value.trim();
    this.setState({ code });
  }

  selectOptions() {
    return this.props.options.map(o => <option key={o} value={o}>{o}</option>);
  }

  showManualProvider() {
    return this.state.provider === this.props.otherOption;
  }

  providerField() {
    if (!this.showManualProvider()) { return false; }
    const { manualProviderError } = this.state;
    const manualProviderClasses = classNames('width-100', { 'ui-state-error': manualProviderError });

    return (
      <div className="manual-provider scaling-mb-4">
        <div className="form-group">
          <div className="form-group__header">
            <label
              htmlFor="manual_provider"
              className="form-group__label-text"
            >
              {I18n.t('js.manualShippingInfoForm.providerName')}
            </label>
          </div>
          <div className="form-group__fields">
            <input
              type="text"
              placeholder={I18n.t('js.manualShippingInfoForm.enterName')}
              className={manualProviderClasses}
              id="manual_provider"
              name="manual_provider"
              onChange={this.setManualProvider}
              required
            />
            { manualProviderError &&
              <div className="form-group__error size-80 mt-1">
                {manualProviderError}
              </div>
            }
          </div>
        </div>
      </div>
    );
  }

  codeValid() {
    const validator = this.props.trackingValidations[this.state.provider];
    if (!validator) { return true; }

    const code = this.state.code.replace(/\s|-|_|#/g, '');
    return code.match(new RegExp(validator, 'i'));
  }

  // FedEx barcodes are 34 chars long. The tracking # is the last 14 digits of the barcode.
  // http://pages.message.fedex.com/barcodescan_home
  submittedFedexBarcode() {
    return this.state.provider === FEDEX && !!this.state.code && this.state.code.length === FEDEX_BARCODE_LENGTH;
  }

  getCodeError() {
    if (!this.state.code) {
      return I18n.t('js.manualShippingInfoForm.codeError');
    }

    if (!this.codeValid() && this.submittedFedexBarcode()) {
      const suggested = this.state.code.slice(-FEDEX_LAST_N_DIGITS_TRACKING);

      return I18n.t('js.manualShippingInfoForm.barcodeError', {
        provider: this.state.provider,
        lastNDigits: FEDEX_LAST_N_DIGITS_TRACKING,
        suggested,
      });
    }

    if (!this.codeValid()) {
      return I18n.t('js.manualShippingInfoForm.invalidCode', { provider: this.state.provider });
    }

    return null;
  }

  validateForm(event) {
    let manualProviderError = null;
    const codeError = this.getCodeError();

    if (this.showManualProvider() && !this.state.manualProvider) {
      manualProviderError = I18n.t('js.manualShippingInfoForm.providerError');
    }

    if (manualProviderError || codeError) {
      event.preventDefault();
    }

    this.setState({ manualProviderError, codeError });
  }

  formTitle() {
    if (this.props.isEditingTracking) {
      return I18n.t('js.manualShippingInfoForm.editTrackingInfo');
    }

    return I18n.t('js.manualShippingInfoForm.addTrackingInfo');
  }

  render() {
    const shippingCodeClasses = classNames('width-100', { 'ui-state-error': this.state.codeError });

    return (
      <form
        action={this.props.url}
        method="post"
        onSubmit={this.validateForm}
        data-disable-auto-validation="true"
      >
        <input type="hidden" name="_method" value="put" />
        <input type="hidden" name="authenticity_token" value={this.props.token} />
        <h4 className="heading-4">
          {this.formTitle()}
        </h4>
        <p className="mb-2">{I18n.t('js.manualShippingInfoForm.addTrackingDetails')}</p>
        <div className="form-group scaling-mb-4">
          <div className="form-group__header">
            <label
              htmlFor="order[shipping_provider]"
              className="form-group__label-text"
            >
              {I18n.t('js.manualShippingInfoForm.shippingProvider')}
            </label>
          </div>
          <div className="form-group__fields">
            <div className="styled-dropdown">
              <select
                name="order[shipping_provider]"
                id="order[shipping_provider]"
                onChange={this.setProvider}
                value={this.state.provider}
              >
                {this.selectOptions()}
              </select>
            </div>
          </div>
        </div>
        {this.providerField()}
        <div className="form-group scaling-mb-4">
          <div className="form-group__header">
            <label
              htmlFor="order[shipping_code]"
              className="form-group__label-text"
            >
              {I18n.t('js.manualShippingInfoForm.trackingNumber')}
            </label>
          </div>
          <div className="form-group__fields">
            <input
              type="text"
              id="order[shipping_code]"
              name="order[shipping_code]"
              className={shippingCodeClasses}
              placeholder={I18n.t('js.manualShippingInfoForm.shippingCode')}
              onChange={this.setCode}
              required
            />
            { this.state.codeError &&
              <div className="form-group__error size-80 mt-1">
                {this.state.codeError}
              </div>
            }
          </div>
        </div>
        <MultiOrderSelectContainer context={MultiOrderSelect.Context.ManualShippingInfoForm} />
        <div className="label-with-checkbox">
          <input
            id="send_track_notification_checkbox"
            type="checkbox"
            value="1"
            name="send_notification"
            defaultChecked="checked"
            className="add-to-sale-checkbox"
          />
          <label htmlFor="send_track_notification_checkbox">
            {I18n.t('js.manualShippingInfoForm.sendNotification')}
          </label>
        </div>
        <div className="scaling-mt-4">
          <input
            className="button button--primary mobile-width-100 mb-0"
            name="commit"
            type="submit"
            value={I18n.t('js.manualShippingInfoForm.addTracking')}
          />
        </div>
      </form>
    );
  }
}

ManualShippingInfoForm.propTypes = {
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
  selectedProvider: PropTypes.string.isRequired,
  selectedCode: PropTypes.string,
  isEditingTracking: PropTypes.bool.isRequired,
  url: PropTypes.string.isRequired,
  otherOption: PropTypes.string.isRequired,
  codeError: PropTypes.string,
  trackingValidations: PropTypes.shape({}).isRequired,
  token: PropTypes.string.isRequired,
  primaryOrder: PropTypes.string.isRequired,
};

ManualShippingInfoForm.defaultProps = {
  selectedCode: '',
  codeError: '',
};

export default ManualShippingInfoForm;
