// TODO update this to import from commons
// import { gql } from '@reverbdotcom/commons/src/gql';
// eslint-disable-next-line no-restricted-imports
import { gql } from '@apollo/client';
import React from 'react';
import { PriceDisplay } from '@reverbdotcom/commons/src/components/PriceDisplay';
import UrlHelpers from '../../shared/url_helpers';
import { ReverbHeaderCartItemFieldsFragment, core_apimessages_CartItem_ListingTotal_Discount_Type } from '@reverbdotcom/commons/src/gql/graphql';

type CartItem = ReverbHeaderCartItemFieldsFragment['cart']['cartItems'][0];

interface Props {
  cartItem: CartItem;
}

export function ReverbHeaderCartItem({ cartItem }: Props) {
  const { listingTotal, listing } = cartItem;

  return (
    <li className="reverb-header__menu__item">
      <a
        className="reverb-header__menu__link"
        href={UrlHelpers.cartPath}
      >
        <img
          src={findThumbnailOrNull(cartItem)}
          alt=""
          width="96"
          height="96"
        />
        <div className="reverb-header__menu__item__description">
          {hasSale(cartItem) && (
            <p className="reverb-header__menu__item__description__sale-reminder">
              {cartItem.listingTotal.discount.label}
            </p>
          )}
          <div className="reverb-header__menu__item__description__title">
            {listing.title}
          </div>
          <small data-item-price>
            {cartItem.quantity > 1 && (
              <span className="mr-1">
                {`${cartItem.quantity}x`}
              </span>
            )}
            {listingTotal.discounted && (
              <span className="mr-1 opacity-60 td-line-through">
                <PriceDisplay
                  display={listingTotal.originalUnitPrice.display}
                />
              </span>
            )}
            <ItemPrice cartItem={cartItem} />
          </small>
        </div>
      </a>
    </li>
  );
}

function hasSale(cartItem: CartItem) {
  return cartItem.listingTotal.discounted &&
    cartItem.listingTotal.discount.type == core_apimessages_CartItem_ListingTotal_Discount_Type.SALE;
}

function findThumbnailOrNull(cartItem: CartItem) {
  const [image] = cartItem.listing.images;
  return image?.source;
}

function ItemPrice({ cartItem }: { cartItem: CartItem }) {
  const { listingTotal } = cartItem;

  if (listingTotal.discounted) {
    return (
      <span className="reverb-header__menu__item__description__discount-price">
        <PriceDisplay display={listingTotal.unitPrice.display} />
      </span>
    );
  }

  return <PriceDisplay display={listingTotal.unitPrice.display} />;
}

export const ReverbHeaderCartItemFragment = gql`
  fragment ReverbHeaderCartItemFields on rql_Me {
    _id
    uuid
    cart {
      cartItems {
        _id
        uuid
        quantity
        listingTotal {
          discounted
          discount {
            type
            label
          }
          unitPrice {
            display
          }
          originalUnitPrice {
            display
          }
        }
        listing {
          _id
          title
          images(
            input: {
              transform: "card_square"
              count: 1
              scope: "photos"
              type: "Product"
            }
          ) {
            source
          }
        }
      }
    }
  }
`;
