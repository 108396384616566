import React from 'react';
import I18n from 'i18n-js';

import { useMutation } from '@reverbdotcom/commons/src/useMutation';
import { ModalDialog } from '@reverbdotcom/commons';
import { I18N as Translate } from '@reverbdotcom/commons/src/components/translate';
import SellerListingCardsToolbarContext from '../../SellerListingCardsToolbarContext';
import { MParticleEventName } from '@reverbdotcom/commons/src/elog/mparticle_types';
import { trackEvent } from '@reverbdotcom/commons/src/elog/mparticle_tracker';
import { gql } from '@reverbdotcom/commons/src/gql';
import { useViewTracking } from '@reverbdotcom/commons/src/use_tracking';
import SellerListingsCollectionContext from '../../SellerListingsCollectionContext';

export const COMPONENT_NAME = 'BulkDeleteDirectOffersModal';

const BULK_DELETE_AUTO_OFFERS_MUTATION = gql(`
  mutation Core_MyListings_BulkDeleteAutoOffers(
    $input: Input_core_apimessages_BulkDeleteAutoOffersRequest
  ) {
    bulkDeleteAutoOffers(input: $input) {
      responses {
        listingId
        success
        errors {
          message
        }
      }
    }
  }
`);

const BULK_DELETE_AUTO_OFFERS_ASYNC_MUTATION = gql(`
  mutation Core_MyListings_BulkDeleteAutoOffersAsync(
    $input: Input_core_apimessages_BulkDeleteAutoOffersAsyncRequest
  ) {
    bulkDeleteAutoOffersAsync(input: $input) {
      enqueued
      message
    }
  }
`);

export default function BulkDeleteDirectOffersModal({
  closeModal,
  selectedListings,
}) {
  const {
    listingsCollectionState,
  } = React.useContext(SellerListingsCollectionContext);

  const {
    hasAllMatchesSelected,
    totalMatchesEnabledForDirectOffers,
    serializedDashboardSearchParams,
  } = listingsCollectionState;

  const {
    handleBulkResponses,
    handleGenericError,
    handleAsyncBulkResponse,
  } = React.useContext(SellerListingCardsToolbarContext);

  const [bulkDeleteAutoOffers, {
    data,
    loading,
    errors,
  }] = useMutation(BULK_DELETE_AUTO_OFFERS_MUTATION);
  const hasErrors = !!errors.length;

  const [bulkDeleteAutoOffersAsync, {
    data: asyncData,
    loading: asyncLoading,
    errors: asyncErrors,
  }] = useMutation(BULK_DELETE_AUTO_OFFERS_ASYNC_MUTATION);
  const hasAsyncErrors = !!asyncErrors.length;

  const enabledListings = selectedListings.filter(listing => listing.autoOffers.id !== '');
  const enabledCount = hasAllMatchesSelected ? totalMatchesEnabledForDirectOffers : enabledListings.length;

  React.useEffect(() => {
    if (loading) return;
    if (hasErrors) return handleUnexpectedError();

    handleResponses();
  }, [loading]);

  React.useEffect(() => {
    if (asyncLoading) return;
    if (hasAsyncErrors) return handleUnexpectedError();

    handleAsyncResponse();
  }, [asyncLoading]);

  function handleResponses() {
    if (data?.bulkDeleteAutoOffers) {
      const { responses } = data.bulkDeleteAutoOffers;

      handleBulkResponses({
        responses,
        I18nSuccessMessageKey: 'discovery.listingsManagement.toolbar.bulkActions.directOffers.deleteModal.successes',
        I18nErrorMessageKey: 'discovery.listingsManagement.toolbar.bulkActions.directOffers.deleteModal.errors.title',
      });

      closeModal();
    }
  }

  function handleAsyncResponse() {
    if (asyncData?.bulkDeleteAutoOffersAsync) {
      handleAsyncBulkResponse({
        I18nKey: 'discovery.listingsManagement.toolbar.bulkActions.directOffers.deleteModal.asyncResponses.successes',
        expectedTotal: enabledCount,
      });

      closeModal();
    }
  }

  function handleUnexpectedError() {
    handleGenericError();
    closeModal();
  }

  function submit() {
    trackEvent({
      componentName: COMPONENT_NAME,
      eventName: MParticleEventName.SellerBulkDeletingDirectOffers,
      listingsCount: enabledCount,
      hasAllMatchesSelected,
    });

    if (hasAllMatchesSelected) {
      bulkDeleteAutoOffersAsync({
        variables: {
          input: {
            serializedDashboardSearchParams,
            expectedTotal: enabledCount,
          },
        },
      });
    } else {
      const listingIds = enabledListings.map(listing => listing.id);

      bulkDeleteAutoOffers({
        variables: {
          input: {
            listingIds,
          },
        },
      });
    }
  }

  useViewTracking({
    componentName: COMPONENT_NAME,
    eventName: MParticleEventName.ComponentView,
  }, true);

  return (
    <ModalDialog
      isOpen
      onRequestClose={closeModal}
      headerTitle={I18n.t('discovery.listingsManagement.toolbar.bulkActions.directOffers.disableDirectOffers')}
      saveButtonText={I18n.t('discovery.listingsManagement.toolbar.bulkActions.directOffers.disableDirectOffers')}
      isDisabled={!enabledCount}
      isSaving={loading || asyncLoading}
      onSubmit={submit}
    >
      <div className="d-flex fx-dir-col gap-4">
        <div>
          {I18n.t('discovery.listingsManagement.toolbar.bulkActions.directOffers.deleteModal.buyerText')}
        </div>

        <div className="d-flex fx-dir-col gap-2">
          <Translate
            html
            text="discovery.listingsManagement.toolbar.bulkActions.directOffers.deleteModal.disableText"
            args={{
              count: enabledCount,
              formattedCount: I18n.toNumber(enabledCount, {
                precision: 0,
              }),
            }}
          />
        </div>
      </div>
    </ModalDialog>
  );
}
