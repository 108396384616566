import I18n from 'i18n-js';
import React from 'react';

interface IProps extends React.HTMLProps<HTMLElement> {
  html?: boolean;
  to?: string;
  text: string;
  args?: { [key: string]: string };
  tag?: string;
  children?: React.ReactNode;
}

/**
 * Returns a span tag with the contents set to the value of
 * the given i18n key. Optionally can be flagged as html
 * safe.
 *
 * **WARNING!!!** Do not use this with user provided
 * input unless first sanitizing it using [SanitizedRender](./sanitized_render.tsx).
 *
 * **NOTE** Our translations team needs context on how these strings fit together.
 * When using this pattern, after releasing your code,
 * visit the [Transifex UI](https://www.transifex.com/reverbcom/reverb-core/js-translations)
 * locate your translation, and provide instructions
 * in the dev notes on how the entire text should read, including links to the relevant embedded strings.
 *
 * @tutorial
 * ```typescript
 * import { I18N } from './translate';
 *
 * <I18N
 *  html
 *  tag="p"
 *  text="js.some.key"
 *  args={{ foo: 'bar' }}
 * />
 * ```
 *
 * @tutorial
 * You can also pass in children for richer translation components, this
 * is preferable to passing in strings as html elements and gets you
 * sanitization via React!
 *
 * Your translation key needs to have `{{children}}` as placeholder.
 *
 * For instance, if you want to embed clickable button text within an outer translated string,
 * Your translation key/vals might look like:
 *```javascript
 *  {
 *    ...
 *    myBlurb: 'Please just {{children}} now!'
 *    myBlurbButtonText: 'click the button'
 *    ...
 *  }
 * ```
 *
 * JSX for these elements can then be written as
 *
 * ```typescript
 * <I18N tag="p" text="js.some.myBlurb">
 *   <CoreLink to="/foozles"><I18N text="js.another.myBlurbButtonText" /></CoreLink>
 * </I18N>
 * ```
 *
 * and will be rendered as:
 * ```html
 * <p>"Please just "<a href="/foozles">click the button</a>" now!"</p>
 * ```
 *
 */
export function I18N(props: IProps) {
  const { children, text, args, tag, html, ...htmlProps } = props;
  const el = tag || 'span';

  if (children) {
    const str = I18n.t(text, { ...args, children: '_PLACE' });
    const parts = str.split('_PLACE');
    if (parts.length !== 2) {
      console.error('when using children, ensure that you have one {{children}} placeholder');
    }

    return React.createElement(el, { ...htmlProps }, [parts[0], children, parts[1]]);
  }

  if (html) {
    return React.createElement(el, {
      ...htmlProps,
      dangerouslySetInnerHTML: {
        __html: I18n.t(text, args),
      } },
    );
  }

  return React.createElement(
    el,
    { ...htmlProps },
    I18n.t(text, args),
  );
}
