import { MutationFunction } from '@reverbdotcom/commons/src/useHOCMutation';
// TODO update this to import from commons
// import { gql } from '@reverbdotcom/commons/src/gql';
// eslint-disable-next-line no-restricted-imports
import { gql } from '@apollo/client';
import React from 'react';
import I18n from 'i18n-js';
import { withGraphql } from '@reverbdotcom/commons/src/with_graphql';
import { COUNTRY_CODES } from './packlink_supported_countries';
import { CorePacklinkLoginAccount } from '@reverbdotcom/commons/src/gql/graphql';
import FormGroupWithInput from '@reverbdotcom/commons/src/components/form_group_with_input';
import FormGroupWithSelect from '@reverbdotcom/commons/src/components/form_group_with_select';
import { useUser } from '@reverbdotcom/commons/src/user_hooks';
import { IUser } from '@reverbdotcom/commons/src/components/user_context_provider';

interface MutationProps {
  mutate: MutationFunction<CorePacklinkLoginAccount.Mutation, CorePacklinkLoginAccount.Variables>;
}

interface ExternalProps {
  onComplete: Function;
}

type Props = ExternalProps & MutationProps;

function defaultCountryCode({ countryCode }: Partial<IUser>) {
  if (COUNTRY_CODES.includes(countryCode)) {
    return countryCode;
  }

  return 'UN';
}

export function PacklinkLoginAccountForm(props: Props) {
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [countryCode, setCountryCode] = React.useState(defaultCountryCode(useUser()));

  function countryOptions() {
    return COUNTRY_CODES.map(code => ({
      value: code,
      text: I18n.t(`discovery.packlink.login.countryCodes.${code}`),
    }));
  }

  async function loginAccount(e) {
    e.preventDefault();
    const response = await props.mutate({
      variables: {
        input: {
          email,
          password,
          countryCode,
        },
      },
    });

    if (response) {
      props.onComplete();
    }
  }

  return (
    <form onSubmit={loginAccount}>
      <FormGroupWithInput
        inputName="email"
        inputType="email"
        fieldName="email"
        label={I18n.t('discovery.packlink.login.email')}
        updateField={(field) => setEmail(field.email)}
        required
        tagRequired
      />
      <FormGroupWithInput
        inputName="password"
        label={I18n.t('discovery.packlink.login.password')}
        inputType="password"
        updateField={(field) => setPassword(field.password)}
        required
        tagRequired
      />
      <FormGroupWithSelect
        inputName="countryCode"
        label={I18n.t('discovery.packlink.login.countryCode')}
        fieldName="countryCode"
        value={countryCode}
        updateField={(field) => setCountryCode(field.countryCode)}
        options={countryOptions()}
        required
      />
      <button className="button button--primary" type="submit">{I18n.t('discovery.packlink.login.submit')}</button>
    </form>
  );
}

const withMutation = withGraphql<ExternalProps, CorePacklinkLoginAccount.Mutation, CorePacklinkLoginAccount.Variables>(gql`
mutation Core_Packlink_LoginAccount($input: Input_core_apimessages_LoginMyPacklinkAccountRequest) {
  loginMyPacklinkAccount(input: $input) {
    shopId
  }
}`,
);

export default withMutation(PacklinkLoginAccountForm);
