import React from 'react';
import I18n from 'i18n-js';
import classNames from 'classnames';
import { flatten } from 'lodash';

import { gql } from '@reverbdotcom/commons/src/gql';
import { formatInternationalizedSeconds } from '@reverbdotcom/commons/src/date_utils';
import { RCAlertBox, RCButton } from '@reverbdotcom/cadence/components';
import { Sale, core_apimessages_ListingSaleMembership } from '@reverbdotcom/commons/src/gql/graphql';
import { useMutation } from '@reverbdotcom/commons/src/useMutation';

import { SalesStates } from './BulkSalesModal';

import BulkActionErrors from '../BulkActionErrors';
import { useViewTracking } from '@reverbdotcom/commons/src/use_tracking';
import { MParticleEventName } from '@reverbdotcom/commons/src/elog/mparticle_types';
import { trackEvent } from '@reverbdotcom/commons/src/elog/mparticle_tracker';

export const COMPONENT_NAME = 'BulkSaleCard';

const BULK_CREATE_SALE_MEMBERSHIPS_MUTATION = gql(`
  mutation Core_MyListings_BulkCreateSaleMemberships(
    $input: Input_core_apimessages_BulkSaleMembershipsRequest
  ) {
    bulkCreateSaleMemberships(input: $input) {
      responses {
        listingId
        success
        errors {
          message
        }
      }
    }
  }
`);

const BULK_DELETE_SALE_MEMBERSHIPS_MUTATION = gql(`
  mutation Core_MyListings_BulkDeleteSaleMemberships(
    $input: Input_core_apimessages_BulkSaleMembershipsRequest
  ) {
    bulkDeleteSaleMemberships(input: $input) {
      responses {
        listingId
        success
        errors {
          message
        }
      }
    }
  }
`);

interface IExternalProps {
  sale: Sale;
  salesMemberships: core_apimessages_ListingSaleMembership[];
  isReverbSale: boolean;
  onSuccessCallback: () => void;
}

export function displayStatus(sale) {
  const status = sale.state.toLowerCase();
  return I18n.t(`discovery.listingsManagement.toolbar.bulkActions.sales.modal.saleCard.statuses.${status}`);
}

function statusColorClass(sale) {
  const status = sale.state.toLowerCase();

  switch (status) {
    case SalesStates.LIVE:
      return 'text-color-sentiment-positive-dark';
    case SalesStates.UPCOMING:
      return 'text-color-vu-orange-500';
    default:
      return 'color-primary-disabled';
  }
}

export function filterListingsEligibleToAddToSale(sale, salesMemberships) {
  const filteredMemberships = salesMemberships.filter(saleMembership => saleMembership.sale.id == sale.id);

  return filteredMemberships.filter(saleMembership => saleMembership.isListingEligible && !saleMembership.isListingInSale);
}

export function filterListingsEligibleToRemoveFromSale(sale, salesMemberships) {
  const filteredMemberships = salesMemberships.filter(saleMembership => saleMembership.sale.id == sale.id);

  return filteredMemberships.filter(saleMembership => saleMembership.isListingInSale);
}

enum BulkSaleActions {
  ADDING = 'adding',
  REMOVING = 'removing',
  NONE = 'none',
}

export default function BulkSaleCard({
  sale,
  salesMemberships,
  isReverbSale,
  onSuccessCallback,
}: IExternalProps) {
  const [bulkCreateSaleMemberships, {
    data: createData,
    loading: createLoading,
    errors: createErrors,
  }] = useMutation(BULK_CREATE_SALE_MEMBERSHIPS_MUTATION);

  const [bulkDeleteSaleMemberships, {
    data: deleteData,
    loading: deleteLoading,
    errors: deleteErrors,
  }] = useMutation(BULK_DELETE_SALE_MEMBERSHIPS_MUTATION);

  const [successResponses, setSuccessResponses] = React.useState([]);
  const [failedResponses, setFailedResponses] = React.useState([]);
  const [recentAction, setRecentAction] = React.useState(BulkSaleActions.NONE);

  const successCount = successResponses.length;

  const listingsEligibleToAddToSale = filterListingsEligibleToAddToSale(sale, salesMemberships);
  const listingsEligibleToRemoveFromSale = filterListingsEligibleToRemoveFromSale(sale, salesMemberships);
  const listingsEligibleToAddToSaleCount = listingsEligibleToAddToSale.length;
  const listingsEligibleToRemoveFromSaleCount = listingsEligibleToRemoveFromSale.length;
  const hasListingsToAdd = !!listingsEligibleToAddToSaleCount;
  const hasListingsToRemove = !!listingsEligibleToRemoveFromSaleCount;

  const isSaleLive = sale.state.toLowerCase() == SalesStates.LIVE;
  const isSaleEnded = sale.state.toLowerCase() == SalesStates.ENDED;

  const hasNoEligibleListingsForBulkActions = !hasListingsToAdd && !hasListingsToRemove;
  const showNoEligibleListingsCallout = !isSaleEnded && hasNoEligibleListingsForBulkActions;

  function clearResponses() {
    setSuccessResponses([]);
    setFailedResponses([]);
  }

  function handleUnexpectedError() {
    setFailedResponses([{
      errors: [{
        message: I18n.t('discovery.listingsManagement.genericError'),
      }],
    }]);
  }

  React.useEffect(() => {
    if (createLoading) return;
    if (createErrors.length) return handleUnexpectedError();

    if (createData) {
      setRecentAction(BulkSaleActions.ADDING);

      const successfullyAddedListings = createData.bulkCreateSaleMemberships.responses.filter(response => response.success);
      const unsuccessfullyAddedListings = createData.bulkCreateSaleMemberships.responses.filter(response => !response.success);

      setSuccessResponses(successfullyAddedListings);
      setFailedResponses(unsuccessfullyAddedListings);
      onSuccessCallback();
    }
  }, [createLoading]);

  React.useEffect(() => {
    if (deleteLoading) return;
    if (deleteErrors.length) return handleUnexpectedError();

    if (deleteData) {
      setRecentAction(BulkSaleActions.REMOVING);

      const successfullyRemovedListings = deleteData.bulkDeleteSaleMemberships.responses.filter(response => response.success);
      const unsuccessfullyRemovedListings = deleteData.bulkDeleteSaleMemberships.responses.filter(response => !response.success);

      setSuccessResponses(successfullyRemovedListings);
      setFailedResponses(unsuccessfullyRemovedListings);
      onSuccessCallback();
    }
  }, [deleteLoading]);


  useViewTracking({
    componentName: COMPONENT_NAME,
    eventName: MParticleEventName.ComponentView,
  }, true);

  return (
    <div className="d-flex fx-dir-col gap-1 ptb-3 bdb-1 bd-color-primary">
      <div className="d-flex fx-justify-between fx-wrap">
        <div className="d-flex fx-dir-col gap-1">
          <div className="weight-bold">
            {isReverbSale && (
              <div className="color-secondary size-90 weight-bold">
                {I18n.t('discovery.listingsManagement.toolbar.bulkActions.sales.modal.saleCard.reverbPromotedSale')}
              </div>
            )}

            <span className=" mr-1">
              {sale.name}
            </span>

            {isSaleLive && (
              <span className="size-90">
                <a
                  href={sale.links.self.href}
                  target="_blank" rel="noreferrer"
                >
                  {I18n.t('discovery.listingsManagement.toolbar.bulkActions.sales.modal.saleCard.viewSalePage')}
                </a>
              </span>
            )}
          </div>

          <table>
            <tbody>
              <tr>
                <td className="pr-2">
                  {I18n.t('discovery.listingsManagement.toolbar.bulkActions.sales.modal.saleCard.datesActive')}
                </td>

                <td>
                  {I18n.t('discovery.listingsManagement.toolbar.bulkActions.sales.modal.saleCard.saleRunning', {
                    startDate: formatInternationalizedSeconds(sale.startsAt.seconds, 'LL'),
                    endDate: formatInternationalizedSeconds(sale.endsAt.seconds, 'LL'),
                  })}
                </td>
              </tr>

              <tr>
                <td className="pr-2">
                  {I18n.t('discovery.listingsManagement.toolbar.bulkActions.sales.modal.saleCard.discount')}
                </td>

                <td>
                  {sale.discountValue}
                </td>
              </tr>

              <tr>
                <td className="pr-2">
                  {I18n.t('discovery.listingsManagement.toolbar.bulkActions.sales.modal.saleCard.status')}
                </td>

                <td className={classNames('weight-bold', {
                  [statusColorClass(sale)]: true,
                })}>
                  {displayStatus(sale)}
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="d-flex fx-dir-col tablet-width-100">
          {hasListingsToAdd && !isSaleEnded && (
            <div className="tablet-mt-3">
              <RCButton
                fullWidth
                onClick={() => {
                  clearResponses();

                  const listingIds = listingsEligibleToAddToSale.map(l => l.listingId);

                  trackEvent({
                    componentName: COMPONENT_NAME,
                    eventName: MParticleEventName.SellerBulkCreatingSaleMemberships,
                    listingIds,
                    saleId: sale.id,
                    hasAllMatchesSelected: false,
                  });

                  bulkCreateSaleMemberships({
                    variables: {
                      input: {
                        listingIds,
                        saleId: sale.id,
                      },
                    },
                  });
                }}
                disabled={!hasListingsToAdd || createLoading}
              >
                {I18n.t('discovery.listingsManagement.toolbar.bulkActions.sales.modal.saleCard.adds', {
                  count: listingsEligibleToAddToSaleCount,
                  formattedCount: I18n.toNumber(listingsEligibleToAddToSaleCount, {
                    precision: 0,
                  }),
                })}
              </RCButton>
            </div>
          )}

          {hasListingsToRemove && (
            <div className={classNames('d-flex fx-justify-end tablet-fx-justify-center fx-wrap', {
              'mt-3': !isSaleEnded || hasListingsToAdd,
            })}>
              <div className="mr-1">
                {I18n.t('discovery.listingsManagement.toolbar.bulkActions.sales.modal.saleCard.removes', {
                  count: listingsEligibleToRemoveFromSaleCount,
                  formattedCount: I18n.toNumber(listingsEligibleToRemoveFromSaleCount, {
                    precision: 0,
                  }),
                })}
              </div>
              <button
                disabled={deleteLoading}
                className="button-as-link"
                onClick={(e) => {
                  e.preventDefault();

                  clearResponses();

                  const listingIds = listingsEligibleToRemoveFromSale.map(l => l.listingId);

                  trackEvent({
                    componentName: COMPONENT_NAME,
                    eventName: MParticleEventName.SellerBulkDeletingSaleMemberships,
                    listingIds,
                    saleId: sale.id,
                    hasAllMatchesSelected: false,
                  });

                  bulkDeleteSaleMemberships({
                    variables: {
                      input: {
                        listingIds,
                        saleId: sale.id,
                      },
                    },
                  });
                }}
              >
                {I18n.t('discovery.listingsManagement.toolbar.bulkActions.sales.modal.saleCard.removeLink')}
              </button>
            </div>
          )}
        </div>
      </div>

      {showNoEligibleListingsCallout && (
        <RCAlertBox
          type="warning"
        >
          {I18n.t('discovery.listingsManagement.toolbar.bulkActions.sales.modal.saleCard.noEligibleListings')}
        </RCAlertBox>
      )}

      <div className="d-flex fx-dir-col gap-2">
        {!!successResponses.length && (
          <div className="mt-2">
            <RCAlertBox
              type="success"
              onDismiss={() => setSuccessResponses([])}
            >
              {I18n.t(`discovery.listingsManagement.toolbar.bulkActions.sales.modal.saleCard.messages.${recentAction}.successes`, {
                count: successCount,
                formattedCount: I18n.toNumber(successCount, {
                  precision: 0,
                }),
              })}
            </RCAlertBox>
          </div>
        )}

        {!!failedResponses.length && (
          <div className="mt-2">
            <RCAlertBox
              type="error"
              onDismiss={() => setFailedResponses([])}
            >
              <BulkActionErrors
                titleI18nKey="discovery.listingsManagement.toolbar.bulkActions.sales.modal.saleCard.messages.adding.errors.title"
                totalListingsWithErrors={failedResponses.length}
                errors={flatten(failedResponses.map(listing => listing.errors))}
              />
            </RCAlertBox>
          </div>
        )}
      </div>
    </div>
  );
}
