import React from 'react';
import I18n from 'i18n-js';

import AppleBadgeEn from '@reverbdotcom/cadence/images/vendor/apple-app-store-EN.svg';
import AppleBadgeDe from '@reverbdotcom/cadence/images/vendor/apple-app-store-DE.svg';
import AppleBadgeEs from '@reverbdotcom/cadence/images/vendor/apple-app-store-ES.svg';
import AppleBadgeFr from '@reverbdotcom/cadence/images/vendor/apple-app-store-FR.svg';
import AppleBadgeIt from '@reverbdotcom/cadence/images/vendor/apple-app-store-IT.svg';
import AppleBadgeJa from '@reverbdotcom/cadence/images/vendor/apple-app-store-JP.svg';

import GoogleBadgeEn from '@reverbdotcom/cadence/images/vendor/google-play-store-EN.svg';
import GoogleBadgeDe from '@reverbdotcom/cadence/images/vendor/google-play-store-DE.svg';
import GoogleBadgeEs from '@reverbdotcom/cadence/images/vendor/google-play-store-ES.svg';
import GoogleBadgeFr from '@reverbdotcom/cadence/images/vendor/google-play-store-FR.svg';
import GoogleBadgeIt from '@reverbdotcom/cadence/images/vendor/google-play-store-IT.svg';
import GoogleBadgeJa from '@reverbdotcom/cadence/images/vendor/google-play-store-JA.svg';

interface IAppStoreBadgeProps {
  locale: string;
  brand: 'apple' | 'google';
}

const LOCALE_TO_APPLE_BADGE = {
  de: AppleBadgeDe,
  en: AppleBadgeEn,
  es: AppleBadgeEs,
  fr: AppleBadgeFr,
  it: AppleBadgeIt,
  ja: AppleBadgeJa,
};

const LOCALE_TO_GOOGLE_BADGE = {
  de: GoogleBadgeDe,
  en: GoogleBadgeEn,
  es: GoogleBadgeEs,
  fr: GoogleBadgeFr,
  it: GoogleBadgeIt,
  ja: GoogleBadgeJa,
};

function getAppStoreBadgeIcon({ brand, locale }: IAppStoreBadgeProps) {
  if (brand === 'apple') {
    return LOCALE_TO_APPLE_BADGE[locale];
  }

  return LOCALE_TO_GOOGLE_BADGE[locale];
}

export function AppStoreBadge({ brand, locale }: IAppStoreBadgeProps) {
  return (
    <img
      src={getAppStoreBadgeIcon({ brand, locale })}
      alt={brand === 'apple' ?
        I18n.t('commons.appStoreBadge.storeBadgeAltApple') :
        I18n.t('commons.appStoreBadge.storeBadgeAltGoogle')
      }
    />
  );
}
