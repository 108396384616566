import React from 'react';
import I18n from 'i18n-js';
import { useUser } from '@reverbdotcom/commons/src/user_hooks';
import { V3 } from '../api_request';
import UrlHelpers from '../shared/url_helpers';
import { getShippingRegionOptions, IShippingRegion } from '../shipping_region_helpers';

function LocalePickerRegionSelect() {
  const [shippingRegions, setShippingRegions] = React.useState([]);
  const { everywhere, topRegions, usRegions, remainingRegions } = getShippingRegionOptions(shippingRegions);
  const user = useUser();

  React.useEffect(() => {
    V3.get(UrlHelpers.shippingRegionsPath).done((response) => {
      setShippingRegions(response.shipping_regions);
    });
  }, []);

  function renderRegionOption(region: IShippingRegion) {
    return (
      <option
        key={region.code}
        data-region-type={region.region_type}
        value={region.code}
      >
        {region.name}
      </option>
    );
  }

  return (
    <div className="styled-dropdown">
      {/* in order to preserve the defaultValue of the user's current shipping region, we can only mount the select once we have the api response */}
      {!shippingRegions.length && (
        (
          <select
            id="locale_picker_shipping_region_code_field"
            name="shipping_region_code"
          >
            <option value={user.shippingRegionCode}>
              {I18n.t(`discovery.shippingRegions.${user.shippingRegionCode}`)}
            </option>
          </select>
        )
      )}
      {!!shippingRegions.length && (
        <select
          id="locale_picker_shipping_region_code_field"
          name="shipping_region_code"
          defaultValue={user.shippingRegionCode}
        >
          <optgroup>
            {everywhere.map(renderRegionOption)}
          </optgroup>
          <optgroup>
            {topRegions.map(renderRegionOption)}
          </optgroup>
          <optgroup>
            {usRegions.map(renderRegionOption)}
          </optgroup>
          <optgroup>
            {remainingRegions.map(renderRegionOption)}
          </optgroup>
        </select>
      )}
    </div>
  );
}

export default LocalePickerRegionSelect;
