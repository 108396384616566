import { GOOGLE_RECAPTCHA_SITE_KEY } from '@reverbdotcom/env';
import window from './window_wrapper';
import { elog } from './elog';
import { RecaptchaAction } from './constants';

/**
 * This function helps us set a Google Recaptcha token as a header value.
 *
 * Usage:
 * ```
 * const token = await generateRecaptchaToken(GOOGLE_RECAPTCHA_ACTIONS.SEND_MESSAGE);
 *
 * {
 *   headers: {
 *     'X-Google-Recaptcha-Token': token,
 *   },
 * };
 * ```
 */
export async function generateRecaptchaToken(action: RecaptchaAction) {
  try {
    const recaptcha = window.grecaptcha?.enterprise;

    if (!recaptcha) {
      logEvent(
        'failure',
        {
          expected_action: action,
          token_present: false,
        },
      );

      return null;
    }

    const token = await recaptcha.execute(GOOGLE_RECAPTCHA_SITE_KEY, { action });

    logEvent(
      'success',
      {
        expected_action: action,
        token_present: !!token,
      },
    );

    return token;
  } catch (e) {
    elog.error(eventKey('error'), {}, e);

    return null;
  }
}

function logEvent(eventName: 'success' | 'failure', data: Record<string, any>) {
  elog.info(eventKey(eventName), data);
}

function eventKey(name: 'success' | 'failure' | 'error') {
  return `generate_recaptcha_token.${name}`;
}
