import { core_apimessages_ListingSaleMembership } from '@reverbdotcom/commons/src/gql/graphql';
import { ISalesMembershipsState } from './SalesMembershipsContext';

export enum ActionType {
  UPDATE_REVERB_SALE_MEMBERSHIP = 'updateReverbSaleMembership',
  UPDATE_SELLER_SALE_MEMBERSHIP = 'updateSellerSaleMembership',
}
interface IPayload {
  saleMembership: core_apimessages_ListingSaleMembership;
}
interface IAction {
  type?: ActionType;
  payload: IPayload;
}


export default function salesMembershipsReducer(state: ISalesMembershipsState, action: IAction): ISalesMembershipsState {
  switch (action.type) {
    case ActionType.UPDATE_REVERB_SALE_MEMBERSHIP: {
      const updatedReverbSalesMemberships = state.reverbSalesMemberships.map(saleMembership => {
        const updatedMembership = action.payload.saleMembership;

        if (saleMembership.sale.id == updatedMembership.sale.id) {
          return updatedMembership;
        } else {
          return saleMembership;
        }
      });

      return {
        ...state,
        ...{
          reverbSalesMemberships: updatedReverbSalesMemberships,
        },
      };
    }

    case ActionType.UPDATE_SELLER_SALE_MEMBERSHIP: {
      const updatedSellerSalesMemberships = state.sellerSalesMemberships.map(saleMembership => {
        const updatedMembership = action.payload.saleMembership;

        if (saleMembership.sale.id == updatedMembership.sale.id) {
          return updatedMembership;
        } else {
          return saleMembership;
        }
      });

      return {
        ...state,
        ...{
          sellerSalesMemberships: updatedSellerSalesMemberships,
        },
      };

    }

    default: {
      return { ...state };
    }
  }
}
