import PropTypes from 'prop-types';
import React from 'react';

class StreetAddressFormatHint extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showHint: false,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.checkStreetAddressFormatting(nextProps.streetAddress);
  }

  checkStreetAddressFormatting(rawText) {
    const inputText = $.trim(rawText);

    const $select = $(this.refs.streetAddressFormatHint);
    if (this.inputHasOneWord(inputText) && this.inputHasNoNumbers(inputText)) {
      $select.slideDown();
      this.setState({ showHint: true });
    } else {
      $select.slideUp('fast', () => this.setState({ showHint: false }));
    }
  }

  inputHasOneWord(text) {
    const singleWordRegex = new RegExp(/^[^\s]*$/);
    return singleWordRegex.test(text);
  }

  inputHasNoNumbers(text) {
    const noNumberRegex = new RegExp(/^[^0-9]+$/);
    return noNumberRegex.test(text);
  }

  display() {
    return {
      display: this.state.showHint ? 'block' : 'none',
    };
  }

  render() {
    return (
      <div
        ref="streetAddressFormatHint"
        style={this.display()}
        className="alert alert-warn alert-tiny mt-0"
      >
        <p className="small">
          {I18n.t('js.bundledCheckout.streetAddressFormatHint.hint')}
        </p>
      </div>
    );
  }
}

StreetAddressFormatHint.propTypes = {
  streetAddress: PropTypes.string,
};

export default StreetAddressFormatHint;
