import React from 'react';
import classNames from 'classnames';

interface IHelpCardProps {
  children: React.ReactNode;
  svgIcon?: string;
  iconAlt?: string;
  variant?: 'default' | 'secondary';
}

interface IHelpCardIconProps {
  svgIcon: string;
  iconAlt: string;
}

const DEFAULT_VARIANT = 'default';

function HelpCardIcon({ svgIcon, iconAlt }: IHelpCardIconProps) {

  return (
    <div className="help-card__icon-container">
      <div className="help-card__icon-frame">
        <img
          src={svgIcon}
          alt={iconAlt}
          className="help-card__icon"
        />
      </div>
    </div>
  );
}

export default function HelpCard({
  children,
  svgIcon = null,
  iconAlt = '',
  variant = DEFAULT_VARIANT,
}: IHelpCardProps) {

  return (
    <div className={classNames('help-card', `help-card--${variant}`)}>
      {svgIcon && (
        <HelpCardIcon
          svgIcon={svgIcon}
          iconAlt={iconAlt}
        />
      )}
      <div className="help-card__content">
        {children}
      </div>
    </div>
  );
}
