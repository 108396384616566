import React from 'react';
import I18n from 'i18n-js';
import { RCButton } from '@reverbdotcom/cadence/components';
import { MParticleEventName, trackEvent } from '@reverbdotcom/commons/src/elog/mparticle_tracker';
import SalesModal from './SalesModal';

interface IExternalProps {
  listingId: string;
}

export const COMPONENT_NAME = 'SalesModalButton';

export default function SalesModalButton({ listingId }: IExternalProps) {
  const [isModalOpen, setIsModalOpen] = React.useState(false);

  function handleClick() {
    setIsModalOpen(true);
    trackEvent({
      eventName: MParticleEventName.ClickedComponent,
      componentName: COMPONENT_NAME,
      listingId,
    });
  }

  return (
    <>
      <RCButton
        variant="muted"
        onClick={handleClick}
        fullWidth
      >
        {I18n.t('discovery.listingsManagement.sellerListingCard.sales.salesButtonText')}
      </RCButton>

      <SalesModal
        listingId={listingId}
        isModalOpen={isModalOpen}
        onModalClose={() => setIsModalOpen(false)}
      />
    </>
  );
}
