import React from 'react';
import I18n from 'i18n-js';
import classNames from 'classnames';
import bind from '@reverbdotcom/commons/src/bind';
import { IUserContext, withUserContext } from '@reverbdotcom/commons/src/components/user_context_provider';
import ScrollTarget from '@reverbdotcom/commons/src/components/scroll_target';
import HeaderDropdown from '@reverbdotcom/commons/src/components/header_dropdown';
import CoreLink from '@reverbdotcom/commons/src/components/core_link';
import { trackEvent } from '@reverbdotcom/commons/src/elog/mparticle_tracker';
import { MParticleEventName } from '@reverbdotcom/commons/src/elog/mparticle_types';
import { CaretDownIcon } from '@reverbdotcom/cadence/icons/react';
import { RCTextWithIcon } from '@reverbdotcom/cadence/components';

import NewsDropdown from './news_dropdown';
import CategoryFlyout, { NAV_LINK_COMP_NAME } from '../category_flyout';
import userHelpCenterUrl from '../shared/user_help_center_url';
import { dealsAndStealsPromoURLPath } from '../promo_url_path_for_user';
import { REVERB_OUTLET_PATH } from '@reverbdotcom/commons/src/url_helpers';

import {
  brandData,
  buildExploreLinks,
  LinkData,
  CategoryPanelData,
  buildCategoryData,
} from '../category_flyout_data';
import { eligibleForOutlet } from '@reverbdotcom/commons/src/user_context_helpers';

const MOBILE_WEB_BREAKPOINT = 640;

interface IState {
  openFlyoutKey: string;
  activeDropdownKey: string;
  overflowCategories: boolean;
}

function ExploreLink(linkData: LinkData) {
  return (
    <CoreLink
      key={linkData.textKey}
      className="category-flyout-header__link"
      to={linkData.href}
      clickEvent={{
        eventName: MParticleEventName.ClickedComponent,
        componentName: NAV_LINK_COMP_NAME,
        entityId: linkData.href,
        entityType: 'url',
        displayStyle: 'exploreDropdown',
      }}
    >
      <span className="mr-2">{I18n.t(`discovery.categoryNavBar.exploreLinks.${linkData.textKey}`)}</span>
    </CoreLink>
  );
}

export class CategoryFlyoutHeader extends React.Component<IUserContext, IState> {
  state = {
    openFlyoutKey: null,
    activeDropdownKey: null,
    overflowCategories: false,
  };

  scrollTarget: ScrollTarget;

  categoriesInner: HTMLElement = null;

  lastPrimaryCategory: HTMLElement = null;

  componentDidMount() {
    window.addEventListener('resize', this.updateOverflow);
    this.updateOverflow();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateOverflow);
  }

  setCategoryFlyout(openFlyoutKey) {
    if (openFlyoutKey === this.state.openFlyoutKey) {
      return this.setState({ openFlyoutKey: null });
    }

    if (openFlyoutKey) {
      const shouldScroll = window && window.innerWidth < MOBILE_WEB_BREAKPOINT;

      if (shouldScroll) this.scrollTarget.scrollToTop();
    }

    return this.setState({ openFlyoutKey });
  }

  @bind
  closeFlyout() {
    this.setState({ openFlyoutKey: null });
    this.categoriesInner?.focus();
  }

  @bind
  setDropdownOpen(key) {
    this.setState({ activeDropdownKey: key });
  }

  @bind
  updateOverflow() {
    if (!this.categoriesInner || !this.lastPrimaryCategory) {
      this.setState({ overflowCategories: false });
      return;
    }
    const rowCutoff = this.categoriesInner.getBoundingClientRect().right;
    const lastCategoryCutoff = this.lastPrimaryCategory.getBoundingClientRect().right;
    const overflowCategories = lastCategoryCutoff > rowCutoff;

    this.setState(
      { overflowCategories },
    );
  }

  renderFlyoutButton(category: CategoryPanelData) {
    const classes = classNames(
      'category-flyout-header__link',
      { 'category-flyout-header__link--active': category.key === this.state.openFlyoutKey },
    );
    const displayStyle = this.state.openFlyoutKey ? 'flyoutOpenNavLink' : 'flyoutClosedNavLink';

    return (
      <button
        key={category.key}
        type="button"
        className={classes}
        data-header-category={category.key}
        onClick={() => {
          this.setCategoryFlyout(category.key);
          trackEvent({
            eventName: MParticleEventName.ClickedComponent,
            componentName: NAV_LINK_COMP_NAME,
            entityId: category.key,
            entityType: 'button',
            displayStyle,
          });
        }}
      >
        {I18n.t(`${category.i18nPrefix}.title`)}
      </button>
    );
  }

  @bind
  filterLinksByUserAttributes(link) {
    const { user } = this.props;

    if (user.countryCode !== 'US' && link.usOnly) {
      return false;
    }

    if (user.locale !== 'en' && link.enLocaleOnly) {
      return false;
    }

    return true;
  }

  renderExploreLink(dropdownKey) {
    const exploreLinks = buildExploreLinks(this.props.user, false);

    return (
      <HeaderDropdown
        trigger={(
          <span className="category-flyout-header__link">
            <RCTextWithIcon svgComponent={CaretDownIcon}>
              {I18n.t('discovery.navLinks.explore')}
            </RCTextWithIcon>
          </span>
        )}
        contents={exploreLinks.filter(this.filterLinksByUserAttributes).map(ExploreLink)}
        onOpen={this.setDropdownOpen}
        dropdownKey={dropdownKey}
        activeDropdownKey={this.state.activeDropdownKey}
      />
    );
  }

  secondaryClickEvent(href :string) {
    const displayStyle = this.state.openFlyoutKey ? 'flyoutOpenNavLink' : 'flyoutClosedNavLink';

    return {
      eventName: MParticleEventName.ClickedComponent,
      componentName: NAV_LINK_COMP_NAME,
      entityId: href,
      entityType: 'url',
      displayStyle,
    };
  }

  renderSecondary() {
    const { locale, loggedOut } = this.props.user;
    const helpCenterLink = `${userHelpCenterUrl(locale)}?utm_source=Reverb&utm_medium=header&utm_campaign=${loggedOut ? 'loggedout' : 'loggedin'}`;

    return (
      <ul className="category-flyout-header__link-bar__secondary">
        <li className="category-flyout-header__link-bar__list-item">
          {this.renderFlyoutButton(brandData)}
        </li>
        <li className="category-flyout-header__link-bar__list-item" data-news-trigger>
          <HeaderDropdown
            coreLinkTrigger={
              <CoreLink
                to="/news"
                className="icon-r-caret-down category-flyout-header__link"
                clickEvent={this.secondaryClickEvent('/news')}
              >
                {I18n.t('discovery.navLinks.news')}
              </CoreLink>
            }
            contents={<NewsDropdown />}
            onOpen={this.setDropdownOpen}
            dropdownKey="news"
            activeDropdownKey={this.state.activeDropdownKey}
          />
        </li>
        <li className="category-flyout-header__link-bar__list-item">
          {this.renderExploreLink('explore-secondary')}
        </li>
        <li className="category-flyout-header__link-bar__list-item">
          <CoreLink
            className="category-flyout-header__link"
            to="/shops"
            clickEvent={this.secondaryClickEvent('/shops')}
          >
            {I18n.t('discovery.categoryNavBar.shops')}
          </CoreLink>
        </li>
        <li className="category-flyout-header__link-bar__list-item">
          <CoreLink
            to="/page/reverb-gives"
            className="category-flyout-header__link"
            clickEvent={this.secondaryClickEvent('/page/reverb-gives')}
          >
            {I18n.t('discovery.navLinks.reverbGives')}
          </CoreLink>
        </li>
        <li className="category-flyout-header__link-bar__list-item">
          <CoreLink
            to={helpCenterLink}
            className="category-flyout-header__link"
            entityId="help-center"
            clickEvent={this.secondaryClickEvent(helpCenterLink)}
          >
            {I18n.t('discovery.navLinks.helpCenter')}
          </CoreLink>
        </li>
      </ul>
    );
  }

  renderOverflow(categories: CategoryPanelData[]) {
    if (!this.state.overflowCategories) { return null; }

    const buttons = categories.map((category) => {
      return this.renderFlyoutButton(category);
    });

    return (
      <HeaderDropdown
        trigger={(
          <span className="category-flyout-header__view-more">
            {I18n.t('discovery.categoryNavBar.viewAll')}
          </span>
        )}
        contents={buttons}
        onOpen={this.setDropdownOpen}
        dropdownKey="more"
        activeDropdownKey={this.state.activeDropdownKey}
      />
    );
  }

  render() {
    const categoryData = buildCategoryData(this.props.user);
    const outletExperiment = eligibleForOutlet(this.props.user);

    const classes = classNames(
      'category-flyout-header',
      { 'category-flyout-header--open': !!this.state.openFlyoutKey },
    );

    return (
      <div
        className={classes}
        role="navigation"
        aria-label={I18n.t('discovery.categoryNavBar.navigationRole')}
      >
        <div
          className="category-flyout-header__underlay"
          onClick={this.closeFlyout}
          role="presentation"
        />
        <div className="category-flyout-header__link-bar">
          <ScrollTarget ref={(r) => { this.scrollTarget = r; }} />
          <ul
            className="category-flyout-header__link-bar__primary"
            ref={(el) => { this.categoriesInner = el; }}
            tabIndex={-1}
          >
            {!outletExperiment &&
              <li className="category-flyout-header__link-bar__list-item category-flyout-header__link-bar__list-item--tablet-only">
                <CoreLink
                  className="category-flyout-header__link"
                  to={dealsAndStealsPromoURLPath(this.props.user)}
                  clickEvent={{
                    eventName: MParticleEventName.ClickedComponent,
                    componentName: NAV_LINK_COMP_NAME,
                    entityId: dealsAndStealsPromoURLPath(this.props.user),
                    entityType: 'url',
                    displayStyle: 'tabletNav',
                  }}
                >
                  {I18n.t('discovery.navLinks.findADeal')}
                </CoreLink>
              </li>
            }
            {outletExperiment &&
              <li className="category-flyout-header__link-bar__list-item category-flyout-header__link-bar__list-item__outlet">
                <CoreLink
                  className="category-flyout-header__link"
                  to={REVERB_OUTLET_PATH}
                  clickEvent={this.secondaryClickEvent('/outlet')}
                >
                  {I18n.t('discovery.categoryNavBar.theReverbOutlet')}
                </CoreLink>
              </li>
            }
            {categoryData.map((category, idx) => (
              <li
                className="category-flyout-header__link-bar__list-item"
                key={category.key}
                ref={(el) => {
                  if (idx === (categoryData.length - 1)) {
                    this.lastPrimaryCategory = el;
                  }
                }}
              >
                {this.renderFlyoutButton(category)}
              </li>
            ))}
            <div className="d-none tablet-d-inline-block">
              {this.renderFlyoutButton(brandData)}
            </div>
            <div className="d-none tablet-d-inline-block">
              {this.renderExploreLink('explore-tablet')}
            </div>
          </ul>
          {this.renderOverflow(categoryData)}
          {this.renderSecondary()}
        </div>
        {categoryData.map((category) => (
          <CategoryFlyout
            key={category.key}
            data={category}
            visible={category.key === this.state.openFlyoutKey}
            closeDialog={this.closeFlyout}
          />
        ))}
        <CategoryFlyout
          key={brandData.key}
          data={brandData}
          visible={brandData.key === this.state.openFlyoutKey}
          closeDialog={this.closeFlyout}
        />
      </div>
    );
  }
}

export default withUserContext(CategoryFlyoutHeader);
