export function setScrollbarWidthVars() {
  window.addEventListener('load', () => {
    const headTag = document?.getElementsByTagName('head')[0];
    const styleTag = document?.createElement('style');
    const windowWidth = window?.innerWidth || 0;
    const documentWidth = document?.documentElement?.clientWidth || 0;
    const scrollbarWidth = windowWidth - documentWidth;

    if (headTag && styleTag && scrollbarWidth >= 0) {
      const value = scrollbarWidth > 0 ? `${scrollbarWidth}px` : scrollbarWidth;

      styleTag.innerHTML = `html { --rc-size-width-scrollbar: ${value} }`;
      headTag.appendChild(styleTag);
    }
  }, {
    once: true, // invoke once then remove listener
  });
}
