import React from 'react';
import I18n from 'i18n-js';
import { useUser } from '@reverbdotcom/commons/src/user_hooks';
import Currency from '@reverbdotcom/commons/src/currency';
import { RCIcon } from '@reverbdotcom/cadence/components';
import { GlobeIcon } from '@reverbdotcom/cadence/icons/react';
import { LANGUAGES } from './shared/constants';
import LocalePickerButton from './locale_picker/locale_picker_button';

export default function FooterLocalePicker() {
  const { locale, currency, shippingRegionCode } = useUser();
  const language = LANGUAGES[locale];
  const currencySymbol = Currency.get(currency)?.symbol || '';

  return (
    <LocalePickerButton className="locale-switcher">
      <RCIcon svgComponent={GlobeIcon} />
      <div className="locale-switcher__current">
        <span className="locale-switcher__detail">
          {I18n.t(`discovery.shippingRegions.${shippingRegionCode}`)}
        </span>
        <span className="locale-switcher__detail">
          {language}
        </span>
        <span className="locale-switcher__detail">
          {`${currencySymbol} ${currency}`}
        </span>
      </div>
    </LocalePickerButton>
  );
}
