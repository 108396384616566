import React from 'react';
import { APIRequest } from '../index';
import LoadingOverlay from '../shared/loading_overlay';
import StatsDisplay from './shop_stats/stats_display';
import StatsGraph from './shop_stats/stats_graph';
import SalesStats from './shop_stats/sales_stats';
import ShopData from './shop_stats/shop_data';
import TimePeriods from './shop_stats/time_periods';
import Earnings from './seller_hub/Earnings';

const WAITING_PERIOD_LENGTH = 200; // milliseconds

interface IProps {
  showEarnings?: boolean;
}

function sellerGraph(formattedStatsData, chartData, chartLoadError) {
  if (!formattedStatsData.shouldShowGraph) { return null; }

  return <StatsGraph chartData={chartData} error={chartLoadError} />;
}

function salesStats(formattedStatsData) {
  return <SalesStats shopData={formattedStatsData} />;
}

// component to be deprecated with full release of Seller Hub (New Seller Dashboard)
function ShopStats({ showEarnings = false }: IProps) {
  const [chartData, setChartData] = React.useState(null);
  const [statsData, setStatsData] = React.useState({});
  const [dataLoaded, setDataLoaded] = React.useState(false);
  const [waitingPeriodOver, setWaitingPeriodOver] = React.useState(false);
  const [timePeriod, setTimePeriod] = React.useState(null);
  const [statsLoadError, setStatsLoadError] = React.useState(false);
  const [chartLoadError, setChartLoadError] = React.useState(false);

  const timePeriods = React.useMemo(() => new TimePeriods(timePeriod), [timePeriod]);
  const formattedStatsData = React.useMemo(() => new ShopData(timePeriods, statsData), [timePeriods, statsData]);

  React.useEffect(() => {
    setTimeout(() => setWaitingPeriodOver(true), WAITING_PERIOD_LENGTH);
    fetchStatAndChartData();
  }, []);

  React.useEffect(() => {
    if (timePeriod === null) {
      return;
    }

    fetchStatAndChartData();
  }, [timePeriod]);

  async function fetchStatAndChartData() {
    setDataLoaded(false);
    await Promise.all([fetchStatsData(), fetchChartData()]);
    setDataLoaded(true);
  }

  async function fetchStatsData() {
    try {
      const response = await APIRequest.get(
        '/my/shop_stats',
        { time_period: timePeriods.timePeriodString },
      );
      setStatsData(response);
    } catch (error) {
      setStatsLoadError(true);
    }
  }

  async function fetchChartData() {
    try {
      const response = await APIRequest.get(
        '/my/selling/charts/revenue',
        { time_period: timePeriods.timePeriodString },
      );
      setChartData(response);
    } catch (error) {
      setChartLoadError(true);
    }
  }

  if (!(formattedStatsData.shouldShowStats || waitingPeriodOver)) { return null; }
  if (!formattedStatsData.shouldShowStats && !statsLoadError && dataLoaded) {
    return null;
  }

  return (
    <div className="width-100">
      <LoadingOverlay showOverlay={!dataLoaded}>
        {showEarnings &&
          <Earnings timePeriod={timePeriod} />
        }
        <StatsDisplay
          sellerGraph={sellerGraph(formattedStatsData, chartData, chartLoadError)}
          timePeriods={timePeriods}
          timePeriodChanged={val => setTimePeriod(val)}
          statsData={formattedStatsData}
          salesStats={salesStats(formattedStatsData)}
          error={statsLoadError}
        />
      </LoadingOverlay>
    </div>
  );
}

export default ShopStats;
