import React from 'react';
import { RCLoadingBars } from '@reverbdotcom/cadence/components';
import ErrorMessage from './error_message';

function Loading(props) {
  if (!props.loaded) {
    return (
      <div className="scaling-mtb-4 d-flex fx-justify-center">
        <RCLoadingBars />
      </div>
    );
  }

  if (props.hasError) { return <ErrorMessage message={props.errorMessage} />; }

  return <div className="loaded-component">{props.children}</div>;
}

export default Loading;
