export enum ActionType {
  SHOW_TOAST = 'showToast',
  HIDE_TOAST = 'hideToast',
}
interface IAction {
  type: ActionType;
  payload?: any;
}
export interface ICardState {
  toast: {
    isOpen: boolean;
    message?: string;
    isSuccess?: boolean;
  };
}

function sellerListingCardReducer(state: ICardState, action: IAction) {
  switch (action.type) {
    case ActionType.SHOW_TOAST:
      return {
        ...state,
        toast: {
          isOpen: true,
          message: action.payload.message,
          isSuccess: action.payload.isSuccess,
        },
      };
    case ActionType.HIDE_TOAST:
      return { ...state, toast: { isOpen: false } } ;
    default:
      return { ...state };
  }
}

export default sellerListingCardReducer;
