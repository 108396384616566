import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { camelizeKeys } from 'humps';
import { ShipOrderHeader, PacklinkShippingLabelButton, AddressSummary } from '@reverbdotcom/discovery-ui';

import UrlHelpers from './shared/url_helpers';
import Order from './shipping_labels/order';
import { actions as ShippingLabelActions } from './shipping_labels/shipping_label_form_module';
import MultiOrderSelectContainer from './shipping_labels/containers/multi_order_select_container';
import MultiOrderSelect from './shipping_labels/multi_order_select';
import MONEY_ICON from '../../images/icons/money-stack.svg';
import CLOCK_ICON from '../../images/icons/clock.svg';
import SHIELD_ICON from '../../images/icons/shield.svg';

const FieldWithError = (props) => {
  const errorMessage = () => {
    if (!props.error) { return false; }
    return <p className="mb-0"><span className="error">{props.error}</span></p>;
  };

  return (
    <div className={classNames({ 'ui-state-error': props.error })}>
      {props.children}
      {errorMessage(props)}
    </div>
  );
};

FieldWithError.defaultProps = {
  error: '',
};

FieldWithError.propTypes = {
  children: PropTypes.node.isRequired,
  error: PropTypes.string,
};

const PACKLINK = 'packlink';
const FEDEX = 'FedEx';
const FEDEX_BARCODE_LENGTH = 34;
const FEDEX_LAST_N_DIGITS_TRACKING = 14;

// This component performs a similar role to app/assets/javascripts/components/manual_shipping_info_form.jsx
// Please ensure any tracking code form updates are consistent in both places.
class TrackOrderForm extends React.Component {
  constructor(props) {
    super(props);

    this.setManualProvider = this.setManualProvider.bind(this);
    this.setCode = this.setCode.bind(this);
    this.setProvider = this.setProvider.bind(this);
    this.validateForm = this.validateForm.bind(this);
    this.renderBuyShippingLabelSection = this.renderBuyShippingLabelSection.bind(this);
    this.shippingLabelButton = this.shippingLabelButton.bind(this);
    this.packlinkShippingLabelButton = this.packlinkShippingLabelButton.bind(this);
    this.reverbShippingLabelLink = this.reverbShippingLabelLink.bind(this);
    this.submittedFedexBarcode = this.submittedFedexBarcode.bind(this);
    this.getCodeError = this.getCodeError.bind(this);

    const primaryOrder = new Order(JSON.parse(this.props.primaryOrder));

    this.state = {
      primaryOrder,
      provider: this.setSelectedOption(),
      code: this.props.selectedCode,
      codeError: this.props.codeError,
      manualProvider: this.setDefaultManualProvider(),
    };
  }

  componentDidMount() {
    ShippingLabelActions.setupPrimaryOrder(this.state.primaryOrder);
  }

  setSelectedOption() {
    if (this.props.options.indexOf(this.props.selectedProvider) > -1) {
      return this.props.selectedProvider;
    }

    return this.props.otherOption;
  }

  setDefaultManualProvider() {
    if (this.props.options.indexOf(this.props.selectedProvider) > -1) {
      return null;
    }

    return this.props.selectedProvider;
  }

  setManualProvider(event) {
    const manualProvider = event.target.value.trim();
    this.setState({ manualProvider });
  }

  setProvider(event) {
    this.setState({
      provider: event.target.value,
      codeError: null,
      manualProviderError: null,
    });
  }

  setCode(event) {
    const code = event.target.value.trim();
    this.setState({ code });
  }

  selectOptions() {
    return this.props.options.map(o => <option key={o} value={o}>{o}</option>);
  }

  showManualProvider() {
    return this.state.provider === this.props.otherOption;
  }

  providerField() {
    if (!this.showManualProvider()) { return false; }

    return (
      <div className="manual-provider">
        <label htmlFor="manual_provider">
          {I18n.t('js.trackOrderForm.providerName')}
        </label>
        <FieldWithError error={this.state.manualProviderError}>
          <input
            type="text"
            placeholder={I18n.t('js.trackOrderForm.enterName')}
            className="wide"
            name="manual_provider"
            onChange={this.setManualProvider}
          />
        </FieldWithError>
      </div>
    );
  }

  codeValid() {
    const validator = this.props.trackingValidations[this.state.provider];
    if (!validator) { return true; }

    const code = this.state.code.replace(/\s|-|_|#/g, '');
    return code.match(new RegExp(validator, 'i'));
  }

  // FedEx barcodes are 34 chars long. The tracking # is the last 14 digits of the barcode.
  // http://pages.message.fedex.com/barcodescan_home
  submittedFedexBarcode() {
    return this.state.provider === FEDEX && !!this.state.code && this.state.code.length === FEDEX_BARCODE_LENGTH;
  }

  getCodeError() {
    if (!this.state.code) {
      return I18n.t('js.trackOrderForm.codeError');
    }

    if (!this.codeValid() && this.submittedFedexBarcode()) {
      const suggested = this.state.code.slice(-FEDEX_LAST_N_DIGITS_TRACKING);

      return I18n.t('js.trackOrderForm.barcodeError', {
        provider: this.state.provider,
        lastNDigits: FEDEX_LAST_N_DIGITS_TRACKING,
        suggested,
      });
    }

    if (!this.codeValid()) {
      return I18n.t('js.trackOrderForm.invalidCode', { provider: this.state.provider });
    }

    return null;
  }

  validateForm(event) {
    let manualProviderError = null;
    const codeError = this.getCodeError();

    if (this.showManualProvider() && !this.state.manualProvider) {
      manualProviderError = I18n.t('js.trackOrderForm.providerError');
    }

    if (manualProviderError || codeError) {
      event.preventDefault();
    }

    this.setState({ manualProviderError, codeError });
  }

  formTitle() {
    if (this.props.isEditingTracking) {
      return I18n.t('js.trackOrderForm.editTrackingInfo');
    }

    return I18n.t('js.trackOrderForm.addTrackingInfo');
  }

  renderBuyShippingLabelSection() {
    if (!this.props.canPurchaseLabel) {
      return null;
    }

    return (
      <div className="bg-white padding-4 bd-radius-primary scaling mb-4" data-shipping-label-section>
        <h4 className="weight-bold size-120 mb-4">
          {I18n.t(`js.trackOrderForm.buyLabel.${this.props.shippingLabelProvider}.header`)}
        </h4>
        <ul className="mb-6">
          <li className="mb-2 d-flex">
            <img src={MONEY_ICON} alt={I18n.t('js.trackOrderForm.buyLabel.saveMoneyAlt')} />
            <div className="ml-4">
              <p className="weight-bold lh-120 mb-0">
                {I18n.t('js.trackOrderForm.buyLabel.saveMoneyLabel')}
              </p>
              <p className="lh-120 mb-0">
                {I18n.t(`js.trackOrderForm.buyLabel.${this.props.shippingLabelProvider}.saveMoney`)}
              </p>
            </div>
          </li>
          <li className="mb-2 d-flex">
            <img src={CLOCK_ICON} alt={I18n.t('js.trackOrderForm.buyLabel.saveTimeAlt')} />
            <div className="ml-4">
              <p className="weight-bold lh-120 mb-0">
                {I18n.t('js.trackOrderForm.buyLabel.saveTimeLabel')}
              </p>
              <p className="lh-120 mb-0">
                {I18n.t(`js.trackOrderForm.buyLabel.${this.props.shippingLabelProvider}.saveTime`)}
              </p>
            </div>
          </li>
          <li className="d-flex">
            <img src={SHIELD_ICON} alt={I18n.t('js.trackOrderForm.buyLabel.protectShipmentAlt')} />
            <div className="ml-4">
              <p className="weight-bold lh-120 mb-0">
                {I18n.t('js.trackOrderForm.buyLabel.protectShipmentLabel')}
              </p>
              <p className="lh-120 mb-0">
                {I18n.t(`js.trackOrderForm.buyLabel.${this.props.shippingLabelProvider}.protectShipment`)}
              </p>
            </div>
          </li>
        </ul>
        <div className="d-flex fx-align-center mobile-d-block mobile-align-center">
          {this.shippingLabelButton()}
          <a href={UrlHelpers.cmsPagePath('shipping-labels')} rel="noopener noreferrer" target="_blank">
            {I18n.t('js.trackOrderForm.buyLabel.learnMore')}
          </a>
        </div>
      </div>
    );
  }

  shippingLabelButton() {
    const shippingLabelButtonProps = {
      classes: 'button button--primary mr-4 mobile-mr-0 mobile-width-100 mobile-mb-2',
      translation: I18n.t(`js.trackOrderForm.buyLabel.${this.props.shippingLabelProvider}.actionText`),
    };

    if (this.props.shippingLabelProvider === PACKLINK) {
      return this.packlinkShippingLabelButton(shippingLabelButtonProps);
    }

    return this.reverbShippingLabelLink(shippingLabelButtonProps);
  }

  packlinkShippingLabelButton({ classes, translation }) {
    return (
      <PacklinkShippingLabelButton
        orderId={this.state.primaryOrder.id}
        classes={classes}
        translation={translation}
      />
    );
  }

  reverbShippingLabelLink({ classes, translation }) {
    return (
      <a
        data-reverb-shipping-label-link
        href={this.props.buyLabelUrl}
        className={classes}
      >
        {translation}
      </a>
    );
  }

  renderPageTitle() {
    return I18n.t(
      'js.shipOrderViews.header.pageTitle',
      { orderId: this.state.primaryOrder.id },
    );
  }

  renderShippingAddress() {
    if (!this.props.shippingAddress) { return null; }

    const address = JSON.parse(this.props.shippingAddress);

    return (
      <div className="g-col-4 g-offset-2 g-offset-mobile-0 g-col-mobile-12 mobile-mt-4">
        <h4 className="weight-bold size-120 mb-4 mobile-mb-0">
          {I18n.t('js.trackOrderForm.toAddress')}
        </h4>
        <AddressSummary address={camelizeKeys(address)} />
      </div>
    );
  }

  primaryOrderCreatedAtSeconds() {
    return this.state.primaryOrder.createdAtInUnixSeconds;
  }

  render() {
    return (
      <div className="site-wrapper site-wrapper--narrow">

        <div className="shipping-label-form__titleblock">
          <ShipOrderHeader
            pageTitle={this.renderPageTitle()}
            orderId={this.state.primaryOrder.id}
            buyerName={this.state.primaryOrder.buyerName}
            orderCreatedAtSeconds={this.primaryOrderCreatedAtSeconds()}
          />
        </div>

        {this.renderBuyShippingLabelSection()}

        <form
          action={this.props.url}
          method="post"
          onSubmit={this.validateForm}
          data-disable-auto-validation="true"
        >
          <input type="hidden" name="_method" value="put" />
          <input type="hidden" name="authenticity_token" value={this.props.token} />
          <div className="bg-white padding-4 bd-radius-primary scaling-mb-4">
            <div className="g-container">
              <div className="g-col-6 g-col-mobile-12">
                <input type="hidden" name="_method" value="put" />
                <input type="hidden" name="authenticity_token" value={this.props.token} />
                <h4 className="weight-bold size-120 mb-4">
                  {this.formTitle()}
                </h4>
                <div className="form-group">
                  <div className="form-group__header">
                    <label htmlFor="order[shipping_provider]">
                      {I18n.t('js.trackOrderForm.shippingProvider')}
                    </label>
                  </div>
                  <div className="form-group__fields">
                    <div className="styled-dropdown mb-0">
                      <select name="order[shipping_provider]" onChange={this.setProvider} value={this.state.provider}>
                        {this.selectOptions()}
                      </select>
                    </div>
                  </div>
                </div>
                {this.providerField()}
                <div className="form-group">
                  <div className="form-group__header">
                    <label htmlFor="order[shipping_code]">
                      {I18n.t('js.trackOrderForm.trackingNumber')}
                    </label>
                  </div>
                  <div className="form-group__fields">
                    <FieldWithError error={this.state.codeError}>
                      <input
                        type="text"
                        name="order[shipping_code]"
                        className="width-100"
                        placeholder={I18n.t('js.trackOrderForm.shippingCode')}
                        onChange={this.setCode}
                      />
                    </FieldWithError>
                  </div>
                </div>
                <div className="form-group">
                  <div className="form-group__fields">
                    <div className="label-with-checkbox">
                      <input
                        id="send_track_notification_checkbox"
                        type="checkbox"
                        value="1"
                        name="send_notification"
                        defaultChecked="checked"
                        className="add-to-sale-checkbox"
                      />
                      <label htmlFor="send_track_notification_checkbox">
                        {I18n.t('js.trackOrderForm.sendNotification')}
                      </label>
                    </div>
                  </div>
                </div>
                <MultiOrderSelectContainer
                  context={MultiOrderSelect.Context.ManualShippingInfoForm}
                />
                <div className="form-group mb-0">
                  <div className="form-group__fields">
                    <input
                      className="button button--primary mb-0"
                      name="commit"
                      type="submit"
                      value={I18n.t('js.trackOrderForm.addTracking')}
                    />
                  </div>
                </div>
              </div>
              {this.renderShippingAddress()}
            </div>
          </div>
        </form>
      </div>
    );
  }
}

TrackOrderForm.propTypes = {
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
  selectedProvider: PropTypes.string.isRequired,
  selectedCode: PropTypes.string,
  isEditingTracking: PropTypes.bool.isRequired,
  url: PropTypes.string.isRequired,
  otherOption: PropTypes.string.isRequired,
  codeError: PropTypes.string,
  trackingValidations: PropTypes.shape({}).isRequired,
  token: PropTypes.string.isRequired,
  primaryOrder: PropTypes.string.isRequired,
  shippingAddress: PropTypes.string,
  canPurchaseLabel: PropTypes.bool.isRequired,
  buyLabelUrl: PropTypes.string.isRequired,
  shippingLabelProvider: PropTypes.string.isRequired,
};

TrackOrderForm.defaultProps = {
  selectedCode: '',
  codeError: '',
  canPurchaseLabel: true,
  shippingAddress: null,
};

export default TrackOrderForm;
