import React from 'react';
import { GOOGLE_RECAPTCHA_TOKEN_PARAM, RecaptchaAction } from '../constants';
import { generateRecaptchaToken } from '../generate_recaptcha_token';

interface Props {
  formId: string;
  action: RecaptchaAction;
}

/**
 * This component helps us set a Google Recaptcha token as a param on HTML forms where we can't set it as a header.
 * See views/dashboard/messages/_form.haml for its usage.
 */
export function RecaptchaInput({
  formId,
  action,
}: Props) {
  React.useEffect(() => {
    async function onFormSubmit(e) {
      e.preventDefault();
      const token = await generateRecaptchaToken(action);

      e.target.elements[GOOGLE_RECAPTCHA_TOKEN_PARAM].value = token;

      e.target.submit();
    }

    const form = document.getElementById(formId);
    form.addEventListener('submit', onFormSubmit);

    return () => {
      form.removeEventListener('submit', onFormSubmit);
    };
  }, [formId, action]);

  return (
    <input type="hidden" name={GOOGLE_RECAPTCHA_TOKEN_PARAM} />
  );
}
