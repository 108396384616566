import { ChildProps } from '@apollo/client/react/hoc';
import React from 'react';
import I18n from 'i18n-js';
import {
  withCreateUserActionMutation,
  CoreUserActionsCreateMutationFn,
  useCreateUserAction,
} from '@reverbdotcom/commons/src/user_actions/create_action_mutation';
import { connect } from '@reverbdotcom/commons/src/connect';
import {
  UserAvailableActions,
  core_apimessages_UserActionName,
} from '@reverbdotcom/commons/src/gql/graphql';
import { RCGuidePopover } from '@reverbdotcom/cadence/components';
import {
  trackDismissUserActionEvent,
  useTrackViewUserActionEvent,
  isUserActionAvailable,
  AVAILABLE_ACTION_NAME_TO_I18N_PREFIX,
  availableUserActionsQuery,
} from './user_actions_helpers';
import { formatExperimentsForEventAttributes } from '@reverbdotcom/commons/src/elog/mparticle';
import { isExperimentEnabled } from '@reverbdotcom/commons/src/user_context_helpers';
import { useQuery } from '@reverbdotcom/commons/src/useQuery';
import { useUser } from '@reverbdotcom/commons/src/user_hooks';

interface ExternalProps {
  anchorComponent: JSX.Element;
  parentComponentName: string;
  userActionName: core_apimessages_UserActionName;
  showCallout: boolean;
  experimentName?: string;
}

interface MutationProps {
  createUserAction: CoreUserActionsCreateMutationFn;
}

type IProps = ChildProps<ExternalProps & MutationProps>;

export function UserActionCallout(props: IProps) {
  const user = useUser();

  const { data } = useQuery<UserAvailableActions.Query, UserAvailableActions.Variables>(
    availableUserActionsQuery,
    {
      errorPolicy: 'all',
      ssr: true,
      variables: {
        loggedOut: !!user.loggedOut,
      },
    },
  );

  const i18nPrefix = AVAILABLE_ACTION_NAME_TO_I18N_PREFIX[props.userActionName];
  const createUserActionState = useCreateUserAction(
    props.userActionName,
    data?.me,
    {
      listingId: null,
    },
    props.createUserAction,
  );

  const showUserActionCallout: boolean =
    props.showCallout && isUserActionAvailable(props.userActionName, data?.me?.availableActions);
  const expEnabled = props.experimentName ? isExperimentEnabled(user, props.experimentName) : true;
  const formattedExperiments = props.experimentName ? formatExperimentsForEventAttributes(user, [props.experimentName]) : null;

  // We want to track this view event for default and experiment groups anytime they would've seen it. Even after dismissal.
  useTrackViewUserActionEvent(props.parentComponentName, props.userActionName, props.showCallout, formattedExperiments);

  if (!showUserActionCallout || !expEnabled) { return (props.anchorComponent); }

  return (
    <RCGuidePopover
      title={i18nPrefix ? I18n.t(`${i18nPrefix}.title`) : ''}
      content={i18nPrefix ? I18n.t(`${i18nPrefix}.subtitle`) : ''}
      isOpen
      onDismiss={() => {
        createUserActionState.createUserAction();
        trackDismissUserActionEvent(props.parentComponentName, props.userActionName);
      }}
      anchor={props.anchorComponent}
      width="narrow"
      position="bottom-end"
      preventAutoFocus
    />
  );
}

export default connect<ExternalProps>([
  withCreateUserActionMutation,
])(UserActionCallout);
