import React from 'react';
import I18n from 'i18n-js';
import { RCBanner } from '@reverbdotcom/cadence/components';
import BrandedLockIcon from '@reverbdotcom/cadence/images/brand/security-orange.svg';
import { I18N as Translate } from '@reverbdotcom/commons/src/components/translate';
import CoreLink from '@reverbdotcom/commons/src/components/core_link';
import { Paths } from '../shared/url_helpers';

const HELP_CENTER_ARTICLE_ID = '21894613284627';

const ShopLockedModeBanner = () => {
  return (
    <RCBanner
      content={<Translate text="discovery.sellerDashboard.lockedModeBanner.description" />}
      cta={
        <CoreLink
          target="_blank"
          to={Paths.helpCenterArticlesUrl.expand({ articleId: HELP_CENTER_ARTICLE_ID })}
        >
          <Translate text="discovery.sellerDashboard.lockedModeBanner.helpCenterLinkText" />
        </CoreLink>
      }
      ctaVariant="muted"
      disableTexture
      fullBleed
      image={BrandedLockIcon}
      title={I18n.t('discovery.sellerDashboard.lockedModeBanner.title')}
      theme="error"
    />
  );
};

export default ShopLockedModeBanner;
