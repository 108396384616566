import PropTypes from 'prop-types';
import React from 'react';
import TogglableDropdown from '../../../shared/togglable_dropdown';
import I18n from 'i18n-js';

const optionsForSelect = currencies =>
  currencies.map(currency =>
    ({ name: currency.name, value: currency.code }));

const CurrencyInput = props =>
  (<div className="g-col-6" data-change-currency>
    <TogglableDropdown
      defaultValue={props.selectedPayoutCurrency}
      selectOptions={optionsForSelect(props.currencies)}
      inputName={props.nameForField('currency')}
      onChange={currencyCode => props.onCurrencyChange(currencyCode)}
      labelTitle={I18n.t('js.dashboard.selling.shopPolicies.directCheckoutProfile.bankAccountFields.currencyInput.label')}
      changeLinkText={I18n.t('js.dashboard.selling.shopPolicies.directCheckoutProfile.bankAccountFields.currencyInput.change')}
      autoComplete="currency-code"
    />
  </div>);

CurrencyInput.propTypes = {
  selectedPayoutCurrency: PropTypes.string.isRequired,
  onCurrencyChange: PropTypes.func.isRequired,
  nameForField: PropTypes.func.isRequired,
  currencies: PropTypes.array.isRequired,
};

export default CurrencyInput;
