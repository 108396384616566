import React from 'react';
import { RCLoadingBars } from '@reverbdotcom/cadence/components';

interface IProps {
  title?: string | JSX.Element | React.ReactNode;
  description?: string | JSX.Element | React.ReactNode;
  children: React.ReactNode;
  loading?: boolean;
  id?: string;
}

function FormSection({
  title,
  description,
  children,
  loading,
  id,
}: IProps) {
  function loadingState() {
    return (
      <div className="form-section__content">
        <div className="d-flex fx-align-center fx-justify-center">
          <RCLoadingBars />
        </div>
      </div>
    );
  }

  function loadedContent() {
    return (
      <>
        {(title || description) && (
          <div className="form-section__info">
            {title &&
              <h3 className="form-section__title">
                {title}
              </h3>
            }
            {description &&
              <div className="form-section__description">
                {description}
              </div>
            }
          </div>
        )}
        <div className="form-section__content">
          {children}
        </div>
      </>
    );
  }

  return (
    // eslint-disable-next-line reverb-design-system/prefer-components-to-classname
    (<div className="form-section" id={id}>
      <div className="form-section__wrapper">
        {loading ? loadingState() : loadedContent()}
      </div>
    </div>)
  );
}

export default FormSection;
