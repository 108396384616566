import I18n from 'i18n-js';
import React from 'react';
import classNames from 'classnames';

import { isZeroAmount } from '@reverbdotcom/commons/src/money';
import CoreLink from '@reverbdotcom/commons/src/components/core_link';
import { RCCheckbox } from '@reverbdotcom/cadence/components';
import { SanitizedRender } from '@reverbdotcom/commons';

import { IShippingRate, PriceField } from './edit_shipping_rate_card_manual_prices';
import { getLocalizedRegionName } from '../shipping_profiles/shipping_profile_rate_card';
import URLHelpers from './url_helpers';

export function EditShippingRateCardExpeditedInfo({ isFree }: { isFree?: boolean }) {
  const classes = classNames('shipping-rate-card__regional__info', {
    'shipping-rate-card__regional__info--self-contained': isFree,
  });
  return (
    <div className={classes} id="disallowed-expedited-shipping">
      <div className="shipping-rate-card__regional__info__header">
        {I18n.t('discovery.dashboard.selling.shippingRates.shippingModeRadios.regional.expeditedInfo.title')}
      </div>
      <div>
        <SanitizedRender
          htmlTag="span"
          html={I18n.t('discovery.dashboard.selling.shippingRates.shippingModeRadios.regional.expeditedInfo.helpText', {
            free: isFree ? I18n.t('discovery.dashboard.selling.shippingRates.shippingModeRadios.regional.expeditedInfo.free') : '',
            tagOpen: '<strong>',
            tagClose: '</strong>',
          })}
        />
        <CoreLink
          to={URLHelpers.expeditedShippingHelpPath}
          target="_blank"
          className="shipping-rate-card__inline-link"
        >
          {I18n.t('discovery.dashboard.selling.shippingRates.shippingModeRadios.regional.expeditedInfo.link')}
        </CoreLink>
      </div>
    </div>
  );
}

interface IFreeExpeditedShippingOption {
  allowedToShipExpedited: boolean;
  shippingRate: IShippingRate;
  handleEditShippingRate?: (IShippingRate) => void;
}

export function FreeExpeditedShippingOption({
  allowedToShipExpedited,
  shippingRate,
  handleEditShippingRate,
}: IFreeExpeditedShippingOption) {
  if (!allowedToShipExpedited) {
    if (!shippingRate.isRequired) {
      return null;
    }

    return (
      <EditShippingRateCardExpeditedInfo isFree />
    );
  }

  return (
    <div className="shipping-rate-card__option-container shipping-rate-card__option-container--inline">
      <p>{I18n.t('discovery.dashboard.selling.shippingRates.shippingModeRadios.free.expedited.title')}</p>
      <RCCheckbox
        label={I18n.t('discovery.dashboard.selling.shippingRates.shippingModeRadios.free.expedited.checkbox', { country: getLocalizedRegionName(shippingRate) })}
        name={`free-expedited-checkbox-${shippingRate.regionCode}`}
        id="free-expedited-checkbox"
        onChange={(e) => handleEditShippingRate({
          ...shippingRate,
          [PriceField.EXPEDITED]: e.target.checked ? { amount: '0' } : null,
        })}
        checked={isZeroAmount(shippingRate[PriceField.EXPEDITED]?.amount)}
      />
      <div className="shipping-rate-card__option-container__help-text">
        {I18n.t('discovery.dashboard.selling.shippingRates.shippingModeRadios.free.expedited.helpText')}
        <CoreLink
          to={URLHelpers.expeditedShippingHelpPath}
          className="shipping-rate-card__inline-link"
          target="_blank"
        >
          {I18n.t('discovery.dashboard.selling.shippingRates.shippingModeRadios.free.expedited.link')}
        </CoreLink>
      </div>
    </div>
  );
}
