import { mapValues } from 'lodash';
import template from '@reverbdotcom/url-template';
import { CORE_API_ORIGIN, CORE_WEB_ORIGIN, ECHIDNA_BASE_URL } from '@reverbdotcom/env';
import { IUrl } from '@reverbdotcom/commons/src/url_context';
import { regionalizePath } from '@reverbdotcom/commons/src/url_helpers';
import {
  MY_FAVORITES_FEED_ROUTE_PATH,
  MY_FAVORITES_SEARCHES_ROUTE_PATH,
  MY_FAVORITES_SHOPS_ROUTE_PATH,
  MY_FAVORITES_WATCH_LIST_ENDED_ROUTE_PATH,
  MY_FAVORITES_WATCH_LIST_ROUTE_PATH,
  MY_FAVORITES_LANDING_ROUTE_PATH,
} from './constants';

interface WithID {
  id: string | number;
}

export function withAPIHost(path: string): string {
  return withHost(CORE_API_ORIGIN, path);
}

export function withWebHost(path: string): string {
  return withHost(CORE_WEB_ORIGIN, path);
}

export function withWebHostAndRegion(path: string, region: string): string {
  const regionalizedPath = regionalizePath(path, region);

  return withHost(CORE_WEB_ORIGIN, regionalizedPath);
}

export function withNativeHost(path: string): string {
  return MOBILE_NATIVE_PROTOCOL + path;
}

function withHost(host: string, path: string): string {
  if (host) {
    return host + path;
  }

  return path;
}

export const shopPromotePath = '/page/shop-buttons';
export const myWatchesPath = '/watched_products';
export const watchesPath = template.parse(withAPIHost('/api/watches{?per_page}'));
export const articlesPath = template.parse(withAPIHost('/api/articles{?per_page}'));
export const brandsApiPath = template.parse(withAPIHost('/api/brands{?slugs}'));
export const cspsFindPath = template.parse(withAPIHost('/api/csps/find{?id,slug}'));

export const shopPath = template.parse('/shop/{slug}#');
export const shopFeedbackPath = template.parse('/shop/{slug}/feedback#');
export const shopInfoPath = template.parse('/shop/{slug}/about');
export const adminEditUserPath = template.parse<WithID>('/admin/users/{id}');

export interface MarketplacePathContext {
  curated_set_slug?: string;
  sale_slug?: string;
  landing_page_slug?: string;
  brand_slug?: string;
  shop?: string;
  deals_and_steals?: boolean
}

export interface ItemWithSlugRouteContext {
  id: string | number;
  slug: string;
  bk?: string; // bump key
  rollup?: boolean;
}

export const ITEM_WITH_SLUG_ROUTE = template.parse<ItemWithSlugRouteContext>('/item/{id}-{slug}{?bk,rollup}');
export const SHOP_PAGE = template.parse('/shop{/slug}{?query*}');
export const WEB_DEVICE = 'web';
export const MOBILE_NATIVE_DEVICES = ['ios', 'android'];
export const MOBILE_NATIVE_PROTOCOL = 'reverb-mobile-internal://';

export interface SimilarListingsRouteContext {
  id: string;
  make: string;
  model: string;
}

export function getSimilarListingPageRoute(listingId: string, listingMake: string, listingModel: string) {
  const make = slugify(listingMake);
  const model = slugify(listingModel);

  const temp = template.parse<SimilarListingsRouteContext>('/similar/{id}-{make}-{model}');
  return temp.expand({ id: listingId, make, model });
}

export interface PriceGuidePathContext {
  cspSlug?: string;
}

export const PRICE_GUIDE_ROUTE = 'price-guide(/:cspSlug)';
export const priceGuidePath = template.parse<PriceGuidePathContext>('/price-guide/{cspSlug}');

const TRANSACTION_HISTORY_PATTERN = withAPIHost(
  '/api/csps/{cspId}/transactions{?condition,per_page}',
);

export const TRANSACTION_HISTORY_PATH = template.parse(TRANSACTION_HISTORY_PATTERN);

export function isMobileNativeDevice(deviceName: string) {
  return MOBILE_NATIVE_DEVICES.includes(deviceName);
}

export function slugify(text: string) {
  return text
    .toString()
    .toLowerCase()
    .replace(/\s+/g, '-')
    .replace(/[^\w\-]+/g, '')
    .replace(/\-\-+/g, '-')
    .replace(/^-+/, '')
    .replace(/-+$/, '');
}

export const echidnaUriTemplate = template.parse(`${ECHIDNA_BASE_URL}{/section,slug,id}{?query*}`);

// Usage: `Paths.buyerOffer.expand({ offerId, status })`
export const Paths = mapValues({
  ...mapValues({
    nativeCheckoutSuccess: 'checkout/success{?order_bundle_id,message}',
    nativeCheckoutFailure: 'checkout/failure{?message}',
  }, withNativeHost),

  ...mapValues({
    apiArticleFollow: '/api/my/follows/articles',
    apiArticles: '/api/articles',
    apiArticlesMultiple: '/api/articles/multiple',
    apiArticlesRecentlyFeatured: '/api/articles/recently_featured',
    apiAdminShopDisbursements: '/api/admin/shops/{shopID}/disbursements{?amount,currency}',
    apiBrandCollectionHeader: '/api/brands/{slug}/collection_header',
    apiBrands: '/api/brands/{slug}',
    apiBrandsApi: '/api/brands',
    apiBrandsByName: '/api/brand_find_by_name{?name}',
    apiBuyerFeedback: '/api/shops/{slug}/feedback/buyer{?query*}',
    apiCategoriesFlat: '/api/categories/flat',
    apiCategoriesTree: '/api/categories/taxonomy',
    apiCategoriesWithTraits: '/api/categories/traits',
    apiCategoryCollectionHeader: '/api/categories/{categoryUuidOrSlug}/collection_header',
    apiCategoryCollectionHeaders: '/api/categories/collection_headers{?root_slug,child_slug}',
    // eslint-disable-next-line max-len
    apiCheckoutApplePayLineItems: '/api/checkout/mobile_pay/apple_pay/RCP-{listingCurrency}-apple_pay/line_items{?locality,postal_code,region,country_code}',
    apiCheckoutApplePayPayment: '/api/checkout/mobile_pay/apple_pay/RCP-{listingCurrency}-apple_pay/payments',
    apiCountries: '/api/countries',
    apiCspCategories: '/api/csps/categories',
    apiCspReviewsUrl: '/api/products/{cspSlug}/reviews{?page,per_page}',
    apiCsps: '/api/csps',
    apiCspsEmbed: '/api/comparison_shopping_pages',
    apiCuratedSearchCollectionHeader: '/api/curated_searches/{slug}/collection_header',
    apiCuratedSet: '/api/curated_sets/{slug}',
    apiCuratedSetCollectionHeader: '/api/curated_sets/{slug}/collection_header',
    apiCuratedSets: '/api/curated_sets',
    apiCuratedSetsHomepage: '/api/curated_sets/homepage',
    apiDigitalListing: '/api/digital_listings/{id}',
    apiFeedback: '/api/shops/{slug}/feedback{?query*}',
    apiFeedbackSummary: '/api/shops/{slug}/feedback_summary',
    apiFeedCustomize: '/api/my/feed/customize',
    apiFeedGrid: '/api/my/feed/grid',
    apiLandingPageCollectionHeader: '/api/landing_pages/{slug}/collection_header',
    apiListing: '/api/listings/{id}?skip_product_view_tracking=true',
    apiListingBump: '/api/listings/{listingId}/bump',
    apiListingsCuratedSets: '/api/listings/curated_sets{?ids,curated_set_slugs}',
    apiListingProductReviews: '/api/listings/{listingId}/reviews',
    apiListingsGrid: '/api/listings/grid',
    apiMosaicTiles: '/api/mosaic_tiles/homepage',
    apiMyAddressesCreate: '/api/my/addresses/',
    apiMyAddressesDelete: '/api/my/addresses/{addressId}',
    apiMyAddressesIndex: '/api/my/addresses',
    apiMyAddressesUpdate: '/api/my/addresses/{addressId}',
    apiMyCreditCards: '/api/my/credit_cards{?per_page}',
    apiMyCuratedSetMemberships: '/api/my/curated_set_memberships/{listingId}',
    apiMyCuratedSetProduct: '/api/my/curated_set/{curatedSetId}/product/{listingId}',
    apiMyFollowsSearch: '/api/my/follows/search',
    apiMyCounts: '/api/my/counts',
    apiMyListings: '/api/my/listings',
    apiMyListingsForCuratedSet: '/api/my/listings/curated_sets',
    apiMyShop: '/api/shop',
    apiProductTypeCollectionHeader: '/api/product_types/{slug}/collection_header',
    apiRelatedArticles: '/api/articles/related',
    apiRelatedArticlesToListing: '/api/listings/{listingId}/related_articles',
    apiSellerFeedback: '/api/shops/{slug}/feedback/seller{?query*}',
    apiSetupShopPaymentMethods: '/api/my/shop/setup_payment_methods',
    apiShippingEstimate: '/api/shipping/estimate{?query*}',
    apiShippingRegions: '/api/shipping/regions',
    apiShop: '/api/shops/{slug}{?query*}',
    apiSimilarCSPs: '/api/csps/{strategy}',
    apiUserCuratedSetProduct: '/api/my/curated_set/product/{id}',
    apiUserCuratedSetProducts: '/api/my/curated_set/products',
    cspTransactionsSummary: '/api/csps/{cspSlug}/transactions/summary{?number_of_months,condition}',
  }, withAPIHost),

  accountEdit: '/my/account/settings',
  adminBlockReview: '/admin/product_reviews/{id}/block',
  adminCreateManualOrderRefund: '/admin/orders/{orderId}/create_manual_order_refund',
  adminCreateStatementLineItem: '/admin/statements/{statementId}/line_items',
  adminEditBrand: '/admin/brands/{brandSlug}/edit_from_slug',
  adminEditCsp: '/admin/comparison_shopping_pages/{cspSlug}/edit',
  adminEditCuratedSet: '/admin/curated_sets/{curatedSetSlug}/edit',
  adminEditUniversalPromoCampaign: '/admin/universal_promo_campaigns/{id}/edit',
  adminFlagRoute: '/admin/flags/{listingId}-{listingSlug}?type=Product',
  adminRenameBrand: '/admin/brands/{brandSlug}/rename_from_slug',
  adminRollouts: '/admin/rollouts',
  adminRolloutsCompare: '/admin/rollouts/compare/{?user_id}',
  adminRolloutsMatch: '/admin/rollouts/match/{user_id}',
  adminRolloutEdit: '/admin/rollouts/{name}/edit',
  adminRolloutOptIn: '/admin/rollouts/{name}/opt_in',
  adminRolloutOptOut: '/admin/rollouts/{name}/opt_out',
  adminRolloutGenerateEphemeralOptIn: '/admin/rollouts/{name}/generate_ephemeral_opt_in',
  adminRolloutGenerateEphemeralOptOut: '/admin/rollouts/{name}/generate_ephemeral_opt_out',
  adminRolloutUpdate: '/admin/rollouts/{name}',
  affirmFaq: '/page/affirm-faqs',
  apiCart: '/web_api/cart',
  appleAppStore: 'https://itunes.apple.com/app/reverb.com/id674604876',
  articlesSearch: '/news{?query*}',
  autocomplete: '/autocomplete{?q}',
  brand: '/brand/{brandSlug}{?query*}',
  bumpBidsCreate: '/bump/bids',
  bumpBidsDelete: '/bump/bids/{listingId}',
  bumpClickTracking: '/bump/clicks{?b,listing_id}',
  bumpTracking: '/bump/impressions/track_multiple',
  buyer: '/buyers/{id}',
  buyerOffer: '/my/buying/offers/{offerId}{?status}',
  buyerOffers: '/my/buying/offers{?product_id,status}',
  buyerOrder: '/my/buying/orders/{orderId}{?actions_expanded}{#anchor}',
  buyerOrderAddToGearCollectionAndRedirect: 'my/buying/orders/{orderId}/add_to_gear_collection_and_redirect',
  buyerOrders: '/my/buying/orders{?params*}',
  buyingGuide: '/guide',
  cart: '/cart{?error_checkout_id}',
  checkoutAffirmNew: '/multi-checkout/{checkoutId}/affirm',
  checkoutStart: '/checkout/start{?checkout_id,payment_method}',
  checkoutShow: '/checkout/{checkoutId}{?_reverb_app,show_checkout_error,step,token}',
  checkoutCreateCreditCard: '/checkout/{checkoutId}/cards/new{?selected_card_id}',
  checkoutEditCreditCard: '/checkout/{checkoutId}/cards/{creditCardId}/edit',
  checkoutCreditCardWallet: '/checkout/{checkoutId}/cards{?selected_card_id}',
  checkoutDirectCheckoutNew: '/multi-checkout/{checkoutId}/direct',
  checkoutPayPalNew: '/multi-checkout/{checkoutId}/paypal',
  checkoutPaymentInProgress: '/checkout/{checkoutId}/payment-in-progress{?_reverb_app}',
  checkoutRedirectReturn: '/checkout/{checkoutId}/redirect-return{?_reverb_app,displayed_amount_owed_cents,displayed_amount_owed_currency}',
  checkoutAuthorization: '/checkout/{checkoutId}/authorization{?_reverb_app}',
  checkoutConfirmation: '/multi-checkout/{orderBundleId}/confirmation',
  closeNagAlert: '/nag_alerts/{nagId}/close',
  closeNagAlertAndRedirect: '/new_alerts/{nagId}/close_and_redirect{?redirect_to}',
  crossPlatformMobileStart: '/my/selling/listings/{listingId}/cross_platform_start/v1{?qr_expires_at,token_v2,completion_state}',
  showNagAlertAndRedirect: '/new_alerts/{nagId}/show_and_redirect{?redirect_to}',
  categoryPage: '/c/{categorySlug}/{subcategorySlug}',
  cmsPage: '/page/{pageSlug}',
  contactUs: '/page/contact',
  cookiePolicy: '/page/cookies-policy',
  coreBankAccount: '/my/selling/bank_account',
  coreIdentityVerification: '/my/selling/identity_verification',
  csp: '/p/{cspSlug}',
  cspCollection: '/collection/{curatedSetSlug}',
  cspCondition: '/p/{slug}/{condition}{?filters*}{#anchor}',
  curatedSetAddListings: '/my/selling/curated_sets/{curatedSetId}/add_listings',
  curatedSetRemoveListings: '/my/selling/curated_sets/{curatedSetId}/remove_listings',
  dashboard: '/my/account',
  dashboardSellingPayouts: '/dashboard/selling/payouts',
  directCheckout: '/api/shop/payment_methods/direct_checkout',
  editDirectCheckoutCreditCard: '/multi-checkout/{checkoutId}/direct/edit_credit_card/{creditCardId}',
  editShipment: '/my/selling/shipping_labels/{shipmentId}/edit{?orderId,checkoutUuid,shipmentType}',
  expressSaleSearch: '/trade-in{?query}',
  expressSaleCheckout: '/checkout/RE-{bidId}/trade-in',
  facebookAuth: '/auth/facebook',
  gdprPolicy: '/page/eu-data-transfer-GDPR',
  gearCategories: '/gear-categories',
  gearCollectionCSPSearch: '/my/collection/search{?query}',
  gearCollectionItemFormNew: '/my/collection/new{?csp_id,csp_variant_id,listing_seed_id,order_seed_id,title}',
  gearCollectionItemEdit: '/my/collection/{id}/edit',
  gearCollectionItemShow: '/my/collection/{gearCollectionItemId}',
  gearCollections: '/my/collection{?query*}',
  getPaid: '/item/{listingId}/get_paid{?query*}',
  googlePlayStore: 'https://play.google.com/store/apps/details?id=com.reverb.app',
  handpickedCollections: '/page/handpicked-collections',
  handpickedDeals: '/handpicked/deals{?product_type,sort}',
  helpCenterArticlesUrl: 'https://help.reverb.com/hc/articles/{articleId}{#anchor}',
  helpCenterCategoriesUrl: 'https://help.reverb.com/hc/categories/{categoryId}',
  helpCenterNewRequest: 'https://help.reverb.com/hc/en-us/requests/new',
  holidaySale: '/holiday',
  homepage: '/{?query*}',
  howToSell: '/selling/how-to-sell-on-reverb',
  item: '/item/{id}{slug}{?bk,publish_confirmation}',
  legalPage: '/legal/{pageSlug}',
  legacyPrefilledSellForm: '/sell_prefilled{?seed_id,seed_type,promotional_token}',
  listingsManagerListingResults: '/my/selling/listings/listing_results',
  listingsManagerSidebarFacets: '/my/selling/listings/sidebar_facets',
  listingsManagerUserData: '/my/selling/listings/user_data',
  listingSubmit: '/listing/submit{?query*}',
  marketplace: '/marketplace{?product_type,category,make,query,condition,csp_slug,deals_and_steals}',
  mobileAppsLanding: '/featured/reverb-app',
  myCreditCard: '/api/my/credit_cards/{creditCardId}',
  myCreditCardsCreate: '/api/my/credit_cards/',
  myDirectCheckoutProfile: '/api/shop/payment_methods/direct_checkout',
  myFavorites: MY_FAVORITES_LANDING_ROUTE_PATH, // my/favorites
  myFavoritesWatchList: MY_FAVORITES_WATCH_LIST_ROUTE_PATH, // my/favorites/watch-list
  myFavoritesEnded: MY_FAVORITES_WATCH_LIST_ENDED_ROUTE_PATH, // my/favorites/ended
  myFavoritesSearches: MY_FAVORITES_SEARCHES_ROUTE_PATH, // my/favorites/searches
  myFavoritesShops: MY_FAVORITES_SHOPS_ROUTE_PATH, // my/favorites/shops
  myFavoritesFeed: MY_FAVORITES_FEED_ROUTE_PATH, // my/favorites/feed
  myFeed: '/my/feed',
  myMessages: '/my/messages/',
  myMessage: '/my/messages/{messageId}',
  myOffersOfferCard: '/my/offers/{negotiationId}/offer_card',
  myPaypalProfile: '/api/shop/payment_methods/paypal',
  myRecommendations: '/my/recommendations',
  mySales: 'my/selling/sales',
  newDashboardMessages: '/my/messages/new{?item,recipient_uuid,bk}',
  newFeedback: '/my/feedback/new/{orderId}',
  newsletterSignup: '/email_subscriptions',
  onboarding: '/shop_onboarding/setup',
  onboardingBegin3DS1: '/shop_onboarding/begin_3ds1',
  onboardingCompleteShopDetails: '/shop_onboarding/reverb_payments/complete_shop_details',
  packingSlip: '/my/orders/{orderId}/packing_slip.pdf',
  packlinkRedirect: '/my/selling/packlink_shipping_labels/packlink_redirect',
  passwordReset: '/forgot_password',
  paypalCheckoutReview: '/multi-checkout/{checkoutBundlingId}/paypal/review{?checkout_uuid}',
  personalProfile: '/profile/{slug}',
  personalProfileFollowers: '/profile/{slug}/followers',
  prefilledListingFromCSP: '/my/selling/listings/new{?seed_id,promotional_token}',
  prefilledListingFromCP: '/my/selling/listings/new?seed_id={seed_id}&seed_type=CanonicalProduct',
  prefilledListingFromGCI: '/my/selling/listings/new?seed_id={gci}&seed_type=GearCollectionItem{&promotional_token}',
  prefilledListingFromListing: '/my/selling/listings/new?seed_id={listing}&seed_type=Product{&promotional_token}',
  prefilledListingFromOrder: '/my/selling/listings/new?seed_id={legacyOrderId}&seed_type=Order{&promotional_token}',
  promoDealsAndStealsUK: '/promo/uk-gear-deals',
  promoDealsAndStealsEU: '/promo/european-gear-deals',
  promoDealsAndStealsDefault: '/promo/deals-and-steals',
  promoTheWarehouseDefault: '/promo/the-warehouse',
  promoTheWarehouseEU: '/promo/the-european-warehouse',
  promoTheWarehouseUK: '/promo/the-uk-warehouse',
  pricingUpdate: '/selling/pricing-update',
  priceGuideShow: '/price-guide/{cspSlug}{?query*}',
  priceGuideSearch: '/price-guide{?query*}',
  privacyPolicy: '/page/privacy',
  productReviewGuidelines: '/page/product-review-guidelines',
  productViews: '/product_views/{productId}',
  reverbPaymentsFaq: '/selling/reverb-payment-faq',
  reverbProtection: '/page/reverb-protection',
  reverbMerchStore: '/shop/merch',
  reverbSites: '/page/reverb-sites',
  safeShipping: '/page/safe-shipping',
  sale: '/sales/{saleSlug}',
  sellFormSearch: '/sell/search{?query,promotional_token}',
  sellFormSetup: '/sell-form-setup{?query*}',
  sellFormNewListing: '/my/selling/listings/new{?query*}',
  sellFormEditListing: '/my/selling/listings/{listingId}/edit{?query*}',
  sellerHub: '/page/seller-hub',
  sellerListings: '/my/selling/listings{?params*}',
  sellerOffer: '/my/selling/offers/{negotiationId}',
  sellerOffers: '/my/selling/offers{?productId,status}',
  sellerOrder: '/my/selling/orders/{orderId}{#anchor}',
  sellerOrderTracking: '/my/selling/orders/{orderId}/tracking',
  sellerOrders: '/my/selling/orders',
  sellerProtection: '/selling/reverb-seller-protection',
  sellerStatements: '/my/selling/statements{?query*}',
  sellingFees: '/selling/selling-fees',
  sellingPage: '/selling',
  shipment: '/my/selling/shipping_labels/{shipmentId}{?orderId,checkoutUuid,shipmentType}',
  shippingLabelsNew: '/my/selling/shipping_labels/new/{orderUuid}{?checkoutUuid}',
  shippingGuide: '/page/shipping-guide',
  shippingProtection: '/page/shipping-guide#reverb-shipping-protection',
  shippingRates: '/my/selling/shipping_rates',
  shop: '/shop/{shopSlug}{?query*}',
  shopCampaignIndex: '/my/selling/shop_campaign',
  shopCampaignNew: '/my/selling/shop_campaign/new',
  shopEdit: '/my/shop/edit{#anchor}',
  shopPoliciesEdit: '/my/selling/shop_policies/edit',
  shopFeedback: '/shop/{shopSlug}/feedback',
  shopEditEssentialData: '/my/shop/edit_essential_data{?redirect_to}',
  shopOnboardingReverbPaymentsFinish: '/shop_onboarding/reverb_payments/finish',
  shopOnboardingReverbPaymentsIdentityVerification: '/shop_onboarding/reverb_payments/identity_verification',
  shopOnboardingReverbPaymentsPaymentSettings: '/shop_onboarding/reverb_payments/payment_settings',
  shopOnboardingSetup: '/shop_onboarding/setup{?query*}',
  shopOnboardingShopDetails: '/shop_onboarding/shop_details',
  signup: '/signup{?query*}',
  signin: '/signin{?params*}',
  signout: '/signout',
  software: '/software{/categorySlug}',
  termsOfUse: '/page/terms',
  tradeInTermsOfUse: '/page/trade-in-for-cash-seller-terms',
  unwatchProduct: '/watched_products/{productId}',
  watchProduct: '/watched_products?id={productId}',
  expeditedShippingHelp: '/page/two-day-shipping',
  combinedShippingGuide: '/selling/combined-shipping',
  internationalShippingGuide: '/news/international-shipping-guide',
  categoryTopProducts: '/top/{rootCategory}/{childCategory}',
  defaultShippingGuide: '/selling/shipping-resources',
  guitarShippingGuide: '/selling/how-to-ship-a-guitar',
  keyboardAndSynthShippingGuide: '/selling/how-to-ship-a-keyboard-or-synth',
  effectsAndPedalsShippingGuide: '/selling/how-to-ship-an-effects-pedal',
  drumAndPercussionShippingGuide: '/selling/how-to-ship-a-drum-kit',
  ampShippingGuide: '/selling/how-to-ship-an-amp',
  proAudioShippingGuide: '/selling/how-to-ship-pro-audio-gear-mics-interfaces',
  stereoReceiverShippingGuide: '/selling/how-to-ship-a-stereo-receiver',
  modularGearShippingGuide: '/selling/how-to-ship-modular-gear',
  datingGearGuide: '/news/reverb-dating-and-pricing-hub',
  gearChangeGuide: '/featured/how-your-favorite-gear-changed-over-time',
  vintageFAQGuide: '/page/vintage-guitar-faq',
  sellerVerificationOnboarding: '/seller_verification/onboarding{?query*}',
  tradeInsManagerBuyingPath: '/my/buying/trade_ins{?query*}',
  tradeInManagerBuyingPath: '/my/buying/trade_ins/{tradeInId}',
  listingOptions: '/sell/listing-options{?seed_id,promotional_token}',
}, (v) => template.parse(v));

export function isEndedWatchesPath(url: Partial<IUrl>) {
  const path = url?.pathname || '';
  return !!(
    path.match('\/watched_products(\/shared\/[^\/]+)?\/ended$') ||
    path.includes(Paths.myFavoritesEnded.expand({}))
  );
}

/**
 * @deprecated Add new entries to `Paths` instead.
 */
class DeprecatedHelpers {
  static digitalListingPath(id) {
    return Paths.apiDigitalListing.expand({ id });
  }

  static listingPagePath(id, slug?) {
    slug = slug ? `-${slug}` : '';

    return Paths.item.expand({ id, slug });
  }

  static listingsGridPath() {
    return Paths.apiListingsGrid.expand({});
  }

  static softwarePath(categorySlug?) {
    return Paths.software.expand({ categorySlug });
  }

  static signupPath(query = {}) {
    return Paths.signup.expand({ query });
  }

  static signinPath(params = {}) {
    return Paths.signin.expand({ params });
  }

  static get signoutPath() {
    return Paths.signout.expand({});
  }

  static curatedSetFetchNotePath(listingId) {
    return Paths.apiMyCuratedSetMemberships.expand({ listingId });
  }

  static curatedSetSaveNotePath(curatedSetId, listingId) {
    return Paths.apiMyCuratedSetProduct.expand({ curatedSetId, listingId });
  }

  static listingsManagerPath(params = {}) {
    return Paths.sellerListings.expand({ params });
  }

  static sellerOfferPath(negotiationId) {
    return Paths.sellerOffer.expand({ negotiationId });
  }

  static sellerOffersPath(params = {}) {
    return Paths.sellerOffers.expand(params);
  }

  static ordersManagerPath() {
    return Paths.sellerOrders.expand({});
  }

  static orderPath(params) {
    return Paths.sellerOrder.expand(params);
  }

  static orderTrackingPath(orderId) {
    return Paths.sellerOrderTracking.expand({ orderId });
  }

  static purchaseIndexPath(params = {}) {
    return Paths.buyerOrders.expand({ params });
  }

  static purchasePath(params) {
    return Paths.buyerOrder.expand(params);
  }

  static addPurchaseToGearCollectionsPath(orderId) {
    return Paths.buyerOrderAddToGearCollectionAndRedirect.expand({ orderId });
  }

  static offerPath(params) {
    return Paths.buyerOffer.expand(params);
  }

  static listingOffersPath(params) {
    return Paths.buyerOffers.expand(params);
  }

  static newFeedbackPath(orderId) {
    return Paths.newFeedback.expand({ orderId });
  }

  static packingSlipPath(orderId) {
    return Paths.packingSlip.expand({ orderId });
  }

  static cspCollectionPath(curatedSetSlug) {
    return Paths.cspCollection.expand({ curatedSetSlug });
  }

  static articlesRecentlyFeaturedPath() {
    return Paths.apiArticlesRecentlyFeatured.expand({});
  }

  static articlesMultiplePath() {
    return Paths.apiArticlesMultiple.expand({});
  }

  static shopApiPath(slug, query = {}) {
    return Paths.apiShop.expand({ slug, query });
  }

  static feedbackApiPath(slug, query = {}) {
    return Paths.apiFeedback.expand({ slug, query });
  }

  static buyerFeedbackApiPath(slug, query = {}) {
    return Paths.apiBuyerFeedback.expand({ slug, query });
  }

  static sellerFeedbackApiPath(slug, query = {}) {
    return Paths.apiSellerFeedback.expand({ slug, query });
  }

  static feedbackSummaryApiPath(slug) {
    return Paths.apiFeedbackSummary.expand({ slug });
  }

  static marketplacePath(queryParams = {}) {
    return Paths.marketplace.expand(queryParams);
  }

  static mySellingStatementsPath(query = {}) {
    return Paths.sellerStatements.expand({ query });
  }

  static get myListingsPath() {
    return Paths.apiMyListings.expand({});
  }

  static get sellingShippingLabelsPath() {
    return '/selling/shipping-labels';
  }

  static get sellingSafeShippingInfoPath() {
    return '/selling/reverb-safe-shipping';
  }

  static myListingsForCuratedSet(queryString) {
    return `${Paths.apiMyListingsForCuratedSet.expand({})}${queryString}`;
  }

  static curatedSetAddListings(curatedSetId, queryString) {
    return `${Paths.curatedSetAddListings.expand({ curatedSetId })}${queryString}`;
  }

  static curatedSetRemoveListings(curatedSetId, queryString) {
    return `${Paths.curatedSetRemoveListings.expand({ curatedSetId })}${queryString}`;
  }

  static relatedArticlesToListing(listingId) {
    return Paths.apiRelatedArticlesToListing.expand({ listingId });
  }

  static cspCategories() {
    return Paths.apiCspCategories.expand({});
  }

  static cspsPath() {
    return Paths.apiCsps.expand({});
  }

  // This path is currently blocked by AdBlock. We are going to continue
  // to allow (for now) AdBlock users to block these inline callouts but
  // pivot to /api/csps to evade that block for use outside of the blog
  static cspsEmbedPath() {
    return Paths.apiCspsEmbed.expand({});
  }

  static cspConditionPath(params) {
    return Paths.cspCondition.expand(params);
  }

  static cspPath(cspSlug) {
    return Paths.csp.expand({ cspSlug });
  }

  static categoriesFlatPath() {
    return Paths.apiCategoriesFlat.expand({});
  }

  static categoriesTreePath() {
    return Paths.apiCategoriesTree.expand({});
  }

  static bumpClickTrackingPath(listingId, bumpClickUuid) {
    return Paths.bumpClickTracking.expand({ listing_id: listingId, b: bumpClickUuid });
  }

  static get bumpTrackingPath() {
    return Paths.bumpTracking.expand({});
  }

  static brandCollectionHeaderPath(slug) {
    return Paths.apiBrandCollectionHeader.expand({ slug });
  }

  static brandsPath(slug) {
    return Paths.apiBrands.expand({ slug });
  }

  static brandsByNamePath(name) {
    return Paths.apiBrandsByName.expand({ name });
  }

  static categoryCollectionHeaderPath(categoryUuidOrSlug) {
    return Paths.apiCategoryCollectionHeader.expand({ categoryUuidOrSlug });
  }

  static categoryCollectionHeadersPath(rootSlug, childSlug) {
    return Paths.apiCategoryCollectionHeaders.expand({ root_slug: rootSlug, child_slug: childSlug });
  }

  static curatedSetCollectionHeaderPath(slug) {
    return Paths.apiCuratedSetCollectionHeader.expand({ slug });
  }

  static curatedSetsPath() {
    return Paths.apiCuratedSets.expand({});
  }

  static curatedSetPath(slug) {
    return Paths.apiCuratedSet.expand({ slug });
  }

  static userCuratedSetProductsPath() {
    return Paths.apiUserCuratedSetProducts.expand({});
  }

  static userCuratedSetProductPath(id) {
    return Paths.apiUserCuratedSetProduct.expand({ id });
  }

  static curatedSetsHomepagePath() {
    return Paths.apiCuratedSetsHomepage.expand({});
  }

  static curatedSearchCollectionHeaderPath(slug) {
    return Paths.apiCuratedSearchCollectionHeader.expand({ slug });
  }

  static handpickedDealsPath(product_type, sort) {
    return Paths.handpickedDeals.expand({ product_type, sort });
  }

  static landingPageCollectionHeaderPath(slug) {
    return Paths.apiLandingPageCollectionHeader.expand({ slug });
  }

  static productTypeCollectionHeaderPath(slug) {
    return Paths.apiProductTypeCollectionHeader.expand({ slug });
  }

  static get listingsManagerUserDataPath() {
    return Paths.listingsManagerUserData.expand({});
  }

  static listingPath(id) {
    return Paths.apiListing.expand({ id });
  }

  static get countriesPath() {
    return Paths.apiCountries.expand({});
  }

  static get shippingRegionsPath() {
    return Paths.apiShippingRegions.expand({});
  }

  static get listingsManagerSidebarFacetsPath() {
    return Paths.listingsManagerSidebarFacets.expand({});
  }

  static get listingsManagerListingResultsPath() {
    return Paths.listingsManagerListingResults.expand({});
  }

  static myOffersOfferCard(negotiationId) {
    return Paths.myOffersOfferCard.expand({ negotiationId });
  }

  static get categoriesWithTraitsPath() {
    return Paths.apiCategoriesWithTraits.expand({});
  }

  static listingProductReviewsPath(listingId) {
    return Paths.apiListingProductReviews.expand({ listingId });
  }

  static shipmentPath(params) {
    return Paths.shipment.expand(params);
  }

  static shippingLabelsNewPath({ orderUuid, checkoutUuid }) {
    return Paths.shippingLabelsNew.expand({ orderUuid, checkoutUuid });
  }

  static editShipmentPath(params) {
    return Paths.editShipment.expand(params);
  }

  static get cartPath() {
    return Paths.cart.expand({});
  }

  static get apiCartPath() {
    return Paths.apiCart.expand({});
  }

  static cmsPagePath(pageSlug) {
    return Paths.cmsPage.expand({ pageSlug });
  }

  static legalPagePath(pageSlug) {
    return Paths.legalPage.expand({ pageSlug });
  }

  static crossPlatformMobileStart(listingId, qr_expires_at = null, token_v2 = null, completion_state = null) {
    return Paths.crossPlatformMobileStart.expand({ listingId, qr_expires_at, token_v2, completion_state });
  }

  static categoryPagePath(compoundSlug) {
    const [categorySlug, subcategorySlug] = compoundSlug.split('/');
    return Paths.categoryPage.expand({ categorySlug, subcategorySlug });
  }

  static get shippingProtectionPath() {
    return Paths.shippingProtection.expand({});
  }

  static helpCenterArticlePath(articleId) {
    return Paths.helpCenterArticlesUrl.expand({ articleId });
  }

  static get additionalLabelsHelpPath() {
    return Paths.helpCenterArticlesUrl.expand({ articleId: '223271548-Can-I-print-a-second-shipping-label-for-my-items-sale-' });
  }

  static get sellerRefundFeePath() {
    return Paths.helpCenterArticlesUrl.expand({ articleId: '5766293878163' });
  }

  static get shippingRatesPath() {
    return Paths.shippingRates.expand({});
  }

  static get sellerProtectionPath() {
    return Paths.sellerProtection.expand({});
  }

  static get affirmFaqPath() {
    return Paths.affirmFaq.expand({});
  }

  static get termsOfUsePath() {
    return Paths.termsOfUse.expand({});
  }

  static get tradeInTermsOfUsePath() {
    return Paths.tradeInTermsOfUse.expand({});
  }

  static get privacyPolicyPath() {
    return Paths.privacyPolicy.expand({});
  }

  static get gdprPolicyPath() {
    return Paths.gdprPolicy.expand({});
  }

  static get shippingGuidePath() {
    return Paths.shippingGuide.expand({});
  }

  static get facebookAuthPath() {
    return Paths.facebookAuth.expand({});
  }

  static get passwordResetPath() {
    return Paths.passwordReset.expand({});
  }

  static adminEditCspPath(cspSlug) {
    return Paths.adminEditCsp.expand({ cspSlug });
  }

  static adminBlockReview(id) {
    return Paths.adminBlockReview.expand({ id });
  }

  static get feedGridPath() {
    return Paths.apiFeedGrid.expand({});
  }

  static get feedCustomizePath() {
    return Paths.apiFeedCustomize.expand({});
  }

  static get articleFollowPath() {
    return Paths.apiArticleFollow.expand({});
  }

  static myFollowsSearchPath() {
    return Paths.apiMyFollowsSearch.expand({});
  }

  static myCountsPath() {
    return Paths.apiMyCounts.expand({});
  }

  static newsletterSignup() {
    return Paths.newsletterSignup.expand({});
  }

  static brandPath(brandSlug, params = null) {
    return Paths.brand.expand({ brandSlug, query: params });
  }

  static salePath(saleSlug) {
    return Paths.sale.expand({ saleSlug });
  }

  static get relatedArticlesPath() {
    return Paths.apiRelatedArticles.expand({});
  }

  static get articlesAPIPath() {
    return Paths.apiArticles.expand({});
  }

  static articlesSearchPath(params) {
    return Paths.articlesSearch.expand({ query: params });
  }

  static shopPath(shopSlug) {
    return Paths.shop.expand({ shopSlug });
  }

  static shopCampaignIndexPath() {
    return Paths.shopCampaignIndex.expand({});
  }

  static shopCampaignNewPath() {
    return Paths.shopCampaignNew.expand({});
  }

  static shopFeedbackPath(shopSlug) {
    return Paths.shopFeedback.expand({ shopSlug });
  }

  static get shopPoliciesEditPath() {
    return Paths.shopPoliciesEdit.expand({});
  }

  static closeNagAlertPath(nagId) {
    return Paths.closeNagAlert.expand({ nagId });
  }

  static personalProfilePath(slug) {
    return Paths.personalProfile.expand({ slug });
  }

  static personalProfileFollowersPath(slug) {
    return Paths.personalProfileFollowers.expand({ slug });
  }

  static shopEditPath(params = {}) {
    return Paths.shopEdit.expand(params);
  }

  static get accountEditPath() {
    return Paths.accountEdit.expand({});
  }

  static get appleAppStorePath() {
    return Paths.appleAppStore.expand({});
  }

  static get googlePlayStorePath() {
    return Paths.googlePlayStore.expand({});
  }

  static buyerPath(id) {
    return Paths.buyer.expand({ id });
  }

  static autocompletePath(query) {
    return Paths.autocomplete.expand({ q: query });
  }

  static get myShopPath() {
    return Paths.apiMyShop.expand({});
  }

  static cspReviewsUrl(cspSlug, page, perPage) {
    return Paths.apiCspReviewsUrl.expand({ cspSlug, page, per_page: perPage });
  }

  static get mySalesPath() {
    return Paths.mySales.expand({});
  }

  static myCreditCardsCreatePath() {
    return Paths.myCreditCardsCreate.expand({});
  }

  static myCreditCardPath(creditCardId: string | number) {
    return Paths.myCreditCard.expand({ creditCardId });
  }

  static myCreditCardsPath(params) {
    return Paths.apiMyCreditCards.expand(params);
  }

  static get myDirectCheckoutProfilePath() {
    return Paths.myDirectCheckoutProfile.expand({});
  }

  static myMessagePath(messageId: string | number) {
    return Paths.myMessage.expand({ messageId });
  }

  static myMessagesPath() {
    return Paths.myMessages.expand({});
  }

  static get myPaypalProfilePath() {
    return Paths.myPaypalProfile.expand({});
  }

  static get directCheckoutPath() {
    return Paths.directCheckout.expand({});
  }

  static get contactUsPath() {
    return Paths.contactUs.expand({});
  }

  static get holidaySalePath() {
    return Paths.holidaySale.expand({});
  }

  static get coreIdentityVerificationPath() {
    return Paths.coreIdentityVerification.expand({});
  }

  static editDirectCheckoutCreditCardPath(checkoutId: string, creditCardId: string): string {
    return Paths.editDirectCheckoutCreditCard.expand({ checkoutId, creditCardId });
  }

  static get coreBankAccountPath() {
    return Paths.coreBankAccount.expand({});
  }

  static get shopOnboardingSetupPath() {
    return Paths.shopOnboardingSetup.expand({});
  }

  static get shopOnboardingShopDetailsPath() {
    return Paths.shopOnboardingShopDetails.expand({});
  }

  static get shopOnboardingReverbPaymentsPaymentSettingsPath() {
    return Paths.shopOnboardingReverbPaymentsPaymentSettings.expand({});
  }

  static get shopOnboardingReverbPaymentsIdentityVerificationPath() {
    return Paths.shopOnboardingReverbPaymentsIdentityVerification.expand({});
  }

  static get shopOnboardingReverbPaymentsFinishPath() {
    return Paths.shopOnboardingReverbPaymentsFinish.expand({});
  }

  static get onboardingPath() {
    return Paths.onboarding.expand({});
  }

  static get onboardingCompleteShopDetailsPath() {
    return Paths.onboardingCompleteShopDetails.expand({});
  }

  static get onboardingBegin3DS1() {
    return Paths.onboardingBegin3DS1.expand({});
  }

  static get setupShopPaymentMethodsPath() {
    return Paths.apiSetupShopPaymentMethods.expand({});
  }

  static apiShippingEstimate(query = {}) {
    return Paths.apiShippingEstimate.expand({ query });
  }

  static watchProductPath(productId) {
    return Paths.watchProduct.expand({ productId });
  }

  static unwatchProductPath(productId) {
    return Paths.unwatchProduct.expand({ productId });
  }

  static reverbMerchStorePath() {
    return Paths.reverbMerchStore.expand({});
  }

  static sellFormSearchPath({ query = null, promotionalToken = null } = {}) {
    return Paths.sellFormSearch.expand({ query: query, promotional_token: promotionalToken });
  }

  static expressSaleSearchPath({ query = null } = {}) {
    return Paths.expressSaleSearch.expand({ query });
  }

  static expressSaleCheckoutPath(bidId) {
    return Paths.expressSaleCheckout.expand({ bidId });
  }

  static priceGuideShowPath(cspSlug, query = null) {
    return Paths.priceGuideShow.expand({ cspSlug, query });
  }

  static priceGuideSearchPath(query = null) {
    return Paths.priceGuideSearch.expand({ query });
  }

  static datingGearGuidePath() {
    return Paths.datingGearGuide.expand({});
  }

  static gearChangeGuidePath() {
    return Paths.gearChangeGuide.expand({});
  }

  static vintageFAQGuidePath() {
    return Paths.vintageFAQGuide.expand({});
  }

  static gearCollectionsPath(query = {}) {
    return Paths.gearCollections.expand({ query });
  }

  static gearCollectionItemPath(gearCollectionItemId) {
    return Paths.gearCollectionItemShow.expand({ gearCollectionItemId });
  }

  static gearCollectionCSPSearchPath(query = {}) {
    return Paths.gearCollectionCSPSearch.expand(query);
  }

  static gearCollectionItemFormNewPath({ cspId = null, cspVariantId = null, listingSeedId = null, orderSeedId = null, title = null } = {}) {
    return Paths.gearCollectionItemFormNew.expand({
      csp_id: cspId,
      csp_variant_id: cspVariantId,
      listing_seed_id: listingSeedId,
      order_seed_id: orderSeedId,
      title,
    });
  }

  static gearCollectionItemEditPath(id) {
    return Paths.gearCollectionItemEdit.expand({
      id,
    });
  }

  static get reverbSitesPath() {
    return Paths.reverbSites.expand({});
  }

  static get sellerHub() {
    return Paths.sellerHub.expand({});
  }

  static get dashboardPath() {
    return Paths.dashboard.expand({});
  }

  static prefilledListingFromCSPPath({ cspId, promotionalToken = null }) {
    return Paths.prefilledListingFromCSP.expand({ seed_id: cspId, promotional_token: promotionalToken });
  }

  static listingOptions({ cspId, promotionalToken = null }) {
    return Paths.listingOptions.expand({ seed_id: cspId, promotional_token: promotionalToken });
  }

  static prefilledListingFromCPPath(canonicalProductId) {
    return Paths.prefilledListingFromCP.expand({ seed_id: canonicalProductId });
  }

  static prefilledListingFromGearCollectionItemPath(gciId, promotionalToken = null) {
    return Paths.prefilledListingFromGCI.expand({ gci: gciId, promotional_token: promotionalToken });
  }

  static prefilledListingFromOrderPath(legacyOrderId, promotionalToken = null) {
    return Paths.prefilledListingFromOrder.expand({ legacyOrderId, promotional_token: promotionalToken });
  }

  static prefilledListingFromListing({ listingId, promotionalToken = null }) {
    return Paths.prefilledListingFromListing.expand({ listing: listingId, promotional_token: promotionalToken });
  }

  static get reverbProtectionPath() {
    return Paths.reverbProtection.expand({});
  }

  static get reverbPaymentsFaqPath() {
    return Paths.reverbPaymentsFaq.expand({});
  }

  static newDashboardMessagesPath(listingId, recipientUuid, bumpKey = null) {
    return Paths.newDashboardMessages.expand({ item: listingId, recipient_uuid: recipientUuid, bk: bumpKey });
  }

  static get dashboardSellingPayoutsPath() {
    return Paths.dashboardSellingPayouts.expand({});
  }

  static productViewsPath(productId) {
    return Paths.productViews.expand({ productId });
  }

  static adminFlagRoute(listingId: string, listingSlug: string) {
    return Paths.adminFlagRoute.expand({ listingId, listingSlug });
  }

  /**
   * @deprecated Use {@link withNativeHost} instead
   */
  static mobileNativeCaptureUrl(relativeUrl) {
    return MOBILE_NATIVE_PROTOCOL + relativeUrl;
  }

  static sellFormSetupPath(query = {}) {
    return Paths.sellFormSetup.expand({ query });
  }

  static sellFormNewListingPath(query = {}) {
    return Paths.sellFormNewListing.expand({ query });
  }

  static sellFormEditListingPath(listingId, query = {}) {
    return Paths.sellFormEditListing.expand({ listingId, query });
  }

  static get expeditedShippingHelpPath() {
    return Paths.expeditedShippingHelp.expand({});
  }

  static get combinedShippingGuidePath() {
    return Paths.combinedShippingGuide.expand({});
  }

  static get internationalShippingGuidePath() {
    return Paths.internationalShippingGuide.expand({});
  }

  static get defaultShippingGuidePath() {
    return Paths.defaultShippingGuide.expand({});
  }

  static get guitarShippingGuidePath() {
    return Paths.guitarShippingGuide.expand({});
  }

  static get keyboardAndSynthShippingGuidePath() {
    return Paths.keyboardAndSynthShippingGuide.expand({});
  }

  static get effectsAndPedalsShippingGuidePath() {
    return Paths.effectsAndPedalsShippingGuide.expand({});
  }

  static get drumAndPercussionShippingGuidePath() {
    return Paths.drumAndPercussionShippingGuide.expand({});
  }

  static get ampShippingGuidePath() {
    return Paths.ampShippingGuide.expand({});
  }

  static get proAudioShippingGuidePath() {
    return Paths.proAudioShippingGuide.expand({});
  }

  static get stereoReceiverShippingGuidePath() {
    return Paths.stereoReceiverShippingGuide.expand({});
  }

  static get modularGearShippingGuidePath() {
    return Paths.modularGearShippingGuide.expand({});
  }

  static get productReviewGuidelinesPath() {
    return Paths.productReviewGuidelines.expand({});
  }

  static tradeInsManagerBuyingPath(query = {}) {
    return Paths.tradeInsManagerBuyingPath.expand({ query });
  }

  static tradeInManagerBuyingPath(tradeInId) {
    return Paths.tradeInManagerBuyingPath.expand({ tradeInId });
  }
}

export default class URLHelpers extends DeprecatedHelpers {
  static echidnaListingPage(listingId: string) {
    return echidnaUriTemplate.expand({ section: 'product-matching', slug: 'single-product', id: listingId });
  }
}

export const ADS_ADMIN_AD_ZONES_ROOT = '/admin/ad_zones';
const adZoneEditPath = `${ADS_ADMIN_AD_ZONES_ROOT}/{adZoneUuid}/edit`;
export const ADS_ADMIN_ADVERTISERS_ROOT = '/admin/advertisers';
const advertiserEditPath = `${ADS_ADMIN_ADVERTISERS_ROOT}/{advertiserUuid}/edit`;
const adCampaignsPath = `${ADS_ADMIN_ADVERTISERS_ROOT}/{advertiserUuid}/ad_campaigns`;
const adCampaignEditPath = `${adCampaignsPath}/{adCampaignUuid}/edit`;
const adGroupsPath = `${adCampaignsPath}/{adCampaignUuid}/ad_groups`;
const adGroupEditPath = `${adGroupsPath}/{adGroupUuid}/edit`;
const adsPath = `${adGroupsPath}/{adGroupUuid}/ads`;
const adEditPath = `${adsPath}/{adUuid}/edit`;
export const AD_PREVIEWS_ROOT = '/admin/ad_previews';

export const AdsAdminPaths = mapValues({
  adZonesIndex: `${ADS_ADMIN_AD_ZONES_ROOT}{?offset,limit}`,
  adZoneEdit: adZoneEditPath,
  advertisersIndex: `${ADS_ADMIN_ADVERTISERS_ROOT}{?offset,limit}`,
  advertiserEdit: advertiserEditPath,
  adCampaignsIndex: adCampaignsPath,
  adCampaignEdit: adCampaignEditPath,
  adGroupsIndex: adGroupsPath,
  adGroupEdit: adGroupEditPath,
  adsIndex: adsPath,
  adEdit: adEditPath,
  adPreviews: `${AD_PREVIEWS_ROOT}{?offset,limit,advertiserUuid,status}`,
}, (v) => template.parse(v));
