/* eslint-disable react/require-default-props */
import React from 'react';
import { AdZone, Core_Ad_Banner_WrapperQuery } from '../../gql/graphql';
import { useViewTracking, useIntersectionTracking } from '../../use_tracking';
import CoreLink from '../core_link';
import {
  buildAdImpressionData,
  buildAdClickData,
  buildAdObservedData,
} from './ad_mparticle_tracking_helpers';
import AdTag from './ad_tag';

const COMPONENT_NAME = 'AdBanner';

type Ad = Core_Ad_Banner_WrapperQuery['adServe']['ad'];

interface Props {
  ad: Ad,
  adZones?: AdZone[],
}

interface IContentBlockProps {
  // text content
  title?: string;
  titleColor?: string;
  subtitle?: string;
  subtitleColor?: string;
  // call to action
  ctaText?: string;
  ctaTextColor?: string;
  ctaButtonColor?: string;
  trackingRef: React.Dispatch<any>;
}

function ContentBlock(props: IContentBlockProps) {
  return (
    <div className="amp-banner__content" ref={props.trackingRef}>
      <div className="amp-banner__titleblock">
        <div
          className="amp-banner__title"
          style={{ color: props.titleColor }}
        >
          {props.title}
        </div>
        <div
          className="amp-banner__subtitle"
          style={{ color: props.subtitleColor }}
        >
          {props.subtitle}
        </div>
      </div>
      {props.ctaText &&
        <CallToAction
          ctaText={props.ctaText}
          ctaTextColor={props.ctaTextColor}
          ctaButtonColor={props.ctaButtonColor}
        />
      }
    </div>
  );
}

interface ICTAProps {
  // call to action
  ctaText?: string;
  ctaTextColor?: string;
  ctaButtonColor?: string;
}

function CallToAction(props: ICTAProps) {
  return (
    <span
      className="amp-banner__button"
      style={{
        backgroundColor: props.ctaButtonColor,
        color: props.ctaTextColor,
      }}
    >
      {props.ctaText}
    </span>
  );
}

// AdBanner VS amp-banner namespacing differences are to help circumvent ad blockers
export function AdBanner(props: Props) {
  const intersectionTrackingRef = useIntersectionTracking(
    buildAdObservedData(
      props.ad,
      COMPONENT_NAME,
      props.adZones,
    ),
    true,
  );

  useViewTracking(
    buildAdImpressionData(
      props.ad,
      COMPONENT_NAME,
      props.adZones,
    ),
    true,
  );

  const { imageAdCreative } = props.ad;

  return (
    <CoreLink
      className="amp-banner"
      to={imageAdCreative.ctaHref}
      data-ad-banner
      clickEvent={
        buildAdClickData(
          props.ad,
          COMPONENT_NAME,
          props.adZones,
        )
      }
    >
      <div
        className="amp-banner__background amp-banner__background--desktop"
        style={{ backgroundImage: `url(${imageAdCreative.backgroundImage})` }}
      />
      <div
        className="amp-banner__background amp-banner__background--mobile"
        style={{ backgroundImage: `url(${imageAdCreative.mobileBackgroundImage})` }}
      />
      <ContentBlock
        title={imageAdCreative.title}
        titleColor={imageAdCreative.titleColor}
        subtitle={imageAdCreative.subtitle}
        subtitleColor={imageAdCreative.subtitleColor}
        ctaText={imageAdCreative.ctaText}
        ctaTextColor={imageAdCreative.ctaTextColor}
        ctaButtonColor={imageAdCreative.ctaButtonColor}
        trackingRef={intersectionTrackingRef}
      />
      {
        !imageAdCreative.hideAdCallout &&
        <div className="amp-banner__tag">
          <AdTag />
        </div>
      }
    </CoreLink>
  );
}
