export default {
  banner: {
    auTaxTitle: 'Reverb began assessing GST on fees for sellers in Australia on 6 September.',
    ukTaxTitle: 'Reverb began assessing VAT on fees for sellers in the UK on 6 September.',
    auAndUkContent: "Add your {{children}} to help ensure you're not charged.",
    euAddTaxId: 'Have a {{children}}? Please add it to the Tax Policies section of your account.',
    buttonText: 'Submit my tax info',
    VAT: 'VAT ID',
    GST: 'GST ID',
    learnMore: 'Learn more',
    caTaxTitlePreLaunch: 'Reverb will begin assessing tax on fees for sellers in Canada on 12 August 2024.',
    caTaxTitle: 'Reverb began assessing tax on fees for sellers in Canada on 12 August 2024.',
    caTaxContent: 'You can learn more about this tax policy in our Help Center.',
  },
};
