import React, { useEffect, useState } from 'react';
import I18n from 'i18n-js';
import qs from 'qs';
import { omitBy, isNil } from 'lodash';
import { useUser } from '@reverbdotcom/commons/src/user_hooks';
import window from '@reverbdotcom/commons/src/window_wrapper';
import { Paths } from '@reverbdotcom/commons/src/url_helpers';
import { updateGoogleConsent, IPrivacyConsentSettings } from '@reverbdotcom/commons/src/google';
import { MParticleEventName, trackEvent } from '@reverbdotcom/commons/src/elog/mparticle_tracker';
import { V3 } from './api_request';
import UserPrivacyConsentPopup from './user_privacy_consent_popup';
import UserPrivacySettingModal from './user_privacy_setting_modal';
import Location from '../lib/wrapped_location';
import * as elog from '@reverbdotcom/commons/src/elog';

interface IProps {
  isFooterLink?: boolean;
}

const COMPONENT_NAME = 'UserPrivacyConsentContainer';

export function showUserPrivacyConsent(user) {
  const requireThirdPartyPrivacyConsentBanner = user.requireThirdPartyPrivacyConsent;

  return requireThirdPartyPrivacyConsentBanner;
}

export function submitRequest(privacyConsentSettings: IPrivacyConsentSettings, submitSource) {
  const mparticleId = window.mParticle.Identity.getCurrentUser?.()?.getMPID();

  if (!mparticleId) {
    elog.info('privacy_consent.mpid_not_returned', {});
  }

  const {
    adData,
    adStorage,
    adPersonalization,
    analyticsStorage,
  } = privacyConsentSettings;
  const { gt } = qs.parse(Location.search, { ignoreQueryPrefix: true });
  const params = omitBy({
    third_party_ad_data_consent: adData,
    third_party_ad_storage_consent: adStorage,
    third_party_ad_personalization_consent: adPersonalization,
    third_party_analytics_storage_consent: analyticsStorage,
    mparticle_id: mparticleId,
    gt: gt,
  }, isNil);

  V3.post(Paths.privacySettingsUpdate.expand({}),
    params,
  ).then(() => {
    updateGoogleConsent({
      'adData': adData,
      'adStorage': adStorage,
      'adPersonalization': adPersonalization,
      'analyticsStorage': analyticsStorage,
    });

    trackEvent({
      eventName: MParticleEventName.ClickedPrivacySettingConsentButton,
      componentName: COMPONENT_NAME,
      source: submitSource,
      adDataConsent: adData,
      adStorageConsent: adStorage,
      adPersonalizationConsent: adPersonalization,
      analyticsStorageConsent: analyticsStorage,
    });

    Location.assign(Location.href);
  });
}

export default function UserPrivacyConsentContainer({ isFooterLink = false }: IProps) {
  const user = useUser();
  const [mparticleReady, setMparticleReady] = useState(false);

  useEffect(() => {
    if (!user.loggedOut) {
      return setMparticleReady(true);
    }

    if (window.mParticle) {
      window.mParticle.ready(() => {
        setMparticleReady(true);
      });
    } else {
      elog.info('privacy_consent.mparticle_sdk_undefined', { cookie_id: user.cookieId, session_id: user.sessionId });
    }
  }, [window.mParticle]);

  const [privacyConsentSettings, setPrivacyConsentSettings] = React.useState({
    adData: isFooterLink ? user.thirdPartyAdDataAllowed : true,
    adStorage: isFooterLink ? user.thirdPartyAdStorageAllowed : true,
    adPersonalization: isFooterLink ? user.thirdPartyAdPersonalizationAllowed : true,
    analyticsStorage: isFooterLink ? user.thirdPartyAnalyticsStorageAllowed : true,
  } as IPrivacyConsentSettings);
  const [isUserPrivacyConsentPopupOpen, setUserPrivacyConsentPopupOpen] = React.useState(!isFooterLink);
  const [isUserPrivacySettingModalOpen, setUserPrivacySettingModalOpen] = React.useState(false);
  const openPrivacySettingModal = React.useCallback(() => {
    setUserPrivacySettingModalOpen(true);

    trackEvent({
      eventName: MParticleEventName.OpenedPrivacySettingsModal,
      componentName: COMPONENT_NAME,
    });
  }, []);
  const toggleUserConsentModals = React.useCallback(() => {
    setUserPrivacyConsentPopupOpen(false);
    openPrivacySettingModal();
  }, [openPrivacySettingModal]);
  const updatePrivacySettings = React.useCallback((e) => {
    setPrivacyConsentSettings({
      ...privacyConsentSettings,
      [e.target.id]: e.target.checked,
    } as IPrivacyConsentSettings);
  }, [privacyConsentSettings]);
  const onSubmit = () => {
    submitRequest(
      privacyConsentSettings,
      'Update Privacy Settings',
    );
  };
  const onAcceptAllSubmit = () => {
    submitRequest(
      privacyConsentSettings,
      'Accept All',
    );
  };

  if (isFooterLink) {
    return (
      <button
        type="button"
        className="site-footer-v2__link"
        onClick={openPrivacySettingModal}
      >
        <UserPrivacySettingModal
          onSubmit={onSubmit}
          isAdDataChecked={privacyConsentSettings.adData}
          isAdStorageChecked={privacyConsentSettings.adStorage}
          isAdPersonalizationChecked={privacyConsentSettings.adPersonalization}
          isAnalyticsStorageChecked={privacyConsentSettings.analyticsStorage}
          updatePrivacySettings={updatePrivacySettings}
          onClose={() => setUserPrivacySettingModalOpen(false)}
          isOpen={isUserPrivacySettingModalOpen}
        />
        {I18n.t('discovery.privacySettingsModal.privacySettings.title')}
      </button>
    );
  }

  return (
    <>
      {mparticleReady && (
        <>
          <UserPrivacyConsentPopup
            isOpen={isUserPrivacyConsentPopupOpen}
            onClose={toggleUserConsentModals}
            onSubmit={onAcceptAllSubmit}
          />
        </>
      )}
      <UserPrivacySettingModal
        isAdDataChecked={privacyConsentSettings.adData}
        isAdStorageChecked={privacyConsentSettings.adStorage}
        isAdPersonalizationChecked={privacyConsentSettings.adPersonalization}
        isAnalyticsStorageChecked={privacyConsentSettings.analyticsStorage}
        updatePrivacySettings={updatePrivacySettings}
        onSubmit={onSubmit}
        isOpen={isUserPrivacySettingModalOpen}
        allowBackgroundScroll
      />
    </>
  );
}
