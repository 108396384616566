import { shuffle } from 'lodash';

export const popularSearches = [
  'Gibson',
  'Telecaster',
  'Fender Stratocaster',
  'Charvel',
  'Jazzmaster',
  'Kiesel',
  'Gibson SG',
  'P.R.S',
  'Gibson Les Paul',
  'Ibanez',
  'ESP',
  'Fender Telecaster',
  'Suhr',
  'Roland',
  'B.C. Rich',
  'Korg',
  'Gretsch',
  'Rickenbacker',
  'Gibson Les Paul Custom',
  'Marshall',
  'Fender Jazzmaster',
  'Tokai',
  'Fender Precision Bass',
  'Yamaha',
];

export const popularSearchSampling = (count = popularSearches.length) => {
  return shuffle(popularSearches).splice(0, count);
};
