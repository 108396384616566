import React from 'react';
import classNames from 'classnames';
import FormGroup from '@reverbdotcom/commons/src/components/form_group';
import { CSEName } from '@reverbdotcom/commons/src/adyen_card_hook';
import { CbObjOnError as CardError, CbObjOnFocus as CardFocus } from '@adyen/adyen-web/dist/types/components/internal/SecuredFields/lib/types';

interface Props {
  label: string;
  expiryMonthInputName: string;
  expiryYearInputName: string;
  paymentMethod: ExpiryPaymentMethod;
  focus: Partial<CardFocus>;
  errors: Partial<Record<CSEName, CardError>>;
}

interface ExpiryPaymentMethod {
  encryptedExpiryMonth?: string;
  encryptedExpiryYear?: string;
}

const CSE_NAME: CSEName = 'encryptedExpiryDate';

export default function CreditCardEncryptedDateInput(props: Props) {
  const hasFocus = props.focus?.fieldType === CSE_NAME;
  const errorText = props.errors?.encryptedExpiryDate?.errorI18n;

  const classes = classNames(
    'adyen-field', {
      'adyen-field--focused': hasFocus,
      'adyen-field--error': !!errorText,
    },
  );

  return (
    <FormGroup
      label={props.label}
      errorText={errorText}
    >
      <input name={props.expiryMonthInputName} value={props.paymentMethod?.encryptedExpiryMonth || ''} type="hidden" />
      <input name={props.expiryYearInputName} value={props.paymentMethod?.encryptedExpiryYear || ''} type="hidden" />
      <div className={classes} data-cse={CSE_NAME} />
    </FormGroup>
  );
}
