import I18n from 'i18n-js';
import React from 'react';
import classNames from 'classnames';

import { RCButton, RCTextWithIcon } from '@reverbdotcom/cadence/components';
import { parseAmount } from '@reverbdotcom/commons/src/money';
import { TrashIcon, PencilIcon, PlusCircleIcon, InfoCircleIcon } from '@reverbdotcom/cadence/icons/react';
import { RegionalRateType, IRegionalRate } from './edit_shipping_rate_card_regional_prices';
import { CA, US_CON } from '@reverbdotcom/commons/src/constants';

export interface IRegionalRateGroup {
  regionalRates: IRegionalRate[];
  rateType: RegionalRateType;
  countryCode: string;
  handleModalOpen: () => void;
  handleEditRateGroup: (RegionalRateType, IRegionalRate) => void;
  handleDeleteRateGroup: (RegionalRateType, IRegionalRate) => void;
  isFree?: boolean;
  allowCreateButton?: boolean;
  disabled?: boolean;
}

export interface IRegionalRateGroupActive {
  regionalRate: IRegionalRate;
  rateType: RegionalRateType;
  countryCode: string;
  handleModalOpen: () => void;
  handleEditRateGroup: (RegionalRateType, IRegionalRate) => void;
  handleDeleteRateGroup: (RegionalRateType, IRegionalRate) => void;
  isFree?: boolean;
  showCreateButton?: boolean;
  disabled?: boolean;
}

function HiddenInputFieldsForRates({
  regionalRate,
  rateType,
}: {
  regionalRate: IRegionalRate;
  rateType: RegionalRateType;
}) {
  if (!regionalRate) { return null; }

  const inputName = rateType === RegionalRateType.EXPEDITED ? 'regional_expedited_rates' : 'regional_rates';

  return (
    <>
      <input
        type="hidden"
        value={regionalRate.areas.join(',')}
        name={`shipping_profile[shipping][rates][][${inputName}][][areas]`}
      />
      <input
        type="hidden"
        value={regionalRate.rate.amount}
        name={`shipping_profile[shipping][rates][][${inputName}][][rate][amount]`}
      />
    </>
  );
}

function RegionalRateGroupActive({
  regionalRate,
  rateType,
  countryCode,
  handleModalOpen,
  handleEditRateGroup,
  handleDeleteRateGroup,
  isFree = false,
  showCreateButton = true,
  disabled = false,
}: IRegionalRateGroupActive) {
  const showDefaultIsFreeMessage = isFree && disabled;

  function rateGroupLabelText() {
    const price = !isFree && (regionalRate.rate.display || parseAmount(regionalRate.rate.amount, regionalRate.rate.currency).display);

    switch (rateType) {
      case RegionalRateType.EXPEDITED:
        if (isFree) {
          return I18n.t('discovery.dashboard.selling.shippingRates.shippingModeRadios.regional.options.free.expedited.selected');
        } else {
          return I18n.t('discovery.dashboard.selling.shippingRates.shippingModeRadios.regional.options.paid.expedited.selected', { price: price });
        }
      default:
        if (isFree) {
          return I18n.t('discovery.dashboard.selling.shippingRates.shippingModeRadios.regional.options.free.standard.selected');
        } else {
          return I18n.t('discovery.dashboard.selling.shippingRates.shippingModeRadios.regional.options.paid.standard.selected', { price: price });
        }
    }
  }

  function defaultIsFreeMessageText() {
    switch (countryCode) {
      case CA:
        return I18n.t('discovery.dashboard.selling.shippingRates.shippingModeRadios.regional.options.free.CA.defaultIsFree');
      default:
        return I18n.t('discovery.dashboard.selling.shippingRates.shippingModeRadios.regional.options.free.US_CON.defaultIsFree');
    }
  }

  return (
    <>
      <div className="shipping-rate-card__regional__rate-group__body">
        <div className="shipping-rate-card__regional__rate-group__label">
          <span>
            {rateGroupLabelText()}
          </span>
          <div>
            {showDefaultIsFreeMessage && defaultIsFreeMessageText()}

            {!showDefaultIsFreeMessage && (
              regionalRate.areas.map((areaCode) => I18n.t(`discovery.regionalShippingAreas.${countryCode}.${areaCode}`)).sort().join(', ')
            )}
          </div>
        </div>
        <div className="shipping-rate-card__regional__rate-group__button-group">
          <RCButton
            variant="muted"
            onClick={() => handleEditRateGroup(rateType, regionalRate)}
            id={`edit-${rateType}-${regionalRate.rate.amountCents}-regional-rate-group-button`}
            disabled={disabled}
          >
            <RCTextWithIcon svgComponent={PencilIcon} placement="left">
              {I18n.t('discovery.dashboard.selling.shippingRates.shippingModeRadios.regional.rateGroup.edit')}
            </RCTextWithIcon>
          </RCButton>
          <RCButton
            variant="muted"
            onClick={() => handleDeleteRateGroup(rateType, regionalRate)}
            id={`delete-${rateType}-${regionalRate.rate.amountCents}-regional-rate-group-button`}
            disabled={disabled}
          >
            <RCTextWithIcon svgComponent={TrashIcon} placement="left">
              {I18n.t('discovery.dashboard.selling.shippingRates.shippingModeRadios.regional.rateGroup.delete')}
            </RCTextWithIcon>
          </RCButton>

          {!showDefaultIsFreeMessage && (
            <HiddenInputFieldsForRates regionalRate={regionalRate} rateType={rateType} />
          )}

          {showDefaultIsFreeMessage && (
            <div className="shipping-rate-card__regional__rate-group--default-free-help-text">
              <RCTextWithIcon svgComponent={InfoCircleIcon} placement="left">
                {I18n.t('discovery.dashboard.selling.shippingRates.shippingModeRadios.regional.options.free.defaultIsFreeButtonHelpText')}
              </RCTextWithIcon>
            </div>
          )}
        </div>
      </div>
      {!isFree &&
        <hr className="shipping-rate-card__regional__separator" />
      }
      {showCreateButton && (
        <div className="shipping-rate-card__regional__rate-group__footer">
          <RCButton
            onClick={handleModalOpen}
            id={`add-${rateType}-regional-rate-group-button`}
            disabled={disabled}
          >
            <RCTextWithIcon svgComponent={PlusCircleIcon} placement="left">
              {I18n.t('discovery.dashboard.selling.shippingRates.shippingModeRadios.regional.rateGroup.add')}
            </RCTextWithIcon>
          </RCButton>
        </div>
      )}
    </>
  );
}

function RegionalRateGroup({
  regionalRates,
  rateType,
  countryCode,
  handleModalOpen,
  handleEditRateGroup,
  handleDeleteRateGroup,
  isFree = false,
  allowCreateButton = true,
  disabled = false,
}: IRegionalRateGroup) {
  const showDefaultIsFreeMessage = isFree && disabled;

  function AddRateGroupButton() {
    return (
      <RCButton
        id={isFree ? `add-free-${rateType}-regional-rate-group-button` : `add-${rateType}-regional-rate-group-button`}
        onClick={handleModalOpen}
        disabled={disabled}
      >
        {isFree && countryCode == US_CON && I18n.t('discovery.dashboard.selling.shippingRates.shippingModeRadios.regional.options.free.US_CON.button')}
        {isFree && countryCode == CA && I18n.t('discovery.dashboard.selling.shippingRates.shippingModeRadios.regional.options.free.CA.button')}
        {!isFree && countryCode == US_CON && I18n.t('discovery.dashboard.selling.shippingRates.shippingModeRadios.regional.options.paid.US_CON.button')}
        {!isFree && countryCode == CA && I18n.t('discovery.dashboard.selling.shippingRates.shippingModeRadios.regional.options.paid.CA.button')}
      </RCButton>
    );
  }

  if (regionalRates.length) {
    return (
      <div className="shipping-rate-card__regional__rate-group">
        {regionalRates.map((rr, i) => (
          <RegionalRateGroupActive
            regionalRate={rr}
            rateType={rateType}
            handleDeleteRateGroup={handleDeleteRateGroup}
            handleEditRateGroup={handleEditRateGroup}
            handleModalOpen={handleModalOpen}
            countryCode={countryCode}
            showCreateButton={allowCreateButton && !isFree && i === regionalRates.length - 1}
            isFree={isFree}
            key={rr.rate.amount}
            disabled={disabled}
          />
        ))}
      </div>
    );
  }

  const className = classNames(
    'shipping-rate-card__regional__rate-group',
    'shipping-rate-card__regional__rate-group--new',
    { 'shipping-rate-card__regional__rate-group--default-free': isFree && disabled },
  );

  return (
    <div className={className}>
      {showDefaultIsFreeMessage ? (
        <div>
          <span>
            <strong>
              {rateType == RegionalRateType.STANDARD && I18n.t('discovery.dashboard.selling.shippingRates.shippingModeRadios.regional.options.free.standard.selected')}
              {rateType == RegionalRateType.EXPEDITED && I18n.t('discovery.dashboard.selling.shippingRates.shippingModeRadios.regional.options.free.expedited.selected')}
            </strong>
          </span>
          <div>
            {countryCode == US_CON && I18n.t('discovery.dashboard.selling.shippingRates.shippingModeRadios.regional.options.free.US_CON.defaultIsFree')}
            {countryCode == CA && I18n.t('discovery.dashboard.selling.shippingRates.shippingModeRadios.regional.options.free.CA.defaultIsFree')}
          </div>
        </div>
      ) : (
        <span>
          {isFree && rateType == RegionalRateType.STANDARD && I18n.t('discovery.dashboard.selling.shippingRates.shippingModeRadios.regional.options.free.standard.new')}
          {isFree && rateType == RegionalRateType.EXPEDITED && I18n.t('discovery.dashboard.selling.shippingRates.shippingModeRadios.regional.options.free.expedited.new')}
          {!isFree && rateType == RegionalRateType.STANDARD && I18n.t('discovery.dashboard.selling.shippingRates.shippingModeRadios.regional.options.paid.standard.new')}
          {!isFree && rateType == RegionalRateType.EXPEDITED && I18n.t('discovery.dashboard.selling.shippingRates.shippingModeRadios.regional.options.paid.expedited.new')}
        </span>
      )}

      {showDefaultIsFreeMessage ? (
        <div className="mobile-width-100">
          <AddRateGroupButton/>
          <div className="shipping-rate-card__regional__rate-group--default-free-help-text">
            <RCTextWithIcon svgComponent={InfoCircleIcon} placement="left">
              {I18n.t('discovery.dashboard.selling.shippingRates.shippingModeRadios.regional.options.free.defaultIsFreeButtonHelpText')}
            </RCTextWithIcon>
          </div>
        </div>
      ) : (
        <AddRateGroupButton/>
      )}
    </div>
  );
}

export default RegionalRateGroup;
