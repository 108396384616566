import React from 'react';
import I18n from 'i18n-js';

import { formatAmountCents, isEmptyMoneyResponse, IMoneyConfig } from '../money';
import { PriceDisplay } from './PriceDisplay';

interface IMoney {
  amountCents?: number;
  currency?: string;
  display?: string;
}

interface IProps {
  moneyLow: IMoney;
  moneyHigh: IMoney;
  showFree?: boolean;
  auction?: boolean;
  displayConfig?: IMoneyConfig;
}

export default function PriceDisplayRange(props: IProps) {
  const {
    moneyLow,
    moneyHigh,
    showFree,
    displayConfig,
  } = props;

  if (isEmptyMoneyResponse(moneyLow) || isEmptyMoneyResponse(moneyHigh)) {
    return null;
  }

  function display(money: IMoney) {
    const hasDisplayConfig = !!displayConfig;
    const hasDisplayValue = !!money.display;

    if (hasDisplayValue && !hasDisplayConfig) return money.display;

    return formatAmountCents(money, displayConfig);
  }

  const lowEqualsHigh =
    moneyLow.amountCents === moneyHigh.amountCents &&
    moneyLow.currency === moneyHigh.currency;

  if (lowEqualsHigh) {
    return (
      <PriceDisplay
        amountCents={moneyLow.amountCents}
        display={display(moneyLow)}
      />
    );
  }

  return (
    <div className="price-display-range">
      <PriceDisplay
        amountCents={moneyLow.amountCents}
        display={display(moneyLow)}
        showFree={showFree}
      />
      <span>{I18n.t('commons.priceDisplayRangeSeparator')}</span>
      <PriceDisplay
        amountCents={moneyHigh.amountCents}
        display={display(moneyHigh)}
        showFree={showFree}
      />
    </div>
  );
}
