import React from 'react';
import I18n from 'i18n-js';

import { Listing } from '@reverbdotcom/commons/src/gql/graphql';
import { RCChip, RCMenu, useMediaQuery } from '@reverbdotcom/cadence/components';
import BulkCreateAffirmModal from './BulkCreateAffirmModal';
import BulkDeleteAffirmModal from './BulkDeleteAffirmModal';
import { useViewTracking } from '@reverbdotcom/commons/src/use_tracking';
import { MParticleEventName } from '@reverbdotcom/commons/src/elog/mparticle_types';

export const COMPONENT_NAME = 'BulkAffirmFinancingButton';

interface IExternalProps {
  selectedListings: Listing[];
}

export enum BulkAffirmModals {
  CREATE = 'create',
  DELETE = 'delete',
  NONE = 'none',
}

export default function BulkAffirmFinancingButton({
  selectedListings,
}: IExternalProps) {
  const hasSelectedListings = !!selectedListings.length;
  const isMobile = useMediaQuery('mobile');
  const chipSize = isMobile ? 'small' : 'mini';

  const [
    openModal,
    setOpenModal,
  ] = React.useState<BulkAffirmModals>(BulkAffirmModals.NONE);

  useViewTracking({
    componentName: COMPONENT_NAME,
    eventName: MParticleEventName.ComponentView,
  }, true);

  return (
    <>
      <RCMenu
        anchor={
          <RCChip
            size={chipSize}
            onClick={() => {

            }}
          >
            {I18n.t('discovery.listingsManagement.toolbar.bulkActions.affirm.chipText')}
          </RCChip>
        }
      >
        <RCMenu.Item
          key={I18n.t('discovery.listingsManagement.toolbar.bulkActions.affirm.enableAffirm')}
          disabled={!hasSelectedListings}
          onClick={() => setOpenModal(BulkAffirmModals.CREATE)}
        >
          {I18n.t('discovery.listingsManagement.toolbar.bulkActions.affirm.enableAffirm')}
        </RCMenu.Item>

        <RCMenu.Item
          key={I18n.t('discovery.listingsManagement.toolbar.bulkActions.affirm.disableAffirm')}
          disabled={!hasSelectedListings}
          onClick={() => setOpenModal(BulkAffirmModals.DELETE)}
        >
          {I18n.t('discovery.listingsManagement.toolbar.bulkActions.affirm.disableAffirm')}
        </RCMenu.Item>
      </RCMenu>

      {openModal == BulkAffirmModals.CREATE && (
        <BulkCreateAffirmModal
          closeModal={() => setOpenModal(BulkAffirmModals.NONE)}
          selectedListings={selectedListings}
        />
      )}

      {openModal == BulkAffirmModals.DELETE && (
        <BulkDeleteAffirmModal
          closeModal={() => setOpenModal(BulkAffirmModals.NONE)}
          selectedListings={selectedListings}
        />
      )}
    </>
  );
}
