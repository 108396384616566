import { ChildProps } from '@apollo/client/react/hoc';
import { MutationFunction } from '@reverbdotcom/commons/src/useHOCMutation';
// TODO update this to import from commons
// import { gql } from '@reverbdotcom/commons/src/gql';
// eslint-disable-next-line no-restricted-imports
import { gql } from '@apollo/client';
import React, { useEffect } from 'react';
import track from 'react-tracking';
import { withGraphql } from '@reverbdotcom/commons/src/with_graphql';
import { trackEvent, LISTING_TYPE } from '@reverbdotcom/commons/src/elog/mparticle_tracker';
import {
  CombinedMarketplaceBumpTrackingMutation,
  CombinedMarketplaceBumpTrackingMutationVariables,
  Core_Marketplace_CombinedMarketplaceSearchQuery,
  Core_Marketplace_CombinedMarketplaceSearchQueryVariables,
  core_apimessages_Ad,
} from '@reverbdotcom/commons/src/gql/graphql';
import { MParticleEventName } from '@reverbdotcom/commons/src/elog/mparticle_types';
import * as elog from '@reverbdotcom/commons/src/elog';
import { connect } from '@reverbdotcom/commons/src/connect';
import { ListingGrid, ListingProps } from '../listing_grid';
import ErrorDisplay from '../discovery/error_display';
import { useUser } from '@reverbdotcom/commons/src/user_hooks';
import InterspersedListings from './interspersed_listings';

const ITEMS_TO_DISPLAY = 10;
const COMPONENT_NAME = 'CombinedMarketplaceGrid';
interface CombinedBumpProps {
  error?: Error;
  bumpedListings?: Core_Marketplace_CombinedMarketplaceSearchQuery['bumpedSearch']['listings'];
  ad?: core_apimessages_Ad;
  showOnlySold?: boolean;
  includeItemListMetadata?: boolean;
}

type CombinedBumpListingProps = ChildProps<
  ListingProps & CombinedBumpProps
>;

interface MutationProps {
  mutate: MutationFunction<CombinedMarketplaceBumpTrackingMutation, CombinedMarketplaceBumpTrackingMutationVariables>;
}

type CombinedBumpListingAndQueryProps = ChildProps<
  CombinedBumpListingProps & MutationProps,
  Core_Marketplace_CombinedMarketplaceSearchQuery,
  Core_Marketplace_CombinedMarketplaceSearchQueryVariables
>;

export function CombinedBumpListingGrid(props: CombinedBumpListingAndQueryProps) {
  const user = useUser();
  const shouldDisplayBumps = !props.loading || !props.error || !!props.bumpedListings.length;
  const bumpedListingIds = props.bumpedListings.map(l => l.id);

  useEffect(() => {
    if (shouldDisplayBumps) {
      props.mutate({
        variables: {
          input: {
            listingIds: bumpedListingIds,
          },
        },
      });

      trackEvent({
        eventName: MParticleEventName.ListingImpression,
        listingIds: bumpedListingIds,
        listings: props.listings,
        componentName: COMPONENT_NAME,
      });
    }
  }, [bumpedListingIds, props.mutate, props.listings, shouldDisplayBumps]);

  if (props.error) {
    // Since this listing connect to graphql we want to trigger the errors in sentry
    elog.error('listing-grid-error', { ...props }, props.error);
    return <ErrorDisplay />;
  }

  return (
    <ListingGrid
      loading={props.loading}
      listings={props.listings}
      error={!!props.error}
      isListView={props.isListView}
      filters={props.filters}
      exposedSidebarOpen={props.exposedSidebarOpen}
      toggleExposedSidebarOpen={props.toggleExposedSidebarOpen}
      listingSearchTotal={props.listingSearchTotal}
      clearFiltersDisabled={props.clearFiltersDisabled}
      clearFilters={props.clearFilters}
      itemsToDisplay={ITEMS_TO_DISPLAY}
      emptyGrid={props.emptyGrid}
      trackingName={props.trackingName}
      trackingQuery={props.trackingQuery}
      trackingFilter={props.trackingFilter}
      trackingSort={props.trackingSort}
      trackingPage={props.trackingPage}
      pageSettings={props.pageSettings}
      includeItemListMetadata={props.includeItemListMetadata}
      gridItems={
        <InterspersedListings
          user={user}
          listings={props.listings}
          bumpedListings={props.bumpedListings}
          showOnlySold={props.showOnlySold}
          trackingQuery={props.trackingQuery}
          trackingFilter={props.trackingFilter}
          trackingSort={props.trackingSort}
          trackingPage={props.trackingPage}
          ad={props.ad}
          pageSettings={props.pageSettings}
          displayAsRows={props.isListView}
        />
      } />
  );
}

export const BUMP_TRACKING_MUTATION = gql`
  mutation CombinedMarketplaceBumpTracking($input: Input_arbiter_BulkTrackImpressionsRequest) {
    bulkTrackImpressions(input: $input) {
      listingIds
    }
  }
`;

const withMutation = withGraphql<ListingProps, CombinedMarketplaceBumpTrackingMutation, CombinedMarketplaceBumpTrackingMutationVariables>(
  BUMP_TRACKING_MUTATION,
);

export default connect<CombinedBumpListingProps>([
  withMutation,
  track({
    componentName: COMPONENT_NAME,
    type: LISTING_TYPE,
  }),
])(CombinedBumpListingGrid);
