import React from 'react';
import I18n from 'i18n-js';
import { V3 } from '../../api_request';
import ReverbHeaderUserBadge from './reverb_header_user_badge';
import ReverbHeaderUserNotification from './reverb_header_user_notification';
import HeaderDropdown from '@reverbdotcom/commons/src/components/header_dropdown';
import { RCIcon } from '@reverbdotcom/cadence/components';
import { BellOIcon } from '@reverbdotcom/cadence/icons/react';
import URLHelpers from '../../shared/url_helpers';
import { MParticleEventName, trackEvent } from '@reverbdotcom/commons/src/elog/mparticle_tracker';
import { formatExperimentsForEventAttributes } from '@reverbdotcom/commons/src/elog/mparticle';
import experiments from '@reverbdotcom/commons/src/experiments';
import { useSeller } from '@reverbdotcom/commons/src/user_hooks';

export const CACHED_COUNTS_LOCAL_STORAGE_KEY = 'cachedCounts';
export const BADGE_KEYS = [
  'active_offers',
  'unread_messages',
  'unpaid_orders',
  'seller_orders_refund_requested',
  'seller_orders_awaiting_shipment',
  'seller_orders_awaiting_pickup',
  'buyer_orders_awaiting_pickup',
  'invoiced_statement_count',
  'billing_profile',
  'layaway_orders',
  'outstanding_feedback',
];

const USER_NOTIFICATIONS_KEY = 'user_notifications';
const HAS_ABOVE_AVERAGE_PRICED_LISTINGS = 'HAS_ABOVE_AVERAGE_PRICED_LISTINGS';

export const PERSIST_BADGE_KEYS = new Set([
  'unread_messages',
  'active_offers',
]);

interface IProps {
  activeDropdownKey: string | null;
  setActiveDropdownKey: (string) => void;
}

interface INotification {
  count: number;
  display_text: string;
  badge_links?: {
    web: {
      href: string;
    }
  }
  _links?: {
    web: {
      href: string;
    }
  }
}

interface ICounts {
  active_cart_item_total: INotification;
  active_offers: INotification;
  billing_profile: INotification;
  buyer_active_offers: INotification;
  invoiced_statement_count: INotification;
  layaway_orders: INotification;
  outstanding_feedback: INotification;
  seller_active_offers: INotification;
  seller_orders_awaiting_shipment: INotification;
  seller_orders_refund_requested: INotification;
  unpaid_orders: INotification;
  unread_messages: INotification;
  seller_orders_awaiting_pickup: INotification;
  buyer_orders_awaiting_pickup: INotification;
  user_notifications: {
    description: string;
    key: string;
    notification_links: {
      web: {
        href: string;
      }
    }
    notification_type: string;
    title: string;
  }[];
  total: {
    count: number;
  }
}

function loadCachedCountsFromLocalStorage() {
  const cachedCounts = localStorage.getItem(CACHED_COUNTS_LOCAL_STORAGE_KEY);
  if (cachedCounts) {
    try {
      return JSON.parse(cachedCounts);
    } catch (error) {
      console.warn(error);
      localStorage.removeItem(CACHED_COUNTS_LOCAL_STORAGE_KEY);
    }
  }
  return null;
}

function loadSeenHasAboveAvgPricedListingsFromLocalStorage() {
  const contents = localStorage.getItem(HAS_ABOVE_AVERAGE_PRICED_LISTINGS);
  if (contents) {
    try {
      return contents == '1';
    } catch (error) {
      localStorage.removeItem(HAS_ABOVE_AVERAGE_PRICED_LISTINGS);
    }
  }
  return null;
}

function getTotalCountAttr(counts) {
  const count = counts?.total?.count;
  return count || null;
}

function badgeCountsDidLoad(result, setCounts, user) {
  // https://sentry.io/organizations/reverb-llc/issues/1266760988/?project=1270493&query=is%3Aunresolved&sort=freq&statsPeriod=14d
  try {
    localStorage.setItem(CACHED_COUNTS_LOCAL_STORAGE_KEY, JSON.stringify(result));
    setCounts(result);

    // To ensure QE volume parity between control and experiment groups, only fire it the first time a seller would
    // qualify to see the notification. This way the control group doesn't continually fire the QE on every page load
    // for as long as they're eligible, and the experiment group doesn't continually fire the QE until they dismiss
    // the notification.
    const hasSeenAboveAvgPricedListingsNag = loadSeenHasAboveAvgPricedListingsFromLocalStorage();
    if (!hasSeenAboveAvgPricedListingsNag && hasAboveAverageListingPriceNag(result)) {
      trackEvent({
        eventName: MParticleEventName.AboveAvgPricedListingsNagQualifyingEvent,
        sellerId: user.sellerId,
        shopId: user.shopId,
        secondaryUserModeIsActive: user.secondaryUserModeIsActive,
        secondaryUserModeIsAvailable: user.secondaryUserModeIsAvailable,
        experiments: formatExperimentsForEventAttributes(user, [experiments.SHOW_HAS_ABOVE_AVERAGE_PRICED_LISTINGS_NAG]),
      });
      localStorage.setItem(HAS_ABOVE_AVERAGE_PRICED_LISTINGS, '1');
    }
  } catch (e) {
    localStorage.removeItem(CACHED_COUNTS_LOCAL_STORAGE_KEY);
    console.error(e);
  }
}

function hasUserNotifications(counts) {
  if (!counts) { return false; }
  const notifications = counts[USER_NOTIFICATIONS_KEY];
  return !!notifications && notifications?.length > 0;
}

function renderUserNotifications(counts) {
  if (!hasUserNotifications(counts)) { return null; }

  return counts[USER_NOTIFICATIONS_KEY].map(notification => (
    <ReverbHeaderUserNotification
      key={notification.key}
      data={notification}
    />
  ));
}

function hasAboveAverageListingPriceNag(counts) {
  if (!hasUserNotifications(counts)) { return false; }
  return !!counts[USER_NOTIFICATIONS_KEY].find(notification => notification.key == HAS_ABOVE_AVERAGE_PRICED_LISTINGS);
}

function shouldPersistBadge(key) {
  return PERSIST_BADGE_KEYS.has(key);
}

function ReverbHeaderUserBadges({ activeDropdownKey, setActiveDropdownKey }: IProps) {
  const user = useSeller();
  const [counts, setCounts] = React.useState<ICounts | null>();
  const [isOpen, setIsOpen] = React.useState<boolean>(false);

  React.useEffect(() => {
    setCounts(loadCachedCountsFromLocalStorage());

    V3.get(URLHelpers.myCountsPath()).then(result => badgeCountsDidLoad(result, setCounts, user));
  }, [setCounts]);

  function handleOnOpen(key) {
    setActiveDropdownKey(key);

    if (!isOpen) {
      setIsOpen(true);
      trackEvent({
        componentName: USER_NOTIFICATIONS_KEY,
        eventName: MParticleEventName.ComponentView,
      });
    }
  }

  const totalNotificationCount = getTotalCountAttr(counts);

  const trigger = (
    <span
      className="reverb-header__nav__link reverb-header__nav__link--notification-icon"
    >
      <div
        className="reverb-header__nav__link__icon"
        data-notification-count={totalNotificationCount}
      >
        <RCIcon svgComponent={BellOIcon} />
      </div>
      <div className="reverb-header__nav__link__label">
        {I18n.t('discovery.reverbHeader.notifications')}
      </div>
      <span className="reverb-header__count-badge">
        {totalNotificationCount > 0 && I18n.t('discovery.reverbHeader.notificationCount', { count: totalNotificationCount })}
      </span>
    </span>
  );

  if (!counts) {
    return trigger;
  }

  return (
    <HeaderDropdown
      trigger={trigger}
      contents={(
        <ul className="reverb-header__menu__notifications">
          {renderUserNotifications(counts)}
          {counts && BADGE_KEYS.map(key => (
            <ReverbHeaderUserBadge
              key={key}
              rel={`${key}_badge`}
              countRecord={counts[key]}
              persist={shouldPersistBadge(key)}
            />
          ))}
        </ul>
      )}
      dropdownKey="notifications"
      onOpen={key => handleOnOpen(key)}
      onClose={() => setIsOpen(false)}
      activeDropdownKey={activeDropdownKey}
    />
  );
}

export default ReverbHeaderUserBadges;
