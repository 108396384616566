import React from 'react';
import I18n from 'i18n-js';
import qs from 'qs';
import { CORE_WEB_ORIGIN, DISCO_OAUTH_CLIENT_ID } from '@reverbdotcom/env';
import { PASSWORD_RESET_PATH } from '../url_helpers';
import bind from '../bind';
import CsrfForm from '../csrf_form';
import { isDevSPA } from '../test_env_helper';
import ThirdPartyAuthButtons from './third_party_auth_buttons';
import { PasswordInput } from './PasswordInput';
import { AuthWrapper } from './AuthWrapper';

import {
  RCCheckbox,
  RCEmailInput,
} from '@reverbdotcom/cadence/components';

interface Props {
  redirectTo?: string;
  shouldRenderHeader?: boolean;
  variant: 'default' | 'continue';
  prioritizeOauth?: boolean;
  switchToSignup?: () => void;
}

interface IState {
  rememberMe: boolean;
  email: string;
}

/** Auth URL for disco sandbox mode. Not used in production reverb-ui builds */
export const devOauthRoute = () => {
  return `${CORE_WEB_ORIGIN}/oauth/authorize?client_id=${DISCO_OAUTH_CLIENT_ID}&redirect_uri=${window.location.origin}/oauth_success&response_type=token&scope=read_listings+read_lists+write_lists+read_profile+write_profile+write_offers+read_offers+read_orders+read_addresses+write_addresses+unrestricted`;
};

export default class SigninForm extends React.Component<Props, IState> {
  static defaultProps: Partial<Props> = {
    shouldRenderHeader: true,
  };

  state = {
    rememberMe: true,
    email: '',
  };

  @bind
  handleSubmit(e) {
    const rememberMeElem = e.target.elements['user_session[remember_me]'];
    rememberMeElem.value = this.state.rememberMe; // coerce this into a boolean
  }

  authUrlParams() {
    return qs.stringify({
      redirect_to: this.props.redirectTo,
    });
  }

  renderHeader() {
    if (!this.props.shouldRenderHeader) { return null; }

    return (
      <h4 className="signup-signin__header">
        {I18n.t('commons.signupForm.signinHeader')}
      </h4>
    );
  }

  loginButton() {
    if (isDevSPA(window.location.origin)) {
      return (
        <a className="button button--primary width-100" href={devOauthRoute()}>
          {I18n.t('commons.signupForm.logIn')}
        </a>
      );
    }
    return (
      <input type="submit" value={I18n.t('commons.signupForm.logIn')} className="button button--primary width-100" />
    );
  }

  render() {
    return (
      <AuthWrapper
        header={this.props.shouldRenderHeader &&
          <h4 className="signup-signin__header">
            {I18n.t('commons.signupForm.signinHeader')}
          </h4>
        }
        oauthButtons={
          <ThirdPartyAuthButtons
            redirectTo={this.props.redirectTo}
            variant={this.props.variant === 'continue' ? 'continue' : 'sign-in'}
          />
        }
        authForm={
          <CsrfForm
            action={`/authenticate?${this.authUrlParams()}`}
            method="post"
            onSubmit={this.handleSubmit}
            data-disable-auto-validation="true" // block forms.js handling
          >
            <RCEmailInput
              label={I18n.t('commons.signupForm.emailLabel')}
              id="signin--login"
              name="user_session[login]"
              value={this.state.email}
              onChange={(evt) => this.setState({ email: evt.target.value })}
              required
            />
            <PasswordInput
              autoComplete="current-password"
              id="signin--password"
              label={I18n.t('commons.signinForm.passwordLabel')}
              name="user_session[password]"
              required
            />
            <div className="scaling-mtb-4">
              {this.loginButton()}
            </div>
            <RememberMeAlignment center={this.props.prioritizeOauth}>
              <RCCheckbox
                label={I18n.t('commons.signupForm.rememberMe')}
                id="signin--remember_me"
                name="user_session[remember_me]"
                onChange={() =>
                  this.setState((prevState) => ({ rememberMe: !prevState.rememberMe }))
                }
                checked={this.state.rememberMe}
              />
            </RememberMeAlignment>
            <p className="size-90 mt-2 align-center">
              {I18n.t('commons.signupForm.forgotPassword')}
              {' '}
              <a
                href={PASSWORD_RESET_PATH}
                target="_blank"
                rel="noopener noreferrer"
              >
                {I18n.t('commons.signupForm.passwordReset')}
              </a>
            </p>
            {this.props.prioritizeOauth &&
              <p className="size-100 mt-2 align-center">
                {I18n.t('commons.signupForm.createAccount')}
                {' '}
                <button
                  onClick={this.props.switchToSignup}
                  className="button-as-link"
                  type="button"
                >
                  {I18n.t('commons.signupForm.signUpLowercase')}
                </button>
              </p>
            }
          </CsrfForm>
        }
        prioritizeOauth={this.props.prioritizeOauth}
      />
    );
  }
}

function RememberMeAlignment(props) {
  if (props.center) {
    return (
      <div className="d-flex fx-dir-col fx-align-center mt-2">
        <div className="fx-justify-evenly">
          {props.children}
        </div>
      </div>
    );
  } else {
    return (
      <>
        {props.children}
      </>
    );
  }
}
