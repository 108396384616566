import { times } from 'lodash';

export function generateDropdownYears() {
  const currentYear = new Date().getFullYear();
  const years = times(20, (year) => (currentYear + year).toString());

  return years;
}

export const MONTHS = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'];

