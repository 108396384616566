const POLL_RATE = 1000; // milliseconds
const POLL_TIMEOUT = 10000; // milliseconds; time until we redirect regardless of risk state

class PollConfirmation {
  constructor(config) {
    this.redirectUrl = config.redirectUrl;
    this.pollUrl = config.pollUrl;

    this.window = config.window || window; // injection for testing
  }

  start() {
    this.window.setTimeout(() => this.poll(), POLL_RATE);
    this.window.setTimeout(() => this.redirect(), POLL_TIMEOUT);
  }

  poll() {
    $.get(this.pollUrl).done((data) => {
      if (data.complete) {
        this.redirect();
      } else {
        this.window.setTimeout(() => this.poll(), POLL_RATE);
      }
    }).fail(() => this.redirect());
  }

  redirect() {
    this.window.location = this.redirectUrl;
  }
}

export default PollConfirmation;
