/* eslint max-len: "off" */
import React from 'react';
import PropTypes from 'prop-types';
import { UserStore } from '@reverbdotcom/discovery-ui';
import { MobileAppAd } from '@reverbdotcom/commons/src/components/mobile_app_ad';

// This is a wrapper so Core can consume this in a legacy way.
// Please use <MobileAppAds /> instead if you can.
function MobileAppAds(props) {
  return (
    <div className="scaling-mtb-8">
      <MobileAppAd
        title={I18n.t('js.mobileAppAds.headingExp2')}
        subtitle={I18n.t('js.mobileAppAds.subheadingExp2')}
        appleStoreLink={`${props.iosAppUrl}?pt=2069466&ct=checkout-app-install-ad&mt=8`}
        googleStoreLink={`${props.androidAppUrl}&utm_source=reverb_website&utm_campaign=checkout_app_download&utm_medium=website_banner&utm_content=checkout-ad`}
        locale={UserStore.getState().locale || 'en'}
      />
    </div>
  );
}

MobileAppAds.propTypes = {
  iosAppUrl: PropTypes.string.isRequired,
  androidAppUrl: PropTypes.string.isRequired,
};

export default MobileAppAds;
