import { trackTimings, alt } from '@reverbdotcom/discovery-ui';
import { configureMParticle } from '@reverbdotcom/commons/src/elog/mparticle_tracker';
import { frontendCurrentUser, parseMeta } from '@reverbdotcom/discovery-ui/src/components/parse_meta';
import { datadogRum } from '@datadog/browser-rum-slim';
import {
  CORE_API_ORIGIN,
  CORE_WEB_ORIGIN,
  DEPLOY_ENV,
  ENABLE_RUM,
  REVISION,
  RQL_BASE_URL,
  GQL_GATEWAY_BASE_URL,
} from '@reverbdotcom/env';

import I18n from 'i18n-js';
import moment from 'moment';

import 'moment/locale/de';
import 'moment/locale/en-au';
import 'moment/locale/en-ca';
import 'moment/locale/en-gb';
import 'moment/locale/en-ie';
import 'moment/locale/en-il';
import 'moment/locale/en-nz';
import 'moment/locale/es';
import 'moment/locale/es-us';
import 'moment/locale/fr';
import 'moment/locale/fr-ca';
import 'moment/locale/ja';
import { experimentInt } from '@reverbdotcom/commons/src/user_context_helpers';
import { setScrollbarWidthVars } from '@reverbdotcom/cadence/components/helpers/setScrollbarWidthVars';

let didInit = false;

const AppInitializer = {
  init() {
    if (didInit) { return; }

    this.initializeAltStores();
    this.initializeLocale();
    this.fixFacebookHash();
    this.configureMparticle();
    this.configureRum();
    trackTimings();
    setScrollbarWidthVars();

    didInit = true;
  },

  fixFacebookHash() {
    // Facebook adds a strange hash to our url when we log in, which
    // can break tab navigation. This removes it.
    if (window.location.hash === '#_=_') window.location.hash = '';
  },

  initializeLocale() {
    const userConfig = frontendCurrentUser();
    const translations = parseMeta('js-translations');

    if (userConfig) {
      I18n.locale = userConfig.user.fiveDigitLocale || I18n.defaultLocale;

      moment.locale(I18n.locale);

      if (translations) {
        if (I18n.locale.includes('-') && !I18n.locale.includes(I18n.defaultLocale)) {
          const [fallbackLocale] = I18n.locale.split('-');
          I18n.translations[fallbackLocale] = translations[fallbackLocale];
        }

        I18n.translations[I18n.locale] = translations[I18n.locale];
      }
    }
  },

  initializeAltStores() {
    const stores = document.getElementById('alt-bootstrap');
    // This value is duplicated in two places, so let's share it
    const currentUser = frontendCurrentUser();

    if (stores && currentUser) {
      const parsedStores = JSON.parse(stores.textContent);

      alt.bootstrap(JSON.stringify({
        ...parsedStores,
        UserStore: currentUser.user,
      }));
    }
  },

  configureMparticle() {
    // FIXME: consolidate "enable mparticle" envvars
    if (DEPLOY_ENV === 'test') return;

    const userConfig = frontendCurrentUser();
    const appConfig = parseMeta('app-config-meta');

    if (userConfig && appConfig) {
      configureMParticle(userConfig.user, appConfig.config.inverseReverseExchangeRates);
    }
  },

  experimentTag(user, experimentKey) {
    return `${experimentKey}:${experimentInt(user, experimentKey)}`;
  },

  configureRum() {
    const userConfig = frontendCurrentUser();
    const user = userConfig && userConfig.user;
    const enableRum = ENABLE_RUM &&
      user &&
      !user.isBot;

    if (!enableRum) { return; }

    // Strip off /graphql suffix
    const rqlOrigin = new URL(RQL_BASE_URL).origin;
    const allowedTracingOrigins = [
      CORE_API_ORIGIN,
      CORE_WEB_ORIGIN,
      rqlOrigin,
    ];

    try {
      const gatewayOrigin = new URL(GQL_GATEWAY_BASE_URL).origin;
      allowedTracingOrigins.push(gatewayOrigin);
    } catch (e) {
      // ignore
    }

    datadogRum.init({
      applicationId: 'f985bfcb-6302-45da-b082-152b5d85d60f',
      clientToken: 'pub3937220e6abf34f0ce851dc03e31b7e1',
      site: 'datadoghq.com',
      service: 'core-web-ui',
      sampleRate: 10, // 0-100%
      premiumSampleRate: 0,
      env: DEPLOY_ENV,
      version: REVISION,
      trackInteractions: false,
      allowedTracingOrigins,
    });

    datadogRum.setUser({
      id: user.id,
      admin: user.isAdmin,
      experiments: [],
    });
  },
};

export default AppInitializer;
