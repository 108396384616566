import React from 'react';
import I18n from 'i18n-js';

import { RCSwitch } from '@reverbdotcom/cadence/components';
import { DIRECT_OFFER_HELP_CENTER_ARTICLE_ID } from './listings/auto_offer_modal';
import { useHelpCenterArticleUrl } from '@reverbdotcom/commons/src/url_helpers';
import SanitizedRender from '@reverbdotcom/commons/src/components/sanitized_render';

export const OFFER_HELP_CENTER_ARTICLE_ID = '360033103134';

export interface IProps {
  receiveMatchedCPDirectOffers: boolean;
}

export function OffersPreferences(props: IProps) {
  const [receiveMatchedCPDirectOffers, setReceiveMatchedCPDirectOffers] = React.useState(props.receiveMatchedCPDirectOffers);

  const offerHelpCenterLink = useHelpCenterArticleUrl(OFFER_HELP_CENTER_ARTICLE_ID);
  const directOfferHelpCenterLink = useHelpCenterArticleUrl(DIRECT_OFFER_HELP_CENTER_ARTICLE_ID);

  return (
    <div className="max-width-50 tablet-max-width-70 mobile-max-width-100">
      <h2 className="cms-h1">
        {I18n.t('discovery.offersPreferences.title')}
      </h2>
      <div className="bdt-1 bd-primary">
        <h3 className="cms-h2">
          {I18n.t('discovery.offersPreferences.directOffersFromSellers')}
        </h3>
        <p className="color-secondary size-90">
          <SanitizedRender
            html={I18n.t(
              'discovery.offersPreferences.directOffersFromSellersDescription',
              {
                offerLinkOpen: `<a href="${offerHelpCenterLink}" class="text-link">`,
                directOfferLinkOpen: `<a href="${directOfferHelpCenterLink}" class="text-link">`,
                linkClose: '</a>',
              },
            )}
          />
        </p>
        <div className="scaling-mb-8 scaling-mt-4">
          <RCSwitch
            checked={receiveMatchedCPDirectOffers}
            label={I18n.t('discovery.offersPreferences.receiveOffersForSimilarItemsLabel')}
            sublabel={I18n.t('discovery.offersPreferences.receiveOffersForSimilarItemsSublabel')}
            onChange={() => setReceiveMatchedCPDirectOffers(!receiveMatchedCPDirectOffers) }
          />
        </div>
        <input
          name="offers_preferences[receive_matched_cp_direct_offers]"
          type="hidden"
          value={receiveMatchedCPDirectOffers ? '1' : '0'}
        />
      </div>
    </div>
  );
}

export default OffersPreferences;
