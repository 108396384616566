import React from 'react';
import { RCPasswordInput, RCPasswordAutocomplete } from '@reverbdotcom/cadence/components';

interface Props {
  autoComplete?: RCPasswordAutocomplete;
  helpText?: string;
  id: string;
  label: string;
  name: string;
  required: boolean;
}

export function PasswordInput({
  autoComplete = undefined,
  helpText = undefined,
  id,
  label,
  name,
  required,
}: Props) {

  const [password, setPassword] = React.useState('');

  function onChange(e: React.ChangeEvent<HTMLInputElement>) {
    setPassword(e.target.value);
  }

  return (
    <RCPasswordInput
      autoComplete={autoComplete}
      helpText={helpText}
      id={id}
      label={label}
      name={name}
      onChange={onChange}
      required={required}
      value={password}
    />
  );
}
