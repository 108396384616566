import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { RCAccordion } from '@reverbdotcom/cadence/components';
import { withRouter, WithRouterProps } from 'react-router';
import SearchInputGroup from '../cms/search_input_group';
import { withUpdateURL, IUpdateURLProps } from './with_update_url';
import { Filter, filterID, FilterProps } from './search_filters';
import { MParticleEventName } from '../elog/mparticle_types';
import { trackEvent } from '../elog/mparticle_tracker';

interface IExternalProps {
  hideLabel?: boolean;
  placeholder?: string;
}

function getValue(filter: Filter) {
  return filter.options[0].setValues[0];
}

type IProps = IExternalProps & FilterProps & WithRouterProps & IUpdateURLProps;

const FULL_TEXT_QUERY = 'full_text_query';
const QUERY = 'query';

export function TextInputFacet(props: IProps) {
  const { filter, collapsible, hideLabel, pageSection, placeholder, location, defaultOpen = false } = props;
  const id = `${pageSection}--${filterID(filter)}`;
  const classes = classNames('facet', { 'facet--collapsible': collapsible });
  const [text, setText] = useState(getValue(filter));

  useEffect(() => {
    const newValue = getValue(filter);
    if (newValue !== getValue(filter)) {
      setText(newValue);
    }
  }, [filter]);

  function handleSubmit(evt, value) {
    if (evt) evt.preventDefault();

    const { paramName } = filter.options[0];
    const filteredParamName = paramName === FULL_TEXT_QUERY ? QUERY : paramName;

    const newUrl = props.updateUrl({
      location: location,
      newParams: { [filteredParamName]: value },
    });

    trackEvent({
      componentName: 'TextInputFacet',
      eventName: MParticleEventName.ClickedSearchFilter,
      displayStyle: value,
      selected: true,
      parent: paramName,
      query: value,
    });

    props.router.push(newUrl);
  }

  function handleClear() {
    setText('');
    handleSubmit(null, '');
  }

  function handleChange(evt) {
    setText(evt.target.value);
  }

  const searchInput = <SearchInputGroup
    onSubmit={(evt) => handleSubmit(evt, text)}
    onChange={handleChange}
    onClear={handleClear}
    value={text}
    id={id}
    placeholder={placeholder}
  />;

  return (
    <div className={classes}>
      {!hideLabel && !collapsible && (
        <label
          htmlFor={id}
          className="facet__heading"
        >
          <h3>
            {filter.name}
          </h3>
        </label>
      )}
      {collapsible && (
        <RCAccordion
          id={id}
          heading={filter.name}
          defaultOpen={defaultOpen || !!text}
          onToggle={props.onToggleCallback}
        >
          <div className="pt-2">
            {searchInput}
          </div>
        </RCAccordion>
      )}
      {!collapsible && searchInput}
    </div>
  );
}


export default withRouter<FilterProps & IExternalProps>(withUpdateURL(TextInputFacet));
