import React from 'react';
import { Paths } from './shared/url_helpers';

import { withGraphql } from '@reverbdotcom/commons/src/with_graphql';
import {
  CoreCheckoutSelectCreditCard,
  CheckoutTrackingFields,
  core_apimessages_CheckoutPaymentMethod_Type as PaymentMethodType,
} from '@reverbdotcom/commons/src/gql/graphql';

import ModalDialog from '@reverbdotcom/commons/src/components/modal_dialog';
import { WithRouterProps } from 'react-router';
import { RCAlertBox } from '@reverbdotcom/cadence/components';
import { CreditCardFormContext, useCreditCardFormState } from './credit_card_form_context';
import {
  CHECKOUT_SELECT_CREDIT_CARD_MUTATION,
  SelectCheckoutCreditCardMutationFn,
  useSelectCheckoutCreditCard,
} from './checkout_select_credit_card_hook';
import {
  buildModalState,
  ModalPage,
} from './credit_card_wallet_modal_state';
import { useTrackCheckoutClickEvent } from './checkout/checkoutTracking';
import { CreditCardWalletClickInteraction } from '@reverbdotcom/commons/src/elog/mparticle_types';

interface ExternalProps {
  children: React.ReactNode;
}

interface MutationProps {
  selectCheckoutCreditCard: SelectCheckoutCreditCardMutationFn;
}

type Checkout = CheckoutTrackingFields.Fragment;

type Props = MutationProps & WithRouterProps & ExternalProps;

const formId = 'credit-card-modal-form';

export function CreditCardWalletModal(props: Props) {
  const { query } = props.location;
  const selectedCardId = query.selected_card_id || props.params.creditCardId;

  const checkoutData: Checkout = {
    id: props.params.id,
    paymentMethod: {
      type: PaymentMethodType.DIRECT_CHECKOUT,
    },
  };

  const checkoutClickEvent = useTrackCheckoutClickEvent(checkoutData);

  const modalPaths = buildCheckoutModalPaths(
    props.params.id,
    selectedCardId,
  );

  const modalState = buildModalState({
    modalPaths,
    router: props.router,
  });

  const PAGE_TO_CLICK_EVENT = {
    [ModalPage.Index]: CreditCardWalletClickInteraction.SelectCard,
    [ModalPage.Create]: CreditCardWalletClickInteraction.CreateCard,
    [ModalPage.Edit]: CreditCardWalletClickInteraction.UpdateCard,
  } as const;

  async function onSelect(creditCardId: string) {
    form.setLoading(true);
    const result = await selectCheckoutCreditCard({ creditCardId });

    const modalState = buildModalState({
      modalPaths,
      router: props.router,
    });

    if (result) {
      checkoutClickEvent(PAGE_TO_CLICK_EVENT[modalState.page]);
      modalState.cancel();
    } else {
      form.setLoading(false);
    }
  }

  const form = useCreditCardFormState({
    id: formId,
    onSelect,
  });

  const { selectCheckoutCreditCard, errors } = useSelectCheckoutCreditCard(
    props.params.id,
    props.selectCheckoutCreditCard,
  );

  function onRequestClose() {
    checkoutClickEvent(CreditCardWalletClickInteraction.CloseModal);
    modalState.cancel();
  }

  function onBack() {
    checkoutClickEvent(CreditCardWalletClickInteraction.Cancel);
    modalState.goBack();
  }

  return (
    <ModalDialog
      headerTitle={modalState.headerTitle}
      saveButtonText={modalState.saveButtonText}
      backButtonText={modalState.backButtonText}
      isSaving={form.isLoading}
      onRequestClose={onRequestClose}
      onNavigateBack={onBack}
      formId={form.id}
      containsFormElement
      isOpen
    >
      {errors.length > 0 && (
        <div className="mb-2">
          <RCAlertBox type="error">
            {errors.map(e => <li key={`${e.field}-${e.message}`}>{e.message}</li>)}
          </RCAlertBox>
        </div>
      )}
      <CreditCardFormContext.Provider value={form}>
        {props.children}
      </CreditCardFormContext.Provider>
    </ModalDialog>
  );
}

function buildCheckoutModalPaths(checkoutId: string, creditCardId: string) {
  const start = Paths.checkoutShow.expand({ checkoutId });
  const index = Paths.checkoutCreditCardWallet.expand({
    checkoutId,
    selected_card_id: creditCardId,
  });
  const create = Paths.checkoutCreateCreditCard.expand({ checkoutId });
  const edit = Paths.checkoutEditCreditCard.expand({ checkoutId, creditCardId });

  return {
    start,
    index,
    create,
    edit,
  };
}

const withMutation = withGraphql<Props, CoreCheckoutSelectCreditCard.Mutation, CoreCheckoutSelectCreditCard.Variables>(
  CHECKOUT_SELECT_CREDIT_CARD_MUTATION,
  {
    name: 'selectCheckoutCreditCard',
  },
);

export default withMutation(CreditCardWalletModal);
