import { MutationFunction } from '@reverbdotcom/commons/src/useHOCMutation';
// TODO update this to import from commons/src/gql
// eslint-disable-next-line no-restricted-imports
import { gql } from '@apollo/client';
import React, { useEffect } from 'react';
import * as elog from '../elog';
import SignupSigninModal from '@reverbdotcom/commons/src/components/signup_signin_modal';
import { RCAlertBox, RCIcon, RCTooltip, RCGuidePopover, RCFavoriteButton } from '@reverbdotcom/cadence/components';
import { connect } from '../connect';
import I18n from 'i18n-js';
import { includes, isEmpty } from 'lodash';
import {
  CreateMyWatches,
  DeleteMyWatches,
  core_apimessages_Channel,
  WatchBadgeData,
  core_apimessages_FavoriteType,
} from '../gql/graphql';
import classNames from 'classnames';
import { MParticleEventName, ProductActionType, trackEvent } from '../elog/mparticle_tracker';
import { HeartEmptyIcon, HeartFilledIcon } from '@reverbdotcom/cadence/icons/react';
import { withGraphql } from '../with_graphql';
import { WatchUnwatchAnimation } from './animations/animations';
import { WatchParentComponentName } from '../event_tracking/watchlist';
import { useUser } from '../user_hooks';
import { IUser } from './user_context_provider';
import { isOwner } from '../user_context_helpers';
import { useURL } from '../url_context';
import { browserHistory } from 'react-router';
import { ToastCloseType } from '@reverbdotcom/cadence/components/types';
import { resolveCurrentView, resolveFavoriteType } from '../event_tracking/favorites';
import FavoriteToast from './favorite_toast';

const COMPONENT_NAME = 'watch_badge';
const WATCH_KEY = 'watch_id';
export enum DisplayStyle {
  BUTTON,
  TEXT,
  ANIMATION_ICON,
  ANIMATION_BUTTON,
  RC_FAVORITE_BUTTON,
}

export const WatchBadgeFragment = gql`
  fragment WatchBadgeData on Listing {
    _id
    id
    title
    sellerId
    watching
    watchThumbnails: images(input: { type: "Product", scope: "photos", transform: "card_square", count: 1}) {
      _id
      source
    }
  }
`;

/*
this data only exists if a parent component conditionally queries it (ex. bump rows).
watch badge will not hydrate this data on its own.
if it did, all watches for listings that happen to be bumped
would count as bump clicks (even if they appeared organically!).
*/
interface BumpKeyData { bumpKey?: any }

export type WatchBadgeListing = WatchBadgeData.Fragment & BumpKeyData;

interface GuidePopoverProps {
  title: string;
  content: string;
  isOpen: boolean;
  onDismiss: () => void;
}

interface IProps {
  listing: WatchBadgeListing;
  watchSource?: core_apimessages_Channel;
  createWatch?: MutationFunction<CreateMyWatches.Mutation, CreateMyWatches.Variables>;
  deleteWatch?: MutationFunction<DeleteMyWatches.Mutation, DeleteMyWatches.Variables>;
  displayStyle?: DisplayStyle;
  position?: number;
  // please pass a WatchParentComponentName value when possible instead of a plain string!
  parentComponentName?: WatchParentComponentName | string;
  useToast?: boolean;
  noToastLink?: boolean;
  guidePopoverProps?: GuidePopoverProps;
  autoWatch?: boolean;
  handleCreateWatchResult?: () => void;
  size?: 'default' | 'small' | 'large';
}

const loggedOut = (user) => user?.loggedOut ?? true;
const isIcon = (displayStyle) => includes([DisplayStyle.ANIMATION_ICON, DisplayStyle.RC_FAVORITE_BUTTON], displayStyle);
const showDisabledWithTooltip = (listing, user, displayStyle) => isOwner(user, listing) && !isIcon(displayStyle);

function WatchIconAndText(props) {
  const { displayStyle, watching } = props;
  const animate = includes(
    [DisplayStyle.ANIMATION_BUTTON, DisplayStyle.ANIMATION_ICON],
    displayStyle,
  );

  return (
    <div className="watch-badge__inner">
      <div className="watch-badge__icon watch-badge__icon--heart">
        {animate
          ? <div className="watch-badge__animation"><WatchUnwatchAnimation watching={watching} /></div>
          : <RCIcon svgComponent={watching ? HeartFilledIcon : HeartEmptyIcon} data-watch-heart />
        }
      </div>
      {!isIcon(displayStyle) && (
        <span className="watch-badge__text">
          {watching ?
            I18n.t('commons.watchBadge.unwatch') :
            I18n.t('commons.watchBadge.watch')
          }
        </span>
      )}
    </div>
  );
}

function DisabledWithTooltip(props) {
  const user = useUser();
  const { listing, displayStyle, watching, classes } = props;

  if (!showDisabledWithTooltip(listing, user, displayStyle)) return null;

  return (
    <div role="button" aria-disabled={true} className={classes}>
      <RCTooltip
        text={I18n.t('commons.watchBadge.ownListingTooltip')}
        placement="bottom"
        type="plain"
      >
        <div className="watch-badge__button">
          <WatchIconAndText
            displayStyle={displayStyle}
            watching={watching}
          />
        </div>
      </RCTooltip>
    </div>
  );
}

const WATCH_PROMPT_PARAM = 'watch_prompt';

export function InternalWatchBadge(props: IProps) {
  const user = useUser();
  const url = useURL();

  const useToast = !!props.autoWatch || props.useToast;
  const [toastOpen, setToastOpen] = React.useState(false);
  const [hasApiError, setHasApiError] = React.useState(false);

  const showToast = useToast ? () => setToastOpen(true) : null;
  const hideToast = () => setToastOpen(false);

  const favoriteEventProperties = {
    eventName: MParticleEventName.ClickedToastMessage,
    currentView: resolveCurrentView(url),
    favoriteType: resolveFavoriteType(url),
  };

  const handleToastClose = (closeType: ToastCloseType) => {
    hideToast();

    if (closeType === 'dismiss' || closeType === 'swipe') {
      trackEvent(favoriteEventProperties);
    }
  };

  const [isSignupSigninModalOpen, setIsSignupSigninModalOpen] = React.useState(false);

  useEffect(() => {
    handleAutoWatch(
      props,
      user,
      () => setIsSignupSigninModalOpen(true),
      showToast,
    );

    if (!loggedOut(user)) {
      const { listing } = props;
      const watchId = localStorage.getItem(WATCH_KEY);
      if (watchId === listing.id) {
        localStorage.removeItem(WATCH_KEY);
        if (!listing.watching && !isOwner(user, listing)) {
          handleWatch(props, showToast);
        }
      }
    }

    // During an Auto Watch, this component relies on the ?watch_prompt=true url parameter to display watch functionality
    // for a brief period of time, due to a race condition between the CreateMyWatches & Core_RecentlyViewedListings
    // requests, where the "listing.watching" state this component relied on would flicker from true->false->true, or if the
    // Core_RecentlyViewedListings request finished after CreateMyWatches, this component would have "listing.watching" = false.
    // Setting a brief 1000ms timeout to allow this component to believe the Auto Watch passed from the beginning of page load
    // mitigates this issue.
    if (url.query.watch_prompt) {
      setTimeout(
        () => {
          delete url.query[WATCH_PROMPT_PARAM];
          browserHistory.replace(url);
        },
        1000,
      );
    }
  }, []);

  const { listing, displayStyle = DisplayStyle.BUTTON, guidePopoverProps, noToastLink } = props;

  // Check that the user isn't logged out when ?watch_prompt url query param is present,
  // to avoid showing a false "watching" state when the user still needs to sign up.
  const watching = listing.watching || (!!url.query.watch_prompt && !loggedOut(user));
  const watchThumbnails = listing?.watchThumbnails || [];
  const thumbnail = watchThumbnails[0]?.source || '';

  const classes = classNames(
    'watch-badge',
    { 'watch-badge--watching': watching },
    { 'watch-badge--button': displayStyle === DisplayStyle.BUTTON },
    { 'watch-badge--text': displayStyle === DisplayStyle.TEXT },
    { 'watch-badge--animation-icon': displayStyle === DisplayStyle.ANIMATION_ICON },
    { 'watch-badge--animation-button': displayStyle === DisplayStyle.ANIMATION_BUTTON },
    { 'watch-badge--disabled': isOwner(user, listing) },
  );

  if (isOwner(user, listing)) {
    return (
      <DisabledWithTooltip
        listing={listing}
        displayStyle={displayStyle}
        watching={watching}
        classes={classes}
      />
    );
  }

  const onClick = (e) => handleClick(
    e,
    props,
    user,
    () => setIsSignupSigninModalOpen(true),
    showToast,
    setHasApiError,
  );

  function renderWatchButton() {
    if (displayStyle === DisplayStyle.RC_FAVORITE_BUTTON) {
      return (
        <RCFavoriteButton
          favorited={watching}
          onClick={onClick}
          disabled={isOwner(user, listing)}
          size={props.size}
        />
      );
    }

    return (
      <button
        className="watch-badge__button"
        onClick={onClick}
        type="button"
        title={watching ?
          I18n.t('commons.watchBadge.unwatchTooltip') :
          I18n.t('commons.watchBadge.watchTooltip')
        }
      >
        <WatchIconAndText
          displayStyle={displayStyle}
          watching={watching}
        />
      </button>
    );
  }

  const showGuidePopover = !isEmpty(guidePopoverProps);

  return (
    <>
      <div className={classes}>
        {showGuidePopover ? (
          <RCGuidePopover
            title={guidePopoverProps.title}
            content={guidePopoverProps.content}
            isOpen={guidePopoverProps.isOpen}
            onDismiss={guidePopoverProps.onDismiss}
            anchor={renderWatchButton()}
            width="narrow"
            preventAutoFocus
          />
        ) : renderWatchButton()}
      </div>
      <SignupSigninModal
        onRequestClose={() => setIsSignupSigninModalOpen(false)}
        active={isSignupSigninModalOpen}
        messageComponent={
          <WatchBenefits
            thumbnail={thumbnail}
            alt={listing.title}
          />
        }
        trackSource="WatchBadge"
        onlyShowMessageOnTab="signup"
      />
      {useToast &&
        <FavoriteToast
          favoriteId={listing.id}
          isFavorited={listing.watching}
          favoriteType={core_apimessages_FavoriteType.LISTING}
          isOpen={toastOpen}
          handleToastClose={handleToastClose}
          noToastLink={!!noToastLink}
          hasError={hasApiError}
          setHasError={setHasApiError}
        />
      }
    </>
  );
}

function WatchBenefits(props: { thumbnail: string, alt: string }) {
  return (
    <div
      id="watch-benefits"
      className="scaling-mb-4"
    >
      <RCAlertBox type="info">
        <h4 className="align-center size-110 weight-bold mb-2">
          {I18n.t('commons.watchBadge.benefits.signUp')}
        </h4>
        <div className="d-flex fx-align-center">
          <div className="fx-width-20 mr-2">
            <img src={props.thumbnail} alt={props.alt} role="presentation" className="d-block width-100" />
          </div>
          <ul className="size-80">
            <li className="d-flex">
              <span className="icon-l-check color-green" />
              <span>
                {I18n.t('commons.watchBadge.benefits.priceDrop')}
              </span>
            </li>
            <li className="d-flex mt-1">
              <span className="icon-l-check color-green" />
              {I18n.t('commons.watchBadge.benefits.offers')}
            </li>
            <li className="d-flex mt-1">
              <span className="icon-l-check color-green" />
              {I18n.t('commons.watchBadge.benefits.save')}
            </li>
          </ul>
        </div>
      </RCAlertBox>
    </div>
  );
}

const createWatch =
  withGraphql<IProps, CreateMyWatches.Mutation, CreateMyWatches.Variables>(
    gql`
      mutation CreateMyWatches($input: Input_core_apimessages_WatchesCreateRequest) {
        createWatch(input: $input) {
          id
          listing {
            id
            _id
            watching
          }
        }
      }
    `,
    {
      name: 'createWatch',
      options: (ownProps) => {
        const { listing, watchSource } = ownProps;
        return {
          variables: {
            input: {
              listingId: listing.id,
              channel: watchSource,
              bumpKey: listing.bumpKey?.key ?
                { key: listing.bumpKey.key } :
                null,
            },
          },
          optimisticResponse: {
            __typename: 'Mutation',
            createWatch: {
              __typename: 'core_apimessages_UpdateWatchResponse',
              id: -1,
              listing: {
                id: listing.id,
                _id: listing._id,
                watching: true,
                __typename: 'Listing',
              },
            },
          },
          refetchQueries: ownProps.autoWatch ? ['Core_Listing_Layout'] : undefined,
          onCompleted: ownProps?.handleCreateWatchResult,
        };
      },
    },
  );

const deleteWatch =
  withGraphql<IProps, DeleteMyWatches.Mutation, DeleteMyWatches.Variables>(
    gql`
      mutation DeleteMyWatches($input: Input_core_apimessages_WatchesDeleteRequest) {
        deleteWatch(input: $input) {
          id
          listing {
            _id
            id
            watching
          }
        }
      }
    `,
    {
      name: 'deleteWatch',
      options: (ownProps) => {
        const { listing } = ownProps;
        return {
          variables: {
            input: { listingId: listing.id },
          },
          optimisticResponse: {
            deleteWatch: {
              __typename: 'core_apimessages_UpdateWatchResponse',
              id: -1,
              listing: {
                id: listing.id,
                _id: listing._id,
                watching: false,
                __typename: 'Listing',
              },
            },
          },
        };
      },
    },
  );

async function handleWatch(props: IProps, showToast?: () => void, setHasApiError?: (boolean) => void) {
  const {
    listing,
    position,
    parentComponentName,
    deleteWatch,
    createWatch,
  } = props;

  const watching = listing.watching;

  const productActionType = watching ?
    ProductActionType.RemoveFromWishlist : ProductActionType.AddToWishlist;
  const eventName = watching ?
    MParticleEventName.RemoveFromWishlist : MParticleEventName.AddToWishlist;

  try {
    await (watching ? deleteWatch() : createWatch());
    setHasApiError?.(false);
    showToast?.();

    trackEvent({
      productActionType,
      eventName,
      listing,
      position,
      componentName: parentComponentName,
    });

    // This is temporarily causing an issue where unhearting doesn't work on the Favorites Watch List
    // setFavoritesCacheStale(FavoriteCacheTypes.Listing);
  } catch (e) {
    setHasApiError?.(true);
    showToast?.();

    elog.error(
      e.message,
      { eventName, componentName: COMPONENT_NAME, listingId: listing.id },
      e,
    );
  }
}

function handleClick(evt, props: IProps, user: Partial<IUser>, handleLoggedOutUser: (evt) => void, showToast?: () => void, setHasApiError?: (boolean) => void) {
  evt.preventDefault();
  evt.stopPropagation();

  if (loggedOut(user)) {
    trackLoggedOutWatch(ProductActionType.Click, props);

    localStorage.setItem(WATCH_KEY, props.listing.id);
    handleLoggedOutUser(evt);
    return;
  }

  handleWatch(props, showToast, setHasApiError);
}

function handleAutoWatch(props: IProps, user: Partial<IUser>, handleLoggedOutUser: () => void, showToast?: () => void) {
  if (!props.autoWatch || isOwner(user, props.listing)) return;

  if (loggedOut(user)) {
    trackLoggedOutWatch(ProductActionType.ViewDetail, props);
    handleLoggedOutUser();
    return;
  }

  if (props.listing.watching) {
    showToast?.();
  } else {
    localStorage.setItem(WATCH_KEY, props.listing.id);
  }
}

function trackLoggedOutWatch(productActionType, props: IProps) {
  const {
    listing,
    position,
    parentComponentName,
  } = props;

  trackEvent({
    productActionType,
    eventName: MParticleEventName.LoggedOutWatch,
    listing,
    position,
    componentName: parentComponentName,
  });
}

export const WatchBadge = connect<IProps>([
  createWatch,
  deleteWatch,
])(InternalWatchBadge);
