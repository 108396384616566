import React from 'react';
import ReactDOM from 'react-dom';
import classNames from 'classnames';
import bind from '../bind';

import CoreLink from './core_link';
import { MParticleEvent } from '../elog/mparticle_types';

export interface IJumplink {
  text: string | JSX.Element;
  id?: string;
  inSidebar?: boolean;
  clickEvent?: MParticleEvent;
}

interface IProps {
  jumplinks?: IJumplink[];
  centered?: boolean;
}

function makeId(jump) {
  if (jump.id) {
    return jump.id;
  }

  return jump.text.toLowerCase().replace(/[\W\b\s]/gi, '');
}

interface IState {
  stuckToTop: boolean;
}

export default class Jumplinks extends React.Component<IProps, IState> {
  state = {
    stuckToTop: false,
  };

  componentDidMount() {
    window.addEventListener('scroll', this.checkScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.checkScroll);
  }

  @bind
  checkScroll() {
    // TODO: Remove ReactDOM.findDOMNode
    const el = ReactDOM.findDOMNode(this) as any;
    if (!el) return;

    // can't be exactly 0 because Mobile Safari will flicker
    this.setState({
      stuckToTop: el.getBoundingClientRect().top <= 1,
    });
  }

  render() {
    if (!this.props.jumplinks || !this.props.jumplinks.length) return null;
    const classes = classNames(
      'jumplinks',
      { 'jumplinks--stuck': this.state.stuckToTop },
      { 'jumplinks--centered': this.props.centered },
    );

    return (
      <div
        className={classes}
      >
        <div
          className="jumplinks__inner"
        >
          {this.props.jumplinks.map((jump) => {
            const id = makeId(jump);

            return (
              <CoreLink
                replace
                activeClassName="jumplink--active"
                to={{
                  anchor: id,
                }}
                className={classNames(
                  `jumplink jumplink--${id}`,
                  { 'jumplink--in-sidebar': jump.inSidebar },
                )}
                key={id}
                clickEvent={jump.clickEvent}
              >
                {jump.text}
              </CoreLink>
            );
          })}
        </div>
      </div>
    );
  }
}
