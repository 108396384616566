import { ChildProps } from '@apollo/client/react/hoc';
import { MutationFunction } from '@reverbdotcom/commons/src/useHOCMutation';
// TODO update this to import from commons
// import { gql } from '@reverbdotcom/commons/src/gql';
// eslint-disable-next-line no-restricted-imports
import { gql } from '@apollo/client';
import React, { useState } from 'react';
import I18n from 'i18n-js';

import ModalDialog from '@reverbdotcom/commons/src/components/modal_dialog';
import ModalSidebarListing from '@reverbdotcom/commons/src/components/modal_sidebar_listing';
import { withGraphql } from '@reverbdotcom/commons/src/with_graphql';
import { RCAlertBox, RCCheckbox, RCTextArea, RCTextInput, RCIcon } from '@reverbdotcom/cadence/components';
import SanitizedRender from '@reverbdotcom/commons/src/components/sanitized_render';
import { HeartEmptyIcon, CartIcon } from '@reverbdotcom/cadence/icons/react';
import { formatNumber, parseAmountCents } from '@reverbdotcom/commons/src/money';
import Currency from '@reverbdotcom/commons/src/currency';
import CoreLink from '@reverbdotcom/commons/src/components/core_link';
import { useHelpCenterArticleUrl } from '@reverbdotcom/commons/src/url_helpers';
import { MoneyInput } from '@reverbdotcom/commons/src/components/money_input';

import { ModalSidebarListingFragment } from '@reverbdotcom/commons/src/components/modal_sidebar_listing_operations';
import {
  AutoOfferListingFragment,
  createAutoOfferMutation,
  deleteAutoOfferMutation,
} from '@reverbdotcom/commons/src/offers/offer_operations';
import {
  CoreAutoOfferModal,
  AutoOfferListingFragment as RQLAutoOfferListingFragment,
  CoreAutoOffersCreateAutoOfferMutation,
  CoreAutoOffersDeleteAutoOfferMutation,
  core_apimessages_Money as Money,
} from '@reverbdotcom/commons/src/gql/graphql';
import Location from '../../lib/wrapped_location';
import { connect } from '@reverbdotcom/commons/src/connect';
import { formReducer } from './direct_offer_reducer';

const MINIMUM_DISCOUNT_PERCENT = 5;
const DEFAULT_DISCOUNT_PERCENT = 10;
export const DIRECT_OFFER_HELP_CENTER_ARTICLE_ID = '10672996858387';

interface ExternalProps {
  listingId: string;
  modalIsOpen: boolean;
  onModalClose: () => void;
  refetchSellerListing?: () => void;
}

interface IApolloProps {
  createAutoOffer: MutationFunction<
    CoreAutoOffersCreateAutoOfferMutation.Mutation,
    CoreAutoOffersCreateAutoOfferMutation.Variables
  >;
  deleteAutoOffer: MutationFunction<
    CoreAutoOffersDeleteAutoOfferMutation.Mutation,
    CoreAutoOffersDeleteAutoOfferMutation.Variables
  >;
}

type Props = ChildProps<ExternalProps, CoreAutoOfferModal.Query> & IApolloProps;

export const emptyFormState = {
  autoOfferIsActive: false,
  messageToBuyer: '',
  submitFailedMessage: '',
  percentage: DEFAULT_DISCOUNT_PERCENT,
  shippingPriceOverride: { amount: '' },
};

export interface IState {
  messageToBuyer: string;
  autoOfferIsActive: boolean;
  submitFailedMessage: string;
  percentage: number;
  shippingPriceOverride: Money;
}

function percentageValidationError(formState: IState) {
  if (formState.percentage < MINIMUM_DISCOUNT_PERCENT) {
    return I18n.t('discovery.offers.autoOffers.createForm.minimumPercentageError', { minimum: MINIMUM_DISCOUNT_PERCENT });
  }
  if (formState.percentage >= 100) {
    return I18n.t('discovery.offers.autoOffers.createForm.maximumPercentageError');
  }
  return null;
}

function discountedPrice(amountCents: number, currency: string, percentDiscount: number): string {
  const discountMultiplier = (100 - percentDiscount) / 100;
  const discountedPriceCents = Math.floor(amountCents * discountMultiplier);
  const parsed = parseAmountCents(discountedPriceCents, currency);

  return formatNumber(Number(parsed.amount));
}

function hasAutoOffer(listing: RQLAutoOfferListingFragment.Fragment) {
  return listing && !!listing.autoOffers.id;
}

function hasDisabledExistingAutoOffer(formState: IState, listing: RQLAutoOfferListingFragment.Fragment) {
  return !formState.autoOfferIsActive && hasAutoOffer(listing);
}

function listingTaxIncluded(listing: RQLAutoOfferListingFragment.Fragment) {
  return !!listing.taxIncluded && !!listing.taxIncludedHint;
}

function userCurrencyMatchesShopCurrency(listing: RQLAutoOfferListingFragment.Fragment) {
  return listing.price.currency === listing.sellerPrice.currency;
}

function listingStartingPrice(listing: RQLAutoOfferListingFragment.Fragment) {
  if (listingTaxIncluded(listing) && userCurrencyMatchesShopCurrency(listing)) {
    return listing.price;
  }

  return listing.sellerPrice;
}

function calculatedPriceLabel(listing, percentOff, currencySymbol, startingPrice) {
  const i18nParams = {
    percent: percentOff.toString(),
    currencySymbol,
    price: discountedPrice(startingPrice.amountCents, startingPrice.currency, percentOff),
    tagOpen: '<span class="weight-bold">',
    tagClose: '</span>',
  };

  if (userCurrencyMatchesShopCurrency(listing)) {
    return listingTaxIncluded(listing) ? <SanitizedRender
      html={
        I18n.t('discovery.offers.autoOffers.createForm.calculatedPriceWithTaxHint',
          {
            ...i18nParams,
            hint: listing.taxIncludedHint,
          },
        )
      }
    /> : <SanitizedRender
      html={
        I18n.t('discovery.offers.autoOffers.createForm.calculatedPrice', i18nParams)
      }
    />;
  }

  return '';
}

function LoadingState() {
  return (
    <div className="align-center">
      {I18n.t('discovery.offers.autoOffers.createForm.loading')}
    </div>
  );
}

function AutoOfferModalSidebarListing({ loading, listing }) {
  if (loading || !listing) {
    return null;
  }

  const shouldDisplayListPriceWithTaxIncluded = listing.taxIncluded && !!listing.taxIncludedHint;
  const details = [
    {
      term: I18n.t('commons.offers.sidebar.condition'),
      definition: listing.condition.displayName,
    },
    {
      term: I18n.t('commons.offers.sidebar.sellerCost'),
      definition: listing.sellerCost?.display,
    },
    {
      term: I18n.t('commons.offers.sidebar.askingPrice'),
      definition: listing.sellerPrice.display,
    },
    {
      term: I18n.t('commons.offers.sidebar.listPrice'),
      definition: shouldDisplayListPriceWithTaxIncluded && I18n.t('commons.offers.sidebar.priceWithTaxIncludedHint', {
        displayPrice: listing.price.display,
        taxIncludedHint: listing.taxIncludedHint,
      }),
    },
    {
      term: I18n.t('commons.offers.sidebar.watches'),
      definition: listing.counts.watchers,
    },
  ].filter(({ definition }) => !!definition);

  return (
    <ModalSidebarListing
      loading={loading}
      listing={listing}
      details={details}
    />
  );
}

function GeneralInformation() {
  const helpCenterLink = useHelpCenterArticleUrl(DIRECT_OFFER_HELP_CENTER_ARTICLE_ID);

  return (
    <div className="auto-offer-form__notices">
      <RCAlertBox type="plain">
        <p>
          <strong>
            {I18n.t('discovery.offers.autoOffers.createForm.generalInformation.header')}
          </strong>
        </p>
        <div className="d-flex">
          <div className="auto-offer-form__notices__icon">
            <RCIcon svgComponent={HeartEmptyIcon} inline />
          </div>
          <p>{I18n.t('discovery.offers.autoOffers.createForm.generalInformation.line1')}</p>
        </div>
        <div className="d-flex">
          <div className="auto-offer-form__notices__icon">
            <RCIcon svgComponent={CartIcon} inline />
          </div>
          <p>{I18n.t('discovery.offers.autoOffers.createForm.generalInformation.line2')}</p>
        </div>
        <p>
          <CoreLink
            to={helpCenterLink}
          >
            {I18n.t('discovery.offers.autoOffers.createForm.generalInformation.learnMore')}
          </CoreLink>
        </p>
      </RCAlertBox>
    </div>
  );
}

export function AutoOfferModal(props: Props) {
  const [formState, dispatch] = React.useReducer(formReducer, emptyFormState);
  const [includeOfferMessage, setIncludeOfferMessage] = useState(false);

  const { loading, listing } = props.data;

  React.useEffect(() => {
    if (!loading && hasAutoOffer(listing)) {
      dispatch({ type: 'prefillFormState', value: { listing } });
      setIncludeOfferMessage(!!listing.autoOffers.message);
    }
  }, [listing, loading]);

  const errorDisplay = formState.submitFailedMessage;
  const sellerPriceCurrencyCode = !loading && listing && listing.sellerPrice.currency;
  const autoOfferCurrency = sellerPriceCurrencyCode && Currency.get(sellerPriceCurrencyCode);
  const isCarrierCalculatedListing = !loading && listing && !!listing.shipmentPackage;
  const percentageValidationMessage = percentageValidationError(formState);

  async function handleSubmit() {
    dispatch({ type: 'setSubmitFailedMessage', value: '' });

    try {
      if (hasDisabledExistingAutoOffer(formState, listing)) {
        await props.deleteAutoOffer({
          variables: {
            input: {
              listingId: listing.id,
            },
          },
        });
      } else {
        await props.createAutoOffer({
          variables: {
            input: {
              listingId: listing.id,
              message: includeOfferMessage ? formState.messageToBuyer : '',
              percentage: formState.percentage,
              shippingPriceOverride: isCarrierCalculatedListing ? formState.shippingPriceOverride : null,
            },
          },
        });
      }

      props.onModalClose();

      if (props.refetchSellerListing) {
        // Update listing if using SellerListingCard component
        props.refetchSellerListing();
      } else {
        // Reload the page since the indicator UI in the auto offer button comes in as a Rails prop
        // If we don't reload, the indicator will be out of sync with the current state of the auto offer
        Location.reload();
      }
    } catch (err) {
      dispatch({ type: 'setSubmitFailedMessage', value: I18n.t('discovery.offers.autoOffers.createForm.submitFailedMessage') });
    }
  }

  return (
    <ModalDialog
      isOpen={props.modalIsOpen}
      headerTitle={I18n.t('commons.offers.autoOffers')}
      onRequestClose={() => props.onModalClose()}
      sidebarContent={
        <AutoOfferModalSidebarListing
          loading={props.data.loading}
          listing={props.data && props.data.listing}
        />
      }
      saveButtonText={hasDisabledExistingAutoOffer(formState, listing)
        ? I18n.t('discovery.offers.autoOffers.createForm.save')
        : I18n.t('discovery.offers.autoOffers.createForm.submit')}
      backButtonText={I18n.t('discovery.offers.autoOffers.createForm.cancel')}
      isDisabled={!formState.percentage || !!percentageValidationMessage || (isCarrierCalculatedListing && !formState.shippingPriceOverride?.amount)}
      onSubmit={handleSubmit}
    >
      {loading && <LoadingState />}

      {!loading && (
        <>
          <GeneralInformation />

          <RCCheckbox
            id="toggleAutoOffers"
            name="toggleAutoOffers"
            checked={formState.autoOfferIsActive}
            label={I18n.t('discovery.offers.autoOffers.createForm.enable')}
            onChange={() => dispatch({ type: 'toggleOfferActive' })}
          />

          {formState.autoOfferIsActive && (
            <div>
              {errorDisplay && (
                <RCAlertBox type="warning" small>
                  <p className="mb-0">
                    {errorDisplay}
                  </p>
                </RCAlertBox>
              )}

              <div className="d-flex fx-align-center mt-4">
                <div className="width-50 mr-4">
                  <RCTextInput
                    label={I18n.t('discovery.offers.autoOffers.createForm.percentageLabel')}
                    id="percentage"
                    name="percentage"
                    value={formState.percentage ? formState.percentage.toString() : ''}
                    onChange={(evt) => dispatch({ type: 'setPercentage', value: evt.target.value })}
                    required
                    errorText={percentageValidationMessage}
                    suffixText="%"
                  />
                </div>
                <div id="calculatedPriceLabel" className="width-50 pt-6">
                  {!percentageValidationMessage && !!formState.percentage && calculatedPriceLabel(
                    listing,
                    formState.percentage,
                    autoOfferCurrency.symbol,
                    listingStartingPrice(listing),
                  )}
                </div>
              </div>

              <div className="rc-form-group__help-text">
                {I18n.t('discovery.offers.autoOffers.createForm.percentageHelpText', { minimum: MINIMUM_DISCOUNT_PERCENT })}
              </div>

              {isCarrierCalculatedListing && (
                <>
                  <div className="mtb-4">
                    <p className="hint">
                      {I18n.t('discovery.offers.autoOffers.createForm.carrierCalculated.alertText')}
                    </p>
                  </div>

                  <MoneyInput
                    currencyCode={sellerPriceCurrencyCode}
                    label={I18n.t('discovery.offers.autoOffers.createForm.carrierCalculated.inputLabel')}
                    value={formState.shippingPriceOverride?.amount || ''}
                    onChange={(evt) => dispatch({ type: 'setShippingPriceOverride', value: evt.target.value })}
                  />
                </>
              )}

              <div className="mt-4">
                <RCCheckbox
                  name="includeOfferMessage"
                  checked={includeOfferMessage}
                  label={I18n.t('discovery.offers.autoOffers.createForm.includeOfferMessage')}
                  onChange={() => setIncludeOfferMessage(!includeOfferMessage)}
                />
              </div>
              {includeOfferMessage && (
                <div className="mt-2">
                  <RCTextArea
                    id="messageToBuyer"
                    name="messageToBuyer"
                    value={formState.messageToBuyer}
                    label={I18n.t('discovery.offers.autoOffers.createForm.message')}
                    tag="optional"
                    onChange={(e) =>
                      dispatch({ type: 'setMessageToBuyer', value: e.target.value })
                    }
                  />
                </div>
              )}
            </div>
          )}

          <div className="offers-form__actions">
            {hasDisabledExistingAutoOffer(formState, listing) && (
              <div className="mb-4">
                <RCAlertBox type="warning" small>
                  <h2 className="weight-bold size-120 mb-1 icon-l-exclamation-circle">
                    {I18n.t('discovery.offers.autoOffers.createForm.alertTitle')}
                  </h2>
                  <p className="mb-0">
                    {I18n.t('discovery.offers.autoOffers.createForm.alertCopy')}
                  </p>
                </RCAlertBox>
              </div>
            )}
          </div>
        </>
      )}
    </ModalDialog>
  );
}

const connected = withGraphql<ExternalProps>(
  gql`
    query Core_AutoOfferModal($listingId: String) {
      listing(input: { id: $listingId }) {
        _id
        ...ModalSidebarListingFragment
        ...AutoOfferListingFragment
      }
    }
    ${ModalSidebarListingFragment}
    ${AutoOfferListingFragment}
  `,
  {
    options: {
      ssr: false, // not important for SEO or initial page rendering
      fetchPolicy: 'network-only',
    },
  },
);

export default connect<ExternalProps>([
  createAutoOfferMutation,
  deleteAutoOfferMutation,
  connected,
])(AutoOfferModal);
