import * as I18n from 'i18n-js';
import { regionalizePath, SUPPORTED_URL_REGIONS } from '@reverbdotcom/commons/src/url_helpers';
import { InjectedRouter } from 'react-router';
import { some } from 'lodash';

export enum ModalPage {
  Index = 'index',
  Create = 'create',
  Edit = 'edit',
}

interface CreditCardWalletModalState {
  headerTitle: string;
  saveButtonText: string;
  backButtonText: string;
  page: string;
  goBack: () => void;
  cancel: () => void;
}

export interface ModalPaths {
  start: string;
  index: string;
  create: string;
  edit: string;
}

export function buildModalState({ modalPaths, router }: {
  router: InjectedRouter, modalPaths: ModalPaths
}): CreditCardWalletModalState {
  const page = chooseModalPage(modalPaths, router);
  const headerTitle = translate(page, 'title');
  const saveButtonText = translate(page, 'saveButton');
  const backButtonText = translate(page, 'backButton');

  function cancel() {
    router.push(modalPaths.start);
  }

  function goBack() {
    if (page === ModalPage.Index) {
      cancel();
    } else {
      router.push(modalPaths.index);
    }
  }

  return {
    headerTitle,
    saveButtonText,
    backButtonText,
    goBack,
    cancel,
    page,
  };
}

function translate(page: ModalPage, key: 'title' | 'saveButton' | 'backButton') {
  return I18n.t(`discovery.creditCardWallet.modal.${page}.${key}`);
}

function chooseModalPage(paths: ModalPaths, router: InjectedRouter) {
  const isCreatePage = isModalPathActive(paths.create, router);
  const isEditPage = isModalPathActive(paths.edit, router);

  if (isEditPage) {
    return ModalPage.Edit;
  } else if (isCreatePage) {
    return ModalPage.Create;
  }

  return ModalPage.Index;
}

function isModalPathActive(pathname: string, router: InjectedRouter) {
  return router.isActive({ pathname }) || isRegionalPathActive(pathname, router);
}

function isRegionalPathActive(pathname: string, router: InjectedRouter) {
  return some(SUPPORTED_URL_REGIONS, (region) => (
    router.isActive({ pathname: regionalizePath(pathname, region) })
  ));
}
