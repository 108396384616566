import PropTypes from 'prop-types';
import React from 'react';
import { SortableElement } from 'react-sortable-hoc';
import classNames from 'classnames';
import I18n from 'i18n-js';
import { get } from 'lodash';

class Thumb extends React.Component {
  disableEditPhoto() {
    return !this.props.allowEdit || this.isUnsupportedBrowser;
  }

  get isUnsupportedBrowser() {
    const msie = get($, 'browser.msie');
    const version = get($, 'browser.version');
    return msie && version && parseInt(version, 10) < 11;
  }

  edit() {
    this.props.onEdit(this.props.photo);
  }

  remove() {
    this.props.onRemove(this.props.photo);
  }

  renderEditLink() {
    if (this.disableEditPhoto()) { return null; }

    return (
      /* disabling inline here so we can enable this rule globally, but this should be fixed the next time this file is touched */
      /* eslint-disable-next-line jsx-a11y/anchor-is-valid */
      (<a
        className="img-uploader__actions__action img-uploader__actions__action--edit"
        onClick={() => this.edit()}
      >
        {I18n.t('discovery.photos.thumb.edit')}
      </a>)
    );
  }

  render() {
    const className = classNames('img-uploader__thumb', {
      'img-uploader__thumb--with-warning': this.props.photo.showTooSmallWarning,
    });

    return (
      <div className={className}>
        <div className="img-uploader__thumb__image">
          {/* disabling inline here so we can enable this rule globally, but this should be fixed the next time this file is touched */}
          {/* eslint-disable-next-line jsx-a11y/alt-text */}
          <img
            src={this.props.photo.thumbUrl}
            role="presentation"
          />
          {/* this overlay fixes an issue in firefox that causes the dragged item to
          freeze when using the image as a drag handle */}
          <div className="img-uploader__thumb__image__overlay" />
        </div>
        <div className="img-uploader__actions">
          {this.renderEditLink()}
          {/* disabling inline here so we can enable this rule globally, but this should be fixed the next time this file is touched */}
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a
            className="img-uploader__actions__action img-uploader__actions__action--remove"
            onClick={() => this.remove()}
          />
        </div>
      </div>
    );
  }
}

Thumb.propTypes = {
  photo: PropTypes.object.isRequired,
  onEdit: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  allowEdit: PropTypes.bool.isRequired,
};

export default SortableElement(Thumb); // eslint-disable-line new-cap
