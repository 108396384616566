import React from 'react';
import I18n from 'i18n-js';
import PacklinkModal from './packlink_modal';
import Window from '@reverbdotcom/commons/src/window_wrapper';
import { Paths } from './shared/url_helpers';

interface Props {
  orderId: string;
  classes?: string;
}

export const COMPONENT_NAME = 'PacklinkShippingLabelButton';
export const DEFAULT_CLASSES = 'button button--primary button--mini';

export function PacklinkShippingLabelButton({
  orderId,
  classes = null,
}: Props) {
  const [isOpen, setOpen] = React.useState(false);

  function openModal() {
    setOpen(true);
  }

  function closeModal() {
    setOpen(false);
  }

  function onClick() {
    openModal();
  }

  function onDraftSuccess() {
    Window.open(Paths.packlinkRedirect.expand({}));
  }

  const classNames = classes || DEFAULT_CLASSES;

  return (
    <>
      <button
        type="button"
        onClick={onClick}
        className={classNames}
      >
        {I18n.t('discovery.packlink.getShippingLabel')}
      </button>
      <PacklinkModal
        isOpen={isOpen}
        onRequestClose={closeModal}
        onDraftSuccess={onDraftSuccess}
        orderId={orderId}
      />
    </>
  );
}
