import classnames from 'classnames';
import I18n from 'i18n-js';
import React from 'react';

import { RCTag, RCAccordion, TagType } from '@reverbdotcom/cadence/components';

import { inMobileApp } from '../user_context_helpers';
import { useUser } from '../user_hooks';

function PageWrapper({ children }) {
  return (
    <div className={classnames(
      'form-cards__page-wrapper',
      {
        'form-cards__page-wrapper--mobile-native': inMobileApp(useUser()),
      },
    )}>
      {children}
    </div>
  );
}

interface IFormProps {
  onSubmit: () => void;
  /**
   * Main form content.
  */
  children: React.ReactNode;
  /**
   * Typically button/action content, appears in bottom right of form area on web (collapsed/centered/sticky on mobile).
  */
  primaryFooterContent: React.ReactNode;
  /**
  * Optional content, appears in bottom left of form area on web (collapsed/centered on mobile).
  */
  additionalFooterContent?: React.ReactNode;
  /**
   * Optional row of content above main form content.
   */
  headerContent?: React.ReactNode;
  /**
  * Typically unnecessary, used in special cases to grow container to
  * accommodate 2-3 vertically stacked buttons in some mobile contexts.
  */
  tallFooter?: boolean;
}

function Form({
  onSubmit,
  children,
  primaryFooterContent,
  additionalFooterContent = null,
  headerContent = null,
  tallFooter = false,
}: IFormProps) {
  return (
    <form
      className="form-cards__form"
      data-disable-auto-validation="true" // block forms.js handling
      onSubmit={(e) => {
        e.preventDefault();
        onSubmit();
      }}
    >
      {headerContent}

      <div className={classnames(
        'form-cards__form__content-wrapper',
        {
          'form-cards__form__content-wrapper--tall-footer': tallFooter,
        },
      )}>
        {children}

        <div className="form-cards__form__footer__mobile-additional-content">
          {additionalFooterContent}
        </div>
      </div>


      <div className={classnames(
        'form-cards__form__footer',
        {
          'form-cards__form__footer--native-mobile': inMobileApp(useUser()),
        },
      )}>
        <div className="form-cards__form__footer__additional-content">
          {additionalFooterContent}
        </div>

        {primaryFooterContent && (
          <div className="form-cards__form__footer__actions">
            {primaryFooterContent}
          </div>
        )}
      </div>
    </form>
  );
}

function Card({ children }) {
  return (
    <div className="form-cards__card">
      {children}
    </div>
  );
}

function FormSection({
  children,
  title,
  subtitle,
  tag,
  includeBottomBorder = false,
  fullWidthForm = false,
  togglable = false,
}: {
  children: React.ReactNode;
  title?: string;
  subtitle?: string;
  tag?: TagType;
  includeBottomBorder?: boolean;
  fullWidthForm?: boolean;
  togglable?: boolean;
}) {
  const classes = classnames(
    'form-cards__form-section',
    {
      'form-cards__form-section--bottom-border': includeBottomBorder,
      'form-cards__form-section--condensed-form': !fullWidthForm,
      'form-cards__form-section--toggled-section': togglable,
    },
  );

  return (
    <div className={classes}>
      {title && (
        <div className="form-cards__form-section__header">
          <span className="form-cards__form-section__header__title">
            {title}
          </span>
          {tag && tag !== 'none' && (
            <div className="form-cards__form-section__header__tag">
              <RCTag variant={tag === 'recommended' ? 'highlight' : null}>
                {I18n.t(`commons.formGroup.${tag}`)}
              </RCTag>
            </div>
          )}

          {subtitle && (
            <p className="form-cards__form-section__header__subtitle">
              {subtitle}
            </p>
          )}
        </div>
      )}
      {children}
    </div>
  );
}

function ToggledSection({
  title,
  children,
  id,
  defaultOpen = false,
}: {
  title: string;
  children: React.ReactNode;
  id: string;
  defaultOpen?: boolean;
}) {
  return (
    <FormSection togglable>
      <RCAccordion
        id={id}
        heading={title}
        defaultOpen={defaultOpen}
      >
        {children}
      </RCAccordion>
    </FormSection>
  );
}

export const FormCards = {
  PageWrapper,
  Form,
  Card,
  FormSection,
  ToggledSection,
};
