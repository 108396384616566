/* eslint max-len: "off" */

import acceptedPaymentMethods from './accepted_payment_methods/en';
import countries from './countries/en';
import subRegions from './sub_regions/en';
import currencies from './currencies/en';
import digital from './digital_listings/en';
import emailOffers from './email_offers/en';
import priceGuide from './price_guide/en';
import productShowPage from './product_show_page/en';
import shippingRates from './shipping_rates/en';
import shopStats from './shop_stats/en';
import sellerHub from './seller_hub/en';

const { digitalListing, digitalListingCard, digitalProductsIndex } = digital;

const number = {
  format: {
    separator: '.',
    delimiter: ',',
    precision: 3,
  },
  currency: {
    format: {
      format: '%u%n',
      unit: '$',
      separator: '.',
      delimiter: ',',
      precision: 2,
    },
  },
};

const js = {
  lp: 'Reverb LP',
  next: 'Next',
  previous: 'Previous',
  wait: 'Please wait...',
  elipse: '...',
  listSeparator: ', ',
  free: 'Free',
  mobileAppAds: {
    reverbAppIllustration: 'Reverb Mobile App Illustration',
    headingCtl: 'Get the Reverb App',
    headingExp1: 'A World of Gear Wherever You Go',
    headingExp2: 'Check In On Your Orders From Anywhere',
    subheadingCtl: 'Buy and sell gear anytime, anywhere',
    subheadingExp1: 'Buy and sell gear anywhere with the Reverb App',
    subheadingExp2: 'Order tracking at your fingertips with the Reverb App',
    iosAlt: 'iOS App Store button',
    androidAlt: 'Android Play Store button',
  },
  offerModal: {
    cancel: 'Cancel',
    makeAnOffer: 'Make an Offer',
    title: 'Offer For',
    needLocationForShipping: 'We need your location to give you an accurate shipping cost.',
    neverShareWithThirdParties: 'We will never share your location with third parties.',
    agreeToPay: 'I agree to pay if my offer is accepted.',
    saleDiscountNotice: 'Sale discounts cannot be applied to negotiated offers.',
    successfulOfferResponse: "Your offer for %{displayPrice} has been submitted. You'll hear from the seller soon.",
    stateLabelText: 'Your State',
    countryLabelText: 'Your Country',
    optionalMessageLabelText: 'Add a message (optional)',
    messageRequiredText: 'Message*',
    error: 'Your offer cannot be submitted at this time.',
    expiration_time: '24 hours',
    replyTimeline: '%{shopName} has %{expiration_time} to reply.',
    asking: 'Asking',
    submit_offer: 'Submit Offer',
    sellerSubtext: 'If %{recipientName} accepts this offer, you agree to complete the sale of this item at the accepted price.',
    shipping: 'Shipping',
    condition: 'Condition',
  },
  offerInput: {
    bestOffer: 'Your best offer',
    pricePlacehlder: 'Your Price',
    shipping: 'Shipping',
    nudgeHeader: 'Buying Tip',
    quantity: 'Quantity',
    total: 'Total',
    nudge: 'Based on past transaction data, a higher offer has a better chance of being accepted.',
    offerApproximation_html: 'Your offer: %{priceDisplay}%{open_tag}%{priceCurrency}%{close_tag} + %{priceSymbol}%{shippingPrice}',
    valueMayVary: 'The equivalent value in %{userCurrency} may vary',
    toolTip_html: "This seller receives offers in %{shopCurrency} %{breakTag}What you'll pay in %{userCurrency} may vary %{breakTag} slightly based on exchange rates.",
  },
  passwordInput: {
    label: 'Password',
    lengthRequirement: '(must be at least 8 characters)',
  },
  bump: {
    reverbBump: 'Reverb Bump',
    bumped: 'Bumped',
    noBid: 'None',
    successBid: 'Bump Saved at %{percent}%',
    successBidDelete: 'Bump Removed',
    errorBid: 'Sorry, there was an error. Please try again.',
    deleteBidBumpV2: 'This item is no longer Bumped. You will not be charged Bump fees on it.',
    bidFetchError: 'There was an issue displaying your Bump tier. Please wait a minute and refresh the page.',
    viewError: 'Bump error',
    adminBump: 'You\'ve been Bumped by Reverb! Your %{percentage}% Bump credit will last through %{expirationDate}.',
    hideSlider: 'Hide Bump Tiers',
    setup: {
      bumpHelpsSell: 'Bump Helps Your Item Sell',
      bumpHelpsSellSubheading: 'It\'s simple, transparent, and risk-free. %{linkOpen}Learn more%{linkClose}.',
      listingOnBiggerStage: 'Put Your Listing on a Bigger Stage',
      premiumPlacement: 'Bump gives your listing premium placement across Reverb.',
      learnMore: 'Learn more',
      explanation: {
        bumpIcon: 'Bump Icon',
        one: '1)',
        stepOne: 'Select a Bump tier based on your price. Higher tiers get more views.',
        two: '2)',
        stepTwo: 'Get seen more often on our most viewed pages, as well as in our emails, on Google Shopping, and on Facebook.',
        three: '3)',
        stepThree: 'Pay only if it sells. Unbump at any time and pay nothing.',
        getViewedMore: 'Get viewed more often on:',
        addToCartSlot: 'Reverb\'s Comparison Shopping Pages',
        reverbSiteEmail: 'Reverb\'s site and emails',
        facebookAds: 'Facebook Product Ads',
        googleShopping: 'Google Shopping',
        body: 'Reverb Bump pushes your Brand New listings to the top of search results and gets you prime placement on Comparison Shopping Pages.%{lineBreak}%{lineBreak}You’re only charged if someone clicks on your listing in a Bump slot and then buys it within 7 days. %{linkOpen}Learn more%{linkClose}.',
        valueProp: 'Comparison Shopping Pages convert twice as well as listing pages. The top position on those pages converts even better.',
        onlyPayUnbumpAnytime: 'You only pay if your item sells. Unbump at any time. %{linkOpen}Learn more%{linkClose}.',
        onlyPaySells: 'You only pay if your item sells. Unbump at any time and pay nothin.',
      },
      bumpChart: 'Bump more to get more views graphic',
      moreViews: 'Bump Views',
      mostPopular: 'Most Popular Tiers',
      recommendedTiers: 'Recommended',
      manyMoreViews: '3x Bump Views',
      selectBumpTier: 'Select a Bump Tier',
      pickBumpTier: 'Select a Bump Tier, get more views',
      example: 'How much will I pay for Bump?',
      bumpChargesExplainedNoShipping: 'If you Bumped your item at %{exampleBumpPercent}% and then it sold for %{listingPrice}, you\'d pay a Bump fee of %{exampleSpend} (on top of our standard 5% selling fee).',
      bumpChargesExplainedWithShipping: 'If you Bumped your item at %{exampleBumpPercent}% and then it sold for %{listingPrice} plus %{shippingPrice} shipping, you\'d pay a Bump fee of %{exampleSpend} (on top of our standard 5% selling fee).',
      listingSells: 'You item sells for %{salesAmount} after you Bumped it at %{exampleBumpPercent}% of your listing price.',
      youBumpedAt: 'You Bumped at %{exampleBumpPercent}% of your listing price.',
      bumpAndSellingFees: 'Your Bump cost is %{additionalChargeAmount} (on top of our standard 5% selling fee).',
    },
    change: {
      currentBid: 'Current Bump Tier: %{bidPercent}%',
      performance: 'Bump Performance',
      currentRate: 'Current Max Bump Tier',
      adjustRate: 'Adjust Bump Tier',
      currentPercent: '%{percent}%',
      numberSold: 'Sold',
      spent: 'Spent',
      sales: 'Sales',
      impressions: 'Bumped Views',
    },
    modal: {
      title: 'Want to Bump This Listing?',
    },
  },
  inlineOffer: {
    makeAnOffer: 'Make an Offer',
    cancel: 'Cancel',
  },
  creditCards: {
    creditCardForm: {
      cancel: 'Cancel',
      confirmDelete: 'Are you sure?',
      yes: 'Yes',
      no: 'No',
      deleteCard: 'Delete Card',
      updateCardTitle: 'Update %{cardType} ...%{lastFour}',
      expirationDate: 'Expiration Date',
      monthPlaceholder: 'MM',
      yearPlaceholder: 'YY',
      cvvPlaceholder: 'CVV',
      cvvLabel: 'CVV',
      cannotDeleteCardHtml: 'Sorry, we can\'t delete your primary billing card. To change your billing card, please visit your %{linkOpen}billing settings page%{linkClose}.',
      cvvTooltip: '3 or 4 digit security code usually located on the back of the card.',
      cardholderNamePlaceholder: 'First and Last name',
      nameOnCard: 'Name on Card',
      cardNumber: 'Card Number',
      cardDetails: 'Card Details',
      saveCard: 'Save Card',
      billingAddress: 'Billing Address',
    },
    creditCardBox: {
      expiration: 'Expires %{month}/%{year}',
      cardTypeAndLastFour: '%{cardType} ...%{lastFour}',
      edit: 'Edit',
      primary: 'Primary',
      makePrimary: 'Make Primary',
    },
    creditCardList: {
      noCardsOnFile: 'You don’t have any credit cards on file.',
    },
  },
  addresses: {
    addressBox: {
      edit: 'Edit',
    },
    addressForm: {
      placeholder: {
        name: 'First and Last name',
        streetAddress: 'Address',
        extendedAddress: 'Apt, Suite, etc. (optional)',
        state: 'Select one...',
        country: 'Country',
        city: 'City',
        zip_code: 'Zip/Postal Code',
        phone: 'Phone Number',
      },
      confirmDelete: 'Are you sure?',
      yes: 'Yes',
      no: 'No',
      editAddress: 'Edit Address',
      name: 'Name',
      address: 'Address',
      extendedAddress: 'Apt, Suite...',
      city: 'City',
      state: 'State/Region',
      zip_code: 'Zip/Postal Code',
      country: 'Country',
      phone: 'Phone',
      saveAddress: 'Save Address',
      saving: 'Saving...',
      cancel: 'Cancel',
      deleteAddress: 'Delete Address',
    },
    addressList: {
      addNewAddress: 'Add a New Address',
      noAddressesOnFile: 'No addresses on file.',
      addAddress: 'Add Address',
    },
  },
  braintree: {
    errors: {
      credit_card_error: 'Sorry, we couldn\'t accept your credit card at this time. Please check for errors and try again.',
    },
  },
  shipping: {
    packageStatus: {
      pre_transit: 'Label Created',
      in_transit: 'In Transit',
      out_for_delivery: 'Out for Delivery',
      delivered: 'Delivered',
      return_to_sender: 'Returned to Sender',
      available_for_pickup: 'Available for Pickup',
      failure: 'Carrier Issue',
      cancelled: 'Shipment Canceled',
      unknown: 'Status Unknown',
    },
  },
  autocomplete: {
    recentSearches: 'Recent Searches',
  },
  asyncLoader: {
    oneMomentPlease: 'One moment please',
  },
  bundledCheckout: {
    streetAddressFormatHint: {
      address: 'Address',
      hint: 'You\'ve entered a street address of only one word and without any numbers. Please ensure that is correct.',
    },
    vaultedPaypalCheckout: {
      paypalButton: {
        defaultError: 'There was a problem. Please try again.',
        checkOut: 'Check out',
      },
    },
    passwordOrGuestCheckoutSelection: {
      preferNotToCreate: 'Prefer not to create an account?',
      switchToGuest: 'Check out as a guest',
      wantAllReverbFeatures: 'Want all the features Reverb has to offer?',
      switchToCreateAccount: 'Create an account',
    },
  },
  countrySelector: {
    all: 'All',
    placeholder: 'Select a Country',
    searchPlaceholder: 'Search...',
  },
  csvImports: {
    pending: 'Pending',
    error: 'An error occurred. Please check again later.',
  },
  dashboard: {
    legacyPaymentSelectForm: {
      newFeature: 'New Feature',
      updatedPaymentMethods: 'Updated Payment Methods',
      upgradeExplainer: 'We\'ve updated Reverb Payments to allow buyers to pay you in more ways. Reach more buyers by updating your Accepted Payment Methods.',
      viewUpgradeOptions: 'View Upgrade Options',
      dontUpgradeHtml: '%{icon} Don\'t Upgrade Yet',
    },
    exportsForm: {
      prompt: 'Export',
      ordersRadio: 'Orders',
      paymentsRadio: 'Payments & Refunds',
      download: 'Download Export',
      sendExportEmail: 'Send Export Email',
      exportTooLarge: 'We\'ll email you a download link for large exports (%{count}+ orders).',
      startDate: 'Start Date',
      endDate: 'End Date',
    },
    shared: {
      orders: {
        sidebar: {
          orderDate: 'Order Date',
          createdAfter: 'Start Date',
          createdBefore: 'End Date',
          shippingMethod: 'Shipping Method',
        },
      },
      searchForm: {
        queryLabel: 'Search',
      },
    },
    buying: {
      orders: {
        searchForm: {
          queryPlaceholder: 'Search purchases',
        },
        sidebar: {
          orderStatus: 'Purchase Status',
          cartSourceType: 'Purchase Source',
        },
        orderFacets: {
          count: {
            one: '{{count}} Purchase',
            other: '{{count}} Purchases',
          },
          countWithFilters: {
            one: '{{count}} Purchase matching:',
            other: '{{count}} Purchases matching:',
          },
        },
        exportForm: {
          orderCount: {
            one: 'Export {{count}} purchase',
            other: 'Export {{count}} purchases',
          },
        },
      },
    },
    selling: {
      bulkSale: {
        sale: 'Sale',
        noSales: 'It looks like there are no upcoming sales.',
        createASale: 'Create a new sale.',
        header: 'Add Listings to a Sale',
        addDisabled: 'Add Listings',
        error: 'There was an error. Please try again.',
        usedOnlyHint: 'Brand New Listings Not Included',
        usedOnlyTooltip: 'Only {{linkStart}}Preferred Sellers{{linkEnd}} can add Brand New listings to Promoted Sales',
        addListings: {
          one: 'Add 1 Listing',
          other: 'Add {{count}} Listings',
        },
        addUsedListings: {
          one: 'Add 1 Used Listing',
          other: 'Add {{count}} Used Listings',
        },
        removeListings: {
          one: 'Remove 1 Listing',
          other: 'Remove {{count}} Listings',
        },
        listingsAdded: {
          one: '1 listing added',
          other: '{{count}} listings added',
        },
        listingsRemoved: {
          one: '1 listing removed',
          other: '{{count}} listings removed',
        },
      },
      bulkBump: {
        buttonText: {
          bumping: 'Bumping...',
          tryAgain: 'Try again',
        },
      },
      resultsCount: {
        countListings: {
          one: '{{num}} listing',
          other: '{{num}} listings',
        },
        countListingsMatching: {
          one: '{{num}} listing matching',
          other: '{{num}} listings matching',
        },
      },
      facets: {
        categories_title: 'Categories',
        filters_group_title: 'Filter Listings',
        error: 'There was an error while loading search options. Check back later!',
        clear: 'clear',
        saleLabel: 'Sale',
        salePlaceholder: 'Select Sale...',
        statusLabel: 'Status',
        conditionLabel: 'Condition',
        brandsLabel: 'Brands',
        listingAgeLabel: 'Listing Age',
        showOnlyLabel: 'Show only',
        bundles: 'Bundles',
        acceptsOffers: 'Accepts Offers',
        onSale: 'On Sale',
        listingRecommendationsLabel: 'Listing Recommendations',
        pricedAboveAverage: 'Priced Above Average',
        availableForPreorder: 'Available for Preorder',
        affirmPromotion: '0% Affirm Promotion',
        directOffersEnabled: 'Direct Offers Enabled',
        pricing_model: 'Type',
        exclusive_channel: {
          seller_sites: 'Reverb Sites Exclusive',
        },
        newFeatureCallOut: 'New',
      },
      searchForm: {
        queryPlaceholder: 'Search listings',
      },
      shopPolicies: {
        howCanBuyersPayYou: 'How can buyers pay you?',
        yourPaymentSettings: 'Your Payment Settings',
        acceptedPaymentMethodsForm: {
          directCheckout: {
            paidToBank: 'Your earnings will be automatically sent to your bank account.',
            firstSalePayOutTimingMessageHtml: 'For your first sale, Reverb initiates a payout following confirmation your item has been delivered. For a more detailed payout timeline, please see the %{linkOpen}Reverb Payments FAQ%{linkClose}.',
          },
        },
        updateYourAccount: 'Update Your Account',
        switchToDirectCheckout: 'Switch to Reverb Payments',
        whyUpgrade: 'Reach more buyers by accepting all forms of payment, receive your funds directly in your bank account, and get lower processing fees than PayPal.',
        dcPaymentMethodsHtml: '%{icon} Accept all forms of payment available to Reverb buyers.',
        dcPayoutsHtml: '%{icon} Regardless of how buyers pay, you get paid via direct deposit.',
        dcFeesHtml: '%{icon} One payment processing fee of %{fee_percent}% + %{flat_amount} for all orders. (%{intl_fee_percent}% for intl. payments)',
        dcCoveredByProtectionHtml: '%{icon} All orders covered by %{linkOpen}Reverb Protection%{linkClose}.',
        paypalFeesEURHtml: '%{icon} PayPal payment processing fee of up to 3.49% + 0.49€ (up to 4.99% for intl. payments).',
        paypalFeesGBPHtml: '%{icon} PayPal payment processing fee of up to 3.49% + 49p (up to 4.99% for intl. payments).',
        paypalFeesHtml: '%{icon} PayPal payment processing fee of 3.49% + 49¢ (up to 4.99% for intl. payments).',
        paypalFeesMayVaryHtml: '*PayPal fees can vary by location. See %{linkOpen}PayPal\'s fees page%{linkClose}',
        paypalPoliciesApplyHtml: '%{icon} PayPal\'s return window and protection policies apply.',
        paypalEmailLabel: 'PayPal Email*',
        getDirectCheckout: 'Get Reverb Payments',
        editBankAccount: 'Edit Bank Account Details',
        contactReverb: 'Your shop currently accepts PayPal. Please contact %{linkOpen}sellerengagement@reverb.com%{linkClose} to set up Reverb Payments.',
        completeSetupCta: {
          title: 'Complete Reverb Payments Setup',
          completeDetails: 'Reverb Payments are active, but you need to complete your Payout Details in order to get earnings transferred to your bank account.',
          buttonText: 'Complete Payment Details',
        },
        shopCurrency: {
          currencyExchangeFeeWarningHtml: 'Your bank currency is %{payoutCurrencyCode}. Using a bank currency that is different from your shop currency will result in an additional 2.5% currency conversion fee charged on the payout amount.',
        },
        bankTransferAmountForm: {
          submitButton: 'Submit Request',
          totalTransferableBalance: 'Total Transferable Balance (%{balance})',
          otherAmount: 'Other Amount',
        },
        directCheckoutProfile: {
          changeAccount: 'Change Reverb Payments Account',
          pleaseDoubleCheck: "Please double-check that you've entered your bank details correctly to ensure we can pay you on time.",
          save: 'Save',
          bankInfo: 'Bank Information',
          storedSecurelyAndEncryptedHtml: '%{icon} Stored securely & encrypted',
          useADifferentAccountHtml: '%{icon} Use a different account',
          nevermind: 'Nevermind, use the account on file',
          weNeedYourBankInfo: 'We\'ll need your bank account info to deposit your earnings. This only allows Reverb to deposit funds into your account, not withdraw them. Please double-check that your info is correct to ensure we can pay you on time.',
          remindMeLaterDescriptionHtml: "If you're not ready to complete this step right now, we'll send you a reminder once your first listing sells. Please note, you will not be able to withdraw any earnings until a Bank Account is linked.",
          whereShouldYourEarningsGo: 'Where should your earnings go?',
          youCanChangeThisSettingHint: '(you can change this anytime)',
          pleaseAcceptTerms: 'Please accept the terms & conditions.',
          payOutToBankAccount: 'Automatically send to Bank Account',
          remindMeLater: 'Remind me later',
          agreeToRemindMeLater: 'I understand',
          connectWithPlaidErrorMessage: 'Please finish connecting your account with Plaid.',
          achPayoutDescription: {
            preferredSellerPayOutTimingMessage: 'Reverb initiates payouts for shipped orders each business day.',
            payOutTimingMessageHtml: 'Reverb initiates your payout after the tracking number indicates the item is in-transit. For a more detailed payout timeline, please see the %{linkOpen}Reverb Payments FAQ%{linkClose}.',
            firstSalePayOutTimingMessageHtml: 'For your first sale, Reverb initiates a payout following confirmation your item has been delivered. For a more detailed payout timeline, please see the %{linkOpen}Reverb Payments FAQ%{linkClose}.',
            setUpForAch: 'Your earnings will be automatically sent to your bank account.',
          },
          bankAccountFields: {
            nameOnAccount: 'Name on account*',
            accountType: 'Account Type*',
            checkingRadio: 'Checking',
            savingsRadio: 'Savings',
            routingNumber: 'Routing number*',
            accountNumber: 'Account number*',
            findBankNumbers: 'See where to find your bank routing & account numbers',
            exampleCheckAlt: 'Example check',
            canada: {
              transitNumber: 'Transit number* {{hint_open}}(5 digits){{hint_close}}',
              institutionNumber: 'Institution number* {{hint_open}}(3 digits){{hint_close}}',
              accountNumber: 'Account number* {{hint_open}}(up to 12){{hint_close}}',
              findBankNumbers: 'See where to find your bank transit, institution, & account numbers',
            },
            countryCodeInput: {
              bankLocation: 'Bank Location',
              change: 'Change',
            },
            currencyInput: {
              label: 'Bank Currency',
              change: 'Change',
            },
            bankTransferWarning: 'I have confirmed my bank accepts direct deposits in %{currencySymbol}%{currencyCode}. If your bank does not accept %{currencySymbol}%{currencyCode}, change your %{strongOpen}Bank Currency%{strongClose} to %{defaultCurrencySymbol}%{defaultCurrencyCode}.',
            currencyExchangeFeeWarningHtml: 'Your shop currency is %{shopCurrencyCode}. Using a bank currency that is different from your shop currency will result in an additional 2.5% currency conversion fee charged on the payout amount. To change your shop currency, %{linkOpen}go to your shop policies%{linkClose}.',
            dashboardCurrencyExchangeFeeWarning: 'Your shop currency is %{shopCurrencyCode}. Using a bank currency that is different from your shop currency will result in an additional 2.5% currency conversion fee charged on the payout amount.',
          },
        },
      },
      shippingLabels: {
        submitButton: {
          reviewRates: 'Review Rates →',
        },
        orderCustoms: {
          customsTitle: 'Customs for International Shipping',
          customsExplainerHtml: 'Customs information will be printed right on your label. You may not ship restricted materials such as Brazilian Rosewood, Ivory, Tortoise Shell, etc. See a full list of restricted materials %{linkOpen}here%{linkClose}.',
          quantity: 'Quantity',
          tariffNumber: 'Tariff Number',
          contentType: 'Content Type',
          merchandise: 'Merchandise',
          certifyTooltipHtml: 'Enter the first and last name of the individual %{lineBreak} certifying the information provided is accurate. %{lineBreak} This is known officially as the "Customs Signer."',
          certifyAccuracy: 'I certify the info provided is accurate',
          none: 'None',
          nonDeliveryOption: 'Nondelivery Option',
          nonDeliveryTooltipHtml: 'The shipment will be returned %{lineBreak} to you if it cannot be delivered.',
          returnToSender: 'Return to sender',
          firstAndLastName: 'Your First and Last Name',
          restrictionType: 'Restriction Type',
          description: 'Description',
          declaredValue: 'Declared Value',
          countryOfOrigin: 'Country of Origin',
          whereItWasMade: '(where this item was made)',
          originCountryTooltip: 'If you do not know, it\'s fine to just use "United States."',
          aboveMaximumAllowedDeclaredValueHtml: 'The maximum declared value for Reverb labels is %{maximumAmount} %{lineBreak} (You\'ve declared %{declaredAmount})',
          customsForOrder: 'Customs info for Order %{orderId}',
        },
        edit: 'Edit',
        package: {
          length: 'Length',
          width: 'Width',
          height: 'Height',
          weightTitle: 'Total Weight',
          weightSubTitle: '(item + packaging)',
          inaccurateDimensionsWarning: 'Inaccurate dimensions may incur additional charges or result in a returned package.',
          tipsToAvoidCorrections: 'Tips to Avoid Shipping Charge Corrections.',
        },
        multiOrderSelect: {
          selectOrdersHeading: 'Select additional unshipped orders from %{buyerName} you\'d like to ship in the same package',
          weEmailBuyerAboutPackage: 'We\'ll send the buyer tracking info and let them know the orders will arrive in the same package.',
          clearedForShipment: 'Cleared for Shipment',
          orderLink: 'Order %{orderId}',
          orderedOnDate: 'Ordered %{date}',
          buyerName: 'Buyer: %{name}',
          selectAll: 'Select All',
          deselectAll: 'Deselect All',
        },
        details: {
          shipmentDetailsTitle: 'Shipment Details',
          shipmentOptionsTitle: 'Shipment Options',
        },
        mediaMail: {
          title: 'Package qualifies for media mail rates',
          tooltipHtml: 'Must be educational media, e.g. books,%{lineBreak} manuals, guides, printed music, vinyl, %{lineBreak}CDs, or DVDs. Read more %{linkOpen}here.%{linkClose}',
        },
        insurance: {
          insuranceInfo: 'Protect yourself if your package is lost or damaged during shipment for only %{insuranceCost}. Must add Signature Required.',
          shippingProtectionTitle: 'Reverb Safe Shipping',
          cannotInsureHighValuePackageHtml: 'Only available on items valued under $10,000. %{linkOpen}Learn More%{linkClose}',
          requiredInsuranceHtml: 'Required on orders over $1,500. %{linkOpen}Learn More%{linkClose}',
        },
        signatureRequired: {
          tooltip: 'Not applicable when shipping to a PO Box',
          signatureRequiredTitle: 'Signature Required',
          requiredExplanationHtml: 'Required for Reverb Safe Shipping and %{linkOpen}Seller Protection%{linkClose} to apply.',
          optionalExplanationHtml: 'Required to qualify for %{linkOpen}Seller Protection%{linkClose}.',
        },
      },
      orders: {
        sidebar: {
          orderStatus: 'Order Status',
          cartSourceType: 'Order Source',
        },
        searchForm: {
          queryPlaceholder: 'Search orders',
        },
        orderFacets: {
          count: {
            one: '{{count}} Order',
            other: '{{count}} Orders',
          },
          countWithFilters: {
            one: '{{count}} Order matching:',
            other: '{{count}} Orders matching:',
          },
        },
        exportForm: {
          orderCount: {
            one: 'Export {{count}} order',
            other: 'Export {{count}} orders',
          },
        },
        filteredOrderTotal: 'Filtered Order Total',
      },
      reports: {
        priceDropListings: {
          title: 'Not the Lowest Price',
          countDescription: '%{strongOpen}{{count}} of your listings%{strongClose} are priced higher than similar listings',
          reportButton: 'View Listings',
          error: 'Unable to fetch listings. Please try again.',
          details: {
            title: '{{count}} Live Listings are not the lowest price',
            condition: 'Condition: {{condition}}',
            sku: 'SKU: {{sku}}',
            returnToReports: 'Back to Reports',
            difference: 'Largest Difference First',
            dropDownPriceAsc: 'Price Low to High',
            dropDownPriceDesc: 'Price High to Low',
            price: 'Price: %{price}',
            sellerCost: 'Your Cost: %{cost}',
            competitorRange: 'Competitors %{lowestPrice} - %{highestPrice}',
            priceDiffDescription: '%{percentageDifference}% higher than the lowest price',
            edit: 'Update Price',
            error: 'There was an error loading your report, please try again later.',
            empty: 'You have no listings to review at this time',
            save: 'Save',
            sortLabel: 'Sort',
            goToCsp: 'View all listings',
            priceRange: 'Price Range',
            updateError: 'There was an error updating your listing',
            tableHeadings: {
              details: 'Details',
              yourPrice: 'Your Price',
              lowestPrice: 'Lowest Price',
              priceDifference: 'Price Difference',
              percentDifference: 'Percent Difference',
            },
            conditions: {
              title: 'Conditions',
              all: 'All',
              new: 'Only New',
              used: 'Only Used',
            },
          },
        },
      },
    },
    messages: {
      markAsReadTooltip: 'Mark as Read',
      markAsUnreadTooltip: 'Mark as Unread',
      unreadFilterLabel: 'Unread (%{count})',
    },
  },
  giftCardOptions: {
    valueLabel: 'Choose Card Value',
    designLabel: 'Choose Card Design',
    cardValue: 'Value',
    redeemCode: 'Redeem Code',
    redeemCodePlaceholder: 'XXXX-XXXX-XXXX-XXXX',
  },
  helpDropdown: {
    chatLive: 'Chat Live',
    submitHelpRequest: 'Submit Help Request',
    faqs: 'Help Center',
    trackOrder: 'Track Order',
    reverbProtection: 'Reverb Protection',
  },
  myFeed: 'My Feed',
  transactionHistory: {
    signUpToSee: 'Sign up for free to see recent transactions',
    chartLoader: 'Price History Chart',
    chartDescription: 'Prices reflect monthly average for sales of item in used condition. Prices exclude shipping and tax/VAT/GST.',
    emptyMessage: 'Sorry, there are not enough sales to display a price history chart.',
    noRecords: 'Sorry, no transaction history found!',
    used: 'Used',
    new: 'New',
    next: 'Next',
    prev: 'Previous',
    chartHeader: 'Price History Chart',
    error: 'There was an error while loading history for this item. Check back later!',
    tableHeader: 'Transaction History',
    date: 'Date',
    source: 'Source',
    condition: 'Condition',
    soldPrice: 'Sold Price',
    loading: 'Loading...',
    priceRangeHeader: 'Reverb Estimated Used Price Range',
    excludesVat: 'Prices exclude shipping and tax/VAT/GST.',
  },
  shared: {
    shareInput: {
      copyURL: 'Copy URL',
      shortlinkLabel: 'Shortlink',
      shortlinkTip: 'Here\'s the shortlink for this product. Use it to share with friends.',
    },
    priceRange: {
      label: 'Price',
      minPlaceholder: 'Min',
      maxPlaceholder: 'Max',
    },
    attachinary: {
      remove: 'Remove',
      edit: 'Edit',
      dropbox_ie_message_html: '%{spanOpen}Double click here%{spanClose} to add photos.',
      dropbox_firefox_message_html: '%{spanOpen}Click here%{spanClose} to add photos.',
      drop_your_photo_here: {
        one: 'Drop your photo here',
        other: 'Drop your photos here',
      },
      no_drag_drop_message_html: 'If you\'re having problems dropping an image here, please %{spanOpen}click here%{spanClose} to upload files or try another browser.',
      uploading: 'Uploading...',
    },
  },
  pageSearchBar: {
    articlePlaceholder: 'Search for articles ...',
    submit: 'Search',
  },
  searchBar: {
    placeholders: {
      listings: 'Find used & new music gear...',
      news: 'Search for articles...',
      priceGuides: 'Search for pricing info...',
      shops: 'Search shop names...',
    },
    context: {
      listings: 'Gear',
      news: 'Articles',
      priceGuides: 'Price Guide',
      shops: 'Shops',
    },
  },
  errorMessages: {
    default: 'An error occurred. Please check again later.',
  },
  shopPolicies: {
    shopCurrency: {
      heading: 'Your shop currency is %{currencySymbol}%{currencyCode}',
      sellingFeesDescription: 'The currency you list items in.',
      sellingFeesDescriptionWithCurrencyConversion: 'The currency you list items in. Your fees will be based on the %{currencySymbol}%{currencyCode} item price converted to $USD at the time of sale.',
      currencyLabel: 'Shop Currency',
      selectAnOption: 'Select an option',
      currencyChange: {
        listingsWillUpdateHtml: 'If you save this change, we will update your listings and shipping profiles from %{strongTagOpen}%{oldCurrencyCode}%{strongTagClose} to %{strongTagOpen}%{newCurrencyCode}%{strongTagClose}. It may take up to 60 minutes for these changes to become visible on the site.',
        offersWillRemainHtml: 'All existing offers and counter-offers will remain in %{strongTagOpen}%{oldCurrencyCode}%{strongTagClose}. New offers made after the changes are complete will be in %{strongTagOpen}%{newCurrencyCode}%{strongTagClose}.',
        payoutConversionFee: 'Listing in a currency different from your bank currency will result in an additional 2.5% currency conversion fee charged on payouts.',

      },
    },
    directCheckoutSetup: {
      title: 'Reverb Payments Setup',
      subheading: 'We\'ll need your bank account information to direct deposit your earnings.',
      firstOrderPayoutHtml: '%{strongOpen}Note:%{strongClose} %{lineBreak} For your first sale, Reverb initiates your payout following delivery confirmation of the item. For a more detailed payout timeline, please see the %{linkOpen}Reverb Payments FAQ%{linkClose}.',
      deferBankSetupText: 'After your listing is published add your %{linkOpen}bank account%{linkClose} via Shop Settings so you can get paid.',
    },
  },
  previousOrders: {
    next: 'Next',
    previous: 'Previous',
    totalSellerText: {
      one: '{{count}} Order from you',
      other: '{{count}} Orders from you',
    },
    totalBuyerText: {
      one: 'You\'ve bought {{count}} item from this shop',
      other: 'You\'ve bought {{count}} items from this shop',
    },
    totalAmountBought: 'Total: {{amount}}',
  },
  productReviews: {
    reviewsFor: 'Reviews for {{productTitle}}',
    loadingError: 'Sorry! We are having trouble loading reviews right now',
    createSuccessActivated: 'Thank you for your review!',
    createSuccessNotActivated: 'Thank you for your review! Your review will appear once you confirm your account.',
    verified: 'Verified purchase',
    reviewBody: 'Your review...',
    reviewTitle: 'Review Title (optional)',
    reviewSubmit: 'Post Review',
    reviewUpdate: 'Update Review',
    edit: 'Edit',
    writeAReview: 'Write a Review',
    reviewNudge: 'Help another musician by sharing your experience',
    reviewTips: 'Review Tips',
    reviewGuidelines: '(see all)',
    DoLabel: 'Do:',
    DontLabel: 'Don\'t:',
    DoMessage: 'Talk about how it sounds, mention pros and cons, and compare it to other products.',
    DontMessage: 'Review a seller, your shipping experience, or include offensive content.',
    noReviewsYet: 'No Reviews Yet',
    firstToWriteReview: 'Be the first to write a review.',
    helpAnotherMusician: 'Help another musician by sharing your experience!',
    sellerReviewWarning: 'Oops. It looks like you might be reviewing a seller or shopping experience. However, this is your chance to review the product itself.',
    yourRating: 'Your Rating',
    cancel: 'Cancel',
    rating: {
      one: '{{count}} star',
      other: '{{count}} stars',
    },
  },
  productReview: {
    ratingError: 'Please provide a rating',
    bodyError: 'Please provide a body for your review',
  },
  productSearch: {
    categories: 'Categories',
    refineResults: 'Refine Results',
  },
  emailSignup: {
    close: 'Done!',
    gdprTerms: 'By clicking %{buttonText}, I agree to receive exclusive offers & promotions, news & reviews, and personalized tips for buying and selling on Reverb.',
    default: {
      title: 'Get The Best of Reverb In your Inbox',
      message: 'By clicking subscribe, I agree to receive exclusive offers & promotions, news & reviews, and personalized tips for buying and selling on Reverb.',
    },
    submitted: {
      title: 'Thanks for signing up!',
      message: 'Stay tuned for the latest from Reverb.',
    },
    error: {
      title: 'Oops!',
      message: 'Something went wrong. We\'re sorry for the inconvenience – please try again later.',
    },
    invalid: {
      title: 'Invalid email address',
      message: 'Please make sure you entered your email correctly and try submitting again.',
    },
    emailTooLong: {
      title: 'Invalid email address',
      message: 'That email address is too long. Please enter a valid email address.',
    },
    inputEmailPlaceholder: 'Your email',
    subscribeButton: 'Subscribe',
  },
  feedback: {
    error: {
      message: 'There was an error saving your feedback. Please try again.',
    },
  },
  shipOrderViews: {
    header: {
      pageTitle: 'Ship Order #{{orderId}}',
      orderNumber: 'Order #{{orderId}}',
      buyerName: 'Buyer: {{name}}',
      orderedDate: 'Ordered {{date}}',
      visitShippingGuide: 'Need some shipping tips? Visit our %{linkOpen}shipping guide.%{linkClose}',
    },
  },
  manualShippingInfoForm: {
    providerError: 'Please enter a provider name',
    codeError: 'Please enter a tracking number',
    invalidCode: 'That doesn\'t match the {{provider}} format.',
    barcodeError: 'That looks like a full {{provider}} barcode. Your tracking code should be the last {{lastNDigits}} digits. Did you mean {{suggested}}?',
    addTrackingInfo: 'Add Tracking Info',
    editTrackingInfo: 'Edit Tracking Info',
    addTrackingDetails: 'Please enter the shipping provider and tracking number and we\'ll notify the buyer.',
    shippingProvider: 'Shipping Provider',
    trackingNumber: 'Tracking Number',
    shippingCode: 'Type or paste your tracking # here',
    sendNotification: 'Send tracking information to buyer',
    addTracking: 'Add Tracking',
    enterName: 'Please enter the name',
    providerName: 'Carrier Name',
    noCode: 'I don\'t have a tracking number',
  },
  trackOrderForm: {
    providerError: 'Please enter a provider name',
    codeError: 'Please enter a tracking number',
    invalidCode: 'That doesn\'t match the {{provider}} format.',
    barcodeError: 'That looks like a full {{provider}} barcode. Your tracking code should be the last {{lastNDigits}} digits. Did you mean {{suggested}}?',
    addTrackingInfo: 'Add Tracking Information',
    editTrackingInfo: 'Edit Tracking Info',
    addTrackingDetails: 'Please enter the shipping provider and tracking number and we\'ll notify the buyer.',
    shippingProvider: 'Shipping Provider',
    trackingNumber: 'Tracking Number',
    shippingCode: 'Type or paste your tracking # here',
    sendNotification: 'Send tracking information to buyer',
    addTracking: 'Add Tracking',
    enterName: 'Please enter the name',
    providerName: 'Carrier Name',
    noCode: 'I don\'t have a tracking number',
    toAddress: 'To Address',
    buyLabel: {
      reverb: {
        header: 'Save money on shipping with a Reverb Shipping Label',
        saveMoney: 'Save up to 50% from carriers including USPS, UPS, and FedEx.',
        saveTime: "Purchase and print labels from home. We'll automatically add tracking information and notify your buyer.",
        protectShipment: "Using a Reverb label gives you the option to choose Reverb Safe Shipping for a fraction of your gear's sale price.",
        actionText: 'Get a Reverb Shipping Label',
      },
      packlink: {
        header: 'Save money on a shipping label with Packlink Pro',
        saveMoney: 'Save up to 50% on national shipments and up to 70% on international shipments.',
        saveTime: 'Compare carriers, purchase and print labels, and schedule a pickup from your address—without leaving home.',
        protectShipment: 'Add Packlink Insurance to ensure peace of mind.',
        actionText: 'Buy a Shipping Label',
      },
      saveMoneyLabel: 'Save Money',
      saveMoneyAlt: 'Money Icon',
      saveTimeLabel: 'Save Time',
      saveTimeAlt: 'Clock Icon',
      protectShipmentLabel: 'Protect Your Gear',
      protectShipmentAlt: 'Shield Icon',
      easeOfUse: 'Purchase and print labels from home. We\'ll automatically add tracking information to the order and notify the buyer.',
      learnMore: 'Learn More',
    },
  },
  gearStories: {
    readMore: 'Read more',
    readLess: 'Read less',
  },
  notificationBadges: {
    noNotifications: 'No Notifications',
    allCaughtUp: 'You\'re all caught up!',
    notifications: 'Notifications',
  },
  headerCart: {
    badgeLabel: 'Cart',
    viewCart: 'View Cart',
    credits: 'Reverb Credits:',
    bucks: 'Reverb Bucks:',
    reverbBucksWithIconHtml: '%{icon} Reverb Bucks:',
    saleReminderText: '{{percentage}}% off until {{date}} at {{time}}',
  },
  productDetails: {
    sku: 'SKU: {{sku}}',
    condition: '{{condition}} Condition',
    sellerCost: 'Your cost {{cost}}',
    listPriceWithTaxHint: 'List Price: {{amount}} {{currencyCode}} ({{hint}})',
    askingPrice: 'Asking Price: {{amount}}',
  },
  readMore: {
    readMore: 'Read more...',
    readLess: 'Read less',
  },
  watchList: {
    itemAdded: 'Added to your {{link_open}}Watch List{{link_close}}!',
    itemRemoved: 'Removed from your Watch List.',
  },
  listing: {
    shipping: {
      price: '%{price} Shipping',
      free: 'Free Shipping',
    },
  },
  addToCart: {
    button: {
      add: 'Add to Cart',
      adding: 'Adding to Cart...',
      preorder: 'Pre-Order',
    },
    sellerBlockMessage: 'Woah! You can\'t buy your own listing.',
  },
  watchButton: {
    button: {
      watch: 'Watch',
      watching: 'Watching',
    },
    sellerBlockMessage: 'Woah! You can\'t watch your own listing.',
    benefits: {
      signUp: 'Sign Up to Watch This Listing',
      priceDrop: 'We\'ll notify you if the price drops.',
      offers: 'We\'ll notify you if anyone else makes an offer.',
    },
    watchForPriceDrop: 'Watch this listing to get price drop alerts',
  },
  editorToolbar: {
    bold: {
      label: 'Bold',
    },
    italic: {
      label: 'Italic',
    },
    underline: {
      label: 'Underline',
    },
    list: {
      label: 'List',
      title: 'Unordered List',
    },
  },
  articles: {
    relatedArticles: {
      title: 'Read More from Reverb News',
    },
  },
  conversations: {
    form: {
      attach_images: 'Attach images',
      attach_listings: 'Attach listings',
      body: {
        placeholder: 'Write your message...',
      },
    },
  },
  navLinks: {
    artists: 'Artists',
    browseByCategory: 'Browse By Category',
    feedFull: 'My Feed',
    needHelp: 'Need Help?',
    newsFull: 'Reverb News',
    priceGuide: 'Price Guide',
    reverbGives: 'Reverb Gives',
    sellFull: 'Sell Your Gear',
    shops: 'Shops',
    holiday: 'Holiday Sales',
  },
  autoProductBundles: {
    header: 'Buy Together & Save',
    total: 'Total Price: {{totalPrice}}',
    addBothToCart: 'Add Both to Cart',
  },
  orderNotes: {
    loading: 'Loading...',
    label: {
      zero: 'Add Note',
      one: 'Notes ({{count}})',
      other: 'Notes ({{count}})',
    },
    submit: 'Save New Note',
    hint: 'Only visible to you',
    confirmDelete: 'Are you sure you want to delete this note?',
  },
  aprilFoolsModal: {
    title: 'April Fools!',
    subtext: 'Even though Left-Handed Reverb isn\'t real, we do have thousands of guitars, basses, and more tailor-made for southpaws.',
    browseLeftyGuitars: 'Browse Left-Handed Electric Guitars',
    backToReverb: 'Back to Reverb',
  },
  required: 'Required',
  optional: 'Optional',
  adaChat: {
    buttonText: 'Need help?',
  },

  // Admin-only
  locales: {
    en: 'English',
    de: 'German',
    es: 'Spanish',
    fr: 'French',
    nl: 'Dutch',
    ja: 'Japanese',
    it: 'Italian',
  },
  localizedContents: {
    display_title: 'Display Title' +
      ' (This will override the default page title. Leave blank to use the default title)',
    description: 'Description',
    image_overlay_title: 'Image Overlay Title',
    image_overlay_subtitle: 'Image Overlay Subtitle',
    meta_description: 'Meta Description',
    meta_keywords: 'Meta Keywords',
    meta_title: 'Meta Title',
    title: 'Title',
    body: 'Body',
    summary: 'Summary',
    heading: 'Heading',
    subheading: 'Subheading',
    call_to_action: 'Call to Action',
    column_left: 'Column Left',
    column_right: 'Column Right',
    terms: 'Terms and Conditions',
    published_at: 'Published At',
    question: 'Question',
    search_terms: 'Search Terms',
    model: 'Model',
  },
  localePicker: {
    label: 'Locale',
  },
  listings: {
    dontMissOut: 'Act Fast!',
    peopleHaveInCarts: {
      one: '1 other person has this in their cart',
      other: '{{count}} other people have this in their carts',
    },
  },
};

export const translations = {
  number,
  js,
  acceptedPaymentMethods,
  countries,
  subRegions,
  currencies,
  digitalListing,
  digitalListingCard,
  digitalProductsIndex,
  emailOffers,
  priceGuide,
  productShowPage,
  shippingRates,
  shopStats,
  sellerHub,
};

export default translations;
