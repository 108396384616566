import React from 'react';
import uuid from 'uuid/v4';
import { PAYPAL_CLIENT_ID } from '@reverbdotcom/env';
import { PayPalScriptProvider, PayPalButtons } from '@paypal/react-paypal-js';
import { OnApproveData } from '@paypal/paypal-js';
import I18n from 'i18n-js';
import {
  core_apimessages_Error as Error,
  core_apimessages_StartPaypalCheckoutRequest_PaymentMethodType as PayPalPaymentMethodType,
  core_apimessages_CheckoutPaymentMethod_Type as CheckoutPaymentMethodType,
} from '@reverbdotcom/commons/src/gql/graphql';
import Location from '../../lib/wrapped_location';
import { MParticleEventName } from '@reverbdotcom/commons/src/elog/mparticle_tracker';
import { useEventTracker } from '../trackPayPalEvent';
import { buildCheckoutPaths } from '../../lib/checkoutPaths';
import { gql } from '@reverbdotcom/commons/src/gql';
import { useMutation } from '@reverbdotcom/commons/src/useMutation';

interface Props {
  currency: string;
  quantity?: number;
  checkoutBundlingId: string;
  onError: (errors: Error[]) => void;
  parentComponentName: string;
  amountCents: number;
  showPaypalPayLaterButton: boolean;
  payLaterOnly?: boolean;
  small?: boolean;
  paymentMethod?: CheckoutPaymentMethodType;
  onClick?: (paymentMethodType: CheckoutPaymentMethodType) => void;
  protectionPlanId?: string;
}

/** Must correspond to --rc-min-height-button-medium */
const MEDIUM_BUTTON_HEIGHT = 48;

/** Must correspond to --rc-min-height-button-small */
const SMALL_BUTTON_HEIGHT = 40;

const PAYPAL_PAYMENT_METHOD_TYPE = {
  'PAYPAL': PayPalPaymentMethodType.PAYPAL,
  'DC_VIA_PAYPAL': PayPalPaymentMethodType.DC_VIA_PAYPAL,
};

export function ExpressPayPalButtons({
  parentComponentName,
  currency,
  checkoutBundlingId,
  onError,
  quantity = null,
  amountCents,
  showPaypalPayLaterButton = false,
  payLaterOnly = false,
  small = false,
  paymentMethod = CheckoutPaymentMethodType.DC_VIA_PAYPAL,
  protectionPlanId = null,
  onClick = () => { },
}: Props) {
  const [startPaypalCheckout] = useMutation(START_PAYPAL_CHECKOUT, {
    onError,
  });
  const checkoutRef = React.useRef('');
  const protectionPlanIdRef = React.useRef(null);
  protectionPlanIdRef.current = protectionPlanId;

  const paymentMethodType = PAYPAL_PAYMENT_METHOD_TYPE[paymentMethod] || PayPalPaymentMethodType.DC_VIA_PAYPAL;

  const trackPaypalEvent = useEventTracker({
    listingCurrency: currency,
    parentComponentName,
    currency,
    quantity,
    amountCents,
  });

  function height() {
    if (small) {
      return SMALL_BUTTON_HEIGHT;
    }

    return MEDIUM_BUTTON_HEIGHT;
  }

  function onButtonClick({ fundingSource }: Record<string, any>) {
    if (fundingSource === 'paylater') {
      trackPaypalEvent(MParticleEventName.ClickedPaypalPayLaterButton);
      onClick(CheckoutPaymentMethodType.PAYPAL_PAY_LATER);
    } else {
      trackPaypalEvent(MParticleEventName.ClickedExpressPaypalButton);
      onClick(paymentMethodType);
    }
  }

  async function createOrder() {
    const result = await startPaypalCheckout({
      variables: {
        input: {
          paymentMethodType,
          checkoutBundlingId,
          quantity,
          protectionPlanId: protectionPlanIdRef.current,
        },
      },
    });

    if (result?.data?.startPaypalCheckout.paypalOrderId) {
      checkoutRef.current = result.data.startPaypalCheckout.checkout?.id;
    }

    return result?.data?.startPaypalCheckout.paypalOrderId;
  }

  async function onApprove(data: OnApproveData) {
    trackPaypalEvent(MParticleEventName.ApprovedExpressPaypalPayment);

    const checkoutPaths = buildCheckoutPaths(checkoutRef.current);

    await Location.assign(checkoutPaths.review({ token: data.orderID }));
  }

  function onPaypalError() {
    const error = { message: I18n.t('discovery.checkout.paypal.error') };
    onError([error]);
  }

  const options = {
    currency,
    clientId: PAYPAL_CLIENT_ID,
    intent: 'capture',
    dataNamespace: `${parentComponentName}-${checkoutBundlingId}`,
    components: ['buttons', 'funding-eligibility'],
    commit: false,
    dataUid: uuid(),
  };

  return (
    <PayPalScriptProvider options={options}>
      {!payLaterOnly &&
        <div className="express-paypal-button">
          <PayPalButtons
            fundingSource="paypal"
            createOrder={createOrder}
            onApprove={onApprove}
            onError={onPaypalError}
            style={{
              shape: 'pill',
              height: height(),
              layout: 'horizontal',
              tagline: false,
              color: 'gold',
            }}
            onClick={onButtonClick}
          />
        </div>
      }
      {showPaypalPayLaterButton &&
        <div className="express-paypal-button">
          <PayPalButtons
            fundingSource="paylater"
            createOrder={createOrder}
            onApprove={onApprove}
            onError={onPaypalError}
            style={{
              shape: 'pill',
              height: height(),
              layout: 'horizontal',
              tagline: false,
              color: 'gold',
            }}
            onClick={onButtonClick}
          />
        </div>
      }
    </PayPalScriptProvider>
  );
}

const START_PAYPAL_CHECKOUT = gql(`
  mutation Core_Checkout_StartPaypalCheckout(
    $input: Input_core_apimessages_StartPaypalCheckoutRequest
  ) {
    startPaypalCheckout(input: $input) {
      checkout {
        _id
        id
      }
      paypalOrderId
    }
  }
`);
