import React from 'react';
import I18n from 'i18n-js';
import classNames from 'classnames';

interface IProps {
  label: string | JSX.Element | React.ReactNode;
  inputName?: string;
  errorText?: string;
  helpText?: string | JSX.Element | React.ReactNode;
  tagRequired?: boolean;
  tagOptional?: boolean;
  tagRecommended?: boolean;
  large?: boolean;
}

/**
 * Wraps child component to provide a label and display errors for
 * a field
 */
export default class FormGroup extends React.Component<IProps, null> {
  getTagText() {
    if (this.props.tagOptional) {
      return I18n.t('commons.formGroup.optional');
    }

    if (this.props.tagRecommended) {
      return I18n.t('commons.formGroup.recommended');
    }

    if (this.props.tagRequired) {
      return I18n.t('commons.formGroup.required');
    }
  }

  renderTag() {
    if (!this.getTagText()) return;

    return (
      <div className="form-group__tag">
        {this.getTagText()}
      </div>
    );
  }

  renderError() {
    if (!this.props.errorText) return;

    return (
      <div className="form-group__error size-80 mt-1">
        {this.props.errorText}
      </div>
    );
  }

  renderHelpText() {
    return (
      <div className="form-group__help-text">
        {this.props.helpText}
      </div>
    );
  }

  render() {
    const classes = classNames(
      'form-group',
      { 'form-group--with-error': !!this.props.errorText },
      { 'form-group--large': this.props.large },
    );

    return (
      <div className={classes}>
        <div className="form-group__header">
          <label
            htmlFor={this.props.inputName || null}
            className="form-group__label-text"
          >
            {this.props.label}
          </label>
          {this.renderTag()}
        </div>
        {!!this.props.helpText && !!this.props.large && this.renderHelpText()}
        <div className="form-group__fields">
          {this.props.children}
          {this.renderError()}
        </div>
        {!!this.props.helpText && !this.props.large && this.renderHelpText()}
      </div>
    );
  }
}
