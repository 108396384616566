import { IUser } from '@reverbdotcom/commons/src/components/user_context_provider';
import {
  reverb_search_ListingsSearchRequest as ListingsSearchRequest,
} from './gql/graphql';
import { isNonUSUser } from '@reverbdotcom/commons/src/user_context_helpers';

export function localizeItemRegionFilter(itemRegionQueryParam, user: Partial<IUser>): ListingsSearchRequest {
  if (itemRegionQueryParam) {
    return {
      itemRegion: itemRegionQueryParam,
    };
  }

  if (applyProximityBoost(user)) {
    return {
      itemRegion: 'XX',
    };
  }

  return {
    itemRegion: user.itemRegionOverride,
  };
}

export function applyProximityBoost(user: Partial<IUser>) {
  return !!user?.countryCode && isNonUSUser(user);
}
