import { get as cookieGet } from 'js-cookie';

export function authHeader() {
  const token = authBearer();

  if (!token) return {};
  return { Authorization: token };
}

function authBearer() {
  const tokenV2 = cookieGet('reverb_user_oauth_token_v2');
  const token = cookieGet('reverb_user_oauth_token');

  if (!token && !tokenV2) return;
  if (tokenV2) return `BearerV2 ${tokenV2}`;

  return `Bearer ${token}`;
}
