import { debounce, filter } from 'lodash';
import { UI } from './legacy/ui';
import isValidPassword from './shared/password_policy';

// Rails ujs library is confusing as it handles re-enabling
// form elements different than it does other elements (e.g. links)
// In fact, for form elements there is no function for enabling a single
// element; there is one function for enabling all disabled inputs from
// a form
function enableElement(element) {
  if (element.is(':input') && element.closest('form').length) {
    $.rails.enableFormElements(element.closest('form'));
  } else {
    $.rails.enableElement(element);
    element.prop('disabled', false);
  }
  element.removeClass('btn-disabled');
}

function disableElement(element) {
  $.rails.disableFormElement(element);
}

// jquery-ujs will disable submit buttons that are labeled with data-please-wait,
// or in Rails with disable_with: "Please wait" style elements. However, our custom
// form validation may fail, and the form will not submit. This will re-enable the
// button after a short period of time (500ms) so that the user can re-submit the form.
// We have to wait the 500ms to make sure that jquery-ujs has enough time to disable
// the button, since it may (and does) run after our validations.
function reEnableDisabledFormSubmit(form) {
  setTimeout(() => {
    form.find('[data-disable-with]').each(function () {
      enableElement($(this));
    });
    // our dialogs don't use submit inputs, they use links that are outside the form element
    form.parents('.dialog').find('[data-disable-with]').each(function () {
      enableElement($(this));
    });
  }, 500);
}

function scrollToError() {
  const offsetFromTop = 130;
  const firstError = $('.ui-state-error:visible').first();
  const scrollTo = firstError.offset().top - offsetFromTop;
  $('html, body').animate({ scrollTop: scrollTo }, 500);
}

function displayValidationErrors(form) {
  const submitButton = form.find('input[type=submit]');
  const errorNotice = $('#form-error-notice');

  reEnableDisabledFormSubmit(form);

  // If we're not in a modal, show a flash message and scroll to the top of the page
  if (form.parents('.dialog').length === 0 && form.parents('.dropdown-module').length === 0) {
    submitButton.after(errorNotice);
    errorNotice.hide().fadeIn('fast');
    scrollToError();
  } else {
    errorNotice.fadeOut('fast');
  }
}

// Not all fields should have the errore class applied directly to it.
// This function returns the appropriate field based on the element in question
function errorElementFromField(fieldSelector) {
  const $field = $(fieldSelector);

  if ($field.parent().hasClass('styled-dropdown')) {
    return $field.parent();
  }

  if ($field.hasClass('input-group__input') && $field.parent().hasClass('input-group')) {
    return $field.parent('.input-group');
  }

  if ($field.siblings('.chzn-container').length) {
    return $field.siblings('.chzn-container');
  }

  if ($field.siblings('.wysihtml5-sandbox').length) {
    return $field.siblings('.wysihtml5-sandbox');
  }

  return $field;
}

function addErrorUi(fieldSelector) {
  errorElementFromField(fieldSelector).addClass('ui-state-error');
}

function removeErrorUi(fieldSelector) {
  errorElementFromField(fieldSelector).removeClass('ui-state-error');
}

// Returns value of input element.
// Like $.val, but correctly handles checkboxes (returns null when not checked)
function value($input) {
  if ($input.attr('type') === 'checkbox') {
    if ($input.is(':checked')) {
      return $input.val();
    }

    return null;
  }

  return $input.val();
}

function validateFields(form, fields) {
  let formIsValid = true;
  let fieldValue;

  form.find('.ui-state-error').removeClass('ui-state-error');

  fields.each((_i, field) => {
    // reset valid field bool at each new loop iteration
    let fieldIsValid = true;

    fieldValue = $.trim(value($(field))) || '';

    if (field.pattern) {
      const regexPattern = new RegExp(field.pattern);
      if (!regexPattern.test(fieldValue)) {
        fieldIsValid = false;
        formIsValid = false;
      }
    }

    if (fieldValue === '') {
      fieldIsValid = false;
      formIsValid = false;
    }

    if (!fieldIsValid) {
      addErrorUi(field);
    } else {
      removeErrorUi(field);
    }
  });

  return formIsValid;
}

// Technically we only need to select for the [required] attribute, but for backwards
// compatibility, we are also looking for the required class
function requiredFields(form) {
  return form.find(':input[name].required:visible,' +
    ':input[name][required]:visible,' +
    ':input[data-force-validation]').filter(':enabled')
    .filter((i, el) =>
      // these elements are in a div with max-height: 0 and match
      // the ':visible' selector, but should not be considered visible
      !$(el).closest('.global-slidedown--closed').length);
}

function validateRequiredFields(form) {
  return validateFields(form, requiredFields(form));
}

function handleRequiredFields() {
  const errorNotice = $('#form-error-notice');
  errorNotice.hide();

  $(document).on('submit', 'form', function (e) {
    const form = $(this);

    if (form.data('disable-auto-validation')) { return; }

    if (!validateRequiredFields(form)) {
      e.preventDefault();
      e.stopPropagation();

      displayValidationErrors(form, errorNotice);
    }
  });

  $('form input').change(() => {
    errorNotice.fadeOut('fast');
  });
}

// turns on a checkmark when the user's password matches password policy
function validatePassword() {
  $(document).on('keyup', '.password-validation input[type=password]', function () {
    const valid = isValidPassword($(this).val());
    $(this).next('.password-validation__flag').toggleClass('active', valid);
  });
}

function handleDisabled() {
  $(document).on('click', '[data-disable-with]', function () {
    $(this).addClass('btn-disabled');
    if ($(this).data('disable-element-on-click') == true) {
      $(this).prop('disabled', true);
    }
  });
}

function showSuccessMessage(form) {
  let msg = form.data('success-message');

  // try to find the message on the form
  if (!msg) { msg = form.find('input[data-success-message]').data('success-message'); }

  if (msg) {
    UI.flashSuccess(msg);
  } else {
    // See if there is an inline success element
    form
      .find('[data-success]')
      .fadeIn()
      .delay(2000)
      .fadeOut();
  }
}

/* Delete all dynamic errors from page */
/* @param prefix [String] form element prefix */
function deleteErrors() {
  $('div.form-error').remove();
}

/* Render dynamic errors from ajax request */
/* @param error [Array] collection of errors */
/* @param prefix [String] form element prefix */
function renderErrors(errors) {
  deleteErrors();
  // eslint-disable-next-line
  for (let i in errors) {
    const html = `<div class='form-error'>${errors[i]}</div>`;

    // Find the element with the name. For typical forms,
    // we have something like product[price] but the error
    // object is going to come back with attribute names only,
    // so we are given only '[price]', so we match on the name
    // ending with '[price]'. The $= is the 'ends with' matcher.
    // eslint-disable-next-line
    const elem = $(`[name$='[${i}]']`);

    elem.after(html);
    elem.addClass('ui-state-error');
    // eslint-disable-next-line
    Tabs.showTabSurrounding(elem);
  }
}

function handleRemoteFormSubmit() {
  $(document).on('ajax:beforeSend', '[data-remote]', function () {
    if (validateRequiredFields($(this))) {
      showSuccessMessage($(this));
      return true;
    }

    return false;
  });

  $(document).on('ajax:error', '[data-remote]', (event, xhr) => {
    try {
      const errors = JSON.parse(xhr.responseText).errors;
      const flashError = JSON.parse(xhr.responseText).error;

      renderErrors(errors);

      if (flashError) {
        UI.flashError(flashError);
      } else {
        UI.flashError('Sorry, we had a failure while processing your last request.');
      }
    // eslint-disable-next-line
    } catch (e) {}
  });
}

function allFieldsValid($fields) {
  const emptyFields = filter($fields, element => (
    ($.trim($(element).val()).length === 0)
  ));

  return emptyFields.length === 0;
}

// Fires a delayed callback after a user has stopped typing or unfocused an input
// @param selector [string] CSS selector to observe
// @param timeout [integer] delay in ms
// @param callback [function]
function doneTyping(selector, timeout, callback) {
  const debouncedCallback = debounce(callback, timeout);
  let lastValue = $(selector).val();

  $(document).on('keyup change', selector, (e) => {
    const currentValue = $(selector).val();
    if (lastValue !== currentValue) {
      lastValue = currentValue;
      debouncedCallback(e);
    }
  });
}

function init() {
  handleRemoteFormSubmit();
  handleRequiredFields();
  handleDisabled();
  validatePassword();
}

$(() => { init(); });

const exported = {
  init,
  validateRequiredFields,
  validateFields,
  reEnableDisabledFormSubmit,
  allFieldsValid,
  requiredFields,
  addErrorUi,
  removeErrorUi,
  enableElement,
  displayValidationErrors,
  doneTyping,
  value,
  scrollToError,
};

export default exported;
export { init, validateRequiredFields, validateFields, reEnableDisabledFormSubmit, allFieldsValid, requiredFields, addErrorUi, removeErrorUi, enableElement, displayValidationErrors, doneTyping, value, scrollToError, disableElement };
