import React from 'react';
import I18n from 'i18n-js';
import { withRouter, WithRouterProps } from 'react-router';

import { CSP } from '../gql/graphql';
import CoreLink from './core_link';
import { RCImage } from '@reverbdotcom/cadence/components';
import { buildCSPClick } from '../elog/mparticle_tracker';
import SanitizedRender from './sanitized_render';
import { isItemPage, prefilledListingFromCSP } from '../url_helpers';
import { IUserContext, withUserContext } from './user_context_provider';
import { formatExperimentsForEventAttributes } from '../elog/mparticle';
import experiments from '../experiments';
import {
  BrandMetaData,
  ConditionFilter,
  NameMetaData,
  PriceMetaData,
  DescriptionMetaData,
  ImageMetaData,
} from '../cms/lib/metadata_helper';
import { inWeb } from '../user_context_helpers';

export interface IProps extends IUserContext {
  csp?: CSP;
  conditionFilter?: ConditionFilter;
  position?: number;
  hideMetadata?: boolean;
  includeItemListMetadata?: boolean;
  shouldLinkToSellForm?: boolean;
  trackingQuery?: string;
  hidePrices?: boolean;
}

export type Props = IProps & WithRouterProps;

const FIRST_POSITION = 0;

export class CSPWideCard extends React.Component<Props, null> {
  public static defaultProps: Partial<IProps> = {
    position: FIRST_POSITION,
  };

  priceTranslationKey(format) {
    if (this.props.shouldLinkToSellForm) {
      return `cms.cspFeature.pricing.selling.${format}`;
    }

    return `cms.cspFeature.pricing.buying.${format}`;
  }

  renderPrices() {
    if (this.props.hidePrices) {
      return null;
    }

    const { csp } = this.props;
    const tags = {
      tagOpen: '<b>',
      tagClose: '</b>',
    };

    const { usedLowPrice, newLowPrice } = csp.inventory;

    return (
      <ul className="csp-wide-card__prices">
        {usedLowPrice && this.props.conditionFilter !== 'new' &&
          <SanitizedRender
            className="csp-wide-card__price"
            htmlTag="li"
            html={I18n.t(
              this.priceTranslationKey('usedPriceFromWithTags'),
              { price: usedLowPrice.display, ...tags },
            )}
          />
        }
        {newLowPrice && this.props.conditionFilter !== 'used' &&
          <SanitizedRender
            className="csp-wide-card__price"
            htmlTag="li"
            html={I18n.t(
              this.priceTranslationKey('newPriceFromWithTags'),
              { price: newLowPrice.display, ...tags },
            )}
          />
        }
      </ul>
    );
  }

  destinationPath() {
    if (this.props.shouldLinkToSellForm) {
      return prefilledListingFromCSP(this.props.csp.id);
    }

    if (this.props.conditionFilter === 'new') {
      return `${this.props.csp.webLink.path}#new_listings`;
    }

    if (this.props.conditionFilter === 'used') {
      return `${this.props.csp.webLink.path}#used_listings`;
    }

    return this.props.csp.webLink.path;
  }

  renderPlaceholder() {
    return (
      <div className="csp-wide-card csp-wide-card--placeholder">
        <div className="csp-wide-card__inner">
          <div className="csp-wide-card__content">
            <div className="csp-wide-card__content__img">
              <div className="csp-wide-card__content__img__inner" />
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderMetadata() {
    if (this.props.hideMetadata) return null;

    return (
      <div
        itemProp={this.props.includeItemListMetadata ? 'itemListElement' : null}
        itemScope={this.props.includeItemListMetadata}
        itemType={this.props.includeItemListMetadata ? 'http://schema.org/Product' : null}
        className="height-100"
      >
        <BrandMetaData csp={this.props.csp} />
        <NameMetaData csp={this.props.csp} />
        <DescriptionMetaData csp={this.props.csp} />
        <ImageMetaData csp={this.props.csp} />
        <PriceMetaData
          csp={this.props.csp}
          conditionFilter={this.props.conditionFilter}
        />
      </div>
    );
  }

  clickEvent() {
    return {
      experiments: formatExperimentsForEventAttributes(
        this.props.user,
        [
          experiments.DUMMY,
        ],
      ),
      ...buildCSPClick(this.props.csp),
      displayStyle: 'CspWideCard',
      position: this.props.position,
      query: this.props.trackingQuery,
    };
  }

  render() {
    const { csp } = this.props;
    if (!csp) return this.renderPlaceholder();

    const openNewTab = isItemPage(this.props.location) && inWeb(this.props.user);

    return (
      <article
        className="csp-wide-card"
      >
        <CoreLink
          className="csp-wide-card__inner"
          to={this.destinationPath()}
          clickEvent={this.clickEvent()}
          target={openNewTab ? '_blank' : undefined}
        >
          <div className="csp-wide-card__content">
            <div
              className="csp-wide-card__content__img"
            >
              <RCImage
                src={this.props.csp.image.source}
                overlay="offwhiteWithHover"
              />
            </div>
            <div className="csp-wide-card__content__details">
              <h4 className="csp-wide-card__content__title">
                {csp.title}
              </h4>
              {this.renderPrices()}
              {this.renderMetadata()}
            </div>
          </div>
        </CoreLink>
      </article>
    );
  }
}

export default withUserContext(withRouter(CSPWideCard));
