import React from 'react';
import bind from '@reverbdotcom/commons/src/bind';
import RefundRequestReason from './refund_request_reason';
import RefundTypeSelector from './refund_type_selector';
import PartialRefundAmount from './partial_refund_amount';
import RefundRequestState from './refund_request_state';
import RefundRequestDetails from './refund_request_details';
import NoteToOtherParty from './note_to_other_party';
import RefundExplanation from './refund_explanation';
import {
  IProps as IDefaultProps,
  IState,
  IRefundType,
  IRefundState,
  needsCurrencyExchange,
  amountInSettlementCurrency,
  getInitialAmount,
} from './refund_request';

export interface IProps extends IDefaultProps {
  refundType: IRefundType;
  onRefundTypeChange: (event: any) => void;
  refundState: IRefundState;
  onRefundStateChange: (event: any) => void;
}

export default class StandardFormFields extends React.Component<IProps, IState> {
  constructor(props) {
    super(props);

    this.state = {
      refundReason: '',
      note: '',
      amount: getInitialAmount(this.props.refundRequest, this.props.userType),
      exchangedAmount: null,
    };
  }

  @bind
  onRefundReasonChange(event) {
    this.setState({ refundReason: event.target.value });
  }

  @bind
  onNoteChange(event) {
    this.setState({ note: event.target.value });
  }

  @bind
  onAmountChange(event) {
    const amount = event.target.value;

    if (needsCurrencyExchange(this.props.order, this.props.userType)) {
      this.setState({
        amount,
        exchangedAmount: amountInSettlementCurrency(amount, this.props.order),
      });

      return;
    }

    this.setState({ amount });
  }

  renderRefundExplanation() {
    if (this.props.hideRefundExplanation) {
      return null;
    }

    return (
      <RefundExplanation
        userType={this.props.userType}
        order={this.props.order}
        refundType={this.props.refundType}
        refundState={this.props.refundState}
        refundRequest={this.props.refundRequest}
        refundAmount={this.state.amount}
        refundReason={this.state.refundReason}
        protectionPlanAmount={this.props.protectionPlanAmount}
      />
    );
  }

  render() {
    return (
      <div>
        <RefundTypeSelector
          refundType={this.props.refundType}
          onRefundTypeChange={this.props.onRefundTypeChange}
          userType={this.props.userType}
          order={this.props.order}
          refundRequest={this.props.refundRequest}
        />
        <PartialRefundAmount
          amount={this.state.amount}
          onAmountChange={this.onAmountChange}
          refundType={this.props.refundType}
          order={this.props.order}
          exchangedAmount={this.state.exchangedAmount}
          refundRequest={this.props.refundRequest}
        />
        <RefundRequestDetails
          userType={this.props.userType}
          order={this.props.order}
          refundRequest={this.props.refundRequest}
        />
        <RefundRequestState
          userType={this.props.userType}
          refundState={this.props.refundState}
          onRefundStateChange={this.props.onRefundStateChange}
          refundRequest={this.props.refundRequest}
          viewContext={this.props.viewContext}
        />
        <RefundRequestReason
          refundReason={this.state.refundReason}
          onRefundReasonChange={this.onRefundReasonChange}
          reasons={this.props.reasons}
          userType={this.props.userType}
          refundRequest={this.props.refundRequest}
        />
        <NoteToOtherParty
          refundReason={this.state.refundReason}
          userType={this.props.userType}
          note={this.state.note}
          onNoteChange={this.onNoteChange}
          viewContext={this.props.viewContext}
        />
        {this.renderRefundExplanation()}
      </div>
    );
  }
}
