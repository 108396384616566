import React from 'react';
import I18n from 'i18n-js';
import { useUser } from '@reverbdotcom/commons/src/user_hooks';
import CsrfForm from '@reverbdotcom/commons/src/csrf_form';
import Currency from '@reverbdotcom/commons/src/currency';
import FormGroup from '@reverbdotcom/commons/src/components/form_group';
import { LANGUAGES } from '../shared/constants';
import LocalePickerRegionSelect from './locale_picker_region_select';
import { trackEvent, MParticleEventName } from '@reverbdotcom/commons/src/elog/mparticle_tracker';
import { RCModal } from '@reverbdotcom/cadence/components';

interface IProps {
  isOpen: boolean;
  onClose: () => void;
}

function LocalePickerModal({ isOpen, onClose }: IProps) {
  const user = useUser();
  const languageKeysForUser = Object.keys(LANGUAGES).filter(langKey => langKey !== 'eo' || user.isAdmin);
  const formId = 'locale-picker';

  function logChange(evt) {
    trackEvent({
      eventName: MParticleEventName.UpdatedInternationalSettings,
      newShippingRegionCode: evt.target.shipping_region_code.value,
      newLocaleCode: evt.target.locale_code.value,
      newCurrency: evt.target.currency.value,
      oldShippingRegionCode: user.shippingRegionCode,
      oldLocaleCode: user.locale,
      oldCurrency: user.currency,
    });
  }

  function handleClose(state) {
    if (state === false) {
      onClose();
    }
  }

  return (
    <RCModal
      isOpen={isOpen}
      title={I18n.t('discovery.localePicker.localeSettings')}
      onOpenChange={handleClose}
      actions={{
        primary: {
          form: formId,
          isSubmit: true,
          preventCloseOnClick: true,
        },
        secondary: true,
      }}
    >
      <CsrfForm
        action="/users/international_settings"
        method="put"
        onSubmit={logChange}
        id={formId}
      >
        <FormGroup
          label={I18n.t('discovery.localePicker.shippingRegionLabel')}
          inputName="locale_picker_shipping_region_code_field"
        >
          <LocalePickerRegionSelect />
        </FormGroup>

        <FormGroup
          label={I18n.t('discovery.localePicker.languageLabel')}
          inputName="locale_picker_language_field"
        >
          <div className="styled-dropdown">
            <select
              id="locale_picker_language_field"
              name="locale_code"
              defaultValue={user.locale}
            >
              {languageKeysForUser.map((lang: string) => (
                <option value={lang} key={lang}>{LANGUAGES[lang]}</option>
              ))}
            </select>
          </div>
        </FormGroup>

        <FormGroup
          label={I18n.t('discovery.localePicker.currencyLabel')}
          inputName="locale_picker_currency_field"
        >
          <div className="styled-dropdown">
            <select
              id="locale_picker_currency_field"
              name="currency"
              defaultValue={user.currency}
            >
              {Currency.currencyCodes.map((code: string) => (
                <option key={code} value={code}>
                  {I18n.t(`discovery.currencies.${code}`, { symbol: Currency.get(code).symbol })}
                </option>
              ))}
            </select>
          </div>
        </FormGroup>
      </CsrfForm>
    </RCModal>
  );
}

export default LocalePickerModal;
