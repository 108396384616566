const exported = {
  AVAILABLE_LOCALES: ['en', 'de', 'es', 'fr', 'nl', 'it'],
  CURATED_SET_ROW_LENGTH: 4,
  CSP_ROW_MINIMUM: 3,

  CURRENCY_SYMBOLS: {
    usd: '&#36;',
    cad: '&#36;',
    eur: '&#8364;',
    gbp: '&#163;',
    jpy: '&#165;',
    aud: '&#36;',
    mxn: '&#36;',
    nzd: '&#36;',
  },

  CURRENCY_CODES: {
    USD: 'USD',
    CAD: 'CAD',
    EUR: 'EUR',
    GBP: 'GBP',
    JPY: 'JPY',
    AUD: 'AUD',
    MXN: 'MXN',
    NZD: 'NZD',
  },

  COUNTRY_CODES: {
    US: 'US',
    UK: 'GB',
    AU: 'AU',
    CA: 'CA',
  },

  SHIPPING_REGION_CODES: {
    US_CON: 'US_CON',
  },

  PAYOUT_METHODS: {
    ACH: 'ach',
    REVERB_BANK: 'reverb',
  },

  AUCTION_PRICING_MODEL: 'auction',
  DEFAULT_CONDITION: 'all',
  DEFAULT_EMPTY_GRID_ROWS: 2,
  DEFAULT_GRID_COLUMNS: 3,
  DEFAULT_PAGE: 1,
  EVERYWHERE_CODE: 'XX',
  USED_CONDITION: 'used',
  MAKE_ROUTE_PATH: 'brand/:brand_slug',
  MARKETPLACE_PATH: 'marketplace',
  MARKETPLACE_ROOT: 'marketplace',
  PRICING_MODEL_PARAM: 'pricing_model',
  PRODUCT_TYPE_PARAM: 'product_type',
  CATEGORY_UUID_PARAM: 'category_uuid',
  CATEGORY_PARAM: 'category',
  STATE_PARAM: 'state',
  SORT_PARAM: 'sort',

  pills: {
    CATEGORIES_NAME: 'categories',
    CONTINENTAL_US: 'Continental U.S.',
    DECADES_NAME: 'decades',
    DEFAULT_REGION: 'Anywhere',
    ITEM_REGION_NAME: 'seller_location',
    MAKES_NAME: 'makes',
    MULTI_SELECT_TYPE: 'multi_select',
    RANGE_TYPE: 'range',
    OPEN_INPUT_TYPE: 'open_input',
    SHIPS_TO_NAME: 'ships_to',
  },

  cloudinaryTransforms: {
    H300_W600: 'a_exif,c_fill,f_auto,fl_progressive,g_north,h_300,q_auto:eco,w_600',
    H226_W226: 'a_exif,c_thumb,fl_progressive,g_south,h_226,q_auto:eco,w_226',
  },
};

export default exported;

export const {
  AUCTION_PRICING_MODEL,
  AVAILABLE_LOCALES,
  CURATED_SET_ROW_LENGTH,
  CSP_ROW_MINIMUM,
  CURRENCY_SYMBOLS,
  CURRENCY_CODES,
  COUNTRY_CODES,
  PAYOUT_METHODS,
  DEFAULT_CONDITION,
  DEFAULT_EMPTY_GRID_ROWS,
  DEFAULT_GRID_COLUMNS,
  DEFAULT_PAGE,
  EVERYWHERE_CODE,
  USED_CONDITION,
  MAKE_ROUTE_PATH,
  MARKETPLACE_PATH,
  MARKETPLACE_ROOT,
  PRICING_MODEL_PARAM,
  PRODUCT_TYPE_PARAM,
  CATEGORY_UUID_PARAM,
  CATEGORY_PARAM,
  SORT_PARAM,
  STATE_PARAM,
  pills,
  cloudinaryTransforms,
  SHIPPING_REGION_CODES,
} = exported;
