export default {
  taxProfileTitle: 'Provide your tax information here',
  taxProfileDescription: 'Please note: The information you provide should match the information you use to file your taxes.',
  labels: {
    tin: 'Tax Identification Number',
    tinType: 'Tax identification type',
    dateOfBirth: 'Date of birth',
    address: 'Address',
    businessRegistrationNumber: 'Business registration number',
    vatId: 'VAT ID',
    taxIdentifications: {
      VAT: 'VAT ID',
      GST: 'Canadian Taxpayer IDs',
    },
  },
  tinType: {
    business: 'Business',
    individual: 'Individual',
  },
  name: {
    business: 'Legal business name',
    individual: 'First and last name',
  },
  missingBusinessRegistrationNumber: 'Not provided',
  editTaxInformation: 'Edit tax information',
  errorLoadingTaxProfile: 'We were unable to save your tax information. Please review the information you provided and try again.',
  taxInfoHelpCenterLinkLabel: 'Learn more about providing tax info in the Help Center.',
  form: {
    individualOrBusiness: 'Are you operating as an individual or a business on Reverb?',
    tinTypeHelpText: 'This should be based on how you file for taxes.',
    tinHelpText: 'See our {{children}} for more information about formatting.',
    helpCenterLinkLabel: 'Help Center',
    cancelEditing: 'Cancel',
    submitTaxInformation: 'Submit tax info securely',
    contactInformation: 'Contact Information',
    tinNameHelpText: 'Please enter the name you use when filing your taxes.',
    businessRegistrationNumberHelpText: 'We ask for this because you selected that you operate as a business on Reverb.',
    dateOfBirthHelpText: 'We ask for this because you selected that you operate as an individual on Reverb.',
    error: 'We were unable to save your tax information. Please review the information you entered and try again. {{children}}',
    success: 'Thank you for submitting your tax information.',
  },
  taxIdentifications: {
    noTaxIdentification: {
      VAT: 'No VAT ID on file.',
      GST: 'No Canadian Taxpayer IDs on file.',
    },
    hasTaxIdPrompt: {
      VAT: 'Please visit the {{children}} to make changes to your VAT ID.',
      GST: 'Please visit the {{children}} to make changes to your Canadian taxpayer ID(s).',
    },
    noTaxIdPrompt: {
      VAT: 'Please visit the {{children}} to add a VAT ID.',
      GST: 'Please visit the {{children}} to add a Canadian taxpayer ID.',
    },
    pageLinkText: 'Tax Policies page',
  },
  banner: {
    bannerTitle: 'Please complete your tax profile',
    bannerContent: 'Based on how much you’ve sold this year, we need a few additional details to ensure you can keep using Reverb without disruption.',
    bannerCta: 'Update my tax profile',
  },
  marketplaceTaxReportDownloads: {
    title: 'Download your annual sales summary',
    description: 'Download the information Reverb reported to the tax authorities',
    selectYear: 'Select a year',
    downloadPdf: 'Download PDF',
    exportOrderCsv: 'Export CSV of order data',
    exportOrderCsvSuccess: 'Export started. Your results will be emailed to you.',
    exportOrderCsvFailure: 'We were unable to export your order data. Please refresh and try again.',
  },
};
