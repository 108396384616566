import React from 'react';
import classNames from 'classnames';
import I18n from 'i18n-js';

const LEFT_GAP = 'previous_ellipsis';
const RIGHT_GAP = 'next_ellipsis';
const DEFAULT_PAGINATION_WINDOW = 2;

const range = function range(from, to) {
  const array = [];

  for (let i = from; i <= to; i += 1) {
    array.push(i);
  }

  return array;
};

interface Props {
  limit: number;
  offset: number;
  total: number;
  previousButton?: React.ReactElement;
  onPreviousClick?: () => any;
  nextButton?: React.ReactElement;
  onNextClick?: () => any;
  renderPageNumberLink?: (pageNum: number) => any;
  onPageNumberClick?: (pageNum: number) => any;
  paginationWindow?: number;
}

function getMiddleRange(currentPage, paginationWindow, totalPages) {
  let windowFrom = currentPage - paginationWindow;
  let windowTo = currentPage + paginationWindow;

  if (windowTo > totalPages) {
    windowFrom -= windowTo - totalPages;
    windowTo = totalPages;
  }

  if (windowFrom < 1) {
    windowTo += 1 - windowFrom;
  }

  return range(
    Math.max(1, windowFrom + 1),
    Math.min(windowTo, totalPages),
  );
}

function getLeftRange(paginationWindow, middleRange) {
  if (paginationWindow < middleRange[0]) {
    return [1, LEFT_GAP]; // display first page and gap only
  }

  // display all pages from beginning to middle range
  return range(1, middleRange[0] - 1);
}

function getRightRange(totalPages, middleRange) {
  const endOfMiddleRange = middleRange[middleRange.length - 1];

  if (totalPages > endOfMiddleRange) {
    return [RIGHT_GAP]; // display gap only
  }

  // display all pages from middle range to end
  return range(endOfMiddleRange + 1, totalPages);
}

function getCurrentPage(limit, offset) {
  const page = Math.ceil(offset / limit) + 1;
  return page < 1 ? 1 : page;
}

function EllipsisLink({ pageNum }) {
  const key = `pagination-gap-${pageNum}`;
  const classes = classNames(
    'pagination__button',
    'pagination__button--gap',
  );

  return (
    <div
      key={key}
      className={classes}
      aria-hidden="true"
      data-disabled
    >
      &hellip;
    </div>
  );
}

function PaginationButton({ text, onClick, classes = 'pagination__button' }) {
  return (
    <button
      className={classes}
      onClick={onClick}
    >
      {text}
    </button>
  );
}

export default function PaginationControls({ total, offset, limit, ...props }: Props) {
  const totalPages = Math.ceil(total / limit);
  const paginationWindow = props.paginationWindow || DEFAULT_PAGINATION_WINDOW;

  if (totalPages <= 1 || (offset >= total)) { return null; }

  const currentPage = getCurrentPage(limit, offset);
  const middleRange = getMiddleRange(currentPage, paginationWindow, totalPages);
  const leftRange = getLeftRange(paginationWindow, middleRange);
  const rightRange = getRightRange(totalPages, middleRange);
  const pageNumbers = leftRange.concat(middleRange).concat(rightRange);

  const previousButton = props.previousButton || (
    props.onPreviousClick &&
      <PaginationButton
        text={I18n.t('commons.paginationControls.previous')}
        onClick={props.onPreviousClick}
      />
  );
  const nextButton = props.nextButton || (
    props.onNextClick &&
      <PaginationButton
        text={I18n.t('commons.paginationControls.next')}
        onClick={props.onNextClick}
      />
  );

  return (
    <div className="pagination-container">
      <div className="pagination__buttons">
        {currentPage !== 1 && previousButton}

        {pageNumbers.map((pageNum) => {
          if (pageNum === LEFT_GAP || pageNum === RIGHT_GAP) {
            return (
              <EllipsisLink key={pageNum} pageNum={pageNum} />
            );
          } else if (props.renderPageNumberLink) {
            return (
              <React.Fragment key={pageNum}>
                {props.renderPageNumberLink(pageNum)}
              </React.Fragment>
            );
          } else if (props.onPageNumberClick) {
            return (
              <PaginationButton
                key={pageNum}
                text={pageNum.toString()}
                onClick={() => { props.onPageNumberClick(pageNum); }}
                classes={classNames(
                  'pagination__button',
                  'pagination__button--number',
                  { 'pagination__button--active': pageNum === currentPage },
                )}
              />
            );
          }
        })}

        {currentPage !== totalPages && nextButton}
      </div>
    </div>
  );
}
