import { ChildProps } from '@apollo/client/react/hoc';
// TODO update this to import from commons/src/gql
// eslint-disable-next-line no-restricted-imports
import { gql } from '@apollo/client';
import React from 'react';
import { compact, isEqual } from 'lodash';
import bind from '../../bind';

import SanitizedRender from '../../components/sanitized_render';
import { withGraphql } from '../../with_graphql';
import CSPRow, { CSPCardFragment } from '../../components/csp_row';
import Tiles from '../../components/tiles';
import CspFeatureListItem from './csp_feature_list_item';
import { trackEvent, trackCSPView, CTA } from '../../elog/mparticle_tracker';
import CoreLink from '../../components/core_link';
import I18n from 'i18n-js';
import { IDynamicComponentProps } from '../dynamic_component_props';
import {
  core_apimessages_Ad,
  CommonsCmsCspCollection,
  reverb_search_CSPSearchRequest_Sort,
} from '../../gql/graphql';
import { RCTextWithIcon, buttonClassName } from '@reverbdotcom/cadence/components';
import { ArrowRightIcon } from '@reverbdotcom/cadence/icons/react';

export enum FeatureType {
  CspRow = 'cspRow',
  SquareGrid = 'squareGrid',
  OrderedList = 'orderedList',
  Landing = 'landing',
}

export interface IProps extends IDynamicComponentProps {
  title?: string;
  titleHtml?: string;
  subtitleHtml?: string;
  ledeHtml?: string;
  collectionId?: string;
  query?: string;
  make?: string[];
  categoryId?: string;
  condition?: ConditionFilter;
  countryOfOrigin?: string;
  maxCount?: number;
  ctaText?: string;
  ctaTargetHref?: string;
  sort?: reverb_search_CSPSearchRequest_Sort;
  displayAsGrid?: boolean;
  jumplinkSlug?: string;
  useCspSummary?: boolean;
  traits?: string[];
  cspSlugs?: string[];
  categoryUuids?: string[];
  brandSlugs?: string[];
  ad?: core_apimessages_Ad;
  trackingName?: string;
  yearMin?: number;
  yearMax?: number;
  displayAs?: FeatureType;
  withSidebar?: boolean;
}

type ConditionFilter = 'new' | 'used';

const COMPONENT_NAME = 'CspCollection';

function categoryUuidsFromProps(props: IProps): string[] {
  if (props.categoryUuids) {
    return props.categoryUuids;
  }

  return props.categoryId ? [props.categoryId] : [];
}

interface CSPRowTitleProps {
  title?: string;
  titleHtml?: string;
}

export function CSPRowTitle(props: CSPRowTitleProps) {
  return (
    <SanitizedRender
      html={props.titleHtml || props.title}
    />
  );
}

interface CSPRowActionProps {
  ctaText: string;
  ctaTargetHref: string;
}

export function CSPRowAction(props: CSPRowActionProps) {
  if (!props.ctaTargetHref) { return null; }

  return (
    <CoreLink
      interactionType={CTA}
      to={props.ctaTargetHref}
      className={buttonClassName({ variant: 'transparent' })}
    >
      <RCTextWithIcon svgComponent={ArrowRightIcon} placement="right">
        {props.ctaText ? props.ctaText : I18n.t('cms.comparisonShoppingPages.seeMore')}
      </RCTextWithIcon>
    </CoreLink>
  );
}

export const CSP_ROW_SORT_DEFAULT = reverb_search_CSPSearchRequest_Sort.TRENDING_DESC;
export const CSP_ROW_MAX_COUNT_DEFAULT = 12;

export class CspCollection extends React.Component<ChildProps<IProps, CommonsCmsCspCollection.Query>, null> {
  static defaultProps: Partial<IProps> = {
    sort: CSP_ROW_SORT_DEFAULT,
    maxCount: CSP_ROW_MAX_COUNT_DEFAULT,
  };

  trackingName() {
    return this.props.trackingName ?? COMPONENT_NAME;
  }

  componentDidMount() {
    if (!this.props.data.loading && this.csps().length && this.displayAs(FeatureType.OrderedList)) {
      trackEvent(trackCSPView(this.csps(), this.trackingName()));
    }
  }

  componentDidUpdate(oldProps) {
    if (
      !this.props.data.loading &&
      this.csps().length &&
      !isEqual(this.props.data, oldProps.data) &&
      this.displayAs(FeatureType.OrderedList)) {
      trackEvent(trackCSPView(this.csps(), this.trackingName()));
    }
  }

  csps() {
    return this.props.data?.cspSearch?.csps || [];
  }

  displayAs(style) {
    return this.props.displayAs === style;
  }

  renderTitle() {
    return (
      <SanitizedRender
        html={this.props.titleHtml || this.props.title}
      />
    );
  }

  @bind
  renderOrderedListItem(csp, idx) {
    return (
      <CspFeatureListItem
        csp={csp}
        key={idx}
        listPosition={idx + 1}
        position={idx}
        useCspSummary={this.props.useCspSummary}
      />
    );
  }

  renderOrderedList() {
    return (
      <div className="csp-feature-list">
        <h2 className="csp-feature-list__heading">
          <CSPRowTitle
            title={this.props.title}
            titleHtml={this.props.titleHtml}
          />
        </h2>
        <Tiles
          oneWide
          loading={this.props.data.loading}
          placeholder={<CspFeatureListItem />}
        >
          {this.csps().map(this.renderOrderedListItem)}
        </Tiles>
      </div>
    );
  }

  render() {
    if (this.displayAs(FeatureType.OrderedList)) {
      return this.renderOrderedList();
    }

    // legacy support for boolean flag
    const displayAsGrid = this.displayAs(FeatureType.SquareGrid) || this.props.displayAsGrid;

    return (
      <CSPRow
        title={
          <CSPRowTitle
            title={this.props.title}
            titleHtml={this.props.titleHtml}
          />
        }
        subtitleHtml={this.props.subtitleHtml}
        ledeHtml={this.props.ledeHtml}
        id={this.props.jumplinkSlug}
        csps={this.csps()}
        expectedCount={this.props.maxCount}
        action={
          !!this.props.ctaTargetHref && <CSPRowAction
            ctaTargetHref={this.props.ctaTargetHref}
            ctaText={this.props.ctaText}
          />
        }
        loading={this.props.data.loading}
        conditionFilter={this.props.condition}
        displayAsGrid={displayAsGrid}
        trackingComponentName={this.trackingName()}
        trackingQuery={this.trackingName()}
        shouldTrack
        ad={this.props.ad}
        shouldDisplayAsLanding={this.displayAs(FeatureType.Landing)}
        withSidebar={this.props.withSidebar}
      />
    );
  }
}

export const cspCollectionQuery = withGraphql<IProps, CommonsCmsCspCollection.Query>(
  gql`
    query Commons_Cms_CspCollection(
      $curatedSetId: String
      $fullTextQuery: String
      $brandNames: [String]
      $brandSlugs: [String]
      $condition: String
      $countryOfOrigin: [String]
      $limit: Int
      $categoryUuids: [String]
      $sort: reverb_search_CSPSearchRequest_Sort
      $traitSlugs: [String]
      $slugs: [String]
      $yearMin: Int
      $yearMax: Int
    )
    {
      cspSearch(input: {
        slugs: $slugs
        curatedSetId: $curatedSetId
        fullTextQuery: $fullTextQuery
        brandNames: $brandNames
        brandSlugs: $brandSlugs
        condition: $condition
        countryOfOrigin: $countryOfOrigin
        limit: $limit
        withInventory: true
        sort: $sort
        categoryUuids: $categoryUuids
        traitSlugs: $traitSlugs
        yearMin: $yearMin
        yearMax: $yearMax
      }) {
        csps {
          _id
          ...CSPCard
          metaDescription
          summary
          brand {
            _id
            name
          }
        }
      }
    }
    ${CSPCardFragment}
  `,
  {
    options: (ownProps) => {
      return {
        ssr: ownProps.ssr,
        variables: {
          curatedSetId: ownProps.collectionId,
          fullTextQuery: ownProps.query,
          brandNames: ownProps.make,
          brandSlugs: ownProps.brandSlugs || [],
          condition: ownProps.condition,
          countryOfOrigin: compact([ownProps.countryOfOrigin]),
          limit: ownProps.maxCount || CSP_ROW_MAX_COUNT_DEFAULT,
          categoryUuids: categoryUuidsFromProps(ownProps),
          sort: ownProps.sort || CSP_ROW_SORT_DEFAULT,
          traitSlugs: ownProps.traits,
          slugs: ownProps.cspSlugs || [],
          yearMin: ownProps.yearMin,
          yearMax: ownProps.yearMax,
        },
      };
    },
  },
);

export default cspCollectionQuery(CspCollection);
