import { FilterOption, Filter } from './components/search_filters';

export function isChildSelected(
  option: FilterOption,
  includeSelf = false,
) {
  return (
    (includeSelf && option.selected) ||
    (option.subFilter &&
      option.subFilter.options.some(opt => isChildSelected(opt, true)))
  );
}

export function isSomethingSelected(filter: Filter, excludeAll = true) {
  return filter.options.some(opt => !(excludeAll && opt.all) && isChildSelected(opt, true));
}

/**
 * We often need to rename RQL search params to create the right querystring params.
 * This is a helper to traverse Filters and update all the paramName fields based on
 * a mapping function.
 */
export function renameParams(filters: Filter[], renameFn: (oldParam: string) => string): Filter[] {
  return filters.map(filter => renameParamsSingle(filter, renameFn));
}

export function renameParamsSingle(oldFilter: Filter, renameFn: (oldParam: string) => string): Filter {
  const options = oldFilter.options.map((oldOption) => {
    const paramName = renameFn(oldOption.paramName);
    const subFilter = oldOption.subFilter &&
      renameParamsSingle(oldOption.subFilter, renameFn);

    return { ...oldOption, paramName, subFilter };
  });

  return { ...oldFilter, options };
}

/**
 * Accepts a facetOption, which may have nested options, and returns an array of FacetOptions
 * from the root to the node that is selected. For normal (non-nested) FacetOptions,
 * this simply returns [option] if selected and [] otherwise.
 */
export function selectedPath(
  filter: Filter,
): FilterOption[] {
  function traverse(option: FilterOption, acc = []): FilterOption[] {
    if (option.selected) {
      return [...acc, option];
    }

    const selectedBranch =
      option.subFilter &&
      option.subFilter.options.find(opt => isChildSelected(opt, true));

    if (!selectedBranch) {
      return acc;
    }

    return traverse(selectedBranch, [...acc, option]);
  }

  const selectedOpt = filter.options.find(opt => isChildSelected(opt, true));
  return selectedOpt ? traverse(selectedOpt) : [];
}
