export default {
  updateCard: {
    priceDropOnWatched: {
      header: 'The {{title}} you\'re watching dropped its price',
    },
    newOfferOnWatchedItem: {
      header: '1 New offer was just made on the {{title}} you\'re watching',
    },
    watchedItemSold: {
      header: 'The {{title}} you\'re watching has sold',
    },
    newListingsFromFollowedShop: {
      header: '{{title}} listed new gear',
    },
  },
  filters: {
    updates: 'Updates',
    recommendations: 'Recommendations',
    savedSearches: 'Saved Searches',
    articles: 'Articles',
    videos: 'Videos',
  },
};
