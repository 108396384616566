import React from 'react';
import I18n from 'i18n-js';
import { BannerType, RCBanner } from '@reverbdotcom/cadence/components';
import BrandedHighFiveIcon from '@reverbdotcom/cadence/images/brand/high-five-orange.svg';
import Protection from '@reverbdotcom/cadence/images/brand/protection-orange.svg';
import { I18N as Translate } from '@reverbdotcom/commons/src/components/translate';
import CoreLink from '@reverbdotcom/commons/src/components/core_link';
import { Paths } from '../shared/url_helpers';
import { HELP_CENTER_ARTICLE_ID } from '../../identity_verification/inform_act_verifications';

export interface Props {
  phase: string;
  timeRemainingBeforeLock: string;
}

const InformActBanner = ({
  phase,
  timeRemainingBeforeLock,
}) => {
  // This should match the phase as defined in Reverb::InformActBanner in core
  const PHASE_ZERO = 'phase0';

  const iconName:string = phase === PHASE_ZERO ? BrandedHighFiveIcon : Protection;
  const theme:BannerType = phase === PHASE_ZERO ? 'info' : 'error';

  return (
    <RCBanner
      content={
        <Translate text={`discovery.sellerDashboard.informActBanner.${phase}.description`}>
          <CoreLink
            className="text-link"
            target="_blank"
            to={Paths.helpCenterArticlesUrl.expand({ articleId: HELP_CENTER_ARTICLE_ID })}
          >
            <Translate text="discovery.sellerDashboard.informActBanner.helpCenterLinkText" />
          </CoreLink>
        </Translate>
      }
      cta={
        <CoreLink to={Paths.coreIdentityVerification.expand({})}>
          <Translate text="discovery.sellerDashboard.informActBanner.buttonText" />
        </CoreLink>
      }
      image={iconName}
      title={I18n.t(`discovery.sellerDashboard.informActBanner.${phase}.title`, { timeRemainingBeforeLock })}
      theme={theme}
    />
  );
};

export default InformActBanner;
