import OfferItem from './offer_item';
import Money from '../../../shared/money';
import { find, filter, includes } from 'lodash';

function sum(prices, currency) {
  const totalCents = prices.reduce(
    (total, price) => total + price.cents,
    0,
  );

  return new Money(totalCents, currency);
}

function parsePrice(priceString, currency) {
  let price;

  try {
    price = Money.parse(priceString, currency);
  } catch (ex) {
    price = new Money(0, currency);
  }

  return price;
}

class OfferItems {
  constructor(offerItemsData, listingCurrency) {
    this.offerItemsData = offerItemsData;
    this.listingCurrency = listingCurrency;
  }

  get isEmpty() {
    return this.offerItemsData.length === 0;
  }

  get clone() {
    return new OfferItems(this.offerItemsData.slice(0), this.listingCurrency);
  }

  get offerItems() {
    return this.offerItemsData;
  }

  get listingIds() {
    return this.offerItemsData.map(offerItem => offerItem.listingId);
  }

  findOfferItem(listingId) {
    return find(
      this.offerItemsData,
      offerItem => offerItem.listingId === listingId,
    );
  }

  setPrice(args) {
    const offerItem = this.findOfferItem(args.listingId);
    offerItem.price = args.price;
  }

  setShippingPrice(args) {
    const offerItem = this.findOfferItem(args.listingId);
    offerItem.shippingPrice = args.shippingPrice;
  }

  remove(listingId) {
    this.offerItemsData = filter(
      this.offerItemsData,
      offerItem => offerItem.listingId !== listingId,
    );
  }

  add(listing) {
    if (!includes(this.listingIds, listing.id)) {
      this.offerItemsData.push(new OfferItem({ listing }));
    }
  }

  get priceSubtotal() {
    const prices = this.offerItems.map(offerItem => parsePrice(offerItem.price, this.listingCurrency));

    return sum(prices, this.listingCurrency);
  }

  get shippingSubtotal() {
    const prices = this.offerItems.map(offerItem => parsePrice(offerItem.shippingPrice, this.listingCurrency));

    return sum(prices, this.listingCurrency);
  }

  get subtotal() {
    return sum([this.priceSubtotal, this.shippingSubtotal], this.listingCurrency);
  }
}

export default OfferItems;
