import React from 'react';
import I18n from 'i18n-js';
import TogglableDropdown from '../shared/togglable_dropdown';

interface Props {
  countryCode: string;
  countryCodes: string[];
  inputName: string;
  onCountryChange(countryCode: string): void;
}

function countryCodeToCountryName(countryCode: string) {
  return I18n.t(`countries.${countryCode}`);
}

function selectOptions(props: Props) {
  return props.countryCodes.map(countryCode =>
    ({ value: countryCode, name: countryCodeToCountryName(countryCode) }));
}

export default function PayoutMethodCountryCodeInput(props: Props) {
  return (
    <div className="g-col-6" data-change-country>
      <TogglableDropdown
        defaultValue={props.countryCode}
        selectOptions={selectOptions(props)}
        inputName={props.inputName}
        onChange={props.onCountryChange}
        labelTitle={I18n.t('discovery.payoutMethod.directCheckoutProfile.bankAccountFields.countryCodeInput.bankLocation')}
        changeLinkText={I18n.t('discovery.payoutMethod.directCheckoutProfile.bankAccountFields.countryCodeInput.change')}
        autoComplete="country-code"
      />
    </div>
  );
}
