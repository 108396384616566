import React from 'react';
import I18n from 'i18n-js';
import { RCLoadingBars } from '@reverbdotcom/cadence/components';

export default function ConnectingToPacklink() {
  return (
    <div className="bg-white mlr-4">
      <div className="cms-text">
        <h2 className="align-center">{I18n.t('discovery.packlink.connectingToPacklink')}</h2>
        <div className="d-flex fx-align-center fx-justify-center height-vh-10 pb-8">
          <RCLoadingBars />
        </div>
      </div>
    </div>
  );
}
