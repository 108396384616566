import React from 'react';

import { RCAccordion } from '@reverbdotcom/cadence/components';
import { filterID, FilterProps, Filter } from './search_filters';
import RegionFilterOptions from './region_filter_options';
import { useUser } from '../user_hooks';
import { EVERYWHERE_CODE } from '@reverbdotcom/commons/src/constants';

function defaultValueForFilter(filter: Filter, shippingRegionCode?: string) {
  if (filter.key === 'SHIPS_TO') {
    return shippingRegionCode;
  }

  if (filter.key === 'SELLER_LOCATION') {
    return EVERYWHERE_CODE;
  }

  return null;
}

function nonDefaultValueSelected(filter: Filter, shippingRegionCode?: string) {
  const selectedValue = filter.options?.find(opt => opt.selected)?.optionValue;
  return selectedValue && selectedValue !== defaultValueForFilter(filter, shippingRegionCode);
}

export default function RegionSelectFilter({
  filter,
  pageSection,
  trackingQuery,
  collapsible = false,
  defaultOpen = false,
  onToggleCallback,
}: FilterProps) {
  const id = `${pageSection}--${filterID(filter)}`;
  const { shippingRegionCode } = useUser();

  if (collapsible) {
    return (
      <div className="facet facet--collapsible">
        <RCAccordion
          id={id}
          heading={filter.name}
          defaultOpen={defaultOpen || nonDefaultValueSelected(filter, shippingRegionCode)}
          onToggle={onToggleCallback}
        >
          <RegionFilterOptions
            // passed filter has selected region only
            placeholderFilter={filter}
            trackingQuery={trackingQuery}
            id={id}
          />
        </RCAccordion>
      </div>
    );
  }

  return (
    <div className="facet">
      <label htmlFor={id} className="facet__heading">
        <h3>
          {filter.name}
        </h3>
      </label>
      <RegionFilterOptions
        // passed filter has selected region only
        placeholderFilter={filter}
        trackingQuery={trackingQuery}
        id={id}
      />
    </div>
  );
}
