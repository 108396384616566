import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import moment from 'moment';
import { APIRequest, QueryParamsUtil, UserStore } from '@reverbdotcom/discovery-ui';
import { trackEvent, MParticleEventName } from '@reverbdotcom/commons/src/elog/mparticle_tracker';
import Loading from '../shared/loading';
import Cookies from 'js-cookie';
import { withUserContext } from '@reverbdotcom/commons/src/components/user_context_provider';
import { IS_TEST } from '@reverbdotcom/env';
import { mparticleEmailFields } from '@reverbdotcom/commons/src/mparticle_email_fields_helper';
import uuid from '@reverbdotcom/commons/src/uuid';
import { trackFacebookEvent, LEAD_EVENT, MAILING_LIST_CONTENT_NAME } from '@reverbdotcom/commons/src/facebook';

const COOKIE_DISMISS_KEY = 'weekly_newsletter_dismiss';
const COOKIE_SUBMITTED_KEY = 'weekly_newsletter_submitted';
const EXPIRATION_DATE = new Date(moment().add(30, 'days'));

const ARTICLE_BODY_SELECTOR = '.blog-post__content';
const SCROLL_EVENT = 'scroll.email.signup';
const SCROLL_TOLERANCE = 25;

const AID_PARAM = '_aid';
const AID_VALUE = 'newsletter';

const COMPONENT_NAME = 'Pop Up Newsletter Signup';

function surpressFromNewsLetter() {
  return QueryParamsUtil.getParamValue(AID_PARAM) === AID_VALUE;
}

function SuccessMessage(props) {
  return (
    <div className="align-center">
      <button
        type="submit"
        onClick={props.onClick}
        className="button button--primary"
      >
        {I18n.t('js.emailSignup.close')}
      </button>
    </div>
  );
}

SuccessMessage.propTypes = { onClick: PropTypes.func };

export class EmailSignup extends React.Component {
  static signupUuid = uuid();

  static get defaultProps() {
    return {
      userSignedup: Cookies.get(COOKIE_SUBMITTED_KEY),
      userDismissed: Cookies.get(COOKIE_DISMISS_KEY),
      linkedFromNewsLetter: surpressFromNewsLetter(),
      userEmail: null,
      signupUrl: null,
      articleSlug: '',
    };
  }

  constructor(props) {
    super(props);
    this.state = {
      submitted: false,
      disableSubmit: false,
      dismissed: false,
      active: false,
      tracked: false,
      email: props.userEmail,
      title: I18n.t('js.emailSignup.default.title'),
      message: I18n.t('js.emailSignup.default.message'),
    };
    this.onDismiss = this.onDismiss.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onSetEmail = this.onSetEmail.bind(this);
    this.addWindowEvent = this.addWindowEvent.bind(this);
    this.success = this.success.bind(this);
    this.fail = this.fail.bind(this);
  }

  signUpReason = `article_footer_${this.props.articleSlug.replaceAll('-', '_')}`;

  componentDidMount() {
    if (this.container) {
      this.addWindowEvent($(this.container));
    }
  }

  componentWillUnmount() {
    this.removeWindowEvent();
  }

  onDismiss() {
    if (!this.state.submitted) {
      Cookies.set(COOKIE_DISMISS_KEY, true, { expires: EXPIRATION_DATE });
    }

    this.setState({ dismissed: true });
    this.removeWindowEvent();
  }

  onSubmit(event) {
    event.preventDefault();

    if (this.state.email) {
      this.setState({ disableSubmit: true });

      if (this.state.email.length > 255) {
        const title = I18n.t('js.emailSignup.emailTooLong.title');
        const message = I18n.t('js.emailSignup.emailTooLong.message');

        this.setState({
          submitted: false,
          disableSubmit: false,
          title,
          message,
        });

        return;
      }

      Cookies.set(COOKIE_SUBMITTED_KEY, true);

      const params = {
        email: this.state.email,
        signup_reason: this.signUpReason,
        signup_uuid: EmailSignup.signupUuid,
        ...mparticleEmailFields(),
      };

      if (this.showGdpr) {
        params.consented_to_gdpr = true;
      }
      const req = APIRequest.post(this.props.signupUrl, params);

      req.success(this.success);
      req.fail(this.fail);
    }

    return false;
  }

  onSetEmail(event) {
    this.setState({ email: event.target.value });
  }

  trackView() {
    if (!this.state.tracked) {
      trackEvent({
        eventName: MParticleEventName.ComponentView,
        componentName: COMPONENT_NAME,
        source: this.signUpReason,
      });

      this.setState({ tracked: true });
    }
  }

  addWindowEvent() {
    // this callback was causing flaking karma tests
    // presumably due to some incomplete test setup
    // as a quick fix, we just won't execute this callback in test
    if (IS_TEST) { return; }
    $(window).on(SCROLL_EVENT, () => {
      const articleHeight = $(ARTICLE_BODY_SELECTOR).innerHeight()
        - $(ARTICLE_BODY_SELECTOR).position().top;
      const scrollPosition = $(window).scrollTop();
      const percent = (scrollPosition / articleHeight) * 100;

      if (percent > SCROLL_TOLERANCE) {
        this.setState({ active: true });
        this.trackView();
      } else {
        this.setState({ active: false });
      }
    });
  }

  removeWindowEvent() {
    $(window).off(SCROLL_EVENT);
  }

  updateFacebook() {
    trackFacebookEvent(
      LEAD_EVENT,
      { content_name: MAILING_LIST_CONTENT_NAME },
      { eventID: EmailSignup.signupUuid },
    );
  }

  success() {
    this.updateFacebook();

    this.setState({
      submitted: true,
      disableSubmit: false,
      title: I18n.t('js.emailSignup.submitted.title'),
      message: I18n.t('js.emailSignup.submitted.message'),
    });
  }

  fail(e) {
    let title;
    let message;

    if (e.status === 400) {
      title = I18n.t('js.emailSignup.invalid.title');
      message = I18n.t('js.emailSignup.invalid.message');
    } else {
      title = I18n.t('js.emailSignup.error.title');
      message = I18n.t('js.emailSignup.error.message');
    }

    this.setState({
      submitted: false,
      disableSubmit: false,
      title,
      message,
    });
  }

  display() {
    return {
      display: this.state.dismissed ? 'none' : 'block',
    };
  }

  get showGdpr() {
    return UserStore.getState().gdpr;
  }

  renderGdprTerms() {
    if (!this.showGdpr) { return null; }

    return (
      <div className="email-signup__terms">
        {I18n.t('js.emailSignup.gdprTerms', { buttonText: I18n.t('js.emailSignup.subscribeButton') })}
      </div>
    );
  }

  renderForm() {
    if (this.state.submitted) {
      return <SuccessMessage onClick={this.onDismiss} />;
    }

    return (
      <form onSubmit={this.onSubmit}>
        <div className="input-group">
          <input
            type="email"
            name="email"
            className="input-group__input"
            defaultValue={this.props.userEmail}
            onChange={this.onSetEmail}
            placeholder={I18n.t('js.emailSignup.inputEmailPlaceholder')}
            disabled={this.state.disableSubmit}
          />
          <button
            type="submit"
            disabled={this.state.disableSubmit}
            className="input-group__button button button--primary"
          >
            <Loading
              loaded={!this.state.disableSubmit}
              size="small"
              color="white"
            >
              {I18n.t('js.emailSignup.subscribeButton')}
            </Loading>
          </button>
        </div>
      </form>
    );
  }

  classNames() {
    return classNames({ 'email-signup': true, active: this.state.active });
  }

  render() {
    if (this.props.userSignedup) { return null; }
    if (this.props.userDismissed) { return null; }
    if (this.props.linkedFromNewsLetter) { return null; }

    return (
      <section ref={(el) => this.container = el} className={this.classNames()} style={this.display()}>
        <button className="email-signup__close-icon" onClick={this.onDismiss} type="button">
          <i className="fa fa-times" />
        </button>
        <div className="email-signup__message-wrapper">
          <h2 className="email-signup__message-title">{this.state.title}</h2>
          <p className="email-signup__message-subtitle">{this.state.message}</p>
        </div>
        <div className="email-signup__form-wrapper">
          {this.renderForm()}
          {this.renderGdprTerms()}
        </div>
      </section>
    );
  }
}

EmailSignup.propTypes = {
  userSignedup: PropTypes.bool,
  userDismissed: PropTypes.bool,
  linkedFromNewsLetter: PropTypes.bool,
  signupUrl: PropTypes.string,
  articleSlug: PropTypes.string,
};

export default withUserContext(EmailSignup);
