// TODO update this to import from commons
// import { gql } from '@reverbdotcom/commons/src/gql';
// eslint-disable-next-line no-restricted-imports
import { gql } from '@apollo/client';
import classNames from 'classnames';
import I18n from 'i18n-js';
import { get } from 'lodash';
import React, { useContext, useState } from 'react';

import CoreLink from '@reverbdotcom/commons/src/components/core_link';
import SanitizedRender from '@reverbdotcom/commons/src/components/sanitized_render';
import { ShopBadges } from '@reverbdotcom/commons/src/components/shop_badges';
import { useUser } from '@reverbdotcom/commons/src/user_hooks';
import { ADMINISTRATOR_PERMISSION_LEVEL } from '@reverbdotcom/commons/src/constants';
import { RCIcon, buttonClassName, RCAlertBox, RCTextWithIcon } from '@reverbdotcom/cadence/components';
import { CommentOIcon, MagicIcon, CartIcon } from '@reverbdotcom/cadence/icons/react';
import CouponIcon from '@reverbdotcom/cadence/images/nudge/coupon.svg?component';

import DEFAULT from '../../../../images/default-avatar.jpg';
import {
  Core_Shop_ContainerQuery,
} from '@reverbdotcom/commons/src/gql/graphql';
import URLHelpers, { adminEditUserPath, shopPromotePath } from '../../shared/url_helpers';
import FavoriteShopCTA from '../../../../src/components/favorites/favorite_shop_cta';
import ShopCampaignCouponPreview, { buildFinePrint } from '../../selling/shop_campaigns/ShopCampaignCouponPreview';
import { ModalDialog } from '@reverbdotcom/commons';
import CouponContext, { Coupon } from '@reverbdotcom/commons/src/coupon_context';

type Shop = Core_Shop_ContainerQuery['shop'];

function OwnerLinks() {
  return (
    <ul className="mt-1">
      <li className="d-inline-block mtb-1 mlr-1">
        <CoreLink
          to={URLHelpers.shopEditPath()}
          className="button button--small"
        >
          {I18n.t('discovery.shopHeader.editShop')}
        </CoreLink>
      </li>
      <li className="d-inline-block mtb-1 mlr-1">
        <CoreLink
          to={shopPromotePath}
          className="button button--small"
        >
          {I18n.t('discovery.shopHeader.promoteShop')}
        </CoreLink>
      </li>
    </ul>
  );
}

function Placeholder() {
  const classes = classNames(
    'shop-headerv2',
    'shop-headerv2--placeholder',
    'shop-headerv2--with-banner',
  );

  return (
    <div className={classes}>
      <div className="shop-headerv2__banner" />
      <div className="shop-headerv2__main">
        <div className="shop-headerv2__main__avatar" />
        <div className="shop-headerv2__main__text" />
      </div>
    </div>
  );
}

function BadgeContainer({ shop, stacked }: { shop: Shop, stacked: boolean }) {
  return (
    <div className="shop-headerv2__main__badges">
      <ShopBadges
        preferredSeller={shop.preferredSeller}
        quickResponder={shop.quickResponder}
        quickShipper={shop.quickShipper}
        stacked={stacked}
      />
    </div>
  );
}

function BuyerCoupon({ coupon }: { coupon: Coupon, shopName: string }) {
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  return (
    <>
      <span className="shop-headerv2__buyer-coupon-callout">
        <RCTextWithIcon svgComponent={CouponIcon} placement="left">
          <span className="shop-headerv2__buyer-coupon-available">
            {I18n.t('discovery.shopCoupons.couponAvailable', { discountValue: coupon.discountValue })}
          </span>
          <button type="button" className="shop-headerv2__buyer-coupon-modal-trigger"
            onClick={() => setShowDetailsModal(true)}>
            {I18n.t('discovery.shopCoupons.seeDetails')}
          </button>
        </RCTextWithIcon>
      </span>
      <ModalDialog
        isOpen={showDetailsModal}
        headerTitle={I18n.t('discovery.shopCoupons.couponDetails')}
        onSubmit={() => setShowDetailsModal(false)}
        onRequestClose={() => setShowDetailsModal(false)}
        saveButtonText={I18n.t('discovery.shopCoupons.gotIt')}
        hideFooterDismissButton
      >
        <ShopCampaignCouponPreview headerText={coupon.couponHeader}>
          <p>{coupon.descriptionSummary}</p>

          <p className="shop-headerv2__buyer-coupon-fine-print">
            {
              buildFinePrint(
                coupon.restrictionsSummary,
                coupon.excludedBrands.map(({ name }) => name),
                coupon.excludedCategories.map(({ name }) => name),
              )
            }
          </p>
        </ShopCampaignCouponPreview>
      </ModalDialog>
    </>
  );
}

interface IProps {
  shop: Shop;
  initialFavoriteId: string | null;
}

export const ShopHeaderFragment = gql`
  fragment ShopHeaderFragment on Shop {
    _id
    id
    slug
    name
    preferredSeller
    quickShipper
    quickResponder
    description
    userUuid
    userId
    address {
      _id
      displayLocation
    }
    banner {
      _id
      source
    }
    user {
      _id
      id
      avatar {
        _id
        source
      }
    }
  }
`;

export function ShopHeader({ shop, initialFavoriteId }: IProps) {
  const user = useUser();

  const shouldRenderFreeShippingCallout = !!shop?.freeDomesticShippingRate && shop?.freeDomesticShippingRate?.regionCode === user.shippingRegionCode;
  const isOwner = `${user.id}` === shop?.userId;
  const isOwnerInSecondaryUserMode = !!user.secondaryUserModeIsActive && user.managedShopPermission == ADMINISTRATOR_PERMISSION_LEVEL && user.managedShopUserId == shop?.userId;
  const shouldRenderOwnerLinks = isOwner || isOwnerInSecondaryUserMode;
  const avatarUrl = get(shop, 'user.avatar.source', false);
  const bannerUrl = get(shop, 'banner.source');
  const { coupon } = useContext(CouponContext);

  if (!shop) return (<Placeholder />);

  const classes = classNames(
    'shop-headerv2',
    { 'shop-headerv2--with-banner': !!bannerUrl },
  );

  return (
    <div className={classes}>
      {bannerUrl && (
        <div className="shop-headerv2__banner">
          <img className="shop-headerv2__banner__bg" src={bannerUrl} alt={shop.name} />
          <div className="shop-headerv2__banner__blur" />
          <img className="shop-headerv2__banner__main" src={bannerUrl} alt={shop.name} />
        </div>
      )}
      <div className="shop-headerv2__main">
        <div className="shop-headerv2__main__avatar">
          <img src={avatarUrl || DEFAULT} alt={shop.name} />
        </div>
        <div className="shop-headerv2__main__text">
          <h1 className="shop-headerv2__title">
            <span>
              {shop.name}
              {user.isAdmin && (
                <a
                  href={adminEditUserPath.expand({ id: shop.userId })}
                  data-edit-link
                  className="shop-headerv2__admin-link"
                >
                  <RCIcon svgComponent={() => <MagicIcon />} inline />
                </a>
              )}
            </span>
            <FavoriteShopCTA
              shop={shop}
              initialFavoriteId={initialFavoriteId}
              parentComponentName="ShopHeader"
              showCallout
            />

          </h1>
          <ul className="shop-headerv2__details">
            <li>
              <span className="icon-l-map-marker">
                {shop.address.displayLocation}
              </span>
            </li>
            <li>
              <a
                href={URLHelpers.newDashboardMessagesPath(null, shop.userUuid)}
                className={buttonClassName({ variant: 'muted', theme: 'main', size: 'mini' })}
              >
                <span className="mr-2">
                  <RCIcon svgComponent={() => <CommentOIcon />} inline />
                </span>
                {I18n.t('discovery.shopHeader.message')}
              </a>
            </li>
          </ul>
          <div className="shop-headerv2__bio">
            <SanitizedRender
              html={shop.description}
              truncationLimit={200}
              htmlTag="div"
              convertNewlines
            />
          </div>
          {coupon && (
            <div className="shop-headerV2__buyer-coupons">
              <BuyerCoupon coupon={coupon} shopName={shop.name} />
            </div>
          )}
          <>
            {shouldRenderFreeShippingCallout && (
              <div className="shop-headerv2__free-shipping-alert">
                <RCAlertBox type="success" inline>
                  <RCTextWithIcon
                    svgComponent={CartIcon}
                    placement="left"
                    size="large"
                  >
                    <SanitizedRender
                      html={I18n.t('discovery.shopHeader.freeShipping', {
                        tagOpen: '<strong>',
                        tagClose: '</strong>',
                        shopName: shop.name,
                        displayAmount: shop.freeDomesticShippingRate.freeShippingThreshold.display,
                      })
                      }
                    />
                  </RCTextWithIcon>
                </RCAlertBox>
              </div>
            )}
            {shouldRenderOwnerLinks && <OwnerLinks />}
          </>
        </div>
        <BadgeContainer shop={shop} stacked />
      </div>
    </div>
  );
}

export default ShopHeader;
