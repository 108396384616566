import Constants from '../shared/constants';
import { includes } from 'lodash';

const SUPPORTED_REGION_CODES = [
  'US_CON',
  'US_AK',
  'US_HI',
  'US_PR',
  'US_OTHER',
  'GB',
  'CA',
  'AU',
  'DE',
  'FR',
  'NL',
  'JP',
  'IT',
  'ES',
  'SE',
  'CH',
  'NZ',
  'BE',
  Constants.EVERYWHERE_CODE,
];

export function cspShippingRegionSupported(region) {
  return includes(SUPPORTED_REGION_CODES, region);
}

export function learnContentEligibleRegion(locale: string) {
  return locale === 'en';
}
