import React from 'react';

import { RCImage } from '@reverbdotcom/cadence/components';

import BLANK_MEDIUM_IMAGE from '../../../images/products/blank_medium.jpg';

export default function PlaceholderSellerListingCard() {
  return (
    <div className="seller-listing-card">
      <div className="seller-listing-card__content">
        <div className="seller-listing-card__content__image">
          <RCImage
            src={BLANK_MEDIUM_IMAGE}
            imageBox="fullWidth"
          />
        </div>

        <div className="width-100">
          <div className="seller-listing-card__content__details-header">
            <div className="padding-2 bg-offwhite width-100"></div>
          </div>
          <ul className="mt-4">
            <li className="padding-2 bg-offwhite width-20"></li>
            <li className="padding-2 bg-offwhite width-20"></li>
            <li className="padding-2 bg-offwhite width-20"></li>
          </ul>
        </div>
      </div>
      <div className="padding-8">
        <div className="padding-2 bg-offwhite width-20"></div>
      </div>
    </div>
  );
}
