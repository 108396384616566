const KLARNA_PAY_OVER_TIME_COUNTRIES = [
  'CA',
  'ES',
  'GB',
] as const;

interface KlarnaMerchant {
  clientId: string;
}

type SupportedKlarnaCountries = typeof KLARNA_PAY_OVER_TIME_COUNTRIES;

type CountryCode = SupportedKlarnaCountries[number];

const europeMerchant: KlarnaMerchant = {
  clientId: 'dfcff30e-8aac-574b-aa61-1b7780562fda',
};

const canadaMerchant: KlarnaMerchant = {
  clientId: '2c0671f5-8fbd-5521-9346-23001f6153ef',
};

const countryMerchant: Record<CountryCode, KlarnaMerchant> = {
  CA: canadaMerchant,
  ES: europeMerchant,
  GB: europeMerchant,
};

export function findKlarnaClientID(countryCode: string): string {
  if (!countryCode) {
    return null;
  }

  const merchant = countryMerchant[countryCode.toUpperCase()];
  return merchant?.clientId;
}
