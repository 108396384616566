import alt, { AltStoreModel } from '../../alt';
import CategoriesActions from '../actions/categories_actions';

class CategoriesStore extends AltStoreModel {
  constructor() {
    super();
    this.state = {
      categories: [],
    };

    this.bindListeners({
      handleLoaded: CategoriesActions.LOADED,
    });
  }

  handleLoaded(response) {
    this.setState({ categories: response.categories });
  }
}

export default alt.createStore(CategoriesStore, 'CategoriesStore');
