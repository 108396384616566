// TODO update this to import from commons
// import { gql } from '@reverbdotcom/commons/src/gql';
// eslint-disable-next-line no-restricted-imports
import { gql } from '@apollo/client';
import I18n from 'i18n-js';
import React from 'react';

import { CheckIcon } from '@reverbdotcom/cadence/icons/react';
import { RCTextWithIcon } from '@reverbdotcom/cadence/components';

import { SellFormKeyValueList } from './form/components/SellFormKeyValueList';
import { CARRIER_CALC_RATE_TYPE, REGIONAL_RATE_TYPE } from './form/components/shipping/ShippingForm';
import { core_apimessages_ShippingLabelPackage, core_apimessages_ShippingRates } from '@reverbdotcom/commons/src/gql/graphql';
import { displayWeight, lengthWidthHeightSummary } from '../discovery/shipping_labels/shipping_label_package';

export const ShippingProfilePricesPricesFragment = gql`
  fragment ShippingProfilePrices on Shop {
    _id
    shippingProfiles {
      name
      id
      categoryUuids
      localPickup
      allShippingRates(input: { inOriginalCurrency: true }) {
        regionCode
        regionName
        rateType
        rate {
          display
        }
        incrementalRate {
          display
        }
        expeditedRate {
          display
        }
        regionalRates {
          areas
          rate {
            display
          }
        }
        regionalExpeditedRates {
          areas
          rate {
            display
          }
        }
      }
    }
  }
`;

enum PriceField {
  STANDARD = 'rate',
  COMBINED = 'incrementalRate',
  EXPEDITED = 'expeditedRate',
}

interface IProps {
  offersShipping: boolean;
  offersLocalPickup: boolean;
  shippingRates: core_apimessages_ShippingRates[];
  shipmentPackage?: core_apimessages_ShippingLabelPackage;
}

function RegionalRateSummary({ shippingRate, type }: { shippingRate: core_apimessages_ShippingRates, type: PriceField }) {
  const isExpedited = type === PriceField.EXPEDITED;
  const rates = isExpedited ? shippingRate.regionalExpeditedRates : shippingRate.regionalRates;

  return (
    <>
      {!!rates && rates.map((regionalRate) =>
        <React.Fragment key={regionalRate.rate.display}>
          <div className="mb-1">
            <span className="mr-1">
              <strong>
                {I18n.t(`discovery.sellForm.shippingSection.rateType.regional${isExpedited ? 'Expedited' : ''}Rate`)}
              </strong>
            </span>
            <span className="capitalize">
              {regionalRate.rate.display.toLowerCase()}
            </span>
            <div className="color-secondary">
              {regionalRate.areas.map((area) => I18n.t(`discovery.regionalShippingAreas.${shippingRate.regionCode}.${area}`)).sort().join(', ')}
            </div>
          </div>
          <hr/>
        </React.Fragment>,
      )}
    </>
  );
}

function ShippingRateSummary({ shippingRate }: { shippingRate: core_apimessages_ShippingRates }) {
  const hasRegionalRates = shippingRate.rateType === REGIONAL_RATE_TYPE && shippingRate.regionalRates.length > 0;
  const hasRegionalExpeditedRates = shippingRate.rateType === REGIONAL_RATE_TYPE && shippingRate.regionalExpeditedRates.length > 0;
  const standardShippingOnly = shippingRate.rate?.display && !shippingRate.incrementalRate?.display && !shippingRate.expeditedRate?.display
    && !hasRegionalRates && !hasRegionalExpeditedRates;

  return (
    <>
      {[PriceField.STANDARD, PriceField.COMBINED, PriceField.EXPEDITED].map((type) => (
        (shippingRate[type]?.display || (type === PriceField.EXPEDITED && hasRegionalExpeditedRates)) && (
          <React.Fragment key={type}>
            {shippingRate[type]?.display && (
              <div className="mb-1">
                {!standardShippingOnly && (
                  <span className="mr-1">
                    <strong>
                      {I18n.t(`discovery.sellForm.shippingSection.rateType.${type}`)}
                    </strong>
                  </span>
                )}
                <span className="capitalize">
                  {shippingRate[type]?.display.toLowerCase()}
                </span>
              </div>
            )}
            {!standardShippingOnly && shippingRate[type]?.display && (
              <hr/>
            )}
            {shippingRate.rateType === REGIONAL_RATE_TYPE && (
              <RegionalRateSummary
                shippingRate={shippingRate}
                type={type}
              />
            )}
          </React.Fragment>
        )
      ))}
    </>
  );
}

function PackageSummary({ shipmentPackage }: { shipmentPackage: core_apimessages_ShippingLabelPackage }) {
  const canSummarizePackage = !!lengthWidthHeightSummary(shipmentPackage) && !!displayWeight(shipmentPackage.weight);
  const carrierCalculatedSummaryText = I18n.t('discovery.sellForm.shippingSection.carrierCalculatedSummary');

  return (
    <>
      <div className="mb-1 size-110">
        {carrierCalculatedSummaryText}
      </div>

      {canSummarizePackage && (
        <>
          <div className="mb-1">
            <span className="mr-1">
              <strong>
                {I18n.t('discovery.sellForm.shippingSection.packageLengthSummary')}
              </strong>
            </span>
            <span>
              {lengthWidthHeightSummary(shipmentPackage)}
            </span>
          </div>
          <div>
            <span className="mr-1">
              <strong>
                {I18n.t('discovery.sellForm.shippingSection.packageWeightSummary')}
              </strong>
            </span>
            <span>
              {displayWeight(shipmentPackage.weight)}
            </span>
          </div>
          {shipmentPackage.allowedCarriers?.length && (
            <div>
              <span className="mr-1">
                <strong>
                  {I18n.t('discovery.sellForm.shippingSection.packageCarrier')}
                </strong>
              </span>
              <span>
                {shipmentPackage.allowedCarriers[0]}
              </span>
            </div>
          )}
        </>
      )}
    </>
  );
}

export default function ShippingSummary({ offersShipping, offersLocalPickup, shippingRates = [], shipmentPackage }: IProps) {
  const displayShippingMethods = [
    offersShipping && I18n.t('discovery.sellForm.shippingSection.deliveryOptions.shipping'),
    offersLocalPickup && I18n.t('discovery.sellForm.shippingSection.deliveryOptions.localPickup'),
  ].filter(v => v);

  return (
    <div>
      <div data-delivery-options>
        <strong>
          {I18n.t('discovery.sellForm.shippingSection.deliveryOptionsHeader')}
        </strong>
        {displayShippingMethods.map((method) => (
          <div key={method} className="mt-2">
            <RCTextWithIcon svgComponent={CheckIcon} placement="left">
              {method}
            </RCTextWithIcon>
          </div>
        ))}
      </div>

      {offersShipping && shippingRates.length > 0 && (
        <div data-shipping-rates-list>
          <div className="mt-6">
            <strong>
              {I18n.t('discovery.sellForm.shippingSection.costsPerRegion')}
            </strong>
          </div>
          <SellFormKeyValueList>
            {shippingRates.map((sr) => (
              <SellFormKeyValueList.Row
                key={sr.regionCode}
                label={I18n.t(`discovery.shippingRegions.${sr.regionCode}`)}
                singleColumn={sr.rateType === REGIONAL_RATE_TYPE}
              >
                {sr.rateType === CARRIER_CALC_RATE_TYPE && <PackageSummary shipmentPackage={shipmentPackage} />}

                {sr.rateType !== CARRIER_CALC_RATE_TYPE && <ShippingRateSummary shippingRate={sr} />}
              </SellFormKeyValueList.Row>
            ))}
          </SellFormKeyValueList>
        </div>
      )}
    </div>
  );
}
