import React from 'react';
import I18n from 'i18n-js';
import classNames from 'classnames';
import FormGroupWithRadio from '@reverbdotcom/commons/src/components/form_group_with_radio';
import { formatBid, bumpRatePercentToDecimal } from './bump_helpers';
import {
  MAX_BUMP_AMOUNT_PERCENT,
  MIN_BUMP_AMOUNT_PERCENT,
  BUMP_INCREMENT_AMOUNT_PERCENT,
} from '../shared/constants';
import { RCTag, RCAlertBox } from '@reverbdotcom/cadence/components';
import { I18N as Translate } from '@reverbdotcom/commons/src/components/translate';

function RecommendedRate({ percent, redesignEnabled }) {
  if (redesignEnabled) {
    return (
      <Translate text="discovery.bumpModal.recommendedRate">
        <b><Translate text="discovery.bumpModal.recommendedRateAmount" args={{ percent }}></Translate></b>
      </Translate>
    );
  } else {
    return <span>{I18n.t('discovery.bumpModalV2.copyV2.recommendedRate', { percent })}</span>;
  }
}

export function RecommendedRadio({
  recommendationAmount,
  selectedBid,
  handleBidChange,
  showCustomWarning,
  setShowCustomWarning,
  redesignEnabled = false,
}: {
  recommendationAmount: number;
  selectedBid: number;
  handleBidChange: (bid: number) => void;
  showCustomWarning: boolean;
  setShowCustomWarning: (boolean) => void;
  redesignEnabled?: boolean;
}) {
  const [usingCustom, setUsingCustom] = React.useState(
    (selectedBid !== 0 && ((bumpRatePercentToDecimal(recommendationAmount)) !== selectedBid)),
  );

  const [usingRecommended, setUsingRecommended] = React.useState(
    !usingCustom,
  );
  const [usingNoBump, setUsingNoBump] = React.useState(
    false,
  );

  const [customBid, setCustomBid] = React.useState(
    (selectedBid !== 0) ? (formatBid(selectedBid)) : recommendationAmount,
  );

  React.useEffect(() => {
    if (usingRecommended) {
      handleBidChange(bumpRatePercentToDecimal(recommendationAmount));
    }
  }, [usingRecommended]);

  const handleRecommendedClick = (): void => {
    setUsingRecommended(true);
    setUsingCustom(false);
    setUsingNoBump(false);
    setShowCustomWarning(false);

    handleBidChange(bumpRatePercentToDecimal(recommendationAmount));
  };

  const handleManualClick = (): void => {
    setUsingRecommended(false);
    setUsingCustom(true);
    setUsingNoBump(false);

    setCustomWarning(customBid);
    handleBidChange(bumpRatePercentToDecimal(customBid));
  };

  const handleNoBumpClick = (): void => {
    setUsingRecommended(false);
    setUsingCustom(false);
    setUsingNoBump(true);
    setShowCustomWarning(false);

    handleBidChange(0);
  };

  const handleCustomSelectChange = (event: any) => {
    const bid = event?.target?.value;
    setCustomWarning(bid);
    setCustomBid(bid);
    handleBidChange(bumpRatePercentToDecimal(bid));
  };

  const setCustomWarning = (bid: number) => {
    if ((bid >= MIN_BUMP_AMOUNT_PERCENT) && (bid <= MAX_BUMP_AMOUNT_PERCENT) && (bid % BUMP_INCREMENT_AMOUNT_PERCENT === 0)) {
      setShowCustomWarning(false);
    } else {
      setShowCustomWarning(true);
    }
  };

  return (
    <>
      <div className={classNames(
        'bd-2 bd-radius-md padding-4',
        { 'bd-color-selected': usingRecommended },
        { 'bd-color-selectable': !(usingRecommended) },
      )}>
        <div className="d-flex fx-justify-between">
          <FormGroupWithRadio
            label={<RecommendedRate percent={recommendationAmount} redesignEnabled={redesignEnabled} />}
            inputName="bump-modal-radios"
            id="bump-modal-radio-recommended"
            updateField={handleRecommendedClick}
            value={`${recommendationAmount / 100}`}
            checked={usingRecommended}
          />
          <div className="ml-2">
            <RCTag variant={redesignEnabled ? 'highlight' : null}>
              {I18n.t('discovery.bumpModal.recommended')}
            </RCTag>
          </div>
        </div>
        <p className="size-90 mb-0">
          {redesignEnabled ?
            I18n.t('discovery.bumpModal.recommendedRateDescription') :
            I18n.t('discovery.bumpModalV2.copyV2.recommendedRateDescription')
          }
        </p>
      </div>

      <div className={classNames(
        'bd-2 bd-radius-md padding-4 mt-2',
        { 'bd-color-selected': usingCustom },
        { 'bd-color-selectable': !(usingCustom) },
      )}>
        <FormGroupWithRadio
          label={redesignEnabled ?
            I18n.t('discovery.bumpModal.customRate') :
            I18n.t('discovery.bumpModalV2.copyV2.customRate')
          }
          inputName="bump-modal-radios"
          id="bump-modal-radio-custom"
          updateField={handleManualClick}
          value={`${recommendationAmount / 100}`}
          checked={usingCustom}
        />
        {
          usingCustom &&
          <div className="width-20 mobile-width-100">
            <div className="input-group">
              <input
                type="number"
                className="input-group__input"
                disabled={!(usingCustom)}
                value={customBid}
                onChange={handleCustomSelectChange}
              />
              <div className="input-group__text">%</div>
            </div>
          </div>
        }
        {
          showCustomWarning && <div className="mt-2">
            <RCAlertBox type="error" small>
              {I18n.t('discovery.bumpModalV2.modal.customBumpWarning', {
                minBump: MIN_BUMP_AMOUNT_PERCENT,
                maxBump: MAX_BUMP_AMOUNT_PERCENT,
                bumpIncrement: BUMP_INCREMENT_AMOUNT_PERCENT,
              })}
            </RCAlertBox>
          </div>
        }
      </div>

      <div className={classNames(
        'bd-2 bd-radius-md padding-4 mt-2',
        { 'bd-color-selected': usingNoBump },
        { 'bd-color-selectable': !(usingNoBump) },
      )}>
        <FormGroupWithRadio
          label={I18n.t('discovery.bumpModalV2.copyV2.optOut')}
          inputName="bump-modal-radios"
          id="bump-modal-radio-no-bump"
          updateField={handleNoBumpClick}
          value={`${0}`}
          checked={usingNoBump}
        />
      </div>
    </>
  );
}
