import React from 'react';

interface IExternalProps {
  text: string;
}

export default function PriceRecordsEmptyState({ text }: IExternalProps) {
  return (
    <>
      <p className="align-center mb-0 pt-12 pb-4">
        {text}
      </p>
    </>
  );
}
