import React from 'react';
import I18n from 'i18n-js';
import {
  Shop,
  core_apimessages_Shop,
} from '@reverbdotcom/commons/src/gql/graphql';
import URLHelpers from '../shared/url_helpers';

type FeedbackShop = Shop | core_apimessages_Shop;

interface IProps {
  shop?: FeedbackShop;
  userName?: string;
}

export default class FeedbackShopLink extends React.Component<IProps, null> {
  renderShopLink() {
    const { shop } = this.props;

    return (
      <a
        className="shop-icon-link"
        href={URLHelpers.shopPath(shop.slug)}
      >
        {shop.name}
      </a>
    );
  }

  render() {
    return (
      <div>
        <span className="strong">
          {I18n.t('discovery.feedbackShopInfo.leavingFeedbackFor')}
        </span>
        <p className="strong">
          {this.props.shop ? this.renderShopLink() : this.props.userName}
        </p>
      </div>
    );
  }
}
