import React from 'react';
import classNames from 'classnames';

interface IPercentageBarProps {
  color?: 'green' | 'dark-gray' | 'light-gray';
  percentage?: number;
  animate?: boolean;
}

export default function PercentageBar({
  color,
  percentage,
  animate,
}: IPercentageBarProps) {
  const classes = classNames(
    'percentage-bar',
    { 'percentage-bar--green': color === 'green' },
    { 'percentage-bar--dark-gray': color === 'dark-gray' },
    { 'percentage-bar--light-gray': color === 'light-gray' },
    { 'percentage-bar--animated': animate },
  );

  return (
    <div className={classes}>
      <div
        className="percentage-bar__progress"
        style={{ width: `${percentage}%` }}
      />
    </div>
  );
}
