import {
  core_apimessages_Error as Error,
  core_apimessages_CheckoutPaymentMethod_Type as CheckoutPaymentMethodType,
  Input_core_apimessages_FinalizeCardCheckoutPaymentRequest as FinalizeCardCheckoutPaymentRequest,
  Core_Checkout_FinalizeCardPaymentMutation,
  CheckoutCardPaymentHookMeFieldsFragment,
  CheckoutCardPaymentHookCheckoutFieldsFragment,
} from '@reverbdotcom/commons/src/gql/graphql';
import React from 'react';
import { browserInfo } from '../../credit_cards/browser_info';
import { mapAddressToEntry } from '../../lib/addressEntryMapping';
import { ExecutionResult } from 'graphql';
import { useMutation } from '@reverbdotcom/commons/src/useMutation';
import { gql } from '@reverbdotcom/commons/src/gql';
import { buildDeviceInfoPayload } from '@reverbdotcom/commons/src/accertify';

type Checkout = CheckoutCardPaymentHookCheckoutFieldsFragment;
type Me = CheckoutCardPaymentHookMeFieldsFragment;

type CreditCardInput = Partial<Pick<FinalizeCardCheckoutPaymentRequest, 'creditCardId' | 'creditCard'>>;

const HIDDEN_ERROR_FIELDS = [
  'brand_type',
  'last_digits',
  'card_bin',
];

export interface CardPaymentForm {
  type: CheckoutPaymentMethodType.DIRECT_CHECKOUT;
  showCardWallet: boolean;
  update: (input: CreditCardInput) => void;
  submit: () => Promise<ExecutionResult<Core_Checkout_FinalizeCardPaymentMutation>>;
  errors: Error[];
  loading: boolean;
  result: Core_Checkout_FinalizeCardPaymentMutation['finalizeCardCheckoutPayment'];
}

export function useCheckoutCardPaymentForm({ checkout, me }: {
  checkout: Checkout,
  me: Me,
}): CardPaymentForm {
  const [mutate, { data, errors, loading }] = useMutation(FINALIZE_CARD_PAYMENT_MUTATION);
  const [requestInput, setRequestInput] = React.useState<CreditCardInput>({});
  const showCardWallet = !!me?.creditCards?.length;

  const update = React.useCallback((input: CreditCardInput) => {
    setRequestInput(prevInput => ({ ...prevInput, ...input }));
  }, [setRequestInput]);

  async function submit() {
    return mutate({
      variables: {
        input: {
          id: checkout.id,
          browserInfoJson: JSON.stringify(browserInfo()),
          displayedAmountOwed: {
            amountCents: checkout.total.amount.amountCents,
            currency: checkout.total.amount.currency,
          },
          creditCardId: requestInput.creditCardId,
          accertifyDeviceInfo: buildDeviceInfoPayload(),
          ...mapCreditCard(requestInput),
        },
      },
    });
  }

  return {
    type: CheckoutPaymentMethodType.DIRECT_CHECKOUT,
    showCardWallet,
    update,
    submit,
    errors: filterErrors(errors),
    loading,
    result: data?.finalizeCardCheckoutPayment,
  };
}

function filterErrors(errors: Error[]) {
  return errors.filter((error) => !HIDDEN_ERROR_FIELDS.includes(error.field));
}

function mapCreditCard(requestInput: CreditCardInput) {
  const creditCard = requestInput?.creditCard;

  if (!creditCard) {
    return {};
  }

  return {
    creditCard: {
      ...creditCard,
      address: mapAddressToEntry(creditCard.address),
    },
  };
}

export const fragments = {
  checkout: gql(`
    fragment CheckoutCardPaymentHookCheckoutFields on Checkout {
      _id
      id
      total {
        amount {
          amountCents
          currency
        }
      }
    }
  `),
  me: gql(`
    fragment CheckoutCardPaymentHookMeFields on rql_Me {
      _id
      uuid
      creditCards {
        id
      }
    }
  `),
};

export const FINALIZE_CARD_PAYMENT_MUTATION = gql(`
  mutation Core_Checkout_FinalizeCardPayment(
    $input: Input_core_apimessages_FinalizeCardCheckoutPaymentRequest,
  ) {
    finalizeCardCheckoutPayment(input: $input) {
      checkout {
        _id
      }
    }
  }
`);
