export default {
  RCAlertBox: {
    dismissAriaLabel: 'Dismiss alert',
  },
  RCCloseButton: {
    defaultLabel: 'Close',
  },
  RCCombobox: {
    inputPlaceholder: 'Select one...',
    noResults: 'No results',
    clearInput: 'Clear input',
    search: 'Search',
  },
  RCEmailInput: {
    emailValidationError: 'Please enter a valid email address.',
  },
  RCGuidePopover: {
    gotIt: 'Got It',
  },
  RCLoadingBars: {
    ariaLabel: 'Loading',
  },
  RCPasswordInput: {
    showPassword: 'Show Password',
    hidePassword: 'Hide Password',
  },
  RCSelect: {
    selectOne: 'Select one…',
  },
  RCStarRating: {
    ratingText: '{{rating}} out of 5 stars',
    ariaLabel: 'Rating: {{rating}} out of 5 stars',
    ariaLabelWithCount: {
      one: 'Rating: {{rating}} out of 5 stars (1 review)',
      other: 'Rating: {{rating}} out of 5 stars ({{count}} reviews)',
    },
  },
  RCStarRatingSelector: {
    altLabel: {
      one: '1 Star',
      other: '{{count}} Stars',
    },
  },
  RCToast: {
    linkDefault: 'View',
    closeDialog: 'Close Dialog',
  },
  RCTooltip: {
    ariaLabel: 'Toggle tooltip',
  },
  shared: {
    close: 'Close',
    open: 'Open',
  },
  RCChip: {
    applied: '({{count}} applied)',
  },
  RCModal: {
    cancel: 'Cancel',
    save: 'Save',
  },
  RCFavoriteButton: {
    watch: 'Add to Watch List',
    unwatch: 'Remove from Watch List',
  },
};
