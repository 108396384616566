import PropTypes from 'prop-types';
import React from 'react';

const RelatedArticleView = ({ article }) =>
  (
    <div className="blog-post-related-article">
      <div className="pb-1">
        <a href={article._links.web.href}>
          <img alt={article.title} src={article.photo} />
        </a>
      </div>
      <a
        href={article._links.web.href}
        className="related-article-title strong"
      >
        {article.title}
      </a>
    </div>
  );

RelatedArticleView.propTypes = {
  article: PropTypes.object.isRequired,
};

export default RelatedArticleView;
