import PropTypes from 'prop-types';
import React from 'react';

const RoutingNumberField = (props) => {
  return (
    <div>
      <input
        className="string required wide user-success"
        maxLength={9}
        type="text"
        name={props.name}
      />
    </div>
  );
};

RoutingNumberField.propTypes = {
  name: PropTypes.string.isRequired,
};

export default RoutingNumberField;
