import classNames from 'classnames';
import React from 'react';
import I18n from 'i18n-js';
import { sortBy } from 'lodash';
import FormGroup from '@reverbdotcom/commons/src/components/form_group';
import FormGroupWithInput from '@reverbdotcom/commons/src/components/form_group_with_input';
import { RCAlertBox, RCButton, useMediaQuery } from '@reverbdotcom/cadence/components';
import CoreClient from '../../../lib/core_client';
import BulkSaleRow from './bulk_sale_row';
import URLHelpers from '../../shared/url_helpers';
import { ModalDialog } from '@reverbdotcom/commons';
import { useViewTracking } from '@reverbdotcom/commons/src/use_tracking';
import { MParticleEventName } from '@reverbdotcom/commons/src/elog/mparticle_types';

const COMPONENT_NAME = 'BulkSaleModalButton';

// This is hard-coded from the V3 /my/listings/curated_sets response
export interface ICuratedSet {
  add_count: number;
  id: number;
  label: string;
  lp_only: boolean;
  name: string;
  remove_count: number;
  summary: string;
  used_only: boolean;
  starts_at: string;
  ends_at: string;
  status: SaleStatus;
  discount_percent?: number;
  discount_value?: string;
  reverb_sale: boolean;
  featured: boolean;
  additional_information?: string;
  _links: {
    self: {
      href: string;
    };
  };
  unavailable_to_add_reason?: string;
  zero_percent_affirm_eligible?: boolean;
}

export interface ICuratedSetsResponse {
  curated_sets: ICuratedSet[];
}

export enum SaleStatus {
  LIVE = 'live',
  UPCOMING = 'upcoming',
  ENDED = 'ended',
  ALL = 'all',
}

enum SortFacetState {
  ALPHA = 'alpha',
  ALPHA_DESC = 'alphaDesc',
  ENDING_SOONEST = 'endingSoonest',
}

interface IProps {
  listingsCount: number;
}

interface IModalBodyProps {
  listingsCount: number;
  curatedSets: ICuratedSet[];
  loading: boolean;
  fetchError: boolean;
  queryString: string;
}

interface IFacetsState {
  promotedOnly: boolean;
  statusFilter: SaleStatus;
  userQuery: string;
  sort: SortFacetState;
}

interface IFacetSectionProps {
  facetsState: IFacetsState;
  dispatch: Function;
}

interface IFacetButtonAttributes {
  buttonText: string;
  isSelected: boolean;
  onClick: () => {};
}

interface IFacetButtonGroupProps {
  title: string;
  facets: IFacetButtonAttributes[];
}

const defaultFacetsState: IFacetsState = {
  promotedOnly: true,
  statusFilter: SaleStatus.ALL,
  userQuery: '',
  sort: SortFacetState.ALPHA,
};

function shouldDisplayRow(facetsState: IFacetsState, set) {
  let passesPromotedOnlyFilter;
  let passesStatusFilter;
  let matchesUserQuery;

  // check if sale matches selected public/private type facet
  if (facetsState.promotedOnly) {
    passesPromotedOnlyFilter = set.reverb_sale;
  } else {
    passesPromotedOnlyFilter = !set.reverb_sale;
  }

  // check if sale matches selected status facet
  if (facetsState.statusFilter === SaleStatus.ALL) {
    passesStatusFilter = true;
  } else {
    passesStatusFilter = set.status === facetsState.statusFilter;
  }

  // check if sale name matches current search query
  if (facetsState.userQuery === '') {
    matchesUserQuery = true;
  } else {
    matchesUserQuery = set.name.toLowerCase().includes(facetsState.userQuery.toLowerCase());
  }

  // featured sales are immune to status/user query filters
  if (set.featured) {
    return passesPromotedOnlyFilter;
  }

  return passesPromotedOnlyFilter && passesStatusFilter && matchesUserQuery;
}

function showEmptySection(facetsState: IFacetsState, curatedSets: ICuratedSet[]): boolean {
  if (curatedSets.length === 0) return true;

  return curatedSets.every((set) => !shouldDisplayRow(facetsState, set));
}

function sortByFacetState(filteredSets: ICuratedSet[], { sort }: IFacetsState) {
  switch (sort) {
    case SortFacetState.ALPHA:
      return sortBy(filteredSets, ['name']);
    case SortFacetState.ALPHA_DESC:
      return sortBy(filteredSets, ['name']).reverse();
    case SortFacetState.ENDING_SOONEST:
      return sortBy(filteredSets, ['ends_at']);
    default:
      return filteredSets;
  }
}

function facetReducer(state: IFacetsState, action): IFacetsState {
  switch (action.type) {
    case 'setPromotedOnlyFilter':
      return { ...state, promotedOnly: action.value };
    case 'setStatusFilter':
      return { ...state, statusFilter: action.value };
    case 'setUserQueryFilter':
      return { ...state, userQuery: action.value };
    case 'setSort':
      return { ...state, sort: action.value };
    case 'setDefault':
      return { ...defaultFacetsState };
    default:
      return { ...state };
  }
}

function ListingsCountCallOut({ listingsCount }: { listingsCount: number }) {
  const formattedCount = I18n.toNumber(listingsCount, {
    precision: 0,
  });

  const countCalloutText = I18n.t('discovery.selling.bulkSale.listingsSelected', {
    count: listingsCount,
    formattedCount,
  });

  if (!listingsCount || listingsCount < 1) return null;

  return (
    <RCAlertBox type="info">
      <p className="mb-0">
        {countCalloutText}
      </p>
    </RCAlertBox>
  );
}

function FacetButtonGroup({ title, facets }: IFacetButtonGroupProps) {
  return (
    <FormGroup label={title}>
      <div className="button-group button-group--full-width">
        {facets.map(({ buttonText, isSelected, onClick }) => (
          <button
            key={buttonText}
            className={classNames('button', 'button-group__button', { 'button-group__button--selected': isSelected })}
            onClick={(e) => {
              e.preventDefault();
              onClick();
            }}
          >
            <div className="tablet-ws-normal">
              {buttonText}
            </div>
          </button>
        ))}
      </div>
    </FormGroup>
  );
}

function EmptySaleSection() {
  return (
    <div className="bulk-sale-form__empty">
      <h3 className="bulk-sale-form__empty__title">{I18n.t('discovery.selling.bulkSale.noneFound')}</h3>
      <div className="bulk-sale-form__empty__link">
        <a href="/my/selling/sales" className="button button--secondary">
          {I18n.t('discovery.selling.bulkSale.createASale')}
        </a>
      </div>
    </div>
  );
}

function LoadingState() {
  return <div>{I18n.t('discovery.selling.bulkSale.loading')}</div>;
}

function ErrorState() {
  return <div>{I18n.t('discovery.selling.bulkSale.fetchError')}</div>;
}

function FacetSection({ facetsState, dispatch }: IFacetSectionProps) {
  return (
    <div className="g-container">
      {/* Public/private facets */}
      <div className="g-col-6 g-col-tablet-12 scaling-mb-4">
        <FacetButtonGroup
          title={I18n.t('discovery.selling.bulkSale.facets.saleType')}
          facets={[
            {
              buttonText: I18n.t('discovery.selling.bulkSale.facets.promoted'),
              isSelected: facetsState.promotedOnly,
              onClick: () => dispatch({ type: 'setPromotedOnlyFilter', value: true }),
            },
            {
              buttonText: I18n.t('discovery.selling.bulkSale.facets.mySales'),
              isSelected: !facetsState.promotedOnly,
              onClick: () => dispatch({ type: 'setPromotedOnlyFilter', value: false }),
            },
          ]}
        />
      </div>

      {/* Sales status facets */}
      <div className="g-col-6 g-col-tablet-12 scaling-mb-4">
        <FacetButtonGroup
          title={I18n.t('discovery.selling.bulkSale.facets.saleStatus')}
          facets={[
            {
              buttonText: I18n.t('discovery.selling.bulkSale.facets.allSales'),
              isSelected: facetsState.statusFilter === SaleStatus.ALL,
              onClick: () => dispatch({ type: 'setStatusFilter', value: SaleStatus.ALL }),
            },
            {
              buttonText: I18n.t('discovery.selling.bulkSale.facets.active'),
              isSelected: facetsState.statusFilter === SaleStatus.LIVE,
              onClick: () => dispatch({ type: 'setStatusFilter', value: SaleStatus.LIVE }),
            },
            {
              buttonText: I18n.t('discovery.selling.bulkSale.facets.upcoming'),
              isSelected: facetsState.statusFilter === SaleStatus.UPCOMING,
              onClick: () => dispatch({ type: 'setStatusFilter', value: SaleStatus.UPCOMING }),
            },
          ]}
        />
      </div>

      {/* Sort by facets */}
      <div className="g-col-6 g-col-tablet-12 scaling-mb-4">
        <FacetButtonGroup
          title={I18n.t('discovery.selling.bulkSale.facets.sortBy')}
          facets={[
            {
              buttonText: I18n.t('discovery.selling.bulkSale.facets.alpha'),
              isSelected: facetsState.sort === SortFacetState.ALPHA,
              onClick: () => dispatch({ type: 'setSort', value: SortFacetState.ALPHA }),
            },
            {
              buttonText: I18n.t('discovery.selling.bulkSale.facets.alphaDesc'),
              isSelected: facetsState.sort === SortFacetState.ALPHA_DESC,
              onClick: () => dispatch({ type: 'setSort', value: SortFacetState.ALPHA_DESC }),
            },
            {
              buttonText: I18n.t('discovery.selling.bulkSale.facets.endingSoonest'),
              isSelected: facetsState.sort === SortFacetState.ENDING_SOONEST,
              onClick: () => dispatch({ type: 'setSort', value: SortFacetState.ENDING_SOONEST }),
            },
          ]}
        />
      </div>

      {/* User query input */}
      <div className="g-col-6 g-col-tablet-12 scaling-mb-4">
        <FormGroupWithInput
          value={facetsState.userQuery}
          label={I18n.t('discovery.selling.bulkSale.facets.searchSales')}
          inputName="userQuery"
          updateField={({ userQuery }) => dispatch({ type: 'setUserQueryFilter', value: userQuery })}
        />
      </div>
    </div>
  );
}

export function ModalBody({ loading, fetchError, curatedSets, listingsCount, queryString }: IModalBodyProps) {
  const [facetsState, dispatch] = React.useReducer(facetReducer, defaultFacetsState);

  if (loading) return <LoadingState />;
  if (fetchError) return <ErrorState />;

  const featured = curatedSets.filter(({ featured }) => featured);
  const notFeatured = curatedSets.filter(({ featured }) => !featured);
  const notFeaturedSorted = sortByFacetState(notFeatured, facetsState);

  const showFeaturedSection = facetsState.promotedOnly && featured.length > 0;

  return (
    <div className="bulk-sale-form">
      <div className="bulk-sale-form__count-alert">
        <ListingsCountCallOut listingsCount={listingsCount} />
      </div>
      <div className="bulk-sale-form__filters">
        <FacetSection facetsState={facetsState} dispatch={dispatch} />
      </div>
      <>
        {showFeaturedSection && (
          <div className="bulk-sale-form__rows bulk-sale-form__rows--featured">
            {featured.map(set => (
              <BulkSaleRow
                curatedSet={set}
                key={set.id}
                queryString={queryString}
                visible={shouldDisplayRow(facetsState, set)}
              />
            ))}
          </div>
        )}
        <div className="bulk-sale-form__rows">
          {notFeaturedSorted.map(set => (
            <BulkSaleRow
              curatedSet={set}
              key={set.id}
              queryString={queryString}
              visible={shouldDisplayRow(facetsState, set)} />
          ))}
        </div>
        {showEmptySection(facetsState, curatedSets) && <EmptySaleSection />}
      </>
    </div>
  );
}

export default function BulkSaleModalButton({ listingsCount }: IProps) {
  const isMobile = useMediaQuery('mobile');
  const [curatedSets, setCuratedSets] = React.useState<ICuratedSet[]>([]);
  const [loading, setLoading] = React.useState(true);
  const [fetchError, setError] = React.useState(false);
  const [modalIsOpen, setModalIsOpen] = React.useState(false);
  const queryString = window.location.search;

  function handleOpen() {
    setModalIsOpen(true);
    setLoading(true);
    loadCuratedSets();
  }

  function handleClose() {
    setLoading(false);
    setModalIsOpen(false);
  }

  function buttonSize() {
    return isMobile ? 'medium' : 'mini';
  }

  async function loadCuratedSets() {
    try {
      setError(false);
      const request: ICuratedSetsResponse = await CoreClient.get(URLHelpers.myListingsForCuratedSet(queryString));

      const sanitizedSets = request.curated_sets.filter(
        set => !set.zero_percent_affirm_eligible,
      );
      setLoading(false);
      setCuratedSets(sanitizedSets);
    } catch {
      setLoading(false);
      setError(true);
    }
  }

  useViewTracking({
    eventName: MParticleEventName.ComponentView,
    componentName: COMPONENT_NAME,
  }, true);

  useViewTracking({
    eventName: MParticleEventName.ComponentView,
    componentName: `${COMPONENT_NAME} > ModalDialog`,
  }, modalIsOpen);

  return (
    <>
      <RCButton
        fullWidth
        size={buttonSize()}
        onClick={handleOpen}
      >
        {I18n.t('discovery.selling.bulkSale.addToSales')}
      </RCButton>

      <ModalDialog
        headerTitle={I18n.t('discovery.selling.bulkSale.modalTitle')}
        isOpen={modalIsOpen}
        onRequestClose={handleClose}
        size="wide"
        hideFooterDismissButton
        saveButtonText={I18n.t('discovery.listingsManagement.toolbar.bulkActions.sales.modal.close')}
        onSubmit={handleClose}
      >
        <ModalBody
          loading={loading}
          fetchError={fetchError}
          curatedSets={curatedSets}
          listingsCount={listingsCount}
          queryString={queryString}
        />
      </ModalDialog>
    </>
  );
}
