import PropTypes from 'prop-types';
import React from 'react';

import OrderList from './previous_orders/order_list';
import Wrapper from './previous_orders/wrapper';
import { APIRequest } from '@reverbdotcom/discovery-ui';

const PER_PAGE_AMOUNT = 4;

class PreviousOrders extends React.Component {
  static get propTypes() {
    return {
      buyerHistoryLink: PropTypes.string.isRequired,
      showSellerView: PropTypes.bool.isRequired,
      showBuyerView: PropTypes.bool.isRequired,
      negativeStates: PropTypes.array.isRequired,
      neutralStates: PropTypes.array.isRequired,
    };
  }

  constructor(props) {
    super(props);
    this.followPagination = this.followPagination.bind(this);
    this.state = {
      previousOrders: [],
      total: 0,
      loading: false,
      totalBought: null,
    };
  }

  componentDidMount() {
    this.loadPreviousOrders();
  }

  componentDidUpdate() {
    Reverb.HoverIntent.init();
  }

  fetchPreviousOrders(url, data) {
    this.setState({ loading: true });
    return APIRequest.V3.get(url, data)
      .then((response) => {
        this.setState({
          previousOrders: response.orders,
          total: response.total,
          totalBought: response.total_amount_bought,
          nextLink: response._links.next,
          prevLink: response._links.prev,
          loading: false,
        });
      })
      .fail(() => {
        this.setState({ loading: false });
      });
  }

  loadPreviousOrders() {
    return this.fetchPreviousOrders(this.props.buyerHistoryLink, { per_page: PER_PAGE_AMOUNT });
  }

  followPagination(href) {
    return () => (this.fetchPreviousOrders(href));
  }

  renderBadge() {
    if (this.props.showSellerView) {
      return (
        <span className="repeat-customer-badge">
          {this.state.total}<span className="fa fa-tags" />
        </span>
      );
    } if (this.props.showBuyerView) {
      return (
        <span className="strong small">
          <span className="handshake-icon mr-1" />
          {I18n.t('js.previousOrders.totalBuyerText', { count: this.state.total })}
        </span>
      );
    }
    return null;
  }

  render() {
    if (!this.state.previousOrders.length) { return false; }

    return (
      <Wrapper showSellerView={this.props.showSellerView} showBuyerView={this.props.showBuyerView}>
        {this.renderBadge()}
        <OrderList
          totalBought={this.state.totalBought}
          total={this.state.total}
          loading={this.state.loading}
          previousOrders={this.state.previousOrders}
          negativeStates={this.props.negativeStates}
          neutralStates={this.props.neutralStates}
          followPagination={this.followPagination}
          prevLink={this.state.prevLink}
          nextLink={this.state.nextLink}
          showSellerView={this.props.showSellerView}
          showBuyerView={this.props.showBuyerView}
        />
      </Wrapper>
    );
  }
}

export default PreviousOrders;
