import React from 'react';
import { MParticleEventName, trackEvent } from '@reverbdotcom/commons/src/elog/mparticle_tracker';
import { useViewTracking } from '@reverbdotcom/commons/src/use_tracking';
import { useUser } from '@reverbdotcom/commons/src/user_hooks';
import classNames from 'classnames';

export type TrackingDisplayStyle =
  /** Describes the Apple Pay button shown on mobile for single-item cart bundles */
  'hoisted' |
  /** Describes the Apple Pay button always shown in a cart bundle footer when available */
  'default' |
  /** Describes the Apple Pay button shown in the Buy It Now modal when available */
  'buy-now';

export type ApplePayButtonVariant = 'black' | 'white-outlined' | 'black-express' | 'white-outlined-express';

interface Props {
  variant: ApplePayButtonVariant;
  hidden: boolean;
  shouldTrack: boolean;
  tracking: {
    applePayAvailableForDevice: boolean;
    displayStyle: TrackingDisplayStyle,
  };
  onClick: (e) => void;
  small?: boolean;
}

const trackingComponentName = 'ApplePayButton';

export function ApplePayButton({
  variant,
  hidden,
  shouldTrack,
  tracking,
  onClick,
  small = false,
}: Props) {
  const { fiveDigitLocale } = useUser();

  function onButtonClick(e: React.MouseEvent<HTMLDivElement> | React.KeyboardEvent<HTMLDivElement>) {
    e.preventDefault();

    trackEvent({
      eventName: MParticleEventName.ClickedApplePayButton,
      displayStyle: tracking.displayStyle,
    });

    onClick(e);
  }

  useViewTracking({
    componentName: trackingComponentName,
    eventName: MParticleEventName.ApplePayOffered,
    ...tracking,
  }, shouldTrack);

  if (hidden) {
    return null;
  }

  const classes = classNames('apple-pay-button',
    {
      'apple-pay-button--black': variant === 'black',
      'apple-pay-button--black-express': variant === 'black-express',
      'apple-pay-button--white-outlined': variant === 'white-outlined',
      'apple-pay-button--white-outlined-express': variant === 'white-outlined-express',
      'apple-pay-button--small': small,
    },
  );

  return (
    <div
      role="button"
      tabIndex={0}
      onClick={onButtonClick}
      onKeyPress={onButtonClick}
      className={classes}
      lang={fiveDigitLocale}
    >
    </div>
  );
}
