import React from 'react';
import experiments from '@reverbdotcom/commons/src/experiments';
import { useUser, inExperiment } from '@reverbdotcom/commons/src/user_hooks';
import UserPrivacyConsentContainer, { showUserPrivacyConsent } from '../user_privacy_consent_container';
import PromotionalBannerTop, { ISiteBanner } from './promotional_banner_top';
import ReverbHeader from './reverb_header/reverb_header';
import SecondaryNagBanner from './secondary_nag_banner';
import { useMediaQuery } from '@reverbdotcom/cadence/components';

interface IProps {
  loginComponent?: React.ReactNode; // used to pass in an oauth login component from sandbox layout.tsx
  siteBanner?: ISiteBanner;
  siteBannerHasSearch?: boolean;
}

export function ReverbHeaderZone({ loginComponent, siteBanner, siteBannerHasSearch = false }: IProps) {
  const user = useUser();
  const showSecondaryNagBanner = (inExperiment(user, experiments.SECONDARY_HOMEPAGE_BANNER));
  const showUserPrivacyConsentContainer = showUserPrivacyConsent(user);
  const isMobile = useMediaQuery('mobile');
  const shouldHideSiteSearch = isMobile && siteBannerHasSearch;

  return (
    <>
      <PromotionalBannerTop siteBanner={siteBanner} />
      <ReverbHeader loginComponent={loginComponent} shouldHideSiteSearch={shouldHideSiteSearch} />
      {showSecondaryNagBanner && <SecondaryNagBanner />}
      {showUserPrivacyConsentContainer && <UserPrivacyConsentContainer />}
    </>
  );
}

export default ReverbHeaderZone;
