import React from 'react';
import { camelizeKeys } from 'humps';

import AddressFormFields from './address_form_fields';
import { LabelOverride, AddressInputField } from '../lib/address_form_fields_types';
import { IAddress, ICountry } from '../schemas/core-schema';

import { useUser } from '@reverbdotcom/commons/src/user_hooks';

interface IProps {
  address: ICoreAddress;
  countries: ICountry[];
  fieldPrefix?: string;
  requiredFieldsOverrides?: AddressInputField[];
  hiddenFieldsOverrides?: AddressInputField[];
  disabledFieldsOverrides?: AddressInputField[];
  labelOverrides?: LabelOverride;
  phoneLabelTooltip?: JSX.Element | React.ReactNode;
  trackingComponentName: string;
  autocompleteEnabled?: boolean;
}

export interface ICoreAddress extends IAddress {
  primary: boolean;
}

/**
 * This component should be used exclusively in core with relevant field prefixes. When
 * dealing with components that talk directly to RQL, use `<AddressFormFields />` instead.
 */
export function AddressFormFieldsContainer({
  address,
  countries,
  fieldPrefix,
  requiredFieldsOverrides,
  hiddenFieldsOverrides,
  disabledFieldsOverrides,
  labelOverrides,
  phoneLabelTooltip,
  trackingComponentName,
  autocompleteEnabled,
}: IProps) {
  const user = useUser();
  const countryCode = address.country_code || user.countryCode;
  const [state, setState] = React.useState({
    uuid: address.uuid || '',
    primary: address.primary || '',
    name: address.name || '',
    street_address: address.street_address || '',
    extended_address: address.extended_address || '',
    locality: address.locality || '',
    country_code: countryCode || '',
    region: address.region || '',
    postal_code: address.postal_code || '',
    phone: address.phone || '',
  });

  return (
    <AddressFormFields
      address={camelizeKeys(state)}
      countries={countries}
      fieldPrefix={fieldPrefix}
      updateField={setState}
      requiredFieldsOverrides={requiredFieldsOverrides}
      hiddenFieldsOverrides={hiddenFieldsOverrides}
      disabledFieldsOverrides={disabledFieldsOverrides}
      labelOverrides={labelOverrides}
      phoneLabelTooltip={phoneLabelTooltip}
      trackingComponentName={trackingComponentName}
      autocompleteEnabled={autocompleteEnabled}
    />
  );
}

export default AddressFormFieldsContainer;

AddressFormFieldsContainer.defaultProps = {
  requiredFieldsOverrides: [],
  hiddenFieldsOverrides: [],
  disabledFieldsOverrides: [],
  labelOverrides: {},
};
