import React from 'react';
import { Listing, core_apimessages_ShippingPrice } from '../gql/graphql';

import { LISTING_STATES } from '../constants';
import { listingItemPageMetaDataUrl } from './helpers/metadata_helper';

interface Props {
  listing: Listing;
  shippingData?: core_apimessages_ShippingPrice;
  returnPolicyDays?: number;
}

export function renderAvailability(listing: Listing) {
  const live = listing.state === LISTING_STATES.LIVE;
  if (!live) return null;

  const { preorderInfo } = listing;

  if (preorderInfo?.onPreorder) {
    return <meta itemProp="availability" content="https://schema.org/PreOrder" />;
  }

  return <meta itemProp="availability" content="https://schema.org/InStock" />;
}

export function renderShippingDetails(listing: Listing, shippingData: core_apimessages_ShippingPrice) {
  if (!shippingData || listing.state !== LISTING_STATES.LIVE) return null;

  const localPickupOnly = listing.shipping?.localPickupOnly;
  const regionalShipping = shippingData.carrierCalculated || shippingData.regional;

  if (regionalShipping || localPickupOnly) return null;
  if (!shippingData.rate?.amount || !shippingData.rate?.currency) return null;

  return (
    <div itemProp="shippingDetails" itemScope itemType="https://schema.org/OfferShippingDetails">
      <div itemProp="shippingRate" itemScope itemType="https://schema.org/MonetaryAmount">
        <meta itemProp="value" content={shippingData.rate.amount} />
        <meta itemProp="currency" content={shippingData.rate.currency} />
      </div>
    </div>
  );
}

export function renderReturnPolicy(listing: Listing, returnPolicyDays: number) {
  if (listing.state === LISTING_STATES.LIVE && !listing.soldAsIs && returnPolicyDays > 0) {
    return (
      <div itemProp="hasMerchantReturnPolicy" itemScope itemType="https://schema.org/MerchantReturnPolicy">
        <meta itemProp="returnPolicyCategory" content="https://schema.org/MerchantReturnFiniteReturnWindow" />
        <meta itemProp="merchantReturnDays" content={String(returnPolicyDays)} />
      </div>
    );
  }

  return null;
}

export function ListingOfferMetadata(props: Props) {
  const { listing, shippingData, returnPolicyDays } = props;

  return (
    <div itemProp="offers" itemScope itemType="http://schema.org/Offer">
      <meta itemProp="priceCurrency" content={listing.pricing.buyerPrice.currency} />
      <meta itemProp="price" content={listing.pricing.buyerPrice.amount} />
      {renderAvailability(listing)}
      <meta itemProp="url" content={listingItemPageMetaDataUrl(listing)} />
      {!!listing.upc && <meta itemProp="gtin14" content={listing.upc} />}
      {renderShippingDetails(listing, shippingData)}
      {renderReturnPolicy(listing, returnPolicyDays)}
    </div>
  );
}
