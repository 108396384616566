import {
  reverb_config_cache_AddressFormConfig,
  core_apimessages_AddressEntry as AddressEntry,
} from '@reverbdotcom/commons/src/gql/graphql';
import { Config, Field } from './address_form_fields_types';
import { omit } from 'lodash';

function buildInputName(prefix: string, name: string) {
  if (prefix) {
    return `${prefix}[${name}]`;
  }

  return name;
}

function configFields(countryCode: string, configs: reverb_config_cache_AddressFormConfig[], requiredFieldsOverrides): Field[] {
  if (!configs) return null;

  const formattedConfigs = formatAddressFieldsConfigs(configs);
  const isRegionNotRequired = () => { return !Object.hasOwnProperty(formattedConfigs[countryCode])?.hasOwnProperty('region'); };

  if (isRegionNotRequired() && requiredFieldsOverrides.includes('region')) {
    return formattedConfigs.default;
  } else {
    return formattedConfigs[countryCode] || formattedConfigs.default;
  }
}

function formatAddressFieldsConfigs(configs): Config {
  if (!configs) return null;

  return configs.reduce((prev, config) => {
    const { fields } = config;

    return {
      ...prev,
      [config.countryCode]: fields.map(field => {
        return {
          fieldName: field.fieldName,
          display: field.displayNames.reduce((prev, display) => ({ ...prev, [display.locale]: display.displayName }), {}),
          required: field.required,
        };
      }),
    };
  }, {});
}

/**
 * Retains fields that are not provided by address autocomplete to
 * ensure that the user doesn't need to re-enter fields like name or phone number.
 */
export function mergeAutocompleteAddress({ address, suggestion }: { address: AddressEntry, suggestion: AddressEntry }): AddressEntry {
  const filteredSuggestion = omit(suggestion, ['name', 'phone']);

  return {
    ...address,
    ...filteredSuggestion,
  };
}

export {
  buildInputName,
  configFields,
  formatAddressFieldsConfigs,
};
