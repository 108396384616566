export default {
  emptyCart: {
    description: 'Your cart is empty. Start browsing!',
    categoriesButton: 'View Categories',
    handpickedButton: 'View Handpicked',
  },
  header: {
    itemCount: {
      one: '{{count}} Item in Your Cart',
      other: '{{count}} Items in Your Cart',
    },
    keepShopping: 'Keep Shopping',
  },
  startCheckoutButton: {
    text: 'Proceed to Checkout',
  },
  orderSummary: {
    title: 'Order Summary',
    salesTax: {
      label: 'Tax',
      description: 'Calculated in Checkout',
    },
  },
  freeShippingThresholds: {
    thresholdMet: "You're getting free standard shipping on orders from {{shopName}}!",
    amountRemaining: "You're {{amountRemainingDisplay}} away from getting free standard shipping from {{tagOpen}}{{shopName}}{{tagClose}}</a>.",
  },
  bundleTitle: {
    tooltip: 'Items in the same currency can be purchased in a single checkout.',
    payPalTooltip: 'Items that only accept PayPal must be purchased individually.',
    payPalSubtitle: 'Use a PayPal account or Credit Card',
  },
  cartItemQuantitySelect: 'Quantity',
  cartItemBuyNow: {
    buttonText: 'Buy this Item Now',
  },
  cartItemPricing: {
    shipping: '{{amountDisplay}} Shipping',
    combinedShipping: {
      text: '{{amountDisplay}} Combine & Save Shipping',
      tooltip: 'Shipping discounts apply when buying multiple qualified items from this shop',
    },
    localPickupOnly: 'Local Pickup',
    digitalDownload: 'Digital Download',
    description: {
      shipped: ' {{priceDisplay}} plus {{shippingDisplay}} shipping',
      localPickupOnly: '{{priceDisplay}} with local pickup',
    },
  },
  itemSoldBy: 'Sold By',
  paymentMethodSelector: {
    label: "How you'll pay",
    payWithOption: 'Pay with {{paymentMethod}}',
  },
  preorderEstimatedShipDate: 'Estimated ship date:',
  buyWithAffirmDefaultText: 'Buy with monthly payments through Affirm',
  buyNowPayOverTime: 'Buy now, pay over time with Affirm',
  makeMonthlyPayments: 'Make monthly payments with no hidden fees',
  splitPay: 'Make regular payments with no hidden fees',
  removeItem: 'Remove',
  moveToWatchList: 'Move to Watch List',
  acceptedOffer: 'Accepted Offer',
  changeShippingRegion: 'Change shipping region',
  freeExpeditedShipping: 'Free 2-Day Shipping',
  otherBuyersWithListingInCart: {
    one: '{{count}} other person has this in their cart',
    other: '{{count}} other people have this in their carts',
  },
};
