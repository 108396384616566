import * as elog from './elog';
import {
  getLCP,
  getFID,
  getCLS,
  getFCP,
  getTTFB,
} from 'web-vitals';

const DEFAULT_TIMING_SAMPLE = 0.1;
const MAX_DURATION_MS = 90000;

interface Tags {
  app: string;
  page: string;
  [key: string]: string;
}

function convertTags(tags: Tags): string[] {
  return Object.keys(tags).map(key => `${key}:${tags[key]}`);
}

export class PerformanceTracker {
  tags: string[];

  sample: number;

  constructor(tags: Tags, sample: number) {
    this.tags = convertTags(tags);
    this.sample = sample;
  }

  sampleTimings() {
    if (Math.random() > this.sample) { return; }

    this.recordDomTiming();
    this.recordWebVitals();
  }

  gauge(key: string, value: number) {
    elog.gauge({
      value: Math.min(value, MAX_DURATION_MS),
      tags: this.tags,
      name: `frontend.${key}.duration`,
    });
  }

  sendWebVital({ name, value }) {
    this.gauge(name, value);
  }

  recordWebVitals() {
    const send = this.sendWebVital.bind(this);
    getLCP(send);
    getFID(send);
    getCLS(send);
    getFCP(send);
    getTTFB(send);
  }

  recordDomTiming() {
    const timing = window.performance && window.performance.timing;
    if (!timing) { return; }

    if (timing.loadEventEnd <= timing.navigationStart) {
      // We haven't finished loading yet, try again after executing all 'load' listeners
      window.addEventListener('load', () => {
        setTimeout(this.sendDomTimings.bind(this), 0);
      });
      return;
    }
    this.sendDomTimings();
  }

  sendDomTimings() {
    const { navigationStart, responseEnd, loadEventEnd } = window.performance.timing;

    if (loadEventEnd <= navigationStart) {
      return;
    }

    this.gauge('response', responseEnd - navigationStart);
    this.gauge('loaded', loadEventEnd - navigationStart);
  }
}

export function sampleTimings(tags: Tags, sample = DEFAULT_TIMING_SAMPLE) {
  new PerformanceTracker(tags, sample).sampleTimings();
}
