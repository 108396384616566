/* eslint max-len: "off" */

const digitalListing = {
  views: {
    one: '1 View',
    other: '{{count}} Views',
  },
  watchers: {
    one: '1 Watcher',
    other: '{{count}} Watchers',
  },
  downloads: {
    one: '1 Download',
    other: '{{count}} Downloads',
  },
  supportedFormats: 'Supported Formats',
  paidDownloadAction: 'Download',
  paidDownloadActionFor: 'Download for {{operatingSystem}}',
  freeDownloadAction: 'Free Download',
  freeDownloadActionFor: 'Free Download for {{operatingSystem}}',
  downloadSuccess: 'Download successful!',
  downloadError: 'There was an issue with your download, please try again.',
  noFilesFound: 'Coming Soon!',
  notAStudent: 'This price is only available for students.',
  createdBy: 'By',
  askQuestion: 'Ask a Question',
  sendQuestion: 'Send Question',
  refundPolicy: 'We are not able to issue refunds for software.',
  studentPrice: 'Student Price',
  description: 'Description',
  whatsNew: 'What\'s New',
  compatibility: 'Compatibility',
  compatibilityWithRefund: '{{systemRequirements}}{{headingOpen}}Refund Policy{{headingClose}}' +
    '{{paragraphOpen}} It is your responsibility to know your system\'s configuration and ensure ' +
    'compatibility with the above specifications before making a purchase. As we can not verify ' +
    'that a purchased software product is no longer in use, we are not able to issue refunds on ' +
    'software purchases. Even though we do not issue refunds, we want you to be completely ' +
    'satisfied with your purchase. If you encounter compatibility issues, we\'re happy to help ' +
    'you try to resolve them.{{paragraphClose}}',
  installation: 'Installation',
  noReviews: 'Reviews',
  reviews: {
    one: '1 Review',
    other: '{{count}} Reviews',
  },
  sale: {
    instantDiscount: 'Instant discount at checkout',
    saleLink: 'Shop more items in this sale',
    saleToolTip: 'Sale price shown after code is entered at checkout. Does not apply to negotiated offers.',
  },
  free: 'Free',
  paid: 'Paid',
  summary: 'Summary:',
  fulfilledByReverb: 'Instant download via Reverb',
  fulfilledByReverbTooltip: 'Reverb helps you keep your music software and plugins up-to-date. When you download this software, we will notify you when a new version is available.',
  messageRecipient: 'To: Reverb',
  home: 'Home',
  softwareCategoryName: 'Software & Plugins',
  messageBody: '{{originalBody}} Message regarding: {{listingName}}.',
  addToCart: 'Add to Cart',
  error: {
    title: 'Oops, something went wrong.',
    explanation: 'We\'ve been notified and are looking into it.',
    softwarePage: 'View All Software',
    homePage: 'Reverb Homepage',
    contactSupport: 'Contact Support',
    download: 'Sorry, something went wrong. Please try again later.',
  },
  priceMin: 'Over {{currency}}{{priceMin}}',
  priceMax: 'Under {{currency}}{{priceMax}}',
  affirm: {
    buyWithMonthlyPayments: 'Buy with monthly payments',
    learnMore: 'Learn More',
    zeroPercentOffer: 'Limited time offer: Rates from 0% APR',
    asLowAs: 'As low as {{monthlyPayment}}/month',
    details: 'Based on purchase price of {{purchasePrice}} at 10% APR for {{maxMonths}} months. Excludes tax and shipping fees, to be calculated at checkout. Rates range from 10-36% APR. Buy now, pay over time.',
    zeroPercentDetails: 'Based on purchase price of {{purchasePrice}} at 0% APR for {{max_months}} months. Excludes tax and shipping fees, to be calculated at checkout. Choose from {{month_1}}, {{month_2}}, or {{month_3}} monthly payments.',
    selectAtCheckout: 'Select {{affirmLogo}} at checkout.',
  },
  makeAnOffer: {
    button: {
      initial: 'Make an Offer',
      cancel: 'Cancel',
      registrationMessage: 'make offers.',
    },
    currencyExchange: {
      yourOffer: 'Your offer: {{amount}}',
      conversionNote: 'The equivalent value in {{currency}} may vary',
      tooltip: 'This seller receives offers in {{listingCurrency}}. What you\'ll pay in {{buyerCurrency}} may vary slightly based on exchange rates.',
    },
    message: {
      label: 'Add a message (optional)',
    },
    agreeToPay: {
      label: 'I agree to pay if my offer is accepted.',
    },
    submit: 'Submit Offer',
    success: 'Your offer of {{amount}} has been submitted. You\'ll hear from the seller soon.',
    unactivated: {
      confirmEmail: 'You must confirm your email to continue.',
      showMeHow: 'Show me how →',
    },
    lastOffer: {
      respond: 'Respond',
      viewOffer: 'View Offer',
      rejected: {
        you: 'You declined {{otherParty}}\'s most recent offer of {{price}}',
        otherParty: 'Your most recent offer of {{price}} was declined',
      },
      countered: {
        you: 'You countered with {{price}}',
        otherParty: '{{otherParty}} countered with an offer of {{price}}',
      },
      initialOffer: {
        you: 'You made an offer for {{price}}',
        otherParty: 'You have received an offer for {{price}}',
      },
    },
  },
};

const digitalListingCard = {
  callToAction: 'View Details',
  by: 'by',
};

const digitalProductsIndex = {
  resultsCount: {
    one: '1 listing',
    other: '{{count}} listings',
  },
  emptySearchImgAlt: 'No listing results icon',
  emptySearchTitle: 'There are no listings matching your search.',
  categories: 'Categories',
  refineResults: 'Refine Results',
  brand: 'Brand',
  operatingSystem: 'Operating System',
  fileType: 'Type',
  viaReverb: 'via Reverb',
  updatedAt: 'Newest',
  popularity: 'Most Downloaded',
  name: 'Name',
  priceAsc: 'Price Low to High',
  priceDesc: 'Price High to Low',
  pageTitle: '{{categoryName}} / Software & Plugins | Reverb',
  indexPageTitle: 'Software & Plugins | Reverb',
};

export default {
  digitalListing,
  digitalListingCard,
  digitalProductsIndex,
};
