import I18n from 'i18n-js';
import React from 'react';

import { PriceDisplay } from './PriceDisplay';
import ProximityModal from './proximity_modal';
import { core_apimessages_Money as IMoney } from '../gql/graphql';
import { FreeExpeditedShippingDisplay } from './listing_shipping_display';
import { isZeroAmount } from '../money';

interface IProps {
  postalCode?: string;
  standardShippingPrice?: IMoney;
  expeditedShippingPrice?: IMoney;
}

export default function LocationBasedShippingDisplay({ postalCode, standardShippingPrice, expeditedShippingPrice }: IProps) {
  const editButtonText = postalCode || I18n.t('commons.calculateShipping');
  const [isModalOpen, setOpenModal] = React.useState(false);
  const shippingPrice = expeditedShippingPrice ? expeditedShippingPrice : standardShippingPrice;

  return (
    <div className="listing-shipping-display">
      <ProximityModal
        isModalOpen={isModalOpen}
        setModal={setOpenModal}
        propsPostalCode={postalCode}
      />

      {postalCode && (
        <span className="listing-shipping-display__to-postal">
          {shippingPrice && (
            <>
              {'+ '}
              <PriceDisplay
                amountCents={shippingPrice.amountCents}
                display={shippingPrice.display}
              />
              {expeditedShippingPrice ? ` ${I18n.t('commons.expeditedShippingTo')}` : ` ${I18n.t('commons.shippingTo')}`}
            </>
          )}

          {!shippingPrice && I18n.t('commons.unavailableTo')}
        </span>
      )}

      <button
        type="button"
        className="button-as-link text-link"
        onClick={(e) => {
          e.preventDefault();
          setOpenModal(true);
        }}
      >
        {editButtonText}
      </button>

      {(expeditedShippingPrice && isZeroAmount(expeditedShippingPrice.amount)) && (
        <FreeExpeditedShippingDisplay hideLabel />
      )}
    </div>
  );
}
