import React from 'react';
import I18n from 'i18n-js';
import classNames from 'classnames';

import ModalDialog from '@reverbdotcom/commons/src/components/modal_dialog';

import TransactionsTableContainer from './TransactionsTableContainer';
import LiveListingsTableContainer from './LiveListingsTableContainer';

import { trackEvent, MParticleEventName } from '@reverbdotcom/commons/src/elog/mparticle_tracker';

export const PAGE_SIZE = 10;
export const MAX_PAGES = 10;

interface IExternalProps {
  canonicalProductIds: string[];
}

export default function SellFormPricingGuidanceModal({
  canonicalProductIds,
}: IExternalProps) {
  const [isOpen, setIsOpen] = React.useState(false);
  const [isSoldListingsSelected, selectSoldListings] = React.useState(true);
  const isLiveListingsSelected = !isSoldListingsSelected;

  function openModal(event) {
    event.preventDefault();

    trackEvent({
      eventName: MParticleEventName.ClickedSellFormPricingModalLink,
    });

    setIsOpen(true);
  }

  function selectTab(event) {
    event.preventDefault();

    selectSoldListings(!isSoldListingsSelected);
  }

  return (
    <div className="sell-form-pricing-guidance-modal">
      <button
        className="button-as-link text-link weight-bold"
        onClick={openModal}
      >
        {I18n.t('discovery.sellForm.pricingSection.pricingGuidance.viewLiveAndSoldPrices')}
      </button>
      <ModalDialog
        isOpen={isOpen}
        onRequestClose={() => setIsOpen(false)}
        onSubmit={() => setIsOpen(false) }
        headerTitle={I18n.t('discovery.sellForm.pricingSection.pricingTables.listings.header')}
        saveButtonText={I18n.t('discovery.sellForm.pricingSection.pricingTables.close')}
        hideFooterDismissButton
      >
        <div className="sell-form-pricing-guidance-modal__nav">
          <div className={classNames({
            'sell-form-pricing-guidance-modal__nav--selected': isSoldListingsSelected,
          })}>
            <button
              onClick={selectTab}
            >
              {I18n.t('discovery.sellForm.pricingSection.pricingTables.listings.tabs.soldListingPrices')}
            </button>
          </div>

          <div className={classNames({
            'sell-form-pricing-guidance-modal__nav--selected': isLiveListingsSelected,
          })}>
            <button
              onClick={selectTab}
            >
              {I18n.t('discovery.sellForm.pricingSection.pricingTables.listings.tabs.liveListingPrices')}
            </button>
          </div>
        </div>

        {isSoldListingsSelected ? (
          <TransactionsTableContainer
            canonicalProductIds={canonicalProductIds}
          />
        ) : (
          <LiveListingsTableContainer
            canonicalProductIds={canonicalProductIds}
          />
        )}

        <p className="mt-8 mb-0 color-secondary">
          {I18n.t('discovery.sellForm.pricingSection.pricingTables.disclaimer')}
        </p>
      </ModalDialog>
    </div>
  );
}
