import React from 'react';

export interface State {
  store?: any;
}

interface Listener {
  listen: Function;
  unlisten: Function;
}

interface Options {
  mapper?: Function;
  stores?: Listener[];
  actions?: any;
}

export interface InjectedProps {
  actions?: any;
}

const mapStateToProps = ({ mapper, stores, actions }: Options = {}) =>
  <TProps extends {}>(Component: React.ComponentClass<TProps & InjectedProps> | React.StatelessComponent<TProps & InjectedProps>, name?: string) => {
  type Props = Partial<TProps & InjectedProps>;

  const result = class Connected extends React.Component<Props, State> {
    static displayName = `Connected${name}`;

    static WrappedComponent = Component;

    actions = {};

    constructor(props: Props) {
      super(props);
      this.state = mapper();
      this.actions = actions;
      this.onStoreUpdated = this.onStoreUpdated.bind(this);
    }

    componentDidMount() {
      stores.forEach((store) => {
        store.listen(this.onStoreUpdated);
      });
    }

    componentWillUnmount() {
      stores.forEach((store) => {
        store.unlisten(this.onStoreUpdated);
      });
    }

    onStoreUpdated() {
      const state = mapper();
      if (state) {
        this.setState(state);
      }
    }

    render() {
      return <Component {...this.props} {...this.state} actions={this.actions} />;
    }
  };

  return result;
  };

export default mapStateToProps;
