import React from 'react';
import classNames from 'classnames';
import FormGroup from '@reverbdotcom/commons/src/components/form_group';
import { CSEName } from '@reverbdotcom/commons/src/adyen_card_hook';
import { CbObjOnError as CardError, CbObjOnFocus as CardFocus } from '@adyen/adyen-web/dist/types/components/internal/SecuredFields/lib/types';
import CreditCardInputWithIcon from './credit_card_input_with_icon';
import { PaymentIconName } from './payment_icon';
import CreditCardSecurityCodeIcon from './credit_card_security_code_icon';

interface Props {
  inputName: string;
  inputValue: string;
  label: React.ReactNode | string;
  cseName: CSEName;
  focus: Partial<CardFocus>;
  errors: Partial<Record<CSEName, CardError>>;
  brand?: PaymentIconName | string;
  showBrandIcon?: boolean;
  showSecurityCodeIcon?: boolean;
}

function renderEncryptedInput(props: Props, errorText: string, classes: string) {
  if (props.cseName === 'encryptedCardNumber') {
    return (
      <CCNumberInput
        brand={props.brand}
        showBrandIcon={props.showBrandIcon}
        inputName={props.inputName}
        inputValue={props.inputValue}
        cseName={props.cseName}
        errorText={errorText}
        classes={classes}
      />
    );
  }

  if (props.cseName === 'encryptedSecurityCode') {
    return (
      <SecurityCodeInput
        brand={props.brand}
        showSecurityCodeIcon={props.showSecurityCodeIcon}
        inputName={props.inputName}
        inputValue={props.inputValue}
        cseName={props.cseName}
        errors={props.errors}
        classes={classes}
      />
    );
  }

  return (
    <DefaultInput
      inputName={props.inputName}
      inputValue={props.inputValue}
      classes={classes}
      cseName={props.cseName}
    />
  );
}

export default function CreditCardEncryptedInput(props: Props) {
  const hasFocus = props.focus?.fieldType === props.cseName;
  const errorText = props.errors[props.cseName]?.errorI18n;

  const classes = classNames(
    'adyen-field', {
      'adyen-field--focused': hasFocus,
      'adyen-field--error': !!errorText,
    },
  );

  return (
    <FormGroup
      label={props.label}
      inputName={props.inputName}
      errorText={errorText}
    >
      {renderEncryptedInput(props, errorText, classes)}
    </FormGroup>
  );
}

function CCNumberInput({ brand, showBrandIcon, inputName, inputValue, cseName, errorText, classes }) {
  function cardNameOrError() {
    if (errorText) {
      return 'error';
    }

    return brand;
  }

  return (
    <>
      {showBrandIcon ? (
        <CreditCardInputWithIcon name={cardNameOrError()}>
          <input name={inputName} value={inputValue || ''} type="hidden" />
          <div className={classes} data-cse={cseName} />
        </CreditCardInputWithIcon>
      ) : (
        <DefaultInput
          inputName={inputName}
          inputValue={inputValue}
          classes={classes}
          cseName={cseName}
        />
      )}
    </>
  );
}

function SecurityCodeInput({ brand, errors, showSecurityCodeIcon, inputName, inputValue, cseName, classes }) {
  return (
    <div className={showSecurityCodeIcon ? 'd-flex fx-align-center' : ''}>
      <div className={showSecurityCodeIcon ? 'mr-2 fx-noshrink' : ''} style={{ width: showSecurityCodeIcon ? '11rem' : null }}>
        <DefaultInput
          inputName={inputName}
          inputValue={inputValue}
          classes={classes}
          cseName={cseName}
        />
      </div>

      {showSecurityCodeIcon &&
        <CreditCardSecurityCodeIcon
          brand={brand}
          cardNumberFieldErrorText={errors.encryptedCardNumber?.errorI18n}
        />
      }
    </div>
  );
}

function DefaultInput({ inputName, inputValue, classes, cseName }) {
  return (
    <>
      <input name={inputName} value={inputValue || ''} type="hidden" />
      <div className={classes} data-cse={cseName} />
    </>
  );
}
