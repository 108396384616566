import React, { useState } from 'react';
import I18n from 'i18n-js';
import Currency from '@reverbdotcom/commons/src/currency';

import { RCSwitch, RCCheckbox, RCTextInput, RCAlertBox, RCTag } from '@reverbdotcom/cadence/components';

export const MINIMUM_AUTO_REJECT_THRESHOLD = 50;
export const MAXIMUM_AUTO_REJECT_THRESHOLD = 80;

export const MINIMUM_AUTO_ACCEPT_THRESHOLD = 51;
export const MAXIMUM_AUTO_ACCEPT_THRESHOLD = 99;

export interface IProps {
  brandNewSelected: boolean;
  bStockSelected: boolean;
  usedSelected: boolean;
  autoRejectThreshold: string | null;
  autoAcceptThreshold: string | null;
  minPriceAmount: string | null;
  maxPriceAmount: string | null;
  haggleProtection: boolean;
  autoCounter: boolean;
  currency: string;
  acceptMessage: string | null;
  counterMessage: string | null;
  rejectMessage: string | null;
}

function priceBoundariesErrorText(minPriceAmount: string, maxPriceAmount: string) {
  if ((!!maxPriceAmount && !!minPriceAmount) && (Number(maxPriceAmount) < Number(minPriceAmount))) {
    return I18n.t('discovery.shopSettings.offerBotPolicy.priceBoundaries.min.conflictError');
  }
}

export default function SellingPoliciesOfferBot(props: IProps) {
  const [offerBotEnabled, setOfferBotEnabled] = useState(props.brandNewSelected || props.bStockSelected || props.usedSelected);
  const [brandNewChecked, setBrandNewChecked] = useState(props.brandNewSelected);
  const [bStockChecked, setBStockChecked] = useState(props.bStockSelected);
  const [usedChecked, setUsedChecked] = useState(props.usedSelected);
  const [minPriceAmount, setMinPriceAmount] = useState(props.minPriceAmount);
  const [maxPriceAmount, setMaxPriceAmount] = useState(props.maxPriceAmount);
  const [autoRejectThreshold, setAutoRejectThreshold] = useState(props.autoRejectThreshold);
  const [autoAcceptThreshold, setAutoAcceptThreshold] = useState(props.autoAcceptThreshold);
  const [autoRejectChecked, setRejectDeclineChecked] = useState(!!props.autoRejectThreshold);
  const [haggleProtectionChecked, setHaggleProtectionChecked] = useState(props.haggleProtection);
  const [autoCounterChecked, setAutoCounterChecked] = useState(props.autoCounter);
  const [acceptMessage, setAcceptMessage] = useState(props.acceptMessage || '');
  const [counterMessage, setCounterMessage] = useState(props.counterMessage || '');
  const [rejectMessage, setRejectMessage] = useState(props.rejectMessage || '');
  const [customMessageChecked, setCustomMessages] = useState(!!props.acceptMessage || !!props.counterMessage || !!props.rejectMessage);

  const currencySymbol = () => {
    return Currency.get(props.currency).symbol;
  };

  const autoThresholdErrorMessage = (value, minimum, maximum) => {
    if (value) {
      const parsedThreshold = parseInt(value);
      if (parsedThreshold > maximum || parsedThreshold < minimum) {
        return I18n.t('discovery.shopSettings.offerBotPolicy.thresholdsError', { maximum: maximum, minimum: minimum });
      }
    }

    return null;
  };

  const handleOfferBotEnabled = () => {
    if (offerBotEnabled) {
      setOfferBotEnabled(false);
    } else {
      setOfferBotEnabled(true);
      setBrandNewChecked(true);
      setUsedChecked(true);
      setBStockChecked(true);
    }
  };

  return (
    <>
      <div className="g-container">
        <div className="g-col-12 pt-3">
          <RCSwitch
            checked={offerBotEnabled}
            id="offerBotEnabled"
            name="offerBotEnabled"
            label={I18n.t('discovery.shopSettings.offerBotPolicy.toggleTitle')}
            onChange={handleOfferBotEnabled}
          />
        </div>
      </div>
      {offerBotEnabled && (
        <div className="g-container">
          <div className="g-col-12 pt-4">
            <div className="g-col-12 pt-2">
              <div className="weight-bold mb-0">
                {I18n.t('discovery.shopSettings.offerBotPolicy.conditions.title')}
              </div>
            </div>
            <div className="g-col-12 pt-2">
              <RCCheckbox
                name="brandNewChecked"
                id="brandNewChecked"
                label={I18n.t('discovery.shopSettings.offerBotPolicy.conditions.brandNewOption')}
                onChange={(e) => setBrandNewChecked(e.target.checked)}
                checked={brandNewChecked}
              />
            </div>
            <div className="g-col-12 pt-2">
              <RCCheckbox
                name="bStockChecked"
                id="bStockChecked"
                label={I18n.t('discovery.shopSettings.offerBotPolicy.conditions.bStockOption')}
                onChange={(e) => setBStockChecked(e.target.checked)}
                checked={bStockChecked}
              />
            </div>
            <div className="g-col-12 pt-2">
              <RCCheckbox
                name="usedChecked"
                id="usedChecked"
                label={I18n.t('discovery.shopSettings.offerBotPolicy.conditions.usedOption')}
                onChange={(e) => setUsedChecked(e.target.checked)}
                checked={usedChecked}
              />
            </div>
          </div>
          <div className="g-col-12 pt-4">
            <div className="g-col-12 pt-2">
              <div className="weight-bold mb-0">
                {I18n.t('discovery.shopSettings.offerBotPolicy.priceBoundaries.title')}
              </div>
            </div>
            <div className="g-col-10 g-col-tablet-12 pt-2">
              <p>{I18n.t('discovery.shopSettings.offerBotPolicy.priceBoundaries.description')}</p>
            </div>
            <div className="g-col-4 g-col-tablet-12">
              <div className="mb-2">
                <RCTextInput
                  name="shop_policies_form[offer_bot_rules][min_price_amount]"
                  id="shop_policies_form_offer_bot_rules_min_price_amount"
                  label={I18n.t('discovery.shopSettings.offerBotPolicy.priceBoundaries.min.title')}
                  maxLength={21}
                  onChange={(e) => setMinPriceAmount(e.target.value)}
                  prefixText={currencySymbol()}
                  inputMode="decimal"
                  type="number"
                  value={minPriceAmount}
                  errorText={priceBoundariesErrorText(minPriceAmount, maxPriceAmount)}
                />
              </div>
              <div className="mb-2">
                <RCTextInput
                  name="shop_policies_form[offer_bot_rules][max_price_amount]"
                  id="shop_policies_form_offer_bot_rules_max_price_amount"
                  label={I18n.t('discovery.shopSettings.offerBotPolicy.priceBoundaries.max.title')}
                  maxLength={21}
                  onChange={(e) => setMaxPriceAmount(e.target.value)}
                  prefixText={currencySymbol()}
                  inputMode="decimal"
                  type="number"
                  value={maxPriceAmount}
                />
              </div>
            </div>
          </div>
          <div className="g-col-10 g-col-tablet-12 pt-2">
            <div className="g-col-12 pt-2">
              <div className="weight-bold mb-0">
                {I18n.t('discovery.shopSettings.offerBotPolicy.autoAccept.title')}
              </div>
            </div>
            <div className="g-col-10 g-col-tablet-12 pt-2 mb-2">
              <p className="mb-2">{I18n.t('discovery.shopSettings.offerBotPolicy.autoAccept.example')}</p>
            </div>
            <div className="g-col-4 g-col-tablet-12">
              <RCTextInput
                name="shop_policies_form[offer_bot_rules][auto_accept_threshold]"
                id="shop_policies_form_offer_bot_rules_auto_accept_threshold"
                label={I18n.t('discovery.shopSettings.offerBotPolicy.autoAccept.label')}
                maxLength={2}
                onChange={(e) => setAutoAcceptThreshold(e.target.value)}
                suffixText="%"
                inputMode="numeric"
                type="number"
                value={autoAcceptThreshold}
                errorText={autoThresholdErrorMessage(autoAcceptThreshold, MINIMUM_AUTO_ACCEPT_THRESHOLD, MAXIMUM_AUTO_ACCEPT_THRESHOLD)}
              />
            </div>
            <div className="g-col-12 mt-6 mb-2">
              <div className="weight-bold">
                {I18n.t('discovery.shopSettings.offerBotPolicy.counterAndLowballTitle')}
              </div>
            </div>
            <div className="g-col-12 pt-2">
              <RCCheckbox
                name="autoCounterChecked"
                id="autoCounterChecked"
                label={I18n.t('discovery.shopSettings.offerBotPolicy.autoCounterCheckboxLabel')}
                onChange={(e) => setAutoCounterChecked(e.target.checked)}
                checked={autoCounterChecked}
              />
            </div>
            <div className="g-col-12 pt-4">
              <RCCheckbox
                id="autoRejectChecked"
                name="autoRejectChecked"
                label={I18n.t('discovery.shopSettings.offerBotPolicy.autoDecline.title')}
                sublabel={I18n.t('discovery.shopSettings.offerBotPolicy.autoDecline.description')}
                onChange={(e) => setRejectDeclineChecked(e.target.checked)}
                checked={autoRejectChecked}
              />
              {autoRejectChecked && (
                <div className="g-col-4 g-col-tablet-12 mt-2 ml-6">
                  <RCTextInput
                    name="shop_policies_form[offer_bot_rules][auto_reject_threshold]"
                    id="shop_policies_form_offer_bot_rules_auto_reject_threshold"
                    label={I18n.t('discovery.shopSettings.offerBotPolicy.autoDecline.label')}
                    maxLength={2}
                    onChange={(e) => setAutoRejectThreshold(e.target.value)}
                    suffixText="%"
                    inputMode="numeric"
                    type="number"
                    value={autoRejectThreshold}
                    errorText={autoThresholdErrorMessage(autoRejectThreshold, MINIMUM_AUTO_REJECT_THRESHOLD, MAXIMUM_AUTO_REJECT_THRESHOLD)}
                  />
                </div>
              )}
            </div>
            <div className="g-col-12 pt-4">
              <RCCheckbox
                name="haggleProtectionChecked"
                id="haggleProtectionChecked"
                label={I18n.t('discovery.shopSettings.offerBotPolicy.haggleProtectionLabel')}
                onChange={(e) => setHaggleProtectionChecked(e.target.checked)}
                checked={haggleProtectionChecked}
              />
            </div>
          </div>
          <div className="g-col-12">
            <div className="g-col-12 mt-6 mb-2">
              <div className="weight-bold">
                {I18n.t('discovery.shopSettings.offerBotPolicy.messages.title')}
                <div className="ml-2 d-inline">
                  <RCTag>{I18n.t('discovery.shopSettings.offerBotPolicy.messages.optional')}</RCTag>
                </div>
              </div>
            </div>
            <div className="g-col-12">
              <RCCheckbox
                id="customMessageChecked"
                name="customMessageChecked"
                label={I18n.t('discovery.shopSettings.offerBotPolicy.messages.checkboxLabel')}
                onChange={(e) => setCustomMessages(e.target.checked)}
                checked={customMessageChecked}
              />
            </div>

            {customMessageChecked && (
              <div className="g-col-12 ml-0">
                <div className="g-col-6 g-col-tablet-12">
                  <div className="pt-2">
                    <RCTextInput
                      name="shop_policies_form[offer_bot_messages][accept]"
                      id="shop_policies_form_offer_bot_messages_accept"
                      label={I18n.t('discovery.shopSettings.offerBotPolicy.messages.input.accept')}
                      onChange={(e) => setAcceptMessage(e.target.value)}
                      value={acceptMessage}
                    />
                  </div>
                  <div className="pt-2">
                    <RCTextInput
                      name="shop_policies_form[offer_bot_messages][counter]"
                      id="shop_policies_form_offer_bot_messages_counter"
                      label={I18n.t('discovery.shopSettings.offerBotPolicy.messages.input.counter')}
                      onChange={(e) => setCounterMessage(e.target.value)}
                      value={counterMessage}
                    />
                  </div>
                  <div className="pt-2">
                    <RCTextInput
                      name="shop_policies_form[offer_bot_messages][reject]"
                      id="shop_policies_form_offer_bot_messages_reject"
                      label={I18n.t('discovery.shopSettings.offerBotPolicy.messages.input.reject')}
                      onChange={(e) => setRejectMessage(e.target.value)}
                      value={rejectMessage}
                    />
                  </div>
                </div>
                <div className="g-col-6 g-col-tablet-12 mt-8 pl-tablet-0">
                  <RCAlertBox type="plain">
                    <p><strong>{I18n.t('discovery.shopSettings.offerBotPolicy.messages.alertTitle')}</strong></p>
                    <ul id="offer_bot_message_defaults">
                      <li>
                        <span>
                          <strong className="mr-half">
                            {I18n.t('discovery.shopSettings.offerBotPolicy.messages.default.accept.label')}
                          </strong>

                          {I18n.t('discovery.shopSettings.offerBotPolicy.messages.default.accept.message')}
                        </span>
                      </li>
                      <li>
                        <span>
                          <strong className="mr-half">
                            {I18n.t('discovery.shopSettings.offerBotPolicy.messages.default.counter.label')}
                          </strong>

                          {I18n.t('discovery.shopSettings.offerBotPolicy.messages.default.counter.message')}
                        </span>
                      </li>
                      <li>
                        <span>
                          <strong className="mr-half">
                            {I18n.t('discovery.shopSettings.offerBotPolicy.messages.default.reject.label')}
                          </strong>

                          {I18n.t('discovery.shopSettings.offerBotPolicy.messages.default.reject.message')}
                        </span>
                      </li>
                    </ul>
                  </RCAlertBox>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
      <input
        type="hidden"
        name="shop_policies_form[offer_bot_rules][offer_bot_enabled]"
        id="shop_policies_form_offer_bot_rules_offer_bot_enabled"
        value={offerBotEnabled && (brandNewChecked || bStockChecked || usedChecked) ? '1' : '0'}
      />
      <input
        type="hidden"
        name="shop_policies_form[offer_bot_rules][brand_new_selected]"
        id="shop_policies_form_offer_bot_rules_brand_new_selected"
        value={brandNewChecked ? '1' : '0'}
      />
      <input
        type="hidden"
        name="shop_policies_form[offer_bot_rules][b_stock_selected]"
        id="shop_policies_form_offer_bot_rules_b_stock_selected"
        value={bStockChecked ? '1' : '0'}
      />
      <input
        type="hidden"
        name="shop_policies_form[offer_bot_rules][used_selected]"
        id="shop_policies_form_offer_bot_rules_used_selected"
        value={usedChecked ? '1' : '0'}
      />
      <input
        name="shop_policies_form[offer_bot_rules][haggle_protection]"
        id="shop_policies_form_offer_bot_rules_haggle_protection"
        type="hidden"
        value={haggleProtectionChecked ? '1' : '0'}
      />
      <input
        name="shop_policies_form[offer_bot_rules][auto_counter]"
        id="shop_policies_form_offer_bot_rules_auto_counter"
        type="hidden"
        value={autoCounterChecked ? '1' : '0'}
      />
    </>
  );
}
