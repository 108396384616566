import PropTypes from 'prop-types';
import React from 'react';
import BankTransferWarning from '../bank_transfer_warning';
import CurrencyExchangeFeeWarning from '../currency_exchange_fee_warning';
import classNames from 'classnames';

const CurrencyWarnings = function (props) {
  const showBankTransferWarning = props.payoutCurrency !== props.defaultCurrencyForBankCountry;
  const showCurrencyExchangeFeeWarning = props.payoutCurrency !== props.shopCurrency;

  if (!showBankTransferWarning && !showCurrencyExchangeFeeWarning) {
    return null;
  }

  const bankTransferWarningClassNames = classNames({
    small: true,
    'mb-1': showCurrencyExchangeFeeWarning,
  });

  return (
    <div className="alert alert-warn alert-small">
      <BankTransferWarning
        payoutCurrency={props.payoutCurrency}
        defaultCurrencyForBankCountry={props.defaultCurrencyForBankCountry}
        className={bankTransferWarningClassNames}
        nameForField={props.nameForField}
      />

      <CurrencyExchangeFeeWarning
        payoutCurrency={props.payoutCurrency}
        shopCurrency={props.shopCurrency}
        dashboardView={props.dashboardView}
        className="small"
        directCheckoutSelected
      />
    </div>
  );
};

CurrencyWarnings.propTypes = {
  payoutCurrency: PropTypes.string.isRequired,
  shopCurrency: PropTypes.string.isRequired,
  defaultCurrencyForBankCountry: PropTypes.string.isRequired,
  dashboardView: PropTypes.bool,
  nameForField: PropTypes.func.isRequired,
  className: PropTypes.string,
};

export default CurrencyWarnings;
