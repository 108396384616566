import React from 'react';
import { Route } from 'react-router';
import { AdsAdminRoutes } from './ads_admin_routes';
import UniversalPromoCampaignFormContainer from '../components/promo_codes_admin/universal_promo_campaign_form_container';

export const AdminRoutes = [
  ...AdsAdminRoutes,
  <Route
    key="/admin/universal_promo_campaigns/new"
    path="/admin/universal_promo_campaigns/new"
    props={{ clientSide: true }}
    component={UniversalPromoCampaignFormContainer}
  />,
  <Route
    key="/admin/universal_promo_campaigns/:campaignId/edit"
    path="/admin/universal_promo_campaigns/:campaignId/edit"
    props={{ clientSide: true }}
    component={UniversalPromoCampaignFormContainer}
  />,
];
