import React from 'react';

export interface ListingCardReviews {
  totalReviews: number;
  averageRating: number;
  bestRating?: number;
  worstRating?: number;
}

interface ReviewsStyles {
  classNameReviews?: string;
}

type Props = ListingCardReviews & ReviewsStyles;

export function ReviewMetadata({
  totalReviews,
  averageRating,
  bestRating = 5,
  worstRating = 1,
  classNameReviews = undefined,
} : Props ) {
  if (!totalReviews) return null;

  return (
    <div
      className={classNameReviews}
      itemProp="aggregateRating"
      itemScope
      itemType="http://schema.org/AggregateRating"
    >
      <meta itemProp="ratingValue" content={(Math.round(averageRating * 10) / 10).toString()} />
      <meta itemProp="bestRating" content={bestRating.toString()} />
      <meta itemProp="worstRating" content={worstRating.toString()}/>
      <meta itemProp="reviewCount" content={totalReviews.toString()} />
    </div>
  );
}
