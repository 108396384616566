import PropTypes from 'prop-types';
import React from 'react';

function formatPercent(percent) {
  const roundedPercent = Math.round(Math.abs(percent * 100)).toLocaleString();
  return `${roundedPercent}%`;
}

function changeIcon(percent) {
  if (percent > 0) {
    return <span className="fa fa-arrow-up" />;
  }

  return <span className="fa fa-arrow-down" />;
}

function changeClass(percent) {
  if (percent > 0) {
    return 'stat-green';
  }

  return 'stat-decrease';
}

const PercentageChange = ({ percent, showPercentChange }) => {
  if (!showPercentChange) { return null; }

  return (
    <span className={`stat-change ${changeClass(percent)} ml-1`}>
      {changeIcon(percent)}
      <span className="hidden-mobile ml-1">
        {formatPercent(percent)}
      </span>
    </span>
  );
};

PercentageChange.propTypes = {
  percent: PropTypes.number,
  showPercentChange: PropTypes.bool,
};

export default PercentageChange;
