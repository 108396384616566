import React from 'react';
import BankTransferWarning from './bank_transfer_warning';
import CurrencyExchangeFeeWarning from './currency_exchange_fee_warning';
import classNames from 'classnames';
import { PaymentProvider } from './payment_provider';
import { RCAlertBox } from '@reverbdotcom/cadence/components';

interface Props {
  paymentProvider: PaymentProvider;
  payoutCurrencyCode: string;
  shopCurrencyCode: string;
  shopCountryCode: string;
  inputName: string;
}

const CURRENCY_CODE_DEFAULT_FOR_COUNTRY = {
  US: 'USD',
  CA: 'CAD',
};

export default function BankDepositConfirmCheckbox(props: Props) {
  const showCurrencyExchangeFeeWarning = props.payoutCurrencyCode !== props.shopCurrencyCode;
  const showBankTransferWarning = props.payoutCurrencyCode !== CURRENCY_CODE_DEFAULT_FOR_COUNTRY[props.shopCountryCode];

  if (!showBankTransferWarning && !showCurrencyExchangeFeeWarning) {
    return null;
  }

  return (
    <div className="mt-2 mb-4">
      <RCAlertBox type="warning" small>
        <div className={classNames({ 'mb-1': showCurrencyExchangeFeeWarning })}>
          <BankTransferWarning
            data-bank-transfer-warning
            currencyCode={props.payoutCurrencyCode}
            countryDefaultCurrencyCode={CURRENCY_CODE_DEFAULT_FOR_COUNTRY[props.shopCountryCode]}
            inputName={props.inputName}
          />
        </div>

        <CurrencyExchangeFeeWarning
          payoutCurrency={props.payoutCurrencyCode}
          shopCurrency={props.shopCurrencyCode}
          paymentProvider={props.paymentProvider}
        />
      </RCAlertBox>
    </div>
  );
}
