import React from 'react';
import I18n from 'i18n-js';

import {
  core_apimessages_CreditCard,
  core_apimessages_Error,
  core_apimessages_MyShopOnboardingResponse_BillingMethodStatus as BillingMethodStatus,
  Input_core_apimessages_UpdateBillingMethodRequest as UpdateBillingMethodRequest,
  core_apimessages_Country as Country,
  core_apimessages_AddressEntry as IAddressEntry,
} from '@reverbdotcom/commons/src/gql/graphql';
import BillingMethodNewCardFormFields from '../components/billing_method_new_card_form_fields';
import OnboardingFormSaveButton from '../onboarding/onboarding_form_save_button';
import SelectableCreditCardList from '../onboarding/selectable_credit_card_list';
import CreditCardFormToggleButton from '../onboarding/credit_card_form_toggle_button';
import CannotAddNewCardWarning from '../components/CannotAddNewCardWarning';
import {
  ITokenizedCreditCardData,
  buildBillingCardAddress,
} from '../credit_cards/card_data';
import { buildUpdateBillingMethodMutationInput } from './billing_method_operation_inputs';
import { CardState } from '@reverbdotcom/commons/src/adyen_card_hook';

import { IUserContext } from '@reverbdotcom/commons/src/components/user_context_provider';
import bind from '@reverbdotcom/commons/src/bind';
import { CREDIT_CARD_LIMIT } from '../lib/creditCards';
import { RCAlertBox } from '@reverbdotcom/cadence/components';

export interface Props extends IUserContext {
  billingMethodStatus: BillingMethodStatus;
  countries: Country[];
  creditCards: core_apimessages_CreditCard[];
  defaultOriginCountryCode: string;
  isSaving: boolean;
  returnURL: string;
  updateMyShopBillingMethod(input: UpdateBillingMethodRequest): void;
  validationErrors: core_apimessages_Error[];
  description?: string;
  showEditURL?: boolean;
  title?: string;
}

interface State {
  tokenizedCreditCardData: ITokenizedCreditCardData;
  billingCardAddress: IAddressEntry;
  selectedCreditCardId: string;
  showExistingCreditCards: boolean;
  editingCard: boolean;
  cardholderName: string;
}

export default class BillingMethodForm extends React.Component<Props, State> {
  state = {
    tokenizedCreditCardData: {
      encryptedSecurityCode: '',
      encryptedExpiryMonth: '',
      encryptedExpiryYear: '',
      encryptedCardNumber: '',
    },
    billingCardAddress: this.defaultBillingCardAddress,
    showExistingCreditCards: this.hasCardsOnFile,
    selectedCreditCardId: this.defaultSelectedCreditCardId,
    editingCard: false,
    cardholderName: '',
  };

  get defaultBillingCardAddress(): IAddressEntry {
    return { ...buildBillingCardAddress(), countryCode: this.props.defaultOriginCountryCode };
  }

  get hasCardsOnFile() {
    return this.props.creditCards.length > 0;
  }

  get primaryBillingCard() {
    return this.props.creditCards.find((creditCard) => creditCard.primaryBilling);
  }

  get defaultSelectedCreditCardId(): string {
    if (!this.hasCardsOnFile) {
      return '';
    }
    return this.primaryBillingCard ? this.primaryBillingCard.id : this.props.creditCards[0].id;
  }

  findCard(cardId: string): core_apimessages_CreditCard {
    return this.props.creditCards.find((creditCard) => creditCard.id === cardId);
  }

  @bind
  setCardholderName(cardholderName: string) {
    this.setState({ cardholderName });
  }

  billingCardAddress(cardId: string): IAddressEntry {
    const { address } = this.findCard(cardId);

    if (address) {
      return ({
        postalCode: address.postalCode,
        countryCode: address.countryCode,
      });
    }

    return this.defaultBillingCardAddress;
  }

  @bind
  onTokenizedFieldUpdate(cardState: CardState) {
    const { paymentMethod } = cardState.data;

    this.setState(previousState => ({
      tokenizedCreditCardData: {
        ...previousState.tokenizedCreditCardData,
        ...paymentMethod,
      },
    }));
  }

  @bind
  updateBillingMethod(event) {
    event.preventDefault();
    const mutationData = buildUpdateBillingMethodMutationInput({
      cardholderName: this.state.cardholderName,
      tokenizedCreditCardData: this.state.tokenizedCreditCardData,
      billingCardAddress: this.state.billingCardAddress,
      editingCard: this.state.editingCard,
      selectedCreditCardId: this.state.selectedCreditCardId,
      useExistingCreditCard: this.state.showExistingCreditCards,
      returnUrl: this.props.returnURL,
    });

    this.props.updateMyShopBillingMethod(mutationData);
  }

  @bind
  onBillingCardAddressUpdate(updatedBillingAddress: IAddressEntry) {
    this.setState(previousState => ({
      billingCardAddress: {
        ...previousState.billingCardAddress,
        ...updatedBillingAddress,
      },
    }));
  }

  @bind
  handleEditClick(cardId: string) {
    this.setState({
      editingCard: true,
      billingCardAddress: this.billingCardAddress(cardId),
      selectedCreditCardId: cardId,
      showExistingCreditCards: false,
      cardholderName: this.findCard(cardId).cardholderName,
    });
  }

  @bind
  toggleShowExistingCreditCards(event) {
    event.preventDefault();
    this.setState(previousState => ({
      billingCardAddress: this.defaultBillingCardAddress,
      editingCard: false,
      showExistingCreditCards: !previousState.showExistingCreditCards,
      cardholderName: '',
    }));
  }

  @bind
  setSelectedCreditCardId(id: string) {
    this.setState({
      selectedCreditCardId: id,
    });
  }

  @bind
  renderCreditCardFormButton() {
    const belowCreditCardThreshold = this.props.creditCards.length < CREDIT_CARD_LIMIT;

    if (belowCreditCardThreshold) {
      return (
        <CreditCardFormToggleButton
          classes="button button--primary"
          onClick={this.toggleShowExistingCreditCards}
          buttonText={I18n.t('discovery.coreOnboarding.addNewCard')}
        />
      );
    }

    return <CannotAddNewCardWarning />;
  }

  renderChooseExistingCardButton() {
    if (!this.hasCardsOnFile) return null;

    return (
      <CreditCardFormToggleButton
        classes="button mt-4"
        onClick={this.toggleShowExistingCreditCards}
        buttonText={I18n.t('discovery.coreOnboarding.chooseExistingCard')}
      />
    );
  }

  renderFormContent() {
    if (this.state.showExistingCreditCards) {
      return (
        <SelectableCreditCardList
          creditCards={this.props.creditCards}
          showEditURL={this.props.showEditURL}
          setSelectedCreditCardId={this.setSelectedCreditCardId}
          selectedCreditCardId={this.state.selectedCreditCardId}
          handleEditClick={this.handleEditClick}
        >
          {this.renderCreditCardFormButton()}
        </SelectableCreditCardList>
      );
    }

    return (
      <div>
        <BillingMethodNewCardFormFields
          setCardState={this.onTokenizedFieldUpdate}
          setBillingCardAddress={this.onBillingCardAddressUpdate}
          billingCardAddress={this.state.billingCardAddress}
          countries={this.props.countries}
          setCardholderName={this.setCardholderName}
          cardholderName={this.state.cardholderName}
        />
        {this.renderChooseExistingCardButton()}
      </div>
    );
  }

  renderDescription() {
    if (!this.props.description) {
      return null;
    }

    return (
      <div className="form-section__info">
        <div className="form-section__title">
          {I18n.t('discovery.coreOnboarding.billingMethod.subTitle')}
        </div>
        <p className="form-section__description">
          {this.props.description}
        </p>
      </div>
    );
  }

  renderTitle() {
    if (!this.props.title) {
      return null;
    }

    return (
      <div className="form-section-wrapper__header">
        <h2 className="form-section-wrapper__title">
          {this.props.title}
        </h2>
      </div>
    );
  }

  renderForm() {
    const { isSaving, validationErrors } = this.props;

    return (
      <div className="form-section-wrapper__body">
        {validationErrors.length > 0 && (
          <RCAlertBox type="error">
            {validationErrors.map(error => (<p>{error.message}</p>))}
          </RCAlertBox>
        )}
        <div className="form-section">
          <div className="form-section__wrapper">
            {this.renderDescription()}
            <div className="form-section__content">
              {this.renderFormContent()}
            </div>
          </div>
        </div>
        <OnboardingFormSaveButton
          isSaving={isSaving}
          onClick={this.updateBillingMethod}
        />
      </div>
    );
  }

  render() {
    return (
      <div className="form-section-wrapper">
        {this.renderTitle()}
        {this.renderForm()}
      </div>
    );
  }
}
