import React, { RefObject } from 'react';
import I18n from 'i18n-js';
import classNames from 'classnames';

import { RCTooltip, RCIcon, InputMode } from '@reverbdotcom/cadence/components';
import { EyeIcon, EyeSlashIcon } from '@reverbdotcom/cadence/icons/react';
import EmailValidationHelper from './helpers/email_validation_helper';

import ControlledInput, { InputType } from './controlled_input';
import UncontrolledInput from './uncontrolled_input';
import FormGroup from './form_group';
import bind from '../bind';

interface IProps {
  inputName: string;
  inputTitleValue?: string; // This is the title attribute for the input, not a label
  label: string | JSX.Element | React.ReactNode;
  labelTooltipText?: string;

  value?: string;
  updateField?: Function;
  onBlur?: (event: FocusEvent, attributes: PlainObject) => void;
  fieldName?: string;

  id?: string;
  required?: boolean;
  disabled?: boolean;
  tagOptional?: boolean;
  tagRecommended?: boolean;
  tagRequired?: boolean;
  errorText?: string;
  helpText?: string | JSX.Element | React.ReactNode;
  placeholder?: string;
  currencyPrefix?: string;
  currencySuffix?: string;
  // defaults to type='text'
  inputType?: InputType;
  autoComplete?: string;
  autoCorrect?: string;
  autoCapitalize?: string;
  autoFocus?: boolean;
  minLength?: number;
  maxLength?: number;
  displayCharacterCount?: boolean;
  spellCheck?: boolean;
  pattern?: string;
  numberOnly?: boolean;
  large?: boolean;
  inputMode?: InputMode;
  inputRef?: RefObject<HTMLInputElement>;
  step?: string;
}

interface IState {
  showPassword: boolean;
  inputValue: string;
}

export default class FormGroupWithInput extends React.Component<IProps, IState> {
  state = {
    showPassword: false,
    inputValue: null,
  };

  shouldBeControlled() {
    return !!this.props.updateField || !!this.props.onBlur;
  }

  getInputType(): InputType {
    // if showing password, just go with the default of 'text'
    if (this.props.inputType === 'password' && this.state.showPassword) return 'text';
    return this.props.inputType || 'text';
  }

  @bind
  togglePassword(evt) {
    evt.preventDefault();
    this.setState({ showPassword: !this.state.showPassword });
  }

  getCurrentLength() {
    if (this.shouldBeControlled()) {
      return this.props.value ? this.props.value.length : 0;
    }

    return this.state.inputValue?.length || 0;
  }

  getErrorText() {
    if (this.props.errorText) {
      // if an errorText prop is passed in, let that override any logic below
      return this.props.errorText;
    }

    if (this.props.inputType === 'email') {
      const inputValue = this.shouldBeControlled() ? this.props.value : this.state.inputValue;
      // no need to tell the user their email is invalid if they haven't typed anything
      if (!inputValue) {
        return '';
      }
      if (!EmailValidationHelper.isEmailValid(inputValue)) {
        return I18n.t('commons.signupForm.emailValidationError');
      }
    }

    return this.props.errorText;
  }

  @bind
  onChange(evt) {
    this.setState({ inputValue: evt.target.value });
  }

  renderToggler() {
    return (
      <button
        className="form-group__password-container__toggle"
        onClick={this.togglePassword}
        type="button"
      >
        {this.state.showPassword ?
          <RCIcon svgComponent={EyeSlashIcon} title={I18n.t('commons.signupForm.hidePassword')} /> :
          <RCIcon svgComponent={EyeIcon} title={I18n.t('commons.signupForm.showPassword')} />
        }
      </button>
    );
  }

  renderControlledInput() {
    return (
      <ControlledInput
        autoCapitalize={this.props.autoCapitalize}
        autoComplete={this.props.autoComplete}
        autoCorrect={this.props.autoCorrect}
        autoFocus={this.props.autoFocus}
        currencyPrefix={this.props.currencyPrefix}
        currencySuffix={this.props.currencySuffix}
        disabled={this.props.disabled}
        fieldName={this.props.fieldName || this.props.inputName}
        id={this.props.id}
        inputName={this.props.inputName}
        inputType={this.getInputType()}
        inputValue={this.props.value}
        minLength={this.props.minLength}
        maxLength={this.props.maxLength}
        numberOnly={this.props.numberOnly}
        pattern={this.props.pattern}
        placeholder={this.props.placeholder}
        required={this.props.required}
        spellCheck={this.props.spellCheck}
        updateField={this.props.updateField}
        onBlur={this.props.onBlur}
        inputTitleValue={this.props.inputTitleValue}
        inputMode={this.props.inputMode}
        inputRef={this.props.inputRef}
        step={this.props.step}
      />
    );
  }

  renderUncontrolledInput() {
    return (
      <UncontrolledInput
        autoCapitalize={this.props.autoCapitalize}
        autoComplete={this.props.autoComplete}
        autoCorrect={this.props.autoCorrect}
        autoFocus={this.props.autoFocus}
        currencyPrefix={this.props.currencyPrefix}
        currencySuffix={this.props.currencySuffix}
        disabled={this.props.disabled}
        id={this.props.id || this.props.inputName}
        inputName={this.props.inputName}
        inputType={this.getInputType()}
        minLength={this.props.minLength}
        maxLength={this.props.maxLength}
        pattern={this.props.pattern}
        placeholder={this.props.placeholder}
        required={this.props.required}
        spellCheck={this.props.spellCheck}
        numberOnly={this.props.numberOnly}
        onChange={this.onChange}
        inputTitleValue={this.props.inputTitleValue}
        inputMode={this.props.inputMode}
      />
    );
  }

  renderInput() {
    if (this.shouldBeControlled()) return this.renderControlledInput();
    return this.renderUncontrolledInput();
  }

  renderInputContainer() {
    if (this.props.inputType !== 'password') return this.renderInput();

    const classes = classNames(
      'form-group__password-container',
      { 'form-group__password-container--shown': this.state.showPassword },
    );

    return (
      <div className={classes}>
        {this.renderInput()}
        {this.renderToggler()}
      </div>
    );
  }

  renderHelpText() {
    const { maxLength, displayCharacterCount, helpText } = this.props;
    const current = this.getCurrentLength();

    if (!maxLength && !displayCharacterCount && !helpText) return false;

    return (
      <span data-helptext>
        {displayCharacterCount && !!maxLength &&
          <span className="mr-4">
            {current} / {maxLength}
          </span>
        }
        {helpText}
      </span>
    );
  }

  renderLabel() {
    if (this.props.labelTooltipText) {
      return (
        <RCTooltip text={this.props.labelTooltipText}>
          {this.props.label}
        </RCTooltip>
      );
    }

    return this.props.label;
  }

  render() {
    return (
      <FormGroup
        label={this.renderLabel()}
        inputName={this.props.inputName}
        errorText={this.getErrorText()}
        helpText={this.renderHelpText()}
        tagOptional={this.props.tagOptional}
        tagRecommended={this.props.tagRecommended}
        tagRequired={this.props.tagRequired}
        large={this.props.large}
      >
        {this.renderInputContainer()}
      </FormGroup>
    );
  }
}
