import React from 'react';
import classNames from 'classnames';

import { IDynamicComponentProps } from '../dynamic_component_props';

export interface IProps extends IDynamicComponentProps {
  jumplinkSlug?: string;
  width?: string;

  // legacy support
  htmlContent?: string;
  contentHtml?: string;
}

export default function MarkdownSectionHTML(props: IProps) {
  const content = props.htmlContent || props.contentHtml;
  const classes = classNames(
    'markdown-section',
    {
      'markdown-section--site-width': props.width === 'sitewidth',
      'markdown-section--fullbleed': props.width === 'fullbleed',
    },
  );

  if (!content) return null;

  return (
    <div
      className={classes}
      id={props.jumplinkSlug}
      dangerouslySetInnerHTML={{ __html: content }}
    />
  );
}
