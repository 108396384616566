import React from 'react';
import { Route } from '@reverbdotcom/commons/src/route_settings';
import {
  MY_FAVORITES_WATCH_LIST_ROUTE_PATH,
  MY_FAVORITES_SEARCHES_ROUTE_PATH,
  MY_FAVORITES_SHOPS_ROUTE_PATH,
  MY_FAVORITES_WATCH_LIST_ENDED_ROUTE_PATH,
  MY_FAVORITES_FEED_ROUTE_PATH,
  MY_FAVORITES_DISCOVER_ROUTE_PATH,
  MY_FAVORITES_LANDING_ROUTE_PATH,
} from '../components/shared/constants';
import { buildFavoritesHubPageViewEvent } from '@reverbdotcom/commons/src/event_tracking/favorites';

export const MyFavoritesLandingRoute = (
  <Route
    key="my_favorites_landing"
    path={MY_FAVORITES_LANDING_ROUTE_PATH}
    props={{
      clientSide: false, // Avoid using clientSide: true because it causes stale data after saving a favorite: https://reverb.atlassian.net/browse/HF-407
    }}
    getComponent={(_, cb) => {
      import(
        /* webpackChunkName: 'favorites' */
        '../components/favorites/favorites_layout_wrapper'
      ).then(m => cb(null, m.FavoritesLandingLayoutWrapper));
    }}
  />
);

export const MyFavoritesWatchListRoute = (
  <Route
    key="my_favorites_watch_list"
    path={MY_FAVORITES_WATCH_LIST_ROUTE_PATH}
    props={{
      clientSide: false, // Avoid using clientSide: true because it causes stale data after saving a favorite: https://reverb.atlassian.net/browse/HF-407
      pageViewEvent: ({ location }) => (buildFavoritesHubPageViewEvent(location)),
    }}
    getComponent={(_, cb) => {
      import(
        /* webpackChunkName: 'favorites' */
        '../components/favorites/favorites_layout_wrapper'
      ).then(m => cb(null, m.FavoritesWatchListLayoutWrapper));
    }}
  />
);

export const MyFavoritesWatchListEndedRoute = (
  <Route
    key="my_favorites_watch_list_ended"
    path={MY_FAVORITES_WATCH_LIST_ENDED_ROUTE_PATH}
    props={{
      ended: true,
      clientSide: false, // Avoid using clientSide: true because it causes stale data after saving a favorite: https://reverb.atlassian.net/browse/HF-407
      pageViewEvent: ({ location }) => (buildFavoritesHubPageViewEvent(location)),
    }}
    getComponent={(_, cb) => {
      import(
        /* webpackChunkName: 'favorites' */
        '../components/favorites/favorites_layout_wrapper'
      ).then(m => cb(null, m.FavoritesWatchListEndedLayoutWrapper));
    }}
  />
);

export const MyFavoritesSearchesRoute = (
  <Route
    key="my_favorites_searches"
    path={MY_FAVORITES_SEARCHES_ROUTE_PATH}
    props={{
      clientSide: false, // Avoid using clientSide: true because it causes stale data after saving a favorite: https://reverb.atlassian.net/browse/HF-407
      pageViewEvent: ({ location }) => (buildFavoritesHubPageViewEvent(location)),
    }}
    getComponent={(_, cb) => {
      import(
        /* webpackChunkName: 'favorites' */
        '../components/favorites/favorites_layout_wrapper'
      ).then(m => cb(null, m.FavoritesSearchesLayoutWrapper));
    }}
  />
);

export const MyFavoritesShopsRoute = (
  <Route
    key="my_favorites_shops"
    path={MY_FAVORITES_SHOPS_ROUTE_PATH}
    props={{
      clientSide: false, // Avoid using clientSide: true because it causes stale data after saving a favorite: https://reverb.atlassian.net/browse/HF-407
      pageViewEvent: ({ location }) => (buildFavoritesHubPageViewEvent(location)),
    }}
    getComponent={(_, cb) => {
      import(
        /* webpackChunkName: 'favorites' */
        '../components/favorites/favorites_layout_wrapper'
      ).then(m => cb(null, m.FavoritesShopsLayoutWrapper));
    }}
  />
);

export const MyFavoritesFeedRoute = (
  <Route
    key="my_favorites_feed"
    path={MY_FAVORITES_FEED_ROUTE_PATH}
    props={{
      clientSide: true,
      pageViewEvent: ({ location }) => (buildFavoritesHubPageViewEvent(location)),
    }}
    getComponent={(_, cb) => {
      import(
        /* webpackChunkName: 'favorites' */
        '../components/favorites/favorites_layout_wrapper'
      ).then(m => cb(null, m.FavoritesFeedLayoutWrapper));
    }}
  />
);

export const MyFavoritesDiscoverRoute = (
  <Route
    key="my_favorites_discover"
    path={MY_FAVORITES_DISCOVER_ROUTE_PATH}
    props={{
      clientSide: false,
    }}
    getComponent={(_, cb) => {
      import(
        /* webpackChunkName: 'favorites' */
        '../components/favorites/favorites_layout_wrapper'
      ).then(m => cb(null, m.FavoritesDiscoverWrapper));
    }}
  />
);
