import { QueryParamsUtil } from '@reverbdotcom/discovery-ui';
import { CORE_API_ORIGIN } from '@reverbdotcom/env';

class UrlHelpers {
  digitalListingsPath(params) {
    if (params) {
      return `/api/digital_listings${QueryParamsUtil.queryParamsForUrl(params)}`;
    }

    return '/api/digital_listings';
  }

  digitalListingPath(id) {
    return `/api/digital_listings/${id}`;
  }

  salePath(slug) {
    return `/sales/${slug}`;
  }

  softwarePath(categorySlug) {
    if (categorySlug) {
      return `/software/${categorySlug}`;
    }

    return '/software';
  }

  signupPath(params) {
    return `/signup/${params}`;
  }

  ordersManagerPath() {
    return this.ordersManagerSearchResultsPath();
  }

  ordersManagerBuyingSearchResultsPath() {
    return '/my/buying/orders';
  }

  ordersManagerSellingSearchResultsPath() {
    return '/my/selling/orders';
  }

  orderPath(orderId) {
    return `/my/selling/orders/${orderId}`;
  }

  webListingPath(listingId) {
    return `/item/${listingId}`;
  }

  ordersManagerBuyingSidebarFacetsPath(params) {
    return `/my/buying/orders/sidebar_facets${QueryParamsUtil.queryParamsForUrl(params)}`;
  }

  ordersManagerBuyingOrderFacetsPath(params) {
    return `/my/buying/orders/order_facets${QueryParamsUtil.queryParamsForUrl(params)}`;
  }

  ordersManagerSellingSidebarFacetsPath(params) {
    return `/my/selling/orders/sidebar_facets${QueryParamsUtil.queryParamsForUrl(params)}`;
  }

  ordersManagerSellingOrderFacetsPath(params) {
    return `/my/selling/orders/order_facets${QueryParamsUtil.queryParamsForUrl(params)}`;
  }

  sellingExportsOrdersPath() {
    return '/my/selling/exports/orders';
  }

  sellingExportsPaymentsPath() {
    return '/my/selling/exports/payments';
  }

  buyingExportsOrdersPath() {
    return '/my/buying/exports/orders';
  }

  get rootPath() {
    return '/';
  }

  bumpClickTrackingPath(listingId, bumpClickUuid) {
    return `/bump/clicks?b=${bumpClickUuid}&listing_id=${listingId}`;
  }

  get bumpTrackingPath() {
    return '/bump/impressions/track_multiple';
  }

  get shopTaxPolicyPath() {
    return '/my/selling/tax_policy';
  }

  get shopPoliciesEditPath() {
    return '/my/selling/shop_policies/edit';
  }

  brandCollectionHeaderPath(slug) {
    return `/api/brands/${slug}/collection_header`;
  }

  categoryCollectionHeaderPath(categoryUuidOrSlug) {
    return `/api/categories/${categoryUuidOrSlug}/collection_header`;
  }

  categoryCollectionHeadersPath(rootSlug, childSlug) {
    return `/api/categories/collection_headers?root_slug=${rootSlug}&child_slug=${childSlug}`;
  }

  curatedSetCollectionHeaderPath(slug) {
    return `/api/curated_sets/${slug}/collection_header`;
  }

  curatedSearchCollectionHeaderPath(slug) {
    return `/api/curated_searches/${slug}/collection_header`;
  }

  productTypeCollectionHeaderPath(slug) {
    return `/api/product_types/${slug}/collection_header`;
  }

  get listingsManagerPath() {
    return '/my/selling/listings';
  }

  get listingsManagerUserDataPath() {
    return '/my/selling/listings/user_data';
  }

  get myCreditCardsIndexPath() {
    return `${CORE_API_ORIGIN}/api/my/credit_cards/`;
  }

  // TODO: This is only used in a spec
  myCreditCardsCreatePath() {
    return '/api/my/credit_cards/';
  }

  myCreditCardPath(creditCardId) {
    return `${CORE_API_ORIGIN}/api/my/credit_cards/${creditCardId}`;
  }

  makeDefaultCreditCardPath(creditCardId) {
    return `/my/credit_cards/${creditCardId}/set_default`;
  }

  get countriesPath() {
    return '/api/countries';
  }

  get shippingRegionsPath() {
    return '/api/shipping/regions';
  }

  get listingsManagerSidebarFacetsPath() {
    return '/my/selling/listings/sidebar_facets';
  }

  get listingsManagerListingResultsPath() {
    return '/my/selling/listings/listing_results';
  }

  get categoriesWithTraitsPath() {
    return '/api/categories/traits';
  }

  // POST - add a product to your watch list
  watchProductPath(productId) {
    return `/watched_products?id=${productId}`;
  }

  // DELETE - remove a product from your watch list
  unwatchProductPath(productId) {
    return `/watched_products/${productId}`;
  }

  get watchedProductsPath() {
    return '/watched_products';
  }

  addToCartPath(productId) {
    return `/web_api/cart/${productId}`;
  }

  get addMultipleToCartPath() {
    return '/web_api/cart/multiple';
  }

  get cartPath() {
    return '/cart';
  }

  get apiCartPath() {
    return '/web_api/cart';
  }

  relatedOrdersForShipmentPath(primaryOrderId) {
    return `${CORE_API_ORIGIN}/api/my/orders/selling/related_orders_for_shipment/${primaryOrderId}`;
  }

  get statementsPath() {
    return '/my/selling/statements';
  }

  cmsPagePath(slug) {
    return `/page/${slug}`;
  }

  get affirmFaqPath() {
    return this.cmsPagePath('affirm-faqs');
  }

  get reverbPaymentsFaqPath() {
    return '/selling/reverb-payment-faq';
  }

  listingPath(listingId) {
    return `/api/listings/${listingId}`;
  }

  // POST - tracks a product as viewed
  listingViewedPath(listingId) {
    return `/product_views/${listingId}`;
  }

  listingProductReviewsPath(listingId) {
    return `/api/listings/${listingId}/reviews`;
  }

  listingRelatedArticlesPath(listingId) {
    return `/api/listings/${listingId}/related_articles`;
  }

  listingUpsellsPath(listingId) {
    return `/api/listings/${listingId}/upsells`;
  }

  upsellsPath() {
    return '/api/upsells';
  }

  messageEmbedsPath(messageId) {
    return `/api/messages/${messageId}/message_embeds`;
  }

  get relatedArticlesPath() {
    return '/api/articles/related';
  }

  inverseReverseCurrencyExchangePath(params) {
    return `/api/currencies/convert/inverse_reverse${QueryParamsUtil.queryParamsForUrl(params)}`;
  }

  placeOfferPath(productId) {
    // POST - place an offer
    return `/api/listings/${productId}/offer`;
  }

  offerFromConversationPath(conversationId) {
    // POST - create an offer from a conversation
    return `/api/conversations/${conversationId}/offer`;
  }

  listingLatestNegotiationPath(productId) {
    // GET - returns your latest negotiation for a product
    return `/api/listings/${productId}/negotiation`;
  }

  brandFindByNamePath(params) {
    // GET
    return `/api/brand_find_by_name${QueryParamsUtil.queryParamsForUrl(params)}`;
  }

  requestNewBrandPath() {
    return '/api/new_brand_request';
  }

  get myListingsPath() {
    return `${CORE_API_ORIGIN}/api/my/listings`;
  }

  brandPath(brandSlug) {
    return `/brand/${brandSlug}`;
  }

  priceGuideTransactionsPath(priceGuideId, params) {
    // GET
    const queryParams = QueryParamsUtil.queryParamsForUrl(params);
    return `/api/priceguide/${priceGuideId}/transactions${queryParams}`;
  }

  comparisonShoppinagPageTransactionsPath(cspId, params) {
    // GET
    const queryParams = QueryParamsUtil.queryParamsForUrl(params);
    return `/api/comparison_shopping_pages/${cspId}/transactions${queryParams}`;
  }

  priceGuideTransactionsSummaryPath(priceGuideId, params) {
    // GET
    const queryParams = QueryParamsUtil.queryParamsForUrl(params);
    return `/api/priceguide/${priceGuideId}/transactions/summary${queryParams}`;
  }

  comparisonShoppinagPageTransactionsSummaryPath(cspId, params) {
    // GET
    const queryParams = QueryParamsUtil.queryParamsForUrl(params);
    return `/api/comparison_shopping_pages/${cspId}/transactions/summary${queryParams}`;
  }

  comparisonShoppingPagePriceGuidePricesPath(cspId) {
    // GET
    return `/api/comparison_shopping_pages/${cspId}/transactions/price_guide_prices`;
  }

  orderNotesPath(orderId) {
    // GET V4
    // V4 endpoint should ONLY becalled by RQL. Usage of this method should be refactored
    // to query for order notes in RQL.
    const queryParams = QueryParamsUtil.queryParamsForUrl({ order_ids: [orderId] });
    return `/api/order_notes${queryParams}`;
  }

  get createOrderNotePath() {
    // POST V4
    return '/api/order_notes';
  }

  get deleteOrderNotePath() {
    // DELETE V4
    return '/api/order_notes';
  }

  get editBankAccountPath() {
    return '/my/selling/bank_account';
  }

  get shopOnboardingSetupPath() {
    return '/shop_onboarding/setup';
  }

  get shippingLabelsPage() {
    return 'page/shipping-labels';
  }
}

export default new UrlHelpers();
