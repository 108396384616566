import React from 'react';
import { RCTag } from '@reverbdotcom/cadence/components';
import I18n from 'i18n-js';
import { core_apimessages_TaxProfile_TaxProfileVerificationStatus as VerificationStatus } from '@reverbdotcom/commons/src/gql/graphql';
import { I18N as Translate } from '@reverbdotcom/commons/src/components/translate';

type Variant = 'incomplete' | 'pending' | 'verified' ;
export interface Props {
  variant: Variant;
}

export function TinVerificationStatus({ variant }: Props) {
  const message = () => {
    switch (variant) {
      case 'pending':
        return I18n.t('discovery.taxProfile.tinVerification.pending');
      case 'verified':
        return I18n.t('discovery.taxProfile.tinVerification.complete');
      default:
        return I18n.t('discovery.taxProfile.tinVerification.incomplete');
    }
  };

  return (
    <RCTag
      variant={variant === 'verified' ? 'success' : 'default'}
    >
      <span className={variant === 'pending' ? 'tin-verification__state--pending' : ''}>{message()}</span>
    </RCTag>
  );
}

interface IVerificationStatusProps {
  verificationStatus: VerificationStatus;
}

export function USTaxProfileVerificationStatus({ verificationStatus }: IVerificationStatusProps) {
  const message = () => {
    switch (verificationStatus) {
      case VerificationStatus.PENDING_VERIFICATION:
        return 'discovery.taxProfile.tinVerification.pending';
      case VerificationStatus.VERIFIED:
        return 'discovery.taxProfile.tinVerification.complete';
      default:
        return 'discovery.taxProfile.tinVerification.incomplete';
    }
  };

  const className = verificationStatus === VerificationStatus.PENDING_VERIFICATION ? 'tin-verification__state--pending' : '';

  return (
    <RCTag
      variant={verificationStatus === VerificationStatus.VERIFIED ? 'success' : 'default'}
    >
      <Translate tag="span" className={className} text={message()} />
    </RCTag>
  );
}

export default TinVerificationStatus;
