export default {
  index: {
    header: {
      temporaryPauseBanner: 'Thank you for your interest in Trade in for cash! We recently concluded our pilot program and will share an update as soon as Trade in for cash becomes available again.',
      title: 'Trade in for cash',
      subtitle: 'Sell your gear directly to trusted buyers for an all-in cash price',
      sellInSeconds: 'Sell to a guaranteed buyer in seconds',
      noFees: 'No selling or shipping fees',
      noListing: 'Skip taking photos and creating a listing',
      mobileHeaderImageAlt: 'Pictures of gear',
      startButton: 'Start your trade-in',
    },
    explanation: {
      title: 'How does it work?',
      stepOneImageAlt: 'Icon of guitar',
      stepOneTitle: '1. Search for gear you own',
      stepOneDescription: 'If your gear qualifies for trade-in, you can sell to a buyer in seconds',
      stepTwoImageAlt: 'Icon of shipping box',
      stepTwoTitle: '2. Pack & ship',
      stepTwoDescription: 'We provide a free shipping label and you pay no shipping fees',
      stepThreeImageAlt: 'Icon of money',
      stepThreeTitle: '3. Get paid',
      stepThreeDescription: 'Once your item is verified, you receive the full cash offer listed below',
    },
    search: {
      title: 'Get started with your trade-in',
      subtitle: 'What would you like to trade in for cash?',
      placeholder: 'Brand and model name',
    },
    sellCard: {
      guaranteedBuyer: 'Guaranteed buyer',
      cashOfferHtml: '%{boldOpen}%{displayAmount} cash offer%{boldClose}',
      buttonText: 'Trade in Now',
      notInAHurry: 'Want to set the price? %{linkOpen}List it for sale%{linkClose}',
      estimatedListingPrice: 'Estimated Listing Price',
    },
  },
  checkout: {
    header: {
      title: 'Trade in for cash',
      description: 'Sell your gear directly to trusted buyers for an all-in cash price',
    },
    item: {
      sellingForHtml: 'Selling for %{boldOpen}%{value}%{boldClose}',
      uspsLogoAlt: 'USPS Logo',
      upsLogoAlt: 'UPS Logo',
      carrierLogoAlt: 'Shipping Carrier Logo',
      shippingLabelInfo: 'Free %{boldOpen}%{carrier} shipping label%{boldClose} will be provided',
    },
    condition: {
      title: 'Verify your item\'s condition',
      conditionLabel: 'Is your item in at least very good condition?',
      correctConditionLabel: 'Yes, my item is in very good condition or better.',
      conditionBulletOne: 'Very Good items are fully functional and overall in great condition.',
      conditionBulletTwo: 'There may be a few marks, scratches, or minor blemishes as long as they do not affect the playability or function.',
      incorrectConditionLabel: 'No, my item is not in very good condition.',
      conditionError: 'We\'re sorry, but your item does not qualify for Trade-in. It must be in at least very good condition.',
      modificationsLabel: 'Have you made any modifications?',
      isUnmodifiedLabel: 'No, my item is in its original state.',
      modificationsInfo: '%{boldOpen}Note: %{boldClose}Minor maintenance repairs are good and don’t count as modifications. We won’t accept heavily repaired items or major modifications.',
      isModifiedLabel: 'Yes, I have made modifications.',
      modificationsError: 'We\'re sorry, but your item does not qualify for Trade-in. We don’t accept heavily modified items.',
      helpIconAlt: 'Condition Information Icon',
      beHonest: 'Be honest about the condition of the item you’re selling.',
      beHonestBulletOne: 'When we receive it in the mail, we’re going to determine if it is fully functional and has not been modified.',
      beHonestBulletTwo: 'If the condition is not up to our standards, you will not receive the payout amount and you will have to pay for the shipping to the warehouse and return shipping.',
    },
    shipping: {
      title: 'What is your shipping address?',
      description: 'We will use it on the free shipping label that we will provide.  If there are any issues with the item’s condition, we will return it to this address.',
      addNew: 'Add New Address',
      addNewAriaLabel: 'Add new address',
      usePrimary: 'Use Primary Address',
      usePrimaryAriaLabel: 'Use primary address',
      onlyUsCon: 'We currently only allow Trade-in in the continental United States.',
    },
    terms: {
      title: 'Do you accept our Terms & Conditions?',
      list: {
        first: 'You will receive your payment when we receive your item and determine that it is in at least Very Good Condition and has not been modified.',
        second: 'If your item does not meet our requirements, you will not be paid and you will be responsible for the cost of shipping to the warehouse and return shipping.',
        third: 'You must ship within 5 days.',
        fourthHtml: 'You must have a bank account added in your %{linkOpen}shop settings%{linkClose} to receive your payment.',
      },
      fullTerms: 'View the full Terms & Conditions',
      checkboxLabel: 'I accept the Trade-in Terms & Conditions',
    },
    footer: {
      button: 'Trade in Now',
    },
    loading: {
      pleaseDoNot: 'Please do not close this page.',
      weAreFinalizing: 'We are finalizing your sale with the buyer.',
    },
    errors: {
      inactiveBid: 'Sorry, this offer is no longer valid. Please search for another item to trade in %{linkOpen}here%{linkClose}.',
    },
  },
};
