import React from 'react';
import I18n from 'i18n-js';
import classNames from 'classnames';

import buildSiteFooterData, { SiteFooterLinkData } from './site_footer_data';
import CoreLink from '@reverbdotcom/commons/src/components/core_link';
import NewsletterSignup from '@reverbdotcom/commons/src/components/newsletter_signup';
import { useUser } from '@reverbdotcom/commons/src/user_hooks';
import UserPrivacyConsentContainer from './user_privacy_consent_container';

interface SiteFooterLinkProps {
  linkData: SiteFooterLinkData;
}

function SiteFooterLink({ linkData }: SiteFooterLinkProps) {
  return (
    <CoreLink
      to={linkData.href}
      className="site-footer-v2__link"
    >
      {I18n.t(`discovery.siteFooter.${linkData.textKey}`)}
    </CoreLink>
  );
}

interface SiteFooterLinkSectionProps {
  titleKey: string;
}

export function SiteFooterLinkSection({ titleKey }: SiteFooterLinkSectionProps) {
  const user = useUser();
  const [isOpen, toggleIsOpen] = React.useState(false);
  const { locale, countryCode } = user;
  const siteFooterData = buildSiteFooterData(user);
  const section = siteFooterData.find(data => data.titleKey === titleKey);
  const filterLinksByUserAttributes = link => {
    if (countryCode !== 'US' && link.usOnly) {
      return false;
    }

    if (locale !== 'en' && link.enLocaleOnly) {
      return false;
    }

    return true;
  };
  const renderPrivacySettings = titleKey === 'help';
  const classes = classNames(
    'site-footer-v2__link-section',
    { 'site-footer-v2__link-section--collapsed': !isOpen },
  );

  return (
    <div className={classes}>
      <h3>
        <button
          type="button"
          onClick={() => toggleIsOpen(!isOpen)}
        >
          {I18n.t(`discovery.siteFooter.${section.titleKey}`)}
        </button>
      </h3>
      <div className="site-footer-v2__collapsible-links">
        {section.links.filter(filterLinksByUserAttributes).map(linkData => (
          <SiteFooterLink
            linkData={linkData}
            key={`footer_${linkData.textKey}`}
          />
        ))}
        {renderPrivacySettings && <UserPrivacyConsentContainer isFooterLink />}
      </div>
    </div>
  );
}

export default function SiteFooter() {
  return (
    <div className="site-footer-v2">
      <div className="site-footer-v2__inner">
        <div className="site-footer-v2__link-columns">
          <div className="site-footer-v2__link-column">
            <SiteFooterLinkSection titleKey="shopGear" />
          </div>
          <div className="site-footer-v2__link-column">
            <SiteFooterLinkSection titleKey="sell" />
            <SiteFooterLinkSection titleKey="resources" />
          </div>
          <div className="site-footer-v2__link-column">
            <SiteFooterLinkSection titleKey="help" />
          </div>
          <div className="site-footer-v2__link-column">
            <SiteFooterLinkSection titleKey="company" />
          </div>
        </div>
        <div className="site-footer-v2__newsletter">
          <NewsletterSignup
            title={I18n.t('discovery.siteFooter.newsletterHeader')}
            signupReason="footer_signup"
          />
        </div>
      </div>
    </div>
  );
}
