import React from 'react';
import I18n from 'i18n-js';
import {
  IUserType,
  IRefundType,
  IRefundableOrderProps,
  IRefundRequestProps,
} from './refund_request';

interface IProps {
  userType: IUserType;
  refundType: IRefundType;
  onRefundTypeChange: (event: any) => void;
  order: IRefundableOrderProps;
  refundRequest: IRefundRequestProps;
}

const RefundTypeSelector: React.StatelessComponent<IProps> = (props) => {
  if (props.userType === IUserType.SELLER && props.refundRequest.isPersisted) {
    return null;
  }

  function renderFullRefundTypeText() {
    if (!props.order.hasPreviousRefund) {
      return I18n.t(`discovery.dashboard.refundRequests.${props.userType}.refundType.full`);
    }

    const hasTaxRemittedByReverb
      = props.order.reverbRemittedTaxAmountAvailableToRefund.amountCents !== 0;
    const viewingAsSeller = props.userType === IUserType.SELLER;

    if (hasTaxRemittedByReverb && !viewingAsSeller) {
      return I18n.t(`discovery.dashboard.refundRequests.${props.userType}.refundType.remainingPlusTax`, {
        amount: props.order.amountAvailableToRefund.display,
        amountTax: props.order.reverbRemittedTaxAmountAvailableToRefund.display,
      });
    }

    return I18n.t(`discovery.dashboard.refundRequests.${props.userType}.refundType.remaining`, {
      amount: props.order.amountAvailableToRefund.display,
    });
  }

  return (
    <div className="scaling-mb-4">
      <div className="form-group">
        <div className="form-group__header">
          <span className="weight-bold">{I18n.t(`discovery.dashboard.refundRequests.${props.userType}.refundType.title`)}</span>
        </div>
        <div className="form-group__fields">
          <div className="label-with-radio">
            <input
              type="radio"
              name="order_refund_request[refund_type]"
              id={`order_refund_request_refund_type_${IRefundType.FULL}`}
              value={IRefundType.FULL}
              checked={props.refundType === IRefundType.FULL}
              onChange={props.onRefundTypeChange}
            />
            <label htmlFor={`order_refund_request_refund_type_${IRefundType.FULL}`}>
              {renderFullRefundTypeText()}
            </label>
          </div>
          <div className="label-with-radio">
            <input
              type="radio"
              name="order_refund_request[refund_type]"
              id={`order_refund_request_refund_type_${IRefundType.PARTIAL}`}
              value={IRefundType.PARTIAL}
              checked={props.refundType === IRefundType.PARTIAL}
              onChange={props.onRefundTypeChange}
            />
            <label htmlFor={`order_refund_request_refund_type_${IRefundType.PARTIAL}`}>
              {I18n.t(`discovery.dashboard.refundRequests.${props.userType}.refundType.partial`)}
            </label>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RefundTypeSelector;
