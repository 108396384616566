import React from 'react';
import I18n from 'i18n-js';
// TODO update this to import from commons
// import { gql } from '@reverbdotcom/commons/src/gql';
// eslint-disable-next-line no-restricted-imports
import { gql } from '@apollo/client';

import { useMutation } from '@reverbdotcom/commons/src/useMutation';
import { RCButton, RCTextWithIcon, useMediaQuery } from '@reverbdotcom/cadence/components';
import { Listing } from '@reverbdotcom/commons/src/gql/graphql';
import { TrashIcon } from '@reverbdotcom/cadence/icons/react';
import { ModalDialog } from '@reverbdotcom/commons';
import { isEmpty } from 'lodash';
import { useViewTracking } from '@reverbdotcom/commons/src/use_tracking';
import { MParticleEventName } from '@reverbdotcom/commons/src/elog/mparticle_types';
import { trackEvent } from '@reverbdotcom/commons/src/elog/mparticle_tracker';
import SellerListingCardsToolbarContext from '../SellerListingCardsToolbarContext';
import SellerListingsCollectionContext from '../SellerListingsCollectionContext';

export const COMPONENT_NAME = 'BulkDeleteButton';
export const DELETABLE_STATES = [
  'draft',
];
interface IExternalProps {
  selectedListings: Listing[];
  onSuccessCallback: (responses) => void;
}

const BULK_DELETE_MUTATION = gql`
  mutation Core_MyListings_BulkDelete(
    $input: Input_core_apimessages_BulkDeleteListingsRequest
  ) {
    bulkDeleteListings(input: $input) {
      responses: listings {
        listingId
        success
        errors: deleteErrors {
          message
        }
      }
    }
  }
`;

const BULK_DELETE_ASYNC_MUTATION = gql`
  mutation Core_MyListings_BulkDeleteAsync(
    $input: Input_core_apimessages_BulkDeleteListingsAsyncRequest
  ) {
    bulkDeleteListingsAsync(input: $input) {
      enqueued
      message
    }
  }
`;

export default function BulkDeleteButton({
  selectedListings,
  onSuccessCallback,
}: IExternalProps) {
  const {
    listingsCollectionState,
  } = React.useContext(SellerListingsCollectionContext);

  const {
    hasAllMatchesSelected,
    serializedDashboardSearchParams,
    totalMatches,
  } = listingsCollectionState;

  const {
    handleGenericError,
    handleAsyncBulkResponse,
  } = React.useContext(SellerListingCardsToolbarContext);

  const isMobile = useMediaQuery('mobile');

  const listingIds = selectedListings.map(listing => listing.id);

  const deletableListings = selectedListings.filter(listing => DELETABLE_STATES.includes(listing.state));
  const undeletableListings = selectedListings.filter(listing => !DELETABLE_STATES.includes(listing.state));

  const hasDeletableListings = !!deletableListings.length;
  const hasNoDeletableListings = undeletableListings.length === selectedListings.length;
  const hasUndeletableListings = !!undeletableListings.length;

  const totalListingsForDeletion = hasAllMatchesSelected ? totalMatches : deletableListings.length;

  const modalSubmitI18nKey = hasNoDeletableListings ? 'close' : 'delete';

  const [isErrorModalOpen, showErrorModal] = React.useState(false);

  const [bulkDeleteListings, {
    data = {},
    loading,
    errors,
  }] = useMutation(BULK_DELETE_MUTATION);

  const { responses } = data.bulkDeleteListings ?? {};
  const hasErrors = !!errors.length;

  const [bulkDeleteListingsAsync, {
    data: asyncData,
    loading: asyncLoading,
    errors: asyncErrors,
  }] = useMutation(BULK_DELETE_ASYNC_MUTATION);
  const hasAsyncErrors = !!asyncErrors.length;

  React.useEffect(() => {
    if (loading) return;
    if (hasErrors) return handleGenericError();

    handleResponse();
  }, [loading]);

  React.useEffect(() => {
    if (asyncLoading) return;
    if (hasAsyncErrors) return handleGenericError();

    handleAsyncResponse();
  }, [asyncLoading]);

  function handleResponse() {
    if (isEmpty(data)) return;

    onSuccessCallback(responses);
  }

  function handleAsyncResponse() {
    if (asyncData?.bulkDeleteListingsAsync) {
      handleAsyncBulkResponse({
        I18nKey: 'discovery.listingsManagement.toolbar.bulkActions.bulkDelete.asyncResponses.successes',
        expectedTotal: totalMatches,
      });
    }
  }

  function deleteListings() {
    trackEvent({
      componentName: COMPONENT_NAME,
      eventName: MParticleEventName.DeletingListings,
      listingsCount: totalListingsForDeletion,
      hasAllMatchesSelected,
    });

    if (hasAllMatchesSelected) {
      bulkDeleteListingsAsync({
        variables: {
          input: {
            serializedDashboardSearchParams,
            expectedTotal: totalMatches,
          },
        },
      });
    } else {
      const deletableListingIds = deletableListings.map(listing => listing.id);

      bulkDeleteListings({
        variables: {
          input: {
            listingIds: deletableListingIds,
          },
        },
      });
    }
  }

  function handleDefaultSubmit() {
    trackEvent({
      eventName: MParticleEventName.ClickedComponent,
      componentName: COMPONENT_NAME,
    });

    if (hasUndeletableListings) {
      showErrorModal(true);
    } else {
      deleteListings();
    }
  }

  function handleModalSubmit() {
    if (hasNoDeletableListings) {
      showErrorModal(false);
    } else {
      showErrorModal(false);
      deleteListings();
    }
  }

  useViewTracking({
    componentName: COMPONENT_NAME,
    eventName: MParticleEventName.ComponentView,
  }, true);

  return (
    <>
      <RCButton
        fullWidth
        variant="outlined"
        size={isMobile ? 'medium' : 'mini'}
        onClick={handleDefaultSubmit}
        disabled={!listingIds.length || loading}
        isSubmit={loading}
      >
        <RCTextWithIcon svgComponent={TrashIcon} placement="right">
          {I18n.t('discovery.listingsManagement.toolbar.bulkActions.bulkDelete.deleteSelected')}
        </RCTextWithIcon>
      </RCButton>
      <ModalDialog
        isOpen={isErrorModalOpen}
        headerTitle={I18n.t('discovery.listingsManagement.toolbar.bulkActions.bulkDelete.deleteErrorModal.headerTitle', {
          count: deletableListings.length,
        })}
        saveButtonText={I18n.t(`discovery.listingsManagement.toolbar.bulkActions.bulkDelete.deleteErrorModal.${modalSubmitI18nKey}`)}
        hideFooterDismissButton={hasNoDeletableListings}
        onSubmit={handleModalSubmit}
      >
        {hasDeletableListings && (
          <div className="weight-bold mb-4">
            {I18n.t('discovery.listingsManagement.toolbar.bulkActions.bulkDelete.deleteErrorModal.deleteMessage', {
              count: deletableListings.length,
            })}
          </div>
        )}

        <div className="weight-bold">
          {I18n.t('discovery.listingsManagement.toolbar.bulkActions.bulkDelete.deleteErrorModal.errorMessage', {
            count: undeletableListings.length,
          })}
        </div>
      </ModalDialog>
    </>
  );
}
