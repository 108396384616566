import { ChildProps } from '@apollo/client/react/hoc';
// TODO update this to import from commons
// import { gql } from '@reverbdotcom/commons/src/gql';
// eslint-disable-next-line no-restricted-imports
import { gql } from '@apollo/client';
import React from 'react';
import I18n from 'i18n-js';
import { withGraphql } from '@reverbdotcom/commons/src/with_graphql';
import { WithRouterProps } from 'react-router';
import {
  CoreCreditCardWalletIndex,
  core_apimessages_CheckoutPaymentMethod_Type as PaymentMethodType,
} from '@reverbdotcom/commons/src/gql/graphql';
import { useCreditCardForm } from './credit_card_form_context';
import { Paths } from './shared/url_helpers';
import { buttonClassName, RCRadioCardGroup } from '@reverbdotcom/cadence/components';
import CoreLink from '@reverbdotcom/commons/src/components/core_link';
import CreditCardWalletRow, { CreditCardWalletRowFragment } from './credit_card_wallet_row';
import { SiteModulePlaceholder } from '@reverbdotcom/commons/src/components/site_module_placeholder';
import CreditCardAddButton from './CreditCardAddButton';
import { checkoutClickEvent } from './checkout/checkoutTracking';
import { CreditCardWalletClickInteraction } from '@reverbdotcom/commons/src/elog/mparticle_types';

type Props = ChildProps<WithRouterProps, CoreCreditCardWalletIndex.Query>;

export function CreditCardWalletIndex(props: Props) {
  const form = useCreditCardForm();
  const { query } = props.location;

  const creditCards = props.data?.me?.creditCards || [];
  const cardId = query.selected_card_id
    ? query.selected_card_id
    : creditCards[0]?.id;

  const checkoutId = props.params.id;

  function clickData(checkoutClickInteraction: CreditCardWalletClickInteraction) {
    return {
      checkoutClickInteraction,
      checkoutId,
      paymentMethod: PaymentMethodType.DIRECT_CHECKOUT,
    };
  }

  function onCardChange({ target: { value: creditCardId } }) {

    const selectedCardPath = Paths.checkoutCreditCardWallet.expand({
      checkoutId,
      selected_card_id: creditCardId,
    });

    props.router.push(selectedCardPath);
  }

  function onSubmit(e) {
    e.preventDefault();
    form.onSelect(cardId);
  }

  if (props.data?.loading) {
    return (
      <>
        <SiteModulePlaceholder />
        <SiteModulePlaceholder />
      </>
    );
  }

  return (
    <form id={form.id} onSubmit={onSubmit}>
      <RCRadioCardGroup
        id="credit-card-wallet"
        value={cardId}
        onChange={onCardChange}
        name="credit-card-wallet"
        label=""
      >
        {creditCards.map((creditCard) => (
          <RCRadioCardGroup.Option
            key={creditCard.id}
            value={creditCard.id}
            label={
              <div className="d-flex fx-justify-between">
                <CreditCardWalletRow
                  creditCard={creditCard}
                />
                <div className="fx-align-self-center">
                  <CoreLink
                    className={buttonClassName({ variant: 'muted' })}
                    to={Paths.checkoutEditCreditCard.expand({
                      checkoutId,
                      creditCardId: creditCard.id,
                    })}
                    clickEvent={checkoutClickEvent(clickData(CreditCardWalletClickInteraction.EditCard))}
                  >
                    {I18n.t('discovery.creditCardWallet.editButton')}
                  </CoreLink>
                </div>
              </div>
            }
          />
        ))}
      </RCRadioCardGroup>
      <CreditCardAddButton
        creditCardCount={creditCards.length}
        button={
          <CoreLink
            className={buttonClassName({ variant: 'muted' })}
            to={Paths.checkoutCreateCreditCard.expand({
              checkoutId: props.params.id,
              selected_card_id: cardId,
            })}
            clickEvent={checkoutClickEvent(clickData(CreditCardWalletClickInteraction.AddCard))}
          >
            {I18n.t('discovery.creditCardWallet.modal.create.title')}
          </CoreLink>
        }
      />
    </form>
  );
}

export const CreditCardWalletLayoutFragment = gql`
  fragment CreditCardWalletLayoutFields on rql_Me {
    _id
    ...CreditCardWalletRowFields
  }
  ${CreditCardWalletRowFragment}
`;

const withQuery = withGraphql<Props, CoreCreditCardWalletIndex.Query>(
  gql`
    query Core_CreditCardWallet_Index {
      me {
        _id
        uuid
        ...CreditCardWalletLayoutFields
      }
    }
    ${CreditCardWalletLayoutFragment}
  `,
  {
    options: {
      errorPolicy: 'all',
    },
  },
);

export default withQuery(CreditCardWalletIndex);
