export enum AdyenCardType {
  Mastercard = 'mc',
  Visa = 'visa',
  Discover = 'discover',
  Amex = 'amex',
  Card = 'card', // generic fallback
}

const REVERB_TO_ADYEN_CARD_TYPE = {
  mastercard: 'mc',
  visa: 'visa',
  discover: 'discover',
  'american express': 'amex',
};

export function reverbToAdyenCardType(cardType: string): AdyenCardType {
  if (!cardType) {
    return AdyenCardType.Card;
  }

  return REVERB_TO_ADYEN_CARD_TYPE[cardType.toLowerCase()];
}
