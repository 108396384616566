/* eslint max-len: ["error", 500] */

export default {
  title: 'What\'s your return policy?',
  recommended: 'Recommended',
  noReturnWarning: 'Accepting returns helps Reverb shoppers buy with confidence—we recommend accepting returns to help sell faster.',
  edit: 'Edit',
  summary: 'Accept returns within {{usedReturnWindowDays}} days on used, {{newReturnWindowDays}} days on new',
  defaultReturnPolicy: {
    header: 'Reverb\'s Standard Return Policy',
    returnWindow: {
      label: 'Return Window',
      usedTitle: 'Used & Vintage Items - {{usedReturnWindowDays}} Days',
      usedDetails: 'Used & Vintage items may be returned within {{usedReturnWindowDays}} days of the delivery date.',
      newTitle: 'New Items - {{newReturnWindowDays}} days',
      newDetails: 'New items may be returned within {{newReturnWindowDays}} days of the delivery date.',
    },
    generalTerms: {
      label: 'General Terms',
      content: 'Items must be returned in original, as-shipped condition with all original packaging and no signs of use. Buyer assumes responsibility for all return shipping costs unless the item was not received as described.',
    },
    refund: {
      label: 'Refund',
      content: 'Buyer receives full refund in their original payment method less any shipping charges.',
    },
  },
  customReturnPolicy: {
    header: 'Custom Return Policy',
    restockingFeeHeader: 'Restocking fee %',
    returnWindow: {
      label: 'Return Window',
      formPrompt: 'How long after the date of delivery will you accept returns?',
      usedLabel: 'Used & Vintage Items',
      newLabel: 'New Items',
      note: 'Note: You can exclude items being sold "As-Described" from your shop return policy. You are still obligated to write accurate and detailed descriptions, provide good photos, and deliver these items to the buyer in the described condition.',
      days: {
        zero: 'No Returns',
        one: '{{count}} Day',
        other: '{{count}} Days',
      },
    },
    generalTerms: {
      label: 'General Terms',
      description: 'Items must be returned in original, as-shipped condition with all original packaging and no signs of use. Buyer assumes responsibility for all return shipping costs unless the item was not received as described.',
    },
    feesAndCharges: {
      label: 'Fees and Charges',
      description: 'Buyer receives full refund in their original payment method less any shipping charges.',
    },
    restockingFee: {
      formPrompt: 'I will also withhold a restocking fee',
      label: 'Restocking fee %',
    },
    specialConditions: {
      label: 'Special Conditions',
      optional: 'Optional',
    },
  },
};
