import I18n from 'i18n-js';
import React, { useEffect, useState } from 'react';

import CoreLink from '@reverbdotcom/commons/src/components/core_link';
import { inMobileApp } from '@reverbdotcom/commons/src/user_context_helpers';
import { RCLoadingBars } from '@reverbdotcom/cadence/components';
import { Redirect } from '@reverbdotcom/commons/src/routing_result';
import SiteModule from '@reverbdotcom/commons/src/components/site_module';
import { useNativeMessageListener, useSendNativeMessage } from '@reverbdotcom/commons/src/nativeMobileBridge';
import { useUser } from '@reverbdotcom/commons/src/user_hooks';
import { FormCards } from '@reverbdotcom/commons/src/components/FormCards';

import Location from '../../lib/wrapped_location';
import URLHelpers, { Paths } from '../../components/shared/url_helpers';

const LISTING_REVIEW_ARTICLE_ID = '4401822952083';

// Follows events/statuses used in backend reverb/app/reverb/listing/submit.rb.
export enum PublishStatus {
  LISTING_SUBMISSION_PUBLISHED = 'listing_submission_published',
  LISTING_NEEDS_BILLING_INFO = 'listing_needs_billing_info',
  LISTING_ALREADY_LIVE = 'listing_already_live',
  LISTING_ORDERED = 'listing_ordered',
  LISTING_FAILED_VALIDATION = 'listing_failed_validation',
  LISTING_SUSPENDED = 'listing_suspended',
  SELLER_VERIFICATION_REQUIRED = 'seller_verification_required',
  SHOP_UNDER_REVIEW = 'shop_under_review',
  LISTING_SUBMISSION_UNRECOVERABLE_ERROR = 'listing_submission_unrecoverable_error',
  PAYPAL_VERIFICATION_REQUIRED = 'paypal_verification_required',
}

interface IProps {
  publishStatus: PublishStatus;
  listingData: {
    id: string;
    isBumped: boolean;
    slug: string;
    title: string;
    primaryImageUrl: string;
  };
  errorMessages: string[];
}

// web -> native events that signal special actions needed by apps.
const NEEDS_BILLING_EVENT = PublishStatus.LISTING_NEEDS_BILLING_INFO;
const PUBLISH_SUCCEEDED_EVENT = PublishStatus.LISTING_SUBMISSION_PUBLISHED;

// native -> web event to signal that apps are done completing billing info.
const BILLING_INFO_COMPLETE_EVENT = 'billing_info_complete'; // we receive

export function SellFormPostPublish({ publishStatus, listingData, errorMessages }: IProps) {
  const [mobileBillingFailed, setMobileBillingFailed] = useState(false);
  const sendNativeMessage = useSendNativeMessage();
  const user = useUser();
  const inMobile = inMobileApp(user);

  const hasBackendValidationFailure = [
    PublishStatus.LISTING_ALREADY_LIVE,
    PublishStatus.LISTING_ORDERED,
    PublishStatus.LISTING_FAILED_VALIDATION,
    PublishStatus.LISTING_SUSPENDED,
    PublishStatus.SHOP_UNDER_REVIEW,
    PublishStatus.SELLER_VERIFICATION_REQUIRED,
    PublishStatus.LISTING_SUBMISSION_UNRECOVERABLE_ERROR,
    PublishStatus.PAYPAL_VERIFICATION_REQUIRED,
  ].includes(publishStatus);

  const showHelpCenterLink = [
    PublishStatus.LISTING_FAILED_VALIDATION,
    PublishStatus.LISTING_SUSPENDED,
    PublishStatus.SHOP_UNDER_REVIEW,
    PublishStatus.LISTING_SUBMISSION_UNRECOVERABLE_ERROR,
  ].includes(publishStatus);

  const showErrorUi = hasBackendValidationFailure || mobileBillingFailed;

  function handleNativeBillingComplete({ data: messageBusEvent = {} }: MessageEvent) {
    const { name, data = {} } = messageBusEvent;

    if (name !== BILLING_INFO_COMPLETE_EVENT) { return; }

    if (data.success) {
      Location.reload(); // Reloading the page after mobile billing success will re-submit listing for publish.
    } else {
      setMobileBillingFailed(true);
    }
  }
  useNativeMessageListener(handleNativeBillingComplete);

  useEffect(() => {
    if (!inMobile) { return; }

    if (publishStatus === PublishStatus.LISTING_NEEDS_BILLING_INFO) {
      sendNativeMessage({ eventName: NEEDS_BILLING_EVENT, data: { success: true } });
    } else if (publishStatus === PublishStatus.LISTING_SUBMISSION_PUBLISHED) {
      sendNativeMessage({
        eventName: PUBLISH_SUCCEEDED_EVENT,
        data: { success: true, listing: listingData },
      });
    }
  }, [inMobile, sendNativeMessage, publishStatus, listingData]);

  if (publishStatus === PublishStatus.SELLER_VERIFICATION_REQUIRED) {
    return (
      <Redirect
        url={Paths.sellerVerificationOnboarding.expand({
          query: {
            listing_id: listingData.id,
            react_sell_form_publish: true,
          },
        })}
        fullPageReload
      />
    );
  }

  if (!inMobile && publishStatus === PublishStatus.LISTING_NEEDS_BILLING_INFO) {
    const redirectBack = Paths.listingSubmit.expand({
      query: {
        id: listingData.id,
        react_sell_form_publish: true,
      },
    });

    return (
      <Redirect
        url={URLHelpers.mySellingStatementsPath({ credit_card_needed: true, redirect_to: redirectBack })}
        fullPageReload
      />
    );
  }

  if (!inMobile && publishStatus === PublishStatus.LISTING_SUBMISSION_PUBLISHED) {
    return <Redirect url={Paths.item.expand({ id: listingData.id, slug: `-${listingData.slug}`, publish_confirmation: true })} fullPageReload />;
  }

  return (
    <FormCards.PageWrapper>
      {!showErrorUi && (
        <div className="d-flex fx-align-center fx-justify-center pt-10">
          <RCLoadingBars />
        </div>
      )}

      {showErrorUi && (
        <div className="site-wrapper ptb-8 mobile-pt-4 mobile-pb-0">
          <div className="g-container">
            <div className="g-col-8 g-offset-2 g-col-mobile-12">
              <SiteModule>
                <div className="ptb-4 plr-4">
                  <h1 className="mb-4 size-fixed-150 weight-bold">
                    {I18n.t('discovery.sellForm.publishPage.header')}
                  </h1>
                  <p>
                    {I18n.t('discovery.sellForm.publishPage.errorWarning')}
                  </p>
                  <ul className="cms-ul">
                    {mobileBillingFailed && (
                      <li>
                        {I18n.t('discovery.sellForm.publishPage.creditCardNeeded')}
                      </li>
                    )}

                    {errorMessages.map((msg) => (
                      <li key={msg}>
                        {msg}
                      </li>
                    ))}
                  </ul>

                  <div>
                    <h3 className="mb-4 size-fixed-100 weight-bold">
                      {I18n.t('discovery.sellForm.publishPage.helpfulLinks')}
                    </h3>
                    <ul className="cms-ul">
                      {publishStatus === PublishStatus.SHOP_UNDER_REVIEW && (
                        <li>
                          <CoreLink to={Paths.helpCenterArticlesUrl.expand({ articleId: LISTING_REVIEW_ARTICLE_ID })}>
                            {I18n.t('discovery.sellForm.publishPage.aboutListingReview')}
                          </CoreLink>
                        </li>
                      )}

                      {mobileBillingFailed && (
                        <li>
                          <button
                            className="button-as-link"
                            onClick={() => { Location.reload(); }}
                            type="button"
                          >
                            {I18n.t('discovery.sellForm.publishPage.reEnterBilling')}
                          </button>
                        </li>
                      )}

                      {publishStatus === PublishStatus.PAYPAL_VERIFICATION_REQUIRED && (
                        <li>
                          <CoreLink to={URLHelpers.shopPoliciesEditPath}>
                            {I18n.t('discovery.sellForm.publishPage.shopSettings')}
                          </CoreLink>
                        </li>
                      )}

                      <li>
                        <CoreLink to={URLHelpers.sellFormEditListingPath(listingData.id, { section: 'review' })}>
                          {I18n.t('discovery.sellForm.publishPage.backToSellForm')}
                        </CoreLink>
                      </li>

                      {!inMobile && (
                        <li>
                          <CoreLink to={URLHelpers.listingsManagerPath()}>
                            {I18n.t('discovery.sellForm.publishPage.sellerDashboard')}
                          </CoreLink>
                        </li>
                      )}

                      {showHelpCenterLink && (
                        <li>
                          <CoreLink to={Paths.helpCenterNewRequest.expand({})}>
                            {I18n.t('discovery.sellForm.publishPage.contactSupport')}
                          </CoreLink>
                        </li>
                      )}
                    </ul>
                  </div>
                </div>
              </SiteModule>
            </div>
          </div>
        </div>
      )}
    </FormCards.PageWrapper>
  );
}

export default SellFormPostPublish;
