export interface WrappedLocation {
  assign(url: string): void;
  replace(url: string): void;
  reload(): void;
  href: string;
  origin: string;
  pathname: string;
  hash: string;
  search: string;
}

const location: WrappedLocation = {
  assign(url: string) {
    window.location.assign(url);
  },

  replace(url: string) {
    window.location.replace(url);
  },

  reload() {
    window.location.reload();
  },

  get href() {
    return window.location.href;
  },

  get pathname() {
    return window.location.pathname;
  },

  get hash() {
    return window.location.hash;
  },

  get origin() {
    return window.location.origin;
  },

  get search() {
    return window.location.search;
  },
};

export default location;
