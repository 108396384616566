import I18n from 'i18n-js';
import { compact, filter, find, flatten, includes, map, union } from 'lodash';
import React from 'react';

import bind from '../bind';
import { EVERYWHERE_CODE } from '../constants';

import FormGroupWithSelect, { ISelectOptionGroup } from '../components/form_group_with_select';

export interface ILegacyApiShippingRegion {
  children: ILegacyApiShippingRegion[];
  code: string;
  name: string;
  region_type: string;
}

export interface IShippingRegion {
  regionCode?: string;
  regionName?: string;
  regionType?: string;
}

interface IProps {
  value?: string;
  shippingRegions: ILegacyApiShippingRegion[];
  onSelect: Function;
  disabledShippingRegionCodes: string[];
  disabled?: boolean;
}

const TOP_REGION_CODES = Object.freeze([
  'US',
  'CA',
  'GB',
  'AU',
  'FR',
  'DE',
  'JP',
  'RU',
  'ID',
]);

export function nameForRegion(region: ILegacyApiShippingRegion) {
  if (region.code === EVERYWHERE_CODE) return I18n.t('commons.shippingRegionSelector.everywhereElse');

  return region.name;
}

export class ShippingRegionSelector extends React.Component<IProps, {}> {
  @bind
  updateField({ regionCode }) {
    const selectedRegion = this.allShippingRegions.find(({ code }) => code === regionCode);

    if (!selectedRegion) { return; }

    const formattedRegion: IShippingRegion = {
      regionCode: selectedRegion.code,
      regionName: selectedRegion.name,
      regionType: selectedRegion.region_type,
    };

    this.props.onSelect(formattedRegion);
  }

  private get allShippingRegions() {
    return union(
      [this.everywhereElse],
      this.topRegions,
      this.superRegions,
      ...this.subRegionGroups,
      this.countries,
    );
  }

  // XX
  private get everywhereElse() {
    return find(this.props.shippingRegions, { code: EVERYWHERE_CODE });
  }

  // Africa, North America, Europe, etc.
  private get superRegions(): ILegacyApiShippingRegion[] {
    return compact(filter(this.props.shippingRegions, region => region.code !== EVERYWHERE_CODE));
  }

  // US, CA, MX, etc.
  private get countries(): ILegacyApiShippingRegion[] {
    return compact(flatten<ILegacyApiShippingRegion>(map<ILegacyApiShippingRegion>(this.superRegions, 'children')));
  }

  // Continental US, Alaska, Hawaii, etc.
  private get subRegionGroups(): ILegacyApiShippingRegion[][] {
    return compact(map<ILegacyApiShippingRegion>(filter(this.countries, 'children.length'), 'children'));
  }

  private get topRegions(): ILegacyApiShippingRegion[] {
    return compact(TOP_REGION_CODES.map(regionCode => find(this.countries, { code: regionCode })));
  }

  private generateOptGroup(key: string, shippingRegions: ILegacyApiShippingRegion[]): ISelectOptionGroup {
    return {
      key,
      options: compact(shippingRegions).map((region) => {
        const disabled = includes(this.props.disabledShippingRegionCodes, region.code);
        return {
          disabled,
          text: nameForRegion(region),
          value: region.code,
        };
      }),
    };
  }

  render() {
    if (!this.props.shippingRegions) return null;

    return (
      <FormGroupWithSelect
        inputName="regionCode"
        label={I18n.t('commons.shippingRegionSelector.label')}
        value={this.props.value || ''}
        optionGroups={[
          this.generateOptGroup('everywhere-else', [this.everywhereElse]),
          this.generateOptGroup('top-regions', this.topRegions),
          this.generateOptGroup('super-regions', this.superRegions),
          ...this.subRegionGroups.map((subRegionGroup, index) => {
            return this.generateOptGroup(`sub-region-${index}`, subRegionGroup);
          }),
          this.generateOptGroup('countries', this.countries),
        ]}
        updateField={this.updateField}
        disabled={this.props.disabled}
        emptyOptionText={I18n.t('commons.shippingRegionSelector.emptyOptionText')}
        addEmptyOption
      />
    );
  }
}

export default ShippingRegionSelector;
