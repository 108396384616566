import { ChildProps } from '@apollo/client/react/hoc';
// TODO update this to import from commons/src/gql
// eslint-disable-next-line no-restricted-imports
import { gql } from '@apollo/client';
import React from 'react';
import { get, uniq } from 'lodash';
import classNames from 'classnames';

import { MParticleEventName } from '../../elog/mparticle_tracker';
import bind from '../../bind';
import { CommonsCmsCategoryCollection } from '../../gql/graphql';
import OverflowingRow from '../../components/overflowing_row';
import Tiles from '../../components/tiles';
import CoreLink from '../../components/core_link';
import SanitizedRender from '../../components/sanitized_render';

import { IDynamicComponentProps } from '../dynamic_component_props';
import { withGraphql } from '../../with_graphql';

const COMPONENT_NAME = 'CategoryCollection';
export interface CmsCategoryFields {
  uuid: string;
  imageOverride?: string;
  linkOverride?: string;
}
export interface IProps extends IDynamicComponentProps {
  categoryUUIDs?: string[];
  linkOverride?: string;
  title?: string;
  titleHtml?: string;
  subtitleHtml?: string;
  ledeHtml?: string;
  jumplinkSlug?: string;
  displayStyle?: string;
  categoryFields?: CmsCategoryFields[];
  centerHeading?: boolean;
}

export class CategoryCollection extends React.Component<ChildProps<IProps, CommonsCmsCategoryCollection.Query>, null> {
  clickEvent(cat) {
    const subcategories = uniq([cat.rootSlug, cat.slug]);
    return {
      componentName: COMPONENT_NAME,
      eventName: MParticleEventName.ClickedCategory,
      displayStyle: this.props.displayStyle || '',
      category: cat.rootSlug,
      subcategories: subcategories.join('|'),
      targetUrl: this.getLink(cat),
    };
  }

  getCategoryField(category) {
    const idx = this.props.categoryFields.findIndex(p => p.uuid === category.id);
    return this.props.categoryFields[idx];
  }

  getLink(cat) {
    if (this.getCategoryField(cat).linkOverride) return this.getCategoryField(cat).linkOverride;

    return cat.cmsLink.href;
  }

  getImageOverride(category) {
    return this.getCategoryField(category).imageOverride;
  }

  isEmpty() {
    return !this.props.data.loading && !get(this.props.data.categories, 'length');
  }

  isLoading() {
    return this.props.data.loading;
  }

  getBackgroundImageStyle(cat) {
    const src = this.getImageOverride(cat) || get(cat.images[0], 'source');
    if (!src) return null;

    return { backgroundImage: `url(${src})` };
  }

  displayAsRoundedSquare() {
    return this.props.displayStyle === 'rounded square';
  }

  @bind
  renderTile(cat, idx) {
    const classes = classNames(
      'category-collection__item',
      { 'category-collection__item--rounded-square': this.displayAsRoundedSquare() },
    );

    return (
      <CoreLink
        to={this.getLink(cat)}
        className={classes}
        key={idx}
        clickEvent={this.clickEvent(cat)}
      >
        <div className="category-collection__item__image"
          style={this.getBackgroundImageStyle(cat)}
        />
        <div className="category-collection__item__inner">
          <div className="category-collection__item__text">
            {cat.name}
          </div>
        </div>
      </CoreLink>
    );
  }

  renderPlaceholder() {
    const classes = classNames(
      'category-collection__item',
      'category-collection__item--placeholder',
      { 'category-collection__item--rounded_square': this.displayAsRoundedSquare() },
    );

    return (
      <div className={classes}>
        <div className="category-collection__item__image" />
        <div className="category-collection__item__inner">
          <div className="category-collection__item__text" />
        </div>
      </div>
    );
  }

  renderTitle() {
    return (
      <SanitizedRender
        html={this.props.titleHtml || this.props.title}
      />
    );
  }

  render() {
    if (this.isEmpty()) return null;

    const { categories } = this.props.data;
    const categoryCount = this.props.categoryFields.map(c => c.uuid).length;
    return (
      <OverflowingRow
        collectionCount={categoryCount}
        title={this.renderTitle()}
        subtitleHtml={this.props.subtitleHtml}
        ledeHtml={this.props.ledeHtml}
        id={this.props.jumplinkSlug}
        centeredTitle={this.props.centerHeading}
      >
        <Tiles
          largeTiles
          loading={this.isLoading()}
          placeholder={this.renderPlaceholder()}
          expectedCount={categoryCount}
          singleRow={!this.displayAsRoundedSquare()}
        >
          {categories && categories.map(this.renderTile)}
        </Tiles>
      </OverflowingRow>
    );
  }
}

const connect = withGraphql<IProps, CommonsCmsCategoryCollection.Query, CommonsCmsCategoryCollection.Variables>(
  gql`
  query Commons_Cms_CategoryCollection(
    $categoryUuids: [String]
  ) {
    categories(input:{
      ids: $categoryUuids
    }) {
      _id
      id
      name
      slug
      rootSlug
      cmsLink {
        href
      }
      images(input:{
        type:"Category",
        count:1,
        transform:"card_wide",
        scope:"full_bleed_brand"
      }) {
        _id
        source
      }
    }
  }
  `,
  {
    options: (ownProps) => {
      return {
        ssr: ownProps.ssr,
        variables: {
          categoryUuids: ownProps.categoryFields.map(c => c.uuid),
        },
      };
    },
  },
);

export default connect(CategoryCollection);
