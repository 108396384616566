// TODO update this to import from commons
// import { gql } from '@reverbdotcom/commons/src/gql';
// eslint-disable-next-line no-restricted-imports
import { gql } from '@apollo/client';
import React from 'react';
import I18n from 'i18n-js';
import { withGraphql } from '@reverbdotcom/commons/src/with_graphql';
import { Paths } from './shared/url_helpers';
import {
  CoreCheckoutDeleteCreditCard,
  CreditCardDeleteButtonFieldsFragment,
  core_apimessages_CheckoutPaymentMethod_Type as PaymentMethodType,
} from '@reverbdotcom/commons/src/gql/graphql';
import { useHOCMutation, MutationFunction } from '@reverbdotcom/commons/src/useHOCMutation';
import { RCButton, RCAlertBox } from '@reverbdotcom/cadence/components';
import { InjectedRouter, withRouter, WithRouterProps } from 'react-router';
import { CreditCardWalletClickInteraction } from '@reverbdotcom/commons/src/elog/mparticle_types';
import { useTrackCheckoutClickEvent } from './checkout/checkoutTracking';
import { useHelpCenterArticleUrl } from '@reverbdotcom/commons/src/url_helpers';
import { connect } from '@reverbdotcom/commons/src/connect';

type CreditCard = CreditCardDeleteButtonFieldsFragment['creditCards'][0];

interface MutationProps {
  deleteCheckoutCreditCard: DeleteCheckoutCreditCardMutationFn;
}

interface ExternalProps {
  creditCard: CreditCard;
}

type Props = ExternalProps & MutationProps & WithRouterProps;

type DeleteCheckoutCreditCardMutationFn = MutationFunction<CoreCheckoutDeleteCreditCard.Mutation, CoreCheckoutDeleteCreditCard.Variables>;

const CREDIT_CARD_TROUBLESHOOTING_TIPS_ARTICLE_ID = '1500005385482';

export function CreditCardDeleteButton(props: Props) {
  const selectedCardId = props.creditCard.id;
  const checkoutId = props.params.id;

  const { onDelete, loading, errors } = useDeleteCardCallback(
    checkoutId,
    selectedCardId,
    props.router,
    props.deleteCheckoutCreditCard,
  );

  const troubleshootingUrl = useHelpCenterArticleUrl(CREDIT_CARD_TROUBLESHOOTING_TIPS_ARTICLE_ID);

  return (
    <div className="mt-4">
      {errors.length > 0 && (
        <div className="mb-4">
          <RCAlertBox type="error">
            {errors.map(e => <li key={`${e.field}-${e.message}`}>{e.message}</li>)}
            <div className="mt-2">
              <a href={troubleshootingUrl}>
                {I18n.t('discovery.creditCardWallet.cardTroubleshooting')}
              </a>
            </div>
          </RCAlertBox>
        </div>
      )}
      <RCButton
        variant="filled"
        theme="danger"
        fullWidth
        disabled={loading}
        onClick={onDelete}
      >
        {loading ? translate('loading') : translate('default')}
      </RCButton>
    </div>
  );
}

function useDeleteCardCallback(
  checkoutId: string,
  selectedCardId: string,
  router: InjectedRouter,
  deleteCheckoutCreditCard: DeleteCheckoutCreditCardMutationFn,
) {
  const [mutate, { loading, errors }] = useHOCMutation(deleteCheckoutCreditCard);

  const checkoutClickEvent = useTrackCheckoutClickEvent({
    id: checkoutId,
    paymentMethod: {
      type: PaymentMethodType.DIRECT_CHECKOUT,
    },
  });

  async function onDelete() {
    const result = await mutate({
      variables: {
        input: {
          id: selectedCardId,
        },
      },
      refetchQueries: ['Core_CreditCardWallet_Index', 'Core_Checkout_Layout'],
      awaitRefetchQueries: true,
    });

    if (result) {
      const creditCardWalletIndexPath = Paths.checkoutCreditCardWallet.expand({
        checkoutId,
      });
      checkoutClickEvent(CreditCardWalletClickInteraction.DeleteCard);
      router.push(creditCardWalletIndexPath);
    }
  }

  return { onDelete, loading, errors };
}

function translate(key: string) {
  return I18n.t(`discovery.checkout.creditCardDeleteButton.${key}`);
}

export const CreditCardDeleteButtonFragment = gql`
  fragment CreditCardDeleteButtonFields on rql_Me {
    _id
    creditCards {
      id
    }
  }
`;

export const DELETE_CREDIT_CARD_MUTATION = gql`
  mutation Core_Checkout_DeleteCreditCard(
    $input: Input_core_apimessages_DeleteMyCreditCardRequest
  ) {
    deleteMyCreditCard(input: $input) {
      id
    }
  }
`;

const withMutation = withGraphql<Props, CoreCheckoutDeleteCreditCard.Mutation, CoreCheckoutDeleteCreditCard.Variables>(
  DELETE_CREDIT_CARD_MUTATION,
  {
    name: 'deleteCheckoutCreditCard',
  },
);

export default connect<ExternalProps>([
  withMutation,
  withRouter,
])(CreditCardDeleteButton);
