import React from 'react';
import I18n from 'i18n-js';
import { RCAlertBox } from '@reverbdotcom/cadence/components';

export function NoTaxFormsToDisplay() {
  const noTaxFormsToDisplayMessage = () => {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const prevYear = currentYear - 1;
    const shouldShowComingSoon = (currentDate.getMonth() === 0 && currentDate.getDate() < 31) || currentYear < 2023;

    return shouldShowComingSoon ?
      I18n.t('discovery.taxProfile.taxForms.comingSoon', { currYear: currentYear.toString(), prevYear: prevYear.toString() }) :
      I18n.t('discovery.taxProfile.taxForms.noFormsAvailable', { prevYear: prevYear.toString() });
  };

  return (
    <RCAlertBox
      type="plain"
    >
      <span>{ noTaxFormsToDisplayMessage() }</span>
    </RCAlertBox>
  );
}

export default NoTaxFormsToDisplay;
