export default {
  deleteAccount: 'Delete Account',
  poorExperienceHtml: "If you've had a poor experience on Reverb, please {{children}} so we can do everything possible to ensure it gets straightened out.",
  reachOutLinkText: 'reach out to us',
  aboutAD: 'About account deletions',
  bulletPoints: {
    permanent: 'Account deletions are permanent. Your account cannot be reinstated.',
    history: 'Your previous payout history and Reverb Preferred Seller progress will not carry over if you create a new account.',
    activeSales: 'If there are any active sales, purchases, refund requests, or any outstanding matters within your account (such as unpaid bills and outstanding statements), those will need to be resolved prior to Reverb closing the account.',
  },
  selectReason: 'Before you can delete your account, please select the reason that led to this decision.',
  reasons: {
    label: 'Reasons',
    emptyLabel: 'Select a reason',
    noLongerWantAccount: 'I no longer need to buy or sell on Reverb',
    badUserExperience: 'I had a poor experience',
    fraud: 'This account was created or accessed without my permission',
    other: 'Other',
  },
  deleteMyAccount: 'Delete my account',
  submittedRequest: 'The request to delete your account has been submitted.',
  complyEU: "Reverb is intent on complying with the European Union's General Data Protection Regulation (GDPR), as well as any state privacy statutes. Requests for account deletion in compliance with these policies will be handled within the allotted timeframe. {{children}}",
  learnMoreLink: 'Learn more about these policies.',
  retain: "Depending on your account activity (such as whether you've made purchases or sales on Reverb), we may be required to retain certain information for legal, regulatory, tax, security, integrity, or compliance reasons for a limited period of time, after which it will be deleted.",
  needMoreHelp: 'Need more help?',
  areYouSure: 'Are you sure you want to delete your account?',
  note: 'Please note: This action is permanent and cannot be undone.',
};
