import React, { useCallback, useEffect } from 'react';
import {
  CoreExchangeRates,
} from '../gql/graphql';
import { parseAmountCents } from '@reverbdotcom/commons/src/money';
import { useQuery } from '@reverbdotcom/commons/src/useQuery';
import { CURRENCY_DESCRIPTION } from '@reverbdotcom/commons/src/currency_descriptions';
import { RCTextInput } from '@reverbdotcom/cadence/components';
import { debounce } from 'lodash';
import { convertCurrency, EXCHANGE_RATES_QUERY } from '@reverbdotcom/commons/src/convert_currency';

const CURRENCY_PATTERN = '[0-9.,-\s]*';
export const ADMIN_CURRENCY_USD = 'USD';

interface IProps {
  errorText?: string;
  id: string;
  label: string;
  name: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  required?: boolean;
  setUsdEstimate: (value: string) => void;
  usdEstimate: string;
  userCurrency: string;
  value: string;
}

export function AdminAccommodationAmountInputWithCurrencyConversion({
  errorText = '',
  id,
  label,
  name,
  onChange,
  required = true,
  setUsdEstimate,
  usdEstimate,
  userCurrency,
  value,
}: IProps) {

  const { data } = useQuery<CoreExchangeRates.Query>(
    EXCHANGE_RATES_QUERY,
  );

  const handleUsdEstimateChange = (estimate) => {
    setUsdEstimate(parseAmountCents(estimate.amountCents, ADMIN_CURRENCY_USD).amount);
  };

  const getUsdEstimate = useCallback(debounce((amount) => {
    if (!amount) {
      return;
    }
    const convertedAmount = convertCurrency(
      userCurrency,
      ADMIN_CURRENCY_USD,
      data.exchangeRates?.rates || [],
      amount,
    );

    handleUsdEstimateChange(convertedAmount);
  }, 500), [ data ]);

  useEffect(() => {
    if (userCurrency !== ADMIN_CURRENCY_USD) {
      getUsdEstimate(value);
    }
  }, [ value, getUsdEstimate ]);

  return (
    <RCTextInput
      errorText={errorText}
      helpText={userCurrency === ADMIN_CURRENCY_USD ? '' : `USD Estimate: $${usdEstimate}`}
      id={id}
      label={label}
      name={name}
      onChange={onChange}
      pattern={CURRENCY_PATTERN}
      prefixText={CURRENCY_DESCRIPTION[userCurrency]?.symbol}
      required={required}
      suffixText={userCurrency}
      tag={required ? 'required' : 'none'}
      value={value}
    />
  );
}

export default AdminAccommodationAmountInputWithCurrencyConversion;

