import React from 'react';
import { Route } from 'react-router';
import TrackPurchase from '@reverbdotcom/commons/src/components/track_purchase';
import CreditCardWalletModal from '../components/credit_card_wallet_modal';
import { IndexRoute } from '@reverbdotcom/commons/src/route_settings';
import CreditCardWalletIndex from '../components/credit_card_wallet_index';
import CreditCardWalletCreateFormLayout from '../components/credit_card_wallet_create_form_layout';
import CreditCardWalletEditFormLayout from '../components/credit_card_wallet_edit_form_layout';

const CHECKOUT_CONFIRMATION_URI = 'multi-checkout/:order_bundle_id/confirmation';
const CART_NO_OP_URI = 'cart/*';

export const CheckoutRoutes = [
  <Route
    key="cart"
    path="cart"
    getComponent={(_location, cb) => {
      import(
        /* webpackChunkName: 'checkout' */
        '../components/cart/CartLayout'
      ).then(m => cb(null, m.CartLayout));
    }}
  />,
  <Route
    key="checkout/:id/redirect-return"
    path="checkout/:id/redirect-return"
    getComponent={(_location, cb) => {
      import(
        /* webpackChunkName: 'checkout' */
        '../components/checkout/CheckoutRedirectReturnLayout'
      ).then(m => cb(null, m.default));
    }}
  />,
  <Route
    key="checkout/:id/payment-in-progress"
    path="checkout/:id/payment-in-progress"
    getComponent={(_location, cb) => {
      import(
        /* webpackChunkName: 'checkout' */
        '../components/checkout/CheckoutPaymentInProgressLayout'
      ).then(m => cb(null, m.default));
    }}
  />,
  <Route
    key="checkout/:id"
    path="checkout/:id"
    props={{ clientSide: true }}
    getComponent={(_location, cb) => {
      import(
        /* webpackChunkName: 'checkout' */
        '../components/checkout/CheckoutLayout'
      ).then(m => cb(null, m.CheckoutLayout));
    }}
  >
    <Route
      key="checkout/:id/cards"
      path="cards"
      component={CreditCardWalletModal}
      props={{ clientSide: true }}
    >
      <IndexRoute
        key="checkout/:id/cards"
        component={CreditCardWalletIndex}
        props={{ clientSide: true }}
      />
      <Route
        key="checkout/:id/cards/new"
        path="new"
        component={CreditCardWalletCreateFormLayout}
        props={{ clientSide: true }}
      />
      <Route
        key="checkout/:id/cards/:creditCardId/edit"
        path=":creditCardId/edit"
        component={CreditCardWalletEditFormLayout}
        props={{ clientSide: true }}
      />
    </Route>
  </Route>,
  <Route
    key="checkout/:id/authorization"
    path="checkout/:id/authorization"
    getComponent={(_location, cb) => {
      import(
        /* webpackChunkName: 'checkout' */
        '../components/checkout/CheckoutAuthorizationLayout'
      ).then(m => cb(null, m.default));
    }}
  />,
];

export const CheckoutAnalyticsRoutes = [
  <Route
    key={CHECKOUT_CONFIRMATION_URI} // also matches view shipping route so needs to come first
    path={CHECKOUT_CONFIRMATION_URI}
    getComponent={(_location, cb) => {
      cb(null, TrackPurchase);
    }}
  />,
  <Route
    key={CART_NO_OP_URI}
    path={CART_NO_OP_URI}
  />,
];
