import React from 'react';
import I18n from 'i18n-js';
import URLHelpers from '../shared/url_helpers';
import SanitizedRender from '@reverbdotcom/commons/src/components/sanitized_render';

export function BumpDescription() {
  const bumpPage = URLHelpers.cmsPagePath('bump');

  return (
    <div className="size-90">
      <p className="mb-1 weight-bold">
        {I18n.t('discovery.bumpModalV2.copyV2.howItWorks')}
      </p>
      <p>
        {I18n.t('discovery.bumpModalV2.copyV2.descriptionParagraph1')}
      </p>
      <p className="mb-1">
        {I18n.t('discovery.bumpModalV2.copyV2.descriptionParagraph2')}
      </p>
      <ul className="cms-ul">
        <li>
          {I18n.t('discovery.bumpModalV2.copyV2.bumpLocationBullet1')}
        </li>
        <li>
          {I18n.t('discovery.bumpModalV2.copyV2.bumpLocationBullet2')}
        </li>
        <li>
          {I18n.t('discovery.bumpModalV2.copyV2.bumpLocationBullet3')}
        </li>
      </ul>

      <p className="mb-0">
        <SanitizedRender
          html={
            I18n.t('discovery.bumpModalV2.copyV2.payIfSells', {
              linkOpen: `<a class="icon-r-external-link" target="_blank" href="${bumpPage}">`,
              linkClose: '</a>',
            })
          }
        />
      </p>
    </div>
  );
}
