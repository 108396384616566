import { Location } from 'history';

import {
  isFavoritesPage,
  isMarketplacePage,
  isItemPage,
  isShopPage,
  isCSP,
  isFavoriteSearchesPage,
  isFavoriteShopsPage,
  isFavoriteItemsPage,
  isFavoriteEndedItemsPage,
  isFavoritesHubFeedPage,
  isFavoritesHubWatchListPage,
  isOutletHub,
} from '../url_helpers';
import { IUrl } from '../url_context';
import {
  ClickedAddFavoriteSearch,
  ClickedRemoveFavoriteSearch,
  ClickedRemoveFavoriteShop,
  MParticleEvent,
  MParticleEventName,
  PageViewEvent,
  MParticlePageName,
} from '../elog/mparticle_types';
import { Core_FindFavoriteQuery, FavoriteCTADataFragment } from '../gql/graphql';

type Core_FindFavoriteQuery_Favorite = Core_FindFavoriteQuery['findFavorite']['favorite'];
type FavoriteCTADataFragment_Favorite = FavoriteCTADataFragment['favorite'];

export const FAVORITES_HUB = 'favorites_hub';
export const FavoritesHubPane = {
  LiveWatches: 'Watch - Live',
  EndedWatches: 'Watch - Ended',
  Searches: 'Searches',
  Shops: 'Shops',
  Feed: 'Feed',
} as const;
export type FavoritesHubPaneName = typeof FavoritesHubPane[keyof typeof FavoritesHubPane];

export function trackingSort(location: Location) {
  switch (location.query.sort) {
    case 'price|asc':
      return 'Price Low to High';
    case 'price|desc':
      return 'Price High to Low';
    default:
      return 'Most Recent';
  }
}

export function resolveCurrentView(location: Location) {
  if (isFavoritesPage(location)) {
    return 'favorites_hub';
  } else if (isMarketplacePage(location)) {
    return 'search';
  } else if (isItemPage(location)) {
    return 'listing';
  } else if (isShopPage(location)) {
    return 'shop';
  } else if (isCSP(location)) {
    return 'csp';
  } else if (isOutletHub(location)) {
    return 'outlet';
  } else {
    // default tracking value for any other page
    return 'etc';
  }
}

export function resolveCurrentPane(location: Location): FavoritesHubPaneName | null {
  if (isFavoriteSearchesPage(location)) {
    return FavoritesHubPane.Searches;
  } else if (isFavoriteShopsPage(location)) {
    return FavoritesHubPane.Shops;
  } else if (isFavoriteItemsPage(location)) {
    return FavoritesHubPane.LiveWatches;
  } else if (isFavoriteEndedItemsPage(location)) {
    return FavoritesHubPane.EndedWatches;
  } else if (isFavoritesHubFeedPage(location)) {
    return FavoritesHubPane.Feed;
  } else if (isFavoritesHubWatchListPage(location)) {
    return FavoritesHubPane.LiveWatches;
  } else {
    return null;
  }
}

export function resolveFavoriteType(location: Location) {
  if (isMarketplacePage(location) || isFavoriteSearchesPage(location)) {
    return 'Search';
  } else if (isItemPage(location) || isFavoriteItemsPage(location) || isFavoriteEndedItemsPage(location)) {
    return 'Item';
  } else if (isShopPage(location) || isFavoriteShopsPage(location)) {
    return 'Shop';
  } else if (isCSP(location)) {
    return 'CSP';
  } else {
    return null;
  }
}

export function buildClickedToastMessage(url: IUrl): MParticleEvent {
  return {
    eventName: MParticleEventName.ClickedToastMessage,
    currentView: resolveCurrentView(url),
    favoriteType: resolveFavoriteType(url),
  };
}

export function buildClickedAddFavoriteSearch(
  favorite: Core_FindFavoriteQuery_Favorite,
  url: IUrl,
  addToFeedStatus: boolean,
  dailyFeedEmailStatus: boolean,
  componentName?: string,
): ClickedAddFavoriteSearch {
  return {
    eventName: MParticleEventName.ClickedAddFavoriteSearch,
    currentView: resolveCurrentView(url),
    searchQuery: favorite.title,
    searchUrl: favorite.link?.href,
    componentName,
    addToFeedStatus,
    dailyFeedEmailStatus,
  };
}

export function buildClickedRemoveFavorite(
  favorite: FavoriteCTADataFragment_Favorite,
  url: IUrl,
): ClickedRemoveFavoriteSearch | ClickedRemoveFavoriteShop {
  if (isFavoriteShopsPage(url)) {
    return buildClickedRemoveFavoriteShop(favorite);
  } else {
    return buildClickedRemoveFavoriteSearch(favorite, url);
  }
}

export function buildClickedRemoveFavoriteSearch(
  favorite: FavoriteCTADataFragment_Favorite | Core_FindFavoriteQuery_Favorite,
  url: IUrl,
): ClickedRemoveFavoriteSearch {
  return {
    eventName: MParticleEventName.ClickedRemoveFavoriteSearch,
    currentView: resolveCurrentView(url),
    searchQuery: favorite.title,
    searchUrl: favorite.link?.href,
  };
}

function buildClickedRemoveFavoriteShop(
  favorite: FavoriteCTADataFragment_Favorite,
): ClickedRemoveFavoriteShop {
  return {
    eventName: MParticleEventName.ClickedRemoveFavoriteShop,
    shopId: favorite.searchableId,
    currentView: FAVORITES_HUB,
  };
}

export function buildFavoritesHubPageViewEvent(
  location: Location,
): PageViewEvent {
  const event: PageViewEvent = {
    pageName: MParticlePageName.ViewedFavoritesHubPage,
    currentView: FAVORITES_HUB,
    currentPane: resolveCurrentPane(location),
  };

  if (isFavoriteItemsPage(location) || isFavoriteEndedItemsPage(location)) {
    event.sort = trackingSort(location);
  }

  return event;
}
