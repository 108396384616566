import React from 'react';
import classNames from 'classnames';
import I18n from 'i18n-js';

import bind from '../bind';
import { RCCloseButton } from '@reverbdotcom/cadence/components';

interface IProps {
  value?: string;
  onChange: (evt: { target: { value: string } }) => void;
  onSubmit?: React.FormEventHandler<null>;
  onClear?: () => void;
  placeholder?: string;
  large?: boolean;
  action?: string;
  name?: string;
  id?: string;
  rounded?: boolean;
}

export default class SearchInputGroup extends React.Component<IProps, any> {
  private textInput: HTMLInputElement;

  @bind
  clearInputValue(): void {
    if (this.textInput) this.textInput.focus();
    this.props.onChange({ target: { value: '' } });
    if (this.props.onClear) this.props.onClear();
  }

  @bind
  bindInputRef(node) {
    if (!node) { return; }
    this.textInput = node;
  }

  render() {
    const classes = classNames(
      'search-input-group',
      { 'search-input-group--large': this.props.large },
      { 'search-input-group--rounded': this.props.rounded },
    );

    return (
      <div className={classes}>
        <form
          onSubmit={this.props.onSubmit}
          action={this.props.action}
        >
          <div className="search-input-group__inner">
            <div
              className="search-input-group__field"
            >
              <input
                className="search-input-group__input"
                type="text"
                value={this.props.value}
                onChange={this.props.onChange}
                placeholder={this.props.placeholder}
                ref={this.bindInputRef}
                autoComplete="off"
                autoCorrect="off"
                autoCapitalize="off"
                spellCheck={false}
                id={this.props.id}
                name={this.props.name}
              />
              {this.props.value && (
                <span className="search-input-group__clear">
                  <RCCloseButton
                    aria-label={I18n.t('commons.forms.clearLabel')}
                    onClick={this.clearInputValue}
                  />
                </span>
              )}
            </div>
            <button
              className="search-input-group__button"
              type="submit"
              aria-label={I18n.t('commons.forms.submitLabel')}
            />
          </div>
        </form>
      </div>
    );
  }
}
