import React from 'react';
import { formatInternationalizedDate } from '../../lib/date_utils';
import { Input_core_apimessages_AdyenBalancePlatformNotification as Notification } from '@reverbdotcom/commons/src/gql/graphql';
interface RowProps {
  notification: Notification;
}

export default function AdyenBalanceNotificationRow({
  notification,
}: RowProps) {
  return (
    <tr>
      <td className="width-20">
        {notification.id}
      </td>
      <td className="width-20">
        <div>
          { formatInternationalizedDate(notification.createdAt, 'L h:mma') }
        </div>
      </td>
      <td className="width-60">
        <pre>
          {notification.rawBody}
        </pre>
      </td>
    </tr>
  );
}
