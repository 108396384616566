import React from 'react';
import { core_apimessages_CreditCard } from '@reverbdotcom/commons/src/gql/graphql';
import { CreditCardSummary } from './CreditCardSummary';
import { I18N as Translate } from '@reverbdotcom/commons/src/components/translate';

interface IProps {
  creditCard: core_apimessages_CreditCard;
  setIsEditing: () => void;
}

export default function CreditCardBox({ creditCard, setIsEditing  }: IProps) {
  const { locality, region, postalCode } = creditCard.address || {};

  const finalAddressLine = (locality && region && postalCode) ? `${locality}, ${region} ${postalCode}` : null;

  return (
    <div className="cc-details">
      <ul className="cc-details-list">
        <li className="cc-details-list__item">
          <CreditCardSummary creditCard={creditCard} />
        </li>
        <li className="cc-details-list__item cc-details__address">
          {creditCard.cardholderName}
          <br />
          {creditCard.address?.streetAddress}
          {creditCard.address?.extendedAddress ? (
            <div>{creditCard.address?.extendedAddress}</div>
          ) : (
            <br />
          )}
          {finalAddressLine}
        </li>
      </ul>
      <ul className="cc-details-list cc-details-list--actions">
        {creditCard.primaryForCheckout && (
          <li
            data-primary-card-id={creditCard.id}
            className="cc-details-list__item"
          >
            <Translate className="weight-bold" text="js.creditCards.creditCardBox.primary" />
          </li>
        )}
        <li className="cc-details-list__item cc-details__edit">
          <button
            data-edit-credit-card-id={creditCard.id}
            className="weight-bold button-as-link"
            onClick={setIsEditing}
          >
            <Translate text="js.creditCards.creditCardBox.edit" />
          </button>
        </li>
      </ul>
    </div>
  );
}
