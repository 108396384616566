/**
 * The UserStore is deprecated and unsafe to use for any component that renders server side.
 *
 * Please see the user_context_provider as a replacement.
 * commons/src/components/user_context_provider.tsx
 * Docs: https://github.com/reverbdotcom/docs/blob/master/tech/user-context-provider.md
 */
import alt, { AltStoreModel } from '../components/alt';
import UserActions from './user_actions';
import { deprecated } from '../deprecate';
import {
  isExperimentEnabled,
  experimentBucket,
} from '@reverbdotcom/commons/src/user_context_helpers';

interface Experiment {
  name: string;
  value: string;
}

interface State {
  email: string;
  name: string;
  id: number;
  isAdmin: boolean;
  isActivated: boolean;
  isBusiness: boolean;
  preferredSeller: boolean;
  isBot: boolean;
  loggedOut: boolean;
  cookieId: string;
  requestId: string;
  country: string;
  countryCode: string;
  stateCode: string;
  shippingRegionCode: string;
  profileSlug: string;
  locale: string;
  gdpr: boolean;
  deviceName: string;
  experiments: Experiment[];
  currency: string;
  thirdPartyAdDataAllowed: boolean;
  thirdPartyAdStorageAllowed: boolean;
  thirdPartyAdPersonalizationAllowed: boolean;
  thirdPartyAnalyticsStorageAllowed: boolean;
  secondaryUserModeIsActive: boolean;
}

interface UserStoreMethods extends AltStoreModel<State> {
  isExperimentEnabled(name: string): boolean;
  experimentBucket(name: string): string;
}

class UserStore extends AltStoreModel<State> implements UserStoreMethods {
  email = null;

  name = null;

  country = null;

  id = null;

  isAdmin = false;

  isActivated = true;

  isBusiness = false;

  preferredSeller = false;

  isBot = false;

  loggedOut = true;

  cookieId = null;

  requestId = null;

  countryCode = null;

  stateCode = null;

  shippingRegionCode = null;

  profileSlug = null;

  gdpr = false;

  deviceName = null;

  experiments = [];

  locale = null;

  currency = null;

  thirdPartyAdDataAllowed = true;

  secondaryUserModeIsActive = false;

  constructor() {
    super();
    this.bindActions(UserActions);
    this.exportPublicMethods({
      isExperimentEnabled: this.isExperimentEnabled.bind(this),
      experimentBucket: this.experimentBucket.bind(this),
    });
  }

  onSetUser(user) {
    this.setState(user);
  }

  @deprecated('UserStore is deprecated, please use the UserContextProvider')
  isExperimentEnabled(name) {
    return isExperimentEnabled(this, name);
  }

  @deprecated('UserStore is deprecated, please use the UserContextProvider')
  experimentBucket(name) {
    return experimentBucket(this, name);
  }
}

export default alt.createStore(UserStore, 'UserStore');
