import React from 'react';
import { Location } from 'history';
import { get } from 'lodash';
import { MParticleEventName, trackEvent, EventType } from './elog/mparticle_tracker';
import { IUser } from './components/user_context_provider';
import { useUser } from './user_hooks';
import { isExperimentEnabled } from './user_context_helpers';
import { formatExperimentsForEventAttributes } from './elog/mparticle';
import experiments from './experiments';
import onWindowExitTrigger from './on_window_exit_trigger';
import Modal from './components/modal';
import NewsLetterSignup from './components/newsletter_signup';
import { getItem, setItem } from './local_storage_utils';
import I18n from 'i18n-js';

export const EMAIL_POP_UP = 'email_pop_up';
export const SIGNUP_REASON = 'popup_web_email_capture_MVP_20220907';

const EMAIL_POP_UP_PAGE_PATHS = [
  '/brand/gibson',
  '/brand/gretsch',
  '/brand/roland',
  '/brand/yamaha',
  '/guide',
  '/price-guide',
  '/promo/deals-and-steals',
  '/news',
];

const EMAIL_POP_UP_UTM_SOURCES_BLOCKLIST = ['braze'];
const EMAIL_POP_UP_UTM_MEDIUMS_BLOCKLIST = ['email'];
const MARKETPLACE_PAGE_BRAND_ALLOWLIST = ['fender'];

const checkUtmParams = (query: Record<string, any>): boolean => {
  const utmSource = get(query, 'utmSource', '');
  const utmMedium = get(query, 'utmMedium', '');

  const utmSourceAllowed = !EMAIL_POP_UP_UTM_SOURCES_BLOCKLIST.includes(utmSource);
  const utmMediumAllowed = !EMAIL_POP_UP_UTM_MEDIUMS_BLOCKLIST.includes(utmMedium);

  return utmSourceAllowed && utmMediumAllowed;
};

const checkMarketplaceQuery = (pathname: string, query: Record<string, any>): boolean => {
  const marketplaceBrandParam = get(query, 'make', '');
  const marketplaceBrandParamAllowed = MARKETPLACE_PAGE_BRAND_ALLOWLIST.includes(marketplaceBrandParam);

  return pathname.includes('marketplace') && marketplaceBrandParamAllowed;
};

export const pageShouldShowEmailPopUp = ({ pathname, query }: Partial<Location>): boolean => {
  const isMarketplacePathWithAllowedQuery = checkMarketplaceQuery(pathname, query);
  const isAllowedPath = EMAIL_POP_UP_PAGE_PATHS.some(e => pathname.includes(e));
  const utmParamsAllowed = checkUtmParams(query);

  return utmParamsAllowed && (isAllowedPath || isMarketplacePathWithAllowedQuery);
};

const buildPopUpEvent = (user: Partial<IUser>) => ({
  eventName: MParticleEventName.EligibleForEmailPopUp,
  eventType: EventType.Other,
  experiments: formatExperimentsForEventAttributes(user, [experiments.ELIGIBLE_FOR_EMAIL_POP_UP_V2]),
});

interface EmailPopUpModalProps {
  location?: Location;
  openModal: boolean;
  setOpenModal: any;
}

export function EmailPopUpModal({ location, openModal, setOpenModal }: EmailPopUpModalProps) {
  const user = useUser();
  if (shouldNotDisplayPopUp(location, user)) return null;

  if (openModal) {
    setItem(EMAIL_POP_UP, SIGNUP_REASON);

    trackEvent({
      eventName: MParticleEventName.DisplayedEmailPopUp,
      eventType: EventType.Other,
    });
  }

  return (
    <Modal
      isOpen={openModal}
      onRequestClose={() => setOpenModal(false)}
      size="wide"
    >
      <div className="email-pop-up-modal">
        <div className="email-pop-up-modal__image-container">
          <div className="email-pop-up-modal__image" />
        </div>
        <div className="email-pop-up-modal__content">
          <NewsLetterSignup
            signupReason={SIGNUP_REASON}
            title={I18n.t('commons.emailPopUp.title')}
            subtitle={I18n.t('commons.emailPopUp.subtitle')}
            actionColor="orange"
          />
        </div>
      </div>
    </Modal>
  );
}

function shouldNotDisplayPopUp(location, user) {
  return experimentDisabled(user) || alreadyShownPopUp() || loggedIn(user) || pageDisallowed(location);
}

function experimentDisabled(user) {
  return !isExperimentEnabled(user, experiments.ELIGIBLE_FOR_EMAIL_POP_UP_V2);
}

function alreadyShownPopUp() {
  return getItem(EMAIL_POP_UP) === SIGNUP_REASON;
}

function loggedIn(user) {
  return !user.loggedOut;
}

function pageDisallowed(location) {
  return (location && !pageShouldShowEmailPopUp(location));
}

export function addOnExitTriggerForEmailPopUp(user: Partial<IUser>, openModal, setOpenModal, location) {
  if (shouldNotDisplayPopUp(location, user)) return;

  const popUpEvent = buildPopUpEvent(user);

  onWindowExitTrigger(() => {
    trackEvent(popUpEvent);
    setOpenModal(!openModal);
  });
}

export function AddOnExitTriggerforEmailPopUpHook({ params }) {
  const user = useUser();
  const [openModal, setOpenModal] = React.useState(false);

  React.useEffect(() => {
    const utmSource = params.utm_source;
    const utmMedium = params.utm_medium;
    const utmParamsAllowed = checkUtmParams({ utmSource, utmMedium });

    if (utmParamsAllowed) {
      addOnExitTriggerForEmailPopUp(user, openModal, setOpenModal, location);
    }
  });

  return (<EmailPopUpModal openModal={openModal} setOpenModal={setOpenModal} />);
}
