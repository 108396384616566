import React from 'react';
import isNode from './is-node';
import RouterHistory from './cms/lib/router_history';

/**
 * RRE marshals this RoutingResult into the Component.RoutingResult proto message.
 * If you make changes here, make sure RRE is updated accordingly.
 * Eventually we may want to share this type between commons and RRE but it's small and unlikely to change much.
 */
export interface RoutingResult {
  status?: RoutingStatus;
  redirect_url?: string;
}

export enum RoutingStatus {
  /* eslint-disable no-shadow */
  Ok = 'OK',
  Redirect = 'REDIRECT',
  NotFound = 'NOT_FOUND',
  UnknownError = 'UNKNOWN_ERROR',
  /* eslint-enable no-shadow */
}

/**
 * Allow child components to signal that the result of rendering should affect routing.
 * This gets extracted by RRE for setting HTTP status codes and headers. In the future it may be used
 * for client-side route transitions as well.
 *
 * Inspired by React Router 5's use of context: https://reacttraining.com/react-router/web/guides/server-rendering
 */
export const RoutingResultContext = React.createContext<RoutingResult>({ status: RoutingStatus.Ok });

/**
 * Sets a 404 status code on the response when rendered server-side. You should probably
 * use components/not_found.tsx to render the 404 page instead of rendering this component directly.
 */
export function NotFoundStatusCode() {
  return (
    <RoutingResultContext.Consumer>
      {result => {
        // eslint-disable-next-line no-param-reassign
        result.status = RoutingStatus.NotFound;
        return null;
      }}
    </RoutingResultContext.Consumer>
  );
}

/**
 * Sets a redirect status code and location on the response when rendered server-side
 *
 * @param {string} props.url - Redirect destination
 * @param {boolean} props.fullPageReload - Causes a full page client-side reload if true instead of using react-router.
 *    Necessary when redirecting to a non-React routed page or when redirecting offsite to a known URL.
 */
export function Redirect(props: { url: string, fullPageReload?: boolean }) {
  if (!isNode) {
    if (props.fullPageReload) {
      window.location.assign(props.url);
    } else {
      RouterHistory.replace(props.url);
    }

    return null;
  }

  return (
    <RoutingResultContext.Consumer>
      {result => {
        /* eslint-disable no-param-reassign */
        result.status = RoutingStatus.Redirect;
        result.redirect_url = props.url;
        /* eslint-enable no-param-reassign */

        return (
          <div>
            You are being <a href={props.url}>redirected</a>
          </div>
        );
      }}
    </RoutingResultContext.Consumer>
  );
}
