export default {
  flagMessage: {
    button: {
      completedText: 'Message Reported',
      defaultText: 'Report message',
      modalTitle: 'Report message',
    },
    form: {
      reasonSelect: {
        label: 'Reason',
        option: {
          abuse: 'Abuse',
          fraud: 'Fraud / Spam',
          offsite: 'Off-site/unprotected transaction',
        },
      },
      button: {
        loadingText: 'Please wait...',
        defaultText: 'Submit',
      },
    },
  },
};
