import React from 'react';
import I18n from 'i18n-js';
import { gql } from '@reverbdotcom/commons/src/gql';
import { useQuery } from '@reverbdotcom/commons/src/useQuery';
import SanitizedRender from '@reverbdotcom/commons/src/components/sanitized_render';
import { MParticleEventName, trackEvent } from '@reverbdotcom/commons/src/elog/mparticle_tracker';
import { useViewTracking } from '@reverbdotcom/commons/src/use_tracking';
import { CoreListingShippingBoxImage, Listing } from '@reverbdotcom/commons/src/gql/graphql';
import { ExternalLinkIcon } from '@reverbdotcom/cadence/icons/react';
import { RCImage, RCTextWithIcon } from '@reverbdotcom/cadence/components';
import CoreLink from '@reverbdotcom/commons/src/components/core_link';

import {
  shippingGuideDetailsForListing,
} from './item2_shipping_prompt_helpers';

import URLHelpers from './shared/url_helpers';
import BLANK_MEDIUM_IMAGE from '../../images/products/blank_medium.jpg';

// For the shipping box image, we're hardcoding a listing to fetch.
// This listing and its image is managed by Reverb.
// Even if this listing sells out or ends, the image will still be available through the API.
// Relying on this listing image to mitigate stale branding.
// We may update this asset in the future and have it live in the
// frontend repo.

const LISTING_ID_FOR_IMAGE = '72589089';

const SHIPPING_BOX_IMAGE_QUERY = gql(`
  query Core_Listing_ShippingBoxImage($listingId: String) {
    listing(input: { id: $listingId }) {
      _id
      images(input: { transform: "card_square", count: 1 }) {
        _id
        source
      }
    }
  }
`);
function trackShippingGuideClick(listingId: string) {
  trackEvent({
    eventName: MParticleEventName.ClickedReverbShippingGuideInsideShippingPrompt,
    listingId,
  });
}

function renderShippingGuideText({ shippingGuideDetails }) {
  return I18n.t('discovery.item2ShippingPrompt.shippingGuideDescription', {
    href: shippingGuideDetails.guideLink,
    items: I18n.t(`discovery.item2ShippingPrompt.${shippingGuideDetails.i18ItemKey}`),
  });
}

interface IExternalProps {
  listing: Listing;
}

export function Item2ShippingPrompt({
  listing,
}: IExternalProps) {
  const imageQuery = useQuery<CoreListingShippingBoxImage.Query>(
    SHIPPING_BOX_IMAGE_QUERY,
    {
      variables: {
        listingId: LISTING_ID_FOR_IMAGE,
      },
      ssr: false,
      errorPolicy: 'ignore',
    },
  );

  const {
    loading: imageLoading,
    data = {
      listing: {
        images: [],
      },
    },
  } = imageQuery;

  const shippingBoxImageSource = data.listing.images[0]?.source ?? BLANK_MEDIUM_IMAGE;
  const shippingGuideDetails = shippingGuideDetailsForListing(listing);

  useViewTracking({
    componentName: 'Item2ShippingPrompt',
    eventName: MParticleEventName.ComponentView,
    listingId: listing.id,
  }, true);

  if (imageLoading) return null;

  return (
    <>
      <div className="d-flex fx-dir-col gap-3">
        <div className="size-110 weight-bold">
          {I18n.t('discovery.item2ShippingPrompt.getReadyToPackYourItem')}
        </div>

        <div onClick={() => trackShippingGuideClick(listing.id)}>
          <SanitizedRender
            html={renderShippingGuideText({
              shippingGuideDetails,
            })}
          />
        </div>

        <div className="d-flex gap-3">
          <div className="width-40">
            <RCImage
              src={shippingBoxImageSource}
              alt={I18n.t('discovery.item2ShippingPrompt.shippingBoxImage.alt')}
            />
          </div>

          <div className="d-flex fx-dir-col fx-justify-center">
            <div className="size-100 weight-bold mb-5">
              {I18n.t('discovery.item2ShippingPrompt.needShippingMaterials')}
            </div>

            <CoreLink
              className="rc-button rc-button--filled rc-button--main rc-button--medium"
              to={URLHelpers.reverbMerchStorePath()}
              target="_blank"
            >
              <RCTextWithIcon svgComponent={ExternalLinkIcon} placement="right">
                {I18n.t('discovery.item2ShippingPrompt.viewReverbShippingBoxes')}
              </RCTextWithIcon>
            </CoreLink>
          </div>
        </div>
      </div>
    </>
  );
}

export default Item2ShippingPrompt;
