// TODO update this to import from commons
// import { gql } from '@reverbdotcom/commons/src/gql';
// eslint-disable-next-line no-restricted-imports
import { gql } from '@apollo/client';
import { withGraphql } from '@reverbdotcom/commons/src/with_graphql';
import { mParticleListingFields } from '@reverbdotcom/commons/src/components/with_mparticle_listings';
import { CoreBumpRecommendationListing } from '@reverbdotcom/commons/src/gql/graphql';

// these attributes are required to know if a bumped item is "buy box eligible"
export const BumpRecommendationsAttributesFragment = gql`
  fragment BumpRecommendationsAttributes on Listing {
    _id
    condition {
      conditionUuid
      conditionSlug
      displayName
    }
    csp {
      _id
      id
      title
      slug
    }
  }
`;

export const BumpRecommendationsFragment = gql`
  fragment BumpRecommendationsFragment on Listing {
    _id
    bumpRecommendations {
      bumpRecommendationAmount
    }
  }
`;

export const CoreBumpRecommendationListingCardFragment = gql`
  fragment CoreBumpRecommendationListingCard on Listing {
    images(input:{type: "square", transform: "card_square", count: 1}) {
      _id
      source
    }
    _id
    hasInventory
    sellerPrice {
      amount
      amountCents
      currency
      display
    }
    shipping {
      shippingPrices {
        _id
        shippingRegionCode
        originalRate {
          amount
          amountCents
          currency
          display
        }
      }
    }
    ...mParticleListingFields
    ...BumpRecommendationsAttributes
  }
  ${mParticleListingFields}
  ${BumpRecommendationsAttributesFragment}
`;

export interface CoreBumpListingCardQueryProps {
  listingId: string;
}

export const BUMPED_LISTING_RECOMMENDATION_QUERY = gql`
  query Core_Bump_Recommendation_Listing(
    $id: String
  ){
    listing(
      input: {id: $id}
    ) {
      _id
        ...CoreBumpRecommendationListingCard
        ...BumpRecommendationsFragment
      }
    }
    ${CoreBumpRecommendationListingCardFragment}
    ${BumpRecommendationsFragment}
`;

export const coreBumpListingCardQuery = withGraphql<CoreBumpListingCardQueryProps, CoreBumpRecommendationListing.Query>(
  BUMPED_LISTING_RECOMMENDATION_QUERY,
  {
    options: (ownProps) => {
      return {
        errorPolicy: 'all',
        ssr: false,
        variables: {
          id: ownProps.listingId,
        },
      };
    },
  },
);
