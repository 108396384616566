import React, { useState } from 'react';
import {
  core_apimessages_CreditCard,
  core_apimessages_Country,
  core_apimessages_AddressEntry,
} from '@reverbdotcom/commons/src/gql/graphql';
import I18n from 'i18n-js';
import { useMutation } from '@reverbdotcom/commons/src/useMutation';
import { UPDATE_MY_CREDIT_CARD_MUTATION } from './credit_card_edit_form';
import { CreditCardSummary } from './CreditCardSummary';
import { ErrorList } from '@reverbdotcom/commons/src/ErrorList';
import AddressFormFields from './address_form_fields';
import { RCButton, RCTextInput, RCCheckbox, RCSelect, RCAlertBox } from '@reverbdotcom/cadence/components';
import { useHelpCenterArticleUrl } from '@reverbdotcom/commons/src/url_helpers';
import { DELETE_CREDIT_CARD_MUTATION } from './CreditCardDeleteButton';
import { generateDropdownYears, MONTHS } from './../credit_cards/credit_card_helpers';

const UPDATE_CREDIT_CARD_ARTICLE_ID = '1500009174881';

const years = generateDropdownYears();

const COMPONENT_NAME = 'MyAccountCreditCardForm';

interface IProps {
  creditCard: core_apimessages_CreditCard;
  countries: core_apimessages_Country[];
  cancelEdit: () => void;
}

export default function MyAccountCreditCardForm({
  creditCard,
  countries,
  cancelEdit,
}: IProps) {
  const [updateCreditCard, { loading: updateLoading, errors: updateErrors }] = useMutation(UPDATE_MY_CREDIT_CARD_MUTATION);

  const [deleteCreditCard, { loading: deleteLoading, errors: deleteErrors }] = useMutation(DELETE_CREDIT_CARD_MUTATION);

  const [ isConfirmingDelete, setIsConfirmingDelete ] = useState(false);

  const [saveAsDefault, setSaveAsDefault] = React.useState(creditCard.primaryForCheckout);

  const [creditCardState, setCreditCardState] = React.useState({
    cardholderName: creditCard.cardholderName,
    expirationMonth: creditCard.expirationMonth,
    expirationYear: creditCard.expirationYear,
  });

  const [billingAddress, setBillingAddress] = React.useState<core_apimessages_AddressEntry>({
    countryCode: creditCard.address?.countryCode,
    name: creditCard.address?.name,
    streetAddress: creditCard.address?.streetAddress,
    extendedAddress: creditCard.address?.extendedAddress,
    locality: creditCard.address?.locality,
    region: creditCard.address?.region,
    postalCode: creditCard.address?.postalCode,
    phone: creditCard.address?.phone,
  });

  const troubleshootingUrl = useHelpCenterArticleUrl(UPDATE_CREDIT_CARD_ARTICLE_ID);

  const isLoading = updateLoading || deleteLoading;

  const errors = [...updateErrors, ...deleteErrors];

  const onChange = (e) => {
    setCreditCardState(prev => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  async function onDelete(e) {
    e.preventDefault();

    const result = await deleteCreditCard({
      variables: {
        input: {
          id: creditCard.id,
        },
      },
      refetchQueries: [ 'Core_MyAccount_CreditCardsOnFile' ],
      awaitRefetchQueries: true,
    });

    if (result?.data?.deleteCreditCard) {
      cancelEdit();
    }
  }

  async function onSave(e) {
    e.preventDefault();

    const result = await updateCreditCard({
      variables: {
        input: {
          id: creditCard.id,
          creditCard: {
            ...creditCardState,
            primaryForCheckout: saveAsDefault,
          },
          billingAddress,
        },
      },
      refetchQueries: [ 'Core_MyAccount_CreditCardsOnFile' ],
      awaitRefetchQueries: true,
    });

    if (result?.data?.updateMyCreditCard) {
      cancelEdit();
    }
  }

  return (
    <>
      <CreditCardSummary creditCard={creditCard} />
      <div className="g-container d-flex fx-dir-col mtb-4">
        <div className="d-flex fx-justify-between mb-2">
          <div className="fx-grow mr-4">
            <RCSelect
              label={I18n.t('discovery.creditCardWallet.editForm.expirationMonth')}
              name="expirationMonth"
              id="expirationMonth"
              value={creditCardState.expirationMonth}
              onChange={onChange}
            >
              {MONTHS.map((month) => (
                <RCSelect.Option
                  key={month}
                  value={month}
                  label={month}
                />
              ))}
            </RCSelect>
          </div>
          <div className="fx-grow">
            <RCSelect
              label={I18n.t('discovery.creditCardWallet.editForm.expirationYear')}
              name="expirationYear"
              id="expirationYear"
              value={creditCardState.expirationYear}
              onChange={onChange}
            >
              {years.map((year) => (
                <RCSelect.Option
                  key={year}
                  value={year}
                  label={year}
                />
              ))}
            </RCSelect>
          </div>
        </div>
        <RCTextInput
          label={I18n.t('discovery.creditCardWallet.editForm.cardholderName')}
          name="cardholderName"
          value={creditCardState.cardholderName}
          id="cardholderName"
          type="text"
          placeholder={I18n.t('discovery.creditCardWallet.editForm.cardholderName')}
          onChange={onChange}
        />
      </div>
      <RCCheckbox
        id="credit-card-edit-billing"
        name="credit-card-edit-billing"
        checked={saveAsDefault}
        onChange={() => setSaveAsDefault(prev => !prev)}
        label={I18n.t('discovery.creditCardWallet.editForm.saveDefault')}
      />
      <div className="bdt-1 bd-color-primary mtb-4" />
      <AddressFormFields
        address={billingAddress}
        countries={countries}
        updateField={setBillingAddress}
        useCamelizedFieldsForRQL
        trackingComponentName={COMPONENT_NAME}
      />
      {errors.length > 0 && (
        <div className="mt-4">
          <RCAlertBox type="error">
            <ErrorList errors={errors} />
            <div className="mt-2">
              <a href={troubleshootingUrl}>
                {I18n.t('discovery.creditCardWallet.cardTroubleshooting')}
              </a>
            </div>
          </RCAlertBox>
        </div>
      )}
      <div className="address-form__actions">
        <RCButton
          id="update_card"
          variant="filled"
          onClick={onSave}
          disabled={isLoading}
        >
          {I18n.t('js.creditCards.creditCardForm.saveCard')}
        </RCButton>
        <div className="address-form__actions__options-wrapper">
          <RCButton
            variant="muted"
            id="cancel_edit"
            onClick={cancelEdit}
          >
            {I18n.t('js.creditCards.creditCardForm.cancel')}
          </RCButton>
          { isConfirmingDelete ? (
            <div className="address-form__actions__secondary">
              <span className="size-90 mb-1">
                {I18n.t('js.creditCards.creditCardForm.confirmDelete')}
              </span>
              <button
                id="confirm_delete"
                className="button-as-link ml-1"
                data-confirm-delete-credit-card-id={creditCard.id}
                onClick={onDelete}
              >
                {I18n.t('js.creditCards.creditCardForm.yes')}
              </button>
              <button
                id="cancel_delete"
                className="button-as-link ml-1"
                onClick={() => setIsConfirmingDelete(false)}
              >
                {I18n.t('js.creditCards.creditCardForm.no')}
              </button>
            </div>
          ) : (
            <div className="address-form__actions__secondary">
              <RCButton
                id="delete_card"
                data-delete-credit-card-id={creditCard.id}
                onClick={() => setIsConfirmingDelete(true)}
                variant="muted"
                theme="danger"
              >
                {I18n.t('js.creditCards.creditCardForm.deleteCard')}
              </RCButton>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
