import I18n from 'i18n-js';
import React from 'react';
import classNames from 'classnames';

interface IRibbonViewProps {
  auction?: boolean;
  listingStateDescription: string;
  listingStateSlug: string;
  saleRibbonDisplay?: string;
  priceDropDisplay?: string;
  discountDisplay?: string;
  unstyled?: boolean;
  callout?: JSX.Element;
}

export default class RibbonView extends React.Component<IRibbonViewProps, null> {
  getLanguage() {
    if (this.isEnded()) {
      return this.props.listingStateDescription;
    }

    if (this.isAuction()) {
      return I18n.t('commons.ribbonView.bidNow');
    }

    if (this.hasDiscount()) {
      return this.props.discountDisplay;
    }

    if (this.isOnSale()) {
      return this.props.saleRibbonDisplay;
    }

    if (this.isPriceDrop()) {
      return this.props.priceDropDisplay;
    }

    return false;
  }

  isEnded() {
    return this.props.listingStateSlug !== 'live';
  }

  isAuction() {
    return !!this.props.auction;
  }

  isOnSale() {
    return this.props.saleRibbonDisplay;
  }

  isPriceDrop() {
    return !!this.props.priceDropDisplay;
  }

  hasDiscount() {
    return !!this.props.discountDisplay;
  }

  renderCallout() {
    if (!this.props.callout) return;
    return this.props.callout;
  }

  render() {
    const classes = classNames(
      'ribbon-view',
      {
        'ribbon-view--ended': this.isEnded(),
        'ribbon-view--auction': this.isAuction(),
        'ribbon-view--sale': this.hasDiscount() || this.isOnSale(),
        'ribbon-view--pricedrop': this.isPriceDrop(),
        'ribbon-view--unstyled': this.props.unstyled,
      },
    );

    const language = this.getLanguage();
    if (!language) {
      return null;
    }

    return (
      <div className={classes}>
        {language}
        {this.renderCallout()}
      </div>
    );
  }
}
