import I18n from 'i18n-js';
import { includes } from 'lodash';

import { US_CON, US_AK, US_HI, US_PR, US_OTHER } from '../constants';
import { core_apimessages_Country as ICountry } from '../gql/graphql';

export const SUBREGION_CODES = [
  US_CON,
  US_AK,
  US_HI,
  US_PR,
  US_OTHER,
];

export function subregionName(regionCode: string): string {
  return I18n.t(`commons.subRegions.${regionCode}`);
}

export function shippingRegionName(regionCode: string, countries: ICountry[]) {
  if (includes(SUBREGION_CODES, regionCode)) {
    return subregionName(regionCode);
  }

  return countries.find(country => country.countryCode === regionCode).name;
}
