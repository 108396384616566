import Cookies from 'js-cookie';

const ITEM_REGION_OVERRIDE_COOKIE = 'itemRegionOverride';
const EVERYWHERE_ELSE_REGION_CODE = 'XX';

// exported for mocking
export function refresh(href) {
  window.location.href = href;
}

export function itemRegionConfigured() {
  const region = itemRegion();
  return region && region !== EVERYWHERE_ELSE_REGION_CODE;
}

export function itemRegion() {
  return Cookies.get(ITEM_REGION_OVERRIDE_COOKIE) || '';
}

export function changeStickyItemRegion(region, href: string) {
  const currentValue = itemRegion();
  if (currentValue !== region) {
    if (region.length === 0) {
      Cookies.remove(ITEM_REGION_OVERRIDE_COOKIE);
    } else {
      Cookies.set(ITEM_REGION_OVERRIDE_COOKIE, region);
    }

    refresh(href);
  }
}
