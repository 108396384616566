import { ChildProps } from '@apollo/client/react/hoc';
// TODO update this to import from commons
// import { gql } from '@reverbdotcom/commons/src/gql';
// eslint-disable-next-line no-restricted-imports
import { gql } from '@apollo/client';
import React from 'react';
import { withGraphql } from '@reverbdotcom/commons/src/with_graphql';
import { CoreFeedbackSellerShopInfo } from '@reverbdotcom/commons/src/gql/graphql';
import FeedbackShopLink from './feedback_shop_link';

interface IProps {
  listingId: string;
}

const connect = withGraphql<IProps, CoreFeedbackSellerShopInfo.Query, CoreFeedbackSellerShopInfo.Variables>(
  gql`
    query Core_Feedback_SellerShopInfo(
      $listingId: String
    ){
      listing(input: { id: $listingId }) {
        _id
        shop {
          _id
          name
          slug
        }
      }
    }
  `,
  {
    options: (ownProps) => {
      return {
        ssr: true,
        variables: {
          listingId: ownProps.listingId,
        },
      };
    },
  },
);

export class FeedbackSellerShopInfo extends React.Component<ChildProps<IProps, CoreFeedbackSellerShopInfo.Query>, null> {
  get loading() {
    return this.props.data.loading !== false;
  }

  get shop() {
    return this.props.data.listing.shop;
  }

  renderPlaceholder() {
    return (
      <div className="feedback-seller-shop-info feedback-seller-shop-info--placeholder"></div>
    );
  }

  render() {
    if (this.loading) {
      return this.renderPlaceholder();
    }

    return (
      <FeedbackShopLink
        shop={this.shop}
      />
    );
  }
}

export default connect(FeedbackSellerShopInfo);
