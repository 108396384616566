import React from 'react';
import { Link } from 'react-router';
import qs from 'qs';

interface ILocationDescriptor {
  pathname?: string;
  query?: any;
  hash?: string;
  state?: any;
}

interface IProps {
  scrollTo?: string;

  className?: string;
  onClick?: any;
  rel?: string;
  to: string | ILocationDescriptor;
  activeClassName?: string;
}

export default class ScrollLink extends React.Component<IProps, any> {
  render() {
    return (
      <Link
        className={this.props.className}
        rel={this.props.rel}
        to={scrollLinkLocation(this.props)}
        onClick={this.props.onClick}
        activeClassName={this.props.activeClassName}
      >
        {this.props.children}
      </Link>
    );
  }
}

export function scrollLinkLocation(props: IProps): ILocationDescriptor {
  const state = { scrollTo: props.scrollTo };

  if (typeof props.to === 'object') {
    return {
      ...props.to,
      state,
    };
  }

  const [pathname, search] = props.to.split('?', 2);

  return {
    state,
    pathname,
    query: qs.parse(search),
  };
}
