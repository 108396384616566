import React from 'react';
import classNames from 'classnames';

interface Props {
  size?: string;
  color?: string;
}

export default class Spinner extends React.Component<Props, {}> {
  classNames = () => classNames('loading-component', this.props.size);

  getWings = () => {
    const wings = [];
    const wingCount = this.props.size === 'small' ? 3 : 5;
    const color = (this.props.color) ? { backgroundColor: this.props.color } : null;
    for (let i = 0; i < wingCount; i++) {
      const wing = {
        classNames: `rect rect${i + 1}`,
        style: color,
      };

      wings.push(wing);
    }

    return wings;
  };

  render() {
    return (
      <div className={this.classNames()}>
        {this.getWings().map((wing, index) => <div key={index} className={wing.classNames} style={wing.style} />)}
      </div>
    );
  }
}
