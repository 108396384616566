import I18n from 'i18n-js';
import baseUrlHelpers from '../../../shared/url_helpers';
import componentUrlHelpers, { Paths } from '../../shared/url_helpers';
import userHelpCenterUrl from '../../shared/user_help_center_url';
import { core_apimessages_MyShopOnboardingResponse_PaymentMethod } from '@reverbdotcom/commons/src/gql/graphql';
import { IUser } from '@reverbdotcom/commons/src/components/user_context_provider';
import { dealsAndStealsPromoURLPath } from '../../promo_url_path_for_user';
import { isNonUSUser } from '@reverbdotcom/commons/src/user_context_helpers';

export interface ILinkProps {
  key: string;
  path: string;
  text: string;
  hide?: boolean;
  logOut?: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
}

export interface IReverbHeaderMenuSectionProps {
  title: string;
  links: ILinkProps[];
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
}

export interface IReverbHeaderMenuSectionData extends IReverbHeaderMenuSectionProps {
  key: string;
}

export function buildSellingSection(shopSlug?: string, isShopAdmin?: boolean): IReverbHeaderMenuSectionData {
  return {
    key: 'selling',
    title: I18n.t('discovery.reverbHeader.userMenu.selling'),
    links: shopSlug ? buildShopSectionLinks(shopSlug, isShopAdmin) : buildNoShopSectionLinks(),
  };
}

export function buildBuyingSection(user: Partial<IUser>): IReverbHeaderMenuSectionData {
  return {
    key: 'buying',
    title: I18n.t('discovery.reverbHeader.userMenu.buying'),
    links: [
      {
        key: 'purchases',
        path: baseUrlHelpers.ordersManagerBuyingSearchResultsPath(),
        text: I18n.t('discovery.reverbHeader.userMenu.purchases'),
      },
      {
        key: 'gift_cards',
        path: baseUrlHelpers.giftCardsPath,
        text: I18n.t('discovery.reverbHeader.userMenu.giftCards'),
        hide: isNonUSUser(user),
      },
    ],
  };
}

export function buildFinancesSection(shopPaymentMethod: string): IReverbHeaderMenuSectionData {
  const canViewPayouts = shopPaymentMethod === core_apimessages_MyShopOnboardingResponse_PaymentMethod.REVERB_PAYMENTS;

  return {
    key: 'finances',
    title: I18n.t('discovery.reverbHeader.userMenu.finances'),
    links: [
      {
        key: 'payouts',
        path: baseUrlHelpers.dashboardEarningsPayoutsPath,
        text: I18n.t('discovery.reverbHeader.userMenu.earnings'),
        hide: !canViewPayouts,
      },
      {
        key: 'my_bill',
        path: baseUrlHelpers.statementsPath,
        text: I18n.t('discovery.reverbHeader.userMenu.myBill'),
      },
    ],
  };
}

export function buildMyAccountSection({ locale }): IReverbHeaderMenuSectionData {
  return {
    key: 'my_account',
    title: I18n.t('discovery.reverbHeader.userMenu.myAccount'),
    links: [
      {
        key: 'my_collection',
        path: componentUrlHelpers.gearCollectionsPath(),
        text: I18n.t('discovery.reverbHeader.userMenu.myCollection'),
      },
      {
        key: 'messages',
        path: baseUrlHelpers.myMessagesPath,
        text: I18n.t('discovery.reverbHeader.userMenu.messages'),
      },
      {
        key: 'profile',
        path: componentUrlHelpers.accountEditPath,
        text: I18n.t('discovery.reverbHeader.userMenu.myProfile'),
      },
      helpCenterLink(locale),
      {
        key: 'signout',
        path: componentUrlHelpers.signoutPath,
        text: I18n.t('discovery.reverbHeader.userMenu.logOut'),
        logOut: true,
      },
    ],
  };
}

export function buildExploreSection(user: Partial<IUser>): IReverbHeaderMenuSectionData {
  const favoritesLinks = [
    {
      key: 'favorites',
      path: Paths.myFavorites.expand({}),
      text: I18n.t('discovery.reverbHeader.mobileMenu.favorites'),
      hide: user.loggedOut,
    },
  ];

  return {
    key: 'explore',
    title: I18n.t('discovery.reverbHeader.mobileMenu.explore'),
    links: [
      {
        key: 'new_and_popular',
        path: baseUrlHelpers.topSellersRecentReleasesPath,
        text: I18n.t('discovery.reverbHeader.mobileMenu.newAndPopular'),
      },
      {
        key: 'deals_and_steals',
        path: dealsAndStealsPromoURLPath(user),
        text: I18n.t('discovery.reverbHeader.mobileMenu.dealsAndSteals'),
      },
      {
        key: 'price_guide',
        path: baseUrlHelpers.priceGuidePath,
        text: I18n.t('discovery.reverbHeader.mobileMenu.priceGuide'),
      },
      ...favoritesLinks,
      {
        key: 'news',
        path: baseUrlHelpers.newsPath,
        text: I18n.t('discovery.reverbHeader.mobileMenu.news'),
      },
    ],
  };
}

export function buildJoinReverbSection(locale): IReverbHeaderMenuSectionData {
  return {
    key: 'join_reverb',
    title: I18n.t('discovery.reverbHeader.mobileMenu.joinReverb'),
    links: [
      {
        key: 'sign_up',
        path: componentUrlHelpers.signupPath(),
        text: I18n.t('discovery.reverbHeader.signUp'),
      },
      {
        key: 'log_in',
        path: componentUrlHelpers.signinPath(),
        text: I18n.t('discovery.reverbHeader.logIn'),
      },
      helpCenterLink(locale),
    ],
  };
}

function helpCenterLink(locale: string): ILinkProps {
  const path = `${userHelpCenterUrl(locale)}?utm_source=Reverb&utm_medium=menu&utm_campaign=dropdown`;

  return {
    key: 'help_center',
    path,
    text: I18n.t('discovery.reverbHeader.userMenu.helpCenter'),
  };
}

function buildShopSectionLinks(shopSlug: string, isShopAdmin?: boolean): ILinkProps[] {
  const shopSectionLinks = [
    {
      key: 'sell',
      path: componentUrlHelpers.sellFormSearchPath({}),
      text: I18n.t('discovery.reverbHeader.userMenu.sellYourGear'),
    },
    {
      key: 'my_shop',
      path: componentUrlHelpers.shopPath(shopSlug),
      text: I18n.t('discovery.reverbHeader.userMenu.myShop'),
    },
    {
      key: 'shop_settings',
      path: baseUrlHelpers.shopPoliciesEditPath,
      text: I18n.t('discovery.reverbHeader.userMenu.shopSettings'),
      hide: !isShopAdmin,
    },
    {
      key: 'listings',
      path: baseUrlHelpers.listingsManagerPath,
      text: I18n.t('discovery.reverbHeader.userMenu.listings'),
    },
    {
      key: 'orders',
      path: baseUrlHelpers.ordersManagerSellingSearchResultsPath(),
      text: I18n.t('discovery.reverbHeader.userMenu.orders'),
    },
    {
      key: 'dashboard',
      path: componentUrlHelpers.dashboardPath,
      text: I18n.t('discovery.reverbHeader.userMenu.dashboard'),
    },
  ];

  return shopSectionLinks;
}

function buildNoShopSectionLinks(): ILinkProps[] {
  return [{
    key: 'sell_now',
    path: componentUrlHelpers.sellFormSearchPath({}),
    text: I18n.t('discovery.reverbHeader.userMenu.sellNow'),
  }];
}
