import React from 'react';
import classNames from 'classnames';

interface IProps {
  data: {
    title: string,
    description: string,
    notification_links: {
      web: {
        href: string,
      },
    },
    notification_type: string,
    hidden: boolean;
  },
}

const ACTION_NEEDED = 'ACTION_NEEDED';
const NEW_FEATURE_ALERT = 'NEW_FEATURE_ALERT';

function ReverbHeaderUserNotification({ data }: IProps) {
  const classes = classNames(
    'reverb-header__menu__link',
    'reverb-header__menu__link--alert',
    { 'reverb-header__menu__link--new-feature': data.notification_type === NEW_FEATURE_ALERT },
    { 'reverb-header__menu__link--action-needed': data.notification_type === ACTION_NEEDED },
  );

  if (data.hidden) { return null; }

  const link = data?.notification_links?.web?.href;
  if (!link) { return null; }

  // Remove domain to preseve ORIGIN for admin.reverb.tools
  const loc = new URL(link);
  const href = loc.pathname + loc.search + loc.hash;

  return (
    <li>
      <a
        className={classes}
        href={href}
        target="_blank"
        rel="noopener noreferrer"
      >
        {data.title}
        <span className="reverb-header__menu__link-subtitle">
          {data.description}
        </span>
      </a>
    </li>
  );
}

export default ReverbHeaderUserNotification;
