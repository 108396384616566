import React from 'react';
import { Route } from 'react-router';

import { CSP_INDEX_ROUTE } from '../components/shared/constants';

const CSPIndexRoute = (
  <Route
    key={CSP_INDEX_ROUTE}
    path={CSP_INDEX_ROUTE}
    props={{ clientSide: true }}
    getComponent={(_location, cb) => {
      import(
        /* webpackChunkName: 'csp' */
        '../components/discovery/csp/csp_index'
      ).then(m => cb(null, m.default));
    }}
  />
);

export default CSPIndexRoute;
