import React from 'react';
import ReactModal from 'react-modal';
import classNames from 'classnames';
import isNode from '../is-node';
import { RCCloseButton } from '@reverbdotcom/cadence/components';
import { REACT_MODAL_PORTAL_DOM_CLASS } from '../constants';

import bind from '../bind';

export type Size = 'wide' | 'narrow' | 'fluid';

interface IModalProps {
  /** Main control to hide/show the modal */
  isOpen: boolean;

  /** Add a _unique_ ID to the modal for various targeting purposes */
  id?: string;

  /** Will append a class of `reverb-modal--${subClass}` to the top-level modal element.
   * Helpful for custom style overrides */
  subClass?: string;

  onRequestClose?: Function;
  shouldCloseOnOverlayClick?: boolean;
  shouldCloseOnEsc?: boolean;

  /** This renders in an <h3> tag and should be clear, definitive and SEO/Screen reader friendly */
  headerTitle?: string;

  /** headerMarkup is used for edge cases where the header needs special 1-off styling.
   * It replaces headerTitle if both are present. */
  headerMarkup?: JSX.Element | React.ReactNode;

  /** If you need your modal content to go edge-to-edge, omit paddedBody,
   * otherwise paddedBody is the simple way to get even paddings around your content */
  paddedBody?: boolean;

  /** Optional sidebar content that appears in a black sidebar next to the modal content.
   * Make sure to style the mobile view as it's substantially different than desktop! */
  sidebarContent?: JSX.Element | React.ReactNode;

  /** A few built-in size variations for modals */
  size?: Size;

  /** Allows for overriding default placement. 'Bottom' can be used for consent-type content */
  position?: 'default' | 'bottom';

  /** Use if you want the background-color of the overlay to be transparent */
  hideOverlay?: boolean;

  /** Use if you want the page to be scrollable while the modal is open */
  allowBackgroundScroll?: boolean;

  /** Adds top margin in mobile viewports. Avoid using for "tall" content-heavy modals. */
  mobileTopMargin?: boolean;
}

export default class Modal extends React.Component<IModalProps, {}> {
  constructor(props) {
    super(props);

    // Element to add `aria-hidden` to when modal is open. This should really
    // be a child of body, but our layout doesn't have a single container element.
    // https://reactcommunity.org/react-modal/examples/set_app_element.html
    ReactModal.setAppElement('body');
  }

  @bind
  outlet() {
    return document.querySelector(`.${REACT_MODAL_PORTAL_DOM_CLASS}`) || document.body;
  }

  @bind
  handleClose(evt) {
    evt.stopPropagation();
    evt.preventDefault();

    this.props.onRequestClose?.();
  }

  @bind
  handleAfterOpen() {
    /* eslint-disable no-unused-expressions */
    // Hack for Microsoft Edge - for some reason, on this browser the modal is
    // not being painted on the screen when opened. This will force a repaint of
    // the parent element of the modal. This issue was not reproducable from a
    // basic ReactModal installation, so it's unclear what the root cause is.
    const els = document.getElementsByClassName('ReactModalPortal');
    for (let i = 0; i < els.length; i += 1) {
      const el = els[i] as HTMLElement;
      el.style.display = 'none';
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      el.offsetHeight;
      el.style.display = '';
    }
    /* eslint-enable no-unused-expressions */
  }

  render() {
    // https://reactjs.org/docs/error-decoder.html/?invariant=257
    if (isNode) { return null; }

    const classes = classNames(
      'reverb-modal',
      { 'reverb-modal--padded-body': this.props.paddedBody },
      { 'reverb-modal--with-sidebar': this.props.sidebarContent },
      { 'reverb-modal--mobile-top-margin': this.props.mobileTopMargin },
      { 'reverb-modal--wide': this.props.size === 'wide' },
      { 'reverb-modal--narrow': this.props.size === 'narrow' },
      { 'reverb-modal--fluid': this.props.size === 'fluid' },
      { 'reverb-modal--position-bottom': this.props.position === 'bottom' },
      this.props.subClass && `reverb-modal--${this.props.subClass}`,
    );

    return (
      // https://github.com/reactjs/react-modal/pull/734
      // This code means that ALL clicks from inside the modal will bubble up a click.
      // This causes any onClick handlers above this to understand _how_ to handle these
      // events. In this case a CoreLink wrapped the modal and caused it to route
      (<ReactModal
        id={this.props.id}
        isOpen={this.props.isOpen}
        contentLabel="Modal"
        overlayClassName={classNames(
          'reverb-modal__container',
          { 'reverb-modal__container--hidden': this.props.hideOverlay },
        )}
        className={classes}
        shouldCloseOnOverlayClick={this.props.shouldCloseOnOverlayClick}
        shouldCloseOnEsc={this.props.shouldCloseOnEsc}
        onRequestClose={this.handleClose}
        parentSelector={this.outlet}
        onAfterOpen={this.handleAfterOpen}
        bodyOpenClassName={classNames(
          'ReactModal__Body--open',
          { 'ReactModal__Body--allow-scroll': this.props.allowBackgroundScroll },
        )}
      >
        <div
          className="reverb-modal__content"
          onClick={(e) => { e.stopPropagation(); }}
        >
          {(this.props.headerTitle || this.props.headerMarkup) &&
            <div className="reverb-modal__header">
              {this.props.headerMarkup ? (
                <div className="reverb-modal__header__markup">
                  {this.props.headerMarkup}
                </div>
              ) : (
                <h3 className="reverb-modal__header__title">
                  {this.props.headerTitle}
                </h3>
              )}
            </div>
          }
          {this.props.sidebarContent &&
            <div className="reverb-modal__sidebar">
              {this.props.sidebarContent}
            </div>
          }
          <div className="reverb-modal__body">
            <div className="reverb-modal__body-inner">
              {this.props.children}
            </div>
          </div>
          {this.props.onRequestClose &&
            <div className="reverb-modal__close">
              <RCCloseButton onClick={this.handleClose} />
            </div>
          }
        </div>
      </ReactModal>)
    );
  }
}
