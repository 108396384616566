import React from 'react';
import I18n from 'i18n-js';

import { Listing } from '@reverbdotcom/commons/src/gql/graphql';
import { SanitizedRender } from '@reverbdotcom/commons';

interface IExternalProps {
  listing: Listing;
}

export default function PublishErrors({ listing }: IExternalProps) {
  const showErrors =
    listing.state === 'draft' && !listing.publishValidation?.isValid;

  if (!showErrors) return null;

  const errors = listing.publishValidation?.errors ?? [];

  return (
    <div className="seller-listing-card__errors">
      <div className="mt-1 weight-bold">
        {I18n.t('discovery.listingsManagement.sellerListingCard.draftErrors')}
      </div>
      <div className="weight-semibold ml-6">
        <ul>
          {errors.map((error) => (
            <li key={error.message}>
              <SanitizedRender html={error.message} />
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}
