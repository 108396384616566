import { flowRight, Many } from 'lodash';

/**
 * Used when the connected React component doesn't
 * have external props and receives its dependencies from
 * the composed higher order components such as GraphQL.
 */
export type EmptyExternalProps = Record<string, never>;

export function connect<
  TExternalProps extends TChildProps | EmptyExternalProps,
  TChildProps = unknown,
>(
  funcs: Array<Many<(...args: unknown[]) => unknown>>,
): (WrappedComponent: React.ComponentType<TChildProps & TExternalProps>) => React.ComponentClass<TExternalProps, unknown> {
  return flowRight(funcs);
}
