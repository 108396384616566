import React from 'react';
import classNames from 'classnames';
import I18n from 'i18n-js';

import AutoOfferModal from './auto_offer_modal';

interface IExternalProps {
  listingId: string;
  offerActive: boolean;
}

const initialModalState = {
  open: false,
};

function reducer(state, action) {
  switch (action.type) {
    case 'toggleModalOpen':
      return { ...state, open: !state.open };
  }
}

export function AutoOfferModalButton({
  listingId,
  offerActive,
}: IExternalProps) {
  const [modalState, dispatch] = React.useReducer(reducer, initialModalState);

  return (
    <div className="listing-row__action">
      <button
        className={classNames('listing-row__action__trigger', {
          'listing-row__action__trigger--not-sending': false, // || offerInvalid;
        })}
        onClick={() => dispatch({ type: 'toggleModalOpen' })}
      >
        {offerActive && (I18n.t('discovery.offers.autoOffers.autoOffersDisable'))}
        {!offerActive && (I18n.t('discovery.offers.autoOffers.autoOffersEnable'))}
      </button>
      {/* This is wrapped in modalState.open so that the AutoOfferModal queries do not fire before the modal is toggled */}
      {modalState.open && (
        <AutoOfferModal
          modalIsOpen
          listingId={listingId}
          onModalClose={() => dispatch({ type: 'toggleModalOpen' })}
        />
      )}
    </div>
  );
}

export default AutoOfferModalButton;
