import React from 'react';
import I18n from 'i18n-js';
import classNames from 'classnames';
import bind from '../bind';
import { RCIcon, RCImage } from '@reverbdotcom/cadence/components';
import { AngleLeftIcon, AngleRightIcon } from '@reverbdotcom/cadence/icons/react';

interface Props {
  images: string[];
  imgAlt?: string;
}

interface State {
  nextIndex: number;
  index: number;
  isAnimating: boolean;
  animationClass: string;
  clicked: boolean;
}

const ANIMATION_DURATION = 200;
const RIGHT = 'right';
const LEFT = 'left';

/**
 * ImageControls are hidden for screen readers until
 * we can support user submitted alt-text.
 */
function ImageControls(props: {
  hide: boolean,
  onClickLeft: (e: any) => void,
  onClickRight: (e: any) => void
}) {
  const { onClickLeft, onClickRight, hide } = props;
  if (hide) { return null; }

  return (
    <>
      <div className={`image-carousel__control-wrapper image-carousel__control-wrapper--${LEFT}`}>
        <button
          onClick={onClickLeft}
          className="image-carousel__control"
          tabIndex={-1}
          aria-label={I18n.t('commons.previousImage')}
          aria-hidden="true"
          type="button"
        >
          <RCIcon svgComponent={AngleLeftIcon} />
        </button>
      </div>
      <div className={`image-carousel__control-wrapper image-carousel__control-wrapper--${RIGHT}`}>
        <button
          onClick={onClickRight}
          className="image-carousel__control"
          tabIndex={-1}
          aria-label={I18n.t('commons.nextImage')}
          aria-hidden="true"
          type="button"
        >
          <RCIcon svgComponent={AngleRightIcon} />
        </button>
      </div>
    </>
  );
}

function CloudinaryImage(props: { image: string, alt: string }) {
  if (!props.image) { return null; }

  return (
    <RCImage
      src={props.image}
      alt={props.alt}
      key={props.image}
      overlay="offwhite"
      imageBox="auto"
      borderRadius="lg"
    />
  );
}

function ImageSlide(props: { path: string, alt: string }) {
  return (
    <div className="image-carousel__slide">
      <CloudinaryImage image={props.path} alt={props.alt} />
    </div>
  );
}

class ImageCarousel extends React.Component<Props, State> {
  static defaultProps = {
    imgAlt: '',
  };

  state = {
    nextIndex: 0,
    index: 0,
    isAnimating: false,
    animationClass: '',
    clicked: false,
  };

  @bind
  onClickLeft(e) {
    e.preventDefault();
    e.stopPropagation();

    this.scroll(LEFT);
  }

  @bind
  onClickRight(e) {
    e.preventDefault();
    e.stopPropagation();

    this.scroll(RIGHT);
  }

  getNextIndex(dir) {
    const max = this.props.images.length - 1;
    const next = dir === RIGHT ? this.state.index + 1 : this.state.index - 1;

    if (next < 0) { return max; }
    if (next > max) { return 0; }

    return next;
  }

  @bind
  scroll(dir) {
    if (this.state.isAnimating) { return; }

    const animationClass = `image-carousel--click-${dir}`;

    this.setState({
      animationClass,
      nextIndex: this.getNextIndex(dir),
      isAnimating: true,
      clicked: true,
    });

    setTimeout(
      () => {
        this.setState({
          animationClass: '',
          index: this.state.nextIndex,
          isAnimating: false,
        });
      },
      ANIMATION_DURATION,
    );
  }

  // renders a hidden div with all of the images to force the browser
  // to fetch and cache.
  renderPrefetch() {
    if (!this.state.clicked) { return false; }

    return this.props.images.map((image) => {
      return <CloudinaryImage key={image} image={image} alt={this.props.imgAlt} />;
    });
  }

  render() {
    const image = this.props.images[this.state.index];
    const nextImage = this.state.isAnimating ? this.props.images[this.state.nextIndex] : null;
    const { imgAlt } = this.props;

    return (
      <div className={classNames('image-carousel', this.state.animationClass)}>
        <div className="image-carousel__prefetch">
          {this.renderPrefetch()}
        </div>
        <div className="image-carousel__slides">
          <ImageSlide path={nextImage} alt={imgAlt} />
          <ImageSlide path={image} alt={imgAlt} />
          <ImageSlide path={nextImage} alt={imgAlt} />
        </div>
        <ImageControls
          hide={this.props.images.length <= 1}
          onClickLeft={this.onClickLeft}
          onClickRight={this.onClickRight}
        />
      </div>
    );
  }
}

export default ImageCarousel;
