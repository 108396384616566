import CurationActions from '../actions/curation_actions';
import APIRequest from '../../api_request';
import { pick, omitBy, isNull } from 'lodash';

const CURATION_PARAMS = [
  'comparison_shopping_page_id',
  'product_id',
];

function curationParams(state) {
  const allowlist = pick(state, CURATION_PARAMS);
  return omitBy(allowlist, (value) => isNull(value));
}

function curationPath(state) {
  return `/admin/api/curated_sets/${state.curated_set_id}/curate`;
}

const CurationSource = {
  fetchIndex: {
    remote(state) {
      return APIRequest.V3.get('/admin/api/curated_sets', curationParams(state));
    },

    success: CurationActions.setIndex,
    error: CurationActions.setIndexError,
  },

  curate: {
    remote(state) {
      return APIRequest.V3.post(curationPath(state), curationParams(state));
    },

    success: CurationActions.curateSuccess,
    error: CurationActions.curateError,
  },

  decurate: {
    remote(state) {
      return APIRequest.V3.destroy(curationPath(state), curationParams(state));
    },

    success: CurationActions.decurateSuccess,
    error: CurationActions.decurateError,
  },
};

export default CurationSource;
