import PropTypes from 'prop-types';
import React from 'react';
import { SanitizedRender } from '@reverbdotcom/discovery-ui';

const FULL_BALANCE_TRANSFER = 'full_balance_transfer';
const OTHER_AMOUNT_TRANSFER = 'other_amount_transfer';

class BankTransferRequestAmountFields extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      transferAmount: '',
      transferType: FULL_BALANCE_TRANSFER,
    };
  }

  onOtherAmountRadioButtonChange() {
    this.setTransferType(OTHER_AMOUNT_TRANSFER);
  }

  onWithdrawableAmountRadioButtonChange() {
    this.clearTransferAmount();
    this.setTransferType(FULL_BALANCE_TRANSFER);
  }

  onInputTextChange(event) {
    this.setTransferType(OTHER_AMOUNT_TRANSFER);
    this.setState({ transferAmount: event.target.value });
  }

  setTransferType(transferType) {
    this.setState({ transferType });
  }

  clearTransferAmount() {
    this.setState({ transferAmount: '' });
  }

  formattedAmountValue() {
    return this.props.formattedAmountValue;
  }

  withdrawableTotalAmountValue() {
    return this.props.withdrawableAmountAttributes.amount;
  }

  submitButton() {
    return (
      <button
        className="button button--primary wide mb-0"
        type="submit"
      >
        {I18n.t('js.dashboard.selling.shopPolicies.bankTransferAmountForm.submitButton')}
      </button>
    );
  }

  render() {
    return (
      <div className="g-container">
        <div className="g-col-7 g-col-mobile-12 mobile-mb-2">
          <label>
            <input
              data-withdrawable-amount-total
              name="bank_transfer_request_form[amount]"
              type="radio"
              checked={this.state.transferType === FULL_BALANCE_TRANSFER}
              value={this.withdrawableTotalAmountValue()}
              onChange={event => this.onWithdrawableAmountRadioButtonChange(event)}
            />
            <SanitizedRender
              html={I18n.t('js.dashboard.selling.shopPolicies.bankTransferAmountForm.totalTransferableBalance', { balance: this.formattedAmountValue() })}
            />
          </label>
          <div>
            <div className="inline-block">
              <label>
                <input
                  data-other-amount-total
                  type="radio"
                  name="bank_transfer_request_form[amount]"
                  value={this.state.transferAmount}
                  checked={this.state.transferType === OTHER_AMOUNT_TRANSFER}
                  onChange={event => this.onOtherAmountRadioButtonChange(event)}
                />
                {I18n.t('js.dashboard.selling.shopPolicies.bankTransferAmountForm.otherAmount')}
              </label>
            </div>
            <div className="inline-block ml-1">
              <input
                className="mb-0"
                type="text"
                value={this.state.transferAmount}
                onClick={event => this.onInputTextChange(event)}
                onChange={event => this.onInputTextChange(event)}
              />
            </div>
          </div>
        </div>
        <div className="g-col-5 g-col-mobile-12">
          {this.submitButton()}
        </div>
      </div>
    );
  }
}

BankTransferRequestAmountFields.propTypes = {
  withdrawableAmountAttributes: PropTypes.object.isRequired,
  formattedAmountValue: PropTypes.string.isRequired,
};

export default BankTransferRequestAmountFields;
