import React from 'react';

interface IProps {
  imageSrc: string;
  imageAlt: string;
  children: React.ReactNode;
}

export function SellFormCallout({ imageSrc, imageAlt, children }: IProps) {
  return (
    <div className="sell-form-callout">
      <div className="sell-form-callout__content">
        {imageSrc && (
          <div className="sell-form-callout__info__icon__container">
            <img
              src={imageSrc}
              alt={imageAlt}
              className="sell-form-callout__info__icon"
            />
          </div>
        )}
        <div className="sell-form-callout__info__content">
          {children}
        </div>
      </div>
    </div>
  );
}

export default SellFormCallout;
