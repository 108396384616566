import React from 'react';
import I18n from 'i18n-js';

import { useUser } from '../../user_hooks';
import CoreLink from '../../components/core_link';
import { Paths } from '../../url_helpers';
import CMSSearchInputGroup from '../../components/cms_search_input_group';
import RecentSearches from '../../components/recent_searches';
import { UK } from '../../constants';
import { randInt } from '../../random';
import { useViewTracking } from '../../use_tracking';
import { MParticleEventName } from '../../elog/mparticle_types';
import { DEFAULT_SEARCH_HEADER } from '../components/site_banner_hero';

export const DEFAULT_URL = Paths.marketplace.expand({});
export const DEFAULT_PLACEHOLDER = I18n.t('commons.searchHeader.placeholder');

export const DEFAULT_IMAGE = 'https://rvb-img.reverb.com/image/upload/s--ygGlXocJ--/c_scale,q_auto:good,w_1600/v1579193425/HomepageHero-1_gr0del.jpg';
const DEFAULT_SECONDARY_IMAGE = 'https://rvb-img.reverb.com/image/upload/s--Q2aQifT7--/c_scale,q_auto:eco,w_800/v1579193425/01022020-Homepage1_ji19vh.jpg';

export const DEFAULT_UK_IMAGE = 'https://res.cloudinary.com/reverb-marketing/image/upload/v1634573194/20210806_UK-homepage_v3_02-UK-homepage-desktop.jpg';
const DEFAULT_UK_SECONDARY_IMAGE = 'https://res.cloudinary.com/reverb-marketing/image/upload/v1634573194/20210806_UK-homepage_v3_02-UK-homepage-mobile.jpg';

export const ROTATING_IMAGES = [
  'https://res.cloudinary.com/reverb-marketing/image/upload/v1641488921/Header1.jpg',
  'https://res.cloudinary.com/reverb-marketing/image/upload/v1641488921/Header2.jpg',
  'https://res.cloudinary.com/reverb-marketing/image/upload/v1641488921/Header3.jpg',
  'https://res.cloudinary.com/reverb-marketing/image/upload/v1641488921/Header4.jpg',
  'https://res.cloudinary.com/reverb-marketing/image/upload/v1641488921/Header5.jpg',
];

export const ROTATING_SECONDARY_IMAGES = [
  'https://res.cloudinary.com/reverb-marketing/image/upload/v1641488921/Mobile1.jpg',
  'https://res.cloudinary.com/reverb-marketing/image/upload/v1641488921/Mobile2.jpg',
  'https://res.cloudinary.com/reverb-marketing/image/upload/v1641488922/Mobile3.jpg',
  'https://res.cloudinary.com/reverb-marketing/image/upload/v1641488921/Mobile4.jpg',
  'https://res.cloudinary.com/reverb-marketing/image/upload/v1641488921/Mobile5.jpg',
];

interface IProps {
  title?: string;
  subtitle?: string;
  imageUrl?: string;
  secondaryImageUrl?: string;
  searchPlaceholder?: string;
  actionText?: string;
  actionUrl?: string;
  renderSecondaryImage?: boolean;
  rotatingImages?: boolean;
  trackSearchFn?: (query: string) => void;
  shouldTrackViewEvent?: boolean;
}

export function SearchHeader(props: IProps) {
  const viewEvent = {
    eventName: MParticleEventName.ComponentView,
    componentName: DEFAULT_SEARCH_HEADER,
  };

  useViewTracking(viewEvent, !!props.shouldTrackViewEvent);

  return (
    <div className="search-header">
      <div className="search-header__inner">
        <BackgroundImage {...props} />
        <div className="search-header__text">
          <h2>
            {props.subtitle || I18n.t('commons.searchHeader.subtitle')}
          </h2>
          <h1>
            {props.title || I18n.t('commons.searchHeader.title')}
          </h1>
          <div className="search-header__input">
            <Action {...props} />
          </div>
          <RecentSearches />
        </div>
      </div>
    </div>
  );
}

function BackgroundImage(props: IProps) {
  const user = useUser();
  const imgIndex = props.rotatingImages ? randInt(user.requestId, ROTATING_IMAGES.length) : null;
  const showUKdefaultImg = user.countryCode === UK;

  if (props.secondaryImageUrl || props.renderSecondaryImage || props.rotatingImages) {
    return (
      <>
        <div
          className="search-header__bg-image search-header__bg-image--desktop"
          style={getPrimaryBackgroundImage(props, imgIndex, showUKdefaultImg)}
        />
        <div
          className="search-header__bg-image search-header__bg-image--mobile"
          style={getMobileBackgroundImage(props, imgIndex, showUKdefaultImg)}
        />
      </>
    );
  }
  return (
    <div className="search-header__bg-image"
      style={getPrimaryBackgroundImage(props, imgIndex, showUKdefaultImg)}
    />
  );
}

function getPrimaryBackgroundImage(props: IProps, imgIndex: number, showUKdefaultImg: boolean) {
  if (showUKdefaultImg) {
    return { backgroundImage: `url('${props.imageUrl || DEFAULT_UK_IMAGE}')` };
  }

  if (imgIndex !== null) {
    return getBackgroundImageStyle(ROTATING_IMAGES[imgIndex]);
  }

  return { backgroundImage: `url('${props.imageUrl || DEFAULT_IMAGE}')` };
}

function getMobileBackgroundImage(props: IProps, imgIndex: number, showUKdefaultImg: boolean) {
  if (showUKdefaultImg) {
    return { backgroundImage: `url('${props.secondaryImageUrl || DEFAULT_UK_SECONDARY_IMAGE}')` };
  }

  if (imgIndex !== null) {
    return getBackgroundImageStyle(ROTATING_SECONDARY_IMAGES[imgIndex]);
  }

  return getBackgroundImageStyle(props.secondaryImageUrl || DEFAULT_SECONDARY_IMAGE);
}

function getBackgroundImageStyle(src) {
  return { backgroundImage: `url('${src}')` };
}

function Action(props: IProps) {
  if (!props.actionText) {
    return (
      <CMSSearchInputGroup
        actionUrl={props.actionUrl || DEFAULT_URL}
        placeholder={props.searchPlaceholder || I18n.t('commons.searchHeader.placeholder')}
        trackSearchFn={props.trackSearchFn}
        largeInput
      />
    );
  }

  return (
    <CoreLink
      to={props.actionUrl || DEFAULT_URL}
      className="button button--large"
    >
      {props.actionText}
    </CoreLink>
  );
}

export default SearchHeader;
