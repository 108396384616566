/* eslint-disable no-restricted-globals */
import React from 'react';
import { reverb_feed_FeedResponse, reverb_search_SearchResponse } from '../gql/graphql';
import RQLSearchOverview from './rql_search_overview';
import RQLPagination from './rql_pagination';
import { Filter } from './search_filters';
import { Location } from 'history';
import I18n from 'i18n-js';
import { UpdateURLProvider, useUpdateUrl } from '@reverbdotcom/commons/src/components/with_update_url';
import { defaultUpdateLocation } from '../update_params';
import { InputChipFilterOption } from './grid_row_with_input_chips';
import RQLSearchOverviewWithExposedSidebar from './rql_search_overview_with_exposed_sidebar';
import RQLLoadMoreButton from './rql_load_more_button';

interface IProps {
  error?: boolean;
  loading?: boolean;
  search: reverb_search_SearchResponse | reverb_feed_FeedResponse;
  location: Location;
  hasPagination?: boolean;
  hasInfiniteScroll?: boolean;
  hasSearchOverview?: boolean;
  hideFilters?: boolean;
  showSimilarListingsMessage?: boolean;
  cards: React.ReactNode; // this should be a <Tiles> component of cards
  fallbackCards?: React.ReactNode;
  lowResultsAlert?: React.ReactNode;
  subqueryRows?: React.ReactNode;
  followButton?: React.ReactNode;
  followBanner?: React.ReactNode;
  aboveGridBanner?: React.ReactNode; // Ad Placement Banner
  relatedSearchRow?: React.ReactNode; // MarketplaceCSPRow
  secondaryRelatedSearchRow?: React.ReactNode;
  tipText?: string | React.ReactNode;
  hideClearFiltersButton?: boolean;
  filters?: Filter[];
  totalText?: string; // Used for creating the grid title
  titleOverride?: string;
  useH1Title?: boolean;
  resultsTotal: number;
  query?: string;
  trackingQuery?: string; // Passed down to facets
  setListViewState?: (val: boolean) => void;
  showListViewToggle?: boolean;
  pinTextQuery?: boolean; // ensure search bar always visible above grid, instead of buried within filters
  toggleExposedSidebarOpen?: () => void;
  showNewMobileSidebar?: boolean;
  useExposedSidebar?: boolean;
  hideGridTitle?: boolean;
  isFilterOpenByDefault?: (filter: Filter) => boolean;
  modifyRemovableFilterChips?: (filterChips: InputChipFilterOption[]) => InputChipFilterOption[];
  clearFiltersUrl?: string;
  loadMoreResults?: () => void;
  loadMoreButtonTitle?: string;
  invertFiltersAndCountOrder?: boolean;
}

export default function RQLSearchGrid(props: IProps) {
  const {
    search,
    showSimilarListingsMessage,
    aboveGridBanner = undefined,
    hasPagination = false,
    hasInfiniteScroll = false,
    hasSearchOverview = false,
    hideFilters = false,
    relatedSearchRow = null,
    error = false,
    trackingQuery = '',
    location = null,
    showListViewToggle = false,
    hideClearFiltersButton = false,
    pinTextQuery = false,
    totalText,
    titleOverride,
    resultsTotal,
    tipText,
    loading,
    followButton,
    setListViewState,
    useH1Title = false,
    query,
    lowResultsAlert,
    cards,
    secondaryRelatedSearchRow,
    followBanner,
    subqueryRows,
    fallbackCards,
    hideGridTitle = false,
    toggleExposedSidebarOpen,
    showNewMobileSidebar = false,
    useExposedSidebar = false,
    isFilterOpenByDefault = undefined,
    modifyRemovableFilterChips = undefined,
    clearFiltersUrl = undefined,
    loadMoreResults = undefined,
    loadMoreButtonTitle = '',
    invertFiltersAndCountOrder = false,
  } = props;
  const { updateUrl } = useUpdateUrl();
  const filters = props.filters || search?.filters || [];
  return (
    <UpdateURLProvider updateUrl={updateUrl ?? defaultUpdateLocation}>
      <div className={`faceted-grid faceted-grid--wide ${invertFiltersAndCountOrder ? 'faceted-grid--shorter' : ''}`}>
        {aboveGridBanner}
        <div className="faceted-grid__inner">
          <div className="faceted-grid__main">
            {showSimilarListingsMessage &&
            <div className="pb-2">
              <h1 className="size-140">{I18n.t('discovery.similarListingsMessage.title')}</h1>
            </div>
            }
            {relatedSearchRow}
            {hasSearchOverview && !error && !useExposedSidebar &&
            <RQLSearchOverview
              filters={filters}
              hideFilters={hideFilters}
              followButton={followButton}
              totalText={totalText}
              titleOverride={titleOverride}
              resultsTotal={resultsTotal}
              tipText={tipText}
              loading={loading}
              trackingQuery={trackingQuery}
              location={location}
              showListViewToggle={showListViewToggle}
              setListViewState={setListViewState}
              hideClearFiltersButton={hideClearFiltersButton}
              useH1Title={useH1Title}
              query={query}
              pinTextQuery={pinTextQuery}
              hideGridTitle={hideGridTitle}
              toggleExposedSidebarOpen={toggleExposedSidebarOpen}
              showNewMobileSidebar={showNewMobileSidebar}
              isFilterOpenByDefault={isFilterOpenByDefault}
              modifyRemovableFilterChips={modifyRemovableFilterChips}
              clearFiltersUrl={clearFiltersUrl}
              invertFiltersAndCountOrder={invertFiltersAndCountOrder}
            />
            }
            {hasSearchOverview && !error && useExposedSidebar &&
              <RQLSearchOverviewWithExposedSidebar
                filters={filters}
                followButton={followButton}
                totalText={totalText}
                titleOverride={titleOverride}
                resultsTotal={resultsTotal}
                tipText={tipText}
                loading={loading}
                trackingQuery={trackingQuery}
                location={location}
                showListViewToggle={showListViewToggle}
                setListViewState={setListViewState}
                useH1Title={useH1Title}
                query={query}
                pinTextQuery={pinTextQuery}
                toggleExposedSidebarOpen={toggleExposedSidebarOpen}
              />
            }
            {lowResultsAlert}
            {cards}
            {hasPagination && !error && search &&
            <RQLPagination
              limit={search.limit}
              total={search.total}
              offset={search.offset}
            />
            }
            {hasInfiniteScroll && loadMoreResults && !error && search && !loading &&
            <RQLLoadMoreButton
              offset={search.offset}
              limit={search.limit}
              total={search.total}
              loadMoreHandler={loadMoreResults}
              title={loadMoreButtonTitle}
            />
            }
            {secondaryRelatedSearchRow}
            {!loading && followBanner}
            {subqueryRows}
            {fallbackCards}
          </div>
        </div>
      </div>
    </UpdateURLProvider>
  );
}
