import React from 'react';
import USBankFields from './us_bank_fields';
import CanadianBankFields from './canadian_bank_fields';

interface Props {
  inputNamePrefix: string;
  countryCode: string;
  onCountryChange(countryCode: string): void;
  onCurrencyChange(currencyCode: string): void;
  selectedPayoutCurrency: string;
  shopCurrency: string;
}

const COUNTRY_CODES = ['US', 'CA'];

export default function BankAccountFields(props: Props) {
  if (props.countryCode === 'US') {
    return (
      <USBankFields
        inputNamePrefix={props.inputNamePrefix}
        onCountryChange={props.onCountryChange}
        countryCodes={COUNTRY_CODES}
        shopCurrency={props.shopCurrency}
      />
    );
  }

  return (
    <CanadianBankFields
      inputNamePrefix={props.inputNamePrefix}
      onCountryChange={props.onCountryChange}
      onCurrencyChange={props.onCurrencyChange}
      countryCodes={COUNTRY_CODES}
      payoutCurrency={props.selectedPayoutCurrency}
      shopCurrency={props.shopCurrency}
    />
  );
}
