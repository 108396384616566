import React, { useMemo } from 'react';
import I18n from 'i18n-js';

import FormGroupWithInput from '@reverbdotcom/commons/src/components/form_group_with_input';

import {
  core_apimessages_Country as Country,
  core_apimessages_AddressEntry as IAddressEntry,
} from '@reverbdotcom/commons/src/gql/graphql';
import CreditCardEncryptedInput from './credit_card_encrypted_input';
import CreditCardEncryptedDateInput from './credit_card_encrypted_date_input';
import { AdyenCardType } from '@reverbdotcom/commons/src/adyen';
import { useAdyenCard, CardState } from '@reverbdotcom/commons/src/adyen_card_hook';
import AddressFormFields from './address_form_fields';

const COMPONENT_NAME = 'BillingMethodNewCardFormFields';

export interface BillingMethodNewCardFormProps {
  setCardholderName(cardholderName: string): void;
  setCardState(data: CardState): void;
  setBillingCardAddress(data: IAddressEntry): void;
  cardholderName: string;
  billingCardAddress: IAddressEntry;
  countries: Country[];
}

interface Props extends BillingMethodNewCardFormProps {
  adyenCardFieldsName?: string;
}

export default function BillingMethodNewCardFormFields(props: Props) {
  const cardFieldsId = useMemo(() => {
    return props.adyenCardFieldsName || 'card-fields';
  }, [ props.adyenCardFieldsName ]);

  const { cardState, focus, errors } = useAdyenCard({
    selector: `#${cardFieldsId}`,
    cardType: AdyenCardType.Card,
  });

  const { setCardState } = props;
  const { paymentMethod } = cardState.data;

  React.useEffect(() => {
    setCardState(cardState);
  }, [cardState, setCardState]);

  return (
    <div>
      <FormGroupWithInput
        inputName="cardholderName"
        label={I18n.t('discovery.creditCardForm.nameOnCard')}
        value={props.cardholderName}
        updateField={(field) => props.setCardholderName(field.cardholderName)}
        fieldName="cardholderName"
        required
      />
      <div
        id={cardFieldsId}
        className="g-container"
      >
        <div className="g-col-12 scaling-mb-4">
          <CreditCardEncryptedInput
            inputName="cardNumber"
            inputValue={paymentMethod.encryptedCardNumber}
            cseName="encryptedCardNumber"
            label={I18n.t('discovery.creditCardForm.cardNumber')}
            errors={errors}
            focus={focus}
          />
        </div>
        <div className="g-col-6 g-col-mobile-12 scaling-mb-4">
          <CreditCardEncryptedDateInput
            expiryMonthInputName="expiryMonth"
            expiryYearInputName="expiryYear"
            paymentMethod={paymentMethod}
            label={I18n.t('discovery.creditCardForm.expiration')}
            errors={errors}
            focus={focus}
          />
        </div>
        <div className="g-col-6 g-col-mobile-12 scaling-mb-4">
          <CreditCardEncryptedInput
            inputName="securityCode"
            inputValue={paymentMethod.encryptedSecurityCode}
            label={I18n.t('discovery.creditCardForm.cvv')}
            cseName="encryptedSecurityCode"
            errors={errors}
            focus={focus}
          />
        </div>
      </div>
      <AddressFormFields
        address={props.billingCardAddress}
        countries={props.countries}
        updateField={props.setBillingCardAddress}
        useCamelizedFieldsForRQL
        trackingComponentName={COMPONENT_NAME}
      />
    </div>
  );
}
