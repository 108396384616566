import { startCase } from 'lodash';
import React from 'react';

import FormGroupWithCheckbox from '@reverbdotcom/commons/src/components/form_group_with_checkbox';
import FormGroupWithInput from '@reverbdotcom/commons/src/components/form_group_with_input';
import FormGroupWithSelect from '@reverbdotcom/commons/src/components/form_group_with_select';
import CSRFForm from '@reverbdotcom/commons/src/csrf_form';

interface BrazeMailer {
  class: {};
  optional_params?: string[];
}

interface BrazeMailers {
  [key: string]: BrazeMailer;
}

interface IProps {
  brazeMailers: BrazeMailers;
}

export default function AdminBrazeEmailPreviewForm({ brazeMailers }: IProps) {
  const [emailName, setEmailName] = React.useState('');
  const [recipientId, setRecipientId] = React.useState(undefined);
  const [optionalInputValues, setOptionalInputValues] = React.useState({});
  const optionalInputs = brazeMailers[emailName]?.optional_params;

  const [overrideRecipient, setOverrideRecipient] = React.useState(false);
  const [overrideRecipientLitmus, setOverrideRecipientLitmus] = React.useState(false);
  const [overrideRequester, setOverrideRequester] = React.useState(false);

  function selectFields() {
    return Object.keys(brazeMailers).map(mailerName => ({
      text: mailerName,
      value: mailerName,
    }));
  }

  function initialOptionalInputValues(mailerName) {
    const optionalParams = brazeMailers[mailerName]?.optional_params;

    if (optionalParams) {
      return optionalParams.reduce((acc, optionalInput) => {
        acc[optionalInput] = '';
        if (optionalInput === 'initial_or_reminder') { acc[optionalInput] = '0'; }

        return acc;
      }, {});
    }

    return {};
  }

  const optionalInputField = (optionalInput) => {
    let component = null;
    switch (optionalInput) {
      case 'initial_or_reminder':
        component = optionalInputFieldSelect(optionalInput, [
          { text: 'Initial', value: '0' },
          { text: 'Reminder', value: '1' },
        ]);
        break;
      default:
        component = optionalInputFieldText(optionalInput);
    }

    return component;
  };

  const optionalInputFieldSelect = (optionalInput, options) => (
    <FormGroupWithSelect
      key={optionalInput}
      inputName={optionalInput}
      label={startCase(optionalInput)}
      options={options}
      value={optionalInputValues[optionalInput]}
      updateField={
        (fieldUpdate) => {
          setOptionalInputValues({ ...optionalInputValues, ...fieldUpdate });
        }
      }
      tagOptional
    />
  );

  const optionalInputFieldText = (optionalInput, value = undefined) => (
    <FormGroupWithInput
      key={optionalInput}
      inputName={optionalInput}
      label={startCase(optionalInput)}
      value={value || optionalInputValues[optionalInput]}
      updateField={
        (fieldUpdate) => {
          setOptionalInputValues({ ...optionalInputValues, ...fieldUpdate });
        }
      }
      tagOptional
    />
  );

  return (
    <div className="width-75 mtb-6">
      <CSRFForm
        method="POST"
        action="/admin/braze_trigger_preview_emails"
      >
        <FormGroupWithSelect
          inputName="braze_email_name"
          label="Braze Email"
          options={selectFields()}
          value={emailName}
          updateField={
            (fieldUpdate) => {
              setEmailName(fieldUpdate.braze_email_name);
              setOptionalInputValues(initialOptionalInputValues(fieldUpdate.braze_email_name));

              setOverrideRecipient(false);
              setOverrideRecipientLitmus(false);
              setOverrideRequester(false);
            }
          }
          addEmptyOption
          required
          tagRequired
        />

        {!!optionalInputs && (
          optionalInputs.map(optionalInput => optionalInputField(optionalInput))
        )}

        {!!emailName && (
          <FormGroupWithCheckbox
            inputName="requester_id_override"
            label="Override Requester"
            checked={overrideRequester}
            updateField={
              () => {
                setOverrideRequester(!overrideRequester);
              }
            }
            tagOptional
          />
        )}
        {!!overrideRequester && (
          <FormGroupWithInput
            inputName="requester_id"
            label="Generate For"
            helpText="User ID or Email"
            value=""
            tagOptional
          />
        )}
        {!!emailName && !overrideRecipientLitmus && (
          <FormGroupWithCheckbox
            inputName="recipient_id_override"
            label="Override Recipient"
            checked={overrideRecipient}
            updateField={
              () => {
                setOverrideRecipientLitmus(false);
                setOverrideRecipient(!overrideRecipient);
              }
            }
            tagOptional
          />
        )}
        {!!overrideRecipient && (
          <FormGroupWithInput
            inputName="recipient_id"
            label="Deliver To"
            helpText="User ID or Email. Email MUST have a reverb.com domain."
            value={recipientId}
            tagOptional
          />
        )}
        {!!emailName && !overrideRecipient && (
          <FormGroupWithCheckbox
            inputName="recipient_override_litmus"
            label="Deliver To Litmus"
            checked={overrideRecipientLitmus}
            updateField={
              () => {
                setOverrideRecipient(false);
                setOverrideRecipientLitmus(!overrideRecipientLitmus);

                setRecipientId('-1');
              }
            }
            tagOptional
          />
        )}

        <button
          className="button button--primary"
          type="submit"
          value="Submit"
        >
          Submit
        </button>
      </CSRFForm>
    </div>
  );
}
