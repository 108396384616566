import I18n from 'i18n-js';
import React, { useRef, useContext, useState } from 'react';

import { isZeroAmount } from '@reverbdotcom/commons/src/money';
import { RCRadioCardGroup, RCAlertBox, RCTextWithIcon } from '@reverbdotcom/cadence/components';
import { I18N as Translate } from '@reverbdotcom/commons/src/components/translate';
import { ExclamationTriangleIcon } from '@reverbdotcom/cadence/icons/react';
import { US_CON, US } from '@reverbdotcom/commons/src/constants';
import { FormCards } from '@reverbdotcom/commons/src/components/FormCards';
import { core_apimessages_ShippingLabelPackage } from '@reverbdotcom/commons/src/gql/graphql';

import { Field, ActionType } from '../../sellFormReducerTypes';
import { SellFormContext } from '../../SellFormContext';
import EditShippingRateCardManualPrices from '../../../../shared/edit_shipping_rate_card_manual_prices';
import { DomesticShippingRadioLabel } from './DomesticShippingRadioLabel';
import CarrierCalculatedPackageFormContainer from './CarrierCalculatedPackageFormContainer';
import { carrierCalculatedPackageValidator } from './carrierCalculatedPackageValidator';
import {
  upsertShippingRate,
  BLANK_RATE_ATTRS,
  buildBlankShippingRate,
  FREE_RATE_ATTRS,
  CARRIER_CALC_RATE_TYPE,
} from './ShippingForm';

export enum ShippingMode {
  CUSTOM = 'custom', // user manually inputs a standard shipping price (with optional combine & save price)
  CARRIER_CALCULATED = 'carrierCalculated', // user inputs package dimensions, reverb figures out the shipping cost
  FREE = 'free', // user selects simple free shipping (no combine + save; no user input required)
}

function getLocalizedRegionName(regionCode: string): string {
  if (regionCode === US_CON) {
    return I18n.t('discovery.sellForm.shippingSection.domesticShippingCard.theContinentalUs');
  } else {
    return I18n.t(`discovery.shippingRegions.${regionCode}`);
  }
}

function updateCarrierCalculatedPackage(
  updatedPackage: core_apimessages_ShippingLabelPackage,
  dispatch: React.Dispatch<any>,
) {
  const carrierCalculatedPackageValidatorResponse = carrierCalculatedPackageValidator(updatedPackage);
  const hasValidSelectedCarrier = !carrierCalculatedPackageValidatorResponse?.errorTranslationKey &&
    carrierCalculatedPackageValidatorResponse?.carrier;
  const allowedCarriers = hasValidSelectedCarrier ? [carrierCalculatedPackageValidatorResponse?.carrier] : null;

  dispatch({
    type: ActionType.UPDATE_FIELD,
    fieldKey: Field.CARRIER_CALCULATED_PACKAGE,
    value: { ...updatedPackage, ...{ allowedCarriers } },
  });
}

function DomesticShippingCard() {
  const { listingEntry, dispatch, shopConfig, loading } = useContext(SellFormContext);

  const { currencySetting, shippingRegionSettings, address } = shopConfig;
  const domesticRegion = shippingRegionSettings.find(({ isDomestic }) => isDomestic);
  const inCarrierCalculatedMode = !!listingEntry[Field.CARRIER_CALCULATED_PACKAGE];
  const shippingRate = listingEntry[Field.SHIPPING_RATES].find((sr) => sr.regionCode === domesticRegion.regionCode);
  const localizedRegionName = getLocalizedRegionName(domesticRegion.regionCode);
  const shopAddressSummary = I18n.t(
    'discovery.sellForm.shippingSection.domesticShippingCard.shopAddressSummary',
    { regionName: address.region, postalCode: address.postalCode },
  );
  const headerRef = useRef<HTMLInputElement>();
  const [touchedRadio, setTouchedRaio] = useState(!!shippingRate?.rate?.amount);
  const [isFreeModeSelected, setIsFreeModeSelected] = useState(
    isZeroAmount(shippingRate?.rate?.amount) &&
    !shippingRate?.incrementalRate?.amount &&
    !shippingRate?.expeditedRate?.amount,
  );

  const shippingModes = [
    domesticRegion.allowedToShipCarrierCalculated && ShippingMode.CARRIER_CALCULATED,
    ShippingMode.FREE,
    ShippingMode.CUSTOM,
  ].filter(Boolean);

  let currentMode;
  if (domesticRegion.allowedToShipCarrierCalculated && inCarrierCalculatedMode) {
    currentMode = ShippingMode.CARRIER_CALCULATED;
  } else if (isFreeModeSelected) {
    currentMode = ShippingMode.FREE;
  } else if (shippingRate?.rate?.amount || touchedRadio) {
    currentMode = ShippingMode.CUSTOM;
  }

  function toggleCarrierCalculated() {
    if (inCarrierCalculatedMode) {
      dispatch({
        type: ActionType.DEACTIVATE_CARRIER_CALCULATED_MODE,
        shippingRates: upsertShippingRate(listingEntry[Field.SHIPPING_RATES], buildBlankShippingRate(shippingRate.regionCode)),
      });
    } else {
      dispatch({
        type: ActionType.ACTIVATE_CARRIER_CALCULATED_MODE,
        shippingRates: upsertShippingRate(listingEntry[Field.SHIPPING_RATES], buildBlankShippingRate(shippingRate.regionCode, CARRIER_CALC_RATE_TYPE)),
      });
    }
  }

  function handleModeChange(newMode) {
    setTouchedRaio(true);
    switch (newMode) {
      case currentMode:
        break;

      case ShippingMode.CUSTOM:
        if (inCarrierCalculatedMode) { toggleCarrierCalculated(); }
        if (isFreeModeSelected) {
          setIsFreeModeSelected(false);
          dispatch({
            type: ActionType.UPDATE_FIELD,
            fieldKey: Field.SHIPPING_RATES,
            value: upsertShippingRate(listingEntry[Field.SHIPPING_RATES], { ...shippingRate, ...BLANK_RATE_ATTRS }),
          });
        }
        break;

      case ShippingMode.CARRIER_CALCULATED:
        toggleCarrierCalculated();
        setIsFreeModeSelected(false);
        break;

      case ShippingMode.FREE:
        if (inCarrierCalculatedMode) { toggleCarrierCalculated(); }
        setIsFreeModeSelected(true);
        dispatch({
          type: ActionType.UPDATE_FIELD,
          fieldKey: Field.SHIPPING_RATES,
          value: upsertShippingRate(listingEntry[Field.SHIPPING_RATES], { ...shippingRate, ...FREE_RATE_ATTRS }),
        });
        break;

      default:
        break;
    }
  }

  // Edge case warning for persisted listings that - for historical reasons - don't have a domestic rate.
  if (!shippingRate) {
    return (
      <FormCards.Card>
        <RCAlertBox type="warning" small>
          <RCTextWithIcon svgComponent={ExclamationTriangleIcon} placement="left">
            <Translate
              text="discovery.sellForm.shippingSection.domesticShippingCard.missingDomesticWarning"
              args={{ regionName: localizedRegionName }}
            >
              <button
                className="button-as-link ml-1"
                type="button"
                onClick={() => {
                  dispatch({
                    type: ActionType.UPDATE_FIELD,
                    fieldKey: Field.SHIPPING_RATES,
                    value: upsertShippingRate(listingEntry[Field.SHIPPING_RATES], buildBlankShippingRate(domesticRegion.regionCode)),
                  });
                }}
              >
                {I18n.t('discovery.sellForm.shippingSection.domesticShippingCard.missingDomesticButtonText')}
              </button>
            </Translate>
          </RCTextWithIcon>
        </RCAlertBox>
      </FormCards.Card>
    );
  }

  return (
    <FormCards.Card>
      <div ref={headerRef} />
      <FormCards.FormSection
        tag="required"
        title={I18n.t('discovery.sellForm.shippingSection.domesticShippingCard.header', { regionName: localizedRegionName })}
        fullWidthForm
      >
        <RCRadioCardGroup
          label=""
          id={`howhandle_${shippingRate.regionCode}`}
          name={`howhandle_${shippingRate.regionCode}`}
          value={currentMode}
          onChange={(evt) => handleModeChange(evt.target.value)}
          required
        >
          {shippingModes.map(mode => (
            <RCRadioCardGroup.Option
              key={mode}
              label={<DomesticShippingRadioLabel shippingMode={mode} />}
              sublabel={
                <DomesticShippingRadioLabel.Sublabel
                  shippingMode={mode}
                  isSelected={mode === currentMode}
                  shopAddressSummary={shopAddressSummary}
                />
              }
              value={mode}
            >
              {mode === currentMode && currentMode === ShippingMode.CARRIER_CALCULATED && (
                <div className="domestic-shipping-card__radio__content__wrapper">
                  <CarrierCalculatedPackageFormContainer
                    packageEntry={listingEntry[Field.CARRIER_CALCULATED_PACKAGE]}
                    setPackageEntry={(updatedPackage) => {
                      updateCarrierCalculatedPackage(
                        updatedPackage,
                        dispatch,
                      );
                    }}
                    setIsInCarrierCalculatedMode={() => {
                      toggleCarrierCalculated();
                      headerRef.current.scrollIntoView();
                    }}
                    disabled={loading}
                  />
                </div>
              )}

              {mode === currentMode && currentMode === ShippingMode.CUSTOM && (
                <div className="domestic-shipping-card__radio__content__wrapper">
                  <EditShippingRateCardManualPrices
                    shippingRate={shippingRate}
                    handleEditShippingRate={(updated) => {
                      dispatch({
                        type: ActionType.UPDATE_FIELD,
                        fieldKey: Field.SHIPPING_RATES,
                        value: upsertShippingRate(listingEntry[Field.SHIPPING_RATES], updated),
                      });
                    }}
                    currency={currencySetting}
                    allowedToShipExpedited={false}
                    disabled={loading}
                    displayShippingEstimator={address.countryCode === US}
                  />
                </div>
              )}

              {/* Note: the ShippingMode.Free option does not render any inner content when selected */}
            </RCRadioCardGroup.Option>
          ))}
        </RCRadioCardGroup>
      </FormCards.FormSection>
    </FormCards.Card>
  );
}
export default DomesticShippingCard;
