export default {
  haveAccount: 'Already have an account?',
  linkAccount: 'Sign Up or Link Account',
  getShippingLabel: 'Get Packlink Label',
  connectingToPacklink: 'Connecting to Packlink...',
  continueToPacklink: 'Continue to Packlink',
  draftSuccess: 'A draft shipping label has successfully been created on Packlink.',
  viewOrEditLabel: 'View / Edit Label',
  signIn: 'Sign In',
  needAccount: "Don't have an account yet?",
  signUp: 'Sign Up',
  editShippingLabel: 'Edit Shipping Label',
  login: {
    header: 'Login to Packlink Pro',
    email: 'Email',
    password: 'Password',
    submit: 'Login',
    countryCode: 'Country',
    countryCodes: {
      GB: 'United Kingdom',
      AT: 'Austria',
      BE: 'Belgium',
      DE: 'Germany',
      ES: 'Spain',
      FR: 'France',
      IE: 'Ireland',
      IT: 'Italy',
      NL: 'Netherlands',
      PT: 'Portugal',
      UN: 'Unspecified',
    },
  },
  account: {
    registrationFailed: 'Linking to Packlink account failed.',
    loginFailed: 'Linking to Packlink account failed. Please check your email and password. If you\'ve confirmed your email and password are correct, please try changing Country to "Unspecified" or to the country in which you initially created your Packlink account.',
  },
  register: {
    header: 'Sign Up for Packlink Pro',
    email: 'Email',
    password: 'Password',
    passwordTip: '(at least {{minLength}} characters)',
    submit: 'Create Packlink Account',
    dataProcessing: 'I agree to allow Packlink to store and process my data',
    marketingCalls: 'Receive Packlink commercial calls',
    marketingEmails: 'Receive Packlink promotional e-mails',
    termsAndConditions: "I accept Packlink's {{termsLinkOpen}}Terms & Conditions{{termsLinkClose}} and {{privacyLinkOpen}}Privacy Policy{{privacyLinkClose}}",
  },
  infoAlert: {
    saveMoney: 'Save up to 70% on label costs.',
    saveTime: 'Print labels at home and schedule carrier pickups.',
    protectShipment: 'Protect your shipment with Packlink Insurance.',
  },
};
