import PropTypes from 'prop-types';
import React from 'react';
import { APIRequest } from '@reverbdotcom/discovery-ui';
import CheckoutLoadingIndicator from '@reverbdotcom/discovery-ui/src/components/checkout_loading_indicator';

const DELAY_MILLISECONDS = 1000;

class AsyncLoader extends React.Component {
  constructor(props) {
    super(props);
    this.handleResponse = this.handleResponse.bind(this);
    this.delayedCheckStatus = this.delayedCheckStatus.bind(this);
    this.checkStatus = this.checkStatus.bind(this);
  }

  componentDidMount() {
    this.delayedCheckStatus();
  }

  delayedCheckStatus() {
    this.window.setTimeout(this.checkStatus, DELAY_MILLISECONDS);
  }

  checkStatus() {
    APIRequest.get(this.props.statusPollingPath).then(this.handleResponse).fail(this.delayedCheckStatus);
  }

  handleResponse(response) {
    if (response.complete) {
      this.window.location.href = response.redirect_path;
    } else {
      this.delayedCheckStatus();
    }
  }

  get window() {
    // fake window for testing
    return (this.props.window || window);
  }

  render() {
    return (
      <CheckoutLoadingIndicator
        title={this.props.descriptionText}
      />
    );
  }
}

AsyncLoader.propTypes = {
  statusPollingPath: PropTypes.string.isRequired,
  descriptionText: PropTypes.string,
  window: PropTypes.object,
};

export default AsyncLoader;
