import React from 'react';
import I18n from 'i18n-js';
import { isNil } from 'lodash';

interface Props {
  count: number;
}

export default class Count extends React.Component<Props, null> {
  render() {
    if (isNil(this.props.count)) {
      return null;
    }

    return (
      <div className="facet__option__count">
        ({I18n.toNumber(this.props.count, { precision: 0 })})
      </div>
    );
  }
}
