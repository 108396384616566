import React from 'react';
import I18n from 'i18n-js';
import { core_apimessages_Money } from '@reverbdotcom/commons/src/gql/graphql';
import SanitizedRender from '@reverbdotcom/commons/src/components/sanitized_render';
import {
  IUserType,
  IRefundType,
  IRefundState,
  IRefundableOrderProps,
  IRefundRequestProps,
} from './refund_request';
import { getRefundExplanationLineItems } from './refund_explanation_line_items';

interface IProps {
  userType: IUserType;
  refundType: IRefundType;
  order: IRefundableOrderProps;
  refundState: IRefundState;
  refundRequest: IRefundRequestProps;
  refundAmount: string;
  refundReason: string;
  protectionPlanAmount?: core_apimessages_Money;
}

interface ILineItemProps {
  text: string;
  key: string;
}

const checkMark = '<span class="fa fa-check fa-li color-green"></span>';

const LineItem: React.StatelessComponent<ILineItemProps> = props =>
  <SanitizedRender
    htmlTag="li"
    html={`${checkMark}${props.text}`}
  />;

const RefundExplanation: React.StatelessComponent<IProps> = (props) => {
  if (props.refundState === IRefundState.DENIED) {
    return null;
  }

  const items = getRefundExplanationLineItems(
    props.userType,
    props.refundType,
    props.order,
    props.refundState,
    props.refundRequest,
    props.refundAmount,
    props.refundReason,
    props.protectionPlanAmount,
  );

  return (
    <div className="form-group">
      <div className="form-group__header">
        <label>
          {I18n.t('discovery.dashboard.refundRequests.explanation.heading')}
        </label>
      </div>
      <div className="form-group__fields">
        <ul className="fa-ul size-90">
          {items.map((text, i) => <LineItem text={text} key={`refund-item-${i}`} />)}
        </ul>
      </div>
    </div>
  );
};

export default RefundExplanation;
