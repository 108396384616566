import React from 'react';
import I18n from 'i18n-js';

import { ErrorAlertBox } from '@reverbdotcom/commons/src/ErrorAlertBox';
import { useUser } from '@reverbdotcom/commons/src/user_hooks';
import { inMobileBrowser } from '@reverbdotcom/commons/src/user_context_helpers';
import { useViewTracking } from '@reverbdotcom/commons/src/use_tracking';
import { MParticleEventName } from '@reverbdotcom/commons/src/elog/mparticle_types';

import { useApplePayAvailabilityByConfig } from './useApplePayAvailability';
import { IconDisplay } from '../lib/payment_method_icons';
import { CartBundleExpressApplePayButton } from './CartBundleExpressApplePayButton';
import { ExpressPayPalButtons } from './checkout/ExpressPayPalButtons';
import CartStartCheckoutButton from './CartStartCheckoutButton';
import { PaypalCheckoutEligibilityAttributes, useExpressPayPalAvailability, usePayPalPayLaterButtonAvailability } from './useExpressPayPal';
import {
  core_apimessages_Money as Money,
  core_apimessages_CheckoutPaymentMethod_Type as CheckoutPaymentMethodType,
} from '@reverbdotcom/commons/src/gql/graphql';
import { formatExperimentsForEventAttributes } from '@reverbdotcom/commons/src/elog/mparticle';

export interface Props {
  paymentMethodType: CheckoutPaymentMethodType;
  checkoutPath: string;
  expressPaypalCurrency: string;
  checkoutBundlingId: string;
  amountTotal: Money;
  adyenCheckoutPaymentMethodsConfig: string;
  paymentMethodIcons: IconDisplay[];
  shopCountryCode: string;
  paypalCheckoutEligibilityAttributes: PaypalCheckoutEligibilityAttributes
}

export default function CheckoutCartFooterButtons({
  paymentMethodType,
  checkoutPath,
  expressPaypalCurrency,
  checkoutBundlingId,
  amountTotal,
  adyenCheckoutPaymentMethodsConfig,
  paymentMethodIcons,
  paypalCheckoutEligibilityAttributes,
  shopCountryCode,
}: Props) {
  const user = useUser();
  const [errors, setErrors] = React.useState([]);

  const [isApplePayAvailable] = useApplePayAvailabilityByConfig(adyenCheckoutPaymentMethodsConfig);
  const isDirectCheckout = paymentMethodType === CheckoutPaymentMethodType.DIRECT_CHECKOUT;
  const showApplePayButton = isApplePayAvailable && isDirectCheckout;

  const isExpressPayPalAvailable = useExpressPayPalAvailability({ paypalCheckoutEligibilityAttributes });

  const isPaypalPayLaterAvailable = usePayPalPayLaterButtonAvailability({
    currency: amountTotal.currency,
    amountCents: amountTotal.amountCents,
    shopCountryCode,
  });

  const showOne = showApplePayButton !== isExpressPayPalAvailable;
  const trackedExperiments = [];

  const formattedUserExperiments = formatExperimentsForEventAttributes(user, trackedExperiments);

  const experimentData = {
    componentName: 'CheckoutCartFooterButtons',
    eventName: MParticleEventName.ComponentView,
    experiments: formattedUserExperiments,
    ...paypalCheckoutEligibilityAttributes,
  };

  useViewTracking(experimentData, true);

  return (
    <>
      {errors.length > 0 &&
        <div className="mb-4">
          <ErrorAlertBox errors={errors} />
        </div>
      }
      <div className="checkout-cart-footer__buttons">
        {(inMobileBrowser(user) || showOne) &&
          <>
            {isExpressPayPalAvailable &&
              <div className="checkout-cart-footer__button checkout-cart-footer__button--paypal">
                <ExpressPayPalButtons
                  currency={expressPaypalCurrency}
                  checkoutBundlingId={checkoutBundlingId}
                  onError={setErrors}
                  amountCents={amountTotal.amountCents}
                  parentComponentName="CheckoutCartFooterButtons"
                  showPaypalPayLaterButton={isPaypalPayLaterAvailable}
                  paymentMethod={paymentMethodType}
                />
              </div>
            }
            {showApplePayButton &&
              <div className="checkout-cart-footer__button checkout-cart-footer__button--apple-pay">
                <CartBundleExpressApplePayButton
                  variant="white-outlined-express"
                  checkoutBundlingId={checkoutBundlingId}
                  amountTotal={amountTotal}
                  adyenCheckoutPaymentMethodsConfig={adyenCheckoutPaymentMethodsConfig}
                  tracking={{
                    displayStyle: 'default',
                  }}
                />
              </div>
            }
          </>
        }
        {showOne &&
          <div className="checkout-cart-footer__express-pay-separator">
            {I18n.t('discovery.checkout.expressCheckout.separatorText')}
          </div>
        }
        <CartStartCheckoutButton
          paymentMethodType={paymentMethodType}
          checkoutPath={checkoutPath}
          paymentMethodIcons={paymentMethodIcons}
        />
      </div>
    </>
  );
}
