import React from 'react';
import SiteModule from './site_module';

interface Props {
  placeholderLineCount?: number;
  title?: string;
}

export function SiteModulePlaceholder({
  placeholderLineCount = 3,
  title = null,
}: Props) {
  function randomLineWidthPercent() {
    return `${(Math.random() * (27 - 22)) + 22}%`;
  }

  return (
    <SiteModule title={title}>
      {[...Array(placeholderLineCount)].map((_, idx) => (
        // eslint-disable-next-line react/no-array-index-key
        (<span key={idx} className="site-module-placeholder--line" style={{ width: randomLineWidthPercent() }} />)
      ))}
    </SiteModule>
  );
}
