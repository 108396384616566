import React from 'react';
import I18n from 'i18n-js';
import { first, get, isNull, isUndefined } from 'lodash';
import * as elog from '../elog';
import moment from 'moment';

import {
  Commons_Offers_CreateOfferFormMutation,
  Commons_Offers_CreateOfferFormMutationVariables,
  Commons_Offers_NewOfferFormQuery,
  Commons_Offers_NewOfferFormQueryVariables,
  core_apimessages_BumpKey,
  core_apimessages_Error as RQLError,
  core_apimessages_Money as Money,
  core_apimessages_NegotiationParty as NegotiationParty,
  core_apimessages_ShippingMethod as ShippingMethod,
  Input_core_apimessages_BumpKey,
  Input_core_apimessages_CreateNegotiationRequest,
  core_apimessages_CreditCard as CreditCard,
  core_apimessages_Address as Address,
  reverb_config_cache_ExchangeRate,
} from '../gql/graphql';
import ModalDialog from '../components/modal_dialog';
import { RCAlertBox } from '@reverbdotcom/cadence/components';
import OfferCreateFormFields from './offer_create_form_fields';
import { newOfferFormQuery, createInitialOfferMutation } from './offer_operations';
import { useQuery } from '../useQuery';
import { useUser } from '../user_hooks';
import { useMutation } from '../useMutation';
import { canViewListingPriceRecommendations } from '../user_context_helpers';
import { convertCurrency, convertMoney } from '../convert_currency';
import { MParticleEventName, trackEvent } from '../elog/mparticle_tracker';
import { formatNumber, parseAmount } from '../money';
import { shippingPriceDataForMethod } from '../components/listing_shipping_display';
import OfferModalHeaderContent from './offer_modal_header_content';
import SanitizedRender from '../components/sanitized_render';
import { priceRecommendationCanBeShown } from './estimated_value_alert';
import { GOOGLE_RECAPTCHA_ACTIONS, US } from '@reverbdotcom/commons/src/constants';
import ImmediatePaymentReview from './immediate_payment_review';
import PayImmediatelyRadioGroup from './pay_immediately_radio_group';
import { generateRecaptchaToken } from '../generate_recaptcha_token';
import { ErrorAlertBox } from '../ErrorAlertBox';

interface OfferItem {
  listingId: string;
  shippingPrice?: Money;
  quantity: number;
  price: Money;
}

interface Props {
  listingId: string;
  recipientUuid?: string; // Must be passed if isSeller == true
  isSeller: boolean;
  onError?: (error: RQLError[]) => void;
  onCancel: () => void;
  onSuccess?: () => void;
  isDisplayed: boolean;
  messageInput?: React.ReactNode;
  additionalMutationParams?: Partial<Input_core_apimessages_CreateNegotiationRequest>;
  bumpKey: core_apimessages_BumpKey;
  headerTitle?: string;
  message?: string;
}

export interface OfferFormState {
  price?: string;
  message?: string;
  shippingPrice?: string;
  quantity?: number;
  selectedPercentage?: string;
  shipTo?: Address;
  creditCardId?: string;
}

export interface SubmittedOffer {
  price: string;
  shippingPrice: string;
  totalPrice: string;
}

export function offerFormReducer(state, action): OfferFormState {
  switch (action.type) {
    case 'setPrice':
      return { ...state, price: action.value, selectedPercentage: '' };
    case 'setSelectedPercentage':
      return { ...state, selectedPercentage: action.value };
    case 'setMessage':
      return { ...state, message: action.value };
    case 'setShippingPrice':
      return { ...state, shippingPrice: action.value };
    case 'setQuantity':
      return { ...state, quantity: parseInt(action.value, 10) };
    case 'setShipTo':
      return { ...state, shipTo: action.value };
    case 'setCreditCardId':
      return { ...state, creditCardId: action.value.toString() };
    default:
      return { ...state };
  }
}

export const emptyOfferFormState = {
  price: '',
  message: '',
  shippingPrice: '',
  quantity: 1,
  selectedPercentage: '',
};

function OfferConfirmation({ isSeller, willPayImmediately }) {
  return (
    <div className="offers-form__confirmation">
      <h4>{I18n.t('commons.offers.confirmation.header')}</h4>
      <ul>
        {!isSeller && (
          <>
            <li>
              <SanitizedRender
                html={I18n.t('commons.offers.confirmation.buyer.line1')}
              />
            </li>
            <li>
              {willPayImmediately && I18n.t('commons.offers.confirmation.buyer.line2WillPayImmediately')}

              {!willPayImmediately && (
                <SanitizedRender
                  html={I18n.t('commons.offers.confirmation.buyer.line2')}
                />
              )}
            </li>
          </>
        )}

        {isSeller && (
          <>
            <li>
              <SanitizedRender
                html={I18n.t('commons.offers.confirmation.seller.line1')}
              />
            </li>
            <li>
              <SanitizedRender
                html={I18n.t('commons.offers.confirmation.seller.line2')}
              />
            </li>
          </>
        )}
      </ul>
    </div>
  );
}

function cardValidForPayment(card: CreditCard) {
  return moment(`${card.expirationYear}/${card.expirationMonth}`, 'YYYY-MM') > moment() && card.address?.isComplete;
}

export function filterValidShippingAddresses(addresses: Address[]) {
  return (addresses || []).filter((address) => address.countryCode === US && address.isComplete);
}

export function filterValidCreditCards(creditCards: CreditCard[]) {
  return (creditCards || []).filter(card => cardValidForPayment(card));
}

export function calculateOfferDiscountPercent(offerPrice: Money, listingPrice: Money, exchangeRates: reverb_config_cache_ExchangeRate[]) {
  let listingPriceToUse: Money;
  if (offerPrice.currency != listingPrice.currency) {
    listingPriceToUse = convertMoney({ amountCents: listingPrice.amountCents, currency: listingPrice.currency }, offerPrice.currency, exchangeRates);
  } else {
    listingPriceToUse = listingPrice;
  }
  return Math.round((1 - (offerPrice.amountCents / listingPriceToUse.amountCents)) * 100);
}

export function offerWouldBeAutoRejected(offerPrice: Money, offerCurrency: string, listing: Commons_Offers_NewOfferFormQuery['listing']) {
  if (!listing || !offerPrice || listing.price.currency != offerCurrency) {
    return false;
  }

  const percentOff = Math.round((1 - (offerPrice.amountCents / listing.price.amountCents)) * 100);

  return percentOff >= (100 - (listing.offerBotRule.autoRejectPercentage));
}

export function CreateOfferModal({
  listingId,
  isSeller,
  onCancel,
  isDisplayed,
  bumpKey,
  messageInput,
  recipientUuid,
  additionalMutationParams,
  onError,
  headerTitle,
  message,
  onSuccess,
}: Props) {
  const user = useUser();
  const [formState, dispatch] = React.useReducer(offerFormReducer, emptyOfferFormState);
  const [isEditingLocation, setIsEditingLocation] = React.useState(true);
  const [location, setLocation] = React.useState({
    postalCode: '',
    shippingRegionCode: '',
  });
  const [submittedOffer, setSubmittedOffer] = React.useState<SubmittedOffer>({ price: '', shippingPrice: '', totalPrice: '' });

  const [willPayImmediately, setWillPayImmediately] = React.useState(false);
  const [confirmedWillPayImmediately, setConfirmedWillPayImmediately] = React.useState(false);

  const {
    loading,
    errors,
    data,
    refetch,
  } = useQuery<Commons_Offers_NewOfferFormQuery, Commons_Offers_NewOfferFormQueryVariables>(
    newOfferFormQuery,
    {
      skip: !isDisplayed,
      errorPolicy: 'all',
      notifyOnNetworkStatusChange: true,
      variables: {
        usePrimaryShippingAddress: true,
        listingId: String(listingId),
        isSeller: !!isSeller,
      },
    },
  );
  const [
    createInitialOffer,
    {
      loading: mutationDataLoading,
      errors: mutationErrors,
    },
  ] = useMutation<Commons_Offers_CreateOfferFormMutation, Commons_Offers_CreateOfferFormMutationVariables>(
    createInitialOfferMutation,
  );

  const listing = data?.listing;

  React.useEffect(() => {
    if (loading || !listing) {
      return;
    }

    dispatch({ type: 'setShippingPrice', value: standardShippingPrice() });

    const { shippingRegionCode, shippingAddress, shippingPrices } = listing.shipping || {};
    const firstShippingPrice = shippingPrices?.[0];

    setLocation({
      ...location,
      postalCode: firstShippingPrice?.postalCode || shippingAddress?.postalCode || location.postalCode || '',
      shippingRegionCode: shippingRegionCode || '',
    });

    const missingRequiredLocation = !firstShippingPrice?.postalCode || !shippingRegionCode;
    setIsEditingLocation(!isSeller && missingRequiredLocation);

    if (showPayImmediately) {
      dispatch({ type: 'setShipTo', value: shippingAddresses.find((addr) => addr.primary) || shippingAddresses[0] });
      dispatch({ type: 'setCreditCardId', value: (creditCards.find((card) => card.primaryForCheckout) || creditCards[0]).id });
    }
  }, [listing, loading]);

  // MessageForm passes its own message input component into this modal which has its own state, and we
  // need to keep the message portion of this component's state in sync with it
  React.useEffect(() => {
    if (isNull(message) || isUndefined(message)) { return; }

    dispatch({ type: 'setMessage', value: message });
  }, [message]);

  React.useEffect(() => {
    if (mutationErrors.length > 0) {
      const { message: firstErrorMessage } = first(mutationErrors);
      elog.error('offer.create', { errors: mutationErrors }, firstErrorMessage);
      if (onError) {
        onError(mutationErrors);
      }
    }
  }, [mutationErrors]);

  const exchangeRates = data?.exchangeRates?.rates || [];
  const shop = listing?.shop;
  const shopCurrency = shop?.currency;
  const offerCurrency = isSeller ? shopCurrency : user?.currency;
  const preventLowballOffers = !!listing?.offerBotRule?.autoRejectPercentage;
  const userCanViewPriceRecommendation = user && shopCurrency && canViewListingPriceRecommendations(user, shopCurrency);

  const showPriceRecommendation = priceRecommendationCanBeShown(user, shopCurrency, listing);
  const parsedOfferPrice = formState.price && offerCurrency && parseAmount(formState.price, offerCurrency);
  const parsedOfferShippingPrice = formState.shippingPrice && shopCurrency && parseAmount(formState.shippingPrice, shopCurrency);
  const inRecommendedPriceRange = userCanViewPriceRecommendation &&
    parsedOfferPrice?.amountCents &&
    listing.priceRecommendation?.priceLow?.amountCents &&
    listing.priceRecommendation?.priceHigh?.amountCents &&
    parsedOfferPrice.amountCents >= listing.priceRecommendation.priceLow.amountCents &&
    parsedOfferPrice.amountCents <= listing.priceRecommendation.priceHigh.amountCents;
  const hasSubmittedOffer = !!submittedOffer?.totalPrice;

  const shippingAddresses = filterValidShippingAddresses(data?.me?.shippingAddresses);
  const creditCards = filterValidCreditCards(data?.me?.creditCards);
  const selectedCreditCard = formState.creditCardId && creditCards.find((cc) => cc.id.toString() == formState.creditCardId);
  const showPayImmediately = !isSeller && shop?.address?.countryCode == US && shippingAddresses.length > 0 && creditCards.length > 0;
  const immediatePaymentFormStateIsInvalid = !formState.shipTo?.id || !selectedCreditCard || selectedCreditCard?.needsReverification;

  const wouldBeAutoRejected = !isSeller && preventLowballOffers && offerWouldBeAutoRejected(parsedOfferPrice, offerCurrency, listing);

  const formatBumpKey = (key: core_apimessages_BumpKey): Input_core_apimessages_BumpKey => {
    if (!key?.key) { return null; }

    return { key: key.key };
  };

  function standardShippingPrice() {
    const standardShippingData = shippingPriceDataForMethod(listing, ShippingMethod.SHIPPED);
    if (standardShippingData?.carrierCalculated) { return undefined; }
    if (!standardShippingData?.rate?.amount) { return undefined; }
    return formatNumber(Number(standardShippingData.rate.amount));
  }

  function modalHeaderTitle() {
    if (hasSubmittedOffer) {
      return I18n.t('commons.offers.modalHeader.confirmed');
    } else if (confirmedWillPayImmediately) {
      return I18n.t('commons.offers.modalHeader.review');
    }
    return headerTitle || I18n.t('commons.offers.modalHeader.create');
  }

  function submitButtonText() {
    const immediatePaymentReviewPending = showPayImmediately && willPayImmediately && !confirmedWillPayImmediately;

    if (hasSubmittedOffer) {
      return I18n.t('commons.offers.submitButton.confirm');
    } else if (immediatePaymentReviewPending) {
      return I18n.t('commons.offers.submitButton.review');
    }
    return I18n.t('commons.offers.submitButton.submit');
  }

  async function createNegotiation(): Promise<Commons_Offers_CreateOfferFormMutation['createNegotiation']['negotiations'][0]> {
    const shopCurrencyOfferPrice = convertCurrency(
      offerCurrency,
      shopCurrency,
      exchangeRates,
      formState.price,
    );

    const offerItem: OfferItem = {
      listingId: String(listingId),
      quantity: isSeller ? formState.quantity || 1 : 1,
      price: shopCurrencyOfferPrice,
    };

    if (isSeller) {
      offerItem.shippingPrice = parsedOfferShippingPrice;
    }

    let shippingRegionCodeForBuyer = null;
    if (!isSeller) {
      shippingRegionCodeForBuyer = location.shippingRegionCode;
    }

    let immediatePaymentParams;
    if (confirmedWillPayImmediately) {
      immediatePaymentParams = {
        shippingAddressUuid: formState.shipTo.id,
        creditCardId: formState.creditCardId,
      };
    }

    const input: Input_core_apimessages_CreateNegotiationRequest = {
      recipientUuid: recipientUuid,
      message: formState.message,
      offerItems: [offerItem],
      shippingRegionCode: shippingRegionCodeForBuyer,
      postalCode: location.postalCode,
      bumpKey: formatBumpKey(bumpKey),
      ...(additionalMutationParams || {}),
      ...(immediatePaymentParams || {}),
    };

    const token = await generateRecaptchaToken(GOOGLE_RECAPTCHA_ACTIONS.SUBMIT_OFFER);
    const { data: mutationResult } = await createInitialOffer({
      variables: { input },
      context: {
        headers: {
          'X-Google-Recaptcha-Token': token,
        },
      },
    });
    return get(mutationResult, 'createNegotiation.negotiations.0');
  }

  async function submitOffer() {
    setIsEditingLocation(false);

    const response = await createNegotiation();

    if (response?.lastOffer) {
      let comparedToRecommendedMiddlePrice;
      if (!userCanViewPriceRecommendation || !parsedOfferPrice?.amountCents || !listing.priceRecommendation?.priceMiddle?.amountCents) {
        comparedToRecommendedMiddlePrice = '';
      } else {
        if (parsedOfferPrice.amountCents < listing.priceRecommendation.priceMiddle.amountCents) {
          comparedToRecommendedMiddlePrice = 'lower';
        } else if (parsedOfferPrice.amountCents > listing.priceRecommendation.priceMiddle.amountCents) {
          comparedToRecommendedMiddlePrice = 'higher';
        } else {
          comparedToRecommendedMiddlePrice = 'equal';
        }
      }

      trackEvent({
        eventName: MParticleEventName.SubmittedOffer,
        listingId: listingId,
        discountPercent: calculateOfferDiscountPercent(parsedOfferPrice, listing.price, exchangeRates),
        eligibleForPriceRecommendation: !!showPriceRecommendation,
        inRecommendedPriceRange: !!inRecommendedPriceRange,
        comparedToRecommendedMiddlePrice: comparedToRecommendedMiddlePrice,
        quickInput: formState.selectedPercentage ? `${formState.selectedPercentage}%` : '',
        role: isSeller ? 'seller' : 'buyer',
        isCounterOffer: false,
        optedIntoImmediatePayment: confirmedWillPayImmediately,
        isOutlet: listing.usOutlet,
      });

      const { prices } = response.lastOffer;
      setSubmittedOffer({
        price: prices.price.display.display,
        shippingPrice: prices.shippingPrice.display.display,
        totalPrice: prices.totalPrice.display.display,
      });
    }
  }

  async function handleSubmit() {
    if (showPayImmediately && willPayImmediately && !confirmedWillPayImmediately) {
      setConfirmedWillPayImmediately(true);
    } else if (!hasSubmittedOffer) {
      await submitOffer();
    } else if (onSuccess) {
      onSuccess();
    } else {
      onCancel();
    }
  }

  return (
    <ModalDialog
      onRequestClose={onCancel}
      isOpen={isDisplayed}
      headerTitle={modalHeaderTitle()}
      saveButtonText={submitButtonText()}
      backButtonText={confirmedWillPayImmediately ? I18n.t('commons.offers.cancelButton.back') : I18n.t('commons.offers.cancelButton.cancel')}
      isDisabled={loading ||
        mutationDataLoading ||
        !formState.price ||
        (!isSeller && !location.shippingRegionCode) ||
        (!isSeller && !location.postalCode) ||
        wouldBeAutoRejected ||
        (confirmedWillPayImmediately && immediatePaymentFormStateIsInvalid)
      }
      onSubmit={handleSubmit}
      hideFooterDismissButton={hasSubmittedOffer}
      onNavigateBack={confirmedWillPayImmediately ? () => {
        setConfirmedWillPayImmediately(false);
      } : undefined}
    >
      {errors && (
        <RCAlertBox type="error">
          <p className="mb-0">
            {I18n.t('commons.offers.error')}
          </p>
        </RCAlertBox>
      )}

      {loading && (
        <div className="offers-form offers-form--loading">
          <div className="offers-form-placeholder" />
          <div className="offers-form-placeholder" />
          <div className="offers-form-placeholder" />
        </div>
      )}

      {!loading && !errors && listing && (
        <>
          <OfferModalHeaderContent
            listing={listing}
            isSeller={isSeller}
            countries={data?.countries?.countries || []}
            isEditingLocation={isEditingLocation}
            setIsEditingLocation={setIsEditingLocation}
            location={location}
            setLocation={setLocation}
            refetch={refetch}
            submittedOffer={hasSubmittedOffer ? submittedOffer : undefined}
            titleOnly={confirmedWillPayImmediately && !hasSubmittedOffer}
          />

          {!hasSubmittedOffer && !confirmedWillPayImmediately && (
            <>
              <OfferCreateFormFields
                listing={listing}
                currency={offerCurrency}
                offer={formState}
                onOfferChange={dispatch}
                party={isSeller ? NegotiationParty.SELLER : NegotiationParty.BUYER}
                messageInput={messageInput}
                preventLowballOffers={preventLowballOffers}
                priceRecommendation={showPriceRecommendation && listing.priceRecommendation}
              />

              {showPayImmediately && (
                <div className="mt-4">
                  <PayImmediatelyRadioGroup
                    willPayImmediately={willPayImmediately}
                    setWillPayImmediately={setWillPayImmediately}
                  />
                </div>
              )}
            </>
          )}

          {!hasSubmittedOffer && confirmedWillPayImmediately && (
            <ImmediatePaymentReview
              offerPrice={parsedOfferPrice}
              shippingPrice={parsedOfferShippingPrice}
              currency={offerCurrency}
              selectedAddress={formState.shipTo}
              allAddresses={shippingAddresses}
              selectedCreditCard={selectedCreditCard}
              allCreditCards={creditCards}
              dispatch={dispatch}
              localPickupOnly={listing.shipping.localPickupOnly}
              listingId={listingId}
            />
          )}

          {hasSubmittedOffer && (
            <OfferConfirmation
              isSeller={isSeller}
              willPayImmediately={confirmedWillPayImmediately}
            />
          )}

          {mutationErrors.length > 0 && (
            <div className="mt-2">
              <ErrorAlertBox errors={mutationErrors} small />
            </div>
          )}
        </>
      )}
    </ModalDialog>
  );
}

export default CreateOfferModal;
