import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';

class PageSearchBar extends React.Component {
  static get defaultProps() {
    return {
      placeholderKey: '',
      containerClass: '',
      queryParam: 'query',
    };
  }

  static get propTypes() {
    return {
      containerClass: PropTypes.string,
      query: PropTypes.string,
      queryParam: PropTypes.string,
      placeholderKey: PropTypes.string,
      queryRoute: PropTypes.string,
    };
  }

  render() {
    return (
      <div className={classNames('page-search', this.props.containerClass)}>
        <form
          className="page-search__inner"
          action={this.props.queryRoute}
        >
          <input
            id="page-search-input"
            className="page-search__input"
            name={this.props.queryParam}
            type="text"
            placeholder={I18n.t(this.props.placeholderKey)}
            defaultValue={this.props.query}
          />
          <input
            className="page-search__submit"
            type="submit"
            value={I18n.t('js.pageSearchBar.submit')}
          />
        </form>
      </div>
    );
  }
}

export default PageSearchBar;
