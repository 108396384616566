import React from 'react';
import classNames from 'classnames';
import { RCAccordion } from '@reverbdotcom/cadence/components';
import {
  isChildSelected,
  isSomethingSelected,
} from '../facet_utils';
import CollapsingFacet from './collapsing_facet';
import { SelectFacetOption } from './select_facet_option';
import { FilterOption, filterID, FilterProps } from './search_filters';

export function NestedSelectFacet({
  filter,
  trackingQuery,
  collapsible = false,
  defaultOpen = false,
  pageSection,
  onToggleCallback,
}: FilterProps) {
  function renderChildrenFor(option: FilterOption) {
    if (!option.subFilter || !option.subFilter.options) {
      return null;
    }

    const links = option.subFilter.options.map((option, idx) => (
      <SelectFacetOption
        key={idx}
        option={option}
        trackingQuery={trackingQuery}
      >
        {isChildSelected(option, true) && renderChildrenFor(option)}
      </SelectFacetOption>
    ));

    return (
      <CollapsingFacet count={links.length} countThreshold={6}>
        <ul className="facet__options">{links}</ul>
      </CollapsingFacet>
    );
  }

  function renderRootLinks() {
    const nothingSelected = !isSomethingSelected(filter);

    return filter.options.map((option, idx) => {
      if (option.selected || isChildSelected(option)) {
        return (
          <SelectFacetOption
            key={idx}
            option={option}
            trackingQuery={trackingQuery}
            childActive
          >
            {renderChildrenFor(option)}
          </SelectFacetOption>
        );
      }

      if (nothingSelected || option.all) {
        return <SelectFacetOption
          key={idx}
          option={option}
          trackingQuery={trackingQuery}
          childActive={option.all}
        />;
      }
    });
  }

  if (filter.options.length === 0) {
    return null;
  }

  const classes = classNames('facet', { 'facet--collapsible': collapsible });
  const labelId = `${pageSection}--${filterID(filter)}-lbl`;

  return (
    <div className={classes}>
      {collapsible && (
        <RCAccordion
          id={labelId}
          heading={filter.name}
          defaultOpen={defaultOpen || isSomethingSelected(filter)}
          onToggle={onToggleCallback}
        >
          <ul className="facet__options">{renderRootLinks()}</ul>
        </RCAccordion>
      )}
      {!collapsible && (
        <>
          <h3 className="facet__heading">{filter.name}</h3>
          <ul className="facet__options">{renderRootLinks()}</ul>
        </>
      )}
    </div>
  );
}
