import React from 'react';
import I18n from 'i18n-js';
import { noop } from 'lodash';
import { CardElementData } from '@adyen/adyen-web/dist/types/components/Card/types';

import { useAdyenCard } from '@reverbdotcom/commons/src/adyen_card_hook';
import { AdyenCardType } from '@reverbdotcom/commons/src/adyen';
import { useHelpCenterArticleUrl } from '@reverbdotcom/commons/src/url_helpers';
import { RCCheckbox, RCAlertBox } from '@reverbdotcom/cadence/components';
import { ErrorList } from '@reverbdotcom/commons/src/ErrorList';

import CreditCardEncryptedInput from './credit_card_encrypted_input';
import CreditCardEncryptedDateInput from './credit_card_encrypted_date_input';
import AddressFormFields from './address_form_fields';
import AddressSummary from './AddressSummary';
import { emptyAddressEntry } from '../lib/emptyAddressEntry';
import {
  core_apimessages_AddressEntry as AddressEntry,
  core_apimessages_Address as Address,
  core_apimessages_Country as Country,
  core_apimessages_CreditCardTokenizedFields as CreditCardTokenizedFields,
  core_apimessages_Error as Error,
} from '@reverbdotcom/commons/src/gql/graphql';
import { mapAddressToEntry } from '../lib/addressEntryMapping';

interface CreditCardEntry {
  cardholderName: string;
  tokenizedFields: CreditCardTokenizedFields;
  brandType: string;
  lastDigits: string;
  cardBin: string;
}

export interface CreateCreditCardFormInput {
  creditCard: CreditCardEntry;
  billingAddress: AddressEntry;
}

type ChangeCallback = (input: CreateCreditCardFormInput) => any;
type SubmitCallback = (input: CreateCreditCardFormInput) => any;

interface Props {
  formId: string;
  defaultBillingAddress: Address;
  countries: Country[];
  errors: Error[];
  onChange?: ChangeCallback;
  onSubmit?: SubmitCallback;
  onCardValidChange?: (valid: boolean) => void;
  hideBillingAddressFields?: boolean;
}

const CREDIT_CARD_TROUBLESHOOTING_TIPS_ARTICLE_ID = '1500005385482';
const COMPONENT_NAME = 'CreditCardCreateForm';

export default function CreditCardCreateForm({
  formId,
  defaultBillingAddress,
  errors,
  countries,
  onChange = noop,
  onSubmit = noop,
  onCardValidChange = noop,
  hideBillingAddressFields = false,
}: Props) {
  const isDefaultBillingAddressComplete = defaultBillingAddress.isComplete;
  const [isBillingSameAsShipping, setBillingSameAsShipping] = React.useState(isDefaultBillingAddressComplete);
  const [billingAddress, setBillingAddress] = React.useState<AddressEntry>(mapAddressToEntry(defaultBillingAddress));
  const [tokenizedFields, setTokenizedFields] = React.useState<CreditCardTokenizedFields>({});
  const [lastDigits, setLastDigits] = React.useState('');
  const [brandType, setBrandType] = React.useState('');
  const [binValue, setBinValue] = React.useState('');

  function onCardChange(data: Partial<CardElementData>) {
    const fields = mapTokenizedFields(data);

    setTokenizedFields(fields);

    onChange({
      billingAddress,
      creditCard: {
        cardholderName: billingAddress.name,
        tokenizedFields: fields,
        lastDigits,
        brandType,
        cardBin: binValue,
      },
    });
  }

  function onBrand(brand: string) {
    setBrandType(brand);

    onChange({
      billingAddress,
      creditCard: {
        cardholderName: billingAddress.name,
        tokenizedFields,
        lastDigits,
        brandType: brand,
        cardBin: binValue,
      },
    });
  }

  function onLastDigits(digits: string) {
    setLastDigits(digits);

    onChange({
      billingAddress,
      creditCard: {
        cardholderName: billingAddress.name,
        tokenizedFields,
        lastDigits: digits,
        brandType,
        cardBin: binValue,
      },
    });
  }

  function onAddressChange(address: AddressEntry) {
    onChange({
      billingAddress: address,
      creditCard: {
        cardholderName: address.name,
        tokenizedFields,
        lastDigits,
        brandType,
        cardBin: binValue,
      },
    });

    setBillingAddress(address);
  }

  function onBinValue(newBinValue: string) {
    onChange({
      billingAddress,
      creditCard: {
        cardholderName: billingAddress.name,
        tokenizedFields,
        lastDigits,
        brandType,
        cardBin: newBinValue,
      },
    });

    setBinValue(newBinValue);
  }

  const { focus, errors: adyenErrors } = useAdyenCard({
    selector: '[data-card-fields]',
    cardType: AdyenCardType.Card,
    onBrand,
    onEndDigits: onLastDigits,
    onCardChange: ({ isValid, data }) => {
      onCardChange(data);
      onCardValidChange(isValid);
    },
    onBinValue,
  });

  function onToggleBillingSameAsShipping() {
    if (isBillingSameAsShipping) {
      onAddressChange(emptyAddressEntry);
    } else {
      onAddressChange(mapAddressToEntry(defaultBillingAddress));
    }

    setBillingSameAsShipping(previous => !previous);
  }

  async function onFormSubmit(e: React.ChangeEvent<HTMLFormElement>) {
    e.preventDefault();

    onSubmit({
      creditCard: {
        cardholderName: billingAddress.name,
        tokenizedFields: {
          encryptedExpiryMonth: tokenizedFields.encryptedExpiryMonth,
          encryptedExpiryYear: tokenizedFields.encryptedExpiryYear,
          encryptedCardNumber: tokenizedFields.encryptedCardNumber,
          encryptedSecurityCode: tokenizedFields.encryptedSecurityCode,
        },
        brandType,
        lastDigits,
        cardBin: binValue,
      },
      billingAddress: {
        name: billingAddress.name,
        streetAddress: billingAddress.streetAddress,
        extendedAddress: billingAddress.extendedAddress,
        postalCode: billingAddress.postalCode,
        phone: billingAddress.phone,
        region: billingAddress.region,
        locality: billingAddress.locality,
        countryCode: billingAddress.countryCode,
      },
    });
  }

  const troubleshootingUrl = useHelpCenterArticleUrl(CREDIT_CARD_TROUBLESHOOTING_TIPS_ARTICLE_ID);

  return (
    <form id={formId} onSubmit={onFormSubmit}>
      <div data-card-fields className="g-container">
        {errors.length > 0 && (
          <div className="mb-2">
            <RCAlertBox type="error">
              <ErrorList errors={errors} />
              <div className="mt-2">
                <a href={troubleshootingUrl}>
                  {I18n.t('discovery.creditCardWallet.cardTroubleshooting')}
                </a>
              </div>
            </RCAlertBox>
          </div>
        )}
        <div className="g-col-12 scaling-mb-4">
          <CreditCardEncryptedInput
            inputName="cardNumber"
            inputValue={tokenizedFields.encryptedCardNumber}
            cseName="encryptedCardNumber"
            label={
              <span className="icon-r-lock">
                {I18n.t('discovery.creditCardWallet.createForm.cardNumber')}
              </span>
            }
            errors={adyenErrors}
            focus={focus}
            brand={brandType}
            showBrandIcon
          />
        </div>
        <div className="g-col-5 g-col-mobile-12 scaling-mb-4">
          <CreditCardEncryptedDateInput
            expiryMonthInputName="expiryMonth"
            expiryYearInputName="expiryYear"
            paymentMethod={tokenizedFields}
            label={I18n.t('discovery.creditCardWallet.createForm.expiration')}
            errors={adyenErrors}
            focus={focus}
          />
        </div>
        <div className="g-col-7 g-col-mobile-12 scaling-mb-4">
          <CreditCardEncryptedInput
            inputName="securityCode"
            inputValue={tokenizedFields.encryptedSecurityCode}
            label={
              <span className="icon-r-lock">
                {I18n.t('discovery.creditCardWallet.createForm.securityCode')}
              </span>
            }
            cseName="encryptedSecurityCode"
            errors={adyenErrors}
            focus={focus}
            brand={brandType}
            showSecurityCodeIcon
          />
        </div>
      </div>
      {!hideBillingAddressFields &&
        <>
          {isDefaultBillingAddressComplete &&
            <div className="d-flex fx-dir-col">
              <div className="mt-2">
                <RCCheckbox
                  id="credit-card-create-billing"
                  name="credit-card-create-billing"
                  label={I18n.t('discovery.creditCardWallet.createForm.billingSame')}
                  checked={isBillingSameAsShipping}
                  onChange={onToggleBillingSameAsShipping}
                />
              </div>
            </div>
          }
          <div className="bdt-1 bd-color-primary mtb-4" />
          <BillingAddress
            showEdit={isBillingSameAsShipping}
            billingAddress={billingAddress}
            onAddressUpdate={onAddressChange}
            countries={countries}
          />
        </>
      }
    </form>
  );
}

function BillingAddress(props: {
  showEdit: boolean,
  billingAddress: AddressEntry,
  countries: Country[],
  onAddressUpdate: (address: AddressEntry) => void,
}) {
  const { billingAddress } = props;

  if (props.showEdit) {
    return (
      <div className="mt-1 size-90">
        <AddressSummary address={billingAddress} />
      </div>
    );
  }

  return (
    <AddressFormFields
      address={billingAddress}
      countries={props.countries}
      updateField={props.onAddressUpdate}
      useCamelizedFieldsForRQL
      trackingComponentName={COMPONENT_NAME}
      autocompleteEnabled
      autocompleteRequired
    />
  );
}

function mapTokenizedFields({ paymentMethod }: Partial<CardElementData>): CreditCardTokenizedFields {
  return {
    encryptedCardNumber: paymentMethod.encryptedCardNumber,
    encryptedExpiryMonth: paymentMethod.encryptedExpiryMonth,
    encryptedExpiryYear: paymentMethod.encryptedExpiryYear,
    encryptedSecurityCode: paymentMethod.encryptedSecurityCode,
  };
}
