const percentToDecimal = (percent: number): string => (percent / 100).toFixed(3);
const decimalToPercent = (decimal: string): number => parseFloat(decimal) * 100;

export interface IPhoto {
  attrs: IPhotoAttrs;
  id: string;
  publicId: string;
  version: string;
  width: number;
  height: number;
  transformation: ITransformation;
  crop: ICrop;
  angle: number;

  copy: () => Photo; // TODO should this be IPhoto?
  attrsWithUpdates: IPhotoAttrs;
  parseCropFromTransformation: (transform: ITransformation) => ICrop;
  thumbUrl: string;
  fullUrl: string;
  fullUrlWithoutCrop: string;
  baseTransformations: Partial<ICloudinaryTransformation>;
  limitSizeTransformation: Partial<ICloudinaryTransformation>;
  userTransformation: ITransformation;
  cropTransformation: ITransformation;
  angleTransformation: ITransformation;
  rotate: () => void;
  signedUrl: (publicId: string, options: object) => string;
  isCropped: boolean;
  isCroppedToFullImage: boolean;
  meetsMinimumDimension: (dimension: number) => boolean;

  showTooSmallWarning: boolean;
}

export interface ITransformation {
  width?: string;
  height?: string;
  x?: string;
  y?: string;
  angle?: number;
  crop?: string;
}

interface ICrop {
  width: number;
  height: number;
  x: number;
  y: number;
}

interface ICloudinaryTransformation {
  version?: string;
  quality?: string;
  flags?: string;
  angle?: string;
  format?: string;
  crop?: string;
  width?: number;
  height?: number;
  transformation?: ITransformation;
}

export interface IPhotoAttrs {
  id?: string;
  publicId: string;
  version: string;
  width?: number;
  height?: number;
  format: string;
  resourceType: string;
  originalFilename?: string;
  transformation?: ITransformation;
}

export default class Photo implements IPhoto {
  attrs: IPhotoAttrs;

  id: string;

  publicId: string;

  version: string;

  width: number;

  height: number;

  transformation: ITransformation;

  crop: ICrop;

  angle: number;

  showTooSmallWarning: boolean;

  constructor(attrs: IPhotoAttrs) {
    this.attrs = attrs;

    this.id = attrs.id;
    this.publicId = attrs.publicId;
    this.version = attrs.version;
    this.width = attrs.width;
    this.height = attrs.height;
    this.transformation = attrs.transformation || {};

    this.crop = this.parseCropFromTransformation(this.transformation);
    this.angle = this.transformation.angle;

    this.showTooSmallWarning = false;
  }

  copy() {
    return new Photo(this.attrsWithUpdates);
  }

  get attrsWithUpdates() {
    return Object.assign(this.attrs, {
      transformation: this.userTransformation,
    });
  }

  // Convert our db representation of transformations (in decimals)
  // to crop parameters in percentages
  parseCropFromTransformation(transform) {
    return {
      width: decimalToPercent(transform.width),
      height: decimalToPercent(transform.height),
      x: decimalToPercent(transform.x),
      y: decimalToPercent(transform.y),
    };
  }

  get thumbUrl() {
    const transformations = this.baseTransformations;
    Object.assign(transformations, {
      crop: 'thumb',
      gravity: 'south',
      width: 96,
      height: 96,
      format: 'jpg', // FORCE jpeg when displaying a thumb so that HEIC works
    });

    transformations.transformation = this.userTransformation;

    return this.signedUrl(this.publicId, transformations);
  }

  get fullUrl() {
    const transformations = this.baseTransformations;
    Object.assign(transformations, this.limitSizeTransformation);

    transformations.transformation = this.userTransformation;

    return this.signedUrl(this.publicId, transformations);
  }

  get fullUrlWithoutCrop() {
    const transformations = this.baseTransformations;
    Object.assign(transformations, this.limitSizeTransformation);

    transformations.transformation = this.angleTransformation;

    return this.signedUrl(this.publicId, transformations);
  }

  get baseTransformations(): Partial<ICloudinaryTransformation> {
    return {
      version: this.version,
      quality: 'auto:eco',
      flags: 'progressive',
      angle: 'exif',
      format: 'jpg',
    };
  }

  get limitSizeTransformation(): Partial<ICloudinaryTransformation> {
    return {
      crop: 'limit',
      width: 600,
      height: 600,
    };
  }

  get userTransformation() {
    return Object.assign(this.cropTransformation, this.angleTransformation);
  }

  get cropTransformation() {
    if (!this.isCropped) { return {}; }

    return {
      width: percentToDecimal(this.crop.width),
      height: percentToDecimal(this.crop.height),
      x: percentToDecimal(this.crop.x),
      y: percentToDecimal(this.crop.y),
      crop: 'crop',
    };
  }

  get angleTransformation() {
    if (!this.angle) { return {}; }

    return {
      angle: this.angle,
    };
  }

  rotate() {
    this.angle = this.angle || 0;

    this.angle += 90;

    if (this.angle === 360) { this.angle = null; }

    // Because we've rotated the image, the crop coordinates
    // need to rotate as well.
    if (this.isCropped) {
      this.crop = {
        x: 100 - this.crop.height - this.crop.y,
        y: this.crop.x,
        width: this.crop.height,
        height: this.crop.width,
      };
    }
  }

  signedUrl(publicId, options) {
    const queryString = $.param({ options });
    return `/cloudinary/images/${publicId}?${queryString}`;
  }

  get isCropped() {
    return this.crop.width && !this.isCroppedToFullImage;
  }

  get isCroppedToFullImage() {
    return this.crop.width === 100 && this.crop.height === 100;
  }

  meetsMinimumDimension(dimension) {
    return this.width >= dimension && this.height >= dimension;
  }
}
