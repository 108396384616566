import { ModalDialog } from '@reverbdotcom/commons';
import I18n from 'i18n-js';
import React, { useState } from 'react';
import { gql } from '@reverbdotcom/commons/src/gql';
import { useQuery } from '@reverbdotcom/commons/src/useQuery';
import { useMutation } from '@reverbdotcom/commons/src/useMutation';
import { ErrorAlertBox } from '@reverbdotcom/commons/src/ErrorAlertBox';
import { RCRadioGroup } from '@reverbdotcom/cadence/components';
import AddressSummary from '../AddressSummary';

interface Props {
  id: string;
  onModalClose: () => any;
  refetchQueryName?: string;
}

export function AddressSuggestionModal({
  id,
  onModalClose,
  refetchQueryName = null,
}: Props) {
  const [radioValue, setRadioValue] = useState('suggestion');
  const [mutate, { errors }] = useMutation(ACCEPT_SUGGESTION_MUTATION);
  const { data } = useQuery(
    VERIFY_ADDRESS_QUERY,
    {
      variables: {
        input: {
          id: id,
        },
      },
      onError: onModalClose,
      onCompleted: (response) => {
        if (!response.addressVerify.suggestion) {
          onModalClose();
        }
      },
      fetchPolicy: 'no-cache',
    },
  );

  const { suggestion, address } = data?.addressVerify || {};
  const hasSuggestion = !!suggestion;

  async function submit() {
    if (radioValue === 'default') {
      onModalClose();
      return;
    }

    await mutate({
      variables: {
        input: {
          suggestionId: suggestion.id,
        },
      },
      refetchQueries: [refetchQueryName],
      awaitRefetchQueries: true,
    });

    onModalClose();
  }

  return (
    <ModalDialog
      isOpen={hasSuggestion}
      headerTitle={I18n.t('discovery.addressSuggestion.modal.header')}
      saveButtonText={I18n.t('discovery.addressSuggestion.form.save')}
      onRequestClose={onModalClose}
      onSubmit={submit}
    >
      {errors.length > 0 &&
        <div className="mb-2">
          <ErrorAlertBox errors={errors} />
        </div>
      }
      <RCRadioGroup
        id="radioGroupId"
        label={I18n.t('discovery.addressSuggestion.form.label')}
        name="address-suggestion-form"
        onChange={e => { setRadioValue(e.target.value); }}
        value={radioValue}
      >
        <RCRadioGroup.Option
          label={I18n.t('discovery.addressSuggestion.form.recommended')}
          value="suggestion"
          sublabel={<AddressSummary address={suggestion} />}
        />
        <RCRadioGroup.Option
          label={I18n.t('discovery.addressSuggestion.form.whatYouEntered')}
          value="default"
          sublabel={<AddressSummary address={address} />}
        />
      </RCRadioGroup>
    </ModalDialog>
  );
}

const VERIFY_ADDRESS_QUERY = gql(`
  query Core_Address_Verify(
    $input: Input_core_apimessages_AddressVerifyRequest
  ) {
    addressVerify(input: $input) {
      suggestion {
        _id
        id
        streetAddress
        extendedAddress
        locality
        region
        postalCode
        countryCode
      }
      address {
        _id
        id
        streetAddress
        extendedAddress
        locality
        region
        postalCode
        countryCode
      }
    }
  }
`);

const ACCEPT_SUGGESTION_MUTATION = gql(`
  mutation Core_Accept_AddressSuggestion(
    $input: Input_core_apimessages_AcceptAddressSuggestionRequest
  ) {
    acceptAddressSuggestion(input: $input) {
      id
    }
  }
`);
