import I18n from 'i18n-js';
import { reject } from 'lodash';
import React, { useContext, useEffect } from 'react';

import { RCCheckbox, RCAlertBox, RCTag } from '@reverbdotcom/cadence/components';
import { US } from '@reverbdotcom/commons/src/constants';
import { FormCards } from '@reverbdotcom/commons/src/components/FormCards';

import { Field, ActionType, IShippingRateObjectState } from '../../sellFormReducerTypes';
import { SellFormContext } from '../../SellFormContext';
import ShippingProfileCard from './ShippingProfileCard';
import DomesticShippingCard from './DomesticShippingCard';
import PrefersReverbShippingLabelCard from './PrefersReverbShippingLabelCard';
import InternationalShippingCard from './InternationalShippingCard';
import { buildPackageEntry, MeasurementSystem } from '../../../../discovery/shipping_labels/shipping_label_package';
import { Section } from '../../sellFormViewSections';

interface IProps {
  completed: boolean;
  scrollRef: React.MutableRefObject<HTMLDivElement>;
}

export const FREE_RATE_ATTRS = {
  rateType: null,
  rate: { amount: '0' },
  incrementalRate: null,
  expeditedRate: null,
};

export const BLANK_RATE_ATTRS = {
  rateType: null,
  rate: null,
  incrementalRate: null,
  expeditedRate: null,
};

export const CARRIER_CALC_RATE_TYPE = 'carrier_calculated';
export const REGIONAL_RATE_TYPE = 'regional';

export function upsertShippingRate(existingRates, updatedShippingRate) {
  const targetIndex = existingRates.findIndex((sr) => sr.regionCode === updatedShippingRate.regionCode);
  const updatedShippingRates = existingRates.slice();

  if (targetIndex > -1) {
    updatedShippingRates[targetIndex] = updatedShippingRate;
  } else {
    updatedShippingRates.push(updatedShippingRate);
  }

  return updatedShippingRates;
}

export function deleteShippingRate(existingRates, regionCodeToDelete) {
  return reject(existingRates, (sr) => sr.regionCode === regionCodeToDelete);
}

export function buildBlankShippingRate(regionCode, rateType = null): IShippingRateObjectState {
  return {
    ...BLANK_RATE_ATTRS,
    regionCode,
    rateType,
  };
}

function ShippingForm({ completed, scrollRef }: IProps) {
  const {
    listingEntry,
    dispatch,
    shopConfig,
    loading,
    showRequiredFieldError,
    listingState,
    completionStatusMap,
  } = useContext(SellFormContext);
  const { shippingRegionSettings, address, shippingProfiles = [] } = shopConfig;
  const domesticRegion = shippingRegionSettings.find(({ isDomestic }) => isDomestic);
  const selectedShippingProfile = shippingProfiles.find((sp) => sp.id === listingEntry[Field.SHIPPING_PROFILE_ID]);
  const listingOffersShipping = listingEntry[Field.SHIPPING_RATES].length > 0;
  const inCarrierCalculatedMode = !!listingEntry[Field.CARRIER_CALCULATED_PACKAGE];
  const showRslCard = listingOffersShipping && !inCarrierCalculatedMode && address.countryCode === US;

  // default to carrier calculated if:
  // - user has a domestic region that allows carrier calculated
  // - the seller has not already manually set some type of shipping option (including localPickupOnly, using a shipping profile, or manual prices)
  // - the listing is a draft
  const defaultToCarrierCalculated = !!(
    domesticRegion?.allowedToShipCarrierCalculated &&
    domesticRegion?.regionCode &&
    !completionStatusMap[Section.SHIPPING].completed &&
    listingState === 'draft'
  );

  const errorText = (showRequiredFieldError && !completed) &&
    I18n.t('discovery.sellForm.shippingSection.requiredErrorText');

  useEffect(() => {
    if (!defaultToCarrierCalculated) return;

    dispatch({
      type: ActionType.SET_DEFAULT_SHIPPING,
      shippingRates: [buildBlankShippingRate(domesticRegion.regionCode, CARRIER_CALC_RATE_TYPE)],
    });
    dispatch({
      type: ActionType.UPDATE_FIELD,
      fieldKey: Field.CARRIER_CALCULATED_PACKAGE,
      value: buildPackageEntry({}, MeasurementSystem.IMPERIAL),
    });
  }, []); // set to [] here because we want to run this only once on the first render

  return (
    <>
      <div ref={!completed ? scrollRef : null}/>
      {errorText && (
        <div className="mb-4">
          <RCAlertBox type="error" small>
            {errorText}
          </RCAlertBox>
        </div>
      )}

      {(shippingProfiles.length > 0) && <ShippingProfileCard/>}

      {!selectedShippingProfile && (
        <FormCards.Card>
          <FormCards.FormSection
            title={I18n.t('discovery.sellForm.shippingSection.deliveryOptionsHeader')}
            subtitle={I18n.t('discovery.sellForm.shippingSection.shippingOptionsCard.subheader')}
            tag="required"
          >
            <div className="mb-2">
              <RCCheckbox
                id="listingOffersShipping"
                name="listingOffersShipping"
                label={
                  <>
                    <span className="mr-2">{I18n.t('discovery.sellForm.shippingSection.deliveryOptions.shipping')}</span>
                    <span>
                      <RCTag variant="highlight">
                        {I18n.t('discovery.sellForm.shippingSection.deliveryOptions.shippingHighlight')}
                      </RCTag>
                    </span>
                  </>
                }
                checked={listingOffersShipping}
                onChange={() => {
                  if (listingOffersShipping) {
                    dispatch({ type: ActionType.SET_NO_SHIPPING });
                  } else {
                    dispatch({ type: ActionType.SET_DEFAULT_SHIPPING, shippingRates: [buildBlankShippingRate(domesticRegion.regionCode)] });
                  }
                }}
                disabled={loading}
              />
            </div>

            <RCCheckbox
              id="listingOffersLocalPickup"
              name="listingOffersLocalPickup"
              label={I18n.t('discovery.sellForm.shippingSection.deliveryOptions.localPickup')}
              checked={listingEntry[Field.OFFERS_LOCAL_PICKUP]}
              onChange={() => dispatch({
                type: ActionType.UPDATE_FIELD,
                fieldKey: Field.OFFERS_LOCAL_PICKUP,
                value: !listingEntry[Field.OFFERS_LOCAL_PICKUP],
              })}
              disabled={loading}
            />
          </FormCards.FormSection>
        </FormCards.Card>
      )}

      {listingOffersShipping && <DomesticShippingCard/>}

      {showRslCard && <PrefersReverbShippingLabelCard/>}

      {listingOffersShipping && <InternationalShippingCard />}
    </>
  );
}

export default ShippingForm;
