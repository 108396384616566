import React, { useEffect } from 'react';
import { useUser } from '@reverbdotcom/commons/src/user_hooks';
import { isApplePayAvailable } from './applePayAvailability';
import {
  core_apimessages_CheckoutApplePayDetails as CheckoutApplePayDetails,
  core_apimessages_CheckoutPaymentMethod_Type as CheckoutPaymentMethodType,
} from '@reverbdotcom/commons/src/gql/graphql';
import { CartItemBundle } from './cart/CartBundleOrderSummary';
import { ApplePayConfig } from './useCartApplePay';

const adyenApplePayVersion = 11;

export function useApplePayAvailability(applePayDetails: Pick<CheckoutApplePayDetails, 'merchantId'>) {
  const [isAvailable, setAvailable] = React.useState(false);
  const [isLoading, setLoading] = React.useState(true);
  const { countryCode: userCountryCode } = useUser();
  const merchantId = applePayDetails?.merchantId;

  useEffect(() => {
    async function checkApplePayAvailability() {
      if (!merchantId) {
        setAvailable(false);
        setLoading(false);
        return;
      }

      const applePay = {
        props: {
          version: adyenApplePayVersion,
          configuration: {
            merchantId,
          },
        },
      };

      const available = await isApplePayAvailable({ applePay, userCountryCode });

      setAvailable(!!available);
      setLoading(false);
    }

    checkApplePayAvailability();
  }, [merchantId, userCountryCode]);

  return { isAvailable, isLoading };
}

export function useApplePayAvailabilityForBundle(bundle: CartItemBundle) {
  const applePayConfig = findApplePayConfig(bundle);
  const { isAvailable } = useApplePayAvailability(applePayConfig);

  return { isAvailable, applePayConfig };
}

function findApplePayConfig(bundle: CartItemBundle): ApplePayConfig {
  const applePayMethod = bundle.availablePaymentMethods.find((paymentMethod) =>
    paymentMethod.type === CheckoutPaymentMethodType.APPLE_PAY,
  );

  if (!applePayMethod) {
    return null;
  }

  return {
    merchantId: applePayMethod.applePay.merchantId,
    merchantName: applePayMethod.applePay.merchantName,
  };
}

export function useApplePayAvailabilityByConfig(paymentMethodsConfig: string) {
  const configuration = parse(paymentMethodsConfig);

  const { isAvailable, isLoading } = useApplePayAvailability(configuration);

  return [isAvailable, isLoading];
}

function parse(paymentMethodsConfig: string) {
  if (!paymentMethodsConfig) { return null; }

  const { paymentMethods } = JSON.parse(paymentMethodsConfig);

  const configuration = paymentMethods.find((method) => method.type === 'applepay')?.configuration;

  return configuration;
}
