import { IUser } from '@reverbdotcom/commons/src/components/user_context_provider';
import { COUNTRY_CODES } from '../../shared/constants';

const CONTROL_GROUP = 0;

const LTS_V3_GROUP = 3;

export function shouldApplyLikelihoodToSellBoosts(user: Partial<IUser>) {
  return user.countryCode === COUNTRY_CODES.US;
}

export function likelihoodToSellExperimentGroup(user: Partial<IUser>) {
  if (user.countryCode === COUNTRY_CODES.US ) {
    return LTS_V3_GROUP;
  }

  return CONTROL_GROUP;
}
