import React from 'react';
import I18n from 'i18n-js';

interface Props {
  inputNamePrefix: string;
}

interface State {
  transitNumber: string;
  institutionNumber: string;
}

const INSTITUTION_NUMBER_LENGTH = 3;
const TRANSIT_NUMBER_LENGTH = 5;

export default class CanadianRoutingNumberFields extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      transitNumber: '',
      institutionNumber: '',
    };
  }

  setTransitNumber(event) {
    const transitNumber = event.target.value;
    if (isValidTransitNumber(transitNumber)) {
      this.setState({ transitNumber });
    }
  }

  setInstitutionNumber(event) {
    const institutionNumber = event.target.value;
    if (isValidInstitutionNumber(institutionNumber)) {
      this.setState({ institutionNumber });
    }
  }

  inputName(methodName) {
    return `${this.props.inputNamePrefix}[${methodName}]`;
  }

  render() {
    return (
      <div className="g-container">
        <div className="g-col-6 g-col-mobile-12">
          <label
            className="string required control-label"
            dangerouslySetInnerHTML={{
              __html: I18n.t('discovery.payoutMethod.directCheckoutProfile.' +
                'bankAccountFields.canada.transitNumber', {
                hint_open: '<span class="hint">',
                hint_close: '</span>',
              }),
            }}
          />
          <input
            className="string required wide user-success"
            maxLength={TRANSIT_NUMBER_LENGTH}
            type="text"
            name={this.inputName('transit_number')}
            onBlur={event => this.setTransitNumber(event)}
          />
        </div>
        <div className="g-col-6 g-col-mobile-12">
          <label
            className="string required control-label"
            dangerouslySetInnerHTML={{
              __html: I18n.t('discovery.payoutMethod.directCheckoutProfile.' +
                'bankAccountFields.canada.institutionNumber', {
                hint_open: '<span class="hint">',
                hint_close: '</span>',
              }),
            }}
          />
          <input
            className="string required wide user-success"
            maxLength={INSTITUTION_NUMBER_LENGTH}
            type="text"
            name={this.inputName('institution_number')}
            onBlur={event => this.setInstitutionNumber(event)}
          />
        </div>
      </div>
    );
  }
}

function isValidTransitNumber(transitNumber: string) {
  return transitNumber.length === TRANSIT_NUMBER_LENGTH;
}

function isValidInstitutionNumber(institutionNumber: string) {
  return institutionNumber.length === INSTITUTION_NUMBER_LENGTH;
}
