import StringUtil from './string_util';

export function getSafely(hash, keyStr, defaultVal = null) {
  if (!hash) { return defaultVal; }

  const keys = keyStr.split('.');
  if (keys.length === 1) {
    return hash[keys[0]] || defaultVal;
  }

  const remainingKeys = keys.slice(1);
  const newHash = hash[keys[0]];
  if (newHash) {
    return getSafely(newHash, remainingKeys.join('.'), defaultVal);
  }

  return newHash || defaultVal;
}

export function getLink(obj: object, name: string): string {
  return getSafely(obj, `_links.${name}.href`);
}

export function camelizeKeys(hash = {}) {
  const newHash = {};

  Object.keys(hash).forEach((key) => {
    const camelizedKey = StringUtil.underscoreToCamelCase(key);
    const value = hash[key];
    newHash[camelizedKey] = value;
  });

  return newHash;
}

export function underscoreKeys(hash = {}) {
  const newHash = {};

  Object.keys(hash).forEach((key) => {
    const underscoredKey = StringUtil.camelCaseToUnderscore(key);
    const value = hash[key];
    newHash[underscoredKey] = value;
  });

  return newHash;
}

export function rejectBlankValues(hash = {}) {
  const newHash = {};

  Object.keys(hash).forEach((key) => {
    const value = hash[key];

    if (value !== '' && value !== undefined && value !== null) {
      newHash[key] = value;
    }
  });

  return newHash;
}

export default {
  getSafely,
  getLink,
  camelizeKeys,
  underscoreKeys,
  rejectBlankValues,
};
