import React from 'react';
import { compact } from 'lodash';
import { shouldShowBumpTag } from '@reverbdotcom/commons/src/components/bump_tag';
import {
  Core_Marketplace_CombinedMarketplaceSearchQuery,
  core_apimessages_Ad,
} from '@reverbdotcom/commons/src/gql/graphql';
import { PageSettings } from './map_params';
import { IUser } from '@reverbdotcom/commons/src/components/user_context_provider';
import TileCurationView from '../discovery/curation/tile_curation_view';
import ListingCard from '@reverbdotcom/commons/src/components/listing_card';
import { AdCard } from '@reverbdotcom/commons/src/components/ads/ad_card';
import ListViewRowCard from '../list_view_row_card';
import { WatchParentComponentNames } from '@reverbdotcom/commons/src/event_tracking/watchlist';
import { RCListingGrid } from '@reverbdotcom/cadence/components';

const COMPONENT_NAME = 'InterspersedListings';

type IListing = Core_Marketplace_CombinedMarketplaceSearchQuery['bumpedSearch']['listings'][0];

interface IListingsProps {
  listing: IListing;
  idx: number;
  user: Partial<IUser>;
  showOnlySold?: boolean;
  trackingName?: string;
  trackingQuery?: string;
  trackingFilter?: string;
  trackingSort?: string;
  trackingPage?: number;
  pageSettings?: PageSettings;
  displayAsRows?: boolean;
  loading?: boolean;
}

function listingKey(listing: IListing): string {
  if (listing.bumpKey?.key) {
    return `${listing.id}-${listing.bumpKey?.key}`;
  }

  return listing.id;
}

export function Listing({
  listing,
  idx,
  user,
  showOnlySold = false,
  trackingName = COMPONENT_NAME,
  trackingQuery = '',
  trackingFilter = '',
  trackingSort = '',
  trackingPage = 1,
  pageSettings = undefined,
  displayAsRows = false,
  loading = false,
}: IListingsProps) {
  if (displayAsRows) {
    return (
      <ListViewRowCard
        key={listing.id}
        listing={listing}
        loading={loading}
        position={idx}
        trackingQuery={trackingQuery}
        trackingFilter={trackingFilter}
        trackingSort={trackingSort}
        trackingPage={trackingPage}
        showingSold={showOnlySold}
        titleHtmlTag={'h2'}
      />
    );
  }

  return (
    <ListingCard
      key={listing.id}
      listing={listing}
      position={idx}
      soldListing={showOnlySold}
      trackingName={trackingName}
      trackingQuery={trackingQuery}
      trackingFilter={trackingFilter}
      trackingSort={trackingSort}
      trackingPage={trackingPage}
      watchBadgeParentComponentName={WatchParentComponentNames.MarketplaceGridWatch}
      titleHtmlTag={'h2'}
      footerContent={
        user.isAdmin &&
        <TileCurationView
          listingId={listing.id}
          pageSettings={pageSettings}
          bumped={listing.bumped}
        />
      }
    />
  );
}

// This function intersperses the bump listings amongst normal listings.
// It is setup so that every 2nd and 3rd tile (of out a set of 7) in the grid are bumped listings.
function intersperseBumps(listings, bumps, starting, frequency) {
  let bumpIndex = 0;
  const interspersedArray = [];
  for (let i = 0; i < listings.length; i += 1) {
    if ((i + starting) % frequency === 0) {
      interspersedArray.push(bumps[bumpIndex], bumps[bumpIndex + 1]);
      bumpIndex += 2;
    }
    interspersedArray.push(listings[i]);
  }

  return compact(interspersedArray);
}

function setTrackingName(listing) {
  return shouldShowBumpTag(listing) ? `Bumped${COMPONENT_NAME}` : COMPONENT_NAME;
}

interface AdListingItemProps {
  ad: core_apimessages_Ad;
  idx: number;
  displayAsRows?: boolean;
}

function AdListingItem({ ad, idx, displayAsRows = false }: AdListingItemProps) {
  const adCardElement = (
    <div>
      <AdCard
        ad={ad}
        key={idx}
        componentName="CombinedBumpListingGrid"
      />
    </div>
  );

  return (
    <RCListingGrid.Item colSpan={displayAsRows ? 'all' : 'one'}>
      {adCardElement}
    </RCListingGrid.Item>
  );
}

interface ListingItemProps {
  listing: IListing;
  idx: number;
  user: Partial<IUser>;
  showOnlySold: any;
  trackingQuery?: string;
  trackingFilter?: string;
  trackingSort?: string;
  trackingPage?: number;
  pageSettings?: PageSettings;
  displayAsRows?: boolean;
  loading?: boolean;
}

function ListingItem({
  listing,
  idx,
  user,
  showOnlySold,
  trackingQuery = '',
  trackingFilter,
  trackingSort,
  trackingPage,
  pageSettings = undefined,
  displayAsRows = false,
  loading = false,
}: ListingItemProps) {
  const listingElement = (
    <Listing
      listing={listing}
      idx={idx}
      user={user}
      showOnlySold={showOnlySold}
      trackingName={setTrackingName(listing)}
      trackingQuery={trackingQuery}
      trackingFilter={trackingFilter}
      trackingSort={trackingSort}
      trackingPage={trackingPage}
      pageSettings={pageSettings}
      displayAsRows={displayAsRows}
      loading={loading}
    />
  );

  return (
    <RCListingGrid.Item colSpan={displayAsRows ? 'all' : 'one'}>
      {listingElement}
    </RCListingGrid.Item>
  );
}

function randomAdPosition() {
  // provides a random position in the second row (index positions 5 through 9)
  const AD_POSITION_MIN = 5;
  const AD_POSITION_MAX = 9;
  return Math.floor(Math.random() * (AD_POSITION_MAX - AD_POSITION_MIN + 1) + AD_POSITION_MIN);
}

// used for calculating the idx of listing items if an ad is inserted
function listingItemAdsIndex(
  index: number,
  adIndex: number,
  isAd: boolean,
): number {
  if (!isAd) return index;

  return index < adIndex ? index : index + 1;
}

interface RenderListingsProps {
  listings: IListing[];
  ad: core_apimessages_Ad;
  showOnlySold: boolean;
  trackingQuery: string;
  trackingFilter: string;
  trackingSort: string;
  trackingPage: number;
  user: Partial<IUser>;
  pageSettings?: PageSettings;
  displayAsRows?: boolean;
  loading?: boolean;
}

function ListingsWithAd({
  listings,
  ad,
  showOnlySold,
  trackingQuery,
  trackingFilter,
  trackingSort,
  trackingPage,
  user,
  pageSettings = undefined,
  displayAsRows = false,
  loading = false,
}: RenderListingsProps) {
  const adPosition = randomAdPosition();

  const listingItems = listings.map((listing, idx) =>
    <ListingItem
      key={listingKey(listing)}
      listing={listing}
      idx={listingItemAdsIndex(idx, adPosition, !!ad)}
      user={user}
      showOnlySold={showOnlySold}
      trackingQuery={trackingQuery}
      trackingFilter={trackingFilter}
      trackingSort={trackingSort}
      trackingPage={trackingPage}
      pageSettings={pageSettings}
      displayAsRows={displayAsRows}
      loading={loading}
    />,
  );

  if (!ad) {
    return (
      <>{listingItems}</>
    );
  }

  const adItem = (
    <AdListingItem
      key={ad.uuid}
      idx={adPosition}
      ad={ad}
      displayAsRows={displayAsRows}
    />
  );

  listingItems.splice(adPosition, 0, adItem);

  return (
    <>{listingItems}</>
  );
}

interface IProps {
  user: Partial<IUser>;
  listings: IListing[];
  bumpedListings: IListing[];
  showOnlySold?: boolean;
  trackingQuery?: string;
  trackingFilter?: string;
  trackingSort?: string;
  trackingPage?: number;
  ad?: core_apimessages_Ad;
  pageSettings?: PageSettings;
  displayAsRows?: boolean;
  loading?: boolean;
}

export default function InterspersedListings({
  user,
  listings,
  bumpedListings,
  showOnlySold = false,
  trackingQuery = '',
  trackingFilter = '',
  trackingSort = '',
  trackingPage = 1,
  ad = undefined,
  pageSettings = undefined,
  displayAsRows = false,
  loading = false,
}: IProps) {
  const combinedListings = intersperseBumps(listings, bumpedListings, 4, 5);
  const listingsToUse = listings.length < 25 ? listings : combinedListings;
  return (
    <ListingsWithAd
      listings={listingsToUse}
      ad={ad}
      showOnlySold={showOnlySold}
      trackingQuery={trackingQuery}
      trackingFilter={trackingFilter}
      trackingSort={trackingSort}
      trackingPage={trackingPage}
      user={user}
      pageSettings={pageSettings}
      displayAsRows={displayAsRows}
      loading={loading}
    />
  );
}
