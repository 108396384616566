export default {
  groups: {
    zero: '%{groupName} group',
    one: '%{groupName} and %{count} other group',
    other: '%{groupName} and %{count} other groups',
  },
  users: {
    one: '%{count} individual tester',
    other: '%{count} individual testers',
  },
};
