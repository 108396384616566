import React from 'react';
import CartPaymentMethodSelector from '@reverbdotcom/discovery-ui/src/components/CartPaymentMethodSelector';

export default function CheckoutBuyerPaymentMethodSelectorWrapper({
  showReverbProtectionCallout,
  canUseReverbCredits,
  reverbCredits,
  acceptedPaymentMethods,
  affirmEstimatedPaymentText,
  affirmLearnMoreModalProps,
  initialPaymentMethodSelection,
  klarnaAccountEstimatedTotal,
  paypalCheckoutEligibilityAttributes,
}) {
  const [selectedPaymentMethod, setSelectedPaymentMethod] = React.useState('');

  const onPaymentMethodSelect = (paymentMethod) => {
    setSelectedPaymentMethod(paymentMethod);
  };

  return (
    <span>
      <input
        type="hidden"
        name="payment_method"
        value={selectedPaymentMethod}
        checked={selectedPaymentMethod !== ''}
        readOnly
      />
      <CartPaymentMethodSelector
        showReverbProtectionCallout={showReverbProtectionCallout}
        canUseReverbCredits={canUseReverbCredits}
        reverbCredits={reverbCredits}
        acceptedPaymentMethods={acceptedPaymentMethods}
        affirmEstimatedPaymentText={affirmEstimatedPaymentText}
        affirmLearnMoreModalProps={affirmLearnMoreModalProps}
        initialPaymentMethodSelection={initialPaymentMethodSelection}
        onPaymentMethodSelect={onPaymentMethodSelect}
        klarnaAccountEstimatedTotal={klarnaAccountEstimatedTotal}
        paypalCheckoutEligibilityAttributes={paypalCheckoutEligibilityAttributes}
      />
    </span>
  );
}
