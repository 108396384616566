import React from 'react';
import I18n from 'i18n-js';
import { useUser } from '@reverbdotcom/commons/src/user_hooks';
import FooterLocalePicker from './footer_locale_picker';
import { IUser } from '@reverbdotcom/commons/src/components/user_context_provider';
import { isEU } from '../lib/geo';
import { RCIcon, RCTextWithIcon } from '@reverbdotcom/cadence/components';
import {
  InstagramIcon,
  FacebookAltIcon,
  YoutubePlayIcon,
  XIcon,
  PinterestIcon,
  ReverbLogoCircleIcon,
  TiktokIcon,
} from '@reverbdotcom/cadence/icons/react';
import CoreLink from '@reverbdotcom/commons/src/components/core_link';
import { MParticleEventName } from '@reverbdotcom/commons/src/elog/mparticle_types';
import { Paths } from './shared/url_helpers';
import { preferLightTheme } from '@reverbdotcom/cadence/components/helpers/themeOverrides';

const COMPONENT_NAME = 'SiteSubFooter';

function AppCalloutLink() {
  const clickEvent = {
    eventName: MParticleEventName.ClickedAppCalloutButton,
    componentName: COMPONENT_NAME,
  };

  return (
    <div className="site-subfooter__app-callout">
      <CoreLink
        to={Paths.mobileAppsLanding.expand({})}
        clickEvent={clickEvent}
      >
        <RCTextWithIcon svgComponent={ReverbLogoCircleIcon} placement="left">
          {I18n.t('commons.mobileAppCallout')}
        </RCTextWithIcon>
      </CoreLink>
    </div>
  );
}

function SocialIcons() {
  return (
    <ul className="site-subfooter__social-icons">
      <li className="site-subfooter__social-icons-list-item">
        <a
          href="https://www.instagram.com/reverb/"
          className="site-subfooter__social-icon"
          target="_blank"
          rel="noopener noreferrer"
          aria-label="Instagram"
        >
          <RCIcon svgComponent={InstagramIcon} inline />
        </a>
      </li>
      <li className="site-subfooter__social-icons-list-item">
        <FacebookSocialIcon />
      </li>
      <li className="site-subfooter__social-icons-list-item">
        <a
          href="https://www.pinterest.com/reverbmarket/"
          className="site-subfooter__social-icon"
          target="_blank"
          rel="noopener noreferrer"
          aria-label="Pinterest"
        >
          <RCIcon svgComponent={PinterestIcon} inline />
        </a>
      </li>
      <li className="site-subfooter__social-icons-list-item">
        <a
          href="https://x.com/reverb"
          className="site-subfooter__social-icon"
          target="_blank"
          rel="noopener noreferrer"
          aria-label="X"
        >
          <RCIcon svgComponent={XIcon} inline />
        </a>
      </li>
      <li className="site-subfooter__social-icons-list-item">
        <a
          href="https://www.tiktok.com/@reverbdotcom"
          className="site-subfooter__social-icon"
          target="_blank"
          rel="noopener noreferrer"
          aria-label="X"
        >
          <RCIcon svgComponent={TiktokIcon} inline />
        </a>
      </li>
      <li className="site-subfooter__social-icons-list-item">
        <a
          href="https://www.youtube.com/user/reverbmarket"
          className="site-subfooter__social-icon"
          target="_blank"
          rel="noopener noreferrer"
          aria-label="YouTube"
        >
          <RCIcon svgComponent={YoutubePlayIcon} inline />
        </a>
      </li>
    </ul>
  );
}

function FacebookSocialIcon() {
  const facebookURL = findFacebookURL(useUser());
  return (
    <a
      href={facebookURL}
      className="site-subfooter__social-icon"
      target="_blank"
      rel="noopener noreferrer"
      aria-label="Facebook"
    >
      <RCIcon svgComponent={FacebookAltIcon} inline />
    </a>
  );
}

export function findFacebookURL({ countryCode }: Partial<IUser>) {
  if (['GB', 'IE'].includes(countryCode)) {
    return 'https://www.facebook.com/ReverbUKandIE';
  }
  if (isEU(countryCode)) {
    return 'https://www.facebook.com/ReverbEurope';
  }

  return 'https://www.facebook.com/reverbmarket';
}

export function SiteSubFooter() {
  return (
    <div className="site-subfooter" {...preferLightTheme()}>
      <div className="site-wrapper">
        <div className="d-flex fx-align-center tablet-d-block">
          <div>
            <FooterLocalePicker />
          </div>
          <div className="site-subfooter__icon-messages">
            <div
              className="site-subfooter__icon-message site-subfooter__icon-message--gives"
            >
              <div>
                <h4>
                  {I18n.t('discovery.siteCallouts.givesTitle')}
                </h4>
                <p>
                  {I18n.t('discovery.siteCallouts.givesDescription')}
                </p>
              </div>
            </div>
            <div
              className="site-subfooter__icon-message site-subfooter__icon-message--carbon"
            >
              <div>
                <h4>
                  {I18n.t('discovery.siteCallouts.carbonTitle')}
                </h4>
                <p>
                  {I18n.t('discovery.siteCallouts.carbonDescription')}
                </p>
              </div>
            </div>
          </div>
          <div>
            <SocialIcons />
            <AppCalloutLink />
          </div>
        </div>
      </div>
      <div className="site-wrapper size-90 mt-2 tablet-align-center">
        <ul>
          <li className="d-inline-block mr-2">
            {I18n.t('discovery.siteSubFooter.copyright', { year: new Date().getFullYear() })}
          </li>
          <li className="d-inline-block mr-2">
            <a href="/page/terms" className="text-link">
              {I18n.t('discovery.siteSubFooter.terms')}
            </a>
          </li>
          <li className="d-inline-block">
            <a href="/page/privacy" className="text-link">
              {I18n.t('discovery.siteSubFooter.privacy')}
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default SiteSubFooter;
