import { MutationFunction } from '@reverbdotcom/commons/src/useHOCMutation';
// TODO update this to import from commons
// import { gql } from '@reverbdotcom/commons/src/gql';
// eslint-disable-next-line no-restricted-imports
import { FetchResult, gql } from '@apollo/client';

import * as I18n from 'i18n-js';
import { IUser } from '@reverbdotcom/commons/src/components/user_context_provider';
import {
  CheckoutApplePayLineItemFields,
  CoreCheckoutFinalizeApplePayPayment,
  core_apimessages_AddressEntry as AddressEntry,
  core_apimessages_Checkout_Total as CheckoutTotal,
  core_apimessages_Checkout_Type as CheckoutType,
  Input_core_apimessages_GuestCheckoutUserEntry as GuestCheckoutUserEntry,
} from '@reverbdotcom/commons/src/gql/graphql';
import { withGraphql } from '@reverbdotcom/commons/src/with_graphql';

type Checkout = CheckoutApplePayLineItemFields.Fragment;
type CheckoutLineItem = Checkout['lineItems'][0];

interface AdyenPaymentMethod {
  type: string;
  configuration?: { merchantId: string };
}

interface AdyenPaymentMethodsConfiguration {
  paymentMethods: AdyenPaymentMethod[];
}

export type FinalizePaymentMutationFn =
  MutationFunction<CoreCheckoutFinalizeApplePayPayment.Mutation, CoreCheckoutFinalizeApplePayPayment.Variables>;

const COMMON_REQUIRED_SHIPPING_CONTACT_FIELDS: ApplePayJS.ApplePayContactField[] = [
  'postalAddress',
  'name',
  'phone',
];

export function merchantName() {
  return I18n.t('discovery.checkout.applePay.merchantName');
}

/**
 * @param value Any JSON-encodable value
 * @returns A base-64 encoded value
 */
export function mapJsonToBase64(value: any) {
  // btoa isn't deprecated for web browsers. The deprecation notice
  // given by TypeScript only applies to node.js.
  // See <https://github.com/microsoft/TypeScript/issues/45566> for details.
  return btoa(JSON.stringify(value));
}

export function paymentContactToAddressEntry(shippingContact: ApplePayJS.ApplePayPaymentContact): AddressEntry {
  return {
    name: [shippingContact.givenName, shippingContact.familyName].join(' '),
    streetAddress: shippingContact.addressLines && shippingContact.addressLines[0],
    extendedAddress: shippingContact.addressLines && shippingContact.addressLines[1],
    locality: shippingContact.locality,
    postalCode: shippingContact.postalCode,
    region: shippingContact.administrativeArea,
    countryCode: shippingContact.countryCode?.toUpperCase(),
    phone: shippingContact.phoneNumber,
  };
}

export function guestUserEntry({ shippingContact, checkoutType }: {
  shippingContact: ApplePayJS.ApplePayPaymentContact,
  checkoutType: CheckoutType,
}): GuestCheckoutUserEntry {
  if (checkoutType !== CheckoutType.GUEST) {
    return null;
  }

  return {
    firstName: shippingContact.givenName,
    lastName: shippingContact.familyName,
    email: shippingContact.emailAddress,
  };
}

export function countryCodeOrDefault(countryCode: string) {
  return countryCode || 'US';
}

export function findRequiredShippingContactFields(user: Pick<IUser, 'loggedOut'>): ApplePayJS.ApplePayContactField[] {
  if (user.loggedOut) {
    return [...COMMON_REQUIRED_SHIPPING_CONTACT_FIELDS, 'email'];
  }

  return COMMON_REQUIRED_SHIPPING_CONTACT_FIELDS;
}

export function buildApplePayLineItems(checkout: Checkout) {
  const newTotal = buildTotal(checkout.total);
  const newLineItems = checkout.lineItems.map(buildLineItem);

  return { newTotal, newLineItems };
}

export function findMerchantId({ paymentMethods }: AdyenPaymentMethodsConfiguration) {
  const applePayPaymentMethod = paymentMethods.find((paymentMethod) => paymentMethod.type === 'applepay');

  return applePayPaymentMethod.configuration.merchantId;
}

function buildTotal(total: CheckoutTotal): ApplePayJS.ApplePayLineItem {
  return {
    amount: total.amount.amount,
    label: I18n.t('discovery.checkout.applePay.merchantName'),
    type: 'final',
  };
}

function buildLineItem(lineItem: CheckoutLineItem): ApplePayJS.ApplePayLineItem {
  return {
    amount: lineItem.amount.amount,
    label: lineItem.label,
    type: 'final',
  };
}

export async function withErrorHandling<MutationData>(callback: () => Promise<void | FetchResult<MutationData>>) {
  try {
    return await callback();
  } catch (error) {
    return null;
  }
}

const FINALIZE_APPLE_PAY_PAYMENT = gql`
  mutation Core_Checkout_FinalizeApplePayPayment(
    $input: Input_core_apimessages_CheckoutFinalizeDigitalWalletPaymentRequest
  ) {
    finalizeDigitalWalletPayment(input: $input) {
      id
      checkout {
        _id
        action {
        _id
          type
          redirectUrl
        }
      }
    }
  }
`;

export function finalizePaymentMutation<
  TProps extends CoreCheckoutFinalizeApplePayPayment.Variables | unknown = unknown,
>() {
  return withGraphql<
    TProps,
    CoreCheckoutFinalizeApplePayPayment.Mutation,
    CoreCheckoutFinalizeApplePayPayment.Variables
  >(
    FINALIZE_APPLE_PAY_PAYMENT,
    {
      name: 'finalizeApplePayPayment',
    },
  );
}

export const CheckoutApplePayLineItemFragment = gql`
  fragment CheckoutApplePayLineItemFields on Checkout {
    _id
    total {
      amount {
        amount
        currency
      }
    }
    lineItems {
      type
      label
      amount {
        amount
        amountCents
        currency
      }
    }
  }
`;
