import { DataValue } from '@apollo/client/react/hoc';
import { MutationFunction } from '@reverbdotcom/commons/src/useHOCMutation';

// TODO update this to import from commons
// import { gql } from '@reverbdotcom/commons/src/gql';
// eslint-disable-next-line no-restricted-imports
import { gql } from '@apollo/client';
import { get } from 'lodash';
import React from 'react';
import {
  AddressBook as RQLAddressBook,
  UpdateMyAddress as RQLUpdateMyAddress,
  DeleteMyAddress as RQLDeleteMyAddress,
  Input_core_apimessages_Address as IAddress,
  Input_core_apimessages_UpdateAddressRequest,
  core_apimessages_Error as IError,
  core_apimessages_MyAddressType as IMyAddressType,
} from '@reverbdotcom/commons/src/gql/graphql';

import {
  IAddressFormData,
} from './address_form_data';
import { parseMutationErrors } from '@reverbdotcom/commons/src/parse_graphql_errors';
import { withGraphql } from '@reverbdotcom/commons/src/with_graphql';
import { connect } from '@reverbdotcom/commons/src/connect';

interface IProps {
  data: DataValue<RQLAddressBook.Query, RQLAddressBook.Variables>;
  upsertAddressMutation: MutationFunction<RQLUpdateMyAddress.Mutation, RQLUpdateMyAddress.Variables>;
  deleteAddressMutation: MutationFunction<RQLDeleteMyAddress.Mutation, RQLDeleteMyAddress.Variables>;
}

export interface IAddressResponse {
  address: IAddress;
  errors: IError[];
}

export interface IDeleteAddressResponse {
  addressUuid: string;
}

const gqlQuery = gql`
  query AddressBook {
    countries {
      countries {
        _id
        name
        countryCode
        subregions {
          _id
          name
          code
        }
      }
    }
    me {
      _id
      uuid
      shippingAddresses {
        _id
        uuid
        name
        streetAddress
        extendedAddress
        locality
        region
        regionName
        postalCode
        countryCode
        country {
          _id
          countryCode
          name
        }
        phone
        primary
        legacyId
        isComplete
        verified
      }
    }
  }
`;
const addressBookQuery = withGraphql<any, RQLAddressBook.Query, RQLAddressBook.Variables>(
  gqlQuery,
  {
    options: {
      ssr: true,
    },
  },
);

const upsertAddressMutation =
  withGraphql<any, RQLUpdateMyAddress.Mutation, RQLUpdateMyAddress.Variables>(
    gql`
      mutation UpdateMyAddress($input: Input_core_apimessages_UpdateAddressRequest) {
        updateMyAddress(input: $input) {
          address {
            _id
            uuid
            name
            streetAddress
            extendedAddress
            locality
            region
            regionName
            postalCode
            countryCode
            country {
              _id
              countryCode
              name
            }
            phone
            primary
            legacyId
            isComplete
          }
        }
      }
    `,
    {
      name: 'upsertAddressMutation',
    },
  );

const deleteAddressMutation =
  withGraphql<any, RQLDeleteMyAddress.Mutation, RQLDeleteMyAddress.Variables>(
    gql`
      mutation DeleteMyAddress($input: Input_core_apimessages_AddressDeleteRequest) {
        deleteMyAddress(input: $input) {
          uuid
        }
      }
    `,
    {
      name: 'deleteAddressMutation',
    },
  );

export default function hoc(ChildComponent) {
  const Container = class extends React.Component<IProps, null> {
    constructor(props) {
      super(props);
      this.handleDelete = this.handleDelete.bind(this);
      this.handleUpdate = this.handleUpdate.bind(this);
      this.handleCreate = this.handleCreate.bind(this);
      this.handleMakePrimary = this.handleMakePrimary.bind(this);
      this.handleValidationErrors = this.handleValidationErrors.bind(this);
    }

    async handleMakePrimary(address) {
      const updateData: Input_core_apimessages_UpdateAddressRequest = {
        isPrimary: true,
        uuid: address.uuid,
        addressType: IMyAddressType.SHIPPING,
      };
      return this.props.upsertAddressMutation({
        variables: { input: updateData },
        update: (cache, response) => {
          this.unsetOldPrimaryIfNewPrimary(cache, response.data.updateMyAddress.address);
        },
        optimisticResponse: {
          __typename: 'Mutation',
          updateMyAddress: {
            __typename: 'core_apimessages_UpdateAddressResponse',
            address: { ...address, primary: true },
          },
        },
      });
    }

    async handleDelete(uuid): Promise<boolean> {
      const deleteRequest = { uuid };
      try {
        await this.props.deleteAddressMutation({
          variables: { input: deleteRequest },
          update: (cache) => {
            const data = cache.readQuery({ query: gqlQuery }) as RQLAddressBook.Query;
            cache.modify({
              id: cache.identify(data.me),
              fields: {
                shippingAddresses(existingAddressRefs, { readField }) {
                  return existingAddressRefs.filter(
                    addressRef => uuid !== readField('uuid', addressRef),
                  );
                },
              },
            });
          },
        });
        return true;
      } catch {
        return false;
      }
    }

    async handleCreate(formData: IAddressFormData) {
      const mutationData = this.formDataToMutationRequest(formData);
      const response: IAddressResponse = { address: null, errors: null };
      try {
        const result = await this.props.upsertAddressMutation({
          variables: { input: mutationData },
          update: (cache, response) => {
            const responseAddress = response.data.updateMyAddress.address;
            this.unsetOldPrimaryIfNewPrimary(cache, responseAddress);

            const data = cache.readQuery({ query: gqlQuery }) as RQLAddressBook.Query;
            cache.modify({
              id: cache.identify(data.me),
              fields: {
                shippingAddresses(existingAddressRefs) {
                  return [responseAddress, ...existingAddressRefs];
                },
              },
            });
          },
        });
        response.address = result.data.updateMyAddress.address;
      } catch (error) {
        response.errors = this.handleValidationErrors(error);
      }
      return response;
    }

    async handleUpdate(formData: IAddressFormData) {
      const mutationData = this.formDataToMutationRequest(formData);
      const response: IAddressResponse = { address: null, errors: null };

      try {
        const result = await this.props.upsertAddressMutation({
          variables: { input: mutationData },
          update: (cache, response) => {
            const responseAddress = response.data.updateMyAddress.address;
            this.unsetOldPrimaryIfNewPrimary(cache, responseAddress);
          },
        });
        response.address = result.data.updateMyAddress.address;
      } catch (errors) {
        response.errors = this.handleValidationErrors(errors);
      }
      return response;
    }

    handleValidationErrors(e) {
      const errors: IError[] = parseMutationErrors(e);
      return errors;
    }

    unsetOldPrimaryIfNewPrimary(cache, newAddress: IAddress) {
      if (!newAddress.primary) { return; }

      const data = cache.readQuery({ query: gqlQuery });
      const oldPrimary = data.me.shippingAddresses.find(a => a.primary && a.uuid !== newAddress.uuid);
      if (!oldPrimary) { return; }

      cache.modify({
        id: cache.identify(oldPrimary),
        fields: {
          primary() {
            return false;
          },
        },
      });
    }

    formDataToMutationRequest(formData: IAddressFormData): Input_core_apimessages_UpdateAddressRequest {
      return {
        uuid: formData.uuid,
        addressType: IMyAddressType.SHIPPING,
        address: {
          name: formData.name,
          streetAddress: formData.streetAddress,
          extendedAddress: formData.extendedAddress,
          postalCode: formData.postalCode,
          phone: formData.phone,
          region: formData.region,
          locality: formData.locality,
          countryCode: formData.countryCode,
        },
        isPrimary: formData.primary,
      };
    }

    render() {
      const { data, ...passThroughProps } = this.props;
      const addresses = get(data, 'me.shippingAddresses') || [];

      return (
        <ChildComponent
          {...passThroughProps}
          loading={data.loading}
          error={!!data.error}
          addresses={addresses}
          countries={get(data, 'countries.countries')}
          onCreate={this.handleCreate}
          onDelete={this.handleDelete}
          onMakePrimary={this.handleMakePrimary}
          onUpdate={this.handleUpdate}
        />
      );
    }
  };

  return connect([
    addressBookQuery,
    upsertAddressMutation,
    deleteAddressMutation,
  ])(Container);
}
