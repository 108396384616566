import { connect } from '@reverbdotcom/commons/src/connect';
import * as elog from '@reverbdotcom/commons/src/elog';
import { ExecutionResult } from 'graphql';
import I18n from 'i18n-js';
import React from 'react';

import { RCFavoriteButton } from '@reverbdotcom/cadence/components';
import { RCIcon } from '@reverbdotcom/cadence/components/RCIcon/RCIcon';
import { HeartEmptyIcon, HeartFilledIcon } from '@reverbdotcom/cadence/icons/react';
import { trackEvent } from '@reverbdotcom/commons/src/elog/mparticle_tracker';
import { buildClickedRemoveFavoriteSearch } from '@reverbdotcom/commons/src/event_tracking/favorites';
import { EntityType, EntityTypeName, mapFavoriteToEntityType } from '@reverbdotcom/commons/src/favorites/entity_type';
import { Core_FindFavoriteQuery, DeleteMyFavoriteMutation, core_apimessages_UserActionName } from '@reverbdotcom/commons/src/gql/graphql';
import { useURL } from '@reverbdotcom/commons/src/url_context';
import { useUser } from '@reverbdotcom/commons/src/user_hooks';
import classNames from 'classnames';
import UserActionCallout from '../user_action_callout';
import { DeleteMyFavoriteMutationFn, useDeleteMyFavorite, withDeleteMyFavoriteMutation } from './delete_my_favorite_mutation';
import { FavoriteModal, useFindFavorite } from './find_favorite_context';

type Core_FindFavoriteQuery_Favorite = Core_FindFavoriteQuery['findFavorite']['favorite'];

export interface ExternalProps {
  openModal?: () => void;
  renderWithBanner: boolean;

  canFavorite?: boolean; // used on non-marketplace pages where findFavoriteContext can't discern favorite status
  isFavorited?: boolean; // used on non-marketplace pages where findFavoriteContext can't discern favorite status
  entityType?: EntityTypeName; // used on non-marketplace pages where findFavoriteContext can't discern favorite status
  condensed?: boolean;
  favoriteId?: string;
  handleFavoriteRemove?: (result: ExecutionResult<DeleteMyFavoriteMutation>) => void;
  showCallout?: boolean;
  buttonTypeOverride?: 'link' | 'secondary';
  componentName?: string;
  size?: 'default' | 'small' | 'large';
}

interface MutationProps {
  deleteMyFavorite: DeleteMyFavoriteMutationFn;
}

export type Props = ExternalProps & MutationProps;

export function InternalFavoriteButton(props: Props) {
  const url = useURL();
  const user = useUser();
  const { favorite, setFavorite, setIsToastOpen, canFavorite, setDisplayedModal, setFavoriteIntentComponent } = useFindFavorite();
  const id = props.favoriteId || favorite.id;
  const { deleteMyFavorite } = useDeleteMyFavorite(props.deleteMyFavorite, id);
  const entityType = props.entityType || mapFavoriteToEntityType(favorite);
  const disabled = !(props.canFavorite || canFavorite);
  const favorited = props.isFavorited || favorite.favorited;

  const defaultOpenModal = function (loggedOut, canFavorite) {
    const modalForDisplay = loggedOut ? FavoriteModal.Signup : FavoriteModal.Save;
    if (!canFavorite) {
      return;
    }

    if (props.componentName?.length > 0) {
      setFavoriteIntentComponent(props.componentName);
    }
    setDisplayedModal(modalForDisplay);
  };

  const handleClick = async function (e) {
    e.preventDefault();

    if (disabled) {
      return;
    }

    if (favorited) {
      try {
        const result = await deleteMyFavorite();
        if (props.handleFavoriteRemove) { props.handleFavoriteRemove(result); }
        const newFavorite: Core_FindFavoriteQuery_Favorite = {
          ...favorite,
          favorited: false,
        };
        setFavorite(newFavorite);
        trackEvent(buildClickedRemoveFavoriteSearch(newFavorite, url));
        setIsToastOpen(true);
      } catch (e) {
        elog.error(
          e.message,
          { componentName: 'FavoriteButton' },
          e,
        );
      }
    } else {
      if (props.openModal) {
        props.openModal();
      } else {
        defaultOpenModal(user.loggedOut, canFavorite);
      }
    }
  };

  const buttonType = props.buttonTypeOverride || (entityType === EntityType.Product || props.renderWithBanner ? 'secondary' : 'link');
  const classes = classNames(
    'favorite-button__button',
    {
      'favorite-button__button--link': buttonType === 'link',
      'favorite-button__button--secondary': buttonType === 'secondary',
      'favorite-button__button--favorited': buttonType !== 'link' && favorited,
      'favorite-button__button--disabled': disabled,
    },
  );

  const renderButton = () => {
    return ( props.condensed ?
      <RCFavoriteButton
        favorited={favorited}
        onClick={handleClick}
        disabled={disabled}
        size={props.size}
      />
      :
      <button
        className={classes}
        onClick={handleClick}
        type="button"
        data-disabled={disabled || null}
      >
        <RCIcon svgComponent={favorited ? HeartFilledIcon : HeartEmptyIcon} inline />
        {
          favorited ?
            I18n.t('discovery.favorites.favoriteButton.saved') :
            I18n.t(`discovery.favorites.favoriteButton.save.${entityType}`)
        }
      </button>
    );
  };

  const showSaveSearchEducationCallout = !favorited && entityType === EntityType.Search;

  return (
    <>
      { props.showCallout &&
        <UserActionCallout
          anchorComponent={
            renderButton()
          }
          parentComponentName="FavoriteButton"
          userActionName={core_apimessages_UserActionName.SAVE_SEARCH_EDUCATION_CALLOUT}
          showCallout={showSaveSearchEducationCallout}
        />
      }
      { !props.showCallout && renderButton() }
    </>
  );
}

export const FavoriteButton = connect<ExternalProps>([
  withDeleteMyFavoriteMutation,
])(InternalFavoriteButton);
