import React, { useEffect, useState } from 'react';
import I18n from 'i18n-js';
import {
  core_apimessages_Money,
} from '@reverbdotcom/commons/src/gql/graphql';
import { ExclamationTriangleIcon, NudgeExploreIcon, NudgeGemIcon } from '@reverbdotcom/cadence/icons/react';
import classNames from 'classnames';
import { useQuery } from '@reverbdotcom/commons/src/useQuery';
import { conditionUuidForSlug } from '@reverbdotcom/commons/src/condition_helpers';
import { MParticleEventName, trackEvent } from '@reverbdotcom/commons/src/elog/mparticle_tracker';
import { gql } from '@reverbdotcom/commons/src/gql';
import { RCBox, RCFlex, RCIcon, RCLoadingBars, RCText } from '@reverbdotcom/cadence/components';
import { debounce } from 'lodash';
import { isConditionIneligibleForEstimates } from './pricing_guidance/SellFormPricingGuidance';

const LIKELY_TO_SELL_SCORE = 0;
const LESS_LIKELY_TO_SELL_SCORE = 0.6;
const UNLIKELY_TO_SELL_SCORE = 1.3;
const MINIMUM_PRICE_CENTS = 100;
const COMPONENT_NAME = 'SellFormPriceSignalCallout';

interface IExternalProps {
  calloutPrice: core_apimessages_Money;
  conditionSlug: string;
  canonicalProductId: string;
  trackEvents?: boolean;
}

function getPriceCalloutData(
  priceCondition: string,
  estimatedNewListingPrice: core_apimessages_Money,
) {
  const calloutDataMap = {
    priceHigherThanNewListingPrice: {
      icon: ExclamationTriangleIcon,
      title: I18n.t('discovery.sellForm.pricingSection.priceSignals.higherThanNewPrice.title'),
      text: I18n.t('discovery.sellForm.pricingSection.priceSignals.higherThanNewPrice.text', {
        newPrice: estimatedNewListingPrice?.display,
      }),
      colorFamily: 'sentiment-negative',
    },
    likelyToSell: {
      icon: NudgeGemIcon,
      title: I18n.t('discovery.sellForm.pricingSection.priceSignals.likelyToSell.title'),
      text: I18n.t('discovery.sellForm.pricingSection.priceSignals.likelyToSell.text'),
      colorFamily: 'blue',
    },
    aboveAverage: {
      icon: NudgeExploreIcon,
      iconColor: 'rc-color-fill-box-vu-orange-subtle',
      title: I18n.t('discovery.sellForm.pricingSection.priceSignals.aboveAverage.title'),
      text: I18n.t('discovery.sellForm.pricingSection.priceSignals.aboveAverage.text'),
      colorFamily: 'gray',
    },
    lessLikelyToSell: {
      icon: NudgeExploreIcon,
      iconColor: 'rc-color-fill-box-vu-orange-subtle',
      title: I18n.t('discovery.sellForm.pricingSection.priceSignals.lessLikelyToSell.title'),
      text: I18n.t('discovery.sellForm.pricingSection.priceSignals.lessLikelyToSell.text'),
      colorFamily: 'gray',
    },
    unlikelyToSell: {
      icon: NudgeExploreIcon,
      title: I18n.t('discovery.sellForm.pricingSection.priceSignals.unlikelyToSell.title'),
      text: I18n.t('discovery.sellForm.pricingSection.priceSignals.unlikelyToSell.text'),
      colorFamily: 'vu-orange',
    },
  };

  return calloutDataMap[priceCondition];
}


function findPriceCallout({ calloutPrice, estimatedNewListingPrice, priceScore }) {
  const priceHigherThanNewListingPrice = calloutPrice?.amountCents > estimatedNewListingPrice?.amountCents;
  const likelyToSell = priceScore <= LIKELY_TO_SELL_SCORE;
  const aboveAverage = priceScore > LIKELY_TO_SELL_SCORE && priceScore <= LESS_LIKELY_TO_SELL_SCORE;
  const lessLikelyToSell = priceScore > LESS_LIKELY_TO_SELL_SCORE && priceScore <= UNLIKELY_TO_SELL_SCORE;
  const unlikelyToSell = priceScore > UNLIKELY_TO_SELL_SCORE;

  const priceCallouts = [
    { condition: 'priceHigherThanNewListingPrice', active: priceHigherThanNewListingPrice },
    { condition: 'aboveAverage', active: aboveAverage },
    { condition: 'unlikelyToSell', active: unlikelyToSell },
    { condition: 'lessLikelyToSell', active: lessLikelyToSell },
    { condition: 'likelyToSell', active: likelyToSell },
  ];
  return priceCallouts.find(c => c.active)?.condition ?? null;
}


export default function SellFormPriceSignalCallout({
  calloutPrice,
  canonicalProductId,
  conditionSlug,
  trackEvents = false,
}: IExternalProps) {
  const conditionUuid = conditionUuidForSlug(conditionSlug);
  const [debouncedCalloutPrice, setDebouncedCalloutPrice] = useState(calloutPrice);
  const debouncedSetCalloutPrice = debounce(setDebouncedCalloutPrice, 500);
  // considers the user is done typing when the debounced price is the same as the current price
  const finishedInput = calloutPrice.amountCents == debouncedCalloutPrice.amountCents;

  useEffect(() => {
    debouncedSetCalloutPrice(calloutPrice);
  }, [calloutPrice]);

  const {
    data: newPriceData,
  } = useQuery(
    ESTIMATED_NEW_LISTING_PRICE_QUERY,
    {
      skip: !canonicalProductId,
      variables: { canonicalProductId },
    },
  );

  const {
    data: priceScoresData,
    loading: priceScoresLoading,
  } = useQuery(
    PRICE_SCORE_QUERY,
    {
      skip: !canonicalProductId || isConditionIneligibleForEstimates(conditionSlug) || !finishedInput,
      variables: {
        canonicalProductId,
        conditionUuid: conditionUuid,
        price: debouncedCalloutPrice,
        countryCode: 'US',
      },
    },
  );

  const priceHigherThanMinimumPrice = calloutPrice?.amountCents > MINIMUM_PRICE_CENTS;
  const estimatedNewListingPrice = newPriceData?.canonicalproduct?.estimatedNewListingPrice?.price;
  const priceScore = priceScoresData?.priceScores?.priceScores[0]?.priceScore;

  const activePriceCallout = priceHigherThanMinimumPrice && findPriceCallout({
    calloutPrice,
    estimatedNewListingPrice,
    priceScore,
  });

  const calloutData = getPriceCalloutData(activePriceCallout, estimatedNewListingPrice);

  // creating a 'fake' loading between debounces to prevent flickering
  const loading = !finishedInput || priceScoresLoading;

  const visible = canonicalProductId && priceHigherThanMinimumPrice && (loading || calloutData);

  const calloutClasses = classNames(
    'sell-form-price-signal-callout', {
      'sell-form-price-signal-callout--visible': visible,
    },
  );

  useEffect(() => {
    if (trackEvents && activePriceCallout) {
      trackEvent({
        eventName: MParticleEventName.ComponentView,
        componentName: COMPONENT_NAME,
        currentView: activePriceCallout,
      });
    }
  }, [activePriceCallout]);

  return (
    <div className={calloutClasses}>
      <div>
        {loading &&
          <RCBox
            borderRadius="rc-border-radius-md"
            colorFamily="gray"
            display="block"
            padding="rc-space-5"
            variant="subtle"
          >
            <RCFlex justify="center">
              <RCLoadingBars />
            </RCFlex>
          </RCBox>
        }
        {calloutData && !loading &&
          <RCBox
            borderRadius="rc-border-radius-md"
            colorFamily={calloutData.colorFamily}
            display="block"
            padding="rc-space-4"
            variant="subtle"
          >
            <RCFlex
              align="center"
              gap="2"
            >
              {calloutData.iconColor &&
                <RCText color={calloutData.iconColor}>
                  <RCIcon svgComponent={calloutData.icon} size="rc-space-5" />
                </RCText>
              }

              {!calloutData.iconColor &&
                <RCBox.Icon svgComponent={calloutData.icon} size="rc-space-5" themed />
              }
              <RCFlex
                direction="col"
                gap="0"
              >
                <RCText.Fine weight="bold">
                  {calloutData.title}
                </RCText.Fine>
                <RCText.Fine color="rc-color-text-secondary">
                  {calloutData.text}
                </RCText.Fine>
              </RCFlex>
            </RCFlex>
          </RCBox>
        }
      </div>
    </div>
  );
}

export const ESTIMATED_NEW_LISTING_PRICE_QUERY = gql(`
  query Core_SellForm_EstimatedNewListingPrice($canonicalProductId: String!) {
    canonicalproduct(input: { id: $canonicalProductId }) {
      _id
      estimatedNewListingPrice {
        price {
          amountCents
          display
        }
      }
    }
  }
`);


export const PRICE_SCORE_QUERY = gql(`
  query DataServices_SellForm_PriceScore($canonicalProductId: String!, $conditionUuid: String!, $price: Input_reverb_pricing_Money!, $countryCode: String!){
    priceScores(input: { priceScoreQueries: [{
      canonicalProductId: $canonicalProductId,
      conditionUuid: $conditionUuid,
      price: $price,
      countryCode: $countryCode
    }] }) {
      priceScores {
        priceScore
      }
    }
  }
 `);
