import React from 'react';
import I18n from 'i18n-js';
import bind from '@reverbdotcom/commons/src/bind';
import CoreClient from '../../lib/core_client';
import { ICountry, ICountriesResponse } from '../../schemas/core-schema';
import URLHelpers from '../shared/url_helpers';
import mapAJAXToProps from '../map_ajax_to_props';

const TOP_COUNTRY_CODES = [
  'US',
  'CA',
  'GB',
  'AU',
  'FR',
  'DE',
  'JP',
  'ID',
];

function EmptyCountryOption() {
  return (
    <option value="" key="empty-country">
      {I18n.t('discovery.shopOnboarding.select')}
    </option>
  );
}

interface ExternalProps {
  id?: string;
  inputName?: string;
  onChange: (attributes) => void;
  value: string;
  disabled?: boolean;
  allowedCountryCodes?: readonly string[];
  prioritizeTopCountries?: boolean;
}

interface IProps extends ExternalProps {
  countries: ICountry[];
}

export class CountrySelector extends React.Component<IProps, null> {
  static defaultProps: Partial<IProps> = {
    disabled: false,
    countries: [],
    allowedCountryCodes: null,
  };

  @bind
  onCountryChange(event) {
    this.props.onChange(event.target.value);
  }

  filteredCountries(): ICountry[] {
    if (this.props.allowedCountryCodes) {
      return this.props.countries.filter((countryData) => {
        return this.props.allowedCountryCodes.includes(countryData.country_code);
      });
    }
    return this.props.countries;
  }

  topCountries(): ICountry[] {
    return this.filteredCountries().filter(({ country_code }) => TOP_COUNTRY_CODES.includes(country_code));
  }

  nonTopCountries(): ICountry[] {
    return this.filteredCountries().filter(({ country_code }) => !TOP_COUNTRY_CODES.includes(country_code));
  }

  render() {
    return (
      <div className="styled-dropdown">
        <select
          id={this.props.id}
          name={this.props.inputName}
          onChange={this.onCountryChange}
          value={this.props.value || ''}
          disabled={this.props.disabled}
          required
        >
          {this.props.prioritizeTopCountries && (
            <>
              <EmptyCountryOption />
              <optgroup>
                {this.topCountries().map((country) => (
                  <option
                    value={country.country_code}
                    key={country.country_code}
                  >
                    {country.name}
                  </option>
                ))}
              </optgroup>
              <optgroup>
                {this.nonTopCountries().map((country) => (
                  <option
                    value={country.country_code}
                    key={country.country_code}
                  >
                    {country.name}
                  </option>
                ))}
              </optgroup>
            </>
          )}

          {!this.props.prioritizeTopCountries && (
            <>
              <EmptyCountryOption />
              {this.filteredCountries().map((country) => ((
                <option
                  value={country.country_code}
                  key={country.country_code}
                >
                  {country.name}
                </option>
              )))}
            </>
          )}
        </select>
      </div>
    );
  }
}

function fetcher() {
  return CoreClient.get<ICountriesResponse>(URLHelpers.countriesPath).then((response) => {
    const { countries } = response;
    return { countries };
  });
}

// The default export automatically fetches countries,
// and the base CountrySelector is also exported to support
// injecting countries in case they are loaded elsewhere.
export default mapAJAXToProps<ExternalProps>(fetcher)(CountrySelector, 'CountrySelector');
