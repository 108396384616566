import React from 'react';
import I18n from 'i18n-js';

import Loading from '../../shared/loading';
import BarGraph, { IProps } from '../../components/price_guide_tool/graph/bar_graph';

interface IStatsGraphProps {
  chartData: {
    currency_symbol: string,
    chart_data: IProps['chartData'],
  },
  error: boolean,
}

export default function StatsGraph({
  chartData,
  error,
}: IStatsGraphProps) {

  const hasLoaded = chartData || error;

  if (!hasLoaded) return null;

  const { currency_symbol, chart_data } = chartData;
  const chartOptions = {
    axisX: {
      showGrid: false, // remove vertical gridlines
      onlyInteger: true,
    },
    axisY: {
      referenceValue: 50, // ensure y-axis min value in case no data
      onlyInteger: true,
      labelInterpolationFnc: value => currency_symbol + value.toLocaleString('en'),
    },
  };

  function tooltipCallback(data) {
    const { series, index } = data;
    const value = series[index];

    return currency_symbol + value;
  }

  return (
    <Loading loaded={hasLoaded} hasError={error}>
      <div className="clearfix">
        <div className="seller-sales-chart mt-2">
          <BarGraph
            chartData={chart_data}
            chartOptions={chartOptions}
            tooltipCallback={tooltipCallback}
            ariaLabel={I18n.t('shopStats.statsGraph.ariaLabel')}
          />
        </div>
      </div>
    </Loading>
  );
}
