import React from 'react';
import I18n from 'i18n-js';

class Pagination extends React.Component {
  linkText = () => {
    if (this.props.direction === I18n.t('js.previous')) {
      return (
        <span>
          <span className="fa fa-angle-left" /> {this.props.direction}
        </span>
      );
    } if (this.props.direction === I18n.t('js.next')) {
      return (
        <span>
          {this.props.direction} <span className="fa fa-angle-right" />
        </span>
      );
    }
  };

  onClick = () => this.props.clickEvent(this.props.link.href);

  className = () => {
    if (this.props.direction === I18n.t('js.previous')) {
      return 'previous_page';
    } if (this.props.direction === I18n.t('js.next')) {
      return 'next_page';
    }
  };

  render() {
    if (!this.props.link) { return null; }

    return (
      <li className={this.className()}>
        {/* disabling inline here so we can enable this rule globally, but this should be fixed the next time this file is touched */}
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a onClick={this.onClick}>
          {this.linkText()}
        </a>
      </li>
    );
  }
}

export default Pagination;
