import React from 'react';
// TODO update this to import from commons
// import { gql } from '@reverbdotcom/commons/src/gql';
// eslint-disable-next-line no-restricted-imports
import { gql } from '@apollo/client';
import I18n from 'i18n-js';

import { useMutation } from '@reverbdotcom/commons/src/useMutation';
import CoreLink from '@reverbdotcom/commons/src/components/core_link';
import { RCAlertBox, RCButton, RCLoadingBars, RCTextWithIcon } from '@reverbdotcom/cadence/components';
import { core_apimessages_ListingSaleMembership } from '@reverbdotcom/commons/src/gql/graphql';
import { CheckIcon } from '@reverbdotcom/cadence/icons/react';


import { formatInternationalizedSeconds } from '../../../../lib/date_utils';
import SalesMembershipsContext from './SalesMembershipsContext';
import { ActionType } from './salesMembershipsReducer';

export enum SaleTypes {
  REVERB = 'reverb',
  SELLER = 'seller',
}

export const SALE_TYPES = [SaleTypes.REVERB, SaleTypes.SELLER] as const;

export type SaleType = typeof SALE_TYPES[number];

const CREATE_SALE_MEMBERSHIP_MUTATION = gql`
  mutation Core_MyListing_CreateSaleMembership(
    $input: Input_core_apimessages_CreateMySaleMembershipRequest
  ) {
    createMySaleMembership(input: $input) {
      saleMembership {
        listingId
        sale {
          _id
          id
          name
          percent
          startsAt {
            seconds
          }
          endsAt {
            seconds
          }
          links {
            self {
              href
            }
          }
        }
        totalLiveListings
        isListingInSale
        isListingEligible
      }
    }
  }
`;

const DELETE_SALE_MEMBERSHIP_MUTATION = gql`
  mutation Core_MyListing_DeleteSaleMembership(
    $input: Input_core_apimessages_DeleteMySaleMembershipRequest
  ) {
    deleteMySaleMembership(input: $input) {
      saleMembership {
        listingId
        sale {
          _id
          id
          name
          percent
          startsAt {
            seconds
          }
          endsAt {
            seconds
          }
          links {
            self {
              href
            }
          }
        }
        totalLiveListings
        isListingInSale
        isListingEligible
      }
    }
  }
`;

interface IExternalProps {
  saleMembership: core_apimessages_ListingSaleMembership;
  type: SaleType;
}

export function ApplyButtonText({ isListingInSale }) {
  return (
    <>
      {isListingInSale ? (
        <RCTextWithIcon placement="left" svgComponent={CheckIcon}>
          {I18n.t('discovery.listingsManagement.sellerListingCard.sales.modal.saleCard.applied')}
        </RCTextWithIcon>
      ) : (
        <>
          {I18n.t('discovery.listingsManagement.sellerListingCard.sales.modal.saleCard.apply')}
        </>
      )}
    </>
  );
}

export default function SaleCard({
  saleMembership,
  type,
}: IExternalProps) {
  const { dispatch } = React.useContext(SalesMembershipsContext);

  const actionType = type == SaleTypes.REVERB ? ActionType.UPDATE_REVERB_SALE_MEMBERSHIP : ActionType.UPDATE_SELLER_SALE_MEMBERSHIP;

  const {
    listingId,
    sale,
    totalLiveListings,
    isListingEligible,
    isListingInSale,
  } = saleMembership;

  const [createMySaleMembership, {
    data: createData,
    loading: createLoading,
    errors: createErrors,
  }] = useMutation(CREATE_SALE_MEMBERSHIP_MUTATION);

  const [deleteMySaleMembership, {
    data: deleteData,
    loading: deleteLoading,
    errors: deleteErrors,
  }] = useMutation(DELETE_SALE_MEMBERSHIP_MUTATION);

  const mutationLoading = createLoading || deleteLoading;
  const invalidListing = !isListingInSale && !isListingEligible;
  const disableApplyButton = mutationLoading || invalidListing;

  const hasErrors = !!createErrors.length || !!deleteErrors.length;
  const errorMessages = [...createErrors, ...deleteErrors].map(e => e.message).join(' ');

  React.useEffect(() => {
    if (createLoading) return;
    if (createErrors.length) return;

    if (createData) {
      dispatch({
        type: actionType,
        payload: {
          saleMembership: createData.createMySaleMembership.saleMembership,
        },
      });
    }
  }, [createLoading]);

  React.useEffect(() => {
    if (deleteLoading) return;
    if (deleteErrors.length) return;

    if (deleteData) {
      dispatch({
        type: actionType,
        payload: {
          saleMembership: deleteData.deleteMySaleMembership.saleMembership,
        },
      });
    }
  }, [deleteLoading]);

  function createMembership() {
    createMySaleMembership({
      variables: {
        input: {
          listingId: listingId,
          saleId: sale.id,
        },
      },
    });
  }

  function deleteMembership() {
    deleteMySaleMembership({
      variables: {
        input: {
          listingId: listingId,
          saleId: sale.id,
        },
      },
    });
  }

  return (
    <div className="padding-2 bd-1 bd-color-gray-200">
      <div className="d-flex fx-justify-between">
        <div className="d-flex fx-dir-col gap-3">
          <div className="weight-bold">
            {sale.name}
          </div>

          <div className="d-flex fx-justify-start fx-align-end gap-3 size-90">
            <div
              className="text-color-orange-500 weight-semibold"
            >
              {I18n.t('discovery.listingsManagement.sellerListingCard.sales.modal.saleCard.salePercent', {
                percent: sale.percent,
              })}
            </div>

            <div>
              {I18n.t('discovery.listingsManagement.sellerListingCard.sales.modal.saleCard.saleDateRange', {
                startsAt: formatInternationalizedSeconds(sale.startsAt.seconds),
                endsAt: formatInternationalizedSeconds(sale.endsAt.seconds),
              })}
            </div>

            <div className="weight-semibold">
              <CoreLink
                className="td-underline text-color-blue-600"
                to={sale.links.self.href}
                target="_blank"
              >
                {I18n.t('discovery.listingsManagement.sellerListingCard.sales.modal.saleCard.viewSale')}
              </CoreLink>
            </div>
          </div>

          <div
            className="text-color-green-500 size-90 weight-semibold"
          >
            {I18n.t('discovery.listingsManagement.sellerListingCard.sales.modal.saleCard.totalLiveListings', {
              count: totalLiveListings,
            }).toString()}
          </div>
        </div>
        <div>
          <RCButton
            variant="muted"
            onClick={() => isListingInSale ? deleteMembership() : createMembership()}
            disabled={disableApplyButton}
          >
            {mutationLoading ? <RCLoadingBars /> : <ApplyButtonText isListingInSale={isListingInSale} />}
          </RCButton>
        </div>
      </div>

      {hasErrors && (
        <div className="mt-3">
          <RCAlertBox
            type="error"
          >
            <div>
              {errorMessages}
            </div>
          </RCAlertBox>
        </div>
      )}
    </div>
  );
}
