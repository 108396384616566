import I18n from 'i18n-js';
import React from 'react';
import classNames from 'classnames';
import bind from '@reverbdotcom/commons/src/bind';

import Suggestion, { CompletionOption } from './suggestion';
import lowlight from './lowlight';

import { PriceDisplay } from '@reverbdotcom/commons/src/components/PriceDisplay';
import { cspOptionMinPrice, cspOptionListingTotal } from '../helpers/csp_completion_option_helper';
import { formatAmountCents } from '../../money';

interface IProps {
  suggestion: Suggestion;
  active: boolean;
  lastTypedValue: string;
  selectSuggestion: (suggestion: Suggestion) => void;
}

export default class CspCompletionCard extends React.Component<IProps, null> {

  get option(): CompletionOption {
    return this.props.suggestion.completion;
  }

  get hasNoListings() {
    return cspOptionListingTotal(this.option) === 0;
  }

  @bind
  submitSelection(e): void {
    e.preventDefault();

    this.props.selectSuggestion(this.props.suggestion);
  }

  renderTitle() {
    const title = lowlight({
      input: this.props.lastTypedValue,
      output: this.option.output,
      emphasize: (idx, em) => <React.Fragment key={`csp-completion-card-${idx}`}>
        <span>{em.pre}<b>{em.word}{em.post} </b></span>
      </React.Fragment>,
      noop: (idx, em) => <span key={`csp-completion-card-noop-${idx}`}>{em.word} </span>,
    });

    return (
      <div className="csp__suggestion__title">
        {title}
      </div>
    );
  }

  renderImage() {
    const image = this.option.completionPayload.thumbnailUrl;

    return (
      <div className="csp__suggestion__image">
        <div className="csp__suggestion__image__inner">
          <img src={image} alt={this.option.output} />
        </div>
      </div>
    );
  }

  renderPrices() {
    const minPrice = cspOptionMinPrice(this.option);
    if (!minPrice || !minPrice.cents) return null;

    const money = { amountCents: minPrice.cents, currency: minPrice.currency };

    return (
      <div className="csp__suggestion__prices">
        {I18n.t('commons.siteSearch.cspCompletionCard.availableFrom', { count: cspOptionListingTotal(this.option) })}
        <span className="csp__suggestion__prices__price">
          <PriceDisplay
            amountCents={minPrice.cents}
            display={formatAmountCents(money)}
          />
        </span>
      </div>
    );
  }

  render() {
    if (!this.option) return null;
    if (this.hasNoListings) return null;

    const classes = classNames(
      'site-search__dropdown__section__item',
      'site-search__dropdown__section__item--csp',
      { 'site-search__dropdown__section__item--selected': this.props.active },
    );

    return (
      <div key={this.option.id} className={classes}>
        {/* disabling inline here so we can enable this rule globally, but this should be fixed the next time this file is touched */}
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a onClick={this.submitSelection}>
          <div className="csp__suggestion">
            {this.renderImage()}
            <div className="csp__suggestion__content">
              {this.renderTitle()}
              {this.renderPrices()}
            </div>
          </div>
        </a>
      </div>
    );
  }
}