import { Listing } from '@reverbdotcom/commons/src/gql/graphql';
import React, { useState } from 'react';
import { camelize } from 'humps';
import classNames from 'classnames';
import I18n from 'i18n-js';
import BumpButtonV2 from './footer_buttons/BumpButtonV2';
import { bumpRatePercentToDecimal } from '../bump/bump_helpers';
import AutoOfferModalButtonV2 from './footer_buttons/AutoOfferModalButtonV2';
import EditButton from './footer_buttons/EditButton';
import RelistButton from './footer_buttons/RelistButton';
import EndButton from './footer_buttons/EndButton';
import PublishButton from './footer_buttons/PublishButton';
import DeleteButton from './footer_buttons/DeleteButton';
import SalesModalButton from './footer_buttons/sales/SalesModalButton';
import { RCAlertBox } from '@reverbdotcom/cadence/components';
import AffirmZeroPercentButton from './footer_buttons/affirm_zero_percent_financing/AffirmZeroPercentButton';

const LIVE = 'live';
const SUSPENDED = 'suspended';
const SOLD = 'ordered';
const DRAFT = 'draft';

export const SELLER_SITE = 'seller_site';
interface IExternalProps {
  listing: Listing;
}
interface IListingStateProps {
  listing: Listing;
}

interface IDraftDeleteErrorsProps {
  draftDeleteErrors?: Array<string>;
  setDraftDeleteErrors: React.Dispatch<React.SetStateAction<Array<string>>>;
}

export function isListingBumpEligible(listing) {
  const isListingLive = listing.state === LIVE;
  const isSellerSiteExclusive = listing.exclusiveChannel == SELLER_SITE;

  return isListingLive && !isSellerSiteExclusive;
}

export function ListingState({ listing }: IListingStateProps) {
  const isLive = listing.state === LIVE;
  const isSuspended = listing.state === SUSPENDED;

  const { state } = listing;

  return (
    <div className={classNames('seller-listing-card-footer__state', {
      'seller-listing-card-footer__state--default': !isLive && !isSuspended,
      'seller-listing-card-footer__state--live': isLive,
      'seller-listing-card-footer__state--suspended': isSuspended,
    })}>
      {I18n.t(
        `discovery.listingsManagement.sellerListingCard.states.${camelize(
          state,
        )}`,
      ).toUpperCase()}
    </div>
  );
}

export function SellerFeatureButtons({ listing }: IExternalProps) {
  const isListingLive = listing.state === LIVE;
  const isSellerSiteExclusive = listing.exclusiveChannel == SELLER_SITE;

  const bumpRate = listing.bumpRate?.rate || 0;
  const isBumpEligible = isListingBumpEligible(listing);
  const isAutoOffersEnabled = listing.autoOffers.id !== '';
  const showSaleButton = isListingLive && !isSellerSiteExclusive && listing.shop.canCurateSales;
  const showAffirmZeroPercentButton = (listing.affirmZeroPercentFinancingConfiguration.isEligible || listing.affirmZeroPercentFinancingConfiguration.isEnabled) && isListingLive;
  const isAffirmEnabled = listing.affirmZeroPercentFinancingConfiguration.isEnabled;

  return (
    <div className="d-flex fx-wrap fx-justify-start gap-3 pl-4 tablet-fx-dir-col tablet-fx-align-center tablet-pl-0 width-100">
      {showSaleButton && (
        <div className="tablet-width-100">
          <SalesModalButton
            listingId={listing.id}
          />
        </div>
      )}
      {isBumpEligible && (
        <div className="tablet-width-100">
          <BumpButtonV2
            listingId={listing.id}
            bid={bumpRatePercentToDecimal(bumpRate)}
            refetch={() => { }}
          />
        </div>
      )}
      {listing.isAutoOfferEligible && (
        <div className="tablet-width-100">
          <AutoOfferModalButtonV2
            listingId={listing.id}
            offerActive={isAutoOffersEnabled}
          />
        </div>
      )}
      {showAffirmZeroPercentButton && (
        <div className="tablet-width-100">
          <AffirmZeroPercentButton
            listingId={listing.id}
            isAffirmEnabled={isAffirmEnabled}
          />
        </div>
      )}
    </div>
  );
}

export function ListingActionButtons({ listing, setDraftDeleteErrors }: IExternalProps & IDraftDeleteErrorsProps) {
  const isEditable = listing.state !== SOLD || listing.relistable;

  if (listing.state === DRAFT) {
    return (
      <div className="d-flex gap-3 tablet-width-100 tablet-fx-align-center">
        <DeleteButton
          listing={listing}
          setDraftDeleteErrors={setDraftDeleteErrors}
        />

        <EditButton
          listing={listing}
        />

        <div className="fx-grow">
          <PublishButton
            listing={listing}
          />
        </div>
      </div>
    );
  }

  return (
    <div className="d-flex gap-3 tablet-fx-dir-col tablet-fx-align-center tablet-width-100 tablet-fx-dir-col-reverse">
      {listing.relistable && (
        <div className="tablet-width-100">
          <RelistButton listing={listing} />
        </div>
      )}
      {listing.state == LIVE && (
        <div className="tablet-width-100">
          <EndButton
            listing={listing}
          />
        </div>
      )}
      {isEditable && (
        <div className="tablet-width-100">
          <EditButton
            listing={listing}
          />
        </div>
      )}
    </div>
  );
}

export function DeleteDraftListingErrors({
  draftDeleteErrors = [],
  setDraftDeleteErrors,
}: IDraftDeleteErrorsProps) {
  return (
    <div className="pb-8 pl-6 pr-4">
      <RCAlertBox
        type="error"
        onDismiss={() => {
          setDraftDeleteErrors([]);
        }}
      >
        {I18n.t(
          'discovery.listingsManagement.sellerListingCard.deleteDraftError',
        )}
        <ul className="ml-4">
          {draftDeleteErrors.map((errorMessage) => (
            <li key={errorMessage}>{errorMessage}</li>
          ))}
        </ul>
      </RCAlertBox>
    </div>
  );
}

export default function SellerListingCardFooter({ listing }: IExternalProps) {
  const [draftDeleteErrors, setDraftDeleteErrors] = useState([]);
  return (
    <div className="seller-listing-card-footer">
      <div className="d-flex fx-justify-between fx-align-center tablet-fx-dir-col-reverse tablet-fx-align-start tablet-gap-3">
        <ListingState listing={listing} />
        <SellerFeatureButtons listing={listing} />
        <ListingActionButtons
          listing={listing}
          setDraftDeleteErrors={setDraftDeleteErrors}
        />
      </div>
      {draftDeleteErrors.length > 0 && (
        <DeleteDraftListingErrors
          draftDeleteErrors={draftDeleteErrors}
          setDraftDeleteErrors={setDraftDeleteErrors}
        />
      )}
    </div>
  );
}
