import { CoreBumpRecommendationListingCard } from '@reverbdotcom/commons/src/gql/graphql';

export const DELETE_BID_AMOUNT = 0.0;

export const BRAND_NEW_CONDITION_SLUGS = Object.freeze([
  'brand-new',
]);

export const USED_CONDITION_SLUGS = Object.freeze([
  'non-functioning',
  'poor',
  'fair',
  'good',
  'very-good',
  'excellent',
  'mint',
  'mint-inventory',
  'b-stock',
]);

export function isBrandNew(conditionSlug) {
  return BRAND_NEW_CONDITION_SLUGS.indexOf(conditionSlug) > -1;
}

export function isCSPMatched(listing: CoreBumpRecommendationListingCard.Fragment) {
  return !!listing.csp?.id;
}

export function isBiddingForFeaturedListing(listing: CoreBumpRecommendationListingCard.Fragment): boolean {
  return isBrandNew(listing.condition?.conditionSlug) && isCSPMatched(listing);
}

// handle annoying JS math float weirdness resulting from things like:
// 0.07 * 100
// 7.000000000000001
// This will instead now return 7
export function fixPrecision(amount: number): number {
  return Number.parseFloat((amount).toFixed(1));
}

// converts a decimal representation of a "bid"
// to a percent:
// 0.075 returns 7.5
export function formatBid(bid: number): number {
  return fixPrecision(bid * 100);
}

// converts a percent representation of a "bump rate"
// to a "bid" decimal:
// 7.5 returns 0.075
export function bumpRatePercentToDecimal(bumpRate: number): number {
  return bumpRate / 100;
}
