import React from 'react';
import I18n from 'i18n-js';
import { flatten, orderBy } from 'lodash';

import { gql } from '@reverbdotcom/commons/src/gql';
import { ModalDialog } from '@reverbdotcom/commons';
import { useQuery } from '@reverbdotcom/commons/src/useQuery';
import { RCAlertBox, RCButton, RCSegmentedControl, RCTextInput } from '@reverbdotcom/cadence/components';
import { Listing } from '@reverbdotcom/commons/src/gql/graphql';
import CoreLink from '@reverbdotcom/commons/src/components/core_link';

import BulkSaleCard from './BulkSaleCard';
import LoadingOverlay from '../../../../../src/shared/loading_overlay';
import { useViewTracking } from '@reverbdotcom/commons/src/use_tracking';
import { MParticleEventName } from '@reverbdotcom/commons/src/elog/mparticle_types';
import URLHelpers from '../../../../components/shared/url_helpers';

export const COMPONENT_NAME = 'BulkSalesModal';

const MY_SALES_QUERY = gql(`
query Core_MySales($listingIds: [String]) {
  me {
    _id
    uuid
    shop {
      _id
      sales {
        reverbSales {
          _id
          id
          name
          startsAt {
            seconds
          }
          endsAt {
            seconds
          }
          state
          discountValue
          links {
            self {
              href
            }
          }
        }
        sellerSales {
          _id
          id
          name
          startsAt {
            seconds
          }
          endsAt {
            seconds
          }
          state
          discountValue
          links {
            self {
              href
            }
          }
        }
      }
    }
  }
  listings(input: { ids: $listingIds }) {
    _id
    id
    ...AvailableSalesMemberships
  }
}
`);

export enum SalesTabs {
  REVERB = 'reverb',
  SELLER = 'seller',
}

export enum SalesStates {
  ALL = 'all',
  LIVE = 'live',
  UPCOMING = 'upcoming',
  ENDED = 'ended',
}

enum SalesSort {
  ALPHABETICAL = 'alphabetical',
  REVERSE_ALPHABETICAL = 'reverse_alphabetical',
  ENDING_SOONEST = 'ending_soonest',
}

interface IExternalProps {
  selectedListings: Listing[];
  onClose: React.Dispatch<any>;
  isOpen: boolean;
}

export function sortSales(sales, salesSort) {
  switch (salesSort) {
    case SalesSort.ALPHABETICAL:
      return orderBy(sales, 'name', 'asc');
    case SalesSort.REVERSE_ALPHABETICAL:
      return orderBy(sales, 'name', 'desc');
    case SalesSort.ENDING_SOONEST:
      return orderBy(sales, sale => sale.endsAt.seconds, 'asc');
    default:
      return orderBy(sales, 'name', 'asc');
  }
}

export function filterSalesByState(sales, salesState) {
  if (salesState == SalesStates.ALL) return sales;

  return sales.filter(sale => sale.state.toLowerCase() == salesState);
}

export function filterSalesByQuery(sales, query) {
  if (!query.length) return sales;

  return sales.filter(sale => sale.name.toLowerCase().includes(query.toLowerCase()));
}

export default function BulkSalesModal({
  isOpen,
  onClose,
  selectedListings,
}: IExternalProps) {
  const {
    data,
    loading,
    refetch,
  } = useQuery(MY_SALES_QUERY, {
    ssr: false,
    fetchPolicy: 'network-only',
    errorPolicy: 'all',
    skip: !isOpen,
    notifyOnNetworkStatusChange: true,
    variables: {
      listingIds: selectedListings.map(l => l.id),
    },
  });

  const {
    me = {},
    listings = [],
  } = data ?? {};

  const {
    shop = {},
  } = me;

  const reverbSalesMemberships = flatten(listings.map(listing => listing.availableSalesMemberships.reverbSalesMemberships));
  const sellerSalesMemberships = flatten(listings.map(listing => listing.availableSalesMemberships.sellerSalesMemberships));

  const salesMemberships = reverbSalesMemberships.concat(sellerSalesMemberships);

  const {
    reverbSales = [],
    sellerSales = [],
  } = shop.sales ?? {};

  const [selectedSaleType, selectSaleType] = React.useState(SalesTabs.REVERB);
  const [selectedSalesState, selectSalesState] = React.useState(SalesStates.ALL);
  const [selectedSalesSort, selectSalesSort] = React.useState(SalesSort.ALPHABETICAL);
  const [searchQuery, setSearchQuery] = React.useState('');

  const hasReverbSalesSelected = selectedSaleType == SalesTabs.REVERB;
  const salesFilteredByType = hasReverbSalesSelected ? reverbSales : sellerSales;
  const salesFilteredByState = filterSalesByState(salesFilteredByType, selectedSalesState);
  const salesFilteredByQuery = filterSalesByQuery(salesFilteredByState, searchQuery);
  const sortedSales = sortSales(salesFilteredByQuery, selectedSalesSort);

  const hasNoSales = !loading && !sortedSales.length;
  const selectedListingsCount = selectedListings.length;

  React.useEffect(() => {
    return () => {
      selectSaleType(SalesTabs.REVERB);
      selectSalesState(SalesStates.ALL);
      selectSalesSort(SalesSort.ALPHABETICAL);
      setSearchQuery('');
    };
  }, [isOpen]);

  useViewTracking({
    componentName: COMPONENT_NAME,
    eventName: MParticleEventName.ComponentView,
  }, isOpen);

  return (
    <ModalDialog
      onRequestClose={onClose}
      isOpen={isOpen}
      size="wide"
      headerTitle={I18n.t('discovery.listingsManagement.toolbar.bulkActions.sales.addToSales')}
      hideFooterDismissButton
      saveButtonText={I18n.t('discovery.listingsManagement.toolbar.bulkActions.sales.modal.close')}
      onSubmit={onClose}
    >
      <LoadingOverlay showOverlay={loading}>
        <div className="d-flex fx-dir-col gap-3 pb-2 bdb-1 bd-color-primary">
          <RCAlertBox type="info">
            {I18n.t('discovery.listingsManagement.toolbar.bulkActions.sales.modal.selectedListings', {
              count: selectedListingsCount,
              formattedCount: I18n.toNumber(selectedListingsCount, {
                precision: 0,
              }),
            })}
          </RCAlertBox>

          <div className="d-grid gap-gutter grid-cols-2 tablet-grid-cols-1">
            <div>
              <div className="weight-bold mb-1 size-90">
                {I18n.t('discovery.listingsManagement.toolbar.bulkActions.sales.modal.saleType')}
              </div>
              <RCSegmentedControl
                aria-label={I18n.t('discovery.listingsManagement.toolbar.bulkActions.sales.modal.saleType')}
                fullWidth
              >
                <RCSegmentedControl.Button
                  key="reverbSales"
                  onClick={() => selectSaleType(SalesTabs.REVERB)}
                  selected={selectedSaleType === SalesTabs.REVERB}
                >
                  <div className="ws-normal">
                    {I18n.t('discovery.listingsManagement.toolbar.bulkActions.sales.modal.reverbPromotedSales')}
                  </div>
                </RCSegmentedControl.Button>

                <RCSegmentedControl.Button
                  key="sellerSales"
                  onClick={() => selectSaleType(SalesTabs.SELLER)}
                  selected={selectedSaleType === SalesTabs.SELLER}
                >
                  <div className="ws-normal">
                    {I18n.t('discovery.listingsManagement.toolbar.bulkActions.sales.modal.mySales')}
                  </div>
                </RCSegmentedControl.Button>
              </RCSegmentedControl>
            </div>

            <div>
              <div className="weight-bold mb-1 size-90">
                {I18n.t('discovery.listingsManagement.toolbar.bulkActions.sales.modal.saleStatus')}
              </div>
              <RCSegmentedControl
                aria-label={I18n.t('discovery.listingsManagement.toolbar.bulkActions.sales.modal.saleStatus')}
                fullWidth
              >
                <RCSegmentedControl.Button
                  key={I18n.t('discovery.listingsManagement.toolbar.bulkActions.sales.modal.states.all')}
                  onClick={() => selectSalesState(SalesStates.ALL)}
                  selected={selectedSalesState === SalesStates.ALL}
                >
                  <div className="ws-normal">
                    {I18n.t('discovery.listingsManagement.toolbar.bulkActions.sales.modal.states.all')}
                  </div>
                </RCSegmentedControl.Button>

                <RCSegmentedControl.Button
                  key={I18n.t('discovery.listingsManagement.toolbar.bulkActions.sales.modal.states.live')}
                  onClick={() => selectSalesState(SalesStates.LIVE)}
                  selected={selectedSalesState === SalesStates.LIVE}
                >
                  <div className="ws-normal">
                    {I18n.t('discovery.listingsManagement.toolbar.bulkActions.sales.modal.states.live')}
                  </div>
                </RCSegmentedControl.Button>

                <RCSegmentedControl.Button
                  key={I18n.t('discovery.listingsManagement.toolbar.bulkActions.sales.modal.states.upcoming')}
                  onClick={() => selectSalesState(SalesStates.UPCOMING)}
                  selected={selectedSalesState === SalesStates.UPCOMING}
                >
                  <div className="ws-normal">
                    {I18n.t('discovery.listingsManagement.toolbar.bulkActions.sales.modal.states.upcoming')}
                  </div>
                </RCSegmentedControl.Button>
              </RCSegmentedControl>
            </div>
          </div>

          <div className="d-grid gap-gutter grid-cols-2 tablet-grid-cols-1 mb-3">
            <div>
              <div className="weight-bold mb-1 size-90">
                {I18n.t('discovery.listingsManagement.toolbar.bulkActions.sales.modal.sortBy')}
              </div>
              <RCSegmentedControl
                aria-label={I18n.t('discovery.listingsManagement.toolbar.bulkActions.sales.modal.sortBy')}
                fullWidth
              >
                <RCSegmentedControl.Button
                  key={I18n.t('discovery.listingsManagement.toolbar.bulkActions.sales.modal.sorts.alphabetical')}
                  onClick={() => selectSalesSort(SalesSort.ALPHABETICAL)}
                  selected={selectedSalesSort === SalesSort.ALPHABETICAL}
                >
                  <div className="ws-normal">
                    {I18n.t('discovery.listingsManagement.toolbar.bulkActions.sales.modal.sorts.alphabetical')}
                  </div>
                </RCSegmentedControl.Button>

                <RCSegmentedControl.Button
                  key={I18n.t('discovery.listingsManagement.toolbar.bulkActions.sales.modal.sorts.reverseAlphabetical')}
                  onClick={() => selectSalesSort(SalesSort.REVERSE_ALPHABETICAL)}
                  selected={selectedSalesSort === SalesSort.REVERSE_ALPHABETICAL}
                >
                  <div className="ws-normal">
                    {I18n.t('discovery.listingsManagement.toolbar.bulkActions.sales.modal.sorts.reverseAlphabetical')}
                  </div>
                </RCSegmentedControl.Button>

                <RCSegmentedControl.Button
                  key={I18n.t('discovery.listingsManagement.toolbar.bulkActions.sales.modal.sorts.endingSoonest')}
                  onClick={() => selectSalesSort(SalesSort.ENDING_SOONEST)}
                  selected={selectedSalesSort === SalesSort.ENDING_SOONEST}
                >
                  <div className="ws-normal">
                    {I18n.t('discovery.listingsManagement.toolbar.bulkActions.sales.modal.sorts.endingSoonest')}
                  </div>
                </RCSegmentedControl.Button>
              </RCSegmentedControl>
            </div>

            <RCTextInput
              label={I18n.t('discovery.listingsManagement.toolbar.bulkActions.sales.modal.searchSales')}
              value={searchQuery}
              onChange={e => setSearchQuery(e.target.value)}
              placeholder={I18n.t('discovery.listingsManagement.toolbar.bulkActions.sales.modal.searchPlaceholder')}
            />
          </div>
        </div>

        {hasNoSales && (
          <div className="d-flex fx-dir-col fx-align-center gap-3 mt-14 mb-12">
            <div className="size-120">
              {I18n.t('discovery.selling.bulkSale.noneFound')}
            </div>

            <CoreLink
              to={URLHelpers.mySalesPath}
              target="_blank"
            >
              <RCButton>
                {I18n.t('discovery.listingsManagement.toolbar.bulkActions.sales.modal.createSale')}
              </RCButton>
            </CoreLink>
          </div>
        )}

        {sortedSales.map(sale => (
          <BulkSaleCard
            key={sale.id}
            sale={sale}
            salesMemberships={salesMemberships}
            isReverbSale={selectedSaleType == SalesTabs.REVERB}
            onSuccessCallback={refetch}
          />
        ))}
      </LoadingOverlay>
    </ModalDialog>
  );
}
