import alt, { AltStoreModel, AltActions } from '../alt';
import CollectionHeader from './collection_header';

import { V3 } from '../../http_client';
import locationUtil from '../../cms/lib/location_util';
import {
  landingPageCollectionHeaderPath,
  productTypeCollectionHeaderPath,
  curatedSearchCollectionHeaderPath,
  categoryCollectionHeaderPath,
  categoryCollectionHeadersPath,
  curatedSetCollectionHeaderPath,
  brandCollectionHeaderPath,
} from '../../url_helpers';

interface Actions {
  collectionHeaderLoaded(data: LoadData): LoadData;
  fetchOrClear(params: CollectionHeaderParams): Promise<any>;
  clear(): void;
}

export interface CollectionHeaderParams {
  locale?: string;
  query?: any;
  curated_set_slug?: string;
  curated_search_slug?: string;
  brand_slug?: string;
  landing_page_slug?: string;
  product_type?: string;
  category?: string;
  category_id?: string;
}

export interface LoadData {
  params: object;
  response: {
    collection_headers?: any[];
  };
}

function getUrl(params: CollectionHeaderParams) {
  if (params.curated_set_slug) {
    return curatedSetCollectionHeaderPath(params.curated_set_slug);
  }

  if (params.curated_search_slug) {
    return curatedSearchCollectionHeaderPath(params.curated_search_slug);
  }

  if (params.brand_slug) {
    return brandCollectionHeaderPath(params.brand_slug);
  }

  if (params.landing_page_slug) {
    return landingPageCollectionHeaderPath(params.landing_page_slug);
  }

  if (params.product_type) {
    if (params.category) {
      return categoryCollectionHeadersPath(
        params.product_type,
        params.category,
      );
    }

    return productTypeCollectionHeaderPath(params.product_type);
  }

  // digital listings
  if (params.category_id) {
    return categoryCollectionHeaderPath(params.category_id);
  }

  return null;
}

function shouldHideHeader(params: CollectionHeaderParams) {
  return !getUrl(params) ||
    (locationUtil.isOnMarketplacePage() && params.query);
}

class CollectionHeaderActionsBase extends AltActions implements Actions {
  collectionHeaderLoaded(data: LoadData) {
    return data;
  }

  clear() {
    return null;
  }

  fetchOrClear(params: CollectionHeaderParams) {
    if (shouldHideHeader(params)) {
      this.clear();
      return null;
    }
    let fetchUrl = getUrl(params);
    if (params.locale) {
      fetchUrl = `${fetchUrl}?locale=${params.locale}`;
    }
    const req = V3.get(fetchUrl);

    req.then((resp) => resp.json())
      .then((data) => {
        this.collectionHeaderLoaded({ response: data, params });
      }).catch(() => {
        this.clear();
      });

    return new Promise((success, err) => {
      req.then((r) => { success(r); });
      req.catch((r) => err(r));
    });
  }
}

export const CollectionHeaderActions = alt.createActions(CollectionHeaderActionsBase);

export interface State {
  collectionHeader: CollectionHeader;
  params: CollectionHeaderParams;
}

class CollectionHeaderStoreBase extends AltStoreModel<State> implements State {
  collectionHeader = null;

  params = null;

  constructor() {
    super();
    this.bindActions(CollectionHeaderActions);
  }

  onCollectionHeaderLoaded(data: LoadData) {
    const collectionHeader = Array.isArray(data.response.collection_headers) ?
      data.response.collection_headers[0] :
      data.response;

    this.setState({
      collectionHeader,
      params: data.params,
    });
  }

  onClear() {
    this.setState(this.initialState());
  }

  initialState(): State {
    return {
      collectionHeader: null,
      params: null,
    };
  }
}

export const CollectionHeaderStore = alt.createStore(CollectionHeaderStoreBase, 'CollectionHeaderStore');
