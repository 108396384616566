import React from 'react';
import URLHelpers from '../../shared/url_helpers';
import CoreLink from '@reverbdotcom/commons/src/components/core_link';
import { RCButton, RCIconButton } from '@reverbdotcom/cadence/components';
import I18n from 'i18n-js';
import { Listing } from '@reverbdotcom/commons/src/gql/graphql';
import { PencilIcon } from '@reverbdotcom/cadence/icons/react';
import { MParticleEventName, trackEvent } from '@reverbdotcom/commons/src/elog/mparticle_tracker';

interface IListingStateProps {
  listing: Listing;
}

export const COMPONENT_NAME = 'EditButton';

function EditButton({ listing }: IListingStateProps) {
  return (
    <CoreLink
      to={URLHelpers.sellFormEditListingPath(listing.id, {
        section: 'review',
      })}
      onClick={() => {
        trackEvent({
          eventName: MParticleEventName.ClickedComponent,
          componentName: COMPONENT_NAME,
          listingId: listing.id,
        });
      }}
    >
      {listing.state === 'draft' ? (
        <>
          <div className="tablet-d-none">
            <RCButton
              variant="muted"
              fullWidth
            >
              {I18n.t('discovery.listingsManagement.sellerListingCard.edit')}
            </RCButton>
          </div>
          <div className="seller-listing-card-footer__tablet-button-container">
            <RCIconButton
              label={I18n.t('discovery.listingsManagement.sellerListingCard.edit')}
              svgComponent={PencilIcon}
              variant="muted"
            />
          </div>
        </>
      ) :
        <RCButton variant="filled" fullWidth>
          {I18n.t('discovery.listingsManagement.sellerListingCard.edit')}
        </RCButton>
      }
    </CoreLink>
  );
}

export default EditButton;
