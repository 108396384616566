import { MutationFunction } from '@reverbdotcom/commons/src/useHOCMutation';
// TODO update this to import from commons
// import { gql } from '@reverbdotcom/commons/src/gql';
// eslint-disable-next-line no-restricted-imports
import { gql } from '@apollo/client';
import React from 'react';
import * as elog from '@reverbdotcom/commons/src/elog';
import {
  BillingMethodVerifyShopperMutation,
  BillingMethodVerifyShopperMutationVariables,
  core_apimessages_Error,
  core_apimessages_UpdateBillingMethodResponse as UpdateBillingMethodResponse,
  core_apimessages_BillingMethodVerifyShopperResponse as BillingMethodVerifyShopperResponse,
} from '@reverbdotcom/commons/src/gql/graphql';
import bind from '@reverbdotcom/commons/src/bind';
import { parseMutationErrors } from '@reverbdotcom/commons/src/parse_graphql_errors';
import { RCLoadingBars } from '@reverbdotcom/cadence/components';
import SCAInteraction from './sca_interaction';
import { myShopBillingMethods } from './billing_method_operations';
import { withGraphql } from '@reverbdotcom/commons/src/with_graphql';
import { connect } from '@reverbdotcom/commons/src/connect';

type UpdateResponse = UpdateBillingMethodResponse | BillingMethodVerifyShopperResponse;

interface ExternalProps {
  billingMethodResponse: UpdateResponse;
  title?: string;
  onError(errors: core_apimessages_Error[]): void;
  onBillingMethodResponse(billingMethodResponse: UpdateResponse): void;
  loggerTag?: string;
  partialPayment?: boolean;
}

interface ApolloProps {
  verifyShopper: MutationFunction<BillingMethodVerifyShopperMutation, BillingMethodVerifyShopperMutationVariables>;
}

type Props = ExternalProps & ApolloProps;

function BillingTitle({ title }: { title: string }) {
  if (!title) {
    return null;
  }

  return (
    <div className="form-section-wrapper__header">
      <h2 className="form-section-wrapper__title">
        {title}
      </h2>
    </div>
  );
}

const TAG = 'adyen.threeds2_billing_container';

export class BillingMethodSCAInteraction extends React.Component<Props> {
  eventData(additionalContext = {}) {
    return {
      componentName: 'ThreeDS2BillingContainer',
      context: {
        ...additionalContext,
        props: { ...this.props },
      },
    };
  }

  componentDidMount() {
    elog.info(`${this.props.loggerTag || TAG}.mount`, { ...this.eventData() });
  }

  @bind
  async onPaymentDetailResult(threeds2Result: string) {
    try {
      const result = await this.props.verifyShopper({
        variables: {
          input: {
            billingMethodUuid: this.props.billingMethodResponse.billingMethodUuid,
            results: {
              threeds2Result,
            },
            ...(this.props.partialPayment && { partialPayment: this.props.partialPayment }),
          },
        },
      });

      if (result) {
        this.props.onBillingMethodResponse(result.data.billingMethodVerifyShopper);
      }
    } catch (error) {
      this.onThreeDSFailed(parseMutationErrors(error));
    }
  }

  onThreeDSFailed(errors: core_apimessages_Error[]) {
    elog.info(`${this.props.loggerTag || TAG}.failed`, {
      errors,
      paymentStatus: this.props.billingMethodResponse.adyenPaymentResult?.paymentStatus,
    });
    this.props.onError(errors);
  }

  render() {
    const paymentAction = JSON.parse(this.props.billingMethodResponse.adyenPaymentResult.action);

    return (
      <div>
        <BillingTitle title={this.props.title} />
        <div className="bg-white padding-8">
          <div className="d-flex fx-justify-center mtb-2">
            <RCLoadingBars />
          </div>
          <SCAInteraction
            action={paymentAction}
            onPaymentDetailResult={this.onPaymentDetailResult}
          />
        </div>
      </div>
    );
  }
}

const BILLING_METHOD_VERIFY_SHOPPER = gql`
  mutation BillingMethodVerifyShopper($input: Input_core_apimessages_BillingMethodVerifyShopperRequest) {
    billingMethodVerifyShopper(input: $input) {
      creditCard {
        id
      }
      billingMethodUuid
      adyenPaymentResult {
        action
        paymentStatus
        challengeToken
        paymentData
      }
      me {
        _id
        ...myShopBillingMethods
      }

    }
  }
  ${myShopBillingMethods}
`;

export const withVerifyShopperMutation = withGraphql<ExternalProps, BillingMethodVerifyShopperMutation, BillingMethodVerifyShopperMutationVariables>(
  BILLING_METHOD_VERIFY_SHOPPER,
  {
    name: 'verifyShopper',
  },
);

export default connect([
  withVerifyShopperMutation,
])(BillingMethodSCAInteraction) as React.ComponentClass<ExternalProps>;
