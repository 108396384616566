import React from 'react';
import { ChildProps } from '@apollo/client/react/hoc';
// TODO update this to import from commons/src/gql
// eslint-disable-next-line no-restricted-imports
import { gql } from '@apollo/client';
import isNode from '../is-node';
import {
  Commons_MParticle_OrderBundlesQuery,
  Commons_MParticle_OrderBundlesQueryVariables,
} from '../gql/graphql';
import { mParticleListingFields } from './with_mparticle_listings';
import { browserHistory, RouteComponentProps } from 'react-router';
import { MParticleEventName, trackEvent } from '../elog/mparticle_tracker';
import { trackCriteoTransaction } from '../criteo';
import { useUser } from '../user_hooks';
import { withGraphql } from '../with_graphql';
import { first } from 'lodash';
import ErrorBoundary from './error_boundary';

type RouteProps = RouteComponentProps<{ order_bundle_id: string }, {}>;
type IProps = RouteProps & ChildProps<RouteProps, Commons_MParticle_OrderBundlesQuery, Commons_MParticle_OrderBundlesQueryVariables>;
type OrderBundle = Commons_MParticle_OrderBundlesQuery['orderBundle'];

const TRACKING_COMPLETE_PARAM = 'mp_complete';

const graphQLConfig = {
  props: props => props,
  options: (props: RouteProps) => {
    return {
      variables: {
        id: props.params.order_bundle_id,
      },
      ssr: false,
      context: {
        headers: {
          // override user's currency, since all
          // mParticle tracking needs to be normalized to USD
          'X-Display-Currency': 'USD',
        },
      },
    };
  },
};

const ORDER_BUNDLES_QUERY = gql`
  query Commons_MParticle_OrderBundles(
    $id: String
  )
  {
    orderBundle: orderbundle(input: { id: $id }) {
      _id
      id
      checkoutType
      paymentMethod
      localPickup
      amountTax {
        amount
      }
      amountShipping {
        amount
      }
      amountProductSubtotal {
        amount
      }
      orders {
        nodes {
          _id
          id
          shopUuid
          quantity
          settlementAmountProductSubtotal {
            amount
          }
          listing {
            _id
            ...mParticleListingFields
          }
          payments {
            paymentMethod
          }
          protectionPlanEligible
        }
      }
      orderType
    }
  }
  ${mParticleListingFields}
`;

const TIMEOUT_LENGTH = 5000;

export function TrackMParticlePurchase({ location, data, timeoutLength }) {
  const user = useUser();

  if (isNode) return null;
  if (location.query[TRACKING_COMPLETE_PARAM] === 'true') return null;

  const orderBundle = mapOrderBundle(data?.orderBundle);

  if (orderBundle) {
    trackEvent({
      orderBundle,
      eventName: MParticleEventName.Purchase,
    });

    trackCriteoTransaction(orderBundle, user);

    setTimeout(
      () => {
        browserHistory.replace({
          pathname: location.pathname,
          query: {
            ...location.query,
            [TRACKING_COMPLETE_PARAM]: true,
          },
        });
      },
      timeoutLength,
    );
  }

  return null;
}

export function WrappedTrackMParticlePurchase(props: IProps, timeoutLength = TIMEOUT_LENGTH) {
  return (
    <ErrorBoundary>
      <TrackMParticlePurchase location={props.location} data={props.data} timeoutLength={timeoutLength} />
    </ErrorBoundary>
  );
}

function mapOrderBundle(orderBundle: OrderBundle): OrderBundle {
  if (!orderBundle) {
    return null;
  }

  const firstPayment = first(first(orderBundle.orders.nodes).payments);
  const { paymentMethod } = firstPayment;

  return {
    ...orderBundle,
    paymentMethod,
  };
}


export default withGraphql(ORDER_BUNDLES_QUERY, graphQLConfig)(WrappedTrackMParticlePurchase);
