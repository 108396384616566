import { ChildProps } from '@apollo/client/react/hoc';
// TODO update this to import from commons/src/gql
// eslint-disable-next-line no-restricted-imports
import { gql } from '@apollo/client';
import { CommonsMParticleArticle } from '../gql/graphql';
import { MParticlePageName, trackPageView } from '../elog/mparticle_tracker';
import { withGraphql } from '../with_graphql';

interface IProps {
  params: {
    slug: string;
  };
}

const graphQLConfig = {
  props: props => props,
  options: (props: IProps) => {
    return {
      variables: {
        slug: props.params.slug,
      },
      ssr: false,
    };
  },
};

const ARTICLE_QUERY = gql`
  query Commons_MParticle_Article(
    $slug: String
  )
  {
    article(input: { slug: $slug } ) {
      _id
      categories {
        _id
        slug
      }
    }
  }
`;

export function trackMParticleArticlePageView(props: ChildProps<IProps, CommonsMParticleArticle.Query>) {
  const { article } = props.data;

  if (article) {
    const transformedArticleSlugs = article.categories.map(article => article.slug).join('|');

    trackPageView({
      pageName: MParticlePageName.NewsArticle,
      articleSlug: props.params.slug,
      articleCategories: transformedArticleSlugs,
    });
  }

  return null;
}

export default withGraphql(ARTICLE_QUERY, graphQLConfig)(trackMParticleArticlePageView);
