import I18n from 'i18n-js';
import React from 'react';

import experiments from '@reverbdotcom/commons/src/experiments';
import { useExpEnabled } from '@reverbdotcom/commons/src/user_hooks';
import { RCAlertBox, RCTextWithIcon } from '@reverbdotcom/cadence/components';
import { ExclamationTriangleIcon } from '@reverbdotcom/cadence/icons/react';

export function ShippingLabelOutageBanner() {
  const shouldShowBanner = useExpEnabled(experiments.SHIPPING_LABEL_OUTAGE_BANNER);

  if (!shouldShowBanner) { return null; }

  return (
    <div className="mb-8">
      <RCAlertBox type="warning">
        <RCTextWithIcon svgComponent={ExclamationTriangleIcon} placement="left">
          {I18n.t('discovery.dashboard.selling.shippingLabels.shippingRates.shippingLabelOutageDefaultText')}
        </RCTextWithIcon>
      </RCAlertBox>
    </div>
  );
}

export default ShippingLabelOutageBanner;
