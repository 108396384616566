import React from 'react';
import I18n from 'i18n-js';

import { useExpEnabled, useUser } from '@reverbdotcom/commons/src/user_hooks';
import HeaderDropdown from '@reverbdotcom/commons/src/components/header_dropdown';
import { PriceDisplay } from '@reverbdotcom/commons/src/components/PriceDisplay';
import CoreLink from '@reverbdotcom/commons/src/components/core_link';
import { WebThemeControl } from '@reverbdotcom/commons/src/components/web_theme_control';
import experiments from '@reverbdotcom/commons/src/experiments';
import CsrfForm from '@reverbdotcom/commons/src/csrf_form';
import { RCButton } from '@reverbdotcom/cadence/components';
import { ADMINISTRATOR_PERMISSION_LEVEL } from '@reverbdotcom/commons/src/constants';

import {
  buildSellingSection,
  buildBuyingSection,
  buildFinancesSection,
  buildMyAccountSection,
} from './reverb_header_menu_data';
import baseUrlHelpers from '../../../shared/url_helpers';
import ReverbHeaderMenuSection from './reverb_header_menu_section';
import { Core_ReverbHeaderQuery } from '@reverbdotcom/commons/src/gql/graphql';

interface IProps {
  me: Core_ReverbHeaderQuery['me'];
  activeDropdownKey: string | null;
  setActiveDropdownKey: (string) => void;
}

export const DEFAULT_AVATAR = 'https://res.cloudinary.com/reverb-preprod-cld/image/upload/s--t2fk8vm_--/v1699306207/hfgk738xixwp3yqkxjjw.jpg';

function renderReverbCreditsCallouts(reverbCreditsBalances) {
  if (!reverbCreditsBalances?.length) { return null; }

  return (
    <>
      <div className="reverb-header__menu__user-menu__header">
        {I18n.t('discovery.reverbHeader.userMenu.reverbCredits')}
      </div>
      <ul>
        {reverbCreditsBalances.map(({ display, currency }) => (
          <li className="reverb-header__menu__credits-balance" key={`reverb_credits_${currency}`}>
            <PriceDisplay display={display} />
          </li>
        ))}
      </ul>
    </>
  );
}

function renderPayoutBalancesCallouts(payoutBalances) {
  if (!payoutBalances?.length) { return null; }

  return (
    <>
      <div className="reverb-header__menu__user-menu__header">
        {I18n.t('discovery.reverbHeader.userMenu.payoutBalance')}
      </div>
      <ul>
        {payoutBalances.map(({ display, currency }) => (
          <li key={`payout_balances_${currency}`}>
            <CoreLink to={baseUrlHelpers.dashboardEarningsReverbBankTransactionsPath}>
              <div className="weight-bold size-90 color-accent">
                <PriceDisplay display={display} />
              </div>
            </CoreLink>
          </li>
        ))}
      </ul>
    </>
  );
}

function renderSecondaryUserMenu(secondaryUserModeIsActive: boolean, managedShopName: string) {
  return (
    <>
      <div className="reverb-header__menu__user-menu__header">
        {I18n.t('discovery.reverbHeader.userMenu.secondaryUser.header')}
      </div>
      <ul>
        <li>
          <span>{managedShopName}</span>

          <CsrfForm action={baseUrlHelpers.toggleSecondaryUserModePath} method="POST">
            <RCButton
              size="mini"
              variant="transparent"
              isSubmit={true}
            >
              {secondaryUserModeIsActive ? I18n.t('discovery.reverbHeader.userMenu.secondaryUser.logOut') :
                I18n.t('discovery.reverbHeader.userMenu.secondaryUser.logIn')}
            </RCButton>
          </CsrfForm>
        </li>
      </ul>
    </>
  );
}

export function ReverbHeaderDesktopMenu({
  me,
  setActiveDropdownKey,
  activeDropdownKey,
}: IProps) {
  const user = useUser();
  const { name, loggedOut, avatarUrl, locale, secondaryUserModeIsActive, managedShopName, managedShopUserAvatarUrl, managedShopPermission } = user;
  const { shop, reverbCredits, payoutBalances } = me || {};
  const { slug: shopSlug, paymentMethod: shopPaymentMethod } = shop || {};

  const isShopAdmin = !secondaryUserModeIsActive || managedShopPermission == ADMINISTRATOR_PERMISSION_LEVEL;
  const shownAvatarUrl = (secondaryUserModeIsActive && managedShopUserAvatarUrl) || avatarUrl || DEFAULT_AVATAR;

  const menuSections = [
    buildSellingSection(shopSlug, isShopAdmin),
    buildBuyingSection(user),
    isShopAdmin && shopPaymentMethod && buildFinancesSection(shopPaymentMethod),
    !loggedOut && buildMyAccountSection({ locale }),
  ].filter(Boolean);

  const themeExpEnabled = useExpEnabled(experiments.WEB_THEME_CONTROL);

  const menu = (
    <div className="reverb-header__menu__user-menu">
      <div className="bdb-1 bd-primary padding-2 pt-0 weight-bold text-truncate-1">
        {name}
      </div>
      {themeExpEnabled &&
        <>
          <div className="reverb-header__menu__user-menu__header">
            <span className="icon-r-lock">{I18n.t('discovery.reverbHeader.userMenu.theme')}</span>
          </div>
          <WebThemeControl />
        </>
      }
      {renderPayoutBalancesCallouts(payoutBalances)}
      {renderReverbCreditsCallouts(reverbCredits)}
      {!!managedShopName && renderSecondaryUserMenu(secondaryUserModeIsActive, managedShopName)}
      {menuSections.map(({ key, title, links }) => (<ReverbHeaderMenuSection key={key} title={title} links={links} />))}
    </div>
  );

  const trigger = (
    <div
      className="reverb-header__nav__link reverb-header__nav__link--user-menu"
    >
      <div className="reverb-header__nav__link__icon reverb-header__nav__link--avatar">
        <img src={shownAvatarUrl} alt={I18n.t('discovery.reverbHeader.userMenu.userAvatarAlt')} />
      </div>
      <div className="reverb-header__nav__link__label">
        {I18n.t('discovery.reverbHeader.userMenu.badge')}
      </div>
    </div>
  );

  return (
    <HeaderDropdown
      trigger={trigger}
      contents={menu}
      dropdownKey="user_menu"
      onOpen={key => setActiveDropdownKey(key)}
      activeDropdownKey={activeDropdownKey}
    />
  );
}

export default ReverbHeaderDesktopMenu;
