import React from 'react';
import I18n from 'i18n-js';
import { RCLoadingBars } from '@reverbdotcom/cadence/components';

interface IProps {
  title: string;
}

export default function CheckoutLoadingIndicator({ title }: IProps) {
  return (
    <div className="checkout-loading-indicator">
      <p className="checkout-loading-indicator__title">
        {title}
      </p>
      <div className="checkout-loading-indicator__icon">
        <RCLoadingBars size="large" />
      </div>
      <p className="checkout-loading-indicator__description">
        {I18n.t('discovery.checkout.loadingIndicator.oneMomentPlease')}
      </p>
    </div>
  );
}
