import React from 'react';
import { useUser } from '@reverbdotcom/commons/src/user_hooks';
import { useLoggingEvent } from '@reverbdotcom/commons/src/useLoggingEvent';
import { core_apimessages_Money } from '@reverbdotcom/commons/src/gql/graphql';
import { CanadianKlarnaCallout } from './CanadianKlarnaCallout';
import { KlarnaCreditPlacement } from './KlarnaCreditPlacement';

type Money = Pick<core_apimessages_Money, 'amountCents' | 'currency'>;

interface Props {
  amount: Money;
  textOnly?: boolean;
}

export function RegionalKlarnaCallout({
  amount,
  textOnly = false,
}: Props) {
  const user = useUser();

  useCalloutMountedLoggingEvent(amount);

  if (user.countryCode === 'CA') {
    return (
      <CanadianKlarnaCallout
        amount={amount}
        textOnly={textOnly}
      />
    );
  }

  return (
    <KlarnaCreditPlacement
      amountCents={amount.amountCents}
      textOnly={textOnly}
    />
  );
}

function useCalloutMountedLoggingEvent(amount: Money) {
  const { fiveDigitLocale } = useUser();

  useLoggingEvent({
    eventName: 'klarna_callout.mounted',
    data: {
      amount_cents: amount?.amountCents,
      user_locale: fiveDigitLocale,
    },
    shouldLog: true,
  });
}
