import { ChildProps } from '@apollo/client/react/hoc';
// TODO update this to import from commons
// import { gql } from '@reverbdotcom/commons/src/gql';
// eslint-disable-next-line no-restricted-imports
import { gql } from '@apollo/client';
import React from 'react';
import { withGraphql } from '@reverbdotcom/commons/src/with_graphql';
import {
  Input_core_apimessages_DatadogError,
  SearchUserDatadogErrors,
} from '@reverbdotcom/commons/src/gql/graphql';
import SanitizedRender from '@reverbdotcom/commons/src/components/sanitized_render';
import { RCLoadingBars } from '@reverbdotcom/cadence/components';

interface DatadogErrorProps {
  errors: Input_core_apimessages_DatadogError[];
}

interface StoredError {
  key: string;
  type: string;
  timestamp: string;
  content: string;
  context: string;
}

interface ExternalProps {
  storedErrors: StoredError[];
  userId: string;
}

interface NoErrorsRowProps {
  children: string | React.ReactNode;
}

type Props = ChildProps<ExternalProps, SearchUserDatadogErrors.Query>;

export const SEARCH_USER_DATADOG_ERRORS_QUERY = gql`
  query SearchUserDatadogErrors(
    $input: Input_core_apimessages_AdminUserDatadogErrorsSearchRequest
  ) {
    datadogErrorsSearch(input: $input) {
      datadogErrors {
        key
        type
        content
        context
        timestamp
      }
    }
  }
`;

const withSearchUserDatadogErrorsQuery = withGraphql<ExternalProps, SearchUserDatadogErrors.Query, SearchUserDatadogErrors.Variables>(
  SEARCH_USER_DATADOG_ERRORS_QUERY,
  {
    options: ({ userId }) => {
      return {
        errorPolicy: 'all',
        ssr: false, // admin only
        variables: {
          input: {
            userId: userId.toString(),
          },
        },
      };
    },
  },
);

const NoErrorsRow = ({ children }: NoErrorsRowProps) => {
  return (
    <tr>
      <td colSpan={4}>
        <div className="d-flex fx-justify-center pt-2">
          {children}
        </div>
      </td>
    </tr>
  );
};

export const ErrorRows = ({ errors }: DatadogErrorProps) => {
  if (errors.length === 0) {
    return (
      <NoErrorsRow>
        No recent credit card, PayPal, Plaid, or shipping label errors
      </NoErrorsRow>
    );
  }

  return (
    <>
      {errors.map(error => (
        <tr key={error.key}>
          <td>{error.type}</td>
          <td>{error.timestamp}</td>
          <td><SanitizedRender html={error.content} /></td>
          <td>{error.context}</td>
        </tr>
      ))}
    </>
  );
};

export const AdminUserErrors = ({ data, storedErrors }: Props) => {
  const errors = [...storedErrors, ...(data?.datadogErrorsSearch?.datadogErrors || [])];

  return (
    <table className="table">
      <thead>
        <tr>
          <th>Type</th>
          <th>Time</th>
          <th>Errors</th>
          <th>Context</th>
        </tr>
      </thead>
      <tbody>
        { data.loading ? (
          <NoErrorsRow>
            <RCLoadingBars />
          </NoErrorsRow>
        ) : (
          <ErrorRows errors={errors} />
        )}
      </tbody>
    </table>
  );
};

export default withSearchUserDatadogErrorsQuery(AdminUserErrors);
