import React from 'react';
import classNames from 'classnames';

import { imageUploadURL } from '../../url_helpers';

import MarkdownOutput from './markdown_output';

export interface IProps {
  heading: string;
  headingHtml: string;
  subheading?: string;
  subheadingHtml?: string;
  backgroundImageKey?: string;
  alignment?: 'right' | 'left' | 'center';
  jumplinkSlug?: string;
}

export default class Heading extends React.Component<IProps, null> {
  getBackgroundStyle() {
    if (!this.props.backgroundImageKey) return;
    const img = imageUploadURL(this.props.backgroundImageKey);

    return { backgroundImage: `url(${img})` };
  }

  render() {
    const classes = classNames(
      'cms-heading',
      { 'cms-heading--with-image': !!this.props.backgroundImageKey },
      { 'cms-heading--left': this.props.alignment === 'left' },
      { 'cms-heading--center': this.props.alignment === 'center' },
    );

    return (
      <div
        className={classes}
        style={this.getBackgroundStyle()}
        id={this.props.jumplinkSlug}
      >
        <div className="cms-heading__inner">
          <div className="cms-heading__text">
            <MarkdownOutput
              htmlTag="h3"
              className="cms-heading__title"
              text={this.props.heading}
              textHtml={this.props.headingHtml}
            />
            <MarkdownOutput
              htmlTag="h4"
              className="cms-heading__subtitle"
              text={this.props.subheading}
              textHtml={this.props.subheadingHtml}
            />
          </div>
        </div>
      </div>
    );
  }
}
