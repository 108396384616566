import React from 'react';

class Wrapper extends React.Component {
  render() {
    if (this.props.showSellerView) {
      return (
        <span className="dropdown inline-block">
          {this.props.children}
        </span>
      );
    } if (this.props.showBuyerView) {
      return (
        <div className="dropdown previously-bought-from-seller">
          {this.props.children}
        </div>
      );
    }
  }
}

export default Wrapper;
