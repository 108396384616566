import PropTypes from 'prop-types';
import React from 'react';
import AboutDirectCheckoutBullets from './about_direct_checkout_bullets';

function DirectCheckoutOnlyPaymentMethod(props) {
  return (
    <div className="payment-setup-method-col">
      <div className="payment-setup__label--dc">
        <div className="payment-setup__provider-logo payment-setup__provider-logo--rp-us" />
        <input
          type="hidden"
          value="direct_checkout"
          name={props.fieldName}
        />
      </div>
      <div className="payment-setup__info pl-0">
        <AboutDirectCheckoutBullets
          directCheckoutFeePercent={props.directCheckoutFeePercent}
          directCheckoutFeeFlatAmount={props.directCheckoutFeeFlatAmount}
          directCheckoutIntlFeePercent={props.directCheckoutIntlFeePercent}
          shopCurrency={props.shopCurrency}
        />
      </div>
    </div>
  );
}

DirectCheckoutOnlyPaymentMethod.propTypes = {
  directCheckoutFeePercent: PropTypes.number.isRequired,
  directCheckoutFeeFlatAmount: PropTypes.string.isRequired,
  directCheckoutIntlFeePercent: PropTypes.number.isRequired,
  shopCurrency: PropTypes.string.isRequired,
  fieldName: PropTypes.string.isRequired,
};

export default DirectCheckoutOnlyPaymentMethod;
