import { ChildProps } from '@apollo/client/react/hoc';
import { gql } from '@reverbdotcom/commons/src/gql';
import React from 'react';
import I18n from 'i18n-js';
import { EUODRAddress, CoreListingEuOdrAddress } from '@reverbdotcom/commons/src/gql/graphql';
import { withGraphql } from '@reverbdotcom/commons/src/with_graphql';

const ODR_URL = 'https://ec.europa.eu/consumers/odr';

export const EUODRAddressFragment = gql(`
  fragment EUODRAddress on Shop {
    _id
    vatId
    euOdrAddress {
      _id
      name
      streetAddress
      displayLocation
      postalCode
      email
      phone
    }
  }`);

interface IProps {
  shop: EUODRAddress.Fragment;
}

/**
 * Adds an EU ODR details widget to any query context
 * that has access to a shop. See the container below
 * if you need something self contained.
 */
export function EUODRDetails(props: IProps) {
  if (!props.shop) { return null; }
  const { euOdrAddress, vatId } = props.shop;
  if (!euOdrAddress) { return null; }

  return (
    <div>
      <p>
        {I18n.t('discovery.euOdrDetails.requiredInfo')}: <a href={ODR_URL} target="_blank" rel="noopener noreferrer">{ODR_URL}</a>
      </p>
      <table className="basic-table">
        <tbody>
          <tr>
            <td>
              {I18n.t('discovery.euOdrDetails.name')}
            </td>
            <td>
              {euOdrAddress.name}
            </td>
          </tr>
          <tr>
            <td>
              {I18n.t('discovery.euOdrDetails.address')}
            </td>
            <td>
              {euOdrAddress.streetAddress}<br />
              {euOdrAddress.displayLocation}<br />
              {euOdrAddress.postalCode}
            </td>
          </tr>
          <tr>
            <td>
              {I18n.t('discovery.euOdrDetails.email')}
            </td>
            <td>
              {euOdrAddress.email}
            </td>
          </tr>
          <tr>
            <td>
              {I18n.t('discovery.euOdrDetails.phone')}
            </td>
            <td>
              {euOdrAddress.phone}
            </td>
          </tr>
          {vatId && <tr>
            <td>
              {I18n.t('discovery.euOdrDetails.vatId')}
            </td>
            <td>
              {vatId}
            </td>
          </tr>}
        </tbody>
      </table>
    </div>
  );
}

interface ExternalContainerProps {
  shopSlug: string;
}

const withQuery = withGraphql<ExternalContainerProps, CoreListingEuOdrAddress.Query>(
  gql(`
    query Core_Listing_EuOdrAddress(
      $slug: String
    ) {
      shop(input: {slug: $slug}) {
        _id
        ...EUODRAddress
      }
    }
  `),
  {
    options: (ownProps) => {
      return {
        errorPolicy: 'all',
        ssr: false, // not important for SEO or initial page rendering
        variables: {
          slug: ownProps.shopSlug,
        },
      };
    },
  },
);

type IContainerProps = ChildProps<ExternalContainerProps, CoreListingEuOdrAddress.Query>;

/**
 * This wrapped container should only be used when this
 * component has to be truly standalone (e.g. in core).
 * Prefer using the fragment driven component when
 * possible.
 */
function EUODRDetailsContainer(props: IContainerProps) {
  if (!props.data) { return null; }
  if (props.data.loading) { return null; }

  return <EUODRDetails shop={props.data.shop} />;
}

export default withQuery(EUODRDetailsContainer);
