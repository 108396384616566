import { gql } from '@reverbdotcom/commons/src/gql';
import {
  Item2AvailableActionsData,
  core_apimessages_UserActionName,
  core_apimessages_UserActionStatus,
} from '@reverbdotcom/commons/src/gql/graphql';
import { trackEvent, MParticleEventName } from '@reverbdotcom/commons/src/elog/mparticle_tracker';
import { useViewTracking } from '@reverbdotcom/commons/src/use_tracking';

export function trackDismissUserActionEvent(componentName: string, userActionName: core_apimessages_UserActionName) {
  trackEvent({
    eventName: MParticleEventName.ClickedComponent,
    componentName: componentName,
    interactionType: userActionName,
  });
}

export function useTrackViewUserActionEvent(componentName: string, userActionName: core_apimessages_UserActionName, eligible: boolean, formattedExperiments?: string) {
  useViewTracking({
    eventName: MParticleEventName.ComponentView,
    componentName: componentName,
    interactionType: userActionName,
    experiments: formattedExperiments,
  }, eligible);
}

export function isUserActionAvailable(userActionName: string, availableActions: Item2AvailableActionsData.Fragment[]) {
  if (availableActions && userActionName) {
    return availableActions?.some(action => action.name === userActionName && action.status === core_apimessages_UserActionStatus.AVAILABLE);
  } else {
    return false;
  }
}

const USER_ACTION_NAMES = [
  core_apimessages_UserActionName.CUSTOMIZE_FEED_EDUCATION_CALLOUT,
  core_apimessages_UserActionName.SAVE_SHOP_EDUCATION_CALLOUT,
  core_apimessages_UserActionName.SAVE_SEARCH_EDUCATION_CALLOUT,
] as const;

type ActionNameToI18nPrefix = {
  [key in typeof USER_ACTION_NAMES[number]]: string;
};

export const AVAILABLE_ACTION_NAME_TO_I18N_PREFIX: ActionNameToI18nPrefix = {
  [core_apimessages_UserActionName.CUSTOMIZE_FEED_EDUCATION_CALLOUT]: 'discovery.favorites.callouts.customizeFeedEducation',
  [core_apimessages_UserActionName.SAVE_SHOP_EDUCATION_CALLOUT]: 'discovery.favorites.callouts.saveShopEducation',
  [core_apimessages_UserActionName.SAVE_SEARCH_EDUCATION_CALLOUT]: 'discovery.favorites.callouts.saveSearchEducation',
};


export const availableUserActionsQuery = gql(`
  query User_Available_Actions(
    $loggedOut: Boolean!
  ){
    me @skip(if: $loggedOut) {
      _id
      ...CreateUserActionMeData
      availableActions {
        ...Item2AvailableActionsData
      }
    }
  }
`);
