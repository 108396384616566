import React from 'react';
import I18n from 'i18n-js';
import { get } from 'lodash';

import { RCAlertBox } from '@reverbdotcom/cadence/components';
import CoreLink from '@reverbdotcom/commons/src/components/core_link';

import {
  MAX_SELLER_INITIATED_REFUND,
  withMyShopOrderRefundStats,
  IProps as IRefundStatsProps,
} from './shop_refund_stats';
import URLHelpers from './shared/url_helpers';

interface IComponentProps {
  includeInfo?: boolean;
}

export type IProps = IRefundStatsProps & IComponentProps;

export function SellerInitiatedRefundAlert({
  data,
  includeInfo,
}: IProps) {
  const [count, setCount] = React.useState<number | undefined>(undefined);
  const [showAlert, setShowAlert] = React.useState(false);
  const isAtRefundLimit = count === MAX_SELLER_INITIATED_REFUND;
  const exceededRefundLimit = count > MAX_SELLER_INITIATED_REFUND;
  const alertType: 'info' | 'warning' = count < MAX_SELLER_INITIATED_REFUND ? 'info' : 'warning';

  React.useEffect(() => {
    if (!data.loading) {
      const countTemp = get(data, 'me.shop.orderRefundStats.sellerInitiatedRefundCount');
      setCount(countTemp);
      setShowAlert(
        (includeInfo || !!data.error || (countTemp && countTemp >= MAX_SELLER_INITIATED_REFUND)),
      );
    }
  }, [data, includeInfo]);

  let headerText = I18n.t('discovery.sellerInitiatedRefundFee.title.underLimit');

  if (isAtRefundLimit || exceededRefundLimit) {
    headerText = I18n.t('discovery.sellerInitiatedRefundFee.title.atOrExceededLimit', { count });
  }

  const feeMessage = () => {
    if (isAtRefundLimit) {
      return I18n.t('discovery.sellerInitiatedRefundFee.feeMessage.atLimit');
    }

    if (exceededRefundLimit) {
      return I18n.t('discovery.sellerInitiatedRefundFee.feeMessage.exceededLimit', { max: MAX_SELLER_INITIATED_REFUND });
    }

    return I18n.t('discovery.sellerInitiatedRefundFee.feeMessage.underLimit', { max: MAX_SELLER_INITIATED_REFUND });
  };

  if (showAlert) {
    return (
      <div className="shop-refund-stats__alert">
        <RCAlertBox
          type={alertType}
          headerText={headerText}
          onDismiss={exceededRefundLimit ? undefined : () => setShowAlert(false)}
          small
        >
          {alertType === 'info' &&
            <ul>
              <li>
                {I18n.t('discovery.sellerInitiatedRefundFee.recommended', { max: MAX_SELLER_INITIATED_REFUND })}
                <CoreLink target="_blank" to={URLHelpers.sellerRefundFeePath}>
                  <strong>{I18n.t('discovery.sellerInitiatedRefundFee.helpCenterPolicy')}</strong>
                </CoreLink>
              </li>
              <li>
                {feeMessage()}
                <CoreLink target="_blank" to={URLHelpers.dashboardPath}>
                  <strong>{I18n.t('discovery.sellerInitiatedRefundFee.visitDashboard')}</strong>
                </CoreLink>
              </li>
            </ul>
          }

          {alertType !== 'info' &&
            <span className="shop-refund-stats__alert__content">
              {I18n.t('discovery.sellerInitiatedRefundFee.recommended', { max: MAX_SELLER_INITIATED_REFUND })}
              <span>{feeMessage()}</span>
              <CoreLink target="_blank" to={URLHelpers.sellerRefundFeePath}>
                <strong>{I18n.t('discovery.sellerInitiatedRefundFee.helpCenterPolicy')}</strong>
              </CoreLink>
            </span>
          }
        </RCAlertBox>
      </div>
    );
  }

  return null;
}

export default withMyShopOrderRefundStats(SellerInitiatedRefundAlert);
