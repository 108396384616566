import React from 'react';

interface IOption {
  name: string,
  value: string
}

interface IProps {
  autoComplete: string,
  changeLinkText: string,
  defaultValue: string,
  inputName: string,
  labelTitle: string,
  selectOptions: IOption[],
  onChange: (value: string) => void
}

interface IState {
  isEditing: boolean
}

class TogglableDropdown extends React.Component<IProps, IState> {
  constructor(props) {
    super(props);

    this.state = {
      isEditing: false,
    };
  }

  onSelectChange(event) {
    this.setState({ isEditing: false });
    this.props.onChange(event.target.value);
  }

  canChangeOption() {
    return this.props.selectOptions.length > 1;
  }

  changeOptionLink() {
    if (!this.canChangeOption()) {
      return false;
    }

    return (
      <button
        type="button"
        className="togglable-dropdown__button"
        onClick={() => this.setState({ isEditing: true })}
      >
        {this.props.changeLinkText}
      </button>
    );
  }

  optionsForSelect() {
    return (
      this.props.selectOptions.map(option =>
        (<option
          key={option.value}
          value={option.value}
        >
          {option.name}
        </option>))
    );
  }

  optionLabel() {
    return (
      <label htmlFor={this.props.labelTitle}>
        {this.props.labelTitle}
      </label>
    );
  }

  nameForDefaultValue() {
    const defaultValue = this.props.selectOptions.find(option => option.value === this.props.defaultValue);

    return defaultValue.name;
  }

  optionSelector() {
    return (
      <>
        {this.optionLabel()}
        <select
          name={this.props.inputName}
          id={this.props.labelTitle}
          className="required nochosen"
          autoComplete={this.props.autoComplete}
          defaultValue={this.props.defaultValue}
          onChange={event => this.onSelectChange(event)}
        >
          {this.optionsForSelect()}
        </select>
      </>
    );
  }

  defaultValueHiddenInput() {
    return (
      <>
        {this.optionLabel()}
        <input
          type="hidden"
          name={this.props.inputName}
          value={this.props.defaultValue}
        />
        <>
          {this.nameForDefaultValue()} {this.changeOptionLink()}
        </>
      </>
    );
  }

  render() {
    if (this.state.isEditing) {
      return this.optionSelector();
    }

    return this.defaultValueHiddenInput();
  }
}

export default TogglableDropdown;
