export default {
  adminBlock: {
    block: 'Block',
    blocked: 'Product Review Blocked',
  },
  card: {
    verifiedPurchase: 'Purchased on Reverb',
    voteCount: {
      one: '{{count}} person found this helpful',
      other: '{{count}} people found this helpful',
    },
  },
  flagReview: {
    report: 'Report',
    reported: 'Reported',
    modalTitle: 'Report Review',
    form: {
      cancel: 'Cancel',
      description: 'Reporting this review notifies the Reverb team of a potential issue. We then check the review and take action consistent with our community rules.',
      notRelevant: 'Not Relevant',
      notRelevantDescription: 'Describes issues with the seller, shipping, packaging, specifications, or a different product.',
      offensive: 'Offensive',
      offensiveDescription: "Discriminatory, abusive or violent language, or anything that violates {{linkOpen}}Reverb's Community Rules{{linkClose}}.",
      prompt: 'Would you like to report this review?',
      reasonsPrompt: 'Why are you reporting this review?',
      reportReview: 'Report Review',
      spam: 'Spam',
      spamDescription: 'Contains personal information, links to other websites/content (i.e. spam), promotional reviews.',
    },
    submitted: 'Your report has been submitted.',
  },
  form: {
    createReview: 'Write a Product Review',
    editReview: 'Edit Your Review',
    rating: 'Your Rating',
    review: 'Your Product Review',
    submit: 'Post Review',
    title: 'Review Title',
  },
  guidelines: {
    do: 'Do: talk about how it sounds, mention pros and cons, and compare it to other products.',
    dont: "Don't: review a seller, your shipping experience, or include offensive content.",
    seeAll: 'see all',
    tips: 'Review Tips',
  },
  header: {
    noReviews: 'There are currently no reviews for this product',
    subtitle: '{{averageRating}} out of 5 based on {{count}} reviews',
    title: 'Product Reviews',
    successMessage: 'Your review has been published. Thanks for helping other musicians!',
    errorMessage: {
      body: 'Please include a description for your review',
      rating: 'Please include a rating',
    },
  },
  reviewsRating: {
    reviews: {
      one: '{{count}} review',
      other: '{{count}} reviews',
    },
    stars: {
      one: '{{count}} star',
      other: '{{count}} stars',
    },
  },
  searchInput: {
    placeholder: 'Search product reviews',
    zeroResults: '0 results containing <b>"{{searchQuery}}"</b>',
  },
  reviewWarning: {
    warning: 'It looks like you might be reviewing a seller or shopping experience – this form is intended only for reviewing the item itself.',
  },
  voteButton: {
    helpful: 'Helpful',
  },
};
