import PropTypes from 'prop-types';
import React from 'react';
import I18n from 'i18n-js';

class ProductPreview extends React.Component {
  constructor(props) {
    super(props);

    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    return this.props.handleClick(this.props.product);
  }

  render() {
    return (
      <div
        className="product-preview"
        onClick={this.handleClick}
        key={this.props.product.id}
      >
        <div className="product-preview__thumbnail">
          <img
            alt={this.props.product.title}
            src={this.props.product._links.photo.href}
          />
        </div>

        <div className="product-preview__content">
          <h4 className="product-preview__title">
            {this.props.product.title}
          </h4>

          {this.props.product.sku &&
            <div className="product-preview__sku">
              {I18n.t('discovery.productSearchForm.productDetails.sku', { sku: this.props.product.sku })}
            </div>
          }

          {this.props.product.condition &&
            <div className="product-preview__condition">
              {I18n.t('discovery.productSearchForm.productDetails.condition', {
                condition: this.props.product.condition.display_name,
              })}
            </div>
          }

          {this.props.product.seller_cost &&
            <p className="product-preview__cost">
              {I18n.t('discovery.productSearchForm.productDetails.sellerCost', {
                cost: this.props.product.seller_cost.display,
              })}
            </p>
          }
        </div>

        <span className="product-preview__price">
          {this.props.product.price.display}
        </span>
      </div>
    );
  }
}

ProductPreview.propTypes = {
  product: PropTypes.object.isRequired,
  handleClick: PropTypes.func,
};

export default ProductPreview;
