import { ChildProps } from '@apollo/client/react/hoc';
import React from 'react';
import { RCAlertBox, RCTextWithIcon } from '@reverbdotcom/cadence/components';
import CoreLink from '@reverbdotcom/commons/src/components/core_link';
import I18n from 'i18n-js';
import { I18N } from '@reverbdotcom/commons/src/components/translate';
import { Paths } from '@reverbdotcom/commons/src/url_helpers';
import { SearchAltIcon } from '@reverbdotcom/cadence/icons/react';
import { MParticleEventName } from '@reverbdotcom/commons/src/elog/mparticle_tracker';
import { useViewTracking } from '@reverbdotcom/commons/src/use_tracking';
import { CoreWithSuggestionCheckListingsSearch, MarketplaceSuggestions } from '@reverbdotcom/commons/src/gql/graphql';
import WithSuggestionCheck, { isSuggestionValid } from './with_suggestion_check';

interface IExternalProps {
  query: string;
  suggestion: MarketplaceSuggestions.Fragment;
}

type IProps = ChildProps<IExternalProps, CoreWithSuggestionCheckListingsSearch.Query>;

const COMPONENT_NAME = 'LowResultsAlert';

export function LowResultsAlert(props: IProps) {
  const viewTrackingData = {
    componentName: COMPONENT_NAME,
    eventName: MParticleEventName.ComponentView,
    suggestion: props.suggestion?.text,
  };

  const suggestionValid = isSuggestionValid(props);

  useViewTracking(viewTrackingData, suggestionValid);

  if (!suggestionValid) return null;

  const clickTrackingData = {
    componentName: COMPONENT_NAME,
    eventName: MParticleEventName.ClickedSearchSuggestion,
    suggestion: props.suggestion.text,
  };

  return (
    <div className="mb-6">
      <RCAlertBox
        type="info"
        headerText={I18n.t('discovery.noResults.searchReturningLowResults')}
      >
        <div className="d-flex fx-align-center fx-wrap">
          <div>
            <I18N className="mobile-d-block" html text="discovery.noResults.trySearchingForMoreResults">
              <CoreLink
                className="button mlr-space mobile-mlr-0 mobile-d-block mobile-mtb-2 ws-normal"
                to={Paths.marketplace.expand({ query: props.suggestion.text })}
                clickEvent={clickTrackingData}
              >
                <RCTextWithIcon svgComponent={SearchAltIcon}>
                  <span>{props.suggestion.text}</span>
                </RCTextWithIcon>
              </CoreLink>
            </I18N>
          </div>
        </div>
      </RCAlertBox>
    </div>
  );
}

export default WithSuggestionCheck<IProps>(LowResultsAlert);
