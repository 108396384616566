import React from 'react';
import I18n from 'i18n-js';
import SanitizedRender from '@reverbdotcom/commons/src/components/sanitized_render';

interface Props {
  impressions: number;
  hasInventory: boolean;
  salesCount: number;
  salesSpent: string;
  salesRevenue: string;
}

export function BumpStats(props: Props) {
  return (
    <>
      <h3 className="product-bump__overview-col__stat-heading">{I18n.t('discovery.bump.change.performance')}</h3>
      <ul className="product-bump__overview-col__stats">
        <li>
          <div className="product-bump__overview-col__stats__stat">
            {props.impressions}
          </div>
          <div className="product-bump__overview-col__stats__label">
            {I18n.t('discovery.bump.change.impressions')}
          </div>
        </li>
        {props.hasInventory &&
          <li>
            <div className="product-bump__overview-col__stats__stat">
              {props.salesCount}
            </div>
            <div className="product-bump__overview-col__stats__label">
              {I18n.t('discovery.bump.change.numberSold')}
            </div>
          </li>
        }
        <li>
          <div
            className="product-bump__overview-col__stats__stat"
          >
            <SanitizedRender
              html={props.salesSpent}
            />
          </div>
          <div className="product-bump__overview-col__stats__label">
            {I18n.t('discovery.bump.change.spent')}
          </div>
        </li>
        <li>
          <div
            className="product-bump__overview-col__stats__stat"
          >
            <SanitizedRender
              html={props.salesRevenue}
            />
          </div>
          <div className="product-bump__overview-col__stats__label">
            {I18n.t('discovery.bump.change.sales')}
          </div>
        </li>
      </ul>
    </>
  );
}
