import { intersection } from 'lodash';

export function conditionFilter(raw: string | string[]) {
  let conditionParam = raw;

  if (!Array.isArray(raw)) {
    conditionParam = [raw];
  }

  const usedConditions = intersection(
    ['used', 'b-stock', 'non-functioning'],
    conditionParam,
  );

  const newConditions = intersection(['new'], conditionParam);

  if (usedConditions.length && !newConditions.length) {
    return 'used';
  }

  if (newConditions.length && !usedConditions.length) {
    return 'new';
  }
}
