import React from 'react';
import { noop, omit } from 'lodash';
import { CardElementData } from '@adyen/adyen-web/dist/types/components/Card/types';
import {
  CbObjOnFieldValid as FieldValid,
  CbObjOnError as CardError,
  CbObjOnFocus as CardFocus,
  CbObjOnBrand as CardBrand,
  CbObjOnBinValue as CardBinValue,
} from '@adyen/adyen-web/dist/types/components/internal/SecuredFields/lib/types';
import { AdyenCardType } from './adyen';
import { useFiveDigitLocale } from './user_five_digit_locale_hook';
import { adyenCheckout } from './adyen_checkout';
import { useAdyenEnvironment } from './adyen_environment_hook';

export interface CardState {
  isValid: boolean; // Specifies if all the information that the shopper provided is valid.
  data: Partial<CardElementData>; // Provides the data that you need to pass in the `/payments` call.
}

export type CSEName =
  | 'encryptedCardNumber'
  | 'encryptedExpiryDate'
  | 'encryptedExpiryMonth'
  | 'encryptedExpiryYear'
  | 'encryptedSecurityCode';

// https://docs.adyen.com/payment-methods/cards/custom-card-integration#styling
const STYLES = {
  base: {
    background: 'transparent',
    color: '#212121',
    fontSize: '16px',
    fontSmoothing: 'auto',
    // eslint-disable-next-line max-len
    fontFamily: 'Consolas, "Andale Mono WT", "Andale Mono", "Lucida Console", "Lucida Sans Typewriter", "DejaVu Sans Mono", "Bitstream Vera Sans Mono", "Liberation Mono", "Nimbus Mono L", Monaco, "Courier New", Courier, monospace',
    fontWeight: '400',
    lineHeight: '1.4',
    padding: '6px 8px',
  },
};

type Errors = Record<string, Partial<CardError>>;

type BrandCallback = (brand: string) => void;
type CardChangeCallback = (cardState: CardState) => void;
type EndDigitsCallback = (endDigits: string) => void;
type ErrorCallback = (errors: Errors) => void;
type BinValueCallback = (binValue: string) => void;

interface AdyenCardOptions {
  selector: string;
  cardType: AdyenCardType;
  onBrand?: BrandCallback;
  onCardChange?: CardChangeCallback;
  onEndDigits?: EndDigitsCallback;
  onError?: ErrorCallback;
  onBinValue?: BinValueCallback;
}

export interface AdyenCardResponse {
  focus: Partial<CardFocus>;
  errors: Errors;
  cardState: CardState;
  endDigits: string;
  brand: string;
  binValue: string;
}

// Shared custom credit card hook (https://docs.adyen.com/payment-methods/cards/custom-card-integration)
export function useAdyenCard(options: AdyenCardOptions): AdyenCardResponse {
  const locale = useFiveDigitLocale();
  const { environment, clientKey } = useAdyenEnvironment();

  const { cardType, selector } = options;
  const [errors, setErrors] = React.useState({
    encryptedCardNumber: {},
    encryptedSecurityCode: {},
    encryptedExpiryMonth: {},
    encryptedExpiryYear: {},
  });
  const [cardState, setCardState] = React.useState<CardState>({ isValid: false, data: { paymentMethod: { type: 'card' } } });

  const [focus, setFocus] = React.useState({});
  const [endDigits, setEndDigits] = React.useState('');
  const [brand, setBrand] = React.useState('');
  const [binValue, setBinValue] = React.useState('');

  React.useEffect(() => {
    if (!environment || !clientKey) {
      return;
    }
    const onErrorOrDefault = options.onError || noop;

    const onBrand = buildBrandCallback(setBrand, options.onBrand || noop);
    const onChange = buildCardChangeCallback(setCardState, options.onCardChange || noop);
    const onError = buildErrorCallback(setErrors, onErrorOrDefault);
    const onFieldValid = buildFieldValidCallback({
      setErrors,
      setEndDigits,
      onError: onErrorOrDefault,
      onEndDigits: options.onEndDigits || noop,
    });
    const onBinValue = buildBinValidCallback(setBinValue, options.onBinValue || noop);

    const checkout = adyenCheckout({
      configuration: {
        clientKey,
        environment,
        locale,
        onChange,
      },
    });

    checkout.create('securedfields', {
      type: cardType,
      brands: ['amex', 'cup', 'diners', 'discover', 'jcb', 'mc', 'visa'],
      styles: STYLES,
      onError,
      onFocus: onFocus(setFocus),
      onBrand,
      onFieldValid,
      onBinValue,
    }).mount(selector);
  }, [
    locale,
    selector,
    environment,
    clientKey,
    cardType,
    setCardState,
    setFocus,
    setErrors,
    setEndDigits,
    setBinValue,
    options.onCardChange,
    options.onEndDigits,
    options.onError,
    options.onBrand,
    options.onBinValue,
  ]);

  return { focus, errors, cardState, endDigits, brand, binValue };
}

function onFocus(setFocus) {
  return (event: CardFocus) => {
    setFocus(event);
  };
}

function buildErrorCallback(setErrors, onError: ErrorCallback) {
  return (event: CardError) => {
    setErrors((previousErrors: Errors) => {
      const errors = { ...previousErrors, [event.fieldType]: event };
      onError(errors);
      return errors;
    });
  };
}

function buildCardChangeCallback(setCardState, onCardChange: CardChangeCallback) {
  return (state: CardState) => {
    onCardChange(state);
    setCardState(state);
  };
}

function buildBrandCallback(setBrand, onBrand: BrandCallback) {
  return (event: CardBrand) => {
    onBrand(event.brand);
    setBrand(event.brand);
  };
}

function buildFieldValidCallback({ setErrors, setEndDigits, onEndDigits, onError }) {
  return (event: FieldValid) => {
    setErrors((previousErrors: Errors) => {
      const errors = omit(previousErrors, event.fieldType);
      onError(errors);
      return errors;
    });

    if (event.fieldType === 'encryptedCardNumber') {
      onEndDigits(event.endDigits);
      setEndDigits(event.endDigits);
    }
  };
}

function buildBinValidCallback(setBinValue, onBinValue: BinValueCallback) {
  return (event: CardBinValue) => {
    onBinValue(event.binValue);
    setBinValue(event.binValue);
  };
}

export const callbacks = {
  onBrand: buildBrandCallback,
  onChange: buildCardChangeCallback,
  onError: buildErrorCallback,
  onFieldValid: buildFieldValidCallback,
  onBinValue: buildBinValidCallback,
  onFocus,
};
