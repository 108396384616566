import React from 'react';
import I18n from 'i18n-js';
import PostalAddress from 'i18n-postal-address';
import { core_apimessages_Address } from '@reverbdotcom/commons/src/gql/graphql';

type AddressPropFields =
  | 'name'
  | 'streetAddress'
  | 'extendedAddress'
  | 'phone'
  | 'locality'
  | 'region'
  | 'country'
  | 'postalCode'
  | 'regionName'
  | 'isComplete'
  | 'verified';

type Address = Pick<core_apimessages_Address, AddressPropFields>;

const LETTER_VARIANT = 'letter';
const INLINE_VARIANT = 'inline';
type Variant = typeof LETTER_VARIANT | typeof INLINE_VARIANT;

interface Props {
  address: Address;
  variant?: Variant;
}

export default function AddressSummary({
  address,
  variant = LETTER_VARIANT,
}: Props) {
  const addressLines = mapAddressToAddressLines(address);

  function showIncompleteAddressWarning() {
    if (address.isComplete == null) { return false; }

    return !address.isComplete;
  }

  function displayVariant() {
    if (variant === INLINE_VARIANT) { return addressLines.join(', '); }

    return (
      <>
        {addressLines.map(((addressLine) => (
          <AddressLineDisplay
            data-address-summary__address-line
            key={addressLine}
            text={addressLine}
          />
        )))}
      </>
    );
  }

  return (
    <div className="formatted-address">
      <p className="formatted-address__info">
        {displayVariant()}
      </p>
      {showIncompleteAddressWarning() &&
        <p className="formatted-address__incomplete">
          {I18n.t('discovery.addressBook.addressSummary.incompleteAddress')}
        </p>
      }
    </div>
  );
}

function AddressLineDisplay(props: { text: string }) {
  const { text } = props;

  return (
    <>
      <span>
        {text}
      </span>
      <br />
    </>
  );
}

function mapAddressToAddressLines(address: Address): string[] {
  return buildPostalAddress(address).toArray().map(line => {
    if (line.length > 1) {
      return line.join(' ');
    }

    return line[0];
  });
}

function buildPostalAddress(address: Address): PostalAddress {
  const postalAddress = new PostalAddress();

  return postalAddress
    .setFirstName(address.name)
    .setAddress1(address.streetAddress)
    .setAddress2(address.extendedAddress)
    .setCity(address.locality)
    .setState(address.regionName)
    .setProvince(address.regionName)
    .setPostalCode(address.postalCode)
    .setCountry(address.country?.name)
    .setFormat({
      country: address.country?.countryCode,
      type: 'default',
    });
}
