import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import { APIRequest, SanitizedRender, RCStarRating } from '../index';
import ReviewList from './list';
import Pagination from './pagination';
import Loading from '../shared/loading';
import ReviewForm from './form';
import User from '../user';
import { UI } from '../legacy/ui';
import I18n from 'i18n-js';

const SHOW_REVIEW_FORM = 'show-review-form';

class ProductReviews extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isFormOpen: props.formOpenByDefault,
      loading: false,
      reviews: [],
      canReview: props.canReview,
      total: props.total,
      formError: null,
      rating: props.rating,
    };

    this.handleFormClose = this.handleFormClose.bind(this);
    this.handleFormOpen = this.handleFormOpen.bind(this);
  }

  componentDidMount() {
    if (this.props.total) {
      this.fetchReviews(this.props.reviewLink, { per_page: this.props.numberPerPage });
    }

    if (this.props.scrollToForm) {
      window.location.hash = this.props.sectionSelector;
    }
  }

  fetchReviews(url, data) {
    this.setState({ loading: true });
    return APIRequest.get(url, data)
      .then((response) => {
        this.setState({
          reviews: response.product_reviews,
          total: response.total,
          loading: false,
          prevLink: response._links && response._links.prev,
          nextLink: response._links && response._links.next,
          rating: response.rating,
        });
      }).fail(() => {
        this.setState({ loadingError: true });
      });
  }

  createSuccess(response) {
    this.alertCreateSuccess(response.activated_user);
    this.setState({ canReview: false });
    return this.fetchReviews(this.props.reviewLink, { per_page: this.props.numberPerPage });
  }

  updateSuccess() {
    this.setState({ successMessage: 'Your review has been updated!' });
    return this.fetchReviews(this.props.reviewLink, { per_page: this.props.numberPerPage });
  }

  saveFail(response) {
    const error = JSON.parse(response.responseText);
    this.setState({ formError: error.message });
  }

  alertCreateSuccess(activatedUser) {
    let successMessage;
    if (activatedUser) {
      successMessage = I18n.t('js.productReviews.createSuccessActivated');
    } else {
      successMessage = I18n.t('js.productReviews.createSuccessNotActivated');
    }
    this.setState({ successMessage });
  }

  followPagination(href) {
    window.Reverb.SmoothScroll.scroll(this.props.sectionSelector);
    return this.fetchReviews(href, {});
  }

  handleFormClose() {
    this.setState({ isFormOpen: false });
  }

  handleFormOpen(e) {
    if (UI.isLoggedIn()) {
      this.setState({ isFormOpen: true });
    } else {
      const newUrl = `${window.location.pathname}?${SHOW_REVIEW_FORM}=true${window.location.hash}`;
      window.history.pushState({}, SHOW_REVIEW_FORM, newUrl);
      User.showInlineRegistrationOrLogin(e, e.target);
    }
  }

  reviewButton(widthClass) {
    return (
      <button
        className={classNames('button', 'button--small', 'button--primary', 'mb-1', widthClass)}
        onClick={this.handleFormOpen}
        data-registration-message="write a review"
      >
        {I18n.t('js.productReviews.writeAReview')}
      </button>
    );
  }

  renderReviewForm() {
    if (!(this.state.canReview && UI.isLoggedIn())) { return false; }

    return (
      <ReviewForm
        submitSuccess={response => this.createSuccess(response)}
        submitFail={response => this.saveFail(response)}
        saveLink={this.props.reviewLink}
        errorMessage={this.state.formError}
        onClose={this.handleFormClose}
        saveMethod="POST"
        isOpen={this.state.isFormOpen}
        disableDebounce={this.props.disableWarningDebounce}
        submitText={I18n.t('js.productReviews.reviewSubmit')}
        showSellerReviewWarning
      />
    );
  }

  renderWriteAReview() {
    if (!this.state.canReview) { return false; }
    if (this.state.isFormOpen) { return false; }

    return (
      <div className="g-col-4 g-col-mobile-12 align-center size-90">
        {this.renderReviewButton('wide')}
        <p className="fine-print muted extratight-line-height mb-0 show-only-on-desktop">
          {I18n.t('js.productReviews.reviewNudge')}
        </p>
      </div>
    );
  }

  renderSuccess() {
    if (!this.state.successMessage) { return false; }

    return <p className="alert alert-success">{this.state.successMessage}</p>;
  }

  renderPagination() {
    if (this.state.loading) {
      return (
        <Loading
          hasError={this.state.loadingError}
          loaded={this.state.loadingError}
          errorMessage={I18n.t('js.productReviews.loadingError')}
        />
      );
    }

    if (this.state.total <= this.props.numberPerPage) {
      return null;
    }

    return (
      <div className="pagination">
        <ul>
          <Pagination
            direction={I18n.t('js.previous')}
            link={this.state.prevLink}
            clickEvent={href => this.followPagination(href)}
          />
          <Pagination
            direction={I18n.t('js.next')}
            link={this.state.nextLink}
            clickEvent={href => this.followPagination(href)}
          />
        </ul>
      </div>
    );
  }

  renderReviewButton(cssClass) {
    if (this.state.isFormOpen) { return null; }

    return this.reviewButton(cssClass);
  }

  renderHeader() {
    const classes = classNames('mobile-mb-2 g-col-mobile-12', {
      'g-col-8': this.state.canReview,
      'g-col-12': !this.state.canReview,
    });

    if (this.state.total) {
      return (
        <div className="g-container">
          <div className={classes}>
            <h2 className={this.props.headingClass}>
              <SanitizedRender
                html={I18n.t('js.productReviews.reviewsFor', { productTitle: this.props.productTitle })}
              />
            </h2>
            <RCStarRating rating={this.state.rating / 5} count={this.state.total} />
          </div>
          {this.renderWriteAReview()}
        </div>
      );
    }

    return (
      <div className="align-center">
        <h2 className="heading-2">{I18n.t('js.productReviews.noReviewsYet')}</h2>
        <p className="lead mb-2">
          {I18n.t('js.productReviews.firstToWriteReview')}
          <br />
          {I18n.t('js.productReviews.helpAnotherMusician')}
        </p>
        {this.renderReviewButton('mobile-width-100')}
      </div>
    );
  }

  renderReviews() {
    if (!this.state.total) { return false; }

    return (
      <div>
        <hr className="scaling-mtb-2" />
        <ReviewList reviews={this.state.reviews} updateSuccess={() => this.updateSuccess()} />
        {this.renderPagination()}
      </div>
    );
  }

  render() {
    return (
      <div className="product-reviews">
        {!this.props.hideHeader && this.renderHeader()}
        {this.renderSuccess()}
        {this.renderReviewForm()}
        {this.renderReviews()}
      </div>
    );
  }
}

ProductReviews.propTypes = {
  formOpenByDefault: PropTypes.bool,
  canReview: PropTypes.bool,
  total: PropTypes.number.isRequired,
  rating: PropTypes.number,
  reviewLink: PropTypes.string.isRequired,
  headingClass: PropTypes.string.isRequired,
  productTitle: PropTypes.string.isRequired,
  sectionSelector: PropTypes.string.isRequired,
  numberPerPage: PropTypes.number.isRequired,
  scrollToForm: PropTypes.bool,
  disableWarningDebounce: PropTypes.bool,
  hideHeader: PropTypes.bool,
};

export default ProductReviews;
