import I18n from 'i18n-js';
import React from 'react';
import { withUserContext, IUserContext } from '@reverbdotcom/commons/src/components/user_context_provider';
import CoreLink from '@reverbdotcom/commons/src/components/core_link';
import OverflowingRow from '@reverbdotcom/commons/src/components/overflowing_row';
import Tiles from '@reverbdotcom/commons/src/components/tiles';

import Listing from './listing';
import ListingCard from './listing_card';

const RListingCard = ListingCard as any;

export interface ExternalProps {
  title?: string;
  titleElement?: JSX.Element;
  listings?: Listing[];
  trackName?: string;

  ctaTarget?: string;
  alwaysShowCTA?: boolean;
  ctaText?: string;
  minListings?: number;
  totalListings?: number;
  trackContext?: object;
  expectedCount?: number;
  largeTiles?: boolean;
  error?: boolean;
  centered?: boolean;
  displayAsGrid?: boolean;
}

export type Props = ExternalProps & IUserContext;

export class ListingRow extends React.Component<Props> {
  static defaultProps: Partial<Props> = {
    trackName: 'ListingRow',
    expectedCount: 6,
    largeTiles: false,
    centered: false,
  };

  static listingIds(listings) {
    if (!listings) { return []; }
    return listings.map(l => l.id);
  }

  get listingsLoaded() {
    // empty array counts as loaded
    return !!this.props.listings;
  }

  get notEnoughListings() {
    return this.listingsLoaded
      && this.props.minListings
      && this.props.listings.length < this.props.minListings;
  }

  listings() {
    if (this.listingsLoaded) { return this.props.listings.slice(0, this.props.expectedCount); }
    return [];
  }

  collectionCount() {
    if (this.props.listings) { return this.props.listings.length; }
    return this.props.expectedCount;
  }

  shouldRenderLink() {
    return this.props.ctaTarget && (
      this.props.alwaysShowCTA ||
        (this.props.totalListings && this.props.totalListings > this.props.expectedCount)
    );
  }

  renderAction() {
    if (!this.shouldRenderLink()) { return null; }

    return (
      <CoreLink
        to={this.props.ctaTarget}
        trackName={this.props.trackName}
      >
        {this.props.ctaText || I18n.t('discovery.listingsRow.seeMore')}
      </CoreLink>
    );
  }

  renderTitle() {
    if (this.props.titleElement) { return this.props.titleElement; }

    if (this.shouldRenderLink()) {
      return (
        <CoreLink
          to={this.props.ctaTarget}
          trackName={this.props.trackName}
        >
          {this.props.title}
        </CoreLink>
      );
    }

    return this.props.title;
  }

  renderListingViews() {
    if (!this.listingsLoaded) { return []; }

    return this.props.listings.map((listingJson, index) => {
      const listing = new Listing(listingJson);
      return (
        <RListingCard
          key={listing.id}
          listing={listing}
          position={index}
          trackName={this.props.trackName}
          trackContext={this.props.trackContext}
        />
      );
    });
  }

  renderTiles() {
    return (
      <Tiles
        placeholder={<ListingCard listing={null} />}
        loading={!this.listingsLoaded}
        expectedCount={this.props.expectedCount}
        singleRow={!this.props.displayAsGrid}
        largeTiles={this.props.largeTiles}
        centered={this.props.centered}
      >
        {this.renderListingViews()}
      </Tiles>
    );
  }

  render() {
    if (this.props.error) { return null; }
    if (this.notEnoughListings) { return null; }

    return (
      <OverflowingRow
        title={this.renderTitle()}
        action={this.renderAction()}
        collectionCount={this.collectionCount()}
        centeredTitle={this.props.centered}
      >
        {this.renderTiles()}
      </OverflowingRow>
    );
  }
}

export default withUserContext<Props>(ListingRow);
