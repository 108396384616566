import React, { useState } from 'react';
import { gql } from '@reverbdotcom/commons/src/gql';
import { useMutation } from '@reverbdotcom/commons/src/useMutation';
import { useQuery } from '@reverbdotcom/commons/src/useQuery';
import FormSection from '@reverbdotcom/commons/src/components/form_section';
import { I18N as Translate } from '@reverbdotcom/commons/src/components/translate';
import {
  RCLoadingBars,
} from '@reverbdotcom/cadence/components';
import { USTaxProfileVerificationStatus } from './tin_verification_status';
import TaxProfileVerificationFailed from './tax_profile_verification_failed';
import USTaxProfileContainerDescription from './us_tax_profile_container_description';
import USTaxProfileForm from './us_tax_profile_form';
import USTaxProfile from './us_tax_profile';
import {
  core_apimessages_TaxProfile_TaxProfileVerificationStatus as VerificationStatus,
  InputTaxProfileUpsertRequest,
} from '@reverbdotcom/commons/src/gql/graphql';
import { ErrorAlertBox } from '@reverbdotcom/commons/src/ErrorAlertBox';

const MY_TAX_PROFILE_QUERY = gql(`
    query Core_My_TaxProfile_Query {
      me {
        _id
        uuid
        shop {
          _id
          address {
            _id
            uuid
            name
            streetAddress
            extendedAddress
            phone
            locality
            region
            country {
              _id
              countryCode
              name
            }
            postalCode
          }
          taxProfile {
            firstName
            id
            lastName
            mask
            name
            tinType
            verificationStatus
            verifiedAt
            address {
              _id
              uuid
              name
              streetAddress
              extendedAddress
              phone
              locality
              region
              regionName
              country {
                _id
                countryCode
                name
              }
              postalCode
            }
          }
        }
      }
    }
`);

const DELETE_TAX_PROFILE = gql(`
  mutation Core_DeleteTaxProfile {
    deleteTaxProfile {
      id
    }
  }
`);

const UPSERT_TAX_PROFILE = gql(`
  mutation Core_UpsertTaxProfile($input: InputTaxProfileUpsertRequest) {
    upsertTaxProfile(input: $input) {
      id
    }
  }
`);

export default function USTaxProfileContainer() {
  const { data: taxProfileData, loading: isTaxProfileLoading } = useQuery(
    MY_TAX_PROFILE_QUERY,
    { ssr: true },
  );

  const [ deleteTaxProfileMutation, { loading: deleteLoading, errors: deleteErrors } ] = useMutation(DELETE_TAX_PROFILE);

  const [ upsertTaxProfileMutation, { loading: upsertLoading, errors: upsertErrors } ] = useMutation(UPSERT_TAX_PROFILE);

  const taxProfile = taxProfileData?.me?.shop?.taxProfile;
  const shopAddress = taxProfileData?.me?.shop?.address;

  const [ isEditing, setIsEditing ] = useState(false);

  async function handleDelete() {
    const result = await deleteTaxProfileMutation({
      refetchQueries: ['Core_My_TaxProfile_Query'],
      awaitRefetchQueries: true,
    });

    if (result.data?.deleteTaxProfile?.id) {
      setIsEditing(true);
    }
  }

  async function submitTaxProfile(taxProfileData: InputTaxProfileUpsertRequest) {
    const result = await upsertTaxProfileMutation({
      variables: {
        input: taxProfileData,
      },
      refetchQueries: ['Core_My_TaxProfile_Query'],
      awaitRefetchQueries: true,
    });

    if (result.data?.upsertTaxProfile?.id) {
      setIsEditing(false);
    }
  }

  const errors = [...deleteErrors, ...upsertErrors];

  return (
    <FormSection
      description={!isTaxProfileLoading && (
        <>
          <div className="mtb-2">
            <USTaxProfileVerificationStatus verificationStatus={taxProfile?.verificationStatus} />
          </div>
          <h3 className="form-section__title">
            <Translate className="text-color-primary" text="discovery.taxProfile.usTaxProfile.title" />
          </h3>
          <USTaxProfileContainerDescription taxProfile={taxProfile} isEditing={isEditing} />
        </>
      )}
    >
      { (isTaxProfileLoading || deleteLoading) ? (
        <RCLoadingBars />
      ) : (
        <>
          {taxProfile?.verificationStatus === VerificationStatus.FAILED_VERIFICATION && (
            <div className="mb-6">
              <TaxProfileVerificationFailed />
            </div>
          )}
          { errors.length > 0 && (
            <div className="mb-2">
              <ErrorAlertBox errors={errors} />
            </div>
          )}
          {(isEditing || !taxProfile?.id) ? (
            <USTaxProfileForm
              taxProfile={taxProfile}
              shopAddress={shopAddress}
              cancelEdit={() => setIsEditing(false)}
              submitTaxProfile={submitTaxProfile}
              isSubmitting={upsertLoading}
            />
          ) : (
            <USTaxProfile
              taxProfile={taxProfile}
              handleDelete={handleDelete}
              handleEditClick={() => setIsEditing(true)}
            />
          )}
        </>
      )}
    </FormSection>
  );
}


