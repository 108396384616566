import qs from 'qs';
import { matchPattern } from 'react-router/lib/PatternUtils';
import { GridParams } from '../discovery/listing_row_manager';
import URLHelpers from './url_helpers';

interface Location {
  origin: string;
  pathname: string;
  search: string;
  href: string;
}

function extractQueryParams(loc: Location) {
  return qs.parse(loc.search?.slice(1));
}

const locationUtil = {
  isOnMarketplacePage(loc: Location = window.location) {
    return !!loc.pathname.match('/marketplace');
  },

  isOnBrandPage(loc: Location = window.location) {
    return !!loc.pathname.match('/brand/');
  },

  isOnSalePage(loc: Location = window.location) {
    return !!loc.pathname.match('/sales/');
  },

  isOnHandpickedPage(loc: Location = window.location) {
    return !!loc.pathname.match('/handpicked/');
  },

  isOnCompleteShopDetailsPage(loc: Location = window.location) {
    return !!loc.pathname.match(URLHelpers.onboardingCompleteShopDetailsPath);
  },

  isOnBegin3DS1Page(loc: Location = window.location) {
    return !!loc.pathname.match(URLHelpers.onboardingBegin3DS1);
  },

  filteringShowOnlySold(loc: Location = window.location) {
    return !!extractQueryParams(loc).show_only_sold;
  },

  queryParams(loc: Location = window.location) {
    return extractQueryParams(loc);
  },

  pathname(loc: Location = window.location) {
    return loc.pathname;
  },

  reverbSearchParam() {
    const {
      query,
    } = extractQueryParams(window.location);

    const value = query && (query as string).split('=')[1] || '';
    return decodeURIComponent(value);
  },

  queryWithPage(queryObj, pageNum) {
    const newQuery = { ...queryObj };

    if (pageNum === 1) {
      delete newQuery.page;
    } else {
      // important to stringify page # to ensure consistency with router params
      // otherwise, browser history will not work
      newQuery.page = pageNum.toString();
    }
    return newQuery;
  },

  currentPageWithParams(params: object, loc: Location = window.location) {
    const currentQueryParams = extractQueryParams(loc);

    const newParams = qs.stringify(Object.assign(currentQueryParams, params));
    const searchString = newParams.length > 0 ? `?${newParams}` : '';

    return `${loc.origin}${loc.pathname}${searchString}`;
  },

  extractPathParams(paths: string[], loc: Location): GridParams {
    const nullMatch = { paramNames: null, paramValues: null };
    const { paramNames, paramValues } = paths.reduce(
      (extracted, path) => {
        if (extracted.paramValues && extracted.paramValues.length) { return extracted; }
        return matchPattern(path, loc.pathname) || nullMatch;
      },
      nullMatch,
    );

    const params = {};
    (paramNames || []).forEach((name, idx) => {
      params[name] = paramValues[idx];
    });

    const query = extractQueryParams(loc);
    const parsed = qs.parse(query as any); // TODO: qs types are broken
    Object.assign(params, parsed);
    return params;
  },

  urlWithoutOrigin(loc: Location = window.location): string {
    return loc.href.replace(loc.origin, '');
  },
};

export default locationUtil;
