import PropTypes from 'prop-types';
import React from 'react';
import ProductSearchForm from '../../../components/product_search_form/product_search_form';
import APIRequest from '../../../components/api_request';
import FormGroup from '@reverbdotcom/commons/src/components/form_group';
import TextareaAutosize from 'react-textarea-autosize';
import OfferItemView from './offer_item_view';
import NegotiationSubtotal from './negotiation_subtotal';
import BuyerDetails from './buyer_details';
import OfferItems from './offer_items';
import I18n from 'i18n-js';

class EmailOfferForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      offerItems: new OfferItems([], this.props.listingCurrency),
      productSearchInputValue: '',
      buyer: {},
      message: '',
      isLoading: false,
      errorMessage: '',
    };

    this.addOfferItem = this.addOfferItem.bind(this);
    this.removeOfferItem = this.removeOfferItem.bind(this);
    this.setOfferItemPrice = this.setOfferItemPrice.bind(this);
    this.setOfferItemShippingPrice = this.setOfferItemShippingPrice.bind(this);
    this.setBuyerDetails = this.setBuyerDetails.bind(this);
    this.setMessage = this.setMessage.bind(this);
    this.handleProductSearchFormChange = this.handleProductSearchFormChange.bind(this);
    this.createEmailOffer = this.createEmailOffer.bind(this);
    this.handleCreateEmailOfferResponse = this.handleCreateEmailOfferResponse.bind(this);
    this.handleCreateEmailOfferError = this.handleCreateEmailOfferError.bind(this);
  }

  setMessage(e) {
    this.setState({ message: e.target.value });
  }

  setBuyerDetails(buyerDetails) {
    const buyer = { ...this.state.buyer, ...buyerDetails };
    this.setState({ buyer });
  }

  setOfferItemPrice(args) {
    const offerItems = this.state.offerItems.clone;
    offerItems.setPrice(args);
    this.setState({ offerItems });
  }

  setOfferItemShippingPrice(args) {
    const offerItems = this.state.offerItems.clone;
    offerItems.setShippingPrice(args);
    this.setState({ offerItems });
  }

  handleCreateEmailOfferResponse(response) {
    if (response.success) {
      this.window.location.href = this.props.offerCreatedPath;
    } else {
      this.setState({
        isLoading: false,
        errorMessage: response.error_message,
      });
    }
  }

  handleCreateEmailOfferError() {
    this.setState({
      isLoading: false,
      errorMessage: I18n.t('emailOffers.offerCreationFailed'),
    });
  }

  get window() {
    return this.props.window || window;
  }

  get emailOfferParams() {
    return JSON.stringify({
      buyer: {
        first_name: this.state.buyer.firstName,
        last_name: this.state.buyer.lastName,
        email: this.state.buyer.email,
        accepts_email_marketing: this.state.buyer.acceptsEmailMarketing,
      },
      offer_items: this.state.offerItems.offerItems.map(offerItem => ({
        listing_id: offerItem.listingId,
        price: offerItem.price,
        shipping_price: offerItem.shippingPrice,
      })),
      message: this.state.message,
      expected_subtotal: this.state.offerItems.subtotal.toString(),
    });
  }

  createEmailOffer(e) {
    e.preventDefault();

    this.setState({ isLoading: true });

    APIRequest.post(
      this.props.emailOffersPath,
      this.emailOfferParams,
      { headers: { 'Content-Type': 'application/json' } },
    ).then(this.handleCreateEmailOfferResponse).fail(this.handleCreateEmailOfferError);
  }

  removeOfferItem(listingId) {
    const offerItems = this.state.offerItems.clone;
    offerItems.remove(listingId);
    this.setState({ offerItems });
  }

  addOfferItem(listing) {
    const offerItems = this.state.offerItems.clone;
    offerItems.add(listing);
    this.setState({
      offerItems,
      productSearchInputValue: '',
    });
  }

  handleProductSearchFormChange(e) {
    this.setState({ productSearchInputValue: e.target.value });
  }

  get emailOfferTitle() {
    if (!this.state.buyer.firstName || !this.state.buyer.lastName) {
      return I18n.t('emailOffers.buyerDetails.emailOffer');
    }

    return I18n.t('emailOffers.buyerDetails.emailOfferWithBuyerName', {
      buyerName: `${this.state.buyer.firstName} ${this.state.buyer.lastName}`,
    });
  }

  renderProductSearchForm() {
    return (
      <ProductSearchForm
        productSelected={this.addOfferItem}
        currentListingIds={this.state.offerItems.listingIds}
        onChange={this.handleProductSearchFormChange}
        inputValue={this.state.productSearchInputValue}
        includeLP
      />
    );
  }

  renderOfferItems() {
    return this.state.offerItems.offerItems.map(offerItem => (
      <div key={offerItem.listingId} className="email-offer__item">
        <OfferItemView
          offerItem={offerItem}
          onRemove={this.removeOfferItem}
          setPrice={this.setOfferItemPrice}
          setShippingPrice={this.setOfferItemShippingPrice}
        />
      </div>
    ));
  }

  renderTextarea() {
    return (
      <TextareaAutosize
        minRows={3}
        name="negotiation[message]"
        value={this.state.message}
        onChange={this.setMessage}
        id="negotiation[message]"
      />
    );
  }

  renderAlert() {
    if (this.state.errorMessage) {
      return <div className="alert-box alert-box--red scaling-mb-4">{this.state.errorMessage}</div>;
    }

    return false;
  }

  render() {
    return (
      <form>
        <div className="g-container">
          <div className="g-col-8 g-col-mobile-12">
            {this.renderAlert()}

            <div className="site-module">
              <div className="site-module__header">
                <h3 className="site-module__title">{I18n.t('emailOffers.buyerDetails.title')}</h3>
              </div>
              <div className="site-module__content">
                <BuyerDetails
                  buyer={this.state.buyer}
                  onChange={this.setBuyerDetails}
                />
                <div className="email-offer">
                  <h3 className="email-offer__title">
                    {this.emailOfferTitle}
                  </h3>
                  <div className="email-offer__items">
                    {this.renderOfferItems()}
                  </div>
                  <div className="email-offer__search">
                    <FormGroup label={I18n.t('emailOffers.productSearchForm.addListings')}>
                      {this.renderProductSearchForm()}
                    </FormGroup>
                  </div>
                  <div className="email-offer__totals">
                    <NegotiationSubtotal offerItems={this.state.offerItems} />
                  </div>
                  <div className="email-offer__message">
                    <FormGroup
                      label={I18n.t('emailOffers.writeAMessage')}
                      inputName="negotiation[message]"
                      tagOptional
                    >
                      {this.renderTextarea()}
                    </FormGroup>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-4">
              <button
                onClick={this.createEmailOffer}
                className="button button--primary"
                disabled={this.state.isLoading}
              >
                {I18n.t('emailOffers.sendOffer')}
              </button>
            </div>
          </div>
          <div className="g-col-4 c-col-mobile-12">
            <h3 className="heading-3 mb-1">
              {I18n.t('emailOffers.explanation.title')}
            </h3>
            <p className="mb-0">
              {I18n.t('emailOffers.explanation.summary')}
            </p>
          </div>
        </div>
      </form>
    );
  }
}

EmailOfferForm.propTypes = {
  emailOffersPath: PropTypes.string.isRequired,
  offerCreatedPath: PropTypes.string.isRequired,
  listingCurrency: PropTypes.string.isRequired,
  initialListingId: PropTypes.string,
  window: PropTypes.object,
};

export default EmailOfferForm;
