import React from 'react';
import { includes } from 'lodash';
import NumericInput from './numeric_input';

const UNSUPPORTED_FA_ICONS = ['AUD', 'CAD'];

class PriceFieldWithCurrency extends React.Component {
  renderSymbol = () => {
    const currencyForIcon = includes(UNSUPPORTED_FA_ICONS, this.props.currency) ? 'usd' : this.props.currency;
    if (!currencyForIcon) { return null; }

    const iconClasses = `fa fa-${currencyForIcon.toLowerCase()}`;

    return (
      <span
        className="input-group__text"
        data-price-with-currency-symbol
      >
        <span className={iconClasses} />
      </span>
    );
  };

  // @ts-ignore
  input = () => { this.refs.input.refs.input; }; // eslint-disable-line

  render() {
    return (
      <div className="input-group">
        {this.renderSymbol()}
        <NumericInput
          {...this.props}
          className="input-group__input"
          ref="input"
        />
      </div>
    );
  }
}

export default PriceFieldWithCurrency;
