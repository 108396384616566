import React, { useState } from 'react';
import { useQuery } from '@reverbdotcom/commons/src/useQuery';
import { RCLoadingBars } from '@reverbdotcom/cadence/components';
import { I18N as Translate } from '@reverbdotcom/commons/src/components/translate';
import EditableCreditCard from './EditableCreditCard';
import { gql } from '@reverbdotcom/commons/src/gql';

const CREDIT_CARDS_ON_FILE_QUERY = gql(`
  query Core_MyAccount_CreditCardsOnFile {
    countries {
      countries {
        _id
        countryCode
        name
        subregionRequired
        subregions {
          _id
          code
          name
        }
      }
    }
    me {
      _id
      uuid
      creditCards {
        id
        last4
        brand {
          type
          name
        }
        display {
          summary
          expirationDate
        }
        address {
          _id
          countryCode
          name
          streetAddress
          extendedAddress
          locality
          region
          postalCode
          phone
        }
        expirationMonth
        expirationYear
        cardholderName
        primaryBilling
        primaryForCheckout
      }
    }
  }
`);

export function CreditCardsOnFileList() {
  const [ editableCardId, setEditableCardId ] = useState(null);
  const { data, loading } = useQuery(CREDIT_CARDS_ON_FILE_QUERY, { ssr: false });

  if (loading) {
    return (
      <div className="d-flex fx-justify-center">
        <RCLoadingBars />
      </div>
    );
  }

  if (!(data?.me?.creditCards?.length > 0)) {
    return (
      <div className="align-center">
        <Translate
          className="mtb-2 opacity-60"
          tag="p"
          text="js.creditCards.creditCardList.noCardsOnFile"
        />
      </div>
    );
  }

  return (
    <ul>
      {data.me.creditCards.map((creditCard) => (
        <EditableCreditCard
          key={creditCard.id}
          creditCard={creditCard}
          countriesForSelect={data.countries?.countries || []}
          isEditing={creditCard.id === editableCardId}
          setEditableCardId={setEditableCardId}
        />
      ))}
    </ul>
  );
}

export default CreditCardsOnFileList;
