/* eslint max-len: ["error", 200] */

import 'isomorphic-fetch';
import './components/translations';
import '@reverbdotcom/discovery-ui/src/components/elog_init';
import User from './components/user';
import AppInitializer from './components/app_initializer';
import './components/discovery';

import PollConfirmation from './components/bundled_checkout/poll_confirmation';
import PasswordOrGuestCheckoutSelection from './components/bundled_checkout/password_or_guest_checkout_selection';
import CheckoutEmailInput from './components/bundled_checkout/checkout_email_input';
import CheckoutBuyerPaymentMethodSelectorWrapper from './components/bundled_checkout/checkout_buyer_payment_method_selector_wrapper';
import PasswordInput from './components/shared/password_input';
import AsyncLoader from './components/async_loader';

import ImportsProgressBar from '@reverbdotcom/discovery-ui/src/dashboard/imports/progress_bar';
import EmailOfferForm from '@reverbdotcom/discovery-ui/src/dashboard/selling/email_offers/email_offer_form';
import OrderNotes from '@reverbdotcom/discovery-ui/src/dashboard/selling/order_notes/order_notes';
import AcceptedPaymentMethodsForm from '@reverbdotcom/discovery-ui/src/dashboard/selling/shop_policies/accepted_payment_methods_form';

import EmailSignup from './components/email_signup/email_signup';
import Forms from './components/forms';
import GiftCardOptions from './components/gift_cards/gift_card_options';

import Loading from './components/shared/loading';
import ManualShippingInfoForm from './components/manual_shipping_info_form';
import TrackOrderForm from './components/track_order_form';
import PageSearchBar from './components/shared/page_search_bar';
import PreviousOrders from './components/previous_orders';

import ProductReviews from '@reverbdotcom/discovery-ui/src/product_reviews/product_reviews';
import ShippingProgressBar from './components/shared/shipping_progress_bar';

import AmountFields from './components/bank_transfer_requests/amount_fields';

import ShareInput from './components/shared/share_input';
import Fotorama from './components/shared/fotorama';
import GetPaidLater from '@reverbdotcom/discovery-ui/src/shop/get_paid_later';
import { GetPaidLaterInfo } from '@reverbdotcom/discovery-ui/src/shared/get_paid_later_info';
import ShopPaymentMethodSelector from '@reverbdotcom/discovery-ui/src/shop/payment_method_selector';
import ShopPaypalProfile from '@reverbdotcom/discovery-ui/src/shop/paypal_profile';
import ShopCurrencySelect from '@reverbdotcom/discovery-ui/src/shop/currency_select';
import StreetAddressInput from './components/bundled_checkout/street_address_input';
import ListingShippingRateRecommendation from '@reverbdotcom/discovery-ui/src/components/sell/listing_shipping_rate_recommendation';
import RelatedContent from '@reverbdotcom/discovery-ui/src/product_show_page/related_content';
import SimilarListings from '@reverbdotcom/discovery-ui/src/product_show_page/similar_listings';
import CartSignals from '@reverbdotcom/discovery-ui/src/components/cart_signals';
import OtherBuyersWithListingInCart from './components/listings/other_buyers_with_listing_in_cart';
import { CollectionsPromoBanner } from '@reverbdotcom/discovery-ui/src/components/collections_promo_banner';

import StandaloneAjaxAddToCartButton from './components/listings/cart/standalone_ajax_add_to_cart_button';
import WatchButton from './components/listings/watch_button';

import Embeds from './components/conversations/embeds';
import MobileAppAds from './components/bundled_checkout/mobile_app_ads';

import NavDropdownStore from './components/header/dropdown_store';

import RelatedArticles from './components/articles/related_articles';

import TooltipWrapper from './components/tooltip_wrapper';
import ReadMessagesToggle from './components/read_messages_toggle';

import CheckoutHeader from '@reverbdotcom/discovery-ui/src/components/checkout_header';
import CheckoutHeaderWithTracking from '@reverbdotcom/discovery-ui/src/components/CheckoutHeaderWithTracking';
import LocationBasedShippingDisplay from '@reverbdotcom/commons/src/components/location_based_shipping_display';

import AddressFormFieldsContainer from '@reverbdotcom/discovery-ui/src/components/address_form_fields_container';

import Renderer from './components/react_renderer';

import ThirdPartyAuthButtons from '@reverbdotcom/commons/src/components/third_party_auth_buttons';
import ClaimOrderBanner from '@reverbdotcom/commons/src/components/claim_order_banner';

import GiftCardsUnderConstruction from '@reverbdotcom/discovery-ui/src/components/gift_cards_under_construction';

import TaxInformationBanner from '@reverbdotcom/discovery-ui/src/components/tax_profiles/tax_information_banner';

import TaxProfileVerificationFailed from '@reverbdotcom/discovery-ui/src/components/tax_profiles/tax_profile_verification_failed';

import StatementPaymentNeededBanner from '@reverbdotcom/discovery-ui/src/components/pay_statement/statement_payment_needed_banner';
import PayStatementModal from '@reverbdotcom/discovery-ui/src/components/pay_statement/pay_statement_modal';
import StatementPaymentSuccessBanner from '@reverbdotcom/discovery-ui/src/components/pay_statement/statement_payment_success_banner';
import InternationalTaxIdBanner from '@reverbdotcom/discovery-ui/src/components/international_tax_id_banner';
import AccountingReportForm from '@reverbdotcom/discovery-ui/src/components/accounting_reports/accounting_report_form';
import PostPurchaseShopNudge from '@reverbdotcom/discovery-ui/src/components/favorites/post_purchase_shop_nudge';

import InternationalTaxProfileContainer from '@reverbdotcom/discovery-ui/src/components/international_tax_profiles/international_tax_profile_container';
import InternationalTaxProfileBanner from '@reverbdotcom/discovery-ui/src/components/international_tax_profiles/international_tax_profile_banner';

import ListingSellerLockedModeAlert from '@reverbdotcom/discovery-ui/src/product_show_page/listing_seller_locked_mode_alert';
import DigitalListingDownloadButton from '@reverbdotcom/discovery-ui/src/digital_listings/standalone_download_button';

// legacy components which can't be deferred
import { UI } from './components/legacy/ui';
import './components/legacy/init/hoverIntent';
import './components/legacy/readmore';
import './components/legacy/init/powertip';

// Some pages initialize this in script tags (e.g. /artist)
import './components/legacy/init/owl-carousel';
import YoutubeAPI from './components/legacy/youtube_api'; // /videos

AppInitializer.init();

// Async load vintage JS
import(/* webpackChunkName: 'legacy' */ './components/legacy');

window.User = User;

window.Reverb = {
  ...window.Reverb,
  ListingShippingRateRecommendation,
  AsyncLoader,
  LocationBasedShippingDisplay,
  CartSignals,
  DigitalListingDownloadButton,
  EmailSignup,
  Forms,
  Fotorama,
  GetPaidLater,
  GetPaidLaterInfo,
  Loading,
  ManualShippingInfoForm,
  TrackOrderForm,
  MobileAppAds,
  CollectionsPromoBanner,
  NavDropdownStore,
  OtherBuyersWithListingInCart,
  PageSearchBar,
  PreviousOrders,
  ProductReviews,
  ShippingProgressBar,
  ReadMessagesToggle,
  Renderer,
  ShareInput,
  ShopCurrencySelect,
  ShopPaymentMethodSelector,
  ShopPaypalProfile,
  StreetAddressInput,
  TooltipWrapper,
  UI,
  YoutubeAPI,
  CheckoutHeader,
  CheckoutHeaderWithTracking,
  AddressFormFieldsContainer,
  ThirdPartyAuthButtons,
  GiftCardsUnderConstruction,
  TaxInformationBanner,
  TaxProfileVerificationFailed,
  StatementPaymentNeededBanner,
  PayStatementModal,
  StatementPaymentSuccessBanner,
  InternationalTaxIdBanner,
  AccountingReportForm,
  InternationalTaxProfileContainer,
  InternationalTaxProfileBanner,
  ListingSellerLockedModeAlert,
};

window.Reverb.ReactUJS = { init: Renderer.render }; // TODO remove after frontend updated to use Renderer
window.Reverb.Articles = { RelatedArticles };

window.Reverb.BankTransferRequests = {
  AmountFields,
};

window.Reverb.BundledCheckout = {
  ...window.Reverb.BundledCheckout,
  PollConfirmation,
  PasswordOrGuestCheckoutSelection,
  CheckoutEmailInput,
  PasswordInput,
  CheckoutBuyerPaymentMethodSelectorWrapper,
  ClaimOrderBanner,
  PostPurchaseShopNudge,
};

window.Reverb.Conversations = window.Reverb.Conversations || {};
window.Reverb.Conversations.Embeds = Embeds;

window.Reverb.Dashboard = {
  AcceptedPaymentMethodsForm,
  Imports: {
    ProgressBar: ImportsProgressBar,
  },
  Selling: {
    EmailOffers: { EmailOfferForm },
    OrderNotes,
  },
};

window.Reverb.GiftCard = {
  GiftCardOptions,
};

window.Reverb.Listings = {
  StandaloneAjaxAddToCartButton,
  WatchButton,
  OtherBuyersWithListingInCart,
};

window.Reverb.ProductShowPage = {
  RelatedContent,
  SimilarListings,
};
