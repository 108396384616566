import React from 'react';
import I18n from 'i18n-js';
import moment from 'moment';

import { RCIconButton, RCFlex } from '@reverbdotcom/cadence/components';
import { PencilIcon } from '@reverbdotcom/cadence/icons/react';

import { Listing } from '@reverbdotcom/commons/src/gql/graphql';

import QuickInventoryEditInput from '../listings/QuickInventoryEditInput';

const DAYS_IN_MONTH = 30;
const DAYS_IN_YEAR = 365;
interface IExternalProps {
  listing: Listing;
}

export function listingAge(publishedAtInSeconds: number) {
  const publishedAtInMilliseconds = publishedAtInSeconds * 1000;

  const today = moment().startOf('day');
  const publishedDate = moment(publishedAtInMilliseconds);

  const daysAgo = today.diff(publishedDate, 'days');

  if (daysAgo < 1) {
    return I18n.t('discovery.listingsManagement.sellerListingCard.daysAgo.lessThanOne');
  } else if (daysAgo < DAYS_IN_MONTH) {
    return I18n.t('discovery.listingsManagement.sellerListingCard.daysAgo', {
      count: daysAgo,
    });
  } else if (daysAgo < DAYS_IN_YEAR) {
    const monthsAgo = today.diff(publishedDate, 'months');

    return I18n.t('discovery.listingsManagement.sellerListingCard.monthsAgo', {
      count: monthsAgo,
    });
  } else {
    const yearsAgo = today.diff(publishedDate, 'years');

    return I18n.t('discovery.listingsManagement.sellerListingCard.yearsAgo', {
      count: yearsAgo,
    });
  }
}


export default function SellerListingCardDetails({ listing }: IExternalProps) {
  const [editingInventory, setEditingInventory] = React.useState(false);

  return (
    <>
      <ul className="weight-semibold" style={{ fontSize: 'var(--rc-font-size-350)' }}>
        {!!listing.sku && (
          <li>
            {I18n.t('discovery.listingsManagement.sellerListingCard.sku', {
              sku: listing.sku,
            })}
          </li>
        )}

        {!!listing.condition?.displayName && (
          <li>
            {I18n.t('discovery.listingsManagement.sellerListingCard.condition', {
              condition: listing.condition.displayName,
            })}
          </li>
        )}

        {!!listing.publishedAt?.seconds && (
          <li>
            {listingAge(listing.publishedAt.seconds)}
          </li>
        )}

        {listing.hasInventory && !editingInventory && (
          <li>
            <RCFlex align="center">
              {I18n.t('discovery.listingsManagement.sellerListingCard.inStock', {
                inventory: listing.inventory,
              })}

              <RCIconButton
                size="mini"
                svgComponent={PencilIcon}
                onClick={() => { setEditingInventory(!editingInventory); }}
                label={I18n.t('discovery.listingsManagement.sellerListingCard.editInventory')}
              />
            </RCFlex>
          </li>
        )}
      </ul>

      {listing.hasInventory && (
        <QuickInventoryEditInput
          listing={listing}
          inEditMode={editingInventory}
          onSuccess={() => { setEditingInventory(false); }}
        />
      )}
    </>
  );
}
