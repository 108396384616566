import React from 'react';
import CoreLink from '@reverbdotcom/commons/src/components/core_link';
import URLHelpers from './shared/url_helpers';
import I18n from 'i18n-js';

export function CollectionsPromoBanner() {
  return (
    <div className="collections-promo-banner">
      <div className="collections-promo-banner__img_container">
        <img className="collections-promo-banner__img" alt="music instrument collage" src="https://res.cloudinary.com/reverb-marketing/image/upload/v1642532999/20220124_collections_v2_lifestyle-hex_01.png" />
      </div>
      <div className="collections-promo-banner__text_container">
        <h3 className="collections-promo-banner__header">
          {I18n.t('discovery.collectionPromoBanner.title')}
        </h3>
        <h4 className="collections-promo-banner__body">
          <CoreLink className="collections-promo-banner__link" to={URLHelpers.gearCollectionsPath()}>
            {I18n.t('discovery.collectionPromoBanner.collection')}
          </CoreLink>
          {I18n.t('discovery.collectionPromoBanner.description')}
        </h4>
      </div>
    </div>
  );
}
