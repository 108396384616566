import { ChildProps } from '@apollo/client/react/hoc';
import React from 'react';
import I18n from 'i18n-js';
import moment from 'moment';

import { withGraphql } from '@reverbdotcom/commons/src/with_graphql';
import { SearchPriceGuideToolTransactionTable } from '@reverbdotcom/commons/src/gql/graphql';
import { PRICE_GUIDE_TOOL_CONDITIONS } from '@reverbdotcom/commons/src/condition_helpers';

import { transactionsTableQuery } from '../../../../price_guide_tool/csp_module/shared/csp_transaction_table_container';
import {
  SELLER_COUNTRY_CODES,
  PAID_TRANSACTION_STATUSES,
} from '../../../../price_guide_tool/show/price_guide_tool_transaction_graph_container';
import PriceRecordsTable from './PriceRecordsTable';
import PriceRecordsEmptyState from './PriceRecordsEmptyState';

const DEFAULT_OFFSET = 0;
export const PAGE_SIZE = 10;

const CREATED_AFTER_FILTER = moment().subtract(1, 'years').startOf('day').format();
interface IExternalProps {
  canonicalProductIds: string[];
}

type IProps = ChildProps<IExternalProps, SearchPriceGuideToolTransactionTable.Query>;

interface IQueryVariableArgs {
  canonicalProductIds: string[];
  offset?: number;
}

export function queryVariables({
  canonicalProductIds,
  offset = DEFAULT_OFFSET,
}: IQueryVariableArgs) {
  return {
    canonicalProductIds,
    conditionSlugs: PRICE_GUIDE_TOOL_CONDITIONS,
    sellerCountries: SELLER_COUNTRY_CODES,
    actionableStatuses: PAID_TRANSACTION_STATUSES,
    createdAfterDate: CREATED_AFTER_FILTER,
    limit: PAGE_SIZE,
    offset,
  };
}

export function filterPriceRecords(priceRecords) {
  return priceRecords
    .filter(priceRecord => !!priceRecord.createdAt)
    .map(priceRecord => ({
      timestamp: priceRecord.createdAt,
      conditionDisplayName: priceRecord.condition.displayName,
      priceDisplay: priceRecord.amountProduct.display,
      id: priceRecord._id,
    }));
}

export function TransactionsTableContainer({
  canonicalProductIds,
  data,
}: IProps) {
  const {
    loading,
    refetch,
    priceRecordsSearch = {},
  } = data ?? {};

  const {
    priceRecords = [],
    total = 0,
    offset = 0,
  } = priceRecordsSearch;

  const filteredPriceRecords = filterPriceRecords(priceRecords);

  const showEmptyState = !loading && total === 0;
  const currentPage = offset / PAGE_SIZE;

  const totalPages = Math.floor(total / PAGE_SIZE);

  function onPageChange(pageChange: number) {
    return () => {
      const newPage = currentPage + pageChange;
      const newOffset = newPage * PAGE_SIZE;

      refetch({
        canonicalProductIds,
        offset: newOffset,
      });
    };
  }

  if (showEmptyState) return (
    <PriceRecordsEmptyState
      text={I18n.t('discovery.sellForm.pricingSection.pricingGuidance.noSoldTransactions')}
    />
  );

  return (
    <PriceRecordsTable
      priceRecords={filteredPriceRecords}
      onPageChange={onPageChange}
      currentPage={currentPage}
      totalPages={totalPages}
      dateHeader={I18n.t('discovery.sellForm.pricingSection.pricingTables.recentlySoldListings.dateHeader')}
    />
  );
}

export const withQuery = withGraphql<IProps, SearchPriceGuideToolTransactionTable.Query>(
  transactionsTableQuery,
  {
    skip: ({ canonicalProductIds }) => !canonicalProductIds?.length,
    options: ({
      canonicalProductIds,
    }) => {
      return {
        ssr: false,
        fetchPolicy: 'no-cache',
        errorPolicy: 'ignore',
        variables: queryVariables({
          canonicalProductIds,
        }),
      };
    },
  },
);

export default withQuery(TransactionsTableContainer);
