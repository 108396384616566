import React, { useState } from 'react';
import FormSection from '@reverbdotcom/commons/src/components/form_section';
import { RCAlertBox, RCButton, RCSelect } from '@reverbdotcom/cadence/components';
import { I18N as Translate } from '@reverbdotcom/commons/src/components/translate';
import I18n from 'i18n-js';
import { gql } from '@reverbdotcom/commons/src/gql';
import { useMutation } from '@reverbdotcom/commons/src/useMutation';
import { CoreMyShopExportDac7OrderData } from '@reverbdotcom/commons/src/gql/graphql';

export const DAC7_ANNUAL_REPORT_DOWNLOAD_URL = '/my/selling/exports/reports/marketplace_tax_annual_report?year=';

const EXPORT_STATUS_SUCCESS = 'export_status_success';
const EXPORT_STATUS_FAILURE = 'export_status_failure';
const EXPORT_STATUS_NONE = 'export_status_none';

interface Props {
  availableReportYears: number[];
}

const EXPORT_DAC7_ORDER_CSV_MUTATION = gql(`
  mutation Core_MyShopExportDac7OrderData($input: Input_core_apimessages_MyShopExportDac7OrderDataRequest) {
    myShopExportDac7OrderData(input: $input) {
      success
    }
  }
`);

export function MarketplaceTaxReports({ availableReportYears }: Props) {
  const [ selectedYear, setSelectedYear ] = useState('');
  const [ exportStatus, setExportStatus ] = useState(EXPORT_STATUS_NONE);

  const [ mutate, { loading } ] = useMutation<
    CoreMyShopExportDac7OrderData.Mutation,
    CoreMyShopExportDac7OrderData.Variables
  >(
    EXPORT_DAC7_ORDER_CSV_MUTATION,
  );

  if (availableReportYears.length === 0) {
    return null;
  }

  const changeYear = (year) => {
    setExportStatus(EXPORT_STATUS_NONE);
    setSelectedYear(year);
  };

  const exportOrderCsv = async () => {
    setExportStatus(EXPORT_STATUS_NONE);
    const result = await mutate({
      variables: {
        input: {
          year: selectedYear,
        },
      },
    });

    if (result?.data?.myShopExportDac7OrderData?.success) {
      setExportStatus(EXPORT_STATUS_SUCCESS);
    } else {
      setExportStatus(EXPORT_STATUS_FAILURE);
    }
  };

  const getAnnualReport = () => {
    setExportStatus(EXPORT_STATUS_NONE);
    window.open(
      `${DAC7_ANNUAL_REPORT_DOWNLOAD_URL}${selectedYear}`,
      '_blank',
    );
  };

  return (
    <FormSection
      title={<Translate text="discovery.internationalTaxProfile.marketplaceTaxReportDownloads.title" />}
      description={<Translate text="discovery.internationalTaxProfile.marketplaceTaxReportDownloads.description" />}
    >
      <div className="d-flex fx-align-center">
        <RCSelect
          addEmptyOption
          emptyOptionLabel={I18n.t('discovery.internationalTaxProfile.marketplaceTaxReportDownloads.selectYear')}
          value={selectedYear}
          onChange={(event) => changeYear(event.target.value)}
          label={''}
          id="selectedYear"
        >
          {availableReportYears.map(year => (
            <RCSelect.Option
              key={year}
              label={String(year)}
              value={String(year)}
            />
          ))}
        </RCSelect>
        <div className="mb-5 ml-3">
          <RCButton
            size="small"
            onClick={getAnnualReport}
            disabled={selectedYear === ''}
          >
            <Translate text="discovery.internationalTaxProfile.marketplaceTaxReportDownloads.downloadPdf" />
          </RCButton>
        </div>
        <div className="mb-5 ml-3">
          <RCButton
            size="small"
            onClick={exportOrderCsv}
            disabled={selectedYear === '' || loading}
          >
            <Translate text="discovery.internationalTaxProfile.marketplaceTaxReportDownloads.exportOrderCsv" />
          </RCButton>
        </div>
      </div>
      { exportStatus === EXPORT_STATUS_SUCCESS && (
        <RCAlertBox
          type="success"
          small
        >
          <Translate text="discovery.internationalTaxProfile.marketplaceTaxReportDownloads.exportOrderCsvSuccess" />
        </RCAlertBox>
      )}
      { exportStatus === EXPORT_STATUS_FAILURE && (
        <RCAlertBox
          type="error"
          small
        >
          <Translate text="discovery.internationalTaxProfile.marketplaceTaxReportDownloads.exportOrderCsvFailure" />
        </RCAlertBox>
      )}
    </FormSection>
  );
}

export default MarketplaceTaxReports;
