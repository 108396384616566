import React from 'react';
import classNames from 'classnames';
import I18n from 'i18n-js';
import Swipeable from 'react-swipeable';
import ReactModal from 'react-modal';
import { withRouter, WithRouterProps } from 'react-router';

import bind from '../bind';
import isNode from '../is-node';
import LightboxImageItem from './lightbox_image_item';
import { MParticleEventName, trackEvent } from '@reverbdotcom/commons/src/elog/mparticle_tracker';
import { ThumbnailType } from '../elog/mparticle_types';
import { isItemPage } from '@reverbdotcom/commons/src/url_helpers';
import { preferDarkTheme } from '@reverbdotcom/cadence/components/helpers/themeOverrides';

const LIGHTBOX_OUTLET = '.modal-outlet__lightbox';

interface ExternalProps {
  thumbURLs?: string[];
  imageURLs?: string[];
  imageAlts?: string[];
  fullsizeURLs: string[];
  withControls?: boolean;
  hideMobileControls?: boolean;
  id?: string;
  captionText?: string;
  landscapeIfVideo?: boolean;
  showAltsAsCaptions?: boolean;
  persistKeystrokes?: boolean;
  useAspectOverride?: boolean;
  disableFullScreen?: boolean;
  largerThumbs?: boolean;

  mainImageClickCallback?: () => void;
  thumbClickCallback?: () => void;

  // hides thumbnails but shows them in the modal itself
  hideThumbs?: boolean;

  // hides primary image container
  hidePrimary?: boolean;

  // Eager loads primary image. This is important for Largest Contentful Paint (LCP) performance.
  // Generally, you should elect to lazy load images. However, the largest image on the page should
  // be eager loaded, especially when it is above the fold.
  eagerLoadPrimaryImage?: boolean;
}

type IProps = ExternalProps & WithRouterProps;

interface IState {
  activeImgIndex: number;
  aspectOverride?: number;
  displayVideo: boolean;
  isOpen: boolean;
  imageLoading: boolean;
  primaryImageLoaded: boolean;
  largePrimaryImageLoaded: boolean;
  transitioning: boolean;
}

const LEFT_ARROW_CODE = 37;
const RIGHT_ARROW_CODE = 39;

export class LightboxImage extends React.Component<IProps, IState> {
  constructor(props) {
    super(props);

    // Element to add `aria-hidden` to when modal is open. This should really
    // be a child of body, but our layout doesn't have a single container element.
    // https://reactcommunity.org/react-modal/examples/set_app_element.html
    ReactModal.setAppElement('body');
  }

  state = {
    activeImgIndex: 0,
    aspectOverride: null,
    isOpen: false,
    imageLoading: false,
    displayVideo: false,
    primaryImageLoaded: false,
    largePrimaryImageLoaded: false,
    transitioning: false,
  };

  componentDidMount() {
    if (this.props.persistKeystrokes) {
      window.addEventListener('keydown', this.handleKeyDown);
    }
  }

  get lightboxModalElem() {
    return document.getElementsByClassName('lightbox-modal')[0];
  }

  get isModalOpen() {
    return this.state.isOpen && this.lightboxModalElem;
  }

  get isScrollThumbnailRowEnabled() {
    return isItemPage(this.props.location);
  }

  scrollThumbnailRow() {
    const parentContainer = this.isModalOpen ? this.lightboxModalElem : document.getElementsByClassName('lightbox-image')[0];

    const activeThumbElem = parentContainer.getElementsByClassName('lightbox-image__thumb')[this.state.activeImgIndex];
    const nextThumbElem = activeThumbElem.nextElementSibling;
    const prevThumbElem = activeThumbElem.previousElementSibling;
    const parentRowElem = activeThumbElem.parentElement;

    const activeThumbRect = activeThumbElem.getBoundingClientRect();
    const parentRowRect = parentRowElem.getBoundingClientRect();

    const activeThumbElemY = activeThumbRect.x + activeThumbRect.width;
    const parentRowElemY = parentRowRect.x + parentRowRect.width;

    const nextThumbOverflowingRight = (thumb) => {
      return ((thumb.getBoundingClientRect().x + thumb.clientWidth - parentRowElemY) / thumb.clientWidth) > 0.15;
    };

    const prevThumbOverflowingLeft = (thumb) => {
      return ((parentRowRect.x - thumb.getBoundingClientRect().x) / thumb.clientWidth) > 0.15;
    };

    const activeThumbIsOverflowingRight = activeThumbElemY > parentRowElemY;
    const nextThumbIsOverflowingRight = nextThumbElem && nextThumbOverflowingRight(nextThumbElem);

    const activeThumbIsOverflowingLeft = activeThumbRect.x < parentRowRect.x;
    const prevThumbIsOverflowingLeft = prevThumbElem && prevThumbOverflowingLeft(prevThumbElem);

    const showHalfOfNextThumb = activeThumbIsOverflowingRight || nextThumbIsOverflowingRight;
    const showHalfOfPrevThumb = activeThumbIsOverflowingLeft || prevThumbIsOverflowingLeft;

    const activeThumbIsFirstThumb = this.state.activeImgIndex === 0;
    const activeThumbIsLastThumb = this.state.activeImgIndex === this.props.thumbURLs.length - 1;

    const calculateNextThumbPosition = () => {
      if (nextThumbElem.nextElementSibling) {
        return nextThumbElem.nextElementSibling.getBoundingClientRect().x + (nextThumbElem.nextElementSibling.clientWidth / 2);
      } else {
        return nextThumbElem.getBoundingClientRect().x + nextThumbElem.clientWidth;
      }
    };

    const calculatePreviousThumbPosition = () => {
      if (prevThumbElem.previousElementSibling) {
        return prevThumbElem.previousElementSibling.getBoundingClientRect().x + (prevThumbElem.previousElementSibling.clientWidth / 2);
      } else {
        return prevThumbElem.getBoundingClientRect().x;
      }
    };

    if (activeThumbIsFirstThumb) {
      parentRowElem.scrollLeft = 0;
    } else if (activeThumbIsLastThumb) {
      parentRowElem.scrollLeft = parentRowElem.scrollWidth;
    } else if (showHalfOfNextThumb) {
      const scrollAmount = calculateNextThumbPosition() - parentRowElemY;
      parentRowElem.scrollLeft += scrollAmount;
    } else if (showHalfOfPrevThumb) {
      const scrollAmount = parentRowRect.x - calculatePreviousThumbPosition();
      parentRowElem.scrollLeft -= scrollAmount;
    }
  }

  componentDidUpdate(): void {
    if (this.isScrollThumbnailRowEnabled && this.shouldDisplayThumbnails()) {
      this.scrollThumbnailRow();
    }
  }

  @bind
  outlet() {
    return document.querySelector(LIGHTBOX_OUTLET) || document.body;
  }

  @bind
  handleMainImageClick(evt, idx) {
    evt.preventDefault();
    if (this.isLink(this.props.fullsizeURLs[idx]) && !this.props.disableFullScreen) {
      this.openModal(idx);
      if (this.props.mainImageClickCallback !== undefined) {
        this.props.mainImageClickCallback();
      }
      return;
    }

    if (!this.isLink(this.props.fullsizeURLs[idx])) {
      this.setState({ displayVideo: true });
    }
  }

  @bind
  closeModal() {
    this.setState({
      isOpen: false,
      displayVideo: false,
    });

    if (!this.props.persistKeystrokes) {
      window.removeEventListener('keydown', this.handleKeyDown);
    }
  }

  @bind
  handleImageLoaded() {
    this.setState({
      largePrimaryImageLoaded: true,
      imageLoading: false,
    });
  }

  @bind
  handlePrimaryImageLoaded(evt) {
    if (!this.state.primaryImageLoaded && this.props.useAspectOverride) {
      this.setState({
        aspectOverride: evt.target.height / evt.target.width,
      });
    }

    this.setState({ primaryImageLoaded: true });
  }

  @bind
  handleKeyDown(e) {
    const tag = e.target.tagName;
    // don't change the slides if user is typing in an input
    if ((tag === 'TEXTAREA') || (tag === 'INPUT')) { return; }
    if (this.isSingleImage() || e.ctrlKey || e.metaKey || e.altKey) { return; }
    if (e.keyCode === LEFT_ARROW_CODE) { this.loadPrevImage(e); }
    if (e.keyCode === RIGHT_ARROW_CODE) { this.loadNextImage(e); }
  }

  @bind
  loadNextImage(e) {
    const newIdx = this.state.activeImgIndex + 1;
    e.stopPropagation();

    this.setState({
      activeImgIndex: newIdx >= this.props.fullsizeURLs.length ? 0 : newIdx,
      displayVideo: false,
      imageLoading: true,
    });
  }

  @bind
  loadPrevImage(e) {
    const newIdx = this.state.activeImgIndex - 1;
    e.stopPropagation();

    this.setState({
      activeImgIndex: newIdx >= 0 ? newIdx : this.props.fullsizeURLs.length - 1,
      displayVideo: false,
      imageLoading: true,
    });
  }

  @bind
  handleFullsizeImageClick(evt, idx) {
    evt.stopPropagation();

    if (!this.isLink(this.props.fullsizeURLs[idx])) {
      this.setState({ displayVideo: true });
    }
  }

  @bind
  handleThumbClick(evt, idx) {
    evt.preventDefault();
    this.setState({ displayVideo: false });

    trackEvent({
      eventName: MParticleEventName.ClickedItemImageThumbnail,
      position: idx + 1,
      imageCount: this.props.thumbURLs.length,
      inModal: this.state.isOpen,
      thumbnailType: this.isLink(this.props.thumbURLs[idx]) ? ThumbnailType.Image : ThumbnailType.Video,
    });

    if (this.props.thumbClickCallback !== undefined) {
      this.props.thumbClickCallback();
    }

    if (this.state.activeImgIndex === idx && !this.props.hidePrimary) return null;

    if (this.props.imageURLs && !this.props.hidePrimary) {
      this.setState({
        transitioning: true,
        activeImgIndex: idx,
      });
      setTimeout(this.resetTransition, 50);
      return null;
    }

    this.openModal(idx);
    return null;
  }

  isLink(url) {
    if (!url) { return false; }
    return url.slice(0, 4) === 'http';
  }

  @bind
  resetTransition() {
    this.setState({
      transitioning: false,
    });
  }

  openModal(idx) {
    if (!this.props.persistKeystrokes) {
      window.addEventListener('keydown', this.handleKeyDown);
    }

    this.setState({
      isOpen: true,
      activeImgIndex: idx,
    });
  }

  getImageAlt(idx) {
    if (!this.props.imageAlts) { return null; }
    return this.props.imageAlts[idx];
  }

  isActiveThumb(idx) {
    return this.state.activeImgIndex === idx;
  }

  isSingleImage() {
    return this.props.fullsizeURLs.length === 1;
  }

  hasFullSizeImages() {
    return this.props.fullsizeURLs && this.props.fullsizeURLs.length > 0;
  }

  shouldRenderControls() {
    return this.props.withControls && !this.isSingleImage();
  }

  shouldDisplayThumbnails() {
    if (this.props.hideThumbs) return false;
    if (this.props.hidePrimary) return true;
    if (this.props.imageURLs) {
      return this.props.thumbURLs && this.props.thumbURLs.length > 1;
    }

    return this.props.thumbURLs && this.props.thumbURLs.length > 0;
  }

  renderCaption() {
    if (!(this.props.captionText && !!this.props.captionText.length)) {
      return null;
    }

    return (
      <div className="lightbox-modal__caption">
        {this.props.captionText}
      </div>
    );
  }

  renderThumb(urlOrSlug, idx) {
    const thumbClass = classNames(
      'lightbox-image__thumb',
      { 'lightbox-image__thumb--inactive': !this.isActiveThumb(idx) },
      { 'lightbox-image__thumb--large': this.props.hidePrimary },
      { 'lightbox-image__thumb--border': this.isScrollThumbnailRowEnabled },
    );

    return (
      <div
        className={thumbClass}
        key={idx}
      >
        <LightboxImageItem
          urlOrSlug={urlOrSlug}
          index={idx}
          alt={this.getImageAlt(idx)}
          onClick={this.handleThumbClick}
        />
      </div>
    );
  }

  renderThumbs() {
    if (!this.props.thumbURLs) return null;

    return (
      <div className="lightbox-image__thumbs">
        <div className="lightbox-image__thumbs__inner">
          {this.props.thumbURLs.map((thumbURL, idx) => this.renderThumb(thumbURL, idx))}
        </div>
      </div>
    );
  }

  renderControls() {
    if (this.isSingleImage()) return null;

    return (
      <div data-controls>
        <button
          className="lightbox-image__control lightbox-image__control--next"
          onClick={this.loadNextImage}
          aria-label="Next Image"
          type="button"
        />
        <button
          className="lightbox-image__control lightbox-image__control--prev"
          onClick={this.loadPrevImage}
          aria-label="Previous Image"
          type="button"
        />
      </div>
    );
  }

  renderPrimary() {
    const urlOrSlug = this.props.imageURLs[this.state.activeImgIndex];

    return (
      <LightboxImageItem
        urlOrSlug={urlOrSlug}
        index={this.state.activeImgIndex}
        alt={this.getImageAlt(this.state.activeImgIndex)}
        onClick={this.handleMainImageClick}
        onLoad={this.handlePrimaryImageLoaded}
        controls={this.props.withControls && this.renderControls()}
        renderVideo={!this.state.isOpen && this.state.displayVideo}
        aspectOverride={this.state.aspectOverride}
        eagerLoad={this.props.eagerLoadPrimaryImage}
      />
    );
  }

  renderPrimaryCaption() {
    const alt = this.getImageAlt(this.state.activeImgIndex);
    if (!this.props.showAltsAsCaptions || !alt) return null;

    return (
      <div
        className="lightbox-image__primary__caption"
      >
        {alt}
      </div>
    );
  }

  renderPrimarySwipeContainer() {
    if (!this.props.imageURLs) return null;
    if (this.props.hidePrimary) return null;
    if (this.isSingleImage() || !this.shouldDisplayThumbnails()) {
      return (
        <div className="lightbox-image__primary">
          {this.renderPrimary()}
        </div>
      );
    }

    return (
      <div className="lightbox-image__primary">
        <Swipeable
          onSwipedLeft={this.loadNextImage}
          onSwipedRight={this.loadNextImage}
        >
          {this.renderPrimary()}
        </Swipeable>
      </div>
    );
  }

  renderFullsizeImage() {
    return (
      <LightboxImageItem
        urlOrSlug={this.props.fullsizeURLs[this.state.activeImgIndex]}
        alt={this.getImageAlt(this.state.activeImgIndex)}
        onLoad={this.handleImageLoaded}
        onClick={this.handleFullsizeImageClick}
        renderVideo={this.state.displayVideo}
        index={this.state.activeImgIndex}
      />
    );
  }

  renderSwipeContainer() {
    if (this.isSingleImage()) { return this.renderFullsizeImage(); }

    return (
      <Swipeable
        onSwipedLeft={this.loadNextImage}
        onSwipedRight={this.loadNextImage}
      >
        {this.renderFullsizeImage()}
      </Swipeable>
    );
  }

  renderImageForCache(image, i) {
    return (
      <img
        src={image}
        key={i}
        alt={this.getImageAlt(i)}
      />
    );
  }

  renderImageCache() {
    if (!this.state.primaryImageLoaded) return null;

    return (
      <div className="d-none">
        <div data-lightbox-preload>
          {this.props.imageURLs.filter(this.isLink).map((image, i) => this.renderImageForCache(image, i))}
        </div>
        <div data-lightbox-fullsize-preload>
          {this.state.largePrimaryImageLoaded &&
            this.props.fullsizeURLs.filter(this.isLink).map((image, i) => this.renderImageForCache(image, i))
          }
        </div>
      </div>
    );
  }

  renderCloseButton() {
    return (
      <button
        type="button"
        onClick={this.closeModal}
        className="lightbox-modal__close"
        aria-label={I18n.t('commons.lightboxImage.closeButton')}
      />
    );
  }

  render() {
    if (!this.hasFullSizeImages()) { return null; }

    const modalClasses = classNames(
      'lightbox-modal',
      { 'lightbox-modal--image-loading': this.state.imageLoading },
    );

    const classes = classNames(
      'lightbox-image',
      { 'lightbox-image--single': this.isSingleImage() },
      { 'lightbox-image--transitioning': this.state.transitioning },
      { 'lightbox-image--with-thumbnails': this.shouldDisplayThumbnails() },
      { 'lightbox-image--hide-mobile-controls': this.props.hideMobileControls },
      { 'lightbox-image--larger-thumbs': this.props.largerThumbs },
    );

    return (
      <div className={classes}>
        {this.renderPrimarySwipeContainer()}
        {this.renderPrimaryCaption()}
        {this.shouldDisplayThumbnails() && this.renderThumbs()}
        {this.renderImageCache()}
        {!isNode && <ReactModal
          isOpen={this.state.isOpen}
          onRequestClose={this.closeModal}
          id={this.props.id}
          className={modalClasses}
          overlayClassName="lightbox-modal__container"
          parentSelector={this.outlet}
        >
          <div
            className="lightbox-modal__primary"
            onKeyDown={this.handleKeyDown}
          >
            {this.renderCaption()}
            {this.renderSwipeContainer()}
          </div>
          <div {...preferDarkTheme()}>
            {this.renderThumbs()}
          </div>
          {this.renderControls()}
          {this.renderCloseButton()}
        </ReactModal>}
      </div>
    );
  }
}

export default withRouter(LightboxImage);
