import React from 'react';
import FormGroupWithInput from '@reverbdotcom/commons/src/components/form_group_with_input';
import { RCTooltip } from '@reverbdotcom/cadence/components';

export default function CurrenciesInputs({ currencyValues, setCurrencyValues, onConvert, isEdit }) {
  return (
    <div className="mb-6">
      <h3 className="mb-2">Currencies</h3>
      <div className="form-group__help-text">Manually enter in values or enter in an Amount and Minimum Purchase Amount for a currency and click the Convert button to convert those values to all other currencies using the current exchange rates.</div>
      <div className="mt-4">
        {currencyValues.map(currencyValue => {
          const { currency, amount, minPurchaseAmount } = currencyValue;
          const hasAllFields = amount && minPurchaseAmount;
          const showButton = hasAllFields && !isEdit;
          const disableButton = !(hasAllFields);

          return (
            <div className="d-flex" key={currency}>
              <div className="mr-10 d-flex fx-align-center width-rem-5">
                <h4>{currency}</h4>
              </div>
              <div className="mr-10 mb-2">
                <FormGroupWithInput
                  inputName={`${currency}-amount`}
                  label="Amount"
                  value={amount}
                  disabled={isEdit}
                  required
                  tagRequired
                  updateField={
                    (fieldUpdate) => {
                      const updatedValues = currencyValues.map(val => {
                        if (val.currency === currency) {
                          return { ...val, amount: fieldUpdate[`${currency}-amount`] };
                        }
                        return val;
                      });

                      setCurrencyValues(updatedValues);
                    }
                  }
                />
              </div>
              <div className="mb-2">
                <FormGroupWithInput
                  inputName={`${currency}-minPurchaseAmount`}
                  label="Min Purchase Amount"
                  value={minPurchaseAmount}
                  required
                  tagRequired
                  disabled={isEdit}
                  updateField={
                    (fieldUpdate) => {
                      const updatedValues = currencyValues.map(val => {
                        if (val.currency === currency) {
                          return { ...val, minPurchaseAmount: fieldUpdate[`${currency}-minPurchaseAmount`] };
                        }
                        return val;
                      });

                      setCurrencyValues(updatedValues);
                    }
                  }
                />
              </div>
              {disableButton && (
                <RCTooltip
                  text="Amount and Minimum Purchase Amount must be filled before you can convert to other currencies."
                >
                  <div className="mt-4 ml-4">
                    <button
                      className="button button--large"
                      disabled
                    >
                      Convert
                    </button>
                  </div>
                </RCTooltip>
              )}
              {showButton && (
                <div className="mt-4 ml-4">
                  <button
                    className="button button--large"
                    onClick={(e) => {
                      e.preventDefault();
                      onConvert(currencyValue);
                    }}
                  >
                    Convert
                  </button>
                </div>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
}
