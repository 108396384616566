// https://github.com/NoHomey/bind-decorator/blob/master/src/index.ts

namespace constants {
  export const typeOfFunction: 'function' = 'function';
  export const boolTrue = true;
}

export default function bind<T extends Function>(
  _target: object,
  propertyKey: string,
  descriptor: TypedPropertyDescriptor<T>): TypedPropertyDescriptor<T> | void {

  return {
    configurable: constants.boolTrue,
    get(this: T): T {
      const bound: T = descriptor.value!.bind(this);

      Object.defineProperty(this, propertyKey, {
        value: bound,
        configurable: constants.boolTrue,
        writable: constants.boolTrue,
      });

      return bound;
    },
  };
}
