import React from 'react';
import { get } from 'lodash';
import { MParticleEventName } from '@reverbdotcom/commons/src/elog/mparticle_tracker';
import CoreLink from '@reverbdotcom/commons/src/components/core_link';

interface ICountRecord {
  count?: number;
  display_text?: string,
  _links?: object;
  badge_links?: object;
}

interface IProps {
  countRecord?: ICountRecord;
  rel: string;
  persist?: boolean;
}

const USER_NOTIFICATIONS_KEY = 'user_notifications';

function getLink(countRecord) {
  const link = get(countRecord, '_links.web.href');
  if (!link) { return undefined; }

  // Remove domain to preseve ORIGIN for admin.reverb.tools
  const loc = new URL(link);
  return loc.pathname + loc.search + loc.hash;
}

function ReverbHeaderUserBadge({
  rel,
  countRecord = {},
  persist,
}: IProps) {
  const hasCount = countRecord?.count > 0 || persist;
  const hasDisplayText = !!countRecord.display_text;

  if (hasCount && hasDisplayText) {
    return (
      <li>
        <CoreLink
          to={getLink(countRecord)}
          className="reverb-header__menu__link"
          rel={rel}
          data-notification-count={countRecord.count}
          clickEvent={{
            componentName: USER_NOTIFICATIONS_KEY,
            eventName: MParticleEventName.ClickedUserNotification,
            displayStyle: countRecord.display_text,
          }}
        >
          {countRecord.display_text}
        </CoreLink>
      </li>
    );
  }

  return null;
}

export default ReverbHeaderUserBadge;
