import React from 'react';
import I18n from 'i18n-js';
// TODO update this to import from commons
// import { gql } from '@reverbdotcom/commons/src/gql';
// eslint-disable-next-line no-restricted-imports
import { gql } from '@apollo/client';
import { isEmpty } from 'lodash';

import { useMutation } from '@reverbdotcom/commons/src/useMutation';
import { RCButton, RCTextWithIcon, useMediaQuery } from '@reverbdotcom/cadence/components';
import { Listing } from '@reverbdotcom/commons/src/gql/graphql';
import { ModalDialog } from '@reverbdotcom/commons';
import { CheckCircleIcon } from '@reverbdotcom/cadence/icons/react';
import { useViewTracking } from '@reverbdotcom/commons/src/use_tracking';
import { MParticleEventName } from '@reverbdotcom/commons/src/elog/mparticle_types';
import { trackEvent } from '@reverbdotcom/commons/src/elog/mparticle_tracker';
import SellerListingCardsToolbarContext from '../SellerListingCardsToolbarContext';
import SellerListingsCollectionContext from '../SellerListingsCollectionContext';

export const COMPONENT_NAME = 'BulkPublishButton';
interface IExternalProps {
  selectedListings: Listing[];
}

const BULK_PUBLISH_MUTATION = gql`
  mutation Core_MyListings_BulkPublish(
    $input: Input_core_apimessages_BulkPublishListingsRequest
  ) {
    bulkPublishListings(input: $input) {
      responses: listings {
        listingId
        success
        errors: publishErrors {
          message
        }
      }
      prepublishStepRequired {
        step
      }
    }
  }
`;

const BULK_PUBLISH_ASYNC_MUTATION = gql`
mutation Core_MyListings_BulkPublishAsync(
  $input: Input_core_apimessages_BulkPublishListingsAsyncRequest
) {
  bulkPublishListingsAsync(input: $input) {
    enqueued
    message
  }
}
`;

export default function BulkPublishButton({
  selectedListings,
}: IExternalProps) {
  const {
    listingsCollectionState,
  } = React.useContext(SellerListingsCollectionContext);

  const {
    hasAllMatchesSelected,
    serializedDashboardSearchParams,
    totalMatches,
  } = listingsCollectionState;

  const {
    handleBulkResponses,
    handleGenericError,
    handleAsyncBulkResponse,
  } = React.useContext(SellerListingCardsToolbarContext);
  const isMobile = useMediaQuery('mobile');

  const listingIds = selectedListings.map(listing => listing.id);

  const publishableListings = selectedListings.filter(listing => listing.publishValidation.isValid);
  const unpublishableListings = selectedListings.filter(listing => !listing.publishValidation.isValid);

  const hasPublishableListings = !!publishableListings.length;
  const hasNoPublishableListings = unpublishableListings.length === selectedListings.length;
  const hasUnpublishableListings = !!unpublishableListings.length;

  const totalPublishableListings = hasAllMatchesSelected ? totalMatches : publishableListings.length;

  const modalSubmitI18nKey = hasNoPublishableListings ? 'close' : 'publish';

  const [isErrorModalOpen, showErrorModal] = React.useState(false);

  const [bulkPublishListings, {
    data = {},
    loading,
    errors,
  }] = useMutation(BULK_PUBLISH_MUTATION);
  const { responses, prepublishStepRequired } = data.bulkPublishListings ?? {};
  const hasErrors = !!errors.length;

  const [bulkPublishListingsAsync, {
    data: asyncData,
    loading: asyncLoading,
    errors: asyncErrors,
  }] = useMutation(BULK_PUBLISH_ASYNC_MUTATION);
  const hasAsyncErrors = !!asyncErrors.length;

  React.useEffect(() => {
    if (loading) return;
    if (hasErrors) return handleGenericError();

    handleResponse();
  }, [loading]);

  React.useEffect(() => {
    if (asyncLoading) return;
    if (hasAsyncErrors) return handleGenericError();

    handleAsyncResponse();
  }, [asyncLoading]);

  function handleResponse() {
    if (isEmpty(data)) return;

    if (prepublishStepRequired?.step) {
      handleGenericError(
        'discovery.listingsManagement.toolbar.bulkActions.bulkPublish.prepublishStepRequired',
      );
    } else {
      handleBulkResponses({
        responses,
        I18nSuccessMessageKey: 'discovery.listingsManagement.toolbar.bulkActions.bulkPublish.publishSuccessful',
        I18nErrorMessageKey: 'discovery.listingsManagement.toolbar.bulkActions.bulkPublish.publishErrors.title',
      });
    }
  }

  function handleAsyncResponse() {
    if (!asyncData?.bulkPublishListingsAsync) return;

    if (asyncData?.bulkPublishListingsAsync.enqueued) {
      handleAsyncBulkResponse({
        I18nKey: 'discovery.listingsManagement.toolbar.bulkActions.bulkPublish.asyncResponses.successes',
        expectedTotal: totalMatches,
      });
    } else {
      handleGenericError(
        'discovery.listingsManagement.toolbar.bulkActions.bulkPublish.prepublishStepRequired',
      );
    }
  }

  function publish() {
    trackEvent({
      componentName: COMPONENT_NAME,
      eventName: MParticleEventName.PublishingListings,
      listingsCount: totalPublishableListings,
      hasAllMatchesSelected,
    });

    if (hasAllMatchesSelected) {
      bulkPublishListingsAsync({
        variables: {
          input: {
            serializedDashboardSearchParams,
            expectedTotal: totalPublishableListings,
          },
        },
      });
    } else {
      const publishableListingIds = publishableListings.map(listing => listing.id);

      bulkPublishListings({
        variables: {
          input: {
            listingIds: publishableListingIds,
          },
        },
      });
    }
  }

  function handleDefaultSubmit() {
    trackEvent({
      eventName: MParticleEventName.ClickedComponent,
      componentName: COMPONENT_NAME,
    });

    if (hasUnpublishableListings && !hasAllMatchesSelected) {
      showErrorModal(true);
    } else {
      publish();
    }
  }

  function handleModalSubmit() {
    showErrorModal(false);

    if (!hasNoPublishableListings) {
      publish();
    }
  }

  useViewTracking({
    componentName: COMPONENT_NAME,
    eventName: MParticleEventName.ComponentView,
  }, true);

  return (
    <>
      <RCButton
        fullWidth
        variant="outlined"
        size={isMobile ? 'medium' : 'mini' }
        onClick={handleDefaultSubmit}
        disabled={!listingIds.length || loading || asyncLoading}
        isSubmit={loading || asyncLoading}
      >
        <RCTextWithIcon svgComponent={CheckCircleIcon} placement="right">
          {I18n.t('discovery.listingsManagement.toolbar.bulkActions.bulkPublish.publishSelected')}
        </RCTextWithIcon>
      </RCButton>
      <ModalDialog
        isOpen={isErrorModalOpen}
        headerTitle={I18n.t('discovery.listingsManagement.toolbar.bulkActions.bulkPublish.publishErrorModal.headerTitle', {
          count: publishableListings.length,
        })}
        saveButtonText={I18n.t(`discovery.listingsManagement.toolbar.bulkActions.bulkPublish.publishErrorModal.${modalSubmitI18nKey}`)}
        hideFooterDismissButton={hasNoPublishableListings}
        onSubmit={handleModalSubmit}
        onRequestClose={() => showErrorModal(false)}
      >
        {hasPublishableListings && (
          <div className="weight-bold mb-4">
            {I18n.t('discovery.listingsManagement.toolbar.bulkActions.bulkPublish.publishErrorModal.publishMessage', {
              count: publishableListings.length,
            })}
          </div>
        )}

        <div className="weight-bold">
          {I18n.t('discovery.listingsManagement.toolbar.bulkActions.bulkPublish.publishErrorModal.errorMessage', {
            count: unpublishableListings.length,
          })}
        </div>
      </ModalDialog>
    </>
  );
}
