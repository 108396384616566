import React from 'react';
import PaymentIcon, { PaymentIconName } from './payment_icon';

interface ICreditCardInputWithIconProps {
  name?: PaymentIconName | string;
  children?: React.ReactNode;
}

export default function CreditCardInputWithIcon({ name, children }: ICreditCardInputWithIconProps) {
  return (
    (<div className="cc-input-with-icon">
      <div className="cc-input-with-icon__input">
        {children || (
          // This is just a placeholder to use, please pass `children`
          // when using in a real scenario
          (<input type="text" />)
        )}
      </div>
      <div className="cc-input-with-icon__icon">
        <PaymentIcon
          name={name}
          size="fullwidth"
        />
      </div>
    </div>)
  );
}
