import React from 'react';
import qs from 'qs';
import { withRouter, WithRouterProps } from 'react-router';

import FormGroupWithInput from './form_group_with_input';
import FormGroupWithSelect from './form_group_with_select';
import { withPrefix } from '../i18n_helpers';
import { useUser } from '../user_hooks';
import { CA } from '../constants';
import { RCModal } from '@reverbdotcom/cadence/components';
import CsrfForm from '../csrf_form';

interface ExternalProps {
  isModalOpen: boolean;
  setModal: Function;
  propsPostalCode?: string;
  propsDistance?: string;
  includeRadiusDropdown?: boolean;
  shouldApplyPostalCodeQueryParam?: boolean; // should we apply `postal_code=XXXXX` to the post-submit URL
  shouldApplyDistanceQueryParam?: boolean; // should we apply `distance=X` to the post-submit URL
  additionalQueryParams?: Record<string, any>; // any additional query params to append to the post-submit URL
}

const t = withPrefix('commons.proximityFilter');

export const POSTAL_CODE_PARAM = 'postal_code';
export const DISTANCE_PARAM = 'distance';

type IProps = ExternalProps & WithRouterProps;

const MILES_5 = '5';
const MILES_25 = '25';
const MILES_50 = '50';
export const MILES_100 = '100';
const MILES_250 = '250';
const MILES_500 = '500';

const DISTANCE_OPTIONS = [
  MILES_5,
  MILES_25,
  MILES_50,
  MILES_100,
  MILES_250,
  MILES_500,
];

const CANADA_POSTAL_CODE_REGEX = '^[ABCEGHJ-NPRSTVXY]\\d[ABCEGHJ-NPRSTV-Z][ -]?\\d[ABCEGHJ-NPRSTV-Z]\\d$\\i';

function buildDistanceValue(distance) {
  return {
    text: t('modalDistanceMiles', { distance }),
    value: distance,
  };
}

function getPostSubmitUrl({
  location,
  postalCode,
  distance,
  applyPostalCodeQueryParam,
  applyDistanceQueryParam,
  additionalQueryParams,
}) {
  if (!applyPostalCodeQueryParam && !applyDistanceQueryParam && !additionalQueryParams) { return null; }
  const { pathname, query: currentQuery } = location || {};
  if (!pathname) { return null; }

  const updatedQueryParams = {
    ...currentQuery,
    ...(applyPostalCodeQueryParam && { [POSTAL_CODE_PARAM]: postalCode }),
    ...(applyDistanceQueryParam && { [DISTANCE_PARAM]: distance }),
    ...additionalQueryParams,
  };

  return `${pathname}?${qs.stringify(updatedQueryParams)}`;
}

export function ProximityModal({
  propsPostalCode,
  propsDistance,
  location,
  isModalOpen,
  setModal,
  includeRadiusDropdown = false,
  shouldApplyPostalCodeQueryParam = false,
  shouldApplyDistanceQueryParam = false,
  additionalQueryParams,
}: IProps) {
  const user = useUser();
  const defaultPostalCodeValue = propsPostalCode || user.postalCode || '';
  const defaultDistanceValue = propsDistance || MILES_100;
  const inCanada = user.shippingRegionCode == CA;

  const [postalCode, setPostalCode] = React.useState(defaultPostalCodeValue);
  const [distance, setDistance] = React.useState(defaultDistanceValue);

  return (
    <RCModal
      title={t('adjustLocation')}
      isOpen={isModalOpen}
      onOpenChange={(state) => {
        if (state === false) setModal(false);
      }}
      actions={{
        primary: {
          buttonText: t('update'),
          disabled: !postalCode,
          isSubmit: true,
          form: 'adjust-location',
          preventCloseOnClick: true,
        },
        secondary: true,
      }}
    >
      <CsrfForm
        action="/users/proximity_settings"
        method="post"
        id="adjust-location"
      >
        <div className="g-container">
          <div className="g-col-6">
            <FormGroupWithInput
              inputName={POSTAL_CODE_PARAM}
              label={t(inCanada ? 'postalCode' : 'zipCode')}
              updateField={(e) => { setPostalCode(e[POSTAL_CODE_PARAM]); }}
              value={postalCode}
              pattern={inCanada ? CANADA_POSTAL_CODE_REGEX : null}
              numberOnly={!inCanada}
              minLength={inCanada ? 7 : 5}
              maxLength={inCanada ? 7 : 5}
            />
          </div>

          {includeRadiusDropdown && (
            <div className="g-col-6">
              <FormGroupWithSelect
                inputName={DISTANCE_PARAM}
                label={t('radius')}
                updateField={(e) => setDistance(e.distance)}
                value={distance}
                options={DISTANCE_OPTIONS.map((v) => buildDistanceValue(v))}
              />
            </div>
          )}

          <input
            name="redirect_to"
            value={getPostSubmitUrl({
              location,
              postalCode,
              distance,
              applyPostalCodeQueryParam: (shouldApplyPostalCodeQueryParam && postalCode),
              applyDistanceQueryParam: (shouldApplyDistanceQueryParam && includeRadiusDropdown && distance),
              additionalQueryParams,
            })}
            type="hidden"
          />
        </div>
      </CsrfForm>
    </RCModal>
  );
}

export default withRouter(ProximityModal);
