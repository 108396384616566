import React from 'react';
import I18n from 'i18n-js';
import { V3 } from '../api_request';
import CoreLink from '@reverbdotcom/commons/src/components/core_link';
import Article from '../discovery/article';
import URLHelpers from '../shared/url_helpers';

interface State {
  articles: Article[];
}

export default class NewsDropdown extends React.Component<{}, State> {
  constructor(props) {
    super(props);

    this.state = {
      articles: [],
    };
  }

  componentDidMount() {
    V3.get(URLHelpers.articlesRecentlyFeaturedPath()).then((res) => {
      this.setState({ articles: res?.articles || [] });
    });
  }

  renderArticles() {
    return this.state.articles.map((article) => {
      return (
        <li
          className="padding-1"
          key={`article-${article.id}`}
        >
          <CoreLink
            className="d-flex width-100 fx-align-center"
            to={article._links.web.href}
          >
            <img
              src={article._links.mobile_small.href}
              alt={article.title}
              className="bd-1 bd-primary fx-width-20"
            />
            <div
              className="pl-2 color-primary size-110 ws-normal"
            >
              {article.title}
            </div>
          </CoreLink>
        </li>
      );
    });
  }

  render() {
    return (
      <div>
        <ul>
          {this.renderArticles()}
        </ul>
        <CoreLink
          className="button button--primary width-100 mt-1"
          to="/news"
        >
          {I18n.t('discovery.newsDropdown.viewAll')}
        </CoreLink>
      </div>
    );
  }
}
