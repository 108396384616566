import React from 'react';
import { FindFavoriteProvider } from '../favorites/find_favorite_context';
import { UpdateURLProvider, UpdateUrlType } from '@reverbdotcom/commons/src/components/with_update_url';

export interface Props {
  updateUrl: UpdateUrlType;
  children: React.ReactNode;

  // Used to force a rerender and refetch of find favorite data
  // if the marketplace page layout changes
  keySuffix: string;
}

export function MarketplaceProviders(props: Props) {
  return (
    <FindFavoriteProvider key={`find-favorite-provider--${props.keySuffix}`}>
      <UpdateURLProvider updateUrl={props.updateUrl}>
        {props.children}
      </UpdateURLProvider>
    </FindFavoriteProvider>
  );
}
