import { Field } from './sellFormReducerTypes';

export enum Section {
  INFO = 'info',
  DESCRIPTION = 'description',
  SHIPPING = 'shipping',
  PRICING = 'pricing',
  REVIEW = 'review',
}

export const SECTIONS_IN_ORDER = [
  Section.INFO,
  Section.DESCRIPTION,
  Section.SHIPPING,
  Section.PRICING,
  Section.REVIEW,
];

export function getNextSection(currentSection: Section) {
  const position = SECTIONS_IN_ORDER.findIndex((el) => el === currentSection);

  return SECTIONS_IN_ORDER[position + 1];
}

export function getPreviousSection(currentSection: Section) {
  const position = SECTIONS_IN_ORDER.findIndex((el) => el === currentSection);

  return SECTIONS_IN_ORDER[position - 1];
}

export function getFieldsForSection(forSection: Section): Field[] {
  switch (forSection) {
    case Section.INFO:
      return [
        Field.TITLE,
        Field.MAKE,
        Field.MODEL,
        Field.YEAR,
        Field.FINISH,
        Field.COUNTRY,
        Field.ROOT_CATEGORY_UUID,
        Field.SUBCATEGORY_LEAF_UUIDS,
        Field.HANDMADE,
        Field.TRAITS,
      ];

    case Section.DESCRIPTION:
      return [
        Field.PHOTOS,
        Field.DESCRIPTION,
        Field.CONDITION_SLUG,
        Field.HAS_INVENTORY,
        Field.INVENTORY,
        Field.UPC,
        Field.UPC_DOES_NOT_APPLY,
        Field.VIDEO_LINK_URL,
        Field.SOLD_AS_IS,
        Field.SKU,
        Field.PREORDER_INFO,
        Field.PREORDER_INFO_LEAD_TIME_DAYS,
        Field.PREORDER_INFO_LEAD_TIME_UNIT,
        Field.PREORDER_INFO_SHIP_DATE,
        Field.PREORDER_INFO_TIME_TYPE,
        Field.LOCALIZED_CONTENTS,
      ];

    case Section.SHIPPING:
      return [
        Field.SHIPPING_PROFILE_ID,
        Field.OFFERS_LOCAL_PICKUP,
        Field.SHIPPING_RATES,
        Field.CARRIER_CALCULATED_PACKAGE,
        Field.PREFERS_REVERB_SHIPPING_LABEL,
      ];

    case Section.PRICING:
      return [
        Field.PRICE,
        Field.SELLER_COST,
        Field.SELLER_REPORTED_MAP,
        Field.OFFERS_ENABLED,
        Field.SCHEDULED_PRICE_DROPS,
      ];

    default:
      return [];
  }
}

export function getSectionForField(field: Field): Section {
  return SECTIONS_IN_ORDER.find((s) => getFieldsForSection(s).includes(field));
}
