import {
  core_apimessages_Address as Address,
} from '@reverbdotcom/commons/src/gql/graphql';
import { IUser } from '@reverbdotcom/commons/src/components/user_context_provider';

/** @deprecated Use core_apimessages_Address directly */
export type IAddressFormData = Address;

export const buildBlankAddressFormData = (user: IUser): IAddressFormData => {
  return {
    uuid: '',
    name: '',
    streetAddress: '',
    extendedAddress: '',
    postalCode: '',
    phone: '',
    region: '',
    locality: '',
    countryCode: user.countryCode,
    primary: false,
  };
};

export const buildAddressFormDataFromAddress = (address: Address): IAddressFormData => {
  return {
    uuid: address.uuid,
    name: address.name,
    streetAddress: address.streetAddress,
    extendedAddress: address.extendedAddress,
    postalCode: address.postalCode,
    phone: address.phone,
    region: address.region,
    locality: address.locality,
    countryCode: address.countryCode,
    primary: address.primary,
  };
};
