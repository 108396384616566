import PropTypes from 'prop-types';
import React from 'react';
import TogglableDropdown from '../../../shared/togglable_dropdown';
import I18n from 'i18n-js';

const countryNameForCode = countryCode =>
  I18n.t(`countries.${countryCode}`);

const optionsForSelect = props =>
  props.countryCodeOptions.map(countryCode =>
    ({ value: countryCode, name: countryNameForCode(countryCode) }));

const CountryCodeInput = props =>
  (<div className="g-col-6" data-change-country>
    <TogglableDropdown
      defaultValue={props.countryCode}
      selectOptions={optionsForSelect(props)}
      inputName={props.nameForField('country_code')}
      onChange={countryCode => props.onCountryChange(countryCode)}
      labelTitle={I18n.t('js.dashboard.selling.shopPolicies.directCheckoutProfile.bankAccountFields.countryCodeInput.bankLocation')}
      changeLinkText={I18n.t('js.dashboard.selling.shopPolicies.directCheckoutProfile.bankAccountFields.countryCodeInput.change')}
      autoComplete="country-code"
    />
  </div>);

CountryCodeInput.propTypes = {
  countryCode: PropTypes.string.isRequired,
  countryCodeOptions: PropTypes.array.isRequired,
  nameForField: PropTypes.func.isRequired,
  onCountryChange: PropTypes.func.isRequired,
};

export default CountryCodeInput;
