import PropTypes from 'prop-types';
import React from 'react';
import UrlHelpers from '../shared/url_helpers';
import Currency from '@reverbdotcom/commons/src/currency';
import { SanitizedRender } from '../index';
import I18n from 'i18n-js';

function AboutDirectCheckoutBullets(props) {
  function checkIcon() {
    return '<span class="fa fa-check fa-li color-green"></span>';
  }

  function htmlLineItem(text) {
    return (
      <SanitizedRender htmlTag="li" html={text} />
    );
  }

  function currencySymbol() {
    return Currency.get(props.shopCurrency).symbol;
  }

  return (
    <ul className="fa-ul font-tiny normal">
      {htmlLineItem(I18n.t('js.dashboard.selling.shopPolicies.dcPaymentMethodsHtml', { icon: checkIcon() }))}
      {htmlLineItem(I18n.t('js.dashboard.selling.shopPolicies.dcPayoutsHtml', { icon: checkIcon() }))}
      {htmlLineItem(I18n.t('js.dashboard.selling.shopPolicies.dcFeesHtml', {
        icon: checkIcon(),
        fee_percent: props.directCheckoutFeePercent,
        flat_amount: props.directCheckoutFeeFlatAmount,
        intl_fee_percent: props.directCheckoutIntlFeePercent,
        currency_symbol: currencySymbol(),
      }))}
      {htmlLineItem(I18n.t('js.dashboard.selling.shopPolicies.dcCoveredByProtectionHtml', { icon: checkIcon(), linkOpen: `<a href='${UrlHelpers.cmsPagePath('reverb-protection')}' target='_blank' rel='noreferrer noopener'>`, linkClose: '</a>' }))}
    </ul>
  );
}

AboutDirectCheckoutBullets.propTypes = {
  directCheckoutFeePercent: PropTypes.number.isRequired,
  directCheckoutFeeFlatAmount: PropTypes.string.isRequired,
  directCheckoutIntlFeePercent: PropTypes.number.isRequired,
  shopCurrency: PropTypes.string.isRequired,
};

export default AboutDirectCheckoutBullets;
