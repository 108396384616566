// If this insurance calculator is changed, make sure to also update
// app/reverb/shipping/shipping_label/insurance_calculator.rb
// this includes changes to INSURANCE_RATES_BY_PRODUCT_TYPE_UUID

const LOW_VALUE_THRESHOLD_CENTS_USD = 20000; // $200.00 USD
const LOW_VALUE_INSURANCE_RATE = 0.025;

const MID_VALUE_THRESHOLD_CENTS_USD = 500000; // $5,000.00 USD
const MID_VALUE_INSURANCE_RATE = 0.01;

const HIGH_VALUE_INSURANCE_RATE = 0.015;

// not all categories are included on purpose (for example, Recorded Music or Software) --
// with the understanding that if there is no specific rate for the given product type,
// we will fall back to legacy rates which are not product-type specific
const INSURANCE_RATES_BY_PRODUCT_TYPE_UUID = {
  '62835d2e-ac92-41fc-9b8d-4aba8c1c25d5': 0.02, // Accessories
  '3ca3eb03-7eac-477d-b253-15ce603d2550': 0.02, // Acoustic Guitars
  '09055aa7-ed49-459d-9452-aa959f288dc2': 0.025, // Amps
  '032c74d0-b0e2-4442-877f-e1a22438a7fa': 0.02, // Band and Orchestra
  '53a9c7d7-d73d-4e7f-905c-553503e50a90': 0.015, // Bass Guitars
  '58d889f7-0aa1-4689-a9d3-da16dd225e8d': 0.03, // DJ and Lighting Gear
  'b3cb9f8e-4cb6-4325-8215-1efcd9999daf': 0.015, // Drums and Percussion
  'fa10f97c-dd98-4a8f-933b-8cb55eb653dd': 0.03, // Effects and Pedals
  'dfd39027-d134-4353-b9e4-57dc6be791b9': 0.02, // Electric Guitars
  'fb60628c-be4b-4be2-9c0f-bc5d31e3996c': 0.02, // Folk Instruments
  '40e8bfd0-3021-43f7-b104-9d7b19af5c2b': 0.015, // Home Audio
  'd002db05-ab63-4c79-999c-d49bbe8d7739': 0.02, // Keyboards and Synths
  '1f99c852-9d20-4fd3-a903-91da9c805a5e': 0.03, // Parts
  'b021203f-1ed8-476c-a8fc-32d4e3b0ef9e': 0.02, // Pro-Audio
};

const insuranceRateForProductType = productTypeUUID =>
  INSURANCE_RATES_BY_PRODUCT_TYPE_UUID[productTypeUUID];

const legacyInsuranceRate = (valueCents) => {
  if (valueCents <= LOW_VALUE_THRESHOLD_CENTS_USD) {
    return LOW_VALUE_INSURANCE_RATE;
  }

  if (valueCents <= MID_VALUE_THRESHOLD_CENTS_USD) {
    return MID_VALUE_INSURANCE_RATE;
  }

  return HIGH_VALUE_INSURANCE_RATE;
};

const findInsuranceRate = (totalCents, productTypeUUID) => (insuranceRateForProductType(productTypeUUID) || legacyInsuranceRate(totalCents));

const calculateInsuranceCostCents = (totalCents, productTypeUUID) => {
  const insuranceRate = findInsuranceRate(totalCents, productTypeUUID);
  return (totalCents * insuranceRate);
};

const exported = {
  LOW_VALUE_INSURANCE_RATE,
  LOW_VALUE_THRESHOLD_CENTS_USD,
  MID_VALUE_INSURANCE_RATE,
  MID_VALUE_THRESHOLD_CENTS_USD,
  HIGH_VALUE_INSURANCE_RATE,
  calculateInsuranceCostCents,
};

export default exported;
export { LOW_VALUE_INSURANCE_RATE, LOW_VALUE_THRESHOLD_CENTS_USD, MID_VALUE_INSURANCE_RATE, MID_VALUE_THRESHOLD_CENTS_USD, HIGH_VALUE_INSURANCE_RATE, calculateInsuranceCostCents };
