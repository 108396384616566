import PropTypes from 'prop-types';
import React from 'react';
import UrlHelpers from '../shared/url_helpers';
import I18n from 'i18n-js';

const SHOP_CURRENCY = 'shopCurrency';
const PAYOUT_CURRENCY = 'payoutCurrency';

const CurrencyExchangeFeeWarning = function (props) {
  if (!props.directCheckoutSelected) {
    return null;
  }

  if (props.shopCurrency === props.payoutCurrency) {
    return null;
  }

  const dashboardPayoutCurrencyWarning = I18n.t(
    'js.dashboard.selling.shopPolicies.directCheckoutProfile.bankAccountFields.dashboardCurrencyExchangeFeeWarning',
    {
      shopCurrencyCode: props.shopCurrency,
    },
  );

  const nonDashboardPayoutCurrencyWarning = I18n.t(
    'js.dashboard.selling.shopPolicies.directCheckoutProfile.bankAccountFields.currencyExchangeFeeWarningHtml',
    {
      shopCurrencyCode: props.shopCurrency,
      linkOpen: `<a href="${UrlHelpers.shopPoliciesEditPath}#shop-currency-selector">`,
      linkClose: '</a>',
    },
  );

  const shopCurrencyWarning = I18n.t(
    'js.dashboard.selling.shopPolicies.shopCurrency.currencyExchangeFeeWarningHtml',
    {
      payoutCurrencyCode: props.payoutCurrency,
    },
  );

  let warningText;

  if (props.currencyAttributeName === SHOP_CURRENCY) {
    warningText = shopCurrencyWarning;
  } else if (props.dashboardView) {
    warningText = dashboardPayoutCurrencyWarning;
  } else {
    warningText = nonDashboardPayoutCurrencyWarning;
  }

  return (
    <p
      className={props.className}
      data-currency-exchange-fee-warning
      dangerouslySetInnerHTML={{ __html: warningText }}
    />
  );
};

CurrencyExchangeFeeWarning.propTypes = {
  shopCurrency: PropTypes.string.isRequired,
  payoutCurrency: PropTypes.string.isRequired,
  directCheckoutSelected: PropTypes.bool.isRequired,
  dashboardView: PropTypes.bool.isRequired,
  currencyAttributeName: PropTypes.string,
  className: PropTypes.string,
};

CurrencyExchangeFeeWarning.defaultProps = {
  currencyAttributeName: PAYOUT_CURRENCY,
};

export default CurrencyExchangeFeeWarning;
