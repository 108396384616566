import React from 'react';
import I18n from 'i18n-js';
import {
  core_apimessages_CreditCard,
} from '@reverbdotcom/commons/src/gql/graphql';
import CreditCardIcon from '../credit_cards/legacy/credit_card_icon';

interface Props {
  creditCard: core_apimessages_CreditCard;
  onChangeButtonClick: Function;
}

function renderCardSummary(creditCard) {
  const cardAbout = `${creditCard.cardType} ....${creditCard.last4}`;
  const expiryDate = `${creditCard.expirationMonth}/${creditCard.expirationYear}`;
  return (
    <div className="cc-details__card-info">
      <div className="cc-details__card-type">
        {cardAbout}
      </div>
      <div className="cc-details__expiration">
        {I18n.t('discovery.selectableCreditCardList.expiresOn', { date: expiryDate })}
      </div>
    </div>
  );
}

export default function PrimaryBillingMethod(props: Props) {
  const { creditCard } = props;

  function handleChangeButtonClick(event: React.MouseEvent<HTMLInputElement>): void {
    event.preventDefault();
    props.onChangeButtonClick();
  }

  return (
    <label
      className="boxed-list__item boxed-list__item"
    >
      <div className="cc-details">
        <div className="cc-details__card">
          <div className="cc-details__icon">
            <CreditCardIcon
              cardType={creditCard.cardType}
            />
          </div>
          {renderCardSummary(creditCard)}
        </div>
        <button
          className="button-as-link boxed-list__action"
          type="button"
          onClick={handleChangeButtonClick}
        >
          {I18n.t('discovery.selectableCreditCardList.change')}
        </button>
      </div>
    </label>
  );
}
