import React from 'react';
import { IndexRoute } from '@reverbdotcom/commons/src/route_settings';

export const INDEX_PATH = '/';

export default (<IndexRoute
  key={INDEX_PATH}
  getComponent={(_location, cb) => {
    import(
      /* webpackChunkName: 'homepage' */
      '../components/discovery/homepage'
    ).then(m => cb(null, m.default));
  }}
/>);
