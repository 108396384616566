// If this policy changes, make sure to also update
// app/reverb/shipping/shipping_label/insurance_policy.rb

import accounting from 'accounting';
import { calculateInsuranceCostCents } from './insurance_calculator';

const MINIMUM_VALUE_REQUIRING_INSURANCE_CENTS_USD = 150001; //  $1,500.01 USD
const MAXIMUM_VALUE_REQUIRING_INSURANCE_CENTS_USD = 1000000; // $10,000.00 USD
const MAXIMUM_INTL_DECLARED_VALUE_CENTS_USD = 250000; //        $2,500.00 USD

class InsurancePolicy {
  constructor(valueCents, productTypeUUID) {
    this.valueCents = valueCents;
    this.productTypeUUID = productTypeUUID;
  }

  get formattedInsuranceCost() {
    return (
      this.formattedUSDCentsToDollars(calculateInsuranceCostCents(this.valueCents, this.productTypeUUID))
    );
  }

  get formattedTotalDeclaredValue() {
    return this.formattedUSDCentsToDollars(this.valueCents);
  }

  get formattedMaximumDeclaredValue() {
    return this.formattedUSDCentsToDollars(MAXIMUM_INTL_DECLARED_VALUE_CENTS_USD);
  }

  formattedUSDCentsToDollars(cents) {
    return accounting.formatMoney(
      cents / 100,
      {
        symbol: '$',
        precision: 2,
      },
    );
  }

  get cannotInsure() {
    return this.valueCents > MAXIMUM_VALUE_REQUIRING_INSURANCE_CENTS_USD;
  }

  get insuranceRequired() {
    return (
      this.aboveMinimumRequiredInsuranceThreshold && this.belowMaximumRequiredInsuranceThreshold
    );
  }

  get aboveMaximumIntlDeclaredValueThreshold() {
    return this.valueCents > MAXIMUM_INTL_DECLARED_VALUE_CENTS_USD;
  }

  get aboveMinimumRequiredInsuranceThreshold() {
    return this.valueCents >= MINIMUM_VALUE_REQUIRING_INSURANCE_CENTS_USD;
  }

  get belowMaximumRequiredInsuranceThreshold() {
    return this.valueCents <= MAXIMUM_VALUE_REQUIRING_INSURANCE_CENTS_USD;
  }
}

const exported = {
  InsurancePolicy,
  MINIMUM_VALUE_REQUIRING_INSURANCE_CENTS_USD,
  MAXIMUM_VALUE_REQUIRING_INSURANCE_CENTS_USD,
  MAXIMUM_INTL_DECLARED_VALUE_CENTS_USD,
};

export default exported;
export { InsurancePolicy, MINIMUM_VALUE_REQUIRING_INSURANCE_CENTS_USD, MAXIMUM_VALUE_REQUIRING_INSURANCE_CENTS_USD, MAXIMUM_INTL_DECLARED_VALUE_CENTS_USD };
