import React from 'react';
import { Route } from 'react-router';
import URLHelpers from '../components/shared/url_helpers';

const {
  coreIdentityVerificationPath,
} = URLHelpers;

export const CoreIdentityVerificationRoute = (
  <Route
    key={coreIdentityVerificationPath}
    path={coreIdentityVerificationPath}
    getComponent={(_location, cb) => {
      import(
        /* webpackChunkName: 'onboarding' */
        '../identity_verification/statuses_index'
      ).then(m => cb(null, m.default));
    }}
  />
);
