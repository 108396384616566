import React from 'react';
import { debounce } from 'lodash';
import ProductPreview from './product_preview';
import ScrollableList from './scrollable_list';
import UrlHelpers from '../shared/url_helpers';
import { V3 } from '../api_request';
import I18n from 'i18n-js';

const PER_PAGE = 20;
const SEARCH_DELAY = 200;

interface IProps {
  currentListingIds: string[];
  productSelected: Function;
  onChange: Function;
  results: any[];
  inputValue: string;
  disableDebounce?: boolean;
  includeLP?: boolean;
}

interface IState {
  results: any[];
  hideItems: boolean;
}

class ProductSearchForm extends React.Component<IProps, IState> {
  isComponentMounted: boolean;

  static defaultProps: Partial<IProps> = {
    results: [],
    onChange: () => { },
    includeLP: false,
  };

  constructor(props) {
    super(props);

    this.isComponentMounted = false;

    this.handleSubmit = this.handleSubmit.bind(this);
    this.renderProduct = this.renderProduct.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleContainerFocus = this.handleContainerFocus.bind(this);

    if (this.props.disableDebounce) {
      this.handleChangeDebounced = this.handleChangeDebounced.bind(this);
    } else {
      this.handleChangeDebounced = debounce(this.handleChangeDebounced.bind(this), SEARCH_DELAY);
    }

    this.state = {
      results: props.results,
      hideItems: false,
    };
  }

  componentDidMount() {
    document.addEventListener('click', () => this.handleDocumentClick());
    this.isComponentMounted = true;
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.results !== this.state.results) {
      this.setState({ results: nextProps.results });
    }
  }

  componentWillUnmount() {
    document.removeEventListener('click', () => this.handleDocumentClick());
    this.isComponentMounted = false;
  }

  handleDocumentClick() {
    if (this.isComponentMounted && this.state.results.length > 0 && this.state.hideItems === false) {
      // hide the results
      this.setState({ hideItems: true });
    }
  }

  handleContainerClick(e) {
    e.nativeEvent.stopImmediatePropagation();
  }

  handleContainerFocus() {
    // show the items
    this.setState({ hideItems: false });
  }

  handleChange(e) {
    this.props.onChange(e);
    this.handleChangeDebounced(e.target.value);
  }

  handleChangeDebounced(search) {
    if (search && search.length) {
      V3.get(UrlHelpers.myListingsPath, {
        query: search,
        per_page: PER_PAGE,
        not_ids: this.props.currentListingIds,
        include_lp: this.props.includeLP,
      }).done((response) => {
        if (this.isComponentMounted) {
          this.setState({
            results: response.listings,
          });
        }
      });
    } else {
      this.setState({
        results: [],
      });
    }
  }

  handleSubmit(e) {
    e.preventDefault();

    const focusedProduct = (this.refs.scrollableList as ScrollableList).focusedItem();
    if (focusedProduct) {
      this.props.productSelected(focusedProduct);
    }
  }

  renderProduct(product) {
    return (
      <ProductPreview
        product={product}
        handleClick={this.props.productSelected}
      />
    );
  }

  render() {
    return (
      <div
        onClick={this.handleContainerClick}
        onFocus={this.handleContainerFocus}
      >
        <ScrollableList
          name="search"
          type="text"
          placeholder={I18n.t('discovery.productSearchForm.placeholder')}
          changeEvent={this.handleChange}
          onEnter={this.handleSubmit}
          autoComplete="off"
          autoFocus="on"
          items={this.state.results}
          renderItem={this.renderProduct}
          ref="scrollableList"
          inputValue={this.props.inputValue}
          hideItems={this.state.hideItems}
        />
      </div>
    );
  }
}

export default ProductSearchForm;
