import React from 'react';
import classNames from 'classnames';
import { AngleDownIcon } from '@reverbdotcom/cadence/icons/react';

interface ClassnameProps {
  withDropdown?: boolean;
  selected?: boolean;
  count?: number;
  mobileOnlyCount?: boolean;
  loading?: boolean;
}

interface IProps extends ClassnameProps {
  children: React.ReactNode;
  onClick?: (e) => void;
}

export function filterChipClasses({
  withDropdown = false,
  selected = false,
  count = 0,
  mobileOnlyCount = false,
  loading = false,
}: ClassnameProps) {
  return classNames(
    'filter-chip',
    { 'filter-chip--with-dropdown': withDropdown },
    { 'filter-chip--with-count': count > 1 && !mobileOnlyCount },
    { 'filter-chip--with-mobile-count': count > 0 && mobileOnlyCount },
    { 'filter-chip--selected': selected },
    { 'filter-chip--loading': loading },
  );
}

export const FilterChip = React.forwardRef<HTMLButtonElement, IProps>(({
  withDropdown = false,
  selected = false,
  count = 0,
  mobileOnlyCount = false,
  loading = false,
  children,
  onClick,
  ...passthroughProps
}: IProps, ref) => {
  return (
    <button
      type="button"
      className={filterChipClasses({ withDropdown, selected, count, mobileOnlyCount, loading })}
      onClick={onClick}
      ref={ref}
      // We need prop spreading to automatically pass down aria and other helpful props from
      // the floating-ui library and any others
      /* eslint-disable-next-line react/jsx-props-no-spreading */
      {...passthroughProps}
    >
      {children}
      {withDropdown && (
        <span className="filter-chip__dropdown">
          <AngleDownIcon />
        </span>
      )}
      <span className="filter-chip__count">
        {count}
      </span>
    </button>
  );
});

// Explicitly setting displayName to help with debugging and tracing an otherwise anonymous function.
FilterChip.displayName = 'FilterChip';

export default FilterChip;
