import React from 'react';
import I18n from 'i18n-js';

import { gql } from '@reverbdotcom/commons/src/gql';
import { useMutation } from '@reverbdotcom/commons/src/useMutation';
import { ModalDialog } from '@reverbdotcom/commons';
import { I18N as Translate } from '@reverbdotcom/commons/src/components/translate';
import { RCTextInput, RCText } from '@reverbdotcom/cadence/components';
import SellerListingCardsToolbarContext from '../../SellerListingCardsToolbarContext';
import { useViewTracking } from '@reverbdotcom/commons/src/use_tracking';
import { MParticleEventName, trackEvent } from '@reverbdotcom/commons/src/elog/mparticle_tracker';
import SellerListingsCollectionContext from '../../SellerListingsCollectionContext';

export const COMPONENT_NAME = 'BulkCreateDirectOffersModal';

const MINIMUM_PERCENT = 5;
const MAXIMUM_PERCENT = 100;

const BULK_CREATE_AUTO_OFFERS_MUTATION = gql(`
  mutation Core_MyListings_BulkCreateAutoOffers(
    $input: Input_core_apimessages_BulkCreateAutoOffersRequest
  ) {
    bulkCreateAutoOffers(input: $input) {
      responses {
        listingId
        success
        errors {
          message
        }
      }
    }
  }
`);

const BULK_CREATE_AUTO_OFFERS_ASYNC_MUTATION = gql(`
  mutation Core_MyListings_BulkCreateAutoOffersAsync(
    $input: Input_core_apimessages_BulkCreateAutoOffersAsyncRequest
  ) {
    bulkCreateAutoOffersAsync(input: $input) {
      enqueued
      message
    }
  }
`);

export default function BulkCreateDirectOffersModal({
  closeModal,
  selectedListings,
}) {
  const {
    listingsCollectionState,
  } = React.useContext(SellerListingsCollectionContext);

  const {
    hasAllMatchesSelected,
    totalMatchesEligibleForDirectOffers,
    totalMatchesEnabledForDirectOffers,
    serializedDashboardSearchParams,
  } = listingsCollectionState;

  const {
    handleBulkResponses,
    handleGenericError,
    handleAsyncBulkResponse,
  } = React.useContext(SellerListingCardsToolbarContext);

  const [percentOff, setPercentOff] = React.useState('');
  const [bulkCreateAutoOffers, {
    data,
    loading,
    errors,
  }] = useMutation(BULK_CREATE_AUTO_OFFERS_MUTATION);
  const hasErrors = !!errors.length;

  const [bulkCreateAutoOffersAsync, {
    data: asyncData,
    loading: asyncLoading,
    errors: asyncErrors,
  }] = useMutation(BULK_CREATE_AUTO_OFFERS_ASYNC_MUTATION);
  const hasAsyncErrors = !!asyncErrors.length;

  const eligibleSelectedListings = selectedListings.filter(listing => listing.isAutoOfferEligible && !listing.shipmentPackage);

  const totalListingsToUpdate = hasAllMatchesSelected ? (totalMatchesEligibleForDirectOffers + totalMatchesEnabledForDirectOffers) : eligibleSelectedListings.length;
  const hasNoListingsToUpdate = !totalListingsToUpdate;

  const percentage = Number(percentOff);
  const isInvalidPercentOff = percentage < MINIMUM_PERCENT || percentage >= MAXIMUM_PERCENT;
  const isSubmitDisabled = isInvalidPercentOff || hasNoListingsToUpdate;

  React.useEffect(() => {
    if (loading) return;
    if (hasErrors) return handleUnexpectedError();

    handleResponses();
  }, [loading]);

  React.useEffect(() => {
    if (asyncLoading) return;
    if (hasAsyncErrors) return handleUnexpectedError();

    handleAsyncResponse();
  }, [asyncLoading]);

  function handleResponses() {
    if (data?.bulkCreateAutoOffers) {
      const { responses } = data.bulkCreateAutoOffers;

      handleBulkResponses({
        responses,
        I18nSuccessMessageKey: 'discovery.listingsManagement.toolbar.bulkActions.directOffers.createModal.successes',
        I18nErrorMessageKey: 'discovery.listingsManagement.toolbar.bulkActions.directOffers.createModal.errors.title',
        successArgs: {
          percentOff,
        },
      });

      closeModal();
    }
  }

  function handleAsyncResponse() {
    if (asyncData?.bulkCreateAutoOffersAsync) {
      handleAsyncBulkResponse({
        I18nKey: 'discovery.listingsManagement.toolbar.bulkActions.directOffers.createModal.asyncResponses.successes',
        expectedTotal: totalListingsToUpdate,
        I18nArgs: {
          percentOff,
        },
      });

      closeModal();
    }
  }

  function handleUnexpectedError() {
    handleGenericError();
    closeModal();
  }

  function submit() {
    trackEvent({
      componentName: COMPONENT_NAME,
      eventName: MParticleEventName.SellerBulkCreatingDirectOffers,
      listingsCount: totalListingsToUpdate,
      hasAllMatchesSelected,
      discountPercent: percentage,
    });

    if (hasAllMatchesSelected) {
      bulkCreateAutoOffersAsync({
        variables: {
          input: {
            percentage,
            serializedDashboardSearchParams,
            expectedTotal: totalListingsToUpdate,
          },
        },
      });
    } else {
      const listingIds = eligibleSelectedListings.map(listing => listing.id);
      const percentage = Number(percentOff);

      bulkCreateAutoOffers({
        variables: {
          input: {
            listingIds,
            percentage,
          },
        },
      });
    }
  }

  useViewTracking({
    componentName: COMPONENT_NAME,
    eventName: MParticleEventName.ComponentView,
  }, true);

  return (
    <ModalDialog
      isOpen
      onRequestClose={closeModal}
      headerTitle={I18n.t('discovery.listingsManagement.toolbar.bulkActions.directOffers.enableDirectOffers')}
      saveButtonText={I18n.t('discovery.listingsManagement.toolbar.bulkActions.directOffers.enableDirectOffers')}
      isDisabled={isSubmitDisabled}
      isSaving={loading || asyncLoading}
      onSubmit={submit}
    >
      <div className="d-flex fx-dir-col gap-4">
        <div>
          {I18n.t('discovery.listingsManagement.toolbar.bulkActions.directOffers.createModal.buyerText.line1')}
        </div>

        {hasNoListingsToUpdate ? (
          <Translate
            html
            text="discovery.listingsManagement.toolbar.bulkActions.directOffers.createModal.noSelectedListingsEligible"
          />
        ) : (
          <>
            <Translate
              html
              text="discovery.listingsManagement.toolbar.bulkActions.directOffers.createModal.buyerText.line2"
              args={{
                count: totalListingsToUpdate,
                formattedCount: I18n.toNumber(totalListingsToUpdate, {
                  precision: 0,
                }),
              }}
            />

            <RCTextInput
              onChange={(e) => setPercentOff(e.target.value)}
              helpText={I18n.t('discovery.listingsManagement.toolbar.bulkActions.directOffers.createModal.input.helpText')}
              value={percentOff}
              label={I18n.t('discovery.listingsManagement.toolbar.bulkActions.directOffers.createModal.input.label')}
              type="number"
              placeholder={I18n.t('discovery.listingsManagement.toolbar.bulkActions.directOffers.createModal.input.placeholder')}
              suffixText="%"
            />
          </>
        )}
      </div>

      <div className="mt-4">
        <RCText
          size="350"
          color="rc-color-text-secondary"
          htmlTag="span"
        >
          {I18n.t('discovery.listingsManagement.toolbar.bulkActions.directOffers.createModal.ineligibleListingsText')}
        </RCText>
      </div>
    </ModalDialog>
  );
}
