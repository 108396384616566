import React from 'react';
import { useAdyenSCA } from './adyen_sca_hook';
import { PaymentAction } from '@adyen/adyen-web/dist/types/types';

interface Props {
  action: PaymentAction;
}

export default function SCARedirectShopper({ action }: Props) {
  useAdyenSCA({ selector: '[data-3ds-redirect-shopper]', action, onAdditionalDetails });

  return <div data-3ds-redirect-shopper />;
}

function onAdditionalDetails() {} // Additional details for the Redirect are handled in a browser POST
