import React from 'react';
import SanitizedRender from '@reverbdotcom/commons/src/components/sanitized_render';
import { CoreBumpRecommendationListingCard } from '@reverbdotcom/commons/src/gql/graphql';
import { MParticleEventName } from '@reverbdotcom/commons/src/elog/mparticle_types';
import { useViewTracking } from '@reverbdotcom/commons/src/use_tracking';
import { formatBid } from './bump_helpers';
import { RCAccordion, RCAlertBox, RCInfoBox } from '@reverbdotcom/cadence/components';
import { withPrefix } from '@reverbdotcom/commons/src/i18n_helpers';
import { REVERB_BUMP, useHelpCenterArticleUrl } from '@reverbdotcom/commons/src/url_helpers';
import { I18N as Translate } from '@reverbdotcom/commons/src/components/translate';
import CoreLink from '@reverbdotcom/commons/src/components/core_link';
import { RecommendedRadio } from './recommended_radio';

const COMPONENT_NAME = 'BumpModalBody';

interface Props {
  bid: number;
  handleBidChange: (bid: number) => void;
  listing: CoreBumpRecommendationListingCard.Fragment;
  recommendationAmount: number;
  selectedBid: number;
  successMessage: string;
  failMessage: string;
  updateBid: (
    bid: number,
    bumpRecommendationAmount: number,
    listing: CoreBumpRecommendationListingCard.Fragment,
    refetch: (newBid: number | null) => void,
  ) => void;
  refetch: (newBid: number | null) => void;
  onRequestClose?: Function;
  hideSubmit?: boolean;
  onSubmit: () => void;
  setShowCustomWarning: React.Dispatch<React.SetStateAction<boolean>>;
  showCustomWarning: boolean;
}

const t = withPrefix('discovery.bumpModal');

const BUMP_HELP_CENTER_ARTICLE_ID = '360007185794';

export function BumpModalBody(props: Props) {
  const mParticleViewData = {
    componentName: COMPONENT_NAME,
    eventName: MParticleEventName.ViewedBumpSlider,
    listing: props.listing,
    startingBumpRatePercent: formatBid(props.bid),
    bumpRecommendationPercent: props.recommendationAmount,
  };

  useViewTracking(mParticleViewData, true);

  function Messages() {
    return (
      <>
        {props.failMessage &&
          <div className="scaling-mb-4">
            <RCAlertBox type="error">
              <SanitizedRender html={props.failMessage} />
            </RCAlertBox>
          </div>
        }
        {props.successMessage &&
          <div className="scaling-mb-4">
            <RCAlertBox type="success">
              {props.successMessage}
            </RCAlertBox>
          </div>
        }
      </>
    );
  }

  return (
    <>
      <Messages />
      <div className="scaling-mb-4">
        <Translate text="discovery.bumpModal.overview">
          <b className="text-color-accent"><Translate text="discovery.bumpModal.reverbBump" /></b>
        </Translate>
      </div>
      <div className="scaling-mt-6">
        <div className="weight-bold size-90">
          {t('setRate')}
        </div>
        <div className="mb-1">
          {t('setRateHelp')}
        </div>
        <RecommendedRadio
          handleBidChange={props.handleBidChange}
          recommendationAmount={props.recommendationAmount}
          selectedBid={props.selectedBid}
          showCustomWarning={props.showCustomWarning}
          setShowCustomWarning={props.setShowCustomWarning}
          redesignEnabled
        />
      </div>
      <div className="mt-5">
        <RCInfoBox theme="gray" variant="subtle">
          <RCAccordion id="bump-how-it-works" size="small" heading={t('howItWorks')}>
            <p>{t('bumpDescription')}</p>
            <ol className="list-type-numeric">
              <li>
                <Translate text="discovery.bumpModal.bumpStep1">
                  <CoreLink target="_blank" to={useHelpCenterArticleUrl(BUMP_HELP_CENTER_ARTICLE_ID)}>
                    <Translate text="discovery.bumpModal.bumpStep1Link" />
                  </CoreLink>
                </Translate>
              </li>
              <li>
                <div>{t('bumpStep2')}</div>
                <div className="pl-1">
                  <ul className="list-type-disc">
                    <li>{t('bumpExample1')}</li>
                    <li>{t('bumpExample2')}</li>
                    <li>{t('bumpExample3')}</li>
                    <li>{t('bumpExample4')}</li>
                  </ul>
                </div>
              </li>
              <li>
                {t('bumpStep3')}
              </li>
            </ol>
            <div className="mt-3">
              <a target="_blank" rel="noreferrer" href={REVERB_BUMP}>
                {t('readMore')}
              </a>
            </div>
          </RCAccordion>
        </RCInfoBox>
      </div>
      <div className="scaling-mt-5 color-secondary size-90">
        {t('footnote')}
      </div>
      {!props.hideSubmit && (
        <div className="align-right scaling-mt-8">
          <button
            type="button"
            className="button button--primary size-90 weight-bold"
            disabled={props.showCustomWarning}
            onClick={props.onSubmit}
          >
            {t('save')}
          </button>
        </div>
      )}
    </>
  );
}
