interface IEmphasize {
  word: string;
  pre?: string;
  post?: string;
}

interface IOptions {
  input: string;
  output: string;
  emphasize: (idx: number, em: IEmphasize) => any;
  noop: (idx: number, em: IEmphasize) => any;
}

export default function lowlight(opts: IOptions) {
  const inputs = tokenize(opts.input).map(v => v.toLowerCase());
  const words = tokenize(opts.output);

  return words.map((word, idx) => {
    const lword = word.toLowerCase();
    const matched = inputs.find(input => lword.includes(input));

    if (matched) {
      const em = emphasize(matched, word);

      return opts.emphasize(idx, em);
    }

    return opts.noop(idx, { word });
  });
}

function emphasize(matched, word: string): IEmphasize {
  const start = word.toLowerCase().indexOf(matched);
  const offset = start + matched.length;

  const pre = word.slice(0, start);
  const low = word.slice(start, offset);
  const post = word.slice(offset, word.length);

  return { pre, post, word: low };
}

function tokenize(text: string): string[] {
  return text.split(' ')
    .filter(v => v !== '')
    .map(v => v.trim());
}
