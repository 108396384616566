import { pick } from 'lodash';
import { IState } from './auto_offer_modal';

export function formReducer(state, action): IState {
  switch (action.type) {
    case 'prefillFormState':
      return {
        ...state,
        autoOfferIsActive: true,
        messageToBuyer: action.value.listing.autoOffers.message,
        percentage: action.value.listing.autoOffers.percentage,
        shippingPriceOverride: action.value.listing.autoOffers.shippingPriceOverride ? pick(action.value.listing.autoOffers.shippingPriceOverride, ['amount']) : undefined,
      };
    case 'toggleOfferActive':
      return { ...state, autoOfferIsActive: !state.autoOfferIsActive };
    case 'setPercentage':
      return { ...state, percentage: parseInt(action.value, 10) };
    case 'setMessageToBuyer':
      return { ...state, messageToBuyer: action.value };
    case 'setSubmitFailedMessage':
      return { ...state, submitFailedMessage: action.value };
    case 'setShippingPriceOverride':
      return { ...state, shippingPriceOverride: { amount: action.value } };
    default:
      return { ...state };
  }
}
