import React from 'react';
import classNames from 'classnames';

import MarkdownOutput from './markdown_output';

export interface IProps {
  title?: string;
  titleHtml?: string;
  content?: string;
  contentHtml?: string;
  truncationHeight?: number;
  setJumplinks?: Function;
  name?: string;
  jumplinkSlug?: string;
  withWrapper?: boolean;
}

export default class SeoBlurb extends React.Component<IProps, null> {
  componentDidMount() {
    this.updateJumplinks();
  }

  componentDidUpdate(prevProps) {
    if (this.props.content === prevProps.content) return;
    this.updateJumplinks();
  }

  updateJumplinks() {
    if (!this.props.setJumplinks) return;

    this.props.setJumplinks({
      [this.props.name]: !!this.props.content,
    });
  }

  render() {
    if (!this.props.content && !this.props.contentHtml) return null;
    const classes = classNames(
      'seo-blurb',
      { 'seo-blurb--with-wrapper': this.props.withWrapper },
    );

    return (
      <div
        className={classes}
        id={this.props.jumplinkSlug || this.props.name}
      >
        <div className="seo-blurb__inner">
          {(!!this.props.title || !!this.props.titleHtml) &&
            <MarkdownOutput
              htmlTag="h3"
              className="seo-blurb__title"
              text={this.props.title}
              textHtml={this.props.titleHtml}
            />
          }
          <MarkdownOutput
            htmlTag="div"
            className="cms-text"
            text={this.props.content}
            textHtml={this.props.contentHtml}
            truncationHeight={this.props.truncationHeight}
          />
        </div>
      </div>
    );
  }
}
