import React from 'react';
import SanitizedRender from '@reverbdotcom/commons/src/components/sanitized_render';
import UspsLogo from '@reverbdotcom/cadence/images/vendor/usps-logo.svg';
import UpsLogo from '@reverbdotcom/cadence/images/vendor/ups-logo.svg';
import I18n from 'i18n-js';

export function SelectedCarrier({ carrier }: { carrier: string }) {
  if (carrier !== 'USPS' && carrier !== 'UPS') {
    return null;
  }

  const logo = carrier === 'USPS' ? UspsLogo : UpsLogo;
  const translationKey = carrier === 'USPS' ? 'shipsWithUsps' : 'shipsWithUps';
  const logoClassName = carrier === 'USPS' ? 'selected-carrier__logo-container-usps' : 'selected-carrier__logo-container-ups';

  return (
    <div className="selected-carrier">
      <img src={logo} alt={carrier}  className={logoClassName}/>
      <div className="selected-carrier__text">
        <SanitizedRender
          html={I18n.t(`discovery.sellForm.shippingSection.domesticShippingCard.carrierCalculatedIndicator.${translationKey}`)}
        />
      </div>
    </div>
  );
}
