import React from 'react';
import I18n from 'i18n-js';

import { ErrorAlertBox } from '@reverbdotcom/commons/src/ErrorAlertBox';
import { useUser } from '@reverbdotcom/commons/src/user_hooks';
import { inMobileBrowser } from '@reverbdotcom/commons/src/user_context_helpers';

import { useApplePayAvailabilityByConfig } from './useApplePayAvailability';
import { PaypalCheckoutEligibilityAttributes, useExpressPayPalAvailability, usePayPalPayLaterButtonAvailability } from './useExpressPayPal';
import { CartBundleExpressApplePayButton } from './CartBundleExpressApplePayButton';
import { ExpressPayPalButtons } from './checkout/ExpressPayPalButtons';
import {
  core_apimessages_Money as Money,
  core_apimessages_CheckoutPaymentMethod_Type as CheckoutPaymentMethodType,
} from '@reverbdotcom/commons/src/gql/graphql';

interface Props {
  paymentMethodType: CheckoutPaymentMethodType;
  expressPaypalCurrency: string;
  checkoutBundlingId: string;
  amountTotal: Money;
  adyenCheckoutPaymentMethodsConfig: string;
  shopCountryCode: string;
  paypalCheckoutEligibilityAttributes: PaypalCheckoutEligibilityAttributes;
}

export default function CartFooterExpressPayWrapper({
  paymentMethodType,
  expressPaypalCurrency,
  checkoutBundlingId,
  amountTotal,
  adyenCheckoutPaymentMethodsConfig,
  paypalCheckoutEligibilityAttributes,
  shopCountryCode,
}: Props) {
  const user = useUser();
  const [errors, setErrors] = React.useState([]);

  const isDirectCheckout = paymentMethodType === CheckoutPaymentMethodType.DIRECT_CHECKOUT;
  const [isApplePayAvailable] = useApplePayAvailabilityByConfig(adyenCheckoutPaymentMethodsConfig);
  const showApplePayButton = isApplePayAvailable && isDirectCheckout;

  const isExpressPayPalAvailable = useExpressPayPalAvailability({ paypalCheckoutEligibilityAttributes });

  const isPaypalPayLaterAvailable = usePayPalPayLaterButtonAvailability({
    currency: amountTotal.currency,
    amountCents: amountTotal.amountCents,
    shopCountryCode,
  });

  const showExpressPay = !inMobileBrowser(user) && showApplePayButton && isExpressPayPalAvailable;

  if (!showExpressPay) {
    return null;
  }

  return (
    <>
      {errors.length > 0 &&
        <div className="mtb-4">
          <ErrorAlertBox errors={errors} />
        </div>
      }
      <div className="cart-footer-express-pay-wrapper">
        <div className="weight-bold fx-align-self-center ml-2">{I18n.t('discovery.checkout.expressCheckout.title')}</div>
        <div className="d-flex mr-4 cart-footer-express-pay-wrapper-buttons">
          <div className="checkout-cart-footer__button checkout-cart-footer__button--paypal">
            {isExpressPayPalAvailable &&
              <ExpressPayPalButtons
                currency={expressPaypalCurrency}
                checkoutBundlingId={checkoutBundlingId}
                onError={setErrors}
                parentComponentName="CartFooterExpressPayWrapper"
                amountCents={amountTotal.amountCents}
                showPaypalPayLaterButton={isPaypalPayLaterAvailable}
              />
            }
          </div>
          {isDirectCheckout &&
            <div className="checkout-cart-footer__button checkout-cart-footer__button--apple-pay">
              <CartBundleExpressApplePayButton
                variant="white-outlined-express"
                checkoutBundlingId={checkoutBundlingId}
                amountTotal={amountTotal}
                adyenCheckoutPaymentMethodsConfig={adyenCheckoutPaymentMethodsConfig}
                tracking={{
                  displayStyle: 'default',
                }}
              />
            </div>
          }
        </div>
      </div>
    </>
  );
}
