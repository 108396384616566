import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';

const ErrorMessage = (props) => {
  const message = props.message || I18n.t('js.errorMessages.default');
  const messageWithLinks = props.messageWithLinks;
  const alertStyle = props.alertStyle || 'alert-info';

  if (messageWithLinks) {
    return (
      <p
        className={classNames('alert', alertStyle)}
        dangerouslySetInnerHTML={{ __html: messageWithLinks }}
      />
    );
  }

  return (
    <p
      className={classNames('alert', alertStyle)}
    >
      {message}
    </p>
  );
};

ErrorMessage.propTypes = {
  message: PropTypes.string,
  alertStyle: PropTypes.string,
};

export default ErrorMessage;
