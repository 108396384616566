import React from 'react';
import AddressSummary from '../AddressSummary';
import { RCButton } from '@reverbdotcom/cadence/components';
import { I18N as Translate } from '@reverbdotcom/commons/src/components/translate';
import InternationalTaxProfileMainFormSection from './international_tax_profile_main_form_section';
import TaxIdentificationsSection from './tax_identifications_section';
import {
  core_apimessages_BusinessRegistrationNumber,
  core_apimessages_InternationalTaxProfile,
  core_apimessages_TaxIdentification,
  core_apimessages_TaxIdentification_TaxType,
} from '@reverbdotcom/commons/src/gql/graphql';
import {
  INDIVIDUAL_TYPE,
  BUSINESS_TYPE,
} from './international_tax_profile_constants';

interface IProps {
  businessRegistrationNumber: core_apimessages_BusinessRegistrationNumber;
  displayAdditionalNumbers: boolean;
  internationalTaxProfile: core_apimessages_InternationalTaxProfile;
  switchToEditMode: () => void;
  helpCenterArticleId?: string;
  taxIdentifications: core_apimessages_TaxIdentification[];
  taxIdentificationType: core_apimessages_TaxIdentification_TaxType;
}

export function InternationalTaxProfile({
  businessRegistrationNumber,
  displayAdditionalNumbers,
  internationalTaxProfile,
  switchToEditMode,
  helpCenterArticleId,
  taxIdentifications,
  taxIdentificationType,
}: IProps) {
  return (
    <>
      <InternationalTaxProfileMainFormSection helpCenterArticleId={helpCenterArticleId}>
        <Translate
          tag="div"
          text={`discovery.internationalTaxProfile.name.${internationalTaxProfile.tinType}`}
          className="weight-bold"
        />
        <p>{internationalTaxProfile.name}</p>

        <Translate
          tag="div"
          text="discovery.internationalTaxProfile.labels.tin"
          className="weight-bold"
        />
        <p>{internationalTaxProfile.tinMask}</p>

        <Translate
          tag="div"
          text="discovery.internationalTaxProfile.labels.tinType"
          className="weight-bold"
        />
        <Translate
          tag="p"
          text={`discovery.internationalTaxProfile.tinType.${internationalTaxProfile.tinType}`}
        />

        { internationalTaxProfile.tinType == INDIVIDUAL_TYPE && (
          <>
            <Translate
              tag="div"
              text="discovery.internationalTaxProfile.labels.dateOfBirth"
              className="weight-bold"
            />
            <p>{internationalTaxProfile.dateOfBirth}</p>
          </>
        )}

        { internationalTaxProfile.tinType == BUSINESS_TYPE && displayAdditionalNumbers && (
          <>
            <Translate
              tag="div"
              text="discovery.internationalTaxProfile.labels.businessRegistrationNumber"
              className="weight-bold"
            />
            { businessRegistrationNumber?.registrationNumber ? (
              <p>{businessRegistrationNumber.registrationNumber} ({businessRegistrationNumber.countryCode})</p>
            ) : (
              <Translate
                tag="p"
                text="discovery.internationalTaxProfile.missingBusinessRegistrationNumber"
              />
            )}
          </>
        )}

        {displayAdditionalNumbers && (<>
          <Translate
            tag="div"
            className="weight-bold"
            text={`discovery.internationalTaxProfile.labels.taxIdentifications.${taxIdentificationType}`}
          />
          <TaxIdentificationsSection
            taxIdentifications={taxIdentifications}
            taxIdentificationType={taxIdentificationType}
          />
        </>)}

        <Translate
          tag="div"
          text="discovery.internationalTaxProfile.labels.address"
          className="weight-bold"
        />
        <AddressSummary
          address={internationalTaxProfile.address}
        />
      </InternationalTaxProfileMainFormSection>
      <div className="scaling-mt-4 d-flex fx-align-center fx-justify-center">
        <RCButton
          onClick={switchToEditMode}
        >
          <Translate text="discovery.internationalTaxProfile.editTaxInformation" />
        </RCButton>
      </div>
    </>
  );
}

export default InternationalTaxProfile;
