import React from 'react';
import I18n from 'i18n-js';
import classNames from 'classnames';
import { IUserType, OTHER_REASON, ViewContext } from './refund_request';

interface IProps {
  refundReason: string;
  userType: IUserType;
  note: string;
  onNoteChange: (event: any) => void;
  viewContext: ViewContext;
}

const NoteToOtherParty: React.StatelessComponent<IProps> = (props) => {
  function otherParty() {
    if (props.userType === IUserType.BUYER) {
      return IUserType.SELLER;
    }

    return IUserType.BUYER;
  }

  function isNoteRequired() {
    if (props.viewContext === ViewContext.CONFIRM) {
      return false;
    }

    if (props.userType === IUserType.BUYER) {
      return true;
    }

    return props.refundReason === OTHER_REASON;
  }

  function noteClassNames() {
    return classNames({
      wide: true,
      required: isNoteRequired(),
    });
  }

  function refundNoteLabelHintText() {
    if (isNoteRequired()) {
      return I18n.t('discovery.dashboard.refundRequests.required');
    }

    return I18n.t('discovery.dashboard.refundRequests.optional');
  }

  function noteToOtherParty() {
    if (props.userType === IUserType.BUYER) {
      return I18n.t('discovery.dashboard.refundRequests.noteToSeller');
    }

    return I18n.t('discovery.dashboard.refundRequests.noteToBuyer');
  }

  function renderReturnAddressHint() {
    if (props.userType === IUserType.BUYER) {
      return false;
    }

    return (
      <div className="form-group__help-text">
        {I18n.t('discovery.dashboard.refundRequests.returnAddressHint')}
      </div>
    );
  }

  function inputName() {
    if (props.viewContext === ViewContext.CONFIRM) {
      return 'order_refund_request[final_note_to_buyer]';
    }

    return `order_refund_request[note_to_${otherParty()}]`;
  }

  function inputId() {
    if (props.viewContext === ViewContext.CONFIRM) {
      return 'order_refund_request_final_note_to_buyer';
    }

    return `order_refund_request_note_to_${otherParty()}`;
  }

  return (
    <div className="form-group">
      <div className="form-group__header">
        <label className="form-group__label-text" htmlFor={`order_refund_request_note_to_${otherParty()}`}>
          {noteToOtherParty()}
        </label>
        <div className="form-group__tag">
          {refundNoteLabelHintText()}
        </div>
      </div>
      <div className="form-group__fields">
        <textarea
          id={inputId()}
          name={inputName()}
          className={noteClassNames()}
          cols={50}
          rows={2}
          required={isNoteRequired()}
          value={props.note}
          onChange={props.onNoteChange}
        />
        {renderReturnAddressHint()}
      </div>
    </div>
  );
};

export default NoteToOtherParty;
