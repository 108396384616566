import { ComponentClass } from 'react';

import CmsAdImageFeature from './components/cms_ad_image_feature';
import ArticleCollection, { IProps as ArticleProps } from './components/article_collection';
import BrandCollection, { IProps as BrandProps } from './components/brand_collection';
import CallToAction from './components/call_to_action';
import CategoryCollection, { IProps as CategoryCollectionProps } from './components/category_collection';
import CategorySearch from './components/category_search';
import CmsPageCards, { Props as CmsPageCardsProps } from './components/cms_page_cards';
import CspCollection, { IProps as CSPCollectionProps } from './components/csp_collection';
import CspFeature from './components/csp_feature';
import CspTiles from './components/csp_tiles';
import CuratedSetCollection, { IProps as CuratedSetCollectionProps } from './components/curated_set_collection';
import NewsletterSignupModule from '../components/newsletter_signup_module';
import FullBleedHeading from './components/full_bleed_heading';
import Heading from './components/heading';
import ImageEmbed from './components/image_embed';
import ImageGallery from './components/image_gallery';
import ImageFeature from './components/image_feature';
import Jumplinks from '../components/jumplinks';
import LinkList from './components/link_list';
import CMSListingsCollection from './components/cms_listings_collection';
import PersonalizedListingsCollection from './components/personalized_listings_collection';
import MarkdownSectionHTML from './components/markdown_section_html';
import { MarketingCallout } from './components/marketing_callout';
import Pullquote from './components/pullquote';
import ReviewList from './components/review_list';
import SeoBlurb from './components/seo_blurb';
import SiteBannerHero from './components/site_banner_hero';
import RecentlyViewedListings, { IProps as RecentlyViewedListingsProps } from './components/recently_viewed_listings';
import SubcategoryNav, { IProps as SubcategoryNavProps } from './components/subcategory_nav';
import ToggledSiteBanner from './components/toggled_site_banner';
import VideoEmbed from './components/video_embed';

import * as COMPONENT_TYPES from './component_types';
import { TraitCollection } from './components/trait_collection';
import { FaqSection } from './components/faq_section';

export const COMPONENT_OUTPUT = Object.freeze({
  [COMPONENT_TYPES.AD_IMAGE_FEATURE]: CmsAdImageFeature,
  [COMPONENT_TYPES.ARTICLE_SECTION]: ArticleCollection as ComponentClass<ArticleProps>,
  [COMPONENT_TYPES.BRAND_COLLECTION]: BrandCollection as ComponentClass<BrandProps>,
  [COMPONENT_TYPES.CALL_TO_ACTION]: CallToAction,
  [COMPONENT_TYPES.CATEGORY_COLLECTION]: CategoryCollection as ComponentClass<CategoryCollectionProps>,
  [COMPONENT_TYPES.CATEGORY_SEARCH]: CategorySearch,
  [COMPONENT_TYPES.CMS_PAGE_CARDS]: CmsPageCards as ComponentClass<CmsPageCardsProps>,
  [COMPONENT_TYPES.CSP_COLLECTION]: CspCollection as ComponentClass<CSPCollectionProps>,
  [COMPONENT_TYPES.CSP_FEATURE]: CspFeature,
  [COMPONENT_TYPES.CSP_TILES]: CspTiles,
  [COMPONENT_TYPES.CURATED_SET_COLLECTION]: CuratedSetCollection as ComponentClass<CuratedSetCollectionProps>,
  [COMPONENT_TYPES.EMAIL_SIGNUP_FORM]: NewsletterSignupModule,
  [COMPONENT_TYPES.FAQ_SECTION]: FaqSection,
  [COMPONENT_TYPES.FULL_BLEED_HEADING]: FullBleedHeading,
  [COMPONENT_TYPES.HEADING]: Heading,
  [COMPONENT_TYPES.IMAGE_EMBED]: ImageEmbed,
  [COMPONENT_TYPES.IMAGE_FEATURE]: ImageFeature,
  [COMPONENT_TYPES.IMAGE_GALLERY]: ImageGallery,
  [COMPONENT_TYPES.JUMPLINK_LOCATION]: Jumplinks,
  [COMPONENT_TYPES.LINK_LIST]: LinkList,
  [COMPONENT_TYPES.LISTINGS_COLLECTION]: CMSListingsCollection,
  [COMPONENT_TYPES.PERSONALIZED_LISTINGS_COLLECTION]: PersonalizedListingsCollection,
  [COMPONENT_TYPES.MARKDOWN_TO_HTML_SECTION]: MarkdownSectionHTML,
  [COMPONENT_TYPES.MARKETING_CALLOUT]: MarketingCallout,
  [COMPONENT_TYPES.PULLQUOTE]: Pullquote,
  [COMPONENT_TYPES.RECENTLY_VIEWED_LISTINGS]: RecentlyViewedListings as ComponentClass<RecentlyViewedListingsProps>,
  [COMPONENT_TYPES.REVIEW_LIST]: ReviewList,
  [COMPONENT_TYPES.SEO_BLURB]: SeoBlurb,
  [COMPONENT_TYPES.SITE_BANNER_HERO]: SiteBannerHero,
  [COMPONENT_TYPES.SUBCATEGORY_NAV]: SubcategoryNav as ComponentClass<SubcategoryNavProps>,
  [COMPONENT_TYPES.TOGGLED_SITE_BANNER]: ToggledSiteBanner,
  [COMPONENT_TYPES.TRAIT_COLLECTION]: TraitCollection,
  [COMPONENT_TYPES.VIDEO_EMBED]: VideoEmbed,
});
