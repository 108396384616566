import React from 'react';
import CoreLink from '@reverbdotcom/commons/src/components/core_link';
import { RCAlertBox } from '@reverbdotcom/cadence/components';
import { I18N as Translate } from '@reverbdotcom/commons/src/components/translate';
import { isExperimentEnabled } from '@reverbdotcom/commons/src/user_context_helpers';
import { IUserContext, withUserContext } from '@reverbdotcom/commons';
import experiments from '@reverbdotcom/commons/src/experiments';
import SignupSigninModal from '@reverbdotcom/commons/src/components/signup_signin_modal';

function GiftCardsUnderConstruction({ user }: IUserContext) {

  const [isSignupSigninModalOpen, setIsSignupSigninModalOpen] = React.useState(false);

  const displayUserLoginPrompt = () => {
    return isExperimentEnabled(user, experiments.DISPLAY_USERS_LOGIN_REQUIRED_FOR_GIFT_CARDS);
  };

  const openSignInModal = () => {
    setIsSignupSigninModalOpen(true);
  };

  return (
    <div className="mt-4 mb-4">
      { !displayUserLoginPrompt() &&
        <>
          <RCAlertBox
            type="warning"
            headerText={<Translate text="discovery.giftCardsUnderConstruction.unavailableTitle" />}
          >
            <Translate
              tag="p"
              text="discovery.giftCardsUnderConstruction.unavailableMessageHtml"
            >
              <CoreLink to="https://reverb.com/news/last-minute-gifts-musicians-will-actually-love">
                <Translate text="discovery.giftCardsUnderConstruction.giftIdeasLinkText" />
              </CoreLink>
            </Translate>
          </RCAlertBox>

          <div className="mt-4">
            <RCAlertBox
              type="info"
              headerText={<Translate text="discovery.giftCardsUnderConstruction.redeemTitle" />}
            >
              <Translate
                tag="p"
                text="discovery.giftCardsUnderConstruction.redeemHtml"
              >
                <CoreLink to="/redeem">
                  <Translate text="discovery.giftCardsUnderConstruction.redeemLinkText" />
                </CoreLink>
              </Translate>
            </RCAlertBox>
          </div>
        </>
      }
      { displayUserLoginPrompt() &&
        <>
          <SignupSigninModal
            onRequestClose={() => setIsSignupSigninModalOpen(false)}
            active={isSignupSigninModalOpen}
            trackSource="GiftCards"
          />
          <div className="mt-4">
            <RCAlertBox
              type="info"
              headerText={<Translate text="discovery.giftCardsUnderConstruction.loginPromptTitle" />}
            >
              <Translate
                tag="p"
                text="discovery.giftCardsUnderConstruction.loginPromptHtml"
              >
                <button className="text-link button-as-link" onClick={openSignInModal}>
                  <Translate text="discovery.giftCardsUnderConstruction.loginLinkText" />
                </button>
              </Translate>
            </RCAlertBox>
          </div>
        </>
      }
    </div>
  );
}

export default withUserContext(GiftCardsUnderConstruction);
