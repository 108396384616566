import React from 'react';
import {
  trackEvent,
  MParticleEventName,
} from '@reverbdotcom/commons/src/elog/mparticle_tracker';
import CoreLink from '@reverbdotcom/commons/src/components/core_link';
import { getItem } from '@reverbdotcom/commons/src/local_storage_utils';
import { CORE_WEB_ORIGIN } from '@reverbdotcom/env';
import { LAST_SEARCH_URL } from './marketplace/marketplace_page';

const COMPONENT_NAME = 'KeepShoppingLink';

interface IProps {
  class: string;
  ctaText: string;
  lastSearchUrl: string;
}

export default function KeepShopping(props: IProps) {
  trackEvent({
    eventName: MParticleEventName.ViewedKeepShoppingLink,
    componentName: COMPONENT_NAME,
  });

  const lastSearchURL = getItem(LAST_SEARCH_URL) || CORE_WEB_ORIGIN;

  return (
    <CoreLink
      className={props.class}
      to={lastSearchURL}
      clickEvent={{
        eventName: MParticleEventName.ClickedKeepShoppingLink,
        componentName: COMPONENT_NAME,
      }}
    >
      {props.ctaText}
    </CoreLink>
  );
}
