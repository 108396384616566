import React from 'react';
import moment from 'moment';
import formatAmount from '../shared/format_amount';

class Order extends React.Component {
  detailsLink = content => (
    <a href={this.props.order._links.web.href}>{content}</a>
  );

  formattedTotal = () => {
    if (this.props.order.total) {
      return formatAmount(this.props.order.total);
    }
    return formatAmount(this.props.order.amount_product_subtotal);
  };

  formattedCreatedAt = () => moment(this.props.order.created_at).fromNow();

  orderState = () => this.props.order.actionable_status;

  formattedState = () => this.orderState().replace(/_/g, ' ');

  orderStateIndicatorClass = () => {
    if (this.props.negativeStates.indexOf(this.orderState()) !== -1) {
      return 'important';
    } if (this.props.neutralStates.indexOf(this.orderState()) !== -1) {
      return '';
    }
    return 'success';
  };

  render() {
    return (
      <li className="previous-order dark-links has-divider">
        {this.detailsLink(<div className="g-container">
          <div className="g-col-3">
            <img className="img-wide bd-radius-primary" src={this.props.order._links.photo.href} />
          </div>
          <div className="g-col-9 strong">
            <div className="g-container">
              <div className="g-col-8">
                <div className="truncate color-primary">{this.props.order.title}</div>
              </div>
              <div className="g-col-4">
                <div className="color-orange align-right">{this.formattedTotal()}</div>
              </div>
            </div>
            <div className="muted fine-print normal sentence-capitalization">{this.formattedCreatedAt()}</div>
            <div className={`inline-block badge tiny capitalize ${this.orderStateIndicatorClass()}`}>{this.formattedState()}</div>
          </div>
        </div>)}
      </li>
    );
  }
}

export default Order;
