import React from 'react';
import classNames from 'classnames';
import I18n from 'i18n-js';

import CoreLink from './core_link';
import { get } from 'lodash';

interface IProps {
  article: any;
  renderForSidebar?: boolean;
  cardStyle?: string;
}

function Placeholder({ renderForSidebar }) {
  const classes = classNames(
    'article-collection-card',
    'article-collection-card--placeholder',
    { 'article-collection-card--sidebar': renderForSidebar },
  );

  return (
    <div className={classes}>
      <div className="article-collection-card__inner">
        <div className="article-collection-card__image-wrapper">
          <div className="article-collection-card__image"/>
        </div>
        <div className="article-collection-card__content">
          <div className="article-collection-card__primary">
            <div className="article-collection-card__title" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default class ArticleCollectionCard extends React.Component<IProps, null> {
  getCategoryName(article) {
    return get(article, 'articleCategories[0].name') ||
      get(article, 'categories[0].name');
  }

  getURL(article) {
    return get(article, 'webLink.href') ||
      get(article, '_links.web.href');
  }

  getImageURL(article) {
    return get(article, 'images[0].source') ||
      get(article, 'photo');
  }

  getBackgroundImageStyle(article) {
    const src = this.getImageURL(article);
    if (!src) return null;

    return {
      backgroundImage: `url(${src})`,
      // this should be in image-box by default but isn't yet
      backgroundSize: 'cover',
    };
  }

  renderSponsoredContent() {
    if (!this.props.article.partnership_name) return null;

    return (
      <div className="article-collection-card__sponsored_content">
        {I18n.t('commons.sponsoredContentPartner', { partner: this.props.article.partnership_name })}
      </div>
    );
  }

  render() {
    const { article, renderForSidebar } = this.props;
    if (!article) {
      return <Placeholder renderForSidebar={renderForSidebar} />;
    }

    const classes = classNames(
      'article-collection-card',
      { 'article-collection-card--large': this.props.cardStyle === 'large' },
      { 'article-collection-card--sidebar': renderForSidebar },
    );

    return (
      <CoreLink
        className={classes}
        to={this.getURL(article)}
        entityId={article.id}
      >
        <div className="article-collection-card__inner">
          <div className="article-collection-card__image-wrapper">
            <div
              className="article-collection-card__image"
              style={this.getBackgroundImageStyle(article)}
            />
          </div>
          <div className="article-collection-card__content">
            <div className="article-collection-card__primary">
              <h4 className="article-collection-card__title">
                {article.title}
              </h4>
              {this.renderSponsoredContent()}
            </div>
            <div className="article-collection-card__meta">
              <div className="article-collection-card__category">
                {this.getCategoryName(article)}
              </div>
            </div>
          </div>
        </div>
      </CoreLink>
    );
  }
}
