import { ChildProps } from '@apollo/client/react/hoc';
// TODO update this to import from commons
// import { gql } from '@reverbdotcom/commons/src/gql';
// eslint-disable-next-line no-restricted-imports
import { gql } from '@apollo/client';
import React from 'react';
import { loadAdaChat, onVerificationPage } from './ada_chat';
import { CoreAdaChatTokenQuery } from '@reverbdotcom/commons/src/gql/graphql';
import { useUser } from '@reverbdotcom/commons/src/user_hooks';
import { RCLoadingBars } from '@reverbdotcom/cadence/components';
import I18n from 'i18n-js';
import { IUser } from '@reverbdotcom/commons';
import { MParticleEventName } from '@reverbdotcom/commons/src/elog/mparticle_types';
import { useViewTracking } from '@reverbdotcom/commons/src/use_tracking';
import { withGraphql } from '@reverbdotcom/commons/src/with_graphql';

const TOKEN_QUERY = gql`
  query Core_AdaChat_TokenQuery {
    me {
      _id
      id
      uuid
      adaChatToken {
        token
      }
    }
  }
`;

interface AdaChatTokenContainerProps {
  fetch: boolean;
  doneLoading: () => void;
}

function AdaChatTokenContainer({ data, doneLoading }: ChildProps<AdaChatTokenContainerProps, CoreAdaChatTokenQuery.Query>) {
  const user = useUser();

  if (data?.loading) {
    return null;
  }

  return (
    <AdaChatToken
      user={user}
      token={data?.me?.adaChatToken?.token}
      doneLoading={doneLoading}
    />
  );
}

interface AdaChatTokenProps {
  user: Partial<IUser>;
  token: string;
  doneLoading: () => void;
}

function AdaChatToken({ user, token, doneLoading }: AdaChatTokenProps) {
  React.useEffect(() => {
    // token may not be null for users not logged in
    loadAdaChat(token, user);
    doneLoading();
  }, []); // left empty so that Ada chat is only loaded once.

  return (
    <></>
  );
}

const connect = withGraphql<AdaChatTokenContainerProps, CoreAdaChatTokenQuery.Query>(
  TOKEN_QUERY,
  {
    options: {
      ssr: false, // chat button can render client side
    },
    skip: (props) => !props.fetch,
  },
);

const Query = connect(AdaChatTokenContainer);

/**
 * Allows for Ada chat widget to _only_ be loaded after button click
 */
export function AdaChatButton() {
  const [clicked, setClicked] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const doneLoading = () => {
    setLoading(false);
  };

  React.useEffect(() => {
    if (onVerificationPage()) {
      setClicked(true);
      setLoading(true);
    }
  }, []);

  useViewTracking({ eventName: MParticleEventName.AdaChatButtonClicked }, clicked);

  return (
    <div
      className="chat-widget">
      {!clicked &&
      <button
        className="chat-widget__button"
        onClick={() => {
          setClicked(true);
          setLoading(true);
        }}
        type="button">
        <div
          className="chat-widget__icon">
          <AdaChatIcon />
        </div>
        <span className="chat-widget__label">
          {I18n.t('js.adaChat.buttonText')}
        </span>
      </button>
      }
      {loading && <RCLoadingBars /> }
      {clicked && <Query fetch={clicked} doneLoading={doneLoading} /> }
    </div>
  );
}

// Chat Icon which will be displayed in place of Button
const AdaChatIcon = () => {
  /* eslint-disable max-len */
  return (
    <svg viewBox="0 0 15 16">
      <path
        d="M1.3,16c-0.7,0-1.1-0.3-1.2-0.8c-0.3-0.8,0.5-1.3,0.8-1.5c0.6-0.4,0.9-0.7,1-1c0-0.2-0.1-0.4-0.3-0.7c0,0,0-0.1-0.1-0.1 C0.5,10.6,0,9,0,7.4C0,3.3,3.4,0,7.5,0C11.6,0,15,3.3,15,7.4s-3.4,7.4-7.5,7.4c-0.5,0-1-0.1-1.5-0.2C3.4,15.9,1.5,16,1.5,16 C1.4,16,1.4,16,1.3,16z M3.3,10.9c0.5,0.7,0.7,1.5,0.6,2.2c0,0.1-0.1,0.3-0.1,0.4c0.5-0.2,1-0.4,1.6-0.7c0.2-0.1,0.4-0.2,0.6-0.1 c0,0,0.1,0,0.1,0c0.4,0.1,0.9,0.2,1.4,0.2c3,0,5.5-2.4,5.5-5.4S10.5,2,7.5,2C4.5,2,2,4.4,2,7.4c0,1.2,0.4,2.4,1.2,3.3 C3.2,10.8,3.3,10.8,3.3,10.9z" />
    </svg>
  );
  /* eslint-enable max-len */
};

export default AdaChatButton;
