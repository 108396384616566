import I18n from 'i18n-js';
import { reject } from 'lodash';
import React from 'react';

import { SUPPORTED_REGIONAL_SHIPPING_REGION_CODES } from '@reverbdotcom/commons/src/constants';
import { ShippingRegionSelector, IShippingRegion, ILegacyApiShippingRegion } from '@reverbdotcom/commons/src/shipping/shipping_region_selector';
import { RCTag } from '@reverbdotcom/cadence/components';
import FormSection from '@reverbdotcom/commons/src/components/form_section';

import { ShippingProfileRateCard } from './shipping_profile_rate_card';
import { IShippingRate } from '../shared/edit_shipping_rate_card_manual_prices';

interface IProps {
  currency: string;
  shippingRates: IShippingRate[];
  allowedToShipExpedited: boolean;
  allowedToSetRegionalShipping: boolean;
  shippingRegionOptions: ILegacyApiShippingRegion[];
}

function handleRemoveShippingRate(shippingRateToRemove, currentShippingRates, setShippingRates) {
  const updatedShippingRates = reject(currentShippingRates, (sr) => sr.regionCode === shippingRateToRemove.regionCode);

  setShippingRates(updatedShippingRates);
}

function handleEditShippingRate(updatedShippingRate, currentShippingRates, setShippingRates) {
  const targetShippingRateIndex = currentShippingRates.findIndex((sr) => sr.regionCode === updatedShippingRate.regionCode);

  const updatedShippingRates = currentShippingRates.slice();
  updatedShippingRates[targetShippingRateIndex] = updatedShippingRate;

  setShippingRates(updatedShippingRates);
}

function buildBlankShippingRate(newShippingRegion: IShippingRegion): IShippingRate {
  const { regionType, regionCode, regionName } = newShippingRegion;

  return {
    isRequired: false,
    regionType,
    regionCode,
    regionName,
    rate: null,
    expeditedRate: null,
    incrementalRate: null,
  };
}

export function ShippingProfileForm(props: IProps) {
  const [newShippingRegion, setNewShippingRegion] = React.useState<IShippingRegion>({});
  const [shippingRates, setShippingRates] = React.useState<IShippingRate[]>(props.shippingRates);

  function allowedRegionalShipping(shippingRate) {
    return props.allowedToSetRegionalShipping &&
      shippingRate.isRequired &&
      SUPPORTED_REGIONAL_SHIPPING_REGION_CODES.includes(shippingRate.regionCode);
  }

  return (
    <FormSection
      title={I18n.t('discovery.dashboard.selling.shippingRates.rateOptionsTitleShippingProfile')}
      description={props.allowedToSetRegionalShipping ? (
        <>
          <RCTag variant="highlight">
            {I18n.t('discovery.dashboard.selling.shippingRates.newBadgeText')}
          </RCTag> {I18n.t('discovery.dashboard.selling.shippingRates.regionalOptionsAreNew')}
        </>
      ) : null}
    >
      <>
        {shippingRates.map((shippingRate) => (
          <div
            className="scaling-mb-4"
            key={shippingRate.regionCode}
          >
            <ShippingProfileRateCard
              key={shippingRate.regionCode}
              shippingRate={shippingRate}
              handleRemoveShippingRate={(shippingRateToRemove) => handleRemoveShippingRate(shippingRateToRemove, shippingRates, setShippingRates)}
              handleEditShippingRate={(updatedShippingRate) => handleEditShippingRate(updatedShippingRate, shippingRates, setShippingRates)}
              currency={props.currency}
              allowedToShipExpedited={shippingRate.isRequired && props.allowedToShipExpedited}
              allowedRegionalShipping={allowedRegionalShipping(shippingRate)}
            />
          </div>
        ))}
        <h4 className="shipping-rate-card__title">
          {I18n.t('discovery.dashboard.selling.shippingRates.addShippingLocationToShippingProfileHeader')}
        </h4>
        <div className="shipping-rate-card__main">
          <ShippingRegionSelector
            shippingRegions={props.shippingRegionOptions}
            onSelect={(r) => {
              if (r.regionCode) {
                setNewShippingRegion({});
                setShippingRates(shippingRates.slice().concat(buildBlankShippingRate(r)));
              }
            }}
            disabledShippingRegionCodes={shippingRates.map(({ regionCode }) => regionCode)}
            value={newShippingRegion.regionCode}
          />
        </div>
        <p className="shipping-rate-card-shipping-profile__help__text">
          {I18n.t('discovery.dashboard.selling.shippingRates.addShippingLocationExplainer')}
        </p>
      </>
    </FormSection>
  );
}

export default ShippingProfileForm;
