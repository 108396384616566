import React from 'react';
import I18n from 'i18n-js';

export default function SignInSeparator() {
  return (
    <div className="signin-separator__wrapper">
      <span className="signin-separator__line" />
      <span className="signin-separator__text">
        {I18n.t('commons.signupForm.separatorText')}
      </span>
    </div>
  );
}
