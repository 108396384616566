import React from 'react';
import I18n from 'i18n-js';
import classNames from 'classnames';

import { RCAlertBox } from '@reverbdotcom/cadence/components';
import {
  core_apimessages_NegotiationAction as NegotiationAction,
} from '@reverbdotcom/commons/src/gql/graphql';
import SanitizedRender from '@reverbdotcom/commons/src/components/sanitized_render';

import AcceptOfferAndCheckOutButton from './accept_offer_and_check_out_button';
import { UpdateOfferButton } from './update_offer_button';
import { HOURS_TO_CHECK_OUT_WHEN_CONDITIONALLY_ACCEPTED } from './update_offer_modal';

interface Props {
  listingId: string;
  negotiationId: string;
  isSeller: boolean;
  offerStatus: string;
  counterable: boolean;
  conditionallyAcceptedBySeller?: boolean;
}

export default function UpdateOfferActions({ listingId, negotiationId, isSeller, offerStatus, counterable, conditionallyAcceptedBySeller = false }: Props) {
  const [hasAccepted, setHasAccepted] = React.useState(false);

  return (
    <>
      {conditionallyAcceptedBySeller && (
        <RCAlertBox type="success">
          <div className="weight-bold">
            {I18n.t('discovery.offers.buyerConditionalOfferAlert.title')}
          </div>
          <SanitizedRender
            html={I18n.t('discovery.offers.buyerConditionalOfferAlert.body', { count: HOURS_TO_CHECK_OUT_WHEN_CONDITIONALLY_ACCEPTED })}
          />
        </RCAlertBox>
      )}

      <div className="offer-message__actions-wrapper">
        <div className={classNames('offer-actions', `status-${offerStatus}`)}>
          <div className="update-offer-actions">
            {!isSeller && conditionallyAcceptedBySeller && (
              <div className="offer-action__wrapper">
                <AcceptOfferAndCheckOutButton
                  negotiationId={negotiationId}
                  listingId={listingId}
                  isSeller={isSeller}
                  onClick={() => { setHasAccepted(true); }}
                  onError={() => { setHasAccepted(false); }}
                />
              </div>
            )}

            {(isSeller || !conditionallyAcceptedBySeller) && (
              <div className="offer-action__wrapper">
                <UpdateOfferButton
                  negotiationAction={NegotiationAction.ACCEPT}
                  negotiationId={negotiationId}
                  listingId={listingId}
                  isSeller={isSeller}
                />
              </div>
            )}

            {counterable && (
              <div className="offer-action__wrapper">
                <UpdateOfferButton
                  negotiationAction={NegotiationAction.COUNTER}
                  negotiationId={negotiationId}
                  listingId={listingId}
                  isSeller={isSeller}
                />
              </div>
            )}

            <div className="offer-action__wrapper">
              <UpdateOfferButton
                negotiationAction={NegotiationAction.REJECT}
                negotiationId={negotiationId}
                listingId={listingId}
                isSeller={isSeller}
                disabled={hasAccepted}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
