import PropTypes from 'prop-types';
import React from 'react';
import UrlHelpers from '../../../shared/url_helpers';
import SanitizedRender from '@reverbdotcom/commons/src/components/sanitized_render';
import I18n from 'i18n-js';

// giving the ability for adyen-onboarded shops to enable paypal

function checkmark() {
  return ('<span class="fa fa-check fa-li color-green"></span>');
}

function protectionLinkOpen() {
  return `<a href='${UrlHelpers.cmsPagePath('reverb-protection')}' target='_blank' rel='noreferrer noopener'>`;
}

class AcceptedPaymentMethodsForm extends React.Component {
  get achPayoutDescriptionText() {
    if (this.props.isPreferredSeller) {
      return I18n.t('js.dashboard.selling.shopPolicies.directCheckoutProfile.achPayoutDescription.preferredSellerPayOutTimingMessage');
    }
    if (this.props.hasCompletedPayouts) {
      return I18n.t(
        'js.dashboard.selling.shopPolicies.directCheckoutProfile.achPayoutDescription.payOutTimingMessageHtml',
        { linkOpen: `<a href='${UrlHelpers.reverbPaymentsFaqPath}' rel='noreferrer noopener' target='_blank'>`, linkClose: '</a>' },
      );
    }
    return I18n.t(
      'js.dashboard.selling.shopPolicies.directCheckoutProfile.achPayoutDescription.firstSalePayOutTimingMessageHtml',
      { linkOpen: `<a href='${UrlHelpers.reverbPaymentsFaqPath}' rel='noreferrer noopener' target='_blank'>`, linkClose: '</a>' },
    );
  }

  get sellerFee() {
    if (this.props.isPreferredSeller) {
      return '2.99';
    }
    return '3.19';
  }

  get internationalFee() {
    if (this.props.isPreferredSeller) {
      return '3.99';
    }
    return '4.19';
  }

  renderDCOInput() {
    return (
      <div className="payment-setup">
        <div className="dc-and-paypal-input">
          <input
            name="shop_policies_form[direct_checkout]"
            type="hidden"
            id="shop_policies_form[direct_checkout]"
            value="1"
          />
          <label htmlFor="shop_policies_form[direct_checkout]">
            <div className="reverb-direct-checkout-icon label-icon vertical-bottom" />
          </label>
          <ul className="mt-1 fa-ul font-tiny normal">
            <li>
              <SanitizedRender
                html={I18n.t('js.dashboard.selling.shopPolicies.dcPaymentMethodsHtml', { icon: checkmark() })}
              />
            </li>
            <li>
              <SanitizedRender
                html={I18n.t('js.dashboard.selling.shopPolicies.dcPayoutsHtml', { icon: checkmark() })}
              />
            </li>
            <li>
              <SanitizedRender
                html={I18n.t('js.dashboard.selling.shopPolicies.dcFeesHtml', {
                  icon: checkmark(),
                  fee_percent: this.sellerFee,
                  intl_fee_percent: this.internationalFee,
                  currency_symbol: this.props.shopCurrency,
                })}
              />
            </li>
            <li>
              <SanitizedRender
                html={I18n.t('js.dashboard.selling.shopPolicies.dcCoveredByProtectionHtml', {
                  icon: checkmark(),
                  linkOpen: protectionLinkOpen(),
                  linkClose: '</a>',
                })}
              />
            </li>
          </ul>
          <div className="module panel mt-2 mb-4">
            <p className="strong">
              {I18n.t('js.dashboard.selling.shopPolicies.directCheckoutProfile.achPayoutDescription.setUpForAch')}
            </p>
            <SanitizedRender html={this.achPayoutDescriptionText} />
          </div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div>
        {this.renderDCOInput()}
      </div>
    );
  }
}

AcceptedPaymentMethodsForm.propTypes = {
  isPreferredSeller: PropTypes.bool.isRequired,
  hasCompletedPayouts: PropTypes.bool.isRequired,
  shopCurrency: PropTypes.string.isRequired,
};

export default AcceptedPaymentMethodsForm;
