import moment from 'moment';
import I18n from 'i18n-js';

export const DATE_FORMAT = 'YYYY-MM-DD';
export const DEFAULT_CURRENCY = 'USD';

export function formattedDateLabels(labels) {
  return labels.map(label => moment(label).format('MMM YY')).reverse();
}

export function priceData({ labels = [], productPrices = [] }) {

  return labels.map(label => {
    const price = productPrices.find(productPrice => productPrice.date == label);

    if (price) {
      const { averageProductPrice } = price;

      return {
        value: averageProductPrice.amount,
        meta: {
          date: price.date,
          docCount: price.docCount,
          averageProductPrice,
        },
      };
    } else {
      return null;
    }
  });
}

export function buildLabelsFromTimeframe(createdAfterDate: string) {
  const currentMonth = moment().startOf('month');
  const differenceInMonths = currentMonth.diff(moment(createdAfterDate), 'months');

  const labels = [currentMonth.format(DATE_FORMAT)];

  [...Array(differenceInMonths)].forEach((_, n) => {
    labels.push(moment(currentMonth).subtract(n + 1, 'months').format(DATE_FORMAT));
  });

  return labels;
}

export function buildChartData({ productPrices, createdAfterDate }) {
  const labels = buildLabelsFromTimeframe(createdAfterDate);

  return {
    labels: formattedDateLabels(labels),
    series: [priceData({ productPrices, labels }).reverse()],
  };
}

export function buildChartDescription(chartData) {
  let description = '';

  if (!chartData.series.length || !chartData.series[0].length) {
    return description;
  }

  chartData.series[0].forEach(item => {
    if (!item) return;

    const count = item.meta?.docCount || 0;
    const averagePrice = item.meta?.averageProductPrice?.display || '';
    const dateString = item.meta?.date ? moment(item.meta?.date).format('MMMM YYYY') : '';
    const dataPointDescription = I18n.t('discovery.priceGuideTool.show.form.results.transactionGraph.ariaDescription', {
      count,
      averagePrice,
      dateString,
    });

    description += dataPointDescription;
  });

  return description.trim();
}


