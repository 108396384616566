import { MutationFunction } from '@reverbdotcom/commons/src/useHOCMutation';
// TODO update this to import from commons
// import { gql } from '@reverbdotcom/commons/src/gql';
// eslint-disable-next-line no-restricted-imports
import { gql } from '@apollo/client';
import React, { MutableRefObject } from 'react';
import {
  CartApplePayHookFields,
  CoreCheckoutStartApplePayCheckout,
  CoreCheckoutUpdateApplePayShippingEstimate,
  core_apimessages_CheckoutPaymentMethod_Type as CheckoutPaymentMethodType,
  core_apimessages_Money as Money,
  core_apimessages_CheckoutFinalizeDigitalWalletPaymentRequest_ResultConfirmation as ResultConfirmation,
} from '@reverbdotcom/commons/src/gql/graphql';
import ApplePay from '@adyen/adyen-web/dist/types/components/ApplePay';
import { useUser } from '@reverbdotcom/commons/src/user_hooks';
import AdyenCheckout from '@adyen/adyen-web';
import {
  buildApplePayLineItems,
  CheckoutApplePayLineItemFragment,
  countryCodeOrDefault,
  FinalizePaymentMutationFn,
  findRequiredShippingContactFields,
  guestUserEntry,
  mapJsonToBase64,
  paymentContactToAddressEntry,
  withErrorHandling,
} from './checkoutApplePay';
import Location from '../lib/wrapped_location';
import { IUser } from '@reverbdotcom/commons';
import { useAdyenEnvironment, AdyenEnvironment } from '@reverbdotcom/commons/src/adyen_environment_hook';
import { buildDeviceInfoPayload } from '@reverbdotcom/commons/src/accertify';

export type StartCheckoutMutationFn =
  MutationFunction<CoreCheckoutStartApplePayCheckout.Mutation, CoreCheckoutStartApplePayCheckout.Variables>;

export type UpdateShippingEstimateMutationFn =
  MutationFunction<CoreCheckoutUpdateApplePayShippingEstimate.Mutation, CoreCheckoutUpdateApplePayShippingEstimate.Variables>;


type Checkout = CartApplePayHookFields.Fragment;

type CheckoutRef = MutableRefObject<Checkout>;

interface ApplePayMutations {
  startApplePayCheckout: StartCheckoutMutationFn;
  updateCheckoutShippingEstimate: UpdateShippingEstimateMutationFn;
  finalizeApplePayPayment: FinalizePaymentMutationFn;
}

export interface ApplePayConfig {
  merchantId: string;
  merchantName: string;
}

/**
 * Powers Apple Pay for cases where a checkout hasn't been started yet,
 * which makes this hook suitable for use in cart, on item pages,
 * or anywhere where a currency and subtotal are known.
 */
export function useCartApplePay({
  checkoutBundlingId,
  applePayConfig,
  amountTotal,
  mutations,
}: {
  checkoutBundlingId: string;
  applePayConfig: ApplePayConfig;
  amountTotal: Money;
  mutations: ApplePayMutations;
}) {
  const checkoutRef = React.useRef<Checkout>(null);
  const [applePay, setApplePay] = React.useState<ApplePay>(null);
  const [isLoading, setLoading] = React.useState(true);
  const { loggedOut, countryCode } = useUser();
  const { amountCents, currency } = amountTotal;
  const {
    startApplePayCheckout,
    updateCheckoutShippingEstimate,
    finalizeApplePayPayment,
  } = mutations;

  const adyenEnvironment = useAdyenEnvironment();

  React.useEffect(() => {
    if (!adyenEnvironment.environment || !adyenEnvironment.clientKey) {
      return;
    }

    initializeApplePay({
      checkoutRef,
      checkoutBundlingId,
      applePayConfig,
      adyenEnvironment,
      amountTotal: { amountCents, currency },
      user: { loggedOut, countryCode },
      mutations: {
        startApplePayCheckout,
        updateCheckoutShippingEstimate,
        finalizeApplePayPayment,
      },
      onApplePayAvailable: setApplePay,
      setLoading,
    });
  }, [
    countryCode,
    loggedOut,
    amountCents,
    currency,
    applePayConfig,
    checkoutBundlingId,
    startApplePayCheckout,
    updateCheckoutShippingEstimate,
    finalizeApplePayPayment,
    adyenEnvironment.clientKey,
    adyenEnvironment.environment,
    setApplePay,
    setLoading,
  ]);

  return { applePay, isLoading };
}

async function initializeApplePay({
  checkoutRef,
  checkoutBundlingId,
  applePayConfig,
  adyenEnvironment,
  amountTotal,
  user,
  mutations,
  onApplePayAvailable,
  setLoading,
}: {
  checkoutRef: CheckoutRef,
  checkoutBundlingId: string,
  applePayConfig: ApplePayConfig,
  adyenEnvironment: AdyenEnvironment,
  amountTotal: Pick<Money, 'amountCents' | 'currency'>,
  user: Pick<IUser, 'countryCode' | 'loggedOut'>,
  mutations: ApplePayMutations,
  onApplePayAvailable: (applePay: ApplePay) => any,
  setLoading: (loading: boolean) => any,
}) {
  const applePayClient = buildApplePayClient({
    checkoutRef,
    checkoutBundlingId,
    mutations,
  });

  const adyenCheckout = new AdyenCheckout({ clientKey: adyenEnvironment.clientKey, environment: adyenEnvironment.environment });

  const applePay = adyenCheckout.create<'applepay'>('applepay', {
    onShippingContactSelected: applePayClient.onShippingContactSelected,
    onAuthorized: applePayClient.onAuthorized,
    totalPriceStatus: 'pending',
    countryCode: countryCodeOrDefault(user.countryCode),
    amount: {
      value: amountTotal.amountCents,
      currency: amountTotal.currency,
    },
    requiredShippingContactFields: findRequiredShippingContactFields(user),
    configuration: {
      merchantId: applePayConfig.merchantId,
      merchantName: applePayConfig.merchantName,
    },
  });

  onApplePayAvailable(applePay);
  setLoading(false);
}

export function buildApplePayClient({
  checkoutRef,
  checkoutBundlingId,
  mutations,
}: {
  checkoutRef: CheckoutRef;
  checkoutBundlingId: string;
  mutations: ApplePayMutations;
}) {
  function updateCheckoutRef(checkout: Checkout) {
    checkoutRef.current = checkout;
    return checkoutRef.current;
  }

  async function findOrCreateCheckout() {
    if (checkoutRef.current) {
      return checkoutRef.current;
    }

    const result = await withErrorHandling(() =>
      mutations.startApplePayCheckout({
        variables: {
          input: {
            checkoutBundlingId,
            paymentMethodType: CheckoutPaymentMethodType.APPLE_PAY,
          },
        },
      }),
    );

    if (!result || !result.data) {
      return null;
    }

    return updateCheckoutRef(result.data.startCheckout.checkout);
  }

  const onShippingContactSelected = buildShippingContactSelected(
    findOrCreateCheckout,
    mutations.updateCheckoutShippingEstimate,
    updateCheckoutRef,
  );

  const onAuthorized = buildPaymentAuthorized(
    checkoutRef,
    mutations.finalizeApplePayPayment,
  );

  return {
    onShippingContactSelected,
    onAuthorized,
  };
}

function buildShippingContactSelected(
  findOrCreateCheckout: () => Promise<Checkout>,
  updateCheckoutShippingEstimate: UpdateShippingEstimateMutationFn,
  updateCheckoutRef: (checkout: Checkout) => Checkout,
) {
  return async function onShippingContactSelected(
    resolve: (update: ApplePayJS.ApplePayShippingContactUpdate) => void,
    _reject: (update: ApplePayJS.ApplePayShippingContactUpdate) => void,
    event: ApplePayJS.ApplePayShippingContactSelectedEvent,
  ) {
    const checkout = await findOrCreateCheckout();

    const { locality, postalCode, administrativeArea, countryCode } = event.shippingContact;

    const result = await withErrorHandling(() =>
      updateCheckoutShippingEstimate({
        variables: {
          input: {
            id: checkout.id,
            shippingAddress: {
              locality,
              postalCode,
              region: administrativeArea,
              countryCode,
            },
          },
        },
      }),
    );

    if (!result || !result.data) {
      return;
    }

    const nextCheckout = updateCheckoutRef(result.data.updateCheckoutShippingEstimate.checkout);
    const { newTotal, newLineItems } = buildApplePayLineItems(nextCheckout);

    resolve({ newTotal, newLineItems });
  };
}

function buildPaymentAuthorized(
  checkoutRef: CheckoutRef,
  finalizeApplePayPayment: FinalizePaymentMutationFn,
) {
  return async function onAuthorized(
    resolve: () => void,
    reject: () => void,
    event: ApplePayJS.ApplePayPaymentAuthorizedEvent,
  ) {
    const { payment: { shippingContact, token } } = event;
    const checkout = checkoutRef.current;
    const { amount: totalPrice } = checkout.total;

    const result = await withErrorHandling(() =>
      finalizeApplePayPayment({
        variables: {
          input: {
            id: checkout.id,
            guestUser: guestUserEntry({ checkoutType: checkout.type, shippingContact }),
            totalPrice: {
              amountCents: totalPrice.amountCents,
              currency: totalPrice.currency,
            },
            paymentToken: mapJsonToBase64(token.paymentData),
            shippingAddress: paymentContactToAddressEntry(shippingContact),
            resultConfirmation: ResultConfirmation.SYNC,
            accertifyDeviceInfo: buildDeviceInfoPayload(),
          },
        },
      }));

    if (!result || !result.data) {
      reject();
      return;
    }

    const finalizedCheckout = result.data.finalizeDigitalWalletPayment.checkout;

    resolve();
    Location.assign(finalizedCheckout.action.redirectUrl);
  };
}

export const CartApplePayHookFragment = gql`
  fragment CartApplePayHookFields on Checkout {
    _id
    id
    type
    total {
      amount {
        amountCents
        currency
      }
    }
    lineItems {
      type
      label
      amount {
        amount
        amountCents
        currency
      }
    }
    ...CheckoutApplePayLineItemFields
  }
  ${CheckoutApplePayLineItemFragment}
`;

export const START_APPLE_PAY_CHECKOUT = gql`
  mutation Core_Checkout_StartApplePayCheckout(
    $input: Input_core_apimessages_StartCheckoutRequest
  ) {
    startCheckout(input: $input) {
      checkout {
        _id
        ...CartApplePayHookFields
      }
    }
  }
  ${CartApplePayHookFragment}
`;

export const UPDATE_APPLE_PAY_SHIPPING_ESTIMATE = gql`
  mutation Core_Checkout_UpdateApplePayShippingEstimate(
    $input: Input_core_apimessages_CheckoutUpdateShippingEstimateRequest
  ) {
    updateCheckoutShippingEstimate(input: $input) {
      checkout {
        _id
        ...CartApplePayHookFields
      }
    }
  }
  ${CartApplePayHookFragment}
`;
