import React from 'react';
import CoreLink from '@reverbdotcom/commons/src/components/core_link';
import { ILinkProps, IReverbHeaderMenuSectionProps } from './reverb_header_menu_data';

export function ReverbHeaderMenuLink({
  path,
  text,
  hide,
  logOut,
  onClick,
}: ILinkProps) {
  if (hide) { return null; }

  if (logOut) {
    return (
      <li>
        <a
          href={path}
          data-method="delete"
          className="size-80 opacity-70"
        >
          {text}
        </a>
      </li>
    );
  }

  return (
    <li>
      <CoreLink to={path} onClick={onClick}>
        {text}
      </CoreLink>
    </li>
  );
}

export default function ReverbHeaderMenuSection({ title, links, onClick }: IReverbHeaderMenuSectionProps) {
  return (
    <>
      <div className="reverb-header__menu__user-menu__header">
        {title}
      </div>
      <ul>
        {links.map(link => (
          <ReverbHeaderMenuLink
            key={link.key}
            path={link.path}
            text={link.text}
            logOut={link.logOut}
            hide={link.hide}
            onClick={onClick}
          />
        ))}
      </ul>
    </>
  );
}
