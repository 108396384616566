import { Helmet } from 'react-helmet';

import {
  AddOnExitTriggerforEmailPopUpHook,
  AddressBook,
  AffirmCartOptionText,
  AffirmEstimateCTA,
  AppProvider,
  AutoOfferModalButton,
  BulkSaleModalButton,
  BarGraph,
  BumpBid,
  BumpDisplay,
  CartCoupons,
  ListingRowBumpButton,
  CartCheckoutRedirectErrors,
  CartFooterExpressPayWrapper,
  CartHoistedExpressPayWrapper,
  CheckoutProgressBar,
  CheckoutCartFooterButtons,
  CheckoutTermsAndConditions,
  CartItemActions,
  CheckoutAddressBook,
  CombineAndSaveRow,
  ConditionDisplay,
  CreateOfferButton,
  CreditCardsOnFileList,
  DismissibleTrustBanner,
  EmailSignUpEmbed,
  EuOdrDetailsContainer,
  EuOdrDetailsDashboardContainer,
  FeedbackContainer,
  MyFeedbackMessagesContainer,
  FreeShippingProgressBarContainer,
  GiftCardSendDatePicker,
  HomepageCuratedSetRow,
  InformActBanner,
  Item2ShippingPrompt,
  Spinner,
  setRoutes,
  LegacyCheckoutShippingContinueButton,
  LightboxImage,
  ListingPayPalCallout,
  ListingRow,
  LocalePickerButton,
  MessageForm,
  MessageInput,
  MobileDetection,
  Modal,
  NewsletterSignupForm,
  OnwardWarning,
  PacklinkLinkAccountButton,
  PacklinkShippingLabelButton,
  PasswordInput,
  PromotionalBannerTop,
  RecentlyViewedListings,
  RefundRequestFormFields,
  RegionalListings,
  ReverbShippingLabelsValueProp,
  ReverbHeaderZone,
  SearchHeader,
  SellFormPostPublish,
  SellerInitiatedRefundAlert,
  ShipOrderHeader,
  ShipmentPackingHelpLink,
  ShippingProfileFormContainer,
  ShopBadges,
  ShopRefundStats,
  ShopStats,
  ShopSettingsAuGstContainer,
  ShopSettingsVatLayout,
  SigninForm,
  SignupForm,
  SiteBannerHero,
  SiteFooterContainer,
  SmartRecruiterJobs,
  SiteSearch,
  RCStarRating,
  RCStarRatingSelector,
  Subscriptions,
  TaxForms,
  NoTaxFormsToDisplay,
  TaxPolicyUS,
  TaxProfileFooter,
  TinVerificationStatus,
  RCTooltip,
  TrackCartView,
  UniversalPromoCampaignFormContainer,
  UpdateOfferButton,
  WatchedListings,
  alt,
  buildReverbRouter,
  buildRootRoutes,
  DiscoveryRoutes,
  ConversationFlagMessageButton,
  KeepShopping,
  ShopSettingsOfferPolicy,
  OffersPreferences,
  FeedbackPreferences,
  SellingPoliciesOfferBot,
  QuickPriceEditButton,
  ZeroFinancingPopOverButton,
  SellerListingCard,
  SellerListingCardsCollection,
  ShopLockedModeBanner,
  RecaptchaInput,
  AccountDeletion,
  ShopSettingsUserManagement,
  UpdateOfferActions,
  OfferActionsExplanation,
  USTaxProfileContainer,
} from '@reverbdotcom/discovery-ui';

import AdaChatButton from '@reverbdotcom/discovery-ui/src/components/ada_chat_button';
import { AcceptLocaleSettingsModal } from '@reverbdotcom/discovery-ui/src/components/AcceptLocaleSettingsModal';
import { ShopBadgeStatuses } from '@reverbdotcom/discovery-ui/src/components/shop_badge_statuses';

import trackPageView from './track_page_view';

window.Reverb = window.Reverb || {};
window.ReverbAppProviderV2 = AppProvider;

window.Reverb.AffirmCartOptionText = AffirmCartOptionText;
window.Reverb.AutoOfferModalButton = AutoOfferModalButton;
window.Reverb.BulkSaleModalButton = BulkSaleModalButton;
window.Reverb.CartCoupons = CartCoupons;

window.Reverb.AcceptLocaleSettingsModal = AcceptLocaleSettingsModal;
window.Reverb.BumpDisplay = BumpDisplay;
window.Reverb.BumpBid = BumpBid;
window.Reverb.BarGraph = BarGraph;
window.Reverb.ListingRowBumpButton = ListingRowBumpButton;
window.Reverb.CartCheckoutRedirectErrors = CartCheckoutRedirectErrors;
window.Reverb.CartFooterExpressPayWrapper = CartFooterExpressPayWrapper;
window.Reverb.CartHoistedExpressPayWrapper = CartHoistedExpressPayWrapper;
window.Reverb.CheckoutProgressBar = CheckoutProgressBar;
window.Reverb.CheckoutCartFooterButtons = CheckoutCartFooterButtons;
window.Reverb.CheckoutShippingContinueButton = LegacyCheckoutShippingContinueButton;
window.Reverb.CheckoutTermsAndConditions = CheckoutTermsAndConditions;
window.Reverb.CombineAndSaveRow = CombineAndSaveRow;
window.Reverb.ConditionDisplay = ConditionDisplay;
window.Reverb.CreateOfferButton = CreateOfferButton;
window.Reverb.CreditCardsOnFileList = CreditCardsOnFileList;
window.Reverb.DismissibleTrustBanner = DismissibleTrustBanner;
window.Reverb.Spinner = Spinner;
window.Reverb.ShippingProfileFormContainer = ShippingProfileFormContainer;
window.Reverb.FeedbackContainer = FeedbackContainer;
window.Reverb.MyFeedbackMessagesContainer = MyFeedbackMessagesContainer;
window.Reverb.FreeShippingProgressBarContainer = FreeShippingProgressBarContainer;
window.Reverb.HomepageCuratedSetRow = HomepageCuratedSetRow;
window.Reverb.LightboxImage = LightboxImage;
window.Reverb.ListingRow = ListingRow;
window.Reverb.LocalePickerButton = LocalePickerButton;
window.Reverb.Modal = Modal;
window.Reverb.NewsletterSignupForm = NewsletterSignupForm;
window.Reverb.OnwardWarning = OnwardWarning;
window.Reverb.PacklinkLinkAccountButton = PacklinkLinkAccountButton;
window.Reverb.PacklinkShippingLabelButton = PacklinkShippingLabelButton;
window.Reverb.PasswordInput = PasswordInput;
window.Reverb.RecaptchaInput = RecaptchaInput;
window.Reverb.PromotionalBannerTop = PromotionalBannerTop;
window.Reverb.RecentlyViewedListings = RecentlyViewedListings;
window.Reverb.RegionalListings = RegionalListings;
window.Reverb.ReverbShippingLabelsValueProp = ReverbShippingLabelsValueProp;
window.Reverb.SellerInitiatedRefundAlert = SellerInitiatedRefundAlert;
window.Reverb.ShipmentPackingHelpLink = ShipmentPackingHelpLink;
window.Reverb.ShopBadges = ShopBadges;
window.Reverb.ShopBadgeStatuses = ShopBadgeStatuses;
window.Reverb.ShopRefundStats = ShopRefundStats;
window.Reverb.ShopStats = ShopStats;
window.Reverb.SearchBarV2 = SiteSearch;
window.Reverb.ReverbHeaderZone = ReverbHeaderZone;
window.Reverb.SearchHeader = SearchHeader;
window.Reverb.ShopSettingsAuGstContainer = ShopSettingsAuGstContainer;
window.Reverb.ShopSettingsVatLayout = ShopSettingsVatLayout;
window.Reverb.SignupForm = SignupForm;
window.Reverb.SigninForm = SigninForm;
window.Reverb.SiteBannerHero = SiteBannerHero;
window.Reverb.SiteFooter = SiteFooterContainer;
window.Reverb.AffirmEstimateCTA = AffirmEstimateCTA;
window.Reverb.SmartRecruiterJobs = SmartRecruiterJobs;
window.Reverb.StarRating = RCStarRating;
window.Reverb.RCStarRating = RCStarRating;
window.Reverb.StarRatingSelector = RCStarRatingSelector;
window.Reverb.RCStarRatingSelector = RCStarRatingSelector;
window.Reverb.Subscriptions = Subscriptions;
window.Reverb.TaxForms = TaxForms;
window.Reverb.NoTaxFormsToDisplay = NoTaxFormsToDisplay;
window.Reverb.TaxProfileFooter = TaxProfileFooter;
window.Reverb.TinVerificationStatus = TinVerificationStatus;
window.Reverb.TaxPolicyUS = TaxPolicyUS;
window.Reverb.TrackCartView = TrackCartView;
window.Reverb.UniversalPromoCampaignForm = UniversalPromoCampaignFormContainer;
window.Reverb.UpdateOfferButton = UpdateOfferButton;
window.Reverb.UpdateOfferActions = UpdateOfferActions;
window.Reverb.OfferActionsExplanation = OfferActionsExplanation;
window.Reverb.WatchedListings = WatchedListings;
window.Reverb.SellFormPostPublish = SellFormPostPublish;
window.Reverb.AddressBook = AddressBook;
window.Reverb.CheckoutAddressBook = CheckoutAddressBook;
window.Reverb.EmailSignUp = EmailSignUpEmbed;
window.Reverb.EuOdrDetailsContainer = EuOdrDetailsContainer;
window.Reverb.EuOdrDetailsDashboardContainer = EuOdrDetailsDashboardContainer;
window.Reverb.GiftCardSendDatePicker = GiftCardSendDatePicker;
window.Reverb.ShipOrderHeader = ShipOrderHeader;
window.Reverb.ListingPayPalCallout = ListingPayPalCallout;
window.Reverb.RefundRequests = {
  FormFields: RefundRequestFormFields,
};
window.Reverb.CartItemActions = CartItemActions;
window.Reverb.Item2ShippingPrompt = Item2ShippingPrompt;

window.Reverb.CommonAlt = alt;
window.RREHelmet = Helmet;

window.Reverb.Routes = buildRootRoutes(DiscoveryRoutes); // exposed for server side rendering
setRoutes(window.Reverb.Routes);

window.Reverb.noRouterContext = true;
window.Reverb.Router = buildReverbRouter(window.Reverb.Routes, {
  onUpdate: () => {
    trackPageView(window.location);
    window.Reverb.noRouterContext = false;
  },
});

window.Reverb.MobileDetection = MobileDetection;

window.Reverb.Conversations = window.Reverb.Conversations || {};
window.Reverb.Conversations.BodyInput = MessageInput;
window.Reverb.Conversations.Form = MessageForm;
window.Reverb.Conversations.FlagMessageButton = ConversationFlagMessageButton;
window.Reverb.Tooltip = RCTooltip;
window.Reverb.RCTooltip = RCTooltip;
window.Reverb.AdaChatButton = AdaChatButton;
window.Reverb.AddOnExitTriggerforEmailPopUpHook = AddOnExitTriggerforEmailPopUpHook;
window.Reverb.KeepShopping = KeepShopping;
window.Reverb.ShopSettingsOfferPolicy = ShopSettingsOfferPolicy;
window.Reverb.OffersPreferences = OffersPreferences;
window.Reverb.FeedbackPreferences = FeedbackPreferences;
window.Reverb.SellingPoliciesOfferBot = SellingPoliciesOfferBot;
window.Reverb.QuickPriceEditButton = QuickPriceEditButton;
window.Reverb.ZeroFinancingPopOverButton = ZeroFinancingPopOverButton;
window.Reverb.SellerListingCard = SellerListingCard;
window.Reverb.SellerListingCardsCollection = SellerListingCardsCollection;
window.Reverb.ShopLockedModeBanner = ShopLockedModeBanner;
window.Reverb.InformActBanner = InformActBanner;
window.Reverb.AccountDeletion = AccountDeletion;
window.Reverb.ShopSettingsUserManagement = ShopSettingsUserManagement;
window.Reverb.USTaxProfileContainer = USTaxProfileContainer;
