import React from 'react';
import I18n from 'i18n-js';
import moment from 'moment';
import APIRequest from '../../api_request';
import classNames from 'classnames';
import URLHelpers from '../../shared/url_helpers';
import { ICuratedSet, SaleStatus } from './bulk_sale_modal_button';
import { RCButton } from '@reverbdotcom/cadence/components';
import { useViewTracking } from '@reverbdotcom/commons/src/use_tracking';
import { MParticleEventName } from '@reverbdotcom/commons/src/elog/mparticle_types';
import { trackEvent } from '@reverbdotcom/commons/src/elog/mparticle_tracker';

export const COMPONENT_NAME = 'BulkSaleRow';
interface IProps {
  curatedSet: ICuratedSet;
  queryString: string;
  visible: boolean;
}

interface ISubmittedMessageProps {
  curatedSet: ICuratedSet;
  rowState: IRowState;
}

interface IAddButtonProps {
  curatedSet: ICuratedSet;
  rowState: IRowState;
  handleAddListings: (e) => Promise<void>;
  disabled: boolean;
}

interface ISaleTypeProps {
  curatedSet: ICuratedSet;
}

interface IDetailsTableProps {
  curatedSet: ICuratedSet;
}

interface IRemoveButtonProps {
  curatedSet: ICuratedSet;
  handleRemoveListings: (e) => Promise<void>;
  disabled: boolean;
}

enum RowAction {
  ADDING = 'adding',
  ADDED = 'added',
  REMOVING = 'removing',
  REMOVED = 'removed',
  ERROR = 'error',
}

interface IRowState {
  currentAction: RowAction;
}

const DATE_FORMAT = 'll';

function renderI18nCount(i18nKey, count) {
  return I18n.t(`discovery.selling.bulkSale.${i18nKey}`, {
    formattedCount: I18n.toNumber(count, {
      precision: 0,
    }),
    count,
  });
}

function FeaturedTag() {
  return (
    <span className="bulk-sale-row__featured-tag">
      {I18n.t('discovery.selling.bulkSale.featuredSale')}
    </span>
  );
}

function SaleType({ curatedSet }: ISaleTypeProps) {
  if (!curatedSet.label) return null;

  const classes = classNames(
    'bulk-sale-row__type',
    { 'bulk-sale-row__type--reverb': !curatedSet.lp_only },
  );

  return (
    <h4 className={classes} data-title-label>
      {curatedSet.featured && <FeaturedTag />}
      {curatedSet.label}
    </h4>
  );
}

function rowActionReducer(state: IRowState, action) {
  switch (action.type) {
    case 'setAdding':
      return { ...state, currentAction: RowAction.ADDING };
    case 'setAdded':
      return { ...state, currentAction: RowAction.ADDED };
    case 'setRemoving':
      return { ...state, currentAction: RowAction.REMOVING };
    case 'setRemoved':
      return { ...state, currentAction: RowAction.REMOVED };
    case 'setError':
      return { ...state, currentAction: RowAction.ERROR };
    case 'setDefault':
    default:
      return { ...state, currentAction: null };
  }
}

function shouldDisableButtons(rowState: IRowState) {
  return (
    rowState.currentAction === RowAction.ADDING ||
    rowState.currentAction === RowAction.ADDED ||
    rowState.currentAction === RowAction.REMOVING ||
    rowState.currentAction === RowAction.REMOVED
  );
}

function AddButton({ curatedSet, rowState, handleAddListings, disabled }: IAddButtonProps) {
  if (curatedSet.add_count < 1 || curatedSet.status === SaleStatus.ENDED) return null;

  const i18nKey = curatedSet.used_only ? 'addUsedListings' : 'addListings';
  const addButtonText =
    rowState.currentAction === RowAction.ADDING
      ? I18n.t('discovery.selling.bulkSale.addingListings')
      : renderI18nCount(i18nKey, curatedSet.add_count);

  return (
    <RCButton
      fullWidth
      onClick={handleAddListings}
      disabled={disabled}
    >
      {addButtonText}
    </RCButton>
  );
}

function RemoveButton({ curatedSet, handleRemoveListings, disabled }: IRemoveButtonProps) {
  if (curatedSet.remove_count < 1) return null;

  return (
    !disabled && (
      <div className="bulk-sale-row__remove">
        <span className="bulk-sale-row__remove-text">{renderI18nCount('listingsAlreadyAdded', curatedSet.remove_count)}</span>

        <button className="button-as-link" onClick={handleRemoveListings} disabled={disabled} data-remove-button>
          {I18n.t('discovery.selling.bulkSale.remove')}
        </button>
      </div>
    )
  );
}

function SubmittedMessage({ curatedSet, rowState }: ISubmittedMessageProps) {
  if (rowState.currentAction === RowAction.ADDED) {
    return (
      <p className="bulk-sale-row__submit-message bulk-sale-row__submit-message--success">
        {renderI18nCount('listingsAdded', curatedSet.add_count)}
        <span className="bulk-sale-row__submit-submessage">
          {I18n.t('discovery.selling.bulkSale.pleaseWaitAdding')}
        </span>
      </p>
    );
  }

  if (rowState.currentAction === RowAction.REMOVED) {
    return (
      <p className="bulk-sale-row__submit-message bulk-sale-row__submit-message--success">
        {renderI18nCount('listingsRemoved', curatedSet.remove_count)}
        <span className="bulk-sale-row__submit-submessage">
          {I18n.t('discovery.selling.bulkSale.pleaseWaitRemoving')}
        </span>
      </p>
    );
  }

  if (rowState.currentAction === RowAction.ERROR) {
    return (
      <p className="bulk-sale-row__submit-message bulk-sale-row__submit-message--failure">
        {I18n.t('discovery.selling.bulkSale.error')}
      </p>
    );
  }

  return null;
}

function DetailsTable({ curatedSet }: IDetailsTableProps) {
  const discountValue = curatedSet.discount_value
    ? curatedSet.discount_value
    : I18n.t('discovery.selling.bulkSale.noDiscount');

  const zeroPercentAffirmSet = !curatedSet.reverb_sale &&
    curatedSet.zero_percent_affirm_eligible;

  const dateSummary = zeroPercentAffirmSet ?
    I18n.t('discovery.selling.bulkSale.noDatesActive') :
    I18n.t('discovery.selling.bulkSale.dateSummary', {
      startsAt: moment(curatedSet.starts_at).format(DATE_FORMAT),
      endsAt: moment(curatedSet.ends_at).format(DATE_FORMAT),
    });

  return (
    <table className="bulk-sale-row__details">
      <tbody>
        <tr className="bulk-sale-row__detail">
          <td>{I18n.t('discovery.selling.bulkSale.datesActive')}</td>
          <td>
            {dateSummary}
          </td>
        </tr>
        <tr className="bulk-sale-row__detail">
          <td>{I18n.t('discovery.selling.bulkSale.discount')}</td>
          <td>{discountValue}</td>
        </tr>
        <tr className="bulk-sale-row__detail">
          <td>{I18n.t('discovery.selling.bulkSale.status')}</td>
          <td
            className={classNames('bulk-sale-row__status', {
              'bulk-sale-row__status--active':
                curatedSet.status === SaleStatus.LIVE,
              'bulk-sale-row__status--upcoming':
                curatedSet.status === SaleStatus.UPCOMING,
              'bulk-sale-row__status--ended':
                curatedSet.status === SaleStatus.ENDED,
            })}
          >
            {I18n.t(`discovery.selling.bulkSale.displayStatus.${curatedSet.status}`)}
          </td>
        </tr>
      </tbody>
    </table>
  );
}

export default function BulkSaleRow({ curatedSet, visible, queryString }: IProps) {
  const canAddSale = !curatedSet.unavailable_to_add_reason;

  const [rowState, dispatch] = React.useReducer(rowActionReducer, { currentAction: null });

  async function handleAddListings(e) {
    e.preventDefault();

    trackEvent({
      componentName: COMPONENT_NAME,
      eventName: MParticleEventName.SellerBulkCreatingSaleMemberships,
      listingsCount: curatedSet.add_count,
      hasAllMatchesSelected: true,
    });

    dispatch({ type: 'setAdding' });

    try {
      await APIRequest.V3.post(URLHelpers.curatedSetAddListings(curatedSet.id, queryString));

      dispatch({ type: 'setAdded' });
    } catch (e) {
      handleError();
    }
  }

  async function handleRemoveListings(e) {
    e.preventDefault();
    dispatch({ type: 'setRemoving' });

    trackEvent({
      componentName: COMPONENT_NAME,
      eventName: MParticleEventName.SellerBulkDeletingSaleMemberships,
      listingsCount: curatedSet.remove_count,
      hasAllMatchesSelected: true,
    });

    try {
      await APIRequest.V3.post(URLHelpers.curatedSetRemoveListings(curatedSet.id, queryString));

      dispatch({ type: 'setRemoved' });
    } catch (e) {
      handleError();
    }
  }

  function handleError() {
    dispatch({ type: 'setError' });
  }

  useViewTracking({
    eventName: MParticleEventName.ComponentView,
    componentName: COMPONENT_NAME,
  }, visible);

  return (
    visible && (
      <div className="bulk-sale-row">
        <div className="bulk-sale-row__inner">
          <div className="bulk-sale-row__info">
            <SaleType curatedSet={curatedSet} />

            <h3 className="bulk-sale-row__name">
              {curatedSet.name}
              {curatedSet.status === SaleStatus.LIVE &&
                curatedSet._links.self.href && (
                <a href={curatedSet._links.self.href} className="bulk-sale-row__sale-link" target="_blank" rel="noopener noreferrer">
                  {I18n.t('discovery.selling.bulkSale.viewSale')}
                </a>
              )}
            </h3>

            {curatedSet.additional_information && <div className="bulk-sale-row__affirm-text">{curatedSet.additional_information}</div>}

            <DetailsTable curatedSet={curatedSet} />

            {!canAddSale &&
              <p className="bulk-sale-row__notice bulk-sale-row__notice--error">
                {curatedSet.unavailable_to_add_reason}
              </p>
            }

            {curatedSet.used_only &&
              <p className="bulk-sale-row__notice bulk-sale-row__notice--warning">
                {I18n.t('discovery.selling.bulkSale.usedOnlyHint')}
              </p>
            }
          </div>

          <div className="bulk-sale-row__actions">
            <AddButton
              curatedSet={curatedSet}
              rowState={rowState}
              handleAddListings={handleAddListings}
              disabled={!canAddSale || shouldDisableButtons(rowState)}
            />

            <RemoveButton
              curatedSet={curatedSet}
              handleRemoveListings={handleRemoveListings}
              disabled={shouldDisableButtons(rowState)}
            />

            <SubmittedMessage
              curatedSet={curatedSet}
              rowState={rowState}
            />
          </div>
        </div>
      </div>
    )
  );
}
