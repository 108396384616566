import alt, { AltActions } from '../components/alt';

interface Actions {
  setUser(user: any);
  setExperiment(experimentName: string, isEnabled: boolean);
}

class UserActions extends AltActions implements Actions {
  setUser(user) {
    return user;
  }

  setExperiment(experimentName, isEnabled) {
    return { [experimentName]: isEnabled };
  }
}

export default alt.createActions(UserActions);
