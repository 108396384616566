import I18n from 'i18n-js';
import React from 'react';
import { withUserContext, IUserContext } from '@reverbdotcom/commons/src/components/user_context_provider';
import NewsletterImage1 from '../../../images/promo/newsletter-sample-1.png';
import NewsletterImage2 from '../../../images/promo/newsletter-sample-2.png';
import UrlHelpers from '../shared/url_helpers';
import { post } from '../api_request';
import { mparticleEmailFields } from '@reverbdotcom/commons/src/mparticle_email_fields_helper';

interface IProps extends IUserContext {
  signupReason: string;
}
interface IState {
  email: string;
  invalidEmail: boolean;
  submitSuccess: boolean;
  error: boolean;
  emailTooLong: boolean;
}

class NewsletterSignupForm extends React.Component<IProps, IState> {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      invalidEmail: false,
      submitSuccess: false,
      error: false,
      emailTooLong: false,
    };

    this.setEmail = this.setEmail.bind(this);
    this.submitForm = this.submitForm.bind(this);
  }

  get gdpr() {
    return this.props.user.gdpr;
  }

  setEmail(e) {
    this.setState({ email: e.target.value });
  }

  submitForm(e) {
    e.preventDefault();

    if (!this.state.email.includes('@')) {
      this.setState({ submitSuccess: false, invalidEmail: true, error: false, emailTooLong: false });
      return;
    }

    if (this.state.email.length > 255) {
      this.setState({ submitSuccess: false, invalidEmail: false, error: false, emailTooLong: true });
      return;
    }

    const data = {
      email: this.state.email,
      signup_reason: this.props.signupReason,
      ...mparticleEmailFields(),
    };

    const subscribe_url = UrlHelpers.newsletterSignup();

    post(subscribe_url, data).then(() => {
      this.setState({ submitSuccess: true, invalidEmail: false, error: false, emailTooLong: false });
    }).fail((err) => {
      if (err.status === 400) {
        this.setState({ submitSuccess: false, invalidEmail: true, error: false, emailTooLong: false });
      } else {
        this.setState({ submitSuccess: false, invalidEmail: false, error: true, emailTooLong: false });
      }
    });
  }

  renderSuccessMsg() {
    if (!this.state.submitSuccess) { return null; }

    return (
      <div className="size-90 weight-bold color-green">
        {I18n.t('discovery.newsletterSignup.done')}
      </div>
    );
  }

  renderErrorMsg() {
    if (!this.state.error) { return null; }

    return (
      <div className="size-90 weight-bold color-red">
        {I18n.t('discovery.newsletterSignup.invalid')}
      </div>
    );
  }

  renderInvalidMsg() {
    if (!this.state.invalidEmail) { return null; }

    return (
      <div className="size-90 weight-bold color-red">
        {I18n.t('discovery.newsletterSignup.invalid')}
      </div>
    );
  }

  renderEmailTooLongMsg() {
    if (!this.state.emailTooLong) { return null; }

    return (
      <div className="size-90 weight-bold color-red">
        {I18n.t('discovery.newsletterSignup.emailTooLong')}
      </div>
    );
  }

  render() {
    if (!this.props.user.loggedOut) { return null; }

    return (
      <aside className="newsletter-callout">
        <div className="newsletter-callout__inner">
          <img
            className="newsletter-callout__image"
            src={NewsletterImage2}
            alt={I18n.t('discovery.newsletterSignup.alt2')}
          />
          <img
            className="newsletter-callout__image"
            src={NewsletterImage1}
            alt={I18n.t('discovery.newsletterSignup.alt1')}
          />
          <h3 className="newsletter-callout__title">
            {I18n.t('discovery.newsletterSignup.header')}
          </h3>
          <p>
            {I18n.t('discovery.newsletterSignup.tagline')}
          </p>
          <form
            onSubmit={this.submitForm}
            className="newsletter-callout__form"
          >
            <input
              className="required"
              placeholder={I18n.t('discovery.newsletterSignup.placeholder')}
              type="email"
              onChange={this.setEmail}
            />
            <button
              className="button button--primary button--wide-mobile"
              onClick={this.submitForm}
              type="button"
            >
              {I18n.t('discovery.newsletterSignup.button')}
            </button>
            <div className="newsletter-signup__fineprint">
              {I18n.t(
                this.gdpr ? 'commons.newsletterSignup.gdprTerms' : 'commons.newsletterSignup.terms',
                { buttonText: I18n.t('commons.newsletterSignup.ctaNewsletter') },
              )}
            </div>
          </form>
          {this.renderSuccessMsg()}
          {this.renderInvalidMsg()}
          {this.renderErrorMsg()}
          {this.renderEmailTooLongMsg()}
        </div>
      </aside>
    );
  }
}

export default withUserContext(NewsletterSignupForm);
