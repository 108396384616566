import QueryParamsUtil from './reverb-js-common/query_params_util';
import { UI } from './legacy/ui';

class User {
  // This may be invoked from the client side, or the server
  // may trigger it as part of a request login validation.
  // See ApplicationController#require_user. When the server
  // triggers the event, there will be no params.
  showLightboxWithDialog = (e, button, url) => {
    const opts = {};
    let thisUrl = url;
    let content = null;

    // Clear the current hash, preventing problems in switching
    // between tabs due to the tab framework thinking we're already
    // engaged on a particular tab.
    window.location.hash = '';

    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }

    if (button) {
      Object.assign(opts, this.getOptsFromButton(button));
    }

    thisUrl = this.setupUrlParams(url, opts);

    UI.Ajax.lightbox(`/${thisUrl}`, () => {
      const pane = $('.registration .tab-pane.active');

      if (opts.message) {
        $('[data-bind=\'registration-message-content\']').html(opts.message);
        $('[data-bind=\'registration-message\']').show();
      }

      if (opts.contentSelector) {
        content = $(opts.contentSelector).html();
        $('[data-bind=\'registration-explanation-content\']').html(content);
      }

      pane.focusFirstInput();
    });
  };

  getOptsFromButton = (button) => {
    const vals = {};

    vals.popupSource = $(button).data('source');
    vals.message = $(button).data('registration-message');
    vals.contentSelector = $(button).data('registration-explanation-content');
    vals.urlSource = $(button).data('registration-url-source');
    vals.sourceProductUuid = $(button).data('registration-source-product-uuid');

    return vals;
  };

  setupUrlParams = (url, opts) => {
    const queryHash = {};
    let queryParams = null;
    let urlWithParams = url;

    if (opts.urlSource) {
      queryHash.source = opts.urlSource;
    }

    if (opts.sourceProductUuid) {
      queryHash.source_product_uuid = opts.sourceProductUuid;
    }

    if (!$.isEmptyObject(queryHash)) {
      queryParams = QueryParamsUtil.queryParamsForUrl(queryHash);
      urlWithParams += queryParams;
    }
    return urlWithParams;
  };

  showInlineRegistrationFromServer = () => {
    this.showLightboxWithDialog(null, null, 'ajax_registration_or_login');
  };

  showInlineRegistration = (e, button) => {
    this.showLightboxWithDialog(e, button, 'ajax_registration');
  };

  showInlineLogin = (e, button) => {
    this.showLightboxWithDialog(e, button, 'ajax_login');
  };

  showInlineRegistrationOrLogin = (e, button) => {
    this.showLightboxWithDialog(e, button, 'ajax_registration_or_login');
  };
}

export default new User();
