import React from 'react';

import {
  CheckoutAffirmPaymentFormHookFields,
  core_apimessages_Error as Error,
  Input_core_apimessages_UpdateCheckoutBillingAddressRequest as UpdateCheckoutBillingAddressRequest,
  core_apimessages_CheckoutPaymentMethod_Type as CheckoutPaymentMethodType,
} from '@reverbdotcom/commons/src/gql/graphql';
import { useUser } from '@reverbdotcom/commons/src/user_hooks';
import windowWrapper from '@reverbdotcom/commons/src/window_wrapper';
import { useMutation } from '@reverbdotcom/commons/src/useMutation';
import { gql } from '@reverbdotcom/commons/src/gql';
import { buildDeviceInfoPayload } from '@reverbdotcom/commons/src/accertify';

import { mapAddressToEntry } from '../../lib/addressEntryMapping';
import { buildAffirmCheckout } from './affirmCheckoutBuilder';
import { isEntryEmpty } from '../../lib/addressComparator';

type Checkout = CheckoutAffirmPaymentFormHookFields.Fragment;
type BillingInput = Pick<UpdateCheckoutBillingAddressRequest, 'billingAddress'>;

export interface AffirmPaymentForm {
  type: CheckoutPaymentMethodType.AFFIRM;
  input: BillingInput;
  update: (input: BillingInput) => void;
  submit: () => void;
  errors: Error[];
  loading: boolean;
}

export function useCheckoutAffirmPaymentForm({ checkout }: { checkout: Checkout }): AffirmPaymentForm {
  const user = useUser();
  const [mutate, { errors }] = useMutation(UPDATE_AFFIRM_BILLING_ADDRESS_MUTATION);
  const [requestInput, setRequestInput] = React.useState<BillingInput>({ billingAddress: {} });
  const [loading, setLoading] = React.useState(false);
  const { affirm } = windowWrapper;

  function billingAddress() {
    if (isEntryEmpty(requestInput.billingAddress)) {
      return checkout.billingAddress;
    } else {
      return requestInput.billingAddress;
    }
  }

  const update = React.useCallback((input: BillingInput) => {
    setRequestInput(prevInput => ({ ...prevInput, ...input }));
  }, [setRequestInput]);

  const hideLoading = React.useCallback(() => {
    setLoading(false);
  }, [setLoading]);

  async function submit() {
    setLoading(true);

    const result = await mutate({
      variables: {
        input: {
          id: checkout.id,
          billingAddress: mapAddressToEntry(billingAddress()),
          accertifyDeviceInfo: buildDeviceInfoPayload(),
        },
      },
      fetchPolicy: 'no-cache',
    });

    const resultCheckout = result.data?.updateCheckoutBillingAddress?.checkout;

    if (!resultCheckout) {
      setLoading(false);
      return;
    }

    const affirmCheckout = buildAffirmCheckout({ checkout: resultCheckout, user });
    affirm.checkout(affirmCheckout);

    affirm.checkout.open({
      onFail: hideLoading,
      onValidationError: hideLoading,
    });
  }

  return {
    type: CheckoutPaymentMethodType.AFFIRM,
    input: requestInput,
    update,
    submit,
    errors,
    loading,
  };
}

export const CheckoutAffirmPaymentFormHookFragment = gql(`
  fragment CheckoutAffirmPaymentFormHookFields on Checkout {
    _id
    id
    ...AffirmCheckoutBuilderFields
  }
`);

export const UPDATE_AFFIRM_BILLING_ADDRESS_MUTATION = gql(`
  mutation Core_Checkout_UpdateAffirmBillingAddress(
    $input: Input_core_apimessages_UpdateCheckoutBillingAddressRequest
  ) {
    updateCheckoutBillingAddress(input: $input) {
      checkout {
        _id
        ...AffirmCheckoutBuilderFields
      }
    }
  }
`);
