import { ChildProps } from '@apollo/client/react/hoc';
// TODO update this to import from commons/src/gql
// eslint-disable-next-line no-restricted-imports
import { gql } from '@apollo/client';
import React from 'react';
import classNames from 'classnames';
import { get, times } from 'lodash';
import { parse, stringify } from 'qs';

import { withGraphql } from '../../with_graphql';
import {
  Commons_Cms_SubcategoryNavQuery,
  Commons_Cms_SubcategoryNavQueryVariables,
} from '../../gql/graphql';
import CoreLink from '../../components/core_link';
import { IDynamicComponentProps } from '../dynamic_component_props';

export interface IProps extends IDynamicComponentProps {
  categoryUUID?: string;
  linkOverride?: string;
  jumplinkSlug?: string;
  productType?: string;
  subCategory?: string;
}

function Placeholder() {
  return (
    <div className="facet">
      <nav>
        <ul className="cms-facet__options cms-facet__options--placeholder">
          {times(6, (idx) => {
            return (
              <li className="cms-facet__option" key={idx}>
                <div className="cms-facet__link">
                  <div className="cms-facet__link__image__wrap">
                    <div className="cms-facet__link__image" />
                  </div>
                  <div className="cms-facet__link__text" />
                </div>
              </li>
            );
          })}
        </ul>
      </nav>
    </div>
  );
}

export class SubcategoryNav extends React.Component<ChildProps<IProps, Commons_Cms_SubcategoryNavQuery>, null> {
  buildLink(category: Commons_Cms_SubcategoryNavQuery['categories'][0]): string {
    if (this.props.linkOverride) {
      const parts = this.props.linkOverride.split('?');
      const path = parts[0];
      const parsedQuery = parse(parts[1]);
      let query: string;

      if (!category.rootSlug) {
        query = stringify({ product_type: category.slug, ...parsedQuery });
      } else {
        query = stringify({
          product_type: category.rootSlug,
          category: category.slug,
          ...parsedQuery,
        });
      }

      return `${path}?${query}`;
    }

    return category.cmsLink.href;
  }

  getBackgroundImageStyle(cat) {
    const src = get(cat.images[0], 'source');
    if (!src) return null;

    return { backgroundImage: `url(${src})` };
  }

  renderParentCategories(parentCategories) {
    if (parentCategories.length === 0) return null;

    return (
      <div className="cms-facet__link__parents-wrapper">
        {parentCategories.map((parent, idx) => (
          <CoreLink
            to={this.buildLink(parent)}
            entityId={parent.id}
            className="cms-facet__link cms-facet__link--parent"
            key={idx}
            position={idx}
          >
            <span>{parent.name}</span>
          </CoreLink>
        ))}
      </div>
    );
  }

  categories() {
    return this.props.data.categories || [];
  }

  renderChildrenCategories(childCategories) {
    if (childCategories.length === 0) return null;

    return (
      <ul className="cms-facet__options">
        {childCategories.map((cat, idx) => (
          <li
            key={idx}
            className="cms-facet__option"
          >
            <CoreLink
              to={this.buildLink(cat)}
              entityId={cat.id}
              className="cms-facet__link"
              key={idx}
              position={idx}
            >
              <div className="cms-facet__link__image__wrap">
                <div
                  className="cms-facet__link__image"
                  style={this.getBackgroundImageStyle(cat)}
                />
              </div>
              <div className="cms-facet__link__text">
                {cat.name}
              </div>
            </CoreLink>
          </li>
        ))}
      </ul>
    );
  }

  render() {
    if (this.props.data.loading) {
      return <Placeholder />;
    }

    if (!this.props.data.loading && !this.categories().length) {
      return null;
    }

    const category = this.categories()[0];
    const classes = classNames(
      'cms-facets',
      { 'cms-facets--subcategory': !!category.parentCategories },
    );

    return (
      <div
        className={classes}
        id={this.props.jumplinkSlug || null}
      >
        <nav aria-label={category.name}>
          {this.renderParentCategories(category.parentCategories)}
          {category.childCategories.length !== 0 &&
            <div className="cms-facets__wrapper">
              <div className="cms-facet__link cms-facet__link--current">
                {category.name}
              </div>
              <hr className="mtb-2" />
              {this.renderChildrenCategories(category.childCategories)}
            </div>
          }
        </nav>
      </div>
    );
  }
}

const connect = withGraphql<IProps, Commons_Cms_SubcategoryNavQuery, Commons_Cms_SubcategoryNavQueryVariables>(
  gql`
  query Commons_Cms_SubcategoryNav(
    $ids: [String]
    $slugs: [String]
  )
  {
    categories(input:{
      ids: $ids,
      slugs: $slugs,
      withChildren: true,
      withParent: true,
    }) {
      _id
      id
      name
      slug
      rootSlug
      cmsLink {
        href
      }
      images(input:{
        type:"Category",
        count:1,
        transform:"card_wide",
        scope:"full_bleed_brand"
      }) {
        _id
        source
      }
      childCategories(input: {
        withAggregations: CATEGORY_UUIDS
      }) {
        _id
        id
        name
        slug
        rootSlug
        cmsLink {
          href
        }
        images(input:{
          type:"Category",
          count:1,
          transform:"card_wide",
          scope:"full_bleed_brand"
        }) {
          _id
          source
        }
      }
      parentCategories {
        _id
        id
        name
        slug
        rootSlug
        cmsLink {
          href
        }
        images(input:{
          type:"Category",
          count:1,
          transform:"card_wide",
          scope:"full_bleed_brand"
        }) {
          _id
          source
        }
      }
    }
  }
  `,
  {
    options: ownProps => {
      const slugs = ownProps.subCategory ? [ownProps.productType, ownProps.subCategory].join('/') : ownProps.productType;

      return {
        ssr: ownProps.ssr,
        variables: {
          ids: ownProps.categoryUUID ? [ownProps.categoryUUID] : undefined,
          slugs: ownProps.productType ? [slugs] : undefined,
        },
      };
    },
  },
);

export default connect(SubcategoryNav);
