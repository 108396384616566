import { gql } from './gql';
import { ADYEN_ENVIRONMENT, ADYEN_CLIENT_KEY } from '@reverbdotcom/env';
import { useQuery } from './useQuery';

export interface AdyenEnvironment {
  clientKey: string;
  environment: string;
}

const defaultAdyenEnvironment: AdyenEnvironment = {
  clientKey: ADYEN_CLIENT_KEY,
  environment: ADYEN_ENVIRONMENT,
};

export const GET_ADYEN_REGION = gql(`
  query Core_GetAdyenRegion {
    getAdyenRegion {
      webRegion
    }
  }
`);

export function useAdyenEnvironment(): AdyenEnvironment {
  const { data, loading, errors } = useQuery(GET_ADYEN_REGION);
  if (loading) {
    return {
      clientKey: null,
      environment: null,
    };
  } else if (errors) {
    return defaultAdyenEnvironment;
  }

  return {
    clientKey: ADYEN_CLIENT_KEY,
    environment: data.getAdyenRegion.webRegion,
  };
}
