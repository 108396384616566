import React from 'react';
import I18n from 'i18n-js';
import useDismissibleAlert from '@reverbdotcom/commons/src/components/use_dismissible_alert';
import { MParticleEventName } from '@reverbdotcom/commons/src/elog/mparticle_types';
import { trackEvent } from '@reverbdotcom/commons/src/elog/mparticle_tracker';
import { RCCloseButton } from '@reverbdotcom/cadence/components';

export const COMPONENT_NAME = 'SecondaryNagBanner';
const DISMISSIBLE_KEY = 'secondary_marketing_banner_april_2_2025_contents';

interface IProps {
  bannerText?:string;
  buttonText?: string;
  dismissibleKey?: string;
}

function SecondaryNagBanner( {
  bannerText = I18n.t('discovery.secondaryNagBanner.content'),
  buttonText = I18n.t('discovery.secondaryNagBanner.dismissButton'),
  dismissibleKey = DISMISSIBLE_KEY,
} : IProps) {
  const [dismissed, dismiss] = useDismissibleAlert(dismissibleKey);

  if (dismissed) {
    return null;
  }

  const onDismiss = () => {
    dismiss();

    trackEvent({
      eventName: MParticleEventName.DismissedSecondaryNagBanner,
      componentName: COMPONENT_NAME,
    });
  };

  return (
    <div className="secondary-nag-banner">
      <div className="secondary-nag-banner__inner">
        <div className="secondary-nag-banner__text">
          <span className="mr-1">{bannerText}</span>
          <RCCloseButton
            onClick={onDismiss}
            aria-label={buttonText}
          />
        </div>
      </div>
    </div>
  );
}

export default SecondaryNagBanner;
