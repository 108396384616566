import URLHelpers from './shared/url_helpers';
import { Listing } from '@reverbdotcom/commons/src/gql/graphql';

// Root Category UUIDs
export const ELECTRIC_GUITAR_UUID = 'dfd39027-d134-4353-b9e4-57dc6be791b9';
export const ACOUSTIC_GUITAR_UUID = '3ca3eb03-7eac-477d-b253-15ce603d2550';
export const BASS_GUITAR_UUID = '53a9c7d7-d73d-4e7f-905c-553503e50a90';
export const KEYBOARD_AND_SYNTH_UUID = 'd002db05-ab63-4c79-999c-d49bbe8d7739';
export const EFFECTS_AND_PEDALS_UUID = 'fa10f97c-dd98-4a8f-933b-8cb55eb653dd';
export const DRUM_AND_PERCUSSION_UUID = 'b3cb9f8e-4cb6-4325-8215-1efcd9999daf';
export const AMP_UUID = '09055aa7-ed49-459d-9452-aa959f288dc2';
export const PRO_AUDIO_UUID = 'b021203f-1ed8-476c-a8fc-32d4e3b0ef9e';
export const HOME_AUDIO_UUID = '40e8bfd0-3021-43f7-b104-9d7b19af5c2b';
export const DJ_AND_LIGHTING_GEAR_UUID = '58d889f7-0aa1-4689-a9d3-da16dd225e8d';

// Subcategory UUIDs
export const SYNTH_UUID = 'd6b798a5-113c-4aa0-9cc5-ff58f4a14fc1';
export const DRUM_MACHINES_UUID = 'e36bdc32-abba-45e2-948b-ce60153cbdd9';
export const SAMPLERS_UUID = '5e3c7bdb-469a-4a22-bbb2-85ddf8bff3c9';
export const CONTROLLERS_UUID = 'fa8d98c5-3538-46d1-b74a-d48c5222f889';
export const DIGITAL_PIANOS_UUID = 'e8a9fc21-1e7b-475a-8928-5847fe1b5cca';
export const KEYBOARD_PARTS_UUID = '6ae610f4-7c96-48ef-a94d-5e65f2c801b4';
export const WORKSTATIONS_UUID = '4caba01d-fef3-4f67-9c2c-a5116d1faf0a';
export const ELECTRIC_PIANOS_UUID = '148977b8-b308-4364-89fc-95859d2b3bc3';
export const CYMBALS_UUID = '3a410221-65c5-424f-b729-78ca1cbd66a8';
export const AUXILIARY_PERCUSSION_UUID = '8260fe5a-bc98-4600-a64c-e1564bb8ee5b';
export const HAND_DRUMS_UUID = '7dd339e2-d205-4d4b-a9a9-b37eb3c7d266';
// Accessory Subcategory UUIDs
export const HOME_AUDIO_PARTS_AND_ACCESSORIES_UUID = 'b6895ed9-1d96-4f5e-8651-81bf5c3db748';
export const KEYBOARD_ACCESSORIES_UUID = '271e38fa-a852-48a9-8785-3883023f9742';
export const WOODWIND_ACCESSORIES_UUID = '0aa66738-2512-4952-8626-4edec26c7a01';
export const BRASS_ACCESSORIES_UUID = 'dcd03dd7-e1bf-4fb9-84a5-0e5126a3fc64';
export const SPEAKER_PARTS_AND_ACCESSORIES_UUID = '3f6b77c2-996b-4e41-a6c2-2bd4bb6af4e3';
export const HEADPHONE_PARTS_AND_ACCESSORIES_UUID = '96f2cafd-ada4-4864-8f5b-2c8739318892';
export const STRING_ACCESSORIES_UUID = 'ac90b7da-303f-4219-b6ea-3270d63d6a05';
export const TURNTABLE_PARTS_AND_ACCESSORIES_UUID = '4fc2f5ec-384c-49dd-adb9-4f437296ce0b';
export const DRUMS_PARTS_AND_ACCESSORIES_UUID = '14aea629-7d78-4a57-8195-167e5713f3bc';
export const PRO_AUDIO_ACCESSORIES_UUID = '008f4540-8b55-4136-ba64-ee05292ae586';

const ACCESSORY_CATEGORIES = [
  HOME_AUDIO_PARTS_AND_ACCESSORIES_UUID,
  KEYBOARD_ACCESSORIES_UUID,
  WOODWIND_ACCESSORIES_UUID,
  BRASS_ACCESSORIES_UUID,
  SPEAKER_PARTS_AND_ACCESSORIES_UUID,
  HEADPHONE_PARTS_AND_ACCESSORIES_UUID,
  STRING_ACCESSORIES_UUID,
  TURNTABLE_PARTS_AND_ACCESSORIES_UUID,
  DRUMS_PARTS_AND_ACCESSORIES_UUID,
  PRO_AUDIO_ACCESSORIES_UUID,
];

// special leaf Category UUIDs
export const STEREO_RECEIVER_UUID = '777d30e0-a38d-42dd-8ada-c49e7d01df25';
export const MODULAR_GEAR_UUID = '47ebdc60-1ee5-4221-9b0b-fc45396fc003'; // 500 Series Specifically

const LEAF_CATEGORIES_WITH_SHIPPING_DETAILS = [
  STEREO_RECEIVER_UUID,
  MODULAR_GEAR_UUID,
];
export const DEFAULT_DETAILS = 'default_details';

interface ShippingGuideMappingObject {
  guideLink: string;
  i18ItemKey: string;
}

interface ShippingGuideMappings {
  [key: string]: ShippingGuideMappingObject;
}

export const SHIPPING_GUIDE_MAPPINGS:ShippingGuideMappings = {
  [ELECTRIC_GUITAR_UUID]: {
    guideLink: URLHelpers.guitarShippingGuidePath,
    i18ItemKey: 'guitars',
  },
  [ACOUSTIC_GUITAR_UUID]: {
    guideLink: URLHelpers.guitarShippingGuidePath,
    i18ItemKey: 'guitars',
  },
  [BASS_GUITAR_UUID]: {
    guideLink: URLHelpers.guitarShippingGuidePath,
    i18ItemKey: 'guitars',
  },
  [KEYBOARD_AND_SYNTH_UUID]: {
    guideLink: URLHelpers.keyboardAndSynthShippingGuidePath,
    i18ItemKey: 'keyboards',
  },
  [EFFECTS_AND_PEDALS_UUID]: {
    guideLink: URLHelpers.effectsAndPedalsShippingGuidePath,
    i18ItemKey: 'effectsAndPedals',
  },
  [DRUM_AND_PERCUSSION_UUID]: {
    i18ItemKey: 'drumKits',
    guideLink: URLHelpers.drumAndPercussionShippingGuidePath,
  },
  [AMP_UUID]: {
    i18ItemKey: 'amps',
    guideLink: URLHelpers.ampShippingGuidePath,
  },
  [PRO_AUDIO_UUID]: {
    i18ItemKey: 'proAudioGear',
    guideLink: URLHelpers.proAudioShippingGuidePath,
  },
  [MODULAR_GEAR_UUID]: {
    i18ItemKey: 'modular',
    guideLink: URLHelpers.modularGearShippingGuidePath,
  },
  [STEREO_RECEIVER_UUID]: {
    i18ItemKey: 'stereoReceivers',
    guideLink: URLHelpers.stereoReceiverShippingGuidePath,
  },
  [DEFAULT_DETAILS]: {
    i18ItemKey: 'generalGear',
    guideLink: URLHelpers.defaultShippingGuidePath,
  },
};

export function shippingGuideDetailsForListing(listing: Listing) {
  const allCategoryUuids = listing.categoryUuids ?? [];
  return shippingGuideDetailsForCategoryUuids(allCategoryUuids);
}

export function shippingGuideDetailsForCategoryUuids(categoryUuids: string[]) {
  const bestCategoryUuid = leafCategoryWithShippingGuideDetails(categoryUuids) || accessoryCategory(categoryUuids) || categoryWithShippingGuideDetails(categoryUuids);
  return SHIPPING_GUIDE_MAPPINGS[bestCategoryUuid] || SHIPPING_GUIDE_MAPPINGS[DEFAULT_DETAILS];
}

function leafCategoryWithShippingGuideDetails(categoryUuids: string[]) {
  return categoryUuids.find(uuid => LEAF_CATEGORIES_WITH_SHIPPING_DETAILS.includes(uuid));
}

function accessoryCategory(categoryUuids: string[]) {
  return categoryUuids.find(uuid => ACCESSORY_CATEGORIES.includes(uuid));
}

function categoryWithShippingGuideDetails(categoryUuids: string[]) {
  return categoryUuids.find(uuid => Object.keys(SHIPPING_GUIDE_MAPPINGS).includes(uuid));
}
