import React from 'react';
import I18n from 'i18n-js';
import { Location } from 'history';
import { buttonClassName, RCChip, RCDrawer, RCTextWithIcon, useMediaQuery } from '@reverbdotcom/cadence/components';
import { SlidersIcon } from '@reverbdotcom/cadence/icons/react';

import RQLFacets from './rql_facets';
import { Filter, WidgetType } from './search_filters';
import { useUser } from '../user_hooks';
import SelectFacet from './select_facet';

import { MParticleEventName, trackEvent } from '../elog/mparticle_tracker';
import CoreLink from './core_link';
import { IUser } from './user_context_provider';

interface IProps {
  filters: Filter[];
  loading?: boolean;
  resultsTotal?: number;
  trackingQuery?: string;
  location: Location;
  hideSidebarOnMobile?: boolean;
  clearFiltersUrl?: string;
  hideClearFiltersButton?: boolean;
  toggleExposedSidebarOpen?: () => void;
  isFilterOpenByDefault?: (filter: Filter) => boolean;
}

export default function MarketplaceFilterSidebar({
  filters = [],
  loading = false,
  resultsTotal = null,
  trackingQuery = '',
  location = null,
  hideSidebarOnMobile = false,
  clearFiltersUrl = undefined,
  hideClearFiltersButton = false,
  toggleExposedSidebarOpen = undefined,
  isFilterOpenByDefault = undefined,
}: IProps) {
  const [isOpen, setIsOpen] = React.useState(false);
  const sort = filters.find(filter => filter.widgetType === WidgetType.SORT);
  const user = useUser();
  const { pathname } = location;
  const isMobile = useMediaQuery('mobile');

  const filtersToShow = visibleFilters(filters, { user, pathname });

  const buttonText = !resultsTotal ?
    I18n.t('commons.marketplaceFilters.viewResults') :
    I18n.t('commons.marketplaceFilters.showResultsCount',
      { count: I18n.toNumber(resultsTotal, { precision: 0 }) },
    );

  const trackFilterChipClick = (opened) => {
    trackEvent({
      componentName: 'FilterChip',
      eventName: MParticleEventName.ClickedSearchFilterChip,
      displayStyle: opened ? 'opened' : 'closed',
      parent: 'ALL',
      query: trackingQuery,
    });
  };

  if (hideSidebarOnMobile && isMobile) return null;

  return (
    <>
      <div className="marketplace-filter marketplace-filter--all-filters">
        <RCChip
          loading={loading}
          onClick={() => {
            setIsOpen(true);
            toggleExposedSidebarOpen?.();
          }}
        >
          <RCTextWithIcon
            svgComponent={SlidersIcon}
            placement="left"
            size="large"
          >
            {I18n.t('commons.marketplaceFilters.allFilters')}
          </RCTextWithIcon>
        </RCChip>
        <RCDrawer
          isOpen={isOpen}
          onOpenChange={(state) => {
            setIsOpen(state);
            trackFilterChipClick(state);
            toggleExposedSidebarOpen?.();
          }}
          title={I18n.t('commons.marketplaceFilters.filterYourSearch')}
          actions={{
            primary: {
              buttonText: buttonText,
              fullWidth: true,
            },
          }}
        >
          {clearFiltersUrl && !hideClearFiltersButton &&
            <div className="bdb-1 bd-color-primary pb-4">
              <CoreLink
                to={clearFiltersUrl}
                className={buttonClassName({
                  size: 'small',
                })}
              >
                {I18n.t('commons.marketplaceFilters.clearFilters')}
              </CoreLink>
            </div>
          }
          {sort &&
            <div className="d-none mobile-d-block pb-4 bdb-1 bd-color-primary">
              <SelectFacet
                pageSection="sidebar"
                filter={sort}
                trackingQuery={trackingQuery}
              />
            </div>
          }
          <RQLFacets
            filters={filtersToShow}
            loading={loading}
            trackingQuery={trackingQuery}
            isFilterOpenByDefault={isFilterOpenByDefault}
          />
        </RCDrawer>
      </div>
    </>
  );
}

export function visibleFilters(
  filters: Filter[],
  { user, pathname }: { user: Partial<IUser>, pathname: string },
): Filter[] {
  return filters.filter(f => {
    const key = f.aggregationName || f.key;
    if (pathname?.includes('/shop') && key === 'SELLER_LOCATION') return false;
    if (pathname?.includes('/p/') && key === 'SHOW_ONLY') return false;
    if (key === 'LOCATION' && user.shippingRegionCode !== 'US_CON') return false;
    return true;
  });
}
