import React from 'react';
import I18n from 'i18n-js';
import AmexSecurityCodeIcon from '../../images/icons/checkout-icons/amex-security-code.svg';
import DefaultSecurityCodeIcon from '../../images/icons/checkout-icons/default-security-code.svg';

interface Props {
  brand: string;
  cardNumberFieldErrorText: string;
}

export default function CreditCardSecurityCodeIcon(props: Props) {
  const UNIDENTIFIED_CARD = 'card';
  const AMEX = 'amex';

  if (props.cardNumberFieldErrorText || !props.brand || props.brand === UNIDENTIFIED_CARD) {
    return null;
  }

  function cardTypeDetails() {
    if (![AMEX, UNIDENTIFIED_CARD].includes(props.brand)) {
      return {
        image: DefaultSecurityCodeIcon,
        alt: I18n.t('discovery.creditCardForm.cvvDefaultAlt'),
        text: I18n.t('discovery.creditCardForm.cvvDefaultLocation'),
      };
    }

    if (props.brand === AMEX) {
      return {
        image: AmexSecurityCodeIcon,
        alt: I18n.t('discovery.creditCardForm.cvvAmexAlt'),
        text: I18n.t('discovery.creditCardForm.cvvAmexLocation'),
      };
    }
  }

  return (
    <div className="d-flex fx-align-center">
      <img
        className="d-block mr-2"
        style={{ height: '3.6rem', width: 'auto' }} // This forces the card to be the same height as the input field
        src={cardTypeDetails().image}
        alt={cardTypeDetails().alt}
      />
      <span className="color-light-gray size-90 lh-110">
        {cardTypeDetails().text}
      </span>
    </div>
  );
}
