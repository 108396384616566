import { IUser } from '@reverbdotcom/commons/src/components/user_context_provider';
import { experimentInt } from '@reverbdotcom/commons/src/user_context_helpers';
import { isMarketplacePage } from '@reverbdotcom/commons/src/url_helpers';
import { Location } from 'history';

interface ExternalProps {
  shouldApplyLikelihoodToSellBoosts?: boolean;
}

export function buildMultiClientExperimentGroups(user: Partial<IUser>, location: Location, args: ExternalProps) {
  if (!isMarketplacePage(location)) {
    return [];
  }
  const userExperiments = user?.experiments?.filter(exp => exp.use_in_marketplace_listings_search) || [];
  let multiClientExperimentGroups = [];
  if (userExperiments.length) {
    multiClientExperimentGroups = userExperiments.map((exp) => {
      return experimentGroupHash(user, exp.name);
    });
  }

  let optOutOf = [];
  if (args?.shouldApplyLikelihoodToSellBoosts === true) {
    optOutOf = [];
  }

  multiClientExperimentGroups = multiClientExperimentGroups.filter(exp => !optOutOf.includes(exp.name));
  return multiClientExperimentGroups;
}

function experimentGroupHash(user: Partial<IUser>, experimentName: string) {
  return ({ name: experimentName, group: experimentInt(user, experimentName).toString() });
}
