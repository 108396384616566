import React from 'react';
import I18n from 'i18n-js';

import { I18N as Translate } from '@reverbdotcom/commons/src/components/translate';
import { useHelpCenterArticleUrl } from '@reverbdotcom/commons/src/url_helpers';
import { RCButton, RCLoadingBars, RCSelect, buttonClassName } from '@reverbdotcom/cadence/components';
import ModalConfirmation from '@reverbdotcom/commons/src/components/modal_confirmation';
import { ExclamationCircleIcon } from '@reverbdotcom/cadence/icons/react';

import { gql } from '@reverbdotcom/commons/src/gql';
import { useMutation } from '@reverbdotcom/commons/src/useMutation';
import { ErrorAlertBox } from '@reverbdotcom/commons/src/ErrorAlertBox';
import CoreLink from '@reverbdotcom/commons/src/components/core_link';

export interface IProps {
  hasPendingAccountDeletionRequest: boolean;
}

const reachOutToUsHelpCenterArticleID = '115015709067';
const learnMoreAboutPolicies = '360004097554';

const ACCOUNT_DELETION_REASONS = [
  {
    key: 'no_longer_want_account',
    displayName: 'discovery.accountDeletion.reasons.noLongerWantAccount',
  },
  {
    key: 'bad_user_experience',
    displayName: 'discovery.accountDeletion.reasons.badUserExperience',
  },
  {
    key: 'fraud',
    displayName: 'discovery.accountDeletion.reasons.fraud',
  },
  {
    key: 'other',
    displayName: 'discovery.accountDeletion.reasons.other',
  },
];

export const REQUEST_ACCOUNT_DELETION = gql(`
  mutation Core_RequestAccountDeletion(
    $input: Input_core_apimessages_RequestAccountDeletionRequest
  ) {
    requestAccountDeletion(input: $input){
      uuid
    }
  }
`);

export default function AccountDeletion(props: IProps) {
  const [submittedRequest, setSubmittedRequest] = React.useState(false);
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const hideDeleteAccountButton = props.hasPendingAccountDeletionRequest || submittedRequest;
  const [reason, setReason] = React.useState('');
  const isValidForAccountDeletion = !!reason;
  const reachOutToUsHelpCenterArticleLink = useHelpCenterArticleUrl(reachOutToUsHelpCenterArticleID);
  const learnEUPoliciesLink = useHelpCenterArticleUrl(learnMoreAboutPolicies);
  const [deleteAccountMutation, {
    data,
    loading,
    errors,
  }] = useMutation(REQUEST_ACCOUNT_DELETION);

  React.useEffect(() => {
    if (loading) return;

    if (errors.length) {
      setIsModalOpen(false);
    }

    if (data?.requestAccountDeletion?.uuid) {
      setIsModalOpen(false);
      setSubmittedRequest(true);
    }
  });

  return (
    <>
      <div className="d-flex fx-dir-col mtb-8 gap-2 width-60 mobile-width-100 tablet-width-90">
        <div>
          <Translate
            tag="div"
            className="weight-bold size-180"
            text="discovery.accountDeletion.deleteAccount"
          />

          <Translate
            tag="div"
            text="discovery.accountDeletion.poorExperienceHtml"
          >
            <a id="reachOutToUsLink" href={reachOutToUsHelpCenterArticleLink} target="_blank" className="text-link" rel="noreferrer">
              <Translate text="discovery.accountDeletion.reachOutLinkText" />
            </a>
          </Translate>
        </div>

        <div className="mtb-4">
          {hideDeleteAccountButton ? (
            <Translate
              className="weight-bold mb-2"
              tag="div"
              text="discovery.accountDeletion.submittedRequest"
            />
          ) : (
            <Translate
              className="weight-bold mb-2"
              tag="div"
              text="discovery.accountDeletion.aboutAD"
            />
          )}

          {hideDeleteAccountButton ? (
            <div className="d-flex fx-dir-col gap-4">
              <Translate
                tag="div"
                text="discovery.accountDeletion.complyEU"
              >
                <a id="reachOutToUsLink" href={learnEUPoliciesLink} target="_blank" className="text-link" rel="noreferrer">
                  <Translate text="discovery.accountDeletion.learnMoreLink" />
                </a>
              </Translate>

              <Translate
                tag="div"
                text="discovery.accountDeletion.retain"
              />
            </div>
          ) : (
            <div>
              <ul id="deletionBulletPoints" className="list-type-disc ml-2">
                <Translate
                  tag="li"
                  text="discovery.accountDeletion.bulletPoints.permanent"
                />
                <Translate
                  tag="li"
                  text="discovery.accountDeletion.bulletPoints.history"
                />
                <Translate
                  tag="li"
                  text="discovery.accountDeletion.bulletPoints.activeSales"
                />
              </ul>

              <Translate
                tag="div"
                className="mtb-4"
                text="discovery.accountDeletion.selectReason"
              />

              <RCSelect
                id="account-deletion-reason-item"
                name="account-deletion-reason"
                label={I18n.t('discovery.accountDeletion.reasons.label')}
                disabled={hideDeleteAccountButton}
                addEmptyOption
                emptyOptionLabel={I18n.t('discovery.accountDeletion.reasons.emptyLabel')}
                required
                tag="required"
                value={reason}
                onChange={(e) => {setReason(e.target.value);}}
              >
                {ACCOUNT_DELETION_REASONS.map((option) => (
                  <RCSelect.Option
                    key={option.key}
                    value={option.key}
                    label={I18n.t(option.displayName)}
                  />
                ))}
              </RCSelect>
            </div>
          )}
        </div>

        <div className="width-30 mobile-width-100">
          {hideDeleteAccountButton ? (
            <CoreLink
              to={reachOutToUsHelpCenterArticleLink}
              className={buttonClassName({ variant: 'muted', fullWidth: true })}
            >
              <Translate text="discovery.accountDeletion.needMoreHelp"/>
            </CoreLink>
          )
            : (
              <RCButton
                onClick={() => setIsModalOpen(true)}
                variant="filled"
                theme="danger"
                fullWidth
                disabled={!isValidForAccountDeletion}
              >
                {loading ? <RCLoadingBars /> : <Translate text="discovery.accountDeletion.deleteMyAccount" />}
              </RCButton>
            )}
        </div>
      </div>

      {
        errors.length > 0 && (
          <div className="ml-2">
            <ErrorAlertBox errors={errors} />
          </div>
        )
      }

      <ModalConfirmation
        title={I18n.t('discovery.accountDeletion.areYouSure')}
        content={I18n.t('discovery.accountDeletion.note')}
        confirmButtonText={I18n.t('discovery.accountDeletion.deleteMyAccount')}
        isOpen={isModalOpen}
        theme="danger"
        onRequestClose={() => (setIsModalOpen(false))}
        onConfirm={ () => deleteAccountMutation({
          variables: {
            input: {
              reason: reason,
            },
          },
        }) }
        icon={ExclamationCircleIcon}
      />
    </>
  );
}
