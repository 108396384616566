import React from 'react';
import PropTypes from 'prop-types';
import { CURRENCY_CODES } from '../shared/constants';
import { SanitizedRender } from '../index';
import I18n from 'i18n-js';

function PaypalDescriptionBullets(props) {
  function htmlLineItem(text) {
    return (
      <li dangerouslySetInnerHTML={{ __html: text }} />
    );
  }

  function mutedCaretIcon() {
    return '<span class="fa fa-caret-right fa-li muted"></span>';
  }

  function paypalFeesLineItemText(shopCurrency) {
    if (shopCurrency === CURRENCY_CODES.EUR) {
      return 'js.dashboard.selling.shopPolicies.paypalFeesEURHtml';
    } if (shopCurrency === CURRENCY_CODES.GBP) {
      return 'js.dashboard.selling.shopPolicies.paypalFeesGBPHtml';
    }

    return 'js.dashboard.selling.shopPolicies.paypalFeesHtml';
  }

  function paypalFeesLink() {
    return (
      <p className="mt-2 mb-0 size-80 opacity-80">
        <span>
          <SanitizedRender
            html={
              I18n.t(
                'js.dashboard.selling.shopPolicies.paypalFeesMayVaryHtml',
                {
                  linkOpen: '<a href=\'https://www.paypal.com/us/webapps/mpp/merchant-fees\' target=\'_blank\' rel=\'noreferrer noopener\'>',
                  linkClose: '</a>',
                },
              )
            }
          />
        </span>
      </p>
    );
  }

  return (
    <div>
      <ul className="fa-ul font-tiny normal">
        {htmlLineItem(I18n.t(
          paypalFeesLineItemText(props.shopCurrency),
          { icon: mutedCaretIcon() },
        ))}
        {htmlLineItem(I18n.t('js.dashboard.selling.shopPolicies.paypalPoliciesApplyHtml', { icon: mutedCaretIcon() }))}
      </ul>
      {paypalFeesLink()}
    </div>
  );
}

PaypalDescriptionBullets.propTypes = {
  shopCurrency: PropTypes.string.isRequired,
};

export default PaypalDescriptionBullets;
