export default {
  modal: {
    index: {
      title: 'Choose a Card',
      saveButton: 'Use Card',
      backButton: 'Cancel',
    },
    create: {
      title: 'Add New Card',
      saveButton: 'Save & Use Card',
      backButton: 'Go back',
    },
    edit: {
      title: 'Edit Card',
      saveButton: 'Save & Use Card',
      backButton: 'Go back',
    },
  },
  createForm: {
    cardNumber: 'Card Number',
    expiration: 'Expiration',
    securityCode: 'Security Code',
    billingSame: 'Billing address is the same as shipping address',
    billingAddressTitle: 'Billing Address',
  },
  editForm: {
    expirationMonth: 'Expiration Month',
    expirationYear: 'Expiration Year',
    cardholderName: 'Cardholder Name',
    saveDefault: 'Save as default card',
  },
  editButton: 'Edit',
  cardTroubleshooting: 'Troubleshooting tips',
};
