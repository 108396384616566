import React from 'react';
import I18n from 'i18n-js';
import { I18N as Translate } from '@reverbdotcom/commons/src/components/translate';
import Modal from '@reverbdotcom/commons/src/components/modal';
import { Paths } from '../shared/url_helpers';
import { MParticleEventName } from '@reverbdotcom/commons/src/elog/mparticle_tracker';
import { useViewTracking } from '@reverbdotcom/commons/src/use_tracking';

export function SecureCheckoutMessage() {
  const [isModalOpen, toggleModal] = React.useState(false);
  const openModal = () => toggleModal(true);
  const closeModal = () => toggleModal(false);

  useViewTracking({
    componentName: 'SecureCheckoutMessage',
    eventName: MParticleEventName.ComponentView,
  }, isModalOpen);

  return (
    <>
      <div className="secure-checkout-message">
        <button
          className="secure-checkout-message__trigger"
          type="button"
          onClick={openModal}
        >
          {I18n.t('discovery.secureCheckoutMessage.secureCheckout')}
        </button>
      </div>
      <Modal
        isOpen={isModalOpen}
        headerTitle={I18n.t('discovery.secureCheckoutMessage.securityInfo')}
        onRequestClose={closeModal}
        paddedBody
      >
        <h1 className="size-120 weight-bold scaling-mb-6">
          {I18n.t('discovery.secureCheckoutMessage.howDoesReverbSecureInfo')}
        </h1>
        <p className="scaling-mb-4">
          {I18n.t('discovery.secureCheckoutMessage.cardsUseTLS')}
        </p>
        <p className="mb-0">
          <Translate
            html
            text="discovery.secureCheckoutMessage.stillHaveQuestions"
            args={{ linkOpen: `<a href="${Paths.helpCenterNewRequest.expand({ })}" target="_blank">`, linkClose: '</a>' }} />
        </p>
      </Modal>
    </>
  );
}
