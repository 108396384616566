import PropTypes from 'prop-types';
import React from 'react';

import I18n from 'i18n-js';
import { RCTooltip } from '@reverbdotcom/cadence/components';

const markReadTooltip = I18n.t('js.dashboard.messages.markAsReadTooltip');
const markUnreadTooltip = I18n.t('js.dashboard.messages.markAsUnreadTooltip');

interface IProps {
  placement: 'top' | 'bottom' | 'left' | 'right',
  read: boolean,
  href: string,
  dataBind: string,
}

// This component is a wrapper to get the `toggle_read_status` jquery working with the a11y Tooltip component
// app/assets/javascripts/components/legacy/dashboard/messages/toggle_read_status.js
function ReadMessagesToggle(props: IProps) {
  const {
    read,
    placement,
    href,
    dataBind,
  } = props;
  const [readState, setReadState] = React.useState(read);

  const icon = read ? 'fa fa-circle-o' : 'fa fa-circle';
  const text = readState ? markUnreadTooltip : markReadTooltip;

  return (
    <RCTooltip
      type="plain"
      text={text}
      placement={placement}
    >
      <a
        href={href}
        data-method="put"
        data-remote="true"
        aria-label={text}
        data-bind={dataBind}
        rel="nofollow"
        onClick={() => setReadState(val => !val)}
      >
        <span className={icon} />
      </a>
    </RCTooltip>
  );
}

ReadMessagesToggle.propTypes = {
  placement: PropTypes.oneOf(['top', 'bottom', 'left', 'right']),
  read: PropTypes.bool.isRequired,
  href: PropTypes.string.isRequired,
  dataBind: PropTypes.string.isRequired,
};

ReadMessagesToggle.defaultProps = {
  placement: 'top',
};

export default ReadMessagesToggle;
