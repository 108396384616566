import React from 'react';
import classNames from 'classnames';

import CoreLink from '../../components/core_link';
import MarkdownOutput from './markdown_output';

import { IDynamicComponentProps } from '../dynamic_component_props';

interface IProps extends IDynamicComponentProps {
  content: string;
  contentHtml?: string;
  attribution?: string;
  attributionHtml?: string;
  url?: string;
  alignment: 'right' | 'left' | 'center';
  markdownParser?: any;
}

export default class PullquoteEmbed extends React.Component<IProps, null> {
  renderAttributionText() {
    return (
      <MarkdownOutput
        text={this.props.attribution}
        textHtml={this.props.attributionHtml}
        markdownParser={this.props.markdownParser}
        htmlTag="div"
      />
    );
  }

  renderAttribution() {
    if (!this.props.attribution) return;
    if (!this.props.url) {
      return (
        <div className="pullquote__attribution">
          {this.renderAttributionText()}
        </div>
      );
    }

    return (
      <div className="pullquote__attribution">
        <CoreLink to={this.props.url}>
          {this.renderAttributionText()}
        </CoreLink>
      </div>
    );
  }

  render() {
    const classes = classNames(
      'pullquote',
      { 'pullquote--long': this.props.content.length > 100 },
      { 'pullquote--left': this.props.alignment === 'left' },
      { 'pullquote--right': this.props.alignment === 'right' },
      { 'pullquote--center': this.props.alignment === 'center' },
    );

    return (
      <div className={classes}>
        <div className="pullquote__inner">
          <MarkdownOutput
            text={this.props.content}
            textHtml={this.props.contentHtml}
            markdownParser={this.props.markdownParser}
            className="pullquote__content"
            htmlTag="div"
          />
          {this.renderAttribution()}
        </div>
      </div>
    );
  }
}
