import React from 'react';
import I18n from 'i18n-js';
import SanitizedRender from '@reverbdotcom/commons/src/components/sanitized_render';
import URLHelpers from '../shared/url_helpers';

const bumpPage = URLHelpers.cmsPagePath('bump');

export function BumpExplainer() {

  return (
    <>
      <h3 className="size-120 weight-bold mb-4">
        {I18n.t('discovery.bumpExplainer.heading')}
      </h3>
      <SanitizedRender
        htmlTag="p"
        className="mb-2"
        html={I18n.t('discovery.bumpExplainer.subheading', {
          linkOpen: `<a href="${bumpPage}" target=_blank class="icon-r-external-link">`,
          linkClose: '</a>',
        })}
      />
      <ol className="cms-ol margin-0 ml-4 size-90">
        <li>
          {I18n.t('discovery.bumpExplainer.bullet1')}
        </li>
        <li>
          {I18n.t('discovery.bumpExplainer.bullet2')}
        </li>
        <li>
          {I18n.t('discovery.bumpExplainer.bullet3')}
        </li>
      </ol>
    </>
  );
}
