import React, { Dispatch } from 'react';
import I18n from 'i18n-js';
import { range, round } from 'lodash';

import { Listing } from '@reverbdotcom/commons/src/gql/graphql';
import { useMutation } from '@reverbdotcom/commons/src/useMutation';
import { ModalDialog, SanitizedRender } from '@reverbdotcom/commons';
import { I18N as Translate } from '@reverbdotcom/commons/src/components/translate';
import { RCSelect } from '@reverbdotcom/cadence/components';
import { gql } from '@reverbdotcom/commons/src/gql';

import { isListingBumpEligible } from '../../SellerListingCardFooter';
import SellerListingCardsToolbarContext from '../../SellerListingCardsToolbarContext';
import { MParticleEventName, trackEvent } from '@reverbdotcom/commons/src/elog/mparticle_tracker';
import { useViewTracking } from '@reverbdotcom/commons/src/use_tracking';
import SellerListingsCollectionContext from '../../SellerListingsCollectionContext';
import { formatBid } from '../../../../components/bump/bump_helpers';

export const COMPONENT_NAME = 'BulkCreateBumpModal';

const MIN_BUMP_RATE = 0.005;
const MAX_BUMP_RATE = 0.30;
const RATE_INCREMENT = 0.005;
const END_RATE = MAX_BUMP_RATE + RATE_INCREMENT;

export function bumpRates() {
  return range(
    MIN_BUMP_RATE,
    END_RATE,
    RATE_INCREMENT,
  ).map(percentage => round(percentage, 3));
}

export function rateDisplay(rate) {
  const percent = round(rate * 100, 2);

  return I18n.t('discovery.listingsManagement.toolbar.bulkActions.bump.createModal.rateDisplay', {
    percent,
  });
}

const BULK_CREATE_BUMPED_MUTATION = gql(`
  mutation Core_MyListings_BulkCreateBumped(
    $input: Input_core_apimessages_BulkCreateBumpedRequest
  ) {
    bulkCreateBumped(input: $input) {
      responses {
        listingId
        success
        errors {
          message
        }
      }
    }
  }
`);

const BULK_CREATE_BUMPED_ASYNC_MUTATION = gql(`
  mutation Core_MyListings_BulkCreateBumpedAsync(
    $input: Input_core_apimessages_BulkCreateBumpedAsyncRequest
  ) {
    bulkCreateBumpedAsync(input: $input) {
      enqueued
      message
    }
  }
`);

interface IExternalProps {
  closeModal: Dispatch<void>;
  selectedListings: Listing[];
}

export default function BulkCreateBumpModal({
  closeModal,
  selectedListings,
}: IExternalProps) {
  const {
    listingsCollectionState,
  } = React.useContext(SellerListingsCollectionContext);

  const {
    hasAllMatchesSelected,
    totalMatchesEligibleForBump,
    totalMatchesEnabledForBump,
    serializedDashboardSearchParams,
  } = listingsCollectionState;

  const {
    handleBulkResponses,
    handleGenericError,
    handleAsyncBulkResponse,
  } = React.useContext(SellerListingCardsToolbarContext);

  const [bumpRate, setBumpRate] = React.useState('');

  const [bulkCreateBumped, {
    data,
    loading,
    errors,
  }] = useMutation(BULK_CREATE_BUMPED_MUTATION);
  const hasErrors = !!errors.length;

  const [bulkCreateBumpedAsync, {
    data: asyncData,
    loading: asyncLoading,
    errors: asyncErrors,
  }] = useMutation(BULK_CREATE_BUMPED_ASYNC_MUTATION);
  const hasAsyncErrors = !!asyncErrors.length;

  const selectedBumpableListings = selectedListings.filter(listing => isListingBumpEligible(listing));

  const enableListings = selectedBumpableListings.filter(listing => !listing.bumpRate?.rate);
  const adjustableListings = selectedBumpableListings.filter(listing => !!listing.bumpRate?.rate);

  const countToEnable = hasAllMatchesSelected ? totalMatchesEligibleForBump : enableListings.length;
  const countToAdjust = hasAllMatchesSelected ? totalMatchesEnabledForBump : adjustableListings.length;
  const totalListingsToBump = countToEnable + countToAdjust;

  const isInvalidBumpRate = Number(bumpRate) < MIN_BUMP_RATE || Number(bumpRate) > MAX_BUMP_RATE;
  const hasNoEligibleListings = !totalListingsToBump;

  const isSubmitDisabled = hasNoEligibleListings || isInvalidBumpRate;

  React.useEffect(() => {
    if (loading) return;
    if (hasErrors) return handleUnexpectedError();

    handleResponses();
  }, [loading]);

  React.useEffect(() => {
    if (asyncLoading) return;
    if (hasAsyncErrors) return handleUnexpectedError();

    handleAsyncResponse();
  }, [asyncLoading]);

  function handleResponses() {
    if (data?.bulkCreateBumped) {
      const { responses } = data.bulkCreateBumped;

      handleBulkResponses({
        responses,
        I18nSuccessMessageKey: 'discovery.listingsManagement.toolbar.bulkActions.bump.createModal.successes',
        I18nErrorMessageKey: 'discovery.listingsManagement.toolbar.bulkActions.bump.createModal.errors.title',
        successArgs: {
          bumpRate: rateDisplay(bumpRate),
        },
      });

      closeModal();
    }
  }

  function handleAsyncResponse() {
    if (asyncData?.bulkCreateBumpedAsync) {
      handleAsyncBulkResponse({
        I18nKey: 'discovery.listingsManagement.toolbar.bulkActions.bump.createModal.asyncResponses.successes',
        expectedTotal: totalListingsToBump,
        I18nArgs: {
          bumpRate: rateDisplay(bumpRate),
        },
      });

      closeModal();
    }
  }

  function handleUnexpectedError() {
    handleGenericError();
    closeModal();
  }

  function submit() {
    trackEvent({
      componentName: COMPONENT_NAME,
      eventName: MParticleEventName.SellerBulkCreatingBump,
      newBumpRatePercent: formatBid(Number(bumpRate)),
      listingsCount: totalListingsToBump,
      hasAllMatchesSelected,
    });

    if (hasAllMatchesSelected) {
      bulkCreateBumpedAsync({
        variables: {
          input: {
            rate: Number(bumpRate),
            expectedTotal: totalListingsToBump,
            serializedDashboardSearchParams,
          },
        },
      });
    } else {
      const listingIds = selectedBumpableListings.map(listing => listing.id);

      bulkCreateBumped({
        variables: {
          input: {
            listingIds,
            rate: Number(bumpRate),
          },
        },
      });
    }
  }

  useViewTracking({
    componentName: COMPONENT_NAME,
    eventName: MParticleEventName.ComponentView,
  }, true);

  return (
    <ModalDialog
      isOpen
      onRequestClose={closeModal}
      headerTitle={I18n.t('discovery.listingsManagement.toolbar.bulkActions.bump.createModal.headerTitle')}
      saveButtonText={I18n.t('discovery.listingsManagement.toolbar.bulkActions.bump.createModal.submit')}
      isDisabled={isSubmitDisabled}
      isSaving={loading || asyncLoading}
      onSubmit={submit}
    >
      <div className="d-flex fx-dir-col gap-4">
        <Translate
          text="discovery.listingsManagement.toolbar.bulkActions.bump.createModal.buyerText"
          html
        />

        {hasNoEligibleListings ? (
          <Translate
            html
            text="discovery.listingsManagement.toolbar.bulkActions.bump.createModal.noSelectedListingsEligible"
          />
        ) : (
          <>
            <div className="d-flex fx-dir-col gap-2">
              {I18n.t('discovery.listingsManagement.toolbar.bulkActions.bump.createModal.selectingBumpWill')}
              <ul
                className="d-flex fx-dir-col gap-2 ml-6"
                style={{ listStyleType: 'disc' }}
              >
                {countToEnable > 0 && (
                  <li>
                    <SanitizedRender
                      html={I18n.t('discovery.listingsManagement.toolbar.bulkActions.bump.createModal.enableText', {
                        count: countToEnable,
                        formattedCount: I18n.toNumber(countToEnable, {
                          precision: 0,
                        }),
                      })}
                    />
                  </li>
                )}

                {countToAdjust > 0 && (
                  <li>
                    <SanitizedRender
                      html={I18n.t('discovery.listingsManagement.toolbar.bulkActions.bump.createModal.adjustText', {
                        count: countToAdjust,
                        formattedCount: I18n.toNumber(countToAdjust, {
                          precision: 0,
                        }),
                      })}
                    />
                  </li>
                )}
              </ul>
            </div>

            <RCSelect
              id="bidSelect"
              name="bidSelect"
              onChange={(e) => setBumpRate(e.target.value)}
              value={bumpRate}
              label=""
              addEmptyOption
              emptyOptionLabel={I18n.t('discovery.listingsManagement.toolbar.bulkActions.bump.createModal.input.label')}
            >
              {bumpRates().map(rate=> (
                <RCSelect.Option
                  key={rate}
                  value={rate.toString()}
                  label={rateDisplay(rate)}
                />
              ))}
            </RCSelect>
          </>
        )}
      </div>
    </ModalDialog>
  );
}
