import React from 'react';
import classNames from 'classnames';
import I18n from 'i18n-js';
import { snakeCase } from 'lodash';
import { Location } from 'history';

import FacetOption from './facet_option';
import FilterLink from './filter_link';
import { FilterOption } from './search_filters';
import { MParticleEventName } from '../elog/mparticle_types';
import { trackEvent } from '../elog/mparticle_tracker';
import ProximityFacet, { PROXIMITY_PARAM } from './proximity_facet';
import { formatExperimentsForEventAttributes } from '../elog/mparticle';
import { useUser } from '../user_hooks';
import { SelectFacetCheckbox } from './select_facet_checkbox';

export function linkParams(option: FilterOption, checkbox = false) {
  const useUnset = option.selected && checkbox;
  const values = useUnset ? option.unsetValues : option.setValues;

  return { [option.paramName]: values };
}

interface ISelectFacetOptionCountProps {
  option: FilterOption;
}

export function SelectFacetOptionCount({ option }: ISelectFacetOptionCountProps) {
  if (!option.count) {
    return null;
  }

  return (
    <span className="color-secondary weight-normal">
      ({I18n.toNumber(option.count.value, { precision: 0 })})
    </span>
  );
}

interface IProps {
  option: FilterOption;
  checkbox?: boolean;
  children?: JSX.Element;
  childActive?: boolean;
  trackingQuery?: string;
  parentNameOverride?: string;
  location?: Location;
  enableZeroCountOption?: boolean;
}

export function SelectFacetOption({
  option,
  checkbox = false,
  children = undefined,
  childActive = false,
  trackingQuery = undefined,
  parentNameOverride = undefined,
  location = undefined,
  enableZeroCountOption = false,
}: IProps) {
  const user = useUser();

  const zeroCount = option.count?.value === 0;
  const disabled = enableZeroCountOption ? false : zeroCount;
  const displayProximityFilterFacet = option.paramName === PROXIMITY_PARAM;
  const displayProximitySort = option.optionValue.includes(PROXIMITY_PARAM);
  const isTraitValue = option.paramName === 'trait_values';
  const displayStyleDefault = option.optionValue || option.trackingValue || 'default';
  const displayStyle = isTraitValue ? snakeCase(option.name) : displayStyleDefault;

  function trackFilterInteraction() {
    trackEvent({
      componentName: 'SelectFacetOption',
      eventName: MParticleEventName.ClickedSearchFilter,
      displayStyle: displayStyle,
      selected: !option.selected,
      parent: parentNameOverride || option.paramName,
      query: trackingQuery,
      experiments: formatExperimentsForEventAttributes(user, []),
    });
  }

  if (displayProximityFilterFacet || displayProximitySort) {
    return (
      <ProximityFacet
        option={option}
        trackInteraction={trackFilterInteraction}
        inSortContext={displayProximitySort}
      />
    );
  }

  if (checkbox) {
    return (
      <SelectFacetCheckbox
        option={option}
        trackInteraction={trackFilterInteraction}
        location={location}
        enableZeroCountOption={enableZeroCountOption}
      />
    );
  }

  const linkClass = classNames({
    facet__link: true,
    'facet__link--active': option.selected,
  });

  function onLinkClick(evt) {
    if (zeroCount) {
      evt.preventDefault();
    }
  }

  const isCategoryOrCondition = ['category_slugs', 'condition'].some(f => Object.keys(linkParams(option, false)).includes(f));

  return (
    <FacetOption
      active={option.selected}
      childActive={childActive}
      disabled={disabled}
      paramName={option.paramName}
    >
      <FilterLink
        onClick={onLinkClick}
        className={linkClass}
        params={linkParams(option, false)}
        data-checked={option.selected || null}
        data-disabled={disabled || null}
        rel={isCategoryOrCondition ? undefined : 'nofollow'}
        trackInteraction={trackFilterInteraction}
        directLinkOverride={option.directLinkOverride}
      >
        <div>
          <span className="mr-space">{option.name}</span>
          <SelectFacetOptionCount option={option} />
        </div>
      </FilterLink>
      {children}
    </FacetOption>
  );
}
