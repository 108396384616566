import React from 'react';
import PropTypes from 'prop-types';
import FormGroupWithInput from '@reverbdotcom/commons/src/components/form_group_with_input';
import classNames from 'classnames';

const GUEST_CHECKOUT_TYPE = 'guest';
const STANDARD_CHECKOUT_TYPE = 'standard';
const PASSWORD_MINIMUM_LENGTH = 8;

class PasswordOrGuestCheckoutSelection extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedCheckoutType: props.selectedCheckoutType,
      password: '',
    };

    this.toggleToStandardCheckout = this.toggleToStandardCheckout.bind(this);
    this.toggleToGuestCheckout = this.toggleToGuestCheckout.bind(this);
  }

  standardCheckoutSelected() {
    return (this.state.selectedCheckoutType === STANDARD_CHECKOUT_TYPE);
  }

  toggleToStandardCheckout(event) {
    event.preventDefault();
    this.setState({ selectedCheckoutType: STANDARD_CHECKOUT_TYPE });
  }

  toggleToGuestCheckout(event) {
    event.preventDefault();
    this.setState({ selectedCheckoutType: GUEST_CHECKOUT_TYPE });
  }

  getHelpText() {
    const isPasswordLongEnough = this.state.password.length >= PASSWORD_MINIMUM_LENGTH;
    const classes = classNames(
      { 'icon-r-check color-green': isPasswordLongEnough },
    );
    return (
      <span className={classes}>
        {I18n.t('commons.signupForm.passwordTip', { minLength: PASSWORD_MINIMUM_LENGTH })}
      </span>
    );
  }

  renderAccountSection() {
    return (
      <div>
        <input
          type="hidden"
          name="checkout_type"
          value={STANDARD_CHECKOUT_TYPE}
        />
        <FormGroupWithInput
          label={I18n.t('commons.signupForm.passwordLabel')}
          helpText={this.getHelpText()}
          inputName={this.props.passwordFieldName}
          inputType="password"
          autoComplete="new-password"
          required
          updateField={(e) => this.setState({ password: e['user[password]'] })}
        />
        <div className="form-group">
          <div className="form-group__fields">
            <p className="mb-2">
              {I18n.t('js.bundledCheckout.passwordOrGuestCheckoutSelection.preferNotToCreate')}
              <button className="button-as-link ml-1 weight-bold icon-r-arrow-right" onClick={this.toggleToGuestCheckout}>
                {I18n.t('js.bundledCheckout.passwordOrGuestCheckoutSelection.switchToGuest')}
              </button>
            </p>
          </div>
        </div>
      </div>
    );
  }

  renderGuestSection() {
    return (
      <div className="form-group">
        <div className="form-group__fields">
          <input
            type="hidden"
            name="checkout_type"
            value={GUEST_CHECKOUT_TYPE}
          />
          <p className="mb-2">
            {I18n.t('js.bundledCheckout.passwordOrGuestCheckoutSelection.wantAllReverbFeatures')}
            <button className="button-as-link ml-1 weight-bold icon-r-arrow-right" onClick={this.toggleToStandardCheckout}>
              {I18n.t('js.bundledCheckout.passwordOrGuestCheckoutSelection.switchToCreateAccount')}
            </button>
          </p>
        </div>
      </div>
    );
  }

  render() {
    if (this.standardCheckoutSelected()) {
      return this.renderAccountSection();
    }

    return this.renderGuestSection();
  }
}

PasswordOrGuestCheckoutSelection.propTypes = {
  passwordFieldName: PropTypes.string.isRequired,
  selectedCheckoutType: PropTypes.string.isRequired,
};

export default PasswordOrGuestCheckoutSelection;
