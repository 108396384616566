import { useUser } from '@reverbdotcom/commons/src/user_hooks';
import {
  core_apimessages_CheckoutPaymentMethod_Type as CheckoutPaymentMethodType,
} from '@reverbdotcom/commons/src/gql/graphql';
import { CartItemBundle } from './cart/CartBundleOrderSummary';

export const MIN_PAY_LATER_AMOUNT_CENTS = 50_00;
export const MAX_PAY_LATER_CALLOUT_AMOUNT_CENTS = 350_00;
export const MAX_PAY_LATER_BUTTON_AMOUNT_CENTS = 2500_00;

export interface PaypalCheckoutEligibilityAttributes {
  isLocalPickupOnly: boolean,
  includesDigital: boolean,
}

export function useExpressPayPalAvailability({ paypalCheckoutEligibilityAttributes }: {
  paypalCheckoutEligibilityAttributes: PaypalCheckoutEligibilityAttributes,
}) {
  return !paypalCheckoutEligibilityAttributes.includesDigital &&
    !paypalCheckoutEligibilityAttributes.isLocalPickupOnly;
}

export function usePayPalPayLaterButtonAvailability({ currency, amountCents, shopCountryCode }: {
  currency: string,
  amountCents: number,
  shopCountryCode: string;
}) {
  return useShowPayPalPayLater({
    minPrice: MIN_PAY_LATER_AMOUNT_CENTS,
    maxPrice: MAX_PAY_LATER_BUTTON_AMOUNT_CENTS,
    currency,
    amountCents,
    shopCountryCode,
  });
}

function useShowPayPalPayLater({ minPrice, maxPrice, currency, amountCents, shopCountryCode }: {
  minPrice: number;
  maxPrice: number;
  currency: string;
  amountCents: number;
  shopCountryCode: string;
}) {
  const user = useUser();

  const inPriceRange = amountCents > minPrice && amountCents < maxPrice;
  const isUSDTransaction = user.currency === 'USD' && currency === 'USD';
  const isUSShop = shopCountryCode === 'US';

  return inPriceRange && isUSDTransaction && isUSShop;
}

export function payPalCurrencyFromBundle(bundle: CartItemBundle): string {
  const payPalPaymentMethod = bundle.availablePaymentMethods.find((paymentMethod) =>
    paymentMethod.type === CheckoutPaymentMethodType.PAYPAL || paymentMethod.type === CheckoutPaymentMethodType.DC_VIA_PAYPAL,
  );

  if (!payPalPaymentMethod) { return null; }

  return payPalPaymentMethod.paypal.currency;
}
