import windowWrapper from '@reverbdotcom/commons/src/window_wrapper';

interface Config {
  /** Document-level identifier for the script */
  id: string;
  /** The src value of the script */
  src: string;
  /** crossorigin value of the script, optional */
  crossOrigin?: string;
  /** Script dataset, optional */
  dataset?: Record<string, string>;
}

export async function loadScript(
  {
    id,
    src,
    crossOrigin = null,
    dataset = null,
  }: Config,
  document = windowWrapper.document,
): Promise<HTMLScriptElement> {
  const existing = findScript(id);

  if (existing) {
    return existing;
  }

  const promise = new Promise<HTMLScriptElement>((resolve, reject) => {
    const script = document.createElement('script') as HTMLScriptElement;

    script.id = id;
    script.src = src;
    script.async = true;

    if (crossOrigin !== null) {
      script.crossOrigin = crossOrigin;
    }

    if (dataset) {
      Object.entries(dataset).forEach(([key, value]) => {
        script.dataset[key] = value;
      });
    }

    function onScriptLoad() {
      resolve(script);
    }

    function onScriptError() {
      cleanup();

      script.remove();

      reject(new Error(`Unable to load script ${src}`));
    }

    script.addEventListener('load', onScriptLoad);
    script.addEventListener('error', onScriptError);

    document.body.appendChild(script);

    function cleanup() {
      script.removeEventListener('load', onScriptLoad);
      script.removeEventListener('error', onScriptError);
    }
  });

  return promise;
}

function findScript(id: string) {
  return document.getElementById(id) as HTMLScriptElement;
}
