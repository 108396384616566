import { datadogRum } from '@datadog/browser-rum-slim';
import { pageView } from '@reverbdotcom/commons/src/elog';
import { matchRoutes } from '@reverbdotcom/commons/src/route_settings';

let initialServerRenderComplete = false;

export function addRumRouteContext(location) {
  return matchRoutes(location).then((state) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [_regionRoute, ...pageRoutes] = state.routes;

    const routePath = pageRoutes
      .map(route => route.path || '')
      .join('/');

    datadogRum.addRumGlobalContext('page_route', `/${routePath}`);
  }).catch(() => {});
}

export default (location) => {
  addRumRouteContext(location);

  if (initialServerRenderComplete) {
    // Initial pageView (including non-client routed pages) comes from elog_init.js
    pageView(location);

    if (window.ga !== undefined) {
      const urlWithParams = `${location.pathname}${location.search}`;
      window.ga('set', 'page', urlWithParams);
      window.ga('send', 'pageview');
    }
  }

  initialServerRenderComplete = true;
};
