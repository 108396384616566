import PropTypes from 'prop-types';
import React from 'react';
import RelatedArticle from './related_article';

function RelatedArticles(props) {
  if (props.articles.length < 2) { return null; }

  return (
    <section
      className="article-condensed-card-row"
    >
      <section
        className="article-condensed-card-row__inner"
        data-admin-message={props.messageForAdmins}
      >
        {
          props.articles.map(article =>
            <RelatedArticle key={article.title} article={article} />)
        }
      </section>
    </section>
  );
}

RelatedArticles.propTypes = {
  articles: PropTypes.array,
  messageForAdmins: PropTypes.string,
};

export default RelatedArticles;
