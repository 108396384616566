import alt from '../alt';
import Order from './order';
import SelectedOrders from './selected_orders';
import { InsurancePolicy } from './insurance_policy';
import UrlHelpers from '../shared/url_helpers';
import { APIRequest } from '@reverbdotcom/discovery-ui';

class ShippingLabelFormActions {
  loadOrders(primaryOrder) {
    return new Promise((resolve) => {
      resolve(APIRequest.V3.get(UrlHelpers.relatedOrdersForShipmentPath(primaryOrder.id)));
    }).then((response) => {
      this.ordersLoaded(response.related_orders || []);
    }).catch((e) => {
      throw e;
    });
  }

  fetchOriginCountries() {
    return new Promise((resolve) => {
      resolve(APIRequest.V3.get(UrlHelpers.countriesPath));
    }).then((response) => {
      this.originCountriesLoaded(response.countries || []);
    }).catch((e) => {
      throw e;
    });
  }

  updateOrderDeclaredValue(orderId, price) {
    return { orderId, price };
  }

  toggleOrderSelected(order) {
    return order;
  }

  setupPrimaryOrder(order) {
    this.loadOrders(order);
    return order;
  }

  setupShipment(shipment) {
    return shipment;
  }

  toggleShipmentInsurance() {
    return true;
  }

  toggleSignatureRequired() {
    return true;
  }

  toggleMediaMailQualified() {
    return true;
  }

  originCountriesLoaded(countries) {
    return countries;
  }

  ordersLoaded(relatedOrders) {
    return relatedOrders.map(attrs => new Order(attrs));
  }

  addAllRelatedOrders() {
    return true;
  }

  removeAllRelatedOrders() {
    return true;
  }
}

const actions = alt.createActions(ShippingLabelFormActions);

class ShippingLabelFormStore {
  constructor() {
    this.resetState();

    this.bindListeners({
      handleOrdersLoaded: actions.ORDERS_LOADED,
      handleToggleOrderSelection: actions.TOGGLE_ORDER_SELECTED,
      handleUpdateOrderDeclaredValue: actions.UPDATE_ORDER_DECLARED_VALUE,
      setupPrimaryOrder: actions.SETUP_PRIMARY_ORDER,
      handleOriginCountriesLoaded: actions.ORIGIN_COUNTRIES_LOADED,
      setupShipment: actions.SETUP_SHIPMENT,
      toggleShipmentInsurance: actions.TOGGLE_SHIPMENT_INSURANCE,
      toggleSignatureRequired: actions.TOGGLE_SIGNATURE_REQUIRED,
      toggleMediaMailQualified: actions.TOGGLE_MEDIA_MAIL_QUALIFIED,
      addAllRelatedOrders: actions.ADD_ALL_RELATED_ORDERS,
      removeAllRelatedOrders: actions.REMOVE_ALL_RELATED_ORDERS,
    });
  }

  handleOriginCountriesLoaded(countries) {
    this.originCountries = countries;
  }

  handleUpdateOrderDeclaredValue(order, price) {
    this.selectedOrders.updateOrderDeclaredValue(order, price);
    this.updateInsurancePolicy();
  }

  toggleSignatureRequired() {
    this.shipment.signatureRequired = !this.shipment.signatureRequired;
  }

  toggleMediaMailQualified() {
    this.shipment.mediaMailQualified = !this.shipment.mediaMailQualified;
  }

  toggleShipmentInsurance() {
    this.shipment.insured = !this.shipment.insured;
  }

  handleToggleOrderSelection(order) {
    this.selectedOrders.toggleOrderSelected(order);
    this.updateInsurancePolicy();
  }

  addAllRelatedOrders() {
    this.relatedOrders.forEach(order => this.selectedOrders.addOrder(order));
    this.updateInsurancePolicy();
  }

  removeAllRelatedOrders() {
    this.relatedOrders.forEach(order => this.selectedOrders.removeOrder(order));
    this.updateInsurancePolicy();
  }

  setupShipment(shipment) {
    this.shipment = shipment;
  }

  selectedOrdersPrimaryFirst() {
    return this.selectedOrders.ordersPrimaryFirst;
  }

  setupPrimaryOrder(order) {
    this.selectedOrders.setPrimaryOrder(order);
    this.updateInsurancePolicy();
  }

  updateInsurancePolicy() {
    const amountCents = this.selectedOrders.totalDeclaredAmountUSDCents;
    this.insurableAmountCents = amountCents;
    this.insurancePolicy = new InsurancePolicy(amountCents, this.selectedOrders.mostExpensiveOrderProductTypeUUID);
  }

  handleOrdersLoaded(orders) {
    this.addOrdersInShipment(orders);
    this.relatedOrders = orders;
  }

  addOrdersInShipment(orders) {
    orders.forEach((order) => {
      if (order.includedInShipment) this.selectedOrders.addOrder(order);
    });
    this.updateInsurancePolicy();
  }

  resetState() {
    this.shipment = null;
    this.insurancePolicy = null;
    this.selectedOrders = new SelectedOrders();
    this.relatedOrders = [];
    this.originCountries = [];
  }
}

const store = alt.createStore(ShippingLabelFormStore, 'ShippingLabelFormStore');

const exported = {
  actions,
  store,
};

export default exported;
export { actions, store };
