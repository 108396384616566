import React from 'react';
import I18n from 'i18n-js';
import { ListingForBuyerFieldsFragment } from '../gql/graphql';
import { useUser } from '../user_hooks';
import { eligibleForOutlet } from '../user_context_helpers';
import { LISTING_STATES } from '../constants';
import { RCText } from '@reverbdotcom/cadence/components';

interface Props {
  listing?: ListingForBuyerFieldsFragment;
}

export default function ListingCardTag({ listing }: Props) {
  const user = useUser();

  if (!listing) return null;

  if (listing.state !== LISTING_STATES.LIVE) {
    return (
      <div className="listing-card-tag">
        <RCText.Eyebrow>{listing.stateDescription}</RCText.Eyebrow>
      </div>
    );
  }

  if (listing.usOutlet && eligibleForOutlet(user)) {
    return (
      <div className="listing-card-tag listing-card-tag--outlet">
        <RCText.Fine weight="bold">{I18n.t('commons.listingCard.outlet')}</RCText.Fine>
      </div>
    );
  }

  return null;
}
