import PropTypes from 'prop-types';
import React from 'react';
import FormGroup from '@reverbdotcom/commons/src/components/form_group';
import Product from './product';
import PriceFieldWithCurrency from '../../../shared/price_field_with_currency';
import OfferItem from './offer_item';
import I18n from 'i18n-js';

class OfferItemView extends React.Component {
  constructor(props) {
    super(props);
    this.removeOfferItem = this.removeOfferItem.bind(this);
    this.setPrice = this.setPrice.bind(this);
    this.setShippingPrice = this.setShippingPrice.bind(this);
  }

  setPrice(e) {
    this.props.setPrice({ listingId: this.listing.id, price: e.target.value });
  }

  setShippingPrice(e) {
    this.props.setShippingPrice({ listingId: this.listing.id, shippingPrice: e.target.value });
  }

  removeOfferItem() {
    this.props.onRemove(this.listing.id);
  }

  get offerItem() {
    return this.props.offerItem;
  }

  get listing() {
    return this.offerItem.listing;
  }

  get listingCurrency() {
    return this.offerItem.currency;
  }

  get initialOfferPrice() {
    return this.offerItem.price;
  }

  get initialOfferShippingPrice() {
    return this.offerItem.shippingPrice;
  }

  get offerInputLabel() {
    if (this.offerItem.listing.buyer_price.tax_included_hint) {
      return I18n.t('emailOffers.offerItem.priceWithTaxHint', { hint: this.offerItem.listing.buyer_price.tax_included_hint });
    }

    return I18n.t('emailOffers.offerItem.price');
  }

  render() {
    return (
      <>
        <button className="email-offer__item-close" onClick={this.removeOfferItem} />

        <Product product={this.listing} initialOfferPrice={this.initialOfferPrice} />

        <div className="email-offer__item-fields">
          <div className="email-offer__item-field">
            <FormGroup label={this.offerInputLabel}>
              <PriceFieldWithCurrency
                defaultValue={this.initialOfferPrice}
                currency={this.listingCurrency}
                showCurrencyCode
                onChange={this.setPrice}
                name={`offer_item_price_${this.listing.id}`}
              />
            </FormGroup>
          </div>
          <div className="email-offer__item-field">
            <FormGroup label={I18n.t('emailOffers.offerItem.shippingPrice')}>
              <PriceFieldWithCurrency
                defaultValue={this.initialOfferShippingPrice}
                currency={this.listingCurrency}
                showCurrencyCode
                onChange={this.setShippingPrice}
                name={`offer_item_shipping_price_${this.listing.id}`}
              />
            </FormGroup>
          </div>
        </div>

      </>
    );
  }
}

OfferItemView.propTypes = {
  offerItem: PropTypes.instanceOf(OfferItem),
  onRemove: PropTypes.func.isRequired,
  setPrice: PropTypes.func.isRequired,
  setShippingPrice: PropTypes.func.isRequired,
};

export default OfferItemView;
