// TODO update this to import from commons
// import { gql } from '@reverbdotcom/commons/src/gql';
// eslint-disable-next-line no-restricted-imports
import { gql } from '@apollo/client';
import { useHOCMutation, MutationFunction } from '@reverbdotcom/commons/src/useHOCMutation';
import { useCheckoutDisplay } from './checkoutDisplayContext';
import {
  CoreCheckoutSelectCreditCard,
} from '@reverbdotcom/commons/src/gql/graphql';
import React from 'react';

export type SelectCheckoutCreditCardMutationFn = MutationFunction<CoreCheckoutSelectCreditCard.Mutation, CoreCheckoutSelectCreditCard.Variables>;

export function useSelectCheckoutCreditCard(
  checkoutId: string,
  selectCheckoutCreditCard: SelectCheckoutCreditCardMutationFn,
) {
  const [mutate, { loading, errors }] = useHOCMutation(selectCheckoutCreditCard);
  const { layoutQueryName } = useCheckoutDisplay();

  const selectCheckoutCreditCardCallback = React.useCallback(async ({ creditCardId }) => {
    return mutate({
      variables: {
        input: {
          id: checkoutId,
          creditCardId,
        },
      },
      refetchQueries: [layoutQueryName],
      awaitRefetchQueries: true,
    });
  }, [checkoutId, layoutQueryName, mutate]);

  return { selectCheckoutCreditCard: selectCheckoutCreditCardCallback, loading, errors };
}

export const CHECKOUT_SELECT_CREDIT_CARD_MUTATION = gql`
  mutation Core_Checkout_SelectCreditCard(
    $input: Input_core_apimessages_SelectCheckoutCreditCardRequest
  ) {
    selectCheckoutCreditCard(input: $input) {
      checkout {
        _id
      }
    }
  }
`;
