import { RCTooltip } from '@reverbdotcom/cadence/components';

import React from 'react';
import I18n from 'i18n-js';
import { useUser } from '@reverbdotcom/commons/src/user_hooks';
import { RegionalKlarnaCallout } from './RegionalKlarnaCallout';
import { core_apimessages_Money as Money } from '@reverbdotcom/commons/src/gql/graphql';
import { AffirmCallout } from '@reverbdotcom/commons/src/AffirmCallout';

interface Props {
  initialPaymentMethodSelection: PaymentMethod;
  reverbCredits: Pick<Money, 'display' | 'currency'>[];
  acceptedPaymentMethods: PaymentMethod[];
  affirmEstimatedPaymentText: string;
  affirmLearnMoreModalProps: AffirmLearnMoreModalProps;
  onPaymentMethodSelect(value: PaymentMethod): void;
  canUseReverbCredits: boolean;
  klarnaAccountEstimatedTotal: Pick<Money, 'amountCents' | 'currency'>;
}

const AFFIRM = 'affirm';
const DIRECT_CHECKOUT = 'direct_checkout';
const KLARNA_ACCOUNT = 'klarna_account';
const PAYPAL = 'paypal';

type PaymentMethod =
  | typeof AFFIRM
  | typeof DIRECT_CHECKOUT
  | typeof KLARNA_ACCOUNT
  | typeof PAYPAL;

interface AffirmLearnMoreModalProps {
  financingProgramSlug: string;
  amountCents: number;
}

function CartPaymentMethodSelector(props: Props) {
  const user = useUser();
  const [selectedPaymentMethod, setSelectedPaymentMethod] = React.useState(props.initialPaymentMethodSelection);

  function onPaymentMethodSelect(paymentMethod: PaymentMethod) {
    setSelectedPaymentMethod(paymentMethod);
    props.onPaymentMethodSelect(paymentMethod);
  }

  const reverbCreditsInUserCurrency = props.reverbCredits.find(reverbCredit => reverbCredit.currency === user.currency);

  return (
    <div className="cart-payment-methods">
      <div className="cart-payment-methods__list">
        {props.acceptedPaymentMethods.includes(DIRECT_CHECKOUT) &&
          <div className="cart-payment-method cart-payment-method--rp">
            <PaymentMethodRadioButton
              onPaymentMethodSelect={onPaymentMethodSelect}
              selectedPaymentMethod={selectedPaymentMethod}
              paymentMethod={DIRECT_CHECKOUT}
            >
              <div>
                <div className="cart-payment-method__titleblock">
                  <h3 className="cart-payment-method__title">
                    {I18n.t('discovery.paymentMethodSelection.debitOrCreditCard')}
                  </h3>
                  <div className="cart-payment-method__icons">
                    <div className="payment-icon payment-icon--visa" role="img" aria-label={I18n.t('discovery.paymentMethodSelection.icons.visa')} />
                    <div
                      className="payment-icon payment-icon--mastercard"
                      role="img"
                      aria-label={I18n.t('discovery.paymentMethodSelection.icons.mastercard')}
                    />
                    <div
                      className="payment-icon payment-icon--discover"
                      role="img"
                      aria-label={I18n.t('discovery.paymentMethodSelection.icons.discover')} />
                    <div
                      className="payment-icon payment-icon--amex"
                      role="img"
                      aria-label={I18n.t('discovery.paymentMethodSelection.icons.amex')}
                    />
                    <div
                      className="payment-icon payment-icon--reverb-credits"
                      role="img"
                      aria-label={I18n.t('discovery.paymentMethodSelection.icons.reverbCredits')}
                    />
                  </div>
                </div>
                {props.canUseReverbCredits && !!reverbCreditsInUserCurrency &&
                  <p
                    className="cart-payment-method__credits"
                    key={`tooltip${reverbCreditsInUserCurrency.display}`}
                  >
                    <RCTooltip
                      text={I18n.t('discovery.paymentMethodSelection.creditsAppliedToEligibleItems')}
                    >
                      {
                        I18n.t('discovery.paymentMethodSelection.plusReverbCredits',
                          { display: reverbCreditsInUserCurrency.display, currency: reverbCreditsInUserCurrency.currency },
                        )}
                    </RCTooltip>
                  </p>
                }
              </div>
            </PaymentMethodRadioButton>
          </div>
        }
        {props.acceptedPaymentMethods.includes(AFFIRM) &&
          <div className="cart-payment-method">
            <PaymentMethodRadioButton
              onPaymentMethodSelect={onPaymentMethodSelect}
              selectedPaymentMethod={selectedPaymentMethod}
              paymentMethod={AFFIRM}
            >
              <div className="cart-payment-method__titleblock">
                <h3 className="cart-payment-method__title">
                  {I18n.t('discovery.paymentMethodSelection.payOverTime')}
                  {props.affirmEstimatedPaymentText != null &&
                    <div className="cart-payment-method__subtitle">
                      <AffirmCallout
                        amountCents={props.affirmLearnMoreModalProps.amountCents}
                        financingProgramSlug={props.affirmLearnMoreModalProps.financingProgramSlug}
                        pageType="cart"
                      />
                    </div>
                  }
                </h3>
                <div className="cart-payment-method__icons">
                  <div
                    className="payment-icon payment-icon--affirm"
                    role="img"
                    aria-label={I18n.t('discovery.paymentMethodSelection.icons.affirm')}
                  />
                </div>
              </div>
            </PaymentMethodRadioButton>
          </div>
        }
        {props.acceptedPaymentMethods.includes(KLARNA_ACCOUNT) &&
          <div className="cart-payment-method">
            <PaymentMethodRadioButton
              selectedPaymentMethod={selectedPaymentMethod}
              paymentMethod={KLARNA_ACCOUNT}
              onPaymentMethodSelect={onPaymentMethodSelect}
            >
              <div className="cart-payment-method__titleblock">
                <h3 className="cart-payment-method__title">
                  {I18n.t('discovery.paymentMethodSelection.buyWithKlarnaAccount')}
                  <div className="cart-payment-method__subtitle">
                    <RegionalKlarnaCallout
                      amount={props.klarnaAccountEstimatedTotal}
                      textOnly
                    />
                  </div>
                </h3>
                <div className="cart-payment-method__icons">
                  <div
                    className="payment-icon payment-icon--klarna"
                    role="img"
                    aria-label={I18n.t('discovery.paymentMethodSelection.icons.klarna')}
                  />
                </div>
              </div>
            </PaymentMethodRadioButton>
          </div>
        }
      </div>
    </div>
  );
}

function PaymentMethodRadioButton({
  selectedPaymentMethod,
  paymentMethod,
  onPaymentMethodSelect,
  children,
}: {
  selectedPaymentMethod: PaymentMethod,
  paymentMethod: PaymentMethod,
  onPaymentMethodSelect(paymentMethod: PaymentMethod): void,
  children: JSX.Element,
}) {
  const buttonID = `input_${paymentMethod}`;
  return (
    <div>
      <input
        className="cart-payment-method__input"
        type="radio"
        id={buttonID}
        value={paymentMethod}
        checked={selectedPaymentMethod === paymentMethod}
        onChange={() => onPaymentMethodSelect(paymentMethod)}
      />
      <label
        htmlFor={buttonID}
        className="cart-payment-method__label"
      >
        {children}
      </label>
    </div>
  );
}

export default CartPaymentMethodSelector;
