import I18n from 'i18n-js';
import { ISelectOption } from '@reverbdotcom/commons/src/components/form_group_with_select';
import { FORMATS_FOR_COUNTRY_CODES } from './shop_settings_vat_supported_countries_and_formats';

export default function vatIdHelpText(countryCode?: string, countryOptions?: ISelectOption[]): string {
  if (countryCode) {
    const countryName = countryOptions.find(options => options.value === countryCode).text;
    const formats = FORMATS_FOR_COUNTRY_CODES[countryCode].join('; ');

    return I18n.t('discovery.shopSettings.vat.vatIdExamples', {
      countryName,
      formats,
    });
  }

  return I18n.t('discovery.shopSettings.vat.vatIdAlphanumeric');
}
