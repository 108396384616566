// TODO update this to import from commons/src/gql
// eslint-disable-next-line no-restricted-imports
import { gql } from '@apollo/client';
import React from 'react';
import I18n from 'i18n-js';
import * as elog from '@reverbdotcom/commons/src/elog';
import { withGraphql } from '@reverbdotcom/commons/src/with_graphql';
import { useHOCMutation, MutationFunction } from '@reverbdotcom/commons/src/useHOCMutation';
import { ExecutionResult } from 'graphql';
import { RCToast } from '@reverbdotcom/cadence/components';
import { ToastCloseType } from '@reverbdotcom/cadence/components/types';
import CoreLink from '@reverbdotcom/commons/src/components/core_link';
import { Paths } from '../url_helpers';
import { UndoDeleteMyFavoriteMutation, UndoDeleteMyFavoriteMutationVariables, core_apimessages_FavoriteType } from '../gql/graphql';

type UndoDeleteMyFavoriteMutation_Listing = UndoDeleteMyFavoriteMutation['undoDeleteMyFavorite']['listing'];

interface ExternalProps {
  /** Either a listing ID, or a follow ID */
  favoriteId: string;

  /** Either LISTING, SAVED_SEARCH, or FAVORITE_SHOP (which is used by the UndoDeleteMyFavorite API) */
  favoriteType: core_apimessages_FavoriteType;

  /** Favorite.favorited field for saved searches/favorite shops, or listing.watching for watched listings */
  isFavorited: boolean;

  isOpen: boolean;
  queryToRefetch?: string;
  noToastLink?: boolean;
  handleToastClose: (closeType: ToastCloseType) => void;
  handleMutationResult?: (result: ExecutionResult<UndoDeleteMyFavoriteMutation>) => void;

  /** An error toast message will be shown if has error is true */
  setHasError: Function;
  hasError?: boolean;
  errorMessage?: string;
}

interface ApolloProps {
  undoDeleteMyFavorite: MutationFunction<UndoDeleteMyFavoriteMutation, UndoDeleteMyFavoriteMutationVariables>;
}

type IProps = ExternalProps & ApolloProps;

/**
 * Toast message component for favorites.
 * This renders the "Added" toast when favoriting an entity,
 * and the "Removed" toast when unfavoriting an entity.
 *
 * The "Removed" toast renders an "Undo" action, which calls the UndoDeleteMyFavorite RQL API.
 *
 * This can be used with watched listings, and favorited searches/shops.
 * Hence why we split out the props (favoriteId, favoriteType, and isFavorited) since watched listings are
 * from a different API/data source than saved searches/shops/etc.
 */
export function FavoriteToast(props: IProps) {
  const { favoriteId, favoriteType, isFavorited, queryToRefetch } = props;
  const { undoDeleteMyFavorite } = useUndoDeleteMyFavorite(props.undoDeleteMyFavorite, favoriteId, favoriteType, queryToRefetch);

  const favoritedUrl = () => {
    switch (props.favoriteType) {
      case core_apimessages_FavoriteType.LISTING:
        return Paths.myFavorites.expand({});
      case core_apimessages_FavoriteType.SAVED_SEARCH:
        return Paths.myFavoritesSearches.expand({});
      case core_apimessages_FavoriteType.FAVORITE_SHOP:
        return Paths.myFavoritesShops.expand({});
      default:
        return null;
    }
  };

  const undoFavoriteDeletion = async () => {
    try {
      const result = await undoDeleteMyFavorite();
      if (props.handleMutationResult) {
        props.handleMutationResult(result);
      }
      props.setHasError(false);
    } catch (e) {
      props.setHasError(true);

      elog.error(
        e.message,
        { componentName: 'FavoriteToast' },
        e,
      );
    }
  };

  return (
    <>
      {/*
        This renders separate RCToast components for the add and remove states,
        because on Favorites Hub pages, the add version doesn't have a link prop and the remove does.
        Using 1 RCToast with a conditional link prop causes the toast to not render when the link is null.
      */}
      <RCToast
        key="favorite--add"
        theme="watch-add"
        isOpen={!props.hasError && props.isOpen && isFavorited}
        onClose={props.handleToastClose}
        text={I18n.t('discovery.favorites.toast.added')}
        link={!props.noToastLink &&
          <CoreLink
            to={favoritedUrl()}
          >
            {I18n.t('cadence.RCToast.linkDefault')}
          </CoreLink>
        }
      />
      <RCToast
        key="favorite--remove"
        theme="watch-remove"
        isOpen={!props.hasError && props.isOpen && !isFavorited}
        onClose={props.handleToastClose}
        text={I18n.t('discovery.favorites.toast.removed')}
        link={
          <button
            onClick={undoFavoriteDeletion}
            className="favorites-toast__undo-button"
            type="button"
          >
            {I18n.t('discovery.favorites.toast.undo')}
          </button>
        }
      />
      <RCToast
        key="favorite--error"
        theme="error"
        isOpen={props.isOpen && props.hasError}
        onClose={props.handleToastClose}
        text={props.errorMessage || I18n.t('discovery.favorites.toast.error')}
      />
    </>
  );
}

export const UndoDeleteFavoriteMutation = gql`
  mutation UndoDeleteMyFavorite($input: Input_core_apimessages_UndoDeleteMyFavoriteRequest) {
    undoDeleteMyFavorite(input: $input) {
      favorite {
        id
        favorited
        favoriteType
      }
      listing {
        _id
        id
        watching
      }
    }
  }
`;

const withMutation =
  withGraphql<ExternalProps, UndoDeleteMyFavoriteMutation, UndoDeleteMyFavoriteMutationVariables>(
    UndoDeleteFavoriteMutation,
    {
      name: 'undoDeleteMyFavorite',
    },
  );


function useUndoDeleteMyFavorite(
  undoDeleteMyFavorite: MutationFunction<UndoDeleteMyFavoriteMutation, UndoDeleteMyFavoriteMutationVariables>,
  favoriteId: string,
  favoriteType: core_apimessages_FavoriteType,
  queryToRefetch?: string,
) {
  const [mutate] = useHOCMutation(undoDeleteMyFavorite);

  const undoDeleteMyFavoriteCallback = React.useCallback(async function () {
    const listing: UndoDeleteMyFavoriteMutation_Listing = {
      __typename: 'Listing',
      _id: `id:${favoriteId}`,
      id: favoriteId,
      watching: true,
    };
    const optimisticListing = favoriteType == core_apimessages_FavoriteType.LISTING ? listing : null;

    return mutate({
      variables: {
        input: {
          id: favoriteId,
          type: favoriteType,
        },
      },
      optimisticResponse: {
        __typename: 'Mutation',
        undoDeleteMyFavorite: {
          __typename: 'core_apimessages_UndoDeleteMyFavoriteResponse',
          favorite: {
            __typename: 'core_apimessages_Favorite',
            id: favoriteId,
            favorited: true,
            favoriteType,
          },
          listing: optimisticListing,
        },
      },
      refetchQueries: queryToRefetch ? [queryToRefetch] : null,
      awaitRefetchQueries: !!queryToRefetch,
    });
  }, [favoriteId, favoriteType, queryToRefetch, mutate]);

  return { undoDeleteMyFavorite: undoDeleteMyFavoriteCallback };
}

export default withMutation(FavoriteToast);
