// TODO update this to import from commons/src/gql
// eslint-disable-next-line no-restricted-imports
import { gql } from '@apollo/client';
import React from 'react';
import I18n from 'i18n-js';

import { RCRadioCardGroup, RCAccordion } from '@reverbdotcom/cadence/components';

import { parseAmountCents } from '../money';
import SanitizedRender from '../components/sanitized_render';
import {
  TERMS_OF_USE_PATH,
  ADDRESS_SETTINGS_PATH,
  CREDIT_CARD_SETTINGS_PATH,
} from '../url_helpers';
import {
  core_apimessages_Money,
  core_apimessages_Address,
  core_apimessages_CreditCard,
  CommonsOffersImmediatePaymentReview,
} from '../gql/graphql';
import { useQuery } from '../useQuery';
import { MParticleEventName, trackEvent } from '../elog/mparticle_tracker';
import VerifyCardCVV from '../components/verify_card_cvv';
import { useUser } from '../user_hooks';
import CreditCardBrandIcon from '../components/credit_card_brand_icon';

interface Props {
  offerPrice: core_apimessages_Money;
  shippingPrice: core_apimessages_Money;
  currency: string;
  selectedAddress: core_apimessages_Address;
  allAddresses: core_apimessages_Address[];
  selectedCreditCard: core_apimessages_CreditCard;
  allCreditCards: core_apimessages_CreditCard[];
  dispatch: (any) => void;
  localPickupOnly?: boolean;
  listingId: string;
}

function displayTextForAddress(address) {
  return `${address.name} - ${address.streetAddress} ${address.displayLocation}`;
}

export function ImmediatePaymentReview({ offerPrice, shippingPrice, currency, selectedAddress, allAddresses, selectedCreditCard, allCreditCards, dispatch, listingId, localPickupOnly = false }: Props) {
  const user = useUser();
  const {
    loading,
    data,
  } = useQuery<CommonsOffersImmediatePaymentReview.Query, CommonsOffersImmediatePaymentReview.Variables>(
    immediatePaymentReviewQuery,
    {
      variables: {
        input: {
          amountProduct: { amountCents: offerPrice.amountCents, currency: offerPrice.currency },
          amountShipping: { amountCents: shippingPrice?.amountCents || 0, currency: shippingPrice?.currency || offerPrice.currency },
          shippingAddressUuid: selectedAddress.id,
        },
      },
    },
  );

  React.useEffect(() => {
    if (loading) {
      return null;
    }

    trackEvent({
      eventName: MParticleEventName.ReachedImmediateOfferPaymentReview,
      listingId: listingId,
    });
  }, [loading]);

  const taxEstimate = !loading && data?.estimateNegotiationSalesTax?.amountTax;
  const estimatedTotal = parseAmountCents(offerPrice.amountCents + (shippingPrice?.amountCents || 0) + (taxEstimate?.amountCents || 0), currency).display;

  if (loading) {
    return (
      <div className="offers-form offers-form--loading">
        <div className="offers-form-placeholder" />
        <div className="offers-form-placeholder" />
        <div className="offers-form-placeholder" />
      </div>
    );
  }

  return (
    <div className="offers-form__payment-review">
      <div className="offers-form__payment-review__totals">
        <div className="d-flex fx-justify-between mb-2">
          <span>{I18n.t('commons.offers.immediatePayment.totals.yourOfferPrice')}</span>
          {offerPrice.display}
        </div>

        {!localPickupOnly && shippingPrice?.display && (
          <div className="d-flex fx-justify-between mb-2">
            <span>{I18n.t('commons.offers.immediatePayment.totals.shipping')}</span>
            {shippingPrice.display}
          </div>
        )}

        {taxEstimate?.display && (
          <div className="d-flex fx-justify-between mb-2">
            <span>{I18n.t('commons.offers.immediatePayment.totals.tax')}</span>
            {taxEstimate.display}
          </div>
        )}

        <div className="offers-form__payment-review__totals__estimated-total">
          <div>
            <h4>
              {taxEstimate?.display && I18n.t('commons.offers.immediatePayment.totals.total')}
              {!taxEstimate?.display && I18n.t('commons.offers.immediatePayment.totals.totalWithoutTax')}
            </h4>
            <div>{I18n.t('commons.offers.immediatePayment.totals.ifAccepted')}</div>

            {!taxEstimate?.display && I18n.t('commons.offers.immediatePayment.totals.taxNote')}
          </div>

          {estimatedTotal}
        </div>
      </div>

      <div className="offers-form__review">
        <div className="offers-form__review__header">
          {I18n.t('commons.offers.immediatePayment.shipTo.label')}
        </div>
        <div className="offers-form__review__content">
          <RCAccordion
            id="shipTo"
            heading={displayTextForAddress(selectedAddress)}
            size="small"
          >
            {allAddresses.length > 1 && (
              <div className="offers-form__review__content">
                <RCRadioCardGroup
                  id="shipToGroup"
                  name="shipToGroup"
                  label={I18n.t('commons.offers.immediatePayment.shipTo.label')}
                  value={selectedAddress.id}
                  onChange={(e) => { dispatch({ type: 'setShipTo', value: allAddresses.find((addr) => addr.id === e.target.value) }); }}
                  required
                >
                  {allAddresses.map((address) => {
                    return (
                      <RCRadioCardGroup.Option
                        key={address.id}
                        label={displayTextForAddress(address)}
                        value={address.id}
                      />
                    );
                  })}
                </RCRadioCardGroup>
              </div>
            )}
            <p className="offers-form__payment-review__additional-content">
              <SanitizedRender
                html={I18n.t('commons.offers.immediatePayment.shipTo.helpText', {
                  accountLinkOpen: `<a href='${ADDRESS_SETTINGS_PATH}'>`,
                  accountLinkClose: '</a>',
                })}
              />
            </p>
          </RCAccordion>
        </div>
      </div>

      <div className="offers-form__review">
        <div className="offers-form__review__header">
          {I18n.t('commons.offers.immediatePayment.payWith.label')}
        </div>
        <div className="offers-form__review__content">
          <RCAccordion
            id="payWith"
            heading={
              <div className="offers-form__payment-review__credit-card">
                <CreditCardBrandIcon brand={selectedCreditCard.brand} />
                <RCAccordion.Heading text={selectedCreditCard.display.summary} />
              </div>
            }
            defaultOpen={selectedCreditCard.needsReverification}
            size="small"
          >
            {allCreditCards.length > 1 && (
              <RCRadioCardGroup
                id="payWithGroup"
                name="payWithGroup"
                label={I18n.t('commons.offers.immediatePayment.payWith.label')}
                value={selectedCreditCard.id}
                onChange={(e) => { dispatch({ type: 'setCreditCardId', value: e.target.value }); }}
                required
              >
                {allCreditCards.map((card) => {
                  return (
                    <RCRadioCardGroup.Option
                      key={card.id}
                      label={
                        <div className="offers-form__payment-review__credit-card">
                          <CreditCardBrandIcon brand={card.brand} />
                          <div>
                            <strong>{card.display.summary}</strong>
                            <div>{card.display.expirationDate}</div>
                          </div>
                        </div>
                      }
                      value={card.id}
                    />
                  );
                })}
              </RCRadioCardGroup>
            )}

            {selectedCreditCard.needsReverification && (
              <div className="d-flex fx-justify-end">
                <div className="offers-form__payment-review__reverify">
                  <VerifyCardCVV
                    creditCard={selectedCreditCard}
                    hideHelpText={true}
                  />
                </div>
              </div>
            )}

            <p className="offers-form__payment-review__additional-content">
              {I18n.t('commons.offers.immediatePayment.payWith.helpText')}
            </p>
            <p className="offers-form__payment-review__additional-content">
              <SanitizedRender
                html={I18n.t('commons.offers.immediatePayment.payWith.helpTextLine2', {
                  accountLinkOpen: `<a href='${CREDIT_CARD_SETTINGS_PATH}'>`,
                  accountLinkClose: '</a>',
                })}
              />
            </p>
            <p className="offers-form__payment-review__additional-content">
              {I18n.t('commons.offers.immediatePayment.payWith.helpTextLine3')}
            </p>
          </RCAccordion>
        </div>
      </div>
      <div className="offers-form__review">
        <div className="offers-form__review__header">
          {I18n.t('commons.offers.immediatePayment.delivery.label')}
        </div>
        <div className="offers-form__review__content">
          <RCAccordion
            id="review-delivery"
            locked
            size="small"
            heading={
              localPickupOnly ?
                I18n.t('commons.offers.immediatePayment.delivery.localPickup') :
                I18n.t('commons.offers.immediatePayment.delivery.standard')
            }
          />
        </div>
      </div>
      <div className="offers-form__review">
        <div className="offers-form__review__header">
          {I18n.t('commons.offers.immediatePayment.contact.label')}
        </div>
        <div className="offers-form__review__content">
          <RCAccordion
            id="review-contact"
            locked
            size="small"
            heading={user.email}
          />
        </div>
      </div>
      <p className="offers-form__payment-review__terms">
        <SanitizedRender
          html={I18n.t('commons.offers.immediatePayment.terms', {
            termsLinkOpen: `<a href='${TERMS_OF_USE_PATH}'>`,
            termsLinkClose: '</a>',
          })}
        />
      </p>
    </div>
  );
}

export const immediatePaymentReviewQuery = gql`query Commons_Offers_ImmediatePaymentReview(
  $input: Input_core_apimessages_EstimateNegotiationSalesTaxRequest,
) {
  estimateNegotiationSalesTax(input: $input) {
    amountTax {
      display
      amountCents
    }
  }
}`;

export default ImmediatePaymentReview;
