import React from 'react';
import SigninSeparator from './signin_separator';

interface Props {
  prioritizeOauth: boolean;
  oauthButtons: React.ReactNode;
  authForm: React.ReactNode;
  header: React.ReactNode;
}

export function AuthWrapper({
  prioritizeOauth,
  oauthButtons,
  authForm,
  header,
}: Props) {
  if (prioritizeOauth) {
    return (
      <div>
        {oauthButtons}
        <div className="mt-8">
          <SigninSeparator />
        </div>
        {header}
        {authForm}
      </div>
    );
  } else {
    return (
      <div>
        {header}
        {authForm}
        {oauthButtons}
      </div>
    );
  }
}
