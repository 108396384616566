import React from 'react';
import classNames from 'classnames';

import bind from '../bind';

import { YOUTUBE_IMAGE, YOUTUBE_VIDEO } from '../url_helpers';
import { RCImage } from '@reverbdotcom/cadence/components';

const ASPECT_MAX = 1;
const ASPECT_MIN = 0.7;

interface IProps {
  index: number;
  urlOrSlug: string;
  alt?: string;
  onClick?: any;
  onLoad?: any;
  controls?: any;
  renderVideo?: boolean;
  aspectOverride?: number;
  eagerLoad?: boolean;
}

export default class LightboxImageItem extends React.Component<IProps, null> {
  isLink(url) {
    if (!url) { return false; }
    return url.slice(0, 4) === 'http';
  }

  getImageURL() {
    const { urlOrSlug } = this.props;
    if (this.isLink(urlOrSlug)) return urlOrSlug;
    return YOUTUBE_IMAGE.expand({ slug: urlOrSlug });
  }

  @bind
  handleClick(evt) {
    if (!this.props.onClick) return;
    this.props.onClick(evt, this.props.index);
  }

  getAspectOverride() {
    const a = this.props.aspectOverride;
    if (!a) return false;
    if (a > ASPECT_MAX) return ASPECT_MAX;
    if (a < ASPECT_MIN) return ASPECT_MIN;
    return a;
  }

  getAspectOverrideStyle() {
    const aspect = this.getAspectOverride();
    if (!aspect) return;

    return {
      style: {
        paddingBottom: `${aspect * 100}%`,
      },
    };
  }

  onLoad() {
    this.props.onLoad();
  }

  render() {
    const isVideo = !this.isLink(this.props.urlOrSlug);

    const classes = classNames(
      'lightbox-image-item',
      { 'lightbox-image-item--video': isVideo },
    );

    const slug = this.props.urlOrSlug;

    if (this.props.renderVideo) {
      return (
        <div
          className="lightbox-image-item"
          onClick={this.handleClick}
          {...this.getAspectOverrideStyle()}
        >
          <div className="lightbox-image-item__video-frame">
            <iframe
              src={YOUTUBE_VIDEO.expand({ slug, autoplay: true })}
              title={this.props.alt}
              frameBorder="0"
              allowFullScreen
            />
          </div>
        </div>
      );
    }

    if (isVideo) {
      return (
        <div
          className={classes}
          itemProp="videoObject"
          itemScope
          itemType="http://schema.org/VideoObject"
          onClick={this.handleClick}
          {...this.getAspectOverrideStyle()}
        >
          <meta
            itemProp="embedUrl"
            content={ YOUTUBE_VIDEO.expand({ slug })}
          />
          <RCImage
            src={this.getImageURL()}
            alt={this.props.alt}
            onLoad={this.props.onLoad}
            imageBox="none"
            eagerLoad={this.props.eagerLoad}
          />
          {this.props.controls}
        </div>
      );
    }

    return (
      <div
        className={classes}
        onClick={this.handleClick}
        {...this.getAspectOverrideStyle()}
      >
        <RCImage
          src={this.getImageURL()}
          alt={this.props.alt}
          onLoad={this.props.onLoad}
          imageBox="none"
          eagerLoad={this.props.eagerLoad}
        />
        {this.props.controls}
      </div>
    );
  }
}
