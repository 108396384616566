import React from 'react';
import Isvg from 'react-inlinesvg';

import EMPTY_STATE_IMAGE from '../../images/icons/empty-states/not-found.svg';

export default function NotFoundAnimation() {
  return (
    <Isvg
      className="not-found-animation"
      src={EMPTY_STATE_IMAGE}
    />
  );
}
