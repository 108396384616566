import React from 'react';
import classNames from 'classnames';
import { times } from 'lodash';

const DEFAULT_PLACEHOLDER_COUNT = 6;

interface IProps {
  // when loading is true, renders placeholder on first load, if present
  loading?: boolean;
  placeholder?: JSX.Element;

  // when loading is false and it has no children, renders emptyState
  emptyState?: JSX.Element;

  // dictates how many placeholders are rendered
  expectedCount?: number;

  // prevents Tiles from wrapping
  // (only use this when rendering Tiles inside OverflowingRow)
  singleRow?: boolean;

  // show a 'view more' tile at the end of the row if the rendered count > DEFAULT_PLACEHOLDER_COUNT
  viewMoreURL?: string;

  // enables flex-grow
  grow?: boolean;

  // two wide
  twoWide?: boolean;

  // three wide on desktop, 1 wide on mobile;
  // if singleRow is true, each tile is 66% wide on mobile
  threeWide?: boolean;

  // never render at more than four wide
  fourWideMax?: boolean;

  // flex centering
  centered?: boolean;

  // self-explanatory
  largeTiles?: boolean;
  oneWide?: boolean;
  oneWideMobile?: boolean;
  renderAsOrderedList?: boolean;
}

interface IState {
  hasLoaded: boolean;
}

interface PlaceholderProps {
  placeholder: JSX.Element;
  expectedCount: number;
}

function Placeholder(props: PlaceholderProps) {
  return <>{
    times(props.expectedCount, (idx) => {
      return (
        <li className="tiles__tile" key={idx}>
          {props.placeholder}
        </li>
      );
    })
  }</>;
}

interface GridProps {
  children: React.ReactNode;
}

function Grid(props: GridProps) {
  return <>{
    React.Children.map(props.children, (child, idx) => {
      if (!child) return null;
      return (
        <li className="tiles__tile" key={idx}>
          {child}
        </li>
      );
    })
  }</>;
}

interface ViewMoreTileProps {
  viewMoreURL: string;
}

function ViewMoreTile(props: ViewMoreTileProps) {
  return (
    <li className="tiles__tile">
      <div className="tiles__view-more">
        <a
          className="tiles__view-more__link"
          href={props.viewMoreURL}
        />
      </div>
    </li>
  );
}

function FlexFix() {
  return <>{
    times(DEFAULT_PLACEHOLDER_COUNT, idx => (
      <li className="tiles__tile" key={idx} />
    ))
  }</>;
}

export default class Tiles extends React.Component<IProps, IState> {
  state = {
    hasLoaded: false,
  };

  static defaultProps = {
    emptyState: null,
    expectedCount: DEFAULT_PLACEHOLDER_COUNT,
  };

  componentDidUpdate(_prevProps, prevState) {
    if (prevState.hasLoaded) return;

    this.setState({
      hasLoaded: true,
    });
  }

  hasChildren() {
    return React.Children.toArray(this.props.children).length > 0;
  }

  isEmpty() {
    return !this.props.loading && !this.hasChildren();
  }

  isInitializing() {
    return !this.state.hasLoaded && this.props.loading;
  }

  isReloading() {
    return this.state.hasLoaded && this.props.loading;
  }

  render() {
    const classes = classNames(
      'tiles',
      { 'tiles--single-row': this.props.singleRow },
      { 'tiles--grow': this.props.grow },
      { 'tiles--one-wide': this.props.oneWide },
      { 'tiles--one-wide-mobile': this.props.oneWideMobile },
      { 'tiles--three-wide': this.props.threeWide },
      { 'tiles--two-wide': this.props.twoWide },
      { 'tiles--four-wide-max': this.props.fourWideMax },
      { 'tiles--large': this.props.largeTiles },
      { 'tiles--centered': this.props.centered },
      { 'tiles--reloading': this.isReloading() },
    );

    const shouldRenderPlaceholder = (
      this.props.placeholder &&
      (this.isInitializing() ||
      !this.hasChildren())
    );

    const shouldRenderViewMoreTile = (
      this.props.singleRow &&
      this.props.viewMoreURL &&
      !(React.Children.count(this.props.children) <= DEFAULT_PLACEHOLDER_COUNT)
    );

    const shouldRenderFlexFix = !(
      this.props.singleRow ||
      this.props.oneWide ||
      this.props.grow ||
      (this.props.centered && this.props.threeWide)
    );

    if (this.isEmpty()) { return this.props.emptyState; }

    if (this.props.renderAsOrderedList) {
      return (
        <ol className={classes}>
          {shouldRenderPlaceholder &&
            <Placeholder
              placeholder={this.props.placeholder}
              expectedCount={this.props.expectedCount}
            />
          }
          {!this.isInitializing() && <Grid>{this.props.children}</Grid>}
          {shouldRenderViewMoreTile &&
            <ViewMoreTile
              viewMoreURL={this.props.viewMoreURL}
            />
          }
          {shouldRenderFlexFix && <FlexFix />}
        </ol>
      );
    }

    return (
      <ul className={classes}>
        {shouldRenderPlaceholder &&
          <Placeholder
            placeholder={this.props.placeholder}
            expectedCount={this.props.expectedCount}
          />
        }
        {!this.isInitializing() && <Grid>{this.props.children}</Grid>}
        {shouldRenderViewMoreTile &&
          <ViewMoreTile
            viewMoreURL={this.props.viewMoreURL}
          />
        }
        {shouldRenderFlexFix && <FlexFix />}
      </ul>
    );
  }
}
