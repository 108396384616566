import React from 'react';
import ReactDOM from 'react-dom';
import * as elog from '@reverbdotcom/commons/src/elog';
import { AppProvider, buildApolloClient } from '@reverbdotcom/discovery-ui';
import { parseMeta, frontendCurrentUser } from '@reverbdotcom/discovery-ui/src/components/parse_meta';
import { get } from 'lodash';
import { matchRoutes } from '@reverbdotcom/commons/src/route_settings';
import { offlineInit } from '@eppo/js-client-sdk';
import { assignmentLogger } from '@reverbdotcom/commons/src/eppo/eppoAssignmentLogger';

const CLASS_NAME_ATTR = 'data-react-class';
const PROPS_ATTR = 'data-react-props';

const user = get(frontendCurrentUser(), 'user', {});
const state = parseMeta('apollo-state');

const appConfig = parseMeta('app-config-meta');

const client = buildApolloClient(user);
client.restore(state);

const routerComponent = 'Reverb.Router';

const render = (global: typeof window, dom: typeof document) => {
  const eppoClient = offlineInit({
    flagsConfiguration: appConfig?.config?.eppoFlagsConfiguration?.flags || {},
    isObfuscated: false,
    assignmentLogger,
  });

  const all = dom.querySelectorAll('[data-react-class]');
  [].forEach.call(all, async (component: Element) => { // querySelectorAll returns a NodeList not an []
    try {
      const name = component.getAttribute(CLASS_NAME_ATTR);
      const propsStr = component.getAttribute(PROPS_ATTR);
      const props = JSON.parse(propsStr);

      const constructor = name
        .split('.')
        .reduce((obj, index) => obj[index], global);

      if (!constructor) {
        throw new Error(`Could not find React component ${name}`);
      }

      if (name === routerComponent) {
        // To avoid a blank initial render, resolve react-router's Router props before hydration
        // https://github.com/remix-run/react-router/blob/v3/docs/guides/ServerRendering.md#async-routes
        const routerProps = await matchRoutes(window.location);
        Object.assign(props, routerProps);
      }

      const componentElement = React.createElement(constructor, props);

      const wrapped = React.createElement(
        AppProvider,
        {
          user,
          client,
          eppoClient,
          url: window.location.href,
          referer: document.referrer,
        },
        componentElement,
      );
      if (component.hasChildNodes()) {
        ReactDOM.hydrate(wrapped, component);
      } else {
        ReactDOM.render(wrapped, component);
      }
    } catch (e) {
      elog.error('react-mount-error', {}, e);
    }
  });
};

export default {
  render,
};

document.addEventListener('DOMContentLoaded', render.bind(this, window, document));
