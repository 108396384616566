import { head, get, isNil } from 'lodash';

import {
  core_apimessages_Offer,
  core_apimessages_ShippingPrice,
  core_apimessages_Money,
  Listing,
  Commons_Offers_EditOfferFormQuery,
  Negotiation,
  core_apimessages_NegotiationParty as NegotiationParty,
} from '@reverbdotcom/commons/src/gql/graphql';
import { formatAmount } from '@reverbdotcom/commons/src/money';
import { IUser } from '@reverbdotcom/commons/src/components/user_context_provider';
import { IOfferPrices } from '@reverbdotcom/commons/src/offers/negotiation_types';

function activeNegotiation(me?: Commons_Offers_EditOfferFormQuery['me']): Negotiation | undefined {
  return head(get(me, 'activeNegotiation.negotiations', []));
}

function lastOffer(negotiation: Negotiation): core_apimessages_Offer | undefined {
  return get(negotiation, 'lastOffer');
}

function listingShippingPrice(listing?: Listing): core_apimessages_ShippingPrice | undefined {
  return head(get(listing, 'shipping.shippingPrices', []));
}

function offerShippingPriceAmount(
  currentOfferShippingPrice: string,
  me: Commons_Offers_EditOfferFormQuery['me'],
) {
  if (isNil(currentOfferShippingPrice)) {
    const prices = lastOfferPrices(me);
    const shippingAmount = get(prices, 'shippingPrice.amount', 0);
    return formatAmount({
      amount: shippingAmount,
      symbol: '',
    }, {
      format: '%v', // ovverride specifies we want a money display string with no currency symbol
    });
  }

  return currentOfferShippingPrice;
}

function offerOriginalShippingPriceAmount(
  lastOffer: core_apimessages_Offer,
): core_apimessages_Money | undefined {
  return get(lastOffer, 'prices.shippingPrice.original');
}

function offerQuantity(
  lastOffer: core_apimessages_Offer,
  currentOfferQuantity?: number,
) {
  if (!currentOfferQuantity) {
    return get(head(get(lastOffer, 'offerItems', [])), 'quantity', 1);
  }

  return currentOfferQuantity;
}

function lastOfferPrices(me: Commons_Offers_EditOfferFormQuery['me']): IOfferPrices {
  return lastOfferPricesFor(currentParty(me), activeNegotiation(me));
}

function lastOfferPricesFor(party: NegotiationParty, negotiation: Negotiation): IOfferPrices {
  const key = (party === NegotiationParty.SELLER) ?
    'original' : 'display';
  const offer = lastOffer(negotiation);

  return {
    price: get(offer, `prices.price.${key}`),
    shippingPrice: get(offer, `prices.shippingPrice.${key}`),
    totalPrice: get(offer, `prices.totalPrice.${key}`),
  };
}

function currentParty(me: Commons_Offers_EditOfferFormQuery['me']): NegotiationParty {
  const currentPartyUUID = get(me, 'uuid');
  const sellerUUID = get(activeNegotiation(me), 'sellerUuid');

  if (currentPartyUUID === sellerUUID) {
    return NegotiationParty.SELLER;
  }

  return NegotiationParty.BUYER;
}

function originalCurrency(data: Commons_Offers_EditOfferFormQuery): string {
  const negotiation = activeNegotiation(get(data, 'me'));
  if (negotiation) {
    const offer = lastOffer(negotiation);
    return get(offer, 'prices.price.original.currency');
  }

  return get(data, 'listing.shop.currency');
}

function userCurrency(data: Commons_Offers_EditOfferFormQuery, user: Partial<IUser>): string {
  const party = currentParty(get(data, 'me'));

  if (party === NegotiationParty.SELLER) {
    return originalCurrency(data);
  }

  return user.currency;
}

export {
  activeNegotiation,
  currentParty,
  lastOffer,
  lastOfferPrices,
  lastOfferPricesFor,
  listingShippingPrice,
  offerShippingPriceAmount,
  offerOriginalShippingPriceAmount,
  offerQuantity,
  originalCurrency,
  userCurrency,
};
