import React from 'react';
import I18n from 'i18n-js';
import { first, get } from 'lodash';
import classNames from 'classnames';

import {
  Commons_Offers_UpdateOfferFormMutation,
  Commons_Offers_UpdateOfferFormMutationVariables,
  Input_core_apimessages_UpdateNegotiationRequest,
  Negotiation,
  Listing,
  core_apimessages_NegotiationAction as NegotiationAction,
  core_apimessages_Offer as Offer,
  reverb_config_cache_ExchangeRate as ExchangeRate,
} from '@reverbdotcom/commons/src/gql/graphql';
import { editOfferFormQuery, updateNegotiationMutation } from '@reverbdotcom/commons/src/offers/offer_operations';
import { useLazyQuery } from '@reverbdotcom/commons/src/useLazyQuery';
import { useMutation } from '@reverbdotcom/commons/src/useMutation';
import { convertCurrency } from '@reverbdotcom/commons/src/convert_currency';
import { useUser } from '@reverbdotcom/commons/src/user_hooks';
import { EVERYWHERE_CODE } from '@reverbdotcom/commons/src/constants';
import { RCLoadingBars, RCToast } from '@reverbdotcom/cadence/components';

import Location from '../../lib/wrapped_location';
import URLHelpers from '../shared/url_helpers';
import {
  activeNegotiation,
  lastOffer as getLastOffer,
  originalCurrency as getOriginalCurrency,
  userCurrency as getUserCurrency,
} from './negotiation_getters';

interface Props {
  listingId: string;
  isSeller: boolean;
  negotiationId: string;
  onClick: () => void;
  onError: () => void;
}

function buildOffer(negotiation: Negotiation, lastOffer: Offer, userCurrency: string, originalCurrency: string, exchangeRates: ExchangeRate[], listing: Listing): Input_core_apimessages_UpdateNegotiationRequest {
  const baseOffer = {
    price: lastOffer.prices.price.display.amount,
    shippingPrice: lastOffer.prices.shippingPrice.display.amount,
    quantity: first(lastOffer.offerItems).quantity,
  };

  const shopCurrencyOfferPrice = convertCurrency(
    userCurrency,
    originalCurrency,
    exchangeRates,
    baseOffer.price,
  );
  const shopCurrencyOfferShippingPrice = convertCurrency(
    userCurrency,
    originalCurrency,
    exchangeRates,
    baseOffer.shippingPrice,
  );

  return {
    action: NegotiationAction.ACCEPT,
    id: negotiation.id,
    offerItems: [
      {
        listingId: listing.id,
        quantity: baseOffer.quantity,
        price: shopCurrencyOfferPrice,
        shippingPrice: shopCurrencyOfferShippingPrice,
      },
    ],
  };
}


export default function AcceptOfferAndCheckOutButton({ listingId, isSeller, negotiationId, onClick, onError }: Props) {
  const user = useUser();

  const [showErrorToast, setShowErrorToast] = React.useState(false);

  const [offerQuery, offerQueryResults] = useLazyQuery(
    editOfferFormQuery,
    {
      errorPolicy: 'all',
      fetchPolicy: 'no-cache',
      variables: {
        listingId: listingId,
        negotiationId: negotiationId,
        isSeller: isSeller,
        shippingRegionCode: user?.shippingRegionCode || EVERYWHERE_CODE,
      },
    },
  );

  const [
    updateNegotiation,
    {
      loading: mutationDataLoading,
    },
  ] = useMutation<Commons_Offers_UpdateOfferFormMutation, Commons_Offers_UpdateOfferFormMutationVariables>(
    updateNegotiationMutation,
    { fetchPolicy: 'no-cache' },
  );

  const loading = offerQueryResults.loading || mutationDataLoading;

  async function doNegotiationUpdate(input: Input_core_apimessages_UpdateNegotiationRequest): Promise<Commons_Offers_UpdateOfferFormMutation['updateNegotiation']['negotiation']> {
    const { data: mutationResult } = await updateNegotiation({ variables: { input } });
    return get(mutationResult, 'updateNegotiation.negotiation');
  }

  return (
    <div>
      <button
        className={classNames('offer-action', 'offer-action--accept')}
        onClick={async () => {
          onClick();

          const queryResponse = await offerQuery();
          const negotiation = activeNegotiation(queryResponse.data.me);

          if (negotiation) {
            const lastOffer = getLastOffer(negotiation);
            const userCurrency = getUserCurrency(queryResponse.data, user);
            const originalCurrency = getOriginalCurrency(queryResponse.data);
            const exchangeRates = queryResponse.data.exchangeRates?.rates || [];
            const { listing } = queryResponse.data;

            const offer = buildOffer(
              negotiation,
              lastOffer,
              userCurrency,
              originalCurrency,
              exchangeRates,
              listing,
            );

            const response = await doNegotiationUpdate(offer);
            if (response?.lastOffer) {
              Location.assign(URLHelpers.cartPath);
            } else {
              onError();
              setShowErrorToast(true);
            }
          } else {
            onError();
            setShowErrorToast(true);
          }
        }}
        type="button"
        disabled={loading}
      >
        {I18n.t('discovery.offers.action.acceptAndCheckOut.button')}

        {loading && (
          <RCLoadingBars
            invertColor={true}
          />
        )}

        <RCToast
          text={I18n.t('discovery.offers.action.acceptAndCheckOut.error')}
          isOpen={showErrorToast}
          onClose={() => { setShowErrorToast(false); }}
          theme="error"
        />
      </button>
    </div>
  );
}
