import moment from 'moment';
import I18n from 'i18n-js';

export function formatDate(date: Date | moment.Moment | string, dateFormat: string): string {
  if (isInvalidDate(date)) {
    return I18n.t('invalidDate');
  }

  return moment(date).format(dateFormat);
}

export function formatInternationalizedSeconds(seconds, format = 'L') {
  const t = new Date(1970, 0, 1);
  t.setSeconds(seconds);

  return formatInternationalizedDate(t, format);
}

export function formatInternationalizedDate(date, format = 'L') {
  return moment(date).format(format);
}

function isInvalidDate(val) {
  return !val || new Date(val).toString() === 'Invalid Date';
}

export function getDate(dateString: any): string {
  if (isInvalidDate(dateString)) {
    return I18n.t('invalidDate');
  }

  return moment(dateString).format('MMMM D, gggg');
}

export function getYear(dateTime: any): string {
  if (isInvalidDate(dateTime)) {
    return I18n.t('cms.invalidDate');
  }

  return moment(dateTime).format('YYYY');
}

export function dateFromToday(numberOfDays: number, direction: 'future' | 'past'): Date {
  let date: moment.Moment = moment.utc(Date.now());

  if (direction === 'future') {
    date = date.add(numberOfDays, 'd');
  } else if (direction === 'past') {
    date = date.subtract(numberOfDays, 'd');
  }

  return date.toDate();
}

export function getDateAndTimeFromSeconds(seconds: number, format = 'LLL'): string {
  return moment.unix(seconds).format(format);
}
