import React from 'react';
import I18n from 'i18n-js';
import { withRouter, WithRouterProps } from 'react-router';
import { RCAccordion } from '@reverbdotcom/cadence/components';
import bind from '../bind';
import { withUpdateURL, IUpdateURLProps } from './with_update_url';
import { Filter, filterID, FilterProps, getRangeValue } from './search_filters';
import ControlledInput from './controlled_input';
import { MParticleEventName } from '../elog/mparticle_types';
import { trackEvent } from '../elog/mparticle_tracker';
import { withUserContext, IUserContext } from './user_context_provider';

interface IState {
  minValue: string;
  maxValue: string;
}

type IProps = FilterProps & IUserContext & WithRouterProps & IUpdateURLProps;

function inputValuesFromFilter(filter: Filter): IState {
  return {
    minValue: getRangeValue(filter, 'min').value || '',
    maxValue: getRangeValue(filter, 'max').value || '',
  };
}

export class RangeFacet extends React.Component<IProps, IState> {
  constructor(props) {
    super(props);

    this.state = inputValuesFromFilter(this.props.filter);
  }

  componentDidUpdate(prevProps: IProps) {
    const current = inputValuesFromFilter(this.props.filter);
    const prev = inputValuesFromFilter(prevProps.filter);
    if (current.minValue !== prev.minValue || current.maxValue !== prev.maxValue) {
      this.setState(current);
    }
  }

  @bind
  handleChange(update: IState) {
    this.setState(update);
  }

  @bind
  handleSubmit(evt) {
    if (evt) evt.preventDefault();

    const minParam = getRangeValue(this.props.filter, 'min');
    const maxParam = getRangeValue(this.props.filter, 'max');

    const newUrl = this.props.updateUrl({
      location: this.props.location,
      newParams: {
        [minParam.key]: this.state.minValue,
        [maxParam.key]: this.state.maxValue,
      },
    });

    trackEvent({
      componentName: 'RangeFacet',
      eventName: MParticleEventName.ClickedSearchFilter,
      min: this.state.minValue,
      max: this.state.maxValue,
      selected: true,
      parent: this.props.filter.key,
      query: this.props.trackingQuery,
    });

    this.props.router.push(newUrl);
  }

  @bind
  renderInputs() {
    return (
      <div className="pt-2">
        <form onSubmit={this.handleSubmit}>
          <div className="search-input-group__inner">
            <div className="search-input-group__field">
              <ControlledInput
                className="search-input-group__input"
                inputType="number"
                fieldName="minValue"
                inputName="minValue"
                inputValue={this.state.minValue}
                updateField={this.handleChange}
                placeholder="Min"
                autoComplete="off"
                autoCorrect="off"
                autoCapitalize="off"
                spellCheck={false}
              />
            </div>
            <div className="search-input-group__field">
              <ControlledInput
                className="search-input-group__input"
                inputType="number"
                fieldName="maxValue"
                inputName="maxValue"
                inputValue={this.state.maxValue}
                updateField={this.handleChange}
                placeholder="Max"
                autoComplete="off"
                autoCorrect="off"
                autoCapitalize="off"
                spellCheck={false}
              />
            </div>
            <button
              className="search-input-group__button"
              type="submit"
              aria-label={I18n.t('commons.forms.submitLabel')}
            />
          </div>
        </form>
      </div>
    );
  }

  render() {
    const { minValue, maxValue } = this.state;
    const labelID = `${this.props.pageSection}--${filterID(this.props.filter)}-lbl`;

    if (this.props.collapsible) {
      return (
        <div className="facet facet--collapsible">
          <RCAccordion
            id={labelID}
            heading={this.props.filter.name}
            defaultOpen={this.props.defaultOpen || !!minValue || !!maxValue}
            onToggle={this.props.onToggleCallback}
          >
            {this.renderInputs()}
          </RCAccordion>
        </div>
      );
    }

    return (
      <div
        aria-labelledby={labelID}
        className="facet"
        role="group"
      >
        <h3
          className="facet__heading"
          id={labelID}
        >
          {this.props.filter.name}
        </h3>
        {this.renderInputs()}
      </div>
    );
  }
}

export default withUserContext<FilterProps>(withUpdateURL(withRouter(RangeFacet)));
