import I18n from 'i18n-js';
import React from 'react';

import { isZeroAmount } from '@reverbdotcom/commons/src/money';
import FormGroupWithRadio from '@reverbdotcom/commons/src/components/form_group_with_radio';
import { RCTag } from '@reverbdotcom/cadence/components';
import SiteModule from '@reverbdotcom/commons/src/components/site_module';
import { EVERYWHERE_CODE, REGIONAL_AREA_TYPES, US_CON } from '@reverbdotcom/commons/src/constants';

import EditShippingRateCardManualPrices, { IShippingRate, PriceField } from '../shared/edit_shipping_rate_card_manual_prices';
import EditShippingRateCardRegionalPrices from '../shared/edit_shipping_rate_card_regional_prices';
import { FreeExpeditedShippingOption } from '../shared/edit_shipping_rate_card_expedited';

// What variant of radio cards should we show for the ShippingRate in this region?
enum FormUIMode {
  FREE = 'free', // user has selected simple free shipping -> no combine+save/expedited/regional; no user input required
  CUSTOM = 'custom', // user is manually setting shipping prices, ineligible for regional shipping -> show simple form
  REGIONAL = 'regional', // user is manually setting shipping prices, eligible for regional shipping -> show expanded form
}

interface IProps {
  shippingRate?: IShippingRate;
  currency: string;
  handleRemoveShippingRate?: (IShippingRate) => void;
  handleEditShippingRate?: (IShippingRate) => void;
  allowedRegionalShipping: boolean;
  allowedToShipExpedited: boolean;
}

function existingRateHasFreeStandardShipping(shippingRate: IShippingRate): boolean {
  return isZeroAmount(shippingRate?.rate?.amount) &&
  !shippingRate?.expeditedRate?.amount &&
  !existingRateHasRegionalShippingOrIncrementalValues(shippingRate);
}

function existingRateHasFreeExpeditedShipping(shippingRate: IShippingRate): boolean {
  return isZeroAmount(shippingRate?.rate?.amount) &&
  (!!shippingRate?.expeditedRate?.amount && isZeroAmount(shippingRate?.expeditedRate?.amount)) &&
  !existingRateHasRegionalShippingOrIncrementalValues(shippingRate);
}

function existingRateHasRegionalShippingOrIncrementalValues(shippingRate: IShippingRate): boolean {
  return !!shippingRate?.standardRegionalRates?.length ||
  !!shippingRate?.expeditedRegionalRates?.length ||
  !!shippingRate?.incrementalRate?.amount;
}

interface ICollapsibleShippingRadioProps {
  forMode: FormUIMode;
  badgeText: string;
  shippingRate: IShippingRate;
  onUpdate: () => void;
  children?: any;
  selected: boolean
  label?: string;
  helpText?: string;
}

// A an expandable radio toggle card for each UI mode, which renders child inputs when active
function CollapsibleShippingRadio({
  forMode,
  selected,
  shippingRate,
  badgeText,
  onUpdate,
  children,
  label,
  helpText,
}: ICollapsibleShippingRadioProps) {
  const inputName = `${forMode}_radio_${shippingRate.regionCode}`;
  const country = shippingRate.isRequired && getLocalizedRegionName(shippingRate);
  return (
    <SiteModule>
      <FormGroupWithRadio
        inputName={inputName}
        label={(
          <label htmlFor={inputName}>
            <span className="shipping-rate-card__shipping__mode__title">
              {label || I18n.t(`discovery.dashboard.selling.shippingRates.shippingModeRadios.${forMode}.title`, { country })}
            </span>

            {badgeText && <RCTag variant="highlight">{badgeText}</RCTag>}
          </label>
        )}
        helpText={(
          <div>
            <p className="shipping-rate-card__carrier__calculated__help__text">
              {helpText || I18n.t(`discovery.dashboard.selling.shippingRates.shippingModeRadios.${forMode}.helpText`, { country })}
            </p>
          </div>
        )}
        value={forMode}
        checked={selected}
        updateField={onUpdate}
      />

      {selected && children && (
        <div className="shipping-rate-collapsible__radio__content">
          {children}
        </div>
      )}
    </SiteModule>
  );
}

export function getLocalizedRegionName(shippingRate: IShippingRate): string {
  if (shippingRate.regionCode === US_CON) {
    return I18n.t('discovery.dashboard.selling.shippingRates.theContinentalUs');
  } else {
    return shippingRate.regionName;
  }
}

export function ShippingProfileRateCard({
  currency,
  shippingRate,
  allowedToShipExpedited,
  allowedRegionalShipping,
  handleRemoveShippingRate,
  handleEditShippingRate,
}: IProps) {
  const isEverywhereElseRegion = shippingRate.regionCode === EVERYWHERE_CODE;
  const existingRateHasFreeShipping = existingRateHasFreeStandardShipping(shippingRate) || existingRateHasFreeExpeditedShipping(shippingRate);
  const [isFreeModeSelected, setIsFreeModeSelected] = React.useState(existingRateHasFreeShipping);

  const rateHeader = I18n.t('discovery.dashboard.selling.shippingRates.optionsHeaderWithCountry', { country: getLocalizedRegionName(shippingRate) });

  return (
    <div className="shipping-rate-card-shipping-profile">
      <header className="shipping-rate-card__header">
        <div className="shipping-rate-card__titleblock">
          <h4 className="shipping-rate-card__title">
            {isEverywhereElseRegion && (
              <span className="icon-l-globe">
                {I18n.t('discovery.dashboard.selling.shippingRates.everywhereElse')}
              </span>)}

            {!isEverywhereElseRegion && rateHeader}
          </h4>
          {isEverywhereElseRegion && (
            <p className="shipping-rate-card__subtitle">
              {I18n.t('discovery.dashboard.selling.shippingRates.everywhereElseTip')}
            </p>
          )}
        </div>
        <div className="shipping-rate-card__remove">
          {shippingRate.isRequired && (<span className="form-group__tag">{I18n.t('commons.formGroup.required')}</span>)}

          {!shippingRate.isRequired && (
            <button
              className="d-block button-as-link size-80 color-red"
              onClick={() => handleRemoveShippingRate(shippingRate)}
              type="button"
            >
              {I18n.t('discovery.dashboard.selling.shippingRates.rateFields.removeRate')}
            </button>
          )}
        </div>
      </header>
      <div className="shipping-rate-card__main">
        <input
          data-region-type="true"
          hidden
          readOnly
          type="hidden"
          name="shipping_profile[shipping][rates][][region_type]"
          value={shippingRate.regionType}
        />
        <input
          data-region-code="true"
          hidden
          readOnly
          type="hidden"
          name="shipping_profile[shipping][rates][][region_code]"
          value={shippingRate.regionCode}
        />
        {isFreeModeSelected && (
          <input
            data-automatic-free="true"
            hidden
            readOnly
            type="hidden"
            name="shipping_profile[shipping][rates][][rate]"
            value="0"
          />
        )}
        {isFreeModeSelected && isZeroAmount(shippingRate[PriceField.EXPEDITED]?.amount) && (
          <input
            data-automatic-free-expedited="true"
            hidden
            readOnly
            type="hidden"
            name="shipping_profile[shipping][rates][][expedited_rate]"
            value="0"
          />
        )}

        {/* Always show free shipping option */}
        <CollapsibleShippingRadio
          data-free-mode-radio
          forMode={FormUIMode.FREE}
          selected={isFreeModeSelected}
          shippingRate={shippingRate}
          onUpdate={() => setIsFreeModeSelected(true)}
          badgeText={!allowedRegionalShipping && I18n.t(`discovery.dashboard.selling.shippingRates.shippingModeRadios.${FormUIMode.FREE}.badgeText`)}
          label={allowedRegionalShipping && I18n.t(`discovery.dashboard.selling.shippingRates.shippingModeRadios.free.titleRegional${allowedToShipExpedited ? 'WithExpedited' : ''}`, { country: getLocalizedRegionName(shippingRate) })}
          helpText={allowedRegionalShipping && I18n.t('discovery.dashboard.selling.shippingRates.shippingModeRadios.free.helpTextRegional')}
        />

        {isFreeModeSelected && (
          <FreeExpeditedShippingOption
            allowedToShipExpedited={allowedToShipExpedited}
            shippingRate={shippingRate}
            handleEditShippingRate={handleEditShippingRate}
          />
        )}

        {!allowedRegionalShipping &&
          <CollapsibleShippingRadio
            data-custom-mode-radio
            forMode={FormUIMode.CUSTOM}
            selected={!isFreeModeSelected}
            shippingRate={shippingRate}
            onUpdate={() => setIsFreeModeSelected(false)}
            badgeText=""
          >
            <EditShippingRateCardManualPrices
              shippingRate={shippingRate}
              handleEditShippingRate={handleEditShippingRate}
              currency={currency}
              allowedToShipExpedited={allowedToShipExpedited}
              forShippingProfile
            />
          </CollapsibleShippingRadio>
        }

        {allowedRegionalShipping && (
          <>
            <CollapsibleShippingRadio
              data-regional-shipping-mode-radio
              forMode={FormUIMode.REGIONAL}
              selected={!isFreeModeSelected}
              shippingRate={shippingRate}
              onUpdate={() => setIsFreeModeSelected(false)}
              badgeText=""
              helpText={I18n.t(`discovery.dashboard.selling.shippingRates.shippingModeRadios.regional.helpText${allowedToShipExpedited ? 'WithExpedited' : ''}`, {
                areaType: I18n.t(`discovery.dashboard.selling.shippingRates.shippingModeRadios.regional.${REGIONAL_AREA_TYPES[shippingRate.regionCode]}s`).toLowerCase(),
              })}
            />
            {!isFreeModeSelected && (
              <EditShippingRateCardRegionalPrices
                shippingRate={shippingRate}
                handleEditShippingRate={handleEditShippingRate}
                currency={currency}
                allowedToShipExpedited={allowedToShipExpedited}
              />
            )}
          </>
        )}
      </div>
    </div>
  );
}

export default ShippingProfileRateCard;
